<kt-portlet>
    <kt-portlet-header
            [title]="getComponentTitle()"
            [class]="'kt-portlet-head--lg'"
            [tooltipTitle]="'TOOLTIP.APPENDIX.FORM'+(appendix && appendix.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.APPENDIX.FORM'+(appendix && appendix.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a [routerLink]="appendix.id > 0 ? ['../../'] : ['../']" class="btn btn-secondary mr-2" mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                    back_page: ('APPENDIX.FORM.TITLE.LIST' | translate)
                }">
                <i class="la la-arrow-left"></i>
                <span class="kt-hidden-mobile">
                    {{ 'GENERAL.BUTTON.CLOSE' | translate }}
                </span>
            </a>
            <a href="javascript:;" class="btn btn-success mr-2" color="submit"
               (click)="onSubmit(false)"
               [disabled]="!isFormValid()"

               mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.SAVE' | translate">
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
            </a>
            <!--</mat-menu>-->
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="kt-form" [formGroup]="appendixForm">
            <div class="kt-portlet-body">

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="form-group kt-form-group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'APPENDIX.FORM.FIELD.GENERAL.APPENDIX_NAME.PLACEHOLDER' | translate" formControlName="name"/>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('APPENDIX.FORM.FIELD.GENERAL.APPENDIX_NAME.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'APPENDIX.FORM.FIELD.GENERAL.DESCRIPTION.PLACEHOLDER' | translate" formControlName="description"/>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('APPENDIX.FORM.FIELD.GENERAL.DESCRIPTION.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 kt-margin-bottom-20-mobile">
                        <h5 class="my-3 text-mad">
                            {{ 'APPENDIX.FORM.FIELD.DOCUMENT.SECTION_TITLE' | translate }}
                        </h5>
                        <hr class="active">
                    </div>

                    <div class="col-md-12 kt-margin-bottom-20-mobile">
                        <kt-upload-file
                                [filesOnEditMode]="appendix.appendix_file"
                                [readOnly]="false"
                                [folderOnEditMode]="appendix.path"
                                [descriptionField]="true"
                                [fileType]="fileType"
                                [parentForm]="'appendix'">

                        </kt-upload-file>
                        <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0"
                                   [innerHtml]="'GENERAL.VALIDATION.AT_LEAST_ONE' | translate:{
                                field: ('APPENDIX.FORM.FIELD.DOCUMENT.APPENDIX_DOCUMENT.NAME' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </div>
            </div>
        </form>
    </kt-portlet-body>
</kt-portlet>

