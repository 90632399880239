<div class="d-flex flex-column mb-4" *ngIf="uploadingReport$ | async">
    <h3 class="mb-2">Uploading Signed Report</h3>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="card card-custom">
    <div class="card-header">
        <div class="card-title"></div>
        <div class="card-toolbar" *ngIf="tpState != 1 && toeStatus != 4">
            <input #fileInput type="file" (change)="uploadFile($event)" style="display: none;" accept=".xslx,.xls,image/*,.doc,docx,.pdf">
        </div>
    </div>
    <div class="card-body">
        <div class="example-container mat-elevation-z0" style="min-height: 250px">
            <div class="mat-table-wrapper">
                <mat-table #table [dataSource]="dataSource">
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            <span *ngIf="report.type === reportType.ValuationReport">Valuation Report</span>
                            <span *ngIf="report.type === reportType.DraftStatement">Draft Statement</span>
                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="uploaded_date">
                        <mat-header-cell *matHeaderCellDef>Date uploaded</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            {{ report.created_at ? (report.created_at | date:'dd MMM yyyy, h:mm a':user.settings.time_zone_gmt) : '' }}
                        </mat-cell>
                    </ng-container>
            
                    <ng-container matColumnDef="file_size">
                        <mat-header-cell *matHeaderCellDef>File Size</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            {{report.file_size ? report.file_size : 'N/A'}}
                        </mat-cell>
                    </ng-container>
            
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let report let i = index">
                            <div *ngIf="report.url" class="has_report">
                                <button 
                                    *ngIf="userID && userID == taskUserId"
                                    [disabled]="tpState == 1 || toeStatus == 4" mat-icon-button color="warn"
                                    (click)="deleteReport(report)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button color="primary"
                                    (click)="downloadReport(report)" >
							        <mat-icon>get_app</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="!report.url" class="has_not_report">
                                <button 
                                    *ngIf="userID && userID == taskUserId"
                                    [disabled]="tpState == 1 || toeStatus == 4" mat-icon-button type="button" color="primary"
                                    (click)="uploadDoc(report.type)">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
                <div class="mat-table-message" *ngIf="loading$ | async">Please wait....</div>
                <div class="mat-table-message" *ngIf="!(hasData$ | async)">No Reports...</div>
            </div>
        </div>
    </div>
</div>