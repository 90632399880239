<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Leasehold Information</h3>
            <span class="card-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">

            <a href="javascript:;"
               (click)="close()"
               class="btn btn-icon btn-sm btn-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body m-0 h-100">
            <div class="col-md-12 mat-table-wrapper section-margin-bottom-60">
                <table class="table lmat-elevation-z8 leasehold-information-table">
                    <tbody>
                        <tr>
                            <th>Total Consideration per Month ({{consideration.currency }})</th>
                            <td>{{consideration.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</td>
                            <th class="bl-gainsboro">Rent type</th>
                            <td>{{rentType}}</td>
                        </tr>
                        <tr>
                            <th>Rent review cycle (Years)</th>
                            <td>{{consideration.rent_review ? consideration.rent_review : 'N/A'}}</td>
                            <th class="bl-gainsboro">Capital Payment ({{consideration.currency}})</th>
                            <td>{{consideration.cap_payment | mask:'separator.2':{thousandSeparator: ','} }}</td>
                        </tr>
                        <tr>
                            <th>Lease duration (Years)</th>
                            <td>{{consideration.lease_duration}}</td>
                            <th class="bl-gainsboro">Unexpired lease term (Years)</th>
                            <td>{{consideration.unexpired_lease_term}}</td>
                        </tr>
                        <tr>
                            <th>Write off period after (Years)</th>
                            <td>{{consideration.write_off_period ? consideration.write_off_period : 'N/A'}}</td>
                            <th class="bl-gainsboro">Break option after (Years)</th>
                            <td>{{consideration.break_option ? consideration.break_option : 0}}</td>
                        </tr>
                        <tr>
                            <th>Rent free period (Years)</th>
                            <td>{{consideration.rent_free_period ? consideration.rent_free_period : 0}}</td>
                            <th class="bl-gainsboro">Fitting out period (Years)</th>
                            <td>{{consideration.fitting_out_period ? consideration.fitting_out_period : 0}}</td>
                        </tr>
                        <tr *ngIf="consideration.rent_review_comment">
                            <th>Rent review comments</th>
                            <td colspan="3">{{consideration.rent_review_comment}}</td>
                        </tr>
                        <tr>
                            <th>Inclusive of property management fees (PMF)?</th>
                            <td>{{consideration.inclusive_property_management === '0'
                                    ? 'Yes'
                                    : 'No'}}</td>
                            <th class="bl-gainsboro">Estimated PMF per month ({{consideration.currency}})</th>
                            <td>{{consideration.inclusive_property_management === '0' 
                                    ? (consideration.estimated_amount_property_management | mask:'separator.2':{thousandSeparator: ','})
                                    : 'N/A'}}</td>
                        </tr>
                        <tr>
                            <th>Inclusive of utility cost?</th>
                            <td>{{consideration.consideration_inclusive_cost === '0'
                                    ? 'Yes'
                                    : 'No'}}</td>
                            <th class="bl-gainsboro">Estimated utility cost per month ({{consideration.currency}})</th>
                            <td>{{consideration.consideration_inclusive_cost === '0' 
                                    ? (consideration.estimated_amount_utilities_cost | mask:'separator.2':{thousandSeparator: ','})
                                    : 'N/A'}}</td>
                        </tr>
                        <tr>
                            <th>Inclusive of full repair and insurance cost (FRI)?</th>
                            <td>{{consideration.consideration_full_repair_cost === '0'
                                    ? 'Yes'
                                    : 'No'}}</td>
                            <th class="bl-gainsboro">Estimated FRI cost per month ({{consideration.currency}})</th>
                            <td>{{consideration.consideration_full_repair_cost === '0' 
                                    ? (consideration.estimated_amount_repair_cost | mask:'separator.2':{thousandSeparator: ','})
                                    : 'N/A'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
</div>
