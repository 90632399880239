// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
import {selectBuildingsPageLoading, selectBuildingsShowInitWaitingMessage, selectQueryResult} from '../_selectors/building.selectors';

export class BuildingDataSource extends BaseDataSource {

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectBuildingsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectBuildingsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
