<div class="col-md-12 mb-10">
    <h5 class="my-3 text-mad text-uppercase">leasehold information</h5>
    <hr class="active">
</div>
<div class="col-md-12 mat-table-wrapper section-margin-bottom-60">
    <table class="table lmat-elevation-z8 leasehold-information-table">
        <tbody>
            <ng-container *ngIf="viewModel$|async as vm">
                <tr>
                    <th>Amount {{vm.rent_input_amount_type === 'total' ? 'Total' : 'per Unit'}} ({{vm.rent_basis_name}}) ({{tpCurrency}})</th>
                    <td>{{ vm.total_amount ? (vm.total_amount | mask:'separator.2':{thousandSeparator:','}) : 'N/A' }}</td>
                    <th class="bl-gainsboro">Rent Type</th>
                    <td>{{vm.rent_type}}</td>
                </tr>
                <tr>
                    <th>Start Date</th>
                    <td>
                        {{ vm.start_date ? (vm.start_date | date:'dd MMM yyyy') : 'N/A'}}
                    </td>
                    <th class="bl-gainsboro">Capital Payment ({{tpCurrency}})</th>
                    <td>{{vm.capital_payment ? (vm.capital_payment | mask:'separator.2':{thousandSeparator:','}) : 'N/A' }}</td>
                </tr>
                <tr>
                    <th>Rent Review cycle (Years)</th>
                    <td>{{vm.rent_review_cycle ?(vm.rent_review_cycle | mask:'separator.2') : 'N/A' }}</td>
                    <th class="bl-gainsboro">Unexpired lease term (Years)</th>
                    <td>{{vm.unexpired_lease_terms}}</td>
                </tr>
                <tr>
                    <th>Lease duration (Years)</th>
                    <td>{{vm.lease_duration}}</td>
                    <th class="bl-gainsboro">Break option after (Years)</th>
                    <td>{{vm.break_option_after ? (vm.break_option_after | mask:'separtor.2') : 'N/A'}}</td>
                </tr>
                <tr>
                    <th>Write off period after (Years)</th>
                    <td>{{vm.write_off_period_after ? (vm.write_off_period_after | mask:'separator.2') : 'N/A'}}</td>
                    <th class="bl-gainsboro">Fitting out period (Years)</th>
                    <td>{{vm.fitting_out_period ? (vm.fitting_out_period | mask:'separator.2') : 'N/A'}}</td>
                </tr>
                <tr>
                    <th>Rent free period (Years)</th>
                    <td>{{vm.rent_free_period ? (vm.fitting_out_period | mask:'separator.2') : 'N/A'}}</td>
                    <th class="bl-gainsboro">Rent review type</th>
                    <td>{{vm.rent_review_type}}</td>
                </tr>
                <tr>
                    <th>Recoverable</th>
                    <td>{{vm.recoverable | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Non-Recoverable</th>
                    <td>{{vm.non_recoverable | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Property Management</th>
                    <td>{{vm.recoverable_property_management | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Property management</th>
                    <td>{{vm.non_recoverable_property_management | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Utilities</th>
                    <td>{{vm.recoverable_utilities | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Utilities</th>
                    <td>{{vm.non_recoverable_utilities | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Insurance</th>
                    <td>{{vm.recoverable_insurance | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Insurance</th>
                    <td>{{vm.non_recoverable_insurance | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Property Taxes</th>
                    <td>{{vm.recoverable_property_taxes | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Property Taxes</th>
                    <td>{{vm.non_recoverable_property_taxes | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Mgmt / Admin. / Leasing Expenses</th>
                    <td>{{vm.recoverable_leasing_expenses | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Mgmt / Admin. / Leasing Expenses</th>
                    <td>{{vm.non_recoverable_leasing_expenses | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Maintenance / Operations</th>
                    <td>{{vm.recoverable_maintenance | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Maintenance / Operations</th>
                    <td>{{vm.non_recoverable_maintenance | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Janitorial / Cleaning / Security</th>
                    <td>{{vm.recoverable_janitorial | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Janitorial / Cleaning / Security</th>
                    <td>{{vm.non_recoverable_janitorial | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Business</th>
                    <td>{{vm.recoverable_business | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Business</th>
                    <td>{{vm.non_recoverable_business | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
                <tr>
                    <th>Other</th>
                    <td>{{vm.recoverable_others | mask:'separator.2':{thousandSeparator:','} }}</td>
                    <th class="bl-gainsboro">Other</th>
                    <td>{{vm.non_recoverable_others | mask:'separator.2':{thousandSeparator:','} }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>