// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// State
import {AssetClassDetailState} from '../_reducers/asset-class-detail.reducers';
import * as fromAssetClassDetail from '../_reducers/asset-class-detail.reducers';
import {AssetClassDetailModel} from '../';

export const selectAssetClassDetailState = createFeatureSelector<AssetClassDetailState>('assetClassDetails');

export const selectAssetClassDetailById = (assetClassId: number) => createSelector(
    selectAssetClassDetailState,
    assetClassesState => assetClassesState.entities[assetClassId]
);

export const selectAssetClassDetailByTpId = (tpId: number) => createSelector(
    selectAssetClassDetailState,
    assetClassesState => {

        const items: AssetClassDetailModel[] = [];
        each(assetClassesState.entities, element => {
            if (element.tp_id == tpId) {
                items.push(element);
            }
        });
        return items;
    }
);

export const selectAssetClassDetailActionLoading = createSelector(
    selectAssetClassDetailState,
    assetClassesState => assetClassesState.actionLoading
);

export const selectLastCreatedAssetClassDetailId = createSelector(
    selectAssetClassDetailState,
    assetClassesState => assetClassesState.lastCreatedAssetClassDetailId
);

export const selectAssetClassDetailShowInitWaitingMessage = createSelector(
    selectAssetClassDetailState,
    assetClassesState => assetClassesState.showInitWaitingMessage
);


export const selectAllAssetClassDetail = createSelector(
    selectAssetClassDetailState,
    fromAssetClassDetail.selectAll
);
