import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AssetClassModel, ValuationModel } from "../../asset_class";
import { ToeModel } from "../../toe";
import { CriterionModel } from "../_models/criterion.model";

export enum CriterionActionTypes {
    CreateCriterions = 'Create criterions for valuation',
    CriterionsCreated = 'Criterions for valuation created',
    CriterionsLoading = 'Criterions loading',

    CriteriaDataLoaded = 'Criteria Data loaded',

    AddComparable = 'Comparable data add',
    ComparableAdded = 'Comparable data added',
    RemoveComparable = 'Comparable data remove',
    ComparableRemoved = 'Comparable data removed',

    SetCriterionActive = 'Set active of criterion to true',
    CriterionActivated = 'Criterion is active',
    SetCriterionDeactive = 'Set active of criterion to false',
    CriterionDeactivated = 'Criterion is deactive',

    UpdateCriterion = 'Update Criterion values',
    CriterionUpdated = 'Criterion value is updated',
    AddCriterion = 'Add Custom Criterion',
    CriterionAdded = 'Custom criterion is added',
    DeleteCriterion = 'Delete Custom Criterion',
    ResetCriterion = 'Reset criterion value',
    CriterionResetted = 'Criterion value is resetted',

    RequestCriterions = 'Request criterions from server',
    CriterionsLoaded = 'Criterions from server loaded',

    RestartCriterionState = 'Restart state of criterions',

    AddComparables = 'Multiple comparable data add',
    ComparablesAdded = 'Multiple comparable data added',

    UpdateConsiderationCriterion = 'Update consideration criterion',
    EmptyAction = '[Criterion Effect] emtpy action',

    InsertCriterionsFromTemplate = 'Insert Criterions From Template',
    CriterionsFromTemplateInserted = 'Criterions from template inserted',

    ChangeComparableConsideration = '[Comparable overview dialog] Change Consideration',
    ComparableConsiderationChanged = '[Criterion Effects] Consideration Changed'
}

export class CreateCriterions implements Action {
    readonly type = CriterionActionTypes.CreateCriterions;
    constructor(public payload: {
        assetClass: AssetClassModel,
        toe: ToeModel,
        valuation: ValuationModel
        // agencyID: number,
    }) {}
}

export class CriterionsCreated implements Action {
    readonly type = CriterionActionTypes.CriterionsCreated;
    constructor(public payload: {
        criterions: CriterionModel[]
    }) {}
}

export class CriterionsLoading implements Action {
    readonly type = CriterionActionTypes.CriterionsLoading;
    constructor() {}
}

export class AddComparable implements Action {
    readonly type = CriterionActionTypes.AddComparable;
    constructor(public payload: {
        comparable: any, 
        selectedTenure: number|null,
        targetProperty: AssetClassModel, 
        valuation: ValuationModel
    }) {}
}

export class CriteriaDataLoaded implements Action {
    readonly type = CriterionActionTypes.CriteriaDataLoaded;
    constructor(public payload: {criterions: CriterionModel[], comparables: any[], type_id: number}) {}
}

export class ComparableAdded implements Action {
    readonly type = CriterionActionTypes.ComparableAdded;
    constructor(public payload: {
        criterions: CriterionModel[],
    }) {}
}

export class RemoveComparable implements Action {
    readonly type = CriterionActionTypes.RemoveComparable;
    constructor(public payload: {comparableID: number}) {}
}

export class ComparableRemoved implements Action {
    readonly type = CriterionActionTypes.ComparableRemoved;
    constructor(public payload: {
        criterions: CriterionModel[],
    }) {}
}

export class SetCriterionActive implements Action {
    readonly type = CriterionActionTypes.SetCriterionActive;
    constructor(public payload: {
        criterion: CriterionModel
    }) {}
}

export class CriterionActivated implements Action {
    readonly type = CriterionActionTypes.CriterionActivated;
    constructor(public payload: {
        criterion: Update<CriterionModel>
    }) {}
}

export class SetCriterionDeactive implements Action {
    readonly type = CriterionActionTypes.SetCriterionDeactive;
    constructor(public payload: {
        criterion: CriterionModel
    }) {}
}

export class CriterionDeactivated implements Action {
    readonly type = CriterionActionTypes.CriterionDeactivated;
    constructor(public payload: {
        criterion: Update<CriterionModel>
    }) {}
}

export class UpdateCriterion implements Action {
    readonly type = CriterionActionTypes.UpdateCriterion;
    constructor(public payload: {
        criterion: CriterionModel
    }) {}
}

export class CriterionUpdated implements Action {
    readonly type = CriterionActionTypes.CriterionUpdated;
    constructor(public payload: {
        criterion: Update<CriterionModel>
    }) {}
}

export class AddCriterion implements Action {
    readonly type = CriterionActionTypes.AddCriterion;
    constructor(public payload: {
        criterion: CriterionModel
    }) {}
}

export class CriterionAdded implements Action {
    readonly type = CriterionActionTypes.CriterionAdded;
    constructor(public payload: {
        criterion: CriterionModel
    }) {}
}

export class DeleteCriterion implements Action {
    readonly type = CriterionActionTypes.DeleteCriterion;
    constructor(public payload: {id: number}) {}
}

export class ResetCriterion implements Action {
    readonly type = CriterionActionTypes.ResetCriterion;
    constructor(public payload: {
        criterion: CriterionModel,
        comparables: any[],
        ac: AssetClassModel
    }) {};
}

export class CriterionResetted implements Action {
    readonly type = CriterionActionTypes.CriterionResetted;
    constructor(public payload: {
        criterion: Update<CriterionModel>
    }) {}
}

export class RestartCriterionState implements Action {
    readonly type = CriterionActionTypes.RestartCriterionState;
    constructor() {}
}

export class AddComparables implements Action {
    readonly type = CriterionActionTypes.AddComparables;
    constructor(public payload: {
        comparables: any[],
        tenures: any[],
        targetProperty: AssetClassModel,
        valuation: ValuationModel
    }) {}
}

export class UpdateConsiderationCriterion implements Action {
    readonly type = CriterionActionTypes.UpdateConsiderationCriterion;
    constructor(public payload: {com: any, tpTenureID: number, quote_currency: string}) {}
}

export class CriterionEmptyAction implements Action {
    readonly type = CriterionActionTypes.EmptyAction;
    constructor() {}
}

export class InsertCriterionsFromTemplate implements Action {
    readonly type = CriterionActionTypes.InsertCriterionsFromTemplate;
    constructor(public payload: {
        assetClass: AssetClassModel,
        comparables: any[],
        defaultCriterions: any[],
        customCriterions: any[]
    }) {}
}

export class CriterionsFromTemplateInserted implements Action {
    readonly type = CriterionActionTypes.CriterionsFromTemplateInserted;
    constructor(public payload: {
        criterions: CriterionModel[],
        lastCreatedId: number
    }) {}
}

export class ChangeComparableConsideration implements Action {
    readonly type = CriterionActionTypes.ChangeComparableConsideration;
    constructor(public payload: {
        com: any,
        tpTenureID: number,
        selectedTenure: number,
        quote_currency: string
    }) {}
}

export class ComparableConsiderationChanged implements Action {
    readonly type = CriterionActionTypes.ComparableConsiderationChanged;
    constructor(public payload: {
        criterion: Update<CriterionModel>
    }) {}
}


export type CriterionActions = CreateCriterions 
    | CriterionsCreated
    | CriterionsLoading
    | AddComparable
    | ComparableAdded 
    | RemoveComparable
    | ComparableRemoved
    | SetCriterionActive
    | CriterionActivated
    | SetCriterionDeactive
    | CriterionDeactivated
    | UpdateCriterion
    | CriterionUpdated
    | AddCriterion
    | CriterionAdded
    | CriterionResetted
    | DeleteCriterion
    | RestartCriterionState
    | AddComparables
    | UpdateConsiderationCriterion
    | CriterionEmptyAction
    | InsertCriterionsFromTemplate
    | CriterionsFromTemplateInserted
    | ChangeComparableConsideration
    | ComparableConsiderationChanged;