import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class Rating  {
  rating: string;
  description: string;
  code: string;
  name: string;
  pictures: any[];

  constructor() {
    this.rating = 'N/A';
    this.code = '';
    this.name = '';
    this.description = '';
    this.pictures = [];
  }
}
@Component({
  selector: 'kt-condition-rating-edit',
  templateUrl: './condition-rating-edit.component.html',
  styleUrls: ['./condition-rating-edit.component.scss']
})
export class ConditionRatingEditComponent implements OnInit {
  isShowDesc = false;
  form: UntypedFormGroup;
  rate: Rating;
  hasFormErrors = false;
  floor: string = '';
  constructor(
    private dialogRef: MatDialogRef<ConditionRatingEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) { 
    this.rate = Object.assign({}, this.data.rate);
    this.floor = this.data.floor;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
        rating: [this.rate.rating, Validators.required],
        desc: [this.rate.description]
    });

    if (this.rate.rating == 'Rate 1') {
        this.isShowDesc = true;
    } else if (this.rate.rating == 'Rate 2' || this.rate.rating == 'Rate 3') {
        this.form.controls.desc.setValidators([Validators.required]);
        this.form.controls.desc.updateValueAndValidity();
        this.form.updateValueAndValidity();
        this.isShowDesc = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectionChange() {
    if (this.rate.rating == 'Rate 1') {
        this.form.controls.desc.setValidators(null);
        this.isShowDesc = true;
    } else if (this.rate.rating === 'Rate 2' || this.rate.rating === 'Rate 3') {
        this.form.controls.desc.setValidators([Validators.required]);
        this.isShowDesc = true;
    } else {
        this.form.controls.desc.setValidators(null);
        this.rate.description = '';
        this.isShowDesc = false;
    }
    this.form.controls.desc.updateValueAndValidity();
    this.form.updateValueAndValidity();
  }

  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.form.controls;
    /** check form */
    if (this.isShowDesc && this.form.invalid) {
        Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
        );

        this.hasFormErrors = true;
        return;
    }

    this.dialogRef.close(this.rate);
}
}
