import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllHouseAdjustmentsLoaded,
    AllHouseAdjustmentsRequested,
    HouseAdjustmentActionToggleLoading,
    HouseAdjustmentAdminRestored,
    HouseAdjustmentCreated,
    HouseAdjustmentDeleted,
    HouseAdjustmentDeletedFromAdminTrash,
    HouseAdjustmentDeletedFromTrash,
    HouseAdjustmentOnServerAdminRestored,
    HouseAdjustmentOnServerCreated,
    HouseAdjustmentOnServerRestored,
    HouseAdjustmentRestored,
    HouseAdjustmentsActionTypes,
    HouseAdjustmentsPageLoaded,
    HouseAdjustmentsPageRequested,
    HouseAdjustmentsPageToggleLoading,
    HouseAdjustmentTrashFlushed,
    HouseAdjustmentUpdated
} from '../_actions/house-adjustment.actions';
import {allHouseAdjustmentsLoaded} from '../_selectors/house-adjustment.selectors';
import {HouseAdjustmentsService} from '../_services/house-adjustment.service';

@Injectable()
export class HouseAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: HouseAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new HouseAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new HouseAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new HouseAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllHouseAdjustmentsRequested>(HouseAdjustmentsActionTypes.AllHouseAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allHouseAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllHouseAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentsPageRequested>(HouseAdjustmentsActionTypes.HouseAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new HouseAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentUpdated>(HouseAdjustmentsActionTypes.HouseAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentOnServerCreated>(HouseAdjustmentsActionTypes.HouseAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new HouseAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentTrashFlushed>(HouseAdjustmentsActionTypes.HouseAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentOnServerRestored>(HouseAdjustmentsActionTypes.HouseAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new HouseAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentOnServerAdminRestored>(HouseAdjustmentsActionTypes.HouseAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new HouseAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentDeleted>(HouseAdjustmentsActionTypes.HouseAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentDeletedFromTrash>(HouseAdjustmentsActionTypes.HouseAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<HouseAdjustmentDeletedFromAdminTrash>(HouseAdjustmentsActionTypes.HouseAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}