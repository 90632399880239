<div class="row">
    <div class="col-12" *ngIf="!disable">
        <mat-checkbox [(ngModel)]="isReverse" (change)="changeReverse($event)">
            Reverse System?
        </mat-checkbox>
    </div>
</div>
<div class="row">
    <ng-content></ng-content>
    <div class="col-lg-12 kt-margin-bottom-20-mobile mb-4">
        <agm-map #map
            [latitude]="centerLat$|async"
            [longitude]="centerLng$|async"
            [zoom]="zoom"
            [usePanning]="true"
            [clickableIcons]="false"
            (mapReady)="onMapReady($event)"
            [scrollwheel]="null">
            <agm-map-type-control></agm-map-type-control>
            <button 
                type="button" id="DeleteShape" 
                class="btn btn-white ml-3 mt-1 p-0 map-button" 
                style="width: 30px; height: 26px" 
                (click)="deleteShape()">
                <mat-icon inline>remove_circle_outline</mat-icon>
            </button>
            <agm-marker
                    *ngIf="markerShow"
                    [latitude]="markerLat"
                    [longitude]="markerLng"
                    [markerDraggable]="false"></agm-marker>
        </agm-map>
    </div>
    <div class="col-lg-12 mb-4" style="display: flex; justify-content: end;">
        <button *ngIf="isReverse" mat-raised-button type="button" class="btn btn-primary" color="primary" (click)="addPoint()">
            Add a point
        </button>
    </div>

    <div class="col-lg-12 mb-4">
        <table class="table">
            <thead>
                <tr>
                    <th>
                        No
                    </th>
                    <th>
                        X
                    </th>
                    <th>Y</th>
                    <th>Length ({{staticUnitLengthName}})</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of (datasource|async); let i = index;">
                    <th>
                        {{i + 1}}
                    </th>
                    <td>
                        <ng-container *ngIf="isReverse">
                            <input type="text" [value]="item.point.lat" (focusout)="onFocusOut($event, 'lat', item.point)">
                        </ng-container>
                        <ng-container *ngIf="!isReverse">
                            {{item.point.lat}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="isReverse">
                            <input type="text" [(ngModel)]="item.point.lng" (focusout)="onFocusOut($event, 'lng', item.point)">
                        </ng-container>
                        <ng-container *ngIf="!isReverse">
                            {{item.point.lng}}
                        </ng-container>
                    </td>
                    <td>
                        {{item.length  
                            ? staticUnit == 2 ? item.length * imperial_length : item.length
                            : 'N/A'}}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>
                        Total ({{staticUnitName}})
                    </th>
                    <td colspan="3">
                        <ng-container *ngIf="(area$|async) as area">
                            <ng-container *ngIf="area != null">
                                {{area.toPrecision(6)}} {{staticUnitName}}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(area$|async)">
                            N/A
                        </ng-container>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
