<div class="row">
    <div class="col-12">
        <h5 class="my-3 text-mad text-uppercase">SPECIAL ASSUMPTIONS (PART 2 GLOSSARY, RICS RED BOOK 2022)</h5>
        <hr class="active">
    </div>
</div>
<div class="row" [ngClass]="{'readonly': readonly}">
    <div class="col-12">
        <div class="mb-4">
            <mat-label>
                An assumption that either assumes facts that differ from the actual facts existing at the valuation date or that would not be made by a typical market
                participant
                in a
                transaction on the valuation date.
            </mat-label>
        </div>
    </div>
    <div class="col-12 py-1" *ngFor="let item of specialAssumptionsData; let i = index">
        <mat-checkbox [checked]="item.selected" (change)="changeSelection($event, i)">
            <span>{{item.name}}</span>
        </mat-checkbox>
    </div>
    <div class="col-12">
        <a href="javascript:;" class="bnt bnt-primary mr-2 mt-2" color="primary"
            mat-raised-button
            *ngIf="!readonly"
            matTooltip="Add Special Assumption"
            (click)="addItem()">
            <span>Add Special Assumption</span> 
        </a> 
    </div> 
</div>
