// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {RoofStructure} from '../_models/roof-structure.model';

export enum RoofStructureActionTypes {

    AllRoofStructuresRequested = '[RoofStructures Home Page] All RoofStructures Requested',
    AllRoofStructuresLoaded = '[RoofStructures API] All RoofStructures Loaded',

    RoofStructureOnServerCreated = '[Edit RoofStructure Dialog] RoofStructure On Server Created',
    RoofStructureCreated = '[Edit RoofStructures Dialog] RoofStructures Created',
    RoofStructureUpdated = '[Edit RoofStructure Dialog] RoofStructure Updated',
    RoofStructureDeleted = '[RoofStructures List Page] RoofStructure Deleted',

    RoofStructuresPageRequested = '[RoofStructures List Page] RoofStructures Page Requested',
    RoofStructuresPageLoaded = '[RoofStructures API] RoofStructures Page Loaded',
    RoofStructuresPageCancelled = '[RoofStructures API] RoofStructures Page Cancelled',

    RoofStructuresPageToggleLoading = '[RoofStructures page] RoofStructures Page Toggle Loading',
    RoofStructuresActionToggleLoading = '[RoofStructures] RoofStructures Action Toggle Loading',

    // trash system

    RoofStructureOnServerRestored = '[RoofStructure Trash] RoofStructure On Server Restored',
    RoofStructureRestored = '[RoofStructure Trash] RoofStructure Restored',

    RoofStructureOnServerAdminRestored = '[RoofStructure Admin Trash] RoofStructure On Server Restored',
    RoofStructureAdminRestored = '[RoofStructure Admin Trash] RoofStructure Restored',

    RoofStructureDeletedFromTrash = '[RoofStructure Trash] RoofStructure deleted',
    RoofStructureDeletedFromAdminTrash = '[RoofStructure Admin Trash] RoofStructure deleted',

    RoofStructureTrash = 'RoofStructure Trashed',
    RoofStructureTrashFlushed = 'RoofStructure Trash Flushed',

    RoofStructuresClear = '[RoofStructures API] RoofStructures Page Clear',
}

export class RoofStructureOnServerCreated implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureOnServerCreated;

    constructor(public payload: { roofStructure: RoofStructure}) {
    }
}

export class RoofStructureCreated implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureCreated;

    constructor(public payload: { roofStructure: RoofStructure }) {
    }
}

export class RoofStructureUpdated implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureUpdated;

    constructor(public payload: {
        partialRoofStructure: Update<RoofStructure>,
        roofStructure: RoofStructure
    }) {
    }
}

export class RoofStructureDeleted implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RoofStructuresPageRequested implements Action {
    readonly type = RoofStructureActionTypes.RoofStructuresPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class RoofStructuresPageLoaded implements Action {
    readonly type = RoofStructureActionTypes.RoofStructuresPageLoaded;

    constructor(public payload: { roofStructures: RoofStructure[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class RoofStructuresPageCancelled implements Action {
    readonly type = RoofStructureActionTypes.RoofStructuresPageCancelled;
}

export class AllRoofStructuresRequested implements Action {
    readonly type = RoofStructureActionTypes.AllRoofStructuresRequested;
}

export class AllRoofStructuresLoaded implements Action {
    readonly type = RoofStructureActionTypes.AllRoofStructuresLoaded;

    constructor(public payload: { roofStructures: RoofStructure[] }) {
    }
}

export class RoofStructuresPageToggleLoading implements Action {
    readonly type = RoofStructureActionTypes.RoofStructuresPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RoofStructuresActionToggleLoading implements Action {
    readonly type = RoofStructureActionTypes.RoofStructuresActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class RoofStructureDeletedFromAdminTrash implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class RoofStructureDeletedFromTrash implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RoofStructureOnServerRestored implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RoofStructureRestored implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureRestored;

    constructor(public payload: { item: RoofStructure }) {
    }
}

export class RoofStructureOnServerAdminRestored implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RoofStructureAdminRestored implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureAdminRestored;

    constructor(public payload: { item: RoofStructure }) {
    }
}

export class RoofStructureTrashFlushed implements Action {
    readonly type = RoofStructureActionTypes.RoofStructureTrashFlushed;

    constructor() {
    }
}

export class RoofStructuresClear implements Action {
    readonly type = RoofStructureActionTypes.RoofStructuresClear;

    constructor() {
    }
}

export type RoofStructureActions = RoofStructureCreated
    | RoofStructureUpdated
    | RoofStructureDeleted
    | RoofStructuresPageRequested
    | RoofStructuresPageLoaded
    | RoofStructuresPageCancelled
    | AllRoofStructuresLoaded
    | AllRoofStructuresRequested
    | RoofStructureOnServerCreated
    | RoofStructuresPageToggleLoading
    | RoofStructuresActionToggleLoading
    | RoofStructureDeletedFromAdminTrash
    | RoofStructureDeletedFromTrash
    | RoofStructureOnServerRestored
    | RoofStructureRestored
    | RoofStructureOnServerAdminRestored
    | RoofStructureAdminRestored
    | RoofStructureTrashFlushed
    | RoofStructuresClear;
