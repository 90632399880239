import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { SRERStatus, ValuationSRERModel } from "../_models/valuation-srer.model";
import * as reducer from '../_reducers/valuation-srer.reducer';

export const selectValuationSRERState = createFeatureSelector<reducer.ValuationSRERState>('valuation-srer');
export const selectAll = createSelector(
    selectValuationSRERState,
    state => {
        const items: ValuationSRERModel[] = [];
        each(state.entities, el => items.push(el));
        return items;
    }
)

export const selectSpecialAssumption = createSelector(
    selectAll,
    sers => {
        return sers.map(s => s.specialAssumption).filter(a => a.status == SRERStatus.Amended);
    }
)