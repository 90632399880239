import {MainWallModel} from '../_models/main-wall.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {MainWallsState} from '../_reducers/main-wall.reducer';
import * as fromMainWall from '../_reducers/main-wall.reducer';
import {each} from 'lodash';

export const selectMainWallsState = createFeatureSelector<MainWallsState>('main-walls');

export const selectMainWallById = (id: number) => createSelector(
    selectMainWallsState,
    state => state.entities[id]
);

export const selectAllMainWalls = createSelector(
    selectMainWallsState,
    fromMainWall.selectAll
);

export const selectAllMainWallsIds = createSelector(
    selectMainWallsState,
    fromMainWall.selectIds
);

export const allMainWallsLoaded = createSelector(
    selectMainWallsState,
    state => state.isAllMainWallsLoaded
);


export const selectMainWallsPageLoading = createSelector(
    selectMainWallsState,
    state => state.listLoading
);

export const selectMainWallsActionLoading = createSelector(
    selectMainWallsState,
    state => state.actionLoading
);

export const selectLastCreatedMainWallId = createSelector(
    selectMainWallsState,
    state => state.lastCreatedMainWallId
);

export const selectMainWallsShowInitWaitingMessage = createSelector(
    selectMainWallsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedMainWallCount = createSelector(
    selectMainWallsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedMainWallCount = createSelector(
    selectMainWallsState,
    (state) => state.totalAdminTrashed
);


export const selectMainWallQueryResult = createSelector(
    selectMainWallsState,
    state => {
        const items: MainWallModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: MainWallModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
