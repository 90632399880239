<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{title}}</h3>
        </div>
    </div>
    <div class="form" [formGroup]="form">
        <div class="card-body">

            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Name"
                           formControlName="name"
                           [matAutocomplete]="auto"/>

                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of _options" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>

                    <mat-error>
                        <strong>Name required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput placeholder="Description" rows="5" formControlName="desc">
                    </textarea>
                    <mat-error>
                        <strong>Description required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" (click)="close()">Close</button>&nbsp;
                <button mat-button mat-raised-button
                        color="submit"
                        class="btn btn-success"
                        [disabled]="!isFormValid()"
                        (click)="saveItem()">Save
                </button>&nbsp;
            </div>
        </div>
    </div>
</div>

