<div class="d-flex flex-column">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <button mat-raised-button color="primary" *ngIf="!readonly" (click)="addContact()" type="button">
            {{ 'CONTACT.LIST.BUTTON.ADD.LABEL' | translate }}
        </button>
    </div>

    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <!-- Position Column -->
            <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CONTACT.LIST.DATATABLE.FIRST_NAME' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.first_name}}</mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CONTACT.LIST.DATATABLE.LAST_NAME' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.last_name}}</mat-cell>
            </ng-container>
            <!-- Weight Column -->
            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CONTACT.LIST.DATATABLE.ROLE' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.role}}</mat-cell>
            </ng-container>
            <!-- Symbol Column -->
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CONTACT.LIST.DATATABLE.EMAIL' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.email}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef>{{ 'CONTACT.LIST.DATATABLE.PHONE' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.professional_phone}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>{{ 'CONTACT.LIST.DATATABLE.ACTIONS' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let contact; let i= index">
                    <button mat-icon-button
                            color="primary"
                            [matTooltip]="'CONTACT.LIST.BUTTON.EDIT.TOOLTIP' | translate"
                            [disabled]="readonly"
                            (click)="editContact(contact, i)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <span [matTooltip]="contact.relation_cnt == 0 
                        ? ('CONTACT.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate) 
                        : ('CONTACT.LIST.BUTTON.DELETE.TOOLTIP.CANNOT' | translate)">
                        <button mat-icon-button
                                color="warn"
                                (click)="deleteContact(contact, i)"
                                [disabled]="readonly || contact.relation_cnt != 0"
                                type="button">
                        <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                    </span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>