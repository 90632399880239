import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({providedIn: 'root'})
export class TimeZoneApiService {
  constructor(private httpClient: HttpClient) {}

  timeZoneOf({lat, lng}: {lat: number, lng: number}): Observable<string|null> {
    const time = Math.floor(Date.now() / 1000)
    return this.httpClient.get<{rawOffset: number}>(`https://maps.googleapis.com/maps/api/timezone/json`, {
      params: new HttpParams({fromObject: {
        location: `${lat},${lng}`,
        timestamp: time,
        key: environment.agMapKey
      }})
    }).pipe(
      map(({rawOffset}) => rawOffset / (60 * 60)),
      map(offset => {
        if (!offset) {
          return null
        }
        let offsetStr = offset < 0 ? offset.toString() : ("+" + offset.toString())
        if (Math.abs(offset) < 10) {
          if (offset < 0) {
            offsetStr = "-0" + Math.abs(offset).toString()
          } else {
            offsetStr = "+0" + offset.toString()
          }
        }
        return offsetStr + ":00"
      })
    )
  }
}