import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AssetClassRetailBuildingActionToggleLoading, AssetClassRetailBuildingActionTypes, AssetClassRetailBuildingAdminRestored, AssetClassRetailBuildingCreated, AssetClassRetailBuildingDeleted, AssetClassRetailBuildingDeletedFromAdminTrash, AssetClassRetailBuildingDeletedFromTrash, AssetClassRetailBuildingDuplicate, AssetClassRetailBuildingOnServerAdminRestored, AssetClassRetailBuildingOnServerCreated, AssetClassRetailBuildingOnServerRestored, AssetClassRetailBuildingOnServerUpdated, AssetClassRetailBuildingPageLoaded, AssetClassRetailBuildingPageRequested, AssetClassRetailBuildingPageToggleLoading, AssetClassRetailBuildingRestored, AssetClassRetailBuildingTrashFlushed, AssetClassRetailBuildingUpdated } from "../_actions/asset-class-retail-building.actions";
import { AssetClassRetailBuildingService } from "../_services/asset-class-retail-building.service";

@Injectable()
export class AssetClassRetailBuildingEffects {
    showPageLoadingDispatcher = new AssetClassRetailBuildingPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassRetailBuildingActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassRetailBuildingActionToggleLoading({isLoading: false});

    constructor(
        private actions$: Actions,
        private service: AssetClassRetailBuildingService,
        private store: Store<AppState>
    ) {}

    @Effect()
    loadAssetClassRetailBuildingPage$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingPageRequested>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingPageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery = response[1];
            return new AssetClassRetailBuildingPageLoaded({
                AssetClassRetailBuildings: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            })
        })
    )

    @Effect()
    createAssetClassRetailBuilding$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingOnServerCreated>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                payload.AssetClassRetailBuilding,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailBuildingCreated({AssetClassRetailBuilding: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    updateAssetClassRetailBuilding$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingOnServerUpdated>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateAssetClass(
                payload.AssetClassRetailBuilding,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailBuildingUpdated(
                        {AssetClassRetailBuilding: res.data}
                    ))
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassRetailBuilding$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingDeleted>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteAssetClass(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteAssetClassRetailBuildingFromTrash$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingDeletedFromTrash>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeletedFromTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAssetClassRetailBuilding$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingOnServerRestored>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailBuildingRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAdminAssetClassRetailBuilding$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingOnServerAdminRestored>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerAdminRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailBuildingAdminRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassRetailBuildingFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassRetailBuildingDeletedFromAdminTrash>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTrash$ = this.actions$
        .pipe(
            ofType<AssetClassRetailBuildingTrashFlushed>(AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    duplicateRetailBuilding$ = this.actions$.pipe(
        ofType<AssetClassRetailBuildingDuplicate>(AssetClassRetailBuildingActionTypes.DuplicateAssetClass),
        mergeMap(({payload}) => {
            return this.service.duplicate(payload.id)
        }),
        map((response) => {
            return new AssetClassRetailBuildingCreated({AssetClassRetailBuilding: response.data})
        })
    )
}