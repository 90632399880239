import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { TemplateRequest, CriterionsTemplateModel } from "../_models";

export enum CriterionsTemplateActionTypes {
    LOAD_CRITERIONS_TEMPLATES = '[Criterions Template List] Load Criterions Templates',
    CRITERIONS_TEMPLATES_LOADED = '[Criterions Template Effect] Criterions Templates Loaded',

    ADD_CRITERIONS_TEMPLATE = '[Criterions Template Edit] Add New Criterions Template',
    CRITERIONS_TEMPLATE_ADDED = '[Tp Tempalte Effect] New Criterions Template Added',
     
    UPDATE_CRITERIONS_TEMPLATE = '[Criterions Template Edit] Update Criterions Template',
    CRITERIONS_TEMPLATE_UPDATED = '[Criterions Template Effect] Criterions Template Updated',

    DELETE_CRITERIONS_TEMPLATE = '[Criterions Template List] Delete Criterions Template',
    CRITERIONS_TEMPLATE_DELETED = '[Criterions Template Effect] Criterions Template Deleted',

    DUPLICATE_CRITERIONS_TEMPLATE = '[Criterions Template List] Duplicate Criterions Template',
}

export class LoadCriterionsTemplates implements Action {
    readonly type = CriterionsTemplateActionTypes.LOAD_CRITERIONS_TEMPLATES;
    constructor(public payload: {
        request: TemplateRequest
    }) {}
}

export class CriterionsTemplatesLoaded implements Action {
    readonly type = CriterionsTemplateActionTypes.CRITERIONS_TEMPLATES_LOADED;
    constructor(public payload: {
        templates: CriterionsTemplateModel[],
        total: number
    }) {}
}

export class AddCriterionsTemplate implements Action {
    readonly type = CriterionsTemplateActionTypes.ADD_CRITERIONS_TEMPLATE;
    constructor(public payload: {
        template: CriterionsTemplateModel
    }) {}
}

export class CriterionsTemplateAdded implements Action {
    readonly type = CriterionsTemplateActionTypes.CRITERIONS_TEMPLATE_ADDED;
    constructor(public payload: {
        template: CriterionsTemplateModel
    }) {}
}

export class UpdateCriterionsTemplate implements Action {
    readonly type = CriterionsTemplateActionTypes.UPDATE_CRITERIONS_TEMPLATE;
    constructor(public payload: {
        id: number;
        template: CriterionsTemplateModel
    }) {}
}

export class CriterionsTemplateUpdated implements Action {
    readonly type = CriterionsTemplateActionTypes.CRITERIONS_TEMPLATE_UPDATED;
    constructor(public payload: {
        id: number;
        template: Update<CriterionsTemplateModel>
    }) {}
}

export class DeleteCriterionsTemplate implements Action {
    readonly type = CriterionsTemplateActionTypes.DELETE_CRITERIONS_TEMPLATE;
    constructor(public payload: {
        id: number,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export class CriterionsTemplateDeleted implements Action {
    readonly type = CriterionsTemplateActionTypes.CRITERIONS_TEMPLATE_DELETED;
    constructor(public payload: {id: number}) {}
}

export class DuplicateCriterionsTemplate implements Action {
    readonly type = CriterionsTemplateActionTypes.DUPLICATE_CRITERIONS_TEMPLATE;
    constructor(public payload: {
        template: CriterionsTemplateModel,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export type CriterionsTemplateActions
    = LoadCriterionsTemplates
    | CriterionsTemplatesLoaded
    | AddCriterionsTemplate
    | CriterionsTemplateAdded
    | UpdateCriterionsTemplate
    | CriterionsTemplateUpdated
    | DeleteCriterionsTemplate
    | CriterionsTemplateDeleted
    | DuplicateCriterionsTemplate;