// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ClientActions, ClientActionTypes} from '../_actions/client.actions';
// Models
import {Client} from '../_models/client.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ClientsState extends EntityState<Client> {
    isAllClientsLoaded: boolean;
    queryRowsCount: number;
    queryResult: Client[];
    lastCreatedClientId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    totalTrashed: number;
    totalAdminTrashed: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Client> = createEntityAdapter<Client>();

export const initialClientsState: ClientsState = adapter.getInitialState({
    isAllClientsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedClientId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function clientsReducer(state = initialClientsState, action: ClientActions): ClientsState {
    switch (action.type) {
        case ClientActionTypes.ClientsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedClientId: undefined
            };

        case ClientActionTypes.ClientsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };

        case ClientActionTypes.ClientOnServerCreated:
            return {
                ...state
            };

        case ClientActionTypes.ClientCreated:
            return adapter.addOne(action.payload.client, {
                ...state, lastCreatedClientId: action.payload.client.id
            });

        case ClientActionTypes.ClientUpdated:
            return adapter.updateOne(action.payload.partialClient, state);

        case ClientActionTypes.ClientDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ClientActionTypes.AllClientsLoaded:
            return adapter.addAll(action.payload.clients, {
                ...state, isAllClientsLoaded: true
            });

        case ClientActionTypes.ClientsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };

        case ClientActionTypes.ClientsPageLoaded:
            return adapter.addMany(action.payload.clients, {
                ...initialClientsState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.clients,
                lastQuery: action.payload.page,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                showInitWaitingMessage: false
            });

        case ClientActionTypes.ClientRestored:
            return adapter.addOne(action.payload.client, {
                ...state,
                lastCreatedClientId: action.payload.client.id,
                totalTrashed: state.totalTrashed - 1
            });

        case ClientActionTypes.ClientDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            };

        case ClientActionTypes.ClientTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case ClientActionTypes.ClientAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state,
                lastCreatedClientId: action.payload.item.id,
                totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ClientActionTypes.ClientDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };


        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
