import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { AppState } from "../../reducers";
import { QueryParamsModel } from "../../_base/crud";
import { AllResidentialAdjustmentsLoaded, AllResidentialAdjustmentsRequested, ResidentialAdjustmentActionToggleLoading, ResidentialAdjustmentAdminRestored, ResidentialAdjustmentCreated, ResidentialAdjustmentDeleted, ResidentialAdjustmentDeletedFromAdminTrash, ResidentialAdjustmentDeletedFromTrash, ResidentialAdjustmentOnServerAdminRestored, ResidentialAdjustmentOnServerCreated, ResidentialAdjustmentOnServerRestored, ResidentialAdjustmentRestored, ResidentialAdjustmentsActionTypes, ResidentialAdjustmentsPageLoaded, ResidentialAdjustmentsPageRequested, ResidentialAdjustmentsPageToggleLoading, ResidentialAdjustmentTrashFlushed, ResidentialAdjustmentUpdated } from "../_actions/residential-adjustments.actions";
import { allResidentialAdjustmentsLoaded } from "../_selectors/residential-adjustments.selectors";
import { ResidentialAdjustmentsService } from "../_services/residential-adjustments.service";

@Injectable()
export class ResidentialAdjustmentsEffects {
    constructor(
        private actions$: Actions, 
        private service: ResidentialAdjustmentsService, 
        private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new ResidentialAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ResidentialAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ResidentialAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllResidentialAdjustmentsRequested>(ResidentialAdjustmentsActionTypes.AllResidentialAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allResidentialAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllResidentialAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentsPageRequested>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ResidentialAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentUpdated>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentOnServerCreated>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ResidentialAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentTrashFlushed>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentOnServerRestored>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ResidentialAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentOnServerAdminRestored>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ResidentialAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentDeleted>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentDeletedFromTrash>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<ResidentialAdjustmentDeletedFromAdminTrash>(ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}