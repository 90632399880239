import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../../_base/crud';
import { Observable } from 'rxjs';
import { AssetClassSourceExternalReferenceModel } from '../_models/asset-class-source-external-reference.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class AssetClassSERService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/asset_class_ser`;
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    getAllSER(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }

    getSERByTpId(_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_URL + '/' + _id;
        return this.http.get<any>(url, { headers: httpHeaders });
    }

    updateSER(_assetClassSER: AssetClassSourceExternalReferenceModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            assetClassSER: _assetClassSER
        };
        const url = this.API_URL + `/${_assetClassSER.id}`;
        return this.http.put(url, body, {headers: httpHeaders});
    }
}
