import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AppState } from '../../../../../core/reducers';
import {
    AssetClassOfficesService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassOfficeGetResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public assetClassOfficesService: AssetClassOfficesService,
                private activatedRoute: ActivatedRoute) {}
  
    resolve(route: ActivatedRouteSnapshot) {
        const office_id = Number(route.params.office_id);
        return this.assetClassOfficesService.getAssetClassOffice(office_id);
    }
}
