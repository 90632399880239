import { Injectable } from "@angular/core";
import { GeneralSettingsService } from "../general-settings/general-settings.service";
import { map, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { BehaviorSubject, combineLatest } from "rxjs";
import { currentUser } from "../mad-auth/mad-auth.store";
import { environment } from "src/environments/environment";
import { selectSystemMapLocation } from "../general-settings/general-settings.store";

@Injectable({
    providedIn: 'root'
})
export class MapsService {
    private location$ = combineLatest([
        this.store.select(currentUser),
        this.store.select(selectSystemMapLocation)
    ]).pipe(
        map(([user, location]) => {
            // return location
            if (!user) {
                return location
            }
            if (user.settings.use_system_location) {
                return location
            }
            if (user.settings.latitude && user.settings.longitude) {
                return {
                    latitude: user.settings.latitude,
                    longitude: user.settings.longitude
                }
            }
            return location
        }),
    )

    latitude$ = this.location$.pipe(
        map(location => location.latitude)
    )
    longitude$ = this.location$.pipe(
        map(location => location.longitude)
    )

    // Custom Markers
    private _icon$ = new BehaviorSubject<{
        path: string,
        fillColor: string,
        fillOpacity: number,
        strokeColor: string,
        strokeWeight: number,
        scale: number,
        labelOrigin: google.maps.Point
    }>({
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: '#2DEBA9',
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 1,
        scale: 1.2,
        labelOrigin: new google.maps.Point(0, -30)
    })
    private _label$ = new BehaviorSubject<{
        text: string,
        fontFamily: string,
        fontWeight: string,
        fontSize: string
    }>({
        text: 'TP',
        fontFamily: '',
        fontWeight: 'bold',
        fontSize: '14px',
    })
    icons$ = this._icon$.asObservable()
    label$ = this._label$.asObservable()

    constructor(
        private store: Store<AppState>
    ) {}
}