import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'kt-accommodation-layout-modal',
  templateUrl: './accommodation-layout-modal.component.html',
  styleUrls: ['./accommodation-layout-modal.component.scss']
})
export class AccommodationLayoutModalComponent implements OnInit {
  @Input()
  inputData: {
    propertySubTypeID: number,
    aboveFloor: number,
    belowFloor: number,
    accommodationLayout: any,
    floors: number[]
  }

  aboveFloor: Observable<number>;
  belowFloor: Observable<number>;
  floors: Observable<number[]>;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.aboveFloor = of(this.inputData.aboveFloor);
    this.belowFloor = of(this.inputData.belowFloor);
    this.floors = of(this.inputData.floors.sort());
  }

  close() {
    this.activeModal.close();
  }

}
