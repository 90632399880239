<div class="card card-custom section-margin-bottom-60">
    <ng-container *ngIf="viewModel$|async as viewModel">
    <div class="card-header adjustment-header">
        <div class="card-title">
            <div class="card-label">
                <span>Adjustments</span>
            </div>
            <mat-icon style="cursor: pointer; display: flex; align-items: center;" container="body"
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.ADJUSTMENTS.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.ADJUSTMENTS.TITLE'|translate">
                help
            </mat-icon>
        </div>
        <div class="card-toolbar">
            <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline">
                <button ngbDropdownToggle class="btn btn-label-brand btn-bold btn-sm dropdown-toggle"
                    style="cursor: pointer !important;">Add</button>
                <div ngbDropdownMenu style="width: 230px;">
                    <ul class="navi navi-hover">
                        <li class="navi-item">
                            <a class="navi-link" href="javascript:;" (click)="onAddAdjustment(viewModel.refNums, viewModel.selectedIds, viewModel.propertySubTypeId)">
                                <span class="navi-icon">
                                    <i class="flaticon2-add"></i>
                                </span>
                                <span class="navi-text">
                                    Add Adjustment
                                </span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a class="navi-link" href="javascript:;" (click)="onAddAdjustmentFromTemplate(viewModel.propertySubTypeId, viewModel.refNums)">
                                <span class="navi-icon">
                                    <i class="flaticon2-add"></i>
                                </span>
                                <span class="navi-text">
                                    Add from template
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body adjustment-body">
            <div class="col-md-12 mat-table-wrapper">
                <table mat-table class="lmat-elevation-z8" [dataSource]="viewModel.dataSource">
                    <!-- Headers -->
                    <ng-container [matColumnDef]="'input-'+viewModel.refNums.length+'-header'">
                        <th mat-header-cell *matHeaderCellDef>Quantitative Inputs
                        </th>
                    </ng-container>

                    <ng-container *ngFor="let refNum of viewModel.refNums; let i = index"
                        [matColumnDef]="'com-' + i + '-' + viewModel.refNums.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            <span class="cell">
                                {{refNum}}
                                <span>
                                    <button mat-icon-button color="primary" (click)="onShowComparable(refNum)">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn"
                                        (click)="onRemoveComparable(refNum)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                            </span>
                        </th>
                    </ng-container>

                    <ng-container [matColumnDef]="'analysis-' + viewModel.refNums.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            Comparative Analysis
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="action-header">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                    </ng-container>

                    <!-- Cells -->
                    <ng-container matColumnDef="adjustment">
                        <td mat-cell *matCellDef="let _item">
                            {{_item.name ? _item.name : 'Not defined'}}
                            <ng-template #popTitle>
                                Description of the adjustment
                            </ng-template>
                            <ng-template #popContent>
                                <div>
                                    {{_item.description}}
                                </div>
                            </ng-template>

                            <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                (click)="$event.stopPropagation()" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                                <mat-icon>info</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container *ngFor="let refNum of viewModel.refNums; let i = index"
                        [matColumnDef]="'com-' + i + '-' + viewModel.refNums.length">

                        <td mat-cell *matCellDef="let _item, let ii = index">
                            <kt-percent-input-v2 
                                [value]="_item.values[refNum]"
                                [adjustmentName]="_item.name"
                                [refNum]="refNum"
                                (onPercentChange)="onPercentChange($event, _item, refNum)"
                            ></kt-percent-input-v2>
                            <!-- <kt-simple-percent-input
                                [value]="_item.values[refNum]"
                                [decreaseButtonTooltip]="_item.name + ' of Target Property less desirable than Comparable ' + refNum"
                                [increaseButtonTooltip]="_item.name + ' of Target Property more desirable than Comparable ' + refNum"
                                [min]="-100"
                                [threshold]="0"
                                (onPercentChange)="onPercentChange($event, _item, refNum)"></kt-simple-percent-input> -->
                        </td>
                    </ng-container>

                    <ng-container [matColumnDef]="'analysis-' + viewModel.refNums.length">
                        <td mat-cell *matCellDef="let _item; let ii = index">
                            <textarea class="inline-input" [value]="_item.analysis" (keyup)="textAreaAdjustOnKeyUp($event)"
                                (focusout)="onFocusOutAnalysis($event, _item)"
                                ></textarea>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <td mat-cell *matCellDef="let _item">
                            <button  mat-icon-button color="primary"
                                (click)="onEditAdjustment(_item, viewModel.propertySubTypeId)">
                                <mat-icon>create</mat-icon>
                            </button>

                            <button  mat-icon-button color="warn"
                                (click)="onDeleteAdjustment(_item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="viewModel.headerColumns "></tr>
                    <tr mat-row *matRowDef="let row; columns: viewModel.displayedColumns; let i = index"
                        [ngClass]="{gray: i%2}"></tr>
                </table>
            </div>
    </div>
        </ng-container>
</div>