// NGRX
import {Action} from '@ngrx/store';
// Models
import {AgencyModel} from '../_models/agency.model';
import {Update} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';

export enum AgencyActionTypes {
    AgencyOnServerCreated = '[Edit Agency Dialog] Agency On Server Created',
    AgencyCreated = '[Edit Agency Dialog] Agency Created',

    AgencyOnServerRestored = '[Agency Trash] Agency On Server Restored',
    AgencyRestored = '[Agency Trash] Agency Restored',

    AgencyOnServerAdminRestored = '[Agency Admin Trash] Agency On Server Restored',
    AgencyAdminRestored = '[Agency Admin Trash] Agency Restored',

    AgencyDeletedFromTrash = '[Agency Trash] Agency deleted',
    AgencyDeletedFromAdminTrash = '[Agency Admin Trash] Agency deleted',

    AgencyTrash = 'Agency Trashed',
    AgencyTrashFlushed = 'Agency Trash Flushed',

    AgencyUpdated = '[Edit Agency Dialog] Agency Updated',
    AgencyDeleted = '[Agencies List Page] Agency Deleted',

    AllAgenciesRequested = '[Agencies] All Agencies Requested',

    AgenciesPageRequested = '[Agencies List Page] Agencies Page Requested',
    AgenciesPageLoaded = '[Agencies API] Agencies Page Loaded',

    OneAgencyRequest = '[Agencies API] One Agency Request',
    OneAgencyLoaded = '[Agencies API] One Agency Loaded',

    AgenciesPageCancelled = '[Agencies API] Agencies Page Cancelled',
    AgenciesPageToggleLoading = '[Agencies] Agencies Page Toggle Loading',
    AgencyActionToggleLoading = '[Agencies] Agencies Action Toggle Loading'
}

// list action
export class AgenciesPageRequested implements Action {
    readonly type = AgencyActionTypes.AgenciesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

// list action
export class AllAgenciesRequested implements Action {
    readonly type = AgencyActionTypes.AllAgenciesRequested;

    constructor() {
    }
}

export class AgenciesPageLoaded implements Action {
    readonly type = AgencyActionTypes.AgenciesPageLoaded;

    constructor(public payload: {
        items: AgencyModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}


export class AgencyTrashRequested implements Action {
    readonly type = AgencyActionTypes.AgencyTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash
export class AgencyTrashFlushed implements Action {
    readonly type = AgencyActionTypes.AgencyTrashFlushed;

    constructor() {
    }
}

export class AgencyDeletedFromTrash implements Action {
    readonly type = AgencyActionTypes.AgencyDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AgencyDeletedFromAdminTrash implements Action {
    readonly type = AgencyActionTypes.AgencyDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AgencyOnServerRestored implements Action {
    readonly type = AgencyActionTypes.AgencyOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AgencyRestored implements Action {
    readonly type = AgencyActionTypes.AgencyRestored;

    constructor(public payload: { item: AgencyModel }) {
    }
}

export class AgencyOnServerAdminRestored implements Action {
    readonly type = AgencyActionTypes.AgencyOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AgencyAdminRestored implements Action {
    readonly type = AgencyActionTypes.AgencyAdminRestored;

    constructor(public payload: { item: AgencyModel }) {
    }
}


// toggle loading
export class AgenciesPageToggleLoading implements Action {
    readonly type = AgencyActionTypes.AgenciesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AgencyActionToggleLoading implements Action {
    readonly type = AgencyActionTypes.AgencyActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export class AgencyCreated implements Action {
    readonly type = AgencyActionTypes.AgencyCreated;

    constructor(public payload: { item: AgencyModel }) {
    }
}

export class AgencyOnServerCreated implements Action {
    readonly type = AgencyActionTypes.AgencyOnServerCreated;

    constructor(public payload: { item: AgencyModel}) {
    }
}

export class AgencyUpdated implements Action {
    readonly type = AgencyActionTypes.AgencyUpdated;

    constructor(public payload: {
        partialItem: Update<AgencyModel>,
        item: AgencyModel
    }) {
    }
}


export class AgencyDeleted implements Action {
    readonly type = AgencyActionTypes.AgencyDeleted;

    constructor(public payload: {
        id: number
    }) {
    }
}


export type AgencyActions = AgencyOnServerCreated |
    AgencyCreated |

    AgencyUpdated |
    AgencyDeleted |

    AgencyOnServerRestored |
    AgencyRestored |

    AgencyOnServerAdminRestored |
    AgencyAdminRestored |

    AgencyDeletedFromTrash |
    AgencyDeletedFromAdminTrash |

    AllAgenciesRequested |
    AgenciesPageRequested |

    AgencyTrashRequested |
    AgencyTrashFlushed |

    AgenciesPageToggleLoading |
    AgencyActionToggleLoading |
    AgenciesPageLoaded;
