import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AssetClassRetailShopService } from 'src/app/core/comparable/_services/asset-class-retail-shop.service';
import {
    AssetClassRetailsService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassRetailGetResolver implements Resolve<any> {
    constructor(public assetClassRetailsService: AssetClassRetailShopService) {}
  
    resolve(route: ActivatedRouteSnapshot) {
        const retail_id = Number(route.params.retail_id);
        return this.assetClassRetailsService.getAssetClass(retail_id);
    }
}
