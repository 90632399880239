<div class="mat-table-wrapper mt-5">
    <table class="table">
        <thead>
            <th style="width: 20%;"></th>
            <th style="width: 40%;">Observed current use of the subject property</th>
            <th style="width: 40%;">Evidence seen of such uses having taken place within the viciniy</th>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of data; let i = index">
                <ng-container *ngTemplateOutlet="tablerow;context:{item: item, isGray: i%2==0}"></ng-container>
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #tablerow let-item="item" let-isGray="isGray">
    <tr [ngClass]="{'gray': isGray}">
        <th rowspan="2">{{item.name}}</th>
        <td class="current_use" [rowSpan]="item.es_val && !item.cu_val ? 2 : 1">
            <mat-checkbox [(ngModel)]="item.cu_val"></mat-checkbox>
        </td>
        <td [rowSpan]="item.cu_val && !item.es_val ? 2 : 1">
            <mat-checkbox [(ngModel)]="item.es_val"></mat-checkbox>
        </td>
    </tr>
    <tr>
        <td class="current_use" *ngIf="item.cu_val">
            <mat-form-field appearance="fill" class="mat-form-field-fluid">
                <textarea placeholder="Notes or other observations" matInput row=2 [(ngModel)]="item.cu_desc"></textarea>
            </mat-form-field>
        </td>
        <td *ngIf="item.es_val">
            <mat-form-field appearance="fill" class="mat-form-field-fluid">
                <textarea placeholder="Notes or other observations" matInput row=2 [(ngModel)]="item.es_desc"></textarea>
            </mat-form-field>
        </td>
    </tr>
</ng-template>