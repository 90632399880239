<div class="size-table-container">
    <kt-ipms-standard-measurement-component
        *ngIf="table == 'ipms'"
        [assetClassID]="assetClassID"
        [sizeChanges$]="sizeChange$"
        [readonly]="readonly"
        [floorAction$]="floorAction$">
    </kt-ipms-standard-measurement-component>
    <kt-mongolia-standard-table
        *ngIf="table == 'mongolianStandard' && (unitAreaMeasurement$ | async)"
        [assetClassID]="assetClassID"
        [standardMeasurement]="smID"
        [floorAction$]="floorAction$"
        [unitAreaMeasurement$]="unitAreaMeasurement$"
        [externalAreas$]="externalAreas$"
        [bedrooms$]="bedroom$"
        [roomChange$]="roomChange$"
        [readonly]="readonly"
        >
    </kt-mongolia-standard-table>
</div>