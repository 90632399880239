import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';

@Component({
  selector: 'kt-size-table-modal',
  templateUrl: './size-modal.component.html',
  styleUrls: ['./size-modal.component.scss']
})
export class SizeTableModalComponent implements OnInit {
  floorAction$ = of(null);
  constructor(
    public dialogRef: MatDialogRef<SizeTableModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }
}
