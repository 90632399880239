import { Inject, Injectable } from "@angular/core";

@Injectable()
export class ReadonlyService {
    public isReadOnly(): boolean {
        const val = localStorage.getItem('readonly');
        const isReadonly = val == 'true';
        return isReadonly;
    } 
    public setReadOnly(val: boolean) {
        localStorage.setItem('readonly', String(val));
    }
}