import {BaseModel} from '../../_base/crud';

export class Appendix extends BaseModel {
    id: number;
    name: string;
    description: string;
    cat_id: number;

    path: string;
    appendix_file: any[];

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    user_deleted: Date;

    deletedBy: string;
    userDeletedBy: string;

    createdBy: string;
    lastUpdatedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.description = '';
        this.cat_id = undefined;

        this.path = '';
        this.appendix_file = [];

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
        this.user_deleted = null;

        this.deletedBy = '';
        this.userDeletedBy = '';
    }
}
