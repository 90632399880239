// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TpTaskModel} from '../_models/tp-task.model';

export enum TpTaskActionTypes {
    AllTpTaskRequested = '[Init] All TpTask Requested',
    AllTpTaskLoaded = '[Init] All TpTask Loaded',
    AllTpTaskLoadedByUser = '[Init] All TpTask Loaded By User',
    TpTaskCleared = '[Init] All TpTask cleared',

    TpTaskOnServerCreated = '[Edit TpTask Dialog] TpTask On Server Created',
    TpTaskCreated = '[Edit TpTask Dialog] TpTask Created',
    TpTaskUpdated = '[Edit TpTask Dialog] TpTask Updated',

    TpTaskActionToggleLoading = '[TpTask] TpTask Action Toggle Loading',

    TpTaskDeleted = '[TpTask List Page] TpTask Deleted',

    AllTpTaskByUserRequested = 'All TpTask By User Requested',
    TpTaskByUserPageRequested = 'All TpTask By User Page Requested',
    TpTaskByUserPageLoaded = 'All TpTask By User Page Loaded',
    TpTasksClear = 'clear tasks',

    ManualTpTaskOnServerUpdated = '[Tp Task Tables] Manual Tp Task Updated',
    ManualTpTaskUpdated = '[TpTask Effects] Manual Tp Task updated',

    UpdateSignedDocUploadTask = '[Signed Report effect] Update signed doc upload task',

    BulkUpdateTpTasks = '[Pm Edit Dialog] update multiple similar tasks',
    TpTaskBulkUpdated = '[TpTaskEffects] tasks bulkely updated',

    DueDiligenceTpTaskOnServerUpdated = '[Tp Task Tables] Due Diligence Tp Task Updated',
    DueDiligenceTpTaskUpdated = '[TpTask Effects] Due Diligence Tp Task Updated',

    AddValuationTaskScenario = '[Tp Task Tables] Add Scenario to Valuation Task',
    ValuationTaskScenarioAdded = '[TpTask Effects] Scenario for Valuation Task Added',

    DeleteValuationTaskScenario = '[Tp Task Table] Delete Scenario from Valuation task',
    ValuationTaskScenarioDeleted = '[Tp Task Effects] Scenario for Valuation Task Deleted',

    UpdateMethodOfValuationTask = '[Tp Task Table] Update method of valuation task income',
    ValuationTaskMethodUpdated = '[Tp Task Effects] Valuation task income method updated'
}

export class BulkUpdateTpTasks implements Action {
    readonly type = TpTaskActionTypes.BulkUpdateTpTasks;
    constructor(public payload: {
        id: number,
        user_id: number,
        user_name: string,
        date: string,
        tp_id: number,
        task_id: number,
    }) {}
}

export class TpTasksBulkUpdated implements Action {
    readonly type = TpTaskActionTypes.TpTaskBulkUpdated;
    constructor(public payload: {
        updates: Update<TpTaskModel>[]
    }) {}
}

export class TpTaskCleared implements Action {
    readonly type = TpTaskActionTypes.TpTaskCleared;
}

export class AllTpTaskRequested implements Action {
    readonly type = TpTaskActionTypes.AllTpTaskRequested;
    constructor(public payload?: {toeID?: number, tpId?: number}) {}
}

export class AllTpTaskLoaded implements Action {
    readonly type = TpTaskActionTypes.AllTpTaskLoaded;

    constructor(public payload: { 
        tasks: TpTaskModel[],
    }) {
    }
}

export class AllTpTaskLoadedByUser implements Action {
    readonly type = TpTaskActionTypes.AllTpTaskLoadedByUser;

    constructor(public payload: { 
        tasks: TpTaskModel[],
        totalCount: number,
        page: QueryParamsModel,
    }) {
    }
}


export class TpTaskOnServerCreated implements Action {
    readonly type = TpTaskActionTypes.TpTaskOnServerCreated;

    constructor(public payload: { tp_id: number, task: TpTaskModel }) {
    }
}

export class TpTaskCreated implements Action {
    readonly type = TpTaskActionTypes.TpTaskCreated;

    constructor(public payload: { task: TpTaskModel }) {
    }
}

export class TpTaskUpdated implements Action {
    readonly type = TpTaskActionTypes.TpTaskUpdated;

    constructor(public payload: {
        partialItem: Update<TpTaskModel>,
        item: TpTaskModel
    }) {
    }
}

export class TpTaskDeleted implements Action {
    readonly type = TpTaskActionTypes.TpTaskDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class TpTaskActionToggleLoading implements Action {
    readonly type = TpTaskActionTypes.TpTaskActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AllTpTaskByUserRequested implements Action {
    readonly type = TpTaskActionTypes.AllTpTaskByUserRequested;

    constructor(public payload: { page: QueryParamsModel, user_id: number }) {
    }
}

export class TpTaskByUserPageRequested implements Action {
    readonly type = TpTaskActionTypes.TpTaskByUserPageRequested;

    constructor(public payload: { page: QueryParamsModel, user_id: number }) {
    }
}

export class TpTaskByUserPageLoaded implements Action {
    readonly type = TpTaskActionTypes.TpTaskByUserPageLoaded;

    constructor(public payload: {
        assetClasses: TpTaskModel[],
        totalCount: number,
        page: QueryParamsModel
    }) {
    }
}

export class TpTasksClear implements Action {
    readonly type = TpTaskActionTypes.TpTasksClear;

    constructor() {
    }
}

export class ManualTpTaskOnServerUpdated implements Action {
    readonly type = TpTaskActionTypes.ManualTpTaskOnServerUpdated;
    constructor (public payload: {
        id: number,
        completed: boolean,
        complated_at: Date
    }) {}
}
export class ManualTpTaskUpdated implements Action {
    readonly type = TpTaskActionTypes.ManualTpTaskUpdated;
    constructor (public payload: {
        item: TpTaskModel
    }) {}
}

export class DueDiligenceTpTaskOnServerUpdated implements Action {
    readonly type = TpTaskActionTypes.DueDiligenceTpTaskOnServerUpdated;
    constructor (public payload: {id: number, completed: boolean, completed_at: Date}) {}
}
export class DueDiligenceTpTaskUpdated implements Action {
    readonly type = TpTaskActionTypes.DueDiligenceTpTaskUpdated;
    constructor (public payload: {item: TpTaskModel}) {}
}

export class UpdateSignedDocUploadTask implements Action {
    readonly type = TpTaskActionTypes.UpdateSignedDocUploadTask; 
    constructor(public payload: {
        partial: Update<TpTaskModel>
    }) {}
}

export class AddValuationTaskScenario implements Action {
    readonly type = TpTaskActionTypes.AddValuationTaskScenario;

    constructor(public payload: {
        id: number,
        index: number,
        type: 'blank' | 'duplicate'
    }) {}
}

export class ValuationTaskScenarioAdded implements Action {
    readonly type = TpTaskActionTypes.ValuationTaskScenarioAdded;
    constructor(public payload: {
        item: TpTaskModel[]
    }) {}
}

export class DeleteValuationTaskScenario implements Action {
    readonly type = TpTaskActionTypes.DeleteValuationTaskScenario;
    constructor(public payload: {
        id: number
    }) {}
}

export class ValuationTaskScenarioDeleted implements Action {
    readonly type = TpTaskActionTypes.ValuationTaskScenarioDeleted
    constructor(public payload: {
        update_items: TpTaskModel[],
        delete_item_id: number
    }) {}
}

export class UpdateMethodOfValuationTask implements Action {
    readonly type = TpTaskActionTypes.UpdateMethodOfValuationTask
    constructor(public payload: {
        task_id: number,
        method_id: number
    }){}
}

export class ValuationTaskMethodUpdated implements Action {
    readonly type = TpTaskActionTypes.ValuationTaskMethodUpdated
    constructor(public payload: {
        task: TpTaskModel
    }) {}
}

export type TpTaskActions = AllTpTaskRequested |
    AllTpTaskLoaded |
    TpTaskActionToggleLoading |
    TpTaskOnServerCreated |
    TpTaskCreated |
    TpTaskUpdated |
    TpTaskCleared |
    TpTaskDeleted |
    AllTpTaskByUserRequested |
    TpTaskByUserPageRequested |
    TpTaskByUserPageLoaded | 
    AllTpTaskLoadedByUser |
    TpTasksClear |
    ManualTpTaskUpdated |
    ManualTpTaskOnServerUpdated |
    UpdateSignedDocUploadTask |
    BulkUpdateTpTasks |
    TpTasksBulkUpdated |
    DueDiligenceTpTaskOnServerUpdated |
    DueDiligenceTpTaskUpdated |
    AddValuationTaskScenario | 
    ValuationTaskScenarioAdded |
    DeleteValuationTaskScenario |
    ValuationTaskScenarioDeleted |
    UpdateMethodOfValuationTask |
    ValuationTaskMethodUpdated;
