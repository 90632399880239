// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ParcelCharacteristic} from '../_models/parcel-characteristic.model';

export enum ParcelCharacteristicActionTypes {

    AllParcelCharacteristicsRequested = '[ParcelCharacteristics Home Page] All ParcelCharacteristics Requested',
    AllParcelCharacteristicsLoaded = '[ParcelCharacteristics API] All ParcelCharacteristics Loaded',

    ParcelCharacteristicOnServerCreated = '[Edit ParcelCharacteristic Dialog] ParcelCharacteristic On Server Created',
    ParcelCharacteristicCreated = '[Edit ParcelCharacteristics Dialog] ParcelCharacteristics Created',
    ParcelCharacteristicUpdated = '[Edit ParcelCharacteristic Dialog] ParcelCharacteristic Updated',
    ParcelCharacteristicDeleted = '[ParcelCharacteristics List Page] ParcelCharacteristic Deleted',

    ParcelCharacteristicsPageRequested = '[ParcelCharacteristics List Page] ParcelCharacteristics Page Requested',
    ParcelCharacteristicsPageLoaded = '[ParcelCharacteristics API] ParcelCharacteristics Page Loaded',
    ParcelCharacteristicsPageCancelled = '[ParcelCharacteristics API] ParcelCharacteristics Page Cancelled',

    ParcelCharacteristicsPageToggleLoading = '[ParcelCharacteristics page] ParcelCharacteristics Page Toggle Loading',
    ParcelCharacteristicsActionToggleLoading = '[ParcelCharacteristics] ParcelCharacteristics Action Toggle Loading',

    // trash system

    ParcelCharacteristicOnServerRestored = '[ParcelCharacteristic Trash] ParcelCharacteristic On Server Restored',
    ParcelCharacteristicRestored = '[ParcelCharacteristic Trash] ParcelCharacteristic Restored',

    ParcelCharacteristicOnServerAdminRestored = '[ParcelCharacteristic Admin Trash] ParcelCharacteristic On Server Restored',
    ParcelCharacteristicAdminRestored = '[ParcelCharacteristic Admin Trash] ParcelCharacteristic Restored',

    ParcelCharacteristicDeletedFromTrash = '[ParcelCharacteristic Trash] ParcelCharacteristic deleted',
    ParcelCharacteristicDeletedFromAdminTrash = '[ParcelCharacteristic Admin Trash] ParcelCharacteristic deleted',

    ParcelCharacteristicTrash = 'ParcelCharacteristic Trashed',
    ParcelCharacteristicTrashFlushed = 'ParcelCharacteristic Trash Flushed',
}

export class ParcelCharacteristicOnServerCreated implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerCreated;

    constructor(public payload: { parcelCharacteristic: ParcelCharacteristic}) {
    }
}

export class ParcelCharacteristicCreated implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicCreated;

    constructor(public payload: { parcelCharacteristic: ParcelCharacteristic }) {
    }
}

export class ParcelCharacteristicUpdated implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicUpdated;

    constructor(public payload: {
        partialParcelCharacteristic: Update<ParcelCharacteristic>,
        parcelCharacteristic: ParcelCharacteristic
    }) {
    }
}

export class ParcelCharacteristicDeleted implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ParcelCharacteristicsPageRequested implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ParcelCharacteristicsPageLoaded implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicsPageLoaded;

    constructor(public payload: { parcelCharacteristics: ParcelCharacteristic[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ParcelCharacteristicsPageCancelled implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicsPageCancelled;
}

export class AllParcelCharacteristicsRequested implements Action {
    readonly type = ParcelCharacteristicActionTypes.AllParcelCharacteristicsRequested;
}

export class AllParcelCharacteristicsLoaded implements Action {
    readonly type = ParcelCharacteristicActionTypes.AllParcelCharacteristicsLoaded;

    constructor(public payload: { parcelCharacteristics: ParcelCharacteristic[] }) {
    }
}

export class ParcelCharacteristicsPageToggleLoading implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ParcelCharacteristicsActionToggleLoading implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ParcelCharacteristicDeletedFromAdminTrash implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ParcelCharacteristicDeletedFromTrash implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ParcelCharacteristicOnServerRestored implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ParcelCharacteristicRestored implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicRestored;

    constructor(public payload: { item: ParcelCharacteristic }) {
    }
}

export class ParcelCharacteristicOnServerAdminRestored implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ParcelCharacteristicAdminRestored implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicAdminRestored;

    constructor(public payload: { item: ParcelCharacteristic }) {
    }
}

export class ParcelCharacteristicTrashFlushed implements Action {
    readonly type = ParcelCharacteristicActionTypes.ParcelCharacteristicTrashFlushed;

    constructor() {
    }
}

export type ParcelCharacteristicActions = ParcelCharacteristicCreated
    | ParcelCharacteristicUpdated
    | ParcelCharacteristicDeleted
    | ParcelCharacteristicsPageRequested
    | ParcelCharacteristicsPageLoaded
    | ParcelCharacteristicsPageCancelled
    | AllParcelCharacteristicsLoaded
    | AllParcelCharacteristicsRequested
    | ParcelCharacteristicOnServerCreated
    | ParcelCharacteristicsPageToggleLoading
    | ParcelCharacteristicsActionToggleLoading
    | ParcelCharacteristicDeletedFromAdminTrash
    | ParcelCharacteristicDeletedFromTrash
    | ParcelCharacteristicOnServerRestored
    | ParcelCharacteristicRestored
    | ParcelCharacteristicOnServerAdminRestored
    | ParcelCharacteristicAdminRestored
    | ParcelCharacteristicTrashFlushed;
