// NGRX
import {Action} from '@ngrx/store';
// Models
import {CountryModel} from '../_models/country.model';
import {Update} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';

export enum CountryActionTypes {
    AllCountriesRequested = '[Init] All Countries Requested',

    CountriesPageRequested = '[Countries List Page] Countries Page Requested',
    CountriesPageLoaded = '[Countries API] Countries Page Loaded',

    CountryOnServerCreated = '[Edit Country Dialog] Country On Server Created',
    CountryCreated = '[Edit Country Dialog] Country Created',
    CountryUpdated = '[Edit Country Dialog] Country Updated',

    CountryOnServerRestored = '[Country Trash] Country On Server Restored',
    CountryRestored = '[Country Trash] Country Restored',

    CountryOnServerAdminRestored = '[Country Admin Trash] Country On Server Restored',
    CountryAdminRestored = '[Country Admin Trash] Country Restored',

    CountryDeletedFromTrash = '[Country Trash] Country deleted',
    CountryDeletedFromAdminTrash = '[Country Admin Trash] Country deleted',

    CountryTrash = 'Country Trashed',
    CountryTrashFlushed = 'Country Trash Flushed',

    CountriesActionToggleLoading = '[Countries] Countries Action Toggle Loading',

    CountryDeleted = '[Countries List Page] Country Deleted',
}

export class AllCountriesRequested implements Action {
    readonly type = CountryActionTypes.AllCountriesRequested;
}


// list action
export class CountriesPageRequested implements Action {
    readonly type = CountryActionTypes.CountriesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}


export class CountriesPageLoaded implements Action {
    readonly type = CountryActionTypes.CountriesPageLoaded;

    constructor(public payload: {
        items: CountryModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}


export class CountryOnServerCreated implements Action {
    readonly type = CountryActionTypes.CountryOnServerCreated;

    constructor(public payload: { item: CountryModel, currency: any }) {
    }
}

export class CountryCreated implements Action {
    readonly type = CountryActionTypes.CountryCreated;

    constructor(public payload: { item: CountryModel }) {
    }
}

export class CountryUpdated implements Action {
    readonly type = CountryActionTypes.CountryUpdated;

    constructor(public payload: {
        partialItem: Update<CountryModel>,
        item: CountryModel
    }) {
    }
}

export class CountryDeleted implements Action {
    readonly type = CountryActionTypes.CountryDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class CountriesActionToggleLoading implements Action {
    readonly type = CountryActionTypes.CountriesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export class CountryTrashFlushed implements Action {
    readonly type = CountryActionTypes.CountryTrashFlushed;

    constructor() {
    }
}

export class CountryDeletedFromTrash implements Action {
    readonly type = CountryActionTypes.CountryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CountryDeletedFromAdminTrash implements Action {
    readonly type = CountryActionTypes.CountryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CountryOnServerRestored implements Action {
    readonly type = CountryActionTypes.CountryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CountryRestored implements Action {
    readonly type = CountryActionTypes.CountryRestored;

    constructor(public payload: { item: CountryModel }) {
    }
}

export class CountryOnServerAdminRestored implements Action {
    readonly type = CountryActionTypes.CountryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CountryAdminRestored implements Action {
    readonly type = CountryActionTypes.CountryAdminRestored;

    constructor(public payload: { item: CountryModel }) {
    }
}


export type CountryActions = AllCountriesRequested |
    CountriesPageRequested |
    CountriesPageLoaded |
    CountriesActionToggleLoading |
    CountryOnServerCreated |
    CountryCreated |
    CountryOnServerRestored |
    CountryRestored |
    CountryOnServerAdminRestored |
    CountryAdminRestored |
    CountryDeletedFromTrash |
    CountryDeletedFromAdminTrash |
    CountryTrashFlushed |
    CountryUpdated |
    CountryDeleted;
