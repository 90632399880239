<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-lable">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
    </div>
    <div class="form kt-form--label-align-right kt-form--group-seperator-dashed">

        <div class="form kt-form--label-align-right kt-form--group-seperator-dashed" [formGroup]="formGroup">
            <div class="col-12 kt-margin-bottom-20-mobile mt-4 mb-2">
                <div class="mb-4">
                    <mat-label>
                        Did the client provide the reference?
                    </mat-label>
                </div>
                <div class="mb-4">
                    <mat-radio-group formControlName="ref_control">
                        <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                          [value]="2">
                            YES
                        </mat-radio-button>
                        <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                          [value]="1">
                            NO
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="col-12 kt-margin-bottom-20-mobile mt-4 mb-2">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput placeholder="Description" rows="2" formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button (click)="onNoClick()">Close</button>&nbsp;
                <button mat-button (click)="onYesClick()"
                        color="primary" cdkFocusInitial
                        [disabled]="ref_value == 0">Done
                </button>
            </div>
        </div>
    </div>
</div>
