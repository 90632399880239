import { HttpClient, HttpParams} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { HttpUtilsService } from "src/app/core/_base/crud";
import { environment } from "src/environments/environment";
import { IPMSSizeInfo } from "./models/ipms-residential.model";
import { MSResidential } from "./models/mongolian-standard-residential.model";
import { Rooms } from "./mongolia-standard-table/mongolia-standard-table.types";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

@Injectable()
export class SizeService {
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    public getIPMSResidentialSizeInfo(acID: number, smid: number, uamid: number): Observable<any> {
        const url = this.getUrl(acID);
        const headers = this.authService.getAuthHeaders();
        const params = new HttpParams().append('smid', smid.toString()).append('uamid', uamid.toString()).append('type', 'ipms')
        return this.http.get<IPMSSizeInfo>(url, {
            headers,
            params
        })
    }

    public getMSResidentialSizeInfo(
        acID: number, 
        smid: number, 
        uamid: number,
        rooms: Rooms,
        external_areas: number[],
        first_time: boolean
    ): Observable<MSResidential> {
        const url = this.getUrl(acID);
        const headers = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .append('smid', smid.toString())
            .append('uamid', uamid.toString())
            .append('bath', rooms.bath.toString())
            .append('livingRooms', rooms.livingRooms.toString())
            .append('bedrooms', rooms.bedrooms.toString())
            .append('toilet', rooms.toilet.toString())
            .append('kitchen', rooms.kitchen.toString())
            .append('first_time', String(first_time));
        if (external_areas.length > 0) {
            params = new HttpParams()
                .append('smid', smid.toString())
                .append('uamid', uamid.toString())
                .append('bath', rooms.bath.toString())
                .append('livingRooms', rooms.livingRooms.toString())
                .append('bedrooms', rooms.bedrooms.toString())
                .append('toilet', rooms.toilet.toString())
                .append('kitchen', rooms.kitchen.toString())
                .append('external_areas', external_areas.join(','))
                .append('first_time', String(first_time));
        }; 
        return this.http.get<MSResidential>(url, {
            headers,
            params
        })
    }

    private getUrl(acID: number): string {
        const API_BASE_URl = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/asset-class-detail`;
        return `${API_BASE_URl}/${acID}/size`
    }
}