import {Component, ElementRef, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel} from '../../../../core/_base/crud';
import {Store, select} from '@ngrx/store';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog'; 
import {MatPaginator} from '@angular/material/paginator'; 
import {MatSnackBar} from '@angular/material/snack-bar'; 
import {MatSort} from '@angular/material/sort'; 
import {TranslateService} from '@ngx-translate/core';
import {
    HandoverStandard, HandoverStandardDeleted, HandoverStandardsDataSource, HandoverStandardsPageRequested,
    HandoverStandardsService, selectTrashedHandoverStandardCount, selectAdminTrashedHandoverStandardCount,
    HandoverStandardsActionToggleLoading,
    HandoverStandardOnServerAdminRestored,
    HandoverStandardOnServerRestored,
    HandoverStandardDeletedFromTrash,
    HandoverStandardDeletedFromAdminTrash,
    HandoverStandardTrashFlushed,
    HandoverStandardUpdated,
} from '../../../../core/linked-tables';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
    selector: 'kt-handover-standard-list',
    templateUrl: './handover-standard-list.component.html',
    styleUrls: ['./handover-standard-list.component.scss']
})
export class HandoverStandardListComponent implements OnInit, OnDestroy {

    dataSource: HandoverStandardsDataSource;
    displayedColumns = ['id', 'name', 'is_hidden', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<HandoverStandard>(true, []);
    handoverStandardResult: HandoverStandard[] = [];

    trashCnt: number = 0;
    adminTrashCnt: number = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

    /**
     * Component constructor
     *
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: HandoverStandardsService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {

        this.subheaderService.setTitle('Residential Types');
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Residential Types', page: `../default/admin-management/linked-tables/residential-types`}
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadHandoverStandardsList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadHandoverStandardsList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new HandoverStandardsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.handoverStandardResult = res;
        });

        this.subscriptions.push(entitiesSubscription);

        // trash count
        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedHandoverStandardCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedHandoverStandardCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadHandoverStandardsList();
        }); // Remove this line, just loading imitation
    }

    /**
     * Load HandoverStandards List from service through data-source
     */
    loadHandoverStandardsList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        this.store.dispatch(new HandoverStandardsPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {
            with_hidden: true
        };
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadHandoverStandardsList();
    }

    /**
     * Show add handoverStandard dialog
     */
    addHandoverStandard() {
        this.editHandoverStandard(null);
    }

    /**
     * Show Edit handoverStandard view and save after success close result
     * @param handoverStandard: HandoverStandard
     */
    editHandoverStandard(_handoverStandard) {
        if (_handoverStandard) {
            this.router.navigate(['edit', _handoverStandard.id], {relativeTo: this.activatedRoute});
            return;
        }
        this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    deleteHandoverStandard(_item: HandoverStandard) {
        const _title = this.translate.instant('HANDOVER_STANDARD.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('HANDOVER_STANDARD.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
        const _waitDesciption =  this.translate.instant('HANDOVER_STANDARD.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new HandoverStandardDeleted({id: _item.id}));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new HandoverStandardsActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new HandoverStandardsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new HandoverStandardsActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new HandoverStandardsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = 'Deleted Handover Standards';
        if (isAdmin) {
            _title = 'Admin Deleted Handover Standards';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new HandoverStandardOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new HandoverStandardOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new HandoverStandardDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new HandoverStandardDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new HandoverStandardTrashFlushed());
    }

    viewEntry(item: HandoverStandard) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }
    onHiddenChange(event: MatCheckboxChange, item: HandoverStandard) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new HandoverStandardUpdated({
            handoverStandard: _item,
            partialHandoverStandard: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
