import {BaseModel} from '../../_base/crud';
import { AssetClassSourceExternalReferenceModel } from './asset-class-source-external-reference.model';

export class TpFileModel extends BaseModel {
    id: number;

    path: string;
    name: string;
    tp_id: number;
    cat_id: number;
    cat_cnt: number;
    is_additional: boolean;
    cat_name: string;
    client_ref: number;
    description: string;
    ac_ser: AssetClassSourceExternalReferenceModel;


    clear() {
        this.id = undefined;
        this.path = '';
        this.client_ref = 0;
        this.name = '';
        this.tp_id = undefined;
        this.cat_id = 0;
        this.cat_cnt = 0;
        this.is_additional = false;
        this.cat_name = '';
        this.ac_ser = null;
    }
}
