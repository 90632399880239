import { ClusterIconInfo } from "@google/markerclustererplus";

export function madCalculator(
    markers: google.maps.Marker[],
    numStyles: number
): ClusterIconInfo {
    let index = 0;
    const count: number = markers.length;
    const num = markers.reduce((val, marker) => {
        return val + marker.getLabel().text.split('/').length;
    }, 0)

    let dv = count;
    while (dv !== 0) {
        dv = Math.floor(dv / 10);
        index++;
    }

    index = Math.min(index, numStyles);
    return {
        text: num.toString(),
        index: index,
        title: ''
    }
}