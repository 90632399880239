<div class="card card-custom" [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="worker">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="worker">
        <form [formGroup]="workerForm" class="form form-group-seperator-dashed">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    Oops! It looks like you’ve forgotten some important information.
                </kt-alert>

                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="User"
                                    formControlName="worker_id">
                            <mat-option *ngFor="let worker of filteredWorkers" value="{{ worker.id }}">{{worker.first_name}} {{worker.last_name}} {{'(' +
                                getValuerQualificationName(worker.qualification_id) + ')'}}
                            </mat-option>
                        </mat-select>

                        <mat-error>User
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-12 kt-margin-bottom-20-mobile mt-2">
                    <mat-checkbox class="mat-form-field-fluid margin-top-12" formControlName="lead_valuer">
                        Lead valuer
                    </mat-checkbox>
                </div>
                <div class="col-lg-12 mt-2">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Task" formControlName="task_id">
                            <mat-option *ngFor="let task of tpTasks" [value]="task.id" [disabled]="task.disabled">
                                {{task.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-footer b-0 p-0 text-right">
                <div class="form-actions form-actions-solid">
                    <button type="button" mat-raised-button
                            [mat-dialog-close]="data.animal"
                            cdkFocusInitial matTooltip="Close">
                        Close
                    </button>&nbsp;
                    <button type="button"
                            mat-raised-button
                            class="btn btn-success"
                            color="submit" (click)="onSubmit()"
                            [disabled]="viewLoading"
                            matTooltip="Save Changes">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
