import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssetClassLandAreaComponentModel } from '../../../../../../core/asset_class';
import {
    AllUnitAreaMeasurementsRequested,
    AllUnitLengthMeasurementsRequested,
    selectAllUnitAreaMeasurements,
    selectAllUnitLengthMeasurements,
    UnitAreaMeasurement,
    UnitLengthMeasurement,
} from '../../../../../../core/linked-tables';


@Component({
    selector: 'kt-land-area-edit-dialog',
    templateUrl: './land-area-edit.dialog.component.html',
    styleUrls: ['./land-area-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class LandAreaEditDialogComponent implements OnInit, OnDestroy {

    // Public properties
    landAreaComponent: AssetClassLandAreaComponentModel;
    form: UntypedFormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    unitAreaMeasurements: UnitAreaMeasurement[] = [];
    unitLengthMeasurements: UnitLengthMeasurement[] = [];

    decisionsExtra: any[] = [
        {label: 'Yes', value: 1, default: false},
        {label: 'No', value: 0, default: true},
    ];

    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<LandAreaEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
        // this.existSourceExternalReferences = this.data.sourceExternalReferences;
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.landAreaComponent) {
            this.landAreaComponent = Object.assign({}, this.data.landAreaComponent);
        } else {
            this.landAreaComponent = new AssetClassLandAreaComponentModel();
            this.landAreaComponent.clear();
        }

        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
     createForm() {
        this.form = this.fb.group({
            unit_area_measurement_id: [this.landAreaComponent.unit_area_measurement_id, Validators.required],
            surface: [this.landAreaComponent.surface, Validators.required],
            limited_use: [this.landAreaComponent.limited_use],
            underground_restriction: [this.landAreaComponent.underground_restriction],
            aerial_restriction: [this.landAreaComponent.aerial_restriction],
            unit_length_measurement_id: [this.landAreaComponent.unit_length_measurement_id],
        });
        this.fetchSelects();
    }

    fetchSelects() {
        this.store.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store.dispatch(new AllUnitLengthMeasurementsRequested());

        const unitAreaMeasurementsSubscription = this.store.pipe(select(selectAllUnitAreaMeasurements)).subscribe(res => this.unitAreaMeasurements = res);
        const unitLengthMeasurementsSubscription = this.store.pipe(select(selectAllUnitLengthMeasurements)).subscribe(res => this.unitLengthMeasurements = res);

        this.subscriptions.push(unitAreaMeasurementsSubscription);
        this.subscriptions.push(unitLengthMeasurementsSubscription);
    }

    /** ACTIONS */

    /**
     * Save data
     */
     onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        
            this.hasFormErrors = true;
            return;
        }

        const selectedUnitArea = this.unitAreaMeasurements.find(item => item.id === controls.unit_area_measurement_id.value);
        const selectedUnitLength = this.unitLengthMeasurements.find(item => item.id === controls.unit_length_measurement_id.value);
        this.landAreaComponent.unit_area_measurement_id = controls.unit_area_measurement_id.value;
        this.landAreaComponent.unit_area_measurement_name = selectedUnitArea ? selectedUnitArea.name : '';
        this.landAreaComponent.unit_area_measurement_acronym = selectedUnitArea ? selectedUnitArea.acronym : '';
        this.landAreaComponent.surface = controls.surface.value;
        this.landAreaComponent.limited_use = controls.limited_use.value;
        this.landAreaComponent.underground_restriction = controls.underground_restriction.value;
        this.landAreaComponent.aerial_restriction = controls.aerial_restriction.value;
        this.landAreaComponent.unit_length_measurement_id = controls.unit_length_measurement_id.value;
        this.landAreaComponent.unit_length_measurement_name = selectedUnitLength ? selectedUnitLength.name : '';
        this.landAreaComponent.unit_length_measurement_acronym = selectedUnitLength ? selectedUnitLength.acronym : '';
        
        if (this.landAreaComponent.id) {
            this.landAreaComponent._isEditMode = true;
        }
        
        this.dialogRef.close({
            landAreaComponent: this.landAreaComponent,
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.landAreaComponent && this.landAreaComponent.id) {
            if (this.data.disable) {
                return 'View Component';
            }
            return `Edit Component`;
        }
        return 'New Component';
    }

    isFormValid() {
        return !this.form.invalid;
    }

}
