import {ComparativeLandUse} from '../_models/comparative-land-use.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ComparativeLandUsesState} from '../_reducers/comparative-land-use.reducers';
import * as fromComparativeLandUse from '../_reducers/comparative-land-use.reducers';
import {each} from 'lodash';

export const selectComparativeLandUsesState = createFeatureSelector<ComparativeLandUsesState>('comparativeLandUses');

export const selectComparativeLandUseById = (comparativeLandUseId: number) => createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => comparativeLandUsesState.entities[comparativeLandUseId]
);

export const selectAllComparativeLandUses = createSelector(
    selectComparativeLandUsesState,
    fromComparativeLandUse.selectAll
);

export const selectAllComparativeLandUsesIds = createSelector(
    selectComparativeLandUsesState,
    fromComparativeLandUse.selectIds
);

export const allComparativeLandUsesLoaded = createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => comparativeLandUsesState.isAllComparativeLandUsesLoaded
);


export const selectComparativeLandUsesPageLoading = createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => comparativeLandUsesState.listLoading
);

export const selectComparativeLandUsesActionLoading = createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => comparativeLandUsesState.actionLoading
);

export const selectLastCreatedComparativeLandUseId = createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => comparativeLandUsesState.lastCreatedComparativeLandUseId
);

export const selectComparativeLandUsesShowInitWaitingMessage = createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => comparativeLandUsesState.showInitWaitingMessage
);

export const selectTrashedComparativeLandUseCount = createSelector(
    selectComparativeLandUsesState,
    (comparativeLandUsesState) => comparativeLandUsesState.totalTrashed
);

export const selectAdminTrashedComparativeLandUseCount = createSelector(
    selectComparativeLandUsesState,
    (comparativeLandUsesState) => comparativeLandUsesState.totalAdminTrashed
);


export const selectComparativeLandUseQueryResult = createSelector(
    selectComparativeLandUsesState,
    comparativeLandUsesState => {
        const items: ComparativeLandUse[] = [];
        each(comparativeLandUsesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ComparativeLandUse[] = httpExtension.sortArray(items, comparativeLandUsesState.lastQuery.sortField, comparativeLandUsesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, comparativeLandUsesState.totalCount, '', comparativeLandUsesState.totalTrashed);
    }
);
