import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapsService } from 'src/app/core/map/maps.service';

type Point = {
  lat: number,
  lng: number,
}

type DataItem = {
  point: Point,
  length: number
}

@Component({
  selector: 'kt-land-parcel-map',
  templateUrl: './land-parcel-map.component.html',
  styleUrls: ['./land-parcel-map.component.scss']
})
export class LandParcelMapComponent implements OnInit, OnChanges {
  _centerLat$ = new BehaviorSubject<number>(null)
  @Input() 
  set centerLat(value: number) {
    this._centerLat$.next(value)
  };
  _centerLng$ = new BehaviorSubject<number>(null)
  @Input() 
  set centerLng(value: number) {
    this._centerLng$.next(value)
  };
  @Input() markerInfo: {lat: number, lng: number};
  @Input() points: Point[] = [];
  @Input() staticUnitLengthName: string;
  @Input() staticUnit: number;
  @Input() staticUnitName: string;
  @Input() disable: boolean = false;
  @Output() countryChange: EventEmitter<{countryCode: string}> = new EventEmitter();

  isReverse: boolean = false;

  private _drawingManager: google.maps.drawing.DrawingManager;
  private geoCoder = new google.maps.Geocoder();
  private _shape: google.maps.Polygon; 
  private _map: google.maps.Map;
  private _points: Point[] = []
  public datasource: BehaviorSubject<DataItem[]> = new BehaviorSubject([]);
  public area$: BehaviorSubject<number> = new BehaviorSubject(null);
  zoom = 15;
  dummyPaths: google.maps.LatLngLiteral[] = [
    {lat: 47.91839538152958, lng: 106.91517759259033},
    {lat: 47.916036895892965, lng: 106.91240955288696},
    {lat: 47.9120387295366, lng: 106.91277433331298},
    {lat: 47.91101756558328, lng: 106.91695857937621},
    {lat: 47.91163601939803, lng: 106.92264486248779},
    {lat: 47.91425357928109, lng: 106.927365550354},
    {lat: 47.91625261550955, lng: 106.92858863766479},
    {lat: 47.918970605620196, lng: 106.92745138104247},
    {lat: 47.9202360761073, lng: 106.9207565873413}
  ]
  imperial_area = 10.7639;
  imperial_length = 3.28084;

  markerShow = false;
  markerLat: number;
  markerLng: number;

  centerLat$ = combineLatest([
    this._centerLat$,
    this.mapsService.latitude$
  ]).pipe(map(([incoming, def]) => {
    if (incoming == null)  {
      return def
    }
    return incoming
  }))

  centerLng$ = combineLatest([
    this._centerLng$,
    this.mapsService.longitude$
  ]).pipe(map(([incoming, def]) => {
    if (incoming == null)  {
      return def
    }
    return incoming
  }))

  constructor(
    private mapsService: MapsService
  ) { 
    this.points = this.dummyPaths;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markerInfo && changes.markerInfo.currentValue.lat && changes.markerInfo.currentValue.lng) {
      this.markerLat = changes.markerInfo.currentValue.lat;
      this.markerLng = changes.markerInfo.currentValue.lng;
      this.markerShow = changes.markerInfo.currentValue.isShow;
    }
    if (changes && changes.points && !changes.points.firstChange) {
      this._createPolygon(this.points);
    }
  }

  ngOnInit(): void {
  }

  onMapReady(map: any) {
    this.geoCoder = new google.maps.Geocoder();
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('DeleteShape'));
    this._map = map;
    // if (!this.disable) {
      this._initializeDrawingManager(this._map);
    // }

    if (this.points.length > 0) {
      this._disableDrawing();
      this._createPolygon(this.points);
    }
  }

  private _initializeDrawingManager(map: google.maps.Map) {
    const options: google.maps.drawing.DrawingManagerOptions = {
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      polygonOptions: {
        draggable: false,
        editable: true
      }
    }

    this._drawingManager = new google.maps.drawing.DrawingManager(options);
    this._drawingManager.setMap(map);

    google.maps.event.addListener(this._drawingManager, 'overlaycomplete', (event) => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const points = this._getPointsFromPath(event.overlay.getPath());
        this._createPolygon(points);
        event.overlay.setMap(null);
      }
      if (event.type !== google.maps.drawing.OverlayType.MARKER) {
        this._disableDrawing();
      }
    })
  }

  private _getPointsFromPath(path: any): Point[] {
    const points = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      points.push({...path.getAt(i).toJSON()});
    }
    return points;
  }

  private _updatePoints(points: Point[]) {
    this._points = points;
    const tmp: DataItem[] = this._points.map(p => ({
      point: p,
      length: 0
    }));
    for (let i = 1; i < this._points.length; i++) {
      if (i == this._points.length - 1) {
        tmp[0].length = this._calcLength(this._points[0], this._points[this._points.length - 1]);
        tmp[i].length = this._calcLength(this._points[i], this._points[i - 1]);
      } else {
        tmp[i].length = this._calcLength(this._points[i], this._points[i - 1]);
      }
    }
    this.datasource.next(tmp);
    if (this._points.filter(p => p.lat == null || p.lng == null).length > 0) {
      this.area$.next(null);
    } else {
      this.area$.next(google.maps.geometry.spherical.computeArea(this._points.map(p => (new google.maps.LatLng(p.lat, p.lng)))));
    }

    this._getCountryAddress(new google.maps.LatLng(this._points[0].lat, this._points[0].lng))
  }

  private _updatePaths(path: any) {
    const points = this._getPointsFromPath(path);
    this._updatePoints(points);
  }

  private _calcLength(coord1, coord2): number {
    const pathP2P = [];
    const latlng1 = new google.maps.LatLng(coord1);
    const latlng2 = new google.maps.LatLng(coord2);
    pathP2P.push(latlng1);
    pathP2P.push(latlng2);

    return google.maps.geometry.spherical.computeLength(pathP2P);
  }

  private _createPolygon(points: Point[]) {
    if (this._shape) {
      this._shape.setMap(null);
    }
    const filtered = points.filter(p => p.lat != null && p.lng != null);
    this._shape = new google.maps.Polygon({
      paths: filtered,
      editable: this.disable ? false : true
    });
    this._shape.setMap(this._map);

    const self = this;
    const paths = this._shape.getPaths();
    for (let p = 0; p < paths.getLength(); p++) {
      google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
        self._updatePaths(this._shape.getPath());
      });
      google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
        self._updatePaths(this._shape.getPath());
      });
      google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
        self._updatePaths(this._shape.getPath());
      })
    }

    this._updatePoints(points);
  }

  private _getCountryAddress(latLng: google.maps.LatLng): void {
    this.geoCoder.geocode({location: {lat: latLng.lat(), lng: latLng.lng()}}, (results, status) => {
        if (status && results && results.length > 1) {
          const addressComponent = results.reverse()[0].address_components
          if (addressComponent.length > 0) {
            this.countryChange.emit({ countryCode: addressComponent[0].short_name });
          }
            // this._setAutoFillCountry(results.reverse()[0].formatted_address);
        } else {
            // window.alert('No results found');
        }
    });
  }

  private _disableDrawing() {
    this._drawingManager.setDrawingMode(null);
    this._drawingManager.setOptions({
      drawingControl: false
    })
  }
  private _enableDrawing() {
    this._drawingManager.setOptions({
      drawingControl: true
    })
  }
  
  // Actions 
  public deleteShape() {
    if (this._shape) {
      this._shape.setMap(null);
    }
    this._points = [];
    this.datasource.next([]);
    this.area$.next(null);
    if (!this.isReverse) {
      this._enableDrawing();
    }
  }

  public addPoint() {
    this._points.push({lat: null, lng: null});
    this._createPolygon(this._points);
  }

  public onFocusOut(event: FocusEvent, type: 'lat'|'lng', item: Point) {
    const el = event.target as HTMLInputElement;
    const val = Number(el.value);
    const index = this._points.findIndex(p => p.lat == item.lat && p.lng == item.lng);
    if (index == -1) {
      return;
    }
    let newItem = {...this._points[index]};
    switch (type) {
      case 'lat':
        newItem.lat = val;
        break;
      case 'lng':
        newItem.lng = val;
        break;
    }
    this._points.splice(index, 1, newItem);
    this._createPolygon(this._points);
  }

  public changeReverse(event: MatCheckboxChange) {
    if (event.checked) {
      this._disableDrawing()
    } else {
      if (this._shape && this._shape.getMap()) {
        this._disableDrawing()
      } else {
        this._enableDrawing();
      }
    }
  }

  public getPath() {
    return this._points;
  }

  public addRemoveController() {
    if (!this._map.controls[google.maps.ControlPosition.TOP_CENTER].getArray().includes(document.getElementById('DeleteShape'))) {
      this._map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('DeleteShape'));
    }
  }
}
