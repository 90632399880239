<kt-portlet>
    <kt-portlet-header
        [title]="getComponentTitle()"
        [class]="'class-head-lg'"
        [tooltipTitle]="'TOOLTIP.LAND.FORM' + (acLand && acLand.id > 0 ? '_EDIT' : '') + '.TITLE' | translate"
        [tooltipDesc]="'TOOLTIP.LAND.FORM' + (acLand && acLand.id > 0 ? '_EDIT' : '') + '.DESCRIPTION' | translate"
        [viewLoading$]="loading$"
        [sticky]="true">
        <ng-container ktPortletTools>
            <a href="javascript:;"
                (click)="back()"
                class="btn mr-2"
                mat-raised-button
                matTooltip="Back to Land List">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a href="javascript:;"
                color="warn"
                (click)="onSubmit(false)"
                class="btn btn-success mr-2"
                style="background-color: orange !important;"
                mat-raised-button
                matTooltip="Save to Draft">
                <span>Save draft</span>
            </a>
            <a href="javascript:;"
                color="submit"
                (click)="onSubmit(true)"
                class="btn btn-success mr-2"
                mat-raised-button
                matTooltip="Save">
                <span class="kt-hidden-mobile">Save</span>
            </a>
        </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
        <form class="form" [formGroup]="formGroup" autocomplete="off" *ngIf="acLand">
            <kt-tab-header 
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Location & Land Identification</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
        
                        <div class="form-group row">
                            <div class="col-md-12">
                                <h5 class="my-3 text-mad text-uppercase">{{'INSPECTION.LOCATION_LAND_PARCEL' | translate}}</h5>
                                <hr class="active">
                            </div>
        
                            <div class="col-lg-12 mb-2">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput placeholder="Land Parcel ID" formControlName="name" type="text">
                                            <mat-error>
                                                Name <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Coordinate Reference System" formControlName="coordinate_reference_system_id">
                                                <mat-option *ngFor="let coordinateReferenceSystem of coordinateReferenceSystems" [value]="coordinateReferenceSystem.id">{{coordinateReferenceSystem.name}}</mat-option>
                                            </mat-select>
                                            <mat-icon
                                                matSuffix
                                                class="cursor-pointer"
                                                (click)="$event.stopPropagation();"
                                                container="body"
                                                [ngbPopover]="'TOOLTIP.LAND_PARCEL.CRS.DESCRIPTION' | translate">help
                                            </mat-icon>
                                            <mat-error>
                                                Coordinate Reference System
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-12">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Property Sub-Type" formControlName="property_type_id">
                                                <mat-option *ngFor="let propertyType of filteredPropertySubTypes" [value]="propertyType.id">{{propertyType.name}}</mat-option>
                                            </mat-select>
                                            <mat-error>
                                                Property Sub-Type
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-12 mt-2">
                                        <mat-label style="color: gray;"> 
                                            Please draw on the map below the coordinates of each boundary cover or pivot point of the land parcel:
                                        </mat-label>
                                        <mat-icon
                                            matSuffix
                                            class="cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            [ngbPopover]="'TOOLTIP.LAND_PARCEL.MAP.DESCRIPTION' | translate">help
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-12 mb-10">
                                <kt-land-parcel-map
                                    [points]="points"
                                    [centerLat]="centerLat"
                                    [centerLng]="centerLng"
                                    [staticUnit]="2"
                                    [staticUnitLengthName]="'M'"
                                    [staticUnitName]="'sqm'"
                                    (countryChange)="onCountryChange($event)">
                                    <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                                                placeholder="Search By City" autocorrect="off" autocapitalize="off"
                                                spellcheck="off" #search/>
                                            <mat-icon
                                                    matSuffix
                                                    class="cursor-pointer"
                                                    (click)="$event.stopPropagation();"
                                                    container="body"
                                                    [popoverTitle]="'TOOLTIP.INSPECTION.LOCATE.TITLE' | translate"
                                                    [ngbPopover]="'TOOLTIP.INSPECTION.LOCATE.DESCRIPTION' | translate">help
                                            </mat-icon>
                                        </mat-form-field>
                                    </div>
                                </kt-land-parcel-map>
                            </div>
                        </div>
                        <div class="card-body pt-0 mad-container mx-0">
                            <kt-map-location
                                [title]="''"
                                [showMap]="false"
                                [lockMarker]="false"
                                [isComplexForm]="false"
                                [locationData]="acLand.locationData"
                                [parentType]="'comparable'"
                                [(centerLat)]="centerLat"
                                [(centerLng)]="centerLng"
                                (timezoneChange)="onTimezoneChange($event)"
                            ></kt-map-location>
                        </div>
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <kt-land-area-list
                                class="col-md-12"
                                [landAreaComponentListSubject]="landAreaComponentListSubject"
                                [landAreaSubject]="landAreaSubject">
                            </kt-land-area-list>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Property information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <!-- START LAND USE -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25" >
                            <kt-land-use
                                class="col-md-12"
                                [landUseSubject]="landUseSubject"
                                [buildingsSubject]="buildingsSubject">
                            </kt-land-use>
                        </div>
                        <!-- END LAND USE -->

                        <div class="form-group row">
                            <!-- Building -->
                            <div class="col-lg-12">
                                <kt-lp-building-tab *ngIf="buildingsSubject.value.length > 0"
                                    [(buildingsSubject)]="buildingsSubject">
                                </kt-lp-building-tab>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60" *ngIf="buildingsSubject.value.length > 0">
                            <kt-upload-file #lpUploadFile
                                    class="col-md-12"
                                    [filesOnEditMode]="acLand.building_pictures"
                                    [readOnly]="false"
                                    [folderOnEditMode]="acLand.building_picture"
                                    fileType="image/*"
                                    parentForm="building"
                                    split="2"
                                    addButtonLabel="Add building pictures"
                                    [featuredPictureAllowed]="true"
                                    [buildingField]="true"
                                    [buildingsSubject]="buildingsSubject">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error 
                                    class="tag-custom-error" 
                                    *ngIf="hasFormErrors && lpUploadFile.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error 
                                    class="tag-custom-error" 
                                    *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <!-- START SERVICES AND INFRASTRUCTURES -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <kt-services-infrastructures
                                class="col-md-12"
                                [serviceInfrastructureSubject]="serviceInfrastructureSubject"
                                [selectFacilitiesSubject]="selectFacilitiesSubject">
                            </kt-services-infrastructures>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Lease & sale information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group row section-margin-top-60">
                            <kt-tenure-consideration-list
                                class="col-md-12"
                                [considerationListSubject]="considerationListSubject"
                                [tenureSubject]="tenureSubject">
                            </kt-tenure-consideration-list>
                            <div class="col-md-12">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && tenureConsiderationListComponent.tenureConsiderationList.length == 0">
                                    At least 1 Consideration <strong>required</strong>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        
                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Land Pictures
                                </h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #simple class="col-lg-12"
                                [filesOnEditMode]="acLand.ac_pictures"
                                [folderOnEditMode]="acLand.picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="1"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Land Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
                        <kt-ac-document-upload class="section-margin-top-60" [files]="acLand.documents">
                        </kt-ac-document-upload>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>