// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {WallsAndPartitionsService} from '../_services/walls-and-partition.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allWallsAndPartitionsLoaded} from '../_selectors/walls-and-partition.selectors';
// Actions
import {
    AllWallsAndPartitionsLoaded,
    AllWallsAndPartitionsRequested,
    WallsAndPartitionActionTypes,
    WallsAndPartitionsPageRequested,
    WallsAndPartitionsPageLoaded,
    WallsAndPartitionUpdated,
    WallsAndPartitionsPageToggleLoading,
    WallsAndPartitionDeleted,
    WallsAndPartitionOnServerCreated,
    WallsAndPartitionCreated,
    WallsAndPartitionAdminRestored,
    WallsAndPartitionDeletedFromAdminTrash,
    WallsAndPartitionDeletedFromTrash,
    WallsAndPartitionOnServerAdminRestored,
    WallsAndPartitionOnServerRestored,
    WallsAndPartitionRestored,
    WallsAndPartitionTrashFlushed,
    WallsAndPartitionsActionToggleLoading
} from '../_actions/walls-and-partition.actions';

@Injectable()
export class WallsAndPartitionEffects {
    showPageLoadingDispatcher = new WallsAndPartitionsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new WallsAndPartitionsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new WallsAndPartitionsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllWallsAndPartitions$ = this.actions$
        .pipe(
            ofType<AllWallsAndPartitionsRequested>(WallsAndPartitionActionTypes.AllWallsAndPartitionsRequested),
            withLatestFrom(this.store.pipe(select(allWallsAndPartitionsLoaded))),
            filter(([action, isAllWallsAndPartitionsLoaded]) => !isAllWallsAndPartitionsLoaded),
            mergeMap(() => this.service.getAllWallsAndPartitions()),
            map(res => {
                return new AllWallsAndPartitionsLoaded({wallsAndPartitions: res.data});
            })
        );

    @Effect()
    loadWallsAndPartitionsPage$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionsPageRequested>(WallsAndPartitionActionTypes.WallsAndPartitionsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findWallsAndPartitions(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new WallsAndPartitionsPageLoaded({
                    wallsAndPartitions: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionDeleted>(WallsAndPartitionActionTypes.WallsAndPartitionDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteWallsAndPartition(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionUpdated>(WallsAndPartitionActionTypes.WallsAndPartitionUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateWallsAndPartition(payload.wallsAndPartition);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionOnServerCreated>(WallsAndPartitionActionTypes.WallsAndPartitionOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createWallsAndPartition(payload.wallsAndPartition).pipe(
                    tap(res => {
                        this.store.dispatch(new WallsAndPartitionCreated({wallsAndPartition: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushWallsAndPartitionTrash$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionTrashFlushed>(WallsAndPartitionActionTypes.WallsAndPartitionTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionOnServerRestored>(WallsAndPartitionActionTypes.WallsAndPartitionOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new WallsAndPartitionRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionOnServerAdminRestored>(WallsAndPartitionActionTypes.WallsAndPartitionOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new WallsAndPartitionAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionDeletedFromTrash>(WallsAndPartitionActionTypes.WallsAndPartitionDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashWallsAndPartition$ = this.actions$
        .pipe(
            ofType<WallsAndPartitionDeletedFromAdminTrash>(WallsAndPartitionActionTypes.WallsAndPartitionDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: WallsAndPartitionsService, private store: Store<AppState>) {
    }
}
