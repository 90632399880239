// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
import {selectResidentialTypeQueryResult, selectResidentialTypesPageLoading, selectResidentialTypesShowInitWaitingMessage} from '../_selectors/residential-type.selectors';

// Selectirs

export class ResidentialTypesDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectResidentialTypesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectResidentialTypesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectResidentialTypeQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
