import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ValuationComparableFullState } from "../_reducers/valuation-comparable-full.reducer";

export const selectValuationComparableFullState = createFeatureSelector<ValuationComparableFullState>('valuation-comparables-full');
export const selectValuationComparableFullLoadingState = createSelector(
    selectValuationComparableFullState,
    state => state.actionLoading
)
export const selectValuationFullComparables = (refNums: string[]) => createSelector(
    selectValuationComparableFullState,
    state => {
        return refNums.map(refNum => state.entities[refNum])
    }
)
export const selectNotExistingRefs = (refNums: string[]) => createSelector(
    selectValuationComparableFullState,
    state => {
        return refNums.filter(refNum => !state.entities[refNum])
    }
)
export const selectValuationFullComparable = (refNum: string) => createSelector(
    selectValuationComparableFullState,
    state => state.entities[refNum]
)