// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ApproachesToValueActions, ApproachesToValueActionTypes} from '../_actions/approaches-to-value.actions';
// Models
import {ApproachesToValue} from '../_models/approaches-to-value.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ApproachesToValuesState extends EntityState<ApproachesToValue> {
    isAllApproachesToValuesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ApproachesToValue[];
    lastCreatedApproachesToValueId: number;
    listLoading: boolean;
    actionLoading: boolean;

    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ApproachesToValue> = createEntityAdapter<ApproachesToValue>();

export const initialApproachesToValuesState: ApproachesToValuesState = adapter.getInitialState({
    isAllApproachesToValuesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedApproachesToValueId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function approachesToValuesReducer(state = initialApproachesToValuesState, action: ApproachesToValueActions): ApproachesToValuesState {
    switch (action.type) {
        case ApproachesToValueActionTypes.ApproachesToValuesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedApproachesToValueId: undefined
            };
        case ApproachesToValueActionTypes.ApproachesToValuesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ApproachesToValueActionTypes.ApproachesToValueOnServerCreated:
            return {
                ...state
            };
        case ApproachesToValueActionTypes.ApproachesToValueCreated:
            return adapter.addOne(action.payload.approachesToValue, {
                ...state, lastCreatedApproachesToValueId: action.payload.approachesToValue.id
            });
        case ApproachesToValueActionTypes.ApproachesToValueUpdated:
            return adapter.updateOne(action.payload.partialapproachesToValue, state);

        case ApproachesToValueActionTypes.AllApproachesToValuesLoaded:
            return adapter.addAll(action.payload.approachesToValues, {
                ...state, isAllApproachesToValuesLoaded: true
            });
        case ApproachesToValueActionTypes.ApproachesToValuesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ApproachesToValueActionTypes.ApproachesToValuesPageLoaded:
            return adapter.addMany(action.payload.approachesToValues, {
                ...initialApproachesToValuesState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.approachesToValues,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ApproachesToValueActionTypes.ApproachesToValueDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ApproachesToValueActionTypes.ApproachesToValueRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ApproachesToValueActionTypes.ApproachesToValueDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ApproachesToValueActionTypes.ApproachesToValueAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ApproachesToValueActionTypes.ApproachesToValueDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ApproachesToValueActionTypes.ApproachesToValueTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
