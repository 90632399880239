import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { RetailStoreAdjustmentModel } from '../_models/retail-store-adjustment.model';

export enum RetailStoreAdjustmentsActionTypes {
    AllRetailStoreAdjustmentsRequested = '[RetailStore Adjustments List] All RetailStore Adjustments Requested',
    AllRetailStoreAdjustmentsLoaded = '[RetailStore Adjustments API] All RetailStore Adjustments Loaded',

    RetailStoreAdjustmentOnServerCreated = '[Edit RetailStore Adjustment] RetailStore Adjustment on Server Created',
    RetailStoreAdjustmentCreated = '[Edit RetailStore Adjustment] RetailStore Adjustment Created',
    RetailStoreAdjustmentUpdated = '[Edit RetailStore Adjustment] RetailStore Adjustment Updated',
    RetailStoreAdjustmentDeleted = '[RetailStore Adjustment Deleted] RetailStore Adjustment Deleted',

    RetailStoreAdjustmentRestored = '[RetailStore Adjustment Trash] RetailStore Adjustment Restored',
    RetailStoreAdjustmentOnServerRestored = '[RetailStore Adjustment Trash] RetailStore Adjustment On Server Restored',

    RetailStoreAdjustmentOnServerAdminRestored = '[RetailStore Adjustment Admin Trash] RetailStore Adjustment On Server Restored',
    RetailStoreAdjustmentAdminRestored = '[RetailStore Adjustment Admin Trash] RetailStore Adjustment Restored',

    RetailStoreAdjustmentDeletedFromTrash = '[RetailStore Adjustment Trash] RetailStore Adjustment deleted',
    RetailStoreAdjustmentDeletedFromAdminTrash = '[RetailStore Adjustment Admin Trash] RetailStore Adjustment deleted',

    RetailStoreAdjustmentTrash = 'RetailStore Adjustment Trashed',
    RetailStoreAdjustmentTrashFlushed = 'RetailStore Adjustment Trash Flushed',

    // Page system

    RetailStoreAdjustmentsPageRequested = '[RetailStore Adjustment List Page] RetailStore Adjustment Page Requested',
    RetailStoreAdjustmentsPageLoaded = '[RetailStore Adjustment API] RetailStore Adjustment Page Loaded',
    RetailStoreAdjustmentsPageCancelled = '[RetailStore Adjustment API] RetailStore Adjustment Page Cancelled',

    RetailStoreAdjustmentsPageToggleLoading = '[RetailStore Adjustment page] RetailStore Adjustment Page Toggle Loading',
    RetailStoreAdjustmentActionToggleLoading = '[RetailStore Adjustment] RetailStore Adjustment Action Toggle Loading'
}

export class RetailStoreAdjustmentOnServerCreated implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerCreated;

    constructor(public payload: { item: RetailStoreAdjustmentModel }) {
    }
}

export class RetailStoreAdjustmentCreated implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentCreated;

    constructor(public payload: { item: RetailStoreAdjustmentModel }) {
    }
}

export class RetailStoreAdjustmentUpdated implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<RetailStoreAdjustmentModel>,
        item: RetailStoreAdjustmentModel
    }) {
    }
}

export class RetailStoreAdjustmentDeleted implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RetailStoreAdjustmentsPageRequested implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class RetailStoreAdjustmentsPageLoaded implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageLoaded;

    constructor(public payload: { items: RetailStoreAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class RetailStoreAdjustmentsPageCancelled implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageCancelled;
}

export class AllRetailStoreAdjustmentsRequested implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.AllRetailStoreAdjustmentsRequested;
}

export class AllRetailStoreAdjustmentsLoaded implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.AllRetailStoreAdjustmentsLoaded;

    constructor(public payload: { items: RetailStoreAdjustmentModel[] }) {
    }
}

export class RetailStoreAdjustmentsPageToggleLoading implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RetailStoreAdjustmentActionToggleLoading implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class RetailStoreAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RetailStoreAdjustmentDeletedFromTrash implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RetailStoreAdjustmentOnServerRestored implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RetailStoreAdjustmentRestored implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentRestored;

    constructor(public payload: { item: RetailStoreAdjustmentModel }) {
    }
}

export class RetailStoreAdjustmentOnServerAdminRestored implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RetailStoreAdjustmentAdminRestored implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentAdminRestored;

    constructor(public payload: { item: RetailStoreAdjustmentModel }) {
    }
}

export class RetailStoreAdjustmentTrashFlushed implements Action {
    readonly type = RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentTrashFlushed;

    constructor() {
    }
}

export type RetailStoreAdjustmentActions = RetailStoreAdjustmentCreated
    | RetailStoreAdjustmentUpdated
    | RetailStoreAdjustmentDeleted
    | RetailStoreAdjustmentsPageRequested
    | RetailStoreAdjustmentsPageLoaded
    | RetailStoreAdjustmentsPageCancelled
    | AllRetailStoreAdjustmentsLoaded
    | AllRetailStoreAdjustmentsRequested
    | RetailStoreAdjustmentOnServerCreated
    | RetailStoreAdjustmentDeletedFromAdminTrash
    | RetailStoreAdjustmentDeletedFromTrash
    | RetailStoreAdjustmentOnServerRestored
    | RetailStoreAdjustmentRestored
    | RetailStoreAdjustmentOnServerAdminRestored
    | RetailStoreAdjustmentAdminRestored
    | RetailStoreAdjustmentTrashFlushed
    | RetailStoreAdjustmentsPageToggleLoading
    | RetailStoreAdjustmentActionToggleLoading;