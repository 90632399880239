import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RetailBuildingAdjustmentsState } from "../_reducers/retail-building-adjustment.reducers";
import * as fromAdjustment from '../_reducers/retail-building-adjustment.reducers';
import { RetailBuildingAdjustmentModel } from "../_models/retail-building-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectRetailBuildingAdjustmentsState = createFeatureSelector<RetailBuildingAdjustmentsState>('retail-building-adjustments');

export const selectRetailBuildingAdjustmentById = (AdjustmentId: number) => createSelector(
    selectRetailBuildingAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllRetailBuildingAdjustments = createSelector(
    selectRetailBuildingAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllRetailBuildingAdjustmentsIds = createSelector(
    selectRetailBuildingAdjustmentsState,
    fromAdjustment.selectIds
);

export const allRetailBuildingAdjustmentsLoaded = createSelector(
    selectRetailBuildingAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectRetailBuildingAdjustmentsPageLoading = createSelector(
    selectRetailBuildingAdjustmentsState,
    state => state.listLoading
);

export const selectRetailBuildingAdjustmentsActionLoading = createSelector(
    selectRetailBuildingAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedRetailBuildingAdjustmentId = createSelector(
    selectRetailBuildingAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectRetailBuildingAdjustmentsShowInitWaitingMessage = createSelector(
    selectRetailBuildingAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedRetailBuildingAdjustmentCount = createSelector(
    selectRetailBuildingAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedRetailBuildingAdjustmentCount = createSelector(
    selectRetailBuildingAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectRetailBuildingAdjustmentQueryResult = createSelector(
    selectRetailBuildingAdjustmentsState,
    state => {
        const items: RetailBuildingAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RetailBuildingAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);