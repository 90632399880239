import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import {
    AppendixCategory,
    AppendixCategoryOnServerCreated,
    AppendixCategoryUpdated,
    selectAppendixCategoriesActionLoading,
    selectAppendixCategoryById,
    selectLastCreatedAppendixCategoryId
} from '../../../../../core/admin';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'kt-appendix-category-edit',
    templateUrl: './appendix-category-edit.component.html',
    styleUrls: ['./appendix-category-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})


export class AppendixCategoryEditComponent implements OnInit, OnDestroy {

    appendixCategory: AppendixCategory;
    oldAppendixCategory: AppendixCategory;

    appendixCategoryForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param subheaderService
     * @param activatedRoute
     * @param router
     * @param fb: FormBuilder
     * @param layoutUtilsService
     * @param store: Store<AppState>
     * @param layoutConfigService
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectAppendixCategoriesActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectAppendixCategoryById(id))).subscribe(res => {
                    if (res) {
                        this.oldAppendixCategory = Object.assign({}, res);
                        this.appendixCategory = Object.assign({}, this.oldAppendixCategory);
                        this.initAppendixCategory();
                    }
                });
            } else {
                this.appendixCategory = new AppendixCategory();
                this.appendixCategory.clear();
                this.oldAppendixCategory = Object.assign({}, this.appendixCategory);
                this.initAppendixCategory();
            }
        });
        this.subscriptions.push(routeSubscription);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initAppendixCategory() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.appendixCategoryForm = this.fb.group({
            name: [this.appendixCategory.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.appendixCategory && this.appendixCategory.id > 0) {
            return this.translate.instant('APPENDIX_CATEGORY.FORM.TITLE.EDIT', {name: this.appendixCategory.name});
        }
        return this.translate.instant('APPENDIX_CATEGORY.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.appendixCategoryForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.appendixCategory = Object.assign({}, this.oldAppendixCategory);
        this.createForm();
        this.hasFormErrors = false;
        this.appendixCategoryForm.markAsPristine();
        this.appendixCategoryForm.markAsUntouched();
        this.appendixCategoryForm.updateValueAndValidity();
    }

    back() {
        this.location.back()
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.appendixCategoryForm.controls;
        /** check form */
        if (this.appendixCategoryForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.appendixCategory.id > 0) {
            this.updateAppendixCategory(this.appendixCategory);
        } else {
            this.createAppendixCategory(this.appendixCategory);
        }
    }

    /**
     * Update appendixCategory
     *
     * @param _appendixCategory: AppendixCategoryModel
     */
    updateAppendixCategory(_appendixCategory: AppendixCategory) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateAppendixCategory: Update<AppendixCategory> = {
            id: _appendixCategory.id,
            changes: _appendixCategory
        };
        this.store.dispatch(new AppendixCategoryUpdated({
            partialappendixCategory: updateAppendixCategory,
            appendixCategory: _appendixCategory
        }));
        this.oldAppendixCategory = _appendixCategory;
        this.layoutUtilsService.showActionNotification(
            this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'),
            MessageType.Update,
            3000,
            true,
            false);
    }

    /**
     * Create appendixCategory
     *
     * @param _appendixCategory: AppendixCategoryModel
     */
    createAppendixCategory(_appendixCategory: AppendixCategory) {
        this.store.dispatch(new AppendixCategoryOnServerCreated({appendixCategory: _appendixCategory}));
        this.oldAppendixCategory = _appendixCategory;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedAppendixCategoryId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.appendixCategory && this.appendixCategory.name && this.appendixCategory.name.length > 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.appendixCategory.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        return this.appendixCategory.name != this.oldAppendixCategory.name;
    }

}
