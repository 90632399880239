import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { AdjustmentValuation } from "../_models/valuation.model";
import * as reducer from "../_reducers/valuation.reducer";

export const selectValuationState = createFeatureSelector<reducer.ValuationState>('adjustment-valuations');
export const selectAllValuations = createSelector(
    selectValuationState,
    state => {
        const items: AdjustmentValuation[] = [];
        each(state.entities, el => items.push(el));
        return items;
    }
)
export const selectLastCreatedValuationID = createSelector(
    selectValuationState,
    state => state.lastCreatedID
);
export const selectTotalWeightedAvgGrossUnit = createSelector(
    selectValuationState,
    state => state.totalWeightedAvgGrossUnitRate
)
export const selectTotalPropertyValue = createSelector(
    selectValuationState,
    state => state.totalPropertyValue
)
export const selectJustification = createSelector(
    selectValuationState,
    state => state.justification
)
export const selectAdjustmentProcessFinished = createSelector(
    selectValuationState,
    state => {
        let totalWeights = 0;
        each(state.entities, el => totalWeights += el.weighting);
        if (totalWeights === 100 && state.totalPropertyValue !== undefined) {
            return true;
        }
        return false;
    }
)
export const selectCapitalAllowance = createSelector(
    selectValuationState,
    state => state.capitalAllowance
)
export const selectCapitalAllowanceJustification = createSelector(
    selectValuationState,
    state => state.capitalAllowanceJustification
)
export const selectTotalTPValue = createSelector(
    selectValuationState,
    state => {return state.totalTPValue}
)
export const selectTotalTPInUnitMeasurementValue = createSelector(
    selectValuationState,
    state => state.totalTPWeighted
)
export const selectCapAllowanceData = createSelector(
    selectCapitalAllowance,
    selectCapitalAllowanceJustification,
    (capitalAllowance, justification) => {
        return {capitalAllowance, justification}
    }
)