export interface AcGroundData {
    id: number,
    indoor_garage: number,
    dedicated_indoor_garage: number,
    indoor_garage_comment: string,

    outdoor_garage: number,
    dedicated_outdoor_garage: number,
    outdoor_garage_comment: string,

    garden_id: number,
    garden_comment: string,

    outdoor_space_id: number,
    outdoor_space_comment: string,
}

export function newAcGroundData(): AcGroundData {
    return {
        id: undefined,
        indoor_garage: null,
        dedicated_indoor_garage: null,
        indoor_garage_comment: null,

        outdoor_garage: null,
        dedicated_outdoor_garage: null,
        outdoor_garage_comment: null,

        garden_id: null,
        garden_comment: null,

        outdoor_space_comment: null,
        outdoor_space_id: null,
    }
}