import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kt-timepicker-tz-input',
  templateUrl: './timepicker-tz-input.component.html',
  styleUrls: ['./timepicker-tz-input.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: TimepickerTzInputComponent}
  ],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id'
  }
})
export class TimepickerTzInputComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Date> {
  form = new FormGroup({
    time: new FormControl<Date|null>(null),
    timeStr: new FormControl<string|null>(null)
  })

  @Input()
  get timezone(): string | null {
    return this._timezone$.value
  }
  set timezone(value: string | null) {
    this._timezone$.next(value)
  }
  private _timezone$ = new BehaviorSubject<string|null>(null)

  static nextId = 0;
  id = `datepicker-tz-input-${TimepickerTzInputComponent.nextId++}`

  stateChanges = new Subject<void>()
  focused = false;
  touched = false;
  controlType = 'datepicker-tz-input'
  onChange = (_: any) => {}
  onTouched = () => {}

  get empty(): boolean {
    const {value: {time}} = this.form
    return !time;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  @Input()
  get placeholder(): string {
    return this._placeholder
  }
  set placeholder(value: string) {
    this._placeholder = value
    this.stateChanges.next()
  }
  private _placeholder: string = ''

  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value)
    this.stateChanges.next()
  }
  private _required = false

  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
    this._disabled ? this.form.disable() : this.form.enable()
    this.stateChanges.next()
  }
  private _disabled = false

  @Input()
  get value(): Date | null {
    if (this.form.valid) {
      const {value: {time}} = this.form
      return time
    }
    return null
  }
  set value(_value: Date | null) {
    const timeStr = this._timeStrWithTimezone(_value, this.timezone)
    if (typeof _value == 'string' && _value == '') {
      this.form.patchValue({time: null, timeStr})
    } else {
      this.form.patchValue({time: _value, timeStr})
    }
    this.stateChanges.next()
  }

  get errorState(): boolean {
    const touched = !!this.ngControl?.touched
    if (touched != this.touched) {
      this.touched = touched
      this.stateChanges.next()
    }
    return (this.form.invalid || !!this.ngControl?.invalid) && this.touched
  }

  autofilled?: boolean;
  userAriaDescribedBy?: string;

  // MatdatePicker api
  @Input() max: Date | null;
  @Input() min: Date | null;
  @Output() dateChange = new EventEmitter<void>();
  onDateChange() {
    this.dateChange.emit()
  }

  private _onDestroy$ = new Subject<void>();

  constructor(
    private _datePipe: DatePipe,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) { 
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.form.controls.time.valueChanges.pipe(
        startWith(this.form.controls.time.value)
      ),
      this._timezone$
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([timeValue, timezone]) => {
      this.onChange(timeValue)
      const timeStr = this._timeStrWithTimezone(timeValue, timezone)
      this.form.controls.timeStr.patchValue(timeStr)
    })
  }
  ngOnDestroy(): void {
    this.stateChanges.complete()
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  // MatFormFieldControl methods
  setDescribedByIds(ids: string[]): void {
  }
  onContainerClick(event: MouseEvent): void {
  }

  // CVA methods
  writeValue(obj: any): void {
    this.value = obj
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  // Custom methods
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true
      this.stateChanges.next()
    }
  }
  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true
      this.focused = false
      this.onTouched()
      this.stateChanges.next()
    }

  }

  private _timeStrWithTimezone(timeValue: Date | string | null, timezone: string | null): string | null {
    let timeStr: string | null = null
    if (typeof timeValue == 'string' && timeValue == '') {
      return timeStr
    }
    if (timeValue == null) {
      return timeStr
    }

    const date = new Date(timeValue)
    const hour = date.getHours()
    const hourIn24Format = hour == 0 ? 24 : hour
    const hourIn12Format = hourIn24Format > 12 ? (hourIn24Format - 12) : hourIn24Format
    const am_pm = hour >= 0 && hour < 12
      ? 'AM'
      : 'PM'

    const minute = date.getMinutes()

    timeStr = `${hourIn12Format}:${minute} ${am_pm}`
    if (timezone == null) {
      return timeStr
    }

    // return `${timeStr} (GMT ${timezone})`
    return timeStr
  }
}
