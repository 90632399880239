import {Facility} from '../_models/facility.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {FacilitiesState} from '../_reducers/facility.reducers';
import * as fromFacility from '../_reducers/facility.reducers';
import {each} from 'lodash';

export const selectFacilitiesState = createFeatureSelector<FacilitiesState>('facilities');

export const selectFacilityById = (facilityId: number) => createSelector(
    selectFacilitiesState,
    facilitiesState => facilitiesState.entities[facilityId]
);

export const selectAllFacilities = createSelector(
    selectFacilitiesState,
    fromFacility.selectAll
);

export const selectAllFacilitiesIds = createSelector(
    selectFacilitiesState,
    fromFacility.selectIds
);

export const allFacilitiesLoaded = createSelector(
    selectFacilitiesState,
    facilitiesState => facilitiesState.isAllFacilitiesLoaded
);


export const selectFacilitiesPageLoading = createSelector(
    selectFacilitiesState,
    facilitiesState => facilitiesState.listLoading
);

export const selectFacilitiesActionLoading = createSelector(
    selectFacilitiesState,
    facilitiesState => facilitiesState.actionLoading
);

export const selectLastCreatedFacilityId = createSelector(
    selectFacilitiesState,
    facilitiesState => facilitiesState.lastCreatedFacilityId
);

export const selectFacilitiesShowInitWaitingMessage = createSelector(
    selectFacilitiesState,
    facilitiesState => facilitiesState.showInitWaitingMessage
);

export const selectTrashedFacilityCount = createSelector(
    selectFacilitiesState,
    (facilitiesState) => facilitiesState.totalTrashed
);

export const selectAdminTrashedFacilityCount = createSelector(
    selectFacilitiesState,
    (facilitiesState) => facilitiesState.totalAdminTrashed
);


export const selectFacilityQueryResult = createSelector(
    selectFacilitiesState,
    facilitiesState => {
        const items: Facility[] = [];
        each(facilitiesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Facility[] = httpExtension.sortArray(items, facilitiesState.lastQuery.sortField, facilitiesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, facilitiesState.totalCount, '', facilitiesState.totalTrashed);
    }
);
