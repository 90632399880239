import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as _ from 'lodash'
import { TagModelClass } from 'ngx-chips/core/accessor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectAllInteractionTypes } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ToeAuditTaskReminderModel } from 'src/app/core/toe/_models/toe-audit-task-reminder.model';
import { TypesUtilsService } from 'src/app/core/_base/crud';

@Component({
  selector: 'kt-toe-audit-task-modal',
  templateUrl: './audit-task-modal.component.html',
  styleUrls: ['./audit-task-modal.component.scss']
})
export class ToeAuditTaskModalComponent implements OnInit, OnDestroy {
  title = '';

  /**
   * Form
   */
  private hasErrors = false
  formGroup: UntypedFormGroup
  tpSelections: Array<{id: number, value: string}> = []
  interactionTypeSelections: Array<{id: number, value: string}> = []
  workerSelections: Array<{id: number, value: string}> = []

  /**
   * Component State
   */
  private _onDestroy$: Subject<void> = new Subject()

  constructor(
    private dialogRef: MatDialogRef<ToeAuditTaskModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: UntypedFormBuilder,
    private store$: Store<AppState>,
    private typesUtilsService: TypesUtilsService
  ) {
    this.title = this.data.model.id ? 'Edit a Reminder' : 'Add a Reminder'
  }

  ngOnInit(): void {
    this.tpSelections = _.concat({id: 0, value: 'General'}, this.data.targetProperties.map(item => ({id: item.id, value: item.name})))
    this.workerSelections = this.data.workers.map(item => ({id: item.id, value: `${item.first_name} ${item.last_name}`}))
    this.store$
      .select(selectAllInteractionTypes)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        this.interactionTypeSelections = res ? res.map(item => ({id: item.id, value: item.name})) : []
      })


    this.formGroup = this.formBuilder.group({
      targetProperty: [this.data.model.tp_id, Validators.required],
      interactionType: [this.data.model.interaction_type_id, Validators.required],
      date: [this.data.model.date, Validators.required],
      user: [this.data.model.user_id, Validators.required],
      notes: [this.data.model.notes, Validators.required]
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  /**
   * Component Actions
   */
  clearDate(ctlr: AbstractControl) {
    ctlr.setValue(null)
    ctlr.updateValueAndValidity()
  }

  close() {
    this.dialogRef.close()
  }

  save() {
    this.hasErrors = false 
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(controlName => {
        if (!controls[controlName].valid) {
          controls[controlName].markAsTouched()
        }
      })
      this.hasErrors = true
    }

    if (this.hasErrors) {
      return;
    }

    const model = new ToeAuditTaskReminderModel()
    model.id = this.data.model.id
    model.toe_id = this.data.model.toe_id
    model.audit_trail_id = this.data.model.audit_trail_id
    model.tp_task_id = this.data.model.tp_task_id
    model.type_id = this.data.model.type_id
    model.tp_id = controls.targetProperty.value
    model.interaction_type_id = controls.interactionType.value
    model.date = controls.date.value 
    model.date_str = controls.date.value ? this.typesUtilsService.getDateStringFromDate(new Date(controls.date.value)) : null
    model.user_id = controls.user.value
    model.notes = controls.notes.value
    model.status = this.data.model.status

    this.dialogRef.close(model)
  }


}
