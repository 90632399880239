<div class="form-group row section-margin-top-60">
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">
            CONDITION RATING
        </h5>
        <hr class="active" />
    </div>
    <div class="col-md-12">
        <kt-condition-rating-documents
            [files]="files"
            [readonly]="readonly"></kt-condition-rating-documents>
    </div>
    <div class="col-12">
        <mat-error class="tag-custom-error" *ngIf="hasErrors && documentsComponent.uploadedFiles.length == 0">
            At least 1 document <strong>required</strong>
        </mat-error>
        <mat-error class="tag-custom-error" *ngIf="hasErrors && showTitleRequiredError$|async">
            Document should have a <strong>Title</strong>
        </mat-error>
    </div>
</div>