// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {StorageType} from '../_models/storage-type.model';

export enum StorageTypeActionTypes {

    AllStorageTypesRequested = '[StorageTypes Home Page] All StorageTypes Requested',
    AllStorageTypesLoaded = '[StorageTypes API] All StorageTypes Loaded',

    StorageTypeOnServerCreated = '[Edit StorageType Dialog] StorageType On Server Created',
    StorageTypeCreated = '[Edit StorageTypes Dialog] StorageTypes Created',
    StorageTypeUpdated = '[Edit StorageType Dialog] StorageType Updated',
    StorageTypeDeleted = '[StorageTypes List Page] StorageType Deleted',

    StorageTypesPageRequested = '[StorageTypes List Page] StorageTypes Page Requested',
    StorageTypesPageLoaded = '[StorageTypes API] StorageTypes Page Loaded',
    StorageTypesPageCancelled = '[StorageTypes API] StorageTypes Page Cancelled',

    StorageTypesPageToggleLoading = '[StorageTypes page] StorageTypes Page Toggle Loading',
    StorageTypesActionToggleLoading = '[StorageTypes] StorageTypes Action Toggle Loading',

    // trash system

    StorageTypeOnServerRestored = '[StorageType Trash] StorageType On Server Restored',
    StorageTypeRestored = '[StorageType Trash] StorageType Restored',

    StorageTypeOnServerAdminRestored = '[StorageType Admin Trash] StorageType On Server Restored',
    StorageTypeAdminRestored = '[StorageType Admin Trash] StorageType Restored',

    StorageTypeDeletedFromTrash = '[StorageType Trash] StorageType deleted',
    StorageTypeDeletedFromAdminTrash = '[StorageType Admin Trash] StorageType deleted',

    StorageTypeTrash = 'StorageType Trashed',
    StorageTypeTrashFlushed = 'StorageType Trash Flushed',

    StorageTypesClear = '[StorageTypes API] StorageTypes Page Clear',
}

export class StorageTypeOnServerCreated implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeOnServerCreated;

    constructor(public payload: { storageType: StorageType}) {
    }
}

export class StorageTypeCreated implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeCreated;

    constructor(public payload: { storageType: StorageType }) {
    }
}

export class StorageTypeUpdated implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeUpdated;

    constructor(public payload: {
        partialStorageType: Update<StorageType>,
        storageType: StorageType
    }) {
    }
}

export class StorageTypeDeleted implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class StorageTypesPageRequested implements Action {
    readonly type = StorageTypeActionTypes.StorageTypesPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class StorageTypesPageLoaded implements Action {
    readonly type = StorageTypeActionTypes.StorageTypesPageLoaded;

    constructor(public payload: { storageTypes: StorageType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class StorageTypesPageCancelled implements Action {
    readonly type = StorageTypeActionTypes.StorageTypesPageCancelled;
}

export class AllStorageTypesRequested implements Action {
    readonly type = StorageTypeActionTypes.AllStorageTypesRequested;
}

export class AllStorageTypesLoaded implements Action {
    readonly type = StorageTypeActionTypes.AllStorageTypesLoaded;

    constructor(public payload: { storageTypes: StorageType[] }) {
    }
}

export class StorageTypesPageToggleLoading implements Action {
    readonly type = StorageTypeActionTypes.StorageTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class StorageTypesActionToggleLoading implements Action {
    readonly type = StorageTypeActionTypes.StorageTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class StorageTypeDeletedFromAdminTrash implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class StorageTypeDeletedFromTrash implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class StorageTypeOnServerRestored implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class StorageTypeRestored implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeRestored;

    constructor(public payload: { item: StorageType }) {
    }
}

export class StorageTypeOnServerAdminRestored implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class StorageTypeAdminRestored implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeAdminRestored;

    constructor(public payload: { item: StorageType }) {
    }
}

export class StorageTypeTrashFlushed implements Action {
    readonly type = StorageTypeActionTypes.StorageTypeTrashFlushed;

    constructor() {
    }
}

export class StorageTypesClear implements Action {
    readonly type = StorageTypeActionTypes.StorageTypesClear;

    constructor() {
    }
}

export type StorageTypeActions = StorageTypeCreated
    | StorageTypeUpdated
    | StorageTypeDeleted
    | StorageTypesPageRequested
    | StorageTypesPageLoaded
    | StorageTypesPageCancelled
    | AllStorageTypesLoaded
    | AllStorageTypesRequested
    | StorageTypeOnServerCreated
    | StorageTypesPageToggleLoading
    | StorageTypesActionToggleLoading
    | StorageTypeDeletedFromAdminTrash
    | StorageTypeDeletedFromTrash
    | StorageTypeOnServerRestored
    | StorageTypeRestored
    | StorageTypeOnServerAdminRestored
    | StorageTypeAdminRestored
    | StorageTypeTrashFlushed
    | StorageTypesClear;
