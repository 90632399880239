import {BaseModel} from '../../_base/crud';

export class SignedToeDocModel extends BaseModel {
    id: number;
    toe_id: number;
    url: string;
    file_name: string;
    checked: number;
    user_id: number;
    file_size: string;
    has_altered: number;
    details: string;
    created_at: Date;


    clear() {
        this.id = undefined;
        this.toe_id = undefined;
        this.url = '';
        this.file_name = '';
        this.checked = undefined;
        this.user_id = undefined;
        this.file_size = '';
        this.created_at = undefined;
    }
}

export class SignedInformedConsentModel extends BaseModel {
    id: number;
    toe_id: number;
    url: string;
    file_name: string;
    checked: number;
    user_id: number;
    file_size: string;

    clear() {
        this.id = undefined;
        this.toe_id = undefined;
        this.url = '';
        this.file_name = '';
        this.checked = undefined;
        this.user_id = undefined;
        this.file_size = '';
    }
}
export class SignedToeAmendmentModel extends BaseModel {
    id: number;
    toe_id: number;
    url: string;
    file_name: string;
    checked: number;
    user_id: number;
    file_size: string;

    clear() {
        this.id = undefined;
        this.toe_id = undefined;
        this.url = '';
        this.file_name = '';
        this.checked = undefined;
        this.user_id = undefined;
        this.file_size = '';
    }
}
