import {AssetClassOfficeFacilityModel} from '../_models/asset-class-office-facility.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassOfficeFacilitiesState} from '../_reducers/asset-class-office-facility.reducers';
import * as fromAssetClassOfficeFacility from '../_reducers/asset-class-office-facility.reducers';
import {each} from 'lodash';

export const selectAssetClassOfficeFacilitiesState = createFeatureSelector<AssetClassOfficeFacilitiesState>('assetClassOfficeFacilities');

export const selectAllAssetClassOfficeFacilities = createSelector(
    selectAssetClassOfficeFacilitiesState,
    fromAssetClassOfficeFacility.selectAll
);

export const selectAssetClassOfficeFacilityQueryResult = createSelector(
    selectAssetClassOfficeFacilitiesState,
    assetClassOfficeFacilitiesState => {
        const items: AssetClassOfficeFacilityModel[] = [];
        each(assetClassOfficeFacilitiesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AssetClassOfficeFacilityModel[] = httpExtension.sortArray(items, assetClassOfficeFacilitiesState.lastQuery.sortField, assetClassOfficeFacilitiesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, assetClassOfficeFacilitiesState.queryRowsCount, '');
    }
);
