<h6 class="mb-4" id="">
    <strong>{{index + 1}}. {{conflictData.definition}}</strong>
    <!--<strong>Has the Agency or any of the Valuers been involved with the client-->
    <!--previously</strong><br>-->
    <!--<i>* Refer to RICS / Professional Standard 2 - Section 3, Paragraph 3.7</i>-->
    <mat-icon matSuffix
        class="cursor-pointer icon-gray pl-2 mt-1"
        container="body"
        *ngIf="tooltipKeys.indexOf(conflictData.name) > -1"
        [ngbPopover]="'TOOLTIP.DISCLOSURE.' + conflictData.name.toUpperCase() + '.DESCRIPTION' | translate"
        (click)="$event.stopPropagation();">
        help
    </mat-icon>
</h6>

<mat-radio-group class="mb-2 p-1"
                 [ngClass]="{ 'example-radio-group': options.length == 4, 'readonly': readonly }"
                 (change)="radioChanged()"
                 [(ngModel)]=" selectedOptionIndex">

    <mat-radio-button class="example-radio-button"
                      *ngFor="let option of options; let i=index"
                      [value]="i"
    >{{option.name}}
    </mat-radio-button>
</mat-radio-group>

<div class="kt-form__section kt-form__section--first"
    [ngClass]="{'readonly': readonly}"
     *ngIf="showForm">
    <form [formGroup]="conflictForm">
        <div class="form-group kt-form__group row">
            <div class="col-lg-12  mx-2 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput rows="1"
                              #field
                              [minlength]="conflictData.name == 'purpose_of_valuation' ? null : 20"
                              [attr.disabled]="explainDisable ? '' : null"
                              placeholder="{{conflictData.name == 'purpose_of_valuation' 
                                                ? (selectedOptionIndex == 0 
                                                    ? labelForNoSelection
                                                    : labelForYesSelection) 
                                                : conflictData.mitigation_strategy_title}}"
                              formControlName="explain"></textarea>

                    <!--[disabled]="explainDisable"-->
                    <mat-error *ngIf="conflictForm.controls.explain.hasError('required')">It is
                        <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="conflictForm.controls.explain.hasError('minlength')">
                        It must be at least 20 characters long
                    </mat-error>


                </mat-form-field>
            </div>
            <div lass="col-lg-12 kt-margin-bottom-20-mobile">
                <div class="d-inline-block mt-2 mr-3 ml-4" *ngIf="conflictData.mitigation_strategy && conflictData.mitigation_strategy.length > 0"
                     style="font-size: small; text-align: justify; text-justify: inter-word;">
                    <b>Mitigation strategy: </b>
                    '{{conflictData.mitigation_strategy}}'
                </div>

                <div class="d-inline-block mt-2 mr-3 ml-4 w-100" *ngIf="conflictData.name != 'purpose_of_valuation' && ( conflictData.mitigation_strategy == null || conflictData.mitigation_strategy.length == 0 )"
                     style="font-size: small; text-align: justify; text-justify: inter-word;">
                    NO MITIGATION STRATEGY
                </div>
                <div class="d-inline-block mt-2 mr-3 ml-4 w-100">
                <span class="btn btn-primary"
                      *ngIf="conflictData.name == 'valuer_involved_in_purchase'"
                      color="primary"
                      [ngClass]="{'readonly': readonly}"
                      mat-raised-button
                      (click)="addFiles()">Uploaded Files {{conflictService && conflictService.fileCntSubject && (conflictService.fileCntSubject | async) > 0 ? '('+(conflictService.fileCntSubject | async)+')': ''  }}
                </span>
                </div>
            </div>
        </div>
    </form>

</div>