// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {DefaultAssumptionActions, DefaultAssumptionActionTypes} from '../_actions/default-assumption.actions';
// Models
import {DefaultAssumption} from '../_models/default-assumption.model';
import {QueryParamsModel} from '../../_base/crud';

export interface DefaultAssumptionsState extends EntityState<DefaultAssumption> {
    isAllDefaultAssumptionsLoaded: boolean;
    queryRowsCount: number;
    queryResult: DefaultAssumption[];
    lastCreatedDefaultAssumptionId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<DefaultAssumption> = createEntityAdapter<DefaultAssumption>();

export const initialDefaultAssumptionsState: DefaultAssumptionsState = adapter.getInitialState({
    isAllDefaultAssumptionsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedDefaultAssumptionId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,

    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function defaultAssumptionsReducer(state = initialDefaultAssumptionsState, action: DefaultAssumptionActions): DefaultAssumptionsState {
    switch (action.type) {
        case DefaultAssumptionActionTypes.DefaultAssumptionsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDefaultAssumptionId: undefined
            };

        case DefaultAssumptionActionTypes.DefaultAssumptionsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };

        case DefaultAssumptionActionTypes.DefaultAssumptionOnServerCreated:
            return {
                ...state
            };

        case DefaultAssumptionActionTypes.DefaultAssumptionCreated:
            return adapter.addOne(action.payload.defaultAssumption, {
                ...state, lastCreatedDefaultAssumptionId: action.payload.defaultAssumption.id
            });

        case DefaultAssumptionActionTypes.DefaultAssumptionUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case DefaultAssumptionActionTypes.AllDefaultAssumptionsLoaded:
            return adapter.addAll(action.payload.defaultAssumptions, {
                ...state, isAllDefaultAssumptionsLoaded: true
            });

        case DefaultAssumptionActionTypes.DefaultAssumptionsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case DefaultAssumptionActionTypes.DefaultAssumptionsPageLoaded:
            return adapter.addMany(action.payload.defaultAssumptions, {
                ...initialDefaultAssumptionsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case DefaultAssumptionActionTypes.DefaultAssumptionDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case DefaultAssumptionActionTypes.DefaultAssumptionRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case DefaultAssumptionActionTypes.DefaultAssumptionDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case DefaultAssumptionActionTypes.DefaultAssumptionAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case DefaultAssumptionActionTypes.DefaultAssumptionDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case DefaultAssumptionActionTypes.DefaultAssumptionTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
