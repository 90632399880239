import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { CertificationModel } from "../_models/certification.model";

export enum CertificationActionTypes {
    AllCertificationsRequested = '[Linked Area] All Certification Requested',
    AllCertificationsLoaded = '[Certification Effect] All Certification Loaded',

    CertificationOnServerCreated = '[Add Certification Page] Certification On Server Created',
    CertificationCreated = '[Certification Effect] Certification Created',
    CertificationUpdated = '[Certification Effect] Certification Updated',
    CertificationDeleted = '[Certification List Page] Certification Deleted',

    CertificationOnServerRestored = '[Certification Trash] Certification On Server Restored',
    CertificationRestored = '[Certification Trash] Certification Restored',

    CertificationOnServerAdminRestored = '[Certification Admin Trash] Certification On Server Restored',
    CertificationAdminRestored = '[Certification Admin Trash] Certification Restored',

    CertificationDeletedFromTrash = '[Certification Trash] Certification deleted',
    CertificationDeletedFromAdminTrash = '[Certification Admin Trash] Certification deleted',

    CertificationTrash = 'Certification Trashed',
    CertificationTrashFlushed = 'Certification Trash Flushed',

    CertificationsPageRequested = '[Certification List Page] Certification Page Requested',
    CertificationsPageLoaded = '[Certification API] Certification Page Loaded',
    CertificationsPageCancelled = '[Certification API] Certification Page Cancelled',

    CertificationsPageToggleLoading = '[Certification page] Certification Page Toggle Loading',
    CertificationActionToggleLoading = '[Certification] Certification Action Toggle Loading'
}

export class CertificationOnServerCreated implements Action {
    readonly type = CertificationActionTypes.CertificationOnServerCreated;

    constructor(public payload: { item: CertificationModel }) {
    }
}

export class CertificationCreated implements Action {
    readonly type = CertificationActionTypes.CertificationCreated;

    constructor(public payload: { item: CertificationModel }) {
    }
}

export class CertificationUpdated implements Action {
    readonly type = CertificationActionTypes.CertificationUpdated;

    constructor(public payload: {
        partialItem: Update<CertificationModel>,
        item: CertificationModel
    }) {
    }
}

export class CertificationDeleted implements Action {
    readonly type = CertificationActionTypes.CertificationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class CertificationsPageRequested implements Action {
    readonly type = CertificationActionTypes.CertificationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class CertificationsPageLoaded implements Action {
    readonly type = CertificationActionTypes.CertificationsPageLoaded;

    constructor(public payload: { items: CertificationModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class CertificationsPageCancelled implements Action {
    readonly type = CertificationActionTypes.CertificationsPageCancelled;
}

export class AllCertificationsRequested implements Action {
    readonly type = CertificationActionTypes.AllCertificationsRequested;
}

export class AllCertificationsLoaded implements Action {
    readonly type = CertificationActionTypes.AllCertificationsLoaded;

    constructor(public payload: { items: CertificationModel[] }) {
    }
}

export class CertificationsPageToggleLoading implements Action {
    readonly type = CertificationActionTypes.CertificationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class CertificationActionToggleLoading implements Action {
    readonly type = CertificationActionTypes.CertificationActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class CertificationDeletedFromAdminTrash implements Action {
    readonly type = CertificationActionTypes.CertificationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CertificationDeletedFromTrash implements Action {
    readonly type = CertificationActionTypes.CertificationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CertificationOnServerRestored implements Action {
    readonly type = CertificationActionTypes.CertificationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CertificationRestored implements Action {
    readonly type = CertificationActionTypes.CertificationRestored;

    constructor(public payload: { item: CertificationModel }) {
    }
}

export class CertificationOnServerAdminRestored implements Action {
    readonly type = CertificationActionTypes.CertificationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CertificationAdminRestored implements Action {
    readonly type = CertificationActionTypes.CertificationAdminRestored;

    constructor(public payload: { item: CertificationModel }) {
    }
}

export class CertificationTrashFlushed implements Action {
    readonly type = CertificationActionTypes.CertificationTrashFlushed;

    constructor() {
    }
}


export type CertificationActions = CertificationCreated
    | CertificationUpdated
    | CertificationDeleted
    | CertificationsPageRequested
    | CertificationsPageLoaded
    | CertificationsPageCancelled
    | AllCertificationsLoaded
    | AllCertificationsRequested
    | CertificationOnServerCreated
    | CertificationDeletedFromAdminTrash
    | CertificationDeletedFromTrash
    | CertificationOnServerRestored
    | CertificationRestored
    | CertificationOnServerAdminRestored
    | CertificationAdminRestored
    | CertificationTrashFlushed
    | CertificationsPageToggleLoading
    | CertificationActionToggleLoading;