<div class="d-flex flex-column">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <button class="btn btn-primary" color="primary" (click)="addLink()" type="button" *ngIf="!readOnly">
            Add Matterport link
        </button>
    </div>

    <!-- &lt;!&ndash;<div *ngIf="(linksSubject| async).value > 0" class="example-container mat-elevation-z0">&ndash;&gt; -->
    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <!-- &lt;!&ndash; Position Column &ndash;&gt; -->
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                <mat-cell *matCellDef="let link"> {{link.title}}</mat-cell>
            </ng-container>
            <!-- &lt;!&ndash; Weight Column &ndash;&gt; -->
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
                <mat-cell *matCellDef="let link"> {{link.description}}</mat-cell>
            </ng-container>
            <!-- &lt;!&ndash; Name Column &ndash;&gt; -->
            <ng-container matColumnDef="url">
                <mat-header-cell *matHeaderCellDef> Link/URL</mat-header-cell>
                <mat-cell *matCellDef="let link"> {{link.url}}</mat-cell>
            </ng-container>
            <!-- &lt;!&ndash; Symbol Column &ndash;&gt; -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let link;let i = index;">
                    <button mat-icon-button
                            color="primary"
                            matTooltip="Edit matterport link"
                            (click)="editLink(link, i)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            color="warn"
                            matTooltip="Delete matterport link"
                            (click)="deleteLink(link, i)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>