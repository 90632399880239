import {StorageType} from '../_models/storage-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {StorageTypesState} from '../_reducers/storage-type.reducers';
import * as fromStorageType from '../_reducers/storage-type.reducers';
import {each} from 'lodash';

export const selectStorageTypesState = createFeatureSelector<StorageTypesState>('storageTypes');

export const selectStorageTypeById = (storageTypeId: number) => createSelector(
    selectStorageTypesState,
    storageTypesState => storageTypesState.entities[storageTypeId]
);

export const selectAllStorageTypes = createSelector(
    selectStorageTypesState,
    fromStorageType.selectAll
);

export const selectAllStorageTypesIds = createSelector(
    selectStorageTypesState,
    fromStorageType.selectIds
);

export const allStorageTypesLoaded = createSelector(
    selectStorageTypesState,
    storageTypesState => storageTypesState.isAllStorageTypesLoaded
);


export const selectStorageTypesPageLoading = createSelector(
    selectStorageTypesState,
    storageTypesState => storageTypesState.listLoading
);

export const selectStorageTypesActionLoading = createSelector(
    selectStorageTypesState,
    storageTypesState => storageTypesState.actionLoading
);

export const selectLastCreatedStorageTypeId = createSelector(
    selectStorageTypesState,
    storageTypesState => storageTypesState.lastCreatedStorageTypeId
);

export const selectStorageTypesShowInitWaitingMessage = createSelector(
    selectStorageTypesState,
    storageTypesState => storageTypesState.showInitWaitingMessage
);

export const selectTrashedStorageTypeCount = createSelector(
    selectStorageTypesState,
    (storageTypesState) => storageTypesState.totalTrashed
);

export const selectAdminTrashedStorageTypeCount = createSelector(
    selectStorageTypesState,
    (storageTypesState) => storageTypesState.totalAdminTrashed
);


export const selectStorageTypeQueryResult = createSelector(
    selectStorageTypesState,
    storageTypesState => {
        const items: StorageType[] = [];
        each(storageTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: StorageType[] = httpExtension.sortArray(items, storageTypesState.lastQuery.sortField, storageTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, storageTypesState.totalCount, '', storageTypesState.totalTrashed);
    }
);
