<div class="card">
    <div class="card-header info-header">
        <div class="card-title" style="margin-bottom: 0;">
            <div class="card-label" style="display: flex; flex-direction: row;">
                <span class="mr-2">Notes</span>
                <mat-icon 
                    style="cursor: pointer; display: flex; align-items: center;" 
                    container="body" 
                    [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.NOTES.DESCRIPTION'|translate"
                    [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.NOTES.TITLE'|translate">
                    help
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="card-body info-body">
        <div *ngIf="!readonly" class="d-flex justify-content-end">
            <button (click)="addNote()"
                    mat-raised-button
                    [matTooltip]="'Add new Adjustment'"
                    type="button"
                    class="m-1 filter_button button-gray"
                    [color]="'primary'">
                <span>Add Note</span>
            </button>
        </div>

        <table class="table info-table" *ngIf="data$|async as notes">
            <thead>
                <th class="notes-user">User</th>
                <th class="notes-date">Date</th>
                <th class="notes-input">Input</th>
                <th class="notes-status">Status</th>
                <th class="notes-action">Action</th>
            </thead>
            <tbody>
                <tr *ngFor="let note of notes; let i = index" 
                    [ngClass]="{
                        'white': i%2 == 0,
                        'whiter-gray': i%2 != 0
                    }">
                        <td class="notes-user">{{note.user_name}}</td>
                        <td class="notes-date">{{note.updated_at | date: 'dd MMM yyyy HH:mm a'}}</td>
                        <td class="notes-input">{{note.input}}</td>
                        <td class="notes-status">
                            <mat-checkbox [checked]="note.status" (change)="onChangeChecked($event, note)">
                            </mat-checkbox>
                        </td>
                        <td class="notes-action">
                            <ng-container *ngIf="currentUser.id === note.user_id && !readonly; else notAvailable">
                                    <span class="mr-6" (click)="editNote(note)">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </span>
                                    <span (click)="deleteNote(note.front_id)">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </span>
                            </ng-container>
                            <ng-template #notAvailable>
                                N/A
                            </ng-template>
                        </td>
                </tr>
            </tbody>
            <tfoot *ngIf="notes.length === 0">
                <tr>
                    <td colspan="5" style="text-align: center;">
                        <strong>No Notes</strong>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>