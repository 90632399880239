import { HttpClient, HttpParams } from '@angular/common/http'
import {Injectable} from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {environment} from 'src/environments/environment'
import { MadAuthService } from '../../mad-auth/mad-auth.service'
import { HttpUtilsService } from '../../_base/crud'
import { ToeAuditTaskReminderModel } from '../_models/toe-audit-task-reminder.model'
import { SubdomainService } from '../../_base/subdomain.service'


@Injectable()
export class ToeAuditTaskReminderService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-audit-task-reminder`
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    fetch(toe_id: number): Observable<ToeAuditTaskReminderModel[]> {
        const headers = this.authService.getAuthHeaders()
        const params = new HttpParams().set('toe_id', toe_id.toString())
        return this.http
            .get<{data: ToeAuditTaskReminderModel[]}>(this.API_URL, {headers, params})
            .pipe(
                map(res => res.data)
            )
    }

    create(reminder: any): Observable<ToeAuditTaskReminderModel> {
        const headers = this.authService.getAuthHeaders()
        return this.http
            .post<{data: ToeAuditTaskReminderModel}>(this.API_URL, reminder, {headers})
            .pipe(map(res => res.data))
    }

    update(reminder: any): Observable<ToeAuditTaskReminderModel> {
        const headers = this.authService.getAuthHeaders()
        return this.http
            .put<{data: ToeAuditTaskReminderModel}>(this.API_URL + `/${reminder.id}`, reminder, {headers})
            .pipe(map(res => res.data))
    }

    delete(id: number): Observable<any> {
        const headers = this.authService.getAuthHeaders()
        return this.http.delete(this.API_URL + `/${id}`, {headers})
    }

}