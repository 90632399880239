import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { SizeCriterionModel } from "../_models/valuation-process-criterion.model";
import { ValuationProcessSetDefaultSizeCriterion, ValuationProcessSizeCriterionActions, ValuationProcessSizeCriterionActionTypes } from '../_actions/size_criterion.actions'
import * as _ from "lodash";

export interface ValuationProcessSizeCriterionsState extends EntityState<SizeCriterionModel> {
    lastCreatedId: number
}
export const adapter: EntityAdapter<SizeCriterionModel> = createEntityAdapter();
export const initialValuationProcessSizeCriterionsState: ValuationProcessSizeCriterionsState = adapter.getInitialState({
    lastCreatedId: 0
})
export function valuationProcessSizeCriterionReducer(
    state = initialValuationProcessSizeCriterionsState,
    action: ValuationProcessSizeCriterionActions
): ValuationProcessSizeCriterionsState {
    switch (action.type) {
        case ValuationProcessSizeCriterionActionTypes.LoadCriterions: {
            let lastCreatedId = 0
            const crits = action.payload.crits.map(crit => {
                lastCreatedId += 1
                let values = crit.values;
                if (Array.isArray(values) && values.length == 0) {
                    values = {}
                }
                return {
                    ...crit,
                    id: lastCreatedId,
                    values
                }
            })
            return adapter.setAll(crits, {...state, lastCreatedId})
        }
        case ValuationProcessSizeCriterionActionTypes.SizeCriterionsCreated: {
            const criterions: SizeCriterionModel[] = []
            let lastCreatedId = state.lastCreatedId
            action.payload.criterions.forEach(crit => {
                lastCreatedId += 1
                criterions.push({
                    ...crit,
                    id: lastCreatedId
                })
            })

            return adapter.addMany(criterions, { ...state, lastCreatedId: lastCreatedId })
        }
        case ValuationProcessSizeCriterionActionTypes.UpdateSizeCriterion: {
            const values = _.cloneDeep(action.payload.criterion.values)
            values[action.payload.refNum] = action.payload.value
            const item: Update<SizeCriterionModel> = {
                id: action.payload.criterion.id,
                changes: {
                    values
                }
            }
            return adapter.updateOne(item, state)
        }
        case ValuationProcessSizeCriterionActionTypes.SetDefaultSizeCriterion: {
            const crit: Update<SizeCriterionModel> = {
                id: action.payload.criterion.id,
                changes: {
                    active: true
                }
            }

            const otherCrits: Update<SizeCriterionModel>[] = []
            _.each(state.entities, (entity) => {
                if (entity.id != action.payload.criterion.id) {
                    otherCrits.push({
                        id: entity.id,
                        changes: {
                            active: false
                        }
                    })
                }
            })
            const updates = _.concat([crit], otherCrits)
            return adapter.updateMany(updates, state)
        }
        case ValuationProcessSizeCriterionActionTypes.ComparableAdded: {
            const crits: SizeCriterionModel[] = []
            _.each(state.entities, (entity) => {
                crits.push(_.cloneDeep(entity))
            })
            const criterions = mergeCriterions(crits, _.cloneDeep(action.payload.criterions))
            let lastCreatedId = state.lastCreatedId
            const newCriterions: SizeCriterionModel[] = []
            criterions.forEach(crit => {
                if (crit.id != 0) {
                    newCriterions.push(crit)
                } else {
                    lastCreatedId += 1
                    newCriterions.push({
                        ...crit,
                        id: lastCreatedId
                    })
                }
            })
            return adapter.upsertMany(newCriterions, {...state, lastCreatedId: lastCreatedId})
        }
        case ValuationProcessSizeCriterionActionTypes.MultipleComparableAdded: {
            let lastCreatedId = 0
            const crits = action.payload.importedCriterions.map(crit => {
                lastCreatedId += 1
                let values = crit.values
                if (Array.isArray(values) && values.length == 0) {
                    values = {}
                }
                return {
                    ...crit,
                    id: lastCreatedId,
                    values
                }
            })
            return adapter.setAll(crits, {...state, lastCreatedId: lastCreatedId})
        }
        case ValuationProcessSizeCriterionActionTypes.RemoveComparable: {
            const crits: SizeCriterionModel[] = []
            _.each(state.entities, (entity) => {
                const crit = _.cloneDeep(entity)
                delete crit.values[action.payload.refNum]
                if (checkValues(crit)) {
                    crits.push(crit)
                }
            })
            return adapter.setAll(crits, state)
        }
        case ValuationProcessSizeCriterionActionTypes.ResetState: {
            return initialValuationProcessSizeCriterionsState
        }
        default:
            return state
    }
}

function mergeCriterions(oldCriterions: SizeCriterionModel[], newCriterions: SizeCriterionModel[]): SizeCriterionModel[] {
    const items = [oldCriterions, newCriterions]
    const merged = items.reduce((acc, criterions) => {
        criterions.forEach(criterion => {
            const item = acc.find(el => el.name == criterion.name)
            if (!item) {
                acc.push(criterion)
            } else {
                item.values = { ...item.values, ...criterion.values }
            }
        })
        return acc
    }, [])
    const refNums: string[] = []
    merged.forEach(criterion => {
        Object.keys(criterion.values).forEach(refNum => {
            if (!refNums.includes(refNum)) {
                refNums.push(refNum)
            }
        })
    })
    return merged.map(criterion => {
        const values: {[key: string]: number | null} = {}
        refNums.forEach(refNum => {
            if (!criterion.values[refNum]) {
                values[refNum] = null
            }
        })
        criterion.values = {...criterion.values, ...values}
        return criterion
   })
}

function checkValues(criterion: SizeCriterionModel): boolean {
    let hasValue = false 
    Object.values(criterion.values).forEach((value) => {
        if (value != null) {
            hasValue = true
        }
    })
    return hasValue
}