// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {OrganisationService} from '../_services/organisation.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allOrganisationsLoaded} from '../_selectors/organisation.selectors';
// Actions
import {
    AllOrganisationsLoaded,
    AllOrganisationsRequested,
    OrganisationActionTypes,
    OrganisationsPageRequested,
    OrganisationsPageLoaded,
    OrganisationUpdated,
    OrganisationsPageToggleLoading,
    OrganisationDeleted,
    OrganisationOnServerCreated,
    OrganisationCreated,
    OrganisationActionToggleLoading, OrganisationTrashFlushed, OrganisationOnServerRestored, OrganisationRestored,
    OrganisationOnServerAdminRestored, OrganisationAdminRestored, OrganisationDeletedFromTrash, OrganisationDeletedFromAdminTrash
} from '../_actions/organisation.actions';

@Injectable()
export class OrganisationEffects {
    showPageLoadingDispatcher = new OrganisationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OrganisationActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OrganisationActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOrganisation$ = this.actions$
        .pipe(
            ofType<AllOrganisationsRequested>(OrganisationActionTypes.AllOrganisationsRequested),
            withLatestFrom(this.store.pipe(select(allOrganisationsLoaded))),
            filter(([action, isAllOrganisationLoaded]) => !isAllOrganisationLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllOrganisationsLoaded({items: res.data});
            })
        );

    @Effect()
    loadOrganisationPage$ = this.actions$
        .pipe(
            ofType<OrganisationsPageRequested>(OrganisationActionTypes.OrganisationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OrganisationsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationUpdated>(OrganisationActionTypes.OrganisationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationOnServerCreated>(OrganisationActionTypes.OrganisationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new OrganisationCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushOrganisationTrash$ = this.actions$
        .pipe(
            ofType<OrganisationTrashFlushed>(OrganisationActionTypes.OrganisationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationOnServerRestored>(OrganisationActionTypes.OrganisationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OrganisationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationOnServerAdminRestored>(OrganisationActionTypes.OrganisationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OrganisationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationDeleted>(OrganisationActionTypes.OrganisationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationDeletedFromTrash>(OrganisationActionTypes.OrganisationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashOrganisation$ = this.actions$
        .pipe(
            ofType<OrganisationDeletedFromAdminTrash>(OrganisationActionTypes.OrganisationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: OrganisationService, private store: Store<AppState>) {
    }
}
