import {Tenure} from '../_models/tenure.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {TenureState} from '../_reducers/tenure.reducers';
import * as fromTenure from '../_reducers/tenure.reducers';
import {each} from 'lodash';

export const selectTenureState = createFeatureSelector<TenureState>('tenures');

export const selectTenureById = (valuationStandardId: number) => createSelector(
    selectTenureState,
    valuationStandardsState => valuationStandardsState.entities[valuationStandardId]
);

export const selectAllTenure = createSelector(
    selectTenureState,
    fromTenure.selectAll
);

export const selectAllTenureIds = createSelector(
    selectTenureState,
    fromTenure.selectIds
);

export const allTenureLoaded = createSelector(
    selectTenureState,
    valuationStandardsState => valuationStandardsState.isAllTenureLoaded
);


export const selectTenurePageLoading = createSelector(
    selectTenureState,
    valuationStandardsState => valuationStandardsState.listLoading
);

export const selectTenureActionLoading = createSelector(
    selectTenureState,
    valuationStandardsState => valuationStandardsState.actionLoading
);

export const selectLastCreatedTenureId = createSelector(
    selectTenureState,
    valuationStandardsState => valuationStandardsState.lastCreatedTenureId
);

export const selectTenureShowInitWaitingMessage = createSelector(
    selectTenureState,
    valuationStandardsState => valuationStandardsState.showInitWaitingMessage
);

export const selectTrashedTenureCount = createSelector(
    selectTenureState,
    (valuationStandardsState) => valuationStandardsState.totalTrashed
);

export const selectAdminTrashedTenureCount = createSelector(
    selectTenureState,
    (valuationStandardsState) => valuationStandardsState.totalAdminTrashed
);


export const selectTenureQueryResult = createSelector(
    selectTenureState,
    valuationStandardsState => {
        const items: Tenure[] = [];
        each(valuationStandardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Tenure[] = httpExtension.sortArray(items, valuationStandardsState.lastQuery.sortField, valuationStandardsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, valuationStandardsState.totalCount, '', valuationStandardsState.totalTrashed);
    }
);
