// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {BathroomFittingActions, BathroomFittingActionTypes} from '../_actions/bathroom-fitting.actions';
// Models
import {BathroomFitting} from '../_models/bathroom-fitting.model';
import {QueryParamsModel} from '../../_base/crud';

export interface BathroomFittingsState extends EntityState<BathroomFitting> {
    isAllBathroomFittingsLoaded: boolean;
    queryRowsCount: number;
    queryResult: BathroomFitting[];
    lastCreatedBathroomFittingId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BathroomFitting> = createEntityAdapter<BathroomFitting>();

export const initialBathroomFittingsState: BathroomFittingsState = adapter.getInitialState({
    isAllBathroomFittingsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedBathroomFittingId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function bathroomFittingsReducer(state = initialBathroomFittingsState, action: BathroomFittingActions): BathroomFittingsState {
    switch (action.type) {
        case BathroomFittingActionTypes.BathroomFittingsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBathroomFittingId: undefined
            };
        case BathroomFittingActionTypes.BathroomFittingsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case BathroomFittingActionTypes.BathroomFittingOnServerCreated:
            return {
                ...state
            };
        case BathroomFittingActionTypes.BathroomFittingCreated:
            return adapter.addOne(action.payload.bathroomFitting, {
                ...state, lastCreatedBathroomFittingId: action.payload.bathroomFitting.id
            });
        case BathroomFittingActionTypes.BathroomFittingUpdated:
            return adapter.updateOne(action.payload.partialBathroomFitting, state);
        case BathroomFittingActionTypes.AllBathroomFittingsLoaded:
            return adapter.addAll(action.payload.bathroomFittings, {
                ...state, isAllBathroomFittingsLoaded: true
            });
        case BathroomFittingActionTypes.BathroomFittingsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case BathroomFittingActionTypes.BathroomFittingsPageLoaded:
            return adapter.addMany(action.payload.bathroomFittings, {
                ...initialBathroomFittingsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case BathroomFittingActionTypes.BathroomFittingDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case BathroomFittingActionTypes.BathroomFittingRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case BathroomFittingActionTypes.BathroomFittingDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case BathroomFittingActionTypes.BathroomFittingAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case BathroomFittingActionTypes.BathroomFittingDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case BathroomFittingActionTypes.BathroomFittingTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
