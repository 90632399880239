import {AdditionalCostTitleModel} from '../_models/additional-cost-title.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AdditionalCostTitlesState} from '../_reducers/additional-cost-title.reducer';
import * as fromAdditionalCostTitle from '../_reducers/additional-cost-title.reducer';
import {each} from 'lodash';

export const selectAdditionalCostTitlesState = createFeatureSelector<AdditionalCostTitlesState>('additional-cost-titles');

export const selectAdditionalCostTitleById = (id: number) => createSelector(
    selectAdditionalCostTitlesState,
    state => state.entities[id]
);

export const selectAllAdditionalCostTitles = createSelector(
    selectAdditionalCostTitlesState,
    fromAdditionalCostTitle.selectAll
);

export const selectAllAdditionalCostTitlesIds = createSelector(
    selectAdditionalCostTitlesState,
    fromAdditionalCostTitle.selectIds
);

export const allAdditionalCostTitlesLoaded = createSelector(
    selectAdditionalCostTitlesState,
    state => state.isAllAdditionalCostTitlesLoaded
);


export const selectAdditionalCostTitlesPageLoading = createSelector(
    selectAdditionalCostTitlesState,
    state => state.listLoading
);

export const selectAdditionalCostTitlesActionLoading = createSelector(
    selectAdditionalCostTitlesState,
    state => state.actionLoading
);

export const selectLastCreatedAdditionalCostTitleId = createSelector(
    selectAdditionalCostTitlesState,
    state => state.lastCreatedAdditionalCostTitleId
);

export const selectAdditionalCostTitlesShowInitWaitingMessage = createSelector(
    selectAdditionalCostTitlesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedAdditionalCostTitleCount = createSelector(
    selectAdditionalCostTitlesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedAdditionalCostTitleCount = createSelector(
    selectAdditionalCostTitlesState,
    (state) => state.totalAdminTrashed
);


export const selectAdditionalCostTitleQueryResult = createSelector(
    selectAdditionalCostTitlesState,
    state => {
        const items: AdditionalCostTitleModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AdditionalCostTitleModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
