import {Component, ElementRef, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel} from '../../../../core/_base/crud';
import {select, Store} from '@ngrx/store';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {TranslateService} from '@ngx-translate/core';
import { TopPropertyTypesDataSource } from 'src/app/core/linked-tables/_data-sources/top-property-types.datasource';
import { TopPropertyTypeModel } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { TopPropertyTypeService } from 'src/app/core/linked-tables/_services/top-property-type.service';
import { selectAdminTrashedTopPropertyTypeCount, selectTrashedTopPropertyTypeCount } from 'src/app/core/linked-tables/_selectors/top-property-type.selectors';
import { TopPropertyTypeActionToggleLoading, TopPropertyTypeDeleted, TopPropertyTypeDeletedFromAdminTrash, TopPropertyTypeDeletedFromTrash, TopPropertyTypeOnServerAdminRestored, TopPropertyTypeOnServerRestored, TopPropertyTypesPageRequested, TopPropertyTypeTrashFlushed, TopPropertyTypeUpdated } from 'src/app/core/linked-tables/_actions/top-property-type.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';


@Component({
    selector: 'kt-top-property-types-list',
    templateUrl: './top-property-types-list.component.html',
    styleUrls: ['./top-property-types-list.component.scss']
})
export class TopPropertyTypesListComponent implements OnInit, OnDestroy {

    dataSource: TopPropertyTypesDataSource;
    displayedColumns = ['id', 'name', 'is_hidden', 'actions'];
    trashCnt = 0;
    adminTrashCnt = 0;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    mode = 'NEW';
    selection = new SelectionModel<TopPropertyTypeModel>(true, []);
    baseOfValueResult: TopPropertyTypeModel[] = [];

    // Subscriptions
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param service
     * @param subheaderService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: TopPropertyTypeService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.subheaderService.setTitle('Base of Values');
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Base Of Values', page: `../default/admin-management/linked-tables/base-of-values`}
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadTopPropertyTypesList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                
                this.paginator.pageIndex = 0;
                this.loadTopPropertyTypesList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new TopPropertyTypesDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.baseOfValueResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        // trash count

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedTopPropertyTypeCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedTopPropertyTypeCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadTopPropertyTypesList();
        }); // Remove this line, just loading imitation
    }


    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    /**
     * Load Purpose Of Valuation List from service through data-source
     */
    loadTopPropertyTypesList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        this.store.dispatch(new TopPropertyTypesPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {
            with_hidden: true
        };
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadTopPropertyTypesList();
    }

    /**
     * Show add purpose of valuation view
     */
    addTopPropertyType() {
        this.editTopPropertyType(null);
    }

    /**
     * Show Edit baseOfValue dialog and save after success close result
     * @param baseOfValue: TopPropertyType
     */
    editTopPropertyType(_baseOfValue) {
        if (_baseOfValue) {
            this.router.navigate(['edit', _baseOfValue.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
        }
    }

    deleteTopPropertyType(_item: TopPropertyTypeModel) {
        const _title = 'Are you sure?';
        const _description: string = `${_item.title} will be deleted`;
        const _waitDesciption =  'Property Type deleting';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new TopPropertyTypeDeleted({id: _item.id}));
        });
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new TopPropertyTypeActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new TopPropertyTypeActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.title}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.user_deleted_by && elem.user_deleted_by.length > 0 ? elem.user_deleted_by : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new TopPropertyTypeActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new TopPropertyTypeActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.title}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deleted_by && elem.deleted_by.length > 0 ? elem.deleted_by : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = 'Deleted Property Types';
        if (isAdmin) {
            _title = 'Admin Deleted Property Types';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new TopPropertyTypeOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new TopPropertyTypeOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new TopPropertyTypeDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new TopPropertyTypeDeletedFromAdminTrash({id}));
    }

    flush() {
        
        this.store.dispatch(new TopPropertyTypeTrashFlushed());
    }

    viewEntry(item: TopPropertyTypeModel) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }
    onHiddenChange(event: MatCheckboxChange, item: TopPropertyTypeModel) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new TopPropertyTypeUpdated({
            item: _item,
            partialItem: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
