import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from "../../_base/crud";
import { WarehouseAdjustmentModel } from "../_models/warehouse-adjustment.model";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class WarehouseAdjustmentsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/warehouse-adjustments`;
    constructor (
        private http: HttpClient,
        private authService: MadAuthService,
        private httpUtils: HttpUtilsService,
        private subDomainService: SubdomainService
    ) {}

    getAll(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }

    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(adjustmentId: number): Observable<WarehouseAdjustmentModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<WarehouseAdjustmentModel>(this.API_URL + `/${adjustmentId}`, {headers: httpHeaders});
    }

    create(_item: WarehouseAdjustmentModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {adjustment: _item};
        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    update(adjustment: WarehouseAdjustmentModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${adjustment.id}`, adjustment, {headers: httpHeaders});
    }

    delete(adjusmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_URL}/${adjusmentId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }

    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }

    deleteFromTrash(_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${_id}`, {headers: httpHeaders});
    }

    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    deleteFromAdminTrash(_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: httpHeaders});
    }

    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: httpHeaders});
    }
}