<div class="row">
	<div class="col-12">
		<div class="d-flex justify-content-between">
			<div class="d-flex">
				<h5 class="my-3 text-mad text-uppercase">Abort ToE</h5>
				<button mat-icon-button 
					placement="top"
					[ngbPopover]="'TOOLTIP.DASHBOARD.ABORT_TOE.DESCRIPTION'|translate"
					[popoverTitle]="'TOOLTIP.DASHBOARD.ABORT_TOE.TITLE' | translate">
					<mat-icon>info</mat-icon>
				</button>
			</div>
			<button mat-icon-button (click)="removeAmendment()">
				<mat-icon style="color: orange">remove_circle</mat-icon>
			</button>
		</div>
		<hr class="active">
	</div>
</div>
<div class="form" [formGroup]="form">
	<div class="form-group">
		<div class="row">
			<div class="col-12">
				<p class="form__label">
					Reason to abort ToE:
				</p>
			</div>
			<div class="col-12">
				<mat-form-field class="mat-form-field-fluid">
					<textarea matInput placeholder="Reason" formControlName="reason"></textarea>
					<mat-error>
						Reason is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p class="form__label">
					Payment arbitration: 
					<span>
						<button mat-icon-button 
							placement="top"
							[ngbPopover]="'TOOLTIP.DASHBOARD.PAYMENT_ARBITRATION.DESCRIPTION' | translate"
							[popoverTitle]="'TOOLTIP.DASHBOARD.PAYMENT_ARBITRATION.TITLE' | translate" >
							<mat-icon>info</mat-icon>
						</button>
					</span>
				</p>
			</div>
			<div class="col-12 mx-0">
				<mat-radio-group formControlName="payment_arbitration" class="mx-0">
					<div class="col-12 mb-1 px-0 mx-0 mb-4">
						<mat-radio-button [value]="0">No</mat-radio-button>
					</div>
					<div class="col-12 px-0 mx-0">
						<mat-radio-button [value]="1">Additional Amount of</mat-radio-button>
						<mat-form-field *ngIf="form.controls.payment_arbitration.value == 1">
							<input matInput formControlName="paying_amount"  [mask]="'separator.2'" [thousandSeparator]="','">
							<mat-error>Amount is <strong>required</strong></mat-error>
						</mat-form-field>
						<mat-form-field *ngIf="form.controls.payment_arbitration.value != 1">
							<input matInput formControlName="disabled_input" [mask]="'separator.2'" [thousandSeparator]="','">
							<mat-error>Amount is <strong>required</strong></mat-error>
						</mat-form-field>
						<mat-label>{{currency}} (excluding taxes) to the initial amount</mat-label>
					</div>
					<div class="col-12 px-0 mx-0">
						<mat-radio-button [value]="2">Write-off an amount of</mat-radio-button>
						<mat-form-field *ngIf="form.controls.payment_arbitration.value == 2">
							<input matInput formControlName="paying_amount" [mask]="'separator.2'" [thousandSeparator]="','">
							<mat-error>Amount is <strong>required</strong></mat-error>
						</mat-form-field>
						<mat-form-field *ngIf="form.controls.payment_arbitration.value != 2">
							<input matInput formControlName="disabled_input" [mask]="'separator.2'" [thousandSeparator]="','">
							<mat-error>Amount is <strong>required</strong></mat-error>
						</mat-form-field>
						<mat-label>{{currency}} (excluding taxes) from the initial amount</mat-label>
					</div>
				</mat-radio-group>
			</div>
		</div>
	</div>
	<!-- <div class="form-group" *ngIf="form.controls.payment_arbitration.value == 1">
		<kt-payments-info 
			[currency]="currency" 
			[paymentSummary$]="paymentSummaryData$"
			[paymentTerms$]="paymentTermData$"
			[selectedTerms]="selectedTermIDs"
			[balance]="balance"
			(selectionChange)="selectionChange($event)"></kt-payments-info>

		<div class="row mt-10">
			<div class="col-12">
				<p class="form__label">
					Payment decision: 
					<span>
						<button mat-icon-button 
							placement="top"
							[ngbPopover]="'TOOLTIP.DASHBOARD.PAYMENT_DECISION.DESCRIPTION' | translate"
							[popoverTitle]="'TOOLTIP.DASHBOARD.PAYMENT_DECISION.TITLE' | translate" >
							<mat-icon>info</mat-icon>
						</button>
					</span>
				</p>
			</div>
			<div class="col-12 payment-decision">
				<mat-form-field class="party-select">
					<mat-select formControlName="paying_party" [placeholder]="'Party'">
						<mat-option *ngFor="let party of parties" [value]="party.type">
							{{party.name}}
						</mat-option>
					</mat-select>
					<mat-error>Party is <strong>required</strong></mat-error>
				</mat-form-field>
				<span>will pay to</span>
				<mat-form-field class="party-select">
					<mat-select formControlName="payed_party" [placeholder]="'Party'">
						<mat-option *ngFor="let party of parties" [value]="party.type">
							{{party.name}}
						</mat-option>
					</mat-select>
					<mat-error>Party is <strong>required</strong></mat-error>
				</mat-form-field>
				<span>the amount of</span>
				<mat-form-field>
					<input matInput formControlName="paying_amount" [placeholder]="'Amount'" [mask]="'separator.2'" [thousandSeparator]="','">
					<mat-error>Amount is <strong>required</strong></mat-error>
				</mat-form-field>
				{{currency}} (excluding taxes)
			</div>
		</div>

		<div class="row mt-10">
			<div class="col-12">
				<mat-form-field class="mat-form-field-fluid">
					<textarea matInput formControlName="comments" [placeholder]="'Any comments'"></textarea>
				</mat-form-field>
			</div>
		</div>
	</div> -->
</div>
