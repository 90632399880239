<div class="kt-form__group row">
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">SOURCES</h5>
        <hr class="active">
        <form class="form" [formGroup]="sourceForm">
            <div class="kt-form__group row">
                <div class="col-lg-4 kt-margin-bottom-10-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Source Type" formControlName="source_type_id"
                                    (selectionChange)="selectType($event)">
                            <mat-option [value]="-1">Unknown</mat-option>
                            <mat-option *ngFor="let sourceType of sourceTypes" [value]="sourceType.id">{{sourceType.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Source Type
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 kt-margin-bottom-10-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Source Information" formControlName="source_information_id">
                            <mat-option [value]="-1">Unknown</mat-option>
                            <mat-option *ngFor="let information of sourceInformation" [value]="information.id">{{information.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Source Information
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 kt-margin-bottom-10-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Source Credibility" formControlName="source_credibility_id">
                            <mat-option [value]="-1">Unknown</mat-option>
                            <mat-option *ngFor="let credibility of sourceCredibility" [value]="credibility.id">{{credibility.name}}</mat-option>
                        </mat-select>
                        <mat-icon
                                matSuffix
                                class="cursor-pointer"
                                (click)="$event.stopPropagation();"
                                container="body"
                                [ngbPopover]="'TOOLTIP.SOURCES.CREDIBILITY.DESCRIPTION' | translate"
                                [popoverTitle]="'TOOLTIP.SOURCES.CREDIBILITY.TITLE' | translate">help
                        </mat-icon>
                        <mat-error>
                            Source Credibility
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-8 kt-margin-bottom-20-mobile mb-2" *ngIf="isComplexForm">
                    <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput rows="4" placeholder="Source Description" formControlName="validation_source"></textarea>
                        <mat-icon
                                matSuffix
                                class="cursor-pointer"
                                (click)="$event.stopPropagation();"
                                container="body"
                                [ngbPopover]="'TOOLTIP.SOURCES.DESC.DESCRIPTION' | translate"
                                [popoverTitle]="'TOOLTIP.SOURCES.DESC.TITLE' | translate">help
                        </mat-icon>
                        <mat-error>Source Description
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 kt-margin-bottom-10-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Web Address" formControlName="web_address"/>
                        <mat-error>
                            Web Address
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mat-form-field-fluid" *ngIf="sourceForm.controls.source_type_id.value != '1'">
                        <span (click)="addPicker_information_date.open()">
                        <input matInput [matDatepicker]="addPicker_information_date"
                                [matDatepickerFilter]="dateFilter"
                               readonly
                               placeholder="Date of Information"
                               formControlName="information_date">

                        </span>
                        <mat-datepicker-toggle matSuffix [for]="addPicker_information_date"></mat-datepicker-toggle>

                        <button mat-button
                                *ngIf="this.sourceForm.controls.information_date.value"
                                matSuffix mat-icon-button matTooltip="Clear"
                                (click)="clearDate(this.sourceForm.controls.information_date)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-datepicker #addPicker_information_date></mat-datepicker>
                        <mat-error>
                            Date of Information
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mat-form-field-fluid" *ngIf="sourceForm.controls.source_type_id.value == '1'">
                        <span (click)="addPicker_transaction_date.open()">
                        <input matInput [matDatepicker]="addPicker_transaction_date"
                                [matDatepickerFilter]="dateFilter"
                               readonly
                               placeholder="Date of transaction"
                               formControlName="transaction_date">
                        </span>
                        <mat-datepicker-toggle matSuffix [for]="addPicker_transaction_date"></mat-datepicker-toggle>

                        <button mat-button
                                *ngIf="this.sourceForm.controls.transaction_date.value && sourceForm.controls.transaction_date.status !== 'DISABLED'"
                                matSuffix mat-icon-button matTooltip="Clear"
                                (click)="clearDate(this.sourceForm.controls.transaction_date)">
                            <mat-icon>close</mat-icon>
                        </button>

                        <mat-datepicker #addPicker_transaction_date></mat-datepicker>
                        <mat-error>
                            Date of transaction
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="example-container mat-elevation-z0">
                <input #fileInput type="file" (change)="uploadFile($event)" style="display: none" accept="image/*"/>
                <div *ngIf="error">
                    <!-- {{ error.message }} -->
                    {{ error }}
                </div>

                <div class="card-body-progress" *ngIf="(progress$ | async) > 0">
                    <mat-progress-bar mode="determinate" color="war" [value]="progress$ | async "></mat-progress-bar>
                </div>

                <mat-table #table [dataSource]="dataSource">
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                        <mat-cell *matCellDef="let file">
                            <img [attr.src]="setIcon(file.type)" alt="" style="width: 30px">
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                        <mat-cell *matCellDef="let file"> {{file.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="file_name">
                        <mat-header-cell *matHeaderCellDef> File name</mat-header-cell>
                        <mat-cell *matCellDef="let file"> {{getFileName(file.field)}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let file">
                            <button mat-icon-button
                                    color="primary"
                                    matTooltip="Upload file"
                                    (click)="addFiles(file.field)"
                                    [disabled]="(progress$ | async) > 0"
                                    type="button">
                                <mat-icon>cloud_upload</mat-icon>
                            </button>&nbsp;
                            <button mat-icon-button
                                    color="warn"
                                    matTooltip="Delete file"
                                    (click)="deleteUploadedFile(file.field)"
                                    type="button"
                                    *ngIf="getFileName(file.field) != awConst.NOFILE"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>&nbsp;
                            <button mat-icon-button
                                    matTooltip="Preview file"
                                    (click)="previewUploadedFile(file.field)"
                                    type="button"
                                    *ngIf="getFileName(file.field) != awConst.NOFILE">
                                <mat-icon *ngIf="file.type == 'jpeg'">remove_red_eye</mat-icon>
                                <mat-icon *ngIf="file.type == 'pdf'">get_app</mat-icon>
                            </button>&nbsp;
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>
        </form>
    </div>
</div>