// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Partials
import {PartialsModule} from '../partials/partials.module';
// Pages
import {CoreModule} from '../../core/core.module';
import {UserManagementModule} from './user-management/user-management.module';
import {MyPageComponent} from './my-page/my-page.component';
import {LinkedTablesModule} from './linked-tables/linked-tables.module';
import {AdminManagementModule} from './admin-management/admin-management.module';
import {ToeModule} from './toe/toe.module';
import {TargetPropertyModule} from './target-property/target-property.module';
import {ComparableModule} from './comparable/comparable.module';

@NgModule({
    declarations: [MyPageComponent],
    exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        UserManagementModule,
        LinkedTablesModule,
        AdminManagementModule,
        ToeModule,
        TargetPropertyModule,
        ComparableModule,
    ],
    providers: []
})
export class PagesModule {
}
