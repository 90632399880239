import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {DefaultAssumption} from '../_models/default-assumption.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class DefaultAssumptionsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/default-assumptions`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // DefaultAssumptions
    getAll(): Observable<any> {
        return this.http.get<any>(this.API_URL, {headers: this.authService.getAuthHeaders()});
    }


    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(defaultAssumptionId: number): Observable<DefaultAssumption> {
        return this.http.get<DefaultAssumption>(this.API_URL + `/${defaultAssumptionId}`);
    }

    // CREATE =>  POST: add a new defaultAssumption to the server
    create(item: DefaultAssumption): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {defaultAssumption: item};

        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the defaultAssumption on the server
    update(defaultAssumption: DefaultAssumption): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_URL + `/${defaultAssumption.id}`, defaultAssumption, {headers: httpHeaders});
    }

    // DELETE => delete the defaultAssumption from the server
    delete(defaultAssumptionId: number): Observable<any> {
        const url = `${this.API_URL}/${defaultAssumptionId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {headers: this.authService.getAuthHeaders()});
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: this.authService.getAuthHeaders()});
    }

    deleteFromTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    // delete from admin trash
    deleteFromAdminTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
