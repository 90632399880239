// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {IncludedInValuationsService} from '../_services/included-in-valuation.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allIncludedInValuationsLoaded} from '../_selectors/included-in-valuation.selectors';
// Actions
import {
    AllIncludedInValuationsLoaded,
    AllIncludedInValuationsRequested,
    IncludedInValuationActionTypes,
    IncludedInValuationsPageRequested,
    IncludedInValuationsPageLoaded,
    IncludedInValuationUpdated,
    IncludedInValuationsPageToggleLoading,
    IncludedInValuationDeleted,
    IncludedInValuationOnServerCreated,
    IncludedInValuationCreated,
    IncludedInValuationsActionToggleLoading,
    IncludedInValuationTrashFlushed,
    IncludedInValuationOnServerRestored,
    IncludedInValuationRestored,
    IncludedInValuationOnServerAdminRestored,
    IncludedInValuationAdminRestored,
    IncludedInValuationDeletedFromTrash,
    IncludedInValuationDeletedFromAdminTrash
} from '../_actions/included-in-valuation.actions';

@Injectable()
export class IncludedInValuationEffects {
    showPageLoadingDispatcher = new IncludedInValuationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new IncludedInValuationsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new IncludedInValuationsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllIncludedInValuations$ = this.actions$
        .pipe(
            ofType<AllIncludedInValuationsRequested>(IncludedInValuationActionTypes.AllIncludedInValuationsRequested),
            withLatestFrom(this.store.pipe(select(allIncludedInValuationsLoaded))),
            filter(([action, isAllIncludedInValuationsLoaded]) => !isAllIncludedInValuationsLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllIncludedInValuationsLoaded({includedInValuations: res.data});
            })
        );

    @Effect()
    loadIncludedInValuationsPage$ = this.actions$
        .pipe(
            ofType<IncludedInValuationsPageRequested>(IncludedInValuationActionTypes.IncludedInValuationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new IncludedInValuationsPageLoaded({
                    includedInValuations: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );
    

    @Effect()
    updateIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationUpdated>(IncludedInValuationActionTypes.IncludedInValuationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationOnServerCreated>(IncludedInValuationActionTypes.IncludedInValuationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new IncludedInValuationCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushIncludedInValuationTrash$ = this.actions$
        .pipe(
            ofType<IncludedInValuationTrashFlushed>(IncludedInValuationActionTypes.IncludedInValuationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationOnServerRestored>(IncludedInValuationActionTypes.IncludedInValuationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IncludedInValuationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationOnServerAdminRestored>(IncludedInValuationActionTypes.IncludedInValuationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IncludedInValuationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationDeleted>(IncludedInValuationActionTypes.IncludedInValuationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationDeletedFromTrash>(IncludedInValuationActionTypes.IncludedInValuationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashIncludedInValuation$ = this.actions$
        .pipe(
            ofType<IncludedInValuationDeletedFromAdminTrash>(IncludedInValuationActionTypes.IncludedInValuationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: IncludedInValuationsService, private store: Store<AppState>) {
    }
}
