import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { ToeRelatedData } from "./abort-toe-amendment.service";
import { SubdomainService } from "../../_base/subdomain.service";

interface TpResult {
	tp_id: number;
	tp_name: string;
	is_removed: boolean;
}

export interface TpRelatedData {
	valuations: Array<{
		id: number;
		tp_name: string;
		tp_type: string;
		method: string;
		investigation_type: string;
		size: number;
		price: number;
	}>,
	additionalCosts: Array<{
		id: number;
		title: string;
		tp: string;
		qty: number;
		price: number;
		total: number;
	}>,
	discountPercent: number,
	vatPercent: number,
	otherPercent: number
};

@Injectable()
export class RemoveTpAmendmentService implements OnDestroy {
	public RemoveTpButtonStatus$: Observable<boolean>;
	
	private _numOfAmendments$: BehaviorSubject<number> = new BehaviorSubject(0);
	private _numOfAmendments = 0;
	private _tps: TpResult[] = [];
	private _tps$: BehaviorSubject<TpResult[]> = new BehaviorSubject([]);
	private _getTpSubscription: Subscription;
    private cached: ToeRelatedData = null;
    private _increased: Boolean = false;
	constructor(
		private http: HttpClient,
		private authService: MadAuthService,
		private subDomainService: SubdomainService
	) {
		this.RemoveTpButtonStatus$ = combineLatest([
			this._numOfAmendments$,
			this._tps$
		]).pipe(map(([numOfAmendments, tps]) => {
			// When there is only one TP, the button is disabled
			if (tps.length == 1) {
				return false;
			}
			// make it only one item can add per modal
			// NOTE: sequence matters
			if (this._increased) { 
				return false;
			}
			// Button is available only for N-1 TPs
			if (numOfAmendments < (tps.length - 1)) {
				return true;
			}
			return false;
		}))
	}

	ngOnDestroy() {
		this._getTpSubscription.unsubscribe();
	}

	public getTps(toeID: number) {
		const headers = this.authService.getAuthHeaders();
		this._getTpSubscription = this.http.get<TpResult[]>(
			environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/` + toeID + '/amendments/tps',
			{headers}
		).subscribe(res => {
			this._tps = res;
			this._tps.forEach(tp => {
				if (tp.is_removed) {
					this._numOfAmendments += 1;
				}
			})
			this._numOfAmendments$.next(this._numOfAmendments)
			this._tps$.next(this._tps)
		});
	}

	public selectableTps() {
		return this._tps$.asObservable();
	}

	public tpSelected(prevId: number, newId: number) {
		this._tps = this._tps.map(tp => {
			if (tp.tp_id == newId) {
				return {...tp, is_removed: true}
			}
			if (tp.tp_id == prevId) {
				return {...tp, is_removed: false}
			}
			return tp
		});
		this._tps$.next(this._tps);
	}

	public amendmentIncreased() {
		this._increased = true;
		this._numOfAmendments += 1;
		this._numOfAmendments$.next(this._numOfAmendments)
	}
	public amendmentDecreased(tpId: number) {
		this._numOfAmendments -= 1;
		this._numOfAmendments$.next(this._numOfAmendments)

		if (tpId) {
			this._tps = this._tps.map(tp => {
				if (tp.tp_id == tpId) {
					return {...tp, is_removed: false}
				}
				return tp
			})
			this._tps$.next(this._tps)
		}
	}

    public getToeRelatedInfo(toeID: number): Observable<ToeRelatedData> {
        if (this.cached) {
            return of(this.cached);
        }
        const headers = this.authService.getAuthHeaders();
        return this.http.get<ToeRelatedData>(
            environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/` + toeID + '/amendments/toe-data',
            {headers}
        ).pipe(tap(res => this.cached = res));
    }

	public getTpRelatedInfo(tpID: number, toeID: number): Observable<TpRelatedData> {
		const headers = this.authService.getAuthHeaders();
		return this.http.get<TpRelatedData>(
			environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/` + toeID + '/amendments/tps/'  + tpID,
			{headers}
		)
	}
}