import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ValuationProcessCriterionsState } from "../_reducers/valuation-criterion.reducer";
import * as _ from 'lodash'
import { DefaultCriterionModel } from "../_models/valuation-process-criterion.model";


const selectValuationProcessCriterionsState = createFeatureSelector<ValuationProcessCriterionsState>('valuation-process-criterions')
export const selectAllValuationProcessCriterions = createSelector(
    selectValuationProcessCriterionsState,
    state => {
        const criterions: DefaultCriterionModel[] = []
        _.each(state.entities, (entity) => {
            criterions.push(entity)
        })
        return criterions
    }
)
export const selectAllApplicableValuationProcessCriterions = createSelector(
    selectAllValuationProcessCriterions,
    criterions => {
        return criterions.filter(crit => isApplicableCriterion(crit))
    }
)
export const selectAllActiveValuationProcessCriterions = createSelector(
    selectAllApplicableValuationProcessCriterions,
    criterions => {
        return criterions.filter(crit => crit.active)
    }
)

function isApplicableCriterion(criterion: DefaultCriterionModel): boolean {
    let isApplicable = false
    Object.values(criterion.values).forEach(value => {
        if (value.kind != 'not applicable') {
            isApplicable = true
        }
    })
    return isApplicable
}