import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassModel, selectAssetClassSERByTpId, selectPropertyDetailReportsByTpId, selectTpFilesByTpId, selectTpTasksByTpId } from 'src/app/core/asset_class';
import { selectTpAdditionalFilesByTpId } from 'src/app/core/asset_class/_selectors/tp-file.selectors';
import { AssignmentModel } from 'src/app/core/assignment';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { ToeModel, ToeReportService } from 'src/app/core/toe';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type ModuleData = {
  documents: DocumentData[],
  lastBorder: number;
  lastBorderDefault: number;
}

type DocumentData = {
  name: string;
  type: string;
  uploaded: Date;
  uploadedBy: string;
  uploadedUser: User;
  url: string;
}

@Component({
  selector: 'kt-available-documents-module',
  templateUrl: './available-documents-module.component.html',
  styleUrls: ['../info-module.styles.scss', './available-documents-module.component.scss']
})
export class AvailableDocumentsModuleComponent implements OnInit {
  @Input() toe: ToeModel;
  @Input() targetProperty: AssetClassModel;
  @Input() assignment: AssignmentModel;
  @Input() workers: any[]
  data$: Observable<ModuleData>;
  awConst = awConst;
  constructor(
    private store$: Store<AppState>,
    private toeReportService: ToeReportService,
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.toeReportService.getAllToeSignedReports(this.toe.id).pipe(map(res => res.data)),
      this.store$.select(selectPropertyDetailReportsByTpId(this.targetProperty.id)).pipe(map(res => res ? res : undefined), filter(res => res !== undefined)),
      this.store$.select(selectTpFilesByTpId(this.targetProperty.id)),
      this.store$.select(selectTpAdditionalFilesByTpId(this.targetProperty.id)),
      this.store$.select(selectAssetClassSERByTpId(this.targetProperty.id)),
      this.store$.select(selectTpTasksByTpId(this.targetProperty.id)).pipe(map(val => val ? val : undefined), filter(val => val !== undefined)),
    ]).pipe(map(([signedReportResults, inspectionReportResults, tpFiles, additionalTpFiles, selectedSERs, tasks]) => {
      const documents: DocumentData[] = [];
      const signedReportRes = signedReportResults.find(el => el.checked);
      let num = 0;
      if (signedReportRes) {
        const signedReport: DocumentData = {
          name: 'Terms of Engagement',
          type: this._getType(signedReportRes.url),
          uploaded: signedReportRes.created_at,
          uploadedBy: signedReportRes.user_name,
          uploadedUser: signedReportRes.created_user,
          url: signedReportRes.url
        }
        documents.push(signedReport);
      }
      num++;
      if (inspectionReportResults.length > 0) {
        inspectionReportResults.sort((a, b) => {
          const d1 = new Date(b.created_at);
          const d2 = new Date(a.created_at);
          return d1.getTime() - d2.getTime();
        });
        const inspectionReport: DocumentData = {
          name: 'Investigation Report',
          type: this._getType(inspectionReportResults[0].url),
          uploaded: inspectionReportResults[0].created_at,
          uploadedBy: inspectionReportResults[0].generated_user_name,
          uploadedUser: (inspectionReportResults[0] as any).generated_user,
          url: inspectionReportResults[0].url
        }
        documents.push(inspectionReport);
        num++;
      }

      const selectedSerIds: {[id: number]: number} = {};

      let defaultNum = 0;
      selectedSERs.forEach(ser => {
        selectedSerIds[ser.source_external_reference.id] = 0;
      });
      const relatedDocs: DocumentData[] = tpFiles.filter(file => {
        if (selectedSerIds[file.cat_id] === 0) {
          return true;
        }
        return false;
      }).filter(file => file.client_ref != 1)
        .map(file => {
        selectedSerIds[file.cat_id]++;

        const doc: DocumentData = {
          name: file.cat_name + ' (' + selectedSerIds[file.cat_id] + ')',
          type: this._getType(file.path + "/" + file.name),
          uploaded: file.created_at,
          uploadedBy: file.uploaded_user ? file.uploaded_user.full_name : this._findUserOfTaks(tasks, file.cat_id).full_name,
          uploadedUser: file.uploaded_user ? file.uploaded_user : this._findUserOfTaks(tasks, file.cat_id),
          url: file.path + "/" + file.name
        }
        defaultNum++;
        return doc;
      })
      documents.push(...relatedDocs);

      
      const customDocs: DocumentData[] = additionalTpFiles.map(file => {
        const doc: DocumentData = {
          name: file.cat_name,
          type: this._getType(file.path + "/" + file.name),
          uploaded: file.created_at,
          uploadedBy: file.uploaded_user ? file.uploaded_user.full_name : 'N/A',
          uploadedUser: file.uploaded_user,
          url: file.path + "/" + file.name
        };
        return doc;
      })
      documents.push(...customDocs);
      const data: ModuleData = {
        documents: documents,
        lastBorder: num,
        lastBorderDefault: num + defaultNum,
      }
      return data;
    }));
  }

  showDocument(url: string) {
    if(url) {
      if (this.typesUtilsService.getFileType(url) === 'docx') {
        window.open(url, '_blank');
      } else {
        this.dialog.open(ImageViewerDialogComponent, {
          data: {
              picture: url,
              type: this.typesUtilsService.getFileType(url)
          }
        });
      }
    }
  }

  showUserInfo(event, popover: NgbPopover, user: User) {
    popover.popoverClass = "my-popover";
    if (popover.isOpen()) {
      popover.close();
    } else {
      const role = this._getRoleInfo(user.id);
      popover.open({
          role,
          qualification: user.qualification_name,
          email: user.email,
          agency: this.assignment.agency_name,
          user_pic: user.picture && user.picture.length > 0 ? this._getUserImageUrl(user.picture) : './assets/media/users/default.jpg'
      })
    }
  }
  
  _getUserImageUrl(path: string): string {
    return environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + path;
    // return 'http:/178.128.60.171:89/api/files/download?path=' + path;
  }

  _getRoleInfo(uId: number) {
    let roleInfo: string;
    if (this.toe.assignment.valuer_id === uId) {
        roleInfo = 'Project Manager';
        if (this._isWorker(uId)) {
            roleInfo += this._isLeadValuer(uId)
                ? ' & Lead Valuer'
                : ' & Supporting Staff';
        }
        return roleInfo;
    }
    roleInfo = this._isLeadValuer(uId)
        ? 'Lead Valuer'
        : 'Supporting Staff';
    return roleInfo;
  }

  _isWorker(uId: number): boolean {
    const worker = this.workers.filter(w => w.worker_id === uId);
    return worker.length > 0;
  }

  _isLeadValuer(uId: number): boolean {
      return this.workers[0].worker_id === uId;
  }

  _getType(url: string): string {
    if (url) {
      const type = this.typesUtilsService.getFileType(url);
      if (type === 'docx') {
        return 'Word'
      } else {
        return type.toUpperCase();
      }
    }
    return null;
  }

  _findUserOfTaks(tasks: any[], cat_id: number): User {

    const task = tasks.filter(t => t.task_id == 0).find(t => t.second_id == cat_id);
    if (task) {
      return task.assignee;
    }
    return null;
  }
  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
