<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
        </div>
        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;"
                   (click)="onSubmit()"
                   class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px">
                    Save
                </a>
            </span>
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="form" [formGroup]="form">
        <div class="card-body">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-8">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Input" formControlName="input"></textarea>
                            <mat-error>Input is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Status" formControlName="status">
                                <ng-container *ngFor="let enum of status | keyvalue">
                                    <mat-option *ngIf="!checkWrongValue(enum)" [value]="enum.key">
                                        {{enum.value}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="item.status !== status.Valid">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Justification" formControlName="justification"
                                rows="2">
                            </textarea>
                            <mat-error>Justification is <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>