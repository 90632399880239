// Submethod types of "Time Value of Money" method
export enum TVOMSubmethodType {
    TargetRateAndCapitalisationRate = 1,
    CapitalisationRate = 2,
    TargetRate = 3,
}

export enum TVOMMethodVersion {
    WriteOffIsLesserThanLease,
    WriteOffIsEqualToLeaseOrNA
}

export type TVOMRatesAndJustification = RateValues & RateJustifications;

export type RateValues = {
    capRate: number,
    targetRate: number,
}

export type RateJustifications = {
    capRateJustification: string,
    targetRateJustification: string,
}

export type TVOMMethodData = {
    headlineRent: number;
    rentReceivedFor: number;
    yearsPurchaseOfRentReceived: number;
    presentValueOfRentFree: number;
    capValOfHeadlineRent: number;
    capValOfInducements: number;
    leaseTermCapPeriod: number;
    yearsPurchaseOfLeaseTerm: number;
    presentValueOfFittingOut: number;
    deferredYearsPurchase: number;
    effectiveRent: number;
  }