import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ActivatedRouteSnapshot} from '@angular/router';
import {AppState} from '../../../../../core/reducers/index';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';
import {AssignmentsService} from '../../../../../core/assignment/_services/index';

@Injectable()
export class SaveToeIdResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public service: AssignmentsService,
                private global: Globals) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        this.global.activeTOEId = Number(route.paramMap.get('toe_id'));
        return true;
    }
}
