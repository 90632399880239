// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {GradesService} from '../_services/grade.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allGradesLoaded} from '../_selectors/grade.selectors';
// Actions
import {
    AllGradesLoaded,
    AllGradesRequested,
    GradeActionTypes,
    GradesPageRequested,
    GradesPageLoaded,
    GradeUpdated,
    GradesPageToggleLoading,
    GradeDeleted,
    GradeOnServerCreated,
    GradeCreated,
    GradeAdminRestored,
    GradeDeletedFromAdminTrash,
    GradeDeletedFromTrash,
    GradeOnServerAdminRestored,
    GradeOnServerRestored,
    GradeRestored,
    GradeTrashFlushed,
    GradesActionToggleLoading
} from '../_actions/grade.actions';

@Injectable()
export class GradeEffects {
    showPageLoadingDispatcher = new GradesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new GradesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new GradesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllGrades$ = this.actions$
        .pipe(
            ofType<AllGradesRequested>(GradeActionTypes.AllGradesRequested),
            withLatestFrom(this.store.pipe(select(allGradesLoaded))),
            filter(([action, isAllGradesLoaded]) => !isAllGradesLoaded),
            mergeMap(() => this.service.getAllGrades()),
            map(res => {
                return new AllGradesLoaded({grades: res.data});
            })
        );

    @Effect()
    loadGradesPage$ = this.actions$
        .pipe(
            ofType<GradesPageRequested>(GradeActionTypes.GradesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findGrades(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new GradesPageLoaded({
                    grades: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteGrade$ = this.actions$
        .pipe(
            ofType<GradeDeleted>(GradeActionTypes.GradeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteGrade(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateGrade$ = this.actions$
        .pipe(
            ofType<GradeUpdated>(GradeActionTypes.GradeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateGrade(payload.grade);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createGrade$ = this.actions$
        .pipe(
            ofType<GradeOnServerCreated>(GradeActionTypes.GradeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createGrade(payload.grade).pipe(
                    tap(res => {
                        this.store.dispatch(new GradeCreated({grade: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushGradeTrash$ = this.actions$
        .pipe(
            ofType<GradeTrashFlushed>(GradeActionTypes.GradeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreGrade$ = this.actions$
        .pipe(
            ofType<GradeOnServerRestored>(GradeActionTypes.GradeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new GradeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminGrade$ = this.actions$
        .pipe(
            ofType<GradeOnServerAdminRestored>(GradeActionTypes.GradeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new GradeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashGrade$ = this.actions$
        .pipe(
            ofType<GradeDeletedFromTrash>(GradeActionTypes.GradeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashGrade$ = this.actions$
        .pipe(
            ofType<GradeDeletedFromAdminTrash>(GradeActionTypes.GradeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: GradesService, private store: Store<AppState>) {
    }
}
