export enum CriterionCategories {
    Location = 1,
    Property = 2,
    Building = 3,
    Other = 4,
    ExternalAspect = 5,
    InternalAspect = 6,
    Ground = 7,
    LandParcel = 8
}

export const getCriterionCategories = (cat: CriterionCategories): string => {
    switch (cat) {
        case CriterionCategories.Location:
            return 'Location';
        case CriterionCategories.Property:
            return 'Property';
        case CriterionCategories.Building:
            return 'Building';
        case CriterionCategories.Other:
            return 'Other';
        case CriterionCategories.ExternalAspect:
            return 'External Aspect'
        case CriterionCategories.InternalAspect:
            return 'Internal Aspect'
        case CriterionCategories.Ground:
            return 'Ground'
        case CriterionCategories.LandParcel:
            return 'Land Parcel'
    }
}