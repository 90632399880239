// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TpFinancialTaskModel} from '../_models/tp-financial-task.model';

export enum TpFinancialTaskActionTypes {
    AllTpFinancialTaskRequested = '[Init] All TpFinancialTask Requested',
    AllTpFinancialTaskLoaded = '[Init] All TpFinancialTask Loaded',
    AllTpFinancialTaskLoadedByUser = '[Init] All TpFinancialTask Loaded By User',
    TpFinancialTaskCleared = '[Init] All TpFinancialTask cleared',

    TpFinancialTaskOnServerCreated = '[Edit TpFinancialTask Dialog] TpFinancialTask On Server Created',
    TpFinancialTaskCreated = '[Edit TpFinancialTask Dialog] TpFinancialTask Created',
    TpFinancialTaskUpdated = '[Edit TpFinancialTask Dialog] TpFinancialTask Updated',

    TpFinancialTaskActionToggleLoading = '[TpFinancialTask] TpFinancialTask Action Toggle Loading',

    TpFinancialTaskDeleted = '[TpFinancialTask List Page] TpFinancialTask Deleted',

    AllTpFinancialTaskByUserRequested = 'All TpFinancialTask By User Requested',
    TpFinancialTaskByUserPageRequested = 'All TpFinancialTask By User Page Requested',
    TpFinancialTaskByUserPageLoaded = 'All TpFinancialTask By User Page Loaded',
    TpFinancialTasksClear = 'clear tasks',
}

export class TpFinancialTaskCleared implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskCleared;
}

export class AllTpFinancialTaskRequested implements Action {
    readonly type = TpFinancialTaskActionTypes.AllTpFinancialTaskRequested;
}

export class AllTpFinancialTaskLoaded implements Action {
    readonly type = TpFinancialTaskActionTypes.AllTpFinancialTaskLoaded;

    constructor(public payload: { 
        tasks: TpFinancialTaskModel[],
    }) {
    }
}

export class AllTpFinancialTaskLoadedByUser implements Action {
    readonly type = TpFinancialTaskActionTypes.AllTpFinancialTaskLoadedByUser;

    constructor(public payload: { 
        tasks: TpFinancialTaskModel[],
        totalCount: number,
        page: QueryParamsModel,
    }) {
    }
}


export class TpFinancialTaskOnServerCreated implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskOnServerCreated;

    constructor(public payload: { tp_id: number, task: TpFinancialTaskModel }) {
    }
}

export class TpFinancialTaskCreated implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskCreated;

    constructor(public payload: { task: TpFinancialTaskModel }) {
    }
}

export class TpFinancialTaskUpdated implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskUpdated;

    constructor(public payload: {
        partialItem: Update<TpFinancialTaskModel>,
        item: TpFinancialTaskModel
    }) {
    }
}

export class TpFinancialTaskDeleted implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class TpFinancialTaskActionToggleLoading implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AllTpFinancialTaskByUserRequested implements Action {
    readonly type = TpFinancialTaskActionTypes.AllTpFinancialTaskByUserRequested;

    constructor(public payload: { page: QueryParamsModel, state: number, filterModel: any }) {
    }
}

export class TpFinancialTaskByUserPageRequested implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskByUserPageRequested;

    constructor(public payload: { page: QueryParamsModel, state: number }) {
    }
}

export class TpFinancialTaskByUserPageLoaded implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTaskByUserPageLoaded;

    constructor(public payload: {
        assetClasses: TpFinancialTaskModel[],
        totalCount: number,
        page: QueryParamsModel
    }) {
    }
}

export class TpFinancialTasksClear implements Action {
    readonly type = TpFinancialTaskActionTypes.TpFinancialTasksClear;

    constructor() {
    }
}

export type TpFinancialTaskActions = AllTpFinancialTaskRequested |
    AllTpFinancialTaskLoaded |
    TpFinancialTaskActionToggleLoading |
    TpFinancialTaskOnServerCreated |
    TpFinancialTaskCreated |
    TpFinancialTaskUpdated |
    TpFinancialTaskCleared |
    TpFinancialTaskDeleted |
    AllTpFinancialTaskByUserRequested |
    TpFinancialTaskByUserPageRequested |
    TpFinancialTaskByUserPageLoaded | 
    AllTpFinancialTaskLoadedByUser |
    TpFinancialTasksClear;
