<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <div class="card-label">Size Table</div>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="close()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <div class="row" style="width: 100%;" *ngIf="info">
                <kt-sizes-module
                    class="col-lg-12"
                    [assetClassID]="info.ac_id"
                    [sizeChanges$]="info.sizeChanges$"
                    [floorAction$]="info.floorAction$"
                    [bedroom$]="info.bedrooms$"
                    [roomChange$]="info.roomChanges$"
                    [externalAreas$]="info.externalAreas$"
                    [readonly]="info.readonly">

                </kt-sizes-module>
            </div>
        </mat-dialog-content>
    </div>
</div>