import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { Store, StoreModule } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { toeTemplateReducer, tpTempalteReducer, ToeTemplateEffects, TpTemplateEffects, ToeTemplateService, TpTemplateService, externalRefTemplateReducer, ExternalRefTemplateService, ExternalRefTemplateEffects, adjustmentTemplateReducer, AdjustmentTemplateEffects, AdjustmentTemplateService, criterionsTemplateReducer, CriterionsTemplateService, teamTemplateReducer, TeamTemplateEffects, TeamTemplateService } from "src/app/core/template";
import { CriterionsTemplateEffects } from "src/app/core/template/_effects/criterions-template.effects";
import { PartialsModule } from "../../partials/partials.module";
import { SharedModule } from "../shared_components/shared.module";
import { TemplateListComponent } from "./template-list/template-list.component";
import { AdjustmentTemplateEditComponent } from "./templates/adjustment-template/adjustment-template-edit/adjustment-template-edit.component";
import { AdjustmentTemplateComponent } from "./templates/adjustment-template/adjustment-template.component";
import { AdjustmentFormComponent } from "./templates/adjustment-template/_sub/adjustment-form/adjustment-form.component";
import { AdjustmentTemplateBodyComponent } from "./templates/adjustment-template/_sub/adjustment-template-body/adjustment-template-body.component";
import { AdjustmentTemplateModalComponent } from "./templates/adjustment-template/_sub/adjustment-template-modal/adjustment-template-modal.component";
import { CriterionsTemplateEditComponent } from "./templates/criterions-template/criterions-template-edit/criterions-template-edit.component";
import { CriterionsTemplateComponent } from "./templates/criterions-template/criterions-template.component";
import { CriterionsTemplateBodyComponent } from "./templates/criterions-template/_sub/criterions-template-body/criterions-template-body.component";
import { CriterionsTemplateModalComponent } from "./templates/criterions-template/_sub/criterions-template-modal/criterions-template-modal.component";
import { CustomCriterionModalComponent } from "./templates/criterions-template/_sub/custom-criterion-modal/custom-criterion-modal.component";
import { DefaultCriterionsModalComponent } from "./templates/criterions-template/_sub/default-criterions-modal/default-criterions-modal.component";
import { ExternalRefTemplateEditComponent } from "./templates/external-ref-template/external-ref-template-edit/external-ref-template-edit.component";
import { ExternalRefTemplateComponent } from "./templates/external-ref-template/external-ref-template.component";
import { ExternalRefTemplateBodyComponent } from "./templates/external-ref-template/_sub/external-ref-template-body/external-ref-template-body.component";
import { ExternalRefTemplateModalComponent } from "./templates/external-ref-template/_sub/external-ref-template-modal/external-ref-template-modal.component";
import { TeamTemplateEditComponent } from "./templates/team-template/team-template-edit/team-template-edit.component";
import { TeamTemplateComponent } from "./templates/team-template/team-template.component";
import { TeamTemplateBodyComponent } from "./templates/team-template/_sub/team-template-body/team-template-body.component";
import { TeamTemplateModalComponent } from "./templates/team-template/_sub/team-template-modal/team-template-modal.component";
import { ToeTemplateComponent } from "./templates/toe-template/toe-template.component";
import { TpTemplateEditComponent } from "./templates/tp-template/tp-template-edit/tp-template-edit.component";
import { TpTemplateBodyComponent } from "./templates/tp-template/_sub/tp-template-body/tp-template-body.component";
import { TpTemplateModalComponent } from "./templates/tp-template/_sub/tp-template-modal/tp-template-modal.component";
import { AcValuationComponent } from "./templates/_sub/ac-valuation/ac-valuation.component";
import { AddValuationModalComponent } from "./templates/_sub/ac-valuation/add-valuation-modal/add-valuation-modal.component";
import { AddAdModalComponent } from "./templates/_sub/assumptions-and-departures/add-ad-modal/add-ad-modal.component";
import { AssumptionsSelectionComponent } from "./templates/_sub/assumptions-selection/assumptions-selection.component";
import { DeparturesSelectionComponent } from "./templates/_sub/departures-selection/departures-selection.component";
import { SpecialAssumptionsSelectionComponent } from "./templates/_sub/special-assumptions-selection/special-assumptions-selection.component";
import { RouterLink, provideRouter } from "@angular/router";
import { TemplateListV2Component } from "./template-list-v2/template-list-v2.component";
import { QuoteTemplateListComponent } from "./templates/quote-template/quote-template-list.component";
import { QuoteTemplateEditComponent } from "./templates/quote-template/quote-template-edit/quote-template-edit.component";
import { TabHeaderModule } from "../mad_shared/tab-header/tab-header.module";
import { ClientService } from "src/app/core/admin/_services/client.service";
import { AddMemberDialogModule } from "../mad_shared/add-member-dialog/add-member-dialog.module";
import { MadSharedModule } from "../mad_shared/shared.module";
import { MadFormsModule } from "../mad-forms/mad-form.module";
import { ImageViewerModule } from "../mad_shared/image-viewer/image-viewer.module";
import { AddAdditionalCostModule } from "../mad_shared/add-additional-cost/add-additional-cost.module";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PartialsModule,
        TranslateModule.forChild(),
        SharedModule,
        NgbModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatProgressBarModule,
        MatCardModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
        NgxMatSelectSearchModule,
        MatDialogModule,
        NgxMaskDirective,
        NgxMaskPipe,
        StoreModule.forFeature('toe-template', toeTemplateReducer),
        StoreModule.forFeature('tp-template', tpTempalteReducer),
        StoreModule.forFeature('external-ref-template', externalRefTemplateReducer),
        StoreModule.forFeature('adjustment-template', adjustmentTemplateReducer),
        StoreModule.forFeature('criterions-template', criterionsTemplateReducer),
        StoreModule.forFeature('team-template', teamTemplateReducer),
        EffectsModule.forFeature([ToeTemplateEffects, TpTemplateEffects, ExternalRefTemplateEffects, AdjustmentTemplateEffects, CriterionsTemplateEffects, TeamTemplateEffects]),
        SharedModule,

        TabHeaderModule,
        RouterLink,
        AddMemberDialogModule,
        MadSharedModule,
        MadFormsModule,
        ImageViewerModule,
        AddAdditionalCostModule
    ],
    providers: [
        ToeTemplateService,
        TpTemplateService,
        ExternalRefTemplateService,
        AdjustmentTemplateService,
        CriterionsTemplateService,
        TeamTemplateService,
        ClientService,
        provideNgxMask(options)
    ],
    declarations: [
        ToeTemplateComponent,
        TemplateListComponent,
        TpTemplateEditComponent,
        AcValuationComponent,
        AddValuationModalComponent,
        AddAdModalComponent,
        AssumptionsSelectionComponent,
        SpecialAssumptionsSelectionComponent,
        DeparturesSelectionComponent,
        TpTemplateBodyComponent,
        TpTemplateModalComponent,
        ExternalRefTemplateComponent,
        ExternalRefTemplateEditComponent,
        ExternalRefTemplateBodyComponent,
        ExternalRefTemplateModalComponent,
        AdjustmentTemplateComponent,
        AdjustmentTemplateEditComponent,
        AdjustmentTemplateBodyComponent,
        AdjustmentTemplateModalComponent,
        AdjustmentFormComponent,
        CriterionsTemplateComponent,
        CriterionsTemplateEditComponent,
        CriterionsTemplateBodyComponent,
        CriterionsTemplateModalComponent,
        DefaultCriterionsModalComponent,
        CustomCriterionModalComponent,
        TeamTemplateComponent,
        TeamTemplateEditComponent,
        TeamTemplateBodyComponent,
        TeamTemplateModalComponent,
        TemplateListV2Component,
        QuoteTemplateListComponent,
        QuoteTemplateEditComponent
    ],
    exports: [
        TemplateListComponent,
        ToeTemplateComponent,
        TpTemplateEditComponent,
        TpTemplateModalComponent,
        ExternalRefTemplateComponent,
        ExternalRefTemplateEditComponent,
        ExternalRefTemplateModalComponent,
        AdjustmentTemplateComponent,
        AdjustmentTemplateEditComponent,
        AdjustmentTemplateModalComponent,
        CriterionsTemplateComponent,
        CriterionsTemplateEditComponent,
        CriterionsTemplateModalComponent,
        TeamTemplateComponent,
        TeamTemplateEditComponent,
        TeamTemplateModalComponent,
        QuoteTemplateListComponent,
        TemplateListV2Component,
        QuoteTemplateEditComponent
    ]
})
export class TemplateFeatureModule {}