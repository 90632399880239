<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{'Warning'}}</h3>
		</div>
	</div>
	<div class="form">
		<div class="card-body">
			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<div class="form-group row">
				<div class="col-lg-12 text-center" *ngIf="subTitle.length > 0">
					<b>{{ subTitle }}</b><br><br>
				</div>

				<div class="col-lg-12 text-center">
					<b>{{ description1 }}</b>
				</div>

				<ng-container *ngIf="toe_cnt_ongoing == 0 && toe_cnt_completed == 0"> 
					<div class="col-lg-12 text-center" *ngIf="assignments_cnt > 0">
						<b><span style="color: darkred;">{{ assignments_cnt }}</span> Assignments</b>
					</div>
					<div class="col-lg-12 text-center" *ngIf="toe_cnt_draft > 0">
						<b><span style="color: darkred;">{{ toe_cnt_draft }}</span>  ToEs(Draft)</b>
					</div>
					<div class="col-lg-12 text-center" *ngIf="audits_cnt > 0">
						<b><span style="color: darkred;">{{ audits_cnt }}</span> Audit trail records</b>
					</div>
				</ng-container>

				<div class="col-lg-12 text-center" *ngIf="toe_cnt_ongoing > 0">
					<b><span style="color: darkred;">{{ toe_cnt_ongoing }}</span> ToEs(Ongoing)</b>
				</div>
				<div class="col-lg-12 text-center" *ngIf="toe_cnt_completed > 0">
					<b><span style="color: darkred;">{{ toe_cnt_completed }}</span> ToEs(Completed)</b>
				</div>
				
				<div class="col-lg-12 text-center">
					<br><b>{{ description2 }}</b>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-center">
				<div class="mr-2"></div>
				<div *ngIf="toe_cnt_ongoing == 0 && toe_cnt_completed == 0">
					<button mat-button (click)="onYesClick()" style="color:darkgreen" cdkFocusInitial>YES</button>&nbsp;
					<button mat-button (click)="onNoClick()" style="color:darkred">NO</button>
				</div>
				<div *ngIf="toe_cnt_ongoing > 0 || toe_cnt_completed > 0">
					<button mat-button (click)="onNoClick()">CLOSE</button>
				</div>
			</div>
		</div>
	</div>
</div>