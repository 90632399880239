import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { SubdomainService } from "../../_base/subdomain.service";
import { APIPaginatedResponse, APIResponse } from "../types/api-response";
import { RentReviewType, RentReviewTypeListing } from "../types/rent-review-type";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RentReviewTypeService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  fetchListing() {
    return this.http.get<APIResponse<RentReviewTypeListing>>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types/listing`,
      {
        headers: this.authService.getAuthHeaders()
      }
    ).pipe(
      map(response => response.data)
    )
  }

  fetch() {
    return this.http.get<APIPaginatedResponse<RentReviewType>>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types`,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }

  create(payload: RentReviewType) {
    return this.http.post<unknown>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types`,
      payload,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }

  update(id: number, payload: RentReviewType) {
    return this.http.put<unknown>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types/${id}`,
      payload,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }

  get(id: number) {
    return this.http.get<{data: RentReviewType}>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types/${id}`,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }
  
  delete(id: number) {
    return this.http.delete<unknown>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types/${id}`,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }

  trashed() {
    return this.http.get<{data: RentReviewType[]}>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types/trashed`,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }

  adminTrashed() {
    return this.http.get<{data: RentReviewType[]}>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/rent-review-types/admin-trashed`,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }
}