import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../../core/_base/layout';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
import {select, Store} from '@ngrx/store';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {BehaviorSubject, fromEvent, merge, of, Subscription} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {TranslateService} from '@ngx-translate/core';

import {
  AdminService, AgencyActionToggleLoading,
  Client, ClientDeletedFromAdminTrash, ClientOnServerAdminRestored,
  ClientsDataSource,
  ClientTrashFlushed, selectAdminTrashedClientsCount, selectTrashedClientsCount
} from '../../../../../core/admin';
// ACTIONS
import {
  ClientsPageRequested,
  ClientDeleted,
  ClientOnServerRestored,
  ClientDeletedFromTrash
} from '../../../../../core/admin';
import {ContactOnServerCreated} from '../../../../../core/admin/_actions/contact.actions';
import {ClientContactModel} from '../../../../../core/admin';
import { Option } from '../../../shared_components/filter/f-selection/f-selection.component';
import { ContactEditDialogComponent } from '../../../shared_components/client/_subs/client_contact/contact-edit/contact-edit.dialog.component';
import { currentUser, hasPermission, User } from 'src/app/core/mad-auth/mad-auth.store';

type pmStatus = {
  total_clients: string,
  active_clients: string,
}

@Component({
  selector: 'kt-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit, OnDestroy {
  dataSource: ClientsDataSource;
  displayedColumns = ['id', 'name', 'type', 'country_name', 'city_name', 'toe_cnt', 'actions'];

  trashCnt = 0;
  adminTrashCnt = 0;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  // Selection
  selection = new SelectionModel<Client>(true, []);
  clientResult: Client[] = [];
  // trashClientResult: ClientModel[] = [];
  clientStatusCnts$ = new BehaviorSubject<pmStatus>({
    total_clients: '0',
    active_clients: '0',
  });  
  filter: any;
  types: Option[] = [{id: -1, text: 'All'}, {id: 0, text: 'Company'}, {id: 1, text: 'Individual'}];

  canEdit$ = this.store.pipe(
    select(hasPermission(['client:crud']))
  )
  canAdminThrash$ = this.store.pipe(
    select(hasPermission(['admin_trash']))
  )
  currentUser: User|null;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param activatedRoute
   * @param router
   * @param dialog: MatDialog
   * @param snackBar: MatSnackBar
   * @param clientService
   * @param layoutConfigService
   * @param subheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   */
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public clientService: AdminService,
              public layoutConfigService: LayoutUtilsService,
              public subheaderService: SubheaderService,
              private layoutUtilsService: LayoutUtilsService,
              private translate: TranslateService,
              private store: Store<AppState>) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    const userSub = this.store.select(currentUser).subscribe(user => {
      this.currentUser = user
    })
    this.subscriptions.push(userSub)

    this.subheaderService.setTitle('Clients');
    this.paginator._intl.itemsPerPageLabel = this.translate.instant('GENERAL.PAGELABEL');
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadClientsList())
    ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const selectTrashedSubscription = this.store.pipe(
      select(selectTrashedClientsCount)
    ).subscribe(res => {
      this.trashCnt = res;
    });
    this.subscriptions.push(selectTrashedSubscription);

    const selectAdminTrashedSubscription = this.store.pipe(
      select(selectAdminTrashedClientsCount)
    ).subscribe(res => {
      this.adminTrashCnt = res;
    });
    this.subscriptions.push(selectAdminTrashedSubscription);

    // Init DataSource
    this.dataSource = new ClientsDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.clientResult = res;
      this.clientStatusCnts$.next(
        {
          total_clients: res.length.toString(),
          active_clients: res.filter(item => item.toe_cnt > 0).length.toString()
        }
      )
    });

    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadClientsList();
    }); // Remove this line, just loading imitation
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load Clients List from service through data-source
   */
  loadClientsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filter,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(new ClientsPageRequested({page: queryParams}));
    this.selection.clear();
  }

  /** ACTIONS */
  /**
   * Delete client
   *
   * @param _item: ClientModel
   */
  deleteClient(_item: Client) {
    const _title = this.translate.instant('CLIENT.LIST.DIALOG.DELETE.TITLE');
    const _description = this.translate.instant('CLIENT.LIST.DIALOG.DELETE.DESCRIPTION', { name: _item.name });
    const _waitDesciption = this.translate.instant('CLIENT.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.store.dispatch(new ClientDeleted({id: _item.id}));
    });
  }

  /**
   * Fetch selected clients
   */
  fetchClients() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.name}`,
        id: elem.id.toString()
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }

  // Fetch trash data
  trash() {
    // this.store.dispatch(new ClientActionToggleLoading({isLoading: true}));
    this.clientService.getTrashedClients().subscribe(res => {
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `${elem.name}`,
          id: elem.id.toString(),
          date: elem.user_deleted,
          status: elem.city_name,
          deletedUser: elem.userDeletedBy,
          hasPermanentlyDelete: true
        });
      });
      this.show_trash(items);
    });
  }

  // Fetch admin trash data
  adminTrash() {
    this.store.dispatch(new AgencyActionToggleLoading({isLoading: true}));
    this.clientService.getAdminTrashedClients().subscribe(res => {
      this.store.dispatch(new AgencyActionToggleLoading({isLoading: false}));
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `${elem.name}`,
          id: elem.id.toString(),
          hasPermanentlyDelete: true,
          status: elem.city_name,
          date: elem.deleted_at,
          deletedUser: elem.deletedBy,
        });
      });
      this.show_trash(items, true);
    });
  }

  /**
   * Show Edit client dialog and save after success close result
   * @param items
   */
  show_trash(items: any[], isAdmin = false) {
    let _title = this.translate.instant('CLIENT.LIST.DIALOG.SHOW_TRASH.TITLE');
    if (isAdmin) {
      _title = this.translate.instant('CLIENT.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
    }
    this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
  }

  restore(id: number, isAdmin: boolean) {
    if (isAdmin) {
      this.store.dispatch(new ClientOnServerAdminRestored({clientId: id}));
    } else {
      this.store.dispatch(new ClientOnServerRestored({clientId: id}));
    }
  }

  delete(id: number) {
    this.store.dispatch(new ClientDeletedFromTrash({clientId: id}));
  }

  hardDelete(id: number) {
    this.store.dispatch(new ClientDeletedFromAdminTrash({id}));
  }


  flush() {
    this.store.dispatch(new ClientTrashFlushed());
  }

  /**
   * Show add client dialog
   */
  addClient() {
    this.editClient(null);
  }

  /**
   * Show Edit client dialog and save after success close result
   * @param _client
   */
  editClient(_client) {
    if (_client) {
      this.router.navigate(['../clients/edit', _client.id], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['../clients/add'], {relativeTo: this.activatedRoute});
    }
  }

  // Add Contact
  addContact(_client: Client) {
    if (_client.contact_cnt >= 1 && _client.type == 'Individual') {
      return;
    }
    const contact = new ClientContactModel();
    contact.clear(); // Set all defaults fields
    const _config = {
      data: {
        contactId: contact.id,
        clientName: (_client.type == 'Individual') ? _client.name + ' ' + _client.last_name : _client.name
      }
    };
    const dialogRef = this.dialog.open(ContactEditDialogComponent, _config);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const _contact = res.contact;
      _contact.client_id = _client.id;

      this.store.dispatch(new ContactOnServerCreated({contact: _contact}));
      // lastCreatedContactId
      of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
        this.loadClientsList();
      });
    });
  }

  // Contact List
  contactList(_client) {
    if (_client.contact_cnt >= 1 && _client.type == 'Individual') {
      return;
    }
    this.editClient(_client);
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle all selections
   */
  masterToggle() {
    if (this.selection.selected.length === this.clientResult.length) {
      this.selection.clear();
    } else {
      this.clientResult.forEach(row => this.selection.select(row));
    }
  }

  onFilterChange(event) {
    this.paginator.pageIndex = 0;
    this.filter = event;
    this.loadClientsList();
  }
}
