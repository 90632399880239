// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {CubicContent} from '../_models/cubic-content.model';

export enum CubicContentActionTypes {

    AllCubicContentsRequested = '[CubicContents Home Page] All CubicContents Requested',
    AllCubicContentsLoaded = '[CubicContents API] All CubicContents Loaded',

    CubicContentOnServerCreated = '[Edit CubicContent Dialog] CubicContent On Server Created',
    CubicContentCreated = '[Edit CubicContents Dialog] CubicContents Created',
    CubicContentUpdated = '[Edit CubicContent Dialog] CubicContent Updated',
    CubicContentDeleted = '[CubicContents List Page] CubicContent Deleted',

    CubicContentsPageRequested = '[CubicContents List Page] CubicContents Page Requested',
    CubicContentsPageLoaded = '[CubicContents API] CubicContents Page Loaded',
    CubicContentsPageCancelled = '[CubicContents API] CubicContents Page Cancelled',

    CubicContentsPageToggleLoading = '[CubicContents page] CubicContents Page Toggle Loading',
    CubicContentsActionToggleLoading = '[CubicContents] CubicContents Action Toggle Loading',

    // trash system

    CubicContentOnServerRestored = '[CubicContent Trash] CubicContent On Server Restored',
    CubicContentRestored = '[CubicContent Trash] CubicContent Restored',

    CubicContentOnServerAdminRestored = '[CubicContent Admin Trash] CubicContent On Server Restored',
    CubicContentAdminRestored = '[CubicContent Admin Trash] CubicContent Restored',

    CubicContentDeletedFromTrash = '[CubicContent Trash] CubicContent deleted',
    CubicContentDeletedFromAdminTrash = '[CubicContent Admin Trash] CubicContent deleted',

    CubicContentTrash = 'CubicContent Trashed',
    CubicContentTrashFlushed = 'CubicContent Trash Flushed',

    CubicContentsClear = '[CubicContents API] CubicContents Page Clear',
}

export class CubicContentOnServerCreated implements Action {
    readonly type = CubicContentActionTypes.CubicContentOnServerCreated;

    constructor(public payload: { cubicContent: CubicContent}) {
    }
}

export class CubicContentCreated implements Action {
    readonly type = CubicContentActionTypes.CubicContentCreated;

    constructor(public payload: { cubicContent: CubicContent }) {
    }
}

export class CubicContentUpdated implements Action {
    readonly type = CubicContentActionTypes.CubicContentUpdated;

    constructor(public payload: {
        partialCubicContent: Update<CubicContent>,
        cubicContent: CubicContent
    }) {
    }
}

export class CubicContentDeleted implements Action {
    readonly type = CubicContentActionTypes.CubicContentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class CubicContentsPageRequested implements Action {
    readonly type = CubicContentActionTypes.CubicContentsPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class CubicContentsPageLoaded implements Action {
    readonly type = CubicContentActionTypes.CubicContentsPageLoaded;

    constructor(public payload: { cubicContents: CubicContent[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class CubicContentsPageCancelled implements Action {
    readonly type = CubicContentActionTypes.CubicContentsPageCancelled;
}

export class AllCubicContentsRequested implements Action {
    readonly type = CubicContentActionTypes.AllCubicContentsRequested;
}

export class AllCubicContentsLoaded implements Action {
    readonly type = CubicContentActionTypes.AllCubicContentsLoaded;

    constructor(public payload: { cubicContents: CubicContent[] }) {
    }
}

export class CubicContentsPageToggleLoading implements Action {
    readonly type = CubicContentActionTypes.CubicContentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class CubicContentsActionToggleLoading implements Action {
    readonly type = CubicContentActionTypes.CubicContentsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class CubicContentDeletedFromAdminTrash implements Action {
    readonly type = CubicContentActionTypes.CubicContentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class CubicContentDeletedFromTrash implements Action {
    readonly type = CubicContentActionTypes.CubicContentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CubicContentOnServerRestored implements Action {
    readonly type = CubicContentActionTypes.CubicContentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CubicContentRestored implements Action {
    readonly type = CubicContentActionTypes.CubicContentRestored;

    constructor(public payload: { item: CubicContent }) {
    }
}

export class CubicContentOnServerAdminRestored implements Action {
    readonly type = CubicContentActionTypes.CubicContentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CubicContentAdminRestored implements Action {
    readonly type = CubicContentActionTypes.CubicContentAdminRestored;

    constructor(public payload: { item: CubicContent }) {
    }
}

export class CubicContentTrashFlushed implements Action {
    readonly type = CubicContentActionTypes.CubicContentTrashFlushed;

    constructor() {
    }
}

export class CubicContentsClear implements Action {
    readonly type = CubicContentActionTypes.CubicContentsClear;

    constructor() {
    }
}

export type CubicContentActions = CubicContentCreated
    | CubicContentUpdated
    | CubicContentDeleted
    | CubicContentsPageRequested
    | CubicContentsPageLoaded
    | CubicContentsPageCancelled
    | AllCubicContentsLoaded
    | AllCubicContentsRequested
    | CubicContentOnServerCreated
    | CubicContentsPageToggleLoading
    | CubicContentsActionToggleLoading
    | CubicContentDeletedFromAdminTrash
    | CubicContentDeletedFromTrash
    | CubicContentOnServerRestored
    | CubicContentRestored
    | CubicContentOnServerAdminRestored
    | CubicContentAdminRestored
    | CubicContentTrashFlushed
    | CubicContentsClear;
