// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ConstructionTypeActions, ConstructionTypeActionTypes} from '../_actions/construction-type.actions';
// Models
import {ConstructionType} from '../_models/construction-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ConstructionTypesState extends EntityState<ConstructionType> {
    isAllConstructionTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ConstructionType[];
    lastCreatedConstructionTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ConstructionType> = createEntityAdapter<ConstructionType>();

export const initialConstructionTypesState: ConstructionTypesState = adapter.getInitialState({
    isAllConstructionTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedConstructionTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function constructionTypesReducer(state = initialConstructionTypesState, action: ConstructionTypeActions): ConstructionTypesState {
    switch (action.type) {
        case ConstructionTypeActionTypes.ConstructionTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedConstructionTypeId: undefined
            };
        case ConstructionTypeActionTypes.ConstructionTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ConstructionTypeActionTypes.ConstructionTypeOnServerCreated:
            return {
                ...state
            };
        case ConstructionTypeActionTypes.ConstructionTypeCreated:
            return adapter.addOne(action.payload.constructionType, {
                ...state, lastCreatedConstructionTypeId: action.payload.constructionType.id
            });
        case ConstructionTypeActionTypes.ConstructionTypeUpdated:
            return adapter.updateOne(action.payload.partialConstructionType, state);
        case ConstructionTypeActionTypes.AllConstructionTypesLoaded:
            return adapter.addAll(action.payload.constructionTypes, {
                ...state, isAllConstructionTypesLoaded: true
            });
        case ConstructionTypeActionTypes.ConstructionTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ConstructionTypeActionTypes.ConstructionTypesPageLoaded:
            return adapter.addMany(action.payload.constructionTypes, {
                ...initialConstructionTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case ConstructionTypeActionTypes.ConstructionTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case ConstructionTypeActionTypes.ConstructionTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case ConstructionTypeActionTypes.ConstructionTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case ConstructionTypeActionTypes.ConstructionTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case ConstructionTypeActionTypes.ConstructionTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case ConstructionTypeActionTypes.ConstructionTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
