// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {StandardMeasurement} from '../_models/standard-measurement.model';

export enum StandardMeasurementActionTypes {

    AllStandardMeasurementsRequested = '[StandardMeasurements Home Page] All StandardMeasurements Requested',
    AllStandardMeasurementsLoaded = '[StandardMeasurements API] All StandardMeasurements Loaded',

    StandardMeasurementOnServerCreated = '[Edit StandardMeasurement Dialog] StandardMeasurement On Server Created',
    StandardMeasurementCreated = '[Edit StandardMeasurements Dialog] StandardMeasurements Created',
    StandardMeasurementUpdated = '[Edit StandardMeasurement Dialog] StandardMeasurement Updated',
    StandardMeasurementDeleted = '[StandardMeasurements List Page] StandardMeasurement Deleted',

    StandardMeasurementsPageRequested = '[StandardMeasurements List Page] StandardMeasurements Page Requested',
    StandardMeasurementsPageLoaded = '[StandardMeasurements API] StandardMeasurements Page Loaded',
    StandardMeasurementsPageCancelled = '[StandardMeasurements API] StandardMeasurements Page Cancelled',

    StandardMeasurementsPageToggleLoading = '[StandardMeasurements page] StandardMeasurements Page Toggle Loading',
    StandardMeasurementsActionToggleLoading = '[StandardMeasurements] StandardMeasurements Action Toggle Loading',

    // trash system

    StandardMeasurementOnServerRestored = '[StandardMeasurement Trash] StandardMeasurement On Server Restored',
    StandardMeasurementRestored = '[StandardMeasurement Trash] StandardMeasurement Restored',

    StandardMeasurementOnServerAdminRestored = '[StandardMeasurement Admin Trash] StandardMeasurement On Server Restored',
    StandardMeasurementAdminRestored = '[StandardMeasurement Admin Trash] StandardMeasurement Restored',

    StandardMeasurementDeletedFromTrash = '[StandardMeasurement Trash] StandardMeasurement deleted',
    StandardMeasurementDeletedFromAdminTrash = '[StandardMeasurement Admin Trash] StandardMeasurement deleted',

    StandardMeasurementTrash = 'StandardMeasurement Trashed',
    StandardMeasurementTrashFlushed = 'StandardMeasurement Trash Flushed',
}

export class StandardMeasurementOnServerCreated implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementOnServerCreated;

    constructor(public payload: { standardMeasurement: StandardMeasurement }) {
    }
}

export class StandardMeasurementCreated implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementCreated;

    constructor(public payload: { standardMeasurement: StandardMeasurement }) {
    }
}

export class StandardMeasurementUpdated implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementUpdated;

    constructor(public payload: {
        partialstandardMeasurement: Update<StandardMeasurement>,
        standardMeasurement: StandardMeasurement
    }) {
    }
}

export class StandardMeasurementDeleted implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class StandardMeasurementsPageRequested implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class StandardMeasurementsPageLoaded implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementsPageLoaded;

    constructor(public payload: { standardMeasurements: StandardMeasurement[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class StandardMeasurementsPageCancelled implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementsPageCancelled;
}

export class AllStandardMeasurementsRequested implements Action {
    readonly type = StandardMeasurementActionTypes.AllStandardMeasurementsRequested;
}

export class AllStandardMeasurementsLoaded implements Action {
    readonly type = StandardMeasurementActionTypes.AllStandardMeasurementsLoaded;

    constructor(public payload: { standardMeasurements: StandardMeasurement[] }) {
    }
}

export class StandardMeasurementsPageToggleLoading implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class StandardMeasurementsActionToggleLoading implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class StandardMeasurementDeletedFromAdminTrash implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class StandardMeasurementDeletedFromTrash implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class StandardMeasurementOnServerRestored implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class StandardMeasurementRestored implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementRestored;

    constructor(public payload: { item: StandardMeasurement }) {
    }
}

export class StandardMeasurementOnServerAdminRestored implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class StandardMeasurementAdminRestored implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementAdminRestored;

    constructor(public payload: { item: StandardMeasurement }) {
    }
}

export class StandardMeasurementTrashFlushed implements Action {
    readonly type = StandardMeasurementActionTypes.StandardMeasurementTrashFlushed;

    constructor() {
    }
}

export type StandardMeasurementActions = StandardMeasurementCreated
    | StandardMeasurementUpdated
    | StandardMeasurementDeleted
    | StandardMeasurementsPageRequested
    | StandardMeasurementsPageLoaded
    | StandardMeasurementsPageCancelled
    | AllStandardMeasurementsLoaded
    | AllStandardMeasurementsRequested
    | StandardMeasurementOnServerCreated
    | StandardMeasurementsPageToggleLoading
    | StandardMeasurementsActionToggleLoading
    | StandardMeasurementDeletedFromAdminTrash
    | StandardMeasurementDeletedFromTrash
    | StandardMeasurementOnServerRestored
    | StandardMeasurementRestored
    | StandardMeasurementOnServerAdminRestored
    | StandardMeasurementAdminRestored
    | StandardMeasurementTrashFlushed;
