import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, skip, take, takeUntil, tap } from 'rxjs/operators';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { selectValuationFullComparables, selectValuationProcessLoadingState, selectValuationProcessValuation, ValuationProcessValuationModel } from 'src/app/core/valuation-process';
import { FullComparablesRequested, LoadValuationComparables } from 'src/app/core/valuation-process/_actions/valuation-comparable.actions';
import { ValuationComparableMapData } from 'src/app/core/valuation-process/_models/valuation-comparable.model';
import { PropertySubTypes, ValuationProcessTargetPropertyModel } from 'src/app/core/valuation-process/_models/valuation-process-target-property.model';
import { selectValuationComparables, selectValuationComparableSelectedPropertySubTypeIds } from 'src/app/core/valuation-process/_selectors/valuation-comparable.selectors';
import { selectValuationProcessSelectedComparables } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';
import { emptyFilter, FilterModel, FilterChange } from 'src/app/views/pages/target-property/valuation-process/_sub/filter-section-all/filter-section-all.component';
import { ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput } from '../../_sub/comparable-info-dialog-v2/comparable-info-dialog-v2.component';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowData } from '../../_sub/map-marker-info-window/map-marker-info-window.component';
import { ValuationComparableMapInfoDialogComponent, ValuationComparableMapInfoDialogData } from '../../_sub/valuation-comparable-map-info-dialog/valuation-comparable-map-info-dialog.component';

@Component({
  selector: 'kt-comparables-map-v2',
  templateUrl: './comparables-map-v2.component.html',
  styleUrls: ['./comparables-map-v2.component.scss']
})
export class ComparablesMapV2Component implements OnInit, OnDestroy {
  @Input() currentUser?: User;
  @Input() readonly: boolean = false
  @Input() valuation: ValuationProcessValuationModel
  @Input() targetProperty: ValuationProcessTargetPropertyModel

  private _filterModel: FilterModel = emptyFilter()
  private _filter$ = new BehaviorSubject<FilterModel>(this._filterModel)
  filter$ = this._filter$.asObservable()

  // Map
  private mapAction = new BehaviorSubject<boolean>(true);
  private isFirst = true;
  private map;

  // Data
  comparablesCount$ = this.store$.select(selectValuationComparables).pipe(
    map(items => items.length)
  )
  comparablesMap$ = combineLatest([
    this.store$.select(selectValuationComparables),
    this.store$.select(selectValuationProcessSelectedComparables)
  ]).pipe(
    map(([comparables, refNums]) => {
      const _comparablesMap: Map<string, {
        key: string,
        label: any,
        title: string,
        latitude: number,
        longitude: number,
        coms: ValuationComparableMapData[]
      }> = new Map()
      comparables.forEach(comparable => {
        const key = comparable.latitude + '_' + comparable.longitude
        if (_comparablesMap.has(key)) {
          const data = _comparablesMap.get(key)
          data.title = data.title + '/' + comparable.refNum
          data.coms.push(comparable)
          data.label = this._getLabel(data.title, this.isSelected(refNums, data.coms.map(c => c.refNum)), comparable.propertySubType)
        } else {
          _comparablesMap.set(key, {
            key: key,
            title: comparable.refNum,
            label: this._getLabel(comparable.refNum, this.isSelected(refNums,  [comparable.refNum]), comparable.propertySubType),
            latitude: comparable.latitude,
            longitude: comparable.longitude,
            coms: [comparable]
          })
        }
      })
      this.mapAction.next(false)
      return _comparablesMap
    })
  )

  private isSelected(refNums: string[], comRefNums: string[]): boolean {
    return comRefNums.some(rn => refNums.includes(rn))
  }
  private _getLabel(title: string, isSelected: boolean, propertySubType: PropertySubTypes) {
    const className = this._getComparableColor(propertySubType, isSelected)
    return {
      color: '#000000',
      fontFamily: '',
      fontSize: '14px',
      fontWeight: 'bold',
      className: `bg-marker ${className}`,
      text: title,
    }
  }
  private _getComparableColor(propertySubType: PropertySubTypes, isSelected: boolean): string {
    const _prefix = 'bg-marker';
    let _type = ''

    switch (propertySubType) {
      case 'Apartment': {
        _type = 'apartment'
        break;
      }
      case 'House': { 
        _type = 'house'
        break;
      }
      case 'Land': {
        _type = 'land'
        break;
      }
      case 'Office': {
        _type = 'office';
        break;
      }
      case 'Parking': {
        _type = 'parking';
        break;
      }
      case 'Retail Building': {
        _type = 'retail_building';
        break;
      }
      case 'Retail Shop': {
        _type = 'retail_shop';
        break;
      }
      case 'Warehouse': {
        _type = 'warehouse';
        break;
      }
    }

    const _class = _type != '' ? `${_prefix}-${_type}` : _prefix
    return isSelected ? `${_class}-selected` : _class
  }
  identity(item) {
    return item.key;
  }

  icon = {
    url: './assets/media/icons/my_marker.svg',
  };
  tpIcon = {
    labelOrigin: { x: 18, y: 22 },
    url: './assets/media/icons/pin_red.svg',
    scaledSize: {
      width: 30,
      height: 30
    }
  }

  private ids: number[] = []
  private _onDestroy$: Subject<void> = new Subject()

  private _refSubject: Subject<string> = new Subject()

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.store$.select(selectValuationComparableSelectedPropertySubTypeIds).pipe(take(1)).subscribe(res => {
      this.ids = res;
      this._loadData()
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  onFilterChange(event: FilterChange) {
    this._filterModel = event.filter
    this.ids = event.acTypeIds

    this._loadData()
  }

  onBoundsChanged(latLngBound: google.maps.LatLngBounds) {
    const _new = Object.assign({}, this._filterModel) as FilterModel
    if (_new.bounds == null) {
      _new.bounds = { ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0 }
    } else {
      _new.bounds = Object.assign({}, _new.bounds)
    }
    _new.bounds.sw_lat = latLngBound.getSouthWest().lat();
    _new.bounds.ne_lat = latLngBound.getNorthEast().lat();
    _new.bounds.sw_lng = latLngBound.getSouthWest().lng();
    _new.bounds.ne_lng = latLngBound.getNorthEast().lng();

    this._filterModel = _new

    if (this.isFirst) {
      this.mapAction.next(true)
      this.isFirst = !this.isFirst
      this._loadData()
    }
  }
  onZoomChanged(_event) {
    this.isFirst = true
  }
  onMapReady(event) {
    this.map = event
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(
      document.getElementById('Profile')
    )

    this.map.addListener('dragend', () => {
      this.mapAction.next(true)
      this._loadData()
    })
  }

  private _loadData() {
    this._filterModel.status = 1
    const ids = this.ids.filter(id => id != this.targetProperty.generalInfo.propertySubTypeId)
    const propertySubTypeIds = [this.targetProperty.generalInfo.propertySubTypeId, ...ids];
    this.store$.dispatch(new LoadValuationComparables({ filter: { ...this._filterModel, propertySubTypeIds , valuation_id: this.valuation.valuationId} }))
  }

  onMarkerClick(coms: ValuationComparableMapData[]) {
    const refNums = coms.map(com => com.refNum)
    this.store$.dispatch(new FullComparablesRequested({ refNums }))
    const dialogRef = this.dialog.open<ValuationComparableMapInfoDialogComponent, ValuationComparableMapInfoDialogData>(
      ValuationComparableMapInfoDialogComponent, {
        data: {
          refNums: refNums,
          currentUser: this.currentUser,
        },
        viewContainerRef: this.viewContainerRef
    })

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }

      this.dialog.open<ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput>(ComparableInfoDialogV2Component, {
        data: {
          refNums: [res],
          isMulti: false
        },
        width: '80vw',
        viewContainerRef: this.viewContainerRef
      })
    })
  }
}
