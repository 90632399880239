<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Condition Rating</h3>
            <span class="card-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">

            <a href="javascript:;"
               (click)="close()"
               class="btn btn-icon btn-sm btn-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body m-0">
        <kt-condition-ratin-module [tpDetails]="data.tpDetails" [useMatDialog]="false"></kt-condition-ratin-module>
    </div>
</div>
