import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { FloorRateModel } from 'src/app/core/asset_class/_models/floor-rate.model';
import { initialLandTenuresState } from 'src/app/core/linked-tables/_reducers/land-tenure.reducers';
import { ConditionRatingDataItem, ConditionRatingV2Service } from '../../services/condition-rating-v2.service'
import {tap} from 'rxjs/operators'

@Component({
  selector: 'kt-inspection-condition-ratings',
  templateUrl: './inspection-condition-ratings.component.html',
  styleUrls: ['./inspection-condition-ratings.component.scss']
})
export class InspectionConditionRatingsComponent implements OnInit {
  public components$ = this.crV2Service.components$;
  public conditionRatings$ = this.crV2Service.ratings$;
  public schemeId$ = this.crV2Service.schemeId$;

  constructor(
    private crV2Service: ConditionRatingV2Service
  ) { }

  ngOnInit(): void {
  }

  onFloorRatingChanged(event) {
  }

  getData() {
  }

  onConditionRatingChanged(items: ConditionRatingDataItem[]) {
    this.crV2Service.setConditionRatings(items);
  }
}
