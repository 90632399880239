// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {Grade} from '../_models/grade.model';

export enum GradeActionTypes {

    AllGradesRequested = '[Grades Home Page] All Grades Requested',
    AllGradesLoaded = '[Grades API] All Grades Loaded',

    GradeOnServerCreated = '[Edit Grade Dialog] Grade On Server Created',
    GradeCreated = '[Edit Grades Dialog] Grades Created',
    GradeUpdated = '[Edit Grade Dialog] Grade Updated',
    GradeDeleted = '[Grades List Page] Grade Deleted',

    GradesPageRequested = '[Grades List Page] Grades Page Requested',
    GradesPageLoaded = '[Grades API] Grades Page Loaded',
    GradesPageCancelled = '[Grades API] Grades Page Cancelled',

    GradesPageToggleLoading = '[Grades page] Grades Page Toggle Loading',
    GradesActionToggleLoading = '[Grades] Grades Action Toggle Loading',

    // trash system

    GradeOnServerRestored = '[Grade Trash] Grade On Server Restored',
    GradeRestored = '[Grade Trash] Grade Restored',

    GradeOnServerAdminRestored = '[Grade Admin Trash] Grade On Server Restored',
    GradeAdminRestored = '[Grade Admin Trash] Grade Restored',

    GradeDeletedFromTrash = '[Grade Trash] Grade deleted',
    GradeDeletedFromAdminTrash = '[Grade Admin Trash] Grade deleted',

    GradeTrash = 'Grade Trashed',
    GradeTrashFlushed = 'Grade Trash Flushed',
}

export class GradeOnServerCreated implements Action {
    readonly type = GradeActionTypes.GradeOnServerCreated;

    constructor(public payload: { grade: Grade}) {
    }
}

export class GradeCreated implements Action {
    readonly type = GradeActionTypes.GradeCreated;

    constructor(public payload: { grade: Grade }) {
    }
}

export class GradeUpdated implements Action {
    readonly type = GradeActionTypes.GradeUpdated;

    constructor(public payload: {
        partialGrade: Update<Grade>,
        grade: Grade
    }) {
    }
}

export class GradeDeleted implements Action {
    readonly type = GradeActionTypes.GradeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class GradesPageRequested implements Action {
    readonly type = GradeActionTypes.GradesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class GradesPageLoaded implements Action {
    readonly type = GradeActionTypes.GradesPageLoaded;

    constructor(public payload: { grades: Grade[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class GradesPageCancelled implements Action {
    readonly type = GradeActionTypes.GradesPageCancelled;
}

export class AllGradesRequested implements Action {
    readonly type = GradeActionTypes.AllGradesRequested;
}

export class AllGradesLoaded implements Action {
    readonly type = GradeActionTypes.AllGradesLoaded;

    constructor(public payload: { grades: Grade[] }) {
    }
}

export class GradesPageToggleLoading implements Action {
    readonly type = GradeActionTypes.GradesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class GradesActionToggleLoading implements Action {
    readonly type = GradeActionTypes.GradesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class GradeDeletedFromAdminTrash implements Action {
    readonly type = GradeActionTypes.GradeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class GradeDeletedFromTrash implements Action {
    readonly type = GradeActionTypes.GradeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class GradeOnServerRestored implements Action {
    readonly type = GradeActionTypes.GradeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class GradeRestored implements Action {
    readonly type = GradeActionTypes.GradeRestored;

    constructor(public payload: { item: Grade }) {
    }
}

export class GradeOnServerAdminRestored implements Action {
    readonly type = GradeActionTypes.GradeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class GradeAdminRestored implements Action {
    readonly type = GradeActionTypes.GradeAdminRestored;

    constructor(public payload: { item: Grade }) {
    }
}

export class GradeTrashFlushed implements Action {
    readonly type = GradeActionTypes.GradeTrashFlushed;

    constructor() {
    }
}

export type GradeActions = GradeCreated
    | GradeUpdated
    | GradeDeleted
    | GradesPageRequested
    | GradesPageLoaded
    | GradesPageCancelled
    | AllGradesLoaded
    | AllGradesRequested
    | GradeOnServerCreated
    | GradesPageToggleLoading
    | GradesActionToggleLoading
    | GradeDeletedFromAdminTrash
    | GradeDeletedFromTrash
    | GradeOnServerRestored
    | GradeRestored
    | GradeOnServerAdminRestored
    | GradeAdminRestored
    | GradeTrashFlushed;
