import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    AfterContentInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {AppState} from '../../../../core/reducers';
import {
    AllTpTaskRequested,
    AssetClassDeletedFromTrash,
    AssetClassesService,
    AssetClassModel,
    AssetClassOnServerRestored,
    AssetClassTrashFlushed,
    selectAssetClassByToeId,
    selectAssetClassesTotalTrashed,
    selectTpTasksByToeId,
    AllAssetClassSERRequested
} from '../../../../core/asset_class';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {delay, map,take, tap} from 'rxjs/operators';
import {
    ConflictService, selectToesActionLoading, ToeActionToggleLoading, ToeModel,
    ToeService,
    ToeStatusUpdated
} from '../../../../core/toe';
import {Update} from '@ngrx/entity';
import {AllTpFileRequested} from '../../../../core/asset_class';
import {AllInteractionTypesRequested, AllReportDeliveryMethodsRequested, ReportDeliveryMethod} from '../../../../core/linked-tables';
import {currentUser, User} from 'src/app/core/mad-auth/mad-auth.store';
import {OneAssignmentRequest} from '../../../../core/assignment';
import {AssignmentModel, selectAssignmentById} from '../../../../core/assignment';
import {filter} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {SupportingValuerModel} from '../../../../core/toe/_models/supporting-valuer.model';
import {Globals} from '../../../../core/_base/crud/utils/globals.service';
import { PropertyDetailReportStateResetted } from 'src/app/core/asset_class/_actions/property-detail-report.actions';
import { ValuationReportStateResetted } from 'src/app/core/asset_class/_actions/valuation-report.actions';
import { DraftStatementReportStateResetted } from 'src/app/core/asset_class/_actions/draft-statement-report.action';
import { computeTargetPropertyTaskPercentage, setTaskCompletion } from '../_sub/tp-row/tp-row.component';
import { ChangeToeStatusFromDone, ChangeToeStatusFromOngoing, RevertToe } from 'src/app/core/toe/_actions/toe.actions';
import { selectIsReverted, selectToeById } from 'src/app/core/toe/_selectors/toe.selectors';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { ToeInvoicesComponent } from './toe-invoices/toe-invoices.component';
import { ToeSettlementComponent } from './toe-settlement/toe-settlement.component';
import { ToeAuditTaskRemindersRequested } from 'src/app/core/toe/_actions/toe-audit-task-reminder.actions';
import { selectToeAuditTaskStatus } from 'src/app/core/toe/_selectors/toe-audit-task-reminder.selectors';
import { AllTpFileOfToeRequested } from 'src/app/core/asset_class/_actions/tp-file.actions';

@Component({
    selector: 'kt-toe-dashboard',
    templateUrl: './toe-dashboard.component.html',
    styleUrls: ['./toe-dashboard.component.scss'],
})

export class ToeDashboardComponent implements OnInit, AfterContentInit, OnDestroy {


    @ViewChildren('tpComponents') tpComponents: QueryList<ElementRef>;
    @ViewChild('toe_invoices') toeInvoicesComponent: ToeInvoicesComponent;
    @ViewChild('toe_settlement') toeSettlementComponent: ToeSettlementComponent;

    displayedColumns = ['id', 'vals', 'ac', 'property_det', 'type', 'multimedia', 'actions'];
    colors = ['danger', 'warning', 'success'];
    statusTitle = ['Pending', 'Ongoing', 'Completed'];
    showPM = false;
    dataSource = new MatTableDataSource([]);

    decisions = [
        'Desktop Review',
        'External',
        'Internal',
        'Full Survey'
    ];


    // Subscriptions
    private subscriptions: Subscription[] = [];
    assetClassResult: AssetClassModel[] = [];

    valCnt = 0;
    insCnt = 0;
    insRevCnt = 0;
    completionPercent = 0;

    entitySubject = new BehaviorSubject<AssetClassModel[]>([]);
    baseOfValuesInfo$ = this.entitySubject.pipe(
        map(assetClasses => {
            const bases = assetClasses.reduce(
                (acc, assetClass) => {
                    const bases = assetClass.all_valuations.map(valuation => ({
                        id: valuation.base_of_value_id,
                        name: valuation.base_of_value_name
                    }))
                    return [...acc, ...bases]
                },
                [] as {id: number, name: string}[]
            )
            return bases.reduce(
                (acc, basis) => {
                    if (acc.find(item => item.id == basis.id)) {
                        return acc;
                    }
                    return [...acc, basis]
                },
                [] as {id: number, name: string}[]
            )
        }),
        map(bases => {
            if (bases.length >= 3) {
                return {
                    label: bases.length == 1 ? 'Basis' : 'Bases',
                    text: 'Multiple'
                }
            }
            return {
                label: bases.length == 1 ? 'Basis' : 'Bases',
                text: bases.map(basis => basis.name).join(' / ')
            }
        })
    )
    loading$: Observable<boolean>;
    trashCnt: Observable<number>;
    validTPs$: Observable<number>;
    adminTaskPercentage = 0;

    menuSubject = new BehaviorSubject<number>(-1);
    menuItems = [
        {
            title: 'Edit/View ToE',
            icon: 'flaticon2-edit',
            refId: 1,
            disabled: false
        },
        {
            title: 'Revert to draft',
            refId: 3,
            icon: 'flaticon2-refresh',
            disabled: false
        },
        {
            title: 'Audit Trail',
            icon: 'flaticon2-protection',
            refId: 2,
            disabled: false
        }];
    showingItems = [];

    currentUser: User;
    workers: SupportingValuerModel[] = [];
    toeUsers: User[] = [];
    pm: User;
    toeWorkers: User[] = [];
    selectedRdm: ReportDeliveryMethod[];
    serverData = [];
    toeId: number;
    toeStatus = 0;
    remainingDays = 0;
    toe: ToeModel;
    baseMediaUrl = environment.baseApiUrl;
    assignment: AssignmentModel;
    toe_documents_incomplete_task_cnt = 0;
    toe_documents_total_task_cnt = 0;
    toe_invoices_incomplete_task_cnt = 0;
    toe_invoices_total_task_cnt = 0;
    toe_settlement_incomplete_task_cnt = 0;
    toe_settlement_total_task_cnt = 0;
    toe_audit_incomple_task_cnt = 0;
    toe_audit_total_task_cnt = 0;

    @ViewChild('statusSelection') statusSelection: MatSelect;

    menuItems$ = new BehaviorSubject<{
        title: string,
        icon: string,
        refId: number,
        disabled: boolean
    }[]>([]);
    isCurrentUserLV = false;
    manualTaskInvestigationStatus: Map<number, boolean> = new Map();

    tpCompletionMap: Map<number, {
        info: AssetClassModel,
        completed_val: number,
        report_task: {
            completed: 'available' | 'not available' | 'not applicable',
            tooltip: string,
        }
        completed: boolean
    }> = new Map();
    propertyTypes = PropertyTypes;

    selectedTab = 0;
    selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
    selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
    tabHeaders: TabHeader[] = [
        {label: 'ToE & Amendments', disabled: of(false)},
        {label: 'Invoices', disabled: of(false)},
        {label: 'Settlement', disabled: of(false)},
        {label: 'Audit Trail', disabled: of(false)},
    ]

    auditTrailTasksNum$: Observable<{num: number}>;

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param global
     * @param modelService
     * @param layoutUtilsService
     * @param conflictService
     * @param snackBar: MatSnackBar
     * @param store: Store<AppState>
     * @param translate
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private global: Globals,
                private modelService: AssetClassesService,
                private layoutUtilsService: LayoutUtilsService,
                public snackBar: MatSnackBar,
                private store: Store<AppState>,
                private translate: TranslateService,
                private readonlyService: ReadonlyService) {
        this.auditTrailTasksNum$ = this.store.select(selectToeAuditTaskStatus)
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {

        // dispatch
        this.store.dispatch(new AllReportDeliveryMethodsRequested());
        this.store.dispatch(new AllTpTaskRequested({toeID: this.global.activeTOEId}));
        this.store.dispatch(new AllAssetClassSERRequested());
        this.store.dispatch(new ToeAuditTaskRemindersRequested({toe_id: this.global.activeTOEId}))
        this.store.dispatch(new AllInteractionTypesRequested())

        const menuItemSub = this.menuItems$.asObservable().subscribe(items => this.showingItems = items);
        this.menuItems$.next(this.menuItems);
        this.subscriptions.push(menuItemSub);

        const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
            switch (refId) {
                case 1:
                    // edit TOE
                    localStorage.setItem('toe_form_url', 'dashboard');
                    this.readonlyService.setReadOnly(true);
                    this.router.navigate(['../../../edit', this.toeId], {relativeTo: this.activatedRoute});
                    break;
                case 2:
                    localStorage.setItem('audit_trails_url', 'dashboard');
                    this.router.navigate(['../../../audit-trails'], {relativeTo: this.activatedRoute});
                    break;
                case 3:
                    this._revertToDraft();
                    break;
                default:
                    break;
            }
        });
        this.subscriptions.push(menuSubjectSubscribe);

        const userSubscription = this.store.pipe(
            take(1),
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                }
            });
        this.subscriptions.push(userSubscription);

        const tpTasksByToeIdSubscription = combineLatest([
            this.entitySubject,
            this.store.select(selectTpTasksByToeId(this.global.activeTOEId))
                .pipe(
                    map(tasks => {
                        const taskMap: Map<number, any[]> = new Map();
                        tasks.forEach(task => {
                            if (taskMap.has(task.tp_id)) {
                                const tasks = taskMap.get(task.tp_id);
                                tasks.push(task);
                                taskMap.set(task.tp_id, tasks);
                            } else {
                                const tasks = [task];
                                taskMap.set(task.tp_id, tasks);
                            }
                        });
                        return taskMap;
                    })
                ),
            this.store.select(selectToeById(this.global.activeTOEId)).pipe(filter(toe => toe !== undefined))
        ]).subscribe(([acs, tasksMap, toe]) => {
            if (acs.length == 0) {
                return;
            }
            let len = 0;
            tasksMap.forEach(t => len++);
            if (len == 0) {
                return;
            }
            let sum = 0;
            let num = 0;
            acs.filter(ac => ac.state != 1).forEach(ac => {
                num += 1;
                if (tasksMap.has(ac.id)) {
                    const tasks = tasksMap.get(ac.id);
                    const tpInfo = this.tpCompletionMap.get(ac.id);
                    tpInfo.completed_val = 0;
                    let taskCompletionPercentage: Map<string, boolean[]> = new Map([
                        ["document", []],
                        ['logistic', []],
                        ['landmark', []],
                        ['investigation', []],
                        ['valuation', []],
                        ['reporting', []],
                        ['report-design', []],
                        ['signed_doc', []],
                        ['delivery', []],
                        ['due-diligence', []]
                    ]);
                    if (ac.top_property_type_id == PropertyTypes.Custom) {
                        tpInfo.report_task = {
                            completed: 'not applicable',
                            tooltip: 'Feature not available for custom property types'
                        }
                    } else {
                        const report_task = tasks.find(t => t.task_id == 3)
                        if (report_task && report_task.point == report_task.max_point) {
                            tpInfo.report_task = {
                                completed: 'available',
                                tooltip: null
                            }
                        } else {
                            tpInfo.report_task = {
                                completed: 'not available',
                                tooltip: 'Feature available only when reporting task is completed'
                            }
                        }
                    }
                    const valTaskGrouped = tasks.filter(task => task.task_id == 2).reduce((acc, task) => {
                        if (task.scenario) {
                            if (acc[task.scenario.original_task_id]) {
                                acc[task.scenario.original_task_id].push(task)
                            } else {
                                acc[task.scenario.original_task_id] = [task]
                            }
                        } else {
                            acc[task.id] = [task]
                        }
                        return acc;
                    }, {})
                    Object.values(valTaskGrouped).forEach((tasks: any[]) => {
                        const completed = tasks.filter(t => t.point == t.max_point)
                        if (completed.length == tasks.length) {
                            tpInfo.completed_val += 1;
                        }
                    })
                    tasks.forEach(task => {
                        taskCompletionPercentage = setTaskCompletion(taskCompletionPercentage, task.task_id, task.point == task.max_point);
                    });
                    const percent = computeTargetPropertyTaskPercentage(taskCompletionPercentage);
                    tpInfo.completed = percent == 100;
                    this.tpCompletionMap.set(ac.id, tpInfo);
                    sum += percent;

                    // Investigation status of custom tp
                    const invTask = tasks.find(t => t.task_id == 1);
                    if (invTask) {
                        this.manualTaskInvestigationStatus.set(ac.id, invTask.point == invTask.max_point);
                    }
                }
            });
            const totalACs = acs.length;
            let abortedACs = acs.filter(ac => ac.state == 1).length;
            let isToeAborted = this.toe.status == -1;
            if (totalACs == abortedACs && !isToeAborted) {
                isToeAborted = true;
                this.store.dispatch(new ToeStatusUpdated({
                    toe: {
                        id: this.global.activeTOEId,
                        changes: {
                            id: this.global.activeTOEId,
                            status: -1
                        }
                    }
                }))
            }
            const _temp = Array.from(this.tpCompletionMap.values());
            _temp.sort((a1, a2) => a1.info.id - a2.info.id);
            this.dataSource.data = _temp;
            
            // Calculate Total Percentage
            const tpPercentage = (sum / totalACs) * 80 / 100;
            this.calcAdmintaskCompletion()
            const admin = this.adminTaskPercentage * 20 / 100;

            this.completionPercent = tpPercentage + admin;
            if (this.completionPercent == 100) {
                this.store.dispatch(new ChangeToeStatusFromOngoing({toeID: this.global.activeTOEId}));
            } else {
                if (!isToeAborted && toe.status !== 0) {
                    this.store.dispatch(new ChangeToeStatusFromDone({toeID: this.global.activeTOEId}));
                }
            }
        });

        this.subscriptions.push(tpTasksByToeIdSubscription);

        this.loading$ = this.store.pipe(
            select(selectToesActionLoading),
        );

        this.trashCnt = this.store.pipe(
            select(selectAssetClassesTotalTrashed),
        );

        const selectActivatedRoute = 
            combineLatest([
                this.activatedRoute.data,
                this.store.select(selectToeById(this.global.activeTOEId))
            ]).subscribe(([res, toe]) => {
            this.workers = res.workers.data;
            this.toeWorkers = this.workers.map(w => w.worker);
            this.toeUsers = res.users.data;
            // this.toeUsers
            this.selectedRdm = res.rdMethods;

            this.toe = toe != undefined ? Object.assign({}, toe) : Object.assign({}, res.toeData.data);
            this.toeStatus = this.toe.status;
            this.remainingDays = this.calculateDiff(this.toe.final_delivery_date);

            this.showingItems[0].title = 'View ToE';
            this.showingItems[0].icon = 'flaticon-eye';

            const currentMenuItems = this.menuItems$.value;
            this.menuItems$.next(currentMenuItems.map(mi => {
                if (mi.refId == 3 && (this.toe.status == 4 || this.toe.status == -1)) {
                    return {
                        ...mi,
                        disabled: true
                    }
                }
                return mi;
            }))
        });
        this.subscriptions.push(selectActivatedRoute);

        // toe
        this.toeId = this.global.activeTOEId;

        const selectAssSub = combineLatest([
            this.store.pipe(
            filter(() => this.workers.length > 0),
            select(selectAssignmentById(this.global.activeAssignmentId))),
            this.store.select(currentUser)
        ]).subscribe(([res, currentUser]) => {
            this.currentUser = currentUser
            if (res && currentUser) {
                this.assignment = Object.assign({}, res);
                this.showPM = this.assignment.valuer_id == this.currentUser.id;
                const pmUserIndex = this.toeUsers.findIndex(element => element.id == this.assignment.valuer_id);
                this.pm = this.toeUsers[pmUserIndex];
                if (pmUserIndex > 0) {
                    const _tmp = Object.assign([], this.toeUsers);
                    _tmp.splice(0, 0, _tmp.splice(pmUserIndex, 1)[0]);
                    this.toeUsers = _tmp;
                }
                if (this.workers.length > 0 && this.workers[0].lead_valuer > 0) {
                    const lvUserIndex = this.toeUsers.findIndex(element => element.id == this.workers[0].worker_id);
                    if (lvUserIndex > -1) {
                        const lv = this.toeUsers[lvUserIndex];
                        this.isCurrentUserLV = lv.id == this.currentUser.id;
                    }
                    if (lvUserIndex > 1) {
                        const _tmp = Object.assign([], this.toeUsers);
                        _tmp.splice(1, 0, _tmp.splice(lvUserIndex, 1)[0]);
                        this.toeUsers = _tmp;
                    }
                    this.showPM = this.showPM || this.workers[0].worker_id == this.currentUser.id;
                }
                const currentMenuItems = this.menuItems$.value;
                this.menuItems$.next(currentMenuItems.filter(mi => {
                    if (mi.refId !== 3) {
                        return true;
                    }
                    return this.showPM || this.isCurrentUserLV;
                }));
            } 
        });
        this.subscriptions.push(selectAssSub);


        // Call request from server
        this.store.pipe(
            select(selectAssetClassByToeId(this.toeId)),
        ).subscribe((response) => {
            this.tpCompletionMap = new Map();
            this.assetClassResult = response;
            this.assetClassResult.forEach(ac => {
                if (ac.top_property_type_id == PropertyTypes.Custom) {
                    this.manualTaskInvestigationStatus.set(ac.id, ac.investigation_status);
                }
                this.tpCompletionMap.set(ac.id, {info: ac, completed_val: 0, completed: false, report_task: {completed: 'not available', tooltip: null}});
            })

            let _tmp = 0;
            let _insCnt = 0;
            let _revCnt = 0;
            this.assetClassResult.forEach(e => {
                if (e.state != 1) {
                    _tmp += e.valuations.length;
                    _insCnt += Number(e.type_of_inspection != 1);
                    _revCnt += Number(e.type_of_inspection === 1);
                }
            });
            this.valCnt = _tmp;
            this.insCnt = _insCnt;
            this.insRevCnt = _revCnt;

            this.entitySubject.next(response);
        });


        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.store.dispatch(new AllTpFileOfToeRequested({toeId: this.toeId}));
        });

        this.validTPs$ = this.entitySubject.pipe(map(tps => {
            return tps.filter(tp => tp.state != 1).length;
        }));
    }

    ngAfterContentInit() {
        // navigate from my-tasks for documents
        if (sessionStorage.getItem('tp') != null) {
            this.scroll(Number(sessionStorage.getItem('tp')));
        }
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // action
    statusSelectionChanged(ev) {
        if (ev) {
            this.toeStatus = ev.value;
            this.toe.status = this.toeStatus;
            if (this.toeStatus <= 1) {
                this.showingItems[0].title = 'Edit ToE';
                this.showingItems[0].icon = 'flaticon2-edit';
            } else {
                this.showingItems[0].title = 'View ToE';
                this.showingItems[0].icon = 'flaticon-eye';
            }
            const updateToe: Update<ToeModel> = {
                id: this.toe.id,
                changes: this.toe
            };
            this.store.dispatch(new ToeStatusUpdated({toe: updateToe}));
        }
    }

    showStatus(statusNum: number) {

        if (statusNum == 4) {
            return this.toeStatus == 0 || this.toeStatus == 3;
        }

        if (statusNum < this.toeStatus || statusNum == this.toeStatus + 1) {
            return false;
        }

        return true;
    }


    // Fetch trash data
    trash() {
        this.store.dispatch(new ToeActionToggleLoading({isLoading: true}));
        this.modelService.getTrashedAssetClasses().subscribe(res => {

            this.store.dispatch(new ToeActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted
                });
            });
            this.show_trash(items);
        });
    }

    show_trash(items: any[], isAdmin = false) {
        let _title = 'Deleted TP';
        if (isAdmin) {
            _title = 'Admin Deleted Agencies';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            // this.store.dispatch(new AgencyOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new AssetClassOnServerRestored({assetClassId: id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new AssetClassDeletedFromTrash({assetClassId: id}));
    }

    hardDelete(id: number) {
        // this.store.dispatch(new AssetClassDeletedF({id}));
    }

    flush() {
        this.store.dispatch(new AssetClassTrashFlushed());
    }

    scroll(_id) {
        const elementList = document.querySelectorAll('#anchor' + _id);
        const element = elementList[0] as HTMLElement;
        element.scrollIntoView({behavior: 'smooth'});
    }


    detStatus(item: AssetClassModel) {
        if (item.top_property_type_id == PropertyTypes.Custom) {
            return this.manualTaskInvestigationStatus.get(item.id) ? 2 : 0;
        }

        if (item.details) {
            return item.details.is_complete == 1 ? 2 : 1;
        }
        return 0;

    }

    downloadSignedToeDoc() {
        if (this.toeStatus > 1) {
            window.open(environment.baseApiUrl + this.toe.signedReportUrl);
        }
    }

    calculateDiff(finalDate) {
        const date = new Date(finalDate);
        const currentDate = new Date();

        const days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
        return days;
    }

    getRoleInfo(uId: number) {
        if (this.workers.length < 0 && !this.assignment) {
            return 'error';
        }

        let roleInfo: string;
        if (this.assignment.valuer_id === uId) {
            roleInfo = 'Project Manager';
            if (this.isWorker(uId)) {
                roleInfo += this.isLeadValuer(uId)
                    ? ' & Lead Valuer'
                    : ' & Supporting Staff';
            }
            return roleInfo;
        }
        roleInfo = this.isLeadValuer(uId)
            ? 'Lead Valuer'
            : 'Supporting Staff';
        return roleInfo;
    }

    private isWorker(uId: number): boolean {
        const worker = this.workers.filter(w => w.worker_id === uId);
        return worker.length > 0;
    }

    private isLeadValuer(uId: number): boolean {
        return this.workers[0].worker_id === uId;
    }

    private _revertToDraft() {
        if (this.toe.status == 4) {
            return;
        }
        const _title = this.translate.instant('TOE.PROMPT');
        const _description = this.translate.instant('TOE.REVERT_DRAFT_DESCRIPTION');
        const _waitDescription = this.translate.instant('TOE.PROMPT_WAIT_REVERT');

        const dialogRef = this.layoutUtilsService.saveElement(_title, _description, _waitDescription);
        const dialogRefSub = dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const draftToe = Object.assign({}, this.toe);
            draftToe.status = 0;
            
            const updateToe: Update<ToeModel> = {
                id: draftToe.id,
                changes: draftToe
            };
            this.store.dispatch(new RevertToe({update: updateToe}));
            this.store.dispatch(new PropertyDetailReportStateResetted());
            this.store.dispatch(new ValuationReportStateResetted());
            this.store.dispatch(new DraftStatementReportStateResetted());
            this.store.select(selectIsReverted).subscribe(isReverting => {
                if (!isReverting) {
                    this.readonlyService.setReadOnly(false);
                    this.router.navigate(['../../../edit', this.toeId], {relativeTo: this.activatedRoute});
                }
            })
        });
        this.subscriptions.push(dialogRefSub);
    }

    changeToeStatus(status: number) {
        this.toeStatus = this.toeStatus > 2 ? this.toeStatus : status;
    }

    changeToeDocumentsIncompleteTaskCnt(task_cnt: any) {
        this.toe_documents_incomplete_task_cnt = task_cnt.incomplete;
        this.toe_documents_total_task_cnt = task_cnt.total;
        this.calcAdmintaskCompletion();
    }

    changeSignedUploadDone(uploaded: boolean) {
        this.toeInvoicesComponent.getInformedConsentsReportCMD$.next();
        this.toeSettlementComponent.getInformedConsentsReportCMD$.next();
    }

    changeToeInvoicesIncompleteTaskCnt(task_cnt: any) {
        // NOTE: not using currently
        this.toe_invoices_incomplete_task_cnt = task_cnt.incomplete;
        this.toe_invoices_total_task_cnt = task_cnt.total;
        this.calcAdmintaskCompletion();
    }

    changeToeSettlementIncompleteTaskCnt(task_cnt: any) {
        this.toe_settlement_incomplete_task_cnt = task_cnt.incomplete;
        this.toe_settlement_total_task_cnt = task_cnt.total;
        this.calcAdmintaskCompletion();
    }

    changeAuditTaskCnt(task_cnt: any) {
        this.toe_audit_incomple_task_cnt = task_cnt.incomplete;
        this.toe_audit_total_task_cnt = task_cnt.total;
        this.calcAdmintaskCompletion()
    }

    calcAdmintaskCompletion() {
        const documentTasks = this.toe_documents_total_task_cnt == 0 
            ? 50
            : 50 * (this.toe_documents_total_task_cnt - this.toe_documents_incomplete_task_cnt) / this.toe_documents_total_task_cnt
        const auditTrailTasks = this.toe_audit_total_task_cnt == 0
            ? 50 
            : 50 * (this.toe_audit_total_task_cnt - this.toe_audit_incomple_task_cnt) / this.toe_audit_total_task_cnt
        this.adminTaskPercentage = Math.round(documentTasks + auditTrailTasks)
    }

    navToMultimedia(multimedia) {
        if (multimedia) {
            sessionStorage.setItem('pwd', multimedia.pwd);
            window.open(window.location.origin + '/' + multimedia.url + '/' + multimedia.agency_id, "_blank");
        } else {
            alert("There is no multimedia.");
        }
    }
    onTabChanged($event) {
        this.toeInvoicesComponent.getInformedConsentsReportCMD$.next();
        this.toeSettlementComponent.getInformedConsentsReportCMD$.next();
    }
}
