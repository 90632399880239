import { ReportType } from "src/app/views/pages/target-property/_sub/tp-row/_sub/signed-reports/signed-reports.component";

export class TpSignedDoc {
	id: number;
	tp_id: number;

	url: string;
	file_size: string;
	type: ReportType;

	created_at: Date;
	updated_at: Date;

	constructor(type: ReportType) {
		this.created_at = null;
		this.type = type;
	}
}