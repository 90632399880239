<div [formGroup]="formGroup" class="d-flex flex-column" [ngClass]="{'form-disabled': readonly}" *ngIf="postValuationDeliveryServicetData != null">
    <div class="form-group kt-form-group row section-margin-top-60" *ngIf="parentTypeID == 1">
        <div class="col-lg-12">
            <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                <span>{{'AGENCY.FORM.FIELD.INFORMATION.GENERAL_INFO_SECTION.TITLE' | translate}}</span>
            </h5>
            <hr class="active">
        </div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-4 my-auto">
                    <mat-checkbox formControlName="includes_in_toe">
                        {{ 'AGENCY.FORM.FIELD.INFORMATION.INCLUDES_IN_TOE.PLACEHOLDER' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group kt-form-group row pt-0" *ngIf="parentTypeID == 2">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-4 my-auto">
                    <mat-checkbox formControlName="overridden_in_toe">
                        {{ 'AGENCY.FORM.FIELD.INFORMATION.OVERRIDDEN_IN_TOE.PLACEHOLDER' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group kt-form-group row section-margin-top-40" *ngIf="(parentTypeID == 1 && formGroup.controls.includes_in_toe.value) || (parentTypeID == 2 && formGroup.controls.overridden_in_toe.value)">
        <div class="col-lg-12">
            <h5 class="my-3 text-mad text-uppercase d-flex align-items-center">
                <span>{{'AGENCY.FORM.FIELD.INFORMATION.HOURLY_RATE_SECTION.TITLE' | translate}}</span>
            </h5>
            <hr class="active">
        </div>
        <div class="col-lg-12 mt-4">
            <div class="row">
                <div class="kt-margin-bottom-20-mobile" [ngClass]="{'col-lg-3': parentTypeID == 1, 'col-lg-4': parentTypeID == 2}">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.HOURLY_RATE.PLACEHOLDER' | translate" formControlName="hourly_rate"
                            mask="separator"
                            thousandSeparator=","
                            [suffix]="currencySuffix()"
                        />
                        <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('AGENCY.FORM.FIELD.INFORMATION.HOURLY_RATE.NAME' | translate)
                            }"
                        >
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-lg-3 kt-margin-bottom-20-mobile" *ngIf="parentTypeID == 1">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.CURRENCY.PLACEHOLDER' | translate"
                                    formControlName="currency"
                                    [compareWith]="matSelectCompareWith"
                                    #singleSelectCurrency>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="filterCtrlCountriesData"
                                                        placeholderLabel="Search"
                                                        noEntriesFoundLabel="Currencies not found">

                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                                {{_data.currencyCode}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('AGENCY.FORM.FIELD.INFORMATION.CURRENCY.NAME' | translate)
                            }"
                        >
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="kt-margin-bottom-20-mobile" [ngClass]="{'col-lg-3': parentTypeID == 1, 'col-lg-4': parentTypeID == 2}">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput type="text" mask="0000" [validation]="false" [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.FREE_HOURS.PLACEHOLDER' | translate" formControlName="free_hours"/>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer pt-1"
                            (click)="$event.stopPropagation();"
                            container="body"
                            [ngbPopover]="'TOOLTIP.AGENCY.FREE_HOURS.DESCRIPTION' | translate">help
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div class="kt-margin-bottom-20-mobile" [ngClass]="{'col-lg-3': parentTypeID == 1, 'col-lg-4': parentTypeID == 2}">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput type="text" mask="0000" [validation]="false" [placeholder]="'AGENCY.FORM.FIELD.INFORMATION.VALIDITY_FEES.PLACEHOLDER' | translate" formControlName="validity_of_fees"/>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer pt-1"
                            (click)="$event.stopPropagation();"
                            container="body"
                            [ngbPopover]="'TOOLTIP.AGENCY.VALIDITY_FEES.DESCRIPTION' | translate">help
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-12 mt-12">
            <div class="row">
                <div class="col-12">
                    <h6>
                        <span>{{'TOOLTIP.AGENCY.INCLUDES_BUT_NOT_LIMITED.TITLE' | translate}}</span>
                        <mat-icon matSuffix class="cursor-pointer icon-gray ml-4"
                            inline
                            container="body"
                            [ngbPopover]="'TOOLTIP.AGENCY.INCLUDES_BUT_NOT_LIMITED.DESCRIPTION' | translate"
                            (click)="$event.stopPropagation();">help
                        </mat-icon>
                    </h6>
                </div>
                <div class="col-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput rows="5" formControlName="include_text"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="!readonly">
            <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
                <div>
                    <button class="btn font-semibold reset-btn" mat-raised-button (click)="resetPayment()" type="button">
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
