import {} from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValuationStandardState } from '../_reducers/valuation-standard.reducer';
import * as fromReducer from '../_reducers/valuation-standard.reducer';
import { ValuationStandard } from '../_models/valuation-standard.model';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';

export const selectValuationStandardState = createFeatureSelector<ValuationStandardState>('valuationStandard');

export const selectValuationStandardById = (id: number) => createSelector(
    selectValuationStandardState,
    state => state.entities[id]
);

export const selectAllValuationStandard = createSelector(
    selectValuationStandardState,
    fromReducer.selectAll
);

export const selectAllValuationStandardIds = createSelector(
    selectValuationStandardState,
    fromReducer.selectIds
);

export const allValuationStandardLoaded = createSelector(
    selectValuationStandardState,
    state => state.isAllValuationStandardLoaded
);

export const selectValuationStandardPageLoading = createSelector(
    selectValuationStandardState,
    state => state.listLoading
);

export const selectValuationStandardActionLoading = createSelector(
    selectValuationStandardState,
    state => state.actionLoading
);

export const selectValuationStandardShowInitWaitingMessage = createSelector(
    selectValuationStandardState,
    state => state.showInitWaitingMessage
);

export const selectLastCreatedValuationStandardId = createSelector(
    selectValuationStandardState,
    state => state.lastCreatedValuationStandardId
);

export const selectTrashedValuationStandardCount = createSelector(
    selectValuationStandardState,
    state => state.totalTrashed
);

export const selectAdminTrashedValuationStandardCount = createSelector(
    selectValuationStandardState,
    state => state.totalAdminTrashed
);

export const selectValuationStandardQueryResult = createSelector(
    selectValuationStandardState,
    state => {
        const items: ValuationStandard[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ValuationStandard[] = httpExtension.sortArray(
            items,
            state.lastQuery.sortField,
            state.lastQuery.sortOrder
        );
        return new QueryResultsModel(
            result,
            state.totalCount,
            '',
            state.totalTrashed
        )
    }
);