// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {CertificationService} from '../_services/certification.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allCertificationsLoaded} from '../_selectors/certification.selectors';
// Actions
import {
    AllCertificationsLoaded,
    AllCertificationsRequested,
    CertificationActionTypes,
    CertificationsPageRequested,
    CertificationsPageLoaded,
    CertificationUpdated,
    CertificationsPageToggleLoading,
    CertificationDeleted,
    CertificationOnServerCreated,
    CertificationCreated,
    CertificationActionToggleLoading, CertificationTrashFlushed, CertificationOnServerRestored, CertificationRestored,
    CertificationOnServerAdminRestored, CertificationAdminRestored, CertificationDeletedFromTrash, CertificationDeletedFromAdminTrash
} from '../_actions/certification.actions';

@Injectable()
export class CertificationEffects {
    showPageLoadingDispatcher = new CertificationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new CertificationActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CertificationActionToggleLoading({isLoading: false});

    @Effect()
    loadAllCertification$ = this.actions$
        .pipe(
            ofType<AllCertificationsRequested>(CertificationActionTypes.AllCertificationsRequested),
            withLatestFrom(this.store.pipe(select(allCertificationsLoaded))),
            filter(([action, isAllCertificationLoaded]) => !isAllCertificationLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllCertificationsLoaded({items: res.data});
            })
        );

    @Effect()
    loadCertificationPage$ = this.actions$
        .pipe(
            ofType<CertificationsPageRequested>(CertificationActionTypes.CertificationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new CertificationsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateCertification$ = this.actions$
        .pipe(
            ofType<CertificationUpdated>(CertificationActionTypes.CertificationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createCertification$ = this.actions$
        .pipe(
            ofType<CertificationOnServerCreated>(CertificationActionTypes.CertificationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new CertificationCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushCertificationTrash$ = this.actions$
        .pipe(
            ofType<CertificationTrashFlushed>(CertificationActionTypes.CertificationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreCertification$ = this.actions$
        .pipe(
            ofType<CertificationOnServerRestored>(CertificationActionTypes.CertificationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CertificationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminCertification$ = this.actions$
        .pipe(
            ofType<CertificationOnServerAdminRestored>(CertificationActionTypes.CertificationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CertificationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteCertification$ = this.actions$
        .pipe(
            ofType<CertificationDeleted>(CertificationActionTypes.CertificationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashCertification$ = this.actions$
        .pipe(
            ofType<CertificationDeletedFromTrash>(CertificationActionTypes.CertificationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashCertification$ = this.actions$
        .pipe(
            ofType<CertificationDeletedFromAdminTrash>(CertificationActionTypes.CertificationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: CertificationService, private store: Store<AppState>) {
    }
}
