import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { ExternalRefTemplateModel } from "../_models";
import { ExternalRefTemplateState } from "../_reducers";

export const selectExternalRefTemplateState = createFeatureSelector<ExternalRefTemplateState>('external-ref-template');

export const selectIsLoading = createSelector(
    selectExternalRefTemplateState,
    state => state.isLoading
)

export const selectTotal = createSelector(
    selectExternalRefTemplateState,
    state => state.total
)

export const selectTemplate = (id: number) => createSelector(
    selectExternalRefTemplateState,
    state => state.entities[id]
)

export const selectExternalRefTemplates = createSelector(
    selectExternalRefTemplateState,
    state => {
        const items: ExternalRefTemplateModel[] = [];
        each(state.entities, el => items.push(el));
        return items.sort((a, b) => (b.id - a.id));
    }
)