import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { TemplateRequest, ToeTemplateModel } from "../_models";

export enum ToeTemplateActionTypes {
    LOAD_TOE_TEMPLATES = '[ToE Template List] Load Toe Templates',
    TOE_TEMPLATES_LOADED = '[ToE Template Effect] Toe Templates Loaded',

    ADD_TOE_TEMPLATE = '[Toe Template Edit] Add New Toe Template',
    TOE_TEMPLATE_ADDED = '[ToE Tempalte Effect] New Toe Template Added',
     
    UPDATE_TOE_TEMPLATE = '[Toe Template Edit] Update Toe Template',
    TOE_TEMPLATE_UPDATED = '[Toe Template Effect] Toe Template Updated',

    DELETE_TOE_TEMPLATE = '[Toe Template List] Delete Toe Template',
    TOE_TEMPLATE_DELETED = '[Toe Template Effect] Toe Template Deleted',

    DUPLICATE_TOE_TEMPLATE = '[Toe Template List] Duplicate Toe Template',
}

export class LoadToeTemplates implements Action {
    readonly type = ToeTemplateActionTypes.LOAD_TOE_TEMPLATES;
    constructor (public payload: {
        request: TemplateRequest
    }) {}
}

export class ToeTemplatesLoaded implements Action {
    readonly type = ToeTemplateActionTypes.TOE_TEMPLATES_LOADED;
    constructor (public payload: {
        templates: ToeTemplateModel[],
        total: number
    }) {}
}

export class AddToeTemplate implements Action {
    readonly type = ToeTemplateActionTypes.ADD_TOE_TEMPLATE;
    constructor (public payload: {
        template: ToeTemplateModel
    }) {}
}

export class ToeTemplateAdded implements Action {
    readonly type = ToeTemplateActionTypes.TOE_TEMPLATE_ADDED;
    constructor (public payload: {
        template: ToeTemplateModel
    }) {}
}

export class UpdateToeTemplate implements Action {
    readonly type = ToeTemplateActionTypes.UPDATE_TOE_TEMPLATE;
    constructor (public payload: {
        id: number;
        template: ToeTemplateModel
    }) {}
}

export class ToeTemplateUpdated implements Action {
    readonly type = ToeTemplateActionTypes.TOE_TEMPLATE_UPDATED;
    constructor (public payload: {
        id: number;
        template: Update<ToeTemplateModel>
    }) {}
}

export class DeleteToeTemplate implements Action {
    readonly type = ToeTemplateActionTypes.DELETE_TOE_TEMPLATE;
    constructor (public payload: {
        id: number,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export class ToeTemplateDeleted implements Action {
    readonly type = ToeTemplateActionTypes.TOE_TEMPLATE_DELETED;
    constructor (public payload: {
        id: number
    }) {}
}

export class DuplicateToeTemplate implements Action {
    readonly type = ToeTemplateActionTypes.DUPLICATE_TOE_TEMPLATE;
    constructor (public payload: {
        template: ToeTemplateModel,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export type ToeTemplateActions 
    = LoadToeTemplates
    | ToeTemplatesLoaded
    | AddToeTemplate
    | ToeTemplateAdded
    | UpdateToeTemplate
    | ToeTemplateUpdated
    | DeleteToeTemplate
    | ToeTemplateDeleted
    | DuplicateToeTemplate;
