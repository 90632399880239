// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {WallsAndPartitionActions, WallsAndPartitionActionTypes} from '../_actions/walls-and-partition.actions';
// Models
import {WallsAndPartition} from '../_models/walls-and-partition.model';
import {QueryParamsModel} from '../../_base/crud';

export interface WallsAndPartitionsState extends EntityState<WallsAndPartition> {
    isAllWallsAndPartitionsLoaded: boolean;
    queryRowsCount: number;
    queryResult: WallsAndPartition[];
    lastCreatedWallsAndPartitionId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<WallsAndPartition> = createEntityAdapter<WallsAndPartition>();

export const initialWallsAndPartitionsState: WallsAndPartitionsState = adapter.getInitialState({
    isAllWallsAndPartitionsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedWallsAndPartitionId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function wallsAndPartitionsReducer(state = initialWallsAndPartitionsState, action: WallsAndPartitionActions): WallsAndPartitionsState {
    switch (action.type) {
        case WallsAndPartitionActionTypes.WallsAndPartitionsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedWallsAndPartitionId: undefined
            };
        case WallsAndPartitionActionTypes.WallsAndPartitionsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case WallsAndPartitionActionTypes.WallsAndPartitionOnServerCreated:
            return {
                ...state
            };
        case WallsAndPartitionActionTypes.WallsAndPartitionCreated:
            return adapter.addOne(action.payload.wallsAndPartition, {
                ...state, lastCreatedWallsAndPartitionId: action.payload.wallsAndPartition.id
            });
        case WallsAndPartitionActionTypes.WallsAndPartitionUpdated:
            return adapter.updateOne(action.payload.partialWallsAndPartition, state);
        case WallsAndPartitionActionTypes.AllWallsAndPartitionsLoaded:
            return adapter.addAll(action.payload.wallsAndPartitions, {
                ...state, isAllWallsAndPartitionsLoaded: true
            });
        case WallsAndPartitionActionTypes.WallsAndPartitionsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case WallsAndPartitionActionTypes.WallsAndPartitionsPageLoaded:
            return adapter.addMany(action.payload.wallsAndPartitions, {
                ...initialWallsAndPartitionsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case WallsAndPartitionActionTypes.WallsAndPartitionDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case WallsAndPartitionActionTypes.WallsAndPartitionRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case WallsAndPartitionActionTypes.WallsAndPartitionDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case WallsAndPartitionActionTypes.WallsAndPartitionAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case WallsAndPartitionActionTypes.WallsAndPartitionDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case WallsAndPartitionActionTypes.WallsAndPartitionTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
