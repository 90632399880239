import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {AppState} from '../../../../../../../core/reducers/index';
import {AdjustmentModalComponent} from '../_modals/adjustment-modal/adjustment-modal.component';
import {AdjustmentAddModalComponent} from '../_modals/adjustment-add-modal/adjustment-add-modal.component';
import {ResidentialAdjustmentsPageRequested} from '../../../../../../../core/linked-tables/_actions/residential-adjustments.actions';
import {QueryParamsModel} from '../../../../../../../core/_base/crud/models/query-models/query-params.model';
import {ValuationAdjustmentModel} from '../../../../../../../core/valuation/_models/valuation-adjustment.model';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {PercentageBottomSheet} from '../../../../../../partials/content/crud/percentage-bottom-sheet/percentage-bottom-sheet.component';
import {TranslateService} from '@ngx-translate/core';
import {LayoutUtilsService} from '../../../../../../../core/_base/crud/utils/layout-utils.service';
import {selectAllValuationAdjustments, selectLastCreatedValuationAdjustmentID} from 'src/app/core/valuation/_selectors/valuation-adjustment.selector';
import {
    CreateValuationAdjustment,
    CreateValuationAdjustments,
    EditValuationAdjustment,
    EditValueOfValuationAdjustment,
    RemoveComparableValuationAdjustment,
    RemoveValuationAdjustment,
    ValuationAdjustmentComparableAdded
} from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import {selectAllValComparables, selectLastCreatedValuationDataID} from '../../../../../../../core/valuation/_selectors/valuation-data.selector';
import { AdjustmentTemplateListComponent } from '../_modals/adjustment-template-list/adjustment-template-list.component';
import { AdjustmentTemplateModel } from 'src/app/core/template';
import { AdjustmentTemplateModalComponent } from 'src/app/views/pages/template/templates/adjustment-template/_sub/adjustment-template-modal/adjustment-template-modal.component';

@Component({
    selector: 'kt-adjustment-table',
    templateUrl: './adjustment-table.component.html',
    styleUrls: ['./adjustment-table.component.scss']
})
export class AdjustmentTableComponent implements OnInit, OnDestroy {
    @Input() readonly: boolean;
    @Input() assetClassTypeId: number;

    @Output() removeComparableParent: EventEmitter<any> = new EventEmitter();
    @Output() showOverviewParent: EventEmitter<any> = new EventEmitter();

    form: UntypedFormGroup;
    title = 'Add Adjustment';
    comparables: any[] = [];
    adjustments: any[] = [
        {
            name: 'Location'
        }
    ];
    private valAdjustments: ValuationAdjustmentModel[] = []

    percentPattern = {
        's': {pattern: new RegExp('-?')},
        '0': {pattern: new RegExp('\[0-9\]')}
    };


    headerColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    dataSource: MatTableDataSource<ValuationAdjustmentModel> = new MatTableDataSource();
    protected _onDestroy = new Subject<void>();


    constructor(private formBuilder: UntypedFormBuilder,
                private bottomSheet: MatBottomSheet,
                private store: Store<AppState>,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService) {
    }

    ngOnInit() {

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllValComparables)).subscribe(res => {
            this.comparables = Object.assign([], res);
            this._setHeaders(this.comparables);
        });

        this.store.dispatch(new ResidentialAdjustmentsPageRequested({
            page: new QueryParamsModel(null, 'ASC', 'id', 0, 1000)
        }));

        this.store.pipe(select(selectAllValuationAdjustments)).subscribe(res => {
            this.valAdjustments = res;
            this._createForm(res);
            this.dataSource.data = res
        });
    }

    _createForm(adjustments: ValuationAdjustmentModel[]) {
        const formArray = this.formBuilder.array([]);
        adjustments.forEach(a => {
            const arrayControl = this.formBuilder.group({});
            // a.adjValues.forEach(val => {
            //     const valControl = this.formBuilder.control(val + "%");
            //     arrayControl.push(valControl);
            // })
            Object.entries(a.adjValues).forEach(([key, value]) => {
                const valControl = this.formBuilder.control({value: value, disabled: this.readonly});
                arrayControl.setControl(key + '-cont', valControl);
            })
            const rowGroup = this.formBuilder.group({
                'analysis': this.formBuilder.control({value: a.analysis, disabled: this.readonly}),
                'comparables': arrayControl
            })
            formArray.push(rowGroup);
        })
        this.form = this.formBuilder.group({
            'adjustments': formArray
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public removeComparable(index: number, com: any) {
        this.removeComparableParent.emit({
            index,
            com
        })
    }

    public showOverview(assetClass: any) {
        this.showOverviewParent.emit(assetClass);
    }

    public onSubmit() {
    }

    _setHeaders(_comparables: any[]) {
        const headerColumns = [`input-${_comparables.length}-header`];
        const displayedColumns = ['adjustment'];
        const comparableHeaderColumns = [];
        _comparables.forEach((com, i) => {
            headerColumns.push(`com-${i}-${_comparables.length}-header`);
            comparableHeaderColumns.push(`com-${i}-${_comparables.length}-header`);
            displayedColumns.push(`com-${i}-${_comparables.length}`);
        });
        headerColumns.push(`analysis-${_comparables.length}-header`, 'action-header'); // 'analysis-header',
        displayedColumns.push(`analysis-${_comparables.length}`, 'actions'); // 'analysis',
        this.headerColumns$.next(headerColumns);
        this.displayedColumns$.next(displayedColumns);
    }

    comparableControl(rowIndx: number, comID: number): AbstractControl {
        const formArray = this.form.get('adjustments') as UntypedFormArray;
        const rowGroup = formArray.at(rowIndx);
        const comparableArray = rowGroup.get('comparables') as UntypedFormGroup;
        const name = comID + '-cont';
        return comparableArray.get(name)
    }

    analysisControl(rowIndx: number): AbstractControl {
        const formArray = this.form.get('adjustments') as UntypedFormArray;
        const rowGroup = formArray.at(rowIndx);
        return rowGroup.get('analysis');
    } 

    textAreaAdjustOnKeyUp(e: KeyboardEvent) {
        const element = e.target as HTMLTextAreaElement;
        element.style.height = "1px";
        element.style.height = (10 + element.scrollHeight) + "px";
    }

    textAreaAdjustOnChange(e: KeyboardEvent) {
        const element = e.target as HTMLTextAreaElement;
        element.style.height = "1px";
        element.style.height = (10 + element.scrollHeight) + "px";
    }

    onFocusOut(e: FocusEvent, comID: number, adjustment: ValuationAdjustmentModel) {
        const target = e.target as HTMLInputElement;
        const _val = target.value.substring(0, target.value.length-1);
        const val = Number(_val)
        this.store.dispatch(new EditValueOfValuationAdjustment({
            v: adjustment,
            comID: comID,
            val: val
        }))
    }

    onPercentChange(val: number, comID: number , adjustment: ValuationAdjustmentModel) {
        this.store.dispatch(new EditValueOfValuationAdjustment({
            v: adjustment,
            comID: comID,
            val: val
        }));
    }

    onFocusOutAnalysis(e: FocusEvent, adjustment: ValuationAdjustmentModel) {
        const target = e.target as HTMLInputElement;
        const val = target.value;
        const _tmp = Object.assign({}, adjustment) as ValuationAdjustmentModel;
        _tmp.analysis = val;
        if (adjustment.analysis) {
            if (val.trim() !== adjustment.analysis.trim()) {
                this.store.dispatch(new EditValuationAdjustment({
                    valuationAdjustment: _tmp
                }))
            }
        } else {
            if (val.trim() !== '') {
                this.store.dispatch(new EditValuationAdjustment({
                    valuationAdjustment: _tmp
                }))
            }
        }
    }

    addAdjustment() {
        this.dialog.open(AdjustmentAddModalComponent, {
            width: '80vw',
            data: {
                comparables: this.comparables,
                alreadySelectedIds: this.valAdjustments.filter(item => item.adjustment_id != 0).map(item => item.adjustment_id)
            }
        }).afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.store.dispatch(new CreateValuationAdjustments({models: res}));
        });
    }


    editAdjustment(_model, _index) {
        this.dialog.open(AdjustmentModalComponent, {
            width: '80vw',
            data: {
                comparables: this.comparables,
                model: _model
            }
        }).afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            res.id = _model.id;
            const _items = this.dataSource.data;
            this.store.dispatch(new EditValuationAdjustment({
                valuationAdjustment: res
            }))
        });
    }

    deleteAdjustment(_model, _index) {
        const _title = this.translate.instant('FOUNDATION_TYPE.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('FOUNDATION_TYPE.LIST.DIALOG.DELETE.DESCRIPTION', {name: 'This adjustment'});
        const _waitDesciption = this.translate.instant('FOUNDATION_TYPE.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new RemoveValuationAdjustment({
                id: _model.id
            }));
        });
    }

    showBtmSheet(_item, _comI, _itemI) {
        this.bottomSheet.open(PercentageBottomSheet,
            {
                data: {
                    value: _item.adjValues[_comI]
                }
            }).afterDismissed().subscribe(
            res => {

                if (res) {
                    this.store.dispatch(new EditValueOfValuationAdjustment({
                        v: _item,
                        comID: _comI,
                        val: res
                    }))
                }
            }
        );
    }

    addFromTemplate() {
        const dialogRef = this.dialog.open(AdjustmentTemplateListComponent, {
            data: {
                assetClassTypeId: this.assetClassTypeId
            }
        });
        dialogRef.afterClosed().pipe().subscribe(
            res => {
                if (!res) {
                    return;
                }
                switch (res.type) {
                    case 'select':
                        const adjs = res.template.adjustments.map((item,i ) => {
                            const adj = new ValuationAdjustmentModel();
                            adj.clear();
                            adj.addComparable(this.comparables);
                            adj.id = i + 1;
                            adj.adjustment_id = item.adjustment_id;
                            adj.name = item.name;
                            adj.desc = item.description;
                            return adj;
                        });
                        this.store.dispatch(new ValuationAdjustmentComparableAdded({valuationAdjustments: adjs}));
                        break;
                    case 'view':
                        this._viewTemplate(res.template);
                        break;
                    default:
                        break;
                }
            }
        )
    }

    private _viewTemplate(template: AdjustmentTemplateModel) {
        this.dialog.open(AdjustmentTemplateModalComponent, {
            data: {
                template
            }
        });
    }
}


export function percentRangeValidator(control: AbstractControl): { [key: string]: any } | null {
    // const inValid = Number(control.value) < -100 || Number(control.value) > 100;
    const inputValue = control.value as string;
    // const matches = inputValue.match(/\d+/g);
    // const inValid = matches ? Number(matches[0]) > 100 ? true : false : false;
    const inValid = false;
    return inValid ? {
        error: {
            value: control.value
        }
    } : null;
}

export function validValue(control: AbstractControl): { [key: string]: any } | null {
    const isValid = !(control.value === '%' || control.value === '-%');

    return isValid ? null : {error: {value: control.value}};
}
