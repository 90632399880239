// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {FloorNumberingSchemesService} from '../_services/floor-numbering-scheme.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allFloorNumberingSchemesLoaded} from '../_selectors/floor-numbering-scheme.selectors';
// Actions
import {
    AllFloorNumberingSchemesLoaded,
    AllFloorNumberingSchemesRequested,
    FloorNumberingSchemeActionTypes,
    FloorNumberingSchemesPageRequested,
    FloorNumberingSchemesPageLoaded,
    FloorNumberingSchemeUpdated,
    FloorNumberingSchemesPageToggleLoading,
    FloorNumberingSchemeDeleted,
    FloorNumberingSchemeOnServerCreated,
    FloorNumberingSchemeCreated,
    FloorNumberingSchemeAdminRestored,
    FloorNumberingSchemeDeletedFromAdminTrash,
    FloorNumberingSchemeDeletedFromTrash,
    FloorNumberingSchemeOnServerAdminRestored,
    FloorNumberingSchemeOnServerRestored,
    FloorNumberingSchemeRestored,
    FloorNumberingSchemeTrashFlushed,
    FloorNumberingSchemesActionToggleLoading
} from '../_actions/floor-numbering-scheme.actions';

@Injectable()
export class FloorNumberingSchemeEffects {
    showPageLoadingDispatcher = new FloorNumberingSchemesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new FloorNumberingSchemesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new FloorNumberingSchemesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllFloorNumberingSchemes$ = this.actions$
        .pipe(
            ofType<AllFloorNumberingSchemesRequested>(FloorNumberingSchemeActionTypes.AllFloorNumberingSchemesRequested),
            withLatestFrom(this.store.pipe(select(allFloorNumberingSchemesLoaded))),
            filter(([action, isAllFloorNumberingSchemesLoaded]) => !isAllFloorNumberingSchemesLoaded),
            mergeMap(() => this.service.getAllFloorNumberingSchemes()),
            map(res => {
                return new AllFloorNumberingSchemesLoaded({FloorNumberingSchemes: res.data});
            })
        );

    @Effect()
    loadFloorNumberingSchemesPage$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemesPageRequested>(FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findFloorNumberingSchemes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new FloorNumberingSchemesPageLoaded({
                    FloorNumberingSchemes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeDeleted>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFloorNumberingScheme(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeUpdated>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateFloorNumberingScheme(payload.FloorNumberingScheme);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeOnServerCreated>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createFloorNumberingScheme(payload.FloorNumberingScheme).pipe(
                    tap(res => {
                        this.store.dispatch(new FloorNumberingSchemeCreated({FloorNumberingScheme: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushFloorNumberingSchemeTrash$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeTrashFlushed>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeOnServerRestored>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FloorNumberingSchemeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeOnServerAdminRestored>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FloorNumberingSchemeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeDeletedFromTrash>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashFloorNumberingScheme$ = this.actions$
        .pipe(
            ofType<FloorNumberingSchemeDeletedFromAdminTrash>(FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: FloorNumberingSchemesService, private store: Store<AppState>) {
    }
}
