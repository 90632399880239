export class CoiDetailsTPModel {

    id: number;
    conflict_details_id: number;
    conflict_details_name: string;
    toe_id: number;
    asset_class_ids: string;
    asset_class_names: string;
    conflict_id: number;
    conflict_name: string;
    conflict_descr: string;
    conflict_descr_b: string;
    type_of_conflict_id: number;
    appropriate_parties: string;
    reason_continue: string;
    order_num: number;
    order_num_label: string;

    clear() {
        this.id = undefined;
        this.conflict_details_id = undefined;
        this.conflict_details_name = '';
        this.toe_id = undefined;
        this.asset_class_ids = '';
        this.asset_class_names = '';
        this.conflict_id = 1;
        this.conflict_name = 'No';
        this.conflict_descr = 'N/A';
        this.conflict_descr_b = 'N/A';
        this.type_of_conflict_id = null;
        this.appropriate_parties = null;
        this.reason_continue = null;
        this.order_num = 1;
        this.order_num_label = '';
    }

}
