import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { ValuationProcessConsiderationCriterionUpdateValue } from 'src/app/core/valuation-process/_actions/consideration-criterion.actions';
import { ValuationProcessInsertCriterionsFromTemplate, ValuationProcessResetDefaultCriterion, ValuationProcessToggleCriterionActiveState } from 'src/app/core/valuation-process/_actions/criterion.actions';
import { ConsiderationCriterionModel, ConsiderationValueWithProcess, CriterionModel, DefaultCriterionModel } from 'src/app/core/valuation-process/_models/valuation-process-criterion.model';
import { selectValuationProcessSelectedComparables, selectValuationProcessSelectedComparablesFull, selectValuationProcessSelectedConsiderations, selectValuationProcessTargetPropertyInfo } from 'src/app/core/valuation-process/_selectors';
import { selectValuationProcessConsiderationCriterion } from 'src/app/core/valuation-process/_selectors/consideration-criterion.selector';
import { selectAllValuationProcessCustomCriterions } from 'src/app/core/valuation-process/_selectors/custom-criterion.selectors';
import { selectAllActiveValuationProcessCriterions } from 'src/app/core/valuation-process/_selectors/default-criterion.selectors';
import { CriterionsTemplateModalComponent } from 'src/app/views/pages/template/templates/criterions-template/_sub/criterions-template-modal/criterions-template-modal.component';
import { DeleteEntityDialogComponent } from 'src/app/views/partials/content/crud';
import { ConsiderationModalComponent, IConsiderationModalInputData, IConsiderationModalReturnData } from '../../../valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/consideration-modal.component';
import { CriterionEditModalComponent, CriterionEditModalInput } from '../../../valuation-process-edit/_sub/adjustment-tab/_modals/criterion-edit-modal/criterion-edit-modal.component';
import { CriterionTemplateListComponent } from '../../../valuation-process-edit/_sub/adjustment-tab/_modals/criterion-template-list/criterion-template-list.component';
import { CriterionsModalV2Component } from './criterions-modal-v2/criterions-modal-v2.component';

@Component({
  selector: 'kt-criterions-table-v2',
  templateUrl: './criterions-table-v2.component.html',
  styleUrls: ['./criterions-table-v2.component.scss']
})
export class CriterionsTableV2Component implements OnInit {
  @Output() removeComparable: EventEmitter<{ refNum: string }> = new EventEmitter();
  @Output() showComparable: EventEmitter<{ refNum: string }> = new EventEmitter();
  @Output() showComparableOverview: EventEmitter<{ refNums: string[] }> = new EventEmitter();

  viewModel$ = combineLatest([
    this.store$.select(selectValuationProcessSelectedComparables),
    this.store$.select(selectAllActiveValuationProcessCriterions),
    this.store$.select(selectValuationProcessConsiderationCriterion),
    this.store$.select(selectValuationProcessTargetPropertyInfo),
    this.store$.select(selectAllValuationProcessCustomCriterions)
  ]).pipe(
    filter(([refNums, criterions, considerationCriterion, ...other]) => {
      // criterions.find()
      let hasValue = true;
      refNums.forEach(refNum => {
        if (considerationCriterion.values[refNum] == undefined) {
          hasValue = false
        }
      })

      return hasValue
    }),
    map(([refNums, criterions, considerationCriterion, tp, customCriterions]) => {
      const { headerColumns, displayedColumns } = this._setHeaders(refNums)
      const dataSource = new MatTableDataSource()
      dataSource.data = [considerationCriterion, ...criterions, ...customCriterions]
      return {
        refNums,
        dataSource,
        headerColumns,
        displayedColumns,
        propertySubTypeId: tp.generalInfo.propertySubTypeId
      }
    })
  )

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  _setHeaders(refNums: any[]) {
    const headerColumns = [`input-${refNums.length}-header`];
    const displayedColumns = [`criterion-${refNums.length}`, `category-${refNums.length}`];
    const comparableHeaderColumns = [];
    refNums.forEach((com, i) => {
      headerColumns.push(`com-${i}-${refNums.length}-header`);
      comparableHeaderColumns.push(`com-${i}-${refNums.length}-header`);
      displayedColumns.push(`com-${i}-${refNums.length}`);
    });
    headerColumns.push(`tp-${refNums.length}-header`, 'action-header');
    displayedColumns.push(`tp-${refNums.length}`, 'actions');
    return { headerColumns, displayedColumns }
  }

  onAddExistingCriterion(refNums: string[]) {
    this.dialog.open(CriterionsModalV2Component, {
      data: {
        refNums
      },
      width: '80vw',
      height: '92vh'
    })
  }
  onAddCustomCriterion(refNums: string[]) {
    this.dialog.open<CriterionEditModalComponent, CriterionEditModalInput>(CriterionEditModalComponent, {
      data: {
        refNums,
        criterion: null
      },
      width: '80vw',
    })
  }
  onEditCriterion(refNums: string[], criterion: CriterionModel) {
    this.dialog.open<CriterionEditModalComponent, CriterionEditModalInput>(CriterionEditModalComponent, {
      data: {
        refNums,
        criterion: criterion
      },
      width: '80vw',
    })
  }
  onResetCriterion(criterion: DefaultCriterionModel) {
    combineLatest([
      this.store$.select(selectValuationProcessSelectedComparablesFull),
      this.store$.select(selectValuationProcessSelectedConsiderations)
    ]).pipe(
      take(1)
    ).subscribe(([res, considerations]) => {
      this.store$.dispatch(new ValuationProcessResetDefaultCriterion({
        criterion: criterion,
        comparables: res,
        selectedConsiderations: considerations
      }))
    })
  }
  onAddFromTemplate(propertySubTypeId: number, refNums: string[]) {
    const dialogRef = this.dialog.open(CriterionTemplateListComponent, {
      data: {
        assetClassTypeId: propertySubTypeId
      }
    })
    dialogRef.afterClosed().subscribe(
      res => {
        if (!res) {
          return;
        }

        switch (res.type) {
          case 'select':
            this.store$.dispatch(new ValuationProcessInsertCriterionsFromTemplate({
              refNums: refNums,
              defaultCriterions: res.template.default_criterions,
              customCriterions: res.template.custom_criterions
            }))
            break;
          case 'view':
            this.dialog.open(CriterionsTemplateModalComponent, {
              data: {
                template: res.template
              }
            })
            break;
          default:
            break;
        }
      }
    )
  }

  onShowComparable(refNum: string) {
    this.showComparable.emit({ refNum })
  }
  onShowComparableOverview(refNums: string[]) {
    this.showComparableOverview.emit({ refNums })
  }
  onRemoveComparable(refNum: string) {
    this.removeComparable.emit({ refNum })
  }

  onRemoveCriterion(criterion: CriterionModel) {
    if (criterion.type == 'Custom') {
      const _title = 'Are you sure?';
      const _description = `The criterion "${criterion.name}" will be removed`;
      const _waitDesciption = 'Removing criterion';
      const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
        data: {title: _title, description: _description, waitDesciption: _waitDesciption},
        width: '440px'
      });
    } else {
      this.store$.dispatch(new ValuationProcessToggleCriterionActiveState({
        id: criterion.id,
        active: false
      }))
    }
  }

  editConsiderationValue(refNum: string, criterion: ConsiderationCriterionModel, item: ConsiderationValueWithProcess) {
    const dialogRef = this.dialog.open<ConsiderationModalComponent, IConsiderationModalInputData, IConsiderationModalReturnData>(ConsiderationModalComponent, {
      width: '60vw',
      maxHeight: '90vh',
      data: {
        refNum: refNum,
        tpCurrency: criterion.countryCurrency,
        process: item.considerationConvertionData.process,
        consideration: item.consideration.oldTenure,
        hteProcessData: {
          methods: {
            main: item.considerationConvertionData.methods.mainMethod,
            sub: item.considerationConvertionData.methods.subMethod,
          },
          tvomRatesAndJustifications: {
            capRate: item.considerationConvertionData.rateValues.capRate,
            capRateJustification: item.considerationConvertionData.rateValues.capRateJustification,
            targetRate: item.considerationConvertionData.rateValues.targetRate,
            targetRateJustification: item.considerationConvertionData.rateValues.targetRateJustification
          },
          cfmRatesAndJustifications: item.considerationConvertionData.cfmRateValues,
          selected: item.considerationConvertionData.selected,
        },
        holdConversionProcessData: {
          ary: item.considerationConvertionData.ary
        }
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }

      this.store$.dispatch(new ValuationProcessConsiderationCriterionUpdateValue({
        refNum,
        value: res.value,
        convertionData: {
          process: item.considerationConvertionData.process,
          methods: {
            mainMethod: res.hteProcessData.methods.main,
            subMethod: res.hteProcessData.methods.sub
          },
          ary: res.holdConversionProcessData.ary,
          rateValues: {
            capRate: res.hteProcessData.tvomRatesAndJustifications.capRate,
            capRateJustification: res.hteProcessData.tvomRatesAndJustifications.capRateJustification,
            targetRate: res.hteProcessData.tvomRatesAndJustifications.targetRate,
            targetRateJustification: res.hteProcessData.tvomRatesAndJustifications.targetRateJustification
          },
          cfmRateValues: res.hteProcessData.cfmRatesAndJustifications,
          selected: res.hteProcessData.selected
        }
      }))
    })
  }

}
