import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { flatten } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingFacilityModel } from 'src/app/core/comparable';
import { InternalAspectData } from 'src/app/core/comparable/_models/asset-class-internal-aspect.model';
import { AllBuiltinFittingsRequested, AllStateRepairsRequested, AllWallsAndPartitionsRequested, AssetClassTypesService, BuiltinFitting, selectAllBuiltinFittings, selectAllStateRepairs, selectAllWallsAndPartitions, StateRepair, WallsAndPartition } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import fr_BE from 'src/assets/plugins/formvalidation/src/js/locales/fr_BE';
import { BuiltinFittingsSubListComponent } from '../builtin-fittings/list/builtin-fittings-sub-list.component';
import { FacilitiesSubListComponent } from '../facilities/list/facilities-sub-list.component';
import { BathroomFittingsSubListComponent } from '../bathroom-fittings/list/bathroom-fittings-sub-list.component';

@Component({
  selector: 'kt-building-internal-aspects',
  templateUrl: './building-internal-aspects.component.html',
  styleUrls: ['./building-internal-aspects.component.scss']
})
export class BuildingInternalAspectsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() readonly: boolean = false;
  @Input() parent: 'building-form' | 'comparable-form' = 'building-form';
  @Input() data: InternalAspectData;
  @ViewChild(FacilitiesSubListComponent, {static: false})
  private facilitiesListComponent: FacilitiesSubListComponent;
  @ViewChild('builtinFitting', {static: false}) builtinFittingsSubListComponent: BuiltinFittingsSubListComponent;
  @ViewChild('bathroomFitting', {static: false}) bathroomFittingsSubListComponent: BathroomFittingsSubListComponent;

  formGroup: UntypedFormGroup;
  stateOfRepairs: StateRepair[] = [];
  wallsAndPartitions: WallsAndPartition[] = [];
  selectedFacilities: BehaviorSubject<BuildingFacilityModel[]> = new BehaviorSubject([]);
  facilitiesInValid$: Subject<boolean> = new Subject();

  public builtinFittings: BuiltinFitting[] = [];
  selectedBuiltinFittings: any[] = [];
  errorFields = [];

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<AppState>,
    private assetClassTypesService: AssetClassTypesService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data && !changes.data.firstChange) {
      this._populateForm(this.formGroup);
    }
  }

  private _populateForm(formGroup?: UntypedFormGroup) {
    if (formGroup) {
      formGroup.patchValue({
        above_floors: this.data.above_floors,
        below_floors: this.data.below_floors,
        state_of_repair_id: this.data.state_of_repair_id,
        walls_and_partition_id: this.data.walls_and_partition_id,
        walls_and_partition_comment: this.data.walls_and_partition_comment,
        has_other: this.data.has_other,
        other_aspects: this.data.other_aspects
      });
      formGroup.updateValueAndValidity();
    } else {
      this.formGroup = this.formBuilder.group({
        above_floors: [this.data.above_floors, this.parent == 'building-form' ? Validators.required : null],
        below_floors: [this.data.below_floors],
        state_of_repair_id: [this.data.state_of_repair_id],
        walls_and_partition_id: [this.data.walls_and_partition_id],
        walls_and_partition_comment: [this.data.walls_and_partition_comment],
        has_other: [this.data.has_other],
        other_aspects: [this.data.other_aspects]
      });
    }

    if (this.data.building_facilities_ids) {
      const ids = this.data.building_facilities_ids.split(',').map(id => parseInt(id));
      this.assetClassTypesService.getAllAssetClassTypes().subscribe((res) => {
        let building_facilities: any[] = res.data
          .filter((assetClassType) => {
            return assetClassType.building_facilities.length > 0;
          })
          .map(item => item.building_facilities);
        this.selectedFacilities.next(flatten(building_facilities)
          .filter(bf => ids.includes(bf.id)).map(fb => ({...fb, facility_id: fb.id})));
      });
    }
  }

  ngOnInit(): void {
    this.store$.dispatch(new AllBuiltinFittingsRequested());
    this.store$.select(selectAllBuiltinFittings).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.builtinFittings = res ? res.filter(item => item.property_sub_type_id == -1) : [];
      if (this.data.builtin_fitting_ids && this.data.builtin_fitting_ids.length > 0 && this.builtinFittings.length > 0) {
        this.selectedBuiltinFittings = this.builtinFittings.filter(item => this.data.builtin_fitting_ids.split(',').includes(item.id.toString()))
      }
    });
    this.store$.dispatch(new AllStateRepairsRequested())
    this.store$.select(selectAllStateRepairs).pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        this.stateOfRepairs = res ? res : [];
      });
    this.store$.dispatch(new AllWallsAndPartitionsRequested());
    this.store$.select(selectAllWallsAndPartitions).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.wallsAndPartitions = res ? res.filter(item => item.property_sub_type_id == 0) : [];
    });
    this._populateForm();
    this.formGroup.controls.has_other.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      if (val) {
        this.formGroup.controls.other_aspects.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.other_aspects.clearValidators();
      }
      this.formGroup.controls.other_aspects.updateValueAndValidity();
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public getData(): InternalAspectData {
    const controls = this.formGroup.controls;
    return {
        id: this.data.id,
        roof_structure_id: null,
        roof_structure_comment: null,

        ceiling_id: null,
        ceiling_comment: null,

        walls_and_partition_id: controls.walls_and_partition_id.value,
        walls_and_partition_comment: controls.walls_and_partition_comment.value,

        floor_type_id: null,
        floor_type_comment: null,

        fireplaces_chimney_breast_id: null,
        fireplaces_chimney_breast_comment: null,

        bathroom_fitting_ids: this.bathroomFittingsSubListComponent
          ? this.bathroomFittingsSubListComponent.currentBathroomFittings.length == 0
            ? ''
            : this.bathroomFittingsSubListComponent.currentBathroomFittings.map(item => item.id).join(',')
          : '',
        builtin_fitting_ids: this.builtinFittingsSubListComponent 
          ? this.builtinFittingsSubListComponent.currentBuiltinFittings.length == 0 
            ? ''
            : this.builtinFittingsSubListComponent.currentBuiltinFittings.map(item => item.id).join(',')
          : '',

        woodwork: null,
        woodwork_comment: null,

        height: null,
        height_comment: null,

        cubic_content: null,
        cubic_content_comment: null,

        storage_type_id: null,
        storage_type_comment: null,

        shop_front_type_id: null,
        shop_front_type_comment: null,

        has_other: controls.has_other.value,
        other_aspects: controls.other_aspects.value,

        has_automation: false,
        automations: [],

        windows_id: null,
        windows_comment: null,

        above_floors: controls.above_floors.value,
        below_floors: controls.below_floors.value,
        state_of_repair_id: controls.state_of_repair_id.value,
        building_facilities_ids: this.facilitiesListComponent ? this.facilitiesListComponent.currentFacilities.map(cf => cf.facility_id).join(',') : null
    }
  }

}
