import { OnInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SignedToeDocModel } from '../../../../../core/file-upload';

@Component({
    selector: 'kt-signed-toe-dialog',
    templateUrl: './signed-toe-dialog.component.html',
    styleUrls: ['./signed-toe-dialog.component.scss']
})
export class SignedToeDialogComponent implements OnInit {

    viewLoading = false;
    form: UntypedFormGroup;
    model: SignedToeDocModel;
    checkedValue: string;
    showExplainSection: boolean;

    constructor(
        public dialogRef: MatDialogRef<SignedToeDialogComponent>,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.model = this.data.item;
        this.checkedValue = '0';
        this.createForm();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    save() {
        this.viewLoading = true;
        const _res = Object.assign({}, this.model);
        _res.has_altered = this.showExplainSection ? 1 : 0;
        _res.details = this.showExplainSection ? this.form.controls.explain.value : null;
        setTimeout(() => {
            this.dialogRef.close({
                item: _res
            });
        }, 500);
    }

    private createForm() {
        this.form = this.fb.group({
            has_altered: [this.checkedValue],
            explain: [''],
        });
    }

    radioChanged(event) {
        this.showExplainSection = event.value === '1' ? true : false;
    }
}