<div class="row">
	<div class="col-12">
		<div class="d-flex justify-content-between">
			<div class="d-flex">
				<h5 class="my-3 text-mad text-uppercase">
					{{ (tps$| async)?.length > 1 ? 'Remove a target properties from toe': 'Remove a target property from toe'}}
				</h5>
				<button mat-icon-button 
					placement="top"
					[ngbPopover]="'TOOLTIP.DASHBOARD.REMOVE_TP.DESCRIPTION'|translate"
					[popoverTitle]="'TOOLTIP.DASHBOARD.REMOVE_TP.TITLE' | translate">
					<mat-icon>info</mat-icon>
				</button>
			</div>
		</div>
		<hr class="active">
	</div>
</div>
<div class="form" [formGroup]="form">
	<div class="form-group">
		<div class="row">
			<ng-container *ngFor="let tp of (tps$|async)">
                <div class="col-lg-4 mt-4">
                    <mat-checkbox [disabled]="tp.disabled" (change)="onServiceChange($event, tp)">
                        <span>
                            {{tp.name}}
                        </span>
                    </mat-checkbox>
                </div>
            </ng-container>
		</div>
	</div>
	<ng-container *ngIf="tp_ids.length > 0">
		<div class="row">
			<div class="col-12">
				<p class="form__label">
					{{'Reason to remove Target Propert' + (tp_ids.length > 1 ? 'ies:' : 'y:')}}
				</p>
			</div>
			<div class="col-12">
				<mat-form-field class="mat-form-field-fluid">
					<textarea matInput placeholder="Reason" formControlName="reason"></textarea>
					<mat-error>
						Reason is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p class="form__label">
					Change ToE's date of completion? (Current: {{completionDate ? (completionDate | date: 'dd MMM yyyy') : ''}})
				</p>
			</div>
			<div class="col-12">
				<mat-radio-group formControlName="change_date_of_completion">
					<mat-radio-button [value]="1">Yes</mat-radio-button>
					<mat-radio-button [value]="0">No</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
		<div class="row" *ngIf="form.controls.change_date_of_completion.value == 1">
			<div class="col-12">
				<mat-form-field class="mat-form-field-fluid">
					<span (click)="completionDatePicker.open()">
						<input matInput [matDatepicker]="completionDatePicker" readonly 
							placeholder="New Date of Completion"
							formControlName="completion_date">
					</span>
					<mat-error>Date of Amendment is <strong>required</strong></mat-error>
					<mat-datepicker-toggle matSuffix [for]="completionDatePicker"></mat-datepicker-toggle>
					<button mat-button *ngIf="form.controls.completion_date" matSuffix mat-icon-button matTooltip="Clear"
						(click)="clearDate(form.controls.completion_date)">
						<mat-icon>close</mat-icon> 
					</button>
					<mat-datepicker #completionDatePicker></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
		<div class="form-group">
			<div class="row">
				<div class="col-12">
					<p class="form__label">
						Payment arbitration: 
						<span>
							<button mat-icon-button 
								placement="top"
								[ngbPopover]="'TOOLTIP.DASHBOARD.PAYMENT_ARBITRATION.DESCRIPTION' | translate"
								[popoverTitle]="'TOOLTIP.DASHBOARD.PAYMENT_ARBITRATION.TITLE' | translate" >
								<mat-icon>info</mat-icon>
							</button>
						</span>
					</p>
				</div>
				<div class="col-12 mx-0">
					<mat-radio-group formControlName="payment_arbitration" class="mx-0">
						<div class="col-12 mb-1 px-0 mx-0 mb-4">
							<mat-radio-button [value]="0">No</mat-radio-button>
						</div>
						<div class="col-12 px-0 mx-0">
							<mat-radio-button [value]="1">Additional Amount of</mat-radio-button>
							<mat-form-field *ngIf="form.controls.payment_arbitration.value == 1">
								<input matInput formControlName="paying_amount"  [mask]="'separator.2'" [thousandSeparator]="','">
								<mat-error>Amount is <strong>required</strong></mat-error>
							</mat-form-field>
							<mat-form-field *ngIf="form.controls.payment_arbitration.value != 1">
								<input matInput formControlName="disabled_input" [mask]="'separator.2'" [thousandSeparator]="','">
								<mat-error>Amount is <strong>required</strong></mat-error>
							</mat-form-field>
							<mat-label>{{currency}} (excluding taxes) to the initial amount</mat-label>
						</div>
						<div class="col-12 px-0 mx-0">
							<mat-radio-button [value]="2">Write-off an amount of</mat-radio-button>
							<mat-form-field *ngIf="form.controls.payment_arbitration.value == 2">
								<input matInput formControlName="paying_amount" [mask]="'separator.2'" [thousandSeparator]="','">
								<mat-error>Amount is <strong>required</strong></mat-error>
							</mat-form-field>
							<mat-form-field *ngIf="form.controls.payment_arbitration.value != 2">
								<input matInput formControlName="disabled_input" [mask]="'separator.2'" [thousandSeparator]="','">
								<mat-error>Amount is <strong>required</strong></mat-error>
							</mat-form-field>
							<mat-label>{{currency}} (excluding taxes) from the initial amount</mat-label>
						</div>
					</mat-radio-group>
				</div>
			</div>
			
		</div>
		<!-- <div class="form-group" *ngIf="form.controls.payment_arbitration.value == 1">
			<kt-payments-info 
				[currency]="currency" 
				[paymentSummary$]="paymentSummaryData$"
				[paymentTerms$]="paymentTermData$"
				[selectedTerms]="selectedTermIDs"
				[balance]="balance"
				(selectionChange)="selectionChange($event)"></kt-payments-info>
			<div class="row mt-10">
				<div class="col-12">
					<p class="form__label">Target Property's specific fees:</p>
				</div>
				<div class="col-12">
					<mat-table class="valuations-table" [dataSource]="valuationBreakdownDataSource">
						<ng-container matColumnDef="tp">
							<mat-header-cell *matHeaderCellDef>Target Property ID</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.tp_name}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="type">
							<mat-header-cell *matHeaderCellDef>Property Type</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.tp_type}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="method">
							<mat-header-cell *matHeaderCellDef>Method</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.method}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="investigation">
							<mat-header-cell *matHeaderCellDef>Investigation Type</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.investigation_type}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="size">
							<mat-header-cell *matHeaderCellDef>Size</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.size}} ({{unitAreaMeasurement}})
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="price">
							<mat-header-cell *matHeaderCellDef>
								<div>Price</div>
								<div class="font__small">(incl. taxes and discount)</div>
							</mat-header-cell>
							<mat-cell *matCellDef="let item">
								<div>
									<span>{{currency}} {{item.price | mask: 'separator.2':{thousandSeparator: ','} }}</span>
								</div>
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="['tp', 'type', 'method', 'investigation', 'size', 'price']"></mat-header-row>
						<mat-row *matRowDef="let row; columns: ['tp', 'type', 'method', 'investigation', 'size', 'price']; let index = index"
							[ngClass]="{gray: index%2}"></mat-row>
					</mat-table>
				</div>
				<div class="col-12 mt-8" *ngIf="hasAdditionalCosts">
					<mat-table class="additional-cost-table" [dataSource]="additionalCostDataSource">
						<ng-container matColumnDef="title">
							<mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.title}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="tp">
							<mat-header-cell *matHeaderCellDef>Target Property</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.tp}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="qty">
							<mat-header-cell *matHeaderCellDef>Qty</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{item.qty}}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="unit_price">
							<mat-header-cell *matHeaderCellDef>Unit price</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{currency}} {{item.price | mask: 'separator.2':{thousandSeparator: ','} }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="total">
							<mat-header-cell *matHeaderCellDef>
								<div>Total</div>
								<div class="font__small">(incl. taxes and discount)</div>
							</mat-header-cell>
							<mat-cell *matCellDef="let item">
								{{currency}} {{item.total | mask: 'separator.2':{thousandSeparator: ','} }}
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="['title', 'tp', 'qty', 'unit_price', 'total']"></mat-header-row>
						<mat-row *matRowDef="let row; columns: ['title', 'tp', 'qty', 'unit_price', 'total']; let index = index"
							[ngClass]="{gray: index%2}"></mat-row>
					</mat-table>
				</div>
			</div>
			<div class="row mt-10">
				<div class="col-12">
					<p class="form__label">
						Total Target Property's specific fees (including Taxes & Discounts):
					</p>
				</div>
				<div class="col-12">
					<div class="balance">
						{{tpBalance | mask: 'separator.2':{thousandSeparator: ','} }} {{currency}}
					</div>
				</div>
			</div>
			<div class="row mt-10">
				<div class="col-12">
					<p class="form__label">
						Payment decision: 
						<span>
							<button mat-icon-button 
								placement="top"
								[ngbPopover]="'TOOLTIP.DASHBOARD.PAYMENT_DECISION.DESCRIPTION' | translate"
								[popoverTitle]="'TOOLTIP.DASHBOARD.PAYMENT_DECISION.TITLE' | translate" >
								<mat-icon>info</mat-icon>
							</button>
						</span>
					</p>
				</div>
				<div class="col-12 payment-decision">
					<mat-form-field class="party-select">
						<mat-select formControlName="paying_party" [placeholder]="'Party'">
							<mat-option *ngFor="let party of parties" [value]="party.type">
								{{party.name}}
							</mat-option>
						</mat-select>
						<mat-error>Party is <strong>required</strong></mat-error>
					</mat-form-field>
					<span>will pay to</span>
					<mat-form-field class="party-select">
						<mat-select formControlName="payed_party" [placeholder]="'Party'">
							<mat-option *ngFor="let party of parties" [value]="party.type">
								{{party.name}}
							</mat-option>
						</mat-select>
						<mat-error>Party is <strong>required</strong></mat-error>
					</mat-form-field>
					<span>the amount of</span>
					<mat-form-field>
						<input matInput formControlName="paying_amount" [placeholder]="'Amount'" [mask]="'separator.2'" [thousandSeparator]="','">
						<mat-error>Amount is <strong>required</strong></mat-error>
					</mat-form-field>
					{{currency}} (excluding taxes)
				</div>
			</div>

			<div class="row mt-10">
				<div class="col-12">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput formControlName="comments" [placeholder]="'Any comments'"></textarea>
					</mat-form-field>
				</div>
			</div>
		</div> -->
		
	</ng-container>
</div>
