<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <div class="card-label" style="color: red;">{{data.title}}</div>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    {{ data.description }}
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="d-flex justify-content-between">
            <button mat-button (click)="close()">Close</button>
            <button mat-button color="primary" (click)="callBack()">
                {{ data.action }}
            </button>
        </div>
    </div>
</div>