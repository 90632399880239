import {OrganisationModel} from '../_models/organisation.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {OrganisationsState} from '../_reducers/organisation.reducer';
import * as fromOrganisation from '../_reducers/organisation.reducer';
import {each} from 'lodash';

export const selectOrganisationsState = createFeatureSelector<OrganisationsState>('organisations');

export const selectOrganisationById = (id: number) => createSelector(
    selectOrganisationsState,
    state => state.entities[id]
);

export const selectAllOrganisations = createSelector(
    selectOrganisationsState,
    fromOrganisation.selectAll
);

export const selectAllOrganisationsIds = createSelector(
    selectOrganisationsState,
    fromOrganisation.selectIds
);

export const allOrganisationsLoaded = createSelector(
    selectOrganisationsState,
    state => state.isAllOrganisationsLoaded
);


export const selectOrganisationsPageLoading = createSelector(
    selectOrganisationsState,
    state => state.listLoading
);

export const selectOrganisationsActionLoading = createSelector(
    selectOrganisationsState,
    state => state.actionLoading
);

export const selectLastCreatedOrganisationId = createSelector(
    selectOrganisationsState,
    state => state.lastCreatedOrganisationId
);

export const selectOrganisationsShowInitWaitingMessage = createSelector(
    selectOrganisationsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedOrganisationCount = createSelector(
    selectOrganisationsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedOrganisationCount = createSelector(
    selectOrganisationsState,
    (state) => state.totalAdminTrashed
);


export const selectOrganisationQueryResult = createSelector(
    selectOrganisationsState,
    state => {
        const items: OrganisationModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OrganisationModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
