import {Resolve} from '@angular/router';
import {User } from '../../../../../core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import {Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class GetCurrentUserResolver implements Resolve<User> {
    constructor(private _store: Store<AppState>) {
    }

    resolve(): Observable<User> {
        return this._store.pipe(
            take(1),
            select(currentUser)
        );
    }
}