import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { FloorAction, SizeChange } from '../sizes-module.types';
import * as _ from 'lodash';
import { SizeService } from '../../size-module/size.service';
import { ConditionRatingV2Service } from '../../../target-property/services/condition-rating-v2.service';

@Component({
  selector: 'kt-ipms-standard-measurement-component',
  templateUrl: './ipms-standard-measurement-component.component.html',
  styleUrls: ['./ipms-standard-measurement-component.component.scss']
})
export class IpmsStandardMeasurementComponentComponent implements OnInit, OnDestroy {
  @Input() assetClassID: number;
  @Input() sizeChanges$: Observable<SizeChange>
  @Input() floorAction$: Observable<FloorAction>
  @Input() readonly: boolean;
  config = [];
  legends = [];

  sizeInfo = null;
  sizeInfoLoaded$ = new BehaviorSubject(false);
  smId = null;
  schemeId = null;

  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    public smUtilityService: StandardMeasurementUtility,
    private sizeService: SizeService,
    private cdr: ChangeDetectorRef,
    private crService: ConditionRatingV2Service
  ) { }

  ngOnInit(): void {
    this.sizeChanges$.pipe(
      takeUntil(this._onDestroy$),
      filter(changes => {
        if (!changes) {
          return false
        }
        return true
      }),
      switchMap(changes => {
        return combineLatest([
          of(changes),
          this.sizeService.getIPMSResidentialSizeInfo(this.assetClassID, changes.standard_measurement_id, changes.unit_area_measurement_id)
        ]).pipe(takeUntil(this._onDestroy$))
      })
    ).subscribe(([changes, info]) => {
      this.smId = changes.standard_measurement_id;
      this.config = this.smUtilityService.getConfig(changes.standard_measurement_id, 'ipms');
      this.crService.setComponents(
        this.config
          .filter(c => c.key != 'total_component' && c.key != 'additional_component')
          .map(c => ({
            title: c.title,
            name: c.name,
            description: c.description,
            key: c.key
          }))
      );
      this.sizeInfo = info;
      const currentData = this.sizeInfo.data.map(floorInfo => {
        return this._computeTotal(floorInfo)
      });
      currentData.sort((a, b) => a.floor - b.floor);
      this.sizeInfo.data = currentData;
      this.sizeInfo.unit_of_area_measurement = changes.unit_area_measurement_name; 
      this.sizeInfoLoaded$.next(true);
      this.cdr.detectChanges();
    })

    this.sizeInfoLoaded$.pipe(
      filter(isLoaded => isLoaded),
      switchMap(_ => this.floorAction$),
      takeUntil(this._onDestroy$)
    ).subscribe(action => {
      if (!action) {
        return;
      }
      this.schemeId = action.scheme_id;

      const currentData = _.cloneDeep(this.sizeInfo.data);
      switch (action.type) {
        case 'add':
          action.floors.forEach(floor => {
            const index = currentData.findIndex(item => item.floor == floor)
            if (index > - 1) {
              return;
            }
            currentData.push(this._createColumn(floor));
          })
          break;
        case 'remove':
          action.floors.forEach(floor => {
            const index = currentData.findIndex(item => item.floor == floor);
            if (index == -1) {
              return;
            }
            currentData.splice(index, 1)
          })
          break;
      }
      currentData.sort((a, b) => a.floor - b.floor);
      this.sizeInfo.data = currentData;
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onFocusOut(event: FocusEvent, floor: number, component: string, type: string) {
    const target = event.target as HTMLInputElement;
    let val = Number(target.value);
    if (val < 0) {
      val = Math.abs(val);
    }
    this.updateValue(val, floor, component, type);
  }

  private updateValue(val: number, floor: number, component: string, type: string) {
    const currentData = _.cloneDeep(this.sizeInfo.data);
    const floorIndex = currentData.findIndex(item => item.floor == floor);
    if (floorIndex == -1) {
      return;
    }
    let floorInfo = currentData[floorIndex];
    floorInfo[component][type] = val;
    if (component != 'additional_component') {
      if (component != 'a1_component' && component != 'a2_component') {
        floorInfo[component]['ipms_total'] = floorInfo[component]['total'] - floorInfo[component]['limited'];
      } else {
        floorInfo[component]['total'] = val;
      }
      floorInfo = this._computeTotal(floorInfo)
    }
    currentData.splice(floorIndex, 1, floorInfo);
    currentData.sort((a, b) => a.floor - b.floor);
    this.sizeInfo.data = currentData;
  }

  private _computeTotal(floorInfo: any): any {
    let total = 0;
    let limited = 0;
    let ipms_total = 0;
    const keys = this.config.map(c => c.key);
    Object.entries(floorInfo).forEach(([key, value]: [string, any]) => {
      if (keys.includes(key) && key != 'total_component' && key != 'additional_component') {
        total += value.total;
        limited += value.limited;
        ipms_total += value.ipms_total;
      }
    });
    floorInfo.total_component.total = total;
    floorInfo.total_component.limited = limited;
    floorInfo.total_component.ipms_total = ipms_total;
    return floorInfo;
  }

  private _createColumn(floor: number): any {
    return {
      floor,
      a1_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      a2_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      b_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      b1_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      b2_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      b3_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      c_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      d_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      e_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      e1_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      e2_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      f_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      g_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      h_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      total_component: {
        total: 0,
        limited: 0,
        ipms_total: 0
      },
      additional_component: {
        aggregate_non_limited: 0,
        sheltered_areas: 0,
        any_other_areas: 0,
        external_bays: 0,
        open_light_wells: 0,
        external_car_parking: 0,
        temporary_structures: 0,
        open_external_stairways: 0,
        any_structures_beyond: 0,
        any_area_outside: 0,
        temporary_mezzanines: 0,
        open_external_stairways_structure: 0,
        external_areas: 0,
        other: 0,
        patios: 0,
        unenclosed_parking_areas: 0,
        staircases_opening_above: 0,
        void_including_enclosing_wall: 0,
        vertical_penetrations: 0
      }
    }
  }

  isValid(): boolean {
    const keys = this.config.map(c => c.key);
    for(let d of this.sizeInfo.data) {
      const entries = Object.entries(d);
      for (let item of entries) {
        const [key, value]: [string, any] = item;
        if (keys.includes(key) && key != 'total_component' && key != 'additional_component') {
          if (value.total < value.limited) {
            return false;
          }
        }
      }
    }
    return true;
  }

  getData(): any {
    return {
      ...this.sizeInfo,
      standard_measurement_id: this.smId
    };
  }
}