import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { environment } from 'src/environments/environment';
import * as fromFileUploadActions from '../../../../../../core/file-upload/_actions/file-upload.actions';
import * as fromFileUploadSelectors from '../../../../../../core/file-upload/_selectors/file-upload.selectors';
import * as fromFileUploadState from '../../../../../../core/file-upload/_states/file-upload.state';

@Component({
  selector: 'kt-other-documents',
  templateUrl: './other-documents.component.html',
  styleUrls: ['./other-documents.component.scss'],
})
export class OtherDocumentsComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() files: any[];
  @ViewChild('fileInput') fileInput;

  isInProgress$: Observable<boolean>;
  progress$: Observable<number>;
  hasFailed$: Observable<boolean>;
  error$: Observable<string>;
  completed$: Observable<boolean>;
  isReady$: Observable<boolean>;
  res$: Observable<any>;

  displayedColumns = ['type', 'name', 'size', 'title', 'descr', 'actions'];
  dataSource = new MatTableDataSource<any>([]);

  fileType: string = 'application/pdf';
  fileName: string;
  fileFolder: string = '';
  uploadFiles: any[] = [];
  docs = ['xlsx', 'xls', 'doc', 'docx'];

  basePath = environment.baseApiUrl;

  constructor(private store$: Store<AppState>, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService) {}

  ngOnInit(): void {
    this.resetUpload();
    this.uploadFiles = this.files.map(f => {
      return Object.assign({}, f);
    });
    this.dataSource.data = this.uploadFiles;

    this.fileName = '';
    this.completed$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileCompleted)
    );

    this.progress$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileProgress)
    );

    this.error$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileError)
    );

    this.isInProgress$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileInProgress)
    );

    this.isReady$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileReady)
    );

    this.hasFailed$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileFailed)
    );

    this.res$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileRes)
    );

    this.res$.subscribe((res) => {
      if (res) {
        this.fileFolder = res.success;
        const responseFile = Object.assign({}, res.uploaded_files[0]);
        responseFile.size = this.getHumanReadableSize(responseFile.size);
        this.uploadFiles = [...this.uploadFiles, responseFile];
        this.dataSource.data = this.uploadFiles;
      }
    });
  }

  uploadFile(event: any) {
    const files: FileList = event.target.files;
    const firstFile = files.item(0);
    this.fileName = firstFile.name;

    const formData = new FormData();
    Array.from(files).forEach((file, i) => {
      formData.append('files[' + i + ']', file, file.name);
    });
    if (this.fileFolder) {
      formData.append('path', this.fileFolder);
    }

    this.store$.dispatch(
      new fromFileUploadActions.UploadRequestAction({
        formData,
      })
    );

    // clear the input form
    event.srcElement.value = null;
  }

  cancelUpload() {
    this.fileName = '';
    this.store$.dispatch(new fromFileUploadActions.UploadCancelAction());
  }

  resetUpload() {
    this.fileFolder = '';
    this.uploadFiles = [];
    this.fileName = '';
    this.store$.dispatch(new fromFileUploadActions.UploadResetAction());
  }

  addFiles() {
    this.fileInput.nativeElement.click();
  }

  setIcon(type) {
    let ext = 'doc';
    switch (type) {
      case 'png':
        ext = 'png';
        break;
      case 'jpeg':
        ext = 'jpg';
        break;
      case 'jpg':
        ext = 'jpg';
        break;
      case 'gif':
        ext = 'gif';
        break;
      case 'pdf':
        ext = 'pdf';
        break;
      case 'xls':
        ext = 'xls';
        break;
      case 'xlsx':
        ext = 'xls';
        break;
      case 'rtf':
        ext = 'doc';
        break;
      case 'doc':
        ext = 'doc';
        break;
      case 'docx':
        ext = 'doc';
        break;
      case 'mp3':
        ext = 'mp3';
        break;
    }
    return './assets/media/files-alt/' + ext + '.svg';
  }

  excludeExtention(name) {
    return name.substring(0, name.indexOf('.')).toLowerCase();
  }

  editFileDescription(file) {
    const _title = 'Adding Information';
    const _description = '';
    const _waitDesciption = '';
    const _fileTitle = file.title ? file.title : '';
    const _fileDescription = file.descr ? file.descr : '';
    let _fileDescriptionTooltip = null;
    const _checkedValue = file.is_checked;
    const _isFeatured = file.is_featured;
    let _currentDropDownValue = '';

    const dialogRef = this.layoutUtilsService.saveElementWithInfo(
      _title,
      _description,
      _waitDesciption,
      _fileTitle,
      true,
      _fileDescription,
      _fileDescriptionTooltip,
      false,
      _checkedValue,
      false,
      _isFeatured,
      [],
      _currentDropDownValue
    );

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const index = this.uploadFiles.indexOf(file);
      const editedObj = Object.assign({}, file);
      editedObj.title = res.title;
      editedObj.descr = res.descr || '';
      editedObj.is_checked = res.is_checked;
      editedObj.is_featured = res.is_featured;
      // this.uploadFiles = Object.assign([], this.uploadFiles, {[index]: editedObj});
      this.uploadFiles[index] = editedObj;
      this.dataSource.data = this.uploadFiles
    });
  }
  deleteUploadedFile(file) {
    const index = this.uploadFiles.indexOf(file);
    this.uploadFiles.splice(index, 1);
    this.dataSource.data = this.uploadFiles;
  }
  previewUploadedFile(file) {
    if (this.docs.indexOf(this.getFileType(file.name)) > -1) {
      window.open(this.basePath + file.path + '/' + file.name);
    } else {
      const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
        data: {
          title: file.title,
          picture: file.path + '/' + file.name,
          type: this.getFileType(file.name),
        },
      });
    }
  }

  getFileType(name) {
    return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
  }

  private getHumanReadableSize(bytes: number, si=false, dp=1): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
  }
}
