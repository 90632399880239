// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {OutsideDoorService} from '../_services/outside-door.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allOutsideDoorsLoaded} from '../_selectors/outside-door.selectors';
// Actions
import {
    AllOutsideDoorsLoaded,
    AllOutsideDoorsRequested,
    OutsideDoorActionTypes,
    OutsideDoorsPageRequested,
    OutsideDoorsPageLoaded,
    OutsideDoorUpdated,
    OutsideDoorsPageToggleLoading,
    OutsideDoorDeleted,
    OutsideDoorOnServerCreated,
    OutsideDoorCreated,
    OutsideDoorActionToggleLoading, OutsideDoorTrashFlushed, OutsideDoorOnServerRestored, OutsideDoorRestored,
    OutsideDoorOnServerAdminRestored, OutsideDoorAdminRestored, OutsideDoorDeletedFromTrash, OutsideDoorDeletedFromAdminTrash
} from '../_actions/outside-door.actions';

@Injectable()
export class OutsideDoorEffects {
    showPageLoadingDispatcher = new OutsideDoorsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OutsideDoorActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OutsideDoorActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOutsideDoor$ = this.actions$
        .pipe(
            ofType<AllOutsideDoorsRequested>(OutsideDoorActionTypes.AllOutsideDoorsRequested),
            withLatestFrom(this.store.pipe(select(allOutsideDoorsLoaded))),
            filter(([action, isAllOutsideDoorLoaded]) => !isAllOutsideDoorLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllOutsideDoorsLoaded({items: res.data});
            })
        );

    @Effect()
    loadOutsideDoorPage$ = this.actions$
        .pipe(
            ofType<OutsideDoorsPageRequested>(OutsideDoorActionTypes.OutsideDoorsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OutsideDoorsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorUpdated>(OutsideDoorActionTypes.OutsideDoorUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorOnServerCreated>(OutsideDoorActionTypes.OutsideDoorOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new OutsideDoorCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushOutsideDoorTrash$ = this.actions$
        .pipe(
            ofType<OutsideDoorTrashFlushed>(OutsideDoorActionTypes.OutsideDoorTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorOnServerRestored>(OutsideDoorActionTypes.OutsideDoorOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OutsideDoorRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorOnServerAdminRestored>(OutsideDoorActionTypes.OutsideDoorOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OutsideDoorAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorDeleted>(OutsideDoorActionTypes.OutsideDoorDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorDeletedFromTrash>(OutsideDoorActionTypes.OutsideDoorDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashOutsideDoor$ = this.actions$
        .pipe(
            ofType<OutsideDoorDeletedFromAdminTrash>(OutsideDoorActionTypes.OutsideDoorDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: OutsideDoorService, private store: Store<AppState>) {
    }
}
