import { Injectable } from "@angular/core";
import { CriterionModel } from "../_models/criterion.model";
import { ValuationAdjustmentModel } from "../_models/valuation-adjustment.model";
import { AdjustmentValuation } from "../_models/valuation.model";

@Injectable()
export class AdjustmentValuationService {
    createNewValuation(com: any, lastID: number): AdjustmentValuation {
        const v = new AdjustmentValuation();
        v.id = lastID + 1;
        v.comInfo = {
            id: com.id,
            ref_num: com.ref_num
        };
        v.totalAdjustment = 0;
        v.weighting = 0;
        v.adjustedConsideration = 0;
        v.adjustedGrossUnitRate = 0;
        v.weightedAvgGrossUnitRate = 0;
        return v;
    }

    createMultipleValuations(coms: any[]): {vals: AdjustmentValuation[], id: number} {
        const _vals: AdjustmentValuation[] = []
        let id = 0;
        coms.forEach(c => {
            const res = this.createNewValuation(c, id)
            _vals.push(res);
            id++;
        })
        return {
            vals: _vals,
            id: id
        };
    }

    updateValuations(
        valuations: AdjustmentValuation[],
        sizeCriterion: CriterionModel,
        considerationCriterion: CriterionModel,
        adjustments: ValuationAdjustmentModel[]
    ): AdjustmentValuation[] {
        const res: AdjustmentValuation[] = [];
        valuations.forEach(v => {
            const newVal = Object.assign({}, v) as AdjustmentValuation;
            let total = 0;
            adjustments.forEach(ad => {
                total += ad.adjValues[v.comInfo.id];
            })
            
            // size and consideration
            let sizeValue = this._getSizeValueCom(sizeCriterion, v.comInfo.id);
            let consValue = considerationCriterion.comValues[v.comInfo.id] ? considerationCriterion.comValues[v.comInfo.id] : 0;

            newVal.totalAdjustment = total;
            newVal.adjustedConsideration = this._computeAdjustedConsideration(newVal.totalAdjustment, consValue);
            newVal.adjustedGrossUnitRate = this._computeAdjustedGrossUnitRate(newVal.adjustedConsideration, sizeValue);
            newVal.weightedAvgGrossUnitRate = this._computeWeightedAvgGrossUnitRate(newVal.adjustedGrossUnitRate, newVal.weighting);
            res.push(newVal);
        })
        return res;
    }

    updateValuation(
        valuation: AdjustmentValuation,
        sizeCriterion: CriterionModel,
        considerationCriterion: CriterionModel,
        adjustments: ValuationAdjustmentModel[],
    ): AdjustmentValuation {
        const newVal = Object.assign({}, valuation) as AdjustmentValuation;
        let total = 0;
        adjustments.forEach(ad => {
            total += ad.adjValues[valuation.comInfo.id];
        })
        
        // size and consideration
        let sizeValue = this._getSizeValueCom(sizeCriterion, valuation.comInfo.id);
        let consValue = considerationCriterion.comValues[valuation.comInfo.id] ? considerationCriterion.comValues[valuation.comInfo.id] : 0;

        newVal.totalAdjustment = total;
        newVal.adjustedConsideration = this._computeAdjustedConsideration(newVal.totalAdjustment, consValue);
        newVal.adjustedGrossUnitRate = this._computeAdjustedGrossUnitRate(newVal.adjustedConsideration, sizeValue);
        newVal.weightedAvgGrossUnitRate = this._computeWeightedAvgGrossUnitRate(newVal.adjustedGrossUnitRate, newVal.weighting);
        return newVal;
    }

    computeTotals(valuations: AdjustmentValuation[], sizeCriterion: CriterionModel, capitalAllowance: number): {
        totalWeighted: number,
        totalValue: number,
        totalTPValue: number,
        totalTPWeighted: number
    } {
        let totalWeighted = 0;
        let totalValue = 0;
        let totalTPValue = 0;
        let totalTPWeighted = 0;
        let atLeastOneWeightedHasValue = false;
        valuations.forEach(v => {
            let val = 0;
            if (v.weightedAvgGrossUnitRate !== undefined) {
                val = v.weightedAvgGrossUnitRate;
                atLeastOneWeightedHasValue = true;
            }
            totalWeighted += val;
        });
        totalWeighted = atLeastOneWeightedHasValue ? totalWeighted : undefined;

        let sizeVal = this._getSizeValueTP(sizeCriterion);

        if (totalWeighted !== undefined) {
            totalValue = totalWeighted * sizeVal;
        } else {
            totalValue = undefined
        }

        if (totalValue == undefined) {
            totalTPValue = undefined;
            totalTPWeighted = undefined
        } else {
            totalTPValue = totalValue + capitalAllowance;
            if (sizeVal == undefined || sizeVal == 0) {
                totalTPWeighted = undefined;
            } else {
                totalTPWeighted = totalTPValue / sizeVal
            }
        }

        return {
            totalWeighted, totalValue, totalTPValue, totalTPWeighted
        }
    }

    _computeAdjustedConsideration(adjustment: number, consideration: number): number {
        const val = consideration * adjustment;
        const temp = val / 100;
        return consideration + temp;
    }

    _computeAdjustedGrossUnitRate(adjustedConsideration: number, size: number): number {
        if (size) {
            return adjustedConsideration / size;
        }
        return undefined;
    }

    _computeWeightedAvgGrossUnitRate(adjustedGrossUnitRate: number, weighting: number): number {
        if (adjustedGrossUnitRate !== undefined) {
            const val = adjustedGrossUnitRate * weighting;
            return val / 100;
        }
        return undefined;
    }

    _getSizeValueCom(sizeCriterion: CriterionModel, comID: number): number {
        let sizeValue = sizeCriterion.comValues[comID] ? sizeCriterion.comValues[comID] : 0;
        if (typeof sizeValue === 'string') {
            let temp = sizeValue.split(',');
            let tempValue = temp.join('');
            return Number(tempValue);
        } else {
            return sizeValue;
        }
    }

    _getSizeValueTP(sizeCriterion: CriterionModel): number {
        let sizeValue = sizeCriterion.tpValue ? sizeCriterion.tpValue : 0;
        if (typeof sizeValue === 'string') {
            let temp = sizeValue.split(',');
            let tempValue = temp.join('');
            return Number(tempValue);
        } else {
            return sizeValue;
        }
    }
}