// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {UserActions, UserActionTypes} from '../_actions/user.actions';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import { User } from '../../mad-auth/mad-auth.store';

// tslint:disable-next-line:no-empty-interface
export interface UsersState extends EntityState<User> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    lastCreatedUserId: number;
    lastQuery: QueryParamsModel;
    totalTrashed: number;
    totalAdminTrashed: number;
    showInitWaitingMessage: boolean;
    userExistsStatus: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialUsersState: UsersState = adapter.getInitialState({
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedUserId: undefined,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    showInitWaitingMessage: true,
    userExistsStatus: false
});

export function usersReducer(state = initialUsersState, action: UserActions): UsersState {
    switch (action.type) {
        case UserActionTypes.UsersPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedUserId: undefined
            };
        case UserActionTypes.UsersActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case UserActionTypes.UserOnServerCreated:
            return state;

        case UserActionTypes.UserCreated:
            return adapter.addOne(action.payload.user, {
                ...state, lastCreatedUserId: action.payload.user.id
            });

        case UserActionTypes.UserUpdated:
            return adapter.updateOne(action.payload.partialUser, state);

        case UserActionTypes.UsersPageCancelled:
            return {
                ...state,
                listLoading: false,
                lastQuery: new
                QueryParamsModel({})
            };
        case UserActionTypes.UsersPageLoaded: {
            return adapter.addMany(action.payload.items, {
                ...initialUsersState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }

        case UserActionTypes.AllUsersOfRoleLoaded: {
            return adapter.addMany(action.payload.users, state);
        }

        case UserActionTypes.UserNameCheckAction: {
            return {
                ...state, userExistsStatus: action.payload.userNameExists
            };
        }

        case UserActionTypes.UserStatusUpdated:
            return adapter.updateOne(action.payload.user, state);

        case UserActionTypes.UserDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case UserActionTypes.UserTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case UserActionTypes.UserRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case UserActionTypes.UserDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case UserActionTypes.UserAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case UserActionTypes.UserDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        default:
            return state;
    }
}

export const getUserState = createFeatureSelector<UsersState>('users');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
