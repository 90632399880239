import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-restore-modal',
  templateUrl: './restore-modal.component.html',
  styleUrls: ['./restore-modal.component.scss']
})
export class RestoreModalComponent implements OnInit {
  viewLoading = false;
  constructor(
    public dialogRef: MatDialogRef<RestoreModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(true);
    }, 2500)
  }
}
