<div class="card card-custom">
    <div class="card-body" *ngIf="essentialDataLoaded$|async; else loading">
        <div class="row">
            <div class="col-md-12 kt-margin-bottom-20-mobile">
                <h5 class="my-3 text-mad text-uppercase">{{targetProperty.name}}</h5>
                <hr class="active">
            </div>
        </div>

        <div class="row">
            <div class="col-xl-8 px-3">
                <kt-pictures-module [tpDetails]="tpDetails"></kt-pictures-module>
            </div>
            <div class="col-xl-4 px-3" style="height: 700px;">
                <div style="display: flex; flex-direction: column; height: 100%">
                    <div class="row" style="flex-grow: 1;">
                        <div class="col-xl-12">
                            <kt-milestone-module [targetProperty]="targetProperty" [tpDetails]="tpDetails"></kt-milestone-module>
                        </div>
                    </div>
                    <div class="row mt-6" style="flex-grow: 1;">
                        <div class="col-xl-12">
                            <kt-valuation-module [targetProperty]="targetProperty" [toe]="toe"></kt-valuation-module>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-6">
            <div class="col-xl-4 px-3">
                <kt-team-involvement-module [toe]="toe" [targetProperty]="targetProperty"
                    [assignment]="assignment" [workers]="workers"></kt-team-involvement-module>
            </div>
            <div class="col-xl-8 px-3">
                <kt-property-specifics-module 
                    [toe]="toe" 
                    [targetProperty]="targetProperty" 
                    [tpDetails]="tpDetails">
                </kt-property-specifics-module>
            </div>
        </div>

        <div class="row mt-6" *ngIf="targetProperty.type_of_inspection != 1 && showConditionRating">
            <div class="col-xl-12 px-3">
                <kt-condition-ratin-module-v2
                    [targetProperty]="targetProperty"
                    [tpDetails]="tpDetails"></kt-condition-ratin-module-v2>
            </div>
        </div>

        <div class="row mt-6">
            <div class="col-xl-12 px-3">
                <kt-available-documents-module [toe]="toe" [targetProperty]="targetProperty"
                    [assignment]="assignment" [workers]="workers"></kt-available-documents-module>
            </div>
        </div>

        <div class="row mt-6">
            <div class="col-xl-12 px-3">
                <kt-special-assumptions-module [targetProperty]="targetProperty"></kt-special-assumptions-module>
            </div>
        </div>

        <div class="row mt-6">
            <div class="col-xl-12 px-3">
               <kt-notes-module [readonly]="readonly" [workers]="workers" [valuerUserId]="valuerUserId" [currentUser]="currentUser"></kt-notes-module>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading"></div>
</ng-template>