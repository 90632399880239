import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { TermsOfEngagementFilesService } from 'src/app/core/terms-of-engagement/_services/terms-of-engagement-files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-download-toe-files-dialog',
  templateUrl: './download-toe-files-dialog.component.html',
  styleUrls: ['./download-toe-files-dialog.component.scss'],
  providers: [TermsOfEngagementFilesService]
})
export class DownloadToeFilesDialogComponent implements OnInit {

  legalDocuments$ = this.service.fetchLegalDocuments(this.data.toe_id).pipe(
    map(documents => documents.filter(document => document != null))
  )

  targetPropertyReports$ = this.service.fetchAssetClassReports(this.data.toe_id).pipe(
    map(response => {
      return response.map(({name, reports, audit_reports}) => {
        reports.sort((a, b) => {
          const d1 = new Date(b.created_at)
          const d2 = new Date(a.created_at)
          return d1.getTime() - d2.getTime()
        });
        const _reports = reports;

        const audits = audit_reports.sort((a, b) => (a.id < b.id)
          ? 1
          : (b.id < a.id)
            ? -1
            : 0).filter(report => report.status == 1)

        if (audits.length > 0) {
          _reports.push({
            type: 3,
            url: audits[0].url,
            created_at: audits[0].created_at
          })
        }

        return {
          name,
          reports: _reports
        }
      }).filter(tpReports => tpReports.reports.length != 0)
    })
  )

  constructor(
    private dialogRef: MatDialogRef<DownloadToeFilesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {toe_id: number},
    private service: TermsOfEngagementFilesService,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  onDownloadFile(url: string) {
    if (url == null) {
      return
    }

    if (url.includes('http')) {
      window.open(url, '_blank')
    } else {
      window.open(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + url, '_blank')
    }
  }
}
