import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, fromEvent, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { AssetClassMapPageRequested, AssetClassOfficeMapPageRequested, selectAssetClassOfficesInStore, selectAssetClassResidentialsInStore } from 'src/app/core/comparable';
import { AssetClassHousePageRequested } from 'src/app/core/comparable/_actions/asset-class-house.actions';
import { AssetClassParkingPageRequested } from 'src/app/core/comparable/_actions/asset-class-parking.actions';
import { AssetClassRetailBuildingPageRequested } from 'src/app/core/comparable/_actions/asset-class-retail-building.actions';
import { AssetClassRetailShopPageRequested } from 'src/app/core/comparable/_actions/asset-class-retail-shop.actions';
import { AssetClassWarehousePageRequested } from 'src/app/core/comparable/_actions/asset-class-warehouse.actions';
import { selectAssetClassHouseInStore } from 'src/app/core/comparable/_selectors/asset-class-house.selectors';
import { selectAssetClassParkingInStore } from 'src/app/core/comparable/_selectors/asset-class-parking.selectors';
import { selectAssetClassRetailBuildingInStore } from 'src/app/core/comparable/_selectors/asset-class-retail-building.selectors';
import { selectAssetClassRetailShopInStore } from 'src/app/core/comparable/_selectors/asset-class-retail-shop.selectors';
import { selectAssetClassWarehouseInStore } from 'src/app/core/comparable/_selectors/asset-class-warehouse.selectors';
import { AppState } from 'src/app/core/reducers';
import { ValuationDataModel } from 'src/app/core/valuation';
import { UpdateValuationData } from 'src/app/core/valuation/_actions/valuation-data.actions';
import { selectAllValComparables, selectAllValTenures, selectValuationData } from 'src/app/core/valuation/_selectors/valuation-data.selector';
import { QueryParamsModel, QueryResultsModel } from 'src/app/core/_base/crud';
import { HouseFilterDialogComponent } from 'src/app/views/pages/comparable/house/_sub/house-filter-dialog/house-filter-dialog.component';
import { FilterDialogComponent } from 'src/app/views/pages/comparable/_sub/filter-dialog/filter-dialog.component';
import { FilterOfficeDialogComponent } from 'src/app/views/pages/comparable/_sub/filter-office-dialog/filter-office-dialog.component';
import { emptyFilter, FilterChange, FilterModel } from 'src/app/views/pages/shared_components/filter-section/filter-section.component';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput } from 'src/app/views/pages/shared_components/map-marker-info-window/map-marker-info-window.component';
import { ToMarkerItemConverterService } from 'src/app/views/pages/shared_components/map-marker-info-window/to-marker-item-converter-service.service';
import { ComparableInfoDialogComponent } from '../../comparable-info-dialog/comparable-info-dialog.component';
import {FilterDialogComponent as FDComponent, Filter, HouseFilter, WarehouseFilter} from '../_sub/filter-dialog-component/filter-dialog-component.component';
import * as _ from 'lodash'
import { selectAssetClassLandInStore } from 'src/app/core/comparable/_selectors/asset-class-land.selectors';
import { AssetClassLandPageRequested } from 'src/app/core/comparable/_actions/asset-class-land.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-comparable-tab-map',
  templateUrl: './comparable-tab-map.component.html',
  styleUrls: ['./comparable-tab-map.component.scss'],
})
export class ComparableTabMapComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  valuation: ValuationModel;

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;
  @ViewChild('searchLocation', { static: true })
  searchElementRef: ElementRef;

  valData: ValuationDataModel;

  filterCnt = 0;

  assetClassResult = [];
  assetClassType: number;
  private selectedComparables: any[] = [];
  acTitle = [
    '',
    'Apartment',
    'Parking',
    'Office',
    'Business use',
    'Retail Shop',
    'Industrial',
  ];

  map: any;
  private markerData = new Map();
  private mapAction = new BehaviorSubject<boolean>(true);
  markerData$ = new BehaviorSubject(new Map());
  centerLat = environment.mapCenter.lat;
  centerLng = environment.mapCenter.lng;
  currentCenter: { lat: 0; lng: 0 };
  zoom = 15;
  icon = {
    url: './assets/media/icons/my_marker.svg',
  };
  tpIcon = {
    labelOrigin: {x: 18, y: 22},
    url: './assets/media/icons/pin_red.svg',
    scaledSize: {
        width: 30,
        height: 30
    }
  }
  private isFirst = true;
  private latLngSW: google.maps.LatLng;
  private latLngNE: google.maps.LatLng;
  private _north = new BehaviorSubject<number>(47.92399006317647);
  private _south = new BehaviorSubject<number>(47.9096089375195);
  private _east = new BehaviorSubject<number>(106.9476215928344);
  private _west = new BehaviorSubject<number>(106.8833344071655);
  private modal;
  previous = null;
  selectedTenures = [];

  private OnDestroy = new Subject<void>();
  filterModel: FilterModel = emptyFilter();
  filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
  filterObs$ = this.filter$.asObservable();

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private toMarkerItemConverterService: ToMarkerItemConverterService
  ) {}

  ngOnInit(): void {
    this.filterModel = emptyFilter({valuation_id: this.valuation.id});
    this.filter$.next(this.filterModel);
    this.centerLat = Number(this.targetProperty.details.locationData.latitude);
    this.centerLng = Number(this.targetProperty.details.locationData.longitude);
    this.store$
      .pipe(takeUntil(this.OnDestroy))
      .pipe(select(selectValuationData))
      .subscribe((res: ValuationDataModel) => {
        this.valData = res;
      });

    this.assetClassType = this.targetProperty.type_id;


    this.store$
      .pipe(select(selectAllValComparables))
      .pipe(takeUntil(this.OnDestroy))
      .subscribe((res) => {
        this.selectedComparables = res;
      });

    this.store$.pipe(select(selectAllValTenures)).pipe(takeUntil(this.OnDestroy)).subscribe(res => {
      this.selectedTenures = res;
    })

    this.selectComparables();
  }

  ngOnDestroy() {
    this.OnDestroy.next();
    this.OnDestroy.complete();
  }

  //
  // Events
  //
  mapReady(event) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(
      document.getElementById('Profile')
    );

    this.map.addListener('dragend', () => {
      this.centerLat = this.currentCenter.lat;
      this.centerLng = this.currentCenter.lng;

      this.mapAction.next(true);
      this.loadList();
    });
  }

  centerChange($event: any) {
    if ($event) {
      this.currentCenter = { lat: $event.lat, lng: $event.lng };
    }
  }

  zoomChanged($event) {
    this.isFirst = true;
  }

  boundsChanged(_latLngBound) {
        this.latLngNE = _latLngBound.getNorthEast();
        this.latLngSW = _latLngBound.getSouthWest();

        const xPadding = (this.latLngNE.lat() - this.latLngSW.lat()) * 0.08;
        this._north.next(this.latLngNE.lat() - xPadding);
        this._south.next(this.latLngSW.lat() + xPadding);

        this._east.next(this.latLngNE.lng() - xPadding);
        this._west.next(this.latLngSW.lng() + xPadding);
        const _new = Object.assign({}, this.filterModel) as FilterModel;

        if (_new.bounds == null) {
            _new.bounds = {ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0}
        } else {
            _new.bounds = Object.assign({}, _new.bounds);
        }
        _new.bounds.sw_lat = _latLngBound.getSouthWest().lat();
        _new.bounds.ne_lat = _latLngBound.getNorthEast().lat();
        _new.bounds.sw_lng = _latLngBound.getSouthWest().lng();
        _new.bounds.ne_lng = _latLngBound.getNorthEast().lng();

        this.filterModel = _new;

        if (this.isFirst) {
            if (this.modal) {
                this.modal.close();
            }
            this.previous = null;
            this.mapAction.next(true);
            this.isFirst = !this.isFirst;
            this.loadList();
        }
  }

    onFilterChange(event: FilterChange) {
        this.filterModel = event.filter;
        this.loadList();
    }

  setLocation() {
    this.setCurrentLocation();
  }

  showInfoV2(key, infoData) {
    const sub = new Subject<number>();
    const datas = _.cloneDeep(infoData);
    const data = this.toMarkerItemConverterService.convert('comparable', datas.map(id => ({...id,  ac_type: this.assetClassType})));
    this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
        data: {
            modal_title: data.modal_title,
            entities: data.entities,
            additional: {
              type: 'open-modal',
              channel: sub
            } 
        }
    });
    sub.pipe().subscribe(res => {
      // This is workaround. If we remove this line, dialog content won't be rendered. (ngOnInit won't be called)
      // ----------------
      this.cdr.markForCheck()
      // ----------------
      if (!res) {
        return
      }
      const d = infoData.find(item => item.id == res);
      if (!d) {
        return;
      }
      this._showOverView([d]);
    })
  }

  private _showOverView(infoData) {
      const dialogRef = this.dialog.open(ComparableInfoDialogComponent, {
        data: {
          propertySubTypeId: this.assetClassType,
          targetProperty: this.targetProperty,
          comparables: [infoData[0]],
          valuation: this.valuation,
          selectedIds: this.selectedComparables 
            ? this.selectedComparables.map(el => el.id)
            : [],
          addButton: this.readonly ? false : true,
          isMulti: false
        },
        width: '80vw',
        viewContainerRef: this.viewContainerRef,
        disableClose: true
      });
      const dialogSub = dialogRef.afterClosed().subscribe(res => {
        if (!res || !res.ids) {
          return;
        }
        this.overviewAction(res, infoData);
      })
  }

  public overviewAction(res, infoData) {
    let _tmp = Object.assign(
      [],
      this.selectedComparables ? this.selectedComparables : []
    );
    _tmp = _tmp.filter((el) => res.ids.indexOf(el.id) > -1);

    const ids = res.ids.filter((x) => _tmp.map((el) => el.id).indexOf(x) < 0); // added Ac ids
    if (ids.length > 0) {
      _tmp = [
        ..._tmp,
        ...this.assetClassResult.filter((el) => ids.indexOf(el.id) > -1),
      ];
    }


    let _otherTenures = this.selectedTenures;
    if (res.tenureInfo.length > 0) {
      _otherTenures = this.selectedTenures.filter(el => el.comId != res.tenureInfo[0].comId);
      _otherTenures = [..._otherTenures, ...res.tenureInfo]
    } else {
      _otherTenures = this.selectedTenures.filter(el => el.comId != infoData[0].id)
    }

    this.saveComData(_tmp, _otherTenures);
  }

  showInfo(infoData, mInfo, agMap) {
    if (infoData.length > 1) {
      if (agMap.lastOpen != null) {
        agMap.lastOpen.close();
      }

      agMap.lastOpen = mInfo;

      mInfo.open();
      return;
    }
    of(undefined)
      .pipe(take(1), delay(1000))
      .subscribe(() => {
        // Remove this line, just loading imitation
        mInfo.close();
      });
    if (agMap.lastOpen != null) {
      agMap.lastOpen.close();
      agMap.lastOpen = null;
    }
    this._showOverView(infoData);
  }

  mapClick(event: any, agMap) {
    if (agMap.lastOpen != null) {
      agMap.lastOpen.close();
    }
  }

  //
  // Helpers
  //
  getLabel(title, data) {
    const tmp = data.map((el) => el.id);
    const isSelected =
      this.selectedComparables.filter((el) => tmp.includes(el.id)).length > 0;
    return {
      color: '#000000',
      fontFamily: '',
      fontSize: '14px',
      fontWeight: 'bold',
      className: isSelected ? 'bg-op-red' : 'bg-op',
      text: title,
    };
  }

  isSelectedCom(com) {
    return this.selectedComparables.filter((el) => el.id == com.id).length > 0;
  }

  private selectComparables() {
    switch (this.assetClassType) {
      case 1:
        this.store$
          .pipe(
            select(selectAssetClassResidentialsInStore),
            takeUntil(this.OnDestroy)
          )
          .subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);
          });
          break;
      case 2:
        this.store$
          .pipe(
            select(selectAssetClassParkingInStore),
            takeUntil(this.OnDestroy)
          )
          .subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);
          });
          break;
      case 3:
        this.store$
          .pipe(select(selectAssetClassOfficesInStore))
          .pipe(takeUntil(this.OnDestroy))
          .subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);
          });
        break;
      case 5:
        this.store$
          .pipe(select(selectAssetClassRetailShopInStore))
          .pipe(takeUntil(this.OnDestroy))
          .subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);
          });
        break;
      case 7:
        this.store$
          .pipe(
            select(selectAssetClassWarehouseInStore),
            takeUntil(this.OnDestroy)
          ).subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);

          })
        break;
      case 11:
        this.store$
          .pipe(
            select(selectAssetClassRetailBuildingInStore),
            takeUntil(this.OnDestroy)
          ).subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);

          })
        break;
      case 17:
        this.store$
          .pipe(
            select(selectAssetClassHouseInStore),
            takeUntil(this.OnDestroy)
          ).subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);

          })
        break;
      case 13:
        this.store$
          .pipe(
            select(selectAssetClassLandInStore),
            takeUntil(this.OnDestroy)
          ).subscribe((res: QueryResultsModel) => {
            this.assetClassResult = res.items;
            this.markerData.clear();
            res.items.forEach((el) => {
              const key = el.locationData.latitude + '_' + el.locationData.longitude;
              if (this.markerData.has(key)) {
                const tmp = this.markerData.get(key);
                tmp.title = tmp.title + '/' + el.ref_num;
                tmp.data.push(el);
                this.markerData.set(key, tmp);
              } else {
                this.markerData.set(key, {
                  data: [el],
                  title: '' + el.ref_num,
                  lat: el.locationData.latitude,
                  lng: el.locationData.longitude,
                });
              }
            });
            this.markerData$.next(this.markerData);
            this.mapAction.next(false);

          })
        break;
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.latitude && position.coords.longitude) {
          this.centerLat = position.coords.latitude;
          this.centerLng = position.coords.longitude;
          this.zoom = 15;
        }
      });
    } else {
      alert('Position not available');
    }
  }

  private loadList() {
    this.filterModel.status = 1;
    const queryParams = new QueryParamsModel(
      this.filterModel,
      'asc',
      'id',
      1,
      1000
    );
    // Call request from server

    switch (this.assetClassType) {
      case 1:
        this.store$.dispatch(
          new AssetClassMapPageRequested({ page: queryParams })
        );
        break;
      case 2:
        this.store$.dispatch(
          new AssetClassParkingPageRequested({page: queryParams})
        );
        break;
      case 3:
        this.store$.dispatch(
          new AssetClassOfficeMapPageRequested({ page: queryParams })
        );
        break;
      case 5:
        this.store$.dispatch(
          new AssetClassRetailShopPageRequested({page: queryParams})
        );
        break;
      case 7: {
        this.store$.dispatch(
          new AssetClassWarehousePageRequested({page: queryParams})
        );
        break;
      }
      case 11: {
        this.store$.dispatch(
          new AssetClassRetailBuildingPageRequested({page: queryParams})
        );
        break;
      }
      case 17: {
        this.store$.dispatch(
          new AssetClassHousePageRequested({page: queryParams})
        );
        break;
      }
      case 13: {
        this.store$.dispatch(
          new AssetClassLandPageRequested({page: queryParams})
        )
        break;
      }
    }
  }

  private saveComData(_param, _tenures) {
    const _item = Object.assign({}, this.valData);
    _item.comparables = _param;
    _item.tenures = _tenures;
    this.store$.dispatch(new UpdateValuationData({ item: _item }));
  }
}
