// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {KeyPlaceActions, KeyPlaceActionTypes} from '../_actions/key-place.actions';
// Models
import {KeyPlaceModel, KeyplacesStatus} from '../_models/key-place.model';
import {QueryParamsModel} from '../../_base/crud';

export interface KeyPlacesState extends EntityState<KeyPlaceModel> {
    isAllKeyPlacesLoaded: boolean;
    queryRowsCount: number;
    queryResult: KeyPlaceModel[];
    previouslyCreatedKeyPlaceId: number;
    lastCreatedKeyPlaceId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    totalTrashed: number;
    totalAdminTrashed: number;
    showInitWaitingMessage: boolean;
    status: KeyplacesStatus;
}

export const adapter: EntityAdapter<KeyPlaceModel> = createEntityAdapter<KeyPlaceModel>();

export const initialKeyPlacesState: KeyPlacesState = adapter.getInitialState({
    isAllKeyPlacesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    previouslyCreatedKeyPlaceId: undefined,
    lastCreatedKeyPlaceId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    status: {
        total_lms: 0,
        total_different_locations: 0,
        total_used_in_vals: 0
    }
});

export function keyPlacesReducer(state = initialKeyPlacesState, action: KeyPlaceActions): KeyPlacesState {
    switch (action.type) {
        case KeyPlaceActionTypes.UpdatePreviouslyCreatedKeyPlaceId: {
            return {
                ...state,
                previouslyCreatedKeyPlaceId: state.lastCreatedKeyPlaceId
            }
        }
        case KeyPlaceActionTypes.KeyPlaceActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case KeyPlaceActionTypes.KeyPlaceOnServerCreated:
            return {
                ...state
            };
        case KeyPlaceActionTypes.KeyPlaceCreated:
            return adapter.addOne(action.payload.keyPlace, {
                ...state, 
                previouslyCreatedKeyPlaceId: state.lastCreatedKeyPlaceId,
                lastCreatedKeyPlaceId: action.payload.keyPlace.id
            });
        case KeyPlaceActionTypes.KeyPlaceUpdated:
            return adapter.updateOne(action.payload.partialKeyPlace, state);

        case KeyPlaceActionTypes.KeyPlaceDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });
        case KeyPlaceActionTypes.AllKeyPlacesLoaded:
            return adapter.addAll(action.payload.keyPlaces, {
                ...state, isAllKeyPlacesLoaded: true
            });
        case KeyPlaceActionTypes.KeyPlacesPageLoaded:
            return adapter.addMany(action.payload.keyPlaces, {
                ...initialKeyPlacesState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.keyPlaces,
                lastQuery: action.payload.page,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                showInitWaitingMessage: false,
                status: state.status
            });

        /************   trash functions     ************/

        case KeyPlaceActionTypes.KeyPlaceRestored:
            return adapter.addOne(action.payload.keyPlace, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case KeyPlaceActionTypes.KeyPlaceDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case KeyPlaceActionTypes.KeyPlaceAdminRestored:
            return adapter.addOne(action.payload.keyPlace, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case KeyPlaceActionTypes.KeyPlaceDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case KeyPlaceActionTypes.KeyPlaceTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case KeyPlaceActionTypes.KeyPlacesStatusLoaded: {
            return {
                ...state, status: action.payload.status
            }
        }

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
