import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassWarehouseModel } from "../_models/asset-class-warehouse.model";
import { AssetClassWarehouseState } from "../_reducers/asset-class-warehouse.reducers";

export const selectAssetClassWarehouseState = createFeatureSelector<AssetClassWarehouseState>('assetClassWarehouses');
export const selectAssetClassWarehousePageLoading = createSelector(selectAssetClassWarehouseState, state => state.listLoading);
export const selectAssetClassWarehouseActionLoading = createSelector(selectAssetClassWarehouseState, state => state.actionLoading);
export const selectAssetClassWarehouseShowInitWaitingMessage = createSelector(selectAssetClassWarehouseState, state => state.showInitWaitingMessage);
export const selectAssetClassWarehouseLastQuery = createSelector(selectAssetClassWarehouseState, state => state.lastQuery);
export const selectTrashedAssetClassWarehouseCount = createSelector(selectAssetClassWarehouseState, state => state.totalTrashed);
export const selectAdminTrashedAssetClassWarehouseCount = createSelector(selectAssetClassWarehouseState, state => state.totalAdminTrashed);
export const selectAssetClassWarehouseById = (id: number) => createSelector(selectAssetClassWarehouseState, state => state.entities[id])

export const selectAssetClassWarehouseInStore = createSelector(
    selectAssetClassWarehouseState,
    state => {
        const items: AssetClassWarehouseModel[] = [];
        each(state.entities, el => {
            items.push(el);
        });

        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassWarehouseModel[] = [];
        switch (state.lastQuery.sortField) {
            default: 
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                )
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
)

export const selectLastCreatedAssetClassWarehouseId = createSelector(
    selectAssetClassWarehouseState,
    state => {
        if (state.previouslyCreatedACId != state.lastCreatedAssetClassWarehouseId) {
            return state.lastCreatedAssetClassWarehouseId
        }
        return undefined
    } 
);