// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {BuildingTypeActions, BuildingTypeActionTypes} from '../_actions/building-type.actions';
// Models
import {BuildingType} from '../_models/building-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface BuildingTypesState extends EntityState<BuildingType> {
    isAllBuildingTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: BuildingType[];
    lastCreatedBuildingTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BuildingType> = createEntityAdapter<BuildingType>();

export const initialBuildingTypesState: BuildingTypesState = adapter.getInitialState({
    isAllBuildingTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedBuildingTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function buildingTypesReducer(state = initialBuildingTypesState, action: BuildingTypeActions): BuildingTypesState {
    switch (action.type) {
        case BuildingTypeActionTypes.BuildingTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBuildingTypeId: undefined
            };
        case BuildingTypeActionTypes.BuildingTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case BuildingTypeActionTypes.BuildingTypeOnServerCreated:
            return {
                ...state
            };
        case BuildingTypeActionTypes.BuildingTypeCreated:
            return adapter.addOne(action.payload.buildingType, {
                ...state, lastCreatedBuildingTypeId: action.payload.buildingType.id
            });
        case BuildingTypeActionTypes.BuildingTypeUpdated:
            return adapter.updateOne(action.payload.partialBuildingType, state);
        case BuildingTypeActionTypes.AllBuildingTypesLoaded:
            return adapter.addAll(action.payload.buildingTypes, {
                ...state, isAllBuildingTypesLoaded: true
            });
        case BuildingTypeActionTypes.BuildingTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case BuildingTypeActionTypes.BuildingTypesPageLoaded:
            return adapter.addMany(action.payload.buildingTypes, {
                ...initialBuildingTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case BuildingTypeActionTypes.BuildingTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case BuildingTypeActionTypes.BuildingTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case BuildingTypeActionTypes.BuildingTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case BuildingTypeActionTypes.BuildingTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case BuildingTypeActionTypes.BuildingTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case BuildingTypeActionTypes.BuildingTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
