export interface InternalAspectData {
    id: number,
    roof_structure_id: number;
    roof_structure_comment: string;

    ceiling_id: number;
    ceiling_comment: string;

    walls_and_partition_id: number;
    walls_and_partition_comment: string;

    floor_type_id: number;
    floor_type_comment: string;

    fireplaces_chimney_breast_id: number;
    fireplaces_chimney_breast_comment: string;

    bathroom_fitting_ids: string;
    builtin_fitting_ids: string;

    woodwork: string;
    woodwork_comment: string;


    height: string;
    height_comment: string;

    cubic_content: string;
    cubic_content_comment: string;

    storage_type_id: number;
    storage_type_comment: string;

    shop_front_type_id: number;
    shop_front_type_comment: string;

    has_other: boolean;
    other_aspects: string;

    has_automation: boolean;
    automations: number[];

    above_floors: number,
    below_floors: number,
    state_of_repair_id: number,
    state_of_repair_name?: string,
    building_facilities_ids: string; 

    windows_id: number;
    windows_comment: string;
}

export function newInternalAspectData(): InternalAspectData {
    return {
        id: null,
        roof_structure_id: null,
        roof_structure_comment: null,

        ceiling_id: null,
        ceiling_comment: null,

        walls_and_partition_id: null,
        walls_and_partition_comment: null,

        floor_type_id: null,
        floor_type_comment: null,

        fireplaces_chimney_breast_id: null,
        fireplaces_chimney_breast_comment: null,

        bathroom_fitting_ids: null,
        builtin_fitting_ids: null,

        woodwork: null,
        woodwork_comment: null,

        height: null,
        height_comment: null,

        cubic_content: null,
        cubic_content_comment: null,

        storage_type_id: null,
        storage_type_comment: null,

        shop_front_type_id: null,
        shop_front_type_comment: null,

        has_other: false,
        other_aspects: null,

        has_automation: false,
        automations: [],

        above_floors: null,
        below_floors: null,
        state_of_repair_id: null,
        building_facilities_ids: null ,

        windows_id: null,
        windows_comment: null
    }
}