import {AssetClassType} from '../_models/asset-class-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassTypesState} from '../_reducers/asset-class-type.reducers';
import * as fromAssetClassType from '../_reducers/asset-class-type.reducers';
import {each} from 'lodash';

export const selectAssetClassTypesState = createFeatureSelector<AssetClassTypesState>('assetClassTypes');

export const selectAssetClassTypeById = (assetClassTypeId: number) => createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => assetClassTypesState.entities[assetClassTypeId]
);

export const selectAllAssetClassTypes = createSelector(
    selectAssetClassTypesState,
    fromAssetClassType.selectAll
);

export const selectAllAssetClassTypesIds = createSelector(
    selectAssetClassTypesState,
    fromAssetClassType.selectIds
);

export const allAssetClassTypesLoaded = createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => assetClassTypesState.isAllAssetClassTypesLoaded
);


export const selectAssetClassTypesPageLoading = createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => assetClassTypesState.listLoading
);

export const selectAssetClassTypesActionLoading = createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => assetClassTypesState.actionLoading
);

export const selectLastCreatedAssetClassTypeId = createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => assetClassTypesState.lastCreatedAssetClassTypeId
);

export const selectAssetClassTypesShowInitWaitingMessage = createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => assetClassTypesState.showInitWaitingMessage
);

export const selectTrashedAssetClassTypeCount = createSelector(
    selectAssetClassTypesState,
    (assetClassTypesState) => assetClassTypesState.totalTrashed
);

export const selectAdminTrashedAssetClassTypeCount = createSelector(
    selectAssetClassTypesState,
    (assetClassTypesState) => assetClassTypesState.totalAdminTrashed
);


export const selectAssetClassTypesQueryResult = createSelector(
    selectAssetClassTypesState,
    assetClassTypesState => {
        const items: AssetClassType[] = [];
        each(assetClassTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AssetClassType[] = httpExtension.sortArray(items, assetClassTypesState.lastQuery.sortField, assetClassTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, assetClassTypesState.totalCount, '', assetClassTypesState.totalTrashed);
    }
);
