// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {StandardMeasurementsService} from '../_services/standard-measurement.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allStandardMeasurementsLoaded} from '../_selectors/standard-measurement.selectors';
// Actions
import {
    AllStandardMeasurementsLoaded,
    AllStandardMeasurementsRequested,
    StandardMeasurementActionTypes,
    StandardMeasurementsPageRequested,
    StandardMeasurementsPageLoaded,
    StandardMeasurementUpdated,
    StandardMeasurementsPageToggleLoading,
    StandardMeasurementDeleted,
    StandardMeasurementOnServerCreated,
    StandardMeasurementCreated,
    StandardMeasurementAdminRestored,
    StandardMeasurementDeletedFromAdminTrash,
    StandardMeasurementDeletedFromTrash,
    StandardMeasurementOnServerAdminRestored,
    StandardMeasurementOnServerRestored,
    StandardMeasurementRestored,
    StandardMeasurementTrashFlushed,
    StandardMeasurementsActionToggleLoading
} from '../_actions/standard-measurement.actions';

@Injectable()
export class StandardMeasurementEffects {
    showPageLoadingDispatcher = new StandardMeasurementsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new StandardMeasurementsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new StandardMeasurementsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllStandardMeasurements$ = this.actions$
        .pipe(
            ofType<AllStandardMeasurementsRequested>(StandardMeasurementActionTypes.AllStandardMeasurementsRequested),
            withLatestFrom(this.store.pipe(select(allStandardMeasurementsLoaded))),
            filter(([action, isAllStandardMeasurementsLoaded]) => !isAllStandardMeasurementsLoaded),
            mergeMap(() => this.service.getAllStandardMeasurements()),
            map(res => {
                return new AllStandardMeasurementsLoaded({standardMeasurements: res.data});
            })
        );

    @Effect()
    loadStandardMeasurementsPage$ = this.actions$
        .pipe(
            ofType<StandardMeasurementsPageRequested>(StandardMeasurementActionTypes.StandardMeasurementsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findStandardMeasurements(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new StandardMeasurementsPageLoaded({
                    standardMeasurements: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementDeleted>(StandardMeasurementActionTypes.StandardMeasurementDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteStandardMeasurement(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementUpdated>(StandardMeasurementActionTypes.StandardMeasurementUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateStandardMeasurement(payload.standardMeasurement);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementOnServerCreated>(StandardMeasurementActionTypes.StandardMeasurementOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createStandardMeasurement(payload.standardMeasurement).pipe(
                    tap(res => {
                        this.store.dispatch(new StandardMeasurementCreated({standardMeasurement: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushStandardMeasurementTrash$ = this.actions$
        .pipe(
            ofType<StandardMeasurementTrashFlushed>(StandardMeasurementActionTypes.StandardMeasurementTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementOnServerRestored>(StandardMeasurementActionTypes.StandardMeasurementOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new StandardMeasurementRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementOnServerAdminRestored>(StandardMeasurementActionTypes.StandardMeasurementOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new StandardMeasurementAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementDeletedFromTrash>(StandardMeasurementActionTypes.StandardMeasurementDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashStandardMeasurement$ = this.actions$
        .pipe(
            ofType<StandardMeasurementDeletedFromAdminTrash>(StandardMeasurementActionTypes.StandardMeasurementDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: StandardMeasurementsService, private store: Store<AppState>) {
    }
}
