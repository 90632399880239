import {CoordinateReferenceSystem} from '../_models/coordinate-reference-system.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {CoordinateReferenceSystemsState} from '../_reducers/coordinate-reference-system.reducers';
import * as fromCoordinateReferenceSystem from '../_reducers/coordinate-reference-system.reducers';
import {each} from 'lodash';

export const selectCoordinateReferenceSystemsState = createFeatureSelector<CoordinateReferenceSystemsState>('coordinateReferenceSystems');

export const selectCoordinateReferenceSystemById = (coordinateReferenceSystemId: number) => createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => coordinateReferenceSystemsState.entities[coordinateReferenceSystemId]
);

export const selectAllCoordinateReferenceSystems = createSelector(
    selectCoordinateReferenceSystemsState,
    fromCoordinateReferenceSystem.selectAll
);

export const selectAllCoordinateReferenceSystemsIds = createSelector(
    selectCoordinateReferenceSystemsState,
    fromCoordinateReferenceSystem.selectIds
);

export const allCoordinateReferenceSystemsLoaded = createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => coordinateReferenceSystemsState.isAllCoordinateReferenceSystemsLoaded
);


export const selectCoordinateReferenceSystemsPageLoading = createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => coordinateReferenceSystemsState.listLoading
);

export const selectCoordinateReferenceSystemsActionLoading = createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => coordinateReferenceSystemsState.actionLoading
);

export const selectLastCreatedCoordinateReferenceSystemId = createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => coordinateReferenceSystemsState.lastCreatedCoordinateReferenceSystemId
);

export const selectCoordinateReferenceSystemsShowInitWaitingMessage = createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => coordinateReferenceSystemsState.showInitWaitingMessage
);

export const selectTrashedCoordinateReferenceSystemCount = createSelector(
    selectCoordinateReferenceSystemsState,
    (coordinateReferenceSystemsState) => coordinateReferenceSystemsState.totalTrashed
);

export const selectAdminTrashedCoordinateReferenceSystemCount = createSelector(
    selectCoordinateReferenceSystemsState,
    (coordinateReferenceSystemsState) => coordinateReferenceSystemsState.totalAdminTrashed
);


export const selectCoordinateReferenceSystemQueryResult = createSelector(
    selectCoordinateReferenceSystemsState,
    coordinateReferenceSystemsState => {
        const items: CoordinateReferenceSystem[] = [];
        each(coordinateReferenceSystemsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CoordinateReferenceSystem[] = httpExtension.sortArray(items, coordinateReferenceSystemsState.lastQuery.sortField, coordinateReferenceSystemsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, coordinateReferenceSystemsState.totalCount, '', coordinateReferenceSystemsState.totalTrashed);
    }
);
