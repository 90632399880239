export type VParcelIdentification = {
    landParcelName: string
    coordinateReferencySystem: string,
    points: {latitude: number, longitude: number}[],
    planningStatus: string,
    qualityOfSoil: string,
    environmentalConsideration: string,
    otherConsideration: string,
    landAreas: any[]
}

export type VParcelIdentificationRemote = {
    land_parcel_name: string,
    coordinate_ref_system: string,
    points: {lat: string, lng: string}[],
    planning_status: string,
    qos_degradation: string,
    env_consideration: string,
    other_consideration: string,
    land_areas: any[]
}

export function mapVParcelIdentificationRemoteToDomain(remote: VParcelIdentificationRemote): VParcelIdentification {
    return {
        landParcelName: remote.land_parcel_name,
        coordinateReferencySystem: remote.coordinate_ref_system,
        points: remote.points
                    .map(p => ({lat: Number(p.lat), lng: Number(p.lng)}))
                    .filter(p => p.lat != null && p.lng != null)
                    .map(p => ({latitude: p.lat, longitude: p.lng})),
        planningStatus: remote.planning_status,
        qualityOfSoil: remote.qos_degradation,
        environmentalConsideration: remote.env_consideration,
        otherConsideration: remote.other_consideration,
        landAreas: remote.land_areas
    }
}