import { Injectable } from "@angular/core";
import { IAgencyCriterion } from "../../admin/_services/agency-criteria.service";
import { AssetClassModel, ValuationModel } from "../../asset_class";
import { AssetClassBuildingInformation } from "../../comparable";
import { CriterionCategories } from "../_models/criterion-categories.model";
import { CriterionType } from "../_models/criterion-type.model";
import { CriterionModel } from "../_models/criterion.model";
import { ConsiderationCriterionService } from "./consideration-criterion.service";

@Injectable()
export class CriterionService {
    _garageRange: {value: number, label: string}[] = [];

    constructor(
        private considerationCritService: ConsiderationCriterionService
    ) {
        this._garageRange = setGarangeRange();
    }

    createCriterions(
        assetClass: AssetClassModel,
        criteria: IAgencyCriterion[],
        valuation: ValuationModel
    ): CriterionModel[] {
        let criterions: CriterionModel[];

        const acCriteria = criteria.filter(c => c.ac_type_id === assetClass.type_id);
        criterions = this._createCriteria(assetClass, acCriteria, valuation);
        return criterions;
    }

    insertCriterionsFromTemplate(
        assetClass: AssetClassModel,
        comparables: any[],
        defaultTemplateCriterions: any[],
        customTemplateCriterions: any[],
        allCriterions: CriterionModel[]
    ): CriterionModel[] {
        let criterions: CriterionModel[] = [];
        const considCrit = allCriterions.find(c => c.id === 1);
        const activeCrits: CriterionModel[] = allCriterions
            .filter(c => c.type === CriterionType.Other)
            .map(c => {
                const isActive = defaultTemplateCriterions.findIndex(crit => crit.crit_id == c.id) > -1;
                return {
                    ...c,
                    active: isActive
                }
            })
            .sort((a, b) => a.id - b.id);
        const id = activeCrits[activeCrits.length - 1].id;
        const customCrits: CriterionModel[] = customTemplateCriterions.map((crit, i) => {
            const _inputs: {[id: number]: any} = {};
            comparables.forEach(com => {
                _inputs[com.id] = 'Unknown';
            })
            const c = new CriterionModel();
            c.id = id + i + 1;
            c.name = crit.name;
            c.category = crit.category;
            c.comValues = _inputs;
            c.categoryName = crit.categoryName;
            c.tpValue = null;
            c.active = true;
            c.criterion = 'custom';
            c.isChanged = false;
            c.type = CriterionType.Custom;
            c.canRemove = true;
            return c;
        })
        return [considCrit, ...activeCrits, ...customCrits];
    }

    _createCriteria(tp: AssetClassModel, criteria: IAgencyCriterion[], valuation: ValuationModel): CriterionModel[] {
        const criterions: CriterionModel[] = [];
        const considerationCriterion = this._createConsiderationCriterions(tp, valuation);
        const otherCriterions = this._createOtherCriteria(tp, criteria);
        criterions.push(considerationCriterion, ...otherCriterions);
        return criterions;
    }

    _createOtherCriteria(tp: AssetClassModel, criteria: IAgencyCriterion[]): CriterionModel[] {
        const criterions: CriterionModel[] = [];
        criteria.forEach(c => {
            const criterion = new CriterionModel();
            criterion.id = c.crit_id;
            criterion.name = c.name;
            criterion.category = c.category_id,
            criterion.categoryName = c.category;
            criterion.type = CriterionType.Other;
            criterion.criterion = c.criterion_str;
            criterion.active = c.display_by_default === 1 ? true : false;
            criterion.comValues = {};
            criterion.tpValue = this._getOtherCriterionValue(tp.details, criterion, tp.type_id);
            criterion.isChanged = false;
            criterion.canRemove = c.user_can_remove ? true : false;
            criterion.order_num = c.order_num;
            criterions.push(criterion);
        })
        return criterions;
    }

    addComparables(coms: any[], tenures: any[], criterions: CriterionModel[], targetProperty: AssetClassModel, valuation: ValuationModel): CriterionModel[] {
        let _mainCriterions: CriterionModel[] = this._copyCriterions(criterions);
        coms.forEach(com => {
            const t = tenures.find(t => t.comId == com.id);
            const res = this.addComparable(com, t ? t.selectedTenure : null, _mainCriterions, targetProperty, valuation);
            _mainCriterions = res;
        })
        return _mainCriterions
    }

    _addComs(coms: any[], criterion: CriterionModel, type_id): CriterionModel {
        const _crit = this._copyCriterion(criterion);
        coms.forEach(com => {
            switch (_crit.type) {
                case CriterionType.Other:
                    const value = this._getOtherCriterionValue(com, criterion, type_id);
                    _crit.comValues[com.id] = value;
                    break;
            }
        });
        return _crit;
    }

    addComparable(
        com: any, 
        selectedTenure: number|null,
        criterions: CriterionModel[],
        targetProperty: AssetClassModel,
        valuation: ValuationModel
    ): CriterionModel[] {
        const _mainCriterions: CriterionModel[] = this._copyCriterions(criterions);
        _mainCriterions.forEach(criterion => {
            switch(criterion.type) {
                case CriterionType.Size: {
                    // let val = null;
                    // if (criterion.name) {
                    //     const measurement = sizeData.standardMeasurements.find(m => m.name === criterion.name);
                    //     val = measurement ? this._getSizeCriterionValue(sizeData.sizes, measurement, sizeData.unitAreaMeasurement) : null;
                    // }
                    // criterion.comValues.push(val);
                    break;
                }
                case CriterionType.Consideration:
                    const [val, process] = this.considerationCritService.addComparable(
                        valuation.tenure_id, 
                        selectedTenure, 
                        com.tenures, 
                        targetProperty.country_currency
                    )
                    // const val = Math.random() * 1000000;
                    criterion.comValues[com.id] = val;
                    criterion.comConsideration[com.id] = {
                        process,
                        methods: {
                            mainMethod: undefined,
                            subMethod: undefined,
                        },
                        rateValues: {
                            capRate: undefined,
                            capRateJustification: null,
                            targetRate: undefined,
                            targetRateJustification: null,
                        },
                        cfmRateValues: {
                            targetRate: undefined,
                            targetRateJustification: null,
                            growthRate: undefined,
                            growthRateJustification: null,
                            effectiveRentJustification: null,
                        },
                        selected: {
                            main: undefined,
                            index: undefined,
                        },
                        ary: undefined,
                    };
                    break;
                case CriterionType.Other: {
                    const value = this._getOtherCriterionValue(com, criterion, targetProperty.type_id);
                    criterion.comValues[com.id] = value;
                    break;
                }
                case CriterionType.Custom:
                    criterion.comValues[com.id] = null;
                    break;
                default:
                    break;
            }
        })
        return _mainCriterions
    }

    removeComparable(comID: number, criterions: CriterionModel[]): CriterionModel[] {
        const _criterions: CriterionModel[] = this._copyCriterions(criterions)
        
        _criterions.forEach(criterion => {
            delete criterion.comValues[comID];
            delete criterion.comConsideration[comID];
            // criterion.comValues.splice(comIndex, 1);
        })
       
        return _criterions
    }

    addCriterion(criterion: CriterionModel, id: number) {
        const c = this._copyCriterion(criterion);
        c.id = id + 1;
        return c;
    }

    resetCriterion(criterion: CriterionModel, comparables: any[], ac: AssetClassModel): CriterionModel {
        const _criterion = this._copyCriterion(criterion);
        comparables.forEach((com, i) => {
            _criterion.comValues[com.id] = this._getOtherCriterionValue(com, _criterion, ac.type_id);
        });
        return _criterion;
    }

    /** Consideration Criterions */
    _createConsiderationCriterions(ac: AssetClassModel, valuation: ValuationModel): CriterionModel {
        const criterion = new CriterionModel();
        criterion.id = 1;
        criterion.name = valuation.tenure_id === 1 ? 'Leasehold' : 'Freehold';
        criterion.category = CriterionCategories.Other;
        const title = valuation.tenure_id === 1 ? 'Rental Value - per year' : 'Capital Value'
        criterion.categoryName = `Consideration (${ac.country_currency}) (${title})`;
        criterion.criterion = 'consideration';
        criterion.type = CriterionType.Consideration;
        criterion.active = true;
        criterion.comValues = {};
        criterion.comConsideration = {};
        criterion.tpValue = undefined;
        criterion.isChanged = false;
        return criterion;
    }

    /** Other Criterions */
    _getOtherCriterionValue(com: any, criterion: CriterionModel, ac_type_id: number): any {
        switch (criterion.category) {
            case CriterionCategories.Location: {
                return getNestedValue(com, criterion.criterion);
            }
            case CriterionCategories.Property:
                return this._getResidentialCharacteristics(com, criterion);
            case CriterionCategories.Building:
                return this._getBuildingCriterions(com, criterion)
            default:
                return getNestedValue(com, criterion.criterion);
        }
    }

    _getBuildingCriterions(info: AssetClassBuildingInformation, criterion: CriterionModel): any {
        if (criterion.criterion === 'not_implemented') {
            return null;
        } else if(criterion.criterion === 'building_indoor_garage_places' || criterion.criterion === 'building_outdoor_garage_places') {
            let val = info[criterion.criterion];
            const _val: number = Number(val);
            return getLabel(this._garageRange, _val);
        } else {
            // const val = info[criterion.criterion];
            // return val === '' || val === null ? 'Unknown' : val
            // return info[criterion.criterion];
            return getNestedValue(info, criterion.criterion);
        }
    }

    _getResidentialCharacteristics(com: any, criterion: CriterionModel): any {
        if (criterion.criterion === 'not_implemented') {
            return null;
        } else if (criterion.criterion === 'room' || criterion.criterion === 'bedroom' || criterion.criterion === 'bathroom'
            || criterion.criterion === 'indoor_garage_places' || criterion.criterion === 'outdoor_garage_places') {
                let val = com[criterion.criterion];
                val = Number(val);
                if (val === -1) {
                    return 'Unknown'
                } else {
                    return val;
                }
            }
        else if (criterion.criterion === 'floor_type_name') {
            return com.floor_type_name + (com.flooring_type_comment 
                ? '. ' + com.flooring_type_comment: '');
        } else if (criterion.criterion === 'window_type_name') {
            return com.window_type_name + (com.windows_type_comment
                ? '. ' + com.windows_type_comment : '');
        } else if (criterion.criterion === 'handover_standard_name') {
            return com.handover_standard_name + '. ' + com.handover_standard_description;
        } else {
            return getNestedValue(com, criterion.criterion);
        }
    }

    _copyCriterions(criterions: CriterionModel[]): CriterionModel[] {
        const _criterions: CriterionModel[] = [];
        criterions.forEach(criterion => {
            const _tmp = Object.assign({}, criterion) as CriterionModel;
            const _val = Object.assign({}, _tmp.comValues);
            const _consid = Object.assign({}, _tmp.comConsideration);
            _tmp.comValues = _val;
            _tmp.comConsideration = _consid;
            _criterions.push(_tmp);
        })
        return _criterions;
    }

    _copyCriterion(criterion: CriterionModel): CriterionModel {
        const _tmp = Object.assign({}, criterion) as CriterionModel;
        const _val = Object.assign({}, _tmp.comValues);
        const _consid = Object.assign({}, _tmp.comConsideration);
        _tmp.comValues = _val;
        _tmp.comConsideration = _consid;
        return _tmp
    }
}

function setGarangeRange(): {value: number, label: string}[] {
    const ranges: { value: number, label: string }[] = [];
    ranges.push({value: -1, label: 'Unknown'});
    ranges.push({value: 0, label: '0'});
    ranges.push({value: 1, label: '1 to 10'});
    ranges.push({value: 2, label: '11 to 25'});

    let lowerBound = 25;
    let upperBound = 50;
    let i = 3;
    while (upperBound <= 200) {
        ranges.push({value: i, label: `${lowerBound + 1} to ${upperBound}`});
        lowerBound = upperBound;
        upperBound = 25 + lowerBound;
        i++;
    }
    ranges.push({value: i, label: '>200'});

    return ranges;
}

function getLabel(ranges: {value: number, label: string}[], value: number): string {
    const _r = ranges.find(r => r.value === value);
    return _r ? _r.label: '';
}

function getNestedValue(com: any, path: string) {
    const paths = path.split('.');
    if (paths.length == 1) {
        const val = com[paths[0]];
        return val === '' || val === null ? 'Unknown' : val;
    }

    const inner = com[paths[0]];
    if (inner) {
        if (paths[0] == 'externalAspectData' || paths[0] == 'internalAspectData' || paths[0] == 'buildingInfo' || paths[0] == 'grounds') {
            const splits = paths[1].split('|');
            if (splits.length == 2) {
                let name = null;
                let comment = null;
                if (splits[0] == 'indoor_garage' || splits[0] == 'outdoor_garage') {
                    name = getGarageRange(inner[splits[0]]) 
                    comment = inner[splits[1]];
                } else {
                    name = inner[splits[0]];
                    comment = inner[splits[1]];
                }
                if (name === null) {
                    return 'Unknown';
                }
                const val = name + '. ' + (comment == '' || comment == null ? '' : comment);
                return val;
            }
        } else {
            const splits = paths[1].split('?');
            if (splits.length == 2) {
                const n = inner[splits[0]];
                if (n && n.trim() != '') {
                    return n;
                }
                const n2 = inner[splits[1]];
                if (n2) {
                    return n2;
                }
            }
        }
        const val = inner[paths[1]];
        return val === '' || val === null ? 'Unknown' : val;
    } else {
        if (paths[0] == 'building') {
            const splits = paths[1].split('|');
            if (splits.length == 2) {
                const name = com[splits[0]];
                const comment = com[splits[1]];
                if (name === null) {
                    return 'Unknown';
                }
                const val = name + '. ' + (comment == '' || comment == null ? '' : comment);
                return val;
            }
        }
        if (paths[0] == 'building|buildingInfo') {
            const splits = paths[0].split('|')
            const inner1 = com[splits[0]];
            const inner2 = com[splits[1]];
            if (inner1) {
                const splits = paths[1].split('|');
                if (splits.length == 2) {
                    const name = inner1[splits[0]];
                    const comment = inner1[splits[1]];
                    if (name === null) {
                        return 'Unknown';
                    }
                    const val = name + '. ' + (comment == '' || comment == null ? '' : comment);
                    return val;
                }
                const val = inner1[paths[1]];
                return val === '' || val === null ? 'Unknown' : val;
            } else if (inner2) {
                const splits = paths[1].split('|');
                if (splits.length == 2) {
                    const name = inner2[splits[0]];
                    const comment = inner2[splits[1]];
                    if (name === null) {
                        return 'Unknown';
                    }
                    const val = name + '. ' + (comment == '' || comment == null ? '' : comment);
                    return val;
                }
                const val = inner2[paths[1]];
                return val === '' || val === null ? 'Unknown' : val;
            }

        }
        if (paths[0] == 'aboutProperty') {
            const splits = paths[1].split('?');
            if (splits.length == 2) {
                const n = com[splits[0]];
                if (n && n.trim() != '') {
                    return n;
                }
                const n2 = com[splits[1]];
                if (n2) {
                    return n2;
                }
            }
        }
    }
    const val = com[paths[1]];
    return val === '' || val === null ? 'Unknown' : val;
}

function garageRange() {
    const ranges: { value: number, label: string }[] = [];
    ranges.push({ value: 0, label: '0' });
    ranges.push({ value: 1, label: '1 to 10' });
    ranges.push({ value: 2, label: '11 to 25' });

    let lowerBound = 25;
    let upperBound = 50;
    let i = 3;
    while (upperBound <= 200) {
        ranges.push({ value: i, label: `${lowerBound + 1} to ${upperBound}` });
        lowerBound = upperBound;
        upperBound = 25 + lowerBound;
        i++;
    }
    ranges.push({ value: i, label: '>200' });

    return ranges;
}

function getGarageRange(_value: number) {
    const ranges = garageRange()
    const r = ranges.find(({value}) => value == _value)
    if (!r) {
        return null
    }
    return r.label
}