// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {PropertyType} from '../_models/property-type.model';

export enum PropertyTypeActionTypes {

    AllPropertyTypesRequested = '[PropertyType Home Page] All PropertyType Requested',
    AllPropertyTypesLoaded = '[PropertyType API] All PropertyType Loaded',

    PropertyTypeOnServerCreated = '[Edit PropertyType Dialog] PropertyType On Server Created',
    PropertyTypeCreated = '[Edit PropertyType Dialog] PropertyType Created',
    PropertyTypeUpdated = '[Edit PropertyType Dialog] PropertyType Updated',
    PropertyTypeDeleted = '[PropertyType List Page] PropertyType Deleted',

    // trash system

    PropertyTypeOnServerRestored = '[PropertyType Trash] PropertyType On Server Restored',
    PropertyTypeRestored = '[PropertyType Trash] PropertyType Restored',

    PropertyTypeOnServerAdminRestored = '[PropertyType Admin Trash] PropertyType On Server Restored',
    PropertyTypeAdminRestored = '[PropertyType Admin Trash] PropertyType Restored',

    PropertyTypeDeletedFromTrash = '[PropertyType Trash] PropertyType deleted',
    PropertyTypeDeletedFromAdminTrash = '[PropertyType Admin Trash] PropertyType deleted',

    PropertyTypeTrash = 'PropertyType Trashed',
    PropertyTypeTrashFlushed = 'PropertyType Trash Flushed',

    // Page system

    PropertyTypesPageRequested = '[PropertyType List Page] PropertyType Page Requested',
    PropertyTypesPageLoaded = '[PropertyType API] PropertyType Page Loaded',
    PropertyTypesPageCancelled = '[PropertyType API] PropertyType Page Cancelled',

    PropertyTypesPageToggleLoading = '[PropertyType page] PropertyType Page Toggle Loading',
    PropertyTypeActionToggleLoading = '[PropertyType] PropertyType Action Toggle Loading'
}

export class PropertyTypeOnServerCreated implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeOnServerCreated;

    constructor(public payload: { item: PropertyType }) {
    }
}

export class PropertyTypeCreated implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeCreated;

    constructor(public payload: { item: PropertyType }) {
    }
}

export class PropertyTypeUpdated implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeUpdated;

    constructor(public payload: {
        partialItem: Update<PropertyType>,
        item: PropertyType
    }) {
    }
}

export class PropertyTypeDeleted implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class PropertyTypesPageRequested implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class PropertyTypesPageLoaded implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypesPageLoaded;

    constructor(public payload: { items: PropertyType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class PropertyTypesPageCancelled implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypesPageCancelled;
}

export class AllPropertyTypesRequested implements Action {
    readonly type = PropertyTypeActionTypes.AllPropertyTypesRequested;
}

export class AllPropertyTypesLoaded implements Action {
    readonly type = PropertyTypeActionTypes.AllPropertyTypesLoaded;

    constructor(public payload: { items: PropertyType[] }) {
    }
}

export class PropertyTypesPageToggleLoading implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PropertyTypeActionToggleLoading implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class PropertyTypeDeletedFromAdminTrash implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PropertyTypeDeletedFromTrash implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PropertyTypeOnServerRestored implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PropertyTypeRestored implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeRestored;

    constructor(public payload: { item: PropertyType }) {
    }
}

export class PropertyTypeOnServerAdminRestored implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PropertyTypeAdminRestored implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeAdminRestored;

    constructor(public payload: { item: PropertyType }) {
    }
}

export class PropertyTypeTrashFlushed implements Action {
    readonly type = PropertyTypeActionTypes.PropertyTypeTrashFlushed;

    constructor() {
    }
}


export type PropertyTypeActions = PropertyTypeCreated
    | PropertyTypeUpdated
    | PropertyTypeDeleted
    | PropertyTypesPageRequested
    | PropertyTypesPageLoaded
    | PropertyTypesPageCancelled
    | AllPropertyTypesLoaded
    | AllPropertyTypesRequested
    | PropertyTypeOnServerCreated
    | PropertyTypeDeletedFromAdminTrash
    | PropertyTypeDeletedFromTrash
    | PropertyTypeOnServerRestored
    | PropertyTypeRestored
    | PropertyTypeOnServerAdminRestored
    | PropertyTypeAdminRestored
    | PropertyTypeTrashFlushed
    | PropertyTypesPageToggleLoading
    | PropertyTypeActionToggleLoading;
