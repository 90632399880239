import * as _ from "lodash"

export interface IRow {
  header: string,
  cells: Cells,
  propertySubType: string
}

export type Cell = {
  kind: 'simple',
  value: string
} | {
  kind: 'click',
  value: string,
  onClick: () => void
} | {
  kind: 'unknown'
} | {
  kind: 'location',
  value: string
} | {
  kind: 'picture',
  pictures: {
    name: string,
    path: string,
    url: string
  }[],
  onClick: (index: number) => void
} | {
  kind: 'na'
} | {
  kind: 'checklist',
  value: boolean
} | {
  kind: 'not available'
} | {
  kind: 'unknown-with-value',
  value: string
}

export type Cells = { [key: string]: Cell }

export type Row = {
  header: string,
  cells: Cells,
  type: 'simple' | 'countable'
}

export function mergeCells(c1: Cells, c2: Cells): Cells {
  return {
    ...c1,
    ...c2
  }
}

export function mergeRRows(rrows: IRow[][]): IRow[] {
  return rrows.reduce((acc, rows) => {
    rows.forEach((row) => {
      const item = acc.find(el => el.header == row.header)
      if (!item) {
        acc.push(row)
      } else {
        item.cells = mergeCells(item.cells, row.cells)
      }
    })
    return acc
  }, [])
}

export function simpleCellCreator(value: string | number): Cell {
  if (value == null || value == undefined) {
    return {
      kind: 'unknown'
    }
  }
  if (typeof value == 'string') {
    if (value.trim().length == 0 || value.trim() == 'Unknown') {
      return {
        kind: 'unknown'
      }
    }
    return {
      kind: 'simple',
      value
    }
  }
  return {
    kind: 'simple',
    value: value.toString()
  }
}

export function checklistCellCreator(value: boolean): Cell {
  return {
    kind: 'checklist',
    value
  }
}

export function cellsAreCountable(cells: Cells): boolean {
  let countable = true
  Object.values(cells).forEach(v => {
    countable = countable && !(v.kind == 'unknown' || v.kind == 'na')
  })
  return countable
}

export function mergeRows(rows: IRow[], refNums: string[]): Row[] {
  return rows.map((row) => {
    let cells: Cells = {}
    refNums.forEach((refNum) => {
      if (!row.cells[refNum]) {
        cells[refNum] = { kind: 'na' }
      }
    })
    const header: string = Object.keys(cells).length == 0 ? row.header : `${row.header} (${row.propertySubType})`
    const mergedCells = mergeCells(row.cells, cells)
    const isCountable = cellsAreCountable(mergedCells)
    return {
      header: header,
      cells: mergedCells,
      type: isCountable ? 'countable' : 'simple'
    }
  })
}

export function mergeSizeRows(rows: IRow[], refNums: string[]): Row[] {
  const onlyOneType = Object.keys(_.groupBy(rows, (row) => row.propertySubType)).length == 1
  return rows.map(row => {
    let cells: Cells = {}
    refNums.forEach((refNum) => {
      if (!row.cells[refNum]) {
        cells[refNum] = { kind: 'not available' }
      }
    })
    const header: string = onlyOneType
      ? row.header
      : Object.keys(cells).length == 0 ? row.header : `${row.header} (${row.propertySubType})`
    const mergedCells = mergeCells(row.cells, cells)
    const isCountable = cellsAreCountable(mergedCells)
    return {
      header: header,
      cells: mergedCells,
      type: isCountable ? 'countable' : 'simple'
    }
  })
}