export class NewCoiModel {
	id: number;
	toe_id: number;
	coi_num: number;
	has_prev_knowledge: number;
	has_financial_interest: number;

	previous_work: number;
	previous_work_description: string;
	previous_work_description_b: string;
	previous_work_type_of_conflict_id: number;
	previous_work_appropriate_parties: string;
	previous_work_reason_continue: string; 
	previous_work_coi: number;
	previous_work_can_edit: number; 

	personal_work: number
	personal_work_description: string;
	personal_work_description_b: string;
	personal_work_type_of_conflict_id: number;
	personal_work_appropriate_parties: string;
	personal_work_reason_continue: string;
	personal_work_coi: number; 
	personal_work_can_edit: number;

	employees_knows_each_other: number;
	employees_knows_each_other_description: string;
	employees_knows_each_other_description_b: string;
	employees_knows_each_other_type_of_conflict_id: number; 
	employees_knows_each_other_appropriate_parties: number; 
	employees_knows_each_other_reason_continue: number; 
	employees_knows_each_other_coi: number; 
	employees_knows_each_other_can_edit: number;

	interest_valuation: number;
	interest_valuation_description: string;
	interest_valuation_can_edit: number;

	mad_acting: number; 
	mad_acting_description: string;
	mad_acting_can_edit: number;

	asset_previously_valuated: number; 
	asset_previously_valuated_description: string;
	asset_previously_valuated_can_edit: number; 

	mad_third_party: number; 
	mad_third_party_description: string;
	mad_third_party_description_b: string; 
	mad_third_party_type_of_conflict_id: number; 
	mad_third_party_appropriate_parties: string; 
	mad_third_party_reason_continue: string; 
	mad_third_party_coi: number; 
	mad_third_party_can_edit: number; 

	employees_knowledge: number; 
	employees_knowledge_description: string;
	employees_knowledge_can_edit: number;

	conflicted_projects: number; 
	conflicted_projects_description: string;
	conflicted_projects_description_b: string; 
	conflicted_projects_type_of_conflict_id: number;
	conflicted_projects_appropriate_parties: string; 
	conflicted_projects_reason_continue: string; 
	conflicted_projects_coi: number; 
	conflicted_projects_can_edit: number; 

	conflicted_projects_b: number; 
	conflicted_projects_b_description: string;
	conflicted_projects_b_can_edit: number; 

	common_staff: number;
	common_staff_description: string;
	common_staff_description_b: string; 
	common_staff_type_of_conflict_id: number;
	common_staff_appropriate_parties: string; 
	common_staff_reason_continue: string; 
	common_staff_coi: number; 
	common_staff_can_edit: number; 

	fee_sharing: number; 
	fee_sharing_description: string;
	fee_sharing_description_b: string; 
	fee_sharing_type_of_conflict_id: number; 
	fee_sharing_appropriate_parties: string; 
	fee_sharing_reason_continue: string; 
	fee_sharing_coi: number; 
	fee_sharing_can_edit: number; 

	valuation_significant_revenue: number;
	valuation_significant_revenue_description: string;
	valuation_significant_revenue_can_edit: number; 

	verbal_value: number; 
	verbal_value_description: string;
	verbal_value_can_edit: number; 

	valuing_for_lender: number; 
	valuing_for_lender_description: string;
	valuing_for_lender_description_b: string; 
	valuing_for_lender_type_of_conflict_id: number; 
	valuing_for_lender_appropriate_parties: string; 
	valuing_for_lender_reason_continue: string; 
	valuing_for_lender_coi: number; 
	valuing_for_lender_can_edit: number; 

	long_standing_professional: number; 
	long_standing_professional_description: string;
	long_standing_professional_can_edit: number; 

	introducing_transaction: number; 
	introducing_transaction_description: string;
	introducing_transaction_can_edit: number; 

	financial_interest: number; 
	financial_interest_description: string;
	financial_interest_description_b: string 
	financial_interest_type_of_conflict_id: number;
	financial_interest_appropriate_parties: string; 
	financial_interest_reason_continue: string; 
	financial_interest_coi: number; 
	financial_interest_can_edit: number; 

	financial_interest_b: number; 
	financial_interest_b_description: string;
	financial_interest_b_can_edit: number; 

	acting_for: number; 
	acting_for_description: string;
	acting_for_description_b: string; 
	acting_for_type_of_conflict_id: number; 
	acting_for_appropriate_parties: string; 
	acting_for_reason_continue: string; 
	acting_for_coi: number; 
	acting_for_can_edit: number; 

	acting_for_borrower: number; 
	acting_for_borrower_description: string;
	acting_for_borrower_can_edit: number; 

	retained_to_act: number; 
	retained_to_act_description: string;
	retained_to_act_can_edit: number; 

	recently_acted: number; 
	recently_acted_description: string;
	recently_acted_can_edit: number; 

	professional_advice: number; 
	professional_advice_description: string;
	professional_advice_can_edit: number; 

	providing_development_consultancy: number; 
	providing_development_consultancy_description: string;
	providing_development_consultancy_description_b: string; 
	providing_development_consultancy_type_of_conflict_id: number; 
	providing_development_consultancy_appropriate_parties: string; 
	providing_development_consultancy_reason_continue: string; 
	providing_development_consultancy_coi: number; 
	providing_development_consultancy_can_edit: number; 

	valuer_identify_conflict: number; 
	valuer_identify_conflict_description: string;
	valuer_identify_conflict_description_b: string; 
	valuer_identify_conflict_type_of_conflict_id: number; 
	valuer_identify_conflict_appropriate_parties: string; 
	valuer_identify_conflict_reason_continue: string; 
	valuer_identify_conflict_coi: number; 
	valuer_identify_conflict_can_edit: number; 

	valuer_doubts_ability: number; 
	valuer_doubts_ability_description: string;
	valuer_doubts_ability_type_of_conflict_id: number;
	valuer_doubts_ability_appropriate_parties: string; 
	valuer_doubts_ability_reason_continue: string; 
	valuer_doubts_ability_can_edit: number; 

	coi_properties: any[]
}