import { VAssetClassConsideration } from "src/app/core/valuation-process/_models/valuation-asset-class-common.models";
import { calculateNonRecoverable, calculateRecoverable } from "../../../core";
import { durationCalculation } from "../../../valuation";
import { HeadlineToEffectiveMeta } from "../types";
import { VComparable } from "../types/asset-class/comparable";
import { IncomeValuationProcess, ValuationProcess } from "../types/valuation-process";
import * as _ from 'lodash'

export function addFullComparables(comparables: VComparable[], _comparables: VComparable[]): VComparable[] {
  return _comparables.reduce((acc, com) => {
    const exists = acc.find(item => item.refNum === com.refNum)
    if (exists) return acc
    return [...acc, com]
  }, comparables) 
}

export function addComparable(
  valuationProcess: ValuationProcess,
  comparables: VComparable[], 
  refNum: string,
): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  return addComparableInIncomeValuation(
    valuationProcess,
    comparables, 
    refNum
  )
}

function addComparableInIncomeValuation(
  process: IncomeValuationProcess,
  comparables: VComparable[], 
  refNum: string
): IncomeValuationProcess {
  const item = comparables.find(_item => _item.refNum === refNum)
  if (!item) return process
  if (process.data.comparables[item.propertySubType].length === 5) return process
  const _comparables = {
    ...process.data.comparables,
    [item.propertySubType]: [...process.data.comparables[item.propertySubType], item]
  }
  return {
    ...process,
    data: {
      ...process.data,
      comparables: _comparables
    }
  }
}

export function removeComparable(
  valuationProcess: ValuationProcess,
  refNum: string
): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  return removeComparableInIncomeValuation(valuationProcess, refNum)
}

function removeComparableInIncomeValuation(
  process: IncomeValuationProcess,
  refNum: string
): IncomeValuationProcess {
  const comparables = _.flatten(Object.values(process.data.comparables))
  const item = comparables.find(_item => _item.refNum === refNum)
  if (!item) return process
  const _comparables = {
    ...process.data.comparables,
    [item.propertySubType]: process.data.comparables[item.propertySubType].filter(_item => _item.refNum !== refNum)
  }
  return {
    ...process,
    data: {
      ...process.data,
      comparables: _comparables
    }
  }
}

export function comparableAverages(
  comparables: VComparable[], 
  selectedConsiderations: {refNum: string, id: number}[]
) {
  const considerationAndSizes = comparables.map(comparable => {
    const selectedConsideration = selectedConsiderations.find(item => item.refNum === comparable.refNum)
    if (selectedConsideration !== undefined) {
      return {
        refNum: comparable.refNum,
        consideration: comparable.considerations.find(item => item.id === selectedConsideration.id),
        size: comparable.sizes.length > 0 ? comparable.sizes[0].size : 0
      }
    }
    if (comparable.considerations.length > 0) {
      return {
        refNum: comparable.refNum,
        consideration: comparable.considerations[0],
        size: comparable.sizes.length > 0 ? comparable.sizes[0].size : 0
      }
    }
    return undefined
  })

  const considerations = considerationAndSizes.filter(item => item !== undefined).map(item => item.consideration)


  const leaseDuration = considerations.reduce((acc, item) => {
    if (item.considerationType === 'Rent') {
      const leaseDuration = durationCalculation(item.oldTenure.lease_duration, item.oldTenure.duration_type)
      return {
        total: acc.total + Number(leaseDuration),
        num: acc.num + 1
      }
    }
    return acc
  }, {
    total: 0,
    num: 0
  })

  const rentReview = considerations.reduce((acc, item) => {
    if (item.considerationType === 'Rent') {
      const rentReview = durationCalculation(item.oldTenure.rent_review, item.oldTenure.duration_type)
      return {
        total: acc.total + Number(rentReview),
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const recoverable = considerations.reduce((acc, item) => {
    if (item.considerationType === 'Rent') {
      const recoverable = calculateRecoverable(item.oldTenure)
      return {
        total: acc.total + recoverable,
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const nonRecoverable = considerations.reduce((acc, item) => {
    if (item.considerationType === 'Rent') {
      const nonRecoverable = calculateNonRecoverable(item.oldTenure)
      return {
        total: acc.total + nonRecoverable,
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const rentFreePeriod = considerations.reduce((acc, item) => {
    if (item.considerationType === 'Rent') {
      const rentReview = durationCalculation(item.oldTenure.rent_free_period, item.oldTenure.duration_type)
      return {
        total: acc.total + Number(rentReview),
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const fittingOutPeriod = considerations.reduce((acc, item) => {
    if (item.considerationType === 'Rent') {
      const rentReview = durationCalculation(item.oldTenure.fitting_out_period, item.oldTenure.duration_type)
      return {
        total: acc.total + Number(rentReview),
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  return {
    effectiveRent: avgEffectives(considerationAndSizes),
    leaseDuration: leaseDuration.num > 0 ? (leaseDuration.total / leaseDuration.num) : undefined,
    rentReview: rentReview.num > 0 ? (rentReview.total / rentReview.num) : undefined,
    recoverable: recoverable.num > 0 ? (recoverable.total / recoverable.num) : undefined,
    nonRecoverable: nonRecoverable.num > 0 ? (nonRecoverable.total / nonRecoverable.num) : undefined,
    rentFreePeriod: rentFreePeriod.num > 0 ? (rentFreePeriod.total / rentFreePeriod.num) : undefined,
    fittingOutPeriod: fittingOutPeriod.num > 0 ? (fittingOutPeriod.total / fittingOutPeriod.num) : undefined
  }
}

export function selectConsideration(valuationProcess: ValuationProcess, refNum: string, id: number): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  return {
    ...valuationProcess,
    data: {
      ...valuationProcess.data,
      selectedConsiderations: updateSelectedConsiderations(valuationProcess.data.selectedConsiderations, refNum, id)
    }
  }
}

function updateSelectedConsiderations(
  selectedConsiderations: {refNum: string, id: number}[],
  refNum: string,
  id: number
): {refNum: string, id: number}[] {
  const index = selectedConsiderations.findIndex(item => item.refNum === refNum)
  if (index === -1) {
    return [...selectedConsiderations, {refNum, id}]
  }

  return selectedConsiderations.map(item => {
    if (item.refNum === refNum) {
      return {
        ...item,
        id
      }
    }
    return item
  })
}

function avgEffectives(
  considerationAndSizes: {refNum: string, consideration: VAssetClassConsideration, size: number}[], 
) {
  // if (considerationAndSizes.filter(item => item !== undefined).length == 0) {
  //   return {
  //     value: undefined
  //   }
  // }
  let hasHeadlineRent = false;
  const effectiveRents = considerationAndSizes
    .filter(item => item !== undefined)
    .map(({refNum, consideration, size}) => {
      if (consideration.considerationType === 'Sale') {
        return consideration.value
      }
      if (consideration.considerationType === 'Land') {
        return consideration.value
      }

      if (consideration.rentType === 'Effective') {
        return consideration.value
      }
      hasHeadlineRent = true
      return consideration.value;
    })

  const avg = effectiveRents.reduce((acc, item) => {
    return {
      total: acc.total + item,
      num: acc.num + 1
    }
  }, {total: 0, num: 0})

  return {
    value: avg.num > 0 ? avg.total / avg.num : undefined,
    hasHeadlineRent
  }
}

export function setComparableHeadlineToEffective(
  process: ValuationProcess,
  item: {refNum: string, meta: HeadlineToEffectiveMeta}
): ValuationProcess {
  if (process.kind === 'unknown') return process
  if (process.kind === 'market') return process
  const exists = process.data.comparableHeadlineToEffectives.find(_item => _item.refNum === item.refNum)
  if (exists) {
    const chte = process.data.comparableHeadlineToEffectives.map(_item => {
      if (_item.refNum === item.refNum) {
        return item
      }
      return _item
    })
    return {
      ...process,
      data: {
        ...process.data,
        comparableHeadlineToEffectives: chte
      }
    }
  }
  return {
    ...process,
    data: {
      ...process.data,
      comparableHeadlineToEffectives: [...process.data.comparableHeadlineToEffectives, item]
    }
  }
}