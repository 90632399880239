<h6 class="mb-4">
    <strong>{{conflictData.order_num}}. {{conflictData.definition}}</strong>
    <!-- <mat-icon
            matSuffix
            class="cursor-pointer icon-gray pl-2 mt-1"
            container="body"
            *ngIf="tooltipKeys.indexOf(conflictData.name) > -1"
            [popoverTitle]="'TOOLTIP.CONFLICT_OF_INTEREST.'+conflictData.name.toUpperCase()+'.TITLE' | translate"
            [ngbPopover]="'TOOLTIP.CONFLICT_OF_INTEREST.'+conflictData.name.toUpperCase()+'.DESCRIPTION' | translate"
            (click)="$event.stopPropagation();">help
    </mat-icon> -->
    <mat-icon matSuffix
        class="cursor-pointer icon-gray pl-2 mt-1"
        container="body"
        [ngbPopover]="'TOOLTIP.CONFLICT_OF_INTEREST.' + conflictData.name.toUpperCase() + '_(Q' + conflictData.order_num.toUpperCase() + ').DESCRIPTION' | translate"
        (click)="$event.stopPropagation();">
        help
    </mat-icon>
</h6>

<mat-radio-group class="mb-2 p-1"
                 [ngClass]="{ 
                    'example-radio-group': options.length == 4, 
                    'readonly': readonly
                }"
                 (change)="radioChanged()"
                [disabled]="!canEdit"
                 [(ngModel)]=" selectedOptionIndex">

    <mat-radio-button class="example-radio-button"
                      *ngFor="let option of options; let i=index"
                      [value]="i"
    >{{option.name}}
    </mat-radio-button>
</mat-radio-group>

<h6 class="mb-4 mt-4" id="" *ngIf="showForm_a && conflictData.name != 'valuer_doubts_ability'">
    <strong>{{'a'}}. {{conflictData.definition_a}}</strong>


    <mat-icon
            matSuffix
            *ngIf="conflictData.option_type_a != 6"
            class="cursor-pointer icon-gray pl-2"
            container="body"
            [popoverTitle]="'TOOLTIP.CONFLICT_OF_INTEREST.ANY_CONFLICT.TITLE' | translate"
            [ngbPopover]="'TOOLTIP.CONFLICT_OF_INTEREST.ANY_CONFLICT.DESCRIPTION' | translate"
            (click)="$event.stopPropagation();">help
    </mat-icon>
    <!--<strong>Has the Agency or any of the Valuers been involved with the client-->
    <!--previously</strong><br>-->
    <!--<i>* Refer to RICS / Professional Standard 2 - Section 3, Paragraph 3.7</i>-->
</h6>
<mat-radio-group class="mb-2 p-1" *ngIf="showForm_a && options_a[0].name !== 'tag-input' && conflictData.name != 'valuer_doubts_ability'"
                 [ngClass]="{ 
                    'example-radio-group': options_a.length == 4 ,
                    'readonly': readonly
                }"
                [disabled]="!canEdit"
                 [(ngModel)]=" selectedOptionIndex_a">

    <mat-radio-button class="example-radio-button"
                      *ngFor="let option of options_a; let i=index"
                      [value]="i"
    >{{option.name}}
    </mat-radio-button>
</mat-radio-group>

<kt-tp-list *ngIf="showForm_a && options_a[0].name === 'tag-input'" [selectedTPs]="selectedTPs" [tp]="tp" [conflictData]="conflictData"></kt-tp-list>

<div class="kt-form__section kt-form__section--first"
     *ngIf="(showForm && showForm_a) || (showForm_a && conflictData.name == 'valuer_doubts_ability')"
        [ngClass]="{
            'readonly': readonly
        }">
    <h6 class="mb-4 mt-4">
        <strong>{{conflictData.name == 'valuer_doubts_ability' ? 'a' : 'b'}}. {{'Nature of the (potential) conflict(s)'}}</strong>
    </h6>
    <form [formGroup]="conflictForm">
        <div class="form-group kt-form__group row">
            <div class="col-lg-12  mx-2 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput rows="1"
                              #field
                              minlength="20"
                              [attr.disabled]="explainDisable ? '' : null"
                              placeholder="{{conflictData.mitigation_strategy_title}}"
                              formControlName="explain"></textarea>

                    <!--[disabled]="explainDisable"-->
                    <mat-error *ngIf="conflictForm.controls.explain.hasError('required')">It is
                        <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="conflictForm.controls.explain.hasError('minlength')">
                        It must be at least 20 characters long
                    </mat-error>


                </mat-form-field>
            </div>
            <div class="col-lg-12  mx-2 kt-margin-bottom-20-mobile mt-4 " *ngIf="conflictData.name != 'valuer_doubts_ability'">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput rows="1"
                              #field
                              minlength="20"
                              [attr.disabled]="explainDisable ? '' : null"
                              placeholder="{{conflictData.mitigation_strategy_title_b}}"
                              formControlName="explain2"></textarea>

                    <!--[disabled]="explainDisable"-->
                    <mat-error *ngIf="conflictForm.controls.explain2.hasError('required')">It is
                        <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="conflictForm.controls.explain2.hasError('minlength')">
                        It must be at least 20 characters long
                    </mat-error>
                </mat-form-field>
            </div>
            <div lass="col-lg-12 kt-margin-bottom-20-mobile">
                <div class="d-inline-block mt-2 mr-3 ml-4" *ngIf="conflictData.mitigation_strategy && conflictData.mitigation_strategy.length > 0"
                     style="font-size: small; text-align: justify; text-justify: inter-word;">
                    <b>Mitigation strategy: </b>
                    '{{conflictData.mitigation_strategy}}'
                </div>

                <div class="d-inline-block mt-2 mr-3 ml-4" *ngIf="conflictData.mitigation_strategy == null || conflictData.mitigation_strategy.length == 0"
                     style="font-size: small; text-align: justify; text-justify: inter-word;">
                    NO MITIGATION STRATEGY
                </div>
            </div>
        </div>

        <div class="col-md-12 kt-margin-bottom-20-mobile" *ngIf="conflictData.name != 'valuer_doubts_ability'">
            <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Type of Conflict" formControlName="type_of_conflict_id">
                    <mat-option *ngFor="let typeOfConflict of typeOfConflicts" [value]="typeOfConflict.id">{{typeOfConflict.name}}</mat-option>
                </mat-select>
                <mat-icon
                        matSuffix
                        class="cursor-pointer icon-gray"
                        container="body"
                        [popoverTitle]="'TOOLTIP.CONFLICT_OF_INTEREST.TYPE_OF_CONFLICT.TITLE' | translate"
                        [ngbPopover]="'TOOLTIP.CONFLICT_OF_INTEREST.TYPE_OF_CONFLICT.DESCRIPTION' | translate"
                        (click)="$event.stopPropagation();">help
                </mat-icon>

                <mat-error>
                    Type of Conflict
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-fluid">
                <textarea matInput rows="1"
                          placeholder="Appropriate Parties"
                          formControlName="appropriate_parties"></textarea>
                <mat-error *ngIf="conflictForm.controls.appropriate_parties.hasError('required')">It is
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-fluid">
                <textarea matInput rows="1"
                          placeholder="Reason to Continue"
                          formControlName="reason_continue"></textarea>

                <mat-icon
                        matSuffix
                        class="cursor-pointer icon-gray"
                        container="body"
                        [popoverTitle]="'TOOLTIP.CONFLICT_OF_INTEREST.REASON_CONTINUE.TITLE' | translate"
                        [ngbPopover]="'TOOLTIP.CONFLICT_OF_INTEREST.REASON_CONTINUE.DESCRIPTION' | translate"
                        (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error *ngIf="conflictForm.controls.reason_continue.hasError('required')">It is
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-12 kt-margin-bottom-20-mobile"
             *ngIf="conflictData.name == 'valuer_involved_in_purchase'">

            <div class="mr-2">
                <span class="btn btn-primary" color="primary" mat-raised-button [ngClass]="{'readonly': readonly}" (click)="addFiles()">Uploaded Files {{ countString() }}</span>
            </div>
        </div>
    </form>

</div>