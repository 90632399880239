import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';
import { environment } from 'src/environments/environment';
import {map, tap} from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { DeleteCriterionsTemplate } from 'src/app/core/template';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';


type TeamMember = {
  id: number,
  first_name: string,
  last_name: string,
  email: string
  qualification: string,
  picture: string,
  agency: string
}

export type LogisticsNote = {
  id: number | undefined,
  user: {
    id: number
    first_name: string,
    last_name: string
  },
  updated_at: Date,
  status: boolean,
  input: string,
}

export type InspectionDetails = {
  id: number | undefined,
  type_of_inspection_id: number,
  timezone_offset: string,
  inspection_date: string,
  time_of_inspection: string,
  duration_of_inspection: string,
  has_limitations: boolean,
  limitations: string[],
  inspection_notes: string[],
  information_date: string,
  source_of_information: string
}

export type ParticipantType = 'none' | 'our' | 'client' | 'external'

export type LogisticParticipant = {
  id: number | undefined,
  type: ParticipantType,
  name: string,
  side: string,
  descr: string,
  is_main: boolean,
  included: boolean
}

@Injectable()
export class LogisticsService {
  private _notes$ = new BehaviorSubject<Array<LogisticsNote>>([])
  notes$ = this._notes$.asObservable()
  get notes() {
    return this._notes$.value
  }

  private _inspectionDetails$ = new BehaviorSubject<InspectionDetails>(null);
  inspectionDetail$ = this._inspectionDetails$.asObservable()

  private _participants$ = new BehaviorSubject<Array<LogisticParticipant>>([])
  participants$ = this._participants$
  get participants() {
    return this._participants$.value
  }

  private _timezoneOffset$ = new BehaviorSubject<string>(null);
  timezoneOffset$ = this._timezoneOffset$.asObservable();

  // private _participantSides$ = new BehaviorSubject<Array<{value: ParticipantType, name: string}>>([])
  // participantSides$ = this._participantSides$.asObservable()
  participantSides: Array<{value: ParticipantType, name: string}> = []

  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/logistics`;
  constructor(
    private httpClient: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) { }

  edit(asset_class_id: number) {
    const httpHeaders = this.authService.getAuthHeaders()
    return this.httpClient.get<{data: {
      asset_class: {
        id: number,
        property_type: string | null,
        property_sub_type: string | null,
        address: string | null,
        floor: string,
        floor_numbering_scheme: string | null,
        city: string | null,
        zip_code: string,
        latitude: string,
        longitude: string,
        date_of_investigation: string,
        type_of_investigation: string,
        timezone_offset: string,
        measurement_standard: string,
        unit_area_measurement: string,
        extent_of_investigation: {
          included_all_equipment: boolean,
          included_all_equipment_descr: string,
          included_furniture: boolean,
          included_furniture_descr: string,
          natural_accident_consideration: boolean,
          natural_accident_consideration_descr: string,
          material_accident_consideration: boolean,
          material_accident_consideration_descr: string,
          diligence_check: boolean,
          diligence_check_descr: string,
          expected_limitations_restrictions: boolean,
          expected_limitations_restrictions_descr: string,
          instructed_to_measure: boolean,
          methodology: string,
          purpose: string
        }
      },
      toe: {
        agency: string,
        name: string,
        date_of_completion: string,
        date_of_instruction: string,
        timezone_offset: string,
        client: {
          name: string,
          representative: string | null,
          email: string,
          phone: string
        },
        team: {
          project_manager: TeamMember,
          lead_valuer: TeamMember,
          other_members: Array<TeamMember>
        }
      },
      notes: Array<LogisticsNote & {updated_at: string}>,
      inspection_details: {
        id: number | null,
        type_of_inspection_id: number,
        participants: Array<LogisticParticipant>,
        participant_sides: Array<{value: ParticipantType, name: string}>,
        inspection_date: string,
        time_of_inspection: string,
        duration_of_inspection: string,
        has_limitations: boolean,
        limitations: Array<{value: string}>,
        inspection_notes: Array<{value: string}>,
        information_date: string,
        source_of_information: string
      }
    }}>(`${this.API_URL}/edit/${asset_class_id}`, {
      headers: httpHeaders
    }).pipe(
      tap(res => {
        this._notes$.next(res.data.notes.map(note => ({...note, updated_at: new Date(note.updated_at)})))
        this._inspectionDetails$.next({
          ...res.data.inspection_details,
          timezone_offset: res.data.asset_class.timezone_offset,
          limitations: res.data.inspection_details.limitations 
            ? res.data.inspection_details.limitations.map(item => item.value)
            : [],
          inspection_notes: res.data.inspection_details.inspection_notes
            ? res.data.inspection_details.inspection_notes.map(item => item.value)
            : []
        })
        this.participantSides = res.data.inspection_details.participant_sides
        this._participants$.next(res.data.inspection_details.participants.map(participant => {
          return {
            ...participant,
            side: res.data.inspection_details.participant_sides.find(participant_side => participant_side.value == participant.type).name
          }
        }))
        this._timezoneOffset$.next(res.data.asset_class.timezone_offset)
      }),
      map(res => {
        const body = res.data

        return {
          asset_class: {
            ...body.asset_class,
            longitude: Number(body.asset_class.longitude),
            latitude: Number(body.asset_class.latitude)
          },
          toe: {
            ...body.toe,
            team: {
              project_manager: this._convertTeamMemberDTO(body.toe.team.project_manager, body.toe.team) ,
              lead_valuer: this._convertTeamMemberDTO(body.toe.team.lead_valuer, body.toe.team),
              other_members: body.toe.team.other_members.map(member => this._convertTeamMemberDTO(member, body.toe.team))
            }
          }
        }
      })
    )
  }

  upsert(value: {
    notes: LogisticsNote[], 
    participants: LogisticParticipant[], 
    inspection_date: string,
    time_of_inspection: any,
    duration_of_inspection: string,
    has_limitations: boolean,
    limitations: string[],
    inspection_notes: string[],
    tp_id: number
  }) {
    const httpHeaders = this.authService.getAuthHeaders()
    return this.httpClient.post(this.API_URL, value, {headers: httpHeaders})
  }

  export(asset_class_id: number): Observable<any> {
    const httpHeaders = this.authService.getAuthHeaders()
    const req = new HttpRequest(
      'POST',
      `${this.API_URL}/export/${asset_class_id}`,
      {
        notes: this.notes
      },
      {
        responseType: 'blob',
        headers: httpHeaders
      }
    );
    return this.httpClient.request(req);
  }

  addNote({input, user}: {input: string, user: User}) {
    const notes = this._notes$.value
    notes.push({
      id: undefined,
      input,
      user: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      },
      updated_at: new Date(),
      status: true
    })

    this._notes$.next(notes)
  }

  changeNoteStatus(status: boolean, index: number) {
    const notes = this._notes$.value
    notes[index] = {
      ...notes[index],
      status
    }
    this._notes$.next(notes)
  }

  deleteNote(index: number) {
    const notes = this._notes$.value
    notes.splice(index, 1)
    this._notes$.next(notes)
  }

  editNote(input: string, index: number) {
    const notes = this._notes$.value
    notes[index] = {
      ...notes[index],
      input,
    }
    this._notes$.next(notes)
  }

  addParticipant(participant: LogisticParticipant) {
    const participants = this._participants$.value
    participants.push(participant)
    this._participants$.next(participants.map(p => ({
      ...p,
      side: this.participantSides.find(participantSide => participantSide.value === participant.type).name
    })))
  }
 
  editParticipant({name, type, descr, included} : LogisticParticipant, index: number) {
    const participants = this._participants$.value
    participants[index] = {
      ...participants[index],
      name,
      type,
      descr,
      included
    }
    this._participants$.next(participants.map(participant => ({
      ...participant,
      side: this.participantSides.find(participantSide => participantSide.value === participant.type).name
    })))
  }

  deleteParticipant(index: number) {
    const participants = this._participants$.value
    participants.splice(index, 1)
    this._participants$.next(participants)
  }

  private _convertTeamMemberDTO(member: TeamMember, team: {project_manager: TeamMember, lead_valuer: TeamMember, other_members: Array<TeamMember>}) {
    let role = null;
    if (team.project_manager.id == member.id) {
      role = 'Project Manager'
      if (member.id == team.lead_valuer.id) {
        role += ' & Lead Valuer'
      } else if (team.other_members.filter(m => m.id == member.id).length > 0) {
        role += ' & Supporting Staff'
      }
    } else {
      if (member.id == team.lead_valuer.id) {
        role = 'Lead Valuer'
      } else if (team.other_members.filter(m => m.id == member.id).length > 0) {
        role = 'Supporting Staff'
      }
    }

    return {
      id: member.id,
      full_name: `${member.first_name} ${member.last_name}`,
      email: member.email,
      qualification: member.qualification,
      agency: member.agency,
      user_pic: member.picture,
      role: role
    }
  }
}
