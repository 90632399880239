// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {
    selectTypeOfRegistrationQueryResult,
    selectTypeOfRegistrationsPageLoading,
    selectTypeOfRegistrationsShowInitWaitingMessage
} from '../_selectors/type-of-registration.selectors';

export class TypeOfRegistrationDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectTypeOfRegistrationsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTypeOfRegistrationsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTypeOfRegistrationQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
