// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
// Models
import {ToeSettlement} from '../_models/toe-settlement.model';
import {environment} from '../../../../environments/environment';
import {SupportingValuerModel} from '../_models/supporting-valuer.model';
import {
    AssetClassModel,
    PaymentModel,
    PaymentTaxModel,
    ValuationModel,
    PaymentTermModel,
} from '../../asset_class';
import {CoiDetailsTPModel} from '../_models/coi-details-tp.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class ToeSettlementService {
    API_TOE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-settlements`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }


    // READ =>  GET: list of clients from the server
    findToeSettlements(payload: any): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', payload.sortOrder)
            .set('sortField', payload.sortField)
            .set('filter[toe_id]', payload.filter.toe_id)
        return this.http.get<any>(this.API_TOE_URL, {
            headers: httpHeaders,
            params: params
        });
    }

    // CREATE =>  POST: add a new client to the server
    createToeSettlement(body: {
        toe: ToeSettlement,
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_TOE_URL, body, {headers: httpHeaders});
    }

    // UPDATE =>  PUT: update the client to the server
    updateToeSettlement(payment: ToeSettlement
    ): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_TOE_URL + '/' + payment.id, payment, {headers: httpHeaders});
    }

    // DELETE => delete the toe from the server
    deleteToeSettlement(toeId: number): Observable<any> {
        const url = `${this.API_TOE_URL}/${toeId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url, {headers: httpHeaders});
    }
}
