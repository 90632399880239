<div class="card card-custom">
  <div>
    <div class="form">
      <div class="card-body">
        <p>{{data.description}}</p>
      </div>
      <div class="card-footer b-0 p-0 text-right">
        <div class="form-actions form-actions-solid">
          <button type="button" mat-raised-button cdkFocusInitial matTooltip="Close" (click)="onUnaccept()">
            No
          </button>
          &nbsp;
          <button type="button" mat-raised-button class="btn btn-success" color="submit" matTooltip="Save Changes"
            (click)="onAccept()">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>