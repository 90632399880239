import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';

@Component({
  selector: 'kt-sizes-modal',
  templateUrl: './sizes-modal.component.html',
  styleUrls: ['./sizes-modal.component.scss']
})
export class SizesModalComponent implements OnInit {
  @Input()
  info: any;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }
}
