import {ReportDeliveryMethod} from '../_models/report-delivery-method.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ReportDeliveryMethodsState} from '../_reducers/report-delivery-method.reducers';
import * as fromReportDeliveryMethod from '../_reducers/report-delivery-method.reducers';
import {each} from 'lodash';

export const selectReportDeliveryMethodsState = createFeatureSelector<ReportDeliveryMethodsState>('reportDeliveryMethods');

export const selectReportDeliveryMethodById = (reportDeliveryMethodId: number) => createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => reportDeliveryMethodsState.entities[reportDeliveryMethodId]
);

export const selectAllReportDeliveryMethods = createSelector(
    selectReportDeliveryMethodsState,
    fromReportDeliveryMethod.selectAll
);

export const selectAllReportDeliveryMethodsIds = createSelector(
    selectReportDeliveryMethodsState,
    fromReportDeliveryMethod.selectIds
);

export const allReportDeliveryMethodsLoaded = createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => reportDeliveryMethodsState.isAllReportDeliveryMethodsLoaded
);


export const selectReportDeliveryMethodsPageLoading = createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => reportDeliveryMethodsState.listLoading
);

export const selectReportDeliveryMethodsActionLoading = createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => reportDeliveryMethodsState.actionLoading
);

export const selectLastCreatedReportDeliveryMethodId = createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => reportDeliveryMethodsState.lastCreatedReportDeliveryMethodId
);

export const selectReportDeliveryMethodsShowInitWaitingMessage = createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => reportDeliveryMethodsState.showInitWaitingMessage
);

export const selectTrashedReportDeliveryMethodCount = createSelector(
    selectReportDeliveryMethodsState,
    (reportDeliveryMethodsState) => reportDeliveryMethodsState.totalTrashed
);

export const selectAdminTrashedReportDeliveryMethodCount = createSelector(
    selectReportDeliveryMethodsState,
    (reportDeliveryMethodsState) => reportDeliveryMethodsState.totalAdminTrashed
);


export const selectReportDeliveryMethodQueryResult = createSelector(
    selectReportDeliveryMethodsState,
    reportDeliveryMethodsState => {
        const items: ReportDeliveryMethod[] = [];
        each(reportDeliveryMethodsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ReportDeliveryMethod[] = httpExtension.sortArray(items, reportDeliveryMethodsState.lastQuery.sortField, reportDeliveryMethodsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, reportDeliveryMethodsState.totalCount, '', reportDeliveryMethodsState.totalTrashed);
    }
);
