import {OfficeLayout} from '../_models/office-layout.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {OfficeLayoutsState} from '../_reducers/office-layout.reducers';
import * as fromOfficeLayout from '../_reducers/office-layout.reducers';
import {each} from 'lodash';

export const selectOfficeLayoutsState = createFeatureSelector<OfficeLayoutsState>('officeLayouts');

export const selectOfficeLayoutById = (officeLayoutId: number) => createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => officeLayoutsState.entities[officeLayoutId]
);

export const selectAllOfficeLayouts = createSelector(
    selectOfficeLayoutsState,
    fromOfficeLayout.selectAll
);

export const selectAllOfficeLayoutsIds = createSelector(
    selectOfficeLayoutsState,
    fromOfficeLayout.selectIds
);

export const allOfficeLayoutsLoaded = createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => officeLayoutsState.isAllOfficeLayoutsLoaded
);


export const selectOfficeLayoutsPageLoading = createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => officeLayoutsState.listLoading
);

export const selectOfficeLayoutsActionLoading = createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => officeLayoutsState.actionLoading
);

export const selectLastCreatedOfficeLayoutId = createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => officeLayoutsState.lastCreatedOfficeLayoutId
);

export const selectOfficeLayoutsShowInitWaitingMessage = createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => officeLayoutsState.showInitWaitingMessage
);


export const selectTrashedOfficeLayoutCount = createSelector(
    selectOfficeLayoutsState,
    (officeLayoutsState) => officeLayoutsState.totalTrashed
);

export const selectAdminTrashedOfficeLayoutCount = createSelector(
    selectOfficeLayoutsState,
    (officeLayoutsState) => officeLayoutsState.totalAdminTrashed
);


export const selectOfficeLayoutQueryResult = createSelector(
    selectOfficeLayoutsState,
    officeLayoutsState => {
        const items: OfficeLayout[] = [];
        each(officeLayoutsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OfficeLayout[] = httpExtension.sortArray(items, officeLayoutsState.lastQuery.sortField, officeLayoutsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, officeLayoutsState.totalCount, '', officeLayoutsState.totalTrashed);
    }
);
