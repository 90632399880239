import { Injectable } from "@angular/core";
import { ValuationAdjustmentModel } from "../_models/valuation-adjustment.model";

@Injectable()
export class ValuationAdjustmentService {
    createValuationAdjustment(valuationAdjustment: ValuationAdjustmentModel, id: number): ValuationAdjustmentModel {
        const v = new ValuationAdjustmentModel();
        v.clear();
        v.id = id;
        v.adjustment_id = valuationAdjustment.adjustment_id;
        v.name = valuationAdjustment.name;
        v.desc = valuationAdjustment.desc;
        v.analysis = valuationAdjustment.analysis;
        if (valuationAdjustment.adjValues) {
            Object.entries(valuationAdjustment.adjValues).forEach(([key, value]) => {
                const _id = Number(key);
                v.adjValues[_id] = value;
            })
        }
        return v;
    }

    addComparable(com: any, vas: ValuationAdjustmentModel[]): ValuationAdjustmentModel[] {
        const _vas = this._copyValuationAdjustments(vas);
        _vas.forEach(v => {
            v.adjValues[com.id] = 0;
        })
        return _vas;
    }

    addComparables(com: any[], vas: ValuationAdjustmentModel[]): ValuationAdjustmentModel[] {
        let _vas = this._copyValuationAdjustments(vas);
        com.forEach(c => {
            const res = this.addComparable(c, _vas);
            _vas = res;
        })
        return _vas;
    }

    removeComparable(comID: any, vas: ValuationAdjustmentModel[]): ValuationAdjustmentModel[] {
        const _vas = this._copyValuationAdjustments(vas);
        _vas.forEach(v => {
            // v.adjValues.splice(comIndx, 1)
            delete v.adjValues[comID]
        })
        return _vas;
    }

    _copyValuationAdjustments(vs: ValuationAdjustmentModel[]): ValuationAdjustmentModel[] {
        const _vas: ValuationAdjustmentModel[] = [];
        vs.forEach(v => {
            const _new = this._copyValuationAdjustment(v);
            _vas.push(_new);
        })
        return _vas;
    }

    _copyValuationAdjustment(v: ValuationAdjustmentModel): ValuationAdjustmentModel {
        const _tmp = Object.assign({}, v) as ValuationAdjustmentModel;
        const _adj = Object.assign({}, v.adjValues) as {[id: number]: number};
        _tmp.adjValues = _adj;
        return _tmp
    }
}