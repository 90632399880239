// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {TopPropertyTypeService} from '../_services/top-property-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allTopPropertyTypesLoaded} from '../_selectors/top-property-type.selectors';
// Actions
import {
    AllTopPropertyTypesLoaded,
    AllTopPropertyTypesRequested,
    TopPropertyTypeActionTypes,
    TopPropertyTypesPageRequested,
    TopPropertyTypesPageLoaded,
    TopPropertyTypeUpdated,
    TopPropertyTypesPageToggleLoading,
    TopPropertyTypeDeleted,
    TopPropertyTypeOnServerCreated,
    TopPropertyTypeCreated,
    TopPropertyTypeActionToggleLoading, TopPropertyTypeTrashFlushed, TopPropertyTypeOnServerRestored, TopPropertyTypeRestored,
    TopPropertyTypeOnServerAdminRestored, TopPropertyTypeAdminRestored, TopPropertyTypeDeletedFromTrash, TopPropertyTypeDeletedFromAdminTrash
} from '../_actions/top-property-type.actions';

@Injectable()
export class TopPropertyTypeEffects {
    showPageLoadingDispatcher = new TopPropertyTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new TopPropertyTypeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TopPropertyTypeActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTopPropertyType$ = this.actions$
        .pipe(
            ofType<AllTopPropertyTypesRequested>(TopPropertyTypeActionTypes.AllTopPropertyTypesRequested),
            withLatestFrom(this.store.pipe(select(allTopPropertyTypesLoaded))),
            filter(([action, isAllTopPropertyTypeLoaded]) => !isAllTopPropertyTypeLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllTopPropertyTypesLoaded({items: res.data});
            })
        );

    @Effect()
    loadTopPropertyTypePage$ = this.actions$
        .pipe(
            ofType<TopPropertyTypesPageRequested>(TopPropertyTypeActionTypes.TopPropertyTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new TopPropertyTypesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeUpdated>(TopPropertyTypeActionTypes.TopPropertyTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeOnServerCreated>(TopPropertyTypeActionTypes.TopPropertyTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new TopPropertyTypeCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushTopPropertyTypeTrash$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeTrashFlushed>(TopPropertyTypeActionTypes.TopPropertyTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeOnServerRestored>(TopPropertyTypeActionTypes.TopPropertyTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TopPropertyTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeOnServerAdminRestored>(TopPropertyTypeActionTypes.TopPropertyTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TopPropertyTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeDeleted>(TopPropertyTypeActionTypes.TopPropertyTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeDeletedFromTrash>(TopPropertyTypeActionTypes.TopPropertyTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashTopPropertyType$ = this.actions$
        .pipe(
            ofType<TopPropertyTypeDeletedFromAdminTrash>(TopPropertyTypeActionTypes.TopPropertyTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: TopPropertyTypeService, private store: Store<AppState>) {
    }
}
