import { Update } from '@ngrx/entity';
// NGRX
import { Action } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { ValuationStandard } from '../_models/valuation-standard.model';

export enum ValuationStandardActionTypes {
    AllValuationStandardRequested = '[ValuationStandard Home Page] All ValuationStandard Requested',
    AllValuationStandardLoaded = '[ValuationStandard API] All ValuationStandard Loaded',


    ValuationStandardOnServerCreated = '[Edit ValuationStandard Dialog] ValuationStandard On Server Created',
    ValuationStandardCreated = '[Edit ValuationStandard Dialog] ValuationStandard Created',
    ValuationStandardUpdated = '[Edit ValuationStandard Dialog] ValuationStandard Updated',
    ValuationStandardDeleted = '[ValuationStandard List Page] ValuationStandard Deleted',

    // trash system
    ValuationStandardOnServerRestored = '[ValuationStandard Trash] ValuationStandard On Server Restored',
    ValuationStandardRestored = '[ValuationStandard Trash] ValuationStandard Restored',

    ValuationStandardOnServerAdminRestored = '[ValuationStandard Admin Trash] ValuationStandard On Server Restored',
    ValuationStandardAdminRestored = '[ValuationStandard Admin Trash] ValuationStandard Restored',

    ValuationStandardDeletedFromTrash = '[ValuationStandard Trash] ValuationStandard deleted',
    ValuationStandardDeletedFromAdminTrash = '[ValuationStandard Admin Trash] ValuationStandard deleted',

    ValuationStandardTrash = 'ValuationStandard Trashed',
    ValuationStandardTrashFlushed = 'ValuationStandard Trash Flushed',

    // Page system
    ValuationStandardPageRequested = '[ValuationStandard List Page] ValuationStandard Page Requested',
    ValuationStandardPageLoaded = '[ValuationStandard API] ValuationStandard Page Loaded',
    ValuationStandardPageCancelled = '[ValuationStandard API] ValuationStandard Page Cancelled',

    ValuationStandardPageToggleLoading = '[ValuationStandard page] ValuationStandard Page Toggle Loading',
    ValuationStandardActionToggleLoading = '[ValuationStandard] ValuationStandard Action Toggle Loading'
}

export class ValuationStandardOnServerCreated implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardOnServerCreated;

    constructor(public payload: { valuationStandard: ValuationStandard }) {
    }
}

export class ValuationStandardCreated implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardCreated;

    constructor(public payload: { valuationStandard: ValuationStandard }) {
    }
}

export class ValuationStandardUpdated implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardUpdated;

    constructor(public payload: {
        partialValuationStandard: Update<ValuationStandard>,
        valuationStandard: ValuationStandard
    }) {
    }
}

export class ValuationStandardDeleted implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ValuationStandardPageRequested implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ValuationStandardPageLoaded implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardPageLoaded;

    constructor(public payload: {
        valuationStandards: ValuationStandard[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class ValuationStandardPageCancelled implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardPageCancelled;
}

export class AllValuationStandardRequested implements Action {
    readonly type = ValuationStandardActionTypes.AllValuationStandardRequested;
}

export class AllValuationStandardLoaded implements Action {
    readonly type = ValuationStandardActionTypes.AllValuationStandardLoaded;

    constructor(public payload: { valuationStandards: ValuationStandard[] }) {
    }
}

export class ValuationStandardPageToggleLoading implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ValuationStandardActionToggleLoading implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ValuationStandardDeletedFromAdminTrash implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ValuationStandardDeletedFromTrash implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ValuationStandardOnServerRestored implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ValuationStandardRestored implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardRestored;

    constructor(public payload: { item: ValuationStandard }) {
    }
}

export class ValuationStandardOnServerAdminRestored implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ValuationStandardAdminRestored implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardAdminRestored;

    constructor(public payload: { item: ValuationStandard }) {
    }
}

export class ValuationStandardTrashFlushed implements Action {
    readonly type = ValuationStandardActionTypes.ValuationStandardTrashFlushed;

    constructor() {
    }
}


export type ValuationStandardActions = ValuationStandardCreated
    | ValuationStandardUpdated
    | ValuationStandardDeleted
    | ValuationStandardPageRequested
    | ValuationStandardPageLoaded
    | ValuationStandardPageCancelled
    | AllValuationStandardLoaded
    | AllValuationStandardRequested
    | ValuationStandardOnServerCreated

    | ValuationStandardDeletedFromAdminTrash
    | ValuationStandardOnServerRestored
    | ValuationStandardRestored
    | ValuationStandardOnServerAdminRestored
    | ValuationStandardAdminRestored

    | ValuationStandardDeletedFromTrash
    | ValuationStandardTrashFlushed

    | ValuationStandardPageToggleLoading
    | ValuationStandardActionToggleLoading