import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UpdateStatusDialogComponent} from '../update-status-dialog/update-status-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { User } from 'src/app/core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import { Subscription } from 'rxjs';


export interface TrashAction {
    restore(id: number, isAdmin: boolean);

    delete(id: number);

    flush();

    hardDelete(id: number);
}


@Component({
    selector: 'kt-trash-dialog',
    templateUrl: './trash-dialog.component.html'
})

export class TrashDialogComponent implements OnInit, OnDestroy {

    selectedStatusForUpdate = new UntypedFormControl('');
    viewLoading = false;
    loadingAfterSubmit = false;
    trashAction: TrashAction = null;
    currentUser: User|null;
    userSub: Subscription|null;

    constructor(
        public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>) {
        this.trashAction = data.action;
    }

    ngOnInit() {
        this.userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        setTimeout(() => {
            this.viewLoading = false;
        }, 2500);
    }

    ngOnDestroy(): void {
        if (this.userSub) {
            this.userSub.unsubscribe()
        }
    }

    restore(id: number): void {
        if (this.trashAction) {
            this.data.items.forEach(element => {
                if (element.id == id) {
                    this.data.items.splice(this.data.items.indexOf(element), 1);
                }
            });

            this.trashAction.restore(id, this.data.admin);

            if (this.data.items.length < 1) {
                this.dialogRef.close();
            }
        }
    }

    delete(id: number): void {
        if (this.trashAction) {
            this.data.items.forEach(element => {
                if (element.id == id) {
                    this.data.items.splice(this.data.items.indexOf(element), 1);
                }
            });


            if (this.data.admin) {
                this.trashAction.hardDelete(id);
                if (this.data.items.length < 1) {
                    this.dialogRef.close();
                }
                return;
            }

            this.trashAction.delete(id);

            if (this.data.items.length < 1) {
                this.dialogRef.close();
            }
        }
    }

    flushTrash() {
        if (this.trashAction) {
            this.dialogRef.close();
            this.trashAction.flush();
        }
    }

    updateStatus() {
        if (this.selectedStatusForUpdate.value.length === 0) {
            return;
        }

        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        this.loadingAfterSubmit = true;
        setTimeout(() => {
            this.dialogRef.close(this.selectedStatusForUpdate.value); // Keep only this row
        }, 2500);
    }
}
