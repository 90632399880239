import { AssetClassSERState } from '../_reducers/asset-class-ser.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { AssetClassSourceExternalReferenceModel } from '../_models/asset-class-source-external-reference.model';

export const selectAssetClassSERState = createFeatureSelector<AssetClassSERState>('assetClassSer');

export const selectAssetClassSERByTpId = (tpId: number) => createSelector(
    selectAssetClassSERState,
    assetClassSERState => {
        const items: AssetClassSourceExternalReferenceModel[] = [];
        each(assetClassSERState.entities, element => {
            if (element.asset_class_id == tpId) {
                items.push(element);
            }
        });
        return items;
    }
);
