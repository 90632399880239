// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ComparativeLandUseActions, ComparativeLandUseActionTypes} from '../_actions/comparative-land-use.actions';
// Models
import {ComparativeLandUse} from '../_models/comparative-land-use.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ComparativeLandUsesState extends EntityState<ComparativeLandUse> {
    isAllComparativeLandUsesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ComparativeLandUse[];
    lastCreatedComparativeLandUseId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ComparativeLandUse> = createEntityAdapter<ComparativeLandUse>();

export const initialComparativeLandUsesState: ComparativeLandUsesState = adapter.getInitialState({
    isAllComparativeLandUsesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedComparativeLandUseId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function comparativeLandUsesReducer(state = initialComparativeLandUsesState, action: ComparativeLandUseActions): ComparativeLandUsesState {
    switch (action.type) {
        case ComparativeLandUseActionTypes.ComparativeLandUsesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedComparativeLandUseId: undefined
            };
        case ComparativeLandUseActionTypes.ComparativeLandUsesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ComparativeLandUseActionTypes.ComparativeLandUseOnServerCreated:
            return {
                ...state
            };
        case ComparativeLandUseActionTypes.ComparativeLandUseCreated:
            return adapter.addOne(action.payload.comparativeLandUse, {
                ...state, lastCreatedComparativeLandUseId: action.payload.comparativeLandUse.id
            });
        case ComparativeLandUseActionTypes.ComparativeLandUseUpdated:
            return adapter.updateOne(action.payload.partialComparativeLandUse, state);
        case ComparativeLandUseActionTypes.AllComparativeLandUsesLoaded:
            return adapter.addAll(action.payload.comparativeLandUses, {
                ...state, isAllComparativeLandUsesLoaded: true
            });
        case ComparativeLandUseActionTypes.ComparativeLandUsesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ComparativeLandUseActionTypes.ComparativeLandUsesPageLoaded:
            return adapter.addMany(action.payload.comparativeLandUses, {
                ...initialComparativeLandUsesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case ComparativeLandUseActionTypes.ComparativeLandUseDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case ComparativeLandUseActionTypes.ComparativeLandUseRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case ComparativeLandUseActionTypes.ComparativeLandUseDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case ComparativeLandUseActionTypes.ComparativeLandUseAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case ComparativeLandUseActionTypes.ComparativeLandUseDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case ComparativeLandUseActionTypes.ComparativeLandUseTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
