import {MapsAPILoader} from '@agm/core';
import {Location} from '@angular/common';
import {
    Component, HostListener, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone,
} from '@angular/core';
import {each} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {ControlContainer, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, of} from 'rxjs';
import {delay, startWith, take, takeUntil} from 'rxjs/operators';
import {
    AllGradesRequested,
    AllHandoverStandardsRequested,
    AllResidentialTypesRequested,
    Grade,
    ResidentialType,
    selectAllGrades,
    selectAllHandoverStandards,
    selectAllResidentialTypes,
    ExternalArea,
    AllExternalAreasRequested,
    selectAllExternalAreas, selectAllStandardMeasurements, AllStandardMeasurementsRequested, StandardMeasurement, FloorType, WindowType,
    AllWindowTypesRequested, AllFloorTypesRequested, selectAllFloorTypes, selectAllWindowTypes, AllUnitAreaMeasurementsRequested,
    selectAllUnitAreaMeasurements, UnitAreaMeasurement, OfficeLayout, selectAllOfficeLayouts, AllOfficeLayoutsRequested,
    CoordinateReferenceSystem, selectAllCoordinateReferenceSystems, AllCoordinateReferenceSystemsRequested, selectStandardMeasurementsForAc
} from '../../../../core/linked-tables';
import {currentUser, User} from 'src/app/core/mad-auth/mad-auth.store';
import {LayoutConfigService, SubheaderService} from '../../../../core/_base/layout';
import {LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
import {AppState} from '../../../../core/reducers';
import {
    AssetClassBuildingInformation,
    AssetClassSizeModel,
    ResidentialExternalAreaModel,
    AssetClassResidentialFacilityModel,
    AssetClassOfficeFacilityModel
} from '../../../../core/comparable';
import {
    AssetClassDetailModel, AssetClassesService, AssetClassLandUseModel, AssetClassModel,
    selectLastCreatedAssetClassDetailId
} from '../../../../core/asset_class';
import {AuditTrailParticipantModel} from '../../../../core/assignment';
import {skip} from 'rxjs/operators';
import {RateData} from '../_static-data/rate-data';
import {FloorRateModel, FloorRatingPicture} from '../../../../core/asset_class/_models/floor-rate.model';
import {UploadFileComponent} from '../../shared_components/upload-file/upload-file.component';
import {PolyLinesComponent} from '../../shared_components/land-parcel/poly-lines/poly-lines.component';
import {TenureConsiderationListComponent} from '../../shared_components/land-parcel/tenure-consideration/tenure-consideration-list/tenure-consideration-list.component';
import {LandAreaListComponent} from '../../shared_components/land-parcel/land-area/land-area-list/land-area-list.component';
import {LandUseComponent} from '../../shared_components/land-parcel/land-use/land-use.component';
import {ServicesInfrastructuresComponent} from '../../shared_components/land-parcel/services-infrastructures/services-infrastructures.component';
import {FacilitiesSubListComponent} from '../../shared_components/facilities/list/facilities-sub-list.component';
import {BuildingInformationComponent} from '../../shared_components/building-information/building-information.component';
import {
    AssetClassDetailOnServerCreated, AssetClassDetailOnServerUpdated,
} from '../../../../core/asset_class/_actions/asset-class-detail.actions';
import { AssetClassTenureModel, AssetClassConsiderationModel, AssetClassLandAreaComponentModel, AssetClassLandAreaModel } from '../../../../core/asset_class';
import {ParticipantListComponent} from '../../shared_components/participant/participant-list/participant-list.component';
import {
    CityModel,
    CountryModel,
    selectAllCountries,
    selectCitiesByCountryId,
    AllCountriesRequested,
    AllCitiesRequested
} from '../../../../core/admin';
import { environment } from 'src/environments/environment';
import { ImageViewerDialogComponent } from '../../shared_components/image_viewer/image-viewer.dialog.component';
import * as objectPath from 'object-path';
import { CountryEditComponent } from '../../admin-management/countries/country-edit/country-edit.component';
import { TargetPropertyRadioButtonValues } from 'src/app/core/asset_class/_models/RadioButtonValues';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { TimeFieldComponent } from '../../shared_components/inspection-details/time-field/time-field.component';
import { AssetClassDetailLandParcelModel } from 'src/app/core/asset_class/_models/asset-class-detail-land-parcel.model';
import { LpBuildingTabComponent } from '../../shared_components/lp-building-tab/lp-building-tab.component';
import { UploadVideoComponent } from '../../shared_components/upload-video/upload-video.component';
import { LinkListComponent } from '../../shared_components/links/link-list/link-list.component';
import { SizeModuleComponent } from '../../shared_components/size-module/size-module.component';
import { FloorAction } from '../../shared_components/size-module/models/floor-action.model';
import { LandParcelMapComponent } from '../../shared_components/land-parcel/land-parcel-map/land-parcel-map.component';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { PropertyObservationChecklistComponent } from '../../shared_components/property-observation-checklist/property-observation-checklist.component';
import { AcDocumentUploadComponent } from '../../shared_components/ac-document-upload/ac-document-upload.component';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { InspectionLandParcelComponent } from '../inspection-base/inspection-land-parcel/inspection-land-parcel.component';
import { InspectionDetailsComponent } from '../../shared_components/inspection-details/inspection-details.component';
import { ServicesAndInfrastructuresComponent } from '../../shared_components/services-and-infrastructures/services-and-infrastructures.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
    selector: 'kt-inspection-edit',
    templateUrl: './inspection-edit.component.html',
    styleUrls: ['./inspection-edit.component.scss'],
})

export class InspectionEditComponent implements OnInit, OnDestroy {
    @ViewChild(InspectionDetailsComponent, {static: false})
    inspectionDetailComponent: InspectionDetailsComponent;
    @ViewChild(ServicesAndInfrastructuresComponent, {static: false})
    servicesAndInfraComponent: ServicesAndInfrastructuresComponent;
    @ViewChild(UploadFileComponent)
    public uploadFileComponent: UploadFileComponent;

    @ViewChild(UploadVideoComponent) uploadVideoComponent: UploadVideoComponent;
    @ViewChild(LandParcelMapComponent, {static: false}) landParcelMapComponent: LandParcelMapComponent;

    @ViewChild('search', {static: false})
    public searchElementRef: ElementRef;
    private geoCoder = new google.maps.Geocoder();

    assetClass: AssetClassModel;
    assetClassDetail: AssetClassDetailModel;
    assetClassDetailForm: FormGroup;
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];
    currentUser: User;
    saveState: boolean;

    loading$: Observable<boolean>;

    // linked tables
    staticUnit: number;
    staticUnitName: string;
    staticUnitLengthName: string;
    grades: Grade[] = [];
    decisions = [
        {label: 'Desktop valuation', value: 1, default: true},
        {label: 'External Inspection', value: 2, default: false},
        {label: 'Internal inspection', value: 3, default: false},
        {label: 'Full property survey', value: 4, default: false}
    ];

    allCountries: CountryModel[] = [];
    cityOfCountry: CityModel[] = [];
    autoFillCity = '';


    buildingSubject = new BehaviorSubject<AssetClassBuildingInformation>(new AssetClassBuildingInformation());
    buildingListSubject = new BehaviorSubject<AssetClassBuildingInformation[]>([]);

    map: google.maps.Map;
    markerLat: BehaviorSubject<number> = new BehaviorSubject(null);
    markerLng: BehaviorSubject<number> = new BehaviorSubject(null);
    zoom = 15;
    isShown = true;
    centerLat = null;
    centerLng = null;
    currentCenter: { lat: 0, lng: 0 };

    protected _onDestroy = new Subject<void>();
    propertyTypes = PropertyTypes;
    /** list of models filtered by search keyword */

    // Workaround
    private mapClickListener: google.maps.MapsEventListener;

    // Condition rating data
    public conditionRatingDropdownValues$: ReplaySubject<{
        key: number,
        value: string,
    }[]>;
    public conditionRatingDropdownValues: {
        key: number,
        value: string,
    }[] = [
        {
            key: 0,
            value: 'Not applicable'
        }
    ];
    public conditionRatingRelated$: Subject<{
        key: number,
        value: string,
        path: string,
        name: string,
        title: string,
    }>;
    basePath = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=`;

    hasTitleRequiredError: boolean = false;
    showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly = false;

    // Land Parcel
    @ViewChild(PolyLinesComponent, {static: false}) private polyLinesComponent: PolyLinesComponent;
    @ViewChild(TenureConsiderationListComponent, {static: false}) public tenureConsiderationListComponent: TenureConsiderationListComponent;
    @ViewChild(LandAreaListComponent, {static: false}) private landAreaListComponent: LandAreaListComponent;
    @ViewChild(LandUseComponent, {static: false}) private landUseComponent: LandUseComponent;
    @ViewChild(ServicesInfrastructuresComponent, {static: false}) private servicesInfrastructuresComponent: ServicesInfrastructuresComponent;
    @ViewChild(LpBuildingTabComponent) private lpBuildingTabComponent: LpBuildingTabComponent;
    @ViewChild('lpUploadFile', { static: false }) lpUploadFile: UploadFileComponent;
    @ViewChild(SizeModuleComponent, {static: false}) sizesModuleComponent: SizeModuleComponent;
    @ViewChild(PropertyObservationChecklistComponent, {static: false}) checkListComponent: PropertyObservationChecklistComponent;
    @ViewChild(AcDocumentUploadComponent, {static: false}) documentUploadComponent: AcDocumentUploadComponent;
    coordinateReferenceSystems: CoordinateReferenceSystem[] = [];
    lat = this.centerLat;
    lng = this.centerLng;
    drawingManager: any;
    selectedShape: any;
    landUseSubject = new BehaviorSubject<AssetClassLandUseModel>(new AssetClassLandUseModel());
    buildingsSubject = new BehaviorSubject<AssetClassBuildingInformation[]>([]);

    considerationListSubject = new BehaviorSubject<AssetClassConsiderationModel[]>([]);
    tenureSubject = new BehaviorSubject<AssetClassTenureModel>(new AssetClassTenureModel());
    landAreaComponentListSubject = new BehaviorSubject<AssetClassLandAreaComponentModel[]>([]);
    landAreaSubject = new BehaviorSubject<AssetClassLandAreaModel>(new AssetClassLandAreaModel());

    videoTitleRequiredError$ = new BehaviorSubject(false);

    // Tabs
    tabHeaders: TabHeader[] = [
        {label: 'Inspection', disabled: of(false)},
        {label: 'Location & Land identification', disabled: of(false)},
        {label: 'Property Information', disabled: of(false)},
        {label: 'Land tenure', disabled: of(false)},
        {label: 'Pictures & documents', disabled: of(false)}
    ]
    selectedTab = 0;
    selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
    selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
    onTabChange(index: number) {
        this.selectedTab = index;
    }
    onTabChanged(event) {
        if (event.index == 1 && this.landParcelMapComponent) {
            this.landParcelMapComponent.addRemoveController();
        }
    }

    // Errors
    hasFormErrors = false;
    error:any = {
        msg: ' Missing Fields in total:',
        fields: []
    }
    erroTabMap() {
        return this.typesUtilsService.getInspectionTabErrorMap(this.error.fields, this.tabHeaders);
    }
    onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
        this.hasFormErrors = obj.hasFormErrors;
    }

    // InspectionDetail
    inspectionDetailData: {
        information_date: string;
        source_of_information: string;
        inspection_date: string;
        time_of_inspection: string;
        duration_of_inspection: string;
        any_limitations_or_restrictions: boolean;
        limitation_desc: string;
        inspection_note: string;
        record_potential_env_issues: boolean;
    }
    toeSelected: BehaviorSubject<boolean> = new BehaviorSubject(true);
    agencyName: BehaviorSubject<string> = new BehaviorSubject(null);
    clientName: BehaviorSubject<string> = new BehaviorSubject(null);
    agencyId$ = new BehaviorSubject<number>(null);
    toeId$ = new BehaviorSubject<number>(null);
    clientId$ = new BehaviorSubject<number>(null);
    participantsSubject = new BehaviorSubject<AuditTrailParticipantModel[]>([]);

    // LandParcel
    points: {lat: number, lng: number}[] = [];


    // TODO
    components$ = of([]);
    conditionRatings$ = of([]);
    schemeId$ = of(1);
    /**
     * Component constructor
     *
     * @param assetClassDetailsService: AssetClassDetailsService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param dialog: Dialog
     * @param mapsAPILoader: MapsAPILoader
     * @param layoutConfigService: LayoutConfigService
     * @param ngZone: NgZone
     */
    constructor(public assetClassDetailsService: AssetClassesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                public dialog: MatDialog,
                private mapsAPILoader: MapsAPILoader,
                private layoutConfigService: LayoutConfigService,
                private ngZone: NgZone,
                private translate: TranslateService,
                private readonlyService: ReadonlyService,
                private subDomainService: SubdomainService) {
        this.saveState = false;
    }

    ngOnInit() {
        this.readonly = this.readonlyService.isReadOnly();

        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.asset_class_id;
            if (id && id > 0) {
                this.activatedRoute.data.subscribe(res => {
                    this.agencyName.next(res.toeData.data.agency_name);
                    this.clientName.next(res.toeData.data.client_name);
                    this.agencyId$.next(0);
                    this.clientId$.next(res.toeData.data.client_id);
                    this.toeId$.next(res.toeData.data.id);

                    this.assetClass = res.assetClassData.data as AssetClassModel;

                    if (res.toeData.data.unit_of_measurement_id == 1) {
                        this.staticUnit = 2;
                        this.staticUnitName = 'sqft';
                        this.staticUnitLengthName = 'Ft';
                    } else {
                        this.staticUnit = 1;
                        this.staticUnitName = 'sqm';
                        this.staticUnitLengthName = 'M';
                    }

                    const _acSize = new AssetClassSizeModel();
                    _acSize.standard_measurement_id = this.assetClass.standard_measurement_id;
                    _acSize.standard_measurement_name = this.assetClass.standard_measurement_name;
                    _acSize.unit_of_area_measurement_id = this.staticUnit;
                    _acSize.unit_of_area_measurement_name = this.staticUnitName;
                    _acSize.size = this.assetClass.surface;

                    if (res.assetClassDet.new) {
                        this.isShown = false;
                        this.assetClassDetail = new AssetClassDetailModel();
                        this.assetClassDetail.clear();
                        this.assetClassDetail.floor_details = this.assetClass.floors_valuated;
                        
                        // If inspection type is "Internal inspection" or "Full property survey"
                        // and has floor details add ratings
                        this.assetClassDetail.zip_code = this.assetClass.zip_code;
                        this.assetClassDetail.address = this.assetClass.address;
                        this.assetClassDetail.country_id = this.assetClass.country_id;
                        this.assetClassDetail.city_id = this.assetClass.city_id;
                    } else {
                        this.assetClassDetail = Object.assign({}, res.assetClassDet.data) as AssetClassDetailModel;
                        if (this.assetClassDetail.latitude) {
                            this.markerLat.next( Number(this.assetClassDetail.latitude));
                            this.markerLng.next( Number(this.assetClassDetail.longitude));
                            this.centerLat = this.markerLat.value;
                            this.centerLng = this.markerLng.value;
                        }

                        this.participantsSubject.next(res.subData.participants);
                    }


                    this.inspectionDetailData = {
                        information_date: this.assetClassDetail.information_date,
                        source_of_information: this.assetClassDetail.source_of_info,
                        inspection_date: this.assetClassDetail.inspection_date,
                        time_of_inspection: this.assetClassDetail.time_of_inspection,
                        duration_of_inspection: this.assetClassDetail.duration_of_inspection,
                        any_limitations_or_restrictions: this.assetClassDetail.any_limitations_or_restrictions,
                        limitation_desc: this.assetClassDetail.limitations_desc,
                        inspection_note: this.assetClassDetail.inspection_note,
                        record_potential_env_issues: this.assetClassDetail.record_potential_env_issues
                    }
                    this.points = this.assetClassDetail.land_parcel.ac_detail_lp_path.map(el => {
                        return {
                            lat: Number(el.lat),
                            lng: Number(el.lng)
                        };
                    })
                    this.landAreaComponentListSubject.next(this.assetClassDetail.land_parcel.ac_detail_land_area);
                    this.landAreaSubject.next(this.assetClassDetail.land_parcel);

                    this.buildingSubject.next(this.assetClassDetail.building_information);
                    this.tenureSubject.next(this.assetClassDetail.land_parcel);
                    this.landUseSubject.next(this.assetClassDetail.land_parcel);
                    this.considerationListSubject.next(this.assetClassDetail.land_parcel.ac_detail_consideration);
                    this.buildingsSubject.next(Object.assign([], this.assetClassDetail.land_parcel.ac_detail_lp_buildings));
                    this.createForm();
                });
            }
        });
        this.subscriptions.push(routeSubscription);

        this.store.dispatch(new AllCountriesRequested());
        this.store.dispatch(new AllCitiesRequested());
        const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
            this.allCountries = [];
            each(res, (_country: CountryModel) => {
                this.allCountries.push(_country);
            });
            this.assetClassDetailForm.controls.country_id.setValue(this.assetClass.country_id);
        });
        this.subscriptions.push(countrySubscription);

        const citySubscription = this.store
            .pipe(select(selectCitiesByCountryId(this.assetClass.country_id))).subscribe((res: CityModel[]) => {
                this.cityOfCountry = [];
                each(res, (_city: CityModel) => {
                    this.cityOfCountry.push(_city);
                });
                this.assetClassDetailForm.controls.city_id.setValue(this.assetClass.city_id);
            });
        this.subscriptions.push(citySubscription);


        // load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder();

            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ['(cities)']
            });
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
                        this.centerLat = 47.91868658952473;
                        this.centerLng = 106.91766668255616;
                        this.zoom = 15;
                    } else {
                        // set latitude, longitude and zoom
                        this.centerLat = place.geometry.location.lat();
                        this.centerLng = place.geometry.location.lng();
                        this.zoom = 12;
                    }
                });
            });
        });
    }

    mapRightClick($event: google.maps.MouseEvent) {
        this.markerLat.next( $event.latLng.lat());
        this.markerLng.next( $event.latLng.lng());

        this.centerLat = $event.latLng.lat();
        this.centerLng = $event.latLng.lng();
        this.assetClassDetailForm.controls.latitude.patchValue($event.latLng.lat());
        this.assetClassDetailForm.controls.longitude.patchValue($event.latLng.lng());

        this.isShown = true;
        const latLng = new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng());
        this.geoCoder.geocode({location: {lat: latLng.lat(), lng: latLng.lng()}}, (results, status) => {
            if (status && results.length > 1) {
                // this.cityOfLocation = results.length > 1 ? results[results.length - 2].formatted_address : results[0].formatted_address;
                this._setAutoFillCountry(results.reverse()[0].formatted_address);
            } else {
                // window.alert('No results found');
            }
        })
    }
    private _setAutoFillCountry(address) {
        const addressData = address.split(',').reverse();
        if (addressData.length >= 1) {
            if (this.allCountries.length !== 0) {
                const _country = this.allCountries.find(el => el.name.toLowerCase() == addressData[0].trim().toLowerCase());
                // this.autoFillCity = addressData[0].trim();
                if (_country) {
                    this.assetClassDetailForm.controls.country_id.setValue(_country.id);
                    this.selectCountry();
                } else {
                    this._showError(addressData[0].trim());
                }
            }
        }
    }
  private _showError(country: string) {
      const dialogRef = this.layoutUtilsService.showErrorDialg(
          'Country not found', 
          `Selected country "${country}" is not in the list`,
          'Add country');
      const sub = dialogRef.afterClosed().subscribe(res => {
          if (!res) {
              this.assetClassDetailForm.controls.country_id.setValue(undefined);
              return;
          }
          if (res.proceedAction) {
              this.addCountry(country);
          }
      });
      this.subscriptions.push(sub);
  }

    mapReady(event: any) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

        this.map.addListener('dragend', () => {
            this.centerLat = this.currentCenter.lat;
            this.centerLng = this.currentCenter.lng;
        });

        // Workaround
        this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
            this.ngZone.run(() => {
                this.mapRightClick(e);
            })
        })
    }

    markerDragEnd($event: google.maps.MouseEvent) {
        this.markerLat.next( $event.latLng.lat());
        this.markerLng.next( $event.latLng.lng());

        this.centerLat = $event.latLng.lat();
        this.centerLng = $event.latLng.lng();
        this.assetClassDetailForm.controls.latitude.patchValue($event.latLng.lat());
        this.assetClassDetailForm.controls.longitude.patchValue($event.latLng.lng());
    }

    centerChange($event: any) {
        if ($event) {
            this.currentCenter = {lat: $event.lat, lng: $event.lng};
        }
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
        // Workaround
        if (this.mapClickListener) {
            this.mapClickListener.remove();
        }
        // ----------
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    createForm() {
        this.assetClassDetailForm = this.fb.group({
            information_date: [this.assetClassDetail.information_date],
            source_of_info: [this.assetClassDetail.source_of_info],

            time_of_inspection: [new Date(this.assetClassDetail.time_of_inspection)],
            duration_of_inspection: [readableDuration(this.assetClassDetail.duration_of_inspection)],
            inspection_note: [this.assetClassDetail.inspection_note],
            country_id: [{value: this.assetClassDetail.country_id, disabled: true}],
            city_id: [this.assetClassDetail.city_id, Validators.required],
            zip_code: [this.assetClassDetail.zip_code],
            address: [this.assetClassDetail.address],
            location_grade_id: [this.assetClassDetail.location_grade_id, Validators.required],
            time_offset: [this.assetClassDetail.locationData.time_offset],

            floor_details: [
                this.assetClassDetail.floor_details &&
                this.assetClassDetail.floor_details.length > 0 ?
                    this.assetClassDetail.floor_details.split(',') : ''
            ],
            residential_type_id: [this.assetClassDetail.residential_type_id],
            office_layout_id: [this.assetClassDetail.office_layout_id],
            bedroom: [this.assetClassDetail.bedroom],
            room: [this.assetClassDetail.room],
            bathroom: [
                this.assetClassDetail.bathroom &&
                this.assetClassDetail.bathroom.toString() === 'N/A' ? null : this.assetClassDetail.bathroom
            ],
            floor_type_id: [this.assetClassDetail.floor_type_id],
            window_type_id: [this.assetClassDetail.window_type_id],
            indoor_garage_places: [this.assetClassDetail.indoor_garage_places],
            outdoor_garage_places: [this.assetClassDetail.outdoor_garage_places],
            handover_standard_id: [this.assetClassDetail.handover_standard_id],
            handover_standard_description: [this.assetClassDetail.handover_standard_description],
            unit_description: [this.assetClassDetail.unit_description],
            flooring_type_comment: [this.assetClassDetail.flooring_type_comment],
            windows_type_comment: [this.assetClassDetail.windows_type_comment],
            tenancy: [this.assetClassDetail.tenancy, Validators.required],
            has_facility: [this.assetClassDetail.has_facility, Validators.required],
            has_exclusive_facility: [this.assetClassDetail.has_exclusive_facility],

            // location field
            surrounding_description: [this.assetClassDetail.surrounding_description, Validators.required],
            latitude: [this.assetClassDetail.latitude, Validators.required],
            longitude: [this.assetClassDetail.longitude, Validators.required],

            // inspection details
            inspection_date: [this.assetClassDetail.inspection_date],
            any_limitations_or_restrictions: [this.assetClassDetail.any_limitations_or_restrictions],
            limitations_desc: [this.assetClassDetail.limitations_desc],


            measurement_standard_id: [this.assetClassDetail.measurement_standard_id ? this.assetClassDetail.measurement_standard_id  : this.assetClass.standard_measurement_id],

            //land_parcel
            name: [this.assetClassDetail.land_parcel.name],
            coordinate_reference_system_id: [this.assetClassDetail.land_parcel.coordinate_reference_system_id],

            sub_type_category_id: [this.assetClassDetail.sub_type_category_id],

            record_potential_env_issues: [this.assetClassDetail.record_potential_env_issues],
            record_info_type: [this.assetClassDetail.record_info_type]
        });

        if (this.assetClass.type_of_inspection != 1) {
            this.assetClassDetailForm.controls.any_limitations_or_restrictions.setValidators(Validators.required);
        }
        if (this.assetClass.type_of_inspection == 1) {
            this.assetClassDetailForm.controls.information_date.setValidators(Validators.required);
            this.assetClassDetailForm.controls.source_of_info.setValidators(Validators.required);
        }

        if (this.assetClass.instructed_to_measure == 1) {
            this.assetClassDetailForm.controls.measurement_standard_id.setValidators(Validators.required);
        }

        if (this.assetClass.type_id == 1) {
            this.assetClassDetailForm.controls.room.setValidators(Validators.required);
            this.assetClassDetailForm.controls.bedroom.setValidators(Validators.required);
        } else if (this.assetClass.type_id == 3) {
            this.assetClassDetailForm.controls.office_layout_id.setValidators(Validators.required);
            this.assetClassDetailForm.controls.has_exclusive_facility.setValidators(Validators.required);
            this.assetClassDetailForm.controls.has_exclusive_facility.updateValueAndValidity();

            this.assetClassDetailForm.controls.office_layout_id.valueChanges.subscribe(value => {
                if (value === 1) {
                    this.assetClassDetailForm.controls.room.setValidators([Validators.required]);
                } else {
                    if (this.assetClassDetail && this.assetClassDetail.id) {
                        this.assetClassDetailForm.controls.room.setValue(this.assetClassDetail.room);
                    } else {
                        this.assetClassDetailForm.controls.room.setValue('');
                    }
                    this.assetClassDetailForm.controls.room.clearValidators();
                }
                this.assetClassDetailForm.controls.room.updateValueAndValidity();
            });
        } else if (this.assetClass.top_property_type_id == PropertyTypes.Land) {
            // do land parcel stuff
            this.assetClassDetailForm.controls.name.setValidators(Validators.required);
            this.assetClassDetailForm.controls.coordinate_reference_system_id.setValidators(Validators.required);
        }


        this.assetClassDetailForm.updateValueAndValidity();
        this.fetchSelects();
        this.changeValidator(true);
    }

    get has_facility() {
        return this.assetClassDetailForm.get('has_facility');
    }
    get has_exclusive_facility() {
        return this.assetClassDetailForm.get('has_exclusive_facility');
    }

    fetchSelects() {
        const userSubscription = this.store.pipe(
            take(1),
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                }
            });
        this.subscriptions.push(userSubscription);

        this.store.dispatch(new AllGradesRequested());

        const gradeSubscription = this.store.pipe(
            select(selectAllGrades))
            .subscribe(res => {
                this.grades = [];
                if (res) {
                    this.grades = res;
                }
            });

        this.subscriptions.push(gradeSubscription);


        this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
        const coordinateReferenceSystemsSubscription = this.store.pipe(
            select(selectAllCoordinateReferenceSystems))
            .subscribe(res => {
                this.coordinateReferenceSystems = [];
                if (res) {
                    this.coordinateReferenceSystems = res;
                }
            });

        this.subscriptions.push(coordinateReferenceSystemsSubscription);
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (!this.assetClass) {
            return '';
        }
        if (this.assetClass.type_of_inspection > 0) {
            return `Property Sub-Type ${this.assetClass.type_name} - ${this.decisions[this.assetClass.type_of_inspection - 1].label} Details for "${this.assetClass.name}" ${this.readonly ? '(READ ONLY)' : ''}`;
        }
        return `Property Sub-Type ${this.assetClass.type_name} Details ${this.readonly ? '(READ ONLY)' : ''}`;
    }

    back() {
        this.location.back();
    }

    /**
     * On Submit
     */
    onSubmit(isComplete: boolean) {
        let participants = null;
        let errorFields = [];
        this.hasFormErrors = false;
        this.hasTitleRequiredError = false;
        const controls = this.assetClassDetailForm.controls;
        this.changeValidator(isComplete);

        if (this.inspectionDetailComponent) {
            if (isComplete && !this.inspectionDetailComponent.checkValidation(isComplete)) {
                this.hasFormErrors = true;
                errorFields = [...errorFields, ...this.inspectionDetailComponent.errorFields];
            }
        }
        /** check form */
        if (this.assetClassDetailForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            
            this.hasFormErrors = true;
        }

        if (isComplete && this.uploadFileComponent.uploadFiles.length < 1) {
            this.hasFormErrors = true;
        } else if (isComplete) {
            const uploadedFiles = this.uploadFileComponent.uploadFiles;
            uploadedFiles.forEach(file => {
                if (file.title === null || file.title === '') {
                    this.hasFormErrors = true;
                    this.hasTitleRequiredError = true;
                }
            })
        }
        this.showTitleRequiredError$.next(this.hasTitleRequiredError);

        let videoTitleRequired = false;
        if (isComplete && this.uploadVideoComponent) {
            const videos = this.uploadVideoComponent.uploadedVideos;
            videos.forEach(video => {
                if (video.title === null || video.title === '') {
                    this.hasFormErrors = true;
                    videoTitleRequired = true;
                }
            })
        }
        this.videoTitleRequiredError$.next(videoTitleRequired);

        if (this.hasFormErrors) {
            this.changeValidator(true);
            return;
        }

        this.tenureConsiderationListComponent && this.tenureConsiderationListComponent.updateValue(isComplete);
        this.landAreaListComponent && this.landAreaListComponent.updateValue(isComplete);
        this.landUseComponent && this.landUseComponent.updateValue(isComplete);
        this.servicesInfrastructuresComponent && this.servicesInfrastructuresComponent.updateValue(isComplete);
        const editedAssetClassDetail = this.prepareAssetClassDetail({isComplete});
        editedAssetClassDetail.participants = participants;
        if (this.assetClassDetail.id) {
            this.updateAssetClassDetail(editedAssetClassDetail);
        } else {
            this.createAssetClassDetail(editedAssetClassDetail);
        }
    }


    private changeValidator(isComplete: boolean = false) {
        if (isComplete) {
            if (this.assetClass.top_property_type_id !== PropertyTypes.Land) {
                this.assetClassDetailForm.controls.floor_details.setValidators(Validators.required);
                this.assetClassDetailForm.controls.floor_type_id.setValidators(Validators.required);
                this.assetClassDetailForm.controls.window_type_id.setValidators(Validators.required);
                this.assetClassDetailForm.controls.indoor_garage_places.setValidators(Validators.required);
                this.assetClassDetailForm.controls.outdoor_garage_places.setValidators(Validators.required);
                this.assetClassDetailForm.controls.handover_standard_id.setValidators(Validators.required);
                this.assetClassDetailForm.controls.bathroom.setValidators(Validators.required);
                this.assetClassDetailForm.controls.tenancy.setValidators(Validators.required);
            }

            this.assetClassDetailForm.controls.address.setValidators(Validators.required);
            this.assetClassDetailForm.controls.location_grade_id.setValidators(Validators.required);

            this.assetClassDetailForm.controls.surrounding_description.setValidators(Validators.required);

            this.assetClassDetailForm.controls.latitude.setValidators(Validators.required);

            this.assetClassDetailForm.controls.longitude.setValidators(Validators.required);

            this.assetClassDetailForm.controls.city_id.setValidators(Validators.required);

            // inspection details
            if (this.assetClass.type_of_inspection != 1) {
                this.assetClassDetailForm.controls.any_limitations_or_restrictions.setValidators(Validators.required);
            }

            if (this.assetClass.instructed_to_measure == 1) {
                this.assetClassDetailForm.controls.measurement_standard_id.setValidators(Validators.required);
            }
        } else {

            this.assetClassDetailForm.controls.address.setValidators(null);
            this.assetClassDetailForm.controls.location_grade_id.setValidators(null);
            this.assetClassDetailForm.controls.surrounding_description.setValidators(null);
            this.assetClassDetailForm.controls.latitude.setValidators(null);
            this.assetClassDetailForm.controls.longitude.setValidators(null);

            this.assetClassDetailForm.controls.floor_details.setValidators(null);
            this.assetClassDetailForm.controls.office_layout_id.setValidators(null);
            this.assetClassDetailForm.controls.bedroom.setValidators(null);
            this.assetClassDetailForm.controls.room.setValidators(null);
            this.assetClassDetailForm.controls.bathroom.setValidators(null);
            this.assetClassDetailForm.controls.floor_type_id.setValidators(null);
            this.assetClassDetailForm.controls.flooring_type_comment.setValidators(null);
            this.assetClassDetailForm.controls.window_type_id.setValidators(null);
            this.assetClassDetailForm.controls.windows_type_comment.setValidators(null);
            this.assetClassDetailForm.controls.indoor_garage_places.setValidators(null);
            this.assetClassDetailForm.controls.outdoor_garage_places.setValidators(null);
            this.assetClassDetailForm.controls.handover_standard_description.setValidators(null);
            this.assetClassDetailForm.controls.handover_standard_id.setValidators(null);
            this.assetClassDetailForm.controls.unit_description.setValidators(null);
            this.assetClassDetailForm.controls.tenancy.setValidators(null);

            // inspection details
            this.assetClassDetailForm.controls.any_limitations_or_restrictions.setValidators(null);
            this.assetClassDetailForm.controls.limitations_desc.setValidators(null);

            this.assetClassDetailForm.controls.information_date.setValidators(null);
            this.assetClassDetailForm.controls.source_of_info.setValidators(null);

            this.assetClassDetailForm.controls.measurement_standard_id.setValidators(null);

        }
        this.assetClassDetailForm.updateValueAndValidity();
        Object.keys(this.assetClassDetailForm.controls).forEach(controlName => {
            this.assetClassDetailForm.controls[controlName].updateValueAndValidity({
                emitEvent: false
            });
        });
    }

    /**
     * Returns prepared model
     */
    prepareAssetClassDetail(input: {isComplete: boolean, checkValueChange?: boolean} = {isComplete: false, checkValueChange: false}): AssetClassDetailModel {
        const controls = this.assetClassDetailForm.controls;
        const _assetClassDetail = new AssetClassDetailModel();
        _assetClassDetail.clear();
        _assetClassDetail.id = this.assetClassDetail.id;
        _assetClassDetail.tp_id = this.assetClass.id;
        _assetClassDetail.is_complete = Number(input.isComplete);
        _assetClassDetail.user_id = this.currentUser.id;
        _assetClassDetail.floor_details = controls.floor_details.value.length > 0 ? controls.floor_details.value.join(',') : '';
        _assetClassDetail.residential_type_id = input.checkValueChange ? controls.residential_type_id.value : this.setNullWhenZero(controls.residential_type_id.value);
        _assetClassDetail.office_layout_id = input.checkValueChange ? controls.office_layout_id.value : this.setNullWhenZero(controls.office_layout_id.value);
        _assetClassDetail.bedroom = controls.bedroom.value;
        _assetClassDetail.room = controls.room.value;
        _assetClassDetail.bathroom = controls.bathroom.value;

        _assetClassDetail.has_facility = controls.has_facility.value;
        _assetClassDetail.has_exclusive_facility = controls.has_exclusive_facility.value;
        _assetClassDetail.floor_type_id = input.checkValueChange ? controls.floor_type_id.value : this.setNullWhenZero(controls.floor_type_id.value);
        _assetClassDetail.window_type_id = input.checkValueChange ? controls.window_type_id.value : this.setNullWhenZero(controls.window_type_id.value);

        _assetClassDetail.flooring_type_comment = controls.flooring_type_comment.value;
        _assetClassDetail.windows_type_comment = controls.windows_type_comment.value;
        _assetClassDetail.tenancy = controls.tenancy.value;

        _assetClassDetail.location_grade_id = input.checkValueChange ? controls.location_grade_id.value : this.setNullWhenZero(controls.location_grade_id.value);
        _assetClassDetail.indoor_garage_places = controls.indoor_garage_places.value;
        _assetClassDetail.outdoor_garage_places = controls.outdoor_garage_places.value;
        _assetClassDetail.unit_description = controls.unit_description.value;
        _assetClassDetail.handover_standard_id = input.checkValueChange ? controls.handover_standard_id.value : this.setNullWhenZero(controls.handover_standard_id.value);
        _assetClassDetail.handover_standard_description = controls.handover_standard_description.value;

        _assetClassDetail.latitude = controls.latitude.value;
        _assetClassDetail.longitude = controls.longitude.value;


        _assetClassDetail.country_id = controls.country_id.value;
        _assetClassDetail.city_id = input.checkValueChange ? controls.city_id.value : this.setNullWhenNegOne(controls.city_id.value);
        _assetClassDetail.zip_code = controls.zip_code.value;

        _assetClassDetail.address = controls.address.value;
        _assetClassDetail.surrounding_description = controls.surrounding_description.value;
        _assetClassDetail.sub_type_category_id = controls.sub_type_category_id.value;

        let informationDate = new Date(controls.information_date.value);
        _assetClassDetail.information_date = controls.information_date.value 
            ? this.typesUtilsService.getDateStringFromDate(informationDate)
            : null;
        _assetClassDetail.source_of_info = controls.source_of_info.value;


        _assetClassDetail.building_information = this.buildingSubject.value;
        if (this.assetClass.top_property_type_id == PropertyTypes.Land && this.lpBuildingTabComponent) {
            const building = new AssetClassBuildingInformation();
            this.lpUploadFile.res$.pipe(take(1)).subscribe(value => {
                if (value) {
                    building.path = value.success;
                }
            });
            building.files = this.lpUploadFile.uploadFiles;
            _assetClassDetail.building_information = building;
        }

        this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
            if (value) {
                _assetClassDetail.picture = value.success;
            }
        });


        // inspection detail
        let _date = new Date(controls.inspection_date.value);
        _assetClassDetail.inspection_date = controls.inspection_date.value ? this.typesUtilsService.getDateStringFromDate(_date) : null;
        _assetClassDetail.time_of_inspection = controls.time_of_inspection.value;
        _assetClassDetail.duration_of_inspection = durationFromReadable(controls.duration_of_inspection.value);

        _assetClassDetail.any_limitations_or_restrictions = controls.any_limitations_or_restrictions.value;
        if (_assetClassDetail.any_limitations_or_restrictions) {
            _assetClassDetail.limitations_desc = controls.limitations_desc.value;
        } else {
            _assetClassDetail.limitations_desc = '';
        }
        _assetClassDetail.inspection_note = controls.inspection_note.value;


        // purpose measurement information


        _assetClassDetail.measurement_standard_id = controls.measurement_standard_id.value;

        //land parcel
        _assetClassDetail.land_parcel.name = controls.name.value;
        _assetClassDetail.land_parcel.coordinate_reference_system_id = controls.coordinate_reference_system_id.value;
        _assetClassDetail.land_parcel.ac_detail_lp_path = this.landParcelMapComponent ? this.landParcelMapComponent.getPath() : [];
        //tenure & consideration
        _assetClassDetail.land_parcel.ac_detail_consideration = this.considerationListSubject.value;
        _assetClassDetail.land_parcel.current_occupier = this.tenureSubject.value.current_occupier;
        _assetClassDetail.land_parcel.previous_occupier = this.tenureSubject.value.previous_occupier;
        _assetClassDetail.land_parcel.rrr_over_land = this.tenureSubject.value.rrr_over_land;
        _assetClassDetail.land_parcel.additional_rrr_over_land = this.tenureSubject.value.additional_rrr_over_land;
        _assetClassDetail.land_parcel.difference_between_boundaries = this.tenureSubject.value.difference_between_boundaries;
        //land area
        _assetClassDetail.land_parcel.planning_status_id = this.landAreaSubject.value.planning_status_id;
        _assetClassDetail.land_parcel.ac_detail_land_area = this.landAreaComponentListSubject.value;
        //land use
        _assetClassDetail.land_parcel.land_cover_type = this.landUseSubject.value.land_cover_type;
        _assetClassDetail.land_parcel.qos_degradation_id = this.landUseSubject.value.qos_degradation_id;
        _assetClassDetail.land_parcel.comparative_land_use_id = this.landUseSubject.value.comparative_land_use_id;
        _assetClassDetail.land_parcel.sol_use_classification_id = this.landUseSubject.value.sol_use_classification_id;
        _assetClassDetail.land_parcel.other_characteristics = this.landUseSubject.value.other_characteristics;
        _assetClassDetail.land_parcel.land_use_approval_implementation = this.landUseSubject.value.land_use_approval_implementation;
        _assetClassDetail.land_parcel.building_exist = this.landUseSubject.value.building_exist;
        _assetClassDetail.land_parcel.building_count = this.landUseSubject.value.building_count;
        _assetClassDetail.land_parcel.ac_detail_lp_buildings = this.buildingsSubject.value;

        if (this.uploadVideoComponent) {
            _assetClassDetail.videos = this.uploadVideoComponent.uploadedVideos;
        }

        _assetClassDetail.record_potential_env_issues = controls.record_potential_env_issues.value;
        _assetClassDetail.record_info_type = controls.record_info_type.value;

        _assetClassDetail.observation_checklist = this.checkListComponent ? this.checkListComponent.getData() : null;
        _assetClassDetail.observation_documents = this.documentUploadComponent ? this.documentUploadComponent.getDocuments() : [];

        _assetClassDetail.locationData = {
            country_id: controls.country_id.value,
            city_id: controls.city_id.value,
            zip_code: controls.zip_code.value,
            location_grade_id: controls.location_grade_id.value,
            latitude: controls.latitude.value,
            longitude: controls.longitude.value,
            time_offset: controls.time_offset.value, 
            address: controls.address.value,
            location_surrounding: controls.surrounding_description.value,
            city_of_location: '',
        }

        if (this.inspectionDetailComponent) {
            const data = this.inspectionDetailComponent.getData();
            _assetClassDetail.information_date = data.information_date;
            _assetClassDetail.source_of_info = data.source_of_information;
            _assetClassDetail.inspection_date = data.inspection_date;
            _assetClassDetail.time_of_inspection = data.time_of_inspection;
            _assetClassDetail.duration_of_inspection = data.duration_of_inspection;
            _assetClassDetail.any_limitations_or_restrictions = data.any_limitations_or_restrictions;
            _assetClassDetail.limitations_desc = data.limitation_desc;
            _assetClassDetail.inspection_note = data.inspection_note;
            _assetClassDetail.record_potential_env_issues = this.assetClassDetailForm.controls.record_potential_env_issues.value;

        }

        if (this.servicesAndInfraComponent) {
            _assetClassDetail.serviceAndInfraData = this.servicesAndInfraComponent.getdata();
        }

        return _assetClassDetail;
    }

    setNullWhenZero(value: any) {
        if (value === undefined || value === '0' || value === 0 || value === -1) {
            return null;
        } else {
            return value;
        }
    }
    setNullWhenNegOne(value: any) {
        if (value === -1) {
            return null;
        }
        return value;
    }

    /**
     * Create assignment
     *
     * @param _assetClassDetail: AssetClassDetailModel
     */
    createAssetClassDetail(_assetClassDetail: AssetClassDetailModel) {

        // Participants
        // Rate
        let _participants = [];
        if (this.inspectionDetailComponent) {
            _participants = this.inspectionDetailComponent.getParticipants();
        }

        let _ratings = [];
        let sizeModuleData = null;
        if (this.sizesModuleComponent) {
            sizeModuleData = this.sizesModuleComponent.getData();
        }

        this.store.dispatch(new AssetClassDetailOnServerCreated({
            assetClass: _assetClassDetail,
            sizes: [],
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : [],
            selectedFacilities: [],
            selectedFacilitiesOffice: [],
            selectedFacilitiesOfficeExclusive: [],
            externalAreas: [],
            ratings: _ratings,
            participants: _participants,
            userId: this.currentUser.id,
            sizeModuleData
        }));

        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedAssetClassDetailId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.saveState = true;
            this.navToList();
        });
    }

    /**
     * Update assignment
     *
     * @param _assetClassDetail: AssetClassDetailModel
     */
    updateAssetClassDetail(_assetClassDetail: AssetClassDetailModel) {

        let _participants = [];
        if (this.inspectionDetailComponent) {
            _participants = this.inspectionDetailComponent.getParticipants();
        }
        let _ratings = [];

        let sizeModuleData = null;
        if (this.sizesModuleComponent) {
            sizeModuleData = this.sizesModuleComponent.getData();
        }

        this.store.dispatch(new AssetClassDetailOnServerUpdated({
            assetClass: _assetClassDetail,
            sizes: [],
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : [],
            selectedFacilities: [],
            selectedFacilitiesOffice: [],
            selectedFacilitiesOfficeExclusive: [],
            externalAreas: [],
            ratings: _ratings,
            participants: _participants,
            userId: this.currentUser.id,
            sizeModuleData
        }));
        this.saveState = true;
        this.navToList();
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        const assetClassDetail = this.prepareAssetClassDetail({isComplete: false, checkValueChange: true});
        if (!this.saveState && assetClassDetail.fieldDifference(this.assetClassDetail)) {
            return true;
        }
        return false;
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander($event) {
        if (this.discard()) {
            $event.preventDefault();
            $event.returnValue = 'test';
        }
    }

    /**
     * Alert close event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    isFormValid() {
        return true;
    }

    openTimeField(event) {
        const durationOfInspectionCtrl = this.assetClassDetailForm.controls.duration_of_inspection;
        const dialog = this.dialog.open(TimeFieldComponent, {
            data: {
                trigger: new ElementRef(event.currentTarget),
                duration: durationFromReadable(durationOfInspectionCtrl.value)
            },
            width: '18.5em',
            backdropClass: 'map-backdrop'
        });
        dialog.afterClosed().subscribe(val => {
            if (!val) {
                return;
            }
            durationOfInspectionCtrl.setValue(readableDuration(val))
        })
    }

    navToList() {
        //  http://192.168.244.150:8803/default/assignment/assignments/2/toes/1/dashboard
        //     http://192.168.244.150:8803/default/assignment/assignments/2/toes/1/inspection/7
        this.router.navigate(['../../', 'dashboard'], {relativeTo: this.activatedRoute});
    }

    setLocation() {
        this.setCurrentLocation();
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    this.centerLat = position.coords.latitude;
                    this.centerLng = position.coords.longitude;
                    this.zoom = 15;
                }
            });
        } else {
            alert('Position not available');
        }
    }


    // floor and rate system

    private _getFloors(val: string): number[] {
        const numbers: number[] = []
        const items = val.split('-');
        if (items.length == 2) {
            for (let i = parseInt(items[0]); i <= parseInt(items[1]); i++) {
                numbers.push(i);
            }
        } else {
            numbers.push(parseInt(items[0]));
        }
        return numbers;
    }

    public onAdding(tag): Observable<any> {
        // this.addRatings(tag);
        return of(tag);
    }

    public onRemoving(tag): Observable<any> {
        return of(tag);
    }

    // inspection det functions

    radioChanged(ev) {

        if (ev.value) {
            this.assetClassDetailForm.controls.limitations_desc.setValidators(Validators.required);
            this.assetClassDetailForm.updateValueAndValidity();
        } else {
            this.assetClassDetailForm.controls.limitations_desc.setValidators(null);
            this.assetClassDetailForm.updateValueAndValidity();
        }
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }

    setAutoFillCountry(address) {
        const addressData = address.split(',').reverse();
        if (addressData.length >= 1) {
            if (this.allCountries.length !== 0) {
                const _country = this.allCountries.find(el => el.name == addressData[0].trim());
                this.autoFillCity = addressData[0].trim();
                if (_country) {
                    this.assetClassDetailForm.controls.country_id.setValue(_country.id);
                    this.selectCountry();
                } else {
                    this.showError(addressData[0].trim())
                }
            }
        }
    }

    private showError(country: string) {
        const dialogRef = this.layoutUtilsService.showErrorDialg(
            'Country not found', 
            `Selected country "${country}" is not in the list`,
            'Add country');
        const sub = dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                this.assetClassDetailForm.controls.country_id.setValue(undefined);
                return;
            }
            if (res.proceedAction) {
                this.addCountry(country);
            }
        });
        this.subscriptions.push(sub);
    }

    private addCountry(country: string) {
        const newCountry = new CountryModel();
        newCountry.clear();
        const dialogRef = this.dialog.open(CountryEditComponent, {
            data: {
                id: newCountry.id,
                name: country
            }
        });
        const sub = dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.assetClassDetailForm.controls.country_id.setValue(res.id);
            this.selectCountry();
        });
        this.subscriptions.push(sub);
    }

    selectCountry() {
        const countryId = this.assetClassDetailForm.controls.country_id.value;
        this.store.pipe(takeUntil(this._onDestroy), select(selectCitiesByCountryId(countryId))).subscribe((res: CityModel[]) => {
            this.cityOfCountry = res;
            const _city = this.cityOfCountry.find(el => el.name == this.autoFillCity);
            if (_city) {
                this.assetClassDetailForm.controls.city_id.setValue(_city.id);
            }
        });
    }

    closedSelection(el) {
        // el.updateValueAndValidity();
    }  

    public counter(n: number): number[] {
        return [...Array(n).keys()]
    }
}

interface Item {
    name: string;
}

function filter<T extends Item>(data: T[], searchControl: FormControl): T[] {
    let search: string = searchControl.value;
    if (!search || search === '') {
        return data;
    } else {
        search = search.toLowerCase();
    }
    return data.filter(item => item.name.toLowerCase().indexOf(search) > -1);
}

function readableDuration(duration: string): string {
    if (duration === null) {
        return ''
    }
    const splits = duration.split(':');
    if (splits.length != 2) {
        return ''
    }

    const hours = Number(splits[0]);
    const minutes = Number(splits[1]);
    if (hours == 0) {
        return `${minutes} minute(s)`;
    }
    return `${hours} hour(s) ${minutes} minute(s)`
}

function durationFromReadable(readable: string): string {
    const splits = readable.split(' ');
    if (splits.length === 4) {
        return `${splits[0]}:${splits[2]}`;
    }
    if (splits.length === 2) {
        return `00:${splits[0]}`;
    }
    return null;
}
