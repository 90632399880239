import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { DraftStatementReportActions, DraftStatementReportActionTypes } from "../_actions/draft-statement-report.action";
import { DraftStatementReportModel } from "../_models/draft-statement-report.model";

export interface DraftStatementReportState extends EntityState<DraftStatementReportModel> {
	lastCreatedReportId: number;
	isGenerating: Record<number, boolean>;
	isAllReportsLoaded: Record<number, boolean>;
}
export const adapter: EntityAdapter<DraftStatementReportModel> = createEntityAdapter<DraftStatementReportModel>();
export const initialDraftStatementReportState: DraftStatementReportState = adapter.getInitialState({
	lastCreatedReportId: undefined as number,
	isGenerating: {} as Record<number, boolean>,
	isAllReportsLoaded: {} as Record<number, boolean>
})

export function draftStatementReportReducer(
	state = initialDraftStatementReportState,
	action: DraftStatementReportActions
): DraftStatementReportState {
	let currentLoadedReports: Record<number, boolean>;
	let currentIsGenerating: Record<number, boolean>;

	switch (action.type) {
		case DraftStatementReportActionTypes.AllDraftStatementReportsRequested: {
			currentLoadedReports = Object.assign({}, state.isAllReportsLoaded);
			currentLoadedReports[action.payload.tpID] = false;
			return {
				...state, isAllReportsLoaded: currentLoadedReports
			}
		}
		case DraftStatementReportActionTypes.AllDraftStatementReportsLoaded: {
			currentLoadedReports = Object.assign({}, state.isAllReportsLoaded);
			currentLoadedReports[action.payload.tpId] = true;
			currentIsGenerating = Object.assign({}, state.isGenerating);
			currentIsGenerating[action.payload.tpId] = false;
			return adapter.upsertMany(action.payload.reports, {
				...state, isAllReportsLoaded: currentLoadedReports, isGenerating: currentIsGenerating
			})
		}
		case DraftStatementReportActionTypes.DraftStatementReportOnServerCreated: {
			currentIsGenerating = Object.assign({}, state.isGenerating);
			currentIsGenerating[action.payload.report.tp_id] = true;
			return {
				...state, isGenerating: currentIsGenerating
			}
		}
		case DraftStatementReportActionTypes.DraftStatementReportCreated: {
			currentIsGenerating = Object.assign({}, state.isGenerating);
			currentIsGenerating[action.payload.report.tp_id] = false;
			return adapter.addOne(action.payload.report, {
				...state, lastCreatedReportId: action.payload.report.id, isGenerating: currentIsGenerating
			})
		}
		case DraftStatementReportActionTypes.DraftStatementReportStateResetted:
			return adapter.removeAll({...state});
		case DraftStatementReportActionTypes.DraftStatementReportOnServerDeleted:
			return adapter.removeOne(action.payload.id, state);
		default:
			return state;
	}
}