// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectGardenQueryResult, selectGardensPageLoading, selectGardensShowInitWaitingMessage} from '../_selectors/garden.selectors';

export class GardensDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectGardensPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectGardensShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectGardenQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
