import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { AddTeamTemplate, ITeamMemberTemplate, TeamTemplateModel, UpdateTeamTemplate } from 'src/app/core/template';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { IToeTeamMember } from 'src/app/views/pages/shared_components/toe-members/toe-members.component';

@Component({
  selector: 'kt-team-template-body',
  templateUrl: './team-template-body.component.html',
  styleUrls: ['./team-template-body.component.scss']
})
export class TeamTemplateBodyComponent implements OnInit, OnDestroy {
  @Input() template: TeamTemplateModel;
  @Input() readonly: boolean;

  members$: BehaviorSubject<IToeTeamMember[]> = new BehaviorSubject([]);

  formGroup: UntypedFormGroup;
  atLeastOneValuerError: boolean = false;

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder
  ) { 

  }

  ngOnInit(): void {
    this.members$.next(this._convert(this.template.members));

    this.formGroup = this.formBuilder.group({
      name: [this.template.name, Validators.required],
      description: [this.template.description, Validators.required],
    })
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _convert(members: ITeamMemberTemplate[]): IToeTeamMember[] {
    return members.map(m => ({
      id: m.id,
      ref_id: m.template_id,
      lead_valuer: m.lead_valuer,
      worker_id: m.worker_id,
      worker: m.worker,
      daily_rate: m.daily_rate,
      position: m.lead_valuer ? 'Lead Valuer' : 'Team Member',
      days: 0,
      total_price: 0,
      assigned_default_tasks: m.assigned_default_tasks,
      selected_certificates: [],
      selected_document: []
    }))
  }
  private _convertBack(members: IToeTeamMember[]): ITeamMemberTemplate[] {
    return members.map(m => ({
      id: m.id,
      template_id: m.ref_id,
      worker_id: m.worker_id,
      worker: m.worker,
      daily_rate: m.daily_rate,
      position: m.lead_valuer ? 'Lead Valuer' : 'Team Member',
      days: 0,
      total_price: 0,
      lead_valuer: m.lead_valuer,
      assigned_default_tasks: m.assigned_default_tasks
    }))
  }

  private _prepareTemplate(): TeamTemplateModel {
    const template = new TeamTemplateModel();
    template.clear();
    const controls = this.formGroup.controls;
    template.id = this.template.id;
    template.name = controls.name.value;
    template.description = controls.description.value;
    template.members = this._convertBack(this.members$.value);
    return template;
  }

  onSubmit(complete: boolean): boolean {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      });
      return false;
    }
    if (this.members$.value.length == 0) {
        this.atLeastOneValuerError = true;
        return false;
    }
    const template = this._prepareTemplate();
    if (complete) {
      if (template.id === undefined) {
        this.store.dispatch(new AddTeamTemplate({
          template: template
        }));
      } else {
        this.store.dispatch(new UpdateTeamTemplate({
          id: template.id,
          template
        }));
      }
      return true;
    }
    return false
  }
}
