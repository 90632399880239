import {Component, OnInit, ViewChild, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import * as fromFileUploadState from '../../../../core/file-upload/_states/file-upload.state';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {environment} from '../../../../../environments/environment';
import {AttachFileModel, FileUploadService} from '../../../../core/file-upload';
import {TranslationService} from '../../../../core/_base/layout';
import {ImageViewerDialogComponent} from '../image_viewer/image-viewer.dialog.component';
import { AssetClassBuildingInformation } from 'src/app/core/comparable';
import { awConst } from 'src/app/app.constants';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as _ from 'lodash'

export enum AssetClassPictureTypes {
    Main = '1',
    Building = '2'
}

@Component({
    selector: 'kt-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
    providers: [ComponentStore]
})
export class UploadFileComponent implements OnInit, OnDestroy, OnChanges {

    static fileTypes = ['pdf',
        'png',
        'jpeg',
        'jpg',
        'jp2',
        'png'];
    @Input() folderOnEditMode: string;
    @Input() oldFiles: BehaviorSubject<AttachFileModel[]>;
    @Input() filesOnEditMode: any[];
    @Input() readOnly: boolean;
    @Input() fileType: string;
    @Input() parentForm: string;
    @Input() checkBox = false;
    @Input() featuredPictureAllowed = false;
    @Input() descriptionField = false;
    @Input() fileNameField = false;
    @Input() typeField = true;
    @Input() split = '0';
    @Input() addButtonLabel = 'Add';
    @Input() buildingField = false;
    @Input() buildingsSubject: BehaviorSubject<AssetClassBuildingInformation[]>;
    @Input() hasThumbnail = false;
    @ViewChild('fileInput') fileInput;

    @Input() dropDownValues$: ReplaySubject<{
        key: number,
        value: string,
    }[]>;
    dropDownValues: { key: number, value: string }[] = [];
    dropDownValuesSub: Subscription;
    @Input() dropDownRelated$: Subject<{
        key: number,
        value: string,
        path: string,
        name: string,
        title: string,
        floor: number,
    }>;
    @Output() fileUploaded: EventEmitter<any> = new EventEmitter();


    displayedColumns = ['thumbnail', 'type', 'name', 'title', 'descr', 'actions'];
    completed$: Observable<boolean>;
    progress$: Observable<number>;
    error$: Observable<string>;
    isInProgress$: Observable<boolean>;
    isReady$: Observable<boolean>;
    hasFailed$: Observable<boolean>;
    res$: Observable<any>;
    fileName: string;
    dataSource = new MatTableDataSource();
    fileFolder: string;
    uploadFiles: AttachFileModel[];
    docs: string[] = [];
    basePath: string;
    acPictureTypes = AssetClassPictureTypes;
    buildingList: AssetClassBuildingInformation[] = [];
    awConst = awConst;

    cancel$ = new Subject<void>();

    public lastChangedItems = new BehaviorSubject<AttachFileModel[]>([]);

    constructor(private dialog: MatDialog,
                private readonly store$: ComponentStore<fromFileUploadState.State>,
                private service: FileUploadService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslationService,
                private ref: ChangeDetectorRef) {
        this.fileFolder = '';
        this.uploadFiles = [];
        this.docs = ['xlsx', 'xls', 'doc', 'docx'];
        this.basePath = environment.baseApiUrl;
        this.store$.setState(fromFileUploadState.initialState);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filesOnEditMode && changes.filesOnEditMode.currentValue && changes.folderOnEditMode) {
            this.uploadFiles = [];
            this.uploadFiles.push(...changes.filesOnEditMode.currentValue);
            this.fileFolder = changes.folderOnEditMode.currentValue;
            this.dataSource.data = this.uploadFiles;
        }
    }

    ngOnInit() {
        this.displayedColumns = [];
        if (this.typeField) {
            this.displayedColumns = ['type'];
        }

        if (this.hasThumbnail) {
            this.displayedColumns.push('thumbnail');
        }

        // this.displayedColumns = ['type', 'name', 'title'];
        if (this.fileNameField) {
            this.displayedColumns.push('name');
        }

        this.displayedColumns.push('title');

        if (this.parentForm === 'toe-dashboard') {
            this.displayedColumns.splice(1, 1);
        }

        if (this.descriptionField) {
            this.displayedColumns.push('descr');
        }
        if (this.checkBox) {
            this.displayedColumns.push('check');
        }

        if (this.featuredPictureAllowed) {
            this.displayedColumns.push('is_featured');
        }

        if (this.dropDownValues$) {
            this.displayedColumns.push('rating_desc');
            this.dropDownValuesSub = this.dropDownValues$.asObservable().subscribe(res => {
                this.dropDownValues = res;
                if (this.uploadFiles) {
                    for (let i = 0; i < this.uploadFiles.length; i++) {
                        const ddVal = res.find(r => r.value == this.uploadFiles[i].rating_desc);
                        if (!ddVal) {
                            const file = _.cloneDeep(this.uploadFiles[i])
                            file.rating_desc = ''
                            this.uploadFiles[i] = file;
                        }
                    }
                    this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles)
                }
            });
        }

        if (this.buildingField) {
            this.displayedColumns.push('building');
        }

        this.displayedColumns.push('actions');
        if (this.fileType === '') {
            this.fileType = 'image/*, application/pdf';
            // this.fileType = '.csv,.xlsx,.xls,image/*,.doc,.docx,application/pdf';
        }
        this.resetUpload();
        if (this.folderOnEditMode && this.folderOnEditMode.length > 0) {
            this.fileFolder = this.folderOnEditMode;
            this.uploadFiles.push(...this.filesOnEditMode);
        } else {
            this.uploadFiles.push(...this.filesOnEditMode);
        }
        if (this.oldFiles) {
            this.oldFiles.asObservable().subscribe(res => {
                this.uploadFiles = Object.assign([], res);
                this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
            });
        }

        this.dataSource.data = this.uploadFiles;
        this.fileName = '';
        this.completed$ = this.selectUploadFileCompleted$;

        this.progress$ = this.selectUploadFileProgress$;

        this.error$ = this.selectUploadFileError$;

        this.isInProgress$ = this.selectUploadFileInProgress$;

        this.isReady$ = this.selectUploadFileReady$;

        this.hasFailed$ = this.selectUploadFileFailed$;

        this.res$ = this.selectUploadFileRes$;

        this.res$.subscribe(res => {
                if (res) {
                    if (this.split === this.acPictureTypes.Main || this.split === this.acPictureTypes.Building) {
                        // Upload file is main apartment/office/property picture or building picture
                        const _uploadedFiles = res.uploaded_files.filter(val => val.pic_type === this.split);
                        this.lastChangedItems.next(_uploadedFiles);
                        this.uploadFiles = [...this.uploadFiles, ..._uploadedFiles];
                    } else {
                        this.lastChangedItems.next(res.uploaded_files);
                        this.uploadFiles = [...this.uploadFiles, ...res.uploaded_files];
                    }
                    if (res.uploaded_files.length > 0 && res.uploaded_files[0].pic_type === this.split) {
                        this.fileFolder = res.success;
                    }

                    const featuredPicture = this.uploadFiles.find(file => file.is_featured === true);
                    if (!featuredPicture) {
                        this.uploadFiles = this.selectOnlyOne(this.uploadFiles, this.filterByParentWhenSplit(this.uploadFiles)[0]);
                    }
                    this.fileUploaded.emit(res);
                    this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
                }
            }
        );
    }

    ngOnDestroy(): void {
        if (this.dropDownValuesSub) {
            this.dropDownValuesSub.unsubscribe();
        }
        this.ref.detach();
    }

    uploadFile(event: any) {
        const files: FileList = event.target.files;
        const firstFile = files.item(0);
        this.fileName = firstFile.name;
        const formData = new FormData();
        let wrongExt = false;
        Array.from(files).forEach((file, i) => {
            const ext = file.name.split('.').pop().toLowerCase();
            if (!UploadFileComponent.fileTypes.find(e => e === ext)) {
                wrongExt = true;
            }
            formData.append('files[' + i + ']', file, file.name);
        });
        if (wrongExt) {
            alert(`Please upload "${UploadFileComponent.fileTypes.join(', ')}" files only.`);
            return;
        }
        if (this.fileFolder) {
            formData.append('path', this.fileFolder);
        }
        formData.append('split', this.split);

        this.requestUpload(formData);

        // clear the input form
        event.srcElement.value = null;
    }

    resetUpload() {
        this.fileFolder = '';
        this.uploadFiles = [];
        this.fileName = '';
        this._resetUpload();
    }

    cancelUpload() {
        this.fileName = '';
        this.cancel$.next();
        this._cancelUpload();
    }

    addFiles() {
        this.fileInput.nativeElement.click();
    }

    editFileDescription(obj: AttachFileModel) {
        if (this.buildingsSubject && this.buildingsSubject.value.length > 0) {
            this.buildingsSubject.asObservable().subscribe(res => {
                this.buildingList = Object.assign([], res);
            });
        }

        const _title = 'Adding Information';
        const _description = '';
        const _waitDesciption = '';
        const _fileTitle = obj.title ? obj.title : '';
        const _fileDescription = obj.descr ? obj.descr : '';
        let _fileDescriptionTooltip = null;
        const _checkedValue = obj.is_checked;
        const _isFeatured = obj.is_featured;
        let _currentDropDownValue = '';
        const _building = obj.building ? obj.building : '';

        if (this.parentForm === 'training-document') {
            _fileDescriptionTooltip = {
                desc: '[What the training document will be used for]',
                title: 'Description of training document'
            };
        }
        if (this.dropDownValues.length > 0) {
            _currentDropDownValue = obj.rating_desc;
        }

        const dialogRef = this.layoutUtilsService.saveElementWithInfo(
            _title,
            _description,
            _waitDesciption,
            _fileTitle,
            this.descriptionField,
            _fileDescription,
            _fileDescriptionTooltip,
            this.checkBox,
            _checkedValue,
            this.featuredPictureAllowed,
            _isFeatured,
            this.dropDownValues,
            _currentDropDownValue,
            this.buildingField,
            _building,
            this.buildingList,
        );

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const index = this.uploadFiles.indexOf(obj);
            const editedObj = Object.assign({}, obj);
            editedObj.title = res.title;
            editedObj.descr = res.descr || '';
            editedObj.is_checked = res.is_checked;
            editedObj.is_featured = res.is_featured;
            editedObj.building = res.building || '';
            if (this.dropDownRelated$) {
                editedObj.rating_desc = res.selected_value.value;
                this.dropDownRelated$.next({
                    ...res.selected_value,
                    path: editedObj.path,
                    name: editedObj.name,
                    title: res.title
                });
            }
            if (editedObj.is_featured) {
                this.uploadFiles = this.selectOnlyOne(this.uploadFiles, editedObj);
            }

            // this.uploadFiles = Object.assign([], this.uploadFiles, {[index]: editedObj});
            this.uploadFiles[index] = editedObj;
            this.lastChangedItems.next([editedObj]);
            this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
        });
    }

    deleteUploadedFile(obj) {
        const index = this.uploadFiles.indexOf(obj);
        this.uploadFiles.splice(index, 1);
        if (obj.is_featured && this.uploadFiles.length > 0) {
            this.uploadFiles = this.selectOnlyOne(this.uploadFiles, this.filterByParentWhenSplit(this.uploadFiles)[0]);
        }
        if (this.dropDownRelated$) {
            this.dropDownRelated$.next({
                key: -1,
                value: obj.rating_desc,
                name: obj.name,
                path: obj.path,
                title: obj.title,
                floor: null,
            });
        }
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    previewUploadedFile(obj) {
        if (this.docs.indexOf(this.getFileType(obj.name)) > -1) {
            window.open(this.basePath + obj.path + '/' + obj.name);
        } else {
            const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
                data: {
                    title: obj.title,
                    picture: obj.path + '/' + obj.name,
                    type: this.getFileType(obj.name)
                }
            });
        }
    }

    onchange($ev, obj: AttachFileModel) {
        const index = this.uploadFiles.indexOf(obj);
        const editedObj = Object.assign({}, obj);
        editedObj.is_checked = !editedObj.is_checked;
        this.uploadFiles = Object.assign([], this.uploadFiles, {[index]: editedObj});
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    onchangeFeatured($ev, obj: AttachFileModel) {
        this.uploadFiles = this.selectOnlyOne(this.uploadFiles, obj);
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    setIcon(type) {
        let ext = 'doc';
        switch (type) {
            case 'png':
                ext = 'png';
                break;
            case 'jpeg':
                ext = 'jpg';
                break;
            case 'jpg':
                ext = 'jpg';
                break;
            case 'gif':
                ext = 'gif';
                break;
            case 'pdf':
                ext = 'pdf';
                break;
            case 'xls':
                ext = 'xls';
                break;
            case 'xlsx':
                ext = 'xls';
                break;
            case 'rtf':
                ext = 'doc';
                break;
            case 'doc':
                ext = 'doc';
                break;
            case 'docx':
                ext = 'doc';
                break;
            case 'mp3':
                ext = 'mp3';
                break;
        }
        return './assets/media/files-alt/' + ext + '.svg';
    }

    getFileType(name) {
        return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
    }

    excludeExtention(name) {
        return name.substring(0, name.indexOf('.')).toLowerCase();
    }

    filterByParentWhenSplit(uploadFiles: AttachFileModel[]): AttachFileModel[] {
        if (this.split !== '0') {
            return uploadFiles
                .filter(pictureInfo => pictureInfo.pic_type.toString() === this.split);
        } else {
            return uploadFiles;
        }
    }

    selectOnlyOne(uploadFiles: AttachFileModel[], selectedObject: AttachFileModel): AttachFileModel[] {
        uploadFiles = this.filterByParentWhenSplit(uploadFiles);
        return uploadFiles.map((obj) => {
            if (obj === selectedObject) {
                const _editedObj1 = Object.assign({}, obj);
                _editedObj1.is_featured = true;
                return _editedObj1;
            }
            const _editedObj = Object.assign({}, obj);
            _editedObj.is_featured = false;
            return _editedObj;
        });
    }

    public inputChange(event, file) {
        const itemIndex = this.uploadFiles.findIndex(uf => {
            if (uf.pic_type) {
                return uf.pic_type.toString() == this.split && uf.name == file.name;
            }
            return uf.name == file.name
        });
        if (itemIndex == -1) {
            return;
        }
        const item = this.uploadFiles[itemIndex];
        const newitem = Object.assign({}, item);
        newitem.title = event.target.value;
        this.uploadFiles.splice(itemIndex, 1, newitem);
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    public descriptionChange(event, file) {
        const itemIndex = this.uploadFiles.findIndex(uf => {
            if (uf.pic_type) {
                return uf.pic_type.toString() == this.split && uf.name == file.name;
            }
            return uf.name == file.name
        });
        if (itemIndex == -1) {
            return;
        }
        const item = this.uploadFiles[itemIndex];
        const newitem = Object.assign({}, item);
        newitem.descr = event.target.value;
        this.uploadFiles.splice(itemIndex, 1, newitem);
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    public reload() {
        this.ref.detectChanges();
    }

    public ratingChange(event, file) {
        const itemIndex = this.uploadFiles.findIndex(uf => uf.pic_type.toString() == this.split && uf.name == file.name)
        if (itemIndex == -1) {
            return;
        }
        const item = this.uploadFiles[itemIndex];
        const newitem = Object.assign({}, item);
        newitem.rating_desc = event.value;
        this.uploadFiles.splice(itemIndex, 1, newitem);
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);

        this.dropDownRelated$.next({
            key: undefined,
            value: event.value,
            path: file.path,
            name: file.name,
            title: file.title,
            floor: this.dropDownValues.find(ddv => ddv.value == event.value)?.key
        });
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND
    }

    private getError = (state: fromFileUploadState.State): string => state.error;

    private getStarted = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Started;

    private getRequested = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Requested;

    private getReady = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Ready;

    private getProgress = (state: fromFileUploadState.State): number => state.progress;

    private getInProgress = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Started && state.progress >= 0;

    private getFailed = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Failed;

    private getCompleted = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Completed;

    private getRes = (state: fromFileUploadState.State): object => state.res;

    // Selectors
    readonly selectUploadFileCompleted$ = this.store$.select(this.getCompleted);
    readonly selectUploadFileProgress$ = this.store$.select(this.getProgress);
    readonly selectUploadFileFailed$ = this.store$.select(this.getFailed);
    readonly selectUploadFileRes$ = this.store$.select(this.getRes);
    readonly selectUploadFileStarted$ = this.store$.select(this.getStarted);
    readonly selectUploadFileError$ = this.store$.select(this.getError);
    readonly selectUploadFileReady$ = this.store$.select(this.getReady);
    readonly selectUploadFileRequested$ = this.store$.select(this.getRequested);
    readonly selectUploadFileInProgress$ = this.store$.select(this.getInProgress);

    // Actions
    readonly _requestUpload = this.store$.updater((state) => ({
        ...state,
        status: fromFileUploadState.UploadStatus.Requested,
        progress: null,
        error: null,
        res: null,
    }))
    readonly _cancelUpload = this.store$.updater((state) => ({
        ...state,
        status: fromFileUploadState.UploadStatus.Ready,
        progress: null,
        error: null,
        res: null,
    }))
    readonly _resetUpload = this.store$.updater((state) => ({
        ...state,
        status: fromFileUploadState.UploadStatus.Ready,
        progress: null,
        error: null,
        res: null
    }))
    readonly startedUpload = this.store$.updater((state) => ({
        ...state,
        status: fromFileUploadState.UploadStatus.Started,
        progress: 0
    }))
    readonly progressUpload = this.store$.updater((state, progress: number) => ({
        ...state,
        progress
    }))
    readonly failureUpload = this.store$.updater((state, error: string) => ({
        ...state,
        status: fromFileUploadState.UploadStatus.Failed,
        error,
        progress: null,
        res: null
    }))
    readonly completedUpload = this.store$.updater((state, res: object) => ({
        ...state,
        status: fromFileUploadState.UploadStatus.Completed,
        progress: 100,
        error: null,
        res
    }))


    // Effects
    readonly requestUpload = this.store$.effect((formData$: Observable<FormData>) => {
        return formData$.pipe(
            tap((_) => this._requestUpload()),
            concatMap((data) => this.service.uploadFile(data).pipe(
                takeUntil(
                    this.cancel$
                ),
                tap(event => this.getActionFromHttpEvent(event)),
                catchError(error => of(this.handleError(error)))
            ))
        )
    }) 

    // readonly upload

    private getActionFromHttpEvent(event: HttpEvent<any>) {
        switch (event.type) {
            case HttpEventType.Sent: {
                this.startedUpload();
                return;
            }
            case HttpEventType.UploadProgress: {
                this.progressUpload(Math.round((100 * event.loaded) / event.total));
                return;
            }
            case HttpEventType.ResponseHeader:
            case HttpEventType.Response: {
                if (event.status === 200) {
                    let response_body;
                    if (event.type == HttpEventType.Response) {
                        response_body = event.body;
                    }
                    this.completedUpload(response_body);
                    return;
                } else {
                    if (event.status === 400) {

                        /*  400 Bad Request
                        The server cannot or will not process the request due to an
                        apparent client error (e.g., malformed request syntax, size too large,
                        invalid request message framing, or deceptive request routing)
                        */
                        this.failureUpload(`Bad Request, Please check your form data.`)
                        return;
                    }

                    if (event.status === 403) {

                        /*  403 Forbidden
                          The request contained valid data and was understood by the server, but the server is refusing action. This may be due to the user not having the necessary permissions for a resource or needing
                          an account of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is allowed). This code is also typically used if the request provided
                          authentication via the WWW-Authenticate header field, but the server did not accept that authentication. The request should not be repeated.
                        */

                        this.failureUpload(`The Server is refusing action, Please check your form data.`)
                        return;
                    }


                    if (event.status === 404) {

                        /*  404 Not Found
                            The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible.
                        */
                        this.failureUpload(`Not Found, API have not developed yet.`)
                        return;
                    }

                    if (event.status === 500) {

                        /*  500 Internal Server Error
                        The server cannot or will not process the request due to an
                        apparent client error (e.g., malformed request syntax, size too large,
                        invalid request message framing, or deceptive request routing)
                        */
                        this.failureUpload(`Internal Server Error, Please inform us by email.`)
                        return;
                    }

                    if (event.status >= 500) {
                        this.failureUpload(`Server Error ${event.status}, Please inform us by email.`)
                        return;
                    }

                    if (event.status >= 400) {
                        this.failureUpload(`Client Error ${event.status}, Please check your inserted data`)
                        return;
                    }

                    this.failureUpload(`Request Error ${event.status}, Please check action`)
                    return;
                }
            }
            default: {
                this.failureUpload(`Unknown Event: ${JSON.stringify(event)}`)
                return;
            }
        }
    }

    private handleError(error: any) {
        this.failureUpload('Too large file. Max file upload is: 16mb.')
        return;
    }
}
