<table *ngIf="sizeInfo" class="table" [ngClass]="{'readonly': readonly}">
    <thead>
        <th class="cell">Components</th>
        <th class="cell">Items</th>
        <th *ngFor="let info of sizeInfo.data" class="cell">
            <ng-container *ngIf="schemeId == 1">
                <span *ngIf="info.floor < 0">
                    Lower Ground ({{info.floor}})
                </span>
                <span *ngIf="info.floor == 0">
                    Ground
                </span>
                <span *ngIf="info.floor > 0">
                    Floor {{info.floor}}
                </span>
            </ng-container>
            <ng-container *ngIf="schemeId == 2">
                <span *ngIf="info.floor < 0">
                    Lower Ground ({{info.floor}}) 
                </span>
                <span *ngIf="info.floor == 1">
                    Floor 1 (Ground) 
                </span>
                <span *ngIf="info.floor > 1">
                    Floor {{info.floor}}
                </span>
            </ng-container>
            <span>
                / {{sizeInfo.unit_of_area_measurement}}
            </span>
        </th>
        <th class="cell">
            Total
        </th>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of config">
            <tr *ngFor="let item of row.items; let i = index">
                <th [attr.rowspan]="row.items.length" *ngIf="i == 0" class="cell cell_component" [style.background-color]="row.primaryColor">
                    <div>
                        <span>
                            {{row.title}}
                        </span>
                        <mat-icon
                            *ngIf="row.name"
                            style="cursor: pointer"
                            container="body"
                            [ngbPopover]="row.description"
                            [popoverTitle]="row.name">
                            help
                        </mat-icon>
                    </div>
                </th>
                <th class="cell cell_item" 
                    [style.background-color]="row.secondaryColor"
                    [ngClass]="{'cell_last': i + 1 == row.items.length}"
                    >
                    {{item.title}}
                </th>
                <td *ngFor="let info of sizeInfo.data" 
                    class="cell" 
                    [ngStyle]="{'background-color': item.type == 'static' ? row.lightColor : null}"
                    [ngClass]="{
                        'cell_calculated cell_dashed': item.type == 'static',
                        'cell_last': i + 1 == row.items.length,
                        'cell_static_total': item.type == 'static_total'
                    }">
                    <ng-container *ngIf="item.type == 'input'">
                        <input 
                            noNeg
                            type="text" 
                            [value]="info[row.key][item.key]" 
                            (focusout)="onFocusOut($event, info.floor, row.key, item.key)"/>
                    </ng-container>
                    <ng-container *ngIf="item.type == 'static' || item.type == 'static_total'" >
                        {{info[row.key][item.key]}}
                    </ng-container>
                </td>
                <td class="cell cell_calculated cell_total" 
                    [ngStyle]="{'background-color': item.key == 'ipms_total' 
                                                        ? row.secondaryColor 
                                                        : row.lightColor}"
                    [ngClass]="{
                        'cell_last': i + 1 == row.items.length,
                        'cell_dashed': item.key == 'ipms_total'
                    }"
                    >
                    {{sizeInfo.data | rowsizesum:row.key:item.key}}
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>