// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {OfficeLayoutActions, OfficeLayoutActionTypes} from '../_actions/office-layout.actions';
// Models
import {OfficeLayout} from '../_models/office-layout.model';
import {QueryParamsModel} from '../../_base/crud';

export interface OfficeLayoutsState extends EntityState<OfficeLayout> {
    isAllOfficeLayoutsLoaded: boolean;
    queryRowsCount: number;
    queryResult: OfficeLayout[];
    lastCreatedOfficeLayoutId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<OfficeLayout> = createEntityAdapter<OfficeLayout>();

export const initialOfficeLayoutsState: OfficeLayoutsState = adapter.getInitialState({
    isAllOfficeLayoutsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOfficeLayoutId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function officeLayoutsReducer(state = initialOfficeLayoutsState, action: OfficeLayoutActions): OfficeLayoutsState {
    switch (action.type) {
        case OfficeLayoutActionTypes.OfficeLayoutsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedOfficeLayoutId: undefined
            };
        case OfficeLayoutActionTypes.OfficeLayoutsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case OfficeLayoutActionTypes.OfficeLayoutOnServerCreated:
            return {
                ...state
            };
        case OfficeLayoutActionTypes.OfficeLayoutCreated:
            return adapter.addOne(action.payload.officeLayout, {
                ...state, lastCreatedOfficeLayoutId: action.payload.officeLayout.id
            });
        case OfficeLayoutActionTypes.OfficeLayoutUpdated:
            return adapter.updateOne(action.payload.partialofficeLayout, state);

        case OfficeLayoutActionTypes.AllOfficeLayoutsLoaded:
            return adapter.addAll(action.payload.officeLayouts, {
                ...state, isAllOfficeLayoutsLoaded: true
            });
        case OfficeLayoutActionTypes.OfficeLayoutsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case OfficeLayoutActionTypes.OfficeLayoutsPageLoaded:
            return adapter.addMany(action.payload.officeLayouts, {
                ...initialOfficeLayoutsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case OfficeLayoutActionTypes.OfficeLayoutDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case OfficeLayoutActionTypes.OfficeLayoutRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case OfficeLayoutActionTypes.OfficeLayoutDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case OfficeLayoutActionTypes.OfficeLayoutAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case OfficeLayoutActionTypes.OfficeLayoutDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case OfficeLayoutActionTypes.OfficeLayoutTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
