import {Client} from './../_models/client.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ClientsState} from '../_reducers/client.reducers';
import * as fromClient from '../_reducers/client.reducers';
import {each} from 'lodash';

export const selectClientsState = createFeatureSelector<ClientsState>('clients');

export const selectClientById = (clientId: number) => createSelector(
    selectClientsState,
    clientsState => clientsState.entities[clientId]
);

export const selectAllClients = createSelector(
    selectClientsState,
    fromClient.selectAll
);

export const selectAllClientsIds = createSelector(
    selectClientsState,
    fromClient.selectIds
);

export const allClientsLoaded = createSelector(
    selectClientsState,
    clientsState => clientsState.isAllClientsLoaded
);


export const selectTrashedClientsCount = createSelector(
    selectClientsState,
    clientsState => clientsState.totalTrashed
);

export const selectAdminTrashedClientsCount = createSelector(
    selectClientsState,
    clientsState => clientsState.totalAdminTrashed
);


export const selectClientsPageLoading = createSelector(
    selectClientsState,
    clientsState => clientsState.listLoading
);

export const selectClientsActionLoading = createSelector(
    selectClientsState,
    clientsState => clientsState.actionLoading
);

export const selectLastCreatedClientId = createSelector(
    selectClientsState,
    clientsState => clientsState.lastCreatedClientId
);

export const selectClientsShowInitWaitingMessage = createSelector(
    selectClientsState,
    clientsState => clientsState.showInitWaitingMessage
);


export const selectQueryResult = createSelector(
    selectClientsState,
    clientsState => {
        const items: Client[] = [];
        each(clientsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Client[] = httpExtension.sortArray(items, clientsState.lastQuery.sortField, clientsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, clientsState.queryRowsCount, '', clientsState.totalTrashed);
    }
);
