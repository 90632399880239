import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AllBuildingTypesRequested, AllComparativeLandUsesRequested, AllExternalAreasRequested, AllFoundationTypesRequested, AllGardensRequested, AllGradesRequested, AllHandoverStandardsRequested, AllLandTenuresRequested, AllParkingTypesRequested, AllQosDegradationsRequested, AllSolUseClassificationsRequested, AllSourceCredibilityRequested, AllSourceInformationRequested, AllSourceTypesRequested, AllStandardMeasurementsRequested, AllStateRepairsRequested, AllUnitAreaMeasurementsRequested, BuildingType, ComparativeLandUse, ExternalArea, FoundationType, GardenModel, Grade, HandoverStandard, LandTenure, ParkingTypeModel, QosDegradation, selectAllBuildingTypes, selectAllComparativeLandUses, selectAllExternalAreas, selectAllFoundationTypes, selectAllGardens, selectAllGrades, selectAllHandoverStandards, selectAllLandTenures, selectAllParkingTypes, selectAllQosDegradations, selectAllSolUseClassifications, selectAllSourceCredibility, selectAllSourceInformation, selectAllSourceTypes, selectAllStandardMeasurements, selectAllStateRepairs, selectAllUnitAreaMeasurements, SolUseClassification, SourceCredibility, SourceInformation, SourceType, StandardMeasurement, StateRepair, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { FilterModel } from '../filter-section.component';

type CheckListItem = {
  id: number,
  title: string,
  checked: boolean
}

@Component({
  selector: 'kt-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit, OnDestroy {
  filter: FilterModel;
  form: UntypedFormGroup;

  grades: Grade[] = [];
  handoverStandards: HandoverStandard[] = [];
  stateRepairs: StateRepair[] = [];
  buildingTypes: BuildingType[] = [];
  parkingTypes: ParkingTypeModel[] = [];
  qos: QosDegradation[] = [];
  comparativeLandUses: ComparativeLandUse[] = [];
  sol_uses: SolUseClassification[] = [];
  externalAreas: ExternalArea[] = [];
  gardesn: GardenModel[] = []
  unitAreaMeasurements: UnitAreaMeasurement[] = [];
  unitAreaMeasurements$ = new BehaviorSubject<UnitAreaMeasurement[]>(this.unitAreaMeasurements);
  standardMeasurements: StandardMeasurement[] = [];
  selectedStandardMeasurements: number[] = [];
  sourceTypes: SourceType[] = [];
  sourceInformations: SourceInformation[] = [];
  sourceCredibility: SourceCredibility[] = [];
  heatings: CheckListItem[] = [

    {id: 1, title: 'Gas', checked: false},
    {id: 2, title: 'Electric', checked: false},
    {id: 3, title: 'Solid Fuel', checked: false},
    {id: 4, title: 'Oil', checked: false},
    {id: 5, title: 'Other', checked: false}
  ];
  services: CheckListItem[] = [
    {id: 1, title: "Waste water/sewerage disposal", checked: false},
    {id: 2, title: "Storm water drainage", checked: false},
    {id: 3, title: "Potable water supply", checked: false},
    {id: 4, title: "Electricity (main, solar, off-grid or independently generated)", checked: false},
    {id: 5, title: "Telephony/telecommunications", checked: false},
    {id: 6, title: "Internet access", checked: false},
    {id: 7, title: "Postal service", checked: false},
    {id: 8, title: "Garbage disposal", checked: false},
    {id: 9, title: "Transport (public or other facilities)", checked: false},
    {id: 10, title: "Other services", checked: false},
  ]
  landServices: CheckListItem[] = [
    { title: 'Potable water supply', id: 1, checked: false },
    { title: 'Waste water/sewerage disposal', id: 2, checked: false },
    { title: 'Storm water drainage', id: 3, checked: false },
    { title: 'Electricity (main, solar, off-grid or independently generated)', id: 4, checked: false },
    { title: 'Gas', id: 5, checked: false },
    { title: 'Telephony/telecommunications', id: 6, checked: false },
    { title: 'Internet access', id: 7, checked: false },
    { title: 'Garbage disposal', id: 8, checked: false },
    { title: 'Postal service', id: 9, checked: false },
    { title: 'Transport (public or other facilities)', id: 10, checked: false },
    { title: 'Other services', id: 11, checked: false },
  ];
  landTenures: LandTenure[] = [];
  foundationTypes: FoundationType[] = [];

  bedroomRanges = [0, 10];
  bathroomRanges = [0, 10];
  roomRanges = [0, 10];
  indoorGarageRanges = [0, 200];
  outdoorGarageRanges = [0, 200];
  selectedSizeUnit: string = '';
  sizeRanges = [0, 250];
  pictureRanges = [0, 10];
  freehold = false;
  leasehold = false;


  propertySectionTitle: string = 'Property Information'
  indoorGarageTitle: string = 'Indoor Garage';
  outdoorGarageTitle: string = 'Outdoor Parking';
  sizeSectionTitle: string = 'Sizes';
  sizePlaceholder: string = 'Size Unit';
  sizeRangePlaceholder: string = 'Range';

  private _onDestroy$: Subject<void> = new Subject()

  selectedExternalAreas: number[] = [];

  sections = {
    handover_standard: false,
    state_of_repair: false,
    property_grade: false,
    completion_year: false,
    above_floors: false,
    building_type: false,
    num_of_units: false,
    parking_type: false,
    automation: false,
    quality_of_soil_degradation: false,
    comparative_land_use: false,
    source_of_land_use_classification: false,
    existing_building_on_the_land_parcel: false,
    external_areas: false,
    garden: false,
    tenure: true,
    landTenur: false,
    foundation_type: false,
    compInfra: false,
    landInfra: false
  }

  topSections = {
    rooms: false,
    facilities: false,
    service_and_infra: false,
    sources: true,
    tenure: true
  }

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>
  ) { 
    this.filter = Object.assign({}, data.filter)
    if (this.filter.room_ranges) {
      const _tmp = this.filter.room_ranges.split('-');
      this.roomRanges[0] = parseInt(_tmp[0]);
      this.roomRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.bedroom_ranges) {
      const _tmp = this.filter.bedroom_ranges.split('-');
      this.bedroomRanges[0] = parseInt(_tmp[0]);
      this.bedroomRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.bathroom_ranges) {
      const _tmp = this.filter.bathroom_ranges.split('-');
      this.bathroomRanges[0] = parseInt(_tmp[0]);
      this.bathroomRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.indoor_garage_ranges) {
      const _tmp = this.filter.indoor_garage_ranges.split('-');
      this.indoorGarageRanges[0] = parseInt(_tmp[0]);
      this.indoorGarageRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.outdoor_garage_ranges) {
      const _tmp = this.filter.outdoor_garage_ranges.split('-');
      this.outdoorGarageRanges[0] = parseInt(_tmp[0]);
      this.outdoorGarageRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.size_ranges) {
      const _tmp = this.filter.size_ranges.split('-');
      this.sizeRanges[0] = parseInt(_tmp[0]);
      this.sizeRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.picture_ranges) {
      const _tmp = this.filter.picture_ranges.split('-');
      this.pictureRanges[0] = parseInt(_tmp[0]);
      this.pictureRanges[1] = parseInt(_tmp[1]);
    }
    if (this.filter.tenure) {
      const _tmp = this.filter.tenure.split(',');
      if (_tmp.length == 1) {
        this[_tmp[0]] = true;
      } 
      if (_tmp.length == 2) {
        this[_tmp[0]] = true;
        this[_tmp[1]] = true;
      }
    }

    if (this.filter.selected_external_areas) {
      this.selectedExternalAreas = this.filter.selected_external_areas
        .split(',')
        .map(id => parseInt(id))
    }
    if (this.filter.selected_standard_measurements) {
      this.selectedStandardMeasurements = this.filter.selected_standard_measurements
        .split(',')
        .map(id => parseInt(id))
    }
    if (this.filter.selected_heatings) {
      const heatings = this.filter.selected_heatings.split(',').map(id => parseInt(id));
      this.heatings = this.heatings.map(item => {
        return {...item, checked: heatings.includes(item.id)}
      })
    }
    if (this.filter.selected_services) {
      const services = this.filter.selected_services.split(',').map(id => parseInt(id));
      this.services = this.services.map(item => {
        return {...item, checked: services.includes(item.id)}
      })
    }
    if (this.filter.selected_land_services) {
      const services = this.filter.selected_land_services.split(',').map(id => parseInt(id));
      this.landServices = this.landServices.map(item => {
        return {...item, checked: services.includes(item.id)}
      })
    }

    switch (this.data.acType) {
      case 0: {
        this.sections = {
          ...this.sections,
          completion_year: true,
          foundation_type: true
        }
        this.topSections = {
          ...this.topSections,
          facilities: true,
          sources: false,
          tenure: false
        }
        break;
      }
      case 1: {
        this.sections = {
          ...this.sections,
          handover_standard: true,
          state_of_repair: true,
          external_areas: true
        }
        this.topSections = {
          ...this.topSections,
          rooms: true,
          facilities: true,
        }
        break;
      }
      case 17: {
        this.sections = {
          ...this.sections,
          property_grade: true,
          handover_standard: true,
          state_of_repair: true,
          completion_year: true,
          above_floors: true,
          garden: true,
          compInfra: true
        }
        this.topSections = {
          ...this.topSections,
          service_and_infra: true,
          facilities: true
        }
        break;
      }
      case 3: {
        this.indoorGarageTitle = 'Dedicated Indoor garage';
        this.outdoorGarageTitle = 'Dedicated Outdoor parking';
        this.sections = {
          ...this.sections,
          handover_standard: true,
          state_of_repair: true,
          building_type: true,
          external_areas: true
        }
        this.topSections = {
          ...this.topSections,
          facilities: true,
        }
        break;
      }
      case 5: {
        this.indoorGarageTitle = 'Dedicated Indoor garage';
        this.outdoorGarageTitle = 'Dedicated Outdoor parking';
        this.sections = {
          ...this.sections,
          handover_standard: true,
          state_of_repair: true,
          building_type: true,
          external_areas: true
        }
        this.topSections = {
          ...this.topSections,
          facilities: true,
        }
        break;
      }
      case 11: {
        this.sections = {
          ...this.sections,
          handover_standard: true,
          property_grade: true,
          state_of_repair: true,
          completion_year: true,
          above_floors: true,
          garden: true,
          compInfra: true,
        }
        this.topSections = {
          ...this.topSections,
          service_and_infra: true,
          facilities: true
        }
        break;
      }
      case 2: {
        this.sections = {
          ...this.sections,
          handover_standard: true,
          state_of_repair: true,
          num_of_units: true,
          parking_type: true,
          automation: true,
        }
        break;
      }
      case 7: {
        this.sections = {
          ...this.sections,
          property_grade: true,
          handover_standard: true,
          state_of_repair: true,
          completion_year: true,
          above_floors: true,
          garden: true,
          compInfra: true
        }
        this.topSections = {
          ...this.topSections,
          service_and_infra: true,
          facilities: true
        }
        break;
      }
      case 13: {
        this.propertySectionTitle = 'Land Use';
        this.sizeSectionTitle = 'Land Area';
        this.sizePlaceholder = 'Area unit Type';
        this.sizeRangePlaceholder = 'Surface';
        this.sections = {
          ...this.sections,
          quality_of_soil_degradation: true,
          comparative_land_use: true,
          source_of_land_use_classification: true,
          existing_building_on_the_land_parcel: true,
          tenure: false,
          landTenur: true,
          landInfra: true
        }
        this.topSections = {
          ...this.topSections,
          service_and_infra: true,
        }
        break;
      }
    }
  }

  ngOnInit(): void {
    this._fetchLT();

    this.form = this.fb.group({
      location_grade: [this.filter.location_grade_id],
      handover_standard: [this.filter.handover_standard_id],
      state_repair: [this.filter.state_of_repair_id],
      property_grade: [this.filter.property_grade_id],
      completion_year: [this.filter.completion_year],
      above_floors: [this.filter.above_floors],
      building_type: [this.filter.building_type_id],
      num_of_units: [this.filter.num_of_units],
      parking_type: [this.filter.parking_type_id],
      automation: [this.filter.has_automation],
      qos_degradation: [this.filter.quality_of_soil_degradation_id],
      comparative_land_use: [this.filter.comparative_land_use_id],
      source_of_land_use: [this.filter.source_of_land_use_classification_id],
      existing_building: [this.filter.existing_building_on_the_land_parcel],
      garden: [this.filter.garden_id],
      size_unit: [this.filter.size_unit_id],
      source_type: [this.filter.source_type_id],
      source_info: [this.filter.source_information_id],
      source_cred: [this.filter.source_credibility_id],
      has_photo: [this.filter.has_source_photo],
      land_tenure: [this.filter.land_tenure_id],
      tenure_length: [this.filter.tenure_length],
      foundation_type: [this.filter.foundation_type_id]
    });
    combineLatest([
      this.unitAreaMeasurements$,
      this.form.get('size_unit').valueChanges.pipe(
        startWith(this.form.get('size_unit').value), 
        takeUntil(this._onDestroy$)
      )
    ]).subscribe(([uams, val]) => {
      const uam = uams.find(uam => uam.id == val);
      if (uam) {
        this.selectedSizeUnit = uam.acronym;
      }
    })
  }

  private _fetchLT() {
    this.store.dispatch(new AllGradesRequested());
    this.store.pipe(select(selectAllGrades), takeUntil(this._onDestroy$)).subscribe(res => {
      this.grades = res ? res : [];
    });
    this.store.dispatch(new AllHandoverStandardsRequested());
    this.store.pipe(select(selectAllHandoverStandards), takeUntil(this._onDestroy$)).subscribe(res => {
      this.handoverStandards = res ? res : [];
    });
    this.store.dispatch(new AllStateRepairsRequested());
    this.store.pipe(select(selectAllStateRepairs), takeUntil(this._onDestroy$)).subscribe(res => {
      this.stateRepairs = res ? res : [];
    });
    this.store.dispatch(new AllBuildingTypesRequested());
    this.store.pipe(select(selectAllBuildingTypes), takeUntil(this._onDestroy$)).subscribe(res => {
      this.buildingTypes = res ? res : [];
    });
    this.store.dispatch(new AllParkingTypesRequested())
    this.store.pipe(select(selectAllParkingTypes), takeUntil(this._onDestroy$)).subscribe(res => {
      this.parkingTypes = res ? res : [];
    });
    this.store.dispatch(new AllQosDegradationsRequested());
    this.store.pipe(select(selectAllQosDegradations), takeUntil(this._onDestroy$)).subscribe(res => {
      this.qos = res ? res : [];
    });
    this.store.dispatch(new AllComparativeLandUsesRequested());
    this.store.pipe(select(selectAllComparativeLandUses), takeUntil(this._onDestroy$)).subscribe(res => {
      this.comparativeLandUses = res ? res : [];
    });
    this.store.dispatch(new AllSolUseClassificationsRequested());
    this.store.pipe(select(selectAllSolUseClassifications), takeUntil(this._onDestroy$)).subscribe(res => {
      this.sol_uses = res ? res : [];
    });
    this.store.dispatch(new AllExternalAreasRequested());
    this.store.pipe(select(selectAllExternalAreas), takeUntil(this._onDestroy$)).subscribe(res => {
      this.externalAreas = res ? res : []
    });
    this.store.dispatch(new AllGardensRequested());
    this.store.pipe(select(selectAllGardens), takeUntil(this._onDestroy$)).subscribe(res => {
      this.gardesn = res ? res : [];
    });
    this.store.dispatch(new AllUnitAreaMeasurementsRequested());
    this.store.pipe(select(selectAllUnitAreaMeasurements), takeUntil(this._onDestroy$)).subscribe(res => {
      this.unitAreaMeasurements = res ? res : [];
      this.unitAreaMeasurements$.next(this.unitAreaMeasurements);
    });
    this.store.dispatch(new AllStandardMeasurementsRequested());
    this.store.pipe(select(selectAllStandardMeasurements), takeUntil(this._onDestroy$)).subscribe(res => {
      this.standardMeasurements = res 
        ? res.filter(item => item.asset_class_type_id == this.data.acType)
        : [];
    });
    this.store.dispatch(new AllSourceTypesRequested());
    this.store.pipe(select(selectAllSourceTypes), takeUntil(this._onDestroy$)).subscribe(res => {
      this.sourceTypes = res ? res : [];
    });
    this.store.dispatch(new AllSourceInformationRequested());
    this.store.pipe(select(selectAllSourceInformation), takeUntil(this._onDestroy$)).subscribe(res => {
      this.sourceInformations = res ? res : [];
    });
    this.store.dispatch(new AllSourceCredibilityRequested());
    this.store.pipe(select(selectAllSourceCredibility), takeUntil(this._onDestroy$)).subscribe(res => {
      this.sourceCredibility = res ? res : [];
    });
    this.store.dispatch(new AllLandTenuresRequested());
    this.store.pipe(select(selectAllLandTenures), takeUntil(this._onDestroy$)).subscribe(res => {
      this.landTenures = res ? res : []
    })
    this.store.dispatch(new AllFoundationTypesRequested())
    this.store.pipe(select(selectAllFoundationTypes), takeUntil(this._onDestroy$)).subscribe(res => {
      this.foundationTypes = res ? res : []
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    const controls = this.form.controls;
    this.filter.location_grade_id = controls.location_grade.value;
    this.filter.handover_standard_id = controls.handover_standard.value;
    this.filter.state_of_repair_id = controls.state_repair.value;
    this.filter.property_grade_id = controls.property_grade.value;
    this.filter.completion_year = controls.completion_year.value;
    this.filter.above_floors = controls.above_floors.value;
    this.filter.building_type_id = controls.building_type.value;
    this.filter.num_of_units = controls.num_of_units.value;
    this.filter.parking_type_id = controls.parking_type.value;
    this.filter.has_automation = controls.automation.value;
    this.filter.quality_of_soil_degradation_id = controls.qos_degradation.value;
    this.filter.comparative_land_use_id = controls.comparative_land_use.value;
    this.filter.source_of_land_use_classification_id = controls.source_of_land_use.value;
    this.filter.existing_building_on_the_land_parcel = controls.existing_building.value;
    this.filter.garden_id = controls.garden.value;
    this.filter.size_unit_id = controls.size_unit.value;
    this.filter.source_type_id = controls.source_type.value;
    this.filter.source_information_id = controls.source_info.value;
    this.filter.source_credibility_id = controls.source_cred.value;
    this.filter.has_source_photo = controls.has_photo.value ;
    this.filter.land_tenure_id = controls.land_tenure.value;
    this.filter.tenure_length = controls.tenure_length.value;
    this.filter.foundation_type_id = controls.foundation_type.value;

    this.filter.bedroom_ranges = this.bedroomRanges[0] + '-' + this.bedroomRanges[1];
    if (this.filter.bedroom_ranges == '0-10') {
      this.filter.bedroom_ranges = null;
    }
    this.filter.bathroom_ranges = this.bathroomRanges[0] + '-' + this.bathroomRanges[1];
    if (this.filter.bathroom_ranges == '0-10') {
      this.filter.bathroom_ranges = null;
    }
    this.filter.room_ranges = this.roomRanges[0] + '-' + this.roomRanges[1];
    if (this.filter.room_ranges == '0-10') {
      this.filter.room_ranges = null;
    }
    this.filter.indoor_garage_ranges = this.indoorGarageRanges[0] + '-' + this.indoorGarageRanges[1];
    if (this.filter.indoor_garage_ranges == '0-200') {
      this.filter.indoor_garage_ranges = null;
    }
    this.filter.outdoor_garage_ranges = this.outdoorGarageRanges[0] + '-' + this.outdoorGarageRanges[1];
    if (this.filter.outdoor_garage_ranges == '0-200') {
      this.filter.outdoor_garage_ranges = null;
    }
    if (this.selectedExternalAreas.length == 0)  {
      this.filter.selected_external_areas = null;
    } else {
      this.filter.selected_external_areas = this.selectedExternalAreas.join(',')
    }
    if (this.heatings.every(el => el.checked == false)) {
      this.filter.selected_heatings = null;
    } else {
      this.filter.selected_heatings = this.heatings.filter(el => el.checked).map(el => el.id).join(',');
    }
    if (this.services.every(el => el.checked == false)) {
      this.filter.selected_services = null;
    } else {
      this.filter.selected_services = this.services.filter(el => el.checked).map(el => el.id).join(',');
    }
    if (this.landServices.every(el => el.checked == false)) {
      this.filter.selected_land_services = null;
    } else {
      this.filter.selected_land_services = this.landServices.filter(el => el.checked).map(el => el.id).join(',');
    }
    this.filter.size_ranges = this.sizeRanges[0] + '-' + this.sizeRanges[1];
    if (this.filter.size_ranges == '0-250') {
      this.filter.size_ranges = null;
    }
    this.filter.picture_ranges = this.pictureRanges[0] + '-' + this.pictureRanges[1];
    if (this.filter.picture_ranges == '0-10') {
      this.filter.picture_ranges = null;
    }

    const tenure = []
    if (this.leasehold) {
      tenure.push('leasehold');
    }
    if (this.freehold) {
      tenure.push('freehold');
    }
    if (tenure.length == 0) {
      this.filter.tenure = null;
    } else {
      this.filter.tenure = tenure.join(',');
    }

    if (this.selectedStandardMeasurements.length == 0) {
      this.filter.selected_standard_measurements = null
    } else {
      this.filter.selected_standard_measurements = this.selectedStandardMeasurements.join(',')
    }

    this.dialogRef.close(this.filter);
  }

  onSliderChange(
    selectedValues: number[], 
    type: 'room' | 'bedroom' | 'bathroom' | 'indoor_garage' | 'outdoor_garage' | 'sizes' | 'pictures'
  ) {
    switch (type) {
      case 'room': {
        this.roomRanges[0] = selectedValues[0];
        this.roomRanges[1] = selectedValues[1];
        break;
      }
      case 'bedroom': {
        this.bedroomRanges[0] = selectedValues[0];
        this.bedroomRanges[1] = selectedValues[1];
        break;
      }
      case 'bathroom': {
        this.bathroomRanges[0] = selectedValues[0];
        this.bathroomRanges[1] = selectedValues[1];
        break;
      }
      case 'indoor_garage': {
        this.indoorGarageRanges[0] = selectedValues[0];
        this.indoorGarageRanges[1] = selectedValues[1];
        break;
      }
      case 'outdoor_garage': {
        this.outdoorGarageRanges[0] = selectedValues[0];
        this.outdoorGarageRanges[1] = selectedValues[1];
        break;
      }
      case 'sizes': {
        this.sizeRanges[0] = selectedValues[0];
        this.sizeRanges[1] = selectedValues[1];
        break;
      }
      case 'pictures': {
        this.pictureRanges[0] = selectedValues[0];
        this.pictureRanges[1] = selectedValues[1];
        break;
      }
    }
  }

  updateExternalAreas(item, $event) {
    if ($event.checked) {
      this.selectedExternalAreas.push(item.id);
    } else {
      const index = this.selectedExternalAreas.findIndex(el => el === item.id);
      if (index !== -1) {
        this.selectedExternalAreas.splice(index, 1);
      }
    }
  }
  setSelectedExternalArea(item): boolean {
    if (this.selectedExternalAreas.length <= 0) {
      return false
    } else {
      return this.selectedExternalAreas.some(el => el === item.id);
    }
  }

  onHeatingChange(event: MatCheckboxChange, heating: CheckListItem) {
    this.heatings = this._updateChecklists(this.heatings, heating.id, event.checked);
  }
  onServiceChange(event: MatCheckboxChange, service: CheckListItem) {
    this.services = this._updateChecklists(this.services, service.id, event.checked);
  }
  onLandServiceChange(event: MatCheckboxChange, service: CheckListItem) {
    this.landServices = this._updateChecklists(this.landServices, service.id, event.checked);
  }

  _updateChecklists(list: CheckListItem[], id: number, isChecked: boolean): CheckListItem[] {
    return list.map(item => {
      if (item.id == id) {
        return {...item, checked: isChecked}
      }
      return {...item}
    })
  }

  changeConsideration(event: MatCheckboxChange, val: 'freehold' | 'leasehold') {
    switch (val) {
      case 'freehold': 
        this.freehold = event.checked;
        break;
      case 'leasehold':
        this.leasehold = event.checked;
    }
  }

  changeStandard(event: MatCheckboxChange, id: number) {
    if (event.checked) {
      this.selectedStandardMeasurements.push(id);
    } else {
      const idx = this.selectedStandardMeasurements.findIndex(el => el == id);
      if (idx !== -1) {
        this.selectedStandardMeasurements.splice(idx, 1);
      }
    }
  }
}
