import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { mergeMap, take } from "rxjs/operators";
import { ToeTemplateModel, ToeTemplateService, ToeTemplateState } from "src/app/core/template";
import { toeTemplateSelectors } from "src/app/core/template/_selectors";

@Injectable()
export class ToeTemplateResolver implements Resolve<ToeTemplateModel> {

    constructor(
        private store: Store<ToeTemplateState>,
        private service: ToeTemplateService
    ) {}
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ToeTemplateModel | Observable<ToeTemplateModel> | Promise<ToeTemplateModel> {
        const id = Number(route.params['template_id'])
        return this.store.select(toeTemplateSelectors.selectTemplate(id)).pipe(
            take(1),
            mergeMap(template => {
                if (!template) {
                    return this.service.get(id);
                }
                return of(template)
            })
        )
    }
}