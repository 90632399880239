import { Injectable } from "@angular/core";
import { ConflictOfInterestModel } from "src/app/core/toe";

interface ICoi {
    order_num: string;
    tp_related: boolean;
}
@Injectable()
export class FilterCoiService {
    private prevKnowledgeConflicts: ICoi[] = [
        {order_num: "1", tp_related: false}, 
        {order_num: "2", tp_related: false}, 
        {order_num: "3", tp_related: false}, 
        {order_num: "6", tp_related: true}, 
        {order_num: "8", tp_related: true}, 
        {order_num: "10", tp_related: false}
    ];
    private financialInterestConflicts: ICoi[] = [
        {order_num: "4", tp_related: true}, 
        {order_num: "7", tp_related: false}, 
        {order_num: "11", tp_related: false}, 
        {order_num: "12", tp_related: false}
    ];
    private defaultConflicts: ICoi[] = [
        {order_num: "5", tp_related: true}, 
        {order_num: "9A", tp_related: false}, 
        {order_num: "9B", tp_related: true}, 
        {order_num: "13", tp_related: true}, 
        {order_num: "24", tp_related: false}, 
        {order_num: "25", tp_related: false}
    ];
    private securedLengindConflicts: ICoi[] = [
        {order_num: "14", tp_related: false}, 
        {order_num: "15", tp_related: true}, 
        {order_num: "16", tp_related: true}, 
        {order_num: "17A", tp_related: false}, 
        {order_num: "17B", tp_related: true}, 
        {order_num: "18", tp_related: false}, 
        {order_num: "19", tp_related: true}, 
        {order_num: "20", tp_related: true}, 
        {order_num: "21", tp_related: true}, 
        {order_num: "22", tp_related: true}, 
        {order_num: "23", tp_related: false}
    ];
    constructor() {}
   
    public getCiData(arr: ConflictOfInterestModel[], securedLending: boolean = false, hasKnowledge: boolean = false, hasFinancialInterets: boolean = false, isTemplate: boolean = false): ConflictOfInterestModel[] {
        let conflictOfInterests = this.filterArr(arr, this.defaultConflicts, isTemplate);
        if (securedLending) {
            const coi = this.filterArr(arr, this.securedLengindConflicts, isTemplate);
            conflictOfInterests.push(...coi);
        }
        if (hasKnowledge) {
            const coi = this.filterArr(arr, this.prevKnowledgeConflicts, isTemplate);
            conflictOfInterests.push(...coi);
        }
        if (hasFinancialInterets) {
            const coi = this.filterArr(arr, this.financialInterestConflicts, isTemplate);
            conflictOfInterests.push(...coi);
        }
        conflictOfInterests = this.sortCois(conflictOfInterests);
        return conflictOfInterests;
    }

    private filterArr(baseArr: ConflictOfInterestModel[], filterArr: ICoi[], isTemplate: boolean): ConflictOfInterestModel[] {
        const arr = baseArr.filter(item => {
            const idx = filterArr.filter(ref => !isTemplate || !ref.tp_related ).findIndex(ref => ref.order_num === item.order_num);
            return idx > -1;
        });
        return arr; 
    }

    private sortCois(arr: ConflictOfInterestModel[]): ConflictOfInterestModel[] {
        return arr.sort((a, b) => {
            return a.id - b.id;
        });
    }
}