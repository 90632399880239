import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { BaseTemplateDataModel } from "../_models";

export class TemplateTableDataSource<T extends BaseTemplateDataModel> implements DataSource<BaseTemplateDataModel> {
    protected entitySubject$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    protected destroy$: Subject<void> = new Subject();
    public hasData$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public isLoading$: Observable<boolean>;
    public total$: Observable<number>;

    constructor() {}

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.entitySubject$.asObservable().pipe(tap(entities => this.hasData$.next(entities.length > 0)));
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.entitySubject$.complete();
        this.destroy$.next();
        this.destroy$.complete();
    }
}