import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectValuationProcessLoadingState } from 'src/app/core/valuation-process';
import { RemoveComparableFromValuationProcess } from 'src/app/core/valuation-process/_actions/valuation-process.actions';
import { ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput } from '../_sub/comparable-info-dialog-v2/comparable-info-dialog-v2.component';

@Component({
  selector: 'kt-adjustments-tab-v2',
  templateUrl: './adjustments-tab-v2.component.html',
  styleUrls: ['./adjustments-tab-v2.component.scss']
})
export class AdjustmentsTabV2Component implements OnInit {
  loading$ = this.store$.select(selectValuationProcessLoadingState)

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

  onRemoveComparable({refNum}: {refNum: string}) {
    this.store$.dispatch(new RemoveComparableFromValuationProcess({refNum}))
  }

  onShowComparable({refNum}: {refNum: string}) {
    this.dialog.open<ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput>(ComparableInfoDialogV2Component, {
      data: {
        refNums: [refNum],
        isMulti: false
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef
    })
  }

  onShowComparableOverview({refNums}: {refNums: string[]}) {
    this.dialog.open<ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput>(ComparableInfoDialogV2Component, {
      data: {
        refNums,
        isMulti: true
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef
    })
  }
  
}
