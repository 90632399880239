import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, FormControlName, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AssetClassSizeModel } from "src/app/core/comparable";
import { AllStandardMeasurementsRequested, AllUnitAreaMeasurementsRequested, selectAllStandardMeasurements, selectAllUnitAreaMeasurements, StandardMeasurement, UnitAreaMeasurement } from "src/app/core/linked-tables";
import { AppState } from "src/app/core/reducers";

export interface IAddSizeDialogData {
    assetClassType: number,
    selectedMeasurements: number[],
    size?: AssetClassSizeModel,
    isInspectionForm: boolean,
    unitAreaMeasurementId?: number,
    standardMeasurementId?: number,
    standardMeasurements?: any[]
}

@Component({
    selector: 'kt-add-size-dialog',
    templateUrl: './add-size-dialog.component.html',
    styleUrls: ['./add-size-dialog.component.scss']
})
export class AddSizeDialogComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    assetClassTypeName: string;
    standardMeasurements: StandardMeasurement[] = [];
    unitAreaMeasurements: UnitAreaMeasurement[] = [];
    selectedMeasurements: number[];
    size: AssetClassSizeModel;
    subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddSizeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddSizeDialogData,
        private store$: Store<AppState>
    ) {
        switch (data.assetClassType) {
            case 1:
                this.assetClassTypeName = 'Residential';
                break;
            case 2:
                this.assetClassTypeName = 'Parking';
                break;
            case 3:
                this.assetClassTypeName = 'Office';
                break;
            case 5:
                this.assetClassTypeName = 'Retail Store'
                break;
            case 7:
                this.assetClassTypeName = 'Warehouse';
                break;
            case 11:
                this.assetClassTypeName = 'Retail Building';
                break;
            case 17: 
                this.assetClassTypeName = 'House';
                break;
            case 0:
                this.assetClassTypeName = 'Building';
                break;
            default:
                this.assetClassTypeName = 'Unknown';
        }

        if (data.size) {
            this.size = Object.assign({}, data.size);
        } else {
            this.size = new AssetClassSizeModel();
            this.size.clear();
            this.size.standard_measurement_id = data.standardMeasurementId;
        }

        if (data.isInspectionForm) {
            this.size.unit_of_area_measurement_id = data.unitAreaMeasurementId;
        }

        this.selectedMeasurements = data.selectedMeasurements ? data.selectedMeasurements : [];
    }

    ngOnInit() {
        this._createForm();
        this.store$.dispatch(new AllStandardMeasurementsRequested());
        const standardMeasurementsSubscribe = this.store$.pipe(
            select(selectAllStandardMeasurements))
            .subscribe(res => {
                this.standardMeasurements = [];
                if (res) {
                    this.standardMeasurements = this.data.standardMeasurements && this.data.standardMeasurements.length > 0 ? this.data.standardMeasurements : res.filter(measurement => measurement.asset_class_type_id == this.data.assetClassType);
                }
            });
        this.subscriptions.push(standardMeasurementsSubscribe);
        this.store$.dispatch(new AllUnitAreaMeasurementsRequested());
        const unitAreaMeasurementsSubscribe = this.store$.pipe(
            select(selectAllUnitAreaMeasurements))
            .subscribe(res => {
                this.unitAreaMeasurements = [];
                if (res) {
                    this.unitAreaMeasurements = res.reverse();
                }
            });
        this.subscriptions.push(unitAreaMeasurementsSubscribe);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    _createForm() {
        this.form = new UntypedFormGroup({
            standard_measurement_id: new UntypedFormControl({
                    value: this.size.standard_measurement_id,
                    disabled: this.data.isInspectionForm
                }, Validators.required),
            unit_of_area_measurement_id: new UntypedFormControl({
                    value: this.size.unit_of_area_measurement_id,
                    disabled: this.data.isInspectionForm,
                }, Validators.required),
            size: new UntypedFormControl(this.size.size, Validators.required)
        })
    }

    getTitle() {
        if (this.size && this.size.id) {
            // tslint:disable-next-line:no-string-throw
            return `Edit Size`;
        }
        return 'New Size';
    }
    isValid() {return true};
    onNoClick() {this.dialogRef.close()};
    onSubmit() {
        const controls = this.form.controls;
        const standardMeasurementId = controls.standard_measurement_id.value;
        const unitAreaMeasurementId = controls.unit_of_area_measurement_id.value;
        const size = controls.size.value;

        this.size.standard_measurement_id = standardMeasurementId;
        this.size.standard_measurement_name = this.standardMeasurements.find(item => item.id === standardMeasurementId).name;

        this.size.unit_of_area_measurement_id = unitAreaMeasurementId;
        this.size.unit_of_area_measurement_name = this.unitAreaMeasurements.find(item => item.id == unitAreaMeasurementId).name;

        this.size.size = size;

        this.dialogRef.close({size: this.size});
    }
}