<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">Download Documents</h3>
    </div>

    <div class="card-toolbar">
      <button class="btn btn-icon btn-sm btn-light-warning" 
        (click)="onClose()">
        <i class="ki ki-close icon-nm"></i>
      </button>
    </div>
  </div>

  <div class="card-body">
    <mat-dialog-content>
      <div>
        <h5 class="text-mad text-uppercase">Legal & Admin</h5>
        <hr class="active" />
      </div>
      <div class="example-container mat-elevation-z0">
        <mat-table [dataSource]="legalDocuments$">
          <!-- <mat-table> -->
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let file">
              <b>{{ file.type }}</b>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let file">
              <div class="d-flex justify-content-between align-items-center">
                {{ file.task_name }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let file">
              <div>
                <button mat-icon-button color="primary" (click)="onDownloadFile(file.report_url)">
                  <mat-icon>get_app</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['type', 'name', 'actions']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['type', 'name', 'actions']"></mat-row>
        </mat-table>
      </div>

      <ng-container *ngFor="let tpReport of (targetPropertyReports$|async)">
        <div>
          <h5 class="mt-12 text-mad text-uppercase">{{ tpReport.name }}</h5>
          <hr class="active" />
        </div>
        <div class="example-container mat-elevation-z0">
          <mat-table [dataSource]="tpReport.reports">
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
              <mat-cell *matCellDef="let file">
                <span *ngIf="file.type == 1">Signed Valuation Report</span>
                <span *ngIf="file.type == 2">Signed Draft Report</span>
                <span *ngIf="file.type == 3">Signed Audit Report</span>
                <span *ngIf="file.type == 4">Investigation Report</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="uploaded_date">
              <mat-header-cell *matHeaderCellDef>Date uploaded/generated</mat-header-cell>
              <mat-cell *matCellDef="let file">
                {{ file.created_at | date:'dd MMM yyyy, h:mm a' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let file">
                <button mat-icon-button color="primary" (click)="onDownloadFile(file.url)">
                  <mat-icon>get_app</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['type', 'uploaded_date', 'actions']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['type', 'uploaded_date', 'actions']"></mat-row>
          </mat-table>
        </div>
      </ng-container>
    </mat-dialog-content>
  </div>
</div>