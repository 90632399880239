import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { RetailBuildingAdjustmentModel } from '../_models/retail-building-adjustment.model';

export enum RetailBuildingAdjustmentsActionTypes {
    AllRetailBuildingAdjustmentsRequested = '[RetailBuilding Adjustments List] All RetailBuilding Adjustments Requested',
    AllRetailBuildingAdjustmentsLoaded = '[RetailBuilding Adjustments API] All RetailBuilding Adjustments Loaded',

    RetailBuildingAdjustmentOnServerCreated = '[Edit RetailBuilding Adjustment] RetailBuilding Adjustment on Server Created',
    RetailBuildingAdjustmentCreated = '[Edit RetailBuilding Adjustment] RetailBuilding Adjustment Created',
    RetailBuildingAdjustmentUpdated = '[Edit RetailBuilding Adjustment] RetailBuilding Adjustment Updated',
    RetailBuildingAdjustmentDeleted = '[RetailBuilding Adjustment Deleted] RetailBuilding Adjustment Deleted',

    RetailBuildingAdjustmentRestored = '[RetailBuilding Adjustment Trash] RetailBuilding Adjustment Restored',
    RetailBuildingAdjustmentOnServerRestored = '[RetailBuilding Adjustment Trash] RetailBuilding Adjustment On Server Restored',

    RetailBuildingAdjustmentOnServerAdminRestored = '[RetailBuilding Adjustment Admin Trash] RetailBuilding Adjustment On Server Restored',
    RetailBuildingAdjustmentAdminRestored = '[RetailBuilding Adjustment Admin Trash] RetailBuilding Adjustment Restored',

    RetailBuildingAdjustmentDeletedFromTrash = '[RetailBuilding Adjustment Trash] RetailBuilding Adjustment deleted',
    RetailBuildingAdjustmentDeletedFromAdminTrash = '[RetailBuilding Adjustment Admin Trash] RetailBuilding Adjustment deleted',

    RetailBuildingAdjustmentTrash = 'RetailBuilding Adjustment Trashed',
    RetailBuildingAdjustmentTrashFlushed = 'RetailBuilding Adjustment Trash Flushed',

    // Page system

    RetailBuildingAdjustmentsPageRequested = '[RetailBuilding Adjustment List Page] RetailBuilding Adjustment Page Requested',
    RetailBuildingAdjustmentsPageLoaded = '[RetailBuilding Adjustment API] RetailBuilding Adjustment Page Loaded',
    RetailBuildingAdjustmentsPageCancelled = '[RetailBuilding Adjustment API] RetailBuilding Adjustment Page Cancelled',

    RetailBuildingAdjustmentsPageToggleLoading = '[RetailBuilding Adjustment page] RetailBuilding Adjustment Page Toggle Loading',
    RetailBuildingAdjustmentActionToggleLoading = '[RetailBuilding Adjustment] RetailBuilding Adjustment Action Toggle Loading'
}

export class RetailBuildingAdjustmentOnServerCreated implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentOnServerCreated;

    constructor(public payload: { item: RetailBuildingAdjustmentModel }) {
    }
}

export class RetailBuildingAdjustmentCreated implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentCreated;

    constructor(public payload: { item: RetailBuildingAdjustmentModel }) {
    }
}

export class RetailBuildingAdjustmentUpdated implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<RetailBuildingAdjustmentModel>,
        item: RetailBuildingAdjustmentModel
    }) {
    }
}

export class RetailBuildingAdjustmentDeleted implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RetailBuildingAdjustmentsPageRequested implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class RetailBuildingAdjustmentsPageLoaded implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentsPageLoaded;

    constructor(public payload: { items: RetailBuildingAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class RetailBuildingAdjustmentsPageCancelled implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentsPageCancelled;
}

export class AllRetailBuildingAdjustmentsRequested implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.AllRetailBuildingAdjustmentsRequested;
}

export class AllRetailBuildingAdjustmentsLoaded implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.AllRetailBuildingAdjustmentsLoaded;

    constructor(public payload: { items: RetailBuildingAdjustmentModel[] }) {
    }
}

export class RetailBuildingAdjustmentsPageToggleLoading implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RetailBuildingAdjustmentActionToggleLoading implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class RetailBuildingAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RetailBuildingAdjustmentDeletedFromTrash implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RetailBuildingAdjustmentOnServerRestored implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RetailBuildingAdjustmentRestored implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentRestored;

    constructor(public payload: { item: RetailBuildingAdjustmentModel }) {
    }
}

export class RetailBuildingAdjustmentOnServerAdminRestored implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RetailBuildingAdjustmentAdminRestored implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentAdminRestored;

    constructor(public payload: { item: RetailBuildingAdjustmentModel }) {
    }
}

export class RetailBuildingAdjustmentTrashFlushed implements Action {
    readonly type = RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentTrashFlushed;

    constructor() {
    }
}

export type RetailBuildingAdjustmentActions = RetailBuildingAdjustmentCreated
    | RetailBuildingAdjustmentUpdated
    | RetailBuildingAdjustmentDeleted
    | RetailBuildingAdjustmentsPageRequested
    | RetailBuildingAdjustmentsPageLoaded
    | RetailBuildingAdjustmentsPageCancelled
    | AllRetailBuildingAdjustmentsLoaded
    | AllRetailBuildingAdjustmentsRequested
    | RetailBuildingAdjustmentOnServerCreated
    | RetailBuildingAdjustmentDeletedFromAdminTrash
    | RetailBuildingAdjustmentDeletedFromTrash
    | RetailBuildingAdjustmentOnServerRestored
    | RetailBuildingAdjustmentRestored
    | RetailBuildingAdjustmentOnServerAdminRestored
    | RetailBuildingAdjustmentAdminRestored
    | RetailBuildingAdjustmentTrashFlushed
    | RetailBuildingAdjustmentsPageToggleLoading
    | RetailBuildingAdjustmentActionToggleLoading;