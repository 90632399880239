// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {DeparturesService} from '../_services/departure.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allDeparturesLoaded} from '../_selectors/departure.selectors';
// Actions
import {
    AllDeparturesLoaded,
    AllDeparturesRequested,
    DepartureActionTypes,
    DeparturesPageRequested,
    DeparturesPageLoaded,
    DepartureUpdated,
    DeparturesPageToggleLoading,
    DepartureDeleted,
    DepartureOnServerCreated,
    DepartureCreated,
    DepartureAdminRestored,
    DepartureDeletedFromAdminTrash,
    DepartureDeletedFromTrash,
    DepartureOnServerAdminRestored,
    DepartureOnServerRestored,
    DepartureRestored,
    DepartureTrashFlushed,
    DeparturesActionToggleLoading
} from '../_actions/departure.actions';

@Injectable()
export class DepartureEffects {
    showPageLoadingDispatcher = new DeparturesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new DeparturesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new DeparturesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllDepartures$ = this.actions$
        .pipe(
            ofType<AllDeparturesRequested>(DepartureActionTypes.AllDeparturesRequested),
            withLatestFrom(this.store.pipe(select(allDeparturesLoaded))),
            filter(([action, isAllDeparturesLoaded]) => !isAllDeparturesLoaded),
            mergeMap(() => this.service.getAllDepartures()),
            map(res => {
                return new AllDeparturesLoaded({departures: res.data});
            })
        );

    @Effect()
    loadDeparturesPage$ = this.actions$
        .pipe(
            ofType<DeparturesPageRequested>(DepartureActionTypes.DeparturesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findDepartures(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new DeparturesPageLoaded({
                    departures: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteDeparture$ = this.actions$
        .pipe(
            ofType<DepartureDeleted>(DepartureActionTypes.DepartureDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteDeparture(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateDeparture$ = this.actions$
        .pipe(
            ofType<DepartureUpdated>(DepartureActionTypes.DepartureUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateDeparture(payload.departure);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createDeparture$ = this.actions$
        .pipe(
            ofType<DepartureOnServerCreated>(DepartureActionTypes.DepartureOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createDeparture(payload.departure).pipe(
                    tap(res => {
                        this.store.dispatch(new DepartureCreated({departure: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushDepartureTrash$ = this.actions$
        .pipe(
            ofType<DepartureTrashFlushed>(DepartureActionTypes.DepartureTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreDeparture$ = this.actions$
        .pipe(
            ofType<DepartureOnServerRestored>(DepartureActionTypes.DepartureOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new DepartureRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminDeparture$ = this.actions$
        .pipe(
            ofType<DepartureOnServerAdminRestored>(DepartureActionTypes.DepartureOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new DepartureAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashDeparture$ = this.actions$
        .pipe(
            ofType<DepartureDeletedFromTrash>(DepartureActionTypes.DepartureDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashDeparture$ = this.actions$
        .pipe(
            ofType<DepartureDeletedFromAdminTrash>(DepartureActionTypes.DepartureDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: DeparturesService, private store: Store<AppState>) {
    }
}
