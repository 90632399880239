import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TagInputDropdown } from 'ngx-chips';
import { TagModel } from 'ngx-chips/core/accessor';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetClassType, selectAllAssetClassTypes } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { selectValuationComparableSelectedPropertySubTypeIds } from 'src/app/core/valuation-process/_selectors/valuation-comparable.selectors';

@Component({
  selector: 'kt-property-sub-types-filter',
  templateUrl: './property-sub-types-filter.component.html',
  styleUrls: ['./property-sub-types-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertySubTypesFilterComponent implements OnInit, OnDestroy {
  @Input() defaultPropertySubTypeId: number;
  @Output() propertySubTypeAdd: EventEmitter<TagModel> = new EventEmitter()
  @Output() propertySubTypeRemove: EventEmitter<TagModel> = new EventEmitter()

  selectedPropertySubTypes: TagModel[] = []
  propertySubTypes: TagModel[] = []

  private _propertySubTypes: AssetClassType[] = []
  private _onDestroy$: Subject<void> = new Subject()

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.store$.select(selectAllAssetClassTypes),
      this.store$.select(selectValuationComparableSelectedPropertySubTypeIds)
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([res, ids]) => {
      this._propertySubTypes = res ? res : []
      this.propertySubTypes = this._propertySubTypes
          .filter(item => item.id != 16)
          .map(item => ({
            display: item.id == 13 ? 'Land' : item.name,
            value: item.id
          }))

      this.selectedPropertySubTypes = ids
        .map(id => {
          return this._propertySubTypes.find(item => item.id == id)
        }) 
        .filter(item => item != undefined)
        .map(item => {
          return {
            className: this._getClassName(item),
            display: item.id == 13 ? 'Land' : item.name,
            value: item.id,
            readonly: item.id == this.defaultPropertySubTypeId
          }
        })
    })
  }

  private _getClassName(item: AssetClassType) {
    switch (item.id) {
      case 1:
        return 'apartment'
      case 2:
        return 'parking'
      case 3:
        return 'office'
      case 5:
        return 'retail_shop'
      case 7:
        return 'warehouse'
      case 11:
        return 'retail_building'
      case 13:
        return 'land'
      case 17:
        return 'house'
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next() 
    this._onDestroy$.complete()
  }
  
  onPropertySubTypeAdd(event: TagModel) {
    this.propertySubTypeAdd.emit(event)
  }

  onPropertySubTypeRemove(event: TagModel) {
    this.propertySubTypeRemove.emit(event)
  }
}
