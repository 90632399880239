import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InternalAspectData } from 'src/app/core/comparable/_models/asset-class-internal-aspect.model';
import { AllBathroomFittingsRequested, AllBuiltinFittingsRequested, AllCeilingsRequested, AllChimneyStacksRequested, AllConservatoryPorchesRequested, AllCubicContentsRequested, AllFireplacesChimneyBreastsRequested, AllFloorTypesLoaded, AllFloorTypesRequested, AllMainWallsRequested, AllOtherJoinerysRequested, AllOutsideDoorsRequested, AllRainwaterPipesRequested, AllRoofCoveringsRequested, AllRoofStructuresRequested, AllShopFrontTypesRequested, AllStorageTypesRequested, AllWallsAndPartitionsRequested, AllWindowTypesRequested, BathroomFitting, BuiltinFitting, Ceiling, ChimneyStackModel, ConservatoryPorcheModel, CubicContent, FireplacesChimneyBreast, FloorType, MainWallModel, OtherJoineryModel, OutsideDoorModel, RainwaterPipeModel, RoofCoveringModel, RoofStructure, selectAllBathroomFittings, selectAllBuiltinFittings, selectAllCeilings, selectAllChimneyStacks, selectAllConservatoryPorches, selectAllCubicContents, selectAllFireplacesChimneyBreasts, selectAllFloorTypes, selectAllMainWalls, selectAllOtherJoinerys, selectAllOutsideDoors, selectAllRainwaterPipes, selectAllRoofCoverings, selectAllRoofStructures, selectAllShopFrontTypes, selectAllStorageTypes, selectAllWallsAndPartitions, selectAllWindowTypes, ShopFrontTypeModel, StorageType, WallsAndPartition, WindowType } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ValidateProperty } from '../../validators/property.validator';
import { BuiltinFittingsSubListComponent } from '../builtin-fittings/list/builtin-fittings-sub-list.component';
import { BathroomFittingsSubListComponent } from '../bathroom-fittings/list/bathroom-fittings-sub-list.component';

@Component({
  selector: 'kt-internal-aspects',
  templateUrl: './internal-aspects.component.html',
  styleUrls: ['./internal-aspects.component.scss']
})
export class InternalAspectsComponent implements OnInit, OnDestroy {
  @Input() propertySubTypeID: number;
  @Input() internalAspectData: InternalAspectData;
  formGroup: UntypedFormGroup;

  @ViewChild('builtinFitting') builtinFittingsSubListComponent: BuiltinFittingsSubListComponent;
  @ViewChild('bathroomFitting') bathroomFittingsSubListComponent: BathroomFittingsSubListComponent;

  private _onDestroy$: Subject<void> = new Subject();
  // public facilitiesInValid$: Subject<boolean> = new Subject();

  // Linked tables
  public roofStructures: RoofStructure[] = [];
  public ceilings: Ceiling[] = [];
  public floorTypes: FloorType[] = [];
  public fireplacesChimneyBreasts: FireplacesChimneyBreast[] = [];
  public builtinFittings: BuiltinFitting[] = [];
  public bathroomFittings: BathroomFitting[] = [];
  public storageTypes: StorageType[] = [];
  public wallsAndPartitions: WallsAndPartition[] = [];
  public selectedBuiltinFittings: any[] = [];
  public selectedBathroomFittings: any[] = [];
  public shopFrontTypes: ShopFrontTypeModel[] = [];
  public windows: WindowType[] = [];

  automation_details: CheckListItem[] = [
    {id: 1, title: "Goods-to-person (GTP)", checked: false},
    {id: 2, title: "Automated Storage and Retrieval Systems (AS/RS)", checked: false},
    {id: 3, title: "Automatic Guided Vehicles (AGVs)", checked: false},
    {id: 4, title: "Autonomous Mobile Robots", checked: false},
    {id: 5, title: "Pick-to-Light and Put-to-Light Systems", checked: false},
    {id: 6, title: "Voice Picking and Tasking", checked: false},
    {id: 7, title: "Automated Sortation Systems", checked: false},
  ]
  showAutomationDetails: boolean = false;
  errorFields = [];

  private roofStructures$: BehaviorSubject<RoofStructure[]> = new BehaviorSubject([]);
  private shopFrontTypes$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private ceilings$: BehaviorSubject<Ceiling[]> = new BehaviorSubject([]);
  private wallsAndPartitions$: BehaviorSubject<WallsAndPartition[]> = new BehaviorSubject([]);
  private storageTypes$: BehaviorSubject<StorageType[]> = new BehaviorSubject([]);
  private floors$: BehaviorSubject<FloorType[]> = new BehaviorSubject([]);
  private fireplaces$: BehaviorSubject<FireplacesChimneyBreast[]> = new BehaviorSubject([]);
  private windows$: BehaviorSubject<WindowType[]> = new BehaviorSubject([]);
  private _formFields = [
    {
      select_name: 'Windows', 
      select_formControlName: 'window_type_id', 
      selectables: this.windows$.asObservable(),
      comment_name: 'Windows comment', 
      comment_formControlName: 'window_type_comment',
      ac_ids: [1, 3]
    },
    {
      select_name: 'Shop front type', 
      select_formControlName: 'shop_front_type_id', 
      selectables: this.shopFrontTypes$.asObservable(),
      comment_name: 'Shop front comment', 
      comment_formControlName: 'shop_front_type_comment',
      ac_ids: [5, 11]
    },
    {
      select_name: 'Roof Structure', 
      select_formControlName: 'roof_structure_id', 
      selectables: this.roofStructures$.asObservable(),
      comment_name: 'Roof Structure comment', 
      comment_formControlName: 'roof_structure_comment',
      ac_ids: [7, 11, 17]
    },
    {
      select_name: 'Ceilings', 
      select_formControlName: 'ceiling_id', 
      selectables: this.ceilings$.asObservable(),
      comment_name: 'Ceilings comment', 
      comment_formControlName: 'ceiling_comment',
      ac_ids: [5, 7, 11, 17, 1, 3]
    },
    {
      select_name: 'Walls and partitions', 
      select_formControlName: 'walls_and_partition_id', 
      selectables: this.wallsAndPartitions$.asObservable(),
      comment_name: 'Walls and partitions comment', 
      comment_formControlName: 'walls_and_partition_comment',
      ac_ids: [5, 7, 11, 17, -1, 1, 3]
    },
    {
      select_name: 'Height', 
      select_formControlName: 'height', 
      comment_name: 'Height comment', 
      comment_formControlName: 'height_comment',
      ac_ids: [7, 11]
    },
    {
      select_name: 'Cubic content', 
      select_formControlName: 'cubic_content', 
      comment_name: 'Cubic content comment', 
      comment_formControlName: 'cubic_content_comment',
      ac_ids: [7, 11]
    },
    {
      select_name: 'Storage type', 
      select_formControlName: 'storage_type_id', 
      selectables: this.storageTypes$.asObservable(),
      comment_name: 'Storage type comment', 
      comment_formControlName: 'storage_type_comment',
      ac_ids: [7]
    },
    {
      select_name: 'Floors',
      select_formControlName: 'floor_type_id',
      selectables: this.floors$.asObservable(),
      comment_name: 'Floors comment',
      comment_formControlName: 'floor_type_comment',
      ac_ids: [5, 7, 11, 17, 1, 3]
    },
    {
      select_name: 'Fireplaces, chimney breasts and flues',
      select_formControlName: 'fireplaces_chimney_breast_id',
      selectables: this.fireplaces$.asObservable(),
      comment_name: 'Fireplaces, chimney breasts and flues comment',
      comment_formControlName: 'fireplaces_chimney_breast_comment',
      ac_ids: [17]
    },
    {
      select_name: 'Woodwork',
      select_formControlName: 'woodwork',
      comment_name: 'Woodwork comment',
      comment_formControlName: 'woodwork_comment',
      ac_ids: [17],
      has_tooltip: true
    }
  ];
  public formFields = []

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.formFields = this._getFormFields(this.propertySubTypeID);
    this._fetchLTs();

    this.formGroup = this.formBuilder.group({
      roof_structure_id: [this.internalAspectData.roof_structure_id, (this.propertySubTypeID != 5 && this.propertySubTypeID != 1 && this.propertySubTypeID != 3) ? Validators.required : null],
      roof_structure_comment: [this.internalAspectData.roof_structure_comment],
      ceiling_id: [this.internalAspectData.ceiling_id, Validators.required],
      ceiling_comment: [this.internalAspectData.ceiling_comment],
      walls_and_partition_id: [this.internalAspectData.walls_and_partition_id, Validators.required],
      walls_and_partition_comment: [this.internalAspectData.walls_and_partition_comment],
      floor_type_id: [this.internalAspectData.floor_type_id, Validators.required],
      floor_type_comment: [this.internalAspectData.floor_type_comment],
      fireplaces_chimney_breast_id: [this.internalAspectData.fireplaces_chimney_breast_id, this.propertySubTypeID == 17 ? Validators.required : null],
      fireplaces_chimney_breast_comment: [this.internalAspectData.fireplaces_chimney_breast_comment],
      woodwork: [this.internalAspectData.woodwork, this.propertySubTypeID == 17 ? Validators.required : null],
      woodwork_comment: [this.internalAspectData.woodwork_comment],
      
      height: [this.internalAspectData.height, this.propertySubTypeID == 7 ? Validators.required : null],
      height_comment: [this.internalAspectData.height_comment],
      cubic_content: [this.internalAspectData.cubic_content, this.propertySubTypeID == 7 ? Validators.required : null],
      cubic_content_comment: [this.internalAspectData.cubic_content_comment],
      storage_type_id: [this.internalAspectData.storage_type_id, this.propertySubTypeID == 7 ? Validators.required : null],
      storage_type_comment: [this.internalAspectData.storage_type_comment],

      shop_front_type_id: [this.internalAspectData.shop_front_type_id, (this.propertySubTypeID == 5 || this.propertySubTypeID == 11) ? Validators.required : null],
      shop_front_type_comment: [this.internalAspectData.shop_front_type_comment],

      window_type_id: [this.internalAspectData.windows_id, (this.propertySubTypeID == 1 || this.propertySubTypeID == 3) ? Validators.required : null],
      window_type_comment: [this.internalAspectData.windows_comment],

      has_other: [this.internalAspectData.has_other],
      other_aspects: [this.internalAspectData.other_aspects],

      has_automation: [this.internalAspectData.has_automation],
    });

    if (this.formGroup.controls.has_automation.value == true) {
      this.showAutomationDetails = true;
    }

    this.internalAspectData.automations.forEach(s => {
      this.automation_details = this._updateChecklists(this.automation_details, s, true);
    });

    this.formGroup.controls.has_other.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      if (val) {
        this.formGroup.controls.other_aspects.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.other_aspects.clearValidators();
      }
      this.formGroup.controls.other_aspects.updateValueAndValidity();
    })

    this.formGroup.controls.has_automation.valueChanges.subscribe(val => {
      const boolVal = Boolean(val);
      this.showAutomationDetails = boolVal;
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _fetchLTs() {
    this.store.dispatch(new AllRoofStructuresRequested());
    this.store.select(selectAllRoofStructures).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.roofStructures = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.roofStructures$.next(this.roofStructures);
    });

    this.store.dispatch(new AllCeilingsRequested());
    this.store.select(selectAllCeilings).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.ceilings = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.ceilings$.next(this.ceilings);
    });

    this.store.dispatch(new AllFloorTypesRequested());
    this.store.select(selectAllFloorTypes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.floorTypes = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.floors$.next(this.floorTypes);
    });

    this.store.dispatch(new AllFireplacesChimneyBreastsRequested());
    this.store.select(selectAllFireplacesChimneyBreasts).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.fireplacesChimneyBreasts = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.fireplaces$.next(this.fireplacesChimneyBreasts);
    });

    this.store.dispatch(new AllBuiltinFittingsRequested());
    this.store.select(selectAllBuiltinFittings).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.builtinFittings = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      if (this.internalAspectData.builtin_fitting_ids && this.internalAspectData.builtin_fitting_ids.length > 0 && this.builtinFittings.length > 0) {
        this.selectedBuiltinFittings = this.builtinFittings.filter(item => this.internalAspectData.builtin_fitting_ids.split(',').includes(item.id.toString()))
      }
    });

    this.store.dispatch(new AllBathroomFittingsRequested());
    this.store.select(selectAllBathroomFittings).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.bathroomFittings = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : []
      if (this.internalAspectData.bathroom_fitting_ids && this.internalAspectData.bathroom_fitting_ids.length > 0 && this.bathroomFittings.length > 0) {
        this.selectedBathroomFittings = this.bathroomFittings.filter(item => this.internalAspectData.bathroom_fitting_ids.split(',').includes(item.id.toString()))
      }
    });

    this.store.dispatch(new AllStorageTypesRequested());
    this.store.select(selectAllStorageTypes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.storageTypes = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.storageTypes$.next(this.storageTypes);
    });

    this.store.dispatch(new AllWallsAndPartitionsRequested());
    this.store.select(selectAllWallsAndPartitions).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.wallsAndPartitions = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.wallsAndPartitions$.next(this.wallsAndPartitions);
    });

    this.store.dispatch(new AllShopFrontTypesRequested());
    this.store.select(selectAllShopFrontTypes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.shopFrontTypes = res ? res : [];
      this.shopFrontTypes$.next(this.shopFrontTypes);
    })
    
    this.store.dispatch(new AllWindowTypesRequested());
    this.store.select(selectAllWindowTypes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.windows = res ? res : [];
      this.windows$.next(this.windows);
    })
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName == 'window_type_id' ? 'window_type_intern' : cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public changeValidation() {
    Object.keys(this.formGroup.controls).forEach(cName => {
      this.formGroup.controls[cName].clearValidators();
      this.formGroup.controls[cName].updateValueAndValidity();
    })
  }

  public getData(): InternalAspectData {
    const controls = this.formGroup.controls;
    return {
      id: this.internalAspectData.id,
      roof_structure_id: controls.roof_structure_id.value,
      roof_structure_comment: controls.roof_structure_comment.value,

      ceiling_id: controls.ceiling_id.value,
      ceiling_comment: controls.ceiling_comment.value,

      walls_and_partition_id: controls.walls_and_partition_id.value,
      walls_and_partition_comment: controls.walls_and_partition_comment.value,

      floor_type_id: controls.floor_type_id.value,
      floor_type_comment: controls.floor_type_comment.value,

      fireplaces_chimney_breast_id: controls.fireplaces_chimney_breast_id.value,
      fireplaces_chimney_breast_comment: controls.fireplaces_chimney_breast_comment.value,

      bathroom_fitting_ids: this.bathroomFittingsSubListComponent 
        ? this.bathroomFittingsSubListComponent.currentBathroomFittings.length == 0 ? '' : this.bathroomFittingsSubListComponent.currentBathroomFittings.map(item => item.id).join(',')
        : '',
      builtin_fitting_ids: this.builtinFittingsSubListComponent.currentBuiltinFittings.length == 0 ? '' : this.builtinFittingsSubListComponent.currentBuiltinFittings.map(item => item.id).join(','),
      woodwork: controls.woodwork.value,
      woodwork_comment: controls.woodwork_comment.value,

      height: controls.height.value,
      height_comment: controls.height_comment.value,

      cubic_content: controls.cubic_content.value,
      cubic_content_comment: controls.cubic_content_comment.value,

      storage_type_id: controls.storage_type_id.value,
      storage_type_comment: controls.storage_type_comment.value,

      shop_front_type_id: controls.shop_front_type_id.value,
      shop_front_type_comment: controls.shop_front_type_comment.value,

      has_other: controls.has_other.value,
      other_aspects: controls.other_aspects.value,

      has_automation: controls.has_automation.value,
      automations: this.automation_details.filter(item => item.checked).map(item => item.id),

      above_floors: null,
      below_floors: null,
      state_of_repair_id: null,
      building_facilities_ids: null,

      windows_id: controls.window_type_id.value,
      windows_comment: controls.window_type_comment.value
    }
  }

  onServiceChange(event: MatCheckboxChange, service: CheckListItem) {
    this.automation_details = this._updateChecklists(this.automation_details, service.id, event.checked);
  }

  _updateChecklists(list: CheckListItem[], id: number, isChecked: boolean): CheckListItem[] {
    return list.map(item => {
      if (item.id == id) {
        return {...item, checked: isChecked}
      }
      return {...item}
    })
  }

  _getFormFields(id: number): any[] {
    return this._formFields.filter(field => {
      const ind = field.ac_ids.findIndex(_id => _id == id);
      if (ind > -1) {
        return true;
      }
      return false;
    })
  }
}

type CheckListItem = {
  id: number,
  title: string,
  checked: boolean
}
