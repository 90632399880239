import { ConsiderationConversionData, ConsiderationConversionProcess } from "../../valuation/_models/criterion.model"
import { VAssetClassConsideration, VAssetClassConsiderationRentEffective, VAssetClassConsiderationRentHeadline, VAssetClassConsiderationSale, VTargetPropertyConsideration } from "./valuation-asset-class-common.models"

export type SizeCriterionModel = {
    id: number,
    name: string,
    publicName: string,

    type: 'Size'

    values: { [key: string]: number | null }

    active: boolean
}

export type CriterionModel
    = DefaultCriterionModel
    | CustomCriterionModel

export type CriterionValue = {
    kind: 'unknown'
} | {
    kind: 'not applicable'
} | {
    kind: 'simple',
    value: string
}

export enum CriterionCategory {
    Location = 1,
    Property = 2,
    Building = 3,
    Other = 4,
    ExternalAspect = 5,
    InternalAspect = 6,
    Ground = 7,
    LandParcel = 8,
    LeaseDetail = 9
}

export function categoryName(category: CriterionCategory): string {
    switch (category) {
        case CriterionCategory.Location:
            return 'Location'
        case CriterionCategory.Property:
            return 'Property'
        case CriterionCategory.Other:
            return 'Other'
        case CriterionCategory.Building:
            return 'Building'
        case CriterionCategory.ExternalAspect:
            return 'External Aspect'
        case CriterionCategory.InternalAspect:
            return 'Internal Aspect'
        case CriterionCategory.Ground:
            return 'Ground'
        case CriterionCategory.LandParcel:
            return 'Land Parcel'
        case CriterionCategory.LeaseDetail:
            return 'Lease Detail'
        default:
            return 'Unknown'
    }
}

export type DefaultCriterionModel = {
    id: number,
    name: string,
    publicName: string,

    values: { [key: string]: CriterionValue }

    type: 'Default'

    active: boolean

    category: CriterionCategory,
    categoryName: string,
    field: string,
    isChanged: boolean
}

export type ConsiderationValue = {
    kind: 'no process',
    value: number | null,
    consideration: VAssetClassConsideration
} | ConsiderationValueWithProcess

export type ConsiderationValueSaleToRent = {
    kind: 'sale-to-rent',
    value: number | null,
    consideration: VAssetClassConsiderationSale,
    considerationConvertionData: ConsiderationConversionData
}

export type ConsiderationValueHeadlineToEffective = {
    kind: 'headline-to-effective',
    value: number | null,
    consideration: VAssetClassConsiderationRentHeadline,
    considerationConvertionData: ConsiderationConversionData
}

export type ConsiderationValueHeadlineToEffectiveRentToSale = {
    kind: 'headline-to-effective-to-rent-to-sale',
    value: number | null,
    consideration: VAssetClassConsiderationRentHeadline
    considerationConvertionData: ConsiderationConversionData
}

export type ConsiderationValueWithProcess 
    = ConsiderationValueSaleToRent 
    | ConsiderationValueHeadlineToEffective | {
    kind: 'rent-to-sale',
    value: number | null,
    consideration: VAssetClassConsiderationRentEffective
    considerationConvertionData: ConsiderationConversionData
} | ConsiderationValueHeadlineToEffectiveRentToSale

export function createConsiderationConvertionData(process: ConsiderationConversionProcess): ConsiderationConversionData {
    return {
        process,
        methods: {
            mainMethod: undefined,
            subMethod: undefined,
        },
        rateValues: {
            capRate: undefined,
            capRateJustification: null,
            targetRate: undefined,
            targetRateJustification: null,
        },
        cfmRateValues: {
            targetRate: undefined,
            targetRateJustification: null,
            growthRate: undefined,
            growthRateJustification: null,
            effectiveRentJustification: null,
        },
        selected: {
            main: undefined,
            index: undefined,
        },
        ary: undefined,
    }
}

export type ConsiderationCriterionModel = {
    name: string,
    publicName: string,

    values: { [key: string]: ConsiderationValue }

    type: 'Consideration'

    // tenure: VAssetClassConsideration['tenure']
    considerationType: VTargetPropertyConsideration['considerationType']
    categoryName: string
    countryCurrency: string
}

export type CustomCriterionModel = {
    id: number
    name: string,
    publicName: string,
    values: {[key: string]: {kind: 'simple', value: string}},
    type: 'Custom',

    category: CriterionCategory,
    categoryName: string,
    isChanged: boolean
}