import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassHouseModel } from "../_models/asset-class-house.model";

export enum AssetClassHouseActionTypes {
    AssetClassHouseActionToggleLoading = '[AssetClassHouse Effect] AssetClassHouse Action Toggle Loading',

    AssetClassHouseOnServerCreated = '[Edit AssetClassHouse page] AssetClassHouse On Server Created',
    AssetClassHouseCreated = '[AssetClassHouse Effect] AssetClassHouse Created',
    
    AssetClassHouseOnServerUpdated = '[Edit AssetClassHouse page] AssetClassHouse On Server Updated',
    AssetClassHouseUpdated = '[AssetClassHouse Effect] AssetClassHouse Updated',

    AssetClassHouseDeleted = '[AssetClassHouse List Page] Asset Class Land Deleted',
    AssetClassHouseDeletedFromTrash = '[AssetClassHouse Trash Modal] Asset Class Land Deleted from Trash',
    AssetClassHouseDeletedFromAdminTrash = '[AssetClassHouse Trash Modal] Asset Class House Deleted from Admin Trash',
    AssetClassHouseTrashFlushed = '[AssetClassHouseShop Trash Modal] Asset Class House Shop Trash Flushed',

    AssetClassHouseOnServerRestored = '[AssetClassHouse Trash Modal] Asset Class Land Restored',
    AssetClassHouseRestored = '[AssetClassHouse Effect] Asset Class Land Restored',
    AssetClassHouseOnServerAdminRestored = '[AssetClassHouse Admin Trash Modal] Asset Class Land Admin Restored',
    AssetClassHouseAdminRestored = '[AssetClassHouse Effect] Asset Class Land Admin Restored',

    AssetClassHousePageToggleLoading = '[AssetClassHouse Effect] AssetClassHouse Page Toggle Loading',
    AssetClassHousePageRequested = '[AssetClassHouse List Page] All AssetClassHouse requested',
    AssetClassHousePageLoaded = '[AssetClassHouse Effect] All AssetClassHouse loaded',

    UpdatePreviouslyCreatedACId = '[AssetClassHouse Edit] Update Previously Created AssetClassHouse Id',

    DuplicateAssetClass = '[AssetClassHouse List Page] Duplicate Asset Class'
}

export class AssetClassHouseOnServerCreated implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseOnServerCreated;
    constructor(public payload: {
        AssetClassHouse: AssetClassHouseModel,
        fileList: any[],
    }) {}
}

export class AssetClassHouseCreated implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseCreated;
    constructor(public payload: {AssetClassHouse: AssetClassHouseModel}) {}
}

export class AssetClassHouseOnServerUpdated implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseOnServerUpdated;
    constructor(public payload: {
        AssetClassHouse: AssetClassHouseModel,
        fileList: any[] 
    }) {}
}

export class AssetClassHouseUpdated implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseUpdated;
    constructor(public payload: {AssetClassHouse: AssetClassHouseModel}) {}
}

export class AssetClassHousePageRequested implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHousePageRequested;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class AssetClassHousePageLoaded implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHousePageLoaded;
    constructor(public payload: {
        AssetClassHouses: AssetClassHouseModel[],
        totalCount: number;
        totalTrashed: number;
        totalAdminTrashed: number;
        page: QueryParamsModel
    }) {}
}

export class AssetClassHouseDeleted implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseDeleted;
    constructor(public payload: {id: number}) {}
}

export class AssetClassHouseDeletedFromTrash implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseDeletedFromTrash;
    constructor(public payload: {id: number}) {}
}

export class AssetClassHouseOnServerRestored implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseOnServerRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassHouseRestored implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseRestored;
    constructor(public payload: {ac: AssetClassHouseModel}) {}
}

export class AssetClassHouseOnServerAdminRestored implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseOnServerAdminRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassHouseAdminRestored implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseAdminRestored;
    constructor(public payload: {ac: AssetClassHouseModel}) {}
}

export class AssetClassHousePageToggleLoading implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHousePageToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassHouseActionToggleLoading implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassHouseDeletedFromAdminTrash implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassHouseTrashFlushed implements Action {
    readonly type = AssetClassHouseActionTypes.AssetClassHouseTrashFlushed;
    constructor() {}
}

export class UpdatePreviouslyCreatedACHouseId implements Action {
    readonly type = AssetClassHouseActionTypes.UpdatePreviouslyCreatedACId;
    constructor() {}
}

export class AssetClassHouseDuplicate implements Action {
    readonly type = AssetClassHouseActionTypes.DuplicateAssetClass;

    constructor (public payload: {id: number}) {}
}


export type AssetClassHouseActions 
    = AssetClassHouseOnServerCreated
    | AssetClassHouseCreated

    | AssetClassHouseOnServerUpdated
    | AssetClassHouseUpdated

    | AssetClassHousePageRequested
    | AssetClassHousePageLoaded

    | AssetClassHouseDeleted
    | AssetClassHouseDeletedFromTrash
    | AssetClassHouseDeletedFromAdminTrash
    | AssetClassHouseTrashFlushed

    | AssetClassHouseOnServerRestored
    | AssetClassHouseOnServerAdminRestored
    | AssetClassHouseRestored
    | AssetClassHouseAdminRestored
    
    | AssetClassHousePageToggleLoading
    | AssetClassHouseActionToggleLoading
    
    | UpdatePreviouslyCreatedACHouseId
    
    | AssetClassHouseDuplicate;