// Angular
import {Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {Subscription, ReplaySubject, Subject, combineLatest, Observable} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ValuationModel} from '../../../../core/asset_class/_models/valuation.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
import {
    AllApproachesToValuesRequested,
    AllMethodsToValuesRequested,
    AllAssetClassValuationGroupsRequested,
    ApproachesToValue,
    MethodsToValue,
    AssetClassValuationGroup,
    selectAllMethodsToValues,
    selectAllApproachesToValues,
    selectAllAssetClassValuationGroups,
    PremiseOfValue,
    AllPremiseOfValuesRequested,
    selectAllPremiseOfValues,
    AllTenureRequested,
    selectAllTenure,
    AllLandTenuresRequested,
    selectAllLandTenures,
} from '../../../../core/linked-tables';
import {CountryData} from '../../admin-management/countries/countries-list/local-data';
import {TranslateService} from '@ngx-translate/core';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { TenureType } from '../ac-valuation/eventtype';

@Component({
    selector: 'kt-add-valuation-dialog-alt',
    templateUrl: './add-valuation-dialog.component.html',
    styleUrls: ['./add-valuation-dialog.component.scss']
})
export class AddValuationDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('singleSelectApproachesToValue') singleSelectApproachesToValue: MatSelect;
    @ViewChild('singleSelectMethodsToValue') singleSelectMethodsToValue: MatSelect;

    acValuation: ValuationModel;
    valuationForm: UntypedFormGroup;

    methodsToValues: MethodsToValue[];
    approachesToValues: ApproachesToValue[];
    valuationGroups: AssetClassValuationGroup[];
    premiseOfValues: PremiseOfValue[] = [];

    countriesData = CountryData.countries;

    subscriptions: Subscription[] = [];
    hasFormErrors = false;
    tenureType$: Observable<TenureType>;
    tenureType = TenureType;
    tenures: any[] = [];
    landTenures: any[] = [];
    baseOfValueId$: Observable<number>;

    /** control for the MatSelect filter keyword */
    public filterCtrlApproachesToValuesData: UntypedFormControl = new UntypedFormControl();
    public filterCtrlMethodsToValuesData: UntypedFormControl = new UntypedFormControl();
    /** list of models filtered by search keyword */
    public filteredApproachesToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public filteredMethodsToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    protected _onDestroy = new Subject<void>();

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<FetchEntityDialogComponent>,
     * @param data: inputData
     * @param fb: FormBuilder
     * @param store: AppState
     */
    constructor(public dialogRef: MatDialogRef<AddValuationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
                    this.tenureType$ = this.data.tenureType$;
                    this.baseOfValueId$ = this.data.baseOfValueId$;
    }

    ngOnInit(): void {
        if (this.data.acValuation) {
            this.acValuation = this.data.acValuation;
        } else {
            this.acValuation = new ValuationModel();
            this.acValuation.clear();
        }
        this.createForm();

        this.store.dispatch(new AllApproachesToValuesRequested());
        const approachesToValuesSubscribe = this.store.pipe(
            select(selectAllApproachesToValues))
            .subscribe(res => {
                this.approachesToValues = [];
                if (res) {
                    this.approachesToValues = res;
                    this.filteredApproachesToValuesData.next(this.approachesToValues.slice());
                }
            });
        this.subscriptions.push(approachesToValuesSubscribe);

        this.store.dispatch(new AllAssetClassValuationGroupsRequested());
        const assetClassValuationGroupsSubscribe = this.store.pipe(
            select(selectAllAssetClassValuationGroups))
            .subscribe(res => {
                this.valuationGroups = [];
                if (res) {
                    this.valuationGroups = res;
                }
            });
        this.subscriptions.push(assetClassValuationGroupsSubscribe);

        this.store.dispatch(new AllMethodsToValuesRequested());
        const methodsToValuesSubscribe = combineLatest([
            this.store.pipe(
            select(selectAllMethodsToValues)),
            this.data.customTp$
        ]).subscribe(([res, customTp]) => {
                this.methodsToValues = [];
                if (res) {
                    this.methodsToValues = res;
                    const methodsToValues = this.methodsToValues.map(mtv => {
                        const disabled = !(customTp || (mtv.id == 2 || mtv.id == 9));
                        return {
                            ...mtv,
                            disabled,
                            text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
                        }
                    })
                    this.filteredMethodsToValuesData.next(methodsToValues);
                }
            });
        this.subscriptions.push(methodsToValuesSubscribe);

        this.store.dispatch(new AllPremiseOfValuesRequested())
        const premiseOfValueSub = this.store.select(selectAllPremiseOfValues).subscribe(res => {
            if (!res) {
                this.premiseOfValues = [];
                return;
            }
            this.premiseOfValues = res;
        });
        this.subscriptions.push(premiseOfValueSub);

        this.store.dispatch(new AllTenureRequested())
        const tenureSub = combineLatest([
            this.store.select(selectAllTenure),
            this.baseOfValueId$
        ]).subscribe(([tenures, baseOfValueId]) => {
            this.tenures = [];
            if (tenures) {
                this.tenures = tenures.map(t => ({
                    ...t,
                    disabled: baseOfValueId == 2 && t.id != 1
                }))
            }
        });    
        this.subscriptions.push(tenureSub);

        this.store.dispatch(new AllLandTenuresRequested())
        const landTenureSub = this.store.select(selectAllLandTenures).subscribe(tenures => {
            this.landTenures = [];
            if (tenures) {
                this.landTenures = tenures;
            }
        })
        this.subscriptions.push(landTenureSub);

        this.filterCtrlApproachesToValuesData.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.ApproachesToValues();
            });

        combineLatest([
            this.filterCtrlMethodsToValuesData.valueChanges,
            this.data.customTp$
        ]).pipe(takeUntil(this._onDestroy))
            .subscribe(([val, customTp]: [any, boolean]) => {
                this.MethodsToValues(customTp);
            });
    }

    ngAfterViewInit() {
        // this.setInitialValueBasisOfValue();
        this.setInitialValueApproachesToValue();
        this.setInitialValueMethodsToValue();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {

        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Create form
     */
    createForm() {
        this.valuationForm = this.fb.group({
            // base_of_value_id: [this.acValuation.base_of_value_id, Validators.required],
            approaches_to_value_id: [this.acValuation.approaches_to_value_id, Validators.required],
            methods_to_value_id: [this.acValuation.methods_to_value_id, Validators.required],
            // group_id: [this.acValuation.group_id, Validators.required],
            premise_of_value_id: [this.acValuation.premise_of_value_id, Validators.required],
            interest_valued: [this.acValuation.interest_valued, Validators.required],
            discount: [this.acValuation.discount],
            comment: [this.acValuation.comment],
            tenure_id: [this.acValuation.tenure_id],
            custom_tenure: [this.acValuation.tenure_custom]
        });
        const interestValuedChangeSub = this.valuationForm.controls.interest_valued.valueChanges.subscribe(value => {
            if (value < 100) {
                this.valuationForm.controls.comment.setValidators([Validators.required]);
            } else {
                this.valuationForm.controls.comment.clearValidators();
            }
            this.valuationForm.controls.comment.updateValueAndValidity();
        });
        this.subscriptions.push(interestValuedChangeSub);
    }


    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.acValuation && this.acValuation.id) {
            // tslint:disable-next-line:no-string-throw
            return `Edit Valuation`;
        }

        return 'New Valuation';
    }

    isValid() {
        return true;
    }

    radioChanged() {

    }

    onSubmit() {
        this.hasFormErrors = false;
        /** check form */
        if (!this.isValid()) {
            this.hasFormErrors = true;
            return;
        }

        const controls = this.valuationForm.controls;
        /** check form */
        if (this.valuationForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const _acValuation = new ValuationModel();
        _acValuation.clear();

        if (this.acValuation.id) {
            _acValuation.id = this.acValuation.id;
        }

        _acValuation._isEditMode = this.acValuation._isEditMode;
        _acValuation.approaches_to_value_id = controls.approaches_to_value_id.value;
        _acValuation.methods_to_value_id = controls.methods_to_value_id.value;

        _acValuation.approaches_to_value_name = this.approachesToValues.find(
            item => item.id === _acValuation.approaches_to_value_id
        ).name;
        _acValuation.methods_to_value_name = this.methodsToValues.find(
            item => item.id === _acValuation.methods_to_value_id
        ).name;

        _acValuation.premise_of_value_id = controls.premise_of_value_id.value;
        _acValuation.premise_of_value_name = this.premiseOfValues.find(
            item => item.id === _acValuation.premise_of_value_id
        ).name;

        _acValuation.interest_valued = controls.interest_valued.value;
        _acValuation.discount = controls.discount.value;
        _acValuation.comment = controls.comment.value;

        _acValuation.tenure_id = controls.tenure_id.value;
        _acValuation.tenure_custom = controls.custom_tenure.value;



        this.dialogRef.close({
            size: _acValuation,
            isEdit: false
        });
    }

    protected setInitialValueApproachesToValue() {
        this.filteredApproachesToValuesData
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelectApproachesToValue.compareWith = (a: string, b: string) => {
                    return a === b;
                };
            });
    }

    protected setInitialValueMethodsToValue() {
        this.filteredMethodsToValuesData
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelectMethodsToValue.compareWith = (a: string, b: string) => {
                    return a === b;
                };
            });
    }

    protected ApproachesToValues() {
        if (!this.approachesToValues) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrlApproachesToValuesData.value;
        if (!search || search === '') {
            this.filteredApproachesToValuesData.next(this.approachesToValues.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        this.filteredApproachesToValuesData.next(
            this.approachesToValues.filter(approachesToValue => approachesToValue.name.toLowerCase().indexOf(search) > -1)
        );
    }

    protected MethodsToValues(customTp: boolean) {
        if (!this.methodsToValues) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrlMethodsToValuesData.value;
        if (!search || search === '') {
            const methodsToValues = this.methodsToValues.map(mtv => {
                const disabled = !(customTp || (mtv.id == 2 || mtv.id == 9));
                return {
                    ...mtv,
                    disabled,
                    text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
                }
            })
            this.filteredMethodsToValuesData.next(methodsToValues);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        const filtered = this.methodsToValues.filter(mtv => mtv.name.toLowerCase().indexOf(search) > -1).map(
            mtv => {
                const disabled = !(customTp || (mtv.id == 2 || mtv.id == 9));
                return {
                    ...mtv,
                    disabled,
                    text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
                }
            }
        )
        this.filteredMethodsToValuesData.next(filtered);
    }
}
