import {fromEvent, interval, merge, Observable, Subscription} from 'rxjs';
// Angular
import {ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
// Layout
import {LayoutConfigService, SplashScreenService, TranslationService} from './core/_base/layout';
// language list
import {locale as enLang} from './core/_config/i18n/en';
import {locale as chLang} from './core/_config/i18n/ch';
import {locale as esLang} from './core/_config/i18n/es';
import {locale as jpLang} from './core/_config/i18n/jp';
import {locale as deLang} from './core/_config/i18n/de';
import {locale as frLang} from './core/_config/i18n/fr';
import {AppState} from './core/reducers';
import {select, Store} from '@ngrx/store';
// import {isLoggedIn, Logout} from './core/auth';
import {IdleTimeoutService} from './core/auth/_services/idleTimeout.service';
import {NotificationClear} from './core/auth/_actions/notificaiton.actions';
import {MatDialog} from '@angular/material/dialog';
import { isLoggedIn } from './core/mad-auth/mad-auth.store';
import { ZendeskService } from './core/zendesk/zendesk.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    // Public properties
    title = 'Metronic';
    loader: boolean;
    timedOut: boolean;
    isRunIdle: boolean;
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    public inactivityTimerEvent: Array<any>[] = [[document, 'click'],
        [document, 'wheel'],
        [document, 'scroll'],
        [document, 'mousemove'],
        [document, 'keyup'],
        [window, 'resize'],
        [window, 'scroll'],
        [window, 'mousemove']
    ];
    private mergedObservable$: Observable<any>;


    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e) {
        if (this.isRunIdle) {
            this.userIdle.resetTimer();
        }
    }


    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     * @param layoutConfigService: LayoutConfigService
     * @param splashScreenService: SplashScreenService
     */
    constructor(private translationService: TranslationService,
                private router: Router,
                private layoutConfigService: LayoutConfigService,
                private userIdle: IdleTimeoutService,
                private store: Store<AppState>,
                private dialogRef: MatDialog,
                private splashScreenService: SplashScreenService,
                private zendeskService: ZendeskService
            ) {

        // register translations
        // this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
        this.timedOut = false;
        this.isRunIdle = false;
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        // enable/disable loader
        this.loader = this.layoutConfigService.getConfig('page-loader.type');

        const routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);

                this.zendeskService.converSeationFieldCurrentRoute(window.location.href)
            }
        });
        this.unsubscribe.push(routerSubscription);

        const idleTimerSubscription = this.userIdle.timeoutExpired.subscribe(res => {

            this.store.dispatch(new NotificationClear());
            // this.store.dispatch(new Logout());
            this.dialogRef.closeAll();
            // alert('timeout');
        });
        this.unsubscribe.push(idleTimerSubscription);

        this.store.pipe(select(isLoggedIn)).subscribe(res => {
            if (res) {
                this.userIdle.startTimer();
                this.timedOut = false;
                this.isRunIdle = true;

            } else {
                this.userIdle.stopTimer();
                this.isRunIdle = false;
            }
        });

        this.zendeskService.loadScript().then(() => {
            this.zendeskService.load()
        })
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }


    loadZenDesk() {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script')
            script.id = "ze-snippet"
            script.src = "https://static.zdassets.com/ekr/snippet.js?key=36666877-8108-4d5e-82b4-8d180751550e"
            script.onload = () => {
                resolve({})
            }

            document.body.appendChild(script)
        })
    }
}
