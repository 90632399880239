export class ConflictType {
    public static conflictTypes: any = [
        {
            options: [
                {name: 'No - No conflicts', prefix: 'no_conflict'},
                {name: 'Yes - A conflict of interest exists', prefix: 'yes_conflict'},
                {name: 'Yes - A potential conflict of interest exists', prefix: 'potential_conflict'},
                {name: 'Yes - No conflicts of interest', prefix: 'yes_but_no_conflict'}
            ],
            explainOptionPrefix: ['yes_conflict', 'potential_conflict']
        },

        {
            options: [
                {name: 'No', prefix: 'no'},
                {name: 'Yes', prefix: 'yes'}
            ],
            explainOptionPrefix: ['yes']
        },

        {
            options: [
                {name: 'No', prefix: 'no'},
                {name: 'Yes', prefix: 'yes'}
            ],
            explainOptionPrefix: []
        },
        {
            options: [
                {name: 'No', prefix: 'no'},
                {name: 'Yes', prefix: 'yes'}
            ],
            explainOptionPrefix: ['yes', 'no']
        },
        {
            options: [
                {name: 'No', prefix: 'no'},
                {name: 'Potential', prefix: 'potential'},
                {name: 'Yes', prefix: 'yes'}
            ],
            explainOptionPrefix: ['potential', 'yes']
        },
        {
            options: [
                {name: 'tag-input', prefix: ''},
            ],
            explainOptionPrefix: []
        },
        {
            options: [
                {name: 'Potential', prefix: 'potential'},
                {name: 'Yes', prefix: 'yes'}
            ],
            explainOptionPrefix: ['potential', 'yes']
        },
    ];
}
