import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { from, Observable, of } from "rxjs";
import { delay, exhaustMap, filter, map, mergeMap, switchMap, take, takeWhile } from "rxjs/operators";
import { AppState } from "../reducers";
import { MadAuthService } from "./mad-auth.service";
import { currentUser } from "./mad-auth.store";
import { MadPermission } from "./mad-permission";
import { MadRole } from "./mad-roles.store";

@Injectable()
export class MadPermissionGuard implements CanActivate {
  constructor(
    private _store$: Store<AppState>,
    private _authService: MadAuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const permissions = route.data.permissions as Array<MadPermission>
    return this._authService.checkPermission(permissions)
  }
}