// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectMainWallQueryResult, selectMainWallsPageLoading, selectMainWallsShowInitWaitingMessage} from '../_selectors/main-wall.selectors';

export class MainWallsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectMainWallsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectMainWallsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectMainWallQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
