import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassHouseModel } from "../_models/asset-class-house.model";
import { AssetClassHouseState } from "../_reducers/asset-class-house.reducers";

export const selectAssetClassHouseState = createFeatureSelector<AssetClassHouseState>('assetClassHouses');
export const selectAssetClassHousePageLoading = createSelector(selectAssetClassHouseState, state => state.listLoading);
export const selectAssetClassHouseActionLoading = createSelector(selectAssetClassHouseState, state => state.actionLoading);
export const selectAssetClassHouseShowInitWaitingMessage = createSelector(selectAssetClassHouseState, state => state.showInitWaitingMessage);
export const selectAssetClassHouseLastQuery = createSelector(selectAssetClassHouseState, state => state.lastQuery);
export const selectTrashedAssetClassHouseCount = createSelector(selectAssetClassHouseState, state => state.totalTrashed);
export const selectAdminTrashedAssetClassHouseCount = createSelector(selectAssetClassHouseState, state => state.totalAdminTrashed);
export const selectAssetClassHouseById = (id: number) => createSelector(
    selectAssetClassHouseState,
    state => state.entities[id]
)

export const selectAssetClassHouseInStore = createSelector(
    selectAssetClassHouseState,
    state => {
        const items: AssetClassHouseModel[] = [];
        each(state.entities, el => {
            items.push(el);
        });

        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassHouseModel[] = [];
        switch (state.lastQuery.sortField) {
            default: 
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                )
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
)

export const selectLastCreatedAssetClassHouseId = createSelector(
    selectAssetClassHouseState,
    state => {
        if (state.previouslyCreatedACId != state.lastCreatedAssetClassHouseId) {
            return state.lastCreatedAssetClassHouseId
        }
        return undefined
    }
)