import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { BuiltinFittingAddDialogComponent } from '../add/builtin-fitting-add-dialog.component';
import { AssetClassType, AssetClassTypesService, BuiltinFitting, BuiltinFittingsService } from '../../../../../core/linked-tables';
import { LayoutUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';

@Component({
    selector: 'kt-builtin-fitting-edit',
    templateUrl: './builtin-fitting-edit-dialog.component.html',
    styleUrls: ['./builtin-fitting-edit-dialog.component.scss']
})

export class BuiltinFittingEditDialogComponent implements OnInit, OnDestroy {
    builtinFittings: BuiltinFitting[] = [];
    isBuildingBuiltinFitting = false;
    propertySubTypeId = 0;
    returnBuiltinFittings: any[];

    /**
     * Component constructor
     *
     * @param dialogRef
     * @param data
     * @param dialog: MatDialog
     * @param location
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param assetClassTypesService
     * @param cdRef
     */
    constructor(public dialogRef: MatDialogRef<BuiltinFittingEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>,
                private assetClassTypesService: AssetClassTypesService,
                private builtinFittingsService: BuiltinFittingsService,
                private cdRef: ChangeDetectorRef) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.returnBuiltinFittings = [];
        this.propertySubTypeId = this.data.propertySubTypeId;

        if (this.data.currentBuiltinFittings.length > 0) {
            this.returnBuiltinFittings = this.data.currentBuiltinFittings;
        }

        this.getData();
    }

    ngOnDestroy(): void {
    }

    setSelectedBuiltinFittings(item): boolean {
        if (this.returnBuiltinFittings.length <= 0) {
            return false;
        } else {
            return this.returnBuiltinFittings.some(element => element.id === item.id);
        }
    }

    updateCheckedOptions(item, $event) {
        if ($event.checked) {
            this.returnBuiltinFittings = [...this.returnBuiltinFittings, item];
        } else {
            const index = this.returnBuiltinFittings.findIndex(element => element.id == item.id);
            if (index !== -1) {
                this.returnBuiltinFittings.splice(index, 1);
            }
        }
    }

    addBuiltinFitting() {
        const dialogRef = this.dialog.open(BuiltinFittingAddDialogComponent, {
            data: {property_sub_type_id: this.propertySubTypeId},
            width: '340px',
            position: {top: '50px', right: '50px'}
        });
        dialogRef.afterClosed().subscribe(dRes => {
                this.getData();
            }
        );
    }

    close() {
        this.dialogRef.close({
            data: this.returnBuiltinFittings
        });
    }

    getData() {
        this.builtinFittingsService.getAllBuiltinFittings().subscribe((res) => {
            this.builtinFittings = res.data.filter((builtinFitting) => {
                return builtinFitting.property_sub_type_id == this.propertySubTypeId;
            });
        });
    }
}
