// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {Tenure} from '../_models/tenure.model';

export enum TenureActionTypes {

    AllTenureRequested = '[Tenure Home Page] All Tenure Requested',
    AllTenureLoaded = '[Tenure API] All Tenure Loaded',

    TenureOnServerCreated = '[Edit Tenure Dialog] Tenure On Server Created',
    TenureCreated = '[Edit Tenure Dialog] Tenure Created',
    TenureUpdated = '[Edit Tenure Dialog] Tenure Updated',
    TenureDeleted = '[Tenure List Page] Tenure Deleted',

    // trash system

    TenureOnServerRestored = '[Tenure Trash] Tenure On Server Restored',
    TenureRestored = '[Tenure Trash] Tenure Restored',

    TenureOnServerAdminRestored = '[Tenure Admin Trash] Tenure On Server Restored',
    TenureAdminRestored = '[Tenure Admin Trash] Tenure Restored',

    TenureDeletedFromTrash = '[Tenure Trash] Tenure deleted',
    TenureDeletedFromAdminTrash = '[Tenure Admin Trash] Tenure deleted',

    TenureTrash = 'Tenure Trashed',
    TenureTrashFlushed = 'Tenure Trash Flushed',

    // Page system

    TenurePageRequested = '[Tenure List Page] Tenure Page Requested',
    TenurePageLoaded = '[Tenure API] Tenure Page Loaded',
    TenurePageCancelled = '[Tenure API] Tenure Page Cancelled',

    TenurePageToggleLoading = '[Tenure page] Tenure Page Toggle Loading',
    TenureActionToggleLoading = '[Tenure] Tenure Action Toggle Loading'
}

export class TenureOnServerCreated implements Action {
    readonly type = TenureActionTypes.TenureOnServerCreated;

    constructor(public payload: { tenure: Tenure }) {
    }
}

export class TenureCreated implements Action {
    readonly type = TenureActionTypes.TenureCreated;

    constructor(public payload: { tenure: Tenure }) {
    }
}

export class TenureUpdated implements Action {
    readonly type = TenureActionTypes.TenureUpdated;

    constructor(public payload: {
        partialTenure: Update<Tenure>,
        tenure: Tenure
    }) {
    }
}

export class TenureDeleted implements Action {
    readonly type = TenureActionTypes.TenureDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class TenurePageRequested implements Action {
    readonly type = TenureActionTypes.TenurePageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class TenurePageLoaded implements Action {
    readonly type = TenureActionTypes.TenurePageLoaded;

    constructor(public payload: { tenures: Tenure[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class TenurePageCancelled implements Action {
    readonly type = TenureActionTypes.TenurePageCancelled;
}

export class AllTenureRequested implements Action {
    readonly type = TenureActionTypes.AllTenureRequested;
}

export class AllTenureLoaded implements Action {
    readonly type = TenureActionTypes.AllTenureLoaded;

    constructor(public payload: { tenures: Tenure[] }) {
    }
}

export class TenurePageToggleLoading implements Action {
    readonly type = TenureActionTypes.TenurePageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class TenureActionToggleLoading implements Action {
    readonly type = TenureActionTypes.TenureActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class TenureDeletedFromAdminTrash implements Action {
    readonly type = TenureActionTypes.TenureDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class TenureDeletedFromTrash implements Action {
    readonly type = TenureActionTypes.TenureDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TenureOnServerRestored implements Action {
    readonly type = TenureActionTypes.TenureOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TenureRestored implements Action {
    readonly type = TenureActionTypes.TenureRestored;

    constructor(public payload: { item: Tenure }) {
    }
}

export class TenureOnServerAdminRestored implements Action {
    readonly type = TenureActionTypes.TenureOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TenureAdminRestored implements Action {
    readonly type = TenureActionTypes.TenureAdminRestored;

    constructor(public payload: { item: Tenure }) {
    }
}

export class TenureTrashFlushed implements Action {
    readonly type = TenureActionTypes.TenureTrashFlushed;

    constructor() {
    }
}


export type TenureActions = TenureCreated
    | TenureUpdated
    | TenureDeleted
    | TenurePageRequested
    | TenurePageLoaded
    | TenurePageCancelled
    | AllTenureLoaded
    | AllTenureRequested
    | TenureOnServerCreated

    | TenureDeletedFromAdminTrash
    | TenureOnServerRestored
    | TenureRestored
    | TenureOnServerAdminRestored
    | TenureAdminRestored

    | TenureDeletedFromTrash
    | TenureTrashFlushed

    | TenurePageToggleLoading
    | TenureActionToggleLoading;
