import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ToeTemplateActions, ToeTemplateActionTypes } from "../_actions";
import { TemplateRequest, ToeTemplateModel } from "../_models";

export interface ToeTemplateState extends EntityState<ToeTemplateModel> {
    isLoading: boolean;
    total: number;
}
export const adapter: EntityAdapter<ToeTemplateModel> = createEntityAdapter<ToeTemplateModel>();
export const initialState: ToeTemplateState = adapter.getInitialState({
    isLoading: false,
    total: 0,
});

export function toeTemplateReducer(state = initialState, action: ToeTemplateActions): ToeTemplateState {
    switch (action.type) {
        case ToeTemplateActionTypes.LOAD_TOE_TEMPLATES: {
            return {
                ...state,
                isLoading: true
            };
        }
        case ToeTemplateActionTypes.TOE_TEMPLATES_LOADED: {
            return adapter.setAll(action.payload.templates, {
                ...state,
                isLoading: false,
                total: action.payload.total
            });
        }
        case ToeTemplateActionTypes.ADD_TOE_TEMPLATE: {
            return state;
        }
        case ToeTemplateActionTypes.TOE_TEMPLATE_ADDED: {
            return adapter.addOne(action.payload.template, state);
        }
        case ToeTemplateActionTypes.UPDATE_TOE_TEMPLATE: {
            return state;
        }
        case ToeTemplateActionTypes.TOE_TEMPLATE_UPDATED: {
            return adapter.updateOne(action.payload.template, state);
        }
        case ToeTemplateActionTypes.DELETE_TOE_TEMPLATE: {
            return state;
        }
        case ToeTemplateActionTypes.TOE_TEMPLATE_DELETED: {
            return adapter.removeOne(action.payload.id, state);
        }
        default: {
            return state;
        }
    }
}