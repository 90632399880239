import { Injectable } from "@angular/core";
import { AssetClassModel } from "../../asset_class";
import { AssetClassSizeModel } from "../../comparable";
import { StandardMeasurement, UnitAreaMeasurement } from "../../linked-tables";
import { CriterionCategories } from "../_models/criterion-categories.model";
import { CriterionType } from "../_models/criterion-type.model";
import { CriterionModel } from "../_models/criterion.model";

@Injectable()
export class SizeCriterionService {
    createCriterions(
        ac: AssetClassModel,
        sizes: AssetClassSizeModel[],
        standardMeasurements: StandardMeasurement[],
        unitAreaMeasurement: UnitAreaMeasurement,
    ): {
        criterions: CriterionModel[],
        default: CriterionModel
    } {
        const criterion = this.createDefault(unitAreaMeasurement)

        const criterions: CriterionModel[] = [];
        standardMeasurements.forEach((measurement, i) => {
            const _criterion = new CriterionModel();
            _criterion.id = i;
            _criterion.name = measurement.name;
            _criterion.category = CriterionCategories.Other;
            _criterion.categoryName = 'Size (' + unitAreaMeasurement.name + ')';
            _criterion.type = CriterionType.Size;
            _criterion.comValues = {};
            if (measurement.id === ac.standard_measurement_id) {
                _criterion.criterion = measurement.name + ' (Required by ToE)';
                _criterion.active = true;
            } else {
                _criterion.criterion = measurement.name + ' (If selected, assumption or special assumption will be required)'
                _criterion.active = false;
            }
            _criterion.tpValue = this._getSizeCriterionValue(sizes, measurement, unitAreaMeasurement);
            _criterion.isChanged = false;
            criterions.push(_criterion);
        })

        return {
            default: criterion,
            criterions
        };
    }

    createDefault(um: UnitAreaMeasurement): CriterionModel {
        const criterion = new CriterionModel();
        criterion.id = 0;
        criterion.name = null;
        criterion.category = CriterionCategories.Other;
        criterion.categoryName = 'Size (' + um.name +')';
        criterion.type = CriterionType.Size;
        criterion.criterion = 'size';
        criterion.active = true;
        criterion.comValues = {};
        criterion.tpValue = null;
        criterion.isChanged = false;
        return criterion;
    }

    reset(criterion: CriterionModel, 
            otherCriterions: CriterionModel[],
            comSizes: {[id: number]: any}, 
            acSize: AssetClassSizeModel[], 
            um: UnitAreaMeasurement, 
            sms: StandardMeasurement[]
    ): {defualt: CriterionModel, change: CriterionModel} {
        const _crit = this._copyCriterion(criterion);
        let _changeCrit: CriterionModel;
        if (_crit.name) {
            const sm = sms.find(s => s.name === _crit.name);
            if (sm) {
                let _items: {[id: number]: any} = {};
                Object.keys(_crit.comValues).forEach(key => {
                    const _key = Number(key);
                    const val = this._getSizeCriterionValue(comSizes[_key], sm, um);
                    const _val = val ? val : '';
                    _items[_key] = _val;
                })
                _crit.comValues = _items;
                const _tpVal = this._getSizeCriterionValue(acSize, sm, um)
                const tpVal = _tpVal ? _tpVal : '';
                _crit.tpValue = tpVal
            }

            const _thisCrit = otherCriterions.find(crit => crit.name === _crit.name);
            _changeCrit = this._copyCriterion(_crit);
            _changeCrit.id = _thisCrit.id;
        }

        return {
            defualt: _crit,
            change: _changeCrit,
        }
    }

    addComparables(
        comparables: any[],
        criterions: { main: CriterionModel[], default: CriterionModel},
        sizeData: {[id: number]: {sizes: AssetClassSizeModel[], sms: StandardMeasurement[], um: UnitAreaMeasurement}}): {
        criterions: CriterionModel[],
        default: CriterionModel
    } {
        let _criterions = this._copyCriterions(criterions.main);
        let _default = this._copyCriterion(criterions.default);
        comparables.forEach(c => {
            const res = this.addComparable(c, {main: _criterions, default: _default}, sizeData[c.id]);
            _criterions = res.criterions;
            _default = res.default;
        })
        return {
            criterions: _criterions,
            default: _default,
        }
    }

    addComparable(
        com: any, 
        criterions: {
            main: CriterionModel[],
            default: CriterionModel,
        },
        sizeData: {
            sizes: AssetClassSizeModel[],
            sms: StandardMeasurement[],
            um: UnitAreaMeasurement,
        }
    ): {
        criterions: CriterionModel[],
        default: CriterionModel
    } {
        const _criterions = this._copyCriterions(criterions.main);
        _criterions.forEach(c => {
            const sm = sizeData.sms.find(m => m.name === c.name);
            const val = this._getSizeCriterionValue(sizeData.sizes, sm, sizeData.um);
            c.comValues[com.id] = val;
        })

        const _default = this._copyCriterion(criterions.default);
        let val = null;
        if (_default.name) {
            const measurement = sizeData.sms.find(m => m.name === _default.name);
            val = measurement ? this._getSizeCriterionValue(sizeData.sizes, measurement, sizeData.um) : null;
            val = val ? val : '';
        }
        _default.comValues[com.id] = val;
        
        return {
            criterions: _criterions,
            default: _default,
        }
    }

    removeComparable(comID: number, criterions: {
        main: CriterionModel[],
        default: CriterionModel
    }): {
        criterions: CriterionModel[],
        default: CriterionModel
    } {
        const _criterions = this._copyCriterions(criterions.main);
        _criterions.forEach(criterion => {
            delete criterion.comValues[comID];
        })
        const _default = this._copyCriterion(criterions.default);
        delete _default.comValues[comID];
        
        return {
            criterions: _criterions,
            default: _default
        }
    }

    _getSizeCriterionValue(
        sizes: AssetClassSizeModel[], 
        standardMeasurement: StandardMeasurement, 
        unitAreaMeasurement: UnitAreaMeasurement
    ): any {
        const size = sizes.find(size => size.standard_measurement_id == standardMeasurement.id);
        if (size) {
            return unitAreaMeasurement.id === 1 ? size.size_m : size.size_f;
        } else {
            return null;
        }
    }

    _copyCriterions(criterions: CriterionModel[]): CriterionModel[] {
        const _criterions: CriterionModel[] = [];
        criterions.forEach(criterion => {
            const _tmp = this._copyCriterion(criterion);
            _criterions.push(_tmp);
        })
        return _criterions;
    }

    _copyCriterion(criterion: CriterionModel): CriterionModel {
        const _tmp = Object.assign({}, criterion) as CriterionModel;
        const _val = Object.assign({}, _tmp.comValues);
        _tmp.comValues = _val;
        return _tmp
    }
}