// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map} from 'rxjs/operators';
import {of, forkJoin} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {TpAdminTaskLVService} from '../_services/tp-admin-task-lv.service';
// Actions
import {
    AllTpAdminTaskLVLoadedByUser,
    TpAdminTaskLVActionTypes,
    TpAdminTaskLVActionToggleLoading,
    AllTpAdminTaskLVByUserRequested,
    TpAdminTaskLVByUserPageRequested,
    TpAdminTaskLVByUserPageLoaded,
} from '../_actions/tp-admin-task-lv.actions';
import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';

import {AppState} from '../../reducers';

@Injectable()
export class TpAdminTaskLVEffects {

    showActionLoadingDispatcher = new TpAdminTaskLVActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TpAdminTaskLVActionToggleLoading({isLoading: false});

    @Effect()
    AllTpAdminTaskLVByUser$ = this.actions$
        .pipe(
            ofType<AllTpAdminTaskLVByUserRequested>(TpAdminTaskLVActionTypes.AllTpAdminTaskLVByUserRequested),
            mergeMap(({payload}) => {
                const requestToServer = this.service.getByUserId(payload.page, payload.user_id, payload.filterModel);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map((response: any) => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AllTpAdminTaskLVLoadedByUser({
                    tasks: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery,
                });
            })
        );

    @Effect()
    loadTpAdminTaskLVByUserPage$ = this.actions$.pipe(
        ofType<TpAdminTaskLVByUserPageRequested>(TpAdminTaskLVActionTypes.TpAdminTaskLVByUserPageRequested),
        mergeMap(({payload}) => {
            // this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getByUserId(payload.page, payload.user_id);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new TpAdminTaskLVByUserPageLoaded({
                assetClasses: result.data,
                totalCount: result.pagination.total,
                page: lastQuery
            });
        })
    );

    constructor(private actions$: Actions, private service: TpAdminTaskLVService, private store: Store<AppState>) {
    }
}
