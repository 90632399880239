// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ExternalAreaActions, ExternalAreaActionTypes} from '../_actions/external-area.actions';
// Models
import {ExternalArea} from '../_models/external-area.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ExternalAreasState extends EntityState<ExternalArea> {
    isAllExternalAreasLoaded: boolean;
    queryRowsCount: number;
    queryResult: ExternalArea[];
    lastCreatedExternalAreaId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ExternalArea> = createEntityAdapter<ExternalArea>();

export const initialExternalAreasState: ExternalAreasState = adapter.getInitialState({
    isAllExternalAreasLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedExternalAreaId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function externalAreasReducer(state = initialExternalAreasState, action: ExternalAreaActions): ExternalAreasState {
    switch (action.type) {
        case ExternalAreaActionTypes.ExternalAreasPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedExternalAreaId: undefined
            };
        case ExternalAreaActionTypes.ExternalAreasActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ExternalAreaActionTypes.ExternalAreaOnServerCreated:
            return {
                ...state
            };
        case ExternalAreaActionTypes.ExternalAreaCreated:
            return adapter.addOne(action.payload.externalArea, {
                ...state, lastCreatedExternalAreaId: action.payload.externalArea.id
            });

        case ExternalAreaActionTypes.ExternalAreaUpdated:
            return adapter.updateOne(action.payload.partialexternalArea, state);

        case ExternalAreaActionTypes.AllExternalAreasLoaded:
            return adapter.addAll(action.payload.externalAreas, {
                ...state, isAllExternalAreasLoaded: true
            });

        case ExternalAreaActionTypes.ExternalAreasPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };

        case ExternalAreaActionTypes.ExternalAreasPageLoaded:
            return adapter.addMany(action.payload.externalAreas, {
                ...initialExternalAreasState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ExternalAreaActionTypes.ExternalAreaDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ExternalAreaActionTypes.ExternalAreaRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ExternalAreaActionTypes.ExternalAreaDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ExternalAreaActionTypes.ExternalAreaAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ExternalAreaActionTypes.ExternalAreaDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ExternalAreaActionTypes.ExternalAreaTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
