<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>

        </div>
    </div>
    <div class="form kt-form--label-align-right form-group-seperator-dashed">
        <div class="card-body p-0 w-100 overflow-x-hidden">
            <mat-dialog-content>
                <mat-list class="w-100">
                    <mat-list-item *ngFor="let item of data.items">
                        <mat-icon mat-list-icon>navigate_next</mat-icon>
                        <h4 mat-line>{{item.text}} - {{ item.interaction_date | date : 'dd MMM yyyy'}}</h4>
                        <p mat-line> Deleted on {{item.date | date : 'dd MMM yyyy'}} {{ item.userName && item.userName.length > 0 ? 'by '+item.userName : '' }}</p>
                        <mat-action-list style="width: 14rem;">
                            <button
                                    mat-icon-button
                                    color="secondary"
                                    type="button">
                                <mat-icon
                                        class="cursor-pointer"
                                        (click)="$event.stopPropagation();"
                                        popoverTitle="Description"
                                        placement="left"
                                        container="body"
                                        [ngbPopover]="item.desc">help
                                </mat-icon>
                                <!--container=".kt-portlet__body"-->
    
                            </button>&nbsp;
                            <button
                                    mat-icon-button
                                    color="primary"
                                    style="color: limegreen !important;"
                                    matTooltip="Restore"
                                    type="button"
                                    (click)="restore(item.id)">
                                <!--<mat-icon>restore_from_trash</mat-icon>-->
                                <i class="flaticon-reply"></i>
                            </button>&nbsp;
    
                            <button
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="interaction Detail"
                                    type="button"
                                    (click)="reachAuditTrail(item)">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>&nbsp;
                        </mat-action-list>
                    </mat-list-item>
                </mat-list>
            </mat-dialog-content>
            <div class="card-footer b-0 p-0 text-right">
                <div class="form-actions form-actions--solid">
                    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="data.animal" cdkFocusInitial>Close</button>&nbsp;
                </div>
            </div>
        </div>
    </div>
</div>
