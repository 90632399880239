import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {ValuationsService} from '../_services/valuation.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    ValuationActionTypes,
    ValuationsPageRequested,
    ValuationsPageLoaded,
    ValuationActionToggleLoading,
    ValuationsPageToggleLoading,
    ValuationOnServerCreated,
    ValuationOnServerUpdated,
    ValuationCreated,
    ValuationUpdated,
    ValuationsTrashRequested,
    ValuationRestored,
    ValuationOnServerRestored,
    ValuationDeletedFromTrash,
    OneValuationDeleted,
    ValuationTrashFlushed,
    ValuationGet, ValuationMapPageRequested, ValuationValuationDeleted
} from '../_actions/valuation.actions';
import {of} from 'rxjs';

@Injectable()
export class ValuationEffects {
    showPageLoadingDispatcher = new ValuationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ValuationActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ValuationActionToggleLoading({isLoading: false});

    @Effect()
    loadValuationsPage$ = this.actions$.pipe(
        ofType<ValuationsPageRequested>(ValuationActionTypes.ValuationsPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.findValuations(payload.page, payload.toeId);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new ValuationsPageLoaded({
                valuations: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadValuationMapPage$ = this.actions$.pipe(
        ofType<ValuationMapPageRequested>(ValuationActionTypes.ValuationMapPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getApartments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];

            const lastQuery: QueryParamsModel = response[1];
            return new ValuationsPageLoaded({
                valuations: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createValuation$ = this.actions$.pipe(
        ofType<ValuationOnServerCreated>(ValuationActionTypes.ValuationOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createValuation(
                    payload.valuation,
                    payload.keyPlaces,
                ).pipe(
                tap(res => {
                    this.store.dispatch(new ValuationCreated({valuation: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updateValuation$ = this.actions$
        .pipe(
            ofType<ValuationOnServerUpdated>(ValuationActionTypes.ValuationOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateValuation(
                    payload.valuation,
                    payload.keyPlaces,
                ).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuationUpdated({valuation: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteValuationValuation$ = this.actions$
        .pipe(
            ofType<ValuationValuationDeleted>(ValuationActionTypes.ValuationValuationDeleted),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deletedValuation(
                    payload.valId
                ).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuationUpdated({valuation: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    trashValuations$ = this.actions$
        .pipe(
            ofType<ValuationsTrashRequested>(ValuationActionTypes.ValuationTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getTrashedValuations();
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreValuation$ = this.actions$
        .pipe(
            ofType<ValuationOnServerRestored>(ValuationActionTypes.ValuationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.valuationId).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuationRestored({valuation: res}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteValuationFromTrash$ = this.actions$
        .pipe(
            ofType<ValuationDeletedFromTrash>(ValuationActionTypes.ValuationDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.valuationId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteValuation$ = this.actions$
        .pipe(
            ofType<OneValuationDeleted>(ValuationActionTypes.OneValuationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteValuation(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAgencyTrash$ = this.actions$
        .pipe(
            ofType<ValuationTrashFlushed>(ValuationActionTypes.ValuationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    getValuation$ = this.actions$
        .pipe(
            ofType<ValuationGet>(ValuationActionTypes.ValuationGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getValuation(payload.valuationId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(
        private actions$: Actions,
        private service: ValuationsService,
        private store: Store<AppState>
    ) { }
}
