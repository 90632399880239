// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ShopFrontTypeActions, ShopFrontTypeActionTypes} from '../_actions/shop-front-type.actions';
// Models
import {ShopFrontTypeModel} from '../_models/shop-front-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ShopFrontTypesState extends EntityState<ShopFrontTypeModel> {
    isAllShopFrontTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ShopFrontTypeModel[];
    lastCreatedShopFrontTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ShopFrontTypeModel> = createEntityAdapter<ShopFrontTypeModel>();

export const initialShopFrontTypeState: ShopFrontTypesState = adapter.getInitialState({
    isAllShopFrontTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedShopFrontTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function shopFrontTypeReducer(state = initialShopFrontTypeState, action: ShopFrontTypeActions): ShopFrontTypesState {
    switch (action.type) {
        case ShopFrontTypeActionTypes.ShopFrontTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedShopFrontTypeId: undefined
            };
        case ShopFrontTypeActionTypes.ShopFrontTypeActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ShopFrontTypeActionTypes.ShopFrontTypeOnServerCreated:
            return {
                ...state
            };
        case ShopFrontTypeActionTypes.ShopFrontTypeCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedShopFrontTypeId: action.payload.item.id
            });
        case ShopFrontTypeActionTypes.ShopFrontTypeUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ShopFrontTypeActionTypes.AllShopFrontTypesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllShopFrontTypesLoaded: true
            });
        case ShopFrontTypeActionTypes.ShopFrontTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ShopFrontTypeActionTypes.ShopFrontTypesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialShopFrontTypeState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ShopFrontTypeActionTypes.ShopFrontTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ShopFrontTypeActionTypes.ShopFrontTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ShopFrontTypeActionTypes.ShopFrontTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ShopFrontTypeActionTypes.ShopFrontTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ShopFrontTypeActionTypes.ShopFrontTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ShopFrontTypeActionTypes.ShopFrontTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
