<div class="card card-custom section-margin-bottom-60">
    <div class="card-header valuation-header">
        <div class="card-title">
            <div class="card-label">
                <span>Valuation</span>
            </div>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.VALUATION.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.VALUATION.TITLE'|translate">
                help
            </mat-icon>
        </div>
    </div>
    <div class="card-body valuation-body">
        <div class="col-md-12 mat-table-wrapper">
            <form [formGroup]="form">
                    <table class="lmat-elevation-z8 table">
                        <thead>
                            <tr>
                                <th [class]="'input-'+(tableData$|async).length+'-header'"></th>
                                <th *ngFor="let data of tableData$|async; let i = index" [class]="'val-'+(tableData$|async).length+'-header'"> 
                                    <span class="cell">
                                        {{data.val.comInfo.ref_num}}
                                        <span>
                                            <button mat-icon-button color="primary" (click)="showOverview(data.com)">
                                                <mat-icon>visibility</mat-icon>
                                            </button>
                                            <button [disabled]="readonly" mat-icon-button color="warn" (click)="removeComparable(i, data.val.comInfo)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </span>
                                </th>
                                <th [class]="'tp-'+(tableData$|async).length+'-header'">Target Property 
                                    <button mat-icon-button color="primary" (click)="showMultipleOverview()">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="colored-white">
                                <th>Total Adjustment (%)</th>
                                <td *ngFor="let data of tableData$|async; let i = index">
                                    {{data.val.totalAdjustment}}%
                                </td>
                                <td rowspan="3" style="background-color: #3898ff;"></td>
                            </tr>
                            <tr class="colored-row">
                                <th>Adjusted Consideration {{valuation ? (valuation.tenure_id === 1 ? '(per year) ' : '') : ''}} ({{targetProperty.country_currency}})</th>
                                <td *ngFor="let data of tableData$|async; let i = index">
                                    {{data.val.adjustedConsideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }}
                                </td>
                            </tr>
                            <tr class="colored-white">
                                <th>Adjusted Gross Unit Rate {{valuation ? (valuation.tenure_id === 1 ? '(per year) ' : '') : ''}} ({{targetProperty.country_currency}}/{{(unitMeasurement$|async)?.name}}) </th>
                                <td *ngFor="let data of tableData$|async; let i = index">
                                    {{data.val.adjustedGrossUnitRate !== undefined 
                                        ? (data.val.adjustedGrossUnitRate.toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                        : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Weighting (%)</th>
                                <td *ngFor="let data of tableData$|async; let i = index">
                                    <mat-form-field style="width: 60px;" [appearance]="'fill'">
                                        <input style="text-align: center;" 
                                            (focus)="onFocus($event, i)" 
                                            (focusout)="onFocusOut($event, i, data.val)" 
                                            matInput [formControl]="_getWeigthControl(i)"
                                            mask="percent" suffix="%" />
                                    </mat-form-field>
                                </td>
                                <td style="vertical-align: middle;">
                                    <span [ngClass]="{
                                        'red': !(is100$|async),
                                        'green': (is100$|async)
                                    }">{{totalWeigths$|async}}%</span>
                                    <ng-template #popContent>
                                        <div>
                                            This must be 100%
                                        </div>
                                    </ng-template>
                                    <button mat-icon-button
                                        color="primary"
                                        placement="top"
                                        [container]="'body'"
                                        (click)="$event.stopPropagation()"
                                        [ngbPopover]="popContent"
                                    >
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr class="colored-white">
                                <th>Justification Weighting</th>
                                <td class="justification" [attr.colspan]="(tableData$|async).length + 1">
                                    <mat-form-field style="width: 100%;" [appearance]="'fill'">
                                        <input (focusout)="onJustificationFocusOut($event)" matInput formControlName="justification" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Weighted Average Gross Unit Rate {{valuation ? (valuation.tenure_id === 1 ? '(per year) ' : '') : ''}} ({{targetProperty.country_currency}}/{{(unitMeasurement$|async)?.name}})</th>
                                <td *ngFor="let data of tableData$|async; let i = index">
                                    {{data.val.weightedAvgGrossUnitRate !== undefined 
                                        ? (data.val.weightedAvgGrossUnitRate.toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                        : 'N/A'}}
                                </td>
                                <td style="vertical-align: middle;" >
                                    
                                        <span class="big" [ngClass]="{
                                            'red': !(is100$|async),
                                            'green': (is100$|async)
                                        }">{{ (totalWeighted$ | async) !== undefined 
                                            ? ((totalWeighted$ | async).toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                            : 'N/A'}}</span>
                                </td>
                            </tr>
                            <tr class="colored-white">
                                <th>Target Property {{valuation ? (valuation.tenure_id === 1 ? 'Rental Value (per year) ' : 'Value') : ''}} ({{targetProperty.country_currency}})</th>
                                <td [attr.colspan]="(tableData$|async).length + 1" style="vertical-align: middle;"   >
                                    
                                    <span class="big" [ngClass]="{
                                        'red': !(is100$|async),
                                        'green': (is100$|async)
                                    }">{{(totalPropertyValue$ | async) !== undefined 
                                        ? ((totalPropertyValue$ | async).toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                        : 'N/A'}}</span>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>
                                    Capital Allowance ({{targetProperty.country_currency}})
                                    <button mat-icon-button
                                        color="primary"
                                        placement="top"
                                        [container]="'body'"
                                        (click)="$event.stopPropagation()"
                                        [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.TITLE' | translate"
                                        [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.DESCRIPTION' | translate"
                                    >
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </th>
                                <td [attr.colspan]="(tableData$|async).length + 1">
                                    <mat-form-field style="width: 100%;" [appearance]="'fill'">
                                        <input
                                            autocomplete="off"
                                            style="text-align: center;"
                                            mask="separator"
                                            thousandSeparator=","
                                            [allowNegativeNumbers]="true"
                                            (focusout)="onCapitalAllowanceFocusOut($event)" 
                                            matInput 
                                            formControlName="capital_allowance" />
                                    </mat-form-field>
                                </td> 
                            </tr>
                            <ng-container *ngIf="form.controls.capital_allowance && form.controls.capital_allowance.value != 0">
                                <tr class="colored-white">
                                    <th>Justification</th>
                                    <td [attr.colspan]="(tableData$|async).length + 1">
                                        <mat-form-field style="width: 100%;" [appearance]="'fill'">
                                            <input 
                                                (focusout)="onCapitalAllowanceJustFocusOut($event)" 
                                                matInput 
                                                formControlName="capital_allowance_justification" />
                                            <mat-error>Please provide justification</mat-error>
                                        </mat-form-field>
                                    </td> 
                                </tr>
                                <tr class="colored-row">
                                    <th>Total Target Property Value ({{targetProperty.country_currency}})</th>
                                    <td [attr.colspan]="(tableData$|async).length + 1">
                                        <span class="big" [ngClass]="{
                                            'red': !(is100$|async),
                                            'green': (is100$|async)
                                        }">{{(totalTPValue$ | async) !== undefined 
                                            ? ((totalTPValue$ | async).toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                            : 'N/A'}}</span>
                                    </td> 
                                </tr>
                                <tr class="colored-white">
                                    <th>Total Target Property Value ({{targetProperty.country_currency}}/{{(unitMeasurement$|async)?.name}})</th>
                                    <td [attr.colspan]="(tableData$|async).length + 1">
                                        <span class="big" [ngClass]="{
                                            'red': !(is100$|async),
                                            'green': (is100$|async)
                                        }">{{(totalTPWeighted$ | async) !== undefined 
                                            ? ((totalTPWeighted$ | async).toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                            : 'N/A'}}</span>
                                    </td> 
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </form>
            </div>
    </div>
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">valuation</h5>
    <hr class="active">
</div> -->


