import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterService } from '../filter.service';

export type Option = {
  id: number;
  text: string;
}

@Component({
  selector: 'kt-f-selection',
  templateUrl: './f-selection.component.html',
  styleUrls: ['./f-selection.component.scss']
})
export class FSelectionComponent implements OnInit {
  @Input() bg: string;
  @Input() key: string;
  @Input() placeholder: string = '';
  @Input() selections: Option[] = [];

  @HostBinding('style.min-width') min_width = '0px';

  text$ = new BehaviorSubject<string>('');
  subText$ = new BehaviorSubject<string>(null);

  constructor(
    private filterService: FilterService
  ) { 
  }

  ngOnInit(): void {
    this.filterService.reset$.subscribe(() => {
      this.text$.next(this.unSet());
      this.subText$.next(this.unSetSub());
    })
    let length = this.placeholder.length;
    this.min_width = `${length + 100}px`

    this.text$.next(this.unSet())
    this.subText$.next(this.unSetSub());
  }

  private unSet() {
    return this.selections[0].id == -1 ? this.selections[0].text : this.placeholder;
  }

  private unSetSub() {
    return this.selections[0].id == -1 ? this.placeholder : null;
  }

  onSelect(item: Option) {
    this.text$.next(item.text);
    this.subText$.next(this.placeholder);
    this.filterService.setValue(this.key, item.id);
  }
}
