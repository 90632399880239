import {ToeReport} from '../_models/toe-report.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ToeReportsState} from '../_reducers/toe-report.reducers';
import * as fromToeReport from '../_reducers/toe-report.reducers';
import {each} from 'lodash';

export const selectToeReportsState = createFeatureSelector<ToeReportsState>('toeReports');

export const selectToeReportById = (toeReportId: number) => createSelector(
    selectToeReportsState,
    toeReportsState => toeReportsState.entities[toeReportId]
);

export const selectAllToeReports = createSelector(
    selectToeReportsState,
    fromToeReport.selectAll
);

export const selectAllToeReportsIds = createSelector(
    selectToeReportsState,
    fromToeReport.selectIds
);

export const allToeReportsLoaded = createSelector(
    selectToeReportsState,
    toeReportsState => toeReportsState.isAllToeReportsLoaded
);


export const selectToeReportsPageLoading = createSelector(
    selectToeReportsState,
    toeReportsState => toeReportsState.listLoading
);

export const selectToeReportsActionLoading = createSelector(
    selectToeReportsState,
    toeReportsState => toeReportsState.actionLoading
);

export const selectLastCreatedToeReportId = createSelector(
    selectToeReportsState,
    toeReportsState => toeReportsState.lastCreatedToeReportId
);

export const selectToeReportsShowInitWaitingMessage = createSelector(
    selectToeReportsState,
    toeReportsState => toeReportsState.showInitWaitingMessage
);

export const selectIsReportGenerating = createSelector(
    selectToeReportsState,
    state => state.isGeneratingReport
)

export const selectIsReportGeneratingSuccess = createSelector(
    selectToeReportsState,
    state => state.reportGenerateSuccess
)

export const selectIsReportGeneratingError = createSelector(
    selectToeReportsState,
    state => state.reportGenerateError
)

export const selectCurrentGeneratingReportID = createSelector(
    selectToeReportsState,
    state => state.currentGeneratingReportID
)

export const selectToeReportQueryResult = createSelector(
    selectToeReportsState,
    toeReportsState => {
        const items: ToeReport[] = [];
        each(toeReportsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ToeReport[] = httpExtension.sortArray(items, toeReportsState.lastQuery.sortField, toeReportsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, toeReportsState.queryRowsCount, '');
    }
);
