<div ngbDropdown class="w-100">
    <button ngbDropdownToggle class="btn w-100 d-flex justify-content-between align-items-center text-white"
        style="padding-top: 0.8rem; padding-bottom: 0.8rem"
        [style.background-color]="'#666666'"
        (click)="onClick()">
        <span class="mr-2">
            Other filters {{count == 0 ? '' : ('(' + count + ')')}}
        </span>
        <i class="ki ki-plus text-white"></i>
    </button>
</div>