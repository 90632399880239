// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ToeReport} from '../_models/toe-report.model';

export enum ToeReportActionTypes {

    AllToeReportsRequested = '[ToeReports Home Page] All ToeReports Requested',
    AllToeReportsLoaded = '[ToeReports API] All ToeReports Loaded',

    ToeReportOnServerCreated = '[Edit ToeReport Dialog] ToeReport On Server Created',
    ToeReportCreated = '[Edit ToeReports Dialog] ToeReports Created',
    ToeReportUpdated = '[Edit ToeReport Dialog] ToeReport Updated',
    ToeReportDeleted = '[ToeReports List Page] ToeReport Deleted',

    ToeReportsPageRequested = '[ToeReports List Page] ToeReports Page Requested',
    ToeReportsPageLoaded = '[ToeReports API] ToeReports Page Loaded',
    ToeReportsPageCancelled = '[ToeReports API] ToeReports Page Cancelled',

    ToeReportsPageToggleLoading = '[ToeReports page] ToeReports Page Toggle Loading',
    ToeReportsActionToggleLoading = '[ToeReports] ToeReports Action Toggle Loading',

    GenerateReport = '[Save Toe Dialog] Generate Report',
    ReportGenerated = '[Generate Report Effect] Report generated',
}

export class ToeReportOnServerCreated implements Action {
    readonly type = ToeReportActionTypes.ToeReportOnServerCreated;

    constructor(public payload: { toeReport: ToeReport}) {
    }
}

export class ToeReportCreated implements Action {
    readonly type = ToeReportActionTypes.ToeReportCreated;

    constructor(public payload: { toeReport: ToeReport }) {
    }
}

export class ToeReportUpdated implements Action {
    readonly type = ToeReportActionTypes.ToeReportUpdated;

    constructor(public payload: {
        partialToeReport: Update<ToeReport>,
        toeReport: ToeReport
    }) {
    }
}

export class ToeReportDeleted implements Action {
    readonly type = ToeReportActionTypes.ToeReportDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ToeReportsPageRequested implements Action {
    readonly type = ToeReportActionTypes.ToeReportsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ToeReportsPageLoaded implements Action {
    readonly type = ToeReportActionTypes.ToeReportsPageLoaded;

    constructor(public payload: { toeReports: ToeReport[], totalCount: number, page: QueryParamsModel }) {
    }
}

export class ToeReportsPageCancelled implements Action {
    readonly type = ToeReportActionTypes.ToeReportsPageCancelled;
}

export class AllToeReportsRequested implements Action {
    readonly type = ToeReportActionTypes.AllToeReportsRequested;

    constructor(public payload: { toeId: number }) {
    }
}

export class AllToeReportsLoaded implements Action {
    readonly type = ToeReportActionTypes.AllToeReportsLoaded;

    constructor(public payload: { toeReports: ToeReport[] }) {
    }
}

export class ToeReportsPageToggleLoading implements Action {
    readonly type = ToeReportActionTypes.ToeReportsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ToeReportsActionToggleLoading implements Action {
    readonly type = ToeReportActionTypes.ToeReportsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class GenerateToeReport implements Action {
    readonly type = ToeReportActionTypes.GenerateReport;
    constructor(public payload: {
        toeID: number,
        userID: number,
        toeStatus: number,
        reportID: number
    }) {}
}

export class ReportGenerated implements Action {
    readonly type = ToeReportActionTypes.ReportGenerated;
    constructor(public payload: {success: boolean}) {}
}

export type ToeReportActions = ToeReportCreated
    | ToeReportUpdated
    | ToeReportDeleted
    | ToeReportsPageRequested
    | ToeReportsPageLoaded
    | ToeReportsPageCancelled
    | AllToeReportsLoaded
    | AllToeReportsRequested
    | ToeReportOnServerCreated
    | ToeReportsPageToggleLoading
    | ToeReportsActionToggleLoading
    | GenerateToeReport
    | ReportGenerated
