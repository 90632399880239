import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class CoreStandardsService {
  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/core-standards`
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  fetchListing(): Observable<Array<{id: number, name: string}>> {
    const headers = this.authService.getAuthHeaders()

    return this.http.get<{data: Array<{id: number, name: string}>}>(
      `${this.API_URL}/listing`,
      { headers }
    ).pipe(
      map(response => response.data)
    )
  }
}