// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AuditTrailReportService} from '../_services/audit-trail-report.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AllAuditTrailReportsLoaded,
    AllAuditTrailReportsRequested,
    AllAuditTrailTpReportsRequested,
    AuditTrailReportActionTypes,
    AuditTrailReportUpdated,
    AuditTrailReportsPageToggleLoading,
    AuditTrailReportDeleted,
    AuditTrailReportOnServerCreated,
    AuditTrailReportCreated,
    AuditTrailReportsActionToggleLoading
} from '../_actions/audit-trail-report.actions';

@Injectable()
export class AuditTrailReportEffects {
    showPageLoadingDispatcher = new AuditTrailReportsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AuditTrailReportsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AuditTrailReportsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAuditTrailReports$ = this.actions$
        .pipe(
            ofType<AllAuditTrailReportsRequested>(AuditTrailReportActionTypes.AllAuditTrailReportsRequested),
            mergeMap(( { payload } ) => this.service.getAllAuditTrailReports(payload.assignmentId)),
            map(res => {
                return new AllAuditTrailReportsLoaded({auditTrailReports: res.data});
            })
        );

    @Effect()
    loadAllAuditTrailTpReports$ = this.actions$
        .pipe(
            ofType<AllAuditTrailTpReportsRequested>(AuditTrailReportActionTypes.AllAuditTrailTpReportsRequested),
            mergeMap(( { payload } ) => this.service.getAllAuditTrailReportsByTp(payload.assignmentId, payload.toeId, payload.tpId)),
            map(res => {
                return new AllAuditTrailReportsLoaded({auditTrailReports: res.data});
            })
        );

    @Effect()
    deleteAuditTrailReport$ = this.actions$
        .pipe(
            ofType<AuditTrailReportDeleted>(AuditTrailReportActionTypes.AuditTrailReportDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAuditTrailReport(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateAuditTrailReport$ = this.actions$
        .pipe(
            ofType<AuditTrailReportUpdated>(AuditTrailReportActionTypes.AuditTrailReportUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAuditTrailReport(payload.auditTrailReport);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAuditTrailReport$ = this.actions$
        .pipe(
            ofType<AuditTrailReportOnServerCreated>(AuditTrailReportActionTypes.AuditTrailReportOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createAuditTrailReport(payload.auditTrailReport).pipe(
                    tap(res => {
                        this.store.dispatch(new AuditTrailReportCreated({auditTrailReport: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AuditTrailReportService, private store: Store<AppState>) {
    }
}
