import {BaseModel} from '../../_base/crud';
import { LocationData } from "./location.model";
import { InternalAspectData, newInternalAspectData } from './asset-class-internal-aspect.model';
import { AcGroundData, newAcGroundData } from './asset-class-ground.model';
import { AssetClassTenure } from './asset-class-tenure.model';
import { AssetClassSizeModel } from './asset-class-size.model';
import { AcBuildingInfo } from './ac-building-info.model';
import { AssetClassBuilding } from './asset-class-building.model';

export class AssetClassResidentialModel extends BaseModel {
    id: number;
    ref_num: string;

    status: number;
    formType: number;

    locationData: LocationData;

    building_info: AcBuildingInfo 

    sub_type_category_id: number;
    sub_category_id: number;
    handover_standard_id: number;
    floor_details: string;
    state_of_repair_id: number;
    energy_efficiency_grade_id: number;
    property_general_desc: string;
    bedroom: number;
    room: number;
    bathroom: number;
    accommodation: {
        id: number;
        data: Record<number, Record<string, number>>,
        others: {id: number, title: string}[]
    };
    
    internalAspectData: InternalAspectData;
    grounds: AcGroundData;
    ac_pictures: any[];
    picture: string;
    documents: any[];
    tenures: AssetClassTenure[];
    sizes: AssetClassSizeModel[];
    buildingInfo: AssetClassBuilding;

    userDeletedBy: string;
    deletedBy: string;
    createdBy: string;
    lastUpdatedBy: string;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    user_deleted: Date;

    valuation_id: number | null;

    clear() {
        this.id = undefined;
        this.formType = 2;

        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        },

        this.building_info = new AcBuildingInfo();

        this.sub_type_category_id = undefined;
        this.sub_category_id = null;
        this.handover_standard_id = undefined;
        this.floor_details = '';
        this.state_of_repair_id = null;
        this.energy_efficiency_grade_id = null;
        this.property_general_desc = null;
        this.bedroom = null;
        this.room = null;
        this.bathroom = null;
        this.accommodation = {
            id: undefined,
            data: {},
            others: []
        };

        this.internalAspectData = newInternalAspectData();
        this.grounds = newAcGroundData();
        this.ac_pictures = [];
        this.picture = '';

        this.buildingInfo = new AssetClassBuilding();

        this.documents = [];
        this.tenures = [];
        this.sizes = [];

        this.userDeletedBy = null;
        this.deletedBy = null;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
        this.user_deleted = null;
        this.valuation_id = null;
    }

}





