import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../../core/reducers';
import {Location} from '@angular/common';
import {LayoutUtilsService} from '../../../../../core/_base/crud';
import {Store, select} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {TpFileModel} from '../../../../../core/asset_class';

@Component({
    selector: 'kt-alt-checkbox-add',
    templateUrl: './tp-file-info.component.html',
    styleUrls: ['./tp-file-info.component.scss']
})

export class TpFileInfoComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    hasFormErrors = false;
    model: any;
    title: string;
    required: string;
    placeholder = 'Description';
    _options = [];

    private componentSubscriptions: Subscription;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef
     * @param data: any
     * @param dialog: MatDialog
     * @param location: Location
     * @param fb: FormBuilder
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<TpFileInfoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public location: Location,
                private fb: UntypedFormBuilder,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {

        this.model = this.data.model;
        if (this.data.title) {
            this.title = this.data.title;
        }
        else {
            this.title = '';
        }

        this._options = [];
        this.data.categories.forEach(el => {
            this._options.push(el.source_external_reference.name);
        });
    }

    /**
     * On init
     */
    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            name: [this.model.cat_name, Validators.required],
            desc: [this.model.description, Validators.required],
        });
    }

    isFormValid() {
        const controls = this.form.controls;

        return (controls.name.value);
    }

    close() {
        this.dialogRef.close();
    }

    saveItem() {

        const _item = new TpFileModel();
        _item.clear();

        const controls = this.form.controls;
        if (this.form.invalid) {
            return;
        }
        _item.cat_name = controls.name.value;
        _item.id = this.data.model.id;
        _item.is_additional = this.data.model.is_additional;
        _item.tp_id = this.data.model.tp_id;
        _item.name = this.data.model.name;
        _item.path = this.data.model.path;
        _item.client_ref = 2;

        _item.cat_name = controls.name.value;
        _item.description = controls.desc.value;
        this.dialogRef.close(_item);
    }


    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }
}
