<div [formGroup]="formGroup" class="row" *ngIf="formGroup" [ngClass]="{'form-disabled': readonly}">
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput 
                type="text" 
                mask="0000" 
                [validation]="false" 
                placeholder="Number of floors (above ground)" 
                formControlName="above_floors"/>
            <mat-error>
                Number of floors <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput type="text" mask="0000" 
                [validation]="false" 
                placeholder="Basement floors" 
                formControlName="below_floors"/>
        </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="State of repair" formControlName="state_of_repair_id">
                <mat-option *ngFor="let item of stateOfRepairs" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                State of Repair <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <kt-alt-facilities-list
            [selectedFacilities]="selectedFacilities"
            [isBuildingFacility]="true"
            [assetClassTypeId]="1"
            [inValid]="facilitiesInValid$" >
        </kt-alt-facilities-list>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <kt-builtin-fittings-list #builtinFitting 
            [selectedBuiltinFittings]="selectedBuiltinFittings"
            [propertySubTypeId]="-1">
        </kt-builtin-fittings-list>
    </div>
    <div class="col-lg-4">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select 
                        formControlName="walls_and_partition_id" 
                        placeholder="Walls and Partitions">
                        <mat-option *ngFor="let item of wallsAndPartitions" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-12">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="walls_and_partition_comment"
                        placeholder="Walls and Partition Comment" >
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <mat-checkbox formControlName="has_other">
            Other
        </mat-checkbox>
    </div>
    <div class="col-lg-12" *ngIf="formGroup.controls.has_other.value">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput formControlName="other_aspects" placeholder="Please specify" />
            <mat-error>
                This field is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
</div>