import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { LandAdjustmentModel } from '../_models/land-adjustment.model';

export enum LandAdjustmentsActionTypes {
    AllLandAdjustmentsRequested = '[Land Adjustments List] All Land Adjustments Requested',
    AllLandAdjustmentsLoaded = '[Land Adjustments API] All Land Adjustments Loaded',

    LandAdjustmentOnServerCreated = '[Edit Land Adjustment] Land Adjustment on Server Created',
    LandAdjustmentCreated = '[Edit Land Adjustment] Land Adjustment Created',
    LandAdjustmentUpdated = '[Edit Land Adjustment] Land Adjustment Updated',
    LandAdjustmentDeleted = '[Land Adjustment Deleted] Land Adjustment Deleted',

    LandAdjustmentRestored = '[Land Adjustment Trash] Land Adjustment Restored',
    LandAdjustmentOnServerRestored = '[Land Adjustment Trash] Land Adjustment On Server Restored',

    LandAdjustmentOnServerAdminRestored = '[Land Adjustment Admin Trash] Land Adjustment On Server Restored',
    LandAdjustmentAdminRestored = '[Land Adjustment Admin Trash] Land Adjustment Restored',

    LandAdjustmentDeletedFromTrash = '[Land Adjustment Trash] Land Adjustment deleted',
    LandAdjustmentDeletedFromAdminTrash = '[Land Adjustment Admin Trash] Land Adjustment deleted',

    LandAdjustmentTrash = 'Land Adjustment Trashed',
    LandAdjustmentTrashFlushed = 'Land Adjustment Trash Flushed',

    // Page system

    LandAdjustmentsPageRequested = '[Land Adjustment List Page] Land Adjustment Page Requested',
    LandAdjustmentsPageLoaded = '[Land Adjustment API] Land Adjustment Page Loaded',
    LandAdjustmentsPageCancelled = '[Land Adjustment API] Land Adjustment Page Cancelled',

    LandAdjustmentsPageToggleLoading = '[Land Adjustment page] Land Adjustment Page Toggle Loading',
    LandAdjustmentActionToggleLoading = '[Land Adjustment] Land Adjustment Action Toggle Loading'
}

export class LandAdjustmentOnServerCreated implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentOnServerCreated;

    constructor(public payload: { item: LandAdjustmentModel }) {
    }
}

export class LandAdjustmentCreated implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentCreated;

    constructor(public payload: { item: LandAdjustmentModel }) {
    }
}

export class LandAdjustmentUpdated implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<LandAdjustmentModel>,
        item: LandAdjustmentModel
    }) {
    }
}

export class LandAdjustmentDeleted implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class LandAdjustmentsPageRequested implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class LandAdjustmentsPageLoaded implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentsPageLoaded;

    constructor(public payload: { items: LandAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class LandAdjustmentsPageCancelled implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentsPageCancelled;
}

export class AllLandAdjustmentsRequested implements Action {
    readonly type = LandAdjustmentsActionTypes.AllLandAdjustmentsRequested;
}

export class AllLandAdjustmentsLoaded implements Action {
    readonly type = LandAdjustmentsActionTypes.AllLandAdjustmentsLoaded;

    constructor(public payload: { items: LandAdjustmentModel[] }) {
    }
}

export class LandAdjustmentsPageToggleLoading implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class LandAdjustmentActionToggleLoading implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class LandAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class LandAdjustmentDeletedFromTrash implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class LandAdjustmentOnServerRestored implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class LandAdjustmentRestored implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentRestored;

    constructor(public payload: { item: LandAdjustmentModel }) {
    }
}

export class LandAdjustmentOnServerAdminRestored implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class LandAdjustmentAdminRestored implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentAdminRestored;

    constructor(public payload: { item: LandAdjustmentModel }) {
    }
}

export class LandAdjustmentTrashFlushed implements Action {
    readonly type = LandAdjustmentsActionTypes.LandAdjustmentTrashFlushed;

    constructor() {
    }
}

export type LandAdjustmentActions = LandAdjustmentCreated
    | LandAdjustmentUpdated
    | LandAdjustmentDeleted
    | LandAdjustmentsPageRequested
    | LandAdjustmentsPageLoaded
    | LandAdjustmentsPageCancelled
    | AllLandAdjustmentsLoaded
    | AllLandAdjustmentsRequested
    | LandAdjustmentOnServerCreated
    | LandAdjustmentDeletedFromAdminTrash
    | LandAdjustmentDeletedFromTrash
    | LandAdjustmentOnServerRestored
    | LandAdjustmentRestored
    | LandAdjustmentOnServerAdminRestored
    | LandAdjustmentAdminRestored
    | LandAdjustmentTrashFlushed
    | LandAdjustmentsPageToggleLoading
    | LandAdjustmentActionToggleLoading;