import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { 
    PropertyDetailReportActionToggleLoading, 
    PropertyDetailReportActionTypes, 
    PropertyDetailReportCreated, 
    AllPropertyDetailReportsLoaded, 
    PropertyDetailReportOnServerCreated, 
    PropertyDetailReportOnServerDeleted, 
    AllPropertyDetailReportsRequested,
    PropertyDetailReportRequested,
    PropertyDetailReportLoaded,
    PropertyDetailReportUpdated
} from '../_actions/property-detail-report.actions';
import { ReportType } from '../_models/report-types.enum';
import { PropertyDetailReportState } from '../_reducers/property-detail-report.reducers';
import { PropertyDetailReportService } from '../_services/property-detail-report.service';

@Injectable()
export class PropertyDetailReportEffects {
    hideActionLoadingDispatcher = new PropertyDetailReportActionToggleLoading({isLoading: false});
    showActionLoadingDispatched = new PropertyDetailReportActionToggleLoading({isLoading: true});

    constructor(
        private actions$: Actions,
        private service: PropertyDetailReportService,
        private store: Store<PropertyDetailReportState>
    ) {}

    @Effect()
    createReport$ = this.actions$.pipe(
        ofType<PropertyDetailReportOnServerCreated>(PropertyDetailReportActionTypes.PropertyDetailReportOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatched);
            return this.service.createPropertyDetailReport(payload.report).pipe(
                tap(res => {
                    this.store.dispatch(new PropertyDetailReportCreated({report: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    requestReports$ = this.actions$.pipe(
        ofType<AllPropertyDetailReportsRequested>(PropertyDetailReportActionTypes.AllPropertyDetailReportsRequested),
        mergeMap(({payload}) => this.service.getReports(payload.tpID)),
        map(res => {
            if (res) {
                const mutatedData = res.data.map(item => ({
                    ...item,
                    type: ReportType.PropertyDetailReport
                }));
                return {
                    data: mutatedData,
                    tp_id: res.tp_id
                }
            }
        }),
        map(({data, tp_id}) => {
            return new AllPropertyDetailReportsLoaded({reports: data, tpId: tp_id});
        })
    )

    @Effect()
    requestReport$ = this.actions$.pipe(
        ofType<PropertyDetailReportRequested>(PropertyDetailReportActionTypes.PropertyDetailReportRequested),
        mergeMap(({payload}) => {
            return this.service.getReport(payload.id);
        }),
        map(res => {
            return new PropertyDetailReportLoaded({report: res.data});
        })
    )

    @Effect()
    updateReport$ = this.actions$.pipe(
        ofType<PropertyDetailReportUpdated>(PropertyDetailReportActionTypes.PropertyDetailReportUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatched);
            return this.service.updateReport(payload.report);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteReport$ = this.actions$.pipe(
        ofType<PropertyDetailReportOnServerDeleted>(PropertyDetailReportActionTypes.PropertyDetailReportOnServerDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatched);
            return this.service.deleteReport(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )
}