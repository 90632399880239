import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassParkingModel } from "../_models/asset-class-parking.model";
import { AssetClassParkingState } from "../_reducers/asset-class-parking.reducers";

export const selectAssetClassParkingState = createFeatureSelector<AssetClassParkingState>('assetClassParkings');
export const selectAssetClassParkingPageLoading = createSelector(selectAssetClassParkingState, state => state.listLoading);
export const selectAssetClassParkingActionLoading = createSelector(selectAssetClassParkingState, state => state.actionLoading);
export const selectAssetClassParkingShowInitWaitingMessage = createSelector(selectAssetClassParkingState, state => state.showInitWaitingMessage);
export const selectAssetClassParkingLastQuery = createSelector(selectAssetClassParkingState, state => state.lastQuery);
export const selectTrashedAssetClassParkingCount = createSelector(selectAssetClassParkingState, state => state.totalTrashed);
export const selectAdminTrashedAssetClassParkingCount = createSelector(selectAssetClassParkingState, state => state.totalAdminTrashed);
export const selectAssetClassParkingById = (id: number) => createSelector(
    selectAssetClassParkingState,
    state => state.entities[id]
)

export const selectAssetClassParkingInStore = createSelector(
    selectAssetClassParkingState,
    state => {
        const items: AssetClassParkingModel[] = [];
        each(state.entities, el => {
            items.push(el);
        });

        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassParkingModel[] = [];
        switch (state.lastQuery.sortField) {
            default: 
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                )
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
)

export const selectLastCreatedAssetClassParkingId = createSelector(
    selectAssetClassParkingState,
    state => {
        if (state.previouslyCreatedACId != state.lastCreatedAssetClassParkingId) {
            return state.lastCreatedAssetClassParkingId
        }
        return undefined
    } 
);