<div [formGroup]="formGroup" class="form-group kt-form__group row">
  <div class="col-lg-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">
      Location
    </h5>
    <hr class="active">
  </div>
  <div class="col-lg-12 md-2 filter-section">
    <div class="row">
      <div class="col-lg-3">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput type="text" (keydown.enter)="$event.preventDefault()"
            [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_BY_CITY' | translate" autocorrect="off" autocapitalize="off"
            spellcheck="off" #search />
          <mat-icon matSuffix class="cursor-pointer" (click)="$event.stopPropagation();" container="body"
            [ngbPopover]="'TOOLTIP.HOUSE.LOCATE.DESCRIPTION' | translate"
            [popoverTitle]="'TOOLTIP.HOUSE.LOCATE.TITLE' | translate">help
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="col-lg-3 building-checkbox">
        <ng-container
          *ngIf="assetClassTypeId == 5 || assetClassTypeId == 1 || assetClassTypeId == 3 || assetClassTypeId == 2">
          <mat-checkbox formControlName="show_building">
            Show existing buildings
          </mat-checkbox>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-lg-12 mb-4 map">
    <agm-map [latitude]="centerLat$|async" [longitude]="centerLng$|async" [zoom]="zoom" (mapReady)="onMapReady($event)"
      [scrollwheel]="null" [usePanning]="true" [clickableIcons]="false">
      <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="onSetLocation()">
        <mat-icon>my_location</mat-icon>
      </button>
      <agm-marker *ngIf="(marker$|async) as marker" [latitude]="marker.latitude"
        [longitude]="marker.longitude"></agm-marker>
      <ng-container *ngIf="formGroup.controls.show_building.value">
        <agm-marker *ngFor="let building of (buildings$|async)" [latitude]="building.locationData.latitude"
          [longitude]="building.locationData.longitude" [iconUrl]="icon" [label]="building.label"
          (markerClick)="onShowInfo(building, info)">
          <agm-info-window #info [disableAutoPan]="true" [zIndex]="100" style="width: 15vw;">
            <div class="card card-custom">
              <div class="card-header p-1">
                <div class="card-title">
                  <img *ngIf="building.pictures[0]"
                    [src]="basePath + '/' + building.pictures[0].path + '/' + building.pictures[0].name"
                    (error)="onImgError($event)" style="height: 50px; width: 50px; object-fit: cover" loading="lazy" />
                  <h4 class="card-label ml-6">{{building.building_name}}</h4>
                </div>
              </div>
              <div class="card-body p-1">
                <p class="mb-1"><span class="text-mad">Description: </span>{{building.building_description}}</p>
                <p class="mb-1"><span class="text-mad">Total floors: </span>{{building.total_floors}}</p>
                <p class="mb-1"><span class="text-mad">Completion year: </span>{{building.completion_year}}</p>
                <a (click)="onSelectBuilding(building)" style="float: right; cursor: pointer;">Choose it</a>
              </div>
            </div>
          </agm-info-window>
        </agm-marker>
      </ng-container>
    </agm-map>
  </div>
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <mat-select [placeholder]="'Country'" formControlName="country_id" (selectionChange)="onSelectCountry()">
        <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
      </mat-select>
      <mat-error>
        Country <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <mat-select [placeholder]="'City'" formControlName="city_id">
        <mat-option [value]="-1">Unknown</mat-option>
        <mat-option [value]="0">Not Applicable</mat-option>
        <mat-option *ngFor="let city of cityOfCountry" [value]="city.id">{{city.name}}</mat-option>
      </mat-select>
      <mat-error>
        City <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-4 kt-margin-bottom-10-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <input matInput [placeholder]="'Zipcode/Postalcode'" formControlName="zip_code" />
    </mat-form-field>
  </div>
  <div class="col-lg-4 kt-margin-bottom-10-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <mat-select [placeholder]="'Location Grade'" formControlName="location_grade_id">
        <mat-option [value]="-1">Unknown</mat-option>
        <mat-option *ngFor="let grade of grades" [value]="grade.id">{{grade.name}}</mat-option>
      </mat-select>
      <mat-error>
        Location Grade <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-12 kt-margin-b-10-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <textarea matInput rows="4" [placeholder]="'Address'" formControlName="address"></textarea>
      <mat-error>
        Address <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-12 kt-margin-b-10-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <textarea matInput rows="4" [placeholder]="'Location and Surroundings Description'"
        formControlName="location_surrounding"></textarea>
      <mat-error>
        Description <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>