<div class="card card-custom section-margin-bottom-60">
    <div class="card-header criterion-header">
        <div class="card-title">
            <div class="card-label">
                <span >Criterions</span>
            </div>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.CRITERIONS.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.CRITERIONS.TITLE'|translate">
                help
            </mat-icon>
        </div>
        <div class="card-toolbar">
            <div ngbDropdown *ngIf="!readonly" [placement]="'bottom-right'" class="dropdown dropdown-inline">
                <a ngbDropdownToggle href="javascript:;"
                    class="btn btn btn-label-brand btn-bold btn-sm"
                    style="cursor: pointer !important;">
                    Add
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right" style="width: 150px;">
                    <ul class="navi navi-hover">
                        <li class="navi-item">
                            <a href="javascript:;" class="navi-link" (click)="addExistingCriterion()">
                                <span class="navi-text">Existing criterion</span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a href="javascript:;" class="navi-link" (click)="addCustomCriterion()">
                                <span class="navi-text">Custom criterion</span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a href="javascript:;" class="navi-link" (click)="addFromTemplate()">
                                <span class="navi-text">From template</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body criterion-body">
        <div class="col-md-12 mat-table-wrapper">
            <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource">
        
                <!-- Headers -->
                <ng-container [matColumnDef]="'input-' + comparables.length + '-header'">
                    <th mat-header-cell 
                        *matHeaderCellDef 
                        [attr.colspan]="2">Qualitative Inputs</th>
                </ng-container>
        
                <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length + '-header'">
                    <th mat-header-cell *matHeaderCellDef>
                        <span class="cell">
                            {{com.ref_num}}
                            <span>
                                <button mat-icon-button color="primary" (click)="showOverview(com)">
                                    <mat-icon>visibility</mat-icon>
                                </button>
                                <button mat-icon-button color="warn" [disabled]="readonly" (click)="removeComparable(i, com)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </span>
                        </span>
                    </th>
                </ng-container>
        
                <ng-container [matColumnDef]="'tp-' + comparables.length +'-header'">
                    <th mat-header-cell *matHeaderCellDef>
                        Target Property
                        <button mat-icon-button color="primary" (click)="showMultipleOverview()">
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </th>
                </ng-container>
        
                <ng-container matColumnDef="action-header">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                </ng-container>
        
                <!-- Cells -->
                <ng-container [matColumnDef]="'criterion-' + comparables.length">
                    <td mat-cell *matCellDef="let criterion" [ngClass]="{'bg-orange': !criterion.name}">
                        {{criterion.name ? criterion.name : 'Not defined'}}
                    </td>
                </ng-container>
        
                <ng-container [matColumnDef]="'category-' + comparables.length">
                    <td mat-cell *matCellDef="let criterion">{{criterion.categoryName}}</td>
                </ng-container>
        
                <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length">
                    <td mat-cell *matCellDef="let criterion" 
                        [ngClass]="{
                            'bg-orange': criterion.name === null && (criterion.comValues[com.id] === null || criterion.comValues[com.id] === undefined),
                            'bg-red': criterion.name && (criterion.comValues[com.id] == 'Unknown' || criterion.comValues[com.id] === '' || criterion.comValues[com.id] === null),
                            'bg-green': (criterion.comValues[com.id] 
                                || criterion.comValues[com.id] === 0) && criterion.comValues[com.id] != 'Unknown'
                        }">
                        <span *ngIf="criterion.id !== 1; else considerationCom">
                            {{criterion.name === null 
                                ? 'Not defined'
                                : criterion.comValues[com.id] }}
                        </span>
                        <ng-template #considerationCom>
                            <span class="consideration-criterion">
                                <span>
                                    {{(criterion.comValues[com.id] !== null && criterion.comValues[com.id] !== undefined) 
                                        ? (criterion.comValues[com.id].toFixed(2) | mask:'separator.2':{thousandSeparator: ','} )
                                        : 'Not defined'}} 
                                </span>
                                <button *ngIf="criterion.comConsideration[com.id].process !== considerationProcess.NoProcess" 
                                    mat-icon-button 
                                    color="primary" 
                                    [disabled]="readonly"
                                    (click)="editConsideration(criterion, com)">
                                    <mat-icon>create</mat-icon>
                                </button>
                            </span>
                        </ng-template>
                    </td>
                </ng-container>
        
                <ng-container [matColumnDef]="'tp-' + comparables.length">
                    <td mat-cell *matCellDef="let criterion"
                        [ngClass]="{
                            'bg-orange': criterion.name === null && criterion.tpValue === null,
                            'bg-red': criterion.name && (criterion.tpValue == 'Unknown' || criterion.tpValue === '' || criterion.tpValue === null),
                            'bg-green': (criterion.tpValue || criterion.tpValue === 0) && criterion.tpValue != 'Unknown',
                            'bg-gray': criterion.tpValue === undefined
                        }">
                        <span *ngIf="criterion.id !== 1; else considerationTP">
                            {{
                                criterion.name === null ? 'Not defined' : criterion.tpValue
                            }}
                        </span>
                        <ng-template #considerationTP></ng-template>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <td mat-cell *matCellDef="let criterion">
                        <button mat-icon-button color="primary" [disabled]="readonly" *ngIf="criterion.id !== 1" (click)="editCriterion(criterion)">
                            <mat-icon>create</mat-icon>
                        </button>
                        <button [disabled]="readonly" mat-icon-button (click)="resetCriterion(criterion)" *ngIf="criterion.isChanged">
                            <mat-icon [style.color]="readonly ? 'gray' : 'green'">refresh</mat-icon>
                        </button>
                        <button *ngIf="(criterion.type === criterionType.Other || criterion.type === criterionType.Custom)" 
                            mat-icon-button 
                            color="warn"
                            [disabled]="!criterion.canRemove || readonly"
                            (click)="removeCriterion(criterion)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
                    [ngClass]="{'colored-row': i%2}"></tr>
            </table>
        </div>
    </div>
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">criterions</h5>
    <hr class="active">
</div>

<div class="col-md-12 d-flex justify-content-end">
    
</div> -->

