import {CertificationModel} from '../_models/certification.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {CertificationsState} from '../_reducers/certification.reducer';
import * as fromCertification from '../_reducers/certification.reducer';
import {each} from 'lodash';

export const selectCertificationsState = createFeatureSelector<CertificationsState>('certifications');

export const selectCertificationById = (id: number) => createSelector(
    selectCertificationsState,
    state => state.entities[id]
);

export const selectAllCertifications = createSelector(
    selectCertificationsState,
    fromCertification.selectAll
);

export const selectAllCertificationsIds = createSelector(
    selectCertificationsState,
    fromCertification.selectIds
);

export const allCertificationsLoaded = createSelector(
    selectCertificationsState,
    state => state.isAllCertificationsLoaded
);


export const selectCertificationsPageLoading = createSelector(
    selectCertificationsState,
    state => state.listLoading
);

export const selectCertificationsActionLoading = createSelector(
    selectCertificationsState,
    state => state.actionLoading
);

export const selectLastCreatedCertificationId = createSelector(
    selectCertificationsState,
    state => state.lastCreatedCertificationId
);

export const selectCertificationsShowInitWaitingMessage = createSelector(
    selectCertificationsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedCertificationCount = createSelector(
    selectCertificationsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedCertificationCount = createSelector(
    selectCertificationsState,
    (state) => state.totalAdminTrashed
);


export const selectCertificationQueryResult = createSelector(
    selectCertificationsState,
    state => {
        const items: CertificationModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CertificationModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
