import {QueryParamsModel} from '../../_base/crud';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {BuildingService} from '../_services/building.service';
// State
import {AppState} from '../../reducers';
// Actions

import {
    BuildingActionToggleLoading, BuildingActionTypes, BuildingAdminRestored, BuildingCreated, BuildingDeleted,
    BuildingDeletedFromAdminTrash, BuildingDeletedFromTrash,
    BuildingOnServerAdminRestored,
    BuildingOnServerCreated, BuildingOnServerRestored,
    BuildingRestored,
    BuildingsPageLoaded,
    BuildingsPageRequested,
    BuildingsPageToggleLoading,
    BuildingTrashFlushed,
    BuildingUpdated
} from '../_actions/building.actions';

@Injectable()
export class BuildingEffects {

    showPageLoadingDispatcher = new BuildingsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new BuildingActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new BuildingActionToggleLoading({isLoading: false});

    @Effect()
    loadPageBuildings$ = this.actions$
        .pipe(
            ofType<BuildingsPageRequested>(BuildingActionTypes.BuildingsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.getBuildings(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map((response) => {

                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];

                return new BuildingsPageLoaded({
                    buildings: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            })
        );

    // @Effect()
    // loadAllBuildings$ = this.actions$
    //     .pipe(
    //         ofType<AllBuildingsRequested>(BuildingActionTypes.AllBuildingsRequested),
    //         mergeMap(() => {
    //             this.store.dispatch(this.showActionLoadingDispatcher);
    //             return this.service.getAllBuildings();
    //         }),
    //         map((response) => {
    //             const result: any = response.data;
    //             return new BuildingsPageLoaded({
    //                 buildings: result,
    //                 totalCount: result.length,
    //                 totalTrashed: 0,
    //                 totalAdminTrashed: 0,
    //                 page: new QueryParamsModel([])
    //             });
    //         })
    //     );

    @Effect()
    deleteBuilding$ = this.actions$
        .pipe(
            ofType<BuildingDeleted>(BuildingActionTypes.BuildingDeleted),
            mergeMap(({payload}) => {
                    return this.service.deleteBuilding(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateBuilding$ = this.actions$
        .pipe(
            ofType<BuildingUpdated>(BuildingActionTypes.BuildingUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateBuilding(payload.building, payload.files);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createBuilding$ = this.actions$
        .pipe(
            ofType<BuildingOnServerCreated>(BuildingActionTypes.BuildingOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createBuilding(payload.building, payload.files).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingCreated({building: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreBuilding$ = this.actions$
        .pipe(
            ofType<BuildingOnServerRestored>(BuildingActionTypes.BuildingOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingRestored({building: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminBuilding$ = this.actions$
        .pipe(
            ofType<BuildingOnServerAdminRestored>(BuildingActionTypes.BuildingOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingAdminRestored({building: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteFromTrashBuilding$ = this.actions$
        .pipe(
            ofType<BuildingDeletedFromTrash>(BuildingActionTypes.BuildingDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashBuilding$ = this.actions$
        .pipe(
            ofType<BuildingDeletedFromAdminTrash>(BuildingActionTypes.BuildingDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushBuildingTrash$ = this.actions$
        .pipe(
            ofType<BuildingTrashFlushed>(BuildingActionTypes.BuildingTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     return of(new AllBuildingsRequested());
    // });

    constructor(private actions$: Actions, private service: BuildingService, private store: Store<AppState>) {
    }
}
