import {MapsAPILoader} from '@agm/core';
import {Location} from '@angular/common';
import {Component, HostListener, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {delay, take, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
// tslint:disable-next-line: max-line-length
import {
  AssetClassSizeModel,
  BuildingFacilityModel,
  BuildingModel, BuildingOnServerCreated, BuildingService, BuildingUpdated, selectLastCreatedBuildingId,
} from '../../../../../core/comparable';
// tslint:disable-next-line: max-line-length
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {UploadFileComponent} from '../../../shared_components/upload-file/upload-file.component';
import {
  AllStateRepairsRequested,
  AllTypeOfTitlesRequested,
  BuildingType,
  selectAllStateRepairs,
  selectAllTypeOfTitles,
  StateRepair,
  TypeOfTitle,
  AllGradesRequested,
  Grade,
  selectAllGrades,
  ExternalWall,
  AllExternalWallsRequested,
  selectAllExternalWalls,
  FoundationType,
  AllFoundationTypesRequested,
  selectAllFoundationTypes
} from '../../../../../core/linked-tables';
import {BuildingInfoDialogComponent} from '../../_sub/building-info-dialog/building-info-dialog.component';
import {FacilitiesSubListComponent} from '../../../shared_components/facilities/list/facilities-sub-list.component';
import { ComparablesRadioButtonValues } from 'src/app/core/comparable/_models/RadioButtonValues';
import { ParcelIdentifactionSectionComponent, ParcelIdentificationData } from '../../../shared_components/parcel-identifaction-section/parcel-identifaction-section.component';
import { AssetClassLandAreaModel } from 'src/app/core/asset_class';
import { BuildingSectionComponent, BuildingSectionData } from '../../../shared_components/building-section/building-section.component';
import { ExternalAspectData, newExternalAspectData } from 'src/app/core/comparable/_models/asset-class-external-aspect.model';
import { InternalAspectData, newInternalAspectData } from 'src/app/core/comparable/_models/asset-class-internal-aspect.model';
import { AcGroundData, newAcGroundData } from 'src/app/core/comparable/_models/asset-class-ground.model';
import { MapLocationComponent } from '../../../shared_components/map-location/map-location.component';
import { ExternalAspectsComponent } from '../../../shared_components/external-aspects/external-aspects.component';
import { InternalAspectsComponent } from '../../../shared_components/internal-aspects/internal-aspects.component';
import { AcGroundsComponent } from '../../../shared_components/ac-grounds/ac-grounds.component';
import { BuildingInternalAspectsComponent } from '../../../shared_components/building-internal-aspects/building-internal-aspects.component';
import { ServicesAndInfrastructuresComponent } from '../../../shared_components/services-and-infrastructures/services-and-infrastructures.component';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';

@Component({
  selector: 'kt-residential-edit',
  templateUrl: './building-edit.component.html',
  styleUrls: ['./building-edit.component.scss', '../../complex-btn.scss']
})
export class BuildingEditComponent implements OnInit, OnDestroy {
  @ViewChild('simple')
  public uploadFileComponent: UploadFileComponent;
  @ViewChild(MapLocationComponent, {static: false}) 
  mapLocationComponent: MapLocationComponent;
  @ViewChild(ParcelIdentifactionSectionComponent, {static: false})
  parcelIdentificationComponent: ParcelIdentifactionSectionComponent;
  @ViewChild(BuildingSectionComponent, {static: false})
  buildingSectionComponent: BuildingSectionComponent;
  @ViewChild(ExternalAspectsComponent, {static: false})
  externalAspectComponent: ExternalAspectsComponent;
  @ViewChild(BuildingInternalAspectsComponent, {static: false})
  internalAspectComponent: BuildingInternalAspectsComponent;
  @ViewChild(AcGroundsComponent, {static: false})
  acGroundsComponent: AcGroundsComponent;
  @ViewChild(ServicesAndInfrastructuresComponent, {static: false})
  servicesAndInfraComponent: ServicesAndInfrastructuresComponent;
  @ViewChild(AcDocumentUploadComponent, {static: false})
  acDocumentUploadComponent: AcDocumentUploadComponent;

  building: BuildingModel;
  formGroup: UntypedFormGroup;
  isComplexForm: boolean = false;
  parcelIdentificationData: ParcelIdentificationData = {
    add_info_on_land: false,
    land_parcel_name: null,
    coordinate_reference_system_id: null,
    points: [],
    landAreaComponentListSubject: new BehaviorSubject([]),
    landAreaSubject: new BehaviorSubject(new AssetClassLandAreaModel())
  };
  buildingSectionData: BuildingSectionData = {
    building_name: null,
    completion_year: null,
    is_part_of_property: false,
    info_about_property: null,
    building_type: null,
    building_type_comment: null,
    building_grade_id: null,
    energy_efficiency_grade_id: null,
    developer: null,
    anchor_tenant: null,
    foundation_type_id: null,
    building_description: null
  }
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  pictures: any[] = [];
  sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
  documents: any[] = [];
  hasFormErrors: boolean = false;
  centerLat = null;
  centerLng = null;
  formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
  private _onDestroy$: Subject<void> = new Subject();
  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  tabHeaders: TabHeader[] = [
    {label: 'Location', disabled: of(false)},
    {label: 'Property information', disabled: of(false)},
    {label: 'Pictures & documents', disabled: of(false)},
  ];
  location_tab_items = ['land_parcel_name','coordinate_reference_system_id'];
  error:any = {
    msg: ' Missing Fields in total:',
    fields: []
  }
  /**
   * Component constructor
   *
   * @param buildingsService: BuildingsService
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param fb: FormBuilder
   * @param location: Location
   * @param subheaderService: SubheaderService
   * @param typesUtilsService: TypesUtilsService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store
   * @param dialog: Dialog
   * @param mapsAPILoader
   * @param layoutConfigService: LayoutConfigService
   * @param ngZone: NgZone
   */
  constructor(public buildingsService: BuildingService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private location: Location,
              private subheaderService: SubheaderService,
              public typesUtilsService: TypesUtilsService,
              private layoutUtilsService: LayoutUtilsService,
              private store: Store<AppState>,
              public dialog: MatDialog,
              private mapsAPILoader: MapsAPILoader,
              private layoutConfigService: LayoutConfigService,
              private ngZone: NgZone,
              private translate: TranslateService) {
  }

  // Map Actions
  onCenterLatChange(lat: number) {
    this.centerLat = lat
  }

  onCenterLngChange(lng: number) {
    this.centerLng = lng
  }

  ngOnInit(): void { 
    this.activatedRoute.params.pipe(takeUntil(this._onDestroy$)).subscribe(params => {
      const id = params.building_id;
      if (id && id > 0) {
        this.activatedRoute.data.subscribe(data => {
          const res = data.data;
          this.building = res;
          this.isComplexForm = this.building.form_type == 1;
          this.createForm();
        });
      } else {
        this.building = new BuildingModel();
        this.building.clear();
        this.createForm();
      }


      // Parcels
      this.parcelIdentificationData = {
        ...this.parcelIdentificationData,
        add_info_on_land: this.building.parcelIdentification.add_info_on_land,
        land_parcel_name: this.building.parcelIdentification.land_parcel_name,
        coordinate_reference_system_id: this.building.parcelIdentification.coordinate_reference_system_id,
        points: this.building.parcelIdentification.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)})),
      }
      this.parcelIdentificationData.landAreaComponentListSubject.next(this.building.parcelIdentification.landAreaComponents);
      this.parcelIdentificationData.landAreaSubject.next(this.building.parcelIdentification.landArea);

      // About the building
      this.buildingSectionData = {
        building_name: this.building.building_name,
        completion_year: this.building.completion_year,
        is_part_of_property: Boolean(this.building.is_part_of_property),
        info_about_property: this.building.info_about_property,
        building_type: this.building.building_type,
        building_type_comment: this.building.building_type_comment,
        building_grade_id: this.building.building_grade_id,
        energy_efficiency_grade_id: this.building.energy_efficiency_grade_id,
        developer: this.building.developer,
        anchor_tenant: this.building.anchor_tenant,
        foundation_type_id: this.building.foundation_type_id,
        building_description: this.building.building_description
      }

      this.sizesSubject.next(this.building.sizes);
    });
  }
  createForm() {
    this.formSelectionCtrl.setValue(this.building.form_type);
    this.formSelectionCtrl.updateValueAndValidity();
    this.formSelectionCtrl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      if (val == 1) {
        this.isComplexForm = true;
      } else {
        this.isComplexForm = false;
      }
    });
    this.formGroup = this.fb.group({});
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
  back() {
    this.location.back();
  }
  onSubmit(isComplete: boolean) { 
    let errorFields = [];
    this.hasFormErrors = false;

    if (this.mapLocationComponent) {
      if (isComplete && this.mapLocationComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
      }
    }

    if (this.parcelIdentificationComponent) {
      if (isComplete && this.parcelIdentificationComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.parcelIdentificationComponent.errorFields];
      }
    }

    if (this.buildingSectionComponent) {
      if (isComplete && this.buildingSectionComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.buildingSectionComponent.errorFields];
      }
    }

    if (this.externalAspectComponent) {
      if (isComplete && this.externalAspectComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.externalAspectComponent.errorFields];
      }
    }

    if (this.internalAspectComponent) {
      if (isComplete && this.internalAspectComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.internalAspectComponent.errorFields];
      }
    }

    if (this.acGroundsComponent) {
      if (isComplete && this.acGroundsComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.acGroundsComponent.errorFields];
      }
    }

    if (isComplete && this.uploadFileComponent.uploadFiles.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'pic'];
    } else {
      const uploadedFiles = this.uploadFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasFormErrors = true;
          this.showTitleRequiredError$.next(true);
          errorFields = [...errorFields, 'pic'];
        }
      })
    }

    if (this.hasFormErrors) {
      this.error.fields = errorFields;
      return;
    }

    const building = this._prepareBuilding(isComplete);
    if (building.id) {
      this.updateBuilding(building);
    } else {
      this.createBuilding(building);
    }
  }
  getComponentTitle() {
    if (this.building && this.building.id) {
      return `Edit Building "${this.building.building_name}"`;
    }
    return 'Add Building';
  }
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
  private _prepareBuilding(isComplete: boolean): BuildingModel {
    const _building = new BuildingModel();
    _building.clear();
    _building.id = this.building.id;
    _building.status = isComplete ? 1 : 0;
    _building.form_type = this.formSelectionCtrl.value;
    if (this.mapLocationComponent) {
      _building.locationData = this.mapLocationComponent.getData();
    }
    if (this.parcelIdentificationComponent) {
      _building.parcelIdentification = this.parcelIdentificationComponent.getData();
    }
    if (this.buildingSectionComponent) {
      const _data = this.buildingSectionComponent.getData();
      _building.building_name = _data.building_name;
      _building.completion_year = _data.completion_year;
      _building.is_part_of_property = _data.is_part_of_property;
      _building.info_about_property = _data.info_about_property;
      _building.building_type = _data.building_type;
      _building.building_type_comment = _data.building_type_comment;
      _building.building_grade_id = _data.building_grade_id;
      _building.energy_efficiency_grade_id = _data.energy_efficiency_grade_id;
      _building.developer = _data.developer;
      _building.anchor_tenant = _data.anchor_tenant;
      _building.foundation_type_id = _data.foundation_type_id;
      _building.building_description = _data.building_description;
    }
    if (this.servicesAndInfraComponent) {
      _building.serviceAndInfraData = this.servicesAndInfraComponent.getdata();
    }
    if (this.externalAspectComponent) {
      _building.externalAspectData = this.externalAspectComponent.getData();
    }

    if (this.internalAspectComponent) {
      _building.internalAspectData = this.internalAspectComponent.getData();
    }

    if (this.acGroundsComponent) {
      _building.grounds = {...this.acGroundsComponent.getData(), id: this.building.grounds.id};
    }
    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
      if (value) {
        _building.picture = value.success;
      }
    })
    _building.sizes = this.sizesSubject.value;
    if (this.acDocumentUploadComponent) {
      _building.documents = this.acDocumentUploadComponent.getDocuments();
    }

    return _building;
  }
  createBuilding(_building: BuildingModel) {
    this.store.dispatch(new BuildingOnServerCreated({
      building: _building,
      files: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.layoutUtilsService.showActionNotification('Saved the changes', MessageType.Create, 3000, true, false);
    this.location.back();
  }
  updateBuilding(_building: BuildingModel) {
    const updateBuilding: Update<BuildingModel> = {
      id: _building.id,
      changes: _building
    };
    this.store.dispatch(new BuildingUpdated({
      partialBuilding: updateBuilding,
      building: _building,
      files: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.layoutUtilsService.showActionNotification('Saved the changes', MessageType.Update, 3000, true, false);
    this.location.back();
  }
  onTabChanged($event) {
    const activeIndex = $event.index;
  }
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  changeTab(section: string) {
    const section_tab = [
      {sections: ['location', 'parcel'], tab_index: 0},
      {sections: ['prop'], tab_index: 1},
      {sections: ['consideration'], tab_index: 2},
      {sections: ['pic'], tab_index: 3},
    ]
    const active_Tab = section_tab.find(item => item.sections.includes(section));
    this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
    this.selectedtabchange.next(this.selectedTab);
  }
  erroTabMap() {
    return this.typesUtilsService.getBuildingTabErrorMap(this.isComplexForm, this.error.fields, this.tabHeaders);
  }
  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormErrors = obj.hasFormErrors;
  }
}