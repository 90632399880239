<div class="col-md-12 kt-margin-bottom-20-mobile px-0 margin-top-4">
    <h5 class="my-3 text-mad text-uppercase pl-4">{{ pictureType == 'ac' ? 'A - Property' : (tpDetails ?  (tpDetails.ac_pictures.length > 0 ? 'B - Building' : 'A - Building') : '')}}</h5>
    <hr class="active">
</div>
<div *ngIf="!(loading$|async)">
    <div class="form-group row" *ngIf="data$|async as pictures; else noImage">
        <div  *ngFor="let img of pictures; let i = index" [id]="img.slideId" class="col-lg-4 margin-bottom-2">
            <img class="rounded" [src]="img.url" style="height: 400px; width: 100%; object-fit: cover;" (click)="previewUploadedFile(img, i)"/>
            <div class="image-caption" (click)="previewUploadedFile(img, i)">
                <div>
                    {{img.type + ' (' + img.orderNum + ') - '}} 
                    <span>
                        <span class="img-name">{{img.name}}&nbsp;</span>
                        {{(img.description ? (' - ' + img.description) : '')}}</span>
                </div>                
                <div class="image-action">
                    <ng-container *ngTemplateOutlet="actionTemplate ? actionTemplate : defaultAction;context:{image: img}"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #noImage>
    <div>No Image is provided</div>
</ng-template>
<ng-template #defaultAction>
</ng-template>