// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {MainWallService} from '../_services/main-wall.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allMainWallsLoaded} from '../_selectors/main-wall.selectors';
// Actions
import {
    AllMainWallsLoaded,
    AllMainWallsRequested,
    MainWallActionTypes,
    MainWallsPageRequested,
    MainWallsPageLoaded,
    MainWallUpdated,
    MainWallsPageToggleLoading,
    MainWallDeleted,
    MainWallOnServerCreated,
    MainWallCreated,
    MainWallActionToggleLoading, MainWallTrashFlushed, MainWallOnServerRestored, MainWallRestored,
    MainWallOnServerAdminRestored, MainWallAdminRestored, MainWallDeletedFromTrash, MainWallDeletedFromAdminTrash
} from '../_actions/main-wall.actions';

@Injectable()
export class MainWallEffects {
    showPageLoadingDispatcher = new MainWallsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new MainWallActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new MainWallActionToggleLoading({isLoading: false});

    @Effect()
    loadAllMainWall$ = this.actions$
        .pipe(
            ofType<AllMainWallsRequested>(MainWallActionTypes.AllMainWallsRequested),
            withLatestFrom(this.store.pipe(select(allMainWallsLoaded))),
            filter(([action, isAllMainWallLoaded]) => !isAllMainWallLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllMainWallsLoaded({items: res.data});
            })
        );

    @Effect()
    loadMainWallPage$ = this.actions$
        .pipe(
            ofType<MainWallsPageRequested>(MainWallActionTypes.MainWallsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new MainWallsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateMainWall$ = this.actions$
        .pipe(
            ofType<MainWallUpdated>(MainWallActionTypes.MainWallUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createMainWall$ = this.actions$
        .pipe(
            ofType<MainWallOnServerCreated>(MainWallActionTypes.MainWallOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new MainWallCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushMainWallTrash$ = this.actions$
        .pipe(
            ofType<MainWallTrashFlushed>(MainWallActionTypes.MainWallTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreMainWall$ = this.actions$
        .pipe(
            ofType<MainWallOnServerRestored>(MainWallActionTypes.MainWallOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new MainWallRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminMainWall$ = this.actions$
        .pipe(
            ofType<MainWallOnServerAdminRestored>(MainWallActionTypes.MainWallOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new MainWallAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteMainWall$ = this.actions$
        .pipe(
            ofType<MainWallDeleted>(MainWallActionTypes.MainWallDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashMainWall$ = this.actions$
        .pipe(
            ofType<MainWallDeletedFromTrash>(MainWallActionTypes.MainWallDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashMainWall$ = this.actions$
        .pipe(
            ofType<MainWallDeletedFromAdminTrash>(MainWallActionTypes.MainWallDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: MainWallService, private store: Store<AppState>) {
    }
}
