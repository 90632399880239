import {ExternalWall} from '../_models/external-wall.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ExternalWallsState} from '../_reducers/external-wall.reducers';
import * as fromExternalWall from '../_reducers/external-wall.reducers';
import {each} from 'lodash';

export const selectExternalWallsState = createFeatureSelector<ExternalWallsState>('externalWalls');

export const selectExternalWallById = (externalWallId: number) => createSelector(
    selectExternalWallsState,
    externalWallsState => externalWallsState.entities[externalWallId]
);

export const selectAllExternalWalls = createSelector(
    selectExternalWallsState,
    fromExternalWall.selectAll
);

export const selectAllExternalWallsIds = createSelector(
    selectExternalWallsState,
    fromExternalWall.selectIds
);

export const allExternalWallsLoaded = createSelector(
    selectExternalWallsState,
    externalWallsState => externalWallsState.isAllExternalWallsLoaded
);


export const selectExternalWallsPageLoading = createSelector(
    selectExternalWallsState,
    externalWallsState => externalWallsState.listLoading
);

export const selectExternalWallsActionLoading = createSelector(
    selectExternalWallsState,
    externalWallsState => externalWallsState.actionLoading
);

export const selectLastCreatedExternalWallId = createSelector(
    selectExternalWallsState,
    externalWallsState => externalWallsState.lastCreatedExternalWallId
);

export const selectExternalWallsShowInitWaitingMessage = createSelector(
    selectExternalWallsState,
    externalWallsState => externalWallsState.showInitWaitingMessage
);

export const selectTrashedExternalWallCount = createSelector(
    selectExternalWallsState,
    (externalWallsState) => externalWallsState.totalTrashed
);

export const selectAdminTrashedExternalWallCount = createSelector(
    selectExternalWallsState,
    (externalWallsState) => externalWallsState.totalAdminTrashed
);


export const selectExternalWallQueryResult = createSelector(
    selectExternalWallsState,
    externalWallsState => {
        const items: ExternalWall[] = [];
        each(externalWallsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ExternalWall[] = httpExtension.sortArray(items, externalWallsState.lastQuery.sortField, externalWallsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, externalWallsState.totalCount, '', externalWallsState.totalTrashed);
    }
);
