import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { ValuationAdjustmentModel } from "../_models/valuation-adjustment.model";
import { ValuationAdjustmentsState } from "../_reducers/valuation-adjustment.reducer";

export const selectValuationAdjustmentsState = createFeatureSelector<ValuationAdjustmentsState>('valuation-adjustments');

export const selectAllValuationAdjustments = createSelector(
    selectValuationAdjustmentsState,
    state => {
        const items: ValuationAdjustmentModel[] = [];
        each(state.entities, el => items.push(el));
        return items;
    }
)

export const selectTotalNumOfValuationAdjustments = createSelector(
    selectValuationAdjustmentsState,
    state => {
        let num = 0;
        each(state.entities, el => num++);
        return num;
    }
)

export const selectLastCreatedValuationAdjustmentID = createSelector(
    selectValuationAdjustmentsState,
    state => state.lastCreatedID
)

export const selectSingleValuationAdjustment = (id: number) => createSelector(
    selectValuationAdjustmentsState,
    state => state.entities[id]
)