import {Component, OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {OfficeStandardMeasurementUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import {
    OfficeStandardMeasurement,
    OfficeStandardMeasurementOnServerCreated,
    selectOfficeStandardMeasurementsActionLoading,
    selectOfficeStandardMeasurementById,
    selectLastCreatedOfficeStandardMeasurementId
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';

@Component({
    selector: 'kt-office-standard-measurement-edit',
    templateUrl: './office-standard-measurement-edit.component.html',
    styleUrls: ['./office-standard-measurement-edit.component.scss']
})


export class OfficeStandardMeasurementEditComponent implements OnInit, OnDestroy {

    model: OfficeStandardMeasurement;
    oldModel: OfficeStandardMeasurement;

    officeStandardMeasurementForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param subheaderService: SubheaderService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param store: Store<AppState>
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectOfficeStandardMeasurementsActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectOfficeStandardMeasurementById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initOfficeStandardMeasurement();
                    }
                });
            } else {
                this.model = new OfficeStandardMeasurement();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initOfficeStandardMeasurement();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../admin-management`},
            {title: 'Linked tables', page: `../admin-management/linked-tables`},
            {title: 'Office Standard Measurements', page: `../admin-management/linked-tables/approaches-to-values`},
            {
                title: this.getComponentTitle(),
                page: id > 0 ? `../admin-management/linked-tables/approaches-to-values/edit/${id}`
                    : `../admin-management/linked-tables/approaches-to-values/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initOfficeStandardMeasurement() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.officeStandardMeasurementForm = this.fb.group({
            name: [this.model.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return `Edit Office Standard Measurement '${
                this.model.name
                }'`;
        }
        return 'New Office Standard Measurement';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.officeStandardMeasurementForm.controls[controlName];
        return control.invalid && control.touched;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.officeStandardMeasurementForm.markAsPristine();
        this.officeStandardMeasurementForm.markAsUntouched();
        this.officeStandardMeasurementForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.officeStandardMeasurementForm.controls;
        /** check form */
        if (this.officeStandardMeasurementForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.model.id > 0) {
            this.updateOfficeStandardMeasurement(this.model);
        } else {
            this.createOfficeStandardMeasurement(this.model);
        }
    }

    /**
     * Update model
     *
     * @param _model: OfficeStandardMeasurementModel
     */
    updateOfficeStandardMeasurement(_model: OfficeStandardMeasurement) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateOfficeStandardMeasurement: Update<OfficeStandardMeasurement> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new OfficeStandardMeasurementUpdated({
            partialofficeStandardMeasurement: updateOfficeStandardMeasurement,
            officeStandardMeasurement: _model
        }));
        this.oldModel = _model;

        this.navigateToParent();
    }

    /**
     * Create model
     *
     * @param _model: OfficeStandardMeasurementModel
     */
    createOfficeStandardMeasurement(_model: OfficeStandardMeasurement) {
        this.store.dispatch(new OfficeStandardMeasurementOnServerCreated({officeStandardMeasurement: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedOfficeStandardMeasurementId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }
}
