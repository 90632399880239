// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TypeOfTitle} from '../_models/type-of-title.model';

export enum TypeOfTitleActionTypes {

    AllTypeOfTitlesRequested = '[TypeOfTitles Home Page] All TypeOfTitles Requested',
    AllTypeOfTitlesLoaded = '[TypeOfTitles API] All TypeOfTitles Loaded',

    TypeOfTitleOnServerCreated = '[Edit TypeOfTitle Dialog] TypeOfTitle On Server Created',
    TypeOfTitleCreated = '[Edit TypeOfTitles Dialog] TypeOfTitles Created',
    TypeOfTitleUpdated = '[Edit TypeOfTitle Dialog] TypeOfTitle Updated',
    TypeOfTitleDeleted = '[TypeOfTitles List Page] TypeOfTitle Deleted',

    TypeOfTitlesPageRequested = '[TypeOfTitles List Page] TypeOfTitles Page Requested',
    TypeOfTitlesPageLoaded = '[TypeOfTitles API] TypeOfTitles Page Loaded',
    TypeOfTitlesPageCancelled = '[TypeOfTitles API] TypeOfTitles Page Cancelled',

    TypeOfTitlesPageToggleLoading = '[TypeOfTitles page] TypeOfTitles Page Toggle Loading',
    TypeOfTitlesActionToggleLoading = '[TypeOfTitles] TypeOfTitles Action Toggle Loading',

    // trash system

    TypeOfTitleOnServerRestored = '[TypeOfTitle Trash] TypeOfTitle On Server Restored',
    TypeOfTitleRestored = '[TypeOfTitle Trash] TypeOfTitle Restored',

    TypeOfTitleOnServerAdminRestored = '[TypeOfTitle Admin Trash] TypeOfTitle On Server Restored',
    TypeOfTitleAdminRestored = '[TypeOfTitle Admin Trash] TypeOfTitle Restored',

    TypeOfTitleDeletedFromTrash = '[TypeOfTitle Trash] TypeOfTitle deleted',
    TypeOfTitleDeletedFromAdminTrash = '[TypeOfTitle Admin Trash] TypeOfTitle deleted',

    TypeOfTitleTrash = 'TypeOfTitle Trashed',
    TypeOfTitleTrashFlushed = 'TypeOfTitle Trash Flushed',
}

export class TypeOfTitleOnServerCreated implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleOnServerCreated;

    constructor(public payload: { typeOfTitle: TypeOfTitle}) {
    }
}

export class TypeOfTitleCreated implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleCreated;

    constructor(public payload: { typeOfTitle: TypeOfTitle }) {
    }
}

export class TypeOfTitleUpdated implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleUpdated;

    constructor(public payload: {
        partialTypeOfTitle: Update<TypeOfTitle>,
        typeOfTitle: TypeOfTitle
    }) {
    }
}

export class TypeOfTitleDeleted implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfTitlesPageRequested implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitlesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class TypeOfTitlesPageLoaded implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitlesPageLoaded;

    constructor(public payload: { typeOfTitles: TypeOfTitle[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class TypeOfTitlesPageCancelled implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitlesPageCancelled;
}

export class AllTypeOfTitlesRequested implements Action {
    readonly type = TypeOfTitleActionTypes.AllTypeOfTitlesRequested;
}

export class AllTypeOfTitlesLoaded implements Action {
    readonly type = TypeOfTitleActionTypes.AllTypeOfTitlesLoaded;

    constructor(public payload: { typeOfTitles: TypeOfTitle[] }) {
    }
}

export class TypeOfTitlesPageToggleLoading implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitlesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class TypeOfTitlesActionToggleLoading implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitlesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class TypeOfTitleDeletedFromAdminTrash implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class TypeOfTitleDeletedFromTrash implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfTitleOnServerRestored implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfTitleRestored implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleRestored;

    constructor(public payload: { item: TypeOfTitle }) {
    }
}

export class TypeOfTitleOnServerAdminRestored implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfTitleAdminRestored implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleAdminRestored;

    constructor(public payload: { item: TypeOfTitle }) {
    }
}

export class TypeOfTitleTrashFlushed implements Action {
    readonly type = TypeOfTitleActionTypes.TypeOfTitleTrashFlushed;

    constructor() {
    }
}

export type TypeOfTitleActions = TypeOfTitleCreated
    | TypeOfTitleUpdated
    | TypeOfTitleDeleted
    | TypeOfTitlesPageRequested
    | TypeOfTitlesPageLoaded
    | TypeOfTitlesPageCancelled
    | AllTypeOfTitlesLoaded
    | AllTypeOfTitlesRequested
    | TypeOfTitleOnServerCreated
    | TypeOfTitlesPageToggleLoading
    | TypeOfTitlesActionToggleLoading
    | TypeOfTitleDeletedFromAdminTrash
    | TypeOfTitleDeletedFromTrash
    | TypeOfTitleOnServerRestored
    | TypeOfTitleRestored
    | TypeOfTitleOnServerAdminRestored
    | TypeOfTitleAdminRestored
    | TypeOfTitleTrashFlushed;
