import {InteractionType} from '../_models/interaction-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {InteractionTypesState} from '../_reducers/interaction-type.reducers';
import * as fromInteractionType from '../_reducers/interaction-type.reducers';
import {each} from 'lodash';

export const selectInteractionTypesState = createFeatureSelector<InteractionTypesState>('interactionTypes');

export const selectInteractionTypeById = (interactionTypeId: number) => createSelector(
    selectInteractionTypesState,
    interactionTypesState => interactionTypesState.entities[interactionTypeId]
);

export const selectAllInteractionTypes = createSelector(
    selectInteractionTypesState,
    fromInteractionType.selectAll
);

export const selectAllInteractionTypesIds = createSelector(
    selectInteractionTypesState,
    fromInteractionType.selectIds
);

export const allInteractionTypesLoaded = createSelector(
    selectInteractionTypesState,
    interactionTypesState => interactionTypesState.isAllInteractionTypesLoaded
);


export const selectInteractionTypesPageLoading = createSelector(
    selectInteractionTypesState,
    interactionTypesState => interactionTypesState.listLoading
);

export const selectInteractionTypesActionLoading = createSelector(
    selectInteractionTypesState,
    interactionTypesState => interactionTypesState.actionLoading
);

export const selectLastCreatedInteractionTypeId = createSelector(
    selectInteractionTypesState,
    interactionTypesState => interactionTypesState.lastCreatedInteractionTypeId
);

export const selectInteractionTypesShowInitWaitingMessage = createSelector(
    selectInteractionTypesState,
    interactionTypesState => interactionTypesState.showInitWaitingMessage
);

export const selectTrashedInteractionTypeCount = createSelector(
    selectInteractionTypesState,
    (interactionTypesState) => interactionTypesState.totalTrashed
);

export const selectAdminTrashedInteractionTypeCount = createSelector(
    selectInteractionTypesState,
    (interactionTypesState) => interactionTypesState.totalAdminTrashed
);


export const selectQueryResult = createSelector(
    selectInteractionTypesState,
    interactionTypesState => {
        const items: InteractionType[] = [];
        each(interactionTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: InteractionType[] = httpExtension.sortArray(items, interactionTypesState.lastQuery.sortField, interactionTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, interactionTypesState.totalCount, '', interactionTypesState.totalTrashed);
    }
);
