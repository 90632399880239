<div class="p-0 m-0" *ngIf="loaded$|async">
    <kt-comparable-tab-map class="section-margion-bottom-60"
        [readonly]="readonly"
        [targetProperty]="targetProperty"
        [valuation]="valuation"></kt-comparable-tab-map>
    <kt-comparable-tab-table
        [readonly]="readonly"
        [targetProperty]="targetProperty"
        [currencyExchangeChannel]="currencyExchangeChannel"
        [allConvertedChannel]="allConvertedChannel"
        (onAddComparable)="addComparable()"></kt-comparable-tab-table>
</div>