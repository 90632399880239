import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UploadFileComponent } from '../../../shared_components/upload-file/upload-file.component';

@Component({
  selector: 'kt-inspection-building-pictures',
  templateUrl: './inspection-building-pictures.component.html',
  styleUrls: ['./inspection-building-pictures.component.scss']
})
export class InspectionBuildingPicturesComponent implements OnInit {
  @ViewChild('buildingPic', {static: false})
  public uploadFileComponent: UploadFileComponent;
  @Input() buildingId: number = null;
  @Input() buildingPictures: any[] = [];
  @Input() buildingPicturePath: string = null;
  @Input() hasFormErrors: boolean = false;
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  errorFields = [];
  hasErrors = false;
  constructor() { }

  ngOnInit(): void {
  }

  checkValidation(isComplete: boolean): boolean {
    this.errorFields = [];
    let errorFields = new Set<string>();
    let isValid = true;
    this.hasErrors = false;
    this.showTitleRequiredError$.next(false);
    let hasTitleError = false;

    if (isComplete && this.uploadFileComponent.uploadFiles.length < 1) {
      this.hasErrors = true;
      isValid = false;
      errorFields.add('no_picture_build');
    } else if (isComplete) {
      const uploadedFiles = this.uploadFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasErrors = true;
          hasTitleError = true;
          isValid = false;
          errorFields.add('no_picture_title_build');
        }
      })
    }
    this.showTitleRequiredError$.next(hasTitleError);

    for (let v of errorFields.values()) {
      this.errorFields.push(v);
    }
    return isValid;
  }

  getData() {
    let picture = '';
    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
      if (value) {
        picture = value.success; 
      }
    })
    return {
      picture,
      pictures: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }
  }

}
