// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {PurposeOfValuationsService} from '../_services/purpose-of-valuation.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allPurposeOfValuationsLoaded} from '../_selectors/purpose-of-valuation.selectors';
// Actions
import {
    AllPurposeOfValuationsLoaded,
    AllPurposeOfValuationsRequested,
    PurposeOfValuationActionTypes,
    PurposeOfValuationsPageRequested,
    PurposeOfValuationsPageLoaded,
    PurposeOfValuationUpdated,
    PurposeOfValuationsPageToggleLoading,
    PurposeOfValuationDeleted,
    PurposeOfValuationOnServerCreated,
    PurposeOfValuationCreated,
    PurposeOfValuationsActionToggleLoading, PurposeOfValuationTrashFlushed, PurposeOfValuationOnServerRestored, PurposeOfValuationRestored,
    PurposeOfValuationOnServerAdminRestored, PurposeOfValuationAdminRestored, PurposeOfValuationDeletedFromTrash,
    PurposeOfValuationDeletedFromAdminTrash
} from '../_actions/purpose-of-valuation.actions';

@Injectable()
export class PurposeOfValuationEffects {
    showPageLoadingDispatcher = new PurposeOfValuationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PurposeOfValuationsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PurposeOfValuationsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllPurposeOfValuation$ = this.actions$
        .pipe(
            ofType<AllPurposeOfValuationsRequested>(PurposeOfValuationActionTypes.AllPurposeOfValuationsRequested),
            withLatestFrom(this.store.pipe(select(allPurposeOfValuationsLoaded))),
            filter(([action, isAllPurposeOfValuationLoaded]) => !isAllPurposeOfValuationLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllPurposeOfValuationsLoaded({purposeOfValuations: res.data});
            })
        );

    @Effect()
    loadPurposeOfValuationPage$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationsPageRequested>(PurposeOfValuationActionTypes.PurposeOfValuationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PurposeOfValuationsPageLoaded({
                    purposeOfValuations: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updatePurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationUpdated>(PurposeOfValuationActionTypes.PurposeOfValuationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.purposeOfValuation);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createPurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationOnServerCreated>(PurposeOfValuationActionTypes.PurposeOfValuationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.purposeOfValuation).pipe(
                    tap(res => {
                        this.store.dispatch(new PurposeOfValuationCreated({purposeOfValuation: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushPurposeOfValuationTrash$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationTrashFlushed>(PurposeOfValuationActionTypes.PurposeOfValuationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationOnServerRestored>(PurposeOfValuationActionTypes.PurposeOfValuationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PurposeOfValuationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminPurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationOnServerAdminRestored>(PurposeOfValuationActionTypes.PurposeOfValuationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PurposeOfValuationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deletePurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationDeleted>(PurposeOfValuationActionTypes.PurposeOfValuationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashPurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationDeletedFromTrash>(PurposeOfValuationActionTypes.PurposeOfValuationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashPurposeOfValuation$ = this.actions$
        .pipe(
            ofType<PurposeOfValuationDeletedFromAdminTrash>(PurposeOfValuationActionTypes.PurposeOfValuationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: PurposeOfValuationsService, private store: Store<AppState>) {
    }
}
