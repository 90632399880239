// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {OfficeLayout} from '../_models/office-layout.model';

export enum OfficeLayoutActionTypes {

    AllOfficeLayoutsRequested = '[OfficeLayouts Home Page] All OfficeLayouts Requested',
    AllOfficeLayoutsLoaded = '[OfficeLayouts API] All OfficeLayouts Loaded',

    OfficeLayoutOnServerCreated = '[Edit OfficeLayout Dialog] OfficeLayout On Server Created',
    OfficeLayoutCreated = '[Edit OfficeLayouts Dialog] OfficeLayouts Created',
    OfficeLayoutUpdated = '[Edit OfficeLayout Dialog] OfficeLayout Updated',

    OfficeLayoutsPageRequested = '[OfficeLayouts List Page] OfficeLayouts Page Requested',
    OfficeLayoutsPageLoaded = '[OfficeLayouts API] OfficeLayouts Page Loaded',
    OfficeLayoutsPageCancelled = '[OfficeLayouts API] OfficeLayouts Page Cancelled',

    OfficeLayoutsPageToggleLoading = '[OfficeLayouts page] OfficeLayouts Page Toggle Loading',
    OfficeLayoutsActionToggleLoading = '[OfficeLayouts] OfficeLayouts Action Toggle Loading',

    OfficeLayoutDeleted = '[OfficeLayouts List Page] OfficeLayout Deleted',

    // trash system

    OfficeLayoutOnServerRestored = '[OfficeLayout Trash] OfficeLayout On Server Restored',
    OfficeLayoutRestored = '[OfficeLayout Trash] OfficeLayout Restored',

    OfficeLayoutOnServerAdminRestored = '[OfficeLayout Admin Trash] OfficeLayout On Server Restored',
    OfficeLayoutAdminRestored = '[OfficeLayout Admin Trash] OfficeLayout Restored',

    OfficeLayoutDeletedFromTrash = '[OfficeLayout Trash] OfficeLayout deleted',
    OfficeLayoutDeletedFromAdminTrash = '[OfficeLayout Admin Trash] OfficeLayout deleted',

    OfficeLayoutTrash = 'OfficeLayout Trashed',
    OfficeLayoutTrashFlushed = 'OfficeLayout Trash Flushed',
}

export class OfficeLayoutOnServerCreated implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutOnServerCreated;

    constructor(public payload: { officeLayout: OfficeLayout }) {
    }
}

export class OfficeLayoutCreated implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutCreated;

    constructor(public payload: { officeLayout: OfficeLayout }) {
    }
}

export class OfficeLayoutUpdated implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutUpdated;

    constructor(public payload: {
        partialofficeLayout: Update<OfficeLayout>,
        officeLayout: OfficeLayout
    }) {
    }
}

export class OfficeLayoutDeleted implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeLayoutsPageRequested implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OfficeLayoutsPageLoaded implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutsPageLoaded;

    constructor(public payload: { officeLayouts: OfficeLayout[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OfficeLayoutsPageCancelled implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutsPageCancelled;
}

export class AllOfficeLayoutsRequested implements Action {
    readonly type = OfficeLayoutActionTypes.AllOfficeLayoutsRequested;
}

export class AllOfficeLayoutsLoaded implements Action {
    readonly type = OfficeLayoutActionTypes.AllOfficeLayoutsLoaded;

    constructor(public payload: { officeLayouts: OfficeLayout[] }) {
    }
}

export class OfficeLayoutsPageToggleLoading implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OfficeLayoutsActionToggleLoading implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OfficeLayoutDeletedFromAdminTrash implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class OfficeLayoutDeletedFromTrash implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeLayoutOnServerRestored implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeLayoutRestored implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutRestored;

    constructor(public payload: { item: OfficeLayout }) {
    }
}

export class OfficeLayoutOnServerAdminRestored implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeLayoutAdminRestored implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutAdminRestored;

    constructor(public payload: { item: OfficeLayout }) {
    }
}

export class OfficeLayoutTrashFlushed implements Action {
    readonly type = OfficeLayoutActionTypes.OfficeLayoutTrashFlushed;

    constructor() {
    }
}

export type OfficeLayoutActions = OfficeLayoutCreated
    | OfficeLayoutUpdated
    | OfficeLayoutsPageRequested
    | OfficeLayoutsPageLoaded
    | OfficeLayoutsPageCancelled
    | AllOfficeLayoutsLoaded
    | AllOfficeLayoutsRequested
    | OfficeLayoutOnServerCreated
    | OfficeLayoutsPageToggleLoading
    | OfficeLayoutsActionToggleLoading
    | OfficeLayoutDeleted
    | OfficeLayoutDeletedFromAdminTrash
    | OfficeLayoutDeletedFromTrash
    | OfficeLayoutOnServerRestored
    | OfficeLayoutRestored
    | OfficeLayoutOnServerAdminRestored
    | OfficeLayoutAdminRestored
    | OfficeLayoutTrashFlushed;
