// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
// Models
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const RETAILS_PATH = '/facilities';
const RETAILS_EXCLUSIVE_PATH = '/facilities/exlusive';
const RETAILS_BUILDING_PATH = '/building/facilities';

@Injectable()
export class AssetClassRetailFacilitiesService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/retails/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAllAssetClassRetailFacilities(assetClassRetailFacilityId: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + assetClassRetailFacilityId + RETAILS_PATH;
        return this.http.get<any>(url);
    }

    getAllAssetClassRetailFacilitiesExclusive(assetClassRetailFacilityId: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + assetClassRetailFacilityId + RETAILS_EXCLUSIVE_PATH;
        return this.http.get<any>(url);
    }

    getAllAssetClassRetailBuildingFacilities(assetClassRetailFacilityId: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + assetClassRetailFacilityId + RETAILS_BUILDING_PATH;
        return this.http.get<any>(url);
    }
}
