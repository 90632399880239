// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter, Update} from '@ngrx/entity';
// Actions
import {AssetClassActions, AssetClassActionTypes} from '../_actions/asset-class.actions';
// Models
import {AssetClassModel} from '../_models/asset-class.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AssetClassesState extends EntityState<AssetClassModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastCreatedAssetClassId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassModel> = createEntityAdapter<AssetClassModel>();

export const initialAssetClassesState: AssetClassesState = adapter.getInitialState({
    assetClassForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastCreatedAssetClassId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassesReducer(state = initialAssetClassesState, action: AssetClassActions): AssetClassesState {
    switch (action.type) {
        case AssetClassActionTypes.AssetClassesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassId: undefined
            };
        }
        case AssetClassActionTypes.AssetClassActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AssetClassActionTypes.AssetClassOnServerCreated:
            return {
                ...state
            };
        case AssetClassActionTypes.AssetClassCreated:
            return adapter.addOne(action.payload.assetClass, {
                ...state, lastCreatedAssetClassId: action.payload.assetClass.id
            });
        case AssetClassActionTypes.AssetClassOnServerUpdated:
            return {
                ...state
            };
        case AssetClassActionTypes.AssetClassUpdated:
            const _item: Update<AssetClassModel> = {
                id: action.payload.assetClass.id,
                changes: action.payload.assetClass
            };
            return adapter.updateOne(_item, state);

        // return adapter.addOne(, {
        //     ...state, lastCreatedAssetClassId: action.payload.assetClass.id
        // });

        case AssetClassActionTypes.AssetClassValuationDeleted:
            return state;
        // return adapter.updateOne(action.payload.partialItem, ...state);

        case AssetClassActionTypes.AssetClassesPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AssetClassActionTypes.AssetClassesPageLoaded: {
            // adapter.removeAll(state);
            return adapter.upsertMany(action.payload.assetClasses, {
                ...state,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case AssetClassActionTypes.AssetClassOnServerRestored:
            return {
                ...state
            };
        case AssetClassActionTypes.AssetClassRestored:
            return adapter.addOne(action.payload.assetClass, {
                ...state,
                lastCreatedAssetClassId: action.payload.assetClass.id,
                totalTrashed: state.totalTrashed - 1
            });
        case AssetClassActionTypes.AssetClassDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1
            };
        case AssetClassActionTypes.OneAssetClassDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AssetClassActionTypes.AssetClassTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const getAssetClassState = createFeatureSelector<AssetClassModel>('assetClasses');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
