import {BathroomFitting} from '../_models/bathroom-fitting.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {BathroomFittingsState} from '../_reducers/bathroom-fitting.reducers';
import * as fromBathroomFitting from '../_reducers/bathroom-fitting.reducers';
import {each} from 'lodash';

export const selectBathroomFittingsState = createFeatureSelector<BathroomFittingsState>('bathroomFittings');

export const selectBathroomFittingById = (bathroomFittingId: number) => createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => bathroomFittingsState.entities[bathroomFittingId]
);

export const selectAllBathroomFittings = createSelector(
    selectBathroomFittingsState,
    fromBathroomFitting.selectAll
);

export const selectAllBathroomFittingsIds = createSelector(
    selectBathroomFittingsState,
    fromBathroomFitting.selectIds
);

export const allBathroomFittingsLoaded = createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => bathroomFittingsState.isAllBathroomFittingsLoaded
);


export const selectBathroomFittingsPageLoading = createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => bathroomFittingsState.listLoading
);

export const selectBathroomFittingsActionLoading = createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => bathroomFittingsState.actionLoading
);

export const selectLastCreatedBathroomFittingId = createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => bathroomFittingsState.lastCreatedBathroomFittingId
);

export const selectBathroomFittingsShowInitWaitingMessage = createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => bathroomFittingsState.showInitWaitingMessage
);

export const selectTrashedBathroomFittingCount = createSelector(
    selectBathroomFittingsState,
    (bathroomFittingsState) => bathroomFittingsState.totalTrashed
);

export const selectAdminTrashedBathroomFittingCount = createSelector(
    selectBathroomFittingsState,
    (bathroomFittingsState) => bathroomFittingsState.totalAdminTrashed
);


export const selectBathroomFittingQueryResult = createSelector(
    selectBathroomFittingsState,
    bathroomFittingsState => {
        const items: BathroomFitting[] = [];
        each(bathroomFittingsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BathroomFitting[] = httpExtension.sortArray(items, bathroomFittingsState.lastQuery.sortField, bathroomFittingsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, bathroomFittingsState.totalCount, '', bathroomFittingsState.totalTrashed);
    }
);
