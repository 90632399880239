<ng-template #userUploadedRows>
    <ng-container *ngIf="dataSource$|async as dataSource">
        <tr *ngFor="let data of dataSource; let i = index" class="bg-white">
            <td class="column-type">
                <span *ngIf="data.name.length === 0">N/I</span>
                <img *ngIf="data.name.length > 0" [attr.src]="typesUtilsService.setIcon(getFileType(data.name))" />
            </td>
            <td class="column-required">No</td>
            <td class="column-category">
                {{ data.cat_name }} {{data.cat_cnt != 1 || (i + 1 < uploadFiles.length && data.cat_id == uploadFiles[i + 1].cat_id) ? data.cat_cnt : ''}}
            </td>
            <td class="column-description">
                {{data.description ? data.description : ''}}
            </td>
            <td class="column-available">
                YES
            </td>
            <td class="column-actions">
                <button mat-icon-button
                        [disabled]="tpState == 1 || toeStatus == 4"
                        color="warn"
                        matTooltip="Delete File"
                        (click)="deleteUploadedFile(data)"
                        type="button">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button
                        matTooltip="Preview file"
                        (click)="previewUploadedFile(data)"
                        type="button">
                    <mat-icon *ngIf="!docs.includes(getFileType(data.name))">remove_red_eye</mat-icon>
                    <mat-icon *ngIf="docs.includes(getFileType(data.name))">get_app</mat-icon>
                </button>

                <button mat-icon-button
                        [disabled]="tpState == 1 || toeStatus == 4"
                        matTooltip="Edit"
                        (click)="editFileInfo(data)"
                        color="primary"
                        type="button">
                    <mat-icon>create</mat-icon>

                </button>
            </td>
        </tr>
    </ng-container>
</ng-template>