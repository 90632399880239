// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {TpTaskState} from '../_reducers/tp-task.reducers';
import * as fromTpTasks from '../_reducers/tp-task.reducers';
import {TpTaskModel} from '../_models/tp-task.model';
// Lodash
import {each, find} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
import { ReportType } from 'src/app/views/pages/target-property/_sub/tp-row/_sub/signed-reports/signed-reports.component';

export const selectTpTasksState = createFeatureSelector<TpTaskState>('tpTasks');

export const selectTpTaskById = (itemId: number) => createSelector(
    selectTpTasksState,
    cs => cs.entities[itemId]
);

export const selectAllTpTasks = createSelector(
    selectTpTasksState,
    fromTpTasks.selectAll
);

export const selectAllTpTasksIds = createSelector(
    selectTpTasksState,
    fromTpTasks.selectIds
);

export const allTpTasksLoaded = createSelector(
    selectTpTasksState,
    cs => cs._isAllTpTaskLoaded
);

export const selectTpTasksPageLoading = createSelector(
    selectTpTasksState,
    assetClassesState => assetClassesState.listLoading
);


export const selectTpTasksShowInitWaitingMessage = createSelector(
    selectTpTasksState,
    tpTaskState => tpTaskState.showInitWaitingMessage
);

export const selectTpTasksActionLoading = createSelector(
    selectTpTasksState,
    tpTaskState => tpTaskState.actionsLoading
);

export const selectSignedDocUploadTask = (tpID: number, reportType: ReportType) => createSelector(
    selectTpTasksState,
    state => {
        const rID = reportType == ReportType.DraftStatement ? 1 : 2;
        const task = find(state.entities, el => {
            if (el.tp_id == tpID && el.task_id == 10 && el.second_id == rID) {
                return true;
            }
            return false
        });
        return task;
    }
)

export const selectTpTasksByTpId = (tpId: number) => createSelector(
    selectTpTasksState,
    tpTaskState => {
        const items: TpTaskModel[] = [];
        each(tpTaskState.entities, element => {
            if (element.tp_id == tpId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'order_num');
    }
);

export const selectUploadTaskUsersByTpId = (tpId: number) => createSelector(
    selectTpTasksState,
    tpTaskState => {
        const items: TpTaskModel[] = [];
        each(tpTaskState.entities, element => {
            if (element.tp_id == tpId && element.task_id == 0) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'second_id');
    }
);

export const selectTpTasksByToeId = (toeId: number) => createSelector(
    selectTpTasksState,
    tpTaskState => {
        const items: TpTaskModel[] = [];
        each(tpTaskState.entities, element => {
            if (element.toe_id == toeId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArray(items, 'tp_id');
    }
);


export const selectTpTasksInStore = createSelector(
    selectTpTasksState,
    tpTaskState => {
        const items: TpTaskModel[] = [];
        each(tpTaskState.entities, element => {
            items.push(element);
        });
        let result: TpTaskModel[] = [];
        const httpExtension = new HttpExtenstionsModel();
        const res = httpExtension.separateArrayByCompletion(items);
        let completedResult: TpTaskModel[] = [];
        let inCompletedResult: TpTaskModel[] = [];
        if (tpTaskState.lastQuery.sortField === 'updated_at') {
            completedResult = httpExtension.sortArrayDateTime(
                res['completed'],
                tpTaskState.lastQuery.sortField,
                tpTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayDateTime(
                res['inCompleted'],
                tpTaskState.lastQuery.sortField,
                tpTaskState.lastQuery.sortOrder
            );
        } else if (tpTaskState.lastQuery.sortField === 'completion') {
            completedResult = httpExtension.sortArrayCompletion(
                res['completed'],
                tpTaskState.lastQuery.sortField,
                tpTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayCompletion(
                res['inCompleted'],
                tpTaskState.lastQuery.sortField,
                tpTaskState.lastQuery.sortOrder
            );
        } else {
            completedResult = httpExtension.sortArray(
                res['completed'],
                tpTaskState.lastQuery.sortField,
                tpTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArray(
                res['inCompleted'],
                tpTaskState.lastQuery.sortField,
                tpTaskState.lastQuery.sortOrder
            );
        }
        result = inCompletedResult.concat(completedResult);
        return new QueryResultsModel(result, tpTaskState.totalCount, '');
    }
);