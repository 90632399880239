import { Injectable } from "@angular/core";
import { AssetClassModel } from "../../asset_class";
import { SRERAssumptionModel, SRERStatus, ValuationSRERModel } from "../_models/valuation-srer.model";

@Injectable()
export class ValuationSRERService {
    restoreDefaultAssumption(tp: AssetClassModel, ser: ValuationSRERModel): SRERAssumptionModel {
        const _item = Object.assign({}, ser.defaultAssumption) as SRERAssumptionModel;
        const defSer = tp.external_references.find(er => er.id === ser.refId);
        if (defSer) {
            _item.input = defSer.assumption_descr;
            _item.justification = null;
            _item.status = SRERStatus.Valid;
        }
        return _item;
    }

    restoreSpecialAssumption(tp: AssetClassModel, ser: ValuationSRERModel): SRERAssumptionModel {
        const _item = Object.assign({}, ser.specialAssumption) as SRERAssumptionModel;
        const defSer = tp.external_references.find(er => er.id === ser.refId);
        if (defSer) {
            _item.input = defSer.special_assumption_descr;
            _item.justification = null;
            _item.status = SRERStatus.Valid;
        }
        return _item;
    }
}