import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AssetClassTenure } from 'src/app/core/comparable';
import { ConsiderationConversionProcess } from 'src/app/core/valuation/_models/criterion.model';
import { CFMRatesAndJustifications, CFMReturnType } from './cf-method-types';
import { HoldConversionProcessData } from './conversion-between-lease-and-free-types';
import { HTEProcessData } from './headline-to-effective-process-types';
import { HeadlineToEffectiveComponent} from './headline-to-effective/headline-to-effective.component';
import { CFMethodResult, ChangeBetweenLeaseAndFreeEventData, HeadlineToEffectiveValueCalculatedEventData, SLMethodResult, TVOMMethodResult } from './result-data-types';
import { TVOMRatesAndJustification } from './tvom-types';
import { CalculationType, ValueCalculationComponent } from './value-calculation/value-calculation.component';

export interface IConsiderationModalInputData {
  refNum: string,
  tpCurrency: string;
  process: ConsiderationConversionProcess;
  consideration: AssetClassTenure;
  hteProcessData: HTEProcessData;
  holdConversionProcessData: HoldConversionProcessData;
}

export interface IConsiderationModalReturnData {
  value: number;
  hteProcessData: HTEProcessData;
  holdConversionProcessData: HoldConversionProcessData;
}

@Component({
  selector: 'kt-consideration-modal',
  templateUrl: './consideration-modal.component.html',
  styleUrls: ['./consideration-modal.component.scss']
})
export class ConsiderationModalComponent implements OnInit, OnDestroy {
  @ViewChild('headlineToEffective') headlineToEffectiveComponent: HeadlineToEffectiveComponent;
  @ViewChild('valueCalculation') valueCalculationComponent: ValueCalculationComponent;

  consideration: AssetClassTenure;
  processType = ConsiderationConversionProcess;
  form: UntypedFormGroup;
  value$: BehaviorSubject<number> = new BehaviorSubject(0);
  calculationType: CalculationType;

  total_consideration: number;
  hasFormErrors = false
  onCloseErrors() {
    this.hasFormErrors = false
  }

  result: IConsiderationModalReturnData = {
    value: undefined,
    hteProcessData: {
      methods: {
        main: undefined,
        sub: undefined,
      },
      tvomRatesAndJustifications: {
        capRate: undefined,
        capRateJustification: null,
        targetRate: undefined,
        targetRateJustification: null,
      },
      cfmRatesAndJustifications: {
        targetRate: undefined,
        targetRateJustification: null,
        growthRate: undefined,
        growthRateJustification: null,
        effectiveRentJustification: null,
      },
      selected: {
        main: undefined,
        index: undefined,
      }
    },
    holdConversionProcessData: {
      ary: undefined
    }
  };

  constructor(
    public dialogRef: MatDialogRef<ConsiderationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConsiderationModalInputData,
  ) { 
    this.consideration = Object.assign({}, data.consideration) as AssetClassTenure;
    this.consideration.inclusive_property_management = '' + this.consideration.inclusive_property_management;
    this.consideration.consideration_inclusive_cost = '' + this.consideration.consideration_inclusive_cost;
    this.consideration.consideration_full_repair_cost = '' + this.consideration.consideration_full_repair_cost;
  }

  ngOnInit(): void {
    const isSameCurrency = this.consideration.currency === this.data.tpCurrency;
    const conv = this.consideration.conversion.find(c => c.quote_currency === this.data.tpCurrency);
    this.total_consideration = isSameCurrency
      ? this.consideration.total_consideration
      : conv 
        ? this.consideration.total_consideration * conv.exchange_rate
        : undefined;

    this.calculationType = this.data.process === ConsiderationConversionProcess.FreeholdToLeasehold 
      ? 2 
      : this.data.process === ConsiderationConversionProcess.HeadlineToEffectiveToLeaseholdToFreehold 
        ? 3
        : 1;
    if (this.data.process === ConsiderationConversionProcess.HeadlineToEffectiveToLeaseholdToFreehold) {
      this.value$.next(undefined);
    } else {
      this.value$.next(this.total_consideration);
    }
  }

  ngOnDestroy() {}

  valueCalculationResult(res: ChangeBetweenLeaseAndFreeEventData) {
    this.result.value = res.calculatedValue;
    this.result.holdConversionProcessData = {ary: res.ary};
  }

  headlineToEffectiveResult(res: HeadlineToEffectiveValueCalculatedEventData) {
    if (this.data.process === ConsiderationConversionProcess.HeadlineToEffectiveToLeaseholdToFreehold) {
      this.value$.next(res.result.calculatedValue);
    } else {
      this.result.value = res.result.calculatedValue;
    }
    this.result.hteProcessData.methods = res.methods;
  }

  onSave() {
    let isInValid = false;

    if (this.valueCalculationComponent && this.valueCalculationComponent.isInValid()) {
      isInValid = true;;
    }

    if (this.headlineToEffectiveComponent && this.headlineToEffectiveComponent.isInValid()) {
      isInValid = true;
    }

    if (isInValid) {
      this.hasFormErrors =true
      return;
    }
    
    // Get Justifications
    if (this.headlineToEffectiveComponent) {
      const justifications = this.headlineToEffectiveComponent.getJustifications();
      if (isTVOMJustification(justifications)) {
        this.result.hteProcessData.tvomRatesAndJustifications.capRate = justifications.capRate;
        this.result.hteProcessData.tvomRatesAndJustifications.capRateJustification = justifications.capRateJustification;
        this.result.hteProcessData.tvomRatesAndJustifications.targetRate = justifications.targetRate;
        this.result.hteProcessData.tvomRatesAndJustifications.targetRateJustification = justifications.targetRateJustification;
      } else {
        this.result.hteProcessData.cfmRatesAndJustifications.targetRate = justifications.targetRate;
        this.result.hteProcessData.cfmRatesAndJustifications.targetRateJustification = justifications.targetRateJustification;
        this.result.hteProcessData.cfmRatesAndJustifications.growthRate = justifications.growthRate;
        this.result.hteProcessData.cfmRatesAndJustifications.growthRateJustification = justifications.growthRateJustification;
        this.result.hteProcessData.cfmRatesAndJustifications.effectiveRentJustification = justifications.effectiveRentJustification;
        this.result.hteProcessData.selected.main = justifications.main;
        this.result.hteProcessData.selected.index = justifications.index;
      }
    }
    this.dialogRef.close(this.result);
  }

  onClose() {
    this.dialogRef.close();
  }
}

function isTVOMJustification(raj: TVOMRatesAndJustification | CFMReturnType): raj is TVOMRatesAndJustification {
  return (raj as TVOMRatesAndJustification).capRate !== undefined;
}
