import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { each } from 'lodash';
import { Observable, BehaviorSubject, Subject, Subscription, of, combineLatest } from 'rxjs';
import { startWith, takeUntil, map, take, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CountryModel, CityModel, AllCountriesRequested, AllCitiesRequested, selectAllCountries } from 'src/app/core/admin';
import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel, AssetClassConsiderationModel } from 'src/app/core/asset_class';
import { AssetClassSizeModel, AcSource, AssetClassTenure } from 'src/app/core/comparable';
import { AssetClassRetailBuildingOnServerUpdated, AssetClassRetailBuildingOnServerCreated, UpdatePreviouslyCreatedACRetailBuildingId } from 'src/app/core/comparable/_actions/asset-class-retail-building.actions';
import { AssetClassRetailBuildingModel } from 'src/app/core/comparable/_models/asset-class-retail-building.model';
import { selectAssetClassRetailBuildingActionLoading, selectAssetClassRetailBuildingById, selectLastCreatedAssetClassRetailBuildingId } from 'src/app/core/comparable/_selectors/asset-class-retail-building.selectors';
import { Grade, CoordinateReferenceSystem, StateRepair, HandoverStandard, FoundationType, AllGradesRequested, selectAllGrades, AllCoordinateReferenceSystemsRequested, selectAllCoordinateReferenceSystems, AllStateRepairsRequested, selectAllStateRepairs, AllHandoverStandardsRequested, selectAllHandoverStandards, AllFoundationTypesRequested, selectAllFoundationTypes, selectAllUnitAreaMeasurements, AllUnitAreaMeasurementsRequested } from 'src/app/core/linked-tables';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { selectLastCreatedRetailBuildingAdjustmentId } from 'src/app/core/linked-tables/_selectors/retail-building-adjustment.selectors';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, TypesUtilsService, MessageType } from 'src/app/core/_base/crud';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { AcGroundsComponent } from '../../../shared_components/ac-grounds/ac-grounds.component';
import { AccommodationLayoutTableComponent } from '../../../shared_components/accommodation-layout-table/accommodation-layout-table.component';
import { ExternalAspectsComponent } from '../../../shared_components/external-aspects/external-aspects.component';
import { InternalAspectsComponent } from '../../../shared_components/internal-aspects/internal-aspects.component';
import { LandAreaListComponent } from '../../../shared_components/land-parcel/land-area/land-area-list/land-area-list.component';
import { LandParcelMapComponent } from '../../../shared_components/land-parcel/land-parcel-map/land-parcel-map.component';
import { MapLocationComponent } from '../../../shared_components/map-location/map-location.component';
import { ServicesAndInfrastructuresComponent } from '../../../shared_components/services-and-infrastructures/services-and-infrastructures.component';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { UploadFileComponent } from '../../../shared_components/upload-file/upload-file.component';
import { ConsiderationAndSourceComponent } from '../../_sub/consideration-and-source/consideration-and-source.component';
import { ValuationCheckerModalComponent } from '../../_sub/valuation-checker-modal/valuation-checker-modal.component';
import { environment } from 'src/environments/environment';
import {v4 as uuidv4} from 'uuid'
import { AssetClassSize } from 'src/app/core/v2/types';

@Component({
  selector: 'kt-retail-building-modal',
  templateUrl: './retail-building-modal.component.html',
  styleUrls: ['./retail-building-modal.component.scss', '../../complex-btn.scss']
})
export class RetailBuildingModalComponent implements OnInit {
  @ViewChild('search', {static: true})
  public searchElementRef: ElementRef;

  @ViewChild(MapLocationComponent, {static: false}) 
  mapLocationComponent: MapLocationComponent;

  @ViewChild(LandParcelMapComponent, {static: false}) 
  landParcelMapComponent: LandParcelMapComponent;

  @ViewChild(LandAreaListComponent, {static: false})
  landAreaListComponent: LandAreaListComponent;

  @ViewChild(AccommodationLayoutTableComponent, {static: false})
  accommodationLayoutTable: AccommodationLayoutTableComponent;

  @ViewChild(ServicesAndInfrastructuresComponent, {static: true})
  servicesAndInfraComponent: ServicesAndInfrastructuresComponent;

  @ViewChild(ExternalAspectsComponent, {static: false})
  externalAspectComponent: ExternalAspectsComponent;

  @ViewChild(InternalAspectsComponent, {static: false})
  internalAspectComponent: InternalAspectsComponent;

  @ViewChild(AcGroundsComponent, {static: true})
  acGroundsComponent: AcGroundsComponent;

  @ViewChild(AcDocumentUploadComponent, {static: false})
  acDocumentUploadComponent: AcDocumentUploadComponent;


  @ViewChild('simple')
  public uploadFileComponent: UploadFileComponent;

  @ViewChild(ConsiderationAndSourceComponent, {static: false})
  considerationAndSourceComponent: ConsiderationAndSourceComponent;

  loading$: Observable<boolean>;

  acRetailBuilding: AssetClassRetailBuildingModel;

  centerLat = null;
  centerLng = null;
  points: {lat: number, lng: number}[] = [];

  formGroup: UntypedFormGroup;
  hasFormErrors: boolean = false;
  formErrorMsg$: BehaviorSubject<string> = new BehaviorSubject(null);
  formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
  isComplexForm: boolean = false;

  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  grades: Grade[] = [];
  coordinateReferenceSystems: CoordinateReferenceSystem[] = [];
  landAreaComponentListSubject = new BehaviorSubject<AssetClassLandAreaComponentModel[]>([]);
  landAreaSubject = new BehaviorSubject<AssetClassLandAreaModel>(new AssetClassLandAreaModel());
  subTypeCategories: SubTypeCategoryModel[] = [];
  subCategories: SubCategoryModel[] = [];
  filteredSubCategories: SubCategoryModel[] = [];
  stateOfRepairs: StateRepair[] = [];
  handoverStandards: HandoverStandard[] = [];
  foundationTypes: FoundationType[] = [];
  aboveFloor$: BehaviorSubject<number> = new BehaviorSubject(0);
  belowFloor$: BehaviorSubject<number> = new BehaviorSubject(0);
  floors$: Observable<number[]>; 

  tenuresSubject = new BehaviorSubject<AssetClassTenure[]>([])
  sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
  sizes$ = combineLatest([ 
    this.store.select(selectAllUnitAreaMeasurements),
    this.sizesSubject.asObservable()
  ]).pipe(
    map(([unitAreaMeasurements, sizes]) => {
      const items: AssetClassSize[] = sizes.map(size => ({
        id: size.id,
        uid: size.uid,
        size: size.size,
        standardMeasurementName: size.standard_measurement_name,
        unitAreaMeasurementAcronym: unitAreaMeasurements.find(item => item.id === size.unit_of_area_measurement_id)?.acronym
      }))
      return items
    })
  )
  sourceSubject = new BehaviorSubject<AcSource>(new AcSource());
  considerationListSubject = new BehaviorSubject<AssetClassConsiderationModel[]>([]);

  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  protected _onDestroy = new Subject<void>();
  private subscriptions: Subscription[] = [];

  private _hasParcelInfo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasParcelInfo$: Observable<boolean> = this._hasParcelInfo$.asObservable();
  public considerationError$ = new BehaviorSubject(false);
  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  tabHeaders: TabHeader[] = [
    {label: 'Location & land identification', disabled: of(false)},
    {label: 'Property information', disabled: of(false)},
    {label: 'Lease & sale information', disabled: of(false)},
    {label: 'Pictures & documents', disabled: of(false)},
  ];
  location_tab_items = ['land_parcel_name','coordinate_reference_system_id'];
  error:any = {
    msg: ' Missing Fields in total:',
    fields: []
  }
  markerInfo: {lat: number, lng: number, isShow: boolean} = {lat: undefined, lng: undefined, isShow: false};
  onMarkerChange(event) {
    if (event == null)  {
      return;
    }
    this.markerInfo = {
      lat: event.lat,
      lng: event.lng,
      isShow: event.isShown
    }
  }
  private valuationId: number | null = null

  constructor(
    private activatedRoute: ActivatedRoute,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<RetailBuildingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.valuationId = data.valuationId;
  }

  ngOnInit(): void {
    this.floors$ = combineLatest([
      this.aboveFloor$,
      this.belowFloor$
    ]).pipe(
      map(([above, below]) => {
        const l = -1 * (below ?? 0);
        const h = above ?? 0;
        const numbers: number[] = [];
        for (let i = l; i <= h; i++) {
          numbers.push(i);
        }
        return numbers;
      })
    )
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      const id = params.retailbuilding_id;
      if (id && id > 0) {
        this.activatedRoute.data.subscribe(data => {
          const res = data.data;
          this.acRetailBuilding = res.data;
          this.isComplexForm = this.acRetailBuilding.formType == 1;
          this.points = this.acRetailBuilding.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}));
          this.landAreaComponentListSubject.next(this.acRetailBuilding.landAreaComponents);
          this.landAreaSubject.next(this.acRetailBuilding.landArea)
          const deepCopiedSizes = _deepCopy(this.acRetailBuilding.sizes)
            .map(size => {
              size.uid = uuidv4()
              return size
            })
          const deepCopiedTenures = _deepCopy(this.acRetailBuilding.tenures)
            .map(tenure => {
              const rentSizeUuid = deepCopiedSizes.find(size => size.id === tenure.rent_size_id)?.uid
              const expensesSizeUuid = deepCopiedSizes.find(size => size.id === tenure.expenses_size_id)?.uid
              tenure.rent_size_uid = rentSizeUuid
              tenure.expenses_size_uid = expensesSizeUuid
              return tenure
            })
          this.tenuresSubject.next(deepCopiedTenures)
          this.sizesSubject.next(deepCopiedSizes);
          this.sourceSubject.next(this.acRetailBuilding.source);
          this.markerInfo = {
            lat: this.acRetailBuilding.locationData.latitude,
            lng: this.acRetailBuilding.locationData.longitude,
            isShow: true
          }
          this._createForm();
        })
      } else {
        this.acRetailBuilding = new AssetClassRetailBuildingModel();
        this.acRetailBuilding.clear();
        this.points = this.acRetailBuilding.points;
        this.landAreaComponentListSubject.next(this.acRetailBuilding.landAreaComponents);
        this.landAreaSubject.next(this.acRetailBuilding.landArea);
        this.sizesSubject.next(this.acRetailBuilding.sizes);
        this.sourceSubject.next(this.acRetailBuilding.source);
        this._createForm();
      }
    })
    this.subscriptions.push(routeSubscription);

    this.loading$ = this.store.pipe(select(selectAssetClassRetailBuildingActionLoading));

    // Linked Tables
    this.store.dispatch(new AllUnitAreaMeasurementsRequested())
    this.store.dispatch(new AllCountriesRequested());
    this.store.dispatch(new AllCitiesRequested());
    const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
        this.allCountries = [];
        each(res, (_country: CountryModel) => {
            this.allCountries.push(_country);
        });
    });
    this.subscriptions.push(countrySubscription);

    this.store.dispatch(new AllGradesRequested());
    const gradeSubscription = this.store.pipe(
        select(selectAllGrades))
        .subscribe(res => {
            this.grades = [];
            if (res) {
                this.grades = res;
            }
        });
    this.subscriptions.push(gradeSubscription);

    this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
    const coordinateReferenceSystemsSubscription = this.store.pipe(
        select(selectAllCoordinateReferenceSystems),
    ).subscribe(res => {
        this.coordinateReferenceSystems = [];
        if (res) {
            this.coordinateReferenceSystems = res;
        }
    });
    this.subscriptions.push(coordinateReferenceSystemsSubscription);

    this.store.dispatch(new AllSubTypeCategorysRequested());
    const subTypeSub = this.store.pipe(
      select(selectAllSubTypeCategorys)
    ).subscribe(res => {
      this.subTypeCategories = res ? res.filter(item => item.property_sub_type_id == 11) : [];
    });
    this.subscriptions.push(subTypeSub);

    this.store.dispatch(new AllSubCategorysRequested());
    const subCatSub = this.store.select(selectAllSubCategorys)
      .subscribe(res => {
        this.subCategories = res ? res : [];
        this.filteredSubCategories = this._filterSubCategories(this.acRetailBuilding.sub_type_category_id);
      });
    this.subscriptions.push(subCatSub);

    this.store.dispatch(new AllStateRepairsRequested())
    const stateRepairSub = this.store.select(selectAllStateRepairs)
      .subscribe(res => {
        this.stateOfRepairs = res ? res : [];
      });
    this.subscriptions.push(stateRepairSub);

    this.store.dispatch(new AllHandoverStandardsRequested())
    const handoverSub = this.store.select(selectAllHandoverStandards)
      .subscribe(res => {
        this.handoverStandards = res ? res : []
      });
    this.subscriptions.push(handoverSub);

    this.store.dispatch(new AllFoundationTypesRequested())
    const foundationSub = this.store.select(selectAllFoundationTypes)
      .subscribe(res => {
        this.foundationTypes = res ? res : []
      });
    this.subscriptions.push(foundationSub);
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Actions 
   */
  cancel() {
    this.dialogRef.close();
  }
  onSubmit(isComplete: boolean) {
    let errorFields = [];
    this.hasFormErrors = false;
    this.showTitleRequiredError$.next(false);
    this.considerationError$.next(false);
    if (isComplete == false || this.isComplexForm == false) {
      this._changeValidation(isComplete);
    }

    if (this.mapLocationComponent) {
      if (isComplete && this.mapLocationComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
      }
    }
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid && controls.add_info_on_land.value) {
      Object.keys(controls).filter(cName => this.location_tab_items.includes(cName)).forEach(cName => {
        if (!controls[cName].valid) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, cName];
          this.formErrorMsg$.next(`${cName} has error`);
        }
        controls[cName].markAsTouched();
      });
    }

    if (this.formGroup.invalid) {
      Object.keys(controls).filter(cName => !this.location_tab_items.includes(cName)).forEach(cName => {
        if (!controls[cName].valid) {
          errorFields = [...errorFields, cName];
          this.formErrorMsg$.next(`${cName} has error`);
        }
        controls[cName].markAsTouched();
      });
      this.hasFormErrors = true;
    }
    if (this.externalAspectComponent) {
      if (isComplete && this.externalAspectComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.externalAspectComponent.errorFields];
      }
    }
    if (this.internalAspectComponent) {
      if (isComplete && this.internalAspectComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.internalAspectComponent.errorFields];
      }
    }
    if (isComplete && this.acGroundsComponent.validate()) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, ...this.acGroundsComponent.errorFields];
      if (this.acGroundsComponent.errorFields.length == 0) {
        errorFields = [...errorFields, 'grounds'];
      }
    }
    if (isComplete && this.sizesSubject.value.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'size'];
    }

    // Consideration
    if (isComplete && !this.considerationAndSourceComponent.validate()) {
      this.hasFormErrors = true;
      this.considerationError$.next(true);
      errorFields = [...errorFields, 'consideration'];
    }

    if (isComplete && this.uploadFileComponent.uploadFiles.length == 0) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, 'pic'];
    } else {
        const uploadedFiles = this.uploadFileComponent.uploadFiles;
        uploadedFiles.forEach(file => {
          if (file.title === null || file.title === '') {
            this.hasFormErrors = true;
            this.showTitleRequiredError$.next(true);
            errorFields = [...errorFields, 'pic'];
          }
        })
    }
    if (this.hasFormErrors) {
      this.error.fields = errorFields;
      return;
    }

    this.landAreaListComponent && this.landAreaListComponent.updateValue();
    const _ac = this._preparteAssetClass(isComplete);
    const dialogRef = this.dialog.open(ValuationCheckerModalComponent, {
      width: '400px',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == undefined) {
        return;
      }
      _ac.valuation_id = res ? this.valuationId : null;
      if (_ac.id) {
        this._updateAssetClass(_ac);
      } else {
        this._createAssetClass(_ac);
      }
    })
  }
  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormErrors = obj.hasFormErrors;
  }

  /**
   * Template functions
   */
  getComponentTitle(): string {
    if (this.acRetailBuilding && this.acRetailBuilding.id) {
      return `Edit Retail Building "${this.acRetailBuilding.ref_num}"`
    }
    return 'Add Retail Building'
  }

  /**
   * Methods
   */
  private _changeValidation(isComplete: boolean) {
    const controls = this.formGroup.controls;
    const mustCnames = ['country_id', 'city_id', 'location_grade_id', 'address', 'sub_type_category_id', 
      'sub_category_id', 'property_grade_id', 'completion_year', 'state_of_repair_id', 'handover_standard_id',
      'above_floors'];
    Object.keys(controls).forEach(cName => {
      if (isComplete && mustCnames.filter(item => item == cName).length == 1) {
        return;
      }
      controls[cName].clearValidators();
      controls[cName].updateValueAndValidity();
    });
    if (this.externalAspectComponent) {
      this.externalAspectComponent.changeValidation();
    }
    if (this.internalAspectComponent) {
      this.internalAspectComponent.changeValidation();
    }
  }
  private _preparteAssetClass(isComplete: boolean): AssetClassRetailBuildingModel {
    const _ac = new AssetClassRetailBuildingModel();
    _ac.clear();
    _ac.id = this.acRetailBuilding.id;
    _ac.status = isComplete ? 1 : 0;
    _ac.formType = this.formSelectionCtrl.value;

    const controls = this.formGroup.controls;

    if (this.mapLocationComponent) {
      _ac.locationData = this.mapLocationComponent.getData();
    }

    _ac.add_info_on_land = controls.add_info_on_land.value;
    _ac.land_parcel_name = controls.land_parcel_name.value;
    _ac.coordinate_reference_system_id = controls.coordinate_reference_system_id.value;
    _ac.points = this.landParcelMapComponent ? this.landParcelMapComponent.getPath() : [];
    _ac.landArea = this.landAreaSubject.value;
    _ac.landAreaComponents = this.landAreaComponentListSubject.value;

    _ac.sub_type_category_id = controls.sub_type_category_id.value;
    _ac.sub_category_id = controls.sub_category_id.value;
    _ac.property_grade_id = controls.property_grade_id.value;
    _ac.general_desc = controls.general_desc.value;
    _ac.completion_year = controls.completion_year.value;
    _ac.apprx_year_extended = controls.apprx_year_extended.value;
    _ac.state_of_repair_id = controls.state_of_repair_id.value;
    _ac.handover_standard_id = controls.handover_standard_id.value;
    _ac.foundation_type_id = controls.foundation_type_id.value;
    _ac.above_floors = controls.above_floors.value;
    _ac.below_floors = controls.below_floors.value;
    _ac.energy_efficiency_grade_id = controls.energy_efficiency_grade_id.value;
    _ac.construction_desc = controls.construction_desc.value;
    _ac.has_parcel_consideration = controls.has_parcel_consideration.value;
    _ac.land_comment = controls.land_comment.value;
    _ac.accommodation = this.accommodationLayoutTable ? {...this.accommodationLayoutTable.getData(), id: this.acRetailBuilding.accommodation.id} : {id: this.acRetailBuilding.accommodation.id, data: null, others: []};

    _ac.serviceAndInfraData = this.servicesAndInfraComponent.getdata();

    if (this.externalAspectComponent) {
      _ac.externalAspectData = this.externalAspectComponent.getData();
    }

    if (this.internalAspectComponent) {
      _ac.internalAspectData = this.internalAspectComponent.getData();
    }

    _ac.grounds = {...this.acGroundsComponent.getData(), id: this.acRetailBuilding.grounds.id};

    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
        if (value) {
            _ac.picture = value.success;
        }
    })
    _ac.sizes = this.sizesSubject.value;
    _ac.source = this.sourceSubject.value;
    _ac.tenures = this.considerationAndSourceComponent ? this.considerationAndSourceComponent.getData() : [];
    if (this.acDocumentUploadComponent) {
      _ac.documents = this.acDocumentUploadComponent.getDocuments();
    }
    return _ac;

  }
  private _createForm() {
    this.formSelectionCtrl.setValue(this.acRetailBuilding.formType);
    this.formSelectionCtrl.updateValueAndValidity();
    const formSelectionSubscription = this.formSelectionCtrl.valueChanges.subscribe(val => {
      if (val == 1) {
        this.isComplexForm = true;
      } else {
        this.isComplexForm = false;
      }
    });
    this.subscriptions.push(formSelectionSubscription);

    this.formGroup = this.formBuilder.group({
      add_info_on_land: [this.acRetailBuilding.add_info_on_land],
      land_parcel_name: [this.acRetailBuilding.land_parcel_name],
      coordinate_reference_system_id: [this.acRetailBuilding.coordinate_reference_system_id],

      sub_type_category_id: [this.acRetailBuilding.sub_type_category_id, Validators.required],
      sub_category_id: [this.acRetailBuilding.sub_category_id, Validators.required],
      property_grade_id: [this.acRetailBuilding.property_grade_id, Validators.required],
      general_desc: [this.acRetailBuilding.general_desc],
      completion_year: [this.acRetailBuilding.completion_year, Validators.required],
      apprx_year_extended: [this.acRetailBuilding.apprx_year_extended],
      state_of_repair_id: [this.acRetailBuilding.state_of_repair_id, Validators.required],
      handover_standard_id: [this.acRetailBuilding.handover_standard_id, Validators.required],
      foundation_type_id: [this.acRetailBuilding.foundation_type_id],
      above_floors: [this.acRetailBuilding.above_floors, Validators.required],
      below_floors: [this.acRetailBuilding.below_floors],
      energy_efficiency_grade_id: [this.acRetailBuilding.energy_efficiency_grade_id],
      construction_desc: [this.acRetailBuilding.construction_desc],

      has_parcel_consideration: [this.acRetailBuilding.has_parcel_consideration],
      land_comment: [this.acRetailBuilding.land_comment]
    });

    this.formGroup.controls.add_info_on_land.valueChanges.pipe(
      startWith(this.formGroup.controls.add_info_on_land.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      this._hasParcelInfo$.next(val);
      if (val) {
        this.formGroup.controls.land_parcel_name.setValidators([Validators.required]);
        this.formGroup.controls.coordinate_reference_system_id.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.land_parcel_name.clearValidators();
        this.formGroup.controls.coordinate_reference_system_id.clearValidators();
      }
      this.formGroup.controls.land_parcel_name.updateValueAndValidity();
      this.formGroup.controls.coordinate_reference_system_id.updateValueAndValidity();
    });

    this.formGroup.controls.above_floors.valueChanges.pipe(
      startWith(this.formGroup.controls.above_floors.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => this.aboveFloor$.next(val));

    this.formGroup.controls.below_floors.valueChanges.pipe(
      startWith(this.formGroup.controls.below_floors.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => this.belowFloor$.next(val));

    this.formGroup.controls.sub_type_category_id.valueChanges.pipe(
      startWith(this.formGroup.controls.sub_type_category_id.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      this.filteredSubCategories = this._filterSubCategories(val)

      if (this.filteredSubCategories.length == 0) {
        this.formGroup.controls.sub_category_id.clearValidators();
      } else {
        this.formGroup.controls.sub_category_id.setValidators([Validators.required]);
      }
      this.formGroup.controls.sub_category_id.updateValueAndValidity();
    })
  }
  private _filterSubCategories(stcId: number) {
    if (stcId == null) {
      return [];
    }
    return this.subCategories.filter(item => item.sub_type_category_id == stcId);
  }
  private _updateAssetClass(ac: AssetClassRetailBuildingModel) {
    this.store.dispatch(new AssetClassRetailBuildingOnServerUpdated({
        AssetClassRetailBuilding: ac,
        fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.dialogRef.close(true)
  }
  private _createAssetClass(ac: AssetClassRetailBuildingModel) {
    this.store.dispatch(new AssetClassRetailBuildingOnServerCreated({
        AssetClassRetailBuilding: ac,
        fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.store.pipe(
      select(selectLastCreatedAssetClassRetailBuildingId),
      takeUntil(this._onDestroy),
      distinctUntilChanged(),
      switchMap(res => {
        if (!res) {
          return of(undefined)
        }
        this.store.dispatch(new UpdatePreviouslyCreatedACRetailBuildingId())
        return this.store.select(selectAssetClassRetailBuildingById(res))
      })
    ).subscribe(ac => {
      if (!ac) {
        return;
      }
      this.dialogRef.close(ac)
    })
  }
  onTabChanged($event) {
    const activeIndex = $event.index;
  }
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  changeTab(section: string) {
    const section_tab = [
      {sections: ['location', 'parcel'], tab_index: 0},
      {sections: ['prop'], tab_index: 1},
      {sections: ['consideration'], tab_index: 2},
      {sections: ['pic'], tab_index: 3},
    ]
    const active_Tab = section_tab.find(item => item.sections.includes(section));
    this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
    this.selectedtabchange.next(this.selectedTab);
  }
  erroTabMap() {
    return this.typesUtilsService.getCompTabErrorMap(this.isComplexForm, this.error.fields, this.tabHeaders, 11);
  }
}

function _deepCopy(arr: any[]): any[] {
  return arr.map(item => ({...item}));
}
