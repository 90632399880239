// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {QosDegradation} from '../_models/qos-degradation.model';

export enum QosDegradationActionTypes {

    AllQosDegradationsRequested = '[QosDegradations Home Page] All QosDegradations Requested',
    AllQosDegradationsLoaded = '[QosDegradations API] All QosDegradations Loaded',

    QosDegradationOnServerCreated = '[Edit QosDegradation Dialog] QosDegradation On Server Created',
    QosDegradationCreated = '[Edit QosDegradations Dialog] QosDegradations Created',
    QosDegradationUpdated = '[Edit QosDegradation Dialog] QosDegradation Updated',
    QosDegradationDeleted = '[QosDegradations List Page] QosDegradation Deleted',

    QosDegradationsPageRequested = '[QosDegradations List Page] QosDegradations Page Requested',
    QosDegradationsPageLoaded = '[QosDegradations API] QosDegradations Page Loaded',
    QosDegradationsPageCancelled = '[QosDegradations API] QosDegradations Page Cancelled',

    QosDegradationsPageToggleLoading = '[QosDegradations page] QosDegradations Page Toggle Loading',
    QosDegradationsActionToggleLoading = '[QosDegradations] QosDegradations Action Toggle Loading',

    // trash system

    QosDegradationOnServerRestored = '[QosDegradation Trash] QosDegradation On Server Restored',
    QosDegradationRestored = '[QosDegradation Trash] QosDegradation Restored',

    QosDegradationOnServerAdminRestored = '[QosDegradation Admin Trash] QosDegradation On Server Restored',
    QosDegradationAdminRestored = '[QosDegradation Admin Trash] QosDegradation Restored',

    QosDegradationDeletedFromTrash = '[QosDegradation Trash] QosDegradation deleted',
    QosDegradationDeletedFromAdminTrash = '[QosDegradation Admin Trash] QosDegradation deleted',

    QosDegradationTrash = 'QosDegradation Trashed',
    QosDegradationTrashFlushed = 'QosDegradation Trash Flushed',
}

export class QosDegradationOnServerCreated implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationOnServerCreated;

    constructor(public payload: { qosDegradation: QosDegradation}) {
    }
}

export class QosDegradationCreated implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationCreated;

    constructor(public payload: { qosDegradation: QosDegradation }) {
    }
}

export class QosDegradationUpdated implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationUpdated;

    constructor(public payload: {
        partialQosDegradation: Update<QosDegradation>,
        qosDegradation: QosDegradation
    }) {
    }
}

export class QosDegradationDeleted implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class QosDegradationsPageRequested implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class QosDegradationsPageLoaded implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationsPageLoaded;

    constructor(public payload: { qosDegradations: QosDegradation[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class QosDegradationsPageCancelled implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationsPageCancelled;
}

export class AllQosDegradationsRequested implements Action {
    readonly type = QosDegradationActionTypes.AllQosDegradationsRequested;
}

export class AllQosDegradationsLoaded implements Action {
    readonly type = QosDegradationActionTypes.AllQosDegradationsLoaded;

    constructor(public payload: { qosDegradations: QosDegradation[] }) {
    }
}

export class QosDegradationsPageToggleLoading implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class QosDegradationsActionToggleLoading implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class QosDegradationDeletedFromAdminTrash implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class QosDegradationDeletedFromTrash implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class QosDegradationOnServerRestored implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class QosDegradationRestored implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationRestored;

    constructor(public payload: { item: QosDegradation }) {
    }
}

export class QosDegradationOnServerAdminRestored implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class QosDegradationAdminRestored implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationAdminRestored;

    constructor(public payload: { item: QosDegradation }) {
    }
}

export class QosDegradationTrashFlushed implements Action {
    readonly type = QosDegradationActionTypes.QosDegradationTrashFlushed;

    constructor() {
    }
}

export type QosDegradationActions = QosDegradationCreated
    | QosDegradationUpdated
    | QosDegradationDeleted
    | QosDegradationsPageRequested
    | QosDegradationsPageLoaded
    | QosDegradationsPageCancelled
    | AllQosDegradationsLoaded
    | AllQosDegradationsRequested
    | QosDegradationOnServerCreated
    | QosDegradationsPageToggleLoading
    | QosDegradationsActionToggleLoading
    | QosDegradationDeletedFromAdminTrash
    | QosDegradationDeletedFromTrash
    | QosDegradationOnServerRestored
    | QosDegradationRestored
    | QosDegradationOnServerAdminRestored
    | QosDegradationAdminRestored
    | QosDegradationTrashFlushed;
