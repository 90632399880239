import {RainwaterPipeModel} from '../_models/rainwater-pipe.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {RainwaterPipesState} from '../_reducers/rainwater-pipe.reducer';
import * as fromRainwaterPipe from '../_reducers/rainwater-pipe.reducer';
import {each} from 'lodash';

export const selectRainwaterPipesState = createFeatureSelector<RainwaterPipesState>('rainwater-pipes');

export const selectRainwaterPipeById = (id: number) => createSelector(
    selectRainwaterPipesState,
    state => state.entities[id]
);

export const selectAllRainwaterPipes = createSelector(
    selectRainwaterPipesState,
    fromRainwaterPipe.selectAll
);

export const selectAllRainwaterPipesIds = createSelector(
    selectRainwaterPipesState,
    fromRainwaterPipe.selectIds
);

export const allRainwaterPipesLoaded = createSelector(
    selectRainwaterPipesState,
    state => state.isAllRainwaterPipesLoaded
);


export const selectRainwaterPipesPageLoading = createSelector(
    selectRainwaterPipesState,
    state => state.listLoading
);

export const selectRainwaterPipesActionLoading = createSelector(
    selectRainwaterPipesState,
    state => state.actionLoading
);

export const selectLastCreatedRainwaterPipeId = createSelector(
    selectRainwaterPipesState,
    state => state.lastCreatedRainwaterPipeId
);

export const selectRainwaterPipesShowInitWaitingMessage = createSelector(
    selectRainwaterPipesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedRainwaterPipeCount = createSelector(
    selectRainwaterPipesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedRainwaterPipeCount = createSelector(
    selectRainwaterPipesState,
    (state) => state.totalAdminTrashed
);


export const selectRainwaterPipeQueryResult = createSelector(
    selectRainwaterPipesState,
    state => {
        const items: RainwaterPipeModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RainwaterPipeModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
