import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AssetClassModel } from 'src/app/core/asset_class';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { SRERAssumptionType, SRERStatus, ValuationSRERModel, getSRERAssumption, SRERAssumptionModel } from 'src/app/core/valuation/_models/valuation-srer.model';
import * as valuationSRERSelectors from 'src/app/core/valuation/_selectors/valuation-srer.selectors';
import * as valuationSRERActions from 'src/app/core/valuation/_actions/valuation-srer.actions';
import { MultiplePdfViewerComponent } from 'src/app/views/pages/shared_components/multiple-pdf-viewer/multiple-pdf-viewer.component';
import { EditSrerComponent } from '../_modals/edit-srer/edit-srer.component';
import { RestoreModalComponent } from '../_modals/restore-modal/restore-modal.component';
import { DeleteEntityWithDescrDialogComponent } from 'src/app/views/partials/content/crud';

@Component({
  selector: 'kt-source-required-external-ref-table',
  templateUrl: './source-required-external-ref-table.component.html',
  styleUrls: ['./source-required-external-ref-table.component.scss']
})
export class SourceRequiredExternalRefTableComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() toe: ToeModel;
  @Input() targetProperty: AssetClassModel;

  dataSource: MatTableDataSource<ValuationSRERModel> = new MatTableDataSource<ValuationSRERModel>();
  columns = ['external_ref', 'availability', 'is_provided', 'type', 'input', 'input_status', 'justification', 'actions'];

  tableData: ValuationSRERModel[] = [] //= createDummyData();
  assumptionType = SRERAssumptionType;
  srerStatus = SRERStatus;

  subscriptions: Subscription[] = [];

  constructor(private store$: Store<AppState>, private dialog: MatDialog) { }

  ngOnInit(): void {
    const srerSub = this.store$.select(valuationSRERSelectors.selectAll).subscribe(res => {
      if (res) {
        this.tableData = res;
      }
    });
    this.subscriptions.push(srerSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  edit(item: ValuationSRERModel, assumption: SRERAssumptionModel) {
    switch (assumption.type) {
      case SRERAssumptionType.Default: {
        const dialogRef = this.dialog.open(EditSrerComponent, {
          width: '50vw',
          data: {
            item: item.defaultAssumption,
            externalRef: item.externalRef,
          } 
        });
        const dialogSub = dialogRef.afterClosed().subscribe(res => {
          if (res) {
            const _new = Object.assign({}, item) as ValuationSRERModel;
            _new.defaultAssumption = res;
            this.store$.dispatch(new valuationSRERActions.UpdateData({data: _new}));
          }
        });
        this.subscriptions.push(dialogSub);
        return;
      }
      case SRERAssumptionType.Special: {
        const dialogRef = this.dialog.open(EditSrerComponent, {
          width: '50vw',
          data: {
            item: item.specialAssumption,
            externalRef: item.externalRef,
          } 
        });
        const dialogSub = dialogRef.afterClosed().subscribe(res => {
          if (res) {
            const _new = Object.assign({}, item) as ValuationSRERModel;
            _new.specialAssumption = res;
            this.store$.dispatch(new valuationSRERActions.UpdateData({data: _new}));
          }
        });
        this.subscriptions.push(dialogSub);
        return;
      }
    }
  }

  restore(item: ValuationSRERModel, assumption: SRERAssumptionModel) {
    const dialogRef = this.dialog.open(RestoreModalComponent, {
      width: '440px'
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
            this.store$.dispatch(new valuationSRERActions.RestoreData({
              data: item,
              tp: this.targetProperty,
              type: assumption.type
            }));
          }
        }
    );
    this.subscriptions.push(dialogSub);
  }
  
  remove(item: ValuationSRERModel, assumption: SRERAssumptionModel) {
    const _title = 'Are you sure?';
    const _waitingDescription = `Removing ${item.externalRef} - ${getSRERAssumption(assumption.type)}`;
    const _description = `Any changes previously made will be lost and the content will be restored as per ToE. Please provide your justification(s) for removing this ${item.externalRef} - ${getSRERAssumption(assumption.type)}`;
    const dialogRef = this.dialog.open(DeleteEntityWithDescrDialogComponent, {
      data: {title: _title, description: _description, waitDescription: _waitingDescription, 
        placeholder: 'Justification', error: 'Justification is'},
      width: '440px'
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {

            this.store$.dispatch(new valuationSRERActions.RemoveData({
              data: item,
              tp: this.targetProperty,
              type: assumption.type,
              justification: res
            }));
          }
        }
    )
    this.subscriptions.push(dialogSub);
  }

  seeDoc(item: ValuationSRERModel) {
    const files: {
      name: string;
      path: string;
      type: string;
    }[] = []; 
    item.tpFiles.forEach(file => files.push({name: file.name, path: file.path + '/' + file.name, type: this._getFileType(file.name)}));
    this.dialog.open(MultiplePdfViewerComponent, {
      data: files
    });
  }

  _getFileType(name: string): string {
    return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
  }
}
