// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ShopFrontTypeService} from '../_services/shop-front-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allShopFrontTypesLoaded} from '../_selectors/shop-front-type.selectors';
// Actions
import {
    AllShopFrontTypesLoaded,
    AllShopFrontTypesRequested,
    ShopFrontTypeActionTypes,
    ShopFrontTypesPageRequested,
    ShopFrontTypesPageLoaded,
    ShopFrontTypeUpdated,
    ShopFrontTypesPageToggleLoading,
    ShopFrontTypeDeleted,
    ShopFrontTypeOnServerCreated,
    ShopFrontTypeCreated,
    ShopFrontTypeActionToggleLoading, ShopFrontTypeTrashFlushed, ShopFrontTypeOnServerRestored, ShopFrontTypeRestored,
    ShopFrontTypeOnServerAdminRestored, ShopFrontTypeAdminRestored, ShopFrontTypeDeletedFromTrash, ShopFrontTypeDeletedFromAdminTrash
} from '../_actions/shop-front-type.actions';

@Injectable()
export class ShopFrontTypeEffects {
    showPageLoadingDispatcher = new ShopFrontTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ShopFrontTypeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ShopFrontTypeActionToggleLoading({isLoading: false});

    @Effect()
    loadAllShopFrontType$ = this.actions$
        .pipe(
            ofType<AllShopFrontTypesRequested>(ShopFrontTypeActionTypes.AllShopFrontTypesRequested),
            withLatestFrom(this.store.pipe(select(allShopFrontTypesLoaded))),
            filter(([action, isAllShopFrontTypeLoaded]) => !isAllShopFrontTypeLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllShopFrontTypesLoaded({items: res.data});
            })
        );

    @Effect()
    loadShopFrontTypePage$ = this.actions$
        .pipe(
            ofType<ShopFrontTypesPageRequested>(ShopFrontTypeActionTypes.ShopFrontTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ShopFrontTypesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeUpdated>(ShopFrontTypeActionTypes.ShopFrontTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeOnServerCreated>(ShopFrontTypeActionTypes.ShopFrontTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ShopFrontTypeCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushShopFrontTypeTrash$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeTrashFlushed>(ShopFrontTypeActionTypes.ShopFrontTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeOnServerRestored>(ShopFrontTypeActionTypes.ShopFrontTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ShopFrontTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeOnServerAdminRestored>(ShopFrontTypeActionTypes.ShopFrontTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ShopFrontTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeDeleted>(ShopFrontTypeActionTypes.ShopFrontTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeDeletedFromTrash>(ShopFrontTypeActionTypes.ShopFrontTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashShopFrontType$ = this.actions$
        .pipe(
            ofType<ShopFrontTypeDeletedFromAdminTrash>(ShopFrontTypeActionTypes.ShopFrontTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ShopFrontTypeService, private store: Store<AppState>) {
    }
}
