import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'kt-admin-management',
    templateUrl: './admin-management.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminManagementComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}