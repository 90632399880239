import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { ValuationReportModel } from "../_models/valuation-report.model";

export enum ValuationReportActionTypes {
	AllValuationReportsRequested = 'AllValuationReports Requested',
    AllValuationReportsLoaded = 'AllValuatioReports Loaded',

    ValuationReportOnServerCreated = 'ValuationReport On Server Created',
    ValuationReportOnServerDeleted = 'ValuationReport On Server Deleted',

    ValuationReportRequested = 'ValuationReport Requested',
    ValuationReportLoaded = 'ValuationReport Loaded',

    ValuationReportCreated = 'ValuationReport Created',
    ValuationReportUpdated = 'ValuationReport Updated',

    ValuationReportActionToggleLoading = 'ValuationReport Action Toggle Loading',

    ValuationReportStateResetted = '[Toe dashboard] Reset Valuation report state'
}

export class AllValuationReportsRequested implements Action {
    readonly type = ValuationReportActionTypes.AllValuationReportsRequested;
    constructor(public payload: {tpID: number}) {}
}
export class AllValuationReportsLoaded implements Action {
    readonly type = ValuationReportActionTypes.AllValuationReportsLoaded;
    constructor(public payload: {
        reports: ValuationReportModel[],
        tpId: number
    }) {}
}
export class ValuationReportRequested implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportRequested;
    constructor(public payload: {id: number}) {}
}
export class ValuationReportLoaded implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportLoaded;
    constructor(public payload: {report: ValuationReportModel}) {}
}
export class ValuationReportOnServerCreated implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportOnServerCreated;
    constructor(public payload: {report: ValuationReportModel}) {}
}
export class ValuationReportOnServerDeleted implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportOnServerDeleted;
    constructor(public payload: {id: number}) {}
}
export class ValuationReportCreated implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportCreated;
    constructor(public payload: {report: any}) {}
}
export class ValuationReportUpdated implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportUpdated;
    constructor(public payload: {
        partial: Update<ValuationReportModel>,
        report: ValuationReportModel
    }) {}
}
export class ValuationReportActionToggleLoading implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class ValuationReportStateResetted implements Action {
    readonly type = ValuationReportActionTypes.ValuationReportStateResetted;
    constructor() {}
}

export type ValuationReportActions = 
    AllValuationReportsRequested |
    AllValuationReportsLoaded |
    ValuationReportRequested | 
    ValuationReportLoaded |
    ValuationReportOnServerCreated |
    ValuationReportOnServerDeleted |
    ValuationReportCreated |
    ValuationReportUpdated |
    ValuationReportActionToggleLoading |
    ValuationReportStateResetted;