import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { uniq } from 'lodash';
import { Observable, BehaviorSubject, Subject, Subscription, of, combineLatest } from 'rxjs';
import { startWith, takeUntil, take, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AssetClassSizeModel, AcSource, AssetClassTenure } from 'src/app/core/comparable';
import { AssetClassRetailShopOnServerUpdated, AssetClassRetailShopOnServerCreated, UpdatePreviouslyCreatedACRetailShopId } from 'src/app/core/comparable/_actions/asset-class-retail-shop.actions';
import { AssetClassRetailShopModel } from 'src/app/core/comparable/_models/asset-class-retail-shop.model';
import { selectAssetClassRetailShopActionLoading, selectAssetClassRetailShopById, selectLastCreatedAssetClassRetailShopId } from 'src/app/core/comparable/_selectors/asset-class-retail-shop.selectors';
import { BuildingType, Grade, FoundationType, HandoverStandard, StateRepair, AllGradesRequested, selectAllGrades, AllStateRepairsRequested, selectAllStateRepairs, AllHandoverStandardsRequested, selectAllHandoverStandards, AllFoundationTypesRequested, selectAllFoundationTypes, AllBuildingTypesRequested, selectAllBuildingTypes, selectAllUnitAreaMeasurements, AllUnitAreaMeasurementsRequested } from 'src/app/core/linked-tables';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, TypesUtilsService, MessageType } from 'src/app/core/_base/crud';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { AcGroundsComponent } from '../../../shared_components/ac-grounds/ac-grounds.component';
import { AccommodationLayoutTableComponent } from '../../../shared_components/accommodation-layout-table/accommodation-layout-table.component';
import { BuildingFormComponent } from '../../../shared_components/building-form/building-form.component';
import { InternalAspectsComponent } from '../../../shared_components/internal-aspects/internal-aspects.component';
import { MapLocationComponent } from '../../../shared_components/map-location/map-location.component';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { UploadFileComponent } from '../../../shared_components/upload-file/upload-file.component';
import { BuildingInfoDialogComponent } from '../../_sub/building-info-dialog/building-info-dialog.component';
import { ConsiderationAndSourceComponent } from '../../_sub/consideration-and-source/consideration-and-source.component';
import { ValuationCheckerModalComponent } from '../../_sub/valuation-checker-modal/valuation-checker-modal.component';
import { environment } from 'src/environments/environment';
import {v4 as uuidv4} from 'uuid'
import { AssetClassSize } from 'src/app/core/v2/types';

@Component({
  selector: 'kt-retails-modal',
  templateUrl: './retails-modal.component.html',
  styleUrls: ['./retails-modal.component.scss', '../../complex-btn.scss']
})
export class RetailsModalComponent implements OnInit {
    @ViewChild(AccommodationLayoutTableComponent, { static: false })
    accommodationLayoutTable: AccommodationLayoutTableComponent;
    @ViewChild(InternalAspectsComponent, { static: false })
    internalAspectComponent: InternalAspectsComponent;
    @ViewChild(MapLocationComponent, { static: false })
    mapLocationComponent: MapLocationComponent;
    @ViewChild('simple')
    public uploadFileComponent: UploadFileComponent;
    @ViewChild('buildingPic')
    public buildingFileComponent: UploadFileComponent;
    @ViewChild(AcGroundsComponent, { static: false })
    acGroundsComponent: AcGroundsComponent;
    @ViewChild(AcDocumentUploadComponent, { static: false })
    acDocumentUploadComponent: AcDocumentUploadComponent;
    @ViewChild(ConsiderationAndSourceComponent, { static: false })
    considerationAndSourceComponent: ConsiderationAndSourceComponent;
    @ViewChild(BuildingFormComponent, {static: false})
    buildingFormComponent: BuildingFormComponent;

    acRetailShop: AssetClassRetailShopModel;

    loading$: Observable<boolean>;
    formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
    isComplexForm: boolean = false;
    formGroup: UntypedFormGroup;
    hasFormErrors: boolean = false;
    centerLat = null;
    centerLng = null;

    buildingTypes: BuildingType[] = [];
    grades: Grade[] = [];
    foundationTypes: FoundationType[] = [];
    subTypeCategories: SubTypeCategoryModel[] = [];
    subCategories: SubCategoryModel[] = [];
    filteredSubCategories: SubCategoryModel[] = [];
    handoverStandards: HandoverStandard[] = [];
    stateOfRepairs: StateRepair[] = [];

    aboveFloor$: BehaviorSubject<number> = new BehaviorSubject(null);
    belowFloor$: BehaviorSubject<number> = new BehaviorSubject(null);
    tenuresSubject = new BehaviorSubject<AssetClassTenure[]>([])
    sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
    sizes$ = combineLatest([ 
        this.store.select(selectAllUnitAreaMeasurements),
        this.sizesSubject.asObservable()
    ]).pipe(
        map(([unitAreaMeasurements, sizes]) => {
        const items: AssetClassSize[] = sizes.map(size => ({
            id: size.id,
            uid: size.uid,
            size: size.size,
            standardMeasurementName: size.standard_measurement_name,
            unitAreaMeasurementAcronym: unitAreaMeasurements.find(item => item.id === size.unit_of_area_measurement_id)?.acronym
        }))
        return items
        })
    )
    sourceSubject = new BehaviorSubject<AcSource>(new AcSource());

    showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _onDestroy: Subject<void> = new Subject();
    private _subscriptions: Subscription[] = [];
    private _floors$: BehaviorSubject<number[]> = new BehaviorSubject([]);
    public floors$: Observable<number[]> = this._floors$.asObservable();
    public comps = new BehaviorSubject([]);
    public hasParcel = of(false);
    public considerationError$ = new BehaviorSubject(false);
    selectedTab = 0;
    selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
    selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
    tabHeaders: TabHeader[] = [
        { label: 'Location & building', disabled: of(false) },
        { label: 'Property information', disabled: of(false) },
        { label: 'Lease & sale information', disabled: of(false) },
        { label: 'Pictures & documents', disabled: of(false) },
    ];
    location_tab_items = [
        'completion_year',
        'building_type',
        'building_grade_id',
    ];
    error: any = {
        msg: ' Missing Fields in total:',
        fields: []
    }
    buildingId: number = null;
    building_pictures: any[] = [];
    building_picture: string = null;
    private valuationId: number | null = null;

    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private typesUtilsService: TypesUtilsService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<RetailsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      this.valuationId = data.valuationId;
    }

    ngOnInit(): void {
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.retail_id;
            if (id && id > 0) {
                this.activatedRoute.data.subscribe(data => {
                    const res = data.data;
                    this.acRetailShop = res.data;
                    this.building_pictures = this.acRetailShop.b_pictures;
                    this.building_picture = this.acRetailShop.b_picture;
                    this.buildingId = this.acRetailShop.cp_building_id;
                    this.isComplexForm = this.acRetailShop.formType == 1;
                    this._floors$.next(this._getFloorsFrom(this.acRetailShop.floor_details));
                    const deepCopiedSizes = _deepCopy(this.acRetailShop.sizes)
                        .map(size => {
                        size.uid = uuidv4()
                        return size
                        })
                    const deepCopiedTenures = _deepCopy(this.acRetailShop.tenures)
                        .map(tenure => {
                        const rentSizeUuid = deepCopiedSizes.find(size => size.id === tenure.rent_size_id)?.uid
                        const expensesSizeUuid = deepCopiedSizes.find(size => size.id === tenure.expenses_size_id)?.uid
                        tenure.rent_size_uid = rentSizeUuid
                        tenure.expenses_size_uid = expensesSizeUuid
                        return tenure
                        })
                    this.tenuresSubject.next(deepCopiedTenures)
                    this.sizesSubject.next(deepCopiedSizes);
                    this.sourceSubject.next(this.acRetailShop.source);
                    this._createForm();
                })
            } else {
                this.acRetailShop = new AssetClassRetailShopModel();
                this.acRetailShop.clear();
                this.sizesSubject.next(_deepCopy(this.acRetailShop.sizes));
                this.sourceSubject.next(this.acRetailShop.source);
                this._createForm();
            }
        });
        this._subscriptions.push(routeSubscription);
        this.loading$ = this.store.pipe(select(selectAssetClassRetailShopActionLoading));

        this.store.dispatch(new AllUnitAreaMeasurementsRequested())
        this.store.dispatch(new AllGradesRequested());
        const gradeSubscription = this.store.pipe(
            select(selectAllGrades)
        ).subscribe(res => {
            this.grades = [];
            if (res) {
                this.grades = res;
            }
        });
        this._subscriptions.push(gradeSubscription);

        this.store.dispatch(new AllSubTypeCategorysRequested());
        const subTypeSub = this.store.pipe(
            select(selectAllSubTypeCategorys)
        ).subscribe(res => {
            this.subTypeCategories = res ? res.filter(item => item.property_sub_type_id == 5) : [];
        });
        this._subscriptions.push(subTypeSub);

        this.store.dispatch(new AllSubCategorysRequested());
        const subCatSub = this.store.select(selectAllSubCategorys)
            .subscribe(res => {
                this.subCategories = res ? res : [];
                this.filteredSubCategories = this._filterSubCategories(this.acRetailShop.subtype_category_id);
            });
        this._subscriptions.push(subCatSub);

        this.store.dispatch(new AllStateRepairsRequested())
        const stateRepairSub = this.store.select(selectAllStateRepairs)
            .subscribe(res => {
                this.stateOfRepairs = res ? res : [];
            });
        this._subscriptions.push(stateRepairSub);

        this.store.dispatch(new AllHandoverStandardsRequested())
        const handoverSub = this.store.select(selectAllHandoverStandards)
            .subscribe(res => {
                this.handoverStandards = res ? res : []
            });
        this._subscriptions.push(handoverSub);

        this.store.dispatch(new AllFoundationTypesRequested())
        const foundationSub = this.store.select(selectAllFoundationTypes)
            .subscribe(res => {
                this.foundationTypes = res ? res : []
            });
        this._subscriptions.push(foundationSub);
        this.store.dispatch(new AllBuildingTypesRequested());
        const buildingTypeSub = this.store.select(selectAllBuildingTypes).subscribe(res => {
            this.buildingTypes = res ? res : [];
        });
        this._subscriptions.push(buildingTypeSub);
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    getComponentTitle(): string {
        if (this.acRetailShop && this.acRetailShop.id) {
            return `Edit Retail Shop "${this.acRetailShop.ref_num}"`;
        }
        return 'Add Retail Shop';
    }

    cancel() {
        this.dialogRef.close();
    }

    onSubmit(isComplete: boolean) {
        let errorFields = [];
        this.hasFormErrors = false;
        this.considerationError$.next(false);
        if (isComplete == false || this.isComplexForm == false) {
            this._changeValidation(isComplete);
        }

        if (this.mapLocationComponent) {
            if (isComplete && this.mapLocationComponent.validate()) {
                this.hasFormErrors = true;
                errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
            }
        }
        const controls = this.formGroup.controls;
        if (this.formGroup.invalid) {
            Object.keys(controls).filter(cName => this.location_tab_items.includes(cName)).forEach(cname => {
                if (!controls[cname].valid) {
                    this.hasFormErrors = true;
                    errorFields = [...errorFields, cname];
                }
                controls[cname].markAsTouched();
            })
        }

        if (this.formGroup.invalid) {
            Object.keys(controls).filter(cName => !this.location_tab_items.includes(cName)).forEach(cname => {
                if (!controls[cname].valid) {
                    errorFields = [...errorFields, cname];
                }
                controls[cname].markAsTouched();
            })
            this.hasFormErrors = true;
        }
        if (this.internalAspectComponent) {
            if (isComplete && this.internalAspectComponent.validate()) {
                this.hasFormErrors = true;
                errorFields = [...errorFields, ...this.internalAspectComponent.errorFields];
            }
        }
        if (isComplete && this.acGroundsComponent.validate()) {
            this.hasFormErrors = true;
            errorFields = [...errorFields, ...this.acGroundsComponent.errorFields];
            if (this.acGroundsComponent.errorFields.length == 0) {
                errorFields = [...errorFields, 'grounds'];
            }
        }
        if (isComplete && this.sizesSubject.value.length == 0) {
            this.hasFormErrors = true;
            errorFields = [...errorFields, 'size'];
        }

        if (isComplete && !this.considerationAndSourceComponent.validate()) {
            this.hasFormErrors = true;
            this.considerationError$.next(true);
            errorFields = [...errorFields, 'consideration'];
        }
        if (this.buildingFormComponent) {
            if (isComplete && this.buildingFormComponent.validate()) {
            this.hasFormErrors = true;
            errorFields = [...errorFields, ...this.buildingFormComponent.errorFields.map(f => 'building_form_' + f)];
            }
        }

        if (isComplete && this.uploadFileComponent.uploadFiles.length == 0) {
            this.hasFormErrors = true;
            errorFields = [...errorFields, 'pic'];
        } else {
            const uploadedFiles = this.uploadFileComponent.uploadFiles;
            uploadedFiles.forEach(file => {
                if (file.title === null || file.title === '') {
                    this.hasFormErrors = true;
                    this.showTitleRequiredError$.next(true);
                    errorFields = [...errorFields, 'pic'];
                }
            })
        }
        if (isComplete && this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length == 0) {
            this.hasFormErrors = true;
            errorFields = [...errorFields, 'b_pic'];
        } else if (this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length > 0) {
            const uploadedFiles = this.buildingFileComponent.uploadFiles;
            uploadedFiles.forEach(file => {
                if (file.title === null || file.title === '') {
                    this.hasFormErrors = true;
                    this.showTitleRequiredError$.next(true);
                    errorFields = [...errorFields, 'b_pic'];
                }
            })
        }
        if (this.hasFormErrors) {
            this.error.fields = errorFields;
            return;
        }

        const _ac = this._prepareAssetClass(isComplete);
        const dialogRef = this.dialog.open(ValuationCheckerModalComponent, {
            width: '400px',
            disableClose: true
        })
        dialogRef.afterClosed().subscribe(res => {
            if (res == undefined) {
                return;
            }
            _ac.valuation_id = res ? this.valuationId : null;
            if (_ac.id) {
                this._updateAssetClass(_ac);
            } else {
                this._createAssetClass(_ac);
            }
        })
    }

    onFloorAdd(event) {
        const floorStr = event.value;
        const floors = this._getFloors(floorStr);
        const _floors = this._floors$.value;
        floors.forEach(floor => {
            if (_floors.findIndex(f => f == floor) > -1) {
                return;
            }
            _floors.push(floor);
        });
        _floors.sort();
        this._floors$.next(_floors);
    }
    onFloorRemove(event) {
        const floors = this._getFloors(event);
        const _floors = this._floors$.value;
        floors.forEach(floor => {
            const index = _floors.findIndex(f => f == floor);
            if (index > -1) {
                _floors.splice(index, 1);
            }
        });
        _floors.sort();
        this._floors$.next(_floors);
    }

    private _getFloors(raw: string): number[] {
        const splits = raw.split(':');
        const floors: number[] = []
        if (splits.length == 2) {
            const lower = parseInt(splits[0]);
            const upper = parseInt(splits[1]);
            for (let i = lower; i <= upper; i++) {
                floors.push(i);
            }
        } else {
            floors.push(parseInt(splits[0]));
        }
        return floors;
    }

    private _filterSubCategories(stcId: number) {
        if (stcId == null) {
            return [];
        }
        return this.subCategories.filter(item => item.sub_type_category_id == stcId);
    }

    private _createForm() {
        this.formSelectionCtrl.setValue(this.acRetailShop.formType);
        this.formSelectionCtrl.updateValueAndValidity();

        this.formGroup = this.formBuilder.group({
            building_name: [this.acRetailShop.building_name],
            // completion_year: [this.acRetailShop.completion_year, Validators.required],
            // is_part_of_property: [this.acRetailShop.is_part_of_property],
            // info_about_property: [this.acRetailShop.info_about_property],
            building_type: [this.acRetailShop.building_type, Validators.required],
            // building_type_comment: [this.acRetailShop.building_type_comment],
            // building_grade_id: [this.acRetailShop.building_grade_id, Validators.required],
            // energy_efficiency_grade_id: [this.acRetailShop.energy_efficiency_grade_id],
            // developer: [this.acRetailShop.developer],
            // anchor_tenant: [this.acRetailShop.anchor_tenant],
            // foundation_type_id: [this.acRetailShop.foundation_type_id],
            // building_description: [this.acRetailShop.building_description],

            subtype_category_id: [this.acRetailShop.subtype_category_id, Validators.required],
            sub_category_id: [this.acRetailShop.sub_category_id, Validators.required],
            handover_standard_id: [this.acRetailShop.handover_standard_id, Validators.required],
            state_of_repair_id: [this.acRetailShop.state_of_repair_id, Validators.required],
            floor_details: [this.acRetailShop.floor_details && this.acRetailShop.floor_details.length > 0
                ? this.acRetailShop.floor_details.split(',')
                : '', Validators.required],
            property_general_desc: [this.acRetailShop.property_general_desc]
        });
        this.formGroup.controls.subtype_category_id.valueChanges.pipe(
            startWith(this.formGroup.controls.subtype_category_id.value),
            takeUntil(this._onDestroy)
        ).subscribe(val => {
            this.filteredSubCategories = this._filterSubCategories(val)

            if (this.filteredSubCategories.length == 0) {
                this.formGroup.controls.sub_category_id.clearValidators();
            } else {
                this.formGroup.controls.sub_category_id.setValidators([Validators.required]);
            }
            this.formGroup.controls.sub_category_id.updateValueAndValidity();
        })

        const formSelectionSubscription = this.formSelectionCtrl.valueChanges.pipe(
            startWith(this.formSelectionCtrl.value)
        ).subscribe(val => {
            if (val == 1) {
                this.isComplexForm = true;
                this.formGroup.controls.building_type.clearValidators()
            } else {
                this.isComplexForm = false;
                this.formGroup.controls.building_type.setValidators([Validators.required])
            }
            this.formGroup.controls.building_type.updateValueAndValidity()
        });
        this._subscriptions.push(formSelectionSubscription);

    }

    private _changeValidation(isComplete: boolean) {
        const controls = this.formGroup.controls;
        const mustCnames = ['subtype_category_id', 'sub_category_id', 'handover_standard_id', 'state_of_repair_id', 'floor_details'];
        Object.keys(controls).forEach(cname => {
            if (isComplete && mustCnames.filter(item => item == cname).length == 1) {
                return;
            }
            controls[cname].clearValidators();
            controls[cname].updateValueAndValidity();
        });
        if (this.internalAspectComponent) {
            this.internalAspectComponent.changeValidation();
        }
    }

    public clearBuilding() {
        this.buildingId = null;
        const controls = this.formGroup.controls;
        controls.building_name.setValue(null);
        controls.completion_year.setValue(null);
        controls.is_part_of_property.setValue(false);
        controls.info_about_property.setValue(null);
        controls.building_type.setValue(null);
        controls.building_type_comment.setValue(null);
        controls.building_grade_id.setValue(null);
        controls.energy_efficiency_grade_id.setValue(null);
        controls.developer.setValue(null);
        controls.anchor_tenant.setValue(null);
        controls.foundation_type_id.setValue(null);
        controls.building_description.setValue(null);
        this.formGroup.updateValueAndValidity();
        this.building_pictures = [];
        this.building_picture = null;
    }

    public showExist() {
        const dialogRef = this.dialog.open(BuildingInfoDialogComponent, {
            data: {
                standAloneBuilding: false,
                currentBuildingId: this.buildingId ? this.buildingId : -1,
                lat: this.centerLat,
                lng: this.centerLng,
            }
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            const controls = this.formGroup.controls;
            controls.building_name.setValue(res.building_name);
            controls.completion_year.setValue(res.completion_year);
            controls.is_part_of_property.setValue(res.is_part_of_property);
            controls.info_about_property.setValue(res.info_about_property);
            controls.building_type.setValue(res.building_type);
            controls.building_type_comment.setValue(res.building_type_comment);
            controls.building_grade_id.setValue(res.building_grade_id);
            controls.energy_efficiency_grade_id.setValue(res.energy_efficiency_grade_id);
            controls.developer.setValue(res.developer);
            controls.anchor_tenant.setValue(res.anchor_tenant);
            controls.foundation_type_id.setValue(res.foundation_type_id);
            controls.building_description.setValue(res.building_description);
            this.formGroup.updateValueAndValidity();
            this.buildingId = res.id;
            this.centerLat = res.locationData.latitude;
            this.centerLng = res.locationData.longitude;
            this.acRetailShop = Object.assign({}, this.acRetailShop, {
                locationData: {
                    ...this.acRetailShop.locationData,
                    latitude: (Number(this.centerLat)),
                    longitude: (Number(this.centerLng)),
                    address: res.locationData.address,
                    location_grade_id: res.locationData.location_grade_id,
                    zip_code: res.locationData.zip_code,
                    city_id: res.locationData.city_id,
                    location_surrounding: res.locationData.location_surrounding
                }
            });
            // this.centerChange.emit({lat: this.centerLat, lng: this.centerLng});
            this.building_pictures = res.pictures.map(p => ({ ...p, pic_type: 2 }));
            this.building_picture = res.picture;
            // this.picChange.emit({pictures: this.pictures, picture: this.picture});
        });
    }

    private _prepareAssetClass(isComplete: boolean): AssetClassRetailShopModel {
        const _ac = new AssetClassRetailShopModel();
        _ac.clear();
        _ac.id = this.acRetailShop.id;
        _ac.status = isComplete ? 1 : 0;
        _ac.formType = this.formSelectionCtrl.value;

        const controls = this.formGroup.controls;
        if (this.mapLocationComponent) {
            _ac.locationData = this.mapLocationComponent.getData();
        }

        // _ac.cp_building_id = this.buildingId;
        // _ac.building_name = controls.building_name.value;
        // _ac.completion_year = controls.completion_year.value;
        // _ac.is_part_of_property = controls.is_part_of_property.value;
        // _ac.info_about_property = controls.info_about_property.value;
        // _ac.building_type = controls.building_type.value;
        // _ac.building_type_comment = controls.building_type_comment.value;
        // _ac.building_grade_id = controls.building_grade_id.value;
        // _ac.energy_efficiency_grade_id = controls.energy_efficiency_grade_id.value;
        // _ac.developer = controls.developer.value;
        // _ac.anchor_tenant = controls.anchor_tenant.value;
        // _ac.foundation_type_id = controls.foundation_type_id.value;
        // _ac.building_description = controls.building_description.value;

        _ac.sub_category_id = controls.sub_category_id.value;
        _ac.subtype_category_id = controls.subtype_category_id.value;
        _ac.handover_standard_id = controls.handover_standard_id.value;
        _ac.state_of_repair_id = controls.state_of_repair_id.value;
        _ac.property_general_desc = controls.property_general_desc.value;
        _ac.floor_details = controls.floor_details.value.length > 0
            ? controls.floor_details.value.join(',')
            : '';
        _ac.property_general_desc = controls.property_general_desc.value;
        _ac.accommodation = this.accommodationLayoutTable
            ? { ...this.accommodationLayoutTable.getData(), id: this.acRetailShop.accommodation.id }
            : { id: this.acRetailShop.accommodation.id, data: null, others: [] }

        if (this.internalAspectComponent) {
            _ac.internalAspectData = this.internalAspectComponent.getData();
        }

        _ac.grounds = { ...this.acGroundsComponent.getData(), id: this.acRetailShop.grounds.id };

        this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
            if (value) {
                _ac.picture = value.success;
            }
        })
        _ac.sizes = this.sizesSubject.value;
        _ac.tenures = this.considerationAndSourceComponent ? this.considerationAndSourceComponent.getData() : [];
        _ac.source = this.sourceSubject.value;
        if (this.acDocumentUploadComponent) {
            _ac.documents = this.acDocumentUploadComponent.getDocuments();
        }

        if (this.buildingFileComponent) {
            this.buildingFileComponent.res$.pipe(take(1)).subscribe(value => {
                if (value) {
                    _ac.b_picture = value.success;
                }
            });
            _ac.b_pictures = this.buildingFileComponent.uploadFiles;
        }
        if (this.buildingFormComponent) {
            _ac.buildingInfo = this.buildingFormComponent.getData();
        }

        return _ac;
    }
    private _updateAssetClass(ac: AssetClassRetailShopModel) {
        this.store.dispatch(new AssetClassRetailShopOnServerUpdated({
            AssetClassRetailShop: ac,
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
        }));
        this.dialogRef.close(true)
    }
    private _createAssetClass(ac: AssetClassRetailShopModel) {
        this.store.dispatch(new AssetClassRetailShopOnServerCreated({
            AssetClassRetailShop: ac,
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
        }));
      this.store.pipe(
        select(selectLastCreatedAssetClassRetailShopId),
        takeUntil(this._onDestroy),
        distinctUntilChanged(),
        switchMap(res => {
          if (!res) {
            return of(undefined)
          }
          this.store.dispatch(new UpdatePreviouslyCreatedACRetailShopId())
          return this.store.select(selectAssetClassRetailShopById(res))
        })
      ).subscribe(ac => {
        if (!ac) {
          return;
        }
        this.dialogRef.close(ac)
      })
    }

    private _getFloorsFrom(data: string) {
        const floorRaws = data.split(',');
        let floors = [];
        floorRaws.forEach(raw => {
            const _floors = this._getFloors(raw);
            _floors.forEach(f => floors.push(f));
        });
        floors = uniq(floors);
        return floors;
    }
    onTabChanged($event) {
        const activeIndex = $event.index;
    }
    onTabChange(index: number) {
        this.selectedTab = index;
    }
    changeTab(section: string) {
        const section_tab = [
            { sections: ['location', 'building'], tab_index: 0 },
            { sections: ['prop'], tab_index: 1 },
            { sections: ['consideration'], tab_index: 2 },
            { sections: ['pic'], tab_index: 3 },
        ]
        const active_Tab = section_tab.find(item => item.sections.includes(section));
        this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
        this.selectedtabchange.next(this.selectedTab);
    }
    erroTabMap() {
        return this.typesUtilsService.getCompTabErrorMap(this.isComplexForm, this.error.fields, this.tabHeaders, 5);
    }
    onHasFormErrorsChange(obj: { hasFormErrors: boolean }) {
        this.hasFormErrors = obj.hasFormErrors;
    }

    onCenterChange(locationData) {
        this.centerLat = locationData.latitude;
        this.centerLng = locationData.longitude;
        this.acRetailShop = Object.assign({}, this.acRetailShop, {
            locationData: {
                ...this.acRetailShop.locationData,
                latitude: (Number(locationData.latitude)),
                longitude: (Number(locationData.longitude)),
                address: locationData.address,
                location_grade_id: locationData.location_grade_id,
                zip_code: locationData.zip_code,
                city_id: locationData.city_id,
                location_surrounding: locationData.location_surrounding
            }
        });
    }

    onPicChange(pics: { pictures: any[], picture: any }) {
        this.building_pictures = pics.pictures;
        this.building_picture = pics.picture;
    }
}

function _deepCopy(arr: any[]): any[] {
    return arr.map(item => ({ ...item }));
}

function demoAc(): AssetClassRetailShopModel {
    const ac = new AssetClassRetailShopModel();
    ac.clear();
    // ac.grounds.external_area_comment = "hello";
    // ac.grounds.external_areas = [{area_id: 1}, {area_id: 2}];
    return ac;
}