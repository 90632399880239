import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';

@Injectable()
export class RouteToeIdResolver implements Resolve<any> {
    constructor(private global: Globals,
                private cookieService: CookieService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const toeId = Number(route.paramMap.get('toe_id'));
        this.global.activeTOEId = toeId;
        this.cookieService.set('toe_id', '' + toeId);
        return toeId;
    }
}
