import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from 'src/app/core/auth';
import { AdjustmentsEditComponent } from './adjustments-edit/adjustments-edit.component';
import { AdjustmentsListComponent } from './adjustments-list/adjustments-list.component';
import { AllLinkedTablesComponent } from './all-linked-tables/all-linked-tables.component';
import { ApproachesToValueEditComponent } from './approaches-to-value-edit/approaches-to-value-edit.component';
import { ApproachesToValuesListComponent } from './approaches-to-values-list/approaches-to-values-list.component';
import { AssetClassTypeEditComponent } from './asset-class-type-edit/asset-class-type-edit.component';
import { AssetClassTypesListComponent } from './asset-class-types-list/asset-class-types-list.component';
import { BaseOfValueEditComponent } from './base-of-value-edit/base-of-value-edit.component';
import { BaseOfValuesListComponent } from './base-of-values-list/base-of-values-list.component';
import { BuildingTypeEditComponent } from './building-type-edit/building-type-edit.component';
import { BuildingTypeListComponent } from './building-type-list/building-type-list.component';
import { DefaultAssumptionEditComponent } from './default-assumption-edit/default-assumption-edit.component';
import { DefaultAssumptionsListComponent } from './default-assumptions-list/default-assumptions-list.component';
import { DepartureEditComponent } from './departure-edit/departure-edit.component';
import { DeparturesListComponent } from './departures-list/departures-list.component';
import { ExternalAreaEditComponent } from './external-area-edit/external-area-edit.component';
import { ExternalAreasListComponent } from './external-areas-list/external-areas-list.component';
import { ExternalWallEditComponent } from './external-wall-edit/external-wall-edit.component';
import { ExternalWallsListComponent } from './external-walls-list/external-walls-list.component';
import { FacilitiesListComponent } from './facilities-list/facilities-list.component';
import { FacilityEditComponent } from './facility-edit/facility-edit.component';
import { FloorTypeEditComponent } from './floor-type-edit/floor-type-edit.component';
import { FloorTypesListComponent } from './floor-types-list/floor-types-list.component';
import { FoundationTypeEditComponent } from './foundation-type-edit/foundation-type-edit.component';
import { FoundationTypesListComponent } from './foundation-types-list/foundation-types-list.component';
import { GradeEditComponent } from './grade-edit/grade-edit.component';
import { GradeListComponent } from './grade-list/grade-list.component';
import { HandoverStandardEditComponent } from './handover-standard-edit/handover-standard-edit.component';
import { HandoverStandardListComponent } from './handover-standard-list/handover-standard-list.component';
import { IncludedInValuationEditComponent } from './included-in-valuation-edit/included-in-valuation-edit.component';
import { IncludedInValuationsListComponent } from './included-in-valuations-list/included-in-valuations-list.component';
import { InteractionTypeEditComponent } from './interaction-type-edit/interaction-type-edit.component';
import { InteractionTypesListComponent } from './interaction-types-list/interaction-types-list.component';
import { IpmsStandardEditComponent } from './ipms-standard-edit/ipms-standard-edit.component';
import { IpmsStandardsListComponent } from './ipms-standards-list/ipms-standards-list.component';
import { IvsStandardEditComponent } from './ivs-standard-edit/ivs-standard-edit.component';
import { IvsStandardsListComponent } from './ivs-standards-list/ivs-standards-list.component';
import { KeyCategoryEditComponent } from './key-category-edit/key-category-edit.component';
import { KeyCategoryListComponent } from './key-category-list/key-category-list.component';
import { LinkedTablesComponent } from './linked-tables.component';
import { MeasurementMethodologiesListComponent } from './measurement-methodologies-list/measurement-methodologies-list.component';
import { MeasurementMethodologyEditComponent } from './measurement-methodology-edit/measurement-methodology-edit.component';
import { MethodsToValueEditComponent } from './methods-to-value-edit/methods-to-value-edit.component';
import { MethodsToValuesListComponent } from './methods-to-values-list/methods-to-values-list.component';
import { OfficeAdjustmentEditComponent } from './office-adjustment-edit/office-adjustment-edit.component';
import { OfficeAdjustmentsListComponent } from './office-adjustments-list/office-adjustments-list.component';
import { OfficeLayoutEditComponent } from './office-layout-edit/office-layout-edit.component';
import { OfficeLayoutsListComponent } from './office-layouts-list/office-layouts-list.component';
import { OfficeStandardMeasurementEditComponent } from './office-standard-measurement-edit/office-standard-measurement-edit.component';
import { OfficeStandardMeasurementsListComponent } from './office-standard-measurements-list/office-standard-measurements-list.component';
import { PremiseOfValueEditComponent } from './premise-of-value-edit/premise-of-value-edit.component';
import { PremiseOfValuesListComponent } from './premise-of-values-list/premise-of-values-list.component';
import { PropertyTypeEditComponent } from './property-type-edit/property-type-edit.component';
import { PropertyTypesListComponent } from './property-types-list/property-types-list.component';
import { PurposeMeasurementEditComponent } from './purpose-measurement-edit/purpose-measurement-edit.component';
import { PurposeMeasurementsListComponent } from './purpose-measurements-list/purpose-measurements-list.component';
import { PurposeOfValuationEditComponent } from './purpose-of-valuation-edit/purpose-of-valuation-edit.component';
import { PurposeOfValuationsListComponent } from './purpose-of-valuations-list/purpose-of-valuations-list.component';
import { RentReviewCommentEditComponent } from './rent-review-comment-edit/rent-review-comment-edit.component';
import { RentReviewCommentListComponent } from './rent-review-comment-list/rent-review-comment-list.component';
import { ReportDeliveryMethodEditComponent } from './report-delivery-method-edit/report-delivery-method-edit.component';
import { ReportDeliveryMethodsListComponent } from './report-delivery-methods-list/report-delivery-methods-list.component';
import { ResidentialTypeEditComponent } from './residential-type-edit/residential-type-edit.component';
import { ResidentialTypeListComponent } from './residential-type-list/residential-type-list.component';
import { RicsStandardEditComponent } from './rics-standard-edit/rics-standard-edit.component';
import { RicsStandardsListComponent } from './rics-standards-list/rics-standards-list.component';
import { SourceCredibilityEditComponent } from './source-credibility-edit/source-credibility-edit.component';
import { SourceCredibilityListComponent } from './source-credibility-list/source-credibility-list.component';
import { SourceExternalReferenceEditComponent } from './source-external-reference-edit/source-external-reference-edit.component';
import { SourceExternalReferencesListComponent } from './source-external-references-list/source-external-references-list.component';
import { SourceInformationEditComponent } from './source-information-edit/source-information-edit.component';
import { SourceInformationListComponent } from './source-information-list/source-information-list.component';
import { SourceTypeEditComponent } from './source-type-edit/source-type-edit.component';
import { SourceTypeListComponent } from './source-type-list/source-type-list.component';
import { SpecialAssumptionEditComponent } from './special-assumption-edit/special-assumption-edit.component';
import { SpecialAssumptionsListComponent } from './special-assumptions-list/special-assumptions-list.component';
import { StandardMeasurementEditComponent } from './standard-measurement-edit/standard-measurement-edit.component';
import { StandardMeasurementsListComponent } from './standard-measurements-list/standard-measurements-list.component';
import { StateOfRepairEditComponent } from './state-of-repair-edit/state-of-repair-edit.component';
import { StateOfRepairListComponent } from './state-of-repair-list/state-of-repair-list.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TenureEditComponent } from './tenure-edit/tenure-edit.component';
import { TenureListComponent } from './tenure-list/tenure-list.component';
import { TypeOfRegistrationEditComponent } from './type-of-registration-edit/type-of-registration-edit.component';
import { TypeOfRegistrationListComponent } from './type-of-registration-list/type-of-registration-list.component';
import { TypeOfTitleEditComponent } from './type-of-title-edit/type-of-title-edit.component';
import { TypeOfTitleListComponent } from './type-of-title-list/type-of-title-list.component';
import { UnitAreaMeasurementEditComponent } from './unit-area-measurement-edit/unit-area-measurement-edit.component';
import { UnitAreaMeasurementsListComponent } from './unit-area-measurements-list/unit-area-measurements-list.component';
import { UnitMeasurementEditComponent } from './unit-measurement-edit/unit-measurement-edit.component';
import { UnitMeasurementsListComponent } from './unit-measurements-list/unit-measurements-list.component';
import { ValuationStandardEditComponent } from './valuation-standards-edit/valuation-standards-edit.component';
import { ValuationStandardsListComponent } from './valuation-standars-list/valuation-standards-list.component';
import { WindowTypeEditComponent } from './window-type-edit/window-type-edit.component';
import { WindowTypesListComponent } from './window-types-list/window-types-list.component';
import { BuildingUseStatusEditComponent } from './building-use-status-edit/building-use-status-edit.component';
import { BuildingUseStatusesListComponent } from './building-use-statuses-list/building-use-statuses-list.component';
import { ComparativeLandUseEditComponent } from './comparative-land-use-edit/comparative-land-use-edit.component';
import { ComparativeLandUsesListComponent } from './comparative-land-uses-list/comparative-land-uses-list.component';
import { CoordinateReferenceSystemEditComponent } from './coordinate-reference-system-edit/coordinate-reference-system-edit.component';
import { CoordinateReferenceSystemsListComponent } from './coordinate-reference-systems-list/coordinate-reference-systems-list.component';
import { LandTenureEditComponent } from './land-tenure-edit/land-tenure-edit.component';
import { LandTenuresListComponent } from './land-tenures-list/land-tenures-list.component';
import { ParcelCharacteristicEditComponent } from './parcel-characteristic-edit/parcel-characteristic-edit.component';
import { ParcelCharacteristicsListComponent } from './parcel-characteristics-list/parcel-characteristics-list.component';
import { PlaningStatusEditComponent } from './planing-status-edit/planing-status-edit.component';
import { PlaningStatusesListComponent } from './planing-statuses-list/planing-statuses-list.component';
import { QosDegradationEditComponent } from './qos-degradation-edit/qos-degradation-edit.component';
import { QosDegradationsListComponent } from './qos-degradations-list/qos-degradations-list.component';
import { SolUseClassificationEditComponent } from './sol-use-classification-edit/sol-use-classification-edit.component';
import { SolUseClassificationsListComponent } from './sol-use-classifications-list/sol-use-classifications-list.component';
import { UnitLengthMeasurementEditComponent } from './unit-length-measurement-edit/unit-length-measurement-edit.component';
import { UnitLengthMeasurementsListComponent } from './unit-length-measurements-list/unit-length-measurements-list.component';
import { IpmsComponentListComponent } from './ipms-component-list/ipms-component-list.component';
import { IpmsComponentEditComponent } from './ipms-component-edit/ipms-component-edit.component';
import { TopPropertyTypesListComponent } from './top-property-types-list/top-property-types-list.component';
import { TopPropertyTypeEditComponent } from './top-property-type-edit/top-property-type-edit.component';
import { SubTypeCategoriesListComponent } from './sub-type-categories-list/sub-type-categories-list.component';
import { SubTypeCategoryEditComponent } from './sub-type-category-edit/sub-type-category-edit.component';
import { SubCategoriesListComponent } from './sub-categories-list/sub-categories-list.component';
import { SubCategoryEditComponent } from './sub-category-edit/sub-category-edit.component';
import { BuiltinFittingsListComponent } from './builtin-fittings-list/builtin-fittings-list.component';
import { BuiltinFittingEditComponent } from './builtin-fitting-edit/builtin-fitting-edit.component';
import { CeilingsListComponent } from './ceilings-list/ceilings-list.component';
import { CeilingEditComponent } from './ceiling-edit/ceiling-edit.component';
import { FireplacesChimneyBreastsListComponent } from './fireplaces-chimney-breasts-list/fireplaces-chimney-breasts-list.component';
import { FireplacesChimneyBreastEditComponent } from './fireplaces-chimney-breast-edit/fireplaces-chimney-breast-edit.component';
import { RoofStructuresListComponent } from './roof-structures-list/roof-structures-list.component';
import { RoofStructureEditComponent } from './roof-structure-edit/roof-structure-edit.component';
import { StorageTypesListComponent } from './storage-types-list/storage-types-list.component';
import { StorageTypeEditComponent } from './storage-type-edit/storage-type-edit.component';
import { ExternalFacadesListComponent } from './external-facade-list/external-facade-list.component';
import { WallsAndPartitionEditComponent } from './walls-and-partition-edit/walls-and-partition-edit.component';
import { ChimneyStacksListComponent } from './chimney-stacks-list/chimney-stacks-list.component';
import { ChimneyStackEditComponent } from './chimney-stack-edit/chimney-stack-edit.component';
import { RoofCoveringsListComponent } from './roof-coverings-list/roof-coverings-list.component';
import { RoofCoveringEditComponent } from './roof-covering-edit/roof-covering-edit.component';
import { ConservatoryPorchesListComponent } from './conservatory-porches-list/conservatory-porches-list.component';
import { ConservatoryPorcheEditComponent } from './conservatory-porche-edit/conservatory-porche-edit.component';
import { MainWallsListComponent } from './main-walls-list/main-walls-list.component';
import { MainWallEditComponent } from './main-wall-edit/main-wall-edit.component';
import { OtherJoineriesListComponent } from './other-joineries-list/other-joineries-list.component';
import { OtherJoineryEditComponent } from './other-joinery-edit/other-joinery-edit.component';
import { OutsideDoorEditComponent } from './outside-door-edit/outside-door-edit.component';
import { OutsideDoorsListComponent } from './outside-doors-list/outside-doors-list.component';
import { RainwaterPipesListComponent } from './rainwater-pipes-list/rainwater-pipes-list.component';
import { RainwaterPipeEditComponent } from './rainwater-pipe-edit/rainwater-pipe-edit.component';
import { GardensListComponent } from './gardens-list/gardens-list.component';
import { GardenEditComponent } from './garden-edit/garden-edit.component';
import { OutdoorSpacesListComponent } from './outdoor-spaces-list/outdoor-spaces-list.component';
import { OutdoorSpaceEditComponent } from './outdoor-space-edit/outdoor-space-edit.component';
import { BathroomFittingsListComponent } from './bathroom-fittings-list/bathroom-fittings-list.component';
import { BathroomFittingEditComponent } from './bathroom-fitting-edit/bathroom-fitting-edit.component';
import { HouseAdjustmentListComponent } from './house-adjustment-list/house-adjustment-list.component';
import { HouseAdjustmentEditComponent } from './house-adjustment-edit/house-adjustment-edit.component';
import { WarehouseAdjustmentListComponent } from './warehouse-adjustment-list/warehouse-adjustment-list.component';
import { WarehouseAdjustmentEditComponent } from './warehouse-adjustment-edit/warehouse-adjustment-edit.component';
import { RetailBuildingAdjustmentListComponent } from './retail-building-adjustment-list/retail-building-adjustment-list.component';
import { RetailBuildingAdjustmentEditComponent } from './retail-building-adjustment-edit/retail-building-adjustment-edit.component';
import { ShopFrontTypesListComponent } from './shop-front-types-list/shop-front-types-list.component';
import { ShopFrontTypeEditComponent } from './shop-front-type-edit/shop-front-type-edit.component';
import { ParkingTypesListComponent } from './parking-types-list/parking-types-list.component';
import { ParkingTypeEditComponent } from './parking-type-edit/parking-type-edit.component';
import { RetailStoreAdjustmentListComponent } from './retail-store-adjustment-list/retail-store-adjustment-list.component';
import { RetailStoreAdjustmentEditComponent } from './retail-store-adjustment-edit/retail-store-adjustment-edit.component';
import { ParkingAdjustmentListComponent } from './parking-adjustment-list/parking-adjustment-list.component';
import { ParkingAdjustmentEditComponent } from './parking-adjustment-edit/parking-adjustment-edit.component';
import { LandAdjustmentListComponent } from './land-adjustment-list/land-adjustment-list.component';
import { LandAdjustmentEditComponent } from './land-adjustment-edit/land-adjustment-edit.component';
import { WallsAndPartitionsListComponent } from './walls-and-partitions-list/walls-and-partitions-list.component';
import { ExternalFacadeEditComponent } from './external-facade-edit/external-facade-edit.component';
import { FloorNumberingSchemesListComponent } from './floor-numbering-schemes-list/floor-numbering-schemes-list.component';
import { FloorNumberingSchemeEditComponent } from './floor-numbering-scheme-edit/floor-numbering-scheme-edit.component';
import { OrganisationsListComponent } from './organisation-list/organisations-list.component';
import { OrganisationEditComponent } from './organisation-edit/organisation-edit.component';
import { CertificationsListComponent } from './certification-list/certifications-list.component';
import { CertificationEditComponent } from './certification-edit/certification-edit.component';
import { AdditionalCostTitlesListComponent } from './additional-cost-titles-list/additional-cost-titles-list.component';
import { AdditionalCostTitleEditComponent } from './additiona-cost-title-edit/additional-cost-title-edit.component';
import { RentBasesListComponent } from './rent-bases-list/rent-bases-list.component';
import { RentBasisEditComponent } from './rent-basis-edit/rent-basis-edit.component';
import { RentReviewTypesListComponent } from './rent-review-types-list/rent-review-types-list.component';
import { RentReviewTypeEditComponent } from './rent-review-type-edit/rent-review-type-edit.component';

const routes: Routes = [
    {
        path: '',
        component: LinkedTablesComponent,
        children: [
            {
                path: '',
                component: AllLinkedTablesComponent
            },
            {
                path: 'interaction-types',
                component: InteractionTypesListComponent
            },
            {
                path: 'interaction-types/add',
                component: InteractionTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'interaction-types/edit/:id',
                component: InteractionTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'interaction-types/view/:id',
                component: InteractionTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'purpose-of-valuations',
                component: PurposeOfValuationsListComponent
            },
            {
                path: 'purpose-of-valuations/add',
                component: PurposeOfValuationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'purpose-of-valuations/edit/:id',
                component: PurposeOfValuationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'purpose-of-valuations/view/:id',
                component: PurposeOfValuationEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'report-delivery-methods',
                component: ReportDeliveryMethodsListComponent
            },
            {
                path: 'report-delivery-methods/add',
                component: ReportDeliveryMethodEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'report-delivery-methods/edit/:id',
                component: ReportDeliveryMethodEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'report-delivery-methods/view/:id',
                component: ReportDeliveryMethodEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'source-external-references',
                component: SourceExternalReferencesListComponent
            },
            {
                path: 'source-external-references/add',
                component: SourceExternalReferenceEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-external-references/edit/:id',
                component: SourceExternalReferenceEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-external-references/view/:id',
                component: SourceExternalReferenceEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'approaches-to-values',
                component: ApproachesToValuesListComponent
            },
            {
                path: 'approaches-to-values/add',
                component: ApproachesToValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'approaches-to-values/edit/:id',
                component: ApproachesToValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'approaches-to-values/view/:id',
                component: ApproachesToValueEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'base-of-values',
                component: BaseOfValuesListComponent
            },
            {
                path: 'base-of-values/add',
                component: BaseOfValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'base-of-values/edit/:id',
                component: BaseOfValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'base-of-values/view/:id',
                component: BaseOfValueEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'building-types',
                component: BuildingTypeListComponent
            },
            {
                path: 'building-types/add',
                component: BuildingTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-types/edit/:id',
                component: BuildingTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-types/view/:id',
                component: BuildingTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'state-repairs',
                component: StateOfRepairListComponent
            },
            {
                path: 'state-repairs/add',
                component: StateOfRepairEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'state-repairs/edit/:id',
                component: StateOfRepairEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'state-repairs/view/:id',
                component: StateOfRepairEditComponent,
                data: {
                    readonly: true
                }
            },

            {
                path: 'type-of-titles',
                component: TypeOfTitleListComponent
            },
            {
                path: 'type-of-titles/add',
                component: TypeOfTitleEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'type-of-titles/edit/:id',
                component: TypeOfTitleEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'premise-of-values',
                component: PremiseOfValuesListComponent
            },
            {
                path: 'premise-of-values/add',
                component: PremiseOfValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'premise-of-values/edit/:id',
                component: PremiseOfValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'premise-of-values/view/:id',
                component: PremiseOfValueEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'measurement-methodologies',
                component: MeasurementMethodologiesListComponent
            },
            {
                path: 'measurement-methodologies/add',
                component: MeasurementMethodologyEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'measurement-methodologies/edit/:id',
                component: MeasurementMethodologyEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'measurement-methodologies/view/:id',
                component: MeasurementMethodologyEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'purpose-measurements',
                component: PurposeMeasurementsListComponent
            },
            {
                path: 'purpose-measurements/add',
                component: PurposeMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'purpose-measurements/edit/:id',
                component: PurposeMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'purpose-measurements/view/:id',
                component: PurposeMeasurementEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'default-assumptions',
                component: DefaultAssumptionsListComponent
            },
            {
                path: 'default-assumptions/add',
                component: DefaultAssumptionEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'default-assumptions/edit/:id',
                component: DefaultAssumptionEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'default-assumptions/view/:id',
                component: DefaultAssumptionEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'departures',
                component: DeparturesListComponent
            },
            {
                path: 'departures/add',
                component: DepartureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'departures/edit/:id',
                component: DepartureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'departures/view/:id',
                component: DepartureEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'included-in-valuations',
                component: IncludedInValuationsListComponent
            },
            {
                path: 'included-in-valuations/add',
                component: IncludedInValuationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'included-in-valuations/edit/:id',
                component: IncludedInValuationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'methods-to-values',
                component: MethodsToValuesListComponent
            },
            {
                path: 'methods-to-values/add',
                component: MethodsToValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'methods-to-values/edit/:id',
                component: MethodsToValueEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'methods-to-values/view/:id',
                component: MethodsToValueEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'special-assumptions',
                component: SpecialAssumptionsListComponent
            },
            {
                path: 'special-assumptions/add',
                component: SpecialAssumptionEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'special-assumptions/edit/:id',
                component: SpecialAssumptionEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'special-assumptions/view/:id',
                component: SpecialAssumptionEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'valuation-standards',
                component: ValuationStandardsListComponent
            },
            {
                path: 'valuation-standards/add',
                component: ValuationStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'valuation-standards/edit/:id',
                component: ValuationStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'valuation-standards/view/:id',
                component: ValuationStandardEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'residential-types',
                component: ResidentialTypeListComponent
            },
            {
                path: 'residential-types/add',
                component: ResidentialTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'residential-types/edit/:id',
                component: ResidentialTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'type-of-registrations',
                component: TypeOfRegistrationListComponent
            },
            {
                path: 'type-of-registrations/add',
                component: TypeOfRegistrationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'type-of-registrations/edit/:id',
                component: TypeOfRegistrationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'handover-standards',
                component: HandoverStandardListComponent
            },
            {
                path: 'handover-standards/add',
                component: HandoverStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'handover-standards/edit/:id',
                component: HandoverStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'handover-standards/view/:id',
                component: HandoverStandardEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'rent-review-comments',
                component: RentReviewCommentListComponent
            },
            {
                path: 'rent-review-comments/add',
                component: RentReviewCommentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'rent-review-comments/edit/:id',
                component: RentReviewCommentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },

            {
                path: 'grades',
                component: GradeListComponent
            },
            {
                path: 'grades/add',
                component: GradeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'grades/edit/:id',
                component: GradeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'grades/view/:id',
                component: GradeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'source-credibility',
                component: SourceCredibilityListComponent
            },
            {
                path: 'source-credibility/add',
                component: SourceCredibilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-credibility/edit/:id',
                component: SourceCredibilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-credibility/view/:id',
                component: SourceCredibilityEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'source-types',
                component: SourceTypeListComponent
            },
            {
                path: 'source-types/add',
                component: SourceTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-types/edit/:id',
                component: SourceTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-types/view/:id',
                component: SourceTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'source-information',
                component: SourceInformationListComponent
            },
            {
                path: 'source-information/add',
                component: SourceInformationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-information/edit/:id',
                component: SourceInformationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'source-information/view/:id',
                component: SourceInformationEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'asset-class-types',
                component: AssetClassTypesListComponent
            },
            {
                path: 'asset-class-types/add',
                component: AssetClassTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'asset-class-types/edit/:id',
                component: AssetClassTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'asset-class-types/view/:id',
                component: AssetClassTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'standard-measurements',
                component: StandardMeasurementsListComponent
            },
            {
                path: 'standard-measurements/add',
                component: StandardMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'standard-measurements/edit/:id',
                component: StandardMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'standard-measurements/view/:id',
                component: StandardMeasurementEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'ipms-standards',
                component: IpmsStandardsListComponent
            },
            {
                path: 'ipms-standards/add',
                component: IpmsStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ipms-standards/edit/:id',
                component: IpmsStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ivs-standards',
                component: IvsStandardsListComponent
            },
            {
                path: 'ivs-standards/add',
                component: IvsStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ivs-standards/edit/:id',
                component: IvsStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'rics-standards',
                component: RicsStandardsListComponent
            },
            {
                path: 'task-efforts',
                component: TasksListComponent
            },
            {
                path: 'rics-standards/add',
                component: RicsStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'rics-standards/edit/:id',
                component: RicsStandardEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-measurements',
                component: UnitMeasurementsListComponent
            },
            {
                path: 'unit-measurements/add',
                component: UnitMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-measurements/edit/:id',
                component: UnitMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-measurements/view/:id',
                component: UnitMeasurementEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'unit-length-measurements',
                component: UnitLengthMeasurementsListComponent
            },
            {
                path: 'unit-length-measurements/add',
                component: UnitLengthMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-length-measurements/edit/:id',
                component: UnitLengthMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-length-measurements/view/:id',
                component: UnitLengthMeasurementEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'unit-area-measurements',
                component: UnitAreaMeasurementsListComponent
            },
            {
                path: 'unit-area-measurements/add',
                component: UnitAreaMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-area-measurements/edit/:id',
                component: UnitAreaMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'unit-area-measurements/view/:id',
                component: UnitAreaMeasurementEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: ':ac_type_id/facilities',
                component: FacilitiesListComponent
            },
            {
                path: ':ac_type_id/facilities/add',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':ac_type_id/facilities/edit/:id',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-facilities',
                component: FacilitiesListComponent
            },
            {
                path: 'building-facilities/add',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-facilities/edit/:id',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-facilities/view/:id',
                component: FacilityEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: ':ac_type_id/office-facilities',
                component: FacilitiesListComponent
            },
            {
                path: ':ac_type_id/office-facilities/add',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':ac_type_id/office-facilities/edit/:id',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':ac_type_id/office-facilities-exclusive',
                component: FacilitiesListComponent
            },
            {
                path: ':ac_type_id/office-facilities-exclusive/add',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':ac_type_id/office-facilities-exclusive/edit/:id',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':ac_type_id/retail-facilities',
                component: FacilitiesListComponent
            },
            {
                path: ':ac_type_id/retail-facilities/add',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':ac_type_id/retail-facilities/edit/:id',
                component: FacilityEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'key-categories',
                component: KeyCategoryListComponent
            },
            {
                path: 'key-categories/add',
                component: KeyCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'key-categories/edit/:id',
                component: KeyCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'key-categories/view/:id',
                component: KeyCategoryEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'external-areas',
                component: ExternalAreasListComponent
            },
            {
                path: 'external-areas/add',
                component: ExternalAreaEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'external-areas/edit/:id',
                component: ExternalAreaEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'external-areas/view/:id',
                component: ExternalAreaEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'floor-types',
                component: FloorTypesListComponent
            },
            {
                path: 'floor-types/add',
                component: FloorTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'floor-types/edit/:id',
                component: FloorTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'floor-types/view/:id',
                component: FloorTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: ':property_sub_type_id/warehouse-floor-types',
                component: FloorTypesListComponent
            },
            {
                path: ':property_sub_type_id/warehouse-floor-types/add',
                component: FloorTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ':property_sub_type_id/warehouse-floor-types/edit/:id',
                component: FloorTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'window-types',
                component: WindowTypesListComponent
            },
            {
                path: 'window-types/add',
                component: WindowTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'window-types/edit/:id',
                component: WindowTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'window-types/view/:id',
                component: WindowTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'external-walls',
                component: ExternalWallsListComponent
            },
            {
                path: 'external-walls/add',
                component: ExternalWallEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'external-walls/edit/:id',
                component: ExternalWallEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'external-walls/view/:id',
                component: ExternalWallEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'foundation-types',
                component: FoundationTypesListComponent
            },
            {
                path: 'foundation-types/add',
                component: FoundationTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'foundation-types/edit/:id',
                component: FoundationTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'foundation-types/view/:id',
                component: FoundationTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'property-types',
                component: PropertyTypesListComponent
            },
            {
                path: 'property-types/add',
                component: PropertyTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'property-types/edit/:id',
                component: PropertyTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'tenures',
                component: TenureListComponent
            },
            {
                path: 'tenures/add',
                component: TenureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'tenures/edit/:id',
                component: TenureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'tenures/view/:id',
                component: TenureEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'office-standard-measurements',
                component: OfficeStandardMeasurementsListComponent
            },
            {
                path: 'office-standard-measurements/add',
                component: OfficeStandardMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'office-standard-measurements/edit/:id',
                component: OfficeStandardMeasurementEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'office-layouts',
                component: OfficeLayoutsListComponent
            },
            {
                path: 'office-layouts/add',
                component: OfficeLayoutEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'office-layouts/edit/:id',
                component: OfficeLayoutEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'residential-adjustments',
                component: AdjustmentsListComponent,
            },
            {
                path: 'residential-adjustments/add',
                component: AdjustmentsEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'residential-adjustments/edit/:id',
                component: AdjustmentsEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'residential-adjustments/view/:id',
                component: AdjustmentsEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'office-adjustments',
                component: OfficeAdjustmentsListComponent,
            },
            {
                path: 'office-adjustments/add',
                component: OfficeAdjustmentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'office-adjustments/edit/:id',
                component: OfficeAdjustmentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'office-adjustments/view/:id',
                component: OfficeAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'building-use-statuses',
                component: BuildingUseStatusesListComponent
            },
            {
                path: 'building-use-statuses/add',
                component: BuildingUseStatusEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-use-statuses/edit/:id',
                component: BuildingUseStatusEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'building-use-statuses/view/:id',
                component: BuildingUseStatusEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'comparative-land-uses',
                component: ComparativeLandUsesListComponent
            },
            {
                path: 'comparative-land-uses/add',
                component: ComparativeLandUseEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'comparative-land-uses/edit/:id',
                component: ComparativeLandUseEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'comparative-land-uses/view/:id',
                component: ComparativeLandUseEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'coordinate-reference-systems',
                component: CoordinateReferenceSystemsListComponent
            },
            {
                path: 'coordinate-reference-systems/add',
                component: CoordinateReferenceSystemEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'coordinate-reference-systems/edit/:id',
                component: CoordinateReferenceSystemEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'coordinate-reference-systems/view/:id',
                component: CoordinateReferenceSystemEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'land-tenures',
                component: LandTenuresListComponent
            },
            {
                path: 'land-tenures/add',
                component: LandTenureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'land-tenures/edit/:id',
                component: LandTenureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'land-tenures/view/:id',
                component: LandTenureEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'parcel-characteristics',
                component: ParcelCharacteristicsListComponent
            },
            {
                path: 'parcel-characteristics/add',
                component: ParcelCharacteristicEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'parcel-characteristics/edit/:id',
                component: ParcelCharacteristicEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'parcel-characteristics/view/:id',
                component: ParcelCharacteristicEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'planing-statuses',
                component: PlaningStatusesListComponent
            },
            {
                path: 'planing-statuses/add',
                component: PlaningStatusEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'planing-statuses/edit/:id',
                component: PlaningStatusEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'planing-statuses/view/:id',
                component: PlaningStatusEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'qos-degradations',
                component: QosDegradationsListComponent
            },
            {
                path: 'qos-degradations/add',
                component: QosDegradationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'qos-degradations/edit/:id',
                component: QosDegradationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'qos-degradations/view/:id',
                component: QosDegradationEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'sol-use-classifications',
                component: SolUseClassificationsListComponent
            },
            {
                path: 'sol-use-classifications/add',
                component: SolUseClassificationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'sol-use-classifications/edit/:id',
                component: SolUseClassificationEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'sol-use-classifications/view/:id',
                component: SolUseClassificationEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'ipms-components',
                component: IpmsComponentListComponent
            },
            {
                path: 'ipms-components/add',
                component: IpmsComponentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ipms-components/edit/:id',
                component: IpmsComponentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ipms-components/view/:id',
                component: IpmsComponentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'top-property-types',
                component: TopPropertyTypesListComponent 
            },
            {
                path: 'top-property-types/add',
                component: TopPropertyTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'top-property-types/edit/:id',
                component: TopPropertyTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'top-property-types/view/:id',
                component: TopPropertyTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'sub-type-categories',
                component: SubTypeCategoriesListComponent 
            },
            {
                path: 'sub-type-categories/add',
                component: SubTypeCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'sub-type-categories/edit/:id',
                component: SubTypeCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'sub-type-categories/view/:id',
                component: SubTypeCategoryEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'sub-categories',
                component: SubCategoriesListComponent 
            },
            {
                path: 'sub-categories/add',
                component: SubCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'sub-categories/edit/:id',
                component: SubCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'sub-categories/view/:id',
                component: SubCategoryEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'builtin-fittings',
                component: BuiltinFittingsListComponent
            },
            {
                path: 'builtin-fittings/add',
                component: BuiltinFittingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'builtin-fittings/edit/:id',
                component: BuiltinFittingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'builtin-fittings/view/:id',
                component: BuiltinFittingEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'ceilings',
                component: CeilingsListComponent
            },
            {
                path: 'ceilings/add',
                component: CeilingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ceilings/edit/:id',
                component: CeilingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'ceilings/view/:id',
                component: CeilingEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'fireplaces-chimney-breasts',
                component: FireplacesChimneyBreastsListComponent
            },
            {
                path: 'fireplaces-chimney-breasts/add',
                component: FireplacesChimneyBreastEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'fireplaces-chimney-breasts/edit/:id',
                component: FireplacesChimneyBreastEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'fireplaces-chimney-breasts/view/:id',
                component: FireplacesChimneyBreastEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'roof-structures',
                component: RoofStructuresListComponent
            },
            {
                path: 'roof-structures/add',
                component: RoofStructureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'roof-structures/edit/:id',
                component: RoofStructureEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'roof-structures/view/:id',
                component: RoofStructureEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'storage-types',
                component: StorageTypesListComponent
            },
            {
                path: 'storage-types/add',
                component: StorageTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'storage-types/edit/:id',
                component: StorageTypeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'storage-types/view/:id',
                component: StorageTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'walls-and-partitions',
                component: WallsAndPartitionsListComponent
            },
            {
                path: 'walls-and-partitions/add',
                component: WallsAndPartitionEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'walls-and-partitions/edit/:id',
                component: WallsAndPartitionEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'walls-and-partitions/view/:id',
                component: WallsAndPartitionEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'bathroom-fittings',
                component: BathroomFittingsListComponent
            },
            {
                path: 'bathroom-fittings/add',
                component: BathroomFittingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'bathroom-fittings/edit/:id',
                component: BathroomFittingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'bathroom-fittings/view/:id',
                component: BathroomFittingEditComponent,
                data:  {
                    readonly: true
                }
            },
            {
                path: 'chimney-stacks',
                component: ChimneyStacksListComponent
            },
            {
                path: 'chimney-stacks/add',
                component: ChimneyStackEditComponent
            },
            {
                path: 'chimney-stacks/edit/:id',
                component: ChimneyStackEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'chimney-stacks/view/:id',
                component: ChimneyStackEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'roof-coverings',
                component: RoofCoveringsListComponent 
            },
            {
                path: 'roof-coverings/add',
                component: RoofCoveringEditComponent 
            },
            {
                path: 'roof-coverings/edit/:id',
                component: RoofCoveringEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'roof-coverings/view/:id',
                component: RoofCoveringEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'conservatory-and-porches',
                component: ConservatoryPorchesListComponent 
            },
            {
                path: 'conservatory-and-porches/add',
                component: ConservatoryPorcheEditComponent 
            },
            {
                path: 'conservatory-and-porches/edit/:id',
                component: ConservatoryPorcheEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'conservatory-and-porches/view/:id',
                component: ConservatoryPorcheEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'main-walls',
                component: MainWallsListComponent 
            },
            {
                path: 'main-walls/add',
                component: MainWallEditComponent 
            },
            {
                path: 'main-walls/edit/:id',
                component: MainWallEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'main-walls/view/:id',
                component: MainWallEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'other-joineries',
                component: OtherJoineriesListComponent 
            },
            {
                path: 'other-joineries/add',
                component: OtherJoineryEditComponent 
            },
            {
                path: 'other-joineries/edit/:id',
                component: OtherJoineryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'other-joineries/view/:id',
                component: OtherJoineryEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'outside-doors',
                component: OutsideDoorsListComponent 
            },
            {
                path: 'outside-doors/add',
                component: OutsideDoorEditComponent 
            },
            {
                path: 'outside-doors/edit/:id',
                component: OutsideDoorEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'outside-doors/view/:id',
                component: OutsideDoorEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'rainwater-pipes',
                component: RainwaterPipesListComponent 
            },
            {
                path: 'rainwater-pipes/add',
                component: RainwaterPipeEditComponent 
            },
            {
                path: 'rainwater-pipes/edit/:id',
                component: RainwaterPipeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'rainwater-pipes/view/:id',
                component: RainwaterPipeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'gardens',
                component: GardensListComponent 
            },
            {
                path: 'gardens/add',
                component: GardenEditComponent 
            },
            {
                path: 'gardens/edit/:id',
                component: GardenEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'gardens/view/:id',
                component: GardenEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'outdoor-spaces',
                component: OutdoorSpacesListComponent 
            },
            {
                path: 'outdoor-spaces/add',
                component: OutdoorSpaceEditComponent 
            },
            {
                path: 'outdoor-spaces/edit/:id',
                component: OutdoorSpaceEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'outdoor-spaces/view/:id',
                component: OutdoorSpaceEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'house-adjustments',
                component: HouseAdjustmentListComponent
            },
            {
                path: 'house-adjustments/add',
                component: HouseAdjustmentEditComponent
            },
            {
                path: 'house-adjustments/edit/:id',
                component: HouseAdjustmentEditComponent
            },
            {
                path: 'house-adjustments/view/:id',
                component: HouseAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'warehouse-adjustments',
                component: WarehouseAdjustmentListComponent
            },
            {
                path: 'warehouse-adjustments/add',
                component: WarehouseAdjustmentEditComponent
            },
            {
                path: 'warehouse-adjustments/edit/:id',
                component: WarehouseAdjustmentEditComponent
            },
            {
                path: 'warehouse-adjustments/view/:id',
                component: WarehouseAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'retail-building-adjustments',
                component: RetailBuildingAdjustmentListComponent
            },
            {
                path: 'retail-building-adjustments/add',
                component: RetailBuildingAdjustmentEditComponent
            },
            {
                path: 'retail-building-adjustments/edit/:id',
                component: RetailBuildingAdjustmentEditComponent
            },
            {
                path: 'retail-building-adjustments/view/:id',
                component: RetailBuildingAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'shop-front-types',
                component: ShopFrontTypesListComponent
            },
            {
                path: 'shop-front-types/add',
                component: ShopFrontTypeEditComponent
            },
            {
                path: 'shop-front-types/edit/:id',
                component: ShopFrontTypeEditComponent
            },
            {
                path: 'shop-front-types/view/:id',
                component: ShopFrontTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'parking-types',
                component: ParkingTypesListComponent 
            },
            {
                path: 'parking-types/add',
                component: ParkingTypeEditComponent 
            },
            {
                path: 'parking-types/edit/:id',
                component: ParkingTypeEditComponent 
            },
            {
                path: 'parking-types/view/:id',
                component: ParkingTypeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'retail-store-adjustments',
                component: RetailStoreAdjustmentListComponent
            },
            {
                path: 'retail-store-adjustments/add',
                component: RetailStoreAdjustmentEditComponent
            },
            {
                path: 'retail-store-adjustments/edit/:id',
                component: RetailStoreAdjustmentEditComponent
            },
            {
                path: 'retail-store-adjustments/view/:id',
                component: RetailStoreAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'parking-adjustments',
                component: ParkingAdjustmentListComponent
            },
            {
                path: 'parking-adjustments/add',
                component: ParkingAdjustmentEditComponent
            },
            {
                path: 'parking-adjustments/edit/:id',
                component: ParkingAdjustmentEditComponent
            },
            {
                path: 'parking-adjustments/view/:id',
                component: ParkingAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'land-adjustments',
                component: LandAdjustmentListComponent
            },
            {
                path: 'land-adjustments/add',
                component: LandAdjustmentEditComponent
            },
            {
                path: 'land-adjustments/edit/:id',
                component: LandAdjustmentEditComponent
            },
            {
                path: 'land-adjustments/view/:id',
                component: LandAdjustmentEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'external-facades',
                component: ExternalFacadesListComponent 
            },
            {
                path: 'external-facades/add',
                component: ExternalFacadeEditComponent
            },
            {
                path: 'external-facades/edit/:id',
                component: ExternalFacadeEditComponent
            },
            {
                path: 'external-facades/view/:id',
                component: ExternalFacadeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'floor-numbering-schemes',
                component: FloorNumberingSchemesListComponent
            },
            {
                path: 'floor-numbering-schemes/add',
                component: FloorNumberingSchemeEditComponent
            },
            {
                path: 'floor-numbering-schemes/edit/:id',
                component: FloorNumberingSchemeEditComponent
            },
            {
                path: 'floor-numbering-schemes/view/:id',
                component: FloorNumberingSchemeEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'organisations',
                component: OrganisationsListComponent
            },
            {
                path: 'organisations/add',
                component: OrganisationEditComponent
            },
            {
                path: 'organisations/edit/:id',
                component: OrganisationEditComponent
            },
            {
                path: 'organisations/view/:id',
                component: OrganisationEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'certifications',
                component: CertificationsListComponent
            },
            {
                path: 'certifications/add',
                component: CertificationEditComponent
            },
            {
                path: 'certifications/edit/:id',
                component: CertificationEditComponent
            },
            {
                path: 'certifications/view/:id',
                component: CertificationEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'additional-cost-titles',
                component: AdditionalCostTitlesListComponent
            },
            {
                path: 'additional-cost-titles/add',
                component: AdditionalCostTitleEditComponent,
            },
            {
                path: 'additional-cost-titles/edit/:id',
                component: AdditionalCostTitleEditComponent
            },
            {
                path: 'additional-cost-titles/view/:id',
                component: AdditionalCostTitleEditComponent,
                data: {
                    readonly: true
                }
            },
            {
                path: 'rent-bases',
                component: RentBasesListComponent
            },
            {
                path: 'rent-bases/add',
                component: RentBasisEditComponent
            },
            {
                path: 'rent-bases/edit/:id',
                component: RentBasisEditComponent
            },
            {
                path: 'rent-review-types',
                component: RentReviewTypesListComponent
            },
            {
                path: 'rent-review-types/add',
                component: RentReviewTypeEditComponent
            },
            {
                path: 'rent-review-types/edit/:id',
                component: RentReviewTypeEditComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class LinkedTablesRoutingModule {

}