// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {DepartureActions, DepartureActionTypes} from '../_actions/departure.actions';
// Models
import {Departure} from '../_models/departure.model';
import {QueryParamsModel} from '../../_base/crud';

export interface DeparturesState extends EntityState<Departure> {
    isAllDeparturesLoaded: boolean;
    queryRowsCount: number;
    queryResult: Departure[];
    lastCreatedDepartureId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Departure> = createEntityAdapter<Departure>();

export const initialDeparturesState: DeparturesState = adapter.getInitialState({
    isAllDeparturesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedDepartureId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function departuresReducer(state = initialDeparturesState, action: DepartureActions): DeparturesState {
    switch (action.type) {
        case DepartureActionTypes.DeparturesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDepartureId: undefined
            };
        case DepartureActionTypes.DeparturesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case DepartureActionTypes.DepartureOnServerCreated:
            return {
                ...state
            };
        case DepartureActionTypes.DepartureCreated:
            return adapter.addOne(action.payload.departure, {
                ...state, lastCreatedDepartureId: action.payload.departure.id
            });
        case DepartureActionTypes.DepartureUpdated:
            return adapter.updateOne(action.payload.partialdeparture, state);
        case DepartureActionTypes.AllDeparturesLoaded:
            return adapter.addAll(action.payload.departures, {
                ...state, isAllDeparturesLoaded: true
            });
        case DepartureActionTypes.DeparturesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case DepartureActionTypes.DeparturesPageLoaded:
            return adapter.addMany(action.payload.departures, {
                ...initialDeparturesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case DepartureActionTypes.DepartureDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case DepartureActionTypes.DepartureRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case DepartureActionTypes.DepartureDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case DepartureActionTypes.DepartureAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case DepartureActionTypes.DepartureDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case DepartureActionTypes.DepartureTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
