import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';

@Component({
    selector: 'kt-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {
    @Input() hasFormErrors: boolean;
    @Input() tabHeaders: TabHeader;
    @Input() error: any;
    @Input() erroTabMap: any;
    @Output() hasFormErrorsChange: EventEmitter<{hasFormErrors: boolean}> = new EventEmitter();

    onAlertClose($event) {
        this.hasFormErrors = false;
        this.hasFormErrorsChange.emit({hasFormErrors: this.hasFormErrors});
    }

}