// Angular
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * Sample context menu dropdown
 */
@Component({
    selector: 'kt-context-menu-mad',
    templateUrl: './context-menu-mad.component.html',
    styleUrls: ['./context-menu-mad.component.scss']
})

// export interface ContextMenuInterface {
//     actionBody(item: { title: string, icon: string, refId: number });
// }

export class ContextMenuMadComponent {
    @Input() menuItems: { title: string, icon: string, refId: number, disabled: boolean }[];
    @Input() menuSubject: BehaviorSubject<number>;
    @Input() width: string;
    @Input() menuName: string = 'Actions';
    @Output() menuClick = new EventEmitter<number>()

    clickAction(item: { title: string, icon: string, refId: number }) {
        if (this.menuSubject) {
            this.menuSubject.next(item.refId);
        }
        this.menuClick.emit(item.refId)
    }
}
