import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { TpTaskService } from 'src/app/core/asset_class';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { DeliveryFormComponent } from './delivery-form/delivery-form.component';

export interface DeliveryTaskModalData {
  draftTasks: {
    id: number,
    doable: boolean,
    details: string,
  }[],
  valTasks: {
    id: number,
    doable: boolean,
    details: string
  }[]
}

@Component({
  selector: 'kt-delivery-task-modal',
  templateUrl: './delivery-task-modal.component.html',
  styleUrls: ['./delivery-task-modal.component.scss']
})
export class DeliveryTaskModalComponent implements OnInit {
  @ViewChildren('draftStatement') draftStatements: QueryList<DeliveryFormComponent>;
  @ViewChildren('valuation') valuations: QueryList<DeliveryFormComponent>;

  form: UntypedFormGroup;
  readonly: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeliveryTaskModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeliveryTaskModalData,
    private readonlyService: ReadonlyService,
    private tpTaskService: TpTaskService
  ) { 
  }

  ngOnInit(): void {
    this.readonly = this.readonlyService.isReadOnly();
  }

  close() {this.dialogRef.close();}

  save() {
    if (this.readonly) {
      this.dialogRef.close();
      return;
    }
    const tasks: any[] = [];
    const draftRes = this.draftStatements.map(ds => ds.save());
    const valRes = this.valuations.map(v => v.save());
    for (let i = 0; i < draftRes.length; i++) {
      if (draftRes[i].hasError) {
        return;
      } else {
        tasks.push(draftRes[i].task);
      }
    }
    for (let i = 0; i < valRes.length; i++) {
      if (valRes[i].hasError) {
        return;
      } else {
        tasks.push(valRes[i].task);
      }
    }
    this.tpTaskService.createOrUpdateDeliveryTasks(tasks).subscribe(res => {
      this.dialogRef.close({result: res})
    })
  }
}
