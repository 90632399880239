import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { select, Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { delay, filter, map, mergeMap, retryWhen, take, tap } from "rxjs/operators";
import { AssetClassDetailService } from "../../asset_class/_services/asset-class-detail.service";
import { AssetClassResidentialsService, AssetClassSizeModel } from "../../comparable";
import { selectAllStandardMeasurements, selectAllUnitAreaMeasurements, StandardMeasurement, UnitAreaMeasurement } from "../../linked-tables";
import { AppState } from "../../reducers";
import { CreateSizeCriterions, SizeCriterionActionTypes, SizeCriterionAddComparable, SizeCriterionAddMultipleComparables, SizeCriterionChangeDefault, SizeCriterionChangeValue, SizeCriterionComparableAdded, SizeCriterionComparableRemoved, SizeCriterionDefaultChanged, SizeCriterionEmptyAction, SizeCriterionRemoveComparable, SizeCriterionReset, SizeCriterionResetted, SizeCriterionsCreated, SizeCriterionsLoading, SizeCriterionValueChanged } from "../_actions/size_criterion.actions";
import { AssetType } from "../_models/asset-type.model";
import { CriterionModel } from "../_models/criterion.model";
import { selectCriterionStandardMeasurement, selectCriterionUnitAreaMeasurement, selectDefaultSizeCriterion, selectSizeCriterions } from "../_selectors/size_criterion.selectors";
import { SizeCriterionService } from "../_services/size_criterion.service";
import * as valuationActions from '../_actions/valuation.actions';

@Injectable()
export class SizeCriterionEffects {
    constructor(
        private actions$: Actions,
        private service: SizeCriterionService,
        private acDetService: AssetClassDetailService,
        private acResidentialService: AssetClassResidentialsService,
        private store: Store<AppState>
    ) {}

    @Effect()
    createSizeCriterions$ = this.actions$.pipe(
        ofType<CreateSizeCriterions>(SizeCriterionActionTypes.CreateSizeCriterions),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.select(selectAllStandardMeasurements).pipe(),
                this.store.pipe(
                    select(selectAllUnitAreaMeasurements),
                    map(res => {
                        if (res) {
                            return res.find(item => item.system_id === payload.toe.unit_of_measurement_id)
                        }
                    }),
                    filter(val => val === undefined ? false : true),),
                this.acDetService.fetchSubData(payload.assetClass.details.tp_id).pipe(map(res => res.sizes)),
            ])
        ),
        map(([payload, standardMeasurements, unitAreaMeasurement, sizes]) => {
            if (standardMeasurements.length === 0) {
                return new SizeCriterionsLoading();
            }
            let _assetType = '0';
            switch (payload.assetType) {
                case AssetType.Office: {
                    _assetType = '3';
                    break;
                }
                case AssetType.Residential: {
                    _assetType = '1';
                    break;
                }
                case AssetType.Warehouse: {
                    _assetType = '7';
                    break;
                }
                case AssetType.House: {
                    _assetType = '17';
                    break;
                }
                case AssetType.RetailShop: {
                    _assetType = '5';
                    break;
                }
                case AssetType.RetailBuilding: {
                    _assetType = '11';
                    break;
                }
                case AssetType.Parking: {
                    _assetType = '2';
                    break;
                }
            }
            const _standardMeasurements = standardMeasurements.filter(measurement => 
                measurement.asset_class_type_id.toString() == _assetType
            )
            const res = this.service.createCriterions(payload.assetClass, sizes, _standardMeasurements, unitAreaMeasurement);
            return new SizeCriterionsCreated({
                criterions: res.criterions,
                default: res.default,
                standardMeasurements: _standardMeasurements,
                unitAreaMeasurement: unitAreaMeasurement,
            });
        })
    )

    @Effect()
    addComparableSizeCriterions$ = this.actions$.pipe(
        ofType<SizeCriterionAddComparable>(SizeCriterionActionTypes.SizeCriterionAddComparable),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectSizeCriterions), take(1)),
                this.store.pipe(select(selectDefaultSizeCriterion), take(1)),
                this.store.pipe(select(selectCriterionStandardMeasurement), take(1)),
                this.store.pipe(select(selectCriterionUnitAreaMeasurement), take(1)),
                // this.acResidentialService.getSizesOfAc(payload.comparable.id),
            ])
        ),
        map(([payload, criterions, defaultCriterion, sms, um]) => {
            const res = this.service.addComparable(payload.comparable, {
                main: criterions,
                default: defaultCriterion,
            }, {
                sizes: payload.comparable.sizes,
                sms,
                um,
            })
            return new SizeCriterionComparableAdded({
                criterions: res.criterions,
                default: res.default
            })
        })
    )

    @Effect()
    addMultpleComparableSizeCriterions$ = this.actions$.pipe(
        ofType<SizeCriterionAddMultipleComparables>(SizeCriterionActionTypes.SizeCriterionAddMultipleComparables),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectSizeCriterions), take(1)),
                this.store.pipe(select(selectDefaultSizeCriterion), take(1)),
                this.store.pipe(select(selectCriterionStandardMeasurement), take(1)),
                this.store.pipe(select(selectCriterionUnitAreaMeasurement), take(1)),
            ])
        ),
        map(([payload, criterions, defaultCriterion, sms, um]) => {
            const sizesData: {[id: number]: {
                sizes: AssetClassSizeModel[],
                sms: StandardMeasurement[],
                um: UnitAreaMeasurement}
            } = {}
            payload.comparable.forEach(com => {
                sizesData[com.id] = {
                    sizes: com.sizes, sms, um
                };
            })
            const _criterions = {
                main: criterions,
                default: defaultCriterion
            };
            const res = this.service.addComparables( payload.comparable, _criterions, sizesData)
            return new SizeCriterionComparableAdded({
                criterions: res.criterions,
                default: res.default
            })
        })
        ,
        retryWhen(errors => 
            errors.pipe(
                delay(1000)
            )
        )
    )

    @Effect()
    removeComparableSizeCriterion$ = this.actions$.pipe(
        ofType<SizeCriterionRemoveComparable>(SizeCriterionActionTypes.SizeCriterionRemoveComparable),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload.comparableID),
                this.store.pipe(select(selectSizeCriterions), take(1)),
                this.store.pipe(select(selectDefaultSizeCriterion), take(1)),
            ])
        ),
        map(([id, criterions, defaultCriterion]) => {
            const res = this.service.removeComparable(id, {
                main: criterions,
                default: defaultCriterion
            });
            return new SizeCriterionComparableRemoved({
                criterions: res.criterions,
                default: res.default
            })
        })
    )

    @Effect()
    changeDefaultSizeCriterion$ = this.actions$.pipe(
        ofType<SizeCriterionChangeDefault>(SizeCriterionActionTypes.SizeCriterionChangeDefault),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectSizeCriterions), take(1)),
                this.store.pipe(select(selectDefaultSizeCriterion), take(1))
            ])
        ),
        map(([payload, criterions, defaultCriterion]) => {
            const _criteirons: Update<CriterionModel>[] = [];
            const _changedCriterion: Update<CriterionModel> = {
                id: payload.criterion.id,
                changes: {
                    ...payload.criterion
                }
            }
            _criteirons.push(_changedCriterion);


            const currentDefault = criterions.find(c => c.name === defaultCriterion.name);
            if (currentDefault && currentDefault.name !== payload.criterion.name) {
                const _changeCriterion: Update<CriterionModel> = {
                    id: currentDefault.id,
                    changes: {
                        active: false
                    }
                }
                _criteirons.push(_changeCriterion);
            }

            const _default = this.service._copyCriterion(payload.criterion);
            _default.id = 0;
            return new SizeCriterionDefaultChanged({
                changedCriterions: _criteirons,
                default: _default
            });
        })
    )

    @Effect()
    defaultSizeCriterionChanged$ = this.actions$.pipe(
        ofType<SizeCriterionDefaultChanged>(SizeCriterionActionTypes.SizeCriterionDefaultChanged),
        map(() => {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    resetSizeCriterion$ = this.actions$.pipe(
        ofType<SizeCriterionReset>(SizeCriterionActionTypes.SizeCriterionReset),
        map(({payload}) => {
            return {
                criterion: payload.criterion,
                assetClass: payload.assetClass,
                comparables: payload.comparables
            }
        }),
        mergeMap(({criterion, assetClass, comparables}) => 
            combineLatest([
                of(criterion),
                of(comparables),
                this.store.pipe(select(selectSizeCriterions), take(1)),
                this.store.pipe(select(selectCriterionUnitAreaMeasurement), take(1)),
                this.store.pipe(select(selectCriterionStandardMeasurement), take(1)),
                this.acDetService.fetchSubData(assetClass.details.tp_id).pipe(map(res => res.sizes)),
            ])),
        map(([criterion, comparables, sizeCriterions, um, sms, acSizes]) => {
            const comSizes: {[id: number]: any} = {};
            comparables.forEach(com => {
                comSizes[com.id] = com.sizes;
            });
            const res = this.service.reset(criterion, sizeCriterions, comSizes, acSizes, um, sms);
            res.defualt.isChanged = false;
            let _changed: Update<CriterionModel>
            if (res.change) {
                _changed = {
                    id: res.change.id,
                    changes: {
                        ...res.change,
                        isChanged: false,
                    }
                }
            }
            return new SizeCriterionResetted({
                default: res.defualt,
                changed: _changed
            })
        })
    )

    @Effect()
    sizeCriterionResetted$ = this.actions$.pipe(
        ofType<SizeCriterionResetted>(SizeCriterionActionTypes.SizeCriterionResetted),
        map(() => {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    sizeCriterionChangeValue$ = this.actions$.pipe(
        ofType<SizeCriterionChangeValue>(SizeCriterionActionTypes.SizeCriterionChangeValue),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectSizeCriterions), take(1)),
                this.store.pipe(select(selectDefaultSizeCriterion), take(1))
            ])
        ),
        map(([payload, criterions, defaultCriterion]) => {
            const _payload = {
                criterions: [],
                default: null
            };
            const _criterions = this.service._copyCriterions(criterions);
            const _crit = _criterions.find(c => c.id == payload.id);
            switch (payload.type) {
                case 'com':
                    _crit.comValues[payload.asset_id] = payload.value;
                    break;
                case 'tp':
                    _crit.tpValue = payload.value;
            }
            _payload.criterions = _criterions;

            if (defaultCriterion.name == _crit.name) {
                const _def = this.service._copyCriterion(defaultCriterion);
                switch (payload.type) {
                    case 'com':
                        _def.comValues[payload.asset_id] = payload.value;
                        break;
                    case 'tp':
                        _def.tpValue = payload.value;
                }
                _payload.default = _def;
            }

            return new SizeCriterionValueChanged(_payload);
        })
    )

    @Effect()
    sizeCriterionValueChanged$ = this.actions$.pipe(
        ofType<SizeCriterionValueChanged>(SizeCriterionActionTypes.SizeCriterionValueChanged),
        map(({payload}) => {
            if (payload.default) {
                return new valuationActions.UpdateValuationsExternalData();
            }
            return new SizeCriterionEmptyAction();
        })
    )
}