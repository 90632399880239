import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, take} from 'rxjs/operators';
import { AssetClassDetailModel, AssetClassModel, selectTpTasksByTpId } from 'src/app/core/asset_class';
import { AssignmentModel, AssignmentsService } from 'src/app/core/assignment';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { ToeModel, ToeReportService } from 'src/app/core/toe';
import { SupportingValuerModel } from 'src/app/core/toe/_models/supporting-valuer.model';

@Component({
  selector: 'kt-background-tab',
  templateUrl: './background-tab.component.html',
  styleUrls: ['./background-tab.component.scss']
})
export class BackgroundTabComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean = false;
  @Input() targetProperty: AssetClassModel;
  @Input() toe: ToeModel;
  @Input() tpDetails: AssetClassDetailModel;
  @Input() assignment: AssignmentModel;

  workers: SupportingValuerModel[] = [];
  subscriptions: Subscription[] = [];
  currentUser: User;
  valuerUserId: number;
  assignmentLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  valuerUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  essentialDataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showConditionRating: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store$: Store<AppState>,
    private assignmentService: AssignmentsService,
  ) { }

  ngOnInit(): void {
    if (this.targetProperty.type_id == 2 && this.targetProperty.details.sub_type_category_id != 20) {
      this.showConditionRating = false;
    }
    this.activatedRoute.data.subscribe(res => {
      this.workers = res.workersData.data;
    })

    const essentialDataSub = combineLatest([
      this.userLoaded$,
      this.valuerUser$,
    ]).subscribe(([userLoaded, valuerUserLoaded]) => {
      if(userLoaded && valuerUserLoaded) {
        this.valuerUserId = valuerUserLoaded.id;
        this.essentialDataLoaded$.next(true);
      }
    });
    this.subscriptions.push(essentialDataSub);

    const userSub = combineLatest([
      this.store$.pipe(take(1), select(currentUser)),
      this.store$.select(selectTpTasksByTpId(this.targetProperty.id)).pipe(map(val => val.length > 0 ? val : undefined), filter(val => val !== undefined)),
      this.activatedRoute.data.pipe(map(res => res.usersData.data ? res.usersData.data : undefined), filter(val => val !== undefined)),
      this.activatedRoute.params.pipe(map(params => params.valuation_id)),
    ]).subscribe(([user, tasks, toeUsers, valId]) => {
      const valuationTasks = tasks.filter(t => t.task_id === 2);
      const valuationTask = valuationTasks.find(t => t.second_id == valId);
      if (valuationTask === undefined) {
        this.valuerUser$.next(null);
      } else {
        const valuer = toeUsers.find(u => u.id === valuationTask.user_id);
        this.valuerUser$.next(valuer);
      }
      this.currentUser = user;
      this.userLoaded$.next(true);
    });
    this.subscriptions.push(userSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
