// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PaymentsState} from '../_reducers/payment.reducers';
import {PaymentModel} from '../_models/payment.model';

export const selectPaymentsState = createFeatureSelector<PaymentsState>('payments');

export const selectPaymentById = (paymentId: number) => createSelector(
    selectPaymentsState,
    paymentsState => paymentsState.entities[paymentId]
);

export const selectPaymentsPageLoading = createSelector(
    selectPaymentsState,
    paymentsState => paymentsState.listLoading
);

export const selectPaymentsActionLoading = createSelector(
    selectPaymentsState,
    paymentsState => paymentsState.actionLoading
);

export const selectLastCreatedPaymentId = createSelector(
    selectPaymentsState,
    paymentsState => paymentsState.lastCreatedPaymentId
);

export const selectPaymentsShowInitWaitingMessage = createSelector(
    selectPaymentsState,
    paymentsState => paymentsState.showInitWaitingMessage
);

export const selectPaymentsInStore = createSelector(
    selectPaymentsState,
    paymentsState => {
        const items: PaymentModel[] = [];
        each(paymentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: PaymentModel[] = [];
        if (paymentsState.lastQuery.sortField === 'updated_at') {
            result = httpExtension.sortArrayDateTime(
                items,
                paymentsState.lastQuery.sortField,
                paymentsState.lastQuery.sortOrder
            );
        } else {
            result = httpExtension.sortArray(
                items,
                paymentsState.lastQuery.sortField,
                paymentsState.lastQuery.sortOrder
            );
        }
        return new QueryResultsModel(result, paymentsState.totalCount, '', paymentsState.trashed);
    }
);


export const selectPaymentByToeId = (toeId: number) => createSelector(
    selectPaymentsState,
    paymentsState => {
        const items: PaymentModel[] = [];
        each(paymentsState.entities, element => {
            if (element.terms_of_engagement_id === toeId) {
                items.push(element);
            }
        });
        return items;
    }
);
