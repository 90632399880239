import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ResidentialAdjustmentsState } from "../_reducers/residential-adjustments.reducer";
import * as fromAdjustment from '../_reducers/residential-adjustments.reducer';
import { ResidentialAdjustmentModel } from "../_models/residential-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectResidentialAdjustmentsState = createFeatureSelector<ResidentialAdjustmentsState>('residential-adjustments');

export const selectResidentialAdjustmentById = (AdjustmentId: number) => createSelector(
    selectResidentialAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllResidentialAdjustments = createSelector(
    selectResidentialAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllResidentialAdjustmentsIds = createSelector(
    selectResidentialAdjustmentsState,
    fromAdjustment.selectIds
);

export const allResidentialAdjustmentsLoaded = createSelector(
    selectResidentialAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectResidentialAdjustmentsPageLoading = createSelector(
    selectResidentialAdjustmentsState,
    state => state.listLoading
);

export const selectResidentialAdjustmentsActionLoading = createSelector(
    selectResidentialAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedResidentialAdjustmentId = createSelector(
    selectResidentialAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectResidentialAdjustmentsShowInitWaitingMessage = createSelector(
    selectResidentialAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedResidentialAdjustmentCount = createSelector(
    selectResidentialAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedResidentialAdjustmentCount = createSelector(
    selectResidentialAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectResidentialAdjustmentQueryResult = createSelector(
    selectResidentialAdjustmentsState,
    state => {
        const items: ResidentialAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ResidentialAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);