<div class="card card-custom">
	<div class="card-body-progress card-body-progress-overlay">
		<mat-progress-bar mode="indeterminate" *ngIf="viewLoading$|async"></mat-progress-bar>
	</div>
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{headerTxt$|async}}</h3>
		</div>
	</div>
	<div class="form">
		<div class="card-body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					<ng-container *ngIf="(bodyTxt$|async) as bodyTxt">
						<span *ngIf="!bodyTxt.isError; else errorMessage">
							{{bodyTxt.msg}}
						</span>
						<ng-template #errorMessage>
							<span class="text-danger">{{bodyTxt.msg}}</span>
						</ng-template>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="card-footer b-0 p-0 text-right" [ngSwitch]="(state$|async)">
			<div class="form-actions form-actions-solid" *ngSwitchCase="componentState.Warning">
				<button mat-button (click)="onNoClick()">No</button>
				<button mat-button (click)="onYesClick()" color="primary" 
					cdkFocusInitial>Yes</button>
			</div>
			<div class="form-actions form-actions-solid" *ngSwitchCase="componentState.SavingToeError">
				<button mat-button (click)="onNoClick()">Close</button>
			</div>
			<div class="form-actions form-actions-solid" *ngSwitchCase="componentState.GeneratingToeReportError">
				<button mat-button (click)="onNoClick()">Close</button>
				<button mat-button (click)="generateReport()" color="primary" 
					cdkFocusInitial>Try again</button>
			</div>
			<div class="form-actions form-actions-solid" *ngSwitchDefault>
			</div>
		</div>
	</div>
</div>