import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {
    CountriesActionToggleLoading,
    CountryDeletedFromAdminTrash, CountryDeletedFromTrash,
    CountryOnServerAdminRestored, CountryOnServerRestored, CountryTrashFlushed,
    AllCitiesRequested,
    AllCountriesRequested, 
    CityModel,
    CountryDeleted,
    CountryModel,
    selectAllCountries, CountryService
} from '../../../../../core/admin';
import {debounceTime, delay, distinctUntilChanged, skip, take, takeUntil, tap} from 'rxjs/operators';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService} from '../../../../../core/_base/crud';
import {select, Store} from '@ngrx/store';
import {CountryEditComponent} from '../country-edit/country-edit.component';
import {ActivatedRoute} from '@angular/router';
import {SubheaderService} from '../../../../../core/_base/layout';
import {CityEditComponent} from '../cities/city-edit/city-edit.component';
import {BehaviorSubject, fromEvent, Observable, of, Subject} from 'rxjs';
import {selectAdminTrashedCountryCount, selectTrashedCountryCount} from '../../../../../core/admin/_selectors/country.selectors';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
type pmStatus = {
    total_countries: string,
    total_used_in_vals: string,
}

@Component({
    selector: 'kt-country-list',
    styleUrls: ['./countries-list.component.css'],
    templateUrl: './countries-list.component.html',
})
export class CountriesListComponent implements OnInit, AfterViewInit, OnDestroy {

    displayedColumns = ['name', 'vat', 'currency', 'city_cnt', 'vpga', 'status', 'actions'];
    countryData: CountryModel[] = [];
    dataSource = new MatTableDataSource(this.countryData);

    trashCnt$: Observable<number>;
    adminTrashCnt$: Observable<number>;

    @ViewChild('sort1', {static: true}) sort: MatSort;

    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    countryStatusCnts$ = new BehaviorSubject<pmStatus>({
        total_countries: '0',
        total_used_in_vals: '0',
    });
    currentUser: User | null
    private _onDestroy$ = new Subject<void>()

    constructor(private activatedRoute: ActivatedRoute,
                private store: Store<AppState>,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: CountryService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.subheaderService.setTitle('Countries');
        this.store.dispatch(new AllCountriesRequested());
        this.store.dispatch(new AllCitiesRequested());
        this.store.pipe(select(currentUser), takeUntil(this._onDestroy$)).subscribe(user => this.currentUser = user)
        this.store.pipe(
            skip(1),
            select(selectAllCountries),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.countryData = res;
            this.dataSource.data = res;

            this.countryStatusCnts$.next({
                total_countries: res.length.toString(),
                total_used_in_vals: res.reduce((total, item) => total + (item['used_times'] > 0 ? 1 : 0), 0).toString(),
            })
        });

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                const searchText: string = this.searchInput.nativeElement.value;

                let res = this.countryData;
                if (searchText && searchText != '') {
                    res = this.countryData.filter(value => value.name.toLowerCase().search(searchText.toLowerCase()) != -1);
                }

                this.dataSource.data = res;
            })
        )
            .subscribe();

        this.trashCnt$ = this.store.pipe(select(selectTrashedCountryCount));
        this.adminTrashCnt$ = this.store.pipe(select(selectAdminTrashedCountryCount));

    }

    ngOnDestroy(): void {
        this._onDestroy$.next()
        this._onDestroy$.complete()
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    resetFilter() {

        this.searchInput.nativeElement.value = '';
        this.dataSource.data = this.countryData;
    }

    addCity(_country: CountryModel) {
        const newCity = new CityModel();
        newCity.clear(); // Set all defaults fields
        newCity.country_id = _country.id;
        newCity.country_name = _country.name;
        const dialogRef = this.dialog.open(CityEditComponent, {data: {city: newCity}});
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            // this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 5000, true, false);
            of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
                this.store.dispatch(new AllCountriesRequested());
            });
        });
    }

    addCountry() {
        const newCountry = new CountryModel();
        newCountry.clear(); // Set all defaults fields
        this.editCountry(newCountry);
    }

    editCountry(country: CountryModel) {
        const dialogRef = this.dialog.open(CountryEditComponent, {data: {id: country.id}});
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
        });
    }

    deleteCountry(_item: CountryModel) {
        const _title = this.translate.instant('COUNTRY.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('COUNTRY.LIST.DIALOG.DELETE.DESCRIPTION', {
            name: _item.name
        });
        const _waitDescription = this.translate.instant('COUNTRY.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDescription);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new CountryDeleted({id: _item.id}));
        });
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new CountriesActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new CountriesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    deletedUser: elem.userDeletedBy,
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new CountriesActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new CountriesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    deletedUser: elem.deletedBy,
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('COUNTRY.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('COUNTRY.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new CountryOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new CountryOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new CountryDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new CountryDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new CountryTrashFlushed());
    }

    openPopover(popover) {
        if (popover.isOpen()) {
            popover.close();
        } else {
            popover.open();
        }
    }
}
