import { Component, Input, OnInit } from '@angular/core';
import { CFMLastTerm } from '../../../../cf-method-types';

@Component({
  selector: 'kt-last-term-table',
  templateUrl: './last-term-table.component.html',
  styleUrls: ['./last-term-table.component.scss', '../../../headline-to-effective.component.scss']
})
export class LastTermTableComponent implements OnInit {
  @Input() data: CFMLastTerm;
  @Input() growthRate: number;
  @Input() targetRate: number;
  @Input() remainingYears: number;
  @Input() term: number;
  @Input() rentReview: number;

  constructor() { }

  ngOnInit(): void {}

}
