import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AssetClassDetailService } from '../../../../core/asset_class/_services/asset-class-detail.service';

@Injectable()
export class AssetClassDetailSubDataResolver implements Resolve<any> {
    constructor(public assetClassDetailService: AssetClassDetailService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const assetClassId = Number(route.params.asset_class_id);
        return this.assetClassDetailService.fetchSubData(assetClassId);
    }
}
