<div class="row">
    <div class="col-12">
        <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
            <div>
                <button type="button" class="btn btn-primary" color="primary" mat-raised-button (click)="addItem()">
                    Add other VPO
                </button>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="example-container mat-elevation-z0">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="vpo_name">
                    <mat-header-cell *matHeaderCellDef>VPO Name</mat-header-cell>
                    <mat-cell *matCellDef="let item"> {{ item.name }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="membership_id">
                    <mat-header-cell *matHeaderCellDef>Membership ID</mat-header-cell>
                    <mat-cell *matCellDef="let item"> {{item.membership_id}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="prime_reference">
                    <mat-header-cell *matHeaderCellDef>Prime Reference</mat-header-cell>
                    <mat-cell *matCellDef="let item; let i = index">
                        <mat-checkbox [checked]="item.is_prime_ref" (change)="changeIsPrime(i, $event)"></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item; let i = index">
                        <button mat-icon-button type="button" color="primary"
                            [matTooltip]="'Edit Item'"
                            (click)="editItem(item, i)" type="button">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;
                        <button mat-icon-button type="button" color="warn"
                            [matTooltip]="'Delete Item'"
                            (click)="deleteItem(i)" type="button">
                            <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <!-- <mat-error
                    *ngIf="hasFormErrors && userForm.controls.qualification_id.value == 1 &&  certificates.value.length < 1"
                    [innerHtml]="'USER.FORM.FORM_FIELD.VALIDATION.REQUIRED' | translate"
            >
            </mat-error> -->
        </div>
    </div>
</div>