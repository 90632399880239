import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {PropertyTypeUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import {
    PropertyType,
    PropertyTypeOnServerCreated,
    selectPropertyTypesActionLoading,
    selectPropertyTypeById,
    selectLastCreatedPropertyTypeId,
    AssetClassType,
    AllAssetClassTypesRequested,
    selectAllAssetClassTypes
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';
import {delay} from 'rxjs/operators';

@Component({
    selector: 'kt-property-type-edit',
    templateUrl: './property-type-edit.component.html',
    styleUrls: ['./property-type-edit.component.scss'],
})


export class PropertyTypeEditComponent implements OnInit, OnDestroy {

    model: PropertyType;
    oldModel: PropertyType;

    propertyTypeForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    assetClassTypes: AssetClassType[] = [];
    mode = 'NEW';
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectPropertyTypesActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectPropertyTypeById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initPropertyType();
                    }
                });
            } else {
                this.model = new PropertyType();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initPropertyType();
            }
        });
        this.subscriptions.push(routeSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initPropertyType() {
        this.createForm();
    }


    createForm() {
        this.propertyTypeForm = this.fb.group({
            name: [this.model.name, Validators.required],
            asset_class_type_id: [this.model.asset_class_type_id, Validators.required],
        });

        this.store.dispatch(new AllAssetClassTypesRequested());

        const valuationStandardSubscription = this.store.pipe(
            select(selectAllAssetClassTypes))
            .subscribe(res => {
                this.assetClassTypes = [];
                if (res) {
                    this.assetClassTypes = res.filter(item => item.hide_flag == 0);
                }
            });

        this.subscriptions.push(valuationStandardSubscription);
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return `Edit Property Type '${
                this.model.name
                }'`;
        }

        return 'New Property Type';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.propertyTypeForm.controls[controlName];
        return control.invalid && control.touched;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.propertyTypeForm.markAsPristine();
        this.propertyTypeForm.markAsUntouched();
        this.propertyTypeForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.propertyTypeForm.controls;
        /** check form */
        if (this.propertyTypeForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        this.model.name = this.propertyTypeForm.controls.name.value;
        this.model.asset_class_type_id = this.propertyTypeForm.controls.asset_class_type_id.value;

        if (this.model.id > 0) {
            this.updatePropertyType(this.model);
        } else {
            this.createPropertyType(this.model);
        }
    }

    /**
     * Update model
     *
     * @param _model: PropertyTypeModel
     */
    updatePropertyType(_model: PropertyType) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updatePropertyType: Update<PropertyType> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new PropertyTypeUpdated({
            partialItem: updatePropertyType,
            item: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();

    }

    /**
     * Create model
     *
     * @param _model: PropertyTypeModel
     */
    createPropertyType(_model: PropertyType) {
        this.store.dispatch(new PropertyTypeOnServerCreated({item: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedPropertyTypeId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0
            && this.model.asset_class_type_id);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }


    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name &&
                this.model.asset_class_type_id != this.oldModel.asset_class_type_id;
        }
        return false;
    }

}
