<div class="mat-table-wrapper {{className}}">
    <table class="table">
        <thead>
            <th>
                <span *ngIf="parent != 'inspection'">
                    Floor/Code
                </span>
            </th>
            <th *ngFor="let component of _componentDataSource">
                <div>
                    <span>
                        {{component.title}}
                    </span>
                    <ng-container *ngIf="parent == 'inspection'">
                        <ng-container *ngIf="component.description">
                            <mat-icon
                                style="cursor: pointer;"
                                container="body"
                                [ngbPopover]="component.description"
                                [popoverTitle]="component.name"
                                >
                                help
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!component.description">
                            <mat-icon
                                style="cursor: pointer;"
                                container="body"
                                [ngbPopover]="component.name"
                                >
                                help
                            </mat-icon>
                        </ng-container>
                    </ng-container>
                </div>
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let conditionRating of _conditionRatingDataSource">
                <td [ngClass]="{'bg-light-gray': parent != 'inspection'}">
                    <ng-container *ngTemplateOutlet="floor; context:{floor: conditionRating.floor}"></ng-container>
                </td>
                <ng-container *ngIf="parent == 'inspection'">
                    <td *ngFor="let component of _componentDataSource"
                        [ngClass]="{
                            'not-applicable': conditionRating.ratings[component.key].value === 'N/A',
                            'not-inspected': conditionRating.ratings[component.key].value === 'N/I',
                            'rate-1': conditionRating.ratings[component.key].value === 'Rate 1',
                            'rate-2': conditionRating.ratings[component.key].value === 'Rate 2',
                            'rate-3': conditionRating.ratings[component.key].value === 'Rate 3'
                        }">
                        <div *ngIf="editable" class="table-cell">
                            <mat-select 
                                class="rating-select"
                                [value]="conditionRating.ratings[component.key].value"
                                (selectionChange)="onSelectionChange($event, component.key, conditionRating.floor)"
                                >
                                <mat-option *ngFor="let rating of ratings" 
                                    [value]="rating"
                                    >
                                    {{rating}}
                                </mat-option>
                            </mat-select>
                            <div class="edit-rating">
                                <button 
                                    *ngIf="conditionRating.ratings[component.key].value !== 'N/A'"
                                    type="button" 
                                    mat-icon-button
                                    (click)="editRating(conditionRating.ratings[component.key], component, conditionRating.floor)"
                                    >
                                    <mat-icon style="color: white">edit</mat-icon>
                                </button>
                                <button
                                    *ngIf="conditionRating.ratings[component.key].pictures.length > 0"
                                    class="badge-button"
                                    type="button"
                                    [ngbPopover]="popoverContent"
                                    [popoverTitle]="popoverTitle"
                                    triggers="manual"
                                    #pop="ngbPopover"
                                    (click)="togglePicturePopover(pop, conditionRating.ratings[component.key], conditionRating.floor, component.title)"
                                    >
                                    {{conditionRating.ratings[component.key].pictures.length}}
                                </button>
                            </div>
                        </div>
                        <div *ngIf="!editable" class="table-cell non-editable">
                            <span>{{conditionRating.ratings[component.key].value}}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container *ngIf="parent != 'inspection'">
                    <td *ngFor="let component of _componentDataSource"
                        (click)="onSelect(conditionRating.ratings[component.key], component)"
                        [ngClass]="{
                            'not-applicable': conditionRating.ratings[component.key].value === 'N/A',
                            'not-inspected': conditionRating.ratings[component.key].value === 'N/I',
                            'rate-1': conditionRating.ratings[component.key].value === 'Rate 1',
                            'rate-2': conditionRating.ratings[component.key].value === 'Rate 2',
                            'rate-3': conditionRating.ratings[component.key].value === 'Rate 3'
                        }">
                        <div class="cell">
                            <div class="info-wrapper">
                                <div class="badge bg-blue" [matTooltip]="conditionRating.ratings[component.key].description" *ngIf="conditionRating.ratings[component.key].description">
                                    i
                                </div>
                            </div>
                            <div class="info-wrapper">
                                {{conditionRating.ratings[component.key].value}}
                            </div>
                            <div class="info-wrapper">
                                <div class="badge bg-black" *ngIf="conditionRating.ratings[component.key].pictures.length > 0">
                                    {{conditionRating.ratings[component.key].pictures.length}}
                                </div> 
                            </div>
                        </div>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
    <ng-container *ngIf="parent != 'inspection'">
        <div *ngIf="showDetail" class="form-group row margin-top-2 text-center item-center">
            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                <h6 class="my-3 text-mad">
                    {{selectedComponent.title + ' - ' + selectedComponent.name + (selectedComponent.description ? ('(' + selectedComponent.description + ')') : '')}}
                </h6>
            </div>
        </div>

        <div *ngIf="showDetail" class="form-group row margin-top-2 text-center item-center px-4">
            <div 
                class="col-lg-12 kt-margin-bottom-20-mobile" 
                [ngClass]="{
                            'not-applicable': selectedConditionaRating.value === 'N/A',
                            'not-inspected': selectedConditionaRating.value === 'N/I',
                            'rate-1': selectedConditionaRating.value === 'Rate 1',
                            'rate-2': selectedConditionaRating.value === 'Rate 2',
                            'rate-3': selectedConditionaRating.value === 'Rate 3'
                        }"
            >
                <!-- <h6 class="my-3 text-mad text-white">{{'Rating ' + selectedConditionaRating.rating + ' - ' + getRatingDesc(selectedConditionaRating.value)}}</h6> -->
                <h6 class="my-3 text-mad text-white">{{getRatingDesc(selectedConditionaRating.value)}}</h6>
            </div>
        </div>

        <div *ngIf="showDetail && selectedConditionaRating.pictures.length > 0" class="form-group row margin-top-2 text-center item-center px-4">
            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                <kt-mm-pictures-module [tpDetails]="" [conditionRatingPictures$]="ratingPics$" [pictureType]="'rating'" [useMatDialog]="useMatDialog"></kt-mm-pictures-module>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #popoverTitle let-rating="rating" let-floor="floor" let-component_title="component_title">
    {{rating.pictures.length}} Picture{{rating.pictures.length > 1 ? 's' : ''}} associated to {{component_title}} (Floor: {{floor}})
</ng-template>
<ng-template #popoverContent let-rating="rating">
    <div class="pictures_container">
        <span *ngFor="let picture of rating.pictures" class="picture_container">
            <img [src]="basePath + picture.path + '/' + picture.name" (error)="imgOnError($event)">
        </span>
    </div>
</ng-template>

<div *ngIf="editable" class="legends">
    <div class="legend">
        <div class="color-block not-applicable"></div>
        <p><strong>Not Applicable</strong></p>
    </div>
    <div class="legend">
        <div class="color-block not-inspected"></div>
        <p><strong>Not Inspected</strong></p>
    </div>
    <div class="legend">
        <div class="color-block rate-1"></div>
        <p><strong>Rate 1</strong> - No repair is currently needed. The property must be maintained in the normal way</p>
    </div>
    <div class="legend">
        <div class="color-block rate-2"></div>
        <p><strong>Rate 2</strong> - Defects that need repairing or replacing but are not considered to be either serious or urgent. The property must be maintained in the normal way</p>
    </div>
    <div class="legend">
        <div class="color-block rate-3"></div>
        <p><strong>Rate 3</strong> - Defects that are serious and/or need to be repaired, replaced or investigated urgently</p>
    </div>
</div>

<ng-template #floor let-floor="floor">
    <ng-container *ngIf="schemeId == 1">
        <span *ngIf="floor < 0">
            Lower Ground ({{floor}})
        </span>
        <span *ngIf="floor == 0">
            Ground
        </span>
        <span *ngIf="floor > 0">
            Floor {{floor}}
        </span>
    </ng-container>
    <ng-container *ngIf="schemeId == 2">
        <span *ngIf="floor < 0">
            Lower Ground ({{floor}}) 
        </span>
        <span *ngIf="floor == 1">
            Floor 1 (Ground) 
        </span>
        <span *ngIf="floor > 1">
            Floor {{floor}}
        </span>
    </ng-container>
</ng-template>