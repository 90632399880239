import {BaseModel} from '../../_base/crud';

export class ToeInvoicePayment extends BaseModel {
    id: number;
    toe_id: number;
    invoice_id: number;
    payment_type_id: number;
    payment_date: Date;
    amount: number;
    comment: string;
    last_updated_by: any; 

    invoice: any;
    payment_type: any;

    created_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.toe_id = undefined;
        this.invoice_id = undefined;
        this.payment_type_id = undefined;
        this.payment_date = new Date();
        this.amount = 0;
        this.comment = '';

        this.last_updated_by = undefined;

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
