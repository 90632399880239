import {AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {
    KeyPlacesPageRequested,
    KeyPlaceDataSource,
    KeyPlaceDeleted,
    KeyPlaceModel,
    selectTrashedKeyPlaceCount,
    KeyPlaceActionToggleLoading,
    KeyPlaceService,
    KeyPlaceOnServerAdminRestored,
    KeyPlaceOnServerRestored,
    KeyPlaceDeletedFromTrash,
    KeyPlaceDeletedFromAdminTrash,
    KeyPlaceTrashFlushed, selectAdminTrashedKeyPlaceCountId,
} from '../../../../../core/admin';
import {debounceTime, delay, distinctUntilChanged, map, take, tap} from 'rxjs/operators';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../../core/_base/layout';
import {BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {MapsAPILoader} from '@agm/core';
import {AllKeyCategoriesRequested, KeyCategoryModel, selectAllKeyCategories} from '../../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';
import { awConst } from 'src/app/app.constants';
import { ToMarkerItemConverterService } from '../../../shared_components/map-marker-info-window/to-marker-item-converter-service.service';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput } from '../../../shared_components/map-marker-info-window/map-marker-info-window.component';
import { emptyFilter, FilterModel, FilterChange  } from '../../../shared_components/filter-section/filter-section.component';
import { KeyPlacesStatusRequested } from 'src/app/core/admin/_actions/key-place.actions';
import { selectKeyPlacesStatus } from 'src/app/core/admin/_selectors/key-place.selectors';
import { User } from 'src/app/core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import { MapsService } from 'src/app/core/map/maps.service';
type pmStatus = {
    total_lms: string,
    total_different_locations: string,
    total_used_in_vals: string,
}

@Component({
    selector: 'kt-key-places-list',
    styleUrls: ['./key-places-list.component.scss'],
    templateUrl: './key-places-list.component.html',
})

export class KeyPlacesListComponent implements OnInit, AfterViewInit, OnDestroy {

    dataSource: KeyPlaceDataSource;
    landMarkResult: KeyPlaceModel[] = [];
    allCategories: KeyCategoryModel[] = [];
    filterType: number;
    trashCnt$: Observable<number>;
    adminTrashCnt$: Observable<number>;
    baseMediaUrl = environment.baseMediaUrl;
    awConst = awConst;

    displayedColumns = ['id', 'name', 'city_of_location', 'description', 'category_name', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;

    // Filter fields

    // Mode
    mapText: string;
    listText: string;
    listViewMode = true;

    // map
    map: any;
    latLngSW: google.maps.LatLng;
    latLngNE: google.maps.LatLng;
    previous;
    selectedId = 0;
    zoom = 15;
    private _centerLat$ = new BehaviorSubject<number>(null)
    centerLat$ = combineLatest([
        this._centerLat$,
        this.mapsService.latitude$
    ]).pipe(map(([initial, def]) => {
        if (initial == null) {
            return def
        }
        return initial
    }))
    private _centerLng$ = new BehaviorSubject<number>(null)
    centerLng$ = combineLatest([
        this._centerLng$,
        this.mapsService.longitude$
    ]).pipe(map(([initial, def]) => {
        if (initial == null) {
            return def
        }
        return initial
    }))
    currentCenter: { lat: 0, lng: 0 };

    _north = new BehaviorSubject<number>(47.92399006317647);
    _south = new BehaviorSubject<number>(47.9096089375195);
    _east = new BehaviorSubject<number>(106.9476215928344);
    _west = new BehaviorSubject<number>(106.8833344071655);

    filterModel: FilterModel = emptyFilter();
    filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
    filterObs$ = this.filter$.asObservable();
    isFirst = true;
    mapAction = new BehaviorSubject<boolean>(true);
    lmStatusCnts$ = this.store.select(selectKeyPlacesStatus);

    // Subscriptions
    currentUser: User | null
    subscriptions: Subscription[] = [];

    constructor(private activatedRoute: ActivatedRoute,
                private store: Store<AppState>,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public subheaderService: SubheaderService,
                public keyPlaceService: KeyPlaceService,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private toMarkerItemService: ToMarkerItemConverterService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private mapsService: MapsService) {
        this.mapText = this.translate.instant('LANDMARK.LIST.VIEW.MAP');
        this.listText = this.translate.instant('LANDMARK.LIST.VIEW.LIST');
    }

    ngOnInit() {
        const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
        this.subscriptions.push(userSub)

        this.subheaderService.setTitle('KeyPlaces');

        this.store.dispatch(new KeyPlacesStatusRequested());
        this.dataSource = new KeyPlaceDataSource(this.store);

        const entitiesSubscription = this.dataSource.entitySubject.subscribe(res => {
            if (res && res.length > 0) {
                this.landMarkResult = res;
            }
        });
        this.subscriptions.push(entitiesSubscription);

        this.trashCnt$ = this.store.pipe(
            select(selectTrashedKeyPlaceCount)
        );

        this.adminTrashCnt$ = this.store.pipe(
            select(selectAdminTrashedKeyPlaceCountId)
        );

        this.subheaderService.setTitle('KeyPlaces');
        this.paginator._intl.itemsPerPageLabel = this.translate.instant('GENERAL.PAGELABEL');
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.store.dispatch(new AllKeyCategoriesRequested());
        const categorySubscription = this.store.pipe(select(selectAllKeyCategories))
            .subscribe((res: KeyCategoryModel[]) => {
                this.allCategories = res;
            });

        this.subscriptions.push(categorySubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000))
            .subscribe(() => { // Remove this line, just loading imitation
                this.loadList();
            });
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
    }

    toggleView() {
        this.listViewMode = !this.listViewMode;
        if (this.listViewMode) {
            const _new = Object.assign({}, this.filterModel) as FilterModel;
            // _new.max_lat = 0;
            // _new.max_lng = 0;
            // _new.min_lat = 0;
            // _new.min_lng = 0;

            // _new.sw_lat = 0;
            // _new.ne_lat = 0;
            // _new.sw_lng = 0;
            // _new.ne_lng = 0;

            this.filterModel = _new;
        }
        // this.resetFilter();
    }

    onFilterChange(event: FilterChange) {
        if (event.page != null) {
            this.paginator.pageIndex = event.page
        }
        this.filterModel = event.filter;
        this.loadList();
    }

    /**
     * Load KeyPlaces List from service through data-source
     */
    loadList() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        // Call request from server

        this.store.dispatch(new KeyPlacesPageRequested({page: queryParams}));
    }

    filterConfiguration(): any {
        const _new = Object.assign({}, this.filterModel) as FilterModel;
        this.filterModel = _new;
        return this.filterModel;
    }

    selectCategory(e) {
        this.loadList();
    }

    addKeyPlace() {
        const newKeyPlace = new KeyPlaceModel();
        newKeyPlace.clear(); // Set all defaults fields
        this.editKeyPlace(newKeyPlace);
    }

    editKeyPlace(_keyPlace: KeyPlaceModel) {
        if (_keyPlace.id) {
            this.router.navigate(['../key-places/edit', _keyPlace.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['../key-places/add'], {relativeTo: this.activatedRoute});
        }
    }

    deleteKeyPlace(_item: KeyPlaceModel) {
        const _title = this.translate.instant('LANDMARK.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('LANDMARK.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
        const _waitDesciption = this.translate.instant('LANDMARK.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new KeyPlaceDeleted({id: _item.id}));
        });
    }

    /************   trash functions     ************/

    // Fetch trash data
    trash() {
        this.store.dispatch(new KeyPlaceActionToggleLoading({isLoading: true}));
        this.keyPlaceService.getTrashedKeyPlaces().subscribe(res => {

            this.store.dispatch(new KeyPlaceActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy,
                    date: elem.user_deleted
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new KeyPlaceActionToggleLoading({isLoading: true}));
        this.keyPlaceService.getAdminTrashedKeyPlaces().subscribe(res => {

            this.store.dispatch(new KeyPlaceActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.deletedBy,
                    date: elem.deleted_at
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     */
    show_trash(items: any[], isAdmin = false) {
        const _title = 'Deleted Landmark';
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new KeyPlaceOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new KeyPlaceOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new KeyPlaceDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new KeyPlaceDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new KeyPlaceTrashFlushed());
    }


    /*          MAP Functions       */
    mapReady(event: any) {

        // agmMap
        this.map = event;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));


        this.map.addListener('dragend', () => {
            this._centerLat$.next(this.currentCenter.lat);
            this._centerLng$.next(this.currentCenter.lng);


            this.mapAction.next(true);
            this.paginator.pageIndex = 0;
            this.loadList();
        });
    }

    centerChange($event: any) {
        if ($event) {
            this.currentCenter = {lat: $event.lat, lng: $event.lng};
        }
    }

    boundsChanged(_latLngBound) {
        this.latLngNE = _latLngBound.getNorthEast();
        this.latLngSW = _latLngBound.getSouthWest();


        const xPadding = (this.latLngNE.lat() - this.latLngSW.lat()) * 0.08;
        this._north.next(this.latLngNE.lat() - xPadding);
        this._south.next(this.latLngSW.lat() + xPadding);

        this._east.next(this.latLngNE.lng() - xPadding);
        this._west.next(this.latLngSW.lng() + xPadding);
        const _new = Object.assign({}, this.filterModel) as FilterModel;
        if (_new.bounds == null) {
            _new.bounds = {ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0}
        } else {
            _new.bounds = Object.assign({}, _new.bounds);
        }
        _new.bounds.sw_lat = _latLngBound.getSouthWest().lat();
        _new.bounds.ne_lat = _latLngBound.getNorthEast().lat();
        _new.bounds.sw_lng = _latLngBound.getSouthWest().lng();
        _new.bounds.ne_lng = _latLngBound.getNorthEast().lng();

        this.filterModel = _new;
        if (this.isFirst) {
            this.mapAction.next(true);
            this.isFirst = !this.isFirst;
            this.paginator.pageIndex = 0;
            this.loadList();
        }
    }

    zoomChanged($event: any) {
        this.isFirst = true;
    }

    showInfo(mId, l) {
        this.selectedId = mId;

        const data = this.toMarkerItemService.convert('landmark', [l]);
        this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
            data: {
                modal_title: data.modal_title,
                entities: data.entities
            }
        });
    }

    setLocation() {
        this.setCurrentLocation();
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    this._centerLat$.next(position.coords.latitude);
                    this._centerLng$.next(position.coords.longitude);
                    this.zoom = 15;
                }
            });
        } else {
            alert('Position not available');
        }
    }

    getIcon(landmark) {

        const _cat = this.allCategories.find(el => el.id == landmark.category_id);

        if (_cat && _cat.icon && _cat.icon.length > 0) {
            return {
                labelOrigin: {x: 18, y: 22},
                url: environment.baseApiUrl + _cat.icon,
                scaledSize: {
                    width: 44,
                    height: 44
                }
            };
        }
        return {
            labelOrigin: {x: 18, y: 22},
            url: './assets/media/icons/pin_star.svg',
            scaledSize: {
                width: 44,
                height: 44
            }
        };
    }

    mapClick() {
        if (this.previous) {
            this.previous.close();
        }
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND
    }
    countUnique(iterable) {
        return new Set(iterable).size;
    }
}
