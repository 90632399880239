// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SubTypeCategoryService} from '../_services/sub-type-categories.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSubTypeCategorysLoaded} from '../_selectors/sub-type-category.selectors';
// Actions
import {
    AllSubTypeCategorysLoaded,
    AllSubTypeCategorysRequested,
    SubTypeCategoryActionTypes,
    SubTypeCategorysPageRequested,
    SubTypeCategorysPageLoaded,
    SubTypeCategoryUpdated,
    SubTypeCategorysPageToggleLoading,
    SubTypeCategoryDeleted,
    SubTypeCategoryOnServerCreated,
    SubTypeCategoryCreated,
    SubTypeCategoryActionToggleLoading, SubTypeCategoryTrashFlushed, SubTypeCategoryOnServerRestored, SubTypeCategoryRestored,
    SubTypeCategoryOnServerAdminRestored, SubTypeCategoryAdminRestored, SubTypeCategoryDeletedFromTrash, SubTypeCategoryDeletedFromAdminTrash
} from '../_actions/sub-type-category.actions';

@Injectable()
export class SubTypeCategoryEffects {
    showPageLoadingDispatcher = new SubTypeCategorysPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SubTypeCategoryActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SubTypeCategoryActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSubTypeCategory$ = this.actions$
        .pipe(
            ofType<AllSubTypeCategorysRequested>(SubTypeCategoryActionTypes.AllSubTypeCategorysRequested),
            withLatestFrom(this.store.pipe(select(allSubTypeCategorysLoaded))),
            filter(([action, isAllSubTypeCategoryLoaded]) => !isAllSubTypeCategoryLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllSubTypeCategorysLoaded({items: res.data});
            })
        );

    @Effect()
    loadSubTypeCategoryPage$ = this.actions$
        .pipe(
            ofType<SubTypeCategorysPageRequested>(SubTypeCategoryActionTypes.SubTypeCategorysPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SubTypeCategorysPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryUpdated>(SubTypeCategoryActionTypes.SubTypeCategoryUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryOnServerCreated>(SubTypeCategoryActionTypes.SubTypeCategoryOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new SubTypeCategoryCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushSubTypeCategoryTrash$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryTrashFlushed>(SubTypeCategoryActionTypes.SubTypeCategoryTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryOnServerRestored>(SubTypeCategoryActionTypes.SubTypeCategoryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SubTypeCategoryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryOnServerAdminRestored>(SubTypeCategoryActionTypes.SubTypeCategoryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SubTypeCategoryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryDeleted>(SubTypeCategoryActionTypes.SubTypeCategoryDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryDeletedFromTrash>(SubTypeCategoryActionTypes.SubTypeCategoryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSubTypeCategory$ = this.actions$
        .pipe(
            ofType<SubTypeCategoryDeletedFromAdminTrash>(SubTypeCategoryActionTypes.SubTypeCategoryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: SubTypeCategoryService, private store: Store<AppState>) {
    }
}
