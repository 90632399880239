// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {PlaningStatus} from '../_models/planing-status.model';

export enum PlaningStatusActionTypes {

    AllPlaningStatusesRequested = '[PlaningStatuses Home Page] All PlaningStatuses Requested',
    AllPlaningStatusesLoaded = '[PlaningStatuses API] All PlaningStatuses Loaded',

    PlaningStatusOnServerCreated = '[Edit PlaningStatus Dialog] PlaningStatus On Server Created',
    PlaningStatusCreated = '[Edit PlaningStatuses Dialog] PlaningStatuses Created',
    PlaningStatusUpdated = '[Edit PlaningStatus Dialog] PlaningStatus Updated',
    PlaningStatusDeleted = '[PlaningStatuses List Page] PlaningStatus Deleted',

    PlaningStatusesPageRequested = '[PlaningStatuses List Page] PlaningStatuses Page Requested',
    PlaningStatusesPageLoaded = '[PlaningStatuses API] PlaningStatuses Page Loaded',
    PlaningStatusesPageCancelled = '[PlaningStatuses API] PlaningStatuses Page Cancelled',

    PlaningStatusesPageToggleLoading = '[PlaningStatuses page] PlaningStatuses Page Toggle Loading',
    PlaningStatusesActionToggleLoading = '[PlaningStatuses] PlaningStatuses Action Toggle Loading',

    // trash system

    PlaningStatusOnServerRestored = '[PlaningStatus Trash] PlaningStatus On Server Restored',
    PlaningStatusRestored = '[PlaningStatus Trash] PlaningStatus Restored',

    PlaningStatusOnServerAdminRestored = '[PlaningStatus Admin Trash] PlaningStatus On Server Restored',
    PlaningStatusAdminRestored = '[PlaningStatus Admin Trash] PlaningStatus Restored',

    PlaningStatusDeletedFromTrash = '[PlaningStatus Trash] PlaningStatus deleted',
    PlaningStatusDeletedFromAdminTrash = '[PlaningStatus Admin Trash] PlaningStatus deleted',

    PlaningStatusTrash = 'PlaningStatus Trashed',
    PlaningStatusTrashFlushed = 'PlaningStatus Trash Flushed',
}

export class PlaningStatusOnServerCreated implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusOnServerCreated;

    constructor(public payload: { planingStatus: PlaningStatus}) {
    }
}

export class PlaningStatusCreated implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusCreated;

    constructor(public payload: { planingStatus: PlaningStatus }) {
    }
}

export class PlaningStatusUpdated implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusUpdated;

    constructor(public payload: {
        partialPlaningStatus: Update<PlaningStatus>,
        planingStatus: PlaningStatus
    }) {
    }
}

export class PlaningStatusDeleted implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class PlaningStatusesPageRequested implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class PlaningStatusesPageLoaded implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusesPageLoaded;

    constructor(public payload: { planingStatuses: PlaningStatus[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class PlaningStatusesPageCancelled implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusesPageCancelled;
}

export class AllPlaningStatusesRequested implements Action {
    readonly type = PlaningStatusActionTypes.AllPlaningStatusesRequested;
}

export class AllPlaningStatusesLoaded implements Action {
    readonly type = PlaningStatusActionTypes.AllPlaningStatusesLoaded;

    constructor(public payload: { planingStatuses: PlaningStatus[] }) {
    }
}

export class PlaningStatusesPageToggleLoading implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PlaningStatusesActionToggleLoading implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class PlaningStatusDeletedFromAdminTrash implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class PlaningStatusDeletedFromTrash implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PlaningStatusOnServerRestored implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PlaningStatusRestored implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusRestored;

    constructor(public payload: { item: PlaningStatus }) {
    }
}

export class PlaningStatusOnServerAdminRestored implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PlaningStatusAdminRestored implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusAdminRestored;

    constructor(public payload: { item: PlaningStatus }) {
    }
}

export class PlaningStatusTrashFlushed implements Action {
    readonly type = PlaningStatusActionTypes.PlaningStatusTrashFlushed;

    constructor() {
    }
}

export type PlaningStatusActions = PlaningStatusCreated
    | PlaningStatusUpdated
    | PlaningStatusDeleted
    | PlaningStatusesPageRequested
    | PlaningStatusesPageLoaded
    | PlaningStatusesPageCancelled
    | AllPlaningStatusesLoaded
    | AllPlaningStatusesRequested
    | PlaningStatusOnServerCreated
    | PlaningStatusesPageToggleLoading
    | PlaningStatusesActionToggleLoading
    | PlaningStatusDeletedFromAdminTrash
    | PlaningStatusDeletedFromTrash
    | PlaningStatusOnServerRestored
    | PlaningStatusRestored
    | PlaningStatusOnServerAdminRestored
    | PlaningStatusAdminRestored
    | PlaningStatusTrashFlushed;
