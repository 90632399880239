import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss']
})
export class TimeFieldComponent implements OnInit, OnDestroy {
  private triggerElementRef: ElementRef;
  public selectedHour: number;
  public selectedMinute: number;

  constructor(
    public dialogRef: MatDialogRef<TimeFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.triggerElementRef = data.trigger;
    const hNm = getHourNMinute(data.duration);
    this.selectedHour = hNm.hour;
    this.selectedMinute = hNm.minute;
  }

  ngOnInit(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();

    matDialogConfig.position = {
      top: `${(rect.bottom + 3)}px`,
      left: `${(rect.left)}px`
    };
    this.dialogRef.updatePosition(matDialogConfig.position);
  }

  ngOnDestroy() {}

  increaseNumber(type: string) {
    if (type == 'hour') {
      this.selectedHour += 1;
      return;
    }
    if (type === 'minute') {
      this.selectedMinute += 1;
      return;
    }
  }

  decreaseNumber(type: string) {
    if (type == 'hour') {
      this.selectedHour -= 1;
      return;
    }
    if (type === 'minute') {
      this.selectedMinute -= 1;
      return;
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSet() {
    this.dialogRef.close(`${this.selectedHour}:${this.selectedMinute}`);
  }
}

function getHourNMinute(raw: string): {
  hour: number,
  minute: number
} {
  if (raw === null || raw.trim() === '') {
    return {hour: 0, minute: 0}
  }

  const splits = raw.split(':');
  return {
    hour: Number(splits[0]),
    minute: Number(splits[1])
  }
}
