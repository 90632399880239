import {Component, OnInit, ViewChild, Input, ChangeDetectorRef, OnDestroy, ElementRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import * as fromFileUploadActions from '../../../../core/file-upload/_actions/file-upload.actions';
import * as fromFileUploadSelectors from '../../../../core/file-upload/_selectors/file-upload.selectors';
import * as fromFileUploadState from '../../../../core/file-upload/_states/file-upload.state';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {environment} from '../../../../../environments/environment';
import {AttachFileModel} from '../../../../core/file-upload';
import {UploadFileComponent} from '../upload-file/upload-file.component';
import {TranslateService} from '@ngx-translate/core';
import {ImageViewerDialogComponent} from '../image_viewer/image-viewer.dialog.component';
import {DocumentInfoEditDialogComponent} from 'src/app/views/partials/content/crud/document-info-edit-dialog/document-info-edit-dialog.component';

@Component({
    selector: 'kt-upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit, OnDestroy {
    @Input() folderOnEditMode: string;
    @Input() oldFiles: BehaviorSubject<AttachFileModel[]>;
    @Input() filesOnEditMode: any[];
    @Input() readOnly: boolean;
    @Input() checkBox = false;
    @Input() featuredPictureAllowed = false;
    @Input() descriptionField = false;
    @Input() split = '0';
    @Input() addButtonLabel = 'Add';
    @Input() uploadedFile$: BehaviorSubject<any[]>;
    @Input() res$$: BehaviorSubject<any>;
    @ViewChild('fileInputDoc', {static: true}) fileInput: ElementRef;
    
    displayedColumns = ['type', 'name', 'title', 'descr', 'actions'];
    completed$: Observable<boolean>;
    progress$: Observable<number>;
    error$: Observable<string>;
    isInProgress$: Observable<boolean>;
    isReady$: Observable<boolean>;
    hasFailed$: Observable<boolean>;
    res$: Observable<any>;
    fileName: string;
    dataSource = new MatTableDataSource();
    fileFolder: string;
    uploadFiles: AttachFileModel[];
    docs: string[] = [];
    basePath: string;


    public lastChangedItems = new BehaviorSubject<AttachFileModel[]>([]);

    constructor(private dialog: MatDialog,
                private store$: Store<fromFileUploadState.State>,
                private layoutUtilsService: LayoutUtilsService,
                private ref: ChangeDetectorRef,
                private translate: TranslateService) {
        this.fileFolder = '';
        this.uploadFiles = [];
        this.sendToUploadedFiles();
        this.docs = ['xlsx', 'xls', 'doc', 'docx'];
        this.basePath = environment.baseApiUrl;
    }

    ngOnInit() {
        this.displayedColumns = ['type', 'name', 'title', 'date'];
        if (this.descriptionField) {
            this.displayedColumns.push('descr');
        }
        if (this.checkBox) {
            this.displayedColumns.push('check');
        }

        if (this.featuredPictureAllowed) {
            this.displayedColumns.push('is_featured');
        }

        this.displayedColumns.push('actions');
        this.resetUpload();
        if (this.folderOnEditMode && this.folderOnEditMode.length > 0) {
            this.fileFolder = this.folderOnEditMode;
            this.uploadFiles.push(...this.filesOnEditMode);
            this.sendToUploadedFiles()
        }
        if (this.oldFiles) {
            this.oldFiles.asObservable().subscribe(res => {
                this.uploadFiles = Object.assign([], res);
                this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
                this.sendToUploadedFiles()
            });
        }
        this.dataSource.data = this.filterByParentWhenSplit(this.filesOnEditMode);
        this.fileName = '';
        this.completed$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileCompleted)
        );

        this.progress$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileProgress)
        );

        this.error$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileError)
        );

        this.isInProgress$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileInProgress)
        );

        this.isReady$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileReady)
        );

        this.hasFailed$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileFailed)
        );

        this.res$ = this.store$.pipe(
            select(fromFileUploadSelectors.selectUploadFileRes)
        );

        this.res$.subscribe(res => {
                if (res) {

                    this.lastChangedItems.next(res.uploaded_files);
                    this.uploadFiles = [...this.uploadFiles, ...res.uploaded_files];

                    this.fileFolder = res.success;
                    const featuredPicture = this.uploadFiles.find(file => file.is_featured === true);
                    if (!featuredPicture) {
                        this.uploadFiles = this.selectOnlyOne(this.uploadFiles, this.filterByParentWhenSplit(this.uploadFiles)[0]);
                    }
                    this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
                    this.sendToUploadedFiles()

                    if (this.res$$) {
                        this.res$$.next(res);
                    }
                }
            }
        );
    }

    uploadFile(event: any) {
        const files: FileList = event.target.files;
        const firstFile = files.item(0);
        this.fileName = firstFile.name;

        const formData = new FormData();
        let wrongExt = false;

        Array.from(files).forEach((file, i) => {
            const ext = file.name.split('.').pop().toLowerCase();
            if (!UploadFileComponent.fileTypes.find(e => e === ext)) {
                wrongExt = true;
            }
            formData.append('files[' + i + ']', file, file.name);
        });

        if (wrongExt) {
            alert(`Please upload "${UploadFileComponent.fileTypes.join(', ')}" files only.`);
            return;
        }

        if (this.fileFolder) {
            formData.append('path', this.fileFolder);
        }
        formData.append('split', this.split);

        this.store$.dispatch(
            new fromFileUploadActions.UploadRequestAction({
                formData
            })
        );

        // clear the input form
        event.srcElement.value = null;
    }

    resetUpload() {
        this.fileFolder = '';
        this.uploadFiles = [];
        this.sendToUploadedFiles()
        this.fileName = '';
        this.store$.dispatch(new fromFileUploadActions.UploadResetAction());
    }

    cancelUpload() {
        this.fileName = '';
        this.store$.dispatch(new fromFileUploadActions.UploadCancelAction());
    }

    addFiles() {
        this.fileInput.nativeElement.click();
    }

    editFileDescription(obj: AttachFileModel, _index: number) {
        const _title = 'Adding Information';
        const _description = '';
        const _waitDesciption = '';
        const _fileTitle = obj.title ? obj.title : '';
        const _fileDescription = obj.descr ? obj.descr : '';
        const _checkedValue = obj.is_checked;
        const _isFeatured = obj.is_featured;

        const dialogRef = this.dialog.open(DocumentInfoEditDialogComponent, {
            data: {
                title: _title,
                description: _description,
                waitDesciption: _waitDesciption,
                fileTitle: _fileTitle,
                descriptionField: this.descriptionField,
                fileDescription: _fileDescription,

                checkBox: this.checkBox,
                checkBoxValue: _checkedValue,

                featuredPictureAllowed: this.featuredPictureAllowed,
                isFeatured: _isFeatured,

                date: obj.date ? obj.date : ''
            },
            width: '440px',
            position: {top: '50px', right: '50px'}
        });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const editedObj = Object.assign({}, this.uploadFiles[_index]);
            editedObj.title = res.title;
            editedObj.descr = res.descr || '';
            editedObj.is_checked = res.is_checked;
            editedObj.is_featured = res.is_featured;
            editedObj.date = res.date;
            if (editedObj.is_featured) {
                this.uploadFiles = this.selectOnlyOne(this.uploadFiles, editedObj);
            }


            this.uploadFiles[_index] = editedObj;
            this.lastChangedItems.next([editedObj]);
            this.sendToUploadedFiles()
            this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
        });
    }

    deleteUploadedFile(obj) {
        const index = this.uploadFiles.indexOf(obj);
        this.uploadFiles.splice(index, 1);
        if (obj.is_featured && this.uploadFiles.length > 0) {
            this.uploadFiles = this.selectOnlyOne(this.uploadFiles, this.filterByParentWhenSplit(this.uploadFiles)[0]);
        }
        this.sendToUploadedFiles()
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    previewUploadedFile(obj) {
        if (this.docs.indexOf(this.getFileType(obj.name)) > -1) {
            window.open(this.basePath + obj.path + '/' + obj.name);
        } else {
            const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
                data: {
                    picture: obj.path + '/' + obj.name,
                    type: this.getFileType(obj.name)
                }
            });
        }
    }

    onchange($ev, obj: AttachFileModel) {
        const index = this.uploadFiles.indexOf(obj);
        const editedObj = Object.assign({}, obj);
        editedObj.is_checked = !editedObj.is_checked;
        this.uploadFiles = Object.assign([], this.uploadFiles, {[index]: editedObj});
        this.sendToUploadedFiles()
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    onchangeFeatured($ev, obj: AttachFileModel) {
        this.uploadFiles = this.selectOnlyOne(this.uploadFiles, obj);
        this.sendToUploadedFiles()
        this.dataSource.data = this.filterByParentWhenSplit(this.uploadFiles);
    }

    setIcon(type) {
        let ext = 'doc';
        switch (type) {
            case 'png':
                ext = 'png';
                break;
            case 'jpeg':
                ext = 'jpg';
                break;
            case 'jpg':
                ext = 'jpg';
                break;
            case 'gif':
                ext = 'gif';
                break;
            case 'pdf':
                ext = 'pdf';
                break;
            case 'xls':
                ext = 'xls';
                break;
            case 'xlsx':
                ext = 'xls';
                break;
            case 'rtf':
                ext = 'doc';
                break;
            case 'doc':
                ext = 'doc';
                break;
            case 'docx':
                ext = 'doc';
                break;
            case 'mp3':
                ext = 'mp3';
                break;
        }
        return './assets/media/files-alt/' + ext + '.svg';
    }

    getFileType(name) {
        return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
    }

    excludeExtention(name) {
        return name.substring(0, name.indexOf('.')).toLowerCase();
    }

    filterByParentWhenSplit(uploadFiles: AttachFileModel[]): AttachFileModel[] {
        if (this.split !== '0') {
            return uploadFiles.filter(pictureInfo => pictureInfo.ac_type_id.toString() === this.split);
        } else {
            return uploadFiles;
        }
    }

    selectOnlyOne(uploadFiles: AttachFileModel[], selectedObject: AttachFileModel): AttachFileModel[] {
        uploadFiles = this.filterByParentWhenSplit(uploadFiles);
        return uploadFiles.map((obj) => {
            if (obj === selectedObject) {
                const editedObj = Object.assign({}, obj);
                editedObj.is_featured = true;
                return editedObj;
            }
            const editedObj = Object.assign({}, obj);
            editedObj.is_featured = false;
            return editedObj;
        });
    }

    public reload() {
        this.ref.detectChanges();
    }

    ngOnDestroy(): void {
        this.ref.detach();
    }

    private sendToUploadedFiles() {
        if (this.uploadedFile$) {
            this.uploadedFile$.next(this.uploadFiles);
        }
    }

}
