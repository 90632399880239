import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { SubdomainService } from "../../_base/subdomain.service";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class ClientService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  fetchListing() {
    return this.http.get<{data: {
      id: number,
      type: 'Company' | 'Individual',
      name: string,
      last_name: string
    }[]}>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/clients/listing`,
      {
        headers: this.authService.getAuthHeaders()
      }
    ).pipe(
      map(response => response.data)
    )
  }

  fetchContactsListing(id: number) {
    return this.http.get<{
      data: {
        id: number,
        first_name: string,
        last_name: string
      }[]
    }>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/clients/${id}/contacts/listing`,
      {
        headers: this.authService.getAuthHeaders()
      }
    ).pipe(
      map(response => response.data)
    )
  }
}