import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AssetClassesPageRequested, AssetClassModel } from 'src/app/core/asset_class';
import { AppState } from 'src/app/core/reducers';
import { AllConflictRequested, ConflictOfInterestModel, ConflictService, selectAllConflicts, ToeModel } from 'src/app/core/toe';
import { AllConflictRemoved } from 'src/app/core/toe/_actions/conflict.actions';
import { ConflictModel } from 'src/app/core/toe/_models/conflict.model';
import { NewCoiModel } from 'src/app/core/toe/_models/new-coi.model';
import { ConflictState } from 'src/app/core/toe/_reducers/conflict.reducers';
import { ConflictType } from 'src/app/core/toe/_static-data/conflict-type';
import { QueryParamsModel } from 'src/app/core/_base/crud';
import { FilterCoiService } from '../../../toe/toe-edit/filter-coi.service';
import { ConflictDetailComponent } from '../../../toe/_sub/conflict-detail/conflict-detail.component';

export interface CoiDialogInput {
  toe: ToeModel,
  newCoi: NewCoiModel,
  targetProperties: any[]
  purposeOfValuationID: number,
  hasPrevKnowledge: number,
  hasFinancialInterest: number
}
export interface CoiDialogReturn {
  model: NewCoiModel;
}

@Component({
  selector: 'kt-coi-dialog',
  templateUrl: './coi-dialog.component.html',
  styleUrls: ['./coi-dialog.component.scss']
})
export class CoiDialogComponent implements OnInit, OnDestroy {
  selectedPrevKnowledge = 0;
  selectedFinancialInterest = 0;
  targetProperties: AssetClassModel[] = [];

  ciData: ConflictOfInterestModel[] = [];
  ciTemplateData$: BehaviorSubject<ConflictOfInterestModel[]> = new BehaviorSubject([]);

  @ViewChildren('cdComponents') cdComponents: QueryList<ConflictDetailComponent>;
  hasFormErrors = false;
  errorMessage = '';

  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<CoiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CoiDialogInput,
    public conflictService: ConflictService,
    private filterCoiService: FilterCoiService,
    private conflictStore: Store<ConflictState>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.conflictStore.dispatch(new AllConflictRequested());
    this.selectedPrevKnowledge = this.data.hasPrevKnowledge;
    this.selectedFinancialInterest = this.data.hasFinancialInterest;
    this.targetProperties = this.data.targetProperties;
    const cSub = this.conflictService.conflictData$.subscribe(res => {
      this.ciData = [];
      if (res) {
        this.ciData = res;
        this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, this.data.purposeOfValuationID === 8, this.selectedPrevKnowledge === 1, this.selectedFinancialInterest === 1));
      }
    });
    this.subscriptions.push(cSub);

    const allConflictSub = this.conflictStore.pipe(
      skip(1),
      select(selectAllConflicts)
    ).subscribe(res => {
      if (res) {
        const result = [];
        this.conflictService.setConflictData(res);
        let _index = 0;
        res.forEach(item => {
          const tmp = new ConflictModel();
          tmp.clear();
          tmp.index = this.data.newCoi[item.name] || 0;
          tmp.optionType = item.option_type;
          tmp.text = this.data.newCoi[item.name + '_description'];
          tmp.text2 = this.data.newCoi[item.name + '_description_b'];
          tmp.type_of_conflict_id = this.data.newCoi[item.name + '_type_of_conflict_id'];
          tmp.appropriate_parties = this.data.newCoi[item.name + '_appropriate_parties'];
          tmp.reason_continue = this.data.newCoi[item.name + '_reason_continue'];

          tmp.index_a = this.data.newCoi[item.name + '_coi'] || 0;
          tmp.optionType_a = item.option_type_a;

          tmp.prefix = item.name;
          if (item.name == 'employees_knows_each_other') {
            _index = tmp.index;
          }
          tmp.optionPrefix = ConflictType.conflictTypes[item.option_type - 1].options[tmp.index].prefix;
          tmp.optionPrefix_a = ConflictType.conflictTypes[item.option_type_a - 1].options[tmp.index_a].prefix;
          tmp.canEdit = this.data.newCoi[item.name + '_can_edit'] == 1;
          tmp.coiDetailsTP = this.data.newCoi.coi_properties.filter(p => p.conflict_details_name === item.name);
          result.push(tmp);
        });
        this.conflictService.setConflictResult(result);
      }
    });
    this.subscriptions.push(allConflictSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  prevKnowledgeRadioChange(event: MatRadioChange) {
    this.selectedPrevKnowledge = event.value;
    this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, this.data.purposeOfValuationID == 8, this.selectedPrevKnowledge == 1, this.selectedFinancialInterest == 1))
  }
  financialInterestRadioChange(event: MatRadioChange) {
    this.selectedFinancialInterest = event.value;
    this.ciTemplateData$.next(this.filterCoiService.getCiData(this.ciData, this.data.purposeOfValuationID == 8, this.selectedPrevKnowledge == 1, this.selectedFinancialInterest == 1))
  }

  cancel() {
    this.conflictStore.dispatch(new AllConflictRemoved());
    this.dialogRef.close();
  }

  validate() {
    this.cdComponents.forEach(child => {
      child.sendDataToService();
    });

    if (!this.conflictService.changeValidConflicts()) {
      this.hasFormErrors = true;
      this.errorMessage = 'Please fill the explanation fields';

      this.cdComponents.forEach(child => child.valid());
      return;
    }

    const newCoiModel = new NewCoiModel();
    newCoiModel.id = this.data.newCoi.id;
    newCoiModel.toe_id = this.data.newCoi.toe_id;
    newCoiModel.has_prev_knowledge = this.selectedPrevKnowledge;
    newCoiModel.has_financial_interest = this.selectedFinancialInterest;
    newCoiModel.coi_properties = [] ;

    this.conflictService.conflictResultSubject.value.forEach(item => {
      item.coiDetailsTP.forEach(tp => newCoiModel.coi_properties.push(tp));
      newCoiModel[item.prefix] = item.index;
      newCoiModel[item.prefix + '_description'] = item.text;
      newCoiModel[item.prefix + '_description_b'] = item.text2;
      newCoiModel[item.prefix + '_type_of_conflict_id'] = item.type_of_conflict_id;
      newCoiModel[item.prefix + '_appropriate_parties'] = item.appropriate_parties;
      newCoiModel[item.prefix + '_reason_continue'] = item.reason_continue;
      if (item.optionType_a === 5) {
          newCoiModel[item.prefix + '_coi'] = item.index_a;
      }
      if (item.optionType_a === 7) {
          newCoiModel[item.prefix + '_coi'] = item.index_a;
      }
    });

    this.conflictStore.dispatch(new AllConflictRemoved());
    this.dialogRef.close({model: newCoiModel});
  }

}
