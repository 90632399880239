import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IpmsComponent} from '../_models/ipms-component.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class IpmsComponentsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/ipms-components`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // IpmsComponents
    getAllIpmsComponents(): Observable<any> {
        return this.http.get<any>(this.API_URL, {headers: this.authService.getAuthHeaders()});
    }


    findIpmsComponents(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = this.API_URL;
		return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
    }

    getIpmsComponentById(IpmsComponentId: number): Observable<IpmsComponent> {
        return this.http.get<IpmsComponent>(this.API_URL + `/${IpmsComponentId}`, {headers: this.authService.getAuthHeaders()});
    }

    // CREATE =>  POST: add a new IpmsComponent to the server
    createIpmsComponent(IpmsComponent: IpmsComponent): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {ipmsComponentContent: IpmsComponent};

        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the IpmsComponent on the server
    updateIpmsComponent(IpmsComponent: IpmsComponent): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_URL + `/${IpmsComponent.id}`, IpmsComponent, {headers: httpHeaders});
    }

    // DELETE => delete the IpmsComponent from the server
    deleteIpmsComponent(IpmsComponentId: number): Observable<any> {
        const url = `${this.API_URL}/${IpmsComponentId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {headers: this.authService.getAuthHeaders()});
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: this.authService.getAuthHeaders()});
    }

    deleteFromTrash(agencyId: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${agencyId}`, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    // delete from admin trash
    deleteFromAdminTrash(agencyId: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${agencyId}`, {headers: this.authService.getAuthHeaders()});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
