import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { combineLatest, of, Subject } from "rxjs";
import { switchMap, takeUntil, withLatestFrom } from "rxjs/operators";
import { RentBasisService } from "src/app/core/v2/services";
import { RentBasis } from "src/app/core/v2/types/rent-basis";

interface State {
  id?: number,
  isReadonly: boolean,
}

@Component({
  selector: 'kt-rent-basis-edit',
  templateUrl: './rent-basis-edit.component.html',
  providers: [ComponentStore]
})
export class RentBasisEditComponent implements OnDestroy {
  backRoute$ = this.store.select(state => state.id ? ['../../'] : ['../'])
  isReadonly$ = this.store.select(state => state.isReadonly)
  id$ = this.store.select(state => state.id)
  title$ = this.store.select(
    this.id$,
    this.isReadonly$,
    (id, isReadonly) => {
      if (!id) {
        return 'Create New Rent Basis'
      }
      return isReadonly ? 'Edit Rent Basis (View Only)' : 'Edit Rent Basis'
    }
  ) 

  form = new FormGroup({
    name: new FormControl<string|null>(null, [Validators.required])
  })
  get isFormValid() {
    return this.form.valid
  }

  private _onDestroy$ = new Subject<void>()

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: ComponentStore<State>,
    private service: RentBasisService
  ) {
    this.store.setState({
      isReadonly: false
    }) 

    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([params, data]) => {
      if (params.id) {
        this.store.patchState({id: Number(params.id)})
        this.fetchItem(Number(params.id))
      }
      if (data) {
        this.store.patchState({isReadonly: data.readonly})
      }
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  readonly fetchItem = this.store.effect<number> (id$ => 
    id$.pipe(
      switchMap(id =>
        this.service.get(id).pipe(
          tapResponse(
            (response) => {
              this.form.patchValue({
                name: response.data.name
              })
            },
            (err: Error) => console.error(err)
          )
        )
      )
    )
  )

  onReset() {
  }
  onSubmit() {
    this.submit()
  }
  readonly submit = this.store.effect<void>(trigger$ => 
    trigger$.pipe(
      withLatestFrom(this.store.state$),
      switchMap(([_, state]: [void, State]) => {
        if (this.form.invalid) {
          Object.keys(this.form.controls).forEach(controlName => {
            if (this.form.controls[controlName].invalid) {
              this.form.controls[controlName].markAllAsTouched()
            }
          })
          return of()
        }
        const payload: RentBasis = {
          id: state.id,
          name: this.form.controls.name.value,
          relation_cnt: 0,
          is_hidden: false,
          is_system_entry: false,
          deleted_at: null,
          deletedBy: null,
          user_deleted: null,
          userDeletedBy: null
        }
        if (state.id) {
          return this.service.update(state.id, payload).pipe(
            tapResponse(
              (res) => this.router.navigate(['../../'], {relativeTo: this.activatedRoute}),
              (err: Error) => console.error(err)
            )
          )
        }
        return this.service.create(payload).pipe(
          tapResponse(
            (res) => this.router.navigate(['../'], {relativeTo: this.activatedRoute}),
            (err: Error) => console.error(err)
          )
        )
      })
    )
  )
}