<div class="card card-custom">
    <div class="card-header" >
        <div class="card-title">
            <h3 class="card-label">Add new team member</h3>
        </div>
    </div>
    <div >
        <form [formGroup]="form" class="form form-group-seperator-dashed">
            <div class="card-body">
                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="User"
                                    formControlName="worker_id">
                            <mat-option *ngFor="let worker of filteredUsers" value="{{ worker.id }}">{{worker.first_name}} {{worker.last_name}} {{'(' +
                                getValuerQualificationName(worker.qualification_id) + ')'}}
                            </mat-option>
                        </mat-select>

                        <mat-error>User
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-12 kt-margin-bottom-20-mobile mt-2">
                    <mat-checkbox class="mat-form-field-fluid margin-top-12" formControlName="lead_valuer">
                        Lead valuer
                    </mat-checkbox>
                </div>
                <div class="col-lg-12 mt-2">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Task" formControlName="tasks" multiple>
                            <mat-option *ngFor="let task of tpTasks" [value]="task.id" [disabled]="task.disabled">
                                {{task.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-footer b-0 p-0 text-right">
                <div class="form-actions form-actions-solid">
                    <button type="button" mat-raised-button
                            cdkFocusInitial matTooltip="Close" (click)="onClose()">
                        Close
                    </button>&nbsp;
                    <button type="button"
                            mat-raised-button
                            class="btn btn-success"
                            color="submit" (click)="onSubmit()"
                            matTooltip="Save Changes">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
