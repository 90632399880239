import {BaseModel} from '../../_base/crud';

export class PaymentTaxModel extends BaseModel {
    id: number;
    terms_of_engagement_id: number;
    asset_class_id: number;
    asset_class_name: string;
    title_id: number;
    name: string;
    description: string;
    qty: number;
    amount: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;
        this.terms_of_engagement_id = undefined;
        this.asset_class_id = 0;
        this.asset_class_name = '';
        this.title_id = null;
        this.name = '';
        this.description = null;
        this.qty = undefined;
        this.amount = undefined;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
