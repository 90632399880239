import { createFeatureSelector, createSelector } from "@ngrx/store";
import { OfficeAdjustmentsState } from "../_reducers/office-adjustments.reducer";
import * as fromAdjustment from '../_reducers/office-adjustments.reducer';
import { OfficeAdjustmentModel } from "../_models/office-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectOfficeAdjustmentsState = createFeatureSelector<OfficeAdjustmentsState>('office-adjustments');

export const selectOfficeAdjustmentById = (AdjustmentId: number) => createSelector(
    selectOfficeAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllOfficeAdjustments = createSelector(
    selectOfficeAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllOfficeAdjustmentsIds = createSelector(
    selectOfficeAdjustmentsState,
    fromAdjustment.selectIds
);

export const allOfficeAdjustmentsLoaded = createSelector(
    selectOfficeAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectOfficeAdjustmentsPageLoading = createSelector(
    selectOfficeAdjustmentsState,
    state => state.listLoading
);

export const selectOfficeAdjustmentsActionLoading = createSelector(
    selectOfficeAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedOfficeAdjustmentId = createSelector(
    selectOfficeAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectOfficeAdjustmentsShowInitWaitingMessage = createSelector(
    selectOfficeAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedOfficeAdjustmentCount = createSelector(
    selectOfficeAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedOfficeAdjustmentCount = createSelector(
    selectOfficeAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectOfficeAdjustmentQueryResult = createSelector(
    selectOfficeAdjustmentsState,
    state => {
        const items: OfficeAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OfficeAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);