import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetClassLandAreaModel } from 'src/app/core/asset_class';
import { AssetClassSizeModel } from 'src/app/core/comparable';
import { AssetClassBuilding } from 'src/app/core/comparable/_models/asset-class-building.model';
import { AcGroundsComponent } from '../ac-grounds/ac-grounds.component';
import { BuildingInternalAspectsComponent } from '../building-internal-aspects/building-internal-aspects.component';
import { BuildingSectionComponent } from '../building-section/building-section.component';
import { ExternalAspectsComponent } from '../external-aspects/external-aspects.component';
import { ParcelIdentifactionSectionComponent } from '../parcel-identifaction-section/parcel-identifaction-section.component';
import { ServicesAndInfrastructuresComponent } from '../services-and-infrastructures/services-and-infrastructures.component';

@Component({
  selector: 'kt-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.scss']
})
export class BuildingFormComponent implements OnInit {
  @Input() readonly: boolean = false;
  @Input() isComplexForm: boolean = false;
  @Input() acBuilding: AssetClassBuilding;

  @Input() centerLat: number;
  @Input() centerLng: number;
  @Input() buildingId: number = null;
  @Input() buildingPictures: any[] = [];
  @Input() buildingPicturePath: string = null;
  @Input() parent: 'Comparable' | 'Inspection' = 'Comparable'
  @Input() building$: Observable<any>
  @Output() centerChange: EventEmitter<any> = new EventEmitter();
  @Output() picChange: EventEmitter<{pictures: any[], picture: any}> = new EventEmitter();

  @ViewChild(ParcelIdentifactionSectionComponent, {static: false})
  parcelIdentificationComponent: ParcelIdentifactionSectionComponent;
  @ViewChild(BuildingSectionComponent, {static: false})
  buildingSectionComponent: BuildingSectionComponent;
  @ViewChild(ExternalAspectsComponent, {static: false})
  externalAspectComponent: ExternalAspectsComponent;
  @ViewChild(BuildingInternalAspectsComponent, {static: false})
  internalAspectComponent: BuildingInternalAspectsComponent;
  @ViewChild(AcGroundsComponent, {static: false})
  acGroundsComponent: AcGroundsComponent;
  @ViewChild(ServicesAndInfrastructuresComponent, {static: false})
  servicesAndInfraComponent: ServicesAndInfrastructuresComponent;

  errorFields = [];

  serviceAndInfraData;
  externalAspectData;
  internalAspectData;
  grounds;
  sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
  parcelIdentificationData = {
    add_info_on_land: false,
    land_parcel_name: null,
    coordinate_reference_system_id: null,
    points: [],
    landAreaComponentListSubject: new BehaviorSubject([]),
    landAreaSubject: new BehaviorSubject(new AssetClassLandAreaModel())
  }
  hasParcelChecked = false;

  constructor() { }

  ngOnInit(): void {
    if (this.acBuilding == null) {
      this.acBuilding = new AssetClassBuilding();
    }
    this.serviceAndInfraData = this.acBuilding.serviceAndInfra;
    this.externalAspectData = this.acBuilding.externalAspectData;
    this.internalAspectData = this.acBuilding.internalAspectData;
    this.grounds = this.acBuilding.grounds;
    if (this.acBuilding.parcelIdentification.landArea) {
      this.parcelIdentificationData.landAreaSubject.next(this.acBuilding.parcelIdentification.landArea);
    }
    this.parcelIdentificationData.landAreaComponentListSubject.next(this.acBuilding.parcelIdentification.landAreaComponents);
    this.parcelIdentificationData = {
      ...this.parcelIdentificationData,
      add_info_on_land: this.acBuilding.parcelIdentification.add_info_on_land,
      land_parcel_name: this.acBuilding.parcelIdentification.land_parcel_name,
      coordinate_reference_system_id: this.acBuilding.parcelIdentification.coordinate_reference_system_id,
      points: this.acBuilding.parcelIdentification.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}))
    }
    this.sizesSubject.next(this.acBuilding.sizes);
  }


  validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.buildingSectionComponent && this.buildingSectionComponent.validate()) {
      hasError = true;
      errorFields = [...errorFields, ...this.buildingSectionComponent.errorFields];
    }

    this.errorFields = errorFields;
    return hasError;
  }

  onCenterChange(event: any) {
    this.centerChange.emit(event);
  }
  onPicChange(event: any) {
    this.picChange.emit(event);
  }

  onBuildingChange(event) {
    const b = cloneDeep(event);
    this.serviceAndInfraData = {...b.serviceAndInfraData, id: null};
    this.externalAspectData = {...b.externalAspectData, id: null};
    this.internalAspectData = {...b.internalAspectData, id: null};
    this.grounds = {...b.grounds, id: null};
    this.sizesSubject.next(b.sizes.map(s => ({...s, id: null})));
    this.parcelIdentificationData.landAreaSubject.next({...b.parcelIdentification.landArea, id: null});
    this.parcelIdentificationData.landAreaComponentListSubject.next(b.parcelIdentification.landAreaComponents.map(com => ({...com, id: null})));
    this.parcelIdentificationData = {
      ...this.parcelIdentificationData,
      add_info_on_land: !!b.parcelIdentification.add_info_on_land,
      coordinate_reference_system_id: b.parcelIdentification.coordinate_reference_system_id,
      land_parcel_name: b.parcelIdentification.land_parcel_name,
      points: b.parcelIdentification.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}))
    };
  }

  onBuildingCleared() {
    const id = this.acBuilding.id;
    this.acBuilding = new AssetClassBuilding();
    this.acBuilding.id = id;
    this.serviceAndInfraData = this.acBuilding.serviceAndInfra;
    this.externalAspectData = this.acBuilding.externalAspectData;
    this.internalAspectData = this.acBuilding.internalAspectData;
    this.grounds = this.acBuilding.grounds;
    if (this.acBuilding.parcelIdentification.landArea) {
      this.parcelIdentificationData.landAreaSubject.next(this.acBuilding.parcelIdentification.landArea);
    }
    this.parcelIdentificationData.landAreaComponentListSubject.next(this.acBuilding.parcelIdentification.landAreaComponents);
    this.parcelIdentificationData = {
      ...this.parcelIdentificationData,
      add_info_on_land: this.acBuilding.parcelIdentification.add_info_on_land,
      land_parcel_name: this.acBuilding.parcelIdentification.land_parcel_name,
      coordinate_reference_system_id: this.acBuilding.parcelIdentification.coordinate_reference_system_id,
      points: this.acBuilding.parcelIdentification.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}))
    }
    this.sizesSubject.next(this.acBuilding.sizes);
  }

  onParcelChecked(val: boolean) {
    this.hasParcelChecked = val;
  }

  getData() {
    const _building = new AssetClassBuilding();

    _building.id = this.acBuilding.id;
    if (this.parcelIdentificationComponent) {
      _building.parcelIdentification = this.parcelIdentificationComponent.getData();
    }
    if (this.buildingSectionComponent) {
      const _data = this.buildingSectionComponent.getDataForComparable();
      _building.infoSection.building_id = _data.building_id;
      _building.infoSection.building_name = _data.building_name;
      _building.infoSection.completion_year = _data.completion_year;
      _building.infoSection.is_part_of_property = _data.is_part_of_property;
      _building.infoSection.info_about_property = _data.info_about_property;
      _building.infoSection.building_type = _data.building_type;
      _building.infoSection.building_type_comment = _data.building_type_comment;
      _building.infoSection.building_grade_id = _data.building_grade_id;
      _building.infoSection.energy_efficiency_grade_id = _data.energy_efficiency_grade_id;
      _building.infoSection.developer = _data.developer;
      _building.infoSection.anchor_tenant = _data.anchor_tenant;
      _building.infoSection.foundation_type_id = _data.foundation_type_id;
      _building.infoSection.building_description = _data.building_description;
    }
    if (this.servicesAndInfraComponent) {
      _building.serviceAndInfra = this.servicesAndInfraComponent.getdata();
    }
    if (this.externalAspectComponent) {
      _building.externalAspectData = this.externalAspectComponent.getData();
    }

    if (this.internalAspectComponent) {
      _building.internalAspectData = this.internalAspectComponent.getData();
    }

    if (this.acGroundsComponent) {
      _building.grounds = {...this.acGroundsComponent.getData(), id: this.acBuilding.grounds.id};
    }
    _building.sizes = this.sizesSubject.value;
    return _building;
  }
}
