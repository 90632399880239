import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LayoutUtilsService } from '../../../../../../core/_base/crud';
import { AppState } from '../../../../../../core/reducers';
import { CoiDetailsTPModel } from '../../../../../../core/toe';
import { AssetClassModel } from '../../../../../../core/asset_class';

@Component({
    selector: 'kt-tp-edit-dialog',
    templateUrl: './tp-edit-dialog.component.html',
    styleUrls: ['./tp-edit-dialog.component.scss']
})

export class TpEditDialogComponent implements OnInit {

    toeTps: any[] = [];
    returnTPs: CoiDetailsTPModel[] = [];
    order_num: number = 0;
    private subscriptions: Subscription[] = [];
    hasCheckedTp: boolean;

    /**
     * Component constructor
     *
     * @param dialogRef
     * @param data
     * @param dialog: MatDialog
     * @param location
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param assetClassTypesService
     * @param cdRef
     */
    constructor(public dialogRef: MatDialogRef<TpEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private cdRef: ChangeDetectorRef,
                private translateService: TranslateService) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.returnTPs = [];
        this.toeTps = this.data.toeTps.map(item => Object.assign(new AssetClassModel, item));
        this.hasCheckedTp = false;
        if (this.data.selectedTp.length > 0) {
            this.order_num = this.data.selectedTp[this.data.selectedTp.length - 1].order_num;
        }
    }

    updateCheckedOptions(item, $event) {
        if ($event.checked) {
            const obj: CoiDetailsTPModel = new CoiDetailsTPModel();
            obj.clear();
            obj.asset_class_ids = item.id;
            obj.asset_class_names = item.name;
            this.order_num = this.order_num + 1;
            obj.order_num = this.order_num;
            obj.order_num_label = '#' + obj.order_num.toString();
            this.returnTPs = [...this.returnTPs, obj];
        } else {
            const index = this.returnTPs.findIndex(element => element.asset_class_names === item.name);
            if (index !== -1) {
                this.returnTPs.splice(index, 1);
            }
        }
        this.hasCheckedTp = this.hasCheckedAtLeastOneTp();
    }

    close(addAsGroup: boolean) {
        if (addAsGroup) {
            const obj: CoiDetailsTPModel = new CoiDetailsTPModel();
            obj.clear();
            obj.asset_class_ids = this.returnTPs.map(e => e.asset_class_ids).join(', ');
            obj.asset_class_names = this.returnTPs.map(e => e.asset_class_names).join(', ');
            if (this.data.selectedTp.length > 0) {
                obj.order_num = this.data.selectedTp[this.data.selectedTp.length - 1].order_num + 1;
            } else {
                obj.order_num = 1;
            }
            obj.order_num_label = '#' + obj.order_num.toString();
            this.returnTPs = [...[], obj];
        }

        this.dialogRef.close({
            data: this.returnTPs
        });
    }

    setAll($event) {
        const selectState = $event.checked;
        if (selectState) {
            this.toeTps = this.toeTps.map((property) => {
                property.selected = selectState;
                return property;
            });

            this.returnTPs = this.toeTps.map((property) => {
                const obj: CoiDetailsTPModel = new CoiDetailsTPModel();
                obj.clear();
                obj.asset_class_ids = property.id;
                obj.asset_class_names = property.name;
                this.order_num = this.order_num + 1;
                obj.order_num = this.order_num;
                obj.order_num_label = '#' + obj.order_num.toString();
                return obj;
            });
        } else {
            this.toeTps = this.toeTps.map((property) => {
                property.selected = selectState;
                return property;
            });
            this.returnTPs = [];
        }
        this.hasCheckedTp = this.hasCheckedAtLeastOneTp();
    }

    hasCheckedAtLeastOneTp(): boolean {
        if (this.returnTPs.length > 0) {
            return true;
        }
        return false;
    }
}
