// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {FireplacesChimneyBreastsService} from '../_services/fireplaces-chimney-breast.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allFireplacesChimneyBreastsLoaded} from '../_selectors/fireplaces-chimney-breast.selectors';
// Actions
import {
    AllFireplacesChimneyBreastsLoaded,
    AllFireplacesChimneyBreastsRequested,
    FireplacesChimneyBreastActionTypes,
    FireplacesChimneyBreastsPageRequested,
    FireplacesChimneyBreastsPageLoaded,
    FireplacesChimneyBreastUpdated,
    FireplacesChimneyBreastsPageToggleLoading,
    FireplacesChimneyBreastDeleted,
    FireplacesChimneyBreastOnServerCreated,
    FireplacesChimneyBreastCreated,
    FireplacesChimneyBreastAdminRestored,
    FireplacesChimneyBreastDeletedFromAdminTrash,
    FireplacesChimneyBreastDeletedFromTrash,
    FireplacesChimneyBreastOnServerAdminRestored,
    FireplacesChimneyBreastOnServerRestored,
    FireplacesChimneyBreastRestored,
    FireplacesChimneyBreastTrashFlushed,
    FireplacesChimneyBreastsActionToggleLoading
} from '../_actions/fireplaces-chimney-breast.actions';

@Injectable()
export class FireplacesChimneyBreastEffects {
    showPageLoadingDispatcher = new FireplacesChimneyBreastsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new FireplacesChimneyBreastsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new FireplacesChimneyBreastsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllFireplacesChimneyBreasts$ = this.actions$
        .pipe(
            ofType<AllFireplacesChimneyBreastsRequested>(FireplacesChimneyBreastActionTypes.AllFireplacesChimneyBreastsRequested),
            withLatestFrom(this.store.pipe(select(allFireplacesChimneyBreastsLoaded))),
            filter(([action, isAllFireplacesChimneyBreastsLoaded]) => !isAllFireplacesChimneyBreastsLoaded),
            mergeMap(() => this.service.getAllFireplacesChimneyBreasts()),
            map(res => {
                return new AllFireplacesChimneyBreastsLoaded({fireplacesChimneyBreasts: res.data});
            })
        );

    @Effect()
    loadFireplacesChimneyBreastsPage$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastsPageRequested>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findFireplacesChimneyBreasts(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new FireplacesChimneyBreastsPageLoaded({
                    fireplacesChimneyBreasts: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastDeleted>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFireplacesChimneyBreast(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastUpdated>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateFireplacesChimneyBreast(payload.fireplacesChimneyBreast);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastOnServerCreated>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createFireplacesChimneyBreast(payload.fireplacesChimneyBreast).pipe(
                    tap(res => {
                        this.store.dispatch(new FireplacesChimneyBreastCreated({fireplacesChimneyBreast: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushFireplacesChimneyBreastTrash$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastTrashFlushed>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastOnServerRestored>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FireplacesChimneyBreastRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastOnServerAdminRestored>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FireplacesChimneyBreastAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastDeletedFromTrash>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashFireplacesChimneyBreast$ = this.actions$
        .pipe(
            ofType<FireplacesChimneyBreastDeletedFromAdminTrash>(FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: FireplacesChimneyBreastsService, private store: Store<AppState>) {
    }
}
