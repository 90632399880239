import {TypeOfRegistration} from '../_models/type-of-registration.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {TypeOfRegistrationsState} from '../_reducers/type-of-registration.reducers';
import * as fromTypeOfRegistration from '../_reducers/type-of-registration.reducers';
import {each} from 'lodash';

export const selectTypeOfRegistrationsState = createFeatureSelector<TypeOfRegistrationsState>('typeOfRegistrations');

export const selectTypeOfRegistrationById = (typeOfRegistrationId: number) => createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => typeOfRegistrationsState.entities[typeOfRegistrationId]
);

export const selectAllTypeOfRegistrations = createSelector(
    selectTypeOfRegistrationsState,
    fromTypeOfRegistration.selectAll
);

export const selectAllTypeOfRegistrationsIds = createSelector(
    selectTypeOfRegistrationsState,
    fromTypeOfRegistration.selectIds
);

export const allTypeOfRegistrationsLoaded = createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => typeOfRegistrationsState.isAllTypeOfRegistrationsLoaded
);


export const selectTypeOfRegistrationsPageLoading = createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => typeOfRegistrationsState.listLoading
);

export const selectTypeOfRegistrationsActionLoading = createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => typeOfRegistrationsState.actionLoading
);

export const selectLastCreatedTypeOfRegistrationId = createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => typeOfRegistrationsState.lastCreatedTypeOfRegistrationId
);

export const selectTypeOfRegistrationsShowInitWaitingMessage = createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => typeOfRegistrationsState.showInitWaitingMessage
);

export const selectTrashedTypeOfRegistrationCount = createSelector(
    selectTypeOfRegistrationsState,
    (typeOfRegistrationsState) => typeOfRegistrationsState.totalTrashed
);

export const selectAdminTrashedTypeOfRegistrationCount = createSelector(
    selectTypeOfRegistrationsState,
    (typeOfRegistrationsState) => typeOfRegistrationsState.totalAdminTrashed
);


export const selectTypeOfRegistrationQueryResult = createSelector(
    selectTypeOfRegistrationsState,
    typeOfRegistrationsState => {
        const items: TypeOfRegistration[] = [];
        each(typeOfRegistrationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: TypeOfRegistration[] = httpExtension.sortArray(items, typeOfRegistrationsState.lastQuery.sortField, typeOfRegistrationsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, typeOfRegistrationsState.totalCount, '', typeOfRegistrationsState.totalTrashed);
    }
);
