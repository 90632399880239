import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {LayoutUtilsService, TypesUtilsService} from '../../../../../core/_base/crud';
import {AppState} from '../../../../../core/reducers';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FileUploadService} from '../../../../../core/file-upload/_services';
import {ValuationModel} from '../../../../../core/asset_class';
import {
    AllAssetClassValuationGroupsRequested, AllPropertyTypesRequested, AssetClassValuationGroup, PropertyType,
    selectAllAssetClassValuationGroups, selectAllPropertyTypes
} from '../../../../../core/linked-tables';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, skip} from 'rxjs/operators';
import {AddValuationDialogComponent} from '../../add-valuation-dialog/add-valuation-dialog.component';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';


@Component({
    selector: 'kt-ac-valuation-breakdown-alt',
    templateUrl: './ac-valuation-breakdown.component.html',
    styleUrls: ['./ac-valuation-breakdown.component.scss'],
})
export class AcValuationBreakdownComponent implements OnInit, OnDestroy {

    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() valuationsSubject: BehaviorSubject<ValuationModel[]>;
    @Input() currencySubject: BehaviorSubject<string>;
    @Input() unitSubject: BehaviorSubject<string>;
    @Input() toeStatus: number;
    @Input() paymentForm: UntypedFormGroup;

    displayedColumnsAdd2 = [
        'estimated_value',
        'percentage',
        'total_price'
    ];
    displayedColumnsAdd3 = [
        'per_size_value',
        'total_price'
    ];
    displayedColumnsAdd4 = [
        'daily_rate',
        'days',
        'total_price'
    ];
    displayedColumnsAdd1 = [
        'total_price'
    ];
    displayedColumns = [
        'asset_class_name', 
        'methods_to_value_name', 
        'review_type',
        'approaches_to_value_name'
    ];
    displayedColumnsBase = [
        'asset_class_name', 
        'property_type_id', 
        'base_of_value_name',
        'methods_to_value_name', 
        'review_type',
        'approaches_to_value_name'
    ];
    dataSource = new MatTableDataSource();

    sizes: ValuationModel[];
    valuationGroups: AssetClassValuationGroup[] = [];
    propertyTypes: PropertyType[];

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService: FileUploadService
     * @param dialog: MatDialog
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
        // this.saveState = false;
    }

    ngOnInit() {
        if (this.paymentForm.controls.pricing_strategy.value == 2) {
            this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd2];
        } else if (this.paymentForm.controls.pricing_strategy.value == 3) {
            this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd3];
        } else if (this.paymentForm.controls.pricing_strategy.value == 4) {
            this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd4];
        } else if (this.paymentForm.controls.pricing_strategy.value == 5) {
            this.displayedColumns = this.displayedColumnsBase;
        } else{
            this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd1];
        }

        this.paymentForm.controls.pricing_strategy.valueChanges.subscribe(value => {
            if (value == 2) {
                this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd2];
            } else if (value == 3) {
                this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd3];
            } else if (this.paymentForm.controls.pricing_strategy.value == 4) {
                this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd4];
            } else if (this.paymentForm.controls.pricing_strategy.value == 5) {
                this.displayedColumns = this.displayedColumnsBase;
            } else{
                this.displayedColumns = [...this.displayedColumnsBase, ...this.displayedColumnsAdd1];
            }

            this.dataSource = new MatTableDataSource(
                this.valuationsSubject.value
                    .slice()
                    .map(item => {
                        item.total_price = 0
                        return item
                    })
                    .sort((a, b) => (a.group_id > b.group_id) ? 1 : ((b.group_id > a.group_id) ? -1 : 0)));
        });
        this.dataSource = new MatTableDataSource(this.valuationsSubject.value.slice()
            .sort((a, b) => (a.group_id > b.group_id) ? 1 : ((b.group_id > a.group_id) ? -1 : 0)));

        const valuationsSubjectSubscription = this.valuationsSubject.asObservable()
            .pipe(
                distinctUntilChanged(),
                skip(1))
            .subscribe(res => {
                const nRes = res.slice()
                this.dataSource.data = nRes.sort((a, b) => (a.group_id > b.group_id) ? 1 : ((b.group_id > a.group_id) ? -1 : 0));
            });
        this.subscriptions.push(valuationsSubjectSubscription);

        this.store.dispatch(new AllAssetClassValuationGroupsRequested());
        const assetClassValuationGroupsSubscribe = this.store.pipe(
            select(selectAllAssetClassValuationGroups))
            .subscribe(res => {
                this.valuationGroups = [];
                if (res) {
                    this.valuationGroups = res;
                }
            });
        this.subscriptions.push(assetClassValuationGroupsSubscribe);

        this.store.dispatch(new AllPropertyTypesRequested());
        const propertyTypesSubscribe = this.store.pipe(
            select(selectAllPropertyTypes))
            .subscribe(res => {
                this.propertyTypes = [];
                if (res) {
                    this.propertyTypes = res;
                }
            })
        this.subscriptions.push(propertyTypesSubscribe);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem() {
        const dialogRef = this.dialog.open(AddValuationDialogComponent, {data: {}});

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _data = Object.assign([], this.valuationsSubject.value);
            const temp: ValuationModel = Object.assign({}, res.size);
            _data.push(temp);

            this.valuationsSubject.next(_data);
        });
    }

    editItem(_item: ValuationModel, _index: number) {
        const dialogRef = this.dialog.open(AddValuationDialogComponent, {data: {acValuation: _item}});
        let _data = this.valuationsSubject.value;
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const temp: ValuationModel = Object.assign({}, res.size);
            temp._isEditMode = true;
            _data = Object.assign([], _data, {[_index]: temp});
            this.valuationsSubject.next(_data);
        });
    }

    deleteItem(_item: ValuationModel) {
        const _data = this.valuationsSubject.value.filter(obj => obj !== _item);
        this.valuationsSubject.next(_data);
    }

    change(event, index) {
        if (event.target.value) {
            if (this.paymentForm.controls.pricing_strategy.value == 1) {
                let _data = this.valuationsSubject.value;
                const temp: ValuationModel = Object.assign({}, _data[index]);
                const currencyRemoved = event.target.value.replace(this.currencySubject.value, '');
                temp.total_price = parseFloat(currencyRemoved.replace(/,/g, ''));
                _data = Object.assign([], _data, {[index]: temp});
                this.valuationsSubject.next(_data);
            } else if (this.paymentForm.controls.pricing_strategy.value == 2) {
                let _data = this.valuationsSubject.value;
                const temp: ValuationModel = Object.assign({}, _data[index]);
                if (event.target.value.includes('%')) {
                    const percentageRemoved = event.target.value.replace('%', '');
                    temp.percentage = parseFloat(percentageRemoved.replace(/,/g, ''));
                } else {
                    const currencyRemoved = event.target.value.replace(this.currencySubject.value, '');
                    temp.estimated_value = parseFloat(currencyRemoved.replace(/,/g, ''));
                }
                temp.total_price = temp.estimated_value / 100 * temp.percentage;
                _data = Object.assign([], _data, {[index]: temp});
                this.valuationsSubject.next(_data);
            } else if (this.paymentForm.controls.pricing_strategy.value == 3) {
                let _data = this.valuationsSubject.value;
                const temp: ValuationModel = Object.assign({}, _data[index]);
                const currencyRemoved = event.target.value.replace(this.currencySubject.value, '');
                temp.per_size_value = parseFloat(currencyRemoved.replace(/,/g, ''));
                temp.total_price = temp.per_size_value * temp.surface;
                _data = Object.assign([], _data, {[index]: temp});
                this.valuationsSubject.next(_data);
            } else if (this.paymentForm.controls.pricing_strategy.value == 4) {
                let _data = this.valuationsSubject.value;
                const temp: ValuationModel = Object.assign({}, _data[index]);
                if (event.target.value.includes(this.currencySubject.value)) {
                    const currencyRemoved = event.target.value.replace(this.currencySubject.value, '');
                    temp.daily_rate = parseFloat(currencyRemoved.replace(/,/g, ''));
                } else {
                    temp.days = parseInt(event.target.value);
                }
                temp.total_price = temp.daily_rate * temp.days;
                _data = Object.assign([], _data, {[index]: temp});
                this.valuationsSubject.next(_data);
            }
        }
    }

    getValuationGroupName(id): string {
        if (!id || !this.valuationGroups.find(el => el.id == id)) {
            return 'N/A';
        }
        return this.valuationGroups.find(el => el.id == id).name;
    }

    getPropertyTypeName(item): string {
        return item.property_type_name;
        // if (item.asset_class_type_id == 14) {
        //     return item.property_type_name
        // }
        // if (item.asset_class && item.asset_class.type_id == 14) {
        //     return item.asset_class.custom_property_type ? item.asset_class.custom_property_type : item.asset_class.custom_asset_class_name;
        // }
        // if (item.asset_class && item.asset_class.property_type_id) {
        //     propertyTypeId = item.asset_class.property_type_id;
        // } else if (item.property_type_id) {
        //     propertyTypeId = item.property_type_id;
        // }
        // if (!propertyTypeId || !this.propertyTypes.find(el => el.id == propertyTypeId)) {
        //     return 'N/A';
        // }
        // return this.propertyTypes.find(el => el.id == propertyTypeId).name;
    }

    getReviewType(item): string {
        const ReviewTypes = [
            'Desktop Review',
            'External',
            'Internal',
            'Full Survey'
        ];
        if (item.asset_class) {
            return ReviewTypes[item.asset_class.type_of_inspection - 1];
        }
        return ReviewTypes[item.inspection_type - 1];
    }
}
