import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PostValuationDeliveryServicetData } from 'src/app/core/admin/_models/post-valuation-delivery-service.model';
import { CountryData } from 'src/app/views/pages/admin-management/countries/countries-list/local-data';
import { AppState } from 'src/app/core/reducers';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'kt-post-valuation-delivery-service',
  templateUrl: './post-valuation-delivery-service.component.html',
  styleUrls: ['./post-valuation-delivery-service.component.scss']
})
export class PostValuationDeliveryServiceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() readonly: boolean = false;
  @Input() parentTypeID: number;
  @Input() postValuationDeliveryServicetData: PostValuationDeliveryServicetData;
  @Input() currencySubject: BehaviorSubject<string>;
  @ViewChild('singleSelectCurrency', {static: true}) singleSelectCurrency: MatSelect;
  formGroup: UntypedFormGroup;

  private _onDestroy$: Subject<void> = new Subject();

  countriesData = CountryData.countriesWithNoDuplicateCurrency();
  /** control for the MatSelect filter keyword */
  public filterCtrlCountriesData: UntypedFormControl = new UntypedFormControl();
  /** list of models filtered by search keyword */
  public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  include_text_ph = `Meetings, phone calls, or correspondence with third parties (auditors, banks, etc.) to explain or discuss the valuation findings.
Making any revisions or providing further analysis beyond the scope outlined in this agreement.`;

  errorFields = [];

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.formGroup && changes.postValuationDeliveryServicetData && !changes.postValuationDeliveryServicetData.firstChange) {
      this._populateForm(this.formGroup);
    }
  }

  private _populateForm(formGroup?: UntypedFormGroup) {
    if (formGroup) {
      formGroup.patchValue({
        general_info: this.postValuationDeliveryServicetData.general_info,
        includes_in_toe: this.postValuationDeliveryServicetData.includes_in_toe,
        hourly_rate: this.postValuationDeliveryServicetData.hourly_rate,
        currency: this.postValuationDeliveryServicetData.currency,
        free_hours: this.postValuationDeliveryServicetData.free_hours,
        validity_of_fees: this.postValuationDeliveryServicetData.validity_of_fees,
        include_text: this.postValuationDeliveryServicetData.include_text && this.postValuationDeliveryServicetData.include_text.length > 0 ? this.postValuationDeliveryServicetData.include_text : this.include_text_ph,
        
        overridden_in_toe: this.postValuationDeliveryServicetData.overridden_in_toe
      });
      formGroup.updateValueAndValidity();
    } else {
      this.formGroup = this.formBuilder.group({
        general_info: [this.postValuationDeliveryServicetData.general_info ? this.postValuationDeliveryServicetData.general_info : ''],
        includes_in_toe: [this.postValuationDeliveryServicetData.includes_in_toe],
        hourly_rate: [this.postValuationDeliveryServicetData.hourly_rate, this.postValuationDeliveryServicetData.includes_in_toe ? Validators.required : null],
        currency: [this.postValuationDeliveryServicetData.currency, this.postValuationDeliveryServicetData.includes_in_toe ? Validators.required : null],
        free_hours: [this.postValuationDeliveryServicetData.free_hours],
        validity_of_fees: [this.postValuationDeliveryServicetData.validity_of_fees],
        include_text: [this.postValuationDeliveryServicetData.include_text && this.postValuationDeliveryServicetData.include_text.length > 0 ? this.postValuationDeliveryServicetData.include_text : this.include_text_ph],
        overridden_in_toe: [this.postValuationDeliveryServicetData.overridden_in_toe],

      });
    }
  }

  ngOnInit(): void {
    this._fetchLTs();

    this._populateForm();

    if (this.formGroup.controls.includes_in_toe.value || this.formGroup.controls.overridden_in_toe.value) {
      this.formGroup.controls.hourly_rate.setValidators([Validators.required]);
      this.formGroup.controls.currency.setValidators([Validators.required]);
      this.formGroup.controls.hourly_rate.updateValueAndValidity();
      this.formGroup.controls.currency.updateValueAndValidity();
    }

    this.formGroup.controls.includes_in_toe.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      if (val) {
        this.formGroup.controls.hourly_rate.setValidators([Validators.required]);
        this.formGroup.controls.currency.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.hourly_rate.clearValidators();
        this.formGroup.controls.currency.clearValidators();
      }
      this.formGroup.controls.hourly_rate.updateValueAndValidity();
      this.formGroup.controls.currency.updateValueAndValidity();
    })
    this.formGroup.controls.overridden_in_toe.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      if (val) {
        this.formGroup.controls.hourly_rate.setValidators([Validators.required]);
        this.formGroup.controls.currency.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.hourly_rate.clearValidators();
        this.formGroup.controls.currency.clearValidators();
      }
      this.formGroup.controls.hourly_rate.updateValueAndValidity();
      this.formGroup.controls.currency.updateValueAndValidity();
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _fetchLTs() {
    this.fetchSelectsPayment();
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public changeValidation() {
    Object.keys(this.formGroup.controls).forEach(cName => {
      this.formGroup.controls[cName].clearValidators();
      this.formGroup.controls[cName].updateValueAndValidity();
    })
  }

  public getData(): PostValuationDeliveryServicetData {
    const controls = this.formGroup.controls;
    return {
      id: this.postValuationDeliveryServicetData.id,
      general_info: controls.general_info.value,
      includes_in_toe: controls.includes_in_toe.value,
      hourly_rate: controls.hourly_rate.value,
      currency: (this.currencySubject && this.currencySubject.value) ? this.currencySubject.value : controls.currency.value,
      free_hours: controls.free_hours.value,
      validity_of_fees: controls.validity_of_fees.value,
      include_text: controls.include_text.value,
      overridden_in_toe: controls.overridden_in_toe.value,
    }
  }

  resetPayment() {
    const controls = this.formGroup.controls;

    // controls.general_info.setValue(this.postValuationDeliveryServicetData.general_info);
    // controls.includes_in_toe.setValue(this.postValuationDeliveryServicetData.includes_in_toe);
    // controls.hourly_rate.setValue(this.postValuationDeliveryServicetData.hourly_rate);
    // controls.currency.setValue(this.postValuationDeliveryServicetData.currency);
    // controls.free_hours.setValue(this.postValuationDeliveryServicetData.free_hours);
    // controls.validity_of_fees.setValue(this.postValuationDeliveryServicetData.validity_of_fees);
    controls.include_text.setValue(this.postValuationDeliveryServicetData.include_text);
  }

  matSelectCompareWith(a: any, b: any) {
    return a + '' === b + '';
  }

  fetchSelectsPayment() {
    this.filteredCountriesData.next(this.countriesData.slice());

    this.filterCtrlCountriesData.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
          this.filterCountriesData();
      });
  }

  protected filterCountriesData() {
    if (!this.countriesData) {
        return;
    }
    // get the search keyword
    let search = this.filterCtrlCountriesData.value;
    if (!search || search === '') {
        this.filteredCountriesData.next(this.countriesData.slice());
        return;
    } else {
        search = search.toLowerCase();
    }
    // filter the models
    this.filteredCountriesData.next(
        this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
    );
  }

  currencySuffix() {
    return (this.currencySubject && this.currencySubject.value) ? '(' + this.currencySubject.value + ')' : ''
  }
}
