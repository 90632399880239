import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetClassStatsService, StatsInfo } from 'src/app/core/comparable/_services/asset-class-stats.service';

@Component({
  selector: 'kt-comparable-stats',
  templateUrl: './comparable-stats.component.html',
  styleUrls: ['./comparable-stats.component.scss']
})
export class ComparableStatsComponent implements OnInit {
  @Input() acType: number;

  stats$: Observable<StatsInfo>;

  constructor(
    private statsService: AssetClassStatsService
  ) { }

  ngOnInit(): void {
    this.stats$ = this.statsService.getStats(this.acType);
  }

}