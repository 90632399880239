import {
    Component, OnDestroy, OnInit, ViewChild,
    Input,
    OnChanges,
    SimpleChanges,
    QueryList,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {AppState} from '../../../../core/reducers';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ConstructionType} from '../../../../core/linked-tables/_models/construction-type.model';
import {selectAllConstructionTypes} from '../../../../core/linked-tables/_selectors/contruction-type.selectors';
import {TranslateService} from '@ngx-translate/core';
import {
    AllStateRepairsRequested,
    AllTypeOfTitlesRequested,
    Grade,
    selectAllGrades,
    selectAllStateRepairs,
    selectAllTypeOfTitles,
    StateRepair,
    TypeOfTitle,
    ExternalWall,
    AllExternalWallsRequested,
    selectAllExternalWalls,
    FoundationType,
    AllFoundationTypesRequested,
    selectAllFoundationTypes
} from '../../../../core/linked-tables';
import {
    AssetClassBuildingInformation,
    AssetClassResidentialsService,
    BuildingFacilityModel,
    BuildingModel,
    BuildingOnServerCreated,
    BuildingService,
    BuildingUpdated,
    selectLastCreatedBuildingId
} from '../../../../core/comparable';
import {BuildingInfoDialogComponent} from '../../comparable/_sub/building-info-dialog/building-info-dialog.component';
import {UploadFileComponent} from '../upload-file/upload-file.component';
import {FacilitiesSubListComponent} from '../facilities/list/facilities-sub-list.component';
import {Update} from '@ngrx/entity';
import { ComparablesRadioButtonValues } from "src/app/core/comparable/_models/RadioButtonValues";
import { TargetPropertyRadioButtonValues } from 'src/app/core/asset_class/_models/RadioButtonValues';
import { LpBuildingInformationComponent } from '../lp-building-information/lp-building-information.component';

@Component({
    selector: 'kt-lp-building-tab',
    templateUrl: './lp-building-tab.component.html',
    styleUrls: ['./lp-building-tab.component.scss']
})
export class LpBuildingTabComponent implements OnInit, OnDestroy {

    @ViewChildren('lpBuildingInformationComponent') lpBuildingInformationComponent: QueryList<LpBuildingInformationComponent>;

    @Input() buildingsSubject: BehaviorSubject<AssetClassBuildingInformation[]>;
    selected = new UntypedFormControl(0);
    buildingList: AssetClassBuildingInformation[] = [];
    buildingSubject = new BehaviorSubject<AssetClassBuildingInformation>(new AssetClassBuildingInformation());
    private subscriptions: Subscription[] = [];

    ngOnInit() {
        
        this.buildingsSubject.asObservable().subscribe(res => {
            this.buildingList = Object.assign([], res);
        });

        if (this.buildingsSubject.value.length > 0) {
            this.tabChange(0);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addTab(selectAfterAdding: boolean) {
        const buildingInfo = new AssetClassBuildingInformation();
        buildingInfo.clear();
        this.buildingList.push(buildingInfo);

        if (selectAfterAdding) {
            this.selected.setValue(this.buildingList.length - 1);
        }
        this.buildingsSubject.next(this.buildingList);
    }

    removeTab(index: number) {
        this.buildingList.splice(index, 1);
        this.buildingsSubject.next(this.buildingList);
    }

    tabChange(event) {
        this.selected.setValue(event);
        this.buildingSubject.next(this.buildingList[event]);
    }

    fakeArray(length: number): Array<any> {
        if (length >= 0) {
            return new Array(length);
        }
    }
}
