import { TargetPropertyRadioButtonValues } from '../../asset_class/_models/RadioButtonValues';
import {BaseModel} from '../../_base/crud';
import { ComparablesRadioButtonValues } from "./RadioButtonValues";

export class AssetClassBuildingInformation extends BaseModel {

    id: number;
    building_name: string;
    total_floors: string;
    completion_year: string;
    anchor_tenant: string;
    developer: string;

    grade_id: number;
    grade_name: string;
    energy_efficiency_grade_id: number;
    energy_efficiency_grade_name: string;
    type_name: string;
    state_repair_id: number;
    state_repair_name: string;

    has_building_facility: ComparablesRadioButtonValues | TargetPropertyRadioButtonValues;
    building_facility_string: string;

    building_indoor_garage_places: string;
    building_outdoor_garage_places: string;
    building_description: string;
    constructions: number[];
    building_selected_facilties: any[];
    is_part_of_property: number;
    info_about_property: string;

    external_wall_id: number;
    external_wall_name: string;

    foundation_type_id: number;
    foundation_type_name: string;

    path: string;
    files: any[];
    cp_building_id: number;
    // land parcel
    is_part_of_lp: number;
    footprint: string;
    land_occupation_percent: number;
    building_usage: string;
    env_consideration: string;
    occupation: string;
    building_use_status_id: number;
    area_of_hard_surface: string;
    ac_detail_lp_id: number;

    clear() {

        this.id = null;
        this.building_name = '';
        this.total_floors = '';
        this.completion_year = '';
        this.anchor_tenant = '';
        this.developer = '';
        this.state_repair_id = null;
        this.state_repair_name = '';
        this.grade_id = null;
        this.has_building_facility = ComparablesRadioButtonValues.No;
        this.building_facility_string = '';
        this.grade_name = '';
        this.energy_efficiency_grade_id = null;
        this.energy_efficiency_grade_name = '';
        this.type_name = '';
        this.constructions = [];
        this.building_selected_facilties = [];
        this.is_part_of_property = 0;
        this.info_about_property = '';

        this.external_wall_id = null;
        this.external_wall_name = '';

        this.foundation_type_id = null;
        this.foundation_type_name = '';

        this.path = '';
        this.files = [];
        this.cp_building_id = null;

        this.building_indoor_garage_places = '';
        this.building_outdoor_garage_places = '';
        this.building_description = '';

        this.is_part_of_lp = 0;
        this.footprint = '';
        this.land_occupation_percent = 0;
        this.building_usage = '';
        this.env_consideration = '';
        this.occupation = '';
        this.building_use_status_id = 0;
        this.area_of_hard_surface = '';
        this.ac_detail_lp_id = 0;
    }
}
