// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {IpmsStandardActions, IpmsStandardActionTypes} from '../_actions/ipms-standard.actions';
// Models
import {IpmsStandard} from '../_models/ipms-standard.model';
import {QueryParamsModel} from '../../_base/crud';

export interface IpmsStandardsState extends EntityState<IpmsStandard> {
    isAllIpmsStandardsLoaded: boolean;
    queryRowsCount: number;
    queryResult: IpmsStandard[];
    lastCreatedIpmsStandardId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<IpmsStandard> = createEntityAdapter<IpmsStandard>();

export const initialIpmsStandardsState: IpmsStandardsState = adapter.getInitialState({
    isAllIpmsStandardsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedIpmsStandardId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function ipmsStandardsReducer(state = initialIpmsStandardsState, action: IpmsStandardActions): IpmsStandardsState {
    switch (action.type) {
        case IpmsStandardActionTypes.IpmsStandardsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedIpmsStandardId: undefined
            };
        case IpmsStandardActionTypes.IpmsStandardsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case IpmsStandardActionTypes.IpmsStandardOnServerCreated:
            return {
                ...state
            };
        case IpmsStandardActionTypes.IpmsStandardCreated:
            return adapter.addOne(action.payload.ipmsStandard, {
                ...state, lastCreatedIpmsStandardId: action.payload.ipmsStandard.id
            });
        case IpmsStandardActionTypes.IpmsStandardUpdated:
            return adapter.updateOne(action.payload.partialipmsStandard, state);
        case IpmsStandardActionTypes.AllIpmsStandardsLoaded:
            return adapter.addAll(action.payload.ipmsStandards, {
                ...state, isAllIpmsStandardsLoaded: true
            });
        case IpmsStandardActionTypes.IpmsStandardsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case IpmsStandardActionTypes.IpmsStandardsPageLoaded:
            return adapter.addMany(action.payload.ipmsStandards, {
                ...initialIpmsStandardsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case IpmsStandardActionTypes.IpmsStandardDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case IpmsStandardActionTypes.IpmsStandardRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case IpmsStandardActionTypes.IpmsStandardDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case IpmsStandardActionTypes.IpmsStandardAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case IpmsStandardActionTypes.IpmsStandardDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case IpmsStandardActionTypes.IpmsStandardTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
