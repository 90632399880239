<div class="card card-custom">
    <div class="card-header">
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <form [formGroup]="form">
                <div class="form-group row">
                    <div class="col-lg-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-placeholder>Issue date</mat-placeholder>
                            <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
                            <mat-datetimepicker #datetimePicker type="date" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                            <input matInput formControlName="issue_date" [matDatetimepicker]="datetimePicker" autocomplete="false">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-placeholder>Due date</mat-placeholder>
                            <mat-datetimepicker-toggle [for]="datetimePicker1" matSuffix></mat-datetimepicker-toggle>
                            <mat-datetimepicker #datetimePicker1 type="date" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                            <input matInput formControlName="payment_date" [matDatetimepicker]="datetimePicker1" autocomplete="false">
                        </mat-form-field>
                    </div>


                    <div formArrayName="references" class="col-lg-12">
                        <div *ngFor="let reference of references().controls; let refIndex=index">
                            <div [formGroupName]="refIndex" class="form-group row" style="border-top: 1px dashed gray;">
                                <div [ngClass]="{'col-lg-10': refIndex > 0, 'col-lg-11': refIndex == 0}">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Reference" formControlName="pvd_id">
                                            <mat-option *ngFor="let pvdReference of pvdReferences" [value]="pvdReference.id">{{getReferenceName(pvdReference, pvdReferences)}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="px-2 py-0" [ngClass]="{'col-lg-2': refIndex > 0, 'col-lg-1': refIndex == 0}">
                                    <button mat-icon-button color="primary" (click)="addReference(null)">
                                        <mat-icon [matTooltip]="'Add New Field'">add_circle</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="removeReference(refIndex)" *ngIf="refIndex > 0">
                                        <mat-icon [matTooltip]="'Remove Field'">delete</mat-icon>
                                    </button>
                                </div>
                                <div class="col-lg-12 readonly mt-0">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput rows="3" formControlName="reference_note" style="resize: none;"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput rows="4" [placeholder]="'Description'" formControlName="descr"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 kt-margin-bottom-10-mobile">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input matInput [placeholder]="reference.controls.hours_label.value" formControlName="hours" [allowNegativeNumbers]="false" mask="separator.2" thousandSeparator=","/>
                                        <mat-error>
                                            {{reference.controls.hours_label.value}}
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 kt-margin-bottom-10-mobile readonly">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input matInput placeholder="Amount (Currency) per hour" formControlName="hourly_rate" [allowNegativeNumbers]="true" mask="separator.2" thousandSeparator="," [suffix]="'(' + currency + ')'"/>
                                        <mat-error>
                                            Amount (Currency) per hour
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                      </div>         
                </div>
            </form>
        </div>

        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">
                            Close
                        </button>
                        <button mat-button (click)="save()" color="primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>