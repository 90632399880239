// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ResidentialType} from '../_models/residential-type.model';

export enum ResidentialTypeActionTypes {

    AllResidentialTypesRequested = '[ResidentialTypes Home Page] All ResidentialTypes Requested',
    AllResidentialTypesLoaded = '[ResidentialTypes API] All ResidentialTypes Loaded',

    ResidentialTypeOnServerCreated = '[Edit ResidentialType Dialog] ResidentialType On Server Created',
    ResidentialTypeCreated = '[Edit ResidentialTypes Dialog] ResidentialTypes Created',
    ResidentialTypeUpdated = '[Edit ResidentialType Dialog] ResidentialType Updated',
    ResidentialTypeDeleted = '[ResidentialTypes List Page] ResidentialType Deleted',

    ResidentialTypesPageRequested = '[ResidentialTypes List Page] ResidentialTypes Page Requested',
    ResidentialTypesPageLoaded = '[ResidentialTypes API] ResidentialTypes Page Loaded',
    ResidentialTypesPageCancelled = '[ResidentialTypes API] ResidentialTypes Page Cancelled',

    ResidentialTypesPageToggleLoading = '[ResidentialTypes page] ResidentialTypes Page Toggle Loading',
    ResidentialTypesActionToggleLoading = '[ResidentialTypes] ResidentialTypes Action Toggle Loading',

    // trash system

    ResidentialTypeOnServerRestored = '[ResidentialType Trash] ResidentialType On Server Restored',
    ResidentialTypeRestored = '[ResidentialType Trash] ResidentialType Restored',

    ResidentialTypeOnServerAdminRestored = '[ResidentialType Admin Trash] ResidentialType On Server Restored',
    ResidentialTypeAdminRestored = '[ResidentialType Admin Trash] ResidentialType Restored',

    ResidentialTypeDeletedFromTrash = '[ResidentialType Trash] ResidentialType deleted',
    ResidentialTypeDeletedFromAdminTrash = '[ResidentialType Admin Trash] ResidentialType deleted',

    ResidentialTypeTrash = 'ResidentialType Trashed',
    ResidentialTypeTrashFlushed = 'ResidentialType Trash Flushed',
}

export class ResidentialTypeOnServerCreated implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeOnServerCreated;

    constructor(public payload: { residentialType: ResidentialType}) {
    }
}

export class ResidentialTypeCreated implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeCreated;

    constructor(public payload: { residentialType: ResidentialType }) {
    }
}

export class ResidentialTypeUpdated implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeUpdated;

    constructor(public payload: {
        partialResidentialType: Update<ResidentialType>,
        residentialType: ResidentialType
    }) {
    }
}

export class ResidentialTypeDeleted implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialTypesPageRequested implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ResidentialTypesPageLoaded implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypesPageLoaded;

    constructor(public payload: { residentialTypes: ResidentialType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ResidentialTypesPageCancelled implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypesPageCancelled;
}

export class AllResidentialTypesRequested implements Action {
    readonly type = ResidentialTypeActionTypes.AllResidentialTypesRequested;
}

export class AllResidentialTypesLoaded implements Action {
    readonly type = ResidentialTypeActionTypes.AllResidentialTypesLoaded;

    constructor(public payload: { residentialTypes: ResidentialType[] }) {
    }
}

export class ResidentialTypesPageToggleLoading implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ResidentialTypesActionToggleLoading implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ResidentialTypeDeletedFromAdminTrash implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ResidentialTypeDeletedFromTrash implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialTypeOnServerRestored implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialTypeRestored implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeRestored;

    constructor(public payload: { item: ResidentialType }) {
    }
}

export class ResidentialTypeOnServerAdminRestored implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialTypeAdminRestored implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeAdminRestored;

    constructor(public payload: { item: ResidentialType }) {
    }
}

export class ResidentialTypeTrashFlushed implements Action {
    readonly type = ResidentialTypeActionTypes.ResidentialTypeTrashFlushed;

    constructor() {
    }
}

export type ResidentialTypeActions = ResidentialTypeCreated
    | ResidentialTypeUpdated
    | ResidentialTypeDeleted
    | ResidentialTypesPageRequested
    | ResidentialTypesPageLoaded
    | ResidentialTypesPageCancelled
    | AllResidentialTypesLoaded
    | AllResidentialTypesRequested
    | ResidentialTypeOnServerCreated
    | ResidentialTypesPageToggleLoading
    | ResidentialTypesActionToggleLoading
    | ResidentialTypeDeletedFromAdminTrash
    | ResidentialTypeDeletedFromTrash
    | ResidentialTypeOnServerRestored
    | ResidentialTypeRestored
    | ResidentialTypeOnServerAdminRestored
    | ResidentialTypeAdminRestored
    | ResidentialTypeTrashFlushed;
