import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassRetailBuildingModel } from "../_models/asset-class-retail-building.model";

export enum AssetClassRetailBuildingActionTypes {
    AssetClassRetailBuildingActionToggleLoading = '[AssetClassRetailBuilding Effect] AssetClassRetailBuilding Action Toggle Loading',

    AssetClassRetailBuildingOnServerCreated = '[Edit AssetClassRetailBuilding page] AssetClassRetailBuilding On Server Created',
    AssetClassRetailBuildingCreated = '[AssetClassRetailBuilding Effect] AssetClassRetailBuilding Created',
    
    AssetClassRetailBuildingOnServerUpdated = '[Edit AssetClassRetailBuilding page] AssetClassRetailBuilding On Server Updated',
    AssetClassRetailBuildingUpdated = '[AssetClassRetailBuilding Effect] AssetClassRetailBuilding Updated',

    AssetClassRetailBuildingDeleted = '[AssetClassRetailBuilding List Page] Asset Class Land Deleted',
    AssetClassRetailBuildingDeletedFromTrash = '[AssetClassRetailBuilding Trash Modal] Asset Class Land Deleted from Trash',
    AssetClassRetailBuildingDeletedFromAdminTrash = '[AssetClassRetailBuilding Trash Modal] Asset Class Land Deleted from Admin Trash',
    AssetClassRetailBuildingTrashFlushed = '[AssetClassRetailBuilding Trash Modal] Asset Class Retail Building Trash Flushed',

    AssetClassRetailBuildingOnServerRestored = '[AssetClassRetailBuilding Trash Modal] Asset Class Land Restored',
    AssetClassRetailBuildingRestored = '[AssetClassRetailBuilding Effect] Asset Class Land Restored',
    AssetClassRetailBuildingOnServerAdminRestored = '[AssetClassRetailBuilding Admin Trash Modal] Asset Class Land Admin Restored',
    AssetClassRetailBuildingAdminRestored = '[AssetClassRetailBuilding Effect] Asset Class Land Admin Restored',

    AssetClassRetailBuildingPageToggleLoading = '[AssetClassRetailBuilding Effect] AssetClassRetailBuilding Page Toggle Loading',
    AssetClassRetailBuildingPageRequested = '[AssetClassRetailBuilding List Page] All AssetClassRetailBuilding requested',
    AssetClassRetailBuildingPageLoaded = '[AssetClassRetailBuilding Effect] All AssetClassRetailBuilding loaded',

    UpdatePreviouslyCreatedACId = '[AssetClassRetailBuilding Edit] Update Previously Created AssetClassRetailBuilding Id',

    DuplicateAssetClass = '[AssetClassRetailBuilding List Page] Duplicate Asset Class'
}

export class AssetClassRetailBuildingOnServerCreated implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerCreated;
    constructor(public payload: {
        AssetClassRetailBuilding: AssetClassRetailBuildingModel,
        fileList: any[],
    }) {}
}

export class AssetClassRetailBuildingCreated implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingCreated;
    constructor(public payload: {AssetClassRetailBuilding: AssetClassRetailBuildingModel}) {}
}

export class AssetClassRetailBuildingOnServerUpdated implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerUpdated;
    constructor(public payload: {
        AssetClassRetailBuilding: AssetClassRetailBuildingModel,
        fileList: any[] 
    }) {}
}

export class AssetClassRetailBuildingUpdated implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingUpdated;
    constructor(public payload: {AssetClassRetailBuilding: AssetClassRetailBuildingModel}) {}
}

export class AssetClassRetailBuildingPageRequested implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingPageRequested;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class AssetClassRetailBuildingPageLoaded implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingPageLoaded;
    constructor(public payload: {
        AssetClassRetailBuildings: AssetClassRetailBuildingModel[],
        totalCount: number;
        totalTrashed: number;
        totalAdminTrashed: number;
        page: QueryParamsModel
    }) {}
}

export class AssetClassRetailBuildingDeleted implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeleted;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailBuildingDeletedFromTrash implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeletedFromTrash;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailBuildingOnServerRestored implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailBuildingRestored implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingRestored;
    constructor(public payload: {ac: AssetClassRetailBuildingModel}) {}
}

export class AssetClassRetailBuildingOnServerAdminRestored implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerAdminRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailBuildingAdminRestored implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingAdminRestored;
    constructor(public payload: {ac: AssetClassRetailBuildingModel}) {}
}

export class AssetClassRetailBuildingPageToggleLoading implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingPageToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassRetailBuildingActionToggleLoading implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassRetailBuildingDeletedFromAdminTrash implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassRetailBuildingTrashFlushed implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingTrashFlushed;
    constructor() {}
}

export class UpdatePreviouslyCreatedACRetailBuildingId implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.UpdatePreviouslyCreatedACId;
    constructor() {}
}

export class AssetClassRetailBuildingDuplicate implements Action {
    readonly type = AssetClassRetailBuildingActionTypes.DuplicateAssetClass;
    constructor(public payload: {id: number}) {}
}


export type AssetClassRetailBuildingActions 
    = AssetClassRetailBuildingOnServerCreated
    | AssetClassRetailBuildingCreated

    | AssetClassRetailBuildingOnServerUpdated
    | AssetClassRetailBuildingUpdated

    | AssetClassRetailBuildingPageRequested
    | AssetClassRetailBuildingPageLoaded

    | AssetClassRetailBuildingDeleted
    | AssetClassRetailBuildingDeletedFromTrash
    | AssetClassRetailBuildingDeletedFromAdminTrash
    | AssetClassRetailBuildingTrashFlushed

    | AssetClassRetailBuildingOnServerRestored
    | AssetClassRetailBuildingOnServerAdminRestored
    | AssetClassRetailBuildingRestored
    | AssetClassRetailBuildingAdminRestored
    
    | AssetClassRetailBuildingPageToggleLoading
    | AssetClassRetailBuildingActionToggleLoading
    
    | UpdatePreviouslyCreatedACRetailBuildingId
    
    | AssetClassRetailBuildingDuplicate;