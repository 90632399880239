import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AssetClassModel } from "../../asset_class";
import { ValuationAssumptionDeparture, Type } from "../_models/valuation-assumption-departure.model";

export enum ActionTypes {
    LoadData = '[Assumption table] Load data from server',
    DataLoaded = '[Assumption table] Data loaded',

    AddAssumptionOrDeparture = '[Assumption table] Add new assumption or departure',
    AssumptionOrDepartureAdded = '[Assumption table] New assumption or departure is added',

    EditAssumptionOrDeparture = '[Assumption table] Edit assumption or departure',
    AssumptionOrDepartureEdited = '[Assumption table] Assumption or departure is edited',

    RestoreAssumptionOrDeparture = '[Assumption table] Restore assumption or departure',
    AssumptionOrDepartureRestored = '[Assumption table] Assumption or departure restored',

    RemoveToEAssumptionOrDeparture = '[Assumption table] Remove toe assumption or departure',
    ToEAssumptionOrDepartureRemoved = '[Assumption table] ToE assumption or departure removed',

    RemoveInputAssumptionOrDeparture = '[Assumption table] Remove input assumption or departure',
    InputAssumptionOrDepartureRemoved = '[Assumption table] Input assumption or departure removed'
}

export class LoadData implements Action {
    readonly type = ActionTypes.LoadData;
    constructor(public payload: {
        default: ValuationAssumptionDeparture[],
        special: ValuationAssumptionDeparture[],
        departure: ValuationAssumptionDeparture[]
    }) {}
}

export class DataLoaded implements Action {
    readonly type = ActionTypes.DataLoaded;
    constructor(public payload: {
        default: ValuationAssumptionDeparture[],
        def_id: number,
        special: ValuationAssumptionDeparture[],
        sp_id: number,
        departure: ValuationAssumptionDeparture[],
        dp_id: number
    }) {}
}

export class AddAssumptionOrDeparture implements Action {
    readonly type = ActionTypes.AddAssumptionOrDeparture;
    constructor(public payload: {data: ValuationAssumptionDeparture}) {}
}

export class AssumptionOrDepartureAdded implements Action {
    readonly type = ActionTypes.AssumptionOrDepartureAdded;
    constructor(public payload: {data: ValuationAssumptionDeparture, id: number}) {}
}

export class EditAssumptionOrDeparture implements Action {
    readonly type = ActionTypes.EditAssumptionOrDeparture;
    constructor(public payload: {data: ValuationAssumptionDeparture}) {}
}

export class AssumptionOrDepartureEdited implements Action {
    readonly type = ActionTypes.AssumptionOrDepartureEdited;
    constructor(public payload: {
        data: Update<ValuationAssumptionDeparture>,
        type: Type
    }) {}
}

export class RemoveToEAssumptionOrDeparture implements Action {
    readonly type = ActionTypes.RemoveToEAssumptionOrDeparture;
    constructor(public payload: {
        data: ValuationAssumptionDeparture,
        tp: AssetClassModel,
        justification: string
    }) {}
}

export class ToEAssumptionOrDepartureRemoved implements Action {
    readonly type = ActionTypes.ToEAssumptionOrDepartureRemoved;
    constructor(public payload: {
        data: Update<ValuationAssumptionDeparture>,
        type: Type
    }) {}
}

export class RemoveInputAssumptionOrDeparture implements Action {
    readonly type = ActionTypes.RemoveInputAssumptionOrDeparture;
    constructor(public payload: {
        data: ValuationAssumptionDeparture
    }) {}
}

export class InputAssumptionOrDepartureRemoved implements Action {
    readonly type = ActionTypes.InputAssumptionOrDepartureRemoved;
    constructor(public payload: {
        id: number,
        type: Type
    }) {}
}

export class RestoreAssumptionOrDeparture implements Action {
    readonly type = ActionTypes.RestoreAssumptionOrDeparture;
    constructor(public payload: {
        data: ValuationAssumptionDeparture,
        tp: AssetClassModel
    }) {}
}

export class AssumptionOrDepartureRestored implements Action {
    readonly type = ActionTypes.AssumptionOrDepartureRestored;
    constructor(public payload: {
        data: Update<ValuationAssumptionDeparture>,
        type: Type
    }) {}
}

export type AssumptionDepartureActions = LoadData 
    | DataLoaded 

    | AddAssumptionOrDeparture 
    | AssumptionOrDepartureAdded
    
    | EditAssumptionOrDeparture
    | AssumptionOrDepartureEdited
    
    | RemoveToEAssumptionOrDeparture
    | ToEAssumptionOrDepartureRemoved
    
    | RemoveInputAssumptionOrDeparture
    | InputAssumptionOrDepartureRemoved
    
    | RestoreAssumptionOrDeparture
    | AssumptionOrDepartureRestored;
