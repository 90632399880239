import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from "rxjs/operators";
import { 
    PropertyDetailReportModel, 
    PropertyDetailReportOnServerCreated, 
    selectLastCreatedPropertyDetailReportId, 
    selectPropertyDetailReportsByTpId, 
    PropertyDetailReportOnServerDeleted,
    PropertyDetailReportRequested,
    PropertyDetailReportUpdated,
    ReportType,
} from 'src/app/core/asset_class';
import { DraftStatementReportOnServerCreated, DraftStatementReportOnServerDeleted } from "src/app/core/asset_class/_actions/draft-statement-report.action";
import { ValuationReportOnServerCreated, ValuationReportOnServerDeleted, ValuationReportUpdated } from "src/app/core/asset_class/_actions/valuation-report.actions";
import { DraftStatementReportModel } from "src/app/core/asset_class/_models/draft-statement-report.model";
import { ValuationReportModel } from "src/app/core/asset_class/_models/valuation-report.model";
import { selectDraftStatementReportByTpId, selectDraftStatementReportIsGenerating } from "src/app/core/asset_class/_selectors/draft-statement-report.selector";
import { selectPropertyDetailReportIsGenerating } from "src/app/core/asset_class/_selectors/property-detail-report.selectors";
import { selectValuationReportIsGenerating, selectValuationReportsByTpId } from "src/app/core/asset_class/_selectors/valuation-report.selectors";
import { PropertyDetailReportService } from 'src/app/core/asset_class/_services/property-detail-report.service';
import { User } from "src/app/core/mad-auth/mad-auth.store";
import { AppState } from 'src/app/core/reducers';
import { ToeReport } from 'src/app/core/toe';
import { EditReportDescriptionDialogComponent } from '../edit-description-dialog/edit-description-dialog.component';

@Component({
    selector: 'kt-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
    @Input() tpState: number;
    @Input() inspectionCompleted$: Observable<boolean>;
    @Input() valuationCompleted$: Observable<boolean>;
    @Input() canGenerateValuationReport$: Observable<boolean>;
    @Input() canGenerateDraftReport$: Observable<boolean>;
    @Input() tpID: number;
    @Input() toeID: number;
    @Input() user: User;
    @Input() toeStatus: number;
    @Input() isCustomTp: boolean;
    @Input() toeRestrictionsOnUse: boolean;
    @ViewChild(NgbDropdown) dropDown: NgbDropdown;
    dataSource = new MatTableDataSource([]);
    displayedColumns = ['type', 'status', 'generated_date', 'file_size', 'actions']
    subscriptions: Subscription[] = [];
    reportType = ReportType;
    reportStatus = 1;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    hasData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    generatingReport$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.generatingReport$ = combineLatest([
            this.store.select(selectPropertyDetailReportIsGenerating(this.tpID)),
            this.store.select(selectValuationReportIsGenerating(this.tpID)),
            this.store.select(selectDraftStatementReportIsGenerating(this.tpID))
        ]).pipe(
            map(([prig, vrig, dsrig]) => {
                return prig || vrig || dsrig;
            })
        );

        const reportSub = combineLatest([
            this.store.select(selectPropertyDetailReportsByTpId(this.tpID)),
            this.store.select(selectValuationReportsByTpId(this.tpID)),
            this.store.select(selectDraftStatementReportByTpId(this.tpID))
        ]).subscribe(([detailReports, valuationReports, draftStatementReports]) => {
            const data: Array<PropertyDetailReportModel|ValuationReportModel|DraftStatementReportModel> = [];
            if (detailReports) {
                detailReports.forEach(report => data.push(report));
            }
            if (valuationReports) {
                valuationReports.forEach(report => data.push(report));
            }
            if (draftStatementReports) {
                draftStatementReports.forEach(report => data.push(report));
            }
            if (data.length > 0) {
                data.sort((a, b) => {
                    const d1 = new Date(b.created_at);
                    const d2 = new Date(a.created_at);
                    return d1.getTime() - d2.getTime();
                })
                this.dataSource.data = data;
            } else {
                this.dataSource.data = [];
            }
            if (detailReports || valuationReports || draftStatementReports) {
                this.loading$.next(false);
            }
        });
        this.subscriptions.push(reportSub);

        // const propertyDetailReportSub = this.store.pipe(
        //     select(selectPropertyDetailReportsByTpId(this.tpID))
        // ).subscribe(res => {
        //     if (res) {
        //         this.dataSource.data = res;
        //         this.loading$.next(false);
        //     }
        // });
        // this.subscriptions.push(propertyDetailReportSub);

        const hasDataSub = this.loading$.subscribe(res => {
            if (!res && this.dataSource.data.length === 0) {
                this.hasData$.next(false);
            } else {
                this.hasData$.next(true);
            }
        });
        this.subscriptions.push(hasDataSub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public generateValuationReport() {
        const _report = new ValuationReportModel();
        _report.clear();
        _report.tp_id = this.tpID;
        _report.status = this.reportStatus;

        this.store.dispatch(new ValuationReportOnServerCreated({
            report: _report,
        }));
    }

    public generatePropertyDetailReport() {
        const _report = new PropertyDetailReportModel();
        _report.clear();
        _report.tp_id = this.tpID;
        _report.status = this.reportStatus;

        this.store.dispatch(new PropertyDetailReportOnServerCreated({
            report: _report,
        }));
    }

    public generateDraftStatementReport() {
        const _report = new DraftStatementReportModel();
        _report.clear();
        _report.setsEssentials(this.toeID, this.tpID, this.user.id);
        this.store.dispatch(new DraftStatementReportOnServerCreated({
            report: _report
        }));
    }

    public generateDraftStatement() {

    }

    public onHover(event, type: string) {
        if (this.tpState == 1) {
            return;
        }
        this.dropDown.toggle();
    }

    public downloadReport(item: PropertyDetailReportModel|ValuationReportModel) {
        window.open(item.url, '_blank');
    }

    public editReport(item: PropertyDetailReportModel|ValuationReportModel) {
        const title = 'Add Description';
        const dialogRef = this.dialog.open(EditReportDescriptionDialogComponent, {
            data: {
                title,
                description: '',
            },
            width: '440px',
            position: {
                top: '50px',
                right: '50px'
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            if (item.type === this.reportType.PropertyDetailReport) {
                this.editDescriptionPropertyDetailReport(item, res.description);
                return;
            }
            if (item.type === this.reportType.ValuationReport) {
                this.editDescriptionValuationReport(item, res.description);
                return;
            }
        })
    }

    public deleteReport(item: PropertyDetailReportModel|ValuationReportModel|DraftStatementReportModel) {
        if (item.type === this.reportType.PropertyDetailReport) {
            this.store.dispatch(new PropertyDetailReportOnServerDeleted({id: item.id}))
            return;
        }
        if (item.type === this.reportType.ValuationReport)  {
            this.store.dispatch(new ValuationReportOnServerDeleted({id: item.id}));
        }
        if (item.type == this.reportType.DraftStatementReport) {
            this.store.dispatch(new DraftStatementReportOnServerDeleted({id: item.id}));
        }
    }

    private editDescriptionValuationReport(item: ValuationReportModel, description: string) {
        const _tmp = Object.assign({}, item) as ValuationReportModel;
        _tmp.description = description;
        const updatedReport: Update<ValuationReportModel> = {
            id: _tmp.id,
            changes: _tmp
        };
        this.store.dispatch(new ValuationReportUpdated({
            partial: updatedReport,
            report: _tmp
        }));
    }

    private editDescriptionPropertyDetailReport(item: PropertyDetailReportModel, description: string) {
        const _tmp = Object.assign({}, item) as PropertyDetailReportModel;
        _tmp.description = description;
        const updatedReport: Update<PropertyDetailReportModel> = {
            id: _tmp.id,
            changes: _tmp
        }
        this.store.dispatch(new PropertyDetailReportUpdated({
            partialReport: updatedReport,
            report: _tmp
        }));
    }

    onchangeReportOption(event: MatCheckboxChange) {
        this.reportStatus = event.checked ? 0 : 1;
    }

    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 1:
                return 'Validated';
            case 2:
                return 'Error'
            default:
                return ''
        }
    }

    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'danger';
            default:
                return 'success';
        }
    }
}