import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { IPic } from '../../reporting-settings/picture-selector/picture-selector.component';

@Component({
  selector: 'kt-picture-selector-checkbox',
  templateUrl: './picture-selector-checkbox.component.html',
  styleUrls: ['./picture-selector-checkbox.component.scss']
})
export class PictureSelectorCheckboxComponent implements OnInit, OnChanges {
  @Input() pic: IPic
  @Input() selection_status: number;
  @Input() is_selected: boolean;
  @Input() displayFeatured: boolean;
  @Output() selectionChange: EventEmitter<{status: number, selected: boolean}> = new EventEmitter()

  selected: boolean = false;
  selected_value: number = 0;

  constructor(
    private _dialog: MatDialog,
    private _typesUtilsService: TypesUtilsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.is_selected) {
      this.selected = changes.is_selected.currentValue
    }
    if (changes.selection_status) {
      this.selected_value = changes.selection_status.currentValue
    }
  }

  ngOnInit(): void {
    this.selected = this.pic.selected
    this.selected_value = this.pic.selection_status
  }

  onButtonClick(value: number) {
    if (value == this.selected_value) {
      this.selected_value = 0;
      this.selected = false
    } else {
      this.selected_value = value;
      this.selected = true
    }

    this.selectionChange.emit({
      status: this.selected_value,
      selected: this.selected
    })
  }

  previewPicture(pic: IPic) {
    this._dialog.open(ImageViewerDialogComponent, {
      data: {
        title: pic.title,
        picture: pic.path + "/" + pic.name,
        type: this._typesUtilsService.getFileType(pic.name),
        rating_desc: pic.rating_desc,
        withoutViewer: true,
        viewer_type: 'Report Design',
        rating: pic.rating
      },
    });
  }
}
