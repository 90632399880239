// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
// import {AuthNoticeService, AuthService, Login} from '../../../../core/auth';

@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    // Public params
    loginForm: UntypedFormGroup;
    loading = false;
    rememberMe = false;
    isLoggedIn$: Observable<boolean>;
    errors: any = [];

    emailRegex: any = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';

    private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param router: Router
     * @param auth: AuthService
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr: ChangeDetectorRef
     */
    constructor(private router: Router,
                // private auth: AuthService,
                // private authNoticeService: AuthNoticeService,
                private translate: TranslateService,
                private store: Store<AppState>,
                private fb: UntypedFormBuilder,
                private cdr: ChangeDetectorRef) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.rememberMe = localStorage.getItem('mad_user_mail') != null;
        this.initLoginForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // this.authNoticeService.setNotice(null);
        // this.unsubscribe.next();
        // this.unsubscribe.complete();
        this.loading = false;
        this.cdr.detach();
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initLoginForm() {

        this.loginForm = this.fb.group({
            email: [localStorage.getItem('mad_user_mail') ? localStorage.getItem('mad_user_mail') : '',
                Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
            password: [localStorage.getItem('mad_user_pass') ? localStorage.getItem('mad_user_pass') : '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ]
        });
    }

    /**
     * Form Submit
     */
    submit() {
        const controls = this.loginForm.controls;
        /** check form */
        if (this.loginForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        if (this.rememberMe) {
            localStorage.setItem('mad_user_mail', controls.email.value);
            localStorage.setItem('mad_user_pass', controls.password.value);
        } else {
            localStorage.removeItem('mad_user_mail');
            localStorage.removeItem('mad_user_pass');
        }

        const authData = {
            email: controls.email.value,
            password: controls.password.value
        };
        // this.auth
        //     .login(authData.email, authData.password)
        //     .pipe(
        //         tap(data => {
        //             if (!data || !data.error) {
        //                 this.store.dispatch(new Login({authToken: data.token}));
        //                 this.router.navigateByUrl('/'); // Main page
        //             } else {
        //                 this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
        //             }
        //         }),
        //         takeUntil(this.unsubscribe),
        //         finalize(() => {
        //             this.loading = false;
        //             this.cdr.detectChanges();
        //         })
        //     )
        //     .subscribe();
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    checkValue() {
        this.rememberMe = !this.rememberMe;
    }
}
