import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import { FileUploadService } from 'src/app/core/file-upload';
import { LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { ImageViewerDialogComponent } from '../../../shared_components/image_viewer/image-viewer.dialog.component';

@Component({
  selector: 'kt-file-dialog-v2',
  templateUrl: './file-dialog-v2.component.html',
  styleUrls: ['./file-dialog-v2.component.scss'],
  providers: [ComponentStore]
})
export class FileDialogV2Component implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  header: string;
  uploadedFiles: any[] = [];

  dataSource = new MatTableDataSource();
  displayedColumns = ['type', 'name', 'title', 'descr', 'actions'];
  docs = ['xlsx', 'xls', 'doc', 'docx'];
  basePath = environment.baseApiUrl;

    error$: Observable<string>;
    completed$: Observable<boolean>;
  progress$: Observable<number>;
  isInProgress$: Observable<boolean>;
  isReady$: Observable<boolean>;
  hasFailed$: Observable<boolean>;
  uploadedFiles$: Observable<any[]>;
  cancelEvent$: Subject<void> = new Subject();

  private subs: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<FileDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly componentStore: ComponentStore<UploadFileState>,
    public typesUtilsService: TypesUtilsService,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit(): void {
    this.header = this.data.title;
    this.componentStore.setState({
      uploadedFiles: this.data.files,
      error: null,
      progres: null,
      status: UploadFileStatus.Ready
    });
    this.completed$ = this.componentStore.select(state => state.status === UploadFileStatus.Completed);
    this.error$ = this.componentStore.select(state => state.error);
    this.progress$ = this.componentStore.select(state => state.progres);
    this.isInProgress$ = this.componentStore.select(state => state.status === UploadFileStatus.Started && state.progres >= 0);
    this.isReady$ = this.componentStore.select(state => state.status === UploadFileStatus.Ready);
    this.hasFailed$ = this.componentStore.select(state => state.status === UploadFileStatus.Failed);
    this.uploadedFiles$ = this.componentStore.select(state => state.uploadedFiles);
    const fSub = this.uploadedFiles$.subscribe(files => {
      if (files) {
        this.dataSource.data = files;
        this.uploadedFiles = files;
      }
    })
    this.subs.push(fSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onNoClick() {
    if (this.data.readonly) {
      this.dialogRef.close();
    }
    this.dialogRef.close(this.uploadedFiles)
  }

  onAddClick() {
    this.fileInput.nativeElement.click();
  }

  cancelUpload() {
    this.cancelEvent$.next();
    this.uploadCancel();
  }

  uploadFiles(event) {
    const files: FileList = event.target.files;
    const firstFile = files.item(0);

    const formData = new FormData();
    Array.from(files).forEach((file, i) => {
      formData.append('files[' + i + ']', file, file.name);
    });

    this.uploadFile(formData);

    event.srcElement.value = null;
  }

  editFileDescription(file) {
    const _title = 'Adding Description';
    const _description = '';
    const _waitDesciption = '';
    const _fileTitle = file.title ? file.title : '';
    const _fileDescription = file.descr ? file.descr : '';

    const dialogRef = this.layoutUtilsService.saveElementWithInfo(_title,
        _description,
        _waitDesciption,
        _fileTitle,
        true,
        _fileDescription);
    const dialSub = dialogRef.afterClosed().subscribe(res => {
        if (!res) {
            return;
        }
        const index = this.uploadedFiles.indexOf(file);
        const editedObj = Object.assign({}, file);
        editedObj.title = res.title;
        editedObj.descr = res.descr;
        editedObj._isEditMode = true;
        this.uploadedFiles = Object.assign([], this.uploadedFiles, {[index]: editedObj});
        this.componentStore.setState((state) => {
          return {
            ...state,
            uploadedFiles: this.uploadedFiles
          }
        })
    });
    this.subs.push(dialSub);
  }
  deleteUploadedFile(file) {
    this.componentStore.setState((state) => {
      let currentUploadedFiles = Object.assign([], state.uploadedFiles);
      const indx = currentUploadedFiles.findIndex(f => f.id == file.id);
      if (indx >= 0) {
        currentUploadedFiles.splice(indx, 1);
      }
      return {
        ...state,
        uploadedFiles: currentUploadedFiles
      }
    })
  }
  previewUploadedFile(file) {
    if (this.docs.indexOf(this.typesUtilsService.getFileType(file.name)) > -1) {
      window.open(this.basePath + file.path + '/' + file.name);
    } else {
      this.dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: file.path + '/' + file.name,
          type: this.typesUtilsService.getFileType(file.name)
        }
      })
    }
  }

  private handleHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        this.uploadStarted();
        return;
      }
      case HttpEventType.UploadProgress: {
        this.uploadProgress(Math.round((100 * event.loaded) / event.total))
        return;
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          let response_body;
          if (event.type == HttpEventType.Response) {
            response_body = event.body;
          }
          this.uploadCompleted(response_body);
          return;
        }
        if (event.status == 400) {
          this.uploadFailure('Bad Request, Please check your form data.')
          return;
        }
        if (event.status === 403) {
          this.uploadFailure('The Server is refusing action, Please check your form data.')
          return;
        }
        if (event.status === 404) {
          this.uploadFailure('Not found, API have not developed yet.')
          return;
        }
        if (event.status === 500) {
          this.uploadFailure('Internal Server Error, Please inform us by email.')
          return;
        }
        if (event.status >= 500) {
          this.uploadFailure(`Server error ${event.status}, Please inform us by email`)
          return;
        }
        if (event.status >= 400) {
          this.uploadFailure(`Client error ${event.status}, Please check your inserted data`)
          return;
        }
        this.uploadFailure(`Request error ${event.status}, Please check action`)
        return;
      }
      default: {
        this.uploadFailure(`Unknown event: ${JSON.stringify(event)}`)
        return;
      }
    }
  }

  private uploadFile = this.componentStore.effect((formData$: Observable<FormData>) => {
    return formData$.pipe(
      concatMap(formdata => 
        this.fileUploadService.uploadFile(formdata).pipe(
          takeUntil(
            this.cancelEvent$
          ),
          map(event => this.handleHttpEvent(event)),
          catchError(error => {
            this.uploadFailure(`Too large file. Max file upload is: 16mb.`);
            return EMPTY
          })
        ))
    )
  });

  private uploadStarted() {
    this.componentStore.setState((state) => {
      return {
        ...state,
        status: UploadFileStatus.Started,
        progres: 0
      }
    })
  }
  private uploadProgress(progress: number) {
    this.componentStore.setState((state) => {
      return {
        ...state,
        progres: progress
      }
    })
  }
  private uploadCompleted(res: any) {
    this.componentStore.setState((state) => {
      const uploadedFiles: any[] = [];
      state.uploadedFiles.forEach(f => uploadedFiles.push(f));
      if (res) {
        res.uploaded_files.forEach(f => uploadedFiles.push(f));
      }
      return {
        ...state,
        status: UploadFileStatus.Completed,
        progres: 100,
        error: null,
        uploadedFiles
      }
    })
  }
  private uploadFailure(error: string) {
    this.componentStore.setState((state) => {
      return {
        ...state,
        status: UploadFileStatus.Failed,
        error,
        progres: null,
      }
    })
  }
  private uploadCancel() {
    this.componentStore.setState((state) => {
      return {
        ...state,
        status: UploadFileStatus.Ready,
        progres: null,
        error: null
      }
    })
  }

}

export interface UploadFileState {
  status: UploadFileStatus;
  uploadedFiles: any[];
  error: string | null;
  progres: number | null;
}

export enum UploadFileStatus {
  Ready = 'Ready',
  Failed = 'Failed',
  Requested = 'Requested',
  Started = 'Started',
  Completed = 'Completed'
}
