import { AgmInfoWindow, MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { awConst } from 'src/app/app.constants';
import { AllCitiesRequested, AllCountriesRequested, CityModel, CountryModel, selectAllCountries, selectCitiesByCountryId } from 'src/app/core/admin';
import { AssetClassResidentialsService } from 'src/app/core/comparable';
import { LocationData } from 'src/app/core/comparable/_models/location.model';
import { AllGradesRequested, Grade, selectAllGrades } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash'
import { TimeZoneApiService } from 'src/app/core/g-map/timezone.api.service';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { MapsService } from 'src/app/core/map/maps.service';

@Component({
  selector: 'kt-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() locationData: LocationData
  @Input() selectedBuildingId: number | undefined
  @Input() assetClassTypeId: number
  @Output() buildingSelected: EventEmitter<any> = new EventEmitter()
  @Output() markerChange: EventEmitter<{ lat: number, lng: number, isShown: boolean }> = new EventEmitter();
  @Output() timezoneChange = new EventEmitter<string>()
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  formGroup = this._formBuilder.group({
    show_building: [true],
    country_id: [{value: null, disabled: true}, Validators.required],
    city_id: this._formBuilder.control<number>(null, Validators.required),
    zip_code: this._formBuilder.control<string>(null),
    latitude: this._formBuilder.control<number>(null, Validators.required),
    longitude: this._formBuilder.control<number>(null, Validators.required),
    time_offset: this._formBuilder.control<string>(null),
    location_grade_id: this._formBuilder.control<number>(null, Validators.required),
    address: this._formBuilder.control<string>(null, Validators.required),
    location_surrounding: this._formBuilder.control<string>(null, Validators.required),
  })

  zoom = 15
  icon = {
    url: './assets/media/icons/my_marker.svg',
  };
  basePath: string = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=`;
  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  grades: Grade[] = [];
  errorFields = [];
  private _buildingId$ = new BehaviorSubject<number | undefined>(undefined)

  private _center = {
    latitude: environment.mapCenter.lat,
    longitude: environment.mapCenter.lng
  }
  private _defaultCenter$ = new BehaviorSubject<{latitude: number, longitude: number}>(null)
  private _center$ = combineLatest([
    this._defaultCenter$,
    this.mapsService.latitude$,
    this.mapsService.longitude$
  ]).pipe(map(([initial, defLat, defLng]) => {
    if (initial == null) {
      return {latitude: defLat, longitude: defLng}
    }
    return initial
  }))
  centerLat$ = this._center$.pipe(map(({ latitude }) => latitude))
  centerLng$ = this._center$.pipe(map(({ longitude }) => longitude))

  private _marker$ = new BehaviorSubject<{ latitude: number, longitude: number }>(this._center)
  marker$ = this._marker$.asObservable().pipe(
    tap((coords) => {
      this._defaultCenter$.next({
        latitude: Number(coords.latitude),
        longitude: Number(coords.longitude)
      })
      this.formGroup.controls.latitude.patchValue(coords.latitude)
      this.formGroup.controls.longitude.patchValue(coords.longitude)
      this._timezoneApiService.timeZoneOf({lat: Number(coords.latitude), lng: Number(coords.longitude)}).subscribe(offset => {
        this.formGroup.controls.time_offset.patchValue(offset)
        this.timezoneChange.emit(offset)
      })
      this.markerChange.emit({ lat: coords.latitude, lng: coords.longitude, isShown: true })
  }),
  )

  buildings$ = combineLatest([
    this._loadBuildings(),
    this._buildingId$
  ]).pipe(map(([buildings, buildingId]) => {
    return buildings.map(b => {
      return {
        ...b,
        label: {
          color: '#000000',
          fontFamily: '',
          fontSize: '14px',
          fontWeight: 'bold',
          className: `bg-marker bg-marker${b.id == buildingId ? '-selected' : ''}`,
          text: `B${b.id}`
        }
      }
    })
  }))


  private _map: google.maps.Map
  private _mapClickListener: google.maps.MapsEventListener
  private _infoWindow: AgmInfoWindow
  private _geoCoder = new google.maps.Geocoder();
  private _onDestroy$ = new Subject<void>();


  constructor(
    private _ngZone: NgZone,
    private _buildingService: AssetClassResidentialsService,
    private _formBuilder: FormBuilder,
    private _mapsAPILoader: MapsAPILoader,
    private store: Store<AppState>,
    private _timezoneApiService: TimeZoneApiService,
    private subDomainService: SubdomainService,
    private mapsService: MapsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.locationData && !changes.locationData.firstChange) {
      if (this.formGroup) {
        this.formGroup.patchValue({
          address: changes.locationData.currentValue.address,
          city_id: changes.locationData.currentValue.city_id,
          zip_code: changes.locationData.currentValue.zip_code,
          location_grade_id: changes.locationData.currentValue.location_grade_id,
          location_surrounding: changes.locationData.currentValue.location_surrounding
        })
      }
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new AllCountriesRequested());
    this.store.select(selectAllCountries).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.allCountries = [];
      _.each(res, (_country: CountryModel) => {
        this.allCountries.push(_country);
      });
    });
    this.store.dispatch(new AllCitiesRequested());
    if (this.locationData.country_id) {
      this.store.pipe(
        takeUntil(this._onDestroy$), 
        select(selectCitiesByCountryId(this.locationData.country_id))).subscribe((res: CityModel[]) => {
          this.cityOfCountry = res;
      });
    }
    this.store.dispatch(new AllGradesRequested());
    this.store.select(selectAllGrades).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.grades = res ? res : [];
    });

    this._marker$.next({
      latitude: this.locationData.latitude,
      longitude: this.locationData.longitude
    })
    this._buildingId$.next(this.selectedBuildingId)

    this.formGroup.patchValue({
      show_building: this.assetClassTypeId == 5 || this.assetClassTypeId == 1 || this.assetClassTypeId == 3 || this.assetClassTypeId == 2,
      country_id: this.locationData.country_id,
      city_id: this.locationData.city_id,
      zip_code: this.locationData.zip_code,
      latitude: this.locationData.latitude,
      longitude: this.locationData.longitude,
      time_offset:this.locationData.time_offset,
      location_grade_id: this.locationData.location_grade_id,
      address: this.locationData.address,
      location_surrounding: this.locationData.location_surrounding
    })

    this._mapsAPILoader.load().then(() => {
      this._geoCoder = new google.maps.Geocoder();
      if (this.searchElementRef) {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ['(cities)']
        });
        autocomplete.addListener('place_changed', () => {
          this._ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            // verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
              this._defaultCenter$.next({
                latitude: 47.91868658952473,
                longitude: 106.91766668255616,
              })
              this.zoom = 15;
            } else {
              // set latitude, longitude and zoom
              this._defaultCenter$.next({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              })
              this.zoom = 12;
            }
          });
        });
      }
    })
  }

  onMapReady(event: google.maps.Map) {
    this._map = event;
    this._map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'))

    this._mapClickListener = this._map.addListener('rightclick', (e: google.maps.MouseEvent) => {
      this._ngZone.run(() => {
        this._mapRightClick(e)
      })
    })
  }

  onSetLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.latitude && position.coords.longitude) {
          this._defaultCenter$.next({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        }
      })
    } else {
      alert('Position not available')
    }
  }

  onShowInfo(building, info: AgmInfoWindow) {
    // if (this._infoWindow) {
    //   this._infoWindow.close()
    // }
    this._infoWindow = info;
    this._infoWindow.open()

    this._defaultCenter$.next({
      latitude: Number(building.locationData.latitude) + 0.003,
      longitude: Number(building.locationData.longitude)
    })
  }

  onImgError(event) {
    event.target.src = awConst.IMAGE_NOT_FOUND
  }

  onSelectBuilding(building) {
    this.buildingSelected.emit(building)
    this._buildingId$.next(building.id)
    if (this._infoWindow) {
      this._infoWindow.close()
    }
    this._marker$.next({
      latitude: Number(building.locationData.latitude),
      longitude: Number(building.locationData.longitude)
    })
  }

  onSelectCountry() {
    const countryId = this.formGroup.controls.country_id.value;
    this.store.pipe(
      takeUntil(this._onDestroy$), 
      select(selectCitiesByCountryId(countryId))).subscribe((res: CityModel[]) => {
        this.cityOfCountry = res;
    });
  }

  private _mapRightClick(event: google.maps.MouseEvent) {
    this._marker$.next({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng()
    })
  }

  private _loadBuildings() {{
      return this._buildingService.getBuildingInfos().pipe(
        map(res => res.data),
        map(buildings => {
          return buildings.map(b => {
            return {
              ...b,
              total_floors: b.internalAspectData
                ? (Number(b.internalAspectData.above_floors) + Number(b.internalAspectData.below_floors))
                : null,
            }
          })
        })
      )
    }
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public getData(): LocationData {
    const controls = this.formGroup.controls;
    return {
      country_id: controls.country_id.value,
      city_id: controls.city_id.value,
      zip_code: controls.zip_code.value,
      location_grade_id: controls.location_grade_id.value,
      latitude: controls.latitude.value,
      longitude: controls.longitude.value,
      time_offset: controls.time_offset.value,
      address: controls.address.value,
      location_surrounding: controls.location_surrounding.value,
      city_of_location: '',
    }
  }

  ngOnDestroy(): void {
    this._mapClickListener.remove()
  }
}
