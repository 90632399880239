import { displayConsiderations, getGarageRange, mapConsiderationRemoteToDomain, mapVAssetClassSizeRemoteToDomain, mapVLocationRemoteToDomain, mapVServiceAndInfraRemoteToDomain, VAssetClassConsideration, VAssetClassConsiderationRemote, VAssetClassSize, VAssetClassSizeRemote, VExternalAspectRemote, VGroundRemote, VInternalAspectRemote, VLocationData, VLocationDataRemote, VServiceAndInfra, VServiceAndInfraRemote } from "./valuation-asset-class-common.models"
import { mapVParcelIdentificationRemoteToDomain, VParcelIdentification, VParcelIdentificationRemote } from "./valuation-land-parcel.model"
import { PictureModel, TypeOfInspection, ValuationProcessHouseModel } from "./valuation-process-target-property.model"
import { ValuationProcessTargetPropertyRemote } from '../_services/valuation-process.service'

export type VHouseModel = {
    refNum: string,
    propertySubType: 'House',
    propertySubTypeName: string 

    locationData: VLocationData
    property: VHouseAboutProperty,
    serviceAndInfra: VServiceAndInfra
    externalAspect: VHouseExternalAspect
    internalAspect: VHouseInternalAspect
    ground: VHouseGround

    pictures: PictureModel[]
    sizes: VAssetClassSize[],

    parcelIdentification: VParcelIdentification
}

export type VHouseAboutProperty = {
    subTypeCategory: string,
    subCategory: string,
    stateOfRepair: string,
    handoverStandard: string,
    aboveFloors: number,
    belowFloors: number,
    energyEfficiencyGrade: string,
    generalDescription: string,
    constructionDescription: string,
    propertyGrade: string,
    completionYear: string,
    approximateYear: string,
    foundationType: string
}

export type VHouseExternalAspect = {
    chimneyStack: string,
    chimneyStackComment: string,
    roofCovering: string,
    roofCoveringComment: string,
    rainwaterPipe: string,
    rainwaterPipeComment: string,
    mainWall: string,
    mainWallComment: string,
    window: string,
    windowComment: string,
    outsideDoor: string,
    outsideDoorComment: string,
    conservatory: string,
    conservatoryComment: string,
    otherJoinery: string,
    otherJoineryComment: string,
    others: string
}

export type VHouseInternalAspect = {
    ceiling: string,
    ceilingComment: string,
    wallsAndPartition: string,
    wallsAndPartitionComment: string,
    floors: string,
    floorsComment: string,
    bathroomFitting: string,
    bathroomFittingComment: string,
    roofStructure: string,
    roofStructureComment: string,
    fireplacesAndChimney: string,
    fireplacesAndChimneyComment: string,
    woodwork: string,
    woodworkComment: string,
    builtInFittings: string,
    others: string
}

export type VHouseGround = {
    indoorGarage: string
    indoorGarageComment: string,
    outdoorGarage: string,
    outdoorGarageComment: string,
    garden: string,
    gardentComment: string
}

export type VCHouseModel = VHouseModel & {
    id: number
    
    // Info
    considerations: VAssetClassConsideration[]
    displayConsiderations: VAssetClassConsideration[]

    // Additional
    status: number,
    usedTimes: number,
    propertyTypeName: string
}

export type VTPHouseModel = VHouseModel & {
    refNum: 'tp',
    inspectionInfo: {
        typeOfInspection: TypeOfInspection,
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string 
    },
}

export function mapVTPHouseRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessHouseModel): VTPHouseModel {
    return {
        refNum: 'tp',
        propertySubType: generalInfo.propertySubType,
        propertySubTypeName: tpRemote.propertySubTypeName,
        locationData: {
            latitude: generalInfo.locationData.latitude,
            longitude: generalInfo.locationData.longitude,
            country: generalInfo.locationData.countryName,
            city: generalInfo.locationData.cityName,
            zipCode: generalInfo.locationData.zipCode,
            address: generalInfo.locationData.address,
            locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
            locationSurrounding: tpRemote.locationSurrounding,
            timezoneOffset: tpRemote.locationData?.timezoneOffset
        },
        inspectionInfo: {
            typeOfInspection: tpRemote.inspectionTypeId,
            inspectionDate: new Date(tpRemote.inspectionDate),
            anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
            limitationDescription: tpRemote.limitationDesc
        },
        property: {
            subTypeCategory: tpRemote.subTypeCategoryName == 'N/A' ? null : tpRemote.subTypeCategoryName,
            subCategory: tpRemote.subCategoryName == 'N/A' ? null : tpRemote.subCategoryName,
            stateOfRepair: tpRemote.aboutProperty.stateOfRepairName,
            handoverStandard: tpRemote.aboutProperty.handoverStandardName,
            aboveFloors: Number(tpRemote.aboutProperty.above_floors),
            belowFloors: Number(tpRemote.aboutProperty.below_floors),
            energyEfficiencyGrade: tpRemote.aboutProperty.energyEfficiencyGradeName,
            generalDescription: tpRemote.aboutProperty.generalDescription,
            constructionDescription: tpRemote.aboutProperty.construction_desc,
            propertyGrade: tpRemote.aboutProperty.property_grade_name,
            completionYear: tpRemote.aboutProperty.completion_year,
            approximateYear: tpRemote.aboutProperty.apprx_year_extended,
            foundationType: tpRemote.aboutProperty.foundation_type_name
        },
        parcelIdentification: mapVParcelIdentificationRemoteToDomain(tpRemote.parcel_identification),
        pictures: tpRemote.pictures,
        internalAspect: mapVHouseInternalAspectRemoteToDomain(tpRemote.internalAspect),
        externalAspect: mapVHouseExternalAspectRemoteToDomain(tpRemote.externalAspect),
        serviceAndInfra: mapVServiceAndInfraRemoteToDomain(tpRemote.service_and_infra),
        ground: mapVHouseGroundRemoteToDomain(tpRemote.ground),
        sizes: mapVAssetClassSizeRemoteToDomain(tpRemote.sizes)
    }
}

export type VCHouseRemote = {
    id: number,
    property_sub_type_id: 17;

    // Info
    ref_num: string,
    location_data: VLocationDataRemote,
    sub_type_category_name: string,
    sub_category_name: string,
    property_grade_name: string,
    general_description: string,
    state_of_repair_name: string,
    handover_standard_name: string,
    foundation_type_name: string,
    above_floors: string,
    below_floors: string,
    energy_efficiency_grade_name: string,
    construction_desc: string,
    completion_year: string,
    apprx_year_extended: string,
    external_aspect: VExternalAspectRemote
    internal_aspect: VInternalAspectRemote,
    service_and_infra: VServiceAndInfraRemote,
    ground: VGroundRemote

    sizes: VAssetClassSizeRemote[],
    considerations: VAssetClassConsiderationRemote[],
    pictures: PictureModel[],
    parcel_identification: VParcelIdentificationRemote

    // Additional
    status: number,
    used_times: number,
    property_sub_type_name: string,
    property_type_name: string,
}

export function mapVCHouseRemoteToDomain(remote: VCHouseRemote): VCHouseModel {
    const considerations = mapConsiderationRemoteToDomain(remote.considerations)
    return {
        id: remote.id,
        refNum: remote.ref_num,
        propertySubType: 'House',
        locationData: mapVLocationRemoteToDomain(remote.location_data),
        property: {
            subTypeCategory: remote.sub_type_category_name,
            subCategory: remote.sub_category_name,
            stateOfRepair: remote.state_of_repair_name,
            handoverStandard: remote.handover_standard_name,
            aboveFloors: Number(remote.above_floors),
            belowFloors: Number(remote.below_floors),
            energyEfficiencyGrade: remote.energy_efficiency_grade_name,
            generalDescription: remote.general_description,
            constructionDescription: remote.construction_desc,
            propertyGrade: remote.property_grade_name,
            completionYear: remote.completion_year,
            approximateYear: remote.apprx_year_extended,
            foundationType: remote.foundation_type_name
        },
        externalAspect: mapVHouseExternalAspectRemoteToDomain(remote.external_aspect),
        internalAspect: mapVHouseInternalAspectRemoteToDomain(remote.internal_aspect),
        serviceAndInfra: mapVServiceAndInfraRemoteToDomain(remote.service_and_infra),
        ground: mapVHouseGroundRemoteToDomain(remote.ground),
        sizes: mapVAssetClassSizeRemoteToDomain(remote.sizes),
        considerations,
        displayConsiderations: displayConsiderations(considerations),
        status: remote.status,
        usedTimes: remote.used_times,
        propertySubTypeName: remote.property_sub_type_name,
        propertyTypeName: remote.property_type_name,
        pictures: remote.pictures,
        parcelIdentification: mapVParcelIdentificationRemoteToDomain(remote.parcel_identification)
    }
}

function mapVHouseExternalAspectRemoteToDomain(remote: VExternalAspectRemote): VHouseExternalAspect {
    return {
        chimneyStack: remote.chimney_stack_name,
        chimneyStackComment: remote.chimney_stack_comment,
        roofCovering: remote.roof_covering_name,
        roofCoveringComment: remote.roof_covering_comment,
        rainwaterPipe: remote.rainwater_pipe_name,
        rainwaterPipeComment: remote.rainwater_pipe_comment,
        mainWall: remote.main_wall_name,
        mainWallComment: remote.main_wall_comment,
        window: remote.window_type_name,
        windowComment: remote.window_type_comment,
        outsideDoor: remote.outside_door_name,
        outsideDoorComment: remote.outside_door_comment,
        conservatory: remote.conservatory_name,
        conservatoryComment: remote.conservatory_comment,
        otherJoinery: remote.other_joinery_name,
        otherJoineryComment: remote.other_joinery_comment,
        others: remote.has_other ? remote.other_aspects : null
    }
}

function mapVHouseInternalAspectRemoteToDomain(remote: VInternalAspectRemote): VHouseInternalAspect {
    return {
        ceiling: remote.ceiling_name,
        ceilingComment: remote.ceiling_comment,
        wallsAndPartition: remote.walls_and_partition_name,
        wallsAndPartitionComment: remote.walls_and_partition_comment,
        floors: remote.floor_type_name,
        floorsComment: remote.floor_type_name,
        bathroomFitting: remote.bathroom_fitting_name,
        bathroomFittingComment: remote.bathroom_fitting_comment,
        roofStructure: remote.roof_structure_name,
        roofStructureComment: remote.roof_structure_comment,
        fireplacesAndChimney: remote.fireplaces_chimney_breast_name,
        fireplacesAndChimneyComment: remote.fireplaces_chimney_breast_comment,
        woodwork: remote.woodwork,
        woodworkComment: remote.woodwork_comment,
        builtInFittings: remote.builtin_fitting_names,
        others: remote.has_other ? remote.other_aspects : null
    }
}

function mapVHouseGroundRemoteToDomain(remote: VGroundRemote): VHouseGround {
    return {
        indoorGarage: getGarageRange(remote.indoor_garage),
        indoorGarageComment: remote.indoor_garage_comment,
        outdoorGarage: getGarageRange(remote.outdoor_garage),
        outdoorGarageComment: remote.outdoor_garage_comment,
        garden: remote.garden_name,
        gardentComment: remote.garden_comment
    }
}