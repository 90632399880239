import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { AdjustmentValuationService } from "../_services/valuation.service";
import * as valuationActions from '../_actions/valuation.actions';
import { map, mergeMap, take, withLatestFrom } from "rxjs/operators";
import * as selectors from '../_selectors/valuation.selector';
import { combineLatest } from "rxjs";
import { selectDefaultSizeCriterion } from "../_selectors/size_criterion.selectors";
import { selectSingleCriterion } from "../_selectors/criterion.selectors";
import { selectAllValuationAdjustments } from "../_selectors/valuation-adjustment.selector";
import { Update } from "@ngrx/entity";
import { AdjustmentValuation } from "../_models/valuation.model";
import { of } from "rxjs";

@Injectable()
export class AdjustmentValuationEffects {
    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private service: AdjustmentValuationService
    ) {}

    @Effect()
    addValuation$ = this.actions$.pipe(
        ofType<valuationActions.AddValuation>(valuationActions.AdjustmentValuationActionTypes.AddValuation),
        withLatestFrom(this.store$.select(selectors.selectLastCreatedValuationID)),
        map(([action, id]) => {
            const newValuation = this.service.createNewValuation(action.payload.com, id);
            return new valuationActions.ValuationAdded({valuation: newValuation});
        })
    );

    @Effect()
    valuationAdded$ = this.actions$.pipe(
        ofType<valuationActions.ValuationAdded>(valuationActions.AdjustmentValuationActionTypes.ValuationAdded),
        map(() =>  {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    removeValuation$ = this.actions$.pipe(
        ofType<valuationActions.RemoveValuation>(valuationActions.AdjustmentValuationActionTypes.RemoveValuation),
        mergeMap(({payload}) => combineLatest([
            of(payload.comID),
            this.store$.pipe(select(selectors.selectAllValuations), take(1))
        ])),
        map(([comID, valuations]) => {
            const v = valuations.find(val => val.comInfo.id == comID);
            const id = v ? v.id : 0;
            return new valuationActions.ValuationRemoved({id});
        })
    )

    @Effect()
    valuationRemoved$ = this.actions$.pipe(
        ofType<valuationActions.ValuationRemoved>(valuationActions.AdjustmentValuationActionTypes.ValuationRemoved),
        map(() => {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    addMultipleValuation$ = this.actions$.pipe(
        ofType<valuationActions.AddMultipleValuation>(valuationActions.AdjustmentValuationActionTypes.AddMultipleValuation),
        map(({payload}) => {
            const res = this.service.createMultipleValuations(payload.coms);
            return new valuationActions.MultipleValuationAdded({
                valuations: res.vals,
                id: res.id
            });
        })
    )

    @Effect()
    multipleValuationAdded$ = this.actions$.pipe(
        ofType<valuationActions.MultipleValuationAdded>(valuationActions.AdjustmentValuationActionTypes.MultipleValuationAdded),
        map(() => {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    updateValuationExternalData$ = this.actions$.pipe(
        ofType<valuationActions.UpdateValuationsExternalData>(valuationActions.AdjustmentValuationActionTypes.UpdateValuationsExternalData),
        mergeMap(() => combineLatest([
            this.store$.pipe(select(selectors.selectAllValuations), take(1)),
            this.store$.pipe(select(selectDefaultSizeCriterion), take(1)),
            this.store$.pipe(select(selectSingleCriterion(1)), take(1)),
            this.store$.pipe(select(selectAllValuationAdjustments), take(1))
        ])),
        map(([valuations, sizeCriterion, considerationCriterion, adjustments]) => {
            const res = this.service.updateValuations(valuations, sizeCriterion, considerationCriterion, adjustments);
            return new valuationActions.ValuationsUpdated({valuations: res});
        })
    )

    @Effect()
    updateValuationInternalData$ = this.actions$.pipe(
        ofType<valuationActions.UpdateValuationInternalData>(valuationActions.AdjustmentValuationActionTypes.UpdateValuationInternalData),
        mergeMap(({payload}) => combineLatest([
            of(payload),
            this.store$.pipe(select(selectDefaultSizeCriterion), take(1)),
            this.store$.pipe(select(selectSingleCriterion(1)), take(1)),
            this.store$.pipe(select(selectAllValuationAdjustments), take(1))
        ])),
        map(([payload, sizeCriterion, considerationCriterion, adjustments]) => {
            const res = this.service.updateValuation(payload.valuation, sizeCriterion, considerationCriterion, adjustments);
            const updateValuation: Update<AdjustmentValuation> = {
                id: res.id,
                changes: {
                    ...res
                }
            };
            return new valuationActions.ValuationUpdated({
                updateValuation
            })
        })
    )

    @Effect()
    computeTotalsValuationsUpdated$ = this.actions$.pipe(
        ofType<valuationActions.ValuationsUpdated>(valuationActions.AdjustmentValuationActionTypes.ValuationsUpdated),
        mergeMap(() => combineLatest([
            this.store$.pipe(select(selectors.selectAllValuations), take(1)),
            this.store$.pipe(select(selectDefaultSizeCriterion), take(1)),
            this.store$.pipe(select(selectors.selectCapitalAllowance), take(1))
        ])),
        map(([valuations, sizeCriterion, capitalAllowance]) => {
            const res = this.service.computeTotals(valuations, sizeCriterion, capitalAllowance);
            return new valuationActions.ValuationTotalsComputed({
                totalWeighted: res.totalWeighted,
                totalValue: res.totalValue,
                totalTPValue: res.totalTPValue,
                totalTPWeighted: res.totalTPWeighted,
            })
        })
    )

    @Effect()
    computeTotalsValuationUpdate$ = this.actions$.pipe(
        ofType<valuationActions.ValuationUpdated>(valuationActions.AdjustmentValuationActionTypes.ValuationUpdated),
        mergeMap(() => combineLatest([
            this.store$.pipe(select(selectors.selectAllValuations), take(1)),
            this.store$.pipe(select(selectDefaultSizeCriterion), take(1)),
            this.store$.pipe(select(selectors.selectCapitalAllowance), take(1))
        ])),
        map(([valuations, sizeCriterion, capitalAllowance]) => {
            const res = this.service.computeTotals(valuations, sizeCriterion, capitalAllowance);
            return new valuationActions.ValuationTotalsComputed({
                totalWeighted: res.totalWeighted,
                totalValue: res.totalValue,
                totalTPValue: res.totalTPValue,
                totalTPWeighted: res.totalTPWeighted
            })
        })
    )

    @Effect()
    capitalAllowanceUpdated$ = this.actions$.pipe(
        ofType<valuationActions.UpdateCapitalAllowance>(valuationActions.AdjustmentValuationActionTypes.UpdateCapitalAllowance),
        mergeMap(() => combineLatest([
            this.store$.pipe(select(selectors.selectAllValuations), take(1)),
            this.store$.pipe(select(selectDefaultSizeCriterion), take(1)),
            this.store$.pipe(select(selectors.selectCapitalAllowance), take(1))
        ])),
        map(([valuations, sizeCriterion, capitalAllowance]) => {
            const res = this.service.computeTotals(valuations, sizeCriterion, capitalAllowance);
            return new valuationActions.ValuationTotalsComputed({
                totalWeighted: res.totalWeighted,
                totalValue: res.totalValue,
                totalTPValue: res.totalTPValue,
                totalTPWeighted: res.totalTPWeighted
            })
        })
    )
}