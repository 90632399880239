import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {
    CityModel,
    AllCitiesRequested,
    CityDeleted,
    CountryModel,
    selectCountryById,
    selectCitiesActionLoading,
    CityUpdatedOnServer,
    selectCitiesByCountryId,
    selectTrashedCitiesByCountryId,
    selectTrashedCities,
    selectCities,
    CityTrashFlushed, selectAdminTrashedCities, selectAdminTrashedCitiesByCountryId, AllCountriesRequested
} from '../../../../../../core/admin';
import {AppState} from '../../../../../../core/reducers';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {CityEditComponent} from '../city-edit/city-edit.component';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, fromEvent, Observable, Subscription} from 'rxjs';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {Update} from '@ngrx/entity';
import {currentUser, User} from 'src/app/core/mad-auth/mad-auth.store';
import {take} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
type pmStatus = {
    total_cities: string,
    total_different_countries: string,
    total_used_in_vals: string,
}

@Component({
    selector: 'kt-city-list',
    styleUrls: ['./cities-list.component.css'],
    templateUrl: './cities-list.component.html',
})
export class CitiesListComponent implements OnInit, OnDestroy, AfterViewInit {

    displayedColumns = ['name', 'county_name', 'actions'];
    cityData: CityModel[] = [];
    // trashedData:  = [];
    trashedData = new BehaviorSubject<CityModel[]>([]);
    aTrashedData = new BehaviorSubject<CityModel[]>([]);
    country: CountryModel;
    currentUser: User;
    dataSource = new MatTableDataSource(this.cityData);

    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    subscriptions: Subscription[];
    loading$: Observable<boolean>;
    cityStatusCnts$ = new BehaviorSubject<pmStatus>({
        total_cities: '0',
        total_different_countries: '0',
        total_used_in_vals: '0',
    });


    constructor(private activatedRoute: ActivatedRoute,
                private store: Store<AppState>,
                public subheaderService: SubheaderService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.subheaderService.setTitle('Cities');
        this.subheaderService.setBreadcrumbs([
            {title: 'Admin management', page: `../default/admin-management`},
            {title: 'Cities'}
        ]);

        this.loading$ = this.store.pipe(select(selectCitiesActionLoading));
        this.store.dispatch(new AllCitiesRequested());
        this.store.dispatch(new AllCountriesRequested());
        this.subscriptions = [];

        this.activatedRoute.params.subscribe(params => {
            const _countryId = params.id;
            if (_countryId && _countryId > 0) {
                const selectCountrySub = this.store.pipe(select(selectCountryById(_countryId))).subscribe(res => {
                    if (res) {
                        this.country = Object.assign({}, res);
                    }
                });
                this.subscriptions.push(selectCountrySub);
                const selectCitiesByCountrySub = this.store.pipe(
                    select(selectCitiesByCountryId(_countryId)),
                ).subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.cityData = res;
                    this.dataSource.data = res;

                    this.calcTableTops(res)
                });
                this.subscriptions.push(selectCitiesByCountrySub);

                const selectTrashedCitiesByCountrySub = this.store.pipe(
                    select(selectTrashedCitiesByCountryId(_countryId)),
                ).subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.trashedData.next(res);
                });
                this.subscriptions.push(selectTrashedCitiesByCountrySub);

                const selectAdminTrashedCitiesByCountryIdSub = this.store.pipe(
                    select(selectAdminTrashedCitiesByCountryId(_countryId)),
                ).subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.aTrashedData.next(res);
                });
                this.subscriptions.push(selectAdminTrashedCitiesByCountryIdSub);

            } else {
                this.country = new CountryModel();
                this.country.clear();
                const selectCitiesSub = this.store.pipe(
                    select(selectCities),
                ).subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.cityData = res;
                    this.dataSource.data = res;

                    this.calcTableTops(res);
                });
                this.subscriptions.push(selectCitiesSub);

                const selectTrashedCitiesSub = this.store.pipe(
                    select(selectTrashedCities),
                ).subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.trashedData.next(res);
                });
                this.subscriptions.push(selectTrashedCitiesSub);

                const selectAdminTrashedCitiesSub = this.store.pipe(
                    select(selectAdminTrashedCities),
                ).subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.aTrashedData.next(res);
                });
                this.subscriptions.push(selectAdminTrashedCitiesSub);
            }
        });

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                const searchText: string = this.searchInput.nativeElement.value;

                let res = this.cityData;
                if (searchText && searchText != '') {
                    res = this.cityData.filter(value => value.name.toLowerCase().search(searchText.toLowerCase()) != -1);
                }

                this.dataSource.data = res;
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        const userSubscription = this.store.pipe(
            take(1),
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                }
            });
        this.subscriptions.push(userSubscription);


    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Returns object for filter
     */
    resetFilter() {

        this.searchInput.nativeElement.value = '';
        this.dataSource.data = this.cityData;
    }

    addCity() {
        const newCity = new CityModel();
        newCity.clear(); // Set all defaults fields
        newCity.country_id = this.country.id;
        newCity.country_name = this.country.name;
        this.editCity(newCity);
    }

    editCity(city: CityModel) {
        const _saveMessage = this.translate.instant('CITY.LIST.DIALOG.SAVE.MESSAGE');
        const _messageType = city.id ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(CityEditComponent, {data: {city}});
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
        });
    }

    deleteCity(_item: CityModel) {
        const _title = this.translate.instant('CITY.LIST.DIALOG.DELETE.TITLE');
        const _description = this.translate.instant('CITY.LIST.DIALOG.DELETE.DESCRIPTION', {
            name: _item.name
        });
        const _waitDesciption = this.translate.instant('CITY.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _city = Object.assign({}, _item);
            _city.user_deleted = new Date();
            _city.user_deleted_by = this.currentUser.id;
            this.store.dispatch(new CityUpdatedOnServer({
                city: _city
            }));
        });
    }

    // Fetch trash data
    trash() {
        // this.store.dispatch(new ClientActionToggleLoading({isLoading: true}));
        const items = [];
        this.trashedData.value.forEach(elem => {
            items.push({
                text: `${elem.name}`,
                id: elem.id.toString(),
                deletedUser: elem.userDeletedBy,
                date: elem.user_deleted,
                status: elem.country_name,
                hasPermanentlyDelete: true
            });
        });
        this.show_trash(items);

    }

    // Fetch admin trash data
    adminTrash() {

        const items = [];
        this.aTrashedData.value.forEach(elem => {
            items.push({
                text: `${elem.name}`,
                id: elem.id.toString(),
                deletedUser: elem.deletedBy,
                date: elem.deleted_at,
                status: elem.country_name,
                hasPermanentlyDelete: true
            });
        });
        this.show_trash(items, true);
    }

    /**
     * Show Edit client dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('CITY.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('CITY.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    // functions of trash
    restore(_id: number, isAdmin: boolean) {
        let _city = Object.assign({}, this.trashedData.value.find(e => e.id == _id));
        if (isAdmin) {
            _city = Object.assign({}, this.aTrashedData.value.find(e => e.id == _id));
            _city.deleted_at = null;
        }
        _city.user_deleted = null;
        this.store.dispatch(new CityUpdatedOnServer({
            city: _city
        }));
    }

    delete(_id: number) {
        // this.store.dispatch(new CityDeleted({id: _id}));
        const _city = Object.assign({}, this.trashedData.value.find(el => el.id == _id));
        _city.deleted_at = new Date();
        _city.deleted_by = this.currentUser.id;
        this.store.dispatch(new CityUpdatedOnServer({
            city: _city
        }));
    }

    hardDelete(_id: number) {
        this.store.dispatch(new CityDeleted({id: _id}));
    }

    flush() {
        const trashedItemIds = [];
        const updateCities: Update<CityModel>[] = [];
        this.trashedData.value.forEach(item => {
            const _city = Object.assign({}, item);
            _city.deleted_at = new Date();
            updateCities.push({
                id: _city.id,
                changes: _city
            });
            trashedItemIds.push(item.id);
        });
        this.store.dispatch(new CityTrashFlushed({
            countryId: this.country.id ? this.country.id : -1,
            cityIds: trashedItemIds,
            cities: updateCities
        }));
    }
    countUnique(iterable) {
        return new Set(iterable).size;
    }
    calcTableTops(res) {
        this.cityStatusCnts$.next({
            total_cities: res.length.toString(),
            total_different_countries: this.countUnique(res.map(item => item.country_id)).toString(),
            total_used_in_vals: res.reduce((total, item) => total + (item.used_times > 0 ? 1 : 0), 0).toString(),
        })
    }
}
