// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {NotificationsState} from '../_reducers/notification.reducers';
import {each} from 'lodash';
import {NotificationModel} from '../_models/notification.model';


export const selectNotificationState = createFeatureSelector<NotificationsState>('notifications');

export const selectNotificationById = (userId: number) => createSelector(
    selectNotificationState,
    notificationState => notificationState.entities[userId]
);


export const selectExistNotificationInStore = createSelector(
    selectNotificationState,
    notificationState => notificationState.totalCount != 0
);

export const selectNotificationInStore = createSelector(
    selectNotificationState,
    notificationState => {
        const items: NotificationModel[] = [];
        each(notificationState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Notification[] = httpExtension.sortArrayDateTime(items, 'created_at', 'desc');
        return new QueryResultsModel(result, notificationState.totalCount, '');
    }
);


export const selectNewNotificationCount = createSelector(
    selectNotificationState,
    notificationState => {
        let _cnt = 0;
        each(notificationState.entities, element => {
            _cnt += Number(element.is_new);
        });
        return _cnt;
    }
);


export const selectHasNotificationInStore = createSelector(
    selectNotificationState,
    queryResult => {
        return queryResult.totalCount;


    }
);
