import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, TypesUtilsService, QueryParamsFacilityModel } from '../../../../core/_base/crud';
import { Store, select } from '@ngrx/store';
import { debounceTime, delay, distinctUntilChanged, take, tap } from 'rxjs/operators';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import {
    Facility, FacilityDeleted, FacilitiesDataSource, FacilitiesPageRequested,
    FacilitiesService, FacilitiesClear, selectTrashedFacilityCount, selectAdminTrashedFacilityCount,
    FacilitiesActionToggleLoading,
    FacilityOnServerAdminRestored,
    FacilityOnServerRestored,
    FacilityDeletedFromTrash,
    FacilityDeletedFromAdminTrash,
    FacilityTrashFlushed,
    FacilityUpdated,
} from '../../../../core/linked-tables';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
    selector: 'kt-facilities-list',
    templateUrl: './facilities-list.component.html',
    styleUrls: ['./facilities-list.component.scss']
})
export class FacilitiesListComponent implements OnInit, OnDestroy {

    dataSource: FacilitiesDataSource;
    displayedColumns = ['id', 'name', 'is_hidden', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<Facility>(true, []);
    facilityResult: Facility[] = [];
    title = ' Facilities';
    acTypeId = 0;

    trashCnt: number = 0;
    adminTrashCnt: number = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];
    private isBuilding = 0;
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param facilitiesService
     * @param subheaderService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: FacilitiesService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                public typesUtilsService: TypesUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            if (params.ac_type_id) {
                this.acTypeId = Number(params.ac_type_id);
                this.title = this.typesUtilsService.getAssetClassTypeName(params.ac_type_id) + this.title + (params.ac_type_id == '3' ? ' (common use)' : params.ac_type_id == '12' ? ' (exclusive use)' : '');
            }
        });
        this.subscriptions.push(routeSubscription);
        

        if (this.router.url.indexOf('building') > -1) {
            this.isBuilding = 1;
            this.title = 'Building Facility';
        }

        this.subheaderService.setTitle('Facilities');
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Facilities', page: `../default/admin-management/linked-tables/facilities`}
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadFacilitiesList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadFacilitiesList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource

        this.store.dispatch(new FacilitiesClear());
        this.dataSource = new FacilitiesDataSource(this.store, this.isBuilding, this.acTypeId);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            // skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.facilityResult = res;
            
        });

        this.subscriptions.push(entitiesSubscription);

        // trash count
        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedFacilityCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedFacilityCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadFacilitiesList();
        }); // Remove this line, just loading imitation
    }

    /**
     * Load Facilities List from service through data-source
     */
    loadFacilitiesList() {
        this.selection.clear();
        const queryParams = new QueryParamsFacilityModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.isBuilding,
            this.acTypeId
        );
        // Call request from server
        this.store.dispatch(new FacilitiesPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {
            with_hidden: true
        };
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadFacilitiesList();
    }

    /**
     * Show add facility dialog
     */
    addFacility() {
        this.editFacility(null);
    }

    /**
     * Show Edit facility view and save after success close result
     * @param facility
     */
    editFacility(_facility) {
        if (_facility) {
            this.router.navigate(['edit', _facility.id], {relativeTo: this.activatedRoute});
            return;
        }
        this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    deleteFacility(_item: Facility) {
        const _title = 'Are you sure?';
        const _description: string = _item.name + ' will be deleted';
        const _waitDesciption = this.title + ' deleting';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new FacilityDeleted({id: _item.id}));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new FacilitiesActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new FacilitiesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new FacilitiesActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new FacilitiesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = 'Deleted ' + this.title;
        if (isAdmin) {
            _title = 'Admin Deleted ' + this.title;
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new FacilityOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new FacilityOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new FacilityDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new FacilityDeletedFromAdminTrash({id}));
    }

    flush() {
        
        this.store.dispatch(new FacilityTrashFlushed());
    }

    viewEntry(item: Facility) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }

    onHiddenChange(event: MatCheckboxChange, item: Facility) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new FacilityUpdated({
            facility: _item,
            partialFacility: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
