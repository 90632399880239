// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {NotificationActions, NotificationActionTypes} from '../_actions/notificaiton.actions';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {NotificationModel} from '../_models/notification.model';

// tslint:disable-next-line:no-empty-interface
export interface NotificationsState extends EntityState<NotificationModel> {
    listLoading: boolean;
    actionLoading: boolean;
    loaded: boolean;
    totalCount: number;
    lastCreatedNotificationId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<NotificationModel> = createEntityAdapter<NotificationModel>();

export const initialNotificationsState: NotificationsState = adapter.getInitialState({
    listLoading: false,
    actionLoading: false,
    loaded: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedNotificationId: undefined,
    showInitWaitingMessage: true,
    notificationExistsStatus: false
});

export function notificationsReducer(state = initialNotificationsState, action: NotificationActions): NotificationsState {
    switch (action.type) {
        case NotificationActionTypes.NotificationDeleted:
            return adapter.removeOne(action.payload.id, state);

        case NotificationActionTypes.NotificationUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case NotificationActionTypes.NotificationAdded:
            return adapter.addOne(action.payload.item, {
                ...state,
                totalCount: state.totalCount + 1
            });

        case NotificationActionTypes.NotificationClear:
            return adapter.removeAll(initialNotificationsState);

        case NotificationActionTypes.NotificationLoaded: {
            return adapter.addMany(action.payload.items, {
                ...initialNotificationsState,
                totalCount: action.payload.items.length,
            });
        }
        default:
            return state;
    }
}

export const getNotificationState = createFeatureSelector<NotificationsState>('notifications');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
