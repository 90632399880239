import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { CriterionType } from "../_models/criterion-type.model";
import { CriterionModel } from "../_models/criterion.model";
import { CriterionsState } from "../_reducers/criterion.reducer";

export const selectCriterionsState = createFeatureSelector<CriterionsState>('criterions');

export const selectAllCriterions = createSelector(
    selectCriterionsState,
    criterionsState => {
        const items: CriterionModel[] = [];
        each(criterionsState.entities, element => {
            items.push(element);
        });
        return items;
    }
)

export const selectActiveCriterions = createSelector(
    selectCriterionsState,
    criterionsState => {
        const items: CriterionModel[] = [];
        each(criterionsState.entities, element => {
            items.push(element);
        });
        return items.filter(item => item.active);
    }
)

export const selectOtherCriterions = createSelector(
    selectCriterionsState,
    criterionsState => {
        const items: CriterionModel[] = [];
        each(criterionsState.entities, element => {
            items.push(element);
        });
        return items.filter(item => item.type === CriterionType.Other); 
    }
)

export const selectSingleCriterion = (id: number) => createSelector(
    selectCriterionsState,
    criterionsState => criterionsState.entities[id]
)

export const selectLastCreatedCriterionID = createSelector(
    selectCriterionsState,
    criterionsState => criterionsState.lastCreatedCriterionID
)

export const selectIsCriterionLoading = createSelector(
    selectCriterionsState,
    state => state.isLoading
)