import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { PictureModel } from 'src/app/core/valuation-process';
import { getPictureUrl } from 'src/app/core/valuation-process/_models/valuation-process-target-property.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-picture-slider-dialog',
  templateUrl: './picture-slider-dialog.component.html',
  styleUrls: ['./picture-slider-dialog.component.scss']
})
export class PictureSliderDialogComponent implements OnInit {

  title: string = 'Picture'
  pictures: {
    url: string,
    title: string,
    id: string
  }[] = []

  constructor(
    private dialogRef: MatDialogRef<PictureSliderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      pictures: PictureModel[],
      initIndex: string,
      title?: string
    },
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.title = this.data.title ?? 'Picture'
    this.pictures = this.data.pictures.map((picture, index) => ({
      url: environment.baseApiUrl + getPictureUrl(picture, this.subDomainService.subDomain),
      title: picture.title,
      id: index.toString()
    }))
  }

  close() {
    this.dialogRef.close()
  }

  onImageError(event) {
    event.target.src = awConst.IMAGE_NOT_FOUND
  }
}
