<div class="mad-container">
    <div ktPortletTitle class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">LAND USE</h5>
        <hr class="active">
    </div>
    
    <div class="form" [formGroup]="form">

        <section class="row">
            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Please indicate here the land cover type" formControlName="land_cover_type"/>
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.COVER_TYPE.DESCRIPTION' | translate">help
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Quality of soil and degradation" formControlName="qos_degradation_id">
                        <mat-option *ngFor="let qosDegradation of qosDegradations" [value]="qosDegradation.id">{{qosDegradation.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Quality of soil and degradation
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Please indicate here the comparative land use" formControlName="comparative_land_use_id">
                        <mat-option *ngFor="let comparativeLandUse of comparativeLandUses" [value]="comparativeLandUse.id">{{comparativeLandUse.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Comparative land use
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Source of land use classification" formControlName="sol_use_classification_id">
                        <mat-option *ngFor="let solUseClassification of solUseClassifications" [value]="solUseClassification.id">{{solUseClassification.name}}</mat-option>
                    </mat-select>
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.SOL_CLASSIFICATION.DESCRIPTION' | translate">help
                    </mat-icon>
                    <mat-error>
                        Source of land use classification
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Other characteristics" formControlName="other_characteristics"/>
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.OTHER_CLASSIFICATION.DESCRIPTION' | translate">help
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Date of land use approval and implementation" formControlName="land_use_approval_implementation"/>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-20-mobile mt-4 mb-2">
                <div class="mb-4">
                    <mat-label>
                        Please indicate here whether there are existing building on the land parcel 
                    </mat-label>
                </div>
                <div class="mb-4">
                    <mat-radio-group class="tp-radio-group" formControlName="building_exist">
                        <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                          *ngFor="let decision of decisionsExtra" [value]="decision.value">
                            {{decision.label}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.building_exist.value && form.controls.building_exist.value == '1'">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="How many? " formControlName="building_count" type="number" min="1" ng-min="buildingMin" ng-max="buildingMax" (input)="onInput($event)" (change)="onChangeEvent($event)"/>
                    <mat-error *ngIf="form.controls.building_count.errors?.min || form.controls.building_count.errors?.max">
                        Value must be between {{buildingMin}} to {{buildingMax}}
                    </mat-error>
                </mat-form-field>
            </div>
        </section>
    </div>
</div>