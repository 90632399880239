// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {UnitAreaMeasurementsService} from '../_services/unit-area-measurement.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allUnitAreaMeasurementsLoaded} from '../_selectors/unit-area-measurement.selectors';
// Actions
import {
    AllUnitAreaMeasurementsLoaded,
    AllUnitAreaMeasurementsRequested,
    UnitAreaMeasurementActionTypes,
    UnitAreaMeasurementsPageRequested,
    UnitAreaMeasurementsPageLoaded,
    UnitAreaMeasurementUpdated,
    UnitAreaMeasurementsPageToggleLoading,
    UnitAreaMeasurementDeleted,
    UnitAreaMeasurementOnServerCreated,
    UnitAreaMeasurementCreated,
    UnitAreaMeasurementAdminRestored,
    UnitAreaMeasurementDeletedFromAdminTrash,
    UnitAreaMeasurementDeletedFromTrash,
    UnitAreaMeasurementOnServerAdminRestored,
    UnitAreaMeasurementOnServerRestored,
    UnitAreaMeasurementRestored,
    UnitAreaMeasurementTrashFlushed,
    UnitAreaMeasurementsActionToggleLoading
} from '../_actions/unit-area-measurement.actions';

@Injectable()
export class UnitAreaMeasurementEffects {
    showPageLoadingDispatcher = new UnitAreaMeasurementsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new UnitAreaMeasurementsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new UnitAreaMeasurementsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllUnitAreaMeasurements$ = this.actions$
        .pipe(
            ofType<AllUnitAreaMeasurementsRequested>(UnitAreaMeasurementActionTypes.AllUnitAreaMeasurementsRequested),
            withLatestFrom(this.store.pipe(select(allUnitAreaMeasurementsLoaded))),
            filter(([action, isAllUnitAreaMeasurementsLoaded]) => !isAllUnitAreaMeasurementsLoaded),
            mergeMap(() => this.service.getAllUnitAreaMeasurements()),
            map(res => {
                return new AllUnitAreaMeasurementsLoaded({unitAreaMeasurements: res.data});
            })
        );

    @Effect()
    loadUnitAreaMeasurementsPage$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementsPageRequested>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findUnitAreaMeasurements(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new UnitAreaMeasurementsPageLoaded({
                    unitAreaMeasurements: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementDeleted>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteUnitAreaMeasurement(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementUpdated>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateUnitAreaMeasurement(payload.unitAreaMeasurement);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementOnServerCreated>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createUnitAreaMeasurement(payload.unitAreaMeasurement).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitAreaMeasurementCreated({unitAreaMeasurement: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushUnitAreaMeasurementTrash$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementTrashFlushed>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementOnServerRestored>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitAreaMeasurementRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementOnServerAdminRestored>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitAreaMeasurementAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementDeletedFromTrash>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashUnitAreaMeasurement$ = this.actions$
        .pipe(
            ofType<UnitAreaMeasurementDeletedFromAdminTrash>(UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: UnitAreaMeasurementsService, private store: Store<AppState>) {
    }
}
