// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ConservatoryPorcheActions, ConservatoryPorcheActionTypes} from '../_actions/conservatory-porche.actions';
// Models
import {ConservatoryPorcheModel} from '../_models/conservatory-porche.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ConservatoryPorchesState extends EntityState<ConservatoryPorcheModel> {
    isAllConservatoryPorchesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ConservatoryPorcheModel[];
    lastCreatedConservatoryPorcheId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ConservatoryPorcheModel> = createEntityAdapter<ConservatoryPorcheModel>();

export const initialConservatoryPorcheState: ConservatoryPorchesState = adapter.getInitialState({
    isAllConservatoryPorchesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedConservatoryPorcheId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function conservatoryPorcheReducer(state = initialConservatoryPorcheState, action: ConservatoryPorcheActions): ConservatoryPorchesState {
    switch (action.type) {
        case ConservatoryPorcheActionTypes.ConservatoryPorchesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedConservatoryPorcheId: undefined
            };
        case ConservatoryPorcheActionTypes.ConservatoryPorcheActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerCreated:
            return {
                ...state
            };
        case ConservatoryPorcheActionTypes.ConservatoryPorcheCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedConservatoryPorcheId: action.payload.item.id
            });
        case ConservatoryPorcheActionTypes.ConservatoryPorcheUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ConservatoryPorcheActionTypes.AllConservatoryPorchesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllConservatoryPorchesLoaded: true
            });
        case ConservatoryPorcheActionTypes.ConservatoryPorchesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ConservatoryPorcheActionTypes.ConservatoryPorchesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialConservatoryPorcheState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ConservatoryPorcheActionTypes.ConservatoryPorcheDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ConservatoryPorcheActionTypes.ConservatoryPorcheRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ConservatoryPorcheActionTypes.ConservatoryPorcheDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ConservatoryPorcheActionTypes.ConservatoryPorcheAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ConservatoryPorcheActionTypes.ConservatoryPorcheDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ConservatoryPorcheActionTypes.ConservatoryPorcheTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
