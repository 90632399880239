import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { awConst } from 'src/app/app.constants';
import { PictureModel } from 'src/app/core/valuation-process';
import { ViewModel } from '../comparable-info-dialog-v2/comparable-info-dialog-v2.component';
import { PictureSliderDialogComponent } from '../picture-slider-dialog/picture-slider-dialog.component';

@Component({
  selector: 'kt-considerations-info-table-v2',
  templateUrl: './considerations-info-table-v2.component.html',
  styleUrls: ['./considerations-info-table-v2.component.scss']
})
export class ConsiderationsInfoTableV2Component implements OnInit {
  @Input() refNums: string[]
  @Input() isMulti: boolean
  @Input() considerationsInfo: ViewModel['considerationsInfo']
  @Output() considerationChange: EventEmitter<{refNum: string, id: number}> = new EventEmitter()

  NOTSELECTED = 'Not Selected'
  UNKNOWN = 'Unknown'

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onSelectionChange(event: MatSelectChange, refNum: string) {
    this.considerationChange.emit({refNum, id: event.value})
  }

  onImageError(event) {
    event.target.src = awConst.IMAGE_NOT_FOUND
  }

  public showThumbnail(pictures: PictureModel[], index: number, modalTitle?: string) {
    this.dialog.open(PictureSliderDialogComponent, {
      data: {
        pictures: pictures,
        initIndex: index.toString(),
        title: modalTitle
      },
      width: '60vw'
    })
  }
}
