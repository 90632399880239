import { Injectable } from "@angular/core";
import { getWindow } from "../mad-auth/mad-auth.service";

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  private _zE: Function = null;
  constructor() {}

  load() {
    const window = getWindow()
    this._zE = window.zE
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script')
      script.id = "ze-snippet"
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=36666877-8108-4d5e-82b4-8d180751550e"
      script.onload = () => {
        resolve({})
      }

      document.body.appendChild(script)
    })
  }

  login(jwt: string) {
    if (!this._zE) {
      console.error('Zendesk SDK not loaded')
    }
    const window = getWindow()
    window.zE('messenger', 'loginUser', function (callback) {
      callback(jwt)
    })
  }

  logout() {
    if (!this._zE) {
      console.error('Zendesk SDK not loaded')
    }
    (window as any).zE('messenger', 'logoutUser')
  }

  hide() {
    if (!this._zE) {
      console.error('Zendesk SDK not loaded')
    }
    // console.log(this._zE)
    // this._zE('messenger', 'hide')
    (window as any).zE('messenger', 'hide')
  }

  converSeationFieldPlanInfo(plan_info) {
    const window = getWindow()
    if (typeof window.zE !== "undefined") {
      window.zE('messenger:set', 'conversationFields', [{ id: '39393549721625', value: plan_info}])
    }
  }

  converSeationFieldAccountId(account_id) {
    const window = getWindow()
    if (typeof window.zE !== "undefined") {
      window.zE('messenger:set', 'conversationFields', [{ id: '39500438719513', value: account_id}])
    }
  }

  converSeationFieldCurrentRoute(route) {
    const window = getWindow()
    if (typeof window.zE !== "undefined") {
      window.zE('messenger:set', 'conversationFields', [{ id: '39394327689753', value: route}])
      window.zE("messenger:set", "conversationTags", route.replace('http://', '').replace('https://', '').split('/'))
    }
  }

  converSeationFieldOrg(org) {
    const window = getWindow()
    if (typeof window.zE !== "undefined") {
      window.zE('messenger:set', 'conversationFields', [{ id: '39644759733017', value: org}])
    }
  }
}
