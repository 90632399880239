
export interface ReportFont {
    id: number;
    name: string;
}

export enum ReportType {
    ToE,
    InformedConsent,
    Amendment,
    Investigation,
    DraftStatement,
    Valuation,
    AuditTrail,
    Invoices,
    Default
}

export interface ReportScheme {
    id?: number;
    type: ReportType;
    name: string;
    primaryColor: string | null;
    footerColor: string | null;
    fontId: number;
}