import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutConfigService, SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {AllAssetClassTypesRequested, AssetClassType, BuiltinFittingUpdated, selectAllAssetClassTypes} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import {
    BuiltinFitting,
    BuiltinFittingOnServerCreated,
    selectBuiltinFittingsActionLoading,
    selectBuiltinFittingById,
    selectLastCreatedBuiltinFittingId
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';
import {combineLatest} from 'rxjs'

@Component({
    selector: 'kt-builtin-fitting-edit',
    templateUrl: './builtin-fitting-edit.component.html',
    styleUrls: ['./builtin-fitting-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})


export class BuiltinFittingEditComponent implements OnInit, OnDestroy {

    model: BuiltinFitting;
    oldModel: BuiltinFitting;

    builtinFittingForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    readonly = false
    propertySubTypes: AssetClassType[] = [];
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param layoutUtilsService: LayoutUtilsService
     * @param subheaderService: SubheaderService
     * @param store: Store<AppState>
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private layoutUtilsService: LayoutUtilsService,
                public subheaderService: SubheaderService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
    }

    ids = [1, 3, 5, 7, 11, 17]
    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectBuiltinFittingsActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectBuiltinFittingById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initBuiltinFitting();
                    }
                });
            } else {
                this.model = new BuiltinFitting();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initBuiltinFitting();
            }
        });
        this.subscriptions.push(routeSubscription);

        this.store.dispatch(new AllAssetClassTypesRequested())
        this.store.select(selectAllAssetClassTypes).subscribe(res => {
          this.propertySubTypes = [];
          if (res) {
            this.propertySubTypes = res.filter(item => this.ids.includes(item.id));
          }
        })
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initBuiltinFitting() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.builtinFittingForm = this.fb.group({
            name: [this.model.name, Validators.required],
            property_sub_type_id: [this.model.property_sub_type_id, Validators.required],
        });
    }

    /**
     * Returns page title
     */
     getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return this.translate.instant('BUILTIN_FITTING.FORM.TITLE.EDIT', {name: this.model.name});
        }

        return this.translate.instant('BUILTIN_FITTING.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.builtinFittingForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.builtinFittingForm.markAsPristine();
        this.builtinFittingForm.markAsUntouched();
        this.builtinFittingForm.updateValueAndValidity();
    }

    prepareBuiltinFitting(): BuiltinFitting {
        const controls = this.builtinFittingForm.controls;
        const _model = new BuiltinFitting();
        _model.clear();
        _model.id = this.model.id;
        _model.name = controls.name.value;
        _model.property_sub_type_id = controls.property_sub_type_id.value;
        _model.is_hidden = this.model.is_hidden

        return _model;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.builtinFittingForm.controls;
        /** check form */
        if (this.builtinFittingForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedFacility = this.prepareBuiltinFitting();
        if (this.model.id > 0) {
            this.updateBuiltinFitting(editedFacility);
        } else {
            this.createBuiltinFitting(editedFacility);
        }
    }

    /**
     * Update model
     *
     * @param _model: BuiltinFittingModel
     */
    updateBuiltinFitting(_model: BuiltinFitting) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateBuiltinFitting: Update<BuiltinFitting> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new BuiltinFittingUpdated({
            partialBuiltinFitting: updateBuiltinFitting,
            builtinFitting: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();

    }

    /**
     * Create model
     *
     * @param _model: BuiltinFittingModel
     */
    createBuiltinFitting(_model: BuiltinFitting) {
        this.store.dispatch(new BuiltinFittingOnServerCreated({builtinFitting: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedBuiltinFittingId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && 
            this.model.name && this.model.name.length > 0 &&
            this.builtinFittingForm && this.builtinFittingForm.controls.property_sub_type_id.value);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }

}
