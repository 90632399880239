// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../reducers';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// Selectirs
import {
    selectAppendixCategoriesPageLoading,
    selectAppendixCategoriesQueryResult,
    selectAppendixCategoriesShowInitWaitingMessage
} from '../_selectors/appendix-category.selectors';

export class AppendixCategoriesDataSource extends BaseDataSource {
    trashed = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAppendixCategoriesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAppendixCategoriesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAppendixCategoriesQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
