import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ActivatedRouteSnapshot} from '@angular/router';
import {AppState} from '../../../../../core/reducers';
import {OneToeRequest, ToeService} from '../../../../../core/toe';
import {of} from 'rxjs';
import {OneAssignmentRequest} from '../../../../../core/assignment';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';

@Injectable()
export class ToeActionResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public toeService: ToeService,
                private global: Globals) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (this.global.activeAssignmentId && this.global.activeAssignmentId > 0) {
            this.store.dispatch(new OneAssignmentRequest({id: this.global.activeAssignmentId}));
        }

        if (this.global.activeTOEId && this.global.activeTOEId > 0) {
            this.store.dispatch(new OneToeRequest({id: this.global.activeTOEId}));
        }

        return of(null);
    }
}
