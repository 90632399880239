import {Component,  OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import { SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {RentReviewCommentUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import {
    RentReviewComment,
    RentReviewCommentOnServerCreated,
    selectRentReviewCommentsActionLoading,
    selectRentReviewCommentById,
    selectLastCreatedRentReviewCommentId
} from '../../../../core/linked-tables';

@Component({
    selector: 'kt-rent-review-comment-edit',
    templateUrl: './rent-review-comment-edit.component.html',
    styleUrls: ['./rent-review-comment-edit.component.scss']
})


export class RentReviewCommentEditComponent implements OnInit, OnDestroy {

    rentReviewComment: RentReviewComment;
    oldRentReviewComment: RentReviewComment;

    rentReviewCommentForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                public subheaderService: SubheaderService,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectRentReviewCommentsActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectRentReviewCommentById(id))).subscribe(res => {
                    if (res) {
                        this.oldRentReviewComment = Object.assign({}, res);
                        this.rentReviewComment = Object.assign({}, this.oldRentReviewComment);
                        this.initRentReviewComment();
                    }
                });
            } else {
                this.rentReviewComment = new RentReviewComment();
                this.rentReviewComment.clear();
                this.initRentReviewComment();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Rent Review Comments', page: `../default/admin-management/linked-tables/rent-review-comments`},
            {
                title: this.getComponentTitle(),
                page: id > 0 ? `../default/admin-management/linked-tables/rent-review-comments/edit/${id}`
                    : `../default/admin-management/linked-tables/rent-review-comments/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initRentReviewComment() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.rentReviewCommentForm = this.fb.group({
            name: [this.rentReviewComment.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.rentReviewComment && this.rentReviewComment.id > 0) {
            this.mode = 'EDIT';
            return `Edit Rent review comment '${
                this.rentReviewComment.name
                }'`;
        }

        return 'New Rent review comment';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.rentReviewCommentForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.rentReviewComment = Object.assign({}, this.oldRentReviewComment);
        this.createForm();
        this.hasFormErrors = false;
        this.rentReviewCommentForm.markAsPristine();
        this.rentReviewCommentForm.markAsUntouched();
        this.rentReviewCommentForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.rentReviewCommentForm.controls;
        /** check form */
        if (this.rentReviewCommentForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.rentReviewComment.id > 0) {
            this.updateRentReviewComment(this.rentReviewComment);
        } else {
            this.createRentReviewComment(this.rentReviewComment);
        }
    }

    /**
     * Update RentReviewComment
     *
     * @param _model: RentReviewCommentModel
     */
    updateRentReviewComment(_model: RentReviewComment) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const _updateRentReviewComment: Update<RentReviewComment> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new RentReviewCommentUpdated({
            partialRentReviewComment: _updateRentReviewComment,
            rentReviewComment: _model
        }));
        this.oldRentReviewComment = _model;

        this.navigateToParent();

    }

    /**
     * Create RentReviewComment
     *
     * @param _model: RentReviewCommentModel
     */
    createRentReviewComment(_model: RentReviewComment) {
        this.store.dispatch(new RentReviewCommentOnServerCreated({rentReviewComment: _model}));
        this.oldRentReviewComment = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedRentReviewCommentId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return this.rentReviewComment && this.rentReviewComment.name && this.rentReviewComment.name.length > 0;
    }

    navigateToParent() {
        let url = '../';
        if (this.rentReviewComment.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

}
