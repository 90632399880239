import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Update} from '@ngrx/entity';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../core/_base/crud';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {
    Client, selectClientsActionLoading, selectLastCreatedClientId, ClientOnServerCreated,
    selectClientById, ClientContactModel, selectAllCountries, CountryModel, CityModel, selectCitiesByCountryId,
    ClientUpdated, AllCountriesRequested, AllCitiesRequested, RegNumberModel
} from '../../../../../core/admin';
import {Location} from '@angular/common';
import {each} from 'lodash';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CountryEditComponent } from '../../countries/country-edit/country-edit.component';
import { CityEditComponent } from '../../countries/cities/city-edit/city-edit.component';
import { ContactListComponent } from '../../../shared_components/client/_subs/client_contact/contact-list/contact-list.component';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
    selector: 'kt-clients-edit',
    templateUrl: './client-edit.component.html',
    styleUrls: ['./client-edit.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class ClientEditComponent implements OnInit, OnDestroy {

    client: Client;
    oldClient: Client;
    @ViewChild(ContactListComponent, {static: false}) contactComponent: ContactListComponent;

    clientForm: UntypedFormGroup;
    hasFormErrors = false;
    hasFormErrorsDesc = '';

    // Countries
    private _allCountries: CountryModel[] = [];
    public countries: CountryModel[] = [];
    private _cityOfCountry: CityModel[] = [];
    public cities: CityModel[] = [];
    regNumbersSubject: BehaviorSubject<RegNumberModel[]> = new BehaviorSubject([]);

    loading$: Observable<boolean>;
    // Private properties
    private componentSubscriptions: Subscription;

    private subscriptions: Subscription[] = [];

    validationMessages: any;
    // validationMessages: any = {
    //     email: [
    //         {type: 'required', message: this.translate.ge, decorated: 'required'},
    //         {type: 'email', message: 'Enter a valid ', decorated: 'email'}
    //     ],
    //     phone: [
    //         {type: 'required', message: 'Phone Number is ', decorated: 'required'},
    //         {type: 'pattern', message: 'Enter a valid ', decorated: 'phone number'}
    //     ]
    // };
    emailRegex: any = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
    countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    cityFilterCtrl: UntypedFormControl = new UntypedFormControl();
    private _onDestroy$: Subject<void> = new Subject();
    canEdit$ = this.store.select(hasPermission(['client:crud']))

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param fb: FormBuilder
     * @param location
     * @param subheaderService
     * @param layoutUtilsService
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private dialog: MatDialog,
                private translate: TranslateService) {
        this.validationMessages = {
            email: [
                {
                    type: 'required',
                    message: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.REQUIRED.MESSAGE'),
                    decorated: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.REQUIRED.DECORATION')
                }, {
                    type: 'pattern',
                    message: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.PATTERN.MESSAGE'),
                    decorated: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.PATTERN.DECORATION')
                }
            ]
        };
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectClientsActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectClientById(id))).subscribe(res => {
                    if (res) {
                        this.oldClient = Object.assign({}, res);
                        this.client = Object.assign({}, this.oldClient);
                        this.initClient();
                    } else {
                        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
                        return;
                    }
                });
            } else {
                this.client = new Client();
                this.client.clear();
                this.oldClient = Object.assign({}, this.client);
                this.initClient();
            }
            this.regNumbersSubject.next(this.client.reg_numbers);
        });
        this.subscriptions.push(routeSubscription);

        this.store.dispatch(new AllCountriesRequested());
        this.store.dispatch(new AllCitiesRequested());
        const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
            this._allCountries = [];
            each(res, (_country: CountryModel) => {
                this._allCountries.push(_country);
            });
            this._filterCountries();
        });

        this.subscriptions.push(countrySubscription);

        this.countryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(() => {
                this._filterCountries()
            });
        this.cityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(() => {
                this._filterCities();
            })
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    private _filterCountries() {
        let search = this.countryFilterCtrl.value;
        if (!search || search === '') {
            this.countries = this._allCountries.slice();
            return;
        } else {
            search = search.toLowerCase();
        }

        this.countries = this._allCountries.filter(c => c.name.toLowerCase().indexOf(search) > -1);
    }

    private _filterCities() {
        let search = this.cityFilterCtrl.value;
        if (!search || search === '') {
            this.cities = this._cityOfCountry.slice();
            return;
        } else {
            search = search.toLowerCase();
        }

        this.cities = this._cityOfCountry.filter(c => c.name.toLowerCase().indexOf(search) > -1);
    }

    addCountry() {
        const dialogRef = this.dialog.open(CountryEditComponent, {data: {id: undefined}});
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
            if (!res) {
                return;
            }
            this.clientForm.controls.country_id.setValue(res.id);
            this.clientForm.controls.country_id.updateValueAndValidity();
        })
    }

    addCity() {
        const newCity = new CityModel();
        newCity.clear();
        newCity.country_id = this.clientForm.controls.country_id.value;
        const country = this._allCountries.find(c => c.id == newCity.country_id);
        newCity.country_name = country ? country.name : null;
        const dialogRef = this.dialog.open(CityEditComponent, {data: {city: newCity}});
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
            if (!res) {
                return;
            }
            this.clientForm.controls.city_id.setValue(res.id);
            this.clientForm.controls.city_id.updateValueAndValidity();
            this.selectCountry();
        })
    }

    initClient() {
        this.createForm();
        if (!this.client.id) {
            this.subheaderService.setTitle('New Client');
            this.subheaderService.setBreadcrumbs([
                {title: 'Admin management', page: `../default/admin-management`},
                {title: 'Clients', page: `../default/admin-management/clients`},
                {title: 'Add Client', page: `../default/admin-management/clients/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit Client');
        this.subheaderService.setBreadcrumbs([
            {title: 'Admin management', page: `../default/admin-management`},
            {title: 'Clients', page: `../default/admin-management/clients`},
            {title: 'Add Client', page: `../default/ecommerce/products/edit`}
        ]);
    }


    createForm() {
        this.clientForm = this.fb.group({
            name: [this.client.name, Validators.required],
            last_name: [this.client.last_name],
            phone: [this.client.phone],
            email: [this.client.email],
            country_id: [this.client.country_id, Validators.required],
            city_id: [this.client.city_id, Validators.required],
            zip_code: [this.client.zip_code],

            type: [{value: this.client.type, disabled: this.client.id}, Validators.required],
            address: [this.client.address, Validators.required],
        });
        if (this.client.country_id > 0) {
            this.selectCountry();
        }

        const typeCtrl = this.clientForm.controls.type;
        const changes$ = typeCtrl.valueChanges;

        changes$.subscribe(type => {
            if (type === 'Company') {
                this.clientForm.controls.last_name.clearValidators();
                this.clientForm.controls.phone.clearValidators();
                this.clientForm.controls.email.clearValidators();
            } else {
                this.clientForm.controls.last_name.setValidators([Validators.required]);
                this.clientForm.controls.phone.setValidators([Validators.required]);
                this.clientForm.controls.email.setValidators(Validators.compose([Validators.required, Validators.pattern(this.emailRegex)]));
            }
            this.clientForm.controls.last_name.updateValueAndValidity();
            this.clientForm.controls.phone.updateValueAndValidity();
            this.clientForm.controls.email.updateValueAndValidity();
        });
    }

    selectCountry() {
        // this.client.country_id
        this.store.pipe(select(selectCitiesByCountryId(this.clientForm.controls.country_id.value))).subscribe((res: CityModel[]) => {
            this._cityOfCountry = res;
            this._filterCities();
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): Observable<string> {
        return this.canEdit$.pipe(
            map(canEdit => {
                let title = null;
                if (this.client && this.client.id > 0) {
                    title = this.translate.instant('CLIENT.FORM.TITLE.EDIT', {name: this.client.name});
                } else {
                    title = this.translate.instant('CLIENT.FORM.TITLE.NEW');
                }
                return `${title}` + (canEdit ? '' : ' (View Only)')
            })
        )
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.clientForm.controls;
        /** check form */
        if (this.clientForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                if (!controls[controlName].valid) {
                }
                controls[controlName].markAsTouched();
            });

            this.hasFormErrorsDesc = this.translate.instant('GENERAL.MESSAGE.FORM_WARNING');
            this.hasFormErrors = true;
            return;
        }

        if (this.client.type == 'Company' && this.contactComponent.contactData.length == 0) {

            this.hasFormErrorsDesc = this.translate.instant('CLIENT.FORM.FIELD.GENERAL.CONTACTS.VALIDATION');
            this.hasFormErrors = true;
            return;
        }

        this.client.reg_numbers = this.regNumbersSubject.value;

        if (this.client.id > 0) {
            this.updateClient(this.client);
        } else {
            this.createClient(this.client);
        }
    }

    /**
     * Update client
     *
     * @param _client: ClientModel
     */
    updateClient(_client: Client) {

        const updateClient: Update<ClientContactModel> = {
            id: _client.id,
            changes: _client
        };
        this.store.dispatch(new ClientUpdated({
            partialClient: updateClient,
            client: _client
        }));
        this.oldClient = _client;
        this.layoutUtilsService.showActionNotification(
            this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'), MessageType.Update, 3000, true, false);

    }

    /**
     * Create client
     *
     * @param _client: ClientModel
     */
    createClient(_client: Client) {

        this.store.dispatch(new ClientOnServerCreated({
            client: _client,
            contacts: this.contactComponent ? this.contactComponent.contactData : []
        }));
        this.oldClient = _client;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedClientId),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            const url = `${this.layoutConfigService.getCurrentMainRoute()}/admin-management/clients`;
            this.router.navigate([url], {relativeTo: this.activatedRoute});
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    back() {
        this.location.back();
    }

    isFormValid() {
        if (!this.client) {
            return false;
        }
        if (this.client.type == 'Company') {
            return (this.client && this.client.name && this.client.name.length > 0) &&
                (this.client && this.client.address && this.client.address.length > 0) &&
                (this.client && this.client.country_id && this.client.country_id > 0) &&
                (this.client && this.client.city_id && this.client.city_id > 0);
        }
        return (this.client && this.client.name && this.client.name.length > 0) &&
            (this.client && this.client.last_name && this.client.last_name.length > 0) &&
            (this.client && this.client.phone && this.client.phone.length > 7) &&
            (this.client && this.client.email && this.client.email.length > 0) &&
            (this.client && this.client.address && this.client.address.length > 0) &&
            (this.client && this.client.country_id && this.client.country_id > 0) &&
            (this.client && this.client.city_id && this.client.city_id > 0);
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (!this.client) {
            return false;
        }

        if (this.client.name != this.oldClient.name ||
            this.client.phone != this.oldClient.phone ||
            this.client.email != this.oldClient.email ||
            this.client.country_id != this.oldClient.country_id ||
            this.client.city_id != this.oldClient.city_id ||
            this.client.zip_code != this.oldClient.zip_code ||
            this.client.type != this.oldClient.type ||
            this.client.address != this.oldClient.address) {
            return true;
        } else {
            return false;
        }
    }
}
