import {BuiltinFitting} from '../_models/builtin-fitting.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {BuiltinFittingsState} from '../_reducers/builtin-fitting.reducers';
import * as fromBuiltinFitting from '../_reducers/builtin-fitting.reducers';
import {each} from 'lodash';

export const selectBuiltinFittingsState = createFeatureSelector<BuiltinFittingsState>('builtinFittings');

export const selectBuiltinFittingById = (builtinFittingId: number) => createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => builtinFittingsState.entities[builtinFittingId]
);

export const selectAllBuiltinFittings = createSelector(
    selectBuiltinFittingsState,
    fromBuiltinFitting.selectAll
);

export const selectAllBuiltinFittingsIds = createSelector(
    selectBuiltinFittingsState,
    fromBuiltinFitting.selectIds
);

export const allBuiltinFittingsLoaded = createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => builtinFittingsState.isAllBuiltinFittingsLoaded
);


export const selectBuiltinFittingsPageLoading = createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => builtinFittingsState.listLoading
);

export const selectBuiltinFittingsActionLoading = createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => builtinFittingsState.actionLoading
);

export const selectLastCreatedBuiltinFittingId = createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => builtinFittingsState.lastCreatedBuiltinFittingId
);

export const selectBuiltinFittingsShowInitWaitingMessage = createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => builtinFittingsState.showInitWaitingMessage
);

export const selectTrashedBuiltinFittingCount = createSelector(
    selectBuiltinFittingsState,
    (builtinFittingsState) => builtinFittingsState.totalTrashed
);

export const selectAdminTrashedBuiltinFittingCount = createSelector(
    selectBuiltinFittingsState,
    (builtinFittingsState) => builtinFittingsState.totalAdminTrashed
);


export const selectBuiltinFittingQueryResult = createSelector(
    selectBuiltinFittingsState,
    builtinFittingsState => {
        const items: BuiltinFitting[] = [];
        each(builtinFittingsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BuiltinFitting[] = httpExtension.sortArray(items, builtinFittingsState.lastQuery.sortField, builtinFittingsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, builtinFittingsState.totalCount, '', builtinFittingsState.totalTrashed);
    }
);
