<div class="card card-custom">
    <div class="card-body" *ngIf="loaded$|async;">
        <kt-sizes-table
                [readonly]="readonly"
                (removeComparableParent)="removeComparable($event)"
                (showOverviewParent)="showOverview($event)"
                (showMultipleOverviewParent)="showMultipleOverview()"
        ></kt-sizes-table>
        <kt-criterions-table
                class="section-margin-bottom-60"
                [valuation]="valuation"
                [readonly]="readonly"
                [unitOfMeasurement$]="unitOfMeasurement$"
                [targetProperty]="targetProperty"
                (removeComparableParent)="removeComparable($event)"
                (showOverviewParent)="showOverview($event)"
                (showMultipleOverviewParent)="showMultipleOverview()"
                ></kt-criterions-table>

        <kt-adjustment-table
                [readonly]="readonly"
                [assetClassTypeId]="targetProperty.type_id"
                (removeComparableParent)="removeComparable($event)"
                (showOverviewParent)="showOverview($event)">
        </kt-adjustment-table>
        <kt-valuation-table
            [readonly]="readonly"
            [targetProperty]="targetProperty"
            [valuation]="valData ? valData.valuation : null"
            [unitMeasurement$]="unitOfMeasurement$"
            (removeComparableParent)="removeComparable($event)"
            (showOverviewParent)="showOverview($event)"
            (showMultipleOverviewParent)="showMultipleOverview()"></kt-valuation-table>
    </div>
</div>