import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { selectValuationData } from 'src/app/core/valuation/_selectors/valuation-data.selector';

type ModuleData = {
  client: string;
  purposeOfValuation: string;
  basisOfValue: string;
  approach: string;
  method: string;
  premiseOfValue: string;
  tenure: string;
}

@Component({
  selector: 'kt-valuation-module',
  templateUrl: './valuation-module.component.html',
  styleUrls: [
    '../info-module.styles.scss',
    './valuation-module.component.scss'
  ]
})
export class ValuationModuleComponent implements OnInit {
  @Input() targetProperty: AssetClassModel;
  @Input() toe: ToeModel;
  data$: Observable<ModuleData>;
  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      of(this.toe.client_name),
      of(this.toe.purpose_of_valuation_name),
      of(this.toe.base_of_value_name),
      this.store$.select(selectValuationData).pipe(map(val => val ? val.valuation.approaches_to_value_name : undefined), filter(val => val !== undefined)),
      this.store$.select(selectValuationData).pipe(map(val => val ? val.valuation.methods_to_value_name : undefined), filter(val => val !== undefined)),
      this.store$.select(selectValuationData).pipe(map(val => val ? val.valuation.premise_of_value_name : undefined)),
      this.store$.select(selectValuationData).pipe(map(val => val ? val.valuation.tenure_name : undefined))
    ]).pipe(map(([clientName, purposeOfValuation, basisOfValue, approach, method, premiseOfValue, tenure]) => {
      const data: ModuleData = {
        client: clientName,
        purposeOfValuation,
        basisOfValue,
        approach,
        method,
        premiseOfValue,
        tenure
      };
      return data;
    }));
  }

}
