<div>
    <div ktPortletTools class="mb-1 row justify-content-end" [hidden]="readOnly">
        <div *ngIf="(isInProgress$ | async)" class="mr-1">
            <div style="margin-bottom: 14px;">Uploading... {{ progress$ | async }}%</div>
        </div>

        <div class="error" *ngIf="(hasFailed$ | async)" class="mr-1">
            Error: {{ error$ | async }}
        </div>

        <!-- <span *ngIf="(completed$ | async)" class="btn btn-primary mr-1" color="primary" mat-raised-button (click)="resetUpload()">Upload Different Files</span> -->

        <div *ngIf="(isInProgress$ | async)" class="mr-1">
            <span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">Cancel Upload</span>
        </div>

        <!--<div *ngIf="(isReady$ | async) || (hasFailed$ | async) || (completed$ | async)" class="mr-1">-->
        <div class="mr-4">
            <span class="btn btn-primary" color="primary" mat-raised-button (click)="addFiles()">{{addButtonLabel}}</span>
            <input #fileInputDoc type="file" (change)="uploadFile($event)" style="display: none" multiple accept="image/jpeg,image/jpg,image/jp2,image/png,image/bmp,application/pdf"/>
        </div>
    </div>

    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <img [attr.src]="setIcon(getFileType(file.name))" alt="">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Filename</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{excludeExtention(file.name)}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{file.title}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="descr">
                <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{file.descr}}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef> Expiration date</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{file.date && file.date.length >0 ? (file.date | date: 'dd MMM yyyy') : 'N/A'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="check">
                <mat-header-cell *matHeaderCellDef>Automatically insert to all reports</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <mat-checkbox [checked]="file.is_checked" (change)="onchange($event, file)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="is_featured">
                <mat-header-cell *matHeaderCellDef>Featured picture</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <mat-checkbox [checked]="file.is_featured" (change)="onchangeFeatured($event, file)" [disabled]="file.is_featured == 1">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let file; let i = index">
                    <button mat-icon-button
                            color="primary"
                            matTooltip="Edit Description"
                            (click)="editFileDescription(file, i)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            color="warn"
                            matTooltip="Delete File"
                            (click)="deleteUploadedFile(file)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            matTooltip="Preview file"
                            (click)="previewUploadedFile(file)"
                            type="button">
                        <mat-icon *ngIf="!docs.includes(getFileType(file.name))">remove_red_eye</mat-icon>
                        <mat-icon *ngIf="docs.includes(getFileType(file.name))">get_app</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>
