import {IpmsComponent} from '../_models/ipms-component.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {IpmsComponentsState} from '../_reducers/ipms-component.reducers';
import * as fromIpmsComponent from '../_reducers/ipms-component.reducers';
import {each} from 'lodash';

export const selectIpmsComponentsState = createFeatureSelector<IpmsComponentsState>('ipms-components');

export const selectIpmsComponentById = (IpmsComponentId: number) => createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => IpmsComponentsState.entities[IpmsComponentId]
);

export const selectAllIpmsComponents = createSelector(
    selectIpmsComponentsState,
    fromIpmsComponent.selectAll
);

export const selectAllIpmsComponentsIds = createSelector(
    selectIpmsComponentsState,
    fromIpmsComponent.selectIds
);

export const allIpmsComponentsLoaded = createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => IpmsComponentsState.isAllIpmsComponentsLoaded
);


export const selectIpmsComponentsPageLoading = createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => IpmsComponentsState.listLoading
);

export const selectIpmsComponentsActionLoading = createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => IpmsComponentsState.actionLoading
);

export const selectLastCreatedIpmsComponentId = createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => IpmsComponentsState.lastCreatedIpmsComponentId
);

export const selectIpmsComponentsShowInitWaitingMessage = createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => IpmsComponentsState.showInitWaitingMessage
);

export const selectTrashedIpmsComponentCount = createSelector(
    selectIpmsComponentsState,
    (IpmsComponentsState) => IpmsComponentsState.totalTrashed
);

export const selectAdminTrashedIpmsComponentCount = createSelector(
    selectIpmsComponentsState,
    (IpmsComponentsState) => IpmsComponentsState.totalAdminTrashed
);


export const selectQueryResult = createSelector(
    selectIpmsComponentsState,
    IpmsComponentsState => {
        const items: IpmsComponent[] = [];
        each(IpmsComponentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: IpmsComponent[] = httpExtension.sortArray(items, IpmsComponentsState.lastQuery.sortField, IpmsComponentsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, IpmsComponentsState.totalCount, '', IpmsComponentsState.totalTrashed);
    }
);
