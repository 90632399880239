<div class="form">
    <div class="form-filtration">
        <div class="row">
            <mat-form-field class="col-3 mat-form-field-fluid" *ngIf="config.search.main">
                <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">
            </mat-form-field>
            <mat-form-field class="col-3 mat-form-field-fluid" *ngIf="config.search.location">
                <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                    [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_LOCATION' | translate"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    #searchLocationInput
                >
            </mat-form-field>
            <ng-container *ngIf="config.search.reset">
                <div class="col-4"></div>
                <div class="col-2">
                    <ng-container *ngTemplateOutlet="resetBtn"></ng-container>
                </div>
            </ng-container>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="d-flex flex-wrap flex-grow-1">
                <ng-content></ng-content>
            </div>
            <div class="d-flex" *ngIf="!config.search.reset">
                <ng-container *ngTemplateOutlet="resetBtn"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #resetBtn >
    <div ngbDropdown class="w-100 mt-2">
        <button ngbDropdownToggle class="btn w-100 font-semibold reset-btn"
            (click)="resetFilter()">
            Reset
        </button>
    </div>
</ng-template>