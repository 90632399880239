import {TypeOfTitle} from '../_models/type-of-title.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {TypeOfTitlesState} from '../_reducers/type-of-title.reducers';
import * as fromTypeOfTitle from '../_reducers/type-of-title.reducers';
import {each} from 'lodash';

export const selectTypeOfTitlesState = createFeatureSelector<TypeOfTitlesState>('typeOfTitles');

export const selectTypeOfTitleById = (typeOfTitleId: number) => createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => typeOfTitlesState.entities[typeOfTitleId]
);

export const selectAllTypeOfTitles = createSelector(
    selectTypeOfTitlesState,
    fromTypeOfTitle.selectAll
);

export const selectAllTypeOfTitlesIds = createSelector(
    selectTypeOfTitlesState,
    fromTypeOfTitle.selectIds
);

export const allTypeOfTitlesLoaded = createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => typeOfTitlesState.isAllTypeOfTitlesLoaded
);


export const selectTypeOfTitlesPageLoading = createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => typeOfTitlesState.listLoading
);

export const selectTypeOfTitlesActionLoading = createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => typeOfTitlesState.actionLoading
);

export const selectLastCreatedTypeOfTitleId = createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => typeOfTitlesState.lastCreatedTypeOfTitleId
);

export const selectTypeOfTitlesShowInitWaitingMessage = createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => typeOfTitlesState.showInitWaitingMessage
);

export const selectTrashedTypeOfTitleCount = createSelector(
    selectTypeOfTitlesState,
    (typeOfTitlesState) => typeOfTitlesState.totalTrashed
);

export const selectAdminTrashedTypeOfTitleCount = createSelector(
    selectTypeOfTitlesState,
    (typeOfTitlesState) => typeOfTitlesState.totalAdminTrashed
);


export const selectTypeOfTitleQueryResult = createSelector(
    selectTypeOfTitlesState,
    typeOfTitlesState => {
        const items: TypeOfTitle[] = [];
        each(typeOfTitlesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: TypeOfTitle[] = httpExtension.sortArray(items, typeOfTitlesState.lastQuery.sortField, typeOfTitlesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, typeOfTitlesState.totalCount, '', typeOfTitlesState.totalTrashed);
    }
);
