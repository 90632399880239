import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, map, mergeMap } from "rxjs/operators";
import * as fromVideoUploadActions from '../_actions/video.actions';
import { VideoUploadState } from "../_reducers/video-upload.reducers";
import { VideoUploadService } from "../_services/video-upload.service";

@Injectable()
export class VideoUploadEffects {
    constructor(
        private actions$: Actions<fromVideoUploadActions.Actions>,
        private service: VideoUploadService,
        private store: Store<VideoUploadState>
    ){}
    @Effect()
    videUpload$ = this.actions$.pipe(
        ofType(fromVideoUploadActions.ActionTypes.UPLOAD_VIDEO),
        mergeMap(action => {
            return this.service.upload(action.payload.file);
        }),
        filter(status => status != null),
        mergeMap(status => {
            this.store.dispatch(new fromVideoUploadActions.ProcessVideo());
            if (!status.success) {
                throw new Error(status.error)
            } else {
                return this.service.getVideoInfo(status.info.uri)
            }
        }),
        map(info => {
            const video = this.service.getVideo(info)
            return video;
        }),
        map(video => {
            return new fromVideoUploadActions.VideoUploadedAction({video});
        })
    )
}