import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { UploadFileComponent } from '../../../shared_components/upload-file/upload-file.component';

@Component({
  selector: 'kt-inspection-pictures',
  templateUrl: './inspection-pictures.component.html',
  styleUrls: ['./inspection-pictures.component.scss']
})
export class InspectionPicturesComponent implements OnInit {
  @Input() picture: string;
  @Input() pictures: any[];
  @Input() readonly: boolean;
  @Input() propertyTypeID: number;
  @Input() conditionRatingDropdownValues$: ReplaySubject<{key: number, value: string}[]>
  @Input() conditionRatingRelated$: Subject<{
    key: number,
    value: string,
    path: string,
    name: string,
    title: string,
  }>;

  @ViewChild(UploadFileComponent, {static: true})
  public uploadFileComponent: UploadFileComponent;

  propertyTypes = PropertyTypes;

  hasErrors = false;
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  errorFields = [];

  constructor() { }

  ngOnInit(): void {
  }

  checkValidation(isComplete: boolean): boolean {
    this.errorFields = [];
    let errorFields = new Set<string>();
    let isValid = true;
    this.hasErrors = false;
    this.showTitleRequiredError$.next(false);
    let hasTitleError = false;

    if (isComplete && this.uploadFileComponent.uploadFiles.length < 1) {
      this.hasErrors = true;
      isValid = false;
      errorFields.add('no_picture');
    } else if (isComplete) {
      const uploadedFiles = this.uploadFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasErrors = true;
          hasTitleError = true;
          isValid = false;
          errorFields.add('no_picture_title');
        }
      })
    }
    this.showTitleRequiredError$.next(hasTitleError);

    for (let v of errorFields.values()) {
      this.errorFields.push(v);
    }
    return isValid;
  }

  getData(): {
    picture: string,
    pictures: any[]
  } {
    let picture = '';
    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
      if (value) {
        picture = value.success; 
      }
    })
    return {
      picture,
      pictures: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }
  }

}
