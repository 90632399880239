import {IvsStandard} from '../_models/ivs-standard.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {IvsStandardsState} from '../_reducers/ivs-standard.reducers';
import * as fromIvsStandard from '../_reducers/ivs-standard.reducers';
import {each} from 'lodash';

export const selectIvsStandardsState = createFeatureSelector<IvsStandardsState>('ivsStandards');

export const selectIvsStandardById = (ivsStandardId: number) => createSelector(
    selectIvsStandardsState,
    ivsStandardsState => ivsStandardsState.entities[ivsStandardId]
);

export const selectAllIvsStandards = createSelector(
    selectIvsStandardsState,
    fromIvsStandard.selectAll
);

export const selectAllIvsStandardsIds = createSelector(
    selectIvsStandardsState,
    fromIvsStandard.selectIds
);

export const allIvsStandardsLoaded = createSelector(
    selectIvsStandardsState,
    ivsStandardsState => ivsStandardsState.isAllIvsStandardsLoaded
);


export const selectIvsStandardsPageLoading = createSelector(
    selectIvsStandardsState,
    ivsStandardsState => ivsStandardsState.listLoading
);

export const selectIvsStandardsActionLoading = createSelector(
    selectIvsStandardsState,
    ivsStandardsState => ivsStandardsState.actionLoading
);

export const selectLastCreatedIvsStandardId = createSelector(
    selectIvsStandardsState,
    ivsStandardsState => ivsStandardsState.lastCreatedIvsStandardId
);

export const selectIvsStandardsShowInitWaitingMessage = createSelector(
    selectIvsStandardsState,
    ivsStandardsState => ivsStandardsState.showInitWaitingMessage
);

export const selectTrashedIvsStandardCount = createSelector(
    selectIvsStandardsState,
    (ivsStandardsState) => ivsStandardsState.totalTrashed
);

export const selectAdminTrashedIvsStandardCount = createSelector(
    selectIvsStandardsState,
    (ivsStandardsState) => ivsStandardsState.totalAdminTrashed
);


export const selectIvsStandardsQueryResult = createSelector(
    selectIvsStandardsState,
    ivsStandardsState => {
        const items: IvsStandard[] = [];
        each(ivsStandardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: IvsStandard[] = httpExtension.sortArray(items, ivsStandardsState.lastQuery.sortField, ivsStandardsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, ivsStandardsState.totalCount, '', ivsStandardsState.totalTrashed);
    }
);
