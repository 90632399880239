// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {WindowTypeActions, WindowTypeActionTypes} from '../_actions/window-type.actions';
// Models
import {WindowType} from '../_models/window-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface WindowTypesState extends EntityState<WindowType> {
    isAllWindowTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: WindowType[];
    lastCreatedWindowTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<WindowType> = createEntityAdapter<WindowType>();

export const initialWindowTypesState: WindowTypesState = adapter.getInitialState({
    isAllWindowTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedWindowTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function windowTypesReducer(state = initialWindowTypesState, action: WindowTypeActions): WindowTypesState {
    switch (action.type) {
        case WindowTypeActionTypes.WindowTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedWindowTypeId: undefined
            };
        case WindowTypeActionTypes.WindowTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case WindowTypeActionTypes.WindowTypeOnServerCreated:
            return {
                ...state
            };
        case WindowTypeActionTypes.WindowTypeCreated:
            return adapter.addOne(action.payload.windowType, {
                ...state, lastCreatedWindowTypeId: action.payload.windowType.id
            });
        case WindowTypeActionTypes.WindowTypeUpdated:
            return adapter.updateOne(action.payload.partialWindowType, state);
        case WindowTypeActionTypes.AllWindowTypesLoaded:
            return adapter.addAll(action.payload.windowTypes, {
                ...state, isAllWindowTypesLoaded: true
            });
        case WindowTypeActionTypes.WindowTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case WindowTypeActionTypes.WindowTypesPageLoaded:
            return adapter.addMany(action.payload.windowTypes, {
                ...initialWindowTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case WindowTypeActionTypes.WindowTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case WindowTypeActionTypes.WindowTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case WindowTypeActionTypes.WindowTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case WindowTypeActionTypes.WindowTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case WindowTypeActionTypes.WindowTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case WindowTypeActionTypes.WindowTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
