// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {OutsideDoorActions, OutsideDoorActionTypes} from '../_actions/outside-door.actions';
// Models
import {OutsideDoorModel} from '../_models/outside-door.model';
import {QueryParamsModel} from '../../_base/crud';

export interface OutsideDoorsState extends EntityState<OutsideDoorModel> {
    isAllOutsideDoorsLoaded: boolean;
    queryRowsCount: number;
    queryResult: OutsideDoorModel[];
    lastCreatedOutsideDoorId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<OutsideDoorModel> = createEntityAdapter<OutsideDoorModel>();

export const initialOutsideDoorState: OutsideDoorsState = adapter.getInitialState({
    isAllOutsideDoorsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOutsideDoorId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function outsideDoorReducer(state = initialOutsideDoorState, action: OutsideDoorActions): OutsideDoorsState {
    switch (action.type) {
        case OutsideDoorActionTypes.OutsideDoorsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedOutsideDoorId: undefined
            };
        case OutsideDoorActionTypes.OutsideDoorActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case OutsideDoorActionTypes.OutsideDoorOnServerCreated:
            return {
                ...state
            };
        case OutsideDoorActionTypes.OutsideDoorCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedOutsideDoorId: action.payload.item.id
            });
        case OutsideDoorActionTypes.OutsideDoorUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case OutsideDoorActionTypes.AllOutsideDoorsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllOutsideDoorsLoaded: true
            });
        case OutsideDoorActionTypes.OutsideDoorsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case OutsideDoorActionTypes.OutsideDoorsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialOutsideDoorState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case OutsideDoorActionTypes.OutsideDoorDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case OutsideDoorActionTypes.OutsideDoorRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case OutsideDoorActionTypes.OutsideDoorDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case OutsideDoorActionTypes.OutsideDoorAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case OutsideDoorActionTypes.OutsideDoorDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case OutsideDoorActionTypes.OutsideDoorTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
