<div class="p-0 m-0" *ngIf="!(loading$|async)">
  <ng-container *ngIf="valuation$|async as valuation">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-12">
          <h5 class="my-3 text-mad text-uppercase">Premise of value and condition of sale</h5>
          <hr class="active"/>
        </div>
        <div class="col-12">
          <span class="font-weight-bold">{{valuation.premiseOfValueName}}</span> : <span class="font-weight-bold">{{valuation.premiseOfValueDefinition}}</span>
        </div>
      </div>

      <div class="row mt-8">
        <div class="col-12">
          <h6 class="my-3 font-weight-bold">Disposal period</h6>
          <hr class="active"/>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center disposal-period">
            <mat-label>
              Please indicate the estimated disposal period to sell the asset:
            </mat-label>
            <mat-form-field class="disposal-period-input">
              <input matInput formControlName="disposal_period" placeholder="Period" mask="separator"/>
            </mat-form-field>
            <mat-form-field class="disposal-period-type-input">
              <mat-select formControlName="disposal_period_type" placeholder="Period Type" >
                <mat-option [value]="1">Days</mat-option>
                <mat-option [value]="2">Months</mat-option>
                <mat-option [value]="3">Years</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-8">
        <div class="col-12">
          <h6 class="my-3 font-weight-bold">Discount</h6>
          <hr class="active"/>
        </div>
        <div class="col-12">
          <div class="mb-4">
            <mat-label>Add a discount to the estimated value?</mat-label>
          </div>
          <div class="mb-4">
            <mat-radio-group formControlName="add_discount">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-12" *ngIf="showDiscount$ | async">
          <div class="d-flex align-items-center discount">
            <mat-label>
              Please indicate the total applicable discount to the estimated value:
            </mat-label>
            <mat-form-field class="discount-input">
              <input matInput formControlName="discount" placeholder="Discount" mask="percent.2" suffix="%"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>