<div class="card">
    <div class="card-header info-header" style="background-color: #999999;">
        <span>Property Specifics</span>
    </div>
    <div class="card-body info-body" style="background-color: #ebebeb;">
        <table *ngIf="viewModel" class="table">
            <tbody>
                <tr>
                    <th class="label-1">Property Type :</th>
                    <td class="value-1">{{viewModel.propertyType}}</td>
                    <th class="label-2">Property Sub-Type :</th>
                    <td class="value-2">{{viewModel.propertySubType}}</td>
                </tr>
                <tr *ngIf="viewModel.kind != 'Land'">
                    <ng-container>
                        <th>Sub-Type Category:</th>
                        <td>{{viewModel.subTypeCategory}}</td>
                    </ng-container>
                    <ng-container *ngIf="viewModel.subCategory">
                        <th>Sub Category:</th>
                        <td>{{viewModel.subCategory}}</td>
                    </ng-container>
                </tr>
                <tr *ngIf="viewModel.kind != 'Land'">
                    <th>Location Grade :</th><td>{{viewModel.locationGrade}}</td>
                    <th>Property Size ({{viewModel.measurementStandard}}) :</th>
                    <td>
                        <span *ngIf="!viewModel.size.isClickable">
                            {{viewModel.size.value|mask:'separator':{thousandSeparator: ','} }} ({{viewModel.size.unitAreaMeasurement}})
                        </span>
                        <span *ngIf="viewModel.size.isClickable" class="clickable" (click)="showInfo()">
                            {{viewModel.size.value|mask:'separator':{thousandSeparator: ','} }} ({{viewModel.size.unitAreaMeasurement}})
                        </span>
                    </td>
                </tr>
                <ng-container *ngIf="viewModel.kind == 'Apartment' || viewModel.kind == 'Office' || viewModel.kind == 'Retail Shop'">
                    <tr>
                        <th>Building Type :</th><td>{{viewModel.buildingType}}</td>
                        <th>Building Grade :</th><td>{{viewModel.buildingGrade}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="viewModel.kind == 'Land'">
                    <tr>
                        <th>Location Grade :</th><td>{{viewModel.locationGrade}}</td>
                        <th>Coordinate Reference System:</th><td>{{viewModel.coordinateReferenceSystem}}</td>
                    </tr>
                    <tr>
                        <th>Total number of land parcel :</th><td>{{viewModel.landParcelNum}}</td>
                        <th>Total land area ({{viewModel.unitMeasurement}}):</th><td>{{viewModel.totalLandArea}}</td>
                    </tr>
                    <tr>
                        <th>Planning Status :</th><td>{{viewModel.planningStatus}}</td>
                        <th>Number of buildings:</th><td>{{viewModel.numOfBuilding}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="viewModel.kind == 'House' || viewModel.kind == 'Warehouse'">
                    <tr><th>Property Grade :</th><td>{{viewModel.propertyGrade}}</td></tr>
                </ng-container>
                <tr>
                    <th>Address :</th>
                    <td colspan="3">
                        <span class="clickable" (click)="showInMap($event)">{{viewModel.address}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>