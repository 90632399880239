import {
    Component, OnDestroy, OnInit, Input, AfterViewInit, ViewChild, ChangeDetectorRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../../../core/_base/crud';
import { AppState } from '../../../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TenureConsiderationEditDialogComponent } from '../tenure-consideration-edit/tenure-consideration-edit.dialog.component';
import { AssetClassTenureModel, AssetClassConsiderationModel } from '../../../../../../core/asset_class';

@Component({
    selector: 'kt-tenure-consideration-list',
    templateUrl: './tenure-consideration-list.component.html',
    styleUrls: ['./tenure-consideration-list.component.scss'],
})
export class TenureConsiderationListComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() considerationListSubject: BehaviorSubject<any[]>;
    @Input() tenureSubject: BehaviorSubject<AssetClassTenureModel>;
    tenureConsiderationList: AssetClassConsiderationModel[] = [];

    displayedColumns = ['land_tenure_name', 'tenure_length', 'currency', 'consideration', 'actions'];
    dataSource = new MatTableDataSource();
    item: AssetClassTenureModel;
    form: UntypedFormGroup;
    buttonEnable$: Observable<boolean>;
    @ViewChild(MatSort) matSort: MatSort;
    hasFormErrors = false;
    hasTitleRequiredError: boolean = false;
    showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private cdRef: ChangeDetectorRef,
                private translate: TranslateService) {
                    this.buttonEnable$ = of(true);
    }

    ngOnInit() {
        const considerationListSubjectSubscription = this.considerationListSubject.asObservable().subscribe(res => {
            this.tenureConsiderationList = Object.assign([], res);
            this.dataSource.data = this.tenureConsiderationList;
        });
        const tenureSubjectSubscription = this.tenureSubject.asObservable().subscribe(res => {
            this.item = res;
        });
        
        this.subscriptions.push(considerationListSubjectSubscription);
        this.subscriptions.push(tenureSubjectSubscription);

        this.createForm();
    }

    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
     ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
    }

    createForm() {
        this.form = this.fb.group({
            current_occupier: [this.item.current_occupier],
            previous_occupier: [this.item.previous_occupier],
            rrr_over_land: [this.item.rrr_over_land],
            additional_rrr_over_land: [this.item.additional_rrr_over_land],
            difference_between_boundaries: [this.item.difference_between_boundaries],
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem() {
        const newAssetClassConsideration = new AssetClassConsiderationModel();
        newAssetClassConsideration.clear(); // Set all defaults fields
        this.editItem(newAssetClassConsideration, -1);
    }
    
    editItem(_tenureConsideration: any, index: number) {
        const dialogRef = this.dialog.open(TenureConsiderationEditDialogComponent, {
            data: {tenureConsideration: _tenureConsideration}
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
    
                if (index != -1) {
                    this.tenureConsiderationList = Object.assign([], this.tenureConsiderationList);
                    this.tenureConsiderationList[index] = res.tenureConsideration;
                    this.dataSource.data = this.tenureConsiderationList;
                    this.considerationListSubject.next(this.tenureConsiderationList);
                    this.cdRef.detectChanges();
                } else {
                    this.tenureConsiderationList = [...this.tenureConsiderationList, res.tenureConsideration];
                    this.considerationListSubject.next(this.tenureConsiderationList);
                    this.cdRef.detectChanges();
                }
            }
        );
    }
    
    deleteItem(index: number) {
        const _title = 'Are you sure?';
        const _description: string = 'The Tenure Consideration "#' + (index + 1) + '" will be deleted';
        const _waitDesciption = 'deleting Tenure Consideration';
    
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.tenureConsiderationList.splice(index, 1);
            this.considerationListSubject.next(this.tenureConsiderationList);
        });
    }

    updateValue(isComplete = true) {
        if (isComplete && !this.valid()) {
            return
        }
        const controls = this.form.controls;

        const acTenure = new AssetClassTenureModel();
        acTenure.clear();
        acTenure.current_occupier = controls.current_occupier.value;
        acTenure.previous_occupier = controls.previous_occupier.value;
        acTenure.rrr_over_land = controls.rrr_over_land.value;
        acTenure.additional_rrr_over_land = controls.additional_rrr_over_land.value;
        acTenure.difference_between_boundaries = controls.difference_between_boundaries.value;

        this.tenureSubject.next(acTenure);
    }

    valid() {
        let isValid = true;
        this.hasFormErrors = false;
        const controls = this.form.controls;
        return isValid;
    }
}
