// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TaskEffortActions, TaskEffortActionTypes} from '../_actions/task-effort.actions';
// Models
import {TaskEffort} from '../_models/task-effort.model';
import {QueryParamsModel} from '../../_base/crud';

export interface TaskEffortsState extends EntityState<TaskEffort> {
    isAllTaskEffortsLoaded: boolean;
    queryRowsCount: number;
    queryResult: TaskEffort[];
    lastCreatedTaskEffortId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TaskEffort> = createEntityAdapter<TaskEffort>();

export const initialTaskEffortsState: TaskEffortsState = adapter.getInitialState({
    isAllTaskEffortsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedTaskEffortId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function taskEffortsReducer(state = initialTaskEffortsState, action: TaskEffortActions): TaskEffortsState {
    switch (action.type) {
        case TaskEffortActionTypes.TaskEffortsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTaskEffortId: undefined
            };
        case TaskEffortActionTypes.TaskEffortsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case TaskEffortActionTypes.TaskEffortOnServerCreated:
            return {
                ...state
            };
        case TaskEffortActionTypes.TaskEffortCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedTaskEffortId: action.payload.item.id
            });
        case TaskEffortActionTypes.TaskEffortUpdated:
            return adapter.updateOne(action.payload.partialItem, state);
        case TaskEffortActionTypes.AllTaskEffortsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllTaskEffortsLoaded: true
            });
        case TaskEffortActionTypes.TaskEffortsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case TaskEffortActionTypes.TaskEffortsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialTaskEffortsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case TaskEffortActionTypes.TaskEffortDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case TaskEffortActionTypes.TaskEffortRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case TaskEffortActionTypes.TaskEffortDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case TaskEffortActionTypes.TaskEffortAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case TaskEffortActionTypes.TaskEffortDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case TaskEffortActionTypes.TaskEffortTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
