<div class="row mb-2">
	<div class="col-lg-12">
		<h5 class="my-3 text-mad text-uppercase">Supporting Documents</h5>
		<hr class="active">
	</div>
</div> 
<div class="row mb-20">
	<div class="col-lg-12">
		<h6 class="text-mad mb-10">1 - Appendices documents to include in the report</h6>
		<kt-document-tree-v2 #appendiceDocuments [readonly]="readonly" [title]="'Appendices Library'" [data$]="apData$"></kt-document-tree-v2>
	</div>
</div>
<div class="row mb-20">
	<div class="col-lg-12">
		<h6 class="text-mad mb-10">2 - Toe related docs</h6>
		<kt-toe-docs [readonly]="readonly" [otherDocs]="otherDocs" [leadValuer]="leadValuer" [members]="members" [toeRelatedDocs]="reporting.toe_related_docs" [agency]="agency"></kt-toe-docs>
	</div>
</div>
<div class="row mb-20">
	<div class="col-lg-12">
		<h6 class="text-mad mb-10">3 - Other documents to include in the report (upload)</h6>
		<kt-other-documents [readonly]="readonly" [files]="reporting.otherDocuments"></kt-other-documents>
	</div>
</div>