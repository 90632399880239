import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource, QueryResultsModel } from "../../_base/crud";
import { selectRetailBuildingAdjustmentQueryResult, selectRetailBuildingAdjustmentsPageLoading, selectRetailBuildingAdjustmentsShowInitWaitingMessage } from "../_selectors/retail-building-adjustment.selectors";

export class RetailBuildingAdjustmentsDataSource extends BaseDataSource {
    trashed = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectRetailBuildingAdjustmentsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectRetailBuildingAdjustmentsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectRetailBuildingAdjustmentQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        })
        
    }
}