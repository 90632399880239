// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutConfigService} from '../../../../core/_base/layout';
import {Observable, Subscription, timer} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {AppState} from '../../../../core/reducers';
import {Store} from '@ngrx/store';
import {NotificationClear} from '../../../../core/auth/_actions/notificaiton.actions';
import {MatDialog} from '@angular/material/dialog';
import { MadLogOut } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
    selector: 'kt-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
    notificationsDisplay = true;
    userDisplay = true;
    userLayout = 'offcanvas';
    userDropdownStyle = 'light';
    languagesDisplay = true;


    private _timer: Observable<number>;
    _counter = 0;
    duration = 900;
    private _timerSubscription: Subscription;


    constructor(private layoutConfigService: LayoutConfigService,
                private store: Store<AppState>,
                private dialogRef: MatDialog,
                private changeRef: ChangeDetectorRef) {
        this.notificationsDisplay = this.layoutConfigService.getConfig('extras.notifications.display');
        this.languagesDisplay = this.layoutConfigService.getConfig('extras.languages.display');
        this.userDisplay = this.layoutConfigService.getConfig('extras.user.display');
        this.userLayout = this.layoutConfigService.getConfig('extras.user.layout');
        this.userDropdownStyle = this.layoutConfigService.getConfig('extras.user.dropdown.style');


    }

    public startCounter() {
        // if (this._timerSubscription) {
        //     this._timerSubscription.unsubscribe();
        // }

        // this._counter = 0;
        // this._timer = timer(1000, 1000);
        // this._timerSubscription = this._timer.subscribe(n => {
        //     const lastDate = Number(localStorage.getItem('MADIdleTime'));
        //     const nowDate = (new Date()).getTime();
        //     this._counter = Math.ceil((nowDate - lastDate) / 1000);

        //     if (this._counter >= this.duration ) {
        //         // this.store.dispatch(new NotificationClear());
        //         // this.store.dispatch(new MadLogOut());
        //         // this.dialogRef.closeAll();
        //     }
        //     this.changeRef.markForCheck();
        // });
    }

    public transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        const seconds: number = value - (minutes * 60);
        return minutes + ':' + (seconds > 9 ? '' + seconds : '0' + seconds);
    }

    ngOnInit() {
        // this.startCounter();
    }

    ngOnDestroy() {
        // this._timerSubscription.unsubscribe();
    }
}
