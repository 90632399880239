import {
    Component, OnDestroy, OnInit, Input, ChangeDetectorRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../../../core/_base/crud';
import { AppState } from '../../../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel } from '../../../../../../core/asset_class';
import { LandAreaEditDialogComponent } from '../land-area-edit/land-area-edit.dialog.component';
import { AllPlaningStatusesRequested, AllQosDegradationsRequested, PlaningStatus, QosDegradation, selectAllPlaningStatuses, selectAllQosDegradations } from 'src/app/core/linked-tables';

@Component({
    selector: 'kt-land-area-list',
    templateUrl: './land-area-list.component.html',
    styleUrls: ['./land-area-list.component.scss'],
})
export class LandAreaListComponent implements OnInit, OnDestroy {

    @Input() landAreaComponentListSubject: BehaviorSubject<AssetClassLandAreaComponentModel[]>;
    @Input() landAreaSubject: BehaviorSubject<AssetClassLandAreaModel>;
    @Input() showOtherFields: boolean = false;
    @Input() disable: boolean = false;
    @Input() lowLevelTitle: string = null;
    landAreas: AssetClassLandAreaComponentModel[] = [];

    displayedColumns = ['index', 'unit_area_measurement_name', 'surface', 'limited_use', '3d_restriction', 'actions'];
    dataSource = new MatTableDataSource();
    item: any;
    form: UntypedFormGroup;
    buttonEnable$: Observable<boolean>;
    planingStatuses: PlaningStatus[] = [];
    qosDegradations: QosDegradation[] = [];
    hasFormErrors = false;

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private cdRef: ChangeDetectorRef,
                private translate: TranslateService) {
                    this.buttonEnable$ = of(true);
    }

    ngOnInit() {
        const landAreaComponentListSubjectSubscription = this.landAreaComponentListSubject.asObservable().subscribe(res => {
            this.landAreas = res;
            this.dataSource.data = this.landAreas;
        });
        const landAreaSubjectSubscription = this.landAreaSubject.asObservable().subscribe(res => {
            this.item = Object.assign({}, res);
        });
        this.store.dispatch(new AllPlaningStatusesRequested());
        
        const sourceSubscription = this.store.pipe(
            select(selectAllPlaningStatuses))
            .subscribe(res => {
                this.planingStatuses = [];
                if (res) {
                    this.planingStatuses = res;
                }
            });

        this.subscriptions.push(sourceSubscription);

        this.store.dispatch(new AllQosDegradationsRequested());
        const qosDegradationsSubscription = this.store.pipe(
            select(selectAllQosDegradations))
            .subscribe(res => {
                this.qosDegradations = [];
                if (res) {
                    this.qosDegradations = res;
                }
            });

        this.subscriptions.push(qosDegradationsSubscription);
        this.subscriptions.push(landAreaComponentListSubjectSubscription);
        this.subscriptions.push(landAreaSubjectSubscription);

        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            planning_status_id: [this.item.planning_status_id],
            qos_degradation_id: [this.item.qos_degradation_id],
            env_considerations: [this.item.env_considerations],
            other_considerations: [this.item.other_considerations]
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem() {
        const newAssetClassLandAreaComponent = new AssetClassLandAreaComponentModel();
        newAssetClassLandAreaComponent.clear(); // Set all defaults fields
        this.editItem(newAssetClassLandAreaComponent, -1);
    }
    
    editItem(_landAreaComponent: any, index: number) {
        const dialogRef = this.dialog.open(LandAreaEditDialogComponent, {
            data: {landAreaComponent: _landAreaComponent, disable: this.disable}
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
    
                if (index != -1) {
                    this.landAreas = Object.assign([], this.landAreas);
                    this.landAreas[index] = res.landAreaComponent;
                    this.dataSource.data = this.landAreas;
                    this.landAreaComponentListSubject.next(this.landAreas);
                    this.cdRef.detectChanges();
                } else {
                    if (this.landAreas.length > 0) {
                        const last = this.landAreas[this.landAreas.length - 1];
                        res.landAreaComponent.order = last.order + 1; 
                    }
                    this.landAreas = [...this.landAreas, res.landAreaComponent];
                    this.landAreaComponentListSubject.next(this.landAreas);
                    this.cdRef.detectChanges();
                }
            }
        );
    }
    
    deleteItem(index: number) {
        const _title = 'Are you sure?';
        const _description: string = 'The Land Area Component "#' + (index + 1) + '" will be deleted';
        const _waitDesciption = 'deleting Land Area Component';
    
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.landAreas = this.landAreas.map(la => Object.assign({}, la));
            this.landAreas.splice(index, 1);
            this.landAreaComponentListSubject.next(this.landAreas);
        });
    }

    updateValue(isComplete = true) {
        if (isComplete && !this.valid()) {
            return
        }
        const controls = this.form.controls;

        const acLandArea = new AssetClassLandAreaModel();
        acLandArea.clear();
        acLandArea.id = this.item.id;
        acLandArea.planning_status_id = controls.planning_status_id.value;
        acLandArea.qos_degradation_id = controls.qos_degradation_id.value;
        acLandArea.env_considerations = controls.env_considerations.value;
        acLandArea.other_considerations = controls.other_considerations.value;

        this.landAreaSubject.next(acLandArea);
    }

    getData() {
        const controls = this.form.controls;
        const acLandArea = new AssetClassLandAreaModel();
        acLandArea.clear();
        acLandArea.id = this.item.id;
        acLandArea.planning_status_id = controls.planning_status_id.value;
        acLandArea.qos_degradation_id = controls.qos_degradation_id.value;
        acLandArea.env_considerations = controls.env_considerations.value;
        acLandArea.other_considerations = controls.other_considerations.value;
        return acLandArea;
    }

    valid() {
        let isValid = true;
        this.hasFormErrors = false;
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            isValid = false;
            this.hasFormErrors = true;
        }

        // if (this.landAreas.length === 0) {
        //     this.hasFormErrors = true;
        //     isValid = false;
        // }
        return isValid;
    }
}
