import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { ValuationNoteModel } from '../_models/valuation-note.model';
import * as reducer from '../_reducers/valuation-notes.reducer';
export const selectValuationNotesState = createFeatureSelector<reducer.ValuationNotesState>('valuation-notes');
export const selectAll = createSelector(
    selectValuationNotesState,
    state => {
        const items: ValuationNoteModel[] = [];
        each(state.entities, el => items.push(el));
        return items;
    }
)
export const selectLastCreatedID = createSelector(
    selectValuationNotesState,
    state => state.lastCreatedId,
)