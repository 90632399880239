// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
// Auth
// import {AuthGuard} from './core/auth';
import { MadAuthGuard } from './core/mad-auth/mad-auth.guard';
import { MadPermissionGuard } from './core/mad-auth/mad-permission.guard';

const routes: Routes = [
    // {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
    {path: 'mad-auth', loadChildren: () => import('./views/pages/mad-auth/mad-auth.module').then(m => m.MadAuthModule)},
    {path: 'error', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule)},
    {
        path: '',
        component: BaseComponent,
        canActivate: [MadAuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'my-task',
                loadChildren: () => import('./views/pages/my-task/my-task.module').then(m => m.MyTaskModule),
            },
            {
                path: 'user-management',
                loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
            },
            {
                path: 'admin-management',
                loadChildren: () => import('./views/pages/admin-management/admin-management.module').then(m => m.AdminManagementModule),
            },
            {
                path: 'comparable',
                loadChildren: () => import('./views/pages/comparable/comparable.module').then(m => m.ComparableModule),
            },
            {
                path: 'assignment',
                loadChildren: () => import('./views/pages/assignment/assignment.module').then(m => m.AssignmentModule),
            },
            {
                path: 'template',
                loadChildren: () => import('./views/pages/template/template.module').then(m => m.TemplateModule),
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['template:view', 'template:crud']
                }
            },
            {
                path: 'admin',
                loadChildren: () => import('./views/pages/mad-usermanagement/mad-usermanagement.module').then(m => m.MadUsermanagementModule),
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['role:view', 'role:crud']
                }
            },
            {
                path: 'terms-of-engagements',
                loadChildren: () => import('./views/pages/terms-of-engagements/terms-of-engagements.module').then(m => m.TermsOfEngagementsModule)
            },
            {
                path: 'quotes',
                loadChildren: () => import('./views/pages/quotes/quotes.module').then(m => m.QuotesModule)
            },
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
        ],
    },
    {
        path: 'multimedia',
        loadChildren: () => import('./views/pages/multimedia/multimedia.module').then(m => m.MultimediaModule),
    },
    {path: '**', redirectTo: 'error/error-3', pathMatch: 'full'},
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {enableTracing: false}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
