<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar" *ngIf="!data.admin">
            <a href="javascript:;"
               (click)="flushTrash()"
               class="btn btn-danger mr-2" mat-raised-button matTooltip="Empty Trash">
                <i class="fa fa-trash"></i>
            </a>
        </div>
    </div>
    <div class="form kt-form--label-align-right form-group-seperator-dashed">
        <div class="card-body p-0 w-100 overflow-x-hidden">
            <mat-dialog-content>
                <mat-list class="w-100">
                    <mat-list-item *ngFor="let item of data.items">
                        <mat-icon mat-list-icon>navigate_next</mat-icon>
                        <h4 mat-line>{{item.text}}</h4>
                        <p mat-line> {{ data.admin ? 'Permanently deleted':'Deleted'}} on {{item.date | date:'MMM dd, yyyy':currentUser.settings.time_zone_gmt}} {{item.deletedUser && item.deletedUser.length > 0 ? 'by '+item.deletedUser : ''}}</p>
                        <mat-action-list style="width: 8rem;">
                            <button
                                    mat-icon-button
                                    color="primary"
                                    style="color: limegreen !important;"
                                    matTooltip="Restore"
                                    type="button"
                                    (click)="restore(item.id)">
                                <i class="flaticon-reply"></i>
                            </button>&nbsp;
    
                            <button [disabled]="!item.hasPermanentlyDelete"
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="Permanently Delete"
                                    type="button"
                                    (click)="delete(item.id)">
                                <mat-icon>delete</mat-icon>
                            </button>&nbsp;
                        </mat-action-list>
                    </mat-list-item>
                </mat-list>
            </mat-dialog-content>
            <div class="card-footer border-0 p-0 text-right">
                <div class="form-actions form-actions-solid">
                    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="data.animal" cdkFocusInitial>Close</button>&nbsp;
                </div>
            </div>
        </div>
    </div>
</div>
