import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../../_config/default/translation_file.config';
import { forkJoin, Observable, of } from 'rxjs';
import { TranslationService } from '../layout';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubdomainService } from '../subdomain.service';

@Injectable()
export class InitializeTranslationService {
    private prefix = environment.baseApiUrl +  'api/' + this.subDomainService.subDomain + '/tooltip-edit';
    private suffix = '.json';
    private languages = [
        'en',
    ];
    private obs: Observable<any>[] = [];
    private translationData = {};
    private files = [];

    constructor(
        private http: HttpClient,
        private translate: TranslationService,
        private subDomainService: SubdomainService
    ) {
        this.languages.forEach(lang => {
            this.translationData[lang] = {};
        });

        Object.keys(config).forEach(key => {
            switch (key) {
                case 'linked_tables': {
                    const files = this.getFilesOfLinkedTables(config[key]);
                    this.files = [...this.files, ...files];
                    break;
                }
                case 'valuation':
                case 'comparables':
                case 'user_management':
                case 'localisation':
                case 'resource':
                case 'support': {
                    const files = this.getArrayFiles(key, config[key]);
                    this.files = [...this.files, ...files];
                    break;
                }
                default: {
                    this.files.push(key);
                }
            }
        })
    }

    init(): Promise<boolean> {
        this.obs = [];
        return new Promise<boolean>((resolve: (res: boolean) => void) => {
            this.languages.forEach(lang => {
                this.obs.push(this.http.get(`${this.prefix}/?lang=${lang}`).pipe(
                    catchError((err) => {
                        console.error(err);
                        return of(null);
                    })
                ))
            })
            forkJoin(this.obs).subscribe(res => {
                res.forEach(langConfig => {
                    const lang = langConfig.lang;
                    Object.entries(langConfig).filter(([name, _]) => name != 'lang').forEach(([name, value]: [string, any]) => {
                        this.translationData[lang][name] = value;
                    })
                });
                this.languages.forEach(lang => {
                    const locale = {
                        lang,
                        data: this.translationData[lang]
                    };
                    this.translate.loadTranslations(locale);
                });
                resolve(true);
            });
        });
    }

    private getFilesOfLinkedTables(obj: {}): string[] {
        const files: string[] = [];
        const prefix = 'linked_tables';
        Object.keys(obj).forEach(key => {
            obj[key].forEach(element => {
                const file = prefix + '/' + key + '/' + element;
                files.push(file);
            });
        });
        return files;
    }

    private getArrayFiles(key: string, _files: string[]): string[] {
        const prefix = key;
        const files: string[] = [];
        _files.forEach(f => {
            const str = prefix + '/' + f;
            files.push(str);
        });
        return files;
    }
}