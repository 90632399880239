// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AppendixCategory} from '../_models/appendix-category.model';

export enum AppendixCategoryActionTypes {

    AllAppendixCategoriesRequested = '[AppendixCategories Home Page] All AppendixCategories Requested',
    AllAppendixCategoriesLoaded = '[AppendixCategories API] All AppendixCategories Loaded',

    AppendixCategoryOnServerCreated = '[Edit AppendixCategory Dialog] AppendixCategory On Server Created',
    AppendixCategoryCreated = '[Edit AppendixCategories Dialog] AppendixCategories Created',
    AppendixCategoryUpdated = '[Edit AppendixCategory Dialog] AppendixCategory Updated',
    AppendixCategoryDeleted = '[AppendixCategories List Page] AppendixCategory Deleted',

    AppendixCategoriesPageRequested = '[AppendixCategories List Page] AppendixCategories Page Requested',
    AppendixCategoriesPageLoaded = '[AppendixCategories API] AppendixCategories Page Loaded',
    AppendixCategoriesPageCancelled = '[AppendixCategories API] AppendixCategories Page Cancelled',

    // trash system
    AppendixCategoryOnServerRestored = '[AppendixCategory Trash] AppendixCategory On Server Restored',
    AppendixCategoryRestored = '[AppendixCategory Trash] AppendixCategory Restored',

    AppendixCategoryOnServerAdminRestored = '[AppendixCategory Admin Trash] AppendixCategory On Server Restored',
    AppendixCategoryAdminRestored = '[AppendixCategory Admin Trash] AppendixCategory Restored',

    AppendixCategoryDeletedFromTrash = '[AppendixCategory Trash] AppendixCategory deleted',
    AppendixCategoryDeletedFromAdminTrash = '[AppendixCategory Admin Trash] AppendixCategory deleted',

    AppendixCategoryTrash = 'AppendixCategory Trashed',
    AppendixCategoryTrashFlushed = 'AppendixCategory Trash Flushed',

    AppendixCategoriesPageToggleLoading = '[AppendixCategories page] AppendixCategories Page Toggle Loading',
    AppendixCategoriesActionToggleLoading = '[AppendixCategories] AppendixCategories Action Toggle Loading'
}

export class AppendixCategoryOnServerCreated implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryOnServerCreated;

    constructor(public payload: { appendixCategory: AppendixCategory }) {
    }
}

export class AppendixCategoryCreated implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryCreated;

    constructor(public payload: { appendixCategory: AppendixCategory }) {
    }
}

export class AppendixCategoryUpdated implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryUpdated;

    constructor(public payload: {
        partialappendixCategory: Update<AppendixCategory>,
        appendixCategory: AppendixCategory
    }) {
    }
}

export class AppendixCategoryDeleted implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AppendixCategoriesPageRequested implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoriesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AppendixCategoriesPageLoaded implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoriesPageLoaded;

    constructor(public payload: {
        appendixCategories: AppendixCategory[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class AppendixCategoriesPageCancelled implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoriesPageCancelled;
}

export class AllAppendixCategoriesRequested implements Action {
    readonly type = AppendixCategoryActionTypes.AllAppendixCategoriesRequested;
}

export class AllAppendixCategoriesLoaded implements Action {
    readonly type = AppendixCategoryActionTypes.AllAppendixCategoriesLoaded;

    constructor(public payload: { appendixCategories: AppendixCategory[] }) {
    }
}

export class AppendixCategoriesPageToggleLoading implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoriesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AppendixCategoriesActionToggleLoading implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoriesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class AppendixCategoryDeletedFromAdminTrash implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class AppendixCategoryDeletedFromTrash implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AppendixCategoryOnServerRestored implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AppendixCategoryRestored implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryRestored;

    constructor(public payload: { item: AppendixCategory }) {
    }
}

export class AppendixCategoryOnServerAdminRestored implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AppendixCategoryAdminRestored implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryAdminRestored;

    constructor(public payload: { item: AppendixCategory }) {
    }
}

export class AppendixCategoryTrashFlushed implements Action {
    readonly type = AppendixCategoryActionTypes.AppendixCategoryTrashFlushed;

    constructor() {
    }
}

export type AppendixCategoryActions = AppendixCategoryCreated
    | AppendixCategoryUpdated
    | AppendixCategoryDeleted
    | AppendixCategoriesPageRequested
    | AppendixCategoriesPageLoaded
    | AppendixCategoriesPageCancelled
    | AllAppendixCategoriesLoaded
    | AllAppendixCategoriesRequested
    | AppendixCategoryOnServerCreated
    | AppendixCategoriesPageToggleLoading
    | AppendixCategoryDeletedFromAdminTrash
    | AppendixCategoryDeletedFromTrash
    | AppendixCategoryOnServerRestored
    | AppendixCategoryRestored
    | AppendixCategoryOnServerAdminRestored
    | AppendixCategoryAdminRestored
    | AppendixCategoryTrashFlushed
    | AppendixCategoriesActionToggleLoading;
