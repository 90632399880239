// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {LandTenureActions, LandTenureActionTypes} from '../_actions/land-tenure.actions';
// Models
import {LandTenure} from '../_models/land-tenure.model';
import {QueryParamsModel} from '../../_base/crud';

export interface LandTenuresState extends EntityState<LandTenure> {
    isAllLandTenuresLoaded: boolean;
    queryRowsCount: number;
    queryResult: LandTenure[];
    lastCreatedLandTenureId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<LandTenure> = createEntityAdapter<LandTenure>();

export const initialLandTenuresState: LandTenuresState = adapter.getInitialState({
    isAllLandTenuresLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedLandTenureId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function landTenuresReducer(state = initialLandTenuresState, action: LandTenureActions): LandTenuresState {
    switch (action.type) {
        case LandTenureActionTypes.LandTenuresPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedLandTenureId: undefined
            };
        case LandTenureActionTypes.LandTenuresActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case LandTenureActionTypes.LandTenureOnServerCreated:
            return {
                ...state
            };
        case LandTenureActionTypes.LandTenureCreated:
            return adapter.addOne(action.payload.landTenure, {
                ...state, lastCreatedLandTenureId: action.payload.landTenure.id
            });
        case LandTenureActionTypes.LandTenureUpdated:
            return adapter.updateOne(action.payload.partialLandTenure, state);
        case LandTenureActionTypes.AllLandTenuresLoaded:
            return adapter.addAll(action.payload.landTenures, {
                ...state, isAllLandTenuresLoaded: true
            });
        case LandTenureActionTypes.LandTenuresPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case LandTenureActionTypes.LandTenuresPageLoaded:
            return adapter.addMany(action.payload.landTenures, {
                ...initialLandTenuresState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case LandTenureActionTypes.LandTenureDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case LandTenureActionTypes.LandTenureRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case LandTenureActionTypes.LandTenureDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case LandTenureActionTypes.LandTenureAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case LandTenureActionTypes.LandTenureDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case LandTenureActionTypes.LandTenureTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
