// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AuditTrailReportActions, AuditTrailReportActionTypes} from '../_actions/audit-trail-report.actions';
// Models
import {AuditTrailReport} from '../_models/audit-trail-report.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AuditTrailReportsState extends EntityState<AuditTrailReport> {
    isAllAuditTrailReportsLoaded: boolean;
    queryRowsCount: number;
    queryResult: AuditTrailReport[];
    lastCreatedAuditTrailReportId: number;
    listLoading: boolean;

    totalTrashed: number;
    totalAdminTrashed: number;

    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AuditTrailReport> = createEntityAdapter<AuditTrailReport>();

export const initialAuditTrailReportsState: AuditTrailReportsState = adapter.getInitialState({
    isAllAuditTrailReportsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAuditTrailReportId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function auditTrailReportsReducer(state = initialAuditTrailReportsState, action: AuditTrailReportActions): AuditTrailReportsState {
    switch (action.type) {
        case AuditTrailReportActionTypes.AuditTrailReportsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAuditTrailReportId: undefined
            };
        case AuditTrailReportActionTypes.AuditTrailReportsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AuditTrailReportActionTypes.AuditTrailReportOnServerCreated:
            return {
                ...state, lastCreatedAuditTrailReportId: undefined
            };
        case AuditTrailReportActionTypes.AuditTrailReportCreated:
            return adapter.addOne(action.payload.auditTrailReport, {
                ...state, lastCreatedAuditTrailReportId: action.payload.auditTrailReport.id
            });
        case AuditTrailReportActionTypes.AuditTrailReportUpdated:
            return adapter.updateOne(action.payload.partialAuditTrailReport, state);
        case AuditTrailReportActionTypes.AuditTrailReportDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state
            });
        case AuditTrailReportActionTypes.AllAuditTrailReportsLoaded:
            return adapter.addAll(action.payload.auditTrailReports, {
                ...state, isAllAuditTrailReportsLoaded: true
            });
        case AuditTrailReportActionTypes.AuditTrailReportsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case AuditTrailReportActionTypes.AuditTrailReportsPageLoaded:
            return adapter.addMany(action.payload.auditTrailReports, {
                ...initialAuditTrailReportsState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.auditTrailReports,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
