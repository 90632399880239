import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ResidentialAdjustmentModel } from '../_models/residential-adjustment.model';

export enum ResidentialAdjustmentsActionTypes {
    AllResidentialAdjustmentsRequested = '[Residential Adjustments List] All Residential Adjustments Requested',
    AllResidentialAdjustmentsLoaded = '[Residential Adjustments API] All Residential Adjustments Loaded',

    ResidentialAdjustmentOnServerCreated = '[Edit Residential Adjustment] Residential Adjustment on Server Created',
    ResidentialAdjustmentCreated = '[Edit Residential Adjustment] Residential Adjustment Created',
    ResidentialAdjustmentUpdated = '[Edit Residential Adjustment] Residential Adjustment Updated',
    ResidentialAdjustmentDeleted = '[Residential Adjustment Deleted] Residential Adjustment Deleted',

    ResidentialAdjustmentRestored = '[Residential Adjustment Trash] Residential Adjustment Restored',
    ResidentialAdjustmentOnServerRestored = '[Residential Adjustment Trash] Residential Adjustment On Server Restored',

    ResidentialAdjustmentOnServerAdminRestored = '[Residential Adjustment Admin Trash] Residential Adjustment On Server Restored',
    ResidentialAdjustmentAdminRestored = '[Residential Adjustment Admin Trash] Residential Adjustment Restored',

    ResidentialAdjustmentDeletedFromTrash = '[Residential Adjustment Trash] Residential Adjustment deleted',
    ResidentialAdjustmentDeletedFromAdminTrash = '[Residential Adjustment Admin Trash] Residential Adjustment deleted',

    ResidentialAdjustmentTrash = 'Residential Adjustment Trashed',
    ResidentialAdjustmentTrashFlushed = 'Residential Adjustment Trash Flushed',

    // Page system

    ResidentialAdjustmentsPageRequested = '[Residential Adjustment List Page] Residential Adjustment Page Requested',
    ResidentialAdjustmentsPageLoaded = '[Residential Adjustment API] Residential Adjustment Page Loaded',
    ResidentialAdjustmentsPageCancelled = '[Residential Adjustment API] Residential Adjustment Page Cancelled',

    ResidentialAdjustmentsPageToggleLoading = '[Residential Adjustment page] Residential Adjustment Page Toggle Loading',
    ResidentialAdjustmentActionToggleLoading = '[Residential Adjustment] Residential Adjustment Action Toggle Loading'
}

export class ResidentialAdjustmentOnServerCreated implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerCreated;

    constructor(public payload: { item: ResidentialAdjustmentModel }) {
    }
}

export class ResidentialAdjustmentCreated implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentCreated;

    constructor(public payload: { item: ResidentialAdjustmentModel }) {
    }
}

export class ResidentialAdjustmentUpdated implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<ResidentialAdjustmentModel>,
        item: ResidentialAdjustmentModel
    }) {
    }
}

export class ResidentialAdjustmentDeleted implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialAdjustmentsPageRequested implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ResidentialAdjustmentsPageLoaded implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageLoaded;

    constructor(public payload: { items: ResidentialAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ResidentialAdjustmentsPageCancelled implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageCancelled;
}

export class AllResidentialAdjustmentsRequested implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.AllResidentialAdjustmentsRequested;
}

export class AllResidentialAdjustmentsLoaded implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.AllResidentialAdjustmentsLoaded;

    constructor(public payload: { items: ResidentialAdjustmentModel[] }) {
    }
}

export class ResidentialAdjustmentsPageToggleLoading implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ResidentialAdjustmentActionToggleLoading implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ResidentialAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialAdjustmentDeletedFromTrash implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialAdjustmentOnServerRestored implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialAdjustmentRestored implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentRestored;

    constructor(public payload: { item: ResidentialAdjustmentModel }) {
    }
}

export class ResidentialAdjustmentOnServerAdminRestored implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ResidentialAdjustmentAdminRestored implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentAdminRestored;

    constructor(public payload: { item: ResidentialAdjustmentModel }) {
    }
}

export class ResidentialAdjustmentTrashFlushed implements Action {
    readonly type = ResidentialAdjustmentsActionTypes.ResidentialAdjustmentTrashFlushed;

    constructor() {
    }
}

export type ResidentialAdjustmentActions = ResidentialAdjustmentCreated
    | ResidentialAdjustmentUpdated
    | ResidentialAdjustmentDeleted
    | ResidentialAdjustmentsPageRequested
    | ResidentialAdjustmentsPageLoaded
    | ResidentialAdjustmentsPageCancelled
    | AllResidentialAdjustmentsLoaded
    | AllResidentialAdjustmentsRequested
    | ResidentialAdjustmentOnServerCreated
    | ResidentialAdjustmentDeletedFromAdminTrash
    | ResidentialAdjustmentDeletedFromTrash
    | ResidentialAdjustmentOnServerRestored
    | ResidentialAdjustmentRestored
    | ResidentialAdjustmentOnServerAdminRestored
    | ResidentialAdjustmentAdminRestored
    | ResidentialAdjustmentTrashFlushed
    | ResidentialAdjustmentsPageToggleLoading
    | ResidentialAdjustmentActionToggleLoading;