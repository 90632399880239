<div class="card card-custom section-margin-bottom-60">
    <div class="card-header valuation-header">
        <div class="card-title">
            <div class="card-label">
                <span>Valuation</span>
            </div>
            <mat-icon style="cursor: pointer; display: flex; align-items: center;" container="body"
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.VALUATION.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.VALUATION.TITLE'|translate">
                help
            </mat-icon>
        </div>
    </div>
    <div class="card-body valuation-body">
        <div class="col-md-12 mat-table-wrapper">
            <form [formGroup]="form">

                <ng-container *ngIf="viewModel$|async as viewModel">
                    <table class="lmat-elevation-z8 table">
                        <thead>
                            <tr>
                                <th [class]="'input-'+viewModel.refNums.length+'-header'"></th>
                                <th *ngFor="let refNum of viewModel.refNums; let i = index"
                                    [class]="'val-'+viewModel.refNums.length+'-header'">
                                    <span class="cell">
                                        {{refNum}}
                                        <span>
                                            <button mat-icon-button color="primary" (click)="onShowComparable(refNum)">
                                                <mat-icon>visibility</mat-icon>
                                            </button>
                                            <button mat-icon-button color="warn" (click)="onRemoveComparable(refNum)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </span>
                                </th>
                                <th [class]="'tp-'+viewModel.refNums.length+'-header'">Target Property
                                    <button mat-icon-button color="primary"
                                        (click)="onShowComparableOverview(viewModel.refNums)">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="colored-white">
                                <th>Total Adjustment (%)</th>
                                <td *ngFor="let refNum of viewModel.refNums; let i = index">
                                    {{viewModel.totalAdjustments[refNum] ? viewModel.totalAdjustments[refNum] : 0}}%
                                </td>
                                <td rowspan="3" style="background-color: #3898ff;"></td>
                            </tr>
                            <tr class="colored-row">
                                <th>{{viewModel.adjustedConsiderationRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums; let i = index">
                                    {{viewModel.adjustedConsiderations[refNum].toFixed(2) | mask:'separator':{thousandSeparator:','} }}
                                </td>
                            </tr>
                            <tr class="colored-white">
                                <th>{{viewModel.adjustedGrossUnitRateRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums; let i = index">
                                    {{viewModel.adjustedGrossUnitRates[refNum] !== null
                                    ? (viewModel.adjustedGrossUnitRates[refNum].toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                    : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Weighting (%)</th>
                                <td *ngFor="let refNum of viewModel.refNums; let i = index">
                                    <!-- <mat-form-field style="width: 60px;" [appearance]="'fill'">
                                        <input style="text-align: center;" (focusout)="onWeightFocusOut(refNum, $event)"
                                            matInput [formControl]="getWeightControl(i)" mask="percent" suffix="%" />
                                    </mat-form-field> -->
                                    <kt-simple-percent-input
                                        [value]="viewModel.weightings[refNum]"
                                        (onPercentChange)="onWeightPercentChange(refNum, $event)"></kt-simple-percent-input> 
                                </td>
                                <td style="vertical-align: middle;">
                                    <span [ngClass]="{
                                        'red': !viewModel.is100,
                                        'green': viewModel.is100 
                                    }">{{viewModel.totalWeight}}%</span>
                                    <ng-template #popContent>
                                        <div>
                                            This must be 100%
                                        </div>
                                    </ng-template>
                                    <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                        (click)="$event.stopPropagation()" [ngbPopover]="popContent">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr class="colored-white">
                                <th>Justification Weighting</th>
                                <td class="justification" [attr.colspan]="viewModel.refNums.length + 1">
                                    <mat-form-field style="width: 100%" [appearance]="'fill'">
                                        <textarea rows="1" matInput (focusout)="onJustificationFocusOut($event)"
                                            [value]="viewModel.justification" >
                                        </textarea>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>{{viewModel.weightedAvgRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums; let i = index">
                                    {{viewModel.weightedAvgRates[refNum] != null
                                    ? (viewModel.weightedAvgRates[refNum].toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                    : 'N/A'}}
                                </td>
                                <td style="vertical-align: middle;">

                                    <span class="big" [ngClass]="{
                                            'red': !(viewModel.is100),
                                            'green': (viewModel.is100)
                                        }">{{ viewModel.totalWeighted !== null
                                        ? (viewModel.totalWeighted.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}</span>
                                </td>
                            </tr>
                            <tr class="colored-white">
                                <th>{{viewModel.tpRowHeader}}</th>
                                <td [attr.colspan]="viewModel.refNums.length + 1" style="vertical-align: middle;">

                                    <span class="big" [ngClass]="{
                                        'red': !(viewModel.is100),
                                        'green': (viewModel.is100)
                                    }">{{viewModel.tpValue !== null
                                        ? (viewModel.tpValue.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}</span>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>
                                    {{viewModel.capitalAllowanceRowHeader}}
                                    <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                        (click)="$event.stopPropagation()"
                                        [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.TITLE' | translate"
                                        [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.DESCRIPTION' | translate">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </th>
                                <td [attr.colspan]="viewModel.refNums.length + 1">
                                    <mat-form-field style="width: 100%;" [appearance]="'fill'">
                                        <input autocomplete="off" style="text-align: center;" mask="separator"
                                            thousandSeparator="," [allowNegativeNumbers]="true"
                                            (focusout)="onCapitalAllowanceFocusOut($event)" matInput
                                            formControlName="capital_allowance" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <ng-container *ngIf="viewModel.capitalAllowance != 0">
                                <tr class="colored-white">
                                    <th>Justification</th>
                                    <td [attr.colspan]="viewModel.refNums.length + 1">
                                        <mat-form-field style="width: 100%;" [appearance]="'fill'">
                                            <input [value]="viewModel.capitalAllowanceJustification"
                                                (focusout)="onCapitalAllowanceJustFocusOut($event)" matInput />
                                            <mat-error>Please provide justification</mat-error>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr class="colored-row">
                                    <th>{{viewModel.totalTPRowHeader}}</th>
                                    <td [attr.colspan]="viewModel.refNums.length + 1">
                                        <span class="big" [ngClass]="{
                                            'red': !viewModel.is100,
                                            'green': viewModel.is100 
                                        }">{{viewModel.totalTPValue !== null
                                            ? (viewModel.totalTPValue.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                            : 'N/A'}}</span>
                                    </td>
                                </tr>
                                <tr class="colored-white">
                                    <th>{{viewModel.totalTPWeightedHeader}}</th>
                                    <td [attr.colspan]="viewModel.refNums.length + 1">
                                        <span class="big" [ngClass]="{
                                            'red': !viewModel.is100,
                                            'green': viewModel.is100 
                                        }">{{viewModel.totalTPWeighted !== null
                                            ? (viewModel.totalTPWeighted.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                            : 'N/A'}}</span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>