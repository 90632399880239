<div class="map-modal-wrapper">
        <agm-map
            [latitude]="latitude"
            [longitude]="longitude"
            [zoom]="15"
            [scrollwheel]="null"
            style="height: 100%;">
        <agm-map-type-control></agm-map-type-control>
            <!-- <agm-marker
                    [agmFitBounds]="true"
                    [latitude]="latitude"
                    [longitude]="longitude"
                    [iconUrl]="'https://chart.apis.google.com/chart?chst=d_map_spin&chld=0.9|0|2DEBA9|11|b|TP'"
                    >
            </agm-marker> -->
            <agm-marker
                    [agmFitBounds]="true"
                    [latitude]="latitude"
                    [longitude]="longitude"
                    [iconUrl]="icon$|async"
                    [label]="label$|async"
                    >
            </agm-marker>
        </agm-map>
</div>