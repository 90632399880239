<div class="kt-form" [ngClass]="{'readonly': readonly}">
    <kt-filter-section
        [acType]="assetClassType"
        [filter$]="filterObs$"
        (filterChange)="onFilterChange($event)"
        ></kt-filter-section>

    <div class="form-group kt-form-group row">
        <div class="col-lg-12 kt-margin-bottom-10-mobile">
            <p style="text-align: end !important;">
                Total showing comparables: {{assetClassResult ? assetClassResult.length : 0}}
            </p>
        </div>
        <div class="col-lg-12 md-2">
            <agm-map
                #agmMap
                [latitude]="centerLat"
                [longitude]="centerLng"
                [zoom]="zoom"
                [disableDoubleClickZoom]="true"
                [scrollwheel]="null"
                [gestureHandling]="'cooperative'"
                (mapReady)="mapReady($event)"
                (mapClick)="mapClick($event, agmMap)"
                (centerChange)="centerChange($event)"
                (zoomChange)="zoomChanged($event)"
                (boundsChange)="boundsChanged($event)">
                <agm-map-type-control></agm-map-type-control>
                <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="setLocation()">
                    <mat-icon>my_location</mat-icon>
                </button>
                <agm-marker *ngIf="targetProperty" 
                    [latitude]="targetProperty.details.latitude"
                    [longitude]="targetProperty.details.longitude"
                    animation="DROP"
                    [iconUrl]="tpIcon">
                </agm-marker>
                <ng-container *ngIf="markerData$|async as mData">
                    <agm-marker *ngFor="let key of mData.keys()"
                            [latitude]="mData.get(key).lat"
                            [longitude]="mData.get(key).lng"
                            [label]="getLabel(mData.get(key).title, mData.get(key).data)"
                            [iconUrl]="icon"
                            (markerClick)="showInfoV2(key, mData.get(key).data)"></agm-marker>
                </ng-container>
            </agm-map>
        </div>
    </div>
</div>