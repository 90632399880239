import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, QueryParamsModel } from '../../../../core/_base/crud';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { merge, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { EditPointDialogComponent } from '../../../partials/content/crud';

import {
    TaskEffort,
    TaskEffortsPageRequested,
    TaskEffortsDataSource,
    TaskEffortUpdated
} from '../../../../core/linked-tables';


@Component({
    selector: 'kt-tasks-list',
    templateUrl: './tasks-list.component.html',
    styleUrls: ['./tasks-list.component.scss']
})

export class TasksListComponent implements OnInit, OnDestroy {

    dataSource: TaskEffortsDataSource;
    displayedColumns = ['category', 'note', 'point', 'actions'];


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selectResult: TaskEffort[] = [];

    // Subscriptions
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadTaskEffortsList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Init DataSource
        this.dataSource = new TaskEffortsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.selectResult = res;
            
        });

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadTaskEffortsList();
        }); // Remove this line, just loading imitation
    }

    /**
     * Load TaskEfforts List from service through data-source
     */
    loadTaskEffortsList() {
        const queryParams = new QueryParamsModel(
            null,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        this.store.dispatch(new TaskEffortsPageRequested({page: queryParams}));
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadTaskEffortsList();
    }

    // /**
    //  * Show add ricsStandard dialog
    //  */
    // addTpTask() {
    //     this.editTpTask(null);
    // }

    /**
     * Show Edit ricsStandard view and save after success close result
     * @param _index: number
     * @param _item: TaskEffort
     */
    editItem(_index: number, _item: TaskEffort) {
        const _title = _item.task_name + ' point';
        const _description: string = _item.note;
        const _waitDesciption = 'The point saving';

        const dialogRef = this.dialog.open(EditPointDialogComponent, {
            data: {title: _title, description: _description, waitDesciption: _waitDesciption, point: _item.point},
            width: '440px'
        });

        dialogRef.afterClosed().subscribe((res: string) => {
            if (!res) {
                return;
            }

            const _updatedItem = Object.assign({}, _item);
            _updatedItem.point = Number(res);

            const _partialItem: Update<TaskEffort> = {
                id: _updatedItem.id,
                changes: _updatedItem
            };

            this.store.dispatch(new TaskEffortUpdated({
                partialItem: _partialItem,
                item: _updatedItem
            }));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

}
