// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ConstructionTypesService} from '../_services/contstruction-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allConstructionTypesLoaded} from '../_selectors/contruction-type.selectors';
// Actions
import {
    AllConstructionTypesLoaded,
    AllConstructionTypesRequested,
    ConstructionTypeActionTypes,
    ConstructionTypesPageRequested,
    ConstructionTypesPageLoaded,
    ConstructionTypeUpdated,
    ConstructionTypesPageToggleLoading,
    ConstructionTypeDeleted,
    ConstructionTypeOnServerCreated,
    ConstructionTypeCreated,
    ConstructionTypesOfBuildingTypeRequested,
    ConstructionTypesActionToggleLoading,
} from '../_actions/construction-type.actions';

@Injectable()
export class ConstructionTypeEffects {
    showPageLoadingDispatcher = new ConstructionTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ConstructionTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ConstructionTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllConstructionTypes$ = this.actions$
        .pipe(
            ofType<AllConstructionTypesRequested>(ConstructionTypeActionTypes.AllConstructionTypesRequested),
            withLatestFrom(this.store.pipe(select(allConstructionTypesLoaded))),
            filter(([action, isAllConstructionTypesLoaded]) => !isAllConstructionTypesLoaded),
            mergeMap(() => this.service.getAllConstructionTypes()),
            map(res => {
                return new AllConstructionTypesLoaded({constructionTypes: res.data});
            })
        );



    @Effect()
    loadConstructionTypes$ = this.actions$
        .pipe(
            ofType<ConstructionTypesOfBuildingTypeRequested>(ConstructionTypeActionTypes.ConstructionTypesOfBuildingTypeRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.getConstructionTypeById(payload.typeId);
            }),
            map(res => {
                return new AllConstructionTypesLoaded({constructionTypes: res.data});
            })
        );

    @Effect()
    loadConstructionTypesPage$ = this.actions$
        .pipe(
            ofType<ConstructionTypesPageRequested>(ConstructionTypeActionTypes.ConstructionTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findConstructionTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ConstructionTypesPageLoaded({
                    constructionTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteConstructionType$ = this.actions$
        .pipe(
            ofType<ConstructionTypeDeleted>(ConstructionTypeActionTypes.ConstructionTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteConstructionType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateConstructionType$ = this.actions$
        .pipe(
            ofType<ConstructionTypeUpdated>(ConstructionTypeActionTypes.ConstructionTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateConstructionType(payload.constructionType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createConstructionType$ = this.actions$
        .pipe(
            ofType<ConstructionTypeOnServerCreated>(ConstructionTypeActionTypes.ConstructionTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createConstructionType(payload.constructionType).pipe(
                    tap(res => {
                        this.store.dispatch(new ConstructionTypeCreated({constructionType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ConstructionTypesService, private store: Store<AppState>) {
    }
}
