    <div class="modal-header">
        <div class="modal-title">
            {{ 'IMAGE_VIEWER.TITLE' | translate }} {{ title ? '(' + title +')' : '' }}
        </div>
    </div>
        <div class="modal-body">
                <div [ngClass]="loading ? 'loading' : 'loaded'">
                    <div *ngIf="type != 'pdf'" class="container portrait">
                        <img (error)="imgOnError($event)" (load)="imgLoading($event)" [src]="path"/>
                    </div>
                    <pdf-viewer *ngIf="type == 'pdf'" [src]="path"
                        [original-size]="true"
                        [fit-to-page]="true"
                        [show-all]="true"
                        style="display: block;"
                        [render-text]="false"
                        (on-progress)="pdfLoadingProgress($event)"
                        (error)="onError($event)"
                    >
                    </pdf-viewer>
                </div>
                <div *ngIf="hasError">
                    Error occured
                </div>
        </div>
        <div class="modal-footer b-0 p-5 text-right">
            <button mat-button mat-raised-button color="primary" (click)="onClose()" cdkFocusInitial>
                {{ 'IMAGE_VIEWER.BUTTON.CLOSE.LABEL' | translate }}
            </button>&nbsp;         
        </div>
