import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TextWithPictureSliderModalComponent } from '../text-with-picture-slider-dialog/text-with-picture-slider-modal.component';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-condition-rating-modal',
  templateUrl: './condition-rating-modal.component.html',
  styleUrls: ['./condition-rating-modal.component.scss'],
})
export class ConditionRatingModalComponent implements OnInit {
  @Input()
  data: any;
  awConst = awConst;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private subDomainService: SubdomainService
  ) {}

  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }

  showThumbnail(_array, _title, index) {
    const modalRef = this.modalService.open(
      TextWithPictureSliderModalComponent,
      {
        windowClass: 'text_with_picture_slider_modal',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.inputTtems = _array;
    modalRef.componentInstance.inputTitle = _title;
    modalRef.componentInstance.inputTndex = index;
  }

  getURL(path, name) {
    let _picture = path + '/' + name;
    if (_picture.charAt(0) == '/') {
        _picture = _picture.substring(1, _picture.length);
    }
    const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
    return imgURL;
}
}
