// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter, Update} from '@ngrx/entity';
// Actions
import {ValuationActions, ValuationActionTypes} from '../_actions/valuation.actions';
// Models
import {ValuationModel} from '../_models/valuation.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ValuationsState extends EntityState<ValuationModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastCreatedValuationId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ValuationModel> = createEntityAdapter<ValuationModel>();

export const initialValuationsState: ValuationsState = adapter.getInitialState({
    valuationForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastCreatedValuationId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function valuationsReducer(state = initialValuationsState, action: ValuationActions): ValuationsState {
    switch (action.type) {
        case ValuationActionTypes.ValuationsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedValuationId: undefined
            };
        }
        case ValuationActionTypes.ValuationActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case ValuationActionTypes.ValuationOnServerCreated:
            return {
                ...state
            };
        case ValuationActionTypes.ValuationCreated:
            return adapter.addOne(action.payload.valuation, {
                ...state, lastCreatedValuationId: action.payload.valuation.id
            });
        case ValuationActionTypes.ValuationOnServerUpdated:
            return {
                ...state
            };
        case ValuationActionTypes.ValuationUpdated:
            const _item: Update<ValuationModel> = {
                id: action.payload.valuation.id,
                changes: action.payload.valuation
            };
            return adapter.updateOne(_item, state);

        // return adapter.addOne(, {
        //     ...state, lastCreatedValuationId: action.payload.valuation.id
        // });

        case ValuationActionTypes.ValuationValuationDeleted:
            return state;
        // return adapter.updateOne(action.payload.partialItem, ...state);

        case ValuationActionTypes.ValuationsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case ValuationActionTypes.ValuationsPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.valuations, {
                ...initialValuationsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case ValuationActionTypes.ValuationOnServerRestored:
            return {
                ...state
            };
        case ValuationActionTypes.ValuationRestored:
            return adapter.addOne(action.payload.valuation, {
                ...state,
                lastCreatedValuationId: action.payload.valuation.id,
                totalTrashed: state.totalTrashed - 1
            });
        case ValuationActionTypes.ValuationDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1
            };
        case ValuationActionTypes.OneValuationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ValuationActionTypes.ValuationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const getValuationState = createFeatureSelector<ValuationModel>('valuations');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
