import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'kt-filter-date-selection',
  templateUrl: './filter-date-selection.component.html',
  styleUrls: ['./filter-date-selection.component.scss']
})
export class FilterDateSelectionComponent implements OnInit, OnDestroy {
  @Input() bg: string;
  @ViewChild('timePicker') picker: TemplateRef<any>;
  @Input() resetCmd$: Observable<void>;
  @Output() onDateSelection: EventEmitter<any> = new EventEmitter();
  selectedDates: { startDate: moment.Moment, endDate: moment.Moment } = {startDate: moment(), endDate: moment()};

  text$ = new BehaviorSubject<string>('')
  subText$ = new BehaviorSubject<string>(null);
  placeholder = 'Date of Information';
  dialogRef: MatDialogRef<any>;
  resetSubscription: Subscription;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.resetSubscription = this.resetCmd$.subscribe(() => {
      this.text$.next(this.placeholder);
      this.subText$.next(null);
    })

    this.text$.next(this.placeholder);
  }

  ngOnDestroy(): void {
    this.resetSubscription.unsubscribe();
  }

  onClick(event) {
    const target = new ElementRef(event.currentTarget);
    this.dialogRef = this.dialog.open(this.picker, {
      maxWidth: '800px',
      backdropClass: 'map-backdrop',
      width: 'content',
      height: 'content'
    })
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = target.nativeElement.getBoundingClientRect();
    matDialogConfig.position = {
      top: `${(rect.bottom + 20)}px`,
      left: `${rect.left - 150}px`
    }
    this.dialogRef.updatePosition(matDialogConfig.position);
    this.dialogRef.afterClosed().subscribe(() => {})
  }


  choosedDate(event: any) {
    this.text$.next(event.chosenLabel);
    this.subText$.next(this.placeholder);
    this.selectedDates = {startDate: event.startDate, endDate: event.endDate};
    this.onDateSelection.emit({startDate: event.startDate, endDate: event.endDate})
    this.dialogRef.close()
  }
}
