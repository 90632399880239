// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {PurposeMeasurementActions, PurposeMeasurementActionTypes} from '../_actions/purpose-measurement.actions';
// Models
import {PurposeMeasurement} from '../_models/purpose-measurement.model';
import {QueryParamsModel} from '../../_base/crud';

export interface PurposeMeasurementsState extends EntityState<PurposeMeasurement> {
    isAllPurposeMeasurementsLoaded: boolean;
    queryRowsCount: number;
    queryResult: PurposeMeasurement[];
    lastCreatedPurposeMeasurementId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PurposeMeasurement> = createEntityAdapter<PurposeMeasurement>();

export const initialPurposeMeasurementsState: PurposeMeasurementsState = adapter.getInitialState({
    isAllPurposeMeasurementsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedPurposeMeasurementId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function purposeMeasurementsReducer(state = initialPurposeMeasurementsState, action: PurposeMeasurementActions): PurposeMeasurementsState {
    switch (action.type) {
        case PurposeMeasurementActionTypes.PurposeMeasurementsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPurposeMeasurementId: undefined
            };
        case PurposeMeasurementActionTypes.PurposeMeasurementsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case PurposeMeasurementActionTypes.PurposeMeasurementOnServerCreated:
            return {
                ...state
            };
        case PurposeMeasurementActionTypes.PurposeMeasurementCreated:
            return adapter.addOne(action.payload.purposeMeasurement, {
                ...state, lastCreatedPurposeMeasurementId: action.payload.purposeMeasurement.id
            });
        case PurposeMeasurementActionTypes.PurposeMeasurementUpdated:
            return adapter.updateOne(action.payload.partialpurposeMeasurement, state);
        case PurposeMeasurementActionTypes.AllPurposeMeasurementsLoaded:
            return adapter.addAll(action.payload.purposeMeasurements, {
                ...state, isAllPurposeMeasurementsLoaded: true
            });
        case PurposeMeasurementActionTypes.PurposeMeasurementsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case PurposeMeasurementActionTypes.PurposeMeasurementsPageLoaded:
            return adapter.addMany(action.payload.purposeMeasurements, {
                ...initialPurposeMeasurementsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case PurposeMeasurementActionTypes.PurposeMeasurementDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case PurposeMeasurementActionTypes.PurposeMeasurementRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case PurposeMeasurementActionTypes.PurposeMeasurementDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case PurposeMeasurementActionTypes.PurposeMeasurementAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case PurposeMeasurementActionTypes.PurposeMeasurementDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case PurposeMeasurementActionTypes.PurposeMeasurementTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
