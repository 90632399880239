import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ConfirmDialogComponentInput {
  description: string
}
export type ConfirmDialogComponentOutput = 'yes' | 'no' | undefined

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogComponentOutput>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponentInput
  ) {}

  onAccept() {
    this.dialogRef.close('yes')
  }
  onUnaccept() {
    this.dialogRef.close('no')
  }
}