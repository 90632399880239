import { createSelector } from "@ngrx/store";
import { selectFeature, targetProperty } from "./_base";
import * as _ from "lodash";
import { CostRevenue } from "../types";
import { EffectiveRentUtil } from "../core/headline-rent";
import { calculateFreeholdCapOnPerpetuity, calculateLeaseholdDualRateWithASFAndAllowanceForTax, calculateLeaseholdUnderMRDualRateWithASFAndAllowanceForTaxDouble } from "../core/income";
import { ConvertedCostRevenueUtils } from "../types/converted-cost-revenue";
import { calculateFreeholdCapOnShortTerm } from "../core/income/freehold-cap-on-short-term";
import { calculateTermAndReversionHadrcoreLayer } from "../core/income/term-and-reversion-hardcore-layer";
import { calculateTermAndReversionHardcoreFroth } from "../core/income/term-and-reversion-hardcore-froth";
import { calculateLeaseholdDualRateWithASFUnderMRDualRateAndAllowanceForTaxParnellApproach } from "../core/income/leasehold-dual-rate-with-asf-under-mr-dual-rate-and-allowance-for-tax-parnell-approach";
import { calculateFreeholdWithTerminableLifeBuildingReversionToSiteValue } from "../core/income/freehold-with-terminable-life-building-reversion-to-site-value";
import { calculateFreeholdWithTerminableLifeBuildingProvisionForRebuilding } from "../core/income/freehold-with-terminable-life-building-provision-for-rebuilding";
import { convertedResultValue } from "../core/income/converted-result-value";

export const leases = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    return state.valuationProcess.data.leases
  }
)

export const propertyRevenues = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    return state.valuationProcess.data.propertyRevenues
  }
)

export const valuationDate = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return null
    if (state.valuationProcess.kind === 'market') return null
    return state.valuationProcess.data.valuationDate
  }
)

export const leasesAndPropertyRevenues = createSelector(
  leases,
  propertyRevenues,
  (leases, propertyRevenues) => {
    const costRevenues: Array<CostRevenue & {label: string}>  = [
      ...leases.map((item, index) => ({...item, label: `L #${index + 1}`})),
      ...propertyRevenues.map((item, index) => ({...item, label: `O #${index + 1}`}))
    ]
    return costRevenues
  }
)

const sizes = createSelector(
  leases,
  targetProperty,
  (leases, targetProperty) => {
    if (targetProperty === null) return {totalLeasedArea: 0, tpSize: 0}
    const totalLeasedArea = leases.reduce((acc, lease) => acc + lease.leasedArea, 0)
    let tpSize = 0
    if (targetProperty.info.sizes.length > 0) {
      tpSize = targetProperty.info.sizes[0].size
    }
    return {
      totalLeasedArea,
      tpSize,
    }
  }
)

const reportingCurrency = createSelector(
  targetProperty,
  targetProperty => {
    if (targetProperty === null) return null
    return targetProperty.reportingCurrency
  }
)

export const valuationValid = createSelector(
  leasesAndPropertyRevenues,
  sizes,
  valuationDate,
  reportingCurrency,
  (items, sizes, valuationDate, reportingCurrency) => {
    const results = items.map(item => {
      const size = item.kind == 'lease'
        ? item.leasedArea
        : item.size === 'total_lease'
          ? sizes.totalLeasedArea
          : item.size === 'tp'
            ? sizes.tpSize
            : item.sizeInput
      const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
      const result = calculationResult(item, effectiveRent, sizes, valuationDate)
      const value = convertedResultValue(result, item, reportingCurrency)
      return value
    })
    if (results.some(item => item === undefined)) return undefined
    const totalValue = results.reduce((acc, item) => {
      return acc + item
    }, 0)
    return totalValue !== undefined && totalValue >= 0
  }
)

export function calculationResult(
  costRevenue: CostRevenue, 
  effectiveRent: number, 
  sizes: {totalLeasedArea: number, tpSize: number},
  valuationDate: Date
) {
  if (costRevenue.calculationMeta.methodId == 16) {
    const size = costRevenue.kind === 'lease'
      ? costRevenue.leasedArea
      : costRevenue.size === 'total_lease'
        ? sizes.totalLeasedArea
        : costRevenue.size === 'tp'
          ? sizes.tpSize
          : costRevenue.sizeInput
    return calculateFreeholdCapOnPerpetuity(
      effectiveRent,
      {...costRevenue, sizeValue: size},
      costRevenue.calculationMeta.capitalisationRate,
      costRevenue.calculationMeta.purchasersCost,
      costRevenue.calculationMeta.timingId
    )
  }

  if (costRevenue.calculationMeta.methodId == 18) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)

    return calculateFreeholdCapOnShortTerm(
      effectiveRent,
      convertedCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        estimatedVoidPeriod: costRevenue.calculationMeta.estimatedVoidPeriod,
        growthRate: costRevenue.calculationMeta.growthRatePerYear,
        purchaserCost: costRevenue.calculationMeta.purchasersCost
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 19) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)

    return calculateTermAndReversionHadrcoreLayer(
      effectiveRent,
      convertedCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        estimatedVoidPeriod: costRevenue.calculationMeta.estimatedVoidPeriod,
        growthRate: costRevenue.calculationMeta.growthRatePerYear,
        purchaserCost: costRevenue.calculationMeta.purchasersCost,
        marketRent: costRevenue.calculationMeta.marketRent,
        reversionaryYield: costRevenue.calculationMeta.reversionaryYield
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 20) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)

    return calculateTermAndReversionHardcoreFroth(
      effectiveRent,
      convertedCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        purchaserCost: costRevenue.calculationMeta.purchasersCost,
        marketRent: costRevenue.calculationMeta.marketRent,
        reversionaryYield: costRevenue.calculationMeta.reversionaryYield
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 23) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
    const convertedSubCostRevenue = costRevenue.subLease 
      ? costRevenue.subLease.kind == 'lease'
        ? ConvertedCostRevenueUtils.fromLease(costRevenue.subLease, sizes.totalLeasedArea, sizes.tpSize)
        : ConvertedCostRevenueUtils.fromRevenue(costRevenue.subLease, sizes.totalLeasedArea, sizes.tpSize)
      : null

    return calculateLeaseholdDualRateWithASFAndAllowanceForTax(
      convertedCostRevenue,
      convertedSubCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        annualSinkingFund: costRevenue.calculationMeta.annualSinkingFund,
        taxRate: costRevenue.calculationMeta.taxRate,
        timingId: costRevenue.calculationMeta.timingId 
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 24) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
    const convertedSubCostRevenue = costRevenue.subLease 
      ? costRevenue.subLease.kind == 'lease'
        ? ConvertedCostRevenueUtils.fromLease(costRevenue.subLease, sizes.totalLeasedArea, sizes.tpSize)
        : ConvertedCostRevenueUtils.fromRevenue(costRevenue.subLease, sizes.totalLeasedArea, sizes.tpSize)
      : null
    return calculateLeaseholdUnderMRDualRateWithASFAndAllowanceForTaxDouble(
      convertedCostRevenue,
      convertedSubCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        annualSinkingFund: costRevenue.calculationMeta.annualSinkingFund,
        annualSinkingFund2ndTerm: costRevenue.calculationMeta.annualSinkingFund2ndTerm,
        marketRent: costRevenue.calculationMeta.marketRent,
        taxRate: costRevenue.calculationMeta.taxRate,
        subActiveBreakOffOption: costRevenue.calculationMeta.subLeaseActiveBreakOption,
        subEstimatedVoidPeriod: costRevenue.calculationMeta.subLeaseEstimatedVoidPeriod,
        subGrowthRate: costRevenue.calculationMeta.subLeaseGrowthRatePerYear,
        timingId: costRevenue.calculationMeta.timingId
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 25) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
    const convertedSubCostRevenue = costRevenue.subLease 
      ? costRevenue.subLease.kind == 'lease'
        ? ConvertedCostRevenueUtils.fromLease(costRevenue.subLease, sizes.totalLeasedArea, sizes.tpSize)
        : ConvertedCostRevenueUtils.fromRevenue(costRevenue.subLease, sizes.totalLeasedArea, sizes.tpSize)
      : null
    return calculateLeaseholdDualRateWithASFUnderMRDualRateAndAllowanceForTaxParnellApproach(
      convertedCostRevenue,
      convertedSubCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        annualSinkingFund: costRevenue.calculationMeta.annualSinkingFund,
        marketRent: costRevenue.calculationMeta.marketRent,
        taxRate: costRevenue.calculationMeta.taxRate,
        subActiveBreakOffOption: costRevenue.calculationMeta.subLeaseActiveBreakOption,
        subEstimatedVoidPeriod: costRevenue.calculationMeta.subLeaseEstimatedVoidPeriod,
        subGrowthRate: costRevenue.calculationMeta.subLeaseGrowthRatePerYear,
        timingId: costRevenue.calculationMeta.timingId
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 27) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
    return calculateFreeholdWithTerminableLifeBuildingReversionToSiteValue(
      convertedCostRevenue,
      valuationDate,
      {
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        activeBreakOffOption: costRevenue.calculationMeta.activeBreakOption,
        securedRent: costRevenue.calculationMeta.securedRent,
        estimatedRentForLand: costRevenue.calculationMeta.estimatedRentForLand,
        annualSinkingFund: costRevenue.calculationMeta.annualSinkingFund,
        taxRate: costRevenue.calculationMeta.taxRate,
        allRiskYieldForASF: costRevenue.calculationMeta.allRiskYieldForASF,
        allRiskYieldOnPerpetuity: costRevenue.calculationMeta.allRiskYieldOnPerpetuity,
        estimatedVoidPeriod: costRevenue.calculationMeta.estimatedVoidPeriod,
        purchaserCost: costRevenue.calculationMeta.purchasersCost
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 28) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)
    return calculateFreeholdWithTerminableLifeBuildingProvisionForRebuilding(
      convertedCostRevenue,
      valuationDate,
      {
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        costOfRebuilding: costRevenue.calculationMeta.costOfRebuilding,
        estimatedBuildingTerminableLife: costRevenue.calculationMeta.estimatedBuildingTerminableLife,
        purchaserCost: costRevenue.calculationMeta.purchasersCost
      }
    )
  }
  return undefined
}