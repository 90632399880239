import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AssetClassLandService } from "src/app/core/comparable/_services/asset-class-land.service";

@Injectable()
export class AssetClassLandGetResolver implements Resolve<any> {
    constructor(
        private assetClassLandService: AssetClassLandService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const id = Number(route.params.land_id);
        return this.assetClassLandService.getAssetClass(id);
    }
}