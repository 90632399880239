<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'card-head-lg'"
                        [tooltipTitle]="'TOOLTIP.COUNTRY.LIST.TITLE' | translate"
                        [tooltipDesc]="'TOOLTIP.COUNTRY.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <!-- <h3 class="kt-portlet__head-title"> -->
                <span>{{ 'COUNTRY.LIST.TITLE' | translate }}</span>
            <!-- </h3> -->
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <button (click)="addCountry()"
                    mat-raised-button
                    [matTooltip]="'COUNTRY.LIST.BUTTON.NEW.TOOLTIP' | translate"
                    color="primary"
                    class="mr-2"
                    type="button">
                <span>{{ 'COUNTRY.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button *ngIf="(trashCnt$ |async) != 0" (click)="trash()" mat-raised-button [matTooltip]="'COUNTRY.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt$ |async }})</span>
            </button>
            <button *ngIf="(adminTrashCnt$ |async) != 0" (click)="adminTrash()" mat-raised-button [matTooltip]="'COUNTRY.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt$ |async }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form">
            <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="countryStatusCnts$ | async as vm;">
                <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">
    
                    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                        <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                            <span style="vertical-align: middle; color: #E8E8E8;">
                                {{ vm.total_countries }}
                            </span>
                        </div>
                        <div class="float-right align-content-end w-100 text-uppercase text-white">
                            <p class="m-0">
                                <span style="color: #cccccc;">TOTAL COUNTRIES</span>
                            </p>
                        </div>
                    </div>
                    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_used_in_vals }}
                        </span>
                        <div class="float-right align-content-end w-100 text-uppercase text-white">
                            <p class="m-0"><span style="color: #E8E8E8;">USED IN VALUATIONS</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center active">

                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">
                    </mat-form-field>
                    <div class="col-md-1">
                        <button (click)="resetFilter()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="asc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let country">{{country.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'COUNTRY.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country" (click)="editCountry(country)">{{country.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="vat">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'COUNTRY.LIST.DATATABLE.VAT' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">{{country.vat | mask: 'separator'}}%</mat-cell>
                </ng-container>

                <ng-container matColumnDef="currency">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'COUNTRY.LIST.DATATABLE.CURRENCY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">{{country.currency}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city_cnt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'COUNTRY.LIST.DATATABLE.CITIES' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">
                        <span [routerLink]="['../countries/',country.id]"
                              [matTooltip]="'COUNTRY.LIST.BUTTON.CITIES.TOOLTIP' | translate:{
                                  name: country.name
                              }">
                            {{country.city_cnt}}
                        </span>&nbsp;
                        <button mat-icon-button [matTooltip]="'COUNTRY.LIST.BUTTON.ADD_CITY.TOOLTIP' | translate" (click)="addCity(country)">
                            <mat-icon color="primary">add</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'COUNTRY.LIST.DATATABLE.DESCRIPTION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">{{country.description | truncate : ['100']}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="vpga">
                    <mat-header-cell *matHeaderCellDef>
                        VPGA
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">
                        {{country.vpgas.length}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        Country Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">
                        {{country.status_public_domain | truncate : ['100']}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'COUNTRY.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let country">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <!-- <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: country.createdBy ? country.createdBy : 'Unknown',
                                date: (country.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: country.lastUpdatedBy ? country.lastUpdatedBy: 'Unknown',
                                date: (country.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div> -->
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: country.createdBy ? country.createdBy : 'Unknown',
                                date: (country.created_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: country.lastUpdatedBy ? country.lastUpdatedBy: 'Unknown',
                                date: (country.updated_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                        </ng-template>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" [matTooltip]="'COUNTRY.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editCountry(country)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <span
                                [matTooltip]="country.relation_cnt == 0 
                                    ? ('COUNTRY.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate)
                                    : ('COUNTRY.LIST.BUTTON.DELETE.TOOLTIP.CANNOT' | translate)">
                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="country.relation_cnt > 0"
                                    (click)="deleteCountry(country)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>

                        <!--<button mat-icon-button-->
                        <!--[routerLink]="['../countries/',country.id]"-->
                        <!--matTooltip="Cities of {{country.name}}"-->
                        <!--type="button">-->
                        <!--<mat-icon>place</mat-icon>-->
                        <!--&lt;!&ndash;<i class="flaticon2-pin kt-font-success"></i>&ndash;&gt;-->
                        <!--</button>&nbsp;-->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="countryData.length == 0">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}</div>
        </div>
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
