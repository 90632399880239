import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, QueryParamsModel } from '../../../../core/_base/crud';
import { Store, select } from '@ngrx/store';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import {
    TypeOfRegistration, TypeOfRegistrationDeleted, TypeOfRegistrationDataSource, TypeOfRegistrationsPageRequested,
    selectTrashedTypeOfRegistrationCount, selectAdminTrashedTypeOfRegistrationCount,
    TypeOfRegistrationsService,
    TypeOfRegistrationsActionToggleLoading,
    TypeOfRegistrationOnServerAdminRestored,
    TypeOfRegistrationOnServerRestored,
    TypeOfRegistrationDeletedFromTrash,
    TypeOfRegistrationDeletedFromAdminTrash,
    TypeOfRegistrationTrashFlushed,
} from '../../../../core/linked-tables';

@Component({
    selector: 'kt-type-of-registration-list',
    templateUrl: './type-of-registration-list.component.html',
    styleUrls: ['./type-of-registration-list.component.scss']
})
export class TypeOfRegistrationListComponent implements OnInit, OnDestroy {

    dataSource: TypeOfRegistrationDataSource;
    displayedColumns = ['id', 'name', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<TypeOfRegistration>(true, []);
    typeOfRegistrationResult: TypeOfRegistration[] = [];

    trashCnt: number = 0;
    adminTrashCnt: number = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param subheaderService
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param subheaderService
     * @param typeOfRegistrationsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: TypeOfRegistrationsService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {

        this.subheaderService.setTitle('TypeOfRegistrations');
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../admin-management`},
            {title: 'Linked tables', page: `../admin-management/linked-tables`},
            {title: 'TypeOfRegistrations', page: `../admin-management/linked-tables/typeOfRegistrations`}
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadTypeOfRegistrationsList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadTypeOfRegistrationsList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new TypeOfRegistrationDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.typeOfRegistrationResult = res;
            
        });

        this.subscriptions.push(entitiesSubscription);

        // trash count
        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedTypeOfRegistrationCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedTypeOfRegistrationCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadTypeOfRegistrationsList();
        }); // Remove this line, just loading imitation
    }

    /**
     * Load TypeOfRegistrations List from service through data-source
     */
    loadTypeOfRegistrationsList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        // Call request from server
        this.store.dispatch(new TypeOfRegistrationsPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadTypeOfRegistrationsList();
    }

    /**
     * Show add typeOfRegistration dialog
     */
    addTypeOfRegistration() {
        this.editTypeOfRegistration(null);
    }

    /**
     * Show Edit typeOfRegistration view and save after success close result
     * @param _typeOfRegistration
     */
    editTypeOfRegistration(_typeOfRegistration) {
        if (_typeOfRegistration) {
            this.router.navigate(['edit', _typeOfRegistration.id], {relativeTo: this.activatedRoute});
            return;
        }
        this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    deleteTypeOfRegistration(_item: TypeOfRegistration) {
        const _title = this.translate.instant('TYPE_OF_REGISTRATION.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('TYPE_OF_REGISTRATION.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
        const _waitDesciption =  this.translate.instant('TYPE_OF_REGISTRATION.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new TypeOfRegistrationDeleted({id: _item.id}));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new TypeOfRegistrationsActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new TypeOfRegistrationsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new TypeOfRegistrationsActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new TypeOfRegistrationsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = 'Deleted Type of Registrations';
        if (isAdmin) {
            _title = 'Admin Deleted Type of Registrations';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new TypeOfRegistrationOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new TypeOfRegistrationOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new TypeOfRegistrationDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new TypeOfRegistrationDeletedFromAdminTrash({id}));
    }

    flush() {
        
        this.store.dispatch(new TypeOfRegistrationTrashFlushed());
    }
}
