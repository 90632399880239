<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Add Parking</h3>
        </div>
        <div class="card-toolbar">
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup name="radioBasic"
                [formControl]="formSelectionCtrl">
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                    <input ngbButton type="radio" class="btn-check" [value]="2"> Simple
                </label>
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-left-white">
                    <input ngbButton type="radio" class="btn-check" [value]="1"> Advanced
                </label>
            </div>
        </div>
        <div style="width: 20%"></div>
    </div>

    <div class="form form-group-seperator-dashed">
        <form class="form" [formGroup]="formGroup">
            <div class="card-body">

                <mat-dialog-content>
            <kt-tab-header 
                [innerTop]="'0px'"
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Location</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-map-location
                            [title]="'Location'"
                            [showMap]="true"
                            [lockMarker]="false"
                            [isComplexForm]="isComplexForm"
                            [locationData]="ac.locationData"
                            [parentType]="'comparable'"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (markerChange)="onMarkerChange($event)"
                        >
                        ></kt-map-location>

                        <div class="form-group kt-form__group row" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile section-margin-top-60">
                                <h5 class="my-3 text-mad text-uppercase">
                                Parcel Identification 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12 mb-2">
                                <mat-label>Would you like to add information on the land</mat-label>
                                <mat-radio-group class="ml-4" formControlName="add_info_on_land">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <ng-container *ngIf="formGroup.controls.add_info_on_land.value">
                                <div class="col-lg-4">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input matInput placeholder="Land Parcel ID" formControlName="land_parcel_name" type="text">
                                        <mat-error>
                                            Land Parcel ID <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Coordinate Reference System" formControlName="coordinate_reference_system_id">
                                            <mat-option *ngFor="let coordinateReferenceSystem of coordinateReferenceSystems" [value]="coordinateReferenceSystem.id">{{coordinateReferenceSystem.name}}</mat-option>
                                        </mat-select>
                                        <mat-icon
                                            matSuffix
                                            class="cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            [ngbPopover]="'TOOLTIP.LAND_PARCEL.CRS.DESCRIPTION' | translate">help
                                        </mat-icon>
                                        <mat-error>
                                            Coordinate Reference System
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12 mt-2">
                                    <mat-label style="color: gray;"> 
                                        Please draw on the map below the coordinates of each boundary cover or pivot point of the land parcel:
                                    </mat-label>
                                    <mat-icon
                                        matSuffix
                                        class="cursor-pointer"
                                        (click)="$event.stopPropagation();"
                                        container="body"
                                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.MAP.DESCRIPTION' | translate">help
                                    </mat-icon>
                                </div>
                                <div class="col-lg-12 mb-10">
                                    <kt-land-parcel-map
                                        [points]="points"
                                        [markerInfo]="markerInfo"
                                        [centerLat]="centerLat"
                                        [centerLng]="centerLng"
                                        [staticUnit]="2"
                                        [staticUnitLengthName]="'M'"
                                        [staticUnitName]="'sqm'">
                                    </kt-land-parcel-map>
                                </div>
                                <kt-land-area-list
                                    class="col-lg-12"
                                    [landAreaComponentListSubject]="landAreaComponentListSubject"
                                    [landAreaSubject]="landAreaSubject"
                                    [showOtherFields]="true">
                                </kt-land-area-list>
                            </ng-container>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Property & building information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   About the property 
                                </h5>
                                <hr class="active">
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-type category" formControlName="sub_type_category_id">
                                        <mat-option *ngFor="let item of subTypeCategories" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-type category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="filteredSubCategories.length != 0">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-category" formControlName="sub_category_id">
                                        <mat-option *ngFor="let item of filteredSubCategories" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput type="number" placeholder="Number of unit(s)" formControlName="number_of_units" />
                                    <mat-error>
                                        Number of unit(s) <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Parking Type" formControlName="parking_type_id">
                                        <mat-option *ngFor="let item of parkingTypes" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Parking type <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="State of repair" formControlName="state_of_repair_id">
                                        <mat-option *ngFor="let item of stateOfRepairs" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        State of Repair <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Handover standard" formControlName="handover_standard_id">
                                        <mat-option *ngFor="let item of handoverStandards" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Handover standard <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-12 mb-2 kt-margin-bottom-10-mobile" *ngIf="isParkingGarage">
                                <tag-input class="floor" matInput formControlName="floor_details"
                                           [placeholder]="'PARKING.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                                           [secondaryPlaceholder]="'PARKING.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                                           modelAsStrings="true"
                                           style="width: 100%"
                                >
                                </tag-input>
                            </div>            
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Automation" formControlName="automation">
                                        <mat-option [value]="1">Yes</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Automation <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-8 kt-margin-bottom-20-mobile" style="align-self: center;" *ngIf="formGroup.controls.automation.value == 1">
                                <!-- <div class="row"> -->
                                    <!-- <div class="col-lg-12"> -->
                                        <mat-radio-group   formControlName="automation_type">
                                            <mat-radio-button [value]="'full'">Automated parking system</mat-radio-button>
                                            <mat-radio-button [value]="'semi'">Semi-automated parking system</mat-radio-button>
                                        </mat-radio-group>
                                    <!-- </div> -->
                                <!-- </div> -->
                            </div>
        
        
                            <div class="kt-margin-bottom-20-mobile" [ngClass]="{'col-lg-8': formGroup.controls.automation.value != 1, 'col-lg-12': formGroup.controls.automation.value == 1}">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="1" placeholder="Property general description" formControlName="property_general_desc"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <kt-building-form
                            *ngIf="isComplexForm && isParkingGarage"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (centerChange)="onCenterChange($event)"
                            (picChange)="onPicChange($event)"
                            [buildingPictures]="building_pictures"
                            [buildingPicturePath]="building_picture"
                            [isComplexForm]="isComplexForm"
                            [acBuilding]="ac.buildingInfo"></kt-building-form>
        

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   Services and Infrastructures 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-services-and-infrastructures 
                                    [propertySubTypeID]="2"
                                    [isComplexForm]="isComplexForm"
                                    [serviceAndInfraData]="ac.serviceAndInfraData"
                                    ></kt-services-and-infrastructures>
                            </div>
                        </div>
                        <kt-ac-size class="section-margin-top-60" [sizesSubject]="sizesSubject" [assetClassType]="'2'"></kt-ac-size>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && sizesSubject.value.length == 0"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APARTMENT.FORM.FIELD.SIZES.SECTION_TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Lease & sale information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-consideration-and-source
                            style="margin-top: 60px;"
                            [inputConsiderations]="tenuresSubject|async"
                            [landAreaComponentListSubject]="landAreaComponentListSubject"
                            [hasParcelInfo$]="hasParcelInfo$"
                            [sizes]="sizes$|async"></kt-consideration-and-source>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && (considerationError$|async)"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APARTMENT.FORM.FIELD.CONSIDERATIONS.TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        
                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Pictures
                                </h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #simple class="col-lg-12"
                                [filesOnEditMode]="ac.ac_pictures"
                                [folderOnEditMode]="ac.picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="1"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Parking Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
                        
                        <div class="form-group row section-margin-top-60" *ngIf="isComplexForm && isParkingGarage">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Building Pictures
                                </h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #buildingPic class="col-lg-12"
                                [filesOnEditMode]="ac.b_pictures"
                                [folderOnEditMode]="ac.b_picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="2"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Building Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && buildingFileComponent && buildingFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <kt-ac-document-upload class="section-margin-top-60" [files]="ac.documents" *ngIf="isComplexForm">
                        </kt-ac-document-upload>
                    </div>
                </mat-tab>
            </mat-tab-group>
            </mat-dialog-content>
            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="cancel()">
                    Cancel
                </button>
                &nbsp;
                <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit(true)">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>