import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { TpTemplateActions, TpTemplateActionTypes } from "../_actions";
import { TpTemplateModel } from "../_models";

export interface TpTemplateState extends EntityState<TpTemplateModel> {
    isLoading: boolean;
    total: number;
}
export const adapter: EntityAdapter<TpTemplateModel> = createEntityAdapter<TpTemplateModel>();
export const initialState: TpTemplateState = adapter.getInitialState({
    isLoading: false,
    total: 0
});

export function tpTempalteReducer(state = initialState, action: TpTemplateActions): TpTemplateState {
    switch (action.type) {
        case TpTemplateActionTypes.LOAD_TP_TEMPLATES: {
            return {
                ...state,
                isLoading: true
            };
        }
        case TpTemplateActionTypes.TP_TEMPLATES_LOADED: {
            return adapter.setAll(action.payload.templates, {
                ...state,
                isLoading: false,
                total: action.payload.total
            })
        }
        case TpTemplateActionTypes.ADD_TP_TEMPLATE: {
            return state;
        }
        case TpTemplateActionTypes.TP_TEMPLATE_ADDED: {
            return adapter.addOne(action.payload.template, state);
        }
        case TpTemplateActionTypes.UPDATE_TP_TEMPLATE: {
            return state;
        }
        case TpTemplateActionTypes.TP_TEMPLATE_UPDATED: {
            return adapter.updateOne(action.payload.template, state);
        }
        case TpTemplateActionTypes.DELETE_TP_TEMPLATE: {
            return state;
        }
        case TpTemplateActionTypes.TP_TEMPLATE_DELETED: {
            return adapter.removeOne(action.payload.id, state);
        }
        default: {
            return state;
        }
    }
}