import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { ChimneyStackActionToggleLoading, ChimneyStackDeleted, ChimneyStackDeletedFromAdminTrash, ChimneyStackDeletedFromTrash, ChimneyStackModel, ChimneyStackOnServerAdminRestored, ChimneyStackOnServerRestored, ChimneyStacksDataSource, ChimneyStackService, ChimneyStacksPageRequested, ChimneyStackTrashFlushed, ChimneyStackUpdated, selectAdminTrashedChimneyStackCount, selectTrashedChimneyStackCount } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
  selector: 'kt-chimney-stacks-list',
  templateUrl: './chimney-stacks-list.component.html',
  styleUrls: ['./chimney-stacks-list.component.scss']
})
export class ChimneyStacksListComponent implements OnInit {
    dataSource: ChimneyStacksDataSource;
    displayedColumns = ['id', 'name', 'is_hidden', 'actions'];
    trashCnt = 0;
    adminTrashCnt = 0;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    mode = 'NEW';
    selection = new SelectionModel<ChimneyStackModel>(true, []);
    subTypeCategoryResult: ChimneyStackModel[] = [];
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

    // Subscriptions
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param service
     * @param subheaderService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: ChimneyStackService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>) {
    }
  ngOnInit(): void {
        this.subheaderService.setTitle('Base of Values');
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Base Of Values', page: `../default/admin-management/linked-tables/base-of-values`}
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadChimneyStacksList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadChimneyStacksList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new ChimneyStacksDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.subTypeCategoryResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        // trash count

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedChimneyStackCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedChimneyStackCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitatio
            this.loadChimneyStacksList();
        }); // Remove this line, just loading imitation
  }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    /**
     * Load Purpose Of Valuation List from service through data-source
     */
    loadChimneyStacksList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        this.store.dispatch(new ChimneyStacksPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {
            with_hidden: true
        };
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadChimneyStacksList();
    }

    /**
     * Show add purpose of valuation view
     */
    addChimneyStack() {
        this.editChimneyStack(null);
    }

    /**
     * Show Edit subTypeCategory dialog and save after success close result
     * @param subTypeCategory: ChimneyStack
     */
    editChimneyStack(_subTypeCategory) {
        if (_subTypeCategory) {
            this.router.navigate(['edit', _subTypeCategory.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
        }
    }

    deleteChimneyStack(_item: ChimneyStackModel) {
        const _title = this.translate.instant('CHIMNEY_STACK.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('CHIMNEY_STACK.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
        const _waitDesciption =  this.translate.instant('CHIMNEY_STACK.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new ChimneyStackDeleted({id: _item.id}));
        });
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new ChimneyStackActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new ChimneyStackActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.user_deleted_by && elem.user_deleted_by.length > 0 ? elem.user_deleted_by : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new ChimneyStackActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new ChimneyStackActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deleted_by && elem.deleted_by.length > 0 ? elem.deleted_by : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('CHIMNEY_STACK.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('CHIMNEY_STACK.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new ChimneyStackOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new ChimneyStackOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new ChimneyStackDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new ChimneyStackDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new ChimneyStackTrashFlushed());
    }

    viewEntry(item: ChimneyStackModel) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }
    onHiddenChange(event: MatCheckboxChange, item: ChimneyStackModel) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new ChimneyStackUpdated({
            item: _item,
            partialItem: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
