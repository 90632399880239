import {Component, OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {AllAssetClassTypesRequested, AssetClassType, selectAllAssetClassTypes, ExternalFacadeUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import {
    ExternalFacadeModel,
    ExternalFacadeOnServerCreated,
    selectExternalFacadesActionLoading,
    selectExternalFacadeById,
    selectLastCreatedExternalFacadeId
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';
import { addBuildingAsAssetClass } from '../lt_utils/add_building_ac';
import {combineLatest} from 'rxjs'

@Component({
    selector: 'kt-external-facade-edit',
    templateUrl: './external-facade-edit.component.html',
    styleUrls: ['./external-facade-edit.component.scss']
})


export class ExternalFacadeEditComponent implements OnInit, OnDestroy {

    model: ExternalFacadeModel;
    oldModel: ExternalFacadeModel;

    ExternalFacadeForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    readonly = false
    propertySubTypes: AssetClassType[] = [];
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                public subheaderService: SubheaderService,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectExternalFacadesActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectExternalFacadeById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initExternalFacade();
                    }
                });
            } else {
                this.model = new ExternalFacadeModel();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initExternalFacade();
            }
        });
        this.subscriptions.push(routeSubscription);

        this.store.dispatch(new AllAssetClassTypesRequested())
        this.store.select(selectAllAssetClassTypes).subscribe(res => {
          this.propertySubTypes = [];
          if (res) {
            // this.propertySubTypes = res.filter(item => item.id == 7 || item.id == 11 || item.id == 17 || item.id == 5);
            this.propertySubTypes = addBuildingAsAssetClass(this.propertySubTypes);
          }
        })
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initExternalFacade() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.ExternalFacadeForm = this.fb.group({
            name: [this.model.name, Validators.required],
            property_sub_type_id: [this.model.property_sub_type_id, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return `Edit External Facade '${
                this.model.name
                }'`;
        }

        return 'New External Facade';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.ExternalFacadeForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.ExternalFacadeForm.markAsPristine();
        this.ExternalFacadeForm.markAsUntouched();
        this.ExternalFacadeForm.updateValueAndValidity();
    }

    prepareExternalFacade(): ExternalFacadeModel {
        const controls = this.ExternalFacadeForm.controls;
        const _model = new ExternalFacadeModel();
        _model.clear();
        _model.id = this.model.id;
        _model.name = controls.name.value;
        _model.property_sub_type_id = controls.property_sub_type_id.value;
        _model.is_hidden = this.model.is_hidden;

        return _model;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.ExternalFacadeForm.controls;
        /** check form */
        if (this.ExternalFacadeForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedFacility = this.prepareExternalFacade();
        if (this.model.id > 0) {
            this.updateExternalFacade(editedFacility);
        } else {
            this.createExternalFacade(editedFacility);
        }
    }

    /**
     * Update model
     *
     * @param _model: ExternalFacadeModel
     */
    updateExternalFacade(_model: ExternalFacadeModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateExternalFacade: Update<ExternalFacadeModel> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new ExternalFacadeUpdated({
            partialItem: updateExternalFacade,
            item: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();

    }

    /**
     * Create model
     *
     * @param _model: ExternalFacadeModel
     */
    createExternalFacade(_model: ExternalFacadeModel) {
        this.store.dispatch(new ExternalFacadeOnServerCreated({item: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedExternalFacadeId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0 &&
            this.ExternalFacadeForm && this.ExternalFacadeForm.controls.property_sub_type_id.value >= 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }

}
