import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { CriterionModel } from 'src/app/core/valuation';
import { SetCriterionActive, SetCriterionDeactive } from 'src/app/core/valuation/_actions/criterion.actions';
import { RemoveCriterionFromAdjustment } from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import { selectOtherCriterions } from 'src/app/core/valuation/_selectors/criterion.selectors';

@Component({
  selector: 'kt-criterion-modal',
  templateUrl: './criterion-modal.component.html',
  styleUrls: ['./criterion-modal.component.scss']
})
export class CriterionModalComponent implements OnInit {
  dataSource: MatTableDataSource<CriterionModel> = new MatTableDataSource();
  comparables: any[];
  headerColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]); 
  displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  constructor(
    public dialogRef: MatDialogRef<CriterionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>
  ) {
    this.comparables = data.comparables;
  }

  ngOnInit(): void {
    this._setHeaders(this.comparables);
    this.store.pipe(select(selectOtherCriterions)).subscribe(res => {
      res.sort((a, b) => a.order_num - b.order_num)
      this.dataSource.data = res;
    })
  }

  _setHeaders(comparables: any[]) {
    const headerColumns = ['input-header'];
    const displayedColumns = ['criterion', 'category'];
    const comparableHeaderColumns = [];
    comparables.forEach((com, i) => {
      headerColumns.push(`com-${i}-${comparables.length}-header`);
      comparableHeaderColumns.push(`com-${i}-${comparables.length}-header`);
      displayedColumns.push(`com-${i}-${comparables.length}`);
    });
    headerColumns.push(`tp-${comparables.length}-header`, 'action-header');
    displayedColumns.push(`tp-${comparables.length}`, 'actions');
    this.headerColumns$.next(headerColumns);
    this.displayedColumns$.next(displayedColumns);
  }

  setCriterionToActive(criterion: CriterionModel) {
    this.store.dispatch(new SetCriterionActive({criterion: criterion}));
  }

  setCriterionToInactive(criterion: CriterionModel) {
    this.store.dispatch(new SetCriterionDeactive({criterion: criterion}));
    this.store.dispatch(new RemoveCriterionFromAdjustment({criterion: criterion}));
  }

  onClose() {
    this.dialogRef.close()
  }

}
