// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TypeOfTitleActions, TypeOfTitleActionTypes} from '../_actions/type-of-title.actions';
// Models
import {TypeOfTitle} from '../_models/type-of-title.model';
import {QueryParamsModel} from '../../_base/crud';

export interface TypeOfTitlesState extends EntityState<TypeOfTitle> {
    isAllTypeOfTitlesLoaded: boolean;
    queryRowsCount: number;
    queryResult: TypeOfTitle[];
    lastCreatedTypeOfTitleId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TypeOfTitle> = createEntityAdapter<TypeOfTitle>();

export const initialTypeOfTitlesState: TypeOfTitlesState = adapter.getInitialState({
    isAllTypeOfTitlesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedTypeOfTitleId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function typeOfTitlesReducer(state = initialTypeOfTitlesState, action: TypeOfTitleActions): TypeOfTitlesState {
    switch (action.type) {
        case TypeOfTitleActionTypes.TypeOfTitlesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTypeOfTitleId: undefined
            };
        case TypeOfTitleActionTypes.TypeOfTitlesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case TypeOfTitleActionTypes.TypeOfTitleOnServerCreated:
            return {
                ...state
            };
        case TypeOfTitleActionTypes.TypeOfTitleCreated:
            return adapter.addOne(action.payload.typeOfTitle, {
                ...state, lastCreatedTypeOfTitleId: action.payload.typeOfTitle.id
            });
        case TypeOfTitleActionTypes.TypeOfTitleUpdated:
            return adapter.updateOne(action.payload.partialTypeOfTitle, state);
        case TypeOfTitleActionTypes.AllTypeOfTitlesLoaded:
            return adapter.addAll(action.payload.typeOfTitles, {
                ...state, isAllTypeOfTitlesLoaded: true
            });
        case TypeOfTitleActionTypes.TypeOfTitlesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case TypeOfTitleActionTypes.TypeOfTitlesPageLoaded:
            return adapter.addMany(action.payload.typeOfTitles, {
                ...initialTypeOfTitlesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case TypeOfTitleActionTypes.TypeOfTitleDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case TypeOfTitleActionTypes.TypeOfTitleRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case TypeOfTitleActionTypes.TypeOfTitleDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case TypeOfTitleActionTypes.TypeOfTitleAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case TypeOfTitleActionTypes.TypeOfTitleDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case TypeOfTitleActionTypes.TypeOfTitleTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
