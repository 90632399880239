import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { selectValuationFullComparables } from 'src/app/core/valuation-process';
import { AddComparableToValuationProcess, RemoveComparableFromValuationProcess } from 'src/app/core/valuation-process/_actions';
import { selectValuationComparableFullLoadingState, selectValuationProcessSelectedComparables } from 'src/app/core/valuation-process/_selectors';

export type ValuationComparableMapInfoDialogData = {
  currentUser?: any,
  refNums: string[],
}

@Component({
  selector: 'kt-valuation-comparable-map-info-dialog',
  templateUrl: './valuation-comparable-map-info-dialog.component.html',
  styleUrls: ['./valuation-comparable-map-info-dialog.component.scss']
})
export class ValuationComparableMapInfoDialogComponent implements OnInit {

  loading$ = this.store$.select(selectValuationComparableFullLoadingState)
  comparables$ = this.store$.pipe(
    select(selectValuationFullComparables(this.data.refNums)),
    filter(comparables => comparables.filter(c => c != undefined).length == this.data.refNums.length)
  )
  refNums$ = this.store$.select(selectValuationProcessSelectedComparables)

  constructor(
    public dialogRef: MatDialogRef<ValuationComparableMapInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ValuationComparableMapInfoDialogData,
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onAddComparable(event: {action: 'remove' | 'add', refNum: string}) {
    if (event.action == 'remove') {
      this.store$.dispatch(new RemoveComparableFromValuationProcess({refNum: event.refNum}))
      this.dialogRef.close()
      return
    }
    this.store$.dispatch(new AddComparableToValuationProcess({refNum: event.refNum}))
    this.dialogRef.close()
  }

  onOpenInfoModal(refNum: string) {
    this.dialogRef.close(refNum)
  }
}
