import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AddMemberDialogComponent } from "./add-member-dialog.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  declarations: [
    AddMemberDialogComponent,
  ],
  exports: [
    AddMemberDialogComponent
  ]
})
export class AddMemberDialogModule {}