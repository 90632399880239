import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LayoutUtilsService} from '../../../../../core/_base/crud';
import {ClientContactModel} from '../../../../../core/admin';
import {AppState} from '../../../../../core/reducers';
import {SupportValuerEditDialogComponent} from '../support-valuer-edit/support-valuer-edit.dialog.component';
import {SupportingValuerModel} from '../../../../../core/toe/_models/supporting-valuer.model';
import {BehaviorSubject, Subscription, Observable} from 'rxjs';
import {ToeService} from '../../../../../core/toe';
import { TranslateService } from '@ngx-translate/core';
import {
    AllValuerQualificationsRequested,
    ValuerQualification,
    selectAllValuerQualifications,
} from '../../../../../core/linked-tables';
import { SupportValuerTemplateListModalComponent } from '../support-valuer-template-list-modal/support-valuer-template-list-modal.component';
import { takeUntil } from 'rxjs/operators';
import { TeamTemplateModel } from 'src/app/core/template';
import { TeamTemplateModalComponent } from '../../../template/templates/team-template/_sub/team-template-modal/team-template-modal.component';

@Component({
    selector: 'kt-support-valuer-list',
    templateUrl: './support-valuer-list.component.html',
    styleUrls: ['./support-valuer-list.component.scss']
})
export class SupportValuerListComponent implements OnInit, OnDestroy, AfterViewInit {


    displayedColumns = ['worker', 'worker.qualification_id', 'lead_valuer', 'task', 'actions'];
    valuerData: SupportingValuerModel[] = [];
    dataSource = new MatTableDataSource(this.valuerData);
    valuerQualifications: ValuerQualification[] = [];
    isLV = false;
    isPM = false;

    @Input() isPMSubject: BehaviorSubject<boolean>;
    @Input() isLVSubject: BehaviorSubject<boolean>;
    @Input() valuersSubject: BehaviorSubject<SupportingValuerModel[]>;
    @Input() toeId: number;
    @Input() isTemplate: boolean = false;
    @ViewChild(MatSort) matSort: MatSort;
    @Input() readonly: boolean;

    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    constructor(private store: Store<AppState>,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public toeService: ToeService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.valuerData = this.valuersSubject.value;
        this.dataSource.data = this.valuerData;

        const lvSubsription = this.isLVSubject.subscribe(res => this.isLV = res);
        this.subscriptions.push(lvSubsription);
        const pmSubscription = this.isPMSubject.subscribe(res => this.isPM = res);
        this.subscriptions.push(pmSubscription);

        this.store.dispatch(new AllValuerQualificationsRequested());
        const valuerQualificationsSubscribe = this.store.pipe(
            // distinctUntilChanged(),
            select(selectAllValuerQualifications))
            .subscribe(res => {
                this.valuerQualifications = [];
                if (res) {
                    this.valuerQualifications = res;
                }
            });
        this.subscriptions.push(valuerQualificationsSubscribe);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    public reload() {
        this.valuerData = this.valuersSubject.value;
        this.dataSource.data = this.valuerData;
    }

    addValuer() {
        const newValuer = new ClientContactModel();
        newValuer.clear(); // Set all defaults fields
        this.editValuer(newValuer);
    }

    editValuer(contact: ClientContactModel) {
        const dialogRef = this.dialog.open(SupportValuerEditDialogComponent, {
            data: {
                contactId: contact.id,
                teamMembers: this.valuersSubject,
            },
            width: '440px',
            position: {
                top: '50px',
                right: '50px'
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const valuer = res.worker;
            valuer.terms_of_engagement_id = this.toeId;
            this.valuerData = Object.assign([], this.valuerData);
            this.valuerData.push(valuer);
            if (valuer.lead_valuer) {
                this.valuerData = this.selectOnlyOne(this.valuerData, valuer);
            }
            this.valuerData = this._sortValuers(this.valuerData);
            this.valuersSubject.next(this.valuerData);
            this.dataSource.data = this.valuerData;
        });
    }

    deleteValuer(valuer: SupportingValuerModel, i: number) {
        const _title = 'Supporting Valuer';
        const _description = 'Would you like to remove this valuer as a team member?';
        const _waitDesciption = 'Valuer is deleting...';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.valuerData.forEach((element, index) => {
                if (valuer.id != undefined && valuer.id == element.id) {
                    this.valuerData.splice(index, 1);
                    this.valuerData = this._sortValuers(this.valuerData);
                    this.valuersSubject.next(this.valuerData);
                    this.dataSource.data = this.valuerData;
                }
            });

            if (valuer.id == undefined) {
                this.valuerData.splice(i, 1);
                this.valuerData = this._sortValuers(this.valuerData);
                this.valuersSubject.next(this.valuerData);
                this.dataSource.data = this.valuerData;
            }

            if (valuer.lead_valuer == 1) {
                let newLeadValuer = this.valuerData.find(item => item.worker.qualification_id === 1);
                if (newLeadValuer) {
                    this.valuerData = this.selectOnlyOne(this.valuerData, newLeadValuer);
                    this.valuerData = this._sortValuers(this.valuerData);
                    this.valuersSubject.next(this.valuerData);
                    this.dataSource.data = this.valuerData;
                }
            }
        });
    }

    getValuerQualificationName(id): string {
        if (!id) {
            return '(N/A)';
        }
        if (this.valuerQualifications.length > 0) {
            return this.valuerQualifications.find(el => el.id == id).name;
        } else {
            return 'N/A';
        }
    }

    onchangeLeadValuer($ev, obj: SupportingValuerModel) {
        this.valuerData = this.selectOnlyOne(this.valuerData, obj);
        this.valuerData = this._sortValuers(this.valuerData);
        this.valuersSubject.next(this.valuerData);
        this.dataSource = new MatTableDataSource(this.valuerData);
    }

    selectOnlyOne(valuerData: SupportingValuerModel[], selectedObject: SupportingValuerModel): SupportingValuerModel[] {
        return valuerData.map((obj) => {
            if (obj === selectedObject) {
                const editedObj = Object.assign({}, obj);
                editedObj.lead_valuer = 1;
                return editedObj;
            }
            const editedObj = Object.assign({}, obj);
            editedObj.lead_valuer = 0;
            return editedObj;
        });
    }

    private _sortValuers(valuerData: SupportingValuerModel[]): SupportingValuerModel[] {
        const _temp: SupportingValuerModel[] = [];
        valuerData.forEach(vd => _temp.push(vd));
        return _temp.sort((a, b) => b.lead_valuer - a.lead_valuer);
    }

    public _addValuersFromTemplate() {
        const dialogRef = this.dialog.open(SupportValuerTemplateListModalComponent);
        const sub = dialogRef.afterClosed().subscribe(
            res => {
                if (!res) {
                    return;
                }
                switch (res.type) {
                    case 'select':
                        this.valuerData = res.template.members.map(member => {
                            const valuer = new SupportingValuerModel();
                            valuer.clear();
                            valuer.worker_id = member.worker_id;
                            valuer.worker = member.worker;
                            valuer.terms_of_engagement_id = this.toeId;
                            valuer.lead_valuer = member.lead_valuer;
                            // valuer.assigned_default_task_id = member.assigned_default_task_id;
                            // valuer.assigned_default_task_name = member.assigned_default_task_name;
                            return valuer;
                        });
                        this.valuerData = this._sortValuers(this.valuerData);
                        this.valuersSubject.next(this.valuerData);
                        this.dataSource.data = this.valuerData;
                        break;
                    case 'view':
                        this._viewTemplate(res.template);
                        break;
                    default:
                        break;
                }
            }
        );
        this.subscriptions.push(sub);
    }
    private _viewTemplate(template: TeamTemplateModel) {
        this.dialog.open(TeamTemplateModalComponent, {
            data: {
                template
            }
        });
    }
}
