// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import * as fromBuildings from '../_reducers/building.reducers';
import {BuildingModel} from '../_models/building.model';

export const selectBuildingsState = createFeatureSelector<fromBuildings.BuildingsState>('buildings');

export const selectBuildingById = (buildingId: number) => createSelector(
    selectBuildingsState,
    cs => cs.entities[buildingId]
);

export const selectAllBuildings = createSelector(
    selectBuildingsState,
    fromBuildings.selectAll
);

export const selectActionLoading = createSelector(
    selectBuildingsState,
    _state => _state.actionsLoading
);

export const selectBuildingsPageLoading = createSelector(
    selectBuildingsState,
    _state => _state.listLoading
);


export const selectLastCreatedBuildingId = createSelector(
    selectBuildingsState,
    (_state) => _state.lastCreatedBuildingId
);

export const selectTrashedBuildingCount = createSelector(
    selectBuildingsState,
    (_state) => _state.totalTrashed
)

export const selectAdminTrashedBuildingCount = createSelector(
    selectBuildingsState,
    (_state) => _state.totalAdminTrashed
);

export const selectQueryResult = createSelector(
    selectBuildingsState,
    _state => {
        const items: BuildingModel[] = [];
        each(_state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: BuildingModel[] = [];
        switch (_state.lastQuery.sortField) {
            case 'building_name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            case 'city_of_location':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'state_repair_id':
                result = httpExtension
                    .sortArrayNumber(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'building_grade_id':
                result = httpExtension
                    .sortArrayNumber(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'building_type_name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'total_floors':
                result = httpExtension
                    .sortArrayNumber(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'linked_comparable':
                result = httpExtension
                    .sortArrayNumber(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension
                    .sortArray(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);

        }

        if (_state.lastQuery.filter && _state.lastQuery.filter.max_lng && _state.lastQuery.filter.max_lng > 0) {
            result = result.filter(el => {
                return el.locationData.longitude > _state.lastQuery.filter.min_lng &&
                    el.locationData.longitude < _state.lastQuery.filter.max_lng &&
                    el.locationData.latitude > _state.lastQuery.filter.min_lat &&
                    el.locationData.latitude < _state.lastQuery.filter.max_lat;
            });
        }

        return new QueryResultsModel(result, _state.totalCount, '', _state.totalTrashed);
    }
);

export const selectBuildingsShowInitWaitingMessage = createSelector(
    selectBuildingsState,
    _state => _state.showInitWaitingMessage
);

