<kt-portlet>
  <kt-portlet-header
    [sticky]="true" [class]="'kt-portlet-head--lg'"
    title="Report Design">
    <ng-container ktPortletTools>
      <a [routerLink]="['../../dashboard']" mat-raised-button matTooltip="Back to Toe dashboard">
        <mat-icon>arrow_back</mat-icon>
        <span>Close</span>
      </a>
      <button class="btn btn-success mr-2" color="warn" style="background-color: orange !important" mat-raised-button (click)="onSubmit(false)">
        <span>Save Draft</span>
      </button>
      <button class="btn btn-success mr-2" color="submit" mat-raised-button (click)="onSubmit(true)">
        <span>Save</span>
      </button>
    </ng-container> 
  </kt-portlet-header>
  <kt-portlet-body>
    <div *ngIf="reporting">
      <kt-design-layout-v2
        [readonly]="false"
        [targetProperty]="tp"
        [reporting]="reporting"
        [selectedTpPics]="reporting.tp_selected_pictures"
      ></kt-design-layout-v2>
    </div>
  </kt-portlet-body>
</kt-portlet>