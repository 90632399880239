import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {NotificationModel} from '../_models/notification.model';


export enum NotificationActionTypes {
    NotificationActionToggleLoading = '[Notification] Action ActionToggleLoading',
    NotificationClear = '[Notification] Action Clear',
    NotificationAdded = '[Notification] Action Added',
    NotificationDeleted = '[Notification] Action Deleted',
    NotificationUpdated = '[Notification] Action Updated',
    NotificationRequested = '[Request Notification] Action',
    NotificationLoaded = '[Load Notification] Notification API'
}

export class NotificationAdded implements Action {
    readonly type = NotificationActionTypes.NotificationAdded;

    constructor(public payload: {
        item: NotificationModel
    }) {
    }
}

export class NotificationActionToggleLoading implements Action {
    readonly type = NotificationActionTypes.NotificationActionToggleLoading;

    constructor(public payload: {
        isLoading: boolean
    }) {
    }
}

export class NotificationClear implements Action {
    readonly type = NotificationActionTypes.NotificationClear;
}

export class NotificationUpdated implements Action {
    readonly type = NotificationActionTypes.NotificationUpdated;

    constructor(public payload: {
        partialItem: Update<NotificationModel>,
        item: NotificationModel
    }) {
    }
}

export class NotificationDeleted implements Action {
    readonly type = NotificationActionTypes.NotificationDeleted;

    constructor(public payload: {
        id: number
    }) {
    }
}

export class NotificationRequested implements Action {
    readonly type = NotificationActionTypes.NotificationRequested;

    constructor(public payload: { userId: number }) {
    }
}

export class NotificationLoaded implements Action {
    readonly type = NotificationActionTypes.NotificationLoaded;

    constructor(public payload: { items: NotificationModel[] }) {
    }
}


export type NotificationActions =
    NotificationActionToggleLoading
    | NotificationClear
    | NotificationAdded
    | NotificationUpdated
    | NotificationDeleted
    | NotificationRequested
    | NotificationLoaded;
