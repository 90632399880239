<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{ 'IMAGE_VIEWER.TITLE' | translate }} {{(currentFile | async) ? '(' + (currentFile|async).name + ')' : ''}}
            </h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div  *ngIf="currentFile | async as file" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <button *ngIf="hasLeft$|async" mat-icon-button (click)="moveLeft()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-dialog-content style="margin: 0;">
                    <div>
                        <div *ngIf="file.type != 'pdf'" class="container portrait">
                            <img [src]="file.path" (error)="imgOnError($event)">
                        </div>
                        <pdf-viewer *ngIf="file.type == 'pdf'" [src]="file.path"
                            [original-size]="true"
                            [fit-to-page]="true"
                            [show-all]="true"
                            style="display: block;"
                            (on-progress)="pdfLoadingProgress($event)"
                            (error)="onError($event)"
                        >
                        </pdf-viewer>
                    </div>
                </mat-dialog-content>
                <button *ngIf="hasRight$|async" mat-icon-button (click)="moveRight()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" [mat-dialog-close]="1" cdkFocusInitial>
                    {{ 'IMAGE_VIEWER.BUTTON.CLOSE.LABEL' | translate }}
                </button>&nbsp;         
            </div>
        </div>
    </div>
</div>
