import { TpFileModel } from "../../asset_class";

export class ValuationSRERModel {
    id: number;
    frontId: number;

    refId: number;
    externalRef: string;
    initialAvailability: boolean;
    isProvided: boolean;
    providedCount: number;
    tpFiles: TpFileModel[]

    defaultAssumption: SRERAssumptionModel
    specialAssumption: SRERAssumptionModel;

    constructor(frontId: number) {
        this.frontId = frontId;
        this.defaultAssumption = new SRERAssumptionModel(SRERAssumptionType.Default);
        this.specialAssumption = new SRERAssumptionModel(SRERAssumptionType.Special);
        this.tpFiles = [];
    }
}

export function copyValuationSRERModel(other: ValuationSRERModel): ValuationSRERModel {
    const _new = Object.assign({}, other) as ValuationSRERModel;
    return _new;
}

export class SRERAssumptionModel {
    type: SRERAssumptionType;
    input: string;
    status: SRERStatus;
    justification: string;

    constructor(type: SRERAssumptionType) {
        this.type = type;
        this.status = SRERStatus.Valid;
    }
}

export enum SRERAssumptionType {
    Default = 1,
    Special = 2
}

export function getSRERAssumption(type: SRERAssumptionType): string {
    switch (type) {
        case SRERAssumptionType.Default:
            return 'Assumption'
        case SRERAssumptionType.Special:
            return 'Special Assumption'
    }
}

export enum SRERStatus {
    Valid = 1,
    Amended = 2,
    Removed = 3
}