import { G } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { IpmsComponent } from 'src/app/core/linked-tables/_models/ipms-component.model';
import { IpmsComponentsService } from 'src/app/core/linked-tables/_services/ipms-component.service';
import { FloorAction } from '../models/floor-action.model';
import { IPMSSizeInfo, IPMSSizeInfoFloorSpecific } from '../models/ipms-residential.model';
import { SizeService } from '../size.service';

@Component({
  selector: 'kt-ipms-table',
  templateUrl: './ipms-table.component.html',
  styleUrls: ['./ipms-table.component.scss']
})
export class IpmsTableComponent implements OnInit, OnDestroy {
  @Input() assetClassID: number;
  @Input() standardMeasurement: StandardMeasurement;
  @Input() unitAreaMeasurement$: Observable<UnitAreaMeasurement>;
  @Input() floorAction$: Observable<FloorAction|null>
  @Input() readonly: boolean;

  private _onDestroy$: Subject<void> = new Subject();

  sizeInfo: IPMSSizeInfo = null;
  sizeInfoLoaded$ = new BehaviorSubject<boolean>(false);
  components: {
    key: string,
    name: string,
    description: string
  }[] = [];
  ipms3a = [];
  ipms3b = ['B1'];
  ipms3c = ['B1', 'B2', 'B3'];
  ipmsOff = ['A1', 'A2', 'B1', 'B2', 'C', 'D'];

  constructor(
    private sizeService: SizeService, 
    private componentService: IpmsComponentsService, 
    private cdr: ChangeDetectorRef) { 
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this.unitAreaMeasurement$.pipe(
      takeUntil(this._onDestroy$),
      switchMap(unitAreaMeasurement => {
        return this.sizeService.getIPMSResidentialSizeInfo(this.assetClassID, this.standardMeasurement.id, unitAreaMeasurement.id);
      })
    ).subscribe(res => {
      if (this.sizeInfo) {
        this.sizeInfo.unit_of_area_measurement = res.unit_of_area_measurement;
        return;
      }
      this.sizeInfo = res;
      this.sizeInfoLoaded$.next(true);
      this.cdr.detectChanges();
    })
    this.sizeInfoLoaded$.pipe(
      filter(isLoaded => isLoaded),
      switchMap(val => {
        return this.floorAction$
      }),
      takeUntil(this._onDestroy$)
    )
    .subscribe(action => {
      if (!action) {
        return;
      }
      switch (action.type) {
        case 'add': {
          const currentData = this._deepCopy(this.sizeInfo.data);
          action.floors.forEach(floor => {
            const indx = currentData.findIndex(item => item.floor == floor);
            if (indx > -1) {
              return;
            }
            currentData.push(this._createColumn(floor));
          })
          currentData.sort((a, b) => a.floor - b.floor);
          this.sizeInfo.data = currentData;
          break;
        }
        case 'remove': {
          const currentData = this._deepCopy(this.sizeInfo.data);
          action.floors.forEach(floor => {
            const indx = currentData.findIndex(item => item.floor == floor);
            if (indx == -1) {
              return;
            }
            currentData.splice(indx, 1);
          })
          currentData.sort((a, b) => a.floor - b.floor);
          this.sizeInfo.data = currentData;
          break;
        }
      }
    })

    this.componentService.getAllIpmsComponents().subscribe(res => {
      let keys = [];
      switch (this.standardMeasurement.id)
      {
        case 4:
          keys = this.ipms3a;
          break;
        case 5:
          keys = this.ipms3b;
          break;
        case 6:
          keys = this.ipms3c;
          break;
        case 8:
          keys = this.ipmsOff
          break;
      }
      this.components = res.data.filter(item => {
        const idx = keys.findIndex(k => item.key == k);
        return idx == -1
      }).map(item => {
        return {
          key: item.key.toLowerCase(),
          name: item.name,
          description: item.description
        }
      });
    })
  }

  seeSizeInfo() {}

  onFocusOut(event: FocusEvent, floor: number, component: string, type: string) {
    const target = event.target as HTMLInputElement;
    let val = Number(target.value);
    if (val < 0) {
      val = Math.abs(val);
    }
    this.updateValue(val, floor, component, type);
  }

  private updateValue(val: number, floor: number, component: string, type: string) {
    const currentData = this._deepCopy(this.sizeInfo.data);
    const floorIndex = currentData.findIndex(item => item.floor == floor);
    if (floorIndex == -1) {
      return;
    }
    const floorInfo = currentData[floorIndex];
    floorInfo[component][type] = val;
    currentData.splice(floorIndex, 1, floorInfo);
    currentData.sort((a, b) => a.floor - b.floor);
    this.sizeInfo.data = currentData;
  }

  private _deepCopy(data: IPMSSizeInfoFloorSpecific[]): IPMSSizeInfoFloorSpecific[] {
    const newData = [];
    data.forEach(item => {
      const newItem = Object.assign({}, item);
      newData.push(newItem);
    })
    return newData;
  }

  private _createColumn(floor: number): IPMSSizeInfoFloorSpecific {
    const item: IPMSSizeInfoFloorSpecific = {
      id: undefined,
      floor,
      a1_component: {
          total: 0,
          limited: 0,
      },
      a2_component: {
          total: 0,
          limited: 0,
      },
      b1_component: {
          total: 0,
          limited: 0,
      },
      b2_component: {
          total: 0,
          limited: 0,
      },
      b3_component: {
          total: 0,
          limited: 0,
      },
      c_component: {
          total: 0,
          limited: 0,
      },
      d_component: {
          total: 0,
          limited: 0,
      },
      e_component: {
          total: 0,
          limited: 0,
      },
      f_component: {
          total: 0,
          limited: 0,
      },
      g_component: {
          total: 0,
          limited: 0,
      },
      h_component: {
          total: 0,
          limited: 0,
      },
      additional: {
          patios: 0,
          unenclosed_parking_areas: 0,
          staircase: 0,
          enclosing_wall: 0,
          vertical_penetrations: 0,
      }
    };
    return item
  }

  public getData(): any {
    return {
      ...this.sizeInfo,
      standard_measurement_id: this.standardMeasurement.id
    };
  }

  public isValid(): boolean {
    for(let d of this.sizeInfo.data)
    {
      if (this.standardMeasurement.id != 8) {
        if (d.a1_component.total < d.a1_component.limited) {
          return false;
        }
        if (d.a2_component.total < d.a2_component.limited) {
          return false;
        }
        if (d.d_component.total < d.d_component.limited) {
          return false;
        }
        if (d.c_component.total < d.c_component.limited) {
          return false;
        }
      }

      if (this.standardMeasurement.id == 4) {
        if (d.b1_component.total < d.b1_component.limited) {
          return false;
        }
      }
      if (this.standardMeasurement.id == 4 || this.standardMeasurement.id == 5) {
        if (d.b2_component.total < d.b2_component.limited) {
          return false;
        }
        if (d.b3_component.total < d.b3_component.limited) {
          return false;
        }
      }

      if (d.e_component.total < d.e_component.limited) {
        return false;
      }
      if (d.f_component.total < d.f_component.limited) {
        return false;
      }
      if (d.g_component.total < d.g_component.limited) {
        return false;
      }
      if (d.h_component.total < d.h_component.limited) {
        return false;
      }
    }

    const total = computeTotal(this.sizeInfo.data, 'total', this.standardMeasurement.id);
    const limited = computeTotal(this.sizeInfo.data, 'limited', this.standardMeasurement.id);
    if (total <= limited) {
      return false;
    }

    return true;
  }
}

function computeTotal(values: IPMSSizeInfoFloorSpecific[], key: string, id: number): number {
    let total = 0;
    values.forEach(value => {
        total += value.a1_component[key]
        total += value.a2_component[key]
        if (id == 4) {
            total += value.b1_component[key]
        }
        if (id == 4 || id == 5 || id == 8) {
            total += value.b2_component[key]
            total += value.b3_component[key]
        }
        total += value.c_component[key]
        total += value.d_component[key]
        total += value.e_component[key]
        total += value.f_component[key]
        total += value.g_component[key]
        total += value.h_component[key]
    })
    return total
}
