// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
// Models
import {ToeInvoicePayment} from '../_models/toe-invoice-payment.model';
import {environment} from '../../../../environments/environment';
import {SupportingValuerModel} from '../_models/supporting-valuer.model';
import {
    AssetClassModel,
    PaymentModel,
    PaymentTaxModel,
    ValuationModel,
    PaymentTermModel,
} from '../../asset_class';
import {CoiDetailsTPModel} from '../_models/coi-details-tp.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class ToeInvoicePaymentService {
    API_TOE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-invoice-payments`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }


    // READ =>  GET: list of clients from the server
    findToeInvoicePayments(payload: any): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', payload.sortOrder)
            .set('sortField', payload.sortField)
            .set('filter[toe_id]', payload.filter.toe_id)
        return this.http.get<any>(this.API_TOE_URL, {
            headers: httpHeaders,
            params: params
        });
    }

    // CREATE =>  POST: add a new client to the server
    createToeInvoicePayment(body: {
        toe: ToeInvoicePayment,
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_TOE_URL, body, {headers: httpHeaders});
    }

    // UPDATE =>  PUT: update the client to the server
    updateToeInvoicePayment(payment: ToeInvoicePayment
    ): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_TOE_URL + '/' + payment.id, payment, {headers: httpHeaders});
    }

    // DELETE => delete the toe from the server
    deleteToeInvoicePayment(toeId: number): Observable<any> {
        const url = `${this.API_TOE_URL}/${toeId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url, {headers: httpHeaders});
    }
}
