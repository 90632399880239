import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllSourceExternalReferencesRequested, selectAllSourceExternalReferences, SourceExternalReference } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { AddExternalRefTemplate, ExternalRefTemplateModel, UpdateExternalRefTemplate } from 'src/app/core/template';
import { IExternalRefSERTEmplate } from 'src/app/core/template/_models/external-ref-template.model';

@Component({
  selector: 'kt-external-ref-template-body',
  templateUrl: './external-ref-template-body.component.html',
  styleUrls: ['./external-ref-template-body.component.scss']
})
export class ExternalRefTemplateBodyComponent implements OnInit, OnDestroy {
  @Input() template: ExternalRefTemplateModel;
  @Input() readonly: boolean;

  private _template: ExternalRefTemplateModel;
  formGroup: UntypedFormGroup;
  sourceExternalReferences: SourceExternalReference[] = [];
  private _mapFormArrayToData: number[] = [];

  private _onDestroy$: Subject<void> = new Subject();
  public sourceExternalRefs$: BehaviorSubject<{
    id: number,
    name: string,
    disabled: boolean
  }[]> = new BehaviorSubject([]);
  private _selectedSERS: number[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this._template = Object.assign({}, this.template);
    this._createForm();
    this._fetchData();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _createForm() {
    this.formGroup = this.fb.group({
      name: [this._template.name, Validators.required],
      description: [this._template.description, Validators.required],
      source_external_references: this._createSERFormArray(this._template.source_external_references)
    });
  }
  private _fetchData() {
    this.store.dispatch(new AllSourceExternalReferencesRequested());
    this.store.select(selectAllSourceExternalReferences).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.sourceExternalReferences = [];
      if (res) {
        this.sourceExternalReferences = res.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        this._updateSerObs();
      }
    })
  }
  private _prepareTemplate(): ExternalRefTemplateModel {
    const template = new ExternalRefTemplateModel();
    template.clear();
    const controls = this.formGroup.controls;
    template.id = this._template.id;
    template.name = controls.name.value;
    template.description = controls.description.value;
    template.source_external_references = this._getSERValue();
    return template;
  }


  get serFormArray(): UntypedFormArray {
    const formArray = this.formGroup.get('source_external_references') as UntypedFormArray;
    return formArray;
  }
  private _createSERFormArray(sers: IExternalRefSERTEmplate[]): UntypedFormArray {
    const formArray = this.fb.array([]);
    if (sers.length > 0) {
      sers.forEach((ser, i) => {
        const control = this.fb.control(ser.source_external_reference_id, Validators.required);
        formArray.push(control);
        this._mapFormArrayToData.push(ser.id);
        this._updateSelectedSER(ser.source_external_reference_id,i);
      })
    } else {
      const control = this.fb.control(undefined, Validators.required);
      formArray.push(control);
      this._mapFormArrayToData.push(null);
      this._selectedSERS.push(undefined);
    }
    return formArray;
  }
  private _getSERValue(): IExternalRefSERTEmplate[] {
    let vals: IExternalRefSERTEmplate[] = [];
    this.serFormArray.controls.forEach((control, i) => {
      let id = undefined;
      if (this._mapFormArrayToData[i]) {
        id = this._mapFormArrayToData[i];
      }
      vals.push({
        id: id,
        source_external_reference_id: control.value
      })
    })
    return vals
  }
  addSerField() {
    const control = this.fb.control(undefined, Validators.required);
    this.serFormArray.push(control);
    this._mapFormArrayToData.push(null);
  }
  removeSerField(indx: number) {
    this.serFormArray.removeAt(indx);
    this._mapFormArrayToData.splice(indx, 1);
    this._selectedSERS.splice(indx, 1);
    this._updateSerObs();
  }

  private _updateSelectedSER(val: number, index: number) {
    this._selectedSERS[index] = val;
    this._updateSerObs();
  }

  private _updateSerObs() {
    this.sourceExternalRefs$.next(this.sourceExternalReferences.map(ser => {
      const disabled = this._selectedSERS.find(iser => iser === ser.id);
      return {
        id: ser.id,
        name: ser.name,
        disabled: disabled ? true : false
      }
    }));
  }


  public onSubmit(complete: boolean): boolean {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      });
      return false;
    }
    const template = this._prepareTemplate();
    if (complete) {
      if (template.id === undefined) {
        this.store.dispatch(new AddExternalRefTemplate({
          template: template
        }));
      } else {
        this.store.dispatch(new UpdateExternalRefTemplate({
          id: template.id,
          template
        }));
      }
      return true;
    }
    return false
  }

  public onSelectionChange(event: MatSelectChange, indx: number) {
    this._updateSelectedSER(event.value, indx);
  }

}
