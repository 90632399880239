// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {MeasurementMethodologyActions, MeasurementMethodologyActionTypes} from '../_actions/measurement-methodology.actions';
// Models
import {MeasurementMethodology} from '../_models/measurement-methodology.model';
import {QueryParamsModel} from '../../_base/crud';

export interface MeasurementMethodologiesState extends EntityState<MeasurementMethodology> {
    isAllMeasurementMethodologiesLoaded: boolean;
    queryRowsCount: number;
    queryResult: MeasurementMethodology[];
    lastCreatedMeasurementMethodologyId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<MeasurementMethodology> = createEntityAdapter<MeasurementMethodology>();

export const initialMeasurementMethodologiesState: MeasurementMethodologiesState = adapter.getInitialState({
    isAllMeasurementMethodologiesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedMeasurementMethodologyId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function measurementMethodologiesReducer(state = initialMeasurementMethodologiesState, action: MeasurementMethodologyActions): MeasurementMethodologiesState {
    switch (action.type) {
        case MeasurementMethodologyActionTypes.MeasurementMethodologiesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMeasurementMethodologyId: undefined
            };
        case MeasurementMethodologyActionTypes.MeasurementMethodologiesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerCreated:
            return {
                ...state
            };
        case MeasurementMethodologyActionTypes.MeasurementMethodologyCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedMeasurementMethodologyId: action.payload.item.id
            });
        case MeasurementMethodologyActionTypes.MeasurementMethodologyUpdated:
            return adapter.updateOne(action.payload.partialItem, state);
        case MeasurementMethodologyActionTypes.AllMeasurementMethodologiesLoaded:
            return adapter.addAll(action.payload.measurementMethodologies, {
                ...state, isAllMeasurementMethodologiesLoaded: true
            });
        case MeasurementMethodologyActionTypes.MeasurementMethodologiesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case MeasurementMethodologyActionTypes.MeasurementMethodologiesPageLoaded:
            return adapter.addMany(action.payload.measurementMethodologies, {
                ...initialMeasurementMethodologiesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case MeasurementMethodologyActionTypes.MeasurementMethodologyDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case MeasurementMethodologyActionTypes.MeasurementMethodologyRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case MeasurementMethodologyActionTypes.MeasurementMethodologyDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case MeasurementMethodologyActionTypes.MeasurementMethodologyAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case MeasurementMethodologyActionTypes.MeasurementMethodologyDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case MeasurementMethodologyActionTypes.MeasurementMethodologyTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
