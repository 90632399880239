import {ConstructionType} from '../_models/construction-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ConstructionTypesState} from '../_reducers/construction-type.reducers';
import * as fromConstructionType from '../_reducers/construction-type.reducers';
import {each} from 'lodash';

export const selectConstructionTypesState = createFeatureSelector<ConstructionTypesState>('constructionTypes');

export const selectConstructionTypeById = (constructionTypeId: number) => createSelector(
    selectConstructionTypesState,
    constructionTypesState => constructionTypesState.entities[constructionTypeId]
);

export const selectAllConstructionTypes = createSelector(
    selectConstructionTypesState,
    fromConstructionType.selectAll
);

export const selectAllConstructionTypesIds = createSelector(
    selectConstructionTypesState,
    fromConstructionType.selectIds
);

export const allConstructionTypesLoaded = createSelector(
    selectConstructionTypesState,
    constructionTypesState => constructionTypesState.isAllConstructionTypesLoaded
);


export const selectConstructionTypesPageLoading = createSelector(
    selectConstructionTypesState,
    constructionTypesState => constructionTypesState.listLoading
);

export const selectConstructionTypesActionLoading = createSelector(
    selectConstructionTypesState,
    constructionTypesState => constructionTypesState.actionLoading
);

export const selectLastCreatedConstructionTypeId = createSelector(
    selectConstructionTypesState,
    constructionTypesState => constructionTypesState.lastCreatedConstructionTypeId
);

export const selectConstructionTypesShowInitWaitingMessage = createSelector(
    selectConstructionTypesState,
    constructionTypesState => constructionTypesState.showInitWaitingMessage
);

export const selectTrashedConstructionTypeCount = createSelector(
    selectConstructionTypesState,
    (constructionTypesState) => constructionTypesState.totalTrashed
);

export const selectAdminTrashedConstructionTypeCount = createSelector(
    selectConstructionTypesState,
    (constructionTypesState) => constructionTypesState.totalAdminTrashed
);


export const selectConstructionTypeQueryResult = createSelector(
    selectConstructionTypesState,
    constructionTypesState => {
        const items: ConstructionType[] = [];
        each(constructionTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ConstructionType[] = httpExtension.sortArray(items, constructionTypesState.lastQuery.sortField, constructionTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, constructionTypesState.totalCount, '', constructionTypesState.totalTrashed);
    }
);
