<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">Choose an Assignment</h3>
    </div>

    <div class="card-toolbar">
      <span [matTooltip]="'Save'">
        <button (click)="onSubmit()" class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px">
          Validate
        </button>
      </span>
      <button (click)="onClose()" class="btn btn-icon btn-sm btn-light-warning p-1">
        <i class="ki ki-close icon-nm"></i>
      </button>
    </div>
  </div>

  <div class="form" [formGroup]="formGroup">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select formControlName="assignment_id" placeholder="Assignment">
              <mat-option>
                <ngx-mat-select-search [formControl]="searchControl"
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Assignment">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="-1">
                <div class="add-entry">
                  <i class="ki ki-plus"></i> New Assignment 
                </div>
              </mat-option>
              <ng-container *ngFor="let option  of (assignments$|async)">
                <mat-option [value]="option.value">
                  {{option.label}}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error>
              Required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="showAssignmentForm$|async">
        <div class="row">
          <div class="col-6">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Name" formControlName="name" />
              <mat-error>Name is <strong>required</strong></mat-error>
            </mat-form-field>
          </div> 

          <div class="col-6">
            <mat-form-field class="mat-form-field-fluid">
              <span (click)="picker.open()">
              <input matInput [matDatepicker]="picker"
                [placeholder]="'ASSIGNMENT.FORM.FIELD.GENERAL.INSERTION_DATE.PLACEHOLDER' | translate"
                formControlName="date_of_creation"/>
              </span>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field class="mat-form-field-fluid">
              <mat-select formControlName="project_manager_id" placeholder="Project Manager">
                <ng-container *ngFor="let option of (users$|async)">
                  <mat-option [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error>Project Manager is <strong>required</strong></mat-error>
            </mat-form-field>
          </div> 

          <div class="col-6">
            <mat-form-field class="mat-form-field-fluid">
              <mat-select formControlName="agency_id" placeholder="Agency">
                <ng-container *ngFor="let option of (agencies$|async)">
                  <mat-option [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error>Agency is <strong>required</strong></mat-error>
            </mat-form-field>
          </div> 
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field class="mat-form-field-fluid">
              <textarea matInput rows="4" formControlName="point_of_contact" placeholder="Point of Contact"></textarea>
              <mat-icon
                      matSuffix
                      class="cursor-pointer"
                      container="body"
                      (click)="$event.stopPropagation();"
                      [ngbPopover]="'TOOLTIP.ASSIGNMENT.FORM_FIELD.POINT_OF_CONTACT.DESCRIPTION' | translate"
                      [popoverTitle]="'TOOLTIP.ASSIGNMENT.FORM_FIELD.POINT_OF_CONTACT.TITLE' | translate">help
              </mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field class="mat-form-field-fluid">
              <textarea matInput rows="4" formControlName="description" placeholder="Description"></textarea>
              <mat-icon
                      matSuffix
                      class="cursor-pointer"
                      container="body"
                      (click)="$event.stopPropagation();"
                      [ngbPopover]="'TOOLTIP.ASSIGNMENT.FORM_FIELD.DESCRIPTION.DESCRIPTION' | translate"
                      [popoverTitle]="'TOOLTIP.ASSIGNMENT.FORM_FIELD.DESCRIPTION.TITLE' | translate">help
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>