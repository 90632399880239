import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { size, zip, zipWith } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResidentialExternalAreaModel } from 'src/app/core/comparable';
import { ExternalArea, StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { ConditionRatingV2Service } from '../../../target-property/services/condition-rating-v2.service';
import { MSFloorInfo, MSResidential, MSSizeInfo } from '../models/mongolian-standard-residential.model';
import { SizeService } from '../size.service';
import { Rooms } from './mongolia-standard-table.types';
import * as _ from 'lodash';
import { LatestComparablesComponent } from '../../../dashboard/components/latest-comparables/latest-comparables.component';
import { FloorAction } from '../../sizes-module/sizes-module.types';

@Component({
  selector: 'kt-mongolia-standard-table',
  templateUrl: './mongolia-standard-table.component.html',
  styleUrls: ['./mongolia-standard-table.component.scss']
})
export class MongoliaStandardTableComponent implements OnInit, OnDestroy {
  @Input() assetClassID: number;
  @Input() standardMeasurement: number;
  @Input() unitAreaMeasurement$: Observable<UnitAreaMeasurement>;
  @Input() floorAction$: Observable<FloorAction|null>
  @Input() bedrooms$: Observable<number>;
  @Input() externalAreas$: Observable<ResidentialExternalAreaModel[]>;
  @Input() readonly: boolean;
  @Input() roomChange$: Observable<Rooms>;
  sizeInfo: MSResidential;
  bedrooms: number;
  firstTime: boolean = true;
  schemeId: number;
  sizeInfoLoaded$ = new BehaviorSubject<boolean>(false);
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private sizeService: SizeService,
    private cdr: ChangeDetectorRef,
    private crV2Service: ConditionRatingV2Service
  ) { }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    combineLatest([
      this.roomChange$,
      this.externalAreas$,
      this.unitAreaMeasurement$,
    ]).pipe(
      switchMap(([rooms, externalAreas, unitAreaMeasurement]) => {
        return this.sizeService
          .getMSResidentialSizeInfo(this.assetClassID, this.standardMeasurement, unitAreaMeasurement.id, rooms, externalAreas ? externalAreas.filter(ea => ea.name != 'None').map(ea => ea.id) : [], this.firstTime)
          .pipe(takeUntil(this._onDestroy$));
      })
    ).subscribe(res => {
      this.firstTime = false;
      this.sizeInfo = this._compare(res, this.sizeInfo);
      this.sizeInfo = this.convert(this.sizeInfo);
      this.crV2Service.setMSComponents(this.sizeInfo);
      this.sizeInfoLoaded$.next(true);
      this.cdr.detectChanges();
    });

    this.sizeInfoLoaded$.pipe(
      filter(isLoaded => isLoaded),
      switchMap(val => {
        return this.floorAction$
      }),
      takeUntil(this._onDestroy$)
    )
    .subscribe(action => {
      if (!action) {
        return;
      }
      this.schemeId = action.scheme_id;
      switch (action.type) {
        case 'add': {
          let currentInfo = _.cloneDeep(this.sizeInfo);
          action.floors.forEach(floor => {
            const indx = currentInfo.floors.findIndex(f => f == floor);
            if (indx == -1) {
              currentInfo = this._addFloor(currentInfo, floor);
            }
          });
          this.sizeInfo = currentInfo;
          break;
        }
        case 'remove': {
          let currentInfo = this._deepCopy(this.sizeInfo);
          action.floors.forEach(floor => {
            const indx = currentInfo.floors.findIndex(item => item == floor);
            if (indx > -1) {
              currentInfo = this._removeFloor(currentInfo, floor, indx);
            }
          })
          this.sizeInfo = currentInfo;
          break;
        }
      }
    })
  }

  private _deepCopy(sizeInfo: MSResidential): MSResidential {
    const floors = sizeInfo.floors.slice();
    const clone = (item: MSSizeInfo): MSSizeInfo => {
      const _f = Object.assign({}, item.floors)
      return {
        name: item.name,
        key: item.key,
        multiplier: item.multiplier,
        floors: _f
      }
    }
    const living_areas = sizeInfo.living_areas.map(clone)
    const standard_facilities = sizeInfo.standard_facilities.map(clone)
    const external_areas = sizeInfo.external_areas.map(clone)
    return {
      unit_of_area_measurement: sizeInfo.unit_of_area_measurement,
      floors,
      living_areas,
      standard_facilities,
      external_areas 
    }
  }

  private _addFloor(_info: MSResidential, floor: number): MSResidential {
    const clone = (item: MSSizeInfo): MSSizeInfo => {
      let f = _.cloneDeep(item.floors);
      f[floor] = {
        size: 0
      }
      return {
        ...item,
        floors: f 
      }
    }
    const info = this._deepCopy(_info);
    const info1 = this._deepCopy(_info);
    info.floors.push(floor);
    info.floors.sort();
    info.living_areas = info1.living_areas.map(clone);
    info.standard_facilities = info1.standard_facilities.map(clone);
    info.external_areas = info1.external_areas.map(clone)
    return info;
  }

  private _removeFloor(_info: MSResidential, floor: number, indx: number): MSResidential {
    let info = this._deepCopy(_info);
    info.floors.splice(indx, 1);
    info.floors.sort();
    info.living_areas.forEach(la => {
      delete la.floors[floor];
    });
    info.standard_facilities.forEach(sf => {
      delete sf.floors[floor];
    })
    info.external_areas.forEach(ea => {
      delete ea.floors[floor];
    });
    return info;
  }

  private _compare(_newSize: MSResidential, _oldSize: MSResidential): MSResidential {
    let newSize = _.cloneDeep(_newSize);
    if (_oldSize == null) {
      return newSize;
    }
    let oldSize = _.cloneDeep(_oldSize);
    let unit_of_area_measurement: string = newSize.unit_of_area_measurement;

    // Living areas
    const oldlivingrooms = oldSize.living_areas.filter(la => la.name.includes('Living room')).slice();
    const oldbedrooms = oldSize.living_areas.filter(la => la.name.includes('Bedroom')).slice();
    const newlivingrooms = newSize.living_areas.filter(la => la.name.includes('Living room')).slice();
    const newbedrooms = newSize.living_areas.filter(la => la.name.includes('Bedroom')).slice();
    const la_of = oldSize.living_areas.reverse().shift();
    const livingrooms = [];
    const bedrooms: MSSizeInfo[] = [];
    for (let i = 0; i < newlivingrooms.length; i++) {
      if (oldlivingrooms[i]) {
        livingrooms.push(oldlivingrooms[i])
      } else {
        livingrooms.push(newlivingrooms[i])
      }
    }
    for (let i = 0; i < newbedrooms.length; i++) {
      if (oldbedrooms[i]) {
        bedrooms.push(oldbedrooms[i])
      } else {
        bedrooms.push(newbedrooms[i])
      }
    }

    // 
    const oldkitchens = oldSize.standard_facilities.filter(la => la.name.includes('Kitchen')).slice();
    const newkitchens = newSize.standard_facilities.filter(la => la.name.includes('Kitchen')).slice();
    const oldtoilets = oldSize.standard_facilities.filter(la => la.name.includes('Toilet')).slice();
    const newtoilets = newSize.standard_facilities.filter(la => la.name.includes('Toilet')).slice();
    const oldbaths = oldSize.standard_facilities.filter(la => la.name.includes('Bathroom')).slice();
    const newbaths = newSize.standard_facilities.filter(la => la.name.includes('Bathroom')).slice();
    const bar = oldSize.standard_facilities.filter(la => la.name.includes("Bar/food area")).slice();
    const closet = oldSize.standard_facilities.filter(la => la.name.includes("Closet/storage room")).slice();
    const corridor = oldSize.standard_facilities.filter(la => la.name.includes("Corridor")).slice();
    const kitchens = [];
    for (let i = 0; i < newkitchens.length; i++) {
      if (oldkitchens[i]) {
        kitchens.push(oldkitchens[i])
      } else {
        kitchens.push(newkitchens[i])
      }
    }
    const toilets = [];
    for (let i = 0; i < newtoilets.length; i++) {
      if (oldtoilets[i]) {
        toilets.push(oldtoilets[i])
      } else {
        toilets.push(newtoilets[i])
      }
    }
    const baths = [];
    for (let i = 0; i < newbaths.length; i++) {
      if (oldbaths[i]) {
        baths.push(oldbaths[i])
      } else {
        baths.push(newbaths[i])
      }
    }

    // External areas
    const external_areas: MSSizeInfo[] = [];
    newSize.external_areas.forEach(ea => {
      const indx = oldSize.external_areas.findIndex(item => item.key == ea.key);
      if (indx == -1) {
        external_areas.push(ea);
      } else {
        external_areas.push(oldSize.external_areas[indx]);
      }
    })

    return {
      unit_of_area_measurement,
      floors: newSize.floors,
      living_areas: _.concat(livingrooms, bedrooms, [la_of]),
      standard_facilities: _.concat(kitchens, bar, toilets, closet, corridor, baths),
      external_areas 
    }
  }

  onFocusOut(event: FocusEvent,type: string, name: string, floor: number) {
    const target = event.target as HTMLInputElement;
    let val = Number(target.value);
    if (val < 0) {
      val = Math.abs(val);
    }
    this._updateData(val, floor, type, name);
  }

  private _updateData(val: number, floor: number, type: string, name: string) {
    const itemIndx = this.sizeInfo[type].findIndex(arrItem => arrItem.name == name);
    if (itemIndx == -1) {
      return;
    }
    const item = this.sizeInfo[type][itemIndx];
    const newItem = Object.assign({}, item) 
    newItem.floors[floor].size = val;
    newItem.isEdited = true;
    this.sizeInfo[type].splice(itemIndx, 1, newItem);

    this.sizeInfo = Object.assign({}, this.sizeInfo);
  }

  public getData(): any {
    return {
      ...this.sizeInfo,
      standard_measurement_id: this.standardMeasurement
    }
  }

  public isValid(): boolean {
    let total = 0;
    const types = ['living_areas', 'standard_facilities', 'external_areas'];
    types.forEach(type => {
        this.sizeInfo[type].forEach((area: MSSizeInfo) => {
            Object.entries(area.floors).forEach(([_, info]) => {
              if (info != undefined) {
                total += (info.size * area.multiplier)
              }
            })
        })
    })
    if (total <= 0) {
      return false;
    }
    return true;
  }

  convert(sizeInfo: MSResidential): MSResidential {
    const m = _.cloneDeep(sizeInfo);
    m.living_areas = sizeInfo.living_areas.map(la => {
      return {
        ...la,
        floors: Array.isArray(la.floors) ? arrayToRecord(la.floors) : la.floors
      }
    })
    return m;
  }
}

function arrayToRecord<T>(arr: T[]): Record<number, T> {
  const r: Record<number, T> = {};
  arr.forEach((item, i) => {
    r[i] = item;
  })
  return r;
}
