// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassesState} from '../_reducers/asset-class.reducers';
import * as fromAssetClass from '../_reducers/asset-class.reducers';
import {AssetClassModel} from '../_models/asset-class.model';

export const selectAssetClassesState = createFeatureSelector<AssetClassesState>('assetClasses');

export const selectAssetClassById = (assetClassId: number) => createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.entities[assetClassId]
);

export const selectAssetClassesPageLoading = createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.listLoading
);

export const selectAssetClassesTotalTrashed = createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.totalTrashed
);

export const selectAssetClassesTotalAdminTrashed = createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.totalAdminTrashed
);

export const selectAssetClassesActionLoading = createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.actionLoading
);

export const selectLastCreatedAssetClassId = createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.lastCreatedAssetClassId
);

export const selectAssetClassesShowInitWaitingMessage = createSelector(
    selectAssetClassesState,
    assetClassesState => assetClassesState.showInitWaitingMessage
);

export const selectAssetClassesInStore = createSelector(
    selectAssetClassesState,
    assetClassesState => {
        const items: AssetClassModel[] = [];
        each(assetClassesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassModel[] = [];
        if (assetClassesState.lastQuery.sortField === 'updated_at') {
            result = httpExtension.sortArrayDateTime(
                items,
                assetClassesState.lastQuery.sortField,
                assetClassesState.lastQuery.sortOrder
            );
        } else {
            result = httpExtension.sortArray(
                items,
                assetClassesState.lastQuery.sortField,
                assetClassesState.lastQuery.sortOrder
            );
        }
        return new QueryResultsModel(result, assetClassesState.totalCount, '');
    }
);


export const selectAssetClassByToeId = (toeId: number) => createSelector(
    selectAssetClassesState,
    assetClassesState => {
        const items: AssetClassModel[] = [];
        each(assetClassesState.entities, element => {
            if (element.toe_id == toeId) {
                items.push(element);
            }
        });
        return items;
    }
);

export const selectAllAssetClasses = createSelector(
    selectAssetClassesState,
    fromAssetClass.selectAll
);
