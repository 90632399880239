// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ExternalAreasService} from '../_services/external-area.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allExternalAreasLoaded} from '../_selectors/external-area.selectors';
// Actions
import {
    AllExternalAreasLoaded,
    AllExternalAreasRequested,
    ExternalAreaActionTypes,
    ExternalAreasPageRequested,
    ExternalAreasPageLoaded,
    ExternalAreaUpdated,
    ExternalAreasPageToggleLoading,
    ExternalAreaDeleted,
    ExternalAreaOnServerCreated,
    ExternalAreaCreated,
    ExternalAreasActionToggleLoading,
    ExternalAreaTrashFlushed,
    ExternalAreaOnServerRestored,
    ExternalAreaRestored,
    ExternalAreaAdminRestored,
    ExternalAreaOnServerAdminRestored,
    ExternalAreaDeletedFromTrash,
    ExternalAreaDeletedFromAdminTrash
} from '../_actions/external-area.actions';

@Injectable()
export class ExternalAreaEffects {
    showPageLoadingDispatcher = new ExternalAreasPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ExternalAreasActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ExternalAreasActionToggleLoading({isLoading: false});

    @Effect()
    loadAllExternalAreas$ = this.actions$
        .pipe(
            ofType<AllExternalAreasRequested>(ExternalAreaActionTypes.AllExternalAreasRequested),
            withLatestFrom(this.store.pipe(select(allExternalAreasLoaded))),
            filter(([action, isAllExternalAreasLoaded]) => !isAllExternalAreasLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllExternalAreasLoaded({externalAreas: res.data});
            })
        );

    @Effect()
    loadExternalAreasPage$ = this.actions$
        .pipe(
            ofType<ExternalAreasPageRequested>(ExternalAreaActionTypes.ExternalAreasPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ExternalAreasPageLoaded({
                    externalAreas: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaUpdated>(ExternalAreaActionTypes.ExternalAreaUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.externalArea);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaOnServerCreated>(ExternalAreaActionTypes.ExternalAreaOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.externalArea).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalAreaCreated({externalArea: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushExternalAreaTrash$ = this.actions$
        .pipe(
            ofType<ExternalAreaTrashFlushed>(ExternalAreaActionTypes.ExternalAreaTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaOnServerRestored>(ExternalAreaActionTypes.ExternalAreaOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalAreaRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaOnServerAdminRestored>(ExternalAreaActionTypes.ExternalAreaOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalAreaAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaDeleted>(ExternalAreaActionTypes.ExternalAreaDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaDeletedFromTrash>(ExternalAreaActionTypes.ExternalAreaDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashExternalArea$ = this.actions$
        .pipe(
            ofType<ExternalAreaDeletedFromAdminTrash>(ExternalAreaActionTypes.ExternalAreaDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ExternalAreasService, private store: Store<AppState>) {
    }
}
