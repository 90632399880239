import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationAdjustmentActions, ValuationAdjustmentActionTypes } from "../_actions/valuation-adjustment.actions";
import { ValuationAdjustmentModel } from "../_models/valuation-adjustment.model";

export interface ValuationAdjustmentsState extends EntityState<ValuationAdjustmentModel> {
    lastCreatedID: number
}
export const adapter: EntityAdapter<ValuationAdjustmentModel> = createEntityAdapter();
export const initialValuationAdjustmentsState: ValuationAdjustmentsState = adapter.getInitialState({
    lastCreatedID: 0,
});

export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();

export function valuationAdjustmentReducer(
    state = initialValuationAdjustmentsState,
    action: ValuationAdjustmentActions
): ValuationAdjustmentsState {
    switch (action.type) {
        case ValuationAdjustmentActionTypes.ValuationAdjustmentCreated: {
            return adapter.addOne(action.payload.valuationAdjustment, {...state, lastCreatedID: action.payload.valuationAdjustment.id})
        }
        case ValuationAdjustmentActionTypes.ValuationAdjustmentEdited: {
            return adapter.updateOne(action.payload.valuationAdjustment, {
                ...state
            })
        }
        case ValuationAdjustmentActionTypes.ValueOfValuationAdjustmentEdited: {
            return adapter.updateOne(action.payload.valuationAdjustment, {
                ...state
            })
        }
        case ValuationAdjustmentActionTypes.ComparableAdded: {
            let id = 0;
            if (action.payload.valuationAdjustments.length > 0) {
                const val =action.payload.valuationAdjustments[action.payload.valuationAdjustments.length - 1].id;
                id = val; 
            }
            return adapter.setAll(action.payload.valuationAdjustments, {
                ...state,
                lastCreatedID: id 
            })
        }
        case ValuationAdjustmentActionTypes.ComparableRemoved: {
            return adapter.setAll(action.payload.valuationAdjustments, {
                ...state
            })
        }
        case ValuationAdjustmentActionTypes.RemoveValuationAdjustment: {
            return adapter.removeOne(action.payload.id, {...state})
        }
        case ValuationAdjustmentActionTypes.RestartValuationAdjustmentState: {
            return initialValuationAdjustmentsState
        }
        case ValuationAdjustmentActionTypes.CriterionFromAdjustmentRemoved: {
            return adapter.updateMany(action.payload.adjustments, {...state});
        }
        case ValuationAdjustmentActionTypes.ValuationAdjustmentsCreated: {
            return adapter.addMany(action.payload.models, {...state, lastCreatedID: action.payload.lastId})
        }
        default: 
            return state;
    }
}
