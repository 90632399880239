import {BaseModel} from '../../_base/crud';

export class CurrencyModel extends BaseModel {
    id: number;
    name: string;
    symbol: string;
    acronym: string;
    rate: number;
    country_cnt: number;

    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    deletedBy: string;
    userDeletedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.symbol = '';
        this.acronym = '';
        this.rate = 0;
        this.country_cnt = 0;

        this.deletedBy = '';
        this.userDeletedBy = '';

        this.user_deleted = null;
        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
