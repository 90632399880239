<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet__head--lg'" [viewLoading$]="dataSource.loading$">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <!-- <h3 class="kt-portlet__head-title"> -->
                <span>{{ 'TOOLTIP.RENT_REVIEW_COMMENT.LIST.TITLE' | translate }}</span>
            <!-- </h3> -->
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <a routerLink="../"
               class="btn  mr-2"
               mat-raised-button matTooltip="Back to the Linked Table List">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
            </a>

            <button (click)="addRentReviewComment()" mat-raised-button matTooltip="Create a New Rent Review Comment" color="primary"
                    type="button">
                <span>{{ 'GENERAL.BUTTON.NEW' | translate }}</span>
            </button>
            <button *ngIf="trashCnt != 0" (click)="trash()" mat-raised-button matTooltip="Deleted Rent Review Comments" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button *ngIf="adminTrashCnt != 0" (click)="adminTrash()" mat-raised-button matTooltip="Deleted Rent Review Comments" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form">
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center active">

                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput placeholder="Search rentReviewComment" #searchInput placeholder="Search">

                    </mat-form-field>
                    <div class="col-md-1">
                        <button (click)="resetFilter()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>

                </div>
            </div>

            <!-- end::FILTERS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to dataSources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped" #table [dataSource]="dataSource" matSort #sort1="matSort"
                       matSortActive="id" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell *matCellDef="let rentReviewComment">{{rentReviewComment.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell *matCellDef="let rentReviewComment">{{rentReviewComment.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let rentReviewComment">
                        <button mat-icon-button color="primary" matTooltip="Edit Rent Review Comment" (click)="editRentReviewComment(rentReviewComment)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <button mat-icon-button color="warn" matTooltip="Delete Rent Review Comment" type="button"
                                (click)="deleteRentReviewComment(rentReviewComment)">
                            <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index" [ngClass]="{gray: index%2}">
                </mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
                           [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>