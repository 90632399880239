import {HttpEvent, HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, takeUntil} from 'rxjs/operators';
import {FileUploadService} from '../_services';
import * as fromFileUploadActions from '../_actions/file-upload.actions';

// const {serializeError, deserializeError} = require('serialize-error');


@Injectable()
export class UploadFileEffects {
    @Effect()
    uploadRequestEffect$: Observable<Action> = this.actions$.pipe(
        ofType(fromFileUploadActions.ActionTypes.UPLOAD_REQUEST),
        concatMap(action =>
            this.fileUploadService.uploadFile(action.payload.formData).pipe(
                takeUntil(
                    this.actions$.pipe(
                        ofType(fromFileUploadActions.ActionTypes.UPLOAD_CANCEL)
                    )
                ),
                map(event => this.getActionFromHttpEvent(event)),
                catchError(error => of(this.handleError(error)))
            )
        )
    );

    @Effect()
    uploadToTpRequestEffect$: Observable<Action> = this.actions$.pipe(
        ofType(fromFileUploadActions.ActionTypes.UPLOAD_TO_TP),
        concatMap(action =>
            this.fileUploadService.uploadFileToTP(action.payload.formData, action.payload.tpId).pipe(
                takeUntil(
                    this.actions$.pipe(
                        ofType(fromFileUploadActions.ActionTypes.UPLOAD_CANCEL)
                    )
                ),
                map(event => this.getActionFromHttpEvent(event)),
                catchError(error => of(this.handleError(error)))
            )
        )
    )

    @Effect()
    uploadEffect$: Observable<Action> = this.actions$.pipe(
        ofType(fromFileUploadActions.ActionTypes.UPLOAD),
        concatMap(action =>
            this.fileUploadService.uploadFiles(action.payload.formData).pipe(
                takeUntil(
                    this.actions$.pipe(
                        ofType(fromFileUploadActions.ActionTypes.UPLOAD_CANCEL)
                    )
                ),
                map(event => this.getActionFromHttpEvent(event)),
                catchError(error => of(this.handleError(error)))
            )
        )
    );

    constructor(private fileUploadService: FileUploadService,
                private actions$: Actions<fromFileUploadActions.Actions>) {
    }

    private getActionFromHttpEvent(event: HttpEvent<any>) {
        switch (event.type) {
            case HttpEventType.Sent: {
                return new fromFileUploadActions.UploadStartedAction();
            }
            case HttpEventType.UploadProgress: {
                return new fromFileUploadActions.UploadProgressAction({
                    progress: Math.round((100 * event.loaded) / event.total)
                });
            }
            case HttpEventType.ResponseHeader:
            case HttpEventType.Response: {
                if (event.status === 200) {
                    let response_body;
                    if (event.type == HttpEventType.Response) {
                        response_body = event.body;
                    }
                    return new fromFileUploadActions.UploadCompletedAction({
                        res: response_body
                    });
                } else {
                    if (event.status === 400) {

                        /*  400 Bad Request
                        The server cannot or will not process the request due to an
                        apparent client error (e.g., malformed request syntax, size too large,
                        invalid request message framing, or deceptive request routing)
                        */
                        return new fromFileUploadActions.UploadFailureAction({
                            error: `Bad Request, Please check your form data.`
                        });
                    }

                    if (event.status === 403) {

                        /*  403 Forbidden
                          The request contained valid data and was understood by the server, but the server is refusing action. This may be due to the user not having the necessary permissions for a resource or needing
                          an account of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is allowed). This code is also typically used if the request provided
                          authentication via the WWW-Authenticate header field, but the server did not accept that authentication. The request should not be repeated.
                        */

                        return new fromFileUploadActions.UploadFailureAction({
                            error: `The Server is refusing action, Please check your form data.`
                        });
                    }


                    if (event.status === 404) {

                        /*  404 Not Found
                            The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible.
                        */
                        return new fromFileUploadActions.UploadFailureAction({
                            error: `Not Found, API have not developed yet.`
                        });
                    }

                    if (event.status === 500) {

                        /*  500 Internal Server Error
                        The server cannot or will not process the request due to an
                        apparent client error (e.g., malformed request syntax, size too large,
                        invalid request message framing, or deceptive request routing)
                        */
                        return new fromFileUploadActions.UploadFailureAction({
                            error: `Internal Server Error, Please inform us by email.`
                        });
                    }

                    if (event.status >= 500) {
                        return new fromFileUploadActions.UploadFailureAction({
                            error: `Server Error ${event.status}, Please inform us by email.`
                        });
                    }

                    if (event.status >= 400) {
                        return new fromFileUploadActions.UploadFailureAction({
                            error: `Client Error ${event.status}, Please check your inserted data`
                        });
                    }

                    return new fromFileUploadActions.UploadFailureAction({
                        error: `Request Error ${event.status}, Please check action`
                    });
                }
            }
            default: {
                return new fromFileUploadActions.UploadFailureAction({
                    error: `Unknown Event: ${JSON.stringify(event)}`
                });
            }
        }
    }

    private handleError(error: any) {
        // const friendlyErrorMessage = serializeError(error).message;
        return new fromFileUploadActions.UploadFailureAction({
            error: 'Too large file. Max file upload is: 16mb.'
            // error: 'Uploading file size is huge, Please check file size is less 16mb.'
        });
    }
}
