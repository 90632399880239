// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { AuditTrailParticipantsService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    AuditTrailParticipantActionTypes,
    AuditTrailParticipantsPageRequested,
    AuditTrailParticipantsPageLoaded,
    AuditTrailParticipantActionToggleLoading,
    AuditTrailParticipantsPageToggleLoading,
    AuditTrailParticipantOnServerCreated,
    AuditTrailParticipantCreated
} from '../_actions/audit-trail-participant.actions';

@Injectable()
export class AuditTrailParticipantEffects {
    showPageLoadingDispatcher = new AuditTrailParticipantsPageToggleLoading({ isLoading: true });
    showActionLoadingDispatcher = new AuditTrailParticipantActionToggleLoading({ isLoading: true });
    hideActionLoadingDispatcher = new AuditTrailParticipantActionToggleLoading({ isLoading: false });

    @Effect()
    loadAuditTrailParticipantsPage$ = this.actions$.pipe(
        ofType<AuditTrailParticipantsPageRequested>(AuditTrailParticipantActionTypes.AuditTrailParticipantsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            return this.auditTrailsService.findAuditTrailParticipants(payload.auditTrailId);
        }),
        map(response => {
            return new AuditTrailParticipantsPageLoaded({
                auditTrailParticipants: response.data
            });
        })
    );

    @Effect()
    createAuditTrailParticipant$ = this.actions$
        .pipe(
            ofType<AuditTrailParticipantOnServerCreated>(AuditTrailParticipantActionTypes.AuditTrailParticipantOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.auditTrailsService.createAuditTrailParticipant(payload.auditTrailParticipant, payload.auditTrailId).pipe(
                    tap(res => {
                        this.store.dispatch(new AuditTrailParticipantCreated({auditTrailParticipant: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private auditTrailsService: AuditTrailParticipantsService, private store: Store<AppState>) { }
}
