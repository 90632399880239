<kt-portlet>
  <kt-portlet-header [class]="'car-head-lg'">
    <ng-container ktPortletTitle>
      <h3 class="kt-portlet-head-title">
        <span>Rent Review Types</span>
      </h3>
    </ng-container>

    <ng-container ktPortletTools>
      <a routerLink="../" class="btn mr-2" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
        back_page: ('LT.TITLE' | translate)
      }">
        <mat-icon>arrow_back</mat-icon>
        <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
      </a>

      <a [routerLink]="['./add']" mat-raised-button
        [matTooltip]="'ADDITIONAL_COST_TITLE.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button">
        <span>{{ 'GENERAL.BUTTON.NEW' | translate }}</span>
      </a>

      <button *ngIf="(trashedCnt$|async)" (click)="onTrash()" mat-raised-button
        [matTooltip]="'ADDITIONAL_COST_TITLE.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button"
        class="button-gray ml-2">
        <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashedCnt$|async }})</span>
      </button>
      <button *ngIf="(adminTrashedCnt$|async) != 0 && (canAccessAdminTrash$|async)" (click)="onAdminTrash()" mat-raised-button
        [matTooltip]="'ADDITIONAL_COST_TITLE.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button"
        class="button-gray ml-2">
        <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashedCnt$|async }})</span>
      </button>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div class="mat-table-wrapper">
      <mat-table class="lmat-elevation-z8 table-striped"
        [dataSource]="items$">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let item">{{item.id}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let item">{{item.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="is_hidden">
          <mat-header-cell *matHeaderCellDef>Hidden</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <mat-checkbox [checked]="item.is_hidden"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <ng-container *ngIf="!item.is_system_entry">
              <a mat-icon-button color="primary"
                [matTooltip]="'Edit Entry'" 
                [routerLink]="['./edit', item.id]"
                >
                <mat-icon>create</mat-icon>
              </a>&nbsp;
              <span [matTooltip]="item.relation_cnt == 0
                ? 'Delete Rent Review Types'
                : 'Cannot Delete, Linked to other tables'">
                <button mat-icon-button color="warn" type="button" (click)="onDeleteItem(item)" [disabled]="item.relation_cnt > 0">
                  <mat-icon>delete</mat-icon>
                </button>
              </span>
            </ng-container>
            <ng-container *ngIf="item.is_system_entry">
              <button mat-icon-button color="primary"
                [matTooltip]="'View Entry'" 
                (click)="onViewItem(item)">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['id', 'name', 'is_hidden', 'actions']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['id', 'name', 'is_hidden', 'actions']"></mat-row>
      </mat-table>
    </div>
  </kt-portlet-body>
</kt-portlet>