import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { ChecklistTableComponent } from './_sub/checklist-table/checklist-table.component';

@Component({
  selector: 'kt-property-observation-checklist',
  templateUrl: './property-observation-checklist.component.html',
  styleUrls: ['./property-observation-checklist.component.scss']
})
export class PropertyObservationChecklistComponent implements OnInit {
  @Input() propertyTypeId: number;
  @Input() inputData: any;
  @ViewChild(ChecklistTableComponent) checkListTableComponent: ChecklistTableComponent;

  dataSource = new MatTableDataSource();
  data$: BehaviorSubject<ObservationDataItem[]> = new BehaviorSubject([]);
  data: ObservationDataItem[] = [];
  displayedColumns = [];
  otherNotes: string = '';

  constructor() {}

  ngOnInit(): void {
    if (this.propertyTypeId == PropertyTypes.Commercial) {
      this.displayedColumns = ['name', 'building', 'basement', 'outbuilding', 
                               'hardstanding', 'agriculture', 'landscaping', 'water_course', 
                               'surrounding_area', 'extent_of_inspection'];
      this.data$.next(this.inputData.table1.length == 0 ? getCommercialData2() : this._deepCopyData(this.inputData.table1));
    } else {
      this.displayedColumns = ['name', 'building', 'basement', 'outbuilding',
                               'gardens', 'surrounding_area', 'extent']
      this.data$.next(this.inputData.table1.length == 0 ? getResidentialData() : this._deepCopyData(this.inputData.table1));
    }

    this.otherNotes = this.inputData.otherNotes;

    this.data$.subscribe(data => {
      this.data = data;
      this.dataSource.data = data;
    })
  }

  checkBoxChange(event: MatCheckboxChange, item: ObservationDataItem, type: string) {
    let list = this._deepCopyData(this.data);
    // Check
    const indx = list.findIndex(data_item => data_item.key == item.key);
    if (indx == -1) {
      return;
    }
    const curr_item = list[indx];
    const new_item = Object.assign({}, curr_item);
    new_item[type].value = event.checked;
    list.splice(indx, 1, new_item);

    if (item.key == "inspected") {
      list = list.map(listItem => {
        if (listItem.key == "inspected") {
          return listItem;
        }
        const newItem = {...listItem};
        newItem[type] = {
          inspected: event.checked, 
          value: newItem[type].value == null ? null : false
        } 
        return newItem;
      })
    }

    this.data$.next(list);
  }

  private _deepCopyData(data: ObservationDataItem[]): ObservationDataItem[] {
    const arr = data.map(item => {
      return Object.assign({}, item)
    });
    arr.sort((a, b) => (a.order - b.order));
    return arr;
  }

  getData() {
    return {
      table1: this.data,
      table2: this.checkListTableComponent.getData(),
      otherNotes: this.otherNotes
    };
  }
}

type ObservationDataItem = {
  id: number;
  name: string;
  key: string;
  list: string[];
  order: number;
  building: ObservationInspected;
  basement: ObservationInspected;
  outbuilding: ObservationInspected;
  hardstanding: ObservationInspected;
  agriculture: ObservationInspected;
  landscaping: ObservationInspected;
  water_course: ObservationInspected;
  surrounding_area: ObservationInspected;
  extent_of_inspection: ObservationInspected;
  gardens: ObservationInspected;
  extent: ObservationInspected;
}

type ObservationInspected = {
  inspected: boolean;
  value: boolean;
}

function getResidentialData(): ObservationDataItem[] {
  const rows = [
    {name: "Inspected", key: "inspected", order: 1},
    {name: "Renewables", key: "renewables", order: 2},
    {name: "Asbestos", key: "asbestos", order: 3},
    {name: "Fly Tipping", key: "fly_tipping", order: 4},
    {name: "Oil fuel Tanks", key: "fuel_tanks", order: 5, list: ["above or below ground", "bunded"]},
    {name: "Other tanks or containers", order: 6, key: "other_tanks"},
    {name: "Incineration areas", order: 7, key: "incineration_areas"},
    {name: "Septic tank overflow", order: 8, key: "septic_tank_overflow"},
    {name: "Oil staining", order: 9, key: "oil_staining"},
    {name: "Invasive non-native species", order: 10, key: "invasive_non_native"},
    {name: "Irregular topography", order: 11, key: "topography"},
    {name: "Vegetation dieback", order: 12, key: "vegetation_dieback"},
    {name: "Utilities", order: 13, key: "utilities"}
  ]

  return rows.map(row => {
    return {
      id: undefined,
      name: row.name,
      key: row.key,
      order: row.order,
      list: row.list ? row.list : null,
      building: getObservationInspected(row.key, 'building'),
      basement: getObservationInspected(row.key, 'basement'),
      outbuilding: getObservationInspected(row.key, 'outbuilding'),
      hardstanding: getObservationInspected(row.key, 'hardstanding'),
      agriculture: getObservationInspected(row.key, 'agriculture'),
      landscaping: getObservationInspected(row.key, 'landscaping'),
      water_course: getObservationInspected(row.key, 'water_course'),
      surrounding_area: getObservationInspected(row.key, 'surrounding_area'),
      extent_of_inspection: getObservationInspected(row.key, 'extent_of_inspection'),
      gardens: getObservationInspected(row.key, 'gardens'),
      extent: getObservationInspected(row.key, 'extent')
    }
  });

}

function getCommercialData2(): ObservationDataItem[] {
  const rows = [
    {name: "Inspected", order: 1, key: "inspected"},
    {name: "Waste management abuses", order: 2, key: "waste_management"},
    {name: "Asbestos containing material?", order: 3, key: "asbestos", list: ["In site?", "Discarded", "On site?"]},
    {name: "Fly Tipping", order: 4, key: "fly_tipping"},
    {name: "Fuel Tanks", order: 5, key: "fuel_tanks", list: ["above or below ground and/or", "oil staining"]},
    {name: "Other tanks or containers", order: 6, key: "other_tanks"},
    {name: "Chemical odors / Incineration areas", order: 7, key: "chemical_odors"},
    {name: "Discolored or smelly water / liquids leak discharges", order: 8, key: "discolored_water"},
    {name: "Invasive non-native species", order: 9, key: "invasive_non_native"},
    {name: "Irregular topography", order: 10, key: "topography"},
    {name: "Vegetation dieback", order: 11, key: "vegetation_dieback"},
    {name: "Utilities", order: 12, key: "utilities"}
  ]

  return rows.map(row => {
    return {
      id: undefined,
      name: row.name,
      key: row.key,
      order: row.order,
      list: row.list ? row.list : null,
      building: getObservationInspected(row.key, 'building'),
      basement: getObservationInspected(row.key, 'basement'),
      outbuilding: getObservationInspected(row.key, 'outbuilding'),
      hardstanding: getObservationInspected(row.key, 'hardstanding'),
      agriculture: getObservationInspected(row.key, 'agriculture'),
      landscaping: getObservationInspected(row.key, 'landscaping'),
      water_course: getObservationInspected(row.key, 'water_course'),
      surrounding_area: getObservationInspected(row.key, 'surrounding_area'),
      extent_of_inspection: getObservationInspected(row.key, 'extent_of_inspection'),
      gardens: getObservationInspected(row.key, 'gardens'),
      extent: getObservationInspected(row.key, 'extent')
    }
  });
}

function getObservationInspected(key: string, type: string): ObservationInspected {
  if (key == 'inspected') {
    return {inspected: true, value: false};
  }
  if (key == 'topography') {
    const val = type == 'building' || type == 'basement' || type == 'outbuilding' || type == 'hardstanding' || type == 'water_course';
    return {inspected: false, value: val ? null : false }
  }
  if (key == 'vegetation_dieback') {
    const val = type == 'building' || type == 'basement' || type == 'outbuilding' || type == 'hardstanding';
    return {inspected: false, value: val ? null : false }
  }
  return {inspected: false, value: false};
}

function getCommercialData() {
  return [
    {
      name: "Inspected",
      key: "inspected",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Waste management abuses",
      key: "waste_management",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Asbestos containing material?",
      key: "asbestos",
      list: ["In site?", "Discarded", "On Site?"],
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Fly Tipping",
      key: "fly_tipping",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Fuel Tanks:",
      key: "fuel_tanks",
      list: ["above or below ground and/or", "oil staining"],
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Other tanks or containers",
      key: "other_tanks",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Chemical odors / Incineration areas",
      key: "chemical_odors",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Discolored or smelly water / liquids leak discharges",
      key: "discolored_water",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Invasive non-native species",
      key: "invasive_non_native",
      list: null,
      building: false,
      basement: false,
      outbuilding: true,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Invasive topography",
      key: "topography",
      list: null,
      building: null,
      basement: null,
      outbuilding: null,
      hardstanding: null,
      agriculture: false,
      landscaping: true,
      water_course: null,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Vegetation dieback",
      key: "vegetation_dieback",
      list: null,
      building: null,
      basement: null,
      outbuilding: null,
      hardstanding: null,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
    {
      name: "Utilities",
      key: "utilities",
      list: null,
      building: false,
      basement: false,
      outbuilding: false,
      hardstanding: false,
      agriculture: false,
      landscaping: true,
      water_course: false,
      surrounding_area: true,
      extent_of_inspection: false
    },
  ]
}
