<div class="card card-custom">
    <div *ngIf="viewLoading" class="card-body-progress card-body-progress-overlay">
        <!-- here can place a progress bar-->
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">
            <button
                (click)="onYesClick()" 
                class="btn btn-sm btn-success mr-2" cdkFocusInitial
                style="width: 80px;" 
                [disabled]="inputTitleForUpdate.value.length == 0">
                Save
        </button>
            <button (click)="onNoClick()"
                class="btn btn-sm btn-icon btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
    </button>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="form-group kt-form__group row">
                <div class="col-lg-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <input required matInput placeholder="Title" [formControl]="inputTitleForUpdate"/>
                        <mat-error>Title for file
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="descriptionField" class="mat-form-field-fluid">
						<textarea required matInput rows="4" placeholder="Description" [formControl]="inputDescriptionForUpdate">
						</textarea>
                        <mat-icon
                                matSuffix
                                *ngIf="descriptionTooltip"
                                class="cursor-pointer"
                                (click)="$event.stopPropagation();"
                                container="body"

                                [popoverTitle]="descriptionTooltip.title"
                                [ngbPopover]="descriptionTooltip.desc">help
                        </mat-icon>

                        <mat-error>Description for file
                            <strong>required</strong>
                        </mat-error>
                        <!--descriptionTooltip-->
                    </mat-form-field>

                    <mat-checkbox *ngIf="checkBox" [checked]="checkBoxValue" (change)="changed()">
                        Automatically insert to all reports
                    </mat-checkbox>
                    <mat-checkbox *ngIf="featuredPictureAllowed" [checked]="isFeatured" (change)="featuredChanged()" [disabled]="isFeatured">
                        Featured picture
                    </mat-checkbox>
                    <mat-select #dropDown class="mt-8" 
                        *ngIf="dropDownValues.length > 0" 
                        [value]="currentDropDownValueInd" >
                        <mat-option 
                            *ngFor="let value of dropDownValues; let i = index" 
                            [value]="i">
                            {{value.value}}
                        </mat-option>
                    </mat-select>
                    <mat-form-field *ngIf="buildingField" class="mat-form-field-fluid">
                        <mat-select placeholder="Building" [formControl]="inputBuildingForUpdate">
                            <mat-option *ngFor="let building of buildingList; let index = index" [value]="(index + 1) + ' - ' + building.building_name">{{building.building_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions--solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">Close</button>&nbsp;
                        <button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="inputTitleForUpdate.value.length == 0">
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
