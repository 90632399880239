import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { CriterionModel } from "../_models/criterion.model";
import { SizeCriterionsState } from "../_reducers/size_criterion.reducer";

export const selectSizeCriterionsState = createFeatureSelector<SizeCriterionsState>('size-criterions');

export const selectDefaultSizeCriterion = createSelector(
    selectSizeCriterionsState,
    state => state.default
);

export const selectSizeCriterions = createSelector(
    selectSizeCriterionsState,
    state => {
        const items: CriterionModel[] = [];
        each(state.entities, el => items.push(el));
        return items;
    }
)

export const selectCriterionStandardMeasurement = createSelector(
    selectSizeCriterionsState,
    state => state.standardMeasurements
)

export const selectCriterionUnitAreaMeasurement = createSelector(
    selectSizeCriterionsState,
    state => state.unitAreaMeasurement
)

export const selectIsSizeCriterionLoading = createSelector(
    selectSizeCriterionsState,
    state => state.isLoading
)