// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {RoofStructuresService} from '../_services/roof-structure.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allRoofStructuresLoaded} from '../_selectors/roof-structure.selectors';
// Actions
import {
    AllRoofStructuresLoaded,
    AllRoofStructuresRequested,
    RoofStructureActionTypes,
    RoofStructuresPageRequested,
    RoofStructuresPageLoaded,
    RoofStructureUpdated,
    RoofStructuresPageToggleLoading,
    RoofStructureDeleted,
    RoofStructureOnServerCreated,
    RoofStructureCreated,
    RoofStructureAdminRestored,
    RoofStructureDeletedFromAdminTrash,
    RoofStructureDeletedFromTrash,
    RoofStructureOnServerAdminRestored,
    RoofStructureOnServerRestored,
    RoofStructureRestored,
    RoofStructureTrashFlushed,
    RoofStructuresActionToggleLoading
} from '../_actions/roof-structure.actions';

@Injectable()
export class RoofStructureEffects {
    showPageLoadingDispatcher = new RoofStructuresPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RoofStructuresActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RoofStructuresActionToggleLoading({isLoading: false});

    @Effect()
    loadAllRoofStructures$ = this.actions$
        .pipe(
            ofType<AllRoofStructuresRequested>(RoofStructureActionTypes.AllRoofStructuresRequested),
            withLatestFrom(this.store.pipe(select(allRoofStructuresLoaded))),
            filter(([action, isAllRoofStructuresLoaded]) => !isAllRoofStructuresLoaded),
            mergeMap(() => this.service.getAllRoofStructures()),
            map(res => {
                return new AllRoofStructuresLoaded({roofStructures: res.data});
            })
        );

    @Effect()
    loadRoofStructuresPage$ = this.actions$
        .pipe(
            ofType<RoofStructuresPageRequested>(RoofStructureActionTypes.RoofStructuresPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findRoofStructures(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new RoofStructuresPageLoaded({
                    roofStructures: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureDeleted>(RoofStructureActionTypes.RoofStructureDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteRoofStructure(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureUpdated>(RoofStructureActionTypes.RoofStructureUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateRoofStructure(payload.roofStructure);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureOnServerCreated>(RoofStructureActionTypes.RoofStructureOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createRoofStructure(payload.roofStructure).pipe(
                    tap(res => {
                        this.store.dispatch(new RoofStructureCreated({roofStructure: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushRoofStructureTrash$ = this.actions$
        .pipe(
            ofType<RoofStructureTrashFlushed>(RoofStructureActionTypes.RoofStructureTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureOnServerRestored>(RoofStructureActionTypes.RoofStructureOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RoofStructureRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureOnServerAdminRestored>(RoofStructureActionTypes.RoofStructureOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RoofStructureAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureDeletedFromTrash>(RoofStructureActionTypes.RoofStructureDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashRoofStructure$ = this.actions$
        .pipe(
            ofType<RoofStructureDeletedFromAdminTrash>(RoofStructureActionTypes.RoofStructureDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: RoofStructuresService, private store: Store<AppState>) {
    }
}
