import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CreateToeAuditTaskReminder, DeleteToeAuditTaskReminder, ToeAuditTaskReminderActionTypes, ToeAuditTaskReminderCreated, ToeAuditTaskReminderDeleted, ToeAuditTaskRemindersLoaded, ToeAuditTaskRemindersRequested, ToeAuditTaskReminderUpdated, UpdateToeAuditTaskReminder } from "../_actions/toe-audit-task-reminder.actions";
import { ToeAuditTaskReminderModel } from "../_models/toe-audit-task-reminder.model";
import { ToeAuditTaskReminderService } from "../_services/toe-audit-task-reminder.service";

@Injectable()
export class ToeAuditTaskReminderEffects {
    constructor (
        private actions$: Actions,
        private service: ToeAuditTaskReminderService
    ) {}
    @Effect()
    loadData$ = this.actions$.pipe(
        ofType<ToeAuditTaskRemindersRequested>(ToeAuditTaskReminderActionTypes.ToeAuditTaskRemindersRequested),
        mergeMap(({payload}) => {
            return this.service.fetch(payload.toe_id)
        }),
        map(response => {
            return new ToeAuditTaskRemindersLoaded({
                reminders: response
            })
        })
    )

    @Effect()
    create$ = this.actions$.pipe(
        ofType<CreateToeAuditTaskReminder>(ToeAuditTaskReminderActionTypes.CreateToeAuditTaskReminder),
        mergeMap(({payload}) => {
            return this.service.create(payload.reminder)
        }),
        map(response => {
            return new ToeAuditTaskReminderCreated({
                reminder: response
            })
        })
    )

    @Effect()
    update$ = this.actions$.pipe(
        ofType<UpdateToeAuditTaskReminder>(ToeAuditTaskReminderActionTypes.UpdateToeAuditTaskReminder),
        mergeMap(({payload}) => {
            return this.service.update(payload.reminder)
        }),
        map(response => {
            const update: Update<ToeAuditTaskReminderModel> = {
                id: response.id,
                changes: {
                    ...response
                }
            }
            return new ToeAuditTaskReminderUpdated({
                reminder: update
            })
        })
    )

    @Effect()
    delete$ = this.actions$.pipe(
        ofType<DeleteToeAuditTaskReminder>(ToeAuditTaskReminderActionTypes.DeleteToeAuditTaskReminder),
        mergeMap(({payload}) => {
            return combineLatest([this.service.delete(payload.id), of(payload.id)])
        }),
        map(([_res, id]) => {
            return new ToeAuditTaskReminderDeleted({
                id
            })
        })
    )
}