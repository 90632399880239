// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AssetClassValuationGroup} from '../_models/asset-class-valuation-group.model';

export enum AssetClassValuationGroupActionTypes {

    AllAssetClassValuationGroupsRequested = '[AssetClassValuationGroups Home Page] All AssetClassValuationGroups Requested',
    AllAssetClassValuationGroupsLoaded = '[AssetClassValuationGroups API] All AssetClassValuationGroups Loaded',

    AssetClassValuationGroupOnServerCreated = '[Edit AssetClassValuationGroup Dialog] AssetClassValuationGroup On Server Created',
    AssetClassValuationGroupCreated = '[Edit AssetClassValuationGroups Dialog] AssetClassValuationGroups Created',
    AssetClassValuationGroupUpdated = '[Edit AssetClassValuationGroup Dialog] AssetClassValuationGroup Updated',
    AssetClassValuationGroupDeleted = '[AssetClassValuationGroups List Page] AssetClassValuationGroup Deleted',

    AssetClassValuationGroupsPageRequested = '[AssetClassValuationGroups List Page] AssetClassValuationGroups Page Requested',
    AssetClassValuationGroupsPageLoaded = '[AssetClassValuationGroups API] AssetClassValuationGroups Page Loaded',
    AssetClassValuationGroupsPageCancelled = '[AssetClassValuationGroups API] AssetClassValuationGroups Page Cancelled',

    AssetClassValuationGroupsPageToggleLoading = '[AssetClassValuationGroups page] AssetClassValuationGroups Page Toggle Loading',
    AssetClassValuationGroupsActionToggleLoading = '[AssetClassValuationGroups] AssetClassValuationGroups Action Toggle Loading'
}

export class AssetClassValuationGroupOnServerCreated implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupOnServerCreated;

    constructor(public payload: { assetClassValuationGroup: AssetClassValuationGroup}) {
    }
}

export class AssetClassValuationGroupCreated implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupCreated;

    constructor(public payload: { assetClassValuationGroup: AssetClassValuationGroup }) {
    }
}

export class AssetClassValuationGroupUpdated implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupUpdated;

    constructor(public payload: {
        partialassetClassValuationGroup: Update<AssetClassValuationGroup>,
        assetClassValuationGroup: AssetClassValuationGroup
    }) {
    }
}

export class AssetClassValuationGroupDeleted implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassValuationGroupsPageRequested implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassValuationGroupsPageLoaded implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageLoaded;

    constructor(public payload: { assetClassValuationGroups: AssetClassValuationGroup[], totalCount: number, page: QueryParamsModel }) {
    }
}

export class AssetClassValuationGroupsPageCancelled implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageCancelled;
}

export class AllAssetClassValuationGroupsRequested implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AllAssetClassValuationGroupsRequested;
}

export class AllAssetClassValuationGroupsLoaded implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AllAssetClassValuationGroupsLoaded;

    constructor(public payload: { assetClassValuationGroups: AssetClassValuationGroup[] }) {
    }
}

export class AssetClassValuationGroupsPageToggleLoading implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassValuationGroupsActionToggleLoading implements Action {
    readonly type = AssetClassValuationGroupActionTypes.AssetClassValuationGroupsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export type AssetClassValuationGroupActions = AssetClassValuationGroupCreated
    | AssetClassValuationGroupUpdated
    | AssetClassValuationGroupDeleted
    | AssetClassValuationGroupsPageRequested
    | AssetClassValuationGroupsPageLoaded
    | AssetClassValuationGroupsPageCancelled
    | AllAssetClassValuationGroupsLoaded
    | AllAssetClassValuationGroupsRequested
    | AssetClassValuationGroupOnServerCreated
    | AssetClassValuationGroupsPageToggleLoading
    | AssetClassValuationGroupsActionToggleLoading;
