// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ExternalFacadeActions, ExternalFacadeActionTypes} from '../_actions/external-facade.actions';
// Models
import {ExternalFacadeModel} from '../_models/external-facade.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ExternalFacadesState extends EntityState<ExternalFacadeModel> {
    isAllExternalFacadesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ExternalFacadeModel[];
    lastCreatedExternalFacadeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ExternalFacadeModel> = createEntityAdapter<ExternalFacadeModel>();

export const initialExternalFacadeState: ExternalFacadesState = adapter.getInitialState({
    isAllExternalFacadesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedExternalFacadeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function externalFacadeReducer(state = initialExternalFacadeState, action: ExternalFacadeActions): ExternalFacadesState {
    switch (action.type) {
        case ExternalFacadeActionTypes.ExternalFacadesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedExternalFacadeId: undefined
            };
        case ExternalFacadeActionTypes.ExternalFacadeActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ExternalFacadeActionTypes.ExternalFacadeOnServerCreated:
            return {
                ...state
            };
        case ExternalFacadeActionTypes.ExternalFacadeCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedExternalFacadeId: action.payload.item.id
            });
        case ExternalFacadeActionTypes.ExternalFacadeUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ExternalFacadeActionTypes.AllExternalFacadesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllExternalFacadesLoaded: true
            });
        case ExternalFacadeActionTypes.ExternalFacadesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ExternalFacadeActionTypes.ExternalFacadesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialExternalFacadeState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ExternalFacadeActionTypes.ExternalFacadeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ExternalFacadeActionTypes.ExternalFacadeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ExternalFacadeActionTypes.ExternalFacadeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ExternalFacadeActionTypes.ExternalFacadeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ExternalFacadeActionTypes.ExternalFacadeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ExternalFacadeActionTypes.ExternalFacadeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
