import {BaseModel} from '../../_base/crud';

export class ClientContactModel extends BaseModel {
    id: number;
    client_id: number;

    first_name: string;
    last_name: string;
    role: string;
    professional_phone: string;
    mobile_phone: string;
    email: string;
    social: string;

    relation_cnt: number;

    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear(): void {
        this.id = undefined;
        this.client_id = undefined;
        this.first_name = '';
        this.last_name = '';
        this.relation_cnt = 0;

        this.role = '';
        this.professional_phone = '';
        this.mobile_phone = '';
        this.email = '';
        this.social = '';

        this.user_deleted = null;
        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
