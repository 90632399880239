import { Action } from "@ngrx/store";
import { AdjustmentModel } from "../_models/valuation-process-adjustment.model";
import { ValuationAdjustmentTabDataModel } from "../_models/valuation-adjustment-tab-data.model";

export enum ValuationProcessAdjustmentActionTypes {
    AddAdjustments = '[Valuation Adjustment Modal] Add adjustments to valuation process',

    DeleteAdjustment = '[Adjustments Table] Delete adjusment from valuation process',

    EditAdjustment = '[Adjustments Table] Update valuation process adjustment',

    AddComparable = '[Valuation Process Effect] Add comparable to adjustments',
    AddMultipleComparables = '[Valuation Comparable Effect] Add Multiple comparable to adjustments',
    RemoveComparable = '[Valuation Process Effect] Remove comparable from adjustments',
    
    UnimportValuation = '[Valuation Process Effect] Unimport comparables of imported valuation',

    UpdateAdjustment = '[Adjustments Table] Update adjustment value of comparable',

    ResetState = '[Valuation Process Page] Reset State of valuation process adjustments',

    LoadAdjustments = '[Valuation Process Effect] Load adjustments'
}

export class ValuationProcessAddAdjustments implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.AddAdjustments
    constructor(public payload: {
        refNums: string[],
        adjustments: {
            id: number,
            name: string,
            description: string
        }[]
    }) {}
}

export class ValuationProcessDeleteAdjustment implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.DeleteAdjustment
    constructor(public payload: {id: number}){}
}

export class ValuationProcessEditAdjustment implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.EditAdjustment
    constructor(public payload: {id: number, changes: {name: string, description: string, analysis: string, adjustmentId: number}}) {}
}

export class ValuationProcessAdjustmentsAddComparable implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.AddComparable
    constructor(public payload: {refNum: string}) {}
}

export class ValuationProcessAdjustmentsAddMultipleComparables implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.AddMultipleComparables
    constructor(public payload: {refNums: string[], adjustmentTabData: ValuationAdjustmentTabDataModel}) {}
}

export class ValuationProcessAdjustmentsRemoveComparable implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.RemoveComparable
    constructor(public payload: {refNum: string}) {}
}

export class ValuationProcessAdjustmentUpdateValue implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.UpdateAdjustment
    constructor(public payload: {refNum: string, value: number, item: AdjustmentModel}) {}
}

export class ValuationProcessAdjustmentsReset implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.ResetState
}

export class ValuationProcessLoadAdjustments implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.LoadAdjustments
    constructor(public payload: {adjustments: AdjustmentModel[]}) {}
}

export class ValuationProcessUnimportAdjustments implements Action {
    readonly type = ValuationProcessAdjustmentActionTypes.UnimportValuation
}

export type ValuationProcessAdjustmentActions 
    = ValuationProcessAddAdjustments
    | ValuationProcessDeleteAdjustment
    | ValuationProcessEditAdjustment
    | ValuationProcessAdjustmentsAddComparable
    | ValuationProcessAdjustmentsRemoveComparable
    | ValuationProcessAdjustmentUpdateValue
    | ValuationProcessAdjustmentsReset
    | ValuationProcessLoadAdjustments

    | ValuationProcessAdjustmentsAddMultipleComparables
    | ValuationProcessUnimportAdjustments