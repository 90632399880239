// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {BuildingType} from '../_models/building-type.model';

export enum BuildingTypeActionTypes {

    AllBuildingTypesRequested = '[BuildingTypes Home Page] All BuildingTypes Requested',
    AllBuildingTypesLoaded = '[BuildingTypes API] All BuildingTypes Loaded',

    BuildingTypeOnServerCreated = '[Edit BuildingType Dialog] BuildingType On Server Created',
    BuildingTypeCreated = '[Edit BuildingTypes Dialog] BuildingTypes Created',
    BuildingTypeUpdated = '[Edit BuildingType Dialog] BuildingType Updated',
    BuildingTypeDeleted = '[BuildingTypes List Page] BuildingType Deleted',

    BuildingTypesPageRequested = '[BuildingTypes List Page] BuildingTypes Page Requested',
    BuildingTypesPageLoaded = '[BuildingTypes API] BuildingTypes Page Loaded',
    BuildingTypesPageCancelled = '[BuildingTypes API] BuildingTypes Page Cancelled',

    BuildingTypesPageToggleLoading = '[BuildingTypes page] BuildingTypes Page Toggle Loading',
    BuildingTypesActionToggleLoading = '[BuildingTypes] BuildingTypes Action Toggle Loading',

    // trash system

    BuildingTypeOnServerRestored = '[BuildingType Trash] BuildingType On Server Restored',
    BuildingTypeRestored = '[BuildingType Trash] BuildingType Restored',

    BuildingTypeOnServerAdminRestored = '[BuildingType Admin Trash] BuildingType On Server Restored',
    BuildingTypeAdminRestored = '[BuildingType Admin Trash] BuildingType Restored',

    BuildingTypeDeletedFromTrash = '[BuildingType Trash] BuildingType deleted',
    BuildingTypeDeletedFromAdminTrash = '[BuildingType Admin Trash] BuildingType deleted',

    BuildingTypeTrash = 'BuildingType Trashed',
    BuildingTypeTrashFlushed = 'BuildingType Trash Flushed',
}

export class BuildingTypeOnServerCreated implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeOnServerCreated;

    constructor(public payload: { buildingType: BuildingType}) {
    }
}

export class BuildingTypeCreated implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeCreated;

    constructor(public payload: { buildingType: BuildingType }) {
    }
}

export class BuildingTypeUpdated implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeUpdated;

    constructor(public payload: {
        partialBuildingType: Update<BuildingType>,
        buildingType: BuildingType
    }) {
    }
}

export class BuildingTypeDeleted implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingTypesPageRequested implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class BuildingTypesPageLoaded implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypesPageLoaded;

    constructor(public payload: { buildingTypes: BuildingType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class BuildingTypesPageCancelled implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypesPageCancelled;
}

export class AllBuildingTypesRequested implements Action {
    readonly type = BuildingTypeActionTypes.AllBuildingTypesRequested;
}

export class AllBuildingTypesLoaded implements Action {
    readonly type = BuildingTypeActionTypes.AllBuildingTypesLoaded;

    constructor(public payload: { buildingTypes: BuildingType[] }) {
    }
}

export class BuildingTypesPageToggleLoading implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BuildingTypesActionToggleLoading implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class BuildingTypeDeletedFromAdminTrash implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class BuildingTypeDeletedFromTrash implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingTypeOnServerRestored implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingTypeRestored implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeRestored;

    constructor(public payload: { item: BuildingType }) {
    }
}

export class BuildingTypeOnServerAdminRestored implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingTypeAdminRestored implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeAdminRestored;

    constructor(public payload: { item: BuildingType }) {
    }
}

export class BuildingTypeTrashFlushed implements Action {
    readonly type = BuildingTypeActionTypes.BuildingTypeTrashFlushed;

    constructor() {
    }
}

export type BuildingTypeActions = BuildingTypeCreated
    | BuildingTypeUpdated
    | BuildingTypeDeleted
    | BuildingTypesPageRequested
    | BuildingTypesPageLoaded
    | BuildingTypesPageCancelled
    | AllBuildingTypesLoaded
    | AllBuildingTypesRequested
    | BuildingTypeOnServerCreated
    | BuildingTypesPageToggleLoading
    | BuildingTypesActionToggleLoading
    | BuildingTypeDeletedFromAdminTrash
    | BuildingTypeDeletedFromTrash
    | BuildingTypeOnServerRestored
    | BuildingTypeRestored
    | BuildingTypeOnServerAdminRestored
    | BuildingTypeAdminRestored
    | BuildingTypeTrashFlushed;
