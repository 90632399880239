import {FireplacesChimneyBreast} from '../_models/fireplaces-chimney-breast.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {FireplacesChimneyBreastsState} from '../_reducers/fireplaces-chimney-breast.reducers';
import * as fromFireplacesChimneyBreast from '../_reducers/fireplaces-chimney-breast.reducers';
import {each} from 'lodash';

export const selectFireplacesChimneyBreastsState = createFeatureSelector<FireplacesChimneyBreastsState>('fireplacesChimneyBreasts');

export const selectFireplacesChimneyBreastById = (fireplacesChimneyBreastId: number) => createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => fireplacesChimneyBreastsState.entities[fireplacesChimneyBreastId]
);

export const selectAllFireplacesChimneyBreasts = createSelector(
    selectFireplacesChimneyBreastsState,
    fromFireplacesChimneyBreast.selectAll
);

export const selectAllFireplacesChimneyBreastsIds = createSelector(
    selectFireplacesChimneyBreastsState,
    fromFireplacesChimneyBreast.selectIds
);

export const allFireplacesChimneyBreastsLoaded = createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => fireplacesChimneyBreastsState.isAllFireplacesChimneyBreastsLoaded
);


export const selectFireplacesChimneyBreastsPageLoading = createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => fireplacesChimneyBreastsState.listLoading
);

export const selectFireplacesChimneyBreastsActionLoading = createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => fireplacesChimneyBreastsState.actionLoading
);

export const selectLastCreatedFireplacesChimneyBreastId = createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => fireplacesChimneyBreastsState.lastCreatedFireplacesChimneyBreastId
);

export const selectFireplacesChimneyBreastsShowInitWaitingMessage = createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => fireplacesChimneyBreastsState.showInitWaitingMessage
);

export const selectTrashedFireplacesChimneyBreastCount = createSelector(
    selectFireplacesChimneyBreastsState,
    (fireplacesChimneyBreastsState) => fireplacesChimneyBreastsState.totalTrashed
);

export const selectAdminTrashedFireplacesChimneyBreastCount = createSelector(
    selectFireplacesChimneyBreastsState,
    (fireplacesChimneyBreastsState) => fireplacesChimneyBreastsState.totalAdminTrashed
);


export const selectFireplacesChimneyBreastQueryResult = createSelector(
    selectFireplacesChimneyBreastsState,
    fireplacesChimneyBreastsState => {
        const items: FireplacesChimneyBreast[] = [];
        each(fireplacesChimneyBreastsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: FireplacesChimneyBreast[] = httpExtension.sortArray(items, fireplacesChimneyBreastsState.lastQuery.sortField, fireplacesChimneyBreastsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, fireplacesChimneyBreastsState.totalCount, '', fireplacesChimneyBreastsState.totalTrashed);
    }
);
