// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {ToeReportService} from '../_services/toe-report.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AllToeReportsLoaded,
    AllToeReportsRequested,
    ToeReportActionTypes,
    ToeReportUpdated,
    ToeReportsPageToggleLoading,
    ToeReportDeleted,
    ToeReportOnServerCreated,
    ToeReportCreated,
    ToeReportsActionToggleLoading,
    GenerateToeReport,
    ReportGenerated
} from '../_actions/toe-report.actions';

@Injectable()
export class ToeReportEffects {
    showPageLoadingDispatcher = new ToeReportsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ToeReportsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ToeReportsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllToeReports$ = this.actions$
        .pipe(
            ofType<AllToeReportsRequested>(ToeReportActionTypes.AllToeReportsRequested),
            mergeMap(( { payload } ) => this.service.getAllToeReports(payload.toeId)),
            map(res => {
                return new AllToeReportsLoaded({toeReports: res.data});
            })
        );

    @Effect()
    deleteToeReport$ = this.actions$
        .pipe(
            ofType<ToeReportDeleted>(ToeReportActionTypes.ToeReportDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteToeReport(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateToeReport$ = this.actions$
        .pipe(
            ofType<ToeReportUpdated>(ToeReportActionTypes.ToeReportUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateToeReport(payload.toeReport);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createToeReport$ = this.actions$
        .pipe(
            ofType<ToeReportOnServerCreated>(ToeReportActionTypes.ToeReportOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createToeReport(payload.toeReport).pipe(
                    tap(res => {
                        this.store.dispatch(new ToeReportCreated({toeReport: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    generateReport$ = this.actions$.pipe(
        ofType<GenerateToeReport>(ToeReportActionTypes.GenerateReport),
        mergeMap(({payload}) => {
            return this.service.generateToeReport(payload.toeID, payload.userID, payload.toeStatus, payload.reportID);
        }),
        map((res) => {
            return new ReportGenerated({success: res.success});
        })
    )

    constructor(private actions$: Actions, private service: ToeReportService, private store: Store<AppState>) {
    }
}
