import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, mergeMap, tap } from "rxjs/operators";
import { AllValuationReportsLoaded, AllValuationReportsRequested, ValuationReportActionToggleLoading, ValuationReportActionTypes, ValuationReportCreated, ValuationReportLoaded, ValuationReportOnServerCreated, ValuationReportOnServerDeleted, ValuationReportRequested, ValuationReportUpdated } from "../_actions/valuation-report.actions";
import { ReportType } from "../_models/report-types.enum";
import { ValuationReportState } from "../_reducers/valuation-report.reducer";
import { ValuationReportService } from "../_services/valuation-report.service";

@Injectable()
export class ValuationReportEffects {
	hideActionLoadingDispatcher = new ValuationReportActionToggleLoading({isLoading: false});
    showActionLoadingDispatcher = new ValuationReportActionToggleLoading({isLoading: true});

    constructor(
        private actions$: Actions,
        private service: ValuationReportService,
        private store$: Store<ValuationReportState>
    ) {}

    @Effect()
    createReport$ = this.actions$.pipe(
        ofType<ValuationReportOnServerCreated>(ValuationReportActionTypes.ValuationReportOnServerCreated),
        mergeMap(({payload}) => {
            this.store$.dispatch(this.showActionLoadingDispatcher);
            return this.service.createValuationReport(payload.report).pipe(
                tap(res => {
                    this.store$.dispatch(new ValuationReportCreated({report: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    requestReports$ = this.actions$.pipe(
        ofType<AllValuationReportsRequested>(ValuationReportActionTypes.AllValuationReportsRequested),
        mergeMap(({payload}) => this.service.getReports(payload.tpID)),
        map(res => {
            if (res) {
                const mutatedData = res.data.map(item => ({
                    ...item,
                    type: ReportType.ValuationReport
                }));
                return {
                    data: mutatedData,
                    tp_id: res.tp_id
                }
            }
        }),
        map(({data, tp_id}) => {
            return new AllValuationReportsLoaded({reports: data, tpId: tp_id});
        })
    )

    @Effect()
    requestReport$ = this.actions$.pipe(
        ofType<ValuationReportRequested>(ValuationReportActionTypes.ValuationReportRequested),
        mergeMap(({payload}) => {
            return this.service.getReport(payload.id);
        }),
        map(res => {
            return new ValuationReportLoaded({report: res.data});
        })
    )

    @Effect()
    updateReport$ = this.actions$.pipe(
        ofType<ValuationReportUpdated>(ValuationReportActionTypes.ValuationReportUpdated),
        mergeMap(({payload}) => {
            this.store$.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateReport(payload.report)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteReport$ = this.actions$.pipe(
        ofType<ValuationReportOnServerDeleted>(ValuationReportActionTypes.ValuationReportOnServerDeleted),
        mergeMap(({payload}) => {
            this.store$.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteReport(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )
}