import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { CriterionModel } from 'src/app/core/valuation';
import { SizeCriterionChangeDefault, SizeCriterionChangeValue } from 'src/app/core/valuation/_actions/size_criterion.actions';
import { selectSizeCriterions } from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import { selectAllValComparables } from 'src/app/core/valuation/_selectors/valuation-data.selector';

@Component({
  selector: 'kt-sizes-table',
  templateUrl: './sizes-table.component.html',
  styleUrls: ['./sizes-table.component.scss']
})
export class SizesTableComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Output() removeComparableParent: EventEmitter<any> = new EventEmitter();
  @Output() showOverviewParent: EventEmitter<any> = new EventEmitter();
  @Output() showMultipleOverviewParent: EventEmitter<any> = new EventEmitter();
  dataSource: MatTableDataSource<CriterionModel> = new MatTableDataSource();
  activeStates: boolean[] = [];
  comparables: any[] = [];
  headerColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]); 
  displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  selectedCriterion: CriterionModel;
  isSelected: boolean = false;
  selectedCriteironIndx: number;
  isChanged: {[id: number]: boolean}[] = [];
  formArray: UntypedFormArray;
  unitOfMeasurement: string;
  private _onDestroy = new Subject<void>();
  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    combineLatest([
      this.store.select(selectAllValComparables),
      this.store.select(selectSizeCriterions)
    ]).pipe(takeUntil(this._onDestroy)).subscribe(([comparables, sizes]) => {
      if (sizes && comparables) {
        const _sizes = this._filterSizes(sizes);
        this.comparables = comparables;
        this._setHeaders(comparables);
        this._createForm(_sizes);
        this.dataSource.data = _sizes;
        this.unitOfMeasurement = _sizes[0]?.categoryName;
        this.activeStates = [];
        _sizes.forEach(item => {
          this.activeStates.push(item.active);
        })
        this.selectedCriteironIndx = _sizes.findIndex(item => item.active);
        if (this.selectedCriteironIndx > -1) {
          this.isSelected = true;
          this.selectedCriterion = _sizes[this.selectedCriteironIndx];
        }
      }
    });
    // this.store.pipe(
    //   takeUntil(this._onDestroy),
    //   select(selectAllValComparables)
    // ).subscribe(res => {
    //   this.comparables = Object.assign([], res);
    // })


    this._setHeaders(this.comparables);
    this.store.pipe(select(selectSizeCriterions)).subscribe(res => {
    })
  }

  private _filterSizes(sizes: CriterionModel[]): CriterionModel[] {
    return sizes.filter(item => {
      let allEmpty = true 
      if (item.tpValue) {
        allEmpty = false 
      }
      Object.values(item.comValues).forEach(v => {
        if (v) {
          allEmpty = false
        }
      })

      return !allEmpty;
    }) 
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  _setHeaders(comparables: any[]) {
    const headerColumns = [`standard-${comparables.length}-header`];
    const displayedColumns = [`criterion-${comparables.length}`];
    const comparableHeaderColumns = [];
    comparables.forEach((com, i) => {
      headerColumns.push(`com-${i}-${comparables.length}-header`);
      comparableHeaderColumns.push(`com-${i}-${comparables.length}-header`);
      displayedColumns.push(`com-${i}-${comparables.length}`);
    });
    headerColumns.push(`tp-${comparables.length}-header`, 'action-header');
    displayedColumns.push(`tp-${comparables.length}`, 'actions');
    this.headerColumns$.next(headerColumns);
    this.displayedColumns$.next(displayedColumns);
  }

  _createForm(criterions: CriterionModel[]): void {
    this.formArray = this.formBuilder.array([]);
    criterions.forEach((criterion, i) => {
      this.formArray.push(this._createCriterionRowForm(criterion, i));
    });
  }

  _createCriterionRowForm(criterion: CriterionModel, index: number): UntypedFormGroup {
    const formArray = this.formBuilder.group({});
    this.isChanged.push({});
    Object.entries(criterion.comValues).forEach(([key, value]) => {
      const control = new UntypedFormControl(value);
      control.valueChanges.pipe(takeUntil(this._onDestroy),debounceTime(1000)).subscribe(val => {
        if (val != value) {
          this.isChanged[index][key] = true;
        } else {
          this.isChanged[index][key] = false;
        }

        this.store.dispatch(new SizeCriterionChangeValue({
          id: criterion.id,
          type: 'com',
          asset_id: Number(key),
          value: Number(val)
        }));
      })
      formArray.setControl(key+'-cont', control);
    })
    const tpControl = new UntypedFormControl(criterion.tpValue);
    tpControl.valueChanges.pipe(takeUntil(this._onDestroy),debounceTime(1000)).subscribe(val => {
      if (val != criterion.tpValue) {
        this.isChanged[index]['tp'] = true;
      } else {
        this.isChanged[index]['tp'] = false;
      }
      this.store.dispatch(new SizeCriterionChangeValue({
        id: criterion.id,
        type: 'tp',
        asset_id: 0,
        value: Number(val)
      }));
    })
    return new UntypedFormGroup({
      'tp': tpControl,
      'comparables': formArray
    })
  }

  comparableControl(criterionIndx: number, comparableID: number): AbstractControl {
    const criterionFormRow = this.formArray.at(criterionIndx) as UntypedFormGroup;
    const comparableFormArray = criterionFormRow.get('comparables') as UntypedFormGroup;
    return comparableFormArray.get(comparableID+'-cont');
  }

  tpControl(criterionIndx: number): AbstractControl {
    const criterionFormRow = this.formArray.at(criterionIndx) as UntypedFormGroup;
    return criterionFormRow.get('tp');
  }

  setSizeCriterion(criterion: CriterionModel, indx: number ) {
    this.isSelected = true;
    this.selectedCriterion = criterion;
    this.selectedCriteironIndx = indx;
    this._changeActiveStates(indx);
  }

  _copyCriterion(criterion: CriterionModel): CriterionModel {
    const _tmp = Object.assign({}, criterion) as CriterionModel;
    const _val = Object.assign({}, _tmp.comValues);
    _tmp.comValues = _val;
    return _tmp
  }

  _changeActiveStates(indx: number) {
    const _states = [];
    this.activeStates.forEach((state, i) => {
      const _state = indx === i ? true : false;
      _states.push(_state);
    })
    this.activeStates = _states;

    const criterionFormRow = this.formArray.at(this.selectedCriteironIndx) as UntypedFormGroup;
    const _criterion = this._copyCriterion(this.selectedCriterion);
    const comparableFormArray = criterionFormRow.get('comparables') as UntypedFormGroup;
    Object.entries(comparableFormArray.controls).forEach(([key, control]) => {
      const id = key.substring(0, key.length - 5);
      const _id = Number(id)
      _criterion.comValues[_id] = control.value;
    })
    // comparableFormArray.controls.forEach((c, i) => {
    //   _criterion.comValues[i] = c.value ? c.value : '';
    // })
    const tp_value = criterionFormRow.get('tp').value;
    _criterion.tpValue = tp_value ? tp_value : '';
    _criterion.active = true;
    this.isSelected = true;

    const isChanged = this.isChanged[this.selectedCriteironIndx];
    let atLeastOneChanged = false;
    Object.entries(isChanged).forEach(([key, value]) => {
      if (value) {
        atLeastOneChanged = true;
      }
    })
    _criterion.isChanged = atLeastOneChanged;
    this.store.dispatch(new SizeCriterionChangeDefault({
      criterion: _criterion
    }))
  }

  showOverview(assetClass: any) {
      this.showOverviewParent.emit(assetClass);
  }
  removeComparable(index: number, comparable: any) {
      this.removeComparableParent.emit({
          index,
          com: comparable
      });
  }
  showMultipleOverview() {
      this.showMultipleOverviewParent.emit();
  }
}
