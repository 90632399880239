<kt-building-section [readonly]="readonly" [buildingId]="acBuilding.infoSection.building_id" [(centerLat)]="centerLat"
    [(centerLng)]="centerLng" (centerChange)="onCenterChange($event)" (picChange)="onPicChange($event)"
    (buildingSelected)="onBuildingChange($event)" (buildingCleared)="onBuildingCleared()"
    [buildingPictures]="buildingPictures" [buildingPicturePath]="buildingPicturePath" [parent]="parent"
    [showPicSection]="false" [data]="acBuilding.infoSection" [lowLevelTitle]="'1 - General Info'"
    [building$]="building$"></kt-building-section>
<div *ngIf="isComplexForm">
    <div class="form-group kt-form__group row section-margin-top-60">
        <div class="col-lg-12 mb-0 multimedia-header item-center ">
            <h5 class="my-3 text-black text-uppercase">
                2 - Land Parcel
            </h5>
        </div>
        <div class="col-lg-12">
            <kt-parcel-identifaction-section [readonly]="readonly" [landAreaLowLevelTitle]="'3 - Land Area'" [centerLat]="centerLat"
                [centerLng]="centerLng" [data]="parcelIdentificationData"
                (checked)="onParcelChecked($event)">
            </kt-parcel-identifaction-section>
        </div>
    </div>
    <div class="form-group kt-form__group row section-margin-top-60">
        <div class="col-lg-12 mb-0 multimedia-header item-center ">
            <h5 class="my-3 text-black text-uppercase">
                {{hasParcelChecked ? '4' : '3'}} - Services and Infrastructures
            </h5>
        </div>
        <div class="col-lg-12">
            <kt-services-and-infrastructures [readonly]="readonly" [propertySubTypeID]="0" [isComplexForm]="isComplexForm"
                [serviceAndInfraData]="serviceAndInfraData">
            </kt-services-and-infrastructures>
        </div>
    </div>

    <div class="form-group kt-form__group row section-margin-top-60">
        <div class="col-lg-12 mb-0 multimedia-header item-center ">
            <h5 class="my-3 text-black text-uppercase">
                {{hasParcelChecked ? '5' : '4'}} - External Aspect
            </h5>
        </div>
        <div class="col-lg-12">
            <kt-external-aspects [readonly]="readonly" [propertySubTypeID]="0" [externalAspectData]="externalAspectData">
            </kt-external-aspects>
        </div>
    </div>

    <div class="form-group kt-form__group row section-margin-top-60">
        <div class="col-lg-12 mb-0 multimedia-header item-center ">
            <h5 class="my-3 text-black text-uppercase">
                {{hasParcelChecked ? '6' : '5'}} - Internal Aspect
            </h5>
        </div>
        <div class="col-lg-12">
            <kt-building-internal-aspects [readonly]="readonly" [data]="internalAspectData"></kt-building-internal-aspects>
        </div>
    </div>

    <div class="form-group kt-form__group row section-margin-top-60">
        <div class="col-lg-12 mb-0 multimedia-header item-center ">
            <h5 class="my-3 text-black text-uppercase">
                {{hasParcelChecked ? '7' : '6'}} - Grounds
            </h5>
        </div>
        <div class="col-lg-12">
            <kt-ac-grounds [readonly]="readonly" [isComplexForm]="isComplexForm" [propertySubTypeID]="0" [initData]="grounds"></kt-ac-grounds>
        </div>
    </div>

    <div class="form-group row section-margin-top-60">
        <div class="col-lg-12">
            <kt-ac-size [readonly]="readonly" [hideTitle]="true" [lowLevelTitle]="(hasParcelChecked ? '8' : '7') + ' - Sizes'" [sizesSubject]="sizesSubject"
                [assetClassType]="'0'"></kt-ac-size>
        </div>
    </div>
</div>