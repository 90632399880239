import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
import {
    AssetClassResidentialModel,
    AssetClassResidentialsDataSource,
    OneAssetClassResidentialDeleted,
    AssetClassResidentialDeletedFromTrash,
    AssetClassResidentialOnServerRestored,
    AssetClassResidentialsService,
    AssetClassMapPageRequested,
    ApartmentTrashFlushed,
    selectAdminTrashedAssetClassResidentialCount,
    selectTrashedAssetClassResidentialCount,
    AssetClassResidentialOnServerAdminRestored,
    AssetClassResidentialDeletedFromAdminTrash,
    selectAssetClassResidentialsLastQuery,
    AssetClassResidentialFacilitiesService
} from '../../../../../core/comparable';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, fromEvent, merge, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {SubheaderService} from '../../../../../core/_base/layout';
import {Location} from '@angular/common';
import {AssetClassResidentialActionToggleLoading, AssetClassResidentialDuplicate} from '../../../../../core/comparable/_actions/asset-class-residential.actions';
import {AllStandardMeasurementsRequested} from '../../../../../core/linked-tables';
import { FileUploadService } from 'src/app/core/file-upload';
import { emptyFilter, FilterChange, FilterModel } from '../../../shared_components/filter-section/filter-section.component';
import { User, currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
    selector: 'kt-apartments-list',
    templateUrl: './apartments-list.component.html',
    styleUrls: ['./apartments-list.component.scss']
})
export class ApartmentListComponent implements OnInit, OnDestroy {
    // Table fields
    dataSource: AssetClassResidentialsDataSource;
    displayedColumns = ['id', 'sub_type', 'building_id', 'size', 'consideration', 'source_date', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('fileInput') fileInput;
    filterStatus = '';
    filterType = '';
    filterModel: FilterModel = emptyFilter();
    filterCnt = 0;

    trashCnt = 0;
    adminTrashCnt = 0;
    fileType = 'application/json';

    menuItems = [
        {
            title: 'Import',
            icon: 'flaticon2-add',
            refId: 0,
            disabled: false
        },
        {
            title: 'Download Template',
            icon: 'flaticon2-download',
            refId: 1,
            disabled: false
        },
        {
            title: 'Export',
            icon: 'flaticon2-box-1',
            refId: 2,
            disabled: true
        }
    ];
    menuSubject = new BehaviorSubject<number>(-1);

    assignmentId: number;
    // assignment: AssignmentModel;

    // Selection
    assetClassResidentialResult: AssetClassResidentialModel[] = [];
    trashAssetClassResidentialResult: AssetClassResidentialModel[] = [];

    // Subscriptions
    private subscriptions: Subscription[] = [];

    // Last Query Params
    lastQueryParams: QueryParamsModel;

    filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
    filterObs$ = this.filter$.asObservable();
    canAccessAdminTrash$ = this.store.select(hasPermission(['admin_trash']))
    currentUser: User|null;

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param cookieService: CookieService
     * @param service: AssetClassResidentialsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public location: Location,
                public subheaderService: SubheaderService,
                // public cookieService: CookieService,
                public service: AssetClassResidentialsService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private ngZone: NgZone,
                private store: Store<AppState>,
                private fileUploadService: FileUploadService,
                private assetClassResidentialsService: AssetClassResidentialsService,
                private assetClassResidentialFacilitiesService: AssetClassResidentialFacilitiesService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const userSub = this.store.select(currentUser).subscribe(user => {
            this.currentUser = user;
        })
        this.subscriptions.push(userSub)
        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this._loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Init DataSource
        this.dataSource = new AssetClassResidentialsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.assetClassResidentialResult = res;
        });
        this.subscriptions.push(entitiesSubscription);


        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedAssetClassResidentialCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedAssetClassResidentialCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this._loadList();
        }); // Remove this line, just loading imitation

        // Last Query Params
        this.lastQueryParams = new QueryParamsModel({});
        const lastyQueryParamsSubscriptions = this.store.pipe(
            select(selectAssetClassResidentialsLastQuery),
            take(1)
        ).subscribe(res => {
            if (res.isEmpty() || res.filter.max_lat !== 0) {
                return;
            }
            this.lastQueryParams = res;
            this.updateFilter(res.filter);
        });
        this.subscriptions.push(lastyQueryParamsSubscriptions);

        this.store.dispatch(new AllStandardMeasurementsRequested());

        // menu observer
        const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
            switch (refId) {
                case 0:
                    this.addFiles();
                    break;
                case 1:
                    this.downloadJsonTemplate();
                    break;
                case 2:
                    // TODO: export comparables
                    break;
                default:
                    break;
            }
        });
        this.subscriptions.push(menuSubjectSubscribe);
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    onFilterChange(event: FilterChange) {
        if (event.page != null) {
            this.paginator.pageIndex = event.page
        }
        this.filterModel = event.filter;
        this._loadList();
    }

    private _loadList() {
        let queryParams = null;
        if (this.lastQueryParams.isEmpty()) {
            queryParams = new QueryParamsModel(
                this.filterModel,
                this.sort.direction,
                this.sort.active,
                this.paginator.pageIndex + 1,
                this.paginator.pageSize
            )
        } else {
            queryParams = this.lastQueryParams;
        }
        this.lastQueryParams = new QueryParamsModel({});
        this.store.dispatch(new AssetClassMapPageRequested({page: queryParams}));
    }

    /** ACTIONS */
    /**
     * Deleting comparabable residential into trash
     *
     * @param _item: AssetClassResidentialModel
     */
    deleteAssetClassResidential(_item: AssetClassResidentialModel) {
        this.store.dispatch(new OneAssetClassResidentialDeleted({id: _item.id}));
    }


    /**
     * Show add residential comparable dialog
     */
    addResidential() {
        this.editAssetClassResidential(null);
    }

    /**
     * Show Edit toe dialog and save after success close result
     * @param assetClassResidentialModel: AssetClassResidentialModel
     */
    editAssetClassResidential(assetClassResidentialModel: AssetClassResidentialModel) {
        if (assetClassResidentialModel) {
            this.router.navigate(['edit', assetClassResidentialModel.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
        }
    }

    back() {
        this.location.back();
    }


    trash() {
        this.store.dispatch(new AssetClassResidentialActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {
            this.store.dispatch(new AssetClassResidentialActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.residential_type_name}`,
                    id: elem.id.toString(),
                    date: elem.user_deleted,
                    deletedUser: elem.userDeletedBy,
                    hasPermanentlyDelete: true
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new AssetClassResidentialActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new AssetClassResidentialActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.residential_type_name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: elem.deletedBy,
                });
            });
            this.show_trash(items, true);
        });
    }

    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('APARTMENT.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('APARTMENT.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }

        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(_id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new AssetClassResidentialOnServerAdminRestored({id: _id}));
        } else {
            this.store.dispatch(new AssetClassResidentialOnServerRestored({id: _id}));
        }

    }

    delete(id: number) {
        this.store.dispatch(new AssetClassResidentialDeletedFromTrash({assetClassResidentialId: id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new AssetClassResidentialDeletedFromAdminTrash({id}));
    }


    flush() {
        this.store.dispatch(new ApartmentTrashFlushed());
    }


    goMap() {
        this.router.navigate(['map'], {relativeTo: this.activatedRoute});
    }

    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'info';
            default:
                return 'success';
        }
    }
    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 2:
                return 'imported';
            default:
                return 'Validated';
        }
    }

    duplicate(id: number) {
        this.store.dispatch(new AssetClassResidentialDuplicate({id}));
    }
    /**
     * Returns object for filter
     */

    updateFilter(filter: FilterModel) {
        this.filterModel = filter;
        this.filter$.next(this.filterModel);
    }
    addFiles() {
        this.fileInput.nativeElement.click();
    }
    
    importComparable(event: any) {
        const file: File = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.fileUploadService.uploadCompJson(formData).subscribe(
            (res) => {
                if (!res) {
                    return;
                }
                if (res.success) {
                    this._loadList();
                }
            },
            (err) => console
        );
    }
    downloadJsonTemplate() {
        this.fileUploadService.compTemplateDownload(1).subscribe(res => {
                if (res.type == 4) {
                    let blob = new Blob([res.body], { type: 'application/json'});
                    let url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement("a");
                    anchor.download = "interval-comp-apartment-template.json";
                    anchor.href = url;
                    anchor.click();
                }
            },
            err => {
                alert("Problem while downloading the file.");
            }
        );
    }
}
