// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AgencyActionTypes, AgencyActions} from '../_actions/agency.actions';
// Models
import {AgencyModel} from '../';
import {QueryParamsModel} from '../../_base/crud';

export interface AgenciesState extends EntityState<AgencyModel> {
    _isAllAgenciesLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    lastCreatedAgencyId: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AgencyModel> = createEntityAdapter<AgencyModel>();

export const initialAgenciesState: AgenciesState = adapter.getInitialState({
    _isAllAgenciesLoaded: false,
    listLoading: false,
    actionsLoading: false,
    lastCreatedAgencyId: undefined,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function agenciesReducer(state = initialAgenciesState, action: AgencyActions): AgenciesState {
    switch (action.type) {
        case AgencyActionTypes.AgenciesPageRequested:
            return {
                ...state,
                _isAllAgenciesLoaded: false
            };
        case AgencyActionTypes.AgenciesPageLoaded:
            return adapter.addAll(action.payload.items, {
                ...state,
                _isAllAgenciesLoaded: true,
                listLoading: false,
                showInitWaitingMessage: false,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                lastQuery: action.payload.page
            });


        case AgencyActionTypes.AgenciesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAgencyId: undefined
            };
        }
        case AgencyActionTypes.AgencyActionToggleLoading: {
            return {
                ...state, actionsLoading: action.payload.isLoading
            };
        }

        case AgencyActionTypes.AgencyDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });


        case AgencyActionTypes.AgencyTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case AgencyActionTypes.AgencyRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case AgencyActionTypes.AgencyDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case AgencyActionTypes.AgencyAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AgencyActionTypes.AgencyDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case AgencyActionTypes.AgencyOnServerCreated:
            return {
                ...state
            };

        case AgencyActionTypes.AgencyCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAgencyId: action.payload.item.id
            });

        case AgencyActionTypes.AgencyUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
