import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators';
import { FileUploadService, SignedToeDocModel } from 'src/app/core/file-upload';
import { SignedInformedConsentModel, SignedToeAmendmentModel } from 'src/app/core/file-upload/_models/signed-toe-doc.model';
import { ToeReportService } from 'src/app/core/toe';
import { InformedConsentReportService } from 'src/app/core/toe/_services/informed-consent-report.service';
import { ToeAmendmentService } from 'src/app/core/toe/_services/toe-amendment.service';
import { HttpExtenstionsModel, LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [ComponentStore]
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  header: string;
  uploadedFiles: any[] = [];

  dataSource = new MatTableDataSource();
  displayedColumns = ['upload_date', 'size', 'upload_by', 'actions'];
  docs = ['xlsx', 'xls', 'doc', 'docx'];
  basePath = environment.baseApiUrl;

  error$: Observable<string>;
  completed$: Observable<boolean>;
  progress$: Observable<number>;
  isInProgress$: Observable<boolean>;
  isReady$: Observable<boolean>;
  hasFailed$: Observable<boolean>;
  uploadedFiles$: Observable<any[]>;
  cancelEvent$: Subject<void> = new Subject();

  currentSignedDocID$ = new BehaviorSubject<number>(0);

  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly componentStore: ComponentStore<UploadFileState>,
    public typesUtilsService: TypesUtilsService,
    private fileUploadService: FileUploadService,
    private layoutUtilsService: LayoutUtilsService,
    private dialog: MatDialog,
    private toeReportService: ToeReportService,
    private informedConsentReportService: InformedConsentReportService,
    private translate: TranslateService,
    private toeAmendmentService: ToeAmendmentService
  ) { }

  ngOnInit(): void {
    this.header = this.data.title;
    const sorted = this.data.files;// httpExtensionMode.sortArrayDateTime(this.data.files, 'created_at', 'desc');
    sorted.forEach(el => {
      if (el.checked) {
        this.currentSignedDocID$.next(el.id);
      }
    })
    this.componentStore.setState({
      uploadedFiles: sorted,
      error: null,
      progres: null,
      status: UploadFileStatus.Ready
    });
    this.completed$ = this.componentStore.select(state => state.status === UploadFileStatus.Completed);
    this.error$ = this.componentStore.select(state => state.error);
    this.progress$ = this.componentStore.select(state => state.progres);
    this.isInProgress$ = this.componentStore.select(state => state.status === UploadFileStatus.Started && state.progres >= 0);
    this.isReady$ = this.componentStore.select(state => state.status === UploadFileStatus.Ready);
    this.hasFailed$ = this.componentStore.select(state => state.status === UploadFileStatus.Failed);
    this.uploadedFiles$ = this.componentStore.select(state => state.uploadedFiles);
    combineLatest([
      this.uploadedFiles$,
      this.currentSignedDocID$
    ]).subscribe(([files, id]) => {
      if (files) {
        const fs = files.map(f => {
          const nf = Object.assign({}, f);
          if (nf.id == id) {
            nf.checked = 1;
          } else {
            nf.checked = 0;
          }
          return nf;
        })
        fs.sort((a, b) => {
          const d1 = new Date(b.created_at);
          const d2 = new Date(a.created_at);
          return d1.getTime() - d2.getTime();
        })
        this.dataSource.data = fs;
        this.uploadedFiles = fs
      }
    })
  }

  onNoClick() {
    this.dialogRef.close(this.uploadedFiles);
  }

  onAddClick() {
    this.fileInput.nativeElement.click();
  }

  cancelUpload() {
    this.cancelEvent$.next();
    this.uploadCancel();
  }

  uploadFiles(event) {
    const files: FileList = event.target.files;
    
    const formData = new FormData();
    Array.from(files).forEach((file, i) => {
      formData.append('files[' + i + ']', file, file.name);
    });

    this.uploadFile(formData);

    event.srcElement.value = null;
  }

  // editFileDescription(file) {
  //   const _title = 'Adding Description';
  //   const _description = '';
  //   const _waitDesciption = '';
  //   const _fileTitle = file.title ? file.title : '';
  //   const _fileDescription = file.descr ? file.descr : '';

  //   const dialogRef = this.layoutUtilsService.saveElementWithInfo(_title,
  //       _description,
  //       _waitDesciption,
  //       _fileTitle,
  //       true,
  //       _fileDescription);
  //   dialogRef.afterClosed().subscribe(res => {
  //       if (!res) {
  //           return;
  //       }
  //       const index = this.uploadedFiles.indexOf(file);
  //       const editedObj = Object.assign({}, file);
  //       editedObj.title = res.title;
  //       editedObj.descr = res.descr;
  //       editedObj._isEditMode = true;
  //       this.uploadedFiles = Object.assign([], this.uploadedFiles, {[index]: editedObj});
  //       this.componentStore.setState((state) => {
  //         return {
  //           ...state,
  //           uploadedFiles: this.uploadedFiles
  //         }
  //       })
  //   });
  // }
  deleteUploadedFile(file) {
    this.componentStore.setState((state) => {
      let currentUploadedFiles = Object.assign([], state.uploadedFiles);
      const indx = currentUploadedFiles.findIndex(f => f.id == file.id);
      if (indx >= 0) {
        currentUploadedFiles.splice(indx, 1);
      }
      return {
        ...state,
        uploadedFiles: currentUploadedFiles
      }
    })
  }
  previewUploadedFile(report) {
    if (this.docs.indexOf(this.typesUtilsService.getFileType(report.url)) > -1) {
        window.open(environment.baseApiUrl + report.url);
    } else {
        const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
            data: {
                picture: report.url,
                type: this.typesUtilsService.getFileType(report.url)
            }
        });
    }
  }
  changeSignedDoc($event, id) {
    $event.preventDefault()
    const _title = this.translate.instant('TOE.PROMPT');
    const _description = this.translate.instant('TOE.SIGNED_REPORT.CHANGE_REPORT_DESCRIPTION');
    const _waitDesciption = this.translate.instant('TOE.SIGNED_REPORT.PROMPT_WAIT_CHANGE');

    const dialogRef = this.layoutUtilsService.saveElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
        if (!res) {
            return;
        }
        if (this.data.taskID && this.data.taskID == 7) {
          this.toeReportService.updateToeSignedReport(this.data.toeID, id)
              .pipe(take(1))
              .subscribe(
                  (res) => {
                  });
        } else if (this.data.taskID && this.data.taskID == 8) {
          this.informedConsentReportService.updateSignedReport(this.data.toeID, id).pipe(take(1)).subscribe(() => {});
        }
        this.currentSignedDocID$.next(id);
    });
  }

  private handleHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        this.uploadStarted();
        return;
      }
      case HttpEventType.UploadProgress: {
        this.uploadProgress(Math.round((100 * event.loaded) / event.total))
        return;
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          let response_body;
          if (event.type == HttpEventType.Response) {
            response_body = event.body;
          }
          this.uploadCompleted(response_body);
          return;
        }
        if (event.status == 400) {
          this.uploadFailure('Bad Request, Please check your form data.')
          return;
        }
        if (event.status === 403) {
          this.uploadFailure('The Server is refusing action, Please check your form data.')
          return;
        }
        if (event.status === 404) {
          this.uploadFailure('Not found, API have not developed yet.')
          return;
        }
        if (event.status === 500) {
          this.uploadFailure('Internal Server Error, Please inform us by email.')
          return;
        }
        if (event.status >= 500) {
          this.uploadFailure(`Server error ${event.status}, Please inform us by email`)
          return;
        }
        if (event.status >= 400) {
          this.uploadFailure(`Client error ${event.status}, Please check your inserted data`)
          return;
        }
        this.uploadFailure(`Request error ${event.status}, Please check action`)
        return;
      }
      default: {
        return;
      }
    }
  }

  private uploadFile = this.componentStore.effect((formData$: Observable<FormData>) => {
    return formData$.pipe(
      concatMap(formdata => 
        this.fileUploadService.uploadFile(formdata).pipe(
          takeUntil(
            this.cancelEvent$
          ),
          map(event => this.handleHttpEvent(event)),
          catchError(error => {
            this.uploadFailure(`Too large file. Max file upload is: 16mb.`);
            return EMPTY
          })
        ))
    )
  });

  private uploadStarted() {
    this.componentStore.setState((state) => {
      return {
        ...state,
        status: UploadFileStatus.Started,
        progres: 0
      }
    })
  }
  private uploadProgress(progress: number) {
    this.componentStore.setState((state) => {
      return {
        ...state,
        progres: progress
      }
    })
  }
  private uploadCompleted(res: any) {
    const uploadedFiles: any[] = [];
    let uploadedFile = null;
    if (res) {
      uploadedFile = res.uploaded_files[0];
    }
    switch (this.data.taskID) {
      case 7:
        if (uploadedFile) {
          const item = new SignedToeDocModel();
          item.clear();
          item.toe_id = this.data.toeID;
          item.checked = 1;
          item.url = uploadedFile.path + '/' + uploadedFile.name;
          item.file_name = uploadedFile.name;
          item.user_id = this.data.userID;
          item.file_size = this.humanReadableSize(uploadedFile.size)
          item.has_altered = 0;
          item.details = '';
          this.toeReportService.createToeSignedReportV2(item).subscribe(result => {
            uploadedFiles.push(result.data);
            this.currentSignedDocID$.next(result.data.id);
            this.componentStore.setState((state) => {
              state.uploadedFiles.forEach(f => {
                uploadedFiles.push(f)
              });
              return {
                ...state,
                status: UploadFileStatus.Completed,
                progres: 100,
                error: null,
                uploadedFiles
              }
            })
          })
        }
        break;
      case 8:
        if (uploadedFile) {
          const item = new SignedInformedConsentModel();
          item.clear();
          item.toe_id = this.data.toeID;
          item.checked = 1;
          item.url = uploadedFile.path + '/' + uploadedFile.name;
          item.file_name = uploadedFile.name;
          item.user_id = this.data.userID;
          item.file_size = this.humanReadableSize(uploadedFile.size);
          this.informedConsentReportService.createSignedReport(item, this.data.taskDBID).subscribe(result => {
            uploadedFiles.push(result.data);
            this.currentSignedDocID$.next(result.data.id);
            this.componentStore.setState((state) => {
              state.uploadedFiles.forEach(f => uploadedFiles.push(f));
              return {
                ...state,
                status: UploadFileStatus.Completed,
                progres: 100,
                error: null,
                uploadedFiles
              }
            })
          })
        }
        break;
      case 9:
        if (uploadedFile) {
          const item = new SignedToeAmendmentModel();
          item.clear();
          item.toe_id = this.data.toeID;
          item.checked = 1;
          item.url = uploadedFile.path + '/' + uploadedFile.name;
          item.file_name = uploadedFile.name;
          item.user_id = this.data.userID;
          item.file_size = this.humanReadableSize(uploadedFile.size);
          this.toeAmendmentService.createSignedReport(item, this.data.taskDBID).subscribe(result => {
            uploadedFiles.push(result.data);
            this.currentSignedDocID$.next(result.data.id);
            this.componentStore.setState((state) => {
              state.uploadedFiles.forEach(f => uploadedFiles.push(f));
              return {
                ...state,
                status: UploadFileStatus.Completed,
                progres: 100,
                error: null,
                uploadedFiles
              }
            })
          })
        }
        break;

      default:
        break;
    }
  }
  private uploadFailure(error: string) {
    this.componentStore.setState((state) => {
      return {
        ...state,
        status: UploadFileStatus.Failed,
        error,
        progres: null,
      }
    })
  }
  private uploadCancel() {
    this.componentStore.setState((state) => {
      return {
        ...state,
        status: UploadFileStatus.Ready,
        progres: null,
        error: null
      }
    })
  }

  private humanReadableSize(bytes: number, dp: number = 1): string {
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }
}
export interface UploadFileState {
  status: UploadFileStatus;
  uploadedFiles: any[];
  error: string | null;
  progres: number | null;
}

export enum UploadFileStatus {
  Ready = 'Ready',
  Failed = 'Failed',
  Requested = 'Requested',
  Started = 'Started',
  Completed = 'Completed'
}
