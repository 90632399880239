import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kt-mad-auth',
  templateUrl: './mad-auth.component.html',
  styleUrls: ['./mad-auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MadAuthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
