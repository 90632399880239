import { ValuationProcessValuationActions, ValuationProcessValuationActionTypes } from "../_actions/valuation.actions"
import * as _ from 'lodash'

export interface ValuationProcessValuationState {
    weightings: {[key: string]: number},
    totalWeighting: number,
    justification: string,
    capitalAllowance: number
    capitalAllowanceJustification: string
}

export const initialValuationProcessValuationState: ValuationProcessValuationState = {
    weightings: {},
    totalWeighting: 0,
    justification: null,
    capitalAllowance: 0,
    capitalAllowanceJustification: null
}

export function valuationProcessValuationReducer(state = initialValuationProcessValuationState, action: ValuationProcessValuationActions): ValuationProcessValuationState {
    switch (action.type) {
        case ValuationProcessValuationActionTypes.LoadState: {
            let weightings = action.payload.state.weightings;
            if (Array.isArray(weightings) && weightings.length == 0) {
                weightings = {}
            }
            return {...action.payload.state, weightings}
        }
        case ValuationProcessValuationActionTypes.AddComparable: {
            const weightings = _.cloneDeep(state.weightings)
            weightings[action.payload.refNum] = 0
            return {
                ...state,
                weightings
            }
        }
        case ValuationProcessValuationActionTypes.AddMultipleComparables: {
            let weightings = action.payload.adjustmentTabData.valuation.weightings;
            if (Array.isArray(weightings) && weightings.length == 0) {
                weightings = {}
            }
            return {...action.payload.adjustmentTabData.valuation, weightings}
        }
        case ValuationProcessValuationActionTypes.RemoveComparable: {
            const weightings = _.cloneDeep(state.weightings)
            delete weightings[action.payload.refNum]
            return {
                ...state,
                weightings,
                totalWeighting: computeTotalWeighting(weightings)
            }
        }
        case ValuationProcessValuationActionTypes.UpdateWeightingValue: {
            const weightings = _.cloneDeep(state.weightings)
            weightings[action.payload.refNum] = action.payload.value
            return {
                ...state,
                weightings,
                totalWeighting: computeTotalWeighting(weightings)
            }
        }
        case ValuationProcessValuationActionTypes.UpdateJustification: {
            return {
                ...state,
                justification: action.payload.value
            }
        }
        case ValuationProcessValuationActionTypes.UpdateCapitalAllowance: {
            return {
                ...state,
                capitalAllowance: action.payload.value
            }
        }
        case ValuationProcessValuationActionTypes.UpdateCapitalAllowanceJustification: {
            return {
                ...state,
                capitalAllowanceJustification: action.payload.value
            }
        }
        case ValuationProcessValuationActionTypes.ResetState: {
            return initialValuationProcessValuationState
        }
        case ValuationProcessValuationActionTypes.UnimportValuation: {
            return {
                ...state,
                capitalAllowance: 0,
                capitalAllowanceJustification: ''
            }
        }
        default: 
            return state
    }
}

function computeTotalWeighting(weightings: {[key: string]: number}): number {
  return Object.values(weightings).reduce((acc, v) => acc + v, 0)
}