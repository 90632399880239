import {BaseModel} from '../../_base/crud';

export class AcSource extends BaseModel {
    id: number;
    source_information_id: number;
    source_information_name: string;
    source_type_id: number;
    source_type_name: string;
    source_credibility_id: number;
    source_credibility_name: string;

    validation_source: string;
    web_address: string;

    source_picture_1: string;
    source_picture_2: string;

    information_date: string;
    transaction_date: string;
    source_date: string;

    clear() {

        this.source_information_name = '';
        this.source_information_id = undefined;

        this.source_type_name = '';
        this.source_type_id = undefined;

        this.source_credibility_id = undefined;
        this.source_credibility_name = '';

        this.validation_source = '';
        this.web_address = '';

        this.source_picture_1 = '';
        this.source_picture_2 = '';

        this.information_date = '';
        this.transaction_date = '';
        this.source_date = '';
    }
}
