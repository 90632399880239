import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgApexchartsModule} from 'ng-apexcharts';
// NgBootstrap
import {NgbDropdownModule, NgbModule, NgbPopoverModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// Core module
import {CoreModule} from '../../core/core.module';
// CRUD Partials
import {
    ActionNotificationComponent,
    AlertComponent,
    DeleteEntityDialogComponent,
    DeleteAgencyDialogComponent,
    DeleteEntityWithDescrDialogComponent,
    EditPointDialogComponent,
    FetchEntityDialogComponent,
    SaveEntityDialogComponent,
    SaveEntityWithDescrDialogComponent,
    SaveEntityWithoutDescrDialogComponent,
    TermsDialogComponent,
    TrashAuditTrailDialogComponent,
    TrashDialogComponent,
    UpdateStatusDialogComponent,
} from './content/crud';
// Layout partials
import {
    ContextMenu2Component,
    ContextMenuComponent,
    ContextMenuMadComponent,
    ContextMenuMultimediaComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    SubheaderSearchComponent,
    UserProfile2Component,
    UserProfile3Component,
    UserProfileComponent,
    UserProfile4Component,
} from './layout';
import {
    Widget1SalesStatComponent,
    Widget9RecentActivitiesComponent,
    Widget12NewUsersComponent,
    Widget7WeeklySalesComponent,
    Widget1TasksOverviewComponent,
    Widget2NewArrivalsComponent,
    Widget3NewArrivalsAuthorsComponent,
    Widget4TodoComponent,
    Widget8TrendsComponent,
    Dropdown1Component,
    Dropdown2Component,
    Dropdown3Component,
    Dropdown4Component,
    Dropdown5Component
} from './content/dashboard-widgets';
// General
import {NoticeComponent} from './content/general/notice/notice.component';
import {PortletModule} from './content/general/portlet/portlet.module';
// Extra module
import {WidgetModule} from './content/widgets/widget.module';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
import {CartComponent} from './layout/topbar/cart/cart.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatNativeDateModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../../environments/environment';
import {CustomPipesModule} from '../theme/pipes/custom-pipes.module';
import { DocumentInfoEditDialogComponent } from './content/crud/document-info-edit-dialog/document-info-edit-dialog.component';
import { CertificateEditDialogComponent } from './content/crud/certificate-edit-dialog/certificate-edit-dialog.component';
import {ErrorDialogComponent} from './content/crud/error-dialog/error-dialog.component';
import {PercentageBottomSheet} from './content/crud/percentage-bottom-sheet/percentage-bottom-sheet.component';
import {MatSliderModule} from '@angular/material/slider';
import { EditEntityDialogComponent } from './content/crud/edit-entity-dialog/edit-entity-dialog.component';

@NgModule({
    declarations: [
        ScrollTopComponent,
        NoticeComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        DeleteAgencyDialogComponent,

        PercentageBottomSheet,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertComponent,

        ContextMenuMadComponent,
        ContextMenuMultimediaComponent,
        ContextMenu2Component,
        ContextMenuComponent,
        QuickPanelComponent,
        QuickUserPanelComponent,
        ScrollTopComponent,
        SearchResultComponent,
        SplashScreenComponent,
        StickyToolbarComponent,
        Subheader1Component,
        Subheader2Component,
        Subheader3Component,
        SubheaderSearchComponent,
        LanguageSelectorComponent,
        NotificationComponent,
        QuickActionComponent,
        SearchDefaultComponent,
        SearchDropdownComponent,
        UserProfileComponent,
        UserProfile2Component,
        UserProfile3Component,
        UserProfile4Component,
        CartComponent,
        DeleteEntityWithDescrDialogComponent,
        SaveEntityDialogComponent,
        SaveEntityWithDescrDialogComponent,
        SaveEntityWithoutDescrDialogComponent,
        TrashDialogComponent,
        TrashAuditTrailDialogComponent,
        TermsDialogComponent,
        ActionNotificationComponent,
        EditPointDialogComponent,
        DocumentInfoEditDialogComponent,
        ErrorDialogComponent,
        CertificateEditDialogComponent,
        EditEntityDialogComponent
    ],
    exports: [
        WidgetModule,
        PortletModule,

        ScrollTopComponent,
        NoticeComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        DeleteAgencyDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertComponent,

        // topbar components
        ContextMenu2Component,
        ContextMenuComponent,
        ContextMenuMadComponent,
        ContextMenuMultimediaComponent,
        QuickPanelComponent,
        QuickUserPanelComponent,
        ScrollTopComponent,
        SearchResultComponent,
        SplashScreenComponent,
        StickyToolbarComponent,
        Subheader1Component,
        Subheader2Component,
        Subheader3Component,
        SubheaderSearchComponent,
        LanguageSelectorComponent,
        NotificationComponent,
        QuickActionComponent,
        SearchDefaultComponent,
        SearchDropdownComponent,
        UserProfileComponent,
        UserProfile2Component,
        UserProfile3Component,
        UserProfile4Component,
        CartComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        InlineSVGModule,
        CoreModule,
        PortletModule,
        WidgetModule,
        NgApexchartsModule,
        // angular material modules
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatSliderModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        MatListModule,

        // ng-bootstrap modules
        NgbModule,
        NgbPopoverModule,

        NgbDropdownModule,
        NgbTabsetModule,
        NgbTooltipModule,
        CustomPipesModule,

        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
    ],
    providers: [
        MatDialogConfig,
    ]
})
export class PartialsModule {
}
