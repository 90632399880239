import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'kt-poly-lines',
    templateUrl: './poly-lines.component.html',
    styleUrls: ['./poly-lines.component.scss'],
})
export class PolyLinesComponent implements OnInit, OnDestroy {

    @Input() polySubject: BehaviorSubject<any[]>;
    @Input() selectedAreaSubject: BehaviorSubject<number>;
    @Input() staticUnit: number;
    @Input() staticUnitName: string;
    @Input() staticUnitLengthName: string;

    convertedAreaSubject: any;
    imperial_area = 10.7639;
    imperial_length = 3.28084;
    displayedColumns = ['index', 'lat', 'lng', 'length'];
    dataSource = new MatTableDataSource();
    uploadFiles: any[];


    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
                    this.uploadFiles = [];
    }

    ngOnInit() {
        if (this.selectedAreaSubject) {
            this.selectedAreaSubject.asObservable().subscribe(res => {
                this.convertedAreaSubject = res //m2
                if (this.staticUnit == 2) { //sqft
                    this.convertedAreaSubject = res * this.imperial_area
                }
            })
        }
        this.polySubject.asObservable().subscribe(res => {
            this.uploadFiles = Object.assign([], res);
            this.dataSource.data = this.uploadFiles;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }
}
