import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassRetailShopModel } from "../_models/asset-class-retail-shop.model";

export enum AssetClassRetailShopActionTypes {
    AssetClassRetailShopActionToggleLoading = '[AssetClassRetailShop Effect] AssetClassRetailShop Action Toggle Loading',

    AssetClassRetailShopOnServerCreated = '[Edit AssetClassRetailShop page] AssetClassRetailShop On Server Created',
    AssetClassRetailShopCreated = '[AssetClassRetailShop Effect] AssetClassRetailShop Created',
    
    AssetClassRetailShopOnServerUpdated = '[Edit AssetClassRetailShop page] AssetClassRetailShop On Server Updated',
    AssetClassRetailShopUpdated = '[AssetClassRetailShop Effect] AssetClassRetailShop Updated',

    AssetClassRetailShopDeleted = '[AssetClassRetailShop List Page] Asset Class Land Deleted',
    AssetClassRetailShopDeletedFromTrash = '[AssetClassRetailShop Trash Modal] Asset Class Land Deleted from Trash',
    AssetClassRetailShopDeletedFromAdminTrash = '[AssetClassRetailShop Trash Modal] Asset Class House Deleted from Admin Trash',
    AssetClassRetailShopTrashFlushed = '[AssetClassRetailShop Trash Modal] Asset Class Retail Shop Trash Flushed',

    AssetClassRetailShopOnServerRestored = '[AssetClassRetailShop Trash Modal] Asset Class Land Restored',
    AssetClassRetailShopRestored = '[AssetClassRetailShop Effect] Asset Class Land Restored',
    AssetClassRetailShopOnServerAdminRestored = '[AssetClassRetailShop Admin Trash Modal] Asset Class Land Admin Restored',
    AssetClassRetailShopAdminRestored = '[AssetClassRetailShop Effect] Asset Class Land Admin Restored',

    AssetClassRetailShopPageToggleLoading = '[AssetClassRetailShop Effect] AssetClassRetailShop Page Toggle Loading',
    AssetClassRetailShopPageRequested = '[AssetClassRetailShop List Page] All AssetClassRetailShop requested',
    AssetClassRetailShopPageLoaded = '[AssetClassRetailShop Effect] All AssetClassRetailShop loaded',

    UpdatePreviouslyCreatedACId = '[AssetClassRetailShop Edit] Update Previously Created AssetClassRetailShop Id',

    DuplicateAssetClass = '[AssetClassRetailShop List Page] Duplciate AssetClassRetailShop'
}

export class AssetClassRetailShopOnServerCreated implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerCreated;
    constructor(public payload: {
        AssetClassRetailShop: AssetClassRetailShopModel,
        fileList: any[],
    }) {}
}

export class AssetClassRetailShopCreated implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopCreated;
    constructor(public payload: {AssetClassRetailShop: AssetClassRetailShopModel}) {}
}

export class AssetClassRetailShopOnServerUpdated implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerUpdated;
    constructor(public payload: {
        AssetClassRetailShop: AssetClassRetailShopModel,
        fileList: any[] 
    }) {}
}

export class AssetClassRetailShopUpdated implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopUpdated;
    constructor(public payload: {AssetClassRetailShop: AssetClassRetailShopModel}) {}
}

export class AssetClassRetailShopPageRequested implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopPageRequested;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class AssetClassRetailShopPageLoaded implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopPageLoaded;
    constructor(public payload: {
        AssetClassRetailShops: AssetClassRetailShopModel[],
        totalCount: number;
        totalTrashed: number;
        totalAdminTrashed: number;
        page: QueryParamsModel
    }) {}
}

export class AssetClassRetailShopDeleted implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopDeleted;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailShopDeletedFromTrash implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopDeletedFromTrash;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailShopOnServerRestored implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailShopRestored implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopRestored;
    constructor(public payload: {ac: AssetClassRetailShopModel}) {}
}

export class AssetClassRetailShopOnServerAdminRestored implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerAdminRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassRetailShopAdminRestored implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopAdminRestored;
    constructor(public payload: {ac: AssetClassRetailShopModel}) {}
}

export class AssetClassRetailShopPageToggleLoading implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopPageToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassRetailShopActionToggleLoading implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassRetailShopDeletedFromAdminTrash implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassRetailShopTrashFlushed implements Action {
    readonly type = AssetClassRetailShopActionTypes.AssetClassRetailShopTrashFlushed;
    constructor() {}
}

export class UpdatePreviouslyCreatedACRetailShopId implements Action {
    readonly type = AssetClassRetailShopActionTypes.UpdatePreviouslyCreatedACId;
    constructor() {}
}

export class AssetClassRetailShopDuplicate implements Action {
    readonly type = AssetClassRetailShopActionTypes.DuplicateAssetClass
    constructor(public payload: {id: number}) {}
}


export type AssetClassRetailShopActions 
    = AssetClassRetailShopOnServerCreated
    | AssetClassRetailShopCreated

    | AssetClassRetailShopOnServerUpdated
    | AssetClassRetailShopUpdated

    | AssetClassRetailShopPageRequested
    | AssetClassRetailShopPageLoaded

    | AssetClassRetailShopDeleted
    | AssetClassRetailShopDeletedFromTrash
    | AssetClassRetailShopDeletedFromAdminTrash
    | AssetClassRetailShopTrashFlushed

    | AssetClassRetailShopOnServerRestored
    | AssetClassRetailShopOnServerAdminRestored
    | AssetClassRetailShopRestored
    | AssetClassRetailShopAdminRestored
    
    | AssetClassRetailShopPageToggleLoading
    | AssetClassRetailShopActionToggleLoading
    | UpdatePreviouslyCreatedACRetailShopId
    
    | AssetClassRetailShopDuplicate;