import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
import {
    AssetClassOfficeModel,
    AssetClassOfficesDataSource,
    OneAssetClassOfficeDeleted,
    AssetClassOfficeDeletedFromTrash,
    AssetClassOfficeOnServerRestored,
    AssetClassOfficeOnServerAdminRestored,
    AssetClassOfficesService,
    AssetClassOfficeMapPageRequested,
    OfficeTrashFlushed,
    selectTrashedAssetClassOfficeCount,
    selectAdminTrashedAssetClassOfficeCount,
    AssetClassOfficeDeletedFromAdminTrash,
    selectAssetClassOfficeLastQuery,
} from '../../../../../core/comparable';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, fromEvent, merge, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {SubheaderService} from '../../../../../core/_base/layout';
import {Location} from '@angular/common';
import {FilterOfficeDialogComponent} from '../../_sub/filter-office-dialog/filter-office-dialog.component';
import {AssetClassOfficeActionToggleLoading, AssetClassOfficeDuplicate} from '../../../../../core/comparable/_actions/asset-class-office.actions';
import {AllStandardMeasurementsRequested, AllUnitAreaMeasurementsRequested} from '../../../../../core/linked-tables';
import { FileUploadService } from 'src/app/core/file-upload';
import { emptyFilter, FilterChange, FilterModel } from '../../../shared_components/filter-section/filter-section.component';
import { User, currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
    selector: 'kt-offices-list',
    templateUrl: './offices-list.component.html',
    styleUrls: ['./offices-list.component.scss']
})
export class OfficeListComponent implements OnInit, OnDestroy {
    // Table fields
    dataSource: AssetClassOfficesDataSource;
    displayedColumns = ['id',
        'sub_type',
        'building_id',
        'size',
        'consideration',
        'source_date',
        'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('fileInput') fileInput;
    filterStatus = '';
    filterType = '';
    filterModel: FilterModel = emptyFilter();
    filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
    filterObs$ = this.filter$.asObservable();
    filterCnt = 0;

    trashCnt = 0;
    adminTrashCnt = 0;
    fileType = 'application/json';

    menuItems = [
        {
            title: 'Import',
            icon: 'flaticon2-add',
            refId: 0,
            disabled: false
        },
        {
            title: 'Download Template',
            icon: 'flaticon2-download',
            refId: 1,
            disabled: false
        },
        {
            title: 'Export',
            icon: 'flaticon2-box-1',
            refId: 2,
            disabled: true
        }
    ];
    menuSubject = new BehaviorSubject<number>(-1);

    assignmentId: number;
    // assignment: AssignmentModel;

    // Selection
    assetClassOfficeResult: AssetClassOfficeModel[] = [];
    trashAssetClassOfficeResult: AssetClassOfficeModel[] = [];

    // Subscriptions
    private subscriptions: Subscription[] = [];

    // Last Query Params
    lastQueryParams: QueryParamsModel;
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
    currentUser: User|null

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param cookieService: CookieService
     * @param service: AssetClassOfficesService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param ngZone
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public location: Location,
                public subheaderService: SubheaderService,
                // public cookieService: CookieService,
                public service: AssetClassOfficesService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private ngZone: NgZone,
                private store: Store<AppState>,
                private fileUploadService: FileUploadService
                ) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
        this.subscriptions.push(userSub)
        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadAssetClassOfficesList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.store.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store.dispatch(new AllStandardMeasurementsRequested());

        // Init DataSource
        this.dataSource = new AssetClassOfficesDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.assetClassOfficeResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedAssetClassOfficeCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedAssetClassOfficeCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadAssetClassOfficesList();
        }); // Remove this line, just loading imitation


        // Last Query Params
        this.lastQueryParams = new QueryParamsModel({});
        const lastyQueryParamsSubscriptions = this.store.pipe(
            select(selectAssetClassOfficeLastQuery),
            take(1)
        ).subscribe(res => {
            if (res.isEmpty() || res.filter.max_lat !== 0) {
                return;
            }
            this.lastQueryParams = res;
            this.updateFilter(res.filter);
        });
        this.subscriptions.push(lastyQueryParamsSubscriptions);

        // menu observer
        const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
            switch (refId) {
                case 0:
                    this.addFiles();
                    break;
                case 1:
                    this.downloadJsonTemplate();
                    break;
                case 2:
                    // TODO: export comparables
                    break;
                default:
                    break;
            }
        });
        this.subscriptions.push(menuSubjectSubscribe);
    }

    onFilterChange(event: FilterChange) {
        if (event.page != null) {
            this.paginator.pageIndex = event.page
        }
        this.filterModel = event.filter;
        this.loadAssetClassOfficesList();
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Load AssetClassOffices List from service through data-source
     */
    loadAssetClassOfficesList() {
        let queryParams = null;
        if (this.lastQueryParams.isEmpty()) {
            const _filter = Object.assign({}, this.filterModel) as FilterModel;
            queryParams = new QueryParamsModel(
                _filter,
                this.sort.direction,
                this.sort.active,
                this.paginator.pageIndex + 1,
                this.paginator.pageSize
            );
        } else {
            queryParams = this.lastQueryParams;
        }
        this.lastQueryParams = new QueryParamsModel({});
        // Call request from server
        this.store.dispatch(new AssetClassOfficeMapPageRequested({page: queryParams}));
    }

    /** ACTIONS */
    /**
     * Deleting comparabable office into trash
     *
     * @param _item: AssetClassOfficeModel
     */
    deleteAssetClassOffice(_item: AssetClassOfficeModel) {
        this.store.dispatch(new OneAssetClassOfficeDeleted({id: _item.id}));
    }


    /**
     * Show add office comparable dialog
     */
    addOffice() {
        this.editAssetClassOffice(null);
    }

    /**
     * Show Edit toe dialog and save after success close result
     * @param assetClassOfficeModel: AssetClassOfficeModel
     */
    editAssetClassOffice(assetClassOfficeModel: AssetClassOfficeModel) {
        if (assetClassOfficeModel) {
            this.router.navigate(['edit', assetClassOfficeModel.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
        }
    }

    back() {
        this.location.back();
    }


    trash() {
        this.service.getTrashed().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.office_layout_name}`,
                    id: elem.id.toString(),
                    date: elem.user_deleted,
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy,
                });
            });
            this.show_trash(items);
        });
    }


    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new AssetClassOfficeActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new AssetClassOfficeActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.office_layout_name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: elem.deletedBy,
                });
            });
            this.show_trash(items, true);
        });
    }

    show_trash(items, isAdmin = false) {
        let _title = 'Deleted Office Comparables';
        if (isAdmin) {
            _title = 'Admin Deleted Office Comparables';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(_id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new AssetClassOfficeOnServerAdminRestored({id: _id}));
        } else {
            this.store.dispatch(new AssetClassOfficeOnServerRestored({id: _id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new AssetClassOfficeDeletedFromTrash({assetClassOfficeId: id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new AssetClassOfficeDeletedFromAdminTrash({id}));
    }


    flush() {
        this.store.dispatch(new OfficeTrashFlushed());
    }


    goMap() {
        this.router.navigate(['map'], {relativeTo: this.activatedRoute});
    }

    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'info';
            default:
                return 'success';
        }
    }
    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 2:
                return 'imported';
            default:
                return 'Validated';
        }
    }

    duplicate(id: number) {
        this.store.dispatch(new AssetClassOfficeDuplicate({id}));
    }

    /**
     * Returns object for filter
     */

    updateFilter(filter: FilterModel) {
        // this.searchInput.nativeElement.value = filter.name;
        // this.searchElementRef.nativeElement.value = filter.city_name;
        // this.filterModel = filter;
        // this.filterCnt = FilterModel.fCount(this.filterModel);
        this.filter$.next(filter);
    }
    addFiles() {
        this.fileInput.nativeElement.click();
    }
    
    importComparable(event: any) {
        const file: File = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.fileUploadService.uploadCompJson(formData).subscribe(
            (res) => {
                if (!res) {
                    return;
                }
                if (res.success) {
                    this.loadAssetClassOfficesList();
                }
            },
            (err) => console
        );
    }
    downloadJsonTemplate() {
        this.fileUploadService.compTemplateDownload(3).subscribe(res => {
                if (res.type == 4) {
                    let blob = new Blob([res.body], { type: 'application/json'});
                    let url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement("a");
                    anchor.download = "interval-comp-office-template.json";
                    anchor.href = url;
                    anchor.click();
                }
            },
            err => {
                alert("Problem while downloading the file.");
            }
        );
    }
}
