import { PropertySubTypes, VPropertySubTypes } from "src/app/core/valuation-process/_models/valuation-process-target-property.model"
import { VApartmentInternalAspect } from "src/app/core/valuation-process/_models/valuation-apartment.model"
import { Cells, checklistCellCreator, mergeRows, mergeRRows, Row, simpleCellCreator } from "./comparable-info-dialog.types"
import { VHouseInternalAspect } from "src/app/core/valuation-process/_models/valuation-house.model"
import { VOfficeInternalAspect } from "src/app/core/valuation-process/_models/valuation-office.model"
import { VRetailShopInternalAspect } from "src/app/core/valuation-process/_models/valuation-retail-shop.model"
import { VRetailBuildingInternalAspect } from "src/app/core/valuation-process/_models/valuation-retail-building.model"
import { VWarehouseInternalAspect } from "src/app/core/valuation-process/_models/valuation-warehouse.model"
import { merge } from "lodash"

const apartmentItems: {header: string, field: keyof VApartmentInternalAspect}[] = [
    {header: 'Windows', field: 'windows'},
    {header: 'Windows comment', field: 'windowsComment'},
    {header: 'Ceiling', field: 'ceiling'},
    {header: 'Ceiling comment', field: 'ceilingComment'},
    {header: 'Walls and partitions', field: 'wallsAndPartition'},
    {header: 'Walls and partitions comment', field: 'wallsAndPartitionComment'},
    {header: 'Floors', field: 'floors'},
    {header: 'Floors comment', field: 'floorsComment'},
    {header: 'Bathroom fitting', field: 'bathroomFitting'},
    {header: 'Bathroom fitting comment', field: 'bathroomFittingComment'},
    {header: 'Built-in fittings', field: 'builtInFittings'},
    {header: 'Other aspect', field: 'others'},
]
const officeItems: {header: string, field: keyof VOfficeInternalAspect}[] = [
    {header: 'Windows', field: 'windows'},
    {header: 'Windows comment', field: 'windowsComment'},
    {header: 'Ceiling', field: 'ceiling'},
    {header: 'Ceiling comment', field: 'ceilingComment'},
    {header: 'Walls and partitions', field: 'wallsAndPartition'},
    {header: 'Walls and partitions comment', field: 'wallsAndPartitionComment'},
    {header: 'Floors', field: 'floors'},
    {header: 'Floors comment', field: 'floorsComment'},
    {header: 'Built-in fittings', field: 'builtInFittings'},
    {header: 'Other aspect', field: 'others'},
]
const houseItems: {header: string, field: keyof VHouseInternalAspect}[] = [
    {header: 'Roof Structure', field: 'roofStructure'},
    {header: 'Roof Structure comment', field: 'roofStructureComment'},
    {header: 'Ceiling', field: 'ceiling'},
    {header: 'Ceiling comment', field: 'ceilingComment'},
    {header: 'Walls and partitions', field: 'wallsAndPartition'},
    {header: 'Walls and partitions comment', field: 'wallsAndPartitionComment'},
    {header: 'Floors', field: 'floors'},
    {header: 'Floors comment', field: 'floorsComment'},
    {header: 'Bathroom fitting', field: 'bathroomFitting'},
    {header: 'Bathroom fitting comment', field: 'bathroomFittingComment'},
    {header: 'Fireplaces, chimney breasts and flues', field: 'fireplacesAndChimney'},
    {header: 'Fireplaces, chimney breasts and flues comment', field: 'fireplacesAndChimneyComment'},
    {header: 'Woodwork', field: 'woodwork'},
    {header: 'Woodwork comment', field: 'woodworkComment'},
    {header: 'Built-in fittings', field: 'builtInFittings'},
    {header: 'Other aspect', field: 'others'},
]
const retailShopItems: {header: string, field: keyof VRetailShopInternalAspect}[] = [
    {header: 'Ceiling', field: 'ceiling'},
    {header: 'Ceiling comment', field: 'ceilingComment'},
    {header: 'Walls and partitions', field: 'wallsAndPartition'},
    {header: 'Walls and partitions comment', field: 'wallsAndPartitionComment'},
    {header: 'Floors', field: 'floors'},
    {header: 'Floors comment', field: 'floorsComment'},
    {header: 'Shop Front type', field: 'shopFrontType'},
    {header: 'Shop Front type comment', field: 'shopFrontTypeComment'},
    {header: 'Built-in fittings', field: 'builtInFittings'},
    {header: 'Other aspect', field: 'others'},
]
const retailBuildingItems: {header: string, field: keyof VRetailBuildingInternalAspect}[] = [
    {header: 'Roof Structure', field: 'roofStructure'},
    {header: 'Roof Structure comment', field: 'roofStructureComment'},
    {header: 'Ceiling', field: 'ceiling'},
    {header: 'Ceiling comment', field: 'ceilingComment'},
    {header: 'Walls and partitions', field: 'wallsAndPartition'},
    {header: 'Walls and partitions comment', field: 'wallsAndPartitionComment'},
    {header: 'Floors', field: 'floors'},
    {header: 'Floors comment', field: 'floorsComment'},
    {header: 'Shop Front type', field: 'shopFrontType'},
    {header: 'Shop Front type comment', field: 'shopFrontTypeComment'},
    {header: 'Height', field: 'height'},
    {header: 'Height comment', field: 'heightComment'},
    {header: 'Cubic Content', field: 'cubicContent'},
    {header: 'Cubic Content comment', field: 'cubicContentComment'},
    {header: 'Built-in fittings', field: 'builtInFittings'},
    {header: 'Other aspect', field: 'others'},
]
const automationHeader = 'Automation equipment & technology'
const warehouseItems: {header: string, field: keyof VWarehouseInternalAspect}[] = [
    {header: 'Roof Structure', field: 'roofStructure'},
    {header: 'Roof Structure comment', field: 'roofStructureComment'},
    {header: 'Ceiling', field: 'ceiling'},
    {header: 'Ceiling comment', field: 'ceilingComment'},
    {header: 'Walls and partitions', field: 'wallsAndPartition'},
    {header: 'Walls and partitions comment', field: 'wallsAndPartitionComment'},
    {header: 'Floors', field: 'floors'},
    {header: 'Floors comment', field: 'floorsComment'},
    {header: 'Height', field: 'height'},
    {header: 'Height comment', field: 'heightComment'},
    {header: 'Cubic Content', field: 'cubicContent'},
    {header: 'Cubic Content comment', field: 'cubicContentComment'},
    {header: 'Storage type', field: 'storageType'},
    {header: 'Storage type comment', field: 'storageTypeComment'},
    {header: 'Built-in fittings', field: 'builtInFittings'},
    {header: 'Other aspect', field: 'others'},
    {header: automationHeader, field: 'automations'}
]

const automations = [
    { id: 1, title: "Goods-to-person (GTP)", checked: false },
    { id: 2, title: "Automated Storage and Retrieval Systems (AS/RS)", checked: false },
    { id: 3, title: "Automatic Guided Vehicles (AGVs)", checked: false },
    { id: 4, title: "Autonomous Mobile Robots", checked: false },
    { id: 5, title: "Pick-to-Light and Put-to-Light Systems", checked: false },
    { id: 6, title: "Voice Picking and Tasking", checked: false },
    { id: 7, title: "Automated Sortation Systems", checked: false },
]

const Items = {
    'Apartment': apartmentItems,
    'Office': officeItems,
    'House': houseItems,
    'Retail Shop': retailShopItems,
    'Retail Building': retailBuildingItems,
    'Warehouse': warehouseItems
}

export function internalAspectRows(assetClasses: {
    refNum: string,
    propertySubType: VPropertySubTypes,
    propertySubTypeName: string,
    internalAspect: VApartmentInternalAspect | VHouseInternalAspect | VOfficeInternalAspect | VRetailBuildingInternalAspect | VRetailShopInternalAspect | VWarehouseInternalAspect | null
}[]): Row[] {
    const rrows = assetClasses.map(assetClasses => {
        const items = Items[assetClasses.propertySubType]
        if (!items) {
            return []
        }
        return items.map(item => {
            const cells: Cells = {}
            if (item.field == 'automations') {
                cells[assetClasses.refNum] = checklistCellCreator(assetClasses.internalAspect[item.field].length > 0)
            } else {
                cells[assetClasses.refNum] = simpleCellCreator(assetClasses.internalAspect[item.field])
            }
            return {
                header: item.header,
                cells,
                propertySubType: assetClasses.propertySubTypeName
            }
        })
    })
    const mergedRows = mergeRRows(rrows)

    const automationRowIdx = mergedRows.findIndex(row => row.header == automationHeader)
    if (automationRowIdx != -1) {
        const automationRow = mergedRows[automationRowIdx]
        mergedRows.splice(automationRowIdx, 1)
        mergedRows.push(automationRow)
        const automationRRows = assetClasses.map(assetClass => {
            if (assetClass.internalAspect['automations']) {
                const acAutomations = assetClass.internalAspect['automations']
                return automations.map(automation => {
                    const cells: Cells = {}
                    cells[assetClass.refNum] = checklistCellCreator(acAutomations.includes(automation.id))
                    return {
                        header: automation.title,
                        cells,
                        propertySubType: assetClass.propertySubTypeName
                    }
                })
            }
            return []
        })
        mergedRows.push(...mergeRRows(automationRRows))
    }


    return mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))
}