<div class="card card-custom">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 kt-margin-bottom-20-mobile">
                <h5 class="my-3 text-mad">{{tp.name}}</h5>
                <hr class="active">
            </div>
        </div>

        <div class="row">
            <div class="col-xl-8 px-3">
                <kt-pictures-module [tpDetails]="tp.details">
                </kt-pictures-module>
            </div>
            <div class="col-xl-4 px-3" style="height: 700px;">
                <div style="display: flex; flex-direction: column; height: 100%;">
                    <div class="row" style="flex-grow: 1;">
                        <div class="col-xl-12">
                            <kt-property-specific-section 
                                [tp]="tp"
                                [toe]="toe">
                            </kt-property-specific-section>
                        </div>
                    </div>
                    <div class="row mt-6" style="flex-grow: 1;">
                        <div class="col-xl-12">
                            <kt-toe-specific-section 
                                [toe]="toe"
                                [tp]="tp">
                            </kt-toe-specific-section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>