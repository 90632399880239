<div class="card card-custom" [ngClass]="{ 'kt-portlet--body-progress' : viewLoading, 'kt-portlet--body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="contact">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="contact">
        <form [formGroup]="contactForm" class="form form-group-seperator-dashed">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="form-group kt-form-group row">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   [placeholder]="'CONTACT.FORM.FIELD.GENERAL.FIRST_NAME.PLACEHOLDER' | translate"
                                   [(ngModel)]="contact.first_name"
                                   formControlName="first_name"/>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                   field: ('CONTACT.FORM.FIELD.GENERAL.FIRST_NAME.NAME' | translate)
                               }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   [placeholder]="'CONTACT.FORM.FIELD.GENERAL.LAST_NAME.PLACEHOLDER' | translate"
                                   [(ngModel)]="contact.last_name"
                                   formControlName="last_name"/>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                   field: ('CONTACT.FORM.FIELD.GENERAL.LAST_NAME.NAME' | translate)
                               }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group kt-form-group row">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input type="text" [placeholder]="'CONTACT.FORM.FIELD.GENERAL.ROLE.PLACEHOLDER' | translate" aria-label="Number"
                                   matInput
                                   [(ngModel)]="contact.role"
                                   formControlName="role"/>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                   field: ('CONTACT.FORM.FIELD.GENERAL.ROLE.NAME' | translate)
                               }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   type="tel"
                                   [placeholder]="'CONTACT.FORM.FIELD.GENERAL.PHONE.PLACEHOLDER' | translate"
                                   [(ngModel)]="contact.professional_phone"
                                   formControlName="professional_phone"/>

                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONTACT.FORM.FIELD.GENERAL.PHONE.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   type="email"
                                   [placeholder]="'CONTACT.FORM.FIELD.GENERAL.EMAIL.PLACEHOLDER' | translate"
                                   [(ngModel)]="contact.email"
                                   formControlName="email"/>
                            <mat-error *ngFor="let validation of contact_validation_messages.email">
                                <mat-error class="error-message" *ngIf="contactForm.get('email').hasError(validation.type) && (contactForm.get('email').dirty || contactForm.get('email').touched)"
                                           [innerHtml]="'GENERAL.VALIDATION.CUSTOM_ERROR' | translate:{
                                        message: validation.message,
                                        decoration: validation.decorated
                                    }"
                                >
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 b-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
                                    [matTooltip]="'GENERAL.DIALOG_BUTTON.CANCEL.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.CANCEL.LABEL' | translate }}
                            </button>&nbsp;
                            <button type="button"
                                    mat-raised-button
                                    class="btn btn-success"
                                    color="submit"
                                    (click)="onSubmit()"
                                    [matTooltip]="'GENERAL.DIALOG_BUTTON.SAVE.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.SAVE.LABEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
