// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TpAdminTaskActions, TpAdminTaskActionTypes} from '../_actions/tp-admin-task.actions';
import {TpAdminTaskModel} from '../_models/tp-admin-task.model';

// Models
import {QueryParamsModel} from '../../_base/crud';

export interface TpAdminTaskState extends EntityState<TpAdminTaskModel> {
    _isAllTpAdminTaskLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedItemId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TpAdminTaskModel> = createEntityAdapter<TpAdminTaskModel>();

export const initialTpAdminTaskState: TpAdminTaskState = adapter.getInitialState({
    _isAllTpAdminTaskLoaded: false,
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedItemId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function tpAdminTaskReducer(state = initialTpAdminTaskState, action: TpAdminTaskActions): TpAdminTaskState {
    switch (action.type) {
        case TpAdminTaskActionTypes.AllTpAdminTaskRequested:
            return {
                ...state,
                _isAllTpAdminTaskLoaded: false
            };
        case TpAdminTaskActionTypes.AllTpAdminTaskLoaded:
            return adapter.addAll(action.payload.tasks, {
                ...state,
                _isAllTpAdminTaskLoaded: true
            });
        case TpAdminTaskActionTypes.TpAdminTaskCleared:
            return adapter.removeAll(state);

        case TpAdminTaskActionTypes.TpAdminTaskDeleted:
            return adapter.removeOne(action.payload.id, state);

        case TpAdminTaskActionTypes.TpAdminTaskOnServerCreated:
            return {
                ...state
            };
        case TpAdminTaskActionTypes.TpAdminTaskCreated:

            return adapter.addOne(action.payload.task, {
                ...state
            });

        case TpAdminTaskActionTypes.TpAdminTaskUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case TpAdminTaskActionTypes.TpAdminTaskByUserPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.assetClasses, {
                ...initialTpAdminTaskState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case TpAdminTaskActionTypes.AllTpAdminTaskLoadedByUser:
            return adapter.addMany(action.payload.tasks, {
                ...initialTpAdminTaskState,
                _isAllTpAdminTaskLoaded: true,
                listLoading: false,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
            });
        case TpAdminTaskActionTypes.TpAdminTasksClear:
            return adapter.removeAll(initialTpAdminTaskState);
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
