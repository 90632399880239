// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {InteractionTypeActions, InteractionTypeActionTypes} from '../_actions/interaction-type.actions';
// Models
import {InteractionType} from '../_models/interaction-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface InteractionTypesState extends EntityState<InteractionType> {
    isAllInteractionTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: InteractionType[];
    lastCreatedInteractionTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<InteractionType> = createEntityAdapter<InteractionType>();

export const initialInteractionTypesState: InteractionTypesState = adapter.getInitialState({
    isAllInteractionTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedInteractionTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function interactionTypesReducer(state = initialInteractionTypesState, action: InteractionTypeActions): InteractionTypesState {
    switch (action.type) {
        case InteractionTypeActionTypes.InteractionTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedInteractionTypeId: undefined
            };
        case InteractionTypeActionTypes.InteractionTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case InteractionTypeActionTypes.InteractionTypeOnServerCreated:
            return {
                ...state
            };
        case InteractionTypeActionTypes.InteractionTypeCreated:
            return adapter.addOne(action.payload.interactionType, {
                ...state, lastCreatedInteractionTypeId: action.payload.interactionType.id
            });
        case InteractionTypeActionTypes.InteractionTypeUpdated:
            return adapter.updateOne(action.payload.partialinteractionType, state);
        case InteractionTypeActionTypes.AllInteractionTypesLoaded:
            return adapter.addAll(action.payload.interactionTypes, {
                ...state, isAllInteractionTypesLoaded: true
            });
        case InteractionTypeActionTypes.InteractionTypesPageCancelled:
            return {
                ...state,
                listLoading: false,
                queryRowsCount: 0,
                queryResult: [],
                lastQuery: new QueryParamsModel({})
            };
        case InteractionTypeActionTypes.InteractionTypesPageLoaded:
            return adapter.addMany(action.payload.interactionTypes, {
                ...initialInteractionTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case InteractionTypeActionTypes.InteractionTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case InteractionTypeActionTypes.InteractionTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case InteractionTypeActionTypes.InteractionTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case InteractionTypeActionTypes.InteractionTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case InteractionTypeActionTypes.InteractionTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case InteractionTypeActionTypes.InteractionTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
