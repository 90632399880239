import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, merge, fromEvent, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { LandAdjustmentsPageRequested, LandAdjustmentDeleted, LandAdjustmentActionToggleLoading, LandAdjustmentOnServerAdminRestored, LandAdjustmentOnServerRestored, LandAdjustmentDeletedFromTrash, LandAdjustmentDeletedFromAdminTrash, LandAdjustmentTrashFlushed, LandAdjustmentUpdated } from 'src/app/core/linked-tables/_actions/land-adjustment.actions';
import { LandAdjustmentsDataSource } from 'src/app/core/linked-tables/_data-sources/land-adjustment.datasource';
import { LandAdjustmentModel } from 'src/app/core/linked-tables/_models/land-adjustment.model';
import { selectTrashedLandAdjustmentCount, selectAdminTrashedLandAdjustmentCount } from 'src/app/core/linked-tables/_selectors/land-adjustment.selectors';
import { LandAdjustmentsService } from 'src/app/core/linked-tables/_services/land-adjustment.service';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
  selector: 'kt-land-adjustment-list',
  templateUrl: './land-adjustment-list.component.html',
  styleUrls: ['./land-adjustment-list.component.scss']
})
export class LandAdjustmentListComponent implements OnInit {
  dataSource: LandAdjustmentsDataSource;
  displayedColumns = ['id', 'name', 'description', 'is_hidden', 'actions'];
  trashCnt = 0;
  adminTrashCnt = 0;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  @ViewChild('table', {static: true}) table: any;
  // Selection
  mode = 'NEW';
  selection = new SelectionModel<LandAdjustmentModel>(true, []);
  adjustmentResult: LandAdjustmentModel[] = [];
  canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

  private subscriptions: Subscription[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public service: LandAdjustmentsService,
    public subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Base of Values');
    this.subheaderService.setBreadcrumbs([
        {title: 'Developer', page: `../default/admin-management`},
        {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
        {title: 'Base Of Values', page: `../default/admin-management/linked-tables/base-of-values`}
    ]);

    this.paginator._intl.itemsPerPageLabel = 'Display';
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => this.loadAdjustmentsList())
    )
        .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadAdjustmentsList();
        })
    )
        .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource

    this.dataSource = new LandAdjustmentsDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
        skip(1),
        distinctUntilChanged()
    ).subscribe(res => {
        this.adjustmentResult = res;
    });
    this.subscriptions.push(entitiesSubscription);

    // trash count

    const selectTrashedSubscription = this.store.pipe(
        select(selectTrashedLandAdjustmentCount)
    ).subscribe(res => {
        this.trashCnt = res;
    });
    this.subscriptions.push(selectTrashedSubscription);

    const selectAdminTrashedSubscription = this.store.pipe(
        select(selectAdminTrashedLandAdjustmentCount)
    ).subscribe(res => {
        this.adminTrashCnt = res;
    });
    this.subscriptions.push(selectAdminTrashedSubscription);

    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
        this.loadAdjustmentsList();
    }); // Remove this line, just loading imitation
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  loadAdjustmentsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
    );
    this.store.dispatch(new LandAdjustmentsPageRequested({page: queryParams}));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {
      with_hidden: true
    };
    const searchText: string = this.searchInput.nativeElement.value;

    filter.name = searchText;
    if (!searchText) {
        return filter;
    }
    return filter;
  }

  resetFilter(): any {
    this.searchInput.nativeElement.value = '';
    this.loadAdjustmentsList();
  }

  addAdjustment() {
    this.editAdjustment(null);
  }

  editAdjustment(_adjustment) {
    if (_adjustment) {
      this.router.navigate(['edit', _adjustment.id], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }
  }

  deleteAdjustment(_item: LandAdjustmentModel) {
    const _title = this.translate.instant('LAND-ADJUSTMENTS.LIST.DIALOG.DELETE.TITLE');
    const _description: string = this.translate.instant('LAND-ADJUSTMENTS.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
    const _waitDesciption =  this.translate.instant('LAND-ADJUSTMENTS.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
        if (!res) {
            return;
        }

        this.store.dispatch(new LandAdjustmentDeleted({id: _item.id}));
    });
  }

  trash() {
    this.store.dispatch(new LandAdjustmentActionToggleLoading({isLoading: true}));
    this.service.getTrashed().subscribe(res => {

      this.store.dispatch(new LandAdjustmentActionToggleLoading({isLoading: false}));
      const items = [];
      res.data.forEach(elem => {
          items.push({
              text: `${elem.name}`,
              id: elem.id.toString(),
              hasPermanentlyDelete: true,
              date: elem.user_deleted,
              deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
          });
      });
      this.show_trash(items);
    });
  }

  adminTrash() {
    this.store.dispatch(new LandAdjustmentActionToggleLoading({isLoading: true}));
    this.service.getAdminTrashed().subscribe(res => {

        this.store.dispatch(new LandAdjustmentActionToggleLoading({isLoading: false}));
        const items = [];
        res.data.forEach(elem => {
            items.push({
                text: `${elem.name}`,
                id: elem.id.toString(),
                hasPermanentlyDelete: true,
                date: elem.deleted_at,
                deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
            });
        });
        this.show_trash(items, true);
    });
  }

  show_trash(items: any[], isAdmin = false) {
    let _title = this.translate.instant('LAND-ADJUSTMENTS.LIST.DIALOG.SHOW_TRASH.TITLE');
    if (isAdmin) {
        _title = this.translate.instant('LAND-ADJUSTMENTS.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
    }
    this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
  }

  restore(id: number, isAdmin = false) {
    if (isAdmin) {
        this.store.dispatch(new LandAdjustmentOnServerAdminRestored({id}));
    } else {
        this.store.dispatch(new LandAdjustmentOnServerRestored({id}));
    }
  }

  delete(id: number) {
      this.store.dispatch(new LandAdjustmentDeletedFromTrash({id}));
  }

  hardDelete(id: number) {
      this.store.dispatch(new LandAdjustmentDeletedFromAdminTrash({id}));
  }

  flush() {
      this.store.dispatch(new LandAdjustmentTrashFlushed());
  }

  viewEntry(item: LandAdjustmentModel) {
    this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
  }
  onHiddenChange(event: MatCheckboxChange, item: LandAdjustmentModel) {
    const _item = cloneDeep(item)
    _item.is_hidden = event.checked
    this.store.dispatch(new LandAdjustmentUpdated({
      item: _item,
      partialItem: {
        id: item.id,
        changes: {
          is_hidden: event.checked
        }
      }
    }))
  }
}
