import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalRefTemplateModel } from 'src/app/core/template';

@Component({
  selector: 'kt-external-ref-template-modal',
  templateUrl: './external-ref-template-modal.component.html',
  styleUrls: ['./external-ref-template-modal.component.scss']
})
export class ExternalRefTemplateModalComponent implements OnInit {
  public template: ExternalRefTemplateModel;
  constructor(
    private dialogRef: MatDialogRef<ExternalRefTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.template = data.template;
  }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
