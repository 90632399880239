import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { select, Store } from '@ngrx/store';
import { NgxMaskPipe } from 'ngx-mask';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AssetClassModel, selectAssetClassById } from 'src/app/core/asset_class';
import { AssetClassTenure } from 'src/app/core/comparable';
import { selectAssetClassTypeById } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ChangeComparableConsideration } from 'src/app/core/valuation/_actions/criterion.actions';
import { selectValuationData } from 'src/app/core/valuation/_selectors/valuation-data.selector';
import { DateAgoPipe } from 'src/app/views/theme/pipes/date-ago.pipe';

type Rows = {
  comparableType: {}
  consideration: {}
  source: {
    type: {},
    information: {},
    credibility: {},
    description: {},
    webAddr: {},
    pictures: {}
  }
}

@Component({
  selector: 'kt-consideration-info-table',
  templateUrl: './consideration-info-table.component.html',
  styleUrls: ['./consideration-info-table.component.scss']
})
export class ConsiderationInfoTableComponent implements OnInit, OnDestroy {
  @Input() selectedIds: number[];
  @Input() propertySubTypeId: number;
  @Input() targetProperty: AssetClassModel; 
  @Input() comparablesData: {com: any, selectedTenure: number}[];
  @Input() isMulti: boolean;

  _comparablesData$: BehaviorSubject<{com: any, selectedTenure: number}[]> = new BehaviorSubject([]);

  notSelected = -5;

  rows$: Observable<Rows>;
  onDestroy$: Subject<void> = new Subject();
  valData: any;

  constructor(
    private store$: Store<AppState>,
    private maskPipe: NgxMaskPipe,
    private datePipe: DatePipe,
    private dateAgePipe: DateAgoPipe,
  ) { }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this._comparablesData$.next(this.comparablesData);

    this.store$.pipe(select(selectValuationData), takeUntil(this.onDestroy$)).subscribe(
      res => {
        this.valData = res;
      }
    )

    this.rows$ = combineLatest([
      this._comparablesData$,
      this.store$.select(selectAssetClassTypeById(this.propertySubTypeId))
    ]).pipe(map(([comparablesData, propertySubType]) => {
      const rows: Rows = {
        comparableType: {},
        consideration: {},
        source: {
          type: {},
          information: {},
          credibility: {},
          description: {},
          webAddr: {},
          pictures: {}
        }
      }

      comparablesData.forEach(comData => {
        rows.comparableType[comData.com.ref_num] = `${propertySubType.top_property_type_name} (${propertySubType.name})`;

        const considerationNum = comData.com.tenures.length;
        const tenures = this.sortedTenures(comData.com.tenures);
        rows.consideration[comData.com.ref_num] = {
          values: tenures.map(t => {
            const tenure = capitalize(t.tenure);
            const totalConsideration = this.maskPipe.transform(t.total_consideration, 'separator.2', {thousandSeparator: ','});
            const currency = t.tenure == 'freehold' ? `${t.currency}` : `${t.currency} per month`;
            const date = this.datePipe.transform(t.source.source_date, 'dd MMM yyyy');
            const value = `${tenure} - ${totalConsideration} ${currency} (${date})`;
            return {id: t.id, value: value};
          }),
          placeholder: `${considerationNum} consideration${considerationNum > 1 ? 's' : ''} (click to select)`,
          selected: comData.selectedTenure
        }

        if (comData.selectedTenure != null) {
          const tenure = comData.com.tenures.find(t => t.id == comData.selectedTenure);
          if (tenure) {
            rows.source.type[comData.com.ref_num] = `${tenure.source.source_type_name} - ${this.datePipe.transform(tenure.source.source_date, 'dd MMM yyyy')} - (${this.dateAgePipe.transform(tenure.source.source_date)})`;
            rows.source.information[comData.com.ref_num] = tenure.source.source_information_name;
            rows.source.credibility[comData.com.ref_num] = tenure.source.source_credibility_id != -1 ? `${tenure.source.source_credibility_name}/5` : 'Unknown';
            const isUnknown = !tenure.source.validation_source || !tenure.source.validation_source.length;
            rows.source.description[comData.com.ref_num] = isUnknown ? 'Unknown' : tenure.source.validation_source;
          }
        } else {
          rows.source.type[comData.com.ref_num] = this.notSelected;
          rows.source.information[comData.com.ref_num] = this.notSelected; 
          rows.source.credibility[comData.com.ref_num] = this.notSelected;
          rows.source.description[comData.com.ref_num] = this.notSelected; 
          rows.source.webAddr[comData.com.ref_num] = this.notSelected;
          rows.source.pictures[comData.com.ref_num] = this.notSelected; 
        }
      });
      return rows;
    }));

  }

  onSelectionChange(event: MatSelectChange, com_id: number) {
    const comDatas = this._comparablesData$.value;
    const index = comDatas.findIndex(cd => cd.com.id == com_id);
    if (index == -1) {
      return;
    }
    comDatas[index].selectedTenure = event.value;
    this._comparablesData$.next(comDatas);

    if (this.isMulti || this.alreadyAdded(com_id)) {
      const com = comDatas[index].com;
      this.store$.dispatch(new ChangeComparableConsideration({
        com: com,
        tpTenureID: this.valData.valuation.tenure_id,
        selectedTenure: event.value,
        quote_currency: this.valData.asset_class.country_currency,
      }))
    }
  }

  alreadyAdded(item: any) {
      return this.selectedIds.indexOf(item) > -1;
  }

  copy(tenures: any[]): any[] {
      const temp = [];
      tenures.forEach(t => temp.push(Object.assign({}, t)));
      return temp;
  }

  sortedTenures(tenures: AssetClassTenure[]): AssetClassTenure[] {
    const ts = this.copy(tenures);
    const lt = ts.filter(t => t.tenure == 'leasehold');
    const ft = ts.filter(t => t.tenure == 'freehold');
    const temp = [];
    if (lt.length > 0) {
      lt.sort((a, b) => {
          const aDate = a.source.source_type_id != 1 ? new Date(a.source.information_date) : new Date(a.source.transaction_date);
          const bDate = b.source.source_type_id != 1 ? new Date(b.source.information_date) : new Date(b.source.transaction_date);
          return bDate.getTime() - aDate.getTime();
      });  
    }
    if (ft.length > 0) {
      ft.sort((a, b) => {
          const aDate = a.source.source_type_id != 1 ? new Date(a.source.information_date) : new Date(a.source.transaction_date);
          const bDate = b.source.source_type_id != 1 ? new Date(b.source.information_date) : new Date(b.source.transaction_date);
          return bDate.getTime() - aDate.getTime();
      });  
    }

    if (this.targetProperty.tenure_id == 1) {
      lt.forEach(t => temp.push(t));
      ft.forEach(t => temp.push(t));
      return temp;
    }
    ft.forEach(t => temp.push(t));
    lt.forEach(t => temp.push(t));
    return temp;
  }
}

function capitalize(text: string): string {
  if (text == null || text.length == 0) {
    return text;
  }
  if (text.length == 1) {
    return text.toUpperCase();
  }
  return text[0].toUpperCase() + text.substring(1);
}