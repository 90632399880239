<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
        <div class="card-toolbar">
            <button (click)="onYesClick()" class="btn btn-sm btn-success mr-2" cdkFocusInitial style="width: 80px;">
                Save
            </button>
            <button (click)="onNoClick()" class="btn btn-sm btn-icon btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </button>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="form-group row">
                <ng-container *ngFor="let field of fields">
                    <div class="col-lg-12 {{field.class ? field.class : ''}}" [ngSwitch]="field.type">
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field  class="mat-form-field-fluid">
                                <input matInput [placeholder]="field.label" [formControl]="field.formControl">
                                <mat-error>
                                    {{field.errors.length > 0 ? field.errors[0] : 'Error occured'}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <mat-checkbox  [formControl]="field.formControl">
                                {{field.label}}
                            </mat-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'textarea'">
                            <mat-form-field class="mat-form-field-fluid">
                                <textarea matInput [placeholder]="field.label" [formControl]="field.formControl" rows="3"></textarea>
                                <mat-error>
                                    {{field.errors.length > 0 ? field.errors[0] : 'Error occured'}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'selection'">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select [placeholder]="field.label" [formControl]="field.formControl">
                                    <ng-container *ngIf="field.selectionValues">
                                        <mat-option *ngFor="let item of field.selectionValues" [value]="item.id">
                                            {{item.value}}
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngIf="!field.selectionValues">
                                        <mat-option disabled>No Values Provided</mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error>
                                    {{field.errors.length > 0 ? field.errors[0] : 'Error occured'}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'date-picker'">
                            <mat-form-field class="mat-form-field-fluid">
                                <span (click)="date_picker.open()">
                                    <input matInput [matDatepicker]="date_picker" readonly [placeholder]="field.label"
                                        [formControl]="field.formControl">
                                </span>
                                <mat-datepicker-toggle matSuffix [for]="date_picker"></mat-datepicker-toggle>
                                <button mat-button matSuffix *ngIf="field.formControl.value"
                                    matTooltip="Clear"
                                    (click)="clearDate(field.formControl)">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-datepicker #date_picker></mat-datepicker>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>