// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {StorageTypeActions, StorageTypeActionTypes} from '../_actions/storage-type.actions';
// Models
import {StorageType} from '../_models/storage-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface StorageTypesState extends EntityState<StorageType> {
    isAllStorageTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: StorageType[];
    lastCreatedStorageTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<StorageType> = createEntityAdapter<StorageType>();

export const initialStorageTypesState: StorageTypesState = adapter.getInitialState({
    isAllStorageTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedStorageTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function storageTypesReducer(state = initialStorageTypesState, action: StorageTypeActions): StorageTypesState {
    switch (action.type) {
        case StorageTypeActionTypes.StorageTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedStorageTypeId: undefined
            };
        case StorageTypeActionTypes.StorageTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case StorageTypeActionTypes.StorageTypeOnServerCreated:
            return {
                ...state
            };
        case StorageTypeActionTypes.StorageTypeCreated:
            return adapter.addOne(action.payload.storageType, {
                ...state, lastCreatedStorageTypeId: action.payload.storageType.id
            });
        case StorageTypeActionTypes.StorageTypeUpdated:
            return adapter.updateOne(action.payload.partialStorageType, state);
        case StorageTypeActionTypes.AllStorageTypesLoaded:
            return adapter.addAll(action.payload.storageTypes, {
                ...state, isAllStorageTypesLoaded: true
            });
        case StorageTypeActionTypes.StorageTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case StorageTypeActionTypes.StorageTypesPageLoaded:
            return adapter.addMany(action.payload.storageTypes, {
                ...initialStorageTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case StorageTypeActionTypes.StorageTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case StorageTypeActionTypes.StorageTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case StorageTypeActionTypes.StorageTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case StorageTypeActionTypes.StorageTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case StorageTypeActionTypes.StorageTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case StorageTypeActionTypes.StorageTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
