import {OutdoorSpaceModel} from '../_models/outdoor-space.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {OutdoorSpacesState} from '../_reducers/outdoor-space.reducer';
import * as fromOutdoorSpace from '../_reducers/outdoor-space.reducer';
import {each} from 'lodash';

export const selectOutdoorSpacesState = createFeatureSelector<OutdoorSpacesState>('outdoor-spaces');

export const selectOutdoorSpaceById = (id: number) => createSelector(
    selectOutdoorSpacesState,
    state => state.entities[id]
);

export const selectAllOutdoorSpaces = createSelector(
    selectOutdoorSpacesState,
    fromOutdoorSpace.selectAll
);

export const selectAllOutdoorSpacesIds = createSelector(
    selectOutdoorSpacesState,
    fromOutdoorSpace.selectIds
);

export const allOutdoorSpacesLoaded = createSelector(
    selectOutdoorSpacesState,
    state => state.isAllOutdoorSpacesLoaded
);


export const selectOutdoorSpacesPageLoading = createSelector(
    selectOutdoorSpacesState,
    state => state.listLoading
);

export const selectOutdoorSpacesActionLoading = createSelector(
    selectOutdoorSpacesState,
    state => state.actionLoading
);

export const selectLastCreatedOutdoorSpaceId = createSelector(
    selectOutdoorSpacesState,
    state => state.lastCreatedOutdoorSpaceId
);

export const selectOutdoorSpacesShowInitWaitingMessage = createSelector(
    selectOutdoorSpacesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedOutdoorSpaceCount = createSelector(
    selectOutdoorSpacesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedOutdoorSpaceCount = createSelector(
    selectOutdoorSpacesState,
    (state) => state.totalAdminTrashed
);


export const selectOutdoorSpaceQueryResult = createSelector(
    selectOutdoorSpacesState,
    state => {
        const items: OutdoorSpaceModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OutdoorSpaceModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
