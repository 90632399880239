import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TpTaskService } from 'src/app/core/asset_class';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';

@Component({
  selector: 'kt-audit-task-modal',
  templateUrl: './audit-task-modal.component.html',
  styleUrls: ['./audit-task-modal.component.scss']
})
export class AuditTaskModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  readonly: boolean = false;
  auditTask$: Observable<{id: number, created: number}>;
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<AuditTaskModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private tpTaskService: TpTaskService,
    private readonlyService: ReadonlyService,
  ) { }

  ngOnInit(): void {
    this.readonly = this.readonlyService.isReadOnly();

    this.form = this.formBuilder.group({
      created_audit: [0]
    });

    this.auditTask$ = this.tpTaskService.getAuditTask(this.data.task_id).pipe(
      takeUntil(this._onDestroy$),
      map(task => {
        if (task == null) {
          return {
            id: undefined,
            created: 0
          }
        }
        return {
          id: task.id,
          created: task.created
        }
      }),
      tap(task => {
        this.form.patchValue({'created_audit': task.created});
      })
    )

  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  close() {this.dialogRef.close();}

  save() {
    if (this.readonly) {
      this.dialogRef.close();
      return;
    }

    const task = {
      task_id: this.data.task_id,
      created: this.form.controls.created_audit.value,
    }

    this.tpTaskService.createOrUpdateAuditTrailTask(task).subscribe(res => {
      this.dialogRef.close({result: res});
    })
  }

}
