import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { IpmsComponentDeleted, IpmsComponentDeletedFromAdminTrash, IpmsComponentDeletedFromTrash, IpmsComponentOnServerAdminRestored, IpmsComponentOnServerRestored, IpmsComponentsActionToggleLoading, IpmsComponentsPageRequested, IpmsComponentTrashFlushed, IpmsComponentUpdated } from 'src/app/core/linked-tables/_actions/ipms-component.actions';
import { IpmsComponentsDataSource } from 'src/app/core/linked-tables/_data-sources/ipms-component.datasource';
import { IpmsComponent } from 'src/app/core/linked-tables/_models/ipms-component.model';
import { selectAdminTrashedIpmsComponentCount, selectTrashedIpmsComponentCount } from 'src/app/core/linked-tables/_selectors/ipms-component.selectors';
import { IpmsComponentsService } from 'src/app/core/linked-tables/_services/ipms-component.service';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'kt-ipms-component-list',
  templateUrl: './ipms-component-list.component.html',
  styleUrls: ['./ipms-component-list.component.scss']
})
export class IpmsComponentListComponent implements OnInit {
  dataSource: IpmsComponentsDataSource;
  displayedColumns = ['id', 'name', 'description', 'is_hidden', 'actions'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;

  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  selection = new SelectionModel<IpmsComponent>(true, []);
  ipmsComponentResult: IpmsComponent[] = [];

  trashCnt = 0;
  adminTrashCnt = 0;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: IpmsComponentsService,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>
  ) { }

  ngOnInit(): void {
        this.subheaderService.setTitle('Interaction types');
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Interaction types', page: `../default/admin-management/linked-tables/ipms-components`}
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadIpmsComponentsList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadIpmsComponentsList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new IpmsComponentsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.ipmsComponentResult = res;
        });

        this.subscriptions.push(entitiesSubscription);

        // trash count
        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedIpmsComponentCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedIpmsComponentCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadIpmsComponentsList();
        }); // Remove this line, just loading imitation
  }

    loadIpmsComponentsList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        // Call request from server
        this.store.dispatch(new IpmsComponentsPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {
            with_hidden: true
        };
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadIpmsComponentsList();
    }

    /**
     * Show add IpmsComponent dialog
     */
    addIpmsComponent() {
        this.editIpmsComponent(null);
    }

    /**
     * Show Edit IpmsComponent view and save after success close result
     * @param IpmsComponent: IpmsComponent
     */
    editIpmsComponent(_IpmsComponent) {
        if (_IpmsComponent)
            this.router.navigate(['edit', _IpmsComponent.id], {relativeTo: this.activatedRoute});
        else
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    deleteIpmsComponent(_item: IpmsComponent) {
        const _title = this.translate.instant('INTERACTION_TYPE.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('INTERACTION_TYPE.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
        const _waitDesciption =  this.translate.instant('INTERACTION_TYPE.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new IpmsComponentDeleted({id: _item.id}));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new IpmsComponentsActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new IpmsComponentsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new IpmsComponentsActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new IpmsComponentsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('INTERACTION_TYPE.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('INTERACTION_TYPE.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new IpmsComponentOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new IpmsComponentOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new IpmsComponentDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new IpmsComponentDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new IpmsComponentTrashFlushed());
    }

    viewEntry(item: IpmsComponent) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }
    onHiddenChange(event: MatCheckboxChange, item: IpmsComponent) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new IpmsComponentUpdated({
            IpmsComponent: _item,
            partialIpmsComponent: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
