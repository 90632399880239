// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {PaymentTypeActions, PaymentTypeActionTypes} from '../_actions/payment-type.actions';
// Models
import {PaymentType} from '../_models/payment-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface PaymentTypesState extends EntityState<PaymentType> {
    isAllPaymentTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: PaymentType[];
    lastCreatedPaymentTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PaymentType> = createEntityAdapter<PaymentType>();

export const initialPaymentTypesState: PaymentTypesState = adapter.getInitialState({
    isAllPaymentTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedPaymentTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function paymentTypesReducer(state = initialPaymentTypesState, action: PaymentTypeActions): PaymentTypesState {
    switch (action.type) {
        case PaymentTypeActionTypes.PaymentTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPaymentTypeId: undefined
            };
        case PaymentTypeActionTypes.PaymentTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case PaymentTypeActionTypes.PaymentTypeOnServerCreated:
            return {
                ...state
            };
        case PaymentTypeActionTypes.PaymentTypeCreated:
            return adapter.addOne(action.payload.paymentType, {
                ...state, lastCreatedPaymentTypeId: action.payload.paymentType.id
            });
        case PaymentTypeActionTypes.PaymentTypeUpdated:
            return adapter.updateOne(action.payload.partialPaymentType, state);
        case PaymentTypeActionTypes.AllPaymentTypesLoaded:
            return adapter.addAll(action.payload.paymentTypes, {
                ...state, isAllPaymentTypesLoaded: true
            });
        case PaymentTypeActionTypes.PaymentTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case PaymentTypeActionTypes.PaymentTypesPageLoaded:
            return adapter.addMany(action.payload.paymentTypes, {
                ...initialPaymentTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case PaymentTypeActionTypes.PaymentTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case PaymentTypeActionTypes.PaymentTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case PaymentTypeActionTypes.PaymentTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case PaymentTypeActionTypes.PaymentTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case PaymentTypeActionTypes.PaymentTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case PaymentTypeActionTypes.PaymentTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
