<div class="mad-container" [ngClass]="{'readonly': readonly}">
    <div class="d-flex flex-column mb-4" *ngIf="uploading$|async">
        <div>Uploading...</div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="d-flex flex-column mb-4" *ngIf="processing$|async">
        <div>Processing...</div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container" *ngIf="!readonly">

        <span class="btn btn-primary" color="primary" mat-raised-button (click)="addVideo()">
            Upload Video
        </span>
        <input type="file" #file name="video" id="video" accept="video/*" style="display: none;" (change)="onVideoUpload($event)">
    </div>
    <div class="example-container mat-elevation-z0">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="thumbnail">
                <mat-header-cell *matHeaderCellDef>Thumbnail</mat-header-cell>
                <mat-cell *matCellDef="let video">
                    <span 
                        style="cursor: pointer;"
                        class="picture-container"
                        (click)="viewVideo(video)"
                        >
                        <img
                            style="
                                height: 60px;
                                width: 60px;
                                object-fit: cover;
                            "
                            (error)="imgOnError($event)"
                            [src]="video.pic_url" />
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
                <mat-cell *matCellDef="let video">
                    <input class="title-input" [value]="video.title" (focusout)="onInputFocusOut($event, video)">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let video">
                    <textarea 
                        cols="30"
                        rows="1"
                        class="description-input"
                        [value]="video.description" 
                        (change)="descriptionChange($event, video)">
                    </textarea>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let video">
                    <button mat-icon-button
                        color="primary"
                        matTooltip="Edit Video"
                        type="button"
                        (click)="editVideo(video)"
                        [disabled]="readonly">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button mat-icon-button
                        color="warn"
                        matTooltip="Delete Video"
                        (click)="deleteVideo(video)"
                        type="button"
                        [disabled]="readonly">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
    </div>
</div>