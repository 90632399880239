<div class="col-md-12 mb-1 mt-4">
    <h6 class="my-3 text-mad text-uppercase">{{term}}{{data.termSuffix}} term</h6>
</div>
<div class="col-md-12 mat-table-wrapper">
    <table class="table lmat-elevation-z8" style="overflow: hidden;">
        <tr>
            <th class="calculation__name">
                Reversion to future rental value @<ng-container *ngIf="growthRate; else notAvailable">{{growthRate}}</ng-container>% pa for {{data.year}} year(s)
            </th>
            <td class="calculation_value">
                <ng-container *ngIf="data.revisionToFutureRentalValue; else notAvailable">
                    {{data.revisionToFutureRentalValue}}
                </ng-container>
            </td>
            <td class="calculation__tooltip">
                <ng-template #reversion>
                    <strong>Calculation:</strong> 
                        Reversion to future rental value @<ng-container *ngIf="growthRate; else notAvailable">{{growthRate}}</ng-container> pa
                    <br>
                    = 
                        <span class="text-red">(1 + </span>
                        <span class="text-blue">(Assumed Growth Rate)</span>
                        <span class="text-red">)</span>
                        <sup>
                            <span class="text-red">(</span>
                            <span class="text-blue">(Rent Review Cycle)</span>
                            <span class="text-red"> x (</span>
                            <span class="text-blue">(Current Term)</span>
                            <span class="text-red"> - 1))</span>
                        </sup>
                        <span class="text-red">)</span>
                    <br>
                    <ng-container *ngIf="data.revisionToFutureRentalValue">
                        =  
                            <span class="text-red">(1 + </span>
                            <span class="text-blue">{{growthRate}}</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">(</span>
                                <span class="text-blue">{{rentReview | mask:'separator.2'}}</span>
                                <span class="text-red"> x (</span>
                                <span class="text-blue">{{term}}</span>
                                <span class="text-red"> - 1))</span>
                            </sup>
                            <span class="text-red">)</span>
                        <br>
                        = <span class="text-green">{{data.revisionToFutureRentalValue}}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="reversion" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr class="gray">
            <th>
                <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: rentReview, rate: targetRate}"></ng-container>
            </th>
            <td>
                <ng-container *ngIf="data.yearsPurchaseOfRentReview; else notAvailable">
                    {{data.yearsPurchaseOfRentReview}}
                </ng-container>
            </td>
            <td>
                <ng-template #popYearsPurchase>
                    <strong>Calculation:</strong> 
                        <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: rentReview, rate: targetRate}"></ng-container>
                    <br>
                    = 
                        <span class="text-red">(1 - (1 + </span>
                        <span class="text-blue">(Target Rate)</span>
                        <span class="text-red">)</span>
                        <sup>
                            <span class="text-red">-</span>
                            <span class="text-blue">(Rent Review Cycle)</span>
                        </sup>
                        <span class="text-red">)/</span>
                        <span class="text-blue">(Target Rate)</span>
                    <br>
                    <ng-container *ngIf="data.yearsPurchaseOfRentReview">
                        =  
                            <span class="text-red">(1 - (1 + </span>
                            <span class="text-blue">{{targetRate}}%</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">{{rentReview | mask:'separator.2'}}</span>
                            </sup>
                            <span class="text-red">)/</span>
                            <span class="text-blue">{{targetRate}}%</span>
                        <br>
                        = <span class="text-green">{{data.yearsPurchaseOfRentReview}}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popYearsPurchase" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr> 
            <th>
                <ng-container *ngTemplateOutlet="presentValue; context: {year: data.year, rate: targetRate}"></ng-container>
            </th>
            <td>
                <ng-container *ngIf="data.presentValueOfRentReviewCycle; else notAvailable">
                    {{data.presentValueOfRentReviewCycle}}
                </ng-container>
            </td>
            <td>
                <ng-template #popPresentValue>
                    <strong>Calculation:</strong> 
                        <ng-container *ngTemplateOutlet="presentValue; context: {year: data.year, rate: targetRate}"></ng-container>
                    <br>
                    = 
                        <span class="text-red">(1 + </span>
                        <span class="text-blue">(Target Rate)</span>
                        <span class="text-red">)</span>
                        <sup>
                            <span class="text-red">-(</span>
                            <span class="text-blue">(Rent Review Cycle)</span>
                            <span class="text-red"> x (</span>
                            <span class="text-blue">(Current Term)</span>
                            <span class="text-red"> - 1))</span>
                        </sup>
                    <br>
                    <ng-container *ngIf="data.presentValueOfRentReviewCycle">
                        =  
                            <span class="text-red">(1 + </span>
                            <span class="text-blue">{{targetRate}}</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-(</span>
                                <span class="text-blue">{{rentReview | mask:'separator.2'}}</span>
                                <span class="text-red"> x (</span>
                                <span class="text-blue">{{term}}</span>
                                <span class="text-red"> - 1))</span>
                            </sup>
                        <br>
                        = <span class="text-green">{{data.presentValueOfRentReviewCycle}}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popPresentValue" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr class="gray"> 
            <th>
                Factor Value of {{term - 1}}{{data.prevTermSuffix}} revisionary rent
            </th>
            <td>
                <ng-container *ngIf="data.factorValueOfRevisionaryRent; else notAvailable">
                    {{data.factorValueOfRevisionaryRent}}
                </ng-container>
            </td>
            <td>
                <ng-template #popFactor>
                    <strong>Calculation:</strong> 
                        Factor Value of {{term - 1}}{{data.prevTermSuffix}} revisionary rent
                    <br>
                    = 
                        <span class="text-blue">(Reversion to future rental value)</span>
                        <span class="text-red"> x </span>
                        <span class="text-blue">(Year's Purchase)</span>
                        <span class="text-red"> x </span>
                        <span class="text-blue">(Present Value)</span>
                    <br>
                    <ng-container *ngIf="data.factorValueOfRevisionaryRent">
                        =  
                            <span class="text-blue">{{data.revisionToFutureRentalValue}}</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">{{data.yearsPurchaseOfRentReview}}</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">{{data.presentValueOfRentReviewCycle}}</span>
                        <br>
                        = <span class="text-green">{{data.factorValueOfRevisionaryRent}}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popFactor" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>
    </table>
</div>

<ng-template #yearsPurchase let-year="year" let-rate="rate">
    Year's Purchase (YP) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year | mask:'separator.2'}} year(s)
</ng-template>

<ng-template #presentValue let-year="year" let-rate="rate">
    Present Value (PV) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year | mask:'separator.2'}} year(s)
</ng-template>

<ng-template #notAvailable>
    <span class="text-red">N/A</span>
</ng-template>