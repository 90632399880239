import {PurposeOfValuation} from '../_models/purpose-of-valuation.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PurposeOfValuationsState} from '../_reducers/purpose-of-valuation.reducers';
import * as fromPurposeOfValuation from '../_reducers/purpose-of-valuation.reducers';
import {each} from 'lodash';

export const selectPurposeOfValuationsState = createFeatureSelector<PurposeOfValuationsState>('purposeOfValuations');

export const selectPurposeOfValuationById = (purposeOfValuationId: number) => createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => purposeOfValuationsState.entities[purposeOfValuationId]
);

export const selectAllPurposeOfValuations = createSelector(
    selectPurposeOfValuationsState,
    fromPurposeOfValuation.selectAll
);

export const selectAllPurposeOfValuationsIds = createSelector(
    selectPurposeOfValuationsState,
    fromPurposeOfValuation.selectIds
);

export const allPurposeOfValuationsLoaded = createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => purposeOfValuationsState.isAllPurposeOfValuationsLoaded
);


export const selectPurposeOfValuationsPageLoading = createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => purposeOfValuationsState.listLoading
);

export const selectPurposeOfValuationsActionLoading = createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => purposeOfValuationsState.actionLoading
);

export const selectLastCreatedPurposeOfValuationId = createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => purposeOfValuationsState.lastCreatedPurposeOfValuationId
);

export const selectPurposeOfValuationsShowInitWaitingMessage = createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => purposeOfValuationsState.showInitWaitingMessage
);

export const selectTrashedPurposeOfValuationCount = createSelector(
    selectPurposeOfValuationsState,
    (purposeOfValuationsState) => purposeOfValuationsState.totalTrashed
);

export const selectAdminTrashedPurposeOfValuationCount = createSelector(
    selectPurposeOfValuationsState,
    (purposeOfValuationsState) => purposeOfValuationsState.totalAdminTrashed
);


export const selectPurposeOfValuationQueryResult = createSelector(
    selectPurposeOfValuationsState,
    purposeOfValuationsState => {
        const items: PurposeOfValuation[] = [];
        each(purposeOfValuationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PurposeOfValuation[] = httpExtension.sortArray(items, purposeOfValuationsState.lastQuery.sortField, purposeOfValuationsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, purposeOfValuationsState.totalCount, '', purposeOfValuationsState.totalTrashed);
    }
);
