// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {CountriesState} from '../_reducers/country.reducers';
import * as fromCountries from '../_reducers/country.reducers';

export const selectCountriesState = createFeatureSelector<CountriesState>('countries');

export const selectCountryById = (countryId: number) => createSelector(
    selectCountriesState,
    _state => _state.entities[countryId]
);

export const selectAllCountries = createSelector(
    selectCountriesState,
    fromCountries.selectAll
);

export const selectAllCountriesIds = createSelector(
    selectCountriesState,
    fromCountries.selectIds
);

export const allCountriesLoaded = createSelector(
    selectCountriesState,
    _state => _state._isAllCountriesLoaded
);

export const selectTrashedCountryCount = createSelector(
    selectCountriesState,
    (_state) => _state.totalTrashed
)

export const selectAdminTrashedCountryCount = createSelector(
    selectCountriesState,
    (_state) => _state.totalAdminTrashed
);


export const selectLastCreatedCountryId = createSelector(
    selectCountriesState,
    _state => _state.lastCreatedCountryId
);

export const selectCountriesShowInitWaitingMessage = createSelector(
    selectCountriesState,
    _state => _state.showInitWaitingMessage
);

export const selectCountriesActionLoading = createSelector(
    selectCountriesState,
    _state => _state.actionsLoading
);
