<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Full Building Info</h3>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="close()"
               class="btn btn-icon btn-sm btn-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body m-0 h-100">
        <mat-dialog-content>
            <kt-building-form
                [readonly]="true"
                [(centerLat)]="centerLat"
                [(centerLng)]="centerLng"
                [buildingPicturePath]="''"
                [isComplexForm]="true"
                [acBuilding]="buildingInfo"
            ></kt-building-form>
        </mat-dialog-content>
    </div>
</div>