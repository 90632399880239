import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HouseAdjustmentsState } from "../_reducers/house-adjustment.reducers";
import * as fromAdjustment from '../_reducers/house-adjustment.reducers';
import { HouseAdjustmentModel } from "../_models/house-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectHouseAdjustmentsState = createFeatureSelector<HouseAdjustmentsState>('house-adjustments');

export const selectHouseAdjustmentById = (AdjustmentId: number) => createSelector(
    selectHouseAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllHouseAdjustments = createSelector(
    selectHouseAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllHouseAdjustmentsIds = createSelector(
    selectHouseAdjustmentsState,
    fromAdjustment.selectIds
);

export const allHouseAdjustmentsLoaded = createSelector(
    selectHouseAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectHouseAdjustmentsPageLoading = createSelector(
    selectHouseAdjustmentsState,
    state => state.listLoading
);

export const selectHouseAdjustmentsActionLoading = createSelector(
    selectHouseAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedHouseAdjustmentId = createSelector(
    selectHouseAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectHouseAdjustmentsShowInitWaitingMessage = createSelector(
    selectHouseAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedHouseAdjustmentCount = createSelector(
    selectHouseAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedHouseAdjustmentCount = createSelector(
    selectHouseAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectHouseAdjustmentQueryResult = createSelector(
    selectHouseAdjustmentsState,
    state => {
        const items: HouseAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HouseAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);