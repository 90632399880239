import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AssetClassParkingActionToggleLoading, AssetClassParkingActionTypes, AssetClassParkingAdminRestored, AssetClassParkingCreated, AssetClassParkingDeleted, AssetClassParkingDeletedFromAdminTrash, AssetClassParkingDeletedFromTrash, AssetClassParkingDuplicate, AssetClassParkingOnServerAdminRestored, AssetClassParkingOnServerCreated, AssetClassParkingOnServerRestored, AssetClassParkingOnServerUpdated, AssetClassParkingPageLoaded, AssetClassParkingPageRequested, AssetClassParkingPageToggleLoading, AssetClassParkingRestored, AssetClassParkingTrashFlushed, AssetClassParkingUpdated } from "../_actions/asset-class-parking.actions";
import { AssetClassParkingService } from "../_services/asset-class-parking.service";

@Injectable()
export class AssetClassParkingEffects {
    showPageLoadingDispatcher = new AssetClassParkingPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassParkingActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassParkingActionToggleLoading({isLoading: false});

    constructor(
        private actions$: Actions,
        private service: AssetClassParkingService,
        private store: Store<AppState>
    ) {}

    @Effect()
    loadAssetClassParkingPage$ = this.actions$.pipe(
        ofType<AssetClassParkingPageRequested>(AssetClassParkingActionTypes.AssetClassParkingPageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery = response[1];
            return new AssetClassParkingPageLoaded({
                AssetClassParkings: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            })
        })
    )

    @Effect()
    createAssetClassParking$ = this.actions$.pipe(
        ofType<AssetClassParkingOnServerCreated>(AssetClassParkingActionTypes.AssetClassParkingOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                payload.AssetClassParking,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassParkingCreated({AssetClassParking: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    updateAssetClassParking$ = this.actions$.pipe(
        ofType<AssetClassParkingOnServerUpdated>(AssetClassParkingActionTypes.AssetClassParkingOnServerUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateAssetClass(
                payload.AssetClassParking,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassParkingUpdated(
                        {AssetClassParking: res.data}
                    ))
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassParking$ = this.actions$.pipe(
        ofType<AssetClassParkingDeleted>(AssetClassParkingActionTypes.AssetClassParkingDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteAssetClass(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteAssetClassParkingFromTrash$ = this.actions$.pipe(
        ofType<AssetClassParkingDeletedFromTrash>(AssetClassParkingActionTypes.AssetClassParkingDeletedFromTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassParkingFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassParkingDeletedFromAdminTrash>(AssetClassParkingActionTypes.AssetClassParkingDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassParking$ = this.actions$.pipe(
        ofType<AssetClassParkingOnServerRestored>(AssetClassParkingActionTypes.AssetClassParkingOnServerRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassParkingRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAdminAssetClassParking$ = this.actions$.pipe(
        ofType<AssetClassParkingOnServerAdminRestored>(AssetClassParkingActionTypes.AssetClassParkingOnServerAdminRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassParkingAdminRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    flushTrash$ = this.actions$
        .pipe(
            ofType<AssetClassParkingTrashFlushed>(AssetClassParkingActionTypes.AssetClassParkingTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    duplicateParking$ = this.actions$
        .pipe(
            ofType<AssetClassParkingDuplicate>(AssetClassParkingActionTypes.DuplicateAssetClass),
            mergeMap(({payload}) => {
                return this.service.duplicate(payload.id)
            }),
            map((response) => {
                return new AssetClassParkingCreated({AssetClassParking: response.data})
            })
        )
}