// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {IpmsStandard} from '../_models/ipms-standard.model';

export enum IpmsStandardActionTypes {

    AllIpmsStandardsRequested = '[IpmsStandards Home Page] All IpmsStandards Requested',
    AllIpmsStandardsLoaded = '[IpmsStandards API] All IpmsStandards Loaded',

    IpmsStandardOnServerCreated = '[Edit IpmsStandard Dialog] IpmsStandard On Server Created',
    IpmsStandardCreated = '[Edit IpmsStandards Dialog] IpmsStandards Created',
    IpmsStandardUpdated = '[Edit IpmsStandard Dialog] IpmsStandard Updated',
    IpmsStandardDeleted = '[IpmsStandards List Page] IpmsStandard Deleted',

    IpmsStandardsPageRequested = '[IpmsStandards List Page] IpmsStandards Page Requested',
    IpmsStandardsPageLoaded = '[IpmsStandards API] IpmsStandards Page Loaded',
    IpmsStandardsPageCancelled = '[IpmsStandards API] IpmsStandards Page Cancelled',

    IpmsStandardsPageToggleLoading = '[IpmsStandards page] IpmsStandards Page Toggle Loading',
    IpmsStandardsActionToggleLoading = '[IpmsStandards] IpmsStandards Action Toggle Loading',

    // trash system

    IpmsStandardOnServerRestored = '[IpmsStandard Trash] IpmsStandard On Server Restored',
    IpmsStandardRestored = '[IpmsStandard Trash] IpmsStandard Restored',

    IpmsStandardOnServerAdminRestored = '[IpmsStandard Admin Trash] IpmsStandard On Server Restored',
    IpmsStandardAdminRestored = '[IpmsStandard Admin Trash] IpmsStandard Restored',

    IpmsStandardDeletedFromTrash = '[IpmsStandard Trash] IpmsStandard deleted',
    IpmsStandardDeletedFromAdminTrash = '[IpmsStandard Admin Trash] IpmsStandard deleted',

    IpmsStandardTrash = 'IpmsStandard Trashed',
    IpmsStandardTrashFlushed = 'IpmsStandard Trash Flushed',
}

export class IpmsStandardOnServerCreated implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardOnServerCreated;

    constructor(public payload: { ipmsStandard: IpmsStandard}) {
    }
}

export class IpmsStandardCreated implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardCreated;

    constructor(public payload: { ipmsStandard: IpmsStandard }) {
    }
}

export class IpmsStandardUpdated implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardUpdated;

    constructor(public payload: {
        partialipmsStandard: Update<IpmsStandard>,
        ipmsStandard: IpmsStandard
    }) {
    }
}

export class IpmsStandardDeleted implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsStandardsPageRequested implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class IpmsStandardsPageLoaded implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardsPageLoaded;

    constructor(public payload: { ipmsStandards: IpmsStandard[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class IpmsStandardsPageCancelled implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardsPageCancelled;
}

export class AllIpmsStandardsRequested implements Action {
    readonly type = IpmsStandardActionTypes.AllIpmsStandardsRequested;
}

export class AllIpmsStandardsLoaded implements Action {
    readonly type = IpmsStandardActionTypes.AllIpmsStandardsLoaded;

    constructor(public payload: { ipmsStandards: IpmsStandard[] }) {
    }
}

export class IpmsStandardsPageToggleLoading implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class IpmsStandardsActionToggleLoading implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class IpmsStandardDeletedFromAdminTrash implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class IpmsStandardDeletedFromTrash implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsStandardOnServerRestored implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsStandardRestored implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardRestored;

    constructor(public payload: { item: IpmsStandard }) {
    }
}

export class IpmsStandardOnServerAdminRestored implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsStandardAdminRestored implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardAdminRestored;

    constructor(public payload: { item: IpmsStandard }) {
    }
}

export class IpmsStandardTrashFlushed implements Action {
    readonly type = IpmsStandardActionTypes.IpmsStandardTrashFlushed;

    constructor() {
    }
}

export type IpmsStandardActions = IpmsStandardCreated
    | IpmsStandardUpdated
    | IpmsStandardDeleted
    | IpmsStandardsPageRequested
    | IpmsStandardsPageLoaded
    | IpmsStandardsPageCancelled
    | AllIpmsStandardsLoaded
    | AllIpmsStandardsRequested
    | IpmsStandardOnServerCreated
    | IpmsStandardsPageToggleLoading
    | IpmsStandardsActionToggleLoading
    | IpmsStandardDeletedFromAdminTrash
    | IpmsStandardDeletedFromTrash
    | IpmsStandardOnServerRestored
    | IpmsStandardRestored
    | IpmsStandardOnServerAdminRestored
    | IpmsStandardAdminRestored
    | IpmsStandardTrashFlushed;
