import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SubdomainService } from "../_base/subdomain.service";

export interface ITimeZone {
  name: string,
  gmtValue: string,
}


@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private _timeZones$ = new BehaviorSubject<ITimeZone[]>([]);
  private _timeZones: Array<ITimeZone> = []
  private _filter$ = new BehaviorSubject<{name: string}>({name: ''})

  public timeZones$ = combineLatest([
    this._timeZones$,
    this._filter$
  ]).pipe(map(([timezones, {name}]) => {
    return timezones.filter(timezone => timezone.name.toLowerCase().includes(name.toLowerCase()))
  }))

  constructor(
    private httpClient: HttpClient,
    private subDomainService: SubdomainService
  ) {
    this.httpClient.get<{data: any[]}>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/timezones`).subscribe(res => {
      this._timeZones = res.data.map(item => ({name: item.value, gmtValue: item.GMT}));
      this._timeZones$.next(res.data.map(item => ({name: item.value, gmtValue: item.GMT})))
    })
  }

  public setFilter(name: string) {
    this._filter$.next({name})
  }

  public getGmt(_name: string) {
    const timezone = this._timeZones.find(({name}) => name == _name)
    return timezone ? timezone.gmtValue : null;
  }
}