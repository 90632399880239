// Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CoiDetailsTPModel } from '../../../../../../core/toe';
import {
    AssetClassType, UnitAreaMeasurement
} from '../../../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../../../../../core/reducers';

const CONFLICTS: { id: number, name: string }[] = [{ id: 1, name: 'No' }, { id: 2, name: 'Existing' }, { id: 3, name: 'Potential' }];
const TYPE_OF_CONFLICT: any[] = [
    { id: 1, name: 'Party Conflict', descr: 'a situation in which the duty of an RICS member (working independently or within a non-regulated firm or within a regulated firm) or a regulated firm to act in the interests of a client or other party in a professional assignment conflicts with a duty owed to another client or party in relation to the same or a related professional assignment' },
    { id: 2, name: 'Own Interest Conflict', descr: 'a situation in which the duty of an RICS member (working independently or within a non-regulated firm or within a regulated firm) or a regulated firm to act in the interests of a client in a professional assignment conflicts with the interests of that same RICS member/ firm (or in the case of a regulated firm, the interests of any of the individuals within that regulated firm who are involved directly or indirectly in that or any related professional assignment)' },
    { id: 3, name: 'Confidential Information Conflict', descr: 'a conflict between the duty of an RICS member (working independently or within a non-regulated firm or within a regulated firm) under paragraph 2.2 of the PS mentioned above to provide material information to one client, and the duty of that RICS member (working independently or within a non-regulated firm) or of a regulated firm under paragraph 2.1 (ibid) to another client to keep that same information confidential' },
];

@Component({
    selector: 'kt-tp-add-conflict-dialog',
    templateUrl: './tp-add-conflict-dialog.component.html',
    styleUrls: ['./tp-add-conflict-dialog.component.scss']
})
export class TpAddConflictDialogComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    property_coi: CoiDetailsTPModel;

    unitAreaMeasurements: UnitAreaMeasurement[] = [];
    assetClassTypes: AssetClassType[] = [];
    conflicts: { id: number, name: string }[] = [];
    tp: any[] = [];
    typeOfConflicts: any[] = [];

    private subscriptions: Subscription[] = [];


    /**
     * Component constructor
     *
     */
    constructor(public dialogRef: MatDialogRef<TpAddConflictDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>,
                private toeFB: UntypedFormBuilder,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.conflicts = CONFLICTS;
        this.typeOfConflicts = TYPE_OF_CONFLICT;

        if (this.data && this.data.item) {
            this.tp = this.data.tp;
            this.property_coi = Object.assign({}, this.data.item) as CoiDetailsTPModel;
        } else {
            this.property_coi = new CoiDetailsTPModel();
        }

        this.createForm();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    getTitle() {
        return 'Edit';
    }


    createForm() {
        this.form = this.toeFB.group({
            conflict_id: [this.property_coi.conflict_id, Validators.required],
            conflict_descr: [this.property_coi.conflict_descr],
            conflict_descr_b: [this.property_coi.conflict_descr_b],
            type_of_conflict_id: [this.property_coi.type_of_conflict_id],
            appropriate_parties: [this.property_coi.appropriate_parties],
            reason_continue: [this.property_coi.reason_continue],
        });

        this.form.controls.conflict_id.valueChanges.subscribe(value => {
            if (value === 1) {
                this.form.controls.conflict_descr.clearValidators();
                this.form.controls.conflict_descr.setValue('N/A');
                
                this.form.controls.conflict_descr_b.clearValidators();
                this.form.controls.conflict_descr_b.setValue('N/A');

                this.form.controls.type_of_conflict_id.clearValidators();
                this.form.controls.type_of_conflict_id.setValue(null);

                this.form.controls.appropriate_parties.clearValidators();
                this.form.controls.appropriate_parties.setValue('N/A');

                this.form.controls.reason_continue.clearValidators();
                this.form.controls.reason_continue.setValue('N/A');
            } else {
                this.form.controls.conflict_descr.setValue('');
                this.form.controls.conflict_descr.setValidators([Validators.required, Validators.minLength(20)]);

                this.form.controls.conflict_descr_b.setValue('');
                this.form.controls.conflict_descr_b.setValidators([Validators.required, Validators.minLength(20)]);

                this.form.controls.type_of_conflict_id.setValue(null);
                this.form.controls.type_of_conflict_id.setValidators([Validators.required]);

                this.form.controls.appropriate_parties.setValue('');
                this.form.controls.appropriate_parties.setValidators([Validators.required]);

                this.form.controls.reason_continue.setValue('');
                this.form.controls.reason_continue.setValidators([Validators.required]);
            }
            this.form.controls.conflict_descr.updateValueAndValidity();
            this.form.controls.conflict_descr_b.updateValueAndValidity();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    isValid() {
        return this.form.valid;
    }

    onSubmit() {
        this.property_coi.conflict_id = this.form.controls.conflict_id.value;
        this.property_coi.conflict_name = this.conflicts.find(el => el.id == this.property_coi.conflict_id).name;
        this.property_coi.conflict_descr = this.form.controls.conflict_descr.value;
        this.property_coi.conflict_descr_b = this.form.controls.conflict_descr_b.value;
        this.property_coi.type_of_conflict_id = this.form.controls.type_of_conflict_id.value;
        this.property_coi.appropriate_parties = this.form.controls.appropriate_parties.value;
        this.property_coi.reason_continue = this.form.controls.reason_continue.value;

        this.dialogRef.close(this.property_coi);
    }

    setSelectedTps(item): boolean {
        return this.property_coi.asset_class_names.includes(item.name);
    }

    updateCheckedOptions(item, $event) {
        if (!$event.checked) {
            this.property_coi.asset_class_names = this.property_coi.asset_class_names.replace(item.name, '');
            if (this.property_coi.asset_class_names.charAt(0) === ', ') {
                this.property_coi.asset_class_names = this.property_coi.asset_class_names.substr(1);
            }
            if (this.property_coi.asset_class_names.charAt(this.property_coi.asset_class_names.length - 1) === ', ') {
                this.property_coi.asset_class_names = this.property_coi.asset_class_names.slice(0, this.property_coi.asset_class_names.lastIndexOf(','));
            }
        } else {
            this.property_coi.asset_class_ids = this.property_coi.asset_class_ids + ', ' + item.id;
            this.property_coi.asset_class_names = this.property_coi.asset_class_names + ', ' + item.name;
        }
        if (this.property_coi.asset_class_names.includes(',,')) {
            this.property_coi.asset_class_names = this.property_coi.asset_class_names.replace(',,', ', ');
        }
        if (this.property_coi.asset_class_names.includes(',, ')) {
            this.property_coi.asset_class_names = this.property_coi.asset_class_names.replace(',, ', ', ');
        }
        if (this.property_coi.asset_class_names.includes(', , ')) {
            this.property_coi.asset_class_names = this.property_coi.asset_class_names.replace(', , ', ', ');
        }
    }
}
