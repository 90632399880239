<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{title}}</h3>
        </div>
        <div class="card-toolbar">
            <button class="btn btn-icon btn-sm btn-warning"
                (click)="close()"
                data-card-tool="remove"
                data-toggle="tooltip"
                data-placement="top"
                >
                <i class="ki ki-close icon-sm"></i>
            </button>
        </div>
    </div>
    <div class="card-body m-0">
        <ngb-carousel [interval]="0" [activeId]="data.initIndex">
            <ng-template *ngFor="let picture of pictures" [id]="picture.id" ngbSlide>
                <img [src]="picture.url" class="rounded" style="height: 700px; width: 100%; object-fit: cover;" (error)="onImageError($event)">
                <div class="image-caption">
                    {{picture.title}}
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>