import { VAssetClassModelTypes } from "src/app/core/valuation-process/_models/valuation-asset-class.model";
import { VHouseExternalAspect } from "src/app/core/valuation-process/_models/valuation-house.model";
import { PropertySubTypes } from "src/app/core/valuation-process/_models/valuation-process-target-property.model";
import { VRetailBuildingExternalAspect } from "src/app/core/valuation-process/_models/valuation-retail-building.model";
import { VWarehouseExternalAspect } from "src/app/core/valuation-process/_models/valuation-warehouse.model";
import { Cells, mergeRows, simpleCellCreator, Row, mergeRRows } from "./comparable-info-dialog.types";

const houseItems: {header: string, field: keyof VHouseExternalAspect}[] = [
    {header: 'Chimney Stack', field: 'chimneyStack'},
    {header: 'Chimney Stack comment', field: 'chimneyStackComment'},
    {header: 'Roof Covering', field: 'roofCovering'},
    {header: 'Roof Covering comment', field: 'roofCoveringComment'},
    {header: 'Rainwater pipes and gutters', field: 'rainwaterPipe'},
    {header: 'Rainwater pipes and gutters comment', field: 'rainwaterPipeComment'},
    {header: 'Main wall', field: 'mainWall'},
    {header: 'Main wall comment', field: 'mainWallComment'},
    {header: 'Window', field: 'window'},
    {header: 'Window comment', field: 'windowComment'},
    {header: 'Outside door', field: 'outsideDoor'},
    {header: 'Outside door comment', field: 'outsideDoorComment'},
    {header: 'Conservatory and porches', field: 'conservatory'},
    {header: 'Conservatory and porches comment', field: 'conservatoryComment'},
    {header: 'Other joinery and finishes', field: 'otherJoinery'},
    {header: 'Other joinery and finishes comment', field: 'otherJoineryComment'},
    {header: 'Other aspect', field: 'others'}
]
const warehouseItems: {header: string, field: keyof VWarehouseExternalAspect}[] = [
    {header: 'Roof Covering', field: 'roofCovering'},
    {header: 'Roof Covering comment', field: 'roofCoveringComment'},
    {header: 'Rainwater pipes and gutters', field: 'rainwaterPipe'},
    {header: 'Rainwater pipes and gutters comment', field: 'rainwaterPipeComment'},
    {header: 'Main wall', field: 'mainWall'},
    {header: 'Main wall comment', field: 'mainWallComment'},
    {header: 'Window', field: 'window'},
    {header: 'Window comment', field: 'windowComment'},
    {header: 'Outside door', field: 'outsideDoor'},
    {header: 'Outside door comment', field: 'outsideDoorComment'},
    {header: 'Loading Docks', field: 'loadingDock'},
    {header: 'Other aspect', field: 'others'}
]
const retailBuildingItems: {header: string, field: keyof VRetailBuildingExternalAspect}[] = [
    {header: 'Roof Covering', field: 'roofCovering'},
    {header: 'Roof Covering comment', field: 'roofCoveringComment'},
    {header: 'Rainwater pipes and gutters', field: 'rainwaterPipe'},
    {header: 'Rainwater pipes and gutters comment', field: 'rainwaterPipeComment'},
    {header: 'Main wall', field: 'mainWall'},
    {header: 'Main wall comment', field: 'mainWallComment'},
    {header: 'Window', field: 'window'},
    {header: 'Window comment', field: 'windowComment'},
    {header: 'Outside door', field: 'outsideDoor'},
    {header: 'Outside door comment', field: 'outsideDoorComment'},
    {header: 'Loading Docks', field: 'loadingDock'},
    {header: 'Other aspect', field: 'others'}
]

const Items = {
    'House': houseItems,
    'Warehouse': warehouseItems,
    'Retail Building': retailBuildingItems
}

export function externalAspectRows(assetClasses: {
    refNum: string,
    propertySubType: PropertySubTypes,
    propertySubTypeName: string,
    externalAspect: VHouseExternalAspect | VWarehouseExternalAspect | VRetailBuildingExternalAspect | null 
}[]): Row[] {
    const rrows = assetClasses.map(assetClasses => {
        const items = Items[assetClasses.propertySubType]
        if (!items) {
            return []
        }
        return items.map(item => {
            const cells: Cells = {}
            cells[assetClasses.refNum] = simpleCellCreator(assetClasses.externalAspect[item.field])
            return {
                header: item.header,
                cells,
                propertySubType: assetClasses.propertySubTypeName
            }
        })
    })

    const mergedRows = mergeRRows(rrows)
    return mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))
}