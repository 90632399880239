import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CriterionsTemplateModel, IDefaultCriterion, TemplateRequest, TemplateResponse } from "../_models";
import { HttpUtilsService } from "../../_base/crud";
import { map } from "rxjs/operators";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class CriterionsTemplateService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/templates/criterions`;
    constructor (
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    getAll(request: TemplateRequest): Observable<TemplateResponse<CriterionsTemplateModel>> {
        const headers = this.authService.getAuthHeaders();
        const params = new HttpParams()
            .set("page", request.pageNumber.toString())
            .set("pageSize", request.pageSize.toString())
            .set("ac_type", request.assetClassTypeID ? request.assetClassTypeID.toString() : '-1');

        return this.http.get<{
            data: CriterionsTemplateModel[],
            pagination: {
                total: number
            }
        }>(this.API_URL, {
            headers,
            params
        }).pipe(
            map(res => {
                const tres: TemplateResponse<CriterionsTemplateModel> = {
                    templates: res.data,
                    total: res.pagination.total
                }
                return tres;
            })
        )
    }

    get(id: number): Observable<CriterionsTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.get<{data: CriterionsTemplateModel}>(this.API_URL + `/${id}`, {headers}).pipe(
            map(res => res.data)
        );
    }

    create(template: CriterionsTemplateModel): Observable<CriterionsTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.post<{data: CriterionsTemplateModel}>(this.API_URL, template, {headers}).pipe(map(res => res.data));
    }

    update(id: number, template: CriterionsTemplateModel): Observable<CriterionsTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.put<{data: CriterionsTemplateModel}>(this.API_URL + `/${id}`, template, {headers}).pipe(map(res => res.data));
    }

    delete(id: number): Observable<{sucess: boolean, id: number}> {
        const headers = this.authService.getAuthHeaders();
        return this.http.delete<{sucess: boolean, id: number}>(this.API_URL + `/${id}`, {headers});
    }

    duplicate(template: CriterionsTemplateModel): Observable<CriterionsTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.post<{data: CriterionsTemplateModel}>(this.API_URL + '/duplicate', template, {headers}).pipe(map(res => res.data));
    }

    getDefaults(acTypeId: number): Observable<IDefaultCriterion[]> {
        const headers = this.authService.getAuthHeaders();
        const params = new HttpParams()
            .set("ac_type_id", acTypeId.toString());
        return this.http.get<{
            criterions: IDefaultCriterion[],
        }>(this.API_URL + '/defaults', {
            headers,
            params
        }).pipe(
            map(res => {
                return res.criterions
            })
        );
    }
}