// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
import {of, forkJoin} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {TpAdminTaskService} from '../_services/tp-admin-task.service';
// Actions
import {
    AllTpAdminTaskRequested,
    AllTpAdminTaskLoaded,
    AllTpAdminTaskLoadedByUser,
    TpAdminTaskActionTypes,
    TpAdminTaskActionToggleLoading,
    TpAdminTaskCreated,
    TpAdminTaskOnServerCreated,
    TpAdminTaskUpdated,
    TpAdminTaskDeleted,
    AllTpAdminTaskByUserRequested,
    TpAdminTaskByUserPageRequested,
    TpAdminTaskByUserPageLoaded,
} from '../_actions/tp-admin-task.actions';
import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';

import {AppState} from '../../reducers';

@Injectable()
export class TpAdminTaskEffects {

    showActionLoadingDispatcher = new TpAdminTaskActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TpAdminTaskActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTpAdminTask$ = this.actions$
        .pipe(
            ofType<AllTpAdminTaskRequested>(TpAdminTaskActionTypes.AllTpAdminTaskRequested),
            mergeMap(() => this.service.getAll()),
            map((result: any) => {
                return new AllTpAdminTaskLoaded({
                    tasks: result.data
                });
            })
        );

    @Effect()
    createTpAdminTask$ = this.actions$
        .pipe(
            ofType<TpAdminTaskOnServerCreated>(TpAdminTaskActionTypes.TpAdminTaskOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.task).pipe(
                    tap(res => {
                        if (res) {
                            this.store.dispatch(new TpAdminTaskCreated({
                                task: res.data
                            }));
                        }
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    deleteTpAdminTask$ = this.actions$
        .pipe(
            ofType<TpAdminTaskDeleted>(TpAdminTaskActionTypes.TpAdminTaskDeleted),
            mergeMap(({payload}) => {
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTpAdminTask$ = this.actions$
        .pipe(
            ofType<TpAdminTaskUpdated>(TpAdminTaskActionTypes.TpAdminTaskUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    AllTpAdminTaskByUser$ = this.actions$
        .pipe(
            ofType<AllTpAdminTaskByUserRequested>(TpAdminTaskActionTypes.AllTpAdminTaskByUserRequested),
            mergeMap(({payload}) => {
                const requestToServer = this.service.getByUserId(payload.page, payload.user_id, payload.filterModel);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map((response: any) => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AllTpAdminTaskLoadedByUser({
                    tasks: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery,
                });
            })
        );

    @Effect()
    loadTpAdminTaskByUserPage$ = this.actions$.pipe(
        ofType<TpAdminTaskByUserPageRequested>(TpAdminTaskActionTypes.TpAdminTaskByUserPageRequested),
        mergeMap(({payload}) => {
            // this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getByUserId(payload.page, payload.user_id);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new TpAdminTaskByUserPageLoaded({
                assetClasses: result.data,
                totalCount: result.pagination.total,
                page: lastQuery
            });
        })
    );

    constructor(private actions$: Actions, private service: TpAdminTaskService, private store: Store<AppState>) {
    }
}
