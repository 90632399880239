import { AbstractControl, FormArray, FormControl } from "@angular/forms";
import { Observable, defer } from "rxjs";
import { shareReplay, startWith } from "rxjs/operators";


// export function formValueChanges<T>(formControl: FormControl<T>): Observable<T>
// export function formValueChanges<T>(formControl: FormArray<FormControl<T>>): Observable<T>
export function formControlValueChanges<T>(formControl: FormControl<T>) {
  return defer(() => formControl.valueChanges.pipe(
    startWith(formControl.value)
  )).pipe(shareReplay(1))
}
// export function formValueChanges<T>(formArray: FormArray<T>) {
//   return defer(() => formArray.valueChanges.pipe(
//     startWith(formArray.value)
//   )).pipe(shareReplay(1))
// }
