<div class="row">
    <div class="col-12">
        <h4 class="my-3 text-mad text-uppercase d-flex align-items-center">
            <span>
                Additional Terms
            </span>
            <mat-icon matSuffix class="cursor-pointer icon-gray ml-4"
                inline
                container="body"
                [ngbPopover]="'This section allows you to add new terms at specific places in the Terms of Engagement'"
                (click)="$event.stopPropagation();">help
            </mat-icon>
        </h4>
        <hr class="active">
    </div>
    <div class="col-12">
        <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container" *ngIf="!readonly">
            <div>
                <button class="btn btn-primary" color="primary" mat-raised-button (click)="addItem()">
                    Add Term
                </button>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="example-container mat-elevation-z0">
            <mat-table #table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.title}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="content">
                    <mat-header-cell *matHeaderCellDef>Content</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.content_trimmed}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.location_name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item, let i = index">
                        <button mat-icon-button color="primary" matTooltip="Edit Description"
                            (click)="editItem(i, item)" type="button">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;
                        <button mat-icon-button color="warn" matTooltip="Delete File" (click)="deleteItem(i)"
                            *ngIf="!readonly"
                            type="button">
                            <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</div>

<ng-template #na>
    N/A
</ng-template>