import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IpmsStandard} from '../_models/ipms-standard.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class IpmsStandardsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/ipms-standards`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // IpmsStandards
    getAllIpmsStandards(): Observable<any> {
        return this.http.get<any>(this.API_URL, {headers: this.authService.getAuthHeaders()});
    }


    findIpmsStandards(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = this.API_URL;
		return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
    }

    getIpmsStandardById(ipmsStandardId: number): Observable<IpmsStandard> {
        return this.http.get<IpmsStandard>(this.API_URL + `/${ipmsStandardId}`, {headers: this.authService.getAuthHeaders()});
    }

    // CREATE =>  POST: add a new ipmsStandard to the server
    createIpmsStandard(ipmsStandard: IpmsStandard): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {ipmsStandard: ipmsStandard};

        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the ipmsStandard on the server
    updateIpmsStandard(ipmsStandard: IpmsStandard): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_URL + `/${ipmsStandard.id}`, ipmsStandard, {headers: httpHeaders});
    }

    // DELETE => delete the ipmsStandard from the server
    deleteIpmsStandard(ipmsStandardId: number): Observable<any> {
        const url = `${this.API_URL}/${ipmsStandardId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {headers: this.authService.getAuthHeaders()});
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: this.authService.getAuthHeaders()});
    }

    deleteFromTrash(agencyId: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${agencyId}`, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    // delete from admin trash
    deleteFromAdminTrash(agencyId: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${agencyId}`, {headers: this.authService.getAuthHeaders()});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
