import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpUtilsService} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import {CurrencyModel} from '../_models/currency.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class CurrencyService {
    API_CURRENCY_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/currencies`;

    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }


    // Currency
    getAllCurrencies(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_CURRENCY_URL,
            {headers: httpHeaders});
    }

    // DELETE => delete the currency from the server
    deleteCurrency(currencyId: number): Observable<any> {
        const url = `${this.API_CURRENCY_URL}/${currencyId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url,
            {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new currency to the server
    createCurrency(currency: CurrencyModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_CURRENCY_URL, currency, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the currency on the server
    updateCurrency(currency: CurrencyModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_CURRENCY_URL + `/${currency.id}`, currency,
            {
                headers: httpHeaders
            });
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CURRENCY_URL + '/trash',
            {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CURRENCY_URL + '/trash/flush',
            {headers: httpHeaders});
    }

    deleteFromTrash(agencyId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_CURRENCY_URL + `/trash/${agencyId}`,
            {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_CURRENCY_URL + `/admin/trash`,
            {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(agencyId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_CURRENCY_URL + `/admin/trash/${agencyId}`,
            {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CURRENCY_URL + `/trash/${id}`,
            {headers: httpHeaders});
    }


    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
