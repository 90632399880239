// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {FireplacesChimneyBreastActions, FireplacesChimneyBreastActionTypes} from '../_actions/fireplaces-chimney-breast.actions';
// Models
import {FireplacesChimneyBreast} from '../_models/fireplaces-chimney-breast.model';
import {QueryParamsModel} from '../../_base/crud';

export interface FireplacesChimneyBreastsState extends EntityState<FireplacesChimneyBreast> {
    isAllFireplacesChimneyBreastsLoaded: boolean;
    queryRowsCount: number;
    queryResult: FireplacesChimneyBreast[];
    lastCreatedFireplacesChimneyBreastId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<FireplacesChimneyBreast> = createEntityAdapter<FireplacesChimneyBreast>();

export const initialFireplacesChimneyBreastsState: FireplacesChimneyBreastsState = adapter.getInitialState({
    isAllFireplacesChimneyBreastsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedFireplacesChimneyBreastId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function fireplacesChimneyBreastsReducer(state = initialFireplacesChimneyBreastsState, action: FireplacesChimneyBreastActions): FireplacesChimneyBreastsState {
    switch (action.type) {
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedFireplacesChimneyBreastId: undefined
            };
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerCreated:
            return {
                ...state
            };
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastCreated:
            return adapter.addOne(action.payload.fireplacesChimneyBreast, {
                ...state, lastCreatedFireplacesChimneyBreastId: action.payload.fireplacesChimneyBreast.id
            });
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastUpdated:
            return adapter.updateOne(action.payload.partialFireplacesChimneyBreast, state);
        case FireplacesChimneyBreastActionTypes.AllFireplacesChimneyBreastsLoaded:
            return adapter.addAll(action.payload.fireplacesChimneyBreasts, {
                ...state, isAllFireplacesChimneyBreastsLoaded: true
            });
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageLoaded:
            return adapter.addMany(action.payload.fireplacesChimneyBreasts, {
                ...initialFireplacesChimneyBreastsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
