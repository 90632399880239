import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NgxMaskPipe } from 'ngx-mask';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { awConst } from 'src/app/app.constants';
import { AppState } from 'src/app/core/reducers';
import { selectValuationFullComparables } from 'src/app/core/valuation-process';
import { AddComparableToValuationProcess, RemoveComparableFromValuationProcess, UpdateValuationProcessSelectedConsideration } from 'src/app/core/valuation-process/_actions/valuation-process.actions';
import { getPictureUrl, PictureModel, PropertySubTypes, TypeOfInspection, ValuationProcessTargetPropertyInfo, ValuationProcessTargetPropertyModel } from 'src/app/core/valuation-process/_models/valuation-process-target-property.model';
import { selectValuationProcessAdditionalData, selectValuationProcessSelectedComparables, selectValuationProcessSelectedConsiderations, selectValuationProcessTargetProperty, selectValuationProcessTargetPropertyInfo } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { DateAgoPipe } from 'src/app/views/theme/pipes/date-ago.pipe';
import { environment } from 'src/environments/environment';
import { BuildingInfoModalComponent } from '../../../valuation-process-edit/_sub/comparable-info-dialog/building-info-modal/building-info-modal.component';
import { PictureSliderDialogComponent } from '../picture-slider-dialog/picture-slider-dialog.component';
import * as _ from 'lodash'
import { VComparable } from 'src/app/core/valuation-process/_models/valuation-comparable.model';
import { VAssetClassConsideration } from 'src/app/core/valuation-process/_models/valuation-asset-class-common.models';
import { aboutPropertyRows } from './comparable-info-about-property';
import { Cell, Cells, cellsAreCountable, IRow, mergeRows, mergeRRows, mergeSizeRows, Row, simpleCellCreator } from './comparable-info-dialog.types';
import { externalAspectRows } from './comparable-info-external-aspect';
import { internalAspectRows } from './comparable-info-internal-aspect';
import { groundRows } from './comparable-info-ground';
import { VBuilding } from 'src/app/core/valuation-process/_models/valuation-asset-class-building.model';
import { SizeTableModalComponent } from '../../../valuation-process-edit/_sub/background-tab/size-modal/size-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConditionRatingV2Service } from '../../../services/condition-rating-v2.service';
import { SizesModalComponent } from '../../../valuation-process-edit/_sub/sizes-modal/sizes-modal.component';
import { ConditionRatingModalComponent } from '../../../valuation-process-edit/_sub/condition-rating-dialog/condition-rating-modal.component';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { VParcelIdentification } from 'src/app/core/valuation-process/_models/valuation-land-parcel.model';
import { filter } from 'lodash';
import { ParcelIdentificationModalComponent } from '../../../valuation-process-edit/_sub/comparable-info-dialog/parcel-identification-modal/parcel-identification-modal.component';
import ValuationUtils from 'src/app/core/valuation-process/_utils/map'
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { LeaseDurationType } from 'src/app/core/v2/types';
import { AssetClassTenure } from 'src/app/core/comparable';
import { durationCalculation } from 'src/app/core/v2/valuation';
import { calculateRecoverable } from 'src/app/core/v2/core';

export type ComparableInfoDialogV2DataInput = {
  refNums: string[],
  isMulti: boolean
}

export type ViewModel = {
  points: number,
  refNums: string[]
  locationData: {
    [key: string]: {
      latitude: number,
      longitude: number,
      label?: any
    }
  },
  considerationsInfo: {
    [key: string]: {
      comparableType: string,
      placeholder: string,
      selectedConsideration: number,
      considerations: {
        id: number,
        info: string,
      }[],
      sourceType: string,
      sourceInformation: string,
      sourceCredibility: string,
      sourceDescription: string,
      sourcePictures: Array<PictureModel & {url: string}> 
    }
  },
  targetPropertyInspectionInfo: Row[],
  locationInfo: {
    rows: Row[],
    counts: number
  },
  buildingInfo: {
    rows: Row[],
    counts: number,
    exists: boolean
  },
  aboutPropertyInfo: {
    rows: Row[],
    counts: number
  },
  internalAspectsInfo: {
    rows: Row[],
    counts: number,
    exists: boolean
  },
  externalAspectsInfo: {
    rows: Row[],
    counts: number,
    exists: boolean
  },
  groundInfo: {
    rows: Row[],
    counts: number,
    exists: boolean
  },
  sizesInfo: {
    rows: Row[],
    counts: number
  },
  serviceAndInfraInfo: {
    rows: Row[],
    counts: number
  }
  parcelIdentificationInfo: {
    rows: Row[],
    counts: number,
    exists: boolean
  },
  parcelLandAreaInfo: {
    rows: Row[],
    counts: number,
    exists: boolean
  },
  leaseDetailsInfo: {
    [key: string]: {
      startDate: string,
      leaseDuration: number,
      rentFreePeriod: number,
      fittingOutPeriod: number,
      capitalPayment: number,
      writeOffPeriod: number,
      breakOptionAfter: number,
      currency: string,
      rentReviewType: string
      rentReviewCycle: number
      rentReviewDetail: number,
      totalRecoverable: number
      durationType: string
    }
  }
}

@Component({
  selector: 'kt-comparable-info-dialog-v2',
  templateUrl: './comparable-info-dialog-v2.component.html',
  styleUrls: ['./comparable-info-dialog-v2.component.scss'],
  providers: [DateAgoPipe, DatePipe, NgxMaskPipe]
})
export class ComparableInfoDialogV2Component implements OnInit {

  private _loading$ = new BehaviorSubject<boolean>(true);
  loading$ = this._loading$.asObservable()

  private _show100Fields$ = new BehaviorSubject<boolean>(true);

  targetProperty: ValuationProcessTargetPropertyModel 

  title$ = this.store$.select(selectValuationFullComparables(this.data.refNums)).pipe(
    map(comparables => {
      if (comparables.length == 1) {
        const comparable = comparables[0]
        return `${comparable.propertySubTypeName} #${comparable.refNum}`
      }
      return 'Comparables Overview'
    })
  )

  comparableSelectionInfo$ = this.store$.select(selectValuationProcessSelectedComparables).pipe(
    map((refNums) => {
      const refNum = this.data.refNums[0]
      const full = refNums.length == 5;
      const includes = refNums.includes(refNum)
      const action = includes ? 'remove' : 'add' as const
      return {
        tooltip: full && !includes 
          ? 'You can choose to limit 5 comparables'
          : includes
            ? 'Remove this comparable'
            : 'Select this comparable',
        buttonText: includes ? 'remove' : 'add',
        disabled: full && !includes,
        action,
        refNum
      }
    })
  )

  viewModel$ = combineLatest([
    this.store$.select(selectValuationProcessTargetProperty),
    this.store$.select(selectValuationFullComparables(this.data.refNums)),
    this.store$.select(selectValuationProcessSelectedConsiderations),
    this.store$.select(selectValuationProcessTargetPropertyInfo),
    this._show100Fields$.asObservable()
  ]).pipe(
    map(([targetProperty, comparables, selectedConsiderations, tp, show100Fields]) => {
      this.targetProperty = tp;
      const locationInfo = this._locationInfo(targetProperty, comparables)
      const buildingInfo = this.buildingInfo(targetProperty, comparables, show100Fields)
      const aboutPropertyInfo = this.aboutPropertyInfo(targetProperty, comparables, show100Fields)
      const internalAspectsInfo = this.internalAspectInfo(targetProperty, comparables, show100Fields)
      const groundInfo = this.groundsInfo(targetProperty, comparables, show100Fields)
      const externalAspectsInfo = this.externalAspectInfo(targetProperty, comparables, show100Fields)
      const sizesInfo = this.sizesInfo(targetProperty, comparables, tp)
      const parcelIdentificationInfo = this.parcelIdentificationInfo(targetProperty, comparables, show100Fields)
      const parcelLandAreaInfo = this.parcelLandAreaInfo(targetProperty, comparables, show100Fields)
      const serviceAndInfraInfo = this.serviceAndInfraInfo(targetProperty, comparables, show100Fields)
      const leaseDetailsInfo = this.leaseDetailsInfo(comparables, selectedConsiderations)
      const viewModel: ViewModel = {
        points: locationInfo.counts + buildingInfo.counts + aboutPropertyInfo.counts + internalAspectsInfo.counts + groundInfo.counts + externalAspectsInfo.counts + sizesInfo.counts + parcelIdentificationInfo.counts + parcelLandAreaInfo.counts + serviceAndInfraInfo.counts,
        refNums: this.data.refNums,
        locationData: this._locationData(targetProperty, comparables),
        considerationsInfo: this._considerationsInfo(comparables, selectedConsiderations),
        targetPropertyInspectionInfo: this._targetPropertyInspectionInfo(targetProperty),
        locationInfo,
        buildingInfo,
        aboutPropertyInfo,
        internalAspectsInfo,
        groundInfo,
        externalAspectsInfo,
        sizesInfo,
        parcelIdentificationInfo,
        parcelLandAreaInfo,
        serviceAndInfraInfo,
        leaseDetailsInfo
      }
      this._loading$.next(false)
      return viewModel
    })
  )


  icon: google.maps.Icon = {
    url: './assets/media/icons/my_marker.svg',
    labelOrigin: new google.maps.Point(18, 40)
  }
  tpIcon: google.maps.Icon = {
    url: './assets/media/icons/pin_red.svg',
    scaledSize: new google.maps.Size(30, 30),
    labelOrigin: new google.maps.Point(18, 22)
  }

  private _label = {
    color: '#000000',
    fontFamily: '',
    fontSize: '14px',
    fontWeight: 'bold',
    className: 'bg-op',
    text: ''
  }

  private _tpFull;
  private _ratings;

  constructor(
    public dialogRef: MatDialogRef<ComparableInfoDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: ComparableInfoDialogV2DataInput,
    private store$: Store<AppState>,
    private maskPipe: NgxMaskPipe,
    private datePipe: DatePipe,
    private dateAgoPipe: DateAgoPipe,
    private typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private crService: ConditionRatingV2Service,
    private smUtility: StandardMeasurementUtility,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void { 
    this.store$.select(selectValuationProcessAdditionalData).subscribe(add => {
      this._tpFull = add.assetClass
      this._ratings = add.ratings
    })
  }

  private _locationData(targetProperty: ValuationProcessTargetPropertyInfo, comparables: VComparable[]): ViewModel['locationData'] {
    const data: ViewModel['locationData'] = {
      tp: {
        latitude: targetProperty.locationData.latitude,
        longitude: targetProperty.locationData.longitude
      }
    }
    comparables.forEach(comparable => {
      data[comparable.refNum] = {
        latitude: comparable.locationData.latitude,
        longitude: comparable.locationData.longitude,
        label: {
          ...this._label,
          text: comparable.refNum
        }
      }
    })
    return data
  }

  private _considerationsInfo(comparables: VComparable[], selectedConsiderations: Array<{ refNum: string, id: number }>): ViewModel['considerationsInfo'] {
    const NOTSELECTED = 'Not Selected'
    const UNKNOWN = 'Unknown'
    const data: ViewModel['considerationsInfo'] = {}
    comparables.forEach(comparable => {
      const selectedConsideration = selectedConsiderations.find(item => item.refNum == comparable.refNum)
      let sourceType: string = NOTSELECTED
      let sourceInformation: string = NOTSELECTED
      let sourceCredibility: string = NOTSELECTED
      let sourceDescription: string = NOTSELECTED
      let consideration: VAssetClassConsideration = null
      let sourcePictures: Array<PictureModel&{url: string}> = null
      if (selectedConsideration) {
        consideration = comparable.considerations.find(consideration => consideration.id == selectedConsideration.id)
      }
      if (comparable.considerations.length == 1) {
        consideration = comparable.considerations[0]
      }
      if (consideration) {
        sourceType = UNKNOWN
        sourceInformation = UNKNOWN
        sourceCredibility = UNKNOWN
        sourceDescription = UNKNOWN
        sourcePictures = []
        if (consideration.source) {
          sourceType = `${consideration.source.sourceTypeName} - ${this.datePipe.transform(consideration.source.sourceDate, 'dd MMM yyyy')} - (${this.dateAgoPipe.transform(this.typesUtilsService.getDateStringFromDate(consideration.source.sourceDate))})`
          sourceInformation = consideration.source.sourceInformation
          sourceCredibility = consideration.source.sourceCredibility != UNKNOWN ? `${consideration.source.sourceCredibility}/5` : UNKNOWN
          sourceDescription = consideration.source.validationSource && consideration.source.validationSource.trim() != '' ? consideration.source.validationSource : UNKNOWN
          if (consideration.source.picture_1) {
            sourcePictures.push(this._sourcePictureToPictureModel(consideration.source.picture_1, 1))
          }
          if (consideration.source.picture_2) {
            sourcePictures.push(this._sourcePictureToPictureModel(consideration.source.picture_2, 2))
          }
        }
      }
      data[comparable.refNum] = {
        comparableType: `${comparable.propertyTypeName} (${comparable.propertySubTypeName})`,
        placeholder: `${comparable.considerations.length} consideration${comparable.considerations.length > 1 ? 's' : ''} (click to select)`,
        selectedConsideration: selectedConsiderations.find(item => item.refNum == comparable.refNum)?.id,
        considerations: comparable.considerations.map(consideration => {
          const value = this.maskPipe.transform(consideration.value, 'separator.2', {thousandSeparator: ','})
          const currency = consideration.tenure == 'Freehold' ? `${consideration.currency}` : `${consideration.currency} per month`
          const date = consideration.source ? this.datePipe.transform(consideration.source.sourceDate, 'dd MMM yyyy') : 'Unknown'
          return {
            id: consideration.id,
            info: `${consideration.tenureName} - ${value} ${currency} (${date})`
          }
        }),
        sourceType,
        sourceInformation,
        sourceCredibility,
        sourceDescription,
        sourcePictures
      }
    })
    return data
  }

  private serviceAndInfraInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['serviceAndInfraInfo'] {
    const _tp = {
      refNum: targetProperty.refNum,
      propertySubType: targetProperty.propertySubType,
      propertySubTypeName: targetProperty.propertySubTypeName,
      serviceAndInfra: null
    }
    return {
      rows: [],
      counts: 0
    }
  }

  private leaseDetailsInfo(comparables: VComparable[], selectedConsiderations: Array<{refNum: string, id: number}>): ViewModel['leaseDetailsInfo'] {
    const data: ViewModel['leaseDetailsInfo'] = {}
    comparables.forEach(comparable => {
      const selectedconsideration = selectedConsiderations.find(item => item.refNum === comparable.refNum)
      let consideration: VAssetClassConsideration = null
      if (selectedconsideration) {
        consideration = comparable.considerations.find(consideration => consideration.id === selectedconsideration.id)
      }
      if (comparable.considerations.length == 1) {
        consideration = comparable.considerations[0]
      }
      let tenure: AssetClassTenure
      if (consideration && consideration.considerationType !== 'Land') {
        tenure = consideration.oldTenure
      }
      data[comparable.refNum] = {
        durationType: tenure
          ? tenure.duration_type
          : null,
        startDate: tenure 
          ? tenure.start_date
          : null,
        currency: tenure
          ? tenure.currency
          : null,
        leaseDuration: tenure
          ? tenure.lease_duration
          : null,
        rentFreePeriod: tenure
          ? tenure.duration_type === 'months' 
            ? tenure.rent_free_period
            : tenure.rent_free_period * 12
          : null,
        fittingOutPeriod: tenure
          ? tenure.duration_type === 'months' 
            ? tenure.fitting_out_period
            : tenure.fitting_out_period * 12
          : null,
        writeOffPeriod: tenure
          ? tenure.duration_type === 'months' 
            ? tenure.write_off_period
            : tenure.write_off_period * 12
          : null,
        breakOptionAfter: tenure
          ? tenure.duration_type === 'months' 
            ? tenure.break_option
            : tenure.break_option * 12
          : null,
        capitalPayment: tenure
          ? tenure.cap_payment
          : null,
        rentReviewCycle: tenure
          ? tenure.duration_type === 'months'
            ? tenure.rent_review
            : tenure.rent_review * 12
          : null,
        rentReviewType: tenure
          ? tenure.rent_review_type_name
          : null,
        rentReviewDetail: tenure
          ? tenure.rent_review_detail
          : null,
        totalRecoverable: tenure
          ? calculateRecoverable(tenure)
          : null
      }
    })
    return data
  }

  private _targetPropertyInspectionInfo(targetProperty: ValuationProcessTargetPropertyInfo): ViewModel['targetPropertyInspectionInfo'] {
    const rows: Row[] = []

    rows.push({
      header: 'Date of Inspection',
      cells: {
        tp: {
          kind: 'simple',
          value: `${this.datePipe.transform(targetProperty.inspectionInfo.inspectionDate, 'dd MMM yyyy')} - (${this.dateAgoPipe.transform(this.typesUtilsService.getDateStringFromDate(targetProperty.inspectionInfo.inspectionDate))})`
        }
      },
      type: 'simple'
    })
    rows.push({
      header: 'Limitations or restrictions',
      cells: {
        tp: {
          kind: 'simple',
          value: !targetProperty.inspectionInfo.anyLimitationOrRestriction
            ? 'No'
            : `Yes ${targetProperty.inspectionInfo.limitationDescription && targetProperty.inspectionInfo.limitationDescription.length ? ('- ' + targetProperty.inspectionInfo.limitationDescription) : ''}`
        }
      },
      type: 'simple'
    })

    if (targetProperty.inspectionInfo.typeOfInspection == TypeOfInspection.Internal || targetProperty.inspectionInfo.typeOfInspection == TypeOfInspection.Full) {
      rows.push({
        header: 'Condition Rating',
        cells: {
          tp: {
            kind: 'click',
            value: 'View Condition Rating',
            onClick: () => { 
              this.showConditionRating()
            }
          }
        },
        type: 'simple'
      })
    }

    if (targetProperty.propertySubType != 'Land') {
      rows.push({
        header: 'Location and Surroundings Description',
        cells: {
          tp: simpleCellCreator(targetProperty.locationData.locationSurrounding)
        },
        type: 'simple'
      })
    }

    return rows
  }

  private _locationInfo(targetProperty: ValuationProcessTargetPropertyInfo, comparables: VComparable[]): ViewModel['locationInfo'] {
    const rows: Row[] = []
    let counts = 0

    const coordinateCells: { [key: string]: Cell } = {
      tp: {
        kind: 'simple',
        value: `${targetProperty.locationData.latitude}, ${targetProperty.locationData.longitude}`
      }
    }
    comparables.forEach(comparable => {
      coordinateCells[comparable.refNum] = {
        kind: 'simple',
        value: `${comparable.locationData.latitude}, ${comparable.locationData.longitude}`
      }
    })
    rows.push({
      header: 'Google coordinates',
      cells: coordinateCells,
      type: 'simple'
    })

    const propertyLocationCells: { [key: string]: Cell } = {
      tp: {
        kind: 'location',
        value: ''
      }
    }
    comparables.forEach(comparable => {
      const distOfCom = distance(
        comparable.locationData.latitude,
        comparable.locationData.longitude,
        targetProperty.locationData.latitude,
        targetProperty.locationData.longitude,
        'K'
      )
      propertyLocationCells[comparable.refNum] = {
        kind: 'location',
        value: distOfCom == 0 ? 'Located in the same location as Target Property' : `Located <b> ${distOfCom > 1 ? (distOfCom + 'km') : (distOfCom * 1000).toFixed(0) + 'm'} </b> from Target Property`
      }
    })
    rows.push({
      header: 'Property Location',
      cells: propertyLocationCells,
      type: 'simple'
    })

    const countryCells: Cells = {
      tp: simpleCellCreator(targetProperty.locationData.country)
    }
    comparables.forEach(comparable => {
      countryCells[comparable.refNum] = simpleCellCreator(comparable.locationData.country)
    })
    rows.push({
      header: 'Country',
      cells: countryCells,
      type: 'simple'
    })

    const cityCells: Cells = {
      tp: simpleCellCreator(targetProperty.locationData.city)
    }
    comparables.forEach(comparable => {
      cityCells[comparable.refNum] = simpleCellCreator(comparable.locationData.city)
    })
    rows.push({
      header: 'City',
      cells: cityCells,
      type: 'simple'
    })

    const zipCodeCells: Cells = {
      tp: simpleCellCreator(targetProperty.locationData.zipCode)
    }
    comparables.forEach(comparable => {
      zipCodeCells[comparable.refNum] = simpleCellCreator(comparable.locationData.zipCode)
    })
    rows.push({
      header: 'Zip Code',
      cells: zipCodeCells,
      type: 'simple'
    })

    const locationGradeCells: Cells = {
      tp: simpleCellCreator(targetProperty.locationData.locationGrade)
    }
    comparables.forEach(comparable => {
      locationGradeCells[comparable.refNum] = simpleCellCreator(comparable.locationData.locationGrade)
    })
    const locationGradeCountable = cellsAreCountable(locationGradeCells)
    counts += locationGradeCountable ? 1 : 0
    rows.push({
      header: 'Location Grade',
      cells: locationGradeCells,
      type: locationGradeCountable ? 'countable' : 'simple'
    })

    const addressCells: Cells = {
      tp: simpleCellCreator(targetProperty.locationData.address)
    }
    comparables.forEach(comparable => {
      addressCells[comparable.refNum] = simpleCellCreator(comparable.locationData.address)
    })
    const addressCountable = cellsAreCountable(addressCells)
    counts += addressCountable ? 1 : 0
    rows.push({
      header: 'Address',
      cells: addressCells,
      type: addressCountable ? 'countable' : 'simple'
    })

    return { rows, counts };
  }

  /**
   * Building
   */
  private buildingInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['buildingInfo'] {
    const _tp = {
      refNum: targetProperty.refNum,
      latitude: targetProperty.locationData.latitude,
      longitude: targetProperty.locationData.longitude,
      building: null,
      oldBuildingInfo: null
    }
    if (targetProperty.propertySubType != 'House' && targetProperty.propertySubType != 'Warehouse' && targetProperty.propertySubType != 'Retail Building' && targetProperty.propertySubType != 'Land') {
      _tp.building = targetProperty.building,
      _tp.oldBuildingInfo = targetProperty.oldBuildingInfo
    }
    const _comps = comparables.map(comparable => {
      const _com = {
        refNum: comparable.refNum,
        latitude: comparable.locationData.latitude,
        longitude: comparable.locationData.longitude,
        building: null,
        oldBuildingInfo: null
      }
      if (comparable.propertySubType != 'House' && comparable.propertySubType != 'Warehouse' && comparable.propertySubType != 'Retail Building' && comparable.propertySubType != 'Land') {
        _com.building = comparable.building
        _com.oldBuildingInfo = comparable.oldBuildingInfo
      }
      return _com
    })
    const buildings = _.concat([_tp], _comps)
    if (buildings.every(b => b.building == null)) {
      return {
        rows: [],
        counts: 0,
        exists: false
      }
    }

    const items: {header: string, field: keyof VBuilding}[] = [
      {header: 'Building ID', field: 'name'},
      {header: 'Completion Year', field: 'completionYear'},
      {header: 'Builting Type', field: 'buildingType'},
      {header: 'Builting Type comment', field: 'buildingTypeComment'},
      {header: 'Building Grade', field: 'buildingGrade'},
      {header: 'Energy Efficiency Grade', field: 'energyEfficiencyGrade'},
      {header: 'Developer', field: 'developer'},
      {header: 'Primary anchor tenant', field: 'anchorTenant'},
      {header: 'Foundation type', field: 'foundationType'},
      {header: 'Construction description', field: 'buildingDescription'}
    ]

    const rows = items.map(item => {
      const cells: Cells = {}
      buildings.forEach(b => cells[b.refNum] = b.building ? simpleCellCreator(b.building[item.field]) : {kind: 'na'})
      const isCountable = cellsAreCountable(cells)
      return {
        header: item.header,
        cells,
        type: isCountable ? 'countable' : 'simple' 
      } as const
    })

    const pictureModalTitle = 'Building Pictures'
    const pictureCells: Cells = {}
    buildings.forEach(b => pictureCells[b.refNum] = b.building ? this._picturesCellCreator(b.building.pictures, pictureModalTitle) : {kind: 'na'}) 
    rows.push({
      header: 'Pictures',
      cells: pictureCells,
      type: 'simple'
    })

    const buildingInfoCells: Cells = {}
    buildings.forEach(b => buildingInfoCells[b.refNum] = b.building ? {
      kind: 'click',
      value: 'View full building form',
      onClick: () => {
        this.showBuildingForm(b.oldBuildingInfo, { lat: b.latitude, lng: b.longitude })
      }
    } : {kind: 'na'})
    rows.push({
      header: 'Full building info',
      cells: buildingInfoCells,
      type: 'simple'
    })



    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length,
      exists: true
    }
  }

  /**
   *  About Property
   *  TODO: Accommodation layout
   */
  private aboutPropertyInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['aboutPropertyInfo'] {
    let rows: Row[] = []

    rows = aboutPropertyRows(_.concat(
      [{refNum: targetProperty.refNum, propertySubType: targetProperty.propertySubType, propertySubTypeName: targetProperty.propertySubTypeName, property: targetProperty.property}],
      comparables.map(comparable => ({refNum: comparable.refNum, propertySubType: comparable.propertySubType, propertySubTypeName: comparable.propertySubTypeName, property: comparable.property}))
    ))

    const pictureCells: Cells = {
      tp: this._picturesCellCreator(targetProperty.pictures, 'Target Property Pictures') 
    }
    comparables.forEach(comparable => pictureCells[comparable.refNum] = this._picturesCellCreator(comparable.pictures, 'Comparable Pictures'))
    rows.push({
      header: 'Pictures',
      cells: pictureCells,
      type: 'simple'
    })

    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length
    }
  }

  private externalAspectInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['externalAspectsInfo'] {
    let isExists = false
    if (this.checkIfExternalAspectExists(targetProperty)) {
      isExists = true
    }
    comparables.forEach(com => {
      if (this.checkIfExternalAspectExists(com)) {
        isExists = true
      }
    })

    if (!isExists) {
      return {
        rows: [],
        counts: 0,
        exists: false
      }
    }

    const _tp = {
      refNum: targetProperty.refNum,
      propertySubType: targetProperty.propertySubType,
      propertySubTypeName: targetProperty.propertySubTypeName,
      externalAspect: null
    }
    if (targetProperty.propertySubType == 'House' || targetProperty.propertySubType == 'Warehouse' || targetProperty.propertySubType == 'Retail Building') {
      _tp.externalAspect = targetProperty.externalAspect
    }
    const _comps = comparables.map(comparable => {
      const _com = {
        refNum: comparable.refNum,
        propertySubType: comparable.propertySubType,
        propertySubTypeName: comparable.propertySubTypeName,
        externalAspect: null
      }
      if (comparable.propertySubType == 'House' || comparable.propertySubType == 'Warehouse' || comparable.propertySubType == 'Retail Building') {
        _com.externalAspect = comparable.externalAspect
      }
      return _com
    })

    const rows = externalAspectRows(_.concat(
      [_tp],
      _comps
    ))

    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length,
      exists: true
    }
  }
  private checkIfExternalAspectExists({propertySubType}: {propertySubType: PropertySubTypes}): boolean {
    if (propertySubType == 'House' || propertySubType == 'Warehouse' || propertySubType == 'Retail Building') {
      return true
    }
    return false
  }

  private internalAspectInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['internalAspectsInfo'] {
    const _tp = {
      refNum: targetProperty.refNum,
      propertySubType: targetProperty.propertySubType,
      propertySubTypeName: targetProperty.propertySubTypeName,
      internalAspect: null
    }
    if (targetProperty.propertySubType != 'Parking' && targetProperty.propertySubType != 'Land') {
      _tp.internalAspect = targetProperty.internalAspect
    }
    const _comps = comparables.map(comparable => {
      const _com = {
        refNum: comparable.refNum,
        propertySubType: comparable.propertySubType,
        propertySubTypeName: comparable.propertySubTypeName,
        internalAspect: null
      }
      if (comparable.propertySubType != 'Parking' && comparable.propertySubType != 'Land') {
        _com.internalAspect = comparable.internalAspect
      }
      return _com
    })
    const assetClasses = _.concat([_tp], _comps)
    if (assetClasses.every(ac => ac.internalAspect == null)) {
      return {
        rows: [],
        counts: 0,
        exists: false
      }
    }

    const rows = internalAspectRows(assetClasses)

    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length,
      exists: true
    }
  }

  private groundsInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['groundInfo'] {
    const _tp = {
      refNum: targetProperty.refNum,
      propertySubType: targetProperty.propertySubType,
      propertySubTypeName: targetProperty.propertySubTypeName,
      ground: null
    }
    if (targetProperty.propertySubType != 'Parking' && targetProperty.propertySubType != 'Land') {
      _tp.ground = targetProperty.ground
    }
    const _comps = comparables.map(comparable => {
      const _com = {
        refNum: comparable.refNum,
        propertySubType: comparable.propertySubType,
        propertySubTypeName: comparable.propertySubTypeName,
        ground: null
      }
      if (comparable.propertySubType != 'Parking' && comparable.propertySubType != 'Land') {
        _com.ground = comparable.ground
      }
      return _com
    })
    const assetClasses = _.concat([_tp], _comps)
    if (assetClasses.every(ac => ac.ground == null)) {
      return {
        rows: [],
        counts: 0,
        exists: false
      }
    }

    const rows = groundRows(assetClasses)

    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length,
      exists: true
    }
  }

  private sizesInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    tp: ValuationProcessTargetPropertyModel
  ): ViewModel['sizesInfo'] {
    const tpSizesRows: IRow[] = targetProperty.sizes.map(size => {
      const cells: Cells = {}
      cells[targetProperty.refNum] = this._tpSizeCellCreator(`${size.size} ${size.unitOfAreaMeasurementAcronym}`, size.standardMeasurementId, tp) 
      return {
        header: size.standardMeasurementName,
        cells,
        propertySubType: targetProperty.propertySubTypeName
      }
    })
    const comparableSizeRows: IRow[][] = comparables.map(comparable => {
      return comparable.sizes.map(size => {
        const cells: Cells = {}
        cells[comparable.refNum] = {kind: 'simple', value: `${size.size} ${size.unitOfAreaMeasurementAcronym}`}
        return {
          header: size.standardMeasurementName,
          cells,
          propertySubType: comparable.propertySubTypeName
        }
      })
    })
    const mergedRows = mergeRRows(_.concat([tpSizesRows], comparableSizeRows))
    const rows = mergeSizeRows(mergedRows, ['tp', ...comparables.map(com => com.refNum)])
    return {
      rows: rows,
      counts: 0
    }
  }

  private parcelLandAreaInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ) {
    type parcelIdentification = {
      refNum: string,
      propertySubType: PropertySubTypes,
      propertySubTypeName: string,
      parcelLandArea: {
        planningStatus: string,
        qosDegradation: string,
        envConsideration: string,
        otherConsideration: string,
      } | null
    }
    const tp: parcelIdentification = {
      refNum: targetProperty.refNum,
      propertySubType: targetProperty.propertySubType,
      propertySubTypeName: targetProperty.propertySubTypeName,
      parcelLandArea: null
    }
    switch (targetProperty.propertySubType) {
      case 'Apartment':
      case 'Office':
      case 'Retail Shop':
        tp.parcelLandArea = null
        break;
      case 'Land':
        tp.parcelLandArea = {
          planningStatus: null,
          qosDegradation: null,
          envConsideration: null,
          otherConsideration: null
        }
        break;
      default:
        tp.parcelLandArea = {
          planningStatus: targetProperty.parcelIdentification.planningStatus,
          qosDegradation: targetProperty.parcelIdentification.qualityOfSoil,
          envConsideration: targetProperty.parcelIdentification.environmentalConsideration,
          otherConsideration: targetProperty.parcelIdentification.otherConsideration
        }
    }
    const _comps = comparables.map(comparable => {
      const _com: parcelIdentification = {
        refNum: comparable.refNum,
        propertySubType: comparable.propertySubType,
        propertySubTypeName: comparable.propertySubTypeName,
        parcelLandArea: null
      }
      switch (comparable.propertySubType) {
        case 'Apartment':
        case 'Office':
        case 'Retail Shop':
          _com.parcelLandArea = null
          break;
        case 'Land':
          _com.parcelLandArea = {
            planningStatus: null,
            qosDegradation: comparable.property.qualityOfSoilDegradation,
            envConsideration: null,
            otherConsideration: null
          }
          break;
        default:
          _com.parcelLandArea = {
            planningStatus: comparable.parcelIdentification.planningStatus,
            qosDegradation: comparable.parcelIdentification.qualityOfSoil,
            envConsideration: comparable.parcelIdentification.environmentalConsideration,
            otherConsideration: comparable.parcelIdentification.otherConsideration
          }
      }
      return _com
    })

    const assetClasses = _.concat([tp], _comps)
    if (assetClasses.every(ac => ac.parcelLandArea == null)) {
      return {
        rows: [],
        counts: 0,
        exists: false
      }
    }

    const items: {header: string, field: keyof parcelIdentification['parcelLandArea']}[] = [
      {header: 'Planning Status of the land parcel', field: 'planningStatus'},
      {header: 'Quality of soil and degradation', field: 'qosDegradation'},
      {header: 'Environmental considerations', field: 'envConsideration'},
      {header: 'Other considerations', field: 'otherConsideration'}
    ]

    const rrows = assetClasses.map(assetClass => {
      if (assetClass.propertySubType == 'Apartment' || assetClass.propertySubType == 'Office' || assetClass.propertySubType == 'Retail Shop') {
        return []
      }
      return items.map(item => {
        const cells: Cells = {}
        cells[assetClass.refNum] = simpleCellCreator(assetClass.parcelLandArea[item.field]) 
        return {
          header: item.header,
          cells,
          propertySubType: assetClass.propertySubTypeName
        }
      })
    })
    const mergedRows = mergeRRows(rrows)
    const rows = mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))


    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length,
      exists: true
    }
  }

  private parcelIdentificationInfo(
    targetProperty: ValuationProcessTargetPropertyInfo,
    comparables: VComparable[],
    show100Fields: boolean
  ): ViewModel['parcelIdentificationInfo'] {
    type parcelIdentification = {
      refNum: string,
      propertySubType: PropertySubTypes,
      propertySubTypeName: string,
      parcelIdentification: {
        landParcelName: string,
        coordinateReferencySystem: string,
        points: {latitude: number, longitude: number}[]
      } | null
    }
    const tp: parcelIdentification = {
      refNum: targetProperty.refNum,
      propertySubType: targetProperty.propertySubType,
      propertySubTypeName: targetProperty.propertySubTypeName,
      parcelIdentification: null
    }
    switch (targetProperty.propertySubType) {
      case 'Apartment':
      case 'Office':
      case 'Retail Shop':
        tp.parcelIdentification = null
        break;
      case 'Land':
        tp.parcelIdentification = {
          landParcelName: targetProperty.property.landParcelName,
          coordinateReferencySystem: targetProperty.property.coordinateReferencySystem,
          points: targetProperty.locationData.points.map(p => ({latitude: p.lat, longitude: p.lng}))
        }
        break;
      default:
        tp.parcelIdentification = {
          landParcelName: targetProperty.parcelIdentification.landParcelName,
          coordinateReferencySystem: targetProperty.parcelIdentification.coordinateReferencySystem,
          points: targetProperty.parcelIdentification.points
        }
    }
    const _comps = comparables.map(comparable => {
      const _com: parcelIdentification = {
        refNum: comparable.refNum,
        propertySubType: comparable.propertySubType,
        propertySubTypeName: comparable.propertySubTypeName,
        parcelIdentification: null
      }
      switch (comparable.propertySubType) {
        case 'Apartment':
        case 'Office':
        case 'Retail Shop':
          _com.parcelIdentification = null
          break;
        case 'Land':
          _com.parcelIdentification = {
            landParcelName: comparable.property.landParcelName,
            coordinateReferencySystem: comparable.property.coordinateReferencySystem,
            points: comparable.locationData.points.map(p => ({latitude: p.lat, longitude: p.lng}))
          }
          break;
        default:
          _com.parcelIdentification = {
            landParcelName: comparable.parcelIdentification.landParcelName,
            coordinateReferencySystem: comparable.parcelIdentification.coordinateReferencySystem,
            points: comparable.parcelIdentification.points
          }
      }
      return _com
    })

    const assetClasses = _.concat([tp], _comps)
    if (assetClasses.every(ac => ac.parcelIdentification == null)) {
      return {
        rows: [],
        counts: 0,
        exists: false
      }
    }
    const items: {header: string, field: keyof VParcelIdentification}[] = [
      {header: 'Land Parcel ID', field: 'landParcelName'},
      {header: 'Coordinate Reference System', field: 'coordinateReferencySystem'},
      {header: 'Map', field: 'points'}
    ]

    const rrows = assetClasses.map(assetClass => {
      if (assetClass.propertySubType == 'Apartment' || assetClass.propertySubType == 'Office' || assetClass.propertySubType == 'Retail Shop') {
        return []
      }
      return items.map(item => {
        const cells: Cells = {}
        cells[assetClass.refNum] = item.field == 'points'
          ? this._pointsCellCreator(assetClass.parcelIdentification.points)
          : simpleCellCreator(assetClass.parcelIdentification[item.field])
        return {
          header: item.header,
          cells,
          propertySubType: assetClass.propertySubTypeName
        }
      })
    })
    const mergedRows = mergeRRows(rrows)
    const rows = mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))


    const filteredRows = show100Fields ? rows.filter(row => this._atLeastOneOfThemAvailable(row.cells)) : rows
    return {
      rows: filteredRows,
      counts: filteredRows.filter(row => row.type == 'countable').length,
      exists: true
    }
  }


  /**
   *  Utils
   */

  private _pointsCellCreator(points: {latitude: number, longitude: number}[]): Cell {
    if (points.length == 0) {
      return {
        kind: 'unknown-with-value',
        value: 'No points'
      }
    }
    return {
      kind: 'click',
      value: 'Show in map',
      onClick: () => {
        this._showInMap(points)
      }
    }
  }

  private _showInMap(points: {latitude: number, longitude: number}[]) {
    const center = ValuationUtils.getCenter(points.map(point => ({lat: point.latitude, lng: point.longitude})))
    const modalRef = this.modalService.open(ParcelIdentificationModalComponent, {
        windowClass: 'parcel-identification-modal',
        backdrop: 'static'
    });
    modalRef.componentInstance.points = points.map(point => ({ lat: Number(point.latitude), lng: Number(point.longitude) }));
    modalRef.componentInstance.centerLat = center.lat;
    modalRef.componentInstance.centerLng = center.lng;
  }

  private _picturesCellCreator(pictures: PictureModel[], modalTitle?: string): Cell {
    const _pictures = pictures.map(picture => ({
      name: picture.name,
      path: picture.path,
      url: environment.baseApiUrl + getPictureUrl(picture, this.subDomainService.subDomain)
    }))
    if (_pictures.length == 0) {
      return {
        kind: 'unknown'
      }
    }
    return {
      kind: 'picture',
      pictures: _pictures,
      onClick: (index: number) => {
        this.showThumbnail(pictures, index, modalTitle)
      }
    }
  }

  private _tpSizeCellCreator(value: string, standardMeasurementId: number, targetProperty: ValuationProcessTargetPropertyModel): Cell {
    const hasSizeTable = standardMeasurementId >= 1 && targetProperty.instructedToMeasure && this.smUtility.checkHasOwnTable(standardMeasurementId)
    return hasSizeTable ? {
      kind: 'simple',
      value: value,
    } : {
      kind: 'click',
      value,
      onClick: () => {
        this._showSizeTable()
      }
    }
  }

    private showConditionRating() {
        const modalRef = this.modalService.open(ConditionRatingModalComponent, {
            windowClass: 'condition_rating_modal',
            backdrop: 'static',
            injector: Injector.create({
                providers: [{
                    provide: ConditionRatingV2Service,
                    useValue: this.crService
                }]
            })
        });
        modalRef.componentInstance.data = {
            ratings: this._ratings,
            tpDetails: this._tpFull.details
        };
    }

  private _showSizeTable() {
    if (this.targetProperty.generalInfo.propertySubType == 'Land') {
      return
    }
    const rooms = {
        bath: 0,
        kitchen: 0,
        bedrooms: 0,
        livingRooms: 0,
        toilet: 0
    }
    _.each(this.targetProperty.generalInfo.accommodation, (col) => {
        Object.entries(col).forEach(([key, value]) => {
            if (rooms.hasOwnProperty(key)) {
            rooms[key] += value != undefined ? Number(value) : 0; 
            }
        })
    })
    let meta = {
      bedroom: 0,
      selectedExternalAreas: [] 
    }
    switch (this.targetProperty.generalInfo.propertySubType) {
      case 'Apartment': {
        meta = {
          ...meta,
          bedroom: this.targetProperty.generalInfo.bedroom,
          selectedExternalAreas: this.targetProperty.generalInfo.selectedExternalArea
        }
        break;
      }
      case 'Office':
      case 'Retail Shop': {
        meta = {
          ...meta,
          selectedExternalAreas: this.targetProperty.generalInfo.selectedExternalArea
        }
      }
    }

      const modalRef = this.modalService.open(SizesModalComponent, {
          windowClass: 'sizes_modal',
          backdrop: 'static',
          injector: Injector.create({
              providers: [{
                  provide: ConditionRatingV2Service,
                  useValue: this.crService
              }]
          }) 
      });

        modalRef.componentInstance.info = {
        ac_id: this.targetProperty.id,
        standard_measurement: this.targetProperty.generalInfo.measurementStandardModel,
        unitAreaMeasurement$: of(this.targetProperty.generalInfo.unitAreaMeasurementModel),
        readonly: true,
        bedrooms$: of(meta.bedroom),
        externalAreas$: of(meta.selectedExternalAreas),
        floorAction$: of({
            type: 'remove',
            floors: [],
            scheme_id: this.targetProperty.generalInfo.floorNumberingSchemeId
        }),
        sizeChanges$: of({
          standard_measurement_id: this.targetProperty.generalInfo.measurementStandardModel?.id,
          unit_area_measurement_id: this.targetProperty.generalInfo.unitAreaMeasurementModel?.id,
          unit_area_measurement_name: this.targetProperty.generalInfo.unitAreaMeasurementAcronym
        }) ,
        roomChanges$: of(rooms)
        };
    
    // this.dialog.open(SizeTableModalComponent, { 
    //   width: '80vw',
    //   data: {
    //   },
    // });
  }

  private _atLeastOneOfThemAvailable(cells: Cells) {
    let atLeastOneOfThemAvailable = false
    Object.values(cells).forEach(cell => {
      if (cell.kind != 'unknown' && cell.kind != 'na') {
        atLeastOneOfThemAvailable = true
      }
    })
    return atLeastOneOfThemAvailable
  }

  private showThumbnail(pictures: PictureModel[], index: number, modalTitle?: string) {
    this.dialog.open(PictureSliderDialogComponent, {
      data: {
        pictures: pictures,
        initIndex: index.toString(),
        title: modalTitle
      },
      width: '60vw'
    })
  }

  onConsiderationChange({ refNum, id }: { refNum: string, id: number }) {
    this.store$.dispatch(new UpdateValuationProcessSelectedConsideration({ refNum: refNum, id: id }))
  }

  show100Fields(event: MatCheckboxChange) {
    this._show100Fields$.next(event.checked)
  }

  onImageError(event) {
    event.target.src = awConst.IMAGE_NOT_FOUND
  }

  onComparableSelect(action: 'remove' | 'add', refNum: string) {
    if (action == 'remove') {
      this.store$.dispatch(new RemoveComparableFromValuationProcess({refNum: refNum}))
      return
    }
    this.store$.dispatch(new AddComparableToValuationProcess({refNum: refNum}))
    this.dialogRef.close()
  }

  /// FIX IT SOMEHOW
  private showBuildingForm(buildingInfo, point) {
    this.dialog.open(BuildingInfoModalComponent, {
      data: {
        buildingInfo,
        point
      },
      width: '80vw'
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  private _sourcePictureToPictureModel(p: string, num: number): PictureModel & {url: string} {
    const index = p.lastIndexOf('/');
    const path = p.substring(0, index + 1);
    const name = p.substring(index + 1)
    const pic: PictureModel =  {
      id: num,
      title: `Source Picture ${num}`,
      path,
      name,
    }
    return {
      url: environment.baseApiUrl + getPictureUrl(pic, this.subDomainService.subDomain),
      ...pic
    }
  }
}

function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344
    }
    if (unit == 'N') {
      dist = dist * 0.8684
    }
    return parseFloat(dist.toFixed(2));
  }
}
