<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
            <span class="card-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;"
                   (click)="onSubmit()"
                   class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px">
                    Save
                </a>
            </span>

            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body m-0 h-100">
        <div class="form" [formGroup]="fg">
            <mat-dialog-content>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select
                                    placeholder="Adjustment"
                                    formControlName="adjustment_id">
                                <mat-option [value]="0">Other</mat-option>
                                <mat-option *ngFor="let item of adjustments" [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>

                            <mat-error> Adjustment required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6" *ngIf="fg.controls.adjustment_id.value == 0">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Adjustment Name" formControlName="name"/>
                            <mat-error>Adjustment Name required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput
                                      placeholder="Adjustment description"
                                      rows="2"
                                      formControlName="desc"></textarea>

                            <mat-error> Adjustment description required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">

                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput
                                      placeholder="Comparative analysis"
                                      rows="2"
                                      formControlName="analysis"></textarea>

                            <mat-error> Comparative analysis required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </mat-dialog-content>
        </div>
    </div>
</div>
