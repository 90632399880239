<div class="row">
    <div class="col-12">
        <h4 class="my-3 text-mad text-uppercase">{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.SECTION_TITLE' | translate }}
        </h4>
        <hr class="active">
    </div>
    <kt-upload-wrapper *ngIf="!readonly" class="col-12" [fileFolder]="'user/certificate'" hasPrefix
        (fileUploaded)="onFileUploaded($event)"></kt-upload-wrapper>
    <div class="col-12">
        <div class="example-container mat-elevation-z0">
            <mat-table #table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="organisation">
                    <mat-header-cell *matHeaderCellDef>{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.DATATABLE.ORGANIZATION' |
                        translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let file"> {{file.org_id ? file.org_name : 'N/A'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="certification">
                    <mat-header-cell *matHeaderCellDef>{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.DATATABLE.CERTIFICATION' |
                        translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let file"> {{file.cert_type ? file.cert_name : 'N/A'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="registration">
                    <mat-header-cell *matHeaderCellDef>{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.DATATABLE.REGISTRATION' |
                        translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let file"> {{file.registration && file.registration.length >0 ?
                        file.registration : 'N/A'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="prime_reference">
                    <mat-header-cell *matHeaderCellDef>{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.DATATABLE.PRIME_REFERENCE'
                        | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let file; let i = index;">
                        <mat-checkbox [disabled]="readonly" [checked]="file.is_prime_ref" (change)="changeIsPrime(i, $event)"></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.DATATABLE.EXPIRATION_DATE'
                        | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <ng-container *ngIf="file.date && file.date.length > 0; else na">
                            <span>{{file.date | date: 'dd MMM yyyy'}}</span>
                            <ng-container *ngIf="isExpired(file.date)">
                                <span class="ml-1" style="color: red">(expired!)</span>
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ 'USER.FORM.FORM_FIELD.CERTIFICATION.DATATABLE.ACTION' |
                        translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let file; let i = index">
                        <ng-container *ngIf="!readonly">
                            <button mat-icon-button color="primary"
                                [matTooltip]="'USER.FORM.FORM_FIELD.CERTIFICATION.TOOLTIP.EDIT' | translate"
                                (click)="editCert(file, i)" type="button">
                                <mat-icon>create</mat-icon>
                            </button>&nbsp;
                            <button mat-icon-button color="warn"
                                [matTooltip]="'USER.FORM.FORM_FIELD.CERTIFICATION.TOOLTIP.DELETE_FILE' | translate"
                                (click)="deleteCert(i)" type="button">
                                <mat-icon>delete</mat-icon>
                            </button>&nbsp;
                        </ng-container>
                        <button mat-icon-button
                            [matTooltip]="'USER.FORM.FORM_FIELD.CERTIFICATION.TOOLTIP.PREVIEW_FILE' | translate"
                            (click)="previewCert(file)" *ngIf="file.name && file.name.length > 0" type="button">
                            <mat-icon *ngIf="!docs.includes(typesUtilsService.getFileType(file.name))">remove_red_eye
                            </mat-icon>
                            <mat-icon *ngIf="docs.includes(typesUtilsService.getFileType(file.name))">get_app</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <!-- <mat-error
                    *ngIf="hasFormErrors && userForm.controls.qualification_id.value == 1 &&  certificates.value.length < 1"
                    [innerHtml]="'USER.FORM.FORM_FIELD.VALIDATION.REQUIRED' | translate"
            >
            </mat-error> -->
        </div>
    </div>
</div>

<ng-template #na>
    N/A
</ng-template>