import {AssetClassValuationGroup} from '../_models/asset-class-valuation-group.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassValuationGroupsState} from '../_reducers/asset-class-valuation-group.reducers';
import * as fromAssetClassValuationGroup from '../_reducers/asset-class-valuation-group.reducers';
import {each} from 'lodash';

export const selectAssetClassValuationGroupsState = createFeatureSelector<AssetClassValuationGroupsState>('assetClassValuationGroups');

export const selectAssetClassValuationGroupById = (assetClassValuationGroupId: number) => createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => assetClassValuationGroupsState.entities[assetClassValuationGroupId]
);

export const selectAllAssetClassValuationGroups = createSelector(
    selectAssetClassValuationGroupsState,
    fromAssetClassValuationGroup.selectAll
);

export const selectAllAssetClassValuationGroupsIds = createSelector(
    selectAssetClassValuationGroupsState,
    fromAssetClassValuationGroup.selectIds
);

export const allAssetClassValuationGroupsLoaded = createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => assetClassValuationGroupsState.isAllAssetClassValuationGroupsLoaded
);


export const selectAssetClassValuationGroupsPageLoading = createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => assetClassValuationGroupsState.listLoading
);

export const selectAssetClassValuationGroupsActionLoading = createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => assetClassValuationGroupsState.actionLoading
);

export const selectLastCreatedAssetClassValuationGroupId = createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => assetClassValuationGroupsState.lastCreatedAssetClassValuationGroupId
);

export const selectAssetClassValuationGroupsShowInitWaitingMessage = createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => assetClassValuationGroupsState.showInitWaitingMessage
);


export const selectAssetClassValuationGroupsQueryResult = createSelector(
    selectAssetClassValuationGroupsState,
    assetClassValuationGroupsState => {
        const items: AssetClassValuationGroup[] = [];
        each(assetClassValuationGroupsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AssetClassValuationGroup[] = httpExtension.sortArray(items, assetClassValuationGroupsState.lastQuery.sortField, assetClassValuationGroupsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, assetClassValuationGroupsState.queryRowsCount, '');
    }
);
