import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { LoadTeamTemplates, TeamTemplateDataSource, TeamTemplateModel, TeamTemplateState } from 'src/app/core/template';
import { PaginationEvent } from '../../../template/template-list/template-list.component';

@Component({
  selector: 'kt-support-valuer-template-list-modal',
  templateUrl: './support-valuer-template-list-modal.component.html',
  styleUrls: ['./support-valuer-template-list-modal.component.scss']
})
export class SupportValuerTemplateListModalComponent implements OnInit {
  dataSource: TeamTemplateDataSource;
  pageSize = 4;

  constructor(
    public dialogRef: MatDialogRef<SupportValuerTemplateListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<TeamTemplateState>
  ) { }

  ngOnInit(): void {
    this.dataSource = new TeamTemplateDataSource(this.store);
    this.store.dispatch(new LoadTeamTemplates({
      request: {
        pageNumber: 1,
        pageSize: this.pageSize
      }
    }));
  }

  loadList(event: PaginationEvent) {
    this.store.dispatch(new LoadTeamTemplates({
      request: {
        pageNumber: event.pageNumber + 1,
        pageSize: event.pageSize
      }
    }));
  }

  selectTemplate(event: TeamTemplateModel) {
    this.dialogRef.close({
      type: 'select',
      template: event
    });
  }

  viewTemplate(event: TeamTemplateModel) {
    this.dialogRef.close({
      type: 'view',
      template: event
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
