<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Add Retail Shop</h3>
        </div>
        <div class="card-toolbar">
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup name="radioBasic"
                [formControl]="formSelectionCtrl">
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                    <input ngbButton type="radio" class="btn-check" [value]="2"> Simple
                </label>
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-left-white">
                    <input ngbButton type="radio" class="btn-check" [value]="1"> Advanced
                </label>
            </div>
        </div>
        <div style="width: 20%"></div>
    </div>

    <div class="form form-group-seperator-dashed">
        <form class="form" [formGroup]="formGroup">
            <div class="card-body">

                <mat-dialog-content>
            <kt-tab-header 
                [innerTop]="'0px'"
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Location & building</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-map-location
                            [title]="'Location'"
                            [showMap]="true"
                            [lockMarker]="false"
                            [isComplexForm]="isComplexForm"
                            [locationData]="acRetailShop.locationData"
                            [parentType]="'comparable'"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                        ></kt-map-location>

                        <kt-building-form
                            *ngIf="isComplexForm"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (centerChange)="onCenterChange($event)"
                            (picChange)="onPicChange($event)"
                            [buildingPictures]="building_pictures"
                            [buildingPicturePath]="building_picture"
                            [isComplexForm]="isComplexForm"
                            [acBuilding]="acRetailShop.buildingInfo"></kt-building-form>

                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Property information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group kt-form__group row">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile section-margin-top-60">
                                <h5 class="my-3 text-mad text-uppercase">
                                    About the property
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-4">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-type category" formControlName="subtype_category_id">
                                        <mat-option *ngFor="let category of subTypeCategories" [value]="category.id">
                                            {{category.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-type category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div> 
                            <div class="col-lg-4" *ngIf="filteredSubCategories.length != 0">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-category" formControlName="sub_category_id">
                                        <mat-option *ngFor="let category of filteredSubCategories" [value]="category.id">
                                            {{category.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div> 
                            <div class="col-lg-4">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Handover standards" formControlName="handover_standard_id">
                                        <mat-option *ngFor="let item of handoverStandards" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Handover standard <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div> 
                            <div class="col-lg-8 kt-margin-bottom-10-mobile">
                                <tag-input class="floor" matInput formControlName="floor_details"
                                           [placeholder]="'RETAIL_SHOP.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                                           [secondaryPlaceholder]="'RETAIL_SHOP.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                                           (onAdd)="onFloorAdd($event)"
                                           (onRemove)="onFloorRemove($event)"
                                           modelAsStrings="true"
                                           style="width: 100%"
                                >
                                </tag-input>
                            </div>            
                            <div class="col-lg-4">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="State of repair" formControlName="state_of_repair_id">
                                        <mat-option *ngFor="let item of stateOfRepairs" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        State of Repair <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div> 
                            <div class="col-lg-4" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Building ID" formControlName="building_name">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Building Type" formControlName="building_type">
                                        <mat-option *ngFor="let type of buildingTypes" [value]="type.id">
                                            {{type.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Building type <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
        
                            </div>
                            <div class="col-lg-12">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="3"  placeholder="Property general description" formControlName="property_general_desc"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12 mt-10" *ngIf="isComplexForm">
                                <kt-accommodation-layout-table
                                    [propertySubTypeID]="5"
                                    [aboveFloor]="aboveFloor$"
                                    [belowFloor]="belowFloor$"
                                    [floors$]="floors$"
                                    [initData]="acRetailShop.accommodation.data"
                                    [initOthers]="acRetailShop.accommodation.others"></kt-accommodation-layout-table>
                            </div>
                        </div>
        
                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   Internal Aspect 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-internal-aspects 
                                    [propertySubTypeID]="5" 
                                    [internalAspectData]="acRetailShop.internalAspectData"></kt-internal-aspects>
                            </div>
                        </div>
        
                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   Grounds & External Areas
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-ac-grounds 
                                    [isComplexForm]="isComplexForm" 
                                    [propertySubTypeID]="5"
                                    [initData]="acRetailShop.grounds"></kt-ac-grounds>
                            </div>
                        </div>
                        <kt-ac-size class="section-margin-top-60" [sizesSubject]="sizesSubject" [assetClassType]="'5'"></kt-ac-size>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && sizesSubject.value.length == 0"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('RETAIL_SHOP.FORM.FIELD.SIZES.SECTION_TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Lease & sale information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-consideration-and-source
                            style="margin-top: 60px;"
                            [inputConsiderations]="tenuresSubject|async"
                            [landAreaComponentListSubject]="comps"
                            [hasParcelInfo$]="hasParcel"
                            [sizes]="sizes$|async"></kt-consideration-and-source>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && (considerationError$|async)"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APARTMENT.FORM.FIELD.CONSIDERATIONS.TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        
                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Property Pictures
                                </h5>
                                <hr class="active">
                            </div>
        
                            <kt-upload-file #simple class="col-lg-12"
                                [filesOnEditMode]="acRetailShop.ac_pictures"
                                [folderOnEditMode]="acRetailShop.picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="1"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Retail Shop Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <div class="form-group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Building Pictures
                                </h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #buildingPic class="col-lg-12"
                                [filesOnEditMode]="building_pictures"
                                [folderOnEditMode]="building_picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="2"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Building Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && buildingFileComponent && buildingFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <kt-ac-document-upload *ngIf="isComplexForm" class="section-margin-top-60" [files]="acRetailShop.documents">
                        </kt-ac-document-upload>
                    </div>
                </mat-tab>
            </mat-tab-group>
            </mat-dialog-content>
            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="cancel()">
                    Cancel
                </button>
                &nbsp;
                <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit(true)">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>