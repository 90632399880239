// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TpAdminTaskModel} from '../_models/tp-admin-task.model';

export enum TpAdminTaskActionTypes {
    AllTpAdminTaskRequested = '[Init] All TpAdminTask Requested',
    AllTpAdminTaskLoaded = '[Init] All TpAdminTask Loaded',
    AllTpAdminTaskLoadedByUser = '[Init] All TpAdminTask Loaded By User',
    TpAdminTaskCleared = '[Init] All TpAdminTask cleared',

    TpAdminTaskOnServerCreated = '[Edit TpAdminTask Dialog] TpAdminTask On Server Created',
    TpAdminTaskCreated = '[Edit TpAdminTask Dialog] TpAdminTask Created',
    TpAdminTaskUpdated = '[Edit TpAdminTask Dialog] TpAdminTask Updated',

    TpAdminTaskActionToggleLoading = '[TpAdminTask] TpAdminTask Action Toggle Loading',

    TpAdminTaskDeleted = '[TpAdminTask List Page] TpAdminTask Deleted',

    AllTpAdminTaskByUserRequested = 'All TpAdminTask By User Requested',
    TpAdminTaskByUserPageRequested = 'All TpAdminTask By User Page Requested',
    TpAdminTaskByUserPageLoaded = 'All TpAdminTask By User Page Loaded',
    TpAdminTasksClear = 'clear tasks',
}

export class TpAdminTaskCleared implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskCleared;
}

export class AllTpAdminTaskRequested implements Action {
    readonly type = TpAdminTaskActionTypes.AllTpAdminTaskRequested;
}

export class AllTpAdminTaskLoaded implements Action {
    readonly type = TpAdminTaskActionTypes.AllTpAdminTaskLoaded;

    constructor(public payload: { 
        tasks: TpAdminTaskModel[],
    }) {
    }
}

export class AllTpAdminTaskLoadedByUser implements Action {
    readonly type = TpAdminTaskActionTypes.AllTpAdminTaskLoadedByUser;

    constructor(public payload: { 
        tasks: TpAdminTaskModel[],
        totalCount: number,
        page: QueryParamsModel,
    }) {
    }
}


export class TpAdminTaskOnServerCreated implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskOnServerCreated;

    constructor(public payload: { tp_id: number, task: TpAdminTaskModel }) {
    }
}

export class TpAdminTaskCreated implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskCreated;

    constructor(public payload: { task: TpAdminTaskModel }) {
    }
}

export class TpAdminTaskUpdated implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskUpdated;

    constructor(public payload: {
        partialItem: Update<TpAdminTaskModel>,
        item: TpAdminTaskModel
    }) {
    }
}

export class TpAdminTaskDeleted implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class TpAdminTaskActionToggleLoading implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AllTpAdminTaskByUserRequested implements Action {
    readonly type = TpAdminTaskActionTypes.AllTpAdminTaskByUserRequested;

    constructor(public payload: { page: QueryParamsModel, user_id: number, filterModel: any }) {
    }
}

export class TpAdminTaskByUserPageRequested implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskByUserPageRequested;

    constructor(public payload: { page: QueryParamsModel, user_id: number }) {
    }
}

export class TpAdminTaskByUserPageLoaded implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTaskByUserPageLoaded;

    constructor(public payload: {
        assetClasses: TpAdminTaskModel[],
        totalCount: number,
        page: QueryParamsModel
    }) {
    }
}

export class TpAdminTasksClear implements Action {
    readonly type = TpAdminTaskActionTypes.TpAdminTasksClear;

    constructor() {
    }
}

export type TpAdminTaskActions = AllTpAdminTaskRequested |
    AllTpAdminTaskLoaded |
    TpAdminTaskActionToggleLoading |
    TpAdminTaskOnServerCreated |
    TpAdminTaskCreated |
    TpAdminTaskUpdated |
    TpAdminTaskCleared |
    TpAdminTaskDeleted |
    AllTpAdminTaskByUserRequested |
    TpAdminTaskByUserPageRequested |
    TpAdminTaskByUserPageLoaded | 
    AllTpAdminTaskLoadedByUser |
    TpAdminTasksClear;
