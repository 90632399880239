<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h4 class="card-label">{{header}}</h4>
		</div>
		<div class="card-toolbar">
			<input #fileInput type="file" (change)="uploadFiles($event)" style="display: none;" multiple accept=".xlsx,.xls,image/*,.doc,.docx,.pdf" />

			<button mat-button mat-raised-button color="primary" (click)="onAddClick()" class="mr-2">
				Add file
			</button>
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
	<div class="card-body pt-0 w-100">
		<mat-dialog-content>
			<div class="m-1 row justify-content-end">
				<div *ngIf="(isInProgress$|async)" class="mr-2">
					<div style="margin-bottom: 14px;">Uploading... {{progress$|async}}</div>
				</div>
				<div class="error mr-2" *ngIf="(hasFailed$|async)">
					Error: {{error$|async}}
				</div>
				<div class="mr-2" *ngIf="(isInProgress$|async)">
					<span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">
						Cancel
					</span>
				</div>
			</div>
			

			<div *ngIf="dataSource.data.length > 0" class="mat-elevation-z0">
				<mat-table [dataSource]="dataSource">
					<ng-container matColumnDef="upload_date">
						<mat-header-cell *matHeaderCellDef>Upload Date</mat-header-cell>
						<mat-cell *matCellDef="let report">{{report.created_at | date: 'dd MMM yyyy HH:mm'}}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="size">
						<mat-header-cell *matHeaderCellDef>Size</mat-header-cell>
						<mat-cell *matCellDef="let report">
							<span>{{report.file_size}}</span>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="upload_by">
						<mat-header-cell *matHeaderCellDef>Upload by</mat-header-cell>
						<mat-cell *matCellDef="let report">
							{{report.user_name}}
						</mat-cell>
					</ng-container>
					<!-- <ng-container matColumnDef="current_toe">
						<mat-header-cell *matHeaderCellDef>Current ToE?</mat-header-cell>
						<mat-cell *matCellDef="let report">
							<mat-checkbox
								(click)="changeSignedDoc($event, report.id)"
								[checked]="report.id == (currentSignedDocID$ | async)"
								[disabled]="report.id == (currentSignedDocID$ | async)">
							</mat-checkbox>
						</mat-cell>
					</ng-container> -->
					<ng-container matColumnDef="actions">
						<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
						<mat-cell *matCellDef="let file">
							<button mat-icon-button
									color="warn"
									[matTooltip]="'GENERAL.TOOLTIP.DELETE_FILE' | translate"
									(click)="deleteUploadedFile(file)"
									*ngIf="file.checked == 0"
									type="button">
								<mat-icon>delete</mat-icon>
							</button>&nbsp;
							<button mat-icon-button
									[matTooltip]="'GENERAL.TOOLTIP.PREVIEW_FILE' | translate"
									(click)="previewUploadedFile(file)"
									type="button">
								<mat-icon *ngIf="!docs.includes(typesUtilsService.getFileType(file.url))">remove_red_eye</mat-icon>
								<mat-icon *ngIf="docs.includes(typesUtilsService.getFileType(file.url))">get_app</mat-icon>
							</button>&nbsp;
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{
						'green': row.id == (currentSignedDocID$|async)
					}"></mat-row>
				</mat-table>
			</div>
		</mat-dialog-content>
	</div>
</div>