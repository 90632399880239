// Angular
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AssetClassBuildingInformation } from 'src/app/core/comparable';

@Component({
    selector: 'kt-save-entity-with-descr-dialog',
    templateUrl: './save-entity-with-descr-dialog.component.html'
})
export class SaveEntityWithDescrDialogComponent implements OnInit {
    // Public properties
    viewLoading = false;
    loadingAfterSubmit = false;
    inputTitleForUpdate = new UntypedFormControl('');
    inputDescriptionForUpdate = new UntypedFormControl('');
    inputBuildingForUpdate = new UntypedFormControl('');

    descriptionField = false;
    descriptionTooltip = null;
    checkBox = false;
    checkBoxValue: boolean = false;

    featuredPictureAllowed = false;
    isFeatured: boolean = false;

    @ViewChild('dropDown') dropDownSelect: MatSelect;
    dropDownValues: {key: number, value: string}[] = [];
    currentDropDownValueInd: number;
    buildingField = false;
    building = '';
    buildingList: AssetClassBuildingInformation[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>
     * @param data: any
     */
    constructor(public dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data.checkBox) {
            this.checkBox = data.checkBox;
            this.checkBoxValue = data.checkBoxValue;
        }

        if (data.featuredPictureAllowed) {
            this.featuredPictureAllowed = data.featuredPictureAllowed;
            this.isFeatured = data.isFeatured;
        }

        if (data.descriptionField) {
            this.descriptionField = data.descriptionField;
        }
        if (data.descriptionTooltip) {
            this.descriptionTooltip = data.descriptionTooltip;
        }
        if (data.dropDownValues.length > 0) {
            this.dropDownValues = data.dropDownValues;
            this.currentDropDownValueInd = this.dropDownValues.findIndex(val => val.value === data.currentDropDownValue);
            if (this.currentDropDownValueInd === -1) {
                this.currentDropDownValueInd = 0;
            }
        }
        if (data.buildingField) {
            this.buildingField = data.buildingField;
            this.building = data.building;
            this.buildingList = data.buildingList;
        }

    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.fileTitle) {
            this.inputTitleForUpdate.setValue(this.data.fileTitle);
        }
        if (this.data.fileDescription) {
            this.inputDescriptionForUpdate.setValue(this.data.fileDescription);
        }
        if (this.data.building) {
            this.inputBuildingForUpdate.setValue(this.data.building);
        }
    }

    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    changed() {
        this.checkBoxValue = !this.checkBoxValue;
    }

    featuredChanged() {
        this.isFeatured = !this.isFeatured;
    }

    /**
     * Close dialog with true result
     */
    onYesClick(): void {
        if (this.inputTitleForUpdate.value.length === 0) {
            return;
        }
        // if (this.inputDescriptionForUpdate.value.length == 0) {
        //     return;
        // }
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        this.loadingAfterSubmit = true;
        setTimeout(() => {
            this.dialogRef.close({
                title: this.inputTitleForUpdate.value,
                descr: this.inputDescriptionForUpdate.value,
                is_checked: this.checkBoxValue,
                is_featured: this.isFeatured,
                selected_value: this.dropDownSelect 
                    ? this.getDropDownValue((this.dropDownSelect.selected as MatOption).value)
                    : {},
                building: this.inputBuildingForUpdate.value,
            });
        }, 500);
    }

    private getDropDownValue(i: number) {
        return this.dropDownValues[i];
    }
}
