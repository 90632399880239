// NGRX
import {Action} from '@ngrx/store';
// Models
import {Update} from '@ngrx/entity';
import {TpFileModel} from '../_models/tp-file.model';

export enum TpFileActionTypes {
    AllTpFileRequested = '[Init] All TpFile Requested',
    AllTpFileLoaded = '[Init] All TpFile Loaded',

    TpFileOnServerCreated = '[Edit TpFile Dialog] TpFile On Server Created',
    TpFileCreated = '[Edit TpFile Dialog] TpFile Created',
    TpFileOnServerUpdated = '[Edit TpFile Dialog] TpFile Updated',

    TpFileUpdated = '[TpFile] TpFile Front Updated',

    TpFileActionToggleLoading = '[TpFile] TpFile Action Toggle Loading',

    TpFileDeleted = '[TpFile List Page] TpFile Deleted',

    AllTpFileOfTPRequested = '[Valuation Process Page] All tpfile of tp is requested',
    AllTpFileOfToeRequested = '[Toe Dashboard Page] All TP Files from Toe is requested'
}

export class AllTpFileRequested implements Action {
    readonly type = TpFileActionTypes.AllTpFileRequested;
}

export class AllTpFileLoaded implements Action {
    readonly type = TpFileActionTypes.AllTpFileLoaded;

    constructor(public payload: { files: TpFileModel[] }) {
    }
}

export class AllTpFileOfTPRequested implements Action {
    readonly type = TpFileActionTypes.AllTpFileOfTPRequested

    constructor(public payload: {tpId: number}) {}
}

export class AllTpFileOfToeRequested implements Action {
    readonly type = TpFileActionTypes.AllTpFileOfToeRequested

    constructor(public payload: {toeId: number}) {}
}

export class TpFileOnServerCreated implements Action {
    readonly type = TpFileActionTypes.TpFileOnServerCreated;

    constructor(public payload: { tp_id: number, tp_file: TpFileModel }) {
    }
}

export class TpFileCreated implements Action {
    readonly type = TpFileActionTypes.TpFileCreated;

    constructor(public payload: { tp_file: TpFileModel }) {
    }
}

export class TpFileOnServerUpdated implements Action {
    readonly type = TpFileActionTypes.TpFileOnServerUpdated;

    constructor(public payload: {
        tp_id: number,
        partialTpFile: Update<TpFileModel>,
        tpFile: TpFileModel
    }) {
    }
}

export class TpFileUpdated implements Action {
    readonly type = TpFileActionTypes.TpFileUpdated;

    constructor(public payload: {
        partialTpFile: Update<TpFileModel>,
    }) {}
}

export class TpFileDeleted implements Action {
    readonly type = TpFileActionTypes.TpFileDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class TpFileActionToggleLoading implements Action {
    readonly type = TpFileActionTypes.TpFileActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export type TpFileActions = AllTpFileRequested |
    AllTpFileLoaded |
    TpFileActionToggleLoading |
    TpFileOnServerCreated |
    TpFileCreated |
    TpFileOnServerUpdated |
    TpFileDeleted |
    TpFileUpdated |
    AllTpFileOfTPRequested |
    AllTpFileOfToeRequested;
