// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {
    ConflictActions,
    ConflictActionTypes,
} from '../_actions/conflict.actions';
// Models
import {ConflictOfInterestModel} from '../_models/conflict-of-interest.model';

export interface ConflictState extends EntityState<ConflictOfInterestModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ConflictOfInterestModel> = createEntityAdapter<ConflictOfInterestModel>();

export const initialConflictsState: ConflictState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    showInitWaitingMessage: true
});

export function conflictReducer(state = initialConflictsState, action: ConflictActions): ConflictState {
    switch (action.type) {
        case ConflictActionTypes.AllConflictRequested: {
            return {
                ...state, actionsLoading: true
            };
        }

        case ConflictActionTypes.AllConflictLoaded: {
            return adapter.addMany(action.payload.conflicts, {
                ...initialConflictsState,
                actionsLoading: false
            });
        }
        case ConflictActionTypes.AllConflictRemoved: {
            return adapter.removeAll(state);
        }
        default:
            return state;
    }
}

export const getConflictState = createFeatureSelector<ConflictOfInterestModel>('conflicts');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
