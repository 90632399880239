import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppState} from '../../../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {of, Subject, Subscription} from 'rxjs';
import {
    CountryModel,
    CountryOnServerCreated,
    CountryUpdated,
    CurrencyModel,
    selectAllCountries,
    selectAllCurrencies,
    selectCountryById, selectLastCreatedCountryId
} from '../../../../../core/admin';
import {Update} from '@ngrx/entity';
import {delay, takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CountryData} from '../countries-list/local-data';
import coinify from 'coinify';
import {NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import { CountryVpgaModel } from 'src/app/core/admin/_models/vpga.model';
import { TimezoneService } from 'src/app/core/system-settings/timezone.service';

@Component({
    selector: 'kt-country-edit',
    templateUrl: './country-edit.component.html',
    providers: [NgbPopoverConfig]
})
export class CountryEditComponent implements OnInit, OnDestroy {

    country: CountryModel;
    existCountries = [];
    currencies: CurrencyModel[];

    countryForm: UntypedFormGroup;
    hasFormErrors = false;

    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    // Private properties
    private componentSubscriptions: Subscription;

    private _countriesData = CountryData.countries;
    countries = this._countriesData

    mapFormArrayToData: number[] = [];

    // Timezone
    countrySearchCtrl = new UntypedFormControl()
    timezoneCtlr = new UntypedFormControl()
    private _onDestroy$ = new Subject<void>()

    constructor(private fb: UntypedFormBuilder,
                public dialogRef: MatDialogRef<CountryEditComponent>,
                public popoverConfig: NgbPopoverConfig,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>,
                private translate: TranslateService,
                public timezoneService: TimezoneService) {
        this.popoverConfig.container = '.countryEdit';
        this.popoverConfig.placement = 'left';
    }

    ngOnInit() {

        const id = this.data.id;
        const name = this.data.name ? this.data.name : '';
        if (id && id > 0) {
            this.store.pipe(select(selectCountryById(id))).subscribe(res => {
                if (res) {
                    this.country = Object.assign({}, res);
                    this.createForm();
                }
            });
        } else if (name !== '') {
            this.country = new CountryModel();
            this.country.clear();
            this.country.name = name;
            this.setCurrency(name);
            this.createForm();
        } else {
            this.country = new CountryModel();
            this.country.clear();
            this.createForm();
        }

        this.store.pipe(select(selectAllCurrencies)).subscribe(res => {
            this.currencies = res;
        });

        this.store.pipe(
            select(selectAllCountries),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            const _tmp: string[] = res.map(el => el.name);
            if (_tmp.indexOf(this.country.name) > -1) {
                _tmp.splice(_tmp.indexOf(this.country.name), 1);
            }
            this.existCountries = _tmp;
        });

        this.countrySearchCtrl.valueChanges.pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(value => {
            this.countries = this._countriesData.filter(country => country.countryName.toLowerCase().includes(value.toLowerCase()))
        })

        this.timezoneCtlr.valueChanges.pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(value => this.timezoneService.setFilter(value))
    }

    ngOnDestroy(): void {
        this._onDestroy$.next()
        this._onDestroy$.complete()
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    createForm() {
        this.countryForm = this.fb.group({
            name: [this.country.name, Validators.required],
            vat: [this.country.vat, Validators.required],
            currency: [{value: '' + this.country.currency, disabled: true}],
            default_timezone: [this.country.default_timezone, Validators.required],
            // macro_context_field: [this.country.macro_context_field, Validators.required],
            macro_context_field: this._createVpgaFormArray(this.country.vpgas),
            status_public_domain: [this.country.status_public_domain, Validators.required],
        });
    }

    _createVpgaFormArray(vpgas: CountryVpgaModel[]): UntypedFormArray {
        const formArray = this.fb.array([]);
        if (vpgas.length === 0) {
            const control = this.fb.control('');
            formArray.push(control);
            this.mapFormArrayToData.push(null);
        } else {
            vpgas.forEach(vpga => {
                const control = this.fb.control(vpga.vpga);
                formArray.push(control);
                this.mapFormArrayToData.push(vpga.id)
            })
        }
        return formArray;
    }

    get macroContextFormArray(): UntypedFormArray {
        const formArray = this.countryForm.get('macro_context_field') as UntypedFormArray;
        return formArray;
    }

    addVPGAMatterField() {
        const control = this.fb.control('');
        this.macroContextFormArray.push(control);
        this.mapFormArrayToData.push(null);
        Object.keys(this.countryForm.controls).forEach(controlName =>
            this.countryForm.controls[controlName].markAsDirty()
        );
    }

    removeVPGAMatterField(indx: number) {
        this.macroContextFormArray.removeAt(indx);
        this.mapFormArrayToData.splice(indx, 1);
    }

    _getMacroContextValue(): CountryVpgaModel[] {
        let vpgas: CountryVpgaModel[] = []
        this.macroContextFormArray.controls.forEach((control, i) => {
            const _new = new CountryVpgaModel();
            if (this.mapFormArrayToData[i]) {
                _new.id = this.mapFormArrayToData[i];
            }
            _new.vpga = control.value;
            vpgas.push(_new);
        })
        return vpgas;
    }

    changeName(e) {
        this.setCurrency(e.value);
    }

    private setCurrency(countryName: string) {
        this._countriesData.forEach(value => {
            if (value.countryName == countryName) {
                this.country.currency = value.currencyCode;
            }
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.country && this.country.id > 0) {
            return this.translate.instant('COUNTRY.FORM.TITLE.EDIT', {name: this.country.name});
        }

        return this.translate.instant('COUNTRY.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.countryForm.controls[controlName];
        return control.invalid && control.touched;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.countryForm.controls;
        /** check form */
        if (this.countryForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        this.country.macro_context_field = '';
        this.country.vpgas = this._getMacroContextValue();

        if (this.country.id > 0) {
            this.updateCountry(this.country);
        } else {
            this.createCountry(this.country);
        }
    }

    /**
     * Update country
     *
     * @param _country: CountryModel
     */
    updateCountry(_country: CountryModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        /* Server loading imitation. Remove this on real code */
        const updateCountry: Update<CountryModel> = {
            id: this.country.id,
            changes: _country
        };
        this.store.dispatch(new CountryUpdated({
            partialItem: updateCountry,
            item: _country
        }));
        of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line
            this.viewLoading = false;
            this.dialogRef.close({
                country: _country,
                isEdit: true
            });
        }); // Remove this line

    }

    /**
     * Create country
     *
     * @param _country: CountryModel
     */
    createCountry(_country: CountryModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        this.store.dispatch(new CountryOnServerCreated({item: _country, currency: coinify.get(_country.currency)}));

        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedCountryId)
        ).subscribe(res => {
            if (!res) {
                return;
            }

            this.viewLoading = false;
            this.dialogRef.close({
                id: res,
                isEdit: false,
            });
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        // return (this.client && this.client.name && this.client.name.length > 0) &&
        //     (this.countryCityComponent.selectedCountryId && this.countryCityComponent.selectedCountryId != undefined) &&
        //     (this.countryCityComponent.selectedCityId && this.countryCityComponent.selectedCityId != undefined);

        return true;
    }

}
