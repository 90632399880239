import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { AssetClassTenure } from 'src/app/core/comparable';
import { RentReviewType } from '../../cash-flow-method-types';
import { CFMRatesAndJustifications, CFMReturnType, SelectedRent } from '../../cf-method-types';
import { CFMethodResult, TVOMMethodResult } from '../../result-data-types';
import { TVOMRatesAndJustification, TVOMSubmethodType } from '../../tvom-types';
import { TimeValueOfMoneyMethodComponent } from '../time-value-of-money-method/time-value-of-money-method.component';
import { CfmComponent } from './cfm/cfm.component';

@Component({
  selector: 'kt-cash-flow-method',
  templateUrl: './cash-flow-method.component.html',
  styleUrls: [
    './cash-flow-method.component.scss',
    '../headline-to-effective.component.scss'
  ]
})
export class CashFlowMethodComponent implements OnInit {
  @Input() refNum: string
  @Input() tpCurrency: string;
  @Input() consideration: AssetClassTenure;
  @Input() ratesAndJustifications: CFMRatesAndJustifications;
  @Input() selected: SelectedRent;
  @Output() valueComputed = new EventEmitter<CFMethodResult>();

  @ViewChild('timeValueOfMoneyMethod') tvomMethodComponent: TimeValueOfMoneyMethodComponent;
  @ViewChild('cfm') cfmComponent: CfmComponent;

  rentReviewType: RentReviewType;
  rentReviewTypeEnum = RentReviewType;

  targetRate$ = of(TVOMSubmethodType.TargetRate);
  ratesAndJustification: TVOMRatesAndJustification = {
    capRate: null,
    capRateJustification: null,
    targetRate: null,
    targetRateJustification: null,
  }

  constructor() { }

  ngOnInit(): void {
    this.rentReviewType = this._checkRentReview();
    this.ratesAndJustification.targetRate = this.ratesAndJustifications.targetRate;
    this.ratesAndJustification.targetRateJustification = this.ratesAndJustifications.targetRateJustification;
  }

  _checkRentReview(): RentReviewType {
    if (Number(this.consideration.rent_review) === 0) {
      return RentReviewType.RentReviewIsEqualToOneOrNA;
    }

    if ((Number(this.consideration.lease_duration) / Number(this.consideration.rent_review)) === 1) {
      return RentReviewType.RentReviewIsEqualToOneOrNA;
    }

    return RentReviewType.RentReviewIsGreaterThanOne;
  }

  isInValid(): boolean {
    let hasInvalidField = false;

    if (this.tvomMethodComponent && this.tvomMethodComponent.isInValid()) {
      hasInvalidField = true;
    }
    if (this.cfmComponent && this.cfmComponent.isInValid()) {
      hasInvalidField = true;
    }

    return hasInvalidField;
  }

  getJustifications(): CFMReturnType {
    if (this.tvomMethodComponent) {
      return {
        targetRate: this.tvomMethodComponent.getJustifications().targetRate,
        targetRateJustification: this.tvomMethodComponent.getJustifications().targetRateJustification,
        growthRate: null,
        growthRateJustification: null,
        effectiveRentJustification: null,
        main: undefined,
        index: undefined,
      }
    }
    if (this.cfmComponent) {
      return this.cfmComponent.getJustifications();
    }
    return {
      targetRate: undefined,
      targetRateJustification: null,
      growthRate: undefined,
      growthRateJustification: null,
      effectiveRentJustification: null,
      main: undefined,
      index: undefined,
    }
  }

  cfmValueComputed(val: number) {
    this.valueComputed.emit({
      calculatedValue: val
    });
  }

  tvomComputed(value: TVOMMethodResult) {
    this.valueComputed.emit({
      calculatedValue: value.calculatedValue,
    });
  }
}
