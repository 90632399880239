import {ShopFrontTypeModel} from '../_models/shop-front-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ShopFrontTypesState} from '../_reducers/shop-front-type.reducer';
import * as fromShopFrontType from '../_reducers/shop-front-type.reducer';
import {each} from 'lodash';

export const selectShopFrontTypesState = createFeatureSelector<ShopFrontTypesState>('shop-front-types');

export const selectShopFrontTypeById = (id: number) => createSelector(
    selectShopFrontTypesState,
    state => state.entities[id]
);

export const selectAllShopFrontTypes = createSelector(
    selectShopFrontTypesState,
    fromShopFrontType.selectAll
);

export const selectAllShopFrontTypesIds = createSelector(
    selectShopFrontTypesState,
    fromShopFrontType.selectIds
);

export const allShopFrontTypesLoaded = createSelector(
    selectShopFrontTypesState,
    state => state.isAllShopFrontTypesLoaded
);


export const selectShopFrontTypesPageLoading = createSelector(
    selectShopFrontTypesState,
    state => state.listLoading
);

export const selectShopFrontTypesActionLoading = createSelector(
    selectShopFrontTypesState,
    state => state.actionLoading
);

export const selectLastCreatedShopFrontTypeId = createSelector(
    selectShopFrontTypesState,
    state => state.lastCreatedShopFrontTypeId
);

export const selectShopFrontTypesShowInitWaitingMessage = createSelector(
    selectShopFrontTypesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedShopFrontTypeCount = createSelector(
    selectShopFrontTypesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedShopFrontTypeCount = createSelector(
    selectShopFrontTypesState,
    (state) => state.totalAdminTrashed
);


export const selectShopFrontTypeQueryResult = createSelector(
    selectShopFrontTypesState,
    state => {
        const items: ShopFrontTypeModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ShopFrontTypeModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
