import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { QuoteService } from "src/app/core/v2/api-services/quote.service";

@Injectable()
export class QuoteResolver implements Resolve<any> {
  constructor(
    private quoteService: QuoteService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const quoteId = Number(route.paramMap.get('quote_id'))
    return this.quoteService.get(quoteId)
  }
}