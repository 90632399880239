// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {StateRepairsService} from '../_services/state-repair.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allStateRepairsLoaded} from '../_selectors/state-repair.selectors';
// Actions
import {
    AllStateRepairsLoaded,
    AllStateRepairsRequested,
    StateRepairActionTypes,
    StateRepairsPageRequested,
    StateRepairsPageLoaded,
    StateRepairUpdated,
    StateRepairsPageToggleLoading,
    StateRepairDeleted,
    StateRepairOnServerCreated,
    StateRepairCreated,
    StateRepairAdminRestored,
    StateRepairDeletedFromAdminTrash,
    StateRepairDeletedFromTrash,
    StateRepairOnServerAdminRestored,
    StateRepairOnServerRestored,
    StateRepairRestored,
    StateRepairTrashFlushed,
    StateRepairsActionToggleLoading
} from '../_actions/state-repair.actions';

@Injectable()
export class StateRepairEffects {
    showPageLoadingDispatcher = new StateRepairsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new StateRepairsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new StateRepairsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllStateRepairs$ = this.actions$
        .pipe(
            ofType<AllStateRepairsRequested>(StateRepairActionTypes.AllStateRepairsRequested),
            withLatestFrom(this.store.pipe(select(allStateRepairsLoaded))),
            filter(([action, isAllStateRepairsLoaded]) => !isAllStateRepairsLoaded),
            mergeMap(() => this.service.getAllStateRepairs()),
            map(res => {
                return new AllStateRepairsLoaded({stateRepairs: res.data});
            })
        );

    @Effect()
    loadStateRepairsPage$ = this.actions$
        .pipe(
            ofType<StateRepairsPageRequested>(StateRepairActionTypes.StateRepairsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findStateRepairs(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new StateRepairsPageLoaded({
                    stateRepairs: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairDeleted>(StateRepairActionTypes.StateRepairDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteStateRepair(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairUpdated>(StateRepairActionTypes.StateRepairUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateStateRepair(payload.stateRepair);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairOnServerCreated>(StateRepairActionTypes.StateRepairOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createStateRepair(payload.stateRepair).pipe(
                    tap(res => {
                        this.store.dispatch(new StateRepairCreated({stateRepair: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushStateRepairTrash$ = this.actions$
        .pipe(
            ofType<StateRepairTrashFlushed>(StateRepairActionTypes.StateRepairTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairOnServerRestored>(StateRepairActionTypes.StateRepairOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new StateRepairRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairOnServerAdminRestored>(StateRepairActionTypes.StateRepairOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new StateRepairAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairDeletedFromTrash>(StateRepairActionTypes.StateRepairDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashStateRepair$ = this.actions$
        .pipe(
            ofType<StateRepairDeletedFromAdminTrash>(StateRepairActionTypes.StateRepairDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: StateRepairsService, private store: Store<AppState>) {
    }
}
