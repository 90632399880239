// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
    selectAssetClassOfficesInStore,
    selectAssetClassOfficesPageLoading,
    selectAssetClassOfficesShowInitWaitingMessage
} from '../_selectors/asset-class-office.selectors';
import { tenureAdder } from './tenure_extension';

export class AssetClassOfficesDataSource extends BaseDataSource {
    trashed = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssetClassOfficesPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssetClassOfficesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssetClassOfficesInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.trashed = response.trashed;
            this.entitySubject.next(response.items.map(e => tenureAdder(e)));
        });
    }
}
