// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SourceCredibilityActions, SourceCredibilityActionTypes} from '../_actions/source-credibility.actions';
// Models
import {SourceCredibility} from '../_models/source-credibility.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SourceCredibilityState extends EntityState<SourceCredibility> {
    isAllSourceCredibilityLoaded: boolean;
    queryRowsCount: number;
    queryResult: SourceCredibility[];
    lastCreatedSourceCredibilityId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SourceCredibility> = createEntityAdapter<SourceCredibility>();

export const initialSourceCredibilityState: SourceCredibilityState = adapter.getInitialState({
    isAllSourceCredibilityLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSourceCredibilityId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function sourceCredibilityReducer(state = initialSourceCredibilityState, action: SourceCredibilityActions): SourceCredibilityState {
    switch (action.type) {
        case SourceCredibilityActionTypes.SourceCredibilityPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSourceCredibilityId: undefined
            };
        case SourceCredibilityActionTypes.SourceCredibilityActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SourceCredibilityActionTypes.SourceCredibilityOnServerCreated:
            return {
                ...state
            };
        case SourceCredibilityActionTypes.SourceCredibilityCreated:
            return adapter.addOne(action.payload.sourceCredibility, {
                ...state, lastCreatedSourceCredibilityId: action.payload.sourceCredibility.id
            });
        case SourceCredibilityActionTypes.SourceCredibilityUpdated:
            return adapter.updateOne(action.payload.partialSourceCredibility, state);
        case SourceCredibilityActionTypes.AllSourceCredibilityLoaded:
            return adapter.addAll(action.payload.sourceCredibility, {
                ...state, isAllSourceCredibilityLoaded: true
            });
        case SourceCredibilityActionTypes.SourceCredibilityPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SourceCredibilityActionTypes.SourceCredibilityPageLoaded:
            return adapter.addMany(action.payload.sourceCredibility, {
                ...initialSourceCredibilityState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case SourceCredibilityActionTypes.SourceCredibilityDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SourceCredibilityActionTypes.SourceCredibilityRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SourceCredibilityActionTypes.SourceCredibilityDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SourceCredibilityActionTypes.SourceCredibilityAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SourceCredibilityActionTypes.SourceCredibilityDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SourceCredibilityActionTypes.SourceCredibilityTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
