// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {TypeOfTitlesService} from '../_services/type-of-title.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allTypeOfTitlesLoaded} from '../_selectors/type-of-title.selectors';
// Actions
import {
    AllTypeOfTitlesLoaded,
    AllTypeOfTitlesRequested,
    TypeOfTitleActionTypes,
    TypeOfTitlesPageRequested,
    TypeOfTitlesPageLoaded,
    TypeOfTitleUpdated,
    TypeOfTitlesPageToggleLoading,
    TypeOfTitleDeleted,
    TypeOfTitleOnServerCreated,
    TypeOfTitleCreated,
    TypeOfTitleAdminRestored,
    TypeOfTitleDeletedFromAdminTrash,
    TypeOfTitleDeletedFromTrash,
    TypeOfTitleOnServerAdminRestored,
    TypeOfTitleOnServerRestored,
    TypeOfTitleRestored,
    TypeOfTitleTrashFlushed,
    TypeOfTitlesActionToggleLoading
} from '../_actions/type-of-title.actions';

@Injectable()
export class TypeOfTitleEffects {
    showPageLoadingDispatcher = new TypeOfTitlesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new TypeOfTitlesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TypeOfTitlesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTypeOfTitles$ = this.actions$
        .pipe(
            ofType<AllTypeOfTitlesRequested>(TypeOfTitleActionTypes.AllTypeOfTitlesRequested),
            withLatestFrom(this.store.pipe(select(allTypeOfTitlesLoaded))),
            filter(([action, isAllTypeOfTitlesLoaded]) => !isAllTypeOfTitlesLoaded),
            mergeMap(() => this.service.getAllTypeOfTitles()),
            map(res => {
                return new AllTypeOfTitlesLoaded({typeOfTitles: res.data});
            })
        );

    @Effect()
    loadTypeOfTitlesPage$ = this.actions$
        .pipe(
            ofType<TypeOfTitlesPageRequested>(TypeOfTitleActionTypes.TypeOfTitlesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findTypeOfTitles(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new TypeOfTitlesPageLoaded({
                    typeOfTitles: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleDeleted>(TypeOfTitleActionTypes.TypeOfTitleDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteTypeOfTitle(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleUpdated>(TypeOfTitleActionTypes.TypeOfTitleUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateTypeOfTitle(payload.typeOfTitle);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleOnServerCreated>(TypeOfTitleActionTypes.TypeOfTitleOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createTypeOfTitle(payload.typeOfTitle).pipe(
                    tap(res => {
                        this.store.dispatch(new TypeOfTitleCreated({typeOfTitle: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTypeOfTitleTrash$ = this.actions$
        .pipe(
            ofType<TypeOfTitleTrashFlushed>(TypeOfTitleActionTypes.TypeOfTitleTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleOnServerRestored>(TypeOfTitleActionTypes.TypeOfTitleOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TypeOfTitleRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleOnServerAdminRestored>(TypeOfTitleActionTypes.TypeOfTitleOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TypeOfTitleAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleDeletedFromTrash>(TypeOfTitleActionTypes.TypeOfTitleDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashTypeOfTitle$ = this.actions$
        .pipe(
            ofType<TypeOfTitleDeletedFromAdminTrash>(TypeOfTitleActionTypes.TypeOfTitleDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: TypeOfTitlesService, private store: Store<AppState>) {
    }
}
