import {CriterionModel} from './criterion.model';

export class ValuationAdjustmentModel {
    id: number;
    adjustment_id: number;
    name: string;
    adjValues: {[id: number]: number};
    desc: string;
    analysis: string;

    clear() {
        this.id = undefined;
        this.adjustment_id = undefined;
        this.name = '';
        this.adjValues = {};
        this.desc = '';
        this.analysis = '';
    }

    addComparable(comparables: any[]) {
        comparables.forEach(c => {
            this.adjValues[c.id] = 0;
        });
    }
}