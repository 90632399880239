<div class="d-flex flex-column mb-4" *ngIf="loading$|async">
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<span></span>
		</div>
		<div class="card-toolbar">
			<div class="d-flex justify-content-end">
				<button mat-raised-button color="secondary" type="button" class="btn btn-secondary mr-4" (click)="addReminder()">
					Add a reminder
				</button>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="mat-table-wrapper">
			<mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="date">
					<mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{ item.date | date:'dd MMM yyyy' }}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="type">
					<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{ item.type_name }}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="target_property">
					<mat-header-cell *matHeaderCellDef>Target Property</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{ item.tp_name }}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="interaction_type">
					<mat-header-cell *matHeaderCellDef>Interaction type</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<div>
							<ng-container *ngIf="item.interaction_type_name">
								{{ item.interaction_type_name }}
							</ng-container>
							<ng-container *ngIf="!item.interaction_type_name">
								<span class="font-weight-bolder text-danger">
									TBD
								</span>
							</ng-container>
						</div>
						<button mat-icon-button color="primary"
							[ngbPopover]="info"
							placement="top"
							container="body"
							>
							<mat-icon>info</mat-icon>
						</button>
						<ng-template #info>
							<div>
								<div>The note was created by <strong>{{item.created_by}}</strong> on <strong>{{item.date | date:'dd MMM yyyy'}}</strong></div>
								<div>Note: {{item.audit_trail && item.audit_trail.interaction_description ? item.audit_trail.interaction_description : item.notes}}</div>
							</div>
						</ng-template>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="status">
					<mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<ng-container *ngTemplateOutlet="status; context: {status: item.status}"></ng-container>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="user">
					<mat-header-cell *matHeaderCellDef>User</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<ng-container *ngIf="item.user">
							{{ item.user }}
						</ng-container>
						<ng-container *ngIf="!item.user">
							<span class="font-weight-bolder text-danger">
								TBD
							</span>
						</ng-container>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<button mat-icon-button color="primary" (click)="editReminder(item)">
							<mat-icon>edit</mat-icon>
						</button>
						<span [matTooltip]="item.status == 1 ? 'Delete' : 'Cannot Delete'">
							<button mat-icon-button color="warn" (click)="deleteReminder(item)" [disabled]="item.status == 2">
								<mat-icon>delete</mat-icon>
							</button>
						</span>
						<span [matTooltip]="item.user_id == null ? 'Assign User' : 'Reach'">
							<button [disabled]="item.user_id == null" mat-icon-button color="primary" (click)="reach(item)"
								type="button">
								<i class="flaticon2-arrow" [ngStyle]="{'color': item.user_id == null ? 'lightgray' : 'black'}"></i>
							</button>
						</span>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="columns"
					[style.backgroundColor]="'#bc9ef8'">
				</mat-header-row>
				<mat-row *matRowDef="let row; columns: columns"></mat-row>
			</mat-table>
		</div>
	</div>
</div>

<ng-template #status let-status="status">
	<div *ngIf="status == 1">
		<span style="color: #F44D5E; font-weight: bold; margin-left: 10px;">Ongoing</span>
	</div>
	<div *ngIf="status == 2">
		<span style="color: #16C4BB; font-weight: bold; margin-left: 10px;">Completed</span>
	</div>
</ng-template>