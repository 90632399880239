import {AppendixCategory} from '../_models/appendix-category.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AppendixCategoriesState} from '../_reducers/appendix-category.reducers';
import * as fromAppendixCategory from '../_reducers/appendix-category.reducers';
import {each} from 'lodash';

export const selectAppendixCategoriesState = createFeatureSelector<AppendixCategoriesState>('appendixCategories');

export const selectAppendixCategoryById = (appendixCategoryId: number) => createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => appendixCategoriesState.entities[appendixCategoryId]
);

export const selectAllAppendixCategories = createSelector(
    selectAppendixCategoriesState,
    fromAppendixCategory.selectAll
);

export const selectAllAppendixCategoriesIds = createSelector(
    selectAppendixCategoriesState,
    fromAppendixCategory.selectIds
);

export const allAppendixCategoriesLoaded = createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => appendixCategoriesState.isAllAppendixCategoriesLoaded
);


export const selectAppendixCategoriesPageLoading = createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => appendixCategoriesState.listLoading
);

export const selectAppendixCategoriesActionLoading = createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => appendixCategoriesState.actionLoading
);

export const selectTrashedAppendixCategoriesCount = createSelector(
    selectAppendixCategoriesState,
    (appendixCategoriesState) => appendixCategoriesState.totalTrashed
);

export const selectAdminTrashedAppendixCategoriesCount = createSelector(
    selectAppendixCategoriesState,
    (appendixCategoriesState) => appendixCategoriesState.totalAdminTrashed
);

export const selectLastCreatedAppendixCategoryId = createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => appendixCategoriesState.lastCreatedAppendixCategoryId
);

export const selectAppendixCategoriesShowInitWaitingMessage = createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => appendixCategoriesState.showInitWaitingMessage
);


export const selectAppendixCategoriesQueryResult = createSelector(
    selectAppendixCategoriesState,
    appendixCategoriesState => {
        const items: AppendixCategory[] = [];
        each(appendixCategoriesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: AppendixCategory[] = [];
        switch (appendixCategoriesState.lastQuery.sortField) {
            case  'appendix_cnt':
            case  'id':
                result = httpExtension.sortArrayNumber(items, appendixCategoriesState.lastQuery.sortField,
                    appendixCategoriesState.lastQuery.sortOrder);
                break;
            case  'name':
                result = httpExtension.sortArrayString(items, appendixCategoriesState.lastQuery.sortField,
                    appendixCategoriesState.lastQuery.sortOrder);
                break;
            default:
                result = httpExtension.sortArrayString(items, appendixCategoriesState.lastQuery.sortField,
                    appendixCategoriesState.lastQuery.sortOrder);
        }


        return new QueryResultsModel(result, appendixCategoriesState.queryRowsCount, '');
    }
    )
;
