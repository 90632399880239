import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { ReportDesignModel } from "./report-design.model";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { SubdomainService } from "src/app/core/_base/subdomain.service";


@Injectable()
export class ReportDesignService {
  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/report-design`
  constructor(
    private httpClient: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  public upsert(item: ReportDesignModel, assetClassId: number) {
    const httpHeaders = this.authService.getAuthHeaders();
    return this.httpClient.post(`${this.API_URL}/${assetClassId}`, {item}, {headers: httpHeaders})
  }

  public get(assetClassId: number) {
    const httpHeaders = this.authService.getAuthHeaders();
    return this.httpClient.get<any>(`${this.API_URL}/edit/${assetClassId}`, {headers: httpHeaders})
  }
}