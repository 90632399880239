<div class="d-flex flex-column">
    <div class="col-lg-12 px-0 kt-margin-bottom-20-mobile section-margin-top-60">
        <h5 class="my-3 text-mad text-uppercase">
            Registration numbers (taxes, VAT, company, etc…)
            <mat-icon
                matSuffix
                class="cursor-pointer mt-1"
                (click)="$event.stopPropagation();"
                container="body"
                [ngbPopover]="'GENERAL.REG_NUMBERS.DESCRIPTION' | translate">help
            </mat-icon>
        </h5>
        <hr class="active">
    </div>

    <div ktPortletTools class="mb-1 d-flex justify-content-end" *ngIf="!readonly">
        <button  class="btn btn-primary" color="primary" (click)="addRegNumber()" type="button">
            Add Registration #
        </button>
    </div>

    <!-- &lt;!&ndash;<div *ngIf="(participantsSubject| async).value > 0" class="example-container mat-elevation-z0">&ndash;&gt; -->
    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="label">
                <mat-header-cell *matHeaderCellDef> #</mat-header-cell>
                <mat-cell *matCellDef="let regNumber"> {{regNumber.label}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="code">
                <mat-header-cell *matHeaderCellDef> Number / Code</mat-header-cell>
                <mat-cell *matCellDef="let regNumber"> {{regNumber.code}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let regNumber;let i = index;">
                    <button mat-icon-button
                            color="primary"
                            [disabled]="readonly"
                            matTooltip="Edit external reference"
                            (click)="editRegNumber(regNumber, i)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="warn"
                            [disabled]="readonly"
                            matTooltip="Delete external reference"
                            (click)="deleteRegNumber(regNumber, i)"
                            type="button">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>