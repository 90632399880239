import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type TabHeader = {
  label: string,
  disabled: Observable<boolean>,
  tooltip?: {
    title: string,
    description: string
  },
  activeButton?: {
    label: string,
    onClick: () => void
  }
}

@Component({
  selector: 'kt-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss']
})
export class TabHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() headerLabels: TabHeader[] = [];
  @Input() selectedTabChange: Observable<number>;
  @Input() centerTabs: boolean = false;
  @Output() tabChange: EventEmitter<number> = new EventEmitter();
  @Input() innerTop: string = null;
  @ViewChild('container', {static: true}) headerContainer: ElementRef<HTMLDivElement>;
  backgroun: ThemePalette = 'primary';
  selectedIndex: number = 0;
  _onDestroy$: Subject<void> = new Subject();


  @HostBinding('style.top') top = '0px';
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.innerTop) {
      this.top = this.innerTop;
      return;
    }
    this.updateStickyPosition();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.innerTop) {
      this.top = this.innerTop;
      return;
    }
    this.updateStickyPosition();
  }

  constructor(
  ) { 
  }

  ngOnInit(): void {
    if (this.selectedTabChange) {
      this.selectedTabChange.pipe(takeUntil(this._onDestroy$)).subscribe(num => this.selectedIndex = num);
    }
  }
  ngAfterViewInit() {
    if (this.innerTop) {
      this.top = this.innerTop;
      return;
    }
    this.updateStickyPosition();
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  changeTab(index: number) {
    this.selectedIndex = index;
    this.tabChange.emit(this.selectedIndex);
  }

  updateStickyPosition() {
    const topHeaderElement = document.querySelector('.header') as HTMLElement;
    const ktPortleHeaderEl = document.getElementsByTagName('kt-portlet-header').item(0) as HTMLElement;
    const modalHeaderEl = document.querySelector('.card-header') as HTMLElement;
    let height = 0;
    if (topHeaderElement != null) {
      height += topHeaderElement.offsetHeight;
    }
    if (ktPortleHeaderEl != null)  {
      height += ktPortleHeaderEl.offsetHeight
    }
    if (ktPortleHeaderEl == null && modalHeaderEl != null) {
      height += modalHeaderEl.offsetHeight;
    }
    this.top = height + 'px';
    // this.headerContainer.nativeElement.style.top = height + 'px';
  }
}
