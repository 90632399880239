// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {OrganisationActions, OrganisationActionTypes} from '../_actions/organisation.actions';
// Models
import {OrganisationModel} from '../_models/organisation.model';
import {QueryParamsModel} from '../../_base/crud';

export interface OrganisationsState extends EntityState<OrganisationModel> {
    isAllOrganisationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: OrganisationModel[];
    lastCreatedOrganisationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<OrganisationModel> = createEntityAdapter<OrganisationModel>();

export const initialOrganisationState: OrganisationsState = adapter.getInitialState({
    isAllOrganisationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOrganisationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function organisationReducer(state = initialOrganisationState, action: OrganisationActions): OrganisationsState {
    switch (action.type) {
        case OrganisationActionTypes.OrganisationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedOrganisationId: undefined
            };
        case OrganisationActionTypes.OrganisationActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case OrganisationActionTypes.OrganisationOnServerCreated:
            return {
                ...state
            };
        case OrganisationActionTypes.OrganisationCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedOrganisationId: action.payload.item.id
            });
        case OrganisationActionTypes.OrganisationUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case OrganisationActionTypes.AllOrganisationsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllOrganisationsLoaded: true
            });
        case OrganisationActionTypes.OrganisationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case OrganisationActionTypes.OrganisationsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialOrganisationState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case OrganisationActionTypes.OrganisationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case OrganisationActionTypes.OrganisationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case OrganisationActionTypes.OrganisationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case OrganisationActionTypes.OrganisationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case OrganisationActionTypes.OrganisationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case OrganisationActionTypes.OrganisationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
