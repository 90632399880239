import { AfterViewInit, Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResidentialExternalAreaModel } from 'src/app/core/comparable';
import { AllExternalAreasRequested, AllGardensRequested, AllOutdoorSpacesRequested, ChimneyStackActionToggleLoading, ExternalArea, selectAllExternalAreas, selectAllGardens, selectAllOutdoorSpaces } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { InspectionSizeService } from '../../target-property/inspection-base/inspection-size.service';


@Component({
  selector: 'kt-ac-grounds',
  templateUrl: './ac-grounds.component.html',
  styleUrls: ['./ac-grounds.component.scss']
})
export class AcGroundsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() readonly: boolean = false;
  @Input() propertySubTypeID: number;
  @Input() isComplexForm: boolean;
  @Input() initData: any;
  @Input() parent: 'simple' | 'inspection' = 'simple';
  formGroup: UntypedFormGroup;

  public garageRange: {
    value: number,
    label: string,
  }[] = [];
  public gardens = [];
  public outdoorSpaces = [];
  externalAreas: ExternalArea[] = [];
  apartmentResidentialExternalAreas: number[] = [];
  public externalAreasInValid$: Subject<boolean> = new Subject();
  errorFields = [];

  private _onDestroy$: Subject<void> = new Subject();
  inspectionSizeService = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private injector: Injector,
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.initData && !changes.initData.firstChange) {
      this._populateForm();
    }
  }

  private _populateForm() {
    if (this.formGroup) {
      this.formGroup.patchValue({
        indoor_garage: this.initData.indoor_garage, 
        dedicated_indoor_garage: this.initData.dedicated_indoor_garage, 
        outdoor_garage: this.initData.outdoor_garage, 
        dedicated_outdoor_garage: this.initData.dedicated_outdoor_garage, 
        indoor_garage_comment: this.initData.indoor_garage_comment,
        outdoor_garage_comment: this.initData.outdoor_garage_comment,
        garden_id: this.initData.garden_id,
        garden_comment: this.initData.garden_comment,
        outdoor_space_id: this.initData.outdoor_space_id,
        outdoor_space_comment: this.initData.outdoor_space_comment,
        external_area_comment: this.initData.external_area_comment
      });
      this.formGroup.updateValueAndValidity();
    } else {
      this.formGroup = this.formBuilder.group({
        indoor_garage: [this.initData.indoor_garage, 
          this.parent == 'inspection' && (this.propertySubTypeID == 0)
            ? Validators.required
            : ((this.propertySubTypeID != 0) ? null : Validators.required)],
        outdoor_garage: [this.initData.outdoor_garage, 
          this.parent == 'inspection' && (this.propertySubTypeID == 0) 
            ? Validators.required
            : ((this.propertySubTypeID != 0) ? null : Validators.required)],
        dedicated_indoor_garage: [this.initData.dedicated_indoor_garage,
          this.parent == 'inspection' && this.propertySubTypeID != 0
            ? Validators.required
            : (this.propertySubTypeID == 0) ? null : Validators.required
        ],
        dedicated_outdoor_garage: [this.initData.dedicated_outdoor_garage,
          this.parent == 'inspection' && this.propertySubTypeID != 0
            ? Validators.required
            : (this.propertySubTypeID == 0) ? null : Validators.required
        ],
        indoor_garage_comment: [this.initData.indoor_garage_comment],
        outdoor_garage_comment: [this.initData.outdoor_garage_comment],

        garden_id: [this.initData.garden_id, this.propertySubTypeID == 17 ? Validators.required : null],
        garden_comment: [this.initData.garden_comment],

        outdoor_space_id: [this.initData.outdoor_space_id, this.propertySubTypeID == 7 ? Validators.required : null],
        outdoor_space_comment: [this.initData.outdoor_space_comment],

        external_area_comment: [this.initData.external_area_comment]
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.parent == 'inspection') {
      this.inspectionSizeService = this.injector.get<InspectionSizeService>(InspectionSizeService);
      this.inspectionSizeService && this.inspectionSizeService.setExternalAreas(this.externalAreas.filter(ea => this.apartmentResidentialExternalAreas.includes(ea.id)))
    }
  }


  ngOnInit(): void {
    this.garageRange = setGarageRange();
    this.apartmentResidentialExternalAreas = this.initData.external_areas ? this.initData.external_areas.map(id => parseInt(id)) : this.apartmentResidentialExternalAreas;
    
    this.store.dispatch(new AllGardensRequested());
    this.store.select(selectAllGardens).pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      this.gardens = val ? val : [];
    });

    this.store.dispatch(new AllOutdoorSpacesRequested());
    this.store.select(selectAllOutdoorSpaces).pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      this.outdoorSpaces = val ? val : [];
    });
    this.store.dispatch(new AllExternalAreasRequested());
    this.store.pipe(
      select(selectAllExternalAreas), takeUntil(this._onDestroy$))
      .subscribe(res => {
        this.externalAreas = [];
        if (res) {
          this.externalAreas = res.filter(item => item.id !== 6);
          if (res.find(item => item.id == 6)) {
            this.externalAreas = [res.find(item => item.id == 6), ...this.externalAreas]
          }
          this.inspectionSizeService && this.inspectionSizeService.setExternalAreas(this.externalAreas.filter(ea => this.apartmentResidentialExternalAreas.includes(ea.id)))
        }
      });

    this._populateForm();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  validate(): boolean {
    let errorFields = [];
    let hasError = false;
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        controls[cName].markAsTouched();
      })
      hasError = true;
    }
    if ((this.propertySubTypeID == 5 || this.propertySubTypeID == 1) && this.apartmentResidentialExternalAreas.length == 0) {
      this.externalAreasInValid$.next(true);
      hasError = true;
      errorFields.push('external_area');
    }
    this.errorFields = errorFields;
    return hasError;
  }

  getData() {
    const controls = this.formGroup.controls;
    return {
      indoor_garage: controls.indoor_garage.value,
      dedicated_indoor_garage: controls.dedicated_indoor_garage.value,
      indoor_garage_comment: controls.indoor_garage_comment.value,

      outdoor_garage: controls.outdoor_garage.value,
      dedicated_outdoor_garage: controls.dedicated_outdoor_garage.value,
      outdoor_garage_comment: controls.outdoor_garage_comment.value,

      garden_id: controls.garden_id.value,
      garden_comment: controls.garden_comment.value,

      outdoor_space_id: controls.outdoor_space_id.value,
      outdoor_space_comment: controls.outdoor_space_comment.value,

      external_areas: this.apartmentResidentialExternalAreas,
      external_area_comment: controls.external_area_comment.value ?? null
    }
  }

  updateCheckedOptions(item, $event) {
    // Id of "None" is 6
    if (item.id == 6 && $event.checked) {
      const obj: ResidentialExternalAreaModel = new ResidentialExternalAreaModel();
      obj.clear();
      obj.area_id = item.id;
      obj.name = item.name;
      this.apartmentResidentialExternalAreas = [obj.area_id]
    } else {
      if ($event.checked) {
        const obj: ResidentialExternalAreaModel = new ResidentialExternalAreaModel();
        obj.clear();
        obj.area_id = item.id;
        obj.name = item.name;
        this.apartmentResidentialExternalAreas = [...this.apartmentResidentialExternalAreas, obj.area_id];
        this.apartmentResidentialExternalAreas = this.apartmentResidentialExternalAreas.filter(are => are !== 6)
        this.externalAreasInValid$.next(false);
      } else {
        const index = this.apartmentResidentialExternalAreas.findIndex(element => element === item.id);
        if (index !== -1) {
          this.apartmentResidentialExternalAreas.splice(index, 1);
        }
        if (this.apartmentResidentialExternalAreas.length == 0) {
          this.externalAreasInValid$.next(true);
        }
      }
    }
    this.inspectionSizeService && this.inspectionSizeService.setExternalAreas(this.externalAreas.filter(ea => this.apartmentResidentialExternalAreas.includes(ea.id)))
  }

  setSelectedExternalArea(item): boolean {
    if (this.apartmentResidentialExternalAreas.length <= 0) {
      return false;
    } else {
      return this.apartmentResidentialExternalAreas.some(element => element === item.id);
    }
  }
}

function setGarageRange(): {value: number, label: string}[] {
    const ranges: { value: number, label: string }[] = [];
    ranges.push({value: 0, label: '0'});
    ranges.push({value: 1, label: '1 to 10'});
    ranges.push({value: 2, label: '11 to 25'});

    let lowerBound = 25;
    let upperBound = 50;
    let i = 3;
    while (upperBound <= 200) {
        ranges.push({value: i, label: `${lowerBound + 1} to ${upperBound}`});
        lowerBound = upperBound;
        upperBound = 25 + lowerBound;
        i++;
    }
    ranges.push({value: i, label: '>200'});

    return ranges;
}
