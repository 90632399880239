import {SpecialAssumption} from '../_models/special-assumption.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SpecialAssumptionsState} from '../_reducers/special-assumption.reducers';
import * as fromSpecialAssumption from '../_reducers/special-assumption.reducers';
import {each} from 'lodash';

export const selectSpecialAssumptionsState = createFeatureSelector<SpecialAssumptionsState>('specialAssumptions');

export const selectSpecialAssumptionById = (specialAssumptionId: number) => createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => specialAssumptionsState.entities[specialAssumptionId]
);

export const selectAllSpecialAssumptions = createSelector(
    selectSpecialAssumptionsState,
    fromSpecialAssumption.selectAll
);

export const selectAllSpecialAssumptionsIds = createSelector(
    selectSpecialAssumptionsState,
    fromSpecialAssumption.selectIds
);

export const allSpecialAssumptionsLoaded = createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => specialAssumptionsState.isAllSpecialAssumptionsLoaded
);


export const selectSpecialAssumptionsPageLoading = createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => specialAssumptionsState.listLoading
);

export const selectSpecialAssumptionsActionLoading = createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => specialAssumptionsState.actionLoading
);

export const selectLastCreatedSpecialAssumptionId = createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => specialAssumptionsState.lastCreatedSpecialAssumptionId
);

export const selectSpecialAssumptionsShowInitWaitingMessage = createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => specialAssumptionsState.showInitWaitingMessage
);

export const selectTrashedSpecialAssumptionCount = createSelector(
    selectSpecialAssumptionsState,
    (specialAssumptionsState) => specialAssumptionsState.totalTrashed
);

export const selectAdminTrashedSpecialAssumptionCount = createSelector(
    selectSpecialAssumptionsState,
    (specialAssumptionsState) => specialAssumptionsState.totalAdminTrashed
);


export const selectSpecialAssumptionQueryResult = createSelector(
    selectSpecialAssumptionsState,
    specialAssumptionsState => {
        const items: SpecialAssumption[] = [];
        each(specialAssumptionsState.entities, element => {
            if (element.id != 1) {
                items.push(element);
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SpecialAssumption[] = httpExtension.sortArray(items, specialAssumptionsState.lastQuery.sortField, specialAssumptionsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, specialAssumptionsState.totalCount, '', specialAssumptionsState.totalTrashed);
    }
);
