import {
    Component, OnDestroy, OnInit, Input, OnChanges, SimpleChange, SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, combineLatest, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../core/_base/crud';
import { AppState } from '../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploadService } from '../../../../core/file-upload/_services';
import { AssetClassSizeModel } from '../../../../core/comparable';
import { AddSizeDialogComponent, IAddSizeDialogData } from '../../comparable/_sub/add-size-dialog/add-size-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
    AllUnitAreaMeasurementsRequested, selectUnitAreaMeasurementById,
    selectAllStandardMeasurements, AllStandardMeasurementsRequested,
    StandardMeasurement, UnitAreaMeasurement
} from '../../../../core/linked-tables';
import { Observable } from 'rxjs';
import { v4 as uuidv4} from 'uuid'

@Component({
    selector: 'kt-ac-size',
    templateUrl: './ac-size.component.html',
    styleUrls: ['./ac-size.component.scss'],
})
export class AcSizeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() readonly: boolean = false;
    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() sizesSubject: BehaviorSubject<AssetClassSizeModel[]>;
    @Input() assetClassType: string;
    @Input() addingTitle = '';
    @Input() staticUnit: number;
    @Input() standardMeasurementID: number;
    @Input() hideTitle: boolean;
    @Input() lowLevelTitle: string = null;
    @Input() measurements$: Observable<any[]> = of([]);
    @Input() newMeasurementName$: Observable<string> = of(null);
    staticUnitArea: UnitAreaMeasurement;
    assetClassTypeName: string;
    standardMeasurements: StandardMeasurement[] = [];

    displayedColumns = ['name'];
    dataSource = new MatTableDataSource();
    buttonEnable$: Observable<boolean>;


    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.staticUnit) {
            this.store.pipe(select(selectUnitAreaMeasurementById(changes.staticUnit.currentValue))).subscribe(res => {
                this.staticUnitArea = res
            });
        }
    }

    ngOnInit() {
        switch (this.assetClassType) {
            case '1':
                this.assetClassTypeName = 'Residential';
                break;
            case '2':
                this.assetClassTypeName = 'Parking';
                break;
            case '3':
                this.assetClassTypeName = 'Office';
                break;
            case '5':
                this.assetClassTypeName = 'Retail Store'
                break;
            case '7':
                this.assetClassTypeName = 'Warehouse';
                break;
            case '11':
                this.assetClassTypeName = 'Retail Building';
                break;
            case '17': 
                this.assetClassTypeName = 'House';
                break;
            case '0':
                this.assetClassTypeName = 'Building';
                break;
            default:
                this.assetClassTypeName = 'Unknown';
        }
        
        this.store.dispatch(new AllStandardMeasurementsRequested());
        const standardMeasurementsSubscribe = 
           combineLatest([
            this.store.pipe(select(selectAllStandardMeasurements)),
            this.measurements$,
            this.newMeasurementName$
           ]) 
            .subscribe(([res, mms, name]) => {
                this.standardMeasurements = mms;
                if (res) {
                    this.standardMeasurements = res.filter(measurement => measurement.asset_class_type_id.toString() == this.assetClassType);
                    this.standardMeasurements = 
                        mms.length == 0 
                            ? this.standardMeasurements.find(sm => sm.id == this.standardMeasurementID)
                                ? [this.standardMeasurements.find(sm => sm.id == this.standardMeasurementID)] 
                                : res.filter(measurement => measurement.asset_class_type_id.toString() == this.assetClassType)
                            : mms.map(item => {
                                if (item.id == -2) {
                                    return {...item, name: name};
                                }
                                return item;
                            });
                }
            });
        this.subscriptions.push(standardMeasurementsSubscribe);

        if (!this.staticUnit) {
            this.displayedColumns.push('unit_of_area_measurement_name');
            this.buttonEnable$ = of(true);
        } else if (this.readonly) {
            this.buttonEnable$ = of(false);
        } else {
            this.buttonEnable$ = this.sizesSubject.pipe(
                map(val => {
                    if (val.length === 0) {
                        return true;
                    }
                    return false;
                }))
            if(this.staticUnit == 1) {
                sessionStorage.setItem('unit_of_area_measurement_id', '' + 2);
            } else {
                sessionStorage.setItem('unit_of_area_measurement_id', '' + 1)
            }
        }

        if (this.readonly) {
            this.buttonEnable$ = of(false);
        }
        
        this.displayedColumns.push('size');
        this.displayedColumns.push('actions');
        
        this.dataSource = new MatTableDataSource(this.sizesSubject.value);
        
        const sizesSubjectSubscription = this.sizesSubject.asObservable()
            .pipe(distinctUntilChanged())
            .subscribe(res => this.dataSource.data = res);
        this.subscriptions.push(sizesSubjectSubscription);
        
        this.store.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store.pipe(select(selectUnitAreaMeasurementById(this.staticUnit))).subscribe(res => this.staticUnitArea = res);
    }

    getItemName(id: number): string {
        const item = this.standardMeasurements.find(item => item ? item.id == id : false);
        return item?.name; 
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem() {

        const _measurements = this.sizesSubject.value.map(el => el.standard_measurement_id);
        // const dialogRef = this.dialog.open<AddSizeDialogComponent, IAddSizeDialogData>(AddSizeDialogComponent, {
        //     data: {unit: this.staticUnit, measurements: _measurements, assetClassType: this.assetClassType}});

        const dialogRef = this.dialog.open<AddSizeDialogComponent, IAddSizeDialogData>(AddSizeDialogComponent, {
            data: {
                assetClassType: Number(this.assetClassType),
                isInspectionForm: this.staticUnit ? true : false,
                unitAreaMeasurementId: this.staticUnit,
                selectedMeasurements: _measurements,
                standardMeasurementId: this.standardMeasurementID
            }
        })
        
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _data = Object.assign([], this.sizesSubject.value);
            const temp: AssetClassSizeModel = Object.assign({}, res.size);
            if (this.staticUnit) {
                temp.unit_of_area_measurement_id = this.staticUnit;
            }
            res.size.uid = uuidv4()
            _data.push(res.size);
            sessionStorage.setItem('unit_of_area_measurement_id', temp.unit_of_area_measurement_id.toString());
        
            this.sizesSubject.next(_data);
        });
    }

    editItem(_item: AssetClassSizeModel, _index: number) {
        const _measurements = this.sizesSubject.value.map((el, i) => {
            if (i != _index) {
                return el.standard_measurement_id
            }
        });
        // const dialogRef = this.dialog.open<AddSizeDialogComponent, IAddSizeDialogData>(AddSizeDialogComponent, {
        //     data: {
        //         unit: this.staticUnit,
        //         acSize: _item,
        //         measurements: _measurements,
        //         assetClassType: this.assetClassType,
        //     }
        // });
        const dialogRef = this.dialog.open<AddSizeDialogComponent, IAddSizeDialogData>(AddSizeDialogComponent, {
            data: {
                assetClassType: Number(this.assetClassType),
                size: _item,
                isInspectionForm: this.staticUnit ? true : false,
                unitAreaMeasurementId: this.staticUnit,
                selectedMeasurements: _measurements,
                standardMeasurements: this.standardMeasurements
            }
        })
        const _data = this.sizesSubject.value;
        const _mutableData = _data.map(d => {
            const _d = Object.assign({}, d);
            return _d;
        })
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const temp: AssetClassSizeModel = Object.assign({}, res.size);
            temp._isEditMode = true;
            _mutableData[_index] = temp;
            this.sizesSubject.next(_mutableData);
        });
    }
    
    deleteItem(_item: AssetClassSizeModel) {
        const _data = this.sizesSubject.value.filter(obj => obj !== _item);
        this.sizesSubject.next(_data);
    }
    
    getStandardMeasurementname(standard_measurement_id) {
        if (!standard_measurement_id) {
            return '(N/A)';
        }
        if (this.standardMeasurements.length > 0) {
            return this.standardMeasurements.find(el => el.id == standard_measurement_id).name;
        } else {
            return 'N/A';
        }
    }
}
