<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form" [formGroup]="fg">
        <div class="card-body">
            <div class="form-group kt-form__group row">
                <div class="col-12 kt-margin-bottom-20-mobile mt-4 mb-2">
                    <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput 
                            placeholder="Description" 
                            rows="2" 
                            formControlName="desc"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">Close</button>&nbsp;
                        <button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial>
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
