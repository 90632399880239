// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TpAdminTaskLVModel} from '../_models/tp-admin-task-lv.model';

export enum TpAdminTaskLVActionTypes {
    AllTpAdminTaskLVRequested = '[Init] All TpAdminTaskLV Requested',
    AllTpAdminTaskLVLoaded = '[Init] All TpAdminTaskLV Loaded',
    AllTpAdminTaskLVLoadedByUser = '[Init] All TpAdminTaskLV Loaded By User',
    TpAdminTaskLVCleared = '[Init] All TpAdminTaskLV cleared',

    TpAdminTaskLVOnServerCreated = '[Edit TpAdminTaskLV Dialog] TpAdminTaskLV On Server Created',
    TpAdminTaskLVCreated = '[Edit TpAdminTaskLV Dialog] TpAdminTaskLV Created',
    TpAdminTaskLVUpdated = '[Edit TpAdminTaskLV Dialog] TpAdminTaskLV Updated',

    TpAdminTaskLVActionToggleLoading = '[TpAdminTaskLV] TpAdminTaskLV Action Toggle Loading',

    TpAdminTaskLVDeleted = '[TpAdminTaskLV List Page] TpAdminTaskLV Deleted',

    AllTpAdminTaskLVByUserRequested = 'All TpAdminTaskLV By User Requested',
    TpAdminTaskLVByUserPageRequested = 'All TpAdminTaskLV By User Page Requested',
    TpAdminTaskLVByUserPageLoaded = 'All TpAdminTaskLV By User Page Loaded',
    TpAdminTaskLVsClear = 'clear tasks',
}

export class TpAdminTaskLVCleared implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVCleared;
}

export class AllTpAdminTaskLVRequested implements Action {
    readonly type = TpAdminTaskLVActionTypes.AllTpAdminTaskLVRequested;
}

export class AllTpAdminTaskLVLoaded implements Action {
    readonly type = TpAdminTaskLVActionTypes.AllTpAdminTaskLVLoaded;

    constructor(public payload: { 
        tasks: TpAdminTaskLVModel[],
    }) {
    }
}

export class AllTpAdminTaskLVLoadedByUser implements Action {
    readonly type = TpAdminTaskLVActionTypes.AllTpAdminTaskLVLoadedByUser;

    constructor(public payload: { 
        tasks: TpAdminTaskLVModel[],
        totalCount: number,
        page: QueryParamsModel,
    }) {
    }
}


export class TpAdminTaskLVOnServerCreated implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVOnServerCreated;

    constructor(public payload: { tp_id: number, task: TpAdminTaskLVModel }) {
    }
}

export class TpAdminTaskLVCreated implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVCreated;

    constructor(public payload: { task: TpAdminTaskLVModel }) {
    }
}

export class TpAdminTaskLVUpdated implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVUpdated;

    constructor(public payload: {
        partialItem: Update<TpAdminTaskLVModel>,
        item: TpAdminTaskLVModel
    }) {
    }
}

export class TpAdminTaskLVDeleted implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class TpAdminTaskLVActionToggleLoading implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AllTpAdminTaskLVByUserRequested implements Action {
    readonly type = TpAdminTaskLVActionTypes.AllTpAdminTaskLVByUserRequested;

    constructor(public payload: { page: QueryParamsModel, user_id: number, filterModel: any }) {
    }
}

export class TpAdminTaskLVByUserPageRequested implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVByUserPageRequested;

    constructor(public payload: { page: QueryParamsModel, user_id: number }) {
    }
}

export class TpAdminTaskLVByUserPageLoaded implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVByUserPageLoaded;

    constructor(public payload: {
        assetClasses: TpAdminTaskLVModel[],
        totalCount: number,
        page: QueryParamsModel
    }) {
    }
}

export class TpAdminTaskLVsClear implements Action {
    readonly type = TpAdminTaskLVActionTypes.TpAdminTaskLVsClear;

    constructor() {
    }
}

export type TpAdminTaskLVActions = AllTpAdminTaskLVRequested |
    AllTpAdminTaskLVLoaded |
    TpAdminTaskLVActionToggleLoading |
    TpAdminTaskLVOnServerCreated |
    TpAdminTaskLVCreated |
    TpAdminTaskLVUpdated |
    TpAdminTaskLVCleared |
    TpAdminTaskLVDeleted |
    AllTpAdminTaskLVByUserRequested |
    TpAdminTaskLVByUserPageRequested |
    TpAdminTaskLVByUserPageLoaded | 
    AllTpAdminTaskLVLoadedByUser |
    TpAdminTaskLVsClear;
