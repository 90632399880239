import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource, QueryResultsModel } from "../../_base/crud";
import { selectHouseAdjustmentQueryResult, selectHouseAdjustmentsPageLoading, selectHouseAdjustmentsShowInitWaitingMessage } from "../_selectors/house-adjustment.selectors";

export class HouseAdjustmentsDataSource extends BaseDataSource {
    trashed = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectHouseAdjustmentsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectHouseAdjustmentsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectHouseAdjustmentQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        })
        
    }
}