import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { ImageViewerNgbModalComponent } from '../image-viewer-ngb-modal/image-viewer-ngb-modal.component';

@Component({
  selector: 'kt-comparable-documents-modal',
  templateUrl: './comparable-documents-modal.component.html',
  styleUrls: ['./comparable-documents-modal.component.scss']
})
export class ComparableDocumentsModalComponent implements OnInit {
  @Input()
  documents;

  dataSource = new MatTableDataSource();
  displayedColumns = ['type', 'title', 'descr', 'actions'];
  docs = ['xlsx', 'xls', 'doc', 'docx'];
  basePath = environment.baseApiUrl;

  constructor(
    private activeModal: NgbActiveModal,
    public typesUtilsService: TypesUtilsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dataSource.data = this.documents;
  }

  close() {
    this.activeModal.close();
  }

  previewUploadedFile(file) {
    if (this.docs.indexOf(this.typesUtilsService.getFileType(file.name)) > -1) {
      window.open(this.basePath + file.path + '/' + file.name);
    } else {
      const modalRef = this.modalService.open(ImageViewerNgbModalComponent, {
        windowClass: 'image_viewer_ngb_modal',
        backdrop: 'static',
        scrollable: true,
      });
      modalRef.componentInstance.title = file.title;
      modalRef.componentInstance.picture = file.path + '/' + file.name,
      modalRef.componentInstance.type = this.typesUtilsService.getFileType(file.name);
    }
  }

}
