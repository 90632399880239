import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    BuildingService
} from '../../../../../core/comparable';

@Injectable()
export class BuildingGetResolver implements Resolve<any> {
    constructor(public buildingService: BuildingService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const buildingId = Number(route.params.building_id);
        return this.buildingService.getBuildingById(buildingId);
    }
}
