import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-post-register',
  templateUrl: './post-register.component.html',
  styleUrls: ['./post-register.component.scss']
})
export class PostRegisterComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
