import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-edit-description-dialog',
  templateUrl: './edit-description-dialog.component.html',
})
export class EditReportDescriptionDialogComponent implements OnInit {

  fg: UntypedFormGroup;
  description: '';

  constructor(
    public dialogRef: MatDialogRef<EditReportDescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) {
    this.description = this.data.description;
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.fg = this.fb.group({
      desc: [this.description]
    });
  }

  onYesClick() {
    setTimeout(() => {
      this.dialogRef.close({
        description: this.fg.controls.desc.value,
      });
    }, 500);
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
