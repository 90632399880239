// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { PaymentModel } from '../_models/payment.model';
import { PaymentTaxModel } from '../_models/payment-tax.model';
import { ValuationModel } from '../_models/valuation.model';

export enum PaymentActionTypes {
    PaymentOnServerCreated = '[Edit Payment Dialog] Payment On Server Created',
    PaymentCreated = '[Edit Payment Dialog] Payment Created',

    PaymentOnServerUpdated = '[Edit Payment Dialog] Payment On Server Updated',
    PaymentUpdated = '[Edit Payment Dialog] Payment Updated',

    Payments = '[Payments List] get all ',
    PaymentsPageRequested = '[Payments List Page] Payments Page Requested',
    PaymentMapPageRequested = '[Payments Map Page] Payments Page Requested',
    PaymentsPageLoaded = '[Payments API] Payments Page Loaded',

    PaymentOnServerRestored = '[Payment Trash] Payment On Server Restored',
    PaymentRestored = '[Payment Trash] Payment Restored',
    PaymentDeletedFromTrash = '[Payment Trash] Payment deleted',
    PaymentTrash= 'Payment Trashed',
    OnePaymentDeleted = '[Payments List Page] One Payment Deleted',

    PaymentsPageCancelled = '[Payments API] Payments Page Cancelled',
    PaymentsPageToggleLoading = '[Payments] Payments Page Toggle Loading',
    PaymentActionToggleLoading = '[Payments] Payments Action Toggle Loading',
    PaymentGet = '[Payment Object] Payment Object Requested'
}

export class PaymentOnServerCreated implements Action {
    readonly type = PaymentActionTypes.PaymentOnServerCreated;
    constructor(public payload: {
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        valuations: ValuationModel[],
    }) { }
}

export class PaymentOnServerUpdated implements Action {
    readonly type = PaymentActionTypes.PaymentOnServerUpdated;
    constructor(public payload: {partialPayment: Update<PaymentModel>,
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        valuations: ValuationModel[],
    }) { }
}

export class PaymentGet implements Action {
    readonly type = PaymentActionTypes.Payments;
    constructor(public payload: { paymentId: number }) { }
}

export class PaymentCreated implements Action {
    readonly type = PaymentActionTypes.PaymentCreated;
    constructor(public payload: { payment: PaymentModel }) { }
}

export class PaymentUpdated implements Action {
    readonly type = PaymentActionTypes.PaymentUpdated;
    constructor(public payload: { payment: PaymentModel }) { }
}

export class PaymentsPageRequested implements Action {
    readonly type = PaymentActionTypes.PaymentsPageRequested;
    constructor(public payload: { page: QueryParamsModel, toeId: number}) { }
}

export class PaymentMapPageRequested implements Action {
    readonly type = PaymentActionTypes.PaymentMapPageRequested;
    constructor(public payload: { page: QueryParamsModel}) { }
}

export class PaymentsPageLoaded implements Action {
    readonly type = PaymentActionTypes.PaymentsPageLoaded;
    constructor(public payload: { payments: PaymentModel[],
        totalCount: number,
        trashed: number,
        page: QueryParamsModel
    }) { }
}

export class PaymentsPageCancelled implements Action {
    readonly type = PaymentActionTypes.PaymentsPageCancelled;
}

export class PaymentsPageToggleLoading implements Action {
    readonly type = PaymentActionTypes.PaymentsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PaymentActionToggleLoading implements Action {
    readonly type = PaymentActionTypes.PaymentActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PaymentOnServerRestored implements Action {
    readonly type = PaymentActionTypes.PaymentOnServerRestored;
    constructor(public payload: { paymentId: number }) { }
}

export class PaymentRestored implements Action {
    readonly type = PaymentActionTypes.PaymentRestored;
    constructor(public payload: { payment: PaymentModel }) { }
}

export class PaymentDeletedFromTrash implements Action {
    readonly type = PaymentActionTypes.PaymentDeletedFromTrash;
    constructor(public payload: { paymentId: number }) { }
}

export class PaymentsTrashRequested implements Action {
    readonly type = PaymentActionTypes.PaymentTrash;
    constructor(public payload: { isLoading: boolean }) { }
}

export class OnePaymentDeleted implements Action {
    readonly type = PaymentActionTypes.OnePaymentDeleted;
    constructor(public payload: { id: number }) {}
}

export type PaymentActions = PaymentOnServerCreated
| PaymentOnServerUpdated
| PaymentCreated
| PaymentUpdated
| PaymentsPageRequested
| PaymentMapPageRequested
| PaymentsPageLoaded
| PaymentsPageCancelled
| PaymentsPageToggleLoading
| PaymentActionToggleLoading
| PaymentOnServerRestored
| PaymentRestored
| PaymentDeletedFromTrash
| OnePaymentDeleted
| PaymentGet;
