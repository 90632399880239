import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { Video } from "../_models/video.model";
import { VideoUploadState } from "../_reducers/video-upload.reducers";

export const selectVideoUploadState = createFeatureSelector<VideoUploadState>('videoUpload');

export const selectVideoIsUploading = createSelector(
    selectVideoUploadState,
    state => state.isUploading
)

export const selectVideoIsProcessing = createSelector(
    selectVideoUploadState,
    state => state.isProcessing
)

export const selectVideos = createSelector(
    selectVideoUploadState,
    state => {
        const items: Video[] = [];
        each(state.entities, element => {
            items.push(element);
        })
        return items;
    }
)