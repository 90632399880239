import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

type CheckListItem = {
  id: number,
  title: string,
  checked: boolean
}

@Component({
  selector: 'kt-services-and-infrastructures',
  templateUrl: './services-and-infrastructures.component.html',
  styleUrls: ['./services-and-infrastructures.component.scss']
})
export class ServicesAndInfrastructuresComponent implements OnInit, OnChanges {
  @Input() readonly: boolean = false;
  @Input() propertySubTypeID: number|null;
  @Input() isComplexForm: boolean;
  @Input() serviceAndInfraData: {
    id: number,
    road: boolean,
    status_n_quality: string,
    access_points: string,
    length_of_road: string,
    ownership: string,

    central_heating: boolean,
    heatings: number[],
    other_heating: string,

    all_of_it: boolean,
    services: number[],
    other_service: string
  }

  formGroup: UntypedFormGroup;
  hasServices: boolean = true;

  heatings: CheckListItem[] = [
    {id: 1, title: 'Gas', checked: false},
    {id: 2, title: 'Electric', checked: false},
    {id: 3, title: 'Solid Fuel', checked: false},
    {id: 4, title: 'Oil', checked: false},
    {id: 5, title: 'Other', checked: false}
  ];
  showOtherHeating: boolean = false;

  services: CheckListItem[] = [
    {id: 1, title: "Waste water/sewerage disposal", checked: false},
    {id: 2, title: "Storm water drainage", checked: false},
    {id: 3, title: "Potable water supply", checked: false},
    {id: 4, title: "Electricity (main, solar, off-grid or independently generated)", checked: false},
    {id: 5, title: "Telephony/telecommunications", checked: false},
    {id: 6, title: "Internet access", checked: false},
    {id: 7, title: "Postal service", checked: false},
    {id: 8, title: "Garbage disposal", checked: false},
    {id: 9, title: "Transport (public or other facilities)", checked: false},
    {id: 10, title: "Other services", checked: false},
  ]
  showOtherService: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.serviceAndInfraData && !changes.serviceAndInfraData.firstChange) {
      this._populate();
    }
  }

  private _populate() {
    if (this.formGroup) {
      this.formGroup.patchValue({
        road: this.serviceAndInfraData.road,
        status_n_quality: this.serviceAndInfraData.status_n_quality,
        access_points: this.serviceAndInfraData.access_points,
        length_of_road: this.serviceAndInfraData.length_of_road,
        ownership: this.serviceAndInfraData.ownership,
        central_heating: this.serviceAndInfraData.central_heating,
        other_heating: this.serviceAndInfraData.other_heating,
        all_of_it: this.serviceAndInfraData.all_of_it,
        other_service: this.serviceAndInfraData.other_service
      });
      this.formGroup.updateValueAndValidity();
    } else {
      this.formGroup = this.formBuilder.group({
        road: [this.serviceAndInfraData.road],
        status_n_quality: [this.serviceAndInfraData.status_n_quality],
        access_points: [this.serviceAndInfraData.access_points],
        length_of_road: [this.serviceAndInfraData.length_of_road],
        ownership: [this.serviceAndInfraData.ownership],
        central_heating: [this.serviceAndInfraData.central_heating],
        other_heating: [this.serviceAndInfraData.other_heating],
        all_of_it: [this.serviceAndInfraData.all_of_it],
        other_service: [this.serviceAndInfraData.other_service]
      })
    }
    this.serviceAndInfraData.heatings.forEach(h => {
      if (h == 5) {
        this.showOtherHeating = true;
        this._updateControl("other_heating", true)
      }
      this.heatings = this._updateChecklists(this.heatings, h, true);
    });
    this.serviceAndInfraData.services.forEach(s => {
      if (s == 10) {
        this.showOtherService = true;
        this._updateControl("other_service", true);
      }
      this.services = this._updateChecklists(this.services, s, true);
    });
  }

  ngOnInit(): void {
    if (this.propertySubTypeID && this.propertySubTypeID == 2) {
      this.hasServices = false;
    }

    this._populate();

    this.formGroup.controls.all_of_it.valueChanges.subscribe(val => {
      const boolVal = Boolean(val);
      this.services = this.services.map(s => ({...s, checked: s.id == 10 ? s.checked : boolVal}));
      if (!this.isComplexForm) {
        this.formGroup.controls.road.setValue(true);
        this.formGroup.controls.central_heating.setValue(true);
        this.formGroup.updateValueAndValidity();
      }
    })
  }

  onHeatingChange(event: MatCheckboxChange, heating: CheckListItem) {
    this.heatings = this._updateChecklists(this.heatings, heating.id, event.checked);
    if (heating.id == 5) {
      this.showOtherHeating = event.checked;
      this._updateControl("other_heating", event.checked)
    }
  }

  onServiceChange(event: MatCheckboxChange, service: CheckListItem) {
    this.services = this._updateChecklists(this.services, service.id, event.checked);
    if (service.id == 10) {
      this.showOtherService = event.checked;
      this._updateControl("other_service", event.checked)
    }
  }

  getdata() {
    const controls = this.formGroup.controls;
    return {
      id: this.serviceAndInfraData.id,
      road: controls.road.value,
      status_n_quality: controls.status_n_quality.value,
      access_points: controls.access_points.value,
      length_of_road: controls.length_of_road.value,
      ownership: controls.ownership.value,

      central_heating: controls.central_heating.value,
      heatings: this.heatings.filter(item => item.checked).map(item => item.id),
      other_heating: controls.other_heating.value,

      all_of_it: controls.all_of_it.value,
      services: this.services.filter(item => item.checked).map(item => item.id),
      other_service: controls.other_service.value
    }
  }

  _updateChecklists(list: CheckListItem[], id: number, isChecked: boolean): CheckListItem[] {
    return list.map(item => {
      if (item.id == id) {
        return {...item, checked: isChecked}
      }
      return {...item}
    })
  }

  _updateControl(cName: string, isRequired: boolean) {
    if (isRequired) {
      this.formGroup.controls[cName].setValidators([Validators.required]); 
    } else {
      this.formGroup.controls[cName].clearValidators();
    }
    this.formGroup.controls[cName].updateValueAndValidity();
  }

}
