// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {selectQueryResult, selectKeyPlaceActionLoading, selectKeyPlacesShowInitWaitingMessage} from '../_selectors/key-place.selectors';

export class KeyPlaceDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectKeyPlaceActionLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectKeyPlacesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
