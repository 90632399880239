import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { SubdomainService } from "../../_base/subdomain.service";

export interface ICurrencyExchange {
    id?: number;
    date?: string;
    base_currency?: string;
    quote_currency?: string;
    exchange_rate: number;
}

@Injectable()
export class CurrencyExchangeService {
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {}

    create(currencyExchage: ICurrencyExchange): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/currencies/exchange`, currencyExchage, {
            headers: httpHeaders
        });
    }

    bulkCreateOrUpdate(currencyExchanges: ICurrencyExchange[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/currencies/exchange/bulk`, {data: currencyExchanges}, {
            headers: httpHeaders
        });
    }

    getCurrencyExchangeRate(date: Date, currencies: string[]): Observable<any> {
        const dateStr = formatDate(date);
        return this.http.get(`https://openexchangerates.org/api/historical/${dateStr}.json?app_id=9ccf789ee53e48ec8aa34701aafc5495&symbols=${currencies.join(',')}`);
    } 
}

function formatDate(date: Date): string {
    let month = date.getMonth().toString();
    let day = date.getDate().toString();
    const year = date.getFullYear().toString();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}