import { Component, Input, OnInit } from '@angular/core';
import { CFMFirstTerm } from '../../../../cf-method-types';

@Component({
  selector: 'kt-first-term-table',
  templateUrl: './first-term-table.component.html',
  styleUrls: ['./first-term-table.component.scss', '../../../headline-to-effective.component.scss']
})
export class FirstTermTableComponent implements OnInit {
  @Input() data: CFMFirstTerm;
  @Input() rentReview: number;
  @Input() fittingOut: number;
  @Input() targetRate: number;

  constructor() { }

  ngOnInit(): void {
  }

}
