import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ChimneyStackModel } from "../_models/chimney-stack.model";

export enum ChimneyStackActionTypes {
    AllChimneyStacksRequested = '[Linked Area] All ChimneyStack Requested',
    AllChimneyStacksLoaded = '[ChimneyStack Effect] All ChimneyStack Loaded',

    ChimneyStackOnServerCreated = '[Add ChimneyStack Page] ChimneyStack On Server Created',
    ChimneyStackCreated = '[ChimneyStack Effect] ChimneyStack Created',
    ChimneyStackUpdated = '[ChimneyStack Effect] ChimneyStack Updated',
    ChimneyStackDeleted = '[ChimneyStack List Page] ChimneyStack Deleted',

    ChimneyStackOnServerRestored = '[ChimneyStack Trash] ChimneyStack On Server Restored',
    ChimneyStackRestored = '[ChimneyStack Trash] ChimneyStack Restored',

    ChimneyStackOnServerAdminRestored = '[ChimneyStack Admin Trash] ChimneyStack On Server Restored',
    ChimneyStackAdminRestored = '[ChimneyStack Admin Trash] ChimneyStack Restored',

    ChimneyStackDeletedFromTrash = '[ChimneyStack Trash] ChimneyStack deleted',
    ChimneyStackDeletedFromAdminTrash = '[ChimneyStack Admin Trash] ChimneyStack deleted',

    ChimneyStackTrash = 'ChimneyStack Trashed',
    ChimneyStackTrashFlushed = 'ChimneyStack Trash Flushed',

    ChimneyStacksPageRequested = '[ChimneyStack List Page] ChimneyStack Page Requested',
    ChimneyStacksPageLoaded = '[ChimneyStack API] ChimneyStack Page Loaded',
    ChimneyStacksPageCancelled = '[ChimneyStack API] ChimneyStack Page Cancelled',

    ChimneyStacksPageToggleLoading = '[ChimneyStack page] ChimneyStack Page Toggle Loading',
    ChimneyStackActionToggleLoading = '[ChimneyStack] ChimneyStack Action Toggle Loading'
}

export class ChimneyStackOnServerCreated implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackOnServerCreated;

    constructor(public payload: { item: ChimneyStackModel }) {
    }
}

export class ChimneyStackCreated implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackCreated;

    constructor(public payload: { item: ChimneyStackModel }) {
    }
}

export class ChimneyStackUpdated implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackUpdated;

    constructor(public payload: {
        partialItem: Update<ChimneyStackModel>,
        item: ChimneyStackModel
    }) {
    }
}

export class ChimneyStackDeleted implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ChimneyStacksPageRequested implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStacksPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ChimneyStacksPageLoaded implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStacksPageLoaded;

    constructor(public payload: { items: ChimneyStackModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ChimneyStacksPageCancelled implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStacksPageCancelled;
}

export class AllChimneyStacksRequested implements Action {
    readonly type = ChimneyStackActionTypes.AllChimneyStacksRequested;
}

export class AllChimneyStacksLoaded implements Action {
    readonly type = ChimneyStackActionTypes.AllChimneyStacksLoaded;

    constructor(public payload: { items: ChimneyStackModel[] }) {
    }
}

export class ChimneyStacksPageToggleLoading implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStacksPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ChimneyStackActionToggleLoading implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ChimneyStackDeletedFromAdminTrash implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ChimneyStackDeletedFromTrash implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ChimneyStackOnServerRestored implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ChimneyStackRestored implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackRestored;

    constructor(public payload: { item: ChimneyStackModel }) {
    }
}

export class ChimneyStackOnServerAdminRestored implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ChimneyStackAdminRestored implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackAdminRestored;

    constructor(public payload: { item: ChimneyStackModel }) {
    }
}

export class ChimneyStackTrashFlushed implements Action {
    readonly type = ChimneyStackActionTypes.ChimneyStackTrashFlushed;

    constructor() {
    }
}


export type ChimneyStackActions = ChimneyStackCreated
    | ChimneyStackUpdated
    | ChimneyStackDeleted
    | ChimneyStacksPageRequested
    | ChimneyStacksPageLoaded
    | ChimneyStacksPageCancelled
    | AllChimneyStacksLoaded
    | AllChimneyStacksRequested
    | ChimneyStackOnServerCreated
    | ChimneyStackDeletedFromAdminTrash
    | ChimneyStackDeletedFromTrash
    | ChimneyStackOnServerRestored
    | ChimneyStackRestored
    | ChimneyStackOnServerAdminRestored
    | ChimneyStackAdminRestored
    | ChimneyStackTrashFlushed
    | ChimneyStacksPageToggleLoading
    | ChimneyStackActionToggleLoading;