import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpUtilsService} from '../../_base/crud';
import {environment} from '../../../../environments/environment';

import {ToeReport} from '../_models/toe-report.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class ToeReportService {
    API_TOE_GENERATE_REPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/report/`;
    API_TOE_GENERATE_REPORTS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/generated-reports/`;
    API_TOE_SIGNED_REPORTS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/signed-reports/`;
    API_TOE_GENERATE_REPORTS_DELETE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/generated-reports/delete/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAllToeReports(toeId: number): Observable<any> {
        const url = this.API_TOE_GENERATE_REPORTS_URL + `${toeId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getAllToeSignedReports(toeId: number): Observable<any> {
        const url = this.API_TOE_SIGNED_REPORTS_URL + `${toeId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }


    // CREATE =>  POST: add a new toeReport to the server
    createToeSignedReport(item: any): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_TOE_SIGNED_REPORTS_URL + 'add', item, {headers: httpHeaders});
    }

    createToeSignedReportV2(item: any): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_TOE_SIGNED_REPORTS_URL + 'v2/add', item, {headers: httpHeaders});
    }

    updateToeReportHasAltered(item: any): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_TOE_SIGNED_REPORTS_URL + `${item.id}`, item, {
            headers: httpHeaders
        });
    }

    // UPDATE
    updateToeSignedReport(toe_id: number, id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_SIGNED_REPORTS_URL + toe_id + '/' + id, {headers: httpHeaders});
    }

    // DELETE
    deleteToeSignedReport(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_TOE_SIGNED_REPORTS_URL + id, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new toeReport to the server
    createToeReport(toeReport: ToeReport): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_TOE_GENERATE_REPORTS_URL + 'add', toeReport, {headers: httpHeaders});
        // return this.http.get<any>(this.API_TOE_GENERATE_REPORT_URL + `${toeId}/${userId}/${toeStatus}`);
    }

    // UPDATE => PUT: update the toeReport on the server
    updateToeReport(toeReport: ToeReport): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_TOE_GENERATE_REPORTS_URL + `update/${toeReport.id}`, toeReport, {headers: httpHeaders});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    // DELETE => delete the toeReport from the server
    deleteToeReport(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(this.API_TOE_GENERATE_REPORTS_DELETE_URL + `${id}`, {headers: httpHeaders});
    }

    generateToeReport(toeId: number, userId: number, toeStatus: number, reportId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_GENERATE_REPORT_URL + `${toeId}/${userId}/${toeStatus}/${reportId}`, {headers: httpHeaders});
    }

}
