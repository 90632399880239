<div class="card card-custom">
    <div class="card-header">
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <form [formGroup]="form">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-placeholder>Payment date</mat-placeholder>
                            <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
                            <mat-datetimepicker #datetimePicker type="date" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                            <input matInput formControlName="payment_date" [matDatetimepicker]="datetimePicker" autocomplete="false">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Invoice" formControlName="invoice_id">
                                <mat-option *ngFor="let invoice of invoices" [value]="invoice.id">{{getTaskName(invoice, invoices)}}</mat-option>
                            </mat-select>
                            <mat-error>Invoice
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Payment type" formControlName="payment_type_id">
                                <mat-option *ngFor="let paymentType of paymentTypes" [value]="paymentType.id">{{paymentType.name}}</mat-option>
                            </mat-select>
                            <mat-error>Payment type
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Amount (incl. Taxes)" formControlName="amount" [allowNegativeNumbers]="true" mask="separator.2" thousandSeparator="," [suffix]="'(' + currency + ')'"/>
                            <mat-error>
                                Amount
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput rows="4" [placeholder]="'Comments'" formControlName="comment"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">
                            Close
                        </button>
                        <button mat-button (click)="save()" color="primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>