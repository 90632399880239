import { CostRevenue } from "../../types";

export function convertedResultValue(
  result: {value: number},
  item: CostRevenue,
  reportingCurrency: string
) {
  if (result == undefined) return undefined;
  if (item.currency === reportingCurrency) return result.value
  if (item.calculationMeta.exchangeRate === null || item.calculationMeta.exchangeRate === 0) return undefined
  return result.value / item.calculationMeta.exchangeRate
}