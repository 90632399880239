// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter, Update} from '@ngrx/entity';
// Actions
import {AssignmentActions, AssignmentActionTypes} from '../_actions/assignment.actions';
// Models
import {AssignmentModel} from '../_models/assignment.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AssignmentsState extends EntityState<AssignmentModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastCreatedAssignmentId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssignmentModel> = createEntityAdapter<AssignmentModel>();

export const initialAssignmentsState: AssignmentsState = adapter.getInitialState({
    assignmentForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastCreatedAssignmentId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assignmentsReducer(state = initialAssignmentsState, action: AssignmentActions): AssignmentsState {
    switch (action.type) {
        case AssignmentActionTypes.AssignmentsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssignmentId: undefined
            };
        }
        case AssignmentActionTypes.AssignmentActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AssignmentActionTypes.AssignmentOnServerCreated:
            return {
                ...state
            };
        case AssignmentActionTypes.AssignmentCreated:
            return adapter.addOne(action.payload.assignment, {
                ...state, lastCreatedAssignmentId: action.payload.assignment.id
            });

        case AssignmentActionTypes.AssignmentRestored:
            return adapter.addOne(action.payload.assignment, {
                ...state,
                lastCreatedAssignmentId: action.payload.assignment.id,
                totalTrashed: state.totalTrashed - 1
            });

        case AssignmentActionTypes.AssignmentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state,
                lastCreatedAssignmentId: action.payload.item.id,
                totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AssignmentActionTypes.AssignmentDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            };

        case AssignmentActionTypes.AssignmentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case AssignmentActionTypes.AssignmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case AssignmentActionTypes.AssignmentUpdated:
            return adapter.updateOne(action.payload.partialAssignment, state);

        case AssignmentActionTypes.AssignmentsStatusUpdated: {
            const _partialAssignments: Update<AssignmentModel>[] = [];

            for (let i = 0; i < action.payload.assignments.length; i++) {
                _partialAssignments.push({
                    id: action.payload.assignments[i].id,
                    changes: {
                        status: action.payload.status
                    }
                });
            }
            return adapter.updateMany(_partialAssignments, state);
        }

        case AssignmentActionTypes.OneAssignmentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AssignmentActionTypes.ManyAssignmentsDeleted:
            return adapter.removeMany(action.payload.ids, state);

        case AssignmentActionTypes.AssignmentsPageCancelled: {
            return {
                ...state,
                listLoading: false,
                lastQuery: new QueryParamsModel({})
            };
        }
        case AssignmentActionTypes.AssignmentsPageLoaded: {
            return adapter.addMany(action.payload.assignments, {
                ...initialAssignmentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        case AssignmentActionTypes.OneAssignmentLoaded: {
            return adapter.addOne(action.payload.assignment, state);
        }

        case AssignmentActionTypes.AssignmentOnServerDuplicated: {
            return {
                ...state
            };
        }

        case AssignmentActionTypes.AssignmentDuplicated: {
            return adapter.addOne(action.payload.assignment, {
                ...state, lastCreatedAssignmentId: action.payload.assignment.id
            });
        }
        case AssignmentActionTypes.AssignmentStatusUpdated: 
            return adapter.updateOne(action.payload.updateAssignment, state);
        case AssignmentActionTypes.AssignmentEmptyAction:
            return state;
        case AssignmentActionTypes.AllAssignmentsLoaded: {
            return adapter.setAll(action.paylaod.assignments, state)
        }

        default:
            return state;
    }
}

export const getAssignmentState = createFeatureSelector<AssignmentModel>('assignments');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
