<div class="card card-custom">
    <div class="card-header">
        <div class="card-title"></div>
        <div class="card-toolbar">
            <ng-container *ngIf="refNums$|async as refNums">
                <div *ngIf="refNums.length">
                    <button
                        (click)="showComparableOverview(refNums)"
                        mat-raised-button
                        [matTooltip]="'Show info of selected comparable'"
                        class="float-right m-0 btn">
                        <span>Overview</span>
                    </button>
                </div>
                <div>
                    <button (click)="addComparable()" mat-raised-button
                            [matTooltip]="'Add Comparable'"
                            class="float-right m-0 ml-4 btn"
                            type="button">
                        <span>Add Comparable</span>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="card-body">
        <div class="mat-table-wrapper">
            <form [formGroup]="form">

            <ng-container *ngIf="dataSource$|async as dataSource">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="column-id">#</th>
                            <th class="column-residential_type_name">Sub-type</th>
                            <th class="column-size">Size</th>
                            <th class="column-consideration">Consideration</th>
                            <th *ngIf="dataSource.hasBuilding"  class="column-building">Building ID</th>
                            <th class="column-date_of_transaction">Date of information/transaction</th>
                            <th class="column-exchange_rate">Exchange Rate</th>
                            <th class="column-converted_consideration">Consideration (in adopted currencty - {{targetProperty.countryCurrency}})</th>
                            <th class="column-actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comparable of dataSource.comparables; let i = index"
                            [ngClass]="{'colored-row': i%2 == 1, 'white-colored-row': i%2==0}">
                            <td>{{comparable.refNum}}</td>
                            <td>
                                {{ comparable.subTypeCategory }}
                            </td>
                            <td>{{ comparable.size }}</td>
                            <td>
                                <div style="display: flex; flex-direction: column;" *ngIf="comparable.displayConsiderations.length > 0 else no_data">
                                    <div *ngFor="let t of comparable.displayConsiderations">
                                        {{ t }}
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="dataSource.hasBuilding">{{ comparable.buildingID }}</td>
                            <td>
                                <div style="display: flex; flex-direction: column;" *ngIf="comparable.sourceDates.length > 0 else no_data">
                                    <div *ngFor="let t of comparable.sourceDates">
                                        {{ t }}
                                    </div>
                                </div>
                            </td>
                            <td class="cell-conversion">
                                <ng-container *ngIf="comparable.consideration.currency != targetProperty.countryCurrency; else no_conversion">
                                    <ng-template #popContentSingle>
                                        <div>
                                            At Date of {{ comparable.sourceType == 'Advertised' 
                                                ? ('information (' + comparable.sourceDates[0] + ')')
                                                : ('transaction (' + comparable.sourceDates[0] + ')') }}
                                        </div>
                                        <div>
                                            1 {{ comparable.consideration.currency }} = {{dataSource.convertedValues[comparable.refNum].rate 
                                                ? dataSource.convertedValues[comparable.refNum].rate 
                                                : '?' }} {{targetProperty.countryCurrency}} 
                                        </div>
                                    </ng-template>
                                    <mat-form-field>
                                        <input
                                            matInput 
                                            type="text" 
                                            [formControlName]="comparable.refNum + '_' + comparable.consideration.id"
                                             />
                                        <span matSuffix>
                                            ({{comparable.consideration.currency}}/{{ targetProperty.countryCurrency }})
                                            <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                                [ngbPopover]="popContentSingle"
                                                (click)="$event.stopPropagation()">
                                                <mat-icon>info</mat-icon>
                                            </button>
                                        </span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                            <td>
                                <div>
                                    {{ dataSource.convertedValues[comparable.refNum].converted 
                                        ? ((dataSource.convertedValues[comparable.refNum].converted.toFixed(2) | mask:'separator':{thousandSeparator: ','}) + ' ' + (comparable.tenureInfo))
                                        : 'N/A' }} 
                                </div>
                            </td>
                            <td>
                                <button mat-icon-button
                                        color="primary"
                                        [matTooltip]="'View Comparable Info'"
                                        (click)="showAcInfo(comparable.refNum)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
            
                                <button mat-icon-button
                                        [disabled]="readonly"
                                        color="warn"
                                        [matTooltip]="'Remove Comparable' | translate"
                                        type="button"
                                        (click)="deleteAc(comparable.refNum)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            </form>
        </div>
    </div>
</div>

<ng-template #no_data>
    {{ 'No Data' }}
</ng-template>

<ng-template #no_conversion>
    N/A
</ng-template>