import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllAssetClassTypesRequested, AssetClassType, selectAllAssetClassTypes } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { AddCriterionsTemplate, CriterionsTemplateModel, criterionsTemplateReducer, ICriterionTemplate, UpdateCriterionsTemplate } from 'src/app/core/template';
import { CriterionType } from 'src/app/core/valuation';
import { CriterionCategories, getCriterionCategories } from 'src/app/core/valuation/_models/criterion-categories.model';
import { CustomCriterionModalComponent } from '../custom-criterion-modal/custom-criterion-modal.component';
import { DefaultCriterionsModalComponent } from '../default-criterions-modal/default-criterions-modal.component';

type DataSourceItem = {
  id: number;
  name: string;
  category: string;
  type: CriterionType;
  categoryId: number;
}

@Component({
  selector: 'kt-criterions-template-body',
  templateUrl: './criterions-template-body.component.html',
  styleUrls: ['./criterions-template-body.component.scss']
})
export class CriterionsTemplateBodyComponent implements OnInit, OnDestroy {
  @Input() template: CriterionsTemplateModel;
  @Input() readonly: boolean;

  private _template: CriterionsTemplateModel;
  formGroup: UntypedFormGroup;
  private _onDestroy$: Subject<void> = new Subject();

  public assetClassTypes: AssetClassType[] = [];
  public selectedAssetClassTypeId: number = undefined;

  private _defaultCriterionArr: ICriterionTemplate[] = [];
  private _defaultCriterionSubject: BehaviorSubject<ICriterionTemplate[]> = new BehaviorSubject([]);
  private _customCriterionArr: ICriterionTemplate[] = [];
  private _customCriterionSubject: BehaviorSubject<ICriterionTemplate[]> = new BehaviorSubject([]);


  public defaultCriterionsDataSource: MatTableDataSource<DataSourceItem> = new MatTableDataSource();
  public customCriterionsDataSource: MatTableDataSource<DataSourceItem> = new MatTableDataSource();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._template = Object.assign({}, this.template);
    this.selectedAssetClassTypeId = this._template.asset_class_type_id;
    this._createForm();
    this._fetchData();
    this._defaultCriterionArr = this._template.default_criterions;
    this._customCriterionArr = this._template.custom_criterions;
    this._defaultCriterionSubject.next(this._defaultCriterionArr);
    this._customCriterionSubject.next(this._customCriterionArr);
    combineLatest([
      this._defaultCriterionSubject,
      this._customCriterionSubject
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([def, custom]) => {
      if (def.length > 0) {
        this.formGroup.get('asset_class_type').disable();
        this.formGroup.get('asset_class_type').updateValueAndValidity();
      } else {
        this.formGroup.get('asset_class_type').enable();
        this.formGroup.get('asset_class_type').updateValueAndValidity();
      }
      this.defaultCriterionsDataSource.data = def.map(c => ({
        id: c.id,
        name: c.name, 
        category: getCriterionCategories(c.category), 
        type: c.type,
        categoryId: c.category
      }))
      this.customCriterionsDataSource.data = custom.map(c => ({
        id: c.id,
        name: c.name, 
        category: c.category == CriterionCategories.Other ? c.categoryName : getCriterionCategories(c.category),
        type: c.type,
        categoryId: c.category
      }));
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _createForm() {
    this.formGroup = this.fb.group({
      name: [this._template.name, Validators.required],
      description: [this._template.description, Validators.required],
      asset_class_type: [this._template.asset_class_type_id, Validators.required]
    });
    const acTypeCtrl = this.formGroup.get('asset_class_type');
    acTypeCtrl.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      this.selectedAssetClassTypeId = val;
    })
  }
  private _fetchData() {
    this.store.dispatch(new AllAssetClassTypesRequested());
    this.store.select(selectAllAssetClassTypes).pipe(
      takeUntil(this._onDestroy$),
    ).subscribe(res => {
      this.assetClassTypes = [];
      if (res) {
        this.assetClassTypes = res
          .filter(item => item.id == 1 || item.id == 3 || item.id == 7 || item.id == 17 || item.id == 5 || item.id == 2 || item.id == 11 || item.id == 13)
          .map(item => {
            if (item.id != 13) {
              return item
            }
            return copyWith(item, {name: 'Land'})
          });
      }
    })
  }
  private _prepareTemplate(): CriterionsTemplateModel {
    const template = new CriterionsTemplateModel();
    template.clear();
    const controls = this.formGroup.controls;
    template.id = this._template.id;
    template.name = controls.name.value;
    template.description = controls.description.value;
    template.asset_class_type_id = controls.asset_class_type.value;
    template.default_criterions = this._defaultCriterionArr;
    template.custom_criterions = this._customCriterionArr;
    return template;
  }

  public addDefaultCriterion() {
    const dialogRef = this.dialog.open(DefaultCriterionsModalComponent, {
      data: {
        acTypeId: this.selectedAssetClassTypeId,
        selectedCriterions: this._defaultCriterionArr 
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(
      res => {
        if (res.length == 0) {
          return;
        }
        this._defaultCriterionArr = res;
        this._defaultCriterionArr.sort((a, b) => a.crit_id - b.crit_id);
        this._defaultCriterionSubject.next(this._defaultCriterionArr);
      }
    )
  }
  public addCustomCriterion() {
    const dialogRef = this.dialog.open(CustomCriterionModalComponent, {
      data: {
        criterion: {
          id: undefined,
          crit_id: null,
          name: null,
          category: null,
          type: CriterionType.Custom
        }
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(
      res => {
        if (!res) {
          return;
        }
        this._customCriterionArr.push(res);
        this._customCriterionArr.sort((a, b) => a.category - b.category);
        this._customCriterionSubject.next(this._customCriterionArr);
      }
    )
  }
  public editCriterion(criterion: DataSourceItem, index: number) {
    const crit: ICriterionTemplate = {
      id: criterion.id,
      crit_id: null,
      name: criterion.name,
      category: criterion.categoryId,
      type: criterion.type ,
      categoryName: criterion.category
    };
    const dialogRef = this.dialog.open(CustomCriterionModalComponent, {
      data: {
        criterion: crit
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(
      res => {
        if (!res) {
          return;
        }
        this._customCriterionArr.splice(index, 1, res);
        this._customCriterionArr.sort((a, b) => a.category - b.category);
        this._customCriterionSubject.next(this._customCriterionArr);
      }
    )
  }
  public deleteCriterion(type: CriterionType, index: number) {
    switch (type) {
      case CriterionType.Other: {
        this._defaultCriterionArr.splice(index, 1);
        this._defaultCriterionSubject.next(this._defaultCriterionArr);
        break;
      }
      case CriterionType.Custom: {
        this._customCriterionArr.splice(index, 1);
        this._customCriterionSubject.next(this._customCriterionArr);
        break;
      }
    }
  }

  public onSubmit(complete: boolean): boolean {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      });
      return false;
    }
    const template = this._prepareTemplate();
    if (complete) {
      if (template.id === undefined) {
        this.store.dispatch(new AddCriterionsTemplate({
          template: template
        }));
      } else {
        this.store.dispatch(new UpdateCriterionsTemplate({
          id: template.id,
          template
        }));
      }
      return true;
    }
    return false
  }
}

function copyWith(ac: AssetClassType, data: {name?: string}): AssetClassType {
  const item = new AssetClassType();
  item.id = ac.id
  item.name = data.name ? data.name : ac.name;
  item.hide_flag = ac.hide_flag
  item.relation_cnt = ac.relation_cnt
  item.facilities = ac.facilities
  item.top_property_type_id = ac.top_property_type_id
  item.top_property_type_name = ac.top_property_type_name

  item.created_at = ac.created_at
  item.updated_at = ac.updated_at
  item.deleted_at = ac.deleted_at

  item.deletedBy = ac.deletedBy
  item.userDeletedBy = ac.userDeletedBy
  item.user_deleted = ac.user_deleted
  return item
}