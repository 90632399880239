import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AppState } from "../reducers";
import { isLoggedIn } from "./mad-auth.store";

@Injectable()
export class MadAuthGuard implements CanActivate {
  constructor(
    private _store$: Store<AppState>,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._store$.pipe(
      select(isLoggedIn),
      tap(loggedIn => {
        if (!loggedIn) {
          this._router.navigateByUrl('/mad-auth/login')
        }
      })
    )
  }

}