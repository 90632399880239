<div class="card card-custom" 
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="country">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
            <mat-icon
                    class="pl-2 pt-1 icon-gray cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    placement="bottom"
                    [popoverTitle]="'TOOLTIP.COUNTRY.FORM'+(country && country.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.COUNTRY.FORM'+(country && country.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
                    popoverClass="testUI">help
            </mat-icon>
        </div>
    </div>
    <div *ngIf="country">
        <form class="form" [formGroup]="countryForm">
            <div class="card-body countryEdit">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="form-group row">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'COUNTRY.FORM.FIELD.GENERAL.COUNTRY_NAME.PLACEHOLDER' | translate"
                                        formControlName="name"
                                        [(ngModel)]="country.name"
                                        (selectionChange)="changeName($event)">
                                <mat-option>
                                    <ngx-mat-select-search
                                        [formControl]="countrySearchCtrl"
                                        [placeholderLabel]="'GENERAL.PLACEHOLDER.SEARCH' | translate"
                                        [noEntriesFoundLabel]="'Not Found'"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of countries" value="{{ country.countryName }}"
                                            [disabled]="existCountries.indexOf(country.countryName) > -1">{{country.countryName}}</mat-option>
                            </mat-select>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('COUNTRY.FORM.FIELD.GENERAL.COUNTRY_NAME.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput 
                                mask="percent"
                                suffix="%"
                                [placeholder]="'COUNTRY.FORM.FIELD.GENERAL.COUNTRY_VAT.PLACEHOLDER' | translate" 
                                formControlName="vat"
                                [(ngModel)]="country.vat"
                            />
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('COUNTRY.FORM.FIELD.GENERAL.COUNTRY_VAT.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   type="text"
                                   [placeholder]="'COUNTRY.FORM.FIELD.GENERAL.CURRENCY.PLACEHOLDER' | translate"
                                   formControlName="currency"
                                   [(ngModel)]="country.currency"
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Default Timezone" formControlName="default_timezone" [(ngModel)]="country.default_timezone">
                                <mat-option>
                                    <ngx-mat-select-search 
                                        [formControl]="timezoneCtlr"
                                        [placeholderLabel]="'GENERAL.PLACEHOLDER.SEARCH' | translate"
                                        [noEntriesFoundLabel]="'Not Found'"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let item of (timezoneService.timeZones$ | async)" [value]="item.name">
                                    {{item.name}} (GMT {{item.gmtValue}})
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div class="form-group row mt-2">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <div class="row">
                            <mat-label class="col-lg-11" style="color: #181C32; vertical-align: middle;">{{'COUNTRY.FORM.FIELD.GENERAL.VPGA10_MATTERS.PLACEHOLDER' | translate}}</mat-label>
                            <button mat-icon-button class="col-lg-1" color="primary"
                                (click)="addVPGAMatterField()">
                                <mat-icon [matTooltip]="'Add New Field'">add_circle</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-12  kt-margin-bottom-20-mobile" *ngFor="let control of macroContextFormArray.controls; let i = index">
                        <div class="row" style="align-items: center;" >
                            <mat-form-field class="mat-form-field-fluid col-lg-11">
                                <textarea matInput [placeholder]="'VPGA Matter (' + (i + 1) + ')'"
                                    [formControl]="control"></textarea>
                            </mat-form-field>
                            <button mat-icon-button class="col-lg-1" color="warn"
                                (click)="removeVPGAMatterField(i)">
                                <mat-icon [matTooltip]="'Remove Field'">delete</mat-icon>
                            </button>
                        </div>
                    </div>


                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput [placeholder]="'COUNTRY.FORM.FIELD.GENERAL.PUBLIC_DOMAIN_STATUS.PLACEHOLDER' | translate"
                                      formControlName="status_public_domain"
                                      [(ngModel)]="country.status_public_domain"
                                      rows="3"
                            ></textarea>
                            <mat-icon
                                    matSuffix
                                    container="form"
                                    class="cursor-pointer icon-gray"
                                    (click)="$event.stopPropagation();"
                                    [popoverTitle]="'TOOLTIP.COUNTRY.PUBLIC_DOMAIN.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.COUNTRY.PUBLIC_DOMAIN.DESCRIPTION' | translate">help
                            </mat-icon>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('COUNTRY.FORM.FIELD.GENERAL.PUBLIC_DOMAIN_STATUS.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 border-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="false" cdkFocusInitial 
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.CANCEL.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.CANCEL.LABEL' | translate }}
                            </button>&nbsp;
                            <button type="button" [disabled]="!isFormValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.SAVE.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.SAVE.LABEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

