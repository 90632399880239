import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, Subscription } from 'rxjs';
import { AssetClassConsiderationModel } from 'src/app/core/asset_class';
import { AllLandTenuresRequested, LandTenure, selectAllLandTenures } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { CountryData } from '../../admin-management/countries/countries-list/local-data';
import { TenureConsiderationEditDialogComponent } from '../land-parcel/tenure-consideration/tenure-consideration-edit/tenure-consideration-edit.dialog.component';

@Component({
  selector: 'kt-parcel-considerations',
  templateUrl: './parcel-considerations.component.html',
  styleUrls: ['./parcel-considerations.component.scss']
})
export class ParcelConsiderationsComponent implements OnInit, OnDestroy {
  @Input() initData: AssetClassConsiderationModel[];
  @Input() disable: boolean = false;

  datasource = new MatTableDataSource();
  displayedColumns = ['land_tenure_name', 'tenure_length', 'currency', 'consideration'];
  tenureConsiderationList: AssetClassConsiderationModel[] = [];
  landTenures: LandTenure[]  = [];
  subscriptions: Subscription[] = [];
  public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  countriesData = CountryData.countriesWithNoDuplicateCurrency();
  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
  ) { }

  ngOnInit(): void {
      if (!this.disable) {
        this.displayedColumns.push('actions')
      }
      this.datasource.data = this._deepCopy(this.initData);
        this.store.dispatch(new AllLandTenuresRequested());
        
        const sourceSubscription = this.store.pipe(
            select(selectAllLandTenures))
            .subscribe(res => {
                this.landTenures = [];
                if (res) {
                    this.landTenures = res;
                }
            });
        this.subscriptions.push(sourceSubscription);
        this.filteredCountriesData.next(this.countriesData.slice());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addItem() {
    const newAssetClassConsideration = new AssetClassConsiderationModel();
    newAssetClassConsideration.clear(); // Set all defaults fields
    this.editItem(newAssetClassConsideration, -1);
  }
  editItem(_tenureConsideration, index) {
      const dialogRef = this.dialog.open(TenureConsiderationEditDialogComponent, {
          data: {tenureConsideration: _tenureConsideration}
      });
      dialogRef.afterClosed().subscribe(res => {
              if (!res) {
                  return;
              }
              this.tenureConsiderationList = this._deepCopy(this.datasource.data);
              if (index != -1) {
                  this.tenureConsiderationList[index] = res.tenureConsideration;
              } else {
                  this.tenureConsiderationList.push(res.tenureConsideration);
              }
              this.datasource.data = this.tenureConsiderationList;
          }
      );
  }
  deleteItem(index: number) {
    const _title = 'Are you sure?';
    const _description: string = 'The Tenure Consideration "#' + (index + 1) + '" will be deleted';
    const _waitDesciption = 'deleting Tenure Consideration';

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
        if (!res) {
            return;
        }
        this.tenureConsiderationList = this._deepCopy(this.datasource.data);
        this.tenureConsiderationList.splice(index, 1);
        this.datasource.data = this.tenureConsiderationList;
    });
  }

  getData(): AssetClassConsiderationModel[] {
    this.tenureConsiderationList = this._deepCopy(this.datasource.data);
    return this.tenureConsiderationList;
  }

  private _deepCopy(arr: any[]): any[] {
    return arr.map(item => ({...item}))
  }
}
