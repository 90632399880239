<div class="card card-custom">
    <div class="card-body">
        <form class="form">
            <mat-checkbox
                [checked]="value"
                (change)="toggle($event)">
                Save it only for this valuation
            </mat-checkbox>
        </form>
    </div>
    <div class="card-footer b-0 p-4 text-right">
        <div class="form-actions form-actions-solid">
            <button mat-button mat-raised-button (click)="onClose()">
                Cancel
            </button>
            &nbsp;
            <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()">
                Save
            </button>
        </div>
    </div>
</div>