<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
        </div>
        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;"
                   (click)="onSubmit()"
                   class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px">
                    Save
                </a>
            </span>
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="form" [formGroup]="form">
        <div class="card-body">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Input" formControlName="input"></textarea>
                            <mat-error>Input is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>