// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ExternalArea} from '../_models/external-area.model';

export enum ExternalAreaActionTypes {

    AllExternalAreasRequested = '[ExternalAreas Home Page] All ExternalAreas Requested',
    AllExternalAreasLoaded = '[ExternalAreas API] All ExternalAreas Loaded',

    ExternalAreaOnServerCreated = '[Edit ExternalArea Dialog] ExternalArea On Server Created',
    ExternalAreaCreated = '[Edit ExternalAreas Dialog] ExternalAreas Created',
    ExternalAreaUpdated = '[Edit ExternalArea Dialog] ExternalArea Updated',
    ExternalAreaDeleted = '[ExternalAreas List Page] ExternalArea Deleted',

    // trash system

    ExternalAreaOnServerRestored = '[ExternalArea Trash] ExternalArea On Server Restored',
    ExternalAreaRestored = '[ExternalArea Trash] ExternalArea Restored',

    ExternalAreaOnServerAdminRestored = '[ExternalArea Admin Trash] ExternalArea On Server Restored',
    ExternalAreaAdminRestored = '[ExternalArea Admin Trash] ExternalArea Restored',

    ExternalAreaDeletedFromTrash = '[ExternalArea Trash] ExternalArea deleted',
    ExternalAreaDeletedFromAdminTrash = '[ExternalArea Admin Trash] ExternalArea deleted',

    ExternalAreaTrash = 'ExternalArea Trashed',
    ExternalAreaTrashFlushed = 'ExternalArea Trash Flushed',

    // Page system

    ExternalAreasPageRequested = '[ExternalAreas List Page] ExternalAreas Page Requested',
    ExternalAreasPageLoaded = '[ExternalAreas API] ExternalAreas Page Loaded',
    ExternalAreasPageCancelled = '[ExternalAreas API] ExternalAreas Page Cancelled',

    ExternalAreasPageToggleLoading = '[ExternalAreas page] ExternalAreas Page Toggle Loading',
    ExternalAreasActionToggleLoading = '[ExternalAreas] ExternalAreas Action Toggle Loading'
}

export class ExternalAreaOnServerCreated implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaOnServerCreated;

    constructor(public payload: { externalArea: ExternalArea}) {
    }
}

export class ExternalAreaCreated implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaCreated;

    constructor(public payload: { externalArea: ExternalArea }) {
    }
}

export class ExternalAreaUpdated implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaUpdated;

    constructor(public payload: {
        partialexternalArea: Update<ExternalArea>,
        externalArea: ExternalArea
    }) {
    }
}

export class ExternalAreaDeleted implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalAreasPageRequested implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreasPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ExternalAreasPageLoaded implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreasPageLoaded;

    constructor(public payload: { externalAreas: ExternalArea[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ExternalAreasPageCancelled implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreasPageCancelled;
}

export class AllExternalAreasRequested implements Action {
    readonly type = ExternalAreaActionTypes.AllExternalAreasRequested;
}

export class AllExternalAreasLoaded implements Action {
    readonly type = ExternalAreaActionTypes.AllExternalAreasLoaded;

    constructor(public payload: { externalAreas: ExternalArea[] }) {
    }
}

export class ExternalAreasPageToggleLoading implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreasPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ExternalAreasActionToggleLoading implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreasActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ExternalAreaDeletedFromAdminTrash implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ExternalAreaDeletedFromTrash implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalAreaOnServerRestored implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalAreaRestored implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaRestored;

    constructor(public payload: { item: ExternalArea }) {
    }
}

export class ExternalAreaOnServerAdminRestored implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalAreaAdminRestored implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaAdminRestored;

    constructor(public payload: { item: ExternalArea }) {
    }
}

export class ExternalAreaTrashFlushed implements Action {
    readonly type = ExternalAreaActionTypes.ExternalAreaTrashFlushed;

    constructor() {
    }
}

export type ExternalAreaActions = ExternalAreaCreated
    | ExternalAreaUpdated
    | ExternalAreaDeleted
    | ExternalAreasPageRequested
    | ExternalAreasPageLoaded
    | ExternalAreasPageCancelled
    | AllExternalAreasLoaded
    | AllExternalAreasRequested
    | ExternalAreaOnServerCreated

    | ExternalAreaDeletedFromAdminTrash
    | ExternalAreaDeletedFromTrash
    | ExternalAreaOnServerRestored
    | ExternalAreaRestored
    | ExternalAreaOnServerAdminRestored
    | ExternalAreaAdminRestored
    | ExternalAreaTrashFlushed

    | ExternalAreasPageToggleLoading
    | ExternalAreasActionToggleLoading;
