import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamTemplateModel } from 'src/app/core/template';

@Component({
  selector: 'kt-team-template-modal',
  templateUrl: './team-template-modal.component.html',
  styleUrls: ['./team-template-modal.component.scss']
})
export class TeamTemplateModalComponent implements OnInit {
  public template: TeamTemplateModel;

  constructor(
    private dialogRef: MatDialogRef<TeamTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.template = data.template;
  }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
