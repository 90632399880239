<kt-portlet>
    <kt-portlet-header [class]="'kt-portlet__head--lg'" [viewLoading$]="loading$">

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet__head-title">
                <span>Project Management</span>
            </h3>
        </ng-container>

        <ng-container ktPortletTools>

            <a routerLink="../dashboard"
               class="btn btn-secondary mr-2" mat-raised-button matTooltip="Back to the ToE Dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Back</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>

        <div class="col-md-12 kt-margin-bottom-20-mobile p-0 section-margin-bottom-60"
             style="background-color: #5d5b6f">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="tp_name">
                    <th mat-header-cell *matHeaderCellDef> Target Property</th>
                    <td mat-cell *matCellDef="let item; let i = index"
                        [style.display]="rowSpan[i] == 0 ? 'none':''"X
                        [attr.rowspan]="rowSpan[i]">
                        {{item.tp_name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="task">
                    <th mat-header-cell *matHeaderCellDef> Task</th>
                    <td mat-cell *matCellDef="let item; let i = index"> {{item.task_name}}</td>
                </ng-container>
                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef>Details</th>
                    <td mat-cell *matCellDef="let item; let i = index"> {{item.details}}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Expected Completion Date</th>
                    <td mat-cell *matCellDef="let item; let i = index">
                        {{item.date && item.date.length >0 ? (item.date | date: 'dd MMM yyyy - hh a') : 'N/A'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User</th>
                    <td mat-cell *matCellDef="let item; let i = index"> {{item.user_name}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let item; let i = index">
                        <span
                                [matTooltip]="item.task_id == 3 && item.user_id != currentUser.id ? 'You are not Lead Valuer':'Edit Information'">
                            <button mat-icon-button color="primary"
                                    (click)="edit(item, i)">
                                <mat-icon>create</mat-icon>
                            </button>&nbsp;
                        </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [style.border-bottom-color]="row.task_id == 2 ? '#2c343c !important': ''"
                    style="border-bottom-width: 1px; border-bottom-style: solid"></tr>

            </table>
        </div>
    </kt-portlet-body>

</kt-portlet>
