// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
// Actions
import {StandardMeasurementActions, StandardMeasurementActionTypes} from '../_actions/standard-measurement.actions';
// Models
import {StandardMeasurement} from '../_models/standard-measurement.model';

export interface StandardMeasurementsState extends EntityState<StandardMeasurement> {
    isAllStandardMeasurementsLoaded: boolean;
    queryRowsCount: number;
    queryResult: StandardMeasurement[];
    lastCreatedStandardMeasurementId: number;
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<StandardMeasurement> = createEntityAdapter<StandardMeasurement>();

export const initialStandardMeasurementsState: StandardMeasurementsState = adapter.getInitialState({
    isAllStandardMeasurementsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedStandardMeasurementId: undefined,
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function standardMeasurementsReducer(state = initialStandardMeasurementsState, action: StandardMeasurementActions): StandardMeasurementsState {
    switch (action.type) {
        case StandardMeasurementActionTypes.StandardMeasurementsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedStandardMeasurementId: undefined
            };
        case StandardMeasurementActionTypes.StandardMeasurementsActionToggleLoading:
            return {
                ...state, actionsLoading: action.payload.isLoading
            };
        case StandardMeasurementActionTypes.StandardMeasurementOnServerCreated:
            return {
                ...state
            };
        case StandardMeasurementActionTypes.StandardMeasurementCreated:
            return adapter.addOne(action.payload.standardMeasurement, {
                ...state, lastCreatedStandardMeasurementId: action.payload.standardMeasurement.id
            });
        case StandardMeasurementActionTypes.StandardMeasurementUpdated:
            return adapter.updateOne(action.payload.partialstandardMeasurement, state);
        case StandardMeasurementActionTypes.AllStandardMeasurementsLoaded:
            return adapter.addAll(action.payload.standardMeasurements, {
                ...state, isAllStandardMeasurementsLoaded: true
            });
        case StandardMeasurementActionTypes.StandardMeasurementsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case StandardMeasurementActionTypes.StandardMeasurementsPageLoaded:
            return adapter.addMany(action.payload.standardMeasurements, {
                ...initialStandardMeasurementsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case StandardMeasurementActionTypes.StandardMeasurementDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case StandardMeasurementActionTypes.StandardMeasurementRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case StandardMeasurementActionTypes.StandardMeasurementDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case StandardMeasurementActionTypes.StandardMeasurementAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case StandardMeasurementActionTypes.StandardMeasurementDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case StandardMeasurementActionTypes.StandardMeasurementTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
