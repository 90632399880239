import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { SubdomainService } from "../../_base/subdomain.service";

@Injectable()
export class AgencyCriteriaService {
    private API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/agencies`
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    getAll(agencyID: number): Observable<IAgencyCriterion[]> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<{data: IAgencyCriterion[]}>(this.API_URL + `/${agencyID}/criteria`, {
            headers: httpHeaders
        }).pipe(map(result => result.data));
    }

    getAllDefault(): Observable<IAgencyCriterion[]> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<{data: IAgencyCriterion[]}>(this.API_URL + `/default/criteria`, {
            headers: httpHeaders
        }).pipe(map(result => result.data));
    }
}

export interface IAgencyCriterion {
    id: number;
    crit_id: number;
    ac_type_id: number;
    name: string;
    category_id: number;
    category: string;
    criterion_str: string;
    display_by_default: number;
    user_can_remove: number;
    isEdit?: boolean;
    order_num: number;
}