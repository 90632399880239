// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SpecialAssumptionActions, SpecialAssumptionActionTypes} from '../_actions/special-assumption.actions';
// Models
import {SpecialAssumption} from '../_models/special-assumption.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SpecialAssumptionsState extends EntityState<SpecialAssumption> {
    isAllSpecialAssumptionsLoaded: boolean;
    queryRowsCount: number;
    queryResult: SpecialAssumption[];
    lastCreatedSpecialAssumptionId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SpecialAssumption> = createEntityAdapter<SpecialAssumption>();

export const initialSpecialAssumptionsState: SpecialAssumptionsState = adapter.getInitialState({
    isAllSpecialAssumptionsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSpecialAssumptionId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,

    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function specialAssumptionsReducer(state = initialSpecialAssumptionsState, action: SpecialAssumptionActions): SpecialAssumptionsState {
    switch (action.type) {
        case SpecialAssumptionActionTypes.SpecialAssumptionsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSpecialAssumptionId: undefined
            };
        case SpecialAssumptionActionTypes.SpecialAssumptionsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SpecialAssumptionActionTypes.SpecialAssumptionOnServerCreated:
            return {
                ...state
            };
        case SpecialAssumptionActionTypes.SpecialAssumptionCreated:
            return adapter.addOne(action.payload.specialAssumption, {
                ...state, lastCreatedSpecialAssumptionId: action.payload.specialAssumption.id
            });
        case SpecialAssumptionActionTypes.SpecialAssumptionUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case SpecialAssumptionActionTypes.AllSpecialAssumptionsLoaded:
            return adapter.addAll(action.payload.specialAssumptions, {
                ...state, isAllSpecialAssumptionsLoaded: true
            });
        case SpecialAssumptionActionTypes.SpecialAssumptionsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SpecialAssumptionActionTypes.SpecialAssumptionsPageLoaded:
            return adapter.addMany(action.payload.specialAssumptions, {
                ...initialSpecialAssumptionsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case SpecialAssumptionActionTypes.SpecialAssumptionDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SpecialAssumptionActionTypes.SpecialAssumptionRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SpecialAssumptionActionTypes.SpecialAssumptionDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SpecialAssumptionActionTypes.SpecialAssumptionAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SpecialAssumptionActionTypes.SpecialAssumptionDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SpecialAssumptionActionTypes.SpecialAssumptionTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
