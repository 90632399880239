import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Actions, ActionTypes } from "../_actions/video.actions";
import { Video } from "../_models/video.model";

export interface VideoUploadState extends EntityState<Video> {
    isUploading: boolean;
    isProcessing: boolean;
}
export const adapter: EntityAdapter<Video> = createEntityAdapter<Video>(
    {
        selectId: video => video.vimeo_id
    }
)
export const initialState: VideoUploadState = adapter.getInitialState({
    isUploading: false,
    isProcessing: false
});

export function videoUploadReducer(state = initialState, action: Actions): VideoUploadState {
    switch (action.type) {
        case ActionTypes.SET_STATE: {
            return adapter.setAll(action.payload.videos, {
                ...state,
                isUploading: false,
                isProcessing: false
            });
        }
        case ActionTypes.RESET_STATE: {
            const newState = adapter.removeAll({
                ...state,
                isUploading: false,
                isProcessing: false
            })
            return newState 
        }
        case ActionTypes.UPLOAD_VIDEO: {
            return {
                ...state,
                isUploading: true,
            };
        }
        case ActionTypes.VIDEO_UPLOADED: {
            return adapter.addOne(action.payload.video, {
                ...state,
                isProcessing: false 
            })
        }
        case ActionTypes.UPDATE_VIDEO: {
            return adapter.updateOne(action.payload.partial, {
                ...state,
            })
        }
        case ActionTypes.DELETE_VIDEO: {
            return adapter.removeOne(action.payload.vimeo_id, {
                ...state
            })
        }
        case ActionTypes.PROCESS_VIDEO: {
            return {
                ...state,
                isUploading: false,
                isProcessing: true    
            }
        }
        default: {
            return state;
        }
    }
}