import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Behavior } from 'popper.js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AssetClassModel } from 'src/app/core/asset_class';
import { AppState } from 'src/app/core/reducers';
import { ValuationAssumptionDeparture, Type, Source, Status, getType } from 'src/app/core/valuation/_models/valuation-assumption-departure.model';
import * as assumptionDepartureSelectors from 'src/app/core/valuation/_selectors/assumption_departure.selectors';
import * as assumptionDepartureActions from 'src/app/core/valuation/_actions/assumption_departure.actions';
import { EditAssumptionDepartureComponent } from '../_modals/edit-assumption-departure/edit-assumption-departure.component';
import { DeleteEntityDialogComponent, DeleteEntityWithDescrDialogComponent } from 'src/app/views/partials/content/crud';
import { RestoreModalComponent } from '../_modals/restore-modal/restore-modal.component';
import { selectDefaultSizeCriterion } from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import { CriterionModel } from 'src/app/core/valuation';
import { selectValuationProcessDefaultSizeCriterion } from 'src/app/core/valuation-process/_selectors/size-criterion.selectors';
import { SizeCriterionModel } from 'src/app/core/valuation-process/_models';

@Component({
  selector: 'kt-assumptions-table',
  templateUrl: './assumptions-table.component.html',
  styleUrls: ['./assumptions-table.component.scss']
})
export class AssumptionsTableComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() targetProperty: AssetClassModel;
  dataSource: MatTableDataSource<ValuationAssumptionDeparture> = new MatTableDataSource();
  subscriptions: Subscription[] = [];
  type = Type;
  source = Source;
  status = Status;
  valuationId: string;

  columns = ['type', 'source', 'input', 'input-status', 'justification', 'actions']

   // Will change future
   selectedNotToERequiredSize$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    const sub = this.store$.select(assumptionDepartureSelectors.selectAll).subscribe(res => {
      if (res) {
        this.dataSource.data = res;
      }
    });
    this.subscriptions.push(sub);

    const sizeSub = this.store$.select(selectValuationProcessDefaultSizeCriterion).subscribe(res => {
      if (res) {
        this.selectedNotToERequiredSize$.next(this._checkIfSelectedNotToeRequired(res));
      }
    });
    this.subscriptions.push(sizeSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addAssumptionOrDeparture(type: Type) {
    const dialogRef = this.dialog.open(EditAssumptionDepartureComponent, {
      width: '50vw',
      data: {
        isEdit: false,
        type: type,
        tp: this.targetProperty,
        existing: this.dataSource.data
      }
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new assumptionDepartureActions.AddAssumptionOrDeparture({data: res}));
      }
    });
    this.subscriptions.push(dialogSub);
  }

  edit(item: ValuationAssumptionDeparture) {
    const dialogRef = this.dialog.open(EditAssumptionDepartureComponent, {
      width: '50vw',
      data: {
        isEdit: true,
        type: item.type,
        item: item,
        tp: this.targetProperty
      }
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new assumptionDepartureActions.EditAssumptionOrDeparture({data: res}));
      }
    });
    this.subscriptions.push(dialogSub);
  };

  restore(item: ValuationAssumptionDeparture) {
    const dialogRef = this.dialog.open(RestoreModalComponent, {
      width: '440px'
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new assumptionDepartureActions.RestoreAssumptionOrDeparture({
          data: item,
          tp: this.targetProperty
        }))
      }
    });
    this.subscriptions.push(dialogSub);
  };

  remove(item: ValuationAssumptionDeparture) {
    const _title = 'Are you sure?';
    const _waitingDescription = `Removing ${getType(item.type)}`;
    if (item.source === Source.ToE) {
      const _description = `Any changes previously made will be lost and the content will be restored as per ToE. Please provide your justification(s) for removing this ${getType(item.type)}`;
      const dialogRef = this.dialog.open(DeleteEntityWithDescrDialogComponent, {
        data: {title: _title, description: _description, waitDescription: _waitingDescription, 
          placeholder: 'Justification', error: 'Justification is'},
        width: '440px'
      });
      const dialogSub = dialogRef.afterClosed().subscribe(res => {
        if (res) {
          // Change status of ToE 
          this.store$.dispatch(new assumptionDepartureActions.RemoveToEAssumptionOrDeparture({
            data: item,
            tp: this.targetProperty,
            justification: res
          }))
        }
      })
      this.subscriptions.push(dialogSub);
    } else {
      const _description = `Once deleted, you will not be able to retrieve this newly created ${getType(item.type)}`
      const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
        data: {title: _title, description: _description, waitDescription: _waitingDescription},
        width: '440px'
      });
      const dialogSub = dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.store$.dispatch(new assumptionDepartureActions.RemoveInputAssumptionOrDeparture({
            data: item,
          }))
        }
      })
      this.subscriptions.push(dialogSub);
    }
  };

  _checkIfSelectedNotToeRequired(sizeCriterion: SizeCriterionModel): boolean {
    return sizeCriterion.publicName.includes('If selected, assumption or special assumption will be required');
}
}
