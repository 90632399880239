import { A } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AssetClassLandAreaComponentModel } from 'src/app/core/asset_class';
import { AcConsideration, AcLandTenure } from 'src/app/core/comparable/_models/asset-class-tenure.model';
import { AllLandTenuresRequested, LandTenure, selectAllLandTenures, tenuresReducer } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { CountryData } from 'src/app/views/pages/admin-management/countries/countries-list/local-data';
import { LandAreaEditDialogComponent } from 'src/app/views/pages/shared_components/land-parcel/land-area/land-area-edit/land-area-edit.dialog.component';

type TableItem = {
  land_area: any,
  order: number,
  included: boolean,
  tenure_name: number,
  tenure_length: number,
  consideration: number,
  currency: string
}

@Component({
  selector: 'kt-parcel-consideration-table',
  templateUrl: './parcel-consideration-table.component.html',
  styleUrls: ['./parcel-consideration-table.component.scss']
})
export class ParcelConsiderationTableComponent implements OnInit, OnDestroy {
  @Input() components: AssetClassLandAreaComponentModel[];
  @Input() additionals: AcLandTenure[];

  dataSource = new MatTableDataSource<TableItem>();
  columns = ['land_area', 'included', 'tenure_name', 'tenure_length', 'consideration', 'currency']
  disColumn = ['land_area', 'included', 'text'];
  landTenures: LandTenure[]  = [];
  private subscriptions: Subscription[] = [];
  public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  countriesData = CountryData.countriesWithNoDuplicateCurrency();

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
      this.dataSource.data = this.additionals.map(add => {
        const land = this.components.find(com => com.order == add.com_order);
        return {
          land_area: land,
          order: add.com_order,
          included: add.included,
          tenure_name: add.land_tenure_id,
          tenure_length: add.tenure_length,
          consideration: add.consideration,
          currency: add.currency
        }
      }) 

    this.store.dispatch(new AllLandTenuresRequested());
    
    const sourceSubscription = this.store.pipe(
        select(selectAllLandTenures))
        .subscribe(res => {
            this.landTenures = [];
            if (res) {
                this.landTenures = res;
            }
        });
    this.subscriptions.push(sourceSubscription);
    this.filteredCountriesData.next(this.countriesData.slice());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  viewLandArea(component: any) {
    this.dialog.open(LandAreaEditDialogComponent, {
        data: {landAreaComponent: component, disable: true}
    });
  }

  // if valid return true, otherwise false
  validate(): boolean {
    let valid = true;
    this.dataSource.data.forEach(d => {
      if (!d.included && !this.isValid(d)) {
        valid = false;
      }
    });
    return valid;
  }

  private isValid(t: TableItem): boolean {
    if (t.tenure_name == null) {
      return false;
    }
    if (t.currency == null) {
      return false
    }
    return true; 
  }

  onSave() {
    const current = this.additionals
    const _new = current.map(v => {
      const index = this.dataSource.data.findIndex(d => d.order == v.com_order)
      if (index == -1) {
        return v;
      } const data = this.dataSource.data[index];
      const _v = Object.assign({}, v) as AcLandTenure;
      _v.consideration = data.consideration;
      _v.tenure_length = data.tenure_length;
      _v.land_tenure_id = data.tenure_name;
      _v.currency = data.currency;
      _v.included = data.included;
      return _v
    });
    return _new;
  }
}
