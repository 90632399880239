import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, timer } from "rxjs";
import { tap, delayWhen, map, mergeMap, retryWhen } from "rxjs/operators";
import * as tus from 'tus-js-client';
import { Video } from "../_models/video.model";
import {environment} from '../../../../environments/environment';

@Injectable()
export class VideoUploadService {
    private accessToken = environment.accessToken;
    private myAccAPI = environment.myAccAPI;
    private createLinkAPI = environment.createLinkAPI;
    constructor(
        private httpClient: HttpClient
    ) {}

    public upload(file: File): Observable<any> {
        const status = new BehaviorSubject(null);
        return this.createLink(file).pipe(
            mergeMap(response => {
                const uploadUrl = response.body.upload.upload_link
                this.tusUploadDemo(
                    file,
                    uploadUrl,
                    (err) => {
                        console.error('Failed: ', file.name, err)
                        status.next({success: false, error: err})
                    },
                    (bytesUploaded, bytesTotal) => {
                        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                        console.log(
                            'file: ' + file.name,
                            bytesUploaded,
                            bytesTotal,
                            percentage + '%'
                        );
                    },
                    () => {
                        status.next({success: true, info: response.body})
                    }
                )
                return status
            })
        )
    }

    private createLink(file: File): Observable<any> {
        const body = {
            name: file.name,
            upload: {
                approach: 'tus',
                size: file.size
            }
        }

        const header = new HttpHeaders()
            .set('Authorization', 'bearer ' + this.accessToken)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/vnd.vimeo.*+json;version=3.4')
        return this.httpClient.post(this.createLinkAPI, body, {
            headers: header,
            observe: 'response'
        });
    }

    private tusUploadDemo(
        file: File,
        uploadUrl: string,
        onError: (err: any) => void,
        onProgress: (bytesUploaded: any, bytesTotal: any) => void,
        onSucess: () => void
    ) {
        const upload = new tus.Upload(file, {
            uploadUrl: uploadUrl,
            endpoint: uploadUrl,
            retryDelays: [0, 1000, 3000, 5000],
            onError: onError,
            onProgress: onProgress,
            onSuccess: onSucess
        });
        upload.start();
    }

    public getVideoInfo(url: string): Observable<any> {
        const apiurl = this.myAccAPI + `${url}`;
        const header = new HttpHeaders() 
            .set('Authorization', 'bearer ' + this.accessToken)
        return this.httpClient.get<any>(apiurl, {
            headers: header
        }).pipe(
            map(info => {
                if (info.pictures.active == false) {
                    throw 'default';
                }
                return info
            }),
            retryWhen(errors => 
                errors.pipe(
                    delayWhen(val => timer(3000))
                )
            )
        )
    }

    public getVideo(info: any): Video {
        const firstIndex = info.embed.html.indexOf("\"");
        const lastIndex = info.embed.html.indexOf("\"", firstIndex + 1);
        const idIndex = info.uri.lastIndexOf("/");
        const video = new Video(
            Number(info.uri.substring(idIndex + 1)),
            info.embed.html.substring(firstIndex + 1, lastIndex - 1),
            info.pictures.base_link
        );
        return video;
    }
}