<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet__head--lg'" [viewLoading$]="dataSource.loading$">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <!-- <h3 class="kt-portlet__head-title"> -->
                <span>{{ 'TASK_EFFORT.LIST.TITLE' | translate }}</span>
            <!-- </h3> -->
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <a routerLink="../"
               class="btn  mr-2"
               mat-raised-button matTooltip="Back to the Linked Table List">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
            </a>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       #sort1="matSort"
                       matSort
                       [dataSource]="dataSource"
                       matSortActive="id"
                       matSortDirection="asc"
                       matSortDisableClear>

                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef>{{ 'TASK_EFFORT.LIST.DATATABLE.CATEGORY' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{ item.task_name}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="note">
                    <mat-header-cell *matHeaderCellDef>{{ 'TASK_EFFORT.LIST.DATATABLE.NOTE' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.note}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="point">
                    <mat-header-cell *matHeaderCellDef>{{ 'TASK_EFFORT.LIST.DATATABLE.POINTS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.point}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ 'TASK_EFFORT.LIST.DATATABLE.ACTIONS' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let item; let i=index;">
                        <button mat-icon-button color="primary" matTooltip="Edit Point" (click)="editItem(i, item)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index" [ngClass]="{gray: index%2}">
                </mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
                           [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>