
import { Component } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { select, Store } from "@ngrx/store";
import { switchMap, tap, withLatestFrom } from "rxjs/operators";
import { LayoutUtilsService } from "src/app/core/_base/crud";
import { hasPermission } from "src/app/core/mad-auth/mad-auth.store";
import { AppState } from "src/app/core/reducers";
import { RentReviewTypeService } from "src/app/core/v2/services";
import { RentReviewType } from "src/app/core/v2/types/rent-review-type";

interface State {
  items: RentReviewType[]
  trashed: RentReviewType[],
  adminTrashed: RentReviewType[]
}

@Component({
  selector: 'kt-rent-review-types-list',
  templateUrl: './rent-review-types-list.component.html',
  providers: [ComponentStore],
  styles: [
    `
      .mat-column-id {
        flex: 0 0 5% !important;
      }

      .mat-column-is_hidden {
        flex: 0 0 10% !important;
        justify-content: center;
      }
    `
  ]
})
export class RentReviewTypesListComponent {

  items$ = this.store.select(state => state.items)
  trashedCnt$ = this.store.select(state => state.trashed.length)
  adminTrashedCnt$ = this.store.select(state => state.adminTrashed.length)
  canAccessAdminTrash$ = this.ngStore.pipe(select(hasPermission(['admin_trash'])));

  constructor(
    private store: ComponentStore<State>,
    private ngStore: Store<AppState>,
    private service: RentReviewTypeService,
    private layoutUtilService: LayoutUtilsService
  ) {
    this.store.setState({
      items: [],
      trashed: [],
      adminTrashed: []
    })

    this._pageOpened()
    this._loadTrashed()
  }

  onEditItem(item: RentReviewType) {}

  onTrash() {
    this.trash()
  }
  private readonly trash = this.store.effect<void>(trigger$ =>
    trigger$.pipe(
      withLatestFrom(this.store.state$),
      tap(([_, state]: [void, State]) => {
        this.showTrash(
          state.trashed.map(item => ({
            text: `#${item.id} - ${item.name}`,
            id: item.id.toString(),
            hasPermanentlyDelete: true,
            deletedUser: item.userDeletedBy ? item.userDeletedBy : 'N/A',
            date: item.user_deleted
          })),
          false
        )
      })
    )
  )

  onAdminTrash() {
    this.adminTrash()
  }
  private readonly adminTrash = this.store.effect<void>(trigger$ =>
    trigger$.pipe(
      withLatestFrom(this.store.state$),
      tap(([_, state]: [void, State]) => {
        this.showTrash(
          state.adminTrashed.map(item => ({
            text: `#${item.id} - ${item.name}`,
            id: item.id.toString(),
            hasPermanentlyDelete: true,
            deletedUser: item.deletedBy ? item.deletedBy : 'N/A',
            date: item.deleted_at
          })),
          false
        )
      })
    )
  )

  onDeleteItem(item: RentReviewType) {
    this.delete(item.id)
  }
  private readonly delete = this.store.effect<number>(id$ => 
    id$.pipe(
      switchMap(id =>
        this.service.delete(id).pipe(
          tapResponse(
            () => {
              this._loadList()
              this._loadTrashed()
            },
            (err: Error) => console.error(err)
          )
        )
      )
    )
  )
  onViewItem(item: RentReviewType) {}

  private _pageOpened = this.store.effect<void>(trigger$ => 
    trigger$.pipe(
      switchMap(() => 
        this.service.fetch().pipe(
          tapResponse(
            (response) => {
              this.store.patchState({
                items: response.data
              })
            },
            (err: Error) => console.error(err)
          )
        )
      )
    )
  )

  private _loadList = this.store.effect<void>(trigger$ => 
    trigger$.pipe(
      switchMap(() => 
        this.service.fetch().pipe(
          tapResponse(
            (response) => {
              this.store.patchState({
                items: response.data
              })
            },
            (err: Error) => console.error(err)
          )
        )
      )
    )
  )

  private _loadTrashed = this.store.effect<void>(trigger$ =>
    trigger$.pipe(
      switchMap(() => 
        this.service.trashed().pipe(
          tapResponse(
            (response) => this.store.patchState({trashed: response.data}),
            (err: Error) => console.error(err)
          )
        )
      )
    )
  )

  private _loadAdminTrashed = this.store.effect<void>(trigger$ =>
    trigger$.pipe(
      switchMap(() => 
        this.service.adminTrashed().pipe(
          tapResponse(
            (response) => this.store.patchState({adminTrashed: response.data}),
            (err: Error) => console.error(err)
          )
        )
      )
    )
  )

  private showTrash(items: any[], isAdmin = false) {
    let _title = 'Deleted Terms of Engagements';
    if (isAdmin) {
        _title = 'Admin Deleted Terms of Engagements';
    }
    this.layoutUtilService.trashedEntities(_title, items, this, isAdmin)
  }
}