<div class="size-table-container">
    <!-- <ng-container> -->
        <!-- <kt-ipms-table *ngSwitchCase="4" 
            [assetClassID]="assetClassId" 
            [standardMeasurement]="standardMeasurement"
            [floorAction$]="floorAction$"
            [unitAreaMeasurement$]="unitAreaMeasurement$"
            [readonly]="readonly"
            ></kt-ipms-table>
        <kt-ipms-table *ngSwitchCase="5" 
            [assetClassID]="assetClassId" 
            [standardMeasurement]="standardMeasurement"
            [floorAction$]="floorAction$"
            [unitAreaMeasurement$]="unitAreaMeasurement$"
            [readonly]="readonly"
            ></kt-ipms-table>
        <kt-ipms-table *ngSwitchCase="6" 
            [assetClassID]="assetClassId" 
            [standardMeasurement]="standardMeasurement"
            [floorAction$]="floorAction$"
            [unitAreaMeasurement$]="unitAreaMeasurement$"
            [readonly]="readonly"
            ></kt-ipms-table>
        <kt-ipms-table *ngSwitchCase="8"
            [assetClassID]="assetClassId" 
            [standardMeasurement]="standardMeasurement"
            [floorAction$]="floorAction$"
            [unitAreaMeasurement$]="unitAreaMeasurement$"
            [readonly]="readonly"
        ></kt-ipms-table> -->
        <kt-ipms-table
            *ngIf="smUtilityService.isIPMS(standardMeasurement.id)"
            [assetClassID]="assetClassId" 
            [standardMeasurement]="standardMeasurement"
            [floorAction$]="floorAction$"
            [unitAreaMeasurement$]="unitAreaMeasurement$"
            [readonly]="readonly"
        ></kt-ipms-table>
        <kt-mongolia-standard-table *ngIf="smUtilityService.isMongolianStandard(standardMeasurement.id)"
            [assetClassID]="assetClassId" 
            [standardMeasurement]="standardMeasurement"
            [floorAction$]="floorAction$"
            [unitAreaMeasurement$]="unitAreaMeasurement$"
            [bedrooms$]="bedrooms$"
            [readonly]="readonly"
            [externalAreas$]="externalAreas$"
        ></kt-mongolia-standard-table>
        <!-- <ng-container *ngSwitchDefault>
            <p>Currently "{{standardMeasurement.name}}" standard measurement is not supported</p>
        </ng-container> -->
    <!-- </ng-container> -->
</div>