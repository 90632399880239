<div class="card h-100">
    <div class="card-header info-header">
        <span>Milestones</span>
    </div>
    <div class="card-body info-body">
        <table class="table" *ngIf="data$|async as data">
            <tbody>
                <tr *ngIf="multimedia">
                    <th>Instruction date :</th>
                    <td>{{toe.instruction_date  | dateWithOffset:toe.timezone}}</td>
                </tr>
                <tr>
                    <th>Inspection :</th>
                    <td>{{data.inspectionType}} - {{data.inspectionDate  | dateWithOffset:tpDetails.locationData.time_offset}}</td>
                </tr>
                <tr *ngIf="multimedia">
                    <th>Valuation report date :</th>
                    <td>{{valuationReportDate 
                        ? (valuationReportDate  | dateWithOffset:toe.timezone)
                        : 'N/A'}}
                    </td>
                </tr>
                <tr *ngIf="!multimedia">
                    <th>Draft Report (if any) :</th>
                    <td>{{data.draftDate 
                        ? (data.draftDate  | dateWithOffset:toe.timezone)
                        : 'N/A'}}
                    </td>
                </tr>
                <tr *ngIf="multimedia">
                    <th>Date of Valuation :</th>
                    <td>{{valuationDate 
                        ? (valuationDate  | dateWithOffset:toe.timezone)
                        : 'N/A'}}
                    </td>
                </tr>
                <tr *ngIf="!multimedia">
                    <th>Final Report :</th>
                    <td>{{data.finalDate  | dateWithOffset:toe.timezone}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>