// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AssetClassOfficeActions, AssetClassOfficeActionTypes} from '../_actions/asset-class-office.actions';
// Models
import {AssetClassOfficeModel} from '../_models/asset-class-office.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AssetClassOfficesState extends EntityState<AssetClassOfficeModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    previouslyCreatedACId: number;
    lastCreatedAssetClassOfficeId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassOfficeModel> = createEntityAdapter<AssetClassOfficeModel>();

export const initialAssetClassOfficesState: AssetClassOfficesState = adapter.getInitialState({
    assetClassOfficeForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    previouslyCreatedACId: undefined,
    lastCreatedAssetClassOfficeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassOfficesReducer(state = initialAssetClassOfficesState, action: AssetClassOfficeActions): AssetClassOfficesState {
    switch (action.type) {
        case AssetClassOfficeActionTypes.UpdatePreviouslyCreatedACId: {
            return {
                ...state,
                previouslyCreatedACId: state.lastCreatedAssetClassOfficeId
            }
        }
        case AssetClassOfficeActionTypes.AssetClassOfficesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassOfficeId: undefined
            };
        }
        case AssetClassOfficeActionTypes.AssetClassOfficeActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AssetClassOfficeActionTypes.AssetClassOfficeOnServerCreated:
            return {
                ...state
            };
        case AssetClassOfficeActionTypes.AssetClassOfficeCreated:
            return adapter.addOne(action.payload.assetClassOffice, {
                ...state, 
                previouslyCreatedACId: state.lastCreatedAssetClassOfficeId,
                lastCreatedAssetClassOfficeId: action.payload.assetClassOffice.id
            });

        case AssetClassOfficeActionTypes.OfficeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        case AssetClassOfficeActionTypes.AssetClassOfficeUpdated:
            return adapter.addOne(action.payload.assetClassOffice, {
                ...state, lastCreatedAssetClassOfficeId: action.payload.assetClassOffice.id
            });
        case AssetClassOfficeActionTypes.AssetClassOfficesPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AssetClassOfficeActionTypes.AssetClassOfficesPageLoaded: {

            return adapter.addMany(action.payload.assetClassOffices, {
                ...initialAssetClassOfficesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case AssetClassOfficeActionTypes.AssetClassOfficeOnServerRestored:
            return {
                ...state
            };
        case AssetClassOfficeActionTypes.AssetClassOfficeRestored:
            return adapter.addOne(action.payload.assetClassOffice, {
                ...state,
                lastCreatedAssetClassOfficeId: action.payload.assetClassOffice.id,
                totalTrashed: state.totalTrashed - 1
            });


        case AssetClassOfficeActionTypes.AssetClassOfficeAdminRestored:
            return adapter.addOne(action.payload.assetClassOffice, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AssetClassOfficeActionTypes.AssetClassOfficeDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            };

        case AssetClassOfficeActionTypes.AssetClassOfficeDeletedFromAdminTrash:
            return {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case AssetClassOfficeActionTypes.OneAssetClassOfficeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });
        case AssetClassOfficeActionTypes.AssetClassOfficeOnServerUpdated:
            return adapter.updateOne(action.payload.partialAssetClassOffice, state);
        default:
            return state;
    }
}

export const getAssetClassOfficeState = createFeatureSelector<AssetClassOfficeModel>('assetClassOffices');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
