import {BaseModel} from '../../_base/crud';
import {ToeInvoicePvd} from './toe-invoice-pvd.model';

export class ToeInvoice extends BaseModel {
    id: number;
    toe_id: number;
    payment_term_id: number;
    tp_amendment_id: number;
    task_id: number;
    invoice_number: string;
    status: string;
    status_date: Date;
    payment_date: Date;
    void_reason: string;
    comment: string;
    invoice_amount: number;

    toeInvoicePvds: ToeInvoicePvd[];
    toeInvoicePvdsAlt: any[];

    created_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.toe_id = undefined;
        this.payment_term_id = undefined;
        this.tp_amendment_id = undefined;
        this.task_id = undefined;
        this.invoice_number = '';
        this.status = '';
        this.status_date = new Date();
        this.payment_date = new Date();
        this.void_reason = '';
        this.comment = '';
        this.invoice_amount = 0;

        this.toeInvoicePvds = [];

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
