<div class="form-group kt-form-group row" [formGroup]="form">
    <div class="col-md-12">
        <h5 class="my-3 text-mad text-uppercase"> 
            {{ typeOfInspection == 1 ? 'Desktop Review Detail' : 'Details' }}
        </h5>
        <hr class="active">
    </div>
    <ng-container *ngIf="typeOfInspection == 1">
        <div class="col-md-6">
            <mat-form-field class="mat-form-field-fluid">
                <!-- <span (click)="addPicker_information_date.open()">
                    <input matInput 
                        readonly
                        [matDatepicker]="addPicker_information_date" 
                        placeholder="Date of information"
                        formControlName="information_date" />
                </span> -->
                <kt-datepicker-tz-input #addPicker_information_date
                    placeholder="Date of information"
                    formControlName="information_date"
                    [timezone]="timezoneOffset"
                ></kt-datepicker-tz-input>
                <button mat-button mat-icon-button matSuffix type="button">
                    <mat-icon>today</mat-icon>
                </button>
                <button mat-button mat-icon-button matSuffix type="button" (click)="clearInformationDate(addPicker_information_date)">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error>Date of information is <strong>required</strong></mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field class="mat-form-field-fluid">
                <textarea matInput 
                    rows="2" 
                    placeholder="Source of information"
                    formControlName="source_of_information"></textarea>
                <mat-error>Source of informtion is <strong>required</strong></mat-error>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="typeOfInspection != 1">
        <div class="col-md-12">
            <mat-form-field class="mat-form-field-fluid">
                <span (click)="addPicker_inpection_date.open()">
                    <input matInput [matDatepicker]="addPicker_inpection_date" readonly placeholder="Date of inpection" formControlName="inspection_date">
                </span>
                <mat-error>Date of inspection is <strong>required</strong></mat-error>
                <mat-datepicker-toggle matSuffix [for]="addPicker_inpection_date"></mat-datepicker-toggle>
                <button mat-button
                        *ngIf="form.controls.inspection_date.value"
                        matSuffix mat-icon-button matTooltip="Clear"
                        (click)="clearDate(form.controls.inspection_date)">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker #addPicker_inpection_date></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="mat-form-field-fluid">
                        <kt-timepicker-tz-input placeholder="Time of Inspection" formControlName="time_of_inspection" [timezone]="timezoneOffset"></kt-timepicker-tz-input>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="mat-form-field-fluid">
                        <span (click)="openTimeField($event)">
                            <input matInput 
                                formControlName="duration_of_inspection"
                                placeholder="Duration of Inspection"
                                autocomplete="off" />
                        </span>
                            <mat-error>Duration of Inspection <strong>required</strong></mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-md-12 kt-margin-bottom-20-mobile mb-3">
            <kt-participant-list
                    [toeSelected]="toeSelected"
                    [agencyName]="agencyName"
                    [clientName]="clientName"
                    [participantsSubject]="participantsSubject"
                    [atLeastOneParticipant$]="atLeastOneParticipant$"
                    [readOnly]="readonly"
                    [agencyId]="agencyId$"
                    [toeId]="toeId$"
                    [clientId]="clientId$"
            >
            </kt-participant-list>
            <mat-error class="tag-custom-error" *ngIf="hasFormErrors && (atLeastOneParticipant$|async)">
                At least one participant
                <strong>required</strong>
            </mat-error>
        </div>

        <div class="col-md-12 kt-margin-bottom-20-mobile mt-3">
            <label class="external-area-label-gray">Were there any limitations or restrictions during the inspection?</label>
        </div>

        <div class="col-lg-12 kt-margin-bottom-20-mobile">

            <mat-radio-group class="tp-radio-group m-2 mt-4 p-2"
                                (change)="radioChanged($event)"
                                formControlName="any_limitations_or_restrictions">
                <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                    [value]="true">Yes
                </mat-radio-button>
                <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                    [value]="false">No
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <!--Limitations during the inspection-->
        <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="form.controls.any_limitations_or_restrictions.value">
            <mat-form-field class="mat-form-field-fluid">
                <textarea matInput rows="4" placeholder="Limitations During the Inspection" formControlName="limitations_desc"></textarea>
                <mat-error>Limitations During the Inspection
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-12">
            <mat-form-field class="mat-form-field-fluid">
                <textarea matInput rows="2" placeholder="Inspection note" formControlName="inspection_note"></textarea>
            </mat-form-field>
        </div>
    </ng-container>
</div>