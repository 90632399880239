// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {
    selectStandardMeasurementsQueryResult,
    selectStandardMeasurementsPageLoading,
    selectStandardMeasurementsShowInitWaitingMessage
} from '../_selectors/standard-measurement.selectors';

export class StandardMeasurementsDataSource extends BaseDataSource {
    trashed: number;

    constructor(private store: Store<AppState>) {
        super();
        this.trashed = 0;

        this.loading$ = this.store.pipe(
            select(selectStandardMeasurementsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectStandardMeasurementsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectStandardMeasurementsQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
