import { BaseTemplateDataModel } from ".";

export class AdjustmentTemplateModel implements BaseTemplateDataModel {
    id: number;
    name: string;
    description: string;

    asset_class_type_id: number;
    adjustments: IAdjustmentTemplate[];

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.name = null;
        this.description = null;
        this.asset_class_type_id = undefined;
        this.adjustments = [];
    }
}

export interface IAdjustmentTemplate {
    id: number;
    adjustment_id: number;
    description: string;
    name: string;
}