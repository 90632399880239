import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ToeAuditTaskReminderModel } from "../_models/toe-audit-task-reminder.model";
import { ToeAuditTaskReminderState } from "../_reducers/toe-audit-task-reminders.reducer";
import * as _ from 'lodash'

export const selectToeAuditTaskReminderState = createFeatureSelector<ToeAuditTaskReminderState>('toe-audit-task-reminders')

export const selectToeAuditTaskReminders = createSelector(
    selectToeAuditTaskReminderState,
    state => {
        const items: ToeAuditTaskReminderModel[] = [];
        _.each(state.entities, el => {
            items.push(el)
        })

        items.sort(sortByCreatedAt)

        return items
    }
)

export const selectToeAuditTaskStatus = createSelector(
    selectToeAuditTaskReminders,
    entities => {
        const total = entities.length
        if (total == 0) {
            return {num: null}
        }
        const num = entities.filter(item => item.status == 1).length
        return {num}
    }
)

function sortByCreatedAt<T extends {created_at: any}>(a: T, b: T): number {
    const d1 = new Date(b.created_at)
    const d2 = new Date(a.created_at)
    return d1.getTime() - d2.getTime()
}