import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { GardenModel } from "../_models/garden.model";

export enum GardenActionTypes {
    AllGardensRequested = '[Linked Area] All Garden Requested',
    AllGardensLoaded = '[Garden Effect] All Garden Loaded',

    GardenOnServerCreated = '[Add Garden Page] Garden On Server Created',
    GardenCreated = '[Garden Effect] Garden Created',
    GardenUpdated = '[Garden Effect] Garden Updated',
    GardenDeleted = '[Garden List Page] Garden Deleted',

    GardenOnServerRestored = '[Garden Trash] Garden On Server Restored',
    GardenRestored = '[Garden Trash] Garden Restored',

    GardenOnServerAdminRestored = '[Garden Admin Trash] Garden On Server Restored',
    GardenAdminRestored = '[Garden Admin Trash] Garden Restored',

    GardenDeletedFromTrash = '[Garden Trash] Garden deleted',
    GardenDeletedFromAdminTrash = '[Garden Admin Trash] Garden deleted',

    GardenTrash = 'Garden Trashed',
    GardenTrashFlushed = 'Garden Trash Flushed',

    GardensPageRequested = '[Garden List Page] Garden Page Requested',
    GardensPageLoaded = '[Garden API] Garden Page Loaded',
    GardensPageCancelled = '[Garden API] Garden Page Cancelled',

    GardensPageToggleLoading = '[Garden page] Garden Page Toggle Loading',
    GardenActionToggleLoading = '[Garden] Garden Action Toggle Loading'
}

export class GardenOnServerCreated implements Action {
    readonly type = GardenActionTypes.GardenOnServerCreated;

    constructor(public payload: { item: GardenModel }) {
    }
}

export class GardenCreated implements Action {
    readonly type = GardenActionTypes.GardenCreated;

    constructor(public payload: { item: GardenModel }) {
    }
}

export class GardenUpdated implements Action {
    readonly type = GardenActionTypes.GardenUpdated;

    constructor(public payload: {
        partialItem: Update<GardenModel>,
        item: GardenModel
    }) {
    }
}

export class GardenDeleted implements Action {
    readonly type = GardenActionTypes.GardenDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class GardensPageRequested implements Action {
    readonly type = GardenActionTypes.GardensPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class GardensPageLoaded implements Action {
    readonly type = GardenActionTypes.GardensPageLoaded;

    constructor(public payload: { items: GardenModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class GardensPageCancelled implements Action {
    readonly type = GardenActionTypes.GardensPageCancelled;
}

export class AllGardensRequested implements Action {
    readonly type = GardenActionTypes.AllGardensRequested;
}

export class AllGardensLoaded implements Action {
    readonly type = GardenActionTypes.AllGardensLoaded;

    constructor(public payload: { items: GardenModel[] }) {
    }
}

export class GardensPageToggleLoading implements Action {
    readonly type = GardenActionTypes.GardensPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class GardenActionToggleLoading implements Action {
    readonly type = GardenActionTypes.GardenActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class GardenDeletedFromAdminTrash implements Action {
    readonly type = GardenActionTypes.GardenDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class GardenDeletedFromTrash implements Action {
    readonly type = GardenActionTypes.GardenDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class GardenOnServerRestored implements Action {
    readonly type = GardenActionTypes.GardenOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class GardenRestored implements Action {
    readonly type = GardenActionTypes.GardenRestored;

    constructor(public payload: { item: GardenModel }) {
    }
}

export class GardenOnServerAdminRestored implements Action {
    readonly type = GardenActionTypes.GardenOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class GardenAdminRestored implements Action {
    readonly type = GardenActionTypes.GardenAdminRestored;

    constructor(public payload: { item: GardenModel }) {
    }
}

export class GardenTrashFlushed implements Action {
    readonly type = GardenActionTypes.GardenTrashFlushed;

    constructor() {
    }
}


export type GardenActions = GardenCreated
    | GardenUpdated
    | GardenDeleted
    | GardensPageRequested
    | GardensPageLoaded
    | GardensPageCancelled
    | AllGardensLoaded
    | AllGardensRequested
    | GardenOnServerCreated
    | GardenDeletedFromAdminTrash
    | GardenDeletedFromTrash
    | GardenOnServerRestored
    | GardenRestored
    | GardenOnServerAdminRestored
    | GardenAdminRestored
    | GardenTrashFlushed
    | GardensPageToggleLoading
    | GardenActionToggleLoading;