import {WindowType} from '../_models/window-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {WindowTypesState} from '../_reducers/window-type.reducers';
import * as fromWindowType from '../_reducers/window-type.reducers';
import {each} from 'lodash';

export const selectWindowTypesState = createFeatureSelector<WindowTypesState>('windowTypes');

export const selectWindowTypeById = (windowTypeId: number) => createSelector(
    selectWindowTypesState,
    windowTypesState => windowTypesState.entities[windowTypeId]
);

export const selectAllWindowTypes = createSelector(
    selectWindowTypesState,
    fromWindowType.selectAll
);

export const selectAllWindowTypesIds = createSelector(
    selectWindowTypesState,
    fromWindowType.selectIds
);

export const allWindowTypesLoaded = createSelector(
    selectWindowTypesState,
    windowTypesState => windowTypesState.isAllWindowTypesLoaded
);


export const selectWindowTypesPageLoading = createSelector(
    selectWindowTypesState,
    windowTypesState => windowTypesState.listLoading
);

export const selectWindowTypesActionLoading = createSelector(
    selectWindowTypesState,
    windowTypesState => windowTypesState.actionLoading
);

export const selectLastCreatedWindowTypeId = createSelector(
    selectWindowTypesState,
    windowTypesState => windowTypesState.lastCreatedWindowTypeId
);

export const selectWindowTypesShowInitWaitingMessage = createSelector(
    selectWindowTypesState,
    windowTypesState => windowTypesState.showInitWaitingMessage
);

export const selectTrashedWindowTypeCount = createSelector(
    selectWindowTypesState,
    (windowTypesState) => windowTypesState.totalTrashed
);

export const selectAdminTrashedWindowTypeCount = createSelector(
    selectWindowTypesState,
    (windowTypesState) => windowTypesState.totalAdminTrashed
);


export const selectWindowTypeQueryResult = createSelector(
    selectWindowTypesState,
    windowTypesState => {
        const items: WindowType[] = [];
        each(windowTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: WindowType[] = httpExtension.sortArray(items, windowTypesState.lastQuery.sortField, windowTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, windowTypesState.totalCount, '', windowTypesState.totalTrashed);
    }
);
