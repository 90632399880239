import { HttpClient, HttpParams, HttpUserEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { SubdomainService } from "../../_base/subdomain.service";


export interface StatsInfo {
    total: number;
    comparables90Days: number;
    comparables30Days: number;
}

@Injectable()
export class AssetClassStatsService {
    API = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/asset-class-stats`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {}

    getStats(acType: number): Observable<StatsInfo> {
        const headers = this.authService.getAuthHeaders();
        const params = new HttpParams().set('ac_type', acType.toString())
        return this.http.get<StatsInfo>(this.API, {
            headers,
            params
        })
    }
}