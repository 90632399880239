// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'kt-delete-agency-dialog',
	templateUrl: './delete-agency-dialog.component.html'
})
export class DeleteAgencyDialogComponent implements OnInit {
	// Public properties
	viewLoading = false;
	title = '';
	subTitle = ''
	description1 = '';
	description2 = '';
	assignments_cnt = 0;
	toe_cnt = 0;
	toe_cnt_ongoing = 0;
	toe_cnt_completed = 0;
	toe_cnt_draft = 0;
	audits_cnt = 0;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<DeleteAgencyDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.data.assignments.length == 0) {
			this.description1 = 'This agency has no Assignments'
			this.description2 = 'Are you sure that you want to delete ' + this.data.description + ' agency?'
		} else {
			this.assignments_cnt = this.data.assignments.length
			this.audits_cnt = this.data.assignments.reduce((total, item) => total + item.audit_cnt, 0)
			this.toe_cnt_ongoing = this.data.assignments.reduce((total, item) => total + item.toe_cnt_ongoing, 0)
			this.toe_cnt_completed = this.data.assignments.reduce((total, item) => total + item.toe_cnt_complete, 0)
			this.toe_cnt_draft = this.data.assignments.reduce((total, item) => total + item.toe_cnt_draft, 0)
			this.toe_cnt = this.data.assignments.reduce((total, item) => total + item.toe_cnt, 0)

			this.description1 = 'This agency has:'
			this.description2 = 'Are you sure that you want to delete ' + this.data.description + ' agency?'

			if (this.toe_cnt_ongoing > 0 || this.toe_cnt_completed > 0) {
				this.subTitle = "You can't delete " + this.data.description + '.'
				this.description2 = 'Please delete these ToEs manually one by one, or return them to draft mode, if you wish to delete this agency.'
			}
		}
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		setTimeout(() => {
			this.dialogRef.close(true); // Keep only this row
		}, 2500);
	}
}
