import { Component, HostBinding, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

type UserInput = {
  firstName: string;
  lastName: string;
  picture: string;
  email: string;
  agency?: string;
  qualification: string;
  role?: string;
}

export type UserInfo = {
  name: string;
  profilePic: string;
  role?: string;
  qualification: string;
  email: string;
  agency?: string;
} 

@Component({
  selector: 'kt-user-tooltip',
  templateUrl: './user-tooltip.component.html',
  styleUrls: ['./user-tooltip.component.scss'],
})
export class UserTooltipComponent {
  private baseMediaURL = environment.baseMediaUrl

  @Input()
  set user(value: UserInput) {
    this._user$.next({
      name: `${value.firstName} ${value.lastName}`,
      profilePic: this.setPic(value.picture),
      role: value.role,
      qualification: value.qualification,
      email: value.email,
      agency: value.agency
    });
  }

  private _user$ = new BehaviorSubject<UserInfo>(null)
  user$ = this._user$.asObservable()

  private setPic(path: string) {
    if (path && path.length > 0) {
      return this.baseMediaURL + path
    }
    return './assets/media/users/default.jpg'
  }

  onImageError(e: any) {
    e.target.src = './assets/media/users/default.jpg'
  }
}