import {Action} from '@ngrx/store'
import { ValuationProcessDataModel } from '../_models'
import { PropertySubTypes } from '../_models/valuation-process-target-property.model'
import { ValidatedValuationModel } from '../_models/validated-valuation.model'
import { ValuationAdjustmentTabDataModel } from '../_models/valuation-adjustment-tab-data.model'

export enum ValuationProcessActionTypes {
    RequestValuationProcessData = '[Valuation Process Page] Fetch Valuation Process Data',
    ValuationProcessDataLoaded = '[Valuation Process API] Valuation Process Data Loaded',

    StoreValuationProcessData = '[Valuation Process Page] Save Valuation Process Data',

    UpdateValuationProcessSelectedConsideration = '[Comparable Overview Modal] Update Selected Tenure',

    AddComparableToValuation = '[Comparable Overview Modal] Add comparable to valuation process',
    RemoveComparableFromValuation = '[Comparable Overview Modal] Remove comparable from valuation process',
    ImportMultipleComparablesToValuation = '[Market Data Tab Page] Add multiple comparables to valuation process',
    UnimportValuation = '[Market Data Tab Page] Unimport valuation',

    ResetState = '[Valuation Process Page] Reset state of valuation process'
}

export class RequestValuationProcessData implements Action {
    readonly type = ValuationProcessActionTypes.RequestValuationProcessData

    constructor(public payload: {
        targetPropertyId: number,
        valuationId: number
    }) {}
}

export class StoreValuationProcessData implements Action {
    readonly type = ValuationProcessActionTypes.StoreValuationProcessData
    constructor(public payload: {
        targetPropertyId: number,
        valuationId: number,
        body: {
            validation: boolean,
            notes: any[],
            land_marks: number[],
            selected_refNums: string[],
            tenures: any[],
            adjustmentTab: any,
            assumptionTabData: any,
            propertySubTypes: number[],
            liquidationValuation: any
        }
    }) {}
}

export class ValuationProcessDataLoaded implements Action {
    readonly type = ValuationProcessActionTypes.ValuationProcessDataLoaded

    constructor(public payload: {
        data: ValuationProcessDataModel,
        comparables: {ref_num: string, selected_tenure_id: number}[],
        adjustmentTab: any,
    }) {}
}

export class UpdateValuationProcessSelectedConsideration implements Action {
    readonly type = ValuationProcessActionTypes.UpdateValuationProcessSelectedConsideration

    constructor(public payload: {
        refNum: string,
        id: number
    }) {}
}

export class AddComparableToValuationProcess implements Action {
    readonly type = ValuationProcessActionTypes.AddComparableToValuation

    constructor(public payload: {
        refNum: string,
    }) {}
}

export class ImportMultipleComparablestoValuationProcess implements Action {
    readonly type = ValuationProcessActionTypes.ImportMultipleComparablesToValuation

    constructor(public payload: {
        comparables: {
            refNum: string,
            tenureId: number
        }[],
        adjustmentsTabData: ValuationAdjustmentTabDataModel
    }) {}
}

export class UnimportValuationFromValuationProcess implements Action {
    readonly type = ValuationProcessActionTypes.UnimportValuation
    constructor() {}
}

export class RemoveComparableFromValuationProcess implements Action {
    readonly type = ValuationProcessActionTypes.RemoveComparableFromValuation

    constructor(public payload: {
        refNum: string,
    }) {}
}

export class ResetStateOfValuationProcess implements Action {
    readonly type = ValuationProcessActionTypes.ResetState
}

export type ValuationProcessActions
    = RequestValuationProcessData
    | ValuationProcessDataLoaded
    | UpdateValuationProcessSelectedConsideration
    | AddComparableToValuationProcess
    | RemoveComparableFromValuationProcess
    | ImportMultipleComparablestoValuationProcess
    | ResetStateOfValuationProcess
    | UnimportValuationFromValuationProcess