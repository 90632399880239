// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ExternalWallActions, ExternalWallActionTypes} from '../_actions/external-wall.actions';
// Models
import {ExternalWall} from '../_models/external-wall.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ExternalWallsState extends EntityState<ExternalWall> {
    isAllExternalWallsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ExternalWall[];
    lastCreatedExternalWallId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ExternalWall> = createEntityAdapter<ExternalWall>();

export const initialExternalWallsState: ExternalWallsState = adapter.getInitialState({
    isAllExternalWallsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedExternalWallId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function externalWallsReducer(state = initialExternalWallsState, action: ExternalWallActions): ExternalWallsState {
    switch (action.type) {
        case ExternalWallActionTypes.ExternalWallsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedExternalWallId: undefined
            };
        case ExternalWallActionTypes.ExternalWallsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ExternalWallActionTypes.ExternalWallOnServerCreated:
            return {
                ...state
            };
        case ExternalWallActionTypes.ExternalWallCreated:
            return adapter.addOne(action.payload.externalWall, {
                ...state, lastCreatedExternalWallId: action.payload.externalWall.id
            });
        case ExternalWallActionTypes.ExternalWallUpdated:
            return adapter.updateOne(action.payload.partialExternalWall, state);
        case ExternalWallActionTypes.AllExternalWallsLoaded:
            return adapter.addAll(action.payload.externalWalls, {
                ...state, isAllExternalWallsLoaded: true
            });
        case ExternalWallActionTypes.ExternalWallsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ExternalWallActionTypes.ExternalWallsPageLoaded:
            return adapter.addMany(action.payload.externalWalls, {
                ...initialExternalWallsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case ExternalWallActionTypes.ExternalWallDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case ExternalWallActionTypes.ExternalWallRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case ExternalWallActionTypes.ExternalWallDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case ExternalWallActionTypes.ExternalWallAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case ExternalWallActionTypes.ExternalWallDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case ExternalWallActionTypes.ExternalWallTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
