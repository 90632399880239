// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {IpmsComponentActions, IpmsComponentActionTypes} from '../_actions/ipms-component.actions';
// Models
import {IpmsComponent} from '../_models/ipms-component.model';
import {QueryParamsModel} from '../../_base/crud';

export interface IpmsComponentsState extends EntityState<IpmsComponent> {
    isAllIpmsComponentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: IpmsComponent[];
    lastCreatedIpmsComponentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<IpmsComponent> = createEntityAdapter<IpmsComponent>();

export const initialIpmsComponentsState: IpmsComponentsState = adapter.getInitialState({
    isAllIpmsComponentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedIpmsComponentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function ipmsComponentsReducer(state = initialIpmsComponentsState, action: IpmsComponentActions): IpmsComponentsState {
    switch (action.type) {
        case IpmsComponentActionTypes.IpmsComponentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedIpmsComponentId: undefined
            };
        case IpmsComponentActionTypes.IpmsComponentsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case IpmsComponentActionTypes.IpmsComponentOnServerCreated:
            return {
                ...state
            };
        case IpmsComponentActionTypes.IpmsComponentCreated:
            return adapter.addOne(action.payload.IpmsComponent, {
                ...state, lastCreatedIpmsComponentId: action.payload.IpmsComponent.id
            });
        case IpmsComponentActionTypes.IpmsComponentUpdated:
            return adapter.updateOne(action.payload.partialIpmsComponent, state);
        case IpmsComponentActionTypes.AllIpmsComponentsLoaded:
            return adapter.addAll(action.payload.IpmsComponents, {
                ...state, isAllIpmsComponentsLoaded: true
            });
        case IpmsComponentActionTypes.IpmsComponentsPageCancelled:
            return {
                ...state,
                listLoading: false,
                queryRowsCount: 0,
                queryResult: [],
                lastQuery: new QueryParamsModel({})
            };
        case IpmsComponentActionTypes.IpmsComponentsPageLoaded:
            return adapter.addMany(action.payload.IpmsComponents, {
                ...initialIpmsComponentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case IpmsComponentActionTypes.IpmsComponentDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case IpmsComponentActionTypes.IpmsComponentRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case IpmsComponentActionTypes.IpmsComponentDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case IpmsComponentActionTypes.IpmsComponentAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case IpmsComponentActionTypes.IpmsComponentDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case IpmsComponentActionTypes.IpmsComponentTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
