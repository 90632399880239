import {FloorNumberingScheme} from '../_models/floor-numbering-scheme.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {FloorNumberingSchemesState} from '../_reducers/floor-numbering-scheme.reducers';
import * as fromFloorNumberingScheme from '../_reducers/floor-numbering-scheme.reducers';
import {each} from 'lodash';

export const selectFloorNumberingSchemesState = createFeatureSelector<FloorNumberingSchemesState>('floorNumberingSchemes');

export const selectFloorNumberingSchemeById = (FloorNumberingSchemeId: number) => createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => FloorNumberingSchemesState.entities[FloorNumberingSchemeId]
);

export const selectAllFloorNumberingSchemes = createSelector(
    selectFloorNumberingSchemesState,
    fromFloorNumberingScheme.selectAll
);

export const selectAllFloorNumberingSchemesIds = createSelector(
    selectFloorNumberingSchemesState,
    fromFloorNumberingScheme.selectIds
);

export const allFloorNumberingSchemesLoaded = createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => FloorNumberingSchemesState.isAllFloorNumberingSchemesLoaded
);


export const selectFloorNumberingSchemesPageLoading = createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => FloorNumberingSchemesState.listLoading
);

export const selectFloorNumberingSchemesActionLoading = createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => FloorNumberingSchemesState.actionLoading
);

export const selectLastCreatedFloorNumberingSchemeId = createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => FloorNumberingSchemesState.lastCreatedFloorNumberingSchemeId
);

export const selectFloorNumberingSchemesShowInitWaitingMessage = createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => FloorNumberingSchemesState.showInitWaitingMessage
);

export const selectTrashedFloorNumberingSchemeCount = createSelector(
    selectFloorNumberingSchemesState,
    (FloorNumberingSchemesState) => FloorNumberingSchemesState.totalTrashed
);

export const selectAdminTrashedFloorNumberingSchemeCount = createSelector(
    selectFloorNumberingSchemesState,
    (FloorNumberingSchemesState) => FloorNumberingSchemesState.totalAdminTrashed
);


export const selectFloorNumberingSchemeQueryResult = createSelector(
    selectFloorNumberingSchemesState,
    FloorNumberingSchemesState => {
        const items: FloorNumberingScheme[] = [];
        each(FloorNumberingSchemesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: FloorNumberingScheme[] = httpExtension.sortArray(items, FloorNumberingSchemesState.lastQuery.sortField, FloorNumberingSchemesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, FloorNumberingSchemesState.totalCount, '', FloorNumberingSchemesState.totalTrashed);
    }
);
