// Angular
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// Partials for CRUD
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    DeleteAgencyDialogComponent,
    DeleteEntityWithDescrDialogComponent,
    SaveEntityDialogComponent,
    SaveEntityWithDescrDialogComponent,
    FetchEntityDialogComponent,
    TrashDialogComponent,
    TrashAuditTrailDialogComponent,
    TermsDialogComponent,
    UpdateStatusDialogComponent,
    ErrorDialogComponent
} from '../../../../views/partials/content/crud';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { AssetClassBuildingInformation } from 'src/app/core/comparable';
import { EditEntityDialogComponent, EditEntityDialogData, EditEntityDialogReturn } from 'src/app/views/partials/content/crud/edit-entity-dialog/edit-entity-dialog.component';

export enum MessageType {
    Create,
    Read,
    Update,
    Delete
}

@Injectable()
export class LayoutUtilsService {
    /**
     * Service constructor
     *
     * @param snackBar: MatSnackBar
     * @param http: HttpClient
     * @param dialog: MatDialog
     */
    constructor(private snackBar: MatSnackBar,
                private http: HttpClient,
                private dialog: MatDialog) {
    }

    /**
     * Showing (Mat-Snackbar) Notification
     *
     * @param _message: string
     * @param _type: MessageType
     * @param _duration: number
     * @param _showCloseButton: boolean
     * @param _showUndoButton: boolean
     * @param _undoButtonDuration: number
     * @param _verticalPosition: 'top' | 'bottom' = 'top'
     */
    showActionNotification(_message: string,
                           _type: MessageType = MessageType.Create,
                           _duration: number = 5000,
                           _showCloseButton: boolean = true,
                           _showUndoButton: boolean = false,
                           _undoButtonDuration: number = 3000,
                           _verticalPosition: 'top' | 'bottom' = 'bottom') {
        const _data = {
            message: _message,
            snackBar: this.snackBar,
            showCloseButton: _showCloseButton,
            showUndoButton: _showUndoButton,
            undoButtonDuration: _undoButtonDuration,
            verticalPosition: _verticalPosition,
            type: _type,
            action: 'Undo'
        };
        return this.snackBar.openFromComponent(ActionNotificationComponent, {
            duration: _duration,
            data: _data,
            verticalPosition: _verticalPosition
        });
    }


    showUserNotification(_message: string, showAction) {
        const _data = {
            message: _message,
            snackBar: this.snackBar,
            undoButtonDuration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            type: MessageType.Create,
            action: showAction ? 'Go' : null
        };

        // snack-bar-ui
        // this.snackBar.open()
        return this.snackBar.open(_message, 'Go', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snack-bar-ui'],
            data: _data
        });
    }


    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDesciption: string
     */
    deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
        return this.dialog.open(DeleteEntityDialogComponent, {
            data: {title, description, waitDesciption},
            width: '440px'
        });
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDesciption: string
     */
     deleteAgency(title: string = '', description: string = '', waitDesciption: string = '', assignments: any) {
        return this.dialog.open(DeleteAgencyDialogComponent, {
            data: {title, description, waitDesciption, assignments},
            width: '440px'
        });
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDesciption: string
     */
    deleteElementWithDescr(title: string = '', description: string = '', waitDesciption: string = '') {
        return this.dialog.open(DeleteEntityWithDescrDialogComponent, {
            data: {title, description, waitDesciption},
            width: '440px'
        });
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDesciption: string
     */
    saveElement(title: string = '', description: string = '', waitDesciption: string = '') {
        return this.dialog.open(SaveEntityDialogComponent, {
            data: {title, description, waitDesciption},
            width: '440px'
        });
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDesciption: string
     * @param fileTitle: string
     * @param descriptionField: boolean
     * @param fileDescription: string
     * @param descriptionTooltip: any
     * @param checkBox: boolean
     * @param fileChecked: boolean
     * @param featuredPictureAllowed: boolean
     * @param isFeatured: boolean
     */
    saveElementWithInfo(title: string = '',
                        description: string = '',
                        waitDesciption: string = '',
                        fileTitle: string = '',
                        descriptionField: boolean = false,
                        fileDescription: string = '',
                        descriptionTooltip: { desc: string, title: string } = null,
                        checkBox: boolean = false,
                        fileChecked: boolean = false,
                        featuredPictureAllowed: boolean = false,
                        isFeatured: boolean = false,
                        dropDownValues: {
                            key: number,
                            value: string,
                        }[] = [],
                        currentDropDownValue: string = '',
                        buildingField: boolean = false,
                        building: string = '',
                        buildingList: AssetClassBuildingInformation[] = [],) {
        return this.dialog.open(SaveEntityWithDescrDialogComponent, {
            data: {
                title, description, waitDesciption, fileTitle, descriptionField, fileDescription,
                checkBox, checkBoxValue: fileChecked, featuredPictureAllowed, isFeatured, descriptionTooltip,
                dropDownValues, currentDropDownValue, buildingField, building, buildingList
            },
            width: '440px',
            // position: {top: '50px', right: '50px'}
        });
    }

    /**
     * Showing Fetching Window(Mat-Dialog)
     *
     * @param _data: any
     * @param _action: string
     */
    fetchElements(_data, _action = null) {
        return this.dialog.open(FetchEntityDialogComponent, {
            data: {title: _data.title, items: _data.items, action: _action},
            width: '400px'
        });
    }

    /**
     * Showing Update Status for Entites Window
     *
     * @param title: string
     * @param statuses: string[]
     * @param messages: string[]
     */
    updateStatusForEntities(title, statuses, messages) {
        return this.dialog.open(UpdateStatusDialogComponent, {
            data: {title, statuses, messages},
            width: '480px'
        });
    }

    trashedEntities(title, items, action, admin = false) {
        return this.dialog.open(TrashDialogComponent, {
            data: {title, items, action, admin}
        });
    }

    trashedAuditTrailEntities(title, items, action) {
        return this.dialog.open(TrashAuditTrailDialogComponent, {
            data: {title, items, action}
        });
    }

    showTerms() {
        return this.dialog.open(TermsDialogComponent);
    }

    showErrorDialg(title: string, description: string, action: string) {
        return this.dialog.open(ErrorDialogComponent, {
            data: {
                title, description, action
            },
            width: '440px'
        });
    }

    getWhat3Word(latLng: google.maps.LatLng): Observable<any> {
        const url = 'https://api.what3words.com/v2/reverse?coords=' +
            latLng.lat() + ',' + latLng.lng() + '&display=full&format=json&key=G6C4BV33&lang=en';
        return this.http.get<any>(url);
    }

    editElementWithInfo(data: EditEntityDialogData) {
        return this.dialog.open<EditEntityDialogComponent, EditEntityDialogData, EditEntityDialogReturn>(EditEntityDialogComponent, {
            width: '440px',
            disableClose: true,
            data 
        });
    }
}

export type EditElementWithInfoDialogRef = MatDialogRef<EditEntityDialogComponent, EditEntityDialogReturn>;
