import { CriterionCategories } from "./criterion-categories.model";
import { CriterionType } from "./criterion-type.model";

export class CriterionModel {
    id: number;
    name: string;
    category: CriterionCategories;
    categoryName: string;
    criterion: string;

    type: CriterionType;

    active: boolean;

    comValues: {[id: number]: any};
    comConsideration: {[id: number]: ConsiderationConversionData}
    tpValue: any;

    isChanged: boolean;
    order_num: number;

    canRemove: boolean;

    static copy(criterion: CriterionModel): CriterionModel {
        const _tmp = Object.assign({}, criterion) as CriterionModel;
        const _val = Object.assign({}, _tmp.comValues);
        const _consid = Object.assign({}, _tmp.comConsideration);
        _tmp.comValues = _val;
        _tmp.comConsideration = _consid;
        return _tmp 
    }

    static copyWithChanged(old: CriterionModel, n: CriterionModel): CriterionModel {
        const _tmp = Object.assign({}, old) as CriterionModel;
        const _consid = Object.assign({}, _tmp.comConsideration);
        _tmp.comConsideration = _consid;
        // tp
        if (n.tpValue != old.tpValue) {
            _tmp.tpValue = n.tpValue;
        } else {
            _tmp.tpValue = old.tpValue;
        }
        const _val = Object.assign({}, _tmp.comValues);
        _tmp.comValues = _val;
        return _tmp;
    }
}

export type ConsiderationConversionData = {
    process: ConsiderationConversionProcess,
    methods: {
        mainMethod: number,
        subMethod: number,
    },
    rateValues: {
        capRate: number,
        capRateJustification: string,
        targetRate: number,
        targetRateJustification: string,
    },
    cfmRateValues: {
        targetRate: number,
        targetRateJustification: string,
        growthRate: number,
        growthRateJustification: string,
        effectiveRentJustification: string,
    },
    selected: {
        main: boolean,
        index: number,
    },
    ary: number,
}

export enum ConsiderationConversionProcess {
    NoProcess = 0,
    HeadlineToEffective = 1,
    LeaseholdToFreehold = 2,
    FreeholdToLeasehold = 3,
    HeadlineToEffectiveToLeaseholdToFreehold = 4,
}