import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { awConst } from 'src/app/app.constants';

export type SliderImageViewerInput = {
  images: Array<{
    picture: string;
    type: string,
    img: any
  }>,
  index: string;
}

@Component({
  selector: 'kt-slider-image-viewer',
  templateUrl: './slider-image-viewer.component.html',
  styleUrls: ['./slider-image-viewer.component.scss']
})
export class SliderImageViewerComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SliderImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SliderImageViewerInput
  ) { }

  ngOnInit(): void {}

  onImgError(e: any) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
