// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {IpmsStandardsService} from '../_services/ipms-standard.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allIpmsStandardsLoaded} from '../_selectors/ipms-standard.selectors';
// Actions
import {
    AllIpmsStandardsLoaded,
    AllIpmsStandardsRequested,
    IpmsStandardActionTypes,
    IpmsStandardsPageRequested,
    IpmsStandardsPageLoaded,
    IpmsStandardUpdated,
    IpmsStandardsPageToggleLoading,
    IpmsStandardDeleted,
    IpmsStandardOnServerCreated,
    IpmsStandardCreated,
    IpmsStandardAdminRestored,
    IpmsStandardDeletedFromAdminTrash,
    IpmsStandardDeletedFromTrash,
    IpmsStandardOnServerAdminRestored,
    IpmsStandardOnServerRestored,
    IpmsStandardRestored,
    IpmsStandardTrashFlushed,
    IpmsStandardsActionToggleLoading
} from '../_actions/ipms-standard.actions';

@Injectable()
export class IpmsStandardEffects {
    showPageLoadingDispatcher = new IpmsStandardsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new IpmsStandardsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new IpmsStandardsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllIpmsStandards$ = this.actions$
        .pipe(
            ofType<AllIpmsStandardsRequested>(IpmsStandardActionTypes.AllIpmsStandardsRequested),
            withLatestFrom(this.store.pipe(select(allIpmsStandardsLoaded))),
            filter(([action, isAllIpmsStandardsLoaded]) => !isAllIpmsStandardsLoaded),
            mergeMap(() => this.service.getAllIpmsStandards()),
            map(res => {
                return new AllIpmsStandardsLoaded({ipmsStandards: res.data});
            })
        );

    @Effect()
    loadIpmsStandardsPage$ = this.actions$
        .pipe(
            ofType<IpmsStandardsPageRequested>(IpmsStandardActionTypes.IpmsStandardsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findIpmsStandards(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new IpmsStandardsPageLoaded({
                    ipmsStandards: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardDeleted>(IpmsStandardActionTypes.IpmsStandardDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteIpmsStandard(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardUpdated>(IpmsStandardActionTypes.IpmsStandardUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateIpmsStandard(payload.ipmsStandard);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardOnServerCreated>(IpmsStandardActionTypes.IpmsStandardOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createIpmsStandard(payload.ipmsStandard).pipe(
                    tap(res => {
                        this.store.dispatch(new IpmsStandardCreated({ipmsStandard: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushIpmsStandardTrash$ = this.actions$
        .pipe(
            ofType<IpmsStandardTrashFlushed>(IpmsStandardActionTypes.IpmsStandardTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardOnServerRestored>(IpmsStandardActionTypes.IpmsStandardOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IpmsStandardRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardOnServerAdminRestored>(IpmsStandardActionTypes.IpmsStandardOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IpmsStandardAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardDeletedFromTrash>(IpmsStandardActionTypes.IpmsStandardDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashIpmsStandard$ = this.actions$
        .pipe(
            ofType<IpmsStandardDeletedFromAdminTrash>(IpmsStandardActionTypes.IpmsStandardDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: IpmsStandardsService, private store: Store<AppState>) {
    }
}
