import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { 
    // InterceptService, 
    LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { PartialsModule } from '../../partials/partials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Routing
import { LinkedTablesRoutingModule } from './linked-tables.routing.module';
// Pipes
import { FilterPipe } from './all-linked-tables/filter.pipe';
import { NestedLabelPipe } from './all-linked-tables/filter-by-nested.pipe';
// Material
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
// NGRX Store
import { 
    ResidentialAdjustmentsService,
    ApproachesToValuesService,
    AssetClassTypesService,
    AssetClassValuationGroupsService,
    BaseOfValuesService,
    BuildingTypesService,
    ConstructionTypesService,
    DefaultAssumptionsService,
    DeparturesService,
    ExternalAreasService,
    ExternalWallsService,
    FacilitiesService,
    FloorTypesService,
    FoundationTypesService,
    GradesService,
    HandoverStandardsService,
    IncludedInValuationsService,
    InteractionTypesService,
    IpmsStandardsService,
    IvsStandardsService,
    KeyCategoriesService,
    MeasurementMethodologiesService,
    MethodsToValuesService,
    OfficeLayoutsService,
    OfficeStandardMeasurementsService,
    PremiseOfValuesService,
    PropertyTypesService,
    PurposeMeasurementsService,
    PurposeOfValuationsService,
    RentReviewCommentsService,
    ReportDeliveryMethodsService,
    ResidentialTypesService,
    RicsStandardsService,
    SourceCredibilityService,
    SourceExternalReferencesService,
    SourceInformationService,
    SourceTypesService,
    SpecialAssumptionsService,
    StandardMeasurementsService,
    StateRepairsService,
    TaskEffortsService,
    TenureService,
    TypeOfRegistrationsService,
    TypeOfTitlesService,
    UnitAreaMeasurementsService,
    UnitMeasurementsService,
    ValuationStandardService, 
    ValuerQualificationsService, 
    WindowTypesService,
    OfficeAdjustmentsService,
    BuildingUseStatussService, 
    ComparativeLandUsesService, 
    CoordinateReferenceSystemsService, 
    LandTenuresService, 
    ParcelCharacteristicsService, 
    PlaningStatusesService, 
    QosDegradationsService, 
    SolUseClassificationsService, 
    UnitLengthMeasurementsService,
    BuiltinFittingsService,
    CeilingsService,
    CubicContentsService,
    RoofStructuresService,
    StorageTypesService,
    WallsAndPartitionsService,
    ChimneyStackService,
    RoofCoveringService,
    ConservatoryPorcheService,
    MainWallService,
    OtherJoineryService,
    OutsideDoorService,
    RainwaterPipeService,
    GardenService,
    OutdoorSpaceService,
    FireplacesChimneyBreastsService,
    BathroomFittingsService,
    ShopFrontTypeService,
    ExternalFacadeService,
    FloorNumberingSchemesService,
    OrganisationService,
    CertificationService,
    PaymentTypesService,
    AdditionalCostTitleService,
} from 'src/app/core/linked-tables';
// Components
import { LinkedTablesComponent } from './linked-tables.component';
import { AllLinkedTablesComponent } from './all-linked-tables/all-linked-tables.component';
import { ValuationStandardsListComponent } from './valuation-standars-list/valuation-standards-list.component';
import { EditPointDialogComponent } from '../../partials/content/crud';
import { ValuationStandardEditComponent } from './valuation-standards-edit/valuation-standards-edit.component';
import { BaseOfValuesListComponent } from './base-of-values-list/base-of-values-list.component';
import { BaseOfValueEditComponent } from './base-of-value-edit/base-of-value-edit.component';
import { InteractionTypesListComponent } from './interaction-types-list/interaction-types-list.component';
import { InteractionTypeEditComponent } from './interaction-type-edit/interaction-type-edit.component';
import { BuildingTypeListComponent } from './building-type-list/building-type-list.component';
import { BuildingTypeEditComponent } from './building-type-edit/building-type-edit.component';
import { PremiseOfValuesListComponent } from './premise-of-values-list/premise-of-values-list.component';
import { PremiseOfValueEditComponent } from './premise-of-value-edit/premise-of-value-edit.component';
import { PurposeOfValuationsListComponent } from './purpose-of-valuations-list/purpose-of-valuations-list.component';
import { PurposeOfValuationEditComponent } from './purpose-of-valuation-edit/purpose-of-valuation-edit.component';
import { ReportDeliveryMethodsListComponent } from './report-delivery-methods-list/report-delivery-methods-list.component';
import { ReportDeliveryMethodEditComponent } from './report-delivery-method-edit/report-delivery-method-edit.component';
import { SourceExternalReferencesListComponent } from './source-external-references-list/source-external-references-list.component';
import { SourceExternalReferenceEditComponent } from './source-external-reference-edit/source-external-reference-edit.component';
import { MeasurementMethodologiesListComponent } from './measurement-methodologies-list/measurement-methodologies-list.component';
import { MeasurementMethodologyEditComponent } from './measurement-methodology-edit/measurement-methodology-edit.component';
import { ApproachesToValueEditComponent } from './approaches-to-value-edit/approaches-to-value-edit.component';
import { ApproachesToValuesListComponent } from './approaches-to-values-list/approaches-to-values-list.component';
import { AssetClassTypeEditComponent } from './asset-class-type-edit/asset-class-type-edit.component';
import { DefaultAssumptionEditComponent } from './default-assumption-edit/default-assumption-edit.component';
import { DefaultAssumptionsListComponent } from './default-assumptions-list/default-assumptions-list.component';
import { DepartureEditComponent } from './departure-edit/departure-edit.component';
import { DeparturesListComponent } from './departures-list/departures-list.component';
import { ExternalAreaEditComponent } from './external-area-edit/external-area-edit.component';
import { ExternalAreasListComponent } from './external-areas-list/external-areas-list.component';
import { ExternalWallEditComponent } from './external-wall-edit/external-wall-edit.component';
import { ExternalWallsListComponent } from './external-walls-list/external-walls-list.component';
import { FacilitiesListComponent } from './facilities-list/facilities-list.component';
import { FacilityEditComponent } from './facility-edit/facility-edit.component';
import { FloorTypeEditComponent } from './floor-type-edit/floor-type-edit.component';
import { FloorTypesListComponent } from './floor-types-list/floor-types-list.component';
import { FoundationTypeEditComponent } from './foundation-type-edit/foundation-type-edit.component';
import { FoundationTypesListComponent } from './foundation-types-list/foundation-types-list.component';
import { GradeEditComponent } from './grade-edit/grade-edit.component';
import { GradeListComponent } from './grade-list/grade-list.component';
import { HandoverStandardEditComponent } from './handover-standard-edit/handover-standard-edit.component';
import { HandoverStandardListComponent } from './handover-standard-list/handover-standard-list.component';
import { IncludedInValuationEditComponent } from './included-in-valuation-edit/included-in-valuation-edit.component';
import { IncludedInValuationsListComponent } from './included-in-valuations-list/included-in-valuations-list.component';
import { IpmsStandardEditComponent } from './ipms-standard-edit/ipms-standard-edit.component';
import { IpmsStandardsListComponent } from './ipms-standards-list/ipms-standards-list.component';
import { IvsStandardEditComponent } from './ivs-standard-edit/ivs-standard-edit.component';
import { IvsStandardsListComponent } from './ivs-standards-list/ivs-standards-list.component';
import { KeyCategoryEditComponent } from './key-category-edit/key-category-edit.component';
import { KeyCategoryListComponent } from './key-category-list/key-category-list.component';
import { MethodsToValueEditComponent } from './methods-to-value-edit/methods-to-value-edit.component';
import { MethodsToValuesListComponent } from './methods-to-values-list/methods-to-values-list.component';
import { OfficeLayoutEditComponent } from './office-layout-edit/office-layout-edit.component';
import { OfficeLayoutsListComponent } from './office-layouts-list/office-layouts-list.component';
import { OfficeStandardMeasurementEditComponent } from './office-standard-measurement-edit/office-standard-measurement-edit.component';
import { OfficeStandardMeasurementsListComponent } from './office-standard-measurements-list/office-standard-measurements-list.component';
import { PropertyTypeEditComponent } from './property-type-edit/property-type-edit.component';
import { PropertyTypesListComponent } from './property-types-list/property-types-list.component';
import { PurposeMeasurementEditComponent } from './purpose-measurement-edit/purpose-measurement-edit.component';
import { PurposeMeasurementsListComponent } from './purpose-measurements-list/purpose-measurements-list.component';
import { RentReviewCommentEditComponent } from './rent-review-comment-edit/rent-review-comment-edit.component';
import { RentReviewCommentListComponent } from './rent-review-comment-list/rent-review-comment-list.component';
import { ResidentialTypeEditComponent } from './residential-type-edit/residential-type-edit.component';
import { ResidentialTypeListComponent } from './residential-type-list/residential-type-list.component';
import { RicsStandardEditComponent } from './rics-standard-edit/rics-standard-edit.component';
import { RicsStandardsListComponent } from './rics-standards-list/rics-standards-list.component';
import { SourceCredibilityEditComponent } from './source-credibility-edit/source-credibility-edit.component';
import { SourceCredibilityListComponent } from './source-credibility-list/source-credibility-list.component';
import { SourceInformationEditComponent } from './source-information-edit/source-information-edit.component';
import { SourceInformationListComponent } from './source-information-list/source-information-list.component';
import { SourceTypeEditComponent } from './source-type-edit/source-type-edit.component';
import { SourceTypeListComponent } from './source-type-list/source-type-list.component';
import { SpecialAssumptionEditComponent } from './special-assumption-edit/special-assumption-edit.component';
import { SpecialAssumptionsListComponent } from './special-assumptions-list/special-assumptions-list.component';
import { StandardMeasurementEditComponent } from './standard-measurement-edit/standard-measurement-edit.component';
import { StandardMeasurementsListComponent } from './standard-measurements-list/standard-measurements-list.component';
import { StateOfRepairEditComponent } from './state-of-repair-edit/state-of-repair-edit.component';
import { StateOfRepairListComponent } from './state-of-repair-list/state-of-repair-list.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TenureEditComponent } from './tenure-edit/tenure-edit.component';
import { TenureListComponent } from './tenure-list/tenure-list.component';
import { TypeOfRegistrationEditComponent } from './type-of-registration-edit/type-of-registration-edit.component';
import { TypeOfRegistrationListComponent } from './type-of-registration-list/type-of-registration-list.component';
import { TypeOfTitleEditComponent } from './type-of-title-edit/type-of-title-edit.component';
import { TypeOfTitleListComponent } from './type-of-title-list/type-of-title-list.component';
import { UnitAreaMeasurementEditComponent } from './unit-area-measurement-edit/unit-area-measurement-edit.component';
import { UnitAreaMeasurementsListComponent } from './unit-area-measurements-list/unit-area-measurements-list.component';
import { UnitLengthMeasurementEditComponent } from './unit-length-measurement-edit/unit-length-measurement-edit.component';
import { UnitLengthMeasurementsListComponent } from './unit-length-measurements-list/unit-length-measurements-list.component';
import { UnitMeasurementEditComponent } from './unit-measurement-edit/unit-measurement-edit.component';
import { UnitMeasurementsListComponent } from './unit-measurements-list/unit-measurements-list.component';
import { WindowTypeEditComponent } from './window-type-edit/window-type-edit.component';
import { WindowTypesListComponent } from './window-types-list/window-types-list.component';
import { AssetClassTypesListComponent } from './asset-class-types-list/asset-class-types-list.component';
import { CanDeactivateGuard } from 'src/app/core/auth';
import { AdjustmentsEditComponent } from './adjustments-edit/adjustments-edit.component';
import { AdjustmentsListComponent } from './adjustments-list/adjustments-list.component';
import { OfficeAdjustmentEditComponent } from './office-adjustment-edit/office-adjustment-edit.component';
import { OfficeAdjustmentsListComponent } from './office-adjustments-list/office-adjustments-list.component';

import { BuildingUseStatusEditComponent } from './building-use-status-edit/building-use-status-edit.component';
import { BuildingUseStatusesListComponent } from './building-use-statuses-list/building-use-statuses-list.component';
import { ComparativeLandUseEditComponent } from './comparative-land-use-edit/comparative-land-use-edit.component';
import { ComparativeLandUsesListComponent } from './comparative-land-uses-list/comparative-land-uses-list.component';
import { CoordinateReferenceSystemEditComponent } from './coordinate-reference-system-edit/coordinate-reference-system-edit.component';
import { CoordinateReferenceSystemsListComponent } from './coordinate-reference-systems-list/coordinate-reference-systems-list.component';
import { LandTenureEditComponent } from './land-tenure-edit/land-tenure-edit.component';
import { LandTenuresListComponent } from './land-tenures-list/land-tenures-list.component';
import { ParcelCharacteristicEditComponent } from './parcel-characteristic-edit/parcel-characteristic-edit.component';
import { ParcelCharacteristicsListComponent } from './parcel-characteristics-list/parcel-characteristics-list.component';
import { PlaningStatusEditComponent } from './planing-status-edit/planing-status-edit.component';
import { PlaningStatusesListComponent } from './planing-statuses-list/planing-statuses-list.component';
import { QosDegradationEditComponent } from './qos-degradation-edit/qos-degradation-edit.component';
import { QosDegradationsListComponent } from './qos-degradations-list/qos-degradations-list.component';
import { SolUseClassificationEditComponent } from './sol-use-classification-edit/sol-use-classification-edit.component';
import { SolUseClassificationsListComponent } from './sol-use-classifications-list/sol-use-classifications-list.component';
import { LinkedTablesStoreModule } from 'src/app/core/linked-tables/linked-tables-store.module';
import { IpmsComponentListComponent } from './ipms-component-list/ipms-component-list.component';
import { IpmsComponentsService } from 'src/app/core/linked-tables/_services/ipms-component.service';
import { IpmsComponentEditComponent } from './ipms-component-edit/ipms-component-edit.component';
import { TopPropertyTypeService } from 'src/app/core/linked-tables/_services/top-property-type.service';
import { TopPropertyTypesListComponent } from './top-property-types-list/top-property-types-list.component';
import { TopPropertyTypeEditComponent } from './top-property-type-edit/top-property-type-edit.component';
import { SubTypeCategoryService } from 'src/app/core/linked-tables/_services/sub-type-categories.service';
import { SubTypeCategoriesListComponent } from './sub-type-categories-list/sub-type-categories-list.component';
import { SubTypeCategoryEditComponent } from './sub-type-category-edit/sub-type-category-edit.component';
import { SubCategoryService } from 'src/app/core/linked-tables/_services/sub-category.service';
import { SubCategoriesListComponent } from './sub-categories-list/sub-categories-list.component';
import { SubCategoryEditComponent } from './sub-category-edit/sub-category-edit.component';
import { BuiltinFittingsListComponent } from './builtin-fittings-list/builtin-fittings-list.component';
import { BuiltinFittingEditComponent } from './builtin-fitting-edit/builtin-fitting-edit.component';
import { CeilingsListComponent } from './ceilings-list/ceilings-list.component';
import { CeilingEditComponent } from './ceiling-edit/ceiling-edit.component';
import { FireplacesChimneyBreastsListComponent } from './fireplaces-chimney-breasts-list/fireplaces-chimney-breasts-list.component';
import { FireplacesChimneyBreastEditComponent } from './fireplaces-chimney-breast-edit/fireplaces-chimney-breast-edit.component';
import { RoofStructuresListComponent } from './roof-structures-list/roof-structures-list.component';
import { RoofStructureEditComponent } from './roof-structure-edit/roof-structure-edit.component';
import { StorageTypesListComponent } from './storage-types-list/storage-types-list.component';
import { StorageTypeEditComponent } from './storage-type-edit/storage-type-edit.component';
import { ExternalFacadesListComponent} from './external-facade-list/external-facade-list.component';
import { WallsAndPartitionEditComponent } from './walls-and-partition-edit/walls-and-partition-edit.component';
import { ChimneyStackEditComponent } from './chimney-stack-edit/chimney-stack-edit.component';
import { ChimneyStacksListComponent } from './chimney-stacks-list/chimney-stacks-list.component';
import { RoofCoveringsListComponent } from './roof-coverings-list/roof-coverings-list.component';
import { RoofCoveringEditComponent } from './roof-covering-edit/roof-covering-edit.component';
import { ConservatoryPorchesListComponent } from './conservatory-porches-list/conservatory-porches-list.component';
import { ConservatoryPorcheEditComponent } from './conservatory-porche-edit/conservatory-porche-edit.component';
import { MainWallsListComponent } from './main-walls-list/main-walls-list.component';
import { MainWallEditComponent } from './main-wall-edit/main-wall-edit.component';
import { OtherJoineriesListComponent } from './other-joineries-list/other-joineries-list.component';
import { OtherJoineryEditComponent } from './other-joinery-edit/other-joinery-edit.component';
import { OutsideDoorsListComponent } from './outside-doors-list/outside-doors-list.component';
import { OutsideDoorEditComponent } from './outside-door-edit/outside-door-edit.component';
import { RainwaterPipesListComponent } from './rainwater-pipes-list/rainwater-pipes-list.component';
import { RainwaterPipeEditComponent } from './rainwater-pipe-edit/rainwater-pipe-edit.component';
import { GardenEditComponent } from './garden-edit/garden-edit.component';
import { GardensListComponent } from './gardens-list/gardens-list.component';
import { OutdoorSpaceEditComponent } from './outdoor-space-edit/outdoor-space-edit.component';
import { OutdoorSpacesListComponent } from './outdoor-spaces-list/outdoor-spaces-list.component';
import { BathroomFittingEditComponent } from './bathroom-fitting-edit/bathroom-fitting-edit.component';
import { BathroomFittingsListComponent } from './bathroom-fittings-list/bathroom-fittings-list.component';
import { HouseAdjustmentsService } from 'src/app/core/linked-tables/_services/house-adjustment.service';
import { HouseAdjustmentEditComponent } from './house-adjustment-edit/house-adjustment-edit.component';
import { HouseAdjustmentListComponent } from './house-adjustment-list/house-adjustment-list.component';
import { WarehouseAdjustmentsService } from 'src/app/core/linked-tables/_services/warehouse-adjustment.service';
import { WarehouseAdjustmentEditComponent } from './warehouse-adjustment-edit/warehouse-adjustment-edit.component';
import { WarehouseAdjustmentListComponent } from './warehouse-adjustment-list/warehouse-adjustment-list.component';
import { RetailBuildingAdjustmentsService } from 'src/app/core/linked-tables/_services/retail-building-adjustment.service';
import { RetailBuildingAdjustmentEditComponent } from './retail-building-adjustment-edit/retail-building-adjustment-edit.component';
import { RetailBuildingAdjustmentListComponent } from './retail-building-adjustment-list/retail-building-adjustment-list.component';
import { ShopFrontTypesListComponent } from './shop-front-types-list/shop-front-types-list.component';
import { ShopFrontTypeEditComponent } from './shop-front-type-edit/shop-front-type-edit.component';
import { ParkingTypeService } from 'src/app/core/linked-tables/_services/parking-type.service';
import { ParkingTypeEditComponent } from './parking-type-edit/parking-type-edit.component';
import { ParkingTypesListComponent } from './parking-types-list/parking-types-list.component';
import { RetailStoreAdjustmentsService } from 'src/app/core/linked-tables/_services/retail-store-adjustment.service';
import { ParkingAdjustmentsService } from 'src/app/core/linked-tables/_services/parking-adjustment.service';
import { LandAdjustmentsService } from 'src/app/core/linked-tables/_services/land-adjustment.service';
import { RetailStoreAdjustmentListComponent } from './retail-store-adjustment-list/retail-store-adjustment-list.component';
import { RetailStoreAdjustmentEditComponent } from './retail-store-adjustment-edit/retail-store-adjustment-edit.component';
import { ParkingAdjustmentListComponent } from './parking-adjustment-list/parking-adjustment-list.component';
import { ParkingAdjustmentEditComponent } from './parking-adjustment-edit/parking-adjustment-edit.component';
import { LandAdjustmentListComponent } from './land-adjustment-list/land-adjustment-list.component';
import { LandAdjustmentEditComponent } from './land-adjustment-edit/land-adjustment-edit.component';
import { ExternalFacadeEditComponent } from './external-facade-edit/external-facade-edit.component';
import { WallsAndPartitionsListComponent } from './walls-and-partitions-list/walls-and-partitions-list.component';
import { SharedModule } from '../shared_components/shared.module';
import { FloorNumberingSchemeEditComponent } from './floor-numbering-scheme-edit/floor-numbering-scheme-edit.component';
import { FloorNumberingSchemesListComponent } from './floor-numbering-schemes-list/floor-numbering-schemes-list.component';
import { OrganisationsListComponent } from './organisation-list/organisations-list.component';
import { OrganisationEditComponent } from './organisation-edit/organisation-edit.component';
import { CertificationsListComponent } from './certification-list/certifications-list.component';
import { CertificationEditComponent } from './certification-edit/certification-edit.component';
import { AdditionalCostTitleEditComponent } from './additiona-cost-title-edit/additional-cost-title-edit.component';
import { AdditionalCostTitlesListComponent } from './additional-cost-titles-list/additional-cost-titles-list.component';
import { RentBasesListComponent } from './rent-bases-list/rent-bases-list.component';
import { RentBasisEditComponent } from './rent-basis-edit/rent-basis-edit.component';
import { RentReviewTypesListComponent } from './rent-review-types-list/rent-review-types-list.component';
import { RentReviewTypeEditComponent } from './rent-review-type-edit/rent-review-type-edit.component';
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        PartialsModule,
        LinkedTablesRoutingModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatListModule,
        LinkedTablesStoreModule,
        SharedModule
    ],
    providers: [
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                maxHeight: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        CanDeactivateGuard,
        // Store Services
        InteractionTypesService,
        PurposeOfValuationsService,
        ReportDeliveryMethodsService,
        SourceExternalReferencesService,
        BaseOfValuesService,
        PremiseOfValuesService,
        MeasurementMethodologiesService,
        PurposeMeasurementsService,
        ApproachesToValuesService,
        DefaultAssumptionsService,
        DeparturesService,
        IncludedInValuationsService,
        MethodsToValuesService,
        SpecialAssumptionsService,
        ValuationStandardService,
        AssetClassTypesService,
        StandardMeasurementsService,
        OfficeStandardMeasurementsService,
        IpmsStandardsService,
        IvsStandardsService,
        RicsStandardsService,
        UnitMeasurementsService,
        UnitAreaMeasurementsService,
        ExternalAreasService,
        HandoverStandardsService,
        GradesService,
        TypeOfRegistrationsService,
        ResidentialTypesService,
        FacilitiesService,
        SourceTypesService,
        SourceInformationService,
        SourceCredibilityService,
        BuildingTypesService,
        ConstructionTypesService,
        StateRepairsService,
        TypeOfTitlesService,
        KeyCategoriesService,
        RentReviewCommentsService,
        FloorTypesService,
        WindowTypesService,
        ExternalWallsService,
        FoundationTypesService,
        AssetClassValuationGroupsService,
        ValuerQualificationsService,
        PropertyTypesService,
        TenureService,
        TaskEffortsService,
        OfficeLayoutsService,
        ResidentialAdjustmentsService,
        OfficeAdjustmentsService,
        BuildingUseStatussService,
        ComparativeLandUsesService,
        CoordinateReferenceSystemsService,
        LandTenuresService,
        ParcelCharacteristicsService,
        PlaningStatusesService,
        QosDegradationsService,
        SolUseClassificationsService,
        UnitLengthMeasurementsService,
        IpmsComponentsService,
        TopPropertyTypeService,
        SubTypeCategoryService,
        SubCategoryService,
        BuiltinFittingsService,
        CeilingsService,
        CubicContentsService,
        RoofStructuresService,
        StorageTypesService,
        WallsAndPartitionsService,
        ChimneyStackService,
        RoofCoveringService,
        ConservatoryPorcheService,
        MainWallService,
        OtherJoineryService,
        OutsideDoorService,
        RainwaterPipeService,
        FireplacesChimneyBreastsService,
        BathroomFittingsService,
        GardenService,
        OutdoorSpaceService,
        HouseAdjustmentsService,
        WarehouseAdjustmentsService,
        RetailBuildingAdjustmentsService,
        ShopFrontTypeService,
        ParkingTypeService,
        RetailStoreAdjustmentsService,
        ParkingAdjustmentsService,
        LandAdjustmentsService,
        ExternalFacadeService,
        FloorNumberingSchemesService,
        OrganisationService,
        CertificationService,
        AdditionalCostTitleService
    ],
    declarations: [
        FilterPipe,
        NestedLabelPipe,
        LinkedTablesComponent,
        AllLinkedTablesComponent,
        InteractionTypesListComponent,
        InteractionTypeEditComponent,
        BaseOfValuesListComponent,
        BaseOfValueEditComponent,
        BuildingTypeListComponent,
        BuildingTypeEditComponent,
        PremiseOfValuesListComponent,
        PremiseOfValueEditComponent,
        PurposeOfValuationsListComponent,
        PurposeOfValuationEditComponent,
        ReportDeliveryMethodsListComponent,
        ReportDeliveryMethodEditComponent,
        SourceExternalReferencesListComponent,
        SourceExternalReferenceEditComponent,
        MeasurementMethodologiesListComponent,
        MeasurementMethodologyEditComponent,
        PurposeMeasurementsListComponent,
        PurposeMeasurementEditComponent,
        ApproachesToValuesListComponent,
        ApproachesToValueEditComponent,
        DefaultAssumptionsListComponent,
        DefaultAssumptionEditComponent,
        DeparturesListComponent,
        DepartureEditComponent,
        IncludedInValuationsListComponent,
        IncludedInValuationEditComponent,
        MethodsToValuesListComponent,
        MethodsToValueEditComponent,
        SpecialAssumptionsListComponent,
        SpecialAssumptionEditComponent,
        ValuationStandardsListComponent,
        ValuationStandardEditComponent,
        ResidentialTypeListComponent,
        ResidentialTypeEditComponent,
        HandoverStandardListComponent,
        HandoverStandardEditComponent,
        GradeListComponent,
        GradeEditComponent,
        SourceCredibilityListComponent,
        SourceCredibilityEditComponent,
        TypeOfRegistrationListComponent,
        TypeOfRegistrationEditComponent,
        SourceTypeListComponent,
        SourceTypeEditComponent,
        SourceInformationListComponent,
        SourceInformationEditComponent,
        StateOfRepairListComponent,
        StateOfRepairEditComponent,
        TypeOfTitleListComponent,
        TypeOfTitleEditComponent,
        AssetClassTypesListComponent,
        AssetClassTypeEditComponent,
        StandardMeasurementsListComponent,
        StandardMeasurementEditComponent,
        OfficeStandardMeasurementsListComponent,
        OfficeStandardMeasurementEditComponent,
        IpmsStandardsListComponent,
        IpmsStandardEditComponent,
        IvsStandardsListComponent,
        IvsStandardEditComponent,
        RicsStandardsListComponent,
        RicsStandardEditComponent,
        TasksListComponent,
        UnitMeasurementsListComponent,
        UnitMeasurementEditComponent,
        UnitAreaMeasurementsListComponent,
        UnitAreaMeasurementEditComponent,
        ExternalAreasListComponent,
        ExternalAreaEditComponent,
        FacilitiesListComponent,
        FacilityEditComponent,
        KeyCategoryListComponent,
        KeyCategoryEditComponent,
        RentReviewCommentListComponent,
        RentReviewCommentEditComponent,
        FloorTypesListComponent,
        FloorTypeEditComponent,
        WindowTypesListComponent,
        WindowTypeEditComponent,
        ExternalWallsListComponent,
        ExternalWallEditComponent,
        FoundationTypesListComponent,
        FoundationTypeEditComponent,
        PropertyTypesListComponent,
        PropertyTypeEditComponent,
        TenureListComponent,
        TenureEditComponent,
        OfficeLayoutsListComponent,
        OfficeLayoutEditComponent,
        AdjustmentsEditComponent,
        AdjustmentsListComponent,
        OfficeAdjustmentEditComponent,
        OfficeAdjustmentsListComponent,
        BuildingUseStatusEditComponent,
        BuildingUseStatusesListComponent,
        ComparativeLandUseEditComponent,
        ComparativeLandUsesListComponent,
        CoordinateReferenceSystemEditComponent,
        CoordinateReferenceSystemsListComponent,
        LandTenureEditComponent,
        LandTenuresListComponent,
        ParcelCharacteristicEditComponent,
        ParcelCharacteristicsListComponent,
        PlaningStatusEditComponent,
        PlaningStatusesListComponent,
        QosDegradationEditComponent,
        QosDegradationsListComponent,
        SolUseClassificationEditComponent,
        SolUseClassificationsListComponent,
        UnitLengthMeasurementEditComponent,
        UnitLengthMeasurementsListComponent,
        IpmsComponentListComponent,
        IpmsComponentEditComponent,
        TopPropertyTypesListComponent,
        TopPropertyTypeEditComponent,
        SubTypeCategoriesListComponent,
        SubTypeCategoryEditComponent,
        SubCategoriesListComponent,
        SubCategoryEditComponent,
        WallsAndPartitionsListComponent,
        WallsAndPartitionEditComponent,
        StorageTypesListComponent,
        StorageTypeEditComponent,
        RoofStructuresListComponent,
        RoofStructureEditComponent,
        FireplacesChimneyBreastsListComponent,
        FireplacesChimneyBreastEditComponent,
        CeilingsListComponent,
        CeilingEditComponent,
        BuiltinFittingsListComponent,
        BuiltinFittingEditComponent,
        ChimneyStackEditComponent,
        ChimneyStacksListComponent,
        RoofCoveringsListComponent,
        RoofCoveringEditComponent,
        ConservatoryPorchesListComponent,
        ConservatoryPorcheEditComponent,
        MainWallsListComponent,
        MainWallEditComponent,
        OtherJoineriesListComponent,
        OtherJoineryEditComponent,
        OutsideDoorsListComponent,
        OutsideDoorEditComponent,
        RainwaterPipesListComponent,
        RainwaterPipeEditComponent,
        GardenEditComponent,
        GardensListComponent,
        OutdoorSpaceEditComponent,
        OutdoorSpacesListComponent,
        BathroomFittingEditComponent,
        BathroomFittingsListComponent,
        HouseAdjustmentEditComponent,
        HouseAdjustmentListComponent,
        WarehouseAdjustmentEditComponent,
        WarehouseAdjustmentListComponent,
        RetailBuildingAdjustmentEditComponent,
        RetailBuildingAdjustmentListComponent,
        ShopFrontTypesListComponent,
        ShopFrontTypeEditComponent,
        ParkingTypeEditComponent,
        ParkingTypesListComponent,
        RetailStoreAdjustmentListComponent,
        RetailStoreAdjustmentEditComponent,
        ParkingAdjustmentListComponent,
        ParkingAdjustmentEditComponent,
        LandAdjustmentListComponent,
        LandAdjustmentEditComponent,
        ExternalFacadesListComponent,
        ExternalFacadeEditComponent,
        FloorNumberingSchemeEditComponent,
        FloorNumberingSchemesListComponent,
        OrganisationsListComponent,
        OrganisationEditComponent,
        CertificationsListComponent,
        CertificationEditComponent,
        AdditionalCostTitleEditComponent,
        AdditionalCostTitlesListComponent,
        RentBasesListComponent,
        RentBasisEditComponent,
        RentReviewTypesListComponent,
        RentReviewTypeEditComponent
    ]
})
export class LinkedTablesModule {
    
}