
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
    selectAssetClassResidentialsInStore,
    selectAssetClassResidentialsPageLoading,
    selectAssetClassResidentialsShowInitWaitingMessage
} from '../_selectors/asset-class-residential.selectors';
import { tenureAdder } from './tenure_extension';

export class AssetClassResidentialsDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssetClassResidentialsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssetClassResidentialsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssetClassResidentialsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items.map(e => tenureAdder(e)));
        });
    }
}
