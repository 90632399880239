import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ActivatedRouteSnapshot} from '@angular/router';
import {AppState} from '../../../../../core/reducers/index';
import {AssignmentsService} from '../../../../../core/assignment/_services/index';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';

@Injectable()
export class ToeAssignmentGetResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public service: AssignmentsService,
                private global: Globals) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (this.global.activeAssignmentId) {
            return this.service.getById(this.global.activeAssignmentId);
        }
    }
}
