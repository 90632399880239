import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ExternalRefTemplateActions, ExternalRefTemplateActionTypes } from "../_actions";
import { ExternalRefTemplateModel } from "../_models";

export interface ExternalRefTemplateState extends EntityState<ExternalRefTemplateModel> {
    isLoading: boolean;
    total: number;
}
export const adapter: EntityAdapter<ExternalRefTemplateModel> = createEntityAdapter<ExternalRefTemplateModel>();
export const initialState: ExternalRefTemplateState = adapter.getInitialState({
    isLoading: false,
    total: 0
});

export function externalRefTemplateReducer(state = initialState, action: ExternalRefTemplateActions): ExternalRefTemplateState {
    switch (action.type) {
        case ExternalRefTemplateActionTypes.LOAD_EXTERNAL_REF_TEMPLATES: {
            return {
                ...state,
                isLoading: true
            };
        }
        case ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATES_LOADED: {
            return adapter.setAll(action.payload.templates, {
                ...state,
                isLoading: false,
                total: action.payload.total
            })
        }
        case ExternalRefTemplateActionTypes.ADD_EXTERNAL_REF_TEMPLATE: {
            return state;
        }
        case ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATE_ADDED: {
            return adapter.addOne(action.payload.template, state);
        }
        case ExternalRefTemplateActionTypes.UPDATE_EXTERNAL_REF_TEMPLATE: {
            return state;
        }
        case ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATE_UPDATED: {
            return adapter.updateOne(action.payload.template, state);
        }
        case ExternalRefTemplateActionTypes.DELETE_EXTERNAL_REF_TEMPLATE: {
            return state;
        }
        case ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATE_DELETED: {
            return adapter.removeOne(action.payload.id, state);
        }
        default: {
            return state;
        }
    }
}