import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ParkingTypeModel } from "../_models/parking-type.model";

export enum ParkingTypeActionTypes {
    AllParkingTypesRequested = '[Linked Area] All ParkingType Requested',
    AllParkingTypesLoaded = '[ParkingType Effect] All ParkingType Loaded',

    ParkingTypeOnServerCreated = '[Add ParkingType Page] ParkingType On Server Created',
    ParkingTypeCreated = '[ParkingType Effect] ParkingType Created',
    ParkingTypeUpdated = '[ParkingType Effect] ParkingType Updated',
    ParkingTypeDeleted = '[ParkingType List Page] ParkingType Deleted',

    ParkingTypeOnServerRestored = '[ParkingType Trash] ParkingType On Server Restored',
    ParkingTypeRestored = '[ParkingType Trash] ParkingType Restored',

    ParkingTypeOnServerAdminRestored = '[ParkingType Admin Trash] ParkingType On Server Restored',
    ParkingTypeAdminRestored = '[ParkingType Admin Trash] ParkingType Restored',

    ParkingTypeDeletedFromTrash = '[ParkingType Trash] ParkingType deleted',
    ParkingTypeDeletedFromAdminTrash = '[ParkingType Admin Trash] ParkingType deleted',

    ParkingTypeTrash = 'ParkingType Trashed',
    ParkingTypeTrashFlushed = 'ParkingType Trash Flushed',

    ParkingTypesPageRequested = '[ParkingType List Page] ParkingType Page Requested',
    ParkingTypesPageLoaded = '[ParkingType API] ParkingType Page Loaded',
    ParkingTypesPageCancelled = '[ParkingType API] ParkingType Page Cancelled',

    ParkingTypesPageToggleLoading = '[ParkingType page] ParkingType Page Toggle Loading',
    ParkingTypeActionToggleLoading = '[ParkingType] ParkingType Action Toggle Loading'
}

export class ParkingTypeOnServerCreated implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeOnServerCreated;

    constructor(public payload: { item: ParkingTypeModel }) {
    }
}

export class ParkingTypeCreated implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeCreated;

    constructor(public payload: { item: ParkingTypeModel }) {
    }
}

export class ParkingTypeUpdated implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeUpdated;

    constructor(public payload: {
        partialItem: Update<ParkingTypeModel>,
        item: ParkingTypeModel
    }) {
    }
}

export class ParkingTypeDeleted implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingTypesPageRequested implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ParkingTypesPageLoaded implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypesPageLoaded;

    constructor(public payload: { items: ParkingTypeModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ParkingTypesPageCancelled implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypesPageCancelled;
}

export class AllParkingTypesRequested implements Action {
    readonly type = ParkingTypeActionTypes.AllParkingTypesRequested;
}

export class AllParkingTypesLoaded implements Action {
    readonly type = ParkingTypeActionTypes.AllParkingTypesLoaded;

    constructor(public payload: { items: ParkingTypeModel[] }) {
    }
}

export class ParkingTypesPageToggleLoading implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ParkingTypeActionToggleLoading implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ParkingTypeDeletedFromAdminTrash implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingTypeDeletedFromTrash implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingTypeOnServerRestored implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingTypeRestored implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeRestored;

    constructor(public payload: { item: ParkingTypeModel }) {
    }
}

export class ParkingTypeOnServerAdminRestored implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingTypeAdminRestored implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeAdminRestored;

    constructor(public payload: { item: ParkingTypeModel }) {
    }
}

export class ParkingTypeTrashFlushed implements Action {
    readonly type = ParkingTypeActionTypes.ParkingTypeTrashFlushed;

    constructor() {
    }
}


export type ParkingTypeActions = ParkingTypeCreated
    | ParkingTypeUpdated
    | ParkingTypeDeleted
    | ParkingTypesPageRequested
    | ParkingTypesPageLoaded
    | ParkingTypesPageCancelled
    | AllParkingTypesLoaded
    | AllParkingTypesRequested
    | ParkingTypeOnServerCreated
    | ParkingTypeDeletedFromAdminTrash
    | ParkingTypeDeletedFromTrash
    | ParkingTypeOnServerRestored
    | ParkingTypeRestored
    | ParkingTypeOnServerAdminRestored
    | ParkingTypeAdminRestored
    | ParkingTypeTrashFlushed
    | ParkingTypesPageToggleLoading
    | ParkingTypeActionToggleLoading;