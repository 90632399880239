import {BaseModel} from '../../_base/crud';

export class AssetClassSpecialAssumptionModel extends BaseModel {
    id: number;
    asset_class_id: number;
    special_assumption_id: number;
    special_assumption_name: string;
    name: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.asset_class_id = undefined;
        this.special_assumption_id = undefined;
        this.special_assumption_name= '';
        this.name = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}