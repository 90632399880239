import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { TermsOfEngagementService } from 'src/app/core/terms-of-engagement/_services/terms-of-engagement.service';
import { ToeModel } from 'src/app/core/toe';

@Component({
  selector: 'kt-duplicate-toe-dialog',
  templateUrl: './duplicate-toe-dialog.component.html',
  styleUrls: ['./duplicate-toe-dialog.component.scss'],
  providers: [TermsOfEngagementService]
})
export class DuplicateToeDialogComponent implements OnInit {
  private tpLoading$ = new BehaviorSubject<boolean>(true)
  private submitting$ = new BehaviorSubject<boolean>(false)
  loading$ = combineLatest([
    this.tpLoading$,
    this.submitting$
  ]).pipe(
    map(([tpLoading, submitting]) => {
      return tpLoading || submitting
    })
  )

  title$ = new BehaviorSubject<string>('Duplicate Toe')

  selectedTps$ = new BehaviorSubject<Array<number>>([])
  tps$ = this.service.getTpsListing(this.data.toe.id).pipe(
    tap(_ => this.tpLoading$.next(false)),
    switchMap(response => {
      return this.selectedTps$.asObservable().pipe(
        map(selectedTps => {
          return response.data.map(tp => {
            return {
              id: tp.id,
              name: tp.name,
              checked: selectedTps.includes(tp.id)
            }
          })
        })
      )
    })
  )

  constructor(
    public dialogRef: MatDialogRef<DuplicateToeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {toe: ToeModel},
    private service: TermsOfEngagementService
  ) { }

  ngOnInit(): void {
  }

  onTpSelected(event: MatCheckboxChange, {id}: {id: number}) {
    const selectedTps = this.selectedTps$.value;
    if (event.checked) {
      selectedTps.push(id)
    } else {
      const indx = selectedTps.findIndex(tpId => tpId == id)
      selectedTps.splice(indx, 1)
    }
    this.selectedTps$.next(selectedTps)
  }

  onSubmit() {
    this.service.duplicateToe(this.data.toe.id, this.selectedTps$.value).pipe(
      finalize(() => this.submitting$.next(false))
    ).subscribe({
      next: (res) => {
        this.dialogRef.close('success')
      },
      error: (err) => console.error('Error:', err)
    })
  }


  onClose() {
    this.dialogRef.close()
  }
}
