import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICriterionTemplate } from 'src/app/core/template';
import { CriterionCategories, getCriterionCategories } from 'src/app/core/valuation/_models/criterion-categories.model';

@Component({
  selector: 'kt-custom-criterion-modal',
  templateUrl: './custom-criterion-modal.component.html',
  styleUrls: ['./custom-criterion-modal.component.scss']
})
export class CustomCriterionModalComponent implements OnInit, OnDestroy {
  criterion: ICriterionTemplate;
  title: string = '';
  form: UntypedFormGroup;
  criterionCategories = CriterionCategories;
  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    private dialogRef: MatDialogRef<CustomCriterionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) { 
    this.criterion = data.criterion;
    if (this.criterion.name) {
      this.title = 'Edit Custom Criterion'
    } else {
      this.title = 'New Custom Criterion'
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.criterion.name, Validators.required],
      category: ['' + this.criterion.category, Validators.required],
      categoryName: [this.criterion.categoryName]
    })
    const categoryCtrl = this.form.get('category');
    categoryCtrl.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      if (val === CriterionCategories.Other) {
        this.form.get('categoryName').setValidators(Validators.required);
        this.form.get('categoryName').updateValueAndValidity();
      } else {
        const name = getCriterionCategories(val);
        this.form.get('categoryName').patchValue(name);
      }
    })
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSaveClick() {
    const name = this.form.get('name').value;
    const category = Number(this.form.get('category').value);
    const categoryName = this.form.get('categoryName').value ? this.form.get('categoryName').value : getCriterionCategories(category);
    const item: ICriterionTemplate = {
      id: this.criterion.id,
      crit_id: this.criterion.crit_id,
      category,
      categoryName,
      name,
      type: this.criterion.type
    }
    this.dialogRef.close(item);
  }
  onNoClick() {
    this.dialogRef.close();
  }
  checkEnum(val): boolean {
    return isNaN(val);
  }
}
