import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { AssetClassParkingService } from "src/app/core/comparable/_services/asset-class-parking.service";

@Injectable()
export class AssetClassParkingGetResolver implements Resolve<any> {
    constructor(
        private service: AssetClassParkingService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = Number(route.params.parking_id);
        return this.service.getAssetClass(id);
    }
}
