// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {BuildingUseStatus} from '../_models/building-use-status.model';

export enum BuildingUseStatusActionTypes {

    AllBuildingUseStatussRequested = '[BuildingUseStatuss Home Page] All BuildingUseStatuss Requested',
    AllBuildingUseStatussLoaded = '[BuildingUseStatuss API] All BuildingUseStatuss Loaded',

    BuildingUseStatusOnServerCreated = '[Edit BuildingUseStatus Dialog] BuildingUseStatus On Server Created',
    BuildingUseStatusCreated = '[Edit BuildingUseStatuss Dialog] BuildingUseStatuss Created',
    BuildingUseStatusUpdated = '[Edit BuildingUseStatus Dialog] BuildingUseStatus Updated',
    BuildingUseStatusDeleted = '[BuildingUseStatuss List Page] BuildingUseStatus Deleted',

    BuildingUseStatussPageRequested = '[BuildingUseStatuss List Page] BuildingUseStatuss Page Requested',
    BuildingUseStatussPageLoaded = '[BuildingUseStatuss API] BuildingUseStatuss Page Loaded',
    BuildingUseStatussPageCancelled = '[BuildingUseStatuss API] BuildingUseStatuss Page Cancelled',

    BuildingUseStatussPageToggleLoading = '[BuildingUseStatuss page] BuildingUseStatuss Page Toggle Loading',
    BuildingUseStatussActionToggleLoading = '[BuildingUseStatuss] BuildingUseStatuss Action Toggle Loading',

    // trash system

    BuildingUseStatusOnServerRestored = '[BuildingUseStatus Trash] BuildingUseStatus On Server Restored',
    BuildingUseStatusRestored = '[BuildingUseStatus Trash] BuildingUseStatus Restored',

    BuildingUseStatusOnServerAdminRestored = '[BuildingUseStatus Admin Trash] BuildingUseStatus On Server Restored',
    BuildingUseStatusAdminRestored = '[BuildingUseStatus Admin Trash] BuildingUseStatus Restored',

    BuildingUseStatusDeletedFromTrash = '[BuildingUseStatus Trash] BuildingUseStatus deleted',
    BuildingUseStatusDeletedFromAdminTrash = '[BuildingUseStatus Admin Trash] BuildingUseStatus deleted',

    BuildingUseStatusTrash = 'BuildingUseStatus Trashed',
    BuildingUseStatusTrashFlushed = 'BuildingUseStatus Trash Flushed',
}

export class BuildingUseStatusOnServerCreated implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusOnServerCreated;

    constructor(public payload: { buildingUseStatus: BuildingUseStatus}) {
    }
}

export class BuildingUseStatusCreated implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusCreated;

    constructor(public payload: { buildingUseStatus: BuildingUseStatus }) {
    }
}

export class BuildingUseStatusUpdated implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusUpdated;

    constructor(public payload: {
        partialBuildingUseStatus: Update<BuildingUseStatus>,
        buildingUseStatus: BuildingUseStatus
    }) {
    }
}

export class BuildingUseStatusDeleted implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingUseStatussPageRequested implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatussPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class BuildingUseStatussPageLoaded implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatussPageLoaded;

    constructor(public payload: { buildingUseStatuss: BuildingUseStatus[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class BuildingUseStatussPageCancelled implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatussPageCancelled;
}

export class AllBuildingUseStatussRequested implements Action {
    readonly type = BuildingUseStatusActionTypes.AllBuildingUseStatussRequested;
}

export class AllBuildingUseStatussLoaded implements Action {
    readonly type = BuildingUseStatusActionTypes.AllBuildingUseStatussLoaded;

    constructor(public payload: { buildingUseStatuss: BuildingUseStatus[] }) {
    }
}

export class BuildingUseStatussPageToggleLoading implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatussPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BuildingUseStatussActionToggleLoading implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatussActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class BuildingUseStatusDeletedFromAdminTrash implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class BuildingUseStatusDeletedFromTrash implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingUseStatusOnServerRestored implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingUseStatusRestored implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusRestored;

    constructor(public payload: { item: BuildingUseStatus }) {
    }
}

export class BuildingUseStatusOnServerAdminRestored implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingUseStatusAdminRestored implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusAdminRestored;

    constructor(public payload: { item: BuildingUseStatus }) {
    }
}

export class BuildingUseStatusTrashFlushed implements Action {
    readonly type = BuildingUseStatusActionTypes.BuildingUseStatusTrashFlushed;

    constructor() {
    }
}

export type BuildingUseStatusActions = BuildingUseStatusCreated
    | BuildingUseStatusUpdated
    | BuildingUseStatusDeleted
    | BuildingUseStatussPageRequested
    | BuildingUseStatussPageLoaded
    | BuildingUseStatussPageCancelled
    | AllBuildingUseStatussLoaded
    | AllBuildingUseStatussRequested
    | BuildingUseStatusOnServerCreated
    | BuildingUseStatussPageToggleLoading
    | BuildingUseStatussActionToggleLoading
    | BuildingUseStatusDeletedFromAdminTrash
    | BuildingUseStatusDeletedFromTrash
    | BuildingUseStatusOnServerRestored
    | BuildingUseStatusRestored
    | BuildingUseStatusOnServerAdminRestored
    | BuildingUseStatusAdminRestored
    | BuildingUseStatusTrashFlushed;
