<kt-portlet>
    <kt-portlet-header
        [title]="'General Settings'"
        [class]="'kt-portlet-head--lg'"
        [sticky]="true">
        <ng-container ktPortletTools>
            <a href="javascript:;" class="btn mr-2" mat-raised-button [matTooltip]="'Close'" (click)="back()">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">
                    Close
                </span>
            </a>
            <button
                mat-raised-button
                [matTooltip]="'GENERAL.TOOLTIP.SAVE' | translate"
                class="btn btn-success" color="submit" (click)="onSubmit()">
                Save
            </button>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form">
            <kt-tab-header
                [centerTabs]="true"
                [headerLabels]="tabHeaders"
                [selectedTabChange]="selectedTabChange$"
                (tabChange)="onTabChange($event)">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs"
                [(selectedIndex)]="selectedTab">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Maps</span>
                    </ng-template>
                    <div>
                        <mat-label class="mb-2">
                            Please choose (Right-Click on the map) the location that should be used by default to centre all the system's maps.
                        </mat-label>
                        <div>
                            <mat-form-field class="mat-form-field-fluid">
                                <input 
                                    #locationSearch
                                    matInput 
                                    [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_BY_CITY' | translate"
                                />
                                <mat-icon matSuffix container="body" 
                                    [ngbPopover]="'TOOLTIP.HOUSE.LOCATE.DESCRIPTION' | translate"
                                    [popoverTitle]="'TOOLTIP.HOUSE.LOCATE.TITLE' | translate">
                                    help
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <agm-map
                            [latitude]="latitude$|async"
                            [longitude]="longitude$|async"
                            [zoom]="13"
                            [usePanning]="true"
                            [clickableIcons]="false"
                            [scrollwheel]="null"
                            (mapReady)="onMapReady($event)">
                            <agm-map-type-control></agm-map-type-control>
                            <agm-marker
                                [latitude]="latitude$|async"
                                [longitude]="longitude$|async"
                                [markerDraggable]="false">
                            </agm-marker>
                        </agm-map>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>