/**
 * Location
 */

import { remove } from "lodash"
import ValuationUtils from "../_utils/map"
import { capitalize } from "../../v2/utils"

export type VLocationData = {
    latitude: number,
    longitude: number,
    country: string,
    city: string,
    zipCode: string,
    address: string,
    locationGrade: string,
    locationSurrounding: string,
    timezoneOffset: string | null
}
export type VLandLocationData = Omit<VLocationData, 'locationSurrounding'> & {
    points: {lat: number, lng: number}[]
}

export type VLocationDataTypes = VLocationData | VLandLocationData

type VLocationDataRemoteBase = {
    country_name: string,
    city_name: string,
    zip_code: string,
    address: string,
    location_grade_name: string,
}
export type VLocationDataRemote = VLocationDataRemoteBase & {
    latitude: string,
    longitude: string,
    location_surrounding: string,
    timezoneOffset: string |null
}
export type VLandLocationDataRemote = VLocationDataRemoteBase & {
    points: { lat: string, lng: string }[],
    timezoneOffset: string | null
}

export type VLocationDataRemoteTypes
    = VLocationDataRemote
    | VLandLocationDataRemote

export function mapVLocationRemoteToDomain(remote: VLocationDataRemote): VLocationData {
    return {
        city: remote.city_name,
        country: remote.country_name,
        zipCode: remote.zip_code,
        address: remote.address,
        locationGrade: remote.location_grade_name,
        latitude: Number(remote.latitude),
        longitude: Number(remote.longitude),
        locationSurrounding: remote.location_surrounding,
        timezoneOffset: remote.timezoneOffset
    }
}

export function mapVLandLocationRemoteToDomain(remote: VLandLocationDataRemote): VLandLocationData {
    const center = ValuationUtils.getCenter(remote.points)
    return {
        city: remote.city_name,
        country: remote.country_name,
        zipCode: remote.zip_code,
        address: remote.address,
        locationGrade: remote.location_grade_name,
        latitude: center.lat,
        longitude: center.lng,
        points: remote.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)})).filter(p => p.lat != null && p.lng != null),
        timezoneOffset: remote.timezoneOffset
    }
}

/**
 * Sizes
 */

export type VAssetClassSize = {
    standardMeasurementId: number,
    standardMeasurementName: string,
    unitOfAreaMeasurementId: number,
    unitOfAreaMeasurementAcronym: string,
    size: number
}

export type VAssetClassSizeRemote = {
    standard_measurement_id: number,
    standard_measurement_name: string,
    unit_of_area_measurement_id: number,
    unit_of_area_measurement_acronym: string,
    size: number
}

export function mapVAssetClassSizeRemoteToDomain(remote: VAssetClassSizeRemote[]): VAssetClassSize[] {
    return remote.map(item => ({
        standardMeasurementId: item.standard_measurement_id,
        standardMeasurementName: item.standard_measurement_name,
        unitOfAreaMeasurementId: item.unit_of_area_measurement_id,
        unitOfAreaMeasurementAcronym: item.unit_of_area_measurement_acronym,
        size: item.size
    }))
}

enum SizeMeasurements {
    SQM = 1,
    SQF = 2,
    ACRE = 3,
    HECTARE = 4
}

enum UnitMeasurement {
    IMPERIAL = 1,
    METRIC = 2
}

const CONST_F = 10.7639
const CONST_ACRE = 43560
const CONST_HECTARE = 107639.104167

export function sizeInfo(size: VAssetClassSize, preferredUnitMeasurement?: number): string {
    if (!size) {
        return 'Unknown'
    }

    const info: { sizeInF: number, sizeInM: number } = {
        sizeInF: null,
        sizeInM: null,
    }

    switch (size.unitOfAreaMeasurementId) {
        case SizeMeasurements.SQM: {
            info.sizeInF = size.size * CONST_F
            info.sizeInM = size.size
            break;
        }
        case SizeMeasurements.SQF: {
            info.sizeInF = size.size
            info.sizeInM = size.size / CONST_F
            break;
        }
        case SizeMeasurements.ACRE: {
            info.sizeInF = size.size * CONST_ACRE
            info.sizeInM = size.size / CONST_F
            break;
        }
        case SizeMeasurements.HECTARE: {
            info.sizeInF = size.size * CONST_HECTARE
            info.sizeInM = size.size / CONST_F
            break;
        }
    }

    if (info.sizeInF == null || info.sizeInM == null) {
        return 'Unknown'
    }

    const sizeInF = info.sizeInF.toLocaleString('en-US', { maximumFractionDigits: 2 })
    const sizeInM = info.sizeInM.toLocaleString('en-US', { maximumFractionDigits: 2 })

    return preferredUnitMeasurement && preferredUnitMeasurement == UnitMeasurement.IMPERIAL
        ? `${sizeInF} ft2 (${sizeInM} m2)`
        : `${sizeInM} m2 (${sizeInF} ft2)`
}

export function convertSize(value: number, srcUnitAreaMeasurementId: number, dstUnitAreaMeasurementId: number): number {
    let sizeInM = 0;
    switch (srcUnitAreaMeasurementId) {
        case SizeMeasurements.SQM: {
            sizeInM = value;
            break;
        }
        case SizeMeasurements.SQF: {
            sizeInM = value / CONST_F
            break;
        }
        case SizeMeasurements.ACRE: {
            const sizeInF = value * CONST_ACRE
            sizeInM = sizeInF / CONST_F
            break;
        }
        case SizeMeasurements.HECTARE: {
            const sizeInF = value * CONST_HECTARE
            sizeInM = sizeInF / CONST_F
            break;
        }
    }
    switch (dstUnitAreaMeasurementId) {
        case SizeMeasurements.SQM: {
            return sizeInM
        }
        case SizeMeasurements.SQF: {
            return sizeInM * CONST_F
        }
        case SizeMeasurements.ACRE: {
            return sizeInM / 4047
        }
        case SizeMeasurements.HECTARE: {
            return sizeInM / 10000
        }
    }
}

/**
 * Considerations
 */

type VAssetClassSourceBase = {
    sourceTypeName: string,
    sourceDate: Date,
    sourceInformation: string,
    sourceCredibility: string,
    validationSource: string,
    webAddress: string,
    picture_1: string
    picture_2: string
}

export type VAssetClassSource
    = VAssetClassSourceBase & {
        sourceType: 'Transacted',
        transactionDate: Date,
    }
    | VAssetClassSourceBase & {
        sourceType: 'Advertised',
        informationDate: Date
    }


export type VTargetPropertyConsideration
    = VTargetPropertyConsiderationSale
    | VTargetPropertyConsiderationRent

export type VTargetPropertyConsiderationSale =  {
    considerationType: 'Sale'
    considerationTypeName: string
}

export type VTargetPropertyConsiderationRent = {
    considerationType: 'Rent'
    considerationTypeName: string
}

type VAssetClassConsiderationBase = {
    id: number,
    currency: string,
    tenureName: string,
    value: number,
    conversion: {quoteCurrency: string, exchangeRate: number}[]
    source: VAssetClassSource | null
}

export type VAssetClassConsideration
    = VAssetClassConsiderationSale
    | VAssetClassConsiderationRentEffective
    | VAssetClassConsiderationRentHeadline
    | VAssetClassConsiderationLand

export type VAssetClassConsiderationSale = VAssetClassConsiderationBase & {
    considerationType: 'Sale',
    oldTenure: any
}
export type VAssetClassConsiderationRentEffective = VAssetClassConsiderationBase & {
    considerationType: 'Rent',
    rentType: 'Effective',
    oldTenure: any
}

export type VAssetClassConsiderationRentHeadline = VAssetClassConsiderationBase & {
    considerationType: 'Rent'
    rentType: 'Headline',
    oldTenure: any
}

export type VAssetClassConsiderationLand = VAssetClassConsiderationBase & {
    considerationType: 'Land',
}

export type VAssetClassSourceRemote = {
    source_type_id: number,
    source_type_name: string,
    information_date: Date,
    transaction_date: Date,
    source_information_name: string,
    source_credibility: string,
    validation_source: string,
    picture_1: string,
    picture_2: string,
    web_address: string
}

export type VAssetClassConsiderationRemote = {
    id: number,
    tenure: string,
    consideration_type_id: number,
    rent_type: string,
    currency: string,
    value: number,
    conversion: {quote_currency: string, exchange_rate: number}[]
    source: VAssetClassSourceRemote,
    old_tenure: any
}

export type VAssetClassLandConsiderationRemote = {
    id: number,
    tenure_id: number,
    tenure_name: string,
    currency: string,
    consideration: number,
    conversion: {quote_currency: string, exchange_rate: number}[]
    source: VAssetClassSourceRemote
}

function mapConsiderationRemoteToConsiderationSale(considerationRemote: VAssetClassConsiderationRemote): VAssetClassConsiderationSale {
    return {
        id: considerationRemote.id,
        tenureName: capitalize(considerationRemote.tenure),
        considerationType: 'Sale',
        conversion: considerationRemote.conversion.map(conv => ({quoteCurrency: conv.quote_currency, exchangeRate: conv.exchange_rate})),
        currency: considerationRemote.currency,
        oldTenure: considerationRemote.old_tenure,
        source: mapSourceRemoteToDomain(considerationRemote.source),
        value: considerationRemote.value
    }
}

function mapConsiderationRemoteToConsiderationRent(considerationRemote: VAssetClassConsiderationRemote): VAssetClassConsiderationRentEffective | VAssetClassConsiderationRentHeadline {
    if (considerationRemote.rent_type === 'headline') {
        return {
            id: considerationRemote.id,
            tenureName: capitalize(considerationRemote.tenure),
            considerationType: 'Rent',
            rentType: 'Headline',
            conversion: considerationRemote.conversion.map(conv => ({quoteCurrency: conv.quote_currency, exchangeRate: conv.exchange_rate})),
            currency: considerationRemote.currency,
            oldTenure: considerationRemote.old_tenure,
            source: mapSourceRemoteToDomain(considerationRemote.source),
            value: considerationRemote.value
        }
    }
    return {
        id: considerationRemote.id,
        tenureName: capitalize(considerationRemote.tenure),
        considerationType: 'Rent',
        rentType: 'Effective',
        conversion: considerationRemote.conversion.map(conv => ({quoteCurrency: conv.quote_currency, exchangeRate: conv.exchange_rate})),
        currency: considerationRemote.currency,
        oldTenure: considerationRemote.old_tenure,
        source: mapSourceRemoteToDomain(considerationRemote.source),
        value: considerationRemote.value
    }
}

export function mapConsiderationRemoteToDomain(considerationsRemote: VAssetClassConsiderationRemote[]): VAssetClassConsideration[] {
    const considerations = considerationsRemote
        .map(con => {
            if (con.consideration_type_id === 1) {
                return mapConsiderationRemoteToConsiderationSale(con)
            } else if (con.consideration_type_id === 2) {
                return mapConsiderationRemoteToConsiderationRent(con)
            } 
            return null
        })
        .filter(item => item !== null)

    considerations.sort((a, b) => {
        if (a.source == null || b.source == null) {
            return -1
        }
        const aDate = a.source.sourceType == 'Advertised' ? a.source.informationDate : a.source.transactionDate
        const bDate = b.source.sourceType == 'Advertised' ? b.source.informationDate : b.source.transactionDate
        return bDate.getTime() - aDate.getTime()
    })

    return considerations
}

export function mapSourceRemoteToDomain(source: VAssetClassSourceRemote): VAssetClassSource {
    if (source == null) {
        return null
    }
    const base = {
        sourceTypeName: source.source_type_name,
        sourceInformation: source.source_information_name,
        sourceCredibility: source.source_credibility,
        validationSource: !source.validation_source || !source.validation_source.length ? null : source.validation_source
    }
    switch (source.source_type_id) {
        case 1: {
            return {
                ...base,
                sourceType: 'Transacted',
                transactionDate: new Date(source.transaction_date),
                sourceDate: new Date(source.transaction_date),
                webAddress: source.web_address,
                picture_1: source.picture_1,
                picture_2: source.picture_2
            }
        }
        case 2: {
            return {
                ...base,
                sourceType: 'Advertised',
                informationDate: new Date(source.information_date),
                sourceDate: new Date(source.information_date),
                webAddress: source.web_address,
                picture_1: source.picture_1,
                picture_2: source.picture_2
            }
        }
        default:
            return null
    }
}

export function displayConsiderations(considerations: VAssetClassConsideration[]): VAssetClassConsideration[] {
    const items = [];
    const freeholds = considerations.filter(item => item.tenureName === 'Freehold')
    if (freeholds.length > 0) {
        items.push(freeholds[0])
    }
    const leaseholds = considerations.filter(item => item.tenureName === 'Leasehold')
    if (leaseholds.length > 0) {
        items.push(leaseholds[0])
    }
    return items
}

/**
 * Internal Aspect
 */
export type VInternalAspectRemote = {
    roof_structure_id: number;
    roof_structure_name: string;
    roof_structure_comment: string;

    ceiling_id: number;
    ceiling_name: string;
    ceiling_comment: string;

    walls_and_partition_id: number;
    walls_and_partition_name: string;
    walls_and_partition_comment: string;

    floor_type_id: number;
    floor_type_name: string;
    floor_type_comment: string;

    fireplaces_chimney_breast_id: number;
    fireplaces_chimney_breast_name: string;
    fireplaces_chimney_breast_comment: string;

    builtin_fitting_names: string;

    woodwork: string;
    woodwork_comment: string;

    bathroom_fitting_id: number;
    bathroom_fitting_name: string;
    bathroom_fitting_comment: string;

    height: string;
    height_comment: string;

    cubic_content: string;
    cubic_content_comment: string;

    storage_type_id: number;
    storage_type_name: string;
    storage_type_comment: string;

    shop_front_type_id: number;
    shop_front_type_name: string;
    shop_front_type_comment: string;

    has_other: boolean;
    other_aspects: string;

    has_automation: boolean;
    automations: number[];

    above_floors: number,
    below_floors: number,
    state_of_repair_id: number,
    state_of_repair_name?: string,
    building_facilities_ids: string;

    windows_id: number;
    windows_name: string;
    windows_comment: string;
}

export type VExternalAspectRemote = {
    id: number;
    chimney_stack_id: number;
    chimney_stack_name: string;
    chimney_stack_comment: string;

    roof_covering_id: number;
    roof_covering_name: string;
    roof_covering_comment: string;

    rainwater_pipe_id: number;
    rainwater_pipe_name: string;
    rainwater_pipe_comment: string;

    main_wall_id: number;
    main_wall_name: string;
    main_wall_comment: string;

    window_type_id: number;
    window_type_name: string;
    window_type_comment: string;

    outside_door_id: number;
    outside_door_name: string;
    outside_door_comment: string;

    conservatory_id: number;
    conservatory_name: string;
    conservatory_comment: string;

    other_joinery_id: number;
    other_joinery_name: string;
    other_joinery_comment: string;

    loading_dock: boolean;
    loading_dock_num: number;
    loading_dock_comment: string;

    external_facade_id: number;
    external_facade_name: string;
    external_facade_comment: string;

    has_other: boolean;
    other_aspects: string;
}

export type VGroundRemote = {
    indoor_garage: number,
    indoor_garage_comment: string,

    outdoor_garage: number,
    outdoor_garage_comment: string,

    garden_name: string,
    garden_comment: string,

    outdoor_space_name: string,
    outdoor_space_comment: string,

    external_area_comment: string,
    external_areas_name: string
}

function garageRanges(): { value: number, label: string }[] {
    const ranges: { value: number, label: string }[] = [];
    ranges.push({ value: 0, label: '0' });
    ranges.push({ value: 1, label: '1 to 10' });
    ranges.push({ value: 2, label: '11 to 25' });

    let lowerBound = 25;
    let upperBound = 50;
    let i = 3;
    while (upperBound <= 200) {
        ranges.push({ value: i, label: `${lowerBound + 1} to ${upperBound}` });
        lowerBound = upperBound;
        upperBound = 25 + lowerBound;
        i++;
    }
    ranges.push({ value: i, label: '>200' });

    return ranges;
}

export function getGarageRange(value: number) {
    const ranges = garageRanges()
    const range = ranges.find(r => r.value == value)
    return range ? range.label : null
}

/**
 * Land Area Components
 */
export type VLandAreaComponentRemote = {
    unit_area_measurement_id: number,
    unit_area_measurement_acronym: string
    unit_area_measurement_name: string,
    surface: number
}

/**
 * Service and infras
 */
export type VServiceAndInfraRemote = {
    road: boolean,
    status_n_quality: string
    access_points: string
    length_of_road: string
    ownership: string

    central_heating: boolean
    heatings: string[]
    other_heating: string

    all_of_it: boolean
    services: string[]
    other_service: string
}

export type VServiceAndInfra = {
    road: boolean
    statusAndQuality: string
    accessPoints: string
    lengthOfRoad: string
    ownership: string

    centralHeating: boolean
    heatings: number[]
    otherHeating: string

    allOfIt: boolean
    services: number[]
    otherServices: string
}

export function mapVServiceAndInfraRemoteToDomain(remote: VServiceAndInfraRemote): VServiceAndInfra {
    return {
        road: remote.road,
        statusAndQuality: remote.status_n_quality,
        accessPoints: remote.access_points,
        lengthOfRoad: remote.length_of_road,
        ownership: remote.ownership,
        centralHeating: remote.central_heating,
        heatings: remote.heatings.map(item => Number(item)),
        otherHeating: remote.other_heating,
        allOfIt: remote.all_of_it,
        services: remote.services.map(item => Number(item)),
        otherServices: remote.other_service
    }
}