// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel, QueryParamsFacilityModel} from '../../_base/crud';
// Models
import {Facility} from '../_models/facility.model';

export enum FacilityActionTypes {

    AllFacilitiesRequested = '[Facilities Home Page] All Facilities Requested',
    AllFacilitiesLoaded = '[Facilities API] All Facilities Loaded',

    FacilityOnServerCreated = '[Edit Facility Dialog] Facility On Server Created',
    FacilityCreated = '[Edit Facilities Dialog] Facilities Created',
    FacilityUpdated = '[Edit Facility Dialog] Facility Updated',
    FacilityDeleted = '[Facilities List Page] Facility Deleted',

    FacilitiesPageRequested = '[Facilities List Page] Facilities Page Requested',
    FacilitiesPageLoaded = '[Facilities API] Facilities Page Loaded',
    FacilitiesPageCancelled = '[Facilities API] Facilities Page Cancelled',

    FacilitiesClear = '[Facilities API] Facilities Page Clear',

    FacilitiesPageToggleLoading = '[Facilities page] Facilities Page Toggle Loading',
    FacilitiesActionToggleLoading = '[Facilities] Facilities Action Toggle Loading',

    // trash system

    FacilityOnServerRestored = '[Facility Trash] Facility On Server Restored',
    FacilityRestored = '[Facility Trash] Facility Restored',

    FacilityOnServerAdminRestored = '[Facility Admin Trash] Facility On Server Restored',
    FacilityAdminRestored = '[Facility Admin Trash] Facility Restored',

    FacilityDeletedFromTrash = '[Facility Trash] Facility deleted',
    FacilityDeletedFromAdminTrash = '[Facility Admin Trash] Facility deleted',

    FacilityTrash = 'Facility Trashed',
    FacilityTrashFlushed = 'Facility Trash Flushed',
}

export class FacilityOnServerCreated implements Action {
    readonly type = FacilityActionTypes.FacilityOnServerCreated;

    constructor(public payload: { facility: Facility}) {
    }
}

export class FacilityCreated implements Action {
    readonly type = FacilityActionTypes.FacilityCreated;

    constructor(public payload: { facility: Facility }) {
    }
}

export class FacilityUpdated implements Action {
    readonly type = FacilityActionTypes.FacilityUpdated;

    constructor(public payload: {
        partialFacility: Update<Facility>,
        facility: Facility
    }) {
    }
}

export class FacilityDeleted implements Action {
    readonly type = FacilityActionTypes.FacilityDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class FacilitiesPageRequested implements Action {
    readonly type = FacilityActionTypes.FacilitiesPageRequested;

    constructor(public payload: { page: QueryParamsFacilityModel }) {
    }
}

export class FacilitiesPageLoaded implements Action {
    readonly type = FacilityActionTypes.FacilitiesPageLoaded;

    constructor(public payload: { facilities: Facility[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsFacilityModel }) {
    }
}

export class FacilitiesPageCancelled implements Action {
    readonly type = FacilityActionTypes.FacilitiesPageCancelled;
}

export class AllFacilitiesRequested implements Action {
    readonly type = FacilityActionTypes.AllFacilitiesRequested;
}

export class AllFacilitiesLoaded implements Action {
    readonly type = FacilityActionTypes.AllFacilitiesLoaded;

    constructor(public payload: { facilities: Facility[] }) {
    }
}

export class FacilitiesPageToggleLoading implements Action {
    readonly type = FacilityActionTypes.FacilitiesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class FacilitiesActionToggleLoading implements Action {
    readonly type = FacilityActionTypes.FacilitiesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class FacilitiesClear implements Action {
    readonly type = FacilityActionTypes.FacilitiesClear;

    constructor() {
    }
}

// Trash

export class FacilityDeletedFromAdminTrash implements Action {
    readonly type = FacilityActionTypes.FacilityDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class FacilityDeletedFromTrash implements Action {
    readonly type = FacilityActionTypes.FacilityDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class FacilityOnServerRestored implements Action {
    readonly type = FacilityActionTypes.FacilityOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FacilityRestored implements Action {
    readonly type = FacilityActionTypes.FacilityRestored;

    constructor(public payload: { item: Facility }) {
    }
}

export class FacilityOnServerAdminRestored implements Action {
    readonly type = FacilityActionTypes.FacilityOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FacilityAdminRestored implements Action {
    readonly type = FacilityActionTypes.FacilityAdminRestored;

    constructor(public payload: { item: Facility }) {
    }
}

export class FacilityTrashFlushed implements Action {
    readonly type = FacilityActionTypes.FacilityTrashFlushed;

    constructor() {
    }
}

export type FacilityActions = FacilityCreated
    | FacilityUpdated
    | FacilityDeleted
    | FacilitiesPageRequested
    | FacilitiesPageLoaded
    | FacilitiesPageCancelled
    | AllFacilitiesLoaded
    | AllFacilitiesRequested
    | FacilityOnServerCreated
    | FacilitiesClear
    | FacilitiesPageToggleLoading
    | FacilitiesActionToggleLoading
    | FacilityDeletedFromAdminTrash
    | FacilityDeletedFromTrash
    | FacilityOnServerRestored
    | FacilityRestored
    | FacilityOnServerAdminRestored
    | FacilityAdminRestored
    | FacilityTrashFlushed;
