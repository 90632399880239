import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ResidentialExternalAreaModel } from 'src/app/core/comparable';
import { StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { IpmsTableComponent } from './ipms-table/ipms-table.component';
import { FloorAction } from './models/floor-action.model';
import { MongoliaStandardTableComponent } from './mongolia-standard-table/mongolia-standard-table.component';

@Component({
  selector: 'kt-size-module',
  templateUrl: './size-module.component.html',
  styleUrls: ['./size-module.component.scss'],
  providers: [StandardMeasurementUtility]
})
export class SizeModuleComponent implements OnInit, OnDestroy {
  @Input() assetClassId: number;
  @Input() standardMeasurement: StandardMeasurement;
  @Input() unitAreaMeasurement$: Observable<UnitAreaMeasurement>;
  @Input() floorAction$: Observable<FloorAction|null>;
  @Input() bedrooms$: Observable<number>;
  @Input() externalAreas$: Observable<ResidentialExternalAreaModel[]>;
  @Input() readonly: boolean;

  @ViewChild(IpmsTableComponent, {static: false}) ipmsTable: IpmsTableComponent;
  @ViewChild(MongoliaStandardTableComponent, {static: false}) msTable: MongoliaStandardTableComponent;

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    public smUtilityService: StandardMeasurementUtility
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getData(): any {
    switch (this.standardMeasurement.id) {
      case 4:
        return this.ipmsTable.getData();
      case 5:
        return this.ipmsTable.getData();
      case 6:
        return this.ipmsTable.getData();
      case 7:
        return this.msTable.getData();
      case 8:
        return this.ipmsTable.getData();
      default:
        return null;
    }
  }

  isValid(): boolean {
    switch (this.standardMeasurement.id) {
      case 4:
        return this.ipmsTable.isValid();
      case 5:
        return this.ipmsTable.isValid();
      case 6:
        return this.ipmsTable.isValid();
      case 7:
        return this.msTable.isValid();
      case 8:
        return this.ipmsTable.isValid();
      default:
        return null;
    }
  }
}
