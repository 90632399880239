import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { SubdomainService } from "../../_base/subdomain.service";

const BASE_API_URL = environment.baseApiUrl;

@Injectable()
export class TermsOfEngagementFilesService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  fetchLegalDocuments(id: number): Observable<unknown[]> {
    return this.http.get<{data: unknown[]}>(`${BASE_API_URL}/api/${this.subDomainService.subDomain}/terms-of-engagements/${id}/legal-documents`).pipe(
      map(res => res.data)
    )
  }

  fetchAssetClassReports(id: number) {
    return this.http.get<{data: {
      name: string,
      reports: {
        type: number,
        url: string,
        created_at: string
      }[],
      audit_reports: {
        id: number,
        url: string,
        status: number,
        created_at: string
      }[]
    }[]}>(`${BASE_API_URL}/api/${this.subDomainService.subDomain}/terms-of-engagements/${id}/asset-class-reports`).pipe(
      map(res => res.data)
    )
  }
}