import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassLandModel } from "../_models/asset-class-land.model";
import { AssetClassLandState } from "../_reducers/asset-class-land.reducers";

export const selectAssetClassLandState = createFeatureSelector<AssetClassLandState>('assetClassLands');
export const selectAssetClassLandPageLoading = createSelector(selectAssetClassLandState, state => state.listLoading);
export const selectAssetClassLandActionLoading = createSelector(selectAssetClassLandState, state => state.actionLoading);
export const selectAssetClassLandShowInitWaitingMessage = createSelector(selectAssetClassLandState, state => state.showInitWaitingMessage);
export const selectAssetClassLandLastQuery = createSelector(selectAssetClassLandState, state => state.lastQuery);
export const selectTrashedAssetClassLandCount = createSelector(selectAssetClassLandState, state => state.totalTrashed);
export const selectAdminTrashedAssetClassLandCount = createSelector(selectAssetClassLandState, state => state.totalAdminTrashed);

export const selectAssetClassLandById = (id: number) => createSelector(
    selectAssetClassLandState, 
    state => state.entities[id]
)

export const selectLastCreatedAssetClassLandId = createSelector(
    selectAssetClassLandState,
    state => {
        if (state.previouslyCreatedAssetClassResidentialId != state.lastCreatedAssetClassLandId) {
            return state.lastCreatedAssetClassLandId
        }
        return undefined
    }
)

export const selectAssetClassLandInStore = createSelector(
    selectAssetClassLandState,
    state => {
        const items: AssetClassLandModel[] = [];
        each(state.entities, el => {
            items.push(el);
        });

        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassLandModel[] = [];
        switch (state.lastQuery.sortField) {
            default: 
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                )
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
)