// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {selectGradeQueryResult, selectGradesPageLoading, selectGradesShowInitWaitingMessage} from '../_selectors/grade.selectors';

export class GradesDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectGradesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectGradesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectGradeQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
