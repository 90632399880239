// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Models
import {AssetClassResidentialFacilityModel} from '../_models/asset-class-residential-facility.model';
import {QueryParamsModel} from '../../_base/crud';
import {AssetClassResidentialFacilityActions, AssetClassResidentialFacilityActionTypes} from '../_actions/asset-class-residential-facility.actions';

export interface AssetClassResidentialFacilitiesState extends EntityState<AssetClassResidentialFacilityModel> {
    isAllAssetClassResidentialFacilitiesLoaded: boolean;
    queryRowsCount: number;
    queryResult: AssetClassResidentialFacilityModel[];
    lastCreatedResidentialTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassResidentialFacilityModel> = createEntityAdapter<AssetClassResidentialFacilityModel>();

export const initialAssetClassResidentialFacilitiesState: AssetClassResidentialFacilitiesState = adapter.getInitialState({
    isAllAssetClassResidentialFacilitiesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedResidentialTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassResidentialFacilitiesReducer(state = initialAssetClassResidentialFacilitiesState, action: AssetClassResidentialFacilityActions): AssetClassResidentialFacilitiesState {
    switch (action.type) {
        case AssetClassResidentialFacilityActionTypes.AssetClassResidentialFacilityActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AssetClassResidentialFacilityActionTypes.AllAssetClassResidentialFacilitiesLoaded:
            return adapter.addAll(action.payload.assetClassResidentialFacilities, {
                ...state, isAllAssetClassResidentialFacilitiesLoaded: true
            });
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
