import {PlaningStatus} from '../_models/planing-status.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PlaningStatusesState} from '../_reducers/planing-status.reducers';
import * as fromPlaningStatus from '../_reducers/planing-status.reducers';
import {each} from 'lodash';

export const selectPlaningStatusesState = createFeatureSelector<PlaningStatusesState>('planingStatuses');

export const selectPlaningStatusById = (planingStatusId: number) => createSelector(
    selectPlaningStatusesState,
    planingStatusesState => planingStatusesState.entities[planingStatusId]
);

export const selectAllPlaningStatuses = createSelector(
    selectPlaningStatusesState,
    fromPlaningStatus.selectAll
);

export const selectAllPlaningStatusesIds = createSelector(
    selectPlaningStatusesState,
    fromPlaningStatus.selectIds
);

export const allPlaningStatusesLoaded = createSelector(
    selectPlaningStatusesState,
    planingStatusesState => planingStatusesState.isAllPlaningStatusesLoaded
);


export const selectPlaningStatusesPageLoading = createSelector(
    selectPlaningStatusesState,
    planingStatusesState => planingStatusesState.listLoading
);

export const selectPlaningStatusesActionLoading = createSelector(
    selectPlaningStatusesState,
    planingStatusesState => planingStatusesState.actionLoading
);

export const selectLastCreatedPlaningStatusId = createSelector(
    selectPlaningStatusesState,
    planingStatusesState => planingStatusesState.lastCreatedPlaningStatusId
);

export const selectPlaningStatusesShowInitWaitingMessage = createSelector(
    selectPlaningStatusesState,
    planingStatusesState => planingStatusesState.showInitWaitingMessage
);

export const selectTrashedPlaningStatusCount = createSelector(
    selectPlaningStatusesState,
    (planingStatusesState) => planingStatusesState.totalTrashed
);

export const selectAdminTrashedPlaningStatusCount = createSelector(
    selectPlaningStatusesState,
    (planingStatusesState) => planingStatusesState.totalAdminTrashed
);


export const selectPlaningStatusQueryResult = createSelector(
    selectPlaningStatusesState,
    planingStatusesState => {
        const items: PlaningStatus[] = [];
        each(planingStatusesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PlaningStatus[] = httpExtension.sortArray(items, planingStatusesState.lastQuery.sortField, planingStatusesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, planingStatusesState.totalCount, '', planingStatusesState.totalTrashed);
    }
);
