// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {UnitMeasurementActions, UnitMeasurementActionTypes} from '../_actions/unit-measurement.actions';
// Models
import {UnitMeasurement} from '../_models/unit-measurement.model';
import {QueryParamsModel} from '../../_base/crud';

export interface UnitMeasurementsState extends EntityState<UnitMeasurement> {
    isAllUnitMeasurementsLoaded: boolean;
    queryRowsCount: number;
    queryResult: UnitMeasurement[];
    lastCreatedUnitMeasurementId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<UnitMeasurement> = createEntityAdapter<UnitMeasurement>();

export const initialUnitMeasurementsState: UnitMeasurementsState = adapter.getInitialState({
    isAllUnitMeasurementsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedUnitMeasurementId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function unitMeasurementsReducer(state = initialUnitMeasurementsState, action: UnitMeasurementActions): UnitMeasurementsState {
    switch (action.type) {
        case UnitMeasurementActionTypes.UnitMeasurementsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedUnitMeasurementId: undefined
            };
        case UnitMeasurementActionTypes.UnitMeasurementsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case UnitMeasurementActionTypes.UnitMeasurementOnServerCreated:
            return {
                ...state
            };
        case UnitMeasurementActionTypes.UnitMeasurementCreated:
            return adapter.addOne(action.payload.unitMeasurement, {
                ...state, lastCreatedUnitMeasurementId: action.payload.unitMeasurement.id
            });
        case UnitMeasurementActionTypes.UnitMeasurementUpdated:
            return adapter.updateOne(action.payload.partialunitMeasurement, state);
        case UnitMeasurementActionTypes.AllUnitMeasurementsLoaded:
            return adapter.addAll(action.payload.unitMeasurements, {
                ...state, isAllUnitMeasurementsLoaded: true
            });
        case UnitMeasurementActionTypes.UnitMeasurementsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case UnitMeasurementActionTypes.UnitMeasurementsPageLoaded:
            return adapter.addMany(action.payload.unitMeasurements, {
                ...initialUnitMeasurementsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case UnitMeasurementActionTypes.UnitMeasurementDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case UnitMeasurementActionTypes.UnitMeasurementRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case UnitMeasurementActionTypes.UnitMeasurementDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case UnitMeasurementActionTypes.UnitMeasurementAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case UnitMeasurementActionTypes.UnitMeasurementDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case UnitMeasurementActionTypes.UnitMeasurementTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
