<div class="card h-100">
    <div class="card-header info-header">
        <span>Team Involvement</span>
    </div>
    <div class="card-body info-body">
        <table *ngIf="viewModel" class="table">
            <tbody>
                <tr class="info-row"><th>Project Manager :</th><td>
                    <span [ngbPopover]="popContent"
                    [popoverTitle]="viewModel.projectManager.fullName" 
                    triggers="manual"
                    placement="right"
                    class="clickable"
                    #pPM="ngbPopover" (click)="showUserInfo($event, pPM, viewModel.projectManager)">{{viewModel.projectManager.fullName}}</span>
                </td></tr>
                <tr class="info-row"><th>Lead Valuer :</th><td>
                    <span [ngbPopover]="popContent"
                    [popoverTitle]="viewModel.leadValuer.fullName"
                    triggers="manual"
                    placement="right"
                    class="clickable"
                    #pLV="ngbPopover" (click)="showUserInfo($event, pLV, viewModel.leadValuer)">{{viewModel.leadValuer.fullName}}</span></td></tr>
                <tr class="info-row"><th>Inspection :</th><td>
                    <span [ngbPopover]="popContent"
                    [popoverTitle]="viewModel.inspection.fullName"
                    triggers="manual"
                    placement="right"
                    class="clickable"
                    #pI="ngbPopover" (click)="showUserInfo($event, pI, viewModel.inspection)">{{viewModel.inspection.fullName}}</span></td></tr>
                <tr class="info-row"><th>Valuer :</th><td>
                    <span [ngbPopover]="popContent"
                    [popoverTitle]="viewModel.valuer.fullName"
                    triggers="manual"
                    placement="right"
                    class="clickable"
                    container="body"
                    #pV="ngbPopover" (click)="showUserInfo($event, pV, viewModel.valuer)">
                    {{viewModel.valuer.fullName}}
                    </span></td></tr>
                <tr class="info-row"><th>Documents:</th><td>(Please see table below)</td></tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #popContent 
    let-role="role" let-qualification="qualification"
    let-email="email" let-agency="agency" let-user_pic="user_pic" >
    <div style="display: flex; align-items: center;">
        <div class="image_container mr-3">
            <img [src]="user_pic" (error)="imgOnError($event)">
        </div>
        <div>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Role:</span> {{role}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Qualification:</span> {{qualification}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Email:</span> {{email}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Agency:</span> {{ agency}}</p>
        </div>
    </div>
</ng-template>