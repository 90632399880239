<div class="card h-100">
    <div class="card-header info-header">
        <span>Valuation Specifics</span>
    </div>
    <div class="card-body info-body">
        <table *ngIf="viewModel" class="table">
            <tbody>
                <tr><th>Client :</th><td>{{viewModel.client}}</td></tr>
                <tr><th>Purpose of Valuation :</th><td>{{viewModel.purposeOfValuation}}</td></tr>
                <tr><th>Basis of Value :</th><td>{{viewModel.baseOfValue}}</td></tr>
                <tr><th>Approach :</th><td>{{viewModel.approachToValue}}</td></tr>
                <tr><th>Method :</th><td>{{viewModel.methodToValue}}</td></tr>
                <tr><th>Premise of Value :</th><td>{{viewModel.premiseOfValue}}</td></tr>
                <tr><th>Tenure :</th><td>{{viewModel.tenure}}</td></tr>
            </tbody>
        </table>
    </div>
</div>