// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {StorageTypesService} from '../_services/storage-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allStorageTypesLoaded} from '../_selectors/storage-type.selectors';
// Actions
import {
    AllStorageTypesLoaded,
    AllStorageTypesRequested,
    StorageTypeActionTypes,
    StorageTypesPageRequested,
    StorageTypesPageLoaded,
    StorageTypeUpdated,
    StorageTypesPageToggleLoading,
    StorageTypeDeleted,
    StorageTypeOnServerCreated,
    StorageTypeCreated,
    StorageTypeAdminRestored,
    StorageTypeDeletedFromAdminTrash,
    StorageTypeDeletedFromTrash,
    StorageTypeOnServerAdminRestored,
    StorageTypeOnServerRestored,
    StorageTypeRestored,
    StorageTypeTrashFlushed,
    StorageTypesActionToggleLoading
} from '../_actions/storage-type.actions';

@Injectable()
export class StorageTypeEffects {
    showPageLoadingDispatcher = new StorageTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new StorageTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new StorageTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllStorageTypes$ = this.actions$
        .pipe(
            ofType<AllStorageTypesRequested>(StorageTypeActionTypes.AllStorageTypesRequested),
            withLatestFrom(this.store.pipe(select(allStorageTypesLoaded))),
            filter(([action, isAllStorageTypesLoaded]) => !isAllStorageTypesLoaded),
            mergeMap(() => this.service.getAllStorageTypes()),
            map(res => {
                return new AllStorageTypesLoaded({storageTypes: res.data});
            })
        );

    @Effect()
    loadStorageTypesPage$ = this.actions$
        .pipe(
            ofType<StorageTypesPageRequested>(StorageTypeActionTypes.StorageTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findStorageTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new StorageTypesPageLoaded({
                    storageTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeDeleted>(StorageTypeActionTypes.StorageTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteStorageType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeUpdated>(StorageTypeActionTypes.StorageTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateStorageType(payload.storageType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeOnServerCreated>(StorageTypeActionTypes.StorageTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createStorageType(payload.storageType).pipe(
                    tap(res => {
                        this.store.dispatch(new StorageTypeCreated({storageType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushStorageTypeTrash$ = this.actions$
        .pipe(
            ofType<StorageTypeTrashFlushed>(StorageTypeActionTypes.StorageTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeOnServerRestored>(StorageTypeActionTypes.StorageTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new StorageTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeOnServerAdminRestored>(StorageTypeActionTypes.StorageTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new StorageTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeDeletedFromTrash>(StorageTypeActionTypes.StorageTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashStorageType$ = this.actions$
        .pipe(
            ofType<StorageTypeDeletedFromAdminTrash>(StorageTypeActionTypes.StorageTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: StorageTypesService, private store: Store<AppState>) {
    }
}
