import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllLandAdjustmentsLoaded,
    AllLandAdjustmentsRequested,
    LandAdjustmentActionToggleLoading,
    LandAdjustmentAdminRestored,
    LandAdjustmentCreated,
    LandAdjustmentDeleted,
    LandAdjustmentDeletedFromAdminTrash,
    LandAdjustmentDeletedFromTrash,
    LandAdjustmentOnServerAdminRestored,
    LandAdjustmentOnServerCreated,
    LandAdjustmentOnServerRestored,
    LandAdjustmentRestored,
    LandAdjustmentsActionTypes,
    LandAdjustmentsPageLoaded,
    LandAdjustmentsPageRequested,
    LandAdjustmentsPageToggleLoading,
    LandAdjustmentTrashFlushed,
    LandAdjustmentUpdated
} from '../_actions/land-adjustment.actions';
import {allLandAdjustmentsLoaded} from '../_selectors/land-adjustment.selectors';
import {LandAdjustmentsService} from '../_services/land-adjustment.service';

@Injectable()
export class LandAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: LandAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new LandAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new LandAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new LandAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllLandAdjustmentsRequested>(LandAdjustmentsActionTypes.AllLandAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allLandAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllLandAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<LandAdjustmentsPageRequested>(LandAdjustmentsActionTypes.LandAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new LandAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentUpdated>(LandAdjustmentsActionTypes.LandAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentOnServerCreated>(LandAdjustmentsActionTypes.LandAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new LandAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<LandAdjustmentTrashFlushed>(LandAdjustmentsActionTypes.LandAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentOnServerRestored>(LandAdjustmentsActionTypes.LandAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new LandAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentOnServerAdminRestored>(LandAdjustmentsActionTypes.LandAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new LandAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentDeleted>(LandAdjustmentsActionTypes.LandAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentDeletedFromTrash>(LandAdjustmentsActionTypes.LandAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<LandAdjustmentDeletedFromAdminTrash>(LandAdjustmentsActionTypes.LandAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}