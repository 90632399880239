<div class="card card-custom" 
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="city">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
            <mat-icon
                    class="pl-2 pt-1 icon-gray cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    placement="bottom"
                    [popoverTitle]="'TOOLTIP.CITY.FORM'+(city && city.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.CITY.FORM'+(city && city.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
                    popoverClass="testUI">help
            </mat-icon>
        </div>
    </div>
    <div *ngIf="city">
        <form class="form" [formGroup]="cityForm">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="form-group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'CITY.FORM.FIELD.GENERAL.COUNTRY.PLACEHOLDER' | translate" (selectionChange)="changeCountry($event)"
                                        [(ngModel)]="city.country_id"
                                        formControlName="country_id">
                                <mat-option>
                                    <div (click)="addCountry()" class="add-entry">
                                        <i class="ki ki-plus"></i> New Country
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</mat-option>
                            </mat-select>
                        <mat-error
                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('CITY.FORM.FIELD.GENERAL.COUNTRY.NAME' | translate)
                            }"
                        >
                        </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput type="text" id="autoAddress" 
                            [placeholder]="'CITY.FORM.FIELD.GENERAL.CITY.PLACEHOLDER' | translate" formControlName="name" [(ngModel)]="city.name" 
                            (ngModelChange)="search()" required #addressInputAutocomplete [matAutocomplete]="auto" />
                        
                            <mat-autocomplete id="autoAddressDropdown" #auto="matAutocomplete" (optionSelected)="placeSelected($event)">
                                <mat-option *ngFor="let place of placeOptions | async" [value]="place.structured_formatting.main_text">{{place.description}}</mat-option>
                            </mat-autocomplete>
                        
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CITY.FORM.FIELD.GENERAL.CITY.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 border-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="false" cdkFocusInitial 
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.CANCEL.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.CANCEL.LABEL' | translate }}
                            </button>&nbsp;
                            <button type="button" [disabled]="!isFormValid()" mat-raised-button class="btn btn-success" color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.SAVE.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.SAVE.LABEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

