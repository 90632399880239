import {BaseModel} from '../../_base/crud';
import {User} from '../../auth';
export class AssignmentModel extends BaseModel {
    id: number;
    name: string;
    status: number;
    valuer_id: number;
    manager: User;
    agency_id: number;
    agency_name: string;

    toe_cnt: number;
    audit_cnt: number;
    relation_cnt: number;
    completion: number;
    interaction_type_id: number;

    insertion_date: string;
    pointOfContact: string;
    description: string;

    created_at: Date;
    deleted_at: Date;
    user_deleted: Date;
    updated_at: Date;

    deletedBy: string;
    userDeletedBy: string;
    createdBy: string;
    lastUpdatedBy: string;

    clear() {
        this.name = '';
        this.status = 0;
        this.valuer_id = undefined;
        this.agency_id = undefined;
        this.agency_name = '';

        this.toe_cnt = 0;
        this.relation_cnt = 0;
        this.audit_cnt = 0;
        this.completion = 0;
        this.interaction_type_id = undefined;

        this.insertion_date = '';
        this.pointOfContact = '';
        this.description = '';

        this.deletedBy = '';
        this.userDeletedBy = '';

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = new Date();
        this.user_deleted = new Date();
    }
}