import { P } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AllPaymentTypesRequested, PaymentType, selectAllPaymentTypes } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ToeInvoicePaymentService } from 'src/app/core/toe';

@Component({
  selector: 'kt-edit-invoice-payment-info',
  templateUrl: './edit-invoice-payment-info.component.html',
  styleUrls: ['./edit-invoice-payment-info.component.scss']
})
export class EditInvoicePaymentInfoComponent implements OnInit {
  viewLoading = false;
  form: UntypedFormGroup;
  invoices: any[];
  invoicePayment: any;
  currency: string;
  paymentTypes: PaymentType[];
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditInvoicePaymentInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private invoiceService: ToeInvoicePaymentService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.invoices = this.data.invoices.sort(function(a, b){return (a.term ? (a.term.order-b.term.order) : (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()))});
    this.invoicePayment = this.data.invoicePayment;
    this.currency = this.data.currency

    this.form = this.fb.group({
      invoice_id: [this.data.invoicePayment.invoice_id, Validators.required],
      payment_type_id: [this.data.invoicePayment.payment_type_id, Validators.required],
      payment_date: [this.data.invoicePayment.payment_date ? moment(this.data.invoicePayment.payment_date) : moment(new Date())],
      amount: [this.data.invoicePayment.amount],
      comment: [this.data.invoicePayment.comment],
    });

    this.form.controls.invoice_id.valueChanges.subscribe(value => {
      const selected_invoice = this.invoices.find((item) => item.id == value);
      this.form.controls.amount.setValue(this.getAmountWithTax(selected_invoice));
    });

    this.store.dispatch(new AllPaymentTypesRequested());
    const paymentTypeSubscription = this.store.pipe(select(selectAllPaymentTypes)).subscribe((res: PaymentType[]) => {
      this.paymentTypes = res;
    });
    this.subscriptions.push(paymentTypeSubscription);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  save() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAllAsTouched();
      })
      return;
    }

    let dateValue = '';
    if (controls.payment_date.value) {
      dateValue = controls.payment_date.value.format('YYYY-MM-DD');
    }

    this.viewLoading = true;
    const changedInvoice = Object.assign({}, this.invoicePayment);
    changedInvoice.id = this.data.invoicePayment.id;
    changedInvoice.toe_id = this.data.toe.id;
    changedInvoice.invoice_id = controls.invoice_id.value;
    changedInvoice.payment_type_id = controls.payment_type_id.value;
    changedInvoice.payment_date = dateValue;
    changedInvoice.amount = controls.amount.value;
    changedInvoice.comment = controls.comment.value;

    if (this.data.invoicePayment.id) {
      this.invoiceService.updateToeInvoicePayment(changedInvoice).subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({item: null});
      });
    } else {
      this.invoiceService.createToeInvoicePayment(changedInvoice).subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({item: null});
      });
    }
  }
  getTaskName(invoice, toe_invoices) {
    if (invoice.term) { // regular invoice
      const total = toe_invoices.filter((el) => el.payment_term_id != null).length;
      return invoice.invoice_number + (total > 1 ? ' ' + invoice.term.order + '/' + total : '');
    } else if (invoice.payment_amendment && invoice.task.details_name.includes('Invoice -') && invoice.task.details_name.includes('(')) { // amendment invoice
      return invoice.invoice_number + ' ' + invoice.task.details_name.substring(invoice.task.details_name.indexOf('- ') + 1, invoice.task.details_name.indexOf('('));
    } else if (invoice.payment_amendment && invoice.task.details_name.includes('Payment')) { // amendment invoice
      return invoice.invoice_number + ' Amendment';
    } else{
      return invoice.invoice_number;
    }
  }
  public getAmountWithTax(invoice) {
    if (invoice.payment_amendment) {
      const total = invoice.invoice_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    }
    return invoice.invoice_amount;
  }
}
