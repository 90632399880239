import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { AdjustmentTemplateModel } from "../_models";
import { AdjustmentTemplateState } from "../_reducers";

export const selectAdjustmentTemplateState = createFeatureSelector<AdjustmentTemplateState>('adjustment-template');

export const selectIsLoading = createSelector(
    selectAdjustmentTemplateState,
    state => state.isLoading
)

export const selectTotal = createSelector(
    selectAdjustmentTemplateState,
    state => state.total
)

export const selectTemplate = (id: number) => createSelector(
    selectAdjustmentTemplateState,
    state => state.entities[id]
)

export const selectAdjustmentTemplates = createSelector(
    selectAdjustmentTemplateState,
    state => {
        const items: AdjustmentTemplateModel[] = [];
        each(state.entities, el => items.push(el));
        return items.sort((a, b) => (b.id - a.id));
    }
)