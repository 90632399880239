import {TrainingDocument} from '../_models/training-document.model';
// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {TrainingDocumentsState} from '../_reducers/training-document.reducers';
import * as fromTrainingDocument from '../_reducers/training-document.reducers';
import {each} from 'lodash';

export const selectTrainingDocumentsState = createFeatureSelector<TrainingDocumentsState>('trainingDocuments');

export const selectTrainingDocumentById = (trainingDocumentId: number) => createSelector(
    selectTrainingDocumentsState,
    _state => _state.entities[trainingDocumentId]
);

export const selectAllTrainingDocuments = createSelector(
    selectTrainingDocumentsState,
    fromTrainingDocument.selectAll
);

export const selectAllTrainingDocumentsIds = createSelector(
    selectTrainingDocumentsState,
    fromTrainingDocument.selectIds
);

export const allTrainingDocumentsLoaded = createSelector(
    selectTrainingDocumentsState,
    _state => _state.isAllTrainingDocumentsLoaded
);


export const selectTrainingDocumentsPageLoading = createSelector(
    selectTrainingDocumentsState,
    _state => _state.listLoading
);

export const selectTrainingDocumentsActionLoading = createSelector(
    selectTrainingDocumentsState,
    _state => _state.actionLoading
);

export const selectLastCreatedTrainingDocumentId = createSelector(
    selectTrainingDocumentsState,
    _state => _state.lastCreatedTrainingDocumentId
);

export const selectTrashedTrainingDocumentsCount = createSelector(
    selectTrainingDocumentsState,
    (_state) => _state.totalTrashed
)

export const selectAdminTrashedTrainingDocumentsCount = createSelector(
    selectTrainingDocumentsState,
    (_state) => _state.totalAdminTrashed
);

export const selectTrainingDocumentsShowInitWaitingMessage = createSelector(
    selectTrainingDocumentsState,
    _state => _state.showInitWaitingMessage
);


export const selectTrainingDocumentsQueryResult = createSelector(
    selectTrainingDocumentsState,
    _state => {
        const items: TrainingDocument[] = [];
        each(_state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: TrainingDocument[] = [];
        switch (_state.lastQuery.sortField) {
            case 'name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension
                    .sortArray(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);

        }

        return new QueryResultsModel(result, _state.queryRowsCount, '');
    }
);
