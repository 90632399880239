import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ExternalRefTemplateModel } from "..";
import { LoadExternalRefTemplates, ExternalRefTemplateActionTypes, ExternalRefTemplatesLoaded } from "../_actions";
import { AddExternalRefTemplate, DeleteExternalRefTemplate, DuplicateExternalRefTemplate, ExternalRefTemplateAdded, ExternalRefTemplateUpdated, UpdateExternalRefTemplate } from "../_actions/external-ref-template.actions";
import { ExternalRefTemplateService } from "../_services";

@Injectable()
export class ExternalRefTemplateEffects {
    constructor(
        private actions: Actions,
        private service: ExternalRefTemplateService
    ) {}

    @Effect()
    loadExternalRefTemplate$ =
        this.actions.pipe(
            ofType<LoadExternalRefTemplates>(ExternalRefTemplateActionTypes.LOAD_EXTERNAL_REF_TEMPLATES),
            mergeMap(({payload}) => {
                return this.service.getAll(payload.request)
            }),
            map(res => {
                return new ExternalRefTemplatesLoaded({
                    templates: res.templates,
                    total: res.total
                });
            })
        )

    @Effect()
    addExternalRefTemplate$ =
        this.actions.pipe(
            ofType<AddExternalRefTemplate>(ExternalRefTemplateActionTypes.ADD_EXTERNAL_REF_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.create(payload.template)
            }),
            map(res => {
                return new ExternalRefTemplateAdded({
                    template: res
                })
            })
        )

    @Effect()
    updateExternalRefTemplate$ =
        this.actions.pipe(
            ofType<UpdateExternalRefTemplate>(ExternalRefTemplateActionTypes.UPDATE_EXTERNAL_REF_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.update(payload.id, payload.template)
            }),
            map(res => {
                const newTemplate: Update<ExternalRefTemplateModel> = {
                    id: res.id,
                    changes: {
                        ...res
                    }
                };
                return new ExternalRefTemplateUpdated({
                    id: res.id,
                    template: newTemplate
                })
            })
        )

    @Effect()
    deleteExternalRefTemplate$ = 
        this.actions.pipe(
            ofType<DeleteExternalRefTemplate>(ExternalRefTemplateActionTypes.DELETE_EXTERNAL_REF_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.delete(payload.id),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadExternalRefTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )

    @Effect()
    duplicateExternalRefTemplate$ =
        this.actions.pipe(
            ofType<DuplicateExternalRefTemplate>(ExternalRefTemplateActionTypes.DUPLICATE_EXTERNAL_REF_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.duplicate(payload.template),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadExternalRefTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )
}
