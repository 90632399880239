import { Action } from '@ngrx/store';

export enum ActionTypes {
    UPLOAD_REQUEST = '[File Upload Form] Request',
    UPLOAD = '[Files Upload Form] Upload',

    UPLOAD_CANCEL = '[File Upload Form] Cancel',
    UPLOAD_RESET = '[File Upload Form] Reset',

    UPLOAD_STARTED = '[File Upload API] Started',

    UPLOAD_PROGRESS = '[File Upload API] Progress',

    UPLOAD_FAILURE = '[File Upload API] Failure',
    UPLOAD_COMPLETED = '[File Upload API] Success',

    UPLOAD_TO_TP = '[File Upload API] Upload To TP'
}

export class UploadRequestAction implements Action {
    readonly type = ActionTypes.UPLOAD_REQUEST;
    constructor(public payload: { formData: FormData }) { }
}

export class UploadCancelAction implements Action {
    readonly type = ActionTypes.UPLOAD_CANCEL;
}

export class UploadAction implements Action {
    readonly type = ActionTypes.UPLOAD;
    constructor(public payload: { formData: FormData }) { }
}

export class UploadResetAction implements Action {
    readonly type = ActionTypes.UPLOAD_RESET;
}

export class UploadStartedAction implements Action {
    readonly type = ActionTypes.UPLOAD_STARTED;
}

export class UploadProgressAction implements Action {
    readonly type = ActionTypes.UPLOAD_PROGRESS;
    constructor(public payload: { progress: number }) { }
}

export class UploadFailureAction implements Action {
    readonly type = ActionTypes.UPLOAD_FAILURE;
    constructor(public payload: { error: string }) { }
}

export class UploadCompletedAction implements Action {
    readonly type = ActionTypes.UPLOAD_COMPLETED;
    constructor(public payload: { res: object }) { }
}

export class UploadToTpAction implements Action {
    readonly type = ActionTypes.UPLOAD_TO_TP;
    constructor(public payload: {formData: FormData, tpId: number}) {}
}

export type Actions =
    | UploadRequestAction
    | UploadAction
    | UploadCancelAction
    | UploadResetAction
    | UploadStartedAction
    | UploadProgressAction
    | UploadFailureAction
    | UploadCompletedAction
    | UploadToTpAction;
