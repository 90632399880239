import {RoofStructure} from '../_models/roof-structure.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {RoofStructuresState} from '../_reducers/roof-structure.reducers';
import * as fromRoofStructure from '../_reducers/roof-structure.reducers';
import {each} from 'lodash';

export const selectRoofStructuresState = createFeatureSelector<RoofStructuresState>('roofStructures');

export const selectRoofStructureById = (roofStructureId: number) => createSelector(
    selectRoofStructuresState,
    roofStructuresState => roofStructuresState.entities[roofStructureId]
);

export const selectAllRoofStructures = createSelector(
    selectRoofStructuresState,
    fromRoofStructure.selectAll
);

export const selectAllRoofStructuresIds = createSelector(
    selectRoofStructuresState,
    fromRoofStructure.selectIds
);

export const allRoofStructuresLoaded = createSelector(
    selectRoofStructuresState,
    roofStructuresState => roofStructuresState.isAllRoofStructuresLoaded
);


export const selectRoofStructuresPageLoading = createSelector(
    selectRoofStructuresState,
    roofStructuresState => roofStructuresState.listLoading
);

export const selectRoofStructuresActionLoading = createSelector(
    selectRoofStructuresState,
    roofStructuresState => roofStructuresState.actionLoading
);

export const selectLastCreatedRoofStructureId = createSelector(
    selectRoofStructuresState,
    roofStructuresState => roofStructuresState.lastCreatedRoofStructureId
);

export const selectRoofStructuresShowInitWaitingMessage = createSelector(
    selectRoofStructuresState,
    roofStructuresState => roofStructuresState.showInitWaitingMessage
);

export const selectTrashedRoofStructureCount = createSelector(
    selectRoofStructuresState,
    (roofStructuresState) => roofStructuresState.totalTrashed
);

export const selectAdminTrashedRoofStructureCount = createSelector(
    selectRoofStructuresState,
    (roofStructuresState) => roofStructuresState.totalAdminTrashed
);


export const selectRoofStructureQueryResult = createSelector(
    selectRoofStructuresState,
    roofStructuresState => {
        const items: RoofStructure[] = [];
        each(roofStructuresState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RoofStructure[] = httpExtension.sortArray(items, roofStructuresState.lastQuery.sortField, roofStructuresState.lastQuery.sortOrder);

        return new QueryResultsModel(result, roofStructuresState.totalCount, '', roofStructuresState.totalTrashed);
    }
);
