import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BuildingModel } from 'src/app/core/comparable';
import { AllBuildingTypesRequested, AllFoundationTypesRequested, AllGradesRequested, BuildingType, FoundationType, Grade, selectAllBuildingTypes, selectAllFoundationTypes, selectAllGrades } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { BuildingInfoDialogComponent } from '../../comparable/_sub/building-info-dialog/building-info-dialog.component';

@Component({
  selector: 'kt-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit, OnDestroy {
  @Input() propertySubTypeID: number;
  @Input() inputData: {
    building_id: number;
    building_name: string;
    completion_year: string;
    is_part_of_property: boolean;
    info_about_property: string;
    building_type: number;
    building_type_comment: string;
    building_grade_id: number;
    energy_efficiency_grade_id: number;
    developer: string;
    anchor_tenant: string;
    foundation_type_id: number;
    building_description: string;
    pictures: any[],
    picture: any
  }
  @Input() buildingId: number;
  @Output() centerChange: EventEmitter<any> = new EventEmitter();
  @Output() picChange: EventEmitter<{pictures: any[], picture: any}> = new EventEmitter();
  @Input() centerLat: number;
  @Input() centerLng: number;

  form: UntypedFormGroup;

  grades: Grade[] = [];
  foundationTypes: FoundationType[] = [];
  buildingTypes: BuildingType[] = [];

  private _subscriptions: Subscription[] = [];
  errorFields = [];

  pictures = [];
  picture = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      building_name: [this.inputData.building_name],
      completion_year: [this.inputData.completion_year, Validators.required],
      is_part_of_property: [this.inputData.is_part_of_property],
      info_about_property: [this.inputData.info_about_property],
      building_type: [this.inputData.building_type, Validators.required],
      building_type_comment: [this.inputData.building_type_comment],
      building_grade_id: [this.inputData.building_grade_id, Validators.required],
      energy_efficiency_grade_id: [this.inputData.energy_efficiency_grade_id],
      developer: [this.inputData.developer],
      anchor_tenant: [this.inputData.anchor_tenant],
      foundation_type_id: [this.inputData.foundation_type_id],
      building_description: [this.inputData.building_description],
    })

    this.store.dispatch(new AllGradesRequested());
    const gradeSubscription = this.store.pipe(
        select(selectAllGrades)
    ).subscribe(res => {
        this.grades = [];
        if (res) {
            this.grades = res;
        }
    });
    this._subscriptions.push(gradeSubscription);

    this.store.dispatch(new AllFoundationTypesRequested())
    const foundationSub = this.store.select(selectAllFoundationTypes)
    .subscribe(res => {
        this.foundationTypes = res ? res : []
    });
    this._subscriptions.push(foundationSub);
    this.store.dispatch(new AllBuildingTypesRequested());
    const buildingTypeSub = this.store.select(selectAllBuildingTypes).subscribe(res => {
        this.buildingTypes = res ? res : [];
    });
    this._subscriptions.push(buildingTypeSub);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  checkValidation(isComplete: boolean): boolean {
    let errorFields = []
    let isValid = true;
    const controls = this.form.controls;
    if (isComplete && this.form.invalid) {
      Object.keys(controls).forEach(cname => {
        if (controls[cname].invalid) {
          errorFields.push(cname);
        }
        controls[cname].markAsTouched();
      })
      isValid = false;
    }
    this.errorFields = errorFields;
    return isValid;
  }

  getData(): BuildingComponent['inputData'] {
    const controls = this.form.controls;
    return {
      building_name: controls.building_name.value,
      completion_year: controls.completion_year.value,
      is_part_of_property: controls.is_part_of_property.value,
      info_about_property: controls.info_about_property.value,
      building_type: controls.building_type.value,
      building_type_comment: controls.building_type_comment.value,
      building_grade_id: controls.building_grade_id.value,
      energy_efficiency_grade_id: controls.energy_efficiency_grade_id.value, 
      developer: controls.developer.value, 
      anchor_tenant: controls.anchor_tenant.value, 
      foundation_type_id: controls.foundation_type_id.value, 
      building_description: controls.building_description.value,
      pictures: this.pictures,
      picture: this.picture,
      building_id: this.buildingId
    }
  }

  public clearBuilding() {
      this.buildingId = null;
      const controls = this.form.controls;
      controls.building_name.setValue(null);
      controls.completion_year.setValue(null);
      controls.is_part_of_property.setValue(false);
      controls.info_about_property.setValue(null);
      controls.building_type.setValue(null);
      controls.building_type_comment.setValue(null);
      controls.building_grade_id.setValue(null);
      controls.energy_efficiency_grade_id.setValue(null);
      controls.developer.setValue(null);
      controls.anchor_tenant.setValue(null);
      controls.foundation_type_id.setValue(null);
      controls.building_description.setValue(null);
      this.form.updateValueAndValidity();
      this.pictures = [];
      this.picture = null;
      this.picChange.emit({pictures: this.pictures, picture: this.picture});
  }

  public showExist() {
    const dialogRef = this.dialog.open(BuildingInfoDialogComponent, {
        data: {
            standAloneBuilding: false,
            currentBuildingId: this.buildingId ? this.buildingId : -1,
            lat: this.centerLat,
            lng: this.centerLng,
        }
    });

    dialogRef.afterClosed().subscribe((res: BuildingModel) => {
        if (!res) {
            return;
        }
        const controls = this.form.controls;
        controls.building_name.setValue(res.building_name);
        controls.completion_year.setValue(res.completion_year);
        controls.is_part_of_property.setValue(res.is_part_of_property);
        controls.info_about_property.setValue(res.info_about_property);
        controls.building_type.setValue(res.building_type);
        controls.building_type_comment.setValue(res.building_type_comment);
        controls.building_grade_id.setValue(res.building_grade_id);
        controls.energy_efficiency_grade_id.setValue(res.energy_efficiency_grade_id);
        controls.developer.setValue(res.developer);
        controls.anchor_tenant.setValue(res.anchor_tenant);
        controls.foundation_type_id.setValue(res.foundation_type_id);
        controls.building_description.setValue(res.building_description);
        this.form.updateValueAndValidity();
        this.buildingId = res.id;
        this.centerLat = res.locationData.latitude;
        this.centerLng = res.locationData.longitude;
        this.centerChange.emit(res.locationData);
        this.pictures = res.pictures.map(p => ({...p, pic_type: 2}));
        this.picture = res.picture;
        this.picChange.emit({pictures: this.pictures, picture: this.picture});
    });
  }
}
