import {BaseModel} from '../../_base/crud';

export class AuditTrailParticipantModel extends BaseModel {
    id: number;
    linked_id: number;
    participants_side: string;
    participants_names: string;
    participants_descr: string;
    ref_id: number;
    checked: boolean;
    created_at: Date;
    deleted_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.linked_id = undefined;
        this.participants_side = '';
        this.participants_names = '';
        this.participants_descr = '';
        this.ref_id = null;
        this.checked = false;
        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
