// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {FloorNumberingScheme} from '../_models/floor-numbering-scheme.model';

export enum FloorNumberingSchemeActionTypes {

    AllFloorNumberingSchemesRequested = '[FloorNumberingSchemes Home Page] All FloorNumberingSchemes Requested',
    AllFloorNumberingSchemesLoaded = '[FloorNumberingSchemes API] All FloorNumberingSchemes Loaded',

    FloorNumberingSchemeOnServerCreated = '[Edit FloorNumberingScheme Dialog] FloorNumberingScheme On Server Created',
    FloorNumberingSchemeCreated = '[Edit FloorNumberingSchemes Dialog] FloorNumberingSchemes Created',
    FloorNumberingSchemeUpdated = '[Edit FloorNumberingScheme Dialog] FloorNumberingScheme Updated',
    FloorNumberingSchemeDeleted = '[FloorNumberingSchemes List Page] FloorNumberingScheme Deleted',

    FloorNumberingSchemesPageRequested = '[FloorNumberingSchemes List Page] FloorNumberingSchemes Page Requested',
    FloorNumberingSchemesPageLoaded = '[FloorNumberingSchemes API] FloorNumberingSchemes Page Loaded',
    FloorNumberingSchemesPageCancelled = '[FloorNumberingSchemes API] FloorNumberingSchemes Page Cancelled',

    FloorNumberingSchemesPageToggleLoading = '[FloorNumberingSchemes page] FloorNumberingSchemes Page Toggle Loading',
    FloorNumberingSchemesActionToggleLoading = '[FloorNumberingSchemes] FloorNumberingSchemes Action Toggle Loading',

    // trash system

    FloorNumberingSchemeOnServerRestored = '[FloorNumberingScheme Trash] FloorNumberingScheme On Server Restored',
    FloorNumberingSchemeRestored = '[FloorNumberingScheme Trash] FloorNumberingScheme Restored',

    FloorNumberingSchemeOnServerAdminRestored = '[FloorNumberingScheme Admin Trash] FloorNumberingScheme On Server Restored',
    FloorNumberingSchemeAdminRestored = '[FloorNumberingScheme Admin Trash] FloorNumberingScheme Restored',

    FloorNumberingSchemeDeletedFromTrash = '[FloorNumberingScheme Trash] FloorNumberingScheme deleted',
    FloorNumberingSchemeDeletedFromAdminTrash = '[FloorNumberingScheme Admin Trash] FloorNumberingScheme deleted',

    FloorNumberingSchemeTrash = 'FloorNumberingScheme Trashed',
    FloorNumberingSchemeTrashFlushed = 'FloorNumberingScheme Trash Flushed',

    FloorNumberingSchemesClear = '[FloorNumberingSchemes API] FloorNumberingSchemes Page Clear',
}

export class FloorNumberingSchemeOnServerCreated implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerCreated;

    constructor(public payload: { FloorNumberingScheme: FloorNumberingScheme}) {
    }
}

export class FloorNumberingSchemeCreated implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeCreated;

    constructor(public payload: { FloorNumberingScheme: FloorNumberingScheme }) {
    }
}

export class FloorNumberingSchemeUpdated implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeUpdated;

    constructor(public payload: {
        partialFloorNumberingScheme: Update<FloorNumberingScheme>,
        FloorNumberingScheme: FloorNumberingScheme
    }) {
    }
}

export class FloorNumberingSchemeDeleted implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class FloorNumberingSchemesPageRequested implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class FloorNumberingSchemesPageLoaded implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageLoaded;

    constructor(public payload: { FloorNumberingSchemes: FloorNumberingScheme[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class FloorNumberingSchemesPageCancelled implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageCancelled;
}

export class AllFloorNumberingSchemesRequested implements Action {
    readonly type = FloorNumberingSchemeActionTypes.AllFloorNumberingSchemesRequested;
}

export class AllFloorNumberingSchemesLoaded implements Action {
    readonly type = FloorNumberingSchemeActionTypes.AllFloorNumberingSchemesLoaded;

    constructor(public payload: { FloorNumberingSchemes: FloorNumberingScheme[] }) {
    }
}

export class FloorNumberingSchemesPageToggleLoading implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class FloorNumberingSchemesActionToggleLoading implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class FloorNumberingSchemeDeletedFromAdminTrash implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class FloorNumberingSchemeDeletedFromTrash implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class FloorNumberingSchemeOnServerRestored implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FloorNumberingSchemeRestored implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeRestored;

    constructor(public payload: { item: FloorNumberingScheme }) {
    }
}

export class FloorNumberingSchemeOnServerAdminRestored implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FloorNumberingSchemeAdminRestored implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeAdminRestored;

    constructor(public payload: { item: FloorNumberingScheme }) {
    }
}

export class FloorNumberingSchemeTrashFlushed implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemeTrashFlushed;

    constructor() {
    }
}

export class FloorNumberingSchemesClear implements Action {
    readonly type = FloorNumberingSchemeActionTypes.FloorNumberingSchemesClear;

    constructor() {
    }
}

export type FloorNumberingSchemeActions = FloorNumberingSchemeCreated
    | FloorNumberingSchemeUpdated
    | FloorNumberingSchemeDeleted
    | FloorNumberingSchemesPageRequested
    | FloorNumberingSchemesPageLoaded
    | FloorNumberingSchemesPageCancelled
    | AllFloorNumberingSchemesLoaded
    | AllFloorNumberingSchemesRequested
    | FloorNumberingSchemeOnServerCreated
    | FloorNumberingSchemesPageToggleLoading
    | FloorNumberingSchemesActionToggleLoading
    | FloorNumberingSchemeDeletedFromAdminTrash
    | FloorNumberingSchemeDeletedFromTrash
    | FloorNumberingSchemeOnServerRestored
    | FloorNumberingSchemeRestored
    | FloorNumberingSchemeOnServerAdminRestored
    | FloorNumberingSchemeAdminRestored
    | FloorNumberingSchemeTrashFlushed
    | FloorNumberingSchemesClear;
