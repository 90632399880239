// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AssetClassRetailsState } from '../_reducers/asset-class-retail.reducers';
import { AssetClassRetailModel } from '../_models/asset-class-retail.model';

export const selectAssetClassRetailsState = createFeatureSelector<AssetClassRetailsState>('assetClassRetails');

export const selectAssetClassRetailById = (assetClassRetailId: number) => createSelector(
    selectAssetClassRetailsState,
    assetClassRetailsState => assetClassRetailsState.entities[assetClassRetailId]
);

export const selectAssetClassRetailsPageLoading = createSelector(
    selectAssetClassRetailsState,
    assetClassRetailsState => assetClassRetailsState.listLoading
);

export const selectAssetClassRetailsActionLoading = createSelector(
    selectAssetClassRetailsState,
    assetClassRetailsState => assetClassRetailsState.actionLoading
);

export const selectLastCreatedAssetClassRetailId = createSelector(
    selectAssetClassRetailsState,
    assetClassRetailsState => assetClassRetailsState.lastCreatedAssetClassRetailId
);

export const selectAssetClassRetailsShowInitWaitingMessage = createSelector(
    selectAssetClassRetailsState,
    assetClassRetailsState => assetClassRetailsState.showInitWaitingMessage
);

export const selectAssetClassRetailsInStore = createSelector(
    selectAssetClassRetailsState,
    assetClassRetailsState => {
        const items: AssetClassRetailModel[] = [];
        each(assetClassRetailsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassRetailModel[] = [];
        if (assetClassRetailsState.lastQuery.sortField === 'updated_at') {
            result = httpExtension.sortArrayDateTime(
                items,
                assetClassRetailsState.lastQuery.sortField,
                assetClassRetailsState.lastQuery.sortOrder
            );
        } else {
            result = httpExtension.sortArray(
                items,
                assetClassRetailsState.lastQuery.sortField,
                assetClassRetailsState.lastQuery.sortOrder
            );
        }
        return new QueryResultsModel(result, assetClassRetailsState.totalCount, '', assetClassRetailsState.totalTrashed);
    }
);
