<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{title}}</h3>
        </div>
        <div class="card-toolbar">
			<button mat-button mat-raised-button class="btn-success mr-2" 
				color="warn" aria-label="Close" (click)="onSaveClick()">
                Save
			</button>
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
    </div>
    <div class="card-body">
        <form [formGroup]="form" *ngIf="form">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Criterion Name" formControlName="name" />
                            <mat-error>Name required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Criterion Category" formControlName="category">
                                <ng-container *ngFor="let enum of criterionCategories| keyvalue">
                                    <mat-option *ngIf="!checkEnum(enum.key)"
                                    [value]="enum.key">
                                    {{enum.value}}
                                </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error>Category Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid" *ngIf="form.controls.category.value == criterionCategories.Other">
                            <input matInput placeholder="Category Name" formControlName="categoryName" />
                            <mat-error>Category name required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
