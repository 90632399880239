import {WallsAndPartition} from '../_models/walls-and-partition.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {WallsAndPartitionsState} from '../_reducers/walls-and-partition.reducers';
import * as fromWallsAndPartition from '../_reducers/walls-and-partition.reducers';
import {each} from 'lodash';

export const selectWallsAndPartitionsState = createFeatureSelector<WallsAndPartitionsState>('wallsAndPartitions');

export const selectWallsAndPartitionById = (wallsAndPartitionId: number) => createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => wallsAndPartitionsState.entities[wallsAndPartitionId]
);

export const selectAllWallsAndPartitions = createSelector(
    selectWallsAndPartitionsState,
    fromWallsAndPartition.selectAll
);

export const selectAllWallsAndPartitionsIds = createSelector(
    selectWallsAndPartitionsState,
    fromWallsAndPartition.selectIds
);

export const allWallsAndPartitionsLoaded = createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => wallsAndPartitionsState.isAllWallsAndPartitionsLoaded
);


export const selectWallsAndPartitionsPageLoading = createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => wallsAndPartitionsState.listLoading
);

export const selectWallsAndPartitionsActionLoading = createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => wallsAndPartitionsState.actionLoading
);

export const selectLastCreatedWallsAndPartitionId = createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => wallsAndPartitionsState.lastCreatedWallsAndPartitionId
);

export const selectWallsAndPartitionsShowInitWaitingMessage = createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => wallsAndPartitionsState.showInitWaitingMessage
);

export const selectTrashedWallsAndPartitionCount = createSelector(
    selectWallsAndPartitionsState,
    (wallsAndPartitionsState) => wallsAndPartitionsState.totalTrashed
);

export const selectAdminTrashedWallsAndPartitionCount = createSelector(
    selectWallsAndPartitionsState,
    (wallsAndPartitionsState) => wallsAndPartitionsState.totalAdminTrashed
);


export const selectWallsAndPartitionQueryResult = createSelector(
    selectWallsAndPartitionsState,
    wallsAndPartitionsState => {
        const items: WallsAndPartition[] = [];
        each(wallsAndPartitionsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: WallsAndPartition[] = httpExtension.sortArray(items, wallsAndPartitionsState.lastQuery.sortField, wallsAndPartitionsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, wallsAndPartitionsState.totalCount, '', wallsAndPartitionsState.totalTrashed);
    }
);
