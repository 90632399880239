import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../../core/reducers/index';
import {selectAllResidentialAdjustments} from '../../../../../../../../core/linked-tables/_selectors/residential-adjustments.selectors';
import {ResidentialAdjustmentModel} from '../../../../../../../../core/linked-tables/_models/residential-adjustment.model';
import {ValuationAdjustmentModel} from '../../../../../../../../core/valuation/_models/valuation-adjustment.model';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import { selectDefaultSizeCriterion } from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import { map, filter } from 'rxjs/operators';
import { Behavior } from 'popper.js';

@Component({
    selector: 'kt-adjustment-modal',
    templateUrl: './adjustment-modal.component.html',
    styleUrls: ['./adjustment-modal.component.scss']
})
export class AdjustmentModalComponent implements OnInit, OnDestroy {

    fg: UntypedFormGroup;
    comparables: any[];
    adjustments: ResidentialAdjustmentModel[];

    model: ValuationAdjustmentModel;
    selectedCheckBox: number[];
    subscriptions: Subscription[] = [];

    constructor(private formBuilder: UntypedFormBuilder,
                public dialogRef: MatDialogRef<AdjustmentModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>) {
        this.comparables = data.comparables;
        if (data.model) {
            this.model = data.model;
        } else {
            this.model = new ValuationAdjustmentModel();
            this.model.clear();
            this.selectedCheckBox = [];
            const _tmp: {[id: number]: number} = {};
            this.comparables.forEach((com) => {
                _tmp[com.id] = 0;
            });
            this.model.adjValues = _tmp;
        }
    }

    ngOnInit(): void {
        this.createForm();
        this.store.select(selectAllResidentialAdjustments).subscribe(res => {
            this.adjustments = res;
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    getComponentTitle(): string {
        return this.model && this.model.desc && this.model.desc.length ? 'Edit Adjustment' : 'Add Adjustment'
    }

    createForm() {
        this.fg = this.formBuilder.group({
            adjustment_id: new UntypedFormControl(this.model.adjustment_id, Validators.required),
            name: new UntypedFormControl(this.model.name),
            desc: new UntypedFormControl(this.model.desc, Validators.required),
            analysis: new UntypedFormControl(this.model.analysis)
        })
        this.fg.get('adjustment_id').valueChanges.subscribe(res => {
            if (res !== 0) {
                const adjustment = this.adjustments.find(a => a.id == res);
                const val = adjustment ? adjustment.description : ''
                this.fg.get('desc').setValue(val);
                this.fg.get('desc').updateValueAndValidity();
            }
        })
    }

    changeCheckBox($event, _id) {
        if ($event.checked) {
            this.selectedCheckBox.push(_id);
        } else {
            this.selectedCheckBox = this.selectedCheckBox.filter(el => el != _id);
        }
    }

    onSubmit() {

        const controls = this.fg.controls;
        if (this.fg.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        const responseObject = new ValuationAdjustmentModel();
        responseObject.clear();
        responseObject.adjustment_id = controls.adjustment_id.value;
        if (responseObject.adjustment_id == 0) {

            responseObject.name = controls.name.value;
        }else{

            responseObject.name = this.adjustments.find(el => el.id == responseObject.adjustment_id).name;
        }
        responseObject.desc = controls.desc.value;
        responseObject.analysis = controls.analysis.value;
        // if (this.model.id) {
            const _vals = Object.assign({}, this.model.adjValues) as {[id: number]: number};
            responseObject.adjValues = _vals;
        // }
        this.dialogRef.close(responseObject);
    }

    onClose() {
        this.dialogRef.close();
    }
}
