import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource } from "../../_base/crud";
import { selectAssetClassParkingInStore, selectAssetClassParkingPageLoading, selectAssetClassParkingShowInitWaitingMessage } from "../_selectors/asset-class-parking.selectors";
import { tenureAdder } from "./tenure_extension";

export class AssetClassParkingDataSource extends BaseDataSource {
    trashed = 0;

    constructor(
        private store: Store<AppState>
    ) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssetClassParkingPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssetClassParkingShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssetClassParkingInStore)
        ).subscribe(response => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.trashed = response.trashed;
            this.entitySubject.next(response.items.map(e => tenureAdder(e)));
        })
    }
}