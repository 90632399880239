// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {FloorTypeActions, FloorTypeActionTypes} from '../_actions/floor-type.actions';
// Models
import {FloorType} from '../_models/floor-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface FloorTypesState extends EntityState<FloorType> {
    isAllFloorTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: FloorType[];
    lastCreatedFloorTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<FloorType> = createEntityAdapter<FloorType>();

export const initialFloorTypesState: FloorTypesState = adapter.getInitialState({
    isAllFloorTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedFloorTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function floorTypesReducer(state = initialFloorTypesState, action: FloorTypeActions): FloorTypesState {
    switch (action.type) {
        case FloorTypeActionTypes.FloorTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedFloorTypeId: undefined
            };
        case FloorTypeActionTypes.FloorTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case FloorTypeActionTypes.FloorTypeOnServerCreated:
            return {
                ...state
            };
        case FloorTypeActionTypes.FloorTypeCreated:
            return adapter.addOne(action.payload.floorType, {
                ...state, lastCreatedFloorTypeId: action.payload.floorType.id
            });
        case FloorTypeActionTypes.FloorTypeUpdated:
            return adapter.updateOne(action.payload.partialFloorType, state);
        case FloorTypeActionTypes.AllFloorTypesLoaded:
            return adapter.addAll(action.payload.floorTypes, {
                ...state, isAllFloorTypesLoaded: true
            });
        case FloorTypeActionTypes.FloorTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case FloorTypeActionTypes.FloorTypesPageLoaded:
            return adapter.addMany(action.payload.floorTypes, {
                ...initialFloorTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case FloorTypeActionTypes.FloorTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case FloorTypeActionTypes.FloorTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case FloorTypeActionTypes.FloorTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case FloorTypeActionTypes.FloorTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case FloorTypeActionTypes.FloorTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case FloorTypeActionTypes.FloorTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
