import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { TemplateRequest, AdjustmentTemplateModel } from "../_models";

export enum AdjustmentTemplateActionTypes {
    LOAD_ADJUSTMENT_TEMPLATES = '[Adjustment Template List] Load Adjustment Templates',
    ADJUSTMENT_TEMPLATES_LOADED = '[Adjustment Template Effect] Adjustment Templates Loaded',

    ADD_ADJUSTMENT_TEMPLATE = '[Adjustment Template Edit] Add New Adjustment Template',
    ADJUSTMENT_TEMPLATE_ADDED = '[Tp Tempalte Effect] New Adjustment Template Added',
     
    UPDATE_ADJUSTMENT_TEMPLATE = '[Adjustment Template Edit] Update Adjustment Template',
    ADJUSTMENT_TEMPLATE_UPDATED = '[Adjustment Template Effect] Adjustment Template Updated',

    DELETE_ADJUSTMENT_TEMPLATE = '[Adjustment Template List] Delete Adjustment Template',
    ADJUSTMENT_TEMPLATE_DELETED = '[Adjustment Template Effect] Adjustment Template Deleted',

    DUPLICATE_ADJUSTMENT_TEMPLATE = '[Adjustment Template List] Duplicate Adjustment Template',
}

export class LoadAdjustmentTemplates implements Action {
    readonly type = AdjustmentTemplateActionTypes.LOAD_ADJUSTMENT_TEMPLATES;
    constructor(public payload: {
        request: TemplateRequest
    }) {}
}

export class AdjustmentTemplatesLoaded implements Action {
    readonly type = AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATES_LOADED;
    constructor(public payload: {
        templates: AdjustmentTemplateModel[],
        total: number
    }) {}
}

export class AddAdjustmentTemplate implements Action {
    readonly type = AdjustmentTemplateActionTypes.ADD_ADJUSTMENT_TEMPLATE;
    constructor(public payload: {
        template: AdjustmentTemplateModel
    }) {}
}

export class AdjustmentTemplateAdded implements Action {
    readonly type = AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATE_ADDED;
    constructor(public payload: {
        template: AdjustmentTemplateModel
    }) {}
}

export class UpdateAdjustmentTemplate implements Action {
    readonly type = AdjustmentTemplateActionTypes.UPDATE_ADJUSTMENT_TEMPLATE;
    constructor(public payload: {
        id: number;
        template: AdjustmentTemplateModel
    }) {}
}

export class AdjustmentTemplateUpdated implements Action {
    readonly type = AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATE_UPDATED;
    constructor(public payload: {
        id: number;
        template: Update<AdjustmentTemplateModel>
    }) {}
}

export class DeleteAdjustmentTemplate implements Action {
    readonly type = AdjustmentTemplateActionTypes.DELETE_ADJUSTMENT_TEMPLATE;
    constructor(public payload: {
        id: number,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export class AdjustmentTemplateDeleted implements Action {
    readonly type = AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATE_DELETED;
    constructor(public payload: {id: number}) {}
}

export class DuplicateAdjustmentTemplate implements Action {
    readonly type = AdjustmentTemplateActionTypes.DUPLICATE_ADJUSTMENT_TEMPLATE;
    constructor(public payload: {
        template: AdjustmentTemplateModel,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export type AdjustmentTemplateActions
    = LoadAdjustmentTemplates
    | AdjustmentTemplatesLoaded
    | AddAdjustmentTemplate
    | AdjustmentTemplateAdded
    | UpdateAdjustmentTemplate
    | AdjustmentTemplateUpdated
    | DeleteAdjustmentTemplate
    | AdjustmentTemplateDeleted
    | DuplicateAdjustmentTemplate;