import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassHouseModel } from "../_models/asset-class-house.model";
import { SubdomainService } from "../../_base/subdomain.service";

const AC_PATH = 'houses';

@Injectable()
export class AssetClassHouseService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/`;
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const headers = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };



        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.get<QueryResultsModel>(url, {
            headers,
            params
        });
    }

    duplicate(id: number) {
        const headers = this.authService.getAuthHeaders()
        const url = this.API_COMPARABLES_URL + AC_PATH
        return this.http.post<any>(url + `/${id}/copy`, {}, {headers})
    }

    getAssetClass(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH;
        const headers = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/${id}`, {headers});
    }

    createAssetClass(
        _AssetClassHouse: AssetClassHouseModel,
        _fileList: any[]
    ): Observable<any> {
        const headers = this.authService.getAuthHeaders();
        const body = {
            assetClassHouse: _AssetClassHouse,
            fileList: _fileList
        };
        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.post<any>(url, body, {headers});
    }

    updateAssetClass(
        _AssetClassHouse: AssetClassHouseModel,
        _fileList: any[]
    ): Observable<any> {
        const headers = this.authService.getAuthHeaders();
        const body = {
            assetClassHouse: _AssetClassHouse,
            fileList: _fileList
        };
        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.put(url + `/${_AssetClassHouse.id}`, body, {headers});
    }

    deleteAssetClass(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH + `/${id}`;
        const headers = this.authService.getAuthHeaders();
        return this.http.delete<any>(url, {headers});
    }

    getTrashed(): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH + '/trash';
        const headers=  this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers});
    }

    deleteFromTrash(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH + `/trash/${id}`;
        const headers=  this.authService.getAuthHeaders();
        return this.http.delete<any>(url, {headers});
    }

    deleteFromAdminTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/houses/admin/trash/${id}`,
            {headers: httpHeaders});
    }

    getAdminTrashed(): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH + '/admin/trash';
        const headers=  this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers});
    }

    restoreFromTrash(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH + `/trash/${id}`;
        const headers=  this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers});
    }

    flushTrash(): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH + `/trash/flush`;
        const headers=  this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers});
    }
}