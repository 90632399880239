import { BaseModel } from '../../_base/crud';
import { ReportType } from './report-types.enum';

export class PropertyDetailReportModel extends BaseModel {
    id: number;
    tp_id: number;
    status: number;

    type = ReportType.PropertyDetailReport;

    url: string;
    file_size: string;
    file_name: string;
    description: string;

    generated_user_id: number;
    generated_user_name: string;

    created_at: Date;
    updated_at: Date;

    public clear() {
        this.id = undefined;
        this.tp_id = undefined;
        this.status = undefined;
        
        this.url = "";
        this.file_size = "";
        this.file_name = "";

        this.generated_user_id = undefined;
        this.generated_user_name = "";
    }
}