import { createFeatureSelector, createSelector } from '@ngrx/store'
import { LiquidationValuationState, featureKey } from '../_reducers/liquidation-valuation.reducer'

const selectFeatureState = createFeatureSelector<LiquidationValuationState>(featureKey)

export const selectLiquidationFormValue = createSelector(
  selectFeatureState,
  state => {
    return {
      importOtherValuation: state.importOtherValuation,
      selectedValuationId: state.selectedValuationId
    }
  }
)

export const selectLiquidationValueEstimateFormValue = createSelector(
  selectFeatureState,
  state => {
    return {
      disposalPeriod: state.disposalPeriod,
      disposalPeriodType: state.disposalPeriodType,
      addDiscount: state.addDiscount,
      discount: state.discount
    }
  }
)

export const selectLiquidationValuationState = createSelector(
  selectFeatureState,
  state => {
    return {
      importOtherValuation: state.importOtherValuation,
      selectedValuationId: state.selectedValuationId,
      disposalPeriod: state.disposalPeriod,
      disposalPeriodType: state.disposalPeriodType,
      addDiscount: state.addDiscount,
      discount: state.discount
    }
  }
)