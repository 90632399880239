<div>
    <div ktPortletTitle *ngIf="!hideTitle" class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">SIZES {{addingTitle}}</h5>
        <hr class="active">
    </div>
    <ng-container *ngIf="lowLevelTitle">
        <div class="col-lg-12 mb-0 multimedia-header item-center pl-0">
            <h5 class="my-3 text-black text-uppercase">
                {{lowLevelTitle}}
            </h5>
        </div>
    </ng-container>
    <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
        <button
            *ngIf="buttonEnable$|async"
                class="btn btn-primary"
                color="primary"
                (click)="addItem()"
                matTooltip="Add a New size"
                type="button">Add sizes
        </button>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>{{assetClassTypeName}} Measurement Standards</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{getItemName(item.standard_measurement_id)}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unit_of_area_measurement_name">
            <mat-header-cell *matHeaderCellDef>Unit type</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.unit_of_area_measurement_name}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="size">
            <mat-header-cell *matHeaderCellDef> Size {{staticUnitArea ? '('+staticUnitArea.acronym+')' : ''}}</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.size | mask: 'separator,'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let item; let i = index;">
                <button mat-icon-button
                        color="primary"
                        matTooltip="Edit Measurement"
                        [disabled]="readonly"
                        (click)="editItem(item, i)"
                        type="button">
                    <mat-icon>create</mat-icon>
                </button>&nbsp;
                <button mat-icon-button
                        color="warn"
                        matTooltip="Delete Measurement"
                        [disabled]="readonly"
                        (click)="deleteItem(item)"
                        type="button">
                    <mat-icon>delete</mat-icon>
                </button>&nbsp;
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>