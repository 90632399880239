import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ExternalRefTemplateDataSource, ExternalRefTemplateModel, ExternalRefTemplateState, LoadExternalRefTemplates } from 'src/app/core/template';
import { PaginationEvent } from '../../../template/template-list/template-list.component';

@Component({
  selector: 'kt-external-ref-template-list',
  templateUrl: './external-ref-template-list.component.html',
  styleUrls: ['./external-ref-template-list.component.scss']
})
export class ExternalRefTemplateListComponent implements OnInit {
  dataSource: ExternalRefTemplateDataSource;
  pageSize = 4;

  constructor(
    public dialogRef: MatDialogRef<ExternalRefTemplateListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<ExternalRefTemplateState>
  ) { }

  ngOnInit(): void {
    this.dataSource = new ExternalRefTemplateDataSource(this.store);
    this.store.dispatch(new LoadExternalRefTemplates({
      request: {
        pageNumber: 1,
        pageSize: this.pageSize
      }
    }));
  }

  loadList(event: PaginationEvent) {
    this.store.dispatch(new LoadExternalRefTemplates({
      request: {
        pageNumber: event.pageNumber + 1,
        pageSize: event.pageSize
      }
    }));
  }

  selectTemplate(event: ExternalRefTemplateModel) {
    this.dialogRef.close({
      type: 'select',
      template: event
    });
  }

  viewTemplate(event: ExternalRefTemplateModel) {
    this.dialogRef.close({
      type: 'view',
      template: event
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
