import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { Source, Status } from 'src/app/core/valuation/_models/valuation-assumption-departure.model';
import { ValuationVpga10Matter} from 'src/app/core/valuation/_models/vpga10-matter.model';
import * as valuationVpga10MattersSelectors from 'src/app/core/valuation/_selectors/vpga10-matters.selectors';
import * as valuationVpga10MatterActions from 'src/app/core/valuation/_actions/vpga10-matters.actions';
import { EditVpga10MatterComponent } from '../_modals/edit-vpga10-matter/edit-vpga10-matter.component';
import { ToeModel } from 'src/app/core/toe';
import { AssetClassModel } from 'src/app/core/asset_class';
import { selectCountryById } from 'src/app/core/admin';
import { take } from 'rxjs/operators';
import { DeleteEntityDialogComponent, DeleteEntityWithDescrDialogComponent } from 'src/app/views/partials/content/crud';
import { RestoreModalComponent } from '../_modals/restore-modal/restore-modal.component';

@Component({
  selector: 'kt-vpga10-table',
  templateUrl: './vpga10-table.component.html',
  styleUrls: ['./vpga10-table.component.scss']
})
export class Vpga10TableComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() toe: ToeModel;
  @Input() targetProperty: AssetClassModel;
  dataSource: MatTableDataSource<ValuationVpga10Matter> = new MatTableDataSource();
  source = Source;
  status = Status;
  subscriptions: Subscription[] = [];

  columns = ['source', 'input', 'input-status', 'justification', 'actions']

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const sub = this.store$.select(valuationVpga10MattersSelectors.selectAll).subscribe(res => {
      this.dataSource.data = res;
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addVpga10Matter() {
    const dialogRef = this.dialog.open(EditVpga10MatterComponent, {
      width: '50vw',
      data: {
        isEdit: false
      }
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new valuationVpga10MatterActions.AddVpgaMatter({
          data: res
        }));
      }
    });
    this.subscriptions.push(dialogSub);
  }

  edit(item: ValuationVpga10Matter) {
    const dialogRef = this.dialog.open(EditVpga10MatterComponent, {
      width: '50vw',
      data: {
        isEdit: true,
        item: item
      }
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new valuationVpga10MatterActions.EditVpgaMatter({data: res}));
      }
    });
    this.subscriptions.push(dialogSub);
  }

  restore(item: ValuationVpga10Matter) {
    const dialogRef = this.dialog.open(RestoreModalComponent, {
      width: '440px'
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
            this.store$.dispatch(new valuationVpga10MatterActions.RestoreLocalVpgaMatter({
              data: item,
              tp: this.targetProperty
            }));
          }
        }
    );
    this.subscriptions.push(dialogSub);
  }

  remove(item: ValuationVpga10Matter) {
    const _title = 'Are you sure?';
    const _waitingDescription = `Removing VPGA-10`;
    switch (item.source) {
      case Source.ToE: {
        const _description = `Any changes previously made will be lost and the content will be restored as per ToE. Please provide your justification(s) for removing this VPGA-10`;
        const dialogRef = this.dialog.open(DeleteEntityWithDescrDialogComponent, {
          data: {title: _title, description: _description, waitDescription: _waitingDescription, 
            placeholder: 'Justification', error: 'Justification is'},
          width: '440px'
        });
        const dialogSub = dialogRef.afterClosed().subscribe(res => {
          if (res) {

                this.store$.dispatch(new valuationVpga10MatterActions.RemoveToeLocalVpgaMatter({
                  data: item,
                  tp: this.targetProperty,
                  justification: res
                }));
              }
            }
        )
        this.subscriptions.push(dialogSub);
        break;
      }
      case Source.Input: {
        const _description = `Once deleted, you will not be able to retrieve this newly created VPGA-10`
        const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
          data: {title: _title, description: _description, waitDescription: _waitingDescription},
          width: '440px'
        });
        const dialogSub = dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.store$.dispatch(new valuationVpga10MatterActions.RemoveInputVpgaMatter({
              id: item.front_id
            }));
          }
        })
        this.subscriptions.push(dialogSub);
      }
    }
    
  }
}
