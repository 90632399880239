// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
import {
    selectAuditTrailsInStore,
    selectAuditTrailsPageLoading,
    selectAuditTrailsShowInitWaitingMessage
} from '../../assignment/_selectors/audit-trail.selectors';

export class AuditTrailsDataSource extends BaseDataSource {

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAuditTrailsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAuditTrailsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAuditTrailsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
