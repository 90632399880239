import {SolUseClassification} from '../_models/sol-use-classification.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SolUseClassificationsState} from '../_reducers/sol-use-classification.reducers';
import * as fromSolUseClassification from '../_reducers/sol-use-classification.reducers';
import {each} from 'lodash';

export const selectSolUseClassificationsState = createFeatureSelector<SolUseClassificationsState>('solUseClassifications');

export const selectSolUseClassificationById = (solUseClassificationId: number) => createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => solUseClassificationsState.entities[solUseClassificationId]
);

export const selectAllSolUseClassifications = createSelector(
    selectSolUseClassificationsState,
    fromSolUseClassification.selectAll
);

export const selectAllSolUseClassificationsIds = createSelector(
    selectSolUseClassificationsState,
    fromSolUseClassification.selectIds
);

export const allSolUseClassificationsLoaded = createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => solUseClassificationsState.isAllSolUseClassificationsLoaded
);


export const selectSolUseClassificationsPageLoading = createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => solUseClassificationsState.listLoading
);

export const selectSolUseClassificationsActionLoading = createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => solUseClassificationsState.actionLoading
);

export const selectLastCreatedSolUseClassificationId = createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => solUseClassificationsState.lastCreatedSolUseClassificationId
);

export const selectSolUseClassificationsShowInitWaitingMessage = createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => solUseClassificationsState.showInitWaitingMessage
);

export const selectTrashedSolUseClassificationCount = createSelector(
    selectSolUseClassificationsState,
    (solUseClassificationsState) => solUseClassificationsState.totalTrashed
);

export const selectAdminTrashedSolUseClassificationCount = createSelector(
    selectSolUseClassificationsState,
    (solUseClassificationsState) => solUseClassificationsState.totalAdminTrashed
);


export const selectSolUseClassificationQueryResult = createSelector(
    selectSolUseClassificationsState,
    solUseClassificationsState => {
        const items: SolUseClassification[] = [];
        each(solUseClassificationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SolUseClassification[] = httpExtension.sortArray(items, solUseClassificationsState.lastQuery.sortField, solUseClassificationsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, solUseClassificationsState.totalCount, '', solUseClassificationsState.totalTrashed);
    }
);
