import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'kt-toe',
    templateUrl: './toe.component.html',
})
export class TOEComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
