import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { ValuationProcessToggleCriterionActiveState } from 'src/app/core/valuation-process/_actions/criterion.actions';
import { DefaultCriterionModel } from 'src/app/core/valuation-process/_models/valuation-process-criterion.model';
import { selectAllApplicableValuationProcessCriterions } from 'src/app/core/valuation-process/_selectors/default-criterion.selectors';

@Component({
  selector: 'kt-criterions-modal-v2',
  templateUrl: './criterions-modal-v2.component.html',
  styleUrls: ['./criterions-modal-v2.component.scss']
})
export class CriterionsModalV2Component implements OnInit {

  viewModel$ = this.store$.select(selectAllApplicableValuationProcessCriterions).pipe(
    map(criterions => {
      const { headerColumns, displayedColumns } = this._setHeaders(this.data.refNums)
      const dataSource = new MatTableDataSource()
      dataSource.data = criterions
      return {
        dataSource,
        refNums: this.data.refNums,
        headerColumns,
        displayedColumns
      }
    })
  )

  constructor(
    private store$: Store<AppState> ,
    private dialogRef: MatDialogRef<CriterionsModalV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: {
      refNums: string[]
    }
  ) { }

  ngOnInit(): void {
  }

  _setHeaders(comparables: any[]) {
    const headerColumns = ['input-header'];
    const displayedColumns = ['criterion', 'category'];
    const comparableHeaderColumns = [];
    comparables.forEach((com, i) => {
      headerColumns.push(`com-${i}-${comparables.length}-header`);
      comparableHeaderColumns.push(`com-${i}-${comparables.length}-header`);
      displayedColumns.push(`com-${i}-${comparables.length}`);
    });
    headerColumns.push(`tp-${comparables.length}-header`, 'action-header');
    displayedColumns.push(`tp-${comparables.length}`, 'actions');
    return {headerColumns, displayedColumns}
  }

  onSetCriterionActive(criterion: DefaultCriterionModel) {
    this.store$.dispatch(new ValuationProcessToggleCriterionActiveState({
      id: criterion.id,
      active: !criterion.active
    }))
  }
  onSetCriterionDeactive(criterion: DefaultCriterionModel) {
    this.store$.dispatch(new ValuationProcessToggleCriterionActiveState({
      id: criterion.id,
      active: !criterion.active
    }))
  }
  onClose() {
    this.dialogRef.close()
  }

}
