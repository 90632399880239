<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet-head--lg'" [viewLoading$]="dataSource.loading$"
        [tooltipTitle]="'TOOLTIP.CLIENT.LIST.TITLE' | translate"
        [tooltipDesc]="'TOOLTIP.CLIENT.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet-head-title">
                <span>{{ 'CLIENT.LIST.TITLE' | translate }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools *ngIf="canEdit$ | async">
            <button (click)="addClient()" mat-raised-button [matTooltip]="'CLIENT.LIST.BUTTON.NEW.TOOLTIP' | translate"
                color="primary" type="button" class="mr-2">
                <span>{{ 'CLIENT.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button *ngIf="trashCnt != 0" (click)="trash()" mat-raised-button
                [matTooltip]="'CLIENT.LIST.TOOLTIP.TRASHED_CLIENTS' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button *ngIf="adminTrashCnt != 0 && (canAdminThrash$|async)" (click)="adminTrash()" mat-raised-button
                [matTooltip]="'CLIENT.LIST.TOOLTIP.TRASHED_CLIENTS' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="clientStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center"
                    style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_clients }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TOTAL CLIENTS</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center"
                    style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.active_clients }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">ACTIVE CLIENTS</span></p>
                    </div>
                </div>
            </div>
        </div>

        <kt-filter [config]="{
                search: {
                    main: true,
                    location: true,
                    reset: false
                }
            }" (filterChange)="onFilterChange($event)">
            <kt-f-selection class="mr-2 mt-2" [key]="'type'" [bg]="'#187916'" [placeholder]="'Type'"
                [selections]="types"></kt-f-selection>
        </kt-filter>


        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="mat-elevation-z0 table-striped" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="id" matSortDirection="asc" matSortDisableClear>
                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell *matCellDef="let client">{{client.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'CLIENT.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let client" (click)="editClient(client)">{{(client.type == 'Individual') ?
                        client.name+' '+ client.last_name : client.name}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'CLIENT.LIST.DATATABLE.TYPE_CONTACTS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let client">
                        {{client.type}}&nbsp;
                        <ng-container *ngIf="client.type === 'Company'">
                            <span [matTooltip]="'CLIENT.LIST.BUTTON.CONTACT_LIST.TOOLTIP' | translate"
                                (click)="contactList(client)" *ngIf="client.type === 'Company'" class="cursor-pointer">
                                (&nbsp;{{client.contact_cnt}}
                            </span>
                            <ng-container *ngIf="canEdit$|async">
                                <button mat-icon-button
                                    [matTooltip]="'CLIENT.LIST.BUTTON.CONTACT_ADD.TOOLTIP' | translate"
                                    (click)="addContact(client)" *ngIf="client.type === 'Company'" class="cursor-pointer">
                                    <mat-icon color="primary">add</mat-icon>)
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!(canEdit$|async)">
                                <span>&nbsp;)</span>
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="country_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'CLIENT.LIST.DATATABLE.COUNTRY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let client">{{client.country_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'CLIENT.LIST.DATATABLE.CITY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let client">{{client.city_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="toe_cnt">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="text-center row h80-p">
                            <div class="col-12 h50-percent pt-4 pl-2">
                                <span>{{ 'AGENCY.LIST.DATATABLE.TOE_CNT' | translate }}</span>
                            </div>
                            <div class="col-4 text-center bg-gray h50-percent pt-4"><span>{{
                                    'AGENCY.LIST.DATATABLE.TOE_CNT_DRAFT' | translate }}</span></div>
                            <div class="col-4 text-center bg-gray h50-percent pt-4"><span>{{
                                    'AGENCY.LIST.DATATABLE.TOE_CNT_ONGOING' | translate }}</span></div>
                            <div class="col-4 text-center bg-gray h50-percent pt-4"><span>{{
                                    'AGENCY.LIST.DATATABLE.TOE_CNT_COMPLETE' | translate }}</span></div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let client">
                        <span class="col-4 text-center pl-2">{{client.toe_cnt_draft}}</span>
                        <span class="col-4 text-center pl-2">{{client.toe_cnt_ongoing}}</span>
                        <span class="col-4 text-center pl-2">{{client.toe_cnt_complete}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'CLIENT.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let client">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <!-- <div [innerHtml]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: client.createdBy ? client.createdBy : 'Unknown',
                                date: (client.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: client.lastUpdatedBy ? client.lastUpdatedBy: 'Unknown',
                                date: (client.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </div> -->
                            <div [innerHtml]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: client.createdBy ? client.createdBy : 'Unknown',
                                date: (client.created_at | date: 'dd MMM yyyy')
                                }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: client.lastUpdatedBy ? client.lastUpdatedBy: 'Unknown',
                                date: (client.updated_at | date: 'dd MMM yyyy')
                                }">
                            </div>
                        </ng-template>
                        <button mat-icon-button color="primary" [ngbPopover]="popContent" [popoverTitle]="popTitle" *ngIf="currentUser">
                            <mat-icon [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate">info</mat-icon>
                        </button>

                        <button mat-icon-button color="primary"
                            [matTooltip]="'CLIENT.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editClient(client)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <span [matTooltip]="client.ass_cnt == 0
                                    ? ('CLIENT.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate)
                                    : ('CLIENT.LIST.BUTTON.DELETE.TOOLTIP.CANNOT' | translate)">
                            <button mat-icon-button color="warn" type="button" (click)="deleteClient(client)"
                                [disabled]="!(canEdit$|async) || client.ass_cnt != 0">
                                <mat-icon>delete</mat-icon>
                            </button>&nbsp;
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                    [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">{{
                'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
                [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>