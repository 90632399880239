<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Default Criterions of {{assetClassName}}</h3>
        </div>
        <div class="card-toolbar">
			<button mat-button mat-raised-button class="btn-success mr-2" 
				color="warn" aria-label="Close" (click)="onSaveClick()">
                Save
			</button>
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <mat-table [dataSource]="datasource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                    <mat-cell *matCellDef="let criterion">{{criterion.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
                    <mat-cell *matCellDef="let criterion">{{criterion.category}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="selected">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let criterion; let i = index;">
                        <mat-checkbox [checked]="criterion.selected" (change)="onSelectionChange($event, i)"></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="['name', 'category', 'selected']"></mat-header-row>
                <mat-row *matRowDef="let row; columns: ['name', 'category', 'selected']"></mat-row>
            </mat-table>
        </mat-dialog-content>
    </div>
</div>
