<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Add Facility</h3>
        </div>
    </div>
    <div class="form" [formGroup]="facilityForm">
        <div class="card-body">
            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Facility Name" formControlName="name"/>
                    <mat-error>Facility Name
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="card-footer p-0 b-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" (click)="close()">Close</button>&nbsp;
                <button mat-button mat-raised-button color="primary" [disabled]="!isFormValid()" (click)="createFacility()">Add</button>&nbsp;
            </div>
        </div>
    </div>
</div>