// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {FoundationTypesService} from '../_services/foundation-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allFoundationTypesLoaded} from '../_selectors/foundation-type.selectors';
// Actions
import {
    AllFoundationTypesLoaded,
    AllFoundationTypesRequested,
    FoundationTypeActionTypes,
    FoundationTypesPageRequested,
    FoundationTypesPageLoaded,
    FoundationTypeUpdated,
    FoundationTypesPageToggleLoading,
    FoundationTypeDeleted,
    FoundationTypeOnServerCreated,
    FoundationTypeCreated,
    FoundationTypeAdminRestored,
    FoundationTypeDeletedFromAdminTrash,
    FoundationTypeDeletedFromTrash,
    FoundationTypeOnServerAdminRestored,
    FoundationTypeOnServerRestored,
    FoundationTypeRestored,
    FoundationTypeTrashFlushed,
    FoundationTypesActionToggleLoading
} from '../_actions/foundation-type.actions';

@Injectable()
export class FoundationTypeEffects {
    showPageLoadingDispatcher = new FoundationTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new FoundationTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new FoundationTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllFoundationTypes$ = this.actions$
        .pipe(
            ofType<AllFoundationTypesRequested>(FoundationTypeActionTypes.AllFoundationTypesRequested),
            withLatestFrom(this.store.pipe(select(allFoundationTypesLoaded))),
            filter(([action, isAllFoundationTypesLoaded]) => !isAllFoundationTypesLoaded),
            mergeMap(() => this.service.getAllFoundationTypes()),
            map(res => {
                return new AllFoundationTypesLoaded({foundationTypes: res.data});
            })
        );

    @Effect()
    loadFoundationTypesPage$ = this.actions$
        .pipe(
            ofType<FoundationTypesPageRequested>(FoundationTypeActionTypes.FoundationTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findFoundationTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new FoundationTypesPageLoaded({
                    foundationTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeDeleted>(FoundationTypeActionTypes.FoundationTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFoundationType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeUpdated>(FoundationTypeActionTypes.FoundationTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateFoundationType(payload.foundationType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeOnServerCreated>(FoundationTypeActionTypes.FoundationTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createFoundationType(payload.foundationType).pipe(
                    tap(res => {
                        this.store.dispatch(new FoundationTypeCreated({foundationType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushFoundationTypeTrash$ = this.actions$
        .pipe(
            ofType<FoundationTypeTrashFlushed>(FoundationTypeActionTypes.FoundationTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeOnServerRestored>(FoundationTypeActionTypes.FoundationTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FoundationTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeOnServerAdminRestored>(FoundationTypeActionTypes.FoundationTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FoundationTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeDeletedFromTrash>(FoundationTypeActionTypes.FoundationTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashFoundationType$ = this.actions$
        .pipe(
            ofType<FoundationTypeDeletedFromAdminTrash>(FoundationTypeActionTypes.FoundationTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: FoundationTypesService, private store: Store<AppState>) {
    }
}
