<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{getTitle()}}
            </h3>
        </div>
    </div>

    <div class="form form-group-seperator-dashed">
    <form [formGroup]="form" class="form" *ngIf="form">
        <div class="card-body">
        <mat-dialog-content>
            <div class="d-flex justify-content-center">
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup name="radioBasic" [formControl]="formSelectionCtrl">
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                    <input ngbButton type="radio" class="btn-check" [value]="2"> Simple
                  </label>
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-left-white">
                  <input ngbButton type="radio" class="btn-check" [value]="1"> Advanced
                </label>
            </div>

            </div>
                <div class="form-group row">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile">
                        <h5 class="text-mad text-uppercase">Currency</h5>
                        <hr>
                    </div>
                    <div class="col-lg-12 mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'CONSIDERATIONS.FORM.FIELD.GENERAL.CURRENCY.TITLE' | translate"
                                formControlName="currency" #singleSelectCurrency>
                                <mat-option>

                                </mat-option>
                                <mat-option *ngFor="let _data of filteredCountriesData|async" [value]="_data">
                                    {{_data}}
                                </mat-option>
                            </mat-select>
                            <mat-error 
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.GENERAL.CURRENCY.TITLE' | translate)
                                }"
                            ></mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12">
                        <h6 class="text-mad">TENURE</h6>
                        <hr>
                        <mat-radio-group class="mb-2 p-1"
                                            formControlName="tenure">
                            <mat-radio-button class="example-radio-button"
                                                *ngFor="let option of options; let i=index"
                                                [value]="option.toLowerCase()">
                                {{option}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="form-group row mb-4" *ngIf="form.controls.tenure.value == 'freehold'">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile">
                        <h6 class="text-mad kt-portlet__head-title">{{ 'CONSIDERATIONS.FORM.FIELD.FREEHOLD.SECTION_TITLE' | translate }}</h6>
                        <hr>
                    </div>

                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                    mask="separator.2"
                                    thousandSeparator=","
                                    [placeholder]="'CONSIDERATIONS.FORM.FIELD.FREEHOLD.TOTAL_CONSIDERATION.PLACEHOLDER' | translate:{
                                        currency: form.controls.currency.value
                                    }"
                                    formControlName="total_consideration"/>
                            <mat-error
                                *ngIf="form.controls.total_consideration.hasError('required')"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.FREEHOLD.TOTAL_CONSIDERATION.TITLE' | translate)
                                }"
                            >
                            </mat-error>
                            <mat-error *ngIf="form.controls.total_consideration.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row mb-4" *ngIf="form.controls.tenure.value &&  form.controls.tenure.value.length != 0  && form.controls.tenure.value != 'freehold'">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile">
                        <h6 class="text-mad kt-portlet__head-title">
                            {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.SECTION_TITLE' | translate }}
                        </h6>
                        <hr>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput 
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.TOTAL_CONSIDERATION_PER_MONTH.PLACEHOLDER' | translate:{
                                    currency: form.controls.currency.value
                                }"
                                    formControlName="total_consideration"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-error
                                *ngIf="form.controls.total_consideration.hasError('required')"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.LEASEHOLD.TOTAL_CONSIDERATION_PER_MONTH.TITLE' | translate)
                                }"
                            >
                            </mat-error>
                            <mat-error *ngIf="form.controls.total_consideration.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 mb-4">
                        <mat-radio-group class="mb-2 p-1" formControlName="rent_type">
                            <mat-radio-button class="example-radio-button" value="headline">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.RENT_TYPE.VALUE.HEADLINE' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button" value="effective">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.RENT_TYPE.VALUE.EFFECTIVE' | translate }}
                            </mat-radio-button>
                            <mat-icon
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.RENT.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.RENT.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-radio-group>
                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.RENT_REVIEW.PLACEHOLDER' | translate"  
                                formControlName="rent_review"
                                mask="separator.2"
                                thousandSeparator=",">
                            <mat-icon
                                matSuffix
                                class="cursor-pointer icon-gray"
                                [popoverTitle]="'TOOLTIP.CONSIDERATION.RENT_REVIEW.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.CONSIDERATION.RENT_REVIEW.DESCRIPTION' | translate"
                                popoverClass="consideration__popover__class"
                                (click)="$event.stopPropagation();">help</mat-icon>
                            <mat-error *ngIf="form.controls.rent_review.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.rent_review.hasError('notLesserThanLease')">
                                Value cannot be greater than <strong>Lease duration</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.rent_review.hasError('isZero')">
                                Value cannot be <strong>0</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6" *ngIf="isComplexform">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.RENT_REVIEW_COMMENT.PLACEHOLDER' | translate"  
                                formControlName="rent_review_comment">
                            <mat-icon
                                matSuffix
                                container="body"
                                class="cursor-pointer icon-gray"
                                [popoverTitle]="'TOOLTIP.CONSIDERATION.RENT_COMMENT.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.CONSIDERATION.RENT_COMMENT.DESCRIPTION' | translate"
                                popoverClass="consideration__popover__class"
                                (click)="$event.stopPropagation();">help</mat-icon>
                            <mat-error>
                                Rent review comment is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.DURATION.PLACEHOLDER' | translate"
                                    formControlName="lease_duration"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    container="body"
                                    class="cursor-pointer icon-gray"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.LEASE_DURATION.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.LEASE_DURATION.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error *ngIf="form.controls.lease_duration.hasError('required')">
                                Lease duration is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.lease_duration.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.UNEXPIRED_LEASE_TERM.PLACEHOLDER' | translate"
                                    formControlName="unexpired_lease_term"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    container="body"
                                    class="cursor-pointer icon-gray"
                                    placement="left"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.LEASE_TERM.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.LEASE_TERM.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error *ngIf="form.controls.unexpired_lease_term.hasError('required')">
                                Unexpired lease term is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.unexpired_lease_term.hasError('notLesserThanLease')">
                                Value cannot be greater than <strong>Lease duration</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="isComplexform">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.WRITE_OFF_PERIOD.PLACEHOLDER' | translate"
                                    formControlName="write_off_period" mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    container="body"
                                    class="cursor-pointer icon-gray"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.WRITE_OF_PERIOD.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.WRITE_OF_PERIOD.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error *ngIf="form.controls.write_off_period.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.write_off_period.hasError('notLesserThanLease')">
                                Value cannot be greater than <strong>Lease duration</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.write_off_period.hasError('isZero')">
                                Value cannot be <strong>0</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="isComplexform">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.BREAK_OPTION.PLACEHOLDER' | translate"
                                    formControlName="break_option"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    container="body"
                                    placement="left"
                                    class="cursor-pointer icon-gray"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.BREAK_OPTION.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.BREAK_OPTION.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error *ngIf="form.controls.break_option.hasError('notNegative')">
                                Value cannot be <strong>0</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.break_option.hasError('notLesserThanLeaseOrEqualToLease')">
                                Value cannot be greater than or equal to <strong>Lease duration</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="isComplexform">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.RENT_FREE_PERIOD.PLACEHOLDER' | translate"
                                    formControlName="rent_free_period"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    container="body"
                                    class="cursor-pointer icon-gray"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.RENT_FREE.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.RENT_FREE.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error *ngIf="form.controls.rent_free_period.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.rent_free_period.hasError('notLesserThanLease')">
                                Value cannot be greater than or equal to <strong>Lease duration</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.rent_free_period.hasError('notLesserThanWriteOff')">
                                Value cannot be greater than or equal to <strong>Write Off</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="isComplexform">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                    [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.FITTING_OUT_PERIOD.PLACEHOLDER' | translate"
                                    formControlName="fitting_out_period"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    placement="left"
                                    container="body"
                                    class="cursor-pointer icon-gray"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.FITTING_OUT.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.FITTING_OUT.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error *ngIf="form.controls.fitting_out_period.hasError('notNegative')">
                                Value cannot be <strong>negative</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.fitting_out_period.hasError('notLesserThanLeaseOrEqualToLease')">
                                Value cannot be greater than or equal to <strong>Lease duration</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.fitting_out_period.hasError('notLesserThanWriteOff')">
                                Value cannot be greater than or equal to <strong>Write Off</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                    [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.CAP_PAYMENT.PLACEHOLDER' | translate"
                                    formControlName="cap_payment"
                                    mask="separator.2"
                                    thousandSeparator=","/>
                            <mat-icon
                                    matSuffix
                                    container="body"
                                    class="cursor-pointer icon-gray"
                                    [popoverTitle]="'TOOLTIP.CONSIDERATION.CAPITAL_PAYMENT.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.CONSIDERATION.CAPITAL_PAYMENT.DESCRIPTION' | translate"
                                    popoverClass="consideration__popover__class"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.LEASEHOLD.CAP_PAYMENT.TITLE' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-4">
                        <h6 class="kt-portlet__head-title">
                            {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_PROPERTY_MANAGEMENT.TITLE' | translate }}
                        </h6>
                        <hr>

                        <mat-radio-group class="mb-2 p-1"
                                            formControlName="inclusive_property_management">
                            <mat-radio-button class="example-radio-button"
                                                value="yes">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_PROPERTY_MANAGEMENT.VALUE.YES' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button"
                                                value="no">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_PROPERTY_MANAGEMENT.VALUE.NO' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-form-field class="mat-form-field-fluid"
                                        *ngIf="form.controls.inclusive_property_management.value == 'no'">
                            <input matInput mask="separator.2"
                                thousandSeparator=","
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.ESTIMATED_PROPERTY_MANAGEMENT.PLACEHOLDER' | translate:{
                                    currency: form.controls.currency.value
                                }"
                                    formControlName="estimated_amount_property_management"/>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.LEASEHOLD.ESTIMATED_PROPERTY_MANAGEMENT.TITLE' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-4">
                        <h6 class="kt-portlet__head-title">
                            {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_UTILITY_COST.TITLE' | translate }}
                        </h6>
                        <hr>

                        <mat-radio-group class="mb-2 p-1"
                                            formControlName="consideration_inclusive_cost">
                            <mat-radio-button class="example-radio-button"
                                                value="yes">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_UTILITY_COST.VALUE.YES' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button"
                                                value="no">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_UTILITY_COST.VALUE.NO' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-form-field class="mat-form-field-fluid"
                                        *ngIf="form.controls.consideration_inclusive_cost.value == 'no'">
                            <input matInput mask="separator.2"
                                thousandSeparator=","
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.ESTIMATED_UTILITY_COST.PLACEHOLDER' | translate:{
                                    currency: form.controls.currency.value
                                }"
                                    formControlName="estimated_amount_utilities_cost"/>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.LEASEHOLD.ESTIMATED_UTILITY_COST.TITLE' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-4">
                        <h6 class="kt-portlet__head-title">
                            {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_FULL_REPAIR_INSURANCE.TITLE' | translate }}
                        </h6>
                        <hr>

                        <mat-radio-group class="mb-2 p-1"
                                            formControlName="consideration_full_repair_cost">
                            <mat-radio-button class="example-radio-button"
                                                value="yes">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_FULL_REPAIR_INSURANCE.VALUE.YES' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button"
                                                value="no">
                                {{ 'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.INCLUSIVE_FULL_REPAIR_INSURANCE.VALUE.NO' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-form-field class="mat-form-field-fluid"
                                        *ngIf="form.controls.consideration_full_repair_cost.value == 'no'">
                            <input matInput mask="separator.2"
                                thousandSeparator=","
                                [placeholder]="'CONSIDERATIONS.FORM.FIELD.LEASEHOLD.ESTIMATED_FULL_REPAIR_INSURANCE.PLACEHOLDER' | translate:{
                                    currency: form.controls.currency.value
                                }"
                                    formControlName="estimated_amount_repair_cost"/>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CONSIDERATIONS.FORM.FIELD.LEASEHOLD.ESTIMATED_FULL_REPAIR_INSURANCE.TITLE' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row mb-4" *ngIf="data.hasParcel">
                    <kt-parcel-consideration-table
                        class="col-12"
                        [components]="data.components"
                        [additionals]="data.acTenure.additional" 
                        ></kt-parcel-consideration-table>
                    <div class="col-12">
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && (parcelError$|async)">
                            Tenure, or currency must be selected for not included parcel(s)
                        </mat-error>
                    </div>
                </div>

                <div class="form-group row">
                    <kt-source-info class="col-12" [sourceSubject]="sourceSubject" [otherDates]="otherDates" [isComplexForm]="isComplexform"></kt-source-info>
                </div>
            </mat-dialog-content>
            </div>
        </form>
        <div class="card-footer p-0 b-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="onNoClick()">{{ 'CONSIDERATIONS.FORM.BUTTON.CLOSE.LABEL' | translate }}</button>&nbsp;
                <button type="button"
                        class="btn btn-success"
                        mat-raised-button
                        color="submit"
                        (click)="onSubmit()"
                        [matTooltip]="'CONSIDERATIONS.FORM.BUTTON.ADD.TOOLTIP' | translate">
                    {{ 'CONSIDERATIONS.FORM.BUTTON.ADD.LABEL' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>