import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AssetClassModel, TpTaskService } from 'src/app/core/asset_class';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { InvoiceService, ToeInvoicePayment, ToeInvoicePaymentService, ToeModel, ToeReportService, ToeService, ToeSettlement, ToeSettlementService } from 'src/app/core/toe';
import { ToeReportTaskModel } from 'src/app/core/toe/_models/toe-report-task.model';
import { InformedConsentReportService } from 'src/app/core/toe/_services/informed-consent-report.service';
import { NewCoiService } from 'src/app/core/toe/_services/new-coi.service';
import { ToeAmendmentService } from 'src/app/core/toe/_services/toe-amendment.service';
import { LayoutUtilsService, QueryParamsModel, TypesUtilsService } from 'src/app/core/_base/crud';
import { EditInvoicePaymentInfoComponent } from '../edit-invoice-payment-info/edit-invoice-payment-info.component';
import { EditSettlementInfoComponent } from '../edit-settlement-info/edit-settlement-info.component';

@Component({
  selector: 'kt-toe-settlement',
  templateUrl: './toe-settlement.component.html',
  styleUrls: ['./toe-settlement.component.scss']
})
export class ToeSettlementComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() toe: ToeModel;
  @Input() toeID: number;
  @Input() leadValuerID: number;
  @Input() workers: User[];
  @Input() pm: User;
  @Input() targetProperties: AssetClassModel[];
  @Output() settlementIncompleteTaskCnt = new EventEmitter<any>();
  @ViewChild(NgbDropdown) dropDown: NgbDropdown;

  status = Status;
  state = State;
  columns = ['payment_date', 'invoice_number',  'invoice_amount', 'amount', 'payment_type_id', 'actions'];
  settlementColumns = ['label', 'value', 'action'];
  dataSource = new MatTableDataSource<InvoicePaymentDataSourceInterface>([]);
  dataSourceSettlement = new MatTableDataSource<Settlement1DataSourceInterface>([]);
  docs = ['xlsx', 'xls', 'doc', 'docx'];
  currentUser: User;
  tasks: ToeReportTaskModel[] = [];
  cancel$: Subject<void> = new Subject();
  showAddNewPaymentBtn$: Observable<boolean>;
  toeSignedUploaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  informedConsentSignedUploaded$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  getInformedConsentsReportCMD$: Subject<void> = new Subject();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currency = '';
  todo_ids: number[] = [];
  upcoming_ids: number[] = [];
  math = Math;
  constructor(
    private store: Store<AppState>,
    private toeReportService: ToeReportService,
    private informedConsentReportService: InformedConsentReportService,
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private newCoiService: NewCoiService,
    private toeAmendmentService: ToeAmendmentService,
    private toeService: ToeService,
    private paymentService: ToeInvoicePaymentService,
    private settlementService: ToeSettlementService,
    private tpTaskService: TpTaskService,
    private invoiceService: InvoiceService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit(): void {
    this.showAddNewPaymentBtn$ = combineLatest([
      of(this.toe),
      this.store.select(currentUser).pipe(filter(user => user != null), tap(user => this.currentUser = user)),
    ]).pipe(map(([toe, user]) => {
      return toe.status != 4 && user.id == this.leadValuerID || user.id == this.pm.id
    }))

    combineLatest([
      this.store.select(currentUser).pipe(tap(user => this.currentUser = user), filter(user => user != null && user != undefined)),
      this.getInformedConsentsReportCMD$.pipe(
        switchMap(() => {
          return this.toeService.getToeById(this.toeID)
        }),
        map(res => res.data)
      ),
      this.getInformedConsentsReportCMD$.pipe(
        switchMap(() => {
          return this.paymentService.findToeInvoicePayments(new QueryParamsModel(
            {
              toe_id: this.toeID
            },
            'asc',
            'id',
            0,
            100
        ))
        }),
        map(res => res.data)
      ),
      this.getInformedConsentsReportCMD$.pipe(
        switchMap(() => {
          return this.settlementService.findToeSettlements(new QueryParamsModel(
            {
              toe_id: this.toeID
            },
            'asc',
            'id',
            0,
            100
        ))
        }),
        map(res => res.data)
      ),
    ]).pipe(
        takeUntil(this.cancel$),
        map(([user, toe, invoicePayments, toeSettlements]: [User, ToeModel, ToeInvoicePayment[], ToeSettlement[]]) => {
          const currendDate = new Date();
          this.toe = toe;
          this.currency = toe.payment.currency

          const data: InvoicePaymentDataSourceInterface[] = [];
          const dataSettelement: Settlement1DataSourceInterface[] = [];
          let initialDue = 0;
          let amendmentDue = 0;
          let additionalDue = 0;
          let totalDue = 0;
          let totalReceived = 0;

          toe['invoices'].forEach(invoice => {
            totalDue += this.getAmountWithTax(invoice);
            initialDue += this.getTermAmountWithTax(invoice);
            additionalDue += this.getAdditionalAmountWithTax(invoice);
            amendmentDue += this.getAmendmentAmountWithTax(invoice);
          });

          invoicePayments.forEach(invoicePayment => {
            totalReceived += invoicePayment.amount;

            // Task state
            let state = State.Initial;
            let reportURL = null;
            let additionalTaskInfo: AdditionalTaskInfo = null;
            let canBeDeleted = true;
            let canBeSeen = true;
            let canBeEdited = toe.status != 4 && toe.status != -1 && (user.id == this.leadValuerID || user.id == this.pm.id);
            let canBeReached = toe.status != 4 && (user.id == this.leadValuerID || user.id == this.pm.id);
            let canBeUserDeleted = (user.id == this.leadValuerID || user.id == this.pm.id);
            let infoCanBeShown = true;

            const d: InvoicePaymentDataSourceInterface = {
              id: invoicePayment.id,
              invoice: invoicePayment.invoice,
              toe: toe,
              payment_type: invoicePayment.payment_type,
              invoice_id: invoicePayment.invoice_id,
              payment_type_id: invoicePayment.payment_type_id,
              amount: invoicePayment.amount,
              payment_date: invoicePayment.payment_date,
              comment: invoicePayment.comment,
              taskActions: {
                infoCanBeShown,
                canBeUserDeleted,
                canBeDeleted,
                canBeEdited,
                canBeReached,
                canBeSeen
              },
            }
            data.push(d);
          }) 

          var toeSettlement = new ToeSettlement;
          toeSettlement.clear();
          if(toeSettlements.length > 0) {
            toeSettlement = toeSettlements[0];
          }
          const initial: Settlement1DataSourceInterface = {
            label: 'Initial ToE Amount',
            value: initialDue,
            edit: false,
            color: '#000000',
            toeSettlement: null
          }
          const amendment: Settlement1DataSourceInterface = {
            label: 'Amendment(s) Arbitration',
            value: amendmentDue,
            edit: false,
            color: '#000000',
            toeSettlement: null
          }
          const additional: Settlement1DataSourceInterface = {
            label: 'Additional Invoices',
            value: additionalDue,
            edit: false,
            color: '#000000',
            toeSettlement: null
          }
          const due: Settlement1DataSourceInterface = {
            label: 'Total Due',
            value: totalDue,
            edit: false,
            color: '#000000',
            toeSettlement: null
          }
          const received: Settlement1DataSourceInterface = {
            label: 'Total Received',
            value: totalReceived,
            edit: false,
            color: '#000000',
            toeSettlement: null
          }
          const balance: Settlement1DataSourceInterface = {
            label: 'Balance',
            value: totalDue - totalReceived - toeSettlement.amount,
            edit: false,
            color: '#000000',
            toeSettlement: null
          }
          toeSettlement.balance = balance.value
          this.settlementIncompleteTaskCnt.emit({incomplete: (balance.value != 0 ? 1 : 0), total: 1});

          const settlement: Settlement1DataSourceInterface = {
            label: 'Settlement',
            value: toeSettlement.amount,
            edit: toe.status != 4 && toe.status != -1 && (user.id == this.leadValuerID || user.id == this.pm.id),
            color: balance.value == 0 ? '#16C4BB' : '#F44D5E',
            toeSettlement: toeSettlement
          }
          dataSettelement.push(initial)
          dataSettelement.push(amendment)
          if (additionalDue > 0) {
            dataSettelement.push(additional)
          }
          dataSettelement.push(due)
          dataSettelement.push(received)
          dataSettelement.push(balance)
          dataSettelement.push(settlement)
          return [data, dataSettelement];
        })
      )
      .subscribe(res => {
        this.dataSource.data = res[0] as InvoicePaymentDataSourceInterface[]
        this.dataSourceSettlement.data = res[1] as Settlement1DataSourceInterface[]
      });

    this.getInformedConsentsReportCMD$.next();
  }

  ngOnDestroy() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  public deletePayment(row: InvoicePaymentDataSourceInterface) {
    const _title = 'Warning';
    const _description = 'You are about to delete a recorded payment. This action is not reversible. Are you sure you want to proceed?';
    const _waitDesciption = '';

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.paymentService.deleteToeInvoicePayment(row.id).subscribe(res => {
        this.getInformedConsentsReportCMD$.next();
      });
    });
  }
  public recordPayment(item) {
    var toeInvoicePayment = new ToeInvoicePayment
    var _title = '';
    if (item == null) {
      _title = 'Record Payment';
      toeInvoicePayment = new ToeInvoicePayment;
    } else {
      toeInvoicePayment = new ToeInvoicePayment;
      toeInvoicePayment.id = item.id;
      toeInvoicePayment.toe_id = item.toe_id;
      toeInvoicePayment.invoice_id = item.invoice_id;
      toeInvoicePayment.payment_type_id = item.payment_type_id;
      toeInvoicePayment.payment_date = item.payment_date;
      toeInvoicePayment.amount = item.amount;
      toeInvoicePayment.comment = item.comment;
      _title = 'Edit Payment';
    }
    
    const dialogRef = this.dialog.open(EditInvoicePaymentInfoComponent, {
      data: {
        title: _title,
        toe: this.toe,
        currency: this.currency,
        invoices: this.toe['invoices'],
        invoicePayment: toeInvoicePayment
      },
      width: '640px'
    })
    dialogRef.afterClosed().subscribe(res => {
      this.getInformedConsentsReportCMD$.next();
    })
  }
  public editSEttlement(item) {
    var _title = 'Edit Settlement';
    
    const dialogRef = this.dialog.open(EditSettlementInfoComponent, {
      data: {
        title: _title,
        toe: this.toe,
        currency: this.currency,
        toeSettlement: item.toeSettlement
      },
      width: '640px'
    })
    dialogRef.afterClosed().subscribe(res => {
      this.getInformedConsentsReportCMD$.next();
    })
  }
  getTaskName(invoice, toe_invoices) {
    if (invoice.term) { // regular invoice
      const total = toe_invoices.filter((el) => el.payment_term_id != null).length;
      return invoice.invoice_number + (total > 1 ? ' ' + invoice.term.order + '/' + total : '');
    } else if (invoice.payment_amendment && invoice.task.details_name.includes('Invoice -') && invoice.task.details_name.includes('(')) { // amendment invoice
      return invoice.invoice_number + ' ' + invoice.task.details_name.substring(invoice.task.details_name.indexOf('- ') + 1, invoice.task.details_name.indexOf('('));
    } else if (invoice.payment_amendment && invoice.task.details_name.includes('Payment')) { // amendment invoice
      return invoice.invoice_number + ' Amendment';
    } else{
      return invoice.invoice_number;
    }
  }
  public getAmountWithTax(invoice) {
    if (invoice.payment_amendment) {
      const total = invoice.invoice_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    }
    if (!invoice.term && !invoice.payment_amendment) {
      const total = invoice.invoice_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    }
    return invoice.invoice_amount;
  }
  public getAmendmentAmountWithTax(invoice) {
    if (invoice.payment_amendment) {
      const total = invoice.invoice_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    }
    return 0;
  }
  public getTermAmountWithTax(invoice) {
    if (invoice.term) {
      return invoice.invoice_amount;
    }
    return 0;
  }
  public getAdditionalAmountWithTax(invoice) {
    if (!invoice.term && !invoice.payment_amendment) {
      const total = invoice.invoice_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    }
    return 0;
  }
}

interface InvoicePaymentDataSourceInterface {
  id: number;
  invoice: any;
  toe: any;
  invoice_id: number;
  payment_type_id: number;
  payment_type: any;
  amount: number;
  payment_date: Date;
  comment: string;
  taskActions: TaskActions;
}

interface Settlement1DataSourceInterface {
  label: string;
  value: number;
  edit: boolean;
  color: string;
  toeSettlement: ToeSettlement
}

interface TaskActions {
  infoCanBeShown: boolean;
  canBeDeleted: boolean;
  canBeUserDeleted: boolean;
  canBeSeen: boolean;
  canBeEdited: boolean;
  canBeReached: boolean;
}

interface AdditionalTaskInfo {
  createdDate: string;
  userID: number;
  userName: string;
  taskId: number;
}

enum Status {
  Upcoming,
  Overdue,
  Voided,
  Completed,
  Overpaid,
  PartiallyPaid,
}

enum State {
  Initial,
  Additional
}
