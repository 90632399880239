import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { OrganisationModel } from "../_models/organisation.model";

export enum OrganisationActionTypes {
    AllOrganisationsRequested = '[Linked Area] All Organisation Requested',
    AllOrganisationsLoaded = '[Organisation Effect] All Organisation Loaded',

    OrganisationOnServerCreated = '[Add Organisation Page] Organisation On Server Created',
    OrganisationCreated = '[Organisation Effect] Organisation Created',
    OrganisationUpdated = '[Organisation Effect] Organisation Updated',
    OrganisationDeleted = '[Organisation List Page] Organisation Deleted',

    OrganisationOnServerRestored = '[Organisation Trash] Organisation On Server Restored',
    OrganisationRestored = '[Organisation Trash] Organisation Restored',

    OrganisationOnServerAdminRestored = '[Organisation Admin Trash] Organisation On Server Restored',
    OrganisationAdminRestored = '[Organisation Admin Trash] Organisation Restored',

    OrganisationDeletedFromTrash = '[Organisation Trash] Organisation deleted',
    OrganisationDeletedFromAdminTrash = '[Organisation Admin Trash] Organisation deleted',

    OrganisationTrash = 'Organisation Trashed',
    OrganisationTrashFlushed = 'Organisation Trash Flushed',

    OrganisationsPageRequested = '[Organisation List Page] Organisation Page Requested',
    OrganisationsPageLoaded = '[Organisation API] Organisation Page Loaded',
    OrganisationsPageCancelled = '[Organisation API] Organisation Page Cancelled',

    OrganisationsPageToggleLoading = '[Organisation page] Organisation Page Toggle Loading',
    OrganisationActionToggleLoading = '[Organisation] Organisation Action Toggle Loading'
}

export class OrganisationOnServerCreated implements Action {
    readonly type = OrganisationActionTypes.OrganisationOnServerCreated;

    constructor(public payload: { item: OrganisationModel }) {
    }
}

export class OrganisationCreated implements Action {
    readonly type = OrganisationActionTypes.OrganisationCreated;

    constructor(public payload: { item: OrganisationModel }) {
    }
}

export class OrganisationUpdated implements Action {
    readonly type = OrganisationActionTypes.OrganisationUpdated;

    constructor(public payload: {
        partialItem: Update<OrganisationModel>,
        item: OrganisationModel
    }) {
    }
}

export class OrganisationDeleted implements Action {
    readonly type = OrganisationActionTypes.OrganisationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OrganisationsPageRequested implements Action {
    readonly type = OrganisationActionTypes.OrganisationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OrganisationsPageLoaded implements Action {
    readonly type = OrganisationActionTypes.OrganisationsPageLoaded;

    constructor(public payload: { items: OrganisationModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OrganisationsPageCancelled implements Action {
    readonly type = OrganisationActionTypes.OrganisationsPageCancelled;
}

export class AllOrganisationsRequested implements Action {
    readonly type = OrganisationActionTypes.AllOrganisationsRequested;
}

export class AllOrganisationsLoaded implements Action {
    readonly type = OrganisationActionTypes.AllOrganisationsLoaded;

    constructor(public payload: { items: OrganisationModel[] }) {
    }
}

export class OrganisationsPageToggleLoading implements Action {
    readonly type = OrganisationActionTypes.OrganisationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OrganisationActionToggleLoading implements Action {
    readonly type = OrganisationActionTypes.OrganisationActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OrganisationDeletedFromAdminTrash implements Action {
    readonly type = OrganisationActionTypes.OrganisationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OrganisationDeletedFromTrash implements Action {
    readonly type = OrganisationActionTypes.OrganisationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OrganisationOnServerRestored implements Action {
    readonly type = OrganisationActionTypes.OrganisationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OrganisationRestored implements Action {
    readonly type = OrganisationActionTypes.OrganisationRestored;

    constructor(public payload: { item: OrganisationModel }) {
    }
}

export class OrganisationOnServerAdminRestored implements Action {
    readonly type = OrganisationActionTypes.OrganisationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OrganisationAdminRestored implements Action {
    readonly type = OrganisationActionTypes.OrganisationAdminRestored;

    constructor(public payload: { item: OrganisationModel }) {
    }
}

export class OrganisationTrashFlushed implements Action {
    readonly type = OrganisationActionTypes.OrganisationTrashFlushed;

    constructor() {
    }
}


export type OrganisationActions = OrganisationCreated
    | OrganisationUpdated
    | OrganisationDeleted
    | OrganisationsPageRequested
    | OrganisationsPageLoaded
    | OrganisationsPageCancelled
    | AllOrganisationsLoaded
    | AllOrganisationsRequested
    | OrganisationOnServerCreated
    | OrganisationDeletedFromAdminTrash
    | OrganisationDeletedFromTrash
    | OrganisationOnServerRestored
    | OrganisationRestored
    | OrganisationOnServerAdminRestored
    | OrganisationAdminRestored
    | OrganisationTrashFlushed
    | OrganisationsPageToggleLoading
    | OrganisationActionToggleLoading;