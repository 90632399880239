import { Source, Status } from "./valuation-assumption-departure.model";

export class ValuationVpga10Matter {
    id: number;
    front_id: number;
    valuation_id: number;
    local_vpga_id: number;
    source: Source;
    input: string;
    status: Status;
    justification: string;
}