// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SourceTypeActions, SourceTypeActionTypes} from '../_actions/source-type.actions';
// Models
import {SourceType} from '../_models/source-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SourceTypesState extends EntityState<SourceType> {
    isAllSourceTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: SourceType[];
    lastCreatedSourceTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SourceType> = createEntityAdapter<SourceType>();

export const initialSourceTypesState: SourceTypesState = adapter.getInitialState({
    isAllSourceTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSourceTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function sourceTypesReducer(state = initialSourceTypesState, action: SourceTypeActions): SourceTypesState {
    switch (action.type) {
        case SourceTypeActionTypes.SourceTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSourceTypeId: undefined
            };
        case SourceTypeActionTypes.SourceTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SourceTypeActionTypes.SourceTypeOnServerCreated:
            return {
                ...state
            };
        case SourceTypeActionTypes.SourceTypeCreated:
            return adapter.addOne(action.payload.sourceType, {
                ...state, lastCreatedSourceTypeId: action.payload.sourceType.id
            });
        case SourceTypeActionTypes.SourceTypeUpdated:
            return adapter.updateOne(action.payload.partialSourceType, state);
        case SourceTypeActionTypes.AllSourceTypesLoaded:
            return adapter.addAll(action.payload.sourceTypes, {
                ...state, isAllSourceTypesLoaded: true
            });
        case SourceTypeActionTypes.SourceTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SourceTypeActionTypes.SourceTypesPageLoaded:
            return adapter.addMany(action.payload.sourceTypes, {
                ...initialSourceTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case SourceTypeActionTypes.SourceTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SourceTypeActionTypes.SourceTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SourceTypeActionTypes.SourceTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SourceTypeActionTypes.SourceTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SourceTypeActionTypes.SourceTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SourceTypeActionTypes.SourceTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
