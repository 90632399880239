<div class="card card-custom">
    <div class="card-header">
        <div class="card-title"></div>
        <div class="card-toolbar">
            <div *ngIf="selectedComparables.length">
                <button (click)="showSelectedItemsInfo()" mat-raised-button
                        [matTooltip]="'Show info of selected comparable'"
                        class="float-right m-0 btn"
                        type="button">
                    <span>Overview</span>
                </button>
            </div>
            <div>
                <button (click)="addComparable()" mat-raised-button
                        [matTooltip]="'Add Comparable'"
                        class="float-right m-0 ml-4 btn"
                        type="button">
                    <span>Add Comparable</span>
                </button>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="mat-table-wrapper">
            <form [formGroup]="form">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="column-id">#</th>
                            <th class="column-residential_type_name">Sub-type</th>
                            <th class="column-size">Size</th>
                            <th class="column-consideration">Consideration</th>
                            <th *ngIf="targetProperty.type_id == 1 || targetProperty.type_id == 3 || targetProperty.type_id == 5"  class="column-building">Building ID</th>
                            <th class="column-date_of_transaction">Date of information/transaction</th>
                            <th class="column-exchange_rate">Exchange Rate</th>
                            <th class="column-converted_consideration">Consideration (in adopted currencty - {{targetProperty.country_currency}})</th>
                            <th class="column-actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let com of selectedComparables; let i = index"  
                            [ngClass]="{'colored-row': i%2==1, 'white-colored-row': i%2==0}">
                            <td>{{com.ref_num}}</td>
                            <td >
                                {{com.sub_type_category_name == 'Unknown'
                                    ? 'Unknown'
                                    : (com.sub_type_category_name + ' (' + com.sub_category_name + ')')}} 
                            </td>
                            <td>{{com.size}}</td>
                            <td>
                                <div style="display: flex; flex-direction: column;" *ngIf="com.tenures.length > 0 else no_data">
                                    <div *ngFor="let t of com.tenures">
                                        {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}{{t.tenure === 'leasehold' ? '/month' : ''}}
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="targetProperty.type_id == 1 || targetProperty.type_id == 3 || targetProperty.type_id == 5">{{com.buildingInfo ? com.buildingInfo.building_name : 'N/A'}}</td>
                            <td>{{com.source ? (com.source.source_date | date:'dd MMM yyyy') : 'N/A' }}</td>
                            <td class="cell-conversion">
                                <ng-container *ngIf="isCurrencySame(com.tenures)">
                                    <ng-container *ngIf="!isTenureCurrencyNTpCurrenySame(com.tenures[0]); else no_conversion">
                                        <ng-template #popContentSingle>
                                            <div>
                                                At Date of 
                                                    {{com.source.source_type_id == 1 
                                                        ? ('transaction (' + (com.source.transaction_date | date: 'dd MMM yyyy') + ')' )
                                                        : ('information (' + (com.source.information_date | date: 'dd MMM yyyy') + ')' )}}
                                            </div>
                                            <div>
                                                1 {{com.tenures[0].currency}} = {{convertedValues[com.id][com.tenures[0].id].rate ? convertedValues[com.id][com.tenures[0].id].rate : '?'}} {{targetProperty.country_currency}}
                                            </div>
                                        </ng-template>
                                        <mat-form-field>
                                            <input
                                                matInput 
                                                type="text" 
                                                [formControlName]="com.ref_num + '_' + com.tenures[0].id"
                                                (focusout)="onFocusOut($event, com.ref_num, com.tenures[0].id, true)" />
                                            <span matSuffix>
                                                ({{com.tenures[0].currency}}/{{ targetProperty.country_currency }})
                                                <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                                    [ngbPopover]="popContentSingle"
                                                    (click)="$event.stopPropagation()">
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </span>
                                        </mat-form-field>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!isCurrencySame(com.tenures)">
                                    <ng-container *ngFor="let t of com.tenures">
                                        <ng-container *ngIf="!isTenureCurrencyNTpCurrenySame(t); else no_conversion">
                                            <ng-template #popContent>
                                                <div>
                                                    At Date of 
                                                    {{com.source.source_type_id == 1 
                                                        ? ('transaction (' + (com.source.transaction_date | date: 'dd MMM yyyy') + ')' )
                                                        : ('information (' + (com.source.information_date | date: 'dd MMM yyyy') + ')' )}}
                                                </div>
                                                <div>
                                                    1 {{t.currency}} = {{convertedValues[com.id][t.id].rate ? convertedValues[com.id][t.id].rate : '?'}} {{targetProperty.country_currency}}
                                                </div>
                                            </ng-template>
                                            <mat-form-field>
                                                <input 
                                                    matInput 
                                                    type="text" 
                                                    [formControlName]="com.ref_num + '_' + t.id"
                                                    (focusout)="onFocusOut($event, com.ref_num, t.id, false)" />
                                                <span matSuffix>({{t.currency}}/{{targetProperty.country_currency}})
                                                    <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                                        [ngbPopover]="popContent"
                                                        (click)="$event.stopPropagation()">
                                                        <mat-icon>info</mat-icon>
                                                    </button>
                                                </span>
                                            </mat-form-field>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <div *ngFor="let t of com.tenures">
                                    {{convertedValues[com.id][t.id].converted 
                                            ? ((convertedValues[com.id][t.id].converted.toFixed(2) | mask:'separator':{thousandSeparator: ','} ) + ' ' + targetProperty.country_currency + (t.tenure === 'leasehold' ? '/month' : '') )
                                            : 'N/A'}}
                                </div>
                            </td>
                            <td>
                                <button mat-icon-button
                                        color="primary"
                                        [matTooltip]="'View Comparable Info'"
                                        (click)="showAcInfo(com)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
            
                                <button mat-icon-button
                                        [disabled]="readonly"
                                        color="warn"
                                        [matTooltip]="'Remove Comparable' | translate"
                                        type="button"
                                        (click)="deleteAc(com)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>
</div>

<ng-template #no_data>
    {{ 'No Data' }}
</ng-template>

<ng-template #no_conversion>
    N/A
</ng-template>

