import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PropertyDetailReportActions, PropertyDetailReportActionTypes } from '../_actions/property-detail-report.actions';
import { PropertyDetailReportModel } from '../_models/property-detail-report.model';

export interface PropertyDetailReportState extends EntityState<PropertyDetailReportModel> {
    lastCreatedReportId: number;
    isGenerating: Record<number, boolean>;
    isAllReportsLoaded: Record<number, boolean>;
}

export const adapter: EntityAdapter<PropertyDetailReportModel> = createEntityAdapter<PropertyDetailReportModel>();

export const initialPropertyDetailReportState: PropertyDetailReportState = adapter.getInitialState({
    lastCreatedReportId: undefined as number,
    isGenerating: {} as Record<number, boolean>,
    isAllReportsLoaded: {} as Record<number, boolean>
});

export function propertyDetailReportReducer(
    state = initialPropertyDetailReportState, 
    action: PropertyDetailReportActions
): PropertyDetailReportState {
    let currentLoadedReports: Record<number, boolean>;
    let currentIsGenerating: Record<number, boolean>;
    switch (action.type) {
        case PropertyDetailReportActionTypes.PropertyDetailReportOnServerCreated: {
            currentIsGenerating = Object.assign({}, state.isGenerating);
            currentIsGenerating[action.payload.report.tp_id] = true;
            return {
                ...state, isGenerating: currentIsGenerating,
            }
        }
        case PropertyDetailReportActionTypes.PropertyDetailReportCreated:
            currentIsGenerating = Object.assign({}, state.isGenerating);
            currentIsGenerating[action.payload.report.tp_id] = false
            return adapter.addOne(action.payload.report, {
                ...state, lastCreatedReportId: action.payload.report.id, isGenerating: currentIsGenerating
            });
        
        case PropertyDetailReportActionTypes.PropertyDetailReportUpdated:
            return adapter.updateOne(action.payload.partialReport, state);
        
        case PropertyDetailReportActionTypes.AllPropertyDetailReportsRequested: 
            currentLoadedReports = Object.assign({}, state.isAllReportsLoaded);
            currentLoadedReports[action.payload.tpID] = false;
            return {
                ...state, isAllReportsLoaded: currentLoadedReports
            };

        case PropertyDetailReportActionTypes.AllPropertyDetailReportsLoaded: 
            currentLoadedReports = Object.assign({}, state.isAllReportsLoaded);
            currentLoadedReports[action.payload.tpId] = true;
            currentIsGenerating = Object.assign({}, state.isGenerating);
            currentIsGenerating[action.payload.tpId] = false;
            
            return adapter.upsertMany(action.payload.reports, {
                ...state, isAllReportsLoaded: currentLoadedReports, isGenerating: currentIsGenerating
            });
            
        case PropertyDetailReportActionTypes.PropertyDetailReportOnServerDeleted:
            return adapter.removeOne(action.payload.id, state);

        case PropertyDetailReportActionTypes.PropertyDetailReportLoaded:
            return adapter.upsertOne(action.payload.report, state);

        case PropertyDetailReportActionTypes.PropertyDetailReportStateResetted:
            return adapter.removeAll(state);

        default: 
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();