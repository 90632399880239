// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {StateRepair} from '../_models/state-repair.model';

export enum StateRepairActionTypes {

    AllStateRepairsRequested = '[StateRepairs Home Page] All StateRepairs Requested',
    AllStateRepairsLoaded = '[StateRepairs API] All StateRepairs Loaded',

    StateRepairOnServerCreated = '[Edit StateRepair Dialog] StateRepair On Server Created',
    StateRepairCreated = '[Edit StateRepairs Dialog] StateRepairs Created',
    StateRepairUpdated = '[Edit StateRepair Dialog] StateRepair Updated',
    StateRepairDeleted = '[StateRepairs List Page] StateRepair Deleted',

    StateRepairsPageRequested = '[StateRepairs List Page] StateRepairs Page Requested',
    StateRepairsPageLoaded = '[StateRepairs API] StateRepairs Page Loaded',
    StateRepairsPageCancelled = '[StateRepairs API] StateRepairs Page Cancelled',

    StateRepairsPageToggleLoading = '[StateRepairs page] StateRepairs Page Toggle Loading',
    StateRepairsActionToggleLoading = '[StateRepairs] StateRepairs Action Toggle Loading',

    // trash system

    StateRepairOnServerRestored = '[StateRepair Trash] StateRepair On Server Restored',
    StateRepairRestored = '[StateRepair Trash] StateRepair Restored',

    StateRepairOnServerAdminRestored = '[StateRepair Admin Trash] StateRepair On Server Restored',
    StateRepairAdminRestored = '[StateRepair Admin Trash] StateRepair Restored',

    StateRepairDeletedFromTrash = '[StateRepair Trash] StateRepair deleted',
    StateRepairDeletedFromAdminTrash = '[StateRepair Admin Trash] StateRepair deleted',

    StateRepairTrash = 'StateRepair Trashed',
    StateRepairTrashFlushed = 'StateRepair Trash Flushed',
}

export class StateRepairOnServerCreated implements Action {
    readonly type = StateRepairActionTypes.StateRepairOnServerCreated;

    constructor(public payload: { stateRepair: StateRepair}) {
    }
}

export class StateRepairCreated implements Action {
    readonly type = StateRepairActionTypes.StateRepairCreated;

    constructor(public payload: { stateRepair: StateRepair }) {
    }
}

export class StateRepairUpdated implements Action {
    readonly type = StateRepairActionTypes.StateRepairUpdated;

    constructor(public payload: {
        partialStateRepair: Update<StateRepair>,
        stateRepair: StateRepair
    }) {
    }
}

export class StateRepairDeleted implements Action {
    readonly type = StateRepairActionTypes.StateRepairDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class StateRepairsPageRequested implements Action {
    readonly type = StateRepairActionTypes.StateRepairsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class StateRepairsPageLoaded implements Action {
    readonly type = StateRepairActionTypes.StateRepairsPageLoaded;

    constructor(public payload: { stateRepairs: StateRepair[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class StateRepairsPageCancelled implements Action {
    readonly type = StateRepairActionTypes.StateRepairsPageCancelled;
}

export class AllStateRepairsRequested implements Action {
    readonly type = StateRepairActionTypes.AllStateRepairsRequested;
}

export class AllStateRepairsLoaded implements Action {
    readonly type = StateRepairActionTypes.AllStateRepairsLoaded;

    constructor(public payload: { stateRepairs: StateRepair[] }) {
    }
}

export class StateRepairsPageToggleLoading implements Action {
    readonly type = StateRepairActionTypes.StateRepairsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class StateRepairsActionToggleLoading implements Action {
    readonly type = StateRepairActionTypes.StateRepairsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class StateRepairDeletedFromAdminTrash implements Action {
    readonly type = StateRepairActionTypes.StateRepairDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class StateRepairDeletedFromTrash implements Action {
    readonly type = StateRepairActionTypes.StateRepairDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class StateRepairOnServerRestored implements Action {
    readonly type = StateRepairActionTypes.StateRepairOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class StateRepairRestored implements Action {
    readonly type = StateRepairActionTypes.StateRepairRestored;

    constructor(public payload: { item: StateRepair }) {
    }
}

export class StateRepairOnServerAdminRestored implements Action {
    readonly type = StateRepairActionTypes.StateRepairOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class StateRepairAdminRestored implements Action {
    readonly type = StateRepairActionTypes.StateRepairAdminRestored;

    constructor(public payload: { item: StateRepair }) {
    }
}

export class StateRepairTrashFlushed implements Action {
    readonly type = StateRepairActionTypes.StateRepairTrashFlushed;

    constructor() {
    }
}

export type StateRepairActions = StateRepairCreated
    | StateRepairUpdated
    | StateRepairDeleted
    | StateRepairsPageRequested
    | StateRepairsPageLoaded
    | StateRepairsPageCancelled
    | AllStateRepairsLoaded
    | AllStateRepairsRequested
    | StateRepairOnServerCreated
    | StateRepairsPageToggleLoading
    | StateRepairsActionToggleLoading
    | StateRepairDeletedFromAdminTrash
    | StateRepairDeletedFromTrash
    | StateRepairOnServerRestored
    | StateRepairRestored
    | StateRepairOnServerAdminRestored
    | StateRepairAdminRestored
    | StateRepairTrashFlushed;
