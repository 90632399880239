import { combineLatest, forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, take, switchMap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Services
import { AssignmentsService } from '../_services/';
// State
import { AppState } from '../../reducers';
// Actions
import {
    AssignmentActionTypes,
    AssignmentsPageRequested,
    AssignmentsPageLoaded,
    ManyAssignmentsDeleted,
    AssignmentActionToggleLoading,
    AssignmentsPageToggleLoading,
    AssignmentCreated,
    AssignmentUpdated,
    AssignmentsStatusUpdated,
    AssignmentOnServerCreated,
    AssignmentRestored,
    AssignmentOnServerRestored,
    AssignmentOnServerAdminRestored,
    AssignmentAdminRestored,
    AssignmentDeletedFromTrash,
    AssignmentDeletedFromAdminTrash,
    AssignmentTrashFlushed,
    OneAssignmentDeleted,
    OneAssignmentRequest,
    OneAssignmentLoaded,
    AssignmentDuplicated,
    AssignmentOnServerDuplicated,
    SetAssignmentStatusToValidated,
    AssignmentEmptyAction,
    AssignmentStatusUpdated,
    SetAssignmentStatusToOngoing,
    AllAssignmentsRequested,
    AllAssignmentsLoaded,
} from '../_actions/assignment.actions';
import { of } from 'rxjs';
import { selectAssignmentById } from '../_selectors/assignment.selectors';
import { Update } from '@ngrx/entity';
import { AssignmentModel } from '..';

@Injectable()
export class AssignmentEffects {
    showPageLoadingDispatcher = new AssignmentsPageToggleLoading({ isLoading: true });
    showActionLoadingDispatcher = new AssignmentActionToggleLoading({ isLoading: true });
    hideActionLoadingDispatcher = new AssignmentActionToggleLoading({ isLoading: false });

    @Effect()
    loadAssignmentsPage$ = this.actions$.pipe(
        ofType<AssignmentsPageRequested>(AssignmentActionTypes.AssignmentsPageRequested),
        switchMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map((response) => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssignmentsPageLoaded({
                assignments: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadAssignment$ = this.actions$.pipe(
        ofType<OneAssignmentRequest>(AssignmentActionTypes.OneAssignmentRequest),
        mergeMap(({ payload }) => {
            return this.service.getById(payload.id);
        }),
        map(response => {
            return new OneAssignmentLoaded({
                assignment: response.data
            });
        })
    );

    @Effect()
    deleteAssignment$ = this.actions$
        .pipe(
            ofType<OneAssignmentDeleted>(AssignmentActionTypes.OneAssignmentDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.delete(payload.id);
            }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssignments$ = this.actions$
        .pipe(
            ofType<ManyAssignmentsDeleted>(AssignmentActionTypes.ManyAssignmentsDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteAssignments(payload.ids);
            }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentUpdated>(AssignmentActionTypes.AssignmentUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.assignment);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    updateAssignmentsStatus$ = this.actions$
        .pipe(
            ofType<AssignmentsStatusUpdated>(AssignmentActionTypes.AssignmentsStatusUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateStatusForAssignment(payload.assignments, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    createAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentOnServerCreated>(AssignmentActionTypes.AssignmentOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.assignment).pipe(
                    tap(res => {
                        this.store.dispatch(new AssignmentCreated({ assignment: res.data }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    duplicateAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentOnServerDuplicated>(AssignmentActionTypes.AssignmentOnServerDuplicated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.duplicate(
                    payload.assignment,
                    payload.toesNames,
                    payload.oldAssignmentID,
                ).pipe(
                    tap(res => {
                        const data = res.data;
                        data.toe_cnt = payload.assignment.toe_cnt;
                        data.relation_cnt = payload.assignment.relation_cnt;
                        this.store.dispatch(new AssignmentDuplicated({ assignment: data }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentOnServerRestored>(AssignmentActionTypes.AssignmentOnServerRestored),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssignmentRestored({ assignment: res.data }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentOnServerAdminRestored>(AssignmentActionTypes.AssignmentOnServerAdminRestored),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssignmentAdminRestored({ item: res.data }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteAssignmentFromTrash$ = this.actions$
        .pipe(
            ofType<AssignmentDeletedFromTrash>(AssignmentActionTypes.AssignmentDeletedFromTrash),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentDeletedFromAdminTrash>(AssignmentActionTypes.AssignmentDeletedFromAdminTrash),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAssignmentTrash$ = this.actions$
        .pipe(
            ofType<AssignmentTrashFlushed>(AssignmentActionTypes.AssignmentTrashFlushed),
            mergeMap(() => {
                return this.service.flushTrash();
            }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    setAssignmentStatusToValidated$ = this.actions$
        .pipe(
            ofType<SetAssignmentStatusToValidated>(AssignmentActionTypes.SetAssignmentStatusToValidated),
            mergeMap(({ payload }) => {
                return combineLatest([
                    this.store.select(selectAssignmentById(payload.id)).pipe(take(1)),
                    of(payload.id)
                ])
            }),
            mergeMap(([assignment, id]) => {
                if (assignment) {
                    return of(assignment);
                }
                return this.service.getById(id).pipe(map(res => res.data));
            }),
            map(assignment => {
                if (assignment.status == 1 || assignment.status == 2) {
                    return new AssignmentEmptyAction();
                }
                const newAssignment: Update<AssignmentModel> = {
                    id: assignment.id,
                    changes: {
                        status: 1
                    }
                }
                return new AssignmentStatusUpdated({ updateAssignment: newAssignment });
            })
        )

    @Effect()
    setAssignmentStatusToOngoing$ = this.actions$
        .pipe(
            ofType<SetAssignmentStatusToOngoing>(AssignmentActionTypes.SetAssignmentStatusToOngoing),
            mergeMap(({ payload }) => {
                return combineLatest([
                    this.store.select(selectAssignmentById(payload.id)).pipe(take(1)),
                    of(payload.id)
                ])
            }),
            mergeMap(([assignment, id]) => {
                if (assignment) {
                    return of(assignment);
                }
                return this.service.getById(id).pipe(map(res => res.data));
            }),
            map(assignment => {
                if (assignment.status == 0) {
                    return new AssignmentEmptyAction();
                }
                const newAssignment: Update<AssignmentModel> = {
                    id: assignment.id,
                    changes: {
                        status: 0
                    }
                }
                return new AssignmentStatusUpdated({ updateAssignment: newAssignment });
            })
        )

    @Effect()
    updateStatusOfAssignment$ = this.actions$
        .pipe(
            ofType<AssignmentStatusUpdated>(AssignmentActionTypes.AssignmentStatusUpdated),
            mergeMap(({ payload }) => {
                return this.service.updateStatus(Number(payload.updateAssignment.id), payload.updateAssignment.changes.status);
            }),
            map(res => {
                return new AssignmentEmptyAction();
            })
        )

    @Effect()
    allAssignmentsRequested$ = this.actions$
        .pipe(
            ofType<AllAssignmentsRequested>(AssignmentActionTypes.AllAssignmentsRequested),
            mergeMap(_ => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllAssignmentsLoaded({assignments: res.data})
            })
        )


    constructor(private actions$: Actions, private service: AssignmentsService, private store: Store<AppState>) {
    }
}
