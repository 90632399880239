// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {AppState} from '../../reducers';
import {Update} from '@ngrx/entity';
import {Store} from '@ngrx/store';
// Services
import {CityService} from '../_services/city.service';
// Actions
import {
    AllCitiesRequested,
    AllCitiesLoaded,
    CityActionTypes,
    CitiesActionToggleLoading,
    CityCreated,
    CityOnServerCreated,
    CityUpdatedOnServer,
    CityUpdated,
    CityDeleted, CityTrashFlushed
} from '../_actions/city.actions';
// Models
import {CityModel} from '../_models/city.model';


@Injectable()
export class CityEffects {

    showActionLoadingDispatcher = new CitiesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CitiesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllCities$ = this.actions$
        .pipe(
            ofType<AllCitiesRequested>(CityActionTypes.AllCitiesRequested),
            mergeMap(() => this.service.getAll()),
            map((result: any) => {
                return new AllCitiesLoaded({
                    cities: result.data
                });
            })
        );

    @Effect()
    deleteCity$ = this.actions$
        .pipe(
            ofType<CityDeleted>(CityActionTypes.CityDeleted),
            mergeMap(({payload}) => {
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateCity$ = this.actions$
        .pipe(
            ofType<CityUpdatedOnServer>(CityActionTypes.CityUpdatedOnServer),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.city).pipe(
                    tap(res => {
                        const updateCity: Update<CityModel> = {
                            id: res.data.id,
                            changes: res.data
                        };
                        this.store.dispatch(new CityUpdated({partialCity: updateCity}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createCity$ = this.actions$
        .pipe(
            ofType<CityOnServerCreated>(CityActionTypes.CityOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.city).pipe(
                    tap(res => {
                        this.store.dispatch(new CityCreated({city: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushClientTrash$ = this.actions$
        .pipe(
            ofType<CityTrashFlushed>(CityActionTypes.CityTrashFlushed),
            mergeMap(({payload}) => {
                    return this.service.flush(payload.countryId);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     return of(new AllCitiesRequested());
    // });

    constructor(private actions$: Actions, private service: CityService, private store: Store<AppState>) {
    }
}
