<div class="mad-container">
    <!-- <div ktPortletTitle class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">SIZES {{addingTitle}}</h5>
        <hr class="active">
    </div> -->
    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef>No</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index;">
                    {{i + 1}}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ 'Total (' + staticUnitName + ')' }} </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="lat">
                <mat-header-cell *matHeaderCellDef>X</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.lat}}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="lng">
                <mat-header-cell *matHeaderCellDef>Y</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.lng}}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <span> {{ selectedAreaSubject.value.toPrecision(6) + staticUnitName }} </span>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="length">
                <mat-header-cell *matHeaderCellDef> {{ 'Length (' + staticUnitLengthName + ')' }} </mat-header-cell>
                <mat-cell *matCellDef="let item; let i = length;">
                    {{staticUnit == 2 ? item.distance * imperial_length : item.distance}}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
        </mat-table>
    </div>
</div>