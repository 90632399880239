<div class="mad-container">
    <div ktPortletTitle class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">SERVICES AND INFRASTRUCTURES</h5>
        <hr class="active">
    </div>
    
    <div class="form" [formGroup]="form">

        <section class="row">
            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-label style="color: gray;"> 
                    Please indicate here all the services, utilities and or infrastructure that serves the property. They shall include, but are mot limited to, all items that form an integral part of the land parcel.
                </mat-label>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-checkbox class="mat-form-field-fluid margin-top-12" 
                    formControlName="road_access">Road and other viable access
                </mat-checkbox>
            </div>


            <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="form.controls.road_access.value && form.controls.road_access.value == '1'">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Status and quality" formControlName="status_and_quality"/>
                    <mat-error>
                        Status and quality
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="form.controls.road_access.value && form.controls.road_access.value == '1'">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Access/egress points (vehicular and pedestrian)" formControlName="access_points"/>
                    <mat-error>
                        Access/egress points (vehicular and pedestrian)
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="form.controls.road_access.value && form.controls.road_access.value == '1'">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Length of road frontage" formControlName="road_length"/>
                </mat-form-field>
            </div>

            <div class="col-lg-6 kt-margin-bottom-10-mobile" *ngIf="form.controls.road_access.value && form.controls.road_access.value == '1'">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Ownership if in the ownership of another and consent(s)" formControlName="owner_of_another_consent"/>
                </mat-form-field>
            </div>

            <div class="col-lg-12 row align-items-center">
                <div class="col-lg-4" *ngFor="let item of landParcelFacilities">
                    <mat-checkbox
                            [checked]="setSelectedFacilities(item)"
                            (change)="updateCheckedOptions(item, $event)">
                        {{ item.name }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid" *ngIf="toggleSpecify()">
                    <input matInput placeholder="Please specify" formControlName="specify_other_service"/>
                    <mat-error>
                        Please specify
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </section>
    </div>
</div>