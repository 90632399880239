import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { InvoiceService, OneToeRequest, ToeModel, ToeReportService, ToeService } from 'src/app/core/toe';
import { AllToeReportTasksByToeIDRequested, DeleteReportTask } from 'src/app/core/toe/_actions/toe-report-task.actions';
import { NewCoiModel } from 'src/app/core/toe/_models/new-coi.model';
import { ToeAmendmentModel } from 'src/app/core/toe/_models/toe-amendment.model';
import { ToeReportTaskModel } from 'src/app/core/toe/_models/toe-report-task.model';
import { NewCoiService } from 'src/app/core/toe/_services/new-coi.service';
import { ToeAmendmentService } from 'src/app/core/toe/_services/toe-amendment.service';
import { LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { ImageViewerDialogComponent } from '../../../shared_components/image_viewer/image-viewer.dialog.component';
import { CoiDialogComponent, CoiDialogInput, CoiDialogReturn } from '../../_sub/coi-dialog/coi-dialog.component';
import { EditReportTaskInfoComponent } from '../edit-report-task-info/edit-report-task-info.component';
import { EditInvoicePvdInfoComponent } from '../edit-invoice-pvd-info/edit-invoice-pvd-info.component';
import { ToeInvoice } from 'src/app/core/toe/_models/toe-invoice.model';
import { ToeInvoicePvd } from 'src/app/core/toe/_models/toe-invoice-pvd.model';

@Component({
  selector: 'kt-toe-invoices',
  templateUrl: './toe-invoices.component.html',
  styleUrls: ['./toe-invoices.component.scss']
})
export class ToeInvoicesComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() toe: ToeModel;
  @Input() toeID: number;
  @Input() leadValuerID: number;
  @Input() workers: User[];
  @Input() pm: User;
  @Input() targetProperties: AssetClassModel[];
  @Output() invoiceIncompleteTaskCnt = new EventEmitter<any>();
  @ViewChild(NgbDropdown) dropDown: NgbDropdown;

  status = Status;
  state = State;
  columns = ['task_name', 'status_date', 'amount', 'actions'];
  dataSource = new MatTableDataSource<DataSourceInterface>([]);
  docs = ['xlsx', 'xls', 'doc', 'docx'];
  currentUser: User;
  tasks: ToeReportTaskModel[] = [];
  newCoi: NewCoiModel;
  toeAmendmentModel: ToeAmendmentModel;
  cancel$: Subject<void> = new Subject();
  getInformedConsentsReportCMD$: Subject<void> = new Subject();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showAddInvoiceBtn$: Observable<boolean>;
  currency = '';
  math = Math;
  incomplete_task_cnt = 0;
  constructor(
    private store: Store<AppState>,
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private newCoiService: NewCoiService,
    private toeAmendmentService: ToeAmendmentService,
    private toeService: ToeService,
    private invoiceService: InvoiceService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new AllToeReportTasksByToeIDRequested({toeID: this.toeID}));
    this.newCoiService.getNewCoi(this.toeID).pipe(takeUntil(this.cancel$)).subscribe(res => this.newCoi = res);
    this.toeAmendmentService.getAmendment(this.toeID).pipe(takeUntil(this.cancel$)).subscribe(res => {
      this.toeAmendmentModel = res;
    });

    this.showAddInvoiceBtn$ = combineLatest([
      of(this.toe),
      this.store.select(currentUser).pipe(filter(user => user != null), tap(user => this.currentUser = user)),
    ]).pipe(map(([toe, user]) => {
      let show_pvd_invoice = toe.postValuationDeliveryServicetData && toe.postValuationDeliveryServicetData.overridden_in_toe
      return toe.status != 4 && show_pvd_invoice && user.id == this.leadValuerID || (show_pvd_invoice && user.id == this.pm.id) 
    }))

    combineLatest([
      this.store.select(currentUser).pipe( filter(user => user != null), tap(user => this.currentUser = user)),
      this.getInformedConsentsReportCMD$.pipe(
        switchMap(() => {
          return this.toeService.getToeById(this.toeID)
        }),
        map(res => res.data)
      ),
    ]).pipe(
        takeUntil(this.cancel$),
        map(([user, toe]: [User, ToeModel]) => {
          this.incomplete_task_cnt = 0;
          const currendDate = new Date();
          this.toe = toe;

          const data: DataSourceInterface[] = [];
          toe['invoices'].sort(function(a, b){return (a.term ? (a.term.order-b.term.order) : (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()))}).forEach(invoice => {
            this.currency = invoice.payment.currency;

            // Task completion status
            const taskExpDate = this.typesUtilsService.getDateFromString(invoice.status_date);
            const stat = this.getTaskStatus(invoice.status, currendDate, taskExpDate);

            // Task state
            let state = State.Initial;
            let reportURL = null;
            let additionalTaskInfo: AdditionalTaskInfo = null;
            let canBeDeleted = false;
            let canBeSeen = invoice.task.point == invoice.task.max_point;
            let canBeEdited = toe.status != 4 && toe.status != -1 && (user.id == this.leadValuerID || user.id == this.pm.id);
            let canBeReached = toe.status != 4 && (user.id == this.leadValuerID || user.id == this.pm.id || user.id == invoice.task.assignee_id);
            let canBeUserDeleted = false;
            let infoCanBeShown = invoice.status == 'Completed' || invoice.status == 'Voided' ? true : false;
            this.incomplete_task_cnt += invoice.task.point != invoice.task.max_point ? 1 : 0;
            this.invoiceIncompleteTaskCnt.emit({incomplete: this.incomplete_task_cnt, total: toe['invoices'].length});

            const d: DataSourceInterface = {
              invoice: invoice,
              taskID: invoice.task.id,
              taskName: this.getTaskName(invoice, toe['invoices']),
              amount: invoice.term ? invoice.term.amount : this.getAmendmentAmount(invoice),
              status: invoice.status,
              void_reason: invoice.void_reason,
              comment: invoice.comment,
              status_date: invoice.status_date,
              taskDetail: invoice.task.detail,
              taskState: state,
              additionalTaskInfo,
              generatedReportURL: reportURL,
              taskActions: {
                infoCanBeShown,
                canBeUserDeleted,
                canBeDeleted,
                canBeEdited,
                canBeReached,
                canBeSeen
              },
              assignee: {
                id: invoice.task.user_id,
                name: invoice.task.user_name
              },
              completion: {
                expectedDate: invoice.task.date,
                completedDate: invoice.task.updated_at,
                status: stat.status,
                invoice_status: stat.invoice_status,
                remainingTime: stat.remainingTime,
                overdueTime: stat.overdueTime
              },
            }
            data.push(d);
          }) 
          return data;
        })
      )
      .subscribe(data => this.dataSource.data = data);

    this.getInformedConsentsReportCMD$.next();
  }

  ngOnDestroy() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  public downloadReport(item) {
    this.invoiceService.generateReport(item).subscribe(res => {
        if (res.type == 4) {
          let blob = new Blob([res.body], { type: res.body.type});
          var fileName = res.headers.get('Content-Disposition').split('filename=')[1].split(';')[0].split('/')[1];
          fileName = fileName.substring(0, fileName.length - 1);
          let url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = url;
          anchor.click();
        }
      },
      err => {
        alert("Problem while downloading the file.");
      });
  }

  public addConflictOfInterests() {
    const dialogRef = this.dialog.open<CoiDialogComponent, CoiDialogInput, CoiDialogReturn>(CoiDialogComponent, {
      data: {
        toe: this.toe,
        newCoi: this.newCoi,
        targetProperties: this.targetProperties,
        purposeOfValuationID: this.toe.purpose_of_valuation_id,
        hasPrevKnowledge: this.newCoi.has_prev_knowledge,
        hasFinancialInterest: this.newCoi.has_financial_interest
      },
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.loading$.next(true);
      this.newCoiService.updateNewCoi(res.model).pipe(takeUntil(this.cancel$)).subscribe(res => {
        this.loading$.next(false);
        this.newCoi = res;
        this.store.dispatch(new AllToeReportTasksByToeIDRequested({toeID: this.toeID}));
      })
    })
  }
  public deleteTask(row: DataSourceInterface) {
    this.store.dispatch(new DeleteReportTask({id: row.taskID}));
  }
  public viewDocument(row: DataSourceInterface) {
    const reportTask = this.tasks.find(t => t.id === row.taskID);
    const report = reportTask ? reportTask.report.data.find(r => r.checked == 1) : null;
    if (!report) {
      return;
    }
    if (this.docs.indexOf(this.typesUtilsService.getFileType(report.url)) > -1) {
      window.open(environment.baseApiUrl + report.url);
    } else {
      this.dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: report.url,
          type: this.typesUtilsService.getFileType(report.url)
        }
      });
    }
  }
  public recordPvdInvoice(item) {
    var toeInvoicePayment = new ToeInvoice;

    var _title = '';
    if (item == null) {
      _title = 'New Invoice';
      toeInvoicePayment = new ToeInvoice;
      
      toeInvoicePayment.clear();
    } else {
      toeInvoicePayment = item.invoice;
      _title = 'Invoice '+toeInvoicePayment.invoice_number;
    }
    
    const dialogRef = this.dialog.open(EditInvoicePvdInfoComponent, {
      data: {
        title: _title,
        toe: this.toe,
        currency: this.currency,
        references: [this.toe.postValuationDeliveryServicetData],
        invoicePayment: toeInvoicePayment
      },
      width: '640px'
    })
    dialogRef.afterClosed().subscribe(res => {
      this.getInformedConsentsReportCMD$.next();
    })
  }
  public editTaskInformation(row: DataSourceInterface) {
    // const _title = 'Change Information';
    // const users: User[] = [];
    // this.workers.forEach(w => users.push(w));

    // const indx = users.findIndex(u => u.id == this.pm.id);
    // if (indx < 0) {
    //   users.unshift(this.pm);
    // }

    // const reportTask = this.tasks.find(t => t.id == row.taskID);

    // this.dialog.open(EditReportTaskInfoComponent, {
    //   data: {
    //     title: _title,
    //     currentUserId: row.assignee.id,
    //     currentDate: row.completion.expectedDate,
    //     users,
    //     task: reportTask,
    //     manager_id: this.pm.id,
    //     lead_valuer_id: this.leadValuerID,
    //     pmIsWorker: this.isWorker(this.pm.id) 
    //   },
    //   width: '540px'
    // })

  }

  public deletePvdInvoice(row: DataSourceInterface) {    
    const _title = 'Warning';
    const _description = 'You are about to delete a recorded invoice. This action is not reversible. Are you sure you want to proceed?';
    const _waitDesciption = '';

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.invoiceService.deleteToeInvoicePayment(row.invoice.id).subscribe(res => {
        this.getInformedConsentsReportCMD$.next();
      });
    });
  }

  private isWorker(id: number) {
    // const worker = this.workers.filter(w => w.id == id);
    // return worker.length > 0;
  }

  private getTaskStatus(invoice_status, currentDate: Date, expDate: Date): {
    status: Status,
    remainingTime: string,
    overdueTime: string,
    invoice_status: string,
  } {
    let status = null;
    let remainingTime: string = null;
    let overDueTime = null;
    if (invoice_status == 'Upcoming' && this.isDatesAreSame(currentDate, expDate)) {
      status = Status.Upcoming;
    } else {
      const diff = currentDate.getTime() - expDate.getTime();
      if (invoice_status == 'Upcoming' && diff < 0) {
        status = Status.Upcoming;
        remainingTime = this.getDaysString(Math.abs(diff));
      } else {
        status = Status.Overdue;
        overDueTime = this.getDaysString(diff);
      }
    }
    if (invoice_status == 'Completed') {
      status = Status.Completed;
    } else if (invoice_status == 'Voided') {
      status = Status.Voided;
    } else if (invoice_status == 'Overpaid') {
      status = Status.Overpaid;
    } else if (invoice_status == 'Partially Paid') {
      status = Status.PartiallyPaid;
    }

    return {
      status,
      remainingTime,
      overdueTime: overDueTime,
      invoice_status
    }
  }

  private isDatesAreSame(a: Date, b: Date): boolean {
    return (
      a.getFullYear() == b.getFullYear() && a.getMonth() == a.getMonth() && a.getDate() == b.getDate()
    )
  }

  private getDaysString(diff: number): string {
    const days = Math.floor(diff / 1000 / 60 / 60 / 24);
    return `${days} day${days > 1 ? 's' : ''}`
  }

  private getTaskName(invoice, toe_invoices) {
    if (invoice.term) { // regular invoice
      const total = toe_invoices.filter((el) => el.payment_term_id != null).length;
      return invoice.invoice_number + (total > 1 ? ' ' + invoice.term.order + '/' + total : '');
    } else if (invoice.payment_amendment && invoice.task.details_name.includes('Invoice -') && invoice.task.details_name.includes('(')) { // amendment invoice
      return invoice.invoice_number + ' ' + invoice.task.details_name.substring(invoice.task.details_name.indexOf('- ') + 1, invoice.task.details_name.indexOf('('));
    } else if (invoice.payment_amendment && invoice.task.details_name.includes('Payment')) { // amendment invoice
      return invoice.invoice_number + ' Amendment';
    } else{
      return invoice.invoice_number;
    }
  }

  public getAmendmentAmount(invoice) {
    if (invoice.payment_amendment) {
      let abort_total_paying_amount = 0;
      let tp_remove_total_paying_amount = 0;
      const amendment_id =  invoice.payment_amendment.id;
      const abort_toe_amendments =  invoice.payment_amendment.abort_toe_amendments;
      const remove_tp_amendments =  invoice.payment_amendment.remove_tp_amendments;

      abort_total_paying_amount = abort_toe_amendments
        .filter((el) => el.amendment_id == amendment_id && (el.payment_arbitration == 1 || el.payment_arbitration == 2))
        .reduce((prevAmount, curAmendment) => prevAmount + (curAmendment.payed_party == 1 ? -Math.abs(curAmendment.paying_amount) : curAmendment.paying_amount), 0);

      tp_remove_total_paying_amount = remove_tp_amendments
        .filter((el) => el.amendment_id == amendment_id && (el.payment_arbitration == 1 || el.payment_arbitration == 2))
        .reduce((prevAmount, curAmendment) => prevAmount + (curAmendment.payed_party == 1 ? -Math.abs(curAmendment.paying_amount) : curAmendment.paying_amount), 0);

      const total = abort_total_paying_amount + tp_remove_total_paying_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    } else{
      const total = invoice.invoice_amount;
      const vatPrice = invoice.toe_payment.vat * total / 100;
      const vatOtherPrice = invoice.toe_payment.vat_other * total / 100;

      return total + vatPrice + vatOtherPrice;
    }
    return 'calculating...';
  }
}

interface DataSourceInterface {
  invoice: any;
  taskID: number;
  taskName: string;
  amount: string;
  status: string;
  status_date: string;
  void_reason: string;
  comment: string;
  taskDetail: string;
  taskState: State;
  taskActions: TaskActions;
  additionalTaskInfo: AdditionalTaskInfo;
  generatedReportURL: string;
  assignee: Assignee;
  completion: TaskCompletion;
}

interface TaskActions {
  infoCanBeShown: boolean;
  canBeDeleted: boolean;
  canBeUserDeleted: boolean;
  canBeSeen: boolean;
  canBeEdited: boolean;
  canBeReached: boolean;
}

interface AdditionalTaskInfo {
  createdDate: string;
  userID: number;
  userName: string;
  taskId: number;
}

interface Assignee {
  id: number;
  name: string;
}

interface TaskCompletion {
  status: Status;
  expectedDate: string;
  invoice_status: string;
  remainingTime: string;
  overdueTime: string;
  completedDate: string;
}

enum Status {
  Upcoming,
  Overdue,
  Voided,
  Completed,
  Overpaid,
  PartiallyPaid,
}

enum State {
  Initial,
  Additional
}
