// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {PaymentTypesService} from '../_services/payment-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allPaymentTypesLoaded} from '../_selectors/payment-type.selectors';
// Actions
import {
    AllPaymentTypesLoaded,
    AllPaymentTypesRequested,
    PaymentTypeActionTypes,
    PaymentTypesPageRequested,
    PaymentTypesPageLoaded,
    PaymentTypeUpdated,
    PaymentTypesPageToggleLoading,
    PaymentTypeDeleted,
    PaymentTypeOnServerCreated,
    PaymentTypeCreated,
    PaymentTypeAdminRestored,
    PaymentTypeDeletedFromAdminTrash,
    PaymentTypeDeletedFromTrash,
    PaymentTypeOnServerAdminRestored,
    PaymentTypeOnServerRestored,
    PaymentTypeRestored,
    PaymentTypeTrashFlushed,
    PaymentTypesActionToggleLoading
} from '../_actions/payment-type.actions';

@Injectable()
export class PaymentTypeEffects {
    showPageLoadingDispatcher = new PaymentTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PaymentTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PaymentTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllPaymentTypes$ = this.actions$
        .pipe(
            ofType<AllPaymentTypesRequested>(PaymentTypeActionTypes.AllPaymentTypesRequested),
            withLatestFrom(this.store.pipe(select(allPaymentTypesLoaded))),
            filter(([action, isAllPaymentTypesLoaded]) => !isAllPaymentTypesLoaded),
            mergeMap(() => this.service.getAllPaymentTypes()),
            map(res => {
                return new AllPaymentTypesLoaded({paymentTypes: res.data});
            })
        );

    @Effect()
    loadPaymentTypesPage$ = this.actions$
        .pipe(
            ofType<PaymentTypesPageRequested>(PaymentTypeActionTypes.PaymentTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findPaymentTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PaymentTypesPageLoaded({
                    paymentTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deletePaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeDeleted>(PaymentTypeActionTypes.PaymentTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deletePaymentType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updatePaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeUpdated>(PaymentTypeActionTypes.PaymentTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updatePaymentType(payload.paymentType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createPaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeOnServerCreated>(PaymentTypeActionTypes.PaymentTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createPaymentType(payload.paymentType).pipe(
                    tap(res => {
                        this.store.dispatch(new PaymentTypeCreated({paymentType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushPaymentTypeTrash$ = this.actions$
        .pipe(
            ofType<PaymentTypeTrashFlushed>(PaymentTypeActionTypes.PaymentTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeOnServerRestored>(PaymentTypeActionTypes.PaymentTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PaymentTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminPaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeOnServerAdminRestored>(PaymentTypeActionTypes.PaymentTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PaymentTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashPaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeDeletedFromTrash>(PaymentTypeActionTypes.PaymentTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashPaymentType$ = this.actions$
        .pipe(
            ofType<PaymentTypeDeletedFromAdminTrash>(PaymentTypeActionTypes.PaymentTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: PaymentTypesService, private store: Store<AppState>) {
    }
}
