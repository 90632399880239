import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { selectAllSpecialAssumptions } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import * as assumptionDepartureSelectors from 'src/app/core/valuation/_selectors/assumption_departure.selectors';
import * as valuationSRERSelectors from 'src/app/core/valuation/_selectors/valuation-srer.selectors';

type ModuleData = {
  assumptions: AssumptionData[],
  lastGeneral: number;
}

type AssumptionData = {
  source: string;
  input: string;
};

@Component({
  selector: 'kt-special-assumptions-module',
  templateUrl: './special-assumptions-module.component.html',
  styleUrls: ['../info-module.styles.scss', './special-assumptions-module.component.scss']
})
export class SpecialAssumptionsModuleComponent implements OnInit {
  @Input() targetProperty: AssetClassModel;
  data$: Observable<ModuleData>
  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      of(this.targetProperty),
      this.store$.select(selectAllSpecialAssumptions),
      this.store$.select(assumptionDepartureSelectors.selectNewSpecialAssumptions),
      this.store$.select(valuationSRERSelectors.selectSpecialAssumption)
    ]).pipe(map(([tp, specialAssumptions, newAssumptions, serAssumptions]) => {
        const toeSpecialAssumptions: AssumptionData[] = this.targetProperty.selected_special_assumptions
          .filter(a => {
            if (a.special_assumption_name !== 'None' && a.special_assumption_id !== 1) {
              return true;
            }
            return false;
          })
          .map(a => {
            const specialAssumption = specialAssumptions.find(s => s.id === a.special_assumption_id);
            const data: AssumptionData = {
              source: 'General ToE',
              input: specialAssumption ? specialAssumption.name : a.special_assumption_name
            }
            return data;
          });
        const docSpecialAssumptions: AssumptionData[] = this.targetProperty.external_references
          .filter(ref => ref.special_assumption)
          .map(ref => {
            const data: AssumptionData = {
              source: `ToE Doc - ${ref.source_external_reference.name}`,
              input: ref.special_assumption_descr
            }
            return data;
          });
        const newSpecialAssumptions: AssumptionData[] = newAssumptions
          .map(a => {
            const data: AssumptionData = {
              source: 'New Input',
              input: a.input
            }
            return data;
          })
        const newSerAsumptions: AssumptionData[] = serAssumptions
          .map(a => {
            const data: AssumptionData = {
              source: 'New Input',
              input: a.input
            }
            return data;
          });
        const data: ModuleData = {
          assumptions: [...toeSpecialAssumptions, ...docSpecialAssumptions, ...newSpecialAssumptions, ...newSerAsumptions],
          lastGeneral: toeSpecialAssumptions.length
        }
        return data;
      }))
  }

}
