import {BaseModel} from '../../_base/crud';

export class CertificationFileModel extends BaseModel {
    id: number;

    org_id: number;
    org_name: string;
    cert_type: number;
    cert_name: string;
    registration: string;
    date: string;
    name: string;
    path: string;
    is_prime_ref: boolean;


    clear() {
        this.id = undefined;
        this.org_id = undefined;
        this.org_name = '';
        this.cert_type = undefined;
        this.cert_name = '';
        this.registration = '';
        this.date = '';
        this.name = '';
        this.path = '';
        this.is_prime_ref = false
    }
}
