<div class="percent-input-container cell-dark-gray" [formGroup]="form"
        [ngClass]="{
            'cell-dark-gray': !threshold || (val$|async) == threshold,
            'cell-red': threshold || (val$|async) < threshold,
            'cell-green': threshold || (val$|async) > threshold
        }">
    <button class="percent-input-btn red-btn"
        type="button"
        (click)="changeVal('decrease')"
        [matTooltip]="decreaseButtonTooltip"
        matTooltipPosition="left"
        matTooltipClass="red-btn-tooltip">
        -
    </button>
    <input percentRange [min]="0" class="percent-input-element" formControlName="percent" size="5"
        (focusout)="onFocusOut($event)">
    <button class="percent-input-btn green-btn"
        type="button"
        (click)="changeVal('increase')"
        [matTooltip]='increaseButtonTooltip'
        matTooltipPosition="right"
        matTooltipClass="green-btn-tooltip">
        +
    </button>
</div>