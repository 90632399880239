import {createEntityAdapter, EntityAdapter, EntityState, Update} from '@ngrx/entity';
import {
  ValuationDataActions, ValuationDataActionTypes
} from '../_actions/valuation-data.actions';
import {ValuationDataModel} from '../';

export interface ValuationDataState extends EntityState<ValuationDataModel> {
    lastCreatedID: number,
    hasChanged: boolean,
    message: string,
    actionLoading: boolean,
    lastCurrencyConvertedComID: number,
}

export const adapter: EntityAdapter<ValuationDataModel> = createEntityAdapter();
export const initialValuationDataState: ValuationDataState = adapter.getInitialState({
    lastCreatedID: 0,
    hasChanged: false,
    message: '',
    actionLoading: false,
    lastCurrencyConvertedComID: undefined,
});

export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();

export function valuationDataReducer(state = initialValuationDataState,
                                     action: ValuationDataActions): ValuationDataState {
    switch (action.type) {
        case ValuationDataActionTypes.ValuationDataLoaded: {
            adapter.removeAll(initialValuationDataState);
            return adapter.addOne(
                action.payload.item,
                {...state, lastCreatedID: 1, hasChanged: false, lastCurrencyConvertedComID: undefined}
            )
        }
        case ValuationDataActionTypes.ValuationDataUpdated: {
            return adapter.updateOne(action.payload.item, {...state, hasChanged: true, lastCurrencyConvertedComID: undefined});
        }

        case ValuationDataActionTypes.StoredValuationData: {
            return {
                ...state,
                hasChanged: false,
                message: action.payload.message,
                actionLoading: false,
                lastCurrencyConvertedComID: undefined,
            };
        }
        case ValuationDataActionTypes.RestartValuationDataState: {
            return initialValuationDataState;
        }
        case ValuationDataActionTypes.ValuationDataStoreActionLoading: {
            return {...state, actionLoading: true}
        }
        case ValuationDataActionTypes.ValuationDataStore: {
          return {...state, hasChanged: true}
        }
        case ValuationDataActionTypes.ChangeComparableTenure: {
            const valData = state.entities[1];
            const comparables = Object.assign([], valData.comparables);
            const comIndx = comparables.findIndex(com => com.id == action.payload.comId)
            if (comIndx === -1) {
                return state;
            }
            const com = Object.assign([], comparables[comIndx]);
            let tenures = Object.assign([], com.tenures);

            if (action.payload.sameCurrency) {
              if (tenures.length === 2) {
                const newTenures = [];
                tenures.forEach((t) => {
                  const tenure = Object.assign({}, t);
                  const conversions = Object.assign([], tenure.conversion);
                  if (action.payload.currencyExchange.id) {
                    const indx = conversions.findIndex(
                      (c) => c.id === action.payload.currencyExchange.id
                    );
                    if (indx == -1) {
                      return state;
                    }
                    const newEx = Object.assign({}, conversions[indx]);
                    newEx.exchange_rate =
                      action.payload.currencyExchange.exchange_rate;

                    conversions[indx] = newEx;
                  } else {
                    const indx = conversions.findIndex(
                      (c) =>
                        c.quote_currency ===
                        action.payload.currencyExchange.quote_currency
                    );
                    if (indx == -1) {
                      const newEx = {
                        quote_currency:
                          action.payload.currencyExchange.quote_currency,
                        exchange_rate:
                          action.payload.currencyExchange.exchange_rate,
                      };
                      conversions.push(newEx);
                    } else {
                      const newEx = Object.assign({}, conversions[indx]);
                      newEx.exchange_rate =
                        action.payload.currencyExchange.exchange_rate;
                      conversions[indx] = newEx;
                    }
                  }
                  tenure.conversion = conversions;
                  newTenures.push(tenure);
                });
                tenures = newTenures
              }
            } else {
              const tenureInd = tenures.findIndex(
                (t) => t.id === action.payload.tenureId
              );
              if (tenureInd === -1) {
                return state;
              }
              const tenure = Object.assign({}, tenures[tenureInd]);
              const conversions = Object.assign([], tenure.conversion);
              if (action.payload.currencyExchange.id) {
                const indx = conversions.findIndex(
                  (c) => c.id === action.payload.currencyExchange.id
                );
                if (indx == -1) {
                  return state;
                }
                const newEx = Object.assign({}, conversions[indx]);
                newEx.exchange_rate =
                  action.payload.currencyExchange.exchange_rate;

                conversions[indx] = newEx;
              } else {
                const indx = conversions.findIndex(
                  (c) =>
                    c.quote_currency ===
                    action.payload.currencyExchange.quote_currency
                );
                if (indx == -1) {
                  const newEx = {
                    quote_currency:
                      action.payload.currencyExchange.quote_currency,
                    exchange_rate:
                      action.payload.currencyExchange.exchange_rate,
                  };
                  conversions.push(newEx);
                } else {
                  const newEx = Object.assign({}, conversions[indx]);
                  newEx.exchange_rate =
                    action.payload.currencyExchange.exchange_rate;
                  conversions[indx] = newEx;
                }
              }
              tenure.conversion = conversions;
              tenures[tenureInd] = tenure;
            }

            com.tenures = tenures;
            comparables[comIndx] = com;
            const upValData: Update<ValuationDataModel> = {
                id: valData.id,
                changes: {
                    comparables: comparables
                }
            }
            return adapter.updateOne(upValData, {...state, lastCurrencyConvertedComID: com.id});
        }
        case ValuationDataActionTypes.ChangeComparableConsideration: {
          const valData = state.entities[1];
          const oldTenures = Object.assign([], valData.tenures);
          const index = oldTenures.findIndex(t => t.comId == action.payload.comId);
          if (index >= 0) {
            oldTenures.splice(index, 1);
            oldTenures.push({comId: action.payload.comId, selectedTenure: action.payload.tenureId});
          }
          const upValData: Update<ValuationDataModel> = {
            id: valData.id,
            changes: {
              tenures: oldTenures
            }
          }
          return adapter.updateOne(upValData, {...state});
        }
        default:
            return state
    }
}