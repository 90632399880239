// Angular
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BuildingModel, AssetClassResidentialsService} from '../../../../../core/comparable';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { MapsService } from 'src/app/core/map/maps.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'kt-building-info-dialog',
    templateUrl: './building-info-dialog.component.html',
    styleUrls: ['./building-info-dialog.component.scss']
})
export class BuildingInfoDialogComponent implements OnInit {

    buildingInformations: BuildingModel[];
    filteredBuildings: Array<any & {total_floors: number}> = [];

    lat: number = 0;
    lng: number = 0;
    currentBuildingId = 0;


    private _centerLat$ = new BehaviorSubject<number>(null)
    centerLat$ = combineLatest([
        this._centerLat$,
        this.mapsService.latitude$
    ]).pipe(map(([initial, def]) => {
        if (initial == null) {
            return def
        }
        return initial
    }))
    private _centerLng$ = new BehaviorSubject<number>(null)
    centerLng$ = combineLatest([
        this._centerLng$,
        this.mapsService.longitude$
    ]).pipe(map(([initial, def]) => {
        if (initial == null) {
            return def
        }
        return initial
    }))

    selectedId: number;
    previous;

    icon = {
        labelOrigin: {x: 18, y: 22},
        url: './assets/media/icons/pin_blue.svg',
        scaledSize: {
            width: 44,
            height: 44
        }
    };

    basePath: string = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=`;
    awConst = awConst;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<FetchEntityDialogComponent>,
     * @param data
     * @param assetClassResidentialsService: assetClassResidentialsService
     */
    constructor(public dialogRef: MatDialogRef<BuildingInfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public assetClassResidentialsService: AssetClassResidentialsService,
                private translate: TranslateService,
                private subDomainService: SubdomainService,
                private mapsService: MapsService) {

        if (data.lat && data.lng) {
            this.lat = parseFloat(data.lat);
            this.lng = parseFloat(data.lng);
            this.currentBuildingId = data.currentBuildingId;

            this._centerLat$.next(this.lat);
            this._centerLng$.next(this.lng);
        }
    }

    ngOnInit(): void {
        this.assetClassResidentialsService.getBuildingInfos().subscribe(res => {
            this.buildingInformations = res.data;
            this.filteredBuildings = this.buildingInformations.map(b => ({...b, total_floors: b.internalAspectData ? (Number(b.internalAspectData.above_floors) + Number(b.internalAspectData.below_floors)) : null}))
            // this.calculateDistance();
        });
    }

    /**
     * Close dialog with false result
     */
    calculateDistance(): void {

        if (this.lat < 1) {
            this.filteredBuildings = this.buildingInformations;
            return;
        }

        const limit = 1; // 1 km

        const _filteredBuildings = [];

        this.buildingInformations.forEach((el) => {
            const dist = this.calcCrow(this.lat, this.lng, el.locationData.latitude, el.locationData.longitude);

            if (dist <= limit) {
                _filteredBuildings.push(el);
            }
        });

        this.filteredBuildings = _filteredBuildings;
    }

    // This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    calcCrow(_lat1, _lon1, _lat2, _lon2) {
        let R = 6371; // km
        let dLat = this.toRad(_lat2 - _lat1);
        let dLon = this.toRad(_lon2 - _lon1);
        let lat1 = this.toRad(_lat1);
        let lat2 = this.toRad(_lat2);

        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;
        return d;
    }

    // Converts numeric degrees to radians
    toRad(Value) {
        return Value * Math.PI / 180;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    showInfo(mId, infowindow) {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
        infowindow.open();

        this.selectedId = mId;
    }

    mapClick() {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = null;
    }

    clickItem(item: BuildingModel): void {
        if (this.data.standAloneBuilding) {
            this.dialogRef.close(item);
        } else {
            this.dialogRef.close(item);
        }
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND
    }
}
