<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h4 class="card-label">{{title}}</h4>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <form [formGroup]="formGroup" *ngIf="formGroup">
                <div class="form-group row">
                    <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="targetProperty" placeholder="Target Property">
                                <mat-option *ngFor="let item of tpSelections" [value]="item.id">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Target Property is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="interactionType" placeholder="Interaction Type">
                                <mat-option *ngFor="let item of interactionTypeSelections" [value]="item.id">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Interaction Type is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <span (click)="date.open()">
                                <input matInput readonly placeholder="Date" formControlName="date" [matDatepicker]="date" />
                            </span>
                            <mat-error>Date is <strong>required</strong></mat-error>
                            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                            <button mat-button mat-icon-button matSuffix *ngIf="formGroup.controls.date.value" matTooltip="Clear"
                                (click)="clearDate(formGroup.controls.date)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-datepicker #date></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="user" placeholder="Assignee">
                                <mat-option *ngFor="let item of workerSelections" [value]="item.id">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Assignee is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="form-group row">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput rows="4" placeholder="Notes" formControlName="notes"></textarea>
                            <mat-error>
                                Notes <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="close()">
                            Close
                        </button>
                        <button mat-button color="primary" (click)="save()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>