// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {FloorNumberingSchemeActions, FloorNumberingSchemeActionTypes} from '../_actions/floor-numbering-scheme.actions';
// Models
import {FloorNumberingScheme} from '../_models/floor-numbering-scheme.model';
import {QueryParamsModel} from '../../_base/crud';

export interface FloorNumberingSchemesState extends EntityState<FloorNumberingScheme> {
    isAllFloorNumberingSchemesLoaded: boolean;
    queryRowsCount: number;
    queryResult: FloorNumberingScheme[];
    lastCreatedFloorNumberingSchemeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<FloorNumberingScheme> = createEntityAdapter<FloorNumberingScheme>();

export const initialFloorNumberingSchemesState: FloorNumberingSchemesState = adapter.getInitialState({
    isAllFloorNumberingSchemesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedFloorNumberingSchemeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function floorNumberingSchemesReducer(state = initialFloorNumberingSchemesState, action: FloorNumberingSchemeActions): FloorNumberingSchemesState {
    switch (action.type) {
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedFloorNumberingSchemeId: undefined
            };
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemeOnServerCreated:
            return {
                ...state
            };
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemeCreated:
            return adapter.addOne(action.payload.FloorNumberingScheme, {
                ...state, lastCreatedFloorNumberingSchemeId: action.payload.FloorNumberingScheme.id
            });
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemeUpdated:
            return adapter.updateOne(action.payload.partialFloorNumberingScheme, state);
        case FloorNumberingSchemeActionTypes.AllFloorNumberingSchemesLoaded:
            return adapter.addAll(action.payload.FloorNumberingSchemes, {
                ...state, isAllFloorNumberingSchemesLoaded: true
            });
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case FloorNumberingSchemeActionTypes.FloorNumberingSchemesPageLoaded:
            return adapter.addMany(action.payload.FloorNumberingSchemes, {
                ...initialFloorNumberingSchemesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case FloorNumberingSchemeActionTypes.FloorNumberingSchemeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case FloorNumberingSchemeActionTypes.FloorNumberingSchemeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case FloorNumberingSchemeActionTypes.FloorNumberingSchemeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case FloorNumberingSchemeActionTypes.FloorNumberingSchemeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
