<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label text-uppercase">add existing criterion</h3>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
</div>

<div class="card-body">
    <div class="card card-custom">
        <div class="card-body criterion-body">
            <div class="col-md-12 mat-table-wrapper">
                <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource">
                    <!-- Headers -->
                    <ng-container matColumnDef="input-header">
                        <th mat-header-cell 
                            *matHeaderCellDef 
                            [attr.colspan]="2">Qualitative Inputs</th>
                    </ng-container>
            
                    <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            {{com.ref_num}}
                        </th>
                    </ng-container>
            
                    <ng-container [matColumnDef]="'tp-' + comparables.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            Target Property
                        </th>
                    </ng-container>
            
                    <ng-container matColumnDef="action-header">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                    </ng-container>
            
                    <!-- Cells -->
                    <ng-container matColumnDef="criterion">
                        <td mat-cell *matCellDef="let criterion">
                            {{criterion.name ? criterion.name : 'Not defined'}}
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="category">
                        <td mat-cell *matCellDef="let criterion">{{criterion.categoryName}}</td>
                    </ng-container>
            
                    <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length">
                        <td mat-cell *matCellDef="let criterion"
                            [ngClass]="{
                                'bg-red': criterion.comValues[com.id] === 'Unknown',
                                'bg-green': criterion.comValues[com.id] !== null && criterion.comValues[com.id] != 'Unknown'
                            }">
                            {{criterion.comValues[com.id] !== null ? criterion.comValues[com.id] : 'Not defined'}}
                        </td>
                    </ng-container>
            
                    <ng-container [matColumnDef]="'tp-' + comparables.length">
                        <td mat-cell *matCellDef="let criterion"
                        [ngClass]="{
                            'bg-red': criterion.tpValue === 'Unknown',
                            'bg-green': criterion.tpValue !== null && criterion.tpValue != 'Unknown'
                        }">
                            {{criterion.tpValue !== null ? criterion.tpValue : 'Not defined'}}
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="actions">
                        <td mat-cell *matCellDef="let criterion">
                            <button *ngIf="!criterion.active" mat-icon-button color="primary" (click)="setCriterionToActive(criterion)">
                                <mat-icon>add_circle</mat-icon>
                            </button>
                            <button *ngIf="criterion.active" [disabled]="!criterion.canRemove" mat-icon-button color="warn" (click)="setCriterionToInactive(criterion)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
                        [ngClass]="{'colored-row': i%2}"></tr>
                </table>
            </div>
        </div>
    </div>
    
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h3 class="my-3 text-uppercase">add existing criterion</h3>
    <hr class="active">
</div> -->

