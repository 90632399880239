// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
import {of, forkJoin} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {UserService} from '../_services';
// State
import {AppState} from '../../reducers';
import {
    UserActionTypes,
    UsersPageRequested,
    UsersPageLoaded,
    UserCreated,
    UserDeleted,
    UserUpdated,
    UserStatusUpdated,
    UserOnServerCreated,
    UsersActionToggleLoading,
    UserAdminRestored, UserDeletedFromAdminTrash, UserDeletedFromTrash, UserOnServerAdminRestored,
    UserOnServerRestored,
    UserRestored,
    UserTrashFlushed,
    UsersPageToggleLoading, AllUsersOfRoleLoaded, AllUsersOfRoleRequested
} from '../_actions/user.actions';

@Injectable()
export class UserEffects {
    showPageLoadingDispatcher = new UsersPageToggleLoading({isLoading: true});
    hidePageLoadingDispatcher = new UsersPageToggleLoading({isLoading: false});

    showActionLoadingDispatcher = new UsersActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new UsersActionToggleLoading({isLoading: false});

    @Effect()
    loadUsersPage$ = this.actions$
        .pipe(
            ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findUsers(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new UsersPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    loadUsersOfRole$ = this.actions$
        .pipe(
            ofType<AllUsersOfRoleRequested>(UserActionTypes.AllUsersOfRoleRequested),
            mergeMap(({payload}) => {
                return this.service.getAllUsers(payload.roleId);
            }),
            map(res => {
                return new AllUsersOfRoleLoaded({
                    users: res.data
                });
            }),
        );

    @Effect()
    deleteUser$ = this.actions$
        .pipe(
            ofType<UserDeleted>(UserActionTypes.UserDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteUser(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateUser$ = this.actions$
        .pipe(
            ofType<UserUpdated>(UserActionTypes.UserUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateUser(payload.user);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    createUser$ = this.actions$
        .pipe(
            ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createUser(payload.user).pipe(
                    tap(res => {
                        this.store.dispatch(new UserCreated({user: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushUserTrash$ = this.actions$
        .pipe(
            ofType<UserTrashFlushed>(UserActionTypes.UserTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    restoreUser$ = this.actions$
        .pipe(
            ofType<UserOnServerRestored>(UserActionTypes.UserOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UserRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminUser$ = this.actions$
        .pipe(
            ofType<UserOnServerAdminRestored>(UserActionTypes.UserOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UserAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteFromTrashUser$ = this.actions$
        .pipe(
            ofType<UserDeletedFromTrash>(UserActionTypes.UserDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashUser$ = this.actions$
        .pipe(
            ofType<UserDeletedFromAdminTrash>(UserActionTypes.UserDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateUserStatus$ = this.actions$
        .pipe(
            ofType<UserStatusUpdated>(UserActionTypes.UserStatusUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateUserStatus({
                    user_id: payload.user.changes.id,
                    is_active: payload.user.changes.is_active
                });
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    constructor(private actions$: Actions, private service: UserService, private store: Store<AppState>) {
    }
}
