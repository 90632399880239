import { ThisReceiver } from '@angular/compiler';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResidentialAdjustmentModel, selectAllResidentialAdjustments } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ValuationAdjustmentModel } from 'src/app/core/valuation/_models/valuation-adjustment.model';

@Component({
  selector: 'kt-adjustment-add-modal',
  templateUrl: './adjustment-add-modal.component.html',
  styleUrls: ['./adjustment-add-modal.component.scss']
})
export class AdjustmentAddModalComponent implements OnInit, OnDestroy {
  fg: UntypedFormGroup;
  adjustments: Array<any & {selected: boolean}> = [];
  selectedAdjustments: any[] = [];
  showOthers: boolean = false;
  comparables: any[];
  alreadySelectedIds: number[];
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AdjustmentAddModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store$: Store<AppState>
  ) { 
    this.comparables = data.comparables;
    this.alreadySelectedIds = data.alreadySelectedIds ? data.alreadySelectedIds : [];
  }

  ngOnInit(): void {
    const others = new ResidentialAdjustmentModel();
    others.clear();
    others.id = 0;
    others.name = 'Others';
    this.store$.pipe(
      select(selectAllResidentialAdjustments),
      takeUntil(this._onDestroy$)
    ).subscribe(res => { 
      let _adjustments = [{...others, selected: false}]
      if (res) {
        _adjustments = [...(res.map(item => ({...item, selected: false}))), {...others, selected: false}]
      }
      this.adjustments = _adjustments.map(item => {
        if (this.alreadySelectedIds.includes(item.id)) {
          return {...item, selected: true}
        }
        return item
      })
    })

    this.fg = this.formBuilder.group({
      name: new UntypedFormControl(''),
      desc: new UntypedFormControl('')
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  changeCheckBox(event: MatCheckboxChange, item: ResidentialAdjustmentModel) {
    if (event.checked) {
      this.selectedAdjustments.push(item);
    } else {
      this.selectedAdjustments = this.selectedAdjustments.filter(el => el.id != item.id);
    }

    if (this.selectedAdjustments.find(el => el.id == 0)) {
      this.showOthers = true;
      this.fg.get('name').setValidators([Validators.required])
      this.fg.get('desc').setValidators([Validators.required]);
      this.fg.get('name').updateValueAndValidity();
      this.fg.get('desc').updateValueAndValidity();
    } else {
      this.showOthers = false;
      this.fg.get('name').clearValidators();
      this.fg.get('desc').clearValidators();
      this.fg.get('name').updateValueAndValidity();
      this.fg.get('desc').updateValueAndValidity();
    }
  }

  onClose() {
    this.dialogRef.close()
  }

  onSubmit() {
    const controls = this.fg.controls;
    if (this.fg.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
      );
      return;
    }

    const response: ValuationAdjustmentModel[] = this.selectedAdjustments.map(ad => {
      const item = new ValuationAdjustmentModel();
      item.clear();
      item.adjustment_id = ad.id;
      item.name = ad.id == 0 ? controls.name.value : ad.name;
      item.desc = ad.id == 0 ? controls.desc.value : ad.description;
      const _tmp: {[id: number]: number} = {};
      this.comparables.forEach((com) => {
        _tmp[com.id] = 0;
      });
      item.adjValues = _tmp;

      return item;
    });

    this.dialogRef.close(response);
  }

}
