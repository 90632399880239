// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ValuerQualificationActions, ValuerQualificationActionTypes} from '../_actions/valuer-qualification.actions';
// Models
import {ValuerQualification} from '../_models/valuer-qualification.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ValuerQualificationsState extends EntityState<ValuerQualification> {
    isAllValuerQualificationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ValuerQualification[];
    lastCreatedValuerQualificationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ValuerQualification> = createEntityAdapter<ValuerQualification>();

export const initialValuerQualificationState: ValuerQualificationsState = adapter.getInitialState({
    isAllValuerQualificationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedValuerQualificationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function valuerQualificationsReducer(state = initialValuerQualificationState, action: ValuerQualificationActions): ValuerQualificationsState {
    switch (action.type) {
        case ValuerQualificationActionTypes.ValuerQualificationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedValuerQualificationId: undefined
            };
        case ValuerQualificationActionTypes.ValuerQualificationActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ValuerQualificationActionTypes.ValuerQualificationOnServerCreated:
            return {
                ...state
            };
        case ValuerQualificationActionTypes.ValuerQualificationCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedValuerQualificationId: action.payload.item.id
            });
        case ValuerQualificationActionTypes.ValuerQualificationUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ValuerQualificationActionTypes.AllValuerQualificationsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllValuerQualificationsLoaded: true
            });
        case ValuerQualificationActionTypes.ValuerQualificationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ValuerQualificationActionTypes.ValuerQualificationsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialValuerQualificationState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ValuerQualificationActionTypes.ValuerQualificationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ValuerQualificationActionTypes.ValuerQualificationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ValuerQualificationActionTypes.ValuerQualificationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ValuerQualificationActionTypes.ValuerQualificationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ValuerQualificationActionTypes.ValuerQualificationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ValuerQualificationActionTypes.ValuerQualificationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
