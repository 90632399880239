<kt-portlet>
    <kt-portlet-header
            [title]="getComponentTitle()"
            [class]="'kt-portlet-head--lg'"
            [tooltipTitle]="'TOOLTIP.APPENDIX.CATEGORY_FORM'+(appendixCategory && appendixCategory.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.APPENDIX.CATEGORY_FORM'+(appendixCategory && appendixCategory.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a 
                (click)="back()"
                class="btn btn-secondary mr-2" mat-raised-button
                [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                    back_page: ('APPENDIX_CATEGORY.LIST.TITLE' | translate)
                }">
                <i class="la la-arrow-left"></i>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.CLOSE' | translate }}</span>
            </a>
            <a href="javascript:;" class="btn btn-success mr-2" color="submit"
               (click)="onSubmit(false)"
               [disabled]="!isFormValid()"

               mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.SAVE' | translate">
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
            </a>
            <!--</mat-menu>-->
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="kt-form" [formGroup]="appendixCategoryForm">
            <div class="kt-portlet-body">

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput [placeholder]="'APPENDIX_CATEGORY.FORM.FIELD.GENERAL.APPENDIX_CATEGORY.PLACEHOLDER' | translate" formControlName="name"
                               [(ngModel)]="appendixCategory.name"
                        />
                        <mat-error
                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APPENDIX_CATEGORY.FORM.FIELD.GENERAL.APPENDIX_CATEGORY.NAME' | translate)
                            }"
                        >
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </kt-portlet-body>
</kt-portlet>

