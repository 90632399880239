import { VApartmentModel, VCApartmentModel } from "./valuation-apartment.model"
import { VCHouseModel, VHouseModel } from "./valuation-house.model"
import { VCOfficeModel, VOfficeModel } from "./valuation-office.model"
import { VCParkingModel, VParkingModel } from "./valuation-parking.model"
import { PictureModel, PropertySubTypes } from "./valuation-process-target-property.model"
import { VCRetailBuildingModel, VRetailBuildingModel } from "./valuation-retail-building.model"
import { VCRetailShopModel, VRetailShopModel } from "./valuation-retail-shop.model"
import { VCWarehouseModel, VWarehouseModel } from "./valuation-warehouse.model"
import { VCLandModel } from './valuation-land.model'

export type ValuationComparableMapData = {
    id: number
    refNum: string
    propertySubType: PropertySubTypes
    latitude: number
    longitude: number
}

export type VComparable
    = VCApartmentModel
    | VCHouseModel
    | VCOfficeModel
    | VCRetailShopModel
    | VCRetailBuildingModel
    | VCWarehouseModel
    | VCParkingModel
    | VCLandModel

export function isApartmentComparable(item: VApartmentModel): item is VCApartmentModel {
    return (item as VCApartmentModel).considerations !== undefined
}

export function isHouseComparable(item: VHouseModel): item is VCHouseModel {
    return (item as VCHouseModel).considerations !== undefined
}

export function isOfficeComparable(item: VOfficeModel): item is VCOfficeModel {
    return (item as VCOfficeModel).considerations !== undefined
}

export function isRetailShopComparable(item: VRetailShopModel): item is VCRetailShopModel {
    return (item as VCRetailShopModel).considerations !== undefined
}

export function isRetailBuildingComparable(item: VRetailBuildingModel): item is VCRetailBuildingModel {
    return (item as VCRetailBuildingModel).considerations !== undefined
}

export function isWarehouseComparable(item: VWarehouseModel): item is VCWarehouseModel {
    return (item as VCWarehouseModel).considerations !== undefined
}

export function isParkingComparable(item: VParkingModel): item is VCParkingModel {
    return (item as VCParkingModel).considerations !== undefined
}