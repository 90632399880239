// NGRX
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
// Actions
import {AppendixActions, AppendixActionTypes} from '../_actions/appendix.actions';
// Models
import {Appendix} from '../_models/appendix.model';

export interface AppendicesState extends EntityState<Appendix> {
    isAllAppendicesLoaded: boolean;
    queryRowsCount: number;
    queryResult: Appendix[];
    lastCreatedAppendixId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Appendix> = createEntityAdapter<Appendix>();

export const initialAppendicesState: AppendicesState = adapter.getInitialState({
    isAllAppendicesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAppendixId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function appendicesReducer(state = initialAppendicesState, action: AppendixActions): AppendicesState {
    switch (action.type) {
        case AppendixActionTypes.AppendicesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAppendixId: undefined
            };
        case AppendixActionTypes.AppendicesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AppendixActionTypes.AppendixOnServerCreated:
            return {
                ...state
            };
        case AppendixActionTypes.AppendicesClear:
            return adapter.removeAll(initialAppendicesState);
        case AppendixActionTypes.AppendixCreated:
            return adapter.addOne(action.payload.appendix, {
                ...state, lastCreatedAppendixId: action.payload.appendix.id
            });
        case AppendixActionTypes.AppendixUpdated:
            return adapter.updateOne(action.payload.partialappendix, state);

        case AppendixActionTypes.AllAppendicesLoaded:
            return adapter.addAll(action.payload.appendices, {
                ...state, isAllAppendicesLoaded: true
            });
        case AppendixActionTypes.AppendicesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case AppendixActionTypes.AppendicesPageLoaded:
            return adapter.addMany(action.payload.appendices, {
                ...initialAppendicesState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.appendices,
                lastQuery: action.payload.page,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                showInitWaitingMessage: false
            });

        case AppendixActionTypes.AppendixDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AppendixActionTypes.AppendiceRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case AppendixActionTypes.AppendiceDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case AppendixActionTypes.AppendiceAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AppendixActionTypes.AppendiceDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AppendixActionTypes.AppendiceTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
