import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FilterService } from './filter.service';

export interface FilterConfig {
  search: {
    main: boolean,
    location: boolean,
    reset: boolean,
  }
}

@Component({
  selector: 'kt-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [FilterService]
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() config: FilterConfig
  @Output() filterChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchInput', {static: false}) search: ElementRef;
  private searchValue: string = '';
  @ViewChild('searchLocationInput', {static: false}) searchLocation: ElementRef;
  private searchLocationValue: string = '';

  private _placeChangeEventListener: google.maps.MapsEventListener;
  private _onDestroy$: Subject<void> = new Subject();
  private _filter: any = {};
  constructor(
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.filterService.filter$.subscribe(f => {
      this._filter = f;
      this._emitFilter();
    })
  }

  ngAfterViewInit(): void {
    // Search Input
    if (this.search) {
      fromEvent(this.search.nativeElement, 'keyup').pipe(
        debounceTime(50),
        distinctUntilChanged(),
        tap(() => {
          this.searchValue = this.search.nativeElement.value;
          this._emitFilter()
        }),
        takeUntil(this._onDestroy$)
      ).subscribe();
    }

    // Search Location
    if (this.searchLocation) {
      const autocomplete = new google.maps.places.Autocomplete(this.searchLocation.nativeElement, {
        types: ['(cities)']
      });
      this._placeChangeEventListener = autocomplete.addListener('place_changed', () => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry == null) {
          this.searchLocation.nativeElement.value = '';
          this.searchLocationValue = this.searchLocation.nativeElement.value;
          this._emitFilter();
          return;
        }
        if (place.formatted_address === 'Ulaanbaatar, Mongolia') {

        } else {

        }
        this.searchLocationValue = this.searchLocation.nativeElement.value;
        this._emitFilter();
      });
    }
  }

  ngOnDestroy(): void {
    if (this._placeChangeEventListener) {
      this._placeChangeEventListener.remove();
    }
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  resetFilter(): void {
    if (this.search) {
      this.search.nativeElement.value = '';
      this.searchValue = '';
    }
    if (this.searchLocation) {
      this.searchLocation.nativeElement.value = '';
      this.searchLocationValue = '';
    }
    this.filterService.reset();
  }

  private _emitFilter() {
    const filter = {};
    if (this.config.search.main && this.searchValue != null && this.searchValue != '') {
      filter['q_name'] = this.searchValue;
    }
    if (this.config.search.location && this.searchLocationValue != null && this.searchLocationValue != '') {
      filter['city_name'] = this.searchLocationValue ;
    }

    Object.keys(this._filter).forEach(key => {
      filter[key] = this._filter[key];
    })

    this.filterChange.emit(filter);
  }
}
