// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassOfficeFacilitiesService} from '../_services/asset-class-office-facility.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AllAssetClassOfficeFacilitiesRequested,
    AllAssetClassOfficeFacilitiesLoaded,
    AssetClassOfficeFacilityActionToggleLoading,
    AssetClassOfficeFacilityActionTypes
} from '../_actions/asset-class-office-facility.actions';

@Injectable()
export class AssetClassOfficeFacilityEffects {
    showActionLoadingDistpatcher = new AssetClassOfficeFacilityActionToggleLoading({isLoading: true});
    hideActionLoadingDistpatcher = new AssetClassOfficeFacilityActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOfficeTypes$ = this.actions$
        .pipe(
            ofType<AllAssetClassOfficeFacilitiesRequested>(AssetClassOfficeFacilityActionTypes.AllAssetClassOfficeFacilitiesRequested),
            mergeMap(({payload}) => this.assetClassOfficesService.getAllAssetClassOfficeFacilities(payload.assetClassOfficeId)),
            map(res => {
                return new AllAssetClassOfficeFacilitiesLoaded({assetClassOfficeFacilities: res.data});
            })
        );

    constructor(private actions$: Actions, private assetClassOfficesService: AssetClassOfficeFacilitiesService, private store: Store<AppState>) {
    }
}
