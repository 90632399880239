import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AdditionalCostTitleModel} from '../_models/additional-cost-title.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';
import { map } from 'rxjs/operators';


@Injectable()
export class AdditionalCostTitleService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/additional-cost-titles`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // AdditionalCostTitles
    getAll(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }


    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(AdditionalCostTitleId: number): Observable<AdditionalCostTitleModel> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<AdditionalCostTitleModel>(this.API_URL + `/${AdditionalCostTitleId}`, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new AdditionalCostTitle to the server
    create(_item: AdditionalCostTitleModel): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();

        return this.http.post(this.API_URL, _item, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the AdditionalCostTitle on the server
    update(AdditionalCostTitle: AdditionalCostTitleModel): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${AdditionalCostTitle.id}`, AdditionalCostTitle, {headers: httpHeaders});
    }

    // DELETE => delete the AdditionalCostTitle from the server
    delete(AdditionalCostTitleId: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_URL}/${AdditionalCostTitleId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }

    deleteFromTrash(_id: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${_id}`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(_id: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: httpHeaders});
    }

    fetchListing() {
        return this.http.get<{data: {id: number, name: string}[]}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/additional-cost-titles/listing`,
            {
                headers: this.authService.getAuthHeaders()
            }
        ).pipe(
            map(response => response.data)
        )
    }


    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
