import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetClassDetailModel } from 'src/app/core/asset_class';
import { FloorRateModel } from 'src/app/core/asset_class/_models/floor-rate.model';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type ModuleData = {
  id: number;
  slideId: string;
  type: string;
  orderNum: number;
  name: string;
  url: string;
  description?: string;
}

@Component({
  selector: 'kt-pictures-module',
  templateUrl: './pictures-module.component.html',
  styleUrls: ['./pictures-module.component.scss']
})
export class PicturesModuleComponent implements OnInit {
  @Input()
  actionTemplate: TemplateRef<any>;
  @Input() tpDetails: AssetClassDetailModel;
  @Input() changeSlide$: Observable<string>;
  @ViewChild(NgbCarousel) ngbCarousel: NgbCarousel;
  data$: Observable<ModuleData[]>
  ratingList: FloorRateModel[] = [];
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  awConst = awConst;
  constructor(
    private activatedRoute: ActivatedRoute,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    if (this.changeSlide$) {
      this.changeSlide$.subscribe(val => {
        if (val) {
          this.ngbCarousel.select(val);
        }
      })
    }

    this.data$ = combineLatest([
      of(this.tpDetails.ac_pictures),
      of(this.tpDetails.building_information.files)
    ]).pipe(map(([acPictures, buildingPictures]) => {
      const newAcPictures: ModuleData[] = acPictures
        .filter(img => !img.hasOwnProperty('rating_desc') || img.rating_desc == '')
        .map((img, i) => {
          const pData: ModuleData = {
            id: img.id,
            slideId: `ac-${img.id}`,
            type: 'Property Picture',
            orderNum: i + 1,
            name: img.title ? img.title : 'N/A',
            url: this._getImageUrl(img)
          };
          return pData;
        });

      const ratingPictures: ModuleData[] = acPictures
        .filter(img => img.hasOwnProperty('rating_desc') && img.rating_desc != '')
        .map((img, i) => {
          const pData: ModuleData = {
            id: img.id,
            slideId: `ac-${img.id}`,
            type: 'Condition Rating Picture',
            orderNum: i + 1,
            name: img.title ? img.title : 'N/A',
            description: img.rating_desc,
            url: this._getImageUrl(img)
          };
          return pData;
        });

      const newBuildingPictures: ModuleData[] = buildingPictures.map((img, i) => {
        const pData: ModuleData = {
          id: img.id,
          slideId: `b-${img.id}`,
          type: 'Building Picture',
          orderNum: i + 1,
          name: img.title ? img.title : 'N/A',
          url: this._getImageUrl(img)
        };
        return pData;
      });
      return [...newAcPictures, ...newBuildingPictures];
    }));
  }

  _getImageUrl(img: any): string {
    let _picture = img.path + '/' + img.name;
    const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
    return imgURL;
  }
  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
