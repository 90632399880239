import { BaseTemplateDataModel } from ".";
import { SourceExternalReference } from "../../linked-tables";

export class ExternalRefTemplateModel implements BaseTemplateDataModel {
    id: number;
    name: string;
    description: string;

    source_external_references: IExternalRefSERTEmplate[];

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.name = null;
        this.description = null;
        this.source_external_references = [];
    }
}

export interface IExternalRefSERTEmplate {
    id: number;
    source_external_reference_id: number;
}