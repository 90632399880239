import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FloorRateModel } from 'src/app/core/asset_class/_models/floor-rate.model';
import { MSResidential, MSSizeInfo } from '../../shared_components/size-module/models/mongolian-standard-residential.model';
import { _MAT_INK_BAR_POSITIONER } from '@angular/material/tabs';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { AssetClassDetailModel, AssetClassModel } from 'src/app/core/asset_class';
import { SizeService } from '../../shared_components/size-module/size.service';
import { Rooms } from '../../shared_components/size-module/mongolia-standard-table/mongolia-standard-table.types';
import * as _ from 'lodash';

export interface ComponentDataItem {
  title: string;
  key: string;
  name: string;
  description?: string;
  index?: number;
}

export interface ConditionRatingDataItem {
  floor: number;
  ratings: Record<string, Rating>
}

export function ratingContainsKey(ratings: ConditionRatingDataItem[], key: string): boolean {
  if (ratings.length == 0) {
    return true;
  }
  if (ratings[0].ratings[key] == undefined) {
    return false;
  }
  return true;
}

export function insertRatingWithKey(ratings: ConditionRatingDataItem[], key: string): ConditionRatingDataItem[] {
  ratings.forEach(r => {
    r.ratings[key] = {
      value: 'N/A',
      description: null,
      pictures: []
    }
  });
  return ratings;
}

export interface Rating {
  value: string;
  description: string;
  pictures: any[]
}

interface ConditionRatingDropDownItem {
    key: number;
    value: string;
}

interface RatingModel {
  code: string,
  area: string,
  floor: number,
  rating: string,
  desc: string,
  pictures: any[]
}

function convertToRating(
  components: ComponentDataItem[], 
  ratings: ConditionRatingDataItem[]
): Array<RatingModel>  {
  return _.flatten(
      ratings
        .map(r => {
          return _.entries(r.ratings)
            .map(([key, value]) => {
              const component = components.find(c => c.key == key)
              return {
                floor: r.floor,
                code: component ? component.title : null,
                area: component ? component.name : null,
                rating: value.value,
                desc: value.description,
                pictures: value.pictures
              }
            })
        }) 
    );
}

function convertToConditionRating(
  items: Array<RatingModel>, 
  components: ComponentDataItem[]
): ConditionRatingDataItem[] {
  const temp = _.groupBy(items, (item) => item.floor);
  const res = _
    .entries(temp)
    .map(([key, value]) => {
      const rating: ConditionRatingDataItem = {
        floor: Number(key),
        ratings: {}
      }
      value
        .filter(v => v.code != null)
        .filter(v => components.find(c => c.title == v.code))
        .forEach(v => {
          const component = components.find(c => c.title == v.code);
          if (component)
          rating.ratings[component.key] = {
            value: v.rating,
            description: v.desc,
            pictures: v.pictures
          }
        }) 
      return rating;
    })
  return res;
}

@Injectable()
export class ConditionRatingV2Service implements OnDestroy {

  private _components$: BehaviorSubject<ComponentDataItem[]> = new BehaviorSubject([]);
  public components$ = this._components$.asObservable();
  private _ratings = [];
  private _ratings$: BehaviorSubject<ConditionRatingDataItem[]> = new BehaviorSubject([]);
  public ratings$ = this._ratings$.asObservable();
  private _schemeId$: BehaviorSubject<number> = new BehaviorSubject(null);
  public schemeId$ = this._schemeId$.asObservable();
  private components = [
    'a1_component', 'a2_component', 'b_component', 'b1_component', 'b2_component', 'b3_component', 
    'c_component', 'd_component', 'e_component', 'e1_component', 'e2_component', 'f_component',
    'g_component', 'h_component'
  ];

  public isAllNA$ = this.ratings$.pipe(
    map(ratings => {
      let _allNa = true 
      ratings.forEach(rating => {
        Object.values(rating.ratings).forEach(r => {
          if (r.value != 'N/A') {
            _allNa = false
          }
        })
      })
      return !_allNa
    })
  )

  private _conditionRatingDropDownValues$: ReplaySubject<ConditionRatingDropDownItem[]>;
  private _conditionRatingRelated$: Subject<{
      key: number,
      value: string,
      path: string,
      name: string,
      title: string,
      floor: number,
  }>

  private _onDestroy$: Subject<void> = new Subject();
  private _componentLoaded$: Subject<void> = new Subject();

  constructor(
    private sizeService: SizeService
  ) {
    combineLatest([
      this._components$,
      this._ratings$,
      this._schemeId$
    ]).pipe(takeUntil(this._onDestroy$))
      .subscribe(([components, ratings, schemeId]) => {
        this._ratings = convertToRating(components, ratings);

        const dropDownValues: ConditionRatingDropDownItem[] = [
          {key: null, value: 'Not applicable'}
        ];
        ratings.sort((r1, r2) => r1.floor - r2.floor)
        ratings.forEach(rating => {
          components.forEach(component => {
            const r = rating.ratings[component.key]
            if (r && r.value != 'N/A' && r.value != 'N/I') {
              const floorVal = this._floorValue(rating.floor, schemeId);
              dropDownValues.push({
                key: Number(rating.floor),
                value: `${component.title} (${component.name}) - ${floorVal}`
              });
            }
          })
        })
        if (this._conditionRatingDropDownValues$) {
          this._conditionRatingDropDownValues$.next(dropDownValues);
        }
      })
  }

  private _floorValue(floor: number, schemeId: number): string {
    if (schemeId == 1) { // European
      if (floor < 0) {
        return `Lower Ground (${floor})`;
      } else if (floor == 0) {
        return 'Ground';
      } else if (floor > 0) {
        return `Floor ${floor}`
      }
    } else if (schemeId == 2)  { // North
      if (floor < 0) {
        return `Lower Ground (${floor})`;
      } else if (floor == 1) {
        return 'Floor 1 (Ground)'
      } else if (floor > 1) {
        return `Floor ${floor}`;
      }
    }
    return floor.toString();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  initializeDropdown() {
    this._conditionRatingRelated$ = new Subject();
    this._conditionRatingRelated$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        if (!res.value) {
          this._deleteFromRatings(res.path, res.name)
          return;
        }
        const code = res.value.split(' ')[0].trim();
        if (code === 'No') {
          this._deleteFromRatings(res.path, res.name);
          return;
        }
        const values = res.value.split(" ");
        const floorNumber = res.floor;
        this._setImageForRating(floorNumber, code, res.path, res.name, res.key, res.title);
      })
    this._conditionRatingDropDownValues$ = new ReplaySubject();
    return {dropdown: this._conditionRatingDropDownValues$, related: this._conditionRatingRelated$};
  }

  getRatings() {
    return this._ratings;
  }
  setConditionRatings(ratings: ConditionRatingDataItem[]) {
    this._ratings$.next(ratings);
  }
  setConditionRatingsFromRatingModels(items: any[]) {
    this._components$
      .pipe(
        takeUntil(this._componentLoaded$),
        filter(components => components.length != 0)
      )
      .subscribe(components => {
        const res = convertToConditionRating(items, this._components$.value);
        this._ratings$.next(res);
      })
  }

  public setComponentsAllBefore(
    smUtilityService: StandardMeasurementUtility,
    config: {
      measurementStandardId: number,
      acId: number,
      unitMeasurementId: number,
      externalAreas: any[],
      rooms: any
    }
  ) {
    if (smUtilityService.checkHasOwnTable(config.measurementStandardId)) {
      if (smUtilityService.isIPMS(config.measurementStandardId)) {
        const components = smUtilityService.getConfig(config.measurementStandardId, 'ipms');
        this.setComponents(
          components 
            .filter(c => c.key != 'total_component' && c.key != 'additional_component')
            .map(c => ({
              title: c.title,
              name: c.name,
              description: c.description,
              key: c.key
            }))
        );
        return;
      } else  {
        this.sizeService.getMSResidentialSizeInfo(
          config.acId, 
          config.measurementStandardId, 
          config.unitMeasurementId, 
          config.rooms,
          config.externalAreas.filter(id => Number(id) != 6),
          true
        ).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
          this.setMSComponents(res);
        })
      }
    } else {
      const components = smUtilityService.getComponents(config.measurementStandardId);
      this.setComponents(
        components 
          .filter(c => c.key != 'total_component' && c.key != 'additional_component')
          .map(c => ({
            title: c.title,
            name: c.name,
            description: c.description,
            key: c.key
          })))
    }
  }

  public setComponentsAll(smUtilityService: StandardMeasurementUtility, acDet: AssetClassDetailModel, ac: AssetClassModel) {
    if (smUtilityService.checkHasOwnTable(acDet.measurement_standard_id)) {
      if (smUtilityService.isIPMS(acDet.measurement_standard_id)) {
        const components = smUtilityService.getConfig(acDet.measurement_standard_id, 'ipms');
        this.setComponents(
          components 
            .filter(c => c.key != 'total_component' && c.key != 'additional_component')
            .map(c => ({
              title: c.title,
              name: c.name,
              description: c.description,
              key: c.key
            }))
        );
        return;
      } else  {
        // Mongolian Standard
        const rooms: Rooms = {
          bath: 0,
          kitchen: 0,
          bedrooms: 0,
          livingRooms: 0,
          toilet: 0
        }
        _.each(acDet.accommodation.data, (col) => {
          Object.entries(col).forEach(([key, value]) => {
            if (rooms.hasOwnProperty(key)) {
              rooms[key] += value != undefined ? Number(value) : 0; 
            }
          })
        })
        this.sizeService.getMSResidentialSizeInfo(
          ac.id, 
          acDet.measurement_standard_id, 
          acDet.unit_measurement_id, 
          rooms,
          acDet.grounds.external_areas.filter(id => Number(id) != 6),
          true
        ).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
          this.setMSComponents(res);
        })
      }
    } else {
      const components = smUtilityService.getComponents(acDet.measurement_standard_id);
      this.setComponents(
        components 
          .filter(c => c.key != 'total_component' && c.key != 'additional_component')
          .map(c => ({
            title: c.title,
            name: c.name,
            description: c.description,
            key: c.key
          })))
    }
  }

  setComponents(components: ComponentDataItem[]) {
    this._components$.next(components);
    let ratings = _.cloneDeep(this._ratings$.value);
    components.forEach(component => {
      if (!ratingContainsKey(ratings, component.key)) {
        ratings = insertRatingWithKey(ratings, component.key);
      }
    });
    this._ratings$.next(ratings);
    this._componentLoaded$.next();
    this._componentLoaded$.complete();
  }
  setMSComponents(sizeInfo: MSResidential) {
    let index = 0;
    const components: ComponentDataItem[] = [];
    sizeInfo.living_areas.forEach(la => {
      components.push({
        title: `S${index + 1}`,
        index: index,
        name: la.name,
        key: la.name.split('/')[0].toLowerCase().split(' ').join('_'),
      })
      index++;
    });
    sizeInfo.standard_facilities.forEach(la => {
      components.push({
        title: `S${index + 1}`,
        index: index,
        name: la.name,
        key: la.name.split('/')[0].toLowerCase().split(' ').join('_'),
      })
      index++;
    });
    sizeInfo.external_areas.forEach(la => {
      components.push({
        title: la.key ? la.key : 'N/A',
        index: index,
        name: la.name,
        key: la.name.split('/')[0].toLowerCase().split(' ').join('_')
      })
      index++;
    })
    _.sortBy(components, (c) => c.index);
    let ratings = _.cloneDeep(this._ratings$.value);
    components.forEach(component => {
      if (!ratingContainsKey(ratings, component.key)) {
        ratings = insertRatingWithKey(ratings, component.key);
      }
    });
    this._ratings$.next(ratings);
    this.components = _.concat(this.components, components.map(c => c.key));
    this._components$.next(components);
    this._componentLoaded$.next();
    this._componentLoaded$.complete();
  }

  floorChanged({floors, scheme_id}: {floors: number[], scheme_id: number}) {
    let ratings = _.cloneDeep(this._ratings$.value);
    const currentFloors = ratings.map(r => r.floor);
    const deleteFloors = _.difference(currentFloors, floors);
    const insertFloors = _.difference(floors, currentFloors);

    deleteFloors.forEach(floor => ratings = ratings.filter(r => r.floor != floor));
    insertFloors.forEach(floor => ratings.push({
      floor,
      ratings: this._generateDefaultRating()
    }));
    ratings.sort((a, b) => a.floor - b.floor);
    this._ratings$.next(ratings);
    this._schemeId$.next(scheme_id);
  }

  setSchemeId(schemId: number) {
    this._schemeId$.next(schemId);
  }

  private _generateDefaultRating(): Record<string, Rating> {
    const r: Record<string, Rating> = {};
    this.components.forEach((key) => {
      r[key] = {
        value: 'N/A',
        description: null,
        pictures: []
      }
    })
    return r
  }

  private _setImageForRating(floor: number, code: string, path: string, name: string, actionType: number, title: string) {
    const tempList: ConditionRatingDataItem[] = [];
    let ratings = _.cloneDeep(this._ratings$.value);
    const components = this._components$.value;
    const component = components.find(c => c.title.toLowerCase() == code.trim().toLowerCase());
    if (component == undefined) {
      return;
    }
    ratings.forEach(rate => {
      const ra: Record<string, Rating> = {};
      Object.entries(rate.ratings).forEach(([key, value]) => {
        let r = this._pictureExists(value, path, name);
        if (key == component.key && rate.floor == floor) {
          if (actionType === -1) {
            r = this._deletePictureFromRating(r, path, name);
          } else {
            const pic = {
              path: path,
              name: name,
              title: title
            };
            r.pictures.push(pic);
          }
        }
        ra[key] = r;
      })
      tempList.push({
        floor: rate.floor,
        ratings: ra
      })
    })
    this.setConditionRatings(tempList);
  }

  private _deleteFromRatings(path: string, name: string) {
    const tempList: ConditionRatingDataItem[] = [];
    let ratings = _.cloneDeep(this._ratings$.value);
    ratings.forEach(rate => {
      const ra: Record<string, Rating> = {};
      Object.entries(rate.ratings).forEach(([key, value]) => {
        const r = this._pictureExists(value, path, name);
        ra[key] = r;
      })
      tempList.push({
        floor:  rate.floor,
        ratings: ra
      })
    })
    this.setConditionRatings(tempList);
  }

  private _pictureExists(rate: Rating, path: string, name: string): Rating {
    if (rate.pictures.length > 0) {
      const picInd = rate.pictures.findIndex(pic => pic.name === name && pic.path === path )
      if (picInd > -1) {
        return this._deletePictureFromRating(rate, path, name);
      }
    }
    return rate;
  }

  private _deletePictureFromRating(rate: Rating, path: string, name: string): Rating {
    const ind = rate.pictures.findIndex(pic => pic.name === name && pic.path === path);
    if (ind > -1) {
      const pics = Object.assign([], rate.pictures);
      pics.splice(ind, 1);
      rate.pictures = pics;
    }
    return rate
  }
}