// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AppendixCategoryActions, AppendixCategoryActionTypes} from '../_actions/appendix-category.actions';
// Models
import {AppendixCategory} from '../_models/appendix-category.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AppendixCategoriesState extends EntityState<AppendixCategory> {
    isAllAppendixCategoriesLoaded: boolean;
    queryRowsCount: number;
    queryResult: AppendixCategory[];
    lastCreatedAppendixCategoryId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AppendixCategory> = createEntityAdapter<AppendixCategory>();

export const initialAppendixCategoriesState: AppendixCategoriesState = adapter.getInitialState({
    isAllAppendixCategoriesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAppendixCategoryId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function appendixCategoriesReducer(state = initialAppendixCategoriesState,
                                          action: AppendixCategoryActions): AppendixCategoriesState {
    switch (action.type) {
        case AppendixCategoryActionTypes.AppendixCategoriesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAppendixCategoryId: undefined
            };
        case AppendixCategoryActionTypes.AppendixCategoriesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AppendixCategoryActionTypes.AppendixCategoryOnServerCreated:
            return {
                ...state
            };
        case AppendixCategoryActionTypes.AppendixCategoryCreated:
            return adapter.addOne(action.payload.appendixCategory, {
                ...state, lastCreatedAppendixCategoryId: action.payload.appendixCategory.id
            });
        case AppendixCategoryActionTypes.AppendixCategoryUpdated:
            return adapter.updateOne(action.payload.partialappendixCategory, state);

        case AppendixCategoryActionTypes.AllAppendixCategoriesLoaded:
            return adapter.addAll(action.payload.appendixCategories, {
                ...state, isAllAppendixCategoriesLoaded: true
            });
        case AppendixCategoryActionTypes.AppendixCategoriesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case AppendixCategoryActionTypes.AppendixCategoriesPageLoaded:
            return adapter.addMany(action.payload.appendixCategories, {
                ...initialAppendixCategoriesState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.appendixCategories,
                lastQuery: action.payload.page,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                showInitWaitingMessage: false
            });
        case AppendixCategoryActionTypes.AppendixCategoryDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AppendixCategoryActionTypes.AppendixCategoryRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case AppendixCategoryActionTypes.AppendixCategoryDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case AppendixCategoryActionTypes.AppendixCategoryAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AppendixCategoryActionTypes.AppendixCategoryDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AppendixCategoryActionTypes.AppendixCategoryTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
