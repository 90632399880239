import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AssetClassServiceinfraModel } from 'src/app/core/asset_class';

@Component({
    selector: 'kt-services-infrastructures',
    templateUrl: './services-infrastructures.component.html',
    styleUrls: ['./services-infrastructures.component.scss'],
})
export class ServicesInfrastructuresComponent implements OnInit, OnDestroy {

    @Input() serviceInfrastructureSubject: BehaviorSubject<AssetClassServiceinfraModel>;
    @Input() selectFacilitiesSubject: BehaviorSubject<any[]>;

    item: any;
    tempFacilities: any[];
    form: UntypedFormGroup;
    hasFormErrors = false;

    decisionsExtra: any[] = [
        {label: 'Yes', value: 1, default: false},
        {label: 'No', value: 0, default: true},
        {label: 'I don’t know', value: 2, default: false}
    ];

    landParcelFacilities: any[] = [
        { name: 'Potable water supply', id: '1' },
        { name: 'Waste water/sewerage disposal', id: '2' },
        { name: 'Storm water drainage', id: '3' },
        { name: 'Electricity (main, solar, off-grid or independently generated)', id: '4' },
        { name: 'Gas', id: '5' },
        { name: 'Telephony/telecommunications', id: '6' },
        { name: 'Internet access', id: '7' },
        { name: 'Garbage disposal', id: '8' },
        { name: 'Postal service', id: '9' },
        { name: 'Transport (public or other facilities)', id: '10' },
        { name: 'Other services', id: '11' },
    ];

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.serviceInfrastructureSubject.asObservable().subscribe(res => {
            this.item = Object.assign({}, res);
        });
        this.selectFacilitiesSubject.asObservable().subscribe(res => {
            this.tempFacilities = Object.assign([], res);
        });

        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            road_access: [this.item.road_access],
            status_and_quality: [this.item.status_and_quality],
            access_points: [this.item.access_points],
            road_length: [this.item.road_length],
            owner_of_another_consent: [this.item.owner_of_another_consent],
            specify_other_service: [this.item.specify_other_service, Validators.required],
        });
        this.form.controls.road_access.valueChanges.pipe(
            startWith(this.form.controls.road_access.value)
        ).subscribe(val => {
            if (val == '1') {
                this.form.controls.status_and_quality.setValidators(Validators.required);
            } else {
                this.form.controls.status_and_quality.clearValidators();
            }
            this.form.controls.status_and_quality.updateValueAndValidity();
        })
    }

    setSelectedFacilities(item): boolean {
        if (this.tempFacilities.length <= 0) {
            return false;
        } else {
            return this.tempFacilities.some(element => element === item.id);
        }
    }

    updateCheckedOptions(item, $event) {
        if ($event.checked) {
            this.tempFacilities = [...this.tempFacilities, item.id];
        } else {
            const index = this.tempFacilities.findIndex(element => element === item.id);
            if (index !== -1) {
                this.tempFacilities.splice(index, 1);
            }
        }
        this.selectFacilitiesSubject.next(this.tempFacilities);
    }

    toggleSpecify() {
        return this.tempFacilities.includes('11') ? true : false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    updateValue(isComplete = true) {
        if (isComplete && !this.valid()) {
            return
        }
        const controls = this.form.controls;

        const acServiceinfra = new AssetClassServiceinfraModel();
        acServiceinfra.clear();
        acServiceinfra.road_access = controls.road_access.value;
        acServiceinfra.status_and_quality = controls.status_and_quality.value;
        acServiceinfra.access_points = controls.access_points.value;
        acServiceinfra.road_length = controls.road_length.value;
        acServiceinfra.owner_of_another_consent = controls.owner_of_another_consent.value;
        acServiceinfra.specify_other_service = controls.specify_other_service.value;

        this.serviceInfrastructureSubject.next(acServiceinfra);
    }

    valid() {
        let isValid = true;
        this.hasFormErrors = false;
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            isValid = false;
            this.hasFormErrors = true;
        }

        return isValid;
    }
}
