// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssignmentsState, selectAll} from '../_reducers/assignment.reducers';
import {AssignmentModel} from '../_models/assignment.model';

export const selectAssignmentsState = createFeatureSelector<AssignmentsState>('assignments');

export const selectAllAssignments = createSelector(
    selectAssignmentsState,
    selectAll
)

export const selectAllAssignmentsInSort = createSelector(
    selectAllAssignments,
    assignments => {
        const items: AssignmentModel[] = [];
        assignments.forEach(assignment => items.push(Object.assign({}, assignment)))

        items.sort((a, b) => {
            const localCompare = a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            return localCompare
        })

        return items
    }
)


export const selectAssignmentsBySearch = (value: string) => createSelector(
    selectAllAssignmentsInSort,
    assignments => {
        if (value == undefined || value == null || value.trim().length == 0) {
            return assignments
        } 
        return assignments.filter(assignment => assignment.name.toLowerCase().includes(value.toLowerCase()))
    }
)

export const selectAssignmentById = (assignmentId: number) => createSelector(
    selectAssignmentsState,
    assignmentsState => {
        return assignmentsState.entities[assignmentId];
    }
);

export const selectAssignmentsPageLoading = createSelector(
    selectAssignmentsState,
    assignmentsState => assignmentsState.listLoading
);

export const selectAssignmentsActionLoading = createSelector(
    selectAssignmentsState,
    assignmentsState => assignmentsState.actionLoading
);

export const selectLastCreatedAssignmentId = createSelector(
    selectAssignmentsState,
    assignmentsState => assignmentsState.lastCreatedAssignmentId
);

export const selectAssignmentsShowInitWaitingMessage = createSelector(
    selectAssignmentsState,
    assignmentsState => assignmentsState.showInitWaitingMessage
);


export const selectTrashedAssignmentCount = createSelector(
    selectAssignmentsState,
    (assignmentsState) => assignmentsState.totalTrashed
)

export const selectAdminTrashedAssignmentCount = createSelector(
    selectAssignmentsState,
    (assignmentsState) => assignmentsState.totalAdminTrashed
);

export const selectAssignmentLastQuery = createSelector(
    selectAssignmentsState,
    (assignmentsState) => assignmentsState.lastQuery
);

export const selectAssignmentsInStore = createSelector(
    selectAssignmentsState,
    assignmentsState => {
        const items: AssignmentModel[] = [];
        each(assignmentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: AssignmentModel[] = [];
        switch (assignmentsState.lastQuery.sortField) {
            case 'name':
                result = httpExtension.sortArrayString(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'manager.first_name':
                result = httpExtension.sortArrayString(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'agency_name':
                result = httpExtension.sortArrayString(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'created_at':
                result = httpExtension.sortArrayDateTime(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'updated_at':
                result = httpExtension.sortArrayDateTime(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'toe_cnt':
                result = httpExtension.sortArrayNumber(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'tp_cnt':
                result = httpExtension.sortArrayNumber(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'val_cnt':
                result = httpExtension.sortArrayNumber(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'audit_cnt':
                result = httpExtension.sortArrayNumber(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;
            case 'status':
                result = httpExtension.sortArrayNumber(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension.sortArray(items, assignmentsState.lastQuery.sortField, assignmentsState.lastQuery.sortOrder);
        }
        return new QueryResultsModel(result, assignmentsState.totalCount, '', assignmentsState.totalTrashed, assignmentsState.lastQuery.pageNumber - 1);
    }
);
