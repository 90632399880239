import {BaseModel} from '../../_base/crud';
import { RegNumberModel } from './reg-number.model';

export class Client extends BaseModel {
    id: number;
    name: string;
    last_name: string;
    address: string;
    picture: string;

    email: string;
    phone: string;

    country_id: number;
    country_name: string;
    city_id: number;
    city_name: string;
    zip_code: string;

    type: string;

    contact_cnt: number;
    ass_cnt: number;
    completed_ass: number;
    ongoing_ass: number;
    reg_numbers: RegNumberModel[];

    toe_cnt: number;

    user_deleted: Date;
    deleted_at: Date;
    created_at: Date;
    updated_at: Date;

    createdBy: string;
    lastUpdatedBy: string;

    deletedBy: string;
    userDeletedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.last_name = '';
        this.address = '';
        this.picture = './assets/media/users/default.jpg';

        this.country_id = undefined;
        this.country_name = '';
        this.city_id = undefined;
        this.city_name = '';

        this.type = 'Company';
        this.email = '';
        this.phone = '';
        this.contact_cnt = 0;
        this.ass_cnt = 0;
        this.completed_ass = 0;
        this.ongoing_ass = 0;
        this.reg_numbers = [];
        this.toe_cnt = 0;

        this.deletedBy = '';
        this.userDeletedBy = '';

        this.user_deleted = null;
        this.deleted_at = null;
        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
