import {
    ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
    BehaviorSubject,
    Subject
} from 'rxjs';
import {AssetClassDetailModel, AssetClassModel} from 'src/app/core/asset_class';
import {
    AssetClassType,
} from 'src/app/core/linked-tables';
import {AppState} from 'src/app/core/reducers';
import {environment} from 'src/environments/environment';
import {
    AllKeyCategoriesRequested,
    KeyCategoryModel,
    MeasurementMethodology,
    PremiseOfValue,
    PurposeMeasurement,
    selectAllKeyCategories,
    StandardMeasurement, UnitAreaMeasurement
} from '../../../../../../core/linked-tables';
import {
    KeyPlaceModel,
    KeyPlaceService,
    KeyPlacesPageRequested,
    selectAllKeyPlaces
} from '../../../../../../core/admin';
import {ValuationDataModel} from '../../../../../../core/valuation';
import {LayoutUtilsService, QueryParamsModel, TypesUtilsService} from '../../../../../../core/_base/crud';
import {AssetClassesService} from '../../../../../../core/asset_class';
import {takeUntil} from 'rxjs/operators';
import {LayoutConfigService, SubheaderService} from '../../../../../../core/_base/layout';
import {
    UpdateValuationData,
} from '../../../../../../core/valuation/_actions/valuation-data.actions';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {
    selectAllValKeyPlaces,
    selectValuationData
} from '../../../../../../core/valuation/_selectors/valuation-data.selector';
import {TranslateService} from '@ngx-translate/core';
import {ToeService} from '../../../../../../core/toe';
import {Location} from '@angular/common';
import {MapsAPILoader} from '@agm/core';
import { awConst } from 'src/app/app.constants';
import { KeyplaceModalComponent } from './keyplace-modal/keyplace-modal.component';

@Component({
    selector: 'kt-valuation-keyplace',
    templateUrl: './valuation-keyplace.component.html',
    styleUrls: ['./valuation-keyplace.component.scss'],
})


export class ValuationKeyplaceComponent implements OnInit, OnDestroy {

    @Input() readonly: boolean;
    @Input() assetClass: AssetClassModel;
    valData: ValuationDataModel;
    // component variables
    protected _onDestroy = new Subject<void>();

    dataSource = new MatTableDataSource();
    displayedColumns = ['id', 'name', 'city_of_location', 'description', 'category', 'actions'];

    landMarkResult: KeyPlaceModel[] = [];
    landMarkResult$: BehaviorSubject<KeyPlaceModel[]> = new BehaviorSubject([]);
    allCategories: KeyCategoryModel[] = [];
    filterType: number;
    baseMediaUrl = environment.baseMediaUrl;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;

    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    // map
    @ViewChild('search', {static: true}) searchElementRef: ElementRef;
    map: any;
    previous;
    selectedId = 0;
    zoom = 15;
    centerLat = environment.mapCenter.lat;
    centerLng = environment.mapCenter.lng;
    currentCenter: { lat: 0, lng: 0 };

    lat = environment.mapCenter.lat;
    lng = environment.mapCenter.lng;

    icon = {
        labelOrigin: {x: 18, y: 22},
        url: './assets/media/icons/pin_red.svg',
        scaledSize: {
            width: 44,
            height: 44
        }
    };

    // old need to clear up

    unitAreaMeasurements: UnitAreaMeasurement[];
    premiseOfValues: PremiseOfValue[];
    assetClassTypes: AssetClassType[];
    standardMeasurements: StandardMeasurement[];
    measurementMethodologies: MeasurementMethodology[];
    purposeMeasurements: PurposeMeasurement[];


    selectedCategory: any[] = [];
    selectedkeyPlaces: any[] = [];

    allCategorySelected = true;
    awConst = awConst;

    /**
     * Component constructor
     *
     * @param assetClassesService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param storePipe$
     * @param dialog: Dialog
     * @param keyPlaceService
     * @param ngZone
     * @param mapsAPILoader: MapsAPILoader
     * @param layoutConfigService: LayoutConfigService
     * @param ref: ChangeDetectorRef
     * @param toeService: ToeService
     * @param translate
     */
    constructor(public assetClassesService: AssetClassesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private storePipe$: Store<AppState>,
                public dialog: MatDialog,
                public keyPlaceService: KeyPlaceService,
                private ngZone: NgZone,
                private mapsAPILoader: MapsAPILoader,
                private layoutConfigService: LayoutConfigService,
                private ref: ChangeDetectorRef,
                private toeService: ToeService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.lat = Number(this.assetClass.details.locationData.latitude);
        this.lng = Number(this.assetClass.details.locationData.longitude);
        this.centerLat = this.lat;
        this.centerLng = this.lng;
        this.store.dispatch(new AllKeyCategoriesRequested());
        this.store.pipe(select(selectAllKeyCategories))
            .pipe(takeUntil(this._onDestroy))
            .subscribe((res: KeyCategoryModel[]) => {
                this.allCategories = res;
                this.selectedCategory = this.allCategories.filter(item => item.selected).map(item => item.id);
            });

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectValuationData))
            .subscribe((res: ValuationDataModel) => {
                this.valData = res;
                this.filterBackEnd();
            });

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllValKeyPlaces))
            .subscribe((res) => {
                this.selectedkeyPlaces = Object.assign([], res);
                this.dataSource.data = res;
            });

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllKeyPlaces))
            .subscribe(res => {
                if (res) {
                    this.landMarkResult$.next(res);
                    this.landMarkResult = res;
                }
            });
    }


    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*          MAP Functions       */
    mapReady(event: any) {

        // agmMap
        this.map = event;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

        this.map.addListener('dragend', () => {
            this.centerLat = this.currentCenter.lat;
            this.centerLng = this.currentCenter.lng;
        });
    }

    centerChange($event: any) {
        if ($event) {
            this.currentCenter = {lat: $event.lat, lng: $event.lng};
        }
    }

    showInfo(landMark, infowindow) {
        this.centerLat = Number(landMark.latitude)
        this.centerLng = Number(landMark.longitude)

        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
        infowindow.open();

        this.selectedId = landMark.id;
    }

    setLocation() {
        this.setCurrentLocation();
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    this.centerLat = position.coords.latitude;
                    this.centerLng = position.coords.longitude;
                    this.zoom = 15;
                }
            });
        } else {
            alert('Position not available');
        }
    }

    getIcon(landmark) {
        const isSelected = this.selectedkeyPlaces.find(el => el.id == landmark.id);
        return {
            labelOrigin: {x: 18, y: 22},
            url: isSelected ? './assets/media/icons/blue_pin_star.svg' : './assets/media/icons/pin_star.svg',
            scaledSize: {
                width: 44,
                height: 44
            }
        };
    }


    updateCheckedOptions(item, $event) {
        if ($event.checked) {
            this.toggleCategory(true, item);
        } else {
            this.allCategorySelected = false;
            this.toggleCategory(false, item);
        }
        this.selectedCategory = this.allCategories.filter(item => item.selected).map(item => item.id);
        this.filterBackEnd();
    }

    setAll($event) {
        this.allCategorySelected = $event.checked;
        this.toggleAllCategories($event.checked);
        this.filterBackEnd();
    }

    toggleAllCategories(toggleOption) {
        this.allCategories = this.allCategories.map(item => {
            const temp = Object.assign({}, item);
            temp.selected = toggleOption;
            return temp;
        });
        this.selectedCategory = this.allCategories.filter(item => item.selected).map(item => item.id);
    }

    toggleCategory(toggleOption, category) {
        this.allCategories = this.allCategories.map(item => {
            if (category.id == item.id) {
                const temp = Object.assign({}, item);
                temp.selected = toggleOption;
                return temp;
            } else {
                return item;
            }
        });
    }

    addKeyPlace(keyPlace, readonly: boolean, infoWindow) {
        if (readonly) {
            return;
        }
        this._addKeyPlace(keyPlace);
        infoWindow.close();
    }

    private _addKeyPlace(keyPlace) {
        const _item = Object.assign({}, this.valData);

        if (this.alreadyAdded(keyPlace)) {
            _item.key_places = this.selectedkeyPlaces.filter((item) => {
                return item.id != keyPlace.id;
            })
        } else {
            _item.key_places = [...this.selectedkeyPlaces, keyPlace];
        }
        this.store.dispatch(new UpdateValuationData({item: _item}));
    }

    deleteKeyPlace(keyPlace) {
        const _item = Object.assign({}, this.valData);
        _item.key_places = this.selectedkeyPlaces.filter(element => element.id != keyPlace.id);
        this.store.dispatch(new UpdateValuationData({item: _item}));
    }

    alreadyAdded(keyPlace) {
        if (this.selectedkeyPlaces.length == 0) {
            return false;
        }
        return this.selectedkeyPlaces.filter(item => item.id == keyPlace.id).length;
    }


    filterBackEnd() {
        const filter: any = {};

        filter.name = '';
        filter.key_categories = this.selectedCategory.join('-');
        filter.valuation_id = this.valData ? this.valData.valuation.id : null;

        const queryParams = new QueryParamsModel(
            filter,
            'asc',
            'id',
            1,
            100
        );
        this.store.dispatch(new KeyPlacesPageRequested({page: queryParams}));
    }

    mapClick(event: any) {
        if (this.previous) {
            this.previous.close();
        }
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND
    }

    public addLandmark() {
        const dialogRef = this.dialog.open(KeyplaceModalComponent, {
            width: '80vw',
            data: {
                valuationId: this.valData ? this.valData.valuation.id : null,
            }
        })
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return
            }
            this._addKeyPlace(res);
        })
    }
}
