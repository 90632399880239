<div class="mad-container" [ngClass]="{'readonly': readOnly}">
    <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container" *ngIf="!readOnly">
        <div *ngIf="(isInProgress$ | async)" class="mr-2">
            <div style="margin-bottom: 14px;">Uploading... {{ progress$ | async }}%</div>
        </div>

        <div class="error" *ngIf="(hasFailed$ | async)" class="mr-2">
            Error: {{ error$ | async }}
        </div>

        <!-- <span *ngIf="(completed$ | async)" class="btn btn-primary mr-2" color="primary" mat-raised-button (click)="resetUpload()">Upload Different Files</span> -->

        <div *ngIf="(isInProgress$ | async)" class="mr-2">
            <span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">Cancel Upload</span>
        </div>

        <!--<div *ngIf="(isReady$ | async) || (hasFailed$ | async) || (completed$ | async)" class="mr-2">-->
        <div *ngIf="!readOnly">
            <span class="btn btn-primary" color="primary" mat-raised-button (click)="addFiles()">{{addButtonLabel}}</span>
            <input #fileInput type="file" (change)="uploadFile($event)" style="display: none" multiple accept="{{fileType}}"/>
        </div>
    </div>

    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <img [attr.src]="setIcon(getFileType(file.name))" alt="">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Filename</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{excludeExtention(file.name)}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="thumbnail">
                <mat-header-cell *matHeaderCellDef> Thumbnail</mat-header-cell>
                <mat-cell *matCellDef="let file"> 
                    <span 
                        style="cursor: pointer;"
                        (click)="previewUploadedFile(file)"
                        class="picture-container"
                        >
                        <img
                            style="
                                height: 60px;
                                width: 60px;
                                object-fit: cover;
                            "
                            (error)="imgOnError($event)"
                            [src]="basePath + file.path + '/' + file.name" />
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                <mat-cell *matCellDef="let file"> 
                    <input class="title-input" [value]="file.title" (change)="inputChange($event, file)">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="descr">
                <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
                <mat-cell *matCellDef="let file"> 
                    <textarea 
                        cols="30"
                        rows="1"
                        class="description-input"
                        [value]="file.descr" 
                        (change)="descriptionChange($event, file)">
                    </textarea>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="check">
                <mat-header-cell *matHeaderCellDef>Automatically insert to all reports</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <mat-checkbox [checked]="file.is_checked" (change)="onchange($event, file)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="is_featured">
                <mat-header-cell *matHeaderCellDef>Featured picture</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <mat-checkbox [checked]="file.is_featured" (change)="onchangeFeatured($event, file)" [disabled]="file.is_featured == 1 || readOnly">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rating_desc">
                <mat-header-cell *matHeaderCellDef>Rating Description</mat-header-cell>
                <mat-cell *matCellDef="let file; let i = index">
                    <mat-select class="rating-selection" [value]="file.rating_desc" (selectionChange)="ratingChange($event, file)">
                        <mat-option [value]="''">
                            Not applicable
                        </mat-option>
                        <ng-container *ngFor="let val of dropDownValues">
                            <mat-option *ngIf="val.key != null" [value]="val.value">
                                {{val.value}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="building">
                <mat-header-cell *matHeaderCellDef>building(s)</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{file.building}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <button mat-icon-button
                            color="primary"
                            matTooltip="Edit Description"
                            (click)="editFileDescription(file)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            color="warn"
                            matTooltip="Delete File"
                            (click)="deleteUploadedFile(file)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            *ngIf="!hasThumbnail"
                            matTooltip="Preview file"
                            (click)="previewUploadedFile(file)"
                            type="button">
                        <mat-icon *ngIf="!docs.includes(getFileType(file.name))">remove_red_eye</mat-icon>
                        <mat-icon *ngIf="docs.includes(getFileType(file.name))">get_app</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>
