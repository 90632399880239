import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'kt-target-property',
    templateUrl: './target-property.component.html',
    styleUrls: ['./target-property.component.scss']
})
export class TargetPropertyComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
