import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ToeConflictsState } from "../_reducers/toe-conflicts.reducer";

export const selectToeConflictsState = createFeatureSelector<ToeConflictsState>('toe-conflicts')

export const selectLeadValuerInvolvementsWithClient = createSelector(
    selectToeConflictsState,
    state => {
        return {
            lead_valuer: state.lead_valuer,
            client: state.client,
            involvements: state.lv_involvement_with_clients
        }
    }
)

export const selectAgencyInvolvementsWithClient = createSelector(
    selectToeConflictsState,
    state => {
        return {
            agency: state.agency,
            client: state.client,
            involvements: state.agency_involvement_with_clients
        }
    }
)

export const selectLvInvolvementsWithTp = createSelector(
    selectToeConflictsState,
    state => {
        return state.lv_involvement_with_tps
    }
)

export const selectAgencyInvolvmentWithTps = createSelector(
    selectToeConflictsState,
    state => state.agency_involvement_with_tps
)

export const selectHasToeConflicts = createSelector(
    selectToeConflictsState,
    state => {
        return state.lv_involvement_with_clients.length > 0
            || state.agency_involvement_with_clients.length > 0
            || state.lv_involvement_with_tps.length > 0
            || state.agency_involvement_with_tps.length > 0
    }
)