export class AcBuildingInfo {
    building_id: number;
    building_name: string;
    completion_year: string;
    is_part_of_property: boolean;
    info_about_property: string;
    building_type: number;
    building_type_comment: string;
    building_grade_id: number;
    energy_efficiency_grade_id: number;
    developer: string;
    anchor_tenant: string;
    foundation_type_id: number;
    building_description: string;
    pictures: any[];
    picture: string;

    constructor() {
        this.building_id = null;
        this.building_name = null;
        this.building_type = null;
        this.building_type_comment = null;
        this.completion_year = null;
        this.is_part_of_property = false;
        this.info_about_property = null;
        this.building_grade_id = null;
        this.energy_efficiency_grade_id = null;
        this.developer = null;
        this.anchor_tenant = null;
        this.foundation_type_id = null;
        this.building_description = null;
        this.pictures = [];
        this.picture = null;
    }
}