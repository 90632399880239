// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {CeilingsService} from '../_services/ceiling.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allCeilingsLoaded} from '../_selectors/ceiling.selectors';
// Actions
import {
    AllCeilingsLoaded,
    AllCeilingsRequested,
    CeilingActionTypes,
    CeilingsPageRequested,
    CeilingsPageLoaded,
    CeilingUpdated,
    CeilingsPageToggleLoading,
    CeilingDeleted,
    CeilingOnServerCreated,
    CeilingCreated,
    CeilingAdminRestored,
    CeilingDeletedFromAdminTrash,
    CeilingDeletedFromTrash,
    CeilingOnServerAdminRestored,
    CeilingOnServerRestored,
    CeilingRestored,
    CeilingTrashFlushed,
    CeilingsActionToggleLoading
} from '../_actions/ceiling.actions';

@Injectable()
export class CeilingEffects {
    showPageLoadingDispatcher = new CeilingsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new CeilingsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CeilingsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllCeilings$ = this.actions$
        .pipe(
            ofType<AllCeilingsRequested>(CeilingActionTypes.AllCeilingsRequested),
            withLatestFrom(this.store.pipe(select(allCeilingsLoaded))),
            filter(([action, isAllCeilingsLoaded]) => !isAllCeilingsLoaded),
            mergeMap(() => this.service.getAllCeilings()),
            map(res => {
                return new AllCeilingsLoaded({ceilings: res.data});
            })
        );

    @Effect()
    loadCeilingsPage$ = this.actions$
        .pipe(
            ofType<CeilingsPageRequested>(CeilingActionTypes.CeilingsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findCeilings(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new CeilingsPageLoaded({
                    ceilings: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteCeiling$ = this.actions$
        .pipe(
            ofType<CeilingDeleted>(CeilingActionTypes.CeilingDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteCeiling(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateCeiling$ = this.actions$
        .pipe(
            ofType<CeilingUpdated>(CeilingActionTypes.CeilingUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateCeiling(payload.ceiling);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createCeiling$ = this.actions$
        .pipe(
            ofType<CeilingOnServerCreated>(CeilingActionTypes.CeilingOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createCeiling(payload.ceiling).pipe(
                    tap(res => {
                        this.store.dispatch(new CeilingCreated({ceiling: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushCeilingTrash$ = this.actions$
        .pipe(
            ofType<CeilingTrashFlushed>(CeilingActionTypes.CeilingTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreCeiling$ = this.actions$
        .pipe(
            ofType<CeilingOnServerRestored>(CeilingActionTypes.CeilingOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CeilingRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminCeiling$ = this.actions$
        .pipe(
            ofType<CeilingOnServerAdminRestored>(CeilingActionTypes.CeilingOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CeilingAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashCeiling$ = this.actions$
        .pipe(
            ofType<CeilingDeletedFromTrash>(CeilingActionTypes.CeilingDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashCeiling$ = this.actions$
        .pipe(
            ofType<CeilingDeletedFromAdminTrash>(CeilingActionTypes.CeilingDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: CeilingsService, private store: Store<AppState>) {
    }
}
