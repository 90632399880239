import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { ValuationNoteModel } from "src/app/core/valuation/_models/valuation-note.model";

export const addNote = createAction(
  '[Valuation Process Page - V2 / Notes] Add Note',
  props<{data: ValuationNoteModel}>()
)

export const editNote = createAction(
  '[Valuation Process Page - V2 / Notes] Edit Note',
  props<{data: Update<ValuationNoteModel>}>()
)

export const removeNote = createAction(
  '[Valuation Process Page - V2 / Notes] Remove Note',
  props<{id: number}>()
)