import { Location } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BathroomFitting, BathroomFittingsService } from "src/app/core/linked-tables";
import { BathroomFittingAddDialogComponent } from "../add/bathroom-fitting-add-dialog.component";

@Component({
  templateUrl: './bathroom-fitting-edit-dialog.component.html',
  styleUrls: ['./bathroom-fitting-edit-dialog.component.scss']
})
export class BathroomFittingEditDialogComponent implements OnInit, OnDestroy {
  bathroomFittings: BathroomFitting[] = [];
  propertySubTypeId = 0;
  returnBathroomFittings: any[]

  constructor(
    public dialogRef: MatDialogRef<BathroomFittingEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public location: Location,
    private bathroomFittingService: BathroomFittingsService
  ) { }

  ngOnInit(): void {
    this.returnBathroomFittings = []
    this.propertySubTypeId = this.data.propertySubTypeId

    if (this.data.currentBathroomFittings.length > 0) {
      this.returnBathroomFittings = this.data.currentBathroomFittings
    }
    this.getData()
  }

  ngOnDestroy(): void {

  }

  setSelectedFittings(item): boolean {
    if (this.returnBathroomFittings.length <= 0) {
      return false
    } else {
      return this.returnBathroomFittings.some(element => element.id == item.id)
    }
  }

  updateCheckOptions(item, $event) {
    if ($event.checked) {
      this.returnBathroomFittings = [...this.returnBathroomFittings, item];
    } else {
      const index = this.returnBathroomFittings.findIndex(element => element.id == item.id);
      if (index !== -1) {
        this.returnBathroomFittings.splice(index, 1);
      }
    }
  }

  addFitting() {
    const dialogRef = this.dialog.open(BathroomFittingAddDialogComponent, {
      data: {property_sub_type_id: this.propertySubTypeId},
      width: '340px',
      position: {top: '50px', right: '50px'}
    })
    dialogRef.afterClosed().subscribe(res => {
      this.getData()
    })
  }

  close() {
    this.dialogRef.close({
      data: this.returnBathroomFittings
    })
  }

  getData() {
    this.bathroomFittingService.getAllBathroomFittings().subscribe(res => {
      this.bathroomFittings = res.data.filter(fitting => {
        return fitting.property_sub_type_id == this.propertySubTypeId
      })
    })
  }
}
