// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {OfficeLayoutsService} from '../_services/office-layout.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allOfficeLayoutsLoaded} from '../_selectors/office-layout.selectors';
// Actions
import {
    AllOfficeLayoutsLoaded,
    AllOfficeLayoutsRequested,
    OfficeLayoutActionTypes,
    OfficeLayoutsPageRequested,
    OfficeLayoutsPageLoaded,
    OfficeLayoutUpdated,
    OfficeLayoutsPageToggleLoading,
    OfficeLayoutDeleted,
    OfficeLayoutOnServerCreated,
    OfficeLayoutCreated,
    OfficeLayoutAdminRestored,
    OfficeLayoutDeletedFromAdminTrash,
    OfficeLayoutDeletedFromTrash,
    OfficeLayoutOnServerAdminRestored,
    OfficeLayoutOnServerRestored,
    OfficeLayoutRestored,
    OfficeLayoutTrashFlushed,
    OfficeLayoutsActionToggleLoading
} from '../_actions/office-layout.actions';

@Injectable()
export class OfficeLayoutEffects {
    showPageLoadingDispatcher = new OfficeLayoutsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OfficeLayoutsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OfficeLayoutsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOfficeLayouts$ = this.actions$
        .pipe(
            ofType<AllOfficeLayoutsRequested>(OfficeLayoutActionTypes.AllOfficeLayoutsRequested),
            withLatestFrom(this.store.pipe(select(allOfficeLayoutsLoaded))),
            filter(([action, isAllOfficeLayoutsLoaded]) => !isAllOfficeLayoutsLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllOfficeLayoutsLoaded({officeLayouts: res.data});
            })
        );

    @Effect()
    loadOfficeLayoutsPage$ = this.actions$
        .pipe(
            ofType<OfficeLayoutsPageRequested>(OfficeLayoutActionTypes.OfficeLayoutsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OfficeLayoutsPageLoaded({
                    officeLayouts: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutUpdated>(OfficeLayoutActionTypes.OfficeLayoutUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.officeLayout);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutOnServerCreated>(OfficeLayoutActionTypes.OfficeLayoutOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.officeLayout).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeLayoutCreated({officeLayout: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushOfficeLayoutTrash$ = this.actions$
        .pipe(
            ofType<OfficeLayoutTrashFlushed>(OfficeLayoutActionTypes.OfficeLayoutTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutOnServerRestored>(OfficeLayoutActionTypes.OfficeLayoutOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeLayoutRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutOnServerAdminRestored>(OfficeLayoutActionTypes.OfficeLayoutOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeLayoutAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutDeleted>(OfficeLayoutActionTypes.OfficeLayoutDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutDeletedFromTrash>(OfficeLayoutActionTypes.OfficeLayoutDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashOfficeLayout$ = this.actions$
        .pipe(
            ofType<OfficeLayoutDeletedFromAdminTrash>(OfficeLayoutActionTypes.OfficeLayoutDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: OfficeLayoutsService, private store: Store<AppState>) {
    }
}
