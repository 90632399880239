// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TaskEffort} from '../_models/task-effort.model';

export enum TaskEffortActionTypes {

    AllTaskEffortsRequested = '[TaskEfforts Home Page] All TaskEfforts Requested',
    AllTaskEffortsLoaded = '[TaskEfforts API] All TaskEfforts Loaded',

    TaskEffortOnServerCreated = '[Edit TaskEffort Dialog] TaskEffort On Server Created',
    TaskEffortCreated = '[Edit TaskEfforts Dialog] TaskEfforts Created',
    TaskEffortUpdated = '[Edit TaskEffort Dialog] TaskEffort Updated',
    TaskEffortDeleted = '[TaskEfforts List Page] TaskEffort Deleted',

    TaskEffortsPageRequested = '[TaskEfforts List Page] TaskEfforts Page Requested',
    TaskEffortsPageLoaded = '[TaskEfforts API] TaskEfforts Page Loaded',
    TaskEffortsPageCancelled = '[TaskEfforts API] TaskEfforts Page Cancelled',

    TaskEffortsPageToggleLoading = '[TaskEfforts page] TaskEfforts Page Toggle Loading',
    TaskEffortsActionToggleLoading = '[TaskEfforts] TaskEfforts Action Toggle Loading',

    // trash system

    TaskEffortOnServerRestored = '[TaskEffort Trash] TaskEffort On Server Restored',
    TaskEffortRestored = '[TaskEffort Trash] TaskEffort Restored',

    TaskEffortOnServerAdminRestored = '[TaskEffort Admin Trash] TaskEffort On Server Restored',
    TaskEffortAdminRestored = '[TaskEffort Admin Trash] TaskEffort Restored',

    TaskEffortDeletedFromTrash = '[TaskEffort Trash] TaskEffort deleted',
    TaskEffortDeletedFromAdminTrash = '[TaskEffort Admin Trash] TaskEffort deleted',

    TaskEffortTrash = 'TaskEffort Trashed',
    TaskEffortTrashFlushed = 'TaskEffort Trash Flushed',
}

export class TaskEffortOnServerCreated implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortOnServerCreated;

    constructor(public payload: { item: TaskEffort}) {
    }
}

export class TaskEffortCreated implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortCreated;

    constructor(public payload: { item: TaskEffort }) {
    }
}

export class TaskEffortUpdated implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortUpdated;

    constructor(public payload: {
        partialItem: Update<TaskEffort>,
        item: TaskEffort
    }) {
    }
}

export class TaskEffortDeleted implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class TaskEffortsPageRequested implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class TaskEffortsPageLoaded implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortsPageLoaded;

    constructor(public payload: { items: TaskEffort[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class TaskEffortsPageCancelled implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortsPageCancelled;
}

export class AllTaskEffortsRequested implements Action {
    readonly type = TaskEffortActionTypes.AllTaskEffortsRequested;
}

export class AllTaskEffortsLoaded implements Action {
    readonly type = TaskEffortActionTypes.AllTaskEffortsLoaded;

    constructor(public payload: { items: TaskEffort[] }) {
    }
}

export class TaskEffortsPageToggleLoading implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class TaskEffortsActionToggleLoading implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class TaskEffortDeletedFromAdminTrash implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class TaskEffortDeletedFromTrash implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TaskEffortOnServerRestored implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TaskEffortRestored implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortRestored;

    constructor(public payload: { item: TaskEffort }) {
    }
}

export class TaskEffortOnServerAdminRestored implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TaskEffortAdminRestored implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortAdminRestored;

    constructor(public payload: { item: TaskEffort }) {
    }
}

export class TaskEffortTrashFlushed implements Action {
    readonly type = TaskEffortActionTypes.TaskEffortTrashFlushed;

    constructor() {
    }
}

export type TaskEffortActions = TaskEffortCreated
    | TaskEffortUpdated
    | TaskEffortDeleted
    | TaskEffortsPageRequested
    | TaskEffortsPageLoaded
    | TaskEffortsPageCancelled
    | AllTaskEffortsLoaded
    | AllTaskEffortsRequested
    | TaskEffortOnServerCreated
    | TaskEffortsPageToggleLoading
    | TaskEffortsActionToggleLoading
    | TaskEffortDeletedFromAdminTrash
    | TaskEffortDeletedFromTrash
    | TaskEffortOnServerRestored
    | TaskEffortRestored
    | TaskEffortOnServerAdminRestored
    | TaskEffortAdminRestored
    | TaskEffortTrashFlushed;
