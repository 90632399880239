import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class FilterService {
  private _filter = {};
  private _filter$: BehaviorSubject<any> = new BehaviorSubject({});
  public filter$: Observable<any> = this._filter$.asObservable();
  private _reset$: Subject<void> = new Subject();
  public reset$: Observable<void> = this._reset$.asObservable();
  constructor() { }

  setValue(key: string, value: number) {
    this._filter = {
      ...this._filter,
      [key]: value
    };
    this._filter$.next(this._filter);
  }

  reset() {
    this._filter = {};
    this._filter$.next(this._filter);
    this._reset$.next();
  }
}
