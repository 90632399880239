import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgencyModel, Appendix, selectAllAppendices, selectAllAppendixCategories, 
 } from 'src/app/core/admin';
import { AppState } from 'src/app/core/reducers';
import { ReportingProcessData } from 'src/app/core/reporting/_models/reporting_process.model';
import { selectReportingAppendices, selectReportingMarketResearch } from 'src/app/core/reporting/_selectors/reporting_process.selectors';
import { SupportingValuerModel } from 'src/app/core/toe/_models/supporting-valuer.model';
import { IFile } from '../category.interface';
import { DocumentTreeV2Component } from '../document-tree-v2/document-tree-v2.component';
import { OtherDocumentsComponent } from '../other-documents/other-documents.component';
import { ToeDocsComponent } from './toe-docs/toe-docs.component';


@Component({
  selector: 'kt-supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrls: ['./supporting-documents.component.scss']
})
export class SupportingDocumentsComponent implements OnInit {
  @Input()
  readonly: boolean;
  @Input()
  reporting: ReportingProcessData;
  @Input()
  reportingID: number;
  @Input()
  agency: AgencyModel;
  @Input()
  leadValuer: SupportingValuerModel;
  @Input()
  members: SupportingValuerModel[];
  @Input()
  otherDocs: any[];

  @ViewChild('appendiceDocuments') appendicesFileTree: DocumentTreeV2Component;
  @ViewChild(OtherDocumentsComponent) documentUpload: OtherDocumentsComponent;
  @ViewChild(ToeDocsComponent) toeDocs: ToeDocsComponent;

  apData$: Observable<IFile[]>;
  constructor(
    private store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.apData$ = combineLatest([
      this.store$
        .select(selectAllAppendixCategories)
        .pipe(map((acs) => acs.filter((ac) => ac.appendix_cnt > 0))),
      this.store$.select(selectAllAppendices),
      this.store$.select(selectReportingAppendices(this.reportingID))
    ]).pipe(
      map(([appendixCategories, appendices, selectedAppendiceIds]) => {
        const files: IFile[] = [];
        appendixCategories.forEach((ac) => {
          const file: IFile = {
            id: ac.id,
            name: ac.name,
            isDir: true,
            selected: false,
            subFiles: this.appendixSubCategories(ac.id, appendices, selectedAppendiceIds),
          }
          files.push(file);
        })
        return files;
      })
    );
  }

  public getAppendicesFiles() {
    return this.appendicesFileTree.getDocuments();
  }
  public getUploadFiles() {
    return this.documentUpload.uploadFiles;
  }
  public getToeDocs() {
    return this.toeDocs.getToeDocs();
  }

  private appendixSubCategories(
    catID: number,
    appendices: Appendix[],
    selectedIds: number[]
  ): IFile[] {
    const files: IFile[] = [];
    appendices
      .filter(a => a.cat_id == catID)
      .forEach(a => {
        const file: IFile = {
          id: a.id,
          name: a.name,
          isDir: true,
          selected: false,
          subFiles: a.appendix_file.map((file) => ({
            id: file.id,
            name: file.title,
            selected: selectedIds.includes(file.id) ? true : false,
            isDir: false,
            url: file.path + '/' + file.name,
            subFiles: [],
          }))
        }
        files.push(file);
      })
    return files;
  }
}
