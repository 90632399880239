<div class="card card-custom section-margin-bottom-60">
    <div class="card-header adjustment-header">
        <div class="card-title">
            <div class="card-label">
                <span>Adjustments</span>
            </div>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.ADJUSTMENTS.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.ADJUSTMENTS.TITLE'|translate">
                help
            </mat-icon>
        </div>
        <div class="card-toolbar">
            <!-- <button (click)="addAdjustment()"
                mat-raised-button
                [matTooltip]="'Add new Adjustment'"
                type="button"
                class="m-1 btn"
                *ngIf="!readonly"
                style="cursor: pointer !important;">
            <span>Add Adjustment</span> -->
            <div ngbDropdown placement="bottom-right" *ngIf="!readonly" class="dropdown dropdown-inline">
                <button ngbDropdownToggle class="btn btn-label-brand btn-bold btn-sm dropdown-toggle" style="cursor: pointer !important;">Add</button>
                <div ngbDropdownMenu style="width: 230px;">
                    <ul class="navi navi-hover">
                        <li class="navi-item">
                            <a class="navi-link" href="javascript:;" (click)="addAdjustment()">
                                <span class="navi-icon">
                                    <i class="flaticon2-add"></i>
                                </span>
                                <span class="navi-text">
                                    Add Adjustment
                                </span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a class="navi-link" href="javascript:;" (click)="addFromTemplate()">
                                <span class="navi-icon">
                                    <i class="flaticon2-add"></i>
                                </span>
                                <span class="navi-text">
                                    Add from template
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        <!-- </button> -->
        </div>
    </div>
    <div class="card-body adjustment-body">
        <div class="col-md-12 mat-table-wrapper ">
            <form [formGroup]="form">
            <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource">
        
                <!-- Headers -->
                <ng-container [matColumnDef]="'input-'+comparables.length+'-header'">
                    <th mat-header-cell
                        *matHeaderCellDef>Quantitative Inputs
                    </th>
                </ng-container>
        
                <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length + '-header'">
                    <th mat-header-cell *matHeaderCellDef>
                        <!-- {{com.ref_num}}
                        <button mat-icon-button color="primary" (click)="showOverview(com)">
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="removeComparable(i, com)">
                            <mat-icon>delete</mat-icon>
                        </button> -->
                        <span class="cell">
                            {{com.ref_num}}
                            <span>
                                <button mat-icon-button color="primary" (click)="showOverview(com)">
                                    <mat-icon>visibility</mat-icon>
                                </button>
                                <button mat-icon-button color="warn" [disabled]="readonly" (click)="removeComparable(i, com)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </span>
                        </span>
                    </th>
                </ng-container>
        
                <ng-container [matColumnDef]="'analysis-' + comparables.length + '-header'">
                    <th mat-header-cell *matHeaderCellDef>
                        Comparative Analysis
                    </th>
                </ng-container>
        
                <ng-container matColumnDef="action-header">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                </ng-container>
        
                <!-- Cells -->
                <ng-container matColumnDef="adjustment">
                    <td mat-cell
                        *matCellDef="let _item">
                        {{_item.name ? _item.name : 'Not defined'}}
                        <ng-template #popTitle>
                            Description of the adjustment
                        </ng-template>
                        <ng-template #popContent>
                            <div>
                                {{_item.desc}}
                            </div>
                        </ng-template>
        
                        <button mat-icon-button
                                color="primary"
                                placement="top"
                                [container]="'body'"
                                (click)="$event.stopPropagation()"
                                [ngbPopover]="popContent"
                                [popoverTitle]="popTitle"
                        >
                            <mat-icon>info</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length">
        
                    <td mat-cell *matCellDef="let _item, let ii = index">
                        <!-- <mat-form-field style="width: 60px;" [appearance]="'fill'">
                            <input 
                                style="text-align: center;" percentRange matInput [formControl]="comparableControl(ii, com.id)"
                                (focusout)="onFocusOut($event, com.id, _item)" />
                        </mat-form-field> -->
                        <div>
                            <kt-percent-input 
                                [adjustmentName]="_item.name" 
                                [comparableID]="com.ref_num" 
                                [formControl]="comparableControl(ii, com.id)"
                                (onPercentChange)="onPercentChange($event, com.id, _item)"></kt-percent-input>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container [matColumnDef]="'analysis-' + comparables.length">
                    <td mat-cell *matCellDef="let _item; let ii = index">
                        <mat-form-field [appearance]="'fill'">
                            <textarea matInput 
                                (keyup)="textAreaAdjustOnKeyUp($event)" 
                                (focusout)="onFocusOutAnalysis($event, _item)"
                                [formControl]="analysisControl(ii)"></textarea>
                        </mat-form-field>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <td mat-cell *matCellDef="let _item;let i = index">
                        <button [disabled]="readonly" mat-icon-button color="primary" (click)="editAdjustment(_item, i)">
                            <mat-icon>create</mat-icon>
                        </button>
        
                        <button
                            [disabled]="readonly"
                                mat-icon-button
                                color="warn"
                                (click)="deleteAdjustment(_item, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
                    [ngClass]="{'colored-row': i%2}"></tr>
            </table>
        </form>
        </div>
    </div>
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">ADJUSTMENTS</h5>
    <hr class="active">
</div>

<div class="col-md-12 d-flex justify-content-end mb-4">
    
</div> -->