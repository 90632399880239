// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {UnitMeasurement} from '../_models/unit-measurement.model';

export enum UnitMeasurementActionTypes {

    AllUnitMeasurementsRequested = '[UnitMeasurements Home Page] All UnitMeasurements Requested',
    AllUnitMeasurementsLoaded = '[UnitMeasurements API] All UnitMeasurements Loaded',

    UnitMeasurementOnServerCreated = '[Edit UnitMeasurement Dialog] UnitMeasurement On Server Created',
    UnitMeasurementCreated = '[Edit UnitMeasurements Dialog] UnitMeasurements Created',
    UnitMeasurementUpdated = '[Edit UnitMeasurement Dialog] UnitMeasurement Updated',
    UnitMeasurementDeleted = '[UnitMeasurements List Page] UnitMeasurement Deleted',

    UnitMeasurementsPageRequested = '[UnitMeasurements List Page] UnitMeasurements Page Requested',
    UnitMeasurementsPageLoaded = '[UnitMeasurements API] UnitMeasurements Page Loaded',
    UnitMeasurementsPageCancelled = '[UnitMeasurements API] UnitMeasurements Page Cancelled',

    UnitMeasurementsPageToggleLoading = '[UnitMeasurements page] UnitMeasurements Page Toggle Loading',
    UnitMeasurementsActionToggleLoading = '[UnitMeasurements] UnitMeasurements Action Toggle Loading',

    // trash system

    UnitMeasurementOnServerRestored = '[UnitMeasurement Trash] UnitMeasurement On Server Restored',
    UnitMeasurementRestored = '[UnitMeasurement Trash] UnitMeasurement Restored',

    UnitMeasurementOnServerAdminRestored = '[UnitMeasurement Admin Trash] UnitMeasurement On Server Restored',
    UnitMeasurementAdminRestored = '[UnitMeasurement Admin Trash] UnitMeasurement Restored',

    UnitMeasurementDeletedFromTrash = '[UnitMeasurement Trash] UnitMeasurement deleted',
    UnitMeasurementDeletedFromAdminTrash = '[UnitMeasurement Admin Trash] UnitMeasurement deleted',

    UnitMeasurementTrash = 'UnitMeasurement Trashed',
    UnitMeasurementTrashFlushed = 'UnitMeasurement Trash Flushed',
}

export class UnitMeasurementOnServerCreated implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementOnServerCreated;

    constructor(public payload: { unitMeasurement: UnitMeasurement}) {
    }
}

export class UnitMeasurementCreated implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementCreated;

    constructor(public payload: { unitMeasurement: UnitMeasurement }) {
    }
}

export class UnitMeasurementUpdated implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementUpdated;

    constructor(public payload: {
        partialunitMeasurement: Update<UnitMeasurement>,
        unitMeasurement: UnitMeasurement
    }) {
    }
}

export class UnitMeasurementDeleted implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class UnitMeasurementsPageRequested implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class UnitMeasurementsPageLoaded implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementsPageLoaded;

    constructor(public payload: { unitMeasurements: UnitMeasurement[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class UnitMeasurementsPageCancelled implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementsPageCancelled;
}

export class AllUnitMeasurementsRequested implements Action {
    readonly type = UnitMeasurementActionTypes.AllUnitMeasurementsRequested;
}

export class AllUnitMeasurementsLoaded implements Action {
    readonly type = UnitMeasurementActionTypes.AllUnitMeasurementsLoaded;

    constructor(public payload: { unitMeasurements: UnitMeasurement[] }) {
    }
}

export class UnitMeasurementsPageToggleLoading implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class UnitMeasurementsActionToggleLoading implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class UnitMeasurementDeletedFromAdminTrash implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class UnitMeasurementDeletedFromTrash implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class UnitMeasurementOnServerRestored implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UnitMeasurementRestored implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementRestored;

    constructor(public payload: { item: UnitMeasurement }) {
    }
}

export class UnitMeasurementOnServerAdminRestored implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UnitMeasurementAdminRestored implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementAdminRestored;

    constructor(public payload: { item: UnitMeasurement }) {
    }
}

export class UnitMeasurementTrashFlushed implements Action {
    readonly type = UnitMeasurementActionTypes.UnitMeasurementTrashFlushed;

    constructor() {
    }
}

export type UnitMeasurementActions = UnitMeasurementCreated
    | UnitMeasurementUpdated
    | UnitMeasurementDeleted
    | UnitMeasurementsPageRequested
    | UnitMeasurementsPageLoaded
    | UnitMeasurementsPageCancelled
    | AllUnitMeasurementsLoaded
    | AllUnitMeasurementsRequested
    | UnitMeasurementOnServerCreated
    | UnitMeasurementsPageToggleLoading
    | UnitMeasurementsActionToggleLoading
    | UnitMeasurementDeletedFromAdminTrash
    | UnitMeasurementDeletedFromTrash
    | UnitMeasurementOnServerRestored
    | UnitMeasurementRestored
    | UnitMeasurementOnServerAdminRestored
    | UnitMeasurementAdminRestored
    | UnitMeasurementTrashFlushed;
