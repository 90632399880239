import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { TemplateRequest, TeamTemplateModel } from "../_models";

export enum TeamTemplateActionTypes {
    LOAD_TEAM_TEMPLATES = '[Team Template List] Load Team Templates',
    TEAM_TEMPLATES_LOADED = '[Team Template Effect] Team Templates Loaded',

    ADD_TEAM_TEMPLATE = '[Team Template Edit] Add New Team Template',
    TEAM_TEMPLATE_ADDED = '[Tp Tempalte Effect] New Team Template Added',
     
    UPDATE_TEAM_TEMPLATE = '[Team Template Edit] Update Team Template',
    TEAM_TEMPLATE_UPDATED = '[Team Template Effect] Team Template Updated',

    DELETE_TEAM_TEMPLATE = '[Team Template List] Delete Team Template',
    TEAM_TEMPLATE_DELETED = '[Team Template Effect] Team Template Deleted',

    DUPLICATE_TEAM_TEMPLATE = '[Team Template List] Duplicate Team Template',
}

export class LoadTeamTemplates implements Action {
    readonly type = TeamTemplateActionTypes.LOAD_TEAM_TEMPLATES;
    constructor(public payload: {
        request: TemplateRequest
    }) {}
}

export class TeamTemplatesLoaded implements Action {
    readonly type = TeamTemplateActionTypes.TEAM_TEMPLATES_LOADED;
    constructor(public payload: {
        templates: TeamTemplateModel[],
        total: number
    }) {}
}

export class AddTeamTemplate implements Action {
    readonly type = TeamTemplateActionTypes.ADD_TEAM_TEMPLATE;
    constructor(public payload: {
        template: TeamTemplateModel
    }) {}
}

export class TeamTemplateAdded implements Action {
    readonly type = TeamTemplateActionTypes.TEAM_TEMPLATE_ADDED;
    constructor(public payload: {
        template: TeamTemplateModel
    }) {}
}

export class UpdateTeamTemplate implements Action {
    readonly type = TeamTemplateActionTypes.UPDATE_TEAM_TEMPLATE;
    constructor(public payload: {
        id: number;
        template: TeamTemplateModel
    }) {}
}

export class TeamTemplateUpdated implements Action {
    readonly type = TeamTemplateActionTypes.TEAM_TEMPLATE_UPDATED;
    constructor(public payload: {
        id: number;
        template: Update<TeamTemplateModel>
    }) {}
}

export class DeleteTeamTemplate implements Action {
    readonly type = TeamTemplateActionTypes.DELETE_TEAM_TEMPLATE;
    constructor(public payload: {
        id: number,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export class TeamTemplateDeleted implements Action {
    readonly type = TeamTemplateActionTypes.TEAM_TEMPLATE_DELETED;
    constructor(public payload: {id: number}) {}
}

export class DuplicateTeamTemplate implements Action {
    readonly type = TeamTemplateActionTypes.DUPLICATE_TEAM_TEMPLATE;
    constructor(public payload: {
        template: TeamTemplateModel,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export type TeamTemplateActions
    = LoadTeamTemplates
    | TeamTemplatesLoaded
    | AddTeamTemplate
    | TeamTemplateAdded
    | UpdateTeamTemplate
    | TeamTemplateUpdated
    | DeleteTeamTemplate
    | TeamTemplateDeleted
    | DuplicateTeamTemplate;