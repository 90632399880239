import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { AppState } from '../../../../../core/reducers';
import {
    AssetClassesPageRequested } from '../../../../../core/asset_class';
import { QueryParamsModel } from '../../../../../core/_base/crud';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';

@Injectable()
export class ToeAssetClassesResolver implements Resolve<any> {
    constructor(
        private store: Store<AppState>,
        private global: Globals,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const queryParams = new QueryParamsModel(
            {},
            'asc',
            'id',
            0,
            1000
        );
        this.store.dispatch(new AssetClassesPageRequested({
            page: queryParams,
            toeId: this.global.activeTOEId
        }));

        return of(null);
    }
}
