// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {TpAdminTaskLVState} from '../_reducers/tp-admin-task-lv.reducers';
import * as fromTpAdminTaskLVs from '../_reducers/tp-admin-task-lv.reducers';
import {TpAdminTaskLVModel} from '../_models/tp-admin-task-lv.model';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';

export const selectTpAdminTaskLVsState = createFeatureSelector<TpAdminTaskLVState>('tpAdminTaskLVs');

export const selectTpAdminTaskLVById = (itemId: number) => createSelector(
    selectTpAdminTaskLVsState,
    cs => cs.entities[itemId]
);

export const selectAllTpAdminTaskLVs = createSelector(
    selectTpAdminTaskLVsState,
    fromTpAdminTaskLVs.selectAll
);

export const selectAllTpAdminTaskLVsIds = createSelector(
    selectTpAdminTaskLVsState,
    fromTpAdminTaskLVs.selectIds
);

export const allTpAdminTaskLVsLoaded = createSelector(
    selectTpAdminTaskLVsState,
    cs => cs._isAllTpAdminTaskLVLoaded
);

export const selectTpAdminTaskLVsPageLoading = createSelector(
    selectTpAdminTaskLVsState,
    assetClassesState => assetClassesState.listLoading
);


export const selectTpAdminTaskLVsShowInitWaitingMessage = createSelector(
    selectTpAdminTaskLVsState,
    tpAdminTaskLVState => tpAdminTaskLVState.showInitWaitingMessage
);

export const selectTpAdminTaskLVsActionLoading = createSelector(
    selectTpAdminTaskLVsState,
    tpAdminTaskLVState => tpAdminTaskLVState.actionsLoading
);

export const selectTpAdminTaskLVsByTpId = (tpId: number) => createSelector(
    selectTpAdminTaskLVsState,
    tpAdminTaskLVState => {
        const items: TpAdminTaskLVModel[] = [];
        each(tpAdminTaskLVState.entities, element => {
            if (element.tp_id == tpId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'task_id');
    }
);

export const selectUploadTaskUsersByTpId = (tpId: number) => createSelector(
    selectTpAdminTaskLVsState,
    tpAdminTaskLVState => {
        const items: TpAdminTaskLVModel[] = [];
        each(tpAdminTaskLVState.entities, element => {
            if (element.tp_id == tpId && element.task_id == 0) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'second_id');
    }
);

export const selectTpAdminTaskLVsByToeId = (toeId: number) => createSelector(
    selectTpAdminTaskLVsState,
    tpAdminTaskLVState => {
        const items: TpAdminTaskLVModel[] = [];
        each(tpAdminTaskLVState.entities, element => {
            if (element.toe_id == toeId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArray(items, 'tp_id');
    }
);


export const selectTpAdminTaskLVsInStore = createSelector(
    selectTpAdminTaskLVsState,
    tpAdminTaskLVState => {
        const items: TpAdminTaskLVModel[] = [];
        each(tpAdminTaskLVState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: TpAdminTaskLVModel[] = [];
        const res = httpExtension.separateArrayByCompletion(items);
        let completedResult: TpAdminTaskLVModel[] = [];
        let inCompletedResult: TpAdminTaskLVModel[] = [];
        if (tpAdminTaskLVState.lastQuery.sortField === 'updated_at') {
            completedResult = httpExtension.sortArrayDateTime(
                res['completed'],
                tpAdminTaskLVState.lastQuery.sortField,
                tpAdminTaskLVState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayDateTime(
                res['inCompleted'],
                tpAdminTaskLVState.lastQuery.sortField,
                tpAdminTaskLVState.lastQuery.sortOrder
            );
        } else if (tpAdminTaskLVState.lastQuery.sortField === 'completion') {
            completedResult = httpExtension.sortArrayCompletion(
                res['completed'],
                tpAdminTaskLVState.lastQuery.sortField,
                tpAdminTaskLVState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayCompletion(
                res['inCompleted'],
                tpAdminTaskLVState.lastQuery.sortField,
                tpAdminTaskLVState.lastQuery.sortOrder
            );
        } else {
            completedResult = httpExtension.sortArray(
                res['completed'],
                tpAdminTaskLVState.lastQuery.sortField,
                tpAdminTaskLVState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArray(
                res['inCompleted'],
                tpAdminTaskLVState.lastQuery.sortField,
                tpAdminTaskLVState.lastQuery.sortOrder
            );
        }
        result = inCompletedResult.concat(completedResult);
        return new QueryResultsModel(result, tpAdminTaskLVState.totalCount, '');
    }
);