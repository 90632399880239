import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    AssetClassOfficeFacilitiesService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassOfficeFacilitiesBuildingGetResolver implements Resolve<any> {
    constructor(public assetClassOfficeFacilitiesService: AssetClassOfficeFacilitiesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const officeId = Number(route.params.office_id);
        return this.assetClassOfficeFacilitiesService.getAllAssetClassOfficeBuildingFacilities(officeId);
    }
}
