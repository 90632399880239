import {Ceiling} from '../_models/ceiling.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {CeilingsState} from '../_reducers/ceiling.reducers';
import * as fromCeiling from '../_reducers/ceiling.reducers';
import {each} from 'lodash';

export const selectCeilingsState = createFeatureSelector<CeilingsState>('ceilings');

export const selectCeilingById = (ceilingId: number) => createSelector(
    selectCeilingsState,
    ceilingsState => ceilingsState.entities[ceilingId]
);

export const selectAllCeilings = createSelector(
    selectCeilingsState,
    fromCeiling.selectAll
);

export const selectAllCeilingsIds = createSelector(
    selectCeilingsState,
    fromCeiling.selectIds
);

export const allCeilingsLoaded = createSelector(
    selectCeilingsState,
    ceilingsState => ceilingsState.isAllCeilingsLoaded
);


export const selectCeilingsPageLoading = createSelector(
    selectCeilingsState,
    ceilingsState => ceilingsState.listLoading
);

export const selectCeilingsActionLoading = createSelector(
    selectCeilingsState,
    ceilingsState => ceilingsState.actionLoading
);

export const selectLastCreatedCeilingId = createSelector(
    selectCeilingsState,
    ceilingsState => ceilingsState.lastCreatedCeilingId
);

export const selectCeilingsShowInitWaitingMessage = createSelector(
    selectCeilingsState,
    ceilingsState => ceilingsState.showInitWaitingMessage
);

export const selectTrashedCeilingCount = createSelector(
    selectCeilingsState,
    (ceilingsState) => ceilingsState.totalTrashed
);

export const selectAdminTrashedCeilingCount = createSelector(
    selectCeilingsState,
    (ceilingsState) => ceilingsState.totalAdminTrashed
);


export const selectCeilingQueryResult = createSelector(
    selectCeilingsState,
    ceilingsState => {
        const items: Ceiling[] = [];
        each(ceilingsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Ceiling[] = httpExtension.sortArray(items, ceilingsState.lastQuery.sortField, ceilingsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, ceilingsState.totalCount, '', ceilingsState.totalTrashed);
    }
);
