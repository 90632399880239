// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectExternalFacadeQueryResult, selectExternalFacadesPageLoading, selectExternalFacadesShowInitWaitingMessage} from '../_selectors/external-facade.selectors';

export class ExternalFacadesDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectExternalFacadesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectExternalFacadesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectExternalFacadeQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
