// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {TpFileState} from '../_reducers/tp-file.reducers';
import * as fromTpFiles from '../_reducers/tp-file.reducers';
// Lodash
import {each} from 'lodash';
import {TpFileModel} from '../_models/tp-file.model';
import {HttpExtenstionsModel} from '../../_base/crud';

export const selectTpFilesState = createFeatureSelector<TpFileState>('tpFiles');

export const selectTpFileById = (fileId: number) => createSelector(
    selectTpFilesState,
    cs => cs.entities[fileId]
);

export const selectAllTpFiles = createSelector(
    selectTpFilesState,
    fromTpFiles.selectAll
);

export const selectAllTpFilesIds = createSelector(
    selectTpFilesState,
    fromTpFiles.selectIds
);

export const allTpFilesLoaded = createSelector(
    selectTpFilesState,
    cs => cs._isAllTpFileLoaded
);


// export const selectLastCreatedTpFileId = createSelector(
//     selectTpFilesState,
//     tpFileState => tpFileState.lastCreatedItemId
// );

export const selectTpFilesShowInitWaitingMessage = createSelector(
    selectTpFilesState,
    tpFileState => tpFileState.showInitWaitingMessage
);

export const selectTpFilesActionLoading = createSelector(
    selectTpFilesState,
    tpFileState => tpFileState.actionsLoading
);

export const selectTpFilesByTpId = (tpId: number) => createSelector(
    selectTpFilesState,
    tpFileState => {
        const items: TpFileModel[] = [];
        each(tpFileState.entities, element => {
            if (element.tp_id == tpId && !element.is_additional) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArray(items, 'cat_id');
    }
);

export const selectTpAdditionalFilesByTpId = (tpId: number) => createSelector(
    selectTpFilesState,
    tpFileState => {
        const items: TpFileModel[] = [];
        each(tpFileState.entities, element => {
            if (element.tp_id == tpId && element.is_additional) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArray(items, 'cat_id');
    }
);
