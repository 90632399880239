import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { selectAllUnitAreaMeasurements } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { selectSingleCriterion } from 'src/app/core/valuation/_selectors/criterion.selectors';
import { selectDefaultSizeCriterion } from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import { selectTotalNumOfValuationAdjustments } from 'src/app/core/valuation/_selectors/valuation-adjustment.selector';
import { selectAllValComparables } from 'src/app/core/valuation/_selectors/valuation-data.selector';
import * as valuationSelectors from 'src/app/core/valuation/_selectors/valuation.selector';
import { ComparableInfoDialogComponent } from '../comparable-info-dialog/comparable-info-dialog.component';

interface TemplateData {
  sizeStandard: string;
  sizeUnit: string;
  tenure: string;
  currency: string;
  numOfAdjustments: number;
  tpValueInfo: {
    valueInCurrency: number;
    valueInUnitSize: number;
    valueWithCapitalAllowance: number;
    valueWithCapitalAllowanceInUnitSize: number;
  },
  capitalAllowance: number;
  capitalAllowanceJustification: string;
  comparableInfo: ComparableInfo[]
}

interface ComparableInfo {
  fullData: any,
  ref_num: string;
  buildingID?: string;
  size: number;
  consideration: number;
  dateType: string;
  date: string;
  totalAdjustment: number;
  adjustedConsideration: number;
  weighting: number;
  weightedAvgGrossUnitRate: number;
}

@Component({
  selector: 'kt-summary-tab',
  templateUrl: './summary-tab.component.html',
  styleUrls: ['./summary-tab.component.scss'],
})
export class SummaryTabComponent implements OnInit {
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  toe: ToeModel;

  map: google.maps.Map;
  tpLat: number;
  tpLng: number;
  comparables: any[] = [];

  cLocations$: Observable<
    {
      ref_num: string;
      lat: number;
      lng: number;
      dist: number;
      label: any;
    }[]
  >;
  templateData$: Observable<TemplateData>;
  tpIcon = {
    labelOrigin: {x: 18, y: 22},
    url: './assets/media/icons/pin_red.svg',
    scaledSize: {
        width: 30,
        height: 30
    }
  }
  icon = {
    url: './assets/media/icons/my_marker.svg',
    labelOrigin: {
      x: 18,
      y: 40
    }
  };

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.tpLat = Number(this.targetProperty.details.locationData.latitude);
    this.tpLng = Number(this.targetProperty.details.locationData.longitude);

    this.cLocations$ = this.store$.select(selectAllValComparables).pipe(
      map((data) => {
        const cLocations: {
          ref_num: string;
          lat: number;
          lng: number;
          dist: number;
          label: any;
        }[] = [];
        this.comparables = [];
        this.comparables = data;
        data.forEach((d) => {
          cLocations.push({
            ref_num: d.ref_num,
            lat: Number(d.locationData.latitude),
            lng: Number(d.locationData.longitude),
            dist: this.calculateDistance(
              Number(d.locationData.latitude),
              Number(d.locationData.longitude),
              this.tpLat,
              this.tpLng,
              'K'
            ),
            label: {
              color: '#000000',
              fontFamily: '',
              fontSize: '14px',
              fontWeight: 'bold',
              className: 'bg-op',
              text: d.ref_num,
            }
          });
        });

        return cLocations;
      })
    );

    this.templateData$ = combineLatest([
      this.store$.select(selectAllValComparables),
      combineLatest([
        this.store$.select(valuationSelectors.selectAllValuations),
        this.store$.select(valuationSelectors.selectTotalPropertyValue),
        this.store$.select(valuationSelectors.selectTotalWeightedAvgGrossUnit),
        this.store$.select(selectDefaultSizeCriterion),
        this.store$.select(selectSingleCriterion(1)),
        this.store$.select(selectTotalNumOfValuationAdjustments),
        this.store$.select(valuationSelectors.selectCapitalAllowance),
        this.store$.select(valuationSelectors.selectTotalTPValue),
        this.store$.select(valuationSelectors.selectTotalTPInUnitMeasurementValue),
        this.store$.select(valuationSelectors.selectCapitalAllowanceJustification)
      ]).pipe(
        map(
          ([
            valuations,
            tpValue,
            tpValueInSizeRate,
            sizeCriterion,
            considerationCriterion,
            numOfAdjustments,
            capitalAllowance,
            totalTPValue,
            totalTPValueInUnitSize,
            capitalAllowanceJustification
          ]: any[]) => {
            return {
              valuations,
              tpValue,
              tpValueInSizeRate,
              sizeCriterion,
              considerationCriterion,
              numOfAdjustments,
              capitalAllowance,
              totalTPValue,
              totalTPValueInUnitSize,
              capitalAllowanceJustification
            };
          }
        )
      ),
      this.store$.pipe(select(selectAllUnitAreaMeasurements)).pipe(
        map((res) => {
          if (res) {
            return res.find(
              (item) => item.system_id === this.toe.unit_of_measurement_id
            );
          }
        }),
        filter((val) => (val === undefined ? false : true))
      ),
    ]).pipe(
      map(([comparables, adjustmentTabData, unitAreaMeasurement]) => {
        const comparableInfo: ComparableInfo[] = [];

        comparables.forEach((com) => {
          const valuation = adjustmentTabData.valuations.find(
            (v) => v.comInfo.id === com.id
          );
          const size = adjustmentTabData.sizeCriterion.comValues[com.id];
          const consideration =
            adjustmentTabData.considerationCriterion.comValues[com.id];
          comparableInfo.push({
            fullData: com,
            ref_num: com.ref_num,
            buildingID: com.buildingInfo ? com.buildingInfo.building_name : null,
            size: size,
            consideration: consideration,
            dateType: com.source.source_type_name,
            date:
              com.source.source_type_id == 1
                ? com.source.transaction_date
                : com.source.information_date,
            totalAdjustment: valuation.totalAdjustment,
            adjustedConsideration: valuation.adjustedConsideration,
            weighting: valuation.weighting,
            weightedAvgGrossUnitRate: valuation.weightedAvgGrossUnitRate,
          });
        });

        const templateData: TemplateData = {
          sizeStandard: adjustmentTabData.sizeCriterion.name,
          sizeUnit: unitAreaMeasurement.name,
          tenure: adjustmentTabData.considerationCriterion.name,
          currency: this.targetProperty.country_currency,
          numOfAdjustments: adjustmentTabData.numOfAdjustments,
          capitalAllowance: adjustmentTabData.capitalAllowance,
          tpValueInfo: {
            valueInCurrency: adjustmentTabData.tpValue,
            valueInUnitSize: adjustmentTabData.tpValueInSizeRate,
            valueWithCapitalAllowance: adjustmentTabData.totalTPValue,
            valueWithCapitalAllowanceInUnitSize: adjustmentTabData.totalTPValueInUnitSize
          },
          capitalAllowanceJustification: adjustmentTabData.capitalAllowanceJustification,
          comparableInfo,
        };

        return templateData;
      })
    );
  }

  //
  // Actions
  //
  showMultipleOverview() {
    const dialogRef = this.dialog.open(ComparableInfoDialogComponent, {
        data: {
        propertySubTypeId: this.targetProperty.type_id,
        targetProperty: this.targetProperty,
        comparables: this.comparables,
        selectedIds: this.comparables.map(el => el.id),
        addButton: false,
        isMulti: true
        },
        width: '80vw',
        viewContainerRef: this.viewContainerRef,
        disableClose: true
    })
  }

  //
  // Events
  //
  onMapReady(event) {
    this.map = event;
  }

  //
  // Helpers
  //
  private calculateDistance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == 'K') {
        dist = dist * 1.609344;
      }
      if (unit == 'N') {
        dist = dist * 0.8684;
      }
      return parseFloat(dist.toFixed(2));
    }
  }
}
