<div class="p-0 m-0" [ngClass]="{'readonly': readonly}">
    <!-- start::MAP -->
    <div class="form-group kt-form-group row">
        <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
            <agm-map [latitude]="centerLat"
                     [longitude]="centerLng"
                     [zoom]="zoom"
                     (mapReady)="mapReady($event)"
                     (mapClick)="mapClick($event)"
                     (centerChange)="centerChange($event)"
                     [usePanning]="true"
                     [disableDoubleClickZoom]="true"
                     [scrollwheel]="null"
                     [clickableIcons]="false">
                <agm-map-type-control></agm-map-type-control>
                <button type="button"
                        id="Profile"
                        class="btn btn-light btn-icon mr-2 map-button"
                        (click)="setLocation()">
                    <mat-icon>my_location</mat-icon>
                </button>
                <agm-marker *ngIf="lat != 0"
                            [latitude]="lat"
                            [longitude]="lng"
                            animation="DROP"
                            [iconUrl]="icon">
                </agm-marker>
                <ng-container *ngIf="landMarkResult$|async as lmr">
                    <agm-marker *ngFor="let item of lmr"
                            [latitude]="item.latitude"
                            [longitude]="item.longitude"
                            [iconUrl]="getIcon(item)"
                            animation="DROP"
                            (markerClick)="showInfo(item, info)">
                    <agm-info-window #info
                                     [disableAutoPan]="true"
                                     style="padding: 2rem">
                        <div class="card card-custom landmark-info-window">
                            <div class="card-header p-1">
                                <div class="card-title">
                                    <ng-container *ngIf="item.picture.length > 0">
                                        <img 
                                            [src]="baseMediaUrl + '/' + item.picture"
                                            (error)="imgOnError($event)"
                                            style="
                                                height: 50px;
                                                width: 50px;
                                                object-fit: cover;
                                            " loading="lazy">
                                    </ng-container>
                                    <h4 class="card-label ml-6">{{item.name}}</h4>
                                </div>
                            </div>
                            <div class="card-body p-1">
                                <p class="mb-1">
                                    <span class="text-mad">Category: </span>
                                    {{item.category_name}}
                                </p>
                                <p class="mb-1">
                                    <span class="text-mad">Description: </span>
                                    {{item.description}}
                                </p>
                                <a (click)="addKeyPlace(item, readonly, info)" style="float: right; cursor: pointer;">
                                    {{alreadyAdded(item) ? 'Remove' : 'Add'}}
                                </a>
                            </div>
                        </div>
                    </agm-info-window>
                </agm-marker>
                </ng-container>
            </agm-map>
        </div>
    </div>
    <!-- end::MAP -->
    <div class="form-group kt-form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile pt-2">
            <mat-checkbox
                    [checked]="allCategorySelected"
                    (change)="setAll($event)"
            >
                All
            </mat-checkbox>
        </div>
        <div *ngFor="let category of allCategories; let i = index"
             class="col-lg-4 kt-margin-bottom-20-mobile pt-2">
            <mat-checkbox
                    [checked]="category.selected"
                    (change)="updateCheckedOptions(category, $event)"
            >
                {{category.name}}
            </mat-checkbox>
        </div>
    </div>

    <div class="card card-custom">
        <div class="card-header landmark-header">
            <div class="card-title"></div>
            <div class="card-toolbar">
                <div>
                    <button (click)="addLandmark()" mat-raised-button
                            [matTooltip]="'Add Landmark'"
                            class="float-right m-0 ml-4 btn"
                            type="button">
                        <span>Add Landmark</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body landmark-body">
            <div class="form-group kt-form-group row">
                <div class="mat-table-wrapper">
                    <mat-table class="mat-elevation-z0 table-striped"
                               #table
                               [dataSource]="dataSource"
                               matSort
                               #sort1="matSort"
                               matSortActive="id"
                               matSortDirection="desc"
                               matSortDisableClear>
        
                        <ng-container matColumnDef="id">
                            <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                            <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                            <mat-cell
                                    *matCellDef="let keyPlace">{{keyPlace.id}}
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                            <mat-cell *matCellDef="let keyPlace">{{ keyPlace.name}}</mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
                            <mat-cell *matCellDef="let keyPlace">{{keyPlace.description | truncate : ['100']}}</mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="category">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                            <mat-cell *matCellDef="let keyPlace">{{keyPlace.category_name}}</mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="city_of_location">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                            <mat-cell *matCellDef="let keyPlace">
                                {{ keyPlace.city_of_location }}
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                            <mat-cell *matCellDef="let keyPlace">
                                                            <span
                                                                    [matTooltip]="'Unselect Landmark'">
                                                                <button mat-icon-button
                                                                        [disabled]="readonly"
                                                                        color="warn"
                                                                        type="button"
                                                                        (click)="deleteKeyPlace(keyPlace)">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>&nbsp;
                                                            </span>
                            </mat-cell>
                        </ng-container>
        
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        
                        <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                                 [ngClass]="{'colored-row': index%2}"></mat-row>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>

    
</div>