<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<div class="card-label">Warning</div>
		</div>
	</div>
	<div class="form">
		<div class="card-body">

		</div>
		<div class="card-footer b-0 p-0 text-right">
			<div class="form-actions form-actions-solid">
				<button mat-button (click)="onNoClick()">No</button>
				<button mat-button (click)="onYesClick()" color="primary">Yes</button>
			</div>
		</div>
	</div>
</div>