import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ConflictOfInterestModel} from '../../../../../core/toe';
import {ConflictType} from '../../../../../core/toe/_static-data/conflict-type';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConflictModel} from '../../../../../core/toe/_models/conflict.model';
import {ConflictService} from '../../../../../core/toe';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-conflict-detail-old',
    templateUrl: './conflict-detail-old.component.html',
    styleUrls: ['./conflict-detail-old.component.scss']
})


export class ConflictDetailOldComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() conflictData: ConflictOfInterestModel;
    @Input() isDisclosure = false;
    @Input() index: number;
    @Input() isTemplate: boolean;
    @Input() readonly: boolean;

    @Output() onClickFileBtn: EventEmitter<any> = new EventEmitter();
    selectedOptionIndex: number;
    options: { name: string, prefix: string }[];
    explainOptionPrefix: string[];
    conflictForm: UntypedFormGroup;
    explainDisable = false;

    labelForNoSelection = "";
    labelForYesSelection = "";

    @ViewChild('field') field: ElementRef;
    private explainFieldSubscription: Subscription;

    tooltipKeys: string[] = [
        "not_having_the_level",
        "valuer_employ_another_firm",
        "valuation_relied_on_other_parties",
        "asset_previously_valued",
        "valuer_involved_in_purchase",
        "valuer_provided_valuations",
        "valuer_signatory",
        "valuer_involved_professional_services",
        "valuer_involved_12_month_before",
        "purpose_of_valuation",
    ];

    // private subscriptions: Subscription[] = [];

    constructor(private formBuilder: UntypedFormBuilder, 
        public conflictService: ConflictService,
        private translate: TranslateService) {
    }

    ngOnInit() {
        if (this.conflictData.name == 'purpose_of_valuation') {
            const [no, yes] = this.conflictData.mitigation_strategy_title.split('||');
            this.labelForNoSelection = no;
            this.labelForYesSelection = yes;
        }
        this.options = ConflictType.conflictTypes[this.conflictData.option_type - 1].options;
        this.explainOptionPrefix = ConflictType.conflictTypes[this.conflictData.option_type - 1].explainOptionPrefix;

        if (this.isDisclosure) {
            const _value = this.conflictService.getDisclosure(this.conflictData.name);
            this.selectedOptionIndex = _value.index;

            this.conflictForm = this.formBuilder.group({
                explain: [_value.text, Validators.required]
            });

            if (this.conflictData.name == 'valuation_relied_on_other_parties') {
                this.conflictService.changedValueOfDisclosure('valuation_relied_on_other_parties', _value.index);
            }
        } else {
            const _value = this.conflictService.getConflict(this.conflictData.name);
            this.selectedOptionIndex = _value.index;
            this.conflictForm = this.formBuilder.group({
                explain: [_value.text, Validators.required]
            });
        }
        if (this.isTemplate) {
            this.conflictForm.controls.explain.clearValidators();
        }
        this.conflictForm.controls.explain.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.sendDataToService();
    }

    ngAfterViewInit(): void {
        if (this.conflictData.name == 'purpose_of_valuation') {
            if (this.selectedOptionIndex < 1) {
                this.conflictForm.controls.explain.setValue('The valuation may not be acceptable to a lender');
                this.explainDisable = true;
            } else {
                const _value = this.conflictService.getDisclosure(this.conflictData.name);
                this.conflictForm.controls.explain.setValue(_value.text);
                this.explainDisable = false;
            }
        }
    }


    get showForm(): boolean {
        const _res = this.explainOptionPrefix.indexOf(this.options[this.selectedOptionIndex].prefix) > -1;
        if (_res && this.field) {
            // add sub
            this.explainFieldSubscription = fromEvent(this.field.nativeElement, 'keyup').pipe(
                debounceTime(50),
                distinctUntilChanged(),
                tap(() => {
                    this.sendDataToService();
                })
            )
                .subscribe();
        } else {
            if (this.explainFieldSubscription) {
                this.explainFieldSubscription.unsubscribe();
                this.explainFieldSubscription = null;
            }
        }
        return _res;
    }

    public valid() {

        const controls = this.conflictForm.controls;
        if (!this.conflictForm.valid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        }
    }

    sendDataToService() {
        const _conflict = new ConflictModel();
        _conflict.clear();
        _conflict.prefix = this.conflictData.name;
        _conflict.optionPrefix = this.options[this.selectedOptionIndex].prefix;

        _conflict.index = this.selectedOptionIndex;
        _conflict.optionType = this.conflictData.option_type;
        _conflict.text = this.conflictForm.controls.explain.value;

        _conflict._isValid = this.explainOptionPrefix.indexOf(this.options[this.selectedOptionIndex].prefix) > -1 ?
            (_conflict.text && _conflict.text.length >= 20) : true;

        if (this.isDisclosure) {
            this.conflictService.updateDisclosureResult(_conflict);
        } else {
            this.conflictService.updateConflictResult(_conflict);
        }
    }

    radioChanged() {

        // explain
        if (this.conflictData.name == 'purpose_of_valuation') {
            if (this.selectedOptionIndex < 1) {
                this.conflictForm.controls.explain.setValue('The valuation may not be acceptable to a lender');
                this.explainDisable = true;
            } else {
                this.conflictForm.controls.explain.setValue(null);
                this.explainDisable = false;
            }
        }

        this.sendDataToService();

        setTimeout(() => {
            if (this.field) {
                this.field.nativeElement.focus();
            }
        }, 500);
    }

    addFiles() {
        this.onClickFileBtn.emit();
    }
}
