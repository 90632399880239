import {BaseModel} from '../../_base/crud';
import {SourceExternalReference} from '../../linked-tables';

export class AssetClassSourceExternalReferenceModel extends BaseModel {
    id: number;
    asset_class_id: number;
    source_external_reference_id: number;
    source_external_reference: SourceExternalReference;
    name: string;
    description: string;
    reference_available: number;
    assumption: number;
    assumption_descr: string;
    special_assumption: number;
    special_assumption_descr: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.asset_class_id = undefined;
        this.source_external_reference_id = undefined;
        this.source_external_reference = undefined;
        this.name = '';
        this.description = '';
        this.reference_available = 1;
        this.assumption = 0;
        this.assumption_descr = '';
        this.special_assumption = 0;
        this.special_assumption_descr = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}