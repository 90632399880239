// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
// Models
import {environment} from '../../../../environments/environment';
import {AssetClassSizeModel, ResidentialExternalAreaModel} from '../../comparable';
import {FloorRateModel} from '../_models/floor-rate.model';
import {AuditTrailParticipantModel} from '../../assignment/_models/audit-trail-participant.model';
import {AssetClassDetailModel} from '../_models/asset-class-detail.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class AssetClassDetailService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/inspection`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    get(assetClassId: number): Observable<any> {
        const url = `${this.API_URL}/${assetClassId}`;
        return this.http.get<any>(url);
    }

    fetchSubData(assetClassId: number): Observable<any> {
        const url = `${this.API_URL}/${assetClassId}/data`;
        return this.http.get<any>(url);
    }

    create(_model: AssetClassDetailModel,
           _sizes: AssetClassSizeModel[],
           _fileList: any[],
           _selectedFacilities: any[],
           _selectedFacilitiesOffice: any[],
           _selectedFacilitiesOfficeExclusive: any[],
           _externalAreas: ResidentialExternalAreaModel[],
           _ratings: FloorRateModel[],
           _participants: AuditTrailParticipantModel[],
           _user_id: number,
           _size_module_data: any): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {
            assetClassData: _model,
            sizes: _sizes,
            fileList: _fileList,
            selectedFacilities: _selectedFacilities,
            selectedFacilitiesOffice: _selectedFacilitiesOffice,
            selectedFacilitiesOfficeExclusive: _selectedFacilitiesOfficeExclusive,
            externalAreas: _externalAreas,
            ratings: _ratings,
            participants: _participants,
            user_id: _user_id,
            sizeModuleData: _size_module_data
        };

        return this.http.post<any>(this.API_URL, body, {headers: httpHeaders});
    }

    update(_det: AssetClassDetailModel,
           _sizes: AssetClassSizeModel[],
           _fileList: any[],
           _selectedFacilities: any[],
           _selectedFacilitiesOffice: any[],
           _selectedFacilitiesOfficeExclusive: any[],
           _externalAreas: ResidentialExternalAreaModel[],
           _ratings: FloorRateModel[],
           _participants: AuditTrailParticipantModel[],
           _user_id: number,
           _size_module_data: any): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        httpHeader.set('Content-Type', 'application/json');
        const body = {
            assetClassData: _det,
            sizes: _sizes,
            fileList: _fileList,
            selectedFacilities: _selectedFacilities,
            selectedFacilitiesOffice: _selectedFacilitiesOffice,
            selectedFacilitiesOfficeExclusive: _selectedFacilitiesOfficeExclusive,
            externalAreas: _externalAreas,
            ratings: _ratings,
            participants: _participants,
            user_id: _user_id,
            sizeModuleData: _size_module_data
        };
        const url = `${this.API_URL}/${_det.id}`;
        return this.http.put(url, body, {headers: httpHeader});
    }


    getAllAssetClassDetails(_id: number): Observable<any> {
        const url = `${this.API_URL}/${_id}/sub_data`;
        return this.http.get<any>(url);
    }
}
