import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { AllDefaultAssumptionsRequested, AllDeparturesRequested, AllSpecialAssumptionsRequested, DefaultAssumption, Departure, selectAllDefaultAssumptions, selectAllDepartures, selectAllSpecialAssumptions, selectAllValuationStandard, SpecialAssumption, ValuationStandard } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ValuationAssumptionDeparture, Type, getType, Source, Status } from 'src/app/core/valuation/_models/valuation-assumption-departure.model';
import * as _ from 'lodash'

@Component({
  selector: 'kt-edit-assumption-departure',
  templateUrl: './edit-assumption-departure.component.html',
  styleUrls: ['./edit-assumption-departure.component.scss']
})
export class EditAssumptionDepartureComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  item: ValuationAssumptionDeparture;
  isEdit: boolean;
  type: Type;
  status = Status;
  inputSource = Source;
  inputType = Type;
  subscriptions: Subscription[] = [];
  valuationStandards: ValuationStandard[] = [];
  selectedValuationStandardId: number = null;

  private _onDestroy$: Subject<void> = new Subject()
  tp: AssetClassModel
  assumptions: DefaultAssumption[] = []
  specialAssumptions: SpecialAssumption[] = []
  departures: Departure[] = []
  existings: any[] = []

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditAssumptionDepartureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>
  ) { 
    this.isEdit = data.isEdit;
    this.type = data.type;
    this.tp = data.tp
    this.existings = data.existing ?? []

    if (data.item) {
      this.item = Object.assign({}, data.item) as ValuationAssumptionDeparture;
    } else {
      this.item = new ValuationAssumptionDeparture();
      this.item.ref_id = null;
      this.item.type = this.type;
      this.item.source = Source.Input;
      this.item.input = null;
      this.item.status = Status.Valid;
      this.item.justification = null;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new AllDefaultAssumptionsRequested())
    this.store.pipe(
      select(selectAllDefaultAssumptions),
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      if (res) {
        const customs = this.tp.selected_default_assumptions.filter(el => !el.default_assumption_id).map(el => {
          const _tmp = new DefaultAssumption()
          _tmp.clear()
          _tmp.id = el.default_assumption_id
          _tmp.name = el.default_assumption_name
          _tmp.selected = true
          return _tmp
        })
        const defaults = res
          .filter(item => item.id != 1)
          .map(item => {
            const _tmp = Object.assign({}, item)
            if (this.existings
                  .filter(el => el.type == Type.DefaultAssumption)
                  .some(el => {
                    return el.default_assumption_id == item.id
                  })) {
              _tmp.selected = true
            } else {
              _tmp.selected = false
            }
            return _tmp
          });
        this.assumptions = _.concat(defaults, customs)
      }
    })
    this.store.dispatch(new AllSpecialAssumptionsRequested())
    this.store.pipe(
      select(selectAllSpecialAssumptions),
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      if (res) {
        const customs = this.tp.selected_special_assumptions.filter(el => !el.special_assumption_id).map(el => {
          const _tmp = new SpecialAssumption()
          _tmp.clear()
          _tmp.id = el.special_assumption_id
          _tmp.name = el.special_assumption_name
          _tmp.selected = true
          return _tmp
        })
        const defaults = res
          .filter(item => item.id != 1)
          .map(item => {
            const _tmp = Object.assign({}, item)
            if (this.existings
                  .filter(el => el.type == Type.SpecialAssumption)
                  .some(el => {
                    return el.special_assumption_id == item.id
                  })) {
              _tmp.selected = true
            } else {
              _tmp.selected = false
            }
            return _tmp
          });
        this.specialAssumptions = _.concat(defaults, customs)
      }
    })
    this.store.dispatch(new AllDeparturesRequested())
    this.store.pipe(
      select(selectAllDepartures),
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      if (res) {
        const customs = this.tp.selected_departures.filter(el => !el.departure_id).map(el => {
          const _tmp = new Departure()
          _tmp.clear()
          _tmp.id = el.departure_id
          _tmp.name = el.departure_name
          _tmp.valuation_standard_id = el.valuation_standard_id
          _tmp.valuation_standard_name = el.valuation_standard_name
          _tmp.selected = true
          return _tmp
        })
        const defaults = res
          .filter(item => item.id != 1)
          .map(item => {
            const _tmp = Object.assign({}, item)
            if (this.existings
                  .filter(el => el.type == Type.Departure)
                  .some(el => {
                    return el.departure_id == item.id
                  })) {
              _tmp.selected = true
            } else {
              _tmp.selected = false
            }
            return _tmp
          });
        this.departures = _.concat(defaults, customs)
      }
    })
    this._createFormV2();
    const valuationStandardSub = this.store.select(selectAllValuationStandard).subscribe(res => {
      if (res) {
        this.valuationStandards = res;
        if (this.item.input) {
          const splits = this.item.input.split('|');
          const vs = this.valuationStandards.find(v => v.name.trim() === splits[0].trim());
          if (vs) {
            this.selectedValuationStandardId = vs.id;
            const control = this.formBuilder.control(this.selectedValuationStandardId)
            this.form.setControl('valuation_standard', control);
            this._setValuationStandard();
          }
        }
      }
    });
    this.subscriptions.push(valuationStandardSub);
  }

  ngOnDestroy() {
    this._onDestroy$.next()
    this._onDestroy$.complete()
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  _createForm(): void {
    let _input = this.item.input;
    if (this.type === Type.Departure && this.item.input) {
      const splits = this.item.input.split('|')
      _input = splits[1];
    }
    this.form = this.formBuilder.group({
      'input': new UntypedFormControl({value: _input, disabled: this.item.status === Status.Removed}, Validators.required),
      'valuation_standard': this.formBuilder.control(this.selectedValuationStandardId),
      'status': this.formBuilder.control('' + this.item.status),
      'justification': this.formBuilder.control(this.item.justification)
    });

    const inputControl = this.form.get('input');
    const inputSub = inputControl.valueChanges.subscribe((val: string) => {
      if (this.item.input !== val.trim()) {
        this.item.status = Status.Amended;
      } else {
        this.item.status = Status.Valid;
      }

      // Set status
      if (this.isEdit && this.item.source === Source.ToE) {
        const statusControl = this.form.get('status');
        statusControl.setValue('' + this.item.status);
        statusControl.updateValueAndValidity();
      }

      // Set Justification
      const justificationControl = this.form.get('justification');
      if (this.item.source === Source.ToE && this.item.status !== Status.Valid) {
        justificationControl.setValidators(Validators.required);
        justificationControl.updateValueAndValidity();
      } else {
        justificationControl.clearValidators();
        justificationControl.updateValueAndValidity();
      }
    });
    this.subscriptions.push(inputSub);

    if (this.item.status !== Status.Valid) {
      const justificationControl = this.form.get('justification');
      justificationControl.setValidators(Validators.required);
      justificationControl.updateValueAndValidity();
    }

    const statusControl = this.form.get('status');
    const statusSub = statusControl.valueChanges.subscribe(val => {
      this.item.status = Number(val);
    });
    this.subscriptions.push(statusSub);

    if (this.type === Type.Departure) {
      const vsControl = this.form.get('valuation_standard');
      vsControl.setValidators(Validators.required);
      vsControl.updateValueAndValidity();
      const initialValue = vsControl.value;

      vsControl.valueChanges.subscribe(res => {
        if (this.item.source === Source.ToE && res != initialValue) {
          const justificationControl = this.form.get('justification');
          justificationControl.setValidators(Validators.required);
          justificationControl.updateValueAndValidity();
        }
      })
    }

  }

  _createFormV2(): void {
    let _input = this.item.input;
    if (this.type === Type.Departure && this.item.input) {
      const splits = this.item.input.split('|')
      _input = splits[1];
    };

    this.form = this.formBuilder.group({
      'input': new UntypedFormControl({value: _input, disabled: this.item.status === Status.Removed}, Validators.required),
      'valuation_standard': this.formBuilder.control({value: this.selectedValuationStandardId, disabled: this.item.status === Status.Removed}),
      'status': this.formBuilder.control({value: '' + this.item.status, disabled: true}),
      'justification': this.formBuilder.control(this.item.justification)
    });

    if (this.item.status === Status.Valid) {
      // Listen 'Input' value changes
      const inputControl = this.form.get('input');
      const inputSub = inputControl.valueChanges.subscribe((val: string) => {
        if (_input != val.trim()) {
          this.item.status = Status.Amended;
        } else {
          this.item.status = Status.Valid;
        }

        // Set Status
        if (this.isEdit && this.item.source === Source.ToE) {
          const statusControl = this.form.get('status');
          statusControl.setValue('' + this.item.status);
          statusControl.updateValueAndValidity();
        }
      });
      this.subscriptions.push(inputSub);
    } else {
      const justificationControl = this.form.get('justification');
      justificationControl.setValidators(Validators.required);
      justificationControl.updateValueAndValidity();
    }

    // Listen status changes
    const statusControl = this.form.get('status');
    const statusSub = statusControl.valueChanges.subscribe(val => {
      this.item.status = Number(val);

      // Set justification
      const justificationControl = this.form.get('justification');
      if (this.item.source === Source.ToE && this.item.status !== Status.Valid) {
        justificationControl.setValidators(Validators.required);
        justificationControl.updateValueAndValidity();
      } else {
        justificationControl.setValidators(null);
        justificationControl.updateValueAndValidity();
      }
    });
    this.subscriptions.push(statusSub);

    if (this.type === Type.Departure) {
      this._setValuationStandard();
    }
  }

  _setValuationStandard() {
    const vsControl = this.form.get('valuation_standard');
    const initialValue = vsControl.value;
    vsControl.setValidators(Validators.required);
    vsControl.updateValueAndValidity();

    if (this.item.status === Status.Valid) {
      // Listen 'Valuation Standard' value changes
      const vasControl = this.form.get('valuation_standard');
      const vsSub = vasControl.valueChanges.subscribe(val => {
        if (initialValue !== val) {
          this.item.status = Status.Amended;
        } else {
          this.item.status = Status.Valid;
        }

        // Set Status
        if (this.isEdit && this.item.source === Source.ToE) {
          const statusControl = this.form.get('status');
          statusControl.setValue('' + this.item.status);
          statusControl.updateValueAndValidity();
        }
      });
      this.subscriptions.push(vsSub);
    }
  }

  getComponentTitle(): string {
    let type = getType(this.type);
    if (this.isEdit) {
      return `Edit ${type}`;
    }
    return `Add ${type}`;
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(controlName => {
        this.form.controls[controlName].markAsTouched();
      })
      return;
    }
    let _input = this.form.get('input').value;
    const _valuationStandardId = this.form.get('valuation_standard').value;
    const vs = this.valuationStandards.find(v => v.id == _valuationStandardId);
    if (vs) {
      _input = vs.name + '|' + _input;
    }
    const _status = Number(this.form.get('status').value);
    const _justification = this.form.get('justification').value;
    this.item.input = _input;
    this.item.status = _status;
    this.item.justification = _justification;
    this.dialogRef.close(this.item);
  }

  checkEnum(val): boolean {
    return isNaN(val);
  }

  updateAssumption(assumption: any) {
    this.item.default_assumption_id = assumption.id
    this.item.source = Source.Input
    this.item.input = assumption.name
    this.dialogRef.close(this.item)
  }

  updateSAssumption(assumption: any) {
    this.item.special_assumption_id = assumption.id
    this.item.source = Source.Input
    this.item.input = assumption.name
    this.dialogRef.close(this.item)
  }

  updateDeparture(departure: Departure) {
    this.item.departure_id = departure.id
    this.item.source = Source.Input
    this.item.input = departure.valuation_standard_name + '|' + departure.name
    this.dialogRef.close(this.item)
  }
}
