import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { TemplateRequest, TemplateResponse, TpTemplateModel } from "../_models";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class TpTemplateService {

    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/templates/tp`;
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    getAll(request: TemplateRequest): Observable<TemplateResponse<TpTemplateModel>> {
        const headers = this.authService.getAuthHeaders();
        const params = new HttpParams()
            .set("page", request.pageNumber.toString())
            .set("pageSize", request.pageSize.toString());

        return this.http.get<{
            data: TpTemplateModel[],
            pagination: {
                total: number
            }
        }>(this.API_URL, {
            headers,
            params
        }).pipe(
            map(res => {
                const tres: TemplateResponse<TpTemplateModel> = {
                    templates: res.data,
                    total: res.pagination.total
                }
                return tres;
            })
        )
    }

    get(id: number): Observable<TpTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.get<{data: TpTemplateModel}>(this.API_URL + `/${id}`, {headers}).pipe(
            map(res => res.data)
        );
    }

    create(template: TpTemplateModel): Observable<TpTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.post<{data: TpTemplateModel}>(this.API_URL, template, {headers}).pipe(map(res => res.data));
    }

    update(id: number, template: TpTemplateModel): Observable<TpTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.put<{data: TpTemplateModel}>(this.API_URL + `/${id}`, template, {headers}).pipe(map(res => res.data));
    }

    delete(id: number): Observable<{sucess: boolean, id: number}> {
        const headers = this.authService.getAuthHeaders();
        return this.http.delete<{sucess: boolean, id: number}>(this.API_URL + `/${id}`, {headers});
    }

    duplicate(template: TpTemplateModel): Observable<TpTemplateModel> {
        const headers = this.authService.getAuthHeaders();
        return this.http.post<{data: TpTemplateModel}>(this.API_URL + '/duplicate', template, {headers}).pipe(map(res => res.data));
    }
}