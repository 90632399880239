<div [ngClass]="{'readonly': readonly}">
    <tag-input matInput
                class="facility"
                [(ngModel)]="currentBathroomFittings"
                [identifyBy]="'id'"
                [displayBy]="'name'"
                (onRemove)="tagRemove($event)"
                (click)="chooseBathroomFittings()"
                [placeholder]="getPlaceholder()"
                [secondaryPlaceholder]="getPlaceholder()"
                readonly="true">
    </tag-input>
</div>