import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AdditionalCostTitleModel } from "../_models/additional-cost-title.model";

export enum AdditionalCostTitleActionTypes {
    AllAdditionalCostTitlesRequested = '[Linked Area] All AdditionalCostTitle Requested',
    AllAdditionalCostTitlesLoaded = '[AdditionalCostTitle Effect] All AdditionalCostTitle Loaded',

    AdditionalCostTitleOnServerCreated = '[Add AdditionalCostTitle Page] AdditionalCostTitle On Server Created',
    AdditionalCostTitleCreated = '[AdditionalCostTitle Effect] AdditionalCostTitle Created',
    AdditionalCostTitleUpdated = '[AdditionalCostTitle Effect] AdditionalCostTitle Updated',
    AdditionalCostTitleDeleted = '[AdditionalCostTitle List Page] AdditionalCostTitle Deleted',

    AdditionalCostTitleOnServerRestored = '[AdditionalCostTitle Trash] AdditionalCostTitle On Server Restored',
    AdditionalCostTitleRestored = '[AdditionalCostTitle Trash] AdditionalCostTitle Restored',

    AdditionalCostTitleOnServerAdminRestored = '[AdditionalCostTitle Admin Trash] AdditionalCostTitle On Server Restored',
    AdditionalCostTitleAdminRestored = '[AdditionalCostTitle Admin Trash] AdditionalCostTitle Restored',

    AdditionalCostTitleDeletedFromTrash = '[AdditionalCostTitle Trash] AdditionalCostTitle deleted',
    AdditionalCostTitleDeletedFromAdminTrash = '[AdditionalCostTitle Admin Trash] AdditionalCostTitle deleted',

    AdditionalCostTitleTrash = 'AdditionalCostTitle Trashed',
    AdditionalCostTitleTrashFlushed = 'AdditionalCostTitle Trash Flushed',

    AdditionalCostTitlesPageRequested = '[AdditionalCostTitle List Page] AdditionalCostTitle Page Requested',
    AdditionalCostTitlesPageLoaded = '[AdditionalCostTitle API] AdditionalCostTitle Page Loaded',
    AdditionalCostTitlesPageCancelled = '[AdditionalCostTitle API] AdditionalCostTitle Page Cancelled',

    AdditionalCostTitlesPageToggleLoading = '[AdditionalCostTitle page] AdditionalCostTitle Page Toggle Loading',
    AdditionalCostTitleActionToggleLoading = '[AdditionalCostTitle] AdditionalCostTitle Action Toggle Loading'
}

export class AdditionalCostTitleOnServerCreated implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerCreated;

    constructor(public payload: { item: AdditionalCostTitleModel }) {
    }
}

export class AdditionalCostTitleCreated implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleCreated;

    constructor(public payload: { item: AdditionalCostTitleModel }) {
    }
}

export class AdditionalCostTitleUpdated implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleUpdated;

    constructor(public payload: {
        partialItem: Update<AdditionalCostTitleModel>,
        item: AdditionalCostTitleModel
    }) {
    }
}

export class AdditionalCostTitleDeleted implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AdditionalCostTitlesPageRequested implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitlesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AdditionalCostTitlesPageLoaded implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitlesPageLoaded;

    constructor(public payload: { items: AdditionalCostTitleModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class AdditionalCostTitlesPageCancelled implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitlesPageCancelled;
}

export class AllAdditionalCostTitlesRequested implements Action {
    readonly type = AdditionalCostTitleActionTypes.AllAdditionalCostTitlesRequested;
}

export class AllAdditionalCostTitlesLoaded implements Action {
    readonly type = AdditionalCostTitleActionTypes.AllAdditionalCostTitlesLoaded;

    constructor(public payload: { items: AdditionalCostTitleModel[] }) {
    }
}

export class AdditionalCostTitlesPageToggleLoading implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitlesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AdditionalCostTitleActionToggleLoading implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class AdditionalCostTitleDeletedFromAdminTrash implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AdditionalCostTitleDeletedFromTrash implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AdditionalCostTitleOnServerRestored implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AdditionalCostTitleRestored implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleRestored;

    constructor(public payload: { item: AdditionalCostTitleModel }) {
    }
}

export class AdditionalCostTitleOnServerAdminRestored implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AdditionalCostTitleAdminRestored implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleAdminRestored;

    constructor(public payload: { item: AdditionalCostTitleModel }) {
    }
}

export class AdditionalCostTitleTrashFlushed implements Action {
    readonly type = AdditionalCostTitleActionTypes.AdditionalCostTitleTrashFlushed;

    constructor() {
    }
}


export type AdditionalCostTitleActions = AdditionalCostTitleCreated
    | AdditionalCostTitleUpdated
    | AdditionalCostTitleDeleted
    | AdditionalCostTitlesPageRequested
    | AdditionalCostTitlesPageLoaded
    | AdditionalCostTitlesPageCancelled
    | AllAdditionalCostTitlesLoaded
    | AllAdditionalCostTitlesRequested
    | AdditionalCostTitleOnServerCreated
    | AdditionalCostTitleDeletedFromAdminTrash
    | AdditionalCostTitleDeletedFromTrash
    | AdditionalCostTitleOnServerRestored
    | AdditionalCostTitleRestored
    | AdditionalCostTitleOnServerAdminRestored
    | AdditionalCostTitleAdminRestored
    | AdditionalCostTitleTrashFlushed
    | AdditionalCostTitlesPageToggleLoading
    | AdditionalCostTitleActionToggleLoading;