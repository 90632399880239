// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {UnitLengthMeasurement} from '../_models/unit-length-measurement.model';

export enum UnitLengthMeasurementActionTypes {

    AllUnitLengthMeasurementsRequested = '[UnitLengthMeasurements Home Page] All UnitLengthMeasurements Requested',
    AllUnitLengthMeasurementsLoaded = '[UnitLengthMeasurements API] All UnitLengthMeasurements Loaded',

    UnitLengthMeasurementOnServerCreated = '[Edit UnitLengthMeasurement Dialog] UnitLengthMeasurement On Server Created',
    UnitLengthMeasurementCreated = '[Edit UnitLengthMeasurements Dialog] UnitLengthMeasurements Created',
    UnitLengthMeasurementUpdated = '[Edit UnitLengthMeasurement Dialog] UnitLengthMeasurement Updated',
    UnitLengthMeasurementDeleted = '[UnitLengthMeasurements List Page] UnitLengthMeasurement Deleted',

    UnitLengthMeasurementsPageRequested = '[UnitLengthMeasurements List Page] UnitLengthMeasurements Page Requested',
    UnitLengthMeasurementsPageLoaded = '[UnitLengthMeasurements API] UnitLengthMeasurements Page Loaded',
    UnitLengthMeasurementsPageCancelled = '[UnitLengthMeasurements API] UnitLengthMeasurements Page Cancelled',

    UnitLengthMeasurementsPageToggleLoading = '[UnitLengthMeasurements page] UnitLengthMeasurements Page Toggle Loading',
    UnitLengthMeasurementsActionToggleLoading = '[UnitLengthMeasurements] UnitLengthMeasurements Action Toggle Loading',

    // trash system

    UnitLengthMeasurementOnServerRestored = '[UnitLengthMeasurement Trash] UnitLengthMeasurement On Server Restored',
    UnitLengthMeasurementRestored = '[UnitLengthMeasurement Trash] UnitLengthMeasurement Restored',

    UnitLengthMeasurementOnServerAdminRestored = '[UnitLengthMeasurement Admin Trash] UnitLengthMeasurement On Server Restored',
    UnitLengthMeasurementAdminRestored = '[UnitLengthMeasurement Admin Trash] UnitLengthMeasurement Restored',

    UnitLengthMeasurementDeletedFromTrash = '[UnitLengthMeasurement Trash] UnitLengthMeasurement deleted',
    UnitLengthMeasurementDeletedFromAdminTrash = '[UnitLengthMeasurement Admin Trash] UnitLengthMeasurement deleted',

    UnitLengthMeasurementTrash = 'UnitLengthMeasurement Trashed',
    UnitLengthMeasurementTrashFlushed = 'UnitLengthMeasurement Trash Flushed',
}

export class UnitLengthMeasurementOnServerCreated implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerCreated;

    constructor(public payload: { unitLengthMeasurement: UnitLengthMeasurement}) {
    }
}

export class UnitLengthMeasurementCreated implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementCreated;

    constructor(public payload: { unitLengthMeasurement: UnitLengthMeasurement }) {
    }
}

export class UnitLengthMeasurementUpdated implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementUpdated;

    constructor(public payload: {
        partialunitLengthMeasurement: Update<UnitLengthMeasurement>,
        unitLengthMeasurement: UnitLengthMeasurement
    }) {
    }
}

export class UnitLengthMeasurementDeleted implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class UnitLengthMeasurementsPageRequested implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class UnitLengthMeasurementsPageLoaded implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageLoaded;

    constructor(public payload: { unitLengthMeasurements: UnitLengthMeasurement[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class UnitLengthMeasurementsPageCancelled implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageCancelled;
}

export class AllUnitLengthMeasurementsRequested implements Action {
    readonly type = UnitLengthMeasurementActionTypes.AllUnitLengthMeasurementsRequested;
}

export class AllUnitLengthMeasurementsLoaded implements Action {
    readonly type = UnitLengthMeasurementActionTypes.AllUnitLengthMeasurementsLoaded;

    constructor(public payload: { unitLengthMeasurements: UnitLengthMeasurement[] }) {
    }
}

export class UnitLengthMeasurementsPageToggleLoading implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class UnitLengthMeasurementsActionToggleLoading implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class UnitLengthMeasurementDeletedFromAdminTrash implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class UnitLengthMeasurementDeletedFromTrash implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class UnitLengthMeasurementOnServerRestored implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UnitLengthMeasurementRestored implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementRestored;

    constructor(public payload: { item: UnitLengthMeasurement }) {
    }
}

export class UnitLengthMeasurementOnServerAdminRestored implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UnitLengthMeasurementAdminRestored implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementAdminRestored;

    constructor(public payload: { item: UnitLengthMeasurement }) {
    }
}

export class UnitLengthMeasurementTrashFlushed implements Action {
    readonly type = UnitLengthMeasurementActionTypes.UnitLengthMeasurementTrashFlushed;

    constructor() {
    }
}

export type UnitLengthMeasurementActions = UnitLengthMeasurementCreated
    | UnitLengthMeasurementUpdated
    | UnitLengthMeasurementDeleted
    | UnitLengthMeasurementsPageRequested
    | UnitLengthMeasurementsPageLoaded
    | UnitLengthMeasurementsPageCancelled
    | AllUnitLengthMeasurementsLoaded
    | AllUnitLengthMeasurementsRequested
    | UnitLengthMeasurementOnServerCreated
    | UnitLengthMeasurementsPageToggleLoading
    | UnitLengthMeasurementsActionToggleLoading
    | UnitLengthMeasurementDeletedFromAdminTrash
    | UnitLengthMeasurementDeletedFromTrash
    | UnitLengthMeasurementOnServerRestored
    | UnitLengthMeasurementRestored
    | UnitLengthMeasurementOnServerAdminRestored
    | UnitLengthMeasurementAdminRestored
    | UnitLengthMeasurementTrashFlushed;
