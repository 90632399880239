<div class="card h-100">
    <div class="card-header section-header">
        <span>Property Specifics</span>
    </div>
    <div class="card-body section-body">
        <table class="table" *ngIf="data$|async as data">
            <tbody>
                <tr>
                    <th>Property Type: </th> <td>{{data.propertyType}}</td>
                </tr>
                <tr>
                    <th>Property Size: </th> <td>{{data.propertySize}}</td>
                </tr>
                <tr>
                    <ng-container *ngIf="tp.type_id == 1 || tp.type_id == 3">
                        <th>Building Grade: </th> <td>{{data.buildingGrade}}</td>
                    </ng-container>
                    <ng-container *ngIf="tp.type_id == 7 || tp.type_id == 17">
                        <th>Property Grade: </th> <td>{{data.propertyGrade}}</td>
                    </ng-container>
                </tr>
                <tr>
                    <th>Property Sub-Type: </th> <td>{{data.assetClass}}</td>
                </tr>
                <tr *ngIf="tp.type_id == 1 || tp.type_id == 3">
                    <th>Building Type: </th> <td>{{data.buildingType}}</td>
                </tr>
                <tr>
                    <th>Location Grade: </th> <td>{{data.locationGrade}}</td>
                </tr>
                <tr>
                    <th>Address: </th> <td><span class="clickable" (click)="showInMap($event, data.location.ltlng)">{{data.location.address}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>