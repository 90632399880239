import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AssetClassSERActionTypes, AssetClassSERActions } from '../_actions/asset-class-ser.action';
import { AssetClassSourceExternalReferenceModel } from '../_models/asset-class-source-external-reference.model';

export interface AssetClassSERState extends EntityState<AssetClassSourceExternalReferenceModel> {
    listLoading: boolean;
}

export const adapter: EntityAdapter<AssetClassSourceExternalReferenceModel>
    = createEntityAdapter<AssetClassSourceExternalReferenceModel>();

export const initialAssetClassSERState: AssetClassSERState = adapter.getInitialState({
    listLoading: false
});

export function assetClassSERReducer(
    state = initialAssetClassSERState,
    action: AssetClassSERActions
): AssetClassSERState {
    switch (action.type) {
        case AssetClassSERActionTypes.AllAssetClassSERRequested:
            return {
                ...state,
                listLoading: true
            };

        case AssetClassSERActionTypes.AllAssetClassSERLoaded:
            return adapter.addAll(action.payload.sers, {
                ...state,
                listLoading: false
            });

        case AssetClassSERActionTypes.AssetClassSERUpdated:
            return adapter.updateOne(action.payload.partialSER, state);

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
