import { Component, ElementRef, Injectable, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUploadService } from '../../../../core/file-upload/_services/file-upload.service';
import { map, expand, takeUntil, mergeMap, filter, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { VideoViewerDialogComponent } from './video-viewer-dialog/video-viewer-dialog.component';
import { VideoUploadService } from 'src/app/core/file-upload/_services/video-upload.service';
import { Video } from 'src/app/core/file-upload/_models/video.model';
import { Store } from '@ngrx/store';
import { VideoUploadState } from 'src/app/core/file-upload/_reducers/video-upload.reducers';
import * as fromVideoUploadActions from 'src/app/core/file-upload/_actions/video.actions';
import { selectVideoIsProcessing, selectVideoIsUploading, selectVideos } from 'src/app/core/file-upload/_selectors/video-upload.selectors';
import { Update } from '@ngrx/entity';
import { VideoEditDialogComponent } from './video-edit-dialog/video-edit-dialog.component';
import { awConst } from 'src/app/app.constants';

export class uploadFiles {
  constructor(public video: File, public path: string, public uploadURI: string) {
    this.video = video;
    this.path = path;
    this.uploadURI = uploadURI;
  }
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss'],
})
export class UploadVideoComponent implements OnInit, OnDestroy {
    @Input() videos: Video[] = [];
    @Input() readonly: boolean = false;
    @ViewChild('file') fileInput: ElementRef<HTMLInputElement>;

    columns = ['thumbnail', 'title', 'description', 'actions'];
    dataSource = new MatTableDataSource<Video>();

    public uploading$: Observable<boolean>;
    public processing$: Observable<boolean>;
    private _onDestroy$: Subject<void> = new Subject();
    public uploadedVideos: Video[] = [];
    awConst = awConst;

    constructor(
        private dialog: MatDialog,
        private store$: Store<VideoUploadState>
    ) { }

    ngOnInit() {
        this.store$.dispatch(new fromVideoUploadActions.SetVideoStateAction({videos: this.videos}));
        this.store$.select(selectVideos).pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(videos => {
            this.uploadedVideos = videos;
            this.dataSource.data = videos;
        });
        this.uploading$ = this.store$.select(selectVideoIsUploading);
        this.processing$ = this.store$.select(selectVideoIsProcessing);
    }

    ngOnDestroy() {
        this._onDestroy$.next();
        this._onDestroy$.complete();
        this.store$.dispatch(new fromVideoUploadActions.ResetVideoStateAction());
    }

    /** Actions */
    public addVideo() {
        this.fileInput.nativeElement.click()
    }
    public editVideo(video: Video) {
        const dialogRef = this.dialog.open(VideoEditDialogComponent, {
            data: {
                description: video.description
            },
            width: '440px'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const partial: Update<Video> = {
                id: video.vimeo_id,
                changes: {
                    description: res.description
                }
            };
            this.store$.dispatch(new fromVideoUploadActions.UpdateVideo({partial}));
        })
    }

    public deleteVideo(video: Video) {
        this.store$.dispatch(new fromVideoUploadActions.DeleteVideo({vimeo_id: video.vimeo_id}));
    }

    public viewVideo(video: Video) {
        this.dialog.open(VideoViewerDialogComponent, {
            data: {
                title: video.title,
                src: video.video_player_url 
            },
            width: '800px'
        });
    }

    public descriptionChange(event, file) {
        const target = event.target as HTMLInputElement;
        const val = target.value.trim();
        const partial: Update<Video> = {
            id: file.vimeo_id,
            changes: {
                description: val
            }
        }
        this.store$.dispatch(new fromVideoUploadActions.UpdateVideo({partial}));
    }

    public seeVideos() {}

    /** Events */
    public onInputFocusOut(event: FocusEvent, video: Video) {
        const target = event.target as HTMLInputElement;
        const val = target.value.trim();
        const partial: Update<Video> = {
            id: video.vimeo_id,
            changes: {
                title: val
            }
        }
        this.store$.dispatch(new fromVideoUploadActions.UpdateVideo({partial}));
    }

    public onVideoUpload(event) {
        const files: FileList = event.target.files;
        const file = files[0]
        this.store$.dispatch(new fromVideoUploadActions.UploadVideoAction({file}));
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND
    }
}