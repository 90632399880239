// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {BuildingTypesService} from '../_services/building-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allBuildingTypesLoaded} from '../_selectors/building-type.selectors';
// Actions
import {
    AllBuildingTypesLoaded,
    AllBuildingTypesRequested,
    BuildingTypeActionTypes,
    BuildingTypesPageRequested,
    BuildingTypesPageLoaded,
    BuildingTypeUpdated,
    BuildingTypesPageToggleLoading,
    BuildingTypeDeleted,
    BuildingTypeOnServerCreated,
    BuildingTypeCreated,
    BuildingTypeAdminRestored,
    BuildingTypeDeletedFromAdminTrash,
    BuildingTypeDeletedFromTrash,
    BuildingTypeOnServerAdminRestored,
    BuildingTypeOnServerRestored,
    BuildingTypeRestored,
    BuildingTypeTrashFlushed,
    BuildingTypesActionToggleLoading
} from '../_actions/building-type.actions';

@Injectable()
export class BuildingTypeEffects {
    showPageLoadingDispatcher = new BuildingTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new BuildingTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new BuildingTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllBuildingTypes$ = this.actions$
        .pipe(
            ofType<AllBuildingTypesRequested>(BuildingTypeActionTypes.AllBuildingTypesRequested),
            withLatestFrom(this.store.pipe(select(allBuildingTypesLoaded))),
            filter(([action, isAllBuildingTypesLoaded]) => !isAllBuildingTypesLoaded),
            mergeMap(() => this.service.getAllBuildingTypes()),
            map(res => {
                return new AllBuildingTypesLoaded({buildingTypes: res.data});
            })
        );

    @Effect()
    loadBuildingTypesPage$ = this.actions$
        .pipe(
            ofType<BuildingTypesPageRequested>(BuildingTypeActionTypes.BuildingTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findBuildingTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new BuildingTypesPageLoaded({
                    buildingTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeDeleted>(BuildingTypeActionTypes.BuildingTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteBuildingType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeUpdated>(BuildingTypeActionTypes.BuildingTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateBuildingType(payload.buildingType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeOnServerCreated>(BuildingTypeActionTypes.BuildingTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createBuildingType(payload.buildingType).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingTypeCreated({buildingType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushBuildingTypeTrash$ = this.actions$
        .pipe(
            ofType<BuildingTypeTrashFlushed>(BuildingTypeActionTypes.BuildingTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeOnServerRestored>(BuildingTypeActionTypes.BuildingTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeOnServerAdminRestored>(BuildingTypeActionTypes.BuildingTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeDeletedFromTrash>(BuildingTypeActionTypes.BuildingTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashBuildingType$ = this.actions$
        .pipe(
            ofType<BuildingTypeDeletedFromAdminTrash>(BuildingTypeActionTypes.BuildingTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: BuildingTypesService, private store: Store<AppState>) {
    }
}
