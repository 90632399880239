import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssetClassSourceExternalReferenceModel } from '../../../../../core/asset_class';
import {
    AllSourceExternalReferencesRequested,
    selectAllSourceExternalReferences,
    SourceExternalReference,
} from '../../../../../core/linked-tables';


@Component({
    selector: 'kt-external-references-edit-dialog',
    templateUrl: './external-references-edit.dialog.component.html',
    styleUrls: ['./external-references-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ExternalReferencesEditDialogComponent implements OnInit, OnDestroy {

    // Public properties
    participant: AssetClassSourceExternalReferenceModel;
    participantForm: UntypedFormGroup;
    existSourceExternalReferences = [];
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    sourceExternalReferences: SourceExternalReference[] = [];

    decisionsExtra: any[] = [
        {label: 'Yes', value: 1, default: false},
        {label: 'No', value: 0, default: true},
    ];

    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<ExternalReferencesEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
        // this.existSourceExternalReferences = this.data.sourceExternalReferences;
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.store.dispatch(new AllSourceExternalReferencesRequested());
        
        const sourceSubscription = this.store.pipe(
            select(selectAllSourceExternalReferences))
            .subscribe(res => {
                this.sourceExternalReferences = [];
                if (res) {
                    this.sourceExternalReferences = res.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                }
            });
        this.subscriptions.push(sourceSubscription);
        
        if (this.data.participant) {
            this.participant = Object.assign({}, this.data.participant);
        } else {
            this.participant = new AssetClassSourceExternalReferenceModel();
            this.participant.clear();
        }
        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
    createForm() {
        this.participantForm = this.fb.group({
            source_external_reference_id: [this.participant.source_external_reference_id, Validators.required],
            reference_available: [this.participant.reference_available, Validators.required],
            assumption: [this.participant.assumption, Validators.required],
            assumption_descr: [this.participant.assumption_descr],
            special_assumption: [this.participant.special_assumption, Validators.required],
            special_assumption_descr: [this.participant.special_assumption_descr],
        });
        
        this.participantForm.controls.assumption.valueChanges.subscribe(value => {
            if (value === 1) {
                this.participantForm.controls.assumption_descr.setValidators([Validators.required]);
            } else {
                if (this.participant && this.participant.id) {
                    this.participantForm.controls.assumption_descr.setValue(this.participant.assumption_descr);
                } else {
                    this.participantForm.controls.assumption_descr.setValue('');
                }
                this.participantForm.controls.assumption_descr.clearValidators();
            }
            this.participantForm.controls.assumption_descr.updateValueAndValidity();
        });
        
        this.participantForm.controls.special_assumption.valueChanges.subscribe(value => {
            if (value === 1) {
                this.participantForm.controls.special_assumption_descr.setValidators([Validators.required]);
            } else {
                if (this.participant && this.participant.id) {
                    this.participantForm.controls.special_assumption_descr.setValue(this.participant.special_assumption_descr);
                } else {
                    this.participantForm.controls.special_assumption_descr.setValue('');
                }
                this.participantForm.controls.special_assumption_descr.clearValidators();
            }
            this.participantForm.controls.special_assumption_descr.updateValueAndValidity();
        });
        
        this.participantForm.controls.reference_available.valueChanges.subscribe(value => {
            if (value === 1) {
                this.participantForm.controls.assumption.setValue(0);
                this.participantForm.controls.special_assumption.setValue(0);
            }
        });
    }

    /** ACTIONS */

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        /** check form */
        if (!this.isNameValid()) {
            this.hasFormErrors = true;
            return;
        }
        
        const controls = this.participantForm.controls;
        /** check form */
        if (this.participantForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        
            this.hasFormErrors = true;
            return;
        }
        
        this.participant.source_external_reference = this.sourceExternalReferences.find(item => item.id === controls.source_external_reference_id.value);
        this.participant.reference_available = controls.reference_available.value;
        if (controls.reference_available.value == 1) {
            this.participant.assumption = 0;
            this.participant.assumption_descr = '';
            this.participant.special_assumption = 0;
            this.participant.special_assumption_descr = '';
        } else {
            this.participant.assumption = controls.assumption.value;
            this.participant.assumption_descr = controls.assumption_descr.value;
            this.participant.special_assumption = controls.special_assumption.value;
            this.participant.special_assumption_descr = controls.special_assumption_descr.value;
        }
        
        if (this.participant.id) {
            this.participant._isEditMode = true;
        }
        
        this.dialogRef.close({
            participant: this.participant,
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.participant && this.participant.id) {
            return `Edit External Source`;
        }

        return 'New External Source';
    }

    /**
     * Returns is name valid
     */
    isNameValid(): boolean {
        // return true;
        return (this.participant && this.participant.source_external_reference_id > 0);
    }

}
