import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TeamTemplateModel } from "..";
import { LoadTeamTemplates, TeamTemplateActionTypes, TeamTemplatesLoaded } from "../_actions";
import { AddTeamTemplate, DeleteTeamTemplate, DuplicateTeamTemplate, TeamTemplateAdded, TeamTemplateUpdated, UpdateTeamTemplate } from "../_actions";
import { TeamTemplateService } from "../_services";

@Injectable()
export class TeamTemplateEffects {
    constructor(
        private actions: Actions,
        private service: TeamTemplateService
    ) {}

    @Effect()
    loadTeamTemplate$ =
        this.actions.pipe(
            ofType<LoadTeamTemplates>(TeamTemplateActionTypes.LOAD_TEAM_TEMPLATES),
            mergeMap(({payload}) => {
                return this.service.getAll(payload.request)
            }),
            map(res => {
                return new TeamTemplatesLoaded({
                    templates: res.templates,
                    total: res.total
                });
            })
        )

    @Effect()
    addTeamTemplate$ =
        this.actions.pipe(
            ofType<AddTeamTemplate>(TeamTemplateActionTypes.ADD_TEAM_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.create(payload.template)
            }),
            map(res => {
                return new TeamTemplateAdded({
                    template: res
                })
            })
        )

    @Effect()
    updateTeamTemplate$ =
        this.actions.pipe(
            ofType<UpdateTeamTemplate>(TeamTemplateActionTypes.UPDATE_TEAM_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.update(payload.id, payload.template)
            }),
            map(res => {
                const newTemplate: Update<TeamTemplateModel> = {
                    id: res.id,
                    changes: {
                        ...res
                    }
                };
                return new TeamTemplateUpdated({
                    id: res.id,
                    template: newTemplate
                })
            })
        )

    @Effect()
    deleteTeamTemplate$ = 
        this.actions.pipe(
            ofType<DeleteTeamTemplate>(TeamTemplateActionTypes.DELETE_TEAM_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.delete(payload.id),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadTeamTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )

    @Effect()
    duplicateTeamTemplate$ =
        this.actions.pipe(
            ofType<DuplicateTeamTemplate>(TeamTemplateActionTypes.DUPLICATE_TEAM_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.duplicate(payload.template),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadTeamTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )
}
