// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ExternalWall} from '../_models/external-wall.model';

export enum ExternalWallActionTypes {

    AllExternalWallsRequested = '[ExternalWalls Home Page] All ExternalWalls Requested',
    AllExternalWallsLoaded = '[ExternalWalls API] All ExternalWalls Loaded',

    ExternalWallOnServerCreated = '[Edit ExternalWall Dialog] ExternalWall On Server Created',
    ExternalWallCreated = '[Edit ExternalWalls Dialog] ExternalWalls Created',
    ExternalWallUpdated = '[Edit ExternalWall Dialog] ExternalWall Updated',
    ExternalWallDeleted = '[ExternalWalls List Page] ExternalWall Deleted',

    ExternalWallsPageRequested = '[ExternalWalls List Page] ExternalWalls Page Requested',
    ExternalWallsPageLoaded = '[ExternalWalls API] ExternalWalls Page Loaded',
    ExternalWallsPageCancelled = '[ExternalWalls API] ExternalWalls Page Cancelled',

    ExternalWallsPageToggleLoading = '[ExternalWalls page] ExternalWalls Page Toggle Loading',
    ExternalWallsActionToggleLoading = '[ExternalWalls] ExternalWalls Action Toggle Loading',

    // trash system

    ExternalWallOnServerRestored = '[ExternalWall Trash] ExternalWall On Server Restored',
    ExternalWallRestored = '[ExternalWall Trash] ExternalWall Restored',

    ExternalWallOnServerAdminRestored = '[ExternalWall Admin Trash] ExternalWall On Server Restored',
    ExternalWallAdminRestored = '[ExternalWall Admin Trash] ExternalWall Restored',

    ExternalWallDeletedFromTrash = '[ExternalWall Trash] ExternalWall deleted',
    ExternalWallDeletedFromAdminTrash = '[ExternalWall Admin Trash] ExternalWall deleted',

    ExternalWallTrash = 'ExternalWall Trashed',
    ExternalWallTrashFlushed = 'ExternalWall Trash Flushed',
}

export class ExternalWallOnServerCreated implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallOnServerCreated;

    constructor(public payload: { externalWall: ExternalWall}) {
    }
}

export class ExternalWallCreated implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallCreated;

    constructor(public payload: { externalWall: ExternalWall }) {
    }
}

export class ExternalWallUpdated implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallUpdated;

    constructor(public payload: {
        partialExternalWall: Update<ExternalWall>,
        externalWall: ExternalWall
    }) {
    }
}

export class ExternalWallDeleted implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalWallsPageRequested implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ExternalWallsPageLoaded implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallsPageLoaded;

    constructor(public payload: { externalWalls: ExternalWall[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ExternalWallsPageCancelled implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallsPageCancelled;
}

export class AllExternalWallsRequested implements Action {
    readonly type = ExternalWallActionTypes.AllExternalWallsRequested;
}

export class AllExternalWallsLoaded implements Action {
    readonly type = ExternalWallActionTypes.AllExternalWallsLoaded;

    constructor(public payload: { externalWalls: ExternalWall[] }) {
    }
}

export class ExternalWallsPageToggleLoading implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ExternalWallsActionToggleLoading implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ExternalWallDeletedFromAdminTrash implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ExternalWallDeletedFromTrash implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalWallOnServerRestored implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalWallRestored implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallRestored;

    constructor(public payload: { item: ExternalWall }) {
    }
}

export class ExternalWallOnServerAdminRestored implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalWallAdminRestored implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallAdminRestored;

    constructor(public payload: { item: ExternalWall }) {
    }
}

export class ExternalWallTrashFlushed implements Action {
    readonly type = ExternalWallActionTypes.ExternalWallTrashFlushed;

    constructor() {
    }
}

export type ExternalWallActions = ExternalWallCreated
    | ExternalWallUpdated
    | ExternalWallDeleted
    | ExternalWallsPageRequested
    | ExternalWallsPageLoaded
    | ExternalWallsPageCancelled
    | AllExternalWallsLoaded
    | AllExternalWallsRequested
    | ExternalWallOnServerCreated
    | ExternalWallsPageToggleLoading
    | ExternalWallsActionToggleLoading
    | ExternalWallDeletedFromAdminTrash
    | ExternalWallDeletedFromTrash
    | ExternalWallOnServerRestored
    | ExternalWallRestored
    | ExternalWallOnServerAdminRestored
    | ExternalWallAdminRestored
    | ExternalWallTrashFlushed;
