// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
import {of, forkJoin} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {TpFinancialTaskService} from '../_services/tp-financial-task.service';
// Actions
import {
    AllTpFinancialTaskRequested,
    AllTpFinancialTaskLoaded,
    AllTpFinancialTaskLoadedByUser,
    TpFinancialTaskActionTypes,
    TpFinancialTaskActionToggleLoading,
    TpFinancialTaskCreated,
    TpFinancialTaskOnServerCreated,
    TpFinancialTaskUpdated,
    TpFinancialTaskDeleted,
    AllTpFinancialTaskByUserRequested,
    TpFinancialTaskByUserPageRequested,
    TpFinancialTaskByUserPageLoaded,
} from '../_actions/tp-financial-task.actions';
import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';

import {AppState} from '../../reducers';

@Injectable()
export class TpFinancialTaskEffects {

    showActionLoadingDispatcher = new TpFinancialTaskActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TpFinancialTaskActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTpFinancialTask$ = this.actions$
        .pipe(
            ofType<AllTpFinancialTaskRequested>(TpFinancialTaskActionTypes.AllTpFinancialTaskRequested),
            mergeMap(() => this.service.getAll()),
            map((result: any) => {
                return new AllTpFinancialTaskLoaded({
                    tasks: result.data
                });
            })
        );

    @Effect()
    createTpFinancialTask$ = this.actions$
        .pipe(
            ofType<TpFinancialTaskOnServerCreated>(TpFinancialTaskActionTypes.TpFinancialTaskOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.task).pipe(
                    tap(res => {
                        if (res) {
                            this.store.dispatch(new TpFinancialTaskCreated({
                                task: res.data
                            }));
                        }
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    deleteTpFinancialTask$ = this.actions$
        .pipe(
            ofType<TpFinancialTaskDeleted>(TpFinancialTaskActionTypes.TpFinancialTaskDeleted),
            mergeMap(({payload}) => {
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTpFinancialTask$ = this.actions$
        .pipe(
            ofType<TpFinancialTaskUpdated>(TpFinancialTaskActionTypes.TpFinancialTaskUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    AllTpFinancialTaskByUser$ = this.actions$
        .pipe(
            ofType<AllTpFinancialTaskByUserRequested>(TpFinancialTaskActionTypes.AllTpFinancialTaskByUserRequested),
            mergeMap(({payload}) => {
                const requestToServer = this.service.getByUserId(payload.page, payload.state, payload.filterModel);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map((response: any) => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AllTpFinancialTaskLoadedByUser({
                    tasks: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery,
                });
            })
        );

    @Effect()
    loadTpFinancialTaskByUserPage$ = this.actions$.pipe(
        ofType<TpFinancialTaskByUserPageRequested>(TpFinancialTaskActionTypes.TpFinancialTaskByUserPageRequested),
        mergeMap(({payload}) => {
            // this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getByUserId(payload.page, payload.state);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new TpFinancialTaskByUserPageLoaded({
                assetClasses: result.data,
                totalCount: result.pagination.total,
                page: lastQuery
            });
        })
    );

    constructor(private actions$: Actions, private service: TpFinancialTaskService, private store: Store<AppState>) {
    }
}
