// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
import {BuildingModel} from '../_models/building.model';

// Models

export enum BuildingActionTypes {
    BuildingOnServerCreated = '[Edit Building Dialog] Building On Server Created',
    BuildingCreated = '[Edit Building Dialog] Building Created',

    BuildingOnServerRestored = '[Building Trash] Building On Server Restored',
    BuildingRestored = '[Building Trash] Building Restored',

    BuildingOnServerAdminRestored = '[Building Admin Trash] Building On Server Restored',
    BuildingAdminRestored = '[Building Admin Trash] Building Restored',

    BuildingDeletedFromTrash = '[Building Trash] Building deleted',
    BuildingDeletedFromAdminTrash = '[Building Admin Trash] Building deleted',

    BuildingTrash = 'Building Trashed',
    BuildingTrashFlushed = 'Building Trash Flushed',

    BuildingUpdated = '[Edit Building Dialog] Building Updated',
    // BuildingsStatusUpdated = '[Building List Page] Buildings Status Updated',
    BuildingDeleted = '[Buildings List Page] Building Deleted',

    AllBuildingsRequested = '[Buildings] All Buildings Requested',

    BuildingsPageRequested = '[Buildings List Page] Buildings Page Requested',
    BuildingsPageLoaded = '[Buildings API] Buildings Page Loaded',

    OneBuildingRequest = '[Buildings API] One Building Request',
    OneBuildingLoaded = '[Buildings API] One Building Loaded',

    BuildingsPageCancelled = '[Buildings API] Buildings Page Cancelled',
    BuildingsPageToggleLoading = '[Buildings] Buildings Page Toggle Loading',
    BuildingActionToggleLoading = '[Buildings] Buildings Action Toggle Loading'
}

// list action
export class BuildingsPageRequested implements Action {
    readonly type = BuildingActionTypes.BuildingsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

// list action
export class AllBuildingsRequested implements Action {
    readonly type = BuildingActionTypes.AllBuildingsRequested;

    constructor() {
    }
}

export class BuildingsPageLoaded implements Action {
    readonly type = BuildingActionTypes.BuildingsPageLoaded;

    constructor(public payload: {
        buildings: BuildingModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {

    }
}


export class BuildingTrashRequested implements Action {
    readonly type = BuildingActionTypes.BuildingTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BuildingTrashFlushed implements Action {
    readonly type = BuildingActionTypes.BuildingTrashFlushed;

    constructor() {
    }
}

export class BuildingDeletedFromTrash implements Action {
    readonly type = BuildingActionTypes.BuildingDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingDeletedFromAdminTrash implements Action {
    readonly type = BuildingActionTypes.BuildingDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingOnServerRestored implements Action {
    readonly type = BuildingActionTypes.BuildingOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingRestored implements Action {
    readonly type = BuildingActionTypes.BuildingRestored;

    constructor(public payload: { building: BuildingModel }) {
    }
}

export class BuildingOnServerAdminRestored implements Action {
    readonly type = BuildingActionTypes.BuildingOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuildingAdminRestored implements Action {
    readonly type = BuildingActionTypes.BuildingAdminRestored;

    constructor(public payload: { building: BuildingModel }) {
    }
}


// toggle loading
export class BuildingsPageToggleLoading implements Action {
    readonly type = BuildingActionTypes.BuildingsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BuildingActionToggleLoading implements Action {
    readonly type = BuildingActionTypes.BuildingActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export class BuildingCreated implements Action {
    readonly type = BuildingActionTypes.BuildingCreated;

    constructor(public payload: { building: BuildingModel }) {
    }
}

export class BuildingOnServerCreated implements Action {
    readonly type = BuildingActionTypes.BuildingOnServerCreated;

    constructor(public payload: { building: BuildingModel, files: any[] }) {
    }
}

export class BuildingUpdated implements Action {
    readonly type = BuildingActionTypes.BuildingUpdated;

    constructor(public payload: {
        partialBuilding: Update<BuildingModel>,
        building: BuildingModel,
        files: any[]
    }) {
    }
}


export class BuildingDeleted implements Action {
    readonly type = BuildingActionTypes.BuildingDeleted;

    constructor(public payload: {
        id: number
    }) {
    }
}


export type BuildingActions = BuildingOnServerCreated |
    BuildingCreated |

    BuildingUpdated |
    BuildingDeleted |

    BuildingOnServerRestored |
    BuildingRestored |

    BuildingOnServerAdminRestored |
    BuildingAdminRestored |

    BuildingDeletedFromTrash |
    BuildingDeletedFromAdminTrash |

    BuildingTrashFlushed |

    AllBuildingsRequested |
    BuildingsPageRequested |

    BuildingTrashRequested |

    BuildingsPageToggleLoading |
    BuildingActionToggleLoading |
    BuildingsPageLoaded;
