import { ValuationLandmarkAction, ValuationLandmarkActionTypes } from "../_actions/valuation-landmark.actions";

export interface ValuationLandmarkState {
    landmarks: any[],
}

export const initialValuationLandmarkState: ValuationLandmarkState = {
    landmarks: []
}

export function valuationLandmarkReducer(
    state = initialValuationLandmarkState,
    action: ValuationLandmarkAction
): ValuationLandmarkState {
    switch (action.type) {
        case ValuationLandmarkActionTypes.UpdateValuationLandmarks:
            return {
                landmarks: action.payload.landmarks
            }
        case ValuationLandmarkActionTypes.LoadData: {
            return {
                landmarks: action.payload.landmarks
            }
        }
        default: 
            return state
    }
}