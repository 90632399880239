import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { SubCategoryUpdated, SubCategoryOnServerCreated } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { selectSubCategorysActionLoading, selectSubCategoryById, selectLastCreatedSubCategoryId } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { AppState } from 'src/app/core/reducers';
import { SubheaderService } from 'src/app/core/_base/layout';
import {Location} from '@angular/common';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import {combineLatest} from 'rxjs'

@Component({
  selector: 'kt-sub-category-edit',
  templateUrl: './sub-category-edit.component.html',
  styleUrls: ['./sub-category-edit.component.scss']
})
export class SubCategoryEditComponent implements OnInit {
    model: SubCategoryModel;
    oldModel: SubCategoryModel;

    subCategoryForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    readonly = false;
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    subTypeCategories: SubTypeCategoryModel[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location
     * @param subheaderService: SubheaderService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                public subheaderService: SubheaderService,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectSubCategorysActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectSubCategoryById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initSubCategory();
                    }
                });
            } else {
                this.model = new SubCategoryModel();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initSubCategory();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }

        this.store.dispatch(new AllSubTypeCategorysRequested())
        this.store.select(selectAllSubTypeCategorys).subscribe(res => {
          this.subTypeCategories = [];
          if (res) {
            this.subTypeCategories = res;
          }
        })
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Bases of Values', page: `../default/admin-management/linked-tables/base-of-values`},
            {
                title: this.getComponentTitle(),
                page: id > 0 ? `../default/admin-management/linked-tables/base-of-values/edit/${id}`
                    : `../default/admin-management/linked-tables/base-of-values/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initSubCategory() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.subCategoryForm = this.fb.group({
            name: [this.model.name, Validators.required],
            description: [this.model.description],
            sub_type_category_id: [this.model.sub_type_category_id, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return this.translate.instant('SUB_CATEGORY.FORM.TITLE.EDIT', {name: this.model.name});
        }

        return this.translate.instant('SUB_CATEGORY.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.subCategoryForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.model.description = this.oldModel.is_system_entry ? this.model.default_description : this.oldModel.description;
        this.createForm();
        this.hasFormErrors = false;
        this.subCategoryForm.markAsPristine();
        this.subCategoryForm.markAsUntouched();
        this.subCategoryForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.subCategoryForm.controls;
        /** check form */
        if (this.subCategoryForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const newModel = new SubCategoryModel();
        newModel.id = this.model.id;
        newModel.name = this.readonly ? this.model.name : this.subCategoryForm.controls.name.value;
        newModel.sub_type_category_id = this.readonly ? this.model.sub_type_category_id : this.subCategoryForm.controls.sub_type_category_id.value;
        newModel.description = this.subCategoryForm.controls.description.value;
        newModel.default_description = this.model.default_description;
        newModel.is_hidden = this.model.is_hidden
        newModel.is_system_entry = this.model.is_system_entry;
        if (this.model.id > 0) {
            this.updateSubCategory(newModel);
        } else {
            this.createSubCategory(newModel);
        }
    }

    /**
     * Update model
     *
     * @param _model: SubCategoryModel
     */
    updateSubCategory(_model: SubCategoryModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateSubCategory: Update<SubCategoryModel> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new SubCategoryUpdated({
            partialItem: updateSubCategory,
            item: _model
        }));
        this.oldModel = _model;

        this.navigateToParent();

    }

    /**
     * Create model
     *
     * @param _model: SubCategoryModel
     */
    createSubCategory(_model: SubCategoryModel) {
        this.store.dispatch(new SubCategoryOnServerCreated({item: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedSubCategoryId),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0 && this.subCategoryForm.controls.sub_type_category_id.value);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }


    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name && this.model.sub_type_category_id != this.oldModel.sub_type_category_id && this.model.description != this.oldModel.description;
        }
        return false;
    }
}
