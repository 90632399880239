import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpUtilsService} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import {ToeInvoice} from '../_models/toe-invoice.model';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class InvoiceService {
    API_INVOICE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/invoice`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    // CREATE =>  POST: add a new toeReport to the server
    updateStatus(paramObj: any): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_INVOICE_URL + '/update', paramObj, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the toeReport on the server
    generateReport(paramObj: any): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        // return this.http.post(this.API_INVOICE_URL + 'generate', paramObj, {headers: httpHeaders});

        const req = new HttpRequest(
            'POST',
            this.API_INVOICE_URL + '/generate',
            paramObj,
            {   
                responseType: 'blob',
                headers: httpHeaders
            }
          );
        return this.http.request(req);
    }

    createToeInvoicePayment(body: {
        toe: ToeInvoice,
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_INVOICE_URL, body, {headers: httpHeaders});
    }

    // UPDATE =>  PUT: update the client to the server
    updateToeInvoicePayment(payment: ToeInvoice
    ): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_INVOICE_URL + '/' + payment.id, payment, {headers: httpHeaders});
    }

    // DELETE => delete the toe from the server
    deleteToeInvoicePayment(id: number): Observable<any> {
        const url = `${this.API_INVOICE_URL}/${id}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url, {headers: httpHeaders});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
