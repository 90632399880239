import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { selectAgencyInvolvementsWithClient, selectAgencyInvolvmentWithTps, selectLeadValuerInvolvementsWithClient, selectLvInvolvementsWithTp } from 'src/app/core/toe/_selectors/toe-conflicts.selectors';

@Component({
  selector: 'kt-toe-conflicts-modal',
  templateUrl: './toe-conflicts-modal.component.html',
  styleUrls: ['./toe-conflicts-modal.component.scss']
})
export class ToeConflictsModalComponent implements OnInit {

  lvInvolvementVm$ = this.store$.select(
    selectLeadValuerInvolvementsWithClient
  ).pipe(map(({lead_valuer, client, involvements}) => {
    if (involvements.length == 0) {
      return null
    }

    let title = ''
    const dataSource = new MatTableDataSource()
    dataSource.data = involvements
    if (lead_valuer != null && client != null) {
      title = `List of previous involvement(s) between Lead Valuer ${lead_valuer.full_name} and the Client ${client.name}`
    }

    return {
      title,
      dataSource
    }
  }))

  agencyInvolvementVm$ = this.store$.select(
    selectAgencyInvolvementsWithClient
  ).pipe(map(({agency, client, involvements}) => {
    if (involvements.length == 0) {
      return null
    }

    let title = ''
    if (agency != null && client != null) {
      title = `List of previous involvement(s) between Agency ${agency.name} and the Client ${client.name}`
    }

    const dataSource = new MatTableDataSource()
    dataSource.data = involvements

    return { title, dataSource }
  }))

  lvInvolvementTpsVm$ = this.store$.select(
    selectLvInvolvementsWithTp
  ).pipe(map(involvements => {
    if (involvements.length == 0) {
      return null
    }

    const data = involvements.map(item => {
      return {
        current_tp: item.current_tp_name,
        matching_tp: item.matching_tp_name,
        distance: item.distance,
        floor: item.floor != null ? `${item.floor} ${item.floor_numbering}` : 'N/A',
        toe: item.toe_name != null ? item.toe_name : 'N/A',
        agency: item.agency_name != null ? item.agency_name : 'N/A',
        date: item.toe_signing_date
      }
    })
    const dataSource = new MatTableDataSource()
    dataSource.data = data;

    return {
      title: 'List of properties similar to previous Lead Valuer involvement',
      dataSource
    }
  }))

  agencyInvolvementTpsVm$ = this.store$.select(
    selectAgencyInvolvmentWithTps
  ).pipe(map(involvements => {
    if (involvements.length == 0) {
      return null
    }

    const data = involvements.map(item => {
      return {
        current_tp: item.current_tp_name,
        matching_tp: item.matching_tp_name,
        distance: item.distance,
        floor: item.floor != null ? `${item.floor} ${item.floor_numbering}` : 'N/A',
        toe: item.toe_name != null ? item.toe_name : 'N/A',
        lead_valuer: item.lead_valuer_name,
        date: item.toe_signing_date
      }
    })
    const dataSource = new MatTableDataSource()
    dataSource.data = data;

    return {
      title: 'List of properties similar to previous Agency involvement',
      dataSource
    }
  }))

  constructor(
    private store$: Store<AppState>,
    public dialogRef: MatDialogRef<ToeConflictsModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }
}
