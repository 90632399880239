// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
// Models
import {AssetClassModel} from '../_models/asset-class.model';
import {ValuationModel} from '../_models/valuation.model';
import {environment} from '../../../../environments/environment';
import {AssetClassDefaultAssumptionModel} from '../_models/asset-class-default-assumption.model';
import {AssetClassSpecialAssumptionModel} from '../_models/asset-class-special-assumption.model';
import {AssetClassDepartureModel} from '../_models/asset-class-departure.model';
import {AssetClassSourceExternalReferenceModel} from '../_models/asset-class-source-external-reference.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';
// import 'googlemaps';

const TP_PATH = '/asset-classes';

@Injectable()
export class AssetClassesService {
    API_TOE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    findAssetClasses(queryParams: QueryParamsModel, toeId: number): Observable<QueryResultsModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = `${this.API_TOE_URL}/` + toeId + TP_PATH;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getApartments(queryParams: QueryParamsModel): Observable<any> {


        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
        };


        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_TOE_URL + TP_PATH + '/data';
        return this.http.post<any>(url, _body, {headers: httpHeaders});
    }

    createAssetClass(_assetClass: AssetClassModel,
                     _valuations: ValuationModel[],
                     _defaultAssumptions: AssetClassDefaultAssumptionModel[],
                     _specialAssumptions: AssetClassSpecialAssumptionModel[],
                     _departures: AssetClassDepartureModel[],
                     _ser: AssetClassSourceExternalReferenceModel[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            assetClass: _assetClass,
            valuations: _valuations,
            defaultAssumptions: _defaultAssumptions,
            specialAssumptions: _specialAssumptions,
            departures: _departures,
            ser: _ser,
        };
        const url = this.API_TOE_URL + TP_PATH;
        return this.http.post<any>(url, body, {headers: httpHeaders});
    }

    updateAssetClass(_assetClass: AssetClassModel,
                     _valuations: ValuationModel[],
                     _defaultAssumptions: AssetClassDefaultAssumptionModel[],
                     _specialAssumptions: AssetClassSpecialAssumptionModel[],
                     _departures: AssetClassDepartureModel[],
                     _ser: AssetClassSourceExternalReferenceModel[]): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            assetClass: _assetClass,
            valuations: _valuations,
            defaultAssumptions: _defaultAssumptions,
            specialAssumptions: _specialAssumptions,
            departures: _departures,
            ser: _ser,
        };
        const url = this.API_TOE_URL + TP_PATH;
        return this.http.put(url + `/${_assetClass.id}`, body, {headers: httpHeader});
    }


    deletedAssetClassValuation(_assetClassId: number,
                               _valuationId: number): Observable<any> {

        const url = this.API_TOE_URL + TP_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(url + `/${_assetClassId}/${_valuationId}`, {headers: httpHeaders});
    }

    // Trash
    getTrashedAssetClasses(): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/trash`, {headers: httpHeaders});
    }

    // admin Trash
    getAdminTrashedAssetClasses(assetClassId: number): Observable<any> {
        const url = this.API_TOE_URL + assetClassId + TP_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/admin/trash`, {headers: httpHeaders});
    }

    // Trash
    // delete from trash
    deleteFromTrash(assetClassId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_TOE_URL + TP_PATH + `/trash/${assetClassId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(assetClassId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_URL + TP_PATH + `/trash/${assetClassId}`, {headers: httpHeaders});
    }

    deleteAssetClass(assetClassId: number): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH + `/${assetClassId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(url, {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_URL + TP_PATH + '/trash/flush', {headers: httpHeaders});
    }

    getAssetClass(assetClassId: number): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<AssetClassModel>(url + `/${assetClassId}`, {headers: httpHeaders});
    }

    getAssetClassWithAllValuations(assetClassId: number): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<AssetClassModel>(url + `/${assetClassId}?with=valuations`, {headers: httpHeaders});
    }

    getBuildingInfos(): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH + `/building-informations`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getTenuresOfAc(id: number): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH + `/${id}/tenures`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getSizesOfAc(id: number): Observable<any> {
        const url = this.API_TOE_URL + TP_PATH + `/${id}/sizes`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }


    getApartmentsByLocation(latLng1: google.maps.LatLng, latLng2: google.maps.LatLng): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            max_lng: latLng2.lng(),
            max_lat: latLng2.lat(),
            min_lng: latLng1.lng(),
            min_lat: latLng1.lat(),
        };
        const url = this.API_TOE_URL + TP_PATH;
        return this.http.post(url + `/map`, body, {headers: httpHeaders});
    }
}
