// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Models
import {ResidentialType} from '../_models/residential-type.model';
import {QueryParamsModel} from '../../_base/crud';
import {ResidentialTypeActions, ResidentialTypeActionTypes} from '../_actions/residential-type.actions';

export interface ResidentialTypesState extends EntityState<ResidentialType> {
    isAllResidentialTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ResidentialType[];
    lastCreatedResidentialTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ResidentialType> = createEntityAdapter<ResidentialType>();

export const initialResidentialTypesState: ResidentialTypesState = adapter.getInitialState({
    isAllResidentialTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedResidentialTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function residentialTypesReducer(state = initialResidentialTypesState, action: ResidentialTypeActions): ResidentialTypesState {
    switch (action.type) {
        case ResidentialTypeActionTypes.ResidentialTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedResidentialTypeId: undefined
            };
        case ResidentialTypeActionTypes.ResidentialTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ResidentialTypeActionTypes.ResidentialTypeOnServerCreated:
            return {
                ...state
            };
        case ResidentialTypeActionTypes.ResidentialTypeCreated:
            return adapter.addOne(action.payload.residentialType, {
                ...state, lastCreatedResidentialTypeId: action.payload.residentialType.id
            });
        case ResidentialTypeActionTypes.ResidentialTypeUpdated:
            return adapter.updateOne(action.payload.partialResidentialType, state);
        case ResidentialTypeActionTypes.AllResidentialTypesLoaded:
            return adapter.addAll(action.payload.residentialTypes, {
                ...state, isAllResidentialTypesLoaded: true
            });
        case ResidentialTypeActionTypes.ResidentialTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ResidentialTypeActionTypes.ResidentialTypesPageLoaded:
            return adapter.addMany(action.payload.residentialTypes, {
                ...initialResidentialTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case ResidentialTypeActionTypes.ResidentialTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ResidentialTypeActionTypes.ResidentialTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ResidentialTypeActionTypes.ResidentialTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ResidentialTypeActionTypes.ResidentialTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ResidentialTypeActionTypes.ResidentialTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ResidentialTypeActionTypes.ResidentialTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
