// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {CubicContentsService} from '../_services/cubic-content.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allCubicContentsLoaded} from '../_selectors/cubic-content.selectors';
// Actions
import {
    AllCubicContentsLoaded,
    AllCubicContentsRequested,
    CubicContentActionTypes,
    CubicContentsPageRequested,
    CubicContentsPageLoaded,
    CubicContentUpdated,
    CubicContentsPageToggleLoading,
    CubicContentDeleted,
    CubicContentOnServerCreated,
    CubicContentCreated,
    CubicContentAdminRestored,
    CubicContentDeletedFromAdminTrash,
    CubicContentDeletedFromTrash,
    CubicContentOnServerAdminRestored,
    CubicContentOnServerRestored,
    CubicContentRestored,
    CubicContentTrashFlushed,
    CubicContentsActionToggleLoading
} from '../_actions/cubic-content.actions';

@Injectable()
export class CubicContentEffects {
    showPageLoadingDispatcher = new CubicContentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new CubicContentsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CubicContentsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllCubicContents$ = this.actions$
        .pipe(
            ofType<AllCubicContentsRequested>(CubicContentActionTypes.AllCubicContentsRequested),
            withLatestFrom(this.store.pipe(select(allCubicContentsLoaded))),
            filter(([action, isAllCubicContentsLoaded]) => !isAllCubicContentsLoaded),
            mergeMap(() => this.service.getAllCubicContents()),
            map(res => {
                return new AllCubicContentsLoaded({cubicContents: res.data});
            })
        );

    @Effect()
    loadCubicContentsPage$ = this.actions$
        .pipe(
            ofType<CubicContentsPageRequested>(CubicContentActionTypes.CubicContentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findCubicContents(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new CubicContentsPageLoaded({
                    cubicContents: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentDeleted>(CubicContentActionTypes.CubicContentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteCubicContent(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentUpdated>(CubicContentActionTypes.CubicContentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateCubicContent(payload.cubicContent);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentOnServerCreated>(CubicContentActionTypes.CubicContentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createCubicContent(payload.cubicContent).pipe(
                    tap(res => {
                        this.store.dispatch(new CubicContentCreated({cubicContent: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushCubicContentTrash$ = this.actions$
        .pipe(
            ofType<CubicContentTrashFlushed>(CubicContentActionTypes.CubicContentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentOnServerRestored>(CubicContentActionTypes.CubicContentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CubicContentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentOnServerAdminRestored>(CubicContentActionTypes.CubicContentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CubicContentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentDeletedFromTrash>(CubicContentActionTypes.CubicContentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashCubicContent$ = this.actions$
        .pipe(
            ofType<CubicContentDeletedFromAdminTrash>(CubicContentActionTypes.CubicContentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: CubicContentsService, private store: Store<AppState>) {
    }
}
