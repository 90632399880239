import { ElementRef, Input, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import {
  AssetClassSourceExternalReferenceModel,
  TpFileDeleted,
  TpFileModel,
  TpFileOnServerCreated,
  TpFileOnServerUpdated,
  TpTaskModel,
} from 'src/app/core/asset_class';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { FileUploadService } from 'src/app/core/file-upload';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { Globals } from 'src/app/core/_base/crud/utils/globals.service';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { UploadFileComponent } from 'src/app/views/pages/shared_components/upload-file/upload-file.component';
import { environment } from 'src/environments/environment';
import * as fromFileUploadState from '../../../../../../core/file-upload/_states/file-upload.state';
import { TpFileInfoComponent } from '../../tp-file-info/tp-file-info.component';

@Component({
  selector: 'kt-user-uploaded',
  templateUrl: './user-uploaded.component.html',
  styleUrls: ['./user-uploaded.component.scss'],
})
export class UserUploadedComponent implements OnInit, OnDestroy {
  @Input() tpState: number;
  @Input() folderOnEditMode: string;
  @Input() oldFiles: BehaviorSubject<TpFileModel[]>;
  @Input() readOnly: boolean;
  @Input() fileType = '';
  @Input() parentId: number;
  @Input() fileCategory: BehaviorSubject<
    AssetClassSourceExternalReferenceModel[]
  >;
  @Input() descriptionField = false;
  @Input() fileInput: ElementRef<HTMLInputElement>;
  @Input() callUpload$: BehaviorSubject<number>;

  @Input() isInProgressSub: BehaviorSubject<boolean>;
  @Input() progressSub: BehaviorSubject<number>;
  @Input() hasFailedSub: BehaviorSubject<boolean>;
  @Input() errorSub: BehaviorSubject<string>;
  @Input() cancelReq: Subject<void>;
  @Input() toeStatus: number;

  @ViewChild('userUploadedRows', { static: true }) template;

  fileName: string;
  fileFolder: string;
  uploadFiles: TpFileModel[];
  docs: string[] = [];
  basePath: string;
  currentUser: User;
  tpTasks: TpTaskModel[] = [];

  dataSource$: BehaviorSubject<TpFileModel[]> = new BehaviorSubject([]);

  _categories: AssetClassSourceExternalReferenceModel[] = [];
  _obsTpFile: Observable<TpFileModel[]>;

  protected _onDestroy = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    private global: Globals,
    private store$: Store<fromFileUploadState.State>,
    private fileUploadService: FileUploadService,
    private layoutUtilsService: LayoutUtilsService,
    public typesUtilsService: TypesUtilsService,
    private store: Store<AppState>
  ) {
    this.fileFolder = '';
    this.uploadFiles = [];
    this.docs = ['xlsx', 'xls', 'doc', 'docx'];
    this.basePath = environment.baseApiUrl;
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);

    if (this.fileType === '') {
      this.fileType = 'application/pdf';
    }
    this.resetUpload();

    this.dataSource$.next(this.calculateCatCnt(this.uploadFiles));

    this._obsTpFile = this.oldFiles.asObservable();
    this._obsTpFile.subscribe((result) => {
      this.uploadFiles = this.calculateCatCnt(result);
      this.dataSource$.next(this.uploadFiles);
    });

    this.fileName = '';

    this.fileCategory.asObservable().subscribe((result) => {
      this._categories = result;
    });
    this.store.pipe(take(1), select(currentUser)).subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // Actions
  deleteUploadedFile(obj) {
    const _title = 'Are you sure?';
    const _description = 'The File "' + obj.cat_name + '" will be deleted';
    const _waitDesciption = 'ToE deleting';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(
        new TpFileDeleted({
          id: obj.id,
        })
      );
    });
  }

  previewUploadedFile(obj) {
    if (this.docs.indexOf(this.getFileType(obj.name)) > -1) {
      window.open(this.basePath + obj.path + '/' + obj.name);
    } else {
      this.dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: obj.path + '/' + obj.name,
          type: this.getFileType(obj.name),
        },
      });
    }
  }

  editFileInfo(obj: TpFileModel) {
    const _title = 'Adding Information';

    const dialogRef = this.dialog.open(TpFileInfoComponent, {
      data: {
        title: _title,
        categories: this._categories,
        model: obj,
      },
      width: '440px',
      position: { top: '50px', right: '50px' },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const _item = res as TpFileModel;
      const updateTpFile: Update<TpFileModel> = {
        id: res.id,
        changes: _item,
      };

      this.store.dispatch(
        new TpFileOnServerUpdated({
          tp_id: _item.id,
          partialTpFile: updateTpFile,
          tpFile: _item,
        })
      );
    });
  }

  addFile(_file: TpFileModel, index) {
    // this.addingItemCategory = _file ? _file.cat_id : 0;
    //     this.activeRowIndex = index;
    this.fileInput.nativeElement.click();
    this.callUpload$.next(2);
  }

  uploadFile(event: any) {
    this.hasFailedSub.next(false);
    this.errorSub.next('');
    const files: FileList = event.target.files;
    let wrongExt = false;

    Array.from(files).forEach((file, i) => {
      const ext = file.name.split('.').pop().toLowerCase();
      if (!UploadFileComponent.fileTypes.find((e) => e === ext)) {
        wrongExt = true;
      }
    });
    if (wrongExt) {
      alert(
        `Please upload "${UploadFileComponent.fileTypes.join(
          ', '
        )}" files only.`
      );
      return;
    }

    const firstFile = files.item(0);
    // this.fileName = firstFile.name;

    const formData = new FormData();
    formData.append('file', firstFile, firstFile.name);
    formData.append('path', 'tp/additional-doc');

    this.fileUploadService
      .upload2path(formData)
      .pipe(
        takeUntil(this.cancelReq),
        catchError((error) => throwError(error))
      )
      .subscribe(
        (res) => {
          if (!res) {
            return;
          }

          if (res.status === 'sent') {
            this.isInProgressSub.next(true);
            this.progressSub.next(0);
            return;
          }

          if (res.status === 'progress') {
            this.progressSub.next(res.message);
            return;
          }
          if (res.status === 'error') {
            this.isInProgressSub.next(false);
            this.hasFailedSub.next(true);
            this.errorSub.next('Too large file. Max file upload is: 16mb.');
            return;
          }

          if (res.status == 'done') {
            const _model = new TpFileModel();
            _model.clear();
            _model.tp_id = this.parentId;
            _model.is_additional = true;
            _model.path = res.path;
            _model.name = res.name;
            this.isInProgressSub.next(false);
            this.showDialog(_model);
          }
        },
        (err) => {
          this.isInProgressSub.next(false);
          this.hasFailedSub.next(true);
          this.errorSub.next('Too large file. Max file upload is: 16mb.');
        }
      );

    // clear the input form
    event.srcElement.value = null;
  }
  showDialog(obj) {
    const dialogRef = this.dialog.open(TpFileInfoComponent, {
      data: {
        title: 'Adding Information',
        model: obj,
        categories: this._categories,
      },
      width: '440px',
      position: { top: '50px', right: '50px' },
    });
    dialogRef.afterClosed().subscribe((dialogRes) => {
      if (!dialogRes) {
        return;
      }

      const _item = dialogRes as TpFileModel;
      this.store.dispatch(
        new TpFileOnServerCreated({
          tp_id: dialogRes.tp_id,
          tp_file: _item,
        })
      );
    });
  }

  // Helpers
  resetUpload() {
    this.fileFolder = '';
    this.fileName = '';
    this.isInProgressSub.next(false);
    this.cancelReq.next();
    this.cancelReq.complete();
  }

  cancelUpload() {
    this.fileFolder = '';
    this.fileName = '';
    this.isInProgressSub.next(false);
    this.cancelReq.next();
    this.cancelReq.complete();
  }

  calculateCatCnt(param: TpFileModel[]) {
    const _sortedData = Object.assign([], param);
    _sortedData.sort((a, b) => {
      if (a.cat_name === b.cat_name) {
        return 0;
      } else if (a.cat_name > b.cat_name) {
        return -1;
      }
      return 1;
    });

    // const _sortedData = _.sortBy(param, 'cat_name');
    const _res = [];
    let _currCat = '';
    let _currCnt = 0;
    _sortedData.forEach((el) => {
      if (_currCat != el.cat_name) {
        _currCnt = 1;
      } else {
        _currCnt++;
      }
      const _tmpObj = Object.assign({}, el) as TpFileModel;
      _tmpObj.cat_cnt = _currCnt;
      _currCat = _tmpObj.cat_name;
      _res.push(_tmpObj);
    });
    return _res;
  }

  getFileType(name) {
    return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
  }
}
