import {
    Component, OnDestroy, OnInit, ViewChild,
    Input, ElementRef
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutConfigService, SubheaderService} from '../../../../core/_base/layout';
import {LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
import {AppState} from '../../../../core/reducers';
import {TranslateService} from '@ngx-translate/core';
import {
    AcSource
} from '../../../../core/comparable';
import {
    SourceType, SourceCredibility, SourceInformation, selectAllSourceCredibility, AllSourceCredibilityRequested,
    AllSourceInformationRequested, AllSourceTypesRequested, selectAllSourceTypes
} from '../../../../core/linked-tables';
import {selectAllSourceInformation} from '../../../../core/linked-tables';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {FileUploadService} from '../../../../core/file-upload/_services';
import {ImageViewerDialogComponent} from '../image_viewer/image-viewer.dialog.component';
import { awConst } from 'src/app/app.constants';
import { Moment, MomentInputObject } from 'moment';
import * as moment from 'moment';

@Component({
    selector: 'kt-source-info',
    templateUrl: './source.component.html',
    styleUrls: ['./source.component.scss'],
})
export class SourceComponent implements OnInit, OnDestroy {
    private _leaseStartDate$ = new BehaviorSubject<Date|string|null>(null)
    @Input() 
    set leaseStartDate(val: Date | string | null) {
        this._leaseStartDate$.next(val)
    }

    @Input() otherDates: string[] = [];
    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() sourceSubject: BehaviorSubject<AcSource>;
    @Input() isComplexForm: boolean = true;

    @ViewChild('fileInput') fileInput: ElementRef;
    awConst = awConst;
    selectedFileType: string;
    progress$: Observable<number>;
    progressSubject = new BehaviorSubject<number>(0);
    error: any;

    displayedColumns = ['type', 'name', 'file_name', 'actions'];
    dataSource = new MatTableDataSource();

    sourceFileRows = [
        {
            name: 'Source picture',
            type: 'jpeg',
            field: 'source_picture_1'
        },
        {
            name: 'Source picture',
            type: 'jpeg',
            field: 'source_picture_2'
        }
    ];

    sourceForm: UntypedFormGroup;
    hasFormErrors = false;
    // linked tables
    sourceTypes: SourceType[] = [];
    sourceCredibility: SourceCredibility[] = [];
    sourceInformation: SourceInformation[] = [];

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
        // this.saveState = false;
    }

    dateFilter = (d: Moment): boolean => {
        if (d == null) {
            return false;
        }
        const time = d.unix();
        return !this.otherDates.find(x => moment(x).unix() == time);
    }

    ngOnInit() {

        this.dataSource = new MatTableDataSource(this.sourceFileRows);
        this.progress$ = this.progressSubject.asObservable()

        if (!this.sourceSubject.value) {
            const acSource = new AcSource();
            acSource.clear();
            this.sourceSubject.next(acSource);
            this.sourceForm.valueChanges
                .pipe(
                    debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                    distinctUntilChanged(), // This operator will eliminate duplicate values
                    tap(() => {
                        this.updateSource();
                    })
                )
                .subscribe();
        }

        this.store.dispatch(new AllSourceCredibilityRequested());

        const sourceCredibilitySubscription = this.store.pipe(
            select(selectAllSourceCredibility))
            .subscribe(res => {
                this.sourceCredibility = [];
                if (res) {
                    this.sourceCredibility = res;
                }
            });

        this.subscriptions.push(sourceCredibilitySubscription);


        this.store.dispatch(new AllSourceInformationRequested());

        const sourceInformationSubscription = this.store.pipe(
            select(selectAllSourceInformation))
            .subscribe(res => {
                this.sourceInformation = [];
                if (res) {
                    this.sourceInformation = res;
                }
            });

        this.subscriptions.push(sourceInformationSubscription);


        this.store.dispatch(new AllSourceTypesRequested());

        const sourceTypesSubscription = this.store.pipe(
            select(selectAllSourceTypes))
            .subscribe(res => {
                this.sourceTypes = [];
                if (res) {
                    this.sourceTypes = res;
                }
            });

        this.subscriptions.push(sourceTypesSubscription);


        this.createForm();

        const leaseStartDateSub = this._leaseStartDate$.subscribe(value => {
            if (value) {
                this.sourceForm.controls.source_type_id.patchValue(1)
                this.sourceForm.controls.source_type_id.disable()
                this.sourceForm.controls.transaction_date.patchValue(value)
                this.sourceForm.controls.transaction_date.disable()
            } else {
                this.sourceForm.controls.source_type_id.patchValue(null)
                this.sourceForm.controls.source_type_id.enable()
                this.sourceForm.controls.transaction_date.patchValue(null)
                this.sourceForm.controls.transaction_date.enable()
            }
            this.sourceForm.controls.source_type_id.updateValueAndValidity()
            this.sourceForm.controls.transaction_date.updateValueAndValidity()
        })
        this.subscriptions.push(leaseStartDateSub)
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    createForm() {
        this.sourceForm = this.fb.group({
            source_information_id: [
                this.sourceSubject.value.source_information_id, Validators.required],
            source_type_id: [
                this.sourceSubject.value.source_type_id, Validators.required],
            source_credibility_id: [
                this.sourceSubject.value.source_credibility_id , Validators.required],
            validation_source: [this.sourceSubject.value.validation_source],
            web_address: [this.sourceSubject.value.web_address
                ? this.addProtocol(this.sourceSubject.value.web_address)
                : ''],
            information_date: [this.sourceSubject.value.information_date, Validators.required],
            transaction_date: [this.sourceSubject.value.transaction_date]
        });

        if (this.sourceSubject.value.source_type_id) {
            this.selectType({value: this.sourceSubject.value.source_type_id});
        }
        this.subscriptions.push(
            this.sourceForm.controls.source_type_id.valueChanges.subscribe(value => {
                if (value == 1) {
                    this.sourceForm.controls.transaction_date.setValidators([Validators.required]);
                    this.sourceForm.controls.information_date.clearValidators();
                } else {
                    this.sourceForm.controls.transaction_date.clearValidators();
                    this.sourceForm.controls.information_date.setValidators([Validators.required]);
                }
                this.sourceForm.controls.transaction_date.updateValueAndValidity();
                this.sourceForm.controls.information_date.updateValueAndValidity();
            })
        )
    }

    selectType(ev) {
    }

    updateSource(isComplete: boolean = true) {
        this.loadingSubject.next(true);
        this.hasFormErrors = false;
        const controls = this.sourceForm.controls;
        /** check form */
        if (isComplete && this.sourceForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            this.hasFormErrors = true;
            this.loadingSubject.next(false);

            return;
        }

        const acSource = new AcSource();
        acSource.clear();
        acSource.id = this.sourceSubject.value.id;
        acSource.source_information_id = controls.source_information_id.value;
        acSource.source_type_id = controls.source_type_id.value;
        const sourceType = this.sourceTypes.find(s => s.id == acSource.source_type_id);
        acSource.source_type_name = sourceType ? sourceType.name : 'Unknown' ;
        acSource.source_credibility_id = controls.source_credibility_id.value;
        acSource.validation_source = controls.validation_source.value;
        acSource.web_address = this.removeProtocol(controls.web_address.value);
        acSource.source_picture_1 = this.sourceSubject.value.source_picture_1;
        acSource.source_picture_2 = this.sourceSubject.value.source_picture_2;

        if (controls.source_type_id.value === 1) {
            const _date = new Date(controls.transaction_date.value);
            if (controls.transaction_date.value) {
                acSource.transaction_date = this.typesUtilsService.dateFormat(_date);
            } else {
                acSource.transaction_date = null;
            }
        } else {
            const _date = new Date(controls.information_date.value);
            if (controls.information_date.value) {
                acSource.information_date = this.typesUtilsService.dateFormat(_date);
            } else {
                acSource.information_date = null;
            }
        }
        this.sourceSubject.next(acSource);
        this.loadingSubject.next(false);
    }

    private setNullWhenUnknown(value: number): number {
        if (value === -1) {
            return null;
        }
        return value;
    }

    addFiles(type) {
        this.fileInput.nativeElement.click();
        this.selectedFileType = type;
    }


    // upload
    uploadFile(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            this.upload(file, file.name, this.selectedFileType);
        }
    }

    upload(file: File, fname: string, path: string) {
        const formData = new FormData();
        formData.append('file', file, fname);
        formData.append('path', 'comparable/source_pictures/');

        this.progressSubject.next(1);
        this.fileUploadService.upload2path(formData).subscribe(
            (res) => {
                if (res && res.status) {
                    if (res.status === 'progress') {
                        this.progressSubject.next(res.message);
                    }
                    if (res.status === 'done') {
                        const acSource = Object.assign({}, this.sourceSubject.value);
                        acSource[this.selectedFileType] = res.filePath;
                        this.sourceSubject.next(acSource);
                        this.fileInput.nativeElement.value = '';
                        this.progressSubject.next(0);
                    }
                }
            },
            (err) => this.error = err
        );
    }

    deleteUploadedFile(field) {
        const acSource = this.sourceSubject.value;
        acSource[field] = '';
        this.sourceSubject.next(acSource);
    }

    previewUploadedFile(type) {
        this.dialog.open(ImageViewerDialogComponent, {
            data: {
                picture: this.sourceSubject.value[type],
                type: 'jpg'
            }
        });
    }

    setIcon(type) {
        let ext = 'doc';
        switch (type) {
            case 'png':
                ext = 'png';
                break;
            case 'jpeg':
                ext = 'jpg';
                break;
            case 'jpg':
                ext = 'jpg';
                break;
            case 'gif':
                ext = 'gif';
                break;
            case 'pdf':
                ext = 'pdf';
                break;
            case 'xls':
                ext = 'xls';
                break;
            case 'xlsx':
                ext = 'xls';
                break;
            case 'rtf':
                ext = 'doc';
                break;
            case 'doc':
                ext = 'doc';
                break;
            case 'docx':
                ext = 'doc';
                break;
            case 'mp3':
                ext = 'mp3';
                break;
        }
        return './assets/media/files-alt/' + ext + '.svg';
    }

    getFileType(name) {
        return name.substring(name.indexOf('.') + 1, name.length);
    }

    getFileName(name) {
        return (this.sourceSubject.value[name] && this.sourceSubject.value[name].length > 0) ? this.sourceSubject.value[name].split('/').pop().replace(/^\d+_/, '') : awConst.NOFILE;
    }


    valid() {
        const controls = this.sourceForm.controls;
        /** check form */
        if (this.sourceForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                    if (controls[controlName].invalid) {
                        console.log(controlName)
                    }
                }
            );
            return false;
        }
        // check picture
        return true;
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }

    // Removes protocol from web address if there is a protocol
    // or return web address itself if there is no protocol
    private removeProtocol(address: string): string {
        const http = address.substring(0, 7).toLowerCase();
        const https = address.substring(0, 8).toLowerCase();
        if (http === 'http://') {
            return address.substr(7);
        } else if (https === 'https://') {
            return address.substr(8);
        } else {
            return address;
        }
    }

    // Add http protocol to web address
    private addProtocol(address: string): string {
        return 'http://' + address;
    }
}
