<div class="d-flex flex-column">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <a href="javascript:;"
            *ngIf="!readonly"
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            (click)="addItem()"
            matTooltip="Add additional cost">
            <span class="kt-hidden-mobile"> Add additional cost</span>
        </a>
    </div>

    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                <mat-cell class="pl-0" *matCellDef="let item; let i = index">
                    <mat-form-field 
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never">
                            <input matInput placeholder="Title"
                            [ngModel]="item.name"
                            (blur)="change($event, i, 'name')">
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="asset_class_id">
                <mat-header-cell *matHeaderCellDef> Target Property</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <mat-form-field>
                        <mat-select style="min-width: 200px;" [ngModel]="item.asset_class_name" (selectionChange)="onOptionsSelected($event, i, 'qty')">
                            <mat-option *ngFor="let targetProperty of targetProperties" [value]="targetProperty.name">{{targetProperty.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="qty">
                <mat-header-cell *matHeaderCellDef> Qty</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <mat-form-field 
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never">
                            <input matInput placeholder="Qty"
                            [ngModel]="item.qty"
                            mask="separator"
                            thousandSeparator=","
                            (blur)="change($event, i, 'qty')">
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef>Unit Price</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <mat-form-field 
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never">
                            <input matInput placeholder="Amount"
                            [ngModel]="item.amount"
                            mask="separator"
                            thousandSeparator=","
                            prefix="{{currencySubject.value}}"
                            (blur)="change($event, i, 'amount')">
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef>Total </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{currencySubject.value}} {{item.qty * item.amount | mask: 'separator,'}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index;">
                    <button mat-icon-button
                            color="primary"
                            [disabled]="readonly"
                            matTooltip="Edit additional cost"
                            (click)="editItem(item, i)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            color="warn"
                            [disabled]="readonly"
                            matTooltip="Delete additional cost"
                            (click)="deleteItem(item)"
                            type="button">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>