// NGRX
import {Action} from '@ngrx/store';
// Models
import {CurrencyModel} from '../_models/currency.model';
import {Update} from '@ngrx/entity';

export enum CurrencyActionTypes {
    AllCurrenciesRequested = '[Init] All Currencies Requested',
    AllCurrenciesLoaded = '[Init] All Currencies Loaded',

    CurrencyOnServerCreated = '[Edit Currency Dialog] Currency On Server Created',
    CurrencyCreated = '[Edit Currency Dialog] Currency Created',
    CurrencyUpdated = '[Edit Currency Dialog] Currency Updated',

    CurrenciesActionToggleLoading = '[Currencies] Currencies Action Toggle Loading',

    CurrencyDeleted = '[Currencies List Page] Currency Deleted',

    // trash system

    CurrencyOnServerRestored = '[Currency Trash] Currency On Server Restored',
    CurrencyRestored = '[Currency Trash] Currency Restored',

    CurrencyOnServerAdminRestored = '[Currency Admin Trash] Currency On Server Restored',
    CurrencyAdminRestored = '[Currency Admin Trash] Currency Restored',

    CurrencyDeletedFromTrash = '[Currency Trash] Currency deleted',
    CurrencyDeletedFromAdminTrash = '[Currency Admin Trash] Currency deleted',

    CurrencyTrash = 'Currency Trashed',
    CurrencyTrashFlushed = 'Currency Trash Flushed',
}

export class AllCurrenciesRequested implements Action {
    readonly type = CurrencyActionTypes.AllCurrenciesRequested;
}

export class AllCurrenciesLoaded implements Action {
    readonly type = CurrencyActionTypes.AllCurrenciesLoaded;

    constructor(public payload: {
        currencies: CurrencyModel[],
        totalTrashed: number,
        totalAdminTrashed: number
    }) {
    }
}


export class CurrencyOnServerCreated implements Action {
    readonly type = CurrencyActionTypes.CurrencyOnServerCreated;

    constructor(public payload: { currency: CurrencyModel }) {
    }
}

export class CurrencyCreated implements Action {
    readonly type = CurrencyActionTypes.CurrencyCreated;

    constructor(public payload: { currency: CurrencyModel }) {
    }
}

export class CurrencyUpdated implements Action {
    readonly type = CurrencyActionTypes.CurrencyUpdated;

    constructor(public payload: {
        partialCurrency: Update<CurrencyModel>,
        currency: CurrencyModel
    }) {
    }
}

export class CurrencyDeleted implements Action {
    readonly type = CurrencyActionTypes.CurrencyDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class CurrenciesActionToggleLoading implements Action {
    readonly type = CurrencyActionTypes.CurrenciesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export class CurrencyDeletedFromAdminTrash implements Action {
    readonly type = CurrencyActionTypes.CurrencyDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class CurrencyOnServerRestored implements Action {
    readonly type = CurrencyActionTypes.CurrencyOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CurrencyRestored implements Action {
    readonly type = CurrencyActionTypes.CurrencyRestored;

    constructor(public payload: { item: CurrencyModel }) {
    }
}

export class CurrencyOnServerAdminRestored implements Action {
    readonly type = CurrencyActionTypes.CurrencyOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CurrencyAdminRestored implements Action {
    readonly type = CurrencyActionTypes.CurrencyAdminRestored;

    constructor(public payload: { item: CurrencyModel }) {
    }
}


export class CurrencyDeletedFromTrash implements Action {
    readonly type = CurrencyActionTypes.CurrencyDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CurrencyTrashFlushed implements Action {
    readonly type = CurrencyActionTypes.CurrencyTrashFlushed;

    constructor() {
    }
}


export type CurrencyActions = AllCurrenciesRequested |
    AllCurrenciesLoaded |
    CurrenciesActionToggleLoading |
    CurrencyOnServerCreated |
    CurrencyCreated |
    CurrencyUpdated
    | CurrencyDeleted
    | CurrencyDeletedFromTrash
    | CurrencyDeletedFromAdminTrash
    | CurrencyOnServerRestored
    | CurrencyRestored
    | CurrencyOnServerAdminRestored
    | CurrencyAdminRestored

    | CurrencyTrashFlushed;
