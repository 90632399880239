import { AcSource } from '../../comparable';
import {BaseModel} from '../../_base/crud';

export class AssetClassConsiderationModel extends BaseModel {
    id: number;
    land_tenure_id: number;
    land_tenure_name: string;
    tenure_length: number;
    currency: string;
    consideration: number;

    created_at: Date;
    updated_at: Date;

    source: AcSource;

    clear() {
        this.id = undefined;
        this.land_tenure_id = undefined;
        this.land_tenure_name = '';
        this.tenure_length = 0;
        this.currency = '';
        this.consideration = 0;

        this.created_at = new Date();
        this.updated_at = new Date();

        this.source = new AcSource();
        this.source.clear();
    }
}