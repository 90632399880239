// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassOfficesState} from '../_reducers/asset-class-office.reducers';
import {AssetClassOfficeModel} from '../_models/asset-class-office.model';

export const selectAssetClassOfficesState = createFeatureSelector<AssetClassOfficesState>('assetClassOffices');

export const selectAssetClassOfficeById = (assetClassOfficeId: number) => createSelector(
    selectAssetClassOfficesState,
    state => state.entities[assetClassOfficeId]
);

export const selectAssetClassOfficesPageLoading = createSelector(
    selectAssetClassOfficesState,
    state => state.listLoading
);

export const selectAssetClassOfficesActionLoading = createSelector(
    selectAssetClassOfficesState,
    state => state.actionLoading
);

export const selectLastCreatedAssetClassOfficeId = createSelector(
    selectAssetClassOfficesState,
    state => {
        if (state.previouslyCreatedACId != state.lastCreatedAssetClassOfficeId) {
            return state.lastCreatedAssetClassOfficeId
        }
        return undefined
    } 
);

export const selectAssetClassOfficesShowInitWaitingMessage = createSelector(
    selectAssetClassOfficesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedAssetClassOfficeCount = createSelector(
    selectAssetClassOfficesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedAssetClassOfficeCount = createSelector(
    selectAssetClassOfficesState,
    (state) => state.totalAdminTrashed
);

export const selectAssetClassOfficeLastQuery = createSelector(
    selectAssetClassOfficesState,
    state => state.lastQuery
);

export const selectAssetClassOfficesInStore = createSelector(
    selectAssetClassOfficesState,
    state => {
        const items: AssetClassOfficeModel[] = [];
        each(state.entities, element => {
            if (state.lastQuery.filter
                && state.lastQuery.filter.pic_cnt
                && state.lastQuery.filter.pic_cnt.length > 0) {

                const _tmp = state.lastQuery.filter.pic_cnt.split('-');
                if (element.ac_pictures.length >= _tmp[0] && element.ac_pictures.length <= _tmp[1]) {
                    items.push(element);
                }
            } else {
                items.push(element);
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassOfficeModel[] = [];
        switch (state.lastQuery.sortField) {
            case 'office_layout_name':
                result = httpExtension.sortArrayString(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                );
                break;
            case 'source.source_date':
                result = httpExtension.sortArrayDateTime(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                );
                break;
            case 'building_information.building_name':
                result = httpExtension.sortArrayString(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                );
                break;
            case 'updated_at':
                result = httpExtension.sortArrayDateTime(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                );
                break;
            case 'size':
                result = httpExtension.sortArrayNumber(
                    items,
                    'sizeInMeter',
                    state.lastQuery.sortOrder
                );
                break;

            default:
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                );
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
