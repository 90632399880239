<div class="card card-custom" [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="participant">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="participant">
        <form [formGroup]="participantForm" class="form form-group-seperator-dashed">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    Oops! It looks like you’ve forgotten some important information.
                </kt-alert>

                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="External Reference" formControlName="source_external_reference_id" [(ngModel)]="participant.source_external_reference_id">
                                <mat-option *ngFor="let sourceExternalReference of sourceExternalReferences;"
                                            [disabled]="existSourceExternalReferences.indexOf(sourceExternalReference.id) > -1"
                                            [value]="sourceExternalReference.id">{{sourceExternalReference.name}}</mat-option>
                            </mat-select>
                            <mat-error>
                                External Reference
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <div class="mb-4">
                            <mat-label>
                                Reference available?
                            </mat-label>
                            <mat-radio-group class="tp-radio-group" formControlName="reference_available">
                                <mat-radio-button class="tp-radio-button kt-margin-l-20 position-right"
                                                  *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                    {{decision.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile"  *ngIf="participantForm.controls.reference_available.value == '0'">
                        <div class="mb-4">
                            <mat-label>
                                Add assumption?
                            </mat-label>
                            <mat-radio-group class="tp-radio-group" formControlName="assumption">
                                <mat-radio-button class="tp-radio-button kt-margin-l-20 position-right"
                                                  *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                    {{decision.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="participantForm.controls.reference_available.value == '0' && participantForm.controls.assumption.value == '1'">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Assumption" rows="2" formControlName="assumption_descr"></textarea>
                            <mat-error>
                                Assumption
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="participantForm.controls.reference_available.value == '0'">
                        <div class="mb-4">
                            <mat-label>
                                Add special assumption?
                            </mat-label>
                            <mat-radio-group class="tp-radio-group" formControlName="special_assumption">
                                <mat-radio-button class="tp-radio-button kt-margin-l-20 position-right"
                                                  *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                    {{decision.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="participantForm.controls.reference_available.value == '0' && participantForm.controls.special_assumption.value == '1'">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Special Assumption" rows="2" formControlName="special_assumption_descr"></textarea>
                            <mat-error>
                                Special Assumption
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 b-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="Close">
                                Close
                            </button>&nbsp;
                            <button type="button" [disabled]="!isNameValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                    matTooltip="Save changes">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
