<div class="card card-custom section-margin-bottom-60">
    <div class="card-header size-header">
        <div class="card-title">
            <h5 class="card-label">
                <span>
                    {{unitOfMeasurement}}
                </span>
            </h5>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.SIZES_TABLE.TEXT' | translate"
                >
                help
            </mat-icon>
        </div>
    </div>

<div class="card-body size-body">
    <div class="col-md-12 mat-table-wrapper" >
        <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource" >
            <!-- Headers -->
            <ng-container [matColumnDef]="'standard-' + comparables.length +'-header'">
                <th mat-header-cell *matHeaderCellDef >Measurement Standards</th>
            </ng-container>
    
            <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length + '-header'">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="cell">
                        {{com.ref_num}}
                        <span>
                            <button mat-icon-button color="primary" (click)="showOverview(com)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" [disabled]="readonly" (click)="removeComparable(i, com)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                    </span>
                </th>
            </ng-container>
    
            <ng-container [matColumnDef]="'tp-' + comparables.length + '-header'">
                <th mat-header-cell *matHeaderCellDef>
                    Target Property
                    <button mat-icon-button color="primary" (click)="showMultipleOverview()">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </th>
            </ng-container>
    
            <ng-container matColumnDef="action-header">
                <th mat-header-cell *matHeaderCellDef>Action</th>
            </ng-container>
    
            <!-- Cells -->
            <ng-container [matColumnDef]="'criterion-' + comparables.length">
                <td mat-cell *matCellDef="let criterion">
                    {{criterion.criterion ? criterion.criterion : 'Not defined'}}
                </td>
            </ng-container>
    
    
            <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-' + comparables.length">
                <td mat-cell *matCellDef="let criterion; let j = index"
                    [ngClass]="{
                        'bg-red': comparableControl(j,com.id).value === null || comparableControl(j,com.id).value === '',
                        'bg-green': comparableControl(j,com.id).value !== null && comparableControl(j,com.id).value !== ''
                    }"
                >
                    <input class="inline-input" [formControl]="comparableControl(j, com.id)">
                </td>
            </ng-container>
    
            <ng-container [matColumnDef]="'tp-' + comparables.length">
                <td mat-cell *matCellDef="let criterion; let j = index"
                [ngClass]="{
                    'bg-red': tpControl(j).value === null || tpControl(j).value === '',
                    'bg-green': tpControl(j).value !== null && tpControl(j).value !== ''
                }"
                >
                    <input class="inline-input" [formControl]="tpControl(j)">
                </td>
            </ng-container>
    
            <ng-container matColumnDef="actions">
                <td mat-cell *matCellDef="let criterion; let j = index">
                    <button 
                        mat-icon-button 
                        (click)="setSizeCriterion(criterion, j)">
                        <mat-icon class="blue" 
                            [ngClass]="{'green': activeStates[j]}">add_circle</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
                [ngClass]="{gray: i%2}"></tr>
        </table>
    </div>
</div>

</div>
