// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ExternalWallsService} from '../_services/external-wall.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allExternalWallsLoaded} from '../_selectors/external-wall.selectors';
// Actions
import {
    AllExternalWallsLoaded,
    AllExternalWallsRequested,
    ExternalWallActionTypes,
    ExternalWallsPageRequested,
    ExternalWallsPageLoaded,
    ExternalWallUpdated,
    ExternalWallsPageToggleLoading,
    ExternalWallDeleted,
    ExternalWallOnServerCreated,
    ExternalWallCreated,
    ExternalWallAdminRestored,
    ExternalWallDeletedFromAdminTrash,
    ExternalWallDeletedFromTrash,
    ExternalWallOnServerAdminRestored,
    ExternalWallOnServerRestored,
    ExternalWallRestored,
    ExternalWallTrashFlushed,
    ExternalWallsActionToggleLoading
} from '../_actions/external-wall.actions';

@Injectable()
export class ExternalWallEffects {
    showPageLoadingDispatcher = new ExternalWallsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ExternalWallsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ExternalWallsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllExternalWalls$ = this.actions$
        .pipe(
            ofType<AllExternalWallsRequested>(ExternalWallActionTypes.AllExternalWallsRequested),
            withLatestFrom(this.store.pipe(select(allExternalWallsLoaded))),
            filter(([action, isAllExternalWallsLoaded]) => !isAllExternalWallsLoaded),
            mergeMap(() => this.service.getAllExternalWalls()),
            map(res => {
                return new AllExternalWallsLoaded({externalWalls: res.data});
            })
        );

    @Effect()
    loadExternalWallsPage$ = this.actions$
        .pipe(
            ofType<ExternalWallsPageRequested>(ExternalWallActionTypes.ExternalWallsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findExternalWalls(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ExternalWallsPageLoaded({
                    externalWalls: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallDeleted>(ExternalWallActionTypes.ExternalWallDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteExternalWall(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallUpdated>(ExternalWallActionTypes.ExternalWallUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateExternalWall(payload.externalWall);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallOnServerCreated>(ExternalWallActionTypes.ExternalWallOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createExternalWall(payload.externalWall).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalWallCreated({externalWall: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushExternalWallTrash$ = this.actions$
        .pipe(
            ofType<ExternalWallTrashFlushed>(ExternalWallActionTypes.ExternalWallTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallOnServerRestored>(ExternalWallActionTypes.ExternalWallOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalWallRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallOnServerAdminRestored>(ExternalWallActionTypes.ExternalWallOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalWallAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallDeletedFromTrash>(ExternalWallActionTypes.ExternalWallDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashExternalWall$ = this.actions$
        .pipe(
            ofType<ExternalWallDeletedFromAdminTrash>(ExternalWallActionTypes.ExternalWallDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: ExternalWallsService, private store: Store<AppState>) {
    }
}
