<div class="col-lg-12" [ngClass]="{'readonly': readonly}">
    <div class="row">
        <div class="col-lg-12 gallery">
            <div class="card card-custom">
                <div class="card-body">
                    <!--	<div class="gallery-title">
						Target Property Pictures
					</div> -->
                    <!-- <div class="gallery-container">
                        <div *ngFor="let pic of (pics$|async); let i = index">
                            <div [ngClass]="picIsSelected(pic)" (click)="previewPicture(pic)">

                                <img [src]="pic.url" (error)="imgOnError($event)">
                                <div class="row" (click)="clickOnRadio($event)">
                                    <mat-radio-group id="radioGrp" (change)="radioGroupChange($event, pic)"
                                        >
                                        <div class="col-lg-6 checkboxL">
                                            <mat-radio-button value="1" [checked]="pic.selection_status == 1"
                                                class="leftRadio" (click)="onRadioClick('left', pic)">
                                            </mat-radio-button>
                                        </div>
                                        <div class="col-lg-6 checkboxR">
                                            <mat-radio-button value="2" [checked]="pic.selection_status == 2" 
                                                (click)="onRadioClick('right', pic)">
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>

                            </div>
                        </div>
                    </div> -->
                    <div class="gallery-container">
                        <div *ngFor="let pic of (pics$|async); let i = index">
                            <kt-picture-selector-checkbox 
                                [displayFeatured]="displayFeatured"
                                [pic]="pic"
                                [is_selected]="pic.selected"
                                [selection_status]="pic.selection_status"
                                (selectionChange)="onSelectionChange($event, pic)"></kt-picture-selector-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>