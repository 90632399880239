import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {AssetClassModel, ValuationModel} from 'src/app/core/asset_class';
import {UnitAreaMeasurement} from 'src/app/core/linked-tables';
import {AppState} from 'src/app/core/reducers';
import {ToeModel} from 'src/app/core/toe';
import {CriterionModel, CriterionType, selectActiveCriterions} from 'src/app/core/valuation';
import {DeleteCriterion, InsertCriterionsFromTemplate, RemoveComparable, ResetCriterion, SetCriterionDeactive, UpdateCriterion} from 'src/app/core/valuation/_actions/criterion.actions';
import {SizeCriterionRemoveComparable, SizeCriterionReset} from 'src/app/core/valuation/_actions/size_criterion.actions';
import {sizeCriterionReducer} from 'src/app/core/valuation/_reducers/size_criterion.reducer';
import {selectDefaultSizeCriterion} from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import {DeleteEntityDialogComponent} from 'src/app/views/partials/content/crud';
import {CriterionEditModalComponent} from '../_modals/criterion-edit-modal/criterion-edit-modal.component';
import {CriterionModalComponent} from '../_modals/criterion-modal/criterion-modal.component';
import {SizeModalComponent} from '../_modals/size-modal/size-modal.component';
import {selectAllValComparables, selectAllValTenures, selectLastCurrencyConvertedComID} from '../../../../../../../core/valuation/_selectors/valuation-data.selector';
import {takeUntil} from 'rxjs/operators';
import { RemoveCriterionFromAdjustment } from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import { ConsiderationModalComponent, IConsiderationModalInputData, IConsiderationModalReturnData } from '../_modals/consideration-modal/consideration-modal.component';
import { ConsiderationConversionProcess } from 'src/app/core/valuation/_models/criterion.model';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { CriterionTemplateListComponent } from '../_modals/criterion-template-list/criterion-template-list.component';
import { CriterionsTemplateModel } from 'src/app/core/template';
import { CriterionsTemplateModalComponent } from 'src/app/views/pages/template/templates/criterions-template/_sub/criterions-template-modal/criterions-template-modal.component';
import { selectAllCities } from 'src/app/core/admin';
import { selectAllCriterions } from 'src/app/core/valuation/_selectors/criterion.selectors';

@Component({
    selector: 'kt-criterions-table',
    templateUrl: './criterions-table.component.html',
    styleUrls: ['./criterions-table.component.scss']
})
export class CriterionsTableComponent implements OnInit, OnDestroy {
    @Input() readonly: boolean;
    @Input() valuation: ValuationModel;
    @Input() unitOfMeasurement$: Observable<UnitAreaMeasurement>;
    @Input() targetProperty: AssetClassModel;
    @Output() removeComparableParent: EventEmitter<any> = new EventEmitter();
    @Output() showOverviewParent: EventEmitter<any> = new EventEmitter();
    @Output() showMultipleOverviewParent: EventEmitter<any> = new EventEmitter();

    comparables: any[];

    headerColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    protected _onDestroy = new Subject<void>();

    dataSource: MatTableDataSource<CriterionModel> = new MatTableDataSource();
    criterionType = CriterionType;
    considerationProcess = ConsiderationConversionProcess;
    subscriptions: Subscription[] = [];
    tenures: any[] = [];

    constructor(private store: Store<AppState>,
                private dialog: MatDialog,
                private service: ConsiderationCriterionService) {
    }

    ngOnInit(): void {
        // const valSub = combineLatest([
        //     this.store.select(selectAllValComparables),
        //     this.store.select(selectLastCurrencyConvertedComID)
        // ]).subscribe(([coms, id]) => {
        //     this.comparables = Object.assign([], coms);
        //     this._setHeaders(this.comparables);
        // });
        // this.subscriptions.push(valSub);

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllValComparables)).subscribe(res => {
            this.comparables = Object.assign([], res);
            this._setHeaders(this.comparables);
        });
        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllValTenures)).subscribe(res => {
                this.tenures = res;
            });

        combineLatest([
            this.store.select(selectActiveCriterions),
            this.store.select(selectDefaultSizeCriterion).pipe(filter(val => val ? true : false))
        ]).subscribe(([criterions, sizeCriterion]) => {
            const _criterions = [];
            _criterions.push(...criterions);
            this.dataSource.data = _criterions;
        })
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    _setHeaders(comparables: any[]) {
        const headerColumns = [`input-${comparables.length}-header`];
        const displayedColumns = [`criterion-${comparables.length}`, `category-${comparables.length}`];
        const comparableHeaderColumns = [];
        comparables.forEach((com, i) => {
            headerColumns.push(`com-${i}-${comparables.length}-header`);
            comparableHeaderColumns.push(`com-${i}-${comparables.length}-header`);
            displayedColumns.push(`com-${i}-${comparables.length}`);
        });
        headerColumns.push(`tp-${comparables.length}-header`, 'action-header');
        displayedColumns.push(`tp-${comparables.length}`, 'actions');
        this.headerColumns$.next(headerColumns);
        this.displayedColumns$.next(displayedColumns);
    }

    addExistingCriterion() {
        this.dialog.open(CriterionModalComponent, {
            width: '80vw',
            height: '92vh',
            data: {
                comparables: this.comparables
            }
        })
    }

    addCustomCriterion() {
        this.dialog.open(CriterionEditModalComponent, {
            width: '80vw',
            data: {
                comparables: this.comparables,
                criterion: null
            }
        })
    }

    editConsideration(criterion: CriterionModel, com: any) {
        const t = this.tenures.find(t => t.comId == com.id);
        const comConsideration = criterion.comConsideration[com.id];
        const tenure = this.service._getSameTypeOfTenure(this.valuation.tenure_id, com.tenures, t ? t.selectedTenure : null);
        const dialogRef = this.dialog.open<ConsiderationModalComponent, IConsiderationModalInputData, IConsiderationModalReturnData>(ConsiderationModalComponent, {
            width: '60vw',
            maxHeight: '90vh',
            data: {
                refNum: '',
                tpCurrency: this.targetProperty.country_currency,
                process: comConsideration.process,
                consideration: tenure,
                hteProcessData: {
                    methods: {
                        main: comConsideration.methods.mainMethod,
                        sub: comConsideration.methods.subMethod,
                    },
                    tvomRatesAndJustifications: {
                        capRate: comConsideration.rateValues.capRate,
                        capRateJustification: comConsideration.rateValues.capRateJustification,
                        targetRate: comConsideration.rateValues.targetRate,
                        targetRateJustification: comConsideration.rateValues.targetRateJustification
                    },
                    cfmRatesAndJustifications: comConsideration.cfmRateValues,
                    selected: comConsideration.selected,
                },
                holdConversionProcessData: {
                    ary: comConsideration.ary
                }
            }
        });
        const dialogSub = dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _newCriterion = CriterionModel.copy(criterion);
            _newCriterion.comValues[com.id] = res.value;
            _newCriterion.comConsideration[com.id] = {
                process: comConsideration.process,
                methods: {
                    mainMethod: res.hteProcessData.methods.main,
                    subMethod: res.hteProcessData.methods.sub
                },
                ary: res.holdConversionProcessData.ary,
                rateValues: {
                    capRate: res.hteProcessData.tvomRatesAndJustifications.capRate,
                    capRateJustification: res.hteProcessData.tvomRatesAndJustifications.capRateJustification,
                    targetRate: res.hteProcessData.tvomRatesAndJustifications.targetRate,
                    targetRateJustification: res.hteProcessData.tvomRatesAndJustifications.targetRateJustification
                },
                cfmRateValues: res.hteProcessData.cfmRatesAndJustifications,
                selected: res.hteProcessData.selected
            }
            this.store.dispatch(new UpdateCriterion({criterion: _newCriterion}));
        });
        this.subscriptions.push(dialogSub);
    }

    editCriterion(criterion: CriterionModel) {
        switch (criterion.type) {
            case CriterionType.Size: {
                this.dialog.open(SizeModalComponent, {
                    width: '80vw',
                    data: {
                        comparables: this.comparables,
                    }
                })
                break;
            }
            case CriterionType.Other: {
                this.dialog.open(CriterionEditModalComponent, {
                    width: '80vw',
                    data: {
                        comparables: this.comparables,
                        criterion
                    }
                });
                break;
            }
            case CriterionType.Custom: {
                this.dialog.open(CriterionEditModalComponent, {
                    width: '80vw',
                    data: {
                        comparables: this.comparables,
                        criterion
                    }
                });
                break;
            }
        }
    }

    removeComparable(index: number, comparable: any) {
        this.removeComparableParent.emit({
            index,
            com: comparable
        });
    }
    removeCriterion(criterion: CriterionModel) {
        const _title = 'Are you sure?';
        const _description = `The criterion "${criterion.name}" will be removed`;
        const _waitDesciption = 'Removing criterion';
        const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
          data: {title: _title, description: _description, waitDesciption: _waitDesciption},
          width: '440px'
        });
        dialogRef.afterClosed().subscribe(res => {
          if (!res) {
            return;
          }
          if (criterion.type === CriterionType.Other) {
            this.store.dispatch(new SetCriterionDeactive({criterion: criterion}));
          } else if (criterion.type === CriterionType.Custom) {
            this.store.dispatch(new DeleteCriterion({id: criterion.id}));
          }
          this.store.dispatch(new RemoveCriterionFromAdjustment({criterion: criterion}));
        })
      }

    showOverview(assetClass: any) {
        this.showOverviewParent.emit(assetClass);
    }

    showMultipleOverview() {
        this.showMultipleOverviewParent.emit();
    }

    resetCriterion(criterion: CriterionModel) {
        switch (criterion.type) {
            case CriterionType.Size:
                this.store.dispatch(new SizeCriterionReset({
                    criterion,
                    assetClass: this.targetProperty,
                    comparables: this.comparables,
                }));
                break;
            case CriterionType.Other:
                this.store.dispatch(new ResetCriterion({
                    criterion,
                    comparables: this.comparables,
                    ac: this.targetProperty
                }));
                break;
        }
    }

    addFromTemplate() {
        const dialogRef = this.dialog.open(CriterionTemplateListComponent, {
            data: {
                assetClassTypeId: this.targetProperty.type_id
            }
        });
        dialogRef.afterClosed().pipe().subscribe(
            res => {
                if (!res) {
                    return;
                }
                switch (res.type) {
                    case 'select':
                        this.store.dispatch(new InsertCriterionsFromTemplate({
                            assetClass: this.targetProperty,
                            comparables: this.comparables,
                            defaultCriterions: res.template.default_criterions,
                            customCriterions: res.template.custom_criterions
                        }));
                        break;
                    case 'view':
                        this._viewTemplate(res.template);
                        break;
                    default:
                        break;
                }
            }
        )
    }
    private _viewTemplate(template: CriterionsTemplateModel) {
        this.dialog.open(CriterionsTemplateModalComponent, {
            data: {
                template
            }
        });
    }
}
