<!-- <div>Key Category edit works</div> -->
<kt-portlet>
    <kt-portlet-header 
            [title]="getComponentTitle() + (readonly ? ' (View Only)' : '')" 
            [tooltipTitle]="'TOOLTIP.LT.KEY_CATEGORY.FORM.' + mode + '.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.LT.KEY_CATEGORY.FORM.' + mode + '.DESCRIPTION' | translate" 
            [class]="'kt-portlet__head--lg'" 
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a [routerLink]="model.id > 0 ? ['../../'] : ['../']" class="btn  mr-2" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{back_page: ('TOOLTIP.LT.KEY_CATEGORY.LIST.TITLE' | translate)}">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
            </a>
            <ng-container *ngIf="!readonly">
                <a href="javascript:;" class="btn  mr-2" (click)="reset()"  mat-raised-button >
                    <i class="flaticon-cogwheel-2"></i>
                    <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                </a>
                <a href="javascript:;" class="btn btn-success mr-2" color="submit"
                (click)="onSubmit(false)"
                [disabled]="!isFormValid()"

                mat-raised-button >
                    <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
                </a>
            </ng-container>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" [formGroup]="keyCategoryForm" [ngClass]="{'readonly': readonly}">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{'GENERAL.MESSAGE.FORM_WARNING' | translate}}
                </kt-alert>

                <div class="row">
                <div class="col-8 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput [placeholder]="'LANDMARK_TYPE.FORM.FIELD.GENERAL.NAME.PLACEHOLDER' | translate" formControlName="name"
                               [(ngModel)]="model.name"
                        />
                        <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('LANDMARK_TYPE.FORM.FIELD.GENERAL.NAME.TITLE' | translate)
                                }"
                            >
                        </mat-error>
                    </mat-form-field>
                </div>
                </div>

                <div class="row">
                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <div *ngIf="error">
                        {{ error.message }}
                    </div>

                    <div class="kt-portlet__head-progress" *ngIf="(progress$ | async) > 0">
                        <mat-progress-bar mode="determinate" color="war" [value]="progress$ | async "></mat-progress-bar>
                    </div>


                    <input #fileInputImg type="file" (change)="uploadFile($event)" style="display: none" accept="image/svg+xml"/>


                    <div class="mb-4 example-container mat-elevation-z0 ">
                        <mat-table #table [dataSource]="dataSource">
                            <ng-container matColumnDef="type">
                                <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                                <mat-cell *matCellDef="let file">
                                    <img class="mat-table-icon"
                                         [attr.src]="typesUtilsService.setIcon(getFileName(file.field))"
                                         alt="">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                                <mat-cell *matCellDef="let file"> {{file.name}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="file_name">
                                <mat-header-cell *matHeaderCellDef> File name</mat-header-cell>
                                <mat-cell *matCellDef="let file"> {{getFileName(file.field)}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                                <mat-cell *matCellDef="let file">
                                    <button mat-icon-button
                                            color="primary"
                                            matTooltip="Upload file"
                                            (click)="addFiles(file.field)"
                                            [disabled]="(progress$ | async) > 0 || readonly"
                                            *ngIf="getFileName(file.field) == awConst.NOFILE"
                                            type="button">
                                        <mat-icon>cloud_upload</mat-icon>
                                    </button>&nbsp;
                                    <button mat-icon-button
                                            color="warn"
                                            matTooltip="Delete file"
                                            (click)="deleteUploadedFile(file.field)"
                                            type="button"
                                            [disabled]="readonly"
                                            *ngIf="getFileName(file.field) != awConst.NOFILE"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>&nbsp;
                                    <button mat-icon-button
                                            matTooltip="Preview file"
                                            (click)="previewUploadedFile(file.field)"
                                            type="button"
                                            *ngIf="getFileName(file.field) != awConst.NOFILE">
                                        <mat-icon>remove_red_eye</mat-icon>
                                    </button>&nbsp;
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'required_file' : getFileName(row.field) == awConst.NOFILE}">
                            </mat-row>
                        </mat-table>
                    </div>
                    <br>
                </div>
                </div>
            </div>
        </form>
    </kt-portlet-body>
</kt-portlet>

