import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Amendment, RemoveTPAmendment } from 'src/app/core/toe/_models/toe-amendment.model';
import { RemoveTpAmendmentService, TpRelatedData } from 'src/app/core/toe/_services/remove-tp-amendment.service';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { AmendmentComponent } from '../amendment-dialog.types';
import { PaymentSummaryData, PaymentTermData } from '../payments-info/payments-info.component';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';

interface TpSelect {
  id: number;
  name: string;
  disabled: boolean;
}

@Component({
  selector: 'kt-remove-tp-amendment-v3',
  templateUrl: './remove-tp-amendment-v3.component.html',
  styleUrls: ['./remove-tp-amendment-v3.component.scss']
})
export class RemoveTpAmendmentV3Component implements OnInit, OnDestroy, AmendmentComponent {
  @Input() inputAmendment: Amendment; 
  @Output() onAmendmentRemove: EventEmitter<Amendment> = new EventEmitter();

  public form: UntypedFormGroup;
  public amendment: RemoveTPAmendment;
  public tps$: Observable<TpSelect[]>;

  private destroy$: Subject<void> = new Subject();

  public selectedTermIDs: number[] = [];
  private _totalInclTax: number = 0;
  public balance: number = 0;
  public currency: string = '';
  private _paymentSummaryDataSubject: BehaviorSubject<PaymentSummaryData[]> = new BehaviorSubject([]);
  public paymentSummaryData$ = this._paymentSummaryDataSubject.asObservable();
  
  private _terms: any[] = [];
  private _paymentTermDataSubject: BehaviorSubject<PaymentTermData[]> = new BehaviorSubject([]);
  public paymentTermData$ = this._paymentTermDataSubject.asObservable();

  public parties: Array<{type: number, name: string, isDisabled: boolean}> = [];
  public tp_ids: number[] = [];

  public completionDate: Date = null;
  public valuationBreakdownDataSource = new MatTableDataSource();
  public additionalCostDataSource = new MatTableDataSource();
  public hasAdditionalCosts = false;
  public unitAreaMeasurement = '';
  public tpBalance = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: RemoveTpAmendmentService,
    private dialog: MatDialog,
    public typesUtilsService: TypesUtilsService
  ) { }

  ngOnInit(): void {
    this.amendment = (this.inputAmendment as RemoveTPAmendment);
    this.selectedTermIDs = this.amendment.selected_term_ids;
    if (this.amendment.tp_id) {
      this._getTpRelatedInfo(this.amendment.tp_id);
    }
    this.tps$ = this.service.selectableTps().pipe(map(tps => {
      return tps.map(tp => {
        let disabled = tp.is_removed;
        if (tp.tp_id == this.amendment.tp_id) {
          disabled = false;
        }
        if (this.amendment.tp_ids && this.amendment.tp_ids.includes(tp.tp_id)) {
          disabled = false;
        }
        return {
          id: tp.tp_id,
          name: tp.tp_name,
          disabled 
        }
      })
    }))
    this.service.getToeRelatedInfo(this.amendment.toe_id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(toeRelatedData => {
      this.currency = toeRelatedData.currency;
      this.completionDate = this.typesUtilsService.getDateFromString(toeRelatedData.completionDate);
      this.unitAreaMeasurement = toeRelatedData.measurement;

      const paymentSummaryData: Array<PaymentSummaryData> = [];
      paymentSummaryData.push({designation: 'Valuation Fees', amount: toeRelatedData.valuationFees, isBold: false});
      if (toeRelatedData.additionalCosts > 0) {
        paymentSummaryData.push({designation: 'Additional Costs', amount: toeRelatedData.additionalCosts, isBold: false});
      }
      if (toeRelatedData.discountPercent > 0) {
        paymentSummaryData.push({designation: `Discount (${toeRelatedData.discountPercent}%)`, amount: toeRelatedData.discountPrice, isBold: false});
      }
      paymentSummaryData.push({designation: 'Total (excluding taxes)', amount: toeRelatedData.totalExclTax, isBold: true});
      if (toeRelatedData.vatPercent > 0) {
        paymentSummaryData.push({designation: `VAT (${toeRelatedData.vatPercent}%)`, amount: toeRelatedData.vat, isBold: false});
      }
      if (toeRelatedData.otherPercent > 0) {
        paymentSummaryData.push({designation: `Other taxes (${toeRelatedData.otherPercent}%)`, amount: toeRelatedData.vatOther, isBold: false});
      }
      paymentSummaryData.push({designation: `Total (including taxes)`, amount: toeRelatedData.totalInclTax, isBold: true});
      this._paymentSummaryDataSubject.next(paymentSummaryData);

      this._terms = toeRelatedData.paymentTerms;
      this._paymentTermDataSubject.next(toeRelatedData.paymentTerms.map(p => ({...p, initiallyChecked: false})));

      this._totalInclTax = toeRelatedData.totalInclTax;
      this._updateBalance();

      this.parties = toeRelatedData.parties.map(p => ({...p, isDisabled: false}));
    })

    this.form = this.formBuilder.group({
      // tp_id: [this.amendment.tp_id, Validators.required],
      reason: [this.amendment.reason, Validators.required],
      change_date_of_completion: [this.amendment.change_date_of_completion],
      completion_date: [this.amendment.date_of_completion],
      payment_arbitration: [this.amendment.payment_arbitration],
      paying_party: [this.amendment.paying_party],
      payed_party: [this.amendment.payed_party],
      paying_amount: [this.amendment.paying_amount],
      comments: [this.amendment.comments],
      disabled_input: [{value: '', disabled: true}],
    });
    // this.form.controls.tp_id.valueChanges.pipe(
    //   takeUntil(this.destroy$),
    //   startWith(this.form.controls.tp_id.value),
    //   pairwise()
    // ).subscribe(([prev, next]) => {
    //   this.service.tpSelected(prev, next);
    //   this._getTpRelatedInfo(next);
    // })
    const controls = this.form.controls;
    controls.payment_arbitration.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
      val => {
        if (val == 1 || val == 2) {
          controls.paying_party.setValidators([Validators.required]);
          controls.payed_party.setValidators([Validators.required]);
          controls.paying_amount.setValidators([Validators.required]);
        } else {
          controls.paying_party.clearValidators();
          controls.payed_party.clearValidators();
          controls.paying_amount.clearValidators();
        }
        if (val == 1) {
          controls.paying_party.setValue(1);
          controls.payed_party.setValue(0);
        } else if (val == 2) {
          controls.paying_party.setValue(0);
          controls.payed_party.setValue(1);
        }
        controls.paying_party.updateValueAndValidity();
        controls.payed_party.updateValueAndValidity();
        controls.paying_amount.updateValueAndValidity();
      }
    )
    controls.paying_party.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      const notSelectedParty = this.parties.find(p => p.type != val);
      controls.payed_party.setValue(notSelectedParty.type, {emitEvent: false});
    });
    controls.payed_party.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      const notSelectedParty = this.parties.find(p => p.type != val);
      controls.paying_party.setValue(notSelectedParty.type, {emitEvent: false});
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkValid(): boolean {
    let hasErrors = false;
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        if (!controls[controlName].valid) {
          hasErrors = true;
        }
        controls[controlName].markAsTouched();
      })
    }

    return !hasErrors;
  }

  removeAmendment() {
    const dialogRef = this.dialog.open(WarningModalComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      const amendment = this.fetchData();
      this.onAmendmentRemove.emit(amendment);
      this.service.amendmentDecreased(amendment.tp_id);
    })
  }

  fetchData(): RemoveTPAmendment {
    const amendment = new RemoveTPAmendment(this.amendment.type, this.amendment.amendment_id, this.amendment.order, this.amendment.toe_id);
    amendment.id = this.amendment.id;
    // amendment.tp_id = Number(this.form.controls.tp_id.value);
    amendment.tp_ids = this.tp_ids;
    amendment.reason = this.form.controls.reason.value;
    amendment.change_date_of_completion = this.form.controls.change_date_of_completion.value;
    const date = new Date(this.form.controls.completion_date.value);
    amendment.date_of_completion = this.form.controls.completion_date.value ? this.typesUtilsService.getDateStringFromDate(date) : null;
    amendment.payment_arbitration = this.form.controls.payment_arbitration.value;
    amendment.selected_term_ids = this.selectedTermIDs;
    amendment.paying_party = this.form.controls.paying_party.value;
    amendment.payed_party = this.form.controls.payed_party.value;
    amendment.paying_amount = this.form.controls.paying_amount.value;
    amendment.comments = this.form.controls.comments.value;
    return amendment;
  }

  private _updateBalance() {
    const selectedTerms = this._terms.filter(t => t.invoice)
    const selectedTermSum = selectedTerms.reduce((acc, t) => acc + (t.invoice.status == 'Voided' ? 0 : t.invoice.status == 'Upcoming' ? t.amount : t.amount - t.invoice.invoice_amount), 0);
    this.balance = selectedTermSum;
  }

  public selectionChange({change, id}:{change: boolean, id: number}) {
    if (change) {
      this.selectedTermIDs.push(id);
    } else {
      const indx = this.selectedTermIDs.findIndex(tid => tid == id);
      if (indx > -1) {
        this.selectedTermIDs.splice(indx, 1);
      }
    }
    this._updateBalance();
  }
  public clearDate(control) {
    control.setValue(null);
    control.updateValueAndValidity();
  }
  private _getTpRelatedInfo(tpID: number) {
    this.service.getTpRelatedInfo(tpID, this.amendment.toe_id).subscribe(res => {
      this.valuationBreakdownDataSource.data = res.valuations.map(val => {
        const price = val.price * ((100 - res.discountPercent) * (100 + res.vatPercent + res.otherPercent) / 10000);
        return {
          ...val,
          price
        }
      });
      this.hasAdditionalCosts = res.additionalCosts.length > 0;
      this.additionalCostDataSource.data = res.additionalCosts.map(ac => {
        const price = ac.total * ((100 - res.discountPercent) * (100 + res.vatPercent + res.otherPercent) / 10000);
        return {
          ...ac,
          total: price
        }
      });
      const vfAndAc = res.valuations.reduce((acc, val) => val.price + acc, 0) + res.additionalCosts.reduce((acc, cost) => cost.total + acc, 0);
      this.tpBalance = vfAndAc * ((100 - res.discountPercent) * (100 + res.vatPercent + res.otherPercent) / 10000);
    });
  }
  onServiceChange(event: MatCheckboxChange, tp: TpSelect) {
    this.tp_ids = this._updateChecklists(this.tp_ids, tp.id, event.checked);
  }

  _updateChecklists(list: number[], id: number, isChecked: boolean): number[] {
    if (isChecked) {
      list.push(id);
    } else{
      list.splice(list.indexOf(id) ,1);
    }
    return list;
  }
}
