import {ExternalArea} from '../_models/external-area.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ExternalAreasState} from '../_reducers/external-area.reducers';
import * as fromExternalArea from '../_reducers/external-area.reducers';
import {each} from 'lodash';

export const selectExternalAreasState = createFeatureSelector<ExternalAreasState>('externalAreas');

export const selectExternalAreaById = (externalAreaId: number) => createSelector(
    selectExternalAreasState,
    externalAreasState => externalAreasState.entities[externalAreaId]
);

export const selectAllExternalAreas = createSelector(
    selectExternalAreasState,
    fromExternalArea.selectAll
);

export const selectAllExternalAreasIds = createSelector(
    selectExternalAreasState,
    fromExternalArea.selectIds
);

export const allExternalAreasLoaded = createSelector(
    selectExternalAreasState,
    externalAreasState => externalAreasState.isAllExternalAreasLoaded
);


export const selectExternalAreasPageLoading = createSelector(
    selectExternalAreasState,
    externalAreasState => externalAreasState.listLoading
);

export const selectExternalAreasActionLoading = createSelector(
    selectExternalAreasState,
    externalAreasState => externalAreasState.actionLoading
);

export const selectLastCreatedExternalAreaId = createSelector(
    selectExternalAreasState,
    externalAreasState => externalAreasState.lastCreatedExternalAreaId
);

export const selectExternalAreasShowInitWaitingMessage = createSelector(
    selectExternalAreasState,
    externalAreasState => externalAreasState.showInitWaitingMessage
);


export const selectTrashedExternalAreaCount = createSelector(
    selectExternalAreasState,
    (externalAreasState) => externalAreasState.totalTrashed
);

export const selectAdminTrashedExternalAreaCount = createSelector(
    selectExternalAreasState,
    (externalAreasState) => externalAreasState.totalAdminTrashed
);


export const selectExternalAreasQueryResult = createSelector(
    selectExternalAreasState,
    externalAreasState => {
        const items: ExternalArea[] = [];
        each(externalAreasState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ExternalArea[] = httpExtension.sortArray(items, externalAreasState.lastQuery.sortField, externalAreasState.lastQuery.sortOrder);

        return new QueryResultsModel(result, externalAreasState.totalCount, '', externalAreasState.totalTrashed);
    }
);
