<div class="card card-custom" [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="tenureConsideration">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="tenureConsideration">
        <form [formGroup]="form" class="form form-group-seperator-dashed">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    Oops! It looks like you’ve forgotten some important information.
                </kt-alert>

                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Land Tenure" formControlName="land_tenure_id">
                                <mat-option *ngFor="let landTenure of landTenures" [value]="landTenure.id">{{landTenure.name + '(' + landTenure.payment_type + ')' }}</mat-option>
                            </mat-select>
                            <mat-error>
                                Land Tenure
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput 
                                placeholder="Tenure Length (years)" 
                                formControlName="tenure_length" 
                                ng-min="tenureLengthMin" 
                                ng-max="tenureLengthMax"
                                (change)="onYearChange($event)"
                                />
                            <mat-error *ngIf="form.controls.tenure_length.errors?.required">
                                Tenure Length (years)
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.tenure_length.errors?.min || form.controls.tenure_length.errors?.max">
                                Value must be between {{tenureLengthMin}} to {{tenureLengthMax}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Currency" formControlName="currency" #singleSelectCurrency>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="filterCtrlCountriesData"
                                                        placeholderLabel="Search"
                                                        noEntriesFoundLabel="Currencies not found">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                                    {{_data.currencyCode}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Currency
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Consideration" formControlName="consideration" mask="separator" thousandSeparator=","/>
                            <mat-error>
                                Consideration
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group row">
                    <kt-source-info class="col-12" [sourceSubject]="sourceSubject" [otherDates]="otherDates"></kt-source-info>
                </div>
            </div>
            <div class="card-footer p-0 b-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="Close">
                                Close
                            </button>&nbsp;
                            <button type="button" [disabled]="!isFormValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                    matTooltip="Save changes">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
