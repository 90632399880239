// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
// Models
import {ToeModel} from '../_models/toe.model';
import {environment} from '../../../../environments/environment';
import {SupportingValuerModel} from '../_models/supporting-valuer.model';
import {
    AssetClassModel,
    PaymentModel,
    PaymentTaxModel,
    ValuationModel,
    PaymentTermModel,
} from '../../asset_class';
import {CoiDetailsTPModel} from '../_models/coi-details-tp.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class ToeService {
    API_TOE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes`;
    API_TOE_CUSTOM_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes-custom`;
    API_TOE_WORKER_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-workers`;
    API_TOE_USER_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-users`;
    API_TOE_RDM_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-rdms`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }


    // READ =>  GET: list of clients from the server
    findToes(payload: any): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', payload.page.sortOrder)
            .set('sortField', payload.page.sortField)
            .set('page', payload.page.pageNumber.toString())
            .set('pageSize', payload.page.pageSize.toString())
        if (payload.page.filter) {
            Object.keys(payload.page.filter).forEach(key => {
                const val = payload.page.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };
        return this.http.get<any>(this.API_TOE_URL + '/' + payload.assignmentId, {
            headers: httpHeaders,
            params: params
        });
    }

    // CREATE =>  POST: add a new client to the server
    createToe(body: {
        toe: ToeModel,
        workers: SupportingValuerModel[],
        rdms: number [],
        tp_list: AssetClassModel [],
        coi_properties: CoiDetailsTPModel [],
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        valuations: ValuationModel[],
        paymentTerms: PaymentTermModel[],
        standards: any[],
        fileList: any[],
        isDuplicated: boolean
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_TOE_URL, body, {headers: httpHeaders});
    }

    // UPDATE =>  PUT: update the client to the server
    updateToe(body: {
        toe: ToeModel,
        rdms: number [],
        tp_list: AssetClassModel [],
        coi_properties: CoiDetailsTPModel [],
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        valuations: ValuationModel[],
        paymentTerms: PaymentTermModel[],
        standards: any[],
        fileList: any[],
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_TOE_URL + '/' + body.toe.id, body, {headers: httpHeaders});
    }

    // UPDATE STATUS =>  PUT: data with status
    updateToeStatus(body: {
        toe_id: number,
        status: number
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_TOE_URL + '/' + body.toe_id + '/status', body, {headers: httpHeaders});
    }

    // DELETE => delete the toe from the server
    deleteToe(toeId: number): Observable<any> {
        const url = `${this.API_TOE_URL}/${toeId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url, {headers: httpHeaders});
    }

    // TRASH (User)
    getTrashedByAssignmentID(assId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_URL + `/${assId}/trash`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashedByAssignmentID(assId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_TOE_URL + `/${assId}/admin/trash`, {headers: httpHeaders});
    }


    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_URL + '/trash/flush', {headers: httpHeaders});
    }

    deleteFromTrash(toeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_TOE_URL + `/trash/${toeId}`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(toeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_TOE_URL + `/admin/trash/${toeId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_URL + `/trash/${id}`, {headers: httpHeaders});
    }

    // toe worker

    getWorker(toeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_WORKER_URL + '/' + toeId, {headers: httpHeaders});
    }

    getUsers(toeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders()
        return this.http.get<any>(this.API_TOE_USER_URL + '/' + toeId, {headers: httpHeaders});
    }

    // toe report delivery method
    getRdm(toeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_RDM_URL + '/' + toeId, {headers: httpHeaders});
    }

    getToeById(toeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get <ToeModel>(this.API_TOE_CUSTOM_URL + `/${toeId}`, {headers: httpHeaders});
    }

    getToesByStatus(payload: any): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(payload.page);
        return this.http.get<any>(this.API_TOE_URL + `/status?status=${payload.status}`, {
            headers: httpHeaders,
            params: httpParams,
        });
    }
}
