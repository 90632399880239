// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {TrainingDocumentsService} from '../_services/training-document.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allTrainingDocumentsLoaded} from '../_selectors/training-document.selectors';
// Actions
import {
    AllTrainingDocumentsLoaded,
    AllTrainingDocumentsRequested,
    TrainingDocumentActionTypes,
    TrainingDocumentsPageRequested,
    TrainingDocumentsPageLoaded,
    TrainingDocumentUpdated,
    TrainingDocumentsPageToggleLoading,
    TrainingDocumentDeleted,
    TrainingDocumentOnServerCreated,
    TrainingDocumentCreated,
    TrainingDocumentAdminRestored,
    TrainingDocumentDeletedFromAdminTrash,
    TrainingDocumentDeletedFromTrash,
    TrainingDocumentOnServerAdminRestored,
    TrainingDocumentOnServerRestored,
    TrainingDocumentRestored,
    TrainingDocumentTrashFlushed,
    TrainingDocumentsActionToggleLoading
} from '../_actions/training-document.actions';

@Injectable()
export class TrainingDocumentEffects {
    showPageLoadingDispatcher = new TrainingDocumentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new TrainingDocumentsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TrainingDocumentsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTrainingDocuments$ = this.actions$
        .pipe(
            ofType<AllTrainingDocumentsRequested>(TrainingDocumentActionTypes.AllTrainingDocumentsRequested),
            withLatestFrom(this.store.pipe(select(allTrainingDocumentsLoaded))),
            filter(([action, isAllTrainingDocumentsLoaded]) => !isAllTrainingDocumentsLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllTrainingDocumentsLoaded({trainingDocuments: res.data});
            })
        );

    @Effect()
    loadTrainingDocumentsPage$ = this.actions$
        .pipe(
            ofType<TrainingDocumentsPageRequested>(TrainingDocumentActionTypes.TrainingDocumentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.find(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new TrainingDocumentsPageLoaded({
                    trainingDocuments: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );


    @Effect()
    updateTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentUpdated>(TrainingDocumentActionTypes.TrainingDocumentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.trainingDocument, payload.fileList);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentOnServerCreated>(TrainingDocumentActionTypes.TrainingDocumentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.trainingDocument, payload.fileList).pipe(
                    tap(res => {
                        this.store.dispatch(new TrainingDocumentCreated({trainingDocument: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTrainingDocumentTrash$ = this.actions$
        .pipe(
            ofType<TrainingDocumentTrashFlushed>(TrainingDocumentActionTypes.TrainingDocumentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentOnServerRestored>(TrainingDocumentActionTypes.TrainingDocumentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TrainingDocumentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentOnServerAdminRestored>(TrainingDocumentActionTypes.TrainingDocumentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TrainingDocumentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentDeleted>(TrainingDocumentActionTypes.TrainingDocumentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentDeletedFromTrash>(TrainingDocumentActionTypes.TrainingDocumentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashTrainingDocument$ = this.actions$
        .pipe(
            ofType<TrainingDocumentDeletedFromAdminTrash>(TrainingDocumentActionTypes.TrainingDocumentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: TrainingDocumentsService, private store: Store<AppState>) {
    }
}
