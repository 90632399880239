import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {AgencyService} from '../../../../../core/admin';
import {AppState} from '../../../../../core/reducers';

@Injectable()
export class AgencyResolver implements Resolve<any> {
    constructor(private service: AgencyService,
        private store: Store<AppState>) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const itemId = Number(route.params.id);
        return this.service.getById(itemId);
    }
}
