import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ActivatedRouteSnapshot} from '@angular/router';
import {AppState} from '../../../../../core/reducers/index';
import {ToeService} from '../../../../../core/toe/index';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';

@Injectable()
export class ToeGetResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public toeService: ToeService,
                private global: Globals) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (this.global.activeTOEId) {
            return this.toeService.getToeById(this.global.activeTOEId);
        }
        const toeId = Number(route.paramMap.get('toe_id'));
        return this.toeService.getToeById(toeId);
    }
}
