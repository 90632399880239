import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-multiple-pdf-viewer',
  templateUrl: './multiple-pdf-viewer.component.html',
  styleUrls: ['./multiple-pdf-viewer.component.scss']
})
export class MultiplePdfViewerComponent implements OnInit, OnDestroy {
  items: {name: string; path: string; type: string}[] = [];
  currentIndx: number;
  currentFile: BehaviorSubject<{
    name: string;
    path: string;
    type: string;
  }> = new BehaviorSubject(null);
  basePath: string;
  moveCursor: Subject<number> = new Subject();
  hasLeft$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasRight$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subscriptions: Subscription[] = [];
  awConst = awConst;

  constructor(
    public dialogRef: MatDialogRef<MultiplePdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private subDomainService: SubdomainService
  ) {
    this.items = data;
    this.basePath = environment.baseApiUrl
    this.currentIndx = 0;
    if (this.items.length > 1) {
      this.hasRight$.next(true);
    }
    this.currentFile.next({
      name: this.items[this.currentIndx].name,
      path: this._setURL(this.items[this.currentIndx].path),
      type: this.items[this.currentIndx].type
    });
  }

  ngOnInit(): void {
    const sub = this.moveCursor.subscribe(res => {
      if (res === 1) {
        this.currentIndx = this.currentIndx + 1;
        if (this.currentIndx <= (this.items.length - 1)) {
          this.currentFile.next({
            name: this.items[this.currentIndx].name,
            path: this._setURL(this.items[this.currentIndx].path),
            type: this.items[this.currentIndx].type
          });
          
          // Check if has right
          if (this.currentIndx == (this.items.length - 1)) {
            this.hasRight$.next(false);
          } else {
            this.hasRight$.next(true);
          }
          this.hasLeft$.next(true);
          return;
        }
        this.currentIndx = this.currentIndx - 1;
      } else if (res === -1) {
        this.currentIndx = this.currentIndx - 1;
        if (this.currentIndx >= 0) {
          this.currentFile.next({
            name: this.items[this.currentIndx].name,
            path: this._setURL(this.items[this.currentIndx].path),
            type: this.items[this.currentIndx].type
          });

          // Check if has left
          if (this.currentIndx == 0) {
            this.hasLeft$.next(false);
          } else {
            this.hasLeft$.next(true);
          }
          this.hasRight$.next(true);
          return;
        }
        this.currentIndx = this.currentIndx + 1;
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  _setURL(path: string): string {
    let url = path;
    if (path.charAt(0) == '/') {
      url = path.substring(1, path.length);
    }
    return this.basePath + `api/${this.subDomainService.subDomain}/files/download?path=` + url;
  }

  moveLeft() {
    this.moveCursor.next(-1);
  }

  moveRight() {
    this.moveCursor.next(1);
  }

  pdfLoadingProgress(progressData: PDFProgressData) {}

  onError(e: any) {}
  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
