import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { AdjustmentTemplateModel } from "../_models";
import { AdjustmentTemplateState } from "../_reducers";
import { adjustmentTemplateSelectors } from "../_selectors";
import { TemplateTableDataSource } from "./base-template.datasource";

export class AdjustmentTemplateDataSource extends TemplateTableDataSource<AdjustmentTemplateModel> {
    constructor(private store: Store<AdjustmentTemplateState>) {
        super();
        this.isLoading$ = this.store.select(adjustmentTemplateSelectors.selectIsLoading);
        this.total$ = this.store.select(adjustmentTemplateSelectors.selectTotal);

        this.store.pipe(
            takeUntil(this.destroy$),
            select(adjustmentTemplateSelectors.selectAdjustmentTemplates)
        ).subscribe(res => {
            this.entitySubject$.next(res);
        })
    }
}