<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Buildings</h3>
            <!--<h3 class="kt-portlet__head-title">{{data.title}}</h3>-->
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="onNoClick()"
               class="btn mr-2" mat-raised-button matTooltip="Close">
                <i class="ki ki-close"></i>
            </a>
        </div>
    </div>
    <div class="form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="card-body p-0">
            <agm-map [latitude]="centerLat$|async" [longitude]="centerLng$|async" [zoom]="15"
                     (mapClick)="mapClick()"
                     [scrollwheel]="null">
                <agm-map-type-control></agm-map-type-control>


                <!--<agm-marker *ngIf="lat != 0"-->
                            <!--[latitude]="lat"-->
                            <!--[longitude]="lng"-->
                            <!--animation="DROP"-->
                            <!--[iconUrl]="icon">-->
                <!--</agm-marker>-->

                <agm-marker *ngFor="let item of filteredBuildings"
                            [iconUrl]="item.id == currentBuildingId ? icon: null"
                            [latitude]="item.locationData.latitude"
                            [longitude]="item.locationData.longitude"
                            animation="DROP"
                            (markerClick)="showInfo(item.id, info)">
                    <agm-info-window #info [disableAutoPan]="true"
                        [zIndex]="100" >
                        <div class="card card-custom" style="width: 15vw;">
                            <div class="card-header p-1">
                                <div class="card-title ">
                                    <img 
                                    *ngIf="item.pictures[0]" 
                                    [src]="basePath + '/' + item.pictures[0].path + '/' + item.pictures[0].name" 
                                    (error)="imgOnError($event)"
                                    style="
                                        height: 50px;
                                        width: 50px;
                                        object-fit: cover;
                                    " loading="lazy">
                                    <h4 class="card-label ml-6">{{item.building_name}}</h4>
                                </div>
                            </div>
                            <div class="card-body p-1">
                                <p class="mb-1"><span class="text-mad">Description: </span>{{item.building_description}}</p>
                                <p class="mb-1"><span class="text-mad">Total floors: </span>{{item.total_floors}}</p>
                                <p class="mb-1"><span class="text-mad">Completion year: </span>{{item.completion_year}}</p>
                                <a (click)="clickItem(item)" style="float: right; cursor: pointer;">Choose it</a>
                            </div>
                        </div>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
        <!--<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot&#45;&#45;fit text-right">-->
        <!--<div class="kt-form__actions kt-form__actions&#45;&#45;solid">-->
        <!--<button mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;-->
        <!--</div>-->
        <!--</div>-->
    </div>
</div>
