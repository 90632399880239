// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const OFFICES_PATH = '/facilities';
const OFFICES_EXCLUSIVE_PATH = '/facilities/exlusive';
const OFFICES_BUILDING_PATH = '/building/facilities';

@Injectable()
export class AssetClassOfficeFacilitiesService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/offices/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAllAssetClassOfficeFacilities(assetClassOfficeFacilityId: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + assetClassOfficeFacilityId + OFFICES_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getAllAssetClassOfficeFacilitiesExclusive(assetClassOfficeFacilityId: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + assetClassOfficeFacilityId + OFFICES_EXCLUSIVE_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getAllAssetClassOfficeBuildingFacilities(assetClassOfficeFacilityId: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + assetClassOfficeFacilityId + OFFICES_BUILDING_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }
}
