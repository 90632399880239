<kt-portlet>
    <kt-portlet-header
        [sticky]="true"
        [title]="getComponentTitle()"
        [class]="'kt-portlet-head--lg'"
        [tooltipTitle]="'TOOLTIP.INSPECTION.FORM.TITLE' | translate"
        [tooltipDesc]="'TOOLTIP.INSPECTION.FORM.DESCRIPTION' | translate"
        >
        <ng-container ktPortletTools>
            <a href="javascript:;"
                (click)="back()"
                class="btn mr-2" mat-raised-button matTooltip="Back to ToE dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a  
                *ngIf="!readonly"
                href="javascript:;"
                class="btn btn-success mr-2"
                color="warn"
                style="background-color: orange !important;"
                (click)="onSubmit(false)"
                mat-raised-button
                matTooltip="Save to Draft">
                <span class="kt-hidden-mobile">Save draft</span>
            </a>
            <a 
                *ngIf="!readonly"
                href="javascript:;"
                mat-raised-button
                class="btn btn-success mr-2"
                color="submit"
                (click)="onSubmit(true)"
                matTooltip="Save to Validate">
                <span class="kt-hidden-mobile">Validate</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <div class="card-body pt-0" *ngIf="isLoaded">
            <form [formGroup]="formGroup">
                <kt-tab-header
                    [headerLabels]="tabHeaders"
                    [selectedTabChange]="selectedtabchange$"
                    (tabChange)="onTabChange($event)">
                </kt-tab-header>
                <mat-tab-group class="invinsible-tabs"
                    [(selectedIndex)]="selectedTab"
                    (selecetedTabChange)="onTabChanged($event)">
                    <!-- <mat-tab>
                        <ng-template mat-tab-label>
                        </ng-template>
                        <kt-form-error
                            [hasFormErrors]="hasFormError"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <kt-inspection-details
                            [typeOfInspection]="assetClass.type_of_inspection"
                            [readonly]="readonly"
                            [agencyName]="agencyName"
                            [clientName]="clientName"
                            [acDetailID]="assetClassDetail.id"
                            [participantsSubject]="participantsSubject"
                            [inputData]="inspectionDetailData"
                            [agencyId$]="agencyId$"
                            [toeId$]="toeId$"
                            [clientId$]="clientId$"
                            [timezoneOffset]="timezoneOffset"
                            ></kt-inspection-details>
                    </mat-tab> -->
                    <mat-tab>
                        <kt-form-error
                            [hasFormErrors]="hasFormError"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <kt-location
                            [locationData]="assetClassDetail.locationData"
                            [assetClassTypeId]="assetClass.type_id"
                            [selectedBuildingId]="assetClassDetail.buildingInfo?.infoSection?.building_id"
                            (markerChange)="onMarkerChange($event)"
                            (buildingSelected)="onBuildingSelected($event)"
                            (timezoneChange)="onTimezoneChange($event)"></kt-location>
                        <!-- <kt-map-location
                            [title]="'Location'"
                            [showMap]="true"
                            [lockMarker]="false"
                            [isComplexForm]="true"
                            [locationData]="assetClassDetail.locationData"
                            [parentType]="'inspection'"
                            (markerChange)="onMarkerChange($event)"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"></kt-map-location> -->
                        <kt-inspection-land-parcel
                            *ngIf="assetClass.type_id != 5 && assetClass.type_id != 1 && assetClass.type_id != 3"
                            [inputData]="landParcelData"
                            [points]="points"
                            [markerInfo]="markerInfo"
                            [centerLat]="centerLat"
                            [centerLng]="centerLng"
                            [staticUnit]="staticUnit"></kt-inspection-land-parcel>

                        <kt-building-form
                            *ngIf="assetClass.type_id == 5 || assetClass.type_id == 1 || assetClass.type_id == 3 || (assetClass.type_id ==2 && showBuilding)"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (centerChange)="onCenterChange($event)"
                            (picChange)="buildingPicChange($event)"
                            [buildingPictures]="assetClassDetail.building.pictures"
                            [buildingPicturePath]="assetClassDetail.building.picture"
                            [isComplexForm]="true"
                            [acBuilding]="assetClassDetail.buildingInfo"
                            [building$]="building$"
                            [parent]="'Inspection'"
                            >

                        </kt-building-form>

                    </mat-tab>
                    <mat-tab>
                        <kt-form-error
                            [hasFormErrors]="hasFormError"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <kt-about-property
                            [inputData]="aboutPropertyData"
                            [propertySubTypeID]="assetClass.type_id"
                            [accommodation]="accommodationData"
                            (roomChange)="onRoomChanged($event)"
                            (floorChanged)="onFloorChanged($event)"
                            (subTypeCategoryChanged)="onSubTypeCategoryChange($event)"></kt-about-property>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="assetClass.type_id == 7 || assetClass.type_id == 11 || assetClass.type_id == 17 || (assetClass.type_id == 2 && !showBuilding)">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Services and Infrastructures </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-services-and-infrastructures 
                                    [propertySubTypeID]="assetClass.type_id"
                                    [isComplexForm]="true"
                                    [serviceAndInfraData]="assetClassDetail.serviceAndInfraData"
                                    ></kt-services-and-infrastructures>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="assetClass.type_id != 5 && assetClass.type_id != 2 && assetClass.type_id != 1 && assetClass.type_id != 3">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">External Aspect</h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-external-aspects 
                                    [propertySubTypeID]="assetClass.type_id" 
                                    [externalAspectData]="assetClassDetail.externalAspectData"></kt-external-aspects>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="assetClass.type_id != 2">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Internal Aspect</h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-internal-aspects 
                                    [propertySubTypeID]="assetClass.type_id" 
                                    [internalAspectData]="assetClassDetail.internalAspectData"></kt-internal-aspects>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="assetClass.type_id != 2">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Grounds</h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-ac-grounds 
                                    [parent]="'inspection'"
                                    [isComplexForm]="true" 
                                    [propertySubTypeID]="assetClass.type_id"
                                    [initData]="assetClassDetail.grounds"></kt-ac-grounds>
                            </div>
                        </div>

                        <kt-inspection-size-measurements
                            [isInstructedToMeasure]="assetClass.instructed_to_measure == 1"
                            [propertySubTypeID]="assetClass.type_id"
                            [acSizeData]="acSizeInfo"
                            [measurementData]="acMeasurementData"
                            [assetClassID]="assetClass.id"
                            [readonly]="readonly"
                            [sizesSubject]="sizesSubject"
                            [roomChange$]="roomChange$"
                            (staticUnitChange)="onStaticUnitChange($event)">
                        </kt-inspection-size-measurements>
                    </mat-tab>
                    <mat-tab>
                        <kt-form-error
                            [hasFormErrors]="hasFormError"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <ng-container *ngIf="(assetClass.type_of_inspection == 3 || assetClass.type_of_inspection == 4) && showConditionRating">
                            <kt-inspection-condition-ratings 
                                class="section-margin-top-60"
                                *ngIf="hasComponents"
                            >    
                            </kt-inspection-condition-ratings>
                            <kt-inspection-condition-ratings-upload 
                                class="section-margin-top-60"
                                *ngIf="!hasComponents"
                                [files]="assetClassDetail.condition_rating_documents">
                            </kt-inspection-condition-ratings-upload>
                        </ng-container>

                        <div class="col-md-12 mt-4">
                            <div class="mb-2">
                                <mat-label>Would you like to record potential environmental issues for this property?</mat-label>
                                <mat-radio-group class="ml-4" formControlName="record_potential_env_issues">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <kt-inspection-observation 
                            class="section-margin-top-60"
                            *ngIf="recordPotentialEnvIssue"
                            [propertyTypeID]="assetClass.top_property_type_id"
                            [recordInfoType]="assetClassDetail.record_info_type"
                            [observationChecklist]="assetClassDetail.observation_checklist"
                            [observationDocuments]="assetClassDetail.observation_documents">
                        </kt-inspection-observation>
                    </mat-tab>
                    <mat-tab>
                        <kt-form-error
                            [hasFormErrors]="hasFormError"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <kt-inspection-pictures
                            [picture]="assetClassDetail.picture"
                            [pictures]="assetClassDetail.ac_pictures"
                            [readonly]="readonly"
                            [propertyTypeID]="assetClass.top_property_type_id"
                            [conditionRatingDropdownValues$]="conditionRatingDropdownValues$"
                            [conditionRatingRelated$]="conditionRatingRelated$"></kt-inspection-pictures>

                        <kt-inspection-building-pictures
                            *ngIf="assetClass.type_id == 1 || assetClass.type_id == 3 || assetClass.type_id == 5 || (assetClass.type_id == 2 && showBuilding)"
                            [buildingPictures]="building_pictures"
                            [buildingPicturePath]="building_picture"
                            [hasFormErrors]="hasFormError"
                            [buildingId]="assetClassDetail.building.building_id"
                            ></kt-inspection-building-pictures>

                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Property Videos</h5>
                                <hr class="active">
                            </div>
                            <app-upload
                                class="col-md-12"
                                [videos]="assetClassDetail.videos"
                                [readonly]="readonly"></app-upload>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormError && videoTitleRequiredError$|async">
                                    Video should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Matterport Link</h5>
                                <hr class="active">
                            </div>
                            <kt-link-list
                                class="col-md-12"
                                [links]="assetClassDetail.links"
                                [linkType]="1"
                                [readOnly]="readonly">
                            </kt-link-list>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                
                


            </form>
        </div>
    </kt-portlet-body>
</kt-portlet>