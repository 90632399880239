<tag-input
    [ngModel]="selectedPropertySubTypes"
    (onAdd)="onPropertySubTypeAdd($event)"
    (onRemove)="onPropertySubTypeRemove($event)"
    [onlyFromAutocomplete]="true"
    [placeholder]="'Select additional property sub-type'"
    [secondaryPlaceholder]="'Select additional property sub-type'"
    #input>
    <tag-input-dropdown 
        [showDropdownIfEmpty]="true"
        [autocompleteItems]="propertySubTypes"></tag-input-dropdown>
    <ng-template let-item="item" let-index="index">
        <div [class]="item.className">
            {{item.display}}
            <delete-icon *ngIf="!item.readonly" (click)="input.removeItem(item, index)"></delete-icon>
        </div>
    </ng-template>
</tag-input>