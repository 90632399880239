// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {RentReviewCommentsService} from '../_services/rent-review-comment.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allRentReviewCommentsLoaded} from '../_selectors/rent-review-comment.selectors';
// Actions
import {
    AllRentReviewCommentsLoaded,
    AllRentReviewCommentsRequested,
    RentReviewCommentActionTypes,
    RentReviewCommentsPageRequested,
    RentReviewCommentsPageLoaded,
    RentReviewCommentUpdated,
    RentReviewCommentsPageToggleLoading,
    RentReviewCommentDeleted,
    RentReviewCommentOnServerCreated,
    RentReviewCommentCreated,
    RentReviewCommentAdminRestored,
    RentReviewCommentDeletedFromAdminTrash,
    RentReviewCommentDeletedFromTrash,
    RentReviewCommentOnServerAdminRestored,
    RentReviewCommentOnServerRestored,
    RentReviewCommentRestored,
    RentReviewCommentTrashFlushed,
    RentReviewCommentsActionToggleLoading
} from '../_actions/rent-review-comment.actions';

@Injectable()
export class RentReviewCommentEffects {
    showPageLoadingDispatcher = new RentReviewCommentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RentReviewCommentsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RentReviewCommentsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllRentReviewComments$ = this.actions$
        .pipe(
            ofType<AllRentReviewCommentsRequested>(RentReviewCommentActionTypes.AllRentReviewCommentsRequested),
            withLatestFrom(this.store.pipe(select(allRentReviewCommentsLoaded))),
            filter(([action, isAllRentReviewCommentsLoaded]) => !isAllRentReviewCommentsLoaded),
            mergeMap(() => this.service.getAllRentReviewComments()),
            map(res => {
                return new AllRentReviewCommentsLoaded({rentReviewComments: res.data});
            })
        );

    @Effect()
    loadRentReviewCommentsPage$ = this.actions$
        .pipe(
            ofType<RentReviewCommentsPageRequested>(RentReviewCommentActionTypes.RentReviewCommentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findRentReviewComments(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new RentReviewCommentsPageLoaded({
                    rentReviewComments: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentDeleted>(RentReviewCommentActionTypes.RentReviewCommentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteRentReviewComment(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentUpdated>(RentReviewCommentActionTypes.RentReviewCommentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateRentReviewComment(payload.rentReviewComment);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentOnServerCreated>(RentReviewCommentActionTypes.RentReviewCommentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createRentReviewComment(payload.rentReviewComment).pipe(
                    tap(res => {
                        this.store.dispatch(new RentReviewCommentCreated({rentReviewComment: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushRentReviewCommentTrash$ = this.actions$
        .pipe(
            ofType<RentReviewCommentTrashFlushed>(RentReviewCommentActionTypes.RentReviewCommentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentOnServerRestored>(RentReviewCommentActionTypes.RentReviewCommentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RentReviewCommentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentOnServerAdminRestored>(RentReviewCommentActionTypes.RentReviewCommentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RentReviewCommentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentDeletedFromTrash>(RentReviewCommentActionTypes.RentReviewCommentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashRentReviewComment$ = this.actions$
        .pipe(
            ofType<RentReviewCommentDeletedFromAdminTrash>(RentReviewCommentActionTypes.RentReviewCommentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: RentReviewCommentsService, private store: Store<AppState>) {
    }
}
