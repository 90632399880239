import {PurposeMeasurement} from '../_models/purpose-measurement.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PurposeMeasurementsState} from '../_reducers/purpose-measurement.reducers';
import * as fromPurposeMeasurement from '../_reducers/purpose-measurement.reducers';
import {each} from 'lodash';

export const selectPurposeMeasurementsState = createFeatureSelector<PurposeMeasurementsState>('purposeMeasurements');

export const selectPurposeMeasurementById = (purposeMeasurementId: number) => createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => purposeMeasurementsState.entities[purposeMeasurementId]
);

export const selectAllPurposeMeasurements = createSelector(
    selectPurposeMeasurementsState,
    fromPurposeMeasurement.selectAll
);

export const selectAllPurposeMeasurementsIds = createSelector(
    selectPurposeMeasurementsState,
    fromPurposeMeasurement.selectIds
);

export const allPurposeMeasurementsLoaded = createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => purposeMeasurementsState.isAllPurposeMeasurementsLoaded
);


export const selectPurposeMeasurementsPageLoading = createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => purposeMeasurementsState.listLoading
);

export const selectPurposeMeasurementsActionLoading = createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => purposeMeasurementsState.actionLoading
);

export const selectLastCreatedPurposeMeasurementId = createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => purposeMeasurementsState.lastCreatedPurposeMeasurementId
);

export const selectPurposeMeasurementsShowInitWaitingMessage = createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => purposeMeasurementsState.showInitWaitingMessage
);

export const selectTrashedPurposeMeasurementCount = createSelector(
    selectPurposeMeasurementsState,
    (purposeMeasurementsState) => purposeMeasurementsState.totalTrashed
);

export const selectAdminTrashedPurposeMeasurementCount = createSelector(
    selectPurposeMeasurementsState,
    (purposeMeasurementsState) => purposeMeasurementsState.totalAdminTrashed
);


export const selectPurposeMeasurementQueryResult = createSelector(
    selectPurposeMeasurementsState,
    purposeMeasurementsState => {
        const items: PurposeMeasurement[] = [];
        each(purposeMeasurementsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PurposeMeasurement[] = httpExtension.sortArray(items, purposeMeasurementsState.lastQuery.sortField, purposeMeasurementsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, purposeMeasurementsState.totalCount, '', purposeMeasurementsState.totalTrashed);
    }
);
