import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AdjustmentTemplateActions, AdjustmentTemplateActionTypes } from "../_actions";
import { AdjustmentTemplateModel } from "../_models";

export interface AdjustmentTemplateState extends EntityState<AdjustmentTemplateModel> {
    isLoading: boolean;
    total: number;
}
export const adapter: EntityAdapter<AdjustmentTemplateModel> = createEntityAdapter<AdjustmentTemplateModel>();
export const initialState: AdjustmentTemplateState = adapter.getInitialState({
    isLoading: false,
    total: 0
});

export function adjustmentTemplateReducer(state = initialState, action: AdjustmentTemplateActions): AdjustmentTemplateState {
    switch (action.type) {
        case AdjustmentTemplateActionTypes.LOAD_ADJUSTMENT_TEMPLATES: {
            return {
                ...state,
                isLoading: true
            };
        }
        case AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATES_LOADED: {
            return adapter.setAll(action.payload.templates, {
                ...state,
                isLoading: false,
                total: action.payload.total
            })
        }
        case AdjustmentTemplateActionTypes.ADD_ADJUSTMENT_TEMPLATE: {
            return state;
        }
        case AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATE_ADDED: {
            return adapter.addOne(action.payload.template, state);
        }
        case AdjustmentTemplateActionTypes.UPDATE_ADJUSTMENT_TEMPLATE: {
            return state;
        }
        case AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATE_UPDATED: {
            return adapter.updateOne(action.payload.template, state);
        }
        case AdjustmentTemplateActionTypes.DELETE_ADJUSTMENT_TEMPLATE: {
            return state;
        }
        case AdjustmentTemplateActionTypes.ADJUSTMENT_TEMPLATE_DELETED: {
            return adapter.removeOne(action.payload.id, state);
        }
        default: {
            return state;
        }
    }
}