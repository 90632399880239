<!--begin::Body-->
<div class="kt-login-body">
	<!--begin::Signin-->
	<div class="kt-login-form">
		<h1>
			<a href="https://www.interval-soft.com/" title="Login Page">
				Register Page
			</a>
		</h1>

		<kt-auth-notice></kt-auth-notice>

		<form class="kt-login-form kt-form" [formGroup]="registerForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field floatLabel="always" class="mat-form-field-fluid">
					<mat-label>First name</mat-label>
					<input matInput type="text" placeholder="First name" formControlName="first_name"/>
					<mat-error *ngIf="isControlHasError('first_name','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('first_name','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('first_name','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field floatLabel="always" class="mat-form-field-fluid">
					<mat-label>Last name</mat-label>
					<input matInput type="text" placeholder="Last name" formControlName="last_name"/>
					<mat-error *ngIf="isControlHasError('last_name','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('last_name','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('last_name','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field floatLabel="always" class="mat-form-field-fluid">
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="form-group">
				<mat-form-field floatLabel="always" class="mat-form-field-fluid">
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field floatLabel="always" class="mat-form-field-fluid">
					<mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword" autocomplete="off"/>
					<mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-checkbox (change)="checked($event)" name="agree" formControlName="agree">
					I agree the <a (click)="showTerms()" href="javascript:;">terms & conditions</a>
				</mat-checkbox>
			</div>

			<!--begin::Action-->
			<div class="kt-login-actions">
				<button (click)="submit()"
						id="kt_login_signin_submit"
						class="btn btn-primary btn-elevate kt-login-btn-primary"
						[disabled]="!isChecked"
						[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}
				</button>
			</div>
			<!--end::Action-->
		</form>

		<p style="text-align: center; margin-top: 3rem;">
			<a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
				{{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
			</a>
		</p>
		<p style="text-align: center">
			<a href="javascript:;" routerLink="/auth/login" class="kt-link kt-login__link-forgot">
				{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
			</a>
		</p>

	</div>
</div>
