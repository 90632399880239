import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalAspectData } from 'src/app/core/comparable/_models/asset-class-external-aspect.model';
import { AllChimneyStacksRequested, AllConservatoryPorchesRequested, AllExternalFacadesRequested, AllMainWallsRequested, AllOtherJoinerysRequested, AllOutsideDoorsRequested, AllRainwaterPipesRequested, AllRoofCoveringsRequested, AllWindowTypesRequested, ChimneyStackModel, ConservatoryPorcheModel, ExternalFacadeModel, MainWallModel, OtherJoineryModel, OutsideDoorModel, RainwaterPipeModel, RoofCoveringModel, selectAllChimneyStacks, selectAllConservatoryPorches, selectAllExternalFacades, selectAllMainWalls, selectAllOtherJoinerys, selectAllOutsideDoors, selectAllRainwaterPipes, selectAllRoofCoverings, selectAllWindowTypes, WindowType } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';

@Component({
  selector: 'kt-external-aspects',
  templateUrl: './external-aspects.component.html',
  styleUrls: ['./external-aspects.component.scss']
})
export class ExternalAspectsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() readonly: boolean = false;
  @Input() propertySubTypeID: number;
  @Input() externalAspectData: ExternalAspectData;
  formGroup: UntypedFormGroup;

  private _onDestroy$: Subject<void> = new Subject();

  // Linked tables
  public chimneyStacks: ChimneyStackModel[] = [];
  public roofCoverings: RoofCoveringModel[] = [];
  public rainwaterPipes: RainwaterPipeModel[] = [];
  public mainwalls: MainWallModel[] = [];
  public windows: WindowType[] = [];
  public outsideDoors: OutsideDoorModel[] = [];
  public conservatories: ConservatoryPorcheModel[] = [];
  public otherJoineries: OtherJoineryModel[] = [];
  public externalFacades: ExternalFacadeModel[] = [];

  private chimneyStacks$: BehaviorSubject<ChimneyStackModel[]> = new BehaviorSubject([]);
  private roofCoverings$: BehaviorSubject<RoofCoveringModel[]> = new BehaviorSubject([]);
  private rainwaterPipes$: BehaviorSubject<RainwaterPipeModel[]> = new BehaviorSubject([]);
  private mainwalls$: BehaviorSubject<MainWallModel[]> = new BehaviorSubject([]);
  private windows$: BehaviorSubject<WindowType[]> = new BehaviorSubject([]);
  private outsideDoors$: BehaviorSubject<OutsideDoorModel[]> = new BehaviorSubject([]);
  private conservatories$: BehaviorSubject<ConservatoryPorcheModel[]> = new BehaviorSubject([]);
  private otherJoineries$: BehaviorSubject<OtherJoineryModel[]> = new BehaviorSubject([]);
  private externalFacades$: BehaviorSubject<ExternalFacadeModel[]> = new BehaviorSubject([]);

  private _formFields = [
    {
      select_name: 'Chimney stacks',
      select_formControlName: 'chimney_stack_id',
      selectables: this.chimneyStacks$.asObservable(),
      comment_name: 'Chimney stack(s) comments',
      comment_formControlName: 'chimney_stack_comment',
      ac_ids: [17]
    },
    {
      select_name: 'Roof coverings',
      select_formControlName: 'roof_covering_id',
      selectables: this.roofCoverings$.asObservable(),
      comment_name: 'Roof covering comments',
      comment_formControlName: 'roof_covering_comment',
      ac_ids: [7, 11, 17, 0]
    },
    {
      select_name: 'Rainwater pipes and gutters',
      select_formControlName: 'rainwater_pipe_id',
      selectables: this.rainwaterPipes$.asObservable(),
      comment_name: 'Rainwater pipes and gutters comments',
      comment_formControlName: 'rainwater_pipe_comment',
      ac_ids: [7, 11, 17]
    },
    {
      select_name: 'Main walls',
      select_formControlName: 'mainwall_id',
      selectables: this.mainwalls$.asObservable(),
      comment_name: 'Main walls comments',
      comment_formControlName: 'mainwall_comment',
      ac_ids: [7, 11, 17, 0]
    },
    {
      select_name: 'Windows',
      select_formControlName: 'window_type_id',
      selectables: this.windows$.asObservable(),
      comment_name: 'Windows comments',
      comment_formControlName: 'window_type_comment',
      ac_ids: [7, 11, 17, 0]
    },
    {
      select_name: 'Outside doors',
      select_formControlName: 'outside_door_id',
      selectables: this.outsideDoors$.asObservable(),
      comment_name: 'Outside doors comments',
      comment_formControlName: 'outside_door_comment',
      ac_ids: [7, 11, 17]
    },
    {
      select_name: 'Conservatory and porches',
      select_formControlName: 'conservatory_id',
      selectables: this.conservatories$.asObservable(),
      comment_name: 'Conservatory and porches comments',
      comment_formControlName: 'conservatory_comment',
      ac_ids: [17]
    },
    {
      select_name: 'Other joinery and finishes',
      select_formControlName: 'other_joinery_id',
      selectables: this.otherJoineries$.asObservable(),
      comment_name: 'Other joinery and finishes comments',
      comment_formControlName: 'other_joinery_comment',
      ac_ids: [17]
    },
    {
      select_name: 'External Facades',
      select_formControlName: 'external_facade_id',
      selectables: this.externalFacades$.asObservable(),
      comment_name: 'External Facades comments',
      comment_formControlName: 'external_facade_comment',
      ac_ids: [0]
    }
  ];
  public formFields = []
  errorFields = [];

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.formGroup && changes.externalAspectData && !changes.externalAspectData.firstChange) {
      this._populateForm(this.formGroup);
    }
  }

  private _populateForm(formGroup?: UntypedFormGroup) {
    if (formGroup) {
      formGroup.patchValue({
        chimney_stack_id: this.externalAspectData.chimney_stack_id,
        chimney_stack_comment: this.externalAspectData.chimney_stack_comment,
        roof_covering_id: this.externalAspectData.roof_covering_id,
        roof_covering_comment: this.externalAspectData.roof_covering_comment,
        rainwater_pipe_id: this.externalAspectData.rainwater_pipe_id,
        rainwater_pipe_comment: this.externalAspectData.rainwater_pipe_comment,
        outside_door_id: this.externalAspectData.outside_door_id,
        outside_door_comment: this.externalAspectData.outside_door_comment,
        mainwall_id: this.externalAspectData.main_wall_id,
        mainwall_comment: this.externalAspectData.main_wall_comment,
        window_type_id: this.externalAspectData.window_type_id,
        window_type_comment: this.externalAspectData.window_type_comment,
        conservatory_id: this.externalAspectData.conservatory_id,
        conservatory_comment: this.externalAspectData.conservatory_comment,
        other_joinery_id: this.externalAspectData.other_joinery_id,
        other_joinery_comment: this.externalAspectData.other_joinery_comment,
        loading_dock: this.externalAspectData.loading_dock,
        loading_dock_num: this.externalAspectData.loading_dock_num,
        loading_dock_comment: this.externalAspectData.loading_dock_comment,
        external_facade_id: this.externalAspectData.external_facade_id,
        external_facade_comment: this.externalAspectData.external_facade_comment,
        has_other: this.externalAspectData.has_other,
        other_aspects: this.externalAspectData.other_aspects
      });
      formGroup.updateValueAndValidity();
    } else {
      this.formGroup = this.formBuilder.group({
        chimney_stack_id: [this.externalAspectData.chimney_stack_id, this.propertySubTypeID == 17 ? Validators.required : null],
        chimney_stack_comment: [this.externalAspectData.chimney_stack_comment],
        roof_covering_id: [this.externalAspectData.roof_covering_id, (this.propertySubTypeID == 0) ? null : Validators.required],
        roof_covering_comment: [this.externalAspectData.roof_covering_comment],
        rainwater_pipe_id: [this.externalAspectData.rainwater_pipe_id, this.propertySubTypeID != 0 ? Validators.required : null],
        rainwater_pipe_comment: [this.externalAspectData.rainwater_pipe_comment],
        outside_door_id: [this.externalAspectData.outside_door_id, this.propertySubTypeID != 0 ? Validators.required : null],
        outside_door_comment: [this.externalAspectData.outside_door_comment],
        mainwall_id: [this.externalAspectData.main_wall_id, (this.propertySubTypeID == 0) ? null : Validators.required],
        mainwall_comment: [this.externalAspectData.main_wall_comment],
        window_type_id: [this.externalAspectData.window_type_id, (this.propertySubTypeID == 0) ? null : Validators.required],
        window_type_comment: [this.externalAspectData.window_type_comment],
        conservatory_id: [this.externalAspectData.conservatory_id, this.propertySubTypeID == 17 ? Validators.required : null],
        conservatory_comment: [this.externalAspectData.conservatory_comment],
        other_joinery_id: [this.externalAspectData.other_joinery_id, this.propertySubTypeID == 17 ? Validators.required : null],
        other_joinery_comment: [this.externalAspectData.other_joinery_comment],
        loading_dock: [this.externalAspectData.loading_dock, (this.propertySubTypeID == 7 || this.propertySubTypeID == 11) ? Validators.required : null],
        loading_dock_num: [this.externalAspectData.loading_dock_num],
        loading_dock_comment: [this.externalAspectData.loading_dock_comment],
        external_facade_id: [this.externalAspectData.external_facade_id],
        external_facade_comment: [this.externalAspectData.external_facade_comment],
        has_other: [this.externalAspectData.has_other],
        other_aspects: [this.externalAspectData.other_aspects]
      });
    }
  }

  ngOnInit(): void {
    this.formFields = this._getFormFields(this.propertySubTypeID);
    this._fetchLTs();

    this._populateForm();

    this.formGroup.controls.has_other.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      if (val) {
        this.formGroup.controls.other_aspects.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.other_aspects.clearValidators();
      }
      this.formGroup.controls.other_aspects.updateValueAndValidity();
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _fetchLTs() {
    this.store.dispatch(new AllChimneyStacksRequested());
    this.store.select(selectAllChimneyStacks).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.chimneyStacks = res ? res : [];
      this.chimneyStacks$.next(this.chimneyStacks);
    });

    this.store.dispatch(new AllRoofCoveringsRequested());
    this.store.select(selectAllRoofCoverings).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.roofCoverings = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.roofCoverings$.next(this.roofCoverings);
    });

    this.store.dispatch(new AllRainwaterPipesRequested());
    this.store.select(selectAllRainwaterPipes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.rainwaterPipes = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.rainwaterPipes$.next(this.rainwaterPipes);
    });

    this.store.dispatch(new AllMainWallsRequested());
    this.store.select(selectAllMainWalls).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.mainwalls = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.mainwalls$.next(this.mainwalls);
    });

    this.store.dispatch(new AllWindowTypesRequested());
    this.store.select(selectAllWindowTypes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.windows = res ? res : []
      this.windows$.next(this.windows);
    });

    this.store.dispatch(new AllOutsideDoorsRequested());
    this.store.select(selectAllOutsideDoors).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.outsideDoors = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
      this.outsideDoors$.next(this.outsideDoors);
    });

    this.store.dispatch(new AllConservatoryPorchesRequested());
    this.store.select(selectAllConservatoryPorches).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.conservatories = res ? res : [];
      this.conservatories$.next(this.conservatories);
    });

    this.store.dispatch(new AllOtherJoinerysRequested());
    this.store.select(selectAllOtherJoinerys).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.otherJoineries = res ? res : [];
      this.otherJoineries$.next(this.otherJoineries);
    });

    this.store.dispatch(new AllExternalFacadesRequested());
    this.store.select(selectAllExternalFacades).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.externalFacades = res ? res : [];
      this.externalFacades$.next(this.externalFacades);
    })
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public changeValidation() {
    Object.keys(this.formGroup.controls).forEach(cName => {
      this.formGroup.controls[cName].clearValidators();
      this.formGroup.controls[cName].updateValueAndValidity();
    })
  }

  public getData(): ExternalAspectData {
    const controls = this.formGroup.controls;
    return {
      id: this.externalAspectData.id,
      chimney_stack_id: controls.chimney_stack_id.value,
      chimney_stack_comment: controls.chimney_stack_comment.value,

      roof_covering_id: controls.roof_covering_id.value,
      roof_covering_comment: controls.roof_covering_comment.value,

      rainwater_pipe_id: controls.rainwater_pipe_id.value,
      rainwater_pipe_comment: controls.rainwater_pipe_comment.value,

      main_wall_id: controls.mainwall_id.value,
      main_wall_comment: controls.mainwall_comment.value,

      window_type_id: controls.window_type_id.value,
      window_type_comment: controls.window_type_comment.value,

      outside_door_id: controls.outside_door_id.value,
      outside_door_comment: controls.outside_door_comment.value,

      conservatory_id: controls.conservatory_id.value,
      conservatory_comment: controls.conservatory_comment.value,

      other_joinery_id: controls.other_joinery_id.value,
      other_joinery_comment: controls.other_joinery_comment.value,

      loading_dock: controls.loading_dock.value,
      loading_dock_num: controls.loading_dock_num.value,
      loading_dock_comment: controls.loading_dock_comment.value,

      external_facade_id: controls.external_facade_id.value,
      external_facade_comment: controls.external_facade_comment.value,

      has_other: controls.has_other.value,
      other_aspects: controls.other_aspects.value
    }
  }

  _getFormFields(id: number): any[] {
    return this._formFields.filter(field => {
      const ind = field.ac_ids.findIndex(_id => _id == id);
      if (ind > -1) {
        return true;
      }
      return false;
    })
  }
}
