import * as moment from "moment";
import { ConvertedCostRevenue } from "../../types";
import { EffectiveRentUtil } from "../headline-rent";
import { totalNonRecoverable, totalRecoverable } from "../../utils";
import { miniFs, rounddown4 } from "./utils";
import { ModelAssumptionsUtil } from "./model-assumptions-util";

export function calculateLeaseholdDualRateWithASFAndAllowanceForTax(
  costRevenue: ConvertedCostRevenue,
  subCostRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {
    activeBreakOffOption, 
    allRiskYield, 
    annualSinkingFund, 
    taxRate, 
    timingId
  }: {
    activeBreakOffOption: boolean,
    allRiskYield: number,
    annualSinkingFund: number,
    taxRate: number,
    timingId: number
  }
) {
  const _valuationDate = valuationDate ? moment(valuationDate) : null
  const yearsToReview = _valuationDate ? ModelAssumptionsUtil.yearsToReview(costRevenue, _valuationDate) : null
  const yearsToWriteOff = _valuationDate ? ModelAssumptionsUtil.yearsToWriteOff(costRevenue, _valuationDate) : null
  const yearsToBreakOff = _valuationDate ? ModelAssumptionsUtil.yearsToBreakOff(costRevenue, _valuationDate) : null

  const headEffectiveRent = EffectiveRentUtil.getValueFromConvertedCostRevenue(costRevenue)
  const headTotalRecoverable = totalRecoverable(costRevenue) * 12
  const headTotalNonRecoverable = totalNonRecoverable(costRevenue) * 12
  const passingRent = headEffectiveRent + headTotalRecoverable - headTotalNonRecoverable

  const subEffectiveRent = subCostRevenue ? EffectiveRentUtil.getValueFromConvertedCostRevenue(subCostRevenue) : undefined
  const subTotalRecoverable = subCostRevenue ? totalRecoverable(subCostRevenue) * 12 : undefined
  const subTotalNonRecoverable = subCostRevenue ? totalNonRecoverable(subCostRevenue) * 12 : undefined
  const profitRent = subEffectiveRent + subTotalRecoverable - subTotalNonRecoverable - passingRent

  const minYear = miniFs(
    activeBreakOffOption
      ? {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff, 'Years to Break off': yearsToBreakOff}
      : {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff}
  )
  const _yieldPurchase = yieldPurchase(allRiskYield, annualSinkingFund, taxRate, minYear.value, timingId) 
  const capitalValue = profitRent * _yieldPurchase

  return {
    headEffectiveRent,
    headRecoverable: {
      total: headTotalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * 12,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * 12, 
      recoverableUtilities: costRevenue.recoverableUtilities * 12,
      recoverableMaintenance: costRevenue.recoverableMaintenance * 12,
      recoverableInsurance: costRevenue.recoverableInsurance * 12,
      recoverableJanitorial: costRevenue.recoverableJanitorial * 12,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * 12,
      recoverableBusiness: costRevenue.recoverableBusiness * 12,
      recoverableOthers: costRevenue.recoverableOthers * 12,
    },
    headNonRecoverable: {
      total: headTotalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * 12,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * 12, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * 12,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * 12,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * 12,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * 12,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * 12,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * 12,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * 12,
    },
    passingRent,
    subEffectiveRent,
    subRecoverable: {
      total: subTotalRecoverable,
      recoverablePropertyManagement: subCostRevenue ? subCostRevenue.recoverablePropertyManagement * 12 : undefined,
      recoverableLeasingExpenses: subCostRevenue ? subCostRevenue.recoverableLeasingExpenses * 12 : undefined, 
      recoverableUtilities: subCostRevenue ? subCostRevenue.recoverableUtilities * 12 : undefined,
      recoverableMaintenance: subCostRevenue ? subCostRevenue.recoverableMaintenance * 12 : undefined,
      recoverableInsurance: subCostRevenue ? subCostRevenue.recoverableInsurance * 12 : undefined,
      recoverableJanitorial: subCostRevenue ? subCostRevenue.recoverableJanitorial * 12 : undefined,
      recoverablePropertyTaxes: subCostRevenue ? subCostRevenue.recoverablePropertyTaxes * 12 : undefined,
      recoverableBusiness: subCostRevenue ? subCostRevenue.recoverableBusiness * 12 : undefined,
      recoverableOthers: subCostRevenue ? subCostRevenue.recoverableOthers * 12 : undefined,
    },
    subNonRecoverable: {
      total: subTotalNonRecoverable,
      nonRecoverablePropertyManagement: subCostRevenue ? subCostRevenue.nonRecoverablePropertyManagement * 12 : undefined,
      nonRecoverableLeasingExpenses: subCostRevenue ? subCostRevenue.nonRecoverableLeasingExpenses * 12 : undefined, 
      nonRecoverableUtilities: subCostRevenue ? subCostRevenue.nonRecoverableUtilities * 12 : undefined,
      nonRecoverableMaintenance: subCostRevenue ? subCostRevenue.nonRecoverableMaintenance * 12 : undefined,
      nonRecoverableInsurance: subCostRevenue ? subCostRevenue.nonRecoverableInsurance * 12 : undefined,
      nonRecoverableJanitorial: subCostRevenue ? subCostRevenue.nonRecoverableJanitorial * 12 : undefined,
      nonRecoverablePropertyTaxes: subCostRevenue ? subCostRevenue.nonRecoverablePropertyTaxes * 12 : undefined,
      nonRecoverableBusiness: subCostRevenue ? subCostRevenue.nonRecoverableBusiness * 12 : undefined,
      nonRecoverableOthers: subCostRevenue ? subCostRevenue.nonRecoverableOthers * 12 : undefined,
    },
    profitRent: isNaN(profitRent) ? undefined : profitRent,
    yieldPurchase: {
      value: _yieldPurchase,
      year: minYear 
    },
    value: capitalValue
  }
}

function yieldPurchase(
  allRiskYield: number,
  annualSinkingFund: number,
  taxRate: number,
  year: number,
  timingId: number
) {
  if (year === null) return undefined
  const ary = allRiskYield / 100
  const asf = annualSinkingFund / 100
  const tax = 100/(100 - taxRate)

  const denom = Math.pow(1 + asf, year) - 1

  const aryV = timingId == 1 
    ? ary
    : timingId == 2
      ? 4 * (1 - Math.pow(1 + ary, -0.25))
      : undefined
  const asfV = timingId == 1
    ? asf
    : timingId == 2
      ? 4 * (1 - Math.pow(1 + asf, -0.25))
      : undefined

  const temp1 = asfV / denom 
  const temp2 = temp1 * tax 
  const temp3 = aryV + temp2
  if (temp3 == 0) return undefined
  const value = 1 / temp3
  return rounddown4(value)
}