import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';
import { HttpUtilsService } from 'src/app/core/_base/crud';
import { InitializeTranslationService } from 'src/app/core/_base/translation/initialize-translation.service';
import { environment } from 'src/environments/environment';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-i18n-editor',
  templateUrl: './i18n-editor.component.html',
  styleUrls: ['./i18n-editor.component.scss']
})
export class I18nEditorComponent implements OnInit {
  form: UntypedFormGroup;
  tooltipSections$: Observable<{
    rawField: string,
    field: string,
  }[]>;
  tooltipSections: {
    rawField: string,
    field: string,
  }[] = [];
  tooltipConfig$ = new BehaviorSubject<any>(null);

  templateData$ : Observable<{
    sections: {
      rawField: string,
      field: string,
    }[],
    ltSections: {
      rawField: string,
      field: string,
    }[],
    data: {
      ctrlName: string,
      placeholder: string,
      size: string
    }[]
  }>;

  saveState = true;

  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private translationService: InitializeTranslationService,
    private router: Router,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      section: [null],
      lt: [null]
    });

    const fieldTitleMap: {
      prev: string,
      new: string,
    }[] = [{
      prev: 'CONFLICT_OF_INTEREST',
      new: 'Valuation - Section Conflict of Interest '
    }, {
      prev: 'DISCLOSURE',
      new: 'Valuation - Section Disclosure'
    }, {
      prev: 'ASSIGNMENT',
      new: 'Valuation - Section Assignment'
    }, {
      prev: 'DUPLICATE_ASSIGNMENT',
      new: 'Valuation - Section Duplicate Assignment'
    }, {
      prev: 'AUDIT_TRAIL',
      new: 'Valuation - Section Audit Trail'
    }, {
      prev: 'DASHBOARD',
      new: 'Valuation - Section ToE Dashboard'
    }, {
      prev: 'INSPECTION',
      new: 'Valuation - Section Inspection'
    }, {
      prev: 'TOE',
      new: 'Valuation - Section Terms of Engagement'
    }, {
      prev: 'TP',
      new: 'Valuation - Section Target Property'
    }, {
      prev: 'VALUATION_PROCESS',
      new: 'Valuation - Section Valuation Process'
    }, {
      prev: "REPORTING_PROCESS",
      new: 'Valuation - Section Reporting Process'
    }, {
      prev: 'APARTMENT',
      new: 'Comparables - Section Apartment'
    }, {
      prev: 'OFFICE',
      new: 'Comparables - Section Office'
    }, {
      prev: 'RETAIL',
      new: 'Comparables - Section Retail'
    }, {
      prev: 'WAREHOUSE',
      new: 'Comparables - Section Warehouse'
    }, {
      prev: 'RETAILBUILDING',
      new: 'Comparables - Section Retail Building'
    }, {
      prev: 'PARKING',
      new: 'Comparables - Section Parking'
    }, {
      prev: 'CONSIDERATION',
      new: 'Comparables - Section Consideration'
    }, {
      prev: 'SOURCES',
      new: 'Comparables - Section Sources'
    }, {
      prev: 'AGENCY',
      new: 'User Management - Section Agency'
    }, {
      prev: 'CLIENT',
      new: 'User Management - Section Client'
    }, {
      prev: 'USER',
      new: 'User Management - Section User'
    }, {
      prev: 'BUILDING',
      new: 'Localisation - Section Building'
    }, {
      prev: 'COUNTRY',
      new: 'Localisation - Section Country'
    }, {
      prev: 'CITY',
      new: 'Localisation - Section City'
    }, {
      prev: 'CURRENCY',
      new: 'Localisation - Section Currency'
    },  {
      prev: 'LANDMARK',
      new: 'Localisation - Section Landmark'
    }, {
      prev: 'APPENDIX',
      new: 'Resources - Section Appendix & Appendix Category'
    }, {
      prev: 'MARKET_RESEARCH',
      new: 'Resources - Section Market Research'
    }, {
      prev: 'TRAINING_AND_DOCUMENT',
      new: 'Support - Section Training And Documents'
    }, {
      prev: 'LT',
      new: 'Admin Settings - Section Linked Tables'
    }, {
      prev: 'TEMPLATE',
      new: 'Admin Settings - Templates'
    }, {
      prev: 'TASKS',
      new: 'Tasks'
    }, {
      prev: 'LAND',
      new: 'Comparables - Section Land'
    }, {
      prev: 'HOUSE',
      new: 'Comparables - Section House'
    }, {
      prev: 'MM',
      new: 'MatterPort'
    }, {
      prev: 'MULTIMEDIA',
      new: 'Multi Media'
    }, {
      prev: 'LAND_PARCEL',
      new: 'Land Parcel'
    }]

    this.tooltipSections$ = this.http.get(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/tooltip-edit/?file=tooltip&lang=en`).pipe(
      map((data: any) => data.TOOLTIP),
      tap(
        data => this.tooltipConfig$.next(data)
      ),
      map(data => {
        const arr: {
          rawField: string,
          field: string,
        }[] = [];
        Object.keys(data).forEach(k => {
          const indx = fieldTitleMap.findIndex(item => item.prev === k);
          // const indx = abbrTerms.findIndex(item => item.abbr === k);
          const term = indx > -1 ? fieldTitleMap[indx].new : k;
          arr.push({
            rawField: k,
            field: term,
          });
        });
          // field: term.toLowerCase().split('_').map(text => text.charAt(0).toUpperCase() + text.substr(1)).join(' ')})
        this.tooltipSections = arr;
        return arr;
      })
    );

    this.templateData$ = combineLatest([
      this.tooltipSections$,
      this.form.controls.section.valueChanges.pipe(startWith(this.form.controls.section.value)),
      this.form.controls.lt.valueChanges.pipe(startWith(this.form.controls.lt.value)),
      this.tooltipConfig$
    ]).pipe(map(([sections, selectedSection, selectedLTSection, config]) => {
      if (selectedSection == null) {
        return {
          sections,
          ltSections: [],
          data: [],
        }
      }

      const removeControls: string[] = [];
      const controls = this.form.controls;
      Object.entries(controls).forEach(([ctlrName, _]) => {
        if (!(ctlrName === 'section' || ctlrName === 'lt')) {
          removeControls.push(ctlrName);
        }
      })
      removeControls.forEach(n => this.form.removeControl(n));

      const ctrlNamesNplaceholders: {
        ctrlName: string,
        placeholder: string,
        size: string
      }[] = [];
      const selectedSectionName = sections[selectedSection];
      const ltSection: {rawField: string, field:string}[] = [];
      if (selectedSectionName.rawField == 'LT') {
        const sectionConfig = config[selectedSectionName.rawField];
        Object.keys(sectionConfig).forEach(k => ltSection.push({
          rawField: k,
          field: k.toLowerCase().split('_').map(text => text.charAt(0).toUpperCase() + text.substr(1)).join(' ')
        }));
        if (selectedLTSection !== null) {
          const selectedLtSectionName = ltSection[selectedLTSection];
          const ltSectionConfig = sectionConfig[selectedLtSectionName.rawField];
          Object.entries(ltSectionConfig).forEach(([key, value]: [string, any]) => {
            const names = this.tooltipControl(key, value);
            ctrlNamesNplaceholders.push(...names);
          })
        }
      } else if (selectedSectionName.rawField == 'CONFLICT_OF_INTEREST' || selectedSectionName.rawField == 'DISCLOSURE') {
        const sectionConfig = config[selectedSectionName.rawField];
        Object.entries(sectionConfig).forEach(([key, value]: [string, any]) => {
          const name = this.fullTooltipControl(key, value);
          ctrlNamesNplaceholders.push(name);
        });
      } else {
        const sectionConfig = config[selectedSectionName.rawField];
        Object.entries(sectionConfig).forEach(([key, value]: [string, any]) => {
          if (key !== 'FORM_FIELD') {
            if (value.hasOwnProperty("TEXT")) {
              const name = this.textControl(key, value);
              ctrlNamesNplaceholders.push(name);
            } else {
              const names = this.tooltipControl(key, value);
              ctrlNamesNplaceholders.push(...names);
            }
          } else {
            Object.entries(value).forEach(([key, value]: [string, any]) => {
              const names = this.tooltipControl(key, value);
              ctrlNamesNplaceholders.push(...names);
            })
          }
        });
      }

      return {
        sections,
        ltSections: ltSection,
        data: ctrlNamesNplaceholders
      }
    }));
  }

  back() {
    this.router.navigateByUrl('/dashboard');
  }

  seeForm() {}

  onSubmit() {
    if (this.form.value.section === null) {
      return;
    }

    const currentConfig = this.tooltipConfig$.value;
    const sectionName = this.tooltipSections[this.form.value.section];

    if (sectionName.rawField === 'LT') {
      if (this.form.value.lt === null) {
        return;
      }

      let sectionConfig = currentConfig[sectionName.rawField];
      const ltSections: {rawField: string, field: string}[] = [];
      Object.keys(sectionConfig).forEach(k => ltSections.push({
        rawField: k,
        field: k.toLowerCase().split('_').map(text => text.charAt(0).toUpperCase() + text.substr(1)).join(' ')
      }));
      const ltSectionName = ltSections[this.form.value.lt];
      let editingSectionConfig = sectionConfig[ltSectionName.rawField];
      Object.entries(this.form.value).filter(([key, _]) => !(key === 'section' || key === 'lt')).forEach(([key, value]: [string, string]) => {
        const newConfig = this.changeValue(editingSectionConfig, key, value);
        editingSectionConfig = newConfig;
      });
      sectionConfig[ltSectionName.rawField] = editingSectionConfig;
      currentConfig[sectionName.rawField] = sectionConfig;
    } else {
      let sectionConfig = currentConfig[sectionName.rawField];
      Object.entries(this.form.value)
        .filter(([key, _]) => !(key === 'section' || key === 'lt'))
        .forEach(([key, value]: [string, string]) => {
          if (this.isTextField(key)) {
            const newConfig = this.changeValueText(sectionConfig, key, value);
            sectionConfig = newConfig;
          } else {
            const newConfig = this.changeValue(sectionConfig, key, value);
            sectionConfig = newConfig
          }
        });
      currentConfig[sectionName.rawField] = sectionConfig;
    }
    const httpHeaders = this.authService.getAuthHeaders();

    // ShowDialog
    const command$ = new BehaviorSubject<{
      loading: boolean,
      success: boolean,
      error: boolean,
    }>({
      loading: true,
      success: false,
      error: false,
    });
    this.dialog.open(LoadingDialogComponent, {
      data: command$,
      width: '300px'
    })

    const reqBody = {
      lang: 'en',
      TOOLTIP: currentConfig
    }
    this.http.post(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/tooltip-edit`, reqBody, {
      headers: httpHeaders
    }).subscribe(res => {
      setTimeout(() => {
        this.translationService.init()
          .then(val => command$.next({
            loading: false,
            success: true,
            error: false
          }))
          .catch(err => {
            command$.next({
              loading: false,
              success: false,
              error: true,
            })
            console.error('error', err);
          })
      }, 1000);
    });

  }

  private isTextField(key: string): boolean {
    return key.substr(key.length-5) === '_text';
  }

  private tooltipControl(fieldRaw: string, value: {TITLE: string, DESCRIPTION: string}): {
    ctrlName: string,
    placeholder: string,
    size: string,
  }[] {
    const field = fieldRaw.toLocaleLowerCase();
    const titleCtrlName = field + '_title';
    const titlePlaceholder = this.getPlaceholder(field) + 'Tooltip Title';
    const descriptionCtrlName = field + '_descr';
    const descriptionPlaceholder = this.getPlaceholder(field) + 'Tooltip Description';
    this.form.addControl(titleCtrlName, this.fb.control(value.TITLE));
    this.form.addControl(descriptionCtrlName, this.fb.control(value.DESCRIPTION));
    return [
      {ctrlName: titleCtrlName, placeholder: titlePlaceholder, size: 'half'}, 
      {ctrlName: descriptionCtrlName, placeholder: descriptionPlaceholder, size: 'half'}
    ];
  }

  private fullTooltipControl(fieldRaw: string, value: {DESCRIPTION: string}): {
    ctrlName: string,
    placeholder: string,
    size: string
  } {
    const field = fieldRaw.toLocaleLowerCase();
    const ctrlName = field + '_descr';
    const placeholder = this.getPlaceholder(field) + 'Description';
    this.form.addControl(ctrlName, this.fb.control(value.DESCRIPTION));
    return {
      ctrlName: ctrlName,
      placeholder: placeholder,
      size: 'full'
    }
  }

  private textControl(fieldRaw: string, value: {TEXT: string}): {ctrlName: string, placeholder: string, size: string} {
    const field = fieldRaw.toLocaleLowerCase();
    const textCtrlName = field + '__text';
    const textPlaceholder = this.getPlaceholder(field) + 'Text';
    this.form.addControl(textCtrlName, this.fb.control(value.TEXT));
    return {
      ctrlName: textCtrlName,
      placeholder: textPlaceholder,
      size: 'full'
    }
  }

  private getPlaceholder(field: string): string {
    const splits = field.split('_');
    let placeholder = '';
    splits.forEach(s => {
      placeholder += s.charAt(0).toUpperCase() + s.substr(1);
      placeholder += ' ';
    })
    return placeholder;
  } 

  private changeValue(config: any, key: string, value: string): any {
    const fieldName = key.substr(0, key.length - 6);
    const type = key.substr(key.length-5) === 'title' ? 'TITLE' : 'DESCRIPTION';
    const fieldNameRaw = fieldName.toUpperCase();
    if (config.hasOwnProperty(fieldNameRaw)) {
      const newConfig = {...config};
      newConfig[fieldNameRaw][type] = value;
      return newConfig;
    } else if (config.hasOwnProperty('FORM_FIELD')) {
      const formFieldConfig = config['FORM_FIELD'];
      if (formFieldConfig.hasOwnProperty(fieldNameRaw)) {
        formFieldConfig[fieldNameRaw][type] = value;
      }
      const newConfig = {...config};
      newConfig['FORM_FIELD'] = formFieldConfig;
      return newConfig;
    }
    return config;
  }

  private changeValueText(config: any, key: string, value: string): any {
    const fieldName = key.substr(0, key.length - 6);
    const type = key.substr(key.length-4).toUpperCase();
    const fieldNameRaw = fieldName.toUpperCase();
    if (config.hasOwnProperty(fieldNameRaw)) {
      const newConfig = {...config};
      newConfig[fieldNameRaw][type] = value;
      return newConfig;
    }
    return config;
  }
}

function demoData(): any {
  return {
    TOOLTIP: {
      AGENCY_CONTACT: {
        FORM: {
          TITLE: "New Agency",
          DESCRIPTION: "Cras ultricies ligula sed magna dictum porta."
        },
        FORM_EDIT: {
          TITLE: "Edit Agency",
          DESCRIPTION: "Nulla quis lorem ut libero malesuada feugiat."
        },
        LIST: {
          TITLE: "Agency list",
          DESCRIPTION: "Sed porttitor lectus nibh."
        }
      },
      APARTMENT: {
        FORM: {
          TITLE: "Add Apartment",
          DESCRIPTION: "Nulla porttitor accumsan tincidunt.."
        },
        FORM_EDIT: {
          TITLE: "Edit Apartment",
          DESCRIPTION: "Quisque velit nisi, pretium ut lacinia in, elementum id enim."
        },
        LIST: {
          TITLE: "Apartments",
          DESCRIPTION: "Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a."
        },
        FORM_FIELD: {
          POINT_OF_CONTACT: {
            TITLE: "Point of contact",
            DESCRIPTION: "Vivamus suscipit tortor eget felis porttitor volutpat."
          }
        }
      },
      VALUATION: {
        NOTES: {
          TITLE: "Notes Table",
          DESCRIPTION: "Quisque velit nisi, pretium ut lacinia in, elementum id enim."
        },
        SIZES_TABLE: {
          TEXT: "Sed porttitor lectus nibh."
        }
      },
      LT: {
        ADJUSTMENT: {
          FORM: {
            TITLE: "Add Adjustment",
            DESCRIPTION: "Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui."
          },
          FORM_EDIT: {
            TITLE: "Edit Adjustment",
            DESCRIPTION: "Vivamus suscipit tortor eget felis porttitor volutpat."
          },
          LIST: {
            TITLE: 'Adjustment list'
          }
        }
      }
    }
  }
}
