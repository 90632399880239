import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {
  FloorNumberingScheme, 
  FloorNumberingSchemeUpdated,
  FloorNumberingSchemeOnServerCreated,
  selectFloorNumberingSchemesActionLoading,
  selectFloorNumberingSchemeById,
  selectLastCreatedFloorNumberingSchemeId
} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';
import {Location} from '@angular/common';
import {combineLatest} from 'rxjs'

@Component({
  selector: 'kt-floor-numbering-scheme-edit',
  templateUrl: './floor-numbering-scheme-edit.component.html',
  styleUrls: ['./floor-numbering-scheme-edit.component.scss']
})
export class FloorNumberingSchemeEditComponent implements OnInit {
    model: FloorNumberingScheme;
    oldModel: FloorNumberingScheme;

    fireplacesChimneyBreastForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    readonly = false
    propertySubTypeId = null;
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];
  constructor(
        private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                public subheaderService: SubheaderService,
                private store: Store<AppState>,
                private translate: TranslateService
  ) { }

  ngOnInit(): void {
        this.loading$ = this.store.pipe(select(selectFloorNumberingSchemesActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            this.readonly = data.readonly
            if (params.property_sub_type_id) {
                this.propertySubTypeId = Number(params.property_sub_type_id);
            }
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectFloorNumberingSchemeById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initFloorNumberingScheme();
                    }
                });
            } else {
                this.model = new FloorNumberingScheme();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initFloorNumberingScheme();
            }
        });
        this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
  }

    initFloorNumberingScheme() {
        this.createForm();
        // set subheaderService
    }

    createForm() {
        this.fireplacesChimneyBreastForm = this.fb.group({
            name: [this.model.name, Validators.required],
        });
    }
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return `Edit Floor Numbering Scheme '${
                this.model.name
                }'`;
        }

        return 'New Floor Numbering Scheme';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.fireplacesChimneyBreastForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.fireplacesChimneyBreastForm.markAsPristine();
        this.fireplacesChimneyBreastForm.markAsUntouched();
        this.fireplacesChimneyBreastForm.updateValueAndValidity();
    }

    prepareFloorNumberingScheme(): FloorNumberingScheme {
        const controls = this.fireplacesChimneyBreastForm.controls;
        const _model = new FloorNumberingScheme();
        _model.clear();
        _model.id = this.model.id;
        _model.name = controls.name.value;
        _model.is_hidden = this.model.is_hidden;

        return _model;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.fireplacesChimneyBreastForm.controls;
        /** check form */
        if (this.fireplacesChimneyBreastForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedFacility = this.prepareFloorNumberingScheme();
        if (this.model.id > 0) {
            this.updateFloorNumberingScheme(editedFacility);
        } else {
            this.createFloorNumberingScheme(editedFacility);
        }
    }

    /**
     * Update model
     *
     * @param _model: FloorNumberingSchemeModel
     */
    updateFloorNumberingScheme(_model: FloorNumberingScheme) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateFloorNumberingScheme: Update<FloorNumberingScheme> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new FloorNumberingSchemeUpdated({
            partialFloorNumberingScheme: updateFloorNumberingScheme,
            FloorNumberingScheme: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();

    }

    /**
     * Create model
     *
     * @param _model: FloorNumberingSchemeModel
     */
    createFloorNumberingScheme(_model: FloorNumberingScheme) {
        this.store.dispatch(new FloorNumberingSchemeOnServerCreated({FloorNumberingScheme: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedFloorNumberingSchemeId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }
}
