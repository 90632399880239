<div class="d-flex flex-column">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <div ngbDropdown placement="bottom-right" *ngIf="!isTemplate && !readonly" class="dropdown dropdown-inline">
            <button ngbDropdownToggle class="btn btn-label-brand btn-bold btn-sm dropdown-toggle" style="cursor: pointer !important;">Add</button>
            <div ngbDropdownMenu style="width: 230px;">
                <ul class="navi navi-hover">
                    <li class="navi-item">
                        <a class="navi-link" href="javascript:;" (click)="addExternalReference()">
                            <span class="navi-icon">
                                <i class="flaticon2-add"></i>
                            </span>
                            <span class="navi-text">
                                Add external reference
                            </span>
                        </a>
                    </li>
                    <li class="navi-item">
                        <a class="navi-link" href="javascript:;" (click)="_addFromTemplate()">
                            <span class="navi-icon">
                                <i class="flaticon2-add"></i>
                            </span>
                            <span class="navi-text">
                                Add from template
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <button  class="btn btn-primary" *ngIf="isTemplate && !readonly" color="primary" (click)="addExternalReference()" type="button">
            Add external reference
        </button>
    </div>

    <!-- &lt;!&ndash;<div *ngIf="(participantsSubject| async).value > 0" class="example-container mat-elevation-z0">&ndash;&gt; -->
    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="source_external_reference.name">
                <mat-header-cell *matHeaderCellDef> External reference</mat-header-cell>
                <mat-cell *matCellDef="let externalReference"> {{externalReference.source_external_reference.name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="reference_available">
                <mat-header-cell *matHeaderCellDef> Availability</mat-header-cell>
                <mat-cell *matCellDef="let participant"> {{participant.reference_available == 1 ? 'Yes': 'No'}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="assumption">
                <mat-header-cell *matHeaderCellDef> Assumption</mat-header-cell>
                <mat-cell *matCellDef="let participant"> {{participant.reference_available == 1 ?  'N/A' : participant.assumption == 1 ? 'Yes': 'No'}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="special_assumption">
                <mat-header-cell *matHeaderCellDef> Special Assumption</mat-header-cell>
                <mat-cell *matCellDef="let participant"> {{participant.reference_available == 1 ?  'N/A' : participant.special_assumption == 1 ? 'Yes': 'No'}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let externalReference;let i = index;">
                    <button mat-icon-button
                            color="primary"
                            [disabled]="readonly"
                            matTooltip="Edit external reference"
                            (click)="editExternalReference(externalReference, i)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="warn"
                            [disabled]="readonly"
                            matTooltip="Delete external reference"
                            (click)="deleteExternalReference(externalReference, i)"
                            type="button">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>