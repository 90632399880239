import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    AssetClassResidentialsService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassResidentialGetResolver implements Resolve<any> {
    constructor(public auditTrailsService: AssetClassResidentialsService) {}
  
    resolve(route: ActivatedRouteSnapshot) {
        const apartment_id = Number(route.params.apartment_id);
        return this.auditTrailsService.getAssetClassResidential(apartment_id);
    }
}
