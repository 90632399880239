import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { delay, map, switchMap, takeUntil } from "rxjs/operators";
import { SubdomainService } from "../_base/subdomain.service";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../mad-auth/mad-auth.service";
import { GeneralSettings } from "./general-settings.store";

@Injectable({
    providedIn: 'root'
})
export class GeneralSettingsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/general-settings`

    constructor(
        private http: HttpClient ,
        private subDomainService: SubdomainService,
        private authService: MadAuthService
    ) {}

    updateSettings(item: GeneralSettings): Observable<GeneralSettings> {
        const httpHeaders = this.authService.getAuthHeaders()
        return this.http.post<{data: GeneralSettings}>(this.API_URL, {item}, {
            headers: httpHeaders
        }).pipe( 
            map(response => response.data)
        )
    }

    getSettings(): Observable<GeneralSettings> {
        const httpHeaders = this.authService.getAuthHeaders()
        return this.http.get<{data: GeneralSettings}>(this.API_URL, {
            headers: httpHeaders
        }).pipe(map(response => response.data))
    }
} 