<div class="card card-custom">
    <div class="card-body-progress card-body-progress-overlay">
        <!-- &lt;!&ndash; here can place a progress bar&ndash;&gt; -->
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
    </div>
    <div class="form">

        <div class="card-body">
            <form [formGroup]="form">
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Delivery Detail"
                                        formControlName="delivery_detail_id">
                                <mat-option *ngFor="let item of reportDeliveryMethods"
                                            [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>

                            <mat-error>Delivery Detail
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-fluid">
                            <span (click)="addPicker_date.open()">
                                <input matInput [matDatepicker]="addPicker_date"
                                       readonly
                                       placeholder="Delivery Date"
                                       formControlName="sent_at">
                            </span>
                            <mat-datepicker-toggle matSuffix [for]="addPicker_date"></mat-datepicker-toggle>
                            <button mat-button *ngIf="form.controls.sent_at.value"
                                    matSuffix mat-icon-button
                                    matTooltip="Clear"
                                    (click)="clearDate(form.controls.sent_at)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-datepicker #addPicker_date></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput rows="3"
                                  placeholder="Description"
                                  formControlName="sent_descr"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions--solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">Close</button>&nbsp;
                        <button mat-button (click)="save()" color="primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
