import { P } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AllPaymentTypesRequested, PaymentType, selectAllPaymentTypes } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { InvoiceService, ToeInvoicePaymentService } from 'src/app/core/toe';

@Component({
  selector: 'kt-edit-invoice-pvd-info',
  templateUrl: './edit-invoice-pvd-info.component.html',
  styleUrls: ['./edit-invoice-pvd-info.component.scss']
})
export class EditInvoicePvdInfoComponent implements OnInit {
  viewLoading = false;
  form: UntypedFormGroup;
  pvdReferences: any[];
  invoicePayment: any;
  currency: string;
  paymentTypes: PaymentType[];
  private subscriptions: Subscription[] = [];
  referenceNote = '';

  constructor(
    public dialogRef: MatDialogRef<EditInvoicePvdInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private invoiceService: InvoiceService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.pvdReferences = this.data.references;
    this.invoicePayment = this.data.invoicePayment;
    this.currency = this.data.currency

    this.form = this.fb.group({
      // invoice_id: [this.data.invoicePayment.invoice_id, Validators.required],
      // payment_type_id: [this.data.invoicePayment.payment_type_id, Validators.required],
      issue_date: [this.data.invoicePayment.issue_date ? moment(this.data.invoicePayment.issue_date) : moment(new Date())],
      payment_date: [this.data.invoicePayment.payment_date ? moment(this.data.invoicePayment.payment_date) : moment(new Date())],
      amount: [this.data.invoicePayment.amount],
      comment: [this.data.invoicePayment.comment],
      references: this.fb.array([])
    });


    if (this.invoicePayment['pvds'] && this.invoicePayment['pvds'].length > 0) {
      this.invoicePayment['pvds'].forEach(item => {
        this.addReference(item)  
      });
    } else {
      this.addReference(null) //initial 1st item when new
    }

    this.store.dispatch(new AllPaymentTypesRequested());
    const paymentTypeSubscription = this.store.pipe(select(selectAllPaymentTypes)).subscribe((res: PaymentType[]) => {
      this.paymentTypes = res;
    });
    this.subscriptions.push(paymentTypeSubscription);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  save() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAllAsTouched();
      })
      return;
    }

    let dateValue = '';
    if (controls.payment_date.value) {
      dateValue = controls.payment_date.value.format('YYYY-MM-DD');
    }
    let issueDateValue = '';
    if (controls.issue_date.value) {
      issueDateValue = controls.issue_date.value.format('YYYY-MM-DD');
    }

    this.viewLoading = true;
    const changedInvoice = Object.assign({}, this.invoicePayment);
    changedInvoice.id = this.data.invoicePayment.id;
    changedInvoice.toe_id = this.data.toe.id;
    changedInvoice.payment_date = dateValue;
    changedInvoice.issue_date = issueDateValue;

    changedInvoice.toeInvoicePvdsAlt = controls.references.value
    changedInvoice.toeInvoicePvds = controls.references.value

    if (this.data.invoicePayment.id) {
      this.invoiceService.updateToeInvoicePayment(changedInvoice).subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({item: null});
      });
    } else {
      this.invoiceService.createToeInvoicePayment(changedInvoice).subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({item: null});
      });
    }
  }
  getReferenceName(invoice, toe_invoices) {
    this.currency = invoice.currency
    return 'Post Valuation services';
  }

  references(): FormArray {
    return this.form.get("references") as FormArray
  }

  newReference(item): FormGroup {
    return this.fb.group({
      id: item && item.id ? item.id : undefined,
      pvd_id: item && item.pvd_id ? item.pvd_id : this.pvdReferences[0].id,
      descr: [item && item.descr ? item.descr : '', Validators.required],
      hourly_rate: [item && item.hourly_rate ? item.hourly_rate : this.pvdReferences[0].hourly_rate, Validators.required],
      hours: [item && item.hours ? Math.abs(item.hours) : this.pvdReferences[0].hours, Validators.required],
      reference_note: this.setReferenceNote(this.pvdReferences[0]),
      hours_label: this.setHoursLabel(this.pvdReferences[0])
    })
  }

  setReferenceNote(reference) {
    if (reference.free_hours && reference.free_hours > 0) {
      return `According to the Terms of Engagement, the first ${reference.free_hours} hours of additional services are included at no extra cost. Services rendered beyond this initial ${reference.free_hours}-hour threshold will be billed at an hourly rate of ${reference.currency} ${reference.hourly_rate}. The input here refers to the hours exceeding this threshold.`
    }
    return `Per the Terms of Engagement, any extra hours of additional services will be charged at a rate of ${reference.currency} ${reference.hourly_rate} per hour.`
  }

  setHoursLabel(reference) {
    if (reference.free_hours && reference.free_hours > 0) {
      return `Extra Hours`
    }
    return `Number of Hours`
  }

  addReference(item) {
    this.references().push(this.newReference(item));
  }

  removeReference(refIndex:number) {
    this.references().removeAt(refIndex);
  }
}
