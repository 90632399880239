// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ValuerQualification} from '../_models/valuer-qualification.model';

export enum ValuerQualificationActionTypes {

    AllValuerQualificationsRequested = '[ValuerQualification Home Page] All ValuerQualification Requested',
    AllValuerQualificationsLoaded = '[ValuerQualification API] All ValuerQualification Loaded',

    ValuerQualificationOnServerCreated = '[Edit ValuerQualification Dialog] ValuerQualification On Server Created',
    ValuerQualificationCreated = '[Edit ValuerQualification Dialog] ValuerQualification Created',
    ValuerQualificationUpdated = '[Edit ValuerQualification Dialog] ValuerQualification Updated',
    ValuerQualificationDeleted = '[ValuerQualification List Page] ValuerQualification Deleted',

    // trash system

    ValuerQualificationOnServerRestored = '[ValuerQualification Trash] ValuerQualification On Server Restored',
    ValuerQualificationRestored = '[ValuerQualification Trash] ValuerQualification Restored',

    ValuerQualificationOnServerAdminRestored = '[ValuerQualification Admin Trash] ValuerQualification On Server Restored',
    ValuerQualificationAdminRestored = '[ValuerQualification Admin Trash] ValuerQualification Restored',

    ValuerQualificationDeletedFromTrash = '[ValuerQualification Trash] ValuerQualification deleted',
    ValuerQualificationDeletedFromAdminTrash = '[ValuerQualification Admin Trash] ValuerQualification deleted',

    ValuerQualificationTrash = 'ValuerQualification Trashed',
    ValuerQualificationTrashFlushed = 'ValuerQualification Trash Flushed',

    // Page system

    ValuerQualificationsPageRequested = '[ValuerQualification List Page] ValuerQualification Page Requested',
    ValuerQualificationsPageLoaded = '[ValuerQualification API] ValuerQualification Page Loaded',
    ValuerQualificationsPageCancelled = '[ValuerQualification API] ValuerQualification Page Cancelled',

    ValuerQualificationsPageToggleLoading = '[ValuerQualification page] ValuerQualification Page Toggle Loading',
    ValuerQualificationActionToggleLoading = '[ValuerQualification] ValuerQualification Action Toggle Loading'
}

export class ValuerQualificationOnServerCreated implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationOnServerCreated;

    constructor(public payload: { item: ValuerQualification }) {
    }
}

export class ValuerQualificationCreated implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationCreated;

    constructor(public payload: { item: ValuerQualification }) {
    }
}

export class ValuerQualificationUpdated implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationUpdated;

    constructor(public payload: {
        partialItem: Update<ValuerQualification>,
        item: ValuerQualification
    }) {
    }
}

export class ValuerQualificationDeleted implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ValuerQualificationsPageRequested implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ValuerQualificationsPageLoaded implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationsPageLoaded;

    constructor(public payload: { items: ValuerQualification[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ValuerQualificationsPageCancelled implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationsPageCancelled;
}

export class AllValuerQualificationsRequested implements Action {
    readonly type = ValuerQualificationActionTypes.AllValuerQualificationsRequested;
}

export class AllValuerQualificationsLoaded implements Action {
    readonly type = ValuerQualificationActionTypes.AllValuerQualificationsLoaded;

    constructor(public payload: { items: ValuerQualification[] }) {
    }
}

export class ValuerQualificationsPageToggleLoading implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ValuerQualificationActionToggleLoading implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ValuerQualificationDeletedFromAdminTrash implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ValuerQualificationDeletedFromTrash implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ValuerQualificationOnServerRestored implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ValuerQualificationRestored implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationRestored;

    constructor(public payload: { item: ValuerQualification }) {
    }
}

export class ValuerQualificationOnServerAdminRestored implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ValuerQualificationAdminRestored implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationAdminRestored;

    constructor(public payload: { item: ValuerQualification }) {
    }
}

export class ValuerQualificationTrashFlushed implements Action {
    readonly type = ValuerQualificationActionTypes.ValuerQualificationTrashFlushed;

    constructor() {
    }
}


export type ValuerQualificationActions = ValuerQualificationCreated
    | ValuerQualificationUpdated
    | ValuerQualificationDeleted
    | ValuerQualificationsPageRequested
    | ValuerQualificationsPageLoaded
    | ValuerQualificationsPageCancelled
    | AllValuerQualificationsLoaded
    | AllValuerQualificationsRequested
    | ValuerQualificationOnServerCreated
    | ValuerQualificationDeletedFromAdminTrash
    | ValuerQualificationDeletedFromTrash
    | ValuerQualificationOnServerRestored
    | ValuerQualificationRestored
    | ValuerQualificationOnServerAdminRestored
    | ValuerQualificationAdminRestored
    | ValuerQualificationTrashFlushed
    | ValuerQualificationsPageToggleLoading
    | ValuerQualificationActionToggleLoading;
