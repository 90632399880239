import {} from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { selectValuationStandardPageLoading, selectValuationStandardQueryResult, selectValuationStandardShowInitWaitingMessage } from '../_selectors/valuation-standard.selector';

export class ValuationStandardDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectValuationStandardPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectValuationStandardShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectValuationStandardQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        })
    }
}