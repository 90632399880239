import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetClassTenure } from 'src/app/core/comparable';

@Component({
  selector: 'kt-leasehold-info-modal',
  templateUrl: './leasehold-info-modal.component.html',
  styleUrls: ['./leasehold-info-modal.component.scss']
})
export class LeaseholdInfoModalComponent implements OnInit {
  @Input()
  consideration: AssetClassTenure
  
  rentType: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.rentType = this.consideration.rent_type.charAt(0).toUpperCase() + this.consideration.rent_type.slice(1);
  }

  close() {
    this.activeModal.close();
  }

}
