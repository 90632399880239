// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AuditTrailParticipantActions, AuditTrailParticipantActionTypes} from '../_actions/audit-trail-participant.actions';
// Models
import {AuditTrailParticipantModel} from '../_models/audit-trail-participant.model';

export interface AuditTrailParticipantsState extends EntityState<AuditTrailParticipantModel> {
    isAllParticipantsLoaded: boolean;
    listLoading: boolean;
    actionLoading: boolean;
    lastCreatedAuditTrailParticipantId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AuditTrailParticipantModel> = createEntityAdapter<AuditTrailParticipantModel>();

export const initialAuditTrailParticipantsState: AuditTrailParticipantsState = adapter.getInitialState({
    isAllParticipantsLoaded: false,
    listLoading: false,
    actionLoading: false,
    lastCreatedAuditTrailParticipantId: undefined,
    showInitWaitingMessage: true
});

export function auditTrailParticipantsReducer(state = initialAuditTrailParticipantsState, action: AuditTrailParticipantActions): AuditTrailParticipantsState {
    switch (action.type) {
        case AuditTrailParticipantActionTypes.AuditTrailParticipantsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAuditTrailParticipantId: undefined
            };
        }
        case AuditTrailParticipantActionTypes.AuditTrailParticipantActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AuditTrailParticipantActionTypes.AuditTrailParticipantOnServerCreated:
            return {
                ...state
            };
        case AuditTrailParticipantActionTypes.AuditTrailParticipantsPageCancelled: {
            return {
                ...state, listLoading: false
            };
        }
        case AuditTrailParticipantActionTypes.AuditTrailParticipantCreated:
            return adapter.addOne(action.payload.auditTrailParticipant, {
                ...state, lastCreatedAuditTrailParticipantId: action.payload.auditTrailParticipant.id
            });
        case AuditTrailParticipantActionTypes.AuditTrailParticipantUpdated:
            return adapter.updateOne(action.payload.partialAuditTrailParticipant, state);

        case AuditTrailParticipantActionTypes.AuditTrailParticipantDeleted:
            return adapter.removeOne(action.payload.id, state);

        case AuditTrailParticipantActionTypes.AuditTrailParticipantsPageLoaded:
            return adapter.addAll(action.payload.auditTrailParticipants, {
                ...state, isAllParticipantsLoaded: true
            });
        default:
            return state;
    }
}

export const getAuditTrailParticipantState = createFeatureSelector<AuditTrailParticipantModel>('auditTrailParticipants');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
