// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ValuerQualificationsService} from '../_services/valuer-qualification.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allValuerQualificationsLoaded} from '../_selectors/valuer-qualification.selectors';
// Actions
import {
    AllValuerQualificationsLoaded,
    AllValuerQualificationsRequested,
    ValuerQualificationActionTypes,
    ValuerQualificationsPageRequested,
    ValuerQualificationsPageLoaded,
    ValuerQualificationUpdated,
    ValuerQualificationsPageToggleLoading,
    ValuerQualificationDeleted,
    ValuerQualificationOnServerCreated,
    ValuerQualificationCreated,
    ValuerQualificationActionToggleLoading, ValuerQualificationTrashFlushed, ValuerQualificationOnServerRestored, ValuerQualificationRestored,
    ValuerQualificationOnServerAdminRestored, ValuerQualificationAdminRestored, ValuerQualificationDeletedFromTrash, ValuerQualificationDeletedFromAdminTrash
} from '../_actions/valuer-qualification.actions';

@Injectable()
export class ValuerQualificationEffects {
    showPageLoadingDispatcher = new ValuerQualificationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ValuerQualificationActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ValuerQualificationActionToggleLoading({isLoading: false});

    @Effect()
    loadAllValuerQualification$ = this.actions$
        .pipe(
            ofType<AllValuerQualificationsRequested>(ValuerQualificationActionTypes.AllValuerQualificationsRequested),
            withLatestFrom(this.store.pipe(select(allValuerQualificationsLoaded))),
            filter(([action, isAllValuerQualificationLoaded]) => !isAllValuerQualificationLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllValuerQualificationsLoaded({items: res.data});
            })
        );

    @Effect()
    loadValuerQualificationPage$ = this.actions$
        .pipe(
            ofType<ValuerQualificationsPageRequested>(ValuerQualificationActionTypes.ValuerQualificationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ValuerQualificationsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationUpdated>(ValuerQualificationActionTypes.ValuerQualificationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationOnServerCreated>(ValuerQualificationActionTypes.ValuerQualificationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuerQualificationCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushValuerQualificationTrash$ = this.actions$
        .pipe(
            ofType<ValuerQualificationTrashFlushed>(ValuerQualificationActionTypes.ValuerQualificationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationOnServerRestored>(ValuerQualificationActionTypes.ValuerQualificationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuerQualificationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationOnServerAdminRestored>(ValuerQualificationActionTypes.ValuerQualificationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuerQualificationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationDeleted>(ValuerQualificationActionTypes.ValuerQualificationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationDeletedFromTrash>(ValuerQualificationActionTypes.ValuerQualificationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashValuerQualification$ = this.actions$
        .pipe(
            ofType<ValuerQualificationDeletedFromAdminTrash>(ValuerQualificationActionTypes.ValuerQualificationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ValuerQualificationsService, private store: Store<AppState>) {
    }
}
