import {OutsideDoorModel} from '../_models/outside-door.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {OutsideDoorsState} from '../_reducers/outside-door.reducer';
import * as fromOutsideDoor from '../_reducers/outside-door.reducer';
import {each} from 'lodash';

export const selectOutsideDoorsState = createFeatureSelector<OutsideDoorsState>('outside-doors');

export const selectOutsideDoorById = (id: number) => createSelector(
    selectOutsideDoorsState,
    state => state.entities[id]
);

export const selectAllOutsideDoors = createSelector(
    selectOutsideDoorsState,
    fromOutsideDoor.selectAll
);

export const selectAllOutsideDoorsIds = createSelector(
    selectOutsideDoorsState,
    fromOutsideDoor.selectIds
);

export const allOutsideDoorsLoaded = createSelector(
    selectOutsideDoorsState,
    state => state.isAllOutsideDoorsLoaded
);


export const selectOutsideDoorsPageLoading = createSelector(
    selectOutsideDoorsState,
    state => state.listLoading
);

export const selectOutsideDoorsActionLoading = createSelector(
    selectOutsideDoorsState,
    state => state.actionLoading
);

export const selectLastCreatedOutsideDoorId = createSelector(
    selectOutsideDoorsState,
    state => state.lastCreatedOutsideDoorId
);

export const selectOutsideDoorsShowInitWaitingMessage = createSelector(
    selectOutsideDoorsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedOutsideDoorCount = createSelector(
    selectOutsideDoorsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedOutsideDoorCount = createSelector(
    selectOutsideDoorsState,
    (state) => state.totalAdminTrashed
);


export const selectOutsideDoorQueryResult = createSelector(
    selectOutsideDoorsState,
    state => {
        const items: OutsideDoorModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OutsideDoorModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
