import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as objectPath from 'object-path';
import { BehaviorSubject, combineLatest, concat, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AssetClassesPageRequested, AssetClassModel, TpTaskService } from 'src/app/core/asset_class';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store'; 
import { AppState } from 'src/app/core/reducers';
import { InvoiceService, OneToeRequest, ToeModel, ToeReportService, ToeService } from 'src/app/core/toe';
import { AllToeReportTasksByToeIDLoaded, AllToeReportTasksByToeIDRequested, DeleteReportTask, UpdateReportTaskReports } from 'src/app/core/toe/_actions/toe-report-task.actions';
import { NewCoiModel } from 'src/app/core/toe/_models/new-coi.model';
import { AmendmentType, ToeAmendmentModel } from 'src/app/core/toe/_models/toe-amendment.model';
import { ToeReportTaskModel } from 'src/app/core/toe/_models/toe-report-task.model';
import { selectReportTasksByToeID } from 'src/app/core/toe/_selectors/toe-report-tasks.selectors';
import { InformedConsentReportService } from 'src/app/core/toe/_services/informed-consent-report.service';
import { NewCoiService } from 'src/app/core/toe/_services/new-coi.service';
import { ToeAmendmentService } from 'src/app/core/toe/_services/toe-amendment.service';
import { QueryParamsModel, TypesUtilsService } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { ImageViewerDialogComponent } from '../../../shared_components/image_viewer/image-viewer.dialog.component';
import { AmendmentDialogInputData, AmendmentDialogV2Component } from '../../_sub/amendment-dialog-v2/amendment-dialog-v2.component';
import { CoiDialogComponent, CoiDialogInput, CoiDialogReturn } from '../../_sub/coi-dialog/coi-dialog.component';
import { EditReportTaskInfoComponent } from '../edit-report-task-info/edit-report-task-info.component';
import { EditPaymentInfoComponent } from '../edit-payment-info/edit-payment-info.component';
import { ToeAuditTaskModalComponent } from './audit-task-modal/audit-task-modal.component';
import { selectToeAuditTaskReminders } from 'src/app/core/toe/_selectors/toe-audit-task-reminder.selectors';
import { ToeAuditTaskReminderModel } from 'src/app/core/toe/_models/toe-audit-task-reminder.model';
import { CreateToeAuditTaskReminder, DeleteToeAuditTaskReminder, UpdateToeAuditTaskReminder } from 'src/app/core/toe/_actions/toe-audit-task-reminder.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { EventManager } from '@angular/platform-browser';

@Component({
  selector: 'kt-toe-audit',
  templateUrl: './toe-audit.component.html',
  styleUrls: ['./toe-audit.component.scss']
})
export class ToeAuditComponent implements OnInit, OnDestroy {
  @Input() toeId: number
  @Input() targetProperties: AssetClassModel[]
  @Input() workers: User[]
  @Output() taskCntChange: EventEmitter<any> = new EventEmitter();

  /**
   * DataSource
   */
  dataSource = new MatTableDataSource();
  columns = ['date', 'type', 'target_property', 'interaction_type', 'status', 'user', 'actions']

  /**
   * Component State
   */
  loading$ = new BehaviorSubject<boolean>(false);
  private _onDestroy$: Subject<void> = new Subject()

  constructor(
    private dialog: MatDialog,
    private store$: Store<AppState>,
    private router: Router,
    private activatedReoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.store$
      .select(selectToeAuditTaskReminders)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        this.dataSource.data = res
        this.taskCntChange.emit({
          total: res.length,
          incomplete: res.filter(item => item.status == 1).length
        })
      })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  /**
   * Component actions
   */
  addReminder() {
    const model = new ToeAuditTaskReminderModel()
    model.toe_id = this.toeId
    const dialogRef = this.dialog.open(ToeAuditTaskModalComponent, {
      data: {
        model,
        targetProperties: this.targetProperties,
        workers: this.workers
      } 
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.store$.dispatch(new CreateToeAuditTaskReminder({reminder: res}))
    })
  }

  editReminder(model: ToeAuditTaskReminderModel) {
    const dialogRef = this.dialog.open(ToeAuditTaskModalComponent, {
      data: {
        model,
        targetProperties: this.targetProperties,
        workers: this.workers
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.store$.dispatch(new UpdateToeAuditTaskReminder({reminder: res}))
    })
  }

  deleteReminder(model: ToeAuditTaskReminderModel) {
    this.store$.dispatch(new DeleteToeAuditTaskReminder({id: model.id}))
  }

  reach(model: ToeAuditTaskReminderModel) {
    if (model.status == 2) {
      this.router.navigate(['../../../audit-trails/show', model.audit_trail_id], {relativeTo: this.activatedReoute})
      return
    }
    this.router.navigate(['../../../audit-trails/edit', model.audit_trail_id], {relativeTo: this.activatedReoute})
  }
}