// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CubicContentActions, CubicContentActionTypes} from '../_actions/cubic-content.actions';
// Models
import {CubicContent} from '../_models/cubic-content.model';
import {QueryParamsModel} from '../../_base/crud';

export interface CubicContentsState extends EntityState<CubicContent> {
    isAllCubicContentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: CubicContent[];
    lastCreatedCubicContentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CubicContent> = createEntityAdapter<CubicContent>();

export const initialCubicContentsState: CubicContentsState = adapter.getInitialState({
    isAllCubicContentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedCubicContentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function cubicContentsReducer(state = initialCubicContentsState, action: CubicContentActions): CubicContentsState {
    switch (action.type) {
        case CubicContentActionTypes.CubicContentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCubicContentId: undefined
            };
        case CubicContentActionTypes.CubicContentsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case CubicContentActionTypes.CubicContentOnServerCreated:
            return {
                ...state
            };
        case CubicContentActionTypes.CubicContentCreated:
            return adapter.addOne(action.payload.cubicContent, {
                ...state, lastCreatedCubicContentId: action.payload.cubicContent.id
            });
        case CubicContentActionTypes.CubicContentUpdated:
            return adapter.updateOne(action.payload.partialCubicContent, state);
        case CubicContentActionTypes.AllCubicContentsLoaded:
            return adapter.addAll(action.payload.cubicContents, {
                ...state, isAllCubicContentsLoaded: true
            });
        case CubicContentActionTypes.CubicContentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case CubicContentActionTypes.CubicContentsPageLoaded:
            return adapter.addMany(action.payload.cubicContents, {
                ...initialCubicContentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case CubicContentActionTypes.CubicContentDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case CubicContentActionTypes.CubicContentRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case CubicContentActionTypes.CubicContentDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case CubicContentActionTypes.CubicContentAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case CubicContentActionTypes.CubicContentDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case CubicContentActionTypes.CubicContentTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
