<div class="col-12" [ngClass]="{'readonly': readonly}">
    <div class="row">
        <div class="col-md-6 document-tree">
            <div class="card card-custom h-100">
                <div class="card-body">
                    <div class="cat-title">
                        {{title}}
                    </div>
                    <div class="cat-container">
                        <ng-container *ngFor="let file of files; let i = index">
                            <div class="document-tree-item" style="padding-left: 1.5rem;" [ngClass]="{
                                'selected-item': file.selected,
                                'whiter-green': i%2
                            }" (click)="onFileClick(file)">
                                <ng-container *ngIf="file.isDir" [ngTemplateOutlet]="dirRow"
                                    [ngTemplateOutletContext]="{file: file}">
                                </ng-container>
                                <ng-container *ngIf="!(file.isDir)" [ngTemplateOutlet]="fileRow"
                                    [ngTemplateOutletContext]="{file: file}">
                                </ng-container>
                            </div>
                            <ng-container *ngIf="file.isDir && file.selected" [ngTemplateOutlet]="subList"
                                [ngTemplateOutletContext]="{subFiles: file.subFiles}">
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 document-table">
            <div class="card card-custom h-100">
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="document-table-name">Name</th>
                                <th class="document-table-action">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="(selectedDocs$|async) as selectedDocs">
                            <ng-container *ngIf="selectedDocs.length > 0; else noDocuments">
                                <tr *ngFor="let doc of selectedDocs; let i = index" [ngClass]="{'whiter-green': i%2}">
                                   <td class="document-table-name-cell">{{doc.name}}</td> 
                                   <td class="document-table-action-cell">
                                       <span class="mr-4" (click)="showDocument(doc.url)">
                                           <mat-icon>visibility</mat-icon>
                                        </span>
                                        <span *ngIf="!readonly" (click)="removeDocument(doc)">
                                            <mat-icon color="warn">delete</mat-icon>
                                        </span>
                                   </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #dirRow let-file="file">
    <p>{{file.name}}</p>
    <div>
        <mat-icon>folder_open</mat-icon>
    </div>
</ng-template>

<ng-template #fileRow let-file="file">
    <p>{{file.name}}</p>
    <div class="file_actions">
        <span style="display: flex; align-items: center; margin-right: 1rem;" (click)="showDocument(file.url)">
            <mat-icon style="height: 1rem;">visibility</mat-icon>
        </span>
        <mat-checkbox [checked]="file.selected" (change)="onSelect($event, file)"></mat-checkbox>
    </div>
</ng-template>

<ng-template #subList let-files="subFiles">
    <ng-container *ngIf="files">
        <ng-container *ngFor="let file of files; let i = index">
            <div class="document-tree-item" style="padding-left: 3rem;"
            [ngClass]="{
                    'selected-item': file.isDir && file.selected,
                    'whiter-green': i%2
            }" (click)="onFileClick(file)">
                <ng-container *ngIf="file.isDir" [ngTemplateOutlet]="dirRow"
                    [ngTemplateOutletContext]="{file: file}">
                </ng-container>
                <ng-container *ngIf="!file.isDir" [ngTemplateOutlet]="fileRow"
                    [ngTemplateOutletContext]="{file: file}">
                </ng-container>
            </div>
            <ng-container *ngIf="file.isDir && file.selected" [ngTemplateOutlet]="subList2"
                [ngTemplateOutletContext]="{subFiles: file.subFiles}">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #subList2 let-files="subFiles">
    <ng-container *ngIf="files">
        <ng-container *ngFor="let file of files; let i = index">
            <div class="document-tree-item" style="padding-left: 4.5rem;"
            [ngClass]="{
                    'selected-item': file.isDir && file.selected,
                    'whiter-green': i%2
            }" (click)="onFileClick(file)">
                <ng-container *ngIf="file.isDir" [ngTemplateOutlet]="dirRow"
                    [ngTemplateOutletContext]="{file: file}">
                </ng-container>
                <ng-container *ngIf="!file.isDir" [ngTemplateOutlet]="fileRow"
                    [ngTemplateOutletContext]="{file: file}">
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #subCategories let-categories="categories">
    <ng-container *ngIf="categories">
        <div *ngFor="let category of categories; let i = index">
            <p>{{category.name}}</p>
            <mat-icon>folder_open</mat-icon>
        </div>
    </ng-container>
</ng-template>

<ng-template #noDocuments>
    <div style="display: flex; justify-content: center; align-items: center;">
        No Documents
    </div>
</ng-template>