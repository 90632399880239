export function toeRole(userId: number, projectManagerId: number, leadValuerId: number, isWorker: boolean): string {
  if (userId == projectManagerId && projectManagerId == leadValuerId) {
      return 'Project manager & Lead Valuer';
  } else if (userId == projectManagerId && isWorker) {
      return 'Project manager & Supporting Staff'
  } else if (userId == projectManagerId) {
      return 'Project manager';
  } else if (userId == leadValuerId) {
      return 'Lead Valuer';
  }
  return 'Supporting Staff';
}