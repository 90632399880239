<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <mat-icon style="color: #f3ec24">warning</mat-icon>
            <h3 class="card-label">
                Potential conflicts related to Lead Valuer or Agency involvement with the Client of the Target Properties identified in database
            </h3>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body pt-0 w-100">
        <mat-dialog-content>
            <div *ngIf="lvInvolvementVm$|async as lvInvolvements" class="mt-10">
                <h5 class="text-red">{{lvInvolvements.title}}</h5>
                <mat-table [dataSource]="lvInvolvements.dataSource" class="mt-4 lv-involvements">
                    <ng-container matColumnDef="toe">
                        <mat-header-cell *matHeaderCellDef>TOE</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.toe_name}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="agency">
                        <mat-header-cell *matHeaderCellDef>Agency</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.agency_name}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.toe_signing_date | date:'dd MMM yyyy'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="tps">
                        <mat-header-cell *matHeaderCellDef>Target Properties</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.num_of_tps}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vals">
                        <mat-header-cell *matHeaderCellDef>Valuations</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.num_of_valuations}}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['toe', 'agency', 'date', 'tps', 'vals']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['toe', 'agency', 'date', 'tps', 'vals']"></mat-row>
                </mat-table>
            </div>

            <div *ngIf="agencyInvolvementVm$|async as agencyInvolvements" class="mt-10">
                <h5 class="text-red">{{agencyInvolvements.title}}</h5>
                <mat-table [dataSource]="agencyInvolvements.dataSource" class="mt-4 agency-involvements">
                    <ng-container matColumnDef="toe">
                        <mat-header-cell *matHeaderCellDef>TOE</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.toe_name}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lead_valuer">
                        <mat-header-cell *matHeaderCellDef>Lead Valuer</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.lead_valuer_name}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.toe_signing_date | date:'dd MMM yyyy'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="tps">
                        <mat-header-cell *matHeaderCellDef>Target Properties</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.num_of_tps}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vals">
                        <mat-header-cell *matHeaderCellDef>Valuations</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.num_of_valuations}}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['toe', 'lead_valuer', 'date', 'tps', 'vals']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['toe', 'lead_valuer', 'date', 'tps', 'vals']"></mat-row>
                </mat-table>
            </div>

            <div *ngIf="lvInvolvementTpsVm$|async as lvInvolvements" class="mt-10">
                <h5 class="text-red">{{lvInvolvements.title}}</h5>
                <mat-table [dataSource]="lvInvolvements.dataSource" class="mt-4 lv-involvements-tps">
                    <ng-container matColumnDef="current_tp">
                        <mat-header-cell *matHeaderCellDef>Current TP</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.current_tp}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="matching_tp">
                        <mat-header-cell *matHeaderCellDef>Matching Property</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.matching_tp}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="distance">
                        <mat-header-cell *matHeaderCellDef>Distance</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.distance}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="toe">
                        <mat-header-cell *matHeaderCellDef>TOE</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.toe}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="agency">
                        <mat-header-cell *matHeaderCellDef>Agency</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.agency}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.date | date:'dd MMM yyyy'}}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['current_tp', 'matching_tp', 'distance', 'toe', 'agency', 'date']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['current_tp', 'matching_tp', 'distance', 'toe', 'agency', 'date']"></mat-row>
                </mat-table>
            </div>

            <div *ngIf="agencyInvolvementTpsVm$|async as agencyInvolvements" class="mt-10">
                <h5 class="text-red">{{agencyInvolvements.title}}</h5>
                <mat-table [dataSource]="agencyInvolvements.dataSource" class="mt-4 agency-involvements-tps">
                    <ng-container matColumnDef="current_tp">
                        <mat-header-cell *matHeaderCellDef>Current TP</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.current_tp}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="matching_tp">
                        <mat-header-cell *matHeaderCellDef>Matching Property</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.matching_tp}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="distance">
                        <mat-header-cell *matHeaderCellDef>Distance</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.distance}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="toe">
                        <mat-header-cell *matHeaderCellDef>TOE</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.toe}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lead_valuer">
                        <mat-header-cell *matHeaderCellDef>Lead Valuer</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.lead_valuer}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            {{item.date | date:'dd MMM yyyy'}}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['current_tp', 'matching_tp', 'distance', 'toe', 'lead_valuer', 'date']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['current_tp', 'matching_tp', 'distance', 'toe', 'lead_valuer', 'date']"></mat-row>
                </mat-table>
            </div>
        </mat-dialog-content>
    </div>
</div>