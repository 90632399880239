import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../core/reducers';

import {ClientContactModel} from '../../../../../../../core/admin';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {selectContactById} from '../../../../../../../core/admin';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-contact-edit-dialog',
    templateUrl: './contact-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ContactEditDialogComponent implements OnInit, OnDestroy {

    // Public properties
    contact: ClientContactModel;
    contactForm: UntypedFormGroup;
    contact$: Observable<ClientContactModel>;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;

    contact_validation_messages: any;
    // contact_validation_messages = {
    //     email: [
    //         {type: 'required', message: 'Email is ', decorated: 'required'},
    //         {type: 'email', message: 'Enter a valid ', decorated: 'email'}
    //     ],
    //     professional_phone: [
    //         {type: 'required', message: 'Phone Number is ', decorated: 'required'},
    //         {type: 'pattern', message: 'Enter a valid ', decorated: 'phone number'}
    //     ]
    // };
    emailRegex: any = '^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';


    // Private properties
    private componentSubscriptions: Subscription;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<ContactEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
        this.contact_validation_messages = {
            email: [
                {
                    type: 'required',
                    message: this.translate.instant('CONTACT.FORM.FIELD.GENERAL.EMAIL.VALIDATION.REQUIRED.MESSAGE'),
                    decorated: this.translate.instant('CONTACT.FORM.FIELD.GENERAL.EMAIL.VALIDATION.REQUIRED.DECORATION')
                }, {
                    type: 'pattern',
                    message: this.translate.instant('CONTACT.FORM.FIELD.GENERAL.EMAIL.VALIDATION.PATTERN.MESSAGE'),
                    decorated: this.translate.instant('CONTACT.FORM.FIELD.GENERAL.EMAIL.VALIDATION.PATTERN.DECORATION')
                }
            ]
        };
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.contactId) {
            this.contact$ = this.store.pipe(select(selectContactById(this.data.contactId)));
        } else {
            const newClientContactModel = new ClientContactModel();
            newClientContactModel.clear();
            this.contact$ = of(newClientContactModel);
        }

        this.contact$.subscribe(res => {
            if (!res) {
                this.contact = Object.assign({}, this.data._contact);
                this.createForm();
                return;
            }

            this.contact = Object.assign({}, res);
            this.createForm();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
    createForm() {
        this.contactForm = this.fb.group({
            first_name: [this.contact.first_name, Validators.required],
            last_name: [this.contact.last_name, Validators.required],
            role: [this.contact.role, Validators.required],
            professional_phone: [this.contact.professional_phone, Validators.required],
            email: [this.contact.email, [Validators.required, Validators.pattern(this.emailRegex)]]
        });
    }

    /** ACTIONS */

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        /** check form */

        const controls = this.contactForm.controls;
        /** check form */
        if (this.contactForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        this.contact.email = this.contact.email
            ? this.contact.email.toLowerCase()
            : null;

        this.dialogRef.close({
            contact: this.contact
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.contact && this.contact.id) {
            // tslint:disable-next-line:no-string-throw
            return this.translate.instant('CONTACT.FORM.TITLE.EDIT', {name: this.contact.first_name});
        }

        // tslint:disable-next-line:no-string-throw
        if (this.data.clientName && this.data.clientName.length > 0) {
            return this.translate.instant('CONTACT.FORM.TITLE.NEW_OF', {client_name: this.data.clientName});
        }
        return this.translate.instant('CONTACT.FORM.TITLE.NEW');
    }



}
