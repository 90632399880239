import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {LayoutUtilsService, TypesUtilsService} from '../../../../../../../core/_base/crud';
import {ContactEditDialogComponent} from '../contact-edit/contact-edit.dialog.component';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../core/reducers';
import {SelectionModel} from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';

import {
    AllContactsRequested,
    ContactOnServerCreated,
    ContactDeletedFromTrash, ContactUpdated
} from '../../../../../../../core/admin/_actions/contact.actions';
import {selectAllContactsWithTrash,  ClientContactModel} from '../../../../../../../core/admin';
import {selectTrashedContacts} from '../../../../../../../core/admin/_selectors/contact.selectors';

@Component({
    selector: 'kt-contact-list',
    templateUrl: './contact-list.component.html'
})

export class ContactListComponent implements OnInit, AfterViewInit {

    displayedColumns = ['first_name', 'last_name', 'role', 'email', 'phone', 'actions'];
    contactData: ClientContactModel[] = [];
    // clientIdSubject
    @Input() clientId: number;
    @Input() clientName: string;
    @Input() readonly: boolean = false;
    dataSource = new MatTableDataSource([]);

    @ViewChild(MatSort, {static: true}) matSort: MatSort;

    // Selection
    selection = new SelectionModel<ClientContactModel>(true, []);

    constructor(private store: Store<AppState>,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        if (this.clientId) {
            this.store.dispatch(new AllContactsRequested({clientId: this.clientId}));
            this.store.pipe(
                select(selectAllContactsWithTrash),
            ).subscribe(res => {
                if (!res) {
                    return;
                }
                this.contactData = Object.assign([], res.items);
                this.dataSource.data = this.contactData;
            });

            this.store.pipe(
                select(selectTrashedContacts),
            ).subscribe(res => {
            });
        }
    }

    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
    ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
    }

    addContact() {
        const newContact = new ClientContactModel();
        newContact.clear(); // Set all defaults fields
        newContact.client_id = this.clientId;
        this.editContact(newContact, this.contactData.length);
    }

    editContact(contact: ClientContactModel, _index: number) {
        const dialogRef = this.dialog.open(ContactEditDialogComponent, {
            data: {
                contactId: contact.id,
                _contact: contact,
                clientName: this.clientName
            }
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }


                const _contact = Object.assign({}, res.contact) as ClientContactModel;

                if (this.clientId) {

                    _contact.client_id = this.clientId;

                    if (_contact.id) {

                        this.store.dispatch(new ContactUpdated({
                            partialContact: {
                                id: _contact.id,
                                changes: _contact
                            },
                            contact: _contact
                        }));
                        return;
                    }
                    this.store.dispatch(new ContactOnServerCreated({contact: _contact}));
                    return;
                }
                if (_index >= this.contactData.length) {
                    this.contactData.push(_contact);
                } else {
                    this.contactData[_index] = _contact;
                }
                this.dataSource.data = this.contactData;
            }
        );
    }

    deleteContact(contact: ClientContactModel, i: number) {
        const _title = this.translate.instant('CONTACT.LIST.DIALOG.DELETE.TITLE');
        // const _description: string = 'The contact "' + contact.first_name + '" will be deleted';
        const _description: string = this.translate.instant('CONTACT.LIST.DIALOG.DELETE.DESCRIPTION', {name: contact.first_name});
        const _waitDesciption = this.translate.instant('CONTACT.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            if (this.clientId) {
                this.store.dispatch(new ContactDeletedFromTrash({contactId: contact.id}));
            } else {
                this.contactData.forEach((element, index) => {
                    if (contact.id != undefined && contact.id == element.id) {
                        this.contactData.splice(index, 1);
                        this.dataSource.data = this.contactData;
                        return;
                    }
                });

                if (contact.id == undefined) {
                    this.contactData.splice(i, 1);
                    this.dataSource.data = this.contactData;
                }
            }
        });
    }
}
