import { StandardMeasurement, UnitAreaMeasurement } from "../../linked-tables"
import { VTPApartmentModel } from "./valuation-apartment.model"
import { VBuilding } from "./valuation-asset-class-building.model"
import { VAssetClassModelTypes } from "./valuation-asset-class.model"
import { VTPHouseModel } from "./valuation-house.model"
import { VTPOfficeModel } from "./valuation-office.model"
import { VTPParkingModel } from "./valuation-parking.model"
import { VTPRetailBuildingModel } from "./valuation-retail-building.model"
import { VTPRetailShopModel } from "./valuation-retail-shop.model"
import { VTPWarehouseModel } from "./valuation-warehouse.model"
import { VTPLandModel } from './valuation-land.model'

export class ValuationProcessTargetPropertyModel {
    id: number
    name: string

    generalInfo: ValuationProcessAssetClassGeneralInfoModel
    info: ValuationProcessTargetPropertyInfo

    instructedToMeasure: boolean

    inspectionType: string
    inspectionDate: Date
    draftDate: Date
    finalDate: Date
    timezone: string

    pictures: PictureModel[]

    buildingInfo: ValuationProcessBuildingInfo

    countryCurrency: string
}

export enum TypeOfInspection {
    Desktop = 1,
    Internal = 3,
    Full = 4
}

export type ValuationProcessTargetPropertyInfo 
    = VTPApartmentModel
    | VTPHouseModel
    | VTPOfficeModel
    | VTPRetailShopModel
    | VTPRetailBuildingModel
    | VTPWarehouseModel
    | VTPParkingModel
    | VTPLandModel

export type ValuationProcessLocationModel = {
    countryName: string,
    cityName: string,
    zipCode: string,
    address: string,
    latitude: number,
    longitude: number,
    timezoneOffset: string | null
}

export type ValuationProcessTpBaseModel = {
    propertySubTypeId: number
}

export type ValuationProcessApartmentModel = ValuationProcessTpBaseModel & {
    propertyType: 'Residential',
    propertyTypeName: string,
    propertySubType: 'Apartment',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string,

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,
    bedroom: number,
    selectedExternalArea: any,
}

export type ValuationProcessOfficeModel = ValuationProcessTpBaseModel & {
    propertyType: 'Commercial',
    propertyTypeName: string,
    propertySubType: 'Office',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string,

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,
    selectedExternalArea: any,
}

export type ValuationProcessRetailShopModel = ValuationProcessTpBaseModel & {
    propertyType: 'Commercial',
    propertyTypeName: string,
    propertySubType: 'Retail Shop',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string,

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,
    selectedExternalArea: any,
}

export type ValuationProcessRetailBuildingModel = ValuationProcessTpBaseModel & {
    propertyType: 'Commercial',
    propertyTypeName: string,
    propertySubType: 'Retail Building',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,

    propertyGrade: string
}

export type ValuationProcessParkingModel = ValuationProcessTpBaseModel & {
    propertyType: 'Commercial',
    propertyTypeName: string,
    propertySubType: 'Parking',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,
}

export type ValuationProcessHouseModel = ValuationProcessTpBaseModel & {
    propertyType: 'Residential',
    propertyTypeName: string,
    propertySubType: 'House',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,

    propertyGrade: string
}

export type ValuationProcessWareouseModel = ValuationProcessTpBaseModel & {
    propertyType: 'Industrial',
    propertyTypeName: string,
    propertySubType: 'Warehouse',
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData: ValuationProcessLocationModel,
    locationGrade: string

    measurementStandardId: number,
    measurementStandard: string,
    measurementStandardModel: StandardMeasurement | null
    size: number,
    unitAreaMeasurementAcronym: string,
    unitAreaMeasurementModel: UnitAreaMeasurement | null
    unitMeasurementName: string

    accommodation: any,
    floorNumberingSchemeId: number,

    propertyGrade: string
}

export type ValuationProcessLandModel = ValuationProcessTpBaseModel & {
    propertySubType: 'Land'
    propertyTypeName: string,
    propertySubTypeName: string,
    locationData: ValuationProcessLocationModel,
    locationGrade: string

    planningStatusName: string,
    coordinateReferenceSystemName: string,
    numOfBuilding: 'Unknown' | number,

    totalLandParcel: number,
    totalLandArea: number,
    unitMeasurement: string
    unitMeasurementName: string
}

export type PropertySubTypes = ValuationProcessAssetClassGeneralInfoModel['propertySubType']
export type VPropertySubTypes = VAssetClassModelTypes['propertySubType']

export type ValuationProcessAssetClassGeneralInfoModel 
    =  ValuationProcessApartmentModel
    | ValuationProcessHouseModel
    | ValuationProcessOfficeModel
    | ValuationProcessRetailShopModel
    | ValuationProcessRetailBuildingModel
    | ValuationProcessParkingModel
    | ValuationProcessWareouseModel
    | ValuationProcessLandModel

export type ValuationProcessBuildingInfo = {
    name: string,
    buildingGrade: string,
    buildingType: string,
    buildingTypeComment: string,
    energyEfficiencyGrade: string,
    completionYear: string,
    developer: string,
    anchorTenant: string,
    foundationType: string,
    buildingDescription: string,
    pictures: PictureModel[]
}

export type PictureModel = {
    id: number
    title: string
    path: string
    name: string
} 

export function getPictureUrl(picture: PictureModel, subDomain: string): string {
    let _path = picture.path + '/' + picture.name
    if (_path.charAt(0) == '/') {
        _path = _path.substring(1, _path.length)
    }
    return `api/${subDomain}/files/download?path=` + _path 
}