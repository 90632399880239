<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                Consideration calculation
            </h3>
        </div>

        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;" (click)="onSave()" class="btn btn-icon btn-sm btn-primary mr-2 p-1"
                    style="width: 80px">Save</a>
            </span>
            <a href="javascript:;" (click)="onClose()" class="btn btn-icon btn-sm btn-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>

    <div class="card-body">
        <mat-dialog-content>
            <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onCloseErrors()">
                <div>Please complete the form before saving!</div>
            </kt-alert>
            <kt-leasehold-information-table *ngIf="data.process !== processType.FreeholdToLeasehold"
                [tpCurrency]="data.tpCurrency" [consideration]="consideration"></kt-leasehold-information-table>

            <kt-headline-to-effective #headlineToEffective
                *ngIf="data.process === processType.HeadlineToEffective || data.process === processType.HeadlineToEffectiveToLeaseholdToFreehold"
                [refNum]="data.refNum" [tpCurrency]="data.tpCurrency" [consideration]="consideration"
                [method]="data.hteProcessData.methods"
                [ratesAndJustifications]="data.hteProcessData.tvomRatesAndJustifications"
                [cfmRatesAndJustifications]="data.hteProcessData.cfmRatesAndJustifications"
                [selected]="data.hteProcessData.selected" (onValueComputed)="headlineToEffectiveResult($event)">
            </kt-headline-to-effective>

            <kt-value-calculation #valueCalculation *ngIf="data.process !== processType.HeadlineToEffective"
                [currency]="data.tpCurrency" [ary]="data.holdConversionProcessData.ary"
                [calculationType]="calculationType" [value$]="value$"
                (onValueComputed)="valueCalculationResult($event)">
            </kt-value-calculation>
        </mat-dialog-content>
    </div>
</div>