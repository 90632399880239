import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ValuationsService } from "src/app/core/asset_class";

@Injectable()
export class ValuationResolver implements Resolve<any> {
    constructor(public valuationService: ValuationsService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const valId = Number(route.params.valuation_id);
        return this.valuationService.getValuation(valId);
    }
}