<ng-template #toeRequiredRows>
    <ng-container *ngIf="dataSource$ | async as dataSource">
        <tr *ngFor="let data of dataSource; let i = index" [ngClass]="{
            'required_file': (data.client_ref == 0 || (data.client_ref == 2 && data.path.length < 1 )),
            'completed': (data.client_ref == 1 || (data.client_ref == 2 && data.path.length > 0 ))
        }">
            <td class="column-type">
                <span *ngIf="data.name.length === 0">N/I</span>
                <img *ngIf="data.name.length > 0" [attr.src]="typesUtilsService.setIcon(getFileType(data.name))" />
            </td>
            <td class="column-required">Yes</td>
            <td class="column-category">
                {{ data.cat_name }} {{data.cat_cnt != 1 || (i + 1 < uploadFiles.length && data.cat_id == uploadFiles[i + 1].cat_id) ? data.cat_cnt : ''}}
            </td>
            <td class="column-description">
                {{ data.ac_ser ? data.ac_ser.description : '' }}
            </td>
            <td class="column-available">
                {{ data.client_ref == 2 ? 'YES' : data.client_ref == 1 ? 'NO' : 'N/A' }}
            </td>
            <td class="column-actions">
                <button mat-icon-button 
                    [disabled]="tpState == 1 || toeStatus == 4"
                    *ngIf="!(data.client_ref == 2 && data.name.length > 0)" 
                    color="primary" 
                    matTooltip="Edit Reference" 
                    type="button" 
                    (click)="editFileReference(data)" 
                    [disabled]="readOnly">
                    <mat-icon>create</mat-icon>
                </button>
                <span *ngIf="hasCert(data.cat_id)">
                    <button mat-icon-button 
                        [disabled]="tpState == 1 || toeStatus == 4"
                        *ngIf="data.client_ref == 2 && data.name.length > 0" 
                        color="warn" 
                        matTooltip="Delete File" 
                        (click)="deleteUploadedFile(data)" 
                        type="button">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button
                        *ngIf="data.client_ref == 2 && data.name.length > 0"
                        matTooltip="Preview file"
                        (click)="previewUploadedFile(data)"
                        type="button">
                        <mat-icon *ngIf="!docs.includes(getFileType(data.name))">remove_red_eye</mat-icon>
                        <mat-icon *ngIf="docs.includes(getFileType(data.name))">get_app</mat-icon>
                    </button>
                    <button mat-icon-button
                        [disabled]="tpState == 1 || toeStatus == 4"
                        *ngIf="data.client_ref == 2 && (data.name.length < 1 || (data.name.length > 0 &&  (i + 1 == uploadFiles.length || data.cat_id != uploadFiles[i+1].cat_id)))"
                        matTooltip="Upload file"
                        (click)="addFile(data, i)"
                        color="primary"
                        type="button">
                        <mat-icon *ngIf="data.name.length < 1">cloud_upload</mat-icon>
                        <mat-icon *ngIf="data.name.length > 0">note_add</mat-icon>  
                    </button>
                </span>
                <button mat-icon-button
                    *ngIf="data.client_ref == 2 && data.name.length > 0 && !hasCert(data.cat_id)"
                    matTooltip="Preview file"
                    (click)="previewUploadedFile(data)"
                    type="button">
                    <mat-icon *ngIf="!docs.includes(getFileType(data.name))">remove_red_eye</mat-icon>
                    <mat-icon *ngIf="docs.includes(getFileType(data.name))">get_app</mat-icon>
                </button>
            </td>
        </tr>
    </ng-container>
</ng-template>
