import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByNestedLabel'
})
export class NestedLabelPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            if (args === undefined || args === '') { return value; }
        } else {
            return [];
        }
        return value.filter(data => {
            return  data.type.toLowerCase().includes(args.toLowerCase())
                || data.sub.find(item => item.label.toLowerCase().includes(args.toLowerCase()));
        });
    }
}
