<div ngbDropdown class="w-100">
    <button ngbDropdownToggle class="btn w-100 dropdown-toggle d-flex justify-content-between align-items-center position-relative text-white"
        style="padding-top: 0.8rem; padding-bottom: 0.8rem;"
        [style.background-color]="bg"
        (click)="onClick($event)">
        <small class="sub_text">{{subText$|async}}</small>
        <p class="mb-0">{{text$ | async}}</p>
    </button>
</div>

<ng-template #timePicker>
    <ngx-daterangepicker-material 
        [autoApply]="true"
        [startDate]="selectedDates.startDate"
        [endDate]="selectedDates.endDate"
        [locale]="{applyLabel: 'ok', format: 'MMM DD, YYYY', separator: ' to '}"
        (choosedDate)="choosedDate($event)"></ngx-daterangepicker-material>
</ng-template>