<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h4 class="card-label">
				Add new conflicts of interests for "{{data.toe.name}}"
			</h4>
		</div>
		<div class="card-toolbar">
			<button mat-button mat-raised-button style="background-color: orange; color: white;" (click)="cancel()" 
				class="mr-2">
				Cancel
			</button>
			<button mat-button mat-raised-button 
				class="validate-btn"
				(click)="validate()">
				Validate
			</button>
		</div>
	</div>

	<div class="card-body pt-0 w-100">
		<mat-dialog-content>
			<div class="col-lg-12 mb-2">
				<h5 class="my-3 text-mad text-uppercase">trigger events</h5>
				<p>
					<strong>Preamble to the Conflict of Interest checks. The questions below serve as a basis to adjust the list of potential conflicts of interest for this valuation assignment.</strong>
				</p>
				<hr class="active">
			</div>

			<div class="col-lg-12 p-3 mb-20">
				<h6 class="mb-4">
					<strong>1. Does the agency or the valuer have any previous knowledge of, or relationship with, the client or the asset to be valued ?</strong>
				</h6>
				<div class="mb-10">
					<mat-radio-group class="pl-1" (change)="prevKnowledgeRadioChange($event)" [(ngModel)]="selectedPrevKnowledge">
						<mat-radio-button [value]="0">
						No 
						</mat-radio-button>
						<mat-radio-button [value]="1">
						Yes 
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<h6 class="mb-4">
					<strong>2. Does the valuer or the agency have any existing or future financial interest in the property to be valued or the client?</strong>
				</h6>
				<mat-radio-group class="mb-2 p-1" (change)="financialInterestRadioChange($event)" [(ngModel)]="selectedFinancialInterest">
					<mat-radio-button [value]="0">
						No 
					</mat-radio-button>
					<mat-radio-button [value]="1">
						Yes 
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile">
				<h5 class="my-3 text-mad text-uppercase">CONFLICTS OF INTEREST</h5>
				<p>
					<strong>Conflict of Interest (RICS professional standards and guidance, global - Conflict of Interest, 1st edition, March 2017, revised December 2017) </strong>
				</p>
				<hr class="active">
			</div>
			<div class="col-lg-12 kt-margin-bottom-20-mobile p-3"
					[ngClass]="{'col-lg-12 kt-margin-bottom-20-mobile p-3 gray': i%2, 'col-lg-12 kt-margin-bottom-20-mobile p-3' : true}"
					*ngFor="let ci of (ciTemplateData$|async);let i = index;">
				<kt-conflict-detail
						#cdComponents
						[conflictData]="ci"
						[index]="i"
						[tp]="targetProperties">
				</kt-conflict-detail>
			</div>

		</mat-dialog-content>
	</div>
</div>