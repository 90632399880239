<div class="col-md-12 mb-1">
    <h6 class="my-3 text-mad text-uppercase">Perliminary Calculation</h6>
</div>
<div class="col-md-12 mat-table-wrapper">
    <table class="table lmat-elevation-z8" style="overflow: hidden;">
        <tr>
            <th class="calculation__name">Headline Rent (per year) ({{data.tpCurrency}})</th>
            <td class="calculation__value">{{data.calculatedResult.preliminary.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</td>
            <td class="calculation__tooltip">
                <ng-template #popHeadlineRent>
                    <strong>Calculation:</strong> Headline Rent (per year)
                    <ng-container *ngIf="data.rent_input_amount_type === 'total'">
                        <ng-container *ngIf="data.rent_basis_id === 1">
                            <br> 
                            = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span> <span class="text-red"> x 12</span>
                            <br>
                            = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x 12</span>
                        </ng-container>
                        <ng-container *ngIf="data.rent_basis_id === 2">
                            <br> 
                            = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span> <span class="text-red"> x 4</span>
                            <br>
                            = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x 4</span>
                        </ng-container>
                        <ng-container *ngIf="data.rent_basis_id === 3">
                            <br> 
                            = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span>
                            <br>
                            = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="data.rent_input_amount_type === 'per_units'">
                        <ng-container *ngIf="data.rent_basis_id === 1">
                            <br> 
                            = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span> <span class="text-red"> x 12</span>
                            <br>
                            = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span> <span class="text-red"> x 12</span>
                        </ng-container>
                        <ng-container *ngIf="data.rent_basis_id === 2">
                            <br> 
                            = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span> <span class="text-red"> x 4</span>
                            <br>
                            = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span> <span class="text-red"> x 4</span>
                        </ng-container>
                        <ng-container *ngIf="data.rent_basis_id === 3">
                            <br> 
                            = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span>
                            <br>
                            = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span>
                        </ng-container>
                    </ng-container>
                    <br>
                    = <span class="text-green">{{data.calculatedResult.preliminary.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</span>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popHeadlineRent" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr class="gray">
            <th>Rent Free Period (Years)</th>
            <td colspan="2">{{data.rent_free_period | mask:'separator.2'}}</td>
        </tr>

        <ng-container>
            <tr *ngIf="data.methodVersion === methodVersionEnum.WriteOffIsLesserThanLease">
                <th>Write off (after how many years)</th>
                <td colspan="2">{{data.write_off_period | mask:'separator.2'}}</td>
            </tr>
            <tr *ngIf="data.methodVersion === methodVersionEnum.WriteOffIsEqualToLeaseOrNA">
                <th>Lease Duration (Years)</th>
                <td colspan="2">{{data.consideration.lease_duration}}</td>
            </tr>
        </ng-container>

        <tr class="gray">
            <th>Rent Received for (Years)</th>
            <td>{{data.calculatedResult.preliminary.rentReceivedFor | mask:'separator.2'}}</td>
            <td>
                <ng-template #popRentReceivedFor>
                    <strong>Calculation:</strong> Rent Received for (years) 
                    <br>
                    = <span class="text-blue">{{data.methodVersion === methodVersionEnum.WriteOffIsLesserThanLease 
                        ? '(Write off)' 
                        : '(Lease Duration)'}}</span> <span class="text-red"> - </span> <span class="text-blue">(Rent Free Period)</span> 
                    <br>
                    = <span class="text-blue">{{data.methodVersion === methodVersionEnum.WriteOffIsLesserThanLease 
                        ? (data.write_off_period | mask:'separator.2' )
                        : data.consideration.lease_duration}}</span> <span class="text-red"> - </span> <span class="text-blue">{{data.rent_free_period|mask:'separator.2'}}</span> 
                    <br>
                    = <span class="text-green">{{data.calculatedResult.preliminary.rentReceivedFor | mask:'separator.2'}}</span>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popRentReceivedFor" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr>
            <th>Fitting out Period (Years)</th>
            <td colspan="2">{{data.fitting_out_period | mask:'separator.2'}}</td>
        </tr>

        <tr class="gray">
            <th>
                <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.preliminary.rentReceivedFor, rate: data.rateValue.targetRate}"></ng-container>
            </th>
            <td>
                <ng-container *ngIf="data.calculatedResult.preliminary.yearsPurchaseOfRentReceivedFor; else notAvailable">
                    {{data.calculatedResult.preliminary.yearsPurchaseOfRentReceivedFor}}
                </ng-container>
            </td>
            <td>
                <ng-template #popYearsPurchasePreliminary>
                    <strong>Calculation:</strong> 
                        <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.preliminary.rentReceivedFor, rate: data.rateValue.targetRate}"></ng-container>
                    <br>
                    = 
                        <span class="text-red">(1 - (1 + </span>
                        <span class="text-blue">(Target Rate)</span>
                        <span class="text-red">)</span>
                        <sup>
                            <span class="text-red">-</span>
                            <span class="text-blue">(Rent Received for)</span>
                        </sup>
                        <span class="text-red">)/</span>
                        <span class="text-blue">(Target Rate)</span>
                    <br>
                    <ng-container *ngIf="data.calculatedResult.preliminary.yearsPurchaseOfRentReceivedFor">
                        =  
                            <span class="text-red">(1 - (1 + </span>
                            <span class="text-blue">{{data.rateValue.targetRate}}%</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">{{data.calculatedResult.preliminary.rentReceivedFor}}</span>
                            </sup>
                            <span class="text-red">)/</span>
                            <span class="text-blue">{{data.rateValue.targetRate}}%</span>
                        <br>
                        = <span class="text-green">{{data.calculatedResult.preliminary.yearsPurchaseOfRentReceivedFor}}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popYearsPurchasePreliminary" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr>
            <th>
                <ng-container *ngTemplateOutlet="presentValue; context: {year: data.rent_free_period, rate: data.rateValue.targetRate}"></ng-container>
            </th>
            <td>
                <ng-container *ngIf="data.calculatedResult.preliminary.presentValueOfRentFreePeriod; else notAvailable">
                    {{data.calculatedResult.preliminary.presentValueOfRentFreePeriod}}
                </ng-container>
            </td>
            <td>
                <ng-template #popPresentValuePreliminary>
                    <strong>Calculation:</strong> 
                        <ng-container *ngTemplateOutlet="presentValue; context: {year: data.rent_free_period, rate: data.rateValue.targetRate}"></ng-container>
                    <br>
                    = 
                        <span class="text-red">(1 + </span>
                        <span class="text-blue">(Target Rate)</span>
                        <span class="text-red">)</span>
                        <sup>
                            <span class="text-red">-</span>
                            <span class="text-blue">(Rent Free Period)</span>
                        </sup>
                    <br>
                    <ng-container *ngIf="data.calculatedResult.preliminary.presentValueOfRentFreePeriod">
                        =  
                            <span class="text-red">(1 + </span>
                            <span class="text-blue">{{data.rateValue.targetRate}}%</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">{{data.rent_free_period | mask:'separator.2'}}</span>
                            </sup>
                        <br>
                        = <span class="text-green">{{data.calculatedResult.preliminary.presentValueOfRentFreePeriod}}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popPresentValuePreliminary" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr class="gray">
            <th>Capital Value of Headline Rent ({{data.tpCurrency}})</th>
            <td>
                <ng-container *ngIf="data.calculatedResult.preliminary.capitalValueOfHeadlineRent; else notAvailable">
                    {{data.calculatedResult.preliminary.capitalValueOfHeadlineRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}
                </ng-container>
            </td>
            <td>
                <ng-template #popCapValOfHeadlineRent>
                    <strong>Calculation:</strong> Capital Value of Headline Rent 
                    <br>
                    = 
                        <span class="text-blue">(Headline Rent)</span>
                        <span class="text-red"> x </span>
                        <span class="text-blue">(Year's Purchase)</span>
                        <span class="text-red"> x </span>
                        <span class="text-blue">(Present Value)</span>
                    <br>
                    <ng-container *ngIf="data.calculatedResult.preliminary.capitalValueOfHeadlineRent">
                        =  
                            <span class="text-blue">{{data.calculatedResult.preliminary.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">{{data.calculatedResult.preliminary.yearsPurchaseOfRentReceivedFor}}</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">{{data.calculatedResult.preliminary.presentValueOfRentFreePeriod}}</span>
                        <br>
                        = <span class="text-green">{{data.calculatedResult.preliminary.capitalValueOfHeadlineRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popCapValOfHeadlineRent" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

        <tr>
            <th>Capital Payment ({{data.tpCurrency}})</th>
            <td colspan="2">{{data.capital_payment | mask:'separator.2':{thousandSeparator: ','} }}</td>
        </tr>

        <tr class="gray">
            <th>Capital Value of Inducements ({{data.tpCurrency}})</th>
            <td>
                <ng-container *ngIf="data.calculatedResult.preliminary.capitalValueOfInducements; else notAvailable">
                    {{data.calculatedResult.preliminary.capitalValueOfInducements.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}
                </ng-container>
            </td>
            <td>
                <ng-template #popCapValOfInducements>
                    <strong>Calculation:</strong> Capital Value of Inducements 
                    <br>
                    = 
                        <span class="text-blue">(Capital Value)</span>
                        <span class="text-red"> - </span>
                        <span class="text-blue">(Capital Payment)</span>
                    <br>
                    <ng-container *ngIf="data.calculatedResult.preliminary.capitalValueOfInducements">
                        =  
                            <span class="text-blue">{{data.calculatedResult.preliminary.capitalValueOfInducements.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            <span class="text-red"> - </span>
                            <span class="text-blue">{{data.capital_payment | mask:'separator.2':{thousandSeparator: ','} }}</span>
                        <br>
                        = <span class="text-green">{{data.calculatedResult.preliminary.capitalValueOfInducements.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                    </ng-container>
                </ng-template>
                <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popCapValOfInducements" style="cursor: pointer;">
                    <mat-icon color="primary">information</mat-icon>
                </span>
            </td>
        </tr>

    </table>
</div>

<ng-template #yearsPurchase let-year="year" let-rate="rate">
    Year's Purchase (YP) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year | mask:'separator.2'}} year(s)
</ng-template>


<ng-template #presentValue let-year="year" let-rate="rate">
    Present Value (PV) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year | mask:'separator.2'}} year(s)
</ng-template>

<ng-template #notAvailable>
    <span class="text-red">N/A</span>
</ng-template>