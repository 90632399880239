import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { DeleteSignedDoc, GetTpSignedDocs, UploadTpSignedDoc } from 'src/app/core/asset_class/_actions/tp-signed-docs.actions';
import { TpSignedDoc } from 'src/app/core/asset_class/_models/tp-signed-doc.model';
import { selectIsUploading, selectTpSignedDocs, selectTpSignedDocState } from 'src/app/core/asset_class/_selectors/tp-signed-docs.selectors';
import { User } from 'src/app/core/auth';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';

export enum ReportType {
  DraftStatement = 1,
  ValuationReport = 2
}

@Component({
  selector: 'kt-signed-reports',
  templateUrl: './signed-reports.component.html',
  styleUrls: ['./signed-reports.component.scss']
})
export class SignedReportsComponent implements OnInit, OnDestroy {
  @Input() toeStatus: number;
  @Input() tpState: number;
  @Input() tpID: number;
  @Input() userID: number;
  @Input() taskUserId: number;
  @Input() user: User;
  @Input() draftStatementGenerated$: Observable<boolean>;
  @Input() valuationReportGenerated$: Observable<boolean>;
  @ViewChild(NgbDropdown) dropDown: NgbDropdown;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  onDestroy$: Subject<void> = new Subject();

  uploadingReport$: Observable<boolean>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  hasData$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  dataSource = new MatTableDataSource([]);
  displayedColumns = ['type', 'uploaded_date', 'file_size', 'actions']
  reportType = ReportType;
  private currentType: ReportType;
  private draftStatementReport: TpSignedDoc = new TpSignedDoc(ReportType.DraftStatement);
  private valuationReport: TpSignedDoc = new TpSignedDoc(ReportType.ValuationReport);

  constructor(
    private store: Store<AppState>,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.loading$.next(false);
    this.store.dispatch(new GetTpSignedDocs({tpID: this.tpID}));
    this.uploadingReport$ = this.store.select(selectIsUploading(this.tpID));
    combineLatest([
      this.valuationReportGenerated$,
      this.draftStatementGenerated$,
      this.store.select(selectTpSignedDocs(this.tpID))
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([valGenerated, draftGenerated, reports]) => {
      const data: TpSignedDoc[] = [];
      if (reports) {
        reports.forEach(r => data.push(r));
      }
      if (data.length > 0) {
        data.sort((a, b) => {
            const d1 = new Date(b.created_at);
            const d2 = new Date(a.created_at);
            return d1.getTime() - d2.getTime();
        })
      }
      const drafts: TpSignedDoc[] = data.filter(item => item.type === ReportType.DraftStatement);
      const vals: TpSignedDoc[] = data.filter(item => item.type === ReportType.ValuationReport);
      if (drafts.length > 0) {
        this.draftStatementReport = drafts[0];
      } else {
        this.draftStatementReport = new TpSignedDoc(ReportType.DraftStatement);
      }
      if (vals.length > 0) {
        this.valuationReport = vals[0];
      } else {
        this.valuationReport = new TpSignedDoc(ReportType.ValuationReport);
      }
      const res = [];
      if (draftGenerated) {
        res.push(this.draftStatementReport);
      }
      if (valGenerated) {
        res.push(this.valuationReport);
      }
      this.dataSource.data = res;
    })

    this.loading$.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (!res && this.dataSource.data.length == 0) {
        this.hasData$.next(false);
      } else {
        this.hasData$.next(true);
      }
    })
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onHover(event, type: string) {
    this.dropDown.toggle();
  }

  public downloadReport(item: any) {
    window.open(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + item.url, '_blank');
  }

  public uploadDoc(reportType: ReportType) {
    this.currentType = reportType;
    this.fileInput.nativeElement.click();
  }

  public uploadFile(event) {
    const files: FileList = event.target.files;
    if (files.length == 0) {
      return;
    }
    const formData = new FormData();
    Array.from(files).forEach((file, i) => {
      formData.append('files[' + i + ']', file, file.name);
    })
    formData.append('type', this.currentType == ReportType.DraftStatement ? 'draft' : 'valuation')
    formData.append('user_id', this.userID.toString());
    this.store.dispatch(new UploadTpSignedDoc({tpID: this.tpID, data: formData}));
  }

  public deleteReport(report: TpSignedDoc) {
    this.store.dispatch(new DeleteSignedDoc({tpID: report.tp_id, id: report.id, type: report.type}));
  }
}
