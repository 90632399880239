import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { FileDialogComponent } from 'src/app/views/pages/toe/_sub/file-dialog/file-dialog.component';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';

@Component({
  selector: 'kt-vpga-two',
  templateUrl: './vpga-two.component.html',
  styleUrls: ['./vpga-two.component.scss']
})
export class VpgaTwoComponent implements OnInit {
  @Input()
  readonly: boolean;
  @Input()
  SummaryOfOccupationalLeasesFiles: any[];
  @Input()
  CurrentRentalIncomeFiles: any[];

  public parentFormGroup: AbstractControl;
  private _typeOfPropertyCtrl: AbstractControl;
  public summaryOfOccupationalLeasesFiles: any[] = [];
  public currentRentalIncomeFiles: any[] = [];
  public selectedCoBAssumptions: any[] = [];

  public generalCommentsQuestion: {index: number, name: string, formControlName: string, formControlType: string, formControlNameDescr: string}[] = [
    {index: 1, name: 'Has a recent transaction on the property occurred or a provisionally agreed price been disclosed?', formControlName: 'price_been_disclosed', formControlType: InputType.Radio, formControlNameDescr: 'price_been_disclosed_descr'},
    {index: 2, name: 'Please provide comment on the suitability of the property as security for mortgage purposes.', formControlName: 'suitability_of_property', formControlType: 'text-input', formControlNameDescr: ''},
    {index: 3, name: 'Are there any circumstances that could affect the price?', formControlName: 'circumstances_could_affect_price', formControlType: InputType.Radio, formControlNameDescr: 'circumstances_could_affect_price_descr'},
    {index: 4, name: 'Is there potential and demand for alternative uses, or any foreseeable changes in the current mode or category of occupations?', formControlName: 'demand_for_alternative_use', formControlType: InputType.Radio, formControlNameDescr: 'demand_for_alternative_use_descr'},
    {index: 5, name: 'What is potential occupational demand for the property?', formControlName: 'occupational_demand_for_property', formControlType: 'text-input', formControlNameDescr: ''},
    {index: 6, name: 'Are there any environmental or economic special designation associated to the property?', formControlName: 'special_designation_to_property', formControlType: InputType.Radio, formControlNameDescr: 'special_designation_to_property_descr'},
    {index: 7, name: 'Have any disrepair, deleterious or harmful materials been noted?', formControlName: 'harmful_materials_been_noted', formControlType: InputType.Radio, formControlNameDescr: 'harmful_materials_been_noted_descr'},
    {index: 8, name: 'Are there any environmental issues associated to the property, such as flood risk potential, historic contamination or non conforming uses?', formControlName: 'environmental_issues_to_property', formControlType: InputType.Radio, formControlNameDescr: 'environmental_issues_to_property_descr'},
    {index: 9, name: 'What are the past, current and future trends, and any volatility in the local market and/or demand for the category of property?', formControlName: 'volatility_in_market', formControlType: 'text-input', formControlNameDescr: ''},
    {index: 10, name: 'What is your assessment of the current marketability of the interest and is it likely to be sustainable over the life of the loan?', formControlName: 'assessment_of_current_marketability', formControlType: 'text-input', formControlNameDescr: ''},
    {index: 11, name: 'Are there any other matter revealed during your enquiries that might have a material effect on the value currently reported?', formControlName: 'any_other_matter_revealed', formControlType: InputType.Radio, formControlNameDescr: 'any_other_matter_revealed_descr'},
    {index: 12, name: 'If the property is, or is intended to be, the subject of development or refurbishment for residential purposes, what would be the impact of giving incentives to purchasers?', formControlName: 'impact_of_giving_incentive', formControlType: 'radio-button', formControlNameDescr: 'impact_of_giving_incentive_descr'}
  ]

  public investmentQuestion: {index: number, name: string, formControlName: string, hasDocument?: boolean, formControlType: string, formControlNameDescr: string}[] = [
    {index: 2, name: 'Please provide a summary of occupational leases, indicating whether the leases have been read or not, and the source of any information relied on:', formControlName: 'summary_of_occupational_leases', hasDocument: true, formControlType: 'text-input', formControlNameDescr: ''},
    {index: 3, name: 'Please provide a statement of, and commentary on, current rental income, and comparison with current market rental value. If the property comprises a number of different units that can be let individually, please provide separate information on each:', formControlName: 'current_rental_income', hasDocument: true, formControlType: 'text-input', formControlNameDescr: ''},
    {index: 4, name: 'What is the market\'s view of the quality, suitability and strength of the tenant\'s covenant?', formControlName: 'market_view', formControlType: 'text-input', formControlNameDescr: ''},
    {index: 5, name: 'How would you assess the maintainability of income over the life of the loan (and any risks to the maintainability of income), with particular reference to lease breaks or determinations and anticipated market trends - this may well need to be considered in a broader sustainability context?', formControlName: 'assess_maintainability_of_income', formControlType: 'text-input', formControlNameDescr: ''},
    {index: 6, name: 'Is there any potential for redevelopment or refurbishment at the end of the occupational lease(s)?', formControlName: 'any_potential_for_redevelopment', formControlType: 'radio-button', formControlNameDescr: 'any_potential_for_redevelopment_descr'}
  ];
  public developmentQuestion: {index: number, name: string, formControlName: string}[] = [
    {index: 2, name: 'Please provide comments on costs and contract procurements:', formControlName: 'comments_on_costs'},
    {index: 3, name: 'Please provide comments on the viability of the proposed project:', formControlName: 'comments_on_viability'},
    {index: 4, name: 'What would be the implications on value of any cost overruns or contract delays?', formControlName: 'implications_on_value'},
    {index: 5, name: 'Please provide comments on the anticipated length of time the redevelopment or refurbishment will take, as this may affect the current value due to inconvenience and/or temporary lack of utility:', formControlName: 'comments_on_time'}
  ];

  public closureOfBusinesses: {id: number, name: string}[] = [
    {id: 1, name: 'The business has been closed and the property is vacant.'},
    {id: 2, name: 'The trade inventory has been depleted or removed.'},
    {id: 3, name: 'The licences, consents, certificates and/or permits have been lost or are in jeopardy.'},
    {id: 4, name: 'Accounts and records of trade are not available to a prospective purchaser.'}
  ];

  subscriptions: Subscription[] = [];

  constructor(
    private controlContainer: ControlContainer,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.SummaryOfOccupationalLeasesFiles.forEach(f => this.summaryOfOccupationalLeasesFiles.push(f));
    this.CurrentRentalIncomeFiles.forEach(f => this.currentRentalIncomeFiles.push(f));
    this.parentFormGroup = this.controlContainer.control;
    this._typeOfPropertyCtrl = ((this.parentFormGroup as UntypedFormGroup).get('property_specific_disclosure') as UntypedFormGroup).get('type_of_property');

    let val = ((this.parentFormGroup as UntypedFormGroup).get('property_specific_disclosure') as UntypedFormGroup).controls['market_value_impact'].value;
    this.selectedCoBAssumptions = val ? val.split(',') : [];

    this.parentFormGroup = this.controlContainer.control;
    const controls = (this.parentFormGroup as UntypedFormGroup).controls;

    (controls.generalComments as UntypedFormGroup).addControl('circumstances_could_affect_price_descr', new UntypedFormControl(''));
    (controls.generalComments as UntypedFormGroup).addControl('demand_for_alternative_use_descr', new UntypedFormControl(''));
    (controls.generalComments as UntypedFormGroup).addControl('special_designation_to_property_descr', new UntypedFormControl(''));
    (controls.generalComments as UntypedFormGroup).addControl('harmful_materials_been_noted_descr', new UntypedFormControl(''));
    (controls.generalComments as UntypedFormGroup).addControl('environmental_issues_to_property_descr', new UntypedFormControl(''));
    (controls.generalComments as UntypedFormGroup).addControl('any_other_matter_revealed_descr', new UntypedFormControl(''));
    (controls.generalComments as UntypedFormGroup).addControl('impact_of_giving_incentive_descr', new UntypedFormControl(''));
    (controls.property_specific_disclosure as UntypedFormGroup).addControl('any_potential_for_redevelopment_descr', new UntypedFormControl(''));

    this.generalCommentsQuestion.forEach( question => {
      if (question.formControlType == InputType.Radio) {
        const sub = (controls.generalComments as UntypedFormGroup).controls[question.formControlName].valueChanges.subscribe(val => {
          if (val == '1') {
            (controls.generalComments as UntypedFormGroup).controls[question.formControlNameDescr].setValidators([Validators.required]);
          } else {
            (controls.generalComments as UntypedFormGroup).controls[question.formControlNameDescr].setValidators([]);
            (controls.generalComments as UntypedFormGroup).controls[question.formControlNameDescr].setValue(null);
          }
          (controls.generalComments as UntypedFormGroup).controls[question.formControlNameDescr].updateValueAndValidity();
          (controls.generalComments as UntypedFormGroup).controls[question.formControlNameDescr].markAsTouched();
        });
        this.subscriptions.push(sub);
      }
    })

    this.investmentQuestion.forEach( question => {
      if (question.formControlType == InputType.Radio) {
        const sub = (controls.property_specific_disclosure as UntypedFormGroup).controls[question.formControlName].valueChanges.subscribe(val => {
          if (val == '1') {
            (controls.property_specific_disclosure as UntypedFormGroup).controls[question.formControlNameDescr].setValidators([Validators.required]);
          } else {
            (controls.property_specific_disclosure as UntypedFormGroup).controls[question.formControlNameDescr].setValidators([]);
            (controls.property_specific_disclosure as UntypedFormGroup).controls[question.formControlNameDescr].setValue(null);
          }
          (controls.property_specific_disclosure as UntypedFormGroup).controls[question.formControlNameDescr].updateValueAndValidity();
          (controls.property_specific_disclosure as UntypedFormGroup).controls[question.formControlNameDescr].markAsTouched();
        });
        this.subscriptions.push(sub);
      }
    })

    const typeOfPropertysub = this.typeOfPropertyControl.valueChanges.subscribe(val => {
      if (val == '2') {
        (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact'].setValidators([Validators.minLength(1)]);
        (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact_descr'].setValidators([Validators.required]);
      } else {
        (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact'].setValidators([]);
        (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact'].setValue('');
        (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact_descr'].setValidators([]);
        (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact_descr'].setValue(null);
      }
      (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact_descr'].updateValueAndValidity();
      (controls.property_specific_disclosure as UntypedFormGroup).controls['market_value_impact_descr'].markAsTouched();
    })
    this.subscriptions.push(typeOfPropertysub);
  }

  get typeOfPropertyControl() {
    return this._typeOfPropertyCtrl;
  }

  public getSummaryOfOccupationalLeasesFiles() {
    return this.summaryOfOccupationalLeasesFiles;
  }
  public getCurrentRentalIncomeFiles() {
    return this.currentRentalIncomeFiles;
  }

  addFiles(question: any) {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      data: {
        title: 'Documents',
        files: question.index == 2 ? this.summaryOfOccupationalLeasesFiles : this.currentRentalIncomeFiles,
        readonly: this.readonly
      },
      minHeight: '150px'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      if (question.index == 2) {
        this.summaryOfOccupationalLeasesFiles = res;
      } else {
        this.currentRentalIncomeFiles = res;
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateCoBCheckedOptions(_index, closureOfBusiness, $event) {
    const _tmpArray = Object.assign([], this.selectedCoBAssumptions);
    if ($event.checked) {
      _tmpArray.push(closureOfBusiness.id.toString());
    } else {
      _tmpArray.splice(_tmpArray.indexOf(closureOfBusiness.id.toString()), 1);
    }
    this.selectedCoBAssumptions = _tmpArray;
    ((this.parentFormGroup as UntypedFormGroup).get('property_specific_disclosure') as UntypedFormGroup).controls['market_value_impact'].setValue(this.selectedCoBAssumptions.join(','));
  }

  checkOfSelected(closureOfBusiness) {
    return this.selectedCoBAssumptions.includes(closureOfBusiness.id.toString())
  }

  public castAbstractController(value: AbstractControl): UntypedFormGroup {
    return (value as UntypedFormGroup);
  }
}

export enum InputType {
  Radio = 'radio-button',
  Text = 'text-input'
}
