import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {ToeService} from '../../../../../core/toe';
import {of, Observable} from 'rxjs';
import {SupportingValuerModel} from '../../../../../core/toe/_models/supporting-valuer.model';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';

@Injectable()
export class GetToeWorkersResolver implements Resolve<SupportingValuerModel[]> {
    constructor(private _service: ToeService,
                private global: Globals) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<SupportingValuerModel[]> {
        return this._service.getWorker(this.global.activeTOEId);
    }
}