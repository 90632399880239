import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AuditTrailsService} from '../_services';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AuditTrailActionTypes,
    AuditTrailsPageRequested,
    AuditTrailsPageLoaded,
    AuditTrailActionToggleLoading,
    AuditTrailsPageToggleLoading,
    AuditTrailOnServerCreated,
    AuditTrailCreated,
    AuditTrailUpdated,
    AuditTrailRestored,
    AuditTrailOnServerRestored,
    AuditTrailDeletedFromTrash,
    AuditTrailDeleted,
    AuditTrailDeletedFromAdminTrash, AuditTrailGet,
    AuditTrailAdminRestored, AuditTrailOnServerAdminRestored, AuditTrailTrashFlushed, AuditTrailOnServerUpdated
} from '../_actions/audit-trail.actions';
import {of} from 'rxjs';

@Injectable()
export class AuditTrailEffects {
    showPageLoadingDispatcher = new AuditTrailsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AuditTrailActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AuditTrailActionToggleLoading({isLoading: false});

    @Effect()
    loadAuditTrailsPage$ = this.actions$.pipe(
        ofType<AuditTrailsPageRequested>(AuditTrailActionTypes.AuditTrailsPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page, payload.assignmentId);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AuditTrailsPageLoaded({
                items: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailOnServerCreated>(AuditTrailActionTypes.AuditTrailOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item, payload.assignmentId, payload.participants, payload.fileList).pipe(
                    tap(res => {
                        this.store.dispatch(new AuditTrailCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailOnServerUpdated>(AuditTrailActionTypes.AuditTrailOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher)
                return this.service.update(payload.item, payload.assignmentId, payload.participants, payload.fileList).pipe(
                    tap(res => {
                        this.store.dispatch(new AuditTrailUpdated({item: {
                            id: payload.item.id,
                            changes: {...res}
                        }}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher
            })
        )


    @Effect()
    getAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailGet>(AuditTrailActionTypes.AuditTrailGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getById(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAuditTrailTrash$ = this.actions$
        .pipe(
            ofType<AuditTrailTrashFlushed>(AuditTrailActionTypes.AuditTrailTrashFlushed),
            mergeMap(({payload}) => {
                    return this.service.flushTrash(payload.assId);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailOnServerRestored>(AuditTrailActionTypes.AuditTrailOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AuditTrailRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailOnServerAdminRestored>(AuditTrailActionTypes.AuditTrailOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AuditTrailAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailDeleted>(AuditTrailActionTypes.AuditTrailDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id, payload.trashDeleteDesc, payload.userId);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailDeletedFromTrash>(AuditTrailActionTypes.AuditTrailDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAuditTrail$ = this.actions$
        .pipe(
            ofType<AuditTrailDeletedFromAdminTrash>(AuditTrailActionTypes.AuditTrailDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AuditTrailsService, private store: Store<AppState>) {
    }
}
