// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {FloorTypesService} from '../_services/floor-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allFloorTypesLoaded} from '../_selectors/floor-type.selectors';
// Actions
import {
    AllFloorTypesLoaded,
    AllFloorTypesRequested,
    FloorTypeActionTypes,
    FloorTypesPageRequested,
    FloorTypesPageLoaded,
    FloorTypeUpdated,
    FloorTypesPageToggleLoading,
    FloorTypeDeleted,
    FloorTypeOnServerCreated,
    FloorTypeCreated,
    FloorTypeAdminRestored,
    FloorTypeDeletedFromAdminTrash,
    FloorTypeDeletedFromTrash,
    FloorTypeOnServerAdminRestored,
    FloorTypeOnServerRestored,
    FloorTypeRestored,
    FloorTypeTrashFlushed,
    FloorTypesActionToggleLoading
} from '../_actions/floor-type.actions';

@Injectable()
export class FloorTypeEffects {
    showPageLoadingDispatcher = new FloorTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new FloorTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new FloorTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllFloorTypes$ = this.actions$
        .pipe(
            ofType<AllFloorTypesRequested>(FloorTypeActionTypes.AllFloorTypesRequested),
            withLatestFrom(this.store.pipe(select(allFloorTypesLoaded))),
            filter(([action, isAllFloorTypesLoaded]) => !isAllFloorTypesLoaded),
            mergeMap(() => this.service.getAllFloorTypes()),
            map(res => {
                return new AllFloorTypesLoaded({floorTypes: res.data});
            })
        );

    @Effect()
    loadFloorTypesPage$ = this.actions$
        .pipe(
            ofType<FloorTypesPageRequested>(FloorTypeActionTypes.FloorTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findFloorTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new FloorTypesPageLoaded({
                    floorTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeDeleted>(FloorTypeActionTypes.FloorTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFloorType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeUpdated>(FloorTypeActionTypes.FloorTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateFloorType(payload.floorType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeOnServerCreated>(FloorTypeActionTypes.FloorTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createFloorType(payload.floorType).pipe(
                    tap(res => {
                        this.store.dispatch(new FloorTypeCreated({floorType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushFloorTypeTrash$ = this.actions$
        .pipe(
            ofType<FloorTypeTrashFlushed>(FloorTypeActionTypes.FloorTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeOnServerRestored>(FloorTypeActionTypes.FloorTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FloorTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeOnServerAdminRestored>(FloorTypeActionTypes.FloorTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FloorTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeDeletedFromTrash>(FloorTypeActionTypes.FloorTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashFloorType$ = this.actions$
        .pipe(
            ofType<FloorTypeDeletedFromAdminTrash>(FloorTypeActionTypes.FloorTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: FloorTypesService, private store: Store<AppState>) {
    }
}
