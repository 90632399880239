import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { CriterionActions, CriterionActionTypes } from "../_actions/criterion.actions";
import { CriterionModel } from "../_models/criterion.model";

export interface CriterionsState extends EntityState<CriterionModel> {
    lastCreatedCriterionID: number,
    isLoading: boolean,
}

export const adapter: EntityAdapter<CriterionModel> = createEntityAdapter();

export const initialCriterionsState: CriterionsState = adapter.getInitialState({
    lastCreatedCriterionID: 0,
    isLoading: true,
});

export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();

export function criterionReducer(state = initialCriterionsState, action: CriterionActions): CriterionsState {
    switch (action.type) {
        case CriterionActionTypes.CriterionsLoading: {
            return {
                ...state, isLoading: true
            }
        }
        case CriterionActionTypes.CriterionsCreated: {
            const lastCriterion = action.payload.criterions[action.payload.criterions.length - 1];
            return adapter.setAll(action.payload.criterions, {
                ...state, 
                lastCreatedCriterionID: lastCriterion.id,
                isLoading: false,
            });
        }
        case CriterionActionTypes.ComparableAdded: {
            return adapter.setAll(action.payload.criterions, {
                ...state,
            });
        }
        case CriterionActionTypes.ComparableRemoved: {
            return adapter.setAll(action.payload.criterions, {
                ...state,
            });
        }
        case CriterionActionTypes.CriterionActivated: {
            return adapter.updateOne(action.payload.criterion, {
                ...state
            });
        }
        case CriterionActionTypes.CriterionDeactivated: {
            return adapter.updateOne(action.payload.criterion, {
                ...state
            });
        }
        case CriterionActionTypes.CriterionUpdated: {
            return adapter.updateOne(action.payload.criterion, {
                ...state
            });
        }
        case CriterionActionTypes.CriterionAdded: {
            return adapter.addOne(action.payload.criterion, {
                ...state, lastCreatedCriterionID: action.payload.criterion.id
            });
        }
        case CriterionActionTypes.DeleteCriterion: {
            return adapter.removeOne(action.payload.id, {
                ...state
            })
        }
        case CriterionActionTypes.CriterionResetted: {
            return adapter.updateOne(action.payload.criterion, {
                ...state
            })
        }
        case CriterionActionTypes.RestartCriterionState: {
            return initialCriterionsState
        }
        case CriterionActionTypes.CriterionsFromTemplateInserted: {
            return adapter.setAll(action.payload.criterions, {...state, lastCreatedCriterionID: action.payload.lastCreatedId});
        }
        case CriterionActionTypes.ComparableConsiderationChanged: {
            return adapter.updateOne(action.payload.criterion, {
                ...state
            });
        }
        default: 
            return state;
    }
}