import {IpmsStandard} from '../_models/ipms-standard.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {IpmsStandardsState} from '../_reducers/ipms-standard.reducers';
import * as fromIpmsStandard from '../_reducers/ipms-standard.reducers';
import {each} from 'lodash';

export const selectIpmsStandardsState = createFeatureSelector<IpmsStandardsState>('ipmsStandards');

export const selectIpmsStandardById = (ipmsStandardId: number) => createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => ipmsStandardsState.entities[ipmsStandardId]
);

export const selectAllIpmsStandards = createSelector(
    selectIpmsStandardsState,
    fromIpmsStandard.selectAll
);

export const selectAllIpmsStandardsIds = createSelector(
    selectIpmsStandardsState,
    fromIpmsStandard.selectIds
);

export const allIpmsStandardsLoaded = createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => ipmsStandardsState.isAllIpmsStandardsLoaded
);


export const selectIpmsStandardsPageLoading = createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => ipmsStandardsState.listLoading
);

export const selectIpmsStandardsActionLoading = createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => ipmsStandardsState.actionLoading
);

export const selectLastCreatedIpmsStandardId = createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => ipmsStandardsState.lastCreatedIpmsStandardId
);

export const selectIpmsStandardsShowInitWaitingMessage = createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => ipmsStandardsState.showInitWaitingMessage
);

export const selectTrashedIpmsStandardCount = createSelector(
    selectIpmsStandardsState,
    (ipmsStandardsState) => ipmsStandardsState.totalTrashed
);

export const selectAdminTrashedIpmsStandardCount = createSelector(
    selectIpmsStandardsState,
    (ipmsStandardsState) => ipmsStandardsState.totalAdminTrashed
);


export const selectIpmsStandardsQueryResult = createSelector(
    selectIpmsStandardsState,
    ipmsStandardsState => {
        const items: IpmsStandard[] = [];
        each(ipmsStandardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: IpmsStandard[] = httpExtension.sortArray(items, ipmsStandardsState.lastQuery.sortField, ipmsStandardsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, ipmsStandardsState.totalCount, '', ipmsStandardsState.totalTrashed);
    }
);
