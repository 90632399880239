// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {MeasurementMethodologiesService} from '../_services/measurement-methodology.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allMeasurementMethodologiesLoaded} from '../_selectors/measurement-methodology.selectors';
// Actions
import {
    AllMeasurementMethodologiesLoaded,
    AllMeasurementMethodologiesRequested,
    MeasurementMethodologyActionTypes,
    MeasurementMethodologiesPageRequested,
    MeasurementMethodologiesPageLoaded,
    MeasurementMethodologyUpdated,
    MeasurementMethodologiesPageToggleLoading,
    MeasurementMethodologyDeleted,
    MeasurementMethodologyOnServerCreated,
    MeasurementMethodologyCreated,
    MeasurementMethodologyAdminRestored,
    MeasurementMethodologyDeletedFromAdminTrash,
    MeasurementMethodologyDeletedFromTrash,
    MeasurementMethodologyOnServerAdminRestored,
    MeasurementMethodologyOnServerRestored,
    MeasurementMethodologyRestored,
    MeasurementMethodologyTrashFlushed,
    MeasurementMethodologiesActionToggleLoading
} from '../_actions/measurement-methodology.actions';

@Injectable()
export class MeasurementMethodologyEffects {
    showPageLoadingDispatcher = new MeasurementMethodologiesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new MeasurementMethodologiesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new MeasurementMethodologiesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllMeasurementMethodologies$ = this.actions$
        .pipe(
            ofType<AllMeasurementMethodologiesRequested>(MeasurementMethodologyActionTypes.AllMeasurementMethodologiesRequested),
            withLatestFrom(this.store.pipe(select(allMeasurementMethodologiesLoaded))),
            filter(([action, isAllMeasurementMethodologiesLoaded]) => !isAllMeasurementMethodologiesLoaded),
            mergeMap(() => this.service.getAllMeasurementMethodologies()),
            map(res => {
                return new AllMeasurementMethodologiesLoaded({measurementMethodologies: res.data});
            })
        );

    @Effect()
    loadMeasurementMethodologiesPage$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologiesPageRequested>(MeasurementMethodologyActionTypes.MeasurementMethodologiesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findMeasurementMethodologies(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new MeasurementMethodologiesPageLoaded({
                    measurementMethodologies: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyDeleted>(MeasurementMethodologyActionTypes.MeasurementMethodologyDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteMeasurementMethodology(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyUpdated>(MeasurementMethodologyActionTypes.MeasurementMethodologyUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateMeasurementMethodology(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyOnServerCreated>(MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createMeasurementMethodology(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new MeasurementMethodologyCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushMeasurementMethodologyTrash$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyTrashFlushed>(MeasurementMethodologyActionTypes.MeasurementMethodologyTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyOnServerRestored>(MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new MeasurementMethodologyRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyOnServerAdminRestored>(MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new MeasurementMethodologyAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyDeletedFromTrash>(MeasurementMethodologyActionTypes.MeasurementMethodologyDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashMeasurementMethodology$ = this.actions$
        .pipe(
            ofType<MeasurementMethodologyDeletedFromAdminTrash>(MeasurementMethodologyActionTypes.MeasurementMethodologyDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: MeasurementMethodologiesService, private store: Store<AppState>) {
    }
}
