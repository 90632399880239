import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash'
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { awConst } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
import * as rs from '../../target-property/services/condition-rating-v2.service';
import { ConditionRatingEditComponent } from '../condition-rating-table/condition-rating-edit/condition-rating-edit.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-condition-rating-table-v2',
  templateUrl: './condition-rating-table-v2.component.html',
  styleUrls: ['./condition-rating-table-v2.component.scss']
})
export class ConditionRatingTableV2Component implements OnInit, OnDestroy {
  @Input() parent: 'inspection' | 'background' | 'multimedia' = 'inspection';
  @Input() componentDataSource$: Observable<rs.ComponentDataItem[]>;
  @Input() editable: boolean = false;
  @Input() conditionRatingDataSource$: Observable<rs.ConditionRatingDataItem[]>
  @Input() schemeId$: Observable<number>;
  @Output() conditionRatingChanged = new EventEmitter<rs.ConditionRatingDataItem[]>();
  @Input() useMatDialog: boolean = false;

  _componentDataSource: rs.ComponentDataItem[] = [];
  _conditionRatingDataSource: rs.ConditionRatingDataItem[] = [];
  ratings = ['N/A', 'N/I', 'Rate 1', 'Rate 2', 'Rate 3'];
  basePath = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=`;
  awConst = awConst;
  schemeId = null;
  className = null;

  // Background and Multimedia
  showDetail = false;
  selectedConditionaRating: any;
  selectedComponent: any;
  private _ratingPicsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public ratingPics$: Observable<any[]> = this._ratingPicsSubject.asObservable();

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private dialog: MatDialog,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    switch (this.parent) {
      case 'inspection':
        this.className = 'inspection-condition-rating-table';
        break;
      default:
        this.className = 'background-condition-rating-table';
        break;
    }
    combineLatest([
      this.componentDataSource$,
      this.conditionRatingDataSource$,
      this.schemeId$
    ])
      .pipe(
        takeUntil(this._onDestroy$),
        filter(([components, ratings, _schemeId]) => {
          const component = components.find(c => {
            return !rs.ratingContainsKey(ratings, c.key);
          });
          return component == undefined
        })
      )
      .subscribe(([components, ratings, schemeId]: [rs.ComponentDataItem[], rs.ConditionRatingDataItem[], number]) => {
        this._componentDataSource = components;
        this._conditionRatingDataSource = ratings.sort((a, b) => Number(a.floor) - Number(b.floor));
        this.schemeId = schemeId;
      })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSelectionChange(event: MatSelectChange, component_key: string, floor: number)  {
    this._updateList(floor, component_key, (r: rs.Rating): rs.Rating => {
      r.value = event.value
      return r;
    })
  }

  editRating(item: rs.Rating, component: rs.ComponentDataItem, floor: number) {
    const dialogRef = this.dialog.open(ConditionRatingEditComponent, {
      data: {
        rate: {
          rating: item.value,
          description: item.description,
          code: component.title,
          name: component.name
        },
        floor: floor
      }
    });
    dialogRef.afterClosed().pipe().subscribe(res => {
      if (!res) {
        return;
      }
      this._updateList(floor, component.key, (r: rs.Rating): rs.Rating => {
        r.value = res.rating;
        r.description = res.description
        return r;
      });
    })
  }

  togglePicturePopover(popover: NgbPopover, rating: rs.Rating, floor: number, component_title: string) {
    if (popover.isOpen()) {
      popover.close() 
    } else {
      popover.open({rating, floor, component_title})
    }
  }

  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  private _updateList(
    floor: number, 
    component_key: string, 
    updateFn: (rating: rs.Rating) => rs.Rating
  ) {
    const conditionRatings = _.cloneDeep(this._conditionRatingDataSource);
    const row = conditionRatings.find(cr => cr.floor == floor);
    if (!row) {
      return;
    }
    let rating = row.ratings[component_key];
    if (!rating) {
      return;
    }
    rating = updateFn(rating)
    if (rating.value == 'N/A' || rating.value == 'N/I') {
      rating.pictures = []
    }
    this.conditionRatingChanged.emit(conditionRatings);
  }


  // Background and Multimedia
  public onSelect(item, component) {
    this.showDetail = true;
    this.selectedConditionaRating = item;
    this.selectedComponent = component;
    this._ratingPicsSubject.next(item.pictures);
  }

  public getRatingDesc(value) {
    if (value == 'N/A') {
      return 'Rate N/A - Not Applicable'
    }
    if (value == 'N/I') {
      return 'Rate N/I - Not Inspected'
    }
    if (value == '1' || value == 'Rate 1') {
      return 'Rate 1 - No repair is currently needed. The property must be maintained in the normal way'
    }
    if (value == '2' || value == 'Rate 2') {
      return 'Rate 2 - Defects that need repairing or replacing but are not considered to be either serious or urgent. The property must be maintained in the normal way'
    }
    if (value == '3' || value == 'Rate 3') {
      return 'Rate 3 - Defects that are serious and/or need to be repaired, replaced or investigated urgently'
    }
  }
}
