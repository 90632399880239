// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ResidentialTypesService} from '../_services/residential-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allResidentialTypesLoaded} from '../_selectors/residential-type.selectors';
// Actions
import {
    AllResidentialTypesLoaded,
    AllResidentialTypesRequested,
    ResidentialTypeActionTypes,
    ResidentialTypesPageRequested,
    ResidentialTypesPageLoaded,
    ResidentialTypeUpdated,
    ResidentialTypesPageToggleLoading,
    ResidentialTypeDeleted,
    ResidentialTypeOnServerCreated,
    ResidentialTypeCreated,
    ResidentialTypeAdminRestored,
    ResidentialTypeDeletedFromAdminTrash,
    ResidentialTypeDeletedFromTrash,
    ResidentialTypeOnServerAdminRestored,
    ResidentialTypeOnServerRestored,
    ResidentialTypeRestored,
    ResidentialTypeTrashFlushed,
    ResidentialTypesActionToggleLoading
} from '../_actions/residential-type.actions';

@Injectable()
export class ResidentialTypeEffects {
    showPageLoadingDispatcher = new ResidentialTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ResidentialTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ResidentialTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllResidentialTypes$ = this.actions$
        .pipe(
            ofType<AllResidentialTypesRequested>(ResidentialTypeActionTypes.AllResidentialTypesRequested),
            withLatestFrom(this.store.pipe(select(allResidentialTypesLoaded))),
            filter(([action, isAllResidentialTypesLoaded]) => !isAllResidentialTypesLoaded),
            mergeMap(() => this.service.getAllResidentialTypes()),
            map(res => {
                return new AllResidentialTypesLoaded({residentialTypes: res.data});
            })
        );

    @Effect()
    loadResidentialTypesPage$ = this.actions$
        .pipe(
            ofType<ResidentialTypesPageRequested>(ResidentialTypeActionTypes.ResidentialTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findResidentialTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ResidentialTypesPageLoaded({
                    residentialTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeDeleted>(ResidentialTypeActionTypes.ResidentialTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteResidentialType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeUpdated>(ResidentialTypeActionTypes.ResidentialTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateResidentialType(payload.residentialType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeOnServerCreated>(ResidentialTypeActionTypes.ResidentialTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createResidentialType(payload.residentialType).pipe(
                    tap(res => {
                        this.store.dispatch(new ResidentialTypeCreated({residentialType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushResidentialTypeTrash$ = this.actions$
        .pipe(
            ofType<ResidentialTypeTrashFlushed>(ResidentialTypeActionTypes.ResidentialTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeOnServerRestored>(ResidentialTypeActionTypes.ResidentialTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ResidentialTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeOnServerAdminRestored>(ResidentialTypeActionTypes.ResidentialTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ResidentialTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeDeletedFromTrash>(ResidentialTypeActionTypes.ResidentialTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashResidentialType$ = this.actions$
        .pipe(
            ofType<ResidentialTypeDeletedFromAdminTrash>(ResidentialTypeActionTypes.ResidentialTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: ResidentialTypesService, private store: Store<AppState>) {
    }
}
