// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ApproachesToValuesService} from '../_services/approaches-to-value.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allApproachesToValuesLoaded} from '../_selectors/approaches-to-value.selectors';
// Actions
import {
    AllApproachesToValuesLoaded,
    AllApproachesToValuesRequested,
    ApproachesToValueActionTypes,
    ApproachesToValuesPageRequested,
    ApproachesToValuesPageLoaded,
    ApproachesToValueUpdated,
    ApproachesToValuesPageToggleLoading,
    ApproachesToValueDeleted,
    ApproachesToValueOnServerCreated,
    ApproachesToValueCreated,
    ApproachesToValueAdminRestored,
    ApproachesToValueDeletedFromAdminTrash,
    ApproachesToValueDeletedFromTrash,
    ApproachesToValueOnServerAdminRestored,
    ApproachesToValueOnServerRestored,
    ApproachesToValueRestored,
    ApproachesToValueTrashFlushed,
    ApproachesToValuesActionToggleLoading
} from '../_actions/approaches-to-value.actions';

@Injectable()
export class ApproachesToValueEffects {
    showPageLoadingDispatcher = new ApproachesToValuesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ApproachesToValuesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ApproachesToValuesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllApproachesToValues$ = this.actions$
        .pipe(
            ofType<AllApproachesToValuesRequested>(ApproachesToValueActionTypes.AllApproachesToValuesRequested),
            withLatestFrom(this.store.pipe(select(allApproachesToValuesLoaded))),
            filter(([action, isAllApproachesToValuesLoaded]) => !isAllApproachesToValuesLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllApproachesToValuesLoaded({approachesToValues: res.data});
            })
        );

    @Effect()
    loadApproachesToValuesPage$ = this.actions$
        .pipe(
            ofType<ApproachesToValuesPageRequested>(ApproachesToValueActionTypes.ApproachesToValuesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ApproachesToValuesPageLoaded({
                    approachesToValues: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueUpdated>(ApproachesToValueActionTypes.ApproachesToValueUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.approachesToValue);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueOnServerCreated>(ApproachesToValueActionTypes.ApproachesToValueOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.approachesToValue).pipe(
                    tap(res => {
                        this.store.dispatch(new ApproachesToValueCreated({approachesToValue: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushApproachesToValueTrash$ = this.actions$
        .pipe(
            ofType<ApproachesToValueTrashFlushed>(ApproachesToValueActionTypes.ApproachesToValueTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueOnServerRestored>(ApproachesToValueActionTypes.ApproachesToValueOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ApproachesToValueRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueOnServerAdminRestored>(ApproachesToValueActionTypes.ApproachesToValueOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ApproachesToValueAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueDeleted>(ApproachesToValueActionTypes.ApproachesToValueDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueDeletedFromTrash>(ApproachesToValueActionTypes.ApproachesToValueDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashApproachesToValue$ = this.actions$
        .pipe(
            ofType<ApproachesToValueDeletedFromAdminTrash>(ApproachesToValueActionTypes.ApproachesToValueDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ApproachesToValuesService, private store: Store<AppState>) {
    }
}
