import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import { GeneralSettingsService } from "./general-settings.service";
import { map, switchMap, tap } from "rxjs/operators";
import { EMPTY, defer, of } from "rxjs";
import { Location } from "@angular/common";

export type GeneralSettings = {
    location: {
        latitude: number,
        longitude: number
    }
}

export enum GeneralSettingsActionTypes {
    Get = '[General Settings Effects] Get Settings',
    Loaded = '[General Settings Effects] General Settings Loaded',
    Update = '[General Settings] Update',
    Updated = '[General Settings Effects] Updated',
    UpdateTemp = '[General Settings] Update temp location'
}

export class GetGeneralSettings implements Action {
    readonly type = GeneralSettingsActionTypes.Get
    constructor() {}
}

export class GeneralSettingsLoaded implements Action {
    readonly type = GeneralSettingsActionTypes.Loaded
    constructor(public payload: {item: GeneralSettings}) {}
}

export class UpdateGeneralSettings implements Action {
    readonly type = GeneralSettingsActionTypes.Update
    constructor(public payload: {item: GeneralSettings}) {}
}

export class GeneralSettingsUpdated implements Action {
    readonly type = GeneralSettingsActionTypes.Updated
    constructor(public payload: {item: GeneralSettings}) {}
}

export class UpdateTempLocation implements Action {
    readonly type = GeneralSettingsActionTypes.UpdateTemp
    constructor(public payload: {latitude: number, longitude: number}) {}
}

export type GeneralSettingsActions = UpdateGeneralSettings
    | GeneralSettingsUpdated
    | UpdateTempLocation
    | GetGeneralSettings
    | GeneralSettingsLoaded

export interface GeneralSettingsState {
    settings: GeneralSettings,
    tempLocation: {
        latitude: number,
        longitude: number
    } | null
}

const initialState: GeneralSettingsState = {
    settings: {
        location: {
            latitude: 47.9229,
            longitude: 106.89100
        }
    },
    tempLocation: null
}

export function generalSettingsReducer(
    state = initialState,
    action: GeneralSettingsActions
): GeneralSettingsState {
    switch (action.type) {
        case GeneralSettingsActionTypes.Updated: {
            return {
                ...state,
                settings: action.payload.item
            }
        }
        case GeneralSettingsActionTypes.UpdateTemp: {
            return {
                ...state,
                tempLocation: action.payload
            }
        }
        case GeneralSettingsActionTypes.Loaded: {
            return {
                ...state,
                settings: action.payload.item    
            }
        }
        default: {
            return state
        }
    }
}

@Injectable()
export class GeneralSettingsEffects {
    constructor(private service: GeneralSettingsService, private actions$: Actions, private location: Location) {}
    @Effect()
    updateGeneralSettings$ = this.actions$ 
        .pipe(
            ofType<UpdateGeneralSettings>(GeneralSettingsActionTypes.Update),
            switchMap(({payload}) => {
                return this.service.updateSettings(payload.item)
            }),
            map((response) => 
                new GeneralSettingsUpdated({item: response})
            )
        )

    @Effect()
    getGeneralSettings$ = this.actions$
        .pipe(
            ofType<GetGeneralSettings>(GeneralSettingsActionTypes.Get),
            switchMap(() => {
                return this.service.getSettings()
            }),
            map((response) => new GeneralSettingsLoaded({item: response}))
        )

    @Effect()
    init$ = defer(() => {
        return of(new GetGeneralSettings())
    })

    @Effect({dispatch: false})
    generalSettingsUpdated = this.actions$
        .pipe(
            ofType<GeneralSettingsUpdated>(GeneralSettingsActionTypes.Updated),
            tap((_) => {
                this.location.back()
            })
        )
}

export const selectGeneralSettingsState = createFeatureSelector<GeneralSettingsState>('generalSettings');
export const selectSystemMapLocation = createSelector(selectGeneralSettingsState, state => state.settings.location)
export const selectTempLocation = createSelector(selectGeneralSettingsState, state => {
    if (state.tempLocation !== null) {
        return state.tempLocation
    }
    return state.settings.location
})