// NGRX
import {Action} from '@ngrx/store';
// Models
import {AssetClassDetailModel} from '../';
import {FloorRateModel} from '../_models/floor-rate.model';
import {AssetClassSizeModel, ResidentialExternalAreaModel} from '../../comparable';
import {AuditTrailParticipantModel} from '../../assignment';

export enum AssetClassDetailActionTypes {
    AssetClassDetailOnServerCreated = '[Edit AssetClassDetail Dialog] AssetClassDetail On Server Created',
    AssetClassDetailCreated = '[Edit AssetClassDetail Dialog] AssetClassDetail Created',

    AssetClassDetailOnServerUpdated = '[Edit AssetClassDetail Dialog] AssetClassDetail On Server Updated',
    AssetClassDetailUpdated = '[Edit AssetClassDetail Dialog] AssetClassDetail Updated',

    AssetClassDetailRequest = '[AssetClassDetail Object] AssetClassDetail Object Requested',
    AssetClassDetailLoaded = '[AssetClassDetail Object] AssetClassDetail Object Loaded',


    AssetClassDetailPageCancelled = '[AssetClassDetail API] AssetClassDetail Page Cancelled',
    AssetClassActionToggleLoading = '[AssetClassDetail] AssetClassDetail Action Toggle Loading'
}

export class AssetClassDetailOnServerCreated implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailOnServerCreated;

    constructor(public payload: {
        assetClass: AssetClassDetailModel,
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        selectedFacilitiesOffice: any[],
        selectedFacilitiesOfficeExclusive: any[],
        externalAreas: ResidentialExternalAreaModel[],
        ratings: FloorRateModel[],
        participants: AuditTrailParticipantModel[],
        userId: number,
        sizeModuleData: any
    }) {
    }
}

export class AssetClassDetailOnServerUpdated implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailOnServerUpdated;

    constructor(public payload: {
        assetClass: AssetClassDetailModel,
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        selectedFacilitiesOffice: any[],
        selectedFacilitiesOfficeExclusive: any[],
        externalAreas: ResidentialExternalAreaModel[],
        ratings: FloorRateModel[],
        participants: AuditTrailParticipantModel[],
        userId: number,
        sizeModuleData: any,
    }) {
    }
}


export class AssetClassDetailCreated implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailCreated;

    constructor(public payload: { assetClass: AssetClassDetailModel }) {
    }
}

export class AssetClassDetailUpdated implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailUpdated;

    constructor(public payload: { assetClass: AssetClassDetailModel }) {
    }
}

export class AssetClassDetailRequest implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailRequest;

    constructor(public payload: { assetClassId: number }) {
    }
}


export class AssetClassDetailLoaded implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailLoaded;

    constructor(public payload: {
        assetClass: AssetClassDetailModel
    }) {
    }
}

export class AssetClassDetailPageCancelled implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassDetailPageCancelled;
}


export class AssetClassDetailActionToggleLoading implements Action {
    readonly type = AssetClassDetailActionTypes.AssetClassActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export type AssetClassDetailActions = AssetClassDetailOnServerCreated
    | AssetClassDetailOnServerUpdated
    | AssetClassDetailCreated
    | AssetClassDetailUpdated
    | AssetClassDetailActionToggleLoading
    | AssetClassDetailLoaded
    | AssetClassDetailPageCancelled
    | AssetClassDetailRequest;
