<div class="card h-100">
    <div class="card-header info-header">
        <span>Valuation Specifics</span>
    </div>
    <div class="card-body info-body">
        <table *ngIf="data$|async as vs" class="table">
            <tbody>
                <tr><th>Client :</th><td>{{vs.client}}</td></tr>
                <tr><th>Purpose of Valuation :</th><td>{{vs.purposeOfValuation}}</td></tr>
                <tr><th>Basis of Value :</th><td>{{vs.basisOfValue}}</td></tr>
                <tr><th>Approach :</th><td>{{vs.approach}}</td></tr>
                <tr><th>Method :</th><td>{{vs.method}}</td></tr>
                <tr><th>Premise of Value :</th><td>{{vs.premiseOfValue}}</td></tr>
                <tr><th>Tenure :</th><td>{{vs.tenure}}</td></tr>
            </tbody>
        </table>
    </div>
</div>