import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BuildingModel } from 'src/app/core/comparable';
import { AcBuildingInfo } from 'src/app/core/comparable/_models/ac-building-info.model';
import { AllBuildingTypesRequested, AllFoundationTypesRequested, AllGradesRequested, BuildingType, FoundationType, Grade, selectAllBuildingTypes, selectAllFoundationTypes, selectAllGrades } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { BuildingInfoDialogComponent } from '../../comparable/_sub/building-info-dialog/building-info-dialog.component';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { environment } from 'src/environments/environment';
import { MapsService } from 'src/app/core/map/maps.service';

export interface BuildingSectionData {
  building_name: string,
  completion_year: string,
  is_part_of_property: boolean,
  info_about_property: string,
  building_type: number,
  building_type_comment: string,
  building_grade_id: number,
  energy_efficiency_grade_id: number,
  developer: string,
  anchor_tenant: string,
  foundation_type_id: number,
  building_description: string
}

@Component({
  selector: 'kt-building-section',
  templateUrl: './building-section.component.html',
  styleUrls: ['./building-section.component.scss']
})
export class BuildingSectionComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean = false;
  @ViewChild('buildingPic', {static: false})
  public uploadFileComponent: UploadFileComponent;
  @Input() parent: 'Building' | 'Comparable' | 'Inspection'
  @Input() data: BuildingSectionData;
  @Input() centerLat: number;
  @Input() centerLng: number;
  @Input() buildingId: number = null;
  @Input() buildingPictures: any[] = [];
  @Input() buildingPicturePath: string = null;
  @Input() hasFormErrors: boolean = false;
  @Input() showPicSection: boolean = true;
  @Input() building$: Observable<any>;
  @Output() centerChange: EventEmitter<any> = new EventEmitter();
  @Output() picChange: EventEmitter<{pictures: any[], picture: any}> = new EventEmitter();
  @Output() buildingSelected: EventEmitter<any> = new EventEmitter();
  @Output() buildingCleared: EventEmitter<void> = new EventEmitter();
  @Input() lowLevelTitle: string = null;
  formGroup: UntypedFormGroup;
  formValueChanged: boolean = false;

  pictures = [];
  picture = null;
  title$: Observable<string>;
  buildingTypes: BuildingType[] = [];
  grades: Grade[] = [];
  foundationTypes: FoundationType[] = [];
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  errorFields = [];

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private mapService: MapsService
  ) { }

  ngOnInit(): void {
    this.pictures = this.buildingPictures;
    this.picture = this.picture;
    this.title$ = of(this.parent == 'Building' ? 'About the building' : 'The building')

    // Fetch data
    this.store$.dispatch(new AllFoundationTypesRequested())
    this.store$.select(selectAllFoundationTypes).pipe(takeUntil(this._onDestroy$))
    .subscribe(res => {
        this.foundationTypes = res ? res : []
    });
    this.store$.dispatch(new AllBuildingTypesRequested());
    this.store$.select(selectAllBuildingTypes).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
        this.buildingTypes = res ? res : [];
    });
    this.store$.dispatch(new AllGradesRequested());
    this.store$.pipe(select(selectAllGrades), takeUntil(this._onDestroy$))
    .subscribe(res => {
        this.grades = [];
        if (res) {
            this.grades = res;
          }
      });
    this.store$.dispatch(new AllFoundationTypesRequested());
    this.store$.pipe(select(selectAllFoundationTypes), takeUntil(this._onDestroy$)).subscribe(res => {
      this.foundationTypes = [];
      if (res) {
        this.foundationTypes = res;
      }
    })


    this.formGroup = this.formBuilder.group({
      building_name: [this.data.building_name],
      completion_year: [this.data.completion_year, Validators.required],
      is_part_of_property: [this.data.is_part_of_property],
      info_about_property: [this.data.info_about_property],
      building_type: [this.data.building_type, Validators.required],
      building_type_comment: [this.data.building_type_comment],
      building_grade_id: [this.data.building_grade_id, Validators.required],
      energy_efficiency_grade_id: [this.data.energy_efficiency_grade_id],
      developer: [this.data.developer],
      anchor_tenant: [this.data.anchor_tenant],
      foundation_type_id: [this.data.foundation_type_id],
      building_description: [this.data.building_description],
    })

    combineLatest([this.formGroup.valueChanges]).pipe(takeUntil(this._onDestroy$)).subscribe(
      (_valueChange) => {
        this.formValueChanged = this.formGroup.valid;
      }
    )

    if (this.building$) {
      this.building$.pipe(takeUntil(this._onDestroy$)).subscribe(res => {
        if (res == null) {
          return;
        }
        const controls = this.formGroup.controls;
        controls.building_name.setValue(res.building_name);
        controls.completion_year.setValue(res.completion_year);
        controls.is_part_of_property.setValue(res.is_part_of_property);
        controls.info_about_property.setValue(res.info_about_property);
        controls.building_type.setValue(res.building_type);
        controls.building_type_comment.setValue(res.building_type_comment);
        controls.building_grade_id.setValue(res.building_grade_id);
        controls.energy_efficiency_grade_id.setValue(res.energy_efficiency_grade_id);
        controls.developer.setValue(res.developer);
        controls.anchor_tenant.setValue(res.anchor_tenant);
        controls.foundation_type_id.setValue(res.foundation_type_id);
        controls.building_description.setValue(res.building_description);
        this.formGroup.updateValueAndValidity();
        this.buildingId = res.id;
        this.centerLat = res.locationData.latitude;
        this.centerLng = res.locationData.longitude;
        this.centerChange.emit(res.locationData);
        this.pictures = res.pictures.map(p => ({...p, pic_type: 2}));
        this.picture = res.picture;
        this.picChange.emit({pictures: this.pictures, picture: this.picture});
        this.buildingSelected.emit(res);
      })
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public getData() {
    const controls = this.formGroup.controls;
    return {
      building_name: controls.building_name.value,
      completion_year: controls.completion_year.value,
      is_part_of_property: controls.is_part_of_property.value,
      info_about_property: controls.info_about_property.value,
      building_type: controls.building_type.value,
      building_type_comment: controls.building_type_comment.value,
      building_grade_id: controls.building_grade_id.value,
      energy_efficiency_grade_id: controls.energy_efficiency_grade_id.value,
      developer: controls.developer.value,
      anchor_tenant: controls.anchor_tenant.value,
      foundation_type_id: controls.foundation_type_id.value,
      building_description: controls.building_description.value
    }
  }

  public getDataForComparable(): AcBuildingInfo {
    const controls = this.formGroup.controls;
    const bi = new AcBuildingInfo();
    bi.building_id = this.buildingId;
    bi.building_name = controls.building_name.value;
    bi.completion_year = controls.completion_year.value;
    bi.is_part_of_property = controls.is_part_of_property.value;
    bi.info_about_property = controls.info_about_property.value;
    bi.building_type = controls.building_type.value;
    bi.building_type_comment = controls.building_type_comment.value;
    bi.building_grade_id = controls.building_grade_id.value;
    bi.energy_efficiency_grade_id = controls.energy_efficiency_grade_id.value;
    bi.developer = controls.developer.value;
    bi.anchor_tenant = controls.anchor_tenant.value;
    bi.foundation_type_id = controls.foundation_type_id.value;
    bi.building_description = controls.building_description.value;
    if (this.uploadFileComponent) {
      this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
          if (value) {
              bi.picture = value.success;
          }
      });
      bi.pictures = this.uploadFileComponent.uploadFiles;
    }
    return bi;
  }

  public showExist() {
    const dialogRef = this.dialog.open(BuildingInfoDialogComponent, {
        data: {
            standAloneBuilding: false,
            currentBuildingId: this.buildingId ? this.buildingId : -1,
            lat: this.centerLat,
            lng: this.centerLng,
        }
    });

    dialogRef.afterClosed().subscribe((res: BuildingModel) => {
        if (!res) {
            return;
        }
        const controls = this.formGroup.controls;
        controls.building_name.setValue(res.building_name);
        controls.completion_year.setValue(res.completion_year);
        controls.is_part_of_property.setValue(res.is_part_of_property);
        controls.info_about_property.setValue(res.info_about_property);
        controls.building_type.setValue(res.building_type);
        controls.building_type_comment.setValue(res.building_type_comment);
        controls.building_grade_id.setValue(res.building_grade_id);
        controls.energy_efficiency_grade_id.setValue(res.energy_efficiency_grade_id);
        controls.developer.setValue(res.developer);
        controls.anchor_tenant.setValue(res.anchor_tenant);
        controls.foundation_type_id.setValue(res.foundation_type_id);
        controls.building_description.setValue(res.building_description);
        this.formGroup.updateValueAndValidity();
        this.buildingId = res.id;
        this.centerLat = res.locationData.latitude;
        this.centerLng = res.locationData.longitude;
        this.centerChange.emit(res.locationData);
        this.pictures = res.pictures.map(p => ({...p, pic_type: 2}));
        this.picture = res.picture;
        this.picChange.emit({pictures: this.pictures, picture: this.picture});
        this.buildingSelected.emit(res);
    });
  }

  public clearBuilding() {
    this.buildingId = null;
    const controls = this.formGroup.controls;
    controls.building_name.setValue(null);
    controls.completion_year.setValue(null);
    controls.is_part_of_property.setValue(false);
    controls.info_about_property.setValue(null);
    controls.building_type.setValue(null);
    controls.building_type_comment.setValue(null);
    controls.building_grade_id.setValue(null);
    controls.energy_efficiency_grade_id.setValue(null);
    controls.developer.setValue(null);
    controls.anchor_tenant.setValue(null);
    controls.foundation_type_id.setValue(null);
    controls.building_description.setValue(null);
    this.formGroup.updateValueAndValidity();
    this.pictures = [];
    this.picture = '';
    this.picChange.emit({pictures: this.pictures, picture: this.picture});

    this.centerChange.emit({
      country_id: null,
      city_id: null,
      zip_code: null,
      longitude: null,
      latitude: null,
      location_grade_id: null,
      address: null,
      location_surrounding: null,
      city_of_location: null
    });
    this.buildingCleared.emit();
  }

  public addBuilding() {

  }

}
