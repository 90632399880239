import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassRetailBuildingActions, AssetClassRetailBuildingActionTypes } from "../_actions/asset-class-retail-building.actions";
import { AssetClassRetailBuildingModel } from "../_models/asset-class-retail-building.model";

export interface AssetClassRetailBuildingState extends EntityState<AssetClassRetailBuildingModel> {
    actionLoading: boolean;
    listLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    previouslyCreatedACId: number;
    lastCreatedAssetClassRetailBuildingId: number;
    showInitWaitingMessage: boolean;
}
export const adapter: EntityAdapter<AssetClassRetailBuildingModel> = createEntityAdapter<AssetClassRetailBuildingModel>();
export const initialAssetClassRetailBuildingState: AssetClassRetailBuildingState = adapter.getInitialState({
    actionLoading: false,
    listLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    previouslyCreatedACId: undefined,
    lastCreatedAssetClassRetailBuildingId: undefined,
    showInitWaitingMessage: true
});

export function assetClassRetailBuildingReducer(
    state = initialAssetClassRetailBuildingState,
    action: AssetClassRetailBuildingActions
): AssetClassRetailBuildingState {
    switch (action.type) {
        case AssetClassRetailBuildingActionTypes.UpdatePreviouslyCreatedACId: {
            return {
                ...state,
                previouslyCreatedACId: state.lastCreatedAssetClassRetailBuildingId
            }
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassRetailBuildingId: undefined
            }
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            }
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerCreated: {
            return state;
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingCreated: {
            return adapter.addOne(
                action.payload.AssetClassRetailBuilding,
                {...state, previouslyCreatedACId: state.lastCreatedAssetClassRetailBuildingId, lastCreatedAssetClassRetailBuildingId: action.payload.AssetClassRetailBuilding.id}
            )
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingUpdated: {
            return adapter.addOne(
                action.payload.AssetClassRetailBuilding,
                {...state, lastCreatedAssetClassRetailBuildingId: action.payload.AssetClassRetailBuilding.id}
            )
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingPageLoaded: {
            return adapter.addMany(action.payload.AssetClassRetailBuildings, {
                ...initialAssetClassRetailBuildingState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            })
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeleted: {
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            })
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeletedFromTrash: {
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            }
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingDeletedFromAdminTrash:
            return {...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerRestored: {
            return state
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                lastCreatedAssetClassRetailBuildingId: action.payload.ac.id,
                totalTrashed: state.totalTrashed - 1
            })
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingOnServerAdminRestored: {
            return state
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingAdminRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            })
        }
        case AssetClassRetailBuildingActionTypes.AssetClassRetailBuildingTrashFlushed: {
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            }
        }
        default: {
            return state;
        }
    }
}