// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {RoofStructureActions, RoofStructureActionTypes} from '../_actions/roof-structure.actions';
// Models
import {RoofStructure} from '../_models/roof-structure.model';
import {QueryParamsModel} from '../../_base/crud';

export interface RoofStructuresState extends EntityState<RoofStructure> {
    isAllRoofStructuresLoaded: boolean;
    queryRowsCount: number;
    queryResult: RoofStructure[];
    lastCreatedRoofStructureId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RoofStructure> = createEntityAdapter<RoofStructure>();

export const initialRoofStructuresState: RoofStructuresState = adapter.getInitialState({
    isAllRoofStructuresLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedRoofStructureId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function roofStructuresReducer(state = initialRoofStructuresState, action: RoofStructureActions): RoofStructuresState {
    switch (action.type) {
        case RoofStructureActionTypes.RoofStructuresPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedRoofStructureId: undefined
            };
        case RoofStructureActionTypes.RoofStructuresActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case RoofStructureActionTypes.RoofStructureOnServerCreated:
            return {
                ...state
            };
        case RoofStructureActionTypes.RoofStructureCreated:
            return adapter.addOne(action.payload.roofStructure, {
                ...state, lastCreatedRoofStructureId: action.payload.roofStructure.id
            });
        case RoofStructureActionTypes.RoofStructureUpdated:
            return adapter.updateOne(action.payload.partialRoofStructure, state);
        case RoofStructureActionTypes.AllRoofStructuresLoaded:
            return adapter.addAll(action.payload.roofStructures, {
                ...state, isAllRoofStructuresLoaded: true
            });
        case RoofStructureActionTypes.RoofStructuresPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case RoofStructureActionTypes.RoofStructuresPageLoaded:
            return adapter.addMany(action.payload.roofStructures, {
                ...initialRoofStructuresState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case RoofStructureActionTypes.RoofStructureDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case RoofStructureActionTypes.RoofStructureRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case RoofStructureActionTypes.RoofStructureDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case RoofStructureActionTypes.RoofStructureAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case RoofStructureActionTypes.RoofStructureDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case RoofStructureActionTypes.RoofStructureTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
