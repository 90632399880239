// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {Departure} from '../_models/departure.model';

export enum DepartureActionTypes {

    AllDeparturesRequested = '[Departures Home Page] All Departures Requested',
    AllDeparturesLoaded = '[Departures API] All Departures Loaded',

    DepartureOnServerCreated = '[Edit Departure Dialog] Departure On Server Created',
    DepartureCreated = '[Edit Departures Dialog] Departures Created',
    DepartureUpdated = '[Edit Departure Dialog] Departure Updated',
    DepartureDeleted = '[Departures List Page] Departure Deleted',

    DeparturesPageRequested = '[Departures List Page] Departures Page Requested',
    DeparturesPageLoaded = '[Departures API] Departures Page Loaded',
    DeparturesPageCancelled = '[Departures API] Departures Page Cancelled',

    DeparturesPageToggleLoading = '[Departures page] Departures Page Toggle Loading',
    DeparturesActionToggleLoading = '[Departures] Departures Action Toggle Loading',

    // trash system

    DepartureOnServerRestored = '[Departure Trash] Departure On Server Restored',
    DepartureRestored = '[Departure Trash] Departure Restored',

    DepartureOnServerAdminRestored = '[Departure Admin Trash] Departure On Server Restored',
    DepartureAdminRestored = '[Departure Admin Trash] Departure Restored',

    DepartureDeletedFromTrash = '[Departure Trash] Departure deleted',
    DepartureDeletedFromAdminTrash = '[Departure Admin Trash] Departure deleted',

    DepartureTrash = 'Departure Trashed',
    DepartureTrashFlushed = 'Departure Trash Flushed',
}

export class DepartureOnServerCreated implements Action {
    readonly type = DepartureActionTypes.DepartureOnServerCreated;

    constructor(public payload: { departure: Departure}) {
    }
}

export class DepartureCreated implements Action {
    readonly type = DepartureActionTypes.DepartureCreated;

    constructor(public payload: { departure: Departure }) {
    }
}

export class DepartureUpdated implements Action {
    readonly type = DepartureActionTypes.DepartureUpdated;

    constructor(public payload: {
        partialdeparture: Update<Departure>,
        departure: Departure
    }) {
    }
}

export class DepartureDeleted implements Action {
    readonly type = DepartureActionTypes.DepartureDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class DeparturesPageRequested implements Action {
    readonly type = DepartureActionTypes.DeparturesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class DeparturesPageLoaded implements Action {
    readonly type = DepartureActionTypes.DeparturesPageLoaded;

    constructor(public payload: { departures: Departure[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class DeparturesPageCancelled implements Action {
    readonly type = DepartureActionTypes.DeparturesPageCancelled;
}

export class AllDeparturesRequested implements Action {
    readonly type = DepartureActionTypes.AllDeparturesRequested;
}

export class AllDeparturesLoaded implements Action {
    readonly type = DepartureActionTypes.AllDeparturesLoaded;

    constructor(public payload: { departures: Departure[] }) {
    }
}

export class DeparturesPageToggleLoading implements Action {
    readonly type = DepartureActionTypes.DeparturesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class DeparturesActionToggleLoading implements Action {
    readonly type = DepartureActionTypes.DeparturesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class DepartureDeletedFromAdminTrash implements Action {
    readonly type = DepartureActionTypes.DepartureDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class DepartureDeletedFromTrash implements Action {
    readonly type = DepartureActionTypes.DepartureDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class DepartureOnServerRestored implements Action {
    readonly type = DepartureActionTypes.DepartureOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class DepartureRestored implements Action {
    readonly type = DepartureActionTypes.DepartureRestored;

    constructor(public payload: { item: Departure }) {
    }
}

export class DepartureOnServerAdminRestored implements Action {
    readonly type = DepartureActionTypes.DepartureOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class DepartureAdminRestored implements Action {
    readonly type = DepartureActionTypes.DepartureAdminRestored;

    constructor(public payload: { item: Departure }) {
    }
}

export class DepartureTrashFlushed implements Action {
    readonly type = DepartureActionTypes.DepartureTrashFlushed;

    constructor() {
    }
}

export type DepartureActions = DepartureCreated
    | DepartureUpdated
    | DepartureDeleted
    | DeparturesPageRequested
    | DeparturesPageLoaded
    | DeparturesPageCancelled
    | AllDeparturesLoaded
    | AllDeparturesRequested
    | DepartureOnServerCreated
    | DeparturesPageToggleLoading
    | DeparturesActionToggleLoading
    | DepartureDeletedFromAdminTrash
    | DepartureDeletedFromTrash
    | DepartureOnServerRestored
    | DepartureRestored
    | DepartureOnServerAdminRestored
    | DepartureAdminRestored
    | DepartureTrashFlushed;
