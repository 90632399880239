<div class="kt-form" [ngClass]="{'readonly': readonly}">
    <kt-filter-section-all
        [acType]="targetProperty.generalInfo.propertySubTypeId"
        [filter$]="filter$"
        (filterChange)="onFilterChange($event)"
        ></kt-filter-section-all>
    <div class="form-group kt-form-group row">
        <div class="col-lg-12 kt-margin-bottom-10-mobile">
            <p style="text-align: end !important;">
                Total showing comparables: {{comparablesCount$|async}}
            </p>
        </div>
        <div class="col-lg-12 md-2">
            <agm-map
                [latitude]="targetProperty.generalInfo.locationData.latitude"
                [longitude]="targetProperty.generalInfo.locationData.longitude"
                [zoom]="15"
                [disableDoubleClickZoom]="true"
                [scrollwheel]="null"
                [gestureHandling]="'cooperative'"
                (mapReady)="onMapReady($event)"
                (zoomChange)="onZoomChanged($event)"
                (boundsChange)="onBoundsChanged($event)"
            >
                <agm-map-type-control></agm-map-type-control>
                <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button">
                    <mat-icon>my_location</mat-icon>
                </button>
                <agm-marker
                    [latitude]="targetProperty.generalInfo.locationData.latitude"
                    [longitude]="targetProperty.generalInfo.locationData.longitude"
                    [iconUrl]="tpIcon"
                    animation="DROP"
                ></agm-marker>
                <ng-container *ngIf="comparablesMap$ | async as comparableMap">
                    <agm-marker *ngFor="let key of comparableMap.keys()"
                        [latitude]="comparableMap.get(key).latitude"
                        [longitude]="comparableMap.get(key).longitude"
                        [label]="comparableMap.get(key).label"
                        [iconUrl]="icon"
                        (markerClick)="onMarkerClick(comparableMap.get(key).coms)">
                    </agm-marker>
                </ng-container>
            </agm-map>
        </div>
    </div>
</div>