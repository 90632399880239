// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CoordinateReferenceSystemActions, CoordinateReferenceSystemActionTypes} from '../_actions/coordinate-reference-system.actions';
// Models
import {CoordinateReferenceSystem} from '../_models/coordinate-reference-system.model';
import {QueryParamsModel} from '../../_base/crud';

export interface CoordinateReferenceSystemsState extends EntityState<CoordinateReferenceSystem> {
    isAllCoordinateReferenceSystemsLoaded: boolean;
    queryRowsCount: number;
    queryResult: CoordinateReferenceSystem[];
    lastCreatedCoordinateReferenceSystemId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CoordinateReferenceSystem> = createEntityAdapter<CoordinateReferenceSystem>();

export const initialCoordinateReferenceSystemsState: CoordinateReferenceSystemsState = adapter.getInitialState({
    isAllCoordinateReferenceSystemsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedCoordinateReferenceSystemId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function coordinateReferenceSystemsReducer(state = initialCoordinateReferenceSystemsState, action: CoordinateReferenceSystemActions): CoordinateReferenceSystemsState {
    switch (action.type) {
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCoordinateReferenceSystemId: undefined
            };
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerCreated:
            return {
                ...state
            };
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemCreated:
            return adapter.addOne(action.payload.coordinateReferenceSystem, {
                ...state, lastCreatedCoordinateReferenceSystemId: action.payload.coordinateReferenceSystem.id
            });
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemUpdated:
            return adapter.updateOne(action.payload.partialCoordinateReferenceSystem, state);
        case CoordinateReferenceSystemActionTypes.AllCoordinateReferenceSystemsLoaded:
            return adapter.addAll(action.payload.coordinateReferenceSystems, {
                ...state, isAllCoordinateReferenceSystemsLoaded: true
            });
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageLoaded:
            return adapter.addMany(action.payload.coordinateReferenceSystems, {
                ...initialCoordinateReferenceSystemsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
