import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UpdateStatusDialogComponent} from '../update-status-dialog/update-status-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export interface TrashAction {
    restore(id: number);

    delete(id: number);
}


@Component({
    selector: 'kt-trash-audit-trail-dialog',
    templateUrl: './trash-audit-trail-dialog.component.html'
})

export class TrashAuditTrailDialogComponent implements OnInit {

    selectedStatusForUpdate = new UntypedFormControl('');
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    trashAction: TrashAction = null;

    constructor(public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.trashAction = data.action;
    }

    ngOnInit() {
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        setTimeout(() => {
            this.viewLoading = false;
        }, 2500);
    }

    restore(id: number): void {
        if (this.trashAction) {
            this.data.items.forEach(element => {
                if (element.id == id) {
                    this.data.items.splice(this.data.items.indexOf(element), 1);
                }
            });
            this.trashAction.restore(id);
        }
    }

    reachAuditTrail(item: any): void {
        if (this.trashAction) {
            this.dialogRef.close(item);
        }
    }

    updateStatus() {
        if (this.selectedStatusForUpdate.value.length === 0) {
            return;
        }

        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        this.loadingAfterSubmit = true;
        setTimeout(() => {
            this.dialogRef.close(this.selectedStatusForUpdate.value); // Keep only this row
        }, 2500);
    }
}
