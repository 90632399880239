// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {WindowType} from '../_models/window-type.model';

export enum WindowTypeActionTypes {

    AllWindowTypesRequested = '[WindowTypes Home Page] All WindowTypes Requested',
    AllWindowTypesLoaded = '[WindowTypes API] All WindowTypes Loaded',

    WindowTypeOnServerCreated = '[Edit WindowType Dialog] WindowType On Server Created',
    WindowTypeCreated = '[Edit WindowTypes Dialog] WindowTypes Created',
    WindowTypeUpdated = '[Edit WindowType Dialog] WindowType Updated',
    WindowTypeDeleted = '[WindowTypes List Page] WindowType Deleted',

    WindowTypesPageRequested = '[WindowTypes List Page] WindowTypes Page Requested',
    WindowTypesPageLoaded = '[WindowTypes API] WindowTypes Page Loaded',
    WindowTypesPageCancelled = '[WindowTypes API] WindowTypes Page Cancelled',

    WindowTypesPageToggleLoading = '[WindowTypes page] WindowTypes Page Toggle Loading',
    WindowTypesActionToggleLoading = '[WindowTypes] WindowTypes Action Toggle Loading',

    // trash system

    WindowTypeOnServerRestored = '[WindowType Trash] WindowType On Server Restored',
    WindowTypeRestored = '[WindowType Trash] WindowType Restored',

    WindowTypeOnServerAdminRestored = '[WindowType Admin Trash] WindowType On Server Restored',
    WindowTypeAdminRestored = '[WindowType Admin Trash] WindowType Restored',

    WindowTypeDeletedFromTrash = '[WindowType Trash] WindowType deleted',
    WindowTypeDeletedFromAdminTrash = '[WindowType Admin Trash] WindowType deleted',

    WindowTypeTrash = 'WindowType Trashed',
    WindowTypeTrashFlushed = 'WindowType Trash Flushed',
}

export class WindowTypeOnServerCreated implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeOnServerCreated;

    constructor(public payload: { windowType: WindowType}) {
    }
}

export class WindowTypeCreated implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeCreated;

    constructor(public payload: { windowType: WindowType }) {
    }
}

export class WindowTypeUpdated implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeUpdated;

    constructor(public payload: {
        partialWindowType: Update<WindowType>,
        windowType: WindowType
    }) {
    }
}

export class WindowTypeDeleted implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class WindowTypesPageRequested implements Action {
    readonly type = WindowTypeActionTypes.WindowTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class WindowTypesPageLoaded implements Action {
    readonly type = WindowTypeActionTypes.WindowTypesPageLoaded;

    constructor(public payload: { windowTypes: WindowType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class WindowTypesPageCancelled implements Action {
    readonly type = WindowTypeActionTypes.WindowTypesPageCancelled;
}

export class AllWindowTypesRequested implements Action {
    readonly type = WindowTypeActionTypes.AllWindowTypesRequested;
}

export class AllWindowTypesLoaded implements Action {
    readonly type = WindowTypeActionTypes.AllWindowTypesLoaded;

    constructor(public payload: { windowTypes: WindowType[] }) {
    }
}

export class WindowTypesPageToggleLoading implements Action {
    readonly type = WindowTypeActionTypes.WindowTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class WindowTypesActionToggleLoading implements Action {
    readonly type = WindowTypeActionTypes.WindowTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class WindowTypeDeletedFromAdminTrash implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class WindowTypeDeletedFromTrash implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class WindowTypeOnServerRestored implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class WindowTypeRestored implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeRestored;

    constructor(public payload: { item: WindowType }) {
    }
}

export class WindowTypeOnServerAdminRestored implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class WindowTypeAdminRestored implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeAdminRestored;

    constructor(public payload: { item: WindowType }) {
    }
}

export class WindowTypeTrashFlushed implements Action {
    readonly type = WindowTypeActionTypes.WindowTypeTrashFlushed;

    constructor() {
    }
}

export type WindowTypeActions = WindowTypeCreated
    | WindowTypeUpdated
    | WindowTypeDeleted
    | WindowTypesPageRequested
    | WindowTypesPageLoaded
    | WindowTypesPageCancelled
    | AllWindowTypesLoaded
    | AllWindowTypesRequested
    | WindowTypeOnServerCreated
    | WindowTypesPageToggleLoading
    | WindowTypesActionToggleLoading
    | WindowTypeDeletedFromAdminTrash
    | WindowTypeDeletedFromTrash
    | WindowTypeOnServerRestored
    | WindowTypeRestored
    | WindowTypeOnServerAdminRestored
    | WindowTypeAdminRestored
    | WindowTypeTrashFlushed;
