import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, mergeMap, tap } from "rxjs/operators";
import { AllDraftStatementReportsLoaded, AllDraftStatementReportsRequested, DraftStatementReportActionToggleLoading, DraftStatementReportActionTypes, DraftStatementReportCreated, DraftStatementReportOnServerCreated, DraftStatementReportOnServerDeleted } from "../_actions/draft-statement-report.action";
import { ReportType } from "../_models/report-types.enum";
import { DraftStatementReportState } from "../_reducers/draft-statement-report.reducer";
import { DraftStatementReportService } from "../_services/draft-statement-report.service";

@Injectable()
export class DraftStatementEffects {
	hideActionToggleLoadingDispatcher = new DraftStatementReportActionToggleLoading({isLoading: false});
	showActionToggleLoadingDispatcher = new DraftStatementReportActionToggleLoading({isLoading: true});
	constructor(
		private actions$: Actions,
		private service: DraftStatementReportService,
		private store$: Store<DraftStatementReportState>
	) {}

	@Effect()
	requestReports$ = this.actions$.pipe(
		ofType<AllDraftStatementReportsRequested>(DraftStatementReportActionTypes.AllDraftStatementReportsRequested),
		mergeMap(({payload}) => this.service.getReports(payload.tpID)),
		map(res => {
			if (res) {
				const mutatedData = res.data.map(item => ({
					...item,
					type: ReportType.DraftStatementReport
				}));
				return {
					data: mutatedData,
					tp_id: res.tp_id
				}
			}
		}),
		map(({data, tp_id}) => {
			return new AllDraftStatementReportsLoaded({reports: data, tpId: tp_id});
		})
	)

	@Effect()
	createReport$ = this.actions$.pipe(
		ofType<DraftStatementReportOnServerCreated>(DraftStatementReportActionTypes.DraftStatementReportOnServerCreated),
		mergeMap(({payload}) => {
			this.store$.dispatch(this.showActionToggleLoadingDispatcher);
			return this.service.createReport(payload.report).pipe(
				tap(res => {
					this.store$.dispatch(new DraftStatementReportCreated({report: res.data}));
				})
			)
		}),
		map(() => {
			return this.hideActionToggleLoadingDispatcher;
		})
	)

	@Effect()
	deleteReport$ = this.actions$.pipe(
		ofType<DraftStatementReportOnServerDeleted>(DraftStatementReportActionTypes.DraftStatementReportOnServerDeleted),
		mergeMap(({payload}) => {
			this.store$.dispatch(this.showActionToggleLoadingDispatcher);
			return this.service.deleteReport(payload.id);
		}),
		map(() => {
			return this.hideActionToggleLoadingDispatcher;
		})
	)
}