// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map} from 'rxjs/operators';
// NGRX
import {Actions, Effect, ofType} from '@ngrx/effects';
// Notification actions
import {NotificationService} from '../_services/notification.service';
import {
    NotificationActionToggleLoading, NotificationActionTypes, NotificationDeleted, NotificationLoaded, NotificationRequested,
    NotificationUpdated
} from '../_actions/notificaiton.actions';

@Injectable()
export class NotificationEffects {

    showActionLoadingDispatcher = new NotificationActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new NotificationActionToggleLoading({isLoading: false});

    @Effect()
    loadAllNotifications$ = this.actions$
        .pipe(
            ofType<NotificationRequested>(NotificationActionTypes.NotificationRequested),
            // withLatestFrom(this.store.pipe(select(selectExistNotificationInStore))),
            // filter(([action, existNotificationInStore]) => existNotificationInStore),
            mergeMap(({payload}) => this.service.getAll(payload.userId)),
            map(res => {
                return new NotificationLoaded({items: res.data});
            })
        );

    @Effect({dispatch: false})
    updateNotification$ = this.actions$
        .pipe(
            ofType<NotificationUpdated>(NotificationActionTypes.NotificationUpdated),
            mergeMap(({payload}) => {
                return this.service.update(payload.item);
            }),
            map(res => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    deleteNotification$ = this.actions$
        .pipe(
            ofType<NotificationDeleted>(NotificationActionTypes.NotificationDeleted),
            mergeMap(({payload}) => {
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions,
                private service: NotificationService) {
    }
}
