import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { RainwaterPipeModel } from "../_models/rainwater-pipe.model";

export enum RainwaterPipeActionTypes {
    AllRainwaterPipesRequested = '[Linked Area] All RainwaterPipe Requested',
    AllRainwaterPipesLoaded = '[RainwaterPipe Effect] All RainwaterPipe Loaded',

    RainwaterPipeOnServerCreated = '[Add RainwaterPipe Page] RainwaterPipe On Server Created',
    RainwaterPipeCreated = '[RainwaterPipe Effect] RainwaterPipe Created',
    RainwaterPipeUpdated = '[RainwaterPipe Effect] RainwaterPipe Updated',
    RainwaterPipeDeleted = '[RainwaterPipe List Page] RainwaterPipe Deleted',

    RainwaterPipeOnServerRestored = '[RainwaterPipe Trash] RainwaterPipe On Server Restored',
    RainwaterPipeRestored = '[RainwaterPipe Trash] RainwaterPipe Restored',

    RainwaterPipeOnServerAdminRestored = '[RainwaterPipe Admin Trash] RainwaterPipe On Server Restored',
    RainwaterPipeAdminRestored = '[RainwaterPipe Admin Trash] RainwaterPipe Restored',

    RainwaterPipeDeletedFromTrash = '[RainwaterPipe Trash] RainwaterPipe deleted',
    RainwaterPipeDeletedFromAdminTrash = '[RainwaterPipe Admin Trash] RainwaterPipe deleted',

    RainwaterPipeTrash = 'RainwaterPipe Trashed',
    RainwaterPipeTrashFlushed = 'RainwaterPipe Trash Flushed',

    RainwaterPipesPageRequested = '[RainwaterPipe List Page] RainwaterPipe Page Requested',
    RainwaterPipesPageLoaded = '[RainwaterPipe API] RainwaterPipe Page Loaded',
    RainwaterPipesPageCancelled = '[RainwaterPipe API] RainwaterPipe Page Cancelled',

    RainwaterPipesPageToggleLoading = '[RainwaterPipe page] RainwaterPipe Page Toggle Loading',
    RainwaterPipeActionToggleLoading = '[RainwaterPipe] RainwaterPipe Action Toggle Loading'
}

export class RainwaterPipeOnServerCreated implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeOnServerCreated;

    constructor(public payload: { item: RainwaterPipeModel }) {
    }
}

export class RainwaterPipeCreated implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeCreated;

    constructor(public payload: { item: RainwaterPipeModel }) {
    }
}

export class RainwaterPipeUpdated implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeUpdated;

    constructor(public payload: {
        partialItem: Update<RainwaterPipeModel>,
        item: RainwaterPipeModel
    }) {
    }
}

export class RainwaterPipeDeleted implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RainwaterPipesPageRequested implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class RainwaterPipesPageLoaded implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipesPageLoaded;

    constructor(public payload: { items: RainwaterPipeModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class RainwaterPipesPageCancelled implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipesPageCancelled;
}

export class AllRainwaterPipesRequested implements Action {
    readonly type = RainwaterPipeActionTypes.AllRainwaterPipesRequested;
}

export class AllRainwaterPipesLoaded implements Action {
    readonly type = RainwaterPipeActionTypes.AllRainwaterPipesLoaded;

    constructor(public payload: { items: RainwaterPipeModel[] }) {
    }
}

export class RainwaterPipesPageToggleLoading implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RainwaterPipeActionToggleLoading implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class RainwaterPipeDeletedFromAdminTrash implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RainwaterPipeDeletedFromTrash implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RainwaterPipeOnServerRestored implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RainwaterPipeRestored implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeRestored;

    constructor(public payload: { item: RainwaterPipeModel }) {
    }
}

export class RainwaterPipeOnServerAdminRestored implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RainwaterPipeAdminRestored implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeAdminRestored;

    constructor(public payload: { item: RainwaterPipeModel }) {
    }
}

export class RainwaterPipeTrashFlushed implements Action {
    readonly type = RainwaterPipeActionTypes.RainwaterPipeTrashFlushed;

    constructor() {
    }
}


export type RainwaterPipeActions = RainwaterPipeCreated
    | RainwaterPipeUpdated
    | RainwaterPipeDeleted
    | RainwaterPipesPageRequested
    | RainwaterPipesPageLoaded
    | RainwaterPipesPageCancelled
    | AllRainwaterPipesLoaded
    | AllRainwaterPipesRequested
    | RainwaterPipeOnServerCreated
    | RainwaterPipeDeletedFromAdminTrash
    | RainwaterPipeDeletedFromTrash
    | RainwaterPipeOnServerRestored
    | RainwaterPipeRestored
    | RainwaterPipeOnServerAdminRestored
    | RainwaterPipeAdminRestored
    | RainwaterPipeTrashFlushed
    | RainwaterPipesPageToggleLoading
    | RainwaterPipeActionToggleLoading;