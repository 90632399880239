import {FloorType} from '../_models/floor-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {FloorTypesState} from '../_reducers/floor-type.reducers';
import * as fromFloorType from '../_reducers/floor-type.reducers';
import {each} from 'lodash';

export const selectFloorTypesState = createFeatureSelector<FloorTypesState>('floorTypes');

export const selectFloorTypeById = (floorTypeId: number) => createSelector(
    selectFloorTypesState,
    floorTypesState => floorTypesState.entities[floorTypeId]
);

export const selectAllFloorTypes = createSelector(
    selectFloorTypesState,
    fromFloorType.selectAll
);

export const selectAllFloorTypesIds = createSelector(
    selectFloorTypesState,
    fromFloorType.selectIds
);

export const allFloorTypesLoaded = createSelector(
    selectFloorTypesState,
    floorTypesState => floorTypesState.isAllFloorTypesLoaded
);


export const selectFloorTypesPageLoading = createSelector(
    selectFloorTypesState,
    floorTypesState => floorTypesState.listLoading
);

export const selectFloorTypesActionLoading = createSelector(
    selectFloorTypesState,
    floorTypesState => floorTypesState.actionLoading
);

export const selectLastCreatedFloorTypeId = createSelector(
    selectFloorTypesState,
    floorTypesState => floorTypesState.lastCreatedFloorTypeId
);

export const selectFloorTypesShowInitWaitingMessage = createSelector(
    selectFloorTypesState,
    floorTypesState => floorTypesState.showInitWaitingMessage
);

export const selectTrashedFloorTypeCount = createSelector(
    selectFloorTypesState,
    (floorTypesState) => floorTypesState.totalTrashed
);

export const selectAdminTrashedFloorTypeCount = createSelector(
    selectFloorTypesState,
    (floorTypesState) => floorTypesState.totalAdminTrashed
);


export const selectFloorTypeQueryResult = createSelector(
    selectFloorTypesState,
    floorTypesState => {
        const items: FloorType[] = [];
        each(floorTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: FloorType[] = httpExtension.sortArray(items, floorTypesState.lastQuery.sortField, floorTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, floorTypesState.totalCount, '', floorTypesState.totalTrashed);
    }
);
