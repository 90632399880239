import {PropertyType} from '../_models/property-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PropertyTypesState} from '../_reducers/property-type.reducers';
import * as fromPropertyType from '../_reducers/property-type.reducers';
import {each} from 'lodash';

export const selectPropertyTypesState = createFeatureSelector<PropertyTypesState>('propertyTypes');

export const selectPropertyTypeById = (propertyTypeId: number) => createSelector(
    selectPropertyTypesState,
    propertyTypesState => propertyTypesState.entities[propertyTypeId]
);

export const selectAllPropertyTypes = createSelector(
    selectPropertyTypesState,
    fromPropertyType.selectAll
);

export const selectAllPropertyTypesIds = createSelector(
    selectPropertyTypesState,
    fromPropertyType.selectIds
);

export const allPropertyTypesLoaded = createSelector(
    selectPropertyTypesState,
    propertyTypesState => propertyTypesState.isAllPropertyTypesLoaded
);


export const selectPropertyTypesPageLoading = createSelector(
    selectPropertyTypesState,
    propertyTypesState => propertyTypesState.listLoading
);

export const selectPropertyTypesActionLoading = createSelector(
    selectPropertyTypesState,
    propertyTypesState => propertyTypesState.actionLoading
);

export const selectLastCreatedPropertyTypeId = createSelector(
    selectPropertyTypesState,
    propertyTypesState => propertyTypesState.lastCreatedPropertyTypeId
);

export const selectPropertyTypesShowInitWaitingMessage = createSelector(
    selectPropertyTypesState,
    propertyTypesState => propertyTypesState.showInitWaitingMessage
);


export const selectTrashedPropertyTypeCount = createSelector(
    selectPropertyTypesState,
    (propertyTypesState) => propertyTypesState.totalTrashed
);

export const selectAdminTrashedPropertyTypeCount = createSelector(
    selectPropertyTypesState,
    (propertyTypesState) => propertyTypesState.totalAdminTrashed
);


export const selectPropertyTypeQueryResult = createSelector(
    selectPropertyTypesState,
    propertyTypesState => {
        const items: PropertyType[] = [];
        each(propertyTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PropertyType[] = httpExtension.sortArray(items, propertyTypesState.lastQuery.sortField, propertyTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, propertyTypesState.totalCount, '', propertyTypesState.totalTrashed);
    }
);
