import { Component, Inject, OnInit, SecurityContext } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: 'kt-video-viewer-dialog',
    templateUrl: './video-viewer-dialog.component.html',
    styleUrls: ['./video-viewer-dialog.component.scss']
})
export class VideoViewerDialogComponent implements OnInit {
    src: SafeResourceUrl;
    constructor(
        public dialogRef: MatDialogRef<VideoViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer
    ){}

    ngOnInit() {
        this.src = this.transform();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    transform() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.src)
    }
}