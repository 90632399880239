import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { concat } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, skip, takeUntil, tap } from 'rxjs/operators';
import { AssetClassSizeModel, ResidentialExternalAreaModel } from 'src/app/core/comparable';
import { AllStandardMeasurementsRequested, AllUnitAreaMeasurementsRequested, selectAllUnitAreaMeasurements, selectStandardMeasurementsForAc, StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { AppState } from 'src/app/core/reducers';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { FloorAction } from '../../../shared_components/size-module/models/floor-action.model';
import { Rooms } from '../../../shared_components/size-module/mongolia-standard-table/mongolia-standard-table.types';
import { SizeModuleComponent } from '../../../shared_components/size-module/size-module.component';
import { SizesModuleComponent } from '../../../shared_components/sizes-module/sizes-module.component';
import { SizeChange } from '../../../shared_components/sizes-module/sizes-module.types';
import { ConditionRatingV2Service } from '../../services/condition-rating-v2.service';
import { InspectionBaseComponent } from '../inspection-base.component';
import { InspectionSizeService } from '../inspection-size.service';

@Component({
  selector: 'kt-inspection-size-measurements',
  templateUrl: './inspection-size-measurements.component.html',
  styleUrls: ['./inspection-size-measurements.component.scss'],
  providers: [StandardMeasurementUtility]
})
export class InspectionSizeMeasurementsComponent implements OnInit, OnDestroy {
  @Input() propertySubTypeID: number;
  @Input() assetClassID: number;
  @Input() isInstructedToMeasure: boolean;
  @Input() readonly: boolean;
  @Input() acSizeData: {
    unitMeasurementID: number;
    unitAreaMeasurementID: number;
    measurementStandardID: number;
    standardMeasurementID: number;
    toeUnitOfMeasurementID: number;
  }
  @Input() measurementData: InspectionBaseComponent['acMeasurementData'];
  @Input() sizesSubject: BehaviorSubject<AssetClassSizeModel[]>
  @Input() roomChange$: Observable<Rooms>;
  @Output() staticUnitChange = new EventEmitter<number>();
  @ViewChild(SizesModuleComponent, {static: false}) sizesModuleComponent: SizesModuleComponent;
  @ViewChild(AcDocumentUploadComponent, {static: false}) documentUploadComponent: AcDocumentUploadComponent;

  form: UntypedFormGroup;
  unitMeasurementWarning: boolean = false;
  acStandardMeasurement: StandardMeasurement;
  standardMeasurementWarning: boolean = false;

  staticUnit: number;
  units: UnitAreaMeasurement[] = [];
  standardMeasurements: StandardMeasurement[] = [];

  private _unitMeasurement$: BehaviorSubject<UnitAreaMeasurement> = new BehaviorSubject(null);
  unitMeasurement$: Observable<UnitAreaMeasurement|null> = this._unitMeasurement$.asObservable();

  floorAction$: Observable<FloorAction|null>;
  bedrooms$: Observable<number>;
  externalAreas$: Observable<ResidentialExternalAreaModel[]>;
  sizeModuleError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _onDestroy$: Subject<void> = new Subject();

  hasFormErrors: boolean = false;
  standardMeasurements$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  
  private _sizeChanges$ = new BehaviorSubject<SizeChange>({
    standard_measurement_id: null,
    unit_area_measurement_id: null,
    unit_area_measurement_name: null
  })
  sizeChanges$: Observable<any> = this._sizeChanges$.asObservable();

  errorFields = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private inspectionSizeService: InspectionSizeService,
    public typesUtilsService: TypesUtilsService,
    public smUtilityService: StandardMeasurementUtility,
    private crService: ConditionRatingV2Service
  ) { }

  ngOnInit(): void {
    this.floorAction$ = this.inspectionSizeService.floorAction$;
    this.bedrooms$ = this.inspectionSizeService.bedrooms$;
    this.externalAreas$ = this.inspectionSizeService.externalAreas$;


    combineLatest([
      this.roomChange$.pipe(filter(r => r != null)),
      this.sizeChanges$,
      this.externalAreas$
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([rooms, sizeChange, externalAreas]) => {
      this.crService.setComponentsAllBefore(this.smUtilityService, {
        measurementStandardId: sizeChange.standard_measurement_id,
        acId: this.assetClassID,
        unitMeasurementId: sizeChange.unit_area_measurement_id,
        externalAreas: externalAreas.map(ea => ea.id),
        rooms: rooms
      })
    })

    if (this.acSizeData.toeUnitOfMeasurementID == 1) {
      this.staticUnit = 2;
    } else {
      this.staticUnit = 1;
    }
    this.staticUnitChange.emit(this.staticUnit);

    this.store.dispatch(new AllUnitAreaMeasurementsRequested());
    this.store.pipe(
      select(selectAllUnitAreaMeasurements),
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.units = [];
      if (res) {
        this.units = res;
        const id = this.acSizeData.unitMeasurementID ? this.acSizeData.unitMeasurementID : this.acSizeData.unitAreaMeasurementID;
        const unit = this.units.find(unit => unit.id == id);
        this._unitMeasurement$.next(unit);
        this._sizeChanges$.next({
          unit_area_measurement_id: id,
          unit_area_measurement_name: unit ? unit.acronym : null,
          standard_measurement_id: this.measurementData.measurement_standard_id
        })
        if (unit) {
          this.unitMeasurementWarning = unit.id != this.acSizeData.unitAreaMeasurementID;
        }
      }
    });
    this.store.dispatch(new AllStandardMeasurementsRequested());
    this.store.pipe(
      select(selectStandardMeasurementsForAc(this.propertySubTypeID)),
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.standardMeasurements = [];
      if (res) {
        this.standardMeasurements = res;
        // -1 means New name and -2 means Custom
        if (this.measurementData.measurement_standard_id == -1 || this.measurementData.measurement_standard_id == -2) {
          const csm = new StandardMeasurement();
          csm.id = -2;
          csm.name = 'Custom';
          const nsm = new StandardMeasurement();
          nsm.id = -1;
          nsm.name = this.measurementData.new_measurement_standard_name;
          this.standardMeasurements = concat([csm, nsm], res);
        }
        this.standardMeasurements$.next(this.standardMeasurements);
        const id = this.acSizeData.measurementStandardID ? this.acSizeData.measurementStandardID : this.acSizeData.standardMeasurementID;
        this.acStandardMeasurement = this.standardMeasurements.find(sm => sm.id == id);
        if (this.acStandardMeasurement) {
          this.standardMeasurementWarning = this.acStandardMeasurement.id != this.acSizeData.standardMeasurementID;
        }
      }
    })

    this.form = this.formBuilder.group({
      measurement_date: [this.measurementData.measurement_date, this.isInstructedToMeasure ? Validators.required : null],
      unit_measurement_id: [this.measurementData.unit_measurement_id, this.isInstructedToMeasure ? Validators.required : null],
      measurement_standard_id: [this.measurementData.measurement_standard_id, this.isInstructedToMeasure ? Validators.required : null],
      new_measurement_standard_name: [this.measurementData.new_custom_measurement_standard_name],
      new_measurement_standard_description: [this.measurementData.new_measurement_standard_desc],
      purpose_of_the_measurement_instruction: [this.measurementData.purpose_of_the_measurement_instruction, this.isInstructedToMeasure ? Validators.required : null],
      measurement_methodology_adopted: [this.measurementData.measurement_methodology_adopted, this.isInstructedToMeasure ? Validators.required : null],
      scale_of_plan: [this.measurementData.scale_of_plan, this.isInstructedToMeasure ? Validators.required : null],
      floorpans: [this.measurementData.floorpans, this.isInstructedToMeasure ? Validators.required : null]
    });
    this.form.controls.unit_measurement_id.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      this.unitMeasurementWarning = val != this.acSizeData.unitAreaMeasurementID;
      const unit = this.units.find(u => u.id == val);
      if (!unit) {
        return;
      }
      this.staticUnit = unit.id;
      this.staticUnitChange.emit(this.staticUnit);
      this._unitMeasurement$.next(unit);
      this._sizeChanges$.next({
        standard_measurement_id: this.form.controls.measurement_standard_id.value,
        unit_area_measurement_id: val,
        unit_area_measurement_name: unit.acronym
      })
    });
    this.form.controls.measurement_standard_id.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(val => {
      this.standardMeasurementWarning = val != this.acSizeData.standardMeasurementID;
      const standard = this.standardMeasurements.find(sm => sm.id == val);
      if (!standard) {
        return;
      }
      this.acStandardMeasurement = standard;

      if (val == -2) {
        this.form.controls.new_measurement_standard_name.setValidators([Validators.required])
      } else {
        this.form.controls.new_measurement_standard_name.clearValidators();
      }
      this.form.controls.new_measurement_standard_name.updateValueAndValidity();

      const unit = this.units.find(u => u.id == this.form.controls.unit_measurement_id.value);
      if (!unit) {
        return;
      }
      this._sizeChanges$.next({
        standard_measurement_id: val ,
        unit_area_measurement_id: this.form.controls.unit_measurement_id.value,
        unit_area_measurement_name: unit.acronym
      })
    })

  }


  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearDate(ctrl: AbstractControl) {
    ctrl.setValue(null);
    ctrl.updateValueAndValidity();
  }

  checkValidation(isComplete: boolean): boolean {
    let errorFields = [];
    let isValid = true;
    this.hasFormErrors = false;
    this.sizeModuleError$.next(false);
    const controls = this.form.controls;
    if (isComplete && this.form.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          errorFields.push(cName);
        }
        controls[cName].markAsTouched();
      })
      isValid = false;
      this.hasFormErrors = true;
    }

    if (isComplete && this.sizesModuleComponent && !this.sizesModuleComponent.isValid()) {
      isValid = false;
      this.hasFormErrors = true;
      this.sizeModuleError$.next(true);
      errorFields.push('size_modules');
    }

    this.errorFields = errorFields;
    return isValid;
  }

  getData(): InspectionBaseComponent['acMeasurementData'] {
    const controls = this.form.controls;
    const date = controls.measurement_date.value
      ? this.typesUtilsService.getDateStringFromDate(new Date(controls.measurement_date.value))
      : null
    return {
      measurement_date: date,
      measurement_methodology_adopted: controls.measurement_methodology_adopted.value,
      scale_of_plan: controls.scale_of_plan.value,
      floorpans: controls.floorpans.value,
      purpose_of_the_measurement_instruction: controls.purpose_of_the_measurement_instruction.value,
      unit_measurement_id: controls.unit_measurement_id.value,
      measurement_standard_id: controls.measurement_standard_id.value,
      new_measurement_standard_name: this.measurementData.new_measurement_standard_name,
      new_measurement_standard_desc: controls.new_measurement_standard_description.value,
      new_measurement_standard_files: this.documentUploadComponent ? this.documentUploadComponent.getDocuments() : [],
      new_custom_measurement_standard_name: controls.new_measurement_standard_name.value
    }
  }

  getSizeModuleData(): any {
    if (this.sizesModuleComponent) {
      return this.sizesModuleComponent.getData();
    }
    return null;
  }
}
