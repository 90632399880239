import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { map, mergeMap, tap } from "rxjs/operators";
import { AssetClassesPageRequested } from "../../asset_class";
import { AppState } from "../../reducers";
import { QueryParamsModel } from "../../_base/crud";
import { AllToeReportTasksByToeIDLoaded, AllToeReportTasksByToeIDRequested, DeleteReportTask, ReportTaskDeleted, ToeReportTasksActionTypes, ToeReportTaskUpdated, UpdateReportTaskReports, UpdateToeReportTask } from "../_actions/toe-report-task.actions";
import { ToeReportTaskModel } from "../_models/toe-report-task.model";
import { ToeReportTaskService } from "../_services/toe-report-task.service";

@Injectable()
export class ToeReportTaskEffects {
	constructor(
		private actions$: Actions, 
		private service: ToeReportTaskService,
		private store: Store<AppState>
	) {}

	@Effect()
	getReportTasks$ = this.actions$.pipe(
		ofType<AllToeReportTasksByToeIDRequested>(ToeReportTasksActionTypes.AllToeReportTasksByToeIDRequested),
		mergeMap(({payload}) => {
			return this.service.getAllToeReportTasks(payload.toeID);
		}),
		map(res => {
			return new AllToeReportTasksByToeIDLoaded({reports: res.data});
		})
	);

	@Effect()
	updateReportTask$ = this.actions$.pipe(
		ofType<UpdateToeReportTask>(ToeReportTasksActionTypes.UpdateToeReportTask),
		mergeMap(({payload}) => {
			return this.service.updateReportTask(payload.task.id, payload.task);
		}),
		map(res => {
			const update: Update<ToeReportTaskModel> = {
				id: res.data.id,
				changes: res.data
			};
			return new ToeReportTaskUpdated({update})
		})
	)

	@Effect()
	updateReportTaskStatus$ = this.actions$.pipe(
		ofType<UpdateReportTaskReports>(ToeReportTasksActionTypes.UpdateReportTaskReports),
		mergeMap(({payload}) => {
			return this.service.updateReportTaskStatus(payload.id, payload.taskCompleted, payload.reports.map(r => ({id: r.id, checked: r.checked})));
		}),
		tap(res => {
			this.store.dispatch(new AssetClassesPageRequested({
				page: new QueryParamsModel({}, 'asc', 'id', 0, 1000),
				toeId: res.data.toe_id
			}))
		}),
		map(res => {
			const update: Update<ToeReportTaskModel> = {
				id: res.data.id,
				changes: res.data
			};
			return new ToeReportTaskUpdated({update});
		})
	)

	@Effect()
	deleteReportTask$ = this.actions$.pipe(
		ofType<DeleteReportTask>(ToeReportTasksActionTypes.DeleteReportTask),
		mergeMap(({payload}) => {
			return this.service.deleteReportTask(payload.id);
		}),
		tap(res => {
			this.store.dispatch(new AssetClassesPageRequested({
				page: new QueryParamsModel({}, 'asc', 'id', 0, 1000),
				toeId: res.toe_id
			}))
		}),
		map(res => {
			return new ReportTaskDeleted({id: res.id});
		})
	)
}