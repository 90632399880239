<kt-portlet>
    <kt-portlet-header 
            [title]="getComponentTitle() + (readonly ? ' (View Only)' : '')" 
            [tooltipTitle]="'TOOLTIP.LT.PREMISE_OF_VALUE.FORM' + (mode === 'NEW' ? '' : '_EDIT') + '.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.LT.PREMISE_OF_VALUE.FORM' + (mode === 'NEW' ? '' : '_EDIT') + '.DESCRIPTION' | translate" 
            [class]="'kt-portlet__head--lg'" 
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a [routerLink]="model.id > 0 ? ['../../'] : ['../']"
               class="btn  mr-2"
               mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{back_page: ('PREMISE_OF_VALUE.LIST.TITLE' | translate)}">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
            </a>
            <ng-container *ngIf="!readonly">
                <a href="javascript:;"
                class="btn  mr-2"
                (click)="reset()"
                mat-raised-button
                >
                    <i class="flaticon-cogwheel-2"></i>
                    <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                </a>
                <a href="javascript:;" class="btn btn-success mr-2" color="submit"
                (click)="onSubmit(false)"
                [disabled]="!isFormValid()"

                mat-raised-button >
                    <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
                </a>
            </ng-container>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" [formGroup]="premiseOfValueForm" [ngClass]="{'readonly': readonly}">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{'GENERAL.MESSAGE.FORM_WARNING' | translate}}
                </kt-alert>

                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput
                            [placeholder]="'PREMISE_OF_VALUE.FORM.FIELD.GENERAL.NAME.PLACEHOLDER' | translate"
                               formControlName="name"
                               [(ngModel)]="model.name"/>
                        <mat-error
                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('PREMISE_OF_VALUE.FORM.FIELD.GENERAL.NAME.TITLE' | translate)
                            }"
                        >
                       </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <textarea
                                rows="3"
                                matInput
                                [placeholder]="'PREMISE_OF_VALUE.FORM.FIELD.GENERAL.DEFINITION.PLACEHOLDER' | translate"
                                formControlName="definition"
                                [(ngModel)]="model.definition"></textarea>

                        <mat-error
                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('PREMISE_OF_VALUE.FORM.FIELD.GENERAL.DEFINITION.TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </kt-portlet-body>
</kt-portlet>

