import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { TopPropertyTypeModel } from "../_models/top-property-type.model";

export enum TopPropertyTypeActionTypes {
    AllTopPropertyTypesRequested = '[Linked Area] All Top Property Types Requested',
    AllTopPropertyTypesLoaded = '[Top Property Types Effect] All Top Property Types Loaded',

    TopPropertyTypeOnServerCreated = '[Add Top Property Type Page] TopPropertyType On Server Created',
    TopPropertyTypeCreated = '[Top Property Type Effect] TopPropertyType Created',
    TopPropertyTypeUpdated = '[Top Property Type Effect] TopPropertyType Updated',
    TopPropertyTypeDeleted = '[Top Property Type List Page] TopPropertyType Deleted',

    TopPropertyTypeOnServerRestored = '[TopPropertyType Trash] TopPropertyType On Server Restored',
    TopPropertyTypeRestored = '[TopPropertyType Trash] TopPropertyType Restored',

    TopPropertyTypeOnServerAdminRestored = '[TopPropertyType Admin Trash] TopPropertyType On Server Restored',
    TopPropertyTypeAdminRestored = '[TopPropertyType Admin Trash] TopPropertyType Restored',

    TopPropertyTypeDeletedFromTrash = '[TopPropertyType Trash] TopPropertyType deleted',
    TopPropertyTypeDeletedFromAdminTrash = '[TopPropertyType Admin Trash] TopPropertyType deleted',

    TopPropertyTypeTrash = 'TopPropertyType Trashed',
    TopPropertyTypeTrashFlushed = 'TopPropertyType Trash Flushed',

    TopPropertyTypesPageRequested = '[TopPropertyType List Page] TopPropertyType Page Requested',
    TopPropertyTypesPageLoaded = '[TopPropertyType API] TopPropertyType Page Loaded',
    TopPropertyTypesPageCancelled = '[TopPropertyType API] TopPropertyType Page Cancelled',

    TopPropertyTypesPageToggleLoading = '[TopPropertyType page] TopPropertyType Page Toggle Loading',
    TopPropertyTypeActionToggleLoading = '[TopPropertyType] TopPropertyType Action Toggle Loading'
}

export class TopPropertyTypeOnServerCreated implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeOnServerCreated;

    constructor(public payload: { item: TopPropertyTypeModel }) {
    }
}

export class TopPropertyTypeCreated implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeCreated;

    constructor(public payload: { item: TopPropertyTypeModel }) {
    }
}

export class TopPropertyTypeUpdated implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeUpdated;

    constructor(public payload: {
        partialItem: Update<TopPropertyTypeModel>,
        item: TopPropertyTypeModel
    }) {
    }
}

export class TopPropertyTypeDeleted implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class TopPropertyTypesPageRequested implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class TopPropertyTypesPageLoaded implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypesPageLoaded;

    constructor(public payload: { items: TopPropertyTypeModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class TopPropertyTypesPageCancelled implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypesPageCancelled;
}

export class AllTopPropertyTypesRequested implements Action {
    readonly type = TopPropertyTypeActionTypes.AllTopPropertyTypesRequested;
}

export class AllTopPropertyTypesLoaded implements Action {
    readonly type = TopPropertyTypeActionTypes.AllTopPropertyTypesLoaded;

    constructor(public payload: { items: TopPropertyTypeModel[] }) {
    }
}

export class TopPropertyTypesPageToggleLoading implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class TopPropertyTypeActionToggleLoading implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class TopPropertyTypeDeletedFromAdminTrash implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TopPropertyTypeDeletedFromTrash implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TopPropertyTypeOnServerRestored implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TopPropertyTypeRestored implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeRestored;

    constructor(public payload: { item: TopPropertyTypeModel }) {
    }
}

export class TopPropertyTypeOnServerAdminRestored implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TopPropertyTypeAdminRestored implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeAdminRestored;

    constructor(public payload: { item: TopPropertyTypeModel }) {
    }
}

export class TopPropertyTypeTrashFlushed implements Action {
    readonly type = TopPropertyTypeActionTypes.TopPropertyTypeTrashFlushed;

    constructor() {
    }
}


export type TopPropertyTypeActions = TopPropertyTypeCreated
    | TopPropertyTypeUpdated
    | TopPropertyTypeDeleted
    | TopPropertyTypesPageRequested
    | TopPropertyTypesPageLoaded
    | TopPropertyTypesPageCancelled
    | AllTopPropertyTypesLoaded
    | AllTopPropertyTypesRequested
    | TopPropertyTypeOnServerCreated
    | TopPropertyTypeDeletedFromAdminTrash
    | TopPropertyTypeDeletedFromTrash
    | TopPropertyTypeOnServerRestored
    | TopPropertyTypeRestored
    | TopPropertyTypeOnServerAdminRestored
    | TopPropertyTypeAdminRestored
    | TopPropertyTypeTrashFlushed
    | TopPropertyTypesPageToggleLoading
    | TopPropertyTypeActionToggleLoading;