import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TabHeader } from "../../shared_components/tab-header/tab-header.component";
import { BehaviorSubject, Subject, of } from "rxjs";
import { GeneralSettingsService } from "src/app/core/general-settings/general-settings.service";
import { map, take, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/core/reducers";
import { UpdateGeneralSettings, UpdateTempLocation, selectSystemMapLocation, selectTempLocation } from "src/app/core/general-settings/general-settings.store";
import { MapsAPILoader } from "@agm/core";
import { Location } from "@angular/common";

@Component({
    selector: 'kt-general-settings',
    templateUrl: 'general-settings.component.html',
    styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
    // Tabs
    selectedTab = 0
    selectedTabChange: BehaviorSubject<number> = new BehaviorSubject(0)
    selectedTabChange$ = this.selectedTabChange.asObservable()
    tabHeaders: TabHeader[] = [
        {label: 'Maps', disabled: of(false)}
    ]
    onTabChange(index: number) {
        this.selectedTab = index
    }

    // Map
    latitude$ = this.store.select(selectTempLocation).pipe(map(location => location.latitude))
    longitude$ = this.store.select(selectTempLocation).pipe(map(location => location.longitude))
    locationState = {
        latitude: null,
        longitude: null
    }
    private map: google.maps.Map;
    private mapClickListener: google.maps.MapsEventListener
    @ViewChild('locationSearch', {static: false})
    public searchElementRef: ElementRef

    private _onDestroy$: Subject<void> = new Subject<void>()

    constructor(
        private ngZone: NgZone,
        private mapsApiLoader: MapsAPILoader,
        private store: Store<AppState>,
        private location: Location
    ) {
    }

    ngOnInit(): void {
        this.store.select(selectTempLocation).pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(location => {
            this.locationState = location
        })

        this.mapsApiLoader.load().then(() => {
            if (this.searchElementRef === undefined) {
                return;
            }
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ['(cities)']
            })
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    const place = autocomplete.getPlace()
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();
                    this.store.dispatch(new UpdateTempLocation({latitude, longitude}))
                })
            })
        })
    }

    ngOnDestroy(): void {
        this._onDestroy$.next() 
        this._onDestroy$.complete(),
        this.mapClickListener.remove()
    }

    onMapReady(event: any) {
        this.map = event
        this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
            this.ngZone.run(() => {
                this.mapRightClick(e)
            })
        })
    }

    onSubmit() {
        this.store.dispatch(new UpdateGeneralSettings({item: {location: this.locationState}}))
    }

    back() {
        this.location.back()
    }

    private mapRightClick(event: google.maps.MouseEvent) {
        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();
        this.store.dispatch(new UpdateTempLocation({latitude, longitude}))
    }
}