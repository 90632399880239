import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Subscription, merge, of, BehaviorSubject } from "rxjs";
import { delay, take, tap } from "rxjs/operators";
import { AssetClassLandDeleted, AssetClassLandDeletedFromAdminTrash, AssetClassLandDeletedFromTrash, AssetClassLandDuplicate, AssetClassLandOnServerAdminRestored, AssetClassLandOnServerRestored, AssetClassLandPageRequested } from "src/app/core/comparable/_actions/asset-class-land.actions";
import { AssetClassLandDataSource } from "src/app/core/comparable/_data-sources/asset-class-land.datasource";
import { AssetClassLandModel } from "src/app/core/comparable/_models/asset-class-land.model";
import { selectAdminTrashedAssetClassLandCount, selectAssetClassLandLastQuery, selectTrashedAssetClassLandCount } from "src/app/core/comparable/_selectors/asset-class-land.selectors";
import { AssetClassLandService } from "src/app/core/comparable/_services/asset-class-land.service";
import { User, currentUser, hasPermission } from "src/app/core/mad-auth/mad-auth.store";
import { AppState } from "src/app/core/reducers";
import { LayoutUtilsService, QueryParamsModel } from "src/app/core/_base/crud";
import { emptyFilter, FilterChange, FilterModel } from "../../../shared_components/filter-section/filter-section.component";

@Component({
    selector: 'kt-land-comparable-list',
    templateUrl: './land-comparable-list.component.html',
    styleUrls: ['./land-comparable-list.component.scss']
})
export class LandComparableListComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;

    dataSource: AssetClassLandDataSource;
    displayedColumns = ['id', 'sub_type', 'size', 'consideration', 'source_date', 'actions'];

    lastQueryParams: QueryParamsModel;
    filterModel: FilterModel = emptyFilter();
    filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
    filterObs$ = this.filter$.asObservable();

    trashCnt = 0;
    adminTrashCnt = 0;

    private _subscriptions: Subscription[] = [];
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
    currentUser: User|null

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
        private layoutService: LayoutUtilsService,
        private service: AssetClassLandService
    ) {}

    /**
     * Life cycles
     */
    ngOnInit() {
        const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
        this._subscriptions.push(userSub)
        this.paginator._intl.itemsPerPageLabel = 'Display';

        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this._subscriptions.push(sortSubscription);

        const paginatorSubscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this._loadList())
        ).subscribe();
        this._subscriptions.push(paginatorSubscription);

        this.dataSource = new AssetClassLandDataSource(this.store);

        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this._loadList();
        }); // Remove this line, just loading imitation

        this.lastQueryParams = new QueryParamsModel({});
        const lastQuerySubscription = this.store.pipe(
            select(selectAssetClassLandLastQuery),
            take(1)
        ).subscribe(res => {
            if (res.isEmpty()) {
                return;
            }
            this.lastQueryParams = res;
            this._updateFilter(res.filter)
        });
        this._subscriptions.push(lastQuerySubscription);

        const trashSubscription = this.store.pipe(
            select(selectTrashedAssetClassLandCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this._subscriptions.push(trashSubscription);

        const adminTrashSubscription = this.store.pipe(
            select(selectAdminTrashedAssetClassLandCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this._subscriptions.push(adminTrashSubscription);
    }

    ngOnDestroy() {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    onFilterChange(event: FilterChange) {
        if (event.page != null) {
            this.paginator.pageIndex = event.page
        }
        this.filterModel = event.filter;
        this._loadList();
    }

    /**
     * Actions 
     */
    addComparable() {
        this.editComparable(null);
    }
    editComparable(ac: AssetClassLandModel) {
        if (ac) {
            this.router.navigate(['edit', ac.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute})
        }
    }
    deleteComparable(ac: AssetClassLandModel) {
        this.store.dispatch(new AssetClassLandDeleted({id: ac.id}));
    }
    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'info';
            default:
               return 'success';
        }
    }
    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 2:
                return 'imported';
            default:
                return 'Validated';
        }
    }
    trash() {
        this.service.getTrashed().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.name}`,
                    id: elem.id.toString(),
                    date: elem.user_deleted,
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy
                });
            });
            this._showTrashed(items);
        })
    }
    adminTrash() {
        this.service.getAdminTrashed().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.name}`,
                    id: elem.id.toString(),
                    date: elem.deleted_at,
                    hasPermanentlyDelete: true,
                    deletedUser: elem.deletedBy
                });
            });
            this._showTrashed(items, true);
        })
    }

    // Acs
    delete(id: number) {
        this.store.dispatch(new AssetClassLandDeletedFromTrash({id}))
    }
    hardDelete(id: number) {
        this.store.dispatch(new AssetClassLandDeletedFromAdminTrash({id}))
    }
    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new AssetClassLandOnServerAdminRestored({id: id}))
        } else {
            this.store.dispatch(new AssetClassLandOnServerRestored({id: id}))
        }
    }

    /**
     * Methods
     */
    private _loadList() {
        let queryParams = null;
        if (this.lastQueryParams.isEmpty()) {
            const _filter = Object.assign({}, this.filterModel) as FilterModel;

            queryParams = new QueryParamsModel(
                _filter,
                this.sort.direction,
                this.sort.active,
                this.paginator.pageIndex + 1,
                this.paginator.pageSize
            );
        } else {
            queryParams = this.lastQueryParams;
        }
        this.lastQueryParams = new QueryParamsModel({});
        this.store.dispatch(new AssetClassLandPageRequested({page: queryParams}));
    }
    private _updateFilter(filter: FilterModel) {
        this.filterModel = filter;
        this.filter$.next(filter)
    }
    private _showTrashed(items, isAdmin = false) {
        let _title = 'Deleted Land Comparables';
        if (isAdmin) {
            _title = 'Admin Deleted Land Comparables';
        }
        this.layoutService.trashedEntities(_title, items, this, isAdmin);
    }

    goMap() {
        this.router.navigate(['map'], {relativeTo: this.activatedRoute});
    }

    duplicate(id: number) {
        this.store.dispatch(new AssetClassLandDuplicate({id}))
    }
}