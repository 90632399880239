// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {IncludedInValuationActions, IncludedInValuationActionTypes} from '../_actions/included-in-valuation.actions';
// Models
import {IncludedInValuation} from '../_models/included-in-valuation.model';
import {QueryParamsModel} from '../../_base/crud';

export interface IncludedInValuationsState extends EntityState<IncludedInValuation> {
    isAllIncludedInValuationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: IncludedInValuation[];
    lastCreatedIncludedInValuationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<IncludedInValuation> = createEntityAdapter<IncludedInValuation>();

export const initialIncludedInValuationsState: IncludedInValuationsState = adapter.getInitialState({
    isAllIncludedInValuationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedIncludedInValuationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function includedInValuationsReducer(state = initialIncludedInValuationsState, action: IncludedInValuationActions): IncludedInValuationsState {
    switch (action.type) {
        case IncludedInValuationActionTypes.IncludedInValuationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedIncludedInValuationId: undefined
            };
        case IncludedInValuationActionTypes.IncludedInValuationsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case IncludedInValuationActionTypes.IncludedInValuationOnServerCreated:
            return {
                ...state
            };
        case IncludedInValuationActionTypes.IncludedInValuationCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedIncludedInValuationId: action.payload.item.id
            });
        case IncludedInValuationActionTypes.IncludedInValuationUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case IncludedInValuationActionTypes.AllIncludedInValuationsLoaded:
            return adapter.addAll(action.payload.includedInValuations, {
                ...state, isAllIncludedInValuationsLoaded: true
            });
        case IncludedInValuationActionTypes.IncludedInValuationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case IncludedInValuationActionTypes.IncludedInValuationsPageLoaded:
            return adapter.addMany(action.payload.includedInValuations, {
                ...initialIncludedInValuationsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case IncludedInValuationActionTypes.IncludedInValuationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case IncludedInValuationActionTypes.IncludedInValuationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case IncludedInValuationActionTypes.IncludedInValuationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case IncludedInValuationActionTypes.IncludedInValuationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case IncludedInValuationActionTypes.IncludedInValuationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case IncludedInValuationActionTypes.IncludedInValuationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
