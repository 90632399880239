import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatTableModule } from '@angular/material/table'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { NgbPopoverModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'

import { UserTooltipComponent } from "./user-tooltip/user-tooltip.component";
import { ReactiveFormsModule } from "@angular/forms";
import { DropdownMenuModule } from "./dropdown-menu/dropdown-menu.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    NgbDropdownModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    DropdownMenuModule
  ],
  declarations: [
    UserTooltipComponent,
  ],
  exports: [
    UserTooltipComponent,
  ]
})
export class MadSharedModule {}