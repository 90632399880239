// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
    selectTpAdminTasksInStore,
    selectTpAdminTasksPageLoading,
    selectTpAdminTasksShowInitWaitingMessage
} from '../_selectors/tp-admin-task.selectors';

export class TpAdminTasksDataSource extends BaseDataSource {

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectTpAdminTasksPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTpAdminTasksShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTpAdminTasksInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
