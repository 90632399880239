import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { TemplateRequest, TpTemplateModel } from "../_models";

export enum TpTemplateActionTypes {
    LOAD_TP_TEMPLATES = '[Tp Template List] Load TP Templates',
    TP_TEMPLATES_LOADED = '[Tp Template Effect] TP Templates Loaded',

    ADD_TP_TEMPLATE = '[Tp Template Edit] Add New Tp Template',
    TP_TEMPLATE_ADDED = '[Tp Tempalte Effect] New Tp Template Added',
     
    UPDATE_TP_TEMPLATE = '[Tp Template Edit] Update Tp Template',
    TP_TEMPLATE_UPDATED = '[Tp Template Effect] Tp Template Updated',

    DELETE_TP_TEMPLATE = '[Tp Template List] Delete Tp Template',
    TP_TEMPLATE_DELETED = '[Tp Template Effect] Tp Template Deleted',

    DUPLICATE_TP_TEMPLATE = '[Tp Template List] Duplicate Tp Template',
}

export class LoadTpTemplates implements Action {
    readonly type = TpTemplateActionTypes.LOAD_TP_TEMPLATES;
    constructor(public payload: {
        request: TemplateRequest
    }) {}
}

export class TpTemplatesLoaded implements Action {
    readonly type = TpTemplateActionTypes.TP_TEMPLATES_LOADED;
    constructor(public payload: {
        templates: TpTemplateModel[],
        total: number
    }) {}
}

export class AddTpTemplate implements Action {
    readonly type = TpTemplateActionTypes.ADD_TP_TEMPLATE;
    constructor(public payload: {
        template: TpTemplateModel
    }) {}
}

export class TpTemplateAdded implements Action {
    readonly type = TpTemplateActionTypes.TP_TEMPLATE_ADDED;
    constructor(public payload: {
        template: TpTemplateModel
    }) {}
}

export class UpdateTpTemplate implements Action {
    readonly type = TpTemplateActionTypes.UPDATE_TP_TEMPLATE;
    constructor(public payload: {
        id: number;
        template: TpTemplateModel
    }) {}
}

export class TpTemplateUpdated implements Action {
    readonly type = TpTemplateActionTypes.TP_TEMPLATE_UPDATED;
    constructor(public payload: {
        id: number;
        template: Update<TpTemplateModel>
    }) {}
}

export class DeleteTpTemplate implements Action {
    readonly type = TpTemplateActionTypes.DELETE_TP_TEMPLATE;
    constructor(public payload: {
        id: number,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export class TpTemplateDeleted implements Action {
    readonly type = TpTemplateActionTypes.TP_TEMPLATE_DELETED;
    constructor(public payload: {id: number}) {}
}

export class DuplicateTpTemplate implements Action {
    readonly type = TpTemplateActionTypes.DUPLICATE_TP_TEMPLATE;
    constructor(public payload: {
        template: TpTemplateModel,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export type TpTemplateActions
    = LoadTpTemplates
    | TpTemplatesLoaded
    | AddTpTemplate
    | TpTemplateAdded
    | UpdateTpTemplate
    | TpTemplateUpdated
    | DeleteTpTemplate
    | TpTemplateDeleted
    | DuplicateTpTemplate;