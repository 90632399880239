import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AssetClassRetailBuildingService } from "src/app/core/comparable/_services/asset-class-retail-building.service";

@Injectable()
export class AssetClassRetailBuildingGetResolver implements Resolve<any> {
    constructor(
        private assetClassRetailBuildingService: AssetClassRetailBuildingService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const id = Number(route.params.retailbuilding_id);
        return this.assetClassRetailBuildingService.getAssetClass(id);
    }
}