// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ReportDeliveryMethod} from '../_models/report-delivery-method.model';

export enum ReportDeliveryMethodActionTypes {

    AllReportDeliveryMethodsRequested = '[ReportDeliveryMethods Home Page] All ReportDeliveryMethods Requested',
    AllReportDeliveryMethodsLoaded = '[ReportDeliveryMethods API] All ReportDeliveryMethods Loaded',

    ReportDeliveryMethodOnServerCreated = '[Edit ReportDeliveryMethod Dialog] ReportDeliveryMethod On Server Created',
    ReportDeliveryMethodCreated = '[Edit ReportDeliveryMethods Dialog] ReportDeliveryMethods Created',
    ReportDeliveryMethodUpdated = '[Edit ReportDeliveryMethod Dialog] ReportDeliveryMethod Updated',
    ReportDeliveryMethodDeleted = '[ReportDeliveryMethods List Page] ReportDeliveryMethod Deleted',

    // trash system

    ReportDeliveryOnServerRestored = '[ReportDelivery Trash] ReportDelivery On Server Restored',
    ReportDeliveryRestored = '[ReportDelivery Trash] ReportDelivery Restored',

    ReportDeliveryOnServerAdminRestored = '[ReportDelivery Admin Trash] ReportDelivery On Server Restored',
    ReportDeliveryAdminRestored = '[ReportDelivery Admin Trash] ReportDelivery Restored',

    ReportDeliveryDeletedFromTrash = '[ReportDelivery Trash] ReportDelivery deleted',
    ReportDeliveryDeletedFromAdminTrash = '[ReportDelivery Admin Trash] ReportDelivery deleted',

    ReportDeliveryTrash = 'ReportDelivery Trashed',
    ReportDeliveryTrashFlushed = 'ReportDelivery Trash Flushed',

    // Page system

    ReportDeliveryMethodsPageRequested = '[ReportDeliveryMethods List Page] ReportDeliveryMethods Page Requested',
    ReportDeliveryMethodsPageLoaded = '[ReportDeliveryMethods API] ReportDeliveryMethods Page Loaded',
    ReportDeliveryMethodsPageCancelled = '[ReportDeliveryMethods API] ReportDeliveryMethods Page Cancelled',

    ReportDeliveryMethodsPageToggleLoading = '[ReportDeliveryMethods page] ReportDeliveryMethods Page Toggle Loading',
    ReportDeliveryMethodsActionToggleLoading = '[ReportDeliveryMethods] ReportDeliveryMethods Action Toggle Loading'
}

export class ReportDeliveryMethodOnServerCreated implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodOnServerCreated;

    constructor(public payload: { item: ReportDeliveryMethod }) {
    }
}

export class ReportDeliveryMethodCreated implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodCreated;

    constructor(public payload: { item: ReportDeliveryMethod }) {
    }
}

export class ReportDeliveryMethodUpdated implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodUpdated;

    constructor(public payload: {
        partialItem: Update<ReportDeliveryMethod>,
        item: ReportDeliveryMethod
    }) {
    }
}

export class ReportDeliveryMethodDeleted implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ReportDeliveryMethodsPageRequested implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ReportDeliveryMethodsPageLoaded implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageLoaded;

    constructor(public payload: { reportDeliveryMethods: ReportDeliveryMethod[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ReportDeliveryMethodsPageCancelled implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageCancelled;
}

export class AllReportDeliveryMethodsRequested implements Action {
    readonly type = ReportDeliveryMethodActionTypes.AllReportDeliveryMethodsRequested;
}

export class AllReportDeliveryMethodsLoaded implements Action {
    readonly type = ReportDeliveryMethodActionTypes.AllReportDeliveryMethodsLoaded;

    constructor(public payload: { reportDeliveryMethods: ReportDeliveryMethod[] }) {
    }
}

export class ReportDeliveryMethodsPageToggleLoading implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ReportDeliveryMethodsActionToggleLoading implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryMethodsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ReportDeliveryDeletedFromAdminTrash implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ReportDeliveryDeletedFromTrash implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ReportDeliveryOnServerRestored implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ReportDeliveryRestored implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryRestored;

    constructor(public payload: { item: ReportDeliveryMethod }) {
    }
}

export class ReportDeliveryOnServerAdminRestored implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ReportDeliveryAdminRestored implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryAdminRestored;

    constructor(public payload: { item: ReportDeliveryMethod }) {
    }
}

export class ReportDeliveryTrashFlushed implements Action {
    readonly type = ReportDeliveryMethodActionTypes.ReportDeliveryTrashFlushed;

    constructor() {
    }
}

export type ReportDeliveryMethodActions = ReportDeliveryMethodCreated
    | ReportDeliveryMethodUpdated
    | ReportDeliveryMethodDeleted
    | ReportDeliveryMethodsPageRequested
    | ReportDeliveryMethodsPageLoaded
    | ReportDeliveryMethodsPageCancelled
    | AllReportDeliveryMethodsLoaded
    | AllReportDeliveryMethodsRequested
    | ReportDeliveryMethodOnServerCreated
    
    | ReportDeliveryDeletedFromAdminTrash
    | ReportDeliveryDeletedFromTrash
    | ReportDeliveryOnServerRestored
    | ReportDeliveryRestored
    | ReportDeliveryOnServerAdminRestored
    | ReportDeliveryAdminRestored
    | ReportDeliveryTrashFlushed
    
    | ReportDeliveryMethodsPageToggleLoading
    | ReportDeliveryMethodsActionToggleLoading;
