import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectValuationProcessSelectedComparables } from 'src/app/core/valuation-process/_selectors';
import { selectValuationProcessConsiderationCriterion } from 'src/app/core/valuation-process/_selectors/consideration-criterion.selector';
import { selectValuationProcessAdjustments } from 'src/app/core/valuation-process/_selectors/adjustment.selectors';
import { selectValuationProcessDefaultSizeCriterion } from 'src/app/core/valuation-process/_selectors/size-criterion.selectors'
import { AdjustmentModel } from 'src/app/core/valuation-process/_models/valuation-process-adjustment.model';
import { ConsiderationCriterionModel, SizeCriterionModel } from 'src/app/core/valuation-process/_models/valuation-process-criterion.model';
import { AdjustmentsEditComponent } from 'src/app/views/pages/linked-tables/adjustments-edit/adjustments-edit.component';
import * as _ from 'lodash'
import { selectValuationProcessTargetPropertyUnitMeasurement } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';
import { selectValuationProcessValuationFullState, selectValuationProcessValuationState } from 'src/app/core/valuation-process/_selectors/valuation.selectors';
import { ValuationProcessValuationUpdateCapitalAllowance, ValuationProcessValuationUpdateCapitalAllowanceJustification, ValuationProcessValuationUpdateJustification, ValuationProcessValuationUpdateWeightingValue } from 'src/app/core/valuation-process/_actions/valuation.actions';
import { FormBuilder, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'kt-valuation-table-v2',
  templateUrl: './valuation-table-v2.component.html',
  styleUrls: ['./valuation-table-v2.component.scss']
})
export class ValuationTableV2Component implements OnInit {
  @Output() removeComparable: EventEmitter<{ refNum: string }> = new EventEmitter();
  @Output() showComparable: EventEmitter<{ refNum: string }> = new EventEmitter();
  @Output() showComparableOverview: EventEmitter<{ refNums: string[] }> = new EventEmitter();

  form: UntypedFormGroup
  totalPropertyValue = 0;

  viewModel$ = this.store$.select(selectValuationProcessValuationFullState).pipe(
    filter(state => {
      let hasValue = true
      state.refNums.forEach(refNum => {
        if (state.adjustedConsiderations[refNum] == undefined) {
          hasValue = false
        }
      })
      return hasValue
    }),
    map(state => {
      const weightFormArray = this.formBuilder.array([])
      state.refNums.forEach(refNum => {
        const control = this.formBuilder.control({value: state.weightings[refNum], disabled: false})
        weightFormArray.push(control)
      })
      this.form.setControl('weights', weightFormArray)
      this.form.controls['capital_allowance'].setValue(state.capitalAllowance)
      this.totalPropertyValue = state.tpValue
      return {
        ...state
      }
    })
  )

  // viewModel$ = combineLatest([
  //   this.store$.select(selectValuationProcessSelectedComparables),
  //   this.store$.select(selectValuationProcessDefaultSizeCriterion),
  //   this.store$.select(selectValuationProcessAdjustments),
  //   this.store$.select(selectValuationProcessConsiderationCriterion),
  //   this.store$.select(selectValuationProcessTargetPropertyUnitMeasurement),
  //   this.store$.select(selectValuationProcessValuationState)
  // ]).pipe(
  //   map(([refNums, defaultSize, adjustments, considerationCriterion, unitMeasurement, valuationState]) => {
  //     const totalAdjustments = computeTotalAdjustments(adjustments)
  //     const tenureRelatedInfo = this._tenureRelatedInfo(considerationCriterion)
  //     const tenureRelatedInfo2 = this._tenureRelatedInfo2(considerationCriterion)

  //     const adjustedConsiderationRowHeader = `Adjusted Consideration${tenureRelatedInfo} (${considerationCriterion.countryCurrency})`
  //     const adjustedConsiderations = computeAdjustedConsiderations(totalAdjustments, considerationCriterion)

  //     const adjustedGrossUnitRateRowHeader = `Adjusted Gross Unit Rate${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${unitMeasurement})`
  //     const adjustedGrossUnitRates = computeAdjustedGrossUnitRates(adjustedConsiderations, defaultSize)

  //     // this.form.setControl('capital_allowance', this.formBuilder.control(valuationState.capitalAllowance))

  //     const weightedAvgRowHeader = `Weighted Average Gross Unit Rate${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${unitMeasurement})`
  //     const weightedAvgRates = computeWeightedAvgRates(valuationState.weightings, adjustedGrossUnitRates)
  //     const totalWeighted = computeWeightedTotal(weightedAvgRates)

  //     const tpRowHeader = `Target Property${tenureRelatedInfo2} (${considerationCriterion.countryCurrency})`
  //     const tpValue = computeTPValue(totalWeighted, defaultSize.values['tp'])

  //     const capitalAllowanceRowHeader = `Capital Allowance (${considerationCriterion.countryCurrency})`
  //     this.totalPropertyValue = tpValue

  //     const totalTPRowHeader = `Total Target Property Value (${considerationCriterion.countryCurrency})`
  //     const totalTPWeightedHeader = `Total Target Property Value (${considerationCriterion.countryCurrency}/${unitMeasurement})`

  //     const totalTPValue = tpValue == null ? null : (tpValue + valuationState.capitalAllowance)
  //     const totalTPWeighted = totalTPValue == null 
  //       ? null 
  //       : defaultSize.values['tp'] == null || defaultSize.values['tp'] == 0
  //         ? null
  //         : totalTPValue / defaultSize.values['tp']
  //     return {
  //       refNums,
  //       totalAdjustments,

  //       adjustedConsiderationRowHeader,
  //       adjustedConsiderations,

  //       adjustedGrossUnitRateRowHeader,
  //       adjustedGrossUnitRates,

  //       weightings: valuationState.weightings,
  //       totalWeight: valuationState.totalWeighting,
  //       is100: valuationState.totalWeighting == 100,

  //       justification: valuationState.justification,

  //       weightedAvgRowHeader,
  //       weightedAvgRates,
  //       totalWeighted,

  //       tpRowHeader,
  //       tpValue,

  //       capitalAllowanceRowHeader,
  //       capitalAllowance: valuationState.capitalAllowance,
  //       capitalAllowanceJustification: valuationState.capitalAllowanceJustification,

  //       totalTPRowHeader,
  //       totalTPValue,
  //       totalTPWeightedHeader,
  //       totalTPWeighted
  //     }
  //   })
  // )


  constructor(
    private store$: Store<AppState>,
    private formBuilder: UntypedFormBuilder
  ) { 
    this.form = this.formBuilder.group({
      weights: this.formBuilder.array([]),
      capital_allowance: this.formBuilder.control(0),
    })
  }

  ngOnInit(): void {
  }

  onShowComparable(refNum: string) {
    this.showComparable.emit({ refNum })
  }
  onShowComparableOverview(refNums: string[]) {
    this.showComparableOverview.emit({ refNums })
  }
  onRemoveComparable(refNum: string) {
    this.removeComparable.emit({ refNum })
  }
  getWeightControl(index: number) {
    const weights = this.form.get('weights') as UntypedFormArray
    return weights.at(index)
  }

  onWeightPercentChange(refNum: string, val: number) {
    this.store$.dispatch(new ValuationProcessValuationUpdateWeightingValue({refNum, value: Number(val)}))
  }

  onWeightFocusOut(refNum: string, event: any) {
    const target = event.target as HTMLInputElement
    const val = target.value.substring(0, target.value.length - 1)
    this.store$.dispatch(new ValuationProcessValuationUpdateWeightingValue({refNum, value: Number(val)}))
  }

  onJustificationFocusOut(event: any) {
    const target = event.target as HTMLTextAreaElement 
    this.store$.dispatch(new ValuationProcessValuationUpdateJustification({value: target.value}))
  }

    onCapitalAllowanceFocusOut(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        const val = target.value;
        let num = val != '' ? this._thousandSeparated(val) : 0;
        if (num < 0 && Math.abs(num) >= this.totalPropertyValue) {
            num = 0;
        }
        this.store$.dispatch(new ValuationProcessValuationUpdateCapitalAllowance({value: num}))
    }

    onCapitalAllowanceJustFocusOut(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        const val = target.value;
        this.store$.dispatch(new ValuationProcessValuationUpdateCapitalAllowanceJustification({value: val}))
    }

    private _thousandSeparated(val: string): number {
        const joined = val.split(',').join('');
        return Number(joined);
    }
}

type Value = {[key: string]: number}

function computeTotalAdjustments(adjustments: AdjustmentModel[]): Value {
  return adjustments.reduce((acc, ad) => {
    Object.entries(ad.values).forEach(([k, v], _) => {
      const prev = acc[k]
      acc[k] = prev ? (prev + v) : v
    })
    return acc
  }, {})
}

function computeAdjustedConsiderations(totalAdjustment: Value, considerationCrit: ConsiderationCriterionModel): Value {
  return Object.entries(considerationCrit.values).reduce((acc, [k, v]) => {
    const adjustment = totalAdjustment[k] ? totalAdjustment[k] : 0
    acc[k] = computeAdjustedConsideration(adjustment, v.value) 
    return acc
  }, {})
}

function computeAdjustedConsideration(adjustment: number, consideration: number | null): number {
  if (consideration == null) {
    return 0
  }
  const val = consideration * adjustment
  const temp = val / 100
  return consideration + temp
}

function computeAdjustedGrossUnitRates(adjustedConsiderations: Value, sizes: SizeCriterionModel): Value {
  return Object.entries(sizes.values).reduce((acc, [k, v]) => {
    acc[k] = computeAdjustedGrossUnitRate(adjustedConsiderations[k], v)
    return acc
  }, {})
}

function computeAdjustedGrossUnitRate(adjustedConsideration: number, size: number | null): number {
  if (size == null || size == 0) {
    return null
  }
  return adjustedConsideration / size
}

function computeWeightedAvgRates(weighting: Value, grossUnitRates: Value): Value {
  return Object.entries(weighting).reduce((acc, [k, v]) => {
    acc[k] = computeWeightedAvgRate(v, grossUnitRates[k])
    return acc
  }, {})
}

function computeWeightedAvgRate(weight: number, grossUnitRate: number | null): number {
  if (grossUnitRate == null) {
    return null
  }

  const val = grossUnitRate * weight
  return val / 100
}

function computeWeightedTotal(weightedAvgRates: Value): number | null {
  return Object.values(weightedAvgRates).reduce((acc, v) => {
    if (v == null) {
      return null
    }
    if (acc == null) {
      return null
    }
    return acc + v
  }, 0)
}

function computeTPValue(totalWeighted: number | null, size: number | null): number | null {
  if (totalWeighted == null || size == null) {
    return null
  }
  return totalWeighted * size
}