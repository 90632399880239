// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    AgenciesPageLoaded,
    AgenciesPageRequested,
    AgenciesPageToggleLoading,
    AgencyActionToggleLoading,
    AgencyActionTypes,
    AgencyAdminRestored,
    AgencyCreated,
    AgencyDeleted,
    AgencyDeletedFromAdminTrash,
    AgencyDeletedFromTrash, AgencyOnServerAdminRestored,
    AgencyOnServerCreated,
    AgencyOnServerRestored,
    AgencyRestored,
    AgencyTrashFlushed,
    AgencyUpdated, AllAgenciesRequested
} from '../_actions/agency.actions';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {AgencyService} from '../_services/agency.service';

@Injectable()
export class AgencyEffects {

    showPageLoadingDispatcher = new AgenciesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AgencyActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AgencyActionToggleLoading({isLoading: false});

    @Effect()
    loadPageAgencies$ = this.actions$
        .pipe(
            ofType<AgenciesPageRequested>(AgencyActionTypes.AgenciesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.find(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map((response) => {

                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];

                return new AgenciesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            })
        );

    @Effect()
    loadAllAgencies$ = this.actions$
        .pipe(
            ofType<AllAgenciesRequested>(AgencyActionTypes.AllAgenciesRequested),
            mergeMap(() => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.getAll();
            }),
            map((response) => {
                this.store.dispatch(this.hideActionLoadingDispatcher);
                const result: any = response.data;
                return new AgenciesPageLoaded({
                    items: result,
                    totalCount: result.length,
                    totalTrashed: 0,
                    totalAdminTrashed: 0,
                    page: new QueryParamsModel([])
                });
            })
        );

    @Effect()
    deleteAgency$ = this.actions$
        .pipe(
            ofType<AgencyDeleted>(AgencyActionTypes.AgencyDeleted),
            mergeMap(({payload}) => {
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateAgency$ = this.actions$
        .pipe(
            ofType<AgencyUpdated>(AgencyActionTypes.AgencyUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAgency$ = this.actions$
        .pipe(
            ofType<AgencyOnServerCreated>(AgencyActionTypes.AgencyOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new AgencyCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAgencyTrash$ = this.actions$
        .pipe(
            ofType<AgencyTrashFlushed>(AgencyActionTypes.AgencyTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    restoreAgency$ = this.actions$
        .pipe(
            ofType<AgencyOnServerRestored>(AgencyActionTypes.AgencyOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AgencyRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAgency$ = this.actions$
        .pipe(
            ofType<AgencyOnServerAdminRestored>(AgencyActionTypes.AgencyOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AgencyAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteFromTrashAgency$ = this.actions$
        .pipe(
            ofType<AgencyDeletedFromTrash>(AgencyActionTypes.AgencyDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAgency$ = this.actions$
        .pipe(
            ofType<AgencyDeletedFromAdminTrash>(AgencyActionTypes.AgencyDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AgencyService, private store: Store<AppState>) {
    }
}
