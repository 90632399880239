import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllParkingAdjustmentsLoaded,
    AllParkingAdjustmentsRequested,
    ParkingAdjustmentActionToggleLoading,
    ParkingAdjustmentAdminRestored,
    ParkingAdjustmentCreated,
    ParkingAdjustmentDeleted,
    ParkingAdjustmentDeletedFromAdminTrash,
    ParkingAdjustmentDeletedFromTrash,
    ParkingAdjustmentOnServerAdminRestored,
    ParkingAdjustmentOnServerCreated,
    ParkingAdjustmentOnServerRestored,
    ParkingAdjustmentRestored,
    ParkingAdjustmentsActionTypes,
    ParkingAdjustmentsPageLoaded,
    ParkingAdjustmentsPageRequested,
    ParkingAdjustmentsPageToggleLoading,
    ParkingAdjustmentTrashFlushed,
    ParkingAdjustmentUpdated
} from '../_actions/parking-adjustment.actions';
import {allParkingAdjustmentsLoaded} from '../_selectors/parking-adjustment.selectors';
import {ParkingAdjustmentsService} from '../_services/parking-adjustment.service';

@Injectable()
export class ParkingAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: ParkingAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new ParkingAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ParkingAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ParkingAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllParkingAdjustmentsRequested>(ParkingAdjustmentsActionTypes.AllParkingAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allParkingAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllParkingAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentsPageRequested>(ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ParkingAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentUpdated>(ParkingAdjustmentsActionTypes.ParkingAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentOnServerCreated>(ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ParkingAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentTrashFlushed>(ParkingAdjustmentsActionTypes.ParkingAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentOnServerRestored>(ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ParkingAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentOnServerAdminRestored>(ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ParkingAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentDeleted>(ParkingAdjustmentsActionTypes.ParkingAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentDeletedFromTrash>(ParkingAdjustmentsActionTypes.ParkingAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<ParkingAdjustmentDeletedFromAdminTrash>(ParkingAdjustmentsActionTypes.ParkingAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}