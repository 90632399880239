import { NgModule } from "@angular/core";

import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { DatepickerTzInputComponent } from "./datepicker-tz-input/datepicker-tz-input.component";
import { TimepickerTzInputComponent } from './timepicker-tz-input/timepicker-tz-input.component';
import { OwlDateTimeModule } from "@busacca/ng-pick-datetime";

@NgModule({
  declarations: [
    DatepickerTzInputComponent,
    TimepickerTzInputComponent
  ],
  exports: [
    DatepickerTzInputComponent,
    TimepickerTzInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    OwlDateTimeModule
  ],
  providers: [
  ]
})
export class MadFormsModule {}