import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AssetClassModel, Link } from "src/app/core/asset_class";
import { Video } from "src/app/core/file-upload/_models/video.model";
import { ReportingProcessData } from "src/app/core/reporting/_models/reporting_process.model";
import {
    IPic,
    PicType,
} from "../reporting-settings/picture-selector/picture-selector.component";
import { PictureSelectorV2Component } from "./picture-selector-v2/picture-selector-v2.component";
import {
    VideoSelectorComponent,
    VidnMatterModel,
} from "./video-selector/video-selector.component";
import { ReportDesignModel } from "../../report-design-page/report-design.model";

@Component({
    selector: "kt-design-layout-v2",
    templateUrl: "./design-layout-v2.component.html",
    styleUrls: ["./design-layout-v2.component.scss"],
})
export class DesignLayoutV2Component implements OnInit {
    @Input()
    readonly: boolean;
    @Input()
    targetProperty: AssetClassModel;
    @Input()
    reporting: ReportDesignModel;
    @Input()
    selectedTpPics: { type: string; pic_id: number }[];

    selectedTpPicsAsIPicture: IPic[] = [];
    tpHasOnlyFeaturedPics: boolean = false;

    propertyPictures: IPic[] = [];
    buildingPictures: IPic[] = [];

    videoList: VidnMatterModel[] = [];
    matterportList: VidnMatterModel[] = [];

    public parentFormGroup: AbstractControl;

    leftRadio = 1;
    rightRadio = 1;

    picTypeProperty = PicType.Property;
    picTypeBuilding = PicType.Building;

    form: UntypedFormGroup;
    @ViewChild(PictureSelectorV2Component)
    pictureSelector: PictureSelectorV2Component;

    @ViewChild(VideoSelectorComponent)
    videoSelector: VideoSelectorComponent;

    displayPropertyFeatured$: Observable<boolean>;
    displayBuildingFeatured$: Observable<boolean>;

    hasSelectBuildingSection = false;

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            picture_location: [
                this.reporting.tp_pictures_all_or_custom,
                Validators.required,
            ],
            comparable_pictures: [
                this.reporting.comparable_pictures,
                Validators.required,
            ],
            report_cover: [
                this.reporting.valuation_cover_picture,
                Validators.required,
            ],
        });
        this.displayPropertyFeatured$ = this.form.controls.report_cover.valueChanges.pipe(
            startWith(this.form.controls.report_cover.value),
            map(val => val == 1)
        )
        this.displayBuildingFeatured$ = this.form.controls.report_cover.valueChanges.pipe(
            startWith(this.form.controls.report_cover.value),
            map(val => val == 2)
        )

        this.selectedTpPicsAsIPicture = this.selectedTpPics.map((pic) => {
            return {
                id: pic.pic_id,
                type: pic.type as PicType,
            };
        });
        if (this.targetProperty && ([1, 2, 3, 5].includes(this.targetProperty.type_id))) {
            this.hasSelectBuildingSection = true
        }
    }

    propertyPics(pPics: IPic[]) {
        this.propertyPictures = pPics;
    }

    buildingPics(bPics: IPic[]) {
        this.buildingPictures = bPics;
    }

    getVideos(videos: VidnMatterModel[]) {
        this.videoList = videos;
    }

    getMatterLinks(links: VidnMatterModel[]) {
        this.matterportList = links;
    }

    get selectedPics(): IPic[] {
        return this.pictureSelector
            ? this.pictureSelector.getSelectedPics()
            : [];
    }

    get designData(): any {
        var controls = this.form.controls;
        return {
            picture_location: controls["picture_location"].value,
            comparable_pictures: controls["comparable_pictures"].value,
            report_cover: controls["report_cover"].value,
            property_pics: this.propertyPictures,
            building_pics: this.buildingPictures,
            videos: this.videoList,
            links: this.matterportList,
        };
    }
}
