import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllAssetClassTypesRequested, AssetClassType, selectAllAssetClassTypes } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { AddAdjustmentTemplate, AdjustmentTemplateModel, IAdjustmentTemplate, UpdateAdjustmentTemplate } from 'src/app/core/template';
import { AdjustmentFormComponent } from '../adjustment-form/adjustment-form.component';

@Component({
  selector: 'kt-adjustment-template-body',
  templateUrl: './adjustment-template-body.component.html',
  styleUrls: ['./adjustment-template-body.component.scss']
})
export class AdjustmentTemplateBodyComponent implements OnInit, OnDestroy {
  @Input() template: AdjustmentTemplateModel;
  @Input() readonly: boolean;

  private _template: AdjustmentTemplateModel;
  formGroup: UntypedFormGroup;
  private _onDestroy$: Subject<void> = new Subject();

  public assetClassTypes: AssetClassType[] = [];
  public selectedAssetClassTypeId: number = undefined;

  public adjustments$: BehaviorSubject<IAdjustmentTemplate[]> = new BehaviorSubject([]);
  public atLeastOneAdjustmentError: boolean = false;

  public dataSource: MatTableDataSource<IAdjustmentTemplate> = new MatTableDataSource();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._template = Object.assign({}, this.template);
    this.selectedAssetClassTypeId = this._template.asset_class_type_id;
    if (this._template.adjustments.length > 0) {
      this.adjustments$.next(this._template.adjustments);
    }
    this._createForm();
    this._fetchData();
    this.adjustments$.pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (res.length > 0) {
        this.atLeastOneAdjustmentError = false;
        this.formGroup.get('asset_class_type').disable();
      } else {
        this.formGroup.get('asset_class_type').enable();
      }
      this.dataSource.data = res;
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _createForm() {
    this.formGroup = this.fb.group({
      name: [this._template.name, Validators.required],
      description: [this._template.description, Validators.required],
      asset_class_type: [this._template.asset_class_type_id, Validators.required]
    });
    const acTypeCtrl = this.formGroup.get('asset_class_type');
    acTypeCtrl.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      this.selectedAssetClassTypeId = val;
    })
  }
  private _fetchData() {
    this.store.dispatch(new AllAssetClassTypesRequested());
    this.store.select(selectAllAssetClassTypes).pipe(
      takeUntil(this._onDestroy$),
    ).subscribe(res => {
      this.assetClassTypes = [];
      if (res) {
        this.assetClassTypes = res
          .filter(item => item.id == 1 || item.id == 3 || item.id == 7 || item.id == 17 || item.id == 2 || item.id == 5 || item.id == 11 || item.id == 13)
          .map(item => {
            if (item.id == 13) {
              const _new = Object.assign({}, item);
              _new.name = 'Land';
              return _new;
            }
            return item;
          });
      }
    })
  }
  private _prepareTemplate(): AdjustmentTemplateModel {
    const template = new AdjustmentTemplateModel();
    template.clear();
    const controls = this.formGroup.controls;
    template.id = this._template.id;
    template.name = controls.name.value;
    template.description = controls.description.value;
    template.asset_class_type_id = controls.asset_class_type.value;
    template.adjustments = this.adjustments$.value;
    return template;
  }

  public onSubmit(complete: boolean): boolean {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      });
      return false;
    }
    if (this.adjustments$.value.length == 0) {
      this.atLeastOneAdjustmentError = true;
      return false;
    }
    const template = this._prepareTemplate();
    if (complete) {
      if (template.id === undefined) {
        this.store.dispatch(new AddAdjustmentTemplate({
          template: template
        }));
      } else {
        this.store.dispatch(new UpdateAdjustmentTemplate({
          id: template.id,
          template
        }));
      }
      return true;
    }
    return false
  }

  public addAdjustmentField() {
    const dialogRef = this.dialog.open(AdjustmentFormComponent, {
      data: {
        adjustment: {
          id: undefined,
          adjustment_id: undefined,
          name: null,
          description: null
        },
        selectedAssetClassType: this.selectedAssetClassTypeId,
        mode: 'new',
        selected: this.adjustments$.value
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      const currentAdjustments = this.adjustments$.value;
      currentAdjustments.push(res);
      this.adjustments$.next(currentAdjustments);
    })
  }
  public editAdjutment(adj: IAdjustmentTemplate, i: number) {
    const dialogRef = this.dialog.open(AdjustmentFormComponent, {
      data: {
        adjustment: adj,
        selectedAssetClassType: this.selectedAssetClassTypeId,
        mode: 'edit',
        selected: this.adjustments$.value
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      const currentAdjustments = this.adjustments$.value;
      currentAdjustments.splice(i, 1, res);
      this.adjustments$.next(currentAdjustments);
    })
  }
  public deleteAdjustment(i: number) {
    const currentAdjustments = this.adjustments$.value;
    currentAdjustments.splice(i, 1);
    this.adjustments$.next(currentAdjustments);
  }
}