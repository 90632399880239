<h2 class="example-h2 text-center">Percentage {{value}} %</h2>
<div>
    <div>
        <span class="text-small">-100%</span>
        <span class="text-small float-right">100%</span>
    </div>
    <mat-slider
            class="m-1 w-100"
            [max]="max"
            [min]="min"
            [step]="1"
            [thumbLabel]="true"
            [displayWith]="formatLabel"
            [(ngModel)]="value">
    </mat-slider>
</div>