import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { ValuationProcessAddCustomCriterion, ValuationProcessEditCustomCriterion, ValuationProcessEditDefaultCriterion } from 'src/app/core/valuation-process/_actions/criterion.actions';
import { categoryName, CriterionCategory, CriterionModel, CriterionValue, CustomCriterionModel } from 'src/app/core/valuation-process/_models/valuation-process-criterion.model';
// import { CriterionModel, CriterionType } from 'src/app/core/valuation';
// import { AddCriterion, UpdateCriterion } from 'src/app/core/valuation/_actions/criterion.actions';
// import { CriterionCategories, getCriterionCategories } from 'src/app/core/valuation/_models/criterion-categories.model';

export type CriterionEditModalInput = {
  refNums: string[],
  criterion: CriterionModel
}

@Component({
  selector: 'kt-criterion-edit-modal',
  templateUrl: './criterion-edit-modal.component.html',
  styleUrls: ['./criterion-edit-modal.component.scss']
})
export class CriterionEditModalComponent implements OnInit {
  criterionForm: UntypedFormGroup;
  // comparables: any[];
  refNums: string[]
  criterion: CriterionModel;
  criterionCategories = CriterionCategory;
  // categories = [
  //   'Characteristics',
  //   'Location',
  //   'Building',
  //   'Other'
  // ]
  isChanged: {[id: number]: boolean} = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CriterionEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CriterionEditModalInput,
    private store: Store<AppState>
  ) { 
    this.refNums = data.refNums
    if (data.criterion) {
      this.criterion = data.criterion;
    } else {
      this.criterion = {
        id: null,
        name: null,
        publicName: null,
        type: 'Custom',
        category: null,
        categoryName: null,
        values: {},
        isChanged: false
      }
      this.refNums.forEach((refNum) => {
        this.criterion.values[refNum] = {kind: 'simple', value: null} ;
      })
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  getComponentTitle(): string {
    if (this.criterion.id) {
      return 'Edit Criterion'
    }
    return 'Add Criterion'
  }

  get inputsFormArray(): UntypedFormGroup {
    return this.criterionForm.get('inputs') as UntypedFormGroup;
  }

  get categoryControl(): AbstractControl {
    return this.criterionForm.get('category');
  }

  comparableControl(comID: number): AbstractControl {
    const formArray = this.criterionForm.get('inputs') as UntypedFormGroup;
    return formArray.get(comID + '-cont')
}

  createForm() {
    this.criterionForm = this.formBuilder.group({
      name: new UntypedFormControl(this.criterion.name, Validators.required),
      category: new UntypedFormControl('' + this.criterion.category, Validators.required),
      categoryName: new UntypedFormControl(this.criterion.categoryName),
      inputs: this.createInputFormArray(),
    });

    const categoryControl = this.criterionForm.get('category');
    categoryControl.valueChanges.subscribe(res => {
      if (res === CriterionCategory.Other) {
        this.criterionForm.get('categoryName').patchValue('');
        this.criterionForm.get('categoryName').setValidators(Validators.required);
        this.criterionForm.get('categoryName').updateValueAndValidity();
      } else {
        const val = categoryName(Number(res));
        this.criterionForm.get('categoryName').patchValue(val);
      }
    })
  }

  createInputFormArray(): UntypedFormGroup {
    const inputArray = new UntypedFormGroup({});
    Object.entries(this.criterion.values).forEach(([key, value]: [string, CriterionValue]) => {
      this.isChanged[key] = false;
      let _value = null;
      if (value.kind == 'simple') {
        _value = value.value
      }
      const inputControl = new UntypedFormControl(_value, Validators.required);
      inputControl.valueChanges.subscribe(val => {
        if (val != _value) {
          this.isChanged[key] = true;
        } else {
          this.isChanged[key] = false;
        }
      })
      inputArray.setControl(key+'-cont', inputControl);
    });
    // this.criterion.comValues.forEach(val => {
    //   const inputControl = new FormControl(val, Validators.required);
    //   inputArray.push(inputControl);
    // });
    return inputArray;
  }

  onSubmit() {
    if (this.criterion.id) {
      this.editConsideration();
    } else {
      this.addConsideration();
    }
    this.dialogRef.close();
  }

  addConsideration() {
    const _name = this.criterionForm.get('name').value;
    const _categoryR = this.criterionForm.get('category').value;
    const _category = Number(_categoryR)
    let _categoryName = this.criterionForm.get('categoryName').value;
    if (_categoryName === undefined) {
      _categoryName = categoryName(_category);
    }
    const _inputs: {[id: number]: any} = {}
    Object.entries(this.inputsFormArray.controls).forEach(([key, control]) => {
      const id = key.substring(0, key.length - 5);
      // const _id = Number(id);
      _inputs[id] = {kind: 'simple', value: control.value};
    })
    _inputs['tp'] = {kind: 'simple', value: null}
    const _newCriterion: CriterionModel = {
      ...this.criterion,
      name: _name,
      category: Number(_category),
      categoryName: _categoryName,
      values: _inputs,
      type: 'Custom',
      publicName: _name
    }
    this.store.dispatch(new ValuationProcessAddCustomCriterion({criterion: _newCriterion}))
    // const _inputs = this.inputsFormArray.value;
    // this.store.dispatch(new AddCriterion({criterion: _newCriterion}));
  }

  editConsideration() {
    const _name = this.criterionForm.get('name').value;
    const _category = this.criterionForm.get('category').value;
    let _categoryName = this.criterionForm.get('categoryName').value;
    if (_categoryName === undefined) {
      _categoryName = categoryName(Number(_category));
    }
    const _inputs: {[id: number]: any} = {}
    Object.entries(this.inputsFormArray.controls).forEach(([key, control]) => {
      const id = key.substring(0, key.length - 5);
      // const _id = Number(id);
      _inputs[id] = {kind: 'simple', value: control.value};
    })
    const _newCriterion: CriterionModel = {
      ...this.criterion,
      name: _name,
      category: _category,
      categoryName: _categoryName,
      values: _inputs
    }
    // const _newCriterion = Object.assign({}, this.criterion) as CriterionModel;
    // _newCriterion.name = _name;
    // _newCriterion.category = _category;
    // _newCriterion.comValues = _inputs;
    // _newCriterion.categoryName = _categoryName;
    if (_newCriterion.type == 'Default') {
      let atLeastOneChanged = false;
      Object.entries(this.isChanged).forEach(([key, value]) => {
        if (value) {
          atLeastOneChanged = true;
        }
      })
      _newCriterion.isChanged = atLeastOneChanged;
    }
    if (_newCriterion.type == 'Custom') {
      this.store.dispatch(new ValuationProcessEditCustomCriterion({criterion: _newCriterion}))
    } else {
      this.store.dispatch(new ValuationProcessEditDefaultCriterion({criterion: _newCriterion}))
    }
    // this.store.dispatch(new UpdateCriterion({criterion: _newCriterion}));
  }

  onClose() {
    this.dialogRef.close();
  }

  checkEnum(val): boolean {
    return isNaN(val);
  }
}
