// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {QosDegradationActions, QosDegradationActionTypes} from '../_actions/qos-degradation.actions';
// Models
import {QosDegradation} from '../_models/qos-degradation.model';
import {QueryParamsModel} from '../../_base/crud';

export interface QosDegradationsState extends EntityState<QosDegradation> {
    isAllQosDegradationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: QosDegradation[];
    lastCreatedQosDegradationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<QosDegradation> = createEntityAdapter<QosDegradation>();

export const initialQosDegradationsState: QosDegradationsState = adapter.getInitialState({
    isAllQosDegradationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedQosDegradationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function qosDegradationsReducer(state = initialQosDegradationsState, action: QosDegradationActions): QosDegradationsState {
    switch (action.type) {
        case QosDegradationActionTypes.QosDegradationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedQosDegradationId: undefined
            };
        case QosDegradationActionTypes.QosDegradationsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case QosDegradationActionTypes.QosDegradationOnServerCreated:
            return {
                ...state
            };
        case QosDegradationActionTypes.QosDegradationCreated:
            return adapter.addOne(action.payload.qosDegradation, {
                ...state, lastCreatedQosDegradationId: action.payload.qosDegradation.id
            });
        case QosDegradationActionTypes.QosDegradationUpdated:
            return adapter.updateOne(action.payload.partialQosDegradation, state);
        case QosDegradationActionTypes.AllQosDegradationsLoaded:
            return adapter.addAll(action.payload.qosDegradations, {
                ...state, isAllQosDegradationsLoaded: true
            });
        case QosDegradationActionTypes.QosDegradationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case QosDegradationActionTypes.QosDegradationsPageLoaded:
            return adapter.addMany(action.payload.qosDegradations, {
                ...initialQosDegradationsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case QosDegradationActionTypes.QosDegradationDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case QosDegradationActionTypes.QosDegradationRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case QosDegradationActionTypes.QosDegradationDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case QosDegradationActionTypes.QosDegradationAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case QosDegradationActionTypes.QosDegradationDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case QosDegradationActionTypes.QosDegradationTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
