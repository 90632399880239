// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ParcelCharacteristicActions, ParcelCharacteristicActionTypes} from '../_actions/parcel-characteristic.actions';
// Models
import {ParcelCharacteristic} from '../_models/parcel-characteristic.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ParcelCharacteristicsState extends EntityState<ParcelCharacteristic> {
    isAllParcelCharacteristicsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ParcelCharacteristic[];
    lastCreatedParcelCharacteristicId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ParcelCharacteristic> = createEntityAdapter<ParcelCharacteristic>();

export const initialParcelCharacteristicsState: ParcelCharacteristicsState = adapter.getInitialState({
    isAllParcelCharacteristicsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedParcelCharacteristicId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function parcelCharacteristicsReducer(state = initialParcelCharacteristicsState, action: ParcelCharacteristicActions): ParcelCharacteristicsState {
    switch (action.type) {
        case ParcelCharacteristicActionTypes.ParcelCharacteristicsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedParcelCharacteristicId: undefined
            };
        case ParcelCharacteristicActionTypes.ParcelCharacteristicsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerCreated:
            return {
                ...state
            };
        case ParcelCharacteristicActionTypes.ParcelCharacteristicCreated:
            return adapter.addOne(action.payload.parcelCharacteristic, {
                ...state, lastCreatedParcelCharacteristicId: action.payload.parcelCharacteristic.id
            });
        case ParcelCharacteristicActionTypes.ParcelCharacteristicUpdated:
            return adapter.updateOne(action.payload.partialParcelCharacteristic, state);
        case ParcelCharacteristicActionTypes.AllParcelCharacteristicsLoaded:
            return adapter.addAll(action.payload.parcelCharacteristics, {
                ...state, isAllParcelCharacteristicsLoaded: true
            });
        case ParcelCharacteristicActionTypes.ParcelCharacteristicsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ParcelCharacteristicActionTypes.ParcelCharacteristicsPageLoaded:
            return adapter.addMany(action.payload.parcelCharacteristics, {
                ...initialParcelCharacteristicsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case ParcelCharacteristicActionTypes.ParcelCharacteristicDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case ParcelCharacteristicActionTypes.ParcelCharacteristicRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case ParcelCharacteristicActionTypes.ParcelCharacteristicDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case ParcelCharacteristicActionTypes.ParcelCharacteristicAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case ParcelCharacteristicActionTypes.ParcelCharacteristicDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case ParcelCharacteristicActionTypes.ParcelCharacteristicTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
