// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AssetClassValuationGroupsService} from '../_services/asset-class-valuation-group.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allAssetClassValuationGroupsLoaded} from '../_selectors/asset-class-valuation-group.selectors';
// Actions
import {
    AllAssetClassValuationGroupsLoaded,
    AllAssetClassValuationGroupsRequested,
    AssetClassValuationGroupActionTypes,
    AssetClassValuationGroupsPageRequested,
    AssetClassValuationGroupsPageLoaded,
    AssetClassValuationGroupUpdated,
    AssetClassValuationGroupsPageToggleLoading,
    AssetClassValuationGroupDeleted,
    AssetClassValuationGroupOnServerCreated,
    AssetClassValuationGroupCreated,
    AssetClassValuationGroupsActionToggleLoading
} from '../_actions/asset-class-valuation-group.actions';

@Injectable()
export class AssetClassValuationGroupEffects {
    showPageLoadingDispatcher = new AssetClassValuationGroupsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassValuationGroupsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassValuationGroupsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAssetClassValuationGroups$ = this.actions$
        .pipe(
            ofType<AllAssetClassValuationGroupsRequested>(AssetClassValuationGroupActionTypes.AllAssetClassValuationGroupsRequested),
            withLatestFrom(this.store.pipe(select(allAssetClassValuationGroupsLoaded))),
            filter(([action, isAllAssetClassValuationGroupsLoaded]) => !isAllAssetClassValuationGroupsLoaded),
            mergeMap(() => this.service.getAllAssetClassValuationGroups()),
            map(res => {
                return new AllAssetClassValuationGroupsLoaded({assetClassValuationGroups: res.data});
            })
        );

    @Effect()
    loadAssetClassValuationGroupsPage$ = this.actions$
        .pipe(
            ofType<AssetClassValuationGroupsPageRequested>(AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findAssetClassValuationGroups(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AssetClassValuationGroupsPageLoaded({
                    assetClassValuationGroups: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteAssetClassValuationGroup$ = this.actions$
        .pipe(
            ofType<AssetClassValuationGroupDeleted>(AssetClassValuationGroupActionTypes.AssetClassValuationGroupDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAssetClassValuationGroup(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateAssetClassValuationGroup$ = this.actions$
        .pipe(
            ofType<AssetClassValuationGroupUpdated>(AssetClassValuationGroupActionTypes.AssetClassValuationGroupUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAssetClassValuationGroup(payload.assetClassValuationGroup);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAssetClassValuationGroup$ = this.actions$
        .pipe(
            ofType<AssetClassValuationGroupOnServerCreated>(AssetClassValuationGroupActionTypes.AssetClassValuationGroupOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createAssetClassValuationGroup(payload.assetClassValuationGroup).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassValuationGroupCreated({assetClassValuationGroup: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AssetClassValuationGroupsService, private store: Store<AppState>) {
    }
}
