// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ComparativeLandUsesService} from '../_services/comparative-land-use.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allComparativeLandUsesLoaded} from '../_selectors/comparative-land-use.selectors';
// Actions
import {
    AllComparativeLandUsesLoaded,
    AllComparativeLandUsesRequested,
    ComparativeLandUseActionTypes,
    ComparativeLandUsesPageRequested,
    ComparativeLandUsesPageLoaded,
    ComparativeLandUseUpdated,
    ComparativeLandUsesPageToggleLoading,
    ComparativeLandUseDeleted,
    ComparativeLandUseOnServerCreated,
    ComparativeLandUseCreated,
    ComparativeLandUseAdminRestored,
    ComparativeLandUseDeletedFromAdminTrash,
    ComparativeLandUseDeletedFromTrash,
    ComparativeLandUseOnServerAdminRestored,
    ComparativeLandUseOnServerRestored,
    ComparativeLandUseRestored,
    ComparativeLandUseTrashFlushed,
    ComparativeLandUsesActionToggleLoading
} from '../_actions/comparative-land-use.actions';

@Injectable()
export class ComparativeLandUseEffects {
    showPageLoadingDispatcher = new ComparativeLandUsesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ComparativeLandUsesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ComparativeLandUsesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllComparativeLandUses$ = this.actions$
        .pipe(
            ofType<AllComparativeLandUsesRequested>(ComparativeLandUseActionTypes.AllComparativeLandUsesRequested),
            withLatestFrom(this.store.pipe(select(allComparativeLandUsesLoaded))),
            filter(([action, isAllComparativeLandUsesLoaded]) => !isAllComparativeLandUsesLoaded),
            mergeMap(() => this.service.getAllComparativeLandUses()),
            map(res => {
                return new AllComparativeLandUsesLoaded({comparativeLandUses: res.data});
            })
        );

    @Effect()
    loadComparativeLandUsesPage$ = this.actions$
        .pipe(
            ofType<ComparativeLandUsesPageRequested>(ComparativeLandUseActionTypes.ComparativeLandUsesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findComparativeLandUses(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ComparativeLandUsesPageLoaded({
                    comparativeLandUses: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseDeleted>(ComparativeLandUseActionTypes.ComparativeLandUseDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteComparativeLandUse(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseUpdated>(ComparativeLandUseActionTypes.ComparativeLandUseUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateComparativeLandUse(payload.comparativeLandUse);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseOnServerCreated>(ComparativeLandUseActionTypes.ComparativeLandUseOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createComparativeLandUse(payload.comparativeLandUse).pipe(
                    tap(res => {
                        this.store.dispatch(new ComparativeLandUseCreated({comparativeLandUse: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushComparativeLandUseTrash$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseTrashFlushed>(ComparativeLandUseActionTypes.ComparativeLandUseTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseOnServerRestored>(ComparativeLandUseActionTypes.ComparativeLandUseOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ComparativeLandUseRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseOnServerAdminRestored>(ComparativeLandUseActionTypes.ComparativeLandUseOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ComparativeLandUseAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseDeletedFromTrash>(ComparativeLandUseActionTypes.ComparativeLandUseDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashComparativeLandUse$ = this.actions$
        .pipe(
            ofType<ComparativeLandUseDeletedFromAdminTrash>(ComparativeLandUseActionTypes.ComparativeLandUseDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ComparativeLandUsesService, private store: Store<AppState>) {
    }
}
