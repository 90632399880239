import { StraightLineMethodData } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/straight-line-method-types";
import { AssetClassTenure } from "../../comparable";
import { calculateHeadlineRent, durationCalculation, roundTo4Decimals } from "./utils";

export function calculateStraightLine(consideration: AssetClassTenure, size: number) {
  const rent_free_period = durationCalculation(consideration.rent_free_period, consideration.duration_type)
  const fitting_out_period = durationCalculation(consideration.fitting_out_period, consideration.duration_type)
  const total_consideration = consideration.total_consideration
  const cap_payment = consideration.cap_payment
  const headlineRent = calculateHeadlineRent(total_consideration, consideration.rent_input_amount_type, consideration.rent_basis_id, size)
  const receivedFor = Number(consideration.lease_duration) - rent_free_period;
  const capitalValueOfHeadlineRent = headlineRent * receivedFor;
  const capitalValueOfInducements = capitalValueOfHeadlineRent - cap_payment;
  const spreadOver = Number(consideration.lease_duration) - fitting_out_period;
  const effectiveRent = capitalValueOfInducements / spreadOver;
  const result = {
    headlineRent, receivedFor, capitalValueOfHeadlineRent, capitalValueOfInducements, spreadOver, effectiveRent
  };
  const rounded = roundSLMethod(result);
  return convertNanToUndefinedSLMethod(rounded);
}

function roundSLMethod(val: StraightLineMethodData): StraightLineMethodData {
  const res = Object.assign({}, val) as StraightLineMethodData;
  Object.entries(val).forEach(([field, value]) => {
    res[field] = roundTo4Decimals(value);
  })
  return res;
}

function convertNanToUndefinedSLMethod(data: StraightLineMethodData): StraightLineMethodData {
  const res = Object.assign({}, data) as StraightLineMethodData;
  Object.entries(data).forEach(([field, value]) => {
    res[field] = Number.isNaN(value) ? undefined : value;
  });
  return res;
}