import {MeasurementMethodology} from '../_models/measurement-methodology.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {MeasurementMethodologiesState} from '../_reducers/measurement-methodology.reducers';
import * as fromMeasurementMethodology from '../_reducers/measurement-methodology.reducers';
import {each} from 'lodash';

export const selectMeasurementMethodologiesState = createFeatureSelector<MeasurementMethodologiesState>('measurementMethodologies');

export const selectMeasurementMethodologyById = (measurementMethodologyId: number) => createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => measurementMethodologiesState.entities[measurementMethodologyId]
);

export const selectAllMeasurementMethodologies = createSelector(
    selectMeasurementMethodologiesState,
    fromMeasurementMethodology.selectAll
);

export const selectAllMeasurementMethodologiesIds = createSelector(
    selectMeasurementMethodologiesState,
    fromMeasurementMethodology.selectIds
);

export const allMeasurementMethodologiesLoaded = createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => measurementMethodologiesState.isAllMeasurementMethodologiesLoaded
);


export const selectMeasurementMethodologiesPageLoading = createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => measurementMethodologiesState.listLoading
);

export const selectMeasurementMethodologiesActionLoading = createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => measurementMethodologiesState.actionLoading
);

export const selectLastCreatedMeasurementMethodologyId = createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => measurementMethodologiesState.lastCreatedMeasurementMethodologyId
);

export const selectMeasurementMethodologiesShowInitWaitingMessage = createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => measurementMethodologiesState.showInitWaitingMessage
);

export const selectTrashedMeasurementMethodologyCount = createSelector(
    selectMeasurementMethodologiesState,
    (measurementMethodologiesState) => measurementMethodologiesState.totalTrashed
);

export const selectAdminTrashedMeasurementMethodologyCount = createSelector(
    selectMeasurementMethodologiesState,
    (measurementMethodologiesState) => measurementMethodologiesState.totalAdminTrashed
);


export const selectMeasurementMethodologiesQueryResult = createSelector(
    selectMeasurementMethodologiesState,
    measurementMethodologiesState => {
        const items: MeasurementMethodology[] = [];
        each(measurementMethodologiesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: MeasurementMethodology[] = httpExtension.sortArray(items, measurementMethodologiesState.lastQuery.sortField, measurementMethodologiesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, measurementMethodologiesState.totalCount, '', measurementMethodologiesState.totalTrashed);
    }
);
