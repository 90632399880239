<div>
    <div ktPortletTitle>
        <h5 class="my-3 text-mad text-uppercase">Considerations</h5>
        <hr>
    </div>

    <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
        <button class="btn btn-primary"
            (click)="addConsiderationV2()"
            type="button"
            color="primary">
            Add Consideration
        </button>
    </div>

    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>
                Date
            </mat-header-cell>
            <!-- <mat-cell *matCellDef="let item">
                {{item.date | date:'dd MMM yyyy'}} {{timezoneOffset && ('(GMT ' + timezoneOffset +')')}}
            </mat-cell> -->
            <mat-cell *matCellDef="let item">
                {{item.date | date:'dd MMM yyyy'}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef>
                Source
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.source}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tenure">
            <mat-header-cell *matHeaderCellDef>
                Tenure
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.tenure}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="consideration">
            <mat-header-cell *matHeaderCellDef>
                Consideration
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.consideration | mask: 'separator':{thousandSeparator: ','} }} {{item.currency}} {{item.consideration_type_id == 2 ? '(per month)' : ''}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="additional_consideration">
            <mat-header-cell *matHeaderCellDef>
                Additional Consideration
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
                <ng-container *ngIf="item.hasAdditional">
                    {{item.additional_consideration | mask: 'separator':{thousandSeparator: ','} }} {{item.currency}} (x{{item.num}})
                </ng-container>
                <ng-container *ngIf="!item.hasAdditional">
                    N/A
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>
                Action
            </mat-header-cell>
            <mat-cell *matCellDef="let item; let i = index">
                <button mat-icon-button color="primary"
                    (click)="editConsideration(item.con, i)">
                    <mat-icon>create</mat-icon>
                </button>
                <button mat-icon-button color="warn"
                    (click)="deleteConsideration(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
    </mat-table>
</div>