import {BaseModel} from '../../_base/crud';

export class AssetClassDepartureModel extends BaseModel {
    id: number;
    asset_class_id: number;
    asset_class_name: string;
    departure_id: number;
    departure_name: string;
    valuation_standard_id: number;
    valuation_standard_name: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.asset_class_id = undefined;
        this.asset_class_name = '';
        this.departure_id = undefined;
        this.valuation_standard_id = undefined;
        this.departure_name = '';
        this.valuation_standard_name = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}