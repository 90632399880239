<div class="col-md-12" *ngIf="selectedNotToERequiredSize$ | async">
    <div style="display: flex; flex-direction: row; align-items: center;">
        <mat-icon style="height: 30px; color: #F3EC24; font-size: 30px !important; margin-right: 10px;">warning</mat-icon>
        <p style="color: red; margin-bottom: 0;">Please be mindful that you have selected a different measurement standard in the valuation process than the one initially prescribed in the T.O.E. Assumption and / or special assumption are required</p>
    </div>
</div>

<div class="card card-custom section-margin-bottom-60">
    <div class="card-header assumption-header">
        <div class="card-title">
            <div class="card-label">
                <span>Assumptions, Special Assumptions & Departures</span>
            </div>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.ASSUMPTIONS.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.ASSUMPTIONS.TITLE'|translate">
                help
            </mat-icon>
        </div>
        <div class="card-toolbar">
            <div *ngIf="!readonly" ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline">
                <a ngbDropdownToggle href="javascript:;"
                    class="btn btn btn-label-brand btn-bold btn-sm"
                    style="cursor: pointer !important;">
                    Add
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right" style="width: 200px;">
                    <ul class="navi navi-hover">
                        <li class="navi-item">
                            <a href="javascript:;" class="navi-link" (click)="addAssumptionOrDeparture(type.DefaultAssumption)">
                                <span class="navi-text">Assumption</span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a href="javascript:;" class="navi-link" (click)="addAssumptionOrDeparture(type.SpecialAssumption)">
                                <span class="navi-text">Special Assumption</span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a href="javascript:;" class="navi-link" (click)="addAssumptionOrDeparture(type.Departure)">
                                <span class="navi-text">Departure</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body assumption-body">
        <div class="col-md-12 mat-table-wrapper">
            <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.type === type.DefaultAssumption 
                            ? 'Assumption' 
                            : (item.type === type.SpecialAssumption
                                ? 'Special Assumption'
                                : 'Departure')}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef>Source</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.source === source.ToE 
                            ? 'ToE' 
                            : 'New input'}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="input">
                    <th mat-header-cell *matHeaderCellDef>Input</th>
                    <td mat-cell *matCellDef="let item">
                        <span *ngIf="item.type !== type.Departure; else departure">
                            {{item.input}}
                        </span>
                        <ng-template #departure >
                            <span *ngIf="item.input | split:'|' as splits">
                                <strong>{{splits[0]}}</strong> - {{splits[1]}}
                            </span>
                        </ng-template>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="input-status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.status === status.Valid 
                            ? 'Valid' 
                            : (item.status === status.Amended
                                ? 'Amended'
                                : 'Removed')}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="justification">
                    <th mat-header-cell *matHeaderCellDef>Justification for deletion or change</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.justification ? item.justification : 'N/A'}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let item">
                        <button [disabled]="readonly" mat-icon-button color="primary" (click)="edit(item)">
                            <mat-icon>create</mat-icon>
                        </button>
                        <button [disabled]="readonly" mat-icon-button *ngIf="item.source === source.ToE && item.status !== status.Valid" (click)="restore(item)">
                            <mat-icon [style.color]="'green'">refresh</mat-icon>
                        </button>
                        <button 
                            [disabled]="readonly"
                            *ngIf="item.status !== status.Removed"
                            mat-icon-button 
                            color="warn"
                            (click)="remove(item)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns; let i = index"
                    [ngClass]="{'colored-row': i%2}"></tr>
            </table>
            <div class="mat-table-message" *ngIf="!dataSource.data.length">
                No data
            </div>
        </div>
    </div>
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">assumptions, special assumptions & departures</h5>
    <hr class="active">
</div>

<div class="col-md-12 d-flex justify-content-end">
    
</div> -->

