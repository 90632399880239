import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { combineLatest } from "rxjs";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { AssetClassDetailService } from "../../asset_class/_services/asset-class-detail.service";
import { AssetClassResidentialsService } from "../../comparable";
import { AppState } from "../../reducers";
import { AddComparable, AddComparables, AddCriterion, ChangeComparableConsideration, ComparableAdded, ComparableConsiderationChanged, ComparableRemoved, CreateCriterions, CriteriaDataLoaded, CriterionActionTypes, CriterionActivated, CriterionAdded, CriterionDeactivated, CriterionEmptyAction, CriterionResetted, CriterionsCreated, CriterionsFromTemplateInserted, CriterionsLoading, CriterionUpdated, InsertCriterionsFromTemplate, RemoveComparable, ResetCriterion, SetCriterionActive, SetCriterionDeactive, UpdateConsiderationCriterion, UpdateCriterion } from "../_actions/criterion.actions";
import { CriterionType } from "../_models/criterion-type.model";
import { CriterionModel } from "../_models/criterion.model";
import { selectAllCriterions, selectLastCreatedCriterionID, selectSingleCriterion} from "../_selectors/criterion.selectors";
import { CriterionService } from "../_services/criterion.service";
import * as valuationActions from '../_actions/valuation.actions';
import { ConsiderationCriterionService } from "../_services/consideration-criterion.service";
import { AgencyCriteriaService } from "../../admin/_services/agency-criteria.service";
import { selectAllValTenures } from "../_selectors/valuation-data.selector";
import * as valuationDataActions from '../_actions/valuation-data.actions';

@Injectable()
export class CriterionEffects {
    constructor(
        private actions$: Actions,
        private service: CriterionService,
        private acDetService: AssetClassDetailService,
        private acResidentialService: AssetClassResidentialsService,
        private store: Store<AppState>,
        private considerationCriterionService: ConsiderationCriterionService,
        private agencyCriteriaService: AgencyCriteriaService,
    ) {}

    @Effect()
    createCriterions$ = this.actions$.pipe(
        ofType<CreateCriterions>(CriterionActionTypes.CreateCriterions),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.agencyCriteriaService.getAllDefault(),
            ])),
        map(([payload, criteria]) => {
            const criterions = this.service.createCriterions(payload.assetClass, criteria, payload.valuation);
            return new CriterionsCreated({
                criterions: criterions
            });
        })
    )

    @Effect()
    insertCriterionsFromTemplate$ = this.actions$.pipe(
        ofType<InsertCriterionsFromTemplate>(CriterionActionTypes.InsertCriterionsFromTemplate),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectAllCriterions), take(1)),
            ]) 
        ),
        map(([payload, crits]) => {
            const criterions = this.service.insertCriterionsFromTemplate(
                payload.assetClass,
                payload.comparables,
                payload.defaultCriterions,
                payload.customCriterions,
                crits
            );
            return new CriterionsFromTemplateInserted({
                criterions,
                lastCreatedId: criterions[criterions.length - 1].id
            });
        })
    )

    @Effect()
    criteriaDataLoaded$ = this.actions$.pipe(
        ofType<CriteriaDataLoaded>(CriterionActionTypes.CriteriaDataLoaded),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload.criterions),
                this.store.pipe(select(selectAllCriterions), take(1)),
                of(payload.comparables),
                of(payload.type_id)
            ])
        ),
        map(([oldCriterions, newCriterions, comparables, type_id]) => {
            const criterions: CriterionModel[] = [];
            newCriterions.forEach(nc => {
                const oc = oldCriterions.find(c => c.id === nc.id)
                if (oc) {
                    let noc = CriterionModel.copyWithChanged(oc, nc)
                    // noc = this.service._addComs(comparables, noc, type_id)
                    noc.active = oc.active;
                    noc.canRemove = nc.canRemove;
                    criterions.push(noc);
                } else {
                    let noc1 = CriterionModel.copy(nc)
                    noc1 = this.service._addComs(comparables, noc1, type_id)
                    noc1.active = nc.active;
                    noc1.canRemove = nc.canRemove;
                    criterions.push(noc1);
                }
            })
            // oldCriterions.forEach(oc => {
            //     const nc = newCriterions.find(c => c.id === oc.id);
            //     if (nc) {
            //         let noc = CriterionModel.copyWithChanged(oc, nc);
            //         noc = this.service._addComs(comparables, noc, type_id)
            //         if (nc.active) {
            //             noc.active = nc.active;
            //         } else {
            //             noc.active = oc.active;
            //         }
            //         noc.canRemove = nc.canRemove;
            //         criterions.push(noc);
            //     } else {
            //         criterions.push(oc);
            //     }
            // })
            return new ComparableAdded({
                criterions,
            });
        })
    )

    @Effect()
    addComparable$ = this.actions$.pipe(
        ofType<AddComparable>(CriterionActionTypes.AddComparable),
        map(({payload}) => payload),
        mergeMap(res => 
            combineLatest([
                of(res), 
                this.store.pipe(select(selectAllCriterions), take(1)),
            ])
        ),
        // take(1),
        map(([payload, criterions]) => {
            const res = this.service.addComparable(payload.comparable, payload.selectedTenure, criterions, payload.targetProperty, payload.valuation);
            return new ComparableAdded({
                criterions: res,
            });
        })
    )

    @Effect()
    addComparables$ = this.actions$.pipe(
        ofType<AddComparables>(CriterionActionTypes.AddComparables),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload), 
                this.store.pipe(select(selectAllCriterions), take(1)),
            ])
        ),
        // take(1),
        map(([payload, criterions]) => {
            const res = this.service.addComparables(payload.comparables, payload.tenures, criterions, payload.targetProperty, payload.valuation);
            return new ComparableAdded({
                criterions: res,
            });
        })
    )

    @Effect()
    removeComparable$ = this.actions$.pipe(
        ofType<RemoveComparable>(CriterionActionTypes.RemoveComparable),
        map(({payload}) => payload.comparableID),
        mergeMap(res => 
            combineLatest([
                of(res), 
                this.store.pipe(select(selectAllCriterions), take(1))]
        )),
        map(([comparableID, criterions]) => {
            const res = this.service.removeComparable(comparableID, criterions);
            return new ComparableRemoved({
                criterions: res
            });
        })
    )

    @Effect()
    setCriterionActive$ = this.actions$.pipe(
        ofType<SetCriterionActive>(CriterionActionTypes.SetCriterionActive),
        map(({payload}) => {
            const _changedCriterion: Update<CriterionModel> = {
                id: payload.criterion.id,
                changes: {
                    active: true
                }
            }
            return new CriterionActivated({
                criterion: _changedCriterion
            })
        })
    )

    @Effect()
    setCriterionDeactive$ = this.actions$.pipe(
        ofType<SetCriterionDeactive>(CriterionActionTypes.SetCriterionDeactive),
        map(({payload}) => {
            const _changedCriterion: Update<CriterionModel> = {
                id: payload.criterion.id,
                changes: {
                    active: false
                }
            }
            return new CriterionDeactivated({
                criterion: _changedCriterion
            })
        })
    )

    @Effect()
    updateCriterion$ = this.actions$.pipe(
        ofType<UpdateCriterion>(CriterionActionTypes.UpdateCriterion),
        map(({payload}) => {
            const _changedCriterion: Update<CriterionModel> = {
                id: payload.criterion.id,
                changes: {
                    ...payload.criterion
                }
            }

            return new CriterionUpdated({
                criterion: _changedCriterion
            })
        })
    )

    @Effect()
    considerationCriterionUpdated$ = this.actions$.pipe(
        ofType<CriterionUpdated>(CriterionActionTypes.CriterionUpdated),
        map(({payload}) => {
            if (payload.criterion.changes.type === CriterionType.Consideration) {
                return new valuationActions.UpdateValuationsExternalData();
            }
            return new CriterionEmptyAction();
        })
    )

    @Effect()
    addCriterion$ = this.actions$.pipe(
        ofType<AddCriterion>(CriterionActionTypes.AddCriterion),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload.criterion), 
                this.store.pipe(select(selectLastCreatedCriterionID), take(1))])),
        map(([criterion, id]) => {
            const _criterion = this.service.addCriterion(criterion, id);
            return new CriterionAdded({
                criterion: _criterion
            })
        })
    )

    @Effect()
    resetCriterion$ = this.actions$.pipe(
        ofType<ResetCriterion>(CriterionActionTypes.ResetCriterion),
        map(({payload}) => {
            const newCriterion = this.service.resetCriterion(payload.criterion, payload.comparables, payload.ac);
            const _criterion: Update<CriterionModel> = {
                id: newCriterion.id,
                changes: {
                    ...newCriterion,
                    isChanged: false
                }
            };
            return new CriterionResetted({
                criterion: _criterion
            })
        })
    )

    @Effect()
    changeComparableConsideration$ = this.actions$.pipe(
        ofType<ChangeComparableConsideration>(CriterionActionTypes.ChangeComparableConsideration),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectSingleCriterion(1)), take(1)),
            ])
        ),
        tap(([payload, _]) => {
            this.store.dispatch(new valuationDataActions.ChangeComparableConsideration({comId: payload.com.id, tenureId: payload.selectedTenure}));
        }),
        map(([payload, criterion]) => {
            const [val, process] = this.considerationCriterionService.addComparable(payload.tpTenureID, payload.selectedTenure, payload.com.tenures, payload.quote_currency);
            const oldComValues = Object.assign({}, criterion.comValues);
            oldComValues[payload.com.id] = val;
            const oldComConsideration = Object.assign({}, criterion.comConsideration);
            oldComConsideration[payload.com.id] = {
                process,
                methods: {
                    mainMethod: undefined,
                    subMethod: undefined,
                },
                rateValues: {
                    capRate: undefined,
                    capRateJustification: null,
                    targetRate: undefined,
                    targetRateJustification: null,
                },
                cfmRateValues: {
                    targetRate: undefined,
                    targetRateJustification: null,
                    growthRate: undefined,
                    growthRateJustification: null,
                    effectiveRentJustification: null,
                },
                selected: {
                    main: undefined,
                    index: undefined,
                },
                ary: undefined,
            }
            const upCriterion: Update<CriterionModel> = {
                id: criterion.id,
                changes: {
                    ...criterion,
                   comValues: oldComValues,
                   comConsideration: oldComConsideration
                }
            };
            return new ComparableConsiderationChanged({
                criterion: upCriterion
            })
        })
    )
}