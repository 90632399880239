import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectCountryById } from 'src/app/core/admin';
import { AssetClassModel } from 'src/app/core/asset_class';
import { selectAssetClassTypeById, selectGradeById, selectPropertyTypeById, selectTenureById, selectUnitMeasurementById } from 'src/app/core/linked-tables';
import { selectTopPropertyTypeById } from 'src/app/core/linked-tables/_selectors/top-property-type.selectors';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { MapModalComponent } from '../../../valuation-process-edit/_sub/background-tab/map-modal/map-modal.component';

interface TemplateData {
  propertyType: string;
  propertySize: string;
  buildingGrade?: string;
  propertyGrade?: string;
  assetClass: string;
  buildingType: string;
  locationGrade: string;
  location: {
    address: string;
    ltlng: {
      latitude: number;
      longitude: number;
    }
  };
}

@Component({
  selector: 'kt-property-specific-section',
  templateUrl: './property-specific-section.component.html',
  styleUrls: ['./property-specific-section.component.scss', '../../section.styles.scss']
})
export class PropertySpecificSectionComponent implements OnInit {
  @Input()
  tp: AssetClassModel;
  @Input()
  toe: ToeModel;
  data$: Observable<TemplateData>;
  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.store$.select(selectAssetClassTypeById(this.tp.type_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectTopPropertyTypeById(this.tp.top_property_type_id)).pipe(map(val => val ? val.title : undefined)),
      this.store$.select(selectGradeById(this.tp.details.locationData.location_grade_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectGradeById(this.tp.details.building ? this.tp.details.building.building_grade_id : this.tp.details.building_information.grade_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectCountryById(this.tp.details.locationData.country_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectUnitMeasurementById(this.toe.unit_of_measurement_id)).pipe(map(val => val ? val.area : undefined)),
      this.activatedRoute.data.pipe(map(res => res.subData.sizes)),
      this.store$.select(selectGradeById(this.tp.details.aboutProperty.property_grade_id)).pipe(map(val => val ? val.name : undefined))
    ]).pipe(map(([assetType, propertyType, locationGrade, buildingGrade, countryName, unitAreaMeasurement, sizes, propertyGrade]) => {
      const data: TemplateData = {
        assetClass: assetType,
        propertyType: propertyType,
        propertySize: sizes[0].size + ' (' + unitAreaMeasurement + ')',
        buildingGrade: buildingGrade,
        locationGrade: locationGrade,
        propertyGrade: propertyGrade,
        buildingType: this.tp.details.building ? this.tp.details.building.building_type_name : this.tp.details.building_information.type_name,
        location: {
          address: `${this.tp.details.locationData.address} - ${this.tp.details.locationData.city_name} - ${(this.tp.details.locationData.zip_code ? this.tp.details.locationData.zip_code + ' - ' : '')} ${countryName ? countryName : ''}`,
          ltlng: {
            latitude: Number(this.tp.details.locationData.latitude),
            longitude: Number(this.tp.details.locationData.longitude)
          }
        }
      };
      return data;
    }));
  }

  showInMap(event, ltlng: {latitude: number; longitude: number}) {
    const target = new ElementRef(event.currentTarget);
    this.dialog.open(MapModalComponent, {
      width: '800px',
      height: '450px',
      backdropClass: 'map-backdrop',
      data: {
        latitude: ltlng.latitude,
        longitude: ltlng.longitude,
        trigger: target
      }
    })
  }
}
