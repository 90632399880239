// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {BaseOfValue} from '../_models/base-of-value.model';

export enum BaseOfValueActionTypes {

    AllBaseOfValuesRequested = '[BaseOfValue Home Page] All BaseOfValue Requested',
    AllBaseOfValuesLoaded = '[BaseOfValue API] All BaseOfValue Loaded',

    BaseOfValueOnServerCreated = '[Edit BaseOfValue Dialog] BaseOfValue On Server Created',
    BaseOfValueCreated = '[Edit BaseOfValue Dialog] BaseOfValue Created',
    BaseOfValueUpdated = '[Edit BaseOfValue Dialog] BaseOfValue Updated',
    BaseOfValueDeleted = '[BaseOfValue List Page] BaseOfValue Deleted',

    // trash system

    BaseOfValueOnServerRestored = '[BaseOfValue Trash] BaseOfValue On Server Restored',
    BaseOfValueRestored = '[BaseOfValue Trash] BaseOfValue Restored',

    BaseOfValueOnServerAdminRestored = '[BaseOfValue Admin Trash] BaseOfValue On Server Restored',
    BaseOfValueAdminRestored = '[BaseOfValue Admin Trash] BaseOfValue Restored',

    BaseOfValueDeletedFromTrash = '[BaseOfValue Trash] BaseOfValue deleted',
    BaseOfValueDeletedFromAdminTrash = '[BaseOfValue Admin Trash] BaseOfValue deleted',

    BaseOfValueTrash = 'BaseOfValue Trashed',
    BaseOfValueTrashFlushed = 'BaseOfValue Trash Flushed',

    // Page system

    BaseOfValuesPageRequested = '[BaseOfValue List Page] BaseOfValue Page Requested',
    BaseOfValuesPageLoaded = '[BaseOfValue API] BaseOfValue Page Loaded',
    BaseOfValuesPageCancelled = '[BaseOfValue API] BaseOfValue Page Cancelled',

    BaseOfValuesPageToggleLoading = '[BaseOfValue page] BaseOfValue Page Toggle Loading',
    BaseOfValueActionToggleLoading = '[BaseOfValue] BaseOfValue Action Toggle Loading'
}

export class BaseOfValueOnServerCreated implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueOnServerCreated;

    constructor(public payload: { item: BaseOfValue }) {
    }
}

export class BaseOfValueCreated implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueCreated;

    constructor(public payload: { item: BaseOfValue }) {
    }
}

export class BaseOfValueUpdated implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueUpdated;

    constructor(public payload: {
        partialItem: Update<BaseOfValue>,
        item: BaseOfValue
    }) {
    }
}

export class BaseOfValueDeleted implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class BaseOfValuesPageRequested implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValuesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class BaseOfValuesPageLoaded implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValuesPageLoaded;

    constructor(public payload: { items: BaseOfValue[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class BaseOfValuesPageCancelled implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValuesPageCancelled;
}

export class AllBaseOfValuesRequested implements Action {
    readonly type = BaseOfValueActionTypes.AllBaseOfValuesRequested;
}

export class AllBaseOfValuesLoaded implements Action {
    readonly type = BaseOfValueActionTypes.AllBaseOfValuesLoaded;

    constructor(public payload: { items: BaseOfValue[] }) {
    }
}

export class BaseOfValuesPageToggleLoading implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValuesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BaseOfValueActionToggleLoading implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class BaseOfValueDeletedFromAdminTrash implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BaseOfValueDeletedFromTrash implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BaseOfValueOnServerRestored implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BaseOfValueRestored implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueRestored;

    constructor(public payload: { item: BaseOfValue }) {
    }
}

export class BaseOfValueOnServerAdminRestored implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BaseOfValueAdminRestored implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueAdminRestored;

    constructor(public payload: { item: BaseOfValue }) {
    }
}

export class BaseOfValueTrashFlushed implements Action {
    readonly type = BaseOfValueActionTypes.BaseOfValueTrashFlushed;

    constructor() {
    }
}


export type BaseOfValueActions = BaseOfValueCreated
    | BaseOfValueUpdated
    | BaseOfValueDeleted
    | BaseOfValuesPageRequested
    | BaseOfValuesPageLoaded
    | BaseOfValuesPageCancelled
    | AllBaseOfValuesLoaded
    | AllBaseOfValuesRequested
    | BaseOfValueOnServerCreated
    | BaseOfValueDeletedFromAdminTrash
    | BaseOfValueDeletedFromTrash
    | BaseOfValueOnServerRestored
    | BaseOfValueRestored
    | BaseOfValueOnServerAdminRestored
    | BaseOfValueAdminRestored
    | BaseOfValueTrashFlushed
    | BaseOfValuesPageToggleLoading
    | BaseOfValueActionToggleLoading;
