import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { Video } from "../_models/video.model";

export enum ActionTypes  {
    SET_STATE = '[Video Upload Component] Set State',
    RESET_STATE = '[Video Upload Component] Reset State',

    UPLOAD_VIDEO = '[Video Upload Component] Video Upload Request',
    VIDEO_UPLOADED = '[Video Upload Effect] Video Uploaded',

    UPDATE_VIDEO = '[Video Upload Component] Update Video Info',
    DELETE_VIDEO = '[Video Upload Component] Delete Video',

    PROCESS_VIDEO = '[Video Upload Effect] Process video',
}

export class UploadVideoAction implements Action {
    readonly type = ActionTypes.UPLOAD_VIDEO;
    constructor(public payload: {
        file: File
    }) {}
}

export class SetVideoStateAction implements Action {
    readonly type = ActionTypes.SET_STATE;
    constructor(public payload: {
        videos: Video[]
    }) {}
}

export class ResetVideoStateAction implements Action {
    readonly type = ActionTypes.RESET_STATE;
    constructor() {}
}

export class VideoUploadedAction implements Action {
    readonly type = ActionTypes.VIDEO_UPLOADED;
    constructor(public payload: {video: Video}) {}
}

export class UpdateVideo implements Action {
    readonly type = ActionTypes.UPDATE_VIDEO;
    constructor(public payload: {partial: Update<Video>}) {}
}

export class DeleteVideo implements Action {
    readonly type = ActionTypes.DELETE_VIDEO;
    constructor(public payload: {vimeo_id: number}) {}
}

export class ProcessVideo implements Action {
    readonly type = ActionTypes.PROCESS_VIDEO;
}

export type Actions 
    = SetVideoStateAction
    | ResetVideoStateAction
    | UploadVideoAction
    | VideoUploadedAction
    | UpdateVideo
    | DeleteVideo
    | ProcessVideo