<div class="d-flex flex-column mb-4" *ngIf="generatingReport$ | async">
    <h3 class="mb-2">Generating Report</h3>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="card card-custom">
    <div class="card-header">
        <div class="card-title"></div>
        <div class="card-toolbar" *ngIf="!isCustomTp && toeStatus != 4">
            <div class="d-flex justify-content-end mb-3">
                <div ngbDropdown [autoClose]="false" [placement]="'bottom-right'" class="dropdown dropdown-inline mr-4"
                    (mouseenter)="onHover($event, 'mouseenter')"
                    (mouseleave)="onHover($event, 'mouseleave')"
                >
                    <div ngbDropdownAnchor
                        class="btn btn-label-brand btn-bold btn-sm d-flex align-items-start bg-white" 
                        [ngClass]="{'generate-doc-btn-disabled': tpState == 1}"
                        style="pointer-events: none !important; cursor: pointer !important;"
                    >
                        <mat-icon>edit</mat-icon>
                        <span>Generate a Report</span>
                    </div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right m-0">
                        <ul class="navi navi-hover">
                            <li class="navi-item">
                                <a href="javascript:;" 
                                    [class]="(inspectionCompleted$|async) ? 'navi-link' : 'navi-link disabled'"
                                    (click)="generatePropertyDetailReport()">
                                    <div class="d-flex navi-text">
                                        <mat-icon>search</mat-icon>
                                        <span>Investigation</span>
                                    </div>
                                </a>
                            </li>
                            <li class="navi-item">
                                <!-- <a href="javascript:;" 
                                    [class]="(valuationCompleted$|async) 
                                                ? toeStatus >=2 || reportStatus == 0
                                                    ?'navi-link' 
                                                    : 'navi-link disabled'
                                                : 'navi-link disabled'"
                                    (click)="generateValuationReport()">
                                    <div class="d-flex navi-text">
                                        <mat-icon>attach_money</mat-icon>
                                        <span>Valuation</span>
                                    </div>
                                </a> -->
                                <a href="javascript:;" 
                                    [class]="(canGenerateValuationReport$|async) 
                                                ? toeStatus >= 2 || reportStatus == 0
                                                    ? 'navi-link'
                                                    : 'navi-link disabled'
                                                : 'navi-link disabled'"
                                    (click)="generateValuationReport()">
                                    <div class="d-flex navi-text">
                                        <mat-icon>attach_money</mat-icon>
                                        <span>Valuation</span>
                                    </div>
                                </a>
                            </li>
                            <li *ngIf="toeRestrictionsOnUse" class="navi-item">
                                <!-- <a href="javascript:;" 
                                    [class]="(valuationCompleted$|async) ? 'navi-link' : 'navi-link disabled'" 
                                    (click)="generateDraftStatementReport()">
                                    <div class="d-flex navi-text">
                                        <mat-icon>description</mat-icon>
                                        <span>Draft Statement</span>
                                    </div>
                                </a> -->
                                <a href="javascript:;" 
                                    [class]="(canGenerateDraftReport$|async) ? 'navi-link' : 'navi-link disabled'" 
                                    (click)="generateDraftStatementReport()">
                                    <div class="d-flex navi-text">
                                        <mat-icon>description</mat-icon>
                                        <span>Draft Statement</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <mat-checkbox [disabled]="tpState == 1" style="margin-top: auto; margin-bottom: auto;" 
                    class="mr-2"
                    (change)="onchangeReportOption($event)">
                    as draft
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="example-container mat-elevation-z0" style="min-height: 250px" *ngIf="!isCustomTp; else customTpReport">
            <div class="mat-table-wrapper">
                <mat-table #table [dataSource]="dataSource">
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            <span *ngIf="report.type === reportType.PropertyDetailReport">Investigation Report</span>
                            <span *ngIf="report.type === reportType.ValuationReport">Valuation Report</span>
                            <span *ngIf="report.type === reportType.DraftStatementReport">Draft Statement Report</span>
                        </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            <b>
                                <span class="label label-inline label-rounded label-outline-{{ getItemCssClassByStatus(report.status) }} label-outline-2x label-lg py-1"
                                    style="width: 10rem;">
                                    {{ getItemStatusString(report.status) }}
                                </span>
                            </b>
                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="generated_date">
                        <mat-header-cell *matHeaderCellDef>Generated Date</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            {{ report.created_at | date:'dd MMM yyyy, h:mm a':user.settings.time_zone_gmt }}
                        </mat-cell>
                    </ng-container>
            
                    <ng-container matColumnDef="file_size">
                        <mat-header-cell *matHeaderCellDef>File Size</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            {{report.file_size ? report.file_size : 'N/A'}}
                        </mat-cell>
                    </ng-container>
            
                    <!-- <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let report">
                            <span class="text-danger" *ngIf="report.status === 1"> Failed </span>
                            <span *ngIf="report.status === 0">Generating</span>
                            <span class="text-success" *ngIf="report.status === 2">Success</span>
                        </mat-cell>
                    </ng-container> -->
            
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let report let i = index">
                            <ng-template #popTitle>
                                {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                            </ng-template>
                            <ng-template #popContent>
                                <div>
                                    <span class="font-weight-bold">File Name:</span> {{report.file_name}}
                                </div>
                                <div>
                                    <span class="font-weight-bold">Description:</span> {{report.description}}
                                </div>
                            </ng-template>
                            <button mat-icon-button color="primary"
                               [ngbPopover]="popContent"
                               [popoverTitle]="popTitle"
                               container="body" >
                                <mat-icon>info</mat-icon>
                            </button>
        
                            <button mat-icon-button color="primary"
                                [disabled]="tpState == 1 || toeStatus == 4"
                                (click)="editReport(report)" >
                                <mat-icon>create</mat-icon>
                            </button>
        
                            <button mat-icon-button color="primary"
                                (click)="downloadReport(report)" >
                                <mat-icon>get_app</mat-icon>
                            </button>
                            <button mat-icon-button color="warn"
                                [disabled]="tpState == 1 || toeStatus == 4"
                                type="button"
                                [disabled]="false"
                                (click)="deleteReport(report)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
                <div class="mat-table-message" *ngIf="loading$ | async">Please wait....</div>
                <div class="mat-table-message" *ngIf="!(hasData$ | async)">No Reports...</div>
            </div>
            <!-- <div class="mat-table-message" *ngIf="dataSource.data.length === 0">No records found</div> -->
        </div>
        <ng-template #customTpReport>
            <div class="w-100 d-flex justify-content-center pt-4 font-weight-bold">
                The current asset class or property type you have selected is not yet supported by Interval - you are in manual mode
            </div>
        </ng-template>
    </div>
</div>