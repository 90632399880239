// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CeilingActions, CeilingActionTypes} from '../_actions/ceiling.actions';
// Models
import {Ceiling} from '../_models/ceiling.model';
import {QueryParamsModel} from '../../_base/crud';

export interface CeilingsState extends EntityState<Ceiling> {
    isAllCeilingsLoaded: boolean;
    queryRowsCount: number;
    queryResult: Ceiling[];
    lastCreatedCeilingId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Ceiling> = createEntityAdapter<Ceiling>();

export const initialCeilingsState: CeilingsState = adapter.getInitialState({
    isAllCeilingsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedCeilingId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function ceilingsReducer(state = initialCeilingsState, action: CeilingActions): CeilingsState {
    switch (action.type) {
        case CeilingActionTypes.CeilingsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCeilingId: undefined
            };
        case CeilingActionTypes.CeilingsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case CeilingActionTypes.CeilingOnServerCreated:
            return {
                ...state
            };
        case CeilingActionTypes.CeilingCreated:
            return adapter.addOne(action.payload.ceiling, {
                ...state, lastCreatedCeilingId: action.payload.ceiling.id
            });
        case CeilingActionTypes.CeilingUpdated:
            return adapter.updateOne(action.payload.partialCeiling, state);
        case CeilingActionTypes.AllCeilingsLoaded:
            return adapter.addAll(action.payload.ceilings, {
                ...state, isAllCeilingsLoaded: true
            });
        case CeilingActionTypes.CeilingsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case CeilingActionTypes.CeilingsPageLoaded:
            return adapter.addMany(action.payload.ceilings, {
                ...initialCeilingsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case CeilingActionTypes.CeilingDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case CeilingActionTypes.CeilingRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case CeilingActionTypes.CeilingDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case CeilingActionTypes.CeilingAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case CeilingActionTypes.CeilingDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case CeilingActionTypes.CeilingTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
