import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { TpSignedDoc } from "../_models/tp-signed-doc.model";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class TpSignedDocService {
	API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/target_property/`;
	constructor(
		private httpClient: HttpClient,
		private authService: MadAuthService,
		private subDomainService: SubdomainService
	) {}

	public getDoc(tpID: number): Observable<TpSignedDoc[]> {
		return this.httpClient.get<{data: TpSignedDoc[]}>(this.API_URL + tpID + '/signed-docs').pipe(
			map(res => res.data)
		);
	}

	public uploadDoc(tpID: number, formData: FormData): Observable<TpSignedDoc|Error> {
		const headers = this.authService.getAuthHeadersForUpload()
		const req = new HttpRequest(
			'POST',
			this.API_URL + tpID + '/signed-docs',
			formData,
			{headers}
		)
		return this.httpClient.request<{data: TpSignedDoc}>(req).pipe(
			map(event => {
				switch (event.type) {
					case HttpEventType.Response: {
						if (event.status === 201) {
							return event.body.data;
						}
						break;
					}
					default: {
						return new Error('Error happened');
					}
				}
			})
		);
	}

	public delete(tpID: number, id: number, taskID: number): Observable<number> {
		const headers = this.authService.getAuthHeaders()
		return this.httpClient.delete<{data: number}>(this.API_URL + tpID + '/signed-docs/' + id + `?task_id=${taskID}`, { headers }).pipe(
			map(res => (res.data))
		);
	}
}