<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">

        </div>
        <div class="card-toolbar">
            <div *ngIf="(isInProgress$ | async)" class="mr-2">
                <div style="margin-bottom: 14px;">Uploading... {{ progress$ | async }}%</div>
            </div>
            <div *ngIf="(isInProgressSub | async)" class="mr-2">
                <div style="margin-bottom: 14px;">Uploading... {{ progressSub | async }}%</div>
            </div>
    
            <div class="error" *ngIf="(hasFailed$ | async)" class="mr-2">
                Error: {{ error$ | async }}
            </div>
            <div class="error" *ngIf="(hasFailedSub | async)" class="mr-2">
                Error: {{ errorSub | async }}
            </div>

            <div *ngIf="!(isInProgressSub | async)" class="mr-2">
                <button 
                    [ngClass]="{'add-doc-btn-disabled': tpState == 1 || toeStatus == 4}"
                    mat-raised-button type="button" class="m-1 btn" (click)="addFile()">
                    <span>Add Additional Document</span>
                </button>
            </div>

            <div class="mr-2">
                <input #fileInput type="file" (change)="uploadFile($event)" style="display: none" accept="{{fileType}}"/>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="col-lg-12 mat-table-wrapper">
            <table class="table">
                <thead>
                    <tr>
                        <th class="column-type">Type</th>
                        <th class="column-required">ToE Required?</th>
                        <th class="column-category">Category</th>
                        <th class="column-description">Description</th>
                        <th class="column-available">Available</th>
                        <th class="column-actions">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <kt-toe-required
                        #toeRequired
                        [toeStatus]="toeStatus"
                        [tpState]="tpState"
                        [oldFiles]="toeOldFiles"
                        [readOnly]="false"
                        [folderOnEditMode]="'test'"
                        [parentId]="parentId"
                        [fileCategory]="fileCategory"
                        [descriptionField]="true"
                        [fileInput]="fInput"
                        [callUpload$]="whoUploaded$"
                        [lastChangedItem]="lastChangedItem"></kt-toe-required>
                    <kt-user-uploaded
                        #userUploaded
                        [toeStatus]="toeStatus"
                        [tpState]="tpState"
                        [oldFiles]="userOldFiles"
                        [readOnly]="true"
                        [folderOnEditMode]="userFolderOnEditMode"
                        [parentId]="parentId"
                        [fileCategory]="fileCategory"
                        [descriptionField]="true"
                        [fileInput]="fInput"
                        [callUpload$]="whoUploaded$"
                        [isInProgressSub]="isInProgressSub"
                        [progressSub]="progressSub"
                        [hasFailedSub]="hasFailedSub"
                        [errorSub]="errorSub"
                        [cancelReq]="cancelReq"></kt-user-uploaded>
                </tbody>
            </table>
        </div>
        
    </div>
</div>