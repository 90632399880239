import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { OutdoorSpaceModel } from "../_models/outdoor-space.model";

export enum OutdoorSpaceActionTypes {
    AllOutdoorSpacesRequested = '[Linked Area] All OutdoorSpace Requested',
    AllOutdoorSpacesLoaded = '[OutdoorSpace Effect] All OutdoorSpace Loaded',

    OutdoorSpaceOnServerCreated = '[Add OutdoorSpace Page] OutdoorSpace On Server Created',
    OutdoorSpaceCreated = '[OutdoorSpace Effect] OutdoorSpace Created',
    OutdoorSpaceUpdated = '[OutdoorSpace Effect] OutdoorSpace Updated',
    OutdoorSpaceDeleted = '[OutdoorSpace List Page] OutdoorSpace Deleted',

    OutdoorSpaceOnServerRestored = '[OutdoorSpace Trash] OutdoorSpace On Server Restored',
    OutdoorSpaceRestored = '[OutdoorSpace Trash] OutdoorSpace Restored',

    OutdoorSpaceOnServerAdminRestored = '[OutdoorSpace Admin Trash] OutdoorSpace On Server Restored',
    OutdoorSpaceAdminRestored = '[OutdoorSpace Admin Trash] OutdoorSpace Restored',

    OutdoorSpaceDeletedFromTrash = '[OutdoorSpace Trash] OutdoorSpace deleted',
    OutdoorSpaceDeletedFromAdminTrash = '[OutdoorSpace Admin Trash] OutdoorSpace deleted',

    OutdoorSpaceTrash = 'OutdoorSpace Trashed',
    OutdoorSpaceTrashFlushed = 'OutdoorSpace Trash Flushed',

    OutdoorSpacesPageRequested = '[OutdoorSpace List Page] OutdoorSpace Page Requested',
    OutdoorSpacesPageLoaded = '[OutdoorSpace API] OutdoorSpace Page Loaded',
    OutdoorSpacesPageCancelled = '[OutdoorSpace API] OutdoorSpace Page Cancelled',

    OutdoorSpacesPageToggleLoading = '[OutdoorSpace page] OutdoorSpace Page Toggle Loading',
    OutdoorSpaceActionToggleLoading = '[OutdoorSpace] OutdoorSpace Action Toggle Loading'
}

export class OutdoorSpaceOnServerCreated implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceOnServerCreated;

    constructor(public payload: { item: OutdoorSpaceModel }) {
    }
}

export class OutdoorSpaceCreated implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceCreated;

    constructor(public payload: { item: OutdoorSpaceModel }) {
    }
}

export class OutdoorSpaceUpdated implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceUpdated;

    constructor(public payload: {
        partialItem: Update<OutdoorSpaceModel>,
        item: OutdoorSpaceModel
    }) {
    }
}

export class OutdoorSpaceDeleted implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OutdoorSpacesPageRequested implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpacesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OutdoorSpacesPageLoaded implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpacesPageLoaded;

    constructor(public payload: { items: OutdoorSpaceModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OutdoorSpacesPageCancelled implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpacesPageCancelled;
}

export class AllOutdoorSpacesRequested implements Action {
    readonly type = OutdoorSpaceActionTypes.AllOutdoorSpacesRequested;
}

export class AllOutdoorSpacesLoaded implements Action {
    readonly type = OutdoorSpaceActionTypes.AllOutdoorSpacesLoaded;

    constructor(public payload: { items: OutdoorSpaceModel[] }) {
    }
}

export class OutdoorSpacesPageToggleLoading implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpacesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OutdoorSpaceActionToggleLoading implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OutdoorSpaceDeletedFromAdminTrash implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OutdoorSpaceDeletedFromTrash implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OutdoorSpaceOnServerRestored implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OutdoorSpaceRestored implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceRestored;

    constructor(public payload: { item: OutdoorSpaceModel }) {
    }
}

export class OutdoorSpaceOnServerAdminRestored implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OutdoorSpaceAdminRestored implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceAdminRestored;

    constructor(public payload: { item: OutdoorSpaceModel }) {
    }
}

export class OutdoorSpaceTrashFlushed implements Action {
    readonly type = OutdoorSpaceActionTypes.OutdoorSpaceTrashFlushed;

    constructor() {
    }
}


export type OutdoorSpaceActions = OutdoorSpaceCreated
    | OutdoorSpaceUpdated
    | OutdoorSpaceDeleted
    | OutdoorSpacesPageRequested
    | OutdoorSpacesPageLoaded
    | OutdoorSpacesPageCancelled
    | AllOutdoorSpacesLoaded
    | AllOutdoorSpacesRequested
    | OutdoorSpaceOnServerCreated
    | OutdoorSpaceDeletedFromAdminTrash
    | OutdoorSpaceDeletedFromTrash
    | OutdoorSpaceOnServerRestored
    | OutdoorSpaceRestored
    | OutdoorSpaceOnServerAdminRestored
    | OutdoorSpaceAdminRestored
    | OutdoorSpaceTrashFlushed
    | OutdoorSpacesPageToggleLoading
    | OutdoorSpaceActionToggleLoading;