import {DefaultAssumption} from '../_models/default-assumption.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {DefaultAssumptionsState} from '../_reducers/default-assumption.reducers';
import * as fromDefaultAssumption from '../_reducers/default-assumption.reducers';
import {each} from 'lodash';

export const selectDefaultAssumptionsState = createFeatureSelector<DefaultAssumptionsState>('defaultAssumptions');

export const selectDefaultAssumptionById = (defaultAssumptionId: number) => createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => defaultAssumptionsState.entities[defaultAssumptionId]
);

export const selectAllDefaultAssumptions = createSelector(
    selectDefaultAssumptionsState,
    fromDefaultAssumption.selectAll
);

export const selectAllDefaultAssumptionsIds = createSelector(
    selectDefaultAssumptionsState,
    fromDefaultAssumption.selectIds
);

export const allDefaultAssumptionsLoaded = createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => defaultAssumptionsState.isAllDefaultAssumptionsLoaded
);


export const selectDefaultAssumptionsPageLoading = createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => defaultAssumptionsState.listLoading
);

export const selectDefaultAssumptionsActionLoading = createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => defaultAssumptionsState.actionLoading
);

export const selectLastCreatedDefaultAssumptionId = createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => defaultAssumptionsState.lastCreatedDefaultAssumptionId
);

export const selectDefaultAssumptionsShowInitWaitingMessage = createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => defaultAssumptionsState.showInitWaitingMessage
);

export const selectTrashedDefaultAssumptionCount = createSelector(
    selectDefaultAssumptionsState,
    (defaultAssumptionsState) => defaultAssumptionsState.totalTrashed
);

export const selectAdminTrashedDefaultAssumptionCount = createSelector(
    selectDefaultAssumptionsState,
    (defaultAssumptionsState) => defaultAssumptionsState.totalAdminTrashed
);

export const selectDefaultAssumptionQueryResult = createSelector(
    selectDefaultAssumptionsState,
    defaultAssumptionsState => {
        const items: DefaultAssumption[] = [];
        each(defaultAssumptionsState.entities, element => {
            if (element.id != 1) {
                items.push(element);
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: DefaultAssumption[] = httpExtension.sortArray(items, defaultAssumptionsState.lastQuery.sortField, defaultAssumptionsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, defaultAssumptionsState.totalCount, '', defaultAssumptionsState.totalTrashed);
    }
);
