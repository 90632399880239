<div class="card card-custom">
    <div class="card-body-progress">
        <!-- here can place a progress bar-->
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <mat-dialog-content>
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <input required matInput placeholder="Title" [formControl]="inputTitleForUpdate"/>
                            <mat-error>Title for file
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="descriptionField" class="mat-form-field-fluid">
                            <textarea required matInput rows="4" placeholder="Description" [formControl]="inputDescriptionForUpdate">
                            </textarea>
                            <mat-error>Description for file
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-fluid">
                            <span (click)="addPicker_date.open()">
                                <input matInput [matDatepicker]="addPicker_date"
                                       readonly
                                       placeholder="Expiration Date"
                                       [formControl]="dateForUpdate">
                            </span>
                            <mat-datepicker-toggle matSuffix [for]="addPicker_date"></mat-datepicker-toggle>
                            <button mat-button *ngIf="dateForUpdate.value"
                                    matSuffix mat-icon-button
                                    matTooltip="Clear"
                                    (click)="clearDate(dateForUpdate)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-datepicker #addPicker_date></mat-datepicker>
                        </mat-form-field>
    
                        <mat-checkbox *ngIf="checkBox" [checked]="checkBoxValue" (change)="changed()">
                            Automatically insert to all reports
                        </mat-checkbox>
                        <mat-checkbox *ngIf="featuredPictureAllowed" [checked]="isFeatured" (change)="featuredChanged()" [disabled]="isFeatured">
                            Featured picture
                        </mat-checkbox>
                    </div>
                </div>
            </mat-dialog-content>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">Close</button>&nbsp;
                        <button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="inputTitleForUpdate.value.length == 0">
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
