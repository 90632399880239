import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-video-edit-dialog',
  templateUrl: './video-edit-dialog.component.html',
  styleUrls: ['./video-edit-dialog.component.scss']
})
export class VideoEditDialogComponent implements OnInit {

  descriptionControl = new UntypedFormControl('');

  constructor(
    public dialogRef: MatDialogRef<VideoEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.descriptionControl.setValue(this.data.description);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    this.dialogRef.close({
      description: this.descriptionControl.value
    });
  }
}
