import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ValuationComparableState, adapter } from "../_reducers/valuation-comparable.reducer";

const { selectAll } = adapter.getSelectors()

export const selectValuationComparableState = createFeatureSelector<ValuationComparableState>('valuation-comparables')
export const selectValuationComparables = createSelector(
    selectValuationComparableState,
    selectAll
) 
export const selectValuationComparableSelectedPropertySubTypeIds = createSelector(
    selectValuationComparableState,
    state => state.selectedPropertySubTypes
)