import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    AssetClassResidentialFacilitiesService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassResidentialFacilitiesBuildingGetResolver implements Resolve<any> {
    constructor(public assetClassResidentialFacilitiesService: AssetClassResidentialFacilitiesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const apartment_id = Number(route.params.apartment_id);
        return this.assetClassResidentialFacilitiesService.getAllAssetClassResidentialBuildingFacilities(apartment_id);
    }
}
