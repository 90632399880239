import { InputAmountType, LeaseDurationType, RentBasisListing, RentReviewDetailType, RentReviewTypeListing, RentType } from "../../../types"
import { durationCalculation } from "../../../valuation"
import { mapCost } from "../utils"
import { HeadlineToEffectiveMeta } from "./headline-rent"
import { CalculationMeta, CostRevenue, Lease, PropertyRevenue } from "./lease-and-property-revenue"

export type ConvertedCostRevenue = {
  uuid: string,
  kind: 'lease' | 'property-revenue'
  currency: string
  rentType: RentType
  startDate: Date
  propertySubType: {
    id: number,
    name: string
  } | undefined

  durationType: LeaseDurationType | null
  leaseDuration: number
  writeOffPeriod: number
  breakOptionAfter: number
  fittingOutPeriod: number,
  rentFreePeriod: number

  rentReviewCycle: number

  amount: number
  size: number
  additionalSizeInfo: {
    tpSize: number,
    totalLeasedArea: number
  }
  capitalPayment: number

  rentInputAmount: number
  rentInputAmountType: InputAmountType
  rentBasis: RentBasisListing,
  rentReviewType: RentReviewTypeListing | undefined,
  rentReviewDetailType: RentReviewDetailType
  rentReviewDetail: number | null

  expensesInputAmountType: InputAmountType,
  recoverablePropertyManagement: number | null
  recoverableLeasingExpenses: number | null
  recoverableUtilities: number | null
  recoverableMaintenance: number | null
  recoverableInsurance: number | null
  recoverableJanitorial: number | null
  recoverablePropertyTaxes: number | null
  recoverableBusiness: number | null
  recoverableOthers: number | null
  nonRecoverablePropertyManagement: number | null
  nonRecoverableLeasingExpenses: number | null
  nonRecoverableUtilities: number | null
  nonRecoverableMaintenance: number | null
  nonRecoverableInsurance: number | null
  nonRecoverableJanitorial: number | null
  nonRecoverablePropertyTaxes: number | null
  nonRecoverableBusiness: number | null
  nonRecoverableOthers: number | null

  headlineToEffectiveMeta: HeadlineToEffectiveMeta,
  subLease: CostRevenue,
  calculationMeta: CalculationMeta
}

export const ConvertedCostRevenueUtils = {
  fromLease(item: Lease, totalLeasedArea: number, tpSize: number): ConvertedCostRevenue {
    return {
      uuid: item.uid,
      kind: item.kind,
      currency: item.currency,
      rentType: item.rentType,
      startDate: item.startDate,
      durationType: item.durationType,
      leaseDuration: durationCalculation(item.duration, item.durationType),
      rentReviewCycle: durationCalculation(item.rentReviewCycle, item.durationType),
      writeOffPeriod: durationCalculation(item.writeOffPeriodAfter, item.durationType),
      breakOptionAfter: durationCalculation(item.breakOptionAfter, item.durationType),
      fittingOutPeriod: durationCalculation(item.fittingOutPeriod, item.durationType),
      rentFreePeriod: durationCalculation(item.rentFreePeriod, item.durationType),
      propertySubType: item.propertySubType,

      size: item.leasedArea,
      additionalSizeInfo: {
        tpSize: tpSize,
        totalLeasedArea: totalLeasedArea
      },
      amount: item.rentInputAmountType == 'per_units' ? item.rentInputAmount * item.leasedArea : item.rentInputAmount,
      rentInputAmount: item.rentInputAmount,
      rentInputAmountType: item.rentInputAmountType,
      rentBasis: item.rentBasis,
      capitalPayment: item.capitalPayment,

      expensesInputAmountType: item.expensesInputAmountType,
      recoverableBusiness: mapCost(item.recoverableBusiness, item.expensesInputAmountType, item.leasedArea),
      recoverableInsurance: mapCost(item.recoverableInsurance, item.expensesInputAmountType, item.leasedArea),
      recoverableJanitorial: mapCost(item.recoverableJanitorial, item.expensesInputAmountType, item.leasedArea),
      recoverableLeasingExpenses: mapCost(item.recoverableLeasingExpenses, item.expensesInputAmountType, item.leasedArea),
      recoverableMaintenance: mapCost(item.recoverableMaintenance, item.expensesInputAmountType, item.leasedArea),
      recoverableOthers: mapCost(item.recoverableOthers, item.expensesInputAmountType, item.leasedArea),
      recoverablePropertyManagement: mapCost(item.recoverablePropertyManagement, item.expensesInputAmountType, item.leasedArea),
      recoverablePropertyTaxes: mapCost(item.recoverablePropertyTaxes, item.expensesInputAmountType, item.leasedArea),
      recoverableUtilities: mapCost(item.recoverableUtilities, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableBusiness: mapCost(item.nonRecoverableBusiness, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableInsurance: mapCost(item.nonRecoverableInsurance, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableJanitorial: mapCost(item.nonRecoverableJanitorial, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableLeasingExpenses: mapCost(item.nonRecoverableLeasingExpenses, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableMaintenance: mapCost(item.nonRecoverableMaintenance, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableOthers: mapCost(item.nonRecoverableOthers, item.expensesInputAmountType, item.leasedArea),
      nonRecoverablePropertyManagement: mapCost(item.nonRecoverablePropertyManagement, item.expensesInputAmountType, item.leasedArea),
      nonRecoverablePropertyTaxes: mapCost(item.nonRecoverablePropertyTaxes, item.expensesInputAmountType, item.leasedArea),
      nonRecoverableUtilities: mapCost(item.nonRecoverableUtilities, item.expensesInputAmountType, item.leasedArea),

      headlineToEffectiveMeta: item.headlineToEffectiveMeta,
      rentReviewType: item.rentReviewType,
      rentReviewDetailType: item.rentReviewDetailType,
      rentReviewDetail: item.rentReviewDetail,

      subLease: item.subLease,
      calculationMeta: item.calculationMeta
    }
  },
  fromRevenue(item: PropertyRevenue, totalLeasedArea: number, tpSize: number): ConvertedCostRevenue {
    const size = item.size === 'total_lease' 
      ? totalLeasedArea 
      : item.size === 'tp' 
        ? tpSize
        : item.sizeInput;
    return {
      uuid: item.uid,
      kind: item.kind,
      currency: item.currency,
      rentType: item.rentType,
      startDate: item.startDate,
      durationType: item.durationType,
      leaseDuration: durationCalculation(item.duration, item.durationType),
      rentReviewCycle: durationCalculation(item.rentReviewCycle, item.durationType),
      writeOffPeriod: durationCalculation(item.writeOffPeriodAfter, item.durationType),
      breakOptionAfter: durationCalculation(item.breakOptionAfter, item.durationType),
      fittingOutPeriod: durationCalculation(item.fittingOutPeriod, item.durationType),
      rentFreePeriod: durationCalculation(item.rentFreePeriod, item.durationType),
      propertySubType: item.propertySubType,

      size: size,
      additionalSizeInfo: {
        tpSize: tpSize,
        totalLeasedArea: totalLeasedArea
      },
      amount: item.rentInputAmountType == 'per_units' ? item.rentInputAmount * size : item.rentInputAmount,
      rentInputAmount: item.rentInputAmount,
      rentInputAmountType: item.rentInputAmountType,
      rentBasis: item.rentBasis,
      capitalPayment: item.capitalPayment,

      expensesInputAmountType: item.expensesInputAmountType,
      recoverableBusiness: mapCost(item.recoverableBusiness, item.expensesInputAmountType, size),
      recoverableInsurance: mapCost(item.recoverableInsurance, item.expensesInputAmountType, size),
      recoverableJanitorial: mapCost(item.recoverableJanitorial, item.expensesInputAmountType, size),
      recoverableLeasingExpenses: mapCost(item.recoverableLeasingExpenses, item.expensesInputAmountType, size),
      recoverableMaintenance: mapCost(item.recoverableMaintenance, item.expensesInputAmountType, size),
      recoverableOthers: mapCost(item.recoverableOthers, item.expensesInputAmountType, size),
      recoverablePropertyManagement: mapCost(item.recoverablePropertyManagement, item.expensesInputAmountType, size),
      recoverablePropertyTaxes: mapCost(item.recoverablePropertyTaxes, item.expensesInputAmountType, size),
      recoverableUtilities: mapCost(item.recoverableUtilities, item.expensesInputAmountType, size),
      nonRecoverableBusiness: mapCost(item.nonRecoverableBusiness, item.expensesInputAmountType, size),
      nonRecoverableInsurance: mapCost(item.nonRecoverableInsurance, item.expensesInputAmountType, size),
      nonRecoverableJanitorial: mapCost(item.nonRecoverableJanitorial, item.expensesInputAmountType, size),
      nonRecoverableLeasingExpenses: mapCost(item.nonRecoverableLeasingExpenses, item.expensesInputAmountType, size),
      nonRecoverableMaintenance: mapCost(item.nonRecoverableMaintenance, item.expensesInputAmountType, size),
      nonRecoverableOthers: mapCost(item.nonRecoverableOthers, item.expensesInputAmountType, size),
      nonRecoverablePropertyManagement: mapCost(item.nonRecoverablePropertyManagement, item.expensesInputAmountType, size),
      nonRecoverablePropertyTaxes: mapCost(item.nonRecoverablePropertyTaxes, item.expensesInputAmountType, size),
      nonRecoverableUtilities: mapCost(item.nonRecoverableUtilities, item.expensesInputAmountType, size),

      headlineToEffectiveMeta: item.headlineToEffectiveMeta,
      rentReviewType: item.rentReviewType,
      rentReviewDetailType: item.rentReviewDetailType,
      rentReviewDetail: item.rentReviewDetail,

      subLease: item.subLease,
      calculationMeta: item.calculationMeta
    }
  }
}