import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { ValuationNoteModel } from "../_models/valuation-note.model";

export enum ActionTypes {
    LoadData = '[Notes Table] Load data from server',
    DataLoaded = '[Notes Table] Data loaded',

    AddData = '[Notes Table] Add data',
    UpdateData = '[Notes Table] Update data',
    RemoveData = '[Notes Table] Remove data'
}

export class LoadData implements Action {
    readonly type = ActionTypes.LoadData;
    constructor(public payload: {data: ValuationNoteModel[]}) {}
}
export class DataLoaded implements Action {
    readonly type = ActionTypes.DataLoaded;
    constructor(public payload: {data: ValuationNoteModel[], lastCreateId: number}) {}
}

export class AddData implements Action {
    readonly type = ActionTypes.AddData;
    constructor(public payload: {data: ValuationNoteModel}) {}
}
export class UpdateData implements Action {
    readonly type = ActionTypes.UpdateData;
    constructor(public payload: {data: Update<ValuationNoteModel>}) {}
}
export class RemoveData implements Action {
    readonly type = ActionTypes.RemoveData;
    constructor(public payload: {id: number}) {}
}

export type Actions = LoadData | DataLoaded | AddData | UpdateData | RemoveData;