import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AssetClassWarehouseService } from "src/app/core/comparable/_services/asset-class-warehouse.service";

@Injectable()
export class AssetClassWarehouseGetResolver implements Resolve<any> {
    constructor(
        private assetClassWarehouseService: AssetClassWarehouseService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const id = Number(route.params.warehouse_id);
        return this.assetClassWarehouseService.getAssetClass(id);
    }
}