import {RicsStandard} from '../_models/rics-standard.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {RicsStandardsState} from '../_reducers/rics-standard.reducers';
import * as fromRicsStandard from '../_reducers/rics-standard.reducers';
import {each} from 'lodash';

export const selectRicsStandardsState = createFeatureSelector<RicsStandardsState>('ricsStandards');

export const selectRicsStandardById = (ricsStandardId: number) => createSelector(
    selectRicsStandardsState,
    ricsStandardsState => ricsStandardsState.entities[ricsStandardId]
);

export const selectAllRicsStandards = createSelector(
    selectRicsStandardsState,
    fromRicsStandard.selectAll
);

export const selectAllRicsStandardsIds = createSelector(
    selectRicsStandardsState,
    fromRicsStandard.selectIds
);

export const allRicsStandardsLoaded = createSelector(
    selectRicsStandardsState,
    ricsStandardsState => ricsStandardsState.isAllRicsStandardsLoaded
);


export const selectRicsStandardsPageLoading = createSelector(
    selectRicsStandardsState,
    ricsStandardsState => ricsStandardsState.listLoading
);

export const selectRicsStandardsActionLoading = createSelector(
    selectRicsStandardsState,
    ricsStandardsState => ricsStandardsState.actionLoading
);

export const selectLastCreatedRicsStandardId = createSelector(
    selectRicsStandardsState,
    ricsStandardsState => ricsStandardsState.lastCreatedRicsStandardId
);

export const selectRicsStandardsShowInitWaitingMessage = createSelector(
    selectRicsStandardsState,
    ricsStandardsState => ricsStandardsState.showInitWaitingMessage
);

export const selectTrashedRicsStandardCount = createSelector(
    selectRicsStandardsState,
    (ricsStandardsState) => ricsStandardsState.totalTrashed
);

export const selectAdminTrashedRicsStandardCount = createSelector(
    selectRicsStandardsState,
    (ricsStandardsState) => ricsStandardsState.totalAdminTrashed
);


export const selectRicsStandardsQueryResult = createSelector(
    selectRicsStandardsState,
    ricsStandardsState => {
        const items: RicsStandard[] = [];
        each(ricsStandardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RicsStandard[] = httpExtension.sortArray(items, ricsStandardsState.lastQuery.sortField, ricsStandardsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, ricsStandardsState.totalCount, '', ricsStandardsState.totalTrashed);
    }
);
