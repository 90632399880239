import {QueryParamsModel} from '../../_base/crud';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap, switchMap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassOfficesService} from '../_services/asset-class-office.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AssetClassOfficeActionTypes,
    AssetClassOfficesPageRequested,
    AssetClassOfficesPageLoaded,
    AssetClassOfficeActionToggleLoading,
    AssetClassOfficesPageToggleLoading,
    AssetClassOfficeOnServerCreated,
    AssetClassOfficeOnServerUpdated,
    AssetClassOfficeCreated,
    AssetClassOfficeUpdated,
    AssetClassOfficeRestored,
    AssetClassOfficeOnServerRestored,
    AssetClassOfficeDeletedFromTrash,
    OneAssetClassOfficeDeleted,
    AssetClassOfficeAdminRestored,
    AssetClassOfficeOnServerAdminRestored,
    AssetClassOfficeGet,
    OfficeTrashFlushed,
    AssetClassOfficeMapPageRequested,
    AssetClassOfficeDuplicate
} from '../_actions/asset-class-office.actions';
import {of} from 'rxjs';
import {AssetClassOfficeDeletedFromAdminTrash} from '../';
import { merge } from 'lodash';

@Injectable()
export class AssetClassOfficeEffects {
    showPageLoadingDispatcher = new AssetClassOfficesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassOfficeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassOfficeActionToggleLoading({isLoading: false});

    @Effect()
    loadAssetClassOfficesPage$ = this.actions$.pipe(
        ofType<AssetClassOfficesPageRequested>(AssetClassOfficeActionTypes.AssetClassOfficesPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassOfficesPageLoaded({
                assetClassOffices: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadAssetClassMapPage$ = this.actions$.pipe(
        ofType<AssetClassOfficeMapPageRequested>(AssetClassOfficeActionTypes.AssetClassOfficeMapPageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getApartments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassOfficesPageLoaded({
                assetClassOffices: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createAssetClassOffice$ = this.actions$.pipe(
        ofType<AssetClassOfficeOnServerCreated>(AssetClassOfficeActionTypes.AssetClassOfficeOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClassOffice(
                payload.assetClassOffice,
                payload.tenures,
                payload.sizes,
                payload.fileList,
                payload.selectedFacilities,
                payload.selectedFacilitiesExclusive,
                payload.officeExternalAreas,
                payload.documents
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassOfficeCreated({assetClassOffice: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updateAssetClassOffice$ = this.actions$
        .pipe(
            ofType<AssetClassOfficeOnServerUpdated>(AssetClassOfficeActionTypes.AssetClassOfficeOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAssetClassOffice(
                    payload.assetClassOffice,
                    payload.tenures,
                    payload.sizes,
                    payload.fileList,
                    payload.selectedFacilities,
                    payload.selectedFacilitiesExclusive,
                    payload.officeExternalAreas,
                    payload.documents
                ).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassOfficeUpdated(
                            {assetClassOffice: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassOffice$ = this.actions$
        .pipe(
            ofType<AssetClassOfficeOnServerRestored>(AssetClassOfficeActionTypes.AssetClassOfficeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassOfficeRestored({assetClassOffice: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    restoreAdminAssetClassOffice$ = this.actions$
        .pipe(
            ofType<AssetClassOfficeOnServerAdminRestored>(AssetClassOfficeActionTypes.AssetClassOfficeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassOfficeAdminRestored({assetClassOffice: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassOfficeFromTrash$ = this.actions$
        .pipe(
            ofType<AssetClassOfficeDeletedFromTrash>(AssetClassOfficeActionTypes.AssetClassOfficeDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.assetClassOfficeId);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassOfficeFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassOfficeDeletedFromAdminTrash>(AssetClassOfficeActionTypes.AssetClassOfficeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassOffice$ = this.actions$
        .pipe(
            ofType<OneAssetClassOfficeDeleted>(AssetClassOfficeActionTypes.OneAssetClassOfficeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAssetClassOffice(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushApartmentTrash$ = this.actions$
        .pipe(
            ofType<OfficeTrashFlushed>(AssetClassOfficeActionTypes.OfficeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    getAssetClassOffice$ = this.actions$
        .pipe(
            ofType<AssetClassOfficeGet>(AssetClassOfficeActionTypes.AssetClassOfficeGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getAssetClassOffice(payload.assetClassOfficeId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    duplicateOffice$ = this.actions$.pipe(
        ofType<AssetClassOfficeDuplicate>(AssetClassOfficeActionTypes.AssetClassOfficeDuplicate),
        mergeMap(({payload}) => {
            return this.service.duplicate(payload.id);
        }),
        map((response) => {
            return new AssetClassOfficeCreated({assetClassOffice: response.data});
        })
    )

    constructor(private actions$: Actions,
                private service: AssetClassOfficesService,
                private store: Store<AppState>) {
    }
}
