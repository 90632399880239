// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {GardenActions, GardenActionTypes} from '../_actions/garden.actions';
// Models
import {GardenModel} from '../_models/garden.model';
import {QueryParamsModel} from '../../_base/crud';

export interface GardensState extends EntityState<GardenModel> {
    isAllGardensLoaded: boolean;
    queryRowsCount: number;
    queryResult: GardenModel[];
    lastCreatedGardenId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GardenModel> = createEntityAdapter<GardenModel>();

export const initialGardenState: GardensState = adapter.getInitialState({
    isAllGardensLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedGardenId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function gardenReducer(state = initialGardenState, action: GardenActions): GardensState {
    switch (action.type) {
        case GardenActionTypes.GardensPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGardenId: undefined
            };
        case GardenActionTypes.GardenActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case GardenActionTypes.GardenOnServerCreated:
            return {
                ...state
            };
        case GardenActionTypes.GardenCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedGardenId: action.payload.item.id
            });
        case GardenActionTypes.GardenUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case GardenActionTypes.AllGardensLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllGardensLoaded: true
            });
        case GardenActionTypes.GardensPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case GardenActionTypes.GardensPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialGardenState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case GardenActionTypes.GardenDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case GardenActionTypes.GardenRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case GardenActionTypes.GardenDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case GardenActionTypes.GardenAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case GardenActionTypes.GardenDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case GardenActionTypes.GardenTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
