import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ShopFrontTypeModel } from "../_models/shop-front-type.model";

export enum ShopFrontTypeActionTypes {
    AllShopFrontTypesRequested = '[Linked Area] All ShopFrontType Requested',
    AllShopFrontTypesLoaded = '[ShopFrontType Effect] All ShopFrontType Loaded',

    ShopFrontTypeOnServerCreated = '[Add ShopFrontType Page] ShopFrontType On Server Created',
    ShopFrontTypeCreated = '[ShopFrontType Effect] ShopFrontType Created',
    ShopFrontTypeUpdated = '[ShopFrontType Effect] ShopFrontType Updated',
    ShopFrontTypeDeleted = '[ShopFrontType List Page] ShopFrontType Deleted',

    ShopFrontTypeOnServerRestored = '[ShopFrontType Trash] ShopFrontType On Server Restored',
    ShopFrontTypeRestored = '[ShopFrontType Trash] ShopFrontType Restored',

    ShopFrontTypeOnServerAdminRestored = '[ShopFrontType Admin Trash] ShopFrontType On Server Restored',
    ShopFrontTypeAdminRestored = '[ShopFrontType Admin Trash] ShopFrontType Restored',

    ShopFrontTypeDeletedFromTrash = '[ShopFrontType Trash] ShopFrontType deleted',
    ShopFrontTypeDeletedFromAdminTrash = '[ShopFrontType Admin Trash] ShopFrontType deleted',

    ShopFrontTypeTrash = 'ShopFrontType Trashed',
    ShopFrontTypeTrashFlushed = 'ShopFrontType Trash Flushed',

    ShopFrontTypesPageRequested = '[ShopFrontType List Page] ShopFrontType Page Requested',
    ShopFrontTypesPageLoaded = '[ShopFrontType API] ShopFrontType Page Loaded',
    ShopFrontTypesPageCancelled = '[ShopFrontType API] ShopFrontType Page Cancelled',

    ShopFrontTypesPageToggleLoading = '[ShopFrontType page] ShopFrontType Page Toggle Loading',
    ShopFrontTypeActionToggleLoading = '[ShopFrontType] ShopFrontType Action Toggle Loading'
}

export class ShopFrontTypeOnServerCreated implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeOnServerCreated;

    constructor(public payload: { item: ShopFrontTypeModel }) {
    }
}

export class ShopFrontTypeCreated implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeCreated;

    constructor(public payload: { item: ShopFrontTypeModel }) {
    }
}

export class ShopFrontTypeUpdated implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeUpdated;

    constructor(public payload: {
        partialItem: Update<ShopFrontTypeModel>,
        item: ShopFrontTypeModel
    }) {
    }
}

export class ShopFrontTypeDeleted implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ShopFrontTypesPageRequested implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ShopFrontTypesPageLoaded implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypesPageLoaded;

    constructor(public payload: { items: ShopFrontTypeModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ShopFrontTypesPageCancelled implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypesPageCancelled;
}

export class AllShopFrontTypesRequested implements Action {
    readonly type = ShopFrontTypeActionTypes.AllShopFrontTypesRequested;
}

export class AllShopFrontTypesLoaded implements Action {
    readonly type = ShopFrontTypeActionTypes.AllShopFrontTypesLoaded;

    constructor(public payload: { items: ShopFrontTypeModel[] }) {
    }
}

export class ShopFrontTypesPageToggleLoading implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ShopFrontTypeActionToggleLoading implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ShopFrontTypeDeletedFromAdminTrash implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ShopFrontTypeDeletedFromTrash implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ShopFrontTypeOnServerRestored implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ShopFrontTypeRestored implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeRestored;

    constructor(public payload: { item: ShopFrontTypeModel }) {
    }
}

export class ShopFrontTypeOnServerAdminRestored implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ShopFrontTypeAdminRestored implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeAdminRestored;

    constructor(public payload: { item: ShopFrontTypeModel }) {
    }
}

export class ShopFrontTypeTrashFlushed implements Action {
    readonly type = ShopFrontTypeActionTypes.ShopFrontTypeTrashFlushed;

    constructor() {
    }
}


export type ShopFrontTypeActions = ShopFrontTypeCreated
    | ShopFrontTypeUpdated
    | ShopFrontTypeDeleted
    | ShopFrontTypesPageRequested
    | ShopFrontTypesPageLoaded
    | ShopFrontTypesPageCancelled
    | AllShopFrontTypesLoaded
    | AllShopFrontTypesRequested
    | ShopFrontTypeOnServerCreated
    | ShopFrontTypeDeletedFromAdminTrash
    | ShopFrontTypeDeletedFromTrash
    | ShopFrontTypeOnServerRestored
    | ShopFrontTypeRestored
    | ShopFrontTypeOnServerAdminRestored
    | ShopFrontTypeAdminRestored
    | ShopFrontTypeTrashFlushed
    | ShopFrontTypesPageToggleLoading
    | ShopFrontTypeActionToggleLoading;