// Angular
import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {Subscription} from 'rxjs';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToeReport} from '../../../../../core/toe';
import {TypesUtilsService} from '../../../../../core/_base/crud';
import {
    AllReportDeliveryMethodsRequested,
    selectAllReportDeliveryMethods,
    ReportDeliveryMethod,
} from '../../../../../core/linked-tables';

@Component({
    selector: 'kt-toe-report-edit-dialog',
    templateUrl: './toe-report-edit-dialog.component.html'
})
export class ToeReportEditDialogComponent implements OnInit, OnDestroy {
    // // Public properties
    viewLoading = false;
    form: UntypedFormGroup;
    model: ToeReport;
    reportDeliveryMethods: ReportDeliveryMethod[] = [];

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     * @param data: any
     */
    constructor(public dialogRef: MatDialogRef<ToeReportEditDialogComponent>,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                public typesUtilsService: TypesUtilsService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        // fetch reportDeliveryMethods part
        this.store.dispatch(new AllReportDeliveryMethodsRequested());
        
        const reportSubscription = this.store.pipe(
            select(selectAllReportDeliveryMethods))
            .subscribe(res => {
                this.reportDeliveryMethods = [];
                if (res) {
                    this.reportDeliveryMethods = res;
                }
            });
        this.subscriptions.push(reportSubscription);
        
        this.model = this.data.item;
        this.createForm();
    }


    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Close dialog with true result
     */
    save(): void {
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            return;
        }
        this.viewLoading = true;
        const _res = Object.assign({}, this.model) as ToeReport;
        _res.delivery_detail_id = controls.delivery_detail_id.value;
        _res.delivery_detail_name = this.reportDeliveryMethods.find(el => el.id == controls.delivery_detail_id.value).name;
        _res.sent_descr = controls.sent_descr.value;
        let _date = new Date(controls.sent_at.value);
        _res.sent_at = this.typesUtilsService.getDateStringFromDate(_date);
        setTimeout(() => {
            this.dialogRef.close({
                item: _res
            });
        }, 500);
    }

    private createForm() {
        this.form = this.fb.group({
            delivery_detail_id: [this.model.delivery_detail_id, Validators.required],
            sent_at: [{value: this.typesUtilsService.getDateFromString(this.model.sent_at)}, Validators.required],
            sent_descr: [this.model.sent_descr]
        });
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }
}
