// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
// Models
import {AuditTrailParticipantModel} from '../_models/audit-trail-participant.model';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const AUDIT_TRAIL_PATH = '/participants'

@Injectable()
export class AuditTrailParticipantsService {
    API_CUSTOMERS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/audit-trails/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    findAuditTrailParticipants(auditTrailId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_CUSTOMERS_URL + auditTrailId + AUDIT_TRAIL_PATH;
        return this.http.get(url, {
            headers: httpHeaders
        });
    }

    createAuditTrailParticipant(auditTrail: AuditTrailParticipantModel, assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_CUSTOMERS_URL + assignmentId + AUDIT_TRAIL_PATH + '/add';
        return this.http.post<any>(url, auditTrail, {headers: httpHeaders});
    }
}
