import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { distinctUntilChanged, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AddedComparablesRequested, FullComparablesLoadedFromServer, FullComparablesRequested, FullComparablesRequestedOnServer, ImportedComparablesLoadedFromServer, ImportedComparablesRequested, LoadValuationComparables, ValuationComparableActionTypes, ValuationComparablesAddComparableMapData, ValuationComparablesLoaded } from "../_actions/valuation-comparable.actions";
import { selectNotExistingRefs, selectValuationFullComparables, selectValuationProcessSelectedConsiderations } from "../_selectors";
import { ValuationComparableService } from "../_services";
import { AddComparableToValuationProcess } from '../_actions/valuation-process.actions'
import { combineLatest, of } from "rxjs";
import * as _ from "lodash";
import { ValuationProcessSizeCriterionAddMultipleComparables } from "../_actions/size_criterion.actions";
import { ValuationProcessAdjustmentsAddMultipleComparables } from "../_actions/adjustment.actions";
import { ValuationProcessCriterionsAddMultipleComparable } from "../_actions/criterion.actions";
import { ValuationProcessConsiderationCriterionAddMultipleComparables } from "../_actions/consideration-criterion.actions";
import { ValuationProcessValuationAddMultipleComparables } from "../_actions/valuation.actions";

@Injectable()
export class ValuationComparableEffects {
  constructor(
    private service: ValuationComparableService,
    private actions$: Actions,
    private store$: Store<AppState>
  ) { }

  @Effect()
  loadComparables$ =
    this.actions$.pipe(
      ofType<LoadValuationComparables>(ValuationComparableActionTypes.LoadComparables),
      switchMap(({ payload }) => {
        return this.service.load(payload.filter)
      }),
      map(responseModel => {
        return new ValuationComparablesLoaded({ items: responseModel })
      })
    )


  @Effect()
  fullComparablesRequested$ =
    this.actions$.pipe(
      ofType<FullComparablesRequested>(ValuationComparableActionTypes.FullComparablesRequested),
      switchMap(({ payload }) => {
        return this.store$.select(selectNotExistingRefs(payload.refNums)).pipe(take(1))
      }),
      map(refNums => { 
        if (refNums.length == 0) {
          return new FullComparablesLoadedFromServer({items: []})
        }
        return new FullComparablesRequestedOnServer({refNums: refNums})
      })
    )

  @Effect()
  importedComparablesRequested$ = 
    this.actions$.pipe(
      ofType<ImportedComparablesRequested>(ValuationComparableActionTypes.ImportedComparablesRequested),
      switchMap(({payload}) => {
        return combineLatest([
          this.service.loadComparables(payload.refNums),
          of(payload.adjustmentTabData)
        ])
      }),
      map(([response, adjustmentTabData]) => {
        return new ImportedComparablesLoadedFromServer({items: response, adjustmentTabData})
      })
    )

  @Effect({dispatch: false})
  importedComparablesLoaded$ = 
    this.actions$.pipe(
      ofType<ImportedComparablesLoadedFromServer>(ValuationComparableActionTypes.ImportedComparablesLoadedFromServer),
      switchMap(({payload}) => {
        return combineLatest([
          this.store$.pipe(select(selectValuationFullComparables(payload.items.map(item => item.refNum))), take(1)),
          this.store$.pipe(select(selectValuationProcessSelectedConsiderations)).pipe(distinctUntilChanged(_.isEqual)),
          of(payload.adjustmentTabData)
        ])
      }),
      tap(([comparables, considerations, adjustmentTabData]) => {
        this.store$.dispatch(new ValuationProcessSizeCriterionAddMultipleComparables({comparables: comparables, adjustmentTabData}))
        this.store$.dispatch(new ValuationProcessAdjustmentsAddMultipleComparables({refNums: comparables.map(item => item.refNum), adjustmentTabData}))
        this.store$.dispatch(new ValuationProcessCriterionsAddMultipleComparable({comparables, adjustmentTabData}))
        this.store$.dispatch(new ValuationProcessConsiderationCriterionAddMultipleComparables({comparables, selectedConsiderations: considerations, adjustmentTabData}))
        this.store$.dispatch(new ValuationProcessValuationAddMultipleComparables({refNums: comparables.map(item => item.refNum), adjustmentTabData}))
      })
    )

  @Effect()
  fullComparableServeRequest$ =
    this.actions$.pipe(
      ofType<FullComparablesRequestedOnServer>(ValuationComparableActionTypes.FullComparablesRequestedOnServer),
      switchMap(({payload}) => {
        return this.service.loadComparables(payload.refNums)
      }),
      map(response => {
        return new FullComparablesLoadedFromServer({items: response})
      })
    )

   @Effect() 
   addedComparableRequested$ =
      this.actions$.pipe(
        ofType<AddedComparablesRequested>(ValuationComparableActionTypes.AddedComparablesRequested),
        mergeMap(({payload}) => {
          return this.service.loadComparables([payload.refNum])
        }),
        tap(response => {
          this.store$.dispatch(new FullComparablesLoadedFromServer({items: response}))
          this.store$.dispatch(new ValuationComparablesAddComparableMapData({item: {
            id: response[0].id,
            refNum: response[0].refNum,
            propertySubType: response[0].propertySubType,
            latitude: response[0].locationData.latitude,
            longitude: response[0].locationData.longitude
          }}))
        }),
        map(response => {
          return new AddComparableToValuationProcess({refNum: response[0].refNum})
        })
      )
}