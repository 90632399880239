<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Loading</h3>
        </div>
        <div class="card-toolbar">
            <button (click)="close()" [disabled]="(result$|async).loading" style="width: 80px;" class="btn btn-sm btn-success">
                Close
            </button>
        </div>
    </div>
    <div class="card-body" style="display: flex; justify-content: center;">
        <mat-progress-spinner mode="indeterminate" *ngIf="(result$|async).loading"></mat-progress-spinner>
        <div *ngIf="result$|async as res">
            <div *ngIf="res.success">
                Tooltip changed
            </div>
            <div *ngIf="res.error">
                Error occurred. Please try again
            </div>
        </div>
    </div>
</div>