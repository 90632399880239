// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
    selectTpFinancialTasksInStore,
    selectTpFinancialTasksPageLoading,
    selectTpFinancialTasksShowInitWaitingMessage
} from '../_selectors/tp-financial-task.selectors';

export class TpFinancialTasksDataSource extends BaseDataSource {

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectTpFinancialTasksPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTpFinancialTasksShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTpFinancialTasksInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
