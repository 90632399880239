<div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">{{propertyTypeID === propertyTypes.Land ? 'Land Pictures' : 'Property Pictures'}}</h5>
        <hr class="active">
    </div>
    <kt-upload-file
            class="col-md-12"
            [filesOnEditMode]="pictures"
            [folderOnEditMode]="picture"
            [readOnly]="readonly"
            [typeField]="false"
            [featuredPictureAllowed]="false"
            [dropDownValues$]="conditionRatingDropdownValues$"
            [dropDownRelated$]="conditionRatingRelated$"
            fileType="image/*"
            parentForm="apartment"
            split="1"
            [hasThumbnail]="true"
            [addButtonLabel]="propertyTypeID == propertyTypes.Land ? 'Add Land Pictures' : 'Add Property Pictures'">
    </kt-upload-file>
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <mat-error class="tag-custom-error" *ngIf="hasErrors && uploadFileComponent.uploadFiles.length == 0">
            At least 1 Picture <strong>required</strong>
        </mat-error>
        <mat-error class="tag-custom-error" *ngIf="hasErrors && showTitleRequiredError$|async">
            Picture should have a <strong>Title</strong>
        </mat-error>
    </div>
</div>