import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kt-comparable',
    templateUrl: './comparable.component.html',
})
export class ComparableComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
