import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { TpTemplateModel } from "../_models";
import { TpTemplateState } from "../_reducers";

export const selectTpTemplateState = createFeatureSelector<TpTemplateState>('tp-template');

export const selectIsLoading = createSelector(
    selectTpTemplateState,
    state => state.isLoading
)

export const selectTotal = createSelector(
    selectTpTemplateState,
    state => state.total
)

export const selectTemplate = (id: number) => createSelector(
    selectTpTemplateState,
    state => state.entities[id]
)

export const selectTpTemplates = createSelector(
    selectTpTemplateState,
    state => {
        const items: TpTemplateModel[] = [];
        each(state.entities, el => items.push(el));
        return items.sort((a, b) => (b.id - a.id));
    }
)