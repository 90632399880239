// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {BaseOfValueActions, BaseOfValueActionTypes} from '../_actions/base-of-value.actions';
// Models
import {BaseOfValue} from '../_models/base-of-value.model';
import {QueryParamsModel} from '../../_base/crud';

export interface BaseOfValuesState extends EntityState<BaseOfValue> {
    isAllBaseOfValuesLoaded: boolean;
    queryRowsCount: number;
    queryResult: BaseOfValue[];
    lastCreatedBaseOfValueId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BaseOfValue> = createEntityAdapter<BaseOfValue>();

export const initialBaseOfValueState: BaseOfValuesState = adapter.getInitialState({
    isAllBaseOfValuesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedBaseOfValueId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function baseOfValuesReducer(state = initialBaseOfValueState, action: BaseOfValueActions): BaseOfValuesState {
    switch (action.type) {
        case BaseOfValueActionTypes.BaseOfValuesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBaseOfValueId: undefined
            };
        case BaseOfValueActionTypes.BaseOfValueActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case BaseOfValueActionTypes.BaseOfValueOnServerCreated:
            return {
                ...state
            };
        case BaseOfValueActionTypes.BaseOfValueCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedBaseOfValueId: action.payload.item.id
            });
        case BaseOfValueActionTypes.BaseOfValueUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case BaseOfValueActionTypes.AllBaseOfValuesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllBaseOfValuesLoaded: true
            });
        case BaseOfValueActionTypes.BaseOfValuesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case BaseOfValueActionTypes.BaseOfValuesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialBaseOfValueState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case BaseOfValueActionTypes.BaseOfValueDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case BaseOfValueActionTypes.BaseOfValueRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case BaseOfValueActionTypes.BaseOfValueDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case BaseOfValueActionTypes.BaseOfValueAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case BaseOfValueActionTypes.BaseOfValueDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case BaseOfValueActionTypes.BaseOfValueTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
