import {NgModule} from '@angular/core';
import {TruncatePipe} from './truncate.pipe';
import {SafePipe} from './safe.pipe';
import {DateAgoPipe} from './date-ago.pipe';
import {DateStringConvertPipe} from './date-string-converter.pipe';
import {SpaceAfterComma} from './space-after-comma.pipe';

@NgModule({
    imports: [],
    declarations: [
        TruncatePipe,
        SafePipe,
        SpaceAfterComma,
        DateAgoPipe,
        DateStringConvertPipe,
    ],
    exports: [
        TruncatePipe,
        SafePipe,
        SpaceAfterComma,
        DateAgoPipe,
        DateStringConvertPipe
    ]
})
export class CustomPipesModule {
}
