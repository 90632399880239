import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { AppState } from "../../reducers";
import * as valuationSRERActions from '../_actions/valuation-srer.actions';
import { SRERAssumptionType, SRERStatus, ValuationSRERModel } from "../_models/valuation-srer.model";
import { ValuationSRERService } from "../_services/valuation-srer.service";

@Injectable()
export class ValuationSREREffects {
    constructor(private actions$: Actions, private store$: Store<AppState>, private service: ValuationSRERService) {}

    @Effect()
    loadData$ = this.actions$.pipe(
        ofType<valuationSRERActions.LoadData>(valuationSRERActions.ActionTypes.LoadData),
        map(({payload}) => {
            return new valuationSRERActions.DataLoaded({
                data: payload.data
            });
        })
    )

    @Effect()
    updateData$ = this.actions$.pipe(
        ofType<valuationSRERActions.UpdateData>(valuationSRERActions.ActionTypes.UpdateData),
        map(({payload}) => {
            const _update: Update<ValuationSRERModel> = {
                id: payload.data.id,
                changes: {
                    ...payload.data
                }
            };
            return new valuationSRERActions.DataUpdated({
                data: _update
            });
        })
    )

    @Effect()
    restoreAssumption$ = this.actions$.pipe(
        ofType<valuationSRERActions.RestoreData>(valuationSRERActions.ActionTypes.RestoreData),
        map(({payload}) => {
            switch (payload.type) {
                case SRERAssumptionType.Default: {
                    const _restoredAssumption = this.service.restoreDefaultAssumption(payload.tp, payload.data);
                    const _update: Update<ValuationSRERModel> = {
                        id: payload.data.id,
                        changes: {
                            defaultAssumption: _restoredAssumption
                        }
                    }
                    return new valuationSRERActions.DataUpdated({
                        data: _update
                    });
                }
                case SRERAssumptionType.Special: {
                    const _restoredAssumption = this.service.restoreSpecialAssumption(payload.tp, payload.data);
                    const _update: Update<ValuationSRERModel> = {
                        id: payload.data.id,
                        changes: {
                            specialAssumption: _restoredAssumption
                        }
                    }
                    return new valuationSRERActions.DataUpdated({
                        data: _update
                    });
                }
            }
        })
    )

    @Effect()
    removeAssumption$ = this.actions$.pipe(
        ofType<valuationSRERActions.RemoveData>(valuationSRERActions.ActionTypes.RemoveData),
        map(({payload}) => {
            switch (payload.type) {
                case SRERAssumptionType.Default: {
                    const _restoredAssumption = this.service.restoreDefaultAssumption(payload.tp, payload.data);
                    _restoredAssumption.justification = payload.justification;
                    _restoredAssumption.status = SRERStatus.Removed;
                    const _update: Update<ValuationSRERModel> = {
                        id: payload.data.id,
                        changes: {
                            defaultAssumption: _restoredAssumption
                        }
                    }
                    return new valuationSRERActions.DataUpdated({
                        data: _update
                    });
                }
                case SRERAssumptionType.Special: {
                    const _restoredAssumption = this.service.restoreSpecialAssumption(payload.tp, payload.data);
                    _restoredAssumption.justification = payload.justification;
                    _restoredAssumption.status = SRERStatus.Removed;
                    const _update: Update<ValuationSRERModel> = {
                        id: payload.data.id,
                        changes: {
                            specialAssumption: _restoredAssumption
                        }
                    }
                    return new valuationSRERActions.DataUpdated({
                        data: _update
                    });
                }
            }
        })
    )
}