<kt-portlet>
    <kt-portlet-header>
        <ng-container ktPortletTitle>
            <h3>
                <span>Linked Tables</span>
            </h3>
        </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
        <div class="form">
            <div class="form-filtration">
                <div class="row align-items-center active">

                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate" 
                            [(ngModel)]="searchValue"
                            (input)="onChange($event)">
                    </mat-form-field>
                    <mat-form-field class="col-md-3 mat-form-field-fluid">
                        <mat-select placeholder="Type"
                                    [(ngModel)]="selectionValue"
                                    (selectionChange)="selectType()">

                            <mat-option *ngFor="let type of types; let i = index;"
                                        [value]="i">{{type | uppercase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-md-1 kt-margin-t-10-mobile">
                        <button (click)="resetFilter()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <kt-tab-header
            [headerLabels]="tabHeaders"
            (tabChange)="onTabChange($event)"
            [selectedTabChange]="selectedTabChange$">
        </kt-tab-header>
        <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab">
            <mat-tab *ngFor="let link of showData">
                <div class="row align-items-center mb-2">
                    <div class="col-lg-3 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" 
                        *ngFor="let subLink of link.sub | filter :'label' : searchValue : 'includes';">
                        <a class="btn btn-primary"
                        [routerLink]="[subLink.url]"
                        color="primary"
                        mat-raised-button
                        matTooltip="Enter {{subLink.label}}">
                            <span class="kt-hidden-mobile">{{ subLink.label }}</span>
                        </a>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet>
