import { property } from "lodash"
import { ComparableAdded } from "../../valuation/_actions/criterion.actions"
import { ValuationProcessTargetPropertyRemote } from "../_services/valuation-process.service"
import { VBuilding } from "./valuation-asset-class-building.model"
import { displayConsiderations, getGarageRange, mapConsiderationRemoteToDomain, mapVAssetClassSizeRemoteToDomain, mapVLocationRemoteToDomain, VAssetClassConsideration, VAssetClassConsiderationRemote, VAssetClassSize, VAssetClassSizeRemote, VGroundRemote, VInternalAspectRemote, VLocationData, VLocationDataRemote } from "./valuation-asset-class-common.models"
import { PictureModel, TypeOfInspection, ValuationProcessParkingModel, ValuationProcessRetailShopModel, ValuationProcessTargetPropertyInfo } from "./valuation-process-target-property.model"

export type VRetailShopModel = {
    refNum: string
    propertySubType: 'Retail Shop'
    propertySubTypeName: string

    locationData: VLocationData
    property: VRetailShopAboutProperty
    internalAspect: VRetailShopInternalAspect
    ground: VRetailShopGround
    building: VBuilding
    
    oldBuildingInfo: any,
    pictures: PictureModel[]
    sizes: VAssetClassSize[],
}

export type VRetailShopAboutProperty = {
    subTypeCategory: string
    subCategory: string
    stateOfRepair: string
    handoverStandard: string
    floors: string
    generalDescription: string
}

export type VRetailShopInternalAspect = {
    ceiling: string,
    ceilingComment: string,
    wallsAndPartition: string,
    wallsAndPartitionComment: string,
    floors: string,
    floorsComment: string,
    shopFrontType: string,
    shopFrontTypeComment: string,
    builtInFittings: string,
    others: string
}
export type VRetailShopGround = {
    indoorGarage: string
    indoorGarageComment: string,
    outdoorGarage: string,
    outdoorGarageComment: string,
    externalAreas: string,
    externalAreasComment: string
}

export type VCRetailShopModel = VRetailShopModel & {
    id: number,

    considerations: VAssetClassConsideration[],
    displayConsiderations: VAssetClassConsideration[]

    status: number,
    usedTimes: number,
    propertyTypeName: string
}


export type VTPRetailShopModel = VRetailShopModel & {
    refNum: 'tp',
    inspectionInfo: {
        typeOfInspection: TypeOfInspection,
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string 
    }
}

export function mapVTPRetailShopRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessRetailShopModel): VTPRetailShopModel {
    return {
        refNum: 'tp',
        propertySubType: generalInfo.propertySubType,
        propertySubTypeName: tpRemote.propertySubTypeName,
        locationData: {
            latitude: generalInfo.locationData.latitude,
            longitude: generalInfo.locationData.longitude,
            country: generalInfo.locationData.countryName,
            city: generalInfo.locationData.cityName,
            zipCode: generalInfo.locationData.zipCode,
            address: generalInfo.locationData.address,
            locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
            locationSurrounding: tpRemote.locationSurrounding,
            timezoneOffset: generalInfo.locationData.timezoneOffset
        },
        inspectionInfo: {
            typeOfInspection: tpRemote.inspectionTypeId,
            inspectionDate: new Date(tpRemote.inspectionDate),
            anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
            limitationDescription: tpRemote.limitationDesc
        },
        property: {
            subTypeCategory: tpRemote.subTypeCategoryName == 'N/A' ? null : tpRemote.subTypeCategoryName,
            subCategory: tpRemote.subCategoryName == 'N/A' ? null : tpRemote.subCategoryName,
            stateOfRepair: tpRemote.aboutProperty.stateOfRepairName,
            handoverStandard: tpRemote.aboutProperty.handoverStandardName,
            generalDescription: tpRemote.aboutProperty.generalDescription,
            floors: tpRemote.aboutProperty.floorLocation,
        },
        internalAspect: mapVRetailShopInternalAspectRemoteToDomain(tpRemote.internalAspect),
        ground: mapVRetailShopGroundRemoteToDomain(tpRemote.ground),
        building: {
            name: tpRemote.buildingInfo.name,
            completionYear: tpRemote.buildingInfo.completionYear,
            buildingType: tpRemote.buildingInfo.buildingType == 'N/A' || tpRemote.buildingInfo.buildingType == null ? null : tpRemote.buildingInfo.buildingType,
            buildingTypeComment: tpRemote.buildingInfo.buildingTypeComment,
            buildingGrade: tpRemote.buildingInfo.buildingGrade == 'N/A' || tpRemote.buildingInfo.buildingGrade == null ? null : tpRemote.buildingInfo.buildingGrade,
            energyEfficiencyGrade: tpRemote.buildingInfo.energyEfficiencyGrade,
            developer: tpRemote.buildingInfo.developer,
            anchorTenant: tpRemote.buildingInfo.anchorTenant,
            foundationType: tpRemote.buildingInfo.foundationType,
            buildingDescription: tpRemote.buildingInfo.buildingDescription,
            pictures: tpRemote.buildingInfo.pictures
        },
        oldBuildingInfo: tpRemote.oldBuildingInfo,
        pictures: tpRemote.pictures,
        sizes: mapVAssetClassSizeRemoteToDomain(tpRemote.sizes)
    }
}

export type VCRetailShopRemote = {
    id: number,
    property_sub_type_id: 5,

    ref_num: string,
    location_data: VLocationDataRemote,
    sub_type_category_name: string,
    sub_category_name: string,
    general_description: string,
    state_of_repair_name: string,
    handover_standard_name: string,
    floor_location: string,
    internal_aspect: VInternalAspectRemote,
    ground: VGroundRemote

    sizes: VAssetClassSizeRemote[],
    considerations: VAssetClassConsiderationRemote[],
    pictures: PictureModel[],
    buildingInfo: VBuilding,
    oldBuildingInfo: any,

    // Additional
    status: number,
    used_times: number,
    property_sub_type_name: string,
    property_type_name: string,
}

export function mapVCRetailShopRemoteToDomain(remote: VCRetailShopRemote): VCRetailShopModel {
    const considerations = mapConsiderationRemoteToDomain(remote.considerations)
    return {
        id: remote.id,
        refNum: remote.ref_num,
        propertySubType: 'Retail Shop',
        propertySubTypeName: remote.property_sub_type_name,
        propertyTypeName: remote.property_type_name,
        status: remote.status,
        usedTimes: remote.used_times,
        locationData: mapVLocationRemoteToDomain(remote.location_data),
        sizes: mapVAssetClassSizeRemoteToDomain(remote.sizes),
        internalAspect: mapVRetailShopInternalAspectRemoteToDomain(remote.internal_aspect),
        ground: mapVRetailShopGroundRemoteToDomain(remote.ground),
        building: remote.buildingInfo,
        oldBuildingInfo: remote.oldBuildingInfo,
        property: {
            subCategory: remote.sub_category_name,
            subTypeCategory: remote.sub_type_category_name,
            stateOfRepair: remote.state_of_repair_name,
            handoverStandard: remote.handover_standard_name,
            generalDescription: remote.general_description,
            floors: remote.floor_location,
        },
        considerations,
        displayConsiderations: displayConsiderations(considerations),
        pictures: remote.pictures
    }
}

function mapVRetailShopInternalAspectRemoteToDomain(remote: VInternalAspectRemote): VRetailShopInternalAspect {
    return {
        ceiling: remote.ceiling_name,
        ceilingComment: remote.ceiling_comment,
        wallsAndPartition: remote.walls_and_partition_name,
        wallsAndPartitionComment: remote.walls_and_partition_comment,
        floors: remote.floor_type_name,
        floorsComment: remote.floor_type_comment,
        shopFrontType: remote.shop_front_type_name,
        shopFrontTypeComment: remote.shop_front_type_comment,
        builtInFittings: remote.builtin_fitting_names,
        others: remote.has_other ? remote.other_aspects : null
    }
}

function mapVRetailShopGroundRemoteToDomain(remote: VGroundRemote): VRetailShopGround {
    return {
        indoorGarage: getGarageRange(remote.indoor_garage),
        indoorGarageComment: remote.indoor_garage_comment,
        outdoorGarage: getGarageRange(remote.outdoor_garage),
        outdoorGarageComment: remote.outdoor_garage_comment,
        externalAreas: remote.external_areas_name,
        externalAreasComment: remote.external_area_comment
    }
}