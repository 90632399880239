import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassWarehouseActions, AssetClassWarehouseActionTypes } from "../_actions/asset-class-warehouse.actions";
import { AssetClassWarehouseModel } from "../_models/asset-class-warehouse.model";

export interface AssetClassWarehouseState extends EntityState<AssetClassWarehouseModel> {
    actionLoading: boolean;
    listLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    previouslyCreatedACId: number;
    lastCreatedAssetClassWarehouseId: number;
    showInitWaitingMessage: boolean;
}
export const adapter: EntityAdapter<AssetClassWarehouseModel> = createEntityAdapter<AssetClassWarehouseModel>();
export const initialAssetClassWarehouseState: AssetClassWarehouseState = adapter.getInitialState({
    actionLoading: false,
    listLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    previouslyCreatedACId: undefined,
    lastCreatedAssetClassWarehouseId: undefined,
    showInitWaitingMessage: true
});

export function assetClassWarehouseReducer(
    state = initialAssetClassWarehouseState,
    action: AssetClassWarehouseActions
): AssetClassWarehouseState {
    switch (action.type) {
        case AssetClassWarehouseActionTypes.UpdatePreviouslyCreatedACId: {
            return {
                ...state,
                previouslyCreatedACId: state.lastCreatedAssetClassWarehouseId
            }
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehousePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassWarehouseId: undefined
            }
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            }
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerCreated: {
            return state;
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseCreated: {
            return adapter.addOne(
                action.payload.AssetClassWarehouse,
                {...state, previouslyCreatedACId: state.lastCreatedAssetClassWarehouseId, lastCreatedAssetClassWarehouseId: action.payload.AssetClassWarehouse.id}
            )
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseUpdated: {
            return adapter.addOne(
                action.payload.AssetClassWarehouse,
                {...state, lastCreatedAssetClassWarehouseId: action.payload.AssetClassWarehouse.id}
            )
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehousePageLoaded: {
            return adapter.addMany(action.payload.AssetClassWarehouses, {
                ...initialAssetClassWarehouseState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            })
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseDeleted: {
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            })
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseDeletedFromTrash: {
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            }
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseDeletedFromAdminTrash:
            return {...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerRestored: {
            return state
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                lastCreatedAssetClassWarehouseId: action.payload.ac.id,
                totalTrashed: state.totalTrashed - 1
            })
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerAdminRestored: {
            return state
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseAdminRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            })
        }
        case AssetClassWarehouseActionTypes.AssetClassWarehouseTrashFlushed: {
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            }
        }
        default: {
            return state;
        }
    }
}