import { T } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { BaseTemplateDataModel, TemplateTableDataSource } from 'src/app/core/template';

export interface PaginationEvent {
  pageNumber: number;
  pageSize: number;
}

export interface OnDeleteEvent {
  id: number;
  currentPage: number;
  currentPageSize: number;
}

export interface OnDuplicateEvent<T extends BaseTemplateDataModel> {
  template: T;
  currentPage: number;
  currentPageSize: number;
}

@Component({
  selector: 'kt-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateListComponent<T extends BaseTemplateDataModel> implements OnInit, OnDestroy {
  @Input() dataSource: TemplateTableDataSource<T>
  @Output() onLoadList: EventEmitter<PaginationEvent> = new EventEmitter();
  @Output() onDuplicateTemplate: EventEmitter<OnDuplicateEvent<T>> = new EventEmitter();
  @Output() onViewTemplate: EventEmitter<T> = new EventEmitter();
  @Output() onEditTemplate: EventEmitter<T> = new EventEmitter();
  @Output() onDeleteTemplate: EventEmitter<OnDeleteEvent> = new EventEmitter();
  @Input() pageSize: number = 4;
  @Input() isSelection: boolean = false;
  @Output() onSelectTemplate: EventEmitter<T> = new EventEmitter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  columns = ['name', 'description', 'actions'];

  private _onDestroy$: Subject<void> = new Subject();
  canEdit$ = this.store.select(hasPermission(['template:crud']))
  canAdminThrash$ = this.store.select(hasPermission(['admin_trash']))

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.paginator.page.pipe(
      takeUntil(this._onDestroy$),
      tap(() => this._loadList())
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _loadList() {
    this.onLoadList.emit({
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    })
  }

  duplicateTemplate(template: T) {
    this.onDuplicateTemplate.emit({
      template,
      currentPage: this.paginator.pageIndex + 1,
      currentPageSize: this.paginator.pageSize
    });
  }
  viewTemplate(template: T) {
    this.onViewTemplate.emit(template)
  }
  editTemplate(template: T) {
    this.onEditTemplate.emit(template);
  }
  deleteTemplate(template: T) {
    this.onDeleteTemplate.emit({
      id: template.id,
      currentPage: this.paginator.pageIndex + 1,
      currentPageSize: this.paginator.pageSize
    });
  }
  selectTemplate(template: T) {
    this.onSelectTemplate.emit(template);
  }
}
