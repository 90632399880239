import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { OtherJoineryModel } from "../_models/other-joinery.model";

export enum OtherJoineryActionTypes {
    AllOtherJoinerysRequested = '[Linked Area] All OtherJoinery Requested',
    AllOtherJoinerysLoaded = '[OtherJoinery Effect] All OtherJoinery Loaded',

    OtherJoineryOnServerCreated = '[Add OtherJoinery Page] OtherJoinery On Server Created',
    OtherJoineryCreated = '[OtherJoinery Effect] OtherJoinery Created',
    OtherJoineryUpdated = '[OtherJoinery Effect] OtherJoinery Updated',
    OtherJoineryDeleted = '[OtherJoinery List Page] OtherJoinery Deleted',

    OtherJoineryOnServerRestored = '[OtherJoinery Trash] OtherJoinery On Server Restored',
    OtherJoineryRestored = '[OtherJoinery Trash] OtherJoinery Restored',

    OtherJoineryOnServerAdminRestored = '[OtherJoinery Admin Trash] OtherJoinery On Server Restored',
    OtherJoineryAdminRestored = '[OtherJoinery Admin Trash] OtherJoinery Restored',

    OtherJoineryDeletedFromTrash = '[OtherJoinery Trash] OtherJoinery deleted',
    OtherJoineryDeletedFromAdminTrash = '[OtherJoinery Admin Trash] OtherJoinery deleted',

    OtherJoineryTrash = 'OtherJoinery Trashed',
    OtherJoineryTrashFlushed = 'OtherJoinery Trash Flushed',

    OtherJoinerysPageRequested = '[OtherJoinery List Page] OtherJoinery Page Requested',
    OtherJoinerysPageLoaded = '[OtherJoinery API] OtherJoinery Page Loaded',
    OtherJoinerysPageCancelled = '[OtherJoinery API] OtherJoinery Page Cancelled',

    OtherJoinerysPageToggleLoading = '[OtherJoinery page] OtherJoinery Page Toggle Loading',
    OtherJoineryActionToggleLoading = '[OtherJoinery] OtherJoinery Action Toggle Loading'
}

export class OtherJoineryOnServerCreated implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryOnServerCreated;

    constructor(public payload: { item: OtherJoineryModel }) {
    }
}

export class OtherJoineryCreated implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryCreated;

    constructor(public payload: { item: OtherJoineryModel }) {
    }
}

export class OtherJoineryUpdated implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryUpdated;

    constructor(public payload: {
        partialItem: Update<OtherJoineryModel>,
        item: OtherJoineryModel
    }) {
    }
}

export class OtherJoineryDeleted implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OtherJoinerysPageRequested implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoinerysPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OtherJoinerysPageLoaded implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoinerysPageLoaded;

    constructor(public payload: { items: OtherJoineryModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OtherJoinerysPageCancelled implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoinerysPageCancelled;
}

export class AllOtherJoinerysRequested implements Action {
    readonly type = OtherJoineryActionTypes.AllOtherJoinerysRequested;
}

export class AllOtherJoinerysLoaded implements Action {
    readonly type = OtherJoineryActionTypes.AllOtherJoinerysLoaded;

    constructor(public payload: { items: OtherJoineryModel[] }) {
    }
}

export class OtherJoinerysPageToggleLoading implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoinerysPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OtherJoineryActionToggleLoading implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OtherJoineryDeletedFromAdminTrash implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OtherJoineryDeletedFromTrash implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OtherJoineryOnServerRestored implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OtherJoineryRestored implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryRestored;

    constructor(public payload: { item: OtherJoineryModel }) {
    }
}

export class OtherJoineryOnServerAdminRestored implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OtherJoineryAdminRestored implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryAdminRestored;

    constructor(public payload: { item: OtherJoineryModel }) {
    }
}

export class OtherJoineryTrashFlushed implements Action {
    readonly type = OtherJoineryActionTypes.OtherJoineryTrashFlushed;

    constructor() {
    }
}


export type OtherJoineryActions = OtherJoineryCreated
    | OtherJoineryUpdated
    | OtherJoineryDeleted
    | OtherJoinerysPageRequested
    | OtherJoinerysPageLoaded
    | OtherJoinerysPageCancelled
    | AllOtherJoinerysLoaded
    | AllOtherJoinerysRequested
    | OtherJoineryOnServerCreated
    | OtherJoineryDeletedFromAdminTrash
    | OtherJoineryDeletedFromTrash
    | OtherJoineryOnServerRestored
    | OtherJoineryRestored
    | OtherJoineryOnServerAdminRestored
    | OtherJoineryAdminRestored
    | OtherJoineryTrashFlushed
    | OtherJoinerysPageToggleLoading
    | OtherJoineryActionToggleLoading;