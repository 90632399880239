import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ValuationReportModel } from "../_models/valuation-report.model";
import { ValuationReportState } from "../_reducers/valuation-report.reducer";

export const selectValuationReportState = createFeatureSelector<ValuationReportState>('valuation-report');
export const selectValuationReportById = (id: number) => createSelector(
	selectValuationReportState,
    state => state.entities[id]
)
export const selectLastCreatedValuationReportId = createSelector(
    selectValuationReportState,
    state => state.lastCreatedReportId
)
export const selectValuationReportIsGenerating = (tpId: number) => createSelector(
    selectValuationReportState,
    state => state.isGenerating[tpId]
)

export const selectValuationReportsByTpId = (tpId: number) => createSelector(
    selectValuationReportState,
    state => {
        if (!state.isAllReportsLoaded[tpId]) {
            return null;
        }
        const reports: ValuationReportModel[] = [];
        for (const key in state.entities) {
            const val = state.entities[key];
            if (val.tp_id === tpId) {
                reports.push(val);
            }
        }
        reports.sort((a, b) => {
            const d1 = new Date(b.created_at);
            const d2 = new Date(a.created_at);
            return d1.getTime() - d2.getTime();
        })

        return reports.length > 0 ? [reports[0]] : [];
    }
)

export const selectValuationDraftReportsByTpId = (tpId: number) => createSelector(
    selectValuationReportState,
    state => {
        if (!state.isAllReportsLoaded[tpId]) {
            return null;
        }
        const reports: ValuationReportModel[] = [];
        for (const key in state.entities) {
            const val = state.entities[key];
            if (val.tp_id === tpId && val.status == 0) {
                reports.push(val);
            }
        }

        return reports;
    }
)