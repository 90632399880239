import { Action } from "@ngrx/store";
import { AgencyInvolvementWithClient, AgencyInvolvementWithTp, LvInvolvementWithClient, LvInvolvementWithTp, ToeConflictAgency, ToeConflictClient, ToeConflictLeadValuer, ToeConflictTpQuery } from "../_models/toe-conflicts-model";

export enum ToeConflictActionTypes {
    LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient = '[Toe Edit Page] Load Conflicts of Lead valuer\'s previous involvement with the client',
    ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded = '[Toe Conflicts Effect] Conflicts of Lead valuer\'s previous involvement with the client loaded',

    LoadConflictOfAgencyHasPreviousInvolvementWithTheClient = '[Toe Edit Page] Load Conflicts of Agency\'s previous involvement with the client',
    ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded = '[Toe Conflicts Effect] Conflicts of Agency\'s previous involvement with the client loaded',

    LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP = '[Toe Edit Page] Load Conflicts of Lead valuer has already performed a valuation on the TP',
    ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded = '[Toe Conflicts Effect] Conflicts of Lead valuer has already performed a valuation on the TP loaded',

    LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP = '[Toe Edit Page] Load Conflicts of Agency has already performed a valuation on the TP',
    ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded = '[Toe Conflicts Effect] Conflicts of Agency has already performed a valuation on the TP loaded',

    ResetToeConflictsState = '[Toe Edit Page] Reset Toe Conflicts State'
}

export class LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient implements Action {
    readonly type = ToeConflictActionTypes.LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient;

    constructor(public payload: {
        client_id: number,
        lead_valuer_id: number,
        toe_id?: number
    }) {}
}

export class ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded implements Action {
    readonly type = ToeConflictActionTypes.ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded

    constructor(public payload: {
        lead_valuer: ToeConflictLeadValuer,
        client: ToeConflictClient,
        involvements: LvInvolvementWithClient[]
    }) {}
}

export class LoadConflictOfAgencyHasPreviousInvolvementWithTheClient implements Action {
    readonly type = ToeConflictActionTypes.LoadConflictOfAgencyHasPreviousInvolvementWithTheClient

    constructor (public payload: {
        agency_id: number,
        client_id: number,
        toe_id?: number
    }) {}
}

export class ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded implements Action {
    readonly type = ToeConflictActionTypes.ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded

    constructor (public payload: {
        agency: ToeConflictAgency,
        client: ToeConflictClient,
        involvements: AgencyInvolvementWithClient[]
    }) {}
}

export class LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP implements Action {
    readonly type = ToeConflictActionTypes.LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP

    constructor(public payload: {
        lead_valuer_id: number,
        tp_queries: ToeConflictTpQuery[],
        toe_id?: number
    }) {}
}

export class ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded implements Action {
    readonly type = ToeConflictActionTypes.ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded

    constructor(public payload: {
        lead_valuer: ToeConflictLeadValuer,
        involvements: LvInvolvementWithTp[]
    }) {}
}

export class ResetToeConflictsState implements Action {
    readonly type = ToeConflictActionTypes.ResetToeConflictsState
}

export class LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP implements Action {
    readonly type = ToeConflictActionTypes.LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP

    constructor(public payload: {
        agency_id: number,
        tp_queries: ToeConflictTpQuery[],
        toe_id?: number
    }) {}
}

export class ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded implements Action {
    readonly type = ToeConflictActionTypes.ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded

    constructor(public payload: {
        agency: ToeConflictAgency,
        involvements: AgencyInvolvementWithTp[]
    }) {}
}


export type ToeConflictActions 
    = LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient
    | ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded

    | LoadConflictOfAgencyHasPreviousInvolvementWithTheClient
    | ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded

    | LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP
    | ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded

    | LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP
    | ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded

    | ResetToeConflictsState