<div class="timer-container">
    <div class="timer-box-container">
        <div class="timer-box">
            <button class="timer-control-button timer-arrow-button" (click)="increaseNumber('hour')">
                <span class="timer-control-button-content">
                    <svg 
                        xml:space="preserve" 
                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                        height="100%" 
                        style="enable-background:new 0 0 451.847 451.846;" 
                        version="1.1" 
                        viewBox="0 0 451.847 451.846" 
                        width="100%" 
                        x="0px" 
                        xmlns="http://www.w3.org/2000/svg" 
                        y="0px">
                            <path  d="M248.292,106.406l194.281,194.29c12.365,12.359,12.365,32.391,0,44.744c-12.354,12.354-32.391,12.354-44.744,0
                        L225.923,173.529L54.018,345.44c-12.36,12.354-32.395,12.354-44.748,0c-12.359-12.354-12.359-32.391,0-44.75L203.554,106.4
                        c6.18-6.174,14.271-9.259,22.369-9.259C234.018,97.141,242.115,100.232,248.292,106.406z">
                        </path>
                    </svg>
                </span>
            </button>
            <label class="timer-content">
                <input [(ngModel)]="selectedHour">
            </label>
            <button class="timer-control-button timer-arrow-button" (click)="decreaseNumber('hour')">
                <span class="timer-control-button-content">
                    <svg 
                        xml:space="preserve" 
                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                        height="100%" 
                        style="enable-background:new 0 0 451.847 451.846;" 
                        version="1.1" viewBox="0 0 451.847 451.846" width="100%" x="0px" 
                        xmlns="http://www.w3.org/2000/svg" y="0px">
                            <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                        c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                        c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"></path></svg>
                </span>
            </button>
        </div>
        <div>Hour(s)</div>
        <div class="timer-box">
            <button class="timer-control-button timer-arrow-button" (click)="increaseNumber('minute')">
                <span class="timer-control-button-content">
                    <svg 
                        xml:space="preserve" 
                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                        height="100%" 
                        style="enable-background:new 0 0 451.847 451.846;" 
                        version="1.1" 
                        viewBox="0 0 451.847 451.846" 
                        width="100%" 
                        x="0px" 
                        xmlns="http://www.w3.org/2000/svg" 
                        y="0px">
                            <path  d="M248.292,106.406l194.281,194.29c12.365,12.359,12.365,32.391,0,44.744c-12.354,12.354-32.391,12.354-44.744,0
                        L225.923,173.529L54.018,345.44c-12.36,12.354-32.395,12.354-44.748,0c-12.359-12.354-12.359-32.391,0-44.75L203.554,106.4
                        c6.18-6.174,14.271-9.259,22.369-9.259C234.018,97.141,242.115,100.232,248.292,106.406z">
                        </path>
                    </svg>
                </span>
            </button>
            <label class="timer-content">
                <input [(ngModel)]="selectedMinute">
            </label>
            <button class="timer-control-button timer-arrow-button" (click)="decreaseNumber('minute')">
                <span class="timer-control-button-content">
                    <svg 
                        xml:space="preserve" 
                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                        height="100%" 
                        style="enable-background:new 0 0 451.847 451.846;" 
                        version="1.1" viewBox="0 0 451.847 451.846" width="100%" x="0px" 
                        xmlns="http://www.w3.org/2000/svg" y="0px">
                            <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                        c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                        c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"></path></svg>
                </span>
            </button>
        </div>
        <div>Minute(s)</div>
    </div>
    <div class="time-buttons">
        <button class="timer-control-button" (click)="onCancel()">
            <span class="timer-control-button-content action-btn">Cancel</span>
        </button>
        <button class="timer-control-button" (click)="onSet()">
            <span class="timer-control-button-content action-btn">Set</span>
        </button>
    </div>
</div>

