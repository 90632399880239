import { BaseModel } from "../../_base/crud";
import { AcSource } from "./ac-source.model";
import { AssetClassBuilding } from "./asset-class-building.model";
import { InternalAspectData, newInternalAspectData } from "./asset-class-internal-aspect.model";
import { AssetClassSizeModel } from "./asset-class-size.model";
import { AssetClassTenure } from "./asset-class-tenure.model";
import { LocationData } from "./location.model";

export class AssetClassRetailShopModel extends BaseModel {
    id: number;
    ref_num: string;
    status: number;

    formType: number;
    locationData: LocationData;

    // Building
    cp_building_id: number;
    building_name: string;
    completion_year: string;
    is_part_of_property: boolean;
    info_about_property: string;
    building_type: number;
    building_type_comment: string;
    building_grade_id: number;
    energy_efficiency_grade_id: number;
    developer: string;
    anchor_tenant: string;
    foundation_type_id: number;
    building_description: string;
    b_pictures: any[];
    b_picture: string;

    // About property
    subtype_category_id: number;
    sub_category_id: number;
    handover_standard_id: number;
    state_of_repair_id: number;
    floor_details: string;
    accommodation: {
        id: number;
        data: Record<number, Record<string, number>>,
        others: {id: number, title: string}[]
    };
    property_general_desc: string;

    internalAspectData: InternalAspectData;
    grounds: {
        id: number,
        indoor_garage: number,
        indoor_garage_comment: string,

        outdoor_garage: number,
        outdoor_garage_comment: string,

        garden_id: number,
        garden_comment: string,

        outdoor_space_id: number,
        outdoor_space_comment: string,

        external_areas: any[],
        external_area_comment: string,
    }

    ac_pictures: any[];
    picture: string;
    documents: any[];
    tenures: AssetClassTenure[];
    sizes: AssetClassSizeModel[];
    source: AcSource;
    buildingInfo: AssetClassBuilding;

    userDeletedBy: string;
    deletedBy: string;
    createdBy: string;
    lastUpdatedBy: string;

    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    valuation_id: number | null;

    clear() {
        this.id = undefined;
        this.ref_num = null;

        this.formType = 2;
        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        }

        this.building_name = null;
        this.completion_year = null;
        this.is_part_of_property = false;
        this.info_about_property = null;
        this.building_type = null;
        this.building_type_comment = null;
        this.building_grade_id = null;
        this.energy_efficiency_grade_id = null;
        this.developer = null;
        this.anchor_tenant = null;
        this.foundation_type_id = null;
        this.building_description = null;
        this.b_pictures = [];
        this.b_picture = null;

        this.subtype_category_id = null;
        this.sub_category_id = null;
        this.handover_standard_id = null;
        this.state_of_repair_id = null;
        this.floor_details = '';
        this.accommodation = {
            id: undefined,
            data: {},
            others: []
        };

        this.internalAspectData = newInternalAspectData();

        this.grounds = {
            id: undefined,
            indoor_garage: null,
            indoor_garage_comment: null,

            outdoor_garage: null,
            outdoor_garage_comment: null,

            garden_id: null,
            garden_comment: null,

            outdoor_space_comment: null,
            outdoor_space_id: null,

            external_areas: [],
            external_area_comment: null
        }

        this.ac_pictures = [];
        this.picture = '';

        this.documents = [];
        this.tenures = [];
        this.sizes = [];
        this.source = new AcSource();
        this.source.clear();

        this.buildingInfo = new AssetClassBuilding();
        this.valuation_id = null;
    }
}