<!-- <div *ngIf="tooltipConfig$|async as config">
    {{config|json}}
</div> -->

<kt-portlet>
    <kt-portlet-header>
        <ng-container ktPortletTitle>
            <h3>
                <span>Tooltip Editor</span>
            </h3>
        </ng-container>
        <ng-container ktPortletTools>
            <button (click)="back()"
                class="btn mr-2" mat-raised-button>
                <mat-icon>arrow_back</mat-icon>
                <span>
                    {{ 'GENERAL.BUTTON.CLOSE' | translate }}
                </span>
            </button>
            <!-- <button (click)="seeForm()" class="btn btn-secondary mr-2">
                See form
            </button> -->
            <button mat-raised-button class="btn btn-success" (click)="onSubmit()">
                <span>{{'GENERAL.BUTTON.SAVE' | translate}}</span>
            </button>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" [formGroup]="form">
            <ng-container *ngIf="templateData$|async as tData">
                <div class="col-lg-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select [placeholder]="'Select Section'"
                            formControlName="section">
                            <mat-option *ngFor="let s of tData.sections; let i = index" [value]="i">
                                {{s.field}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-lg-12" *ngIf="tooltipSections[form.controls.section.value] && tooltipSections[form.controls.section.value].rawField === 'LT'">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select [placeholder]="'Select Linked Table Section'" 
                            formControlName="lt">
                            <mat-option *ngFor="let ls of tData.ltSections; let i = index" [value]="i">
                                {{ls.field}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-lg-12" *ngIf="tData.data.length > 0">
                    <div class="row">
                        <ng-container *ngFor="let d of tData.data">
                            <div class="col-lg-6" *ngIf="d.size === 'half'">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput type="text" [formControlName]="d.ctrlName" [placeholder]="d.placeholder"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12" *ngIf="d.size === 'full'">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput type="text" [formControlName]="d.ctrlName" [placeholder]="d.placeholder"></textarea>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </form>
    </kt-portlet-body>
</kt-portlet>


