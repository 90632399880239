import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationAssumptionDeparture, Type } from "../_models/valuation-assumption-departure.model";
import * as assumptionDepartureActions from '../_actions/assumption_departure.actions';

export interface DefaultAssumptionState extends EntityState<ValuationAssumptionDeparture> {
    lastCreatedId: number
};
export const defaultAssumptionAdapter: EntityAdapter<ValuationAssumptionDeparture> = createEntityAdapter({
    selectId: (ad: ValuationAssumptionDeparture) => ad.front_id
});
export interface SpecialAssumptionState extends EntityState<ValuationAssumptionDeparture> {
    lastCreatedId: number
};
export const specialAssumptionAdapter: EntityAdapter<ValuationAssumptionDeparture> = createEntityAdapter({
    selectId: (ad: ValuationAssumptionDeparture) => ad.front_id
});
export interface DepartureState extends EntityState<ValuationAssumptionDeparture> {
    lastCreatedId: number
};
export const departureAdapter: EntityAdapter<ValuationAssumptionDeparture> = createEntityAdapter({
    selectId: (ad: ValuationAssumptionDeparture) => ad.front_id
});

export interface ValuationAssumptionDepartureState {
    default: DefaultAssumptionState;
    special: SpecialAssumptionState;
    departure: DepartureState;
}
export const initialValuationAssumptionDepartureState: ValuationAssumptionDepartureState = {
    default: defaultAssumptionAdapter.getInitialState({lastCreatedId: 0}),
    special: specialAssumptionAdapter.getInitialState({lastCreatedId: 0}),
    departure: departureAdapter.getInitialState({lastCreatedId: 0}),
};

export function assumptionDepartureReducer(
    state = initialValuationAssumptionDepartureState,
    action: assumptionDepartureActions.AssumptionDepartureActions
): ValuationAssumptionDepartureState {
    switch (action.type) {
        case assumptionDepartureActions.ActionTypes.DataLoaded: {
            return {
                default: defaultAssumptionAdapter.setAll(action.payload.default, {...state.default, lastCreatedId: action.payload.def_id}),
                special: specialAssumptionAdapter.setAll(action.payload.special, {...state.special, lastCreatedId: action.payload.sp_id}),
                departure: departureAdapter.setAll(action.payload.departure, {...state.departure, lastCreatedId: action.payload.dp_id}),
            }
        }
        case assumptionDepartureActions.ActionTypes.AssumptionOrDepartureAdded: {
            switch (action.payload.data.type) {
                case Type.DefaultAssumption: {
                    return {
                        ...state,
                        default: defaultAssumptionAdapter.addOne(action.payload.data, {...state.default, lastCreatedId: action.payload.id})
                    }
                }
                case Type.SpecialAssumption: {
                    return {
                        ...state,
                        special: specialAssumptionAdapter.addOne(action.payload.data, {...state.special, lastCreatedId: action.payload.id})
                    }
                }
                case Type.Departure: {
                    return {
                        ...state,
                        departure: departureAdapter.addOne(action.payload.data, {...state.departure, lastCreatedId: action.payload.id})
                    }
                }
            }
        }
        case assumptionDepartureActions.ActionTypes.AssumptionOrDepartureEdited: {
            switch (action.payload.type) {
                case Type.DefaultAssumption: {
                    return {
                        ...state,
                        default: defaultAssumptionAdapter.updateOne(action.payload.data, {...state.default})
                    }
                }
                case Type.SpecialAssumption: {
                    return {
                        ...state,
                        special: specialAssumptionAdapter.updateOne(action.payload.data, {...state.special})
                    }
                }
                case Type.Departure: {
                    return {
                        ...state,
                        departure: departureAdapter.updateOne(action.payload.data, {...state.departure})
                    }
                }
            }
        }
        case assumptionDepartureActions.ActionTypes.ToEAssumptionOrDepartureRemoved: {
            switch (action.payload.type) {
                case Type.DefaultAssumption: {
                    return {
                        ...state,
                        default: defaultAssumptionAdapter.updateOne(action.payload.data, {...state.default})
                    }
                }
                case Type.SpecialAssumption: {
                    return {
                        ...state,
                        special: specialAssumptionAdapter.updateOne(action.payload.data, {...state.special})
                    }
                }
                case Type.Departure: {
                    return {
                        ...state,
                        departure: departureAdapter.updateOne(action.payload.data, {...state.departure})
                    }
                }
            }
        }
        case assumptionDepartureActions.ActionTypes.InputAssumptionOrDepartureRemoved: {
            switch (action.payload.type) {
                case Type.DefaultAssumption: {
                    return {
                        ...state,
                        default: defaultAssumptionAdapter.removeOne(action.payload.id, {...state.default})
                    }
                }
                case Type.SpecialAssumption: {
                    return {
                        ...state,
                        special: specialAssumptionAdapter.removeOne(action.payload.id, {...state.special})
                    }
                }
                case Type.Departure: {
                    return {
                        ...state,
                        departure: departureAdapter.removeOne(action.payload.id, {...state.departure})
                    }
                }
            }
        }
        case assumptionDepartureActions.ActionTypes.AssumptionOrDepartureRestored: {
            switch (action.payload.type) {
                case Type.DefaultAssumption: {
                    return {
                        ...state,
                        default: defaultAssumptionAdapter.updateOne(action.payload.data, {...state.default})
                    }
                }
                case Type.SpecialAssumption: {
                    return {
                        ...state,
                        special: specialAssumptionAdapter.updateOne(action.payload.data, {...state.special})
                    }
                }
                case Type.Departure: {
                    return {
                        ...state,
                        departure: departureAdapter.updateOne(action.payload.data, {...state.departure})
                    }
                }
            }
        }
        default:
            return state;
    }
}