import { NgModule } from "@angular/core";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { DropdownMenuComponent } from "./dropdown-menu.component";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule
  ],
  declarations: [
    DropdownMenuComponent
  ],
  exports: [
    DropdownMenuComponent
  ]
})
export class DropdownMenuModule {}