import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";
import { AppState } from "src/app/core/reducers";
import { formControlValueChanges } from "src/app/core/v2/utils/form-value-changes";
import { selectValuationProcessLoadingState, selectValuationProcessValuation } from "src/app/core/valuation-process";
import * as LiquidationValuationActions from "src/app/core/valuation-process/_actions/liquidation-valuation.actions"
import * as LiquidationValuationSelectors from "src/app/core/valuation-process/_selectors/liquidation-valuation.selectors"

@Component({
  selector: 'kt-liquidation-value-estimate-tab',
  templateUrl: './liquidation-value-estimate-tab.component.html',
  styleUrls: ['./liquidation-value-estimate-tab.component.scss'],
})
export class LiquidationValueEstimateTabComponent implements OnInit, OnDestroy {
  loading$ = this.store$.select(selectValuationProcessLoadingState)

  valuation$ = this.store$.select(selectValuationProcessValuation)

  formGroup = new FormGroup({
    disposal_period: new FormControl<number>(null, [Validators.required]),
    disposal_period_type: new FormControl<number>(null, [Validators.required]),
    add_discount: new FormControl<boolean>(false),
    discount: new FormControl<number>(null)
  })
  showDiscount$ = formControlValueChanges(this.formGroup.controls.add_discount)

  private _onDestroy$ = new Subject<void>()

  constructor(
    private store$: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store$.select(LiquidationValuationSelectors.selectLiquidationValueEstimateFormValue).pipe(
      take(1)
    ).subscribe(values => {
      this.formGroup.patchValue({
        disposal_period: values.disposalPeriod,
        disposal_period_type: values.disposalPeriodType,
        add_discount: values.addDiscount,
        discount: values.discount
      })
    })

    this.formGroup.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(values => {
      this.store$.dispatch(LiquidationValuationActions.changeLiquidationValueEstimateFormValues({
        values: {
          disposalPeriod: values.disposal_period ? values.disposal_period : null,
          disposalPeriodType: values.disposal_period_type ? values.disposal_period_type : null,
          addDiscount: values.add_discount,
          discount: values.discount ? Number(values.discount) : null
        }
      }))
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }
}