import {OtherJoineryModel} from '../_models/other-joinery.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {OtherJoinerysState} from '../_reducers/other-joinery.reducer';
import * as fromOtherJoinery from '../_reducers/other-joinery.reducer';
import {each} from 'lodash';

export const selectOtherJoinerysState = createFeatureSelector<OtherJoinerysState>('other-joineries');

export const selectOtherJoineryById = (id: number) => createSelector(
    selectOtherJoinerysState,
    state => state.entities[id]
);

export const selectAllOtherJoinerys = createSelector(
    selectOtherJoinerysState,
    fromOtherJoinery.selectAll
);

export const selectAllOtherJoinerysIds = createSelector(
    selectOtherJoinerysState,
    fromOtherJoinery.selectIds
);

export const allOtherJoinerysLoaded = createSelector(
    selectOtherJoinerysState,
    state => state.isAllOtherJoinerysLoaded
);


export const selectOtherJoinerysPageLoading = createSelector(
    selectOtherJoinerysState,
    state => state.listLoading
);

export const selectOtherJoinerysActionLoading = createSelector(
    selectOtherJoinerysState,
    state => state.actionLoading
);

export const selectLastCreatedOtherJoineryId = createSelector(
    selectOtherJoinerysState,
    state => state.lastCreatedOtherJoineryId
);

export const selectOtherJoinerysShowInitWaitingMessage = createSelector(
    selectOtherJoinerysState,
    state => state.showInitWaitingMessage
);


export const selectTrashedOtherJoineryCount = createSelector(
    selectOtherJoinerysState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedOtherJoineryCount = createSelector(
    selectOtherJoinerysState,
    (state) => state.totalAdminTrashed
);


export const selectOtherJoineryQueryResult = createSelector(
    selectOtherJoinerysState,
    state => {
        const items: OtherJoineryModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OtherJoineryModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
