// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap, take, switchMap} from 'rxjs/operators';
import {of, forkJoin, combineLatest, EMPTY} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {TpTaskService} from '../_services/tp-task.service';
// Actions
import {
    AllTpTaskRequested,
    AllTpTaskLoaded,
    AllTpTaskLoadedByUser,
    TpTaskActionTypes,
    TpTaskActionToggleLoading,
    TpTaskCreated,
    TpTaskOnServerCreated,
    TpTaskUpdated,
    TpTaskDeleted,
    AllTpTaskByUserRequested,
    TpTaskByUserPageRequested,
    TpTaskByUserPageLoaded,
    ManualTpTaskOnServerUpdated,
    ManualTpTaskUpdated,
    BulkUpdateTpTasks,
    TpTasksBulkUpdated,
    DueDiligenceTpTaskOnServerUpdated,
    DueDiligenceTpTaskUpdated,
    AddValuationTaskScenario,
    ValuationTaskScenarioAdded,
    DeleteValuationTaskScenario,
    ValuationTaskScenarioDeleted,
    UpdateMethodOfValuationTask,
    ValuationTaskMethodUpdated,
} from '../_actions/tp-task.actions';
import {QueryParamsModel, TypesUtilsService} from '../../_base/crud';

import {AppState} from '../../reducers';
import { Update } from '@ngrx/entity';
import { selectTpTasksByTpId } from '../_selectors/tp-task.selectors';
import { TpTaskModel } from '../_models/tp-task.model';

@Injectable()
export class TpTaskEffects {

    showActionLoadingDispatcher = new TpTaskActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TpTaskActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTpTask$ = this.actions$
        .pipe(
            ofType<AllTpTaskRequested>(TpTaskActionTypes.AllTpTaskRequested),
            mergeMap(({payload}) => this.service.getAll(payload)),
            map((result: any) => {
                return new AllTpTaskLoaded({
                    tasks: result.data
                });
            })
        );

    @Effect()
    createTpTask$ = this.actions$
        .pipe(
            ofType<TpTaskOnServerCreated>(TpTaskActionTypes.TpTaskOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.task).pipe(
                    tap(res => {
                        if (res) {
                            this.store.dispatch(new TpTaskCreated({
                                task: res.data
                            }));
                        }
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    deleteTpTask$ = this.actions$
        .pipe(
            ofType<TpTaskDeleted>(TpTaskActionTypes.TpTaskDeleted),
            mergeMap(({payload}) => {
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTpTask$ = this.actions$
        .pipe(
            ofType<TpTaskUpdated>(TpTaskActionTypes.TpTaskUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    AllTpTaskByUser$ = this.actions$
        .pipe(
            ofType<AllTpTaskByUserRequested>(TpTaskActionTypes.AllTpTaskByUserRequested),
            mergeMap(({payload}) => {
                const requestToServer = this.service.getByUserId(payload.page, payload.user_id);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map((response: any) => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AllTpTaskLoadedByUser({
                    tasks: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery,
                });
            })
        );

    @Effect()
    loadTpTaskByUserPage$ = this.actions$.pipe(
        ofType<TpTaskByUserPageRequested>(TpTaskActionTypes.TpTaskByUserPageRequested),
        mergeMap(({payload}) => {
            // this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getByUserId(payload.page, payload.user_id);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new TpTaskByUserPageLoaded({
                assetClasses: result.data,
                totalCount: result.pagination.total,
                page: lastQuery
            });
        })
    );

    @Effect()
    manualTpTaskUpdate$ = this.actions$
        .pipe(
            ofType<ManualTpTaskOnServerUpdated>(TpTaskActionTypes.ManualTpTaskOnServerUpdated),
            mergeMap(({payload}) => {
                const dateStr = payload.complated_at ? this.typesUtilsService.getDateStringFromDate(payload.complated_at) : null;
                return this.service.updateManualTask({id: payload.id, completed: payload.completed, completed_at: dateStr})
            }),
            map(response => {
                return new ManualTpTaskUpdated({
                    item: response.data
                })
            })
        )

    @Effect()
    dueDiligenceTpTaskUpdate$ = this.actions$
        .pipe(
            ofType<DueDiligenceTpTaskOnServerUpdated>(TpTaskActionTypes.DueDiligenceTpTaskOnServerUpdated),
            mergeMap(({payload}) => {
                const dateStr = payload.completed_at ? this.typesUtilsService.getDateStringFromDate(payload.completed_at) : null;
                return this.service.updateDueDiligenceTask({id: payload.id, completed: payload.completed, completed_at: dateStr});
            }),
            map(response => {
                return new DueDiligenceTpTaskUpdated({
                    item: response.data
                })
            })
        )

    @Effect()
    bulkUpdateTpTasks$ = this.actions$
        .pipe(
            ofType<BulkUpdateTpTasks>(TpTaskActionTypes.BulkUpdateTpTasks),
            mergeMap(({payload}) => {
                this.service.updateMany(payload.id, payload.user_id, payload.date).subscribe();
                return combineLatest([
                    of(payload),
                    this.store.select(selectTpTasksByTpId(payload.tp_id)).pipe(take(1))
                ])
            }),
            map(([payload, tasks]) => {
                const updates: Update<TpTaskModel>[] = [];
                tasks.filter(t => t.task_id == payload.task_id).map(t => {
                    const update: Update<TpTaskModel> = {
                        id: t.id,
                        changes: {
                            user_id: payload.user_id,
                            user_name: payload.user_name,
                            date: payload.date
                        }
                    };
                    updates.push(update);
                });
                return new TpTasksBulkUpdated({updates});
            })
        )

    @Effect()
    addValuationTaskScenario$ = this.actions$
        .pipe(
            ofType<AddValuationTaskScenario>(TpTaskActionTypes.AddValuationTaskScenario),
            switchMap(({payload}) => {
                return this.service.addScenarioToValuationTask(payload.id, payload.index, payload.type)
            }),
            map(task => {
                return new ValuationTaskScenarioAdded({item: task})
            })
        )

    @Effect()
    deleteValuationTaskScenario$ = this.actions$
        .pipe(
            ofType<DeleteValuationTaskScenario>(TpTaskActionTypes.DeleteValuationTaskScenario),
            mergeMap(({payload}) => {
                return this.service.deleteScenarioOfValuationTask(payload.id)
            }),
            map(res => {
                return new ValuationTaskScenarioDeleted({
                    delete_item_id: res.id,
                    update_items: res.tasks
                })
            })
        )

    @Effect()
    updateValuationTaskMethod$ = this.actions$
        .pipe(
            ofType<UpdateMethodOfValuationTask>(TpTaskActionTypes.UpdateMethodOfValuationTask),
            switchMap(({payload}) => {
                return this.service.updateMethodOfValuationTask(payload.task_id, payload.method_id)
            }),
            map(res => {
                return new ValuationTaskMethodUpdated({
                    task: res.data
                })
            })
        )

    constructor(private actions$: Actions, private service: TpTaskService, private store: Store<AppState>, public typesUtilsService: TypesUtilsService) {
    }
}
