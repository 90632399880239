import {QosDegradation} from '../_models/qos-degradation.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {QosDegradationsState} from '../_reducers/qos-degradation.reducers';
import * as fromQosDegradation from '../_reducers/qos-degradation.reducers';
import {each} from 'lodash';

export const selectQosDegradationsState = createFeatureSelector<QosDegradationsState>('qosDegradations');

export const selectQosDegradationById = (qosDegradationId: number) => createSelector(
    selectQosDegradationsState,
    qosDegradationsState => qosDegradationsState.entities[qosDegradationId]
);

export const selectAllQosDegradations = createSelector(
    selectQosDegradationsState,
    fromQosDegradation.selectAll
);

export const selectAllQosDegradationsIds = createSelector(
    selectQosDegradationsState,
    fromQosDegradation.selectIds
);

export const allQosDegradationsLoaded = createSelector(
    selectQosDegradationsState,
    qosDegradationsState => qosDegradationsState.isAllQosDegradationsLoaded
);


export const selectQosDegradationsPageLoading = createSelector(
    selectQosDegradationsState,
    qosDegradationsState => qosDegradationsState.listLoading
);

export const selectQosDegradationsActionLoading = createSelector(
    selectQosDegradationsState,
    qosDegradationsState => qosDegradationsState.actionLoading
);

export const selectLastCreatedQosDegradationId = createSelector(
    selectQosDegradationsState,
    qosDegradationsState => qosDegradationsState.lastCreatedQosDegradationId
);

export const selectQosDegradationsShowInitWaitingMessage = createSelector(
    selectQosDegradationsState,
    qosDegradationsState => qosDegradationsState.showInitWaitingMessage
);

export const selectTrashedQosDegradationCount = createSelector(
    selectQosDegradationsState,
    (qosDegradationsState) => qosDegradationsState.totalTrashed
);

export const selectAdminTrashedQosDegradationCount = createSelector(
    selectQosDegradationsState,
    (qosDegradationsState) => qosDegradationsState.totalAdminTrashed
);


export const selectQosDegradationQueryResult = createSelector(
    selectQosDegradationsState,
    qosDegradationsState => {
        const items: QosDegradation[] = [];
        each(qosDegradationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: QosDegradation[] = httpExtension.sortArray(items, qosDegradationsState.lastQuery.sortField, qosDegradationsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, qosDegradationsState.totalCount, '', qosDegradationsState.totalTrashed);
    }
);
