import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { MainWallModel } from "../_models/main-wall.model";

export enum MainWallActionTypes {
    AllMainWallsRequested = '[Linked Area] All MainWall Requested',
    AllMainWallsLoaded = '[MainWall Effect] All MainWall Loaded',

    MainWallOnServerCreated = '[Add MainWall Page] MainWall On Server Created',
    MainWallCreated = '[MainWall Effect] MainWall Created',
    MainWallUpdated = '[MainWall Effect] MainWall Updated',
    MainWallDeleted = '[MainWall List Page] MainWall Deleted',

    MainWallOnServerRestored = '[MainWall Trash] MainWall On Server Restored',
    MainWallRestored = '[MainWall Trash] MainWall Restored',

    MainWallOnServerAdminRestored = '[MainWall Admin Trash] MainWall On Server Restored',
    MainWallAdminRestored = '[MainWall Admin Trash] MainWall Restored',

    MainWallDeletedFromTrash = '[MainWall Trash] MainWall deleted',
    MainWallDeletedFromAdminTrash = '[MainWall Admin Trash] MainWall deleted',

    MainWallTrash = 'MainWall Trashed',
    MainWallTrashFlushed = 'MainWall Trash Flushed',

    MainWallsPageRequested = '[MainWall List Page] MainWall Page Requested',
    MainWallsPageLoaded = '[MainWall API] MainWall Page Loaded',
    MainWallsPageCancelled = '[MainWall API] MainWall Page Cancelled',

    MainWallsPageToggleLoading = '[MainWall page] MainWall Page Toggle Loading',
    MainWallActionToggleLoading = '[MainWall] MainWall Action Toggle Loading'
}

export class MainWallOnServerCreated implements Action {
    readonly type = MainWallActionTypes.MainWallOnServerCreated;

    constructor(public payload: { item: MainWallModel }) {
    }
}

export class MainWallCreated implements Action {
    readonly type = MainWallActionTypes.MainWallCreated;

    constructor(public payload: { item: MainWallModel }) {
    }
}

export class MainWallUpdated implements Action {
    readonly type = MainWallActionTypes.MainWallUpdated;

    constructor(public payload: {
        partialItem: Update<MainWallModel>,
        item: MainWallModel
    }) {
    }
}

export class MainWallDeleted implements Action {
    readonly type = MainWallActionTypes.MainWallDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class MainWallsPageRequested implements Action {
    readonly type = MainWallActionTypes.MainWallsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class MainWallsPageLoaded implements Action {
    readonly type = MainWallActionTypes.MainWallsPageLoaded;

    constructor(public payload: { items: MainWallModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class MainWallsPageCancelled implements Action {
    readonly type = MainWallActionTypes.MainWallsPageCancelled;
}

export class AllMainWallsRequested implements Action {
    readonly type = MainWallActionTypes.AllMainWallsRequested;
}

export class AllMainWallsLoaded implements Action {
    readonly type = MainWallActionTypes.AllMainWallsLoaded;

    constructor(public payload: { items: MainWallModel[] }) {
    }
}

export class MainWallsPageToggleLoading implements Action {
    readonly type = MainWallActionTypes.MainWallsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class MainWallActionToggleLoading implements Action {
    readonly type = MainWallActionTypes.MainWallActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class MainWallDeletedFromAdminTrash implements Action {
    readonly type = MainWallActionTypes.MainWallDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class MainWallDeletedFromTrash implements Action {
    readonly type = MainWallActionTypes.MainWallDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class MainWallOnServerRestored implements Action {
    readonly type = MainWallActionTypes.MainWallOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class MainWallRestored implements Action {
    readonly type = MainWallActionTypes.MainWallRestored;

    constructor(public payload: { item: MainWallModel }) {
    }
}

export class MainWallOnServerAdminRestored implements Action {
    readonly type = MainWallActionTypes.MainWallOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class MainWallAdminRestored implements Action {
    readonly type = MainWallActionTypes.MainWallAdminRestored;

    constructor(public payload: { item: MainWallModel }) {
    }
}

export class MainWallTrashFlushed implements Action {
    readonly type = MainWallActionTypes.MainWallTrashFlushed;

    constructor() {
    }
}


export type MainWallActions = MainWallCreated
    | MainWallUpdated
    | MainWallDeleted
    | MainWallsPageRequested
    | MainWallsPageLoaded
    | MainWallsPageCancelled
    | AllMainWallsLoaded
    | AllMainWallsRequested
    | MainWallOnServerCreated
    | MainWallDeletedFromAdminTrash
    | MainWallDeletedFromTrash
    | MainWallOnServerRestored
    | MainWallRestored
    | MainWallOnServerAdminRestored
    | MainWallAdminRestored
    | MainWallTrashFlushed
    | MainWallsPageToggleLoading
    | MainWallActionToggleLoading;