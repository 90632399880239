<div class="form-group kt-form__group row" [formGroup]="form" *ngIf="form">
    <div class="col-lg-12 kt-margin-bottom-20-mobile section-margin-top-60">
        <h5 class="my-3 text-mad text-uppercase">
            Parcel Identification 
        </h5>
        <hr class="active">
    </div>
    <div class="col-lg-12 mb-2">
        <mat-label>Would you like to add information on the land</mat-label>
        <mat-radio-group class="ml-4" formControlName="add_info_on_land">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
    </div>
    <ng-container *ngIf="form.controls.add_info_on_land.value">
        <div class="col-lg-4">
            <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Land Parcel ID" formControlName="land_parcel_name" type="text">
                <mat-error>
                    Land Parcel ID <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6">
            <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Coordinate Reference System" formControlName="coordinate_reference_system_id">
                    <mat-option *ngFor="let coordinateReferenceSystem of coordinateReferenceSystems" 
                        [value]="coordinateReferenceSystem.id">{{coordinateReferenceSystem.name}}</mat-option>
                </mat-select>
                <mat-icon
                    matSuffix
                    class="cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    [ngbPopover]="'TOOLTIP.LAND_PARCEL.CRS.DESCRIPTION' | translate">help
                </mat-icon>
                <mat-error>
                    Coordinate Reference System
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-12 mt-2">
            <mat-label style="color: gray;"> 
                Please draw on the map below the coordinates of each boundary cover or pivot point of the land parcel:
            </mat-label>
            <mat-icon
                matSuffix
                class="cursor-pointer"
                (click)="$event.stopPropagation();"
                container="body"
                [ngbPopover]="'TOOLTIP.LAND_PARCEL.MAP.DESCRIPTION' | translate">help
            </mat-icon>
        </div>
        <div class="col-lg-12 mb-10">
            <kt-land-parcel-map
                [points]="points"
                [centerLat]="centerLat"
                [centerLng]="centerLng"
                [markerInfo]="markerInfo"
                [staticUnit]="staticUnit"
                [staticUnitLengthName]="staticUnitLengthName"
                [staticUnitName]="staticUnitName">
            </kt-land-parcel-map>
        </div>
        <kt-land-area-list
            class="col-lg-12"
            [landAreaComponentListSubject]="landAreaComponentListSubject"
            [landAreaSubject]="landAreaSubject"
            [showOtherFields]="true">
        </kt-land-area-list>
    </ng-container>
</div>