import { CriterionModel } from "./criterion.model";
import { ValuationAdjustmentModel } from "./valuation-adjustment.model";
import { ValuationAssumptionDeparture } from "./valuation-assumption-departure.model";
import { ValuationSRERModel } from "./valuation-srer.model";
import { AdjustmentValuation } from "./valuation.model";
import { ValuationVpga10Matter } from "./vpga10-matter.model";

export interface AdjustmentTabData {
    criterions: CriterionModel[];
    sizeCriterions: CriterionModel[];
    defaultSize: CriterionModel;
    adjustments: ValuationAdjustmentModel[];
    valuations: AdjustmentValuation[];
    justification: string,
    capitalAllowance: number,
    capitalAllowanceJustification: string
}

export function validateAdjustmentTabData(data: AdjustmentTabData): boolean {
    // Validate Consideration
    const considerationCrit = data.criterions.find(c => c.id == 1);
    if (!considerationCrit) {
        return false;
    }
    const considerationUndefined = Object.values(considerationCrit.comValues).filter(v => v == undefined);
    if (considerationUndefined.length != 0) {
        return false;
    }
    return true;
}

export interface AssumptionTabData {
    assumptionsAndDepartures: ValuationAssumptionDeparture[],
    vpga10Matters: ValuationVpga10Matter[],
    valuationSRER: ValuationSRERModel[]
}