<div class="form-group kt-form__group row" [formGroup]="formGroup" [ngClass]="{'form-disabled': readonly}">
    <div class="col-lg-12 kt-margin-bottom-20-mobile section-margin-top-60">
        <div class="d-flex justify-content-between">
            <h5 class="my-3 text-mad text-uppercase">
                {{title$|async}}
            </h5>
            <div *ngIf="(parent == 'Comparable' || parent == 'Inspection') && !readonly">
                <button type="button" *ngIf="parent == 'Comparable'" class="btn btn-primary" mat-raised-button (click)="showExist()">Add existing building</button>
                <button type="button" 
                    style="font-weight: 600; background-color: rgba(255, 0, 0, 0.568)"
                    class="btn btn-primary ml-2" 
                    mat-raised-button
                    *ngIf="buildingId"
                    (click)="clearBuilding()">Clear Data</button>
            </div>
        </div>
        <hr class="active" />
    </div>
    <div class="col-lg-12 mb-0 multimedia-header item-center " *ngIf="lowLevelTitle">
        <h5 class="my-3 text-black text-uppercase">
            {{lowLevelTitle}}
        </h5>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Building ID" formControlName="building_name">
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Completion year" formControlName="completion_year" >
            <mat-error>
                Completion year <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-checkbox class="mat-form-field-fluid margin-top-12" formControlName="is_part_of_property">
            Part of larger property
        </mat-checkbox>
    </div>
    <div class="col-lg-12" *ngIf="formGroup.controls.is_part_of_property.value == 1">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Information about this Property" formControlName="info_about_property"></textarea>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Building Type" formControlName="building_type">
                <mat-option *ngFor="let type of buildingTypes" [value]="type.id">
                    {{type.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                Building type <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Building Grade" formControlName="building_grade_id">
                <mat-option *ngFor="let grade of grades" [value]="grade.id">
                    {{grade.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                Building grade <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Energy Efficiency Grade" formControlName="energy_efficiency_grade_id">
                <mat-option *ngFor="let grade of grades" [value]="grade.id">
                    {{grade.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-12" *ngIf="formGroup.controls.building_type.value != null">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Building Type Comment" formControlName="building_type_comment"></textarea>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Developer" formControlName="developer" >
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Primary anchor tenant" formControlName="anchor_tenant"> 
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Foundation type" formControlName="foundation_type_id">
                <mat-option *ngFor="let type of foundationTypes" [value]="type.id">
                    {{type.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-12">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Construction description" formControlName="building_description"></textarea>
        </mat-form-field>
    </div>
</div>

<div class="form-group row section-margin-top-60" *ngIf="parent == 'Comparable' && showPicSection">
    <div class="col-lg-12">
        <h5 class="my-3 text-mad text-uppercase">
            Building Pictures
        </h5>
        <hr class="active">
    </div>
    <kt-upload-file #buildingPic class="col-lg-12"
        [filesOnEditMode]="pictures"
        [folderOnEditMode]="picture"
        [readOnly]="false"
        [parentForm]="'land'"
        [typeField]="false"
        fileType="image/*"
        split="2"
        [featuredPictureAllowed]="true"
        [hasThumbnail]="true"
        [addButtonLabel]="'Add Building Picture'">
    </kt-upload-file>
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent && uploadFileComponent.uploadFiles.length == 0">
            At least 1 Picture <strong>required</strong>
        </mat-error>
        <mat-error class="tag-custom-error" *ngIf="showTitleRequiredError$|async">
            Picture should have a <strong>Title</strong>
        </mat-error>
    </div>
</div>