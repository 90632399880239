<div *ngIf="!(loading$|async)">
    <ngb-carousel  [interval]="0" *ngIf="data$|async as pictures; else noImage">
        <ng-template  *ngFor="let img of pictures" [id]="img.slideId" ngbSlide>
            <img class="rounded" [src]="img.url" style="height: 700px; width: 100%; object-fit: cover;" (error)="imgOnError($event)"/>
            <div class="image-caption">
                <div>
                    {{img.type + ' (' + img.orderNum + ') - '}} 
                    <span>{{img.name + (img.description ? (' - ' + img.description) : '')}}</span>
                </div>                
                <div class="image-action">
                    <ng-container *ngTemplateOutlet="actionTemplate ? actionTemplate : defaultAction;context:{image: img}"></ng-container>
                </div>
            </div>
        </ng-template>
    </ngb-carousel> 
</div>
<ng-template #noImage>
    <div>No Image is provided</div>
</ng-template>
<ng-template #defaultAction>
</ng-template>