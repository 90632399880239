<kt-portlet>
    <kt-portlet-header
            [title]="getComponentTitle()"
            [class]="'card-head-lg'"
            [sticky]="true"
            [tooltipTitle]="'TOOLTIP.BUILDING.FORM'+(building && building.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.BUILDING.FORM'+(building && building.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate">
        <ng-container ktAdditionalTools>
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup name="radioBasic" [formControl]="formSelectionCtrl">
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                    <input ngbButton type="radio" class="btn-check" [value]="2"> Simple
                  </label>
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-left-white">
                  <input ngbButton type="radio" class="btn-check" [value]="1"> Advanced
                </label>
            </div>
        </ng-container>
        <ng-container ktPortletTools>
            <a href="javascript:;"
               (click)="back()"
               class="btn mr-2" mat-raised-button matTooltip="Back to Building List">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a href="javascript:;"
                color="warn"
                (click)="onSubmit(false)"
                class="btn btn-success mr-2"
                style="background-color: orange !important;"
                mat-raised-button
                matTooltip="Save to Draft">
                <span>Save draft</span>
            </a>
            <a href="javascript:;" class="btn btn-success mr-2" color="submit"
               (click)="onSubmit(true)"
               mat-raised-button matTooltip="Save">
                <span class="kt-hidden-mobile">Save</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" [formGroup]="formGroup" *ngIf="formGroup">
            <kt-tab-header 
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Location</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-map-location
                            [title]="'Location'"
                            [showMap]="true"
                            [lockMarker]="false"
                            [isComplexForm]="isComplexForm"
                            [locationData]="building.locationData"
                            [parentType]="'comparable'"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (centerLatChange)="onCenterLatChange($event)"
                            (centerLngChange)="onCenterLngChange($event)">
                        </kt-map-location>
                        <div class="form-group kt-form__group" *ngIf="isComplexForm">
                            <kt-parcel-identifaction-section
                                [centerLat]="centerLat"
                                [centerLng]="centerLng"
                                [data]="parcelIdentificationData">
                            </kt-parcel-identifaction-section>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Property information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-building-section
                            [parent]="'Building'"
                            [data]="buildingSectionData"></kt-building-section>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                Services and Infrastructures 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-services-and-infrastructures
                                    [propertySubTypeID]="0"
                                    [isComplexForm]="isComplexForm"
                                    [serviceAndInfraData]="building.serviceAndInfraData">
                                </kt-services-and-infrastructures>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                External Aspect 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-external-aspects 
                                    [propertySubTypeID]="0" 
                                    [externalAspectData]="building.externalAspectData"></kt-external-aspects>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                Internal Aspect 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-building-internal-aspects 
                                    [data]="building.internalAspectData"></kt-building-internal-aspects>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                Grounds 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-ac-grounds 
                                    [isComplexForm]="isComplexForm" 
                                    [propertySubTypeID]="0"
                                    [initData]="building.grounds"></kt-ac-grounds>
                            </div>
                        </div>

                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <kt-ac-size [sizesSubject]="sizesSubject" [assetClassType]="'0'"></kt-ac-size>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Pictures
                                </h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #simple class="col-lg-12"
                                [filesOnEditMode]="building.pictures"
                                [folderOnEditMode]="building.picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="1"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Building Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <kt-ac-document-upload *ngIf="isComplexForm" class="col-lg-12" [files]="building.documents">
                            </kt-ac-document-upload>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>