import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ObservedValueOf, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { AssetClassDetailModel, AssetClassLandAreaModel, AssetClassModel, selectLastCreatedAssetClassDetailId } from 'src/app/core/asset_class';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import {Location} from '@angular/common';
import { AuditTrailParticipantModel } from 'src/app/core/assignment';
import { InspectionDetailsComponent } from '../../shared_components/inspection-details/inspection-details.component';
import { LocationData } from 'src/app/core/comparable/_models/location.model';
import { FloorRateModel } from 'src/app/core/asset_class/_models/floor-rate.model';
import { InspectionSizeService } from './inspection-size.service';
import { AssetClassSizeModel, BuildingDataSource } from 'src/app/core/comparable';
import { InspectionSizeMeasurementsComponent } from './inspection-size-measurements/inspection-size-measurements.component';
import { InspectionPicturesComponent } from './inspection-pictures/inspection-pictures.component';
import { UploadVideoComponent } from '../../shared_components/upload-video/upload-video.component';
import { MapLocationComponent } from '../../shared_components/map-location/map-location.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { AssetClassDetailOnServerCreated, AssetClassDetailOnServerUpdated } from 'src/app/core/asset_class/_actions/asset-class-detail.actions';
import { InspectionObservationComponent } from './inspection-observation/inspection-observation.component';
import { LinkListComponent } from '../../shared_components/links/link-list/link-list.component';
import { AboutPropertyComponent } from '../../shared_components/about-property/about-property.component';
import { InspectionLandParcelComponent } from './inspection-land-parcel/inspection-land-parcel.component';
import { ServicesAndInfrastructuresComponent } from '../../shared_components/services-and-infrastructures/services-and-infrastructures.component';
import { ExternalAspectsComponent } from '../../shared_components/external-aspects/external-aspects.component';
import { InternalAspectsComponent } from '../../shared_components/internal-aspects/internal-aspects.component';
import { AcGroundsComponent } from '../../shared_components/ac-grounds/ac-grounds.component';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { BuildingComponent } from '../../shared_components/building/building.component';
import { BuildingSectionComponent } from '../../shared_components/building-section/building-section.component';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { cloneDeep } from 'lodash';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import * as _ from 'lodash';
import { InspectionBuildingPicturesComponent } from './inspection-building-pictures/inspection-building-pictures.component';
import { ConditionRatingDataItem, ConditionRatingV2Service } from '../services/condition-rating-v2.service';
import { Rooms } from '../../shared_components/size-module/mongolia-standard-table/mongolia-standard-table.types';
import { InspectionConditionRatingsUploadComponent } from './inspection-condition-ratings-upload/inspection-condition-ratings-upload.component';
import { BuildingFormComponent } from '../../shared_components/building-form/building-form.component';
import { LocationComponent } from '../../shared_components/location/location.component';
import { environment } from 'src/environments/environment';

const inspectionTypes = [
  {label: 'Desktop valuation', value: 1, default: true},
  {label: 'External Inspection', value: 2, default: false},
  {label: 'Internal inspection', value: 3, default: false},
  {label: 'Full property survey', value: 4, default: false}
]


@Component({
  selector: 'kt-inspection-base',
  templateUrl: './inspection-base.component.html',
  styleUrls: ['./inspection-base.component.scss'],
  providers: [InspectionSizeService, ConditionRatingV2Service]
})
export class InspectionBaseComponent implements OnInit, OnDestroy {
  // @ViewChild(InspectionDetailsComponent, {static: false})
  // inspectionDetailComponent: InspectionDetailsComponent;
  @ViewChild(InspectionLandParcelComponent, {static: false})
  inspectionLandParcelComponent: InspectionLandParcelComponent;
  @ViewChild(AboutPropertyComponent, {static: false})
  aboutPropertyComponent: AboutPropertyComponent;
  @ViewChild(InspectionSizeMeasurementsComponent, {static: false})
  inspectionSizeMeasurementComponent: InspectionSizeMeasurementsComponent;
  @ViewChild(InspectionObservationComponent, {static: false})
  inspectionObservationComponent: InspectionObservationComponent;
  @ViewChild(InspectionPicturesComponent, {static: false})
  inspectionPicturesComponent: InspectionPicturesComponent;
  @ViewChild(UploadVideoComponent, {static: false})
  uploadVideoComponent: UploadVideoComponent;
  @ViewChild(LinkListComponent, {static: false}) 
  linkListComponent: LinkListComponent;
  @ViewChild(LocationComponent, {static: false})
  mapLocationComponent: LocationComponent;
  @ViewChild(ServicesAndInfrastructuresComponent, {static: false})
  servicesAndInfraComponent: ServicesAndInfrastructuresComponent;
  @ViewChild(ExternalAspectsComponent, {static: false})
  externalAspectComponent: ExternalAspectsComponent;
  @ViewChild(InternalAspectsComponent, {static: false})
  internalAspectComponent: InternalAspectsComponent;
  @ViewChild(AcGroundsComponent, {static: false})
  acGroundsComponent: AcGroundsComponent;
  @ViewChild(BuildingComponent, {static: false})
  buildingComponent: BuildingComponent;
  @ViewChild(BuildingSectionComponent, {static: false})
  buildingSectionComponent: BuildingSectionComponent;
  @ViewChild(InspectionBuildingPicturesComponent, {static: false})
  buildingPicturesComponent: InspectionBuildingPicturesComponent;
  @ViewChild(InspectionConditionRatingsUploadComponent, {static: false})
  conditionRatingDocumentUploadComponent: InspectionConditionRatingsUploadComponent;
  @ViewChild(BuildingFormComponent, {static: false})
  buildingFormComponent: BuildingFormComponent;

  readonly: boolean = false;
  isLoaded: boolean = false;
  hasFormError: boolean = false;
  showConditionRating: boolean = true;
  showBuilding: boolean = false;

  assetClass: AssetClassModel;
  assetClassDetail: AssetClassDetailModel;
  formGroup: UntypedFormGroup;

  // Inspection Details
  agencyName: BehaviorSubject<string> = new BehaviorSubject(null);
  clientName: BehaviorSubject<string> = new BehaviorSubject(null);
  participantsSubject = new BehaviorSubject<AuditTrailParticipantModel[]>([]);
  inspectionDetailData: {
    information_date: string;
    source_of_information: string;
    inspection_date: string;
    time_of_inspection: string;
    duration_of_inspection: string;
    any_limitations_or_restrictions: boolean;
    limitation_desc: string;
    inspection_note: string;
    record_potential_env_issues: boolean;
  }

  // Land parcel
  landParcelData: InspectionLandParcelComponent['inputData'];
  points: {lat: number, lng: number}[] = [];
  centerLat = null;
  centerLng = null;

  // Building
  buildingData: BuildingComponent['inputData'];

  // About property
  aboutPropertyData: AboutPropertyComponent['inputData'];
  accommodationData: AboutPropertyComponent['accommodation'];

  // Size and Measurements
  public acSizeInfo: {
    unitAreaMeasurementID: number;
    unitMeasurementID: number;
    measurementStandardID: number;
    standardMeasurementID: number;
    toeUnitOfMeasurementID: number;
  }
  sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
  acMeasurementData: {
    measurement_date: string;
    unit_measurement_id: number;
    measurement_standard_id: number;
    new_measurement_standard_name: string;
    new_custom_measurement_standard_name: string;
    new_measurement_standard_desc: string;
    new_measurement_standard_files: any[];
    purpose_of_the_measurement_instruction: string;
    measurement_methodology_adopted: string;
    scale_of_plan: string;
    floorpans: string;
  }
  staticUnit = 2;

  // Condition ratings
  public ratings$: Observable<FloorRateModel[]>;

  // Observation checklist
  public recordPotentialEnvIssue: boolean = false;

  // Picture
  public conditionRatingDropdownValues$: ReplaySubject<{
      key: number,
      value: string,
  }[]>;
  public conditionRatingRelated$: Subject<{
      key: number,
      value: string,
      path: string,
      name: string,
      title: string,
  }>;

  // Video
  videoTitleRequiredError$ = new BehaviorSubject(false);

  private _onDestroy$: Subject<void> = new Subject();
  private componentSubscription: Subscription;
  private saveState: boolean = false;
  currentUser: User;

  private lastTabName = 'Pictures, Videos & others'
  private caTabName = 'Condition Assessment';
  tabHeaders: TabHeader[] = [
    // {label: 'Inspection', disabled: of(false)},
    // {label: 'Location & Building', disabled: of(false)},
    {label: 'Property Information', disabled: of(false)},
    {label: this.caTabName, disabled: of(false)},
    {label: this.lastTabName, disabled: of(false)},
  ]
  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  onTabChanged(event) {
  }
  error:any = {
    msg: ' Missing Fields in total:',
    fields: []
  }

  public showConditionRating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private recordPotentialEnvIssue$: BehaviorSubject<boolean> = new BehaviorSubject(this.recordPotentialEnvIssue);
  public showConditionAssessmentTab: boolean = false;

  building_pictures = [];
  building_picture = null;
  building$ = new BehaviorSubject(null);

  markerInfo: {lat: number, lng: number, isShow: boolean} = {lat: undefined, lng: undefined, isShow: false};
  onMarkerChange(event) {
    if (event == null)  {
      return;
    }
    this.markerInfo = {
      lat: event.lat,
      lng: event.lng,
      isShow: event.isShown
    }
  }
  timezoneOffset = '';
  onTimezoneChange(event: string) {
    this.timezoneOffset = event
  }

  public roomChange$ = new BehaviorSubject<Rooms>(null);
  public hasComponents = false;

  agencyId$ = new BehaviorSubject<number>(null);
  toeId$ = new BehaviorSubject<number>(null);
  clientId$ = new BehaviorSubject<number>(null);

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private readonlyService: ReadonlyService,
    private location: Location,
    private router: Router,
    private inspectionSizeService: InspectionSizeService,
    public typesUtilsService: TypesUtilsService,
    private formBuilder: UntypedFormBuilder,
    private crV2Service: ConditionRatingV2Service
  ) { }

  ngOnInit(): void {
    this.readonly = this.readonlyService.isReadOnly();

    const userSubscription = this.store.pipe(
        take(1),
        select(currentUser),
        takeUntil(this._onDestroy$))
        .subscribe(res => {
            if (res) {
                this.currentUser = res;
            }
        });

    this.activatedRoute.params.pipe(takeUntil(this._onDestroy$)).subscribe(params => {
      const id = params.asset_class_id;
      if (id && id > 0) {
        this.activatedRoute.data.pipe(takeUntil(this._onDestroy$)).subscribe(res => {
          this.agencyName.next(res.toeData.data.agency_name);
          this.clientName.next(res.toeData.data.client_name);
          this.agencyId$.next(0);
          this.clientId$.next(res.toeData.data.client_id);
          this.toeId$.next(res.toeData.data.id);

          this.assetClass = res.assetClassData.data as AssetClassModel;
          this.tabHeaders = this._changeTabHeaders(this.tabHeaders);

          if (this.assetClass.type_of_inspection === 3 || this.assetClass.type_of_inspection == 4) {
            const result = this.crV2Service.initializeDropdown();
            this.conditionRatingDropdownValues$ = result.dropdown;
            this.conditionRatingRelated$ = result.related;
          }

          this.participantsSubject.next(res.subData.participants);

          const _acSize = new AssetClassSizeModel();
          _acSize.standard_measurement_id = this.assetClass.standard_measurement_id;
          _acSize.standard_measurement_name = this.assetClass.standard_measurement_name;
          _acSize.unit_of_area_measurement_id = res.toeData.data.unit_of_measurement_id == 1 ? 2 : 1;
          _acSize.unit_of_area_measurement_name = res.toeData.data.unit_of_measurement_id == 1 ? 'sqft' : 'sqm';
          _acSize.size = this.assetClass.surface;

          if (res.assetClassDet.new) {
            this.assetClassDetail = new AssetClassDetailModel();
            this.assetClassDetail.clear();
            this.assetClassDetail.locationData = {
              ...this.assetClassDetail.locationData,
              latitude: this.assetClass.latitude,
              longitude: this.assetClass.longitude,
              country_id: this.assetClass.country_id,
              city_id: this.assetClass.city_id,
              zip_code: this.assetClass.zip_code,
              address: this.assetClass.address
            }
            this.crV2Service.setConditionRatings([]);
            this.sizesSubject.next([_acSize]);
          } else {
            this.assetClassDetail = Object.assign({}, res.assetClassDet.data) as AssetClassDetailModel;
            this.recordPotentialEnvIssue = this.assetClassDetail.record_potential_env_issues;
            this.recordPotentialEnvIssue$.next(this.recordPotentialEnvIssue);
            this.showConditionRating = !(this.assetClassDetail.sub_type_category_id == 19 || this.assetClassDetail.sub_type_category_id == 21) 
            this.showConditionRating$.next(this.showConditionRating);
            this.showBuilding = this.assetClass.type_id == 2 && this.assetClassDetail.sub_type_category_id == 20;

            // this.crService.setRatings(res.subData.ratings);
            this.crV2Service.setConditionRatingsFromRatingModels(res.subData.ratings);
            this.sizesSubject.next(res.subData.sizes);
            if (res.subData.sizes.length < 1) {
              this.sizesSubject.next([_acSize]);
            }
          }
          this.centerLat = Number(this.assetClassDetail.locationData.latitude)
          this.centerLng = Number(this.assetClassDetail.locationData.longitude)
          this.markerInfo = {
            lat: this.assetClassDetail.locationData.latitude,
            lng: this.assetClassDetail.locationData.longitude,
            isShow: true
          }

          this.crV2Service.components$.pipe(takeUntil(this._onDestroy$)).subscribe(components => {
            this.hasComponents = components.length > 0;
          })

          this.building_pictures = this.assetClassDetail.building.pictures;
          this.building_picture = this.assetClassDetail.building.picture;

          this.inspectionDetailData = {
            information_date: this.assetClassDetail.information_date,
            source_of_information: this.assetClassDetail.source_of_info,
            inspection_date: this.assetClassDetail.inspection_date,
            time_of_inspection: this.assetClassDetail.time_of_inspection,
            duration_of_inspection: this.assetClassDetail.duration_of_inspection,
            any_limitations_or_restrictions: this.assetClassDetail.any_limitations_or_restrictions,
            limitation_desc: this.assetClassDetail.limitations_desc,
            inspection_note: this.assetClassDetail.inspection_note,
            record_potential_env_issues: this.assetClassDetail.record_potential_env_issues
          }

          this.landParcelData = {
            add_info_on_land: this.assetClassDetail.add_info_on_land,
            land_parcel_name: this.assetClassDetail.land_parcel_name,
            coordinate_reference_system_id: this.assetClassDetail.coordinate_reference_system_id,
            landAreaComponents: this.assetClassDetail.landAreaComponents,
            landArea: this.assetClassDetail.landArea,
          }
          this.points = this.assetClassDetail.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}));

          this.buildingData = {
            building_id: this.assetClassDetail.building.building_id,
            building_name: this.assetClassDetail.building.building_name,
            building_grade_id: this.assetClassDetail.building.building_grade_id,
            completion_year: this.assetClassDetail.building.completion_year,
            is_part_of_property: this.assetClassDetail.building.is_part_of_property,
            info_about_property: this.assetClassDetail.building.info_about_property,
            building_type: this.assetClassDetail.building.building_type,
            energy_efficiency_grade_id: this.assetClassDetail.building.energy_efficiency_grade_id,
            developer: this.assetClassDetail.building.developer,
            anchor_tenant: this.assetClassDetail.building.anchor_tenant,
            foundation_type_id: this.assetClassDetail.building.foundation_type_id,
            building_type_comment: this.assetClassDetail.building.building_type_comment,
            building_description: this.assetClassDetail.building.building_description,
            pictures: this.assetClassDetail.building.pictures,
            picture: this.assetClassDetail.building.picture
          }

          this.aboutPropertyData = {
            sub_category_id: this.assetClassDetail.sub_category_id,
            sub_type_category_id: this.assetClassDetail.sub_type_category_id,
            property_grade_id: this.assetClassDetail.aboutProperty.property_grade_id,
            general_desc: this.assetClassDetail.aboutProperty.general_desc,
            completion_year: this.assetClassDetail.aboutProperty.completion_year,
            apprx_year_extended: this.assetClassDetail.aboutProperty.apprx_year_extended,
            state_of_repair_id: this.assetClassDetail.aboutProperty.state_of_repair_id,
            handover_standard_id: this.assetClassDetail.aboutProperty.handover_standard_id,
            foundation_type_id: this.assetClassDetail.aboutProperty.foundation_type_id,
            above_floors: this.assetClassDetail.aboutProperty.above_floors,
            below_floors: this.assetClassDetail.aboutProperty.below_floors,
            energy_efficiency_grade_id: this.assetClassDetail.aboutProperty.energy_efficiency_grade_id,
            construction_desc: this.assetClassDetail.aboutProperty.construction_desc,
            floor_details: this.assetClassDetail.aboutProperty.floor_details ? this.assetClassDetail.aboutProperty.floor_details : this.assetClass.floors_valuated,
            num_of_units: this.assetClassDetail.aboutProperty.num_of_units,
            parking_type_id: this.assetClassDetail.aboutProperty.parking_type_id,
            automation: this.assetClassDetail.aboutProperty.automation,
            automation_type: this.assetClassDetail.aboutProperty.automation_type,
            floor_numbering_scheme_id: this.assetClassDetail.aboutProperty.floor_numbering_scheme_id 
              ? this.assetClassDetail.aboutProperty.floor_numbering_scheme_id
              : this.assetClass.floor_numbering_scheme_id
          }

          this.accommodationData = this.assetClassDetail.accommodation 

          this.acSizeInfo = {
            unitAreaMeasurementID: this.assetClass.unit_of_area_measurement_id,
            unitMeasurementID: this.assetClassDetail.unit_measurement_id,
            measurementStandardID: this.assetClassDetail.measurement_standard_id,
            standardMeasurementID: this.assetClass.standard_measurement_id,
            toeUnitOfMeasurementID: res.toeData.data.unit_of_measurement_id
          }
          this.staticUnit = this.acSizeInfo.toeUnitOfMeasurementID == 1 ? 2 : 1;

          this.acMeasurementData = {
            measurement_date: this.assetClassDetail.measurement_date,
            unit_measurement_id: this.assetClassDetail.unit_measurement_id ? this.assetClassDetail.unit_measurement_id : this.assetClass.unit_of_area_measurement_id,
            measurement_standard_id: this.assetClassDetail.measurement_standard_id ? this.assetClassDetail.measurement_standard_id : this.assetClass.standard_measurement_id,
            purpose_of_the_measurement_instruction: this.assetClassDetail.purpose_of_the_measurement_instruction && this.assetClassDetail.purpose_of_the_measurement_instruction.trim() != '' 
              ? this.assetClassDetail.purpose_of_the_measurement_instruction
              : this.assetClass.purpose_measurement_name,
            scale_of_plan: this.assetClassDetail.scale_of_plan,
            floorpans: this.assetClassDetail.floorpans,
            measurement_methodology_adopted: this.assetClassDetail.measurement_methodology_adopted && this.assetClassDetail.measurement_methodology_adopted.trim() != ''
              ? this.assetClassDetail.measurement_methodology_adopted
              : this.assetClass.measurement_methodology_name,
            new_measurement_standard_name: this.assetClass.new_standard_measurement_name, 
            new_custom_measurement_standard_name: this.assetClassDetail.new_measurement_standard_name ? this.assetClassDetail.new_measurement_standard_name : this.assetClass.new_standard_measurement_name,
            new_measurement_standard_desc: this.assetClassDetail.new_measurement_standard_desc,
            new_measurement_standard_files: this.assetClassDetail.new_measurement_standard_files,

          }

          this.isLoaded = true;

          this.formGroup = this.formBuilder.group({
            record_potential_env_issues: [this.assetClassDetail.record_potential_env_issues]
          });
          this.formGroup.controls.record_potential_env_issues.valueChanges.pipe(
            takeUntil(this._onDestroy$)
          ).subscribe(val => {
            this.recordPotentialEnvIssue = val;
          })
        });
      }
    })

  }

  private _changeTabHeaders(ths: TabHeader[]): TabHeader[] {
    const tabheaders = cloneDeep(ths);
    let title = 'Location & Building';
    if (this.assetClass.type_id == 17 || this.assetClass.type_id == 11 || this.assetClass.type_id == 2 || this.assetClass.type_id == 7) {
      title = 'Location & Land Identification';
    }
    tabheaders.splice(0, 0, {label: title, disabled: of(false)});
    return tabheaders;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }

  getComponentTitle(): string {
    if (!this.assetClass) {
      return '';
    }
    if (this.assetClass.type_of_inspection > 0) {
      return `Property Sub-Type ${this.assetClass.type_name} - ${inspectionTypes[this.assetClass.type_of_inspection - 1].label} Details for "${this.assetClass.name}" ${this.readonly ? '(READ ONLY)' : ''}`;
    }
    return `Property Sub-Type ${this.assetClass.type_name} Details ${this.readonly ? '(READ ONLY)' : ''}`;
  }

  back() {
    this.location.back();
  }

  onStaticUnitChange(staticUnit: number) {
    this.staticUnit = staticUnit;
  }

  onSubmit(isComplete: boolean = true) {
    let errorFields = [];
    this.hasFormError = false;
    this.videoTitleRequiredError$.next(false);

    // Inspection Detail section
    // if (this.inspectionDetailComponent) {
    //   if (isComplete && !this.inspectionDetailComponent.checkValidation(isComplete)) {
    //     this.hasFormError = true;
    //     errorFields = [...errorFields, ...this.inspectionDetailComponent.errorFields];
    //   }
    // }
    // Map Location
    if (this.mapLocationComponent) {
      if (isComplete && this.mapLocationComponent.validate()) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
      }
    }

    // Land parcel
    if (this.inspectionLandParcelComponent) {
      if (isComplete && !this.inspectionLandParcelComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.inspectionLandParcelComponent.errorFields];
      }
    }

    // External aspects
    if (this.externalAspectComponent) {
      if (isComplete && this.externalAspectComponent.validate()) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.externalAspectComponent.errorFields];
      }
    }
    // Internal aspects
    if (this.internalAspectComponent) {
      if (isComplete && this.internalAspectComponent.validate()) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.internalAspectComponent.errorFields];
      }
    }
    // Grounds
    if (this.acGroundsComponent) {
      if (isComplete && this.acGroundsComponent.validate()) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.acGroundsComponent.errorFields];
      }
    }

    // Building
    if (this.buildingComponent) {
      if (isComplete && !this.buildingComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.buildingComponent.errorFields];
      }
    }

    if (this.buildingSectionComponent) {
      if (isComplete && this.buildingSectionComponent.validate()) {
        this.hasFormError = true
        errorFields = [...errorFields, ...this.buildingSectionComponent.errorFields];
      }
    }
    if (this.buildingFormComponent) {
      if (isComplete && this.buildingFormComponent.validate()) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.buildingFormComponent.errorFields.map(f => 'building_form_' + f)];
      }
    }

    // About property
    if (this.aboutPropertyComponent) {
      if (isComplete && !this.aboutPropertyComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.aboutPropertyComponent.errorFields];
      }
    }


    // Size and measurements
    if (this.inspectionSizeMeasurementComponent) {
      if (isComplete && !this.inspectionSizeMeasurementComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.inspectionSizeMeasurementComponent.errorFields];
      }
    }

    // Condition ratings

    // Pictures
    if (this.inspectionPicturesComponent) {
      if (isComplete && !this.inspectionPicturesComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.inspectionPicturesComponent.errorFields];
      }
    }

    if (this.buildingPicturesComponent) {
      if (isComplete && !this.buildingPicturesComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.buildingPicturesComponent.errorFields];
      }
    }

    // Videos
    let videoTitleRequired = false;
    if (isComplete && this.uploadVideoComponent) {
      const videos = this.uploadVideoComponent.uploadedVideos;
      videos.forEach(video => {
        if (video.title === null || video.title === '') {
          this.hasFormError = true;
          videoTitleRequired = true;
        }
      })
    }
    this.videoTitleRequiredError$.next(videoTitleRequired);

    // Matterport link

    // Condition Rating Documents
    if (this.conditionRatingDocumentUploadComponent) {
      if (isComplete && !this.hasComponents && !this.conditionRatingDocumentUploadComponent.checkValidation(isComplete)) {
        this.hasFormError = true;
        errorFields = [...errorFields, ...this.conditionRatingDocumentUploadComponent.errorFields];
      }
    }

    const {ac, ..._acData} = this._prepareAssetClassDetail(isComplete);

    if (this.hasFormError) {
      this.error.fields = errorFields;
      return;
    }

    // const _acDetail = this._prepareAssetClassDetail(isComplete);
    if (ac.id) {
      this.updateAssetClassDetail(ac, _acData);
    } else {
      this.createAssetClassDetail(ac, _acData);
    }
  }

  onRecordPotentialChange(event: boolean) {
    this.recordPotentialEnvIssue = event;
    this.recordPotentialEnvIssue$.next(this.recordPotentialEnvIssue);
  }

  onFloorChanged(event: {floors: number[], scheme_id: number}) {
    this.crV2Service.floorChanged(event);
    this.inspectionSizeService.setFloor(event);
  }

  private _prepareAssetClassDetail(isComplete: boolean): {
    ac: AssetClassDetailModel,
    sizes: AssetClassSizeModel[],
    fileList: any[],
    selectedFacilities: any[],
    selectedFacilitiesOffice: any[],
    selectedFacilitiesOfficeExclusive: any[],
    externalAreas: any[],
    ratings: FloorRateModel[],
    participants: AuditTrailParticipantModel[],
    userId: number,
    sizeModuleData: any
  } {
    let sizes = [];
    let fileList = [];
    let ratings = [];
    let participants = [];
    let sizeModuleData = null;
    const _acDetail = new AssetClassDetailModel();
    _acDetail.clear();
    _acDetail.id = this.assetClassDetail.id;
    _acDetail.is_complete = Number(isComplete);
    _acDetail.tp_id = this.assetClass.id;
    _acDetail.user_id = this.currentUser.id;


    const controls = this.formGroup.controls;
// 
    // Inspection Details
    // if (this.inspectionDetailComponent) {
    //   const data = this.inspectionDetailComponent.getData();
    //   _acDetail.information_date = data.information_date;
    //   _acDetail.source_of_info = data.source_of_information;
    //   _acDetail.inspection_date = data.inspection_date;
    //   _acDetail.time_of_inspection = data.time_of_inspection;
    //   _acDetail.duration_of_inspection = data.duration_of_inspection;
    //   _acDetail.any_limitations_or_restrictions = data.any_limitations_or_restrictions;
    //   _acDetail.limitations_desc = data.limitation_desc;
    //   _acDetail.inspection_note = data.inspection_note;
    //   _acDetail.record_potential_env_issues = this.formGroup.controls.record_potential_env_issues.value;

    //   participants = this.inspectionDetailComponent.getParticipants();
    // }
    
    _acDetail.record_potential_env_issues = this.formGroup.controls.record_potential_env_issues.value;
    if (this.mapLocationComponent) {
      _acDetail.locationData = this.mapLocationComponent.getData();
    }

    if (this.inspectionLandParcelComponent) {
      const data = this.inspectionLandParcelComponent.getData();
      _acDetail.add_info_on_land = data.add_info_on_land;
      _acDetail.land_parcel_name = data.land_parcel_name;
      _acDetail.coordinate_reference_system_id = data.coordinate_reference_system_id;
      _acDetail.landArea = data.landArea;
      _acDetail.points = data.points;
      _acDetail.landAreaComponents = data.landAreaComponents;
    }

    if (this.buildingComponent) {
      _acDetail.building = {
        ...this.buildingComponent.getData(), 
        id: this.assetClassDetail.building.id,
        building_id: null,
        pictures: [],
        picture: null
      };
    }

    if (this.buildingSectionComponent) {
      _acDetail.building = {
        ...this.buildingSectionComponent.getDataForComparable(),
        id: this.assetClassDetail.building.id,
      }
    }
    if (this.buildingFormComponent) {
      _acDetail.buildingInfo = {
        ...this.buildingFormComponent.getData(),
        id: this.assetClassDetail.buildingInfo.id
      };
    }

    if (this.aboutPropertyComponent) {
      const data = this.aboutPropertyComponent.getData();
      _acDetail.accommodation = data.accommodation;
      _acDetail.aboutProperty = {
        id: this.assetClassDetail.aboutProperty.id,
        property_grade_id: data.about.property_grade_id,
        general_desc: data.about.general_desc,
        completion_year: data.about.completion_year,
        apprx_year_extended: data.about.apprx_year_extended,
        state_of_repair_id: data.about.state_of_repair_id,
        handover_standard_id: data.about.handover_standard_id,
        foundation_type_id: data.about.foundation_type_id,
        above_floors: data.about.above_floors,
        below_floors: data.about.below_floors,
        energy_efficiency_grade_id: data.about.energy_efficiency_grade_id,
        construction_desc: data.about.construction_desc,
        floor_details: data.about.floor_details,
        num_of_units: data.about.num_of_units,
        parking_type_id: data.about.parking_type_id,
        automation: data.about.automation,
        automation_type: data.about.automation_type,
        floor_numbering_scheme_id: data.about.floor_numbering_scheme_id
      }
      _acDetail.sub_type_category_id = data.about.sub_type_category_id;
      _acDetail.sub_category_id = data.about.sub_category_id;
    }

    if (this.servicesAndInfraComponent) {
      _acDetail.serviceAndInfraData = this.servicesAndInfraComponent.getdata();
    }

    if (this.externalAspectComponent) {
      _acDetail.externalAspectData = this.externalAspectComponent.getData();
    }

    if (this.internalAspectComponent) {
      _acDetail.internalAspectData = this.internalAspectComponent.getData();
    }

    if (this.acGroundsComponent) {
      _acDetail.grounds = {...this.acGroundsComponent.getData(), id: this.assetClassDetail.grounds.id};
    }

    // Sizes and measurements
    if (this.inspectionSizeMeasurementComponent) {
      const data = this.inspectionSizeMeasurementComponent.getData();
      _acDetail.measurement_date = data.measurement_date;
      _acDetail.measurement_methodology_adopted = data.measurement_methodology_adopted;
      _acDetail.scale_of_plan = data.scale_of_plan;
      _acDetail.floorpans = data.floorpans;
      _acDetail.purpose_of_the_measurement_instruction = data.purpose_of_the_measurement_instruction;
      _acDetail.unit_measurement_id = data.unit_measurement_id;
      _acDetail.measurement_standard_id = data.measurement_standard_id;
      _acDetail.new_measurement_standard_name = data.new_measurement_standard_name;
      _acDetail.new_measurement_standard_desc = data.new_measurement_standard_desc;
      _acDetail.new_measurement_standard_files = data.new_measurement_standard_files;
      _acDetail.new_custom_measurement_standard_name = data.new_custom_measurement_standard_name;

      sizeModuleData = this.inspectionSizeMeasurementComponent.getSizeModuleData();
      sizes = this.sizesSubject.value;
    }

    // Condition Ratings
    ratings = this.crV2Service.getRatings();

    // Observation
    if (this.inspectionObservationComponent) {
      const data = this.inspectionObservationComponent.getData();
      _acDetail.observation_checklist = data.checkList;
      _acDetail.observation_documents = data.documents;
    }

    // Pictures
    if (this.inspectionPicturesComponent) {
      const data = this.inspectionPicturesComponent.getData();
      _acDetail.picture = data.picture;
      fileList = data.pictures;
    }

    if (this.buildingPicturesComponent) {
      const data = this.buildingPicturesComponent.getData();
      _acDetail.building.picture = data.picture;
      _acDetail.building.pictures = data.pictures;
    }

    // Videos
    if (this.uploadVideoComponent) {
      _acDetail.videos = this.uploadVideoComponent.uploadedVideos;
    }

    // Link
    if (this.linkListComponent) {
      _acDetail.links = this.linkListComponent.links;
    }

    // Condition Rating Documents
    if (this.conditionRatingDocumentUploadComponent) {
      _acDetail.condition_rating_documents = this.conditionRatingDocumentUploadComponent.getDocuments();
    }

    return { 
      ac: _acDetail ,
      sizes,
      fileList,
      selectedFacilities: [],
      selectedFacilitiesOfficeExclusive: [],
      selectedFacilitiesOffice: [],
      externalAreas: [],
      ratings,
      participants,
      userId: this.currentUser.id,
      sizeModuleData,
    };
  }

  /**
     * Create assignment
     *
     * @param _assetClassDetail: AssetClassDetailModel
     */
  createAssetClassDetail(
    _assetClassDetail: AssetClassDetailModel, {
    sizes,
    fileList,
    selectedFacilities,
    selectedFacilitiesOffice,
    selectedFacilitiesOfficeExclusive,
    externalAreas,
    ratings,
    participants,
    userId,
    sizeModuleData
  }) { 

    this.store.dispatch(new AssetClassDetailOnServerCreated({
      assetClass: _assetClassDetail,
      sizes,
      fileList,
      selectedFacilities,
      selectedFacilitiesOffice,
      selectedFacilitiesOfficeExclusive,
      externalAreas,
      ratings,
      participants,
      userId,
      sizeModuleData
    }));

    this.componentSubscription = this.store.pipe(
        select(selectLastCreatedAssetClassDetailId),
        delay(1000), // Remove this line
    ).subscribe(res => {
        if (!res) {
            return;
        }
        this.saveState = true;
        this.navToList();
    });
  }

  /**
   * Update assignment
   *
   * @param _assetClassDetail: AssetClassDetailModel
   */
  updateAssetClassDetail(_assetClassDetail: AssetClassDetailModel, {
    sizes,
    fileList,
    selectedFacilities,
    selectedFacilitiesOffice,
    selectedFacilitiesOfficeExclusive,
    externalAreas,
    ratings,
    participants,
    userId,
    sizeModuleData
  }) {
    this.store.dispatch(new AssetClassDetailOnServerUpdated({
      assetClass: _assetClassDetail,
      sizes,
      fileList,
      selectedFacilities,
      selectedFacilitiesOffice,
      selectedFacilitiesOfficeExclusive,
      externalAreas,
      ratings,
      participants,
      userId,
      sizeModuleData
    }));
    this.saveState = true;
    this.navToList();
  }

  navToList() {
      //  http://192.168.244.150:8803/default/assignment/assignments/2/toes/1/dashboard
      //     http://192.168.244.150:8803/default/assignment/assignments/2/toes/1/inspection/7
      this.router.navigate(['../../', 'dashboard'], {relativeTo: this.activatedRoute});
  }

  onSubTypeCategoryChange(id: number) {
    if (id == 19 || id == 21) {
      this.showConditionRating = false;
      this.showBuilding = false;
    } else {
      this.showConditionRating = true;
      this.showBuilding = true;
    }
    this.showConditionRating$.next(this.showConditionRating);
  }

  onBuildingSelected(building: any) {
    this.building$.next(building)
  }

  erroTabMap() {
    return this.typesUtilsService.getInspectionTabErrorMap(this.error.fields, this.tabHeaders);
  }
  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormError = obj.hasFormErrors;
  }

  buildingPicChange({pictures, picture}) {
    this.building_pictures = pictures;
    this.building_picture = picture;
  }

  onCenterChange(locationData) {
    this.centerLat = locationData.latitude;
    this.centerLng = locationData.longitude;
    this.assetClassDetail = Object.assign({}, this.assetClassDetail, {
      locationData: {
        ...this.assetClassDetail.locationData,
        latitude: (Number(locationData.latitude)),
        longitude: (Number(locationData.longitude)),
        address: locationData.address,
        location_grade_id: locationData.location_grade_id,
        zip_code: locationData.zip_code,
        city_id: locationData.city_id,
        location_surrounding: locationData.location_surrounding
      }
    });
  }

  onRoomChanged(event: Rooms) {
    this.roomChange$.next(event);
  }
}
