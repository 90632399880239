import {MethodsToValue} from '../_models/methods-to-value.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {MethodsToValuesState} from '../_reducers/methods-to-value.reducers';
import * as fromMethodsToValue from '../_reducers/methods-to-value.reducers';
import {each} from 'lodash';

export const selectMethodsToValuesState = createFeatureSelector<MethodsToValuesState>('methodsToValues');

export const selectMethodsToValueById = (methodsToValueId: number) => createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => methodsToValuesState.entities[methodsToValueId]
);

export const selectAllMethodsToValues = createSelector(
    selectMethodsToValuesState,
    fromMethodsToValue.selectAll
);

export const selectAllMethodsToValuesIds = createSelector(
    selectMethodsToValuesState,
    fromMethodsToValue.selectIds
);

export const allMethodsToValuesLoaded = createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => methodsToValuesState.isAllMethodsToValuesLoaded
);


export const selectMethodsToValuesPageLoading = createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => methodsToValuesState.listLoading
);

export const selectMethodsToValuesActionLoading = createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => methodsToValuesState.actionLoading
);

export const selectLastCreatedMethodsToValueId = createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => methodsToValuesState.lastCreatedMethodsToValueId
);

export const selectMethodsToValuesShowInitWaitingMessage = createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => methodsToValuesState.showInitWaitingMessage
);

export const selectTrashedMethodsToValueCount = createSelector(
    selectMethodsToValuesState,
    (methodsToValuesState) => methodsToValuesState.totalTrashed
);

export const selectAdminTrashedMethodsToValueCount = createSelector(
    selectMethodsToValuesState,
    (methodsToValuesState) => methodsToValuesState.totalAdminTrashed
);


export const selectMethodsToValueQueryResult = createSelector(
    selectMethodsToValuesState,
    methodsToValuesState => {
        const items: MethodsToValue[] = [];
        each(methodsToValuesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: MethodsToValue[] = httpExtension.sortArray(items, methodsToValuesState.lastQuery.sortField, methodsToValuesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, methodsToValuesState.totalCount, '', methodsToValuesState.totalTrashed);
    }
);
