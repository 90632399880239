import {IncludedInValuation} from '../_models/included-in-valuation.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {IncludedInValuationsState} from '../_reducers/included-in-valuation.reducers';
import * as fromIncludedInValuation from '../_reducers/included-in-valuation.reducers';
import {each} from 'lodash';

export const selectIncludedInValuationsState = createFeatureSelector<IncludedInValuationsState>('includedInValuations');

export const selectIncludedInValuationById = (includedInValuationId: number) => createSelector(
    selectIncludedInValuationsState,
    includedInValuationsState => includedInValuationsState.entities[includedInValuationId]
);

export const selectAllIncludedInValuations = createSelector(
    selectIncludedInValuationsState,
    fromIncludedInValuation.selectAll
);

export const selectAllIncludedInValuationsIds = createSelector(
    selectIncludedInValuationsState,
    fromIncludedInValuation.selectIds
);

export const allIncludedInValuationsLoaded = createSelector(
    selectIncludedInValuationsState,
    includedInValuationsState => includedInValuationsState.isAllIncludedInValuationsLoaded
);


export const selectIncludedInValuationsPageLoading = createSelector(
    selectIncludedInValuationsState,
    includedInValuationsState => includedInValuationsState.listLoading
);

export const selectIncludedInValuationsActionLoading = createSelector(
    selectIncludedInValuationsState,
    includedInValuationsState => includedInValuationsState.actionLoading
);

export const selectLastCreatedIncludedInValuationId = createSelector(
    selectIncludedInValuationsState,
    includedInValuationsState => includedInValuationsState.lastCreatedIncludedInValuationId
);

export const selectIncludedInValuationsShowInitWaitingMessage = createSelector(
    selectIncludedInValuationsState,
    (includedInValuationsState) => includedInValuationsState.showInitWaitingMessage
);

export const selectTrashedIncludedInValuationCount = createSelector(
    selectIncludedInValuationsState,
    (includedInValuationsState) => includedInValuationsState.totalTrashed
);

export const selectAdminTrashedIncludedInValuationCount = createSelector(
    selectIncludedInValuationsState,
    (includedInValuationsState) => includedInValuationsState.totalAdminTrashed
);




export const selectIncludedInValuationQueryResult = createSelector(
    selectIncludedInValuationsState,
    includedInValuationsState => {
        const items: IncludedInValuation[] = [];
        each(includedInValuationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: IncludedInValuation[] = httpExtension.sortArray(items, includedInValuationsState.lastQuery.sortField, includedInValuationsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, includedInValuationsState.totalCount, '', includedInValuationsState.totalTrashed);
    }
);
