import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../reducers';
import { QueryParamsModel } from '../../_base/crud';
import { 
    AllValuationStandardLoaded,
    AllValuationStandardRequested,
    ValuationStandardActionToggleLoading,
    ValuationStandardActionTypes,
    ValuationStandardAdminRestored,
    ValuationStandardCreated,
    ValuationStandardDeleted,
    ValuationStandardDeletedFromAdminTrash,
    ValuationStandardDeletedFromTrash,
    ValuationStandardOnServerAdminRestored,
    ValuationStandardOnServerCreated,
    ValuationStandardOnServerRestored,
    ValuationStandardPageLoaded,
    ValuationStandardPageRequested,
    ValuationStandardPageToggleLoading, 
    ValuationStandardRestored, 
    ValuationStandardTrashFlushed, 
    ValuationStandardUpdated
} from '../_actions/valuation-standard.actions';
import { allValuationStandardLoaded } from '../_selectors/valuation-standard.selector';
import { ValuationStandardService } from '../_services/valuation-standard.service';

@Injectable()
export class ValuationStandardEffects {
    constructor(
        private actions$: Actions,
        private service: ValuationStandardService,
        private store: Store<AppState>
    ) {}

    showPageLoadingDispatcher = new ValuationStandardPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ValuationStandardActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ValuationStandardActionToggleLoading({isLoading: false});

    @Effect()
    loadAllValuationStandard$ = this.actions$
        .pipe(
            ofType<AllValuationStandardRequested>(ValuationStandardActionTypes.AllValuationStandardRequested),
            withLatestFrom(this.store.pipe(select(allValuationStandardLoaded))),
            filter(([action, isAllValutionStandardLoaded]) => !isAllValutionStandardLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllValuationStandardLoaded({
                    valuationStandards: res.data
                })
            })
        );

    @Effect()
    loadValuationStandardPage$ = this.actions$
        .pipe(
            ofType<ValuationStandardPageRequested>(ValuationStandardActionTypes.ValuationStandardPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ValuationStandardPageLoaded({
                    valuationStandards: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            })
        );

    @Effect()
    updateValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardUpdated>(ValuationStandardActionTypes.ValuationStandardUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.valuationStandard);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    createValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardOnServerCreated>(ValuationStandardActionTypes.ValuationStandardOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.valuationStandard).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuationStandardCreated({valuationStandard: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushValuationStandardTrash$ = this.actions$
        .pipe(
            ofType<ValuationStandardTrashFlushed>(ValuationStandardActionTypes.ValuationStandardTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardOnServerRestored>(ValuationStandardActionTypes.ValuationStandardOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuationStandardRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardOnServerAdminRestored>(ValuationStandardActionTypes.ValuationStandardOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ValuationStandardAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardDeleted>(ValuationStandardActionTypes.ValuationStandardDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardDeletedFromTrash>(ValuationStandardActionTypes.ValuationStandardDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashValuationStandard$ = this.actions$
        .pipe(
            ofType<ValuationStandardDeletedFromAdminTrash>(ValuationStandardActionTypes.ValuationStandardDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}