import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AssetClassLandActionToggleLoading, AssetClassLandActionTypes, AssetClassLandAdminRestored, AssetClassLandCreated, AssetClassLandDeleted, AssetClassLandDeletedFromAdminTrash, AssetClassLandDeletedFromTrash, AssetClassLandDuplicate, AssetClassLandOnServerAdminRestored, AssetClassLandOnServerCreated, AssetClassLandOnServerRestored, AssetClassLandOnServerUpdated, AssetClassLandPageLoaded, AssetClassLandPageRequested, AssetClassLandPageToggleLoading, AssetClassLandRestored, AssetClassLandUpdated } from "../_actions/asset-class-land.actions";
import { AssetClassLandService } from "../_services/asset-class-land.service";

@Injectable()
export class AssetClassLandEffects {
    showPageLoadingDispatcher = new AssetClassLandPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassLandActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassLandActionToggleLoading({isLoading: false});

    constructor(
        private actions$: Actions,
        private service: AssetClassLandService,
        private store: Store<AppState>
    ) {}

    @Effect()
    loadAssetClassLandPage$ = this.actions$.pipe(
        ofType<AssetClassLandPageRequested>(AssetClassLandActionTypes.AssetClassLandPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery = response[1];
            return new AssetClassLandPageLoaded({
                assetClassLands: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            })
        })
    )

    @Effect()
    createAssetClassLand$ = this.actions$.pipe(
        ofType<AssetClassLandOnServerCreated>(AssetClassLandActionTypes.AssetClassLandOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                payload.assetClassLand,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassLandCreated({assetClassLand: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    updateAssetClassLand$ = this.actions$.pipe(
        ofType<AssetClassLandOnServerUpdated>(AssetClassLandActionTypes.AssetClassLandOnServerUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateAssetClass(
                payload.assetClassLand,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassLandUpdated(
                        {assetClassLand: res.data}
                    ))
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassLand$ = this.actions$.pipe(
        ofType<AssetClassLandDeleted>(AssetClassLandActionTypes.AssetClassLandDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteAssetClass(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteAssetClassLandFromTrash$ = this.actions$.pipe(
        ofType<AssetClassLandDeletedFromTrash>(AssetClassLandActionTypes.AssetClassLandDeletedFromTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassLandFromAdminTrash$ = this.actions$.pipe(
        ofType<AssetClassLandDeletedFromAdminTrash>(AssetClassLandActionTypes.AssetClassLandDeletedFromAdminTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromAdminTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAssetClassLand$ = this.actions$.pipe(
        ofType<AssetClassLandOnServerRestored>(AssetClassLandActionTypes.AssetClassLandOnServerRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassLandRestored({acLand: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAdminAssetClassLand$ = this.actions$.pipe(
        ofType<AssetClassLandOnServerAdminRestored>(AssetClassLandActionTypes.AssetClassLandOnServerAdminRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassLandAdminRestored({acLand: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    duplicateLand$ = this.actions$.pipe(
        ofType<AssetClassLandDuplicate>(AssetClassLandActionTypes.DuplicateAssetClass),
        mergeMap(({payload}) => {
            return this.service.duplicate(payload.id)
        }),
        map((response) => {
            return new AssetClassLandCreated({assetClassLand: response.data})
        })
    )
}