import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { ValuationVpga10Matter } from '../_models/vpga10-matter.model';
import * as reducer from '../_reducers/vpga10-matters.reducer';
export const selectValuationVpga10MattersState = createFeatureSelector<reducer.ValuationVpga10MattersState>('valuation-vpga10-matters');
export const selectAll = createSelector(
    selectValuationVpga10MattersState,
    state => {
        const items: ValuationVpga10Matter[] = [];
        each(state.entities, el => items.push(el));
        items.sort(function (a, b) {
            return a.source - b.source;
        })
        return items;
    }
)
export const selectLastCreatedID = createSelector(
    selectValuationVpga10MattersState,
    state => state.lastCreatedId
)