<kt-portlet>
    <kt-portlet-header
            [title]="getComponentTitle()"
            [class]="'card-head-lg'"
            [tooltipTitle]="'TOOLTIP.LANDMARK.FORM'+(keyPlace && keyPlace.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.LANDMARK.FORM'+(keyPlace && keyPlace.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a href="javascript:;"
               (click)="back()"
               class="btn mr-2" mat-raised-button 
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                    back_page: ('LANDMARK.FORM.TITLE.LIST' | translate)
                }"
            >
            <mat-icon>arrow_back</mat-icon>
            <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.CLOSE' | translate }}</span>
            </a>
            <a href="javascript:" class="btn btn-success mr-3" color="submit"
               (click)="onSubmit()"
               [disabled]="!isFormValid()"
               mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.SAVE' | translate">
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" *ngIf="keyPlace" [formGroup]="keyPlaceForm">
            <!--<form class="kt-form" *ngIf="assignment" [formGroup]="assignmentForm">-->
            <div class="card-bogy pt-0">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="form-group row">
                    <div class="col-md-12 kt-margin-bottom-20-mobile">
                        <h5 class="my-3 text-mad text-uppercase">{{ 'LANDMARK.FORM.FIELD.LOCATION.SECTION_TITLE' | translate }}</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                                   [placeholder]="'LANDMARK.FORM.FIELD.LOCATION.SEARCH_BY_CITY.PLACEHOLDER' | translate" autocorrect="off" autocapitalize="off"
                                   spellcheck="off" #search/>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer"
                                    (click)="$event.stopPropagation();"
                                    [popoverTitle]="'TOOLTIP.LANDMARK.LOCATE.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.LANDMARK.LOCATE.DESCRIPTION' | translate">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-2">
                        <agm-map #map
                                 [latitude]="centerLat$ | async"
                                 [longitude]="centerLng$ | async"
                                 [zoom]="zoom"
                                 [scrollwheel]="null"
                                 (mapReady)="mapReady($event)"
                                 [clickableIcons]="false">
                            <agm-map-type-control></agm-map-type-control>

                            <agm-marker
                                    *ngIf="isShown"
                                    [latitude]="lat"
                                    [longitude]="lng"
                                    [markerDraggable]="true"
                                    (dragEnd)="markerDragEnd($event)">
                            </agm-marker>
                        </agm-map>
                    </div>

                    <!-- <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'LANDMARK.FORM.FIELD.LOCATION.WHAT3WORDS.PLACEHOLDER' | translate" formControlName="what3words"/>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('LANDMARK.FORM.FIELD.LOCATION.WHAT3WORDS.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div> -->

                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'LANDMARK.FORM.FIELD.LOCATION.KEY_PLACE_NAME.PLACEHOLDER' | translate" formControlName="name"/>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('LANDMARK.FORM.FIELD.LOCATION.KEY_PLACE_NAME.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'LANDMARK.FORM.FIELD.LOCATION.KEY_PLACE_CATEGORY.PLACEHOLDER' | translate" formControlName="category_id">
                                <mat-option *ngFor="let category of allCategories" [value]="category.id">{{category.name}}</mat-option>
                            </mat-select>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('LANDMARK.FORM.FIELD.LOCATION.KEY_PLACE_CATEGORY.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-b-10-mobile mb-2">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput rows="4" [placeholder]="'LANDMARK.FORM.FIELD.LOCATION.DESCRIPTION.PLACEHOLDER' | translate" formControlName="description"></textarea>
                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('LANDMARK.FORM.FIELD.LOCATION.DESCRIPTION.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--            PICTURES           -->
                <h4 class="kt-section__title text-uppercase">{{ 'LANDMARK.FORM.FIELD.PICTURE.SECTION_TITLE' | translate }}</h4>
                <div *ngIf="error">
                    {{ error.message }}
                </div>
                <div class="example-container mat-elevation-z0">
                    <div class="kt-portlet__head-progress" *ngIf="(progress$ | async) > 0">
                        <!--<mat-progress-bar mode="determinate" value="40"></mat-progress-bar>-->
                        <mat-progress-bar mode="determinate" color="war" [value]="progress$ | async "></mat-progress-bar>
                    </div>
                    <input #fileInputImg type="file" (change)="uploadFile($event)" style="display: none" accept="image/*"/>
                    <mat-table #table [dataSource]="dataSource">
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef>{{ 'LANDMARK.FORM.DATATABLE.PICTURE.TYPE' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let file">
                                <img [attr.src]="typesUtilsService.setIcon(typesUtilsService.getFileType(keyPlace.picture))" alt="">
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>{{ 'LANDMARK.FORM.DATATABLE.PICTURE.TITLE' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let file"> {{file.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="file_name">
                            <mat-header-cell *matHeaderCellDef>{{ 'LANDMARK.FORM.DATATABLE.PICTURE.FILE_NAME' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let file"> {{typesUtilsService.getFileNameFromPath(keyPlace.picture)}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>{{ 'LANDMARK.FORM.DATATABLE.PICTURE.ACTIONS' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let file">
                                <button mat-icon-button
                                        color="primary"
                                        [matTooltip]="'LANDMARK.FORM.BUTTON.UPLOAD_FILE.TOOLTIP' | translate"
                                        (click)="addFiles()"
                                        [disabled]="(progress$ | async) > 0"
                                        *ngIf="typesUtilsService.getFileNameFromPath(keyPlace.picture) == awConst.NOFILE"
                                        type="button">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>&nbsp;
                                <button mat-icon-button
                                        color="warn"
                                        [matTooltip]="'LANDMARK.FORM.BUTTON.DELETE_FILE.TOOLTIP' | translate"
                                        (click)="deleteUploadedFile()"
                                        type="button"
                                        *ngIf="typesUtilsService.getFileNameFromPath(keyPlace.picture) != awConst.NOFILE"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>&nbsp;
                                <button mat-icon-button
                                    [matTooltip]="'LANDMARK.FORM.BUTTON.PREVIEW_FILE.TOOLTIP' | translate"
                                        (click)="previewUploadedFile()"
                                        type="button"
                                        *ngIf="typesUtilsService.getFileNameFromPath(keyPlace.picture) != awConst.NOFILE">
                                    <mat-icon *ngIf="file.type == 'jpeg'">remove_red_eye</mat-icon>
                                    <mat-icon *ngIf="file.type == 'pdf'">get_app</mat-icon>
                                </button>&nbsp;
                            </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'required_file' : typesUtilsService.getFileNameFromPath(keyPlace.picture) == awConst.NOFILE}">
                        </mat-row>
                    </mat-table>
                    <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="hasFormErrors && keyPlaceForm.controls.picture.value == ''"
                        [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                            field: ('LANDMARK.FORM.FIELD.PICTURE.SECTION_TITLE' | translate)
                        }"
                    >
                    </mat-error>
                </div>
            </div>
        </form>
    </kt-portlet-body>
</kt-portlet>