import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { AssetClassModel, ValuationModel } from "src/app/core/asset_class";
import { AppState } from "src/app/core/reducers";
import {
    ReportingStored,
    ReportingUpdated,
} from "src/app/core/reporting/_actions/reporting_process.actions";
import {
    ReportingProcessData,
    ReportingToeRelatedDocuments,
    ReportingValuationExchanges,
} from "src/app/core/reporting/_models/reporting_process.model";
import { ToeModel } from "src/app/core/toe";
import {
    LayoutUtilsService,
    MessageType,
    TypesUtilsService,
} from "src/app/core/_base/crud";
import { Location } from "@angular/common";
import { SupportingDocumentsComponent } from "./supporting-documents/supporting-documents.component";
import { DesignLayoutComponent } from "./design-layout/design-layout.component";
import { property } from "lodash";
import { VpgaTwoComponent } from "./vpga-two/vpga-two.component";
import { AgencyModel } from "src/app/core/admin";
import { SupportingValuerModel } from "src/app/core/toe/_models/supporting-valuer.model";
import { DraftStatementReportModel } from "src/app/core/asset_class/_models/draft-statement-report.model";
import { selectValuationDraftReportsByTpId } from "src/app/core/asset_class/_selectors/valuation-report.selectors";
import { ValuationReportModel } from "src/app/core/asset_class/_models/valuation-report.model";
import { GeneralValuationMattersComponent } from "./general-valuation-matters/general-valuation-matters.component";
import { Member } from "../types";
import { GroupedValuations, groupValuationsWithTaskInfoByMainValuation } from "../reporting-process.utils";

@Component({
    selector: "kt-reporting-settings-v2",
    templateUrl: "./reporting-settings-v2.component.html",
    styleUrls: ["./reporting-settings-v2.component.scss"],
})
export class ReportingSettingsV2Component implements OnInit, OnDestroy {
    @Input() readonly: boolean = false;
    @Input() 
    valuations: ValuationModel[] = [];
    @Input()
    valuationsWithScenario: {valuation: any, valuation_index: number, scenario: {
        scenario_number: number
    }}[] = []
    @Input()
    tp: AssetClassModel = new AssetClassModel();
    @Input()
    reporting: ReportingProcessData;
    @Input()
    toe: ToeModel;
    @Input()
    userID: number;
    @Input()
    agency: AgencyModel;
    @Input()
    leadValuer: SupportingValuerModel;
    @Input()
    members: SupportingValuerModel[];
    @Input()
    toeMembers: Member[]

    @ViewChild(SupportingDocumentsComponent)
    supportingDocuments: SupportingDocumentsComponent;
    // @ViewChild(DesignLayoutComponent)
    // designLayoutComponent: DesignLayoutComponent;
    @ViewChild(VpgaTwoComponent)
    vpgaTwoComponent: VpgaTwoComponent;
    @ViewChild(GeneralValuationMattersComponent)
    generarValuationMattersComponent: GeneralValuationMattersComponent;

    form: UntypedFormGroup;
    hasError$ = new BehaviorSubject<boolean>(false);
    errorMessage$ = new BehaviorSubject<string>(null);
    draftReport: ValuationReportModel = null;

    groupedValuations: Array<GroupedValuations> = [];

    private _onDestroy$: Subject<void> = new Subject()

    constructor(
        private formBuilder: UntypedFormBuilder,
        public typesUtilsService: TypesUtilsService,
        private layoutUtilsService: LayoutUtilsService,
        private store$: Store<AppState>,
        private translate: TranslateService,
        private location: Location
    ) {}

    ngOnDestroy(): void {
        this._onDestroy$.next()
        this._onDestroy$.complete()
    }

    ngOnInit(): void {
        this.groupedValuations = groupValuationsWithTaskInfoByMainValuation(this.valuationsWithScenario) 
        this.store$
            .select(selectValuationDraftReportsByTpId(this.tp.id))
            .subscribe((reports) => {
                if (!reports) {
                    return;
                }
                if (reports.length > 0) {
                    this.draftReport = reports[0];
                } else {
                    this.draftReport = null;
                }
            });

        const exchangeRateGrp = this.formBuilder.group({});
        this.valuations.forEach((val) => {
            const exchangeRate = this.reporting.exchange_rates.find(
                (r) => r.val_id == val.id
            );
            if (exchangeRate) {
                const formControl = new UntypedFormControl(
                    exchangeRate.exchange_rate,
                    this.tp.country_currency !== this.tp.reporting_currency
                        ? Validators.required
                        : null
                );
                exchangeRateGrp.addControl(
                    `${val.id}_exchange_rate`,
                    formControl
                );
            } else {
                const formControl = new UntypedFormControl(
                    null,
                    this.tp.country_currency !== this.tp.reporting_currency
                        ? Validators.required
                        : null
                );
                exchangeRateGrp.addControl(
                    `${val.id}_exchange_rate`,
                    formControl
                );
            }
        });

        this.form = this.formBuilder.group({
            valuation_cover_picture: [
                this.reporting.valuation_cover_picture,
                Validators.required,
            ],
            comparable_pictures: [
                this.reporting.comparable_pictures,
                Validators.required,
            ],
            tp_pictures: [this.reporting.tp_pictures, Validators.required],
            tp_pictures_all_or_custom: [
                this.reporting.tp_pictures_all_or_custom,
            ],
            valuation_scenario: this._valuationScenarioFormGroup(this.groupedValuations, this.reporting.valuation_scenarios),
            valuation: [this.reporting.val_id, Validators.required],
            valuation_justification: [
                this.reporting.val_justification,
                this.groupedValuations.length > 1 ? Validators.required : null,
            ],
            valuation_date: [
                this.reporting.valuation_date,
                Validators.required,
            ],
            change_in_market: [
                this.reporting.change_in_market_conditions,
                Validators.required,
            ],
            has_team_signatures: [
                this.reporting.has_team_signature ? 1 : 0,
                Validators.required
            ],
            signatures: this._signatureFormArray(this.toeMembers, this.reporting.team_signature_ids),
            change_in_market_justification: [
                this.reporting.change_in_market_conditions_explained,
            ],
            market_identification_n_input_levels: [
                this.reporting.market_identification_n_input_levels,
                this.toe.purpose_of_valuation_id == 13
                    ? Validators.required
                    : null,
            ],
            base_of_value_adopted: [
                this.reporting.base_of_value_adopted,
                this.toe.purpose_of_valuation_id == 13
                    ? Validators.required
                    : null,
            ],
            base_of_value_adopted_desc: [
                this.reporting.base_of_value_adopted_desc,
            ],
            use_round_up: [this.reporting.use_round_up],
            val_exchange: exchangeRateGrp,
            draft_is_shared_to_client: [
                this.reporting.draft_is_shared_to_client,
            ],
            has_material_change: [this.reporting.has_material_change],
            generalComments: this.formBuilder.group({
                price_been_disclosed: [
                    this.reporting.general_comments.price_been_disclosed,
                ],
                price_been_disclosed_descr: [
                    this.reporting.general_comments.price_been_disclosed_descr,
                ],
                suitability_of_property: [
                    this.reporting.general_comments.suitability_of_property,
                ],
                circumstances_could_affect_price: [
                    this.reporting.general_comments
                        .circumstances_could_affect_price,
                ],
                demand_for_alternative_use: [
                    this.reporting.general_comments.demand_for_alternative_use,
                ],
                occupational_demand_for_property: [
                    this.reporting.general_comments
                        .occupational_demand_for_property,
                ],
                special_designation_to_property: [
                    this.reporting.general_comments
                        .special_designation_to_property,
                ],
                harmful_materials_been_noted: [
                    this.reporting.general_comments
                        .harmful_materials_been_noted,
                ],
                environmental_issues_to_property: [
                    this.reporting.general_comments
                        .environmental_issues_to_property,
                ],
                volatility_in_market: [
                    this.reporting.general_comments.volatility_in_market,
                ],
                assessment_of_current_marketability: [
                    this.reporting.general_comments
                        .assessment_of_current_marketability,
                ],
                any_other_matter_revealed: [
                    this.reporting.general_comments.any_other_matter_revealed,
                ],
                impact_of_giving_incentive: [
                    this.reporting.general_comments.impact_of_giving_incentive,
                ],

                circumstances_could_affect_price_descr: [
                    this.reporting.general_comments
                        .circumstances_could_affect_price_descr,
                ],
                demand_for_alternative_use_descr: [
                    this.reporting.general_comments
                        .demand_for_alternative_use_descr,
                ],
                special_designation_to_property_descr: [
                    this.reporting.general_comments
                        .special_designation_to_property_descr,
                ],
                harmful_materials_been_noted_descr: [
                    this.reporting.general_comments
                        .harmful_materials_been_noted_descr,
                ],
                environmental_issues_to_property_descr: [
                    this.reporting.general_comments
                        .environmental_issues_to_property_descr,
                ],
                any_other_matter_revealed_descr: [
                    this.reporting.general_comments
                        .any_other_matter_revealed_descr,
                ],
                impact_of_giving_incentive_descr: [
                    this.reporting.general_comments
                        .impact_of_giving_incentive_descr,
                ],
            }),
            property_specific_disclosure: this.formBuilder.group({
                type_of_property: [
                    this.reporting.property_specific_disclosure
                        .type_of_property,
                ],
                summary_of_occupational_leases: [
                    this.reporting.property_specific_disclosure
                        .summary_of_occupational_leases,
                ],
                current_rental_income: [
                    this.reporting.property_specific_disclosure
                        .current_rental_income,
                ],
                market_view: [
                    this.reporting.property_specific_disclosure.market_view,
                ],
                assess_maintainability_of_income: [
                    this.reporting.property_specific_disclosure
                        .assess_maintainability_of_income,
                ],
                any_potential_for_redevelopment: [
                    this.reporting.property_specific_disclosure
                        .any_potential_for_redevelopment,
                ],
                comments_on_costs: [
                    this.reporting.property_specific_disclosure
                        .comments_on_costs,
                ],
                comments_on_viability: [
                    this.reporting.property_specific_disclosure
                        .comments_on_viability,
                ],
                implications_on_value: [
                    this.reporting.property_specific_disclosure
                        .implications_on_value,
                ],
                comments_on_time: [
                    this.reporting.property_specific_disclosure
                        .comments_on_time,
                ],

                any_potential_for_redevelopment_descr: [
                    this.reporting.property_specific_disclosure
                        .any_potential_for_redevelopment_descr,
                ],
                market_value_impact: [
                    this.reporting.property_specific_disclosure
                        .market_value_impact,
                ],
                market_value_impact_descr: [
                    this.reporting.property_specific_disclosure
                        .market_value_impact_descr,
                    this.reporting.property_specific_disclosure
                        .type_of_property == 2
                        ? Validators.required
                        : null,
                ],
            }),
        });
    }

    private _valuationScenarioFormGroup(groupedValuations: Array<GroupedValuations>, input: {valuation_index: number, val_scenario_id: number, justification: string}[]): UntypedFormGroup {
        const formGroup = this.formBuilder.group({})
        groupedValuations
            .filter(v => v.valuations.length > 1)
            .forEach(v => {
                const _input = input.find(i => i.valuation_index == v.valuation_index)
                const control = this.formBuilder.control(_input ? _input.val_scenario_id : v.valuations[0].valuation.id)
                formGroup.setControl(`valuation-scenario-${v.valuation_index}`, control)

                const justificationCtrl = this.formBuilder.control(_input ? _input.justification : null)
                formGroup.setControl(`valuation-scenario-justification-${v.valuation_index}`, justificationCtrl)
            })
        return formGroup;
    }

    private _signatureFormArray(members: Member[], member_ids: number[]): UntypedFormGroup {
        const formArray = this.formBuilder.group({});
        members.forEach(member => {
            const has = member_ids.includes(member.id)
            const control = this.formBuilder.control(has);
            formArray.setControl(`member-${member.id}`, control)
        })
        return formArray;
    }

    submitSetting(complete: boolean, designData?: any) {
        this.hasError$.next(false);
        const controls = this.form.controls;
        if (complete && this.form.invalid) {
            Object.keys(controls).forEach((controlName) => {
                controls[controlName].markAsTouched();
            });
            return;
        }

        let hasUploadFileError = false;
        this.supportingDocuments.getUploadFiles().forEach((f) => {
            if (complete && (f.title === "" || f.descr === "")) {
                hasUploadFileError = true;
            }
        });
        if (hasUploadFileError) {
            this.hasError$.next(true);
            this.errorMessage$.next(
                "Title or Description required on uploaded files"
            );
            return;
        }
        const propertySpecificControls = (
            this.form.get("property_specific_disclosure") as UntypedFormGroup
        ).controls;

        if (
            complete &&
            this.vpgaTwoComponent &&
            propertySpecificControls["type_of_property"].value == 2 &&
            this.vpgaTwoComponent.selectedCoBAssumptions.length == 0
        ) {
            this.hasError$.next(true);
            this.errorMessage$.next(
                "At least 1 need to be checked from Property Specific disclosures sections checkboxes"
            );
            return;
        }

        const generalCommentsControls = (
            this.form.get("generalComments") as UntypedFormGroup
        ).controls;
        const exchangeDirections =
            this.generarValuationMattersComponent.getExchangeDirections();
        const reportingSettingsData: ReportingProcessData = {
            id: this.reporting.id,
            valuation_scenarios: this._getValuationScenarios(controls['valuation_scenario'] as UntypedFormGroup, this.groupedValuations),
            val_id: controls["valuation"].value,
            val_justification: controls["valuation_justification"].value,
            change_in_market_conditions: controls["change_in_market"].value,
            change_in_market_conditions_explained:
                controls["change_in_market_justification"].value,
            exchange_rates: [],
            comparable_pictures: designData.comparable_pictures,
            valuation_cover_picture: designData.report_cover,
            tp_pictures: 0,
            tp_pictures_all_or_custom: designData.picture_location,
            property_pictures: designData.property_pics.map(
                (pic: { type: any; id: any; selection_status: any }) => {
                    return {
                        type: pic.type,
                        pic_id: pic.id,
                        selection: pic.selection_status,
                    };
                }
            ),
            building_pictures: designData.building_pics.map(
                (pic: { type: any; id: any; selection_status: any }) => {
                    return {
                        type: pic.type,
                        pic_id: pic.id,
                        selection: pic.selection_status,
                    };
                }
            ),
            videos: designData.videos.map(
                (vid: { id: any; selection_status: any }) => {
                    return {
                        vid_id: vid.id,
                        selection: vid.selection_status,
                    };
                }
            ),
            matterport_contents: designData.links.map(
                (link: { id: any; selection_status: any }) => {
                    return {
                        link_id: link.id,
                        selection: link.selection_status,
                    };
                }
            ),
            status: Number(complete),
            valuation_date: this.typesUtilsService.getDateStringFromDate(
                new Date(controls["valuation_date"].value)
            ),
            market_identification_n_input_levels:
                controls["market_identification_n_input_levels"].value,
            base_of_value_adopted: controls["base_of_value_adopted"].value,
            base_of_value_adopted_desc:
                controls["base_of_value_adopted_desc"].value,
            use_round_up: controls["use_round_up"].value,
            marketResearch: [],
            appendices: this.supportingDocuments.getAppendicesFiles(),
            otherDocuments: this.supportingDocuments
                .getUploadFiles()
                .map((f) => {
                    return {
                        id: f.id ? f.id : null,
                        title: f.title,
                        descr: f.descr,
                        name: f.name,
                        size: f.size,
                        path: f.path,
                    };
                }),

            has_vpga2: this.toe.purpose_of_valuation_id === 8,
            has_material_change: controls["has_material_change"].value,
            draft_is_shared_to_client:
                controls["draft_is_shared_to_client"].value,
            general_comments: {
                price_been_disclosed:
                    generalCommentsControls["price_been_disclosed"].value,
                price_been_disclosed_descr:
                    generalCommentsControls["price_been_disclosed_descr"].value,
                suitability_of_property:
                    generalCommentsControls["suitability_of_property"].value,
                circumstances_could_affect_price:
                    generalCommentsControls["circumstances_could_affect_price"]
                        .value,
                demand_for_alternative_use:
                    generalCommentsControls["demand_for_alternative_use"].value,
                occupational_demand_for_property:
                    generalCommentsControls["occupational_demand_for_property"]
                        .value,
                special_designation_to_property:
                    generalCommentsControls["special_designation_to_property"]
                        .value,
                harmful_materials_been_noted:
                    generalCommentsControls["harmful_materials_been_noted"]
                        .value,
                environmental_issues_to_property:
                    generalCommentsControls["environmental_issues_to_property"]
                        .value,
                volatility_in_market:
                    generalCommentsControls["volatility_in_market"].value,
                assessment_of_current_marketability:
                    generalCommentsControls[
                        "assessment_of_current_marketability"
                    ].value,
                any_other_matter_revealed:
                    generalCommentsControls["any_other_matter_revealed"].value,
                impact_of_giving_incentive:
                    generalCommentsControls["impact_of_giving_incentive"].value,

                circumstances_could_affect_price_descr:
                    generalCommentsControls[
                        "circumstances_could_affect_price_descr"
                    ].value,
                demand_for_alternative_use_descr:
                    generalCommentsControls["demand_for_alternative_use_descr"]
                        .value,
                special_designation_to_property_descr:
                    generalCommentsControls[
                        "special_designation_to_property_descr"
                    ].value,
                harmful_materials_been_noted_descr:
                    generalCommentsControls[
                        "harmful_materials_been_noted_descr"
                    ].value,
                environmental_issues_to_property_descr:
                    generalCommentsControls[
                        "environmental_issues_to_property_descr"
                    ].value,
                any_other_matter_revealed_descr:
                    generalCommentsControls["any_other_matter_revealed_descr"]
                        .value,
                impact_of_giving_incentive_descr:
                    generalCommentsControls["impact_of_giving_incentive_descr"]
                        .value,
            },
            property_specific_disclosure: {
                type_of_property:
                    propertySpecificControls["type_of_property"].value,
                summary_of_occupational_leases:
                    propertySpecificControls["summary_of_occupational_leases"]
                        .value,
                current_rental_income:
                    propertySpecificControls["current_rental_income"].value,
                market_view: propertySpecificControls["market_view"].value,
                assess_maintainability_of_income:
                    propertySpecificControls["assess_maintainability_of_income"]
                        .value,
                any_potential_for_redevelopment:
                    propertySpecificControls["any_potential_for_redevelopment"]
                        .value,
                comments_on_costs:
                    propertySpecificControls["comments_on_costs"].value,
                comments_on_viability:
                    propertySpecificControls["comments_on_viability"].value,
                implications_on_value:
                    propertySpecificControls["implications_on_value"].value,
                comments_on_time:
                    propertySpecificControls["comments_on_time"].value,
                any_potential_for_redevelopment_descr:
                    propertySpecificControls[
                        "any_potential_for_redevelopment_descr"
                    ].value,
                market_value_impact:
                    propertySpecificControls["market_value_impact"].value,
                market_value_impact_descr:
                    propertySpecificControls["market_value_impact_descr"].value,
            },
            summary_of_occupational_leases_files: this.vpgaTwoComponent
                ? this.vpgaTwoComponent.getSummaryOfOccupationalLeasesFiles()
                : [],
            current_rental_income_files: this.vpgaTwoComponent
                ? this.vpgaTwoComponent.getCurrentRentalIncomeFiles()
                : [],
            toe_id: this.toe.id,
            ac_id: this.tp.id,
            user_id: this.userID,

            toe_related_docs: this.supportingDocuments.getToeDocs(),
            tp_selected_pictures: [],
            has_team_signature: controls['has_team_signatures'].value == 1,
            team_signature_ids: this.signature_ids((controls['signatures'] as UntypedFormGroup), this.toeMembers)
        };

        const valExchange = controls.val_exchange as UntypedFormGroup;
        Object.keys(valExchange.controls).forEach((item) => {
            const idx = item.indexOf("_");
            const valID = item.substring(0, idx);
            const eRate: ReportingValuationExchanges = {
                val_id: Number(valID),
                exchange_rate: valExchange.controls[item].value,
                exchange_direction: exchangeDirections[Number(valID)],
            };
            reportingSettingsData.exchange_rates.push(eRate);
        });


        if (reportingSettingsData.id) {
            // Update
            this.store$.dispatch(
                new ReportingUpdated({ data: reportingSettingsData })
            );
        } else {
            this.store$.dispatch(
                new ReportingStored({
                    tpId: this.tp.id,
                    data: reportingSettingsData,
                })
            );
        }

        this.layoutUtilsService.showActionNotification(
            this.translate.instant("GENERAL.MESSAGE.SAVE_CHANGES"),
            MessageType.Update,
            3000,
            true,
            false
        );
        this.location.back();
    }

    private signature_ids(form: UntypedFormGroup, members: Member[]): number[] {
        return members
            .filter(member => {
                const control = form.get(`member-${member.id}`)
                if (!control) {
                    return false
                }
                return control.value
            })
            .map(member => member.id)
    }

    private _getValuationScenarios(form: UntypedFormGroup, groupedValuations: Array<GroupedValuations>): Array<{valuation_index: number, val_scenario_id: number, justification: string}> {
        return groupedValuations
            .filter(gVal => gVal.valuations.length > 1)
            .map(gVal => {
                const control = form.get(`valuation-scenario-${gVal.valuation_index}`)
                const justificationControl = form.get(`valuation-scenario-justification-${gVal.valuation_index}`)
                if (!control) {
                    return null 
                }
                return {
                    valuation_index: gVal.valuation_index,
                    val_scenario_id: control.value,
                    justification: justificationControl.value
                }
            })
            .filter(item => item != null)
    }
}
