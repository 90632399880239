// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {UnitLengthMeasurementActions, UnitLengthMeasurementActionTypes} from '../_actions/unit-length-measurement.actions';
// Models
import {UnitLengthMeasurement} from '../_models/unit-length-measurement.model';
import {QueryParamsModel} from '../../_base/crud';

export interface UnitLengthMeasurementsState extends EntityState<UnitLengthMeasurement> {
    isAllUnitLengthMeasurementsLoaded: boolean;
    queryRowsCount: number;
    queryResult: UnitLengthMeasurement[];
    lastCreatedUnitLengthMeasurementId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<UnitLengthMeasurement> = createEntityAdapter<UnitLengthMeasurement>();

export const initialUnitLengthMeasurementsState: UnitLengthMeasurementsState = adapter.getInitialState({
    isAllUnitLengthMeasurementsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedUnitLengthMeasurementId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function unitLengthMeasurementsReducer(state = initialUnitLengthMeasurementsState, action: UnitLengthMeasurementActions): UnitLengthMeasurementsState {
    switch (action.type) {
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedUnitLengthMeasurementId: undefined
            };
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerCreated:
            return {
                ...state
            };
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementCreated:
            return adapter.addOne(action.payload.unitLengthMeasurement, {
                ...state, lastCreatedUnitLengthMeasurementId: action.payload.unitLengthMeasurement.id
            });
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementUpdated:
            return adapter.updateOne(action.payload.partialunitLengthMeasurement, state);
        case UnitLengthMeasurementActionTypes.AllUnitLengthMeasurementsLoaded:
            return adapter.addAll(action.payload.unitLengthMeasurements, {
                ...state, isAllUnitLengthMeasurementsLoaded: true
            });
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageLoaded:
            return adapter.addMany(action.payload.unitLengthMeasurements, {
                ...initialUnitLengthMeasurementsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case UnitLengthMeasurementActionTypes.UnitLengthMeasurementTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
