import {createFeatureSelector, createSelector} from '@ngrx/store';
import { AdjustmentTabData, AssumptionTabData } from '../_models/tab_data.model';
import {ValuationDataState} from '../_reducers/valuation-data.reducer';
import { selectAllCriterions } from './criterion.selectors';
import { selectDefaultSizeCriterion, selectSizeCriterions } from './size_criterion.selectors';
import { selectAllValuationAdjustments } from './valuation-adjustment.selector';
import * as valuationSelectors from './valuation.selector';
import * as assumptionDepartureSelectors from './assumption_departure.selectors'
import * as valuationVPGA10Selectors from './vpga10-matters.selectors';
import * as valuationSRERSelectors from './valuation-srer.selectors';

export const selectValuationDataState = createFeatureSelector<ValuationDataState>('valuation-data');

export const selectAllValComparables = createSelector(
    selectValuationDataState,
    state => {
        if (state.ids.length == 0) {
            return [];
        }
        const coms = state.entities[1].comparables;
        const items = [];
        coms.forEach(com => items.push(com));
        items.sort((c1, c2) => c1.id - c2.id);
        return items;
    }
);

export const selectAllValTenures = createSelector(
    selectValuationDataState,
    state => {
        if (state.ids.length == 0) {
            return [];
        }
        return state.entities[1].tenures;
    }
)

export const selectIsDisableAdjustment = createSelector(
    selectValuationDataState,
    state => {
        return !state.ids.length || !state.entities[1].comparables.length
    }
);

export const selectHasSavedValuationData = createSelector(
    selectValuationDataState,
    state => state.hasChanged
);

export const selectValuationData = createSelector(
    selectValuationDataState,
    state => {
        return state.ids.length && state.entities[1]
    }
);

export const selectAllValKeyPlaces = createSelector(
    selectValuationDataState,
    state => {
        return state.ids.length ? state.entities[1].key_places : []
    }
);

export const selectLastCreatedValuationDataID = createSelector(
    selectValuationDataState,
    state => state.lastCreatedID
)

export const selectLastCurrencyConvertedComID = createSelector(
    selectValuationDataState,
    state => state.lastCurrencyConvertedComID
)

export const selectAdjustmentTabData = createSelector(
    selectAllCriterions,
    selectSizeCriterions,
    selectDefaultSizeCriterion,
    selectAllValuationAdjustments,
    valuationSelectors.selectAllValuations,
    valuationSelectors.selectJustification,
    valuationSelectors.selectCapitalAllowance,
    valuationSelectors.selectCapitalAllowanceJustification,
    (criterions, sizeCriterions, defaultSize, adjustments, valuations, justification, capitalAllowance, capitalAllowanceJustification) => {
        const adjustmentTabData: AdjustmentTabData = {
            criterions, sizeCriterions, defaultSize, adjustments, valuations, justification, capitalAllowance, capitalAllowanceJustification
        }
        return adjustmentTabData;
    }
)

export const selectAssumptionTabData = createSelector(
    assumptionDepartureSelectors.selectAll,
    valuationVPGA10Selectors.selectAll,
    valuationSRERSelectors.selectAll,
    (assumptionsAndDepartures, vpga10Matters, srers) => {
        const assumptionTabData: AssumptionTabData = {
            assumptionsAndDepartures,
            vpga10Matters: vpga10Matters,
            valuationSRER: srers
        }
        return assumptionTabData;
    }
)