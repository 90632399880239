import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {delay, take} from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import {
    Appendix, AppendixOnServerCreated, selectLastCreatedAppendixId, CityModel, CountryModel,
    selectAllCountries, selectCitiesByCountryId, selectAppendixCategoryById, AppendixCategory, AllCitiesRequested
} from '../../../../../core/admin';
import {AppendixUpdated, AllCountriesRequested} from '../../../../../core/admin';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {UploadFileComponent} from '../../../shared_components/upload-file/upload-file.component';
import {each} from 'lodash';
import {TagRequiredValidator} from '../../../validators/property.validator';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-appendix-edit',
    templateUrl: './appendix-edit.component.html',
    styleUrls: ['./appendix-edit.component.scss']
})


export class AppendixEditComponent implements OnInit, OnDestroy {

    @ViewChild(UploadFileComponent)
    uploadFileComponent: UploadFileComponent;

    appendix: Appendix;
    oldAppendix: Appendix;

    appendixForm: UntypedFormGroup;
    hasFormErrors = false;
    fileType: string;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    // Countries
    allCountries: CountryModel[] = [];
    cityOfCountry: CityModel[] = [];

    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    catId: number;
    category: AppendixCategory;

    /**
     * Component constructor
     *
     * @param subheaderService
     * @param activatedRoute
     * @param router
     * @param fb: FormBuilder
     * @param location
     * @param layoutUtilsService
     * @param store: Store<AppState>
     * @param layoutConfigService
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
        this.fileType = 'image/*,application/pdf';
    }

    ngOnInit() {
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            this.catId = Number(params.cat_id);
            this.store.pipe(select(selectAppendixCategoryById(this.catId))).subscribe(res => {
                if (res) {
                    this.category = Object.assign({}, res);
                }
            });
            const id = params.id;
            if (id && id > 0) {
                this.activatedRoute.data.subscribe(data => {
                    const res = data.data;
                    this.oldAppendix = res.data;
                    this.appendix = Object.assign({}, this.oldAppendix);
                    this.initAppendix();
                });
            } else {
                this.appendix = new Appendix();
                this.appendix.clear();
                this.oldAppendix = Object.assign({}, this.appendix);
                this.initAppendix();
            }
        });

        this.subscriptions.push(routeSubscription);

        this.store.dispatch(new AllCountriesRequested());
        this.store.dispatch(new AllCitiesRequested());
        const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
            this.allCountries = [];
            each(res, (_country: CountryModel) => {
                this.allCountries.push(_country);
            });
        });
        this.subscriptions.push(countrySubscription);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initAppendix() {
        this.createForm();
    }


    createForm() {
        this.appendixForm = this.fb.group({
            name: [this.appendix.name, Validators.required],
            description: [this.appendix.description, Validators.required],
        });
    }

    selectCountry() {
        this.store.pipe(select(selectCitiesByCountryId(this.appendixForm.controls.country_id.value))).subscribe((res: CityModel[]) => {
            this.cityOfCountry = res;
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.appendix && this.appendix.id > 0) {
            return this.translate.instant('APPENDIX.FORM.TITLE.EDIT', {name: this.appendix.name});
        }
        if (this.category) {
            return this.translate.instant('APPENDIX.FORM.TITLE.NEW_FOR', {category_name: this.category.name});
        }
        return this.translate.instant('APPENDIX.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.appendixForm.controls[controlName];
        return control.invalid && control.touched;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.appendixForm.controls;
        /** check form */
        if (this.appendixForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedAppendix = this.prepareAppendix();

        if (this.uploadFileComponent.uploadFiles.length < 1) {
            this.hasFormErrors = true;
            return;
        }

        if (this.appendix.id > 0) {
            this.updateAppendix(editedAppendix);
        } else {
            this.createAppendix(editedAppendix);
        }
    }

    /**
     * Update appendix
     *
     * @param _appendix: AppendixModel
     */
    updateAppendix(_appendix: Appendix) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateAppendix: Update<Appendix> = {
            id: _appendix.id,
            changes: _appendix
        };
        this.store.dispatch(new AppendixUpdated({
            partialappendix: updateAppendix,
            appendix: _appendix,
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
        }));
        this.oldAppendix = _appendix;
        this.layoutUtilsService.showActionNotification(
            this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'),
            MessageType.Update, 3000, true, false);
    }

    /**
     * Create appendix
     *
     * @param _appendix: AppendixModel
     */
    createAppendix(_appendix: Appendix) {
        this.store.dispatch(new AppendixOnServerCreated({
            appendix: _appendix,
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
        }));
        this.oldAppendix = _appendix;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedAppendixId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return !this.appendixForm.invalid;
    }

    navigateToParent() {
        let url = '../';
        if (this.appendix.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    /**
     * Returns prepared assignment
     */
    prepareAppendix(): Appendix {
        const controls = this.appendixForm.controls;
        const _appendix = new Appendix();
        _appendix.clear();
        _appendix.id = this.appendix.id;
        _appendix.name = controls.name.value;
        _appendix.description = controls.description.value;
        _appendix.cat_id = this.catId;

        this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
            if (value) {
                _appendix.path = value.success;
            }
        });

        if (this.oldAppendix && this.oldAppendix.path.length > 0) {
            _appendix.path = this.oldAppendix.path;
        }
        return _appendix;
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {

        const _appendix = this.prepareAppendix();


        return _appendix.name != this.oldAppendix.name ||
            _appendix.description != this.oldAppendix.description
    }
}
