<div>
        <div class="mb-1" *ngIf="dataSource && (dataSource.isLoading$|async)">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-table class="lmat-elevation-z8"
            [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                <mat-cell *matCellDef="let data">{{data.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let data">{{data.description}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <button mat-icon-button 
                        type="button" 
                        matTooltip="Duplicate Template"
                        [disabled]="!(canEdit$|async)"
                        *ngIf="!isSelection"
                        (click)="duplicateTemplate(data)"
                    >
                        <i [style.color]="(canEdit$|async) ? 'green' : '#bdbdbd'" class="flaticon2-layers"></i>
                    </button>
                    <button mat-icon-button
                        type="button"
                        color="primary"
                        matTooltip="View Template"
                        (click)="viewTemplate(data)"
                    >
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                    <button mat-icon-button 
                        type="button" 
                        color="primary"
                        matTooltip="Edit Template"
                        *ngIf="!isSelection && (canEdit$|async)"
                        (click)="editTemplate(data)"
                    >
                        <mat-icon>create</mat-icon>
                    </button>
                    <button mat-icon-button
                        type="button"
                        color="warn"
                        matTooltip="Delete Template"
                        [disabled]="!(canEdit$|async)"
                        *ngIf="!isSelection"
                        (click)="deleteTemplate(data)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button
                        type="button"
                        color="primary"
                        matTooltip="Select Template"
                        *ngIf="isSelection"
                        (click)="selectTemplate(data)"
                    >
                        <mat-icon>check_circle</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
        <div *ngIf="dataSource && !(dataSource.hasData$|async)">
            There are no templates.
        </div>
        <mat-paginator 
            [length]="dataSource && (dataSource.total$|async)" 
            [pageSize]="pageSize"
            hidePageSize="true"
            ></mat-paginator>
</div>