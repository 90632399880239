import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    AssetClassSERUpdated,
    AssetClassSERActionTypes,
    AllAssetClassSERRequested,
    AllAssetClassSERLoaded,
    AssetClassSEROnServerUpdated,
    AllAssetClassSEROfTPRequested
} from '../_actions/asset-class-ser.action';
import { mergeMap, map, switchMap } from 'rxjs/operators';
import { AssetClassSERService } from '../_services/asset-class-ser.service';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { AssetClassSourceExternalReferenceModel } from '../_models/asset-class-source-external-reference.model';

@Injectable()
export class AssetClassSEREffects {
    constructor(
        private actions$: Actions,
        private service: AssetClassSERService
    ) {}

    @Effect()
    loadAllAssetClassSER$ = this.actions$
        .pipe(
            ofType<AllAssetClassSERRequested>(AssetClassSERActionTypes.AllAssetClassSERRequested),
            mergeMap(() => {
                return this.service.getAllSER();
            }),
            map((result) => {
                return new AllAssetClassSERLoaded({
                    sers: result.data
                });
            })
        );

    @Effect()
    loadAllAssetClassSEROfTP$ = this.actions$
        .pipe(
            ofType<AllAssetClassSEROfTPRequested>(AssetClassSERActionTypes.AllAssetClassSEROfTPRequested),
            switchMap(({payload}) => {
                return this.service.getSERByTpId(payload.tpId)
            }),
            map(result => {
                return new AllAssetClassSERLoaded({
                    sers: result.data
                })
            })
        )

    @Effect()
    updateAssetClassSER$ = this.actions$
        .pipe(
            ofType<AssetClassSEROnServerUpdated>(AssetClassSERActionTypes.AssetClassSEROnServerUpdated),
            mergeMap(({payload}) => {
                return this.service.updateSER(payload.ser);
            }),
            map((res) => {
                    const updateAssetClassSER: Update<AssetClassSourceExternalReferenceModel> = {
                        id: res.data.id,
                        changes: res.data
                    };
                    return new AssetClassSERUpdated({
                        partialSER: updateAssetClassSER
                    });
                })
        );
}
