// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {SolUseClassification} from '../_models/sol-use-classification.model';

export enum SolUseClassificationActionTypes {

    AllSolUseClassificationsRequested = '[SolUseClassifications Home Page] All SolUseClassifications Requested',
    AllSolUseClassificationsLoaded = '[SolUseClassifications API] All SolUseClassifications Loaded',

    SolUseClassificationOnServerCreated = '[Edit SolUseClassification Dialog] SolUseClassification On Server Created',
    SolUseClassificationCreated = '[Edit SolUseClassifications Dialog] SolUseClassifications Created',
    SolUseClassificationUpdated = '[Edit SolUseClassification Dialog] SolUseClassification Updated',
    SolUseClassificationDeleted = '[SolUseClassifications List Page] SolUseClassification Deleted',

    SolUseClassificationsPageRequested = '[SolUseClassifications List Page] SolUseClassifications Page Requested',
    SolUseClassificationsPageLoaded = '[SolUseClassifications API] SolUseClassifications Page Loaded',
    SolUseClassificationsPageCancelled = '[SolUseClassifications API] SolUseClassifications Page Cancelled',

    SolUseClassificationsPageToggleLoading = '[SolUseClassifications page] SolUseClassifications Page Toggle Loading',
    SolUseClassificationsActionToggleLoading = '[SolUseClassifications] SolUseClassifications Action Toggle Loading',

    // trash system

    SolUseClassificationOnServerRestored = '[SolUseClassification Trash] SolUseClassification On Server Restored',
    SolUseClassificationRestored = '[SolUseClassification Trash] SolUseClassification Restored',

    SolUseClassificationOnServerAdminRestored = '[SolUseClassification Admin Trash] SolUseClassification On Server Restored',
    SolUseClassificationAdminRestored = '[SolUseClassification Admin Trash] SolUseClassification Restored',

    SolUseClassificationDeletedFromTrash = '[SolUseClassification Trash] SolUseClassification deleted',
    SolUseClassificationDeletedFromAdminTrash = '[SolUseClassification Admin Trash] SolUseClassification deleted',

    SolUseClassificationTrash = 'SolUseClassification Trashed',
    SolUseClassificationTrashFlushed = 'SolUseClassification Trash Flushed',
}

export class SolUseClassificationOnServerCreated implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationOnServerCreated;

    constructor(public payload: { solUseClassification: SolUseClassification}) {
    }
}

export class SolUseClassificationCreated implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationCreated;

    constructor(public payload: { solUseClassification: SolUseClassification }) {
    }
}

export class SolUseClassificationUpdated implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationUpdated;

    constructor(public payload: {
        partialSolUseClassification: Update<SolUseClassification>,
        solUseClassification: SolUseClassification
    }) {
    }
}

export class SolUseClassificationDeleted implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SolUseClassificationsPageRequested implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SolUseClassificationsPageLoaded implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationsPageLoaded;

    constructor(public payload: { solUseClassifications: SolUseClassification[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SolUseClassificationsPageCancelled implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationsPageCancelled;
}

export class AllSolUseClassificationsRequested implements Action {
    readonly type = SolUseClassificationActionTypes.AllSolUseClassificationsRequested;
}

export class AllSolUseClassificationsLoaded implements Action {
    readonly type = SolUseClassificationActionTypes.AllSolUseClassificationsLoaded;

    constructor(public payload: { solUseClassifications: SolUseClassification[] }) {
    }
}

export class SolUseClassificationsPageToggleLoading implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SolUseClassificationsActionToggleLoading implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class SolUseClassificationDeletedFromAdminTrash implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class SolUseClassificationDeletedFromTrash implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SolUseClassificationOnServerRestored implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SolUseClassificationRestored implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationRestored;

    constructor(public payload: { item: SolUseClassification }) {
    }
}

export class SolUseClassificationOnServerAdminRestored implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SolUseClassificationAdminRestored implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationAdminRestored;

    constructor(public payload: { item: SolUseClassification }) {
    }
}

export class SolUseClassificationTrashFlushed implements Action {
    readonly type = SolUseClassificationActionTypes.SolUseClassificationTrashFlushed;

    constructor() {
    }
}

export type SolUseClassificationActions = SolUseClassificationCreated
    | SolUseClassificationUpdated
    | SolUseClassificationDeleted
    | SolUseClassificationsPageRequested
    | SolUseClassificationsPageLoaded
    | SolUseClassificationsPageCancelled
    | AllSolUseClassificationsLoaded
    | AllSolUseClassificationsRequested
    | SolUseClassificationOnServerCreated
    | SolUseClassificationsPageToggleLoading
    | SolUseClassificationsActionToggleLoading
    | SolUseClassificationDeletedFromAdminTrash
    | SolUseClassificationDeletedFromTrash
    | SolUseClassificationOnServerRestored
    | SolUseClassificationRestored
    | SolUseClassificationOnServerAdminRestored
    | SolUseClassificationAdminRestored
    | SolUseClassificationTrashFlushed;
