<div [formGroup]="formGroup" class="row">
    <ng-container *ngFor="let field of formFields">
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-12">
                    <ng-container *ngIf="field.selectables != undefined">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select 
                                [formControlName]="field.select_formControlName" 
                                [placeholder]="field.select_name">
                                <mat-option *ngFor="let item of field.selectables | async" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{field.select_name}} <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!field.selectables">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [formControlName]="field.select_formControlName" 
                                [placeholder]="field.select_name">
                            <mat-icon
                                    *ngIf="field.has_tooltip"
                                    matSuffix
                                    class="cursor-pointer"
                                    (click)="$event.stopPropagation();"
                                    container="body"
                                    [ngbPopover]="'TOOLTIP.HOUSE.WOODWORK.DESCRIPTION' | translate"
                                    [popoverTitle]="'TOOLTIP.HOUSE.WOODWORK.TITLE' | translate">help
                            </mat-icon>
                            <mat-error>
                                {{field.select_name}} <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                </div>
                <div class="col-lg-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput [formControlName]="field.comment_formControlName"
                            [placeholder]="field.comment_name" >
                    </mat-form-field>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="propertySubTypeID == 17 || propertySubTypeID == 1">
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-12">
                    <kt-bathroom-fittings-list #bathroomFitting 
                        [selectedBathroomFittings]="selectedBathroomFittings"
                        [propertySubTypeId]="propertySubTypeID">
                    </kt-bathroom-fittings-list>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="col-lg-4">
        <div class="row">
            <div class="col-lg-12">
                <kt-builtin-fittings-list #builtinFitting 
                    [selectedBuiltinFittings]="selectedBuiltinFittings"
                    [propertySubTypeId]="propertySubTypeID">
                </kt-builtin-fittings-list>
            </div>
        </div>
    </div>

    <div class="col-lg-12">
        <mat-checkbox formControlName="has_other">
            Other
        </mat-checkbox>
    </div>
    <div class="col-lg-12" *ngIf="formGroup.controls.has_other.value">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput formControlName="other_aspects" placeholder="Please specify" />
            <mat-error>
                This field is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <ng-container *ngIf="propertySubTypeID == 7">
        <div class="mt-8 col-lg-12">
            <mat-checkbox formControlName="has_automation">
                <strong>Automation equipment & technology</strong>
            </mat-checkbox>
        </div>
        <ng-container *ngIf="showAutomationDetails">
            <ng-container *ngFor="let item of automation_details">
                <div class="col-lg-4 mt-4">
                    <mat-checkbox [checked]="item.checked" (change)="onServiceChange($event, item)">
                        <span>
                            {{item.title}}
                        </span>
                    </mat-checkbox>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
