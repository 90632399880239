import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService, SubheaderService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, TypesUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
    AllQosDegradationsRequested,
    selectAllQosDegradations,
    QosDegradation,

    AllComparativeLandUsesRequested,
    selectAllComparativeLandUses,
    ComparativeLandUse,

    AllSolUseClassificationsRequested,
    selectAllSolUseClassifications,
    SolUseClassification
} from '../../../../../core/linked-tables';
import { AssetClassLandUseModel } from 'src/app/core/asset_class';
import { AssetClassBuildingInformation } from 'src/app/core/comparable';

@Component({
    selector: 'kt-land-use',
    templateUrl: './land-use.component.html',
    styleUrls: ['./land-use.component.scss'],
})
export class LandUseComponent implements OnInit, OnDestroy {

    @Input() landUseSubject: BehaviorSubject<AssetClassLandUseModel>;
    @Input() buildingsSubject: BehaviorSubject<AssetClassBuildingInformation[]>;

    item: any;
    form: UntypedFormGroup;
    hasFormErrors = false;
    buildingMin = 1;
    buildingMax = 30;

    qosDegradations: QosDegradation[] = [];
    comparativeLandUses: ComparativeLandUse[] = [];
    solUseClassifications: SolUseClassification[] = [];

    decisionsExtra: any[] = [
        {label: 'Yes', value: 1, default: false},
        {label: 'No', value: 0, default: true},
        {label: 'I don’t know', value: 2, default: false}
    ];

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService
     * @param dialog
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.landUseSubject.asObservable().subscribe(res => {
            this.item = Object.assign({}, res);
        });

        this.store.dispatch(new AllQosDegradationsRequested());
        const qosDegradationsSubscription = this.store.pipe(
            select(selectAllQosDegradations))
            .subscribe(res => {
                this.qosDegradations = [];
                if (res) {
                    this.qosDegradations = res;
                }
            });

        this.subscriptions.push(qosDegradationsSubscription);

        this.store.dispatch(new AllComparativeLandUsesRequested());
        const comparativeLandUsesSubscription = this.store.pipe(
            select(selectAllComparativeLandUses))
            .subscribe(res => {
                this.comparativeLandUses = [];
                if (res) {
                    this.comparativeLandUses = res;
                }
            });

        this.subscriptions.push(comparativeLandUsesSubscription);

        this.store.dispatch(new AllSolUseClassificationsRequested());
        const solUseClassificationsSubscription = this.store.pipe(
            select(selectAllSolUseClassifications))
            .subscribe(res => {
                this.solUseClassifications = [];
                if (res) {
                    this.solUseClassifications = res;
                }
            });

        this.subscriptions.push(solUseClassificationsSubscription);

        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            land_cover_type: [this.item.land_cover_type],
            qos_degradation_id: [this.item.qos_degradation_id],
            comparative_land_use_id: [this.item.comparative_land_use_id],
            sol_use_classification_id: [this.item.sol_use_classification_id],
            other_characteristics: [this.item.other_characteristics],
            land_use_approval_implementation: [this.item.land_use_approval_implementation],
            building_exist: [this.item.building_exist],
            building_count: [this.item.building_count, Validators.compose([Validators.min(this.buildingMin),Validators.max(this.buildingMax)])],
        });

        this.form.controls.building_exist.valueChanges.pipe(
            startWith(this.form.controls.building_exist.value)
        ).subscribe(value => {
            if (value == 1) {
                this.form.controls.building_count.setValidators(Validators.compose([Validators.min(this.buildingMin), Validators.max(this.buildingMax)]));
                this._buildingAdder(this.form.controls.building_count.value);
            } else {
                this.form.controls.building_count.clearValidators();
                this._buildingAdder(0)
            }
            this.form.controls.building_count.updateValueAndValidity();
            this.updateValue(false);
        })

        this.form.controls.building_count.valueChanges.subscribe(value => {
            this.updateValue(false);
        })
    }

    private _buildingAdder(value) {
        if (value == 0 || value == null) {
            this.buildingsSubject.next([]);
        } else if (value > this.buildingsSubject.value.length) {
            let temp = this.buildingsSubject.value;
            let buildingCnt = value > this.buildingMax ? this.buildingMax : value;
            for (let i = this.buildingsSubject.value.length; i < buildingCnt; i++) {
                const buildingInfo = new AssetClassBuildingInformation();
                buildingInfo.clear();
                temp.push(buildingInfo);
            }
            this.buildingsSubject.next(temp);
        } else if (value < this.buildingsSubject.value.length) {
            let temp = this.buildingsSubject.value;
            for (let i = this.buildingsSubject.value.length; i > value; i--) {
                temp.splice(i-1, 1);
            }
            this.buildingsSubject.next(temp);
        }
    }

    onChangeEvent(event) {
        let value = event.target.value;
        if (value <= 0) {
            event.target.value = 1;
            value = 1;
        }
        this.form.controls.building_count.setValue(value);
        this.form.controls.building_count.updateValueAndValidity()
        this._buildingAdder(value);
    }

    onInput(event) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    updateValue(isComplete = true) {
        if (isComplete && !this.valid()) {
            return
        }
        const controls = this.form.controls;

        const acLandUse = new AssetClassLandUseModel();
        acLandUse.clear();
        acLandUse.land_cover_type = controls.land_cover_type.value;
        acLandUse.qos_degradation_id = controls.qos_degradation_id.value;
        acLandUse.comparative_land_use_id = controls.comparative_land_use_id.value;
        acLandUse.sol_use_classification_id = controls.sol_use_classification_id.value;
        acLandUse.other_characteristics = controls.other_characteristics.value;
        acLandUse.land_use_approval_implementation = controls.land_use_approval_implementation.value;
        acLandUse.building_exist = controls.building_exist.value;
        acLandUse.building_count = controls.building_count.value;

        this.landUseSubject.next(acLandUse);
    }

    valid() {
        let isValid = true;
        this.hasFormErrors = false;
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            isValid = false;
            this.hasFormErrors = true;
        }

        return isValid;
    }

    buildingExist() {
        return this.form.controls.building_exist.value;
    }
}
