import {HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import { HttpUtilsService } from '../../_base/crud';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


export class uploadFiles {
  constructor(public video: File, public path: string, public uploadURI: string) {
    this.video = video;
    this.path = path;
    this.uploadURI = uploadURI;
  }
}

@Injectable()
export class FileUploadService {
  API_TP_FILE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/tp`;
  API_COMP_DOWNLOAD_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparables/json/download`;
  API_COMP_IMPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparables/import`;
  API_DOWNLOAD_BULK_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download/bulk`;
  API_FILE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/upload2path`;
  API_UPLOAD_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/upload`;
  API_BASE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/add`;
  API_BASE_URL_V2 = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/v2/files/add`;
  constructor(private httpClient: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {}

  public upload2path(data) {
    return this.httpClient.post<any>(this.API_FILE_URL, data, {
        reportProgress: true,
        observe: 'events',
      headers: this.authService.getAuthHeadersForUpload()
    }).pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return {status: 'sent', message: 'sent'};
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return {status: 'progress', message: progress};
          case HttpEventType.Response:
            if (event.status === 200) {
              return event.body;
            }
            return {status: 'error', message: event.status};
          default:
            return null;
        }
      })
    );
  }

  public uploadFile(formData: FormData): Observable<HttpEvent<{}>> {
    const options = {
      reportProgress: true,
      observe: 'events',
      headers: this.authService.getAuthHeadersForUpload()
    };

    const req = new HttpRequest(
      'POST',
      this.API_BASE_URL,
      formData,
      options
    );
    return this.httpClient.request(req);
  }

  public uploadFileToTP(formData: FormData, tpId: number): Observable<HttpEvent<{}>> {
    const options = {
      reportProgress: true,
      observe: 'events',
      headers: this.authService.getAuthHeadersForUpload()
    };

    const req = new HttpRequest(
      'POST',
      `${this.API_TP_FILE_URL}/${tpId}/add`,
      formData,
      options
    );
    return this.httpClient.request(req);
  }

  public uploadFileV2(formData: FormData): Observable<HttpEvent<{}>> {
    const options = {
      reportProgress: true,
      observe: 'events',
      headers: this.authService.getAuthHeadersForUpload()
    };

    const req = new HttpRequest(
      'POST',
      this.API_BASE_URL_V2,
      formData,
      options
    );
    return this.httpClient.request(req);
  }

  public uploadFiles(formData: FormData): Observable<HttpEvent<{}>> {
    const options = {
      reportProgress: true,
      headers: this.authService.getAuthHeadersForUpload()
    };

    const req = new HttpRequest(
      'POST',
      this.API_UPLOAD_URL,
      formData,
      options
    );
    return this.httpClient.request(req);
  }

  public uploadCompJson(data) {
    return this.httpClient.post<any>(this.API_COMP_IMPORT_URL, data, {
      reportProgress: true,
      observe: 'events',
      headers: this.authService.getAuthHeadersForUpload()
    }).pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return {status: 'sent', message: 'sent'};
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return {status: 'progress', message: progress};
          case HttpEventType.Response:
            if (event.status === 200) {
              return event.body;
            }
            return {status: 'error', message: event.status};
          default:
            return null;
        }
      })
    );
  }

  public downloadBulk(formData: FormData): Observable<any> {
    const req = new HttpRequest(
      'POST',
      this.API_DOWNLOAD_BULK_URL,
      formData,
      {responseType: 'blob'}
    );
    return this.httpClient.request(req);
  }

  public compTemplateDownload(acType): Observable<any> {
    const req = new HttpRequest(
      'GET',
      this.API_COMP_DOWNLOAD_URL + '/' + acType,
      {
        responseType: 'blob',
        headers: this.authService.getAuthHeadersForUpload()
      }
    );
    return this.httpClient.request(req);
  }
}
