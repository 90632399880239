import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllUsersOfRoleRequested } from 'src/app/core/auth';
import { AllValuerQualificationsRequested, selectAllValuerQualifications, ValuerQualification } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { TeamTemplateModel } from 'src/app/core/template';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { TeamTemplateModalComponent } from '../../template/templates/team-template/_sub/team-template-modal/team-template-modal.component';
import { SupportValuerTemplateListModalComponent } from '../../toe/_sub/support-valuer-template-list-modal/support-valuer-template-list-modal.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type DataSourceItem = {
  name: string;
  qualification: string;
  qualification_id: number;
  lead_valuer: boolean;
  task_ids: Array<number>;
  valuer: IToeTeamMember;
}
export type IToeTeamMember = {
  id: number;
  ref_id: number;
  lead_valuer: number;
  position: string;
  daily_rate: number;
  days: number;
  total_price: number;
  worker_id: number;
  worker?: any;
  assigned_default_tasks: Array<number>,
  selected_document: any[],
  selected_certificates: any[]
}
export type TpTaskSelectionItem = {
  id: number,
  name: string,
  disabled: boolean,
  selected: boolean
}

@Component({
  selector: 'kt-toe-members',
  templateUrl: './toe-members.component.html',
  styleUrls: ['./toe-members.component.scss']
})
export class ToeMembersComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean = false;
  @Input() members$: BehaviorSubject<IToeTeamMember[]>;
  @Input() refId: number;
  @Input() isTemplate: boolean = false;
  @Input() isPMSubject: BehaviorSubject<boolean>;
  @Input() isLVSubject: BehaviorSubject<boolean>;

  private _onDestroy$: Subject<void> = new Subject();
  dataSource = new MatTableDataSource<DataSourceItem>()
  displayedColumns = [
    'name',
    'qualification',
    'is_lead_valuer',
    'tasks',
    'actions'
  ];

  tpTasks: Array<TpTaskSelectionItem> = [
    // { id: 0, name: 'Documents Upload (External References)', disabled: false, selected: false },
    // { id: 1, name: 'Investigation', disabled: false, selected: false },
    // { id: 2, name: 'Valuation', disabled: false, selected: false },
    // { id: 3, name: 'Reporting', disabled: false, selected: false },
    // { id: 4, name: 'Delivery', disabled: false, selected: false },
    // { id: 10, name: 'Document Upload (Signed Report)', disabled: false, selected: false },
    {id: 0, name: 'External Reference', disabled: false, selected: false},
    {id: 13, name: 'Logistics', disabled: false, selected: false},
    {id: 14, name: 'Landmarks', disabled: false, selected: false},
    {id: 1, name: 'Investigation', disabled: false, selected: false},
    {id: 2, name: 'Valuation', disabled: false, selected: false},
    {id: 15, name: 'Design', disabled: false, selected: false},
    {id: 3, name: 'Reporting', disabled: false, selected: false},
    {id: 10, name: 'Upload', disabled: false, selected: false},
    {id: 4, name: 'Delivery', disabled: false, selected: false}
  ];
  valuerQualifications: ValuerQualification[] = [];
  private _members: IToeTeamMember[] = [];

  isLV = false;
  isPM = false;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    public subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    if (this.isLVSubject) {
      this.isLVSubject.pipe(takeUntil(this._onDestroy$)).subscribe(res => this.isLV = res);
    }
    if (this.isPMSubject) {
      this.isPMSubject.pipe(takeUntil(this._onDestroy$)).subscribe(res => this.isPM = res);
    }
    this.store.dispatch(new AllUsersOfRoleRequested({roleId: 0}));
    this.store.dispatch(new AllValuerQualificationsRequested());
    this.store.select(selectAllValuerQualifications).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.valuerQualifications = [];
      if (res) {
        this.valuerQualifications = res;
        this.dataSource.data = this._convertTeamMemberToData(this._members);
      }
    });
    this.members$.pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this._members = res;
      this.dataSource.data = this._convertTeamMemberToData(this._members);
    });
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   * Actions
   */
  public addmember() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      data: {
        tasks: this.tpTasks,
        teamMembers: this._members,
      },
      width: '440px',
      position: {
        top: '50px',
        right: '50px'
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const valuer: IToeTeamMember = Object.assign({}, res.member);
      valuer.ref_id = this.refId;
      if (valuer.lead_valuer) {
        this._members = this._members.map(m => ({...m, lead_valuer: 0}))
      }
      this._members.push(valuer);
      this._updateMembers(this._members);
      this.tpTasks = res.tpTasks.map(t => {
        const selected = this._isTaskSelected(this._members, t.id);
        return {
          ...t,
          selected
        }
      });
    })
  }
  public deleteValuer(valuer: DataSourceItem, index: number) {
    const _title = 'Supporting Valuer';
    const _description = 'Would you like to remove this valuer as a team member?';
    const _waitDesciption = 'Valuer is deleting...';

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.tpTasks = this.tpTasks.map(task => {
        if (valuer.valuer.assigned_default_tasks.includes(task.id)) {
          return {...task, selected: false}
        }
        return task;
      });
      this._members.splice(index, 1);
      this._updateMembers(this._members);
    })
  }
  public addValuersFromTemplate() {
    const dialogRef = this.dialog.open(SupportValuerTemplateListModalComponent);
    const sub = dialogRef.afterClosed().subscribe(
        res => {
            if (!res) {
                return;
            }
            switch (res.type) {
                case 'select':
                    this._members = res.template.members.map(member => ({
                      id: undefined,
                      worker_id: member.worker_id,
                      worker: member.worker,
                      ref_id: this.refId,
                      lead_valuer: member.lead_valuer,
                      position: member.lead_valuer ? 'Lead valuer' : 'Team Member',
                      assigned_default_tasks: member.assigned_default_tasks,
                      selected_document: [],
                      selected_certificates: []
                    }));
                    this._updateMembers(this._members);
                    break;
                case 'view':
                    this._viewTemplate(res.template);
                    break;
                default:
                    break;
            }
        }
    );
  }
  
  /**
   * Template methods
   */
  public taskAvailable(valuer: IToeTeamMember, task: TpTaskSelectionItem): boolean {
    let taskAvailable = valuer.assigned_default_tasks.includes(task.id);
    taskAvailable = taskAvailable || this._taskIsAssignable(valuer, task);
    return taskAvailable;
  }
  public isReportingTaskSelected(): boolean {
    const task = this.tpTasks.find(t => t.id === 3 && t.selected);
    return task == null ? false : true;
  }

  /**
   * Events
   */
  public onChangeLeadValuer(event: MatCheckboxChange, valuer: DataSourceItem) {
    const _members = this._members.map(m => {
      if (m.worker_id == valuer.valuer.worker_id) {
        return {
          ...m,
          lead_valuer: 1
        }
      }
      return {
        ...m,
        lead_valuer: 0
      }
    });
    this._updateMembers(_members);
  }
  public onSelectPanelToggle(event: boolean, select: MatSelect, valuer: DataSourceItem) {
    if (!event) {
      const selectedTasks: number[] = select.value;
      const memberIndx = this._members.findIndex(m => m.worker_id == valuer.valuer.worker_id);
      if (memberIndx == -1) {
        return;
      }
      const tmpMember: IToeTeamMember = Object.assign({}, this._members[memberIndx]);
      tmpMember.assigned_default_tasks = selectedTasks;
      this._members.splice(memberIndx, 1, tmpMember);
      this._updateMembers(this._members);

      this.tpTasks = this.tpTasks.map(task => {
        if (this._isTaskSelected(this._members, task.id)) {
          return {...task, selected: true};
        }
        if (selectedTasks.includes(task.id)) {
          return {...task, selected: true}
        } else {
          return {...task, selected: false};
        }
      });

    }
  }


  /**
   * Methods
   */
  private _convertTeamMemberToData(members: IToeTeamMember[]): DataSourceItem[] {
    return members.map(m => ({
      name: `${m.worker.first_name} ${m.worker.last_name ? m.worker.last_name : ''}`,
      qualification_id: m.worker.qualification_id,
      qualification: this._getValuerQualificationName(m.worker.qualification_id),
      lead_valuer: m.lead_valuer == 1,
      task_ids: m.assigned_default_tasks.map(t => t),
      valuer: m
    }));
  }
  private _getValuerQualificationName(id): string {
    if (!id) {
      return '(N/A)';
    }
    if (this.valuerQualifications.length > 0) {
      return this.valuerQualifications.find(el => el.id == id).name;
    } else {
      return 'N/A';
    }
  }
  private _isTaskSelected(teamMembers: IToeTeamMember[], id: number): boolean {
    const indx = teamMembers.findIndex(m => {
        const tIndx = m.assigned_default_tasks.findIndex(t => t === id);
        return tIndx > -1;
    });
    return indx > -1;
  }
  private _taskIsAssignable(valuer: IToeTeamMember, task: TpTaskSelectionItem): boolean {
    switch (task.id) {
      case 2: {
        // Valuation
        return !task.selected && valuer.worker.qualification_id == 1;
      }
      case 3: {
        // report
        return !task.selected &&  valuer.worker.qualification_id == 1 && valuer.lead_valuer == 1;
      }
      default:
        return !task.selected;
    }
  }
  private _updateMembers(member: IToeTeamMember[]) {
    this._members = this._sortMembers(member);
    this.members$.next(this._members);
  }
  private _sortMembers(member: IToeTeamMember[]): IToeTeamMember[] {
    const _temp: IToeTeamMember[] = [];
    member.forEach(m => _temp.push(m));
    _temp.sort((a, b) => b.lead_valuer - a.lead_valuer);
    return _temp;
  }
  private _viewTemplate(template: TeamTemplateModel) {
      this.dialog.open(TeamTemplateModalComponent, {
          data: {
              template
          }
      });
  }

  convertMember(member: any) {
    return {
      firstName: member?.valuer?.worker?.first_name,
      lastName: member?.valuer?.worker?.last_name,
      email: member?.valuer?.worker?.email,
      qualification: member.qualification,
      picture: member?.valuer?.worker?.picture
    }
  }
}
