// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ToesState} from '../_reducers/toe.reducers';
import {ToeModel} from '../_models/toe.model';

export const selectToesState = createFeatureSelector<ToesState>('toes');

export const selectToeById = (toeId: number) => createSelector(
    selectToesState,
    toesState => toesState.entities[toeId]
);

export const selectToesPageLoading = createSelector(
    selectToesState,
    toesState => toesState.listLoading
);

export const selectToesActionLoading = createSelector(
    selectToesState,
    toesState => toesState.actionsLoading
);

export const selectLastCreatedToeId = createSelector(
    selectToesState,
    toesState => toesState.lastCreatedToeId
);

export const selectTrashedToeCount = createSelector(
    selectToesState,
    toesState => toesState.totalTrashed
);

export const selectAdminTrashedToeCount = createSelector(
    selectToesState,
    toesState => toesState.totalAdminTrashed
);

export const selectToesShowInitWaitingMessage = createSelector(
    selectToesState,
    toesState => toesState.showInitWaitingMessage
);

export const selectToesLastQuery = createSelector(
    selectToesState,
    toesState => toesState.lastQuery
)

export const selectIsSavingToe = createSelector(
    selectToesState,
    state => state.savingToe
);

export const selectIsReverted = createSelector(
    selectToesState,
    state => state.revertingToe
);

export const selectToesInStore = createSelector(
    selectToesState,
    toesState => {
        const items: ToeModel[] = [];
        each(toesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: ToeModel[] = [];
        // displayedColumns = ['id', 'name', 'delivery_date', 'tp_cnt', 'val_cnt', 'status', 'actions'];
        switch (toesState.lastQuery.sortField) {
            case 'lead_valuer_name':
                result = httpExtension.sortArrayString(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
                break;
            case 'instruction_date':
                result = httpExtension.sortArrayDateTime(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
                break;
            case 'final_delivery_date':
                result = httpExtension.sortArrayDateTime(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
                break;
            case 'status':
                result = httpExtension.sortArrayNumber(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
                break;
            case 'name':
                result = httpExtension.sortArrayString(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
                break;
            case 'updated_at':
                result = httpExtension.sortArrayDateTime(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension.sortArray(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
        }
        return new QueryResultsModel(result, toesState.totalCount, '', toesState.totalTrashed);
    }
);

export const selectSignedToesLoading = createSelector(
    selectToesState,
    toesState => toesState.listLoading
);

export const selectSignedToes = createSelector(
    selectToesState,
    toesState => {
        const items: ToeModel[] = [];
        each(toesState.entities, element => {
            if (element.status === 2) {
                items.push(element);
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: ToeModel[] = [];
        result = httpExtension.sortArrayDateTime(items, toesState.lastQuery.sortField, toesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, toesState.totalCount, '', toesState.totalTrashed);
    }
)
