import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AllCurrenciesRequested,
    CurrenciesActionToggleLoading,
    CurrencyDeleted,
    CurrencyModel,
    selectAdminTrashedCurrencyCount,
    selectAllCurrencies,
    CurrencyService,
    selectTrashedCurrencyCount, CurrencyOnServerAdminRestored, CurrencyOnServerRestored, CurrencyDeletedFromTrash, CurrencyTrashFlushed,
    CurrencyDeletedFromAdminTrash
} from '../../../../../../core/admin';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {select, Store} from '@ngrx/store';
import {CurrencyEditComponent} from '../currency-edit/currency-edit.component';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../../core/_base/crud';
import {AppState} from '../../../../../../core/reducers';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
type pmStatus = {
    total_currencies: string,
    total_used_in_vals: string,
}

@Component({
    selector: 'kt-currency-list',
    templateUrl: './currencies-list.component.html',
})

export class CurrenciesListComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns = ['name', 'acronym', 'symbol', 'actions'];
    currencyData: CurrencyModel[] = [];
    trashedData: CurrencyModel[] = [];
    dataSource = new MatTableDataSource([]);

    trashCnt$: Observable<number>;
    adminTrashCnt$: Observable<number>;
    currencyStatusCnts$ = new BehaviorSubject<pmStatus>({
        total_currencies: '0',
        total_used_in_vals: '0',
    });

    private unsubscribe: Subscription[] = [];

    @ViewChild('sort1', {static: true}) sort: MatSort;

    constructor(private store: Store<AppState>,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public service: CurrencyService,
                public subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.subheaderService.setTitle('Currencies');
        this.store.dispatch(new AllCurrenciesRequested());

        const selectCurrenciesSub = this.store.pipe(
            select(selectAllCurrencies),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.currencyData = res;
            this.dataSource.data = this.currencyData;

            this.currencyStatusCnts$.next({
                total_currencies: res.length.toString(),
                total_used_in_vals: '0', //TODO: used in vals calc
            })
        });

        this.unsubscribe.push(selectCurrenciesSub);

        // trash count

        this.trashCnt$ = this.store.pipe(
            select(selectTrashedCurrencyCount)
        );


        this.adminTrashCnt$ = this.store.pipe(
            select(selectAdminTrashedCurrencyCount)
        );

    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }

    addCurrency() {
        const newCurrency = new CurrencyModel();
        newCurrency.clear(); // Set all defaults fields
        this.editCurrency(newCurrency);
    }

    editCurrency(currency: CurrencyModel) {
        const _saveMessage = `Currency successfully has been added.`;
        const _messageType = currency.id ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(CurrencyEditComponent, {data: {id: currency.id}});
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
        });
    }

    deleteCurrency(_item: CurrencyModel) {
        const _title = this.translate.instant('CURRENCY.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('CURRENCY.LIST.DIALOG.DELETE.DESCRIPTION', {
            name: _item.name
        });
        const _waitDesciption = this.translate.instant('CURRENCY.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new CurrencyDeleted({id: _item.id}));
        });
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new CurrenciesActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new CurrenciesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy,
                    date: elem.user_deleted
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new CurrenciesActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new CurrenciesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.deletedBy,
                    date: elem.deleted_at
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit client dialog and save after success close result
     * @param items
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('CURRENCY.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('CURRENCY.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN')
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }


    // functions of trash
    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new CurrencyOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new CurrencyOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new CurrencyDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new CurrencyDeletedFromAdminTrash({id}));
    }


    flush() {
        this.store.dispatch(new CurrencyTrashFlushed());
    }
}
