// Angular
import {Component, OnInit} from '@angular/core';
// Layout
import {HtmlClassService} from '../html-class.service';
// Object-Path
import * as objectPath from 'object-path';
import {LayoutUtilsService} from '../../../core/_base/crud';

declare function require(name:string);

@Component({
    selector: 'kt-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
    // Public properties
    today: number = Date.now();
    footerClasses = '';
    footerContainerClasses = '';

    // version data
    version: string = require('../../../../../package.json').version;
    releaseDate: string = require('../../../../../package.json').release;


    /**
     * Component constructor
     *
     * @param uiClasses: HtmlClassService
     * @param layoutUtilsService: LayoutUtilsService
     */
    constructor(private uiClasses: HtmlClassService,
                private layoutUtilsService: LayoutUtilsService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.footerClasses = this.uiClasses.getClasses('footer', true).toString();
        this.footerContainerClasses = this.uiClasses.getClasses('footer_container', true).toString();
    }


    showTerms() {
        this.layoutUtilsService.showTerms();
    }
}
