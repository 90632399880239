import { Component, Input, OnInit } from '@angular/core';

export type ValuationModuleV2ViewModel = {
  client: string,
  purposeOfValuation: string,
  baseOfValue: string,
  approachToValue: string,
  methodToValue: string,
  premiseOfValue: string,
  tenure: string
}

@Component({
  selector: 'kt-valuation-module-v2',
  templateUrl: './valuation-module-v2.component.html',
  styleUrls: [
    '../info-module.styles.scss',
    './valuation-module-v2.component.scss'
  ]
})
export class ValuationModuleV2Component implements OnInit {
  @Input() viewModel: ValuationModuleV2ViewModel

  constructor() { }

  ngOnInit(): void {}
}
