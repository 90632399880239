import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { select, Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AddComparableValuationAdjustment, AddMultipleComparableValuationAdjustment, CreateValuationAdjustment, ValuationAdjustmentsCreated, CreateValuationAdjustments, CriterionFromAdjustmentRemoved, EditValuationAdjustment, EditValueOfValuationAdjustment, RemoveComparableValuationAdjustment, RemoveCriterionFromAdjustment, RemoveValuationAdjustment, ValuationAdjustmentActionTypes, ValuationAdjustmentComparableAdded, ValuationAdjustmentComparableRemoved, ValuationAdjustmentCreated, ValuationAdjustmentEdited, ValueOfValuationAdjustmentEdited } from "../_actions/valuation-adjustment.actions";
import { ValuationAdjustmentModel } from "../_models/valuation-adjustment.model";
import { selectAllValuationAdjustments, selectLastCreatedValuationAdjustmentID } from "../_selectors/valuation-adjustment.selector";
import { ValuationAdjustmentService } from "../_services/valuation-adjustment.service";
import * as valuationActions from '../_actions/valuation.actions';

@Injectable()
export class ValuationAdjustmentEffects {
    constructor(private actions$: Actions, private store: Store<AppState>, private service: ValuationAdjustmentService) {}

    @Effect()
    createAdjustment$ = this.actions$.pipe(
        ofType<CreateValuationAdjustment>(ValuationAdjustmentActionTypes.CreateValuationAdjustment),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload.valuationAdjustment),
                this.store.pipe(select(selectLastCreatedValuationAdjustmentID), take(1))
            ])),
        map(([model, id]) => {
            const _newModel = this.service.createValuationAdjustment(model, id + 1)
            return new ValuationAdjustmentCreated({
                valuationAdjustment: _newModel,
            })
        })
    )

    @Effect()
    createAdjustments$ = this.actions$.pipe(
        ofType<CreateValuationAdjustments>(ValuationAdjustmentActionTypes.CreateValuationAdjustments),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload.models),
                this.store.pipe(select(selectLastCreatedValuationAdjustmentID), take(1))
            ])),
        map(([models, id]) => {
            const _newModels = models.map((item, ind) => this.service.createValuationAdjustment(item, id + ind + 1))
            return new ValuationAdjustmentsCreated({
                models: _newModels,
                lastId: _newModels.length > 0 ? _newModels[_newModels.length - 1].id : id
            })
        })
    )

    @Effect()
    editAdjustment$ = this.actions$.pipe(
        ofType<EditValuationAdjustment>(ValuationAdjustmentActionTypes.EditValuationAdjustment),
        map(({payload}) => {
            const _changed: Update<ValuationAdjustmentModel> = {
                id: payload.valuationAdjustment.id,
                changes: {
                    ...payload.valuationAdjustment,
                }  
            }
            return new ValuationAdjustmentEdited({
                valuationAdjustment: _changed
            })
        })
    )

    @Effect()
    editValueOfAdjustment$ = this.actions$.pipe(
        ofType<EditValueOfValuationAdjustment>(ValuationAdjustmentActionTypes.EditValueOfValuationAdjustment),
        map(({payload}) => {
            const _vals = Object.assign({}, payload.v.adjValues) as {[id: number]: number};
            _vals[payload.comID] = payload.val;
            const _changed: Update<ValuationAdjustmentModel> = {
                id: payload.v.id,
                changes: {
                    adjValues: _vals
                }
            }
            return new ValueOfValuationAdjustmentEdited({
                valuationAdjustment: _changed
            })
        })
    )

    @Effect()
    valueOfAdjustmentEdited$ = this.actions$.pipe(
        ofType<ValueOfValuationAdjustmentEdited>(ValuationAdjustmentActionTypes.ValueOfValuationAdjustmentEdited),
        map(() => {
            // update valuations
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    valuationAdjustmentsAdded$ = this.actions$.pipe(
        ofType<ValuationAdjustmentComparableAdded>(ValuationAdjustmentActionTypes.ComparableAdded),
        map(() => {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    removeAdjustment$ = this.actions$.pipe(
        ofType<RemoveValuationAdjustment>(ValuationAdjustmentActionTypes.RemoveValuationAdjustment),
        map(() => {
            return new valuationActions.UpdateValuationsExternalData();
        })
    )

    @Effect()
    addComparable$ = this.actions$.pipe(
        ofType<AddComparableValuationAdjustment>(ValuationAdjustmentActionTypes.AddComparable),
        mergeMap(res => combineLatest([of(res.payload.com), this.store.pipe(select(selectAllValuationAdjustments), take(1))])),
        map(([comparable, valuationAdjustments]) => {
            const _res = this.service.addComparable(comparable, valuationAdjustments);
            return new ValuationAdjustmentComparableAdded({
                valuationAdjustments: _res
            })
        })
    )

    @Effect()
    addComparables$ = this.actions$.pipe(
        ofType<AddMultipleComparableValuationAdjustment>(ValuationAdjustmentActionTypes.AddMultipleComparables),
        mergeMap(res => combineLatest([
            of(res.payload.com), 
            this.store.pipe(select(selectAllValuationAdjustments), take(1))])),
        map(([comparable, valuationAdjustments]) => {
            const _res = this.service.addComparables(comparable, valuationAdjustments);
            return new ValuationAdjustmentComparableAdded({
                valuationAdjustments: _res
            })
        })
    )

    @Effect()
    removeComparable$ = this.actions$.pipe(
        ofType<RemoveComparableValuationAdjustment>(ValuationAdjustmentActionTypes.RemoveComparable),
        mergeMap(res => combineLatest([of(res.payload.comID), this.store.pipe(select(selectAllValuationAdjustments), take(1))])),
        map(([id, vas]) => {
            const res = this.service.removeComparable(id, vas);
            return new ValuationAdjustmentComparableRemoved({
                valuationAdjustments: res
            })
        })
    )

    @Effect()
    removeCriterion$ = this.actions$.pipe(
        ofType<RemoveCriterionFromAdjustment>(ValuationAdjustmentActionTypes.RemoveCriterionFromAdjustment),
        mergeMap(({paylaod}) => combineLatest([of(paylaod.criterion), this.store.pipe(select(selectAllValuationAdjustments), take(1))])),
        map(([criterion, adjustments]) => {
            const adjustmentUpdates: Update<ValuationAdjustmentModel>[] = [];
            adjustments.forEach(a => {
                const adjustmentUpdate: Update<ValuationAdjustmentModel> = {
                    id: a.id,
                    changes: {}
                };
                adjustmentUpdates.push(adjustmentUpdate);
            });
            return new CriterionFromAdjustmentRemoved({
                adjustments: adjustmentUpdates
            })
        })
    )
}