<div class="card card-custom">
	<div class="card-body">
		<form [formGroup]="form">
			<!-- <kt-design-layout [readonly]="readonly" [targetProperty]="tp"
				[selectedTpPics]="reporting.tp_selected_pictures"></kt-design-layout> -->
			<kt-general-valuation-matters [readonly]="readonly" 
				[valuations]="valuations" 
				[targetProperty]="tp"
				[toe]="toe" [reporting]="reporting" [draftReport]="draftReport" [members]="toeMembers"
				[valuationsWithScenario]="valuationsWithScenario"
				[groupedValuations]="groupedValuations"></kt-general-valuation-matters>
			<kt-vpga-two *ngIf="toe.purpose_of_valuation_id == 8" [readonly]="readonly"
				[SummaryOfOccupationalLeasesFiles]="reporting.summary_of_occupational_leases_files"
				[CurrentRentalIncomeFiles]="reporting.current_rental_income_files"></kt-vpga-two>
			<kt-supporting-documents [readonly]="readonly" [leadValuer]="leadValuer" [members]="members"
				[agency]="agency" [otherDocs]="toe.additional_files" [reporting]="reporting"
				[reportingID]="tp.reporting_id"
				></kt-supporting-documents>
		</form>
	</div>
</div>