import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';

export interface ManualModalData {
  completed: boolean;
  completed_date: Date;
}

@Component({
  selector: 'kt-manual-modal',
  templateUrl: './manual-modal.component.html',
  styleUrls: ['./manual-modal.component.scss']
})
export class ManualModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  onDestroy$: Subject<void> = new Subject();
  readonly: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ManualModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ManualModalData,
    private formBuilder: UntypedFormBuilder,
    private readonlyService: ReadonlyService
  ) {}

  ngOnInit(): void {
    this.readonly = this.readonlyService.isReadOnly();
    this.form = this.formBuilder.group({
      completed: [this.data.completed],
      completed_at: [this.data.completed_date]
    });
    this.form.controls.completed.valueChanges.pipe(
      startWith(this.form.controls.completed.value),
      takeUntil(this.onDestroy$)
    ).subscribe(val => {
      if (val) {
        this.form.controls.completed_at.setValidators([Validators.required]);
      } else {
        this.form.controls.completed_at.clearValidators();
      }
      this.form.controls.completed_at.updateValueAndValidity();
    })
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  save() {
    if (this.readonly) {
      this.dialogRef.close();
      return;
    }
    let hasError = false;
    if (this.form.invalid) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(controlName => {
        if (controls[controlName].invalid) {
          controls[controlName].markAsTouched();
          hasError = true;
        }
      })
    }
    if(hasError) {
      return;
    }
    const returnData: ManualModalData = {
      completed: this.form.controls.completed.value,
      completed_date: this.form.controls.completed_at.value ? new Date(this.form.controls.completed_at.value) : null
    };
    this.dialogRef.close(returnData);
  }
  close() {this.dialogRef.close();}
  clearDate(control) {
    control.setValue(null);
    control.updateValueAndValidity();
  }

}
