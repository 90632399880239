// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../../core/reducers';
import {selectAgenciesPageLoading, selectAgenciesShowInitWaitingMessage, selectQueryResult} from '../_selectors/agency.selectors';

// Selection

export class AgencyDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAgenciesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAgenciesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
