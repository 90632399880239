import { AssetClassLandAreaComponentModel } from '../../asset_class';
import {BaseModel} from '../../_base/crud';
import { AcSource } from './ac-source.model';
import { AssetClassSize, Consideration, InputAmountType, LeaseDurationType, RentReviewDetailType, RentType } from '../../v2/types';
import { dateFormat } from '../../v2/utils';
import { AssetClassSizeModel } from './asset-class-size.model';
import { initialUnitMeasurementsState } from '../../linked-tables/_reducers/unit-measurement.reducers';

export class AssetClassTenure extends BaseModel {
    id: number;
    ac_id: number;
    ac_type_id: number;

    tenure: string;

    currency: string;
    rent_type: string;
    total_consideration: number;

    rent_review: number;
    rent_review_comment: string;
    lease_duration: number;
    unexpired_lease_term: number;
    write_off_period: number;
    break_option: number;
    rent_free_period: number;
    fitting_out_period: number;
    cap_payment: number;


    inclusive_property_management: string;
    estimated_amount_property_management: number;

    consideration_inclusive_cost: string;
    estimated_amount_utilities_cost: number;

    consideration_full_repair_cost: string;
    estimated_amount_repair_cost: number;

    conversion: {
        id?: number,
        quote_currency: string,
        exchange_rate: number;
    }[];

    source: AcSource;
    additional: AcLandTenure[];

    formType: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    // New Fields
    consideration_type_id: number = null
    consideration_type_name: string = null
    start_date: string = null
    duration_type: LeaseDurationType = null
    rent_input_amount_type: string = null
    rent_size_id: number = null
    rent_size: {
        size: number
    } | null = null
    rent_size_uid: string = null
    rent_basis_id: number = null
    rent_basis_name: string = null
    rent_review_type_id: number = null
    rent_review_type_name: string = null
    rent_review_detail_type: string = null
    rent_review_detail: number = null
    expenses_input_amount_type: string = null
    expenses_size_id: number = null
    expenses_size_uid: string = null
    recoverable_property_management: number | null = null
    recoverable_leasing_expenses: number | null = null
    recoverable_utilities: number | null = null
    recoverable_maintenance: number | null = null
    recoverable_insurance: number | null = null
    recoverable_janitorial: number | null = null
    recoverable_property_taxes: number | null = null
    recoverable_business: number | null = null
    recoverable_others: number | null = null
    non_recoverable_property_management: number | null = null
    non_recoverable_leasing_expenses: number | null = null
    non_recoverable_utilities: number | null = null
    non_recoverable_maintenance: number | null = null
    non_recoverable_insurance: number | null = null
    non_recoverable_janitorial: number | null = null
    non_recoverable_property_taxes: number | null = null
    non_recoverable_business: number | null = null
    non_recoverable_others: number | null = null

    notes: string | null

    clear() {
        this.id = undefined;

        this.ac_id = undefined;
        this.ac_type_id = undefined;

        this.tenure = '';

        this.total_consideration = undefined;

        this.rent_type = 'headline';
        this.rent_review = undefined;
        this.rent_review_comment = '';
        this.lease_duration = undefined;
        this.unexpired_lease_term = undefined;
        this.write_off_period = undefined;
        this.break_option = undefined;
        this.rent_free_period = undefined;
        this.fitting_out_period = undefined;
        this.cap_payment = 0;

        this.inclusive_property_management = 'yes';
        this.estimated_amount_property_management = undefined;
        this.consideration_inclusive_cost = 'yes';
        this.estimated_amount_utilities_cost = undefined;
        this.consideration_full_repair_cost = 'yes';
        this.estimated_amount_repair_cost = undefined;
        this.currency = 'MNT';

        this.source = new AcSource();
        this.source.clear();
        this.additional = [];

        this.formType = 2;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }

    public static toConsideration(item: AssetClassTenure): Consideration {
        const considerationType = item.consideration_type_id !== null
            ? {id: item.consideration_type_id, name: item.consideration_type_name}
            : undefined

        return {
            id: item.id,
            assetClassId: item.ac_id,
            formType: item.formType,

            currency: item.currency,

            tenure: item.tenure,
            considerationType, 

            saleInputAmount: item.total_consideration,
            rentType: item.rent_type as RentType,
            startDate: item.start_date ? new Date(item.start_date) : null, 
            duration: item.lease_duration,
            durationType: item.duration_type,
            rentFreePeriod: item.rent_free_period,
            fittingOutPeriod: item.fitting_out_period,
            writeOffPeriodAfter: item.write_off_period,
            breakOptionAfter: item.break_option,
            capitalPayment: item.cap_payment,
            unexpiredLeaseTerms: item.unexpired_lease_term,

            rentInputAmountType: item.rent_input_amount_type as InputAmountType,
            rentSizeUId: item.rent_size_uid === undefined ? null : item.expenses_size_uid,
            rentSize: item.rent_size,
            rentInputAmount: item.total_consideration,
            rentReviewCycle: item.rent_review,
            rentBasis: item.rent_basis_id
                ? {id: item.rent_basis_id, name: item.rent_basis_name}
                : null,

            rentReviewType: item.rent_review_type_id
                ? {id: item.rent_review_type_id, name: item.rent_review_type_name}
                : null,
            rentReviewDetailType: item.rent_review_detail_type as RentReviewDetailType,
            rentReviewDetail: item.rent_review_detail,

            expensesInputAmountType: item.expenses_input_amount_type as InputAmountType,
            expensesSizeUid: item.expenses_size_uid === undefined ? null : item.expenses_size_uid,
            recoverablePropertyManagement: item.recoverable_property_management,
            recoverableLeasingExpenses: item.recoverable_leasing_expenses,
            recoverableUtilities: item.recoverable_utilities,
            recoverableMaintenance: item.recoverable_maintenance,
            recoverableInsurance: item.recoverable_insurance,
            recoverableJanitorial: item.recoverable_janitorial,
            recoverablePropertyTaxes: item.recoverable_property_taxes,
            recoverableBusiness: item.recoverable_business,
            recoverableOthers: item.recoverable_others,
            nonRecoverablePropertyManagement: item.non_recoverable_property_management,
            nonRecoverableLeasingExpenses: item.non_recoverable_leasing_expenses,
            nonRecoverableUtilities: item.non_recoverable_utilities,
            nonRecoverableMaintenance: item.non_recoverable_maintenance,
            nonRecoverableInsurance: item.non_recoverable_insurance,
            nonRecoverableJanitorial: item.non_recoverable_janitorial,
            nonRecoverablePropertyTaxes: item.non_recoverable_property_taxes,
            nonRecoverableBusiness: item.non_recoverable_business,
            nonRecoverableOthers: item.non_recoverable_others,

            notes: item.notes,

            source: item.source,
            additional: item.additional
        }
    }

    public static fromConsideration(consideration: Consideration, sizes: AssetClassSize[]): AssetClassTenure {
        const item = new AssetClassTenure()
        item.clear()

        item.formType = consideration.formType
        item.id = consideration.id
        item.ac_id = consideration.assetClassId

        item.currency = consideration.currency

        item.tenure = consideration.tenure

        item.total_consideration = consideration.considerationType
            ? consideration.considerationType.id === 1
                ? consideration.saleInputAmount
                : consideration.rentInputAmount
            : 0

        item.rent_type = consideration.rentType
        item.lease_duration = consideration.duration
        item.unexpired_lease_term = consideration.unexpiredLeaseTerms
        item.write_off_period = consideration.writeOffPeriodAfter
        item.break_option = consideration.breakOptionAfter
        item.rent_free_period = consideration.rentFreePeriod
        item.fitting_out_period = consideration.fittingOutPeriod
        item.cap_payment = consideration.capitalPayment

        item.rent_review = consideration.rentReviewCycle


        item.source = consideration.source
        item.additional = consideration.additional

        // New Fields
        item.consideration_type_id = consideration.considerationType 
            ? consideration.considerationType.id
            : null

        item.start_date = consideration.startDate ? dateFormat(consideration.startDate) : null
        item.duration_type = consideration.durationType

        item.rent_input_amount_type = consideration.rentInputAmountType
        item.rent_size_id = sizes.find(size => size.uid === consideration.rentSizeUId)?.id
        item.rent_size_uid = consideration.rentSizeUId
        item.rent_basis_id = consideration.rentBasis
            ? consideration.rentBasis.id
            : null
        item.rent_size = consideration.rentSize
            ? {size: consideration.rentSize.size}
            : null
        item.rent_review_type_id = consideration.rentReviewType
            ? consideration.rentReviewType.id
            : null
        item.rent_review_detail_type = consideration.rentReviewDetailType
        item.rent_review_detail = consideration.rentReviewDetail

        item.expenses_input_amount_type = consideration.expensesInputAmountType
        item.expenses_size_id = sizes.find(size => size.uid === consideration.expensesSizeUid)?.id
        item.expenses_size_uid = consideration.expensesSizeUid

        item.recoverable_property_management = consideration.recoverablePropertyManagement
        item.recoverable_leasing_expenses = consideration.recoverableLeasingExpenses
        item.recoverable_utilities = consideration.recoverableUtilities
        item.recoverable_maintenance = consideration.recoverableMaintenance
        item.recoverable_insurance = consideration.recoverableInsurance
        item.recoverable_janitorial = consideration.recoverableJanitorial
        item.recoverable_property_taxes = consideration.recoverablePropertyTaxes
        item.recoverable_business = consideration.recoverableBusiness
        item.recoverable_others = consideration.recoverableOthers
        item.non_recoverable_property_management = consideration.nonRecoverablePropertyManagement
        item.non_recoverable_leasing_expenses = consideration.nonRecoverableLeasingExpenses
        item.non_recoverable_utilities = consideration.nonRecoverableUtilities
        item.non_recoverable_maintenance = consideration.nonRecoverableMaintenance
        item.non_recoverable_insurance = consideration.nonRecoverableInsurance
        item.non_recoverable_janitorial = consideration.nonRecoverableJanitorial
        item.non_recoverable_property_taxes = consideration.nonRecoverablePropertyTaxes
        item.non_recoverable_business = consideration.nonRecoverableBusiness
        item.non_recoverable_others = consideration.nonRecoverableOthers

        item.notes = consideration.notes

        return item
    }
}

export class PropertyTenure {
    id: number;
    ref_id: number;

    tenure: string;

    currency: string;
    rent_type: string;
    total_consideration: number;

    rent_review: number;
    rent_review_comment: string;
    lease_duration: number;
    unexpired_lease_term: number;
    write_off_period: number;
    break_option: number;
    rent_free_period: number;
    fitting_out_period: number;
    cap_payment: number;


    inclusive_property_management: string;
    estimated_amount_property_management: number;

    consideration_inclusive_cost: string;
    estimated_amount_utilities_cost: number;

    consideration_full_repair_cost: string;
    estimated_amount_repair_cost: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;

        this.ref_id = undefined;

        this.tenure = '';

        this.total_consideration = undefined;

        this.rent_type = 'headline';
        this.rent_review = undefined;
        this.rent_review_comment = '';
        this.lease_duration = undefined;
        this.unexpired_lease_term = undefined;
        this.write_off_period = undefined;
        this.break_option = undefined;
        this.rent_free_period = undefined;
        this.fitting_out_period = undefined;
        this.cap_payment = 0;

        this.inclusive_property_management = 'yes';
        this.estimated_amount_property_management = undefined;
        this.consideration_inclusive_cost = 'yes';
        this.estimated_amount_utilities_cost = undefined;
        this.consideration_full_repair_cost = 'yes';
        this.estimated_amount_repair_cost = undefined;
        this.currency = 'MNT';

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}

export class AcConsideration {
    id: number;
    pTenure: PropertyTenure;
    source: AcSource;
    additional: {
        tenure: AcLandTenure,
        included: boolean
    }[];

    clear() {
        this.id = undefined;
        this.pTenure = new PropertyTenure();
        this.pTenure.clear();

        this.source = new AcSource();
        this.source.clear();
        this.additional = [];
    }
}

export class AcLandTenure {
    id: number;
    com_order: number;
    included: boolean;

    land_tenure_id: number;
    land_tenure_name: string;
    tenure_length: number;
    currency: string;
    consideration: number;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.com_order = undefined;
        this.included = true;
        this.land_tenure_id = null;
        this.land_tenure_name = '';
        this.tenure_length = 0;
        this.currency = '';
        this.consideration = 0;

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}