import {ClientContactModel} from './../_models/client-contact.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ContactsState} from '../_reducers/contact.reducers';
import * as fromContact from '../_reducers/contact.reducers';
import {each} from 'lodash';

export const selectContactsState = createFeatureSelector<ContactsState>('contacts');

export const selectContactById = (contactId: number) => createSelector(
    selectContactsState,
    contactsState => contactsState.entities[contactId]
);

export const selectAllContacts = createSelector(
    selectContactsState,
    fromContact.selectAll
);

export const selectAllContactsWithTrash = createSelector(
    selectContactsState,
    contactsState => {
        const items: ClientContactModel[] = [];
        let totalTrashed = 0;
        each(contactsState.entities, element => {
            if (element.user_deleted == null) {
                items.push(element);
            } else {
                totalTrashed++;
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ClientContactModel[] = httpExtension.sortArray(items);

        return new QueryResultsModel(result, contactsState.queryRowsCount, '', totalTrashed);
    }
);

export const selectTrashedContacts = createSelector(
    selectContactsState,
    contactsState => {
        const items: ClientContactModel[] = [];
        each(contactsState.entities, element => {
            if (element.user_deleted != null) {
                items.push(element);
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ClientContactModel[] = httpExtension.sortArray(items);
        return result;
    }
);

export const selectAllContactsIds = createSelector(
    selectContactsState,
    fromContact.selectIds
);

export const allContactsLoaded = createSelector(
    selectContactsState,
    contactsState => contactsState.isAllContactsLoaded
);


export const selectContactsPageLoading = createSelector(
    selectContactsState,
    contactsState => contactsState.listLoading
);

export const selectContactsActionLoading = createSelector(
    selectContactsState,
    contactsState => contactsState.actionLoading
);

export const selectLastCreatedContactId = createSelector(
    selectContactsState,
    contactsState => contactsState.lastCreatedContactId
);

export const selectContactsShowInitWaitingMessage = createSelector(
    selectContactsState,
    contactsState => contactsState.showInitWaitingMessage
);


export const selectQueryResult = createSelector(
    selectContactsState,
    contactsState => {
        const items: ClientContactModel[] = [];
        each(contactsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ClientContactModel[] = httpExtension.sortArray(items, contactsState.lastQuery.sortField, contactsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, contactsState.queryRowsCount, '', contactsState.totalTrashed);
    }
);
