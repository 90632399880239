import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SRERAssumptionModel, SRERAssumptionType, SRERStatus, ValuationSRERModel } from 'src/app/core/valuation/_models/valuation-srer.model';

@Component({
  selector: 'kt-edit-srer',
  templateUrl: './edit-srer.component.html',
  styleUrls: ['./edit-srer.component.scss']
})
export class EditSrerComponent implements OnInit, OnDestroy {
  item: SRERAssumptionModel;
  externalRef: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  status = SRERStatus;

  constructor(
    public dialogRef: MatDialogRef<EditSrerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.item = Object.assign({}, data.item) as SRERAssumptionModel;
    this.externalRef = data.externalRef;
  }

  ngOnInit(): void {this._createFormV2()}

  ngOnDestroy() {this.subscriptions.forEach(s => s.unsubscribe());}

  _createForm() {
    this.form = new UntypedFormGroup({
      'input': new UntypedFormControl({value: this.item.input, disabled: this.item.status === SRERStatus.Removed}),
      'status': new UntypedFormControl('' + this.item.status),
      'justification': new UntypedFormControl(this.item.justification)
    });

    const inputControl = this.form.get('input');
    const inputSub = inputControl.valueChanges.subscribe((val: string) => {
      if (this.item.input !== val.trim()) {
        this.item.status = SRERStatus.Amended;
      } else {
        this.item.status = SRERStatus.Valid;
      }

      // Set status
      const statusControl = this.form.get('status');
      statusControl.setValue('' + this.item.status);
      statusControl.updateValueAndValidity();

      // Set Justification
      const justificationControl = this.form.get('justification');
      if (this.item.status !== SRERStatus.Valid) {
        justificationControl.setValidators(Validators.required);
        justificationControl.updateValueAndValidity();
      } else {
        justificationControl.clearValidators();
        justificationControl.updateValueAndValidity();
      }
    });
    this.subscriptions.push(inputSub);

    const statusControl = this.form.get('status');
    const statusSub = statusControl.valueChanges.subscribe(val => {
      this.item.status = Number(val);
    });
    this.subscriptions.push(statusSub);
  }

  _createFormV2() {
    this.form = new UntypedFormGroup({
      'input': new UntypedFormControl({value: this.item.input, disabled: this.item.status === SRERStatus.Removed}, Validators.required),
      'status': new UntypedFormControl({value: '' + this.item.status, disabled: true}),
      'justification': new UntypedFormControl(this.item.justification)
    });

    if (this.item.status === SRERStatus.Valid) {
      // Listen 'Input' value changes
      const inputControl = this.form.get('input');
      const inputSub = inputControl.valueChanges.subscribe((val: string) => {
        if (this.item.input != val.trim()) {
          this.item.status = SRERStatus.Amended;
        } else {
          this.item.status = SRERStatus.Valid;
        }

        const statusControl = this.form.get('status');
        statusControl.setValue('' + this.item.status);
        statusControl.updateValueAndValidity();
      });
      this.subscriptions.push(inputSub);
    } else {
      const justificationControl = this.form.get('justification');
      justificationControl.setValidators(Validators.required);
      justificationControl.updateValueAndValidity();
    }

    // Listen status changes
    const statusControl = this.form.get('status');
    const statusSub = statusControl.valueChanges.subscribe(val => {
      this.item.status = Number(val);

      // Set justification
      const justificationControl = this.form.get('justification');
      if (this.item.status !== SRERStatus.Valid) {
        justificationControl.setValidators(Validators.required);
        justificationControl.updateValueAndValidity();
      } else {
        justificationControl.setValidators(null);
        justificationControl.updateValueAndValidity();
      }
    });
    this.subscriptions.push(statusSub);
  }

  getComponentTitle() {
    switch (this.item.type) {
      case SRERAssumptionType.Default:
        return `Edit ${this.externalRef} - Assumption`;
      case SRERAssumptionType.Special:
        return `Edit ${this.externalRef} - Special Assumption`;
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(controlName => {
        this.form.controls[controlName].markAsTouched();
      })
      return;
    }

    const _input = this.form.get('input').value;
    const _status = Number(this.form.get('status').value);
    const _justification = this.form.get('justification').value;
    this.item.input = _input;
    this.item.status = _status;
    this.item.justification = _justification;
    this.dialogRef.close(this.item);
  }

  onClose() {this.dialogRef.close()}

  checkWrongValue(val): boolean {return isNaN(val.key) || val.key == 0;}

}
