import {ParkingTypeModel} from '../_models/parking-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ParkingTypesState} from '../_reducers/parking-type.reducers';
import * as fromParkingType from '../_reducers/parking-type.reducers';
import {each} from 'lodash';

export const selectParkingTypesState = createFeatureSelector<ParkingTypesState>('parking-types');

export const selectParkingTypeById = (id: number) => createSelector(
    selectParkingTypesState,
    state => state.entities[id]
);

export const selectAllParkingTypes = createSelector(
    selectParkingTypesState,
    fromParkingType.selectAll
);

export const selectAllParkingTypesIds = createSelector(
    selectParkingTypesState,
    fromParkingType.selectIds
);

export const allParkingTypesLoaded = createSelector(
    selectParkingTypesState,
    state => state.isAllParkingTypesLoaded
);


export const selectParkingTypesPageLoading = createSelector(
    selectParkingTypesState,
    state => state.listLoading
);

export const selectParkingTypesActionLoading = createSelector(
    selectParkingTypesState,
    state => state.actionLoading
);

export const selectLastCreatedParkingTypeId = createSelector(
    selectParkingTypesState,
    state => state.lastCreatedParkingTypeId
);

export const selectParkingTypesShowInitWaitingMessage = createSelector(
    selectParkingTypesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedParkingTypeCount = createSelector(
    selectParkingTypesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedParkingTypeCount = createSelector(
    selectParkingTypesState,
    (state) => state.totalAdminTrashed
);


export const selectParkingTypeQueryResult = createSelector(
    selectParkingTypesState,
    state => {
        const items: ParkingTypeModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ParkingTypeModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
