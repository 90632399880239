import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {UnitMeasurement} from '../_models/unit-measurement.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';
import { map } from 'rxjs/operators';



@Injectable()
export class UnitMeasurementsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/unit-measurements`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // UnitMeasurements
    getAllUnitMeasurements(): Observable<any> {
        return this.http.get<any>(this.API_URL, {headers: this.authService.getAuthHeaders()});
    }


    findUnitMeasurements(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = this.API_URL;
		return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
    }

    getUnitMeasurementById(unitMeasurementId: number): Observable<UnitMeasurement> {
        return this.http.get<UnitMeasurement>(this.API_URL + `/${unitMeasurementId}`, {headers: this.authService.getAuthHeaders()});
    }

    // CREATE =>  POST: add a new unitMeasurement to the server
    createUnitMeasurement(unitMeasurement: UnitMeasurement): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {unitMeasurement: unitMeasurement};

        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the unitMeasurement on the server
    updateUnitMeasurement(unitMeasurement: UnitMeasurement): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_URL + `/${unitMeasurement.id}`, unitMeasurement, {headers: httpHeaders});
    }

    // DELETE => delete the unitMeasurement from the server
    deleteUnitMeasurement(unitMeasurementId: number): Observable<any> {
        const url = `${this.API_URL}/${unitMeasurementId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {headers: this.authService.getAuthHeaders()});
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: this.authService.getAuthHeaders()});
    }

    deleteFromTrash(agencyId: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${agencyId}`, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    // delete from admin trash
    deleteFromAdminTrash(agencyId: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${agencyId}`, {headers: this.authService.getAuthHeaders()});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    fetchListing() {
        return this.http.get<{data: {id: number, name: string}[]}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/unit-measurements/listing`,
            {
                headers: this.authService.getAuthHeaders()
            }
        ).pipe(
            map(response => response.data)
        )
    }

    fetch(id: number) {
        return this.http.get<{data: {id: number, name: string, area_acronym: string}}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/unit-measurements/${id}`,
            {
                headers: this.authService.getAuthHeaders()
            }
        ).pipe(
            map(response => response.data)
        )
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
