import { Action, createReducer, on } from "@ngrx/store"
import * as LiquidationValuationActions from '../_actions/liquidation-valuation.actions'

export const featureKey = 'liquidation-valuation'

export interface LiquidationValuationState {
  importOtherValuation: boolean,
  selectedValuationId: number,
  disposalPeriod: number,
  disposalPeriodType: number,
  addDiscount: boolean,
  discount: number
}

const initialState: LiquidationValuationState = {
  importOtherValuation: false,
  selectedValuationId: null,
  disposalPeriod: null,
  disposalPeriodType: null,
  addDiscount: false,
  discount: null
}

const liquidationValuationReducer = createReducer(
  initialState,
  on(LiquidationValuationActions.setLiquidationValues, (state, {values}) => {
    return {
      ...state,
      importOtherValuation: values.import_other_valuations == 1,
      selectedValuationId: values.imported_valuation_id,
      disposalPeriod: values.disposal_period,
      disposalPeriodType: values.disposal_period_type,
      addDiscount: values.has_discount == 1,
      discount: values.discount
    }
  }),
  on(LiquidationValuationActions.changeLiquidationFormValues, (state, {values}) => {
    return {
      ...state,
      importOtherValuation: values.importOtherValuations,
      selectedValuationId: values.selectedValuationId
    }
  }),
  on(LiquidationValuationActions.changeLiquidationValueEstimateFormValues, (state, {values}) => ({
    ...state,
    ...values
  })),
  on(LiquidationValuationActions.resetLiquidationValues, (_state) => {
    return initialState
  })
)

export function reducer(state: LiquidationValuationState|undefined, action: Action) {
  return liquidationValuationReducer(state, action)
}