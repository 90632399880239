// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {SourceCredibility} from '../_models/source-credibility.model';

export enum SourceCredibilityActionTypes {

    AllSourceCredibilityRequested = '[SourceCredibility Home Page] All SourceCredibility Requested',
    AllSourceCredibilityLoaded = '[SourceCredibility API] All SourceCredibility Loaded',

    SourceCredibilityOnServerCreated = '[Edit SourceCredibility Dialog] SourceCredibility On Server Created',
    SourceCredibilityCreated = '[Edit SourceCredibility Dialog] SourceCredibility Created',
    SourceCredibilityUpdated = '[Edit SourceCredibility Dialog] SourceCredibility Updated',
    SourceCredibilityDeleted = '[SourceCredibility List Page] SourceCredibility Deleted',

    SourceCredibilityPageRequested = '[SourceCredibility List Page] SourceCredibility Page Requested',
    SourceCredibilityPageLoaded = '[SourceCredibility API] SourceCredibility Page Loaded',
    SourceCredibilityPageCancelled = '[SourceCredibility API] SourceCredibility Page Cancelled',

    SourceCredibilityPageToggleLoading = '[SourceCredibility page] SourceCredibility Page Toggle Loading',
    SourceCredibilityActionToggleLoading = '[SourceCredibility] SourceCredibility Action Toggle Loading',

    // trash system

    SourceCredibilityOnServerRestored = '[SourceCredibility Trash] SourceCredibility On Server Restored',
    SourceCredibilityRestored = '[SourceCredibility Trash] SourceCredibility Restored',

    SourceCredibilityOnServerAdminRestored = '[SourceCredibility Admin Trash] SourceCredibility On Server Restored',
    SourceCredibilityAdminRestored = '[SourceCredibility Admin Trash] SourceCredibility Restored',

    SourceCredibilityDeletedFromTrash = '[SourceCredibility Trash] SourceCredibility deleted',
    SourceCredibilityDeletedFromAdminTrash = '[SourceCredibility Admin Trash] SourceCredibility deleted',

    SourceCredibilityTrash = 'SourceCredibility Trashed',
    SourceCredibilityTrashFlushed = 'SourceCredibility Trash Flushed',
}

export class SourceCredibilityOnServerCreated implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityOnServerCreated;

    constructor(public payload: { sourceCredibility: SourceCredibility}) {
    }
}

export class SourceCredibilityCreated implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityCreated;

    constructor(public payload: { sourceCredibility: SourceCredibility }) {
    }
}

export class SourceCredibilityUpdated implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityUpdated;

    constructor(public payload: {
        partialSourceCredibility: Update<SourceCredibility>,
        sourceCredibility: SourceCredibility
    }) {
    }
}

export class SourceCredibilityDeleted implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SourceCredibilityPageRequested implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SourceCredibilityPageLoaded implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityPageLoaded;

    constructor(public payload: { sourceCredibility: SourceCredibility[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SourceCredibilityPageCancelled implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityPageCancelled;
}

export class AllSourceCredibilityRequested implements Action {
    readonly type = SourceCredibilityActionTypes.AllSourceCredibilityRequested;
}

export class AllSourceCredibilityLoaded implements Action {
    readonly type = SourceCredibilityActionTypes.AllSourceCredibilityLoaded;

    constructor(public payload: { sourceCredibility: SourceCredibility[] }) {
    }
}

export class SourceCredibilityPageToggleLoading implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SourceCredibilityActionToggleLoading implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class SourceCredibilityDeletedFromAdminTrash implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class SourceCredibilityDeletedFromTrash implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SourceCredibilityOnServerRestored implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceCredibilityRestored implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityRestored;

    constructor(public payload: { item: SourceCredibility }) {
    }
}

export class SourceCredibilityOnServerAdminRestored implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceCredibilityAdminRestored implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityAdminRestored;

    constructor(public payload: { item: SourceCredibility }) {
    }
}

export class SourceCredibilityTrashFlushed implements Action {
    readonly type = SourceCredibilityActionTypes.SourceCredibilityTrashFlushed;

    constructor() {
    }
}

export type SourceCredibilityActions = SourceCredibilityCreated
    | SourceCredibilityUpdated
    | SourceCredibilityDeleted
    | SourceCredibilityPageRequested
    | SourceCredibilityPageLoaded
    | SourceCredibilityPageCancelled
    | AllSourceCredibilityLoaded
    | AllSourceCredibilityRequested
    | SourceCredibilityOnServerCreated
    | SourceCredibilityPageToggleLoading
    | SourceCredibilityActionToggleLoading
    | SourceCredibilityDeletedFromAdminTrash
    | SourceCredibilityDeletedFromTrash
    | SourceCredibilityOnServerRestored
    | SourceCredibilityRestored
    | SourceCredibilityOnServerAdminRestored
    | SourceCredibilityAdminRestored
    | SourceCredibilityTrashFlushed;
