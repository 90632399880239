import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Update } from '@ngrx/entity';
import {
    CityModel, CityOnServerCreated,  CityUpdatedOnServer, CountryModel, CurrencyModel, selectAllCountries, selectAllCurrencies,
    selectLastCreatedCityId
} from '../../../../../../core/admin';
import { of, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '../../../../../../core/reducers';
import { delay, takeUntil } from 'rxjs/operators';
import { CountryData } from '../../countries-list/local-data';
import { TranslateService } from '@ngx-translate/core';
import { CountryEditComponent } from '../../country-edit/country-edit.component';

@Component({
    selector: 'kt-city-edit',
    templateUrl: './city-edit.component.html',
})
export class CityEditComponent implements OnInit, OnDestroy {

    public placeOptions: BehaviorSubject<[]>;

    @ViewChild('addressInputAutocomplete') addressInput: ElementRef;

    city: CityModel;
    currencies: CurrencyModel[];
    countries: CountryModel[];
    countryCode: string;

    cityForm: UntypedFormGroup;
    hasFormErrors = false;

    viewLoading = false;
    loadingAfterSubmit = false;
    private _onDestroy$ = new Subject<void>()
    private componentSubscriptions: Subscription;


    constructor(private fb: UntypedFormBuilder,
                public dialogRef: MatDialogRef<CityEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>,
                private translate: TranslateService,
                private dialog: MatDialog) {
        this.placeOptions = new BehaviorSubject([]);
        this.countryCode = '';
    }

    ngOnInit() {

        const city = this.data.city;
        if (city) {
            this.city = Object.assign({}, city);
            this.createForm();
        } else {
            this.city = new CityModel();
            this.city.clear();
            this.createForm();
        }

        this.store.pipe(select(selectAllCurrencies)).subscribe(res => {
            this.currencies = res;
        });

        this.store.pipe(select(selectAllCountries)).subscribe(res => {
            this.countries = res;
        });

        setTimeout(() => {
            this.placeOptions.subscribe(data => console.log('UPDATING', data))
        }, 2000);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }

        this._onDestroy$.next()
        this._onDestroy$.complete()
    }

    createForm() {
        this.cityForm = this.fb.group({
            country_id: [{value: this.city.country_id, disabled: this.city.country_id && this.city.country_id > 0}, Validators.required],
            name: [this.city.name, Validators.required],
        });

        this.cityForm.controls.country_id.valueChanges.subscribe(value => {
            if (value) {
                const _country = this.countries.filter(item => item.id == value)[0];
                this.countryCode = CountryData.countries.filter(item => item.countryName == _country.name)[0].countryCode;
            }
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.city && this.city.id > 0) {
            return this.translate.instant('CITY.FORM.TITLE.EDIT', {
                city_name: this.city.name,
                country_name: this.city.country_name
            });
        }

        if (this.city.country_name.length > 0) {
            return this.translate.instant('CITY.FORM.TITLE.NEW_OF', {
                country_name: this.city.country_name
            });
        }

        return this.translate.instant('CITY.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.cityForm.controls[controlName];
        return control.invalid && control.touched;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.cityForm.controls;
        /** check form */
        if (this.cityForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.city.id > 0) {
            this.updateCity(this.city);
        } else {
            this.createCity(this.city);
        }
    }

    /**
     * Update city
     *
     * @param _city: CityModel
     */
    updateCity(_city: CityModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        /* Server loading imitation. Remove this on real code */
        const updateCity: Update<CityModel> = {
            id: this.city.id,
            changes: _city
        };
        this.store.dispatch(new CityUpdatedOnServer({
            city: _city
        }));
        of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line
            this.viewLoading = false;
            this.dialogRef.close({
                city: _city,
                isEdit: true
            });
        }); // Remove this line

    }

    /**
     * Create city
     *
     * @param _city: CityModel
     */
    createCity(_city: CityModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        this.store.dispatch(new CityOnServerCreated({city: _city}));

        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedCityId)
        ).subscribe(res => {
            if (!res) {
                return;
            }

            this.viewLoading = false;
            this.dialogRef.close({
                _city,
                isEdit: false,
                id: res
            });
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.city && this.city.name && this.city.name.length > 0) &&
            (this.city && this.city.country_id && this.city.country_id > 0)
    }

    public search() {
        let options = {
            input: this.city.name,
            types: ['(cities)'],
            componentRestrictions: {country: ''}
        }
        if (this.countryCode.length > 0) {
            options = {
                input: this.city.name,
                types: ['(cities)'],
                componentRestrictions: {country: this.countryCode + ''}
            }
        }
        if (this.city.name.length) {
            // this.autocompleteService.getPlacePredictions(options, (results) => {
                // this.placeOptions.next(results);
            // });
        } else {
            this.placeOptions.next([]);
        }
    }

    public placeSelected(place) {
        this.cityForm.controls.name.patchValue(place.option.value);
    }

    changeCountry($event) {
        this.cityForm.controls.name.patchValue('');
    }

    addCountry() {
        const dialogRef = this.dialog.open(CountryEditComponent, {data: {id: undefined}})
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
            if (!res) {
                return
            }
            this.cityForm.controls.country_id.setValue(res.id)
            this.cityForm.controls.country_id.updateValueAndValidity()
        })
    }

}
