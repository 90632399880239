// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CurrencyActionTypes, CurrencyActions} from '../_actions/currency.actions';
// Models
import {CurrencyModel} from '../_models/currency.model';

export interface CurrenciesState extends EntityState<CurrencyModel> {
    _isAllCurrenciesLoaded: boolean;
    listLoading: boolean;
    actionLoading: boolean;
    lastCreatedCurrencyId: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CurrencyModel> = createEntityAdapter<CurrencyModel>();

export const initialCurrenciesState: CurrenciesState = adapter.getInitialState({
    _isAllCurrenciesLoaded: false,
    listLoading: false,
    actionLoading: false,
    lastCreatedCurrencyId: undefined,

    totalTrashed: 0,
    totalAdminTrashed: 0,

    showInitWaitingMessage: true
});

export function currenciesReducer(state = initialCurrenciesState, action: CurrencyActions): CurrenciesState {
    switch (action.type) {
        case CurrencyActionTypes.AllCurrenciesRequested:
            return {
                ...state,
                _isAllCurrenciesLoaded: false
            };
        case CurrencyActionTypes.AllCurrenciesLoaded:
            return adapter.addAll(action.payload.currencies, {
                ...state,
                _isAllCurrenciesLoaded: true,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
            });
        case CurrencyActionTypes.CurrencyDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case CurrencyActionTypes.CurrencyOnServerCreated:
            return {
                ...state
            };
        case CurrencyActionTypes.CurrencyCreated:
            return adapter.addOne(action.payload.currency, {
                ...state, lastCreatedCurrencyId: action.payload.currency.id
            });
        case CurrencyActionTypes.CurrencyUpdated:
            return adapter.updateOne(action.payload.partialCurrency, state);

        case CurrencyActionTypes.CurrencyRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case CurrencyActionTypes.CurrencyDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case CurrencyActionTypes.CurrencyAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case CurrencyActionTypes.CurrencyDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case CurrencyActionTypes.CurrencyTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
