// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {MethodsToValuesService} from '../_services/methods-to-value.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allMethodsToValuesLoaded} from '../_selectors/methods-to-value.selectors';
// Actions
import {
    AllMethodsToValuesLoaded,
    AllMethodsToValuesRequested,
    MethodsToValueActionTypes,
    MethodsToValuesPageRequested,
    MethodsToValuesPageLoaded,
    MethodsToValueUpdated,
    MethodsToValuesPageToggleLoading,
    MethodsToValueDeleted,
    MethodsToValueOnServerCreated,
    MethodsToValueCreated,
    MethodsToValuesActionToggleLoading, MethodsToValueDeletedFromAdminTrash, MethodsToValueDeletedFromTrash,
    MethodsToValueOnServerAdminRestored, MethodsToValueAdminRestored, MethodsToValueRestored, MethodsToValueOnServerRestored,
    MethodsToValueTrashFlushed
} from '../_actions/methods-to-value.actions';

@Injectable()
export class MethodsToValueEffects {
    showPageLoadingDispatcher = new MethodsToValuesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new MethodsToValuesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new MethodsToValuesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllMethodsToValues$ = this.actions$
        .pipe(
            ofType<AllMethodsToValuesRequested>(MethodsToValueActionTypes.AllMethodsToValuesRequested),
            withLatestFrom(this.store.pipe(select(allMethodsToValuesLoaded))),
            filter(([action, isAllMethodsToValuesLoaded]) => !isAllMethodsToValuesLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllMethodsToValuesLoaded({methodsToValues: res.data});
            })
        );

    @Effect()
    loadMethodsToValuesPage$ = this.actions$
        .pipe(
            ofType<MethodsToValuesPageRequested>(MethodsToValueActionTypes.MethodsToValuesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new MethodsToValuesPageLoaded({
                    methodsToValues: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueUpdated>(MethodsToValueActionTypes.MethodsToValueUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.methodsToValue);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueOnServerCreated>(MethodsToValueActionTypes.MethodsToValueOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.methodsToValue).pipe(
                    tap(res => {
                        this.store.dispatch(new MethodsToValueCreated({methodsToValue: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushMethodsToValueTrash$ = this.actions$
        .pipe(
            ofType<MethodsToValueTrashFlushed>(MethodsToValueActionTypes.MethodsToValueTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueOnServerRestored>(MethodsToValueActionTypes.MethodsToValueOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new MethodsToValueRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueOnServerAdminRestored>(MethodsToValueActionTypes.MethodsToValueOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new MethodsToValueAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueDeleted>(MethodsToValueActionTypes.MethodsToValueDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueDeletedFromTrash>(MethodsToValueActionTypes.MethodsToValueDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashMethodsToValue$ = this.actions$
        .pipe(
            ofType<MethodsToValueDeletedFromAdminTrash>(MethodsToValueActionTypes.MethodsToValueDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: MethodsToValuesService, private store: Store<AppState>) {
    }
}
