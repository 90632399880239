import {QueryParamsModel} from '../../_base/crud';
import {combineLatest, forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap, take} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {ToeService} from '../_services/toe.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    ToeActionTypes,
    ToesPageRequested,
    ToesPageLoaded,
    ManyToesDeleted,
    OneToeDeleted,
    OneToeRequest,
    OneToeLoaded,
    ToeActionToggleLoading,
    ToesPageToggleLoading,
    ToeUpdated,
    ToeStatusUpdated,
    ToeCreated,
    ToeOnServerCreated,
    ToeRestored,
    ToeOnServerRestored,
    ToeDeletedFromTrash,
    ToeDeletedFromAdminTrash,
    ToeTrashFlushed,
    ToeOnServerAdminRestored,
    ToeAdminRestored,
    AllToesByStatusRequested,
    AllToesByStatusLoaded,
    ToeOnServerUpdated,
    ChangeToeStatusFromDone,
    ToeEmptyAction,
    ChangeToeStatusFromOngoing,
    RevertToe,
    ToeReverted,
} from '../_actions/toe.actions';
import {of} from 'rxjs';
import { ToeReportService } from '../_services/toe-report.service';
import { GenerateToeReport } from '../_actions/toe-report.actions';
import { GenerateInformedConsentReport } from '../_actions/informed-consent-report.actions';
import { selectToeById } from '../_selectors/toe.selectors';
import { ToeModel } from '..';
import { Update } from '@ngrx/entity';

@Injectable()
export class ToeEffects {
    showPageLoadingDispatcher = new ToesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ToeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ToeActionToggleLoading({isLoading: false});

    @Effect()
    loadToesPage$ = this.actions$.pipe(
        ofType<ToesPageRequested>(ToeActionTypes.ToesPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.findToes(payload);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {

            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new ToesPageLoaded({
                toes: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    loadToeByStatu$ = this.actions$
        .pipe(
            ofType<AllToesByStatusRequested>(ToeActionTypes.AllToesByStatusRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.getToesByStatus(payload);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AllToesByStatusLoaded({
                    toes: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery
                });
            })
        );

    @Effect()
    updateToe$ = this.actions$
        .pipe(
            ofType<ToeUpdated>(ToeActionTypes.ToeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateToe(payload);
            }),
            tap((res) =>
            { 
                if (res.toe.status != 0) {
                    this.store.dispatch(new GenerateToeReport({toeID: res.toe.id, userID: res.created_user_id, toeStatus: res.toe.status, reportID: res.toe_report_id}));
                    if (res.informed_consent_report_id) {
                        this.store.dispatch(new GenerateInformedConsentReport({toeID: res.toe.id, reportID: res.informed_consent_report_id}));
                    }
                }
            }),
            map(() => {
                this.store.dispatch(this.hideActionLoadingDispatcher);
                return new ToeOnServerUpdated();
            })
        );

    @Effect()
    updateToeStatus$ = this.actions$
        .pipe(
            ofType<ToeStatusUpdated>(ToeActionTypes.ToeStatusUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateToeStatus({
                    toe_id: payload.toe.changes.id,
                    status: payload.toe.changes.status
                });
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            })
        );

    @Effect()
    revertToe$ = this.actions$
        .pipe(
            ofType<RevertToe>(ToeActionTypes.RevertToe),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateToeStatus({
                    toe_id: payload.update.changes.id,
                    status: payload.update.changes.status
                });
            }),
            map((res) => {
                this.store.dispatch(this.hideActionLoadingDispatcher);
                return new ToeReverted();
            })
        );

    @Effect()
    createToe$ = this.actions$
        .pipe(
            ofType<ToeOnServerCreated>(ToeActionTypes.ToeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createToe(payload);
            }),
            tap((res) => {
                if (res.toe.status != 0) {
                    this.store.dispatch(new GenerateToeReport({toeID: res.toe.id, userID: res.created_user_id, toeStatus: res.toe.status, reportID: res.toe_report_id}));
                    if (res.informed_consent_report_id) {
                        this.store.dispatch(new GenerateInformedConsentReport({toeID: res.toe.id, reportID: res.informed_consent_report_id}));
                    }
                }
            }),
            map(({toe}) => {
                this.store.dispatch(new ToeCreated({toe: toe}));
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    loadToe$ = this.actions$.pipe(
        ofType<OneToeRequest>(ToeActionTypes.OneToeRequest),
        // withLatestFrom(this.store.pipe(select(selectAssignmentById(payload)))),
        // filter(([action, _isUserLoaded]) => !_isUserLoaded),
        mergeMap(({payload}) => {

            return this.service.getToeById(payload.id);
        }),
        map(response => {
            return new OneToeLoaded({
                toe: response.data
            });
        })
    );


    @Effect()
    deleteToe$ = this.actions$
        .pipe(
            ofType<OneToeDeleted>(ToeActionTypes.OneToeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteToe(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreToe$ = this.actions$
        .pipe(
            ofType<ToeOnServerRestored>(ToeActionTypes.ToeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.toeId).pipe(
                    tap(res => {
                        this.store.dispatch(new ToeRestored({toe: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminToe$ = this.actions$
        .pipe(
            ofType<ToeOnServerAdminRestored>(ToeActionTypes.ToeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ToeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteToeFromTrash$ = this.actions$
        .pipe(
            ofType<ToeDeletedFromTrash>(ToeActionTypes.ToeDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteToeFromAdminTrash$ = this.actions$
        .pipe(
            ofType<ToeDeletedFromAdminTrash>(ToeActionTypes.ToeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushToeTrash$ = this.actions$
        .pipe(
            ofType<ToeTrashFlushed>(ToeActionTypes.ToeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    changeToeStatusFromDone$ = this.actions$
        .pipe(
            ofType<ChangeToeStatusFromDone>(ToeActionTypes.ChangeToeStatusFromDone),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.store.select(selectToeById(payload.toeID)).pipe(take(1)),
                    of(payload.toeID)
                ]);
            }),
            mergeMap(([toe, id]) => {
                if (toe) {
                    return of(toe);
                }
                return this.service.getToeById(id).pipe(map(res => res.data));
            }),
            map(toe => {
                if (toe.status == 2 || toe.status == 1) {
                    return new ToeEmptyAction;
                }
                const newToe: Update<ToeModel> = {
                    id: toe.id,
                    changes: {
                        id: toe.id,
                        status: 2
                    }
                }
                return new ToeStatusUpdated({toe: newToe});
            })
        )

    @Effect()
    changeToeStatusFromOngoind$ = this.actions$
        .pipe(
            ofType<ChangeToeStatusFromOngoing>(ToeActionTypes.ChangeToeStatusFromOngoing),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.store.select(selectToeById(payload.toeID)).pipe(take(1)),
                    of(payload.toeID)
                ]);
            }),
            mergeMap(([toe, id]) => {
                if (toe) {
                    return of(toe);
                }
                return this.service.getToeById(id).pipe(map(res => res.data));
            }),
            map(toe => {
                if (toe.status == 3 || toe.status == 4) {
                    return new ToeEmptyAction();
                }
                const newToe: Update<ToeModel> = {
                    id: toe.id,
                    changes: {
                        id: toe.id,
                        status: 3
                    }
                }
                return new ToeStatusUpdated({toe: newToe});
            })
        )


    constructor(private actions$: Actions, private service: ToeService, private store: Store<AppState>) {
    }
}
