import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllSpecialAssumptionsRequested, selectAllSpecialAssumptions, SpecialAssumption } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ITpTemplateSelectedSpecialAssumption } from 'src/app/core/template/_models/tp-template.model';
import { AddAdModalComponent } from '../assumptions-and-departures/add-ad-modal/add-ad-modal.component';

type ItemData = SpecialAssumption & {type: number}

@Component({
  selector: 'kt-special-assumptions-selection',
  templateUrl: './special-assumptions-selection.component.html',
  styleUrls: ['./special-assumptions-selection.component.scss']
})
export class SpecialAssumptionsSelectionComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() selectedSpecialAssumptions: ITpTemplateSelectedSpecialAssumption[];
  @Input() customSpecialAssumptions: SpecialAssumption[];
  specialAssumptionsData: ItemData[] = [];
  _customSpecialAssumptions: SpecialAssumption[] = [];

  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._customSpecialAssumptions = this.customSpecialAssumptions.map(el => Object.assign({}, el));
    this.store.dispatch(new AllSpecialAssumptionsRequested());
    this.store.select(selectAllSpecialAssumptions).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.specialAssumptionsData = [];
      if (res) {
        let specialAssumptions: ItemData[] = res.map(item => {
          let selected = this.selectedSpecialAssumptions.filter(el => el.type == 0).some(el => el.assumption_id == item.id);
          const temp = Object.assign({}, item);
          temp.selected = selected;
          return {
            ...temp,
            type: 0
          } as ItemData
        });
        let customAssumptions = this.customSpecialAssumptions.map(item => {
          let selected = this.selectedSpecialAssumptions.filter(el => el.type == 1).some(el => el.assumption_id == item.id);
          const temp = Object.assign({}, item);
          temp.selected = selected;
          return {
            ...temp,
            type: 0
          } as ItemData
        })
        this.specialAssumptionsData = specialAssumptions.concat(customAssumptions);
      }
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addItem() {
    const dialogRef = this.dialog.open(AddAdModalComponent, {
      data: {
        itemType: 'special-assumption',
        title: 'Add Special Assumption',
        placeholder: 'Special Assumption'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const assumption = new SpecialAssumption();
      assumption.clear();
      assumption.name = res.text;
      assumption.selected = true;
      this._customSpecialAssumptions.push(assumption);
      const _tmpArray = Object.assign([], this.specialAssumptionsData);
      const newAssumption = Object.assign({}, assumption) as ItemData;
      newAssumption.type = 1;
      _tmpArray.push(newAssumption);
      this.specialAssumptionsData = _tmpArray;
    })
  }

  changeSelection(event: MatCheckboxChange, index: number) {
    if (event.checked) {
      this._selectingNone(index);
    }
    const _tmpArray = Object.assign([], this.specialAssumptionsData);
    _tmpArray[index].selected = event.checked;
    this.specialAssumptionsData = _tmpArray;
  }

  private _selectingNone(index) {
    if (index == 0) {
      this.specialAssumptionsData = this.specialAssumptionsData.map(el => {
        el.selected = false;
        return el;
      })
    } else {
      const _tmpArray = Object.assign([], this.specialAssumptionsData);
      _tmpArray[0].selected = false;
      this.specialAssumptionsData = _tmpArray;
    }
  }

  public getData(): {
    selected: ITpTemplateSelectedSpecialAssumption[],
    customAssumptions: SpecialAssumption[] 
  } {
    const selected = this.specialAssumptionsData.filter(el => el.selected).map(el => ({
      assumption_id: el.id,
      type: el.type,
      assumption_name: el.name
    }))
    return {
      selected,
      customAssumptions: this._customSpecialAssumptions
    }
  }

}
