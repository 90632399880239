import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { pick } from "lodash";
import { awConst } from "src/app/app.constants";
import { AssetClassDetailModel, Link } from "src/app/core/asset_class";
import { Video } from "src/app/core/file-upload/_models/video.model";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoPlayerDialogComponent } from "../video-player-dialog/video-player-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { V } from "@angular/cdk/keycodes";
import { ReportingProcessData } from "src/app/core/reporting/_models/reporting_process.model";
import { VideoViewerDialogComponent } from "src/app/views/pages/shared_components/upload-video/video-viewer-dialog/video-viewer-dialog.component";
import { ReportDesignModel } from "../../../report-design-page/report-design.model";

@Component({
    selector: "kt-video-selector",
    templateUrl: "./video-selector.component.html",
    styleUrls: ["./video-selector.component.scss"],
})
export class VideoSelectorComponent implements OnInit {
    @Input()
    readonly: boolean;
    @Input()
    targetPropertyDetail: AssetClassDetailModel;
    @Input()
    reporting: ReportDesignModel;
    @Input()
    type: string;

    @Output()
    videosWithSelectedValue = new EventEmitter<VidnMatterModel[]>();

    public videos: Video[] = [];
    public links: Link[] = [];
    selectedIndexs: number[] = [];

    public vidMatterList: VidnMatterModel[] = [];

    constructor(
        private dialog: MatDialog,
        private changeDetection: ChangeDetectorRef,
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        if (this.type == "video") {
            this.targetPropertyDetail.videos.forEach((vid) => {
                var aModel = {
                    id: vid.id,
                    title: vid.title,
                    description: vid.description,
                    // url: this._sanitizer.bypassSecurityTrustResourceUrl(
                    //     vid.video_player_url
                    // ),
                    url: vid.video_player_url,
                    selection_status: 0,
                    thumbnail_url: vid.pic_url,
                    type: "video",
                    is_youtube_url: true,
                };
                this.vidMatterList.push(aModel);
                // this.videos.push(vid);
            });
        }

        if (this.type != "video") {
            this.targetPropertyDetail.links.forEach((link) => {
                var lModel = {
                    id: link.id,
                    title: link.title,
                    description: link.description,
                    is_youtube_url: link.url.includes("youtube.com"),
                    url: link.url.includes("youtube.com")
                        ? this.youtubeEmbedVideoId(link.url)
                        : link.url,
                    selection_status: 0,
                    thumbnail_url: "",
                    type: "link",
                };
                this.vidMatterList.push(lModel);
                // this.links.push(link);
            });
        }

        this.customInit();
        this.videosWithSelectedValue.emit(this.vidMatterList);
    }

    customInit() {
        const BreakError = {};
        if (this.reporting.videos.length > 0) {
            this.reporting.videos.map((pPic) => {
                try {
                    this.vidMatterList.forEach((pic, index) => {
                        if (pic.id == pPic.vid_id) {
                            this.vidMatterList[index].selection_status =
                                pPic.selection;
                            throw BreakError;
                        }
                    });
                } catch (err) {}
            });
        }
        if (this.reporting.matterport_contents.length > 0) {
            this.reporting.matterport_contents.map((pPic) => {
                try {
                    this.vidMatterList.forEach((pic, index) => {
                        if (pic.id == pPic.link_id) {
                            this.vidMatterList[index].selection_status =
                                pPic.selection;
                            throw BreakError;
                        }
                    });
                } catch (err) {}
            });
        }
    }

    radioGroupChange(data: MatRadioChange, index: number) {
        this.vidMatterList[index].selection_status = parseInt(data.value);
        this.videosWithSelectedValue.emit(this.vidMatterList);
    }

    isSelected(index: number) {
        return this.vidMatterList[index].selection_status == 1;
    }

    public videoIsSelected(index: number) {
        if (this.isSelected(index)) {
            return "picture-container1";
        } else {
            return "picture-container";
        }
    }

    youtubeEmbedVideoId(url: string) {
        var splitted = url.split("watch?v=");
        var embb = "https://www.youtube.com/embed/".concat(splitted[1]);
        return this._sanitizer.bypassSecurityTrustResourceUrl(embb);
    }

    clickOnButton(
        title: string,
        description: string,
        url: any,
        is_youtube_url: boolean
    ) {
        this.dialog.open(VideoViewerDialogComponent, {
            data: {
                title: title,
                src: url,
                description: description
            },
            width: '800px'
        })
    }

    // public playVideoDialog(title: string, description: string, url: string) {

    // }

    //to stop previewing picture
    public clickOnRadio(e) {
        e.stopPropagation();
    }

    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND;
    }

    public getSelectedVideos() {
        return this.videos;
    }

    public getSelectedLinks() {
        return this.links;
    }
}

export interface VidnMatterModel {
    id: number;
    title?: string;
    description?: string;
    url: any;
    selection_status?: number;
    thumbnail_url?: string;
    type: string;
    is_youtube_url: boolean;
}
