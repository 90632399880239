<!-- <div class="card card-custom"> -->
    <!-- <div class="card-body"> -->
        <form [formGroup]="form" *ngIf="form" [ngClass]="{'readonly': readonly}">
            <div class="row mb-2">
                <div class="col-lg-12">
                    <h5 class="my-3 text-mad text-uppercase">Target Property
                        <button mat-icon-button container="body"
                            [popoverTitle]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.TITLE'|translate"
                            [ngbPopover]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.DESCRIPTION'|translate">
                            <mat-icon>
                                help
                            </mat-icon>
                        </button>
                    </h5>
                    <hr class="active">
                </div>
            </div>

            <div class="row mb-20">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10">1 - Picture location</h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-radio-group formControlName="picture_location">
                                <mat-radio-button [value]="0">Include pictures in the report section
                                </mat-radio-button>
                                <mat-radio-button [value]="1">Include pictures in the appendices section
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-20">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10">2 - Report's cover
                        <button mat-icon-button container="body"
                            [popoverTitle]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.TITLE'|translate"
                            [ngbPopover]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.DESCRIPTION'|translate">
                            <mat-icon>
                                help
                            </mat-icon>
                        </button>
                    </h6>
                    <div clas="row">
                        <div class="col-lg-12">
                            <mat-radio-group formControlName="report_cover">
                                <mat-radio-button [value]="0">Default (Agency Settings)</mat-radio-button>
                                <mat-radio-button [value]="1">Featured property picture</mat-radio-button>
                                <mat-radio-button *ngIf="hasSelectBuildingSection" [value]="2">Featured building picture</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-20">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10">3 - Select property pictures</h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="customRadios">
                                <mat-radio-group>
                                    <mat-radio-button class="redRadio" checked="true" *ngIf="form.controls.report_cover.value == 1">
                                        <b>Left
                                            checkbox</b> - Featured
                                        picture
                                    </mat-radio-button>
                                    <!-- </mat-radio-group>

                                <mat-radio-group> -->
                                    <mat-radio-button class="greyRadio"><b>Right
                                            checkbox</b> -
                                        pictures to be included
                                        in report
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <kt-picture-selector-v2 [readonly]="readonly" [picType]="picTypeProperty"
                                [targetPropertyDetail]="targetProperty.details" [reporting]="reporting"
                                (picsWithSelectedValue)="propertyPics($event)"
                                [selectedPics]="selectedTpPicsAsIPicture"
                                [displayFeatured$]="displayPropertyFeatured$"></kt-picture-selector-v2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-20" *ngIf="hasSelectBuildingSection">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10">4 -Select building pictures</h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="customRadios">
                                <mat-radio-group>
                                    <mat-radio-button class="redRadio" checked="true" *ngIf="form.controls.report_cover.value == 2">
                                        <b>Left
                                            checkbox</b> - Featured
                                        picture
                                    </mat-radio-button>
                                    <!-- </mat-radio-group>

                                <mat-radio-group> -->
                                    <mat-radio-button class="greyRadio"><b>Right
                                            checkbox</b> -
                                        pictures to be included
                                        in report
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <kt-picture-selector-v2 [readonly]="readonly" (picsWithSelectedValue)="buildingPics($event)"
                                [targetPropertyDetail]="targetProperty.details" [picType]="picTypeBuilding"
                                [reporting]="reporting"
                                [selectedPics]="selectedTpPicsAsIPicture"
                                [displayFeatured$]="displayBuildingFeatured$"></kt-picture-selector-v2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-20">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10 text-uppercase">Comparables
                        <button mat-icon-button container="body"
                            [popoverTitle]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.TITLE'|translate"
                            [ngbPopover]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.DESCRIPTION'|translate">
                            <mat-icon>
                                help
                            </mat-icon>
                        </button>
                    </h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-radio-group formControlName="comparable_pictures">
                                <mat-radio-button [value]="0">No pictures</mat-radio-button>
                                <mat-radio-button [value]="1">Featured property & building</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-20" *ngIf="targetProperty.details.videos.length > 0">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10 text-uppercase">Property Videos
                        <button mat-icon-button container="body"
                            [popoverTitle]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.TITLE'|translate"
                            [ngbPopover]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.DESCRIPTION'|translate">
                            <mat-icon>
                                help
                            </mat-icon>
                        </button>
                    </h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="customRadios">
                                <mat-radio-group>

                                    <!-- </mat-radio-group>

                                <mat-radio-group> -->
                                    <mat-radio-button class="greyRadio"><b>Right
                                            checkbox</b> -
                                        Videos to be included in Online Repository
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <kt-video-selector [readonly]="readonly" type="video"
                                (videosWithSelectedValue)="getVideos($event)" [reporting]="reporting"
                                [targetPropertyDetail]="targetProperty.details"></kt-video-selector>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-20" *ngIf="targetProperty.details.links.length > 0">
                <div class="col-lg-12">
                    <h6 class="text-mad mb-10 text-uppercase">Matterport Contents
                        <button mat-icon-button container="body"
                            [popoverTitle]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.TITLE'|translate"
                            [ngbPopover]="'TOOLTIP.REPORTING_PROCESS.TARGET_PROPERTY_PICTURES.DESCRIPTION'|translate">
                            <mat-icon>
                                help
                            </mat-icon>
                        </button>
                    </h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="customRadios">
                                <mat-radio-group>

                                    <!-- </mat-radio-group>

                                <mat-radio-group> -->
                                    <mat-radio-button class="greyRadio"><b>Right
                                            checkbox</b> -
                                        Matterport content to be included in Online Repository
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <kt-video-selector [readonly]="readonly" type="matterport"
                                (videosWithSelectedValue)="getMatterLinks($event)" [reporting]="reporting"
                                [targetPropertyDetail]="targetProperty.details"></kt-video-selector>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    <!-- </div> -->
<!-- </div> -->