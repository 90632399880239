import {BaseModel} from '../../_base/crud';

export class PaymentTermModel extends BaseModel {
    id: number;
    terms_of_engagement_id: number;
    title: string;
    percent: number;
    amount: number;
    payment_date: any;
    order: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;
        this.terms_of_engagement_id = undefined;
        this.title = '';
        this.percent = 0;
        this.amount = 0;
        this.payment_date = null;
        this.order = 0;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
