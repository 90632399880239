export class Video {
    id: number;
    title: string;
    vimeo_id: number;
    video_player_url: string;
    pic_url: string;
    description: string;

    constructor(vimeo_id: number, video_player_url: string, pic_url: string) {
        this.id = undefined;
        this.title = null;
        this.description = null;
        this.vimeo_id = vimeo_id;
        this.video_player_url = video_player_url;
        this.pic_url = pic_url;
    }
}