import {ValuerQualification} from '../_models/valuer-qualification.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ValuerQualificationsState} from '../_reducers/valuer-qualification.reducers';
import * as fromValuerQualification from '../_reducers/valuer-qualification.reducers';
import {each} from 'lodash';

export const selectValuerQualificationsState = createFeatureSelector<ValuerQualificationsState>('valuerQualifications');

export const selectValuerQualificationById = (valuerQualificationId: number) => createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => valuerQualificationsState.entities[valuerQualificationId]
);

export const selectAllValuerQualifications = createSelector(
    selectValuerQualificationsState,
    fromValuerQualification.selectAll
);

export const selectAllValuerQualificationsIds = createSelector(
    selectValuerQualificationsState,
    fromValuerQualification.selectIds
);

export const allValuerQualificationsLoaded = createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => valuerQualificationsState.isAllValuerQualificationsLoaded
);


export const selectValuerQualificationsPageLoading = createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => valuerQualificationsState.listLoading
);

export const selectValuerQualificationsActionLoading = createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => valuerQualificationsState.actionLoading
);

export const selectLastCreatedValuerQualificationId = createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => valuerQualificationsState.lastCreatedValuerQualificationId
);

export const selectValuerQualificationsShowInitWaitingMessage = createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => valuerQualificationsState.showInitWaitingMessage
);


export const selectTrashedValuerQualificationCount = createSelector(
    selectValuerQualificationsState,
    (valuerQualificationsState) => valuerQualificationsState.totalTrashed
);

export const selectAdminTrashedValuerQualificationCount = createSelector(
    selectValuerQualificationsState,
    (valuerQualificationsState) => valuerQualificationsState.totalAdminTrashed
);


export const selectValuerQualificationQueryResult = createSelector(
    selectValuerQualificationsState,
    valuerQualificationsState => {
        const items: ValuerQualification[] = [];
        each(valuerQualificationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ValuerQualification[] = httpExtension.sortArray(items, valuerQualificationsState.lastQuery.sortField, valuerQualificationsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, valuerQualificationsState.totalCount, '', valuerQualificationsState.totalTrashed);
    }
);
