import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged, skip} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {LayoutUtilsService, TypesUtilsService} from '../../../../../core/_base/crud';
import {AppState} from '../../../../../core/reducers';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {FileUploadService} from '../../../../../core/file-upload/_services';
import {TranslateService} from '@ngx-translate/core';
import {PaymentTaxModel} from '../../../../../core/asset_class';
import { AddAdditionalCostDialogComponent } from '../add-additional-cost-dialog/add-additional-cost-dialog.component';

@Component({
    selector: 'kt-ac-additional-cost-alt',
    templateUrl: './ac-additional-cost.component.html',
    styleUrls: ['./ac-additional-cost.component.scss'],
})
export class AcAdditionalCostComponent implements OnInit, OnDestroy {

    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() paymentTaxesSubject: BehaviorSubject<PaymentTaxModel[]>;
    @Input() currencySubject: BehaviorSubject<string>;
    @Input() targetProperties: any[] = [];
    @Input() readonly: boolean;
    @Input() toeStatus: number;

    displayedColumns = ['name', 'asset_class_id', 'qty', 'amount', 'total', 'actions'];
    dataSource = new MatTableDataSource();

    sizes: PaymentTaxModel[];

    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param fileUploadService: FileUploadService
     * @param dialog: MatDialog
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService) {
        // this.saveState = false;
    }

    ngOnInit() {
        this.targetProperties = [{id: 0, name: 'General'}].concat(this.targetProperties);
        this.dataSource = new MatTableDataSource(this.paymentTaxesSubject.value);

        const paymentTaxesSubjectSubscription = this.paymentTaxesSubject.asObservable()
            .pipe(
                distinctUntilChanged(),
                skip(1))
            .subscribe(res => {
                this.dataSource.data = Object.assign([], res);
            });
        this.subscriptions.push(paymentTaxesSubjectSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    addItem() {
        const dialogRef = this.dialog.open(AddAdditionalCostDialogComponent, {
            data: {
                targetProperties: this.targetProperties,
            },
            // width:'440px',
            // position:{
            //     top:'50px',
            //     right:'50px',
            // }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _data = Object.assign([], this.paymentTaxesSubject.value);
            const temp: PaymentTaxModel = Object.assign({}, res.size);
            _data.push(temp);

            this.paymentTaxesSubject.next(_data);
        });
    }

    editItem(_item: PaymentTaxModel, _index: number) {
        const dialogRef = this.dialog.open(AddAdditionalCostDialogComponent, {
            data: {
                acPaymentTax: _item,
                targetProperties: this.targetProperties,
            }
        });
        let _data = this.paymentTaxesSubject.value;
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const temp: PaymentTaxModel = Object.assign({}, res.size);
            temp._isEditMode = true;
            _data = Object.assign([], _data, {[_index]: temp});
            this.paymentTaxesSubject.next(_data);
        });
    }

    deleteItem(_item: PaymentTaxModel) {
        const _data = this.paymentTaxesSubject.value.filter(obj => obj !== _item);
        this.paymentTaxesSubject.next(_data);
    }

    getTP(name): any {
        if (!name || !this.targetProperties.find(el => el.name == name)) {
            return 'General';
        }
        return this.targetProperties.find(el => el.name == name);
    }

    change(event, index, fieldName) {
        if (event.target.value) {
            let _data = this.paymentTaxesSubject.value;
            const temp: PaymentTaxModel = Object.assign({}, _data[index]);
            if (fieldName == 'amount') {
                const currencyRemoved = event.target.value.replace(this.currencySubject.value, '');
                temp.amount = parseFloat(currencyRemoved.replace(/,/g, ''));
            }
            if (fieldName == 'qty') {
                temp.qty = Number(event.target.value);
            }
            if (fieldName == 'name') {
                temp.name = event.target.value;
            }
            _data = Object.assign([], _data, {[index]: temp});
            this.paymentTaxesSubject.next(_data);
        }
    }

    onOptionsSelected(event, index, fieldName) {
        let _data = this.paymentTaxesSubject.value;
        const temp: PaymentTaxModel = Object.assign({}, _data[index]);
        temp.asset_class_name = this.getTP(event.value).name;
        temp.asset_class_id =  this.getTP(event.value).id;
        _data = Object.assign([], _data, {[index]: temp});
        this.paymentTaxesSubject.next(_data);
    }
}
