import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MadAuthService } from '../mad-auth/mad-auth.service';
import { Task, BaseTask, mapToExternalRefTask, mapToLogisticsTask, mapToLandmarksTask, mapToInspectionTask, mapToFinancialTask, TaskKey, mapToValuationTask } from './task.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SubdomainService } from '../_base/subdomain.service';


export type TaskApiResponse = {
  id: number
  ac_id: number
  key: TaskKey 
  assignee_id: number
  assignee: {
    id: number,
    first_name: string,
    last_name: string
  },
  point: number,
  expected_date: string,
  completed_date: string,
  meta: {
    inspection_type: string,
    valuation_id: number,
    method: string,
    tenure: string
  }
}

@Injectable()
export class TaskService {

  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}`
  constructor(
    private httpClient: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) { }

  getTasksByAssetClass(assetClassId: number): Observable<Task[]> {
    const headers = this.authService.getAuthHeaders()

    return this.httpClient.get<{data: TaskApiResponse[]}>(`${this.API_URL}/asset-classes/${assetClassId}/tasks`, {
      headers
    }).pipe(
      map(res => res.data),
      map(data => data.map(task => {
        const base: BaseTask = {
          id: task.id,
          ac_id: task.ac_id,
          assignee_id: task.assignee_id,
          assignee: {
            id: task.assignee.id,
            name: `${task.assignee.first_name} ${task.assignee.last_name}`
          },
          point: task.point,
          expected_date: task.expected_date,
          completed_date: task.completed_date
        }

        switch (task.key) {
          case 'external_refs': {
            return mapToExternalRefTask(base);
          }
          case 'logistics': {
            return mapToLogisticsTask(base);
          }
          case 'landmarks': {
            return mapToLandmarksTask(base);
          }
          case 'inspection': {
            return mapToInspectionTask(base, {inspection_type: task.meta.inspection_type});
          }
          case 'financial': {
            return mapToFinancialTask(base);
          }
          case 'valuation': {
            const valuationTasks = data.filter(item => item.key === 'valuation')
            const index = valuationTasks.findIndex(item => item.meta.valuation_id == task.meta.valuation_id)
            return mapToValuationTask(base, index, {valuation_id: task.meta.valuation_id, method: task.meta.method, tenure: task.meta.tenure})
          }
        }
      }))
    )
  }

}
