// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AssignmentModel} from '../_models/assignment.model';

export enum AssignmentActionTypes {
    AssignmentOnServerCreated = '[Edit Assignment Dialog] Assignment On Server Created',
    AssignmentCreated = '[Edit Assignment Dialog] Assignment Created',

    AssignmentOnServerDuplicated = '[Duplicate Assignment] Assignment On Server Duplicated',
    AssignmentDuplicated = '[Duplicate Assignment] Assignment Duplicated',

    AssignmentOnServerRestored = '[Assignment Trash] Assignment On Server Restored',
    AssignmentRestored = '[Assignment Trash] Assignment Restored',

    AssignmentOnServerAdminRestored = '[Assignment Admin Trash] Assignment On Server Restored',
    AssignmentAdminRestored = '[Assignment Trash] Assignment Restored from Admin trash',

    AssignmentDeletedFromTrash = '[Assignment Trash] Assignment deleted',
    AssignmentDeletedFromAdminTrash = '[Assignment Admin Trash] Assignment deleted',

    AssignmentTrash = 'Assignment Trashed',
    AssignmentTrashFlushed = 'Assignment Trash Flushed',

    AssignmentUpdated = '[Edit Assignment Dialog] Assignment Updated',
    AssignmentsStatusUpdated = '[Assignment List Page] Assignments Status Updated',
    OneAssignmentDeleted = '[Assignments List Page] One Assignment Deleted',
    ManyAssignmentsDeleted = '[Assignments List Page] Many Assignment Deleted',

    AssignmentsPageRequested = '[Assignments List Page] Assignments Page Requested',
    AssignmentsPageLoaded = '[Assignments API] Assignments Page Loaded',

    OneAssignmentRequest = '[Assignments API] One Assignment Request',
    OneAssignmentLoaded = '[Assignments API] One Assignment Loaded',

    AssignmentsPageCancelled = '[Assignments API] Assignments Page Cancelled',
    AssignmentsPageToggleLoading = '[Assignments] Assignments Page Toggle Loading',
    AssignmentActionToggleLoading = '[Assignments] Assignments Action Toggle Loading',

    SetAssignmentStatusToValidated = '[ToE List Component] Set Assignment status to validated',
    SetAssignmentStatusToOngoing = '[ToE List Component] Set Assignment status to ongoing',
    AssignmentStatusUpdated = '[ToE List Component] Update Status of Assignment',

    AssignmentEmptyAction = '[Assignment Effects] Empty Action',

    AllAssignmentsRequested = '[Select or Create Assignment Dialog] All Assignments Requested',
    AllAssignmentsLoaded = '[Assignment Effects] All Assignments Loaded'
}

export class AssignmentOnServerCreated implements Action {
    readonly type = AssignmentActionTypes.AssignmentOnServerCreated;

    constructor(public payload: { assignment: AssignmentModel }) {
    }
}

export class AssignmentCreated implements Action {
    readonly type = AssignmentActionTypes.AssignmentCreated;

    constructor(public payload: { assignment: AssignmentModel }) {
    }
}

export class OneAssignmentRequest implements Action {
    readonly type = AssignmentActionTypes.OneAssignmentRequest

    constructor(public payload: { id: number }) {
    }
}

export class OneAssignmentLoaded implements Action {
    readonly type = AssignmentActionTypes.OneAssignmentLoaded;

    constructor(public payload: { assignment: AssignmentModel }) {
}
}


export class AssignmentOnServerAdminRestored implements Action {
    readonly type = AssignmentActionTypes.AssignmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssignmentAdminRestored implements Action {
    readonly type = AssignmentActionTypes.AssignmentAdminRestored;

    constructor(public payload: { item: AssignmentModel }) {
    }
}

export class AssignmentOnServerRestored implements Action {
    readonly type = AssignmentActionTypes.AssignmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}


export class AssignmentRestored implements Action {
    readonly type = AssignmentActionTypes.AssignmentRestored;

    constructor(public payload: { assignment: AssignmentModel }) {
    }
}

export class AssignmentDeletedFromTrash implements Action {
    readonly type = AssignmentActionTypes.AssignmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssignmentDeletedFromAdminTrash implements Action {
    readonly type = AssignmentActionTypes.AssignmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssignmentUpdated implements Action {
    readonly type = AssignmentActionTypes.AssignmentUpdated;

    constructor(public payload: {
        partialAssignment: Update<AssignmentModel>, // For State update
        assignment: AssignmentModel // For Server update (through service)
    }) {
    }
}

export class AssignmentsStatusUpdated implements Action {
    readonly type = AssignmentActionTypes.AssignmentsStatusUpdated;

    constructor(public payload: {
        assignments: AssignmentModel[],
        status: number
    }) {
    }
}

export class OneAssignmentDeleted implements Action {
    readonly type = AssignmentActionTypes.OneAssignmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ManyAssignmentsDeleted implements Action {
    readonly type = AssignmentActionTypes.ManyAssignmentsDeleted;

    constructor(public payload: { ids: number[] }) {
    }
}

export class AssignmentsPageRequested implements Action {
    readonly type = AssignmentActionTypes.AssignmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssignmentsTrashRequested implements Action {
    readonly type = AssignmentActionTypes.AssignmentTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssignmentTrashFlushed implements Action {
    readonly type = AssignmentActionTypes.AssignmentTrashFlushed;

    constructor() {
    }
}

export class AssignmentsPageLoaded implements Action {
    readonly type = AssignmentActionTypes.AssignmentsPageLoaded;

    constructor(public payload: {
        assignments: AssignmentModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class AssignmentsPageCancelled implements Action {
    readonly type = AssignmentActionTypes.AssignmentsPageCancelled;
}

export class AssignmentsPageToggleLoading implements Action {
    readonly type = AssignmentActionTypes.AssignmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssignmentActionToggleLoading implements Action {
    readonly type = AssignmentActionTypes.AssignmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssignmentOnServerDuplicated implements Action {
    readonly type = AssignmentActionTypes.AssignmentOnServerDuplicated;
    constructor(public payload: {
        assignment: AssignmentModel,
        toesNames: string[],
        oldAssignmentID: number,
    }) {}
}

export class AssignmentDuplicated implements Action {
    readonly type = AssignmentActionTypes.AssignmentDuplicated;
    constructor(public payload: {assignment: AssignmentModel}) {}
}

export class SetAssignmentStatusToValidated implements Action {
    readonly type = AssignmentActionTypes.SetAssignmentStatusToValidated;
    constructor(public payload: {id: number}) {}
}
export class SetAssignmentStatusToOngoing implements Action {
    readonly type = AssignmentActionTypes.SetAssignmentStatusToOngoing;
    constructor(public payload: {id: number}) {}
}

export class AssignmentStatusUpdated implements Action {
    readonly type = AssignmentActionTypes.AssignmentStatusUpdated;
    constructor(public payload: {
        updateAssignment: Update<AssignmentModel>
    }) {}
}

export class AssignmentEmptyAction implements Action {
    readonly type = AssignmentActionTypes.AssignmentEmptyAction;
    constructor() {}
}

export class AllAssignmentsRequested implements Action {
    readonly type = AssignmentActionTypes.AllAssignmentsRequested
    constructor() {}
}

export class AllAssignmentsLoaded implements Action {
    readonly type = AssignmentActionTypes.AllAssignmentsLoaded;
    constructor(public paylaod: {assignments: AssignmentModel[]}) {}
}

export type AssignmentActions = AssignmentOnServerCreated
    | AssignmentCreated
    | AssignmentOnServerRestored

    | OneAssignmentRequest
    | OneAssignmentLoaded

    | AssignmentRestored
    | AssignmentAdminRestored
    | AssignmentDeletedFromTrash
    | AssignmentOnServerAdminRestored
    | AssignmentDeletedFromAdminTrash
    | AssignmentsTrashRequested
    | AssignmentTrashFlushed
    | AssignmentUpdated
    | AssignmentsStatusUpdated
    | OneAssignmentDeleted
    | ManyAssignmentsDeleted
    | AssignmentsPageRequested
    | AssignmentsPageLoaded
    | AssignmentsPageCancelled
    | AssignmentsPageToggleLoading
    | AssignmentActionToggleLoading
    | AssignmentOnServerDuplicated
    | AssignmentDuplicated
    | SetAssignmentStatusToValidated
    | AssignmentStatusUpdated
    | AssignmentEmptyAction
    | AllAssignmentsRequested
    | AllAssignmentsLoaded;
