// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {FoundationTypeActions, FoundationTypeActionTypes} from '../_actions/foundation-type.actions';
// Models
import {FoundationType} from '../_models/foundation-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface FoundationTypesState extends EntityState<FoundationType> {
    isAllFoundationTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: FoundationType[];
    lastCreatedFoundationTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<FoundationType> = createEntityAdapter<FoundationType>();

export const initialFoundationTypesState: FoundationTypesState = adapter.getInitialState({
    isAllFoundationTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedFoundationTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function foundationTypesReducer(state = initialFoundationTypesState, action: FoundationTypeActions): FoundationTypesState {
    switch (action.type) {
        case FoundationTypeActionTypes.FoundationTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedFoundationTypeId: undefined
            };
        case FoundationTypeActionTypes.FoundationTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case FoundationTypeActionTypes.FoundationTypeOnServerCreated:
            return {
                ...state
            };
        case FoundationTypeActionTypes.FoundationTypeCreated:
            return adapter.addOne(action.payload.foundationType, {
                ...state, lastCreatedFoundationTypeId: action.payload.foundationType.id
            });
        case FoundationTypeActionTypes.FoundationTypeUpdated:
            return adapter.updateOne(action.payload.partialFoundationType, state);
        case FoundationTypeActionTypes.AllFoundationTypesLoaded:
            return adapter.addAll(action.payload.foundationTypes, {
                ...state, isAllFoundationTypesLoaded: true
            });
        case FoundationTypeActionTypes.FoundationTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case FoundationTypeActionTypes.FoundationTypesPageLoaded:
            return adapter.addMany(action.payload.foundationTypes, {
                ...initialFoundationTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case FoundationTypeActionTypes.FoundationTypeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case FoundationTypeActionTypes.FoundationTypeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case FoundationTypeActionTypes.FoundationTypeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case FoundationTypeActionTypes.FoundationTypeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case FoundationTypeActionTypes.FoundationTypeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case FoundationTypeActionTypes.FoundationTypeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
