// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {OfficeStandardMeasurementActions, OfficeStandardMeasurementActionTypes} from '../_actions/office-standard-measurement.actions';
// Models
import {OfficeStandardMeasurement} from '../_models/office-standard-measurement.model';
import {QueryParamsModel} from '../../_base/crud';

export interface OfficeStandardMeasurementsState extends EntityState<OfficeStandardMeasurement> {
    isAllOfficeStandardMeasurementsLoaded: boolean;
    queryRowsCount: number;
    queryResult: OfficeStandardMeasurement[];
    lastCreatedOfficeStandardMeasurementId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<OfficeStandardMeasurement> = createEntityAdapter<OfficeStandardMeasurement>();

export const initialOfficeStandardMeasurementsState: OfficeStandardMeasurementsState = adapter.getInitialState({
    isAllOfficeStandardMeasurementsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOfficeStandardMeasurementId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function officeStandardMeasurementsReducer(state = initialOfficeStandardMeasurementsState, action: OfficeStandardMeasurementActions): OfficeStandardMeasurementsState {
    switch (action.type) {
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedOfficeStandardMeasurementId: undefined
            };
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerCreated:
            return {
                ...state
            };
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementCreated:
            return adapter.addOne(action.payload.officeStandardMeasurement, {
                ...state, lastCreatedOfficeStandardMeasurementId: action.payload.officeStandardMeasurement.id
            });
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementUpdated:
            return adapter.updateOne(action.payload.partialofficeStandardMeasurement, state);

        case OfficeStandardMeasurementActionTypes.AllOfficeStandardMeasurementsLoaded:
            return adapter.addAll(action.payload.officeStandardMeasurements, {
                ...state, isAllOfficeStandardMeasurementsLoaded: true
            });
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageLoaded:
            return adapter.addMany(action.payload.officeStandardMeasurements, {
                ...initialOfficeStandardMeasurementsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
