

<!--begin::Head-->
<div class="kt-login-head kt_login_wrapper">
    <span class="kt-login-signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
    <a href="javascript:;" routerLink="/auth/register" class="kt-link kt-login-signup-link">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
</div>
<!--end::Head-->

<!--begin::Body-->
<div class="kt-login-body">
    <!--begin::Signin-->
    <div class="kt-login-form">
        <h1>
            <a href="https://www.interval-soft.com/" title="Login Page">
                Login Page
            </a>
        </h1>

        <kt-auth-notice></kt-auth-notice>

        <!--begin::Form-->
        <form class="kt-form mad-login-form" [formGroup]="loginForm" autocomplete="off">
            <div class="form-group">
                <mat-form-field class="w-100" floatLabel="always">
                    <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
                    <input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off" style="box-sizing: inherit !important"/>
                    <mat-error *ngIf="isControlHasError('email','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','minLength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','maxLength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','pattern')">
                        <strong>{{ 'AUTH.VALIDATION.MAIL_FORMAT' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100" floatLabel="always">
                    <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" style="box-sizing: inherit !important"/>
                    <mat-error *ngIf="isControlHasError('password','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','minLength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','maxLength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-checkbox (change)="checkValue()" class="example-margin" [checked]="rememberMe">REMEMBER ME</mat-checkbox>
            </div>
            <!--begin::Action-->
            <div class="kt-login-actions">
                <button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}"
                        class="btn-elevate kt-login-btn-primary">{{ 'AUTH.LOGIN.BUTTON' | translate }}
                </button>
            </div>
            <!--end::Action-->
        </form>

        <p id="nav">
            <a href="javascript:;" routerLink="/auth/forgot-password" class="kt-login-link-forgot">
                {{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
            </a>
        </p>
        <!--end::Form-->
    </div>
    <!--end::Signin-->
</div>
<!--end::Body-->
