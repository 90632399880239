import {BaseModel} from '../../_base/crud';

export class AssetClassLandAreaModel extends BaseModel {
    id: number;
    planning_status_id: number;
    planning_status_name: string;
    qos_degradation_id: number;
    qos_degradation_name: string;
    env_considerations: string;
    other_considerations: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = null;
        this.planning_status_id = null;
        this.planning_status_name = '';
        this.qos_degradation_id = null;
        this.qos_degradation_name = null;
        this.env_considerations = null;
        this.other_considerations = null;

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}