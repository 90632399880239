// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {BathroomFitting} from '../_models/bathroom-fitting.model';

export enum BathroomFittingActionTypes {

    AllBathroomFittingsRequested = '[BathroomFittings Home Page] All BathroomFittings Requested',
    AllBathroomFittingsLoaded = '[BathroomFittings API] All BathroomFittings Loaded',

    BathroomFittingOnServerCreated = '[Edit BathroomFitting Dialog] BathroomFitting On Server Created',
    BathroomFittingCreated = '[Edit BathroomFittings Dialog] BathroomFittings Created',
    BathroomFittingUpdated = '[Edit BathroomFitting Dialog] BathroomFitting Updated',
    BathroomFittingDeleted = '[BathroomFittings List Page] BathroomFitting Deleted',

    BathroomFittingsPageRequested = '[BathroomFittings List Page] BathroomFittings Page Requested',
    BathroomFittingsPageLoaded = '[BathroomFittings API] BathroomFittings Page Loaded',
    BathroomFittingsPageCancelled = '[BathroomFittings API] BathroomFittings Page Cancelled',

    BathroomFittingsPageToggleLoading = '[BathroomFittings page] BathroomFittings Page Toggle Loading',
    BathroomFittingsActionToggleLoading = '[BathroomFittings] BathroomFittings Action Toggle Loading',

    // trash system

    BathroomFittingOnServerRestored = '[BathroomFitting Trash] BathroomFitting On Server Restored',
    BathroomFittingRestored = '[BathroomFitting Trash] BathroomFitting Restored',

    BathroomFittingOnServerAdminRestored = '[BathroomFitting Admin Trash] BathroomFitting On Server Restored',
    BathroomFittingAdminRestored = '[BathroomFitting Admin Trash] BathroomFitting Restored',

    BathroomFittingDeletedFromTrash = '[BathroomFitting Trash] BathroomFitting deleted',
    BathroomFittingDeletedFromAdminTrash = '[BathroomFitting Admin Trash] BathroomFitting deleted',

    BathroomFittingTrash = 'BathroomFitting Trashed',
    BathroomFittingTrashFlushed = 'BathroomFitting Trash Flushed',

    BathroomFittingsClear = '[BathroomFittings API] BathroomFittings Page Clear',
}

export class BathroomFittingOnServerCreated implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingOnServerCreated;

    constructor(public payload: { bathroomFitting: BathroomFitting}) {
    }
}

export class BathroomFittingCreated implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingCreated;

    constructor(public payload: { bathroomFitting: BathroomFitting }) {
    }
}

export class BathroomFittingUpdated implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingUpdated;

    constructor(public payload: {
        partialBathroomFitting: Update<BathroomFitting>,
        bathroomFitting: BathroomFitting
    }) {
    }
}

export class BathroomFittingDeleted implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class BathroomFittingsPageRequested implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingsPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class BathroomFittingsPageLoaded implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingsPageLoaded;

    constructor(public payload: { bathroomFittings: BathroomFitting[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class BathroomFittingsPageCancelled implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingsPageCancelled;
}

export class AllBathroomFittingsRequested implements Action {
    readonly type = BathroomFittingActionTypes.AllBathroomFittingsRequested;
}

export class AllBathroomFittingsLoaded implements Action {
    readonly type = BathroomFittingActionTypes.AllBathroomFittingsLoaded;

    constructor(public payload: { bathroomFittings: BathroomFitting[] }) {
    }
}

export class BathroomFittingsPageToggleLoading implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BathroomFittingsActionToggleLoading implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class BathroomFittingDeletedFromAdminTrash implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class BathroomFittingDeletedFromTrash implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BathroomFittingOnServerRestored implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BathroomFittingRestored implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingRestored;

    constructor(public payload: { item: BathroomFitting }) {
    }
}

export class BathroomFittingOnServerAdminRestored implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BathroomFittingAdminRestored implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingAdminRestored;

    constructor(public payload: { item: BathroomFitting }) {
    }
}

export class BathroomFittingTrashFlushed implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingTrashFlushed;

    constructor() {
    }
}

export class BathroomFittingsClear implements Action {
    readonly type = BathroomFittingActionTypes.BathroomFittingsClear;

    constructor() {
    }
}

export type BathroomFittingActions = BathroomFittingCreated
    | BathroomFittingUpdated
    | BathroomFittingDeleted
    | BathroomFittingsPageRequested
    | BathroomFittingsPageLoaded
    | BathroomFittingsPageCancelled
    | AllBathroomFittingsLoaded
    | AllBathroomFittingsRequested
    | BathroomFittingOnServerCreated
    | BathroomFittingsPageToggleLoading
    | BathroomFittingsActionToggleLoading
    | BathroomFittingDeletedFromAdminTrash
    | BathroomFittingDeletedFromTrash
    | BathroomFittingOnServerRestored
    | BathroomFittingRestored
    | BathroomFittingOnServerAdminRestored
    | BathroomFittingAdminRestored
    | BathroomFittingTrashFlushed
    | BathroomFittingsClear;
