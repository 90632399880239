import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ValuationProcessState } from "../_reducers/valuation-process.reducer";
import { selectValuationProcessAdjustments } from "./adjustment.selectors";
import { selectAllActiveValuationProcessCriterions, selectAllValuationProcessCriterions } from "./default-criterion.selectors";
import { selectValuationComparableFullState } from "./valuation-comparable-full.selectors";
import { selectValuationProcessSizeCriterions } from './size-criterion.selectors'
import { selectValuationProcessConsiderationCriterion } from "./consideration-criterion.selector";
import { selectValuationProcessValuationState } from "./valuation.selectors";

export const selectValuationProcessState = createFeatureSelector<ValuationProcessState>('valuation-process')

export const selectValuationProcessTitle = createSelector(
    selectValuationProcessState,
    state => {
        if (!state.data) {
            return 'Valuation: Loading Data'
        }

        return `Valuation: "${state.data.targetProperty.name}" - "${state.data.valuation.approachesToValueName}" - "${state.data.valuation.methodsToValueName}"`
    }
)

export const selectValuationProcessType = createSelector(
    selectValuationProcessState,
    state => state.valuationType
)

export const selectValuationProcessLoadingState = createSelector(
    selectValuationProcessState,
    state => state.loading
)

export const selectValuationProcessTargetPropertyInfo = createSelector(
    selectValuationProcessState,
    state => state.data?.targetProperty
)

export const selectValuationProcessValuation = createSelector(
    selectValuationProcessState,
    state => state.data?.valuation
)

export const selectValuationProcessAdditionalData = createSelector(
    selectValuationProcessState,
    state => {
        return state.data?.additional
    }
)

export const selectValuationProcessTargetProperty = createSelector(
    selectValuationProcessState,
    state => state.data?.targetProperty.info
)

export const selectValuationProcessSelectedConsiderations = createSelector(
    selectValuationProcessState,
    state => state.selectedConsiderations
)

export const selectValuationProcessSelectedTenures = createSelector(
    selectValuationProcessState,
    selectValuationComparableFullState,
    (processState, comparablesState) => {
        const selectedConsiderations = processState.selectedConsiderations;
        const refNums = selectedConsiderations.map(item => item.refNum)
        const tenures = processState.comparables
            .map(refNum => comparablesState.entities[refNum])
            .filter(item => item != undefined)
            .filter(item => {
                return !refNums.includes(item.refNum)
            })
            .map(item => {
                return {
                    refNum: item.refNum,
                    id: item.considerations[0].id
                }
            });

        return [...tenures, ...selectedConsiderations];
    }
)

export const selectValuationProcessSelectedComparables = createSelector(
    selectValuationProcessState,
    state => state.comparables
)

export const selectValuationProcessSelectedComparablesFull = createSelector(
    selectValuationProcessState,
    selectValuationComparableFullState,
    (processState, comparablesState) => {
        return processState.comparables.map(refNum => comparablesState.entities[refNum]).filter(item => item != undefined)
    }
)

export const selectValuationProcessTargetPropertyUnitMeasurement = createSelector(
    selectValuationProcessState,
    state => {
        if (!state.data) {
            return null 
        }
        return state.data.targetProperty.generalInfo.unitMeasurementName
    }
)

export const selectOtherValidatedValuations = createSelector(
    selectValuationProcessState,
    state => state.data.additional.otherValidatedValuations
)
