<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div class="form form-group-seperator-dashed">
        <!-- &lt;!&ndash;<form [formGroup]="toeForm2" class="kt-form kt-form&#45;&#45;group-seperator-dashed">&ndash;&gt; -->
        <form [formGroup]="valuationForm" class="kt-form">
            <div class="card-body pt-0">
                <div class="form-group row">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Approaches to Value"
                                        formControlName="approaches_to_value_id"
                                        [(ngModel)]="acValuation.approaches_to_value_id"
                                        #singleSelectApproachesToValue>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="filterCtrlApproachesToValuesData"
                                                        placeholderLabel="Search"
                                                        noEntriesFoundLabel="Approaches to Value not found">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let _data of filteredApproachesToValuesData | async" [value]="_data.id">
                                    {{ _data.name }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.APPROACHES.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.APPROACHES.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error>
                                    Approaches to Value
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Methods to Value"
                                        formControlName="methods_to_value_id"
                                        [(ngModel)]="acValuation.methods_to_value_id"
                                        #singleSelectMethodsToValue>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="filterCtrlMethodsToValuesData"
                                                        placeholderLabel="Search"
                                                        noEntriesFoundLabel="Methods to value not found">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let _data of filteredMethodsToValuesData | async" [value]="_data.id"
                                    [disabled]="_data.disabled">
                                    {{_data.text}}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.METHODS.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.METHODS.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error>
                                    Methods to Value
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select required placeholder="Premise of Value" formControlName="premise_of_value_id">
                                <mat-option *ngFor="let premiseOfValue of premiseOfValues" [value]="premiseOfValue.id">
                                    {{premiseOfValue.name}}
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.PREMISE.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.PREMISE.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error>
                                Premise of Value
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 kt-margin-bottom-10-mobile mb-3">
                        <ng-container [ngSwitch]="tenureType$ |async">
                            <ng-container *ngSwitchCase="tenureType.Default">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Tenure" formControlName="tenure_id">
                                        <mat-option *ngFor="let tenure of tenures" [value]="tenure.id" [disabled]="tenure.disabled">{{tenure.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Tenure
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="tenureType.Land">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Land Tenure" formControlName="tenure_id">
                                        <mat-option *ngFor="let tenure of landTenures" [value]="tenure.id">{{tenure.name + '(' + tenure.payment_type + ')'}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Land Tenure
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="tenureType.Custom">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput required placeholder="Tenure" formControlName="custom_tenure"/>
                                    <mat-icon matSuffix class="cursor-pointer icon-gray" container="body" (click)="$event.stopPropagation()"
                                        [popoverTitle]="'TOOLTIP.TP.CUSTOM_TENURE.TITLE' | translate"
                                        [ngbPopover]="'TOOLTIP.TP.CUSTOM_TENURE.DESCRIPTION' | translate">
                                        help
                                    </mat-icon>
                                    <mat-error>
                                        Tenure
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <input required matInput placeholder="Interest Valued" formControlName="interest_valued" mask="percent" suffix="%"/>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.INTEREST_VALUED.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.INTEREST_VALUED.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error>
                                Interest Valued
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-10-mobile mb-3" *ngIf="valuationForm.controls.interest_valued.value && valuationForm.controls.interest_valued.value < 100">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Expected Restricted Interest Discount (if any)" formControlName="discount" mask="percent" suffix="%"/>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.INTEREST_DISCOUNT.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.INTEREST_DISCOUNT.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="valuationForm.controls.interest_valued.value && valuationForm.controls.interest_valued.value < 100">
                        <mat-form-field class="mat-form-field-fluid">
                        <textarea required matInput
                                    placeholder="Clarify the relationship of the fractional interest being valued relative to all other fractional interests and the obligations of the fractional interest ownership, if any, to other fractional interest owners."
                                    rows="2" formControlName="comment"></textarea>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error>
                                Clarify the relationship of the fractional...
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                
            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="onNoClick()" class="mr-4">Close</button>
                <button type="button" 
                    [disabled]="!isValid()" 
                    class="btn btn-success" 
                    mat-raised-button color="submit" 
                    (click)="onSubmit()" 
                    matTooltip="Add consideration">
                    Add
                </button>
            </div>
        </div>
    </div>
</div>
