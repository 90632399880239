<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{title}}</h3>
            <span class="card-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">

            <a href="javascript:;"
               (click)="onNoClick()"
               class="btn btn-icon btn-sm btn-warning"
               data-card-tool="remove"
               data-toggle="tooltip"
               data-placement="top"
               title="Remove Card">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body m-0">
        <ngb-carousel [interval]="0" *ngIf="items.length !== 0; else noImage">
            <ng-template *ngFor="let img of items; let i = index" ngbSlide>
                <img [src]="getURL(img.path, img.name)" 
                class="rounded" style="height: 700px; width: 100%; object-fit: cover;" (error)="showDefaultPic($event)">
                <div class="image-caption">
                    {{img.title ? img.title : ('Source Picture ' + (i + 1))}}
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
<ng-template #noImage>
    <div>No Image is provided</div>
</ng-template>
