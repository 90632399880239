import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { WarehouseAdjustmentActions, WarehouseAdjustmentsActionTypes } from "../_actions/warehouse-adjustment.actions";
import { WarehouseAdjustmentModel } from "../_models/warehouse-adjustment.model";

export interface WarehouseAdjustmentsState extends EntityState<WarehouseAdjustmentModel> {
    isAllAdjustmentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: WarehouseAdjustmentModel[];
    lastCreatedAdjustmentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<WarehouseAdjustmentModel> = createEntityAdapter<WarehouseAdjustmentModel>();

export const initialAdjustmentsState: WarehouseAdjustmentsState = adapter.getInitialState({
    isAllAdjustmentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAdjustmentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function warehouseAdjustmentssReducer(state = initialAdjustmentsState, action: WarehouseAdjustmentActions): WarehouseAdjustmentsState {
    switch (action.type) {
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdjustmentId: undefined
            };
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerCreated:
            return {
                ...state
            };
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAdjustmentsId: action.payload.item.id
            });
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case WarehouseAdjustmentsActionTypes.AllWarehouseAdjustmentsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllAdjustmentssLoaded: true
            });
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialAdjustmentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case WarehouseAdjustmentsActionTypes.WarehouseAdjustmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();