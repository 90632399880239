import { Action, ActionReducer } from '@ngrx/store';
import { merge, pick } from 'lodash';
import { MetaAppActions, MetaAppActionTypes } from '../_actions/meta.actions';

const localStorageKey = '___app_storage___';

function setSavedState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
}

function getSavedState(localStorageKey: string): any {
    return JSON.parse(localStorage.getItem(localStorageKey));
}

function deleteSavedState(localStorageKey: string) {
    localStorage.removeItem(localStorageKey);
}

function pickState(state: any, payload: any) {
    const selectingState = pick(state, [payload.name]);
    return selectingState;
}

export function storageMetaReducer<S, A extends Action = Action> (
    reducer: ActionReducer<S, A>
) {
    let onInit = true;
    return function(state: S, action: A): S {
        const nextState = reducer(state, action);
        if (onInit) {
            onInit = false;
            const savedState = getSavedState(localStorageKey);
            return merge(nextState, savedState);
        }
        if ((<any>Object).values(MetaAppActionTypes).includes(action.type)) {
            const metaAction = <MetaAppActions>action;

            switch (metaAction.type) {
                case MetaAppActionTypes.SaveState: {
                    const payload = metaAction.payload;
                    const stateToSave = pickState(nextState, payload);
                    setSavedState(stateToSave, localStorageKey);
                    break;
                }
                case MetaAppActionTypes.DeleteState: {
                    deleteSavedState(localStorageKey);
                    break;
                }
            }
        }
        return nextState;
    }
}