<div class="card h-100">
    <div class="card-header section-header">
        <span>{{ multimedia && valuations.length > 1 ? 'Selected Valuation Specifics' : 'Valuation Specifics'}}</span>
    </div>
    <div class="card-body section-body">
        <table class="table" *ngIf="data$|async as data">
            <tbody>
                <tr>
                    <th>Basis : </th> <td>{{data.basis}}</td>
                </tr>
                <tr>
                    <th>Approach : </th> <td>{{data.approach}}</td>
                </tr>
                <tr>
                    <th>Method : </th> <td>{{data.method}}</td>
                </tr>
                <tr>
                    <th>Premise of Value : </th> <td>{{data.premiseOfValue}}</td>
                </tr>
                <tr>
                    <th>Tenure : </th> <td>{{data.tenure}}</td>
                </tr>
                <tr *ngIf="multimedia && valuations.length > 1">
                    <th>Other Valuation(s) : </th> <td>{{fileCnt + 'x (Files available in file section)'}}</td>
                </tr>
                <tr *ngIf="!multimedia">
                    <th>Last Edit : </th> <td>{{data.lastEditedDate ? (data.lastEditedDate | date:'dd MMM yyyy')  : (data.valuationDate  | date:'dd MMM yyyy')}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>