// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {BuiltinFittingsService} from '../_services/builtin-fitting.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allBuiltinFittingsLoaded} from '../_selectors/builtin-fitting.selectors';
// Actions
import {
    AllBuiltinFittingsLoaded,
    AllBuiltinFittingsRequested,
    BuiltinFittingActionTypes,
    BuiltinFittingsPageRequested,
    BuiltinFittingsPageLoaded,
    BuiltinFittingUpdated,
    BuiltinFittingsPageToggleLoading,
    BuiltinFittingDeleted,
    BuiltinFittingOnServerCreated,
    BuiltinFittingCreated,
    BuiltinFittingAdminRestored,
    BuiltinFittingDeletedFromAdminTrash,
    BuiltinFittingDeletedFromTrash,
    BuiltinFittingOnServerAdminRestored,
    BuiltinFittingOnServerRestored,
    BuiltinFittingRestored,
    BuiltinFittingTrashFlushed,
    BuiltinFittingsActionToggleLoading
} from '../_actions/builtin-fitting.actions';

@Injectable()
export class BuiltinFittingEffects {
    showPageLoadingDispatcher = new BuiltinFittingsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new BuiltinFittingsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new BuiltinFittingsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllBuiltinFittings$ = this.actions$
        .pipe(
            ofType<AllBuiltinFittingsRequested>(BuiltinFittingActionTypes.AllBuiltinFittingsRequested),
            withLatestFrom(this.store.pipe(select(allBuiltinFittingsLoaded))),
            filter(([action, isAllBuiltinFittingsLoaded]) => !isAllBuiltinFittingsLoaded),
            mergeMap(() => this.service.getAllBuiltinFittings()),
            map(res => {
                return new AllBuiltinFittingsLoaded({builtinFittings: res.data});
            })
        );

    @Effect()
    loadBuiltinFittingsPage$ = this.actions$
        .pipe(
            ofType<BuiltinFittingsPageRequested>(BuiltinFittingActionTypes.BuiltinFittingsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findBuiltinFittings(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new BuiltinFittingsPageLoaded({
                    builtinFittings: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingDeleted>(BuiltinFittingActionTypes.BuiltinFittingDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteBuiltinFitting(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingUpdated>(BuiltinFittingActionTypes.BuiltinFittingUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateBuiltinFitting(payload.builtinFitting);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingOnServerCreated>(BuiltinFittingActionTypes.BuiltinFittingOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createBuiltinFitting(payload.builtinFitting).pipe(
                    tap(res => {
                        this.store.dispatch(new BuiltinFittingCreated({builtinFitting: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushBuiltinFittingTrash$ = this.actions$
        .pipe(
            ofType<BuiltinFittingTrashFlushed>(BuiltinFittingActionTypes.BuiltinFittingTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingOnServerRestored>(BuiltinFittingActionTypes.BuiltinFittingOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuiltinFittingRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingOnServerAdminRestored>(BuiltinFittingActionTypes.BuiltinFittingOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuiltinFittingAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingDeletedFromTrash>(BuiltinFittingActionTypes.BuiltinFittingDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashBuiltinFitting$ = this.actions$
        .pipe(
            ofType<BuiltinFittingDeletedFromAdminTrash>(BuiltinFittingActionTypes.BuiltinFittingDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: BuiltinFittingsService, private store: Store<AppState>) {
    }
}
