import {Departure} from '../_models/departure.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {DeparturesState} from '../_reducers/departure.reducers';
import * as fromDeparture from '../_reducers/departure.reducers';
import {each} from 'lodash';

export const selectDeparturesState = createFeatureSelector<DeparturesState>('departures');

export const selectDepartureById = (departureId: number) => createSelector(
    selectDeparturesState,
    departuresState => departuresState.entities[departureId]
);

export const selectAllDepartures = createSelector(
    selectDeparturesState,
    fromDeparture.selectAll
);

export const selectAllDeparturesIds = createSelector(
    selectDeparturesState,
    fromDeparture.selectIds
);

export const allDeparturesLoaded = createSelector(
    selectDeparturesState,
    departuresState => departuresState.isAllDeparturesLoaded
);


export const selectDeparturesPageLoading = createSelector(
    selectDeparturesState,
    departuresState => departuresState.listLoading
);

export const selectDeparturesActionLoading = createSelector(
    selectDeparturesState,
    departuresState => departuresState.actionLoading
);

export const selectLastCreatedDepartureId = createSelector(
    selectDeparturesState,
    departuresState => departuresState.lastCreatedDepartureId
);

export const selectDeparturesShowInitWaitingMessage = createSelector(
    selectDeparturesState,
    departuresState => departuresState.showInitWaitingMessage
);

export const selectTrashedDepartureCount = createSelector(
    selectDeparturesState,
    (departuresState) => departuresState.totalTrashed
);

export const selectAdminTrashedDepartureCount = createSelector(
    selectDeparturesState,
    (departuresState) => departuresState.totalAdminTrashed
);


export const selectDepartureQueryResult = createSelector(
    selectDeparturesState,
    departuresState => {
        const items: Departure[] = [];
        each(departuresState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Departure[] = httpExtension.sortArray(items, departuresState.lastQuery.sortField, departuresState.lastQuery.sortOrder);

        return new QueryResultsModel(result, departuresState.totalCount, '', departuresState.totalTrashed);
    }
);
