// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
import {
    selectAssignmentsInStore,
    selectAssignmentsPageLoading,
    selectAssignmentsShowInitWaitingMessage
} from '../_selectors/assignment.selectors';

export class AssignmentsDataSource extends BaseDataSource {
    trashed: number = 0;
    pageIndex: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssignmentsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssignmentsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssignmentsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.pageIndex = response.page;
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
