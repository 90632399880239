import { Action } from "@ngrx/store";
import { VComparable, ValuationComparableMapData } from "../_models";
import type { FilterModel } from 'src/app/views/pages/shared_components/filter-section/filter-section.component';
import { ValuationAdjustmentTabDataModel } from "../_models/valuation-adjustment-tab-data.model";

export type ValuationComparableFilter = {propertySubTypeIds: number[]} & FilterModel

export enum ValuationComparableActionTypes {
    LoadComparables = '[Valuation Process Page / Comparables Tab] Load Comparables for selected filter',
    ComparablesLoaded = '[Valuation Process API] Comparables loaded',

    FullComparablesRequested = '[Valuation Process Page] Full Comparables Info requested',

    FullComparablesRequestedOnServer = '[Valuation Comparable Effect] Request Full Comparables Info',
    FullComparablesLoadedFromServer = '[Valuation Process API] Full Comparables Info loaded',

    EmptyAction = '[Valuation Comparable Effect] Empty Action',

    AddedComparablesRequested = '[Comparable Tab Page] Added comparable requested',
    AddComparableMapData = '[Valuation Comparable Effect] Add added comparable to map data',

    LoadSelectedPropertySubTypes = '[Valuation Process Effect] Add Selected Property Sub Types',

    ImportedComparablesRequested = '[Market Data Page] Imported Comparables Info requested',
    ImportedComparablesLoadedFromServer = '[Valuation Process API] Imported Comparables Info loaded'
}

export class LoadValuationComparables implements Action {
    readonly type = ValuationComparableActionTypes.LoadComparables
    constructor(public payload: {
        filter: ValuationComparableFilter 
    }) {}
}

export class ValuationComparablesLoaded implements Action {
    readonly type = ValuationComparableActionTypes.ComparablesLoaded
    constructor(public payload: {
        items: ValuationComparableMapData[]
    }) {}
}

export class ValuationComparablesAddComparableMapData implements Action {
    readonly type = ValuationComparableActionTypes.AddComparableMapData
    constructor(public payload: {
        item: ValuationComparableMapData
    }) {}
}

export class FullComparablesRequested implements Action {
    readonly type = ValuationComparableActionTypes.FullComparablesRequested
    constructor (public payload: {
        refNums: string[]
    }) {}
}

export class ImportedComparablesRequested implements Action {
    readonly type = ValuationComparableActionTypes.ImportedComparablesRequested
    constructor (public payload: {
        refNums: string[],
        adjustmentTabData: ValuationAdjustmentTabDataModel
    }) {}
}

export class FullComparablesRequestedOnServer implements Action {
    readonly type = ValuationComparableActionTypes.FullComparablesRequestedOnServer
    constructor(public payload: {
        refNums: string[]
    }) {}
}

export class FullComparablesLoadedFromServer implements Action {
    readonly type = ValuationComparableActionTypes.FullComparablesLoadedFromServer
    constructor(public payload: {
        items: VComparable[]
    }) {}
}

export class ImportedComparablesLoadedFromServer implements Action {
    readonly type = ValuationComparableActionTypes.ImportedComparablesLoadedFromServer
    constructor(public payload: {
        items: VComparable[],
        adjustmentTabData: ValuationAdjustmentTabDataModel
    }) {}
}
export class AddedComparablesRequested implements Action {
    readonly type = ValuationComparableActionTypes.AddedComparablesRequested
    constructor(public payload: {refNum: string}) {}
}

export class LoadSelectedPropertySubTypes implements Action {
    readonly type = ValuationComparableActionTypes.LoadSelectedPropertySubTypes
    constructor(public payload: {propertySubTypeIds: number[]}) {}
}

export type ValuationComparableAction
    = LoadValuationComparables
    | ValuationComparablesLoaded
    | FullComparablesRequested
    | FullComparablesRequestedOnServer
    | FullComparablesLoadedFromServer
    | AddedComparablesRequested
    | ValuationComparablesAddComparableMapData
    | LoadSelectedPropertySubTypes
    | ImportedComparablesRequested
    | ImportedComparablesLoadedFromServer