import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassLandModel } from "../_models/asset-class-land.model";

export enum AssetClassLandActionTypes {
    AssetClassLandActionToggleLoading = '[AssetClassLand Effect] AssetClassLand Action Toggle Loading',

    AssetClassLandOnServerCreated = '[Edit AssetClassLand page] AssetClassLand On Server Created',
    AssetClassLandCreated = '[AssetClassLand Effect] AssetClassLand Created',
    
    AssetClassLandOnServerUpdated = '[Edit AssetClassLand page] AssetClassLand On Server Updated',
    AssetClassLandUpdated = '[AssetClassLand Effect] AssetClassLand Updated',

    AssetClassLandDeleted = '[AssetClassLand List Page] Asset Class Land Deleted',
    AssetClassLandDeletedFromTrash = '[AssetClassLand Trash Modal] Asset Class Land Deleted from Trash',
    AssetClassLandDeletedFromAdminTrash = '[AssetClassLand Trash Modal] Asset Class Land Deleted from Admin Trash',
    AssetClassLandTrashFlushed = '[AssetClassLand Trash Modal] Asset Class Land Trash Flushed',

    AssetClassLandOnServerRestored = '[AssetClassLand Trash Modal] Asset Class Land Restored',
    AssetClassLandRestored = '[AssetClassLand Effect] Asset Class Land Restored',
    AssetClassLandOnServerAdminRestored = '[AssetClassLand Admin Trash Modal] Asset Class Land Admin Restored',
    AssetClassLandAdminRestored = '[AssetClassLand Effect] Asset Class Land Admin Restored',

    AssetClassLandPageToggleLoading = '[AssetClassLand Effect] AssetClassLand Page Toggle Loading',
    AssetClassLandPageRequested = '[AssetClassLand List Page] All AssetClassLand requested',
    AssetClassLandPageLoaded = '[AssetClassLand Effect] All AssetClassLand loaded',

    UpdatePreviouslyCreatedAssetClassLandId = '[AssetClassland Edit] Update Previously Created AssetClassLand Id',

    DuplicateAssetClass = '[AssetClassLand List Page] Duplicate Asset Class Land'
}

export class AssetClassLandOnServerCreated implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandOnServerCreated;
    constructor(public payload: {
        assetClassLand: AssetClassLandModel,
        fileList: any[],
    }) {}
}

export class AssetClassLandCreated implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandCreated;
    constructor(public payload: {assetClassLand: AssetClassLandModel}) {}
}

export class AssetClassLandOnServerUpdated implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandOnServerUpdated;
    constructor(public payload: {
        assetClassLand: AssetClassLandModel,
        fileList: any[] 
    }) {}
}

export class AssetClassLandUpdated implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandUpdated;
    constructor(public payload: {assetClassLand: AssetClassLandModel}) {}
}

export class AssetClassLandPageRequested implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandPageRequested;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class AssetClassLandPageLoaded implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandPageLoaded;
    constructor(public payload: {
        assetClassLands: AssetClassLandModel[],
        totalCount: number;
        totalTrashed: number;
        totalAdminTrashed: number;
        page: QueryParamsModel
    }) {}
}

export class AssetClassLandDeleted implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandDeleted;
    constructor(public payload: {id: number}) {}
}

export class AssetClassLandDeletedFromTrash implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandDeletedFromTrash;
    constructor(public payload: {id: number}) {}
}

export class AssetClassLandOnServerRestored implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandOnServerRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassLandRestored implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandRestored;
    constructor(public payload: {acLand: AssetClassLandModel}) {}
}

export class AssetClassLandOnServerAdminRestored implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandOnServerAdminRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassLandAdminRestored implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandAdminRestored;
    constructor(public payload: {acLand: AssetClassLandModel}) {}
}

export class AssetClassLandPageToggleLoading implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandPageToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassLandActionToggleLoading implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassLandDeletedFromAdminTrash implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassLandTrashFlushed implements Action {
    readonly type = AssetClassLandActionTypes.AssetClassLandTrashFlushed;
    constructor() {}
}

export class UpdatePreviouslyCreatedAssetClassLandId implements Action {
    readonly type = AssetClassLandActionTypes.UpdatePreviouslyCreatedAssetClassLandId;
    constructor() {}
}

export class AssetClassLandDuplicate implements Action {
    readonly type = AssetClassLandActionTypes.DuplicateAssetClass;
    constructor(public payload: {id: number}) {}
}

export type AssetClassLandActions 
    = AssetClassLandOnServerCreated
    | AssetClassLandCreated

    | AssetClassLandOnServerUpdated
    | AssetClassLandUpdated

    | AssetClassLandPageRequested
    | AssetClassLandPageLoaded

    | AssetClassLandDeleted
    | AssetClassLandDeletedFromTrash
    | AssetClassLandDeletedFromAdminTrash
    | AssetClassLandTrashFlushed

    | AssetClassLandOnServerRestored
    | AssetClassLandOnServerAdminRestored
    | AssetClassLandRestored
    | AssetClassLandAdminRestored
    
    | AssetClassLandPageToggleLoading
    | AssetClassLandActionToggleLoading
    | UpdatePreviouslyCreatedAssetClassLandId

    | AssetClassLandDuplicate