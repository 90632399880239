<ng-container *ngIf="calculationType === calculationTypeEnum.FreeholdToLeasehold">
    <div class="col-md-12 mb-10">
        <h5 class="my-3 text-mad text-uppercase">freehold information</h5>
        <hr class="active">
    </div>

    <div class="col-md-12 mat-table-wrapper section-margin-bottom-60">
        <table class="table lmat-elevation-z8">
            <tbody>
                <tr>
                    <th>Capital Value ({{currency}})</th>
                    <td>{{value$|async |  mask:'separator.2':{thousandSeparator: ','} }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>

<div class="col-md-12 mb-10">
    <h5 class="my-3 text-mad text-uppercase">{{title}} value calculation {{calculationType == calculationTypeEnum.FreeholdToLeasehold ? "(monthly)": ""}}</h5>
    <hr class="active">
</div>
<div class="col-md-12">
    <form [formGroup]="form" class="form">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="mat-form-field-fluid">
                   <input matInput placeholder="All Risk Yield (ARY) (%)" 
                        formControlName="ary"
                        mask="percent.5"
                        suffix="%">
                    <mat-error *ngIf="form.controls.ary.hasError('required')">
                        All Risk Yield is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls.ary.hasError('isZero')">
                        All Risk Yield cannot be <strong>0</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-8">
                <div class="calculated-wrapper">
                    <div class="calculated" *ngIf="templateData$|async as data">
                        <span class="calculated-value"> {{ data.computedValue !== undefined
                            ? (data.computedValue.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} )
                            : 'N/A'}} ({{currency}})</span>
                        <ng-template #popValueCalculation>
                            <ng-container *ngIf="calculationType === calculationTypeEnum.FreeholdToLeasehold">
                                <strong>Calculation:</strong> Leasehold Rental Value
                                <br>
                                =
                                    <span class="text-blue">(Capital Value)</span>
                                    <span class="text-red"> x </span>
                                    <span class="text-blue">(ARY)</span>
                                    <span class="text-red"> / 12</span>
                                <br>
                                <ng-container *ngIf="data.ary !== undefined">
                                    =
                                        <span class="text-blue">{{value$|async | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                        <span class="text-red"> x </span>
                                        <span class="text-blue">{{data.ary}}%</span>
                                        <span class="text-red"> / 12</span>
                                    <br>
                                    = <span class="text-green">{{data.computedValue.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="calculationType === calculationTypeEnum.LeaseholdToFreehold">
                                <strong>Calculation:</strong> Freehold Capital Value
                                <br>
                                =
                                    <span class="text-blue">(Total Consideration per month)</span>
                                    <span class="text-red"> x 12 /</span>
                                    <span class="text-blue">(ARY)</span>
                                <br>
                                <ng-container *ngIf="data.ary !== undefined">
                                    =
                                        <span class="text-blue">{{value$|async | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                        <span class="text-red"> x 12 /</span>
                                        <span class="text-blue">{{data.ary}}%</span>
                                    <br>
                                    = <span class="text-green">{{data.computedValue.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="calculationType === calculationTypeEnum.HeadlineToEffectiveToLeaseholdToFreehold">
                                <strong>Calculation:</strong> Freehold Capital Value
                                <br>
                                =
                                    <span class="text-blue">(Effective Rent per year)</span>
                                    <span class="text-red">/</span>
                                    <span class="text-blue">(ARY)</span>
                                <br>
                                <ng-container *ngIf="data.ary !== undefined">
                                    =
                                        <span class="text-blue">{{value$|async | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                        <span class="text-red">/</span>
                                        <span class="text-blue">{{data.ary}}%</span>
                                    <br>
                                    = <span class="text-green">{{data.computedValue.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        <button type="button" class="calculated-formula" mat-icon-button
                            color="primary"
                            [ngbPopover]="popValueCalculation"
                            placement="top"
                            popoverClass="my-popover"
                        >
                            <mat-icon>info</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<ng-template #popValueCalculation>
    <ng-container *ngIf="calculationType === calculationTypeEnum.FreeholdToLeasehold; else leaseholdToFreehold">
        <strong>Calculation:</strong> {{calculationType === calculationTypeEnum.FreeholdToLeasehold
            ? 'Leasehold Rental Value'
            : 'Freehold Capital Value'}}
        <br>
        =
            <span class="text-blue">(Capital Value)</span>
            <span class="text-red"> x </span>
            <span class="text-blue">(ARY)</span>
            <span class="text-red"> / 12</span>
        <br>
        =
            <span class="text-blue">{{value$|async}}</span>
            <span class="text-red"> x </span>
            <span class="text-blue">{{aryValue$|async}}</span>
            <span class="text-red"> / 12</span>
        <br>
        = <span class="text-green">{{computedValue$|async}}</span>
    </ng-container>
</ng-template>

<ng-template #leaseholdToFreehold>

</ng-template>