import {BaseModel} from '../../_base/crud';

export class ToeTpMultimediaModel extends BaseModel {
    id: number;

    toe_id: number;
    tp_id: number;
    tp_index: number;
    tp_name: string;

    url: string;
    pwd: string;
    
    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;

        this.toe_id = undefined;
        this.tp_id = undefined;
        this.tp_index = undefined;
        this.tp_name = '';
        
        this.url = '';
        this.pwd = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
