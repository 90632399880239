// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {KeyPlacesState} from '../_reducers/key-place.reducers';
import * as fromKeyPlaces from '../_reducers/key-place.reducers';
import {HttpExtenstionsModel, QueryResultsModel} from '../../_base/crud';
import {each} from 'lodash';
import {KeyPlaceModel} from '../_models/key-place.model';

export const selectKeyPlacesState = createFeatureSelector<KeyPlacesState>('key-places');

export const selectKeyPlaceById = (keyPlaceId: number) => createSelector(
    selectKeyPlacesState,
    cs => cs.entities[keyPlaceId]
);

export const selectAllKeyPlaces = createSelector(
    selectKeyPlacesState,
    fromKeyPlaces.selectAll
);

export const selectKeyPlaceActionLoading = createSelector(
    selectKeyPlacesState,
    _state => _state.actionLoading
);

export const selectKeyPlacesPageLoading = createSelector(
    selectKeyPlacesState,
    _state => _state.listLoading
);


export const selectLastCreatedKeyPlaceId = createSelector(
    selectKeyPlacesState,
    (_state) => {
        if (_state.previouslyCreatedKeyPlaceId != _state.lastCreatedKeyPlaceId) {
            return _state.lastCreatedKeyPlaceId
        }
        return undefined 
    }
);

export const selectTrashedKeyPlaceCount = createSelector(
    selectKeyPlacesState,
    (_state) => _state.totalTrashed
)

export const selectAdminTrashedKeyPlaceCountId = createSelector(
    selectKeyPlacesState,
    (_state) => _state.totalAdminTrashed
);

export const selectQueryResult = createSelector(
    selectKeyPlacesState,
    _state => {
        const items: KeyPlaceModel[] = [];
        each(_state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: KeyPlaceModel[] = [];

        switch (_state.lastQuery.sortField) {
            case 'name':
                result = httpExtension
                    .sortArrayStringNoArticle(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'city_of_location':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;
            case 'category_name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            case 'id':
                result = httpExtension
                    .sortArrayNumber(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension
                    .sortArray(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);

        }
        return new QueryResultsModel(result, _state.queryRowsCount, '', _state.totalTrashed);
    }
);

export const selectKeyPlacesShowInitWaitingMessage = createSelector(
    selectKeyPlacesState,
    _state => _state.showInitWaitingMessage
);

export const selectKeyPlacesStatus = createSelector(
    selectKeyPlacesState,
    _state => {
        return _state.status
    }
)

