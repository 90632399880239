import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { ValuationStandardActions, ValuationStandardActionTypes } from '../_actions/valuation-standard.actions';
import { ValuationStandard } from '../_models/valuation-standard.model';

export interface ValuationStandardState extends EntityState<ValuationStandard> {
    isAllValuationStandardLoaded: boolean;
    queryRowsCount: number;
    queryResult: ValuationStandard[];
    lastCreatedValuationStandardId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ValuationStandard> = createEntityAdapter<ValuationStandard>();

export const initialValuationStandardState: ValuationStandardState = adapter.getInitialState({
    isAllValuationStandardLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedValuationStandardId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function valuationStandardReducer(
    state = initialValuationStandardState,
    action: ValuationStandardActions
): ValuationStandardState {
    switch (action.type) {
        case ValuationStandardActionTypes.ValuationStandardPageToggleLoading:
            return {
                ...state,
                listLoading: action.payload.isLoading,
                lastCreatedValuationStandardId: undefined
            }
            case ValuationStandardActionTypes.ValuationStandardActionToggleLoading:
                return {
                    ...state, actionLoading: action.payload.isLoading
                };
            case ValuationStandardActionTypes.ValuationStandardOnServerCreated:
                return {
                    ...state
                };
            case ValuationStandardActionTypes.ValuationStandardCreated:
                return adapter.addOne(action.payload.valuationStandard, {
                    ...state, lastCreatedValuationStandardId: action.payload.valuationStandard.id
                });
            case ValuationStandardActionTypes.ValuationStandardUpdated:
                return adapter.updateOne(action.payload.partialValuationStandard, state);
    
            case ValuationStandardActionTypes.AllValuationStandardLoaded:
                return adapter.addAll(action.payload.valuationStandards, {
                    ...state,
                    isAllValuationStandardLoaded: true
                });
            case ValuationStandardActionTypes.ValuationStandardPageCancelled:
                return {
                    ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
                };
            case ValuationStandardActionTypes.ValuationStandardPageLoaded:
                return adapter.addMany(action.payload.valuationStandards, {
                    ...initialValuationStandardState,
                    totalCount: action.payload.totalCount,
                    totalTrashed: action.payload.totalTrashed,
                    totalAdminTrashed: action.payload.totalAdminTrashed,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
    
            case ValuationStandardActionTypes.ValuationStandardDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case ValuationStandardActionTypes.ValuationStandardRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case ValuationStandardActionTypes.ValuationStandardDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case ValuationStandardActionTypes.ValuationStandardAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case ValuationStandardActionTypes.ValuationStandardDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case ValuationStandardActionTypes.ValuationStandardTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();