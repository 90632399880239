import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { each } from 'lodash';
import { Observable, BehaviorSubject, Subject, Subscription, of, combineLatest } from 'rxjs';
import { startWith, takeUntil, take, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { CountryModel, CityModel, AllCountriesRequested, AllCitiesRequested, selectAllCountries } from 'src/app/core/admin';
import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel, AssetClassConsiderationModel } from 'src/app/core/asset_class';
import { AssetClassSizeModel, AcSource, AssetClassTenure } from 'src/app/core/comparable';
import { AssetClassParkingOnServerUpdated, AssetClassParkingOnServerCreated, UpdatePreviouslyCreatedACParkingId } from 'src/app/core/comparable/_actions/asset-class-parking.actions';
import { AssetClassParkingModel } from 'src/app/core/comparable/_models/asset-class-parking.model';
import { selectAssetClassParkingActionLoading, selectAssetClassParkingById, selectLastCreatedAssetClassParkingId } from 'src/app/core/comparable/_selectors/asset-class-parking.selectors';
import { Grade, CoordinateReferenceSystem, BuildingType, StateRepair, HandoverStandard, FoundationType, AllGradesRequested, selectAllGrades, AllCoordinateReferenceSystemsRequested, selectAllCoordinateReferenceSystems, AllStateRepairsRequested, selectAllStateRepairs, AllHandoverStandardsRequested, selectAllHandoverStandards, AllFoundationTypesRequested, selectAllFoundationTypes, AllBuildingTypesRequested, selectAllBuildingTypes, AllParkingTypesRequested, selectAllParkingTypes, selectAllUnitAreaMeasurements, AllUnitAreaMeasurementsRequested } from 'src/app/core/linked-tables';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, TypesUtilsService, MessageType } from 'src/app/core/_base/crud';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { AcGroundsComponent } from '../../../shared_components/ac-grounds/ac-grounds.component';
import { AccommodationLayoutTableComponent } from '../../../shared_components/accommodation-layout-table/accommodation-layout-table.component';
import { BuildingFormComponent } from '../../../shared_components/building-form/building-form.component';
import { ExternalAspectsComponent } from '../../../shared_components/external-aspects/external-aspects.component';
import { InternalAspectsComponent } from '../../../shared_components/internal-aspects/internal-aspects.component';
import { LandAreaListComponent } from '../../../shared_components/land-parcel/land-area/land-area-list/land-area-list.component';
import { LandParcelMapComponent } from '../../../shared_components/land-parcel/land-parcel-map/land-parcel-map.component';
import { MapLocationComponent } from '../../../shared_components/map-location/map-location.component';
import { ServicesAndInfrastructuresComponent } from '../../../shared_components/services-and-infrastructures/services-and-infrastructures.component';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { UploadFileComponent } from '../../../shared_components/upload-file/upload-file.component';
import { BuildingInfoDialogComponent } from '../../_sub/building-info-dialog/building-info-dialog.component';
import { ConsiderationAndSourceComponent } from '../../_sub/consideration-and-source/consideration-and-source.component';
import { ValuationCheckerModalComponent } from '../../_sub/valuation-checker-modal/valuation-checker-modal.component';
import { environment } from 'src/environments/environment';
import {v4 as uuidv4} from 'uuid'
import { AssetClassSize } from 'src/app/core/v2/types';

@Component({
  selector: 'kt-parking-modal',
  templateUrl: './parking-modal.component.html',
  styleUrls: ['./parking-modal.component.scss', '../../complex-btn.scss']
})
export class ParkingModalComponent implements OnInit {

  @ViewChild('search', {static: true})
  public searchElementRef: ElementRef;

  @ViewChild(MapLocationComponent, {static: false}) 
  mapLocationComponent: MapLocationComponent;

  @ViewChild(LandParcelMapComponent, {static: false}) 
  landParcelMapComponent: LandParcelMapComponent;

  @ViewChild(LandAreaListComponent, {static: false})
  landAreaListComponent: LandAreaListComponent;

  @ViewChild(AccommodationLayoutTableComponent, {static: false})
  accommodationLayoutTable: AccommodationLayoutTableComponent;

  @ViewChild(ServicesAndInfrastructuresComponent, {static: false})
  servicesAndInfraComponent: ServicesAndInfrastructuresComponent;

  @ViewChild(ExternalAspectsComponent, {static: false})
  externalAspectComponent: ExternalAspectsComponent;

  @ViewChild(InternalAspectsComponent, {static: false})
  internalAspectComponent: InternalAspectsComponent;

  @ViewChild(AcGroundsComponent, {static: true})
  acGroundsComponent: AcGroundsComponent;

  @ViewChild(AcDocumentUploadComponent, {static: false})
  acDocumentUploadComponent: AcDocumentUploadComponent;

  @ViewChild('buildingPic')
  public buildingFileComponent: UploadFileComponent;

  @ViewChild('simple')
  public uploadFileComponent: UploadFileComponent;
  @ViewChild(ConsiderationAndSourceComponent, {static: false})
  considerationAndSourceComponent: ConsiderationAndSourceComponent;
  @ViewChild(BuildingFormComponent, {static: false})
  buildingFormComponent: BuildingFormComponent;

  loading$: Observable<boolean>;

  ac: AssetClassParkingModel;

  centerLat = null;
  centerLng = null;
  points: {lat: number, lng: number}[] = [];

  formGroup: UntypedFormGroup;
  hasFormErrors: boolean = false;
  formErrorMsg$: BehaviorSubject<string> = new BehaviorSubject(null);
  formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
  isComplexForm: boolean = false;

  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  grades: Grade[] = [];
  coordinateReferenceSystems: CoordinateReferenceSystem[] = [];
  landAreaComponentListSubject = new BehaviorSubject<AssetClassLandAreaComponentModel[]>([]);
  landAreaSubject = new BehaviorSubject<AssetClassLandAreaModel>(new AssetClassLandAreaModel());
  subTypeCategories: SubTypeCategoryModel[] = [];
  subCategories: SubCategoryModel[] = [];
  filteredSubCategories: SubCategoryModel[] = [];
  parkingTypes: any[]  = [];
  buildingTypes: BuildingType[] = [];
  stateOfRepairs: StateRepair[] = [];
  handoverStandards: HandoverStandard[] = [];
  foundationTypes: FoundationType[] = [];

  tenuresSubject = new BehaviorSubject<AssetClassTenure[]>([])
  sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
  sizes$ = combineLatest([ 
    this.store.select(selectAllUnitAreaMeasurements),
    this.sizesSubject.asObservable()
  ]).pipe(
    map(([unitAreaMeasurements, sizes]) => {
      const items: AssetClassSize[] = sizes.map(size => ({
        id: size.id,
        uid: size.uid,
        size: size.size,
        standardMeasurementName: size.standard_measurement_name,
        unitAreaMeasurementAcronym: unitAreaMeasurements.find(item => item.id === size.unit_of_area_measurement_id)?.acronym
      }))
      return items
    })
  )
  sourceSubject = new BehaviorSubject<AcSource>(new AcSource());
  considerationListSubject = new BehaviorSubject<AssetClassConsiderationModel[]>([]);

  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  protected _onDestroy = new Subject<void>();
  private subscriptions: Subscription[] = [];
  public considerationError$ = new BehaviorSubject(false);
  private _hasParcelInfo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasParcelInfo$: Observable<boolean> = this._hasParcelInfo$.asObservable();
  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  tabHeaders: TabHeader[] = [
    {label: 'Location', disabled: of(false)},
    {label: 'Property & building information', disabled: of(false)},
    {label: 'Lease & sale information', disabled: of(false)},
    {label: 'Pictures & documents', disabled: of(false)},
  ];
  location_tab_items = ['land_parcel_name','coordinate_reference_system_id'];
  error:any = {
    msg: ' Missing Fields in total:',
    fields: []
  }

  buildingId: number = null;
  building_pictures: any[] = [];
  building_picture: string = null;

  markerInfo: {lat: number, lng: number, isShow: boolean} = {lat: undefined, lng: undefined, isShow: false};
  onMarkerChange(event) {
    if (event == null)  {
      return;
    }
    this.markerInfo = {
      lat: event.lat,
      lng: event.lng,
      isShow: event.isShown
    }
  }
  private valuationId: number | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ParkingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.valuationId = data.valuationId;
  }

  ngOnInit(): void {
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      const id = params.parking_id;
      if (id && id > 0) {
        this.activatedRoute.data.subscribe(data => {
          const res = data.data;
          this.ac = res.data;
          this.buildingId = this.ac.cp_building_id;
          this.building_pictures = this.ac.b_pictures;
          this.building_picture = this.ac.b_picture;
          this.isComplexForm = this.ac.formType == 1;
          this.points = this.ac.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}));
          this.landAreaComponentListSubject.next(this.ac.landAreaComponents);
          this.landAreaSubject.next(this.ac.landArea)
          const deepCopiedSizes = _deepCopy(this.ac.sizes)
            .map(size => {
              size.uid = uuidv4()
              return size
            })
          const deepCopiedTenures = _deepCopy(this.ac.tenures)
            .map(tenure => {
              const rentSizeUuid = deepCopiedSizes.find(size => size.id === tenure.rent_size_id)?.uid
              const expensesSizeUuid = deepCopiedSizes.find(size => size.id === tenure.expenses_size_id)?.uid
              tenure.rent_size_uid = rentSizeUuid
              tenure.expenses_size_uid = expensesSizeUuid
              return tenure
            })
          this.tenuresSubject.next(deepCopiedTenures)
          this.sizesSubject.next(deepCopiedSizes);
          this.sourceSubject.next(this.ac.source);
          this.markerInfo = {
            lat: this.ac.locationData.latitude,
            lng: this.ac.locationData.longitude,
            isShow: true
          }
          this._createForm();
        })
      } else {
        this.ac = new AssetClassParkingModel();
        this.ac.clear();
        this.points = this.ac.points;
        this.landAreaComponentListSubject.next(this.ac.landAreaComponents);
        this.landAreaSubject.next(this.ac.landArea);
        this.sizesSubject.next(this.ac.sizes);
        this.sourceSubject.next(this.ac.source);
        this._createForm();
      }
    })
    this.subscriptions.push(routeSubscription);

    this.loading$ = this.store.pipe(select(selectAssetClassParkingActionLoading));

    // Linked Tables
    this.store.dispatch(new AllUnitAreaMeasurementsRequested())
    this.store.dispatch(new AllCountriesRequested());
    this.store.dispatch(new AllCitiesRequested());
    const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
        this.allCountries = [];
        each(res, (_country: CountryModel) => {
            this.allCountries.push(_country);
        });
    });
    this.subscriptions.push(countrySubscription);

    this.store.dispatch(new AllGradesRequested());
    const gradeSubscription = this.store.pipe(
        select(selectAllGrades))
        .subscribe(res => {
            this.grades = [];
            if (res) {
                this.grades = res;
            }
        });
    this.subscriptions.push(gradeSubscription);

    this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
    const coordinateReferenceSystemsSubscription = this.store.pipe(
        select(selectAllCoordinateReferenceSystems),
    ).subscribe(res => {
        this.coordinateReferenceSystems = [];
        if (res) {
            this.coordinateReferenceSystems = res;
        }
    });
    this.subscriptions.push(coordinateReferenceSystemsSubscription);

    this.store.dispatch(new AllSubTypeCategorysRequested());
    const subTypeSub = this.store.pipe(
      select(selectAllSubTypeCategorys)
    ).subscribe(res => {
      this.subTypeCategories = res ? res.filter(item => item.property_sub_type_id == 2) : [];
    });
    this.subscriptions.push(subTypeSub);

    this.store.dispatch(new AllSubCategorysRequested());
    const subCatSub = this.store.select(selectAllSubCategorys)
      .subscribe(res => {
        this.subCategories = res ? res : [];
        this.filteredSubCategories = this._filterSubCategories(this.ac.sub_type_category_id);
      });
    this.subscriptions.push(subCatSub);

    this.store.dispatch(new AllStateRepairsRequested())
    const stateRepairSub = this.store.select(selectAllStateRepairs)
      .subscribe(res => {
        this.stateOfRepairs = res ? res : [];
      });
    this.subscriptions.push(stateRepairSub);

    this.store.dispatch(new AllHandoverStandardsRequested())
    const handoverSub = this.store.select(selectAllHandoverStandards)
      .subscribe(res => {
        this.handoverStandards = res ? res : []
      });
    this.subscriptions.push(handoverSub);

    this.store.dispatch(new AllFoundationTypesRequested())
    const foundationSub = this.store.select(selectAllFoundationTypes)
      .subscribe(res => {
        this.foundationTypes = res ? res : []
      });
    this.subscriptions.push(foundationSub);

    this.store.dispatch(new AllBuildingTypesRequested());
    const buildingTypeSub = this.store.select(selectAllBuildingTypes).subscribe(res => {
        this.buildingTypes = res ? res : [];
    });
    this.subscriptions.push(buildingTypeSub);

    this.store.dispatch(new AllParkingTypesRequested());
    const parkingTypeSub = this.store.select(selectAllParkingTypes).subscribe(res => {
      this.parkingTypes = res ? res : [];
    });
    this.subscriptions.push(parkingTypeSub);
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Actions 
   */
  cancel() {
    this.dialogRef.close()
  }
  onSubmit(isComplete: boolean) {
    let errorFields = [];
    this.hasFormErrors = false;
    this.showTitleRequiredError$.next(false);
    this.considerationError$.next(false);
    if (isComplete == false || this.isComplexForm == false) {
      this._changeValidation(isComplete);
    }

    if (this.mapLocationComponent) {
      if (isComplete && this.mapLocationComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
      }
    }

    const controls = this.formGroup.controls;
    if (this.formGroup.invalid && controls.add_info_on_land.value) {
      Object.keys(controls).filter(cName => this.location_tab_items.includes(cName)).forEach(cName => {
        if (!controls[cName].valid) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, cName];
          this.formErrorMsg$.next(`${cName} has error`);
        }
        controls[cName].markAsTouched();
      });
    }

    if (this.formGroup.invalid) {
      Object.keys(controls).filter(cName => !this.location_tab_items.includes(cName)).forEach(cName => {
        if (!controls[cName].valid) {
          errorFields = [...errorFields, cName];
          this.formErrorMsg$.next(`${cName} has error`);
        }
        controls[cName].markAsTouched();
      });
      this.hasFormErrors = true;
    }
    if (isComplete && this.sizesSubject.value.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'size'];
    }

    // Consideration
    if (isComplete && !this.considerationAndSourceComponent.validate()) {
      this.hasFormErrors = true;
      this.considerationError$.next(true);
      errorFields = [...errorFields, 'consideration'];
    }
    if (this.buildingFormComponent) {
      if (isComplete && this.buildingFormComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.buildingFormComponent.errorFields.map(f => 'building_form_' + f)];
      }
    }

    if (isComplete && this.uploadFileComponent.uploadFiles.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'pic'];
    } else {
      const uploadedFiles = this.uploadFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasFormErrors = true;
          this.showTitleRequiredError$.next(true);
          errorFields = [...errorFields, 'pic'];
        }
      })
    }
    if (isComplete && this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'b_pic'];
    } else if (this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length > 0) {
      const uploadedFiles = this.buildingFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasFormErrors = true;
          this.showTitleRequiredError$.next(true);
          errorFields = [...errorFields, 'b_pic'];
        }
      })
    }
    if (this.hasFormErrors) {
      this.error.fields = errorFields;
      return;
    }

    this.landAreaListComponent && this.landAreaListComponent.updateValue();
    const _ac = this._preparteAssetClass(isComplete);
    const dialogRef = this.dialog.open(ValuationCheckerModalComponent, {
      width: '400px',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == undefined) {
        return;
      }
      _ac.valuation_id = res ? this.valuationId : null;
      if (_ac.id) {
        this._updateAssetClass(_ac);
      } else {
        this._createAssetClass(_ac);
      }
    })
  }
  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormErrors = obj.hasFormErrors;
  }

  /**
   * Template functions
   */
  getComponentTitle(): string {
    if (this.ac && this.ac.id) {
      return `Edit Parking "${this.ac.ref_num}"`
    }
    return 'Add Parking'
  }

  /**
   * Methods
   */
  private _changeValidation(isComplete: boolean) {
    const controls = this.formGroup.controls;
    const mustCnames = ['country_id', 'city_id', 'location_grade_id', 'address', 'sub_type_category_id', 
      'sub_category_id', 'state_of_repair_id', 'parking_type_id', 'number_of_units', 'handover_standard_id'];
    Object.keys(controls).forEach(cName => {
      if (isComplete && mustCnames.filter(item => item == cName).length == 1) {
        return;
      }
      controls[cName].clearValidators();
      controls[cName].updateValueAndValidity();
    });
    if (this.externalAspectComponent) {
      this.externalAspectComponent.changeValidation();
    }
    if (this.internalAspectComponent) {
      this.internalAspectComponent.changeValidation();
    }
  }
  private _preparteAssetClass(isComplete: boolean): AssetClassParkingModel {
    const _ac = new AssetClassParkingModel();
    _ac.clear();
    _ac.id = this.ac.id;
    _ac.status = isComplete ? 1 : 0;
    _ac.formType = this.formSelectionCtrl.value;

    const controls = this.formGroup.controls;
    
    if (this.mapLocationComponent) {
      _ac.locationData = this.mapLocationComponent.getData();
    }

    _ac.add_info_on_land = controls.add_info_on_land.value;
    _ac.land_parcel_name = controls.land_parcel_name.value;
    _ac.coordinate_reference_system_id = controls.coordinate_reference_system_id.value;
    _ac.points = this.landParcelMapComponent ? this.landParcelMapComponent.getPath() : [];
    _ac.landArea = this.landAreaSubject.value;
    _ac.landAreaComponents = this.landAreaComponentListSubject.value;

    _ac.sub_type_category_id = controls.sub_type_category_id.value;
    _ac.sub_category_id = controls.sub_category_id.value;
    _ac.property_general_desc = controls.property_general_desc.value;
    _ac.num_of_units = controls.number_of_units.value;
    _ac.parking_type_id = controls.parking_type_id.value;
    _ac.floor_details = controls.floor_details.value && controls.floor_details.value.length > 0 
        ? controls.floor_details.value.join(',') 
        : '';
    _ac.automation = controls.automation.value;
    _ac.automation_type = controls.automation_type.value;
    _ac.state_of_repair_id = controls.state_of_repair_id.value;
    _ac.handover_standard_id = controls.handover_standard_id.value;

    _ac.cp_building_id = null;
    // _ac.building_name = controls.building_name.value;
    // _ac.completion_year = controls.completion_year.value;
    // _ac.is_part_of_property = controls.is_part_of_property.value;
    // _ac.info_about_property = controls.info_about_property.value;
    // _ac.building_type = controls.building_type.value;
    // _ac.building_type_comment = controls.building_type_comment.value;
    // _ac.building_grade_id = controls.building_grade_id.value;
    // _ac.energy_efficiency_grade_id = controls.energy_efficiency_grade_id.value;
    // _ac.developer = controls.developer.value;
    // _ac.anchor_tenant = controls.anchor_tenant.value;
    // _ac.foundation_type_id = controls.foundation_type_id.value;
    // _ac.building_description = controls.building_description.value;

    _ac.has_parcel_consideration = controls.has_parcel_consideration.value;
    _ac.land_comment = controls.land_comment.value;

    if (this.servicesAndInfraComponent) {
      _ac.serviceAndInfraData = this.servicesAndInfraComponent.getdata();
    }

    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
        if (value) {
          _ac.picture = value.success;
        }
    })
    _ac.sizes = this.sizesSubject.value;
    _ac.tenures = this.considerationAndSourceComponent ? this.considerationAndSourceComponent.getData() : [];
    _ac.source = this.sourceSubject.value;
    if (this.acDocumentUploadComponent) {
      _ac.documents = this.acDocumentUploadComponent.getDocuments();
    }

    if (this.buildingFileComponent) {
      this.buildingFileComponent.res$.pipe(take(1)).subscribe(value => {
          if (value) {
            _ac.b_picture = value.success;
          }
      });
      _ac.b_pictures = this.buildingFileComponent.uploadFiles;
    }
    if (this.buildingFormComponent) {
      _ac.buildingInfo = this.buildingFormComponent.getData();
    }

    return _ac;

  }
  private _createForm() {
    this.formSelectionCtrl.setValue(this.ac.formType);
    this.formSelectionCtrl.updateValueAndValidity();
    const formSelectionSubscription = this.formSelectionCtrl.valueChanges.subscribe(val => {
      if (val == 1) {
        this.isComplexForm = true;
      } else {
        this.isComplexForm = false;
      }
    });
    this.subscriptions.push(formSelectionSubscription);

    this.formGroup = this.formBuilder.group({
      add_info_on_land: [this.ac.add_info_on_land],
      land_parcel_name: [this.ac.land_parcel_name],
      coordinate_reference_system_id: [this.ac.coordinate_reference_system_id],

      sub_type_category_id: [this.ac.sub_type_category_id, Validators.required],
      sub_category_id: [this.ac.sub_category_id, Validators.required],
      number_of_units: [this.ac.num_of_units, Validators.required],
      parking_type_id: [this.ac.parking_type_id, Validators.required],
      state_of_repair_id: [this.ac.state_of_repair_id, Validators.required],
      handover_standard_id: [this.ac.handover_standard_id, Validators.required],
      floor_details: [this.ac.floor_details && this.ac.floor_details.length > 0 
          ? this.ac.floor_details.split(',')
          : null],
      automation: [this.ac.automation, Validators.required],
      automation_type: [this.ac.automation_type],
      property_general_desc: [this.ac.property_general_desc],

      has_parcel_consideration: [this.ac.has_parcel_consideration],
      land_comment: [this.ac.land_comment]
    });

    this.formGroup.controls.add_info_on_land.valueChanges.pipe(
      startWith(this.formGroup.controls.add_info_on_land.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      this._hasParcelInfo$.next(val);
      if (val) {
        this.formGroup.controls.land_parcel_name.setValidators([Validators.required]);
        this.formGroup.controls.coordinate_reference_system_id.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.land_parcel_name.clearValidators();
        this.formGroup.controls.coordinate_reference_system_id.clearValidators();
      }
      this.formGroup.controls.land_parcel_name.updateValueAndValidity();
      this.formGroup.controls.coordinate_reference_system_id.updateValueAndValidity();
    });

    this.formGroup.controls.sub_type_category_id.valueChanges.pipe(
      startWith(this.formGroup.controls.sub_type_category_id.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      this.filteredSubCategories = this._filterSubCategories(val)

      if (val == 20) {
        this.formGroup.controls.floor_details.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.floor_details.clearValidators();
      }
      this.formGroup.controls.floor_details.updateValueAndValidity();

      if (this.filteredSubCategories.length == 0) {
        this.formGroup.controls.sub_category_id.clearValidators();
      } else {
        this.formGroup.controls.sub_category_id.setValidators([Validators.required]);
      }
      this.formGroup.controls.sub_category_id.updateValueAndValidity();
    });
  }
  private _filterSubCategories(stcId: number) {
    if (stcId == null) {
      return [];
    }
    return this.subCategories.filter(item => item.sub_type_category_id == stcId);
  }
  private _updateAssetClass(ac: AssetClassParkingModel) {
    this.store.dispatch(new AssetClassParkingOnServerUpdated({
        AssetClassParking: ac,
        fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.dialogRef.close(true)
  }
  private _createAssetClass(ac: AssetClassParkingModel) {
    this.store.dispatch(new AssetClassParkingOnServerCreated({
        AssetClassParking: ac,
        fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.store.pipe(
      select(selectLastCreatedAssetClassParkingId),
      takeUntil(this._onDestroy),
      distinctUntilChanged(),
      switchMap(res => {
        if (!res) {
          return of(undefined)
        }
        this.store.dispatch(new UpdatePreviouslyCreatedACParkingId())
        return this.store.select(selectAssetClassParkingById(res))
      })
    ).subscribe(ac => {
      if (!ac) {
        return
      }
      this.dialogRef.close(ac)
    })
  }

  public get isParkingGarage() {
    return this.formGroup.controls.sub_type_category_id.value == 20;
  }
  onTabChanged($event) {
    const activeIndex = $event.index;
  }
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  changeTab(section: string) {
    const section_tab = [
      {sections: ['location', 'parcel'], tab_index: 0},
      {sections: ['prop'], tab_index: 1},
      {sections: ['consideration'], tab_index: 2},
      {sections: ['pic'], tab_index: 3},
    ]
    const active_Tab = section_tab.find(item => item.sections.includes(section));
    this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
    this.selectedtabchange.next(this.selectedTab);
  }
  erroTabMap() {
    return this.typesUtilsService.getCompTabErrorMap(this.isComplexForm, this.error.fields, this.tabHeaders, 2);
  }

  public clearBuilding() {
      this.buildingId = null;
      const controls = this.formGroup.controls;
      controls.building_name.setValue(null);
      controls.completion_year.setValue(null);
      controls.is_part_of_property.setValue(false);
      controls.info_about_property.setValue(null);
      controls.building_type.setValue(null);
      controls.building_type_comment.setValue(null);
      controls.building_grade_id.setValue(null);
      controls.energy_efficiency_grade_id.setValue(null);
      controls.developer.setValue(null);
      controls.anchor_tenant.setValue(null);
      controls.foundation_type_id.setValue(null);
      controls.building_description.setValue(null);
      this.formGroup.updateValueAndValidity();
      this.building_pictures = [];
      this.building_picture = null;
  }

  public showExist() {
      const dialogRef = this.dialog.open(BuildingInfoDialogComponent, {
          data: {
              standAloneBuilding: false,
              currentBuildingId: this.buildingId ? this.buildingId : -1,
              lat: this.centerLat,
              lng: this.centerLng,
          }
      });

      dialogRef.afterClosed().subscribe((res: any) => {
          if (!res) {
              return;
          }
          const controls = this.formGroup.controls;
          controls.building_name.setValue(res.building_name);
          controls.completion_year.setValue(res.completion_year);
          controls.is_part_of_property.setValue(res.is_part_of_property);
          controls.info_about_property.setValue(res.info_about_property);
          controls.building_type.setValue(res.building_type);
          controls.building_type_comment.setValue(res.building_type_comment);
          controls.building_grade_id.setValue(res.building_grade_id);
          controls.energy_efficiency_grade_id.setValue(res.energy_efficiency_grade_id);
          controls.developer.setValue(res.developer);
          controls.anchor_tenant.setValue(res.anchor_tenant);
          controls.foundation_type_id.setValue(res.foundation_type_id);
          controls.building_description.setValue(res.building_description);
          this.formGroup.updateValueAndValidity();
          this.buildingId = res.id;
          this.centerLat = res.locationData.latitude;
          this.centerLng = res.locationData.longitude;
          this.ac = Object.assign({}, this.ac, {
              locationData: {
                  ...this.ac.locationData,
                  latitude: (Number(this.centerLat)),
                  longitude: (Number(this.centerLng)),
                  address: res.locationData.address,
                  location_grade_id: res.locationData.location_grade_id,
                  zip_code: res.locationData.zip_code,
                  city_id: res.locationData.city_id,
                  location_surrounding: res.locationData.location_surrounding
              }
          });
          // this.centerChange.emit({lat: this.centerLat, lng: this.centerLng});
          this.building_pictures = res.pictures.map(p => ({...p, pic_type: 2}));
          this.building_picture = res.picture;
          // this.picChange.emit({pictures: this.pictures, picture: this.picture});
      });
  }

  onCenterChange(locationData) {
    this.centerLat = locationData.latitude;
    this.centerLng = locationData.longitude;
    this.ac = Object.assign({}, this.ac, {
      locationData: {
        ...this.ac.locationData,
        latitude: (Number(locationData.latitude)),
        longitude: (Number(locationData.longitude)),
        address: locationData.address,
        location_grade_id: locationData.location_grade_id,
        zip_code: locationData.zip_code,
        city_id: locationData.city_id,
        location_surrounding: locationData.location_surrounding
      }
    });
  }

    onPicChange(pics: {pictures: any[], picture: any}) {
      this.ac = Object.assign({}, this.ac, {
        b_pictures: pics.pictures,
        b_picture: pics.picture
      })
    }
}

function _deepCopy(arr: any[]): any[] {
  return arr.map(item => ({...item}));
}
