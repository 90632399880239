<div class="picture-container" 
  [ngClass]="{'img-selected': selected, 'img-unselected': !selected}"
  >
  <img [src]="pic.url" (click)="previewPicture(pic)" />
  <div class="selection-group">
    <div class="selection-left-side">
      <button mat-icon-button (click)="onButtonClick(1)" *ngIf="displayFeatured">
        <mat-icon class="selection-left">
          {{ selected_value == 1 
              ? 'radio_button_checked' 
              : 'radio_button_unchecked'
          }}
        </mat-icon>
      </button>
    </div>
    <div class="selection-right-side">
      <button *ngIf="selected_value != 1" mat-icon-button (click)="onButtonClick(2)">
        <mat-icon class="selection-right">
          {{ selected_value == 2 
              ? 'radio_button_checked' 
              : 'radio_button_unchecked'
          }}
        </mat-icon>
      </button>
    </div>
  </div>
</div>