// NGRX
import {Action} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AuditTrailModel} from '../_models/audit-trail.model';
import {AuditTrailParticipantModel} from '../_models/audit-trail-participant.model';
import { Update } from '@ngrx/entity';

export enum AuditTrailActionTypes {
    AuditTrailOnServerCreated = '[Edit Audit Trail Dialog] Audit Trail On Server Created',
    AuditTrailCreated = '[Edit Audit Trail Dialog] Audit Trail Created',

    AuditTrailOnServerUpdated = '[Edit Audit Trail] Audit Trail On Server Updated',
    AuditTrailUpdated = '[Edit Audit Trail] Audit Trail Update',

    AuditTrails = '[Audit Trail List] get all ',
    AuditTrailsPageRequested = '[Audit Trail List Page] Audit Trail Page Requested',
    AuditTrailsPageLoaded = '[Audit Trail API] Audit Trail Page Loaded',
    AuditTrailDeleted = '[AuditTrails List Page] One AuditTrail Deleted',
    AuditTrailsClear = '[AuditTrails API] AuditTrails Page Clear',

    // trash system
    AuditTrailOnServerRestored = '[AuditTrail Trash] AuditTrail On Server Restored',
    AuditTrailRestored = '[AuditTrail Trash] AuditTrail Restored',

    AuditTrailOnServerAdminRestored = '[AuditTrail Admin Trash] AuditTrail On Server Restored',
    AuditTrailAdminRestored = '[AuditTrail Admin Trash] AuditTrail Restored',

    AuditTrailDeletedFromTrash = '[AuditTrail Trash] AuditTrail deleted',
    AuditTrailDeletedFromAdminTrash = '[AuditTrail Admin Trash] AuditTrail deleted',

    AuditTrailTrash = 'AuditTrail Trashed',
    AuditTrailTrashFlushed = 'AuditTrail Trash Flushed',

    AuditTrailsPageCancelled = '[AuditTrails API] AuditTrails Page Cancelled',
    AuditTrailsPageToggleLoading = '[AuditTrails] AuditTrails Page Toggle Loading',
    AuditTrailActionToggleLoading = '[AuditTrails] AuditTrails Action Toggle Loading',
    AuditTrailGet = '[AuditTrail Object] AuditTrail Object Requested'
}

export class AuditTrailOnServerCreated implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailOnServerCreated;

    constructor(public payload: {
        item: AuditTrailModel,
        assignmentId: number,
        participants: AuditTrailParticipantModel[],
        fileList: any[]
    }) {
    }
}

export class AuditTrailGet implements Action {
    readonly type = AuditTrailActionTypes.AuditTrails;

    constructor(public payload: { id: number }) {
    }
}

export class AuditTrailOnServerUpdated implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailOnServerUpdated

    constructor(public payload: {
        item: AuditTrailModel,
        assignmentId: number,
        participants: AuditTrailParticipantModel[],
        fileList: any[]
    }) {
    }
}

export class AuditTrailUpdated implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailUpdated

    constructor(public payload: {item: Update<AuditTrailModel>}) {}
}

export class AuditTrailCreated implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailCreated;

    constructor(public payload: { item: AuditTrailModel }) {
    }
}

export class AuditTrailsPageRequested implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailsPageRequested;

    constructor(public payload: { page: QueryParamsModel, assignmentId: number }) {
    }
}

export class AuditTrailsPageLoaded implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailsPageLoaded;

    constructor(public payload: {
        items: AuditTrailModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class AuditTrailsPageCancelled implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailsPageCancelled;
}

export class AuditTrailsPageToggleLoading implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AuditTrailActionToggleLoading implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export class AuditTrailDeleted implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailDeleted;

    constructor(public payload: { id: number, trashDeleteDesc: string, userId: number }) {
    }
}


export class AuditTrailsClear implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailsClear;

    constructor() {
    }
}


// Trash

export class AuditTrailDeletedFromAdminTrash implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class AuditTrailDeletedFromTrash implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}


export class AuditTrailOnServerRestored implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AuditTrailRestored implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailRestored;

    constructor(public payload: { item: AuditTrailModel }) {
    }
}

export class AuditTrailOnServerAdminRestored implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AuditTrailAdminRestored implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailAdminRestored;

    constructor(public payload: { item: AuditTrailModel }) {
    }
}

export class AuditTrailTrashFlushed implements Action {
    readonly type = AuditTrailActionTypes.AuditTrailTrashFlushed;

    constructor(public payload: { assId: number }) {
    }
}

export type AuditTrailActions = AuditTrailOnServerCreated
    | AuditTrailOnServerCreated
    | AuditTrailGet
    | AuditTrailCreated
    | AuditTrailsPageRequested
    | AuditTrailsPageLoaded
    | AuditTrailsPageCancelled
    | AuditTrailsPageToggleLoading
    | AuditTrailActionToggleLoading
    | AuditTrailDeleted
    | AuditTrailsClear
    | AuditTrailDeletedFromAdminTrash
    | AuditTrailDeletedFromTrash
    | AuditTrailOnServerRestored
    | AuditTrailRestored
    | AuditTrailOnServerAdminRestored
    | AuditTrailAdminRestored
    | AuditTrailTrashFlushed
    | AuditTrailOnServerUpdated
    | AuditTrailUpdated;
