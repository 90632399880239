// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
// Models
import {environment} from '../../../../environments/environment';
import {BuildingModel} from '../_models/building.model';
import { map, tap } from 'rxjs/operators';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class BuildingService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/buildings`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    // Buildings
    getBuildings(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };

        return this.http.get<any>(this.API_URL, {
            headers: httpHeaders,
            params: params
        });
    }

    getStatus(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders()
        return this.http.get(this.API_URL + `/status`, {headers: httpHeaders})
    }

    getAllBuildings(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();

        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }


    getBuildingById(buildingId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/${buildingId}`, {headers: httpHeaders})
            .pipe(
                map(res => {
                    const data = res.data;
                    const b = new BuildingModel();
                    b.clear();
                    b.copy(data);
                    return b;
                })
            );
    }


    // CREATE =>  POST: add a new building to the server
    createBuilding(_building: BuildingModel, _files: any[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();

        return this.http.post(this.API_URL, {building: _building, files: _files}, {headers: httpHeaders});
    }


    // UPDATE => PUT: update the building on the server
    updateBuilding(_building: BuildingModel, _files: any[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();

        return this.http.put(this.API_URL + `/${_building.id}`, {
            building: _building,
            files: _files,
        }, {headers: httpHeaders});
    }

    // DELETE => delete the building from the server
    deleteBuilding(buildingId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_URL}/${buildingId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }


    // TRASH (User)
    getTrashedBuildings(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {headers: httpHeaders});
    }

    deleteFromTrash(buildingId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${buildingId}`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashedBuildings(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(buildingId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${buildingId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: httpHeaders});
    }

    // restore from trash
    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }

    getAllBuildingFacilities(buildingId: number): Observable<any> {
        const url = this.API_URL + '/' + buildingId + '/facilities';
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }
}
