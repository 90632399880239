import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassModel, selectTpTasksByTpId } from 'src/app/core/asset_class';
import { AssignmentModel } from 'src/app/core/assignment';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type ModuleData = {
  projectManager: User;
  leadValuer: User;
  inspection: User;
  valuer: User;
}

@Component({
  selector: 'kt-team-involvement-module',
  templateUrl: './team-involvement-module.component.html',
  styleUrls: ['../info-module.styles.scss', './team-involvement-module.component.scss']
})
export class TeamInvolvementModuleComponent implements OnInit {
  @Input() toe: ToeModel;
  @Input() targetProperty: AssetClassModel;
  @Input() assignment: AssignmentModel;
  @Input() workers: any[];
  @Input() usersData: any[];
  @Input() multimedia = false;
  data$: Observable<ModuleData>;
  awConst = awConst;
  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    public typesUtilsService: TypesUtilsService,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.store$.select(selectTpTasksByTpId(this.targetProperty.id)).pipe(map(val => val ? val : undefined), filter(val => val !== undefined)),
      this.activatedRoute.params.pipe(map(params => params.valuation_id ? params.valuation_id : this.targetProperty.valuations[0].id)),
      this.activatedRoute.data.pipe(map(res => (res.usersData && res.usersData.data) ? res.usersData.data : this.usersData), filter(val => val !== undefined))
    ]).pipe(map(([tasks, valId, toeUsers]) => {
      const valuationTasks = tasks.filter(t => t.task_id === 2);
      const valuationTask = valuationTasks.find(t => t.second_id == valId);

      const inspectionTask = tasks.find(t => t.task_id === 1);

      const projectManager = toeUsers.find(u => u.id === this.toe.assignment.valuer_id);
      const leadValuer = toeUsers.find(u => u.id === this.toe.leadValuer.worker_id);
      const inspection = toeUsers.find(u => u.id === inspectionTask.user_id);
      const valuer = toeUsers.find(u => u.id === valuationTask.user_id);

      const data: ModuleData = {
        projectManager,
        leadValuer,
        inspection,
        valuer,
      };
      return data;
    }));
  }

  showUserInfo(event, popover: NgbPopover, user: User) {
    popover.popoverClass = "my-popover";
    if (popover.isOpen()) {
      popover.close();
    } else {
      const role = this._getRoleInfo(user.id);
      popover.open({
          role,
          qualification: user.qualification_name,
          email: user.email,
          agency: this.assignment.agency_name,
          user_pic: user.picture && user.picture.length > 0 ? this._getUserImageUrl(user.picture) : './assets/media/users/default.jpg'
      })
    }
  }

  _getUserImageUrl(path: string): string {
    return environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + path;
  }

  _getRoleInfo(uId: number) {
    let roleInfo: string;
    if (this.toe.assignment.valuer_id === uId) {
        roleInfo = 'Project Manager';
        if (this._isWorker(uId)) {
            roleInfo += this._isLeadValuer(uId)
                ? ' & Lead Valuer'
                : ' & Supporting Staff';
        }
        return roleInfo;
    }
    roleInfo = this._isLeadValuer(uId)
        ? 'Lead Valuer'
        : 'Supporting Staff';
    return roleInfo;
  }

  _isWorker(uId: number): boolean {
    const worker = this.workers.filter(w => w.worker_id === uId);
    return worker.length > 0;
  }

  _isLeadValuer(uId: number): boolean {
      return this.workers[0].worker_id === uId;
  }

  _getType(url: string): string {
    if (url) {
      const type = this.typesUtilsService.getFileType(url);
      if (type === 'docx') {
        return 'Word'
      } else {
        return type.toUpperCase();
      }
    }
    return null;
  }
  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
