<form class="form" [formGroup]="formGroup" *ngIf="formGroup" [ngClass]="{'readonly': readonly}">
    <div class="form-group">
        <div class="row">
            <div class="col-4">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput type="text" placeholder="Template Name" formControlName="name">
                    <mat-error>Template Name <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput type="text" placeholder="Template Description" formControlName="description"></textarea>
                    <mat-error>Template Description <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div style="display: flex; justify-content: flex-end;">
                    <button *ngIf="!readonly" type="button" color="primary" class="btn btn-primary" (click)="addSerField()">
                        Add External Reference
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngFor="let control of serFormArray.controls; let i = index">
                <div class="row">
                    <mat-form-field class="col-11 mat-form-field-fluid">
                        <mat-select [placeholder]="'External Reference (' + (i + 1) + ')'" 
                                [formControl]="control"
                                (selectionChange)="onSelectionChange($event, i)">
                            <mat-option *ngFor="let sourceExternalReference of (sourceExternalRefs$|async)"
                                [disabled]="sourceExternalReference.disabled"
                                [value]="sourceExternalReference.id">
                                {{sourceExternalReference.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            External Reference
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <button type="button" [disabled]="readonly" mat-icon-button class="col-1" color="warn"
                            (click)="removeSerField(i)">
                        <mat-icon [matTooltip]="'Remove Field'">delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>