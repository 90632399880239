<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div class="form form-group-seperator-dashed">
        <!--<form [formGroup]="toeForm2" class="kt-form kt-form&#45;&#45;group-seperator-dashed">-->
        <form [formGroup]="form" class="form">
            <div class="card-body pt-0">
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-4">
                        <div class="row align-items-center container">
                            <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" *ngFor="let item of tp">
                                <mat-checkbox
                                    [checked]="setSelectedTps(item)"
                                    (change)="updateCheckedOptions(item, $event)"
                                >
                                    {{ item.name }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Conflict" formControlName="conflict_id">
                                <mat-option *ngFor="let conflict of conflicts" [value]="conflict.id">{{conflict.name}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Conflict
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.conflict_id.value > 1">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea required matInput rows="4" placeholder="Description" formControlName="conflict_descr">
                            </textarea>
                            <mat-error *ngIf="form.controls.conflict_descr.hasError('required')">Description
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.conflict_descr.hasError('minlength')">
                                It must be at least 20 characters long
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.conflict_id.value > 1">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea required matInput rows="4" placeholder="Mitigation Strategy" formControlName="conflict_descr_b">
                            </textarea>
                            <mat-error *ngIf="form.controls.conflict_descr_b.hasError('required')">Mitigation Strategy
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="form.controls.conflict_descr_b.hasError('minlength')">
                                It must be at least 20 characters long
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.conflict_id.value > 1">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Type of Conflict" formControlName="type_of_conflict_id">
                                <mat-option *ngFor="let typeOfConflict of typeOfConflicts" [value]="typeOfConflict.id">{{typeOfConflict.name}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Type of Conflict
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.conflict_id.value > 1">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   placeholder="Appropriate Parties"
                                   formControlName="appropriate_parties"/>
                            <mat-error *ngIf="form.controls.appropriate_parties.hasError('required')">Appropriate Parties
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.conflict_id.value > 1">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   placeholder="Reason to Continue"
                                   formControlName="reason_continue"/>
                            <mat-error *ngIf="form.controls.reason_continue.hasError('required')">Reason to Continue
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
                <button type="button" [disabled]="!isValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()"
                        matTooltip="Save Changes">
                    Save Changes
                </button>
            </div>
        </div>
    </div>
</div>
