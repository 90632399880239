// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AssetClassRetailActions, AssetClassRetailActionTypes} from '../_actions/asset-class-retail.actions';
// Models
import {AssetClassRetailModel} from '../_models/asset-class-retail.model';
import {QueryParamsModel} from '../../_base/crud';
import {AgencyActionTypes} from '../../admin/_actions/agency.actions';

export interface AssetClassRetailsState extends EntityState<AssetClassRetailModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastCreatedAssetClassRetailId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassRetailModel> = createEntityAdapter<AssetClassRetailModel>();

export const initialAssetClassRetailsState: AssetClassRetailsState = adapter.getInitialState({
    assetClassRetailForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastCreatedAssetClassRetailId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassRetailsReducer(state = initialAssetClassRetailsState, action: AssetClassRetailActions): AssetClassRetailsState {
    switch (action.type) {
        case AssetClassRetailActionTypes.AssetClassRetailsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassRetailId: undefined
            };
        }
        case AssetClassRetailActionTypes.AssetClassRetailActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AssetClassRetailActionTypes.AssetClassRetailOnServerCreated:
            return {
                ...state
            };
        case AssetClassRetailActionTypes.AssetClassRetailCreated:
            return adapter.addOne(action.payload.assetClassRetail, {
                ...state, lastCreatedAssetClassRetailId: action.payload.assetClassRetail.id
            });

        case AssetClassRetailActionTypes.RetailTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        case AssetClassRetailActionTypes.AssetClassRetailUpdated:
            return adapter.addOne(action.payload.assetClassRetail, {
                ...state, lastCreatedAssetClassRetailId: action.payload.assetClassRetail.id
            });
        case AssetClassRetailActionTypes.AssetClassRetailsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AssetClassRetailActionTypes.AssetClassRetailsPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.assetClassRetails, {
                ...initialAssetClassRetailsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case AssetClassRetailActionTypes.AssetClassRetailOnServerRestored:
            return {
                ...state
            };
        case AssetClassRetailActionTypes.AssetClassRetailRestored:
            return adapter.addOne(action.payload.assetClassRetail, {
                ...state,
                lastCreatedAssetClassRetailId: action.payload.assetClassRetail.id,
                totalTrashed: state.totalTrashed - 1
            });
        case AssetClassRetailActionTypes.AssetClassRetailDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1
            };
        case AssetClassRetailActionTypes.OneAssetClassRetailDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });
        case AssetClassRetailActionTypes.AssetClassRetailOnServerUpdated:
            return adapter.updateOne(action.payload.partialAssetClassRetail, state);
        default:
            return state;
    }
}

export const getAssetClassRetailState = createFeatureSelector<AssetClassRetailModel>('assetClassRetails');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
