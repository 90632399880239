import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BuiltinFittingsService, BuiltinFitting } from '../../../../../core/linked-tables';
import { LayoutUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';

@Component({
    selector: 'kt-builtin-fitting-add',
    templateUrl: './builtin-fitting-add-dialog.component.html',
    styleUrls: ['./builtin-fitting-add-dialog.component.scss']
})

export class BuiltinFittingAddDialogComponent implements OnInit, OnDestroy {

    builtinFitting: BuiltinFitting;
    builtinFittingForm: UntypedFormGroup;
    hasFormErrors = false;
    
    private componentSubscriptions: Subscription;
    
    /**
     * Component constructor
     *
     * @param dialogRef
     * @param data
     * @param dialog: MatDialog
     * @param location
     * @param fb
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param builtinFittingsService
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<BuiltinFittingAddDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public location: Location,
                private fb: UntypedFormBuilder,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private builtinFittingsService: BuiltinFittingsService,
                private store: Store<AppState>) {
    }
    
    /**
     * On init
     */
    ngOnInit() {
        this.builtinFitting = new BuiltinFitting();
        this.builtinFitting.clear();
        this.initBuiltinFitting();
    }
    
    initBuiltinFitting() {
        this.createForm();
    }
    
    createForm() {
        this.builtinFittingForm = this.fb.group({
            name: [this.builtinFitting.name, Validators.required]
        });
    }
    
    /**
     * Returns prepared assignment
     */
    prepareBuiltinFitting(): BuiltinFitting {
        const controls = this.builtinFittingForm.controls;
        const _builtinFitting = new BuiltinFitting();
        _builtinFitting.clear();
        _builtinFitting.id = this.builtinFitting.id;
        _builtinFitting.name = controls.name.value;
        _builtinFitting.property_sub_type_id = this.data.property_sub_type_id;
    
        return _builtinFitting;
    }
    
    createBuiltinFitting() {
        this.hasFormErrors = false;
        const controls = this.builtinFittingForm.controls;
        /** check form */
        if (this.builtinFittingForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
    
            this.hasFormErrors = true;
            return;
        }
    
        const _builtinFitting = this.prepareBuiltinFitting();
    
        this.builtinFittingsService.createBuiltinFitting(_builtinFitting).subscribe((res) => {
                if (res.data) {
                    this.dialogRef.close({builtinFitting: res.data});
                    return;
                }
            }
        );
    }
    
    isFormValid() {
        const controls = this.builtinFittingForm.controls;
    
        return (controls.name.value);
    }
    
    close() {
        this.dialogRef.close();
    }
    
    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }
}
