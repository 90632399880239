// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AssetClassValuationGroupActions, AssetClassValuationGroupActionTypes} from '../_actions/asset-class-valuation-group.actions';
// Models
import {AssetClassValuationGroup} from '../_models/asset-class-valuation-group.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AssetClassValuationGroupsState extends EntityState<AssetClassValuationGroup> {
    isAllAssetClassValuationGroupsLoaded: boolean;
    queryRowsCount: number;
    queryResult: AssetClassValuationGroup[];
    lastCreatedAssetClassValuationGroupId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassValuationGroup> = createEntityAdapter<AssetClassValuationGroup>();

export const initialAssetClassValuationGroupsState: AssetClassValuationGroupsState = adapter.getInitialState({
    isAllAssetClassValuationGroupsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAssetClassValuationGroupId: undefined,
    listLoading: false,
    actionLoading: false,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassValuationGroupsReducer(state = initialAssetClassValuationGroupsState, action: AssetClassValuationGroupActions): AssetClassValuationGroupsState {
    switch (action.type) {
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassValuationGroupId: undefined
            };
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupOnServerCreated:
            return {
                ...state
            };
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupCreated:
            return adapter.addOne(action.payload.assetClassValuationGroup, {
                ...state, lastCreatedAssetClassValuationGroupId: action.payload.assetClassValuationGroup.id
            });
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupUpdated:
            return adapter.updateOne(action.payload.partialassetClassValuationGroup, state);
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state
            });
        case AssetClassValuationGroupActionTypes.AllAssetClassValuationGroupsLoaded:
            return adapter.addAll(action.payload.assetClassValuationGroups, {
                ...state, isAllAssetClassValuationGroupsLoaded: true
            });
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case AssetClassValuationGroupActionTypes.AssetClassValuationGroupsPageLoaded:
            return adapter.addMany(action.payload.assetClassValuationGroups, {
                ...initialAssetClassValuationGroupsState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.assetClassValuationGroups,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
