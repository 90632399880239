import { Component, Input, OnInit } from '@angular/core';

export type MilestoneModuleV2ViewModel = {
  inspectionType: string,
  inspectionDate: Date,
  draftDate: Date,
  finalDate: Date,
  toeTimezone: string,
  propertyTimezone: string,
}

@Component({
  selector: 'kt-milestone-module-v2',
  templateUrl: './milestone-module-v2.component.html',
  styleUrls: [
    './milestone-module-v2.component.scss',
    '../info-module.styles.scss'
  ]
})
export class MilestoneModuleV2Component implements OnInit {
  @Input() viewModel: MilestoneModuleV2ViewModel

  constructor() { }

  ngOnInit(): void {}
}
