import { Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToeModel } from 'src/app/core/toe';
import { AbortToeAmendment, Amendment, AmendmentType, RemoveTPAmendment, ToeAmendmentModel } from 'src/app/core/toe/_models/toe-amendment.model';
import { AbortToeAmendmentService } from 'src/app/core/toe/_services/abort-toe-amendment.service';
import { RemoveTpAmendmentService } from 'src/app/core/toe/_services/remove-tp-amendment.service';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { AmendmentAction, AmendmentComponent } from './amendment-dialog.types';
import { TimezoneService } from 'src/app/core/system-settings/timezone.service';

export interface AmendmentDialogInputData {
  toe: ToeModel
}

@Component({
  selector: 'kt-amendment-dialog-v2',
  templateUrl: './amendment-dialog-v2.component.html',
  styleUrls: ['./amendment-dialog-v2.component.scss'],
  providers: [
    RemoveTpAmendmentService,
    AbortToeAmendmentService
  ]
})
export class AmendmentDialogV2Component implements OnInit {
  @ViewChildren('amendmentComp') amendmentComps: QueryList<AmendmentComponent>;
  @ViewChild(NgbDropdown) dropDown: NgbDropdown;

  public title: string;
  public amendmentType = AmendmentType;
  public form: UntypedFormGroup;
  public amendmentActions$: Observable<AmendmentAction[]>; 
  public amendments$: BehaviorSubject<Amendment[]> = new BehaviorSubject([]);
  public error$: BehaviorSubject<string> = new BehaviorSubject(null);

  private _amendmentActions$: BehaviorSubject<AmendmentAction[]> = new BehaviorSubject([
    {type: AmendmentType.RemoveTP, name: 'Remove Target Property', disabled: false},
    {type: AmendmentType.AbortToe, name: 'Abort ToE', disabled: false},
    {type: AmendmentType.None, name: 'More coming soon', disabled: true}
  ]);
  private order = 0;
  private amendments: Amendment[] = [];
  public timezone: string | null


  constructor(
    private dialogRef: MatDialogRef<AmendmentDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: AmendmentDialogInputData,
    private formBuilder: UntypedFormBuilder,
    public typesUtilsService: TypesUtilsService,
    private removeTpAmendmentService: RemoveTpAmendmentService,
    private abortToeAmendmentService: AbortToeAmendmentService,
  ) { 
    this.title = this.data.toe.name;
    this.timezone = this.data.toe.timezone
  }

  ngOnInit(): void {
    this.removeTpAmendmentService.getTps(this.data.toe.id);
    this.abortToeAmendmentService.getAmendment(this.data.toe.id);
    this.amendmentActions$ = combineLatest([
      this._amendmentActions$,
      this.removeTpAmendmentService.RemoveTpButtonStatus$,
      this.abortToeAmendmentService.ButtonStatus$
    ]).pipe(map(([actions, removeTpBtnStatus, abortToeBtnStatus]) => {
      return actions.map(action => {
        if (action.type === AmendmentType.RemoveTP) {
          return {...action, disabled: !removeTpBtnStatus || !abortToeBtnStatus} 
        }
        if (action.type == AmendmentType.AbortToe) {
          return {...action, disabled: !abortToeBtnStatus}
        }
        return action;
      })
    }));
    this.form = this.formBuilder.group({
      amendment_date: [new Date(), Validators.required]
    });
  }

  /**
   * Dialog Actions
   */
  public cancel() {
    this.dialogRef.close();
  }
  public validate() {
    this.error$.next(null);
    let hasErrors = false;
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        if (!controls[controlName].valid) {
          hasErrors = true;
        }
        controls[controlName].markAsTouched();
      })
    }

    if (this.amendmentComps.length == 0) {
      this.error$.next('At least one amendment is required');
      return;
    }

    this.amendmentComps.forEach(component => {
      if (!component.checkValid()) {
        hasErrors = true;
      }
    })

    if (hasErrors) {
      return;
    }
    const amendment = this.prepareToeAmendment();
    this.dialogRef.close({
      amendment
    });

  }

  /**
   * Component Methods
   */
  private prepareToeAmendment(): ToeAmendmentModel {
    this.fetchData();
    const _amendment = new ToeAmendmentModel(this.data.toe.id);
    _amendment.id = undefined;
    const _date = new Date(this.form.controls.amendment_date.value);
    _amendment.amendment_date = this.form.controls.amendment_date.value 
        ? this.typesUtilsService.getDateStringFromDate(_date)
        : null;
    let order = 0;
    _amendment.amendments = this.amendments.map(a => {
      order += 1;
      a.setOrder(order);
      if (a.id == undefined) {
        a.id = null;
      }
      return a;
    });
    return _amendment;
  }

  public actionClick(action: AmendmentAction) {
    this.dropDown.close();
    this.fetchData();
    switch (action.type) {
      case AmendmentType.RemoveTP: {
        this.order += 1;
        const amendment = new RemoveTPAmendment(action.type, undefined, this.order, this.data.toe.id);
        this.amendments.push(amendment);
        this.amendments$.next(this.amendments);
        this.removeTpAmendmentService.amendmentIncreased();
        return;
      }
      case AmendmentType.AbortToe: {
        this.order += 1;
        const amendment = new AbortToeAmendment(action.type, undefined, this.order, this.data.toe.id);
        this.amendments = []
        this.amendments.push(amendment);
        this.amendments$.next(this.amendments);
        this.abortToeAmendmentService.amendmentAdded();
        return;
      }
      case AmendmentType.None:
        return;
      default: 
        return;
    }
  }

  public clearDate(control) {
    control.setValue(null);
    control.updateValueAndValidity();
  }

  public onAlertClose() {
    this.error$.next(null);
  }

  private fetchData() {
    if (this.amendmentComps.length == 0) {
      return;
    }
    this.amendmentComps.forEach(comp => {
      const newAmendment = comp.fetchData();
      const oldAmendmentIndx = this.amendments.findIndex(a => a.order == newAmendment.order);
      if (oldAmendmentIndx > -1) {
        this.amendments.splice(oldAmendmentIndx, 1, newAmendment);
      }
    })
  }

  public onAmendmentRemove(removingAmendment: Amendment) {
    const indx = this.amendments.findIndex(a => a.order == removingAmendment.order);
    if (indx == -1) {
      return;
    }
    this.fetchData();
    switch (removingAmendment.type) {
      case AmendmentType.RemoveTP: {
        const removeTpAmendment = (removingAmendment as RemoveTPAmendment);
        this.amendments.splice(indx, 1);
        this.amendments$.next(this.amendments);
        break;
      }
      case AmendmentType.AbortToe: {
        const abortToeAmendment = (removingAmendment as AbortToeAmendment);
        this.amendments.splice(indx, 1);
        this.amendments$.next(this.amendments);
        break;
      }
      case AmendmentType.None:
        return;
    }
  }
}
