import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassRetailBuildingModel } from "../_models/asset-class-retail-building.model";
import { AssetClassRetailBuildingState } from "../_reducers/asset-class-retail-building.reducers";

export const selectAssetClassRetailBuildingState = createFeatureSelector<AssetClassRetailBuildingState>('assetClassRetailBuildings');
export const selectAssetClassRetailBuildingPageLoading = createSelector(selectAssetClassRetailBuildingState, state => state.listLoading);
export const selectAssetClassRetailBuildingActionLoading = createSelector(selectAssetClassRetailBuildingState, state => state.actionLoading);
export const selectAssetClassRetailBuildingShowInitWaitingMessage = createSelector(selectAssetClassRetailBuildingState, state => state.showInitWaitingMessage);
export const selectAssetClassRetailBuildingLastQuery = createSelector(selectAssetClassRetailBuildingState, state => state.lastQuery);
export const selectTrashedAssetClassRetailBuildingCount = createSelector(selectAssetClassRetailBuildingState, state => state.totalTrashed);
export const selectAdminTrashedAssetClassRetailBuildingCount = createSelector(selectAssetClassRetailBuildingState, state => state.totalAdminTrashed);
export const selectAssetClassRetailBuildingById = (id: number) => createSelector(selectAssetClassRetailBuildingState, state => state.entities[id])

export const selectAssetClassRetailBuildingInStore = createSelector(
    selectAssetClassRetailBuildingState,
    state => {
        const items: AssetClassRetailBuildingModel[] = [];
        each(state.entities, el => {
            items.push(el);
        });

        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassRetailBuildingModel[] = [];
        switch (state.lastQuery.sortField) {
            default: 
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                )
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
)

export const selectLastCreatedAssetClassRetailBuildingId = createSelector(
    selectAssetClassRetailBuildingState,
    state => {
        if (state.previouslyCreatedACId != state.lastCreatedAssetClassRetailBuildingId) {
            return state.lastCreatedAssetClassRetailBuildingId
        }
        return undefined
    } 
);