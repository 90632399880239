// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectUnitAreaMeasurementsQueryResult, selectUnitAreaMeasurementsPageLoading, selectUnitAreaMeasurementsShowInitWaitingMessage} from '../_selectors/unit-area-measurement.selectors';

export class UnitAreaMeasurementsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectUnitAreaMeasurementsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectUnitAreaMeasurementsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectUnitAreaMeasurementsQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
