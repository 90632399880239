// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SourceCredibilityService} from '../_services/source-credibility.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSourceCredibilityLoaded} from '../_selectors/source-credibility.selectors';
// Actions
import {
    AllSourceCredibilityLoaded,
    AllSourceCredibilityRequested,
    SourceCredibilityActionTypes,
    SourceCredibilityPageRequested,
    SourceCredibilityPageLoaded,
    SourceCredibilityUpdated,
    SourceCredibilityPageToggleLoading,
    SourceCredibilityDeleted,
    SourceCredibilityOnServerCreated,
    SourceCredibilityCreated,
    SourceCredibilityAdminRestored,
    SourceCredibilityDeletedFromAdminTrash,
    SourceCredibilityDeletedFromTrash,
    SourceCredibilityOnServerAdminRestored,
    SourceCredibilityOnServerRestored,
    SourceCredibilityRestored,
    SourceCredibilityTrashFlushed,
    SourceCredibilityActionToggleLoading
} from '../_actions/source-credibility.actions';

@Injectable()
export class SourceCredibilityEffects {
    showPageLoadingDispatcher = new SourceCredibilityPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SourceCredibilityActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SourceCredibilityActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSourceCredibility$ = this.actions$
        .pipe(
            ofType<AllSourceCredibilityRequested>(SourceCredibilityActionTypes.AllSourceCredibilityRequested),
            withLatestFrom(this.store.pipe(select(allSourceCredibilityLoaded))),
            filter(([action, isAllSourceCredibilityLoaded]) => !isAllSourceCredibilityLoaded),
            mergeMap(() => this.service.getAllSourceCredibility()),
            map(res => {
                return new AllSourceCredibilityLoaded({sourceCredibility: res.data});
            })
        );

    @Effect()
    loadSourceCredibilityPage$ = this.actions$
        .pipe(
            ofType<SourceCredibilityPageRequested>(SourceCredibilityActionTypes.SourceCredibilityPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findSourceCredibility(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SourceCredibilityPageLoaded({
                    sourceCredibility: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityDeleted>(SourceCredibilityActionTypes.SourceCredibilityDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteSourceCredibility(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityUpdated>(SourceCredibilityActionTypes.SourceCredibilityUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateSourceCredibility(payload.sourceCredibility);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityOnServerCreated>(SourceCredibilityActionTypes.SourceCredibilityOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createSourceCredibility(payload.sourceCredibility).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceCredibilityCreated({sourceCredibility: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushSourceCredibilityTrash$ = this.actions$
        .pipe(
            ofType<SourceCredibilityTrashFlushed>(SourceCredibilityActionTypes.SourceCredibilityTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityOnServerRestored>(SourceCredibilityActionTypes.SourceCredibilityOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceCredibilityRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityOnServerAdminRestored>(SourceCredibilityActionTypes.SourceCredibilityOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceCredibilityAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityDeletedFromTrash>(SourceCredibilityActionTypes.SourceCredibilityDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSourceCredibility$ = this.actions$
        .pipe(
            ofType<SourceCredibilityDeletedFromAdminTrash>(SourceCredibilityActionTypes.SourceCredibilityDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: SourceCredibilityService, private store: Store<AppState>) {
    }
}
