import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectValuationProcessLoadingState, ValuationProcessTargetPropertyModel, ValuationProcessValuationModel } from 'src/app/core/valuation-process';
import { MilestoneModuleV2ViewModel } from '../milestone-module-v2/milestone-module-v2.component';
import { TeamInvolvementModuleViewModel } from '../team-involvement-module-v2/team-involvement-module-v2.component';
import { ValuationModuleV2ViewModel } from '../valuation-module-v2/valuation-module-v2.component';
import { PropertySpecificsModuleViewModel } from '../property-specifics-module-v2/property-specifics-module-v2.component'
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { ToeModel } from 'src/app/core/toe';
import { AssignmentModel } from 'src/app/core/assignment';
import { AssetClassModel } from 'src/app/core/asset_class';
import { map, take  } from 'rxjs/operators';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
  selector: 'kt-background-tab-v2',
  templateUrl: './background-tab-v2.component.html',
  styleUrls: ['./background-tab-v2.component.scss']
})
export class BackgroundTabV2Component implements OnInit, OnChanges {
  @Input() targetProperty: ValuationProcessTargetPropertyModel
  @Input() valuation: ValuationProcessValuationModel
  @Input() readonly: boolean = false;
  @Input() toe: ToeModel
  @Input() assignment: AssignmentModel
  @Input() workers: any[]
  @Input() fullTp: AssetClassModel;

  loading$ = this.store$.select(selectValuationProcessLoadingState)

  mileStoneModuleData: MilestoneModuleV2ViewModel
  valuationModuleData: ValuationModuleV2ViewModel
  teamInvolvementModuleData: TeamInvolvementModuleViewModel
  propertySpecificsModuleData: PropertySpecificsModuleViewModel

  currentUser: User;

  constructor(
    private store$: Store<AppState>,
    private smUtility: StandardMeasurementUtility
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.store$.pipe(take(1), select(currentUser)).subscribe(user => this.currentUser = user);
    this.mileStoneModuleData = {
      inspectionType: this.targetProperty.inspectionType,
      inspectionDate: this.targetProperty.inspectionDate,
      draftDate: this.targetProperty.draftDate,
      finalDate: this.targetProperty.finalDate,
      toeTimezone: this.toe.timezone,
      propertyTimezone: this.targetProperty.timezone
    }

    this.valuationModuleData = {
      client: this.valuation.client,
      purposeOfValuation: this.valuation.purposeOfValuationName,
      baseOfValue: this.valuation.baseOfValueName,
      approachToValue: this.valuation.approachesToValueName,
      methodToValue: this.valuation.methodsToValueName,
      premiseOfValue: this.valuation.premiseOfValueName,
      tenure: this.valuation.tenureName
    }

    this.teamInvolvementModuleData = {
      projectManager: this.valuation.team.projectManager,
      leadValuer: this.valuation.team.leadValuer,
      inspection: this.valuation.team.inspection,
      valuer: this.valuation.team.valuer
    }

    this.propertySpecificsModuleData = this._mapTargetPropertyToPropertySpecificsViewModel(this.targetProperty)
  }

  private _mapTargetPropertyToPropertySpecificsViewModel(tp: ValuationProcessTargetPropertyModel): PropertySpecificsModuleViewModel {
    switch (tp.generalInfo.propertySubType) {
      case 'Apartment':  {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'Apartment',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
          bedroom: tp.generalInfo.bedroom,
          selectedExternalAreas: tp.generalInfo.selectedExternalArea,
          buildingGrade: tp.buildingInfo.buildingGrade,
          buildingType: tp.buildingInfo.buildingType
        }
      }
      case 'House': {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'House',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
          propertyGrade: tp.generalInfo.propertyGrade
        }
      }
      case 'Office': {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'Office',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
          selectedExternalAreas: tp.generalInfo.selectedExternalArea,
          buildingGrade: tp.buildingInfo.buildingGrade,
          buildingType: tp.buildingInfo.buildingType
        }
      }
      case 'Retail Shop': {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'Retail Shop',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
          selectedExternalAreas: tp.generalInfo.selectedExternalArea,
          buildingGrade: tp.buildingInfo.buildingGrade,
          buildingType: tp.buildingInfo.buildingType
        }
      }
      case 'Retail Building': {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'Retail Building',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
          propertyGrade: tp.generalInfo.propertyGrade
        }
      }
      case 'Parking': {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'Parking',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
        }
      }
      case 'Warehouse': {
        const hasSizeTable = tp.generalInfo.measurementStandardId >= 1 && tp.instructedToMeasure && this.smUtility.checkHasOwnTable(tp.generalInfo.measurementStandardId)
        return {
          kind: 'Warehouse',
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          subTypeCategory: tp.generalInfo.subTypeCategoryName,
          subCategory: tp.generalInfo.subCategoryName,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
          latitude: tp.generalInfo.locationData.latitude,
          longitude: tp.generalInfo.locationData.longitude,
          locationGrade: tp.generalInfo.locationGrade,
          measurementStandard: this._getStandardMeasurementName(tp.generalInfo.measurementStandard),
          measurementStandardModel: tp.generalInfo.measurementStandardModel,
          size: {
            value: tp.generalInfo.size,
            unitAreaMeasurement: tp.generalInfo.unitAreaMeasurementAcronym,
            isClickable: hasSizeTable,
            unitAreaMeasurementModel: tp.generalInfo.unitAreaMeasurementModel,
          }, 
          accommodation: tp.generalInfo.accommodation,
          floorNumberinSchemeId: tp.generalInfo.floorNumberingSchemeId,
          propertyGrade: tp.generalInfo.propertyGrade
        }
      }
      case 'Land': {
        return {
          kind: 'Land',
          locationGrade: tp.generalInfo.locationGrade,
          coordinateReferenceSystem: tp.generalInfo.coordinateReferenceSystemName,
          landParcelNum: tp.generalInfo.totalLandParcel,
          totalLandArea: tp.generalInfo.totalLandArea,
          unitMeasurement: tp.generalInfo.unitMeasurement,
          planningStatus: tp.generalInfo.planningStatusName,
          numOfBuilding: tp.generalInfo.numOfBuilding,
          targetPropertyId: tp.id,
          propertyType: tp.generalInfo.propertyTypeName,
          propertySubType: tp.generalInfo.propertySubTypeName,
          longitude: tp.generalInfo.locationData.longitude,
          latitude: tp.generalInfo.locationData.latitude,
          address: `${tp.generalInfo.locationData.address} - ${tp.generalInfo.locationData.cityName} - ${(tp.generalInfo.locationData.zipCode ? tp.generalInfo.locationData.zipCode + ' - ' : '')} ${tp.generalInfo.locationData.countryName}`,
        }
      }
    }
  }

  private _getStandardMeasurementName(name: string): string {
    if (name.indexOf('-') == -1) {
      return name
    }
    return name.substring(0, name.indexOf('-'))
  }

}
