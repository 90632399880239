// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {PropertyTypesService} from '../_services/property-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allPropertyTypesLoaded} from '../_selectors/property-type.selectors';
// Actions
import {
    AllPropertyTypesLoaded,
    AllPropertyTypesRequested,
    PropertyTypeActionTypes,
    PropertyTypesPageRequested,
    PropertyTypesPageLoaded,
    PropertyTypeUpdated,
    PropertyTypesPageToggleLoading,
    PropertyTypeDeleted,
    PropertyTypeOnServerCreated,
    PropertyTypeCreated,
    PropertyTypeActionToggleLoading, PropertyTypeTrashFlushed, PropertyTypeOnServerRestored, PropertyTypeRestored,
    PropertyTypeOnServerAdminRestored, PropertyTypeAdminRestored, PropertyTypeDeletedFromTrash, PropertyTypeDeletedFromAdminTrash
} from '../_actions/property-type.actions';

@Injectable()
export class PropertyTypeEffects {
    showPageLoadingDispatcher = new PropertyTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PropertyTypeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PropertyTypeActionToggleLoading({isLoading: false});

    @Effect()
    loadAllPropertyType$ = this.actions$
        .pipe(
            ofType<AllPropertyTypesRequested>(PropertyTypeActionTypes.AllPropertyTypesRequested),
            withLatestFrom(this.store.pipe(select(allPropertyTypesLoaded))),
            filter(([action, isAllPropertyTypeLoaded]) => !isAllPropertyTypeLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllPropertyTypesLoaded({items: res.data});
            })
        );

    @Effect()
    loadPropertyTypePage$ = this.actions$
        .pipe(
            ofType<PropertyTypesPageRequested>(PropertyTypeActionTypes.PropertyTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PropertyTypesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updatePropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeUpdated>(PropertyTypeActionTypes.PropertyTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createPropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeOnServerCreated>(PropertyTypeActionTypes.PropertyTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new PropertyTypeCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushPropertyTypeTrash$ = this.actions$
        .pipe(
            ofType<PropertyTypeTrashFlushed>(PropertyTypeActionTypes.PropertyTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeOnServerRestored>(PropertyTypeActionTypes.PropertyTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PropertyTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminPropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeOnServerAdminRestored>(PropertyTypeActionTypes.PropertyTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PropertyTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deletePropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeDeleted>(PropertyTypeActionTypes.PropertyTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashPropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeDeletedFromTrash>(PropertyTypeActionTypes.PropertyTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashPropertyType$ = this.actions$
        .pipe(
            ofType<PropertyTypeDeletedFromAdminTrash>(PropertyTypeActionTypes.PropertyTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: PropertyTypesService, private store: Store<AppState>) {
    }
}
