import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { MapsService } from 'src/app/core/map/maps.service';

@Component({
  selector: 'kt-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss']
})
export class MapModalComponent implements OnInit {
  latitude: number;
  longitude: number;
  triggerElementRef: ElementRef;
  icon$ = this.mapsService.icons$
  label$ = this.mapsService.label$

  constructor(
    public dialogRef: MatDialogRef<MapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mapsService: MapsService
  ) { 
    this.latitude = Number(this.data.latitude);
    this.longitude = Number(this.data.longitude);
    this.triggerElementRef = data.trigger;
  }

  ngOnInit(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();

    if(this._isPossibleToPutOnTop(rect)) {
      matDialogConfig.position = {
        bottom: `${window.innerHeight - (rect.top - 30)}px`,
        left: `${rect.left + (rect.width / 2) - 400}px`
      };
    } else {
      matDialogConfig.position = {
        top: `${(rect.bottom + 20)}px`,
        left: `${rect.left + (rect.width / 2) - 400}px`
      }
    }

    this.dialogRef.updatePosition(matDialogConfig.position);
  }

  _isPossibleToPutOnTop(rect): boolean {
    if (rect.top < (470)) {
      return false;
    }
    return true;
  } 
}
