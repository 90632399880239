import { BaseTemplateDataModel } from ".";

export class TeamTemplateModel implements BaseTemplateDataModel {
    id: number;
    name: string;
    description: string;

    members: ITeamMemberTemplate[]

    created_at: Date;
    updated_at: Date;
    clear() {
        this.id = undefined;
        this.name = null;
        this.description = null;

        this.members = [];
    }
}

export interface ITeamMemberTemplate {
    id: number;
    template_id: number;
    worker_id: number;
    lead_valuer: number;
    position: string;
    daily_rate: number;
    days: number;
    total_price: number;
    worker?: any;
    assigned_default_tasks: Array<number>
    // assigned_default_task_id: number;
    // assigned_default_task_name: string;
}