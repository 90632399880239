import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AdjustmentTemplateModel } from "..";
import { LoadAdjustmentTemplates, AdjustmentTemplateActionTypes, AdjustmentTemplatesLoaded } from "../_actions";
import { AddAdjustmentTemplate, DeleteAdjustmentTemplate, DuplicateAdjustmentTemplate, AdjustmentTemplateAdded, AdjustmentTemplateUpdated, UpdateAdjustmentTemplate } from "../_actions";
import { AdjustmentTemplateService } from "../_services";

@Injectable()
export class AdjustmentTemplateEffects {
    constructor(
        private actions: Actions,
        private service: AdjustmentTemplateService
    ) {}

    @Effect()
    loadAdjustmentTemplate$ =
        this.actions.pipe(
            ofType<LoadAdjustmentTemplates>(AdjustmentTemplateActionTypes.LOAD_ADJUSTMENT_TEMPLATES),
            mergeMap(({payload}) => {
                return this.service.getAll(payload.request)
            }),
            map(res => {
                return new AdjustmentTemplatesLoaded({
                    templates: res.templates,
                    total: res.total
                });
            })
        )

    @Effect()
    addAdjustmentTemplate$ =
        this.actions.pipe(
            ofType<AddAdjustmentTemplate>(AdjustmentTemplateActionTypes.ADD_ADJUSTMENT_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.create(payload.template)
            }),
            map(res => {
                return new AdjustmentTemplateAdded({
                    template: res
                })
            })
        )

    @Effect()
    updateAdjustmentTemplate$ =
        this.actions.pipe(
            ofType<UpdateAdjustmentTemplate>(AdjustmentTemplateActionTypes.UPDATE_ADJUSTMENT_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.update(payload.id, payload.template)
            }),
            map(res => {
                const newTemplate: Update<AdjustmentTemplateModel> = {
                    id: res.id,
                    changes: {
                        ...res
                    }
                };
                return new AdjustmentTemplateUpdated({
                    id: res.id,
                    template: newTemplate
                })
            })
        )

    @Effect()
    deleteAdjustmentTemplate$ = 
        this.actions.pipe(
            ofType<DeleteAdjustmentTemplate>(AdjustmentTemplateActionTypes.DELETE_ADJUSTMENT_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.delete(payload.id),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadAdjustmentTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )

    @Effect()
    duplicateAdjustmentTemplate$ =
        this.actions.pipe(
            ofType<DuplicateAdjustmentTemplate>(AdjustmentTemplateActionTypes.DUPLICATE_ADJUSTMENT_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.duplicate(payload.template),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadAdjustmentTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )
}
