import { createFeatureSelector, createSelector } from "@ngrx/store";
import { WarehouseAdjustmentsState } from "../_reducers/warehouse-adjustment.reducers";
import * as fromAdjustment from '../_reducers/warehouse-adjustment.reducers';
import { WarehouseAdjustmentModel } from "../_models/warehouse-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectWarehouseAdjustmentsState = createFeatureSelector<WarehouseAdjustmentsState>('warehouse-adjustments');

export const selectWarehouseAdjustmentById = (AdjustmentId: number) => createSelector(
    selectWarehouseAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllWarehouseAdjustments = createSelector(
    selectWarehouseAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllWarehouseAdjustmentsIds = createSelector(
    selectWarehouseAdjustmentsState,
    fromAdjustment.selectIds
);

export const allWarehouseAdjustmentsLoaded = createSelector(
    selectWarehouseAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectWarehouseAdjustmentsPageLoading = createSelector(
    selectWarehouseAdjustmentsState,
    state => state.listLoading
);

export const selectWarehouseAdjustmentsActionLoading = createSelector(
    selectWarehouseAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedWarehouseAdjustmentId = createSelector(
    selectWarehouseAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectWarehouseAdjustmentsShowInitWaitingMessage = createSelector(
    selectWarehouseAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedWarehouseAdjustmentCount = createSelector(
    selectWarehouseAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedWarehouseAdjustmentCount = createSelector(
    selectWarehouseAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectWarehouseAdjustmentQueryResult = createSelector(
    selectWarehouseAdjustmentsState,
    state => {
        const items: WarehouseAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: WarehouseAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);