// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {CoordinateReferenceSystem} from '../_models/coordinate-reference-system.model';

export enum CoordinateReferenceSystemActionTypes {

    AllCoordinateReferenceSystemsRequested = '[CoordinateReferenceSystems Home Page] All CoordinateReferenceSystems Requested',
    AllCoordinateReferenceSystemsLoaded = '[CoordinateReferenceSystems API] All CoordinateReferenceSystems Loaded',

    CoordinateReferenceSystemOnServerCreated = '[Edit CoordinateReferenceSystem Dialog] CoordinateReferenceSystem On Server Created',
    CoordinateReferenceSystemCreated = '[Edit CoordinateReferenceSystems Dialog] CoordinateReferenceSystems Created',
    CoordinateReferenceSystemUpdated = '[Edit CoordinateReferenceSystem Dialog] CoordinateReferenceSystem Updated',
    CoordinateReferenceSystemDeleted = '[CoordinateReferenceSystems List Page] CoordinateReferenceSystem Deleted',

    CoordinateReferenceSystemsPageRequested = '[CoordinateReferenceSystems List Page] CoordinateReferenceSystems Page Requested',
    CoordinateReferenceSystemsPageLoaded = '[CoordinateReferenceSystems API] CoordinateReferenceSystems Page Loaded',
    CoordinateReferenceSystemsPageCancelled = '[CoordinateReferenceSystems API] CoordinateReferenceSystems Page Cancelled',

    CoordinateReferenceSystemsPageToggleLoading = '[CoordinateReferenceSystems page] CoordinateReferenceSystems Page Toggle Loading',
    CoordinateReferenceSystemsActionToggleLoading = '[CoordinateReferenceSystems] CoordinateReferenceSystems Action Toggle Loading',

    // trash system

    CoordinateReferenceSystemOnServerRestored = '[CoordinateReferenceSystem Trash] CoordinateReferenceSystem On Server Restored',
    CoordinateReferenceSystemRestored = '[CoordinateReferenceSystem Trash] CoordinateReferenceSystem Restored',

    CoordinateReferenceSystemOnServerAdminRestored = '[CoordinateReferenceSystem Admin Trash] CoordinateReferenceSystem On Server Restored',
    CoordinateReferenceSystemAdminRestored = '[CoordinateReferenceSystem Admin Trash] CoordinateReferenceSystem Restored',

    CoordinateReferenceSystemDeletedFromTrash = '[CoordinateReferenceSystem Trash] CoordinateReferenceSystem deleted',
    CoordinateReferenceSystemDeletedFromAdminTrash = '[CoordinateReferenceSystem Admin Trash] CoordinateReferenceSystem deleted',

    CoordinateReferenceSystemTrash = 'CoordinateReferenceSystem Trashed',
    CoordinateReferenceSystemTrashFlushed = 'CoordinateReferenceSystem Trash Flushed',
}

export class CoordinateReferenceSystemOnServerCreated implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerCreated;

    constructor(public payload: { coordinateReferenceSystem: CoordinateReferenceSystem}) {
    }
}

export class CoordinateReferenceSystemCreated implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemCreated;

    constructor(public payload: { coordinateReferenceSystem: CoordinateReferenceSystem }) {
    }
}

export class CoordinateReferenceSystemUpdated implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemUpdated;

    constructor(public payload: {
        partialCoordinateReferenceSystem: Update<CoordinateReferenceSystem>,
        coordinateReferenceSystem: CoordinateReferenceSystem
    }) {
    }
}

export class CoordinateReferenceSystemDeleted implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class CoordinateReferenceSystemsPageRequested implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class CoordinateReferenceSystemsPageLoaded implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageLoaded;

    constructor(public payload: { coordinateReferenceSystems: CoordinateReferenceSystem[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class CoordinateReferenceSystemsPageCancelled implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageCancelled;
}

export class AllCoordinateReferenceSystemsRequested implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.AllCoordinateReferenceSystemsRequested;
}

export class AllCoordinateReferenceSystemsLoaded implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.AllCoordinateReferenceSystemsLoaded;

    constructor(public payload: { coordinateReferenceSystems: CoordinateReferenceSystem[] }) {
    }
}

export class CoordinateReferenceSystemsPageToggleLoading implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class CoordinateReferenceSystemsActionToggleLoading implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class CoordinateReferenceSystemDeletedFromAdminTrash implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class CoordinateReferenceSystemDeletedFromTrash implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CoordinateReferenceSystemOnServerRestored implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CoordinateReferenceSystemRestored implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemRestored;

    constructor(public payload: { item: CoordinateReferenceSystem }) {
    }
}

export class CoordinateReferenceSystemOnServerAdminRestored implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CoordinateReferenceSystemAdminRestored implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemAdminRestored;

    constructor(public payload: { item: CoordinateReferenceSystem }) {
    }
}

export class CoordinateReferenceSystemTrashFlushed implements Action {
    readonly type = CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemTrashFlushed;

    constructor() {
    }
}

export type CoordinateReferenceSystemActions = CoordinateReferenceSystemCreated
    | CoordinateReferenceSystemUpdated
    | CoordinateReferenceSystemDeleted
    | CoordinateReferenceSystemsPageRequested
    | CoordinateReferenceSystemsPageLoaded
    | CoordinateReferenceSystemsPageCancelled
    | AllCoordinateReferenceSystemsLoaded
    | AllCoordinateReferenceSystemsRequested
    | CoordinateReferenceSystemOnServerCreated
    | CoordinateReferenceSystemsPageToggleLoading
    | CoordinateReferenceSystemsActionToggleLoading
    | CoordinateReferenceSystemDeletedFromAdminTrash
    | CoordinateReferenceSystemDeletedFromTrash
    | CoordinateReferenceSystemOnServerRestored
    | CoordinateReferenceSystemRestored
    | CoordinateReferenceSystemOnServerAdminRestored
    | CoordinateReferenceSystemAdminRestored
    | CoordinateReferenceSystemTrashFlushed;
