import {FoundationType} from '../_models/foundation-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {FoundationTypesState} from '../_reducers/foundation-type.reducers';
import * as fromFoundationType from '../_reducers/foundation-type.reducers';
import {each} from 'lodash';

export const selectFoundationTypesState = createFeatureSelector<FoundationTypesState>('foundationTypes');

export const selectFoundationTypeById = (foundationTypeId: number) => createSelector(
    selectFoundationTypesState,
    foundationTypesState => foundationTypesState.entities[foundationTypeId]
);

export const selectAllFoundationTypes = createSelector(
    selectFoundationTypesState,
    fromFoundationType.selectAll
);

export const selectAllFoundationTypesIds = createSelector(
    selectFoundationTypesState,
    fromFoundationType.selectIds
);

export const allFoundationTypesLoaded = createSelector(
    selectFoundationTypesState,
    foundationTypesState => foundationTypesState.isAllFoundationTypesLoaded
);


export const selectFoundationTypesPageLoading = createSelector(
    selectFoundationTypesState,
    foundationTypesState => foundationTypesState.listLoading
);

export const selectFoundationTypesActionLoading = createSelector(
    selectFoundationTypesState,
    foundationTypesState => foundationTypesState.actionLoading
);

export const selectLastCreatedFoundationTypeId = createSelector(
    selectFoundationTypesState,
    foundationTypesState => foundationTypesState.lastCreatedFoundationTypeId
);

export const selectFoundationTypesShowInitWaitingMessage = createSelector(
    selectFoundationTypesState,
    foundationTypesState => foundationTypesState.showInitWaitingMessage
);

export const selectTrashedFoundationTypeCount = createSelector(
    selectFoundationTypesState,
    (foundationTypesState) => foundationTypesState.totalTrashed
);

export const selectAdminTrashedFoundationTypeCount = createSelector(
    selectFoundationTypesState,
    (foundationTypesState) => foundationTypesState.totalAdminTrashed
);


export const selectFoundationTypeQueryResult = createSelector(
    selectFoundationTypesState,
    foundationTypesState => {
        const items: FoundationType[] = [];
        each(foundationTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: FoundationType[] = httpExtension.sortArray(items, foundationTypesState.lastQuery.sortField, foundationTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, foundationTypesState.totalCount, '', foundationTypesState.totalTrashed);
    }
);
