// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {TpFinancialTaskState} from '../_reducers/tp-financial-task.reducers';
import * as fromTpFinancialTasks from '../_reducers/tp-financial-task.reducers';
import {TpFinancialTaskModel} from '../_models/tp-financial-task.model';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';

export const selectTpFinancialTasksState = createFeatureSelector<TpFinancialTaskState>('tpFinancialTasks');

export const selectTpFinancialTaskById = (itemId: number) => createSelector(
    selectTpFinancialTasksState,
    cs => cs.entities[itemId]
);

export const selectAllTpFinancialTasks = createSelector(
    selectTpFinancialTasksState,
    fromTpFinancialTasks.selectAll
);

export const selectAllTpFinancialTasksIds = createSelector(
    selectTpFinancialTasksState,
    fromTpFinancialTasks.selectIds
);

export const allTpFinancialTasksLoaded = createSelector(
    selectTpFinancialTasksState,
    cs => cs._isAllTpFinancialTaskLoaded
);

export const selectTpFinancialTasksPageLoading = createSelector(
    selectTpFinancialTasksState,
    assetClassesState => assetClassesState.listLoading
);


export const selectTpFinancialTasksShowInitWaitingMessage = createSelector(
    selectTpFinancialTasksState,
    tpFinancialTaskState => tpFinancialTaskState.showInitWaitingMessage
);

export const selectTpFinancialTasksActionLoading = createSelector(
    selectTpFinancialTasksState,
    tpFinancialTaskState => tpFinancialTaskState.actionsLoading
);

export const selectTpFinancialTasksByTpId = (tpId: number) => createSelector(
    selectTpFinancialTasksState,
    tpFinancialTaskState => {
        const items: TpFinancialTaskModel[] = [];
        each(tpFinancialTaskState.entities, element => {
            if (element.tp_id == tpId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'task_id');
    }
);

export const selectUploadTaskUsersByTpId = (tpId: number) => createSelector(
    selectTpFinancialTasksState,
    tpFinancialTaskState => {
        const items: TpFinancialTaskModel[] = [];
        each(tpFinancialTaskState.entities, element => {
            if (element.tp_id == tpId && element.task_id == 0) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'second_id');
    }
);

export const selectTpFinancialTasksByToeId = (toeId: number) => createSelector(
    selectTpFinancialTasksState,
    tpFinancialTaskState => {
        const items: TpFinancialTaskModel[] = [];
        each(tpFinancialTaskState.entities, element => {
            if (element.toe_id == toeId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArray(items, 'tp_id');
    }
);


export const selectTpFinancialTasksInStore = createSelector(
    selectTpFinancialTasksState,
    tpFinancialTaskState => {
        const items: TpFinancialTaskModel[] = [];
        each(tpFinancialTaskState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: TpFinancialTaskModel[] = [];
        const res = httpExtension.separateArrayByCompletion(items);
        let completedResult: TpFinancialTaskModel[] = [];
        let inCompletedResult: TpFinancialTaskModel[] = [];
        if (tpFinancialTaskState.lastQuery.sortField === 'updated_at') {
            completedResult = httpExtension.sortArrayDateTime(
                res['completed'],
                tpFinancialTaskState.lastQuery.sortField,
                tpFinancialTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayDateTime(
                res['inCompleted'],
                tpFinancialTaskState.lastQuery.sortField,
                tpFinancialTaskState.lastQuery.sortOrder
            );
        } else if (tpFinancialTaskState.lastQuery.sortField === 'completion') {
            completedResult = httpExtension.sortArrayCompletion(
                res['completed'],
                tpFinancialTaskState.lastQuery.sortField,
                tpFinancialTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayCompletion(
                res['inCompleted'],
                tpFinancialTaskState.lastQuery.sortField,
                tpFinancialTaskState.lastQuery.sortOrder
            );
        } else {
            completedResult = httpExtension.sortArray(
                res['completed'],
                tpFinancialTaskState.lastQuery.sortField,
                tpFinancialTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArray(
                res['inCompleted'],
                tpFinancialTaskState.lastQuery.sortField,
                tpFinancialTaskState.lastQuery.sortOrder
            );
        }
        result = inCompletedResult.concat(completedResult);
        return new QueryResultsModel(result, tpFinancialTaskState.totalCount, '');
    }
);