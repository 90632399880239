import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel } from 'src/app/core/asset_class';
import { AllCoordinateReferenceSystemsRequested, CoordinateReferenceSystem, selectAllCoordinateReferenceSystems } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LandAreaListComponent } from '../../../shared_components/land-parcel/land-area/land-area-list/land-area-list.component';
import { LandParcelMapComponent } from '../../../shared_components/land-parcel/land-parcel-map/land-parcel-map.component';

@Component({
  selector: 'kt-inspection-land-parcel',
  templateUrl: './inspection-land-parcel.component.html',
  styleUrls: ['./inspection-land-parcel.component.scss']
})
export class InspectionLandParcelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputData: {
    add_info_on_land: boolean;
    land_parcel_name: string;
    coordinate_reference_system_id: number;
    landAreaComponents: AssetClassLandAreaComponentModel[];
    landArea: AssetClassLandAreaModel
  }
  @Input() centerLat: number;
  @Input() centerLng: number;
  @Input() points: {lat: number, lng: number}[];
  @Input() staticUnit: number;
  @Input() markerInfo: any;
  @ViewChild(LandParcelMapComponent, {static: false})
  landParcelMapComponent: LandParcelMapComponent;
  @ViewChild(LandAreaListComponent, {static: false})
  landAreaListComponent: LandAreaListComponent;

  form: UntypedFormGroup;
  coordinateReferenceSystems: CoordinateReferenceSystem[] = [];
  landAreaComponentListSubject = new BehaviorSubject<AssetClassLandAreaComponentModel[]>([]);
  landAreaSubject = new BehaviorSubject<AssetClassLandAreaModel>(new AssetClassLandAreaModel());

  staticUnitLengthName: string = 'M'
  staticUnitName: string = 'Sqm';

  private _onDestroy: Subject<void> = new Subject();
  errorFields = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.staticUnit) {
      this._changeStatics(changes.staticUnit.currentValue);
    }
  }

  private _changeStatics(unitID: number) {
    switch(unitID) {
      case 1: {
        this.staticUnitLengthName = 'M';
        this.staticUnitName = 'sqm'
        break;
      }
      case 2: {
        this.staticUnitName = 'sqft';
        this.staticUnitLengthName = 'Ft';
      }
    }
  }

  ngOnInit(): void {
    this._changeStatics(this.staticUnit);

    this.landAreaComponentListSubject.next(this.inputData.landAreaComponents);
    this.landAreaSubject.next(this.inputData.landArea);

    this.form = this.formBuilder.group({
      add_info_on_land: [this.inputData.add_info_on_land],
      land_parcel_name: [this.inputData.land_parcel_name],
      coordinate_reference_system_id: [this.inputData.coordinate_reference_system_id]
    });

    this.form.controls.add_info_on_land.valueChanges.pipe(
      startWith(this.form.controls.add_info_on_land.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      if (val) {
        this.form.controls.land_parcel_name.setValidators([Validators.required]);
      } else {
        this.form.controls.land_parcel_name.clearValidators();
      }
      this.form.controls.land_parcel_name.updateValueAndValidity();
    })

    this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
    this.store.pipe(
        select(selectAllCoordinateReferenceSystems),
        takeUntil(this._onDestroy)
    ).subscribe(res => {
        this.coordinateReferenceSystems = [];
        if (res) {
            this.coordinateReferenceSystems = res;
        }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  checkValidation(isComplete: boolean): boolean {
    let errorFields = [];
    let isValid = true;
    const controls = this.form.controls;
    if (isComplete && this.form.invalid) {
      Object.keys(controls).forEach(cname => {
        if (controls[cname].invalid) {
          errorFields.push(cname);
        }
        controls[cname].markAsTouched();
      })
      isValid = false;
    }
    this.errorFields = errorFields;
    return isValid;
  }

  getData(): InspectionLandParcelComponent['inputData'] & {points: any[]} {
    const controls = this.form.controls;
    this.landAreaListComponent && this.landAreaListComponent.updateValue();
    return {
      add_info_on_land: controls.add_info_on_land.value,
      land_parcel_name: controls.land_parcel_name.value,
      coordinate_reference_system_id: controls.coordinate_reference_system_id.value,
      landAreaComponents: this.landAreaComponentListSubject.value,
      landArea: this.landAreaSubject.value,
      points: this.landParcelMapComponent ? this.landParcelMapComponent.getPath() : []
    }
  }
}
