<div class="col-12 section-margin-bottom-60" *ngIf="mapData$|async as mapData">
    <agm-map [latitude]="mapData.tp.latitude" [longitude]="mapData.tp.longitude"
        [zoom]="16" [disableDoubleClickZoom]="true" [fitBounds]="fitBounds" [scrollwheel]="null"
        [gestureHandling]="'cooperative'" [draggable]="false" [clickableIcons]="false" style="height: 400px">
        <agm-map-type-control></agm-map-type-control>
        <agm-marker [agmFitBounds]="true" [latitude]="mapData.tp.latitude"
            [longitude]="mapData.tp.longitude" [iconUrl]="tpIcon$|async" [label]="tpLabel$|async"></agm-marker>
        <!-- <ng-container *ngFor="let refNum of mapData.refNums">
            <agm-marker [agmFitBounds]="true" [latitude]="mapData.comparables[refNum].latitude"
                [longitude]="mapData.comparables[refNum].longitude" [iconUrl]="icon"
                [label]="mapData.comparables[refNum].label"></agm-marker> -->
            <!-- <agm-polyline [strokeColor]="'red'">
                <agm-polyline-point [latitude]="mapData.comparables[refNum].latitude"
                    [longitude]="mapData.comparables[refNum].longitude"></agm-polyline-point>
                <agm-polyline-point [latitude]="mapData.tp.latitude"
                    [longitude]="mapData.tp.longitude"></agm-polyline-point>
            </agm-polyline> -->
        <!-- </ng-container> -->
        <ng-container *ngFor="let com of mapData.comparables">
            <agm-marker [agmFitBounds]="true" [latitude]="com.latitude"
                [longitude]="com.longitude" [iconUrl]="icon"
                [label]="com.label"></agm-marker>
        </ng-container>
        <ng-container *ngFor="let item of mapData.landmarks">
            <agm-marker
                [agmFitBounds]="true"
                [latitude]="item.latitude"
                [longitude]="item.longitude"
                [iconUrl]="icon"
                [label]="item.label">
            </agm-marker>
        </ng-container>
    </agm-map>
</div>



<div class="col-12">
    <ng-container *ngIf="viewModel$|async as viewModel">

        <div class="card card-custom">
            <div class="card-header">
                <div class="card-title"></div>
                <div class="card-toolbar">
                    <div *ngIf="viewModel.refNums.length">
                        <button (click)="onShowComparableOverview(viewModel.refNums)" mat-raised-button
                            [matTooltip]="'Show info of selected comparable'" class="float-right m-0 btn" type="button">
                            <span>Overview</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="mat-table-wrapper">
                    <table class="table" style="overflow-y: hidden;">
                        <thead>
                            <tr>
                                <th [class]="'empty-header-' + viewModel.refNums.length"></th>
                                <th [class]="'com-header-' + viewModel.refNums.length"
                                    *ngFor="let _refNum of viewModel.refNums; let i = index">
                                    Comparable {{i+1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Database ID</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{refNum}}
                                </td>
                            </tr>
                            <tr class="colored-row" *ngIf="viewModel.buildingInfo.exists">
                                <th>Building ID</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{viewModel.buildingInfo.info[refNum]}}
                                </td>
                            </tr>
                            <tr>
                                <th>{{viewModel.sizeRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{viewModel.sizes[refNum] ? viewModel.sizes[refNum] : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>{{viewModel.considerationRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{ viewModel.considerations[refNum].value
                                    ? (viewModel.considerations[refNum].value.toFixed(2) | mask:'separator':{thousandSeparator: ','} )
                                    : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="has-bottom-border">
                                <th>Date of information/transaction</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    <ng-container *ngIf="viewModel.sourceDates[refNum]">
                                        {{viewModel.sourceDates[refNum].type}} -
                                        {{viewModel.sourceDates[refNum].date|date:'dd MMM yyyy'}} -
                                        ({{viewModel.sourceDates[refNum].dateStr|dateAgo}})
                                    </ng-container>
                                    <ng-container *ngIf="!viewModel.sourceDates[refNum]">
                                        N/A
                                    </ng-container>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>{{viewModel.totalAdjustmentsRow}}</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{viewModel.totalAdjustments[refNum] ? viewModel.totalAdjustments[refNum] : 0}}%
                                </td>
                            </tr>
                            <tr>
                                <th>{{viewModel.adjustedConsiderationRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{viewModel.adjustedConsiderations[refNum].toFixed(2) | mask:'separator':{thousandSeparator: ','} }}
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Weighting (%)</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{viewModel.weightings[refNum]}}%
                                </td>
                            </tr>
                            <tr class="has-bottom-border">
                                <th>{{viewModel.weightedAvgRowHeader}}</th>
                                <td *ngFor="let refNum of viewModel.refNums">
                                    {{viewModel.weightedAvgRates[refNum] != null
                                    ? (viewModel.weightedAvgRates[refNum].toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                    : 'N/A'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table">
                        <thead>
                            <tr>
                                <th [class]="'empty-header-' + viewModel.refNums.length"></th>
                                <th [class]="'tp-header-' + viewModel.refNums.length">
                                    Target Property
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{viewModel.tpInUnitRowHeader}}</th>
                                <td [colSpan]="viewModel.refNums.length">
                                    <strong>
                                        {{ viewModel.totalWeighted !== null
                                        ? (viewModel.totalWeighted.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                    </strong>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>{{viewModel.tpRowHeader}}</th>
                                <td [colSpan]="viewModel.refNums.length">
                                    <strong>
                                        {{viewModel.tpValue !== null
                                        ? (viewModel.tpValue.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                    </strong>
                                </td>
                            </tr>
                            <ng-container *ngIf="viewModel.capitalAllowance != 0">
                                <tr>
                                    <th>
                                        {{viewModel.capitalAllowanceRowHeader}}
                                        <button mat-icon-button color="primary" placement="top" [container]="'body'"
                                            (click)="$event.stopPropagation()"
                                            [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.DESCRIPTION' | translate">
                                            <mat-icon>info</mat-icon>
                                        </button>
                                    </th>
                                    <td [colSpan]="viewModel.refNums.length">
                                        <strong>
                                            {{viewModel.capitalAllowance.toFixed(2) | mask:'separator':{thousandSeparator: ','} }}
                                        </strong>
                                    </td>
                                </tr>
                                <tr class="colored-row">
                                    <th>Justification</th>
                                    <td [colSpan]="viewModel.refNums.length">
                                        <strong>
                                            {{viewModel.capitalAllowanceJustification}}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{viewModel.totalTPWeightedHeader}}</th>
                                    <td [colSpan]="viewModel.refNums.length">
                                        <strong>
                                            {{viewModel.totalTPWeighted !== null
                                            ? (viewModel.totalTPWeighted.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                            : 'N/A'}}
                                        </strong>
                                    </td>
                                </tr>
                                <tr class="colored-row">
                                    <th>{{viewModel.totalTPRowHeader}}</th>
                                    <td [colSpan]="viewModel.refNums.length">
                                        <strong>
                                            {{viewModel.totalTPValue !== null
                                            ? (viewModel.totalTPValue.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                            : 'N/A'}}
                                        </strong>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <table class="table" *ngIf="viewModel.liquidationValueSection.addDiscount">
                        <thead>
                            <tr>
                                <th [class]="'empty-header-' + viewModel.refNums.length"></th>
                                <th [class]="'tp-header-' + viewModel.refNums.length">
                                    {{viewModel.premiseOfValueName}} Liquidation value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{viewModel.liquidationValueSection.liquidationDiscountRowHeader}}</th>
                                <td [colSpan]="viewModel.refNums.length">
                                    <strong>
                                        {{ viewModel.liquidationValueSection.totalDiscount !== null
                                        ? (viewModel.liquidationValueSection.totalDiscount.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                    </strong>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>{{viewModel.liquidationValueSection.liquidationValueRowHeader}}</th>
                                <td [colSpan]="viewModel.refNums.length">
                                    <strong>
                                        {{viewModel.liquidationValueSection.value !== null
                                        ? (viewModel.liquidationValueSection.value.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
</div>