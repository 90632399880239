import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { ToeAmendmentModel } from "../_models/toe-amendment.model";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class ToeAmendmentService {
	API_AMENDMENTS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes`;
	constructor (
		private http: HttpClient,
		private authService: MadAuthService,
		private subDomainService: SubdomainService	
	) {}

	getAmendment(toeID: number): Observable<ToeAmendmentModel> {
		const headers = this.authService.getAuthHeaders();
		return this.http.get<{data: ToeAmendmentModel}|null>(`${this.API_AMENDMENTS_URL}/${toeID}/amendments`, {
			headers
		}).pipe(map(res => {
			if (res == null) {
				return new ToeAmendmentModel(toeID);
			}
			return res.data;
		}));
	}

	getAmendmentList(toeID: number): Observable<any> {
		const headers = this.authService.getAuthHeaders();
		return this.http.get<any>(`${this.API_AMENDMENTS_URL}/${toeID}/amendments/list`, {headers});
	}

	storeAmendment(toeID: number, toeAmendment: ToeAmendmentModel): Observable<ToeAmendmentModel> {
		const headers = this.authService.getAuthHeaders();
		const url = `${this.API_AMENDMENTS_URL}/${toeID}/amendments`;
		return this.http.post<{data: ToeAmendmentModel}>(url, toeAmendment, {
			headers
		}).pipe(map(res => res.data));
	}

	updateAmendment(toeID: number, toeAmendment: ToeAmendmentModel): Observable<ToeAmendmentModel> {
		const headers = this.authService.getAuthHeaders();
		const url = `${this.API_AMENDMENTS_URL}/${toeID}/amendments/${toeAmendment.id}`;
		return this.http.post<{data: ToeAmendmentModel}>(url, toeAmendment, {
			headers
		}).pipe(map(res => res.data));
	}

	getAllReports(toeID: number): Observable<any> {
		const headers = this.authService.getAuthHeaders();
		const url = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/amendment-reports/`; 
		return this.http.get<any>(url + toeID, {headers});
	}

	createSignedReport(item: any, taskID: number): Observable<any> {
		const httpHeaders = this.authService.getAuthHeaders();
		const url = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/amendment-signed-reports/`
		return this.http.post<any>(url + `add/${taskID}`, item, {headers: httpHeaders});
	}
}
