<div #container class="sticky-tab-header">
    <nav  mat-tab-nav-bar >
        <a mat-tab-link *ngFor="let header of headerLabels; let i = index"
            [active]="i == selectedIndex"
            [disabled]="header.disabled | async"
            (click)="changeTab(i)">
            <div
                class="d-flex w-100 align-items-center {{centerTabs ? 'justify-content-center' : ''}}"
                >
                {{header.label}}
                <mat-icon 
                    class="ml-4"
                    *ngIf="header.tooltip"
                    style="cursor: pointer;"
                    (click)="$event.stopPropagation()"
                    container="body"
                    placement="bottom"
                    [ngbPopover]="header.tooltip.description | translate"
                    [popoverTitle]="header.tooltip.title | translate"
                    [inline]="true">
                    help
                </mat-icon>
                <button 
                    *ngIf="selectedIndex == i && header.activeButton"
                    mat-raised-button 
                    type="button"
                    color="primary"
                    (click)="header.activeButton.onClick()">
                    {{header.activeButton.label}}
                </button>
            </div>
        </a>
    </nav>
</div>