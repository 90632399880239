import { Action } from "@ngrx/store";

export enum ValuationLandmarkActionTypes {
    UpdateValuationLandmarks = '[Valuation Process Page / Landmarks Tab] Update Valuation Landmark State',
    LoadData = '[Valuation Process Effect] Load landmarks from api'
}

export class UpdateValuationLandMarks implements Action {
    readonly type = ValuationLandmarkActionTypes.UpdateValuationLandmarks
    constructor(public payload: {landmarks: any[]}) {}
}

export class LoadValuationLandMarks implements Action {
    readonly type = ValuationLandmarkActionTypes.LoadData
    constructor(public payload: {landmarks: any[]}) {}
}

export type ValuationLandmarkAction = UpdateValuationLandMarks | LoadValuationLandMarks