// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {KeyCategoryModel} from '../_models/key-category.model';

export enum KeyCategoryActionTypes {

    AllKeyCategoriesRequested = '[KeyCategories Home Page] All KeyCategories Requested',
    AllKeyCategoriesLoaded = '[KeyCategories API] All KeyCategories Loaded',

    KeyCategoryOnServerCreated = '[Edit KeyCategory Dialog] KeyCategory On Server Created',
    KeyCategoryCreated = '[Edit KeyCategories Dialog] KeyCategories Created',
    KeyCategoryUpdated = '[Edit KeyCategory Dialog] KeyCategory Updated',
    KeyCategoryDeleted = '[KeyCategories List Page] KeyCategory Deleted',

    KeyCategoriesPageRequested = '[KeyCategories List Page] KeyCategories Page Requested',
    KeyCategoriesPageLoaded = '[KeyCategories API] KeyCategories Page Loaded',
    KeyCategoriesPageCancelled = '[KeyCategories API] KeyCategories Page Cancelled',

    KeyCategoriesPageToggleLoading = '[KeyCategories page] KeyCategories Page Toggle Loading',
    KeyCategoriesActionToggleLoading = '[KeyCategories] KeyCategories Action Toggle Loading',

    // trash system

    KeyCategoryOnServerRestored = '[KeyCategory Trash] KeyCategory On Server Restored',
    KeyCategoryRestored = '[KeyCategory Trash] KeyCategory Restored',

    KeyCategoryOnServerAdminRestored = '[KeyCategory Admin Trash] KeyCategory On Server Restored',
    KeyCategoryAdminRestored = '[KeyCategory Admin Trash] KeyCategory Restored',

    KeyCategoryDeletedFromTrash = '[KeyCategory Trash] KeyCategory deleted',
    KeyCategoryDeletedFromAdminTrash = '[KeyCategory Admin Trash] KeyCategory deleted',

    KeyCategoryTrash = 'KeyCategory Trashed',
    KeyCategoryTrashFlushed = 'KeyCategory Trash Flushed',
}

export class KeyCategoryOnServerCreated implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryOnServerCreated;

    constructor(public payload: { keyCategory: KeyCategoryModel }) {
    }
}

export class KeyCategoryCreated implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryCreated;

    constructor(public payload: { keyCategory: KeyCategoryModel }) {
    }
}

export class KeyCategoryUpdated implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryUpdated;

    constructor(public payload: {
        partialKeyCategory: Update<KeyCategoryModel>,
        keyCategory: KeyCategoryModel
    }) {
    }
}

export class KeyCategoryDeleted implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class KeyCategoriesPageRequested implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoriesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class KeyCategoriesPageLoaded implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoriesPageLoaded;

    constructor(public payload: { keyCategories: KeyCategoryModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class KeyCategoriesPageCancelled implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoriesPageCancelled;
}

export class AllKeyCategoriesRequested implements Action {
    readonly type = KeyCategoryActionTypes.AllKeyCategoriesRequested;
}

export class AllKeyCategoriesLoaded implements Action {
    readonly type = KeyCategoryActionTypes.AllKeyCategoriesLoaded;

    constructor(public payload: { keyCategories: KeyCategoryModel[] }) {
    }
}

export class KeyCategoriesPageToggleLoading implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoriesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class KeyCategoriesActionToggleLoading implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoriesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class KeyCategoryDeletedFromAdminTrash implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class KeyCategoryDeletedFromTrash implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class KeyCategoryOnServerRestored implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class KeyCategoryRestored implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryRestored;

    constructor(public payload: { item: KeyCategoryModel }) {
    }
}

export class KeyCategoryOnServerAdminRestored implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class KeyCategoryAdminRestored implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryAdminRestored;

    constructor(public payload: { item: KeyCategoryModel }) {
    }
}

export class KeyCategoryTrashFlushed implements Action {
    readonly type = KeyCategoryActionTypes.KeyCategoryTrashFlushed;

    constructor() {
    }
}

export type KeyCategoryActions = KeyCategoryCreated
    | KeyCategoryUpdated
    | KeyCategoryDeleted
    | KeyCategoriesPageRequested
    | KeyCategoriesPageLoaded
    | KeyCategoriesPageCancelled
    | AllKeyCategoriesLoaded
    | AllKeyCategoriesRequested
    | KeyCategoryOnServerCreated
    | KeyCategoriesPageToggleLoading
    | KeyCategoriesActionToggleLoading
    | KeyCategoryDeletedFromAdminTrash
    | KeyCategoryDeletedFromTrash
    | KeyCategoryOnServerRestored
    | KeyCategoryRestored
    | KeyCategoryOnServerAdminRestored
    | KeyCategoryAdminRestored
    | KeyCategoryTrashFlushed;
