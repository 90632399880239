import { AssetClassTenure } from "src/app/core/comparable";
import { HeadlineRent, HeadlineToEffectiveMeta, Lease, PropertyRevenue } from "../types";
import { mapLeaseToHeadlineRent, mapPropertyRevenueToHeadlineRent } from "../utils";
import { headlineToEffective } from "./headline-to-effective";
import { durationCalculation } from "../../../valuation";
import { InputAmountType } from "../../../types";
import { ConvertedCostRevenue } from "../types/converted-cost-revenue";
import { ModelAssumptionsUtil } from "./income/model-assumptions-util";
import * as moment from "moment";

export const EffectiveRentUtil = {
  getValue(item: Lease | PropertyRevenue, sizes: {totalLeasedArea: number, tpSize: number}): number {
    if (item.rentType === 'effective') {
      const basisMultiplier = item.rentBasis
        ? item.rentBasis.id == 1
          ? 12
          : item.rentBasis.id == 2
            ? 4
            : 1
        : undefined
      if (item.rentInputAmountType === 'total') return item.rentInputAmount * basisMultiplier
      if (isLease(item)) return item.rentInputAmount * item.leasedArea * basisMultiplier
      return item.rentInputAmount * (item.size === 'total_lease' ? sizes.totalLeasedArea : sizes.tpSize) * basisMultiplier
    }
    return EffectiveRentUtil.fromHeadlineRent(item, sizes)
  },
  getValueFromConvertedCostRevenue(item: ConvertedCostRevenue): number {
    if (item.rentType === 'effective') {
      const basisMultiplier = item.rentBasis
        ? item.rentBasis.id == 1
          ? 12
          : item.rentBasis.id == 2
            ? 4
            : 1
        : undefined
      return item.amount * basisMultiplier
    }
    const headlineRent = HeadlineRentUtil.fromConvertedCostRevenue(item)
    return EffectiveRentUtil.fromHeadlineRentV2(headlineRent, item.headlineToEffectiveMeta)
  },
  fromHeadlineRent(item: Lease | PropertyRevenue, sizes: {totalLeasedArea: number, tpSize: number}): number {
    const headlineRent = HeadlineRentUtil.fromLeaseAndPropertyRevenue(item, sizes)
    const result = headlineToEffective(headlineRent, item.headlineToEffectiveMeta)
    return result
  },
  fromHeadlineRentV2(item: HeadlineRent, meta: HeadlineToEffectiveMeta): number {
    const result = headlineToEffective(item, meta)
    return result
  },
}

export const HeadlineRentUtil = {
  fromLease: (item: Lease): HeadlineRent => {
    return mapLeaseToHeadlineRent(item)
  },
  fromPropertyRevenue: (item: PropertyRevenue, sizes: {totalLeasedArea: number, tpSize: number}): HeadlineRent => {
    return mapPropertyRevenueToHeadlineRent(item, sizes.tpSize, sizes.totalLeasedArea)
  },
  fromLeaseAndPropertyRevenue: (item: Lease | PropertyRevenue, sizes: {totalLeasedArea: number, tpSize: number}): HeadlineRent => {
    if (isLease(item)) {
      return HeadlineRentUtil.fromLease(item)
    }
    return HeadlineRentUtil.fromPropertyRevenue(item, sizes)
  },
  fromConvertedCostRevenue: (item: ConvertedCostRevenue): HeadlineRent => {
    return {
      currency: item.currency,
      size: item.size,
      startDate: item.startDate,
      duration: item.leaseDuration,
      rentFreePeriod: item.rentFreePeriod,
      fittingOutPeriod: item.fittingOutPeriod,
      writeOffPeriodAfter: item.writeOffPeriod,
      breakOptionAfter: item.breakOptionAfter,
      unexpiredLeaseTerms: ModelAssumptionsUtil.unexpiredLeaseTerm(item, moment()),
      capitalPayment: item.capitalPayment,
      rentInputAmount: item.rentInputAmount,
      rentInputAmounType: item.rentInputAmountType,
      rentBasis: item.rentBasis,
      rentReviewCycle: item.rentReviewCycle,
      rentReviewType: item.rentReviewType,

      expensesInputAmountType: item.expensesInputAmountType,
      recoverableBusiness: item.recoverableBusiness,
      recoverableInsurance: item.recoverableInsurance,
      recoverableJanitorial: item.recoverableJanitorial,
      recoverableLeasingExpenses: item.recoverableLeasingExpenses,
      recoverableMaintenance: item.recoverableMaintenance,
      recoverableOthers: item.recoverableOthers,
      recoverablePropertyManagement: item.recoverablePropertyManagement,
      recoverablePropertyTaxes: item.recoverablePropertyTaxes,
      recoverableUtilities: item.recoverableUtilities,
      nonRecoverableBusiness: item.nonRecoverableBusiness,
      nonRecoverableInsurance: item.nonRecoverableInsurance,
      nonRecoverableJanitorial: item.nonRecoverableJanitorial,
      nonRecoverableLeasingExpenses: item.nonRecoverableLeasingExpenses,
      nonRecoverableMaintenance: item.nonRecoverableMaintenance,
      nonRecoverableOthers: item.nonRecoverableOthers,
      nonRecoverablePropertyManagement: item.nonRecoverablePropertyManagement,
      nonRecoverablePropertyTaxes: item.nonRecoverablePropertyTaxes,
      nonRecoverableUtilities: item.nonRecoverableUtilities,
    }
  },
  fromAssetClassTenure: (item: AssetClassTenure, size: number): HeadlineRent => {
    return {
      currency: item.currency,
      size: size,

      startDate: new Date(item.start_date),
      duration: durationCalculation(item.lease_duration, item.duration_type),
      rentFreePeriod: durationCalculation(item.rent_free_period, item.duration_type),
      fittingOutPeriod: durationCalculation(item.fitting_out_period, item.duration_type),
      writeOffPeriodAfter: durationCalculation(item.write_off_period, item.duration_type),
      breakOptionAfter: durationCalculation(item.break_option, item.duration_type),
      unexpiredLeaseTerms: item.unexpired_lease_term,

      capitalPayment: item.cap_payment,

      rentInputAmount: item.total_consideration,
      rentInputAmounType: item.rent_input_amount_type as InputAmountType,
      rentBasis: item.rent_basis_id
        ? {id: item.rent_basis_id, name: item.rent_basis_name}
        : null,
      rentReviewCycle: durationCalculation(item.rent_review, item.duration_type),
      rentReviewType: item.rent_review_type_id
        ? {id: item.rent_review_type_id, name: item.rent_review_type_name}
        : null,

      expensesInputAmountType: item.expenses_input_amount_type as InputAmountType,
      recoverablePropertyManagement: item.recoverable_property_management,
      recoverableLeasingExpenses: item.recoverable_leasing_expenses,
      recoverableUtilities: item.recoverable_utilities,
      recoverableMaintenance: item.recoverable_maintenance,
      recoverableInsurance: item.recoverable_insurance,
      recoverableJanitorial: item.recoverable_janitorial,
      recoverablePropertyTaxes: item.recoverable_property_taxes,
      recoverableBusiness: item.recoverable_business,
      recoverableOthers: item.recoverable_others,
      nonRecoverablePropertyManagement: item.non_recoverable_property_management,
      nonRecoverableLeasingExpenses: item.non_recoverable_leasing_expenses,
      nonRecoverableUtilities: item.non_recoverable_utilities,
      nonRecoverableMaintenance: item.non_recoverable_maintenance,
      nonRecoverableInsurance: item.non_recoverable_insurance,
      nonRecoverableJanitorial: item.non_recoverable_janitorial,
      nonRecoverablePropertyTaxes: item.non_recoverable_property_taxes,
      nonRecoverableBusiness: item.non_recoverable_business,
      nonRecoverableOthers: item.non_recoverable_others,
    }
  }
}

function isLease(item: Lease | PropertyRevenue): item is Lease {
  return (item as Lease).tenantName !== undefined
}