<div>
    <div class="example-container mat-elevation-z0 section-margin-top-60">
        <div class="col-md-12 px-0 kt-margin-bottom-20-mobile">
            <h5 class="my-3">Daily rates</h5>
        </div>
        <mat-table [dataSource]="dataSource" [ngClass]="{'readonly': readonly}">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Team Member</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef>Position</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <span *ngIf="item.lead_valuer" class="ml-4">
                        {{item.position ? item.position : 'Lead Valuer'}}
                    </span>
                    <div *ngIf="!item.lead_valuer">
                        <input class="input" placeholder="Position"
                        [(ngModel)]="item.position"
                        (blur)="changePosition($event, i)">
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="daily_rate">
                <mat-header-cell *matHeaderCellDef>Daily Rate</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <div class="px-2">
                        <input class="input" placeholder="Daily Rate"
                        [(ngModel)]="item.daily_rate"
                        mask="separator"
                        thousandSeparator=","
                        prefix="{{currencySubject.value}} "
                        (blur)="change($event, i)">
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="days">
                <mat-header-cell *matHeaderCellDef>Days</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <div>
                        <input class="input" placeholder="Days"
                        [(ngModel)]="item.days"
                        mask="0000"
                        (blur)="change($event, i)">
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="total_price">
                <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <span>
                        {{currencySubject.value}} {{item.total_price | mask: 'separator':{thousandSeparator: ','} }}
                    </span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>