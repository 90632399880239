// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {UsersState} from '../_reducers/user.reducers';
import {each} from 'lodash';
import { User } from '../../mad-auth/mad-auth.store';


export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectUserById = (userId: number) => createSelector(
    selectUsersState,
    _state => _state.entities[userId]
);

export const selectUsersPageLoading = createSelector(
    selectUsersState,
    _state => {
        return _state.listLoading;
    }
);

export const selectUsersActionLoading = createSelector(
    selectUsersState,
    _state => _state.actionLoading
);

export const selectLastCreatedUserId = createSelector(
    selectUsersState,
    _state => _state.lastCreatedUserId
);

export const selectUsersPageLastQuery = createSelector(
    selectUsersState,
    _state => _state.lastQuery
);

export const selectTrashedUserCount = createSelector(
    selectUsersState,
    (_state) => _state.totalTrashed
)

export const selectAdminTrashedUserCount = createSelector(
    selectUsersState,
    (_state) => _state.totalAdminTrashed
);

export const selectUsersInStore = createSelector(
    selectUsersState,
    _state => {
        const items: User[] = [];
        each(_state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: User[] = [];
        switch (_state.lastQuery.sortField) {
            case 'full_name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            case 'email':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension
                    .sortArray(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
        }

        return new QueryResultsModel(result, _state.totalCount, '');
    }
);

export const selectValuersInStore = createSelector(
    selectUsersState,
    _state => {
        const items: User[] = [];
        each(_state.entities, element => {
            // if (element.role_id <= 2) {
                items.push(element);
            // }
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: User[] = [];
        switch (_state.lastQuery.sortField) {
            case 'name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            case 'email':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension
                    .sortArray(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);

        }

        return new QueryResultsModel(result, _state.totalCount, '');
    }
);

export const selectUsersShowInitWaitingMessage = createSelector(
    selectUsersState,
    _state => _state.showInitWaitingMessage
);

export const selectHasUsersInStore = createSelector(
    selectUsersState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

export const selectUserExistsStatus = createSelector(
    selectUsersState,
    _state => _state.userExistsStatus
);
