import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class AssetClassTenureService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/asset-class-tenures`;
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    getByAc(acId: number, typeId: number): Observable<any> {
        const headers = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/${acId}/${typeId}`, {headers});
    }
}