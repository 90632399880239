// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ReportDeliveryMethodsService} from '../_services/report-delivery-method.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allReportDeliveryMethodsLoaded} from '../_selectors/report-delivery-method.selectors';
// Actions
import {
    AllReportDeliveryMethodsLoaded,
    AllReportDeliveryMethodsRequested,
    ReportDeliveryMethodActionTypes,
    ReportDeliveryMethodsPageRequested,
    ReportDeliveryMethodsPageLoaded,
    ReportDeliveryMethodUpdated,
    ReportDeliveryMethodsPageToggleLoading,
    ReportDeliveryMethodDeleted,
    ReportDeliveryMethodOnServerCreated,
    ReportDeliveryMethodCreated,
    ReportDeliveryAdminRestored,
    ReportDeliveryDeletedFromAdminTrash,
    ReportDeliveryDeletedFromTrash,
    ReportDeliveryOnServerAdminRestored,
    ReportDeliveryOnServerRestored,
    ReportDeliveryRestored,
    ReportDeliveryTrashFlushed,
    ReportDeliveryMethodsActionToggleLoading
} from '../_actions/report-delivery-method.actions';

@Injectable()
export class ReportDeliveryMethodEffects {
    showPageLoadingDispatcher = new ReportDeliveryMethodsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ReportDeliveryMethodsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ReportDeliveryMethodsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<AllReportDeliveryMethodsRequested>(ReportDeliveryMethodActionTypes.AllReportDeliveryMethodsRequested),
            withLatestFrom(this.store.pipe(select(allReportDeliveryMethodsLoaded))),
            filter(([action, isAllReportDeliveryMethodLoaded]) => !isAllReportDeliveryMethodLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllReportDeliveryMethodsLoaded({reportDeliveryMethods: res.data});
            })
        );

    @Effect()
    loadReportDeliveryMethodPage$ = this.actions$
        .pipe(
            ofType<ReportDeliveryMethodsPageRequested>(ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findReportDeliveryMethods(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ReportDeliveryMethodsPageLoaded({
                    reportDeliveryMethods: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryMethodUpdated>(ReportDeliveryMethodActionTypes.ReportDeliveryMethodUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryMethodOnServerCreated>(ReportDeliveryMethodActionTypes.ReportDeliveryMethodOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ReportDeliveryMethodCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushReportDeliveryMethodTrash$ = this.actions$
        .pipe(
            ofType<ReportDeliveryTrashFlushed>(ReportDeliveryMethodActionTypes.ReportDeliveryTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryOnServerRestored>(ReportDeliveryMethodActionTypes.ReportDeliveryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ReportDeliveryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryOnServerAdminRestored>(ReportDeliveryMethodActionTypes.ReportDeliveryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ReportDeliveryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryMethodDeleted>(ReportDeliveryMethodActionTypes.ReportDeliveryMethodDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryDeletedFromTrash>(ReportDeliveryMethodActionTypes.ReportDeliveryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashReportDeliveryMethod$ = this.actions$
        .pipe(
            ofType<ReportDeliveryDeletedFromAdminTrash>(ReportDeliveryMethodActionTypes.ReportDeliveryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ReportDeliveryMethodsService, private store: Store<AppState>) {
    }
}
