import { AcLandTenure } from '../../comparable/_models/asset-class-tenure.model';
import {BaseModel} from '../../_base/crud';

export class AssetClassLandAreaComponentModel extends BaseModel {
    id: number;
    order: number;
    unit_area_measurement_id: number;
    unit_area_measurement_name: string;
    unit_area_measurement_acronym: string;
    surface: number;
    limited_use: string;
    underground_restriction: number;
    aerial_restriction: number;
    unit_length_measurement_id: number;
    unit_length_measurement_name: string;
    unit_length_measurement_acronym: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.order = 0;
        this.unit_area_measurement_id = undefined;
        this.unit_area_measurement_name = '';
        this.unit_area_measurement_acronym = '';
        this.surface = 0;
        this.limited_use = '';
        this.underground_restriction = 0;
        this.aerial_restriction = 0;
        this.unit_length_measurement_id = undefined;
        this.unit_length_measurement_name = '';
        this.unit_length_measurement_acronym = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}