<div class="row">
    <div class="col-12">
        <div style="display: flex; justify-content: flex-end; margin-bottom: 1em;">
            <div *ngIf="isLV && isLV !== isPM" class="mr-2">
                <div style="margin-bottom: 14px;">Only Project Manager can change the lead valuer</div>
            </div>
            <div ngbDropdown placement="bottom-right" *ngIf="!isTemplate && !readonly" class="dropdown dropdown-inline">
                <button ngbDropdownToggle type="button" class="btn btn-label-brand btn-bold btn-sm dropdown-toggle" style="cursor: pointer !important;">Add</button>
                <div ngbDropdownMenu style="width: 230px;">
                    <ul class="navi navi-hover">
                        <li class="navi-item">
                            <a class="navi-link" href="javascript:;" (click)="addmember()">
                                <span class="navi-icon">
                                    <i class="flaticon2-add"></i>
                                </span>
                                <span class="navi-text">
                                    Add Team Member
                                </span>
                            </a>
                        </li>
                        <li class="navi-item">
                            <a class="navi-link" href="javascript:;" (click)="addValuersFromTemplate()">
                                <span class="navi-icon">
                                    <i class="flaticon2-add"></i>
                                </span>
                                <span class="navi-text">
                                    Add from template
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <button *ngIf="isTemplate"
                [disabled]="readonly"
                mat-raised-button
                color="primary"
                type="button"
                (click)="addmember()">
                Add Team Member
            </button>
        </div>
    </div>
</div>
<div class="row" [ngClass]="{'readonly': readonly}" style="min-height: 200px;">
    <div class="col-12">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Team Member</mat-header-cell>
                <mat-cell *matCellDef="let valuer">
                    <ng-container *ngIf="isTemplate">
                        <div class="user-tooltip-wrapper">
                            <kt-user-tooltip [user]="convertMember(valuer)"></kt-user-tooltip>
                            {{valuer.name}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isTemplate">
                        {{valuer.name}}
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="qualification">
                <mat-header-cell *matHeaderCellDef>Qualification</mat-header-cell>
                <mat-cell *matCellDef="let valuer">
                    {{valuer.qualification}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="is_lead_valuer">
                <mat-header-cell *matHeaderCellDef>Lead Valuer</mat-header-cell>
                <mat-cell *matCellDef="let valuer">
                    <mat-checkbox [checked]="valuer.lead_valuer"
                        [disabled]="readonly || valuer.lead_valuer || valuer.qualification_id == 2 || isReportingTaskSelected()"
                        (change)="onChangeLeadValuer($event, valuer)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tasks">
                <mat-header-cell *matHeaderCellDef>Default Task</mat-header-cell>
                <mat-cell *matCellDef="let valuer">
                    <mat-select #select 
                        placeholder="No default tasks"
                        [(value)]="valuer.task_ids"
                        (openedChange)="onSelectPanelToggle($event, select, valuer)" multiple>
                        <mat-option 
                            *ngFor="let task of tpTasks" 
                            [value]="task.id" 
                            [disabled]="!taskAvailable(valuer.valuer, task)">
                            {{task.name}}
                        </mat-option>
                    </mat-select>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let valuer; let i = index">
                    <button mat-icon-button color="warn" matTooltip="Delete the Team Member"
                        (click)="deleteValuer(valuer, i)"
                        type="button"
                        [disabled]="readonly">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>