import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from "@ngrx/effects"
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators'
import { 
    ValuationProcessActionTypes, 
    RequestValuationProcessData, 
    ValuationProcessDataLoaded,
    AddComparableToValuationProcess, 
    RemoveComparableFromValuationProcess,
    ResetStateOfValuationProcess,
    StoreValuationProcessData,
    ImportMultipleComparablestoValuationProcess,
    UnimportValuationFromValuationProcess
} from '../_actions/valuation-process.actions'
import { ValuationProcessService } from '../_services/valuation-process.service'

import * as assumptionDepartureActions from '../../valuation/_actions/assumption_departure.actions';
import * as valuationNotesActions from '../../valuation/_actions/valuation-notes.action';
import { select, Store } from '@ngrx/store'
import { AppState } from '../../reducers'
import { AllPropertyDetailReportsRequested, AllTpTaskRequested } from '../../asset_class'
import { AllAssetClassSEROfTPRequested } from '../../asset_class/_actions/asset-class-ser.action'
import { AllTpFileOfTPRequested } from '../../asset_class/_actions/tp-file.actions'
import { FullComparablesRequested, ValuationProcessCreateSizeCriterions } from '../_actions'
import { combineLatest } from 'rxjs'
import { selectValuationProcessSelectedConsiderations, selectValuationProcessSizeCriterions } from '../_selectors'
import { selectValuationFullComparable, selectValuationFullComparables } from '../_selectors/valuation-comparable-full.selectors'
import { ValuationProcessLoadSizeCriterions, ValuationProcessSizeCriterionAddComparable, ValuationProcessSizeCriterionRemoveComparable, ValuationProcessSizeCriterionReset } from '../_actions/size_criterion.actions'
import { ValuationProcessAdjustmentsAddComparable, ValuationProcessAdjustmentsRemoveComparable, ValuationProcessAdjustmentsReset, ValuationProcessLoadAdjustments, ValuationProcessUnimportAdjustments } from '../_actions/adjustment.actions'
import { ValuationProcessCreateTargetPropertyCriterions, ValuationProcessCriterionsAddComparable, ValuationProcessCriterionsRemoveComparable, ValuationProcessCriterionsReset, ValuationProcessLoadCriterionFromServer, ValuationProcessLoadCriterions } from '../_actions/criterion.actions'
import { ValuationProcessCreateConsiderationCriterion, ValuationProcessConsiderationCriterionAddComparable, ValuationProcessConsiderationCriterionRemoveComparable, ValuationProcessLoadConsiderationCriterion } from '../_actions/consideration-criterion.actions'
import { ValuationProcessLoadValuation, ValuationProcessUnimportValuationValue, ValuationProcessValuationAddComparable, ValuationProcessValuationRemoveComparable, ValuationProcessValuationReset } from '../_actions/valuation.actions'
import { LoadValuationLandMarks } from '../_actions/valuation-landmark.actions'
import * as valuationVpga10MattersActions from '../../valuation/_actions/vpga10-matters.actions';
import * as valuationSRERActions from '../../valuation/_actions/valuation-srer.actions';
import { ImportedComparablesRequested, LoadSelectedPropertySubTypes } from '../_actions/valuation-comparable.actions'
import * as _ from 'lodash'
import * as LiquidationValuationActions from '../_actions/liquidation-valuation.actions'

@Injectable()
export class ValuationProcessEffects {
    constructor(
        private actions$: Actions,
        private service: ValuationProcessService,
        private store$: Store<AppState>
    ) {}

    @Effect()
    requestValuationProcessData$ = this.actions$.pipe(
        ofType<RequestValuationProcessData>(ValuationProcessActionTypes.RequestValuationProcessData),
        switchMap(({payload}) => {
            return this.service.fetchData(payload.targetPropertyId, payload.valuationId)
        }),
        tap((response) => {
            this.store$.dispatch(new assumptionDepartureActions.LoadData({
                default: response.data.assumptionDepartures.default_assumptions,
                special: response.data.assumptionDepartures.special_assumptions,
                departure: response.data.assumptionDepartures.departures
            }))
            this.store$.dispatch(new valuationNotesActions.LoadData({
                data: response.data.notes
            }))
            this.store$.dispatch(new valuationVpga10MattersActions.LoadData({
                data: response.data.vpga10_matters
            }));
            this.store$.dispatch(new valuationSRERActions.LoadData({data: response.data.sers}));
            this.store$.dispatch(new LoadValuationLandMarks({
                landmarks: response.data.landMarks
            }))

            if (response.data.liquidationValues) {
                this.store$.dispatch(LiquidationValuationActions.setLiquidationValues({
                    values: response.data.liquidationValues
                }))
            }
            this.store$.dispatch(new FullComparablesRequested({refNums: response.comparables.map(com => com.ref_num)}))
            this.store$.dispatch(new LoadSelectedPropertySubTypes({propertySubTypeIds: response.data.propertySubTypeIds}))
        }),
        map(response => {
            return new ValuationProcessDataLoaded({
                data: response.data, 
                comparables: response.comparables, 
                adjustmentTab: response.adjustmentTab,
            })
        })
    )

    @Effect({dispatch: false})
    storeValuationProcessData$ = this.actions$.pipe(
        ofType<StoreValuationProcessData>(ValuationProcessActionTypes.StoreValuationProcessData),
        switchMap(({payload}) => {
            return this.service.storeData(payload.targetPropertyId, payload.valuationId, payload.body)
        })
    )

    @Effect({dispatch: false})
    targetPropertyLoaded$ = this.actions$.pipe(
        ofType<ValuationProcessDataLoaded>(ValuationProcessActionTypes.ValuationProcessDataLoaded),
        tap(({payload}) => {
            const id = payload.data.targetProperty.id
            this.store$.dispatch(new AllPropertyDetailReportsRequested({
                tpID: id
            }));
            this.store$.dispatch(new AllTpFileOfTPRequested({tpId: id}));
            this.store$.dispatch(new AllAssetClassSEROfTPRequested({tpId: id}));
            this.store$.dispatch(new AllTpTaskRequested({tpId: id}));

            // Valuation Process
            if (payload.adjustmentTab == null) {
                this.store$.dispatch(new ValuationProcessCreateSizeCriterions({
                    sizes: payload.data.targetProperty.info.sizes,
                    refNum: 'tp'
                }))
                this.store$.dispatch(new ValuationProcessCreateTargetPropertyCriterions(
                    {targetProperty: payload.data.targetProperty.info}
                ))
                this.store$.dispatch(new ValuationProcessCreateConsiderationCriterion({
                    tenure: payload.data.valuation.tenureName,
                    considerationType: payload.data.valuation.considerationTypeName,
                    countryCurrency: payload.data.targetProperty.countryCurrency
                }))
            } else {
                this.store$.dispatch(new ValuationProcessLoadSizeCriterions({crits: payload.adjustmentTab.sizecriterions}))
                this.store$.dispatch(new ValuationProcessLoadConsiderationCriterion({crit: payload.adjustmentTab.considerationCrit}))
                this.store$.dispatch(new ValuationProcessLoadCriterionFromServer({crits: payload.adjustmentTab.criterions}))
                this.store$.dispatch(new ValuationProcessLoadAdjustments({adjustments: payload.adjustmentTab.adjustments}))
                this.store$.dispatch(new ValuationProcessLoadValuation({state: payload.adjustmentTab.valuation}))
            }
        } )
    )

    @Effect({dispatch: false})
    comparableAdd$ = this.actions$.pipe(
        ofType<AddComparableToValuationProcess>(ValuationProcessActionTypes.AddComparableToValuation),
        switchMap(({payload}) => {
            return combineLatest([
                this.store$.pipe(select(selectValuationFullComparable(payload.refNum)), take(1)),
                this.store$.pipe(select(selectValuationProcessSelectedConsiderations)).pipe(distinctUntilChanged(_.isEqual))
            ])
        }),
        tap(([comparable, considerations]) => {
            this.store$.dispatch(new ValuationProcessSizeCriterionAddComparable({refNum: comparable.refNum, sizes: comparable.sizes}))
            this.store$.dispatch(new ValuationProcessAdjustmentsAddComparable({refNum: comparable.refNum}))
            const consideration = considerations.find(con => con.refNum == comparable.refNum)
            this.store$.dispatch(new ValuationProcessCriterionsAddComparable({comparable, selectedConsiderationId: consideration ? consideration.id : undefined}))

            this.store$.dispatch(new ValuationProcessConsiderationCriterionAddComparable({comparable, selectedConsiderationId: consideration ? consideration.id : null}))

            this.store$.dispatch(new ValuationProcessValuationAddComparable({refNum: comparable.refNum}))
        })
    )

    @Effect()
    comparablesAdd$ = this.actions$.pipe(
        ofType<ImportMultipleComparablestoValuationProcess>(ValuationProcessActionTypes.ImportMultipleComparablesToValuation),
        map(({payload}) => {
            return new ImportedComparablesRequested({
                refNums: payload.comparables.map(item => item.refNum),
                adjustmentTabData: payload.adjustmentsTabData
            }) 
        })
    )

    @Effect({dispatch: false})
    comparableRemove$ = this.actions$.pipe(
        ofType<RemoveComparableFromValuationProcess>(ValuationProcessActionTypes.RemoveComparableFromValuation),
        tap(({payload}) => {
            this.store$.dispatch(new ValuationProcessSizeCriterionRemoveComparable({refNum: payload.refNum}))
            this.store$.dispatch(new ValuationProcessAdjustmentsRemoveComparable({refNum: payload.refNum}))
            this.store$.dispatch(new ValuationProcessCriterionsRemoveComparable({refNum: payload.refNum}))
            this.store$.dispatch(new ValuationProcessConsiderationCriterionRemoveComparable({refNum: payload.refNum}))
            this.store$.dispatch(new ValuationProcessValuationRemoveComparable({refNum: payload.refNum}))
        })
    )

    @Effect({dispatch: false})
    resetState$ = this.actions$.pipe(
        ofType<ResetStateOfValuationProcess>(ValuationProcessActionTypes.ResetState),
        tap(_ => {
            this.store$.dispatch(new ValuationProcessAdjustmentsReset())
            this.store$.dispatch(new ValuationProcessCriterionsReset())
            this.store$.dispatch(new ValuationProcessSizeCriterionReset())
            this.store$.dispatch(new ValuationProcessValuationReset())
            this.store$.dispatch(LiquidationValuationActions.resetLiquidationValues())
        })
    )

    @Effect({dispatch: false})
    unimportValuation$ = this.actions$.pipe(
        ofType<UnimportValuationFromValuationProcess>(ValuationProcessActionTypes.UnimportValuation),
        tap(_ => {
            this.store$.dispatch(new ValuationProcessUnimportAdjustments())
            this.store$.dispatch(new ValuationProcessUnimportValuationValue())
        })
    )
}