import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PropertyDetailReportModel } from '../_models/property-detail-report.model';
import { PropertyDetailReportState } from '../_reducers/property-detail-report.reducers';

export const selectPropertyDetailReportState = createFeatureSelector<PropertyDetailReportState>('property-detail-report');

export const selectPropertyDetailReportById = (id: number) => createSelector(
    selectPropertyDetailReportState,
    state => state.entities[id]
);

export const selectLastCreatedPropertyDetailReportId = createSelector(
    selectPropertyDetailReportState,
    state => state.lastCreatedReportId
);

export const selectPropertyDetailReportIsGenerating = (tpId: number) => createSelector(
    selectPropertyDetailReportState,
    state => {
        return state.isGenerating[tpId];
    }
)

export const selectPropertyDetailReportsByTpId = (tpId: number) => createSelector(
    selectPropertyDetailReportState,
    state => {
        if (!state.isAllReportsLoaded[tpId]) {
            return null;
        }
        const reports: PropertyDetailReportModel[] = [];
        for (const key in state.entities) {
            const val = state.entities[key];
            if (val.tp_id === tpId) {
                reports.push(val);
            }
        }
        reports.sort((a, b) => {
            const d1 = new Date(b.created_at);
            const d2 = new Date(a.created_at);
            return d1.getTime() - d2.getTime();
        })


        return reports.length > 0 ? [reports[0]] : [];
    }
)