import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    AssetClassResidentialFacilitiesService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassResidentialFacilitiesGetResolver implements Resolve<any> {
    constructor(public assetClassResidentialFacilitiesService: AssetClassResidentialFacilitiesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const apartmenId = Number(route.params.apartment_id);
        return this.assetClassResidentialFacilitiesService.getAllAssetClassResidentialFacilities(apartmenId);
    }
}
