import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AgencyModel} from '../_models/agency.model';
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';
import { map } from 'rxjs/operators';


@Injectable()
export class AgencyService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/agencies`;
    API_PUB_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/pub/agencies`;
    API_MM_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/multimedia/agencies`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // Agencies
    find(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };

        return this.http.get<any>(this.API_URL, {
            headers: httpHeaders,
            params: params
        });
    }

    getAll(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {
            headers: httpHeaders
        });
    }


    getById(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/${id}`, {
            headers: httpHeaders
        });
    }

    pubGetById(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_PUB_URL + `/${id}`, {
            headers: httpHeaders
        });
    }

    getMMById(id: number): Observable<any> {
        const httpHeaders = this.httpUtils.getMMHTTPHeaders();
        return this.http.get<any>(this.API_MM_URL + `/${id}`, {
            headers: httpHeaders
        });
    }


    // CREATE =>  POST: add a new agency to the server
    create(item: AgencyModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const reqBody = {agency: item};
        return this.http.post(this.API_URL, reqBody, {headers: httpHeaders});
    }


    // UPDATE => PUT: update the agency on the server
    update(agency: AgencyModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const reqBody = {
            agency
        }
        return this.http.put(this.API_URL + `/${agency.id}`, reqBody, {headers: httpHeaders});
    }

    // DELETE => delete the agency from the server
    delete(id: number): Observable<any> {
        const url = `${this.API_URL}/${id}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url, {
            headers: httpHeaders
        });
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {
            headers: httpHeaders
        });
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {
            headers: httpHeaders
        });
    }

    deleteFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${id}`, {
            headers: httpHeaders
        });
    }

    // delete from admin trash
    deleteFromAdminTrash(keyPlaceId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${keyPlaceId}`, {
            headers: httpHeaders
        });
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {
            headers: httpHeaders
        });
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {
            headers: httpHeaders
        });
    }

    getByAssignmentId(assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/byassignment/${assignmentId}`, {
            headers: httpHeaders
        });
    }

    fetchListing() {
        const httpHeaders = this.authService.getAuthHeaders()
        return this.http.get<{data: {id: number, name: string}[]}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/agencies/listing`, {
            headers: httpHeaders
        }).pipe(
            map(response => response.data)
        )
    }

    fetch(id: number) {
        return this.http.get<{data: {
            id: number,
            name: string,
            city: {
                id: number,
                name: string
            } | null,
            country: {
                id: number,
                name: string
            } | null,
            documents: Array<{
                id: number
                title: string
                date: string
            }>,
            post_valuation_delivery_service: {
                hourly_rate: number,
                free_hours: number,
                validity_of_fees: number,
                include_text: string
            } | null
        }}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/agencies/${id}`,
            {
                headers: this.authService.getAuthHeaders()
            }
        ).pipe(
            map(response => response.data)
        )
    }
}
