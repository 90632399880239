import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { UpdateToeReportTask } from 'src/app/core/toe/_actions/toe-report-task.actions';
import { ToeReportTaskModel } from 'src/app/core/toe/_models/toe-report-task.model';

@Component({
  selector: 'kt-edit-report-task-info',
  templateUrl: './edit-report-task-info.component.html',
  styleUrls: ['./edit-report-task-info.component.scss']
})
export class EditReportTaskInfoComponent implements OnInit {
  viewLoading = false;
  form: UntypedFormGroup;
  managerId = 0;
  leadValuerId = 0;
  users: User[] = [];
  pmIsWorker: boolean;
  task: ToeReportTaskModel;
  constructor(
    public dialogRef: MatDialogRef<EditReportTaskInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.users = this.data.users;
    this.managerId = this.data.manager_id;
    this.leadValuerId = this.data.lead_valuer_id;
    this.pmIsWorker = this.data.pmIsWorker;
    this.task = this.data.task;

    this.form = this.fb.group({
      user: [this.data.currentUserId, Validators.required],
      date: [moment(this.data.currentDate)]
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }

  save() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAllAsTouched();
      })
      return;
    }

    let dateValue = '';
    if (controls.date.value) {
      dateValue = controls.date.value.format('YYYY-MM-DD HH:mm');
    }

    this.viewLoading = true;
    const changedTask = Object.assign({}, this.task) as ToeReportTaskModel;
    changedTask.assignee_id = controls.user.value;
    changedTask.date = dateValue;

    this.store.dispatch(new UpdateToeReportTask({task: changedTask}));

    of(undefined).pipe(delay(1000)).subscribe(() => {
      this.viewLoading = false;
      this.dialogRef.close({item: null});
    })
  }

  userRole(_id: number): string {
    if (_id == this.managerId && this.managerId == this.leadValuerId) {
        return ' (Project manager & Lead Valuer)';
    } else if (_id == this.managerId && this.pmIsWorker) {
        return ' (Project manager & Supporting Staff) '
    } else if (_id == this.managerId) {
        return ' (Project manager)';
    } else if (_id == this.leadValuerId) {
        return ' (Lead Valuer)';
    }
    return '';
  }

}
