import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllDefaultAssumptionsRequested, DefaultAssumption, selectAllDefaultAssumptions } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ITpTemplateSelectedAssumption } from 'src/app/core/template/_models/tp-template.model';
import { AddAdModalComponent } from '../assumptions-and-departures/add-ad-modal/add-ad-modal.component';

type ItemData = DefaultAssumption & {type: number}

@Component({
  selector: 'kt-assumptions-selection',
  templateUrl: './assumptions-selection.component.html',
  styleUrls: ['./assumptions-selection.component.scss']
})
export class AssumptionsSelectionComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() selectedAssumptions: ITpTemplateSelectedAssumption[];
  @Input() customAssumptions: DefaultAssumption[];
  assumptionsData: ItemData[] = [];
  _customAssumptions: DefaultAssumption[] = [];

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._customAssumptions = this.customAssumptions.map(el => Object.assign({}, el));
    this.store.dispatch(new AllDefaultAssumptionsRequested());
    this.store.select(selectAllDefaultAssumptions).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.assumptionsData = [];
      if (res) {
        let assumptionsData: ItemData[] = res.map(item => {
          const selected = this.selectedAssumptions.filter(a => a.type == 0).some(el => el.assumption_id == item.id);
          const temp = Object.assign({}, item);
          temp.selected = selected;
          return {
            ...temp,
            type: 0
          } as ItemData;
        });
        let customAssumptions = this.customAssumptions.map(item => {
          const selected = this.selectedAssumptions.filter(a => a.type == 1).some(el => el.assumption_id == item.id);
          const temp = Object.assign({}, item);
          temp.selected = selected;
          return {
            ...temp,
            type: 1
          } as ItemData;
        })
        this.assumptionsData = assumptionsData.concat(customAssumptions);
      }
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addItem() {
    const dialogRef = this.dialog.open(AddAdModalComponent, {
      data: {
        itemType: 'assumption',
        title: 'Add Assumption',
        placeholder: 'Assumption'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const assumption = new DefaultAssumption();
      assumption.clear();
      assumption.name = res.text;
      assumption.selected = true;
      this._customAssumptions.push(assumption);
      const _tmpArray = Object.assign([], this.assumptionsData) as ItemData[];
      const newAssumption = Object.assign({}, assumption) as ItemData;
      newAssumption.type = 1;
      _tmpArray.push(newAssumption);
      this.assumptionsData = _tmpArray;
    })
  }

  changeSelection(event: MatCheckboxChange, index: number) {
    if (event.checked) {
      this._selectingNone(index);
    }
    const _tmpArray = Object.assign([], this.assumptionsData);
    _tmpArray[index].selected = event.checked;
    this.assumptionsData = _tmpArray;
  }

  private _selectingNone(index: number) {
    if (index == 0) {
      this.assumptionsData = this.assumptionsData.map(el => {
        el.selected = false;
        return el;
      })
    } else {
      const _tmpArray = Object.assign([], this.assumptionsData);
      _tmpArray[0].selected = false;
      this.assumptionsData = _tmpArray;
    }
  }

  public getData(): {
    selected: ITpTemplateSelectedAssumption[],
    customAssumptions: DefaultAssumption[] 
  } {
    const selected = this.assumptionsData.filter(el => el.selected).map(el => ({
      assumption_id: el.id,
      type: el.type,
      assumption_name: el.name
    }))
    return {
      selected,
      customAssumptions: this._customAssumptions
    }
  }

}
