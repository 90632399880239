import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { InformedConsentReportActions, InformedConsentReportActionTypes } from "../_actions/informed-consent-report.actions";
import { InformedConsentReport } from "../_models/informed-consent-report.model";

export interface InformedConsentReportState extends EntityState<InformedConsentReport> {
	isGeneratingReport: boolean;
}
export const adapter: EntityAdapter<InformedConsentReport> = createEntityAdapter<InformedConsentReport>();
export const initialInformedConsentReportState: InformedConsentReportState = adapter.getInitialState({
	isGeneratingReport: false
});

export function informedConsentReducer(state = initialInformedConsentReportState, action: InformedConsentReportActions): InformedConsentReportState {
	switch (action.type) {
		case InformedConsentReportActionTypes.GenerateReport:
			return {
				...state,
				isGeneratingReport: true
			}
		case InformedConsentReportActionTypes.ReportGenerated:
			if (action.payload.success) {
				return {
					...state,
					isGeneratingReport: false
				}
			} else {
				return {
					...state,
					isGeneratingReport: false
				}
			}
		default: 
			return state
	}
}