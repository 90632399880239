import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllValuationStandardRequested, selectAllValuationStandard, ValuationStandard } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';

interface IModalData {
  itemType: 'assumption'|'special-assumption'|'departure',
  title: string,
  placeholder: string;
}

@Component({
  selector: 'kt-add-ad-modal',
  templateUrl: './add-ad-modal.component.html',
  styleUrls: ['./add-ad-modal.component.scss']
})
export class AddAdModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  valuationStandards: ValuationStandard[] = [];
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<AddAdModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalData,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      text: [null, Validators.required],
      valuation_standard_id: [undefined]
    });

    if (this.data.itemType === 'departure') {
      this.form.controls.valuation_standard_id.setValidators(Validators.required);
      this.form.controls.valuation_standard_id.updateValueAndValidity();

      this.store.dispatch(new AllValuationStandardRequested());

      this.store.select(selectAllValuationStandard)
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(res => {
            this.valuationStandards = [];
            if (res) {
                this.valuationStandards = res;
            }
        });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  createItem() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      })
      return;
    }

    const valuationStandardId = Number(controls.valuation_standard_id.value);
    const valuationStandardName = this.valuationStandards.find(vs => vs.id === valuationStandardId)?.name;

    this.dialogRef.close({
      text: controls.text.value,
      valuationStandardId,
      valuationStandardName
    });
  }

}
