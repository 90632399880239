import {SubTypeCategoryModel} from '../_models/sub-type-category.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SubTypeCategorysState} from '../_reducers/sub-type-category.reducers';
import * as fromSubTypeCategory from '../_reducers/sub-type-category.reducers';
import {each} from 'lodash';

export const selectSubTypeCategorysState = createFeatureSelector<SubTypeCategorysState>('subTypeCategories');

export const selectSubTypeCategoryById = (topPropertyTypeId: number) => createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => topPropertyTypesState.entities[topPropertyTypeId]
);

export const selectAllSubTypeCategorys = createSelector(
    selectSubTypeCategorysState,
    fromSubTypeCategory.selectAll
);

export const selectAllSubTypeCategorysIds = createSelector(
    selectSubTypeCategorysState,
    fromSubTypeCategory.selectIds
);

export const allSubTypeCategorysLoaded = createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => topPropertyTypesState.isAllSubTypeCategorysLoaded
);


export const selectSubTypeCategorysPageLoading = createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => topPropertyTypesState.listLoading
);

export const selectSubTypeCategorysActionLoading = createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => topPropertyTypesState.actionLoading
);

export const selectLastCreatedSubTypeCategoryId = createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => topPropertyTypesState.lastCreatedSubTypeCategoryId
);

export const selectSubTypeCategorysShowInitWaitingMessage = createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => topPropertyTypesState.showInitWaitingMessage
);


export const selectTrashedSubTypeCategoryCount = createSelector(
    selectSubTypeCategorysState,
    (topPropertyTypesState) => topPropertyTypesState.totalTrashed
);

export const selectAdminTrashedSubTypeCategoryCount = createSelector(
    selectSubTypeCategorysState,
    (topPropertyTypesState) => topPropertyTypesState.totalAdminTrashed
);


export const selectSubTypeCategoryQueryResult = createSelector(
    selectSubTypeCategorysState,
    topPropertyTypesState => {
        const items: SubTypeCategoryModel[] = [];
        each(topPropertyTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SubTypeCategoryModel[] = httpExtension.sortArray(items, topPropertyTypesState.lastQuery.sortField, topPropertyTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, topPropertyTypesState.totalCount, '', topPropertyTypesState.totalTrashed);
    }
);
