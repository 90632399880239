import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BathroomFitting, BathroomFittingsService } from "src/app/core/linked-tables";
import { initialInformedConsentReportState } from "src/app/core/toe/_reducers/informed-consent-report.reducer";

@Component({
  templateUrl: './bathroom-fitting-add-dialog.component.html',
  styleUrls: ['./bathroom-fitting-add-dialog.component.scss']
})
export class BathroomFittingAddDialogComponent implements OnInit, OnDestroy {
  bathroomFitting: BathroomFitting
  bathroomFittingForm: UntypedFormGroup
  hasFormErrors = false

  private componentSubscriptions: Subscription

  constructor(
    public dialogRef: MatDialogRef<BathroomFittingAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private service: BathroomFittingsService
  ) {}

  ngOnInit(): void {
    this.bathroomFitting = new BathroomFitting() 
    this.bathroomFitting.clear()
    this.initForm()
  }

  initForm() {
    this.createForm()
  }

  createForm() {
    this.bathroomFittingForm = this.fb.group({
      name: [this.bathroomFitting.name, Validators.required]
    })
  }

  prepareFitting(): BathroomFitting {
    const controls = this.bathroomFittingForm.controls;
    const _bathroomFitting = new BathroomFitting()
    _bathroomFitting.clear()
    _bathroomFitting.id = this.bathroomFitting.id
    _bathroomFitting.name = controls.name.value
    _bathroomFitting.property_sub_type_id = this.data.property_sub_type_id
    return _bathroomFitting
  }

  createFitting() {
    this.hasFormErrors = false
    const controls = this.bathroomFittingForm.controls
    if (this.bathroomFittingForm.invalid) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched())
      this.hasFormErrors = true;
      return;
    }

    const _bathroomFitting = this.prepareFitting()

    this.service.createBathroomFitting(_bathroomFitting).subscribe(res => {
      if (res.data) {
        this.dialogRef.close({bathroomFitting: res.data})
        return
      }
    })
  }

  isFormValid() {
    return this.bathroomFittingForm.controls.name.value
  }

  close() {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe()
    }
  }
}