// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ParkingTypeActions, ParkingTypeActionTypes} from '../_actions/parking-type.actions';
// Models
import {ParkingTypeModel} from '../_models/parking-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ParkingTypesState extends EntityState<ParkingTypeModel> {
    isAllParkingTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: ParkingTypeModel[];
    lastCreatedParkingTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ParkingTypeModel> = createEntityAdapter<ParkingTypeModel>();

export const initialParkingTypeState: ParkingTypesState = adapter.getInitialState({
    isAllParkingTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedParkingTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function parkingTypeReducer(state = initialParkingTypeState, action: ParkingTypeActions): ParkingTypesState {
    switch (action.type) {
        case ParkingTypeActionTypes.ParkingTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedParkingTypeId: undefined
            };
        case ParkingTypeActionTypes.ParkingTypeActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ParkingTypeActionTypes.ParkingTypeOnServerCreated:
            return {
                ...state
            };
        case ParkingTypeActionTypes.ParkingTypeCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedParkingTypeId: action.payload.item.id
            });
        case ParkingTypeActionTypes.ParkingTypeUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ParkingTypeActionTypes.AllParkingTypesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllParkingTypesLoaded: true
            });
        case ParkingTypeActionTypes.ParkingTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ParkingTypeActionTypes.ParkingTypesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialParkingTypeState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ParkingTypeActionTypes.ParkingTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ParkingTypeActionTypes.ParkingTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ParkingTypeActionTypes.ParkingTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ParkingTypeActionTypes.ParkingTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ParkingTypeActionTypes.ParkingTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ParkingTypeActionTypes.ParkingTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
