import {BaseOfValue} from '../_models/base-of-value.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {BaseOfValuesState} from '../_reducers/base-of-value.reducer';
import * as fromBaseOfValue from '../_reducers/base-of-value.reducer';
import {each} from 'lodash';

export const selectBaseOfValuesState = createFeatureSelector<BaseOfValuesState>('baseOfValues');

export const selectBaseOfValueById = (baseOfValueId: number) => createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => baseOfValuesState.entities[baseOfValueId]
);

export const selectAllBaseOfValues = createSelector(
    selectBaseOfValuesState,
    fromBaseOfValue.selectAll
);

export const selectAllBaseOfValuesIds = createSelector(
    selectBaseOfValuesState,
    fromBaseOfValue.selectIds
);

export const allBaseOfValuesLoaded = createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => baseOfValuesState.isAllBaseOfValuesLoaded
);


export const selectBaseOfValuesPageLoading = createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => baseOfValuesState.listLoading
);

export const selectBaseOfValuesActionLoading = createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => baseOfValuesState.actionLoading
);

export const selectLastCreatedBaseOfValueId = createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => baseOfValuesState.lastCreatedBaseOfValueId
);

export const selectBaseOfValuesShowInitWaitingMessage = createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => baseOfValuesState.showInitWaitingMessage
);


export const selectTrashedBaseOfValueCount = createSelector(
    selectBaseOfValuesState,
    (baseOfValuesState) => baseOfValuesState.totalTrashed
);

export const selectAdminTrashedBaseOfValueCount = createSelector(
    selectBaseOfValuesState,
    (baseOfValuesState) => baseOfValuesState.totalAdminTrashed
);


export const selectBaseOfValueQueryResult = createSelector(
    selectBaseOfValuesState,
    baseOfValuesState => {
        const items: BaseOfValue[] = [];
        each(baseOfValuesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BaseOfValue[] = httpExtension.sortArray(items, baseOfValuesState.lastQuery.sortField, baseOfValuesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, baseOfValuesState.totalCount, '', baseOfValuesState.totalTrashed);
    }
);
