import { Injectable } from "@angular/core";
import { Update } from "@ngrx/entity";
import { VAssetClassSize, SizeCriterionModel } from '../_models'
import * as _ from 'lodash'

@Injectable()
export class ValuationProcessSizeCriterionsService {
    createCriterions( refNum: string, sizes: VAssetClassSize[]): SizeCriterionModel[] {
        return sizes.map(size => {
            const values = {}
            values[refNum] = size.size
            return {
                id: 0,
                name: size.standardMeasurementName,
                publicName: size.standardMeasurementName + ` (Required by ToE)`,
                type: 'Size',
                values,
                active: true,
            }
        })
    }

    createComparableCriterion(refNum: string, sizes: VAssetClassSize[]): SizeCriterionModel[] {
        return sizes.map(size => {
            const values = {}
            values[refNum] = size.size
            return {
                id: 0,
                name: size.standardMeasurementName,
                publicName: size.standardMeasurementName + ` (If selected, assumption or special assumption will be required)`,
                type: 'Size',
                values,
                active: false
            }
        })
    }

    createMultipleComparablesCriterion(comparables: {refNum: string, sizes: VAssetClassSize[]}[]): SizeCriterionModel[] {
        return _.flatten(comparables.map(comparable => this.createComparableCriterion(comparable.refNum, comparable.sizes)))
    }
}