// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {RoofCoveringActions, RoofCoveringActionTypes} from '../_actions/roof-covering.actions';
// Models
import {RoofCoveringModel} from '../_models/roof-covering.model';
import {QueryParamsModel} from '../../_base/crud';

export interface RoofCoveringsState extends EntityState<RoofCoveringModel> {
    isAllRoofCoveringsLoaded: boolean;
    queryRowsCount: number;
    queryResult: RoofCoveringModel[];
    lastCreatedRoofCoveringId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RoofCoveringModel> = createEntityAdapter<RoofCoveringModel>();

export const initialRoofCoveringState: RoofCoveringsState = adapter.getInitialState({
    isAllRoofCoveringsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedRoofCoveringId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function roofCoveringReducer(state = initialRoofCoveringState, action: RoofCoveringActions): RoofCoveringsState {
    switch (action.type) {
        case RoofCoveringActionTypes.RoofCoveringsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedRoofCoveringId: undefined
            };
        case RoofCoveringActionTypes.RoofCoveringActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case RoofCoveringActionTypes.RoofCoveringOnServerCreated:
            return {
                ...state
            };
        case RoofCoveringActionTypes.RoofCoveringCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedRoofCoveringId: action.payload.item.id
            });
        case RoofCoveringActionTypes.RoofCoveringUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case RoofCoveringActionTypes.AllRoofCoveringsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllRoofCoveringsLoaded: true
            });
        case RoofCoveringActionTypes.RoofCoveringsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case RoofCoveringActionTypes.RoofCoveringsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialRoofCoveringState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case RoofCoveringActionTypes.RoofCoveringDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case RoofCoveringActionTypes.RoofCoveringRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case RoofCoveringActionTypes.RoofCoveringDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case RoofCoveringActionTypes.RoofCoveringAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case RoofCoveringActionTypes.RoofCoveringDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case RoofCoveringActionTypes.RoofCoveringTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
