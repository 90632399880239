<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ title$|async }}
      </h3>
    </div>
  </div>

  <div class="form form-group-separator-dashed">
    <form [formGroup]="formGroup" class="form">
      <div class="card-body">
        <mat-dialog-content>
          <div class="d-flex justify-content-center">
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup [formControl]="formSelectionCtrl">
              <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                <input ngbButton type="radio" class="btn-check" [value]="'simple'" />
                Simple
              </label>
              <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                <input ngbButton type="radio" class="btn-check" [value]="'advanced'" />
                Advanced
              </label>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12 kt-margin-bottom-10-mobile">
              <h5 class="text-mad text-uppercase">Currency</h5>
              <hr />
            </div>
            <div class="col-lg-12 mb-3">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Currency" formControlName="currency">
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterCurrencyFormControl"
                      placeholderLabel="Search"
                      noEntriesFoundLabel="Currency not found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let currency of (currencies$|async)" [value]="currency">
                    {{ currency }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-12">
              <h6 class="text-mad text-uppercase">Tenure and offer</h6>
              <hr />
            </div>
            <div class="col-lg-12">
              <mat-radio-group class="row mb-2 p-1" formControlName="tenure">
                <mat-radio-button 
                  class="col-2" 
                  *ngFor="let tenure of tenures" 
                  [value]="tenure.toLowerCase()">
                  {{ tenure }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error class="tag-custom-error" *ngIf="(hasFormError$|async) && formGroup.controls.tenure.hasError('required')">
                Tenure is <strong>required</strong>
              </mat-error>
            </div>
            <div class="col-lg-12">
              <mat-radio-group class="row mb-2 p-1" formControlName="consideration_type_id">
                <mat-radio-button 
                  class="col-2" 
                  *ngFor="let considerationType of (considerationTypes$|async)" 
                  [value]="considerationType.id">
                  {{ considerationType.name }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error class="tag-custom-error" *ngIf="(hasFormError$|async) && formGroup.controls.consideration_type_id.hasError('required')">
                Consideration Type is <strong>required</strong>
              </mat-error>
            </div>
          </div>

          <div class="form-group row mb-4" *ngIf="showSaleTerms$|async">
            <div class="col-lg-12 kt-margin-bottom-10-mobile">
              <h6 class="text-mad text-uppercase kt_portlet__head-title">
                {{selectedTenure$|async}} terms
              </h6>
              <hr />
            </div>
            <div class="col-lg-12">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  placeholder="Total Consideration"
                  formControlName="sale_input_amount" mask="separator.2" thousandSeparator=","
                  [suffix]="currencySuffix$|async" />
                <mat-error>
                  Total Consideration is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group row mb-4" *ngIf="showLeaseholdTerms$|async">
            <div class="col-lg-12 kt-margin-bottom-10-mobile">
              <h6 class="text-mad text-uppercase kt_portlet__head-title">
                {{selectedTenure$|async}} terms
              </h6>
              <hr />
            </div>

            <div class="col-lg-12 mb-8">
              <mat-radio-group class="mb-2 p-1" formControlName="rent_type">
                <mat-radio-button value="headline">
                  Headline rent
                </mat-radio-button>
                <mat-radio-button value="effective">
                  Effective rent
                </mat-radio-button>
              </mat-radio-group>
              <mat-error class="tag-custom-error" *ngIf="(hasFormError$|async) && formGroup.controls.rent_type.hasError('required')">
                Rent Type is <strong>required</strong>
              </mat-error>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
              <h6>
                General Information
              </h6>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <kt-datepicker-tz-input #startDate placeholder="Start Date" formControlName="start_date" />
                <button mat-button mat-icon-button matSuffix type="button">
                  <mat-icon>today</mat-icon>
                </button>
                <button *ngIf="formGroup.controls.start_date.value" mat-button mat-icon-button matSuffix type="button"
                  (click)="onClearStartDate(startDate)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  placeholder="Lease Duration"
                  formControlName="duration" mask="separator.2" thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.LEASE_DURATION.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.LEASE_DURATION.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error>
                  Lease Duration is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select formControlName="duration_type">
                  <mat-option *ngFor="let duration of duration_types" [value]="duration.value">
                    {{duration.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  [placeholder]="rentFreePeriodLabel$|async" 
                  formControlName="rent_free_period" 
                  mask="separator.2" thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.RENT_FREE.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.RENT_FREE.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error *ngIf="formGroup.controls.rent_free_period.hasError('notNegative')">
                  Value cannot be <strong>negative</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.rent_free_period.hasError('notLesserThanLeaseOrEqualToLease')">
                  Value cannot be greater than or equal to <strong>Lease duration</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.rent_free_period.hasError('notLesserThanWriteOff')">
                  Value cannot be greater than or equal to <strong>Write off</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  [placeholder]="fittingOutPeriodLabel$|async" 
                  formControlName="fitting_out_period" 
                  mask="separator.2" 
                  thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.FITTING_OUT.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.FITTING_OUT.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error *ngIf="formGroup.controls.fitting_out_period.hasError('notNegative')">
                  Value cannot be <strong>negative</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.fitting_out_period.hasError('notLesserThanLeaseOrEqualToLease')">
                  Value cannot be greater than or equal to <strong>Lease duration</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.fitting_out_period.hasError('notLesserThanWriteOff')">
                  Value cannot be greater than or equal to <strong>Write off</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  [placeholder]="writeOffPeriodAfterLabel$|async" 
                  formControlName="write_off_period_after" 
                  mask="separator.2" 
                  thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.WRITE_OF_PERIOD.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.WRITE_OF_PERIOD.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error *ngIf="formGroup.controls.write_off_period_after.hasError('notNegative')">
                  Value cannot be <strong>negative</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.write_off_period_after.hasError('isZero')">
                  Value cannot be <strong>0</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.write_off_period_after.hasError('notLesserThanLease')">
                  Value cannot be greater than <strong>Lease Duration</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  [placeholder]="breakOptionAfterLabel$|async" 
                  formControlName="break_option_after" 
                  mask="separator.2" 
                  thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.BREAK_OPTION.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.BREAK_OPTION.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error *ngIf="formGroup.controls.break_option_after.hasError('notNegative')">
                  Value cannot be <strong>negative</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.break_option_after.hasError('notLesserThanLeaseOrEqualToLease')">
                  Value cannot be greater than or equal to <strong>Lease duration</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  placeholder="Capital payment"
                  formControlName="capital_payment" 
                  mask="separator.2" 
                  thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.CAPITAL_PAYMENT.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.CAPITAL_PAYMENT.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  placeholder="Unexpired lease terms (years)"
                  formControlName="unexpired_lease_terms" 
                  mask="separator.2" 
                  thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.LEASE_TERM.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.LEASE_TERM.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
              </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile mt-10">
              <h6>
                Rent
              </h6>
            </div>
            <div class="col-lg-6 mb-4 mt-4">
              <mat-radio-group class="mb-2 p-1" formControlName="rent_input_amount_type">
                <mat-radio-button 
                  [value]="'total'">
                  Input Amount: Total
                </mat-radio-button>
                <mat-radio-button 
                  [value]="'per_units'">
                  Input Amount: Per Units
                </mat-radio-button>
              </mat-radio-group>
              <mat-error class="tag-custom-error" *ngIf="(hasFormError$|async) && formGroup.controls.rent_input_amount_type.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </div>
            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid" *ngIf="showRentSizeDropdown$|async">
                <mat-select placeholder="Size" formControlName="rent_size_id">
                  <mat-option *ngFor="let size of (sizes$|async); let i = index" [value]="size.uid">
                    Size {{i + 1}}: {{size.size|mask:'separator':{'thousandSeparator':','} }} {{size.unitAreaMeasurementAcronym}} / {{size.standardMeasurementName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Size is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6" *ngIf="showRentAmountPerUnit$|async">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput [suffix]="currencySuffix$|async" placeholder="Amount per Unit" formControlName="rent_amount_per_unit" mask="separator.2" thousandSeparator="," />
                <mat-error>
                  Amount per Unit is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6" *ngIf="showRentAmountTotal$|async">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput [suffix]="currencySuffix$|async" placeholder="Amount Total" formControlName="rent_amount_total"  mask="separator.2" thousandSeparator="," />
                <mat-error>
                  Amount Total is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-{{(showRentAmountPerUnit$|async) ? '3' : '6'}}">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Rent Basis" formControlName="rent_basis_id">
                  <mat-option *ngFor="let rentBasis of (rentBases$|async)" [value]="rentBasis.id">
                    {{ rentBasis.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 d-flex align-items-center" *ngIf="showRentAmountPerUnit$|async">
              <span class="text-muted">
                {{ amountPerUnitSizeTotal$|async|mask:'separator':{thousandSeparator:','} }}{{ currencySuffix$|async }}
              </span>
            </div>
            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Rent Review Type" formControlName="rent_review_type_id">
                  <mat-option [value]="null">
                    None
                  </mat-option>
                  <mat-option *ngFor="let item of (rentReviewTypes$|async)" [value]="item.id">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-6" *ngIf="showRentReviewCycle$|async">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput 
                  [placeholder]="rentReviewCycleLabel$|async"
                  formControlName="rent_review_cycle" 
                  mask="separator.2" 
                  thousandSeparator="," />
                <mat-icon
                  matSuffix
                  container="body"
                  class="cursor-pointer icon-gray"
                  [popoverTitle]="'TOOLTIP.CONSIDERATION.RENT_REVIEW.TITLE' | translate"
                  [ngbPopover]="'TOOLTIP.CONSIDERATION.RENT_REVIEW.DESCRIPTION' | translate"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation();">help
                </mat-icon>
                <mat-error *ngIf="formGroup.controls.rent_review_cycle.hasError('notNegative')">
                  Value cannot be <strong>negative</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.rent_review_cycle.hasError('isZero')">
                  Value cannot be <strong>0</strong>
                </mat-error>
                <mat-error *ngIf="formGroup.controls.rent_review_cycle.hasError('notLesserThanLease')">
                  Value cannot be greater than <strong>Lease Duration</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <ng-container *ngIf="showRentReviewDetail$|async">
              <div class="col-lg-3">
                <mat-form-field class="mat-form-field-fluid" *ngIf="showRentReviewDetailWholeNumber$|async">
                  <input matInput placeholder="Rent Review Detail" 
                    formControlName="rent_review_detail_number"
                    mask="separator.2"
                    thousandSeparator="," />
                </mat-form-field>

                <mat-form-field class="mat-form-field-fluid" *ngIf="showRentReviewDetailPercentage$|async">
                  <input matInput placeholder="Rent Review Detail" 
                    formControlName="rent_review_detail_percentage"
                    mask="percent"
                    suffix="%"/>
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field class="mat-form-field-fluid">
                  <mat-select formControlName="rent_review_detail_type">
                    <mat-option [value]="'number'">Whole Number</mat-option>
                    <mat-option [value]="'percentage'">Percentage</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <div class="col-lg-12 kt-margin-bottom-10-mobile mt-10">
              <h6>
                Expenses & Reimbursable
              </h6>
            </div>
            <div class="col-lg-6 mb-4 mt-4">
              <mat-radio-group class="mb-2 p-1" formControlName="expenses_input_amount_type">
                <mat-radio-button 
                  [value]="'total'">
                  Input Amount: Total
                </mat-radio-button>
                <mat-radio-button 
                  [value]="'per_units'">
                  Input Amount: Per Units
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-lg-6">
              <mat-form-field class="mat-form-field-fluid" *ngIf="showExpensesSizeDropdown$|async">
                <mat-select placeholder="Size" formControlName="expenses_size_id">
                  <mat-option *ngFor="let size of (sizes$|async); let i = index" [value]="size.uid">
                    Size {{i + 1}}: {{size.size|mask:'separator':{'thousandSeparator':','} }} {{size.unitAreaMeasurementAcronym}} / {{size.standardMeasurementName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Size is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 tab-container" style="min-height: 300px;">
              <mat-tab-group>
                <mat-tab>
                  <ng-template mat-tab-label>Recoverable</ng-template>
                  <ng-template matTabContent>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center ">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Property Management" 
                            formControlName="recoverable_property_management"
                            mask="separator.2"
                            thousandSeparator=","
                            [suffix]="currencySuffix$|async"
                             />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="propertyManagementAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Mgmt / Admin. / Leasing Expenses" 
                            formControlName="recoverable_leasing_expenses"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="leasingExpensesAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Utilities" 
                            formControlName="recoverable_utilities"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="utilitiesAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Maintenance / operations" 
                            formControlName="recoverable_maintenance"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="maintenanceAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Insurance" 
                            formControlName="recoverable_insurance"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="insuranceAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Janitorial / Cleaning / Security" 
                            formControlName="recoverable_janitorial"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="janitorialAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Property Taxes" 
                            formControlName="recoverable_property_taxes"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="propertyTaxesAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Business" 
                            formControlName="recoverable_business"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="businessAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Others" 
                            formControlName="recoverable_others"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="otherAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="non-recoverable">
                      Non-Recoverable
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Property Management" 
                            formControlName="nonrecoverable_property_management"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonpropertyManagementAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Mgmt / Admin. / Leasing Expenses" 
                            formControlName="nonrecoverable_leasing_expenses"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>

                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonleasingExpensesAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Utilities" 
                            formControlName="nonrecoverable_utilities"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonutilitiesAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Maintenance / operations" 
                            formControlName="nonrecoverable_maintenance"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonmaintenanceAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Insurance" 
                            formControlName="nonrecoverable_insurance"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="noninsuranceAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Janitorial / Cleaning / Security" 
                            formControlName="nonrecoverable_janitorial"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonjanitorialAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Property Taxes" 
                            formControlName="nonrecoverable_property_taxes"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonpropertyTaxesAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Business" 
                            formControlName="nonrecoverable_business"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonbusinessAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 d-flex align-items-center">
                        <mat-form-field class="flex-grow-1 mr-3" style="max-width: 300px">
                          <input matInput placeholder="Others" 
                            formControlName="nonrecoverable_others"
                            [suffix]="currencySuffix$|async"
                            mask="separator.2"
                            thousandSeparator="," />
                        </mat-form-field>
                        <span class="font-italic text-muted mr-1">
                          {{ expensesInputAmountType$|async }}
                        </span>
                        <ng-container *ngIf="nonotherAmountPerUnit$|async as vm">
                          <span *ngIf="vm !== null" class="text-muted ml-2">
                            {{ vm.value|mask:'separator':{thousandSeparator:','} }} {{ currencySuffix$|async }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
              <div class="tab-tooltip">
                <mat-icon container="body" 
                  class="cursor-pointer icon-gray"
                  [ngbPopover]="'Recoverable expenses are reimbursed by the tenant to the landlord. Non-recoverable are out of pocket expenses paid by the landlord. Payable on the same basis as the rent'"
                  popoverClass="consideration__popover__class"
                  (click)="$event.stopPropagation()">
                  help
                </mat-icon>
              </div>
            </div>
            <div class="col-lg-12">
              <mat-form-field class="mat-form-field-fluid">
                <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group row mb-4" *ngIf="data.hasParcel">
            <kt-parcel-consideration-table
              class="col-12"
              [components]="data.components"
              [additionals]="data.additionals" 
              ></kt-parcel-consideration-table>
            <div class="col-12">
              <mat-error class="tag-custom-error mt-2" *ngIf="(hasFormError$) && (parcelError$|async)">
                  Tenure, or currency must be selected for not included parcel(s)
              </mat-error>
            </div>
          </div>

          <div class="form-group row">
              <kt-source-info class="col-12" 
                [leaseStartDate]="leaseStartDate$|async"
                [sourceSubject]="sourceSubject" 
                [otherDates]="otherDates$|async" 
                [isComplexForm]="isAdvancedForm$|async"></kt-source-info>
          </div>
        </mat-dialog-content>
      </div>
    </form>

    <div class="card-footer p-0 b-0 text-right">
      <div class="form-actions form-actions-solid">
        <button mat-button mat-raised-button (click)="onClose()">
          Close
        </button>
        &nbsp;
        <button type="button" class="btn btn-success" mat-raised-button color="submit" 
          [matTooltip]="'CONSIDERATIONS.FORM.BUTTON.ADD.TOOLTIP' | translate"
          (click)="onSubmit()">
          Add
        </button>
      </div>
    </div>
  </div>
</div>