import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { TeamTemplateModel } from "../_models";
import { TeamTemplateState } from "../_reducers";

export const selectTeamTemplateState = createFeatureSelector<TeamTemplateState>('team-template');

export const selectIsLoading = createSelector(
    selectTeamTemplateState,
    state => state.isLoading
)

export const selectTotal = createSelector(
    selectTeamTemplateState,
    state => state.total
)

export const selectTemplate = (id: number) => createSelector(
    selectTeamTemplateState,
    state => state.entities[id]
)

export const selectTeamTemplates = createSelector(
    selectTeamTemplateState,
    state => {
        const items: TeamTemplateModel[] = [];
        each(state.entities, el => items.push(el));
        return items.sort((a, b) => (b.id - a.id));
    }
)