import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { ToeTemplateModel } from "../_models";
import { ToeTemplateState } from "../_reducers";

export const selectToeTemplateState = createFeatureSelector<ToeTemplateState>('toe-template');

export const selectIsLoading = createSelector(
    selectToeTemplateState,
    state => state.isLoading
)

export const selectTotal = createSelector(
    selectToeTemplateState,
    state => state.total
)

export const selectTemplate = (id: number) => createSelector(
    selectToeTemplateState,
    state => state.entities[id]
)

export const selectToeTemplates = createSelector(
    selectToeTemplateState,
    state => {
        const items: ToeTemplateModel[] = [];
        each(state.entities, el => items.push(el));
        return items.sort((a, b) => (b.id - a.id));
    }
)