import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassTenure } from 'src/app/core/comparable';
import { calculateRecoverable } from 'src/app/core/v2/core';
import { LeaseDurationType } from 'src/app/core/v2/types';
import { capitalize } from 'src/app/core/v2/utils';

@Component({
  selector: 'kt-leasehold-information-table',
  templateUrl: './leasehold-information-table.component.html',
  styleUrls: ['./leasehold-information-table.component.scss']
})
export class LeaseholdInformationTableComponent implements OnInit {
  @Input() tpCurrency: string;
  consideration$ = new BehaviorSubject<AssetClassTenure>(null)
  @Input() 
  set consideration(value: AssetClassTenure) {
    this.consideration$.next(value)
  }
  viewModel$ = this.consideration$.asObservable().pipe(
    filter(value => value !== null),
    map(value => {
      return {
        total_amount: value.total_consideration,
        rent_input_amount_type: value.rent_input_amount_type,
        rent_basis_name: value.rent_basis_name,
        rent_type: capitalize(value.rent_type),
        start_date: value.start_date,
        capital_payment: value.cap_payment,
        rent_review_cycle: this._durationCalculation(value.rent_review, value.duration_type),
        unexpired_lease_terms: value.unexpired_lease_term,
        lease_duration: value.lease_duration,
        break_option_after: this._durationCalculation(value.break_option, value.duration_type),
        write_off_period_after: this._durationCalculation(value.write_off_period, value.duration_type),
        fitting_out_period: this._durationCalculation(value.fitting_out_period, value.duration_type),
        rent_free_period: this._durationCalculation(value.rent_free_period, value.duration_type),
        rent_review_type: value.rent_review_type_name ? value.rent_review_type_name : 'N/A',
        recoverable: calculateRecoverable(value),
        recoverable_property_management: value.recoverable_property_management ? value.recoverable_property_management : 0,
        recoverable_utilities: value.recoverable_utilities ? value.recoverable_utilities : 0,
        recoverable_insurance: value.recoverable_insurance ? value.recoverable_insurance : 0,
        recoverable_property_taxes: value.recoverable_property_taxes ? value.recoverable_property_taxes : 0,
        recoverable_leasing_expenses: value.recoverable_leasing_expenses ? value.recoverable_leasing_expenses : 0,
        recoverable_maintenance: value.recoverable_maintenance ? value.recoverable_maintenance : 0,
        recoverable_janitorial: value.recoverable_janitorial ? value.recoverable_janitorial : 0,
        recoverable_business: value.recoverable_business ? value.recoverable_business : 0,
        recoverable_others: value.recoverable_others ? value.recoverable_others : 0,
        non_recoverable: this._calculate_nonrecoverable(value),
        non_recoverable_property_management: value.non_recoverable_property_management ? value.non_recoverable_property_management : 0,
        non_recoverable_utilities: value.non_recoverable_utilities ? value.non_recoverable_utilities : 0,
        non_recoverable_insurance: value.non_recoverable_insurance ? value.non_recoverable_insurance : 0,
        non_recoverable_property_taxes: value.non_recoverable_property_taxes ? value.non_recoverable_property_taxes : 0,
        non_recoverable_leasing_expenses: value.non_recoverable_leasing_expenses ? value.non_recoverable_leasing_expenses : 0,
        non_recoverable_maintenance: value.non_recoverable_maintenance ? value.non_recoverable_maintenance : 0,
        non_recoverable_janitorial: value.non_recoverable_janitorial ? value.non_recoverable_janitorial : 0,
        non_recoverable_business: value.non_recoverable_business ? value.non_recoverable_business : 0,
        non_recoverable_others: value.non_recoverable_others ? value.non_recoverable_others : 0,
      } 
    })
  )
  constructor() { }

  ngOnInit(): void {
  }

  private _durationCalculation(value: number, duration_type: LeaseDurationType) {
    if (!value) return null
    if (duration_type === 'months') return value / 12
    return value
  }

  private _calculate_nonrecoverable(consideration: AssetClassTenure): number {
    let total = 0;
    if (consideration.non_recoverable_property_management) {
      total += consideration.non_recoverable_property_management
    }
    if (consideration.non_recoverable_utilities) {
      total += consideration.non_recoverable_utilities
    }
    if (consideration.non_recoverable_insurance) {
      total += consideration.non_recoverable_insurance
    }
    if (consideration.non_recoverable_property_taxes) {
      total += consideration.non_recoverable_property_taxes
    }
    if (consideration.non_recoverable_leasing_expenses) {
      total += consideration.non_recoverable_leasing_expenses
    }
    if (consideration.non_recoverable_maintenance) {
      total += consideration.non_recoverable_maintenance
    }
    if (consideration.non_recoverable_janitorial) {
      total += consideration.non_recoverable_janitorial
    }
    if (consideration.non_recoverable_business) {
      total += consideration.non_recoverable_business
    }
    if (consideration.non_recoverable_others) {
      total += consideration.non_recoverable_others
    }
    return total
  }
}
