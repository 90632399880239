
// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {IpmsComponent} from '../_models/ipms-component.model';

export enum IpmsComponentActionTypes {

    AllIpmsComponentsRequested = '[IpmsComponents Home Page] All IpmsComponents Requested',
    AllIpmsComponentsLoaded = '[IpmsComponents API] All IpmsComponents Loaded',

    IpmsComponentOnServerCreated = '[Edit IpmsComponent Dialog] IpmsComponent On Server Created',
    IpmsComponentCreated = '[Edit IpmsComponents Dialog] IpmsComponents Created',
    IpmsComponentUpdated = '[Edit IpmsComponent Dialog] IpmsComponent Updated',
    IpmsComponentDeleted = '[IpmsComponents List Page] IpmsComponent Deleted',

    IpmsComponentsPageRequested = '[IpmsComponents List Page] IpmsComponents Page Requested',
    IpmsComponentsPageLoaded = '[IpmsComponents API] IpmsComponents Page Loaded',
    IpmsComponentsPageCancelled = '[IpmsComponents API] IpmsComponents Page Cancelled',

    IpmsComponentsPageToggleLoading = '[IpmsComponents page] IpmsComponents Page Toggle Loading',
    IpmsComponentsActionToggleLoading = '[IpmsComponents] IpmsComponents Action Toggle Loading',

    // trash system

    IpmsComponentOnServerRestored = '[IpmsComponent Trash] IpmsComponent On Server Restored',
    IpmsComponentRestored = '[IpmsComponent Trash] IpmsComponent Restored',

    IpmsComponentOnServerAdminRestored = '[IpmsComponent Admin Trash] IpmsComponent On Server Restored',
    IpmsComponentAdminRestored = '[IpmsComponent Admin Trash] IpmsComponent Restored',

    IpmsComponentDeletedFromTrash = '[IpmsComponent Trash] IpmsComponent deleted',
    IpmsComponentDeletedFromAdminTrash = '[IpmsComponent Admin Trash] IpmsComponent deleted',

    IpmsComponentTrash = 'IpmsComponent Trashed',
    IpmsComponentTrashFlushed = 'IpmsComponent Trash Flushed',
}

export class IpmsComponentOnServerCreated implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentOnServerCreated;

    constructor(public payload: { IpmsComponent: IpmsComponent}) {
    }
}

export class IpmsComponentCreated implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentCreated;

    constructor(public payload: { IpmsComponent: IpmsComponent }) {
    }
}

export class IpmsComponentUpdated implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentUpdated;

    constructor(public payload: {
        partialIpmsComponent: Update<IpmsComponent>,
        IpmsComponent: IpmsComponent
    }) {
    }
}

export class IpmsComponentDeleted implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsComponentsPageRequested implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class IpmsComponentsPageLoaded implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentsPageLoaded;

    constructor(public payload: { IpmsComponents: IpmsComponent[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class IpmsComponentsPageCancelled implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentsPageCancelled;
}

export class AllIpmsComponentsRequested implements Action {
    readonly type = IpmsComponentActionTypes.AllIpmsComponentsRequested;
}

export class AllIpmsComponentsLoaded implements Action {
    readonly type = IpmsComponentActionTypes.AllIpmsComponentsLoaded;

    constructor(public payload: { IpmsComponents: IpmsComponent[] }) {
    }
}

export class IpmsComponentsPageToggleLoading implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class IpmsComponentsActionToggleLoading implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class IpmsComponentDeletedFromAdminTrash implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class IpmsComponentDeletedFromTrash implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsComponentOnServerRestored implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsComponentRestored implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentRestored;

    constructor(public payload: { item: IpmsComponent }) {
    }
}

export class IpmsComponentOnServerAdminRestored implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IpmsComponentAdminRestored implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentAdminRestored;

    constructor(public payload: { item: IpmsComponent }) {
    }
}

export class IpmsComponentTrashFlushed implements Action {
    readonly type = IpmsComponentActionTypes.IpmsComponentTrashFlushed;

    constructor() {
    }
}

export type IpmsComponentActions = IpmsComponentCreated
    | IpmsComponentUpdated
    | IpmsComponentDeleted
    | IpmsComponentsPageRequested
    | IpmsComponentsPageLoaded
    | IpmsComponentsPageCancelled
    | AllIpmsComponentsLoaded
    | AllIpmsComponentsRequested
    | IpmsComponentOnServerCreated
    | IpmsComponentsPageToggleLoading
    | IpmsComponentsActionToggleLoading
    | IpmsComponentDeletedFromAdminTrash
    | IpmsComponentDeletedFromTrash
    | IpmsComponentOnServerRestored
    | IpmsComponentRestored
    | IpmsComponentOnServerAdminRestored
    | IpmsComponentAdminRestored
    | IpmsComponentTrashFlushed;
