import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AssetClassModel } from "../../asset_class";
import { ValuationVpga10Matter } from "../_models/vpga10-matter.model";

export enum ActionTypes {
    LoadData = '[VPGA10 table] Load data from server',
    DataLoaded = '[VPGA10 table] Data loaded',

    AddVpgaMatter = '[VPGA10 table] Add new vpga-10',
    VpgaMatterAdded = '[VPGA10 table] New vpga-10 added',

    EditVpgaMatter = '[VPGA10 table] Edit vpga-10',
    VpgaMatterEdited = '[VPGA10 table] Vpga-10 edited',

    RestoreVpgaMatter = '[VPGA10 table] Restore local vpga-10',
    VpgaMatterRestored = '[VPGA10 table] Country Vpga-10 restored',

    RemoveToeVpgaMatter = '[VPGA10 table] Remove toe local vpga-10',
    ToeVpgaMatterRemoved = '[VPGA10 table] Toe Vpga-10 removed',

    RemoveInputVpgaMatter = '[VPGA10 table] Remove input vpga-10',
    InputVpgaMatterRemoved = '[VPGA10 table] Input vpga-10 removed'
}

export class LoadData implements Action {
    readonly type = ActionTypes.LoadData;
    constructor(public payload: {
        data: ValuationVpga10Matter[]
    }) {}
}
export class DataLoaded implements Action {
    readonly type = ActionTypes.DataLoaded;
    constructor(public payload: {
        data: ValuationVpga10Matter[],
        id: number
    }) {}
}

export class AddVpgaMatter implements Action {
    readonly type = ActionTypes.AddVpgaMatter;
    constructor(public payload: {data: ValuationVpga10Matter}) {}
}
export class VpgaMatterAdded implements Action {
    readonly type = ActionTypes.VpgaMatterAdded;
    constructor(public payload: {data: ValuationVpga10Matter, id: number}) {}
}

export class EditVpgaMatter implements Action {
    readonly type = ActionTypes.EditVpgaMatter;
    constructor(public payload: {data: ValuationVpga10Matter}) {}
}
export class VpgaMatterEdited implements Action {
    readonly type = ActionTypes.VpgaMatterEdited;
    constructor(public payload: {data: Update<ValuationVpga10Matter>}) {}
}

export class RestoreLocalVpgaMatter implements Action {
    readonly type = ActionTypes.RestoreVpgaMatter;
    constructor(public payload: {
        data: ValuationVpga10Matter,
        tp: AssetClassModel
    }) {}
}
export class VpgaMatterRestored implements Action {
    readonly type = ActionTypes.VpgaMatterRestored;
    constructor(public payload: {data: Update<ValuationVpga10Matter>}) {}
}

export class RemoveToeLocalVpgaMatter implements Action {
    readonly type = ActionTypes.RemoveToeVpgaMatter;
    constructor(public payload: {data: ValuationVpga10Matter, tp: AssetClassModel, justification: string}) {}
}
export class ToeVpgaMatterRemoved implements Action {
    readonly type = ActionTypes.ToeVpgaMatterRemoved;
    constructor(public payload: {data: Update<ValuationVpga10Matter>}) {}
}

export class RemoveInputVpgaMatter implements Action {
    readonly type = ActionTypes.RemoveInputVpgaMatter;
    constructor(public payload: {id: number}) {}
}

export type Actions = LoadData
    | DataLoaded
    
    | AddVpgaMatter
    | VpgaMatterAdded
    
    | EditVpgaMatter
    | VpgaMatterEdited
    
    | RestoreLocalVpgaMatter
    | VpgaMatterRestored
    
    | RemoveToeLocalVpgaMatter
    | ToeVpgaMatterRemoved
    
    | RemoveInputVpgaMatter;