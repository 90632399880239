import { Action } from "@ngrx/store";
import { DraftStatementReportModel } from "../_models/draft-statement-report.model";

export enum DraftStatementReportActionTypes {
	AllDraftStatementReportsRequested = '[ToE Dashboard - Reports table] All draft statement reports requested',
	AllDraftStatementReportsLoaded = '[Draft statement request effect] All draft statement reports loaded',

	DraftStatementReportOnServerCreated = '[] Draft statement report on server created',
	DraftStatementReportCreated = '[] Draft statement report created',

	DraftStatementReportOnServerDeleted = '[Reports table] Draft statement report on server deleted',

	DraftStatementReportActionToggleLoading = '[Draft statement report effect] toggle loading',

	DraftStatementReportStateResetted = '[ToE Dashboard] Reset state of Draft statement report state'
}

export class AllDraftStatementReportsRequested implements Action {
	readonly type = DraftStatementReportActionTypes.AllDraftStatementReportsRequested;
	constructor(public payload: {tpID: number}) {}
}

export class AllDraftStatementReportsLoaded implements Action {
	readonly type = DraftStatementReportActionTypes.AllDraftStatementReportsLoaded;
	constructor(public payload: {
		reports: DraftStatementReportModel[],
		tpId: number
	}) {}
}

export class DraftStatementReportOnServerCreated implements Action {
	readonly type = DraftStatementReportActionTypes.DraftStatementReportOnServerCreated;
	constructor(public payload: {report: DraftStatementReportModel}) {}
}

export class DraftStatementReportCreated implements Action {
	readonly type = DraftStatementReportActionTypes.DraftStatementReportCreated;
	constructor(public payload: {report: any}) {}
}

export class DraftStatementReportActionToggleLoading implements Action {
	readonly type = DraftStatementReportActionTypes.DraftStatementReportActionToggleLoading;
	constructor(public payload: {isLoading: boolean}) {}
}

export class DraftStatementReportStateResetted implements Action {
	readonly type = DraftStatementReportActionTypes.DraftStatementReportStateResetted;
	constructor() {}
}

export class DraftStatementReportOnServerDeleted implements Action {
	readonly type = DraftStatementReportActionTypes.DraftStatementReportOnServerDeleted;
	constructor(public payload: {id: number}) {}
}

export type DraftStatementReportActions = AllDraftStatementReportsRequested
	| AllDraftStatementReportsLoaded
	| DraftStatementReportOnServerCreated
	| DraftStatementReportCreated
	| DraftStatementReportActionToggleLoading
	| DraftStatementReportStateResetted
	| DraftStatementReportOnServerDeleted;