import { CostRevenue, HeadlineToEffectiveMeta } from "../../types";
import { IncomeValuationProcess } from "../../types/valuation-process";

export function updateSubLease(
  process: IncomeValuationProcess,
  {uuid, kind, data}: {
    uuid: string,
    kind: CostRevenue['kind'],
    data: CostRevenue
  }
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: kind === 'lease' ? process.data.leases.map(item => _updateSubLease(item, uuid, data)) : process.data.leases,
      propertyRevenues: kind === 'property-revenue' ? process.data.propertyRevenues.map(item => _updateSubLease(item, uuid, data)) : process.data.propertyRevenues
    }
  }
}

function _updateSubLease<T extends {uid: string, subLease: CostRevenue}>(item: T, uuid: string, data: CostRevenue): T {
  if (item.uid === uuid) {
    return {
      ...item,
      subLease: data
    }
  }
  return item
}

export function updateSubLeaseHeadlineToEffectiveMeta(
  process: IncomeValuationProcess,
  {uuid, kind, meta}: {
    uuid: string,
    kind: CostRevenue['kind'],
    meta: HeadlineToEffectiveMeta
  }
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: kind === 'lease' ? process.data.leases.map(item => _updateSubLeaseHeadlineToEffectiveMeta(item, uuid, meta)) : process.data.leases,
      propertyRevenues: kind === 'property-revenue' ? process.data.propertyRevenues.map(item => _updateSubLeaseHeadlineToEffectiveMeta(item, uuid, meta)) : process.data.propertyRevenues
    }
  }
}

function _updateSubLeaseHeadlineToEffectiveMeta<T extends {uid: string, subLease: CostRevenue}>(item: T, uuid: string, data: HeadlineToEffectiveMeta): T {
  if (item.uid === uuid && item.subLease !== null) {
    return {
      ...item,
      subLease: {
        ...item.subLease,
        headlineToEffectiveMeta: data
      }
    }
  }
  return item
}