
<kt-portlet>
  <kt-portlet-header
    [title]="title$|async"
    [class]="'card-head-lg'"
    >
    <ng-container ktPortletTools>
      <a mat-raised-button
        class="btn mr-2"
        matTooltip="Go back to list"
        [routerLink]="backRoute$|async">
        <mat-icon>arrow_back</mat-icon>
        <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
      </a>

      <ng-container *ngIf="!(isReadonly$|async)">
        <button class="btn mr-2"
          mat-raised-button
          (click)="onReset()">
          <i class="flaticon-cogwheel-2"></i>
          <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
        </button>
        <button class="btn btn-success mr-2"
          color="submit"
          (click)="onSubmit()"
          [disabled]="!isFormValid"
          mat-raised-button>
          <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
        </button>
      </ng-container>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <form class="form" [formGroup]="form" [ngClass]="{'readonly': isReadonly$|async}">
      <div class="card-body">
        <div class="row">
          <div class="col-8">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Name" formControlName="name" />
              <mat-error>
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </kt-portlet-body>
</kt-portlet>