import { Injectable } from "@angular/core";
import { AssetClassModel } from "../../asset_class";
import { ValuationAssumptionDeparture, Type } from "../_models/valuation-assumption-departure.model";

@Injectable()
export class AssumptionDepartureService {
    restore(
        tp: AssetClassModel,
        item: ValuationAssumptionDeparture
    ): ValuationAssumptionDeparture {
        const _item = Object.assign({}, item) as ValuationAssumptionDeparture;
        switch (_item.type) {
            case Type.DefaultAssumption: {
                tp.selected_default_assumptions.forEach((a: any) => {
                    if (a.id === item.ref_id) {
                        _item.input = a.default_assumption ? a.default_assumption.name : a.default_assumption_name;
                    }
                });
                break;
            }
            case Type.SpecialAssumption: {
                tp.selected_special_assumptions.forEach((a: any) => {
                    if (a.id === item.ref_id) {
                        _item.input = a.special_assumption ? a.special_assumption.name : a.special_assumption_name;
                    }
                })
                break;
            }
            case Type.Departure: {
                tp.selected_departures.forEach((d: any) => {
                    if (d.id === item.ref_id) {
                        _item.input = d.departure 
                            ? d.valuation_standard_name + '|' + d.departure.name 
                            : d.valuation_standard_name + '|' + d.departure_name;
                    }
                })
                break;
            }
        }
        return _item;
    }
}