import {SourceType} from '../_models/source-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SourceTypesState} from '../_reducers/source-type.reducers';
import * as fromSourceType from '../_reducers/source-type.reducers';
import {each} from 'lodash';

export const selectSourceTypesState = createFeatureSelector<SourceTypesState>('sourceTypes');

export const selectSourceTypeById = (sourceTypeId: number) => createSelector(
    selectSourceTypesState,
    sourceTypesState => sourceTypesState.entities[sourceTypeId]
);

export const selectAllSourceTypes = createSelector(
    selectSourceTypesState,
    fromSourceType.selectAll
);

export const selectAllSourceTypesIds = createSelector(
    selectSourceTypesState,
    fromSourceType.selectIds
);

export const allSourceTypesLoaded = createSelector(
    selectSourceTypesState,
    sourceTypesState => sourceTypesState.isAllSourceTypesLoaded
);


export const selectSourceTypesPageLoading = createSelector(
    selectSourceTypesState,
    sourceTypesState => sourceTypesState.listLoading
);

export const selectSourceTypesActionLoading = createSelector(
    selectSourceTypesState,
    sourceTypesState => sourceTypesState.actionLoading
);

export const selectLastCreatedSourceTypeId = createSelector(
    selectSourceTypesState,
    sourceTypesState => sourceTypesState.lastCreatedSourceTypeId
);

export const selectSourceTypesShowInitWaitingMessage = createSelector(
    selectSourceTypesState,
    sourceTypesState => sourceTypesState.showInitWaitingMessage
);

export const selectTrashedSourceTypeCount = createSelector(
    selectSourceTypesState,
    (sourceTypesState) => sourceTypesState.totalTrashed
);

export const selectAdminTrashedSourceTypeCount = createSelector(
    selectSourceTypesState,
    (sourceTypesState) => sourceTypesState.totalAdminTrashed
);


export const selectSourceTypesQueryResult = createSelector(
    selectSourceTypesState,
    sourceTypesState => {
        const items: SourceType[] = [];
        each(sourceTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SourceType[] = httpExtension.sortArray(items, sourceTypesState.lastQuery.sortField, sourceTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, sourceTypesState.totalCount, '', sourceTypesState.totalTrashed);
    }
);
