import { MapsAPILoader } from "@agm/core";
import { Component, ElementRef, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { each } from "lodash";
import { BehaviorSubject, Subject, Subscription, combineLatest } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AllCitiesRequested, AllCountriesRequested, CityModel, CountryModel, selectAllCities, selectAllCountries, selectCitiesByCountryId } from "src/app/core/admin";
import { LocationData } from "src/app/core/comparable/_models/location.model";
import { AllGradesRequested, Grade, selectAllGrades } from "src/app/core/linked-tables";
import { AppState } from "src/app/core/reducers";
import { LayoutUtilsService } from "src/app/core/_base/crud";
import { CountryEditComponent } from "../../admin-management/countries/country-edit/country-edit.component";
import { TimeZoneApiService } from "src/app/core/g-map/timezone.api.service";
import { CountryData } from "../../admin-management/countries/countries-list/local-data";
import { environment } from "src/environments/environment";
import { GeneralSettingsService } from "src/app/core/general-settings/general-settings.service";
import { MapsService } from "src/app/core/map/maps.service";
// import { CountryEditComponent } from "../../admin-management/countries/country-edit/country-edit.component";

@Component({
    selector: 'kt-map-location',
    templateUrl: './map-location.component.html',
    styleUrls: ['./map-location.component.scss']
})
export class MapLocationComponent implements OnInit, OnChanges {
  @Input() locationData: LocationData;
  @Input() isComplexForm: boolean;
  @Input() showMap: boolean = true;
  @Input() title: string;
  @Input() lockMarker: boolean;
  @Input() parentType: 'comparable' | 'inspection';
  @Input() centerLat: number = environment.mapCenter.lat;
  @Input() centerLng: number = environment.mapCenter.lng;
  @Output() centerLatChange = new EventEmitter();
  @Output() centerLngChange = new EventEmitter();
  @Output() markerChange: EventEmitter<{lat: number, lng: number, isShown: boolean}> = new EventEmitter();
  @Output() timezoneChange = new EventEmitter<string>()
  formGroup: UntypedFormGroup;

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;

  zoom = 15;
  isShown = true;
  currentCenter: { lat: 0, lng: 0 };
  markerLat: number;
  markerLng: number;
  marker: google.maps.Marker;
  map: google.maps.Map;
  private mapClickListener: google.maps.MapsEventListener;
  private geoCoder = new google.maps.Geocoder();

  localCountries = CountryData.countries;
  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  grades: Grade[] = [];

  private _onDestroy$: Subject<void> = new Subject();
  private subscriptions: Subscription[] = [];
  errorFields = [];

  _centerLat$ = new BehaviorSubject<number>(null)
  centerLatitude$ = combineLatest([
    this._centerLat$,
    this.mapsService.latitude$
  ]).pipe(map(([incoming, def]) => {
    if (incoming == null) {
      return Number(def)
    }
    return incoming
  }))
  _centerLng$ = new BehaviorSubject<number>(null)
  centerLongitude$ = combineLatest([
    this._centerLng$,
    this.mapsService.longitude$,
  ]).pipe(map(([incoming, def]) => {
    if (incoming == null) {
      return Number(def)
    }
    return incoming
  }))

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private timezoneApiService: TimeZoneApiService,
    private mapsService: MapsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.locationData && !changes.locationData.firstChange) {
      this.markerLat = changes.locationData.currentValue.latitude === null ? null : Number(changes.locationData.currentValue.latitude);
      this.centerLat = changes.locationData.currentValue.latitude === null ? null : Number(changes.locationData.currentValue.latitude);
      this.markerLng = changes.locationData.currentValue.longitude === null ? null : Number(changes.locationData.currentValue.longitude);
      this.centerLng = changes.locationData.currentValue.longitude === null ? null : Number(changes.locationData.currentValue.longitude);
      this._centerLat$.next(this.centerLat)
      this._centerLng$.next(this.centerLng)
      if (this.formGroup) {
        this.formGroup.controls.latitude.patchValue(this.centerLat);
        this.formGroup.controls.longitude.patchValue(this.centerLng);
        this.formGroup.controls.address.patchValue(changes.locationData.currentValue.address);
        this.formGroup.controls.city_id.patchValue(changes.locationData.currentValue.city_id);
        this.formGroup.controls.zip_code.patchValue(changes.locationData.currentValue.zip_code);
        this.formGroup.controls.location_grade_id.patchValue(changes.locationData.currentValue.location_grade_id);
        this.formGroup.controls.location_surrounding.patchValue(changes.locationData.currentValue.location_surrounding);
      }
      this._getWhat3words(new google.maps.LatLng(this.centerLat, this.centerLng));
      this.isShown = true;
      this.markerChange.emit({lat: this.markerLat, lng: this.markerLng, isShown: this.isShown});
    }
  }

  ngOnInit(): void {
    this.isShown = this.locationData.latitude && this.locationData.longitude ? true : false;
    this.markerLat = this.locationData.latitude ? Number(this.locationData.latitude) : this.centerLat;
    this.markerLng = this.locationData.longitude ? Number(this.locationData.longitude) : this.centerLng;
    this.centerLng = this.markerLng;
    this.centerLat = this.markerLat;
    this._centerLat$.next(this.centerLat)
    this._centerLng$.next(this.centerLng)
    this.markerChange.emit({lat: this.markerLat, lng: this.markerLng, isShown: this.isShown});
    if (this.showMap) {
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
        if (this.searchElementRef) {
          const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
              types: ['(cities)']
          });
          autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
              // get the place result
              const place: google.maps.places.PlaceResult = autocomplete.getPlace();
              // verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
    
              if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
                this.centerLat = 47.91868658952473;
                this.centerLng = 106.91766668255616;
                this.zoom = 15;
              } else {
                // set latitude, longitude and zoom
                this._centerLat$.next(place.geometry.location.lat())
                this._centerLng$.next(place.geometry.location.lng())
                this.zoom = 12;
              }
            });
          });
        }
      });
    }
    this._fetchLTs();

    this.formGroup = this.formBuilder.group({
      country_id: [{value: this.locationData.country_id, disabled: true}, Validators.required],
      city_id: [this.locationData.city_id, Validators.required],
      zip_code: [this.locationData.zip_code],
      latitude: [this.locationData.latitude, this.showMap ? Validators.required : null],
      longitude: [this.locationData.longitude, this.showMap ? Validators.required : null],
      time_offset: [this.locationData.time_offset],
      location_grade_id: [this.locationData.location_grade_id, Validators.required],
      address: [this.locationData.address, Validators.required],
      location_surrounding: [this.locationData.location_surrounding, this.parentType == 'inspection' ? Validators.required : null],
    });
  }

  onMapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

    this.map.addListener('dragend', () => {
        this.centerLat = this.currentCenter.lat;
        this.centerLng = this.currentCenter.lng;
    });

    // Workaround 
    this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
        this.ngZone.run(() => {
            this._mapRightClick(e);
        })
    })
  }
  setLocation() {
    this._setCurrentLocation();
  }
  markerDragEnd($event: google.maps.MouseEvent) {
    this.markerLat = $event.latLng.lat();
    this.markerLng = $event.latLng.lng();
    this.markerChange.emit({lat: this.markerLat, lng: this.markerLng, isShown:this.isShown});

    this.centerLat = $event.latLng.lat();
    this.centerLng = $event.latLng.lng();
    this.formGroup.controls.latitude.patchValue($event.latLng.lat());
    this.formGroup.controls.longitude.patchValue($event.latLng.lng());
    this._getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
  }
  centerChange($event: any) {
    if ($event) {
      this.currentCenter = {lat: $event.lat, lng: $event.lng};
      this.centerLatChange.emit($event.lat);
      this.centerLngChange.emit($event.lng);
    }
  }
  selectCountry() {
    const countryId = this.formGroup.controls.country_id.value;
    this.store.pipe(
      takeUntil(this._onDestroy$), 
      select(selectCitiesByCountryId(countryId))).subscribe((res: CityModel[]) => {

        this.cityOfCountry = res;
    });
  }
  private _getWhat3words(latLng: google.maps.LatLng): void {
    this.geoCoder.geocode({location: {lat: latLng.lat(), lng: latLng.lng()}}, (results, status) => {
      if (status && results.length > 1) {
        const addressComponent = results.reverse()[0].address_components
        if (addressComponent.length > 0) {
          this._setCountry(addressComponent[0].short_name)
        }
      }
    })
  }

  private _setCountry(countryCode: string) {
    if (this.allCountries.length === 0) {
      return;
    }

    const country = this.localCountries.find(c => c.countryCode == countryCode)
    if (country === undefined) {
      return;
    }
    const _country = this.allCountries.find(el => el.name.toLocaleLowerCase() == country.countryName.toLocaleLowerCase())
    if (_country) {
      this.formGroup.controls.country_id.setValue(_country.id)
      this.selectCountry()
    } else {
      this._showError(country.countryName)
    }
  }
  setCountry(addr) {
    this._setCountry(addr)
  }
  private _setAutoFillCountry(address) {
      const addressData = address.split(',').reverse();
      if (addressData.length >= 1) {
          if (this.allCountries.length !== 0) {
              const _country = this.allCountries.find(el => el.name.toLowerCase() == addressData[0].trim().toLowerCase());
              // this.autoFillCity = addressData[0].trim();
              if (_country) {
                  this.formGroup.controls.country_id.setValue(_country.id);
                  this.selectCountry();
              } else {
                  this._showError(addressData[0].trim());
              }
          }
      }
  }
  private _showError(country: string) {
      const dialogRef = this.layoutUtilsService.showErrorDialg(
          'Country not found', 
          `Selected country "${country}" is not in the list`,
          'Add country');
      const sub = dialogRef.afterClosed().subscribe(res => {
          if (!res) {
              this.formGroup.controls.country_id.setValue(undefined);
              return;
          }
          if (res.proceedAction) {
              this.addCountry(country);
          }
      });
      this.subscriptions.push(sub);
  }
  private addCountry(country: string) {
      const newCountry = new CountryModel();
      newCountry.clear();
      const dialogRef = this.dialog.open(CountryEditComponent, {
          data: {
              id: newCountry.id,
              name: country
          }
      });
      const sub = dialogRef.afterClosed().subscribe(res => {
          if (!res) {
              return;
          }
          this.formGroup.controls.country_id.setValue(res.id);
          this.selectCountry();
      });
      this.subscriptions.push(sub);
  }
  private _mapRightClick($event: google.maps.MouseEvent) {
      if (!this.lockMarker) {
          this.markerLat = $event.latLng.lat();
          this.markerLng = $event.latLng.lng();

          this.centerLat = $event.latLng.lat();
          this.centerLng = $event.latLng.lng();
          this.formGroup.controls.latitude.patchValue($event.latLng.lat());
          this.formGroup.controls.longitude.patchValue($event.latLng.lng());
          this.timezoneApiService.timeZoneOf({lat: Number($event.latLng.lat()),lng: Number($event.latLng.lng())}).subscribe(offset => {
            this.formGroup.controls.time_offset.patchValue(offset)
            this.timezoneChange.emit(offset)
          })
          this._getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
          this.isShown = true;
          this.markerChange.emit({lat: this.markerLat, lng: this.markerLng, isShown: this.isShown});
      }
  }
  private _setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.latitude && position.coords.longitude) {
          this.centerLat = position.coords.latitude;
          this.centerLng = position.coords.longitude;
          this.zoom = 15;
        }
      });
    } else {
      alert('Position not available');
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _fetchLTs() {
    this.store.dispatch(new AllCountriesRequested());
    this.store.select(selectAllCountries).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.allCountries = [];
      each(res, (_country: CountryModel) => {
        this.allCountries.push(_country);
      });
    });
    this.store.dispatch(new AllCitiesRequested());
    if (this.locationData.country_id) {
      this.store.pipe(
        takeUntil(this._onDestroy$), 
        select(selectCitiesByCountryId(this.locationData.country_id))).subscribe((res: CityModel[]) => {
          this.cityOfCountry = res;
      });
    }
    this.store.dispatch(new AllGradesRequested());
    this.store.select(selectAllGrades).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.grades = res ? res : [];
    });
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public changeValidation() {
    Object.keys(this.formGroup.controls).forEach(cName => {
      this.formGroup.controls[cName].clearValidators();
      this.formGroup.controls[cName].updateValueAndValidity();
    })
  }

  public getData(): LocationData {
    const controls = this.formGroup.controls;
    this.timezoneApiService.timeZoneOf({lat: Number(controls.latitude.value), lng: Number(controls.longitude.value)})
    return {
      country_id: controls.country_id.value,
      city_id: controls.city_id.value,
      zip_code: controls.zip_code.value,
      location_grade_id: controls.location_grade_id.value,
      latitude: controls.latitude.value,
      longitude: controls.longitude.value,
      address: controls.address.value,
      location_surrounding: controls.location_surrounding.value,
      city_of_location: '',
      time_offset: controls.time_offset.value
    }
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

    this.map.addListener('dragend', () => {
      this.centerLat = this.currentCenter.lat;
      this.centerLng = this.currentCenter.lng;
    });

    // Workaround 
    this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
      this.ngZone.run(() => {
        this.mapRightClick(e);
      })
    })
  }

  mapRightClick($event: google.maps.MouseEvent) {

    if (!this.lockMarker) {
      this.markerLat = $event.latLng.lat();
      this.markerLng = $event.latLng.lng();

      this.centerLat = $event.latLng.lat();
      this.centerLng = $event.latLng.lng();
      this.formGroup.controls.latitude.patchValue($event.latLng.lat());
      this.formGroup.controls.longitude.patchValue($event.latLng.lng());
      this._getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
      this.isShown = true;
      this.markerChange.emit({lat: this.markerLat, lng: this.markerLng, isShown: this.isShown});
    }
  }
}