import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllRetailStoreAdjustmentsLoaded,
    AllRetailStoreAdjustmentsRequested,
    RetailStoreAdjustmentActionToggleLoading,
    RetailStoreAdjustmentAdminRestored,
    RetailStoreAdjustmentCreated,
    RetailStoreAdjustmentDeleted,
    RetailStoreAdjustmentDeletedFromAdminTrash,
    RetailStoreAdjustmentDeletedFromTrash,
    RetailStoreAdjustmentOnServerAdminRestored,
    RetailStoreAdjustmentOnServerCreated,
    RetailStoreAdjustmentOnServerRestored,
    RetailStoreAdjustmentRestored,
    RetailStoreAdjustmentsActionTypes,
    RetailStoreAdjustmentsPageLoaded,
    RetailStoreAdjustmentsPageRequested,
    RetailStoreAdjustmentsPageToggleLoading,
    RetailStoreAdjustmentTrashFlushed,
    RetailStoreAdjustmentUpdated
} from '../_actions/retail-store-adjustment.actions';
import {allRetailStoreAdjustmentsLoaded} from '../_selectors/retail-store-adjustment.selectors';
import {RetailStoreAdjustmentsService} from '../_services/retail-store-adjustment.service';

@Injectable()
export class RetailStoreAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: RetailStoreAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new RetailStoreAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RetailStoreAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RetailStoreAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllRetailStoreAdjustmentsRequested>(RetailStoreAdjustmentsActionTypes.AllRetailStoreAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allRetailStoreAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllRetailStoreAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentsPageRequested>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new RetailStoreAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentUpdated>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentOnServerCreated>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new RetailStoreAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentTrashFlushed>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentOnServerRestored>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RetailStoreAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentOnServerAdminRestored>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RetailStoreAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentDeleted>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentDeletedFromTrash>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<RetailStoreAdjustmentDeletedFromAdminTrash>(RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}