// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ParkingTypeService} from '../_services/parking-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allParkingTypesLoaded} from '../_selectors/parking-type.selectors';
// Actions
import {
    AllParkingTypesLoaded,
    AllParkingTypesRequested,
    ParkingTypeActionTypes,
    ParkingTypesPageRequested,
    ParkingTypesPageLoaded,
    ParkingTypeUpdated,
    ParkingTypesPageToggleLoading,
    ParkingTypeDeleted,
    ParkingTypeOnServerCreated,
    ParkingTypeCreated,
    ParkingTypeActionToggleLoading, ParkingTypeTrashFlushed, ParkingTypeOnServerRestored, ParkingTypeRestored,
    ParkingTypeOnServerAdminRestored, ParkingTypeAdminRestored, ParkingTypeDeletedFromTrash, ParkingTypeDeletedFromAdminTrash
} from '../_actions/parking-type.actions';

@Injectable()
export class ParkingTypeEffects {
    showPageLoadingDispatcher = new ParkingTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ParkingTypeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ParkingTypeActionToggleLoading({isLoading: false});

    @Effect()
    loadAllParkingType$ = this.actions$
        .pipe(
            ofType<AllParkingTypesRequested>(ParkingTypeActionTypes.AllParkingTypesRequested),
            withLatestFrom(this.store.pipe(select(allParkingTypesLoaded))),
            filter(([action, isAllParkingTypeLoaded]) => !isAllParkingTypeLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllParkingTypesLoaded({items: res.data});
            })
        );

    @Effect()
    loadParkingTypePage$ = this.actions$
        .pipe(
            ofType<ParkingTypesPageRequested>(ParkingTypeActionTypes.ParkingTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ParkingTypesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeUpdated>(ParkingTypeActionTypes.ParkingTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeOnServerCreated>(ParkingTypeActionTypes.ParkingTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ParkingTypeCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushParkingTypeTrash$ = this.actions$
        .pipe(
            ofType<ParkingTypeTrashFlushed>(ParkingTypeActionTypes.ParkingTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeOnServerRestored>(ParkingTypeActionTypes.ParkingTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ParkingTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeOnServerAdminRestored>(ParkingTypeActionTypes.ParkingTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ParkingTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeDeleted>(ParkingTypeActionTypes.ParkingTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeDeletedFromTrash>(ParkingTypeActionTypes.ParkingTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashParkingType$ = this.actions$
        .pipe(
            ofType<ParkingTypeDeletedFromAdminTrash>(ParkingTypeActionTypes.ParkingTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ParkingTypeService, private store: Store<AppState>) {
    }
}
