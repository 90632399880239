import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { PictureModel } from 'src/app/core/valuation-process';
import { environment } from 'src/environments/environment';

type Picture = {
  id: number,
  slideId: string,
  type: 'Property Picture' | 'Building Picture'
  orderNum: number,
  name: string,
  url: string
}

@Component({
  selector: 'kt-pictures-module-v2',
  templateUrl: './pictures-module-v2.component.html',
  styleUrls: ['./pictures-module-v2.component.scss']
})
export class PicturesModuleV2Component implements OnInit {
  @Input()
  actionTemplate: TemplateRef<any>;
  @Input() tpPictures: PictureModel[] = []
  @Input() buildingPictures: PictureModel[] = []

  pictures: Picture[] = []

  constructor(
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    const _tpPictures: Picture[] = this.tpPictures.map((p, i) => {
      return {
        id: p.id,
        slideId: `ac-${p.id}`,
        type: 'Property Picture',
        orderNum: i + 1,
        name: p.title ?? 'N/A',
        url: this._imageUrl(p)
      }
    })
    const _buildingPictures: Picture[] = this.buildingPictures.map((p, i) => {
      return {
        id: p.id,
        slideId: `b-${p.id}`,
        type: 'Building Picture',
        orderNum: i + 1,
        name: p.title ?? 'N/A',
        url: this._imageUrl(p)
      }
    })

    this.pictures = [..._tpPictures, ..._buildingPictures]
  }

  private _imageUrl(p: PictureModel): string {
    return `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/files/download?path=${p.path}/${p.name}` 
  }

  imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
