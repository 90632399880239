import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { CriterionModel, criterionReducer } from 'src/app/core/valuation';
import { SizeCriterionChangeDefault } from 'src/app/core/valuation/_actions/size_criterion.actions';
import { selectSizeCriterions } from 'src/app/core/valuation/_selectors/size_criterion.selectors';

@Component({
  selector: 'kt-size-modal',
  templateUrl: './size-modal.component.html',
  styleUrls: ['./size-modal.component.scss']
})
export class SizeModalComponent implements OnInit {
  dataSource: MatTableDataSource<CriterionModel> = new MatTableDataSource();
  comparables: any[];
  headerColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]); 
  displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  formArray: UntypedFormArray;
  selectedCriterion: CriterionModel;
  selectedCriteironIndx: number;
  isSelected: boolean = false;
  activeStates: boolean[] = [];
  isChanged: {[id: number]: boolean}[] = [];

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<SizeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) { 
    this.comparables = data.comparables;
  }

  ngOnInit(): void {
    this._setHeaders(this.comparables);
    this.store.pipe(select(selectSizeCriterions)).subscribe(res => {
      this._createForm(res);
      this.dataSource.data = res;
      this.activeStates = [];
      res.forEach(item => {
        this.activeStates.push(item.active);
      })
      this.selectedCriteironIndx = res.findIndex(item => item.active);
      if (this.selectedCriteironIndx > -1) {
        this.isSelected = true;
        this.selectedCriterion = res[this.selectedCriteironIndx];
      }
    })
  }

  _createForm(criterions: CriterionModel[]): void {
    this.formArray = this.formBuilder.array([]);
    criterions.forEach((criterion, i) => {
      this.formArray.push(this._createCriterionRowForm(criterion, i));
    });
  }

  _createCriterionRowForm(criterion: CriterionModel, index: number): UntypedFormGroup {
    const formArray = this.formBuilder.group({});
    this.isChanged.push({});
    Object.entries(criterion.comValues).forEach(([key, value]) => {
      const control = new UntypedFormControl(value);
      control.valueChanges.subscribe(val => {
        if (val != value) {
          this.isChanged[index][key] = true;
        } else {
          this.isChanged[index][key] = false;
        }
      })
      formArray.setControl(key+'-cont', control);
    })
    // criterion.comValues.forEach(val => {
    //   const control = new FormControl(val);
    //   formArray.push(control);
    // });
    const tpControl = new UntypedFormControl(criterion.tpValue);
    tpControl.valueChanges.subscribe(val => {
      if (val != criterion.tpValue) {
        this.isChanged[index]['tp'] = true;
      } else {
        this.isChanged[index]['tp'] = false;
      }
    })
    return new UntypedFormGroup({
      'tp': tpControl,
      'comparables': formArray
    })
  }

  comparableControl(criterionIndx: number, comparableID: number): AbstractControl {
    const criterionFormRow = this.formArray.at(criterionIndx) as UntypedFormGroup;
    const comparableFormArray = criterionFormRow.get('comparables') as UntypedFormGroup;
    return comparableFormArray.get(comparableID+'-cont');
  }

  tpControl(criterionIndx: number): AbstractControl {
    const criterionFormRow = this.formArray.at(criterionIndx) as UntypedFormGroup;
    return criterionFormRow.get('tp');
  }

  setSizeCriterion(criterion: CriterionModel, indx: number ) {
    this.isSelected = true;
    this.selectedCriterion = criterion;
    this.selectedCriteironIndx = indx;
    this._changeActiveStates(indx);
  }

  _setHeaders(comparables: any[]) {
    const headerColumns = ['standard-header', 'measurement-header'];
    const displayedColumns = ['criterion', 'category'];
    const comparableHeaderColumns = [];
    comparables.forEach((com, i) => {
      headerColumns.push(`com-${i}-header`);
      comparableHeaderColumns.push(`com-${i}-header`);
      displayedColumns.push(`com-${i}`);
    });
    headerColumns.push('tp-header', 'action-header');
    displayedColumns.push('tp', 'actions');
    this.headerColumns$.next(headerColumns);
    this.displayedColumns$.next(displayedColumns);
  }

  _copyCriterion(criterion: CriterionModel): CriterionModel {
    const _tmp = Object.assign({}, criterion) as CriterionModel;
    const _val = Object.assign({}, _tmp.comValues);
    _tmp.comValues = _val;
    return _tmp
  }

  onSave() {
    const criterionFormRow = this.formArray.at(this.selectedCriteironIndx) as UntypedFormGroup;
    const _criterion = this._copyCriterion(this.selectedCriterion);
    const comparableFormArray = criterionFormRow.get('comparables') as UntypedFormGroup;
    Object.entries(comparableFormArray.controls).forEach(([key, control]) => {
      const id = key.substring(0, key.length - 5);
      const _id = Number(id)
      _criterion.comValues[_id] = control.value;
    })
    // comparableFormArray.controls.forEach((c, i) => {
    //   _criterion.comValues[i] = c.value ? c.value : '';
    // })
    const tp_value = criterionFormRow.get('tp').value;
    _criterion.tpValue = tp_value ? tp_value : '';
    _criterion.active = true;
    this.isSelected = true;

    const isChanged = this.isChanged[this.selectedCriteironIndx];
    let atLeastOneChanged = false;
    Object.entries(isChanged).forEach(([key, value]) => {
      if (value) {
        atLeastOneChanged = true;
      }
    })
    _criterion.isChanged = atLeastOneChanged;
    this.store.dispatch(new SizeCriterionChangeDefault({
      criterion: _criterion
    }))
    this.dialogRef.close();
  }

  onClose() {
    this.dialogRef.close();
  }

  _changeActiveStates(indx: number) {
    const _states = [];
    this.activeStates.forEach((state, i) => {
      const _state = indx === i ? true : false;
      _states.push(_state);
    })
    this.activeStates = _states;
  }
}
