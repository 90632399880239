// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Models
import {AssetClassOfficeFacilityModel} from '../_models/asset-class-office-facility.model';
import {QueryParamsModel} from '../../_base/crud';
import {AssetClassOfficeFacilityActions, AssetClassOfficeFacilityActionTypes} from '../_actions/asset-class-office-facility.actions';

export interface AssetClassOfficeFacilitiesState extends EntityState<AssetClassOfficeFacilityModel> {
    isAllAssetClassOfficeFacilitiesLoaded: boolean;
    queryRowsCount: number;
    queryResult: AssetClassOfficeFacilityModel[];
    lastCreatedOfficeTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassOfficeFacilityModel> = createEntityAdapter<AssetClassOfficeFacilityModel>();

export const initialAssetClassOfficeFacilitiesState: AssetClassOfficeFacilitiesState = adapter.getInitialState({
    isAllAssetClassOfficeFacilitiesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOfficeTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassOfficeFacilitiesReducer(state = initialAssetClassOfficeFacilitiesState, action: AssetClassOfficeFacilityActions): AssetClassOfficeFacilitiesState {
    switch (action.type) {
        case AssetClassOfficeFacilityActionTypes.AssetClassOfficeFacilityActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AssetClassOfficeFacilityActionTypes.AllAssetClassOfficeFacilitiesLoaded:
            return adapter.addAll(action.payload.assetClassOfficeFacilities, {
                ...state, isAllAssetClassOfficeFacilitiesLoaded: true
            });
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
