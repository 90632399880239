import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {delay, take} from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import {
    TrainingDocument,
    TrainingDocumentOnServerCreated,
    selectLastCreatedTrainingDocumentId,
} from '../../../../../core/training-documents';
import {TrainingDocumentUpdated} from '../../../../../core/training-documents';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {SubheaderService} from '../../../../../core/_base/layout';
import {UploadFileComponent} from '../../../shared_components/upload-file/upload-file.component';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-training-document-edit',
    templateUrl: './training-document-edit.component.html',
    styleUrls: ['./training-document-edit.component.scss']
})


export class TrainingDocumentEditComponent implements OnInit, OnDestroy {

    @ViewChild(UploadFileComponent)
    uploadFileComponent: UploadFileComponent;

    trainingDocument: TrainingDocument;
    oldTrainingDocument: TrainingDocument;

    trainingDocumentForm: UntypedFormGroup;
    hasFormErrors = false;
    fileType: string;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param subheaderService
     * @param activatedRoute
     * @param router
     * @param fb: FormBuilder
     * @param location
     * @param layoutUtilsService
     * @param store: Store<AppState>
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private translate: TranslateService) {
        this.fileType = 'application/pdf';
    }

    ngOnInit() {
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.activatedRoute.data.subscribe(data => {
                    const res = data.data;
                    this.oldTrainingDocument = res.data;
                    this.trainingDocument = Object.assign({}, this.oldTrainingDocument);
                    this.initTrainingDocument();
                });
            } else {
                this.trainingDocument = new TrainingDocument();
                this.trainingDocument.clear();
                this.oldTrainingDocument = Object.assign({}, this.trainingDocument);
                this.initTrainingDocument();
            }
        });

        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/training-documents-management`},
            {title: 'Linked tables', page: `../default/training-documents-management/linked-tables`},
            {title: 'Interaction types', page: `../default/training-documents-management/linked-tables/training-documentes`},
            {
                title: this.getComponentTitle(), page: id > 0 ?
                    `../default/training-documents-management/linked-tables/training-documentes/edit/${id}` :
                    `../default/training-documents-management/linked-tables/training-documentes/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initTrainingDocument() {
        this.createForm();
        // set subheaderService
    }


    createForm() {
        this.trainingDocumentForm = this.fb.group({
            name: [this.trainingDocument.name, Validators.required]
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.trainingDocument && this.trainingDocument.id > 0) {
            return this.translate.instant('TRAINING_DOC.FORM.TITLE.EDIT', {name: this.trainingDocument.name});
        }
        return this.translate.instant('TRAINING_DOC.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.trainingDocumentForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.trainingDocumentForm.controls;
        /** check form */
        if (this.trainingDocumentForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedTrainingDocument = this.prepareTrainingDocument();

        if (this.uploadFileComponent.uploadFiles.length < 1) {
            this.hasFormErrors = true;
            return;
        }

        if (this.trainingDocument.id > 0) {
            this.updateTrainingDocument(editedTrainingDocument);
        } else {
            this.createTrainingDocument(editedTrainingDocument);
        }
    }

    /**
     * Update trainingDocument
     *
     * @param _trainingDocument: TrainingDocumentModel
     */
    updateTrainingDocument(_trainingDocument: TrainingDocument) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateTrainingDocument: Update<TrainingDocument> = {
            id: _trainingDocument.id,
            changes: _trainingDocument
        };
        this.store.dispatch(new TrainingDocumentUpdated({
            partialtrainingDocument: updateTrainingDocument,
            trainingDocument: _trainingDocument,
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
        }));

        this.oldTrainingDocument = _trainingDocument;
        this.layoutUtilsService.showActionNotification(
            this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'), MessageType.Update, 3000, true, false);
    }

    /**
     * Create trainingDocument
     *
     * @param _trainingDocument: TrainingDocumentModel
     */
    createTrainingDocument(_trainingDocument: TrainingDocument) {
        this.store.dispatch(new TrainingDocumentOnServerCreated({
            trainingDocument: _trainingDocument,
            fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
        }));
        this.oldTrainingDocument = _trainingDocument;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedTrainingDocumentId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        const controls = this.trainingDocumentForm.controls;

        return controls.name.value;
    }

    navigateToParent() {
        let url = '../';
        if (this.trainingDocument.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    /**
     * Returns prepared assignment
     */
    prepareTrainingDocument(): TrainingDocument {
        const controls = this.trainingDocumentForm.controls;
        const _trainingDocument = new TrainingDocument();
        _trainingDocument.clear();
        _trainingDocument.id = this.trainingDocument.id;
        _trainingDocument.name = controls.name.value;

        this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
            if (value) {
                _trainingDocument.file = value.success;
            }
        });

        if (this.oldTrainingDocument && this.oldTrainingDocument.file.length > 0) {
            _trainingDocument.file = this.oldTrainingDocument.file;
        }
        return _trainingDocument;
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {

        const _trainingDocument = this.prepareTrainingDocument();
        return _trainingDocument.name != this.oldTrainingDocument.name;
    }

}
