<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$"
                       [tooltipTitle]="'TOOLTIP.BUILDING.LIST.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.BUILDING.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-title">
                <span>Buildings - {{listViewMode ? listText: mapText}}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>


            <button (click)="addBuilding()" mat-raised-button matTooltip="Create a New Building" color="primary" type="button">
                <span>Add Building</span>
            </button>

            <button (click)="toggleView()"
                    mat-raised-button
                    matTooltip="Change View Mode"
                    color="primary"
                    type="button"
                    class="ml-2">
                <span>{{!listViewMode ? listText: mapText}}</span>
            </button>

            <button
                    (click)="trash()"
                    *ngIf="trashCnt != 0"
                    mat-raised-button
                    matTooltip="Deleted Buildings"
                    type="button"
                    style="background-color: #c4c5d6 !important;"
                    class="ml-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>

            <button
                    (click)="adminTrash()"
                    *ngIf="adminTrashCnt != 0 && (canAccessAdminTrash$|async)"
                    mat-raised-button
                    matTooltip="Deleted Buildings"
                    type="button"
                    style="background-color: #c4c5d6 !important;"
                    class="ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>


        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- <kt-filter-section [acType]="0"></kt-filter-section> -->
        <!-- end::FILTERS & GROUP ACTIONS -->
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="buildingStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_buildings }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TOTAL BUILDINGS</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_different_locations }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">DIFFERENT LOCATIONS</span></p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_used_by }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">USED BY COMPARABLES</span></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <kt-filter-section [acType]="0" [filter$]="filterObs$" (filterChange)="onFilterChange($event)"></kt-filter-section>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- start::MAP -->
        <div class="form-group kt-form__group row" *ngIf="!listViewMode">
            <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                <agm-map
                        #AgmMap
                        [latitude]="centerLat$|async"
                        [longitude]="centerLng$|async"
                        [zoom]="zoom"
                        [scrollwheel]="null"
                        [disableDoubleClickZoom]="true"
                        (mapReady)="mapReady($event)"
                        (mapClick)="mapClick()"
                        (centerChange)="centerChange($event)"
                        (zoomChange)="zoomChanged($event)"
                        (boundsChange)="boundsChanged($event)"
                        [clickableIcons]="false">
                    <agm-map-type-control></agm-map-type-control>

                    <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="setLocation()">
                        <mat-icon>my_location</mat-icon>
                    </button>

                    <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">

                        <!-- animation="DROP" -->
                        <agm-marker *ngFor="let item of buildings"
                                    [latitude]="item.locationData.latitude"
                                    [longitude]="item.locationData.longitude"
                                    [label]="getLabel(item.id)"
                                    [iconUrl]="icon"
                                    (markerClick)="showInfo(item.id, item)">
                        </agm-marker>
                    </agm-marker-cluster>
                </agm-map>
            </div>
        </div>
        <!-- end::MAP -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="desc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let building">{{building.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="building_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'BUILDING.COLUMN.BUILDING_ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">{{building.building_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city_of_location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'BUILDING.COLUMN.LOCATION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        {{ building.locationData.city_name ? (building.locationData.city_name + ', ' ): '' }}
                        {{ building.locationData.country_name ? building.locationData.country_name : ''}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="state_repair_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'BUILDING.COLUMN.STATE_REPAIR' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        {{building.internalAspectData.state_of_repair_name && building.internalAspectData.state_of_repair_name.length > 0 ? building.internalAspectData.state_of_repair_name :'N/A'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="building_grade_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'BUILDING.COLUMN.GRADE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        {{building.building_grade_name && building.building_grade_name.length > 0 ? building.building_grade_name :'N/A'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="building_type_name">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'BUILDING.COLUMN.TYPE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        {{building.building_type_name && building.building_type_name.length > 0 ? building.building_type_name :'N/A'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="total_floors">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'BUILDING.COLUMN.FLOORS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        {{building.internalAspectData.above_floors || building.internalAspectData.below_floors 
                            ? (building.internalAspectData.above_floors + building.internalAspectData.below_floors)
                            : 'N/A'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <b>
                            <span class="label label-inline label-rounded label-outline-{{getItemCssClassByStatus(acr.status)}} label-outline-2x label-lg"
                                style="width: 10rem;">
                                {{getItemStatusString(acr.status)}}
                            </span>
                        </b>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="linked_properties">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Linked Properties
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        {{ building.linked_comparable}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'BUILDING.COLUMN.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let building">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <p>
                                <b>State of Repair:</b> {{building.internalAspectData.state_of_repair_name && building.internalAspectData.state_of_repair_name.length > 0 ? building.internalAspectData.state_of_repair_name :'N/A'}}
                            </p>
                            <p>
                                <b>Status:</b> {{getItemStatusString(building.status)}}
                            </p>
                            <p>
                                <b>Linked comparables:</b> {{building.linked_comparable}}
                            </p>
                            <p [innerHtml]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                        username: building.createdBy ? building.createdBy : 'Unknown',
                                        date: (building.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }"></p>

                            <p [innerHtml]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: building.lastUpdatedBy ? building.lastUpdatedBy: 'Unknown',
                                date: (building.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }"></p>

                        </ng-template>
                        <button mat-icon-button
                                color="primary"
                                [ngbPopover]="popContent"
                                [popoverTitle]="popTitle"
                                *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <button mat-icon-button
                                color="primary"
                                matTooltip="Edit the Building"
                                (click)="editBuilding(building)">
                            <mat-icon>create</mat-icon>
                        </button>

                        <button mat-icon-button
                                color="warn"
                                matTooltip="Delete the Building"
                                type="button"
                                [disabled]="building.linked_comparable > 0"
                                (click)="deleteBuilding(building)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
