<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h4 class="card-label">Delivery Task</h4>
		</div>
		<div class="card-toolbar">
			<button *ngIf="!readonly" mat-button mat-raised-button color="primary" (click)="save()" class="mr-2">
				Save
			</button>
			<button mat-button mat-raised-button color="warn" (click)="close()" aria-label="Close"
				style="background-color: orange; color: white;">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
	<div class="card-body pt-0 w-100">
		<ng-container *ngIf="data.draftTasks.length > 0">
			<kt-delivery-form 
				*ngFor="let task of data.draftTasks"
				#draftStatement
				[taskId]="task.id" 
				[name]="'Draft Statement'"
				[type]="task.details"
				[disabled]="!task.doable"
				[readonly]="readonly"></kt-delivery-form>
		</ng-container>
		<kt-delivery-form 
			#valuation
			*ngFor="let task of data.valTasks"
			[taskId]="task.id" 
			[name]="'Valuation Report'"
			[type]="task.details"
			[disabled]="!task.doable"
			[readonly]="readonly"></kt-delivery-form> 
		<ng-template #loading>
			...loading
		</ng-template>
	</div>
</div>
