import { Component, Input, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { AssetClassDetailModel } from "src/app/core/asset_class";
import { TypesUtilsService } from "src/app/core/_base/crud";
import { ImageViewerDialogComponent } from "src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component";
import { environment } from "src/environments/environment";
import { awConst } from "src/app/app.constants";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

@Component({
    selector: "kt-picture-selector",
    templateUrl: "./picture-selector.component.html",
    styleUrls: ["./picture-selector.component.scss"],
})
export class PictureSelectorComponent implements OnInit {
    @Input()
    readonly: boolean;
    @Input()
    targetPropertyDetail: AssetClassDetailModel;
    @Input()
    selectedPics: IPic[];

    public pics: IPic[] = [];
    public basePath = environment.baseApiUrl;
    private _selectedPics: IPic[] = [];
    awConst = awConst;

    constructor(
        private dialog: MatDialog,
        public typesUtilsService: TypesUtilsService,
        private subDomainService: SubdomainService
    ) {}

    ngOnInit(): void {
        this.selectedPics.forEach((pic) => this._selectedPics.push(pic));
        let propertyPictureOrderNum = 1;
        let conditionRatingPictureOrderNum = 1;
        let buildingPictureOrderNum = 1;
        this.targetPropertyDetail.ac_pictures
            .filter((pic) => pic.is_featured == 0)
            .forEach((pic) => {
                if (pic.hasOwnProperty("rating_desc")) {
                    // this._selectedPics.push(this._getPicFromImage(pic, PicType.Rating, conditionRatingPictureOrderNum));
                    // this.pics.push(this._getPicFromImage(pic, PicType.Rating, conditionRatingPictureOrderNum));
                    // conditionRatingPictureOrderNum += 1;
                } else {
                    this.pics.push(
                        this._getPicFromImage(
                            pic,
                            PicType.Property,
                            propertyPictureOrderNum
                        )
                    );
                    propertyPictureOrderNum += 1;
                }
            });
        this.targetPropertyDetail.building_information.files
            .filter((pic) => pic.is_featured == 0)
            .forEach((pic) => {
                this.pics.push(
                    this._getPicFromImage(
                        pic,
                        PicType.Building,
                        buildingPictureOrderNum
                    )
                );
                buildingPictureOrderNum += 1;
            });
    }

    public previewPicture(pic: IPic) {
        this.dialog.open(ImageViewerDialogComponent, {
            data: {
                boldTitle: this._getboldTitle(pic.type, pic.orderNum),
                title: pic.title,
                picture: pic.path + "/" + pic.name,
                type: this.typesUtilsService.getFileType(pic.name),
                withoutViewer: true,
            },
        });
    }

    public checkboxChange(event: MatCheckboxChange, pic: IPic) {
        if (event.checked) {
            const newPic: IPic = { ...pic, selected: true };
            this._selectedPics.push(newPic);
        } else {
            const indx = this._selectedPics.findIndex(
                (p) => p.type === pic.type && p.id === pic.id
            );
            if (indx >= 0) {
                this._selectedPics.splice(indx, 1);
            }
        }
    }

    public getSelectedPics() {
        return this._selectedPics;
    }

    private _getPicFromImage(
        img: any,
        picType: PicType,
        orderNum: number
    ): IPic {
        return {
            id: img.id,
            type: picType,
            title: img.title ? img.title : "N/A",
            name: img.name,
            path: img.path,
            url: this._getImageUlr(img.path, img.name),
            selected: this._isSelected(img, picType),
            orderNum,
        };
    }

    private _getImageUlr(path: string, name: string): string {
        return this.basePath + `api/${this.subDomainService.subDomain}/files/download?path=` + path + "/" + name;
    }

    private _isSelected(img: any, type: PicType): boolean {
        const indx = this._selectedPics.findIndex(
            (p) => p.id === img.id && p.type === type
        );
        if (indx >= 0) {
            return true;
        }
        return false;
    }

    private _getboldTitle(type: PicType, orderNum: number): string {
        switch (type) {
            case PicType.Property:
                return `Property Picture (${orderNum})`;
            case PicType.Rating:
                return `Condition Rating Picture (${orderNum})`;
            case PicType.Building:
                return `Building Picture (${orderNum})`;
        }
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND;
    }
}

export interface IPic {
    id: number;
    type: PicType;
    title?: string;
    name?: string;
    path?: string;
    url?: string;
    orderNum?: number;
    selected?: boolean;
    selection_status?: number;
    rating_desc?: string;
    rating?:{
        title: string,
        rate: string,
        description?: string
    }
}

export enum PicType {
    Property = "Property",
    Rating = "Rating",
    Building = "Building",
}
