import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    CurrencyModel, CurrencyOnServerCreated, selectAllCurrencies, selectLastCreatedCurrencyId
} from '../../../../../../core/admin';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import coinify from 'coinify';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-currency-edit',
    templateUrl: './currency-edit.component.html',
})
export class CurrencyEditComponent implements OnInit, OnDestroy {
    currencyForm: UntypedFormGroup;
    hasFormErrors = false;

    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;

    currencyData = coinify.currencies;
    currencyList: Array<{ key: string, value: string }> = [];
    // Private properties
    private componentSubscriptions: Subscription;


    constructor(private fb: UntypedFormBuilder,
                public dialogRef: MatDialogRef<CurrencyEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.store.pipe(
            select(selectAllCurrencies),
        ).subscribe(res => {
            if (!res) {
                return;
            }

            Object.keys(this.currencyData).forEach(
                key => {
                    if (res.filter(resItem => resItem.acronym == key).length == 0) {
                        const item = this.currencyData[key];
                        const value = item.n + ` [${key}]`;
                        this.currencyList.push({key: key, value: value});
                    }
                });
        });
        this.createForm();
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    createForm() {
        this.currencyForm = this.fb.group({
            currency: ['', Validators.required]
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        return this.translate.instant('CURRENCY.FORM.TITLE.NEW')
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.currencyForm.controls;
        /** check form */
        if (this.currencyForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const _code = controls.currency.value;
        const _currData = coinify.get(_code);
        const _currency = new CurrencyModel();
        _currency.clear();

        _currency.name = _currData.name;
        _currency.symbol = _currData.symbol_native;
        _currency.acronym = _currData.code;
        this.createCurrency(_currency);
    }

    /**
     * Create currency
     *
     * @param _currency: CurrencyModel
     */
    createCurrency(_currency: CurrencyModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        this.store.dispatch(new CurrencyOnServerCreated({currency: _currency}));

        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedCurrencyId)
        ).subscribe(res => {
            if (!res) {
                return;
            }

            this.viewLoading = false;
            this.dialogRef.close({
                _currency,
                isEdit: false
            });
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }
}
