<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet-head--lg'" [viewLoading$]="dataSource.loading$"
                        [tooltipTitle]="'TOOLTIP.AGENCY.LIST.TITLE' | translate"
                        [tooltipDesc]="'TOOLTIP.AGENCY.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet-head-title">
                <span>
                    {{ 'AGENCY.LIST.TITLE' | translate }}
                </span>
            </h3>
        </ng-container>

        <ng-container ktPortletTools *ngIf="canEdit$ | async">
            <button 
                (click)="addAgency()" 
                mat-raised-button 
                [matTooltip]="'AGENCY.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button" class="mr-2">
                <span>
                    {{ 'AGENCY.LIST.BUTTON.NEW.LABEL' |  translate}}
                </span>
            </button>
            <button *ngIf="(trashCnt$ |async) != 0" (click)="trash()" mat-raised-button 
                [matTooltip]="'AGENCY.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt$ |async }})</span>
            </button>
            <button *ngIf="(adminTrashCnt$ |async) != 0 && (canAdminThrash$|async)" (click)="adminTrash()" mat-raised-button 
                [matTooltip]="'AGENCY.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt$ |async }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="agencyStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_agencies }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TOTAL AGENCIES</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_assignements }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">ASSIGNMENTS</span></p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_toes }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">TERMS OF ENGAGEMENT</span></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <kt-filter 
            [config]="{
                search: {
                    main: true,
                    location: true,
                    reset: true
                }
            }"
            (filterChange)="onFilterChange($event)"
        ></kt-filter>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="mat-elevation-z0 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort="matSort"
                       matSortActive="id"
                       matSortDirection="asc"
                       matSortDisableClear>
                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                        *matCellDef="let agency">{{agency.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'AGENCY.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let agency" (click)="editAgency(agency)">{{agency.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="country_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'AGENCY.LIST.DATATABLE.COUNTRY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let agency">{{agency.country_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'AGENCY.LIST.DATATABLE.CITY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let agency">{{agency.city_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ass_cnt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'AGENCY.LIST.DATATABLE.ASSIGNMENTS_CNT' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let agency">{{agency.ass_cnt}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="toe_cnt">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="text-center row h80-p">
                            <div class="col-12 h50-percent pt-4 pl-2">
                                <span>{{ 'AGENCY.LIST.DATATABLE.TOE_CNT' | translate }}</span>
                            </div>
                            <div class="col-4 text-left bg-gray h50-percent pt-4"><span class="pl-2">{{ 'AGENCY.LIST.DATATABLE.TOE_CNT_DRAFT' | translate }}</span></div>
                            <div class="col-4 text-left bg-gray h50-percent pt-4"><span class="pl-2">{{ 'AGENCY.LIST.DATATABLE.TOE_CNT_ONGOING' | translate }}</span></div>
                            <div class="col-4 text-left bg-gray h50-percent pt-4"><span class="pl-2">{{ 'AGENCY.LIST.DATATABLE.TOE_CNT_COMPLETE' | translate }}</span></div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let agency">
                        <span class="col-4 text-left pl-2">{{draft_cnt(agency)}}</span>
                        <span class="col-4 text-left pl-2">{{ongoing_cnt(agency)}}</span>
                        <span class="col-4 text-left pl-2">{{complete_cnt(agency)}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="text-center">
                        {{ 'AGENCY.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let agency">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <!-- <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: agency.createdBy ? agency.createdBy : 'Unknown',
                                date: (agency.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: agency.lastUpdatedBy ? agency.lastUpdatedBy : 'Unknown',
                                date: (agency.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)}">
                            </div> -->
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: agency.createdBy ? agency.createdBy : 'Unknown',
                                date: (agency.created_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: agency.lastUpdatedBy ? agency.lastUpdatedBy : 'Unknown',
                                date: (agency.updated_at | date: 'dd MMM yyyy')}">
                            </div>
                        </ng-template>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" 
                            [matTooltip]="'AGENCY.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editAgency(agency)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <span *ngIf="canEdit$ | async"
                                [matTooltip]="agency.ass_cnt == 0 ? ('AGENCY.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate) : 'Can\'t delete'">
                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="agency.ass_cnt > 0"
                                    (click)="deleteAgency(agency)">
                                <mat-icon>delete</mat-icon>
                            </button>&nbsp;
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
