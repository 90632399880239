import {
    AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges,
    SimpleChanges, OnDestroy
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutUtilsService } from '../../../../../core/_base/crud';
import { RegNumberEditDialogComponent } from '../reg-number-edit/reg-number-edit.dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RegNumberModel } from '../../../../../core/admin';
import { ExternalRefTemplateListComponent } from '../../../toe/_sub/external-ref-template-list/external-ref-template-list.component';
import { takeUntil } from 'rxjs/operators';
import { ExternalRefTemplateModel } from 'src/app/core/template';
import { ExternalRefTemplateModalComponent } from '../../../template/templates/external-ref-template/_sub/external-ref-template-modal/external-ref-template-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';

@Component({
    selector: 'kt-reg-numbers-list',
    templateUrl: './reg-numbers-list.component.html',
    styleUrls: ['./reg-numbers-list.component.scss']
})

export class RegNumbersListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    displayedColumns = ['label', 'code', 'actions'];

    @Input() readonly: boolean = false;
    @Input() regNumbersSubject: BehaviorSubject<RegNumberModel[]>;

    regNumbers: RegNumberModel[]= [];
    dataSource = new MatTableDataSource([]);
    trashCnt = 0;
    adminTrashCnt = 0;
    private subscription: Subscription;

    @ViewChild(MatSort) matSort: MatSort;

    // Selection
    private _onDestroy$: Subject<void> = new Subject();

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private layoutUtilsService: LayoutUtilsService,
                private cdRef: ChangeDetectorRef,
                private translate: TranslateService, 
                private store: Store<AppState>) {
    }

    ngOnInit() {
        this.subscription = this.regNumbersSubject.asObservable().subscribe(
            (res) => {
                this.regNumbers = res;
                this.dataSource.data = this.regNumbers;
            }
        );
    }

    private _viewTemplate(template: ExternalRefTemplateModel) {
        this.dialog.open(ExternalRefTemplateModalComponent, {
            data: {
                template
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {}

    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
    ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    addRegNumber() {
        const regNumber = new RegNumberModel();
        regNumber.clear(); // Set all defaults fields
        this.editRegNumber(regNumber, -1);
    }
    
    editRegNumber(_regNumber: RegNumberModel, index: number) {
        const dialogRef = this.dialog.open(RegNumberEditDialogComponent, {
            data: {regNumber: _regNumber}
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
    
                if (index != -1) {
                    this.regNumbers = Object.assign([], this.regNumbers);
                    this.regNumbers[index] = res.regNumber;
                    this.regNumbersSubject.next(this.regNumbers);
                    this.cdRef.detectChanges();
                } else {
                    this.regNumbers = [...this.regNumbers, res.regNumber];
                    this.regNumbersSubject.next(this.regNumbers);
                    this.cdRef.detectChanges();
                }
            }
        );
    }
    
    deleteRegNumber(regNumber: RegNumberModel, index: number) {
        const _title = 'Are you sure?';
        const _description: string = 'The Registration number "' + regNumber.label + '" will be deleted';
        const _waitDesciption = 'Registration number deleting';
    
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.regNumbers = Object.assign([], this.regNumbers);
            this.regNumbers.splice(index, 1);
            this.regNumbersSubject.next(this.regNumbers);
        });
    }
}
