import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { ToeReportTasksActions, ToeReportTasksActionTypes } from "../_actions/toe-report-task.actions";
import { ToeReportTaskModel } from "../_models/toe-report-task.model";

export interface ToeReportTasksState extends EntityState<ToeReportTaskModel> {

}
export const adapter: EntityAdapter<ToeReportTaskModel> = createEntityAdapter<ToeReportTaskModel>();
export const initialToeReportTasksState: ToeReportTasksState = adapter.getInitialState({});

export function toeReportTasksReducer(state = initialToeReportTasksState, action: ToeReportTasksActions): ToeReportTasksState {
	switch (action.type) {
		case ToeReportTasksActionTypes.AllToeReportTasksByToeIDRequested:
			return state;
		case ToeReportTasksActionTypes.AllToeReportTasksByToeIDLoaded:
			return adapter.setAll(action.payload.reports, state);
		case ToeReportTasksActionTypes.UpdateToeReportTask:
			return state;
		case ToeReportTasksActionTypes.ToeReportTaskUpdated:
			return adapter.updateOne(action.payload.update, state);
		case ToeReportTasksActionTypes.UpdateReportTaskReports:
			const update: Update<ToeReportTaskModel> = {
				id: action.payload.id,
				changes: {
					point: action.payload.taskCompleted ? 5 : 0,
					report: {
						data: action.payload.reports
					}
				}
			}
			return adapter.updateOne(update, state);
		case ToeReportTasksActionTypes.ReportTaskDeleted:
			return adapter.removeOne(action.payload.id, state);
		default:
			return state;
	}
}