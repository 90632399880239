import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CriterionsTemplateService, ICriterionTemplate, IDefaultCriterion } from 'src/app/core/template';
import { CriterionType } from 'src/app/core/valuation';
import { getCriterionCategories } from 'src/app/core/valuation/_models/criterion-categories.model';

type DataSourceItem = Omit<IDefaultCriterion, 'criterion_str'> & {category: string, selected: boolean};

@Component({
  selector: 'kt-default-criterions-modal',
  templateUrl: './default-criterions-modal.component.html',
  styleUrls: ['./default-criterions-modal.component.scss']
})
export class DefaultCriterionsModalComponent implements OnInit, OnDestroy {
  assetClassName: string = '';
  acTypeId: number = undefined;
  datasource: MatTableDataSource<DataSourceItem> = new MatTableDataSource();
  defaultCriterions: DataSourceItem[] = [];
  selectedCriterions: ICriterionTemplate[] = [];
  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    private dialogRef: MatDialogRef<DefaultCriterionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: CriterionsTemplateService
  ) { 
    this.acTypeId = data.acTypeId;
    this.selectedCriterions = data.selectedCriterions;
  }

  ngOnInit(): void {
    this.assetClassName = this._getAcName(this.acTypeId);
    this.service.getDefaults(this.acTypeId).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.defaultCriterions = res.map(
        item => ({
          crit_id: item.crit_id, 
          name: item.name, 
          category_id: item.category_id,
          category: getCriterionCategories(item.category_id), 
          selected: this._contains(this.selectedCriterions, item),
          order_num: item.order_num
        })
      ).sort((a, b) => a.order_num - b.order_num);
      this.datasource.data = this.defaultCriterions;
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  public onNoClick() {
    this.dialogRef.close(this.selectedCriterions);
  }
  public onSaveClick() {
    const selecteds: ICriterionTemplate[] = this.defaultCriterions.filter(item => item.selected).map(item => ({
      id: undefined,
      crit_id: item.crit_id,
      name: item.name,
      category: item.category_id,
      categoryName: getCriterionCategories(item.category_id),
      type: CriterionType.Other
    }));
    this.dialogRef.close(selecteds);
  }
  public onSelectionChange(event: MatCheckboxChange, index: number) {
    const item = this.defaultCriterions[index];
    const temp: DataSourceItem = {...item, selected: event.checked};
    this.defaultCriterions.splice(index, 1, temp);
    this.datasource.data = this.defaultCriterions;
  }

  private _getAcName(acTypeId: number): string {
    switch (acTypeId) {
      case 1:
        return 'Apartment';
      case 3:
        return 'Office';
      default:
        return ''
    }
  }
  private _contains(hay: ICriterionTemplate[], needle: IDefaultCriterion): boolean {
    const index = hay.findIndex(item => item.crit_id == needle.crit_id);
    return index > -1;
  }
}
