// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {IvsStandard} from '../_models/ivs-standard.model';

export enum IvsStandardActionTypes {

    AllIvsStandardsRequested = '[IvsStandards Home Page] All IvsStandards Requested',
    AllIvsStandardsLoaded = '[IvsStandards API] All IvsStandards Loaded',

    IvsStandardOnServerCreated = '[Edit IvsStandard Dialog] IvsStandard On Server Created',
    IvsStandardCreated = '[Edit IvsStandards Dialog] IvsStandards Created',
    IvsStandardUpdated = '[Edit IvsStandard Dialog] IvsStandard Updated',
    IvsStandardDeleted = '[IvsStandards List Page] IvsStandard Deleted',

    IvsStandardsPageRequested = '[IvsStandards List Page] IvsStandards Page Requested',
    IvsStandardsPageLoaded = '[IvsStandards API] IvsStandards Page Loaded',
    IvsStandardsPageCancelled = '[IvsStandards API] IvsStandards Page Cancelled',

    IvsStandardsPageToggleLoading = '[IvsStandards page] IvsStandards Page Toggle Loading',
    IvsStandardsActionToggleLoading = '[IvsStandards] IvsStandards Action Toggle Loading',

    // trash system

    IvsStandardOnServerRestored = '[IvsStandard Trash] IvsStandard On Server Restored',
    IvsStandardRestored = '[IvsStandard Trash] IvsStandard Restored',

    IvsStandardOnServerAdminRestored = '[IvsStandard Admin Trash] IvsStandard On Server Restored',
    IvsStandardAdminRestored = '[IvsStandard Admin Trash] IvsStandard Restored',

    IvsStandardDeletedFromTrash = '[IvsStandard Trash] IvsStandard deleted',
    IvsStandardDeletedFromAdminTrash = '[IvsStandard Admin Trash] IvsStandard deleted',

    IvsStandardTrash = 'IvsStandard Trashed',
    IvsStandardTrashFlushed = 'IvsStandard Trash Flushed',
}

export class IvsStandardOnServerCreated implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardOnServerCreated;

    constructor(public payload: { ivsStandard: IvsStandard}) {
    }
}

export class IvsStandardCreated implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardCreated;

    constructor(public payload: { ivsStandard: IvsStandard }) {
    }
}

export class IvsStandardUpdated implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardUpdated;

    constructor(public payload: {
        partialivsStandard: Update<IvsStandard>,
        ivsStandard: IvsStandard
    }) {
    }
}

export class IvsStandardDeleted implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class IvsStandardsPageRequested implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class IvsStandardsPageLoaded implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardsPageLoaded;

    constructor(public payload: { ivsStandards: IvsStandard[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class IvsStandardsPageCancelled implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardsPageCancelled;
}

export class AllIvsStandardsRequested implements Action {
    readonly type = IvsStandardActionTypes.AllIvsStandardsRequested;
}

export class AllIvsStandardsLoaded implements Action {
    readonly type = IvsStandardActionTypes.AllIvsStandardsLoaded;

    constructor(public payload: { ivsStandards: IvsStandard[] }) {
    }
}

export class IvsStandardsPageToggleLoading implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class IvsStandardsActionToggleLoading implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class IvsStandardDeletedFromAdminTrash implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class IvsStandardDeletedFromTrash implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class IvsStandardOnServerRestored implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IvsStandardRestored implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardRestored;

    constructor(public payload: { item: IvsStandard }) {
    }
}

export class IvsStandardOnServerAdminRestored implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IvsStandardAdminRestored implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardAdminRestored;

    constructor(public payload: { item: IvsStandard }) {
    }
}

export class IvsStandardTrashFlushed implements Action {
    readonly type = IvsStandardActionTypes.IvsStandardTrashFlushed;

    constructor() {
    }
}

export type IvsStandardActions = IvsStandardCreated
    | IvsStandardUpdated
    | IvsStandardDeleted
    | IvsStandardsPageRequested
    | IvsStandardsPageLoaded
    | IvsStandardsPageCancelled
    | AllIvsStandardsLoaded
    | AllIvsStandardsRequested
    | IvsStandardOnServerCreated
    | IvsStandardsPageToggleLoading
    | IvsStandardsActionToggleLoading
    | IvsStandardDeletedFromAdminTrash
    | IvsStandardDeletedFromTrash
    | IvsStandardOnServerRestored
    | IvsStandardRestored
    | IvsStandardOnServerAdminRestored
    | IvsStandardAdminRestored
    | IvsStandardTrashFlushed;
