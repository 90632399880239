import {Component, Inject, OnInit} from '@angular/core';

@Component({
    selector: 'kt-terms-dialog',
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['./terms-dialog.component.scss']
})

export class TermsDialogComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
