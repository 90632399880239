// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {KeyCategoriesState} from '../_reducers/key-category.reducers';
import * as fromKeyCategory from '../_reducers/key-category.reducers';
import {each} from 'lodash';
import {KeyCategoryModel} from '../_models/key-category.model';

export const selectKeyCategoriesState = createFeatureSelector<KeyCategoriesState>('keyCategories');

export const selectKeyCategoryById = (keyCategoryId: number) => createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => keyCategoriesState.entities[keyCategoryId]
);

export const selectAllKeyCategories = createSelector(
    selectKeyCategoriesState,
    fromKeyCategory.selectAll
);

export const selectAllKeyCategoriesIds = createSelector(
    selectKeyCategoriesState,
    fromKeyCategory.selectIds
);

export const allKeyCategoriesLoaded = createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => keyCategoriesState.isAllKeyCategoriesLoaded
);


export const selectKeyCategoriesPageLoading = createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => keyCategoriesState.listLoading
);

export const selectKeyCategoriesActionLoading = createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => keyCategoriesState.actionLoading
);

export const selectLastCreatedKeyCategoryId = createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => keyCategoriesState.lastCreatedKeyCategoryId
);

export const selectKeyCategoriesShowInitWaitingMessage = createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => keyCategoriesState.showInitWaitingMessage
);

export const selectTrashedKeyCategoryCount = createSelector(
    selectKeyCategoriesState,
    (keyCategoriesState) => keyCategoriesState.totalTrashed
);

export const selectAdminTrashedKeyCategoryCount = createSelector(
    selectKeyCategoriesState,
    (keyCategoriesState) => keyCategoriesState.totalAdminTrashed
);


export const selectKeyCategoryQueryResult = createSelector(
    selectKeyCategoriesState,
    keyCategoriesState => {
        const items: KeyCategoryModel[] = [];
        each(keyCategoriesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: KeyCategoryModel[] = httpExtension.sortArray(items, keyCategoriesState.lastQuery.sortField, keyCategoriesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, keyCategoriesState.totalCount, '', keyCategoriesState.totalTrashed);
    }
);
