import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { NestedLabelPipe } from './filter-by-nested.pipe';

@Component({
  selector: 'kt-all-linked-tables',
  templateUrl: './all-linked-tables.component.html',
  styleUrls: ['./all-linked-tables.component.scss'],
  providers: [NestedLabelPipe]
})
export class AllLinkedTablesComponent implements OnInit {
  // Filter fields
  @ViewChild('searchInput') searchInput: ElementRef;
  searchValue: string;
  selectionValue: string;

  types = [
    this.translateService.instant('LT.TYPES.TYPE1'),
    this.translateService.instant('LT.TYPES.TYPE2'),
    this.translateService.instant('LT.TYPES.TYPE3'),
    this.translateService.instant('LT.TYPES.TYPE4'),
    this.translateService.instant('LT.TYPES.TYPE5'),
  ]

  showData = [];

  tabHeaders: TabHeader[] = [
    {label: 'Terms of Engagement & Audit Trail', disabled: of(false)},
    {label: 'Measurements', disabled: of(false)},
    {label: 'Property', disabled: of(false)},
    {label: 'Comparables & Landmarks', disabled: of(false)},
    {label: 'Valuation', disabled: of(false)},
    {label: 'User', disabled: of(false)}
  ]
  selectedTab: number = 0;
  selectedTabChange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedTabChange$: Observable<number> = this.selectedTabChange.asObservable();
  onTabChange(index: number) {
    this.selectedTab = index;
  }

  linksAlt = [
    {
      type: this.translateService.instant('LT.TYPES.TYPE1'), id: 0, sub: [
          {label: this.translateService.instant('LT.VALUATION_STANDARD'), url: './valuation-standards'},
          {label: this.translateService.instant('LT.BASE_OF_VALUE'), url: './base-of-values'},
          {label: this.translateService.instant('LT.PURPOSE_OF_VALUATION'), url: './purpose-of-valuations'},
          {label: this.translateService.instant('LT.APPROACHES_TO_VALUE'), url: './approaches-to-values'},
          {label: this.translateService.instant('LT.METHODS_TO_VALUE'), url: './methods-to-values'},
          {label: this.translateService.instant('LT.PREMISES_OF_VALUE'), url: './premise-of-values'},
          {label: this.translateService.instant('LT.ASSUMPTIONS'), url: './default-assumptions'},
          {label: this.translateService.instant('LT.SPECIAL_ASSUMPTIONS'), url: './special-assumptions'},
          {label: this.translateService.instant('LT.DEPARTURES'), url: './departures'},
          {label: this.translateService.instant('LT.REPORT_DELIVERY_METHOD'), url: './report-delivery-methods'},
          // {label: this.translateService.instant('LT.INCLUDED_IN_VALUATION'), url: './included-in-valuations'},
          {label: this.translateService.instant('LT.SOURCE_EXTERNAL_REFERENCES'), url: './source-external-references'},
          {label: this.translateService.instant('LT.INTERACTION_TYPES'), url: './interaction-types'},
          {label: this.translateService.instant('LT.ADDITIONAL_COST_TITLES'), url: './additional-cost-titles'}
      ]
  },
  {
      type: this.translateService.instant('LT.TYPES.TYPE2'), id: 1, sub: [
          {label: this.translateService.instant('LT.MEASUREMENT_METHODOLOGIES'), url: './measurement-methodologies'},
          {label: this.translateService.instant('LT.PURPOSE_MEASUREMENT'), url: './purpose-measurements'},
          {label: this.translateService.instant('LT.MEASUREMENT_STANDARDS'), url: './standard-measurements'},
          {label: this.translateService.instant('LT.SYSTEM_MEASUREMENT'), url: './unit-measurements'},
          {label: this.translateService.instant('LT.UNIT_AREA_MEASUREMENT'), url: './unit-area-measurements'},
          {label: this.translateService.instant('LT.UNIT_LENGTH_MEASUREMENT'), url: './unit-length-measurements'},
          {label: this.translateService.instant('LT.COORDINATE_REFERENCE_SYSTEM'), url: './coordinate-reference-systems'},
      ]
  },
  {
      type: this.translateService.instant('LT.TYPES.TYPE3'), id: 2, sub: [
        // { label: 'Property Types', url: './top-property-types'},
        //   {label: 'Property Sub-Types', url: './asset-class-types'},
          { label: 'Sub-Type Categories', url: './sub-type-categories'},
          { label: 'Sub Categories', url: './sub-categories'},
          {label: this.translateService.instant('LT.BUILDING_FACILITIES'), url: './building-facilities'},
          // {label: this.translateService.instant('LT.RETAIL_FACILITIES'), url: './5/retail-facilities'},
          {label: this.translateService.instant('LT.HANDOVER_STANDARD'), url: './handover-standards'},
          {label: this.translateService.instant('LT.GRADE'), url: './grades'},
          {label: this.translateService.instant('LT.STATE_OF_REPAIR'), url: './state-repairs'},
          // {label: this.translateService.instant('LT.TYPE_OF_TITLE'), url: './type-of-titles'},
          {label: this.translateService.instant('LT.EXTERNAL_AREA'), url: './external-areas'},
          {label: this.translateService.instant('LT.FLOOR_TYPE'), url: './floor-types'},
          {label: this.translateService.instant('LT.WINDOW_TYPE'), url: './window-types'},
          {label: this.translateService.instant('LT.EXTERNAL_WALL'), url: './external-walls'},
          {label: this.translateService.instant('LT.FOUNDATION_TYPE'), url: './foundation-types'},
          {label: this.translateService.instant('LT.TENURE'), url: './tenures'},
          {label: this.translateService.instant('LT.LAND_TENURE'), url: './land-tenures'},
          {label: this.translateService.instant('LT.PLANING_STATUS'), url: './planing-statuses'},
          {label: this.translateService.instant('LT.QOS_DEGRADATION'), url: './qos-degradations'},
          {label: this.translateService.instant('LT.COMPARATIVE_LAND_USE'), url: './comparative-land-uses'},
          {label: this.translateService.instant('LT.SOL_USE_CLASSIFICATION'), url: './sol-use-classifications'},
          {label: this.translateService.instant('LT.BUILDING_USE_STATUS'), url: './building-use-statuses'},
          {label: this.translateService.instant('LT.PARCEL_CHARACTERISTIC'), url: './parcel-characteristics'},
          // {label: 'IPMS Component', url: './ipms-components'},
          {label: this.translateService.instant('LT.BUILTIN_FITTING'), url: './builtin-fittings'},
          {label: this.translateService.instant('LT.CEILING'), url: './ceilings'},
          {label: this.translateService.instant('LT.ROOF_STRUCTURES'), url: './roof-structures'},
          {label: this.translateService.instant('LT.STORAGE_TYPES'), url: './storage-types'},
          {label: this.translateService.instant('LT.WALLS_AND_PARTITION'), url: './walls-and-partitions'},
          {label: this.translateService.instant('LT.BATHROOM_FITTING'), url: './bathroom-fittings'},
          {label: this.translateService.instant('LT.FIREPLACES_CHIMNEY_BREAST'), url: './fireplaces-chimney-breasts'},
          {label: this.translateService.instant('LT.CHIMNEY_STACKS'), url: './chimney-stacks'},
          {label: this.translateService.instant('LT.ROOF_COVERINGS'), url: './roof-coverings'},
          {label: this.translateService.instant('LT.CONSERVATORY'), url: './conservatory-and-porches'},
          {label: this.translateService.instant('LT.MAIN_WALLS'), url: './main-walls'},
          {label: this.translateService.instant('LT.OTHER_JOINERIES'), url: './other-joineries'},
          {label: this.translateService.instant('LT.OUTSIDE_DOOR'), url: './outside-doors'},
          {label: this.translateService.instant('LT.RAINWATER_PIPE'), url: './rainwater-pipes'},
          {label: this.translateService.instant('LT.GARDENS'), url: './gardens'},
          {label: this.translateService.instant('LT.OUTDOOR_SPACE'), url: './outdoor-spaces'},
          {label: this.translateService.instant('LT.SHOP_FRONT_TYPE'), url: './shop-front-types'},
          {label: this.translateService.instant('LT.BUILDING_TYPE'), url: './building-types'},
          {label: this.translateService.instant('LT.PARKING_TYPE'), url: './parking-types'},
          {label: this.translateService.instant('LT.EXTERNAL_FACADES'), url: './external-facades'},
          // {label: this.translateService.instant('LT.FLOOR_NUMBERING_SCHEME'), url: './floor-numbering-schemes'}
      ]
  },
  {
      type: this.translateService.instant('LT.TYPES.TYPE4'), id: 3, sub: [
          {label: this.translateService.instant('LT.LANDMARK_TYPE'), url: './key-categories'},
          {label: this.translateService.instant('LT.SOURCE_CREDIBILITY'), url: './source-credibility'},
          {label: this.translateService.instant('LT.SOURCE_INFORMATION'), url: './source-information'},
          {label: this.translateService.instant('LT.SOURCE_TYPE'), url: './source-types'},
          {label: 'Rent Bases', url: './rent-bases'},
          {label: 'Rent Review Types', url: './rent-review-types'},
      ]
  },
  {
    type: this.translateService.instant('LT.TYPES.TYPE5'),
    id: 4,
    sub: [
      { label: this.translateService.instant('LT.RESIDENTIAL-ADJUSTMENTS'), url: './residential-adjustments' },
      { label: this.translateService.instant('LT.OFFICE-ADJUSTMENTS'), url: './office-adjustments'},
      {label: this.translateService.instant('LT.HOUSE-ADJUSTMENTS'), url: './house-adjustments'},
      {label: this.translateService.instant('LT.WAREHOUSE-ADJUSTMENTS'), url: './warehouse-adjustments'},
      { label: this.translateService.instant('LT.RETAIL-STORE-ADJUSTMENTS'), url: './retail-store-adjustments' },
      { label: this.translateService.instant('LT.RETAIL-BUILDING-ADJUSTMENTS'), url: './retail-building-adjustments'},
      {label: this.translateService.instant('LT.PARKING-ADJUSTMENTS'), url: './parking-adjustments'},
      {label: this.translateService.instant('LT.LAND-ADJUSTMENTS'), url: './land-adjustments'}
    ]
  },
  {
    type: this.translateService.instant('LT.TYPES.TYPE6'),
    id: 5,
    sub: [
      { label: this.translateService.instant('LT.ORGANISATIONS'), url: './organisations' },
      { label: this.translateService.instant('LT.CERTIFICATIONS'), url: './certifications' },
    ]
  }
  ]

  constructor(
    private translateService: TranslateService,
    private nestedLabelPipe: NestedLabelPipe
  ) { }

  ngOnInit(): void {
    this.showData = this.linksAlt;
  }

  onChange(event) {
    this.showData = this.nestedLabelPipe.transform(this.linksAlt, this.searchValue);
    this.selectedTabChange.next(this.showData.length > 0 ? this.showData[0].id : 0);
  }

  selectType() {
    this.showData = this.linksAlt.filter(link => link.id == parseInt(this.selectionValue));
    this.selectedTabChange.next(this.showData.length > 0 ? this.showData[0].id : 0);
  }

  resetFilter(): any {
    this.searchInput.nativeElement.value = '';
    this.selectionValue = null;
    this.searchValue = '';
    this.showData = this.linksAlt;
    this.selectedTabChange.next(0);
  }

}
