import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectClientById } from 'src/app/core/admin';
import { selectBaseOfValueById, selectPurposeOfValuationById } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ToeModel, ToeReportService } from 'src/app/core/toe';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { AssetClassModel } from 'src/app/core/asset_class';

interface TemplateData {
  client: string;
  purposeOfValuation: string;
  basisOfValue: {
    label: string,
    text: string
  };
  toePath: string;
}

@Component({
  selector: 'kt-toe-specific-section',
  templateUrl: './toe-specific-section.component.html',
  styleUrls: ['./toe-specific-section.component.scss', '../../section.styles.scss']
})
export class ToeSpecificSectionComponent implements OnInit {
  @Input()
  toe: ToeModel;
  @Input()
  tp: AssetClassModel;
  @Input()
  multimedia = false;

  data$: Observable<TemplateData>;
  constructor(
    private store$: Store<AppState>,
    private toeReportService: ToeReportService,
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.store$.select(selectClientById(this.toe.client_id)).pipe(filter(val => val !== undefined)),
      this.store$.select(selectPurposeOfValuationById(this.toe.purpose_of_valuation_id)).pipe(map(val => val ? val.name : undefined), filter(val => val !== undefined)),
      // this.store$.select(selectBaseOfValueById(this.toe.base_of_value_id)).pipe(map(val => val ? val.name : undefined), filter(val => val !== undefined)),
      this.toeReportService.getAllToeSignedReports(this.toe.id).pipe(map(res => res.data))
    ]).pipe(map(([client, purposeOfValuation,  signedReportResults]) => {
      const report = signedReportResults.find(el => el.checked);
      const baseOfValues = this.tp.all_valuations.map(val => ({id: val.base_of_value_id, name: val.base_of_value_name})).reduce(
        (acc, basis) => {
          if (acc.find(item => item.id == basis.id)) {
            return acc
          }
          return [...acc, basis]
        },
        [] as {id: number, name: string}[]
      )
      const data: TemplateData = {
        client: client.type == 'Company' ? client.name : (client.name + ' ' + client.last_name),
        purposeOfValuation,
        basisOfValue: {
          label: baseOfValues.length == 1 ? 'Basis' : 'Bases',
          text: baseOfValues.map(item => item.name).join(' / ')
        },
        toePath: report ? report.url : null
      };
      return data;
    }))
  }

  viewToe(event, toePath: string) {
    if(toePath) {
      if (this.typesUtilsService.getFileType(toePath) === 'docx') {
        window.open(toePath, '_blank');
      } else {
        this.dialog.open(ImageViewerDialogComponent, {
          data: {
              picture: toePath,
              type: this.typesUtilsService.getFileType(toePath)
          }
        });
      }
    }
  }
}
