import {SourceExternalReference} from '../_models/source-external-reference.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SourceExternalReferencesState} from '../_reducers/source-external-reference.reducers';
import * as fromSourceExternalReference from '../_reducers/source-external-reference.reducers';
import {each} from 'lodash';

export const selectSourceExternalReferencesState = createFeatureSelector<SourceExternalReferencesState>('sourceExternalReferences');

export const selectSourceExternalReferenceById = (sourceExternalReferenceId: number) => createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => sourceExternalReferencesState.entities[sourceExternalReferenceId]
);

export const selectAllSourceExternalReferences = createSelector(
    selectSourceExternalReferencesState,
    fromSourceExternalReference.selectAll
);

export const selectAllSourceExternalReferencesIds = createSelector(
    selectSourceExternalReferencesState,
    fromSourceExternalReference.selectIds
);

export const allSourceExternalReferencesLoaded = createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => sourceExternalReferencesState.isAllSourceExternalReferencesLoaded
);


export const selectSourceExternalReferencesPageLoading = createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => sourceExternalReferencesState.listLoading
);

export const selectSourceExternalReferencesActionLoading = createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => sourceExternalReferencesState.actionLoading
);

export const selectLastCreatedSourceExternalReferenceId = createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => sourceExternalReferencesState.lastCreatedSourceExternalReferenceId
);

export const selectSourceExternalReferencesShowInitWaitingMessage = createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => sourceExternalReferencesState.showInitWaitingMessage
);

export const selectTrashedSourceExternalReferenceCount = createSelector(
    selectSourceExternalReferencesState,
    (sourceExternalReferencesState) => sourceExternalReferencesState.totalTrashed
);

export const selectAdminTrashedSourceExternalReferenceCount = createSelector(
    selectSourceExternalReferencesState,
    (sourceExternalReferencesState) => sourceExternalReferencesState.totalAdminTrashed
);


export const selectSourceExternalReferenceQueryResult = createSelector(
    selectSourceExternalReferencesState,
    sourceExternalReferencesState => {
        const items: SourceExternalReference[] = [];
        each(sourceExternalReferencesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SourceExternalReference[] = httpExtension.sortArray(items, sourceExternalReferencesState.lastQuery.sortField, sourceExternalReferencesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, sourceExternalReferencesState.totalCount, '', sourceExternalReferencesState.totalTrashed);
    }
);
