// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {FoundationType} from '../_models/foundation-type.model';

export enum FoundationTypeActionTypes {

    AllFoundationTypesRequested = '[FoundationTypes Home Page] All FoundationTypes Requested',
    AllFoundationTypesLoaded = '[FoundationTypes API] All FoundationTypes Loaded',

    FoundationTypeOnServerCreated = '[Edit FoundationType Dialog] FoundationType On Server Created',
    FoundationTypeCreated = '[Edit FoundationTypes Dialog] FoundationTypes Created',
    FoundationTypeUpdated = '[Edit FoundationType Dialog] FoundationType Updated',
    FoundationTypeDeleted = '[FoundationTypes List Page] FoundationType Deleted',

    FoundationTypesPageRequested = '[FoundationTypes List Page] FoundationTypes Page Requested',
    FoundationTypesPageLoaded = '[FoundationTypes API] FoundationTypes Page Loaded',
    FoundationTypesPageCancelled = '[FoundationTypes API] FoundationTypes Page Cancelled',

    FoundationTypesPageToggleLoading = '[FoundationTypes page] FoundationTypes Page Toggle Loading',
    FoundationTypesActionToggleLoading = '[FoundationTypes] FoundationTypes Action Toggle Loading',

    // trash system

    FoundationTypeOnServerRestored = '[FoundationType Trash] FoundationType On Server Restored',
    FoundationTypeRestored = '[FoundationType Trash] FoundationType Restored',

    FoundationTypeOnServerAdminRestored = '[FoundationType Admin Trash] FoundationType On Server Restored',
    FoundationTypeAdminRestored = '[FoundationType Admin Trash] FoundationType Restored',

    FoundationTypeDeletedFromTrash = '[FoundationType Trash] FoundationType deleted',
    FoundationTypeDeletedFromAdminTrash = '[FoundationType Admin Trash] FoundationType deleted',

    FoundationTypeTrash = 'FoundationType Trashed',
    FoundationTypeTrashFlushed = 'FoundationType Trash Flushed',
}

export class FoundationTypeOnServerCreated implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeOnServerCreated;

    constructor(public payload: { foundationType: FoundationType}) {
    }
}

export class FoundationTypeCreated implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeCreated;

    constructor(public payload: { foundationType: FoundationType }) {
    }
}

export class FoundationTypeUpdated implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeUpdated;

    constructor(public payload: {
        partialFoundationType: Update<FoundationType>,
        foundationType: FoundationType
    }) {
    }
}

export class FoundationTypeDeleted implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class FoundationTypesPageRequested implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class FoundationTypesPageLoaded implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypesPageLoaded;

    constructor(public payload: { foundationTypes: FoundationType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class FoundationTypesPageCancelled implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypesPageCancelled;
}

export class AllFoundationTypesRequested implements Action {
    readonly type = FoundationTypeActionTypes.AllFoundationTypesRequested;
}

export class AllFoundationTypesLoaded implements Action {
    readonly type = FoundationTypeActionTypes.AllFoundationTypesLoaded;

    constructor(public payload: { foundationTypes: FoundationType[] }) {
    }
}

export class FoundationTypesPageToggleLoading implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class FoundationTypesActionToggleLoading implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class FoundationTypeDeletedFromAdminTrash implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class FoundationTypeDeletedFromTrash implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class FoundationTypeOnServerRestored implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FoundationTypeRestored implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeRestored;

    constructor(public payload: { item: FoundationType }) {
    }
}

export class FoundationTypeOnServerAdminRestored implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FoundationTypeAdminRestored implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeAdminRestored;

    constructor(public payload: { item: FoundationType }) {
    }
}

export class FoundationTypeTrashFlushed implements Action {
    readonly type = FoundationTypeActionTypes.FoundationTypeTrashFlushed;

    constructor() {
    }
}

export type FoundationTypeActions = FoundationTypeCreated
    | FoundationTypeUpdated
    | FoundationTypeDeleted
    | FoundationTypesPageRequested
    | FoundationTypesPageLoaded
    | FoundationTypesPageCancelled
    | AllFoundationTypesLoaded
    | AllFoundationTypesRequested
    | FoundationTypeOnServerCreated
    | FoundationTypesPageToggleLoading
    | FoundationTypesActionToggleLoading
    | FoundationTypeDeletedFromAdminTrash
    | FoundationTypeDeletedFromTrash
    | FoundationTypeOnServerRestored
    | FoundationTypeRestored
    | FoundationTypeOnServerAdminRestored
    | FoundationTypeAdminRestored
    | FoundationTypeTrashFlushed;
