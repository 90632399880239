import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { selectAllAgencies } from 'src/app/core/admin';
import { AssignmentModel } from 'src/app/core/assignment';
import { AllAssignmentsRequested } from 'src/app/core/assignment/_actions/assignment.actions';
import { selectAssignmentById, selectAssignmentsBySearch } from 'src/app/core/assignment/_selectors/assignment.selectors';
import { AllUsersOfRoleRequested, selectValuersInStore } from 'src/app/core/auth';
import { AppState } from 'src/app/core/reducers';
import { TermsOfEngagementService } from 'src/app/core/terms-of-engagement/_services/terms-of-engagement.service';

@Component({
  selector: 'kt-move-toe-dialog',
  templateUrl: './move-toe-dialog.component.html',
  styleUrls: ['./move-toe-dialog.component.scss'],
  providers: [TermsOfEngagementService]
})
export class MoveToeDialogComponent implements OnInit, OnDestroy {
  formGroup = this.formBuilder.group({
    assignment_id: [null, [Validators.required]],
    name: [null],
    date_of_creation: [null],
    project_manager_id: [null],
    agency_id: [null],
    point_of_contact: [null],
    description: [null]
  })

  searchControl = new FormControl('');

  assignments$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    switchMap(value => {
      return this.store.select(selectAssignmentsBySearch(value)).pipe(
        map(assignments => assignments.map(assignment => ({
          value: assignment.id, 
          label: `${assignment.name} (${assignment.agency_name} - ${assignment.toe_cnt})`
        })))
      )
    })
  ) 
  users$ = this.store.select(selectValuersInStore).pipe(
    map(users => 
      users.items.map(user => ({value: user.id, label: user.full_name}))
    )
  )
  agencies$ = this.store.select(selectAllAgencies).pipe(
    map(agencies =>
      agencies.map(agency => ({value: agency.id, label: agency.name}))
    )
  )

  showAssignmentForm$ = this.formGroup.controls.assignment_id.valueChanges.pipe(
    map(value => value == -1)
  )

  private _onDestroy$: Subject<void> = new Subject()

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MoveToeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {toe: {id: number}},
    private store: Store<AppState>,
    private typeUtilsService: TypesUtilsService,
    private service: TermsOfEngagementService
  ) { 
    this.store.dispatch(new AllAssignmentsRequested())
    this.store.dispatch(new AllUsersOfRoleRequested({roleId: 2}));
  }

  ngOnInit(): void {
    this.showAssignmentForm$.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(value => {
      if (value) {
        this.formGroup.controls.name.setValidators([Validators.required])
        this.formGroup.controls.date_of_creation.setValidators([Validators.required])
        this.formGroup.controls.project_manager_id.setValidators([Validators.required])
        this.formGroup.controls.agency_id.setValidators([Validators.required])
      } else {
        this.formGroup.controls.name.setValidators(null)
        this.formGroup.controls.date_of_creation.setValidators(null)
        this.formGroup.controls.project_manager_id.setValidators(null)
        this.formGroup.controls.agency_id.setValidators(null)
      }
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next() 
    this._onDestroy$.complete()
  }

  onSubmit() {
    const controls = this.formGroup.controls
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched())
      return;
    }

    if (this.formGroup.controls.assignment_id.value != -1) {
      const _assignment = new AssignmentModel()
      _assignment.clear();
      _assignment.id = this.formGroup.controls.assignment_id.value;
      this.service.moveToe(this.data.toe.id, {assignment: _assignment}).subscribe({
        next: (res) => this.dialogRef.close('success'),
        error: (err) => console.error('Error:', err)
      })
      return;
    } else {
      const _assignment = new AssignmentModel()
      _assignment.clear()
      const _date = new Date(controls.date_of_creation.value);
      if (_date) {
        _assignment.insertion_date = this.typeUtilsService.dateFormat(_date);
      }
      _assignment.name = controls.name.value;
      _assignment.valuer_id = controls.project_manager_id.value;
      _assignment.agency_id = controls.agency_id.value;
      _assignment.pointOfContact = controls.point_of_contact.value;
      _assignment.description = controls.description.value;

      this.service.moveToe(this.data.toe.id, {assignment: _assignment}).subscribe({
        next: (res) => this.dialogRef.close('success'),
        error: (err) => console.error('Error:', err)
      })
    }
  }

  onClose() {
    this.dialogRef.close()
  }
}
