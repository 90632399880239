// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {RoofCoveringService} from '../_services/roof-covering.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allRoofCoveringsLoaded} from '../_selectors/roof-covering.selectors';
// Actions
import {
    AllRoofCoveringsLoaded,
    AllRoofCoveringsRequested,
    RoofCoveringActionTypes,
    RoofCoveringsPageRequested,
    RoofCoveringsPageLoaded,
    RoofCoveringUpdated,
    RoofCoveringsPageToggleLoading,
    RoofCoveringDeleted,
    RoofCoveringOnServerCreated,
    RoofCoveringCreated,
    RoofCoveringActionToggleLoading, RoofCoveringTrashFlushed, RoofCoveringOnServerRestored, RoofCoveringRestored,
    RoofCoveringOnServerAdminRestored, RoofCoveringAdminRestored, RoofCoveringDeletedFromTrash, RoofCoveringDeletedFromAdminTrash
} from '../_actions/roof-covering.actions';

@Injectable()
export class RoofCoveringEffects {
    showPageLoadingDispatcher = new RoofCoveringsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RoofCoveringActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RoofCoveringActionToggleLoading({isLoading: false});

    @Effect()
    loadAllRoofCovering$ = this.actions$
        .pipe(
            ofType<AllRoofCoveringsRequested>(RoofCoveringActionTypes.AllRoofCoveringsRequested),
            withLatestFrom(this.store.pipe(select(allRoofCoveringsLoaded))),
            filter(([action, isAllRoofCoveringLoaded]) => !isAllRoofCoveringLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllRoofCoveringsLoaded({items: res.data});
            })
        );

    @Effect()
    loadRoofCoveringPage$ = this.actions$
        .pipe(
            ofType<RoofCoveringsPageRequested>(RoofCoveringActionTypes.RoofCoveringsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new RoofCoveringsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringUpdated>(RoofCoveringActionTypes.RoofCoveringUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringOnServerCreated>(RoofCoveringActionTypes.RoofCoveringOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new RoofCoveringCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushRoofCoveringTrash$ = this.actions$
        .pipe(
            ofType<RoofCoveringTrashFlushed>(RoofCoveringActionTypes.RoofCoveringTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringOnServerRestored>(RoofCoveringActionTypes.RoofCoveringOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RoofCoveringRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringOnServerAdminRestored>(RoofCoveringActionTypes.RoofCoveringOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RoofCoveringAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringDeleted>(RoofCoveringActionTypes.RoofCoveringDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringDeletedFromTrash>(RoofCoveringActionTypes.RoofCoveringDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashRoofCovering$ = this.actions$
        .pipe(
            ofType<RoofCoveringDeletedFromAdminTrash>(RoofCoveringActionTypes.RoofCoveringDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: RoofCoveringService, private store: Store<AppState>) {
    }
}
