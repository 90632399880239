import { Component, Input, OnInit } from '@angular/core';
import { AssetClassModel } from 'src/app/core/asset_class';
import { ToeModel } from 'src/app/core/toe';

@Component({
  selector: 'kt-assumptions-tab',
  templateUrl: './assumptions-tab.component.html',
  styleUrls: ['./assumptions-tab.component.scss']
})
export class AssumptionsTabComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() targetProperty: AssetClassModel;
  @Input() toe: ToeModel;


  constructor() { }

  ngOnInit(): void {
  }

}
