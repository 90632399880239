import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { Action, createFeatureSelector, createSelector, select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map, mergeMap, skip, take, tap } from "rxjs/operators";
import { AppState } from "../reducers";
import { MadAuthService } from "./mad-auth.service";
import * as _ from 'lodash'
import { MadPermission } from "./mad-permission";

/** Models */
export interface ApiFilter {
  page: number
  page_size: number
}
export interface MadRoleResourceResponse {
  data: MadRole
}
export interface MadRoleCollectionResponse {
  data: MadRole[],
  pagination: {
    total: number
  }
}
export interface MadRole {
  id?: number,
  name: string,
  permissions: Array<MadPermission>
}

/** Actions */
export enum MadRolesActionTypes {
  RolesRequested = '[Roles Home Page] Mad Roles Requested',
  RolesLoaded = '[Roles Effect] Mad Roles Loaded',

  CreateRole = '[Role Edit Dialog] Create Mad Role',
  RoleCreated = '[Roles Effect] Mad Role Created',

  UpdateRole = '[Role Edit Dialog] Update Mad Role',
  RoleUpdated = '[Roles Effect] Mad Role Updated',

  RolesActionToggleLoading = '[Roles Effect] Mad Roles Action Toggle Loading',

  DeleteRole = '[Role Home Page] Delete Mad Role'
}

export class MadRolesRequested implements Action {
  readonly type = MadRolesActionTypes.RolesRequested
  constructor(public payload: { query: ApiFilter }) { }
}

export class MadRolesLoaded implements Action {
  readonly type = MadRolesActionTypes.RolesLoaded
  constructor(public payload: { items: MadRole[], total: number }) { }
}

export class CreateMadRole implements Action {
  readonly type = MadRolesActionTypes.CreateRole
  constructor(public payload: { item: MadRole }) { }
}

export class MadRoleCreated implements Action {
  readonly type = MadRolesActionTypes.RoleCreated
  constructor(public payload: { item: MadRole }) { }
}

export class UpdateMadRole implements Action {
  readonly type = MadRolesActionTypes.UpdateRole
  constructor(public payload: { item: MadRole }) { }
}

export class MadRoleUpdated implements Action {
  readonly type = MadRolesActionTypes.RoleUpdated
  constructor(public payload: { item: MadRole }) { }
}

export class MadRolesActionToggle implements Action {
  readonly type = MadRolesActionTypes.RolesActionToggleLoading
  constructor(public payload: { loading: boolean }) { }
}

export class DeleteMadRole implements Action {
  readonly type = MadRolesActionTypes.DeleteRole
  constructor(public payload: { id: number }) { }
}

export type MadRoleActions
  = MadRolesRequested
  | MadRolesLoaded

  | CreateMadRole
  | MadRoleCreated

  | UpdateMadRole
  | MadRoleUpdated

  | MadRolesActionToggle

  | DeleteMadRole


/** Reducer */
export interface MadRolesState extends EntityState<MadRole> {
  actionLoading: boolean
  listLoading: boolean,
  total: number,
  resultItems: MadRole[],
  lastQuery: ApiFilter
}
export const adapter = createEntityAdapter<MadRole>()
export const initialMadRolesState: MadRolesState = adapter.getInitialState({
  actionLoading: false,
  listLoading: false,
  total: 0,
  resultItems: [],
  lastQuery: null
})
export function madRolesReducer(
  state = initialMadRolesState,
  action: MadRoleActions
): MadRolesState {
  switch (action.type) {
    case MadRolesActionTypes.RolesActionToggleLoading: {
      return {
        ...state,
        actionLoading: action.payload.loading
      }
    }
    case MadRolesActionTypes.RolesRequested: {
      return {
        ...state,
        listLoading: true,
        lastQuery: action.payload.query
      }
    }
    case MadRolesActionTypes.RolesLoaded: {
      return adapter.addMany(action.payload.items, {
        ...state,
        listLoading: false,
        total: action.payload.total,
        resultItems: action.payload.items
      })
    }
    case MadRolesActionTypes.RoleCreated: {
      return adapter.addOne(action.payload.item, {
        ...state
      })
    }
    case MadRolesActionTypes.RoleUpdated: {
      const update: Update<MadRole> = {
        id: action.payload.item.id,
        changes: {
          ...action.payload.item
        }
      }
      return adapter.updateOne(update, { ...state })
    }
    case MadRolesActionTypes.DeleteRole: {
      return adapter.removeOne(action.payload.id, { ...state })
    }
    default:
      return state
  }
}
const { selectAll } = adapter.getSelectors()

/** Effect */
@Injectable()
export class MadRoleEffects {
  showActionLoadingDispatcher = new MadRolesActionToggle({ loading: true })
  hideActionLoadingDispatcher = new MadRolesActionToggle({ loading: false })

  constructor(
    private _actions$: Actions,
    private _service: MadAuthService,
    private _store$: Store<AppState>
  ) { }

  @Effect()
  loadRoles$ = this._actions$
    .pipe(
      ofType<MadRolesRequested>(MadRolesActionTypes.RolesRequested),
      mergeMap(({ payload }) => {
        this._store$.dispatch(this.showActionLoadingDispatcher)
        return this._service.getRoles(payload.query).pipe(
          tap(res => {
            this._store$.dispatch(new MadRolesLoaded(res))
          })
        )
      }),
      map(() => {
        return this.hideActionLoadingDispatcher
      })
    )

  @Effect()
  createRole$ = this._actions$
    .pipe(
      ofType<CreateMadRole>(MadRolesActionTypes.CreateRole),
      mergeMap(({ payload }) => {
        this._store$.dispatch(this.showActionLoadingDispatcher)
        return this._service.createRole(payload.item).pipe(
          tap(res => {
            this._store$.dispatch(new MadRoleCreated({ item: res }))
          })
        )
      }),
      map(() => {
        return this.hideActionLoadingDispatcher
      })
    )

  @Effect()
  updateRole$ = this._actions$
    .pipe(
      ofType<UpdateMadRole>(MadRolesActionTypes.UpdateRole),
      mergeMap(({ payload }) => {
        this._store$.dispatch(this.showActionLoadingDispatcher)
        return this._service.updateRole(payload.item).pipe(
          tap(res => {
            this._store$.dispatch(new MadRoleUpdated({ item: res }))
          })
        )
      }),
      map(() => {
        return this.hideActionLoadingDispatcher
      })
    )

  @Effect()
  deleteRole$ = this._actions$
    .pipe(
      ofType<DeleteMadRole>(MadRolesActionTypes.DeleteRole),
      mergeMap(({ payload }) => {
        this._store$.dispatch(this.showActionLoadingDispatcher)
        return combineLatest([
          this._store$.pipe(take(1), select(selectMadRolesLastQuery)),
          this._service.deleteRole(payload.id)
        ]).pipe(
            tap(([_res, lastQuery]) => {
              if (lastQuery) {
                this._store$.dispatch(new MadRolesRequested({
                  query: lastQuery
                }))
              }
            })
          )
      }),
      map(() => {
        return this.hideActionLoadingDispatcher
      })
    )
}

/** Selectors */
export const selectMadRolesSate = createFeatureSelector<MadRolesState>('mad-roles')
export const selectRoles = createSelector(
  selectMadRolesSate,
  selectAll
)
export const selectActionLoading = createSelector(
  selectMadRolesSate,
  state => state.actionLoading
)
const selectListLoading = createSelector(
  selectMadRolesSate,
  state => state.listLoading
)
const selectMadRolesLastQuery = createSelector(
  selectMadRolesSate,
  state => state.lastQuery
)
export const selectQueryResult = createSelector(
  selectMadRolesSate,
  state => {
    const items: MadRole[] = []
    _.each(state.entities, element => {
      items.push(element)
    })

    return {
      items: state.resultItems,
      total: state.total
    }
  }
)

/** DataSource */
export class MadRolesDataSource extends DataSource<MadRole> {
  private _entitySubject = new BehaviorSubject<MadRole[]>([])
  private _subscriptions: Subscription[] = []
  private _paginatorTotalSubject = new BehaviorSubject<number>(0)

  loading$: Observable<boolean>
  paginatorTotal$: Observable<number>
  hasItems$: Observable<boolean>


  constructor(private _store$: Store<AppState>) {
    super();

    this.loading$ = this._store$.pipe(
      select(selectListLoading)
    )
    this.paginatorTotal$ = this._paginatorTotalSubject.asObservable()
    this.hasItems$ = this._paginatorTotalSubject.pipe(
      skip(1),
      map(total => total == 0)
    )

    const querySub = this._store$.pipe(
      select(selectQueryResult)
    ).subscribe(response => {
      this._paginatorTotalSubject.next(response.total)
      this._entitySubject.next(response.items)
    })
    this._subscriptions.push(querySub)
  }

  connect(collectionViewer: CollectionViewer): Observable<readonly MadRole[]> {
    return this._entitySubject.asObservable()
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this._entitySubject.complete()
    this._subscriptions.forEach(s => s.unsubscribe())
  }
}