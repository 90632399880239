<div class="card">
    <div class="card-header info-header">
        <div class="card-title" style="margin-bottom: 0;">
            <div class="card-label" style="display: flex; flex-direction: row;">
                <span class="mr-2">Special Assumptions</span>
                <mat-icon 
                    style="cursor: pointer; display: flex; align-items: center;" 
                    container="body" 
                    [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.SPECIAL_ASSUMPTIONS.DESCRIPTION'|translate"
                    [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.SPECIAL_ASSUMPTIONS.TITLE'|translate">
                    help
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="card-body info-body">
        <table class="table info-table" *ngIf="data$|async as data">
            <thead>
                <th>Source</th>
                <th>Input</th>
            </thead>
            <tbody>
                <tr *ngFor="let assumption of data.assumptions; let i = index" 
                    [ngClass]="{
                        'white': i%2 == 0, 
                        'whiter-red': i%2 != 0,
                        'info-border-bottom': i == data.lastGeneral - 1
                    }">
                        <td>{{assumption.source}}</td>
                        <td>{{assumption.input}}</td>
                </tr>
            </tbody>
            <tfoot *ngIf="data.assumptions.length === 0">
                <tr>
                    <td colspan="2" style="text-align: center;">
                        <strong>No Special Assumptions</strong>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>