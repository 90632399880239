import {BaseModel} from '../../_base/crud';
import {User} from '../../auth';
import { MadRole } from 'src/app/core/mad-auth/mad-roles.store'

export class SupportingValuerModel extends BaseModel {

    id: number;
    terms_of_engagement_id: number;
    worker_id: number;
    role_id: number;
    previous_involvment: string;
    lead_valuer: number;
    selected_document: any[];
    selected_certificate: any[];

    assigned_default_tasks: number[];

    position: string;
    daily_rate: number;
    days: number;
    total_price: number;

    created_at: Date;
    deleted_at: Date;
    user_deleted: Date;
    updated_at: Date;

    worker: User;
    role: MadRole;

    clear() {
        this.id = undefined;

        this.terms_of_engagement_id = undefined;
        this.worker_id = undefined;
        this.role_id = undefined;

        this.previous_involvment = '';
        this.lead_valuer = 0;
        this.selected_document = [];
        this.selected_certificate = [];

        this.assigned_default_tasks = [];

        this.position = '';
        this.daily_rate = 0;
        this.days = 0;
        this.total_price = 0;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
        this.user_deleted = null;
        this.worker = null;
        this.role = null;
    }
}