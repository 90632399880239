<div class="d-flex flex-column mb-4" *ngIf="loading$|async">
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<span *ngIf="title">{{title}}</span>
		</div>
		<div class="card-toolbar">
			<div class="d-flex justify-content-end">
				<!-- NOTE: disabled reqeust by Josh -->
				<button mat-raised-button color="secondary" type="button" class="btn btn-secondary mr-4" (click)="recordPvdInvoice(null)" *ngIf="showAddInvoiceBtn$|async">
					Add Invoice
				</button>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="mat-table-wrapper">
			<mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="task_name">
					<mat-header-cell *matHeaderCellDef>Invoice Number</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.taskName}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="status_date">
					<mat-header-cell *matHeaderCellDef>Status Date</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<ng-container *ngTemplateOutlet="taskCompStat1; context: {stat: item.completion, date: item.status_date  | date: 'dd MMM yyy'}"></ng-container>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="amount">
					<mat-header-cell *matHeaderCellDef>
						{{'Amount (' + currency + ') (incl. taxes)'}}
					</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.amount | number: '1.2-2'}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<button mat-icon-button color="primary" (click)="downloadReport(item)">
							<mat-icon>get_app</mat-icon>
						</button>
						<span *ngIf="item.invoice.pvds && item.invoice.pvds.length > 0"
							[matTooltip]="item.taskActions.canBeEdited ? 'Edit' : 'Cannot edit'">
							<button mat-icon-button color="primary" matTooltip="Edit Information of the Task"
								(click)="recordPvdInvoice(item)" type="button" *ngIf="item.taskActions.canBeEdited">
								<mat-icon>create</mat-icon>
							</button>
						</span>
						<span *ngIf="item.invoice.pvds && item.invoice.pvds.length > 0"
							[matTooltip]="item.taskActions.canBeUserDeleted ? 'Delete' : 'Cannot delete'">
							<button mat-icon-button color="warn" matTooltip="Delete" (click)="deletePvdInvoice(item)"
								type="button">
								<!-- type="button" [disabled]="!item.taskActions.canBeUserDeleted"> -->
								<mat-icon>delete</mat-icon>
							</button>
						</span>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="columns"
					[style.backgroundColor]="'#fcdea2'">
				</mat-header-row>
				<mat-row *matRowDef="let row; columns: columns"></mat-row>
			</mat-table>
		</div>
	</div>
</div>

<ng-template #taskCompStat let-stat="stat" let-difference_amount="difference_amount">
	<div class="d-flex justify-content-center align-items-center">
		<div *ngIf="stat.status == status.Upcoming">
			<span style="color: #000000; font-weight: bold; margin-left: 10px;">{{stat.invoice_status}}</span>
		</div>
		<div *ngIf="stat.status == status.Voided">
			<span style="color: #884FFC; font-weight: bold; margin-left: 10px;">{{stat.invoice_status}}</span>
		</div>
		<div *ngIf="stat.status == status.Overdue">
			<span style="color: #F44D5E; font-weight: bold; margin-left: 10px;">{{'Due'}}</span>
		</div>
		<div *ngIf="stat.status == status.Completed">
			<span style="color: #16C4BB; font-weight: bold; margin-left: 10px;">{{stat.invoice_status}}</span>
		</div>
		<div *ngIf="stat.status == status.Overpaid">
			<span style="color: #3797ff; font-weight: bold; margin-left: 10px;">{{stat.invoice_status + ' (' + (difference_amount | number: '1.2-2') + ' ' + currency + ' owed to client)'}}</span>
		</div>
		<div *ngIf="stat.status == status.PartiallyPaid">
			<span style="color: #FDA701; font-weight: bold; margin-left: 10px;">{{stat.invoice_status + ' (' + (difference_amount | number: '1.2-2') + ' ' + currency + ' due by client)'}}</span>
		</div>
	</div>
</ng-template>
<!-- TODO: refactor -->
<ng-template #taskCompStat1 let-stat="stat" let-date="date">
	<div class="d-flex justify-content-center align-items-center">
		<div *ngIf="stat.status == status.Upcoming">
			<span style="color: #000000; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Voided">
			<span style="color: #884FFC; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Overdue">
			<span style="color: #F44D5E; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Completed">
			<span style="color: #16C4BB; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Overpaid">
			<span style="color: #3797ff; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.PartiallyPaid">
			<span style="color: #FDA701; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
	</div>
</ng-template>