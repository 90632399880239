import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AssetClassModel } from "../../asset_class";
import { SRERAssumptionType, ValuationSRERModel } from "../_models/valuation-srer.model";

export enum ActionTypes {
    LoadData = '[SRER table] Load data from server',
    DataLoaded = '[SRER table] Data loaded',   

    UpdateData = '[SRER table] Update data',
    DataUpdated = '[SRER table] Data update',
    
    RestoreData = '[SRER table] Restore data',
    RemoveData = '[SRER table] Remove data'
}

// Load data from server
export class LoadData implements Action {
    readonly type = ActionTypes.LoadData;
    constructor(public payload: {data: ValuationSRERModel[]}){}
}
export class DataLoaded implements Action {
    readonly type = ActionTypes.DataLoaded;
    constructor(public payload: {data: ValuationSRERModel[]}){}
}

export class UpdateData implements Action {
    readonly type = ActionTypes.UpdateData;
    constructor(public payload: {data: ValuationSRERModel}){}
}
export class DataUpdated implements Action {
    readonly type = ActionTypes.DataUpdated;
    constructor(public payload: {data: Update<ValuationSRERModel>}){}
}

export class RestoreData implements Action {
    readonly type = ActionTypes.RestoreData;
    constructor(public payload: {data: ValuationSRERModel, type: SRERAssumptionType, tp: AssetClassModel}) {}
}

export class RemoveData implements Action {
    readonly type = ActionTypes.RemoveData;
    constructor(public payload: {data: ValuationSRERModel, type: SRERAssumptionType, tp: AssetClassModel, justification: string}) {}
}

export type Actions = LoadData | DataLoaded | UpdateData | DataUpdated | RestoreData;