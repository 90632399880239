import { Action } from "@ngrx/store";
import { ValuationAdjustmentTabDataModel } from "../_models/valuation-adjustment-tab-data.model";

export enum ValuationProcessValuationActionTypes {
    UpdateWeightingValue = '[Valuations table] Update weighting value',

    AddComparable = '[Valuation Process Effect] Add comparable to valuation process valuation computation',
    RemoveComparable = '[Valuation Process Effect] Remove comparable from valuation process valuation computation',
    AddMultipleComparables = '[Valuation Comparable Effect] Add multiple comparables to valuation process valuation computation',

    UpdateJustification = '[Valuations table] Update valuation computation justification',
    UpdateCapitalAllowance = '[Valuations table] Update valuation computation capital allowance',
    UpdateCapitalAllowanceJustification = '[Valuations table] Update valuation computation capital allowance justification',

    UnimportValuation = '[Valuation Process Effect] Unimport valuation value of imported valuation',

    ResetState = '[Valuation Process Page] reset state of valuation process valuation',

    LoadState = '[Valuation Process Effect] load state of valuation process valuation'
}

export class ValuationProcessValuationUpdateWeightingValue implements Action {
    readonly type = ValuationProcessValuationActionTypes.UpdateWeightingValue
    constructor(public payload: {refNum: string, value: number}){}
}

export class ValuationProcessValuationAddComparable implements Action {
    readonly type = ValuationProcessValuationActionTypes.AddComparable
    constructor(public payload: {refNum: string}) {}
}

export class ValuationProcessValuationAddMultipleComparables implements Action {
    readonly type = ValuationProcessValuationActionTypes.AddMultipleComparables
    constructor(public payload: {refNums: string[], adjustmentTabData: ValuationAdjustmentTabDataModel}) {}
}

export class ValuationProcessValuationRemoveComparable implements Action {
    readonly type = ValuationProcessValuationActionTypes.RemoveComparable
    constructor(public payload: {refNum: string}) {}
}

export class ValuationProcessValuationUpdateJustification implements Action {
    readonly type = ValuationProcessValuationActionTypes.UpdateJustification
    constructor(public payload: {value: string}) {}
}
export class ValuationProcessValuationUpdateCapitalAllowance implements Action {
    readonly type = ValuationProcessValuationActionTypes.UpdateCapitalAllowance
    constructor(public payload: {value: number}) {}
}
export class ValuationProcessValuationUpdateCapitalAllowanceJustification implements Action {
    readonly type = ValuationProcessValuationActionTypes.UpdateCapitalAllowanceJustification
    constructor(public payload: {value: string}) {}
}
export class ValuationProcessValuationReset implements Action {
    readonly type = ValuationProcessValuationActionTypes.ResetState
}

export class ValuationProcessUnimportValuationValue implements Action {
    readonly type = ValuationProcessValuationActionTypes.UnimportValuation
}

export class ValuationProcessLoadValuation implements Action {
    readonly type = ValuationProcessValuationActionTypes.LoadState
    constructor(public payload: {state: any}) {}
}

export type ValuationProcessValuationActions 
    = ValuationProcessValuationUpdateWeightingValue
    | ValuationProcessValuationAddComparable
    | ValuationProcessValuationAddMultipleComparables
    | ValuationProcessValuationRemoveComparable
    | ValuationProcessValuationUpdateJustification
    | ValuationProcessValuationUpdateCapitalAllowance
    | ValuationProcessValuationUpdateCapitalAllowanceJustification
    | ValuationProcessValuationReset
    | ValuationProcessLoadValuation
    | ValuationProcessUnimportValuationValue