import {PremiseOfValue} from '../_models/premise-of-value.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PremiseOfValuesState} from '../_reducers/premise-of-value.reducers';
import * as fromPremiseOfValue from '../_reducers/premise-of-value.reducers';
import {each} from 'lodash';

export const selectPremiseOfValuesState = createFeatureSelector<PremiseOfValuesState>('premiseOfValues');

export const selectPremiseOfValueById = (premiseOfValueId: number) => createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => premiseOfValuesState.entities[premiseOfValueId]
);

export const selectAllPremiseOfValues = createSelector(
    selectPremiseOfValuesState,
    fromPremiseOfValue.selectAll
);

export const selectAllPremiseOfValuesIds = createSelector(
    selectPremiseOfValuesState,
    fromPremiseOfValue.selectIds
);

export const allPremiseOfValuesLoaded = createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => premiseOfValuesState.isAllPremiseOfValuesLoaded
);


export const selectPremiseOfValuesPageLoading = createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => premiseOfValuesState.listLoading
);

export const selectPremiseOfValuesActionLoading = createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => premiseOfValuesState.actionLoading
);

export const selectLastCreatedPremiseOfValueId = createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => premiseOfValuesState.lastCreatedPremiseOfValueId
);

export const selectPremiseOfValuesShowInitWaitingMessage = createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => premiseOfValuesState.showInitWaitingMessage
);

export const selectTrashedPremiseOfValueCount = createSelector(
    selectPremiseOfValuesState,
    (premiseOfValuesState) => premiseOfValuesState.totalTrashed
);

export const selectAdminTrashedPremiseOfValueCount = createSelector(
    selectPremiseOfValuesState,
    (premiseOfValuesState) => premiseOfValuesState.totalAdminTrashed
);


export const selectPremiseOfValueQueryResult = createSelector(
    selectPremiseOfValuesState,
    premiseOfValuesState => {
        const items: PremiseOfValue[] = [];
        each(premiseOfValuesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PremiseOfValue[] = httpExtension.sortArray(items, premiseOfValuesState.lastQuery.sortField, premiseOfValuesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, premiseOfValuesState.totalCount, '', premiseOfValuesState.totalTrashed);
    }
);
