import {BaseModel} from '../../_base/crud';

export class AuditTrailReport extends BaseModel {
    id: number;
    assignment_id: number;
    toe_id: number;
    tp_id: number;
    url: string;
    status: number;
    includesOnlySignedToe: boolean;
    file_size: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.assignment_id = undefined;
        this.toe_id = undefined;
        this.tp_id = undefined;
        this.url = '';
        this.status = 0;
        this.includesOnlySignedToe = false;
        this.file_size = '';
        this.user_id = undefined;
        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
