<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h5 class="card-label text-uppercase">add/edit sizes</h5>
        </div>
        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;"
                   (click)="onSave()"
                   class="btn btn-icon btn-sm mr-2 p-1" style="width: 80px"
                   [ngClass]="{
                       'btn-success': isSelected,
                       'disabled': !isSelected
                    }"
                   >
                    Save
                </a>
            </span>

            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
</div>

<div class="card-body">
    <div class="col-md-12 pl-6 mb-4">{{'TOOLTIP.VALUATION_PROCESS.SIZES_TABLE.TEXT' | translate}}</div>
    <div class="col-md-12 mat-table-wrapper" >
        <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource" >
            <!-- Headers -->
            <ng-container matColumnDef="standard-header">
                <th mat-header-cell *matHeaderCellDef >Measurement Standards</th>
            </ng-container>

            <ng-container matColumnDef="measurement-header">
                <th mat-header-cell *matHeaderCellDef>Unit of measurement</th>
            </ng-container>
    
            <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i + '-header'">
                <th mat-header-cell *matHeaderCellDef>
                    {{com.ref_num}}
                </th>
            </ng-container>
    
            <ng-container matColumnDef="tp-header">
                <th mat-header-cell *matHeaderCellDef>
                    Target Property
                </th>
            </ng-container>
    
            <ng-container matColumnDef="action-header">
                <th mat-header-cell *matHeaderCellDef>Action</th>
            </ng-container>
    
            <!-- Cells -->
            <ng-container matColumnDef="criterion">
                <td mat-cell *matCellDef="let criterion">
                    {{criterion.criterion ? criterion.criterion : 'Not defined'}}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="category">
                <td mat-cell *matCellDef="let criterion">{{criterion.categoryName}}</td>
            </ng-container>
    
            <ng-container *ngFor="let com of comparables; let i = index" [matColumnDef]="'com-' + i">
                <td mat-cell *matCellDef="let criterion; let j = index"
                    [ngClass]="{
                        'bg-red': comparableControl(j,com.id).value === null || comparableControl(j,com.id).value === '',
                        'bg-green': comparableControl(j,com.id).value !== null && comparableControl(j,com.id).value !== ''
                    }"
                >
                <!-- <td mat-cell *matCellDef="let criterion; let j = index"> -->
                   <!-- <mat-form-field> -->
                        <input class="inline-input" [formControl]="comparableControl(j, com.id)">
                   <!-- </mat-form-field> -->
                </td>
            </ng-container>
    
            <ng-container matColumnDef="tp">
                <td mat-cell *matCellDef="let criterion; let j = index"
                [ngClass]="{
                    'bg-red': tpControl(j).value === null || tpControl(j).value === '',
                    'bg-green': tpControl(j).value !== null && tpControl(j).value !== ''
                }"
                >
                    <!-- <mat-form-field> -->
                        <input class="inline-input" [formControl]="tpControl(j)">
                    <!-- </mat-form-field> -->
                </td>
            </ng-container>
    
            <ng-container matColumnDef="actions">
                <td mat-cell *matCellDef="let criterion; let j = index">
                    <button 
                        [disabled]="activeStates[j]" mat-icon-button color="primary"
                        (click)="setSizeCriterion(criterion, j)">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
                [ngClass]="{gray: i%2}"></tr>
        </table>
    </div>
</div>

