// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {PlaningStatusActions, PlaningStatusActionTypes} from '../_actions/planing-status.actions';
// Models
import {PlaningStatus} from '../_models/planing-status.model';
import {QueryParamsModel} from '../../_base/crud';

export interface PlaningStatusesState extends EntityState<PlaningStatus> {
    isAllPlaningStatusesLoaded: boolean;
    queryRowsCount: number;
    queryResult: PlaningStatus[];
    lastCreatedPlaningStatusId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PlaningStatus> = createEntityAdapter<PlaningStatus>();

export const initialPlaningStatusesState: PlaningStatusesState = adapter.getInitialState({
    isAllPlaningStatusesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedPlaningStatusId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function planingStatusesReducer(state = initialPlaningStatusesState, action: PlaningStatusActions): PlaningStatusesState {
    switch (action.type) {
        case PlaningStatusActionTypes.PlaningStatusesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPlaningStatusId: undefined
            };
        case PlaningStatusActionTypes.PlaningStatusesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case PlaningStatusActionTypes.PlaningStatusOnServerCreated:
            return {
                ...state
            };
        case PlaningStatusActionTypes.PlaningStatusCreated:
            return adapter.addOne(action.payload.planingStatus, {
                ...state, lastCreatedPlaningStatusId: action.payload.planingStatus.id
            });
        case PlaningStatusActionTypes.PlaningStatusUpdated:
            return adapter.updateOne(action.payload.partialPlaningStatus, state);
        case PlaningStatusActionTypes.AllPlaningStatusesLoaded:
            return adapter.addAll(action.payload.planingStatuses, {
                ...state, isAllPlaningStatusesLoaded: true
            });
        case PlaningStatusActionTypes.PlaningStatusesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case PlaningStatusActionTypes.PlaningStatusesPageLoaded:
            return adapter.addMany(action.payload.planingStatuses, {
                ...initialPlaningStatusesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case PlaningStatusActionTypes.PlaningStatusDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case PlaningStatusActionTypes.PlaningStatusRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case PlaningStatusActionTypes.PlaningStatusDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case PlaningStatusActionTypes.PlaningStatusAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case PlaningStatusActionTypes.PlaningStatusDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case PlaningStatusActionTypes.PlaningStatusTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
