// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ToeActions, ToeActionTypes} from '../_actions/toe.actions';
// Models
import {ToeModel} from '../_models/toe.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ToesState extends EntityState<ToeModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastCreatedToeId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    savingToe: boolean;
    revertingToe: boolean;
}

export const adapter: EntityAdapter<ToeModel> = createEntityAdapter<ToeModel>();

export const initialToesState: ToesState = adapter.getInitialState({
    toeForEdit: null,
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastCreatedToeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    savingToe: false,
    revertingToe: false,
});

export function toesReducer(state = initialToesState, action: ToeActions): ToesState {
    switch (action.type) {
        case ToeActionTypes.ToesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedToeId: undefined
            };
        }
        case ToeActionTypes.ToeActionToggleLoading: {
            return {
                ...state, actionsLoading: action.payload.isLoading
            };
        }

        case ToeActionTypes.ToeClear: {
            return adapter.removeAll({...state});
        }

        case ToeActionTypes.ToeOnServerCreated:
            return {
                ...state, savingToe: true
            };

        case ToeActionTypes.ToeCreated:
            return adapter.addOne(action.payload.toe, {
                ...state, lastCreatedToeId: action.payload.toe.id, savingToe: false
            });

        case ToeActionTypes.ToeOnServerRestored:
            return {
                ...state
            };

        case ToeActionTypes.ToeRestored:
            return adapter.addOne(action.payload.toe, {
                ...state,
                lastCreatedToeId: action.payload.toe.id,
                totalTrashed: state.totalTrashed - 1
            });

        case ToeActionTypes.ToeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state,
                lastCreatedToeId: action.payload.item.id,
                totalAdminTrashed: state.totalAdminTrashed - 1
            });
        case ToeActionTypes.ToeTrashFlushed:
            return {
                ...state,
                totalTrashed: 0,
                totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case ToeActionTypes.ToeDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            };

        case ToeActionTypes.ToeDeletedFromAdminTrash:
            return {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case ToeActionTypes.ToeUpdated:
            return adapter.updateOne(action.payload.partialToe, {...state, savingToe: true});
        case ToeActionTypes.ToeOnServerUpdated:
            return {...state, savingToe: false};

        case ToeActionTypes.ToeStatusUpdated:
            return adapter.updateOne(action.payload.toe, state);

        case ToeActionTypes.OneToeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state,
                totalTrashed: state.totalTrashed + 1
            });

        case ToeActionTypes.ManyToesDeleted:
            return adapter.removeMany(action.payload.ids, state);

        case ToeActionTypes.ToesPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case ToeActionTypes.ToesPageLoaded: {
            return adapter.addMany(action.payload.toes, {
                ...initialToesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        case ToeActionTypes.AllToesByStatusLoaded:
            return adapter.addAll(action.payload.toes, {
                ...initialToesState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
            });
        case ToeActionTypes.ToeEmptyAction:
            return state;
        case ToeActionTypes.RevertToe: {
            return adapter.updateOne(action.payload.update, {...state, revertingToe: true});
        }
        case ToeActionTypes.ToeReverted: {
            return {
                ...state,
                revertingToe: false
            }
        }
        case ToeActionTypes.OneToeLoaded: {
            return adapter.upsertOne(action.payload.toe, {...state});
        }
        default:
            return state;
    }
}

export const getToeState = createFeatureSelector<ToeModel>('toes');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
