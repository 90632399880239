import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllOfficeAdjustmentsLoaded,
    AllOfficeAdjustmentsRequested,
    OfficeAdjustmentActionToggleLoading,
    OfficeAdjustmentAdminRestored,
    OfficeAdjustmentCreated,
    OfficeAdjustmentDeleted,
    OfficeAdjustmentDeletedFromAdminTrash,
    OfficeAdjustmentDeletedFromTrash,
    OfficeAdjustmentOnServerAdminRestored,
    OfficeAdjustmentOnServerCreated,
    OfficeAdjustmentOnServerRestored,
    OfficeAdjustmentRestored,
    OfficeAdjustmentsActionTypes,
    OfficeAdjustmentsPageLoaded,
    OfficeAdjustmentsPageRequested,
    OfficeAdjustmentsPageToggleLoading,
    OfficeAdjustmentTrashFlushed,
    OfficeAdjustmentUpdated
} from '../_actions/office-adjustments.actions';
import {allOfficeAdjustmentsLoaded} from '../_selectors/office-adjustments.selectors';
import {OfficeAdjustmentsService} from '../_services/office-adjustments.service';

@Injectable()
export class OfficeAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: OfficeAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new OfficeAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OfficeAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OfficeAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllOfficeAdjustmentsRequested>(OfficeAdjustmentsActionTypes.AllOfficeAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allOfficeAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllOfficeAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentsPageRequested>(OfficeAdjustmentsActionTypes.OfficeAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OfficeAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentUpdated>(OfficeAdjustmentsActionTypes.OfficeAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentOnServerCreated>(OfficeAdjustmentsActionTypes.OfficeAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentTrashFlushed>(OfficeAdjustmentsActionTypes.OfficeAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentOnServerRestored>(OfficeAdjustmentsActionTypes.OfficeAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentOnServerAdminRestored>(OfficeAdjustmentsActionTypes.OfficeAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentDeleted>(OfficeAdjustmentsActionTypes.OfficeAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentDeletedFromTrash>(OfficeAdjustmentsActionTypes.OfficeAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<OfficeAdjustmentDeletedFromAdminTrash>(OfficeAdjustmentsActionTypes.OfficeAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}