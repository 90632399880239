// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {
    selectTaskEffortQueryResult,
    selectTaskEffortsPageLoading,
    selectTaskEffortsShowInitWaitingMessage
} from '../_selectors/task-effort.selectors';

export class TaskEffortsDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectTaskEffortsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTaskEffortsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTaskEffortQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
