// Angular
import {Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Material
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
// RXJS
import {debounceTime, distinctUntilChanged, tap, skip, take, delay, map, filter} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subscription} from 'rxjs';
// LODASH
import {concat, find} from 'lodash';
// NGRX
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';

// Services
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
// Models
import {
  User,
  UsersDataSource,
  UserDeleted,
  UsersPageRequested,
  UsersActionToggleLoading,
  UserDeletedFromAdminTrash,
  UserDeletedFromTrash,
  UserOnServerAdminRestored,
  UserOnServerRestored,
  UserTrashFlushed,
  selectAdminTrashedUserCount,
  selectTrashedUserCount,
  UserService,
  UserStatusUpdated,
} from '../../../../../core/auth';
import { MadRole, MadRolesRequested, selectRoles } from 'src/app/core/mad-auth/mad-roles.store';
import { currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import {SubheaderService} from '../../../../../core/_base/layout';
import {TranslateService} from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Update } from '@ngrx/entity';
import { AllValuerQualificationsRequested, selectAllValuerQualifications, ValuerQualification } from 'src/app/core/linked-tables';
import { Option } from '../../../shared_components/filter/f-selection/f-selection.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type pmStatus = {
  total_users: string,
  active_users: string,
}

@Component({
  selector: 'kt-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: UsersDataSource;
  displayedColumns = ['id', 'full_name', 'qualification_name', '_roles', 'toe_cnt', 'assignment_cnt', 'is_active', 'actions'];

  trashCnt$: Observable<number>;
  adminTrashCnt$: Observable<number>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  lastQuery: QueryParamsModel;

  usersResult: User[] = [];
  allRoles: MadRole[] = [];
  roleSelections: Option[] = [];
  qualifications: ValuerQualification[] = [];
  qualificationSelections: Option[] = [];
  userStatusCnts$ = new BehaviorSubject<pmStatus>({
      total_users: '0',
      active_users: '0',
  });  
  currentUser: User;  
  filter: any;

  // Subscriptions
  private subscriptions: Subscription[] = [];
  canEdit$ = combineLatest([ 
    this.store.pipe(
      select(hasPermission(['user:edit']))
    ),
    this.store.select(currentUser).pipe(filter(user => !!user))
  ]).pipe(
    map(([canEdit, user]) => {
      if (canEdit) {
        return true;
      }
      if (user.is_account_user) {
        return true;
      }
      return false;
    })
  )
  canAdminThrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
  /**
   *
   * @param activatedRoute: ActivatedRoute
   * @param store: Store<AppState>
   * @param router: Router
   * @param service: UserService
   * @param layoutUtilsService: LayoutUtilsService
   * @param subheaderService: SubheaderService
   * @param translateService: TranslateService
   */
  constructor(private activatedRoute: ActivatedRoute,
              private store: Store<AppState>,
              private router: Router,
              private service: UserService,
              private layoutUtilsService: LayoutUtilsService,
              private subheaderService: SubheaderService,
              private translateService: TranslateService,
              public subDomainService: SubdomainService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.store.pipe(select(currentUser)).subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });
    // load roles list
    this.store.dispatch(new MadRolesRequested({query: {page: -1, page_size: -1}}));
    const rolesSubscription = this.store.pipe(select(selectRoles)).subscribe(res => {
      this.allRoles = res ? res : [];
      this.roleSelections = concat([{id: -1, text: 'All'}], this.allRoles.map(role => ({id: role.id, text: role.name})));
    });
    this.subscriptions.push(rolesSubscription);
    this.store.dispatch(new AllValuerQualificationsRequested());
    const qualificationSub = this.store.pipe(select(selectAllValuerQualifications)).subscribe(res => {
      this.qualifications = res ? res : []; 
      this.qualificationSelections = concat([{id: -1, text: 'All'}], this.qualifications.map(q => ({id: q.id, text: q.name})));
    });
    this.subscriptions.push(qualificationSub);

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => {
        this.loadUsersList();
      })
    ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    this.trashCnt$ = this.store.pipe(select(selectTrashedUserCount));
    this.adminTrashCnt$ = this.store.pipe(select(selectAdminTrashedUserCount));

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('User management');

    // Init DataSource
    this.dataSource = new UsersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.usersResult = res;
      this.userStatusCnts$.next(
        {
          total_users: res.length.toString(),
          active_users: res.filter(item => item.is_active == 1).length.toString()
        }
      )
    });
    this.subscriptions.push(entitiesSubscription);

    // First Load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadUsersList();
    });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load users list
   */
  loadUsersList() {
    const queryParams = new QueryParamsModel(
      this.filter,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
    this.store.dispatch(new UsersPageRequested({page: queryParams}));
  }


  /** ACTIONS */
  /**
   * Delete user
   *
   * @param _item: User
   */
  deleteUser(_item: User) {
    const _title: string = this.translateService.instant('USER.LIST.DIALOG.DELETE_USER.TITLE');
    const _description: string = this.translateService.instant('USER.LIST.DIALOG.DELETE_USER.DESCRIPTION');
    const _waitDesciption: string = this.translateService.instant('USER.LIST.DIALOG.DELETE_USER.WAIT_DESCRIPTION');

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.store.dispatch(new UserDeleted({id: _item.id}));
    });
  }

  trash() {
    this.store.dispatch(new UsersActionToggleLoading({isLoading: true}));
    this.service.getTrashed().subscribe(res => {
      this.store.dispatch(new UsersActionToggleLoading({isLoading: false}));
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `${elem.full_name}`,
          id: elem.id.toString(),
          deletedUser: elem.userDeletedBy,
          hasPermanentlyDelete: true,
          date: elem.user_deleted
        });
      });
      this.show_trash(items);
    });
  }

  // Fetch admin trash data
  adminTrash() {
    this.store.dispatch(new UsersActionToggleLoading({isLoading: true}));
    this.service.getAdminTrashed().subscribe(res => {
      this.store.dispatch(new UsersActionToggleLoading({isLoading: false}));
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `${elem.full_name}`,
          id: elem.id.toString(),
          deletedUser: elem.deletedBy,
          hasPermanentlyDelete: true,
          date: elem.user_deleted
        });
      });
      this.show_trash(items, true);
    });
  }

  /**
   * Show Edit agency dialog and save after success close result
   * @param items: any[]
   * @param isAdmin
   */
  show_trash(items: any[], isAdmin = false) {
    let _title = this.translateService.instant('USER.LIST.DIALOG.SHOW_TRASH.TITLE');

    if (isAdmin) {
      _title = this.translateService.instant('USER.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
    }
    this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
  }

  restore(id: number, isAdmin = false) {
    if (isAdmin) {
      this.store.dispatch(new UserOnServerAdminRestored({id}));
    } else {
      this.store.dispatch(new UserOnServerRestored({id}));
    }
  }

  delete(id: number) {
    this.store.dispatch(new UserDeletedFromTrash({id}));
  }

  hardDelete(id: number) {
    this.store.dispatch(new UserDeletedFromAdminTrash({id}));
  }

  flush() {
    this.store.dispatch(new UserTrashFlushed());
  }

  /* UI */
  /**
   * Returns user roles string
   *
   * @param user: User
   */
  getUserRolesStr(user: User): string {
    const _role = find(this.allRoles, (role: MadRole) => role.id === user.mad_role?.id);
    return _role ? _role.name : '';
  }

  /**
   * Redirect to edit page
   *
   * @param id: User
   */
  editUser(id) {
    this.router.navigate(['../users/edit', id], {relativeTo: this.activatedRoute});
  }

  /**
   * Redirect to add page
   */
  addUser() {
    this.router.navigate(['../users/add'], {relativeTo: this.activatedRoute});
  }

  draft_cnt(user) {
    let lv_cnt = 0
    let member_cnt = 0
    user.toe_draft.forEach(toe => {
      if (toe.members.filter(member => member.lead_valuer == 1 && member.worker_id == user.id).length == 1) {
        lv_cnt++
      }
      if (toe.members.filter(member => member.lead_valuer == 0 && member.worker_id == user.id).length == 1) {
        member_cnt++
      }
    });
    return lv_cnt + ' ( ' + member_cnt + ' )';
  }
  ongoing_cnt(user) {
    let lv_cnt = 0
    let member_cnt = 0
    user.toe_ongoing.forEach(toe => {
      if (toe.members.filter(member => member.lead_valuer == 1 && member.worker_id == user.id).length == 1) {
        lv_cnt++
      }
      if (toe.members.filter(member => member.lead_valuer == 0 && member.worker_id == user.id).length == 1) {
        member_cnt++
      }
    });
    return lv_cnt + ' ( ' + member_cnt + ' )'
  }
  complete_cnt(user) {
    let lv_cnt = 0
    let member_cnt = 0
    user.toe_complete.forEach(toe => {
      if (toe.members.filter(member => member.lead_valuer == 1 && member.worker_id == user.id).length == 1) {
        lv_cnt++
      }
      if (toe.members.filter(member => member.lead_valuer == 0 && member.worker_id == user.id).length == 1) {
        member_cnt++
      }
    });
    return lv_cnt + ' ( ' + member_cnt + ' )'
  }
  getItemCssClassByStatus(status: number = 0): string {
    switch (status) {
      case 0:
        return 'grey';
      case 1: 
        return 'validated'
    }
    return '';
  }
  getItemStatusString(_user: User): string {
    switch (_user.is_active) {
      case 0:
        return 'DISABLED';
      case 1:
        return 'ENABLED';
    }
    return '';
  }
  changeStatus(event: MatCheckboxChange, user: User) {
    const is_active = event.checked ? 1 : 0;
    const newUser: Update<User> = {
      id: user.id,
      changes: {
        id: user.id,
        is_active
      }
    };
    this.store.dispatch(new UserStatusUpdated({user: newUser}));
}

  onFilterChange(event) {
    this.paginator.pageIndex = 0;
    this.filter = event;
    this.loadUsersList();
  }

  convertUser(user: any) {
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      picture: user.picture,
      email: user.email,
      qualification: user.qualification_name,
    }
  }
}
