// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
    selectTpAdminTaskLVsInStore,
    selectTpAdminTaskLVsPageLoading,
    selectTpAdminTaskLVsShowInitWaitingMessage
} from '../_selectors/tp-admin-task-lv.selectors';

export class TpAdminTaskLVsDataSource extends BaseDataSource {

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectTpAdminTaskLVsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTpAdminTaskLVsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTpAdminTaskLVsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
