import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { floor } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Rooms } from '../size-module/mongolia-standard-table/mongolia-standard-table.types';
import * as _ from 'lodash';

type ColumnData = Record<string, number>

@Component({
  selector: 'kt-accommodation-layout-table',
  templateUrl: './accommodation-layout-table.component.html',
  styleUrls: ['./accommodation-layout-table.component.scss']
})
export class AccommodationLayoutTableComponent implements OnInit {
  @Input() propertySubTypeID: number;
  @Input() aboveFloor: Observable<number>;
  @Input() belowFloor: Observable<number>;
  @Input() initData: Record<number, ColumnData>;
  @Input() initOthers: {id: number, title: string}[];
  @Input() schemeId: number = 1;
  @Input() hasTitle: boolean = true;
  @Input() readonly: boolean = false;
  @Input() floors$: Observable<number[]> = of([]);
  @Input() rooms$: Observable<{room: number, bedroom: number, bathroom: number}> = of(null);
  @Output() roomChange: EventEmitter<Rooms> = new EventEmitter();

  data: Record<number, ColumnData> = {};
  floors: number[] = [];
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  others: {id: number, title: string}[] = [];
  rows: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.rows = this._getRows(this.propertySubTypeID);
    this.data = this._deepCopy(this.initData);
    this.others = this.initOthers.map(io => Object.assign({}, io));
    if (this.others.length == 0) {
      this.others.push({id: 1, title: null});
    }
    const rooms: Rooms = {
      bath: 0,
      kitchen: 0,
      bedrooms: 0,
      livingRooms: 0,
      toilet: 0
    }
    _.each(this.data, (col) => {
      Object.entries(col).forEach(([key, value]) => {
        if (rooms.hasOwnProperty(key)) {
          rooms[key] += value != undefined ? Number(value) : 0; 
        }
      })
    })
    this.roomChange.emit(rooms);

    combineLatest([
      // this.aboveFloor,
      // this.belowFloor,
      this.floors$,
      this.rooms$,
    ]).subscribe(([floors, rooms]) => {
      this.loading$.next(true);
      let _floors = [];
      // if (above || below) {
      //   _floors = this._computeFloorNumbers(above, below);
      // } else {
      //   _floors = floors;
      // }
      _floors = floors;
      if (rooms == null) {
        // This branch will work on any other comparable other than Apartment
        _floors.forEach(floor => {
          if (this.data[floor]) {
            return;
          }
          this.data[floor] = {};
          this.others.forEach(other => this.data[floor][other.id] = undefined);
        });
      } else {
        _floors.forEach(floor => {
          if (this.data[floor]) {
            return;
          }
          this.data[floor] = {};
          this.others.forEach(other => this.data[floor][other.id] = undefined);
        });
        if (_floors.length > 0) {
          this.data[_floors[0]]['total'] = rooms.room;
          this.data[_floors[0]]['bedrooms'] = rooms.bedroom;
          this.data[_floors[0]]['bath'] = rooms.bathroom;
        }
      }
      this.floors = _floors;
    })
  }

  removeOther(index: number, id: number) {
    this.others.splice(index, 1);
    Object.values(this.data).forEach(val => {
      delete val[id];
    });
  }
  addOther(index: number) {
    const newId = this.others.length + 1;
    this.others.push({id: newId, title: ''});
    Object.values(this.data).forEach(val => {
      val[newId] = undefined;
    });
  }

  public getData() {
    return {
      data: this.data,
      others: this.others,
    }
  }

  private _computeFloorNumbers(above: number, below: number): number[] {
    const floors: number[] = [];
    for (let i = -below; i <= above; i++) {
      if (i == 0) {
        continue;
      }
      floors.push(i);
    }
    floors.sort((a, b) => a - b);
    return floors;
  }

  private _deepCopy(src: Record<number, Record<string, number>>) {
    if (src == null) {
      return {};
    }
    const dst = {};
    Object.keys(src).forEach(key => {
      const item = {};
      Object.keys(src[key]).forEach(_key => {
        item[_key] = src[key][_key];
      });
      dst[key] = item;
    });
    return dst;
  }

  private _getRows(id: number): any[] {
    switch (id) {
      case 1: {
        return [
          {title: "Total number of rooms", key: "total"},
          {title: 'Living rooms', key: 'livingRooms'},
          {title: 'Bedrooms', key: 'bedrooms'},
          {title: 'Bath or Shower', key: 'bath'},
          {title: 'Separate toilet', key: 'toilet'},
          {title: 'Kitchen', key: 'kitchen'},
          {title: 'Utility room', key: 'utility_room'},
          {title: 'Conservatory', key: 'conservatory'}
        ]
      }
      case 3: {
        return [
          {title: 'Reception area', key: 'reception_area'},
          {title: 'Private office', key: 'private_office'},
          {title: 'Open area', key: 'open_area'},
          {title: 'Conference room', key: 'conference_room'},
          {title: 'Break room / printer room', key: 'break_room'},
          {title: 'Mail room', key: 'mail_room'},
          {title: 'Hygiene area', key: 'hygiene_area'},
          {title: 'Kitchen', key: 'kitchen'},
        ];
      }
      case 5: {
        return [
          {title: 'Retailing area', key: 'retailing_area'},
          {title: 'Storage', key: 'storage'},
          {title: 'Office', key: 'office'},
          {title: 'Break room', key: 'break_room'},
          {title: 'Sales counter', key: 'sales_counter'},
          {title: 'Utility room', key: 'utility_room'}
        ]
      }
      case 7: {
        return [
          {title: 'Warehouse', key: 'warehouse'},
          {title: 'Production', key: 'production'},
          {title: 'Office', key: 'office'},
          {title: 'Showroom', key: 'showroom'},
          {title: 'Mezzanine', key: 'mezzanine'},
          {title: 'Loading docks', key: 'loading_docks'},
          {title: 'Utility room', key: 'utility_room'}
        ];
      }
      case 11: {
        return [
          {title: 'Retailing area', key: 'retailing_area'},
          {title: 'Storage', key: 'storage'},
          {title: 'Office', key: 'office'},
          {title: 'Break room', key: 'break_room'},
          {title: 'Sales counter', key: 'sales_counter'},
          {title: 'Utility room', key: 'utility_room'}
        ]
      }
      case 17: {
        return [
          {title: 'Living rooms', key: 'livingRooms'},
          {title: 'Bedrooms', key: 'bedrooms'},
          {title: 'Bath or Shower', key: 'bath'},
          {title: 'Separate toilet', key: 'toilet'},
          {title: 'Kitchen', key: 'kitchen'},
          {title: 'Utility room', key: 'utility_room'},
          {title: 'Conservatory', key: 'conservatory'}
        ]
      }
    }
  }

  onChange(event: any, key: string) {
    const rooms: Rooms = {
      bath: 0,
      kitchen: 0,
      bedrooms: 0,
      livingRooms: 0,
      toilet: 0
    }
    _.each(this.data, (col) => {
      Object.entries(col).forEach(([key, value]) => {
        if (rooms.hasOwnProperty(key)) {
          rooms[key] += value != undefined ? Number(value) : 0; 
        }
      })
    })
    this.roomChange.emit(rooms);
  }
}

// function distributeRooms(floors: number[], rooms: {room: number, bedroom: number, bathroom: number}): any {
//   let data = {};
//   floors.forEach(floor => {
//     data[floor] = {};
//   });
//   data = singleRoom(data, floors, "livingRooms", rooms.room);
// }

// function singleRoom(data: any, floors: number[], type: string, room: number) {
//   const base = Math.floor(room / floors.length);
//   const rooms = [];
//   for (let i = 0; i < floors.length; i++) {

//   }
//   return data;
// }