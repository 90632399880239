<form class="form" [formGroup]="formGroup" *ngIf="formGroup" [ngClass]="{'readonly': readonly}">
    <div class="form-group">
        <div class="row">
            <div class="col-4">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput type="text" placeholder="Template Name" formControlName="name">
                    <mat-error>Template Name <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
            <div class="col-2"></div>
            <div class="col-4">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Asset Class" formControlName="asset_class_type">
                        <mat-option *ngFor="let type of assetClassTypes" [value]="type.id">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Asset Class <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput type="text" placeholder="Template Description" formControlName="description"></textarea>
                    <mat-error>Template Description <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="subform-header">
                    <h5 class="text-mad text-uppercase my-0">default criterions</h5>
                    <button mat-raised-button 
                        color="primary"
                        *ngIf="!readonly"
                        [disabled]="!selectedAssetClassTypeId"
                        type="button"
                        (click)="addDefaultCriterion()"
                        >
                        <span [matTooltip]="selectedAssetClassTypeId ? 'Add Criterion' : 'Select Asset Class'">Add</span>
                    </button>
                </div>
                <hr class="active">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-table [dataSource]="defaultCriterionsDataSource">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *matCellDef="let criterion">{{criterion.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
                        <mat-cell *matCellDef="let criterion">{{criterion.category}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="tableactions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let criterion; let i = index;">
                            <button mat-icon-button
                                [disabled]="readonly"
                                color="warn"
                                type="button"
                                (click)="deleteCriterion(criterion.type, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['name', 'category', 'tableactions']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['name', 'category', 'tableactions']"></mat-row>
                </mat-table>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="subform-header">
                    <h5 class="text-mad text-uppercase my-0">Custom criterions</h5>
                    <button mat-raised-button 
                        color="primary"
                        *ngIf="!readonly"
                        type="button"
                        (click)="addCustomCriterion()"
                        >
                        <span matTooltip="Add Custom Criterion">Add</span>
                    </button>
                </div>
                <hr class="active">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-table [dataSource]="customCriterionsDataSource">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *matCellDef="let criterion">{{criterion.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
                        <mat-cell *matCellDef="let criterion">{{criterion.category}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="tableactions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let criterion; let i = index;">
                            <button mat-icon-button
                                [disabled]="readonly"
                                color="primary"
                                type="button"
                                (click)="editCriterion(criterion, i)">
                                <mat-icon>create</mat-icon>
                            </button>
                            <button mat-icon-button
                                [disabled]="readonly"
                                color="warn"
                                type="button"
                                (click)="deleteCriterion(criterion.type, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['name', 'category', 'tableactions']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['name', 'category', 'tableactions']"></mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</form>
