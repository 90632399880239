import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { selectValuationProcessTargetPropertyInfo } from 'src/app/core/valuation-process';
import { selectValuationLandmarks, selectValuationProcessSelectedComparablesFull, selectValuationProcessTargetProperty } from 'src/app/core/valuation-process/_selectors';
import { selectValuationProcessValuationFullState, selectValuationProcessValuationSummary } from 'src/app/core/valuation-process/_selectors/valuation.selectors';
import { ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput } from '../_sub/comparable-info-dialog-v2/comparable-info-dialog-v2.component';
import * as _ from 'lodash'
import { MapsService } from 'src/app/core/map/maps.service';

@Component({
  selector: 'kt-summary-tab-v2',
  templateUrl: './summary-tab-v2.component.html',
  styleUrls: ['./summary-tab-v2.component.scss']
})
export class SummaryTabV2Component implements OnInit {

  private _label = {
    color: '#000000',
    fontFamily: '',
    fontSize: '14px',
    fontWeight: 'bold',
    className: 'bg-op',
    text: ''
  }
  fitBounds = true;

  mapData$ = combineLatest([
    this.store$.select(selectValuationProcessTargetProperty),
    this.store$.select(selectValuationProcessSelectedComparablesFull),
    this.store$.select(selectValuationLandmarks)
  ]).pipe(
    map(([tp, comparables, landmarks]) => {
      const _comparableMap: Map<string, {
        latitude: number,
        longitude: number,
        label: any
      }> = new Map();
      comparables.forEach(comparable => {
        const key = comparable.locationData.latitude + '_' + comparable.locationData.longitude;
        if (_comparableMap.has(key)) {
          const data = _comparableMap.get(key);// + '/' + comparable.refNum
          data.label.text = data.label.text + '/' + comparable.refNum
          _comparableMap.set(key, data)
        } else {
          _comparableMap.set(key, {
            latitude: comparable.locationData.latitude,
            longitude: comparable.locationData.longitude,
            label: {
              color: '#000000',
              fontFamily: '',
              fontSize: '14px',
              fontWeight: 'bold',
              className: `bg-marker bg-marker-multimedia-comparable`,
              text: comparable.refNum
            }
          })
        }
      })
      const distances = Array.from(_comparableMap.values()).map((cl: any) => {
        return distance(cl.latitude, cl.longitude, tp.locationData.latitude, tp.locationData.longitude, 'K')
      })
      const maxDist = _.max(distances)
      if (maxDist <= 0.5) {
        this.fitBounds = false
      }
      const landmarksLocation = landmarks.map((item, index) => {
        return {
          ...item,
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
          label: {
            color: '#000000',
            fontFamily: '',
            fontSize: '14px',
            fontWeight: 'bold',
            className: `bg-marker bg-marker-multimedia-landmark`,
            text: `LM${index + 1}`,
          }
        }
      })
      return {
        tp: {
          latitude: tp.locationData.latitude,
          longitude: tp.locationData.longitude
        },
        comparables: Array.from(_comparableMap.values()),
        landmarks: landmarksLocation,
        refNums: comparables.map(comparable => comparable.refNum)
      }
    })
  )

  viewModel$ = this.store$.select(selectValuationProcessValuationSummary).pipe(
    map(state => {
      return {
        ...state
      }
    })
  )

  icon: google.maps.Icon = {
    url: './assets/media/icons/my_marker.svg',
    labelOrigin: new google.maps.Point(18, 40)
  }
  tpIcon: google.maps.Icon = {
    url: 'https://chart.apis.google.com/chart?chst=d_map_spin&chld=0.9|0|ffe2e5|11|b|TP',
    labelOrigin: new google.maps.Point(18, 22)
  }
  tpIcon$ = this.mapsService.icons$.pipe(
    map(icon => ({
      ...icon,
      fillColor: '#FFE2E5',
      strokeWeight: 1
    }))
  )
  tpLabel$ = this.mapsService.label$


  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private mapsService: MapsService
  ) { }

  ngOnInit(): void {
  }

  onShowComparableOverview(refNums: string[]) {
    this.dialog.open<ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput>(ComparableInfoDialogV2Component, {
      data: {
        refNums,
        isMulti: true
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef
    })
  }
}

function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344
    }
    if (unit == 'N') {
      dist = dist * 0.8684
    }
    return parseFloat(dist.toFixed(2));
  }
}