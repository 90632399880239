import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TpTemplateModel } from "..";
import { LoadTpTemplates, TpTemplateActionTypes, TpTemplatesLoaded } from "../_actions";
import { AddTpTemplate, DeleteTpTemplate, DuplicateTpTemplate, TpTemplateAdded, TpTemplateUpdated, UpdateTpTemplate } from "../_actions/tp-template.actions";
import { TpTemplateService } from "../_services";

@Injectable()
export class TpTemplateEffects {
    constructor(
        private actions: Actions,
        private service: TpTemplateService
    ) {}

    @Effect()
    loadTpTemplate$ =
        this.actions.pipe(
            ofType<LoadTpTemplates>(TpTemplateActionTypes.LOAD_TP_TEMPLATES),
            mergeMap(({payload}) => {
                return this.service.getAll(payload.request)
            }),
            map(res => {
                return new TpTemplatesLoaded({
                    templates: res.templates,
                    total: res.total
                });
            })
        )

    @Effect()
    addTpTemplate$ =
        this.actions.pipe(
            ofType<AddTpTemplate>(TpTemplateActionTypes.ADD_TP_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.create(payload.template)
            }),
            map(res => {
                return new TpTemplateAdded({
                    template: res
                })
            })
        )

    @Effect()
    updateTpTemplate$ =
        this.actions.pipe(
            ofType<UpdateTpTemplate>(TpTemplateActionTypes.UPDATE_TP_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.update(payload.id, payload.template)
            }),
            map(res => {
                const newTemplate: Update<TpTemplateModel> = {
                    id: res.id,
                    changes: {
                        ...res
                    }
                };
                return new TpTemplateUpdated({
                    id: res.id,
                    template: newTemplate
                })
            })
        )

    @Effect()
    deleteTpTemplate$ = 
        this.actions.pipe(
            ofType<DeleteTpTemplate>(TpTemplateActionTypes.DELETE_TP_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.delete(payload.id),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadTpTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )

    @Effect()
    duplicateTpTemplate$ =
        this.actions.pipe(
            ofType<DuplicateTpTemplate>(TpTemplateActionTypes.DUPLICATE_TP_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.duplicate(payload.template),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ])
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadTpTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )
}
