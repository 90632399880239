import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AssetClassModel, PaymentModel, PaymentTaxModel, PaymentTermModel, ValuationModel } from 'src/app/core/asset_class';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { CoiDetailsTPModel, ToeModel, ToeOnServerCreated, ToeUpdated } from 'src/app/core/toe';
import { GenerateToeReport } from 'src/app/core/toe/_actions/toe-report.actions';
import { SupportingValuerModel } from 'src/app/core/toe/_models/supporting-valuer.model';
import { selectCurrentGeneratingReportID, selectIsReportGenerating, selectIsReportGeneratingError } from 'src/app/core/toe/_selectors/toe-report.selectors';
import { selectIsSavingToe, selectLastCreatedToeId } from 'src/app/core/toe/_selectors/toe.selectors';

export interface SaveToeData {
  toe: ToeModel,
  payment: PaymentModel,
  workers: SupportingValuerModel[],
  rdms: number[],
  tpList: AssetClassModel[],
  coiProperties: CoiDetailsTPModel[],
  paymentTaxes: PaymentTaxModel[],
  valuations: ValuationModel[],
  paymentTerms: PaymentTermModel[],
  standards: any[],
  fileList: any[]
}

enum ComponentState {
  Warning,
  SavingToe,
  GeneratingToeReport,
  SavingToeError,
  GeneratingToeReportError,
  Done
}


@Component({
  selector: 'kt-save-toe-dialog',
  templateUrl: './save-toe-dialog.component.html',
  styleUrls: ['./save-toe-dialog.component.scss'],
})
export class SaveToeDialogComponent implements OnInit, OnDestroy {
  public state$ = new BehaviorSubject<ComponentState>(ComponentState.Warning);
  public componentState = ComponentState;

  public viewLoading$: Observable<boolean>;
  public headerTxt$: Observable<string>;
  public bodyTxt$: Observable<{isError: boolean, msg: string}>
  public viewLoading: boolean = false;
  private description: string = "After the ToE has been validated, it will be locked for editing. ToE generation and upload functions will become available. The ToE dashboard, on which document uploads, inspections and valuations can be conducted, will become accessible to all valuation team members. If you later wish to revert the ToE to draft form, the uploaded signed ToE will be removed and all tasks conducted on the ToE dashboard (document upload, valuations and inspections) will be removed. All valuation details filled out in tabs 1-6 and generated ToE documents will be retained.";
  private subscriptions: Subscription[] = [];
  private reportGeneratedActionDispatched = false;
  private reportGenerationFailed = false;
  private currentUser: User;
  private currentGeneratingReportID: number;
  private lastCreatedToeID: number;
  private btnClick = 0;

  constructor(
    public dialogRef: MatDialogRef<SaveToeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveToeData,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    const userSub = this.store.pipe(take(1), select(currentUser)).subscribe(res => {
      if (res) {
        this.currentUser = res;
      }
    });
    this.subscriptions.push(userSub);
    this.viewLoading$ = this.state$.pipe(map(state => state == ComponentState.SavingToe || state == ComponentState.GeneratingToeReport));
    this.headerTxt$ = this.state$.pipe(map(state => {
      if (state == ComponentState.Warning) {
        return "Are you sure?"
      }
      return ""
    }));
    this.bodyTxt$ = this.state$.pipe(map(state => {
      switch (state) {
        case ComponentState.Warning:
          return {isError: false, msg: this.description}
        case ComponentState.SavingToe:
          return {isError: false, msg: "Saving ToE"}
        case ComponentState.GeneratingToeReport:
          return {isError: false, msg: "Generating Terms of Engagement"}
        case ComponentState.SavingToeError:
          return {isError: true, msg: "Error occured while saving ToE"};
        case ComponentState.GeneratingToeReportError:
          return {isError: true, msg: "Error occured while generating ToE report"}
        case ComponentState.Done:
          return {isError: false, msg: 'Saved the ToE'}
      }
    }));
    const toeSavingSub = this.store.select(selectIsSavingToe).subscribe(isSaving => {
      if (isSaving) {
        this.state$.next(ComponentState.SavingToe);
      }
    })
    this.subscriptions.push(toeSavingSub);
    const reportGenerationFailSub = this.store.select(selectIsReportGeneratingError).subscribe(failed => {
      if (failed) {
        this.reportGenerationFailed = true;
        this.state$.next(ComponentState.GeneratingToeReportError);
      } else {
        this.reportGenerationFailed = false;
      }
    });
    this.subscriptions.push(reportGenerationFailSub);
    const reportGeneratingSub = this.store.select(selectIsReportGenerating).subscribe(isGenerating => {
      if (isGenerating) {
        this.state$.next(ComponentState.GeneratingToeReport);
        this.reportGeneratedActionDispatched = true;
      } else if (!isGenerating && this.reportGeneratedActionDispatched && !this.reportGenerationFailed) {
        this.state$.next(ComponentState.Done);
      }
    })
    this.subscriptions.push(reportGeneratingSub);
    const currentGeneratingReportID = this.store.select(selectCurrentGeneratingReportID).subscribe(id => this.currentGeneratingReportID = id);
    this.subscriptions.push(currentGeneratingReportID);
    const lasCreatedToeIDSub = this.store.pipe(select(selectLastCreatedToeId)).subscribe(id => this.lastCreatedToeID = id);
    this.subscriptions.push(lasCreatedToeIDSub);
    // this.storeSelectSub = combineLatest([
    //   this.store.select(selectIsSavingToe),
    // ]).subscribe(([isSaving, isGenerating]) => {
    //   if (isSaving) {
    //     this.state$.next(ComponentState.SavingToe);
    //     return;
    //   }
    //   if (isGenerating) {
    //     this.state$.next(ComponentState.GeneratingToeReport);
    //     return;
    //   }
    // })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onNoClick() {
    this.dialogRef.close();
  }

  changeState() {
    switch (this.btnClick) {
      case 0:
        this.state$.next(ComponentState.SavingToe);
        break;
      case 1:
        this.state$.next(ComponentState.GeneratingToeReport);
        break;
      case 2:
        this.state$.next(ComponentState.SavingToeError);
        break;
      case 3:
        this.state$.next(ComponentState.GeneratingToeReportError);
        break;
      default:
        this.state$.next(ComponentState.Warning);
    }
    this.btnClick += 1;
  }

  onYesClick() {
    this.viewLoading = true;

    if (this.data.toe.id > 0) {
      this.updateToe(this.data.toe, this.data.payment);
    } else {
      this.addToe(this.data.toe, this.data.payment);
    }

    const doneSub = this.state$.subscribe(state => {
      if (state == ComponentState.Done) {
        this.dialogRef.close(true);
      }
    });
    this.subscriptions.push(doneSub);
  }

  public generateReport() {
    let toeID = this.data.toe.id;
    if (!(this.data.toe.id > 0)) {
      toeID = this.lastCreatedToeID;
    }
    this.store.dispatch(new GenerateToeReport({toeID, userID: this.currentUser.id, toeStatus: this.data.toe.status, reportID: this.currentGeneratingReportID}))
  }

  private updateToe(toe: ToeModel, payment: PaymentModel) {
    const updateToe: Update<ToeModel> = {
      id: toe.id,
      changes: toe
    };

    this.store.dispatch(new ToeUpdated({
      toe: toe,
      workers: this.data.workers,
      partialToe: updateToe,
      rdms: this.data.rdms,
      tp_list: this.data.tpList,
      coi_properties: this.data.coiProperties,
      payment: payment,
      paymentTaxes: this.data.paymentTaxes,
      valuations: this.data.valuations,
      paymentTerms: this.data.paymentTerms,
      standards: this.data.standards,
      fileList: this.data.fileList
    }));
  }

  private addToe(toe: ToeModel, payment: PaymentModel) {
    this.store.dispatch(new ToeOnServerCreated({
      toe: toe,
      workers: this.data.workers,
      rdms: this.data.rdms,
      tp_list: this.data.tpList,
      coi_properties: this.data.coiProperties,
      payment: payment,
      paymentTaxes: this.data.paymentTaxes,
      valuations: this.data.valuations,
      paymentTerms: this.data.paymentTerms,
      standards: this.data.standards,
      fileList: this.data.fileList,
      isDuplicated: false
    }));
  }

}
