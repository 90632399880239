// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {FacilityActions, FacilityActionTypes} from '../_actions/facility.actions';
import {Facility} from '../_models/facility.model';

export interface FacilitiesState extends EntityState<Facility> {
    isAllFacilitiesLoaded: boolean;
    queryRowsCount: number;
    queryResult: Facility[];
    lastCreatedFacilityId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Facility> = createEntityAdapter<Facility>();

export const initialFacilitiesState: FacilitiesState = adapter.getInitialState({
    isAllFacilitiesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedFacilityId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function facilitiesReducer(state = initialFacilitiesState, action: FacilityActions): FacilitiesState {
    switch (action.type) {
        case FacilityActionTypes.FacilitiesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedFacilityId: undefined
            };
        case FacilityActionTypes.FacilitiesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case FacilityActionTypes.FacilityOnServerCreated:
            return {
                ...state
            };
        case FacilityActionTypes.FacilityCreated:
            return adapter.addOne(action.payload.facility, {
                ...state, lastCreatedFacilityId: action.payload.facility.id
            });
        case FacilityActionTypes.FacilityUpdated:
            return adapter.updateOne(action.payload.partialFacility, state);
        case FacilityActionTypes.AllFacilitiesLoaded:
            return adapter.addAll(action.payload.facilities, {
                ...state, isAllFacilitiesLoaded: true
            });
        case FacilityActionTypes.FacilitiesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case FacilityActionTypes.FacilitiesClear:
            return adapter.removeAll(state);
        case FacilityActionTypes.FacilitiesPageLoaded:
            return adapter.addMany(action.payload.facilities, {
                ...initialFacilitiesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case FacilityActionTypes.FacilityDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case FacilityActionTypes.FacilityRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case FacilityActionTypes.FacilityDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case FacilityActionTypes.FacilityAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case FacilityActionTypes.FacilityDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case FacilityActionTypes.FacilityTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
