<div class="card card-custom section-margin-bottom-60">
    <div class="card-header vpga-header">
        <div class="card-title">
            <div class="card-label">
                <span>VPGA-10</span>
            </div>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.VPGA.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.VPGA.TITLE'|translate">
                help
            </mat-icon>
        </div>
        <div class="card-toolbar">
            <button *ngIf="!readonly" (click)="addVpga10Matter()"
                mat-raised-button
                [matTooltip]="'Add new VPGA'"
                type="button"
                class="m-1 btn"
                style="cursor: pointer !important;">
                <span>Add VPGA-10</span>
            </button>
        </div>
    </div>
    <div class="card-body vpga-body">
        <div class="col-md-12 mat-table-wrapper">
            <table mat-table class="lmat-elevation-z8" [dataSource]="dataSource">
                <!-- <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.type === type.National 
                            ? 'Country' 
                            : (item.type === type.Local
                                ? 'Market / Local'
                                : '')}}
                    </td>
                </ng-container> -->
        
                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef>Source</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.source === source.ToE 
                            ? 'ToE' 
                            : 'New input'}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="input">
                    <th mat-header-cell *matHeaderCellDef>Input</th>
                    <td mat-cell *matCellDef="let item">
                        {{ item.input }}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="input-status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.status === status.Valid 
                            ? 'Valid' 
                            : (item.status === status.Amended
                                ? 'Amended'
                                : 'Removed')}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="justification">
                    <th mat-header-cell *matHeaderCellDef>Justification for deletion or change</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.justification ? item.justification : 'N/A'}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let item">
                        <button [disabled]="readonly" mat-icon-button color="primary" (click)="edit(item)">
                            <mat-icon>create</mat-icon>
                        </button>
                        <button [disabled]="readonly" mat-icon-button *ngIf="item.source === source.ToE && item.status !== status.Valid" (click)="restore(item)">
                            <mat-icon [style.color]="'green'">refresh</mat-icon>
                        </button>
                        <button 
                            [disabled]="readonly"
                            *ngIf="item.status !== status.Removed"
                            mat-icon-button 
                            color="warn"
                            (click)="remove(item)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns; let i = index"
                    [ngClass]="{'colored-row': i%2}"></tr>
            </table>
            <div class="mat-table-message" *ngIf="!dataSource.data.length">
                No data
            </div>
        </div>
    </div>
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">VPGA-10</h5>
    <hr class="active">
</div>

<div class="col-md-12 d-flex justify-content-end mb-4">
    
</div> -->

