// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {RicsStandardsService} from '../_services/rics-standard.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allRicsStandardsLoaded} from '../_selectors/rics-standard.selectors';
// Actions
import {
    AllRicsStandardsLoaded,
    AllRicsStandardsRequested,
    RicsStandardActionTypes,
    RicsStandardsPageRequested,
    RicsStandardsPageLoaded,
    RicsStandardUpdated,
    RicsStandardsPageToggleLoading,
    RicsStandardDeleted,
    RicsStandardOnServerCreated,
    RicsStandardCreated,
    RicsStandardAdminRestored,
    RicsStandardDeletedFromAdminTrash,
    RicsStandardDeletedFromTrash,
    RicsStandardOnServerAdminRestored,
    RicsStandardOnServerRestored,
    RicsStandardRestored,
    RicsStandardTrashFlushed,
    RicsStandardsActionToggleLoading
} from '../_actions/rics-standard.actions';

@Injectable()
export class RicsStandardEffects {
    showPageLoadingDispatcher = new RicsStandardsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RicsStandardsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RicsStandardsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllRicsStandards$ = this.actions$
        .pipe(
            ofType<AllRicsStandardsRequested>(RicsStandardActionTypes.AllRicsStandardsRequested),
            withLatestFrom(this.store.pipe(select(allRicsStandardsLoaded))),
            filter(([action, isAllRicsStandardsLoaded]) => !isAllRicsStandardsLoaded),
            mergeMap(() => this.service.getAllRicsStandards()),
            map(res => {
                return new AllRicsStandardsLoaded({ricsStandards: res.data});
            })
        );

    @Effect()
    loadRicsStandardsPage$ = this.actions$
        .pipe(
            ofType<RicsStandardsPageRequested>(RicsStandardActionTypes.RicsStandardsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findRicsStandards(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new RicsStandardsPageLoaded({
                    ricsStandards: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardDeleted>(RicsStandardActionTypes.RicsStandardDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteRicsStandard(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardUpdated>(RicsStandardActionTypes.RicsStandardUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateRicsStandard(payload.ricsStandard);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardOnServerCreated>(RicsStandardActionTypes.RicsStandardOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createRicsStandard(payload.ricsStandard).pipe(
                    tap(res => {
                        this.store.dispatch(new RicsStandardCreated({ricsStandard: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushRicsStandardTrash$ = this.actions$
        .pipe(
            ofType<RicsStandardTrashFlushed>(RicsStandardActionTypes.RicsStandardTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardOnServerRestored>(RicsStandardActionTypes.RicsStandardOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RicsStandardRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardOnServerAdminRestored>(RicsStandardActionTypes.RicsStandardOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RicsStandardAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardDeletedFromTrash>(RicsStandardActionTypes.RicsStandardDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashRicsStandard$ = this.actions$
        .pipe(
            ofType<RicsStandardDeletedFromAdminTrash>(RicsStandardActionTypes.RicsStandardDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: RicsStandardsService, private store: Store<AppState>) {
    }
}
