import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {StandardMeasurementUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';
import {combineLatest} from 'rxjs'

import {
    StandardMeasurement,
    StandardMeasurementOnServerCreated,
    selectStandardMeasurementsActionLoading,
    selectStandardMeasurementById,
    selectLastCreatedStandardMeasurementId,
    AssetClassType,
    AllAssetClassTypesRequested,
    selectAllAssetClassTypes
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';
import { addBuildingAsAssetClass } from '../lt_utils/add_building_ac';

@Component({
    selector: 'kt-standard-measurement-edit',
    templateUrl: './standard-measurement-edit.component.html',
    styleUrls: ['./standard-measurement-edit.component.scss']
})


export class StandardMeasurementEditComponent implements OnInit, OnDestroy {

    model: StandardMeasurement;
    oldModel: StandardMeasurement;

    standardMeasurementForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    assetClassTypes: AssetClassType[] = [];
    mode = 'NEW';
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    readonly = false
    /**
     * Component constructor
     *
     * @param subheaderService: SubheaderService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param store: Store<AppState>
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private store: Store<AppState>,
                private ref: ChangeDetectorRef,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectStandardMeasurementsActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectStandardMeasurementById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initStandardMeasurement();
                    }
                });
            } else {
                this.model = new StandardMeasurement();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initStandardMeasurement();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../admin-management`},
            {title: 'Linked tables', page: `../admin-management/linked-tables`},
            {title: 'Approaches To Values', page: `../admin-management/linked-tables/standard-measurements`},
            {
                title: this.getComponentTitle(), page: id > 0 ? `../admin-management/linked-tables/standard-measurements/edit/${id}`
                    : `../admin-management/linked-tables/standard-measurements/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initStandardMeasurement() {
        this.createForm();
    }


    createForm() {
        this.standardMeasurementForm = this.fb.group({
            name: [this.model.name, Validators.required],
            asset_class_type_id: [this.model.asset_class_type_id, Validators.required],
        });

        this.store.dispatch(new AllAssetClassTypesRequested());

        const standardMeasurementSubscription = this.store.pipe(
            select(selectAllAssetClassTypes))
            .subscribe(res => {
                this.assetClassTypes = [];
                if (res) {
                    this.assetClassTypes = res.filter(item => item.hide_flag == 0);
                    this.assetClassTypes = addBuildingAsAssetClass(this.assetClassTypes);
                    this.standardMeasurementForm.controls.asset_class_type_id.patchValue('' + this.model.asset_class_type_id);
                    this.ref.markForCheck();
                }
            });

        this.subscriptions.push(standardMeasurementSubscription);
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return this.translate.instant('STANDARD_MEASUREMENT.FORM.TITLE.EDIT', {name: this.model.name});
        }
        return this.translate.instant('STANDARD_MEASUREMENT.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.standardMeasurementForm.controls[controlName];
        return control.invalid && control.touched;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.standardMeasurementForm.markAsPristine();
        this.standardMeasurementForm.markAsUntouched();
        this.standardMeasurementForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.standardMeasurementForm.controls;
        /** check form */
        if (this.standardMeasurementForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        this.model.name = this.standardMeasurementForm.controls.name.value;
        this.model.asset_class_type_id = this.standardMeasurementForm.controls.asset_class_type_id.value;

        if (this.model.id > 0) {
            this.updateStandardMeasurement(this.model);
        } else {
            this.createStandardMeasurement(this.model);
        }
    }

    /**
     * Update model
     *
     * @param _model: StandardMeasurementModel
     */
    updateStandardMeasurement(_model: StandardMeasurement) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateStandardMeasurement: Update<StandardMeasurement> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new StandardMeasurementUpdated({
            partialstandardMeasurement: updateStandardMeasurement,
            standardMeasurement: _model
        }));
        this.model = _model;

        this.navigateToParent();
    }

    /**
     * Create model
     *
     * @param _model: StandardMeasurementModel
     */
    createStandardMeasurement(_model: StandardMeasurement) {
        this.store.dispatch(new StandardMeasurementOnServerCreated({standardMeasurement: _model}));
        this.model = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedStandardMeasurementId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0
            && this.model.asset_class_type_id);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name &&
                this.model.asset_class_type_id != this.oldModel.asset_class_type_id;
        }
        return false;
    }

}
