import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { HouseAdjustmentActions, HouseAdjustmentsActionTypes } from "../_actions/house-adjustment.actions";
import { HouseAdjustmentModel } from "../_models/house-adjustment.model";

export interface HouseAdjustmentsState extends EntityState<HouseAdjustmentModel> {
    isAllAdjustmentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: HouseAdjustmentModel[];
    lastCreatedAdjustmentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<HouseAdjustmentModel> = createEntityAdapter<HouseAdjustmentModel>();

export const initialAdjustmentsState: HouseAdjustmentsState = adapter.getInitialState({
    isAllAdjustmentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAdjustmentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function houseAdjustmentssReducer(state = initialAdjustmentsState, action: HouseAdjustmentActions): HouseAdjustmentsState {
    switch (action.type) {
        case HouseAdjustmentsActionTypes.HouseAdjustmentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdjustmentId: undefined
            };
        case HouseAdjustmentsActionTypes.HouseAdjustmentActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case HouseAdjustmentsActionTypes.HouseAdjustmentOnServerCreated:
            return {
                ...state
            };
        case HouseAdjustmentsActionTypes.HouseAdjustmentCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAdjustmentsId: action.payload.item.id
            });
        case HouseAdjustmentsActionTypes.HouseAdjustmentUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case HouseAdjustmentsActionTypes.AllHouseAdjustmentsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllAdjustmentssLoaded: true
            });
        case HouseAdjustmentsActionTypes.HouseAdjustmentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case HouseAdjustmentsActionTypes.HouseAdjustmentsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialAdjustmentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case HouseAdjustmentsActionTypes.HouseAdjustmentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case HouseAdjustmentsActionTypes.HouseAdjustmentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case HouseAdjustmentsActionTypes.HouseAdjustmentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case HouseAdjustmentsActionTypes.HouseAdjustmentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case HouseAdjustmentsActionTypes.HouseAdjustmentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case HouseAdjustmentsActionTypes.HouseAdjustmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();