import {BuildingUseStatus} from '../_models/building-use-status.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {BuildingUseStatussState} from '../_reducers/building-use-status.reducers';
import * as fromBuildingUseStatus from '../_reducers/building-use-status.reducers';
import {each} from 'lodash';

export const selectBuildingUseStatussState = createFeatureSelector<BuildingUseStatussState>('buildingUseStatuss');

export const selectBuildingUseStatusById = (buildingUseStatusId: number) => createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => buildingUseStatussState.entities[buildingUseStatusId]
);

export const selectAllBuildingUseStatuss = createSelector(
    selectBuildingUseStatussState,
    fromBuildingUseStatus.selectAll
);

export const selectAllBuildingUseStatussIds = createSelector(
    selectBuildingUseStatussState,
    fromBuildingUseStatus.selectIds
);

export const allBuildingUseStatussLoaded = createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => buildingUseStatussState.isAllBuildingUseStatussLoaded
);


export const selectBuildingUseStatussPageLoading = createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => buildingUseStatussState.listLoading
);

export const selectBuildingUseStatussActionLoading = createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => buildingUseStatussState.actionLoading
);

export const selectLastCreatedBuildingUseStatusId = createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => buildingUseStatussState.lastCreatedBuildingUseStatusId
);

export const selectBuildingUseStatussShowInitWaitingMessage = createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => buildingUseStatussState.showInitWaitingMessage
);

export const selectTrashedBuildingUseStatusCount = createSelector(
    selectBuildingUseStatussState,
    (buildingUseStatussState) => buildingUseStatussState.totalTrashed
);

export const selectAdminTrashedBuildingUseStatusCount = createSelector(
    selectBuildingUseStatussState,
    (buildingUseStatussState) => buildingUseStatussState.totalAdminTrashed
);


export const selectBuildingUseStatusQueryResult = createSelector(
    selectBuildingUseStatussState,
    buildingUseStatussState => {
        const items: BuildingUseStatus[] = [];
        each(buildingUseStatussState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BuildingUseStatus[] = httpExtension.sortArray(items, buildingUseStatussState.lastQuery.sortField, buildingUseStatussState.lastQuery.sortOrder);

        return new QueryResultsModel(result, buildingUseStatussState.totalCount, '', buildingUseStatussState.totalTrashed);
    }
);
