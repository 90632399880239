import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassRetailsService} from '../_services/asset-class-retail.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AssetClassRetailActionTypes,
    AssetClassRetailsPageRequested,
    AssetClassRetailsPageLoaded,
    AssetClassRetailActionToggleLoading,
    AssetClassRetailsPageToggleLoading,
    AssetClassRetailOnServerCreated,
    AssetClassRetailOnServerUpdated,
    AssetClassRetailCreated,
    AssetClassRetailUpdated,
    AssetClassRetailsTrashRequested,
    AssetClassRetailRestored,
    AssetClassRetailOnServerRestored,
    AssetClassRetailDeletedFromTrash,
    OneAssetClassRetailDeleted,
    AssetClassRetailGet,
    AssetClassRetailMapPageRequested
} from '../_actions/asset-class-retail.actions';
import {of} from 'rxjs';
import {RetailTrashFlushed} from '..';

@Injectable()
export class AssetClassRetailEffects {
    showPageLoadingDispatcher = new AssetClassRetailsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassRetailActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassRetailActionToggleLoading({isLoading: false});

    @Effect()
    loadAssetClassRetailsPage$ = this.actions$.pipe(
        ofType<AssetClassRetailsPageRequested>(AssetClassRetailActionTypes.AssetClassRetailsPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.findAssetClassRetails(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassRetailsPageLoaded({
                assetClassRetails: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadAssetClassMapPage$ = this.actions$.pipe(
        ofType<AssetClassRetailMapPageRequested>(AssetClassRetailActionTypes.AssetClassRetailMapPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getApartments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassRetailsPageLoaded({
                assetClassRetails: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createAssetClassRetail$ = this.actions$.pipe(
        ofType<AssetClassRetailOnServerCreated>(AssetClassRetailActionTypes.AssetClassRetailOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClassRetail(
                    payload.assetClassRetail,
                    payload.tenures,
                    payload.sizes,
                    payload.fileList,
                    payload.selectedFacilities,
                    payload.selectedFacilitiesExclusive,
                    payload.officeExternalAreas
                ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailCreated({assetClassRetail: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updateAssetClassRetail$ = this.actions$
        .pipe(
            ofType<AssetClassRetailOnServerUpdated>(AssetClassRetailActionTypes.AssetClassRetailOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAssetClassRetail(
                        payload.assetClassRetail,
                        payload.tenures,
                        payload.sizes,
                        payload.fileList,
                        payload.selectedFacilities,
                        payload.selectedFacilitiesExclusive,
                        payload.officeExternalAreas
                    ).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassRetailUpdated({assetClassRetail: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    trashAssetClassRetails$ = this.actions$
        .pipe(
            ofType<AssetClassRetailsTrashRequested>(AssetClassRetailActionTypes.AssetClassRetailTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getTrashedAssetClassRetails();
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassRetail$ = this.actions$
        .pipe(
            ofType<AssetClassRetailOnServerRestored>(AssetClassRetailActionTypes.AssetClassRetailOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassRetailRestored({assetClassRetail: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassRetailFromTrash$ = this.actions$
        .pipe(
            ofType<AssetClassRetailDeletedFromTrash>(AssetClassRetailActionTypes.AssetClassRetailDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.assetClassRetailId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassRetail$ = this.actions$
        .pipe(
            ofType<OneAssetClassRetailDeleted>(AssetClassRetailActionTypes.OneAssetClassRetailDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAssetClassRetail(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushApartmentTrash$ = this.actions$
        .pipe(
            ofType<RetailTrashFlushed>(AssetClassRetailActionTypes.RetailTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    getAssetClassRetail$ = this.actions$
        .pipe(
            ofType<AssetClassRetailGet>(AssetClassRetailActionTypes.AssetClassRetailGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getAssetClassRetail(payload.assetClassRetailId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(
        private actions$: Actions,
        private service: AssetClassRetailsService,
        private store: Store<AppState>
    ) { }
}
