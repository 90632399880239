// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const RESIDENTIALS_PATH = '/facilities';
const RESIDENTIALS_BUILDING_PATH = '/building/facilities';

@Injectable()
export class AssetClassResidentialFacilitiesService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/residentials/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAllAssetClassResidentialFacilities(assetClassResidentialFacilityId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + assetClassResidentialFacilityId + RESIDENTIALS_PATH;
        return this.http.get<any>(url, {
            headers: httpHeaders
        });
    }

    getAllAssetClassResidentialBuildingFacilities(assetClassResidentialFacilityId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + assetClassResidentialFacilityId + RESIDENTIALS_BUILDING_PATH;
        return this.http.get<any>(url, {
            headers: httpHeaders
        });
    }
}
