<div class="card">
    <div class="card-header info-header" style="background-color: #000000;">
        <span>Condition Ratings</span>
    </div>
    <div class="card-body info-body" style="background-color: #cccccc;">
        <kt-condition-rating-table-v2
            *ngIf="hasComponents"
            parent="background"
            [componentDataSource$]="components$"
            [conditionRatingDataSource$]="conditionRatings$"
            [schemeId$]="schemeId$"
            [editable]="false"
            [useMatDialog]="useMatDialog">
        </kt-condition-rating-table-v2>
        <div *ngIf="!hasComponents">
            <kt-condition-rating-documents 
                [parent]="'valuation'"
                [useMatDialog]="useMatDialog"
                [files]="tpDetails.condition_rating_documents" 
                [readonly]="true"></kt-condition-rating-documents>
        </div>
    </div>
</div>