// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SubCategoryService} from '../_services/sub-category.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSubCategorysLoaded} from '../_selectors/sub-category.selectors';
// Actions
import {
    AllSubCategorysLoaded,
    AllSubCategorysRequested,
    SubCategoryActionTypes,
    SubCategorysPageRequested,
    SubCategorysPageLoaded,
    SubCategoryUpdated,
    SubCategorysPageToggleLoading,
    SubCategoryDeleted,
    SubCategoryOnServerCreated,
    SubCategoryCreated,
    SubCategoryActionToggleLoading, SubCategoryTrashFlushed, SubCategoryOnServerRestored, SubCategoryRestored,
    SubCategoryOnServerAdminRestored, SubCategoryAdminRestored, SubCategoryDeletedFromTrash, SubCategoryDeletedFromAdminTrash
} from '../_actions/sub-category.actions';

@Injectable()
export class SubCategoryEffects {
    showPageLoadingDispatcher = new SubCategorysPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SubCategoryActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SubCategoryActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSubCategory$ = this.actions$
        .pipe(
            ofType<AllSubCategorysRequested>(SubCategoryActionTypes.AllSubCategorysRequested),
            withLatestFrom(this.store.pipe(select(allSubCategorysLoaded))),
            filter(([action, isAllSubCategoryLoaded]) => !isAllSubCategoryLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllSubCategorysLoaded({items: res.data});
            })
        );

    @Effect()
    loadSubCategoryPage$ = this.actions$
        .pipe(
            ofType<SubCategorysPageRequested>(SubCategoryActionTypes.SubCategorysPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SubCategorysPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryUpdated>(SubCategoryActionTypes.SubCategoryUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryOnServerCreated>(SubCategoryActionTypes.SubCategoryOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new SubCategoryCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushSubCategoryTrash$ = this.actions$
        .pipe(
            ofType<SubCategoryTrashFlushed>(SubCategoryActionTypes.SubCategoryTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryOnServerRestored>(SubCategoryActionTypes.SubCategoryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SubCategoryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryOnServerAdminRestored>(SubCategoryActionTypes.SubCategoryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SubCategoryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryDeleted>(SubCategoryActionTypes.SubCategoryDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryDeletedFromTrash>(SubCategoryActionTypes.SubCategoryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSubCategory$ = this.actions$
        .pipe(
            ofType<SubCategoryDeletedFromAdminTrash>(SubCategoryActionTypes.SubCategoryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: SubCategoryService, private store: Store<AppState>) {
    }
}
