// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {CitiesState} from '../_reducers/city.reducers';
import * as fromCities from '../_reducers/city.reducers';

export const selectCitiesState = createFeatureSelector<CitiesState>('cities');

export const selectCityById = (cityId: number) => createSelector(
    selectCitiesState,
    cs => cs.entities[cityId]
);

export const selectAllCities = createSelector(
    selectCitiesState,
    fromCities.selectAll
);

export const selectAllCitiesIds = createSelector(
    selectCitiesState,
    entities => fromCities.selectIds
);

export const allCitiesLoaded = createSelector(
    selectCitiesState,
    cs => cs._isAllCitiesLoaded
);


export const selectLastCreatedCityId = createSelector(
    selectCitiesState,
    citiesState => citiesState.lastCreatedCityId
);

export const selectCitiesShowInitWaitingMessage = createSelector(
    selectCitiesState,
    citiesState => citiesState.showInitWaitingMessage
);


export const selectCitiesActionLoading = createSelector(
    selectCitiesState,
    citiesState => citiesState.actionsLoading
);


export const selectCitiesByCountryId = (countryId: number) => createSelector(
    selectAllCities,
    (cities) => cities.filter(city => city.country_id == countryId && city.deleted_at == null && city.user_deleted == null)
);

export const selectTrashedCitiesByCountryId = (countryId: number) => createSelector(
    selectAllCities,
    (cities) => cities.filter(city => city.country_id == countryId && city.user_deleted && city.deleted_at == null)
);

export const selectAdminTrashedCitiesByCountryId = (countryId: number) => createSelector(
    selectAllCities,
    (cities) => cities.filter(city => city.country_id == countryId && city.user_deleted && city.deleted_at)
);

export const selectTrashedCities = createSelector(
    selectAllCities,
    (cities) => cities.filter(city => city.user_deleted && city.deleted_at == null)
);

export const selectAdminTrashedCities = createSelector(
    selectAllCities,
    (cities) => cities.filter(city => city.deleted_at)
);

export const selectCities = createSelector(
    selectAllCities,
    (cities) => cities.filter(city => city.deleted_at == null && city.user_deleted == null)
);

