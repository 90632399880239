import { Component, Input, OnInit, ViewChild } from '@angular/core';

type ChecklistRowItem = {
  id: number;
  name: string;
  order: number;
  cu_val: boolean;
  cu_desc: string;
  es_val: boolean;
  es_desc: string;
}

@Component({
  selector: 'kt-checklist-table',
  templateUrl: './checklist-table.component.html',
  styleUrls: ['./checklist-table.component.scss']
})
export class ChecklistTableComponent implements OnInit {
  @Input() inputData: any[];

  data: ChecklistRowItem[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.inputData.length == 0) {
      this.data = [
        {id: undefined, order: 1, name: 'Industry / Manufacturing?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 2, name: 'Waste management?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 3, name: 'Mineral extraction?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 4, name: 'Vehicule maintenance or refuelling', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 5, name: 'Brownfield?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 6, name: 'Is the property near the water?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 7, name: 'Is the property in a hollow or at the bottom of a hill where flood water could collect?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 8, name: 'Are you aware of any flood events affecting the property or immediate area?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 9, name: 'Is the property protected by flood defences?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 10, name: 'Observable evidence of flooding?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
        {id: undefined, order: 11, name: 'Have you obtained flood data specific to the property?', cu_val: false, cu_desc: '', es_val: false, es_desc: '' },
      ]
    } else {
      this.data = this._deepCopy(this.inputData);
    }
  }

  private _deepCopy(input: any[]): any[] {
    const arr = [];
    input.forEach(item => {
      arr.push(Object.assign({}, item));
    })
    arr.sort((a, b) => (a.order - b.order));
    return arr;
  }

  getData() {
    return this.data
  }
}
