// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {SourceExternalReference} from '../_models/source-external-reference.model';

export enum SourceExternalReferenceActionTypes {

    AllSourceExternalReferencesRequested = '[SourceExternalReferences Home Page] All SourceExternalReferences Requested',
    AllSourceExternalReferencesLoaded = '[SourceExternalReferences API] All SourceExternalReferences Loaded',

    SourceExternalReferenceOnServerCreated = '[Edit SourceExternalReference Dialog] SourceExternalReference On Server Created',
    SourceExternalReferenceCreated = '[Edit SourceExternalReferences Dialog] SourceExternalReferences Created',
    SourceExternalReferenceUpdated = '[Edit SourceExternalReference Dialog] SourceExternalReference Updated',
    SourceExternalReferenceDeleted = '[SourceExternalReferences List Page] SourceExternalReference Deleted',

    SourceExternalReferencesPageRequested = '[SourceExternalReferences List Page] SourceExternalReferences Page Requested',
    SourceExternalReferencesPageLoaded = '[SourceExternalReferences API] SourceExternalReferences Page Loaded',
    SourceExternalReferencesPageCancelled = '[SourceExternalReferences API] SourceExternalReferences Page Cancelled',

    SourceExternalReferencesPageToggleLoading = '[SourceExternalReferences page] SourceExternalReferences Page Toggle Loading',
    SourceExternalReferencesActionToggleLoading = '[SourceExternalReferences] SourceExternalReferences Action Toggle Loading',

    // trash system

    SourceExternalReferenceOnServerRestored = '[SourceExternalReference Trash] SourceExternalReference On Server Restored',
    SourceExternalReferenceRestored = '[SourceExternalReference Trash] SourceExternalReference Restored',

    SourceExternalReferenceOnServerAdminRestored = '[SourceExternalReference Admin Trash] SourceExternalReference On Server Restored',
    SourceExternalReferenceAdminRestored = '[SourceExternalReference Admin Trash] SourceExternalReference Restored',

    SourceExternalReferenceDeletedFromTrash = '[SourceExternalReference Trash] SourceExternalReference deleted',
    SourceExternalReferenceDeletedFromAdminTrash = '[SourceExternalReference Admin Trash] SourceExternalReference deleted',

    SourceExternalReferenceTrash = 'SourceExternalReference Trashed',
    SourceExternalReferenceTrashFlushed = 'SourceExternalReference Trash Flushed',
}

export class SourceExternalReferenceOnServerCreated implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerCreated;

    constructor(public payload: { item: SourceExternalReference}) {
    }
}

export class SourceExternalReferenceCreated implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceCreated;

    constructor(public payload: { item: SourceExternalReference }) {
    }
}

export class SourceExternalReferenceUpdated implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceUpdated;

    constructor(public payload: {
        partialItem: Update<SourceExternalReference>,
        item: SourceExternalReference
    }) {
    }
}

export class SourceExternalReferenceDeleted implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SourceExternalReferencesPageRequested implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferencesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SourceExternalReferencesPageLoaded implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferencesPageLoaded;

    constructor(public payload: { sourceExternalReferences: SourceExternalReference[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SourceExternalReferencesPageCancelled implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferencesPageCancelled;
}

export class AllSourceExternalReferencesRequested implements Action {
    readonly type = SourceExternalReferenceActionTypes.AllSourceExternalReferencesRequested;
}

export class AllSourceExternalReferencesLoaded implements Action {
    readonly type = SourceExternalReferenceActionTypes.AllSourceExternalReferencesLoaded;

    constructor(public payload: { sourceExternalReferences: SourceExternalReference[] }) {
    }
}

export class SourceExternalReferencesPageToggleLoading implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferencesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SourceExternalReferencesActionToggleLoading implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferencesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class SourceExternalReferenceDeletedFromAdminTrash implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class SourceExternalReferenceDeletedFromTrash implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SourceExternalReferenceOnServerRestored implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceExternalReferenceRestored implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceRestored;

    constructor(public payload: { item: SourceExternalReference }) {
    }
}

export class SourceExternalReferenceOnServerAdminRestored implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceExternalReferenceAdminRestored implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceAdminRestored;

    constructor(public payload: { item: SourceExternalReference }) {
    }
}

export class SourceExternalReferenceTrashFlushed implements Action {
    readonly type = SourceExternalReferenceActionTypes.SourceExternalReferenceTrashFlushed;

    constructor() {
    }
}

export type SourceExternalReferenceActions = SourceExternalReferenceCreated
    | SourceExternalReferenceUpdated
    | SourceExternalReferenceDeleted
    | SourceExternalReferencesPageRequested
    | SourceExternalReferencesPageLoaded
    | SourceExternalReferencesPageCancelled
    | AllSourceExternalReferencesLoaded
    | AllSourceExternalReferencesRequested
    | SourceExternalReferenceOnServerCreated
    | SourceExternalReferencesPageToggleLoading
    | SourceExternalReferencesActionToggleLoading
    | SourceExternalReferenceDeletedFromAdminTrash
    | SourceExternalReferenceDeletedFromTrash
    | SourceExternalReferenceOnServerRestored
    | SourceExternalReferenceRestored
    | SourceExternalReferenceOnServerAdminRestored
    | SourceExternalReferenceAdminRestored
    | SourceExternalReferenceTrashFlushed;
