import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassLandActions, AssetClassLandActionTypes } from "../_actions/asset-class-land.actions";
import { AssetClassLandModel } from "../_models/asset-class-land.model";

export interface AssetClassLandState extends EntityState<AssetClassLandModel> {
    actionLoading: boolean;
    listLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    previouslyCreatedAssetClassResidentialId: number;
    lastCreatedAssetClassLandId: number;
    showInitWaitingMessage: boolean;
}
export const adapter: EntityAdapter<AssetClassLandModel> = createEntityAdapter<AssetClassLandModel>();
export const initialAssetClassLandState: AssetClassLandState = adapter.getInitialState({
    actionLoading: false,
    listLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    previouslyCreatedAssetClassResidentialId: undefined,
    lastCreatedAssetClassLandId: undefined,
    showInitWaitingMessage: true
});

export function assetClassLandReducer(
    state = initialAssetClassLandState,
    action: AssetClassLandActions
): AssetClassLandState {
    switch (action.type) {
        case AssetClassLandActionTypes.UpdatePreviouslyCreatedAssetClassLandId: {
            return {
                ...state,
                previouslyCreatedAssetClassResidentialId: state.lastCreatedAssetClassLandId
            }
        }
        case AssetClassLandActionTypes.AssetClassLandPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassLandId: undefined
            }
        }
        case AssetClassLandActionTypes.AssetClassLandActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            }
        }
        case AssetClassLandActionTypes.AssetClassLandOnServerCreated: {
            return state;
        }
        case AssetClassLandActionTypes.AssetClassLandCreated: {
            return adapter.addOne(
                action.payload.assetClassLand,
                {...state, lastCreatedAssetClassLandId: action.payload.assetClassLand.id, previouslyCreatedAssetClassResidentialId: state.lastCreatedAssetClassLandId}
            )
        }
        case AssetClassLandActionTypes.AssetClassLandUpdated: {
            return adapter.addOne(
                action.payload.assetClassLand,
                {...state, lastCreatedAssetClassLandId: action.payload.assetClassLand.id}
            )
        }
        case AssetClassLandActionTypes.AssetClassLandPageLoaded: {
            return adapter.addMany(action.payload.assetClassLands, {
                ...initialAssetClassLandState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            })
        }
        case AssetClassLandActionTypes.AssetClassLandDeleted: {
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            })
        }
        case AssetClassLandActionTypes.AssetClassLandDeletedFromTrash: {
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            }
        }
        case AssetClassLandActionTypes.AssetClassLandDeletedFromAdminTrash: {
            return {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            }
        }
        case AssetClassLandActionTypes.AssetClassLandOnServerRestored: {
            return state
        }
        case AssetClassLandActionTypes.AssetClassLandRestored: {
            return adapter.addOne(action.payload.acLand, {
                ...state,
                lastCreatedAssetClassLandId: action.payload.acLand.id,
                totalTrashed: state.totalTrashed - 1
            })
        }
        case AssetClassLandActionTypes.AssetClassLandOnServerAdminRestored: {
            return state
        }
        case AssetClassLandActionTypes.AssetClassLandAdminRestored: {
            return adapter.addOne(action.payload.acLand, {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            })
        }
        default: {
            return state;
        }
    }
}