<div class="d-flex flex-column mb-4" *ngIf="loading$|async">
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<span *ngIf="title">{{title}}</span>
		</div>
		<div class="card-toolbar">
			<div class="d-flex justify-content-end">
				<button mat-raised-button color="secondary" type="button" class="btn btn-secondary mr-4"
					(click)="recordPayment(null)" *ngIf="showAddNewPaymentBtn$|async">
					<mat-icon style="padding-top: 3px;">credit_card</mat-icon>
					Record Payment
				</button>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="mat-table-wrapper">
			<mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="payment_date">
					<mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.payment_date  | date: 'dd MMM yyy'}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="invoice_number">
					<mat-header-cell *matHeaderCellDef>Invoice</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{'Invoice ' + getTaskName(item.invoice, toe['invoices'])}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="invoice_amount">
					<mat-header-cell *matHeaderCellDef>Amount due (incl. taxes) {{'(' + currency + ')'}}
					</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{getAmountWithTax(item.invoice) | number: '1.2-2'}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="amount">
					<mat-header-cell *matHeaderCellDef>Transferred amount (incl. taxes) {{'(' + currency + ')'}}
					</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.amount | number: '1.2-2'}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="payment_type_id">
					<mat-header-cell *matHeaderCellDef>Payment type</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.payment_type.name}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<span *ngIf="item.taskActions.canBeDeleted"
							[matTooltip]="item.taskActions.canBeEdited ? 'Edit' : 'Cannot edit'">
							<button mat-icon-button color="primary" matTooltip="Edit Information of the Task"
								(click)="recordPayment(item)" type="button" *ngIf="item.taskActions.canBeEdited">
								<mat-icon>create</mat-icon>
							</button>
						</span>
						<span *ngIf="item.taskActions.canBeDeleted"
							[matTooltip]="item.taskActions.canBeUserDeleted ? 'Delete' : 'Cannot delete'">
							<button mat-icon-button color="warn" matTooltip="Delete" (click)="deletePayment(item)"
								type="button" [disabled]="!item.taskActions.canBeUserDeleted">
								<mat-icon>delete</mat-icon>
							</button>
						</span>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="columns" [style.backgroundColor]="'#f39aa3'">
				</mat-header-row>
				<mat-row *matRowDef="let row; columns: columns"></mat-row>
			</mat-table>
			<div class="row mt-8 mx-0 px-0">
				<div class="col-lg-6"></div>
				<div class="col-lg-6 mx-0 px-0">
					<mat-table #table [dataSource]="dataSourceSettlement">
						<ng-container matColumnDef="label">
							<!-- <mat-header-cell *matHeaderCellDef> Valuations</mat-header-cell> -->
							<mat-cell *matCellDef="let item">
								<span [style]="'font-weight: bold; color: '+item.color">{{item.label}} {{'(' + currency
									+ ')'}}</span>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="value">
							<!-- <mat-header-cell *matHeaderCellDef> Val Fees</mat-header-cell> -->
							<mat-cell *matCellDef="let item">
								<span [style]="'color: '+item.color">{{item.value | number: '1.2-2'}}</span>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="action">
							<!-- <mat-header-cell *matHeaderCellDef></mat-header-cell> -->
							<mat-cell *matCellDef="let item">
								<button mat-icon-button color="primary" matTooltip="Edit Information of the Task"
									(click)="editSEttlement(item)" type="button" *ngIf="item.edit">
									<mat-icon>create</mat-icon>
								</button>
							</mat-cell>
						</ng-container>
						<!-- <mat-header-row *matHeaderRowDef="settlementColumns"></mat-header-row> -->
						<mat-row *matRowDef="let row; columns: settlementColumns;"></mat-row>
					</mat-table>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #taskCompStat let-stat="stat" let-difference_amount="difference_amount">
	<div class="d-flex justify-content-center align-items-center">
		<div *ngIf="stat.status == status.Upcoming">
			<span style="color: #000000; font-weight: bold; margin-left: 10px;">{{stat.invoice_status}}</span>
		</div>
		<div *ngIf="stat.status == status.Voided">
			<span style="color: #884FFC; font-weight: bold; margin-left: 10px;">{{stat.invoice_status}}</span>
		</div>
		<div *ngIf="stat.status == status.Overdue">
			<span style="color: #F44D5E; font-weight: bold; margin-left: 10px;">{{'Due'}}</span>
		</div>
		<div *ngIf="stat.status == status.Completed">
			<span style="color: #16C4BB; font-weight: bold; margin-left: 10px;">{{stat.invoice_status}}</span>
		</div>
		<div *ngIf="stat.status == status.Overpaid">
			<span style="color: #3797ff; font-weight: bold; margin-left: 10px;">{{stat.invoice_status + ' (' +
				(difference_amount | number: '1.2-2') + ' ' + currency + ' owed to client)'}}</span>
		</div>
		<div *ngIf="stat.status == status.PartiallyPaid">
			<span style="color: #FDA701; font-weight: bold; margin-left: 10px;">{{stat.invoice_status + ' (' +
				(difference_amount | number: '1.2-2') + ' ' + currency + ' due by client)'}}</span>
		</div>
	</div>
</ng-template>
<!-- TODO: refactor -->
<ng-template #taskCompStat1 let-stat="stat" let-date="date">
	<div class="d-flex justify-content-center align-items-center">
		<div *ngIf="stat.status == status.Upcoming">
			<span style="color: #000000; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Voided">
			<span style="color: #884FFC; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Overdue">
			<span style="color: #F44D5E; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Completed">
			<span style="color: #16C4BB; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.Overpaid">
			<span style="color: #3797ff; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
		<div *ngIf="stat.status == status.PartiallyPaid">
			<span style="color: #FDA701; font-weight: bold; margin-left: 10px;">{{date}}</span>
		</div>
	</div>
</ng-template>