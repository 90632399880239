import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogisticParticipant, LogisticsService } from '../../logistics.service';

@Component({
  selector: 'kt-logisctics-participant-edit',
  templateUrl: './logisctics-participant-edit.component.html',
  styleUrls: ['./logisctics-participant-edit.component.scss']
})
export class LogiscticsParticipantEditComponent implements OnInit {

  form = new FormGroup({
    side: new FormControl(null, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    descr: new FormControl('')
  })

  constructor(
    public dialogRef: MatDialogRef<LogiscticsParticipantEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: LogisticsService
  ) { 
    if (data.participant) {
      this.form.controls.side.patchValue(data.participant.type)
      this.form.controls.name.patchValue(data.participant.name)
      this.form.controls.descr.patchValue(data.participant.descr)
    }
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close()
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(controlName => this.form.controls[controlName].markAsTouched())
      return;
    }

    const participant: LogisticParticipant = this.data.participant 
      ? {
        ...this.data.participant,
        name: this.form.controls.name.value,
        type: this.form.controls.side.value,
        descr: this.form.controls.descr.value
      }
      : {
          id: undefined, 
          name: this.form.controls.name.value,
          type: this.form.controls.side.value,
          descr: this.form.controls.descr.value,
          is_main: false,
          included: false
        }

    this.dialogRef.close({
      participant
    })
  }
}
