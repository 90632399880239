import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { ResidentialAdjustmentActions, ResidentialAdjustmentsActionTypes } from "../_actions/residential-adjustments.actions";
import { ResidentialAdjustmentModel } from "../_models/residential-adjustment.model";

export interface ResidentialAdjustmentsState extends EntityState<ResidentialAdjustmentModel> {
    isAllAdjustmentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ResidentialAdjustmentModel[];
    lastCreatedAdjustmentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ResidentialAdjustmentModel> = createEntityAdapter<ResidentialAdjustmentModel>();

export const initialAdjustmentsState: ResidentialAdjustmentsState = adapter.getInitialState({
    isAllAdjustmentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAdjustmentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function residentialAdjustmentssReducer(state = initialAdjustmentsState, action: ResidentialAdjustmentActions): ResidentialAdjustmentsState {
    switch (action.type) {
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdjustmentId: undefined
            };
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentOnServerCreated:
            return {
                ...state
            };
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAdjustmentsId: action.payload.item.id
            });
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ResidentialAdjustmentsActionTypes.AllResidentialAdjustmentsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllAdjustmentssLoaded: true
            });
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialAdjustmentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ResidentialAdjustmentsActionTypes.ResidentialAdjustmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();