// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SubTypeCategoryActions, SubTypeCategoryActionTypes} from '../_actions/sub-type-category.actions';
// Models
import {SubTypeCategoryModel} from '../_models/sub-type-category.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SubTypeCategorysState extends EntityState<SubTypeCategoryModel> {
    isAllSubTypeCategorysLoaded: boolean;
    queryRowsCount: number;
    queryResult: SubTypeCategoryModel[];
    lastCreatedSubTypeCategoryId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SubTypeCategoryModel> = createEntityAdapter<SubTypeCategoryModel>();

export const initialSubTypeCategoryState: SubTypeCategorysState = adapter.getInitialState({
    isAllSubTypeCategorysLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSubTypeCategoryId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function subTypeCategoryReducer(state = initialSubTypeCategoryState, action: SubTypeCategoryActions): SubTypeCategorysState {
    switch (action.type) {
        case SubTypeCategoryActionTypes.SubTypeCategorysPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSubTypeCategoryId: undefined
            };
        case SubTypeCategoryActionTypes.SubTypeCategoryActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SubTypeCategoryActionTypes.SubTypeCategoryOnServerCreated:
            return {
                ...state
            };
        case SubTypeCategoryActionTypes.SubTypeCategoryCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedSubTypeCategoryId: action.payload.item.id
            });
        case SubTypeCategoryActionTypes.SubTypeCategoryUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case SubTypeCategoryActionTypes.AllSubTypeCategorysLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllSubTypeCategorysLoaded: true
            });
        case SubTypeCategoryActionTypes.SubTypeCategorysPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SubTypeCategoryActionTypes.SubTypeCategorysPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialSubTypeCategoryState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case SubTypeCategoryActionTypes.SubTypeCategoryDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SubTypeCategoryActionTypes.SubTypeCategoryRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SubTypeCategoryActionTypes.SubTypeCategoryDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SubTypeCategoryActionTypes.SubTypeCategoryAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SubTypeCategoryActionTypes.SubTypeCategoryDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SubTypeCategoryActionTypes.SubTypeCategoryTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
