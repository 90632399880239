import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { ValuationProcessAddAdjustments, ValuationProcessAdjustmentUpdateValue, ValuationProcessDeleteAdjustment, ValuationProcessEditAdjustment, ValuationProcessLoadAdjustments } from 'src/app/core/valuation-process/_actions/adjustment.actions';
import { AdjustmentModel } from 'src/app/core/valuation-process/_models/valuation-process-adjustment.model';
import { selectValuationProcessSelectedComparables, selectValuationProcessTargetPropertyInfo } from 'src/app/core/valuation-process/_selectors';
import { selectValuationProcessAdjustments } from 'src/app/core/valuation-process/_selectors/adjustment.selectors';
import { AdjustmentTemplateModalComponent } from 'src/app/views/pages/template/templates/adjustment-template/_sub/adjustment-template-modal/adjustment-template-modal.component';
import { AdjustmentTemplateListComponent } from '../../../valuation-process-edit/_sub/adjustment-tab/_modals/adjustment-template-list/adjustment-template-list.component';
import { AddAdjustmentsModalV2Component } from '../../_sub/add-adjustments-modal-v2/add-adjustments-modal-v2.component';
import { EditAdjustmentModalV2Component } from '../../_sub/edit-adjustment-modal-v2/edit-adjustment-modal-v2.component';

@Component({
  selector: 'kt-adjustments-table-v2',
  templateUrl: './adjustments-table-v2.component.html',
  styleUrls: ['./adjustments-table-v2.component.scss']
})
export class AdjustmentsTableV2Component implements OnInit {
  @Output() removeComparable: EventEmitter<{ refNum: string }> = new EventEmitter();
  @Output() showComparable: EventEmitter<{ refNum: string }> = new EventEmitter();

  viewModel$ = combineLatest([
    this.store$.select(selectValuationProcessSelectedComparables),
    this.store$.select(selectValuationProcessAdjustments),
    this.store$.select(selectValuationProcessTargetPropertyInfo)
  ]).pipe(map(([refNums, adjustments, tp]) => {
    const { headerColumns, displayedColumns } = this._setHeaders(refNums)
    const dataSource = new MatTableDataSource()
    dataSource.data = adjustments
    const selectedIds = adjustments.map(ad => ad.adjustmentId)
    return {
      refNums,
      dataSource,
      headerColumns,
      displayedColumns,
      selectedIds,
      propertySubTypeId: tp.generalInfo.propertySubTypeId
    }
  }))

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onShowComparable(refNum: string) {
    this.showComparable.emit({ refNum })
  }

  onRemoveComparable(refNum: string) {
    this.removeComparable.emit({ refNum })
  }

  onAddAdjustment(refNums: string[], selectedIds: number[], propertySubTypeId: number) {
    const dialogRef = this.dialog.open(AddAdjustmentsModalV2Component, {
      data: {
        selectedIds,
        propertySubTypeId
      },
      width: '80vw'
    })
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.store$.dispatch(new ValuationProcessAddAdjustments({ refNums, adjustments: res }))
    })
  }

  onAddAdjustmentFromTemplate(propertySubTypeId: number, refNums: string[]) {
    const dialogRef = this.dialog.open(AdjustmentTemplateListComponent, {
      data: {
        assetClassTypeId: propertySubTypeId
      }
    })
    dialogRef.afterClosed().subscribe(
      res => {
        if (!res) {
          return;
        }
        switch (res.type) {
          case 'select':
            const adjustments = res.template.adjustments.map(item => {
              const values: {[key: string]: number} = {}
              refNums.forEach(refNum => {
                values[refNum] = 0
              })
              const adjustment: AdjustmentModel = {
                id: 0,
                adjustmentId: item.adjustment_id,
                description: item.description,
                name: item.name,
                analysis: null,
                values: values
              }
                return adjustment
            })
            this.store$.dispatch(new ValuationProcessLoadAdjustments({adjustments}));
            break;
          case 'view':
            this.dialog.open(AdjustmentTemplateModalComponent, {
              data: res.template
            })
            break;
          default: 
            break;
        }
      }
    )
  }
  onFocusOutAnalysis(e: FocusEvent, adjustment: AdjustmentModel) {
      const target = e.target as HTMLInputElement;
      const val = target.value;
      this.store$.dispatch(new ValuationProcessEditAdjustment({
        id: adjustment.id,
        changes: {
          name: adjustment.name,
          description: adjustment.description,
          adjustmentId: adjustment.adjustmentId,
          analysis: val 
        }
      }))
  }

  textAreaAdjustOnKeyUp(e: KeyboardEvent) {
      const element = e.target as HTMLTextAreaElement;
      element.style.height = "1px";
      element.style.height = (10 + element.scrollHeight) + "px";
  }

  onPercentChange(value: number, item: AdjustmentModel, refNum: string) {
    this.store$.dispatch(new ValuationProcessAdjustmentUpdateValue({refNum, value, item}))
  }

  onEditAdjustment(item: AdjustmentModel, propertySubTypeId: number) { 
    const dialogRef = this.dialog.open(EditAdjustmentModalV2Component, {
      data: {
        propertySubTypeId: propertySubTypeId,
        item: item
      },
      width: '80vw'
    })
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }
      this.store$.dispatch(new ValuationProcessEditAdjustment({
        id: item.id,
        changes: res
      }))
    })
  }
  onDeleteAdjustment(item: AdjustmentModel) {
    this.store$.dispatch(new ValuationProcessDeleteAdjustment({ id: item.id }))
  }

  private _setHeaders(refNums: string[]): { headerColumns: string[], displayedColumns: string[] } {
    const headerColumns = [`input-${refNums.length}-header`];
    const displayedColumns = ['adjustment'];
    const comparableHeaderColumns = [];
    refNums.forEach((com, i) => {
      headerColumns.push(`com-${i}-${refNums.length}-header`);
      comparableHeaderColumns.push(`com-${i}-${refNums.length}-header`);
      displayedColumns.push(`com-${i}-${refNums.length}`);
    });
    headerColumns.push(`analysis-${refNums.length}-header`, 'action-header'); // 'analysis-header',
    displayedColumns.push(`analysis-${refNums.length}`, 'actions'); // 'analysis',
    return {
      headerColumns,
      displayedColumns
    }
  }
}
