import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AllCitiesRequested, AllCountriesRequested, CityModel, CountryModel, selectAllCountries, selectCitiesByCountryId } from 'src/app/core/admin';
import { AssetClassSourceExternalReferenceModel } from 'src/app/core/asset_class';
import { LocalVpgaModel } from 'src/app/core/asset_class/_models/local-vpga.model';
import { AllAssetClassTypesRequested, AllLandTenuresRequested, AllMeasurementMethodologiesRequested, AllPremiseOfValuesRequested, AllPropertyTypesRequested, AllPurposeMeasurementsRequested, AllStandardMeasurementsRequested, AllTenureRequested, AssetClassType, DefaultAssumption, Departure, LandTenure, MeasurementMethodology, PremiseOfValue, PropertyType, PurposeMeasurement, selectAllAssetClassTypes, selectAllLandTenures, selectAllMeasurementMethodologies, selectAllPremiseOfValues, selectAllPropertyTypes, selectAllPurposeMeasurements, selectAllStandardMeasurements, selectAllTenure, SpecialAssumption, StandardMeasurement, Tenure } from 'src/app/core/linked-tables';
import { AllTopPropertyTypesRequested } from 'src/app/core/linked-tables/_actions/top-property-type.actions';
import { PropertyTypes, TopPropertyTypeModel } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { selectAllTopPropertyTypes } from 'src/app/core/linked-tables/_selectors/top-property-type.selectors';
import { AppState } from 'src/app/core/reducers';
import { AddTpTemplate, TpTemplateModel, UpdateTpTemplate } from 'src/app/core/template';
import { ITpTemplateExternalRef, ITpTemplateSelectedAssumption, ITpTemplateSelectedDeparture, ITpTemplateSelectedSpecialAssumption, ITpValuation } from 'src/app/core/template/_models/tp-template.model';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { CountryData } from 'src/app/views/pages/admin-management/countries/countries-list/local-data';
import { AssumptionsSelectionComponent } from '../../../_sub/assumptions-selection/assumptions-selection.component';
import { DeparturesSelectionComponent } from '../../../_sub/departures-selection/departures-selection.component';
import { SpecialAssumptionsSelectionComponent } from '../../../_sub/special-assumptions-selection/special-assumptions-selection.component';

@Component({
  selector: 'kt-tp-template-body',
  templateUrl: './tp-template-body.component.html',
  styleUrls: ['./tp-template-body.component.scss']
})
export class TpTemplateBodyComponent implements OnInit, OnDestroy {
  @ViewChild(AssumptionsSelectionComponent) assumptionsSelectionComponent: AssumptionsSelectionComponent; 
  @ViewChild(SpecialAssumptionsSelectionComponent) specialAssumptionsSelectionComponent: SpecialAssumptionsSelectionComponent;
  @ViewChild(DeparturesSelectionComponent) departuresSelectionComponent: DeparturesSelectionComponent;
  @Input() tpTemplateModel: TpTemplateModel;
  @Input() readonly: boolean;
  formGroup: UntypedFormGroup;
  private _onDestroy$: Subject<void> = new Subject();

  private tpTemplate: TpTemplateModel;
  private _valuations$: BehaviorSubject<ITpValuation[]> = new BehaviorSubject([]);
  valuations$ = this._valuations$.asObservable();
  customTp$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subPropertyTypes = PropertyTypes;

  // LT
  assetClassTypes: AssetClassType[] = [];
  propertyTypes: AssetClassType[] = [];
  filteredPropertyTypes: AssetClassType[] = [];
  premiseOfValues: PremiseOfValue[] = [];
  landTenures: LandTenure[] = [];
  tenures: Tenure[] = [];
  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  standardMeasurements: StandardMeasurement[] = [];
  filteredStandardMeasurements: StandardMeasurement[] = [];
  measurementMethodologies: MeasurementMethodology[];
  purposeMeasurements: PurposeMeasurement[];
  countriesData = CountryData.countriesWithNoDuplicateCurrency();
  customAssumptionsData: DefaultAssumption[] = [];
  selectedAssumptions: ITpTemplateSelectedAssumption[] = [];
  customSpecialAssumptions: SpecialAssumption[] = [];
  selectedSpecialAssumptions: ITpTemplateSelectedSpecialAssumption[] = [];
  customDepartures: Departure[] = [];
  selectedDepartures: ITpTemplateSelectedDeparture[] = [];
  public filterCtrlCountriesData: UntypedFormControl = new UntypedFormControl();
  public filterCtrlReportingData: UntypedFormControl = new UntypedFormControl();
  public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredReportsData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public sourceExternalReferenceSubject = new BehaviorSubject<AssetClassSourceExternalReferenceModel[]>([]);
  radios: any[] = [
    {
        // tslint:disable-next-line: max-line-length
        label: '1 - Does this valuation include all the fixed equipment, fixtures, fittings and equipment owned by the Landlord and essential in the running or management of the property?',
        formControlName: 'included_all_equipment',
        triggerFlag: '0',
        show: 1,
        tooltip_key: 'FIXED_EQUIPMENT',
        triggerField: 'descr',
        decisions: [{label: 'Yes', value: 1, default: true}, {label: 'No', value: 0, default: false}],
        required: ['included_all_equipment_descr'],
        descr_label: 'Please specify exclusions',
    },
    {
        // tslint:disable-next-line: max-line-length
        label: '2 - Are any items, furnishings, equipment, improvements, plants or fixtures owned by the occupier or Tenant to be included in this valuation?',
        formControlName: 'included_furniture',
        triggerFlag: '1',
        show: 1,
        tooltip_key: 'BE_INCLUDED',
        triggerField: 'descr',
        decisions: [{label: 'Yes', value: 1, default: true}, {label: 'No', value: 0, default: false}],
        required: ['included_furniture_descr'],
        descr_label: 'Please specify',
    },
    {
        // tslint:disable-next-line: max-line-length
        label: '3 - Does this valuation take into consideration any natural hazards such as ground instability, mining or mineral extraction, radon gas, risk of flooding from all mechanisms including pluvial and fluvial sources or non-natural hazards such as contamination where substances are in, on or under the ground resulting from current or historic uses?',
        formControlName: 'natural_accident_consideration',
        triggerFlag: '1',
        show: 1,
        triggerField: 'descr',
        decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
        required: ['natural_accident_consideration_descr'],
        descr_label: 'Please specify',
    },
    {
        // tslint:disable-next-line: max-line-length
        label: '4 - Does this valuation take into consideration other hazardous materials present in or kept on the property, such as (but not limited to) regulated hazards, including chemicals, radioactive substances, explosive materials, waste management activities, asbestos, ozone depleting substances, oils and deleterious materials, such as building materials that degrade with age, causing structural problems, for example, high alumina cement, calcium chloride or wood-wool shuttering?',
        formControlName: 'material_accident_consideration',
        triggerFlag: '1',
        show: 1,
        triggerField: 'descr',
        decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
        required: ['material_accident_consideration_descr'],
        descr_label: 'Please specify',
    },
    {
        label: '5 - Does this valuation include Due Diligence checks?',
        formControlName: 'diligence_check',
        triggerFlag: '1',
        show: 1,
        tooltip_key: 'DUE_DILIGENCE',
        triggerField: 'descr',
        decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
        required: ['diligence_check_descr'],
        descr_label: 'Please specify',
    },
    {
        label: '6 - Type of investigation',
        formControlName: 'type_of_inspection',
        triggerFlag: '0',
        show: 1,
        triggerField: 'none',
        decisions: [
            {label: 'Desktop valuation (no inspection)', value: 1, default: true},
            {label: 'External Inspection (Drive by)', value: 2, default: false},
            {label: 'Internal inspection', value: 3, default: false},
            {label: 'Full property survey (Coming soon)', value: 4, default: false, disabled: true}
        ],
        required: [],
    },
    {
        label: '7 - Any expected limitations or restrictions on the inspection inquiry and/or analysis?',
        formControlName: 'expected_limitations_restrictions',
        triggerFlag: '1',
        show: 1,
        tooltip_key: 'ON_INSPECTION',
        triggerField: 'descr',
        decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
        required: ['limitations_and_restrictions'],
        descr_label: 'Please specify',
    },
    {
        label: '8 - Has the valuer been instructed to measure the property?',
        formControlName: 'instructed_to_measure',
        triggerFlag: '1',
        show: 1,
        triggerField: 'combos',
        decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
        required: ['standard_measurement_id', 'measurement_methodology_id', 'purpose_measurement_id'],
    },
    {
        // tslint:disable-next-line: max-line-length
        label: '9 - Is the party that commissioned this valuation the intended lender of the valuation? (This question is showing because you have selected “Valuation of interests for secured lending” as valuation purpose in the ToE Form.',
        formControlName: 'intended_lender',
        triggerFlag: '0',
        show: 0,
        triggerField: 'none',
        decisions: [{label: 'Yes', value: 1, default: true}, {label: 'No', value: 0, default: false}],
        required: [],
    },
    ];
  mapFormArrayToData: number[] = [];
  topPropertyTypes: TopPropertyTypeModel[] = [];

  private _propertyType$ = new BehaviorSubject<number>(-1);
  propertyType$ = this._propertyType$.asObservable();
  baseOfValueId$: Observable<number> = of(1);

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.tpTemplate = Object.assign({}, this.tpTemplateModel);
    this._valuations$.next(this.tpTemplate.valuations);
    this._createForm();
    this._fetchData();
    this.filteredCountriesData.next(this.countriesData.slice());
    this.filteredReportsData.next(this.countriesData.slice());
    this.customAssumptionsData = this.tpTemplate.customAssumptions;
    this.selectedAssumptions = this.tpTemplate.selectedAssumptions;
    this.customSpecialAssumptions = this.tpTemplate.customSpecialAssumptions;
    this.selectedSpecialAssumptions = this.tpTemplate.selectedSpecialAssumptions;
    this.customDepartures = this.tpTemplate.customDepartures;
    this.selectedDepartures = this.tpTemplate.selectedDepartures;
    this.sourceExternalReferenceSubject.next(this.convertRefs(this.tpTemplate.external_refs));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _createForm() {
    this.formGroup = this.fb.group({
      name: [this.tpTemplate.name, Validators.required],
      description: [this.tpTemplate.description, Validators.required],
      target_property_type_id: [this.tpTemplate.top_property_type_id],
      property_type_id: [this.tpTemplate.type_id],

      custom_asset_class_name: [this.tpTemplate.custom_asset_class_name],
      custom_property_type: [this.tpTemplate.custom_property_type],

      percentage: [this.tpTemplate.percentage],
      discount: [this.tpTemplate.discount],
      discount_descr: [this.tpTemplate.discount_descr],

      country_id: [this.tpTemplate.country_id],
      city_id: [this.tpTemplate.city_id],
      zip_code: [this.tpTemplate.zip_code],

      included_all_equipment: [this.tpTemplate.included_all_equipment],
      included_all_equipment_descr: [this.tpTemplate.included_all_equipment_descr],
      included_furniture: [this.tpTemplate.included_furniture],
      included_furniture_descr: [this.tpTemplate.included_furniture_descr],
      natural_accident_consideration: [this.tpTemplate.natural_accident_consideration],
      natural_accident_consideration_descr: [this.tpTemplate.natural_accident_consideration_descr],
      material_accident_consideration: [this.tpTemplate.material_accident_consideration],
      material_accident_consideration_descr: [this.tpTemplate.material_accident_consideration_descr],
      diligence_check: [this.tpTemplate.diligence_check],
      diligence_check_descr: [this.tpTemplate.diligence_check_descr],
      type_of_inspection: [this.tpTemplate.type_of_inspection],
      expected_limitations_restrictions: [this.tpTemplate.expected_limitations_restrictions],
      limitations_and_restrictions: [this.tpTemplate.limitations_and_restrictions],
      instructed_to_measure: [this.tpTemplate.instructed_to_measure],
      standard_measurement_id: [this.tpTemplate.standard_measurement_id],
      measurement_methodology_id: [this.tpTemplate.measurement_methodology_id],
      purpose_measurement_id: [this.tpTemplate.purpose_measurement_id],
      intended_lender: [this.tpTemplate.intended_lender],

      country_currency: [this.tpTemplate.country_currency],
      reporting_currency: [this.tpTemplate.reporting_currency],
      currency_exchange_rate: [this.tpTemplate.currency_exchange],

      level_consideration: this._createVpgaFormArray(this.tpTemplate.vpgas)
    });

    const controls = this.formGroup.controls;
    controls.target_property_type_id.valueChanges.pipe(
      startWith(controls.target_property_type_id.value),
      takeUntil(this._onDestroy$)
    ).subscribe(value => {
      if (value && value > 0) {
        this._filterPropertyTypes(value);
      }
      this.customTp$.next(value && value == PropertyTypes.Custom);
      if (value && value == PropertyTypes.Custom) {
        this._filterStandardMeasurements(value, this.formGroup.controls.property_type_id.value);
      }
      this._propertyType$.next(value);
    });
    controls.property_type_id.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(value => {
      if (value && value > 0) {
        this._filterStandardMeasurements(this.formGroup.controls.target_property_type_id.value, value);
      }
    })

    this.filterCtrlCountriesData.valueChanges
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(() => {
            this.filterCountryCurrenciesData();
        });

    this.filterCtrlReportingData.valueChanges
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(() => {
            this.filterReportingCurrenciesData();
        });

    if (this.tpTemplate.id && this.tpTemplate.country_id) {
      this.store.select(selectCitiesByCountryId(this.tpTemplate.country_id)).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
        this.cityOfCountry = res;
      })
    }
  }

  private _fetchData() {
    this.store.dispatch(new AllAssetClassTypesRequested());
    this.store.select(selectAllAssetClassTypes).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.assetClassTypes = [];
      this.filteredPropertyTypes = []
      if (res) {
        this.filteredPropertyTypes = res;
        this.assetClassTypes = res;
        if (this.tpTemplate.top_property_type_id && this.tpTemplate.top_property_type_id > 0) {
          this._filterPropertyTypes(this.tpTemplate.top_property_type_id)
        }
      }
    });

    this.store.dispatch(new AllTopPropertyTypesRequested());
    this.store.pipe(select(selectAllTopPropertyTypes)).pipe(
        takeUntil(this._onDestroy$)
    ).subscribe(res => {
        this.topPropertyTypes = [];
        if (res) {
            this.topPropertyTypes = res;
        }
    })

    this.store.dispatch(new AllPremiseOfValuesRequested())
    this.store.select(selectAllPremiseOfValues).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.premiseOfValues = [];
      if (res) {
        this.premiseOfValues = res;
      }
    });

    this.store.dispatch(new AllLandTenuresRequested());
    this.store.select(selectAllLandTenures).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.landTenures = [];
      if (res) {
        this.landTenures = res;
      }
    });

    this.store.dispatch(new AllTenureRequested());
    this.store.select(selectAllTenure).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.tenures = [];
      if (res) {
        this.tenures = res;
      }
    });

    this.store.dispatch(new AllCountriesRequested());
    this.store.select(selectAllCountries).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.allCountries = res;
      if (res) {
        this.allCountries = res;
      }
    });
    this.store.dispatch(new AllCitiesRequested());
    this.store.dispatch(new AllStandardMeasurementsRequested());
    this.store.select(selectAllStandardMeasurements).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.standardMeasurements = [];
      if (res) {
        this.standardMeasurements = res;
        this.filteredStandardMeasurements = res;
        if (this.tpTemplate.type_id && this.tpTemplate.type_id > 0) {
          this._filterStandardMeasurements(this.tpTemplate.top_property_type_id, this.tpTemplate.type_id);
        }
      }
    });

    this.store.dispatch(new AllMeasurementMethodologiesRequested());
    this.store.select(selectAllMeasurementMethodologies).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.measurementMethodologies = [];
      if (res) {
        this.measurementMethodologies = res;
      }
    });

    this.store.dispatch(new AllPurposeMeasurementsRequested());
    this.store.select(selectAllPurposeMeasurements).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.purposeMeasurements = [];
      if (res) {
        this.purposeMeasurements = res;
      }
    });
  }

  public getAssetClassType(id: number): string {
      const acType = this.topPropertyTypes && this.topPropertyTypes.find(ac => ac.id == id);
      return acType ? acType.title : '';
  }
  public selectCountry() {
    const countryId = this.formGroup.controls.country_id.value;
    this.store.select(selectCitiesByCountryId(countryId)).pipe(takeUntil(this._onDestroy$)).subscribe((res: CityModel[]) => {
        this.cityOfCountry = res;
    });
    if (this.allCountries.length > 0) {
        const countryDefaultCurrency = this.allCountries.find(country => country.id === countryId).currency;

        // ToDo - Currenty
        this.formGroup.controls.country_currency.patchValue(countryDefaultCurrency);
        this.formGroup.controls.reporting_currency.patchValue(countryDefaultCurrency);
    }
  }
  public valuationAdd(valuation: ITpValuation) {
    const valuations = this._valuations$.value;
    valuations.push(valuation);
    this._valuations$.next(valuations);
  }
  public valuationEdit({valuation, index}: {valuation: ITpValuation, index: number}) {
    const valuations = this._valuations$.value;
    const _valuations = valuations.map((val, i) => {
      if (index === i) {
        return valuation
      }
      return val
    })
    // valuations.splice(index, 1, valuation);
    this._valuations$.next(_valuations);
  }
  public valuationDelete(index: number) {
    const valuations = this._valuations$.value;
    valuations.splice(index, 1)
    this._valuations$.next(valuations);
  }
  public currencyDifferent() {
      return  this.formGroup.controls.country_currency.value != this.formGroup.controls.reporting_currency.value;
  }
  public selectCurrency() {
      if (!this.currencyDifferent()) {
          this.formGroup.controls.currency_exchange_rate.setValue('');
      }
  }
  get vpgaFormArray(): UntypedFormArray {
    const formArray = this.formGroup.get('level_consideration') as UntypedFormArray;
    return formArray;
  }
  addVPGAMatterField() {
      const control = this.fb.control('');
      this.vpgaFormArray.push(control);
      this.mapFormArrayToData.push(null);
  }
  removeVPGAMatterField(indx: number) {
      this.vpgaFormArray.removeAt(indx);
  }
  _createVpgaFormArray(vpgas: LocalVpgaModel[]): UntypedFormArray {
      const formArray = this.fb.array([]);
      vpgas.forEach(vpga => {
          const control = this.fb.control(vpga.vpga);
          formArray.push(control);
          this.mapFormArrayToData.push(vpga.id);
      })
      return formArray;
  }
  _getVpgaValue(): LocalVpgaModel[] {
      let vals: LocalVpgaModel[] = [];
      this.vpgaFormArray.controls.forEach((control, i) => {
          const _new = new LocalVpgaModel();
          if (this.mapFormArrayToData[i]) {
              _new.id = this.mapFormArrayToData[i];
          }
          _new.vpga = control.value;
          vals.push(_new);
      })
      return vals;
  }
  private _filterPropertyTypes(acID: number) {
    if (acID == PropertyTypes.Custom) {
      this.filteredPropertyTypes = [];
    } else {
      this.filteredPropertyTypes = this.assetClassTypes.filter(type => type.top_property_type_id === acID);
    }
  }

  private _filterStandardMeasurements(acID: number, subId: number) {
    if (acID == PropertyTypes.Custom) {
        this.filteredStandardMeasurements = this.standardMeasurements;
    } else {
        this.filteredStandardMeasurements = this.standardMeasurements.filter(propertyType => propertyType.asset_class_type_id === subId);
    }
  }

  protected filterCountryCurrenciesData() {
      if (!this.countriesData) {
          return;
      }
      // get the search keyword
      let search = this.filterCtrlCountriesData.value;
      if (!search || search === '') {
          this.filteredCountriesData.next(this.countriesData.slice());
          return;
      } else {
          search = search.toLowerCase();
      }
      // filter the models
      this.filteredCountriesData.next(
          this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
      );
  }

  protected filterReportingCurrenciesData() {
      if (!this.countriesData) {
          return;
      }
      // get the search keyword
      let search = this.filterCtrlReportingData.value;
      if (!search || search === '') {
          this.filteredReportsData.next(this.countriesData.slice());
          return;
      } else {
          search = search.toLowerCase();
      }
      // filter the models
      this.filteredReportsData.next(
          this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
      );
  }

  private convertRefs(refs: ITpTemplateExternalRef[]): AssetClassSourceExternalReferenceModel[] {
    return refs.map(ref => {
      const acRef = new AssetClassSourceExternalReferenceModel();
      acRef.id = ref.id;
      acRef.source_external_reference_id = ref.source_external_reference_id;
      acRef.source_external_reference = ref.source_external_reference;
      acRef.reference_available = ref.reference_available;
      acRef.assumption = ref.assumption;
      acRef.assumption_descr = ref.assumption_descr;
      acRef.special_assumption = ref.special_assumption;
      acRef.special_assumption_descr = ref.special_assumption_descr;
      return acRef;
    });
  }

  private convertRefsBack(acRefs: AssetClassSourceExternalReferenceModel[]): ITpTemplateExternalRef[] {
    return acRefs.map(acRef => ({
      id: acRef.id,
      source_external_reference_id: acRef.source_external_reference_id,
      source_external_reference: null,
      reference_available: acRef.reference_available,
      assumption: acRef.assumption,
      assumption_descr: acRef.assumption_descr,
      special_assumption: acRef.special_assumption,
      special_assumption_descr: acRef.special_assumption_descr
    }))
  }

  private _prepareTpTemplate(): TpTemplateModel {
    const template = new TpTemplateModel();
    template.clear();
    const controls = this.formGroup.controls;
    template.id = this.tpTemplate.id;
    template.name = controls.name.value;
    template.description = controls.description.value;
    template.top_property_type_id = controls.target_property_type_id.value == PropertyTypes.Custom ? null : controls.target_property_type_id.value;
    template.type_id = controls.property_type_id.value;
    
    template.custom_asset_class_name = controls.custom_asset_class_name.value;
    template.custom_property_type = controls.custom_property_type.value;
    template.percentage = controls.percentage.value;
    template.discount = controls.discount.value;
    template.discount_descr = controls.discount_descr.value;

    const valuation = this._valuations$.value.length > 1 ? this._valuations$.value[0] : null ;
    if (valuation) {
      template.premise_of_value_id = valuation.premise_of_value_id;
      template.tenure_id = valuation.tenure_id;
      template.custom_tenure = valuation.tenure_custom;
    }


    template.country_id = controls.country_id.value;
    template.city_id = controls.city_id.value;
    template.zip_code = controls.zip_code.value;

    template.included_all_equipment = controls.included_all_equipment.value;
    template.included_all_equipment_descr = controls.included_all_equipment_descr.value;
    template.included_furniture = controls.included_furniture.value;
    template.included_furniture_descr = controls.included_furniture_descr.value;
    template.natural_accident_consideration = controls.natural_accident_consideration.value;
    template.natural_accident_consideration_descr = controls.natural_accident_consideration_descr.value;
    template.material_accident_consideration = controls.material_accident_consideration.value;
    template.material_accident_consideration_descr = controls.material_accident_consideration_descr.value;
    template.diligence_check = controls.diligence_check.value;
    template.diligence_check_descr = controls.diligence_check_descr.value;
    template.type_of_inspection = controls.type_of_inspection.value;
    template.expected_limitations_restrictions = controls.expected_limitations_restrictions.value;
    template.instructed_to_measure = controls.instructed_to_measure.value;
    template.standard_measurement_id = controls.standard_measurement_id.value;
    template.measurement_methodology_id = controls.measurement_methodology_id.value;
    template.purpose_measurement_id = controls.purpose_measurement_id.value;
    template.intended_lender = controls.intended_lender.value;

    template.country_currency = controls.country_currency.value;
    template.reporting_currency = controls.reporting_currency.value;
    template.currency_exchange = controls.currency_exchange_rate.value;

    const assumptionsRes = this.assumptionsSelectionComponent.getData()
    template.selectedAssumptions = assumptionsRes.selected;
    template.customAssumptions = assumptionsRes.customAssumptions;
    const specialAssumptionsRes = this.specialAssumptionsSelectionComponent.getData();
    template.selectedSpecialAssumptions = specialAssumptionsRes.selected;
    template.customSpecialAssumptions = specialAssumptionsRes.customAssumptions;
    const departuresRes = this.departuresSelectionComponent.getData();
    template.selectedDepartures = departuresRes.selected;
    template.customDepartures = departuresRes.customDepartures;

    template.valuations = this._valuations$.value;

    const vpgas = this._getVpgaValue();
    template.vpgas = vpgas;
    template.external_refs = this.convertRefsBack(this.sourceExternalReferenceSubject.value);

    return template;
  }

  public onSubmit(complete: boolean): boolean {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(cName => {
        if (controls[cName].invalid) {
          controls[cName].markAsTouched();
        }
      });
      return false;
    }
    const template = this._prepareTpTemplate();
    if (complete) {
      if (template.id === undefined) {
        this.store.dispatch(new AddTpTemplate({
          template: template
        }));
      } else {
        this.store.dispatch(new UpdateTpTemplate({
          id: template.id,
          template
        }))
      }
      return true;
    }
    return false;
  }
}
