import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { TemplateRequest, ExternalRefTemplateModel } from "../_models";

export enum ExternalRefTemplateActionTypes {
    LOAD_EXTERNAL_REF_TEMPLATES = '[External Ref Template List] Load External Ref Templates',
    EXTERNAL_REF_TEMPLATES_LOADED = '[External Ref Template Effect] External Ref Templates Loaded',

    ADD_EXTERNAL_REF_TEMPLATE = '[External Ref Template Edit] Add New External Ref Template',
    EXTERNAL_REF_TEMPLATE_ADDED = '[Tp Tempalte Effect] New External Ref Template Added',
     
    UPDATE_EXTERNAL_REF_TEMPLATE = '[External Ref Template Edit] Update External Ref Template',
    EXTERNAL_REF_TEMPLATE_UPDATED = '[External Ref Template Effect] External Ref Template Updated',

    DELETE_EXTERNAL_REF_TEMPLATE = '[External Ref Template List] Delete External Ref Template',
    EXTERNAL_REF_TEMPLATE_DELETED = '[External Ref Template Effect] External Ref Template Deleted',

    DUPLICATE_EXTERNAL_REF_TEMPLATE = '[External Ref Template List] Duplicate External Ref Template',
}

export class LoadExternalRefTemplates implements Action {
    readonly type = ExternalRefTemplateActionTypes.LOAD_EXTERNAL_REF_TEMPLATES;
    constructor(public payload: {
        request: TemplateRequest
    }) {}
}

export class ExternalRefTemplatesLoaded implements Action {
    readonly type = ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATES_LOADED;
    constructor(public payload: {
        templates: ExternalRefTemplateModel[],
        total: number
    }) {}
}

export class AddExternalRefTemplate implements Action {
    readonly type = ExternalRefTemplateActionTypes.ADD_EXTERNAL_REF_TEMPLATE;
    constructor(public payload: {
        template: ExternalRefTemplateModel
    }) {}
}

export class ExternalRefTemplateAdded implements Action {
    readonly type = ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATE_ADDED;
    constructor(public payload: {
        template: ExternalRefTemplateModel
    }) {}
}

export class UpdateExternalRefTemplate implements Action {
    readonly type = ExternalRefTemplateActionTypes.UPDATE_EXTERNAL_REF_TEMPLATE;
    constructor(public payload: {
        id: number;
        template: ExternalRefTemplateModel
    }) {}
}

export class ExternalRefTemplateUpdated implements Action {
    readonly type = ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATE_UPDATED;
    constructor(public payload: {
        id: number;
        template: Update<ExternalRefTemplateModel>
    }) {}
}

export class DeleteExternalRefTemplate implements Action {
    readonly type = ExternalRefTemplateActionTypes.DELETE_EXTERNAL_REF_TEMPLATE;
    constructor(public payload: {
        id: number,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export class ExternalRefTemplateDeleted implements Action {
    readonly type = ExternalRefTemplateActionTypes.EXTERNAL_REF_TEMPLATE_DELETED;
    constructor(public payload: {id: number}) {}
}

export class DuplicateExternalRefTemplate implements Action {
    readonly type = ExternalRefTemplateActionTypes.DUPLICATE_EXTERNAL_REF_TEMPLATE;
    constructor(public payload: {
        template: ExternalRefTemplateModel,
        currentPage: number,
        currentPageSize: number
    }) {}
}

export type ExternalRefTemplateActions
    = LoadExternalRefTemplates
    | ExternalRefTemplatesLoaded
    | AddExternalRefTemplate
    | ExternalRefTemplateAdded
    | UpdateExternalRefTemplate
    | ExternalRefTemplateUpdated
    | DeleteExternalRefTemplate
    | ExternalRefTemplateDeleted
    | DuplicateExternalRefTemplate;