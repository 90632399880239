<div class="row">
<div class="col-lg-12 kt-margin-bottom-10-mobile">
    <h5 class="text-mad text-uppercase">Parcel(s)</h5>
    <hr>
</div>
<div class="col-lg-12">
<mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="land_area">
        <mat-header-cell *matHeaderCellDef>Land Parcel #</mat-header-cell>
        <mat-cell *matCellDef="let item; let i = dataIndex">
            {{i + 1}}
            <button mat-icon-button color="primary" (click)="viewLandArea(item.land_area)">
                <mat-icon>remove_red_eye</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="included">
        <mat-header-cell *matHeaderCellDef>
            Included
            <button
                    matSuffix
                    mat-button
                    mat-icon-button
                    class="p-0"
                    aria-label="Help"
                    [ngbPopover]="'TOOLTIP.CONSIDERATION.INCLUDED.TEXT' | translate"
                    (click)="$event.stopPropagation();">
                <mat-icon>help</mat-icon>
            </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let item; let i = dataIndex">
            <!-- <mat-checkbox [checked]="item.included" (change)="onCheckboxChange($event, item.order)"></mat-checkbox> -->
            <mat-checkbox [(ngModel)]="item.included"></mat-checkbox>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tenure_name">
        <mat-header-cell *matHeaderCellDef>Tenure</mat-header-cell>
        <mat-cell *matCellDef="let item; let i = dataIndex">
            <mat-select style="width: 80%;" [(ngModel)]="item.tenure_name">
                <mat-option *ngFor="let val of landTenures" [value]="val.id">
                    {{val.name + ' (' + val.payment_type + ')'}}
                </mat-option>
            </mat-select>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tenure_length">
        <mat-header-cell *matHeaderCellDef>Length</mat-header-cell>
        <mat-cell *matCellDef="let item; let i = dataIndex">
            <input class="title-input" style="width: 50%;" 
                [(ngModel)]="item.tenure_length"/>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="consideration">
        <mat-header-cell *matHeaderCellDef>Consideration</mat-header-cell>
        <mat-cell *matCellDef="let item; let i = dataIndex">
            <input mask="separator" 
                type="text"
                thousandSeparator="," 
                class="title-input" 
                [(ngModel)]="item.consideration" />
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
        <mat-cell *matCellDef="let item; let i = dataIndex">
            <mat-select [(ngModel)]="item.currency">
                <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                    {{_data.currencyCode}}
                </mat-option>
            </mat-select>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="text">
        <mat-cell *matCellDef="let item" [attr.colspan]="4">
            This parcel's consideration is already included in the global consideration
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns" [class.hidden]="row.included"></mat-row>
    <mat-row *matRowDef="let row; columns: disColumn" [class.hidden]="!row.included"></mat-row>
</mat-table>
</div>

</div>