// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectWallsAndPartitionQueryResult, selectWallsAndPartitionsPageLoading, selectWallsAndPartitionsShowInitWaitingMessage} from '../_selectors/walls-and-partition.selectors';

export class WallsAndPartitionsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectWallsAndPartitionsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectWallsAndPartitionsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectWallsAndPartitionQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
