import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { WarehouseAdjustmentModel } from '../_models/warehouse-adjustment.model';

export enum WarehouseAdjustmentsActionTypes {
    AllWarehouseAdjustmentsRequested = '[Warehouse Adjustments List] All Warehouse Adjustments Requested',
    AllWarehouseAdjustmentsLoaded = '[Warehouse Adjustments API] All Warehouse Adjustments Loaded',

    WarehouseAdjustmentOnServerCreated = '[Edit Warehouse Adjustment] Warehouse Adjustment on Server Created',
    WarehouseAdjustmentCreated = '[Edit Warehouse Adjustment] Warehouse Adjustment Created',
    WarehouseAdjustmentUpdated = '[Edit Warehouse Adjustment] Warehouse Adjustment Updated',
    WarehouseAdjustmentDeleted = '[Warehouse Adjustment Deleted] Warehouse Adjustment Deleted',

    WarehouseAdjustmentRestored = '[Warehouse Adjustment Trash] Warehouse Adjustment Restored',
    WarehouseAdjustmentOnServerRestored = '[Warehouse Adjustment Trash] Warehouse Adjustment On Server Restored',

    WarehouseAdjustmentOnServerAdminRestored = '[Warehouse Adjustment Admin Trash] Warehouse Adjustment On Server Restored',
    WarehouseAdjustmentAdminRestored = '[Warehouse Adjustment Admin Trash] Warehouse Adjustment Restored',

    WarehouseAdjustmentDeletedFromTrash = '[Warehouse Adjustment Trash] Warehouse Adjustment deleted',
    WarehouseAdjustmentDeletedFromAdminTrash = '[Warehouse Adjustment Admin Trash] Warehouse Adjustment deleted',

    WarehouseAdjustmentTrash = 'Warehouse Adjustment Trashed',
    WarehouseAdjustmentTrashFlushed = 'Warehouse Adjustment Trash Flushed',

    // Page system

    WarehouseAdjustmentsPageRequested = '[Warehouse Adjustment List Page] Warehouse Adjustment Page Requested',
    WarehouseAdjustmentsPageLoaded = '[Warehouse Adjustment API] Warehouse Adjustment Page Loaded',
    WarehouseAdjustmentsPageCancelled = '[Warehouse Adjustment API] Warehouse Adjustment Page Cancelled',

    WarehouseAdjustmentsPageToggleLoading = '[Warehouse Adjustment page] Warehouse Adjustment Page Toggle Loading',
    WarehouseAdjustmentActionToggleLoading = '[Warehouse Adjustment] Warehouse Adjustment Action Toggle Loading'
}

export class WarehouseAdjustmentOnServerCreated implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerCreated;

    constructor(public payload: { item: WarehouseAdjustmentModel }) {
    }
}

export class WarehouseAdjustmentCreated implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentCreated;

    constructor(public payload: { item: WarehouseAdjustmentModel }) {
    }
}

export class WarehouseAdjustmentUpdated implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<WarehouseAdjustmentModel>,
        item: WarehouseAdjustmentModel
    }) {
    }
}

export class WarehouseAdjustmentDeleted implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class WarehouseAdjustmentsPageRequested implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class WarehouseAdjustmentsPageLoaded implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageLoaded;

    constructor(public payload: { items: WarehouseAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class WarehouseAdjustmentsPageCancelled implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageCancelled;
}

export class AllWarehouseAdjustmentsRequested implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.AllWarehouseAdjustmentsRequested;
}

export class AllWarehouseAdjustmentsLoaded implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.AllWarehouseAdjustmentsLoaded;

    constructor(public payload: { items: WarehouseAdjustmentModel[] }) {
    }
}

export class WarehouseAdjustmentsPageToggleLoading implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class WarehouseAdjustmentActionToggleLoading implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class WarehouseAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class WarehouseAdjustmentDeletedFromTrash implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class WarehouseAdjustmentOnServerRestored implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class WarehouseAdjustmentRestored implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentRestored;

    constructor(public payload: { item: WarehouseAdjustmentModel }) {
    }
}

export class WarehouseAdjustmentOnServerAdminRestored implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class WarehouseAdjustmentAdminRestored implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentAdminRestored;

    constructor(public payload: { item: WarehouseAdjustmentModel }) {
    }
}

export class WarehouseAdjustmentTrashFlushed implements Action {
    readonly type = WarehouseAdjustmentsActionTypes.WarehouseAdjustmentTrashFlushed;

    constructor() {
    }
}

export type WarehouseAdjustmentActions = WarehouseAdjustmentCreated
    | WarehouseAdjustmentUpdated
    | WarehouseAdjustmentDeleted
    | WarehouseAdjustmentsPageRequested
    | WarehouseAdjustmentsPageLoaded
    | WarehouseAdjustmentsPageCancelled
    | AllWarehouseAdjustmentsLoaded
    | AllWarehouseAdjustmentsRequested
    | WarehouseAdjustmentOnServerCreated
    | WarehouseAdjustmentDeletedFromAdminTrash
    | WarehouseAdjustmentDeletedFromTrash
    | WarehouseAdjustmentOnServerRestored
    | WarehouseAdjustmentRestored
    | WarehouseAdjustmentOnServerAdminRestored
    | WarehouseAdjustmentAdminRestored
    | WarehouseAdjustmentTrashFlushed
    | WarehouseAdjustmentsPageToggleLoading
    | WarehouseAdjustmentActionToggleLoading;