import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { ValuationNoteModel } from 'src/app/core/valuation/_models/valuation-note.model';

@Component({
  selector: 'kt-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.scss']
})
export class EditNoteComponent implements OnInit {
  isEdit: boolean;
  item: ValuationNoteModel;
  currentUser: User;
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.isEdit = data.isEdit;
    this.currentUser = data.user;

    if (data.item)  {
      this.item = Object.assign({}, data.item) as ValuationNoteModel;
    } else {
      this.item = new ValuationNoteModel();
      this.item.clear();
    }
  }

  ngOnInit(): void {
    this._createForm();
  }

  _createForm() {
    this.form = new UntypedFormGroup({
      'input': new UntypedFormControl(this.item.input, Validators.required)
    });
  }

  getComponentTitle() {
    if (this.isEdit) {
      return `Edit Note`;
    }
    return `Add Note`
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(controlName => {
        this.form.controls[controlName].markAsTouched();
      })
      return;
    }
    const _input = this.form.get('input').value;
    this.item.input = _input;
    this.item.user_id = this.currentUser.id;
    this.item.user_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    this.dialogRef.close(this.item);
  }
  onClose() {this.dialogRef.close();}

}
