import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-image-viewer-ngb-modal',
  templateUrl: './image-viewer-ngb-modal.component.html',
  styleUrls: ['./image-viewer-ngb-modal.component.scss']
})
export class ImageViewerNgbModalComponent implements OnInit {
  @Input()
  picture: string;
  @Input()
  type: any;
  @Input()
  title: string;
  awConst = awConst;

  path: string;
  loading: boolean;
  basePath = environment.baseApiUrl;
  hasError: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private subDomainService: SubdomainService
  ) { 
    this.loading = true;
  }

  onClose() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    if (this.picture.charAt(0) == '/') {
      this.picture = this.picture.substring(1, this.picture.length);
    }
    this.path = this.basePath + `api/${this.subDomainService.subDomain}/files/download?path=` + this.picture;
  }

  public imgLoading(e) {
    this.loading = false;
  }

  public imgOnError(e) {
    this.loading = false;
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  public pdfLoadingProgress(progressData: PDFProgressData) {
    if (progressData.loaded) {
      this.loading = false;
    }
  }

  public onError(e) {
    this.loading = false;
    this.hasError = true;
  }
}
