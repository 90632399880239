import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MatTableModule } from '@angular/material/table'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatRadioModule } from '@angular/material/radio'
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'

import { RolesComponent } from '../mad-usermanagement/roles/roles.component';
import { RoleEditComponent } from '../mad-usermanagement/roles/role-edit/role-edit.component';
import { MadAuthComponent } from './mad-auth.component';
import { PartialsModule } from '../../partials/partials.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterComponent } from './register/register.component';
import { StoreModule } from '@ngrx/store';
import { MadAuthEffects, madAuthReducer } from 'src/app/core/mad-auth/mad-auth.store';
import { EffectsModule } from '@ngrx/effects';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';
import { MadAuthGuard } from 'src/app/core/mad-auth/mad-auth.guard';
import { MadPermissionGuard } from 'src/app/core/mad-auth/mad-permission.guard';
import { PostLoginComponent } from './post-login/post-login.component';
import { RedirectComponent } from './redirect/redirect.component';
import { PostRegisterComponent } from './post-register/post-register.component';

const routes: Routes = [
  {
    path: '',
    component: MadAuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {returnUrl: window.location.pathname}
      },
      {
        path: 'post-login',
        component: PostLoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: 'post-register',
        component: PostRegisterComponent
      }
    ]
  },
]

@NgModule({
  declarations: [
    MadAuthComponent,
    LoginComponent,
    RegisterComponent,
    PostLoginComponent
  ],
  imports: [
    CommonModule,
    PartialsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes),
    StoreModule.forFeature('auth', madAuthReducer),
    EffectsModule.forFeature([MadAuthEffects])
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        maxHeight: 'auto',
        width: '900px'
      }
    }
  ]
})
export class MadAuthModule {
  static forRoot(): ModuleWithProviders<MadAuthModule> {
    return {
      ngModule: MadAuthModule,
      providers: [
        MadAuthService,
        MadAuthGuard,
        MadPermissionGuard
      ]
    }
  }
}
