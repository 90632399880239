import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, delay, distinctUntilChanged, map, take, tap} from 'rxjs/operators';
import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../core/_base/crud';
import {
    BuildingModel,
    BuildingsPageRequested,
    BuildingDeletedFromTrash,
    BuildingOnServerRestored,
    BuildingDataSource,
    BuildingService,
    BuildingTrashRequested,
    selectTrashedBuildingCount,
    selectAdminTrashedBuildingCount,
    BuildingDeleted,
    BuildingTrashFlushed, BuildingOnServerAdminRestored, BuildingDeletedFromAdminTrash,
} from '../../../../../core/comparable';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, combineLatest, fromEvent, merge, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../../core/reducers';
import {select, Store} from '@ngrx/store';
// import {CookieService} from 'ngx-cookie-service';
import {SubheaderService} from '../../../../../core/_base/layout';
import {Location} from '@angular/common';
import {MapsAPILoader} from '@agm/core';
import {environment} from '../../../../../../environments/environment';
import { BuildingMarkerInfoDialogComponent } from '../../_sub/building-marker-info-dialog/building-marker-info-dialog.component';
import { awConst } from 'src/app/app.constants';
import { ToMarkerItemConverterService } from '../../../shared_components/map-marker-info-window/to-marker-item-converter-service.service';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput } from '../../../shared_components/map-marker-info-window/map-marker-info-window.component';
import { emptyFilter, FilterModel, FilterChange } from '../../../shared_components/filter-section/filter-section.component';
import { User, currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { MapsService } from 'src/app/core/map/maps.service';
type pmStatus = {
    total_buildings: string,
    total_different_locations: string,
    total_used_by: string,
}

@Component({
    selector: 'kt-residential-list',
    templateUrl: './building-list.component.html',
    styleUrls: ['./building-list.component.scss']
})
export class BuildingListComponent implements OnInit, OnDestroy {
// Table fields
    dataSource: BuildingDataSource;
    trashCnt = 0;
    adminTrashCnt = 0;
    displayedColumns = ['id',
        'building_name',
        'city_of_location',
        'building_grade_id',
        'building_type_name',
        'linked_properties',
        'total_floors',
        'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    filterStatus = '';
    filterType = '';
    awConst = awConst;

    buildings: BuildingModel[] = [];

    // Subscriptions
    private subscriptions: Subscription[] = [];


    // Mode
    mapText = 'Map View';
    listText = 'List View';
    listViewMode = true;

    // map
    isFirst = true;
    latLngSW: google.maps.LatLng;
    latLngNE: google.maps.LatLng;
    previous;
    selectedId: number;
    map: any;
    zoom = 15;
    private _centerLat$ = new BehaviorSubject<number>(null)
    centerLat$ = combineLatest([
        this._centerLat$,
        this.mapsService.latitude$
    ]).pipe(map(([initial, def]) => {
        return initial == null ? def : initial
    }))
    private _centerLng$ = new BehaviorSubject<number>(null)
    centerLng$ = combineLatest([
        this._centerLng$,
        this.mapsService.longitude$
    ]).pipe(map(([initial, def]) => {
        return initial == null ? def : initial
    }))
    currentCenter: { lat: 0, lng: 0 };
    mapAction = new BehaviorSubject<boolean>(true);

    filterModel: FilterModel = emptyFilter();
    filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
    filterObs$ = this.filter$.asObservable();
    _north = new BehaviorSubject<number>(47.92399006317647);
    _south = new BehaviorSubject<number>(47.9096089375195);
    _east = new BehaviorSubject<number>(106.9476215928344);
    _west = new BehaviorSubject<number>(106.8833344071655);

    icon = {
        url: './assets/media/icons/my_marker.svg',
    };
    buildingStatusCnts$ = new BehaviorSubject<pmStatus>({
        total_buildings: '0',
        total_different_locations: '0',
        total_used_by: '0',
    });

    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
    currentUser: User|null

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param location
     * @param subheaderService
     * @param cookieService
     * @param buildingService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param mapsAPILoader
     * @param ngZone
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public location: Location,
                public subheaderService: SubheaderService,
                // public cookieService: CookieService,
                public buildingService: BuildingService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private mapsAPILoader: MapsAPILoader,
                private toMarkerItemService: ToMarkerItemConverterService,
                private ngZone: NgZone,
                private store: Store<AppState>,
                private subDomainService: SubdomainService,
                private mapsService: MapsService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
        this.subscriptions.push(userSub)
        this.subheaderService.setTitle('Terms of Engagements');
        this.subheaderService.setBreadcrumbs([
            {title: 'Main menu', page: `../default/assignment`},
            {title: 'Assignments', page: `../default/assignment/assignments`},
            {title: 'Terms of Engagements', page: `../default/assignment/assignments/toes`},
        ]);

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        // Init DataSource
        this.dataSource = new BuildingDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            distinctUntilChanged()
        ).subscribe(res => {
            if (res && res.length > 0) {
                this.buildings = res;
            }

            this.mapAction.next(false);
        });

        this.subscriptions.push(entitiesSubscription);

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedBuildingCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedBuildingCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });


        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadList();
        }); // Remove this line, just loading imitation

        this.buildingService.getStatus().subscribe(d => {
            this.buildingStatusCnts$.next({
                total_buildings: d.data.total.toString(),
                total_used_by: d.data.used_in_comparables.toString(),
                total_different_locations: d.data.diff_city.toString()
            })
        })
    }

    onFilterChange(event: FilterChange) {
        if (event.page != null) {
            this.paginator.pageIndex = event.page
        }
        this.filterModel = event.filter;
        this.loadList();
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    toggleView() {
        this.listViewMode = !this.listViewMode;
        if (this.listViewMode) {
            const _new = Object.assign({}, this.filterModel) as FilterModel;
            // _new.max_lat = 0;
            // _new.max_lng = 0;
            // _new.min_lat = 0;
            // _new.min_lng = 0;

            // _new.sw_lat = 0;
            // _new.ne_lat = 0;
            // _new.sw_lng = 0;
            // _new.ne_lng = 0;
            
            this.filterModel = _new;
        }
        // this.resetFilter();
    }

    /**
     * Load Buildings List from service through data-source
     */
    loadList(isList = true) {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            isList ? this.paginator.pageSize : 1000
        );
        // Call request from server
        this.store.dispatch(new BuildingsPageRequested({page: queryParams}));
    }

    // /**
    //  * Returns object for filter
    //  */

    // /**
    //  * Returns object for filter
    //  */
    filterConfiguration(): any {
        // const searchText: string = this.searchInput.nativeElement.value;
        const filterModel = Object.assign({}, this.filterModel) as FilterModel;
        // filterModel.name = searchText;
        // if (!searchText) {
        //     filterModel.name = '';
        //     return filterModel;
        // }
        return filterModel;
    }

    /** ACTIONS */
    /**
     * Deleting comparabable residential into trash
     *
     * @param _item: BuildingModel
     */
    deleteBuilding(_item: BuildingModel) {
        this.store.dispatch(new BuildingDeleted({id: _item.id}));
    }

    /**
     * Show add residential comparable dialog
     */
    addBuilding() {
        this.editBuilding(null);
    }

    /**
     * Show Edit toe dialog and save after success close result
     * @param buildingModel: BuildingModel
     */
    editBuilding(buildingModel: BuildingModel) {
        if (buildingModel) {
            this.router.navigate(['edit', buildingModel.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
        }
    }

    // reachBuilding(toe: BuildingModel) {
    //     this.router.navigate(['../toes/', toe.id], {relativeTo: this.activatedRoute});
    // }

    back() {
        this.location.back();
    }

    trash() {
        this.buildingService.getTrashedBuildings().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.building_name}`,
                    id: elem.id.toString(),
                    date: elem.user_deleted,
                    deletedUser: elem.userDeletedBy,
                    hasPermanentlyDelete: true
                });
            });
            this.show_trash(items);
        });
    }

    adminTrash() {
        this.buildingService.getAdminTrashedBuildings().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.building_name}`,
                    id: elem.id.toString(),
                    date: elem.deleted_at,
                    deletedUser: elem.deletedBy,
                    hasPermanentlyDelete: true
                });
            });
            this.show_trash(items, true);
        });
    }

    show_trash(items, isAdmin = false) {
        this.store.dispatch(new BuildingTrashRequested({isLoading: true}));

        let _title = 'Deleted Buildings';
        if (isAdmin) {
            _title = 'Admin Deleted Buildings';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin: boolean) {
        if (isAdmin) {
            this.store.dispatch(new BuildingOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new BuildingOnServerRestored({id}));
        }
    }

    delete(_id: number) {
        this.store.dispatch(new BuildingDeletedFromTrash({id: _id}));
        this.layoutUtilsService.showActionNotification('Deleted from trash', MessageType.Delete, 500, true, false);
    }

    hardDelete(id: number) {
        this.store.dispatch(new BuildingDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new BuildingTrashFlushed());
    }

    /*          MAP Functions       */
    mapReady(event: any) {

        // agmMap
        this.map = event;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

        this.map.addListener('dragend', () => {
            this._centerLat$.next(this.currentCenter.lat);
            this._centerLng$.next(this.currentCenter.lng);

            this.mapAction.next(true);
            this.paginator.pageIndex = 0;
            this.loadList();
        });
    }

    centerChange($event: any) {
        if ($event) {
            this.currentCenter = {lat: $event.lat, lng: $event.lng};
        }
    }

    zoomChanged($event: any) {
        this.isFirst = true;
    }

    boundsChanged(_latLngBound) {
        this.latLngNE = _latLngBound.getNorthEast();
        this.latLngSW = _latLngBound.getSouthWest();


        const xPadding = (this.latLngNE.lat() - this.latLngSW.lat()) * 0.08;
        this._north.next(this.latLngNE.lat() - xPadding);
        this._south.next(this.latLngSW.lat() + xPadding);

        this._east.next(this.latLngNE.lng() - xPadding);
        this._west.next(this.latLngSW.lng() + xPadding);
        const _new = Object.assign({}, this.filterModel) as FilterModel;
        if (_new.bounds == null) {
            _new.bounds = {ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0}
        } else {
            _new.bounds = Object.assign({}, _new.bounds);
        }
        _new.bounds.sw_lat = _latLngBound.getSouthWest().lat();
        _new.bounds.ne_lat = _latLngBound.getNorthEast().lat();
        _new.bounds.sw_lng = _latLngBound.getSouthWest().lng();
        _new.bounds.ne_lng = _latLngBound.getNorthEast().lng();


        this.filterModel = _new;
        if (this.isFirst) {
            this.mapAction.next(true);
            this.isFirst = !this.isFirst;
            this.paginator.pageIndex = 0;
            this.loadList(false);
        }
    }

    showInfo(mId, infoData) {

        const data = this.toMarkerItemService.convert('building', [infoData]);
        this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
            data: {
                modal_title: data.modal_title,
                entities: data.entities
            }
        });
    }

    getImageURL(path, name, acPictures) {
        const featuredPicture = acPictures.find(picture => picture.is_featured === 1);
        if (featuredPicture) {
            path = featuredPicture.path;
            name = featuredPicture.name;
        }
        let _picture = path + '/' + name;

        if (_picture.charAt(0) == '/') {
            _picture = _picture.substring(1, _picture.length);
        }
        // this.path =  picture;
        const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
        return imgURL;
    }

    getLabel(id) {
        return {
            color: '#000000',
            fontFamily: '',
            fontSize: '14px',
            fontWeight: 'bold',
            className: 'bg-op',
            text: '' + id,
        };
    }

    setLocation() {
        this.setCurrentLocation();
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    this._centerLat$.next(position.coords.latitude);
                    this._centerLng$.next(position.coords.longitude);
                    this.zoom = 15;
                }
            });
        } else {
            alert('Position not available');
        }
    }

    mapClick() {
        if (this.previous) {
            this.previous.close();
        }
    }

    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'info';
            default:
                return 'success';
        }
    }
    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 2:
                return 'imported';
            default:
                return 'Validated';
        }
    }
    countUnique(iterable) {
        return new Set(iterable).size;
    }
}
