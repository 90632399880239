<div class="card h-100">
    <div class="card-header info-header">
        <span>Milestones</span>
    </div>
    <div class="card-body info-body">
        <table *ngIf="viewModel" class="table">
            <tbody>
                <tr>
                    <th>Inspection :</th>
                    <td>{{viewModel.inspectionType}} - {{viewModel.inspectionDate | dateWithOffset:viewModel.propertyTimezone}}</td>
                </tr>
                <tr>
                    <th>Draft Report (if any) :</th>
                    <td>{{viewModel.draftDate 
                        ? (viewModel.draftDate | dateWithOffset:viewModel.toeTimezone)
                        : 'N/A'}}
                    </td>
                </tr>
                <tr>
                    <th>Final Report :</th>
                    <td>{{viewModel.finalDate | dateWithOffset:viewModel.toeTimezone}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>