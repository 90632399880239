import { T } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { ReportDesignModel } from './report-design.model';
import { ConditionRatingV2Service } from '../services/condition-rating-v2.service';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { ReportDesignService } from './report-design.service';
import { Location } from '@angular/common';
import { DesignLayoutV2Component } from '../reporting-process/design-layout-v2/design-layout-v2.component';

@Component({
  selector: 'kt-report-design-page',
  templateUrl: './report-design-page.component.html',
  styleUrls: ['./report-design-page.component.scss'],
  providers: [ConditionRatingV2Service, StandardMeasurementUtility]
})
export class ReportDesignPageComponent implements OnInit, OnDestroy {
  @ViewChild(DesignLayoutV2Component)
  designLayoutV2Component: DesignLayoutV2Component;

  private _onDestroy: Subject<void> = new Subject();
  tp: AssetClassModel;

  reporting: ReportDesignModel

  constructor(
    private activatedRoute: ActivatedRoute,
    private crService: ConditionRatingV2Service,
    private smUtilityService: StandardMeasurementUtility,
    private service: ReportDesignService,
    private location: Location,
    private cdf: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        takeUntil(this._onDestroy),
        map((data) => {
          this.tp = Object.assign({}, data.assetClassData.data)

          this.crService.setComponentsAll(
            this.smUtilityService,
            this.tp.details,
            this.tp
          )

          this.crService.setConditionRatingsFromRatingModels(
            data.subData.ratings
          )
          this.crService.setSchemeId(
            this.tp.details.aboutProperty.floor_numbering_scheme_id
          )

          return this.tp;
        }),
        switchMap(tp => {
          return this.service.get(tp.id)
        }),
        tap(reporting => {
          if (reporting) {
            this.reporting = reporting.data
          } else {
            this.reporting = {
              id: undefined,
              status: 0,
              tp_selected_pictures: [],
              tp_pictures_all_or_custom: 0,
              building_pictures: [] ,
              comparable_pictures: 0,
              matterport_contents: [],
              property_pictures: [],
              valuation_cover_picture: 0,
              videos: []
            }
          }
          this.cdf.markForCheck()
        })
      ).subscribe()
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSubmit(complete: boolean) {
    var desingData = this.designLayoutV2Component.designData

    this.service.upsert(
      {
        id: this.reporting.id,
        status: complete ? 2 : 1 ,
        comparable_pictures: desingData.comparable_pictures,
        valuation_cover_picture: desingData.report_cover,
        tp_pictures_all_or_custom: desingData.picture_location,
          property_pictures: desingData.property_pics.map(
              (pic: { type: any; id: any; selection_status: any }) => {
                  return {
                      type: pic.type,
                      pic_id: pic.id,
                      selection: pic.selection_status,
                  };
              }
          ),
          building_pictures: desingData.building_pics.map(
              (pic: { type: any; id: any; selection_status: any }) => {
                  return {
                      type: pic.type,
                      pic_id: pic.id,
                      selection: pic.selection_status,
                  };
              }
          ),
          videos: desingData.videos.map(
              (vid: { id: any; selection_status: any }) => {
                  return {
                      vid_id: vid.id,
                      selection: vid.selection_status,
                  };
              }
          ),
          matterport_contents: desingData.links.map(
              (link: { id: any; selection_status: any }) => {
                  return {
                      link_id: link.id,
                      selection: link.selection_status,
                  };
              }
          ),
          tp_selected_pictures: []
      },
      this.tp.id
    ).subscribe(_ => {
      this.location.back();
    })
  }
}
