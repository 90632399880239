// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {UnitLengthMeasurementsService} from '../_services/unit-length-measurement.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allUnitLengthMeasurementsLoaded} from '../_selectors/unit-length-measurement.selectors';
// Actions
import {
    AllUnitLengthMeasurementsLoaded,
    AllUnitLengthMeasurementsRequested,
    UnitLengthMeasurementActionTypes,
    UnitLengthMeasurementsPageRequested,
    UnitLengthMeasurementsPageLoaded,
    UnitLengthMeasurementUpdated,
    UnitLengthMeasurementsPageToggleLoading,
    UnitLengthMeasurementDeleted,
    UnitLengthMeasurementOnServerCreated,
    UnitLengthMeasurementCreated,
    UnitLengthMeasurementAdminRestored,
    UnitLengthMeasurementDeletedFromAdminTrash,
    UnitLengthMeasurementDeletedFromTrash,
    UnitLengthMeasurementOnServerAdminRestored,
    UnitLengthMeasurementOnServerRestored,
    UnitLengthMeasurementRestored,
    UnitLengthMeasurementTrashFlushed,
    UnitLengthMeasurementsActionToggleLoading
} from '../_actions/unit-length-measurement.actions';

@Injectable()
export class UnitLengthMeasurementEffects {
    showPageLoadingDispatcher = new UnitLengthMeasurementsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new UnitLengthMeasurementsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new UnitLengthMeasurementsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllUnitLengthMeasurements$ = this.actions$
        .pipe(
            ofType<AllUnitLengthMeasurementsRequested>(UnitLengthMeasurementActionTypes.AllUnitLengthMeasurementsRequested),
            withLatestFrom(this.store.pipe(select(allUnitLengthMeasurementsLoaded))),
            filter(([action, isAllUnitLengthMeasurementsLoaded]) => !isAllUnitLengthMeasurementsLoaded),
            mergeMap(() => this.service.getAllUnitLengthMeasurements()),
            map(res => {
                return new AllUnitLengthMeasurementsLoaded({unitLengthMeasurements: res.data});
            })
        );

    @Effect()
    loadUnitLengthMeasurementsPage$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementsPageRequested>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findUnitLengthMeasurements(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new UnitLengthMeasurementsPageLoaded({
                    unitLengthMeasurements: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementDeleted>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteUnitLengthMeasurement(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementUpdated>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateUnitLengthMeasurement(payload.unitLengthMeasurement);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementOnServerCreated>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createUnitLengthMeasurement(payload.unitLengthMeasurement).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitLengthMeasurementCreated({unitLengthMeasurement: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushUnitLengthMeasurementTrash$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementTrashFlushed>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementOnServerRestored>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitLengthMeasurementRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementOnServerAdminRestored>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitLengthMeasurementAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementDeletedFromTrash>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashUnitLengthMeasurement$ = this.actions$
        .pipe(
            ofType<UnitLengthMeasurementDeletedFromAdminTrash>(UnitLengthMeasurementActionTypes.UnitLengthMeasurementDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: UnitLengthMeasurementsService, private store: Store<AppState>) {
    }
}
