export enum CashFlowMethodVersion {
    WriteOffIsLesserThanLease,
    WriteOffIsEqualToLeaseOrNA
}
export type CashFlowMethodData = {
    numberOfTerms: number,
    numberOfTermsReal: number,
    remainingYears: number,

    preliminary: CFMPreliminary,

    firstTerm: CFMFirstTerm,
    lastTerm: CFMLastTerm,
    midTerms: CFMMidTerm[],

    factorValue: number,

    results: CFMResults,
}
export type CFMPreliminary = {
    headlineRent: number,
    rentReceivedFor: number,
    yearsPurchaseOfRentReceivedFor: number,
    presentValueOfRentFreePeriod: number,
    capitalValueOfHeadlineRent: number,
    capitalValueOfInducements: number,
}
export type CFMFirstTerm = {
    yearsPurchaseOfRentReviewCycle: number,
    presentValueOfFittingOutPeriod: number,
    factorValue: number,
}
export type CFMLastTerm = {
    year: number,
    termSuffix: string,
    prevTermSuffix: string,
    revisionToFutureRentalValue: number,
    yearsPurchaseOfLastTermPeriod: number,
    presentValueOfRentReviewCycle: number,
    factorValueOfRevisionaryRent: number,
}
export type CFMMidTerm = {
    year: number,
    termSuffix: string,
    prevTermSuffix: string,
    revisionToFutureRentalValue: number,
    yearsPurchaseOfRentReview: number,
    presentValueOfRentReviewCycle: number,
    factorValueOfRevisionaryRent: number
}
export type CFMResults = {
    effectiveRent: number,
    isSelected: boolean,
    effectiveRents: CFMEffectiveRent[]
}
export type CFMEffectiveRent = {
    termSuffix: string,
    prevSuffix: string,
    prev: number,
    value: number,
    isSelected: boolean,
}
export type CFMRatesAndJustifications = {
    targetRate: number,
    targetRateJustification: string,
    growthRate: number,
    growthRateJustification: string,
    effectiveRentJustification: string,
}
export type SelectedRent = {
    main: boolean,
    index: number
}

export type CFMReturnType = CFMRatesAndJustifications & SelectedRent;