// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectBaseOfValueQueryResult, selectBaseOfValuesPageLoading, selectBaseOfValuesShowInitWaitingMessage} from '../_selectors/base-of-value.selector';

export class BaseOfValuesDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectBaseOfValuesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectBaseOfValuesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectBaseOfValueQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
