import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegNumberModel } from '../../../../../core/admin';

@Component({
    selector: 'kt-reg-number-edit-dialog',
    templateUrl: './reg-number-edit.dialog.component.html',
    styleUrls: ['./reg-number-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class RegNumberEditDialogComponent implements OnInit, OnDestroy {

    // Public properties
    regNumber: RegNumberModel;
    regNumberForm: UntypedFormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;

    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<RegNumberEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {      
        if (this.data.regNumber) {
            this.regNumber = Object.assign({}, this.data.regNumber);
        } else {
            this.regNumber = new RegNumberModel();
            this.regNumber.clear();
        }
        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
    createForm() {
        this.regNumberForm = this.fb.group({
            label: [this.regNumber.label, Validators.required],
            code: [this.regNumber.code, Validators.required],
        });
    }

    /** ACTIONS */

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        
        const controls = this.regNumberForm.controls;
        /** check form */
        if (this.regNumberForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        
            this.hasFormErrors = true;
            return;
        }

        this.regNumber.label = controls.label.value;
        this.regNumber.code = controls.code.value;
        
        if (this.regNumber.id) {
            this.regNumber._isEditMode = true;
        }
        
        this.dialogRef.close({
            regNumber: this.regNumber,
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.regNumber && this.regNumber.id) {
            return `Edit Registration Number`;
        }

        return 'New Registration Number';
    }
}
