export class AdjustmentValuation {
    id: number;
    comInfo: {
        id: number;
        ref_num: string
    };
    totalAdjustment: number;
    adjustedConsideration: number;
    adjustedGrossUnitRate: number;
    weighting: number;
    weightedAvgGrossUnitRate: number;
}