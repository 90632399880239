import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { Source, Status, Type, ValuationAssumptionDeparture } from '../_models/valuation-assumption-departure.model';
import * as reducer from '../_reducers/assumption_departure.reducer';

export const selectAssumptionDepartureState = createFeatureSelector<reducer.ValuationAssumptionDepartureState>('assumption-departures');
export const selectAll = createSelector(
    selectAssumptionDepartureState,
    state => {
        const items: ValuationAssumptionDeparture[] = [];

        const defaultAssumptions: ValuationAssumptionDeparture[] = [];
        each(state.default.entities, el => defaultAssumptions.push(el));
        defaultAssumptions.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            return 0;
        })

        const specialAssumptions: ValuationAssumptionDeparture[] = [];
        each(state.special.entities, el => specialAssumptions.push(el));
        specialAssumptions.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            return 0;
        })

        const departures: ValuationAssumptionDeparture[] = [];
        each(state.departure.entities, el => departures.push(el));
        departures.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            return 0;
        })

        items.push(...defaultAssumptions);
        items.push(...specialAssumptions);
        items.push(...departures);

        return items;
    }
);

export const selecLastCreatedDefaultAssumptionId = createSelector(
    selectAssumptionDepartureState,
    state => state.default.lastCreatedId
)
export const selecLastCreatedSpecialAssumptionId = createSelector(
    selectAssumptionDepartureState,
    state => state.special.lastCreatedId
)
export const selecLastCreatedDepartureId = createSelector(
    selectAssumptionDepartureState,
    state => state.departure.lastCreatedId
)

export const selectNewSpecialAssumptions = createSelector(
    selectAll,
    assumptions => {
        return assumptions.filter(a => a.type == Type.SpecialAssumption && a.source == Source.Input && a.status != Status.Removed)
    }
)