// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ReportDeliveryMethodActions, ReportDeliveryMethodActionTypes} from '../_actions/report-delivery-method.actions';
// Models
import {ReportDeliveryMethod} from '../_models/report-delivery-method.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ReportDeliveryMethodsState extends EntityState<ReportDeliveryMethod> {
    isAllReportDeliveryMethodsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ReportDeliveryMethod[];
    lastCreatedReportDeliveryMethodId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ReportDeliveryMethod> = createEntityAdapter<ReportDeliveryMethod>();

export const initialReportDeliveryMethodsState: ReportDeliveryMethodsState = adapter.getInitialState({
    isAllReportDeliveryMethodsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedReportDeliveryMethodId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function reportDeliveryMethodsReducer(state = initialReportDeliveryMethodsState, action: ReportDeliveryMethodActions): ReportDeliveryMethodsState {
    switch (action.type) {
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedReportDeliveryMethodId: undefined
            };
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodOnServerCreated:
            return {
                ...state
            };
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedReportDeliveryMethodId: action.payload.item.id
            });
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ReportDeliveryMethodActionTypes.AllReportDeliveryMethodsLoaded:
            return adapter.addAll(action.payload.reportDeliveryMethods, {
                ...state, isAllReportDeliveryMethodsLoaded: true
            });
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodsPageLoaded:
            return adapter.addMany(action.payload.reportDeliveryMethods, {
                ...initialReportDeliveryMethodsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ReportDeliveryMethodActionTypes.ReportDeliveryMethodDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ReportDeliveryMethodActionTypes.ReportDeliveryRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ReportDeliveryMethodActionTypes.ReportDeliveryDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ReportDeliveryMethodActionTypes.ReportDeliveryAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ReportDeliveryMethodActionTypes.ReportDeliveryDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ReportDeliveryMethodActionTypes.ReportDeliveryTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
