import { createAction, props } from "@ngrx/store";

export const setLiquidationValues = createAction(
  '[Valuation Process Effect] Set Liquidation Values',
  props<{
    values: {
      import_other_valuations: number,
      imported_valuation_id: number,
      disposal_period: number,
      disposal_period_type: number,
      has_discount: number,
      discount: number                 
    }
  }>()
)

export const changeLiquidationFormValues = createAction(
  '[Comparables Tab Page] Change liquidation form value',
  props<{values: {
    importOtherValuations: boolean,
    selectedValuationId: number
  }}>()
)

export const changeLiquidationValueEstimateFormValues = createAction(
  '[Liquidation Value Estimage Page] Change Form Value',
  props<{
    values: {
      disposalPeriod: number,
      disposalPeriodType: number,
      addDiscount: boolean,
      discount: number
    }
  }>()
)

export const resetLiquidationValues = createAction(
  '[Valuation Process Effect] Reset Liquidation Values'
)