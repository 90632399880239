import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { Source, Status } from 'src/app/core/valuation/_models/valuation-assumption-departure.model';
import { ValuationVpga10Matter} from 'src/app/core/valuation/_models/vpga10-matter.model';

@Component({
  selector: 'kt-edit-vpga10-matter',
  templateUrl: './edit-vpga10-matter.component.html',
  styleUrls: ['./edit-vpga10-matter.component.scss']
})
export class EditVpga10MatterComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  item: ValuationVpga10Matter;
  inputSource = Source;
  status = Status;
  isEdit: boolean;
  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditVpga10MatterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store$: Store<AppState>
  ) {
    this.isEdit = data.isEdit;
    
    if (data.item)  {
      this.item = Object.assign({}, data.item) as ValuationVpga10Matter;
    } else {
      this.item = new ValuationVpga10Matter();
      this.item.input = null;
      this.item.status = Status.Valid;
      this.item.source = Source.Input;
      this.item.justification = null;
    }
  }

  ngOnInit(): void {
    this._createFormV2();
  }

  ngOnDestroy() {}

  _createForm() {
    this.form = this.formBuilder.group({
      'input': new UntypedFormControl({value: this.item.input, disabled: this.item.status === Status.Removed}, Validators.required),
      'status': this.formBuilder.control('' + this.item.status),
      'justification': this.formBuilder.control(this.item.justification)
    });

    const inputControl = this.form.get('input');
    const inputSub = inputControl.valueChanges.subscribe((val: string) => {
      if (this.item.input !== val.trim()) {
        this.item.status = Status.Amended;
      } else {
        this.item.status = Status.Valid;
      }

      // Set status
      if (this.isEdit && this.item.source === Source.ToE) {
        const statusControl = this.form.get('status');
        statusControl.setValue('' + this.item.status);
        statusControl.updateValueAndValidity();
      }

      // Set Justification
      const justificationControl = this.form.get('justification');
      if (this.item.source === Source.ToE && this.item.status !== Status.Valid) {
        justificationControl.setValidators(Validators.required);
        justificationControl.updateValueAndValidity();
      } else {
        justificationControl.clearValidators();
        justificationControl.updateValueAndValidity();
      }
    });
    this.subscriptions.push(inputSub);

    const statusControl = this.form.get('status');
    const statusSub = statusControl.valueChanges.subscribe(val => {
      this.item.status = Number(val);
    });
    this.subscriptions.push(statusSub);
  }

  _createFormV2() {
    this.form = this.formBuilder.group({
      'input': new UntypedFormControl({value: this.item.input, disabled: this.item.status === Status.Removed}, Validators.required),
      'status': this.formBuilder.control({value: '' + this.item.status, disabled: true}),
      'justification': this.formBuilder.control(this.item.justification)
    });

    if (this.item.status === Status.Valid) {
      // Listen 'Input' value changes
      const inputControl = this.form.get('input');
      const inputSub = inputControl.valueChanges.subscribe((val: string) => {
        if (this.item.input != val.trim()) {
          this.item.status = Status.Amended;
        } else {
          this.item.status = Status.Valid;
        }

        // Set Status
        if (this.isEdit && this.item.source === Source.ToE) {
          const statusControl = this.form.get('status');
          statusControl.setValue('' + this.item.status);
          statusControl.updateValueAndValidity();
        }
      });
      this.subscriptions.push(inputSub);
    } else {
      const justificationControl = this.form.get('justification');
      justificationControl.setValidators(Validators.required);
      justificationControl.updateValueAndValidity();
    }

    // Listen status changes
    const statusControl = this.form.get('status');
    const statusSub = statusControl.valueChanges.subscribe(val => {
      this.item.status = Number(val);

      // Set justification
      const justificationControl = this.form.get('justification');
      if (this.item.source === Source.ToE && this.item.status !== Status.Valid) {
        justificationControl.setValidators(Validators.required);
        justificationControl.updateValueAndValidity();
      } else {
        justificationControl.setValidators(null);
        justificationControl.updateValueAndValidity();
      }
    });
    this.subscriptions.push(statusSub);
  }

  getComponentTitle() {
    if (this.isEdit) {
      return `Edit VPGA-10`;
    }
    return `Add VPGA-10`
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(controlName => {
        this.form.controls[controlName].markAsTouched();
      })
      return;
    }
    const _input = this.form.get('input').value;
    const _status = Number(this.form.get('status').value);
    const _justification = this.form.get('justification').value;
    this.item.input = _input;
    this.item.status = _status;
    this.item.justification = _justification;
    this.dialogRef.close(this.item);
  }

  onClose() {
    this.dialogRef.close();
  }

  checkEnum(val): boolean {
    return isNaN(val);
  }
}
