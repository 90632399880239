// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {MethodsToValue} from '../_models/methods-to-value.model';

export enum MethodsToValueActionTypes {

    AllMethodsToValuesRequested = '[MethodsToValues Home Page] All MethodsToValues Requested',
    AllMethodsToValuesLoaded = '[MethodsToValues API] All MethodsToValues Loaded',

    MethodsToValueOnServerCreated = '[Edit MethodsToValue Dialog] MethodsToValue On Server Created',
    MethodsToValueCreated = '[Edit MethodsToValues Dialog] MethodsToValues Created',
    MethodsToValueUpdated = '[Edit MethodsToValue Dialog] MethodsToValue Updated',
    MethodsToValueDeleted = '[MethodsToValues List Page] MethodsToValue Deleted',

    MethodsToValueOnServerRestored = '[MethodsToValue Trash] MethodsToValue On Server Restored',
    MethodsToValueRestored = '[MethodsToValue Trash] MethodsToValue Restored',

    MethodsToValueOnServerAdminRestored = '[MethodsToValue Admin Trash] MethodsToValue On Server Restored',
    MethodsToValueAdminRestored = '[MethodsToValue Admin Trash] MethodsToValue Restored',

    MethodsToValueDeletedFromTrash = '[MethodsToValue Trash] MethodsToValue deleted',
    MethodsToValueDeletedFromAdminTrash = '[MethodsToValue Admin Trash] MethodsToValue deleted',

    MethodsToValueTrash = 'MethodsToValue Trashed',
    MethodsToValueTrashFlushed = 'MethodsToValue Trash Flushed',

    MethodsToValuesPageRequested = '[MethodsToValues List Page] MethodsToValues Page Requested',
    MethodsToValuesPageLoaded = '[MethodsToValues API] MethodsToValues Page Loaded',
    MethodsToValuesPageCancelled = '[MethodsToValues API] MethodsToValues Page Cancelled',

    MethodsToValuesPageToggleLoading = '[MethodsToValues page] MethodsToValues Page Toggle Loading',
    MethodsToValuesActionToggleLoading = '[MethodsToValues] MethodsToValues Action Toggle Loading',

    // trash system

    AssetClassTypeOnServerRestored = '[AssetClassType Trash] AssetClassType On Server Restored',
    AssetClassTypeRestored = '[AssetClassType Trash] AssetClassType Restored',

    AssetClassTypeOnServerAdminRestored = '[AssetClassType Admin Trash] AssetClassType On Server Restored',
    AssetClassTypeAdminRestored = '[AssetClassType Admin Trash] AssetClassType Restored',

    AssetClassTypeDeletedFromTrash = '[AssetClassType Trash] AssetClassType deleted',
    AssetClassTypeDeletedFromAdminTrash = '[AssetClassType Admin Trash] AssetClassType deleted',

    AssetClassTypeTrash = 'AssetClassType Trashed',
    AssetClassTypeTrashFlushed = 'AssetClassType Trash Flushed',
}

export class MethodsToValueOnServerCreated implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueOnServerCreated;

    constructor(public payload: { methodsToValue: MethodsToValue }) {
    }
}

export class MethodsToValueCreated implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueCreated;

    constructor(public payload: { methodsToValue: MethodsToValue }) {
    }
}

export class MethodsToValueUpdated implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueUpdated;

    constructor(public payload: {
        partialmethodsToValue: Update<MethodsToValue>,
        methodsToValue: MethodsToValue
    }) {
    }
}

export class MethodsToValueDeleted implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class MethodsToValuesPageRequested implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValuesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class MethodsToValuesPageLoaded implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValuesPageLoaded;

    constructor(public payload: { methodsToValues: MethodsToValue[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class MethodsToValuesPageCancelled implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValuesPageCancelled;
}

export class AllMethodsToValuesRequested implements Action {
    readonly type = MethodsToValueActionTypes.AllMethodsToValuesRequested;
}

export class AllMethodsToValuesLoaded implements Action {
    readonly type = MethodsToValueActionTypes.AllMethodsToValuesLoaded;

    constructor(public payload: { methodsToValues: MethodsToValue[] }) {
    }
}

export class MethodsToValuesPageToggleLoading implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValuesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class MethodsToValuesActionToggleLoading implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValuesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class MethodsToValueDeletedFromAdminTrash implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class MethodsToValueDeletedFromTrash implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class MethodsToValueOnServerRestored implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class MethodsToValueRestored implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueRestored;

    constructor(public payload: { item: MethodsToValue }) {
    }
}

export class MethodsToValueOnServerAdminRestored implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class MethodsToValueAdminRestored implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueAdminRestored;

    constructor(public payload: { item: MethodsToValue }) {
    }
}

export class MethodsToValueTrashFlushed implements Action {
    readonly type = MethodsToValueActionTypes.MethodsToValueTrashFlushed;

    constructor() {
    }
}

export type MethodsToValueActions = MethodsToValueCreated
    | MethodsToValueUpdated
    | MethodsToValueDeleted
    | MethodsToValuesPageRequested
    | MethodsToValuesPageLoaded
    | MethodsToValuesPageCancelled
    | AllMethodsToValuesLoaded
    | AllMethodsToValuesRequested
    | MethodsToValueOnServerCreated
    | MethodsToValuesPageToggleLoading
    | MethodsToValueDeletedFromAdminTrash
    | MethodsToValueDeletedFromTrash
    | MethodsToValueOnServerRestored
    | MethodsToValueRestored
    | MethodsToValueOnServerAdminRestored
    | MethodsToValueAdminRestored
    | MethodsToValueTrashFlushed
    | MethodsToValuesActionToggleLoading;
