<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="loading$"
                       [tooltipTitle]="'TOOLTIP.CITY.LIST.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.CITY.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-title">
                <!-- <span>Cities {{country.id ? 'of ' + country.name : ''}}</span> -->
                <span>
                    {{ country && country.id 
                        ? ('CITY.LIST.TITLE.CITIES_OF' | translate:{name: country.name} )
                        : ('CITY.LIST.TITLE.ALL' | translate)}}
                </span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <a [routerLink]="['../../countries']" class="btn mr-2" mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                    back_page: ('COUNTRY.LIST.TITLE' | translate)
                }">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">
                    {{ 'GENERAL.BUTTON.BACK' | translate }}
                </span>
            </a>

            <button (click)="addCity()" mat-raised-button
                    [matTooltip]="'CITY.LIST.BUTTON.NEW.TOOLTIP' | translate"
                    color="primary"
                    type="button"
                    class="mr-2">
                <span>{{ 'CITY.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button
                    (click)="trash()"
                    mat-raised-button
                    [matTooltip]="'CITY.LIST.BUTTON.TRASHED.TOOLTIP' | translate"
                    *ngIf="(trashedData | async).length != 0"
                    type="button"
                    style="background-color: #c4c5d6 !important;">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{(trashedData | async).length}})</span>
            </button>

            <button
                    (click)="adminTrash()"
                    class="ml-2"
                    mat-raised-button [matTooltip]="'CITY.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate"
                    *ngIf="(aTrashedData | async).length != 0"
                    type="button"
                    style="background-color: #c4c5d6 !important;">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{(aTrashedData | async).length}})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form">
            <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="cityStatusCnts$ | async as vm;">
                <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">
    
                    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                        <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                            <span style="vertical-align: middle; color: #E8E8E8;">
                                {{ vm.total_cities }}
                            </span>
                        </div>
                        <div class="float-right align-content-end w-100 text-uppercase text-white">
                            <p class="m-0">
                                <span style="color: #cccccc;">TOTAL CITIES</span>
                            </p>
                        </div>
                    </div>
                    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_different_countries }}
                        </span>
                        <div class="float-right align-content-end w-100 text-uppercase text-white">
                            <p class="m-0"><span style="color: #E8E8E8;">DIFFERENT COUNTRIES</span></p>
                        </div>
                    </div>
                    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_used_in_vals }}
                        </span>
                        <div class="float-right align-content-end w-100 text-uppercase text-white">
                            <p class="m-0"><span style="color: #E8E8E8;">USED IN VALUATIONS</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center active">

                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">
                    </mat-form-field>
                    <div class="col-md-1">
                        <button (click)="resetFilter()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->
        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper ">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="asc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let city">{{city.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'CITY.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let city">{{city.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="county_name">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'CITY.LIST.DATATABLE.COUNTRY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let city">{{city.country_name}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'CITY.LIST.DATATABLE.DESCRIPTION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let city">{{city.description | truncate }}</mat-cell>
                    <!--<mat-cell *matCellDef="let city">{{city.description  | limitTo: 20}}</mat-cell>-->
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'CITY.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let city">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <!-- <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: city.createdBy ? city.createdBy : 'Unknown',
                                date: (city.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: city.lastUpdatedBy ? city.lastUpdatedBy: 'Unknown',
                                date: (city.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div> -->
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: city.createdBy ? city.createdBy : 'Unknown',
                                date: (city.created_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: city.lastUpdatedBy ? city.lastUpdatedBy: 'Unknown',
                                date: (city.updated_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                        </ng-template>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" [matTooltip]="'CITY.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editCity(city)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <span
                                [matTooltip]="city.relation_cnt == 0 
                                    ? ('CITY.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate) 
                                    : ('CITY.LIST.BUTTON.DELETE.TOOLTIP.CANNOT' | translate)">
                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="city.relation_cnt > 0"
                                    (click)="deleteCity(city)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="cityData.length == 0">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
        </div>
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
