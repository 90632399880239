// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {IncludedInValuation} from '../_models/included-in-valuation.model';

export enum IncludedInValuationActionTypes {

    AllIncludedInValuationsRequested = '[IncludedInValuations Home Page] All IncludedInValuations Requested',
    AllIncludedInValuationsLoaded = '[IncludedInValuations API] All IncludedInValuations Loaded',

    IncludedInValuationOnServerCreated = '[Edit IncludedInValuation Dialog] IncludedInValuation On Server Created',
    IncludedInValuationCreated = '[Edit IncludedInValuations Dialog] IncludedInValuations Created',
    IncludedInValuationUpdated = '[Edit IncludedInValuation Dialog] IncludedInValuation Updated',
    IncludedInValuationDeleted = '[IncludedInValuations List Page] IncludedInValuation Deleted',

    // trash system

    IncludedInValuationOnServerRestored = '[IncludedInValuation Trash] IncludedInValuation On Server Restored',
    IncludedInValuationRestored = '[IncludedInValuation Trash] IncludedInValuation Restored',

    IncludedInValuationOnServerAdminRestored = '[IncludedInValuation Admin Trash] IncludedInValuation On Server Restored',
    IncludedInValuationAdminRestored = '[IncludedInValuation Admin Trash] IncludedInValuation Restored',

    IncludedInValuationDeletedFromTrash = '[IncludedInValuation Trash] IncludedInValuation deleted',
    IncludedInValuationDeletedFromAdminTrash = '[IncludedInValuation Admin Trash] IncludedInValuation deleted',

    IncludedInValuationTrash = 'IncludedInValuation Trashed',
    IncludedInValuationTrashFlushed = 'IncludedInValuation Trash Flushed',

    // Page system

    IncludedInValuationsPageRequested = '[IncludedInValuations List Page] IncludedInValuations Page Requested',
    IncludedInValuationsPageLoaded = '[IncludedInValuations API] IncludedInValuations Page Loaded',
    IncludedInValuationsPageCancelled = '[IncludedInValuations API] IncludedInValuations Page Cancelled',

    IncludedInValuationsPageToggleLoading = '[IncludedInValuations page] IncludedInValuations Page Toggle Loading',
    IncludedInValuationsActionToggleLoading = '[IncludedInValuations] IncludedInValuations Action Toggle Loading'
}

export class IncludedInValuationOnServerCreated implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationOnServerCreated;

    constructor(public payload: { item: IncludedInValuation }) {
    }
}

export class IncludedInValuationCreated implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationCreated;

    constructor(public payload: { item: IncludedInValuation }) {
    }
}

export class IncludedInValuationUpdated implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationUpdated;

    constructor(public payload: {
        partialItem: Update<IncludedInValuation>,
        item: IncludedInValuation
    }) {
    }
}

export class IncludedInValuationDeleted implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class IncludedInValuationsPageRequested implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class IncludedInValuationsPageLoaded implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationsPageLoaded;

    constructor(public payload: { includedInValuations: IncludedInValuation[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class IncludedInValuationsPageCancelled implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationsPageCancelled;
}

export class AllIncludedInValuationsRequested implements Action {
    readonly type = IncludedInValuationActionTypes.AllIncludedInValuationsRequested;
}

export class AllIncludedInValuationsLoaded implements Action {
    readonly type = IncludedInValuationActionTypes.AllIncludedInValuationsLoaded;

    constructor(public payload: { includedInValuations: IncludedInValuation[] }) {
    }
}

export class IncludedInValuationsPageToggleLoading implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class IncludedInValuationsActionToggleLoading implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class IncludedInValuationDeletedFromAdminTrash implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class IncludedInValuationDeletedFromTrash implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class IncludedInValuationOnServerRestored implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IncludedInValuationRestored implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationRestored;

    constructor(public payload: { item: IncludedInValuation }) {
    }
}

export class IncludedInValuationOnServerAdminRestored implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class IncludedInValuationAdminRestored implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationAdminRestored;

    constructor(public payload: { item: IncludedInValuation }) {
    }
}

export class IncludedInValuationTrashFlushed implements Action {
    readonly type = IncludedInValuationActionTypes.IncludedInValuationTrashFlushed;

    constructor() {
    }
}

export type IncludedInValuationActions = IncludedInValuationCreated
    | IncludedInValuationUpdated
    | IncludedInValuationDeleted
    | IncludedInValuationsPageRequested
    | IncludedInValuationsPageLoaded
    | IncludedInValuationsPageCancelled
    | AllIncludedInValuationsLoaded
    | AllIncludedInValuationsRequested
    | IncludedInValuationOnServerCreated
    | IncludedInValuationDeletedFromAdminTrash
    | IncludedInValuationDeletedFromTrash
    | IncludedInValuationOnServerRestored
    | IncludedInValuationRestored
    | IncludedInValuationOnServerAdminRestored
    | IncludedInValuationAdminRestored
    | IncludedInValuationTrashFlushed
    | IncludedInValuationsPageToggleLoading
    | IncludedInValuationsActionToggleLoading;
