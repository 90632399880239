// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
// RxJS
import {Observable, Subscription} from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import {LayoutConfigService, MenuConfigService, PageConfigService} from '../../../core/_base/layout';
import {HtmlClassService} from '../html-class.service';
import {LayoutConfig} from '../../../core/_config/layout.config';
import {MenuConfig} from '../../../core/_config/menu.config';
import {PageConfig} from '../../../core/_config/page.config';
// User permissions
import {NgxPermissionsService} from 'ngx-permissions';
// import {currentUser, currentUserPermissions, Permission} from '../../../core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {NotificationModel} from '../../../core/auth/_models/notification.model';
import {NotificationAdded, NotificationRequested} from '../../../core/auth/_actions/notificaiton.actions';
import {Router} from '@angular/router';
import {LayoutUtilsService} from '../../../core/_base/crud';

@Component({
    selector: 'kt-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
    // Public variables
    selfLayout = 'default';
    asideSelfDisplay: true;
    contentClasses = '';
    contentContainerClasses = '';
    subheaderDisplay = true;
    contentExtended: false;
    reportGenerationProgressDisplay: boolean;
    interval = null;
    timeOut: boolean;

    // Private properties
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    /** Auth Comment
    // private currentUserPermissions$: Observable<Permission[]>;
    */


    /**
     * Component constructor
     *
     * param layoutConfigService: LayoutConfigService
     * param menuConfigService: MenuConfigService
     * param pageConfigService: PageConfigService
     * param htmlClassService: HtmlClassService
     * param store
     * param permissionsService
     */
    constructor(private layoutConfigService: LayoutConfigService,
                private menuConfigService: MenuConfigService,
                private pageConfigService: PageConfigService,
                private htmlClassService: HtmlClassService,
                private layoutUtilsService: LayoutUtilsService,
                private router: Router,
                private store: Store<AppState>,
                private permissionsService: NgxPermissionsService,
                private ref: ChangeDetectorRef) {
        this.loadRolesWithPermissions();
        this.reportGenerationProgressDisplay = false;
        localStorage.setItem('generatingReport', '0');
        localStorage.setItem('generatingReportTimeOut', '0');

        // register configs by demos
        this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
        this.menuConfigService.loadConfigs(new MenuConfig().configs);
        this.pageConfigService.loadConfigs(new PageConfig().configs);

        // setup element classes
        this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

        const subscription = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
            // reset body class based on global and page level layout config, refer to html-class.service.ts
            document.body.className = '';
            this.htmlClassService.setConfig(layoutConfig);
        });
        this.unsubscribe.push(subscription);
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.timeOut = false;
        const config = this.layoutConfigService.getConfig();
        // Load UI from Layout settings
        this.selfLayout = objectPath.get(config, 'self.layout');
        this.asideSelfDisplay = objectPath.get(config, 'aside.self.display');
        this.subheaderDisplay = objectPath.get(config, 'subheader.display');
        this.contentClasses = this.htmlClassService.getClasses('content', true).toString();
        this.contentContainerClasses = this.htmlClassService.getClasses('content_container', true).toString();
        this.contentExtended = objectPath.get(config, 'content.extended');

        // let the layout type change
        const subscription = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
            setTimeout(() => {
                this.selfLayout = objectPath.get(cfg, 'self.layout');
            });
        });
        this.unsubscribe.push(subscription);

        this.interval = setInterval(() => {
            if (localStorage.getItem('generatingReport') == '1') {
                this.reportGenerationProgressDisplay = true;
                if (this.timeOut == false) {
                    this.timeOut = true;
                    setTimeout(() => {
                        localStorage.setItem('generatingReport', '0');
                        localStorage.setItem('generatingReportTimeOut', '1');
                    }, 300000);
                }
            } else {
                localStorage.setItem('generatingReportTimeOut', '0');
                this.timeOut = false;
                this.reportGenerationProgressDisplay = false;
            }
            this.ref.markForCheck();
            this.ref.detectChanges();
        }, 1000);

        const userSubscription = this.store.pipe(
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    window.Echo.channel('user-notify.' + res.id)
                        .listen('.server.notify', (e) => {
                            const _item = JSON.parse(e.message) as NotificationModel;
                            // this.layoutUtilsService.showActionNotification('A new Notification', MessageType.Create, 3000, true, false);
                            this.store.dispatch(new NotificationAdded({item: _item}));

                            const snackBarRef = this.layoutUtilsService.showUserNotification(_item.title, _item.link.length > 0);

                            snackBarRef.onAction().subscribe(() => {
                                this.router.navigateByUrl(_item.link);
                            });
                            return;

                        });
                    this.store.dispatch(new NotificationRequested({userId: res.id}));
                } else {
                    window.Echo.leave();
                }
            });
        this.unsubscribe.push(userSubscription);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
        this.ref.detach();
        clearInterval(this.interval);
        // https://www.npmjs.com/package/ngx-permissions
        this.permissionsService.flushPermissions();
    }

    /**
     * NGX Permissions, init roles
     */
    loadRolesWithPermissions() {
        /** Auth Comment
        // this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
        // const subscription = this.currentUserPermissions$.subscribe(res => {
        //     if (!res || res.length === 0) {
        //         return;
        //     }

        //     this.permissionsService.flushPermissions();
        //     res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
        // });
        // this.unsubscribe.push(subscription);
        */
    }
}
