// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SourceInformationService} from '../_services/source-information.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSourceInformationLoaded} from '../_selectors/source-information.selectors';
// Actions
import {
    AllSourceInformationLoaded,
    AllSourceInformationRequested,
    SourceInformationActionTypes,
    SourceInformationPageRequested,
    SourceInformationPageLoaded,
    SourceInformationUpdated,
    SourceInformationPageToggleLoading,
    SourceInformationDeleted,
    SourceInformationOnServerCreated,
    SourceInformationCreated,
    SourceInformationAdminRestored,
    SourceInformationDeletedFromAdminTrash,
    SourceInformationDeletedFromTrash,
    SourceInformationOnServerAdminRestored,
    SourceInformationOnServerRestored,
    SourceInformationRestored,
    SourceInformationTrashFlushed,
    SourceInformationActionToggleLoading
} from '../_actions/source-information.actions';

@Injectable()
export class SourceInformationEffects {
    showPageLoadingDispatcher = new SourceInformationPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SourceInformationActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SourceInformationActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSourceInformation$ = this.actions$
        .pipe(
            ofType<AllSourceInformationRequested>(SourceInformationActionTypes.AllSourceInformationRequested),
            withLatestFrom(this.store.pipe(select(allSourceInformationLoaded))),
            filter(([action, isAllSourceInformationLoaded]) => !isAllSourceInformationLoaded),
            mergeMap(() => this.service.getAllSourceInformation()),
            map(res => {
                return new AllSourceInformationLoaded({sourceInformation: res.data});
            })
        );

    @Effect()
    loadSourceInformationPage$ = this.actions$
        .pipe(
            ofType<SourceInformationPageRequested>(SourceInformationActionTypes.SourceInformationPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findSourceInformation(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SourceInformationPageLoaded({
                    sourceInformation: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationDeleted>(SourceInformationActionTypes.SourceInformationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteSourceInformation(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationUpdated>(SourceInformationActionTypes.SourceInformationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateSourceInformation(payload.sourceInformation);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationOnServerCreated>(SourceInformationActionTypes.SourceInformationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createSourceInformation(payload.sourceInformation).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceInformationCreated({sourceInformation: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushSourceInformationTrash$ = this.actions$
        .pipe(
            ofType<SourceInformationTrashFlushed>(SourceInformationActionTypes.SourceInformationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationOnServerRestored>(SourceInformationActionTypes.SourceInformationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceInformationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationOnServerAdminRestored>(SourceInformationActionTypes.SourceInformationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceInformationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationDeletedFromTrash>(SourceInformationActionTypes.SourceInformationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSourceInformation$ = this.actions$
        .pipe(
            ofType<SourceInformationDeletedFromAdminTrash>(SourceInformationActionTypes.SourceInformationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: SourceInformationService, private store: Store<AppState>) {
    }
}
