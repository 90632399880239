// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CityActionTypes, CityActions} from '../_actions/city.actions';
// Models
import {CityModel} from '../_models/city.model';

export interface CitiesState extends EntityState<CityModel> {
    _isAllCitiesLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    lastCreatedCityId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CityModel> = createEntityAdapter<CityModel>();

export const initialCitiesState: CitiesState = adapter.getInitialState({
    _isAllCitiesLoaded: false,
    listLoading: false,
    actionsLoading: false,
    lastCreatedCityId: undefined,
    showInitWaitingMessage: true
});

export function citiesReducer(state = initialCitiesState, action: CityActions): CitiesState {
    switch (action.type) {
        case CityActionTypes.AllCitiesRequested:
            return {
                ...state,
                _isAllCitiesLoaded: false
            };
        case CityActionTypes.AllCitiesLoaded:
            return adapter.addAll(action.payload.cities, {
                ...state,
                _isAllCitiesLoaded: true
            });
        case CityActionTypes.CityDeleted:
            return adapter.removeOne(action.payload.id, state);

        case CityActionTypes.CityOnServerCreated:
            return {
                ...state
            };

        case CityActionTypes.CityTrashFlushed:
            return adapter.updateMany(action.payload.cities, state);

        case CityActionTypes.CityCreated:
            return adapter.addOne(action.payload.city, {
                ...state, lastCreatedCityId: action.payload.city.id
            });
        case CityActionTypes.CityUpdated:
            return adapter.updateOne(action.payload.partialCity, state);

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
