import {AuditTrailReport} from '../_models/audit-trail-report.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AuditTrailReportsState} from '../_reducers/audit-trail-report.reducers';
import * as fromAuditTrailReport from '../_reducers/audit-trail-report.reducers';
import {each} from 'lodash';

export const selectAuditTrailReportsState = createFeatureSelector<AuditTrailReportsState>('auditTrailReports');

export const selectAuditTrailReportById = (auditTrailReportId: number) => createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => auditTrailReportsState.entities[auditTrailReportId]
);

export const selectAllAuditTrailReports = createSelector(
    selectAuditTrailReportsState,
    fromAuditTrailReport.selectAll
);

export const selectAllAuditTrailReportsIds = createSelector(
    selectAuditTrailReportsState,
    fromAuditTrailReport.selectIds
);

export const allAuditTrailReportsLoaded = createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => auditTrailReportsState.isAllAuditTrailReportsLoaded
);


export const selectAuditTrailReportsPageLoading = createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => auditTrailReportsState.listLoading
);

export const selectAuditTrailReportsActionLoading = createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => auditTrailReportsState.actionLoading
);

export const selectLastCreatedAuditTrailReportId = createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => auditTrailReportsState.lastCreatedAuditTrailReportId
);

export const selectAuditTrailReportsShowInitWaitingMessage = createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => auditTrailReportsState.showInitWaitingMessage
);


export const selectAuditTrailReportQueryResult = createSelector(
    selectAuditTrailReportsState,
    auditTrailReportsState => {
        const items: AuditTrailReport[] = [];
        each(auditTrailReportsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AuditTrailReport[] = httpExtension.sortArray(items, auditTrailReportsState.lastQuery.sortField, auditTrailReportsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, auditTrailReportsState.queryRowsCount, '');
    }
);
