import {KeyPlaceModel} from '../../admin';

export class ValuationDataModel {
    id: number;
    asset_class: any;
    valuation: any;
    key_places: KeyPlaceModel[];
    comparables: any[];
    tenures: {comId: number, selectedTenure: number}[]

    clear() {
        this.id = 1;
        this.asset_class = null;
        this.valuation = null;
        this.key_places = [];
        this.comparables = [];
        this.tenures = [];
    }
}