<div class="card card-custom">
    <div class="card-body-progress card-body-progress-overlay">
        <!-- here can place a progress bar-->
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
    </div>
    <div class="form">

        <div class="card-body">
            <form [formGroup]="form">
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12">
                        <div class="mb-4">
                            Has the ToE been altered since its generation?
                        </div>
                        <mat-radio-group 
                            (change)="radioChanged($event)"
                            formControlName="has_altered"
                            >
                            <mat-radio-button value="0">
                                No
                            </mat-radio-button>
                            <mat-radio-button value="1">
                                Yes
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid" *ngIf="showExplainSection">
                            <textarea matInput 
                                rows="2"
                                placeholder="Further Details"
                                formControlName="explain">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">Close</button>&nbsp;
                        <button mat-button (click)="save()" color="primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
