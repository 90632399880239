import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';

import { AuditTrailParticipantModel } from '../../../../../core/assignment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

const PARTICIPANTS_SIDE: string[] = ['None', 'Our Side', 'Client Side', 'External'];

@Component({
    selector: 'kt-participant-edit-dialog',
    templateUrl: './participant-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ParticipantEditDialogComponent implements OnInit, OnDestroy {

    // // Public properties
    participant: AuditTrailParticipantModel;
    participantForm: UntypedFormGroup;
    participant$: Observable<AuditTrailParticipantModel>;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    participantsSide: {name: string, value: string}[] = [];
    selectedParticipantSide: {name: string, value: string};

    // Private properties
    private componentSubscriptions: Subscription;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<ParticipantEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.participantsSide = [
            {name: 'None', value: 'None'},
            {name: `${this.data.agencyName} (The Agency)`, value: 'Our Side'},
            {name: this.data.toeSelected ? `${this.data.clientName} (The Client)` : 'The Client', value: 'Client Side'},
            {name: 'External', value: 'External'}
        ];
        
        if (this.data.participant) {
            this.participant = Object.assign({}, this.data.participant);
            this.selectedParticipantSide = this.participantsSide.find(p => p.value == this.participant.participants_side);
        } else {
            this.participant = new AuditTrailParticipantModel();
            this.participant.clear();
            this.selectedParticipantSide = this.participantsSide.find(p => p.value == this.participant.participants_side);
        }
        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
    createForm() {
        this.participantForm = this.fb.group({
            participants_side: [this.participant.participants_side, Validators.required],
            participants_names: [this.participant.participants_names, Validators.required],
            participants_descr: [this.participant.participants_descr]
        });
    }

    /** ACTIONS */

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        /** check form */
        if (!this.isNameValid()) {
            this.hasFormErrors = true;
            return;
        }
        
        const controls = this.participantForm.controls;
        /** check form */
        if (this.participantForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        
            this.hasFormErrors = true;
            return;
        }
        
        if (this.participant.id) {
            this.participant._isEditMode = true;
        }
        this.participant.participants_side = this.participantForm.controls.participants_side.value;
        
        this.dialogRef.close({
            participant: this.participant,
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        // this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.participant && this.participant.id) {
            return `Edit Participant`;
        }
        
        return 'New Participant';
    }

    /**
     * Returns is name valid
     */
    isNameValid(): boolean {
        return true;
        // return (this.participant && this.participant.participants_side) &&
        //     (this.participant && this.participant.participants_names && this.participant.participants_names.length > 0);
    }

}
