// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassResidentialFacilitiesService} from '../_services/asset-class-residential-facility.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AllAssetClassResidentialFacilitiesRequested,
    AllAssetClassResidentialFacilitiesLoaded,
    AssetClassResidentialFacilityActionToggleLoading,
    AssetClassResidentialFacilityActionTypes
} from '../_actions/asset-class-residential-facility.actions';

@Injectable()
export class AssetClassResidentialFacilityEffects {
    showActionLoadingDistpatcher = new AssetClassResidentialFacilityActionToggleLoading({isLoading: true});
    hideActionLoadingDistpatcher = new AssetClassResidentialFacilityActionToggleLoading({isLoading: false});

    @Effect()
    loadAllResidentialTypes$ = this.actions$
        .pipe(
            ofType<AllAssetClassResidentialFacilitiesRequested>(AssetClassResidentialFacilityActionTypes.AllAssetClassResidentialFacilitiesRequested),
            mergeMap(({payload}) => this.assetClassResidentialsService.getAllAssetClassResidentialFacilities(payload.assetClassResidentialId)),
            map(res => {
                return new AllAssetClassResidentialFacilitiesLoaded({assetClassResidentialFacilities: res.data});
            })
        );

    constructor(private actions$: Actions, private assetClassResidentialsService: AssetClassResidentialFacilitiesService, private store: Store<AppState>) {
    }
}
