export class ValuationNoteModel {
    id: number;
    front_id: number;
    user_id: number;
    user_name: string;
    input: string;
    status: boolean;
    created_at: Date;
    updated_at: Date;

    public clear() {
        this.id = undefined;
        this.front_id = undefined;
        this.user_id = undefined;
        this.user_name = null;
        this.input = null;
        this.status = false;
        this.created_at = new Date();
        this.updated_at = new Date();
    }
}