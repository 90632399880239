import { P } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { ToeSettlementService } from 'src/app/core/toe';

@Component({
  selector: 'kt-edit-settlement-info',
  templateUrl: './edit-settlement-info.component.html',
  styleUrls: ['./edit-settlement-info.component.scss']
})
export class EditSettlementInfoComponent implements OnInit {
  viewLoading = false;
  form: UntypedFormGroup;
  invoices: any[];
  toeSettlement: any;
  currency: string;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditSettlementInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private settlementService: ToeSettlementService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.toeSettlement = this.data.toeSettlement;
    this.currency = this.data.currency

    this.form = this.fb.group({
      amount: [this.data.toeSettlement.amount == 0 ? this.data.toeSettlement.balance : this.data.toeSettlement.amount],
      comment: [this.data.toeSettlement.comment, Validators.required],
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  save() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAllAsTouched();
      })
      return;
    }

    this.viewLoading = true;
    const changedInvoice = Object.assign({}, this.toeSettlement);
    changedInvoice.id = this.data.toeSettlement.id;
    changedInvoice.toe_id = this.data.toe.id;
    changedInvoice.task_id = this.data.toeSettlement.task_id;
    changedInvoice.balance = this.data.toeSettlement.balance;
    changedInvoice.amount = controls.amount.value;
    changedInvoice.comment = controls.comment.value;

    if (this.data.toeSettlement.id) {
      this.settlementService.updateToeSettlement(changedInvoice).subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({item: null});
      });
    } else {
      this.settlementService.createToeSettlement(changedInvoice).subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({item: null});
      });
    }
  }
}
