export class ToeAmendmentModel {
	id: number;
	toe_id: number;
	amendment_date: string;
	amendments: Amendment[];

	constructor (toeID: number) {
		this.id = undefined;
		this.toe_id = toeID;
		this.amendment_date = null;
		this.amendments = [];
	}
}

export enum AmendmentType {
	RemoveTP,
	AbortToe,
	None
}

interface IAmendment {
	id: number;
	type: AmendmentType
	setOrder(order: number)
}

export class RemoveTPAmendment implements IAmendment {
	id: number;
	type: AmendmentType;
	amendment_id: number;
	tp_id: number;
	tp_ids: number[];
	toe_id: number;
	reason: string;
	order: number;

	date_of_completion: string;
	change_date_of_completion: number;
	payment_arbitration: number;
	selected_term_ids: number[];

	paying_party: number;
	payed_party: number;
	paying_amount: number;

	comments: string;

	constructor(type: AmendmentType, amendmentID: number, order: number, toeID: number) {
		this.id = undefined;
		this.type = type;
		this.amendment_id = amendmentID;
		this.tp_id = undefined;
		this.toe_id = toeID;
		this.reason = null;
		this.order = order;

		this.date_of_completion = null; 
		this.change_date_of_completion = 0;
		this.payment_arbitration = 0;
		this.selected_term_ids = [];

		this.paying_party = undefined;
		this.payed_party = undefined;
		this.paying_amount = undefined;

		this.comments = null;
	}
	setOrder(order: number) {
		this.order = order;
	}
}


export class AbortToeAmendment implements IAmendment {
	id: number;
	type: AmendmentType;
	amendment_id: number;
	order: number;

	toe_id: number;
	reason: string;
	payment_arbitration: number;
	selected_term_ids: number[];

	paying_party: number;
	payed_party: number;
	paying_amount: number;

	comments: string;

	constructor(type: AmendmentType, amendmentID: number, order: number, toeID: number) {
		this.id = undefined;
		this.type = type;
		this.amendment_id = amendmentID;
		this.order = order;

		this.reason = null;
		this.payment_arbitration = 0;
		this.toe_id = toeID;
		this.selected_term_ids = [];

		this.paying_party = undefined;
		this.payed_party = undefined;
		this.paying_amount = undefined;

		this.comments = null;
	}

	setOrder(order: number) {
		this.order = order;
	}
}

export type Amendment
	= RemoveTPAmendment
	| AbortToeAmendment;