import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { CriterionsTemplateActions, CriterionsTemplateActionTypes } from "../_actions";
import { CriterionsTemplateModel } from "../_models";

export interface CriterionsTemplateState extends EntityState<CriterionsTemplateModel> {
    isLoading: boolean;
    total: number;
}
export const adapter: EntityAdapter<CriterionsTemplateModel> = createEntityAdapter<CriterionsTemplateModel>();
export const initialState: CriterionsTemplateState = adapter.getInitialState({
    isLoading: false,
    total: 0
});

export function criterionsTemplateReducer(state = initialState, action: CriterionsTemplateActions): CriterionsTemplateState {
    switch (action.type) {
        case CriterionsTemplateActionTypes.LOAD_CRITERIONS_TEMPLATES: {
            return {
                ...state,
                isLoading: true
            };
        }
        case CriterionsTemplateActionTypes.CRITERIONS_TEMPLATES_LOADED: {
            return adapter.setAll(action.payload.templates, {
                ...state,
                isLoading: false,
                total: action.payload.total
            })
        }
        case CriterionsTemplateActionTypes.ADD_CRITERIONS_TEMPLATE: {
            return state;
        }
        case CriterionsTemplateActionTypes.CRITERIONS_TEMPLATE_ADDED: {
            return adapter.addOne(action.payload.template, state);
        }
        case CriterionsTemplateActionTypes.UPDATE_CRITERIONS_TEMPLATE: {
            return state;
        }
        case CriterionsTemplateActionTypes.CRITERIONS_TEMPLATE_UPDATED: {
            return adapter.updateOne(action.payload.template, state);
        }
        case CriterionsTemplateActionTypes.DELETE_CRITERIONS_TEMPLATE: {
            return state;
        }
        case CriterionsTemplateActionTypes.CRITERIONS_TEMPLATE_DELETED: {
            return adapter.removeOne(action.payload.id, state);
        }
        default: {
            return state;
        }
    }
}