// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectExternalWallQueryResult, selectExternalWallsPageLoading, selectExternalWallsShowInitWaitingMessage} from '../_selectors/external-wall.selectors';

export class ExternalWallsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectExternalWallsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectExternalWallsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectExternalWallQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
