import {BaseModel} from '../../_base/crud';

export class ToeSettlement extends BaseModel {
    id: number;
    toe_id: number;
    task_id: number;
    balance: number;
    amount: number;
    comment: string;
    
    last_updated_by: any; 

    created_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.toe_id = undefined;
        this.task_id = undefined;
        this.balance = 0;
        this.amount = 0;
        this.comment = '';

        this.last_updated_by = undefined;

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
