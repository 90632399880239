import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

type ActionTypes = 'increase' | 'decrease';

@Component({
  selector: 'kt-simple-percent-input',
  templateUrl: './simple-percent-input.component.html',
  styleUrls: ['./simple-percent-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplePercentInputComponent implements OnInit {
  @Input() value: number;
  @Input() increaseButtonTooltip: string | undefined
  @Input() decreaseButtonTooltip: string | undefined
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() threshold: number | undefined
  @Output() onPercentChange = new EventEmitter<number>();

  currentVal = 0
  val$ = new BehaviorSubject<number>(this.currentVal)
  form = this._formBuilder.group({
    percent: [this.currentVal + '%']
  })

  constructor(
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this._updateFormValue(this.value)
  }

  changeVal(action: ActionTypes) {
    const currentValue = this._getPercentValueInNum()
    switch (action) {
      case 'decrease': {
        let newVal = currentValue - 5;
        if (newVal < this.min) {
          newVal = this.min
        }
        this._updateFormValue(newVal)
        this._sendChange(newVal)
        return;
      }
      case 'increase': {
        let newVal = currentValue + 5;
        if (newVal > this.max) {
          newVal = this.max
        }
        this._updateFormValue(newVal)
        this._sendChange(newVal)
        return;
      }
    }
  }

  onFocusOut(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    const _val = target.value.substring(0, target.value.length - 1);
    const val = Number(_val);
    this._updateFormValue(val);
    this._sendChange(val);
  }

  private _updateFormValue(val: number) {
    this.val$.next(val);
    this.form.controls.percent.setValue(val + '%')
    this.form.controls.percent.updateValueAndValidity()
  }

  private _sendChange(val: number) {
    this.onPercentChange.emit(val)
  }

  private _getPercentValueInNum(): number {
    const val = this.form.controls.percent.value;
    const numVal = Number(val.substring(0, val.length - 1));
    return numVal;
  }
}
