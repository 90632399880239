import { NgModule } from "@angular/core";
import { TabHeaderComponent } from "./tab-header.component";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTabsModule
  ],
  declarations: [TabHeaderComponent],
  exports: [TabHeaderComponent]
})
export class TabHeaderModule {}