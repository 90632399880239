import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ReportingActions, ReportingProcessActionTypes } from "../_actions/reporting_process.actions";
import { ReportingProcessData } from "../_models/reporting_process.model";

export interface ReportingProcessState extends EntityState<ReportingProcessData> {
}
export const adapter: EntityAdapter<ReportingProcessData> = createEntityAdapter();
export const initialReportingProcessState: ReportingProcessState = adapter.getInitialState({
});

export function reportingProcessReducer(state = initialReportingProcessState, action: ReportingActions): ReportingProcessState {
    switch (action.type) {
        case ReportingProcessActionTypes.ReportingRequested: {
            if (action.payload.id === undefined) {
                return state;
            }
            return state;
        }
        case ReportingProcessActionTypes.ReportingLoaded: {
            return adapter.setOne(action.payload.data, {...state});
        }
        default: {
            return state;
        }
    }
}