<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{data.modal_title}}
            </h3>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
                (click)="onNoClick()"
                class="btn btn-icon btn-sm btn-warning">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="form kt-form--label-align-right form-group-seperator-dashed">
        <mat-dialog-content>
            <div class="card-body">
                <div *ngFor="let item of items" class="mb-10 pt-5 pb-8 {{item.bg}}">
                    <div class="container">
                        <div class="row mb-4">
                            <div class="col-12">
                                <div class="d-flex w-100 align-items-center">
                                    <h5 class="card-title mr-1 mb-0">#{{item.ref}} - </h5>
                                    <a class="text-mad cursor-pointer d-flex justify-content-between align-items-baseline">
                                        <h5 class="card-title mr-1 mb-0">{{item.title}}</h5>
                                        <h6 class="mb-0" *ngIf="item.subtitle">({{item.subtitle}})</h6>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 pr-2" *ngIf="!item.hasPoints">
                                <ngb-carousel [interval]="0" *ngIf="item.pictures.length > 0; else noImage">
                                    <ng-template *ngFor="let img of item.pictures" ngbSlide>
                                        <img 
                                            [src]="img"
                                            (error)="imgOnError($event)"
                                            class="img-fluid rounded"
                                            style="object-fit: cover !important; object-position: center; aspect-ratio: 4 / 3; width: 100%"
                                        />
                                    </ng-template>
                                </ngb-carousel>
                                <ng-template #noImage>
                                    <div style="display: grid; place-content: center;" class="h-100">
                                        No Image is provided
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-8 pr-2" *ngIf="item.hasPoints">
                                <agm-map
                                    style="width: 100%; height: 500px"
                                    [latitude]="item.center.lat()"
                                    [longitude]="item.center.lng()"
                                    [zoom]="zoom"
                                    [usePanning]="true"
                                    [clickableIcons]="false"
                                    (mapReady)="mapReady($event)"
                                    [scrollwheel]="null">
                                    <agm-map-type-control></agm-map-type-control>
                                </agm-map>
                            </div>
                            <div class="col-4">
                                <div class="d-flex flex-column justify-content-between h-100">
                                    <div class="row">
                                        <div class="col-12">
                                            <p *ngFor="let field of item.fields">
                                                <strong class="text-mad">
                                                    {{field.header}} :
                                                </strong>
                                                <ng-container *ngIf="field.value">
                                                    <ng-container *ngIf="field.type == 'consideration'">
                                                        <br>
                                                        <strong *ngIf="field.value.strong">
                                                            {{field.value.strong}}
                                                        </strong>
                                                        <br>
                                                        <span *ngIf="field.value.simple">
                                                            {{field.value.simple}}
                                                        </span>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.type == 'land-consideration'">
                                                        <br>
                                                        <strong *ngIf="field.value.tenure">
                                                            {{field.value.tenure}}
                                                        </strong>
                                                        <br>
                                                        <strong *ngIf="field.value.consideration">
                                                            {{field.value.consideration}}
                                                        </strong>
                                                        <br>
                                                        <span *ngIf="field.value.date">
                                                            {{field.value.date}}
                                                        </span>
                                                        <br>
                                                        <span *ngIf="field.value.source">
                                                            {{field.value.source}}
                                                        </span>
                                                    </ng-container>
                                                    <ng-container *ngIf="!field.type">
                                                        <strong *ngIf="field.value.strong">
                                                            {{field.value.strong}}
                                                        </strong>
                                                        <span *ngIf="field.value.simple">
                                                            {{field.value.simple}}
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="!field.value">
                                                    <strong>N/I</strong>
                                                </ng-container>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-9 pl-4 pr-2">
                                            <ng-container *ngIf="item.status != -1">
                                                <ng-container *ngTemplateOutlet="statusBlock;context:{status:item.status}"></ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="item.status == -1">
                                                <ng-container *ngTemplateOutlet="usedTimesBlock;context:{used_times:item.used_times}"></ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="col-3 pl-2 pr-4">
                                            <ng-container *ngIf="!data.additional">
                                                <button mat-icon-button color="primary"
                                                    class="w-100 border border-primary rounded"
                                                    (click)="goEditForm(item.edit_url, item.id)">
                                                    <mat-icon>create</mat-icon>
                                                </button>
                                            </ng-container> 
                                            <ng-container *ngIf="data.additional && data.additional.type == 'open-modal'">
                                                <button mat-icon-button color="primary"
                                                    class="w-100 border border-primary rounded"
                                                    (click)="onAction(item.id)">
                                                    <mat-icon>remove_red_eye</mat-icon>
                                                </button>
                                            </ng-container>
                                        </div>
                                        <div class="col-12 pl-4 pr-4 pt-4" *ngIf="item.status != -1">
                                            <ng-container *ngTemplateOutlet="usedTimesBlock;context:{used_times:item.used_times}"></ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>

<ng-template #statusBlock let-status="status">
    <div class="border border-{{getItemCssClassByStatus(status)}} rounded h-100 d-flex justify-content-center align-items-center text-{{getItemCssClassByStatus(status)}}">
        {{getItemStatusString(status)}}
    </div>
</ng-template>
<ng-template #usedTimesBlock let-used_times="used_times">
    <div class="border border-secondary rounded h-100 text-center align-middle p-2">
        {{used_times == 0 
            ? 'Never used'
            : ('Used ' + used_times + ' time' + (used_times == 1 ? '' : 's'))}}
    </div>
</ng-template>