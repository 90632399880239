import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { ChangeBetweenLeaseAndFreeEventData } from '../result-data-types';
import { convertHeadlineRentToSale, convertRentToSale, convertSaleToRent } from 'src/app/core/v2/valuation';

export enum CalculationType {
  LeaseholdToFreehold = 1,
  FreeholdToLeasehold = 2,
  HeadlineToEffectiveToLeaseholdToFreehold = 3,
}

@Component({
  selector: 'kt-value-calculation',
  templateUrl: './value-calculation.component.html',
  styleUrls: ['./value-calculation.component.scss']
})
export class ValueCalculationComponent implements OnInit {
  @Input() currency: string;
  @Input() calculationType: CalculationType;
  @Input() ary: number;
  @Input() value$: Observable<number>;
  @Output() onValueComputed = new EventEmitter<ChangeBetweenLeaseAndFreeEventData>();

  title: string;
  form: UntypedFormGroup;
  computedValue$: Observable<number>;
  aryValue$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  subscriptions: Subscription[] = [];
  calculationTypeEnum = CalculationType;

  isValueComputed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  templateData$: Observable<{
    computedValue: number,
    ary: number
  }>

  constructor(
    private service: ConsiderationCriterionService
  ) { }

  ngOnInit(): void {
    switch (this.calculationType) {
      case CalculationType.FreeholdToLeasehold: {
        this.title = "leasehold rental";
        break;
      }
      default: {
        this.title = "freehold capital";
        break;
      }
    }

    this.form = new UntypedFormGroup({
      ary: new UntypedFormControl(this.ary, [Validators.required, notZeroValidator])
    });

    this.templateData$ = combineLatest([
      this.form.get('ary').valueChanges.pipe(
        startWith(this.form.get('ary').value)
      ),
      this.value$      
    ]).pipe(map(([aryVal, value]): {computedValue: number, ary: number} => {
      removeError(this.form.get('ary'), 'mask');
      const ary = Number(aryVal);
      if ((Number.isNaN(ary) || ary === 0) || value === undefined) {
        return {
          computedValue: undefined,
          ary: undefined
        };
      }

      const computedValue = this._computeValue(this.calculationType, ary, value);
      this.onValueComputed.emit({calculatedValue: computedValue, ary});
      return {
        computedValue: this.calculationType == CalculationType.FreeholdToLeasehold ? computedValue / 12 : computedValue, ary
      }
    }));
  }

  _computeValue(type: CalculationType, ary: number, val: number): number {
    switch (type) {
      case CalculationType.FreeholdToLeasehold: {
        // return this.service.convertFreeholdToLeaseHold(ary, val);
        return convertSaleToRent(ary, val)
      }
      case CalculationType.LeaseholdToFreehold: {
        // return this.service.convertLeaseholdToFreehold(ary, val);
        return convertRentToSale(ary, val)
      }
      case CalculationType.HeadlineToEffectiveToLeaseholdToFreehold: {
        // return this.service.convertHeadlineLeaseholdToFreehold(ary, val);
        return convertHeadlineRentToSale(ary, val)
      }
    }
  }

  isInValid(): boolean {
    if (this.form.invalid) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });
      return true;
    }
    return false;
  }
}

function notZeroValidator(control: AbstractControl): {[key: string]: boolean} | null {
  if ((control.value !== null && control.value !== '') && Number(control.value) == 0) {
    return {isZero: true}
  }
  return null;
}

function removeError(control: AbstractControl, error: string) {
  const err = control.errors; // get control errors
  if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
      control.setErrors(null); // set control errors to null making it VALID
      } else {
      control.setErrors(err); // controls got other errors so set them back
      }
  }
}
