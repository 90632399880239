import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { SubdomainService } from "../../_base/subdomain.service";

export interface ToeRelatedData {
    currency: string;
    valuationFees: number;
    additionalCosts: number;
    discountPercent: number;
    discountPrice: number;
    totalExclTax: number;
    vatPercent: number;
    vat: number;
    otherPercent: number;
    vatOther: number;
    totalInclTax: number;
    paymentTerms: Array<{
        id: number;
        order: number;
        term: string;
        date: Date;
        percent: number;
        amount: number;
        invoice: {
            status: string;
            invoice_amount: number;
        } | null;
    }>,
    parties: Array<{
        type: number,
        name: string
    }>,
    completionDate: string,
    measurement: string
}

@Injectable()
export class AbortToeAmendmentService implements OnDestroy {
    public ButtonStatus$: Observable<boolean>;

    private cached: ToeRelatedData = null;
    private _hasAbortToeAmendment: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient,
        // private authService: MadAuthService,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {
        this.ButtonStatus$ = this._hasAbortToeAmendment.pipe(map(hasAmendment => !hasAmendment));
    }

    ngOnDestroy() {
    }

    public getToeRelatedInfo(toeID: number): Observable<ToeRelatedData> {
        if (this.cached) {
            return of(this.cached);
        }
        const headers = this.authService.getAuthHeaders()
        return this.http.get<ToeRelatedData>(
            environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/` + toeID + '/amendments/toe-data',
            {headers}
        ).pipe(tap(res => this.cached = res));
    }

    public getAmendment(toeID: number) {
        const headers = this.authService.getAuthHeaders()
        this.http.get<any>(
            environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/` + toeID + '/amendments/abort-toe',
            {headers}
        ).subscribe(val => {
            this._hasAbortToeAmendment.next(val.has);
        });
    }

    public amendmentAdded() {
        this._hasAbortToeAmendment.next(true);
    }
    public amendmentRemoved() {
        this._hasAbortToeAmendment.next(false);
    }
}