<div class="form-group row margin-top-2 text-center item-center mb-0 pb-0">
    <div class="col-lg-12 mb-0 multimedia-header">
        <h5 class="my-3 text-black" *ngIf="hasTitle">Accommodation Layout</h5>
    </div>
</div>
<div class="mat-table-wrapper" [ngClass]="{'readonly': readonly}">
    <table class="table">
        <thead>
            <th colspan="4">Items</th>
            <th class="h-cell" *ngFor="let floor of floors">
                <ng-container *ngIf="schemeId == 1">
                    <span *ngIf="floor < 0">
                        Lower Ground ({{floor}})
                    </span>
                    <span *ngIf="floor == 0">
                        Ground
                    </span>
                    <span *ngIf="floor > 0">
                        Floor {{floor}}
                    </span>
                </ng-container>
                <ng-container *ngIf="schemeId == 2">
                    <span *ngIf="floor < 0">
                        Lower Ground ({{floor}}) 
                    </span>
                    <span *ngIf="floor == 1">
                        Floor 1 (Ground) 
                    </span>
                    <span *ngIf="floor > 1">
                        Floor {{floor}}
                    </span>
                </ng-container>
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let row of rows; let i = index" [ngClass]="{'gray': i%2}">
                <th class="row-header" colspan="4">
                    {{row.title}}
                </th>
                <td *ngFor="let floor of floors">
                    <span>
                        <input mask="0000"
                            class="title-input"
                            type="text"
                            (change)="onChange($event, row.key)"
                            [(ngModel)]="data[floor][row.key]" >
                    </span>
                </td>
            </tr>
            <tr *ngFor="let other of others; let i = index" [ngClass]="{'gray': i%2==1}">
                <th class="other-header">Other ({{i + 1}})</th>
                <th class="other-input" [attr.colspan]="i == 0 ? 2 : 1">
                    <input type="text" class="title-input" [value]="other" [(ngModel)]="other.title" />
                </th>
                <th  class="row-header btn-cell" style="text-align: center;" *ngIf="i > 0" >
                    <button mat-icon-button type="button" [disabled]="readonly" (click)="removeOther(i, other.id)">
                        <mat-icon color="warn">remove_circle</mat-icon>
                    </button>
                </th>
                <th  class="row-header btn-cell" style="text-align: center;">
                    <button mat-icon-button type="button" [disabled]="readonly" (click)="addOther(i)">
                        <mat-icon style="color: green;">add_circle</mat-icon>
                    </button>
                </th>
                <td *ngFor="let floor of floors">
                    <span><input type="text" mask="0000" class="title-input" [(ngModel)]="data[floor][other.id]"></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>