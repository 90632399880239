<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">View Team Template "{{template.name}}" (READ ONLY)</h3>
        </div>
        <div class="card-toolbar">
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <kt-team-template-body 
                [template]="template"
                [readonly]="true"></kt-team-template-body>
        </mat-dialog-content>
    </div>
</div>
