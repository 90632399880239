<kt-portlet>
    <kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-head-title">
                <span>{{ 'WAREHOUSE.MAP.TITLE.MAP_VIEW' | translate }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <div>
                <input #fileInput type="file" (change)="importComparable($event)" style="display: none" multiple accept="{{fileType}}"/>
            </div>
            <kt-context-menu-mad
                [(menuItems)]="menuItems"
                [(menuSubject)]="menuSubject"
                [width]="'230'"
                menuName="Import"
                class="ml-2">
            </kt-context-menu-mad>
            <button (click)="addComparable()" mat-raised-button
                    class="ml-2"
                    [matTooltip]="'WAREHOUSE.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button">
                <span>{{ 'WAREHOUSE.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button (click)="goToList()"
                    mat-raised-button
                    [matTooltip]="'WAREHOUSE.MAP.BUTTON.LIST_VIEW.TOOLTIP' | translate"
                    color="primary"
                    type="button"
                    class="ml-2">
                <span>{{ 'WAREHOUSE.MAP.BUTTON.LIST_VIEW.LABEL' | translate }}</span>
            </button>
            <button (click)="trash()" *ngIf="trashCnt != 0"
                mat-raised-button
                matTooltip="Deleted Warehouse Comparables"
                type="button"
                style="background-color: #c4c5d6 !important;"
                class="ml-2">
                <span>Trashed ({{trashCnt}})</span>
            </button>
            <button (click)="adminTrash()" *ngIf="adminTrashCnt != 0 && (canAccessAdminTrash$|async)"
                mat-raised-button
                matTooltip="Deleted Warehouse Comparables"
                type="button"
                style="background-color: #c4c5d6 !important;"
                class="ml-2">
                <span>Admin Trashed ({{adminTrashCnt}})</span>
            </button>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <div class="card-body pt-0">
            <kt-filter-section [acType]="7" [filter$]="filterObs$" (filterChange)="onFilterChange($event)"></kt-filter-section>
            <div class="form-group kt-form__group row">
                <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                    <agm-map
                            #AgmMap
                            [latitude]="centerLat$|async"
                            [longitude]="centerLng$|async"
                            [zoom]="zoom"
                            [disableDoubleClickZoom]="true"
                            [scrollwheel]="null"
                            [gestureHandling]="'cooperative'"
                            (mapReady)="mapReady($event)"
                            (mapClick)="mapClick($event)"
                            (centerChange)="centerChange($event)"
                            (zoomChange)="zoomChanged($event)"
                            (boundsChange)="boundsChanged($event)"
                            [clickableIcons]="false">
                        <agm-map-type-control></agm-map-type-control>

                        <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="setLocation()">
                            <mat-icon>my_location</mat-icon>
                        </button>

                        <agm-marker-cluster 
                            [calculator]="calculator"
                            imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">

                            <!-- animation="DROP" -->
                            <ng-container *ngIf="markerData$|async as mData">
                                <agm-marker *ngFor="let item of mData"
                                        style="min-width: 200px;"
                                        [latitude]="item.lat"
                                        [longitude]="item.lng"
                                        [label]="item.label"
                                        [iconUrl]="icon"
                                        (markerClick)="showInfo(item.data)">
                                </agm-marker>
                            </ng-container>
                        </agm-marker-cluster>
                    </agm-map>
                </div>
            </div>
       <div class="mat-table-wrapper">
           <mat-table class="lmat-elevation-z8 table-striped" 
                [dataSource]="dataSource"
                matSort
                #sort="matSort"
                matSortActive="id"
                matSortDirection="desc"
                matSortDisableClear>
               <ng-container matColumnDef="id">
                   <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                       {{ac.ref_num}}
                   </mat-cell>
               </ng-container>

                <ng-container matColumnDef="sub_type">
                    <mat-header-cell *matHeaderCellDef>
                        Sub-type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        {{acr.sub_type_category_name == 'Unknown'
                            ? 'Unknown'
                            : (acr.sub_type_category_name)}} 
                    </mat-cell>
                </ng-container>

               <ng-container matColumnDef="sub_category">
                   <mat-header-cell *matHeaderCellDef>
                       Sub Category
                   </mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                        {{ac.sub_category_name}}
                   </mat-cell>
               </ng-container>

               <ng-container matColumnDef="size">
                   <mat-header-cell *matHeaderCellDef>
                        Size
                   </mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                       {{ac.size}}
                   </mat-cell>
               </ng-container>

               <ng-container matColumnDef="consideration">
                   <mat-header-cell *matHeaderCellDef>
                        Consideration
                   </mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                        <div style="display: flex; flex-direction: column;" *ngIf="ac.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of ac.display_tenures">
                                <ng-container *ngIf="t.consideration_type_id === 1">
                                    {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}
                                </ng-container>
                                <ng-container *ngIf="t.consideration_type_id === 2">
                                    <ng-container *ngIf="t.rent_input_amount_type === 'total'">
                                        {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}/month
                                    </ng-container>
                                    <ng-container *ngIf="t.rent_input_amount_type === 'per_units'">
                                        {{(t.total_consideration * (t.rent_size ? t.rent_size.size : 0)).toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}/month
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-template #no_data>
                            {{ 'No Data' }}
                        </ng-template>
                   </mat-cell>
               </ng-container>

               <ng-container matColumnDef="source_date">
                   <mat-header-cell *matHeaderCellDef>
                        Date of information/transaction
                   </mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                        <div style="display: flex; flex-direction: column;" *ngIf="ac.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of ac.display_tenures">
                                {{t.source.source_date ? (t.source.source_date | dateWithOffset: ac.locationData.time_offset) : 'N/A'}}
                            </div>
                        </div>
                        <ng-template #no_data>
                            {{ 'No Data' }}
                        </ng-template>
                   </mat-cell>
               </ng-container>

               <ng-container matColumnDef="status">
                   <mat-header-cell *matHeaderCellDef>
                       Status
                   </mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                       <b>
                           <span class="label lable-inline label-rounded label-outline-{{getItemCssClassByStatus(ac.status)}} lable-outline-2x label-lg"
                            style="width: 10rem;">
                               {{getItemStatusString(ac.status)}}
                           </span>
                       </b>
                   </mat-cell>
               </ng-container>
                <ng-container matColumnDef="used_times">
                    <mat-header-cell *matHeaderCellDef>
                        Used (times)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let ac">
                        {{ac.used_times == 0
                            ? 'Never used'
                            : ('Used ' + ac.used_times + ' time' + (ac.used_times == 1 ? '' : 's'))}}
                    </mat-cell>
                </ng-container>
               <ng-container matColumnDef="actions">
                   <mat-header-cell *matHeaderCellDef>
                       Actions
                   </mat-header-cell>
                   <mat-cell *matCellDef="let ac">
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <p>
                                <b>Sub Category:</b> {{ac.sub_category_name}}
                            </p>
                            <p>
                                <b>Status:</b> {{getItemStatusString(ac.status)}}
                            </p>
                            <p>
                                <b>Used (times):</b>
                                {{ac.used_times == 0
                                    ? 'Never used'
                                    : ('Used ' + ac.used_times + ' time' + (ac.used_times == 1 ? '' : 's'))}}
                            </p>
                            <p [innerHTML] ="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                    username: ac.createdBy ? ac.createdBy : 'Unknown',
                                    date: (ac.created_at | date: 'dd MMM yyyy')
                                }">
                            </p>
                            <p [innerHTML] ="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                    username: ac.lastUpdatedBy ? ac.lastUpdatedBy : 'Unknown',
                                    date: (ac.updated_at | date: 'dd MMM yyyy')
                                }">
                            </p>
                        </ng-template>
                        <button mat-icon-button 
                            *ngIf="ac.status == 1"
                            matTooltip="Duplicate the Comparable"
                            (click)="duplicate(ac.id)">
                            <i [style.color]="ac.status !== 0 ? 'green' : 'grey'" class="flaticon2-layers"></i>
                        </button>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>
                       <button mat-icon-button color="primary" matTooltip="Edit Warehouse" (click)="editComparable(ac)">
                           <mat-icon>create</mat-icon>
                       </button>
                       <button mat-icon-button color="warn" type="button" (click)="deleteComparable(ac)">
                           <mat-icon>delete</mat-icon>
                       </button>
                   </mat-cell>
               </ng-container>

               <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
               <mat-row *matRowDef="let row; columns: displayedColumns; let index = index"
                [ngClass]="{gray: index%2}"></mat-row>
           </mat-table>
           <div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
           <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$|async">Please wait...</div>
       </div>
       <div class="mat-table-bottom">
           <mat-spinner [diameter]="20" *ngIf="dataSource.loading$|async"></mat-spinner>
           <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$|async" [showFirstLastButtons]="true"></mat-paginator>
       </div>
        </div>
    </kt-portlet-body>
</kt-portlet>