export class ToeAuditTaskReminderModel {
    id: number;
    toe_id: number;
    tp_task_id: number;
    audit_trail_id: number;

    type_id: number;
    type_name: string;

    tp_id: number;
    tp_name: string;

    interaction_type_id: number;
    interaction_type_name: string;

    date: Date;
    date_str: string;
    notes: string;

    user_id: number;
    user: string;

    status: number;

    created_at: Date;

    constructor() {
        this.toe_id = null
        this.tp_task_id = null
        this.audit_trail_id = null
        this.type_id = 1  // 1 - manual
        this.type_name = null
        this.tp_id = null
        this.tp_name = null
        this.interaction_type_id = null
        this.interaction_type_name = null
        this.date = new Date()
        this.notes = null
        this.status = 1
        this.created_at = new Date()
    }
}