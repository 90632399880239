import * as moment from "moment"
import { ConvertedCostRevenue } from "../../types/converted-cost-revenue"
import { round2, rounddown4 } from "./utils"

export const ModelAssumptionsUtil = {
  unexpiredLeaseTerm(costRevenue: ConvertedCostRevenue, today: moment.Moment, notRound: boolean = false) {
    if (!costRevenue.startDate) return costRevenue.leaseDuration
    const startDate = moment(costRevenue.startDate)

    const date = startDate.clone().add(
      costRevenue.durationType === 'months' ? costRevenue.leaseDuration : costRevenue.leaseDuration * 12,
      'M'
    )

    const yearDiff = Math.abs(today.diff(date, 'years'))
    const monthDiff = this._monthsDiff(today, date, 'unexpired')
    const value = yearDiff + (monthDiff / 12)
    return notRound 
      ? value
      : round2(value)
  },
  yearsToReview(costRevenue: ConvertedCostRevenue, today: moment.Moment, notRound: boolean = false) {
    if (!costRevenue.rentReviewCycle) return ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, today, notRound)
    if (!costRevenue.startDate) return costRevenue.rentReviewCycle
    const startDate = moment(costRevenue.startDate)
    const year = startDate.year() + (Math.floor((today.year() - startDate.year()) / costRevenue.rentReviewCycle) + 1) * costRevenue.rentReviewCycle
    const date = moment({
      year,
      month: startDate.month(),
      day: startDate.date()
    })
    const yearDiff = Math.abs(today.diff(date, 'years'))
    const monthDiff = this._monthsDiff(today, date)
    const value = yearDiff + monthDiff / 12
    return notRound
      ? value
      : round2(value)
  },
  yearsToWriteOff(costRevenue: ConvertedCostRevenue, today: moment.Moment, notRound: boolean = false) {
    if (!costRevenue.writeOffPeriod) return ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, today, notRound)
    if (!costRevenue.startDate) return costRevenue.writeOffPeriod
    const startDate = moment(costRevenue.startDate)
    const year = startDate.year() + (Math.floor((today.year() - startDate.year()) / costRevenue.writeOffPeriod) + 1) * costRevenue.writeOffPeriod
    const date = moment({
      year,
      month: startDate.month(),
      day: startDate.date()
    })
    const yearDiff = Math.abs(today.diff(date, 'years'))
    const monthDiff = this._monthsDiff(today, date)
    const value = yearDiff + monthDiff / 12
    return notRound
      ? value
      : round2(value)
  },
  yearsToBreakOff(costRevenue: ConvertedCostRevenue, today: moment.Moment, notRound: boolean = false) {
    if (!costRevenue.breakOptionAfter) return 0
    const startDate = moment(costRevenue.startDate)

    const date = startDate.clone().add(
      costRevenue.durationType === 'months' ? costRevenue.breakOptionAfter : costRevenue.breakOptionAfter * 12,
      'M'
    )

    if (date.isBefore(today)) {
      return 0
    }

    const yearDiff = Math.abs(today.diff(date, 'years'))
    const monthDiff = this._monthsDiff(today, date)
    const value = yearDiff + monthDiff / 12
    return notRound
      ? value
      : round2(value)
  },

  _monthsDiff(startDate: moment.Moment, endDate: moment.Moment, label?: string) {
    const yearsDiff = endDate.year() - startDate.year()
    const monthsDiff = endDate.month() - startDate.month()
    let totalMonths = yearsDiff * 12 + monthsDiff;
    if (endDate.date() < startDate.date()) {
      totalMonths--
    }
    return totalMonths % 12
  }
}