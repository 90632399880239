import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {BuildingType} from '../_models/building-type.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class BuildingTypesService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/building-types`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // BuildingTypes
    getAllBuildingTypes(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }


    findBuildingTypes(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getBuildingTypeById(buildingTypeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/${buildingTypeId}`, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new buildingType to the server
    createBuildingType(buildingType: BuildingType): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_URL, buildingType, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the buildingType on the server
    updateBuildingType(buildingType: BuildingType): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${buildingType.id}`, buildingType, {headers: httpHeaders});
    }

    // DELETE => delete the buildingType from the server
    deleteBuildingType(buildingTypeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_URL}/${buildingTypeId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }

    deleteFromTrash(agencyId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${agencyId}`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(agencyId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${agencyId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: httpHeaders});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
