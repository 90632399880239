import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SubdomainService {
  private _subdomain: string;

  constructor() {
    const link = window.location.href
    const {hostname} = new URL(link)
    const [subdomain] = hostname.split('.')

    this._subdomain = subdomain
  }

  public get subDomain() {
    return this._subdomain
  }
}