// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {SourceInformationActions, SourceInformationActionTypes} from '../_actions/source-information.actions';
import {SourceInformation} from '../_models/source-information.model';

export interface SourceInformationState extends EntityState<SourceInformation> {
    isAllSourceInformationLoaded: boolean;
    queryRowsCount: number;
    queryResult: SourceInformation[];
    lastCreatedSourceInformationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SourceInformation> = createEntityAdapter<SourceInformation>();

export const initialSourceInformationState: SourceInformationState = adapter.getInitialState({
    isAllSourceInformationLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSourceInformationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function sourceInformationReducer(state = initialSourceInformationState, action: SourceInformationActions): SourceInformationState {
    switch (action.type) {
        case SourceInformationActionTypes.SourceInformationPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSourceInformationId: undefined
            };
        case SourceInformationActionTypes.SourceInformationActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SourceInformationActionTypes.SourceInformationOnServerCreated:
            return {
                ...state
            };
        case SourceInformationActionTypes.SourceInformationCreated:
            return adapter.addOne(action.payload.sourceInformation, {
                ...state, lastCreatedSourceInformationId: action.payload.sourceInformation.id
            });
        case SourceInformationActionTypes.SourceInformationUpdated:
            return adapter.updateOne(action.payload.partialSourceInformation, state);
        case SourceInformationActionTypes.AllSourceInformationLoaded:
            return adapter.addAll(action.payload.sourceInformation, {
                ...state, isAllSourceInformationLoaded: true
            });
        case SourceInformationActionTypes.SourceInformationPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SourceInformationActionTypes.SourceInformationPageLoaded:
            return adapter.addMany(action.payload.sourceInformation, {
                ...initialSourceInformationState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case SourceInformationActionTypes.SourceInformationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SourceInformationActionTypes.SourceInformationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SourceInformationActionTypes.SourceInformationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SourceInformationActionTypes.SourceInformationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SourceInformationActionTypes.SourceInformationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SourceInformationActionTypes.SourceInformationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
