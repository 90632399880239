<div class="col-md-12 kt-margin-bottom-20-mobile pl-0 pr-0">

    <div ktPortletTitle class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">Building information</h5>
        <hr class="active">
    </div>

    <mat-tab-group [selectedIndex]="selected.value" #tabs
               (selectedIndexChange)="tabChange($event)">
        <mat-tab *ngFor="let tab of fakeArray(buildingList.length); let index = index" [label]="(index + 1) + ' - Building ' + (index + 1)">
            <ng-template matTabContent>
                <kt-lp-building-information
                    class="col-md-12"
                    [(buildingSubject)]="buildingSubject"
                    [(buildingsSubject)]="buildingsSubject"
                    [nth]="index"
                    [parentFormType]="2">
                </kt-lp-building-information>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>