import {BaseModel} from '../../_base/crud';

export class ValuationModel extends BaseModel {
    id: number;
    asset_class_id: number;
    asset_class_name: string;
    asset_class_type_id: number;
    property_type_name: string;
    base_of_value_id: number;
    base_of_value_name: string;
    property_type_id: number;
    approaches_to_value_id: number;
    approaches_to_value_name: string;
    methods_to_value_id: number;
    methods_to_value_name: string;
    group_id: number;
    group_name: string;
    selected_tenure_id: number;
    status: number;
    weightings_justification: string;
    adjusted_unit_rate: number;
    weighted_average_unit_rate: number;
    total_price: number;
    surface: number;
    valuation_key_places: any;
    inspection_type: number;
    estimated_value: number;
    percentage: number;
    per_size_value: number;
    daily_rate: number;
    days: number;

    premise_of_value_id: number;
    premise_of_value_name: string;
    tenure_id: number;
    tenure_name: string;
    tenure_custom: string;
    consideration_type_id: number;
    interest_valued: number;
    discount: number;
    comment: string;


    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;

        this.asset_class_id = undefined;
        this.asset_class_name = '';
        this.base_of_value_id = undefined;
        this.base_of_value_name = '';
        this.property_type_id = undefined;
        this.property_type_name = '';
        this.approaches_to_value_id = undefined;
        this.approaches_to_value_name = '';
        this.methods_to_value_id = undefined;
        this.methods_to_value_name = '';
        this.group_id = 1;
        this.group_name = '';
        this.surface = 0;
        this.total_price = 0;
        this.valuation_key_places = [];
        this.estimated_value = 0;
        this.percentage = 0;
        this.per_size_value = 0;
        this.daily_rate = 0;
        this.days = 0;

        this.premise_of_value_id = null;
        this.premise_of_value_name = null;
        this.tenure_id = null;
        this.tenure_name = null;
        this.tenure_custom = null;
        this.consideration_type_id = null;
        this.interest_valued = 100;
        this.discount = 0;
        this.comment = null;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
