// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CertificationActions, CertificationActionTypes} from '../_actions/certification.actions';
// Models
import {CertificationModel} from '../_models/certification.model';
import {QueryParamsModel} from '../../_base/crud';

export interface CertificationsState extends EntityState<CertificationModel> {
    isAllCertificationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: CertificationModel[];
    lastCreatedCertificationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CertificationModel> = createEntityAdapter<CertificationModel>();

export const initialCertificationState: CertificationsState = adapter.getInitialState({
    isAllCertificationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedCertificationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function certificationReducer(state = initialCertificationState, action: CertificationActions): CertificationsState {
    switch (action.type) {
        case CertificationActionTypes.CertificationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCertificationId: undefined
            };
        case CertificationActionTypes.CertificationActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case CertificationActionTypes.CertificationOnServerCreated:
            return {
                ...state
            };
        case CertificationActionTypes.CertificationCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedCertificationId: action.payload.item.id
            });
        case CertificationActionTypes.CertificationUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case CertificationActionTypes.AllCertificationsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllCertificationsLoaded: true
            });
        case CertificationActionTypes.CertificationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case CertificationActionTypes.CertificationsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialCertificationState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case CertificationActionTypes.CertificationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case CertificationActionTypes.CertificationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case CertificationActionTypes.CertificationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case CertificationActionTypes.CertificationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case CertificationActionTypes.CertificationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case CertificationActionTypes.CertificationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
