import {BaseModel} from '../../_base/crud';
import {PaymentTaxModel} from './payment-tax.model';

export class PaymentModel extends BaseModel {
    id: number;
    terms_of_engagement_id: number;
    is_validate: number;

    agency_currency_id: number;
    client_currency_id: number;
    exchange_rate: number;
    discount: number;
    percentage_upon_signature: number;
    vat: number;
    vat_other: number;
    pricing_strategy: number;
    total_price_amount: number;
    total_price_letters: string;
    price_upon_signature: number;

    initial_payment_date: string;
    final_payment_date: string;
    currency: string;
    payment_taxes: PaymentTaxModel[];

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;
        this.terms_of_engagement_id = undefined;
        this.is_validate = 0;

        this.agency_currency_id = undefined;
        this.client_currency_id = undefined;
        this.exchange_rate = 1;
        this.discount = 0;
        this.percentage_upon_signature = 0;
        this.vat = 0;
        this.vat_other = 0;
        this.pricing_strategy = 1;
        this.total_price_amount = 0;
        this.total_price_letters = '';
        this.price_upon_signature = 0;

        this.initial_payment_date = '';
        this.final_payment_date = '';
        this.currency = '';
        this.payment_taxes = [];

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
