import {SourceCredibility} from '../_models/source-credibility.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SourceCredibilityState} from '../_reducers/source-credibility.reducers';
import * as fromSourceCredibility from '../_reducers/source-credibility.reducers';
import {each} from 'lodash';

export const selectSourceCredibilityState = createFeatureSelector<SourceCredibilityState>('sourceCredibility');

export const selectSourceCredibilityById = (sourceCredibilityId: number) => createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => sourceCredibilityState.entities[sourceCredibilityId]
);

export const selectAllSourceCredibility = createSelector(
    selectSourceCredibilityState,
    fromSourceCredibility.selectAll
);

export const selectAllSourceCredibilityIds = createSelector(
    selectSourceCredibilityState,
    fromSourceCredibility.selectIds
);

export const allSourceCredibilityLoaded = createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => sourceCredibilityState.isAllSourceCredibilityLoaded
);


export const selectSourceCredibilityPageLoading = createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => sourceCredibilityState.listLoading
);

export const selectSourceCredibilityActionLoading = createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => sourceCredibilityState.actionLoading
);

export const selectLastCreatedSourceCredibilityId = createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => sourceCredibilityState.lastCreatedSourceCredibilityId
);

export const selectSourceCredibilityShowInitWaitingMessage = createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => sourceCredibilityState.showInitWaitingMessage
);

export const selectTrashedSourceCredibilityCount = createSelector(
    selectSourceCredibilityState,
    (sourceCredibilityState) => sourceCredibilityState.totalTrashed
);

export const selectAdminTrashedSourceCredibilityCount = createSelector(
    selectSourceCredibilityState,
    (sourceCredibilityState) => sourceCredibilityState.totalAdminTrashed
);


export const selectSourceCredibilityQueryResult = createSelector(
    selectSourceCredibilityState,
    sourceCredibilityState => {
        const items: SourceCredibility[] = [];
        each(sourceCredibilityState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SourceCredibility[] = httpExtension.sortArray(items, sourceCredibilityState.lastQuery.sortField, sourceCredibilityState.lastQuery.sortOrder);

        return new QueryResultsModel(result, sourceCredibilityState.totalCount, '', sourceCredibilityState.totalTrashed);
    }
);
