import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TpTaskModel} from '../_models/tp-task.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class TpTaskService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/tp-tasks`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService :SubdomainService) {
    }

    getAll(payload?: {toeID?: number, tpId?: number}): Observable<any> {
        let params: HttpParams = null;
        if (payload) {
            if (payload.toeID) {
                params = new HttpParams().set('toe_id', payload.toeID.toString());
            }
            if (payload.tpId) {
                params = new HttpParams().set('tp_id', payload.tpId.toString());
            }
        }
        return this.http.get<any>(this.API_URL, {headers: this.authService.getAuthHeaders(), params});
    }

    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(itemId: number): Observable<TpTaskModel> {
        return this.http.get<TpTaskModel>(this.API_URL + `/${itemId}`, {headers: this.authService.getAuthHeaders()});
    }

    getByUserId(queryParams: QueryParamsModel, userId: number): Observable<any> {
        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
        };
        return this.http.post<any>(this.API_URL + `/by-user/${userId}`, _body, {headers: this.authService.getAuthHeaders()});
    }

    // CREATE =>  POST: add a new _item to the server
    create(item: TpTaskModel): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {_item: item};

        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the _item on the server
    update(_item: TpTaskModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_URL + `/${_item.id}`, _item, {headers: httpHeaders});
    }

    updateMany(id: number, user_id: number, date: string) {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${id}`, {
            bulk_update: true,
            user_id,
            date
        }, {headers: httpHeaders});
    }

    // DELETE => delete the _item from the server
    delete(itemId: number): Observable<any> {
        const url = `${this.API_URL}/${itemId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {headers: this.authService.getAuthHeaders()});
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: this.authService.getAuthHeaders()});
    }

    deleteFromTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    // delete from admin trash
    deleteFromAdminTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    updateManualTask({id, completed, completed_at}: {id: number, completed: boolean, completed_at: string}): Observable<any> {
        return this.http.post<any>(this.API_URL + `/manual/${id}`, {completed, completed_at}, {headers: this.authService.getAuthHeaders()});
    }

    updateDueDiligenceTask({id, completed, completed_at}: {id: number, completed: boolean, completed_at: string}): Observable<any> {
        return this.http.post<any>(this.API_URL + `/due-diligence/${id}`, {completed, completed_at}, {headers: this.authService.getAuthHeaders()});
    }

    getDeliveryTask(taskID: number): Observable<{id: number, is_delivered: boolean, date: Date}|null> {
        return this.http
            .get<{data: {id: number, is_delivered: boolean, date: Date}}|null>
            (this.API_URL + `/delivery/${taskID}`, {headers: this.authService.getAuthHeaders()})
            .pipe(map(res => !res ? null : res.data));
    }
    storeDeliveryTask(taskID: number, body: {is_delivered: boolean, date: string}): Observable<{id: number, is_delivered: boolean, date: Date}> {
        return this.http.post<{data: {id: number, is_delivered: boolean, date: Date}}>(this.API_URL + `/delivery/${taskID}`, body, {headers: this.authService.getAuthHeaders()})
            .pipe(map(res => res.data));
    }
    updateDeliveryTask(taskID: number, body: {is_delivered: boolean, date: string}): Observable<{id: number, is_delivered: boolean, date: Date}> {
        return this.http.put<{data: {id: number, is_delivered: boolean, date: Date}}|null>
            (this.API_URL + `/delivery/${taskID}`, body, {headers: this.authService.getAuthHeaders()})
            .pipe(map(res => !res ? null : res.data));
    }

    createOrUpdateDeliveryTasks(tasks: {task_id: number, is_delivered: boolean, date: string}[]): Observable<any> {
        return this.http.put(this.API_URL + `/delivery/update`, {tasks: tasks}, {headers: this.authService.getAuthHeaders()});
    }

    getAuditTask(taskID: number): Observable<{id: number, created: number}> {
        return this.http.get<{data: {id: number, created: number}}|null>(this.API_URL + `/audit-trail/${taskID}`, {
            headers: this.authService.getAuthHeaders()
        }).pipe(map(res => !res ? null : res.data));
    }
    createOrUpdateAuditTrailTask(task: {task_id: number, created: number}): Observable<any> {
        return this.http.put(this.API_URL + `/audit-trail/update`, {task}, {headers: this.authService.getAuthHeaders()});
    }
    getTpTodos(): Observable<any> {
        return this.http.get(this.API_URL + `/todos`, {headers: this.authService.getAuthHeaders()});
    }

    addScenarioToValuationTask(id: number, index: number, type: 'blank' | 'duplicate'): Observable<any> {
        return this.http.post(this.API_URL + `/${id}/scenario`, {index, scenario_type: type}, {headers: this.authService.getAuthHeaders()})
    }

    deleteScenarioOfValuationTask(id: number): Observable<any> {
        return this.http.delete(this.API_URL + `/${id}/scenario`, {headers: this.authService.getAuthHeaders()})
    }

    updateMethodOfValuationTask(id: number, method_id: number): Observable<any> {
        return this.http.put(this.API_URL + `/${id}/scenario`, {method_id}, {headers: this.authService.getAuthHeaders()})
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
