<div class="card card-custom">
    <div class="card-header">
        <div class="card-title"></div>
        <div class="card-toolbar">
            <div ktPortletTools class="mb-1 row justify-content-end">
                <div *ngIf="(isInProgress$ | async)" class="mr-2">
                    <div style="margin-bottom: 14px;">Uploading... {{ progress$ | async }}%</div>
                </div>
        
                <div class="error" *ngIf="(hasFailed$ | async)" class="mr-2">
                    Error: {{ error$ | async }}
                </div>
        
                <div *ngIf="(isInProgress$ | async)" class="mr-2">
                    <span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">Cancel Upload</span>
                </div>
        
                <div *ngIf="!readonly" class="mr-2">
                    <span class="btn btn-primary" mat-raised-button (click)="addFiles()">Upload file</span>
                    <input #fileInput type="file" (change)="uploadFile($event)" style="display: none" accept="{{fileType}}"/>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <img [attr.src]="setIcon(getFileType(file.name))" alt="">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Filename</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{excludeExtention(file.name)}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="size">
                <mat-header-cell *matHeaderCellDef>Size</mat-header-cell>
                <mat-cell *matCellDef="let file">{{file.size}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{file.title}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="descr">
                <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
                <mat-cell *matCellDef="let file"> {{file.descr}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let file">
                    <button mat-icon-button
                            color="primary"
                            matTooltip="Edit Description"
                            [disabled]="readonly"
                            (click)="editFileDescription(file)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            color="warn"
                            matTooltip="Delete File"
                            [disabled]="readonly"
                            (click)="deleteUploadedFile(file)"
                            type="button">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            matTooltip="Preview file"
                            (click)="previewUploadedFile(file)"
                            type="button">
                        <mat-icon *ngIf="!docs.includes(getFileType(file.name))">remove_red_eye</mat-icon>
                        <mat-icon *ngIf="docs.includes(getFileType(file.name))">get_app</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="{'colored-row': i%2}"></mat-row>
        </mat-table>
    </div>
</div>