// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TrainingDocument} from '../_models/training-document.model';

export enum TrainingDocumentActionTypes {

    AllTrainingDocumentsRequested = '[TrainingDocuments Home Page] All TrainingDocuments Requested',
    AllTrainingDocumentsLoaded = '[TrainingDocuments API] All TrainingDocuments Loaded',

    TrainingDocumentOnServerCreated = '[Edit TrainingDocument Dialog] TrainingDocument On Server Created',
    TrainingDocumentCreated = '[Edit TrainingDocuments Dialog] TrainingDocuments Created',
    TrainingDocumentUpdated = '[Edit TrainingDocument Dialog] TrainingDocument Updated',
    TrainingDocumentDeleted = '[TrainingDocuments List Page] TrainingDocument Deleted',

    // trash system
    TrainingDocumentOnServerRestored = '[TrainingDocument Trash] TrainingDocument On Server Restored',
    TrainingDocumentRestored = '[TrainingDocument Trash] TrainingDocument Restored',

    TrainingDocumentOnServerAdminRestored = '[TrainingDocument Admin Trash] TrainingDocument On Server Restored',
    TrainingDocumentAdminRestored = '[TrainingDocument Admin Trash] TrainingDocument Restored',

    TrainingDocumentDeletedFromTrash = '[TrainingDocument Trash] TrainingDocument deleted',
    TrainingDocumentDeletedFromAdminTrash = '[TrainingDocument Admin Trash] TrainingDocument deleted',

    TrainingDocumentTrash = 'TrainingDocument Trashed',
    TrainingDocumentTrashFlushed = 'TrainingDocument Trash Flushed',

    TrainingDocumentsPageRequested = '[TrainingDocuments List Page] TrainingDocuments Page Requested',
    TrainingDocumentsPageLoaded = '[TrainingDocuments API] TrainingDocuments Page Loaded',
    TrainingDocumentsPageCancelled = '[TrainingDocuments API] TrainingDocuments Page Cancelled',

    TrainingDocumentsPageToggleLoading = '[TrainingDocuments page] TrainingDocuments Page Toggle Loading',
    TrainingDocumentsActionToggleLoading = '[TrainingDocuments] TrainingDocuments Action Toggle Loading'
}

export class TrainingDocumentOnServerCreated implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentOnServerCreated;

    constructor(public payload: { trainingDocument: TrainingDocument, fileList: any[] }) {
    }
}

export class TrainingDocumentCreated implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentCreated;

    constructor(public payload: { trainingDocument: TrainingDocument }) {
    }
}

export class TrainingDocumentUpdated implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentUpdated;

    constructor(public payload: {
        partialtrainingDocument: Update<TrainingDocument>,
        trainingDocument: TrainingDocument,
        fileList: any[]
    }) {
    }
}

export class TrainingDocumentDeleted implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class TrainingDocumentsPageRequested implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class TrainingDocumentsPageLoaded implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentsPageLoaded;

    constructor(public payload: {
        trainingDocuments: TrainingDocument[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class TrainingDocumentsPageCancelled implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentsPageCancelled;
}

export class AllTrainingDocumentsRequested implements Action {
    readonly type = TrainingDocumentActionTypes.AllTrainingDocumentsRequested;
}

export class AllTrainingDocumentsLoaded implements Action {
    readonly type = TrainingDocumentActionTypes.AllTrainingDocumentsLoaded;

    constructor(public payload: { trainingDocuments: TrainingDocument[] }) {
    }
}

export class TrainingDocumentsPageToggleLoading implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class TrainingDocumentsActionToggleLoading implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class TrainingDocumentDeletedFromAdminTrash implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class TrainingDocumentDeletedFromTrash implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TrainingDocumentOnServerRestored implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TrainingDocumentRestored implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentRestored;

    constructor(public payload: { item: TrainingDocument }) {
    }
}

export class TrainingDocumentOnServerAdminRestored implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TrainingDocumentAdminRestored implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentAdminRestored;

    constructor(public payload: { item: TrainingDocument }) {
    }
}

export class TrainingDocumentTrashFlushed implements Action {
    readonly type = TrainingDocumentActionTypes.TrainingDocumentTrashFlushed;

    constructor() {
    }
}

export type TrainingDocumentActions = TrainingDocumentCreated
    | TrainingDocumentUpdated
    | TrainingDocumentDeleted
    | TrainingDocumentsPageRequested
    | TrainingDocumentsPageLoaded
    | TrainingDocumentsPageCancelled
    | AllTrainingDocumentsLoaded
    | AllTrainingDocumentsRequested
    | TrainingDocumentOnServerCreated
    | TrainingDocumentsPageToggleLoading
    | TrainingDocumentDeletedFromAdminTrash
    | TrainingDocumentDeletedFromTrash
    | TrainingDocumentOnServerRestored
    | TrainingDocumentRestored
    | TrainingDocumentOnServerAdminRestored
    | TrainingDocumentAdminRestored
    | TrainingDocumentTrashFlushed
    | TrainingDocumentsActionToggleLoading;
