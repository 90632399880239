import {BaseModel} from '../../_base/crud';

export class InteractionType extends BaseModel {
    id: number;
    name: string;
    relation_cnt: number;
    is_hidden: boolean;
    is_system_entry: boolean

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    deletedBy: string;
    user_deleted: Date;
    userDeletedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.relation_cnt = 0;
        this.is_hidden = false;
        this.is_system_entry = false

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;

        this.deletedBy = '';
        this.userDeletedBy = '';
        this.user_deleted = new Date();
    }
}
