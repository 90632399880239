<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Terms and Conditions for Beta Use</h3>
            <span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
    </div>
    <div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="kt-portlet__body kt-padding-0">


            <div class="p-10 w-100">
                <p>
                    Welcome and thank you for participating in the beta testing phase of our solution. This document outlines the terms and conditions under which you may use this beta version. Your use of the solution indicates your acceptance of these terms.
                </p>
                <div class="terms-conditions-listing">
                    <p><b>Beta Version:</b> Please be aware that this solution is currently in a "beta" stage. This means it is still under development and may contain errors or inaccuracies. We invite your feedback and suggestions for improvement.</p>
                    <p><b>Limited Use:</b> The beta version of this solution is provided for testing purposes only. It is not intended for commercial use or for critical data management.</p>
                    <p><b>Confidentiality:</b> As a beta tester, you may have access to sensitive and confidential information. We ask you to respect the confidentiality of this information and not disclose it to third parties.</p>
                    <p><b>Feedback and Data Collection:</b> Your feedback is valuable to us. By using this beta version, you agree that we may collect data regarding your use of the solution, which will be used to enhance and improve future versions.</p>
                    <p><b>No Warranty:</b> The beta version of this solution is provided "as-is" without any warranties of any kind. We do not guarantee its functionality, reliability, or availability.</p>
                    <p><b>Limitation of Liability:</b> Our liability to you in relation to the use of our beta version solution is limited. We are not responsible for any loss or damage arising from your use of the beta version.</p>
                </div>
            
                <p>
                    By using this beta version, you acknowledge that you have read, understood, and agreed to these Terms and Conditions
                </p>
            </div>

            <div class="card-footer b-0 text-right">
                <div class="form-actions form-actions--solid">
                    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="1" cdkFocusInitial>Close</button>&nbsp;
                </div>
            </div>
        </div>
    </div>
</div>
