import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: '[noNeg]'
})
export class NoNegativeDirective {
    constructor(el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent) {
        const targetElement: HTMLInputElement = event.target as HTMLInputElement;
        if (event.key === '-') {
            event.preventDefault();
        }
    }
}