import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { HttpUtilsService } from '../../_base/crud';
import { PropertyDetailReportModel } from '../_models/property-detail-report.model';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class PropertyDetailReportService {
    API_PROPERTY_DETAIL_REPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/asset-class-detail/report`;
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    getReports(tpID: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_PROPERTY_DETAIL_REPORT_URL + 'ByTp' + `/${tpID}`, {
            headers: httpHeaders
        }).pipe(catchError(err => {
            return EMPTY;
        }));
    }

    getReport(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_PROPERTY_DETAIL_REPORT_URL + `/${id}`, {
            headers: httpHeaders
        });
    }

    createPropertyDetailReport(report: PropertyDetailReportModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_PROPERTY_DETAIL_REPORT_URL, report, {
            headers: httpHeaders
        });
    }

    updateReport(report: PropertyDetailReportModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_PROPERTY_DETAIL_REPORT_URL + `/${report.id}`, report, {
            headers: httpHeaders
        });
    }

    deleteReport(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_PROPERTY_DETAIL_REPORT_URL + `/${id}`, {
            headers: httpHeaders
        });
    }

    generatePropertyDetailReport(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_PROPERTY_DETAIL_REPORT_URL + `-generate/${id}`, {
            headers: httpHeaders
        });
    }
}