import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationReportActions, ValuationReportActionTypes } from "../_actions/valuation-report.actions";
import { ValuationReportModel } from "../_models/valuation-report.model";

export interface ValuationReportState extends EntityState<ValuationReportModel> {
	lastCreatedReportId: number;
    isGenerating: Record<number, boolean>;
    isAllReportsLoaded: Record<number, boolean>;
}
export const adapter: EntityAdapter<ValuationReportModel> = createEntityAdapter<ValuationReportModel>();
export const initialValuationReportState: ValuationReportState = adapter.getInitialState({
    lastCreatedReportId: undefined as number,
    isGenerating: {} as Record<number, boolean>,
    isAllReportsLoaded: {} as Record<number, boolean>
})

export function valuationReportReducer(
    state = initialValuationReportState,
    action: ValuationReportActions
): ValuationReportState {
    let currentLoadedReports: Record<number, boolean>;
    let currentIsGenerating: Record<number, boolean>;

    switch (action.type) {
        case ValuationReportActionTypes.ValuationReportOnServerCreated: {
            currentIsGenerating = Object.assign({}, state.isGenerating);
            currentIsGenerating[action.payload.report.tp_id] = true;
            return {
                ...state, isGenerating: currentIsGenerating
            }
        } 
        case ValuationReportActionTypes.ValuationReportCreated: {
            currentIsGenerating = Object.assign({}, state.isGenerating);
            currentIsGenerating[action.payload.report.tp_id] = false;
            return adapter.addOne(action.payload.report, {
                ...state, lastCreatedReportId: action.payload.report.id, isGenerating: currentIsGenerating
            })
        }
        case ValuationReportActionTypes.ValuationReportUpdated: {
            return adapter.updateOne(action.payload.partial, state);
        }
        case ValuationReportActionTypes.AllValuationReportsRequested: {
            currentLoadedReports = Object.assign({}, state.isAllReportsLoaded);
            currentLoadedReports[action.payload.tpID] = false;
            return {
                ...state, isAllReportsLoaded: currentLoadedReports
            }
        }
        case ValuationReportActionTypes.AllValuationReportsLoaded: {
            currentLoadedReports = Object.assign({}, state.isAllReportsLoaded);
            currentLoadedReports[action.payload.tpId] = true;
            currentIsGenerating = Object.assign({}, state.isGenerating);
            currentIsGenerating[action.payload.tpId] = false;
            return adapter.upsertMany(action.payload.reports, {
                ...state, isAllReportsLoaded: currentLoadedReports, isGenerating: currentIsGenerating
            })
        }
        case ValuationReportActionTypes.ValuationReportOnServerDeleted: {
            return adapter.removeOne(action.payload.id, state);
        }
        case ValuationReportActionTypes.ValuationReportLoaded: {
            return adapter.upsertOne(action.payload.report, state);
        }
        case ValuationReportActionTypes.ValuationReportStateResetted:
            return adapter.removeAll(state);
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();