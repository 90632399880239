// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {
    selectSourceInformationQueryResult,
    selectSourceInformationPageLoading,
    selectSourceInformationShowInitWaitingMessage
} from '../_selectors/source-information.selectors';

export class SourceInformationDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectSourceInformationPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectSourceInformationShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectSourceInformationQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
