
<div class="col-12 section-margin-bottom-60">
    <agm-map
        [latitude]="tpLat"
        [longitude]="tpLng"
        [zoom]="15"
        [fitBounds]="true"
        [clickableIcons]="false"
        [scrollwheel]="null"
        [gestureHandling]="'cooperative'"
        [draggable]="false"
        [disableDoubleClickZoom]="true"
        (mapReady)="onMapReady($event)">
        <agm-map-type-control></agm-map-type-control>
        <agm-marker
            [agmFitBounds]="true"
            [latitude]="tpLat"
            [longitude]="tpLng"
            [iconUrl]="tpIcon">
        </agm-marker>
        <ng-container *ngIf="cLocations$|async as cLocations">
            <ng-container *ngFor="let cLoc of cLocations">
                <agm-marker
                    [agmFitBounds]="true"
                    [latitude]="cLoc.lat"
                    [longitude]="cLoc.lng"
                    [label]="cLoc.label"
                    [iconUrl]="icon"
                >
                </agm-marker>
                <agm-polyline [strokeColor]="'red'">
                    <agm-polyline-point [latitude]="cLoc.lat" [longitude]="cLoc.lng"></agm-polyline-point>
                    <agm-polyline-point [latitude]="tpLat" [longitude]="tpLng"></agm-polyline-point>
                </agm-polyline>
            </ng-container>
        </ng-container>   
    </agm-map>
</div>



<div class="col-12">
    <div class="card card-custom">
        <div class="card-header">
            <div class="card-title"></div>
            <div class="card-toolbar">
                <div *ngIf="comparables.length">
                    <button (click)="showMultipleOverview()" mat-raised-button
                            [matTooltip]="'Show info of selected comparable'"
                            class="float-right m-0 btn"
                            type="button">
                        <span>Overview</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="mat-table-wrapper">
                <ng-container *ngIf="templateData$|async as tData">
                    <table class="table" style="overflow-y: hidden;">
                        <thead>
                            <tr>
                                <th [class]="'empty-header-' + tData.comparableInfo.length"></th>
                                <th [class]="'com-header-' + tData.comparableInfo.length" *ngFor="let cInfo of tData.comparableInfo; let i = index">
                                        Comparable {{i+1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Database ID</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.ref_num}}
                                </td>
                            </tr>
                            <tr class="colored-row" *ngIf="targetProperty.type_id == 1 || targetProperty.type_id == 3">
                                <th>Building ID</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.buildingID}}
                                </td>
                            </tr>
                            <tr>
                                <th>Size ({{tData.sizeStandard}}) ({{tData.sizeUnit}})</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.size ? cInfo.size : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Consideration ({{tData.tenure}}) {{tData.tenure === 'Leasehold' ? '(per year)' : ''}} ({{tData.currency}})</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{ cInfo.consideration 
                                        ? (cInfo.consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="has-bottom-border">
                                <th>Date of information/transaction</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.dateType}} - {{cInfo.date|date:'dd MMM yyyy'}} - ({{cInfo.date|dateAgo}})
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Total Adjustment (%) ({{tData.numOfAdjustments}} adjustment{{tData.numOfAdjustments !== 1 ? 's' : ''}})</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.totalAdjustment}}%
                                </td>
                            </tr>
                            <tr>
                                <th>Adjusted consideration {{tData.tenure === 'Leasehold' ? '(per year)' : ''}} ({{tData.currency}})</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.adjustedConsideration 
                                        ? (cInfo.adjustedConsideration.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Weighting (%)</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.weighting}}%
                                </td>
                            </tr>
                            <tr class="has-bottom-border">
                                <th>Weighted Average Gross Unit Rate {{tData.tenure === 'Leasehold' ? '(per year)' : ''}} ({{tData.currency}}/{{tData.sizeUnit}})</th>
                                <td *ngFor="let cInfo of tData.comparableInfo">
                                    {{cInfo.weightedAvgGrossUnitRate
                                        ? (cInfo.weightedAvgGrossUnitRate.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                        : 'N/A'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table">
                        <thead>
                            <tr>
                                <th [class]="'empty-header-' + tData.comparableInfo.length"></th>
                                <th [class]="'tp-header-' + tData.comparableInfo.length">
                                    Target Property
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Target property {{tData.tenure === 'Leasehold' ? 'rental value (per year)' : 'value'}} ({{tData.currency}}/{{tData.sizeUnit}})</th>
                                <td [colSpan]="tData.comparableInfo.length">
                                    <strong>
                                        {{tData.tpValueInfo.valueInUnitSize 
                                            ? (tData.tpValueInfo.valueInUnitSize.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                            : 'N/A'}}
                                    </strong>
                                </td>
                            </tr>
                            <tr class="colored-row">
                                <th>Target property {{tData.tenure === 'Leasehold' ? 'rental value (per year)' : 'value'}} ({{tData.currency}})</th>
                                <td [colSpan]="tData.comparableInfo.length">
                                    <strong>
                                        {{tData.tpValueInfo.valueInCurrency
                                            ? (tData.tpValueInfo.valueInCurrency.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                            : 'N/A'}}
                                    </strong>
                                </td>
                            </tr>
                            <ng-container *ngIf="tData.capitalAllowance != 0">
                                <tr>
                                    <th>
                                        Capital Allowance ({{tData.currency}})
                                        <button mat-icon-button
                                            color="primary"
                                            placement="top"
                                            [container]="'body'"
                                            (click)="$event.stopPropagation()"
                                            [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.CAPITAL_ALLOWANCE.DESCRIPTION' | translate"
                                        >
                                            <mat-icon>info</mat-icon>
                                        </button>
                                    </th>
                                    <td [colSpan]="tData.comparableInfo.length">
                                        <strong>
                                            {{tData.capitalAllowance.toFixed(2) | mask:'separator':{thousandSeparator: ','} }}
                                        </strong>
                                    </td>
                                </tr>
                                <tr class="colored-row">
                                    <th>Justification</th>
                                    <td [colSpan]="tData.comparableInfo.length">
                                        <strong>
                                            {{tData.capitalAllowanceJustification}}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total Target property {{tData.tenure === 'Leasehold' ? 'rental value (per year)' : 'value'}} ({{tData.currency}}/{{tData.sizeUnit}})</th>
                                    <td [colSpan]="tData.comparableInfo.length">
                                        <strong>
                                            {{tData.tpValueInfo.valueWithCapitalAllowanceInUnitSize
                                                ? (tData.tpValueInfo.valueWithCapitalAllowanceInUnitSize.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                                : 'N/A'}}
                                        </strong>
                                    </td>
                                </tr>
                                <tr class="colored-row">
                                    <th>Total Target property {{tData.tenure === 'Leasehold' ? 'rental value (per year)' : 'value'}} ({{tData.currency}})</th>
                                    <td [colSpan]="tData.comparableInfo.length">
                                        <strong>
                                            {{tData.tpValueInfo.valueWithCapitalAllowance
                                                ? (tData.tpValueInfo.valueWithCapitalAllowance.toFixed(2) | mask:'separator':{thousandSeparator: ','})
                                                : 'N/A'}}
                                        </strong>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
            </div>
        </div>
    </div>
</div>

