<div class="col-lg-12" [ngClass]="{'readonly': readonly}">
    <div class="row">
        <div class="col-lg-12 gallery">
            <div class="card card-custom">
                <div class="card-body">
                    <!--	<div class="gallery-title">
						Target Property Pictures
					</div> -->
                    <!-- <div class="gallery-container" *ngIf="type == 'video'">

                        <div *ngFor="let vid of videos; let i = index">
                            <div [ngClass]="videoIsSelected(i)">

                                <img [src]="vid.pic_url" (error)="imgOnError($event)">
                                <button mat-icon-button color="red"
                                    (click)="clickOnButton(vid.title, vid.description, vid.video_player_url)"
                                    style="left: 40%; position: absolute; top: 40%; z-index: 4; width: 44px; height: 44px;"
                                    type="button">
                                
                                    <i class="flaticon2-arrow"
                                        [ngStyle]="{'color': type == 'video' ? 'red' : 'blue', 'font-size': '2.5rem'}"></i>
                                </button>
                                <div class="row" (click)="clickOnRadio($event)">
                                    <mat-radio-group id="radioGrp" (change)="radioGroupChange($event, i)">
                                        <div class="col-lg-6 checkboxR">
                                            <mat-radio-button [value]="1" [checked]="isSelected(i)">
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gallery-container" *ngIf="type != 'video'">

                        <div *ngFor="let link of links; let i = index">
                            <div [ngClass]="videoIsSelected(i)">

                                <video preload="metadata" *ngIf="!link.url.includes('youtube.com')">
                                    <source [src]="link.url" type="video/mp4">
                                </video>

                                <iframe *ngIf="link.url.includes('youtube.com')" [src]="embeddedUrl">
                                </iframe>

                                <button mat-icon-button color="red"
                                    (click)="clickOnButton(link.title, link.description, link.url)"
                                    style="left: 40%; position: absolute; top: 40%; z-index: 4; width: 44px; height: 44px;"
                                    type="button">
                                    <i class="flaticon2-arrow"
                                        [ngStyle]="{'color': type == 'video' ? 'red' : 'blue', 'font-size': '2.5rem'}"></i>
                                </button>
                                <div class="row" (click)="clickOnRadio($event)">
                                    <mat-radio-group id="radioGrp" (change)="radioGroupChange($event, i)">
                                        <div class="col-lg-6 checkboxR">
                                            <mat-radio-button [value]="1" [checked]="isSelected(i)">
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>

                    </div> -->


                    <div class="gallery-container">

                        <div *ngFor="let link of vidMatterList; let i = index">
                            <div [ngClass]="videoIsSelected(i)">

                                <video preload="metadata" *ngIf="link.type != 'video' && !link.is_youtube_url">
                                    <source [src]="link.url" type="video/mp4">
                                </video>

                                <iframe *ngIf="link.type != 'video' && link.is_youtube_url" [src]="link.url">
                                </iframe>
                                <img *ngIf="link.type == 'video'" [src]="link.thumbnail_url"
                                    (error)="imgOnError($event)">
                                <button mat-icon-button color="red"
                                    (click)="clickOnButton(link.title, link.description, link.url, link.is_youtube_url)"
                                    style="left: 40%; position: absolute; top: 40%; z-index: 4; width: 44px; height: 44px;"
                                    type="button">
                                    <i class="flaticon2-arrow"
                                        [ngStyle]="{'color': type == 'video' ? 'red' : 'blue', 'font-size': '2.5rem'}"></i>
                                </button>
                                <div class="row" (click)="clickOnRadio($event)">
                                    <mat-radio-group id="radioGrp" (change)="radioGroupChange($event, i)">
                                        <div class="col-lg-6 checkboxR">
                                            <mat-radio-button value="1" [checked]="isSelected(i)">
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>