import { DatePipe } from "@angular/common";
import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'dateWithOffset'})
export class DateWithOffsetPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, timezoneOffset: string | null) {
    if (!value) {
      return 'N/A'
    }

    const date = this.datePipe.transform(value, 'dd MMM yyyy')
    const offset = timezoneOffset ? (' (GMT ' + timezoneOffset + ')') : ''
    // Take down GMT
    // return date + offset
    return date
  }
}
