<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ title$ | async }}
      </h3>
    </div>
    <div class="card-toolbar">
      <a href="javascript:;" (click)="onNoClick()" class="btn btn-icon btn-sm btn-warning">
        <i class="ki ki-close icon-nm"></i>
      </a>
    </div>
  </div>
  <mat-dialog-content>
    <div class="card-body">
      <ng-container *ngIf="!(loading$|async); else loading">
        <div *ngFor="let data of (datasource$|async)" class="mb-10 pt-5 pb-8 {{data.bg}}">
          <div class="container">
            <div class="row mb-4">
              <div class="col-12">
                <div class="d-flex w-100 align-items-center">
                  <h5 class="card-title mr-1 mb-0">#{{data.refNum}} - </h5>
                  <a class="text-mad cursor-pointer d-flex justify-content-between align-items-baseline">
                    <h5 class="card-title mr-1 mb-0">{{data.title}}</h5>
                    <h6 class="mb-0" *ngIf="data.subtitle">({{data.subtitle}})</h6>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8 pr-2" *ngIf="data.points.length == 0">
                <ngb-carousel [interval]="0" *ngIf="data.pictures.length > 0; else noImage">
                  <ng-template *ngFor="let img of data.pictures" ngbSlide>
                    <img [src]="img" (error)="imgOnError($event)" class="img-fluid rounded"
                      style="object-fit: cover !important; object-position: center; aspect-ratio: 4 / 3; width: 100%" />
                  </ng-template>
                </ngb-carousel>
                <ng-template #noImage>
                  <div style="display: grid; place-content: center;" class="h-100">
                    No Image is provided
                  </div>
                </ng-template>
              </div>
              <!-- Show Map -->
              <div class="col-8 pr-2" *ngIf="data.points.length != 0">
                <agm-map
                  style="width: 100%; height: 500px"
                  [latitude]="data.latitude"
                  [longitude]="data.longitude"
                  [zoom]="15"
                  [usePanning]="true"
                  [clickableIcons]="false"
                  (mapReady)="onMapReady($event, data.points)"
                  [scrollwheel]="null">
                  <agm-map-type-control></agm-map-type-control>
                </agm-map>
              </div>
              <div class="col-4">
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="row">
                    <div class="col-12">
                      <p *ngFor="let field of data.fields">
                        <strong class="text-mad">
                          {{field.header}} :
                        </strong>
                        <ng-container *ngIf="field.value">
                          <ng-container *ngIf="field.type == 'consideration'">
                            <br>
                            <strong *ngIf="field.value.strong">
                              {{field.value.strong}}
                            </strong>
                            <br>
                            <span *ngIf="field.value.simple">
                              {{field.value.simple}}
                            </span>
                          </ng-container>
                          <ng-container *ngIf="field.type == 'land-consideration'">
                            <br>
                            <strong *ngIf="field.value.tenure">
                              {{field.value.tenure}}
                            </strong>
                            <br>
                            <strong *ngIf="field.value.consideration">
                              {{field.value.consideration}}
                            </strong>
                            <br>
                            <span *ngIf="field.value.date">
                              {{field.value.date}}
                            </span>
                            <br>
                            <span *ngIf="field.value.source">
                              {{field.value.source}}
                            </span>
                          </ng-container>
                          <ng-container *ngIf="!field.type">
                            <strong *ngIf="field.value.strong">
                              {{field.value.strong}}
                            </strong>
                            <span *ngIf="field.value.simple">
                              {{field.value.simple}}
                            </span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!field.value">
                          <strong>N/I</strong>
                        </ng-container>
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="hasAddButton || hasShowInfoButton">
                    <div class="{{hasAddButton || hasShowInfoButton ? 'col-5' : 'col-12'}} pl-4 pr-2">
                      <ng-container *ngIf="data.status != -1">
                        <ng-container *ngTemplateOutlet="statusBlock;context:{status:data.status}"></ng-container>
                      </ng-container>
                      <ng-container *ngIf="data.status == -1">
                        <ng-container *ngTemplateOutlet="usedTimesBlock;context:{used_times:data.usedTimes}">
                        </ng-container>
                      </ng-container>
                    </div>

                    <div class="col-4 pl-2 pr-2" *ngIf="hasAddButton">
                      <ng-container>
                        <button mat-stroked-button [color]="data.selection.selected ? 'warn' : 'primary' " [class]="'w-100 h-100 border ' + (data.selection.selected ? 'border-danger' : 'border-primary') + ' rounded'"
                          (click)="onAdd(data.selection.action, data.refNum)">
                          {{ data.selection.buttonText }}
                        </button>
                      </ng-container>
                    </div>

                    <div class="col-3 pl-2 pr-4" *ngIf="hasShowInfoButton">
                      <ng-container>
                        <button mat-icon-button color="primary" class="w-100 border border-primary rounded"
                          (click)="onOpenInfoModal(data.refNum)">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </ng-container>
                    </div>

                    <div class="col-12 pl-4 pr-4 pt-4" *ngIf="data.status != -1">
                      <ng-container *ngTemplateOutlet="usedTimesBlock;context:{used_times:data.usedTimes}">
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
</div>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>

<ng-template #statusBlock let-status="status">
  <div
    class="border border-{{getItemCssClassByStatus(status)}} rounded h-100 d-flex justify-content-center align-items-center text-{{getItemCssClassByStatus(status)}}">
    {{getItemStatusString(status)}}
  </div>
</ng-template>
<ng-template #usedTimesBlock let-used_times="used_times">
  <div class="border border-secondary rounded h-100 text-center align-middle p-2">
    {{used_times == 0
    ? 'Never used'
    : ('Used ' + used_times + ' time' + (used_times == 1 ? '' : 's'))}}
  </div>
</ng-template>