import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { FilterModel } from '../filter-section.component';

@Component({
  selector: 'kt-other-filters',
  templateUrl: './other-filters.component.html',
  styleUrls: ['./other-filters.component.scss']
})
export class OtherFiltersComponent implements OnInit, OnDestroy {
  @Input() assetType: number;
  @Input() filter: FilterModel;
  @Input() resetCmd$: Observable<void>;
  @Output() onApply: EventEmitter<FilterModel> = new EventEmitter();

  count = 0;
  resetSubscription: Subscription;
  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.resetSubscription = this.resetCmd$.subscribe(() => {
      this.count = 0;
    });
  }

  ngOnDestroy(): void {
    this.resetSubscription.unsubscribe();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onClick() {
    // this.counFilters(this.filter);
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '1000px',
      data: {
        acType: this.assetType,
        filter: this.filter
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      this.count = this.counFilters(res);
      this.onApply.emit(res);
    })
  }

  private counFilters(filter: FilterModel): number {
    let count = 0;
    const fields = ['sub_type_category_id', 'key_category_id', 'sub_category_id', 'status', 'use', 'startDate', 'endDate', 'size_unit_id', 'top_building_type_id', 'q_name', 'city_name', 'centerLat', 'centerLng'];
    Object.keys(filter).filter(key => !fields.includes(key)).forEach(key => {
      if (filter[key] != null) {
        count += 1
      }
    })
    return count;
  }

}
