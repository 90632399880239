export class ExternalFacadeModel {
    id: number;
    name: string;
    relation_cnt: number;
    is_hidden: boolean;
    is_system_entry: boolean;

    property_sub_type_id: number;
    property_sub_type_name: string;

    user_deleted_by: string;
    user_deleted: Date;

    deleted_by: string;
    deleted_at: Date;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.name = null;
        this.property_sub_type_id = undefined;
        this.property_sub_type_name = '';
        this.relation_cnt = 0
        this.is_hidden = false
        this.is_system_entry = false

        this.user_deleted_by = null;
        this.user_deleted = null;

        this.deleted_by = null;
        this.deleted_at = null;

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}