// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectOfficeStandardMeasurementQueryResult, selectOfficeStandardMeasurementsPageLoading, selectOfficeStandardMeasurementsShowInitWaitingMessage} from '../_selectors/office-standard-measurement.selectors';

export class OfficeStandardMeasurementsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectOfficeStandardMeasurementsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectOfficeStandardMeasurementsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectOfficeStandardMeasurementQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
