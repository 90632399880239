// NGRX
import {EntityState, EntityAdapter, createEntityAdapter, Update} from '@ngrx/entity';
// Actions
import {TpTaskActions, TpTaskActionTypes} from '../_actions/tp-task.actions';
import {TpTaskModel} from '../_models/tp-task.model';

// Models
import {QueryParamsModel} from '../../_base/crud';

export interface TpTaskState extends EntityState<TpTaskModel> {
    _isAllTpTaskLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedItemId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TpTaskModel> = createEntityAdapter<TpTaskModel>();

export const initialTpTaskState: TpTaskState = adapter.getInitialState({
    _isAllTpTaskLoaded: false,
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedItemId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function tpTaskReducer(state = initialTpTaskState, action: TpTaskActions): TpTaskState {
    switch (action.type) {
        case TpTaskActionTypes.AllTpTaskRequested:
            return {
                ...state,
                _isAllTpTaskLoaded: false,
                listLoading: true,
                actionsLoading: true
            };
        case TpTaskActionTypes.AllTpTaskLoaded:
            return adapter.addAll(action.payload.tasks, {
                ...state,
                _isAllTpTaskLoaded: true,
                listLoading: false,
                actionsLoading: false
            });
        case TpTaskActionTypes.TpTaskCleared:
            return adapter.removeAll(state);

        case TpTaskActionTypes.TpTaskDeleted:
            return adapter.removeOne(action.payload.id, state);

        case TpTaskActionTypes.TpTaskOnServerCreated:
            return {
                ...state
            };
        case TpTaskActionTypes.TpTaskCreated:

            return adapter.addOne(action.payload.task, {
                ...state
            });

        case TpTaskActionTypes.TpTaskUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case TpTaskActionTypes.TpTaskByUserPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.assetClasses, {
                ...initialTpTaskState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case TpTaskActionTypes.AllTpTaskLoadedByUser:
            return adapter.addMany(action.payload.tasks, {
                ...initialTpTaskState,
                _isAllTpTaskLoaded: true,
                listLoading: false,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
            });
        case TpTaskActionTypes.TpTasksClear:
            return adapter.removeAll(initialTpTaskState);
        case TpTaskActionTypes.ManualTpTaskUpdated:
            return adapter.upsertOne(action.payload.item, {...state});
        case TpTaskActionTypes.DueDiligenceTpTaskUpdated:
            return adapter.upsertOne(action.payload.item, {...state});
        case TpTaskActionTypes.UpdateSignedDocUploadTask: 
            return adapter.updateOne(action.payload.partial, state);
        case TpTaskActionTypes.TpTaskBulkUpdated:
            return adapter.updateMany(action.payload.updates, state);
        case TpTaskActionTypes.ValuationTaskScenarioAdded: {
            return adapter.upsertMany(action.payload.item, state);
        }
        case TpTaskActionTypes.ValuationTaskScenarioDeleted: {
            const interState = adapter.removeOne(action.payload.delete_item_id, state)
            return adapter.upsertMany(action.payload.update_items, interState)
        }
        case TpTaskActionTypes.ValuationTaskMethodUpdated: {
            const update: Update<TpTaskModel> =  {
                id: action.payload.task.id,
                changes: {
                    ...action.payload.task
                }
            }
            return adapter.updateOne(update, state)
        }
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
