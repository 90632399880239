// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {RicsStandard} from '../_models/rics-standard.model';

export enum RicsStandardActionTypes {

    AllRicsStandardsRequested = '[RicsStandards Home Page] All RicsStandards Requested',
    AllRicsStandardsLoaded = '[RicsStandards API] All RicsStandards Loaded',

    RicsStandardOnServerCreated = '[Edit RicsStandard Dialog] RicsStandard On Server Created',
    RicsStandardCreated = '[Edit RicsStandards Dialog] RicsStandards Created',
    RicsStandardUpdated = '[Edit RicsStandard Dialog] RicsStandard Updated',
    RicsStandardDeleted = '[RicsStandards List Page] RicsStandard Deleted',

    RicsStandardsPageRequested = '[RicsStandards List Page] RicsStandards Page Requested',
    RicsStandardsPageLoaded = '[RicsStandards API] RicsStandards Page Loaded',
    RicsStandardsPageCancelled = '[RicsStandards API] RicsStandards Page Cancelled',

    RicsStandardsPageToggleLoading = '[RicsStandards page] RicsStandards Page Toggle Loading',
    RicsStandardsActionToggleLoading = '[RicsStandards] RicsStandards Action Toggle Loading',

    // trash system

    RicsStandardOnServerRestored = '[RicsStandard Trash] RicsStandard On Server Restored',
    RicsStandardRestored = '[RicsStandard Trash] RicsStandard Restored',

    RicsStandardOnServerAdminRestored = '[RicsStandard Admin Trash] RicsStandard On Server Restored',
    RicsStandardAdminRestored = '[RicsStandard Admin Trash] RicsStandard Restored',

    RicsStandardDeletedFromTrash = '[RicsStandard Trash] RicsStandard deleted',
    RicsStandardDeletedFromAdminTrash = '[RicsStandard Admin Trash] RicsStandard deleted',

    RicsStandardTrash = 'RicsStandard Trashed',
    RicsStandardTrashFlushed = 'RicsStandard Trash Flushed',
}

export class RicsStandardOnServerCreated implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardOnServerCreated;

    constructor(public payload: { ricsStandard: RicsStandard}) {
    }
}

export class RicsStandardCreated implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardCreated;

    constructor(public payload: { ricsStandard: RicsStandard }) {
    }
}

export class RicsStandardUpdated implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardUpdated;

    constructor(public payload: {
        partialricsStandard: Update<RicsStandard>,
        ricsStandard: RicsStandard
    }) {
    }
}

export class RicsStandardDeleted implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RicsStandardsPageRequested implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class RicsStandardsPageLoaded implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardsPageLoaded;

    constructor(public payload: { ricsStandards: RicsStandard[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class RicsStandardsPageCancelled implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardsPageCancelled;
}

export class AllRicsStandardsRequested implements Action {
    readonly type = RicsStandardActionTypes.AllRicsStandardsRequested;
}

export class AllRicsStandardsLoaded implements Action {
    readonly type = RicsStandardActionTypes.AllRicsStandardsLoaded;

    constructor(public payload: { ricsStandards: RicsStandard[] }) {
    }
}

export class RicsStandardsPageToggleLoading implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RicsStandardsActionToggleLoading implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class RicsStandardDeletedFromAdminTrash implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class RicsStandardDeletedFromTrash implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RicsStandardOnServerRestored implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RicsStandardRestored implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardRestored;

    constructor(public payload: { item: RicsStandard }) {
    }
}

export class RicsStandardOnServerAdminRestored implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RicsStandardAdminRestored implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardAdminRestored;

    constructor(public payload: { item: RicsStandard }) {
    }
}

export class RicsStandardTrashFlushed implements Action {
    readonly type = RicsStandardActionTypes.RicsStandardTrashFlushed;

    constructor() {
    }
}

export type RicsStandardActions = RicsStandardCreated
    | RicsStandardUpdated
    | RicsStandardDeleted
    | RicsStandardsPageRequested
    | RicsStandardsPageLoaded
    | RicsStandardsPageCancelled
    | AllRicsStandardsLoaded
    | AllRicsStandardsRequested
    | RicsStandardOnServerCreated
    | RicsStandardsPageToggleLoading
    | RicsStandardsActionToggleLoading
    | RicsStandardDeletedFromAdminTrash
    | RicsStandardDeletedFromTrash
    | RicsStandardOnServerRestored
    | RicsStandardRestored
    | RicsStandardOnServerAdminRestored
    | RicsStandardAdminRestored
    | RicsStandardTrashFlushed;
