import { Action } from '@ngrx/store';
import { AssetClassSourceExternalReferenceModel } from '../_models/asset-class-source-external-reference.model';
import { Update } from '@ngrx/entity';

export enum AssetClassSERActionTypes {
    AllAssetClassSERRequested = '[AssetClassSER] AssetClassSER Requested',
    AllAssetClassSERLoaded = '[AssetClassSER] AssetClassSER Loaded',

    AssetClassSEROnServerUpdated = '[AssetClassSEr] AssetClassSER On Server Updated',
    AssetClassSERUpdated = '[AssetClassSER] AssetClassSER Updated',

    AllAssetClassSEROfTPRequested = '[Valuation Process Page] All AssetClassSER of Tp requested'
}

export class AllAssetClassSERRequested implements Action {
    readonly type = AssetClassSERActionTypes.AllAssetClassSERRequested;

    constructor() {}
}

export class AllAssetClassSEROfTPRequested implements Action {
    readonly type = AssetClassSERActionTypes.AllAssetClassSEROfTPRequested
    constructor(public payload: {tpId: number}) {}
}

export class AllAssetClassSERLoaded implements Action {
    readonly type = AssetClassSERActionTypes.AllAssetClassSERLoaded;

    constructor(public payload: {
        sers: AssetClassSourceExternalReferenceModel[]
    }) {}
}

export class AssetClassSEROnServerUpdated implements Action {
    readonly type = AssetClassSERActionTypes.AssetClassSEROnServerUpdated;

    constructor(public payload: {
        ser: AssetClassSourceExternalReferenceModel
    }) {}
}

export class AssetClassSERUpdated implements Action {
    readonly type = AssetClassSERActionTypes.AssetClassSERUpdated;

    constructor(public payload: {
        partialSER: Update<AssetClassSourceExternalReferenceModel>
    }) {}
}

export type AssetClassSERActions =
    AssetClassSERUpdated |
    AllAssetClassSERRequested |
    AllAssetClassSERLoaded |
    AssetClassSEROnServerUpdated |
    AllAssetClassSEROfTPRequested;
