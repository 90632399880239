<div class="form-group kt-form__group row" [formGroup]="form" *ngIf="form">
    <div class="col-lg-12 kt-margin-bottom-20-mobile section-margin-top-60">
        <div class="d-flex justify-content-between">
            <h5 class="my-3 text-mad text-uppercase">
                The Building
            </h5>
            <div>
                <button type="button"
                    class="btn btn-primary mr-2"
                    mat-raised-button
                    *ngIf="buildingId"
                    (click)="clearBuilding()">
                    Clear Copied Building Data
                </button>
                <button type="button"
                    class="btn btn-primary"
                    mat-raised-button
                    (click)="showExist()">
                    Copy data from existing Building
                </button>
            </div>
        </div>
        <hr class="active">
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Building ID" formControlName="building_name">
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Completion year" formControlName="completion_year" >
            <mat-error>
                Completion year <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-checkbox class="mat-form-field-fluid margin-top-12" formControlName="is_part_of_property">
            Part of larger property
        </mat-checkbox>
    </div>
    <div class="col-lg-12" *ngIf="form.controls.is_part_of_property.value == 1">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Information about this Property" formControlName="info_about_property"></textarea>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Building Type" formControlName="building_type">
                <mat-option *ngFor="let type of buildingTypes" [value]="type.id">
                    {{type.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                Building type <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="mat-form-field-fluid" *ngIf="form.controls.building_type.value != null">
            <input matInput placeholder="Building Type Comment" formControlName="building_type_comment" >
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Building Grade" formControlName="building_grade_id">
                <mat-option *ngFor="let grade of grades" [value]="grade.id">
                    {{grade.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                Building grade <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Energy Efficiency Grade" formControlName="energy_efficiency_grade_id">
                <mat-option *ngFor="let grade of grades" [value]="grade.id">
                    {{grade.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Developer" formControlName="developer" >
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Primary anchor tenant" formControlName="anchor_tenant"> 
        </mat-form-field>
    </div>
    <div class="col-lg-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Foundation type" formControlName="foundation_type_id">
                <mat-option *ngFor="let type of foundationTypes" [value]="type.id">
                    {{type.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-12">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Construction description" formControlName="building_description"></textarea>
        </mat-form-field>
    </div>
</div>