<div class="form-group row section-margin-top-60" >
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">
            CONDITION RATING
        </h5>
        <hr class="active">
    </div>
    <div class="col-md-12">
        <kt-condition-rating-table-v2
            [componentDataSource$]="components$"
            [conditionRatingDataSource$]="conditionRatings$"
            [schemeId$]="schemeId$"
            [editable]="true"
            (conditionRatingChanged)="onConditionRatingChanged($event)">
        </kt-condition-rating-table-v2>
    </div>
</div>