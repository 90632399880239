import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    AppendicesService
} from '../../../../../core/admin';

@Injectable()
export class AppendixResolver implements Resolve<any> {
    constructor(public service: AppendicesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const itemId = Number(route.params.id);
        return this.service.getById(itemId);
    }
}
