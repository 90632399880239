import { BaseModel } from "../../_base/crud";
import { ReportType } from "./report-types.enum";

export class DraftStatementReportModel extends BaseModel {
	id: number;
	toe_id: number;
    tp_id: number;
    status: number;

    type = ReportType.DraftStatementReport;

    url: string;
    file_size: string;
    file_name: string;
    description: string;

    generated_user_id: number;
    generated_user_name: string;

    created_at: Date;
    updated_at: Date;

    public clear() {
        this.id = undefined;
        this.tp_id = undefined;
        this.status = undefined;
        
        this.url = "";
        this.file_size = "";
        this.file_name = "";

        this.generated_user_id = undefined;
        this.generated_user_name = "";
    }

	public setsEssentials(toeID: number, acID: number, userID: number) {
		this.toe_id = toeID;
		this.tp_id = acID;
		this.generated_user_id = userID;
		this.status = 1;
	}
}