import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CriterionsTemplateDataSource, CriterionsTemplateModel, CriterionsTemplateState, LoadCriterionsTemplates } from 'src/app/core/template';
import { PaginationEvent } from 'src/app/views/pages/template/template-list/template-list.component';

@Component({
  selector: 'kt-criterion-template-list',
  templateUrl: './criterion-template-list.component.html',
  styleUrls: ['./criterion-template-list.component.scss']
})
export class CriterionTemplateListComponent implements OnInit {
  dataSource: CriterionsTemplateDataSource;
  pageSize = 4;

  constructor(
    public dialogRef: MatDialogRef<CriterionTemplateListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<CriterionsTemplateState>
  ) { 
  }

  ngOnInit(): void {
    this.dataSource = new CriterionsTemplateDataSource(this.store);
    this.store.dispatch(new LoadCriterionsTemplates({
      request: {
        pageNumber: 1,
        pageSize: this.pageSize,
        assetClassTypeID: this.data.assetClassTypeId
      }
    }));
  }

  loadList(event: PaginationEvent) {
    this.store.dispatch(new LoadCriterionsTemplates({
      request: {
        pageNumber: event.pageNumber + 1,
        pageSize: event.pageSize
      }
    }));
  }

  selectTemplate(event: CriterionsTemplateModel) {
    this.dialogRef.close({
      type: 'select',
      template: event
    });
  }

  viewTemplate(event: CriterionsTemplateModel) {
    this.dialogRef.close({
      type: 'view',
      template: event
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
