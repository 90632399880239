// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {HandoverStandardsService} from '../_services/handover-standard.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allHandoverStandardsLoaded} from '../_selectors/handover-standard.selectors';
// Actions
import {
    AllHandoverStandardsLoaded,
    AllHandoverStandardsRequested,
    HandoverStandardActionTypes,
    HandoverStandardsPageRequested,
    HandoverStandardsPageLoaded,
    HandoverStandardUpdated,
    HandoverStandardsPageToggleLoading,
    HandoverStandardDeleted,
    HandoverStandardOnServerCreated,
    HandoverStandardCreated,
    HandoverStandardAdminRestored,
    HandoverStandardDeletedFromAdminTrash,
    HandoverStandardDeletedFromTrash,
    HandoverStandardOnServerAdminRestored,
    HandoverStandardOnServerRestored,
    HandoverStandardRestored,
    HandoverStandardTrashFlushed,
    HandoverStandardsActionToggleLoading
} from '../_actions/handover-standard.actions';

@Injectable()
export class HandoverStandardEffects {
    showPageLoadingDispatcher = new HandoverStandardsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new HandoverStandardsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new HandoverStandardsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllHandoverStandards$ = this.actions$
        .pipe(
            ofType<AllHandoverStandardsRequested>(HandoverStandardActionTypes.AllHandoverStandardsRequested),
            withLatestFrom(this.store.pipe(select(allHandoverStandardsLoaded))),
            filter(([action, isAllHandoverStandardsLoaded]) => !isAllHandoverStandardsLoaded),
            mergeMap(() => this.service.getAllHandoverStandards()),
            map(res => {
                return new AllHandoverStandardsLoaded({handoverStandards: res.data});
            })
        );

    @Effect()
    loadHandoverStandardsPage$ = this.actions$
        .pipe(
            ofType<HandoverStandardsPageRequested>(HandoverStandardActionTypes.HandoverStandardsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findHandoverStandards(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new HandoverStandardsPageLoaded({
                    handoverStandards: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardDeleted>(HandoverStandardActionTypes.HandoverStandardDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteHandoverStandard(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardUpdated>(HandoverStandardActionTypes.HandoverStandardUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateHandoverStandard(payload.handoverStandard);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardOnServerCreated>(HandoverStandardActionTypes.HandoverStandardOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createHandoverStandard(payload.handoverStandard).pipe(
                    tap(res => {
                        this.store.dispatch(new HandoverStandardCreated({handoverStandard: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushHandoverStandardTrash$ = this.actions$
        .pipe(
            ofType<HandoverStandardTrashFlushed>(HandoverStandardActionTypes.HandoverStandardTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardOnServerRestored>(HandoverStandardActionTypes.HandoverStandardOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new HandoverStandardRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardOnServerAdminRestored>(HandoverStandardActionTypes.HandoverStandardOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new HandoverStandardAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardDeletedFromTrash>(HandoverStandardActionTypes.HandoverStandardDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashHandoverStandard$ = this.actions$
        .pipe(
            ofType<HandoverStandardDeletedFromAdminTrash>(HandoverStandardActionTypes.HandoverStandardDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: HandoverStandardsService, private store: Store<AppState>) {
    }
}
