import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { AssignmentsService } from "src/app/core/assignment";
import { Globals } from "src/app/core/_base/crud/utils/globals.service";

@Injectable()
export class AssignmentResolver implements Resolve<any> {
    constructor(
        private assignmentService: AssignmentsService,
        private global: Globals
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.global.activeAssignmentId) {
            return this.assignmentService.getById(this.global.activeAssignmentId)
        }
        const assignmentId = Number(route.paramMap.get('assignment_id'))
        return this.assignmentService.getById(assignmentId)
    }
}