// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {MethodsToValueActions, MethodsToValueActionTypes} from '../_actions/methods-to-value.actions';
// Models
import {MethodsToValue} from '../_models/methods-to-value.model';
import {QueryParamsModel} from '../../_base/crud';

export interface MethodsToValuesState extends EntityState<MethodsToValue> {
    isAllMethodsToValuesLoaded: boolean;
    queryRowsCount: number;
    queryResult: MethodsToValue[];
    lastCreatedMethodsToValueId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<MethodsToValue> = createEntityAdapter<MethodsToValue>();

export const initialMethodsToValuesState: MethodsToValuesState = adapter.getInitialState({
    isAllMethodsToValuesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedMethodsToValueId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function methodsToValuesReducer(state = initialMethodsToValuesState, action: MethodsToValueActions): MethodsToValuesState {
    switch (action.type) {
        case MethodsToValueActionTypes.MethodsToValuesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMethodsToValueId: undefined
            };
        case MethodsToValueActionTypes.MethodsToValuesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case MethodsToValueActionTypes.MethodsToValueOnServerCreated:
            return {
                ...state
            };
        case MethodsToValueActionTypes.MethodsToValueCreated:
            return adapter.addOne(action.payload.methodsToValue, {
                ...state, lastCreatedMethodsToValueId: action.payload.methodsToValue.id
            });
        case MethodsToValueActionTypes.MethodsToValueUpdated:
            return adapter.updateOne(action.payload.partialmethodsToValue, state);
        case MethodsToValueActionTypes.AllMethodsToValuesLoaded:
            return adapter.addAll(action.payload.methodsToValues, {
                ...state, isAllMethodsToValuesLoaded: true
            });
        case MethodsToValueActionTypes.MethodsToValuesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case MethodsToValueActionTypes.MethodsToValuesPageLoaded:
            return adapter.addMany(action.payload.methodsToValues, {
                ...initialMethodsToValuesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case MethodsToValueActionTypes.MethodsToValueDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case MethodsToValueActionTypes.MethodsToValueRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case MethodsToValueActionTypes.MethodsToValueDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case MethodsToValueActionTypes.MethodsToValueAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case MethodsToValueActionTypes.MethodsToValueDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case MethodsToValueActionTypes.MethodsToValueTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
