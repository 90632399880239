import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class InformedConsentReportService {
	API_INFORMED_CONSENT_REPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/informed-consent-reports/`;
	API_INFORMED_CONSENT_SIGNED_REPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/informed-consent-signed-reports/`;
	constructor(
		private http: HttpClient, 
		// private authService: MadAuthService
		private authService: MadAuthService,
		private subDomainService: SubdomainService
	) {}

	generateReport(toeID: number, reportID: number): Observable<any> {
		const httpHeaders = this.authService.getAuthHeaders();
		return this.http.get<any>(this.API_INFORMED_CONSENT_REPORT_URL + toeID + '/' + reportID, {headers: httpHeaders});
	}

	getAllReports(toeID: number): Observable<any> {
		const httpHeaders = this.authService.getAuthHeaders();
		return this.http.get<any>(this.API_INFORMED_CONSENT_REPORT_URL + toeID, {headers: httpHeaders});
	}

	createSignedReport(item: any, taskID: number): Observable<any> {
		const httpHeaders = this.authService.getAuthHeaders();
		return this.http.post<any>(this.API_INFORMED_CONSENT_SIGNED_REPORT_URL + `add/${taskID}`, item, {headers: httpHeaders});
	}

	updateSignedReport(toeID: number, id: number): Observable<any> {
		const headers = this.authService.getAuthHeaders();
		return this.http.get<any>(this.API_INFORMED_CONSENT_SIGNED_REPORT_URL + toeID + '/' + id, {headers});
	}
}