// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {OtherJoineryService} from '../_services/other-joinery.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allOtherJoinerysLoaded} from '../_selectors/other-joinery.selectors';
// Actions
import {
    AllOtherJoinerysLoaded,
    AllOtherJoinerysRequested,
    OtherJoineryActionTypes,
    OtherJoinerysPageRequested,
    OtherJoinerysPageLoaded,
    OtherJoineryUpdated,
    OtherJoinerysPageToggleLoading,
    OtherJoineryDeleted,
    OtherJoineryOnServerCreated,
    OtherJoineryCreated,
    OtherJoineryActionToggleLoading, OtherJoineryTrashFlushed, OtherJoineryOnServerRestored, OtherJoineryRestored,
    OtherJoineryOnServerAdminRestored, OtherJoineryAdminRestored, OtherJoineryDeletedFromTrash, OtherJoineryDeletedFromAdminTrash
} from '../_actions/other-joinery.actions';

@Injectable()
export class OtherJoineryEffects {
    showPageLoadingDispatcher = new OtherJoinerysPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OtherJoineryActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OtherJoineryActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOtherJoinery$ = this.actions$
        .pipe(
            ofType<AllOtherJoinerysRequested>(OtherJoineryActionTypes.AllOtherJoinerysRequested),
            withLatestFrom(this.store.pipe(select(allOtherJoinerysLoaded))),
            filter(([action, isAllOtherJoineryLoaded]) => !isAllOtherJoineryLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllOtherJoinerysLoaded({items: res.data});
            })
        );

    @Effect()
    loadOtherJoineryPage$ = this.actions$
        .pipe(
            ofType<OtherJoinerysPageRequested>(OtherJoineryActionTypes.OtherJoinerysPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OtherJoinerysPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryUpdated>(OtherJoineryActionTypes.OtherJoineryUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryOnServerCreated>(OtherJoineryActionTypes.OtherJoineryOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new OtherJoineryCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushOtherJoineryTrash$ = this.actions$
        .pipe(
            ofType<OtherJoineryTrashFlushed>(OtherJoineryActionTypes.OtherJoineryTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryOnServerRestored>(OtherJoineryActionTypes.OtherJoineryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OtherJoineryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryOnServerAdminRestored>(OtherJoineryActionTypes.OtherJoineryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OtherJoineryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryDeleted>(OtherJoineryActionTypes.OtherJoineryDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryDeletedFromTrash>(OtherJoineryActionTypes.OtherJoineryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashOtherJoinery$ = this.actions$
        .pipe(
            ofType<OtherJoineryDeletedFromAdminTrash>(OtherJoineryActionTypes.OtherJoineryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: OtherJoineryService, private store: Store<AppState>) {
    }
}
