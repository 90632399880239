// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {OtherJoineryActions, OtherJoineryActionTypes} from '../_actions/other-joinery.actions';
// Models
import {OtherJoineryModel} from '../_models/other-joinery.model';
import {QueryParamsModel} from '../../_base/crud';

export interface OtherJoinerysState extends EntityState<OtherJoineryModel> {
    isAllOtherJoinerysLoaded: boolean;
    queryRowsCount: number;
    queryResult: OtherJoineryModel[];
    lastCreatedOtherJoineryId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<OtherJoineryModel> = createEntityAdapter<OtherJoineryModel>();

export const initialOtherJoineryState: OtherJoinerysState = adapter.getInitialState({
    isAllOtherJoinerysLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOtherJoineryId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function otherJoineryReducer(state = initialOtherJoineryState, action: OtherJoineryActions): OtherJoinerysState {
    switch (action.type) {
        case OtherJoineryActionTypes.OtherJoinerysPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedOtherJoineryId: undefined
            };
        case OtherJoineryActionTypes.OtherJoineryActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case OtherJoineryActionTypes.OtherJoineryOnServerCreated:
            return {
                ...state
            };
        case OtherJoineryActionTypes.OtherJoineryCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedOtherJoineryId: action.payload.item.id
            });
        case OtherJoineryActionTypes.OtherJoineryUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case OtherJoineryActionTypes.AllOtherJoinerysLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllOtherJoinerysLoaded: true
            });
        case OtherJoineryActionTypes.OtherJoinerysPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case OtherJoineryActionTypes.OtherJoinerysPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialOtherJoineryState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case OtherJoineryActionTypes.OtherJoineryDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case OtherJoineryActionTypes.OtherJoineryRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case OtherJoineryActionTypes.OtherJoineryDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case OtherJoineryActionTypes.OtherJoineryAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case OtherJoineryActionTypes.OtherJoineryDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case OtherJoineryActionTypes.OtherJoineryTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
