// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ChimneyStackService} from '../_services/chimney-stack.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allChimneyStacksLoaded} from '../_selectors/chimney-stack.selectors';
// Actions
import {
    AllChimneyStacksLoaded,
    AllChimneyStacksRequested,
    ChimneyStackActionTypes,
    ChimneyStacksPageRequested,
    ChimneyStacksPageLoaded,
    ChimneyStackUpdated,
    ChimneyStacksPageToggleLoading,
    ChimneyStackDeleted,
    ChimneyStackOnServerCreated,
    ChimneyStackCreated,
    ChimneyStackActionToggleLoading, ChimneyStackTrashFlushed, ChimneyStackOnServerRestored, ChimneyStackRestored,
    ChimneyStackOnServerAdminRestored, ChimneyStackAdminRestored, ChimneyStackDeletedFromTrash, ChimneyStackDeletedFromAdminTrash
} from '../_actions/chimney-stack.actions';

@Injectable()
export class ChimneyStackEffects {
    showPageLoadingDispatcher = new ChimneyStacksPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ChimneyStackActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ChimneyStackActionToggleLoading({isLoading: false});

    @Effect()
    loadAllChimneyStack$ = this.actions$
        .pipe(
            ofType<AllChimneyStacksRequested>(ChimneyStackActionTypes.AllChimneyStacksRequested),
            withLatestFrom(this.store.pipe(select(allChimneyStacksLoaded))),
            filter(([action, isAllChimneyStackLoaded]) => !isAllChimneyStackLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllChimneyStacksLoaded({items: res.data});
            })
        );

    @Effect()
    loadChimneyStackPage$ = this.actions$
        .pipe(
            ofType<ChimneyStacksPageRequested>(ChimneyStackActionTypes.ChimneyStacksPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ChimneyStacksPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackUpdated>(ChimneyStackActionTypes.ChimneyStackUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackOnServerCreated>(ChimneyStackActionTypes.ChimneyStackOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ChimneyStackCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushChimneyStackTrash$ = this.actions$
        .pipe(
            ofType<ChimneyStackTrashFlushed>(ChimneyStackActionTypes.ChimneyStackTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackOnServerRestored>(ChimneyStackActionTypes.ChimneyStackOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ChimneyStackRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackOnServerAdminRestored>(ChimneyStackActionTypes.ChimneyStackOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ChimneyStackAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackDeleted>(ChimneyStackActionTypes.ChimneyStackDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackDeletedFromTrash>(ChimneyStackActionTypes.ChimneyStackDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashChimneyStack$ = this.actions$
        .pipe(
            ofType<ChimneyStackDeletedFromAdminTrash>(ChimneyStackActionTypes.ChimneyStackDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ChimneyStackService, private store: Store<AppState>) {
    }
}
