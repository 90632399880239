// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AuditTrailsState } from '../_reducers/audit-trail.reducers';
import { AuditTrailModel } from '../_models/audit-trail.model';
import {selectAppendicesState} from '../../admin/_selectors/appendix.selectors';

export const selectAuditTrailsState = createFeatureSelector<AuditTrailsState>('auditTrails');

export const selectAuditTrailById = (auditTrailId: number) => createSelector(
    selectAuditTrailsState,
    auditTrailsState => auditTrailsState.entities[auditTrailId]
);

export const selectAuditTrailsPageLoading = createSelector(
    selectAuditTrailsState,
    auditTrailsState => auditTrailsState.listLoading
);

export const selectAuditTrailsActionLoading = createSelector(
    selectAuditTrailsState,
    auditTrailsState => auditTrailsState.actionLoading
);

export const selectLastCreatedAuditTrailId = createSelector(
    selectAuditTrailsState,
    auditTrailsState => auditTrailsState.lastCreatedAuditTrailId
);

export const selectAuditTrailsShowInitWaitingMessage = createSelector(
    selectAuditTrailsState,
    auditTrailsState => auditTrailsState.showInitWaitingMessage
);


export const selectTrashedAuditTrailsCount = createSelector(
    selectAuditTrailsState,
    (auditTrailsState) => auditTrailsState.totalTrashed
);

export const selectAdminTrashedAuditTrailsCount = createSelector(
    selectAuditTrailsState,
    (auditTrailsState) => auditTrailsState.totalAdminTrashed
);

export const selectAuditTrailsLastQuery = createSelector(
    selectAuditTrailsState,
    (auditTrailsState) => auditTrailsState.lastQuery
)

export const selectAuditTrailsInStore = createSelector(
    selectAuditTrailsState,
    auditTrailsState => {
        const items: AuditTrailModel[] = [];
        each(auditTrailsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        // Add ref to Audit trail list
        let _tmp: AuditTrailModel[] = httpExtension.sortArrayNumber(items, 'id', 'asc');
        const _items = _tmp.map((val, index) => {
            const _val = Object.assign({}, val) as AuditTrailModel;
            return _val;
        });

        let result: AuditTrailModel[] = [];
        if (auditTrailsState.lastQuery.sortField === 'created_at') {
            result = httpExtension.sortArrayDateTime(_items, auditTrailsState.lastQuery.sortField, auditTrailsState.lastQuery.sortOrder);
        } else if (auditTrailsState.lastQuery.sortField === 'id') {
            result = httpExtension.sortArrayNumber(_items, auditTrailsState.lastQuery.sortField, auditTrailsState.lastQuery.sortOrder);
        } else if (auditTrailsState.lastQuery.sortField === 'impact_on_valuation') {
            const order = ['None', 'Low', 'Medium', 'High'];
            result = httpExtension.sortArrayWithCustomKey(
                _items,
                auditTrailsState.lastQuery.sortField,
                auditTrailsState.lastQuery.sortOrder,
                order
            );
        } else {
            result = httpExtension.sortArray(_items, auditTrailsState.lastQuery.sortField, auditTrailsState.lastQuery.sortOrder);
        }

        return new QueryResultsModel(result, auditTrailsState.totalCount, '');
    }
);
