import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppState } from '../../../../../core/reducers';
import {
    AssetClassOfficeFacilitiesService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassOfficeFacilitiesExclusiveGetResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public assetClassOfficeFacilitiesService: AssetClassOfficeFacilitiesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const officeId = Number(route.params.office_id);
        return this.assetClassOfficeFacilitiesService.getAllAssetClassOfficeFacilitiesExclusive(officeId);
    }
}
