// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ApproachesToValue} from '../_models/approaches-to-value.model';

export enum ApproachesToValueActionTypes {

    AllApproachesToValuesRequested = '[ApproachesToValues Home Page] All ApproachesToValues Requested',
    AllApproachesToValuesLoaded = '[ApproachesToValues API] All ApproachesToValues Loaded',

    ApproachesToValueOnServerCreated = '[Edit ApproachesToValue Dialog] ApproachesToValue On Server Created',
    ApproachesToValueCreated = '[Edit ApproachesToValues Dialog] ApproachesToValues Created',
    ApproachesToValueUpdated = '[Edit ApproachesToValue Dialog] ApproachesToValue Updated',

    ApproachesToValuesPageRequested = '[ApproachesToValues List Page] ApproachesToValues Page Requested',
    ApproachesToValuesPageLoaded = '[ApproachesToValues API] ApproachesToValues Page Loaded',
    ApproachesToValuesPageCancelled = '[ApproachesToValues API] ApproachesToValues Page Cancelled',

    ApproachesToValuesPageToggleLoading = '[ApproachesToValues page] ApproachesToValues Page Toggle Loading',
    ApproachesToValuesActionToggleLoading = '[ApproachesToValues] ApproachesToValues Action Toggle Loading',

    ApproachesToValueDeleted = '[ApproachesToValues List Page] ApproachesToValue Deleted',

    // trash system

    ApproachesToValueOnServerRestored = '[ApproachesToValue Trash] ApproachesToValue On Server Restored',
    ApproachesToValueRestored = '[ApproachesToValue Trash] ApproachesToValue Restored',

    ApproachesToValueOnServerAdminRestored = '[ApproachesToValue Admin Trash] ApproachesToValue On Server Restored',
    ApproachesToValueAdminRestored = '[ApproachesToValue Admin Trash] ApproachesToValue Restored',

    ApproachesToValueDeletedFromTrash = '[ApproachesToValue Trash] ApproachesToValue deleted',
    ApproachesToValueDeletedFromAdminTrash = '[ApproachesToValue Admin Trash] ApproachesToValue deleted',

    ApproachesToValueTrash = 'ApproachesToValue Trashed',
    ApproachesToValueTrashFlushed = 'ApproachesToValue Trash Flushed',
}

export class ApproachesToValueOnServerCreated implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueOnServerCreated;

    constructor(public payload: { approachesToValue: ApproachesToValue }) {
    }
}

export class ApproachesToValueCreated implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueCreated;

    constructor(public payload: { approachesToValue: ApproachesToValue }) {
    }
}

export class ApproachesToValueUpdated implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueUpdated;

    constructor(public payload: {
        partialapproachesToValue: Update<ApproachesToValue>,
        approachesToValue: ApproachesToValue
    }) {
    }
}

export class ApproachesToValueDeleted implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ApproachesToValuesPageRequested implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValuesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ApproachesToValuesPageLoaded implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValuesPageLoaded;

    constructor(public payload: { approachesToValues: ApproachesToValue[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ApproachesToValuesPageCancelled implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValuesPageCancelled;
}

export class AllApproachesToValuesRequested implements Action {
    readonly type = ApproachesToValueActionTypes.AllApproachesToValuesRequested;
}

export class AllApproachesToValuesLoaded implements Action {
    readonly type = ApproachesToValueActionTypes.AllApproachesToValuesLoaded;

    constructor(public payload: { approachesToValues: ApproachesToValue[] }) {
    }
}

export class ApproachesToValuesPageToggleLoading implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValuesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ApproachesToValuesActionToggleLoading implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValuesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ApproachesToValueDeletedFromAdminTrash implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ApproachesToValueDeletedFromTrash implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ApproachesToValueOnServerRestored implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ApproachesToValueRestored implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueRestored;

    constructor(public payload: { item: ApproachesToValue }) {
    }
}

export class ApproachesToValueOnServerAdminRestored implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ApproachesToValueAdminRestored implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueAdminRestored;

    constructor(public payload: { item: ApproachesToValue }) {
    }
}

export class ApproachesToValueTrashFlushed implements Action {
    readonly type = ApproachesToValueActionTypes.ApproachesToValueTrashFlushed;

    constructor() {
    }
}

export type ApproachesToValueActions = ApproachesToValueCreated
    | ApproachesToValueUpdated
    | ApproachesToValuesPageRequested
    | ApproachesToValuesPageLoaded
    | ApproachesToValuesPageCancelled
    | AllApproachesToValuesLoaded
    | AllApproachesToValuesRequested
    | ApproachesToValueOnServerCreated
    | ApproachesToValuesPageToggleLoading
    | ApproachesToValuesActionToggleLoading
    | ApproachesToValueDeleted
    | ApproachesToValueDeletedFromAdminTrash
    | ApproachesToValueDeletedFromTrash
    | ApproachesToValueOnServerRestored
    | ApproachesToValueRestored
    | ApproachesToValueOnServerAdminRestored
    | ApproachesToValueAdminRestored
    | ApproachesToValueTrashFlushed;
