import { ExternalAspectData, newExternalAspectData } from "./asset-class-external-aspect.model";
import { AcGroundData, newAcGroundData } from "./asset-class-ground.model";
import { InternalAspectData, newInternalAspectData } from "./asset-class-internal-aspect.model";
import { ParcelIdentificationModel } from "./building.model";

export interface BuildingSectionData {
    building_id: number,
    building_name: string,
    completion_year: string,
    is_part_of_property: boolean,
    info_about_property: string,
    building_type: number,
    building_type_comment: string,
    building_grade_id: number,
    energy_efficiency_grade_id: number,
    developer: string,
    anchor_tenant: string,
    foundation_type_id: number,
    building_description: string
}
export class AssetClassBuilding {
    id: number;
    infoSection: BuildingSectionData;
    serviceAndInfra: {
        id: number,
        road: boolean;
        status_n_quality: string,
        access_points: string,
        length_of_road: string,
        ownership: string,

        central_heating: boolean,
        heatings: number[],
        other_heating: string,

        all_of_it: boolean,
        services: number[],
        other_service: string
    }
    externalAspectData: ExternalAspectData;
    internalAspectData: InternalAspectData;
    grounds: AcGroundData;
    parcelIdentification: ParcelIdentificationModel;
    sizes: any[];
    

    constructor() {
        this.id = null;
        this.infoSection = {
            building_id: null,
            building_name: null,
            completion_year: null,
            is_part_of_property: false,
            info_about_property: null,
            building_type: null,
            building_type_comment: null,
            building_grade_id: null,
            energy_efficiency_grade_id: null,
            developer: null,
            anchor_tenant: null,
            foundation_type_id: null,
            building_description: null
        }
        this.serviceAndInfra = {
            id: null,
            road: false,
            status_n_quality: null,
            access_points: null,
            length_of_road: null,
            ownership: null,

            central_heating: false,
            heatings: [],
            other_heating: null,

            all_of_it: false,
            services: [],
            other_service: null
        }
        this.externalAspectData = newExternalAspectData();
        this.internalAspectData = newInternalAspectData();
        this.grounds = newAcGroundData();
        this.parcelIdentification = new ParcelIdentificationModel();
        this.sizes = [];
    }
}
