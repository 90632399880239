<div class="card card-custom">
  <div class="card-body p-0">
      <ngb-carousel [interval]="0" [activeId]="data.index">
        <ng-template *ngFor="let img of data.images; let i = index" ngbSlide [id]="'img-' + i">
          <img [src]="img.picture" (error)="onImgError($event)" class="img-fluid rounded slide-img" />
          <div class="image-caption">
              <div>
                  {{(img.img.type ? img.img.type : '') + (img.img.orderNum ? ' (' + img.img.orderNum + ') - ' : '')}} 
                  <span>
                    <span class="img-name">
                      {{img.img.name}}
                      &nbsp;
                    </span>
                    {{(img.img.description ? (' - ' + img.img.description) : '')}}
                  </span>
              </div>                
          </div>
        </ng-template>
      </ngb-carousel>
  </div>
</div>