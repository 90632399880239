import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { concatMap, map, mergeMap, switchMap } from 'rxjs/operators'
import { ValuationProcessCreateSizeCriterions, ValuationProcessSizeCriterionActionTypes, ValuationProcessSizeCriterionsCreated, ValuationProcessUpdateSizeCriterion, ValuationProcessSizeCriterionAddComparable, ValuationProcessSizeCriterionComparableAdded, ValuationProcessSizeCriterionAddMultipleComparables, ValuationProcessSizeCriterionMultipleComparablesAdded } from '../_actions/size_criterion.actions'
import { ValuationProcessSizeCriterionsService } from '../_services'
import { ValuationProcessConsiderationCriterionUpdateValueBasedOnSize } from '../_actions/consideration-criterion.actions'

@Injectable()
export class ValuationProcessSizeCriterionEffects {
    constructor(
        private actions$: Actions,
        private service: ValuationProcessSizeCriterionsService
    ) { }

    @Effect()
    createSizeCriterions$ = this.actions$.pipe(
        ofType<ValuationProcessCreateSizeCriterions>(ValuationProcessSizeCriterionActionTypes.CreateSizeCriterions),
        map(({ payload }) => {
            const criterions = this.service.createCriterions(payload.refNum, payload.sizes)
            return new ValuationProcessSizeCriterionsCreated({ criterions })
        }),
    )

    @Effect()
    addComparable$ = this.actions$.pipe(
        ofType<ValuationProcessSizeCriterionAddComparable>(ValuationProcessSizeCriterionActionTypes.AddComprable),
        map(({payload}) => {
            const criterions = this.service.createComparableCriterion(payload.refNum, payload.sizes)
            return new ValuationProcessSizeCriterionComparableAdded({criterions})
        })
    )

    @Effect()
    addMultipleComparables$ = this.actions$.pipe(
        ofType<ValuationProcessSizeCriterionAddMultipleComparables>(ValuationProcessSizeCriterionActionTypes.AddMultipleComparables),
        map(({payload}) => {
            const criterions = this.service.createMultipleComparablesCriterion(payload.comparables)
            return new ValuationProcessSizeCriterionMultipleComparablesAdded({criterions, importedCriterions: payload.adjustmentTabData.sizecriterions})
        })
    )

    @Effect()
    updateSizeCriterion$ = this.actions$.pipe(
        ofType<ValuationProcessUpdateSizeCriterion>(ValuationProcessSizeCriterionActionTypes.UpdateSizeCriterion),
        map(({payload}) => {
            return new ValuationProcessConsiderationCriterionUpdateValueBasedOnSize({
                refNum: payload.refNum,
                size: payload.value
            })
        })
    )
}