import { Pipe, PipeTransform } from "@angular/core";
import { IPMSSizeInfoFloorSpecific } from "../models/ipms-residential.model";
import { MSResidential, MSSizeInfo } from "../models/mongolian-standard-residential.model";

@Pipe({name: 'rowsizesum'})
export class SumeSizePipe implements PipeTransform {
    transform(values: any[], key1: string, key2: string): any {
        const key1Values = values.map(item => item[key1]);
        const key2Values = key1Values.map(item => item[key2]);
        const total = key2Values.reduce((a, b) => a + b, 0);
        return total;
    }
}

@Pipe({name: 'colsizesum'})
export class ColSizeSumPipe implements PipeTransform {
    transform(value: IPMSSizeInfoFloorSpecific, key: string, id: number): number {
        let total = 0;
        total += value.a1_component[key]
        total += value.a2_component[key]
        if (id == 4) {
            total += value.b1_component[key]
        }
        if (id == 4 || id == 5 || id == 8) {
            total += value.b2_component[key]
            total += value.b3_component[key]
        }
        total += value.c_component[key]
        total += value.d_component[key]
        total += value.e_component[key]
        total += value.f_component[key]
        total += value.g_component[key]
        total += value.h_component[key]
        return total
    }
}

@Pipe({name: 'colrowsizesum'})
export class ColRowSizeSumPipe implements PipeTransform {
    transform(values: IPMSSizeInfoFloorSpecific[], key: string, id: number): number {
        let total = 0;
        values.forEach(value => {
            total += value.a1_component[key]
            total += value.a2_component[key]
            if (id == 4) {
                total += value.b1_component[key]
            }
            if (id == 4 || id == 5 || id == 8) {
                total += value.b2_component[key]
                total += value.b3_component[key]
            }
            total += value.c_component[key]
            total += value.d_component[key]
            total += value.e_component[key]
            total += value.f_component[key]
            total += value.g_component[key]
            total += value.h_component[key]
        })
        return total
    }
}

@Pipe({name: 'msrowsum'})
export class MsRowSumPipe implements PipeTransform {
    transform(value: MSSizeInfo): number {
        let total = 0;
        Object.entries(value.floors).forEach(([_, info]) => {
            if (info != undefined) {
                total += (info.size * value.multiplier)
            }
        })
        return total;
    }
}

@Pipe({name: 'mscolsum'})
export class MsColSumPipe implements PipeTransform {
    transform(value: MSResidential, floor: number): number {
        let total = 0;
        const types = ['living_areas', 'standard_facilities', 'external_areas'];
        types.forEach(type => {
            value[type].forEach(area => {
                total += (area.floors[floor].size * area.multiplier);
            })
        })
        return total;
    }
}

@Pipe({name: 'mstotal'})
export class MsTotalPipe implements PipeTransform {
    transform(value: MSResidential): number {
        let total = 0;
        const types = ['living_areas', 'standard_facilities', 'external_areas'];
        types.forEach(type => {
            value[type].forEach((area: MSSizeInfo) => {
                Object.entries(area.floors).forEach(([_, info]) => {
                    if (info != undefined) {
                        total += (info.size * area.multiplier)
                    }
                })
            })
        })
        return total;
    }
}