export interface ExternalAspectData {
    id: number;
    chimney_stack_id: number;
    chimney_stack_comment: string;

    roof_covering_id: number;
    roof_covering_comment: string;

    rainwater_pipe_id: number;
    rainwater_pipe_comment: string;

    main_wall_id: number;
    main_wall_comment: string;

    window_type_id: number;
    window_type_comment: string;

    outside_door_id: number;
    outside_door_comment: string;

    conservatory_id: number;
    conservatory_comment: string;

    other_joinery_id: number;
    other_joinery_comment: string;

    loading_dock: boolean;
    loading_dock_num: number;
    loading_dock_comment: string;

    external_facade_id: number;
    external_facade_comment: string;

    has_other: boolean;
    other_aspects: string;
}

export function newExternalAspectData(): ExternalAspectData {
    return {
        id: undefined,
        chimney_stack_id: null,
        chimney_stack_comment: null,

        roof_covering_id: null,
        roof_covering_comment: null,

        rainwater_pipe_id: null,
        rainwater_pipe_comment: null,

        main_wall_id: null,
        main_wall_comment: null,

        window_type_id: null,
        window_type_comment: null,

        outside_door_id: null,
        outside_door_comment: null,

        conservatory_id: null,
        conservatory_comment: null,

        other_joinery_id: null,
        other_joinery_comment: null,

        loading_dock: false,
        loading_dock_num: null,
        loading_dock_comment: null,

        external_facade_id: null,
        external_facade_comment: null,

        has_other: false,
        other_aspects: null,
    };
}