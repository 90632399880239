function getCenter(points: Array<{lat: any, lng: any}>) {
    const bounds = new google.maps.LatLngBounds()
    const _points = points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}))
    const filtered = _points.filter(p => p.lat != null && p.lng != null);
    filtered.forEach(p => bounds.extend(p))
    const center = bounds.getCenter()
    return {
        lat: center.lat(),
        lng: center.lng()
    }
}

const ValuationUtils = {
    getCenter
}

export default ValuationUtils
