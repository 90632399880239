import {ParcelCharacteristic} from '../_models/parcel-characteristic.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ParcelCharacteristicsState} from '../_reducers/parcel-characteristic.reducers';
import * as fromParcelCharacteristic from '../_reducers/parcel-characteristic.reducers';
import {each} from 'lodash';

export const selectParcelCharacteristicsState = createFeatureSelector<ParcelCharacteristicsState>('parcelCharacteristics');

export const selectParcelCharacteristicById = (parcelCharacteristicId: number) => createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => parcelCharacteristicsState.entities[parcelCharacteristicId]
);

export const selectAllParcelCharacteristics = createSelector(
    selectParcelCharacteristicsState,
    fromParcelCharacteristic.selectAll
);

export const selectAllParcelCharacteristicsIds = createSelector(
    selectParcelCharacteristicsState,
    fromParcelCharacteristic.selectIds
);

export const allParcelCharacteristicsLoaded = createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => parcelCharacteristicsState.isAllParcelCharacteristicsLoaded
);


export const selectParcelCharacteristicsPageLoading = createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => parcelCharacteristicsState.listLoading
);

export const selectParcelCharacteristicsActionLoading = createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => parcelCharacteristicsState.actionLoading
);

export const selectLastCreatedParcelCharacteristicId = createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => parcelCharacteristicsState.lastCreatedParcelCharacteristicId
);

export const selectParcelCharacteristicsShowInitWaitingMessage = createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => parcelCharacteristicsState.showInitWaitingMessage
);

export const selectTrashedParcelCharacteristicCount = createSelector(
    selectParcelCharacteristicsState,
    (parcelCharacteristicsState) => parcelCharacteristicsState.totalTrashed
);

export const selectAdminTrashedParcelCharacteristicCount = createSelector(
    selectParcelCharacteristicsState,
    (parcelCharacteristicsState) => parcelCharacteristicsState.totalAdminTrashed
);


export const selectParcelCharacteristicQueryResult = createSelector(
    selectParcelCharacteristicsState,
    parcelCharacteristicsState => {
        const items: ParcelCharacteristic[] = [];
        each(parcelCharacteristicsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ParcelCharacteristic[] = httpExtension.sortArray(items, parcelCharacteristicsState.lastQuery.sortField, parcelCharacteristicsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, parcelCharacteristicsState.totalCount, '', parcelCharacteristicsState.totalTrashed);
    }
);
