<div class="p-0 m-0" *ngIf="!(loading$|async)">
    <ng-container *ngIf="valuation$ | async as valuation">
        <ng-container *ngIf="(isLiquidationValuation$|async)">
            <form [formGroup]="liquidationFormGroup" class="mb-12">
                <h5 class="my-3 text-mad text-uppercase">existing valuation data</h5>
                <hr class="active" />
                <div>
                    <div class="mb-4">
                        <mat-label>Import datasets and results from another valuation? Be mindful of the assumptions made and of the valuation method used</mat-label>
                    </div>
                    <div class="mb-4">
                        <mat-radio-group formControlName="import_other_valuations">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="mb-4" *ngIf="showValuations$|async">
                        <mat-radio-group formControlName="selected_valuation">
                            <mat-radio-button *ngFor="let item of (otherValidatedValuations$|async); let idx = index" 
                                [value]="item.id">
                                Valuation {{idx + 1}} - {{item.basis_of_value_name}} - {{ item.method_to_value_name }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </form>
            <div>
                <h5 class="my-3 text-mad text-uppercase">Market data</h5>
                <hr class="active" />
            </div>
        </ng-container>

        <kt-comparables-map-v2 class="section-margin-bottom-60"
            [valuation]="valuation"
            [targetProperty]="targetProperty"
            [currentUser]="currentUser"
            ></kt-comparables-map-v2>
        <kt-comparables-table-v2
            [currentUser]="currentUser"
            [targetProperty]="targetProperty"
            (onAddComparable)="addComparable()"></kt-comparables-table-v2>
    </ng-container>
</div>