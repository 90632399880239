// NGRX
import { Action } from '@ngrx/store';
// Models
import { AssetClassOfficeFacilityModel } from '../_models/asset-class-office-facility.model';

export enum AssetClassOfficeFacilityActionTypes {
    AllAssetClassOfficeFacilitiesRequested = '[AssetClassOfficeFacilities Home Page] All AssetClassOfficeFacilities Requested',
    AllAssetClassOfficeFacilitiesLoaded = '[AssetClassOfficeFacilities API] All AssetClassOfficeFacilities Loaded',
    AssetClassOfficeFacilityActionToggleLoading = '[AssetClassOfficeFacility] AssetClassOfficeFacility Action Toggle Loading',
}

export class AllAssetClassOfficeFacilitiesRequested implements Action {
    readonly type = AssetClassOfficeFacilityActionTypes.AllAssetClassOfficeFacilitiesRequested;
    constructor(public payload: { assetClassOfficeId: number }) { }
}

export class AllAssetClassOfficeFacilitiesLoaded implements Action {
    readonly type = AssetClassOfficeFacilityActionTypes.AllAssetClassOfficeFacilitiesLoaded;
    constructor(public payload: { assetClassOfficeFacilities: AssetClassOfficeFacilityModel[] }) { }
}

export class AssetClassOfficeFacilityActionToggleLoading implements Action {
    readonly type = AssetClassOfficeFacilityActionTypes.AssetClassOfficeFacilityActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AssetClassOfficeFacilityActions = AllAssetClassOfficeFacilitiesRequested
| AllAssetClassOfficeFacilitiesLoaded
| AssetClassOfficeFacilityActionToggleLoading;
