// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {PurposeOfValuation} from '../_models/purpose-of-valuation.model';

export enum PurposeOfValuationActionTypes {

    AllPurposeOfValuationsRequested = '[PurposeOfValuations Home Page] All PurposeOfValuations Requested',
    AllPurposeOfValuationsLoaded = '[PurposeOfValuations API] All PurposeOfValuations Loaded',

    PurposeOfValuationOnServerCreated = '[Edit PurposeOfValuation Dialog] PurposeOfValuation On Server Created',
    PurposeOfValuationCreated = '[Edit PurposeOfValuations Dialog] PurposeOfValuations Created',
    PurposeOfValuationUpdated = '[Edit PurposeOfValuation Dialog] PurposeOfValuation Updated',

    PurposeOfValuationsPageRequested = '[PurposeOfValuations List Page] PurposeOfValuations Page Requested',
    PurposeOfValuationsPageLoaded = '[PurposeOfValuations API] PurposeOfValuations Page Loaded',
    PurposeOfValuationsPageCancelled = '[PurposeOfValuations API] PurposeOfValuations Page Cancelled',

    PurposeOfValuationsPageToggleLoading = '[PurposeOfValuations page] PurposeOfValuations Page Toggle Loading',
    PurposeOfValuationsActionToggleLoading = '[PurposeOfValuations] PurposeOfValuations Action Toggle Loading',

    PurposeOfValuationDeleted = '[PurposeOfValuations List Page] PurposeOfValuation Deleted',

    // trash system

    PurposeOfValuationOnServerRestored = '[PurposeOfValuation Trash] PurposeOfValuation On Server Restored',
    PurposeOfValuationRestored = '[PurposeOfValuation Trash] PurposeOfValuation Restored',

    PurposeOfValuationOnServerAdminRestored = '[PurposeOfValuation Admin Trash] PurposeOfValuation On Server Restored',
    PurposeOfValuationAdminRestored = '[PurposeOfValuation Admin Trash] PurposeOfValuation Restored',

    PurposeOfValuationDeletedFromTrash = '[PurposeOfValuation Trash] PurposeOfValuation deleted',
    PurposeOfValuationDeletedFromAdminTrash = '[PurposeOfValuation Admin Trash] PurposeOfValuation deleted',

    PurposeOfValuationTrash = 'PurposeOfValuation Trashed',
    PurposeOfValuationTrashFlushed = 'PurposeOfValuation Trash Flushed',
}

export class PurposeOfValuationOnServerCreated implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationOnServerCreated;

    constructor(public payload: { purposeOfValuation: PurposeOfValuation }) {
    }
}

export class PurposeOfValuationCreated implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationCreated;

    constructor(public payload: { purposeOfValuation: PurposeOfValuation }) {
    }
}

export class PurposeOfValuationUpdated implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationUpdated;

    constructor(public payload: {
        partialpurposeOfValuation: Update<PurposeOfValuation>,
        purposeOfValuation: PurposeOfValuation
    }) {
    }
}

export class PurposeOfValuationsPageRequested implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class PurposeOfValuationsPageLoaded implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationsPageLoaded;

    constructor(public payload: { purposeOfValuations: PurposeOfValuation[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class PurposeOfValuationsPageCancelled implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationsPageCancelled;
}

export class AllPurposeOfValuationsRequested implements Action {
    readonly type = PurposeOfValuationActionTypes.AllPurposeOfValuationsRequested;
}

export class AllPurposeOfValuationsLoaded implements Action {
    readonly type = PurposeOfValuationActionTypes.AllPurposeOfValuationsLoaded;

    constructor(public payload: { purposeOfValuations: PurposeOfValuation[] }) {
    }
}

export class PurposeOfValuationsPageToggleLoading implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PurposeOfValuationsActionToggleLoading implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PurposeOfValuationDeleted implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationDeleted;

    constructor(public payload: { id: number }) {
    }
}

// Trash

export class PurposeOfValuationDeletedFromAdminTrash implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class PurposeOfValuationDeletedFromTrash implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeOfValuationOnServerRestored implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeOfValuationRestored implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationRestored;

    constructor(public payload: { item: PurposeOfValuation }) {
    }
}

export class PurposeOfValuationOnServerAdminRestored implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeOfValuationAdminRestored implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationAdminRestored;

    constructor(public payload: { item: PurposeOfValuation }) {
    }
}

export class PurposeOfValuationTrashFlushed implements Action {
    readonly type = PurposeOfValuationActionTypes.PurposeOfValuationTrashFlushed;

    constructor() {
    }
}


export type PurposeOfValuationActions = PurposeOfValuationCreated
    | PurposeOfValuationUpdated
    | PurposeOfValuationsPageRequested
    | PurposeOfValuationsPageLoaded
    | PurposeOfValuationsPageCancelled
    | AllPurposeOfValuationsLoaded
    | AllPurposeOfValuationsRequested
    | PurposeOfValuationOnServerCreated
    | PurposeOfValuationsPageToggleLoading
    | PurposeOfValuationsActionToggleLoading
    | PurposeOfValuationDeleted
    | PurposeOfValuationDeletedFromAdminTrash
    | PurposeOfValuationDeletedFromTrash
    | PurposeOfValuationOnServerRestored
    | PurposeOfValuationRestored
    | PurposeOfValuationOnServerAdminRestored
    | PurposeOfValuationAdminRestored
    | PurposeOfValuationTrashFlushed;
