import {ChimneyStackModel} from '../_models/chimney-stack.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ChimneyStacksState} from '../_reducers/chimney-stack.reducers';
import * as fromChimneyStack from '../_reducers/chimney-stack.reducers';
import {each} from 'lodash';

export const selectChimneyStacksState = createFeatureSelector<ChimneyStacksState>('chimney-stacks');

export const selectChimneyStackById = (id: number) => createSelector(
    selectChimneyStacksState,
    state => state.entities[id]
);

export const selectAllChimneyStacks = createSelector(
    selectChimneyStacksState,
    fromChimneyStack.selectAll
);

export const selectAllChimneyStacksIds = createSelector(
    selectChimneyStacksState,
    fromChimneyStack.selectIds
);

export const allChimneyStacksLoaded = createSelector(
    selectChimneyStacksState,
    state => state.isAllChimneyStacksLoaded
);


export const selectChimneyStacksPageLoading = createSelector(
    selectChimneyStacksState,
    state => state.listLoading
);

export const selectChimneyStacksActionLoading = createSelector(
    selectChimneyStacksState,
    state => state.actionLoading
);

export const selectLastCreatedChimneyStackId = createSelector(
    selectChimneyStacksState,
    state => state.lastCreatedChimneyStackId
);

export const selectChimneyStacksShowInitWaitingMessage = createSelector(
    selectChimneyStacksState,
    state => state.showInitWaitingMessage
);


export const selectTrashedChimneyStackCount = createSelector(
    selectChimneyStacksState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedChimneyStackCount = createSelector(
    selectChimneyStacksState,
    (state) => state.totalAdminTrashed
);


export const selectChimneyStackQueryResult = createSelector(
    selectChimneyStacksState,
    state => {
        const items: ChimneyStackModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ChimneyStackModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
