<kt-portlet>
    <kt-portlet-header
            [sticky]="true"
            [title]="getComponentTitle()"
            [class]="'kt-portlet-head--lg'"

            [tooltipTitle]="'TOOLTIP.VALUATION_PROCESS.FORM.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.VALUATION_PROCESS.FORM.DESCRIPTION' | translate"
            [viewLoading$]="loading$">

        <ng-container ktPortletTools>
            <a href="javascript:;"
               (click)="back()"
               class="btn btn-secondary mr-2" mat-raised-button matTooltip="Back to ToE Dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a *ngIf="!readonly"
                href="javascript:;"
               class="btn btn-success mr-2"
               color="warn"
               style="background-color: orange !important;"
               (click)="onSubmit(false)"
               mat-raised-button
               matTooltip="Save to Draft">
                <span class="kt-hidden-mobile">Save draft</span>
            </a>
            <button *ngIf="!readonly" mat-raised-button class="btn btn-success mr-2" (click)="onSubmit(true)" [disabled]="(summaryTab$|async)" matTooltip="Save to Validate">
                Validate
            </button>
            <!-- <a href="javascript:;" class="btn btn-success mr-2" (click)="onSubmit(true)" mat-raised-button matTooltip="Save to Validate" [disabled]="true">
                <span class="kt-hidden-mobile">Validate</span>
            </a> -->
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <div class="kt-portlet-body body_padding_top_zero">


            <ng-container *ngIf="hasError$|async as he">
                <kt-alert *ngIf="!he.valid" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{he.msg}}
                </kt-alert>
            </ng-container>

            <div class="col-lg-12 px-4">
                <kt-tab-header 
                    [headerLabels]="tabHeaders"
                    (tabChange)="onTabChange($event)"
                    ></kt-tab-header>
                <mat-tab-group
                    class="invinsible-tabs"
                    [(selectedIndex)]="selectedTab"
                        (selectedTabChange)="selectedTabChange($event)">

                    <mat-tab>
                        <ng-template mat-tab-label>
                            1 - Background
                        </ng-template>
                        <ng-template matTabContent>
                            <kt-background-tab
                                [readonly]="readonly" 
                                [targetProperty]="assetClass" 
                                [toe]="toeData" 
                                [tpDetails]="assetClassDet"
                                [assignment]="assignment"></kt-background-tab>
                        </ng-template>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            2 - Landmarks
                            <!--<span class="label label-md label-rounded label-primary ml-2">3</span>-->
                        </ng-template>
                        <ng-template matTabContent>
                            <kt-valuation-keyplace
                                    [readonly]="readonly"
                                    [assetClass]="assetClass">
                            </kt-valuation-keyplace>
                            <!--<kt-portlet-body style="padding-left: 0px !important; padding-right: 0px !important;">-->

                            <!--</kt-portlet-body>-->
                            <!-- end::Body -->

                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="false">
                        <ng-template mat-tab-label>
                            3 - Comparables
                        </ng-template>
                        <ng-template matTabContent>
                            <!-- <kt-valuation-comparable
                                    [assetClass]="assetClass">
                            </kt-valuation-comparable> -->
                            <kt-comparable-tab 
                                [readonly]="readonly"
                                [targetProperty]="assetClass" 
                                [currencyExchangeChannel]="currencyExchangeChannel"
                                [allConvertedChannel]="allConvertedChannel">
                            </kt-comparable-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="tabAdjustment$ | async">
                        <ng-template mat-tab-label>
                            4 - Adjustments
                        </ng-template>
                        <ng-template matTabContent>
                            <kt-adjustment-tab
                                    [tpDetails]="assetClassDet"
                                    [readonly]="readonly"
                                    [targetProperty]="assetClass"
                                    [toe]="toeData">
                            </kt-adjustment-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="tabAdjustment$ | async">
                        <ng-template mat-tab-label>
                            5 - Preliminaries review
                        </ng-template>
                        <ng-template matTabContent>
                            <kt-assumptions-tab
                                [readonly]="readonly"
                                [targetProperty]="assetClass"
                                [toe]="toeData">
                            </kt-assumptions-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="!(summaryTab$|async)">
                        <ng-template mat-tab-label>
                            6 - Summary
                        </ng-template>
                        <ng-template matTabContent>
                            <!-- <div class="bg-light d-flex justify-content-center" style="height: 250px; z-index: 0;">

                                <div class="align-content-center" style="padding-top: 120px">
                                </div>
                            </div> -->
                            <kt-summary-tab
                                [toe]="toeData"
                                [targetProperty]="assetClass">
                            </kt-summary-tab>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </kt-portlet-body>
</kt-portlet>