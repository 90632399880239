import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { ICategory, IDocument, IFile } from '../category.interface';

@Component({
  selector: 'kt-document-tree-v2',
  templateUrl: './document-tree-v2.component.html',
  styleUrls: ['./document-tree-v2.component.scss']
})
export class DocumentTreeV2Component implements OnInit {
  @Input() readonly: boolean;
  @Input() title: string;
  @Input() data$: Observable<IFile[]>;
  files: IFile[] = [];

  selectedDocs$: BehaviorSubject<IFile[]> = new BehaviorSubject([]);

  constructor(
    private typeUtilsService: TypesUtilsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.data$.subscribe(res => {
      this.files = res;
      this.selectedDocs$.next(this.getSelectedFiles(this.files));
    })
    // this.files = dummyFiles() 
  }

  showDocument(url: string) {
    if (url) {
      if (this.typeUtilsService.getFileType(url) === 'docx') {
        window.open(url, '_blank');
      } else {
        this.dialog.open(ImageViewerDialogComponent, {
          data: {
            picture: url,
            type: this.typeUtilsService.getFileType(url)
          }
        })
      }
    }
  }

  onFileClick(file: IFile) {
    if (!(file.isDir)) {
      return;
    }
    this.files = this.files.map(f => {
      if (f.isDir) {
        if (f.id === file.id) {
          f.selected = true;
        } else {
          f.selected = false;
        }

        if (f.subFiles.length > 0) {
          f.subFiles = f.subFiles.map(sf => {
            if (sf.isDir) {
              if (sf.id === file.id) {
                sf.selected = true;
                f.selected = true;
              } else {
                sf.selected = false;
              }
              return sf;
            }
            return sf;
          })
        }
      }

      return f;
    })
  }

  onSelect(event: MatCheckboxChange, file: IFile) {
    this.files = this.changeFileSelectStatus(this.files, file, event.checked);
    const currentSelectedFiles = this.getSelectedFiles(this.files);
    this.selectedDocs$.next(currentSelectedFiles);
  }

  removeDocument(file: IFile) {
    this.files = this.changeFileSelectStatus(this.files, file, false);
    const currentSelectedFiles = this.getSelectedFiles(this.files);
    this.selectedDocs$.next(currentSelectedFiles);
  }

  getDocuments(): number[] {
    const currentSelectedFiles = this.selectedDocs$.value;
    return currentSelectedFiles.map(f => f.id);
  }

  private getSelectedFiles(files: IFile[]): IFile[] {
    const sFiles: IFile[] = [];
    files.forEach(f => {
      if (f.isDir) {
        const rsFiles = this.getSelectedFiles(f.subFiles);
        sFiles.push(...rsFiles);
      } else if (f.selected) {
        sFiles.push(f);
      }
    })
    return sFiles;
  }

  private changeFileSelectStatus(files: IFile[], file: IFile, checked: boolean): IFile[] {
    return files.map(f => {
      if (f.isDir) {
        f.subFiles = this.changeFileSelectStatus(f.subFiles, file, checked);
      } else if (f.id === file.id) {
        f.selected = checked;
      }
      return f;
    })
  }
}

function dummyFiles(): IFile[] {
  return [
      {
        id: 1,
        isDir: true,
        name: 'Dir1',
        selected: false,
        subFiles: [
          {
            id: 1,
            isDir: false,
            selected: false,
            name: 'D1F1',
            subFiles: [],
          },
          {
            id: 2,
            isDir: false,
            selected: false,
            name: 'D1F2',
            subFiles: [],
          }
        ]
      },
      {
        id: 2,
        isDir: true,
        name: 'Dir2',
        selected: false,
        subFiles: [
          {
            id: 3,
            isDir: false,
            selected: false,
            name: 'D2F1',
            subFiles: [],
          }
        ]
      },
      {
        id: 3,
        isDir: true,
        name: 'Dir3',
        selected: false,
        subFiles: [
          {
            id: 4,
            isDir: true,
            selected: false,
            name: 'D3Dir1',
            subFiles: [
              {
                id: 7,
                isDir: false,
                selected: false,
                name: 'D3D1F1',
                subFiles: [],
              }
            ]
          }
        ]
      },
      {
        id: 13,
        isDir: false,
        name: 'File',
        selected: false,
        subFiles: []
      }
    ] 
}
