// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// Translate
import {TranslateModule} from '@ngx-translate/core';
import {PartialsModule} from '../../partials/partials.module';
// Services
import {HttpUtilsService, TypesUtilsService, 
    // InterceptService, 
    LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import {ActionNotificationComponent} from '../../partials/content/crud';
// Components
import {UserManagementComponent} from './user-management.component';
import {UsersListComponent} from './users/users-list/users-list.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UserRolesListComponent} from './users/_subs/user-roles/user-roles-list.component';

// Material
import {
    usersReducer,
    UserEffects
} from '../../../core/auth';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatNativeDateModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UserService} from '../../../core/auth/_services';
import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared_components/shared.module';
import {UserResolver} from './users/user-edit/user-resolver';
import { CertificateEditDialogComponent } from '../../partials/content/crud/certificate-edit-dialog/certificate-edit-dialog.component';
import { MembershipsTableComponent } from './users/_subs/memberships-table/memberships-table.component';
import { OtherDocumentsComponent } from './users/_subs/other-documents/other-documents.component';
import { TimezoneService } from 'src/app/core/system-settings/timezone.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { MadSharedModule } from '../mad_shared/shared.module';

const routes: Routes = [
    {
        path: '',
        component: UserManagementComponent,
        children: [
            {
                path: '',
                redirectTo: 'roles',
                pathMatch: 'full'
            },
            {
                path: 'users',
                component: UsersListComponent
            },
            {
                path: 'users/add',
                component: UserEditComponent
            },
            {
                path: 'users/edit/:id',
                component: UserEditComponent,
                resolve: {data: UserResolver}
            },
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        PartialsModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('users', usersReducer),
        EffectsModule.forFeature([UserEffects]),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        NgbModule,
        NgbPopoverModule,
        SharedModule,
        NgxMatSelectSearchModule,
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        MadSharedModule
    ],
    providers: [
        /** Auth Comment
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        */
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                maxHeigth: 'auto',
                width: '900px'
            }
        },
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        UserService,
        UserResolver,
        // TimezoneService,
    ],
    declarations: [
        UserManagementComponent,
        UsersListComponent,
        UserEditComponent,
        UserRolesListComponent,
        MembershipsTableComponent,
        OtherDocumentsComponent,
    ]
})
export class UserManagementModule {
}
