import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { 
    VAssetClassSize,
    SizeCriterionModel,
    VComparable
} from '../_models'
import { ValuationProcessActionTypes } from "./valuation-process.actions";
import { ValuationAdjustmentTabDataModel } from "../_models/valuation-adjustment-tab-data.model";

export enum ValuationProcessSizeCriterionActionTypes {
    CreateSizeCriterions = '[Valuation Process Effect] Create Size Criterions',
    SizeCriterionsCreated = '[Size Criterion Effect] Size Criterions Created',

    UpdateSizeCriterion = '[Sizes Table] Update Size Criterion Value',
    SizeCriterionUpdated = '[Size Criterion Effect] Size Criterion Value Updated',

    SetDefaultSizeCriterion = '[Sizes Table] Set Default Size Criterion',

    AddComprable = '[Valuation Process Effect] Add Comparable to size criterions',
    ComparableAdded = '[Size Criterion Effect] Comparable Added to size criterions',
    AddMultipleComparables = '[Valuation Comparable Effect] Add Multiple Comparable to size criterions',
    MultipleComparableAdded = '[Size Criterion Effect] Multiple Comparables Added to size criterions',

    RemoveComparable = '[Valuation Process Effect] Remove Comparable from size criterions',

    ResetState = '[Valuation Process Page] Reset state of valuation process size criterions',

    LoadCriterions = '[Valuation Process Effect] Load size criterion from server'
}

export class ValuationProcessCreateSizeCriterions implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.CreateSizeCriterions
    constructor(public payload: {
        refNum: string,
        sizes: VAssetClassSize[]
    }){}
}

export class ValuationProcessSizeCriterionsCreated implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.SizeCriterionsCreated
    constructor(public payload: {
        criterions: SizeCriterionModel[]
    }) {}
}

export class ValuationProcessUpdateSizeCriterion implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.UpdateSizeCriterion
    constructor(public payload: {
        criterion: SizeCriterionModel,
        value: number,
        refNum: string
    }) {}
}

export class ValuationProcessSetDefaultSizeCriterion implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.SetDefaultSizeCriterion

    constructor(public payload: {
        criterion: SizeCriterionModel
    }) {}
}

export class ValuationProcessSizeCriterionAddComparable implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.AddComprable
    constructor(public payload: {
        refNum: string,
        sizes: VAssetClassSize[]
    }) {}
}

export class ValuationProcessSizeCriterionComparableAdded implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.ComparableAdded
    constructor(public payload: {
        criterions: SizeCriterionModel[]
    }) {}
}

export class ValuationProcessSizeCriterionRemoveComparable implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.RemoveComparable
    constructor(public payload: {
        refNum: string
    }){}
}

export class ValuationProcessSizeCriterionReset implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.ResetState
}

export class ValuationProcessLoadSizeCriterions implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.LoadCriterions
    constructor(public payload: {
        crits: SizeCriterionModel[] 
    }) {}
}

export class ValuationProcessSizeCriterionAddMultipleComparables implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.AddMultipleComparables    
    constructor(public payload: {
        comparables: {
            refNum: string,
            sizes: VAssetClassSize[]
        }[],
        adjustmentTabData: ValuationAdjustmentTabDataModel
    }) {}
}

export class ValuationProcessSizeCriterionMultipleComparablesAdded implements Action {
    readonly type = ValuationProcessSizeCriterionActionTypes.MultipleComparableAdded
    constructor(public payload: {
        criterions: SizeCriterionModel[],
        importedCriterions: SizeCriterionModel[]
    }) {}
}

export type ValuationProcessSizeCriterionActions 
    = ValuationProcessCreateSizeCriterions
    | ValuationProcessSizeCriterionsCreated
    | ValuationProcessUpdateSizeCriterion
    | ValuationProcessSetDefaultSizeCriterion
    | ValuationProcessSizeCriterionComparableAdded
    | ValuationProcessSizeCriterionAddComparable
    | ValuationProcessSizeCriterionRemoveComparable
    | ValuationProcessSizeCriterionReset
    | ValuationProcessLoadSizeCriterions

    | ValuationProcessSizeCriterionAddMultipleComparables
    | ValuationProcessSizeCriterionMultipleComparablesAdded