<kt-portlet>
    <kt-portlet-header [sticky]="true" [title]="title$|async" [class]="'kt-portlet-head--lg'">
        <ng-container ktPortletTools>
            <a href="javascript:;" (click)="back()" class="btn btn-secondary mr-2" mat-raised-button
                matTooltip="Back to ToE Dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a href="javascript:;" class="btn btn-success mr-2" color="warn"
                style="background-color: orange !important;" (click)="onSubmit(false)" mat-raised-button
                *ngIf="!readonly" matTooltip="Save to Draft">
                <span class="kt-hidden-mobile">Save draft</span>
            </a>
            <button *ngIf="!readonly" mat-raised-button class="btn btn-success mr-2" (click)="onSubmit(true)"
                matTooltip="Save to Validate">
                Validate
            </button>
        </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
        <div *ngIf="!(loading$|async); else loading">
            <kt-tab-header [headerLabels]="headerLabels" (tabChange)="onTabChange($event)"></kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab">
                <mat-tab>
                    <ng-template mat-tab-label>
                        1 - Target Property
                    </ng-template>
                    <ng-template matTabContent>
                        <kt-tp-info [tp]="tp" [toe]="toe"></kt-tp-info>
                    </ng-template>
                </mat-tab>
                <ng-container *ngIf="(valuations$|async) as vals">
                    <mat-tab *ngFor="let valuation of vals; let i = index">
                        <ng-template mat-tab-label>
                            {{i + 2}} - Valuation {{vals.length === 1 ? '' : i + 1}}
                        </ng-template>
                        <ng-template matTabContent>
                            <kt-valuation-info [tp]="tp" [toe]="toe" [valuation]="valuation" [workers]="workers"
                                [assignment]="assignment" [reporting]="reporting"></kt-valuation-info>
                        </ng-template>
                    </mat-tab>
                </ng-container>
                <!-- <mat-tab [disabled]="!(reportingLoaded|async)">
                    <kt-design-layout-v2 [readonly]="readonly" [targetProperty]="tp" [reporting]="reporting"
                        [selectedTpPics]="reporting.tp_selected_pictures">
                    </kt-design-layout-v2>
                </mat-tab> -->
                <mat-tab [disabled]="!(reportingLoaded|async)">
                    <kt-reporting-settings-v2 [readonly]="readonly" [leadValuer]="leadValuer" [members]="members"
                        [agency]="agency" [toe]="toe" [reporting]="reporting" [tp]="tp"
                        [userID]="currentUser && currentUser.id" 
                        [valuations]="(valuations$|async)"
                        [toeMembers]="toeMembers"
                        [valuationsWithScenario]="(valuationWithScenarios$|async)">
                    </kt-reporting-settings-v2>
                </mat-tab>
            </mat-tab-group>
        </div>
    </kt-portlet-body>
</kt-portlet>

<ng-template #loading>
    Loading...
</ng-template>