// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ParcelCharacteristicsService} from '../_services/parcel-characteristic.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allParcelCharacteristicsLoaded} from '../_selectors/parcel-characteristic.selectors';
// Actions
import {
    AllParcelCharacteristicsLoaded,
    AllParcelCharacteristicsRequested,
    ParcelCharacteristicActionTypes,
    ParcelCharacteristicsPageRequested,
    ParcelCharacteristicsPageLoaded,
    ParcelCharacteristicUpdated,
    ParcelCharacteristicsPageToggleLoading,
    ParcelCharacteristicDeleted,
    ParcelCharacteristicOnServerCreated,
    ParcelCharacteristicCreated,
    ParcelCharacteristicAdminRestored,
    ParcelCharacteristicDeletedFromAdminTrash,
    ParcelCharacteristicDeletedFromTrash,
    ParcelCharacteristicOnServerAdminRestored,
    ParcelCharacteristicOnServerRestored,
    ParcelCharacteristicRestored,
    ParcelCharacteristicTrashFlushed,
    ParcelCharacteristicsActionToggleLoading
} from '../_actions/parcel-characteristic.actions';

@Injectable()
export class ParcelCharacteristicEffects {
    showPageLoadingDispatcher = new ParcelCharacteristicsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ParcelCharacteristicsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ParcelCharacteristicsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllParcelCharacteristics$ = this.actions$
        .pipe(
            ofType<AllParcelCharacteristicsRequested>(ParcelCharacteristicActionTypes.AllParcelCharacteristicsRequested),
            withLatestFrom(this.store.pipe(select(allParcelCharacteristicsLoaded))),
            filter(([action, isAllParcelCharacteristicsLoaded]) => !isAllParcelCharacteristicsLoaded),
            mergeMap(() => this.service.getAllParcelCharacteristics()),
            map(res => {
                return new AllParcelCharacteristicsLoaded({parcelCharacteristics: res.data});
            })
        );

    @Effect()
    loadParcelCharacteristicsPage$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicsPageRequested>(ParcelCharacteristicActionTypes.ParcelCharacteristicsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findParcelCharacteristics(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ParcelCharacteristicsPageLoaded({
                    parcelCharacteristics: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicDeleted>(ParcelCharacteristicActionTypes.ParcelCharacteristicDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteParcelCharacteristic(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicUpdated>(ParcelCharacteristicActionTypes.ParcelCharacteristicUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateParcelCharacteristic(payload.parcelCharacteristic);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicOnServerCreated>(ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createParcelCharacteristic(payload.parcelCharacteristic).pipe(
                    tap(res => {
                        this.store.dispatch(new ParcelCharacteristicCreated({parcelCharacteristic: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushParcelCharacteristicTrash$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicTrashFlushed>(ParcelCharacteristicActionTypes.ParcelCharacteristicTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicOnServerRestored>(ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ParcelCharacteristicRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicOnServerAdminRestored>(ParcelCharacteristicActionTypes.ParcelCharacteristicOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ParcelCharacteristicAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicDeletedFromTrash>(ParcelCharacteristicActionTypes.ParcelCharacteristicDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashParcelCharacteristic$ = this.actions$
        .pipe(
            ofType<ParcelCharacteristicDeletedFromAdminTrash>(ParcelCharacteristicActionTypes.ParcelCharacteristicDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ParcelCharacteristicsService, private store: Store<AppState>) {
    }
}
