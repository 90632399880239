import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AdjustmentValuation } from "../_models/valuation.model";
import * as valuationActions from '../_actions/valuation.actions';

export interface ValuationState extends EntityState<AdjustmentValuation> {
    lastCreatedID: number;
    totalWeightedAvgGrossUnitRate: number;
    totalPropertyValue: number;
    justification: string;
    capitalAllowance: number;
    capitalAllowanceJustification: string;
    totalTPValue: number;
    totalTPWeighted: number;
}
export const adapter: EntityAdapter<AdjustmentValuation> = createEntityAdapter();
export const initialValuationState: ValuationState = adapter.getInitialState({
    lastCreatedID: 0,
    totalWeightedAvgGrossUnitRate: undefined,
    totalPropertyValue: undefined,
    justification: '',
    capitalAllowance: 0,
    capitalAllowanceJustification: '',
    totalTPValue: undefined,
    totalTPWeighted: undefined
})
export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();
export function valuationReducer(
    state = initialValuationState,
    action: valuationActions.AdjustmentValuationActions
): ValuationState {
    switch (action.type) {
        case valuationActions.AdjustmentValuationActionTypes.ValuationAdded: {
            const val = action.payload.valuation;
            const newState = adapter.addOne(val, {...state, lastCreatedID: val.id});
            return newState;
        }
        case valuationActions.AdjustmentValuationActionTypes.ValuationsUpdated: {
            return adapter.setAll(action.payload.valuations, {...state});
        }
        case valuationActions.AdjustmentValuationActionTypes.ValuationUpdated: {
            return adapter.updateOne(action.payload.updateValuation, {...state});
        }
        case valuationActions.AdjustmentValuationActionTypes.ValuationTotalsComputed: {
            return {
                ...state,
                totalWeightedAvgGrossUnitRate: action.payload.totalWeighted,
                totalPropertyValue: action.payload.totalValue,
                totalTPValue: action.payload.totalTPValue,
                totalTPWeighted: action.payload.totalTPWeighted
            }
        }
        case valuationActions.AdjustmentValuationActionTypes.ValuationRemoved: {
            return adapter.removeOne(action.payload.id, {...state});
        }
        case valuationActions.AdjustmentValuationActionTypes.ResetValuationState: {
            return initialValuationState;
        }
        case valuationActions.AdjustmentValuationActionTypes.MultipleValuationAdded: {
            return adapter.setAll(action.payload.valuations, {...state , lastCreatedID: action.payload.id});
        }
        case valuationActions.AdjustmentValuationActionTypes.UpdateJustification: {
            return {
                ...state,
                justification: action.payload.justification
            }
        }
        case valuationActions.AdjustmentValuationActionTypes.UpdateCapitalAllowance: {
            return {
                ...state,
                capitalAllowance: action.payload.capitalAllowance
            }
        }
        case valuationActions.AdjustmentValuationActionTypes.UpdateCapitalAllowanceJustification: {
            return {
                ...state,
                capitalAllowanceJustification: action.payload.capitalAllowanceJustification
            }
        }
        default: 
            return state
    }
}