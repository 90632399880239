// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {OutdoorSpaceService} from '../_services/outdoor-space.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allOutdoorSpacesLoaded} from '../_selectors/outdoor-space.selectors';
// Actions
import {
    AllOutdoorSpacesLoaded,
    AllOutdoorSpacesRequested,
    OutdoorSpaceActionTypes,
    OutdoorSpacesPageRequested,
    OutdoorSpacesPageLoaded,
    OutdoorSpaceUpdated,
    OutdoorSpacesPageToggleLoading,
    OutdoorSpaceDeleted,
    OutdoorSpaceOnServerCreated,
    OutdoorSpaceCreated,
    OutdoorSpaceActionToggleLoading, OutdoorSpaceTrashFlushed, OutdoorSpaceOnServerRestored, OutdoorSpaceRestored,
    OutdoorSpaceOnServerAdminRestored, OutdoorSpaceAdminRestored, OutdoorSpaceDeletedFromTrash, OutdoorSpaceDeletedFromAdminTrash
} from '../_actions/outdoor-space.actions';

@Injectable()
export class OutdoorSpaceEffects {
    showPageLoadingDispatcher = new OutdoorSpacesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OutdoorSpaceActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OutdoorSpaceActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOutdoorSpace$ = this.actions$
        .pipe(
            ofType<AllOutdoorSpacesRequested>(OutdoorSpaceActionTypes.AllOutdoorSpacesRequested),
            withLatestFrom(this.store.pipe(select(allOutdoorSpacesLoaded))),
            filter(([action, isAllOutdoorSpaceLoaded]) => !isAllOutdoorSpaceLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllOutdoorSpacesLoaded({items: res.data});
            })
        );

    @Effect()
    loadOutdoorSpacePage$ = this.actions$
        .pipe(
            ofType<OutdoorSpacesPageRequested>(OutdoorSpaceActionTypes.OutdoorSpacesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OutdoorSpacesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceUpdated>(OutdoorSpaceActionTypes.OutdoorSpaceUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceOnServerCreated>(OutdoorSpaceActionTypes.OutdoorSpaceOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new OutdoorSpaceCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushOutdoorSpaceTrash$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceTrashFlushed>(OutdoorSpaceActionTypes.OutdoorSpaceTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceOnServerRestored>(OutdoorSpaceActionTypes.OutdoorSpaceOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OutdoorSpaceRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceOnServerAdminRestored>(OutdoorSpaceActionTypes.OutdoorSpaceOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OutdoorSpaceAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceDeleted>(OutdoorSpaceActionTypes.OutdoorSpaceDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceDeletedFromTrash>(OutdoorSpaceActionTypes.OutdoorSpaceDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashOutdoorSpace$ = this.actions$
        .pipe(
            ofType<OutdoorSpaceDeletedFromAdminTrash>(OutdoorSpaceActionTypes.OutdoorSpaceDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: OutdoorSpaceService, private store: Store<AppState>) {
    }
}
