<div class="form-group row section-margion-top-60">
    <div class="col-md-12 mt-10">
        <div class="row" [formGroup]="form" *ngIf="form">
            <div class="col-md-12">
                <h5 class="my-3 text-mad text-uppercase">
                Property Observation checklist for identifying potential environmental issues 
                </h5>
                <hr class="active">
            </div>
            <div class="col-md-12">
                <div class="mb-2">
                    <mat-label>How would you like to record this information?</mat-label>
                    <mat-radio-group class="ml-4" formControlName="record_info_type">
                        <mat-radio-button [value]="1">Manually</mat-radio-button>
                        <mat-radio-button [value]="2">Upload document(s)</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <kt-property-observation-checklist 
                class="col-md-12 mt-10"
                [propertyTypeId]="propertyTypeID"
                [inputData]="observationChecklist"
                *ngIf="form.controls.record_info_type.value == 1">
            </kt-property-observation-checklist>
            <kt-ac-document-upload
                class="col-md-12 mt-10"
                *ngIf="form.controls.record_info_type.value == 2"
                [hasTitle]="false" [files]="observationDocuments"></kt-ac-document-upload>
        </div>
    </div>
</div>