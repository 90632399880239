<div class="card card-custom" [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="landAreaComponent">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="landAreaComponent">
        <form [formGroup]="form" class="form form-group-seperator-dashed">
            <div class="card-body" [ngClass]="{'readonly': data.disable}">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    Oops! It looks like you’ve forgotten some important information.
                </kt-alert>

                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Area Unit Type" formControlName="unit_area_measurement_id">
                                <mat-option *ngFor="let unitAreaMeasurement of unitAreaMeasurements" [value]="unitAreaMeasurement.id">{{unitAreaMeasurement.name}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Area Unit Type
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Surface" formControlName="surface" mask="separator" thousandSeparator=","/>
                            <mat-error>
                                Surface
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Restricted / limited use" formControlName="limited_use"/>
                            <mat-error>
                                Restricted / limited use
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Underground restrictions (depth)" formControlName="underground_restriction" mask="separator" thousandSeparator=","/>
                            <mat-error>
                                Underground restrictions (depth)
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Aerial restriction (height)" formControlName="aerial_restriction" mask="separator" thousandSeparator=","/>
                            <mat-error>
                                Aerial restriction (height)
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Length Unit Type" formControlName="unit_length_measurement_id">
                                <mat-option *ngFor="let unitLengthMeasurement of unitLengthMeasurements" [value]="unitLengthMeasurement.id">{{unitLengthMeasurement.name}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Length Unit Type
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 b-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="Close">
                                Close
                            </button>&nbsp;
                            <button *ngIf="!data.disable" type="button" [disabled]="!isFormValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                    matTooltip="Save changes">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
