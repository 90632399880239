// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {
    selectStateRepairQueryResult,
    selectStateRepairsPageLoading,
    selectStateRepairsShowInitWaitingMessage
} from '../_selectors/state-repair.selectors';


// type of title
// State of repair
// Building Type

export class StateRepairsDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectStateRepairsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectStateRepairsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectStateRepairQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
