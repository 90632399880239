// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassRetailFacilitiesService} from '../_services/asset-class-retail-facility.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AllAssetClassRetailFacilitiesRequested,
    AllAssetClassRetailFacilitiesLoaded,
    AssetClassRetailFacilityActionToggleLoading,
    AssetClassRetailFacilityActionTypes
} from '../_actions/asset-class-retail-facility.actions';

@Injectable()
export class AssetClassRetailFacilityEffects {
    showActionLoadingDistpatcher = new AssetClassRetailFacilityActionToggleLoading({isLoading: true});
    hideActionLoadingDistpatcher = new AssetClassRetailFacilityActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOfficeTypes$ = this.actions$
        .pipe(
            ofType<AllAssetClassRetailFacilitiesRequested>(AssetClassRetailFacilityActionTypes.AllAssetClassRetailFacilitiesRequested),
            mergeMap(({payload}) => this.assetClassRetailsService.getAllAssetClassRetailFacilities(payload.assetClassRetailId)),
            map(res => {
                return new AllAssetClassRetailFacilitiesLoaded({assetClassRetailFacilities: res.data});
            })
        );

    constructor(private actions$: Actions, private assetClassRetailsService: AssetClassRetailFacilitiesService, private store: Store<AppState>) {
    }
}
