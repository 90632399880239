import { Component, OnInit } from '@angular/core';
import { SubheaderService } from 'src/app/core/_base/layout';

@Component({
    selector: 'kt-linked-tables',
    templateUrl: './linked-tables.component.html',
})
export class LinkedTablesComponent implements OnInit {
    constructor(public subheaderService: SubheaderService) {}

    ngOnInit() {
        this.subheaderService.setTitle('Linked Tables');
    }
}