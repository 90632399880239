// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {FloorType} from '../_models/floor-type.model';

export enum FloorTypeActionTypes {

    AllFloorTypesRequested = '[FloorTypes Home Page] All FloorTypes Requested',
    AllFloorTypesLoaded = '[FloorTypes API] All FloorTypes Loaded',

    FloorTypeOnServerCreated = '[Edit FloorType Dialog] FloorType On Server Created',
    FloorTypeCreated = '[Edit FloorTypes Dialog] FloorTypes Created',
    FloorTypeUpdated = '[Edit FloorType Dialog] FloorType Updated',
    FloorTypeDeleted = '[FloorTypes List Page] FloorType Deleted',

    FloorTypesPageRequested = '[FloorTypes List Page] FloorTypes Page Requested',
    FloorTypesPageLoaded = '[FloorTypes API] FloorTypes Page Loaded',
    FloorTypesPageCancelled = '[FloorTypes API] FloorTypes Page Cancelled',

    FloorTypesPageToggleLoading = '[FloorTypes page] FloorTypes Page Toggle Loading',
    FloorTypesActionToggleLoading = '[FloorTypes] FloorTypes Action Toggle Loading',

    // trash system

    FloorTypeOnServerRestored = '[FloorType Trash] FloorType On Server Restored',
    FloorTypeRestored = '[FloorType Trash] FloorType Restored',

    FloorTypeOnServerAdminRestored = '[FloorType Admin Trash] FloorType On Server Restored',
    FloorTypeAdminRestored = '[FloorType Admin Trash] FloorType Restored',

    FloorTypeDeletedFromTrash = '[FloorType Trash] FloorType deleted',
    FloorTypeDeletedFromAdminTrash = '[FloorType Admin Trash] FloorType deleted',

    FloorTypeTrash = 'FloorType Trashed',
    FloorTypeTrashFlushed = 'FloorType Trash Flushed',

    FloorTypesClear = '[FloorTypes API] FloorTypes Page Clear',
}

export class FloorTypeOnServerCreated implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeOnServerCreated;

    constructor(public payload: { floorType: FloorType}) {
    }
}

export class FloorTypeCreated implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeCreated;

    constructor(public payload: { floorType: FloorType }) {
    }
}

export class FloorTypeUpdated implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeUpdated;

    constructor(public payload: {
        partialFloorType: Update<FloorType>,
        floorType: FloorType
    }) {
    }
}

export class FloorTypeDeleted implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class FloorTypesPageRequested implements Action {
    readonly type = FloorTypeActionTypes.FloorTypesPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class FloorTypesPageLoaded implements Action {
    readonly type = FloorTypeActionTypes.FloorTypesPageLoaded;

    constructor(public payload: { floorTypes: FloorType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class FloorTypesPageCancelled implements Action {
    readonly type = FloorTypeActionTypes.FloorTypesPageCancelled;
}

export class AllFloorTypesRequested implements Action {
    readonly type = FloorTypeActionTypes.AllFloorTypesRequested;
}

export class AllFloorTypesLoaded implements Action {
    readonly type = FloorTypeActionTypes.AllFloorTypesLoaded;

    constructor(public payload: { floorTypes: FloorType[] }) {
    }
}

export class FloorTypesPageToggleLoading implements Action {
    readonly type = FloorTypeActionTypes.FloorTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class FloorTypesActionToggleLoading implements Action {
    readonly type = FloorTypeActionTypes.FloorTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class FloorTypeDeletedFromAdminTrash implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class FloorTypeDeletedFromTrash implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class FloorTypeOnServerRestored implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FloorTypeRestored implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeRestored;

    constructor(public payload: { item: FloorType }) {
    }
}

export class FloorTypeOnServerAdminRestored implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FloorTypeAdminRestored implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeAdminRestored;

    constructor(public payload: { item: FloorType }) {
    }
}

export class FloorTypeTrashFlushed implements Action {
    readonly type = FloorTypeActionTypes.FloorTypeTrashFlushed;

    constructor() {
    }
}

export class FloorTypesClear implements Action {
    readonly type = FloorTypeActionTypes.FloorTypesClear;

    constructor() {
    }
}

export type FloorTypeActions = FloorTypeCreated
    | FloorTypeUpdated
    | FloorTypeDeleted
    | FloorTypesPageRequested
    | FloorTypesPageLoaded
    | FloorTypesPageCancelled
    | AllFloorTypesLoaded
    | AllFloorTypesRequested
    | FloorTypeOnServerCreated
    | FloorTypesPageToggleLoading
    | FloorTypesActionToggleLoading
    | FloorTypeDeletedFromAdminTrash
    | FloorTypeDeletedFromTrash
    | FloorTypeOnServerRestored
    | FloorTypeRestored
    | FloorTypeOnServerAdminRestored
    | FloorTypeAdminRestored
    | FloorTypeTrashFlushed
    | FloorTypesClear;
