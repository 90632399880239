import {BaseModel} from '../../_base/crud';

export class PropertyType extends BaseModel {
    id: number;
    name: string;
    asset_class_type_id: number;
    asset_class_type_name: string;
    relation_cnt: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    deletedBy: string;
    user_deleted: Date;
    userDeletedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.asset_class_type_id = undefined;
        this.asset_class_type_name = '';
        this.relation_cnt = 0;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;

        this.deletedBy = '';
        this.userDeletedBy = '';
        this.user_deleted = new Date();
    }
}
