import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-text-with-picture-slider-modal',
  templateUrl: './text-with-picture-slider-modal.component.html',
  styleUrls: ['./text-with-picture-slider-modal.component.scss']
})
export class TextWithPictureSliderModalComponent implements OnInit {
  @Input()
  inputTitle: string;
  @Input()
  inputTtems: any[];
  @Input()
  inputTndex: number;

  desc = new BehaviorSubject<string>(null);
  items = []
  index = 0;
  title = 'Picture';
  awConst = awConst;

  constructor(
    private activeModal: NgbActiveModal,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.items = this.inputTtems;
    this.index = this.inputTndex ? this.inputTndex : 0;
    if (this.items[this.index].title && this.items[this.index].title.length > 0) {
      this.desc.next(this.items[this.index].title);
    }
    this.title = this.inputTitle ? this.inputTitle : 'Picture';
  }

  onNoClick() {
    this.activeModal.close();
  }

  getURL(path, name) {
    let _picture = path + '/' + name;
    if (_picture.charAt(0) == '/') {
        _picture = _picture.substring(1, _picture.length);
    }
    const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
    return imgURL;
  }
  showDefaultPic($event) {
    $event.target.src = awConst.IMAGE_NOT_FOUND
  }
}
