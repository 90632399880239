import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassRetailShopActions, AssetClassRetailShopActionTypes } from "../_actions/asset-class-retail-shop.actions";
import { AssetClassRetailShopModel } from "../_models/asset-class-retail-shop.model";


export interface AssetClassRetailShopState extends EntityState<AssetClassRetailShopModel> {
    actionLoading: boolean;
    listLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    previouslyCreatedACId: number;
    lastCreatedAssetClassRetailShopId: number;
    showInitWaitingMessage: boolean;
}
export const adapter: EntityAdapter<AssetClassRetailShopModel> = createEntityAdapter<AssetClassRetailShopModel>();
export const initialAssetClassRetailShopState: AssetClassRetailShopState = adapter.getInitialState({
    actionLoading: false,
    listLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    previouslyCreatedACId: undefined,
    lastCreatedAssetClassRetailShopId: undefined,
    showInitWaitingMessage: true
});

export function assetClassRetailShopReducer(
    state = initialAssetClassRetailShopState,
    action: AssetClassRetailShopActions
): AssetClassRetailShopState {
    switch (action.type) {
        case AssetClassRetailShopActionTypes.UpdatePreviouslyCreatedACId: {
            return {
                ...state,
                previouslyCreatedACId: state.lastCreatedAssetClassRetailShopId
            }
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassRetailShopId: undefined
            }
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            }
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerCreated: {
            return state;
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopCreated: {
            return adapter.addOne(
                action.payload.AssetClassRetailShop,
                {...state, previouslyCreatedACId: state.lastCreatedAssetClassRetailShopId, lastCreatedAssetClassRetailShopId: action.payload.AssetClassRetailShop.id}
            )
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopUpdated: {
            return adapter.addOne(
                action.payload.AssetClassRetailShop,
                {...state, lastCreatedAssetClassRetailShopId: action.payload.AssetClassRetailShop.id}
            )
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopPageLoaded: {
            return adapter.addMany(action.payload.AssetClassRetailShops, {
                ...initialAssetClassRetailShopState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            })
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopDeleted: {
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            })
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopDeletedFromTrash: {
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            }
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopDeletedFromAdminTrash:
            return {...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerRestored: {
            return state
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                lastCreatedAssetClassRetailShopId: action.payload.ac.id,
                totalTrashed: state.totalTrashed - 1
            })
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerAdminRestored: {
            return state
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopAdminRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            })
        }
        case AssetClassRetailShopActionTypes.AssetClassRetailShopTrashFlushed: {
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            }
        }
        default: {
            return state;
        }
    }
}