import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { awConst } from 'src/app/app.constants';

type Item = {
  id: number,
  ref: string,
  title: string,
  subtitle: string,
  pictures: any[],
  fields: any[],
  status: number,
  edit_url: string,
  used_times: number,
  points: any[],
  center: any,
  hasPoints: boolean,

  bg: string
}

export type MapMarkerInfoWindowDialogInput = {
  modal_title: string,
  additional?: {
    type: 'open-modal',
    channel: Subject<number>
  };
  entities: Array<{
    id: number,
    ref: string,
    title: string,
    subtitle: string,
    pictures: string[],
    fields: Array<{
      header: string,
      value: {strong: string, simple: string},
      type: string
    }>,
    status: number,
    edit_url: string,
    used_times: number,
    points: any[],
    hasPoints: boolean,
    center: any
  }> 
}

@Component({
  selector: 'kt-map-marker-info-window',
  templateUrl: './map-marker-info-window.component.html',
  styleUrls: ['./map-marker-info-window.component.scss']
})
export class MapMarkerInfoWindowComponent implements OnInit {
  items: Item[] = []
  zoom = 16;
  map: any;
  private _drawingManager: google.maps.drawing.DrawingManager;
  constructor(
    public dialogRef: MatDialogRef<MapMarkerInfoWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MapMarkerInfoWindowDialogInput,
    private router: Router
  ) { 
    this.items = data.entities.map((e, i) => {
      let bg = '';
      if (data.entities.length > 1) {
        bg = i%2 == 0 ? 'gray' : ''
      }
      return {
        id: e.id,
        ref: e.ref,
        title: e.title,
        subtitle: e.subtitle,
        pictures: e.pictures,
        fields: e.fields,
        status: e.status,
        edit_url: e.edit_url,
        used_times: e.used_times,
        points: e.points,
        hasPoints: e.hasPoints,
        center: e.center, 

        bg
      }
    });
  }

  ngOnInit(): void {
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

    this._initializeDrawingManager(this.map)

    this.items.forEach(el => {
      if (el.hasPoints) {
        const _shape = new google.maps.Polygon({
          paths: el.points.filter(p => p.lat != null && p.lng != null),
          editable: false
        });
        _shape.setMap(this.map);
      }
    })
  }
  private _initializeDrawingManager(map: google.maps.Map) {
    const options: google.maps.drawing.DrawingManagerOptions = {
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      polygonOptions: {
        draggable: false,
        editable: true
      }
    }

    this._drawingManager = new google.maps.drawing.DrawingManager(options);
    this._drawingManager.setMap(map);

    google.maps.event.addListener(this._drawingManager, 'overlaycomplete', (event) => {
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  getItemCssClassByStatus(status: number): string {
      switch (status) {
          case 0:
              return 'warning';
          case 2:
              return 'info';
          default:
              return 'success';
      }
  }
  getItemStatusString(status: number): string {
      switch (status) {
          case 0:
              return 'Draft';
          case 2:
              return 'Imported';
          default:
              return 'Validated';
      }
  }

  goEditForm(url: string, id: number) {
    this.dialogRef.close()
    this.router.navigate([url + '' + id]);
  }

  onAction(id: number) {
    this.dialogRef.close();
    if (this.data.additional) {
      this.data.additional.channel.next(id);
      this.data.additional.channel.complete();
      return;
    }
  }

}
