// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {IvsStandardActions, IvsStandardActionTypes} from '../_actions/ivs-standard.actions';
// Models
import {IvsStandard} from '../_models/ivs-standard.model';
import {QueryParamsModel} from '../../_base/crud';

export interface IvsStandardsState extends EntityState<IvsStandard> {
    isAllIvsStandardsLoaded: boolean;
    queryRowsCount: number;
    queryResult: IvsStandard[];
    lastCreatedIvsStandardId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<IvsStandard> = createEntityAdapter<IvsStandard>();

export const initialIvsStandardsState: IvsStandardsState = adapter.getInitialState({
    isAllIvsStandardsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedIvsStandardId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function ivsStandardsReducer(state = initialIvsStandardsState, action: IvsStandardActions): IvsStandardsState {
    switch (action.type) {
        case IvsStandardActionTypes.IvsStandardsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedIvsStandardId: undefined
            };
        case IvsStandardActionTypes.IvsStandardsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case IvsStandardActionTypes.IvsStandardOnServerCreated:
            return {
                ...state
            };
        case IvsStandardActionTypes.IvsStandardCreated:
            return adapter.addOne(action.payload.ivsStandard, {
                ...state, lastCreatedIvsStandardId: action.payload.ivsStandard.id
            });
        case IvsStandardActionTypes.IvsStandardUpdated:
            return adapter.updateOne(action.payload.partialivsStandard, state);
        case IvsStandardActionTypes.AllIvsStandardsLoaded:
            return adapter.addAll(action.payload.ivsStandards, {
                ...state, isAllIvsStandardsLoaded: true
            });
        case IvsStandardActionTypes.IvsStandardsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case IvsStandardActionTypes.IvsStandardsPageLoaded:
            return adapter.addMany(action.payload.ivsStandards, {
                ...initialIvsStandardsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case IvsStandardActionTypes.IvsStandardDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case IvsStandardActionTypes.IvsStandardRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case IvsStandardActionTypes.IvsStandardDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case IvsStandardActionTypes.IvsStandardAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case IvsStandardActionTypes.IvsStandardDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case IvsStandardActionTypes.IvsStandardTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
