// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {CountryActionTypes, CountryActions} from '../_actions/country.actions';
// Models
import {CountryModel} from '../_models/country.model';

export interface CountriesState extends EntityState<CountryModel> {
    _isAllCountriesLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    lastCreatedCountryId: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CountryModel> = createEntityAdapter<CountryModel>();

export const initialCountriesState: CountriesState = adapter.getInitialState({
    _isAllCountriesLoaded: false,
    listLoading: false,
    actionsLoading: false,
    lastCreatedCountryId: undefined,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    showInitWaitingMessage: true
});

export function countriesReducer(state = initialCountriesState, action: CountryActions): CountriesState {
    switch (action.type) {
        case CountryActionTypes.AllCountriesRequested:
            return {
                ...state,
                _isAllCountriesLoaded: false
            };
        case CountryActionTypes.CountriesPageLoaded:
            return adapter.addAll(action.payload.items, {
                ...state,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                _isAllCountriesLoaded: true
            });
        
            case CountryActionTypes.CountryOnServerCreated:
            return {
                ...state
            };
        case CountryActionTypes.CountryCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedCountryId: action.payload.item.id
            });
        case CountryActionTypes.CountryUpdated:
            return adapter.updateOne(action.payload.partialItem, state);


        case CountryActionTypes.CountryDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });


        case CountryActionTypes.CountryTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case CountryActionTypes.CountryRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case CountryActionTypes.CountryDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case CountryActionTypes.CountryAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case CountryActionTypes.CountryDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
