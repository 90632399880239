import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { SubCategoryModel } from "../_models/sub-category.model";

export enum SubCategoryActionTypes {
    AllSubCategorysRequested = '[Linked Area] All SubCategory Requested',
    AllSubCategorysLoaded = '[SubCategory Effect] All SubCategory Loaded',

    SubCategoryOnServerCreated = '[Add SubCategory Page] SubCategory On Server Created',
    SubCategoryCreated = '[SubCategory Effect] SubCategory Created',
    SubCategoryUpdated = '[SubCategory Effect] SubCategory Updated',
    SubCategoryDeleted = '[SubCategory List Page] SubCategory Deleted',

    SubCategoryOnServerRestored = '[SubCategory Trash] SubCategory On Server Restored',
    SubCategoryRestored = '[SubCategory Trash] SubCategory Restored',

    SubCategoryOnServerAdminRestored = '[SubCategory Admin Trash] SubCategory On Server Restored',
    SubCategoryAdminRestored = '[SubCategory Admin Trash] SubCategory Restored',

    SubCategoryDeletedFromTrash = '[SubCategory Trash] SubCategory deleted',
    SubCategoryDeletedFromAdminTrash = '[SubCategory Admin Trash] SubCategory deleted',

    SubCategoryTrash = 'SubCategory Trashed',
    SubCategoryTrashFlushed = 'SubCategory Trash Flushed',

    SubCategorysPageRequested = '[SubCategory List Page] SubCategory Page Requested',
    SubCategorysPageLoaded = '[SubCategory API] SubCategory Page Loaded',
    SubCategorysPageCancelled = '[SubCategory API] SubCategory Page Cancelled',

    SubCategorysPageToggleLoading = '[SubCategory page] SubCategory Page Toggle Loading',
    SubCategoryActionToggleLoading = '[SubCategory] SubCategory Action Toggle Loading'
}

export class SubCategoryOnServerCreated implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryOnServerCreated;

    constructor(public payload: { item: SubCategoryModel }) {
    }
}

export class SubCategoryCreated implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryCreated;

    constructor(public payload: { item: SubCategoryModel }) {
    }
}

export class SubCategoryUpdated implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryUpdated;

    constructor(public payload: {
        partialItem: Update<SubCategoryModel>,
        item: SubCategoryModel
    }) {
    }
}

export class SubCategoryDeleted implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SubCategorysPageRequested implements Action {
    readonly type = SubCategoryActionTypes.SubCategorysPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SubCategorysPageLoaded implements Action {
    readonly type = SubCategoryActionTypes.SubCategorysPageLoaded;

    constructor(public payload: { items: SubCategoryModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SubCategorysPageCancelled implements Action {
    readonly type = SubCategoryActionTypes.SubCategorysPageCancelled;
}

export class AllSubCategorysRequested implements Action {
    readonly type = SubCategoryActionTypes.AllSubCategorysRequested;
}

export class AllSubCategorysLoaded implements Action {
    readonly type = SubCategoryActionTypes.AllSubCategorysLoaded;

    constructor(public payload: { items: SubCategoryModel[] }) {
    }
}

export class SubCategorysPageToggleLoading implements Action {
    readonly type = SubCategoryActionTypes.SubCategorysPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SubCategoryActionToggleLoading implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class SubCategoryDeletedFromAdminTrash implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SubCategoryDeletedFromTrash implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SubCategoryOnServerRestored implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SubCategoryRestored implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryRestored;

    constructor(public payload: { item: SubCategoryModel }) {
    }
}

export class SubCategoryOnServerAdminRestored implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SubCategoryAdminRestored implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryAdminRestored;

    constructor(public payload: { item: SubCategoryModel }) {
    }
}

export class SubCategoryTrashFlushed implements Action {
    readonly type = SubCategoryActionTypes.SubCategoryTrashFlushed;

    constructor() {
    }
}


export type SubCategoryActions = SubCategoryCreated
    | SubCategoryUpdated
    | SubCategoryDeleted
    | SubCategorysPageRequested
    | SubCategorysPageLoaded
    | SubCategorysPageCancelled
    | AllSubCategorysLoaded
    | AllSubCategorysRequested
    | SubCategoryOnServerCreated
    | SubCategoryDeletedFromAdminTrash
    | SubCategoryDeletedFromTrash
    | SubCategoryOnServerRestored
    | SubCategoryRestored
    | SubCategoryOnServerAdminRestored
    | SubCategoryAdminRestored
    | SubCategoryTrashFlushed
    | SubCategorysPageToggleLoading
    | SubCategoryActionToggleLoading;