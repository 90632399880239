<div class="card card-custom mb-4">
    <div class="card-header">
       <div class="card-title">
           <h3 class="card-label">Generating Document</h3>
       </div>
       <div class="card-toolbar">
           <button type="button" 
                class="close top-right" 
                data-dismiss="modal" 
                aria-label="Close"
                (click)="close()">
                <span aria-hidden="true">&times;</span>
           </button>
       </div>
       <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>