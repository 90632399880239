// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {LandTenure} from '../_models/land-tenure.model';

export enum LandTenureActionTypes {

    AllLandTenuresRequested = '[LandTenures Home Page] All LandTenures Requested',
    AllLandTenuresLoaded = '[LandTenures API] All LandTenures Loaded',

    LandTenureOnServerCreated = '[Edit LandTenure Dialog] LandTenure On Server Created',
    LandTenureCreated = '[Edit LandTenures Dialog] LandTenures Created',
    LandTenureUpdated = '[Edit LandTenure Dialog] LandTenure Updated',
    LandTenureDeleted = '[LandTenures List Page] LandTenure Deleted',

    LandTenuresPageRequested = '[LandTenures List Page] LandTenures Page Requested',
    LandTenuresPageLoaded = '[LandTenures API] LandTenures Page Loaded',
    LandTenuresPageCancelled = '[LandTenures API] LandTenures Page Cancelled',

    LandTenuresPageToggleLoading = '[LandTenures page] LandTenures Page Toggle Loading',
    LandTenuresActionToggleLoading = '[LandTenures] LandTenures Action Toggle Loading',

    // trash system

    LandTenureOnServerRestored = '[LandTenure Trash] LandTenure On Server Restored',
    LandTenureRestored = '[LandTenure Trash] LandTenure Restored',

    LandTenureOnServerAdminRestored = '[LandTenure Admin Trash] LandTenure On Server Restored',
    LandTenureAdminRestored = '[LandTenure Admin Trash] LandTenure Restored',

    LandTenureDeletedFromTrash = '[LandTenure Trash] LandTenure deleted',
    LandTenureDeletedFromAdminTrash = '[LandTenure Admin Trash] LandTenure deleted',

    LandTenureTrash = 'LandTenure Trashed',
    LandTenureTrashFlushed = 'LandTenure Trash Flushed',
}

export class LandTenureOnServerCreated implements Action {
    readonly type = LandTenureActionTypes.LandTenureOnServerCreated;

    constructor(public payload: { landTenure: LandTenure}) {
    }
}

export class LandTenureCreated implements Action {
    readonly type = LandTenureActionTypes.LandTenureCreated;

    constructor(public payload: { landTenure: LandTenure }) {
    }
}

export class LandTenureUpdated implements Action {
    readonly type = LandTenureActionTypes.LandTenureUpdated;

    constructor(public payload: {
        partialLandTenure: Update<LandTenure>,
        landTenure: LandTenure
    }) {
    }
}

export class LandTenureDeleted implements Action {
    readonly type = LandTenureActionTypes.LandTenureDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class LandTenuresPageRequested implements Action {
    readonly type = LandTenureActionTypes.LandTenuresPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class LandTenuresPageLoaded implements Action {
    readonly type = LandTenureActionTypes.LandTenuresPageLoaded;

    constructor(public payload: { landTenures: LandTenure[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class LandTenuresPageCancelled implements Action {
    readonly type = LandTenureActionTypes.LandTenuresPageCancelled;
}

export class AllLandTenuresRequested implements Action {
    readonly type = LandTenureActionTypes.AllLandTenuresRequested;
}

export class AllLandTenuresLoaded implements Action {
    readonly type = LandTenureActionTypes.AllLandTenuresLoaded;

    constructor(public payload: { landTenures: LandTenure[] }) {
    }
}

export class LandTenuresPageToggleLoading implements Action {
    readonly type = LandTenureActionTypes.LandTenuresPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class LandTenuresActionToggleLoading implements Action {
    readonly type = LandTenureActionTypes.LandTenuresActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class LandTenureDeletedFromAdminTrash implements Action {
    readonly type = LandTenureActionTypes.LandTenureDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class LandTenureDeletedFromTrash implements Action {
    readonly type = LandTenureActionTypes.LandTenureDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class LandTenureOnServerRestored implements Action {
    readonly type = LandTenureActionTypes.LandTenureOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class LandTenureRestored implements Action {
    readonly type = LandTenureActionTypes.LandTenureRestored;

    constructor(public payload: { item: LandTenure }) {
    }
}

export class LandTenureOnServerAdminRestored implements Action {
    readonly type = LandTenureActionTypes.LandTenureOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class LandTenureAdminRestored implements Action {
    readonly type = LandTenureActionTypes.LandTenureAdminRestored;

    constructor(public payload: { item: LandTenure }) {
    }
}

export class LandTenureTrashFlushed implements Action {
    readonly type = LandTenureActionTypes.LandTenureTrashFlushed;

    constructor() {
    }
}

export type LandTenureActions = LandTenureCreated
    | LandTenureUpdated
    | LandTenureDeleted
    | LandTenuresPageRequested
    | LandTenuresPageLoaded
    | LandTenuresPageCancelled
    | AllLandTenuresLoaded
    | AllLandTenuresRequested
    | LandTenureOnServerCreated
    | LandTenuresPageToggleLoading
    | LandTenuresActionToggleLoading
    | LandTenureDeletedFromAdminTrash
    | LandTenureDeletedFromTrash
    | LandTenureOnServerRestored
    | LandTenureRestored
    | LandTenureOnServerAdminRestored
    | LandTenureAdminRestored
    | LandTenureTrashFlushed;
