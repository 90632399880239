<div class="card card-custom">
	<div class="card-body-progress card-body-progress-overlay">
		<!-- here can place a progress bar-->
		<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
	</div>
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="form">
		<div class="card-body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{ viewLoading ? data.waitDesciption : data.description}}
				</div>
			</div>
		</div>
		<div class="card-footer b-0 p-0 text-right">
			<div class="form-actions form-actions-solid">
				<button mat-button (click)="onNoClick()">No</button>&nbsp;
				<button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="viewLoading">Yes</button>
			</div>
		</div>
	</div>
</div>
