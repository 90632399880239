import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { CriterionModel } from "../_models/criterion.model";
import { ValuationAdjustmentModel } from "../_models/valuation-adjustment.model";

export enum ValuationAdjustmentActionTypes {
    CreateValuationAdjustment = 'Create adjustment for valuation',
    ValuationAdjustmentCreated = 'Adjustment for valuation created',

    CreateValuationAdjustments = 'Create adjustments for valuation',
    ValuationAdjustmentsCreated = 'Adjustments for valuation created',

    RemoveValuationAdjustment = 'Remove adjustment for valuation',

    EditValuationAdjustment = 'Edit adjustment for valuation',
    ValuationAdjustmentEdited = 'Adjustment for valuation edited',

    EditValueOfValuationAdjustment = 'Edit value of valuation adjustment',
    ValueOfValuationAdjustmentEdited = 'Value of valuation adjustment edited',

    AddComparable = 'Add comparable data to valuation adjustment',
    ComparableAdded = 'Comparable data is added to valuation adjustment',
    RemoveComparable = 'Remove comparable data from valuation adjustment',
    ComparableRemoved = 'Comparabel data is removed from valuation adjustment',

    RestartValuationAdjustmentState = 'Restart state of valuation adjustment',
    AddMultipleComparables = 'Add comparables data to valuation adjustment',

    RemoveCriterionFromAdjustment = 'Remove criterion from adjustment',
    CriterionFromAdjustmentRemoved = 'Criterion from adjustment removed'
}

export class CreateValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.CreateValuationAdjustment;
    constructor(public payload: {
        valuationAdjustment: ValuationAdjustmentModel
    }) {}
}

export class ValuationAdjustmentCreated implements Action {
    readonly type = ValuationAdjustmentActionTypes.ValuationAdjustmentCreated;
    constructor(public payload: {
        valuationAdjustment: ValuationAdjustmentModel
    }) {}
}

export class CreateValuationAdjustments implements Action {
    readonly type = ValuationAdjustmentActionTypes.CreateValuationAdjustments;
    constructor(public payload: {
        models: ValuationAdjustmentModel[]
    }) {}
}

export class ValuationAdjustmentsCreated implements Action {
    readonly type = ValuationAdjustmentActionTypes.ValuationAdjustmentsCreated;
    constructor(public payload: {
        models: ValuationAdjustmentModel[],
        lastId: number
    }) {}
}

export class EditValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.EditValuationAdjustment;
    constructor (public payload: {
        valuationAdjustment: ValuationAdjustmentModel
    }) {}
}

export class ValuationAdjustmentEdited implements Action {
    readonly type = ValuationAdjustmentActionTypes.ValuationAdjustmentEdited;
    constructor (public payload: {
        valuationAdjustment: Update<ValuationAdjustmentModel>
    }) {}
}

export class EditValueOfValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.EditValueOfValuationAdjustment;
    constructor (public payload: {
        v: ValuationAdjustmentModel,
        comID: number,
        val: number
    }) {}
}

export class ValueOfValuationAdjustmentEdited implements Action {
    readonly type = ValuationAdjustmentActionTypes.ValueOfValuationAdjustmentEdited;
    constructor (public payload: {
        valuationAdjustment: Update<ValuationAdjustmentModel>
    }) {}
}

export class AddComparableValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.AddComparable;
    constructor(public payload: {com: any}) {}
}

export class ValuationAdjustmentComparableAdded implements Action {
    readonly type = ValuationAdjustmentActionTypes.ComparableAdded;
    constructor(public payload: {
        valuationAdjustments: ValuationAdjustmentModel[]
    }) {}
}

export class RemoveComparableValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.RemoveComparable;
    constructor(public payload: {
        comID: number
    }) {}
}

export class ValuationAdjustmentComparableRemoved implements Action {
    readonly type = ValuationAdjustmentActionTypes.ComparableRemoved;
    constructor(public payload: {valuationAdjustments: ValuationAdjustmentModel[]}) {}
}

export class RemoveValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.RemoveValuationAdjustment;
    constructor(public payload: {id: number}) {}
}

export class RestartValuationAdjustmentState implements Action {
    readonly type = ValuationAdjustmentActionTypes.RestartValuationAdjustmentState;
    constructor() {}
}

export class AddMultipleComparableValuationAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.AddMultipleComparables;
    constructor(public payload: {com: any[]}) {}
}

export class RemoveCriterionFromAdjustment implements Action {
    readonly type = ValuationAdjustmentActionTypes.RemoveCriterionFromAdjustment;
    constructor(public paylaod: {criterion: CriterionModel}) {}
}

export class CriterionFromAdjustmentRemoved implements Action {
    readonly type = ValuationAdjustmentActionTypes.CriterionFromAdjustmentRemoved;
    constructor(public payload: {adjustments: Update<CriterionModel>[]}) {}
}

export type ValuationAdjustmentActions = CreateValuationAdjustment
    | ValuationAdjustmentCreated
    | AddComparableValuationAdjustment
    | ValuationAdjustmentComparableAdded
    | RemoveComparableValuationAdjustment
    | ValuationAdjustmentComparableRemoved
    | EditValuationAdjustment
    | EditValueOfValuationAdjustment
    | ValuationAdjustmentEdited
    | ValueOfValuationAdjustmentEdited
    | RemoveValuationAdjustment
    | RestartValuationAdjustmentState
    | RemoveCriterionFromAdjustment
    | CriterionFromAdjustmentRemoved
    | CreateValuationAdjustments
    | ValuationAdjustmentsCreated;