import { ToeConflictActions, ToeConflictActionTypes } from "../_actions/toe-conflict.actions";
import { AgencyInvolvementWithClient, AgencyInvolvementWithTp, LvInvolvementWithClient, LvInvolvementWithTp, ToeConflictAgency, ToeConflictClient, ToeConflictLeadValuer } from "../_models/toe-conflicts-model";

export interface ToeConflictsState {
    lead_valuer: ToeConflictLeadValuer | null
    client: ToeConflictClient | null
    agency: ToeConflictAgency | null
    lv_involvement_with_clients: LvInvolvementWithClient[]
    agency_involvement_with_clients: AgencyInvolvementWithClient[],
    lv_involvement_with_tps: LvInvolvementWithTp[],
    agency_involvement_with_tps: AgencyInvolvementWithTp[]
}

export const initialState: ToeConflictsState = {
    lead_valuer: null,
    client: null,
    agency: null,
    lv_involvement_with_clients: [],
    agency_involvement_with_clients: [],
    lv_involvement_with_tps: [],
    agency_involvement_with_tps: []
}

export function toeConflictsReducer(
    state = initialState,
    action: ToeConflictActions
): ToeConflictsState {
    switch (action.type) {
        case ToeConflictActionTypes.ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded: {
            return {
                ...state,
                lead_valuer: action.payload.lead_valuer,
                client: action.payload.client,
                lv_involvement_with_clients: action.payload.involvements
            }
        }
        case ToeConflictActionTypes.ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded: {
            return {
                ...state,
                client: action.payload.client,
                agency: action.payload.agency,
                agency_involvement_with_clients: action.payload.involvements
            }
        }
        case ToeConflictActionTypes.ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded: {
            return {
                ...state,
                lead_valuer: action.payload.lead_valuer,
                lv_involvement_with_tps: action.payload.involvements
            }
        }
        case ToeConflictActionTypes.ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded: {
            return {
                ...state,
                agency: action.payload.agency,
                agency_involvement_with_tps: action.payload.involvements
            }
        }
        case ToeConflictActionTypes.ResetToeConflictsState: {
            return {...initialState}
        }
        default:
            return state
    }
}