import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { BuiltinFittingEditDialogComponent } from '../edit/builtin-fitting-edit-dialog.component';
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';
import { ComparablesRadioButtonValues } from "../../../../../core/comparable/_models/RadioButtonValues";
import { Subject } from 'rxjs';

@Component({
    selector: 'kt-builtin-fittings-list',
    templateUrl: './builtin-fittings-sub-list.component.html',
    styleUrls: ['./builtin-fittings-sub-list.component.scss']
})

export class BuiltinFittingsSubListComponent implements OnInit, OnDestroy, OnChanges {
    @Input() readonly: boolean;
    @Input() selectedBuiltinFittings: any[];
    @Input() propertySubTypeId = 1;
    // @Input() inValid: Subject<boolean>;
    currentBuiltinFittings: any[];
    dialogRef: any;
    radioButtonValues = ComparablesRadioButtonValues;

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param location
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param cdRef
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public location: Location,
                public typesUtilsService: TypesUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedBuiltinFittings) {
            this.currentBuiltinFittings = changes.selectedBuiltinFittings.currentValue;
        }
    }

    /**
     * On init
     */
    ngOnInit() {
        this.currentBuiltinFittings = this.selectedBuiltinFittings;
        // if (this.inValid) {
        //     this.inValid.next(false);
        // }
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    chooseBuiltinFittings() {
        this.dialogRef = this.dialog.open(BuiltinFittingEditDialogComponent, {
            data: {
                currentBuiltinFittings: this.currentBuiltinFittings,
                propertySubTypeId: this.propertySubTypeId,
                title: this.getPlaceholder(),
            }
        });
        this.dialogRef.afterClosed().subscribe(res => {
                // if (!res) {
                //     if (this.inValid) {
                //         this.inValid.next(true);
                //     }
                //     return;
                // }
                // if (res.data.length == 0) {
                //     if (this.inValid) {
                //         this.inValid.next(true);
                //     }
                // }
                if (res) {
                    this.currentBuiltinFittings = res.data;
                }
                // if (this.inValid) {
                //     this.inValid.next(false);
                // }
                this.cdRef.markForCheck();
                this.cdRef.detectChanges();
            }
        );
    }

    tagRemove($event) {
        const tempBuiltinFittings = [...this.currentBuiltinFittings];
        const index = tempBuiltinFittings.findIndex(element => element.facility_id === $event.facility_id);
        if (index !== -1) {
            tempBuiltinFittings.splice(index, 1);
        }
        this.currentBuiltinFittings = [...tempBuiltinFittings];
        // if (this.currentBuiltinFittings.length == 0) {
        //     if (this.inValid) {
        //         this.inValid.next(true);
        //     }
        // }
    }

    getPlaceholder() {
        return 'Built-in fittings';
    }
}
