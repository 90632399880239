import {Component, OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {InteractionTypeUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

import {
    InteractionType,
    InteractionTypeOnServerCreated,
    selectInteractionTypesActionLoading,
    selectInteractionTypeById,
    selectLastCreatedInteractionTypeId
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';

@Component({
    selector: 'kt-interaction-type-edit',
    templateUrl: './interaction-type-edit.component.html',
    styleUrls: ['./interaction-type-edit.component.scss'],
})


export class InteractionTypeEditComponent implements OnInit, OnDestroy {

    model: InteractionType;
    oldModel: InteractionType;

    interactionTypeForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    readonly = false
    /**
     * Component constructor
     *
     * @param subheaderService: SubheaderService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param store: Store<AppState>
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectInteractionTypesActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectInteractionTypeById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initInteractionType();
                    }
                });
            } else {
                this.model = new InteractionType();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initInteractionType();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Interaction types', page: `../default/admin-management/linked-tables/interaction-types`},
            {
                title: this.getComponentTitle(),
                page: id > 0 ? `../default/admin-management/linked-tables/interaction-types/edit/${id}`
                    : `../default/admin-management/linked-tables/interaction-types/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initInteractionType() {
        this.createForm();
    }


    createForm() {
        this.interactionTypeForm = this.fb.group({
            name: [this.model.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return this.translate.instant('INTERACTION_TYPE.FORM.TITLE.EDIT', {name: this.model.name});
        }
        return this.translate.instant('INTERACTION_TYPE.FORM.TITLE.NEW');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.interactionTypeForm.controls[controlName];
        return control.invalid && control.touched;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.interactionTypeForm.markAsPristine();
        this.interactionTypeForm.markAsUntouched();
        this.interactionTypeForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.interactionTypeForm.controls;
        /** check form */
        if (this.interactionTypeForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.model.id > 0) {
            this.updateInteractionType(this.model);
        } else {
            this.createInteractionType(this.model);
        }
    }

    /**
     * Update model
     *
     * @param _model: InteractionTypeModel
     */
    updateInteractionType(_model: InteractionType) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateInteractionType: Update<InteractionType> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new InteractionTypeUpdated({
            partialinteractionType: updateInteractionType,
            interactionType: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();
    }

    /**
     * Create model
     *
     * @param _model: InteractionTypeModel
     */
    createInteractionType(_model: InteractionType) {
        this.store.dispatch(new InteractionTypeOnServerCreated({interactionType: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedInteractionTypeId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }


    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }

}
