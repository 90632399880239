// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ValuationModel} from '../_models/valuation.model';
import {ValuationKeyplaceModel} from '../_models/valuation-keyplace.model';

export enum ValuationActionTypes {
    ValuationOnServerCreated = '[Edit Valuation Dialog] Valuation On Server Created',
    ValuationCreated = '[Edit Valuation Dialog] Valuation Created',

    ValuationOnServerUpdated = '[Edit Valuation Dialog] Valuation On Server Updated',
    ValuationUpdated = '[Edit Valuation Dialog] Valuation Updated',

    Valuations = '[Valuations List] get all ',
    ValuationsPageRequested = '[Valuations List Page] Valuations Page Requested',
    ValuationMapPageRequested = '[Valuations Map Page] Valuations Page Requested',
    ValuationsPageLoaded = '[Valuations API] Valuations Page Loaded',

    ValuationOnServerRestored = '[Valuation Trash] Valuation On Server Restored',
    ValuationRestored = '[Valuation Trash] Valuation Restored',
    ValuationDeletedFromTrash = '[Valuation Trash] Valuation deleted',
    ValuationTrash = 'Valuation Trashed',
    ValuationTrashFlushed = 'Valuation Trashed Flushed',
    OneValuationDeleted = '[Valuations List Page] One Valuation Deleted',
    ValuationValuationDeleted = '[Valuations Dashboard] Valuation Valuation Deleted',

    ValuationsPageCancelled = '[Valuations API] Valuations Page Cancelled',
    ValuationsPageToggleLoading = '[Valuations] Valuations Page Toggle Loading',
    ValuationActionToggleLoading = '[Valuations] Valuations Action Toggle Loading',
    ValuationGet = '[Valuation Object] Valuation Object Requested'
}

export class ValuationOnServerCreated implements Action {
    readonly type = ValuationActionTypes.ValuationOnServerCreated;

    constructor(public payload: {
        valuation: ValuationModel,
        keyPlaces: ValuationKeyplaceModel[],
    }) {
    }
}

export class ValuationOnServerUpdated implements Action {
    readonly type = ValuationActionTypes.ValuationOnServerUpdated;

    constructor(public payload: {
        partialValuation: Update<ValuationModel>,
        valuation: ValuationModel,
        keyPlaces: ValuationKeyplaceModel[],
    }) {
    }
}

export class ValuationGet implements Action {
    readonly type = ValuationActionTypes.Valuations;

    constructor(public payload: { valuationId: number }) {
    }
}

export class ValuationCreated implements Action {
    readonly type = ValuationActionTypes.ValuationCreated;

    constructor(public payload: { valuation: ValuationModel }) {
    }
}

export class ValuationUpdated implements Action {
    readonly type = ValuationActionTypes.ValuationUpdated;

    constructor(public payload: { valuation: ValuationModel }) {
    }
}

export class ValuationValuationDeleted implements Action {
    readonly type = ValuationActionTypes.ValuationValuationDeleted;

    constructor(public payload: {
        tpId: number,
        valId: number
    }) {
    }
}

export class ValuationsPageRequested implements Action {
    readonly type = ValuationActionTypes.ValuationsPageRequested;

    constructor(public payload: { page: QueryParamsModel, toeId: number }) {
    }
}

export class ValuationMapPageRequested implements Action {
    readonly type = ValuationActionTypes.ValuationMapPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ValuationsPageLoaded implements Action {
    readonly type = ValuationActionTypes.ValuationsPageLoaded;

    constructor(public payload: {
        valuations: ValuationModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class ValuationsPageCancelled implements Action {
    readonly type = ValuationActionTypes.ValuationsPageCancelled;
}

export class ValuationsPageToggleLoading implements Action {
    readonly type = ValuationActionTypes.ValuationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ValuationActionToggleLoading implements Action {
    readonly type = ValuationActionTypes.ValuationActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ValuationOnServerRestored implements Action {
    readonly type = ValuationActionTypes.ValuationOnServerRestored;

    constructor(public payload: { valuationId: number }) {
    }
}

export class ValuationRestored implements Action {
    readonly type = ValuationActionTypes.ValuationRestored;

    constructor(public payload: { valuation: ValuationModel }) {
    }
}

export class ValuationDeletedFromTrash implements Action {
    readonly type = ValuationActionTypes.ValuationDeletedFromTrash;

    constructor(public payload: { valuationId: number }) {
    }
}

export class ValuationsTrashRequested implements Action {
    readonly type = ValuationActionTypes.ValuationTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ValuationTrashFlushed implements Action {
    readonly type = ValuationActionTypes.ValuationTrashFlushed;

    constructor() {
    }
}

export class OneValuationDeleted implements Action {
    readonly type = ValuationActionTypes.OneValuationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export type ValuationActions = ValuationOnServerCreated
    | ValuationOnServerUpdated
    | ValuationCreated
    | ValuationUpdated
    | ValuationsPageRequested
    | ValuationMapPageRequested
    | ValuationsPageLoaded
    | ValuationsPageCancelled
    | ValuationsPageToggleLoading
    | ValuationActionToggleLoading
    | ValuationOnServerRestored
    | ValuationRestored
    | ValuationTrashFlushed
    | ValuationDeletedFromTrash
    | OneValuationDeleted
    | ValuationValuationDeleted
    | ValuationGet;
