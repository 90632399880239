import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppState } from '../../../../core/reducers';
import { CookieService } from 'ngx-cookie-service';
import { AssetClassDetailService } from '../../../../core/asset_class/_services/asset-class-detail.service';

@Injectable()
export class AssetClassDetailResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public assetClassDetailService: AssetClassDetailService,
                private cookieService: CookieService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const assetClassId = Number(route.params.asset_class_id);
        return this.assetClassDetailService.get(assetClassId);
    }
}
