import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AssetClassWarehouseActionToggleLoading, AssetClassWarehouseActionTypes, AssetClassWarehouseAdminRestored, AssetClassWarehouseCreated, AssetClassWarehouseDeleted, AssetClassWarehouseDeletedFromAdminTrash, AssetClassWarehouseDeletedFromTrash, AssetClassWarehouseDuplicate, AssetClassWarehouseOnServerAdminRestored, AssetClassWarehouseOnServerCreated, AssetClassWarehouseOnServerRestored, AssetClassWarehouseOnServerUpdated, AssetClassWarehousePageLoaded, AssetClassWarehousePageRequested, AssetClassWarehousePageToggleLoading, AssetClassWarehouseRestored, AssetClassWarehouseTrashFlushed, AssetClassWarehouseUpdated } from "../_actions/asset-class-warehouse.actions";
import { AssetClassWarehouseService } from "../_services/asset-class-warehouse.service";

@Injectable()
export class AssetClassWarehouseEffects {
    showPageLoadingDispatcher = new AssetClassWarehousePageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassWarehouseActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassWarehouseActionToggleLoading({isLoading: false});

    constructor(
        private actions$: Actions,
        private service: AssetClassWarehouseService,
        private store: Store<AppState>
    ) {}

    @Effect()
    loadAssetClassWarehousePage$ = this.actions$.pipe(
        ofType<AssetClassWarehousePageRequested>(AssetClassWarehouseActionTypes.AssetClassWarehousePageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery = response[1];
            return new AssetClassWarehousePageLoaded({
                AssetClassWarehouses: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            })
        })
    )

    @Effect()
    createAssetClassWarehouse$ = this.actions$.pipe(
        ofType<AssetClassWarehouseOnServerCreated>(AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                payload.AssetClassWarehouse,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassWarehouseCreated({AssetClassWarehouse: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    updateAssetClassWarehouse$ = this.actions$.pipe(
        ofType<AssetClassWarehouseOnServerUpdated>(AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateAssetClass(
                payload.AssetClassWarehouse,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassWarehouseUpdated(
                        {AssetClassWarehouse: res.data}
                    ))
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassWarehouse$ = this.actions$.pipe(
        ofType<AssetClassWarehouseDeleted>(AssetClassWarehouseActionTypes.AssetClassWarehouseDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteAssetClass(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteAssetClassWarehouseFromTrash$ = this.actions$.pipe(
        ofType<AssetClassWarehouseDeletedFromTrash>(AssetClassWarehouseActionTypes.AssetClassWarehouseDeletedFromTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAssetClassWarehouse$ = this.actions$.pipe(
        ofType<AssetClassWarehouseOnServerRestored>(AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassWarehouseRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAdminAssetClassWarehouse$ = this.actions$.pipe(
        ofType<AssetClassWarehouseOnServerAdminRestored>(AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerAdminRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassWarehouseAdminRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassWarehouseFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassWarehouseDeletedFromAdminTrash>(AssetClassWarehouseActionTypes.AssetClassWarehouseDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTrash$ = this.actions$
        .pipe(
            ofType<AssetClassWarehouseTrashFlushed>(AssetClassWarehouseActionTypes.AssetClassWarehouseTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    duplicateWarehouse$ = this.actions$.pipe(
        ofType<AssetClassWarehouseDuplicate>(AssetClassWarehouseActionTypes.DuplicateAssetClass),
        mergeMap(({payload}) => {
            return this.service.duplicate(payload.id)
        }),
        map((response) => {
            return new AssetClassWarehouseCreated({AssetClassWarehouse: response.data})
        })
    )
}