<div class="card card-custom" 
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
            <mat-icon
                    class="pl-2 pt-1 icon-gray cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    placement="bottom"
                    [popoverTitle]="'TOOLTIP.CURRENCY.FORM.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.CURRENCY.FORM.DESCRIPTION' | translate"
                    popoverClass="testUI">help
            </mat-icon>
        </div>
    </div>
    <div>
        <form class="form" [formGroup]="currencyForm">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ 'GENERAL.MESSAGE.FORM_WARNING' | translate }}
                </kt-alert>

                <div class="form-group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'CURRENCY.FORM.FIELD.GENERAL.CURRENCY.PLACEHOLDER' | translate"
                                        formControlName="currency">
                                <mat-option *ngFor="let curr of currencyList" value="{{ curr.key }}">{{curr.value}}</mat-option>
                            </mat-select>

                            <mat-error
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CURRENCY.FORM.FIELD.GENERAL.CURRENCY.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 border-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="false" cdkFocusInitial 
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.CANCEL.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.CANCEL.LABEL' | translate }}
                            </button>&nbsp;
                            <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.SAVE.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.SAVE.LABEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>
