import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AssetClassRetailShopActionToggleLoading, AssetClassRetailShopTrashFlushed, AssetClassRetailShopActionTypes, AssetClassRetailShopAdminRestored, AssetClassRetailShopCreated, AssetClassRetailShopDeleted, AssetClassRetailShopDeletedFromAdminTrash, AssetClassRetailShopDeletedFromTrash, AssetClassRetailShopOnServerAdminRestored, AssetClassRetailShopOnServerCreated, AssetClassRetailShopOnServerRestored, AssetClassRetailShopOnServerUpdated, AssetClassRetailShopPageLoaded, AssetClassRetailShopPageRequested, AssetClassRetailShopPageToggleLoading, AssetClassRetailShopRestored, AssetClassRetailShopUpdated, AssetClassRetailShopDuplicate } from "../_actions/asset-class-retail-shop.actions";
import { AssetClassRetailShopService } from "../_services/asset-class-retail-shop.service";

@Injectable()
export class AssetClassRetailShopEffects {
    showPageLoadingDispatcher = new AssetClassRetailShopPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassRetailShopActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassRetailShopActionToggleLoading({isLoading: false});

    constructor(
        private actions$: Actions,
        private service: AssetClassRetailShopService,
        private store: Store<AppState>
    ) {}

    @Effect()
    loadAssetClassRetailShopPage$ = this.actions$.pipe(
        ofType<AssetClassRetailShopPageRequested>(AssetClassRetailShopActionTypes.AssetClassRetailShopPageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery = response[1];
            return new AssetClassRetailShopPageLoaded({
                AssetClassRetailShops: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            })
        })
    )

    @Effect()
    createAssetClassRetailShop$ = this.actions$.pipe(
        ofType<AssetClassRetailShopOnServerCreated>(AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                payload.AssetClassRetailShop,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailShopCreated({AssetClassRetailShop: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    updateAssetClassRetailShop$ = this.actions$.pipe(
        ofType<AssetClassRetailShopOnServerUpdated>(AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateAssetClass(
                payload.AssetClassRetailShop,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailShopUpdated(
                        {AssetClassRetailShop: res.data}
                    ))
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassRetailShop$ = this.actions$.pipe(
        ofType<AssetClassRetailShopDeleted>(AssetClassRetailShopActionTypes.AssetClassRetailShopDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteAssetClass(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteAssetClassRetailShopFromTrash$ = this.actions$.pipe(
        ofType<AssetClassRetailShopDeletedFromTrash>(AssetClassRetailShopActionTypes.AssetClassRetailShopDeletedFromTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassRetailShopFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassRetailShopDeletedFromAdminTrash>(AssetClassRetailShopActionTypes.AssetClassRetailShopDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassRetailShop$ = this.actions$.pipe(
        ofType<AssetClassRetailShopOnServerRestored>(AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailShopRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAdminAssetClassRetailShop$ = this.actions$.pipe(
        ofType<AssetClassRetailShopOnServerAdminRestored>(AssetClassRetailShopActionTypes.AssetClassRetailShopOnServerAdminRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassRetailShopAdminRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    flushTrash$ = this.actions$
        .pipe(
            ofType<AssetClassRetailShopTrashFlushed>(AssetClassRetailShopActionTypes.AssetClassRetailShopTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    duplicateRetail$ = this.actions$.pipe(
        ofType<AssetClassRetailShopDuplicate>(AssetClassRetailShopActionTypes.DuplicateAssetClass),
        mergeMap(({payload}) => {
            return this.service.duplicate(payload.id)
        }),
        map((response) => {
            return new AssetClassRetailShopCreated({AssetClassRetailShop: response.data})
        })
    )
}