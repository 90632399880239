<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'cart-head-lg'" [viewLoading$]="dataSource.loading$">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
                <span>{{ 'ROOF_COVERING.LIST.TITLE' | translate }}</span>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <a routerLink="../"
               class="btn  mr-2"
               mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                back_page: ('LT.TITLE' | translate)
            }">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
            </a>

            <button (click)="addItem()" mat-raised-button 
                [matTooltip]="'ROOF_COVERING.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary"
                    type="button">
                <span>{{ 'GENERAL.BUTTON.NEW' | translate }}</span>
            </button>

            <button *ngIf="trashCnt != 0" (click)="trash()" mat-raised-button 
                [matTooltip]="'ROOF_COVERING.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button *ngIf="adminTrashCnt != 0 && (canAccessAdminTrash$|async)" (click)="adminTrash()" mat-raised-button 
                [matTooltip]="'ROOF_COVERING.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form">
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center active">

                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">

                    </mat-form-field>
                    <div class="col-md-1">
                        <button (click)="resetFilter()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>

                    <mat-form-field class=" col-md-3 mat-form-field-fluid">
                        <mat-select [formControl]="dropdownCtrl">
                            <mat-option *ngFor="let item of propertySubTypes" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <!-- end::FILTERS -->

            <!-- start::GROUP ACTIONS -->
            <!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
            <div class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
                 [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="kt-form__group kt-form__group--inline">
                        <div class="kt-form__label kt-form__label-no-wrap">
                            <label class="kt--font-bold kt-font-danger-">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
                                {{ selection.selected.length }}
                            </label>
                            <!-- selectedCountsTitle => function from codeBehind (client-list.component.ts file) -->
                            <!-- selectedCountsTitle => just returns title of selected items count -->
                            <!-- for example: Selected records count: 4 -->
                        </div>
                        <div class="kt-form__control kt-form__group--inline">

                            <!--<button (click)="fetchClients()" mat-raised-button matTooltip="Fetch selected clients" class="mat-button-mt-4">-->
                            <!--<mat-icon>clear_all</mat-icon>-->
                            <!--Fetch Selected-->
                            <!--</button>&nbsp;&lt;!&ndash; Call 'fetch-entity-dialog' from _shared folder &ndash;&gt;-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped" #table [dataSource]="dataSource" matSort #sort1="matSort"
                       matSortActive="id" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROOF_COVERING.LIST.DATATABLE.NAME' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="property-sub-type">
                    <mat-header-cell *matHeaderCellDef>{{ 'ROOF_COVERING.LIST.DATATABLE.PROPERTY_SUB_TYPE' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.property_sub_type_name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="is_hidden">
                    <mat-header-cell *matHeaderCellDef>
                        Hidden
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <mat-checkbox (change)="onHiddenChange($event, item)" [checked]="item.is_hidden"></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ 'ROOF_COVERING.LIST.DATATABLE.ACTIONS' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <ng-container *ngIf="!item.is_system_entry">
                            <button mat-icon-button color="primary" 
                                [matTooltip]="'ROOF_COVERING.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editItem(item)">
                                <mat-icon>create</mat-icon>
                            </button>&nbsp;

                            <span [matTooltip]="item.relation_cnt == 0 
                                ? ('ROOF_COVERING.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate)
                                : ('ROOF_COVERING.LIST.BUTTON.DELETE.TOOLTIP.CANNOT' | translate)">
                                <button mat-icon-button color="warn"
                                        type="button"
                                        [disabled]="item.relation_cnt != 0"
                                        (click)="deleteItem(item)">
                                    <mat-icon>delete</mat-icon>
                                </button>&nbsp;
                            </span>
                        </ng-container>
                        <ng-container *ngIf="item.is_system_entry">
                            <button mat-icon-button color="primary" 
                                [matTooltip]="'View'" (click)="viewEntry(item)">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>&nbsp;
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
                           [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>

