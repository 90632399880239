import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { GenerateInformedConsentReport, InformedConsentReportActionTypes, InformedConsentReportGenerated } from "../_actions/informed-consent-report.actions";
import { InformedConsentReportService } from "../_services/informed-consent-report.service";

@Injectable()
export class InformedConsentReportEffects {
	constructor(private actions$: Actions, private service: InformedConsentReportService) {}

	@Effect()
	generateReport$ = this.actions$.pipe(
		ofType<GenerateInformedConsentReport>(InformedConsentReportActionTypes.GenerateReport),
		mergeMap(({payload}) => {
			return this.service.generateReport(payload.toeID, payload.reportID);
		}),
		map((res) => {
			return new InformedConsentReportGenerated({success: res.success});
		})
	)
}