import {BaseModel} from '../../_base/crud';

export class AuditTrailModel extends BaseModel {
    id: number;
    ref_num: string;
    assignment_id: number;
    assignment_name: string;
    interaction_date: string;
    client_id: number;
    client_name: string;
    interaction_type_id: number;
    interaction_type_name: string;
    interaction_description: string;
    impact_on_valuation: string;
    mitigation_strategy: string;
    mitigation_strategy1: string;
    location: string;
    participants_side: string;
    participants_names: string;
    participants_names_client: string;
    participants_names_external: string;
    participants_names_external_desc: string;
    user_id: number;
    user_name: string;
    picture: string;
    files: string;
    audit_file: any[];
    terms_of_engagement_id: number;
    terms_of_engagement_name: string;
    asset_class_id: number;
    asset_class_name: string;
    created_at: Date;
    deleted_at: Date;
    updated_at: Date;
    user_deleted: Date;
    createdBy: string;
    deleted_user_name: string;
    trash_delete_descr: string;
    notes: string;

    clear(): void {
        this.id = undefined;
        this.assignment_id = undefined;
        this.assignment_name = '';
        this.interaction_date = '';
        this.client_id = undefined;
        this.client_name = '';
        this.interaction_type_id = undefined;
        this.interaction_type_name = '';
        this.participants_names_client = '';
        this.participants_names_external = '';
        this.participants_names_external_desc = '';
        this.interaction_description = '';
        this.impact_on_valuation = '';
        this.mitigation_strategy = '';
        this.mitigation_strategy1 = '';
        this.location = '';
        this.participants_side = '';
        this.participants_names = '';
        this.user_id = undefined;
        this.user_name = '';

        this.picture = '';
        this.files = '';
        this.audit_file = [];
        this.terms_of_engagement_id = 0;
        this.terms_of_engagement_name = '';
        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
        this.deleted_user_name = '';
        this.trash_delete_descr = '';
        this.user_deleted = new Date();
        this.notes = null;
    }
}
