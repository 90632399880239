// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {SpecialAssumption} from '../_models/special-assumption.model';

export enum SpecialAssumptionActionTypes {

    AllSpecialAssumptionsRequested = '[SpecialAssumptions Home Page] All SpecialAssumptions Requested',
    AllSpecialAssumptionsLoaded = '[SpecialAssumptions API] All SpecialAssumptions Loaded',

    SpecialAssumptionOnServerCreated = '[Edit SpecialAssumption Dialog] SpecialAssumption On Server Created',
    SpecialAssumptionCreated = '[Edit SpecialAssumptions Dialog] SpecialAssumptions Created',
    SpecialAssumptionUpdated = '[Edit SpecialAssumption Dialog] SpecialAssumption Updated',
    SpecialAssumptionDeleted = '[SpecialAssumptions List Page] SpecialAssumption Deleted',

    // trash system

    SpecialAssumptionOnServerRestored = '[SpecialAssumption Trash] SpecialAssumption On Server Restored',
    SpecialAssumptionRestored = '[SpecialAssumption Trash] SpecialAssumption Restored',

    SpecialAssumptionOnServerAdminRestored = '[SpecialAssumption Admin Trash] SpecialAssumption On Server Restored',
    SpecialAssumptionAdminRestored = '[SpecialAssumption Admin Trash] SpecialAssumption Restored',

    SpecialAssumptionDeletedFromTrash = '[SpecialAssumption Trash] SpecialAssumption deleted',
    SpecialAssumptionDeletedFromAdminTrash = '[SpecialAssumption Admin Trash] SpecialAssumption deleted',

    SpecialAssumptionTrash = 'SpecialAssumption Trashed',

    SpecialAssumptionTrashFlushed = 'SpecialAssumption Trash Flushed',

    SpecialAssumptionsPageRequested = '[SpecialAssumptions List Page] SpecialAssumptions Page Requested',
    SpecialAssumptionsPageLoaded = '[SpecialAssumptions API] SpecialAssumptions Page Loaded',
    SpecialAssumptionsPageCancelled = '[SpecialAssumptions API] SpecialAssumptions Page Cancelled',

    SpecialAssumptionsPageToggleLoading = '[SpecialAssumptions page] SpecialAssumptions Page Toggle Loading',
    SpecialAssumptionsActionToggleLoading = '[SpecialAssumptions] SpecialAssumptions Action Toggle Loading'
}

export class SpecialAssumptionOnServerCreated implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionOnServerCreated;

    constructor(public payload: { specialAssumption: SpecialAssumption }) {
    }
}

export class SpecialAssumptionCreated implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionCreated;

    constructor(public payload: { specialAssumption: SpecialAssumption }) {
    }
}

export class SpecialAssumptionUpdated implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionUpdated;

    constructor(public payload: {
        partialItem: Update<SpecialAssumption>,
        item: SpecialAssumption
    }) {
    }
}

export class SpecialAssumptionDeleted implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SpecialAssumptionsPageRequested implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SpecialAssumptionsPageLoaded implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionsPageLoaded;

    constructor(public payload: {
        specialAssumptions: SpecialAssumption[],
        totalTrashed: number,
        totalAdminTrashed: number,
        totalCount: number,
        page: QueryParamsModel
    }) {
    }
}

export class SpecialAssumptionsPageCancelled implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionsPageCancelled;
}

export class AllSpecialAssumptionsRequested implements Action {
    readonly type = SpecialAssumptionActionTypes.AllSpecialAssumptionsRequested;
}

export class AllSpecialAssumptionsLoaded implements Action {
    readonly type = SpecialAssumptionActionTypes.AllSpecialAssumptionsLoaded;

    constructor(public payload: { specialAssumptions: SpecialAssumption[] }) {
    }
}

export class SpecialAssumptionsPageToggleLoading implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SpecialAssumptionsActionToggleLoading implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class SpecialAssumptionDeletedFromAdminTrash implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class SpecialAssumptionDeletedFromTrash implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SpecialAssumptionOnServerRestored implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SpecialAssumptionRestored implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionRestored;

    constructor(public payload: { item: SpecialAssumption }) {
    }
}

export class SpecialAssumptionOnServerAdminRestored implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SpecialAssumptionAdminRestored implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionAdminRestored;

    constructor(public payload: { item: SpecialAssumption }) {
    }
}

export class SpecialAssumptionTrashFlushed implements Action {
    readonly type = SpecialAssumptionActionTypes.SpecialAssumptionTrashFlushed;

    constructor() {
    }
}

export type SpecialAssumptionActions = SpecialAssumptionCreated
    | SpecialAssumptionUpdated
    | SpecialAssumptionDeleted
    | SpecialAssumptionsPageRequested
    | SpecialAssumptionsPageLoaded
    | SpecialAssumptionsPageCancelled
    | AllSpecialAssumptionsLoaded
    | AllSpecialAssumptionsRequested
    | SpecialAssumptionOnServerCreated
    | SpecialAssumptionsPageToggleLoading
    | SpecialAssumptionDeletedFromAdminTrash
    | SpecialAssumptionDeletedFromTrash
    | SpecialAssumptionOnServerRestored
    | SpecialAssumptionRestored
    | SpecialAssumptionOnServerAdminRestored
    | SpecialAssumptionAdminRestored
    | SpecialAssumptionTrashFlushed
    | SpecialAssumptionsActionToggleLoading;
