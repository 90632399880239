// NGRX
import {Action} from '@ngrx/store';
// Models
import {CityModel} from '../_models/city.model';
import {Update} from '@ngrx/entity';

export enum CityActionTypes {
    AllCitiesRequested = '[Init] All Cities Requested',
    AllCitiesLoaded = '[Init] All Cities Loaded',

    CityOnServerCreated = '[Edit City Dialog] City On Server Created',
    CityCreated = '[Edit City Dialog] Citsy Created',
    CityUpdatedOnServer = '[Edit City Dialog] City Updated On server',
    CityUpdated = '[Edit City Dialog] City Updated',

    CitiesActionToggleLoading = '[Cities] Cities Action Toggle Loading',

    CityDeleted = '[Cities List Page] City Deleted',
    CityTrashFlushed = 'City Trash Flushed',
}

export class AllCitiesRequested implements Action {
    readonly type = CityActionTypes.AllCitiesRequested;
}

export class AllCitiesLoaded implements Action {
    readonly type = CityActionTypes.AllCitiesLoaded;

    constructor(public payload: { cities: CityModel[] }) {
    }
}

export class CityTrashFlushed implements Action {
    readonly type = CityActionTypes.CityTrashFlushed;

    constructor(public payload: { countryId: number, cityIds: number[], cities: Update<CityModel>[] }) {
    }
}


export class CityOnServerCreated implements Action {
    readonly type = CityActionTypes.CityOnServerCreated;

    constructor(public payload: { city: CityModel }) {
    }
}

export class CityCreated implements Action {
    readonly type = CityActionTypes.CityCreated;

    constructor(public payload: { city: CityModel }) {
    }
}

export class CityUpdatedOnServer implements Action {
    readonly type = CityActionTypes.CityUpdatedOnServer;

    constructor(public payload: {
        city: CityModel
    }) {
    }
}

export class CityUpdated implements Action {
    readonly type = CityActionTypes.CityUpdated;

    constructor(public payload: {
        partialCity: Update<CityModel>
    }) {
    }
}

export class CityDeleted implements Action {
    readonly type = CityActionTypes.CityDeleted;

    constructor(public payload: { id: number }) {
    }
}


export class CitiesActionToggleLoading implements Action {
    readonly type = CityActionTypes.CitiesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export type CityActions = AllCitiesRequested |
    AllCitiesLoaded |
    CitiesActionToggleLoading |
    CityOnServerCreated |
    CityCreated |
    CityUpdated |
    CityUpdatedOnServer |
    CityTrashFlushed |
    CityDeleted;
