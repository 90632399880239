import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource } from "../../_base/crud";
import { selectAssetClassWarehouseInStore, selectAssetClassWarehousePageLoading, selectAssetClassWarehouseShowInitWaitingMessage } from "../_selectors/asset-class-warehouse.selectors";
import { tenureAdder } from "./tenure_extension";

export class AssetClassWarehouseDataSource extends BaseDataSource {
    trashed = 0;

    constructor(
        private store: Store<AppState>
    ) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssetClassWarehousePageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssetClassWarehouseShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssetClassWarehouseInStore)
        ).subscribe(response => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.trashed = response.trashed;
            this.entitySubject.next(response.items.map(e => tenureAdder(e)));
        })
    }
}