import {BaseModel} from '../../_base/crud';

export class ResidentialExternalAreaModel extends BaseModel {
    id: number;
    linked_id: number;
    area_id: number;
    name: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.linked_id = undefined;
        this.area_id = undefined;
        this.name = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}