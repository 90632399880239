import {HandoverStandard} from '../_models/handover-standard.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {HandoverStandardsState} from '../_reducers/handover-standard.reducers';
import * as fromHandoverStandard from '../_reducers/handover-standard.reducers';
import {each} from 'lodash';

export const selectHandoverStandardsState = createFeatureSelector<HandoverStandardsState>('handoverStandards');

export const selectHandoverStandardById = (handoverStandardId: number) => createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => handoverStandardsState.entities[handoverStandardId]
);

export const selectAllHandoverStandards = createSelector(
    selectHandoverStandardsState,
    fromHandoverStandard.selectAll
);

export const selectAllHandoverStandardsIds = createSelector(
    selectHandoverStandardsState,
    fromHandoverStandard.selectIds
);

export const allHandoverStandardsLoaded = createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => handoverStandardsState.isAllHandoverStandardsLoaded
);


export const selectHandoverStandardsPageLoading = createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => handoverStandardsState.listLoading
);

export const selectHandoverStandardsActionLoading = createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => handoverStandardsState.actionLoading
);

export const selectLastCreatedHandoverStandardId = createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => handoverStandardsState.lastCreatedHandoverStandardId
);

export const selectHandoverStandardsShowInitWaitingMessage = createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => handoverStandardsState.showInitWaitingMessage
);

export const selectTrashedHandoverStandardCount = createSelector(
    selectHandoverStandardsState,
    (handoverStandardsState) => handoverStandardsState.totalTrashed
);

export const selectAdminTrashedHandoverStandardCount = createSelector(
    selectHandoverStandardsState,
    (handoverStandardsState) => handoverStandardsState.totalAdminTrashed
);


export const selectHandoverStandardQueryResult = createSelector(
    selectHandoverStandardsState,
    handoverStandardsState => {
        const items: HandoverStandard[] = [];
        each(handoverStandardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HandoverStandard[] = httpExtension.sortArray(items, handoverStandardsState.lastQuery.sortField, handoverStandardsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, handoverStandardsState.totalCount, '', handoverStandardsState.totalTrashed);
    }
);
