import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TpFinancialTaskModel} from '../_models/tp-financial-task.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class TpFinancialTaskService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/tp-tasks`;
    constructor(private httpClient: HttpClient, private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    getAll(): Observable<any> {
        return this.http.get<any>(this.API_URL, {headers: this.authService.getAuthHeaders()});
    }

    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(itemId: number): Observable<TpFinancialTaskModel> {
        return this.http.get<TpFinancialTaskModel>(this.API_URL + `/${itemId}`, {headers: this.authService.getAuthHeaders()});
    }

    getByUserId(queryParams: QueryParamsModel, state: number, filterModel: any = null): Observable<TpFinancialTaskModel> {
        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
            filterModel: filterModel,
        };
        return this.http.post<TpFinancialTaskModel>(this.API_URL + `/financials/${state}`, _body, {headers: this.authService.getAuthHeaders()});
    }

    export(userId: number, filterModel: any = null): Observable<any> {
        
        const _body = {
            filterModel: filterModel,
        };
        const req = new HttpRequest(
            'POST',
            this.API_URL + `/export/${userId}`,
            _body,
            {
                responseType: 'blob',
                headers: this.authService.getAuthHeadersForUpload()
            }
          );
        return this.httpClient.request(req);
        // return this.http.post<any>(this.API_URL + `/export/${userId}`, _body, {headers: this.authService.getAuthHeaders()});
    }

    // CREATE =>  POST: add a new _item to the server
    create(item: TpFinancialTaskModel): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {_item: item};

        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the _item on the server
    update(_item: TpFinancialTaskModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_URL + `/${_item.id}`, _item, {headers: httpHeaders});
    }

    // DELETE => delete the _item from the server
    delete(itemId: number): Observable<any> {
        const url = `${this.API_URL}/${itemId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {headers: this.authService.getAuthHeaders()});
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: this.authService.getAuthHeaders()});
    }

    deleteFromTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    // TRASH (Financial)
    getFinancialTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    // delete from admin trash
    deleteFromFinancialTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
