<div class="card card-custom"
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
    <div class="card-header" *ngIf="client">
        <div class="card-title">
            <h3 class="card-label">
                New Client
            </h3>
            <mat-icon
                    class="pl-2 pt-1 icon-gray cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    placement="bottom"
                    [popoverTitle]="'TOOLTIP.CLIENT.FORM'+(client && client.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.CLIENT.FORM'+(client && client.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
                    popoverClass="testUI">help
            </mat-icon>
        </div>
    </div>
    <div>
        <form class="form" [formGroup]="clientForm">
            <div class="card-body">
                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    {{ hasFormErrorsDesc}}
                </kt-alert>

                <div class="form-group kt-form-group row">
                    <div class="col-md-12 kt-margin-bottom-10-mobile">
                        <mat-radio-group formControlName="type" class="flex-row-radio-group" [(ngModel)]="client.type">
                            <mat-radio-button class="example-radio-button" value="Company">
                                {{ 'CLIENT.FORM.FIELD.GENERAL.CLIENT_TYPE.VALUE.COMPANY' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button" value="Individual">
                                {{ 'CLIENT.FORM.FIELD.GENERAL.CLIENT_TYPE.VALUE.INDIVIDUAL' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="form-group kt-form-group row" *ngIf="client.type === 'Company'">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CLIENT.FORM.FIELD.GENERAL.CLIENT_NAME.PLACEHOLDER' | translate" formControlName="name"
                                   [(ngModel)]="client.name"
                            />
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CLIENT.FORM.FIELD.GENERAL.CLIENT_NAME.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group kt-form-group row" *ngIf="client.type === 'Individual'">
                    <div class="col-lg-6 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CLIENT.FORM.FIELD.GENERAL.FIRST_NAME.PLACEHOLDER' | translate" formControlName="name"
                                   [(ngModel)]="client.name"
                            />
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CLIENT.FORM.FIELD.GENERAL.FIRST_NAME.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CLIENT.FORM.FIELD.GENERAL.LAST_NAME.PLACEHOLDER' | translate" formControlName="last_name"
                                   [(ngModel)]="client.last_name"
                            />
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CLIENT.FORM.FIELD.GENERAL.LAST_NAME.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   type="tel"
                                   [placeholder]="'CLIENT.FORM.FIELD.GENERAL.PHONE.PLACEHOLDER' | translate"
                                   [(ngModel)]="client.phone"
                                   formControlName="phone"/>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                       field: ('CLIENT.FORM.FIELD.GENERAL.PHONE.NAME' | translate)
                                   }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   type="email"
                                   [placeholder]="'CLIENT.FORM.FIELD.GENERAL.EMAIL.PLACEHOLDER' | translate"
                                   [(ngModel)]="client.email"
                                   formControlName="email"/>
                            <mat-error *ngFor="let validation of validationMessages.email">
                                <mat-error class="error-message" *ngIf="clientForm.get('email').hasError(validation.type) && (clientForm.get('email').dirty || clientForm.get('email').touched)"
                                           [innerHtml]="'GENERAL.VALIDATION.CUSTOM_ERROR' | translate:{
                                        message: validation.message,
                                        decoration: validation.decorated
                                    }"
                                >
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group kt-form-group row">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'CLIENT.FORM.FIELD.GENERAL.COUNTRY.PLACEHOLDER' | translate" formControlName="country_id" [(ngModel)]="client.country_id"
                                        (selectionChange)="selectCountry()">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="countryFilterCtrl"
                                                           [placeholderLabel]="'GENERAL.PLACEHOLDER.SEARCH' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.MESSAGE.NOT_FOUND' | translate:{
                                                               entry: 'Country' 
                                                           } ">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option>
                                    <div (click)="addCountry()" class="add-entry">
                                        <i class="ki ki-plus"></i> New country
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</mat-option>
                            </mat-select>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CLIENT.FORM.FIELD.GENERAL.COUNTRY.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [placeholder]="'CLIENT.FORM.FIELD.GENERAL.CITY.PLACEHOLDER' | translate" formControlName="city_id" [(ngModel)]="client.city_id">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="cityFilterCtrl"
                                                           [placeholderLabel]="'GENERAL.PLACEHOLDER.SEARCH' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.MESSAGE.NOT_FOUND' | translate:{
                                                               entry: 'City' 
                                                           } ">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngIf="clientForm.controls.country_id.value">
                                    <div (click)="addCity()" class="add-entry">
                                        <i class="ki ki-plus"></i> New city
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let city of cities" [value]="city.id">{{city.name}}</mat-option>
                                <!--<mat-option *ngIf="client.country_id && cityOfCountry.length == 0" disabled>please add a city under *COUNTRY NAME* under the Localisation section</mat-option>-->
                                <mat-option *ngIf="cities.length == 0" disabled>{{ 'GENERAL.MESSAGE.NO_CITY' | translate }}</mat-option>
                            </mat-select>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CLIENT.FORM.FIELD.GENERAL.CITY.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput [placeholder]="'CLIENT.FORM.FIELD.GENERAL.ZIPCODE.PLACEHOLDER' | translate" [(ngModel)]="client.zip_code" formControlName="zip_code"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput rows="2" [placeholder]="'CLIENT.FORM.FIELD.GENERAL.ADDRESS.PLACEHOLDER' | translate" formControlName="address"
                                      [(ngModel)]="client.address"
                            ></textarea>
                            <mat-error
                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                    field: ('CLIENT.FORM.FIELD.GENERAL.ADDRESS.NAME' | translate)
                                }"
                            >
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            <kt-contact-list
                    [(clientId)]="client.id"
                    [(clientName)]="client.name"
                    *ngIf="client.type == 'Company'"
            >
            </kt-contact-list>

            <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && client.type == 'Company' && this.contactComponent && this.contactComponent.contactData.length == 0"
                       [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                    field: ('CLIENT.FORM.FIELD.GENERAL.CONTACTS.NAME' | translate)
                }"
            >
            </mat-error>
            </div>
            <div class="card-footer p-0 border-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="false" cdkFocusInitial 
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.CANCEL.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.CANCEL.LABEL' | translate }}
                            </button>&nbsp;
                            <button type="button" [disabled]="!isFormValid()" mat-raised-button class="btn btn-success" color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                [matTooltip]="'GENERAL.DIALOG_BUTTON.SAVE.TOOLTIP' | translate">
                                {{ 'GENERAL.DIALOG_BUTTON.SAVE.LABEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>