import { Update } from '@ngrx/entity';
import {Action} from '@ngrx/store';
import { PropertyDetailReportModel } from '../_models/property-detail-report.model';

export enum PropertyDetailReportActionTypes {
    AllPropertyDetailReportsRequested = 'AllPropertyDetailReports Requested',
    AllPropertyDetailReportsLoaded = 'AllPropertyDetailReports Loaded',

    PropertyDetailReportOnServerCreated = 'PropertyDetailReport On Server Created',
    PropertyDetailReportOnServerDeleted = 'PropertyDetailReport On Server Delete',
    
    PropertyDetailReportRequested = 'PropertyDetailReport Requested',
    PropertyDetailReportLoaded = 'PropertyDetailReport Loaded',
 
    PropertyDetailReportCreated = 'PropertyDetailReport Created',
    PropertyDetailReportUpdated = 'PropertyDetailReport Updated',

    PropertyDetailReportActionToggleLoading = 'PropertyDetailReport Action Toggle Loading',

    PropertyDetailReportStateResetted = '[Toe dashboard] Reset property details report state'
}

export class AllPropertyDetailReportsRequested implements Action {
    readonly type = PropertyDetailReportActionTypes.AllPropertyDetailReportsRequested;
    constructor(public payload: {tpID: number}) {}
}

export class AllPropertyDetailReportsLoaded implements Action {
    readonly type = PropertyDetailReportActionTypes.AllPropertyDetailReportsLoaded;
    constructor(public payload: {
        reports: PropertyDetailReportModel[],
        tpId: number
    }) {}
}

export class PropertyDetailReportRequested implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportRequested;
    constructor(public payload: {id: number}) {}
}

export class PropertyDetailReportLoaded implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportLoaded;
    constructor(public payload: {report: PropertyDetailReportModel}) {}
}

export class PropertyDetailReportOnServerCreated implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportOnServerCreated;
    constructor(public payload: {report: PropertyDetailReportModel}) {}
}

export class PropertyDetailReportOnServerDeleted implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportOnServerDeleted;
    constructor(public payload: {id: number}) {}
}

export class PropertyDetailReportCreated implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportCreated;
    constructor(public payload: {report: any}) {}
}

export class PropertyDetailReportUpdated implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportUpdated;
    constructor(public payload: {
        partialReport: Update<PropertyDetailReportModel>,
        report: PropertyDetailReportModel
    }) {}
}

export class PropertyDetailReportActionToggleLoading implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportActionToggleLoading;
    constructor(public payload: {
        isLoading: boolean
    }) {}
}

export class PropertyDetailReportStateResetted implements Action {
    readonly type = PropertyDetailReportActionTypes.PropertyDetailReportStateResetted;
    constructor() {}
}

export type PropertyDetailReportActions = 
    AllPropertyDetailReportsRequested |
    AllPropertyDetailReportsLoaded |
    PropertyDetailReportRequested |
    PropertyDetailReportLoaded |
    PropertyDetailReportOnServerCreated |
    PropertyDetailReportOnServerDeleted |
    PropertyDetailReportCreated |
    PropertyDetailReportUpdated |
    PropertyDetailReportActionToggleLoading |
    PropertyDetailReportStateResetted;
