// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectBathroomFittingQueryResult, selectBathroomFittingsPageLoading, selectBathroomFittingsShowInitWaitingMessage} from '../_selectors/bathroom-fitting.selectors';

export class BathroomFittingsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectBathroomFittingsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectBathroomFittingsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectBathroomFittingQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
