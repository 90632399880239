// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TpFileActions, TpFileActionTypes} from '../_actions/tp-file.actions';
import {TpFileModel} from '../_models/tp-file.model';

// Models

export interface TpFileState extends EntityState<TpFileModel> {
    _isAllTpFileLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    lastCreatedItemId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TpFileModel> = createEntityAdapter<TpFileModel>();

export const initialTpFileState: TpFileState = adapter.getInitialState({
    _isAllTpFileLoaded: false,
    listLoading: false,
    actionsLoading: false,
    lastCreatedItemId: undefined,
    showInitWaitingMessage: true
});

export function tpFileReducer(state = initialTpFileState, action: TpFileActions): TpFileState {
    switch (action.type) {
        case TpFileActionTypes.AllTpFileRequested:
            return {
                ...state,
                _isAllTpFileLoaded: false
            };
        case TpFileActionTypes.AllTpFileLoaded:
            return adapter.addAll(action.payload.files, {
                ...state,
                _isAllTpFileLoaded: true
            });
        case TpFileActionTypes.TpFileDeleted:
            return adapter.removeOne(action.payload.id, state);

        case TpFileActionTypes.TpFileOnServerCreated:
            return {
                ...state
            };
        case TpFileActionTypes.TpFileCreated:
            return adapter.addOne(action.payload.tp_file, {
                ...state
            });

        case TpFileActionTypes.TpFileUpdated:
            return adapter.updateOne(action.payload.partialTpFile, state);

        case TpFileActionTypes.TpFileOnServerUpdated:
            return adapter.updateOne(action.payload.partialTpFile, state);


        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
