import {BaseModel} from '../../_base/crud';

export class AssetClassServiceinfraModel extends BaseModel {
    id: number;

    road_access: number;
    status_and_quality: string;
    access_points: string;
    road_length: string;
    owner_of_another_consent: string;
    facilities: string;
    specify_other_service: string;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;

        this.road_access = 0;
        this.status_and_quality = '';
        this.access_points = '';
        this.road_length = '';
        this.owner_of_another_consent = '';
        this.facilities = '';
        this.specify_other_service = '';

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
