// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {MeasurementMethodology} from '../_models/measurement-methodology.model';

export enum MeasurementMethodologyActionTypes {

    AllMeasurementMethodologiesRequested = '[MeasurementMethodologies Home Page] All MeasurementMethodologies Requested',
    AllMeasurementMethodologiesLoaded = '[MeasurementMethodologies API] All MeasurementMethodologies Loaded',

    MeasurementMethodologyOnServerCreated = '[Edit MeasurementMethodology Dialog] MeasurementMethodology On Server Created',
    MeasurementMethodologyCreated = '[Edit MeasurementMethodologies Dialog] MeasurementMethodologies Created',
    MeasurementMethodologyUpdated = '[Edit MeasurementMethodology Dialog] MeasurementMethodology Updated',
    MeasurementMethodologyDeleted = '[MeasurementMethodologies List Page] MeasurementMethodology Deleted',

    MeasurementMethodologiesPageRequested = '[MeasurementMethodologies List Page] MeasurementMethodologies Page Requested',
    MeasurementMethodologiesPageLoaded = '[MeasurementMethodologies API] MeasurementMethodologies Page Loaded',
    MeasurementMethodologiesPageCancelled = '[MeasurementMethodologies API] MeasurementMethodologies Page Cancelled',

    MeasurementMethodologiesPageToggleLoading = '[MeasurementMethodologies page] MeasurementMethodologies Page Toggle Loading',
    MeasurementMethodologiesActionToggleLoading = '[MeasurementMethodologies] MeasurementMethodologies Action Toggle Loading',

    // trash system

    MeasurementMethodologyOnServerRestored = '[MeasurementMethodology Trash] MeasurementMethodology On Server Restored',
    MeasurementMethodologyRestored = '[MeasurementMethodology Trash] MeasurementMethodology Restored',

    MeasurementMethodologyOnServerAdminRestored = '[MeasurementMethodology Admin Trash] MeasurementMethodology On Server Restored',
    MeasurementMethodologyAdminRestored = '[MeasurementMethodology Admin Trash] MeasurementMethodology Restored',

    MeasurementMethodologyDeletedFromTrash = '[MeasurementMethodology Trash] MeasurementMethodology deleted',
    MeasurementMethodologyDeletedFromAdminTrash = '[MeasurementMethodology Admin Trash] MeasurementMethodology deleted',

    MeasurementMethodologyTrash = 'MeasurementMethodology Trashed',
    MeasurementMethodologyTrashFlushed = 'MeasurementMethodology Trash Flushed',
}

export class MeasurementMethodologyOnServerCreated implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerCreated;

    constructor(public payload: { item: MeasurementMethodology}) {
    }
}

export class MeasurementMethodologyCreated implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyCreated;

    constructor(public payload: { item: MeasurementMethodology }) {
    }
}

export class MeasurementMethodologyUpdated implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyUpdated;

    constructor(public payload: {
        partialItem: Update<MeasurementMethodology>,
        item: MeasurementMethodology
    }) {
    }
}

export class MeasurementMethodologyDeleted implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class MeasurementMethodologiesPageRequested implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologiesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class MeasurementMethodologiesPageLoaded implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologiesPageLoaded;

    constructor(public payload: { measurementMethodologies: MeasurementMethodology[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class MeasurementMethodologiesPageCancelled implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologiesPageCancelled;
}

export class AllMeasurementMethodologiesRequested implements Action {
    readonly type = MeasurementMethodologyActionTypes.AllMeasurementMethodologiesRequested;
}

export class AllMeasurementMethodologiesLoaded implements Action {
    readonly type = MeasurementMethodologyActionTypes.AllMeasurementMethodologiesLoaded;

    constructor(public payload: { measurementMethodologies: MeasurementMethodology[] }) {
    }
}

export class MeasurementMethodologiesPageToggleLoading implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologiesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class MeasurementMethodologiesActionToggleLoading implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologiesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class MeasurementMethodologyDeletedFromAdminTrash implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class MeasurementMethodologyDeletedFromTrash implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class MeasurementMethodologyOnServerRestored implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class MeasurementMethodologyRestored implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyRestored;

    constructor(public payload: { item: MeasurementMethodology }) {
    }
}

export class MeasurementMethodologyOnServerAdminRestored implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class MeasurementMethodologyAdminRestored implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyAdminRestored;

    constructor(public payload: { item: MeasurementMethodology }) {
    }
}

export class MeasurementMethodologyTrashFlushed implements Action {
    readonly type = MeasurementMethodologyActionTypes.MeasurementMethodologyTrashFlushed;

    constructor() {
    }
}

export type MeasurementMethodologyActions = MeasurementMethodologyCreated
    | MeasurementMethodologyUpdated
    | MeasurementMethodologyDeleted
    | MeasurementMethodologiesPageRequested
    | MeasurementMethodologiesPageLoaded
    | MeasurementMethodologiesPageCancelled
    | AllMeasurementMethodologiesLoaded
    | AllMeasurementMethodologiesRequested
    | MeasurementMethodologyOnServerCreated
    | MeasurementMethodologiesPageToggleLoading
    | MeasurementMethodologiesActionToggleLoading
    | MeasurementMethodologyDeletedFromAdminTrash
    | MeasurementMethodologyDeletedFromTrash
    | MeasurementMethodologyOnServerRestored
    | MeasurementMethodologyRestored
    | MeasurementMethodologyOnServerAdminRestored
    | MeasurementMethodologyAdminRestored
    | MeasurementMethodologyTrashFlushed;
