import { BaseModel } from '../../_base/crud';
import { AssetClassBuildingInformation, ResidentialExternalAreaModel } from '../../comparable';
import { AssetClassDetailLandParcelModel } from './asset-class-detail-land-parcel.model';
import { TargetPropertyRadioButtonValues } from './RadioButtonValues';
import { Video } from '../../file-upload/_models/video.model';
import { Link } from './link.model';
import { LocationData } from "../../comparable/_models/location.model";
import { ExternalAspectData, newExternalAspectData } from "../../comparable/_models/asset-class-external-aspect.model";
import { InternalAspectData, newInternalAspectData } from "../../comparable/_models/asset-class-internal-aspect.model";
import { AssetClassLandAreaComponentModel } from './asset-class-land-area-component.model';
import { AssetClassLandAreaModel } from './asset-class-land-area.model';
import { AssetClassBuilding } from '../../comparable/_models/asset-class-building.model';

export class AssetClassDetailModel extends BaseModel {
    id: number;
    tp_id: number;
    is_complete: number;
    user_id: number;

    name: string;
    floor_details: string;

    locationData: LocationData;
    serviceAndInfraData: {
        id: number,
        road: boolean;
        status_n_quality: string,
        access_points: string,
        length_of_road: string,
        ownership: string,

        central_heating: boolean,
        heatings: number[],
        other_heating: string,

        all_of_it: boolean,
        services: number[],
        other_service: string
    }

    externalAspectData: ExternalAspectData
    internalAspectData: InternalAspectData

    grounds: {
        id: number,
        indoor_garage: number,
        indoor_garage_comment: string,

        outdoor_garage: number,
        outdoor_garage_comment: string,

        garden_id: number,
        garden_comment: string,

        outdoor_space_id: number,
        outdoor_space_comment: string,

        external_areas: any[],
        external_area_comment: null,
    }

    has_building_facility: TargetPropertyRadioButtonValues;
    building_facility_string: string;
    has_facility: TargetPropertyRadioButtonValues;
    has_exclusive_facility: TargetPropertyRadioButtonValues;
    has_external_areas: TargetPropertyRadioButtonValues;

    residential_type_id: number;
    residential_type_name: string;

    sub_type_category_id: number;

    office_layout_id: number;
    bedroom: number;
    room: number;
    bathroom: number;

    floor_type_id: number;
    floor_type_name: string;
    window_type_id: number;
    window_type_name: string;
    address: string;
    location_grade_id: number;
    location_grade_name: string;
    indoor_garage_places: string;
    outdoor_garage_places: string;
    description: string;
    handover_standard_id: number;
    handover_standard_name: string;
    handover_standard_description: string;
    access_facilities: string;
    is_discount: string;
    discount_percentage: number;
    is_access_commercial: string;
    is_access_green: string;

    unit_description: string;
    flooring_type_comment: string;
    windows_type_comment: string;
    tenancy: string;

    what3words: string;
    surrounding_description: string;
    latitude: number;
    longitude: number;

    toe_unit_measurement_id: number;

    country_id: number;
    country_name: string;
    city_id: number;
    city_name: string;
    zip_code: string;

    building_information: AssetClassBuildingInformation;    
    size: string;

    picture: string;
    ac_pictures: any[];
    selected_external_area: ResidentialExternalAreaModel[];

    facilities_string: string;
    ex_facilities_string: string;
    external_area_string: string;

    // inspection detail
    inspection_date: string;
    time_of_inspection: string;
    duration_of_inspection: string;
    inspection_note: string;

    // desktop review
    information_date: string;
    source_of_info: string;
    // Participants
    any_limitations_or_restrictions: boolean;
    limitations_desc: string;

    // purpose measurement information
    purpose_of_the_measurement_instruction: string;
    measurement_date: string;
    measurement_methodology_adopted: string; // Measurement methodology adopted
    scale_of_plan: string;  // Scale of plan
    floorpans: string;

    unit_measurement_id: number;
    unit_measurement_name: string;
    measurement_standard_id: number;
    measurement_standard_name: string;
    new_measurement_standard_name: string;
    new_measurement_standard_desc: string;
    new_measurement_standard_files: any[];

    participants: any;

    land_parcel: AssetClassDetailLandParcelModel;
    videos: Video[];
    links: Link[];

    record_potential_env_issues: boolean;
    record_info_type: number;
    observation_checklist: {
        table1: any[];
        table2: any[];
        otherNotes: string;
    };
    observation_documents: any[];

    accommodation: {
        id: number;
        data: Record<number, Record<string, number>>,
        others: {id: number, title: string}[]
    }
    points: any[];
    landAreaComponents: AssetClassLandAreaComponentModel[] = [];
    landArea: AssetClassLandAreaModel;

    aboutProperty: {
        id: number,
        property_grade_id: number;
        general_desc: string;
        completion_year: string;
        apprx_year_extended: string;
        state_of_repair_id: number;
        handover_standard_id: number;
        foundation_type_id: number;
        above_floors: number;
        below_floors: number;
        floor_details: string;
        energy_efficiency_grade_id: number;
        construction_desc: string;
        num_of_units: number;
        parking_type_id: number;
        automation: number;
        automation_type: string;
        floor_numbering_scheme_id: number;
    }
    building: {
        id: number,
        building_id: number,
        building_name: string;
        completion_year: string;
        is_part_of_property: boolean;
        info_about_property: string;
        building_type: number;
        building_type_name?: string;
        building_type_comment: string;
        building_grade_id: number;
        building_grade_name?: string;
        energy_efficiency_grade_id: number;
        developer: string;
        anchor_tenant: string;
        foundation_type_id: number;
        building_description: string;
        pictures: any[],
        picture: string
    }
    buildingInfo: AssetClassBuilding;
    sub_category_id: number;
    add_info_on_land: boolean;
    land_parcel_name: string;
    coordinate_reference_system_id: number;
    condition_rating_documents: any[];

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    new_custom_measurement_standard_name: string;

    clear() {
        this.id = null;
        this.tp_id = null;
        this.is_complete = 0;
        this.user_id = null;

        this.name = '';
        this.floor_details = '';

        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        }

        this.serviceAndInfraData = {
            id: null,
            road: false,
            status_n_quality: null,
            access_points: null,
            length_of_road: null,
            ownership: null,

            central_heating: false,
            heatings: [],
            other_heating: null,

            all_of_it: false,
            services: [],
            other_service: null
        }

        this.externalAspectData = newExternalAspectData() 

        this.internalAspectData = newInternalAspectData() 

        this.grounds = {
            id: null,
            indoor_garage: null,
            indoor_garage_comment: null,

            outdoor_garage: null,
            outdoor_garage_comment: null,

            garden_id: null,
            garden_comment: null,

            outdoor_space_comment: null,
            outdoor_space_id: null,

            external_area_comment: null,
            external_areas: []
        }

        this.has_facility = TargetPropertyRadioButtonValues.No;
        this.has_exclusive_facility = TargetPropertyRadioButtonValues.No;
        this.has_external_areas = TargetPropertyRadioButtonValues.No;

        this.has_building_facility = TargetPropertyRadioButtonValues.No;
        this.building_facility_string = '';
        this.toe_unit_measurement_id = null;

        this.residential_type_id = null;
        this.residential_type_name = '';
        this.office_layout_id = null;
        this.bedroom = null;
        this.room = null;
        this.bathroom = null;
        this.floor_type_id = null;
        this.floor_type_name = '';
        this.window_type_id = null;
        this.window_type_name = '';
        this.address = '';
        this.location_grade_id = null;
        this.location_grade_name = '';
        this.indoor_garage_places = '';
        this.outdoor_garage_places = '';
        this.description = '';
        this.handover_standard_id = null;
        this.sub_type_category_id = null;
        this.handover_standard_name = '';
        this.handover_standard_description = '';
        this.access_facilities = 'no';
        this.is_discount = 'no';
        this.discount_percentage = null;
        this.is_access_commercial = 'no';
        this.is_access_green = 'no';

        this.unit_description = '';
        this.flooring_type_comment = '';
        this.windows_type_comment = '';
        this.tenancy = 'Vacant';

        this.what3words = '';
        this.surrounding_description = '';
        this.latitude = 0;
        this.longitude = 0;

        this.picture = '';
        this.ac_pictures = [];
        this.selected_external_area = [];
        this.size = '';

        this.facilities_string = '';
        this.ex_facilities_string = '';
        this.external_area_string = '';

        this.city_id = null;
        this.city_name = '';

        this.building_information = new AssetClassBuildingInformation();
        this.building_information.clear();

        // inspection detail
        this.inspection_date = '';
        this.time_of_inspection = '';
        this.duration_of_inspection = '';
        this.inspection_note = '';
        this.any_limitations_or_restrictions = false;
        this.limitations_desc = '';

        this.information_date = '';
        this.source_of_info = '';

        // purpose measurement information
        this.purpose_of_the_measurement_instruction = '';
        this.measurement_date = '';
        this.measurement_methodology_adopted = '';
        this.scale_of_plan = '';
        this.floorpans = '';

        this.unit_measurement_id = null;
        this.unit_measurement_name = '';
        this.measurement_standard_id = null;
        this.measurement_standard_name = '';
        this.new_custom_measurement_standard_name = '';

        this.land_parcel = new AssetClassDetailLandParcelModel();
        this.land_parcel.clear();
        this.videos = [];
        this.links = [];

        this.record_potential_env_issues = false;
        this.record_info_type = 1;
        this.observation_checklist = {
            table1: [],
            table2: [],
            otherNotes: ''
        };
        this.observation_documents = [];

        this.points = [];
        this.landAreaComponents = [];
        this.landArea = new AssetClassLandAreaModel();
        this.landArea.clear();
        this.accommodation = {
            id: null,
            data: {},
            others: []
        };
        this.aboutProperty = {
            id: undefined,
            property_grade_id : null,
            general_desc : null,
            completion_year : null,
            apprx_year_extended : null,
            state_of_repair_id : null,
            handover_standard_id : null,
            foundation_type_id : null,
            above_floors : null,
            below_floors : null,
            energy_efficiency_grade_id : null,
            construction_desc : null,
            floor_details: '',
            num_of_units: null,
            parking_type_id: null,
            automation: 0,
            automation_type: null,
            floor_numbering_scheme_id: null
        }
        this.building = {
            id: undefined,
            building_id: null,
            building_name: null,
            building_grade_id: null,
            completion_year: null,
            is_part_of_property: null,
            info_about_property: null,
            building_type: null,
            energy_efficiency_grade_id: null,
            developer: null,
            anchor_tenant: null,
            foundation_type_id: null,
            building_type_comment: null,
            building_description: null,
            picture: null,
            pictures: []
        }
        this.buildingInfo = new AssetClassBuilding();
        this.sub_category_id = null;
        this.new_measurement_standard_name = null;
        this.new_measurement_standard_desc = null;
        this.new_measurement_standard_files = [];

        this.add_info_on_land = false;
        this.land_parcel_name = null;
        this.coordinate_reference_system_id = null;
        this.condition_rating_documents = [];

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }

    public fieldDifference(other: AssetClassDetailModel): boolean {
        const _other = Object.assign({}, other);
        let hasFieldDifference = false;
        const fields = [
            'country_id',
            'city_id',
            'zip_code',
            'location_grade_id',
            'address',
            'floor_details',
            'has_facility',
            'has_exclusive_facility',
            'bedroom',
            'room',
            'bathroom',
            'office_layout_id',
            'residential_type_id',
            'floor_type_id',
            'window_type_id',
            'has_external_areas',
            'indoor_garage_places',
            'outdoor_garage_places',
            'handover_standard_id',
            'latitude',
            'longitude'
        ];
        for (let field of fields) {
            if (field === 'floor_details' && _other[field] === null) {
                _other[field] = "";
            }
            if (_other[field] === null) {
                _other[field] = null;
            }
            if (this[field] !== _other[field]) {
                hasFieldDifference = true;
            }
        }
        return hasFieldDifference;
    }
}
