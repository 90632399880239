// NGRX
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
// Actions
import {TrainingDocumentActions, TrainingDocumentActionTypes} from '../_actions/training-document.actions';
// Models
import {TrainingDocument} from '../_models/training-document.model';

export interface TrainingDocumentsState extends EntityState<TrainingDocument> {
    isAllTrainingDocumentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: TrainingDocument[];
    lastCreatedTrainingDocumentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TrainingDocument> = createEntityAdapter<TrainingDocument>();

export const initialTrainingDocumentsState: TrainingDocumentsState = adapter.getInitialState({
    isAllTrainingDocumentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedTrainingDocumentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function trainingDocumentsReducer(state = initialTrainingDocumentsState, action: TrainingDocumentActions): TrainingDocumentsState {
    switch (action.type) {
        case TrainingDocumentActionTypes.TrainingDocumentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTrainingDocumentId: undefined
            };
        case TrainingDocumentActionTypes.TrainingDocumentsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case TrainingDocumentActionTypes.TrainingDocumentOnServerCreated:
            return {
                ...state
            };
        case TrainingDocumentActionTypes.TrainingDocumentCreated:
            return adapter.addOne(action.payload.trainingDocument, {
                ...state, lastCreatedTrainingDocumentId: action.payload.trainingDocument.id
            });
        case TrainingDocumentActionTypes.TrainingDocumentUpdated:
            return adapter.updateOne(action.payload.partialtrainingDocument, state);

            case TrainingDocumentActionTypes.AllTrainingDocumentsLoaded:
            return adapter.addAll(action.payload.trainingDocuments, {
                ...state, isAllTrainingDocumentsLoaded: true
            });
        case TrainingDocumentActionTypes.TrainingDocumentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case TrainingDocumentActionTypes.TrainingDocumentsPageLoaded:
            return adapter.addMany(action.payload.trainingDocuments, {
                ...initialTrainingDocumentsState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.trainingDocuments,
                lastQuery: action.payload.page,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                showInitWaitingMessage: false
            });
        case TrainingDocumentActionTypes.TrainingDocumentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case TrainingDocumentActionTypes.TrainingDocumentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case TrainingDocumentActionTypes.TrainingDocumentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case TrainingDocumentActionTypes.TrainingDocumentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case TrainingDocumentActionTypes.TrainingDocumentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case TrainingDocumentActionTypes.TrainingDocumentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
