<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$"
                        [tooltipTitle]="'TOOLTIP.LANDMARK.LIST.TITLE' | translate"
                        [tooltipDesc]="'TOOLTIP.LANDMARK.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-title">
                <span>{{ 'LANDMARK.LIST.TITLE' | translate:{view_mode: listViewMode ? listText : mapText} }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <button (click)="addKeyPlace()" mat-raised-button 
                [matTooltip]="'LANDMARK.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button" class="mr-2">
                <span>{{ 'LANDMARK.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>

            <button (click)="toggleView()"
                    mat-raised-button
                    [matTooltip]="'LANDMARK.LIST.BUTTON.CHANGE_VIEW_MODE.TOOLTIP' | translate"
                    color="primary"
                    type="button"
                    class="mr-2">
                <span> {{!listViewMode ? listText: mapText}} </span>
            </button>

            <button *ngIf="(trashCnt$ | async) != 0" (click)="trash()" mat-raised-button 
                [matTooltip]="'LANDMARK.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray mr-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt$ | async}})</span>
            </button>

            <button *ngIf="(adminTrashCnt$ | async) != 0" (click)="adminTrash()" mat-raised-button 
                [matTooltip]="'LANDMARK.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt$ | async}})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="lmStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_lms }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TOTAL LANDMARKS</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_different_locations }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">DIFFERENT LOCATIONS</span></p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_used_in_vals }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">USED IN VALUATIONS</span></p>
                    </div>
                </div>
            </div>
        </div>
        <kt-filter-section [acType]="-1" [filter$]="filterObs$" (filterChange)="onFilterChange($event)"></kt-filter-section>

        <!-- start::MAP -->
        <div class="form-group kt-form__group row" *ngIf="!listViewMode">
            <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                <agm-map [latitude]="centerLat$ | async"
                         [longitude]="centerLng$ | async"
                         [zoom]="zoom"
                         (mapClick)="mapClick()"
                         (mapReady)="mapReady($event)"
                         (centerChange)="centerChange($event)"
                         (boundsChange)="boundsChanged($event)"
                         (zoomChange)="zoomChanged($event)"
                         [usePanning]="true"
                         [disableDoubleClickZoom]="true"
                         [scrollwheel]="null"
                         [clickableIcons]="false">
                    <agm-map-type-control></agm-map-type-control>
                    <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="setLocation()">
                        <mat-icon>my_location</mat-icon>
                    </button>
                    <agm-marker-cluster
                        imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">

                        <agm-marker *ngFor="let item of landMarkResult"
                                    [latitude]="item.latitude"
                                    [longitude]="item.longitude"
                                    [iconUrl]="getIcon(item)"
                                    animation="DROP"
                                    (markerClick)="showInfo(item.id, item)">
                        </agm-marker>
                    </agm-marker-cluster>
                </agm-map>
            </div>
        </div>
        <!-- end::MAP -->


        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="mat-elevation-z0 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="desc"
                       matSortDisableClear>

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let keyPlace">{{keyPlace.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'LANDMARK.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let keyPlace" (click)="editKeyPlace(keyPlace)">{{ keyPlace.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'LANDMARK.LIST.DATATABLE.DESCRIPTION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let keyPlace">{{keyPlace.description}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="category_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'LANDMARK.LIST.DATATABLE.CATEGORY' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let keyPlace">{{keyPlace.category_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city_of_location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'LANDMARK.LIST.DATATABLE.LOCATION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let keyPlace">
                        {{ keyPlace.city_of_location }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'LANDMARK.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let keyPlace">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <!-- <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: keyPlace.createdBy ? keyPlace.createdBy : 'Unknown',
                                date: (keyPlace.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: keyPlace.lastUpdatedBy ? keyPlace.lastUpdatedBy: 'Unknown',
                                date: (keyPlace.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }">
                            </div> -->
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: keyPlace.createdBy ? keyPlace.createdBy : 'Unknown',
                                date: (keyPlace.created_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: keyPlace.lastUpdatedBy ? keyPlace.lastUpdatedBy: 'Unknown',
                                date: (keyPlace.updated_at | date: 'dd MMM yyyy')
                            }">
                            </div>
                        </ng-template>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" 
                            [matTooltip]="'LANDMARK.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editKeyPlace(keyPlace)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <span
                            [matTooltip]="'LANDMARK.LIST.BUTTON.DELETE.TOOLTIP' | translate">
                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    (click)="deleteKeyPlace(keyPlace)">
                                <mat-icon>delete</mat-icon>
                            </button>&nbsp;
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}</div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
