import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppState } from '../../../../../core/reducers';
import {
    AssetClassesService
} from '../../../../../core/asset_class';

@Injectable()
export class AssetClassGetResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public assetClassesService: AssetClassesService,) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const acId = Number(route.params.asset_class_id);
        return this.assetClassesService.getAssetClass(acId);
    }
}
