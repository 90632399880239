import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { ToeReportTaskModel } from "../_models/toe-report-task.model";

export enum ToeReportTasksActionTypes {
	AllToeReportTasksByToeIDRequested = '[Toe Dashboard] all toe report tasks related to give toe requested',
	AllToeReportTasksByToeIDLoaded = '[Toe Report Task Requested Effect] all report tasks are loaded',

	UpdateToeReportTask = '[Edit Report Task Info Dialog] updates report task',
	ToeReportTaskUpdated = '[Update Toe Report Task Efffect] updates report task in store',

	UpdateReportTaskReports = '[Toe Documents Table] update report task\'s reports',

	DeleteReportTask = '[Toe Documents Table] delete report tasks',
	ReportTaskDeleted = '[Toe Report Tasks Effect] report task deleted'
}

export class AllToeReportTasksByToeIDRequested implements Action {
	readonly type = ToeReportTasksActionTypes.AllToeReportTasksByToeIDRequested;
	constructor(public payload: {toeID: number}) {}
}

export class AllToeReportTasksByToeIDLoaded implements Action {
	readonly type = ToeReportTasksActionTypes.AllToeReportTasksByToeIDLoaded;
	constructor(public payload: {
		reports: ToeReportTaskModel[]
	}) {}
}

export class UpdateToeReportTask implements Action {
	readonly type = ToeReportTasksActionTypes.UpdateToeReportTask;
	constructor(public payload: {
		task: ToeReportTaskModel
	}) {}
}

export class ToeReportTaskUpdated implements Action {
	readonly type = ToeReportTasksActionTypes.ToeReportTaskUpdated;
	constructor(public payload: {
		update: Update<ToeReportTaskModel>
	}) {}
}

export class UpdateReportTaskReports implements Action {
	readonly type = ToeReportTasksActionTypes.UpdateReportTaskReports;
	constructor(public payload: {
		id: number,
		reports: any[],
		taskCompleted: boolean
	}) {}
}

export class DeleteReportTask implements Action {
	readonly type = ToeReportTasksActionTypes.DeleteReportTask;
	constructor(public payload: {id: number}) {}
}

export class ReportTaskDeleted implements Action {
	readonly type = ToeReportTasksActionTypes.ReportTaskDeleted;
	constructor(public payload: {id: number}) {}
}

export type ToeReportTasksActions = 
	| AllToeReportTasksByToeIDRequested
	| AllToeReportTasksByToeIDLoaded
	| UpdateToeReportTask
	| ToeReportTaskUpdated
	| UpdateReportTaskReports
	| DeleteReportTask
	| ReportTaskDeleted;