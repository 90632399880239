import {
    AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges,
    SimpleChanges, OnDestroy
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LayoutUtilsService} from '../../../../../core/_base/crud';
// import { AuditTrailParticipantModel } from '../../../../../core/assignment';
import {ParticipantEditDialogComponent} from '../participant-edit/participant-edit.dialog.component';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuditTrailParticipantModel} from '../../../../../core/assignment';
import { switchMap } from 'rxjs/operators';
import { ParticipantService } from './participant.service';
import * as _ from 'lodash'
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'kt-participant-list',
    templateUrl: './participant-list.component.html',
    styleUrls: ['./participant-list.component.scss']
})

export class ParticipantListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    displayedColumns = ['participants_names', 'participants_side', 'participants_descr', 'actions'];
    @Input() agencyName: BehaviorSubject<string>;
    @Input() agencyId: BehaviorSubject<number>;
    @Input() clientName: BehaviorSubject<string>;
    @Input() clientId: BehaviorSubject<number>;
    @Input() toeSelected: BehaviorSubject<boolean>;
    @Input() toeId: BehaviorSubject<number>;
    @Input() participantsSubject: BehaviorSubject<AuditTrailParticipantModel[]>;
    participants: AuditTrailParticipantModel[] = [];
    @Input() readOnly: boolean;
    @Input() atLeastOneParticipant$: BehaviorSubject<boolean>;

    dataSource = new MatTableDataSource([]);
    _toeSelected = false;
    _agencyName: string;
    _clientName: string;
    trashed: number;
    private subscription: Subscription;

    @ViewChild(MatSort) matSort: MatSort;

    // Selection
    selection = new SelectionModel<AuditTrailParticipantModel>(true, []);

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private layoutUtilsService: LayoutUtilsService,
                private participantService: ParticipantService,
                private cdRef: ChangeDetectorRef,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.subscription = combineLatest([
            this.participantsSubject.asObservable(),
            this.toeSelected.asObservable(),
            this.agencyName.asObservable(),
            this.clientName.asObservable(),
            combineLatest([
                this.agencyId.asObservable(),
                this.clientId.asObservable(),
                this.toeId.asObservable(),
            ]).pipe(
                switchMap(([agencyId, clientId, toeId]) => {
                    if (toeId == null || toeId == 0) {
                        return of([])
                    }
                    return this.participantService.fetch(agencyId, toeId, clientId);
                })
            )
        ]).subscribe(
            ([res, toeSelected, agencyName, clientName, defParticipants]: any[]) => {
                this.participants = _.cloneDeep(res);
                this._toeSelected = toeSelected;
                this._agencyName = agencyName;
                this._clientName = clientName;
                this.dataSource.data = this.convertParticipants(this.participants, agencyName, clientName, toeSelected, defParticipants);
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
    ngAfterViewInit() {
        // this.dataSource.sort = this.matSort;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    addParticipant() {
        const newAuditTrailParticipant = new AuditTrailParticipantModel();
        newAuditTrailParticipant.clear(); // Set all defaults fields
        this.editParticipant(newAuditTrailParticipant, null);
    }

    editParticipant(_participant: AuditTrailParticipantModel, index: number) {
        const p = this.findParticipant(_participant);
        const dialogRef = this.dialog.open(ParticipantEditDialogComponent, {
            data: {
                participant: _participant,
                agencyName: this._agencyName,
                clientName: this._clientName,
                toeSelected: this._toeSelected
            }
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
                if (!p) {
                    const newP = this.newParticipantFrom(res.participant, {checked: _participant.checked});
                    this.participants.push(newP);
                    this.participantsSubject.next(this.participants);
                } else {
                    if (res.participant && index != null) {
                        this.participants = Object.assign([], this.participants);
                        this.participants[index] = res.participant;
                        this.participantsSubject.next(this.participants);
                    } else {
                        this.participants = [...this.participants, res.participant];
                        this.participantsSubject.next(this.participants);
                    }
                }
                if (this.participants.length > 0) {
                    this.atLeastOneParticipant$.next(false);
                }
            }
        );
    }

    descriptionChange(event, participant: AuditTrailParticipantModel, index: number) {
        const p = _.cloneDeep(participant)
        p.participants_descr = event.target.value;
        this.participants = Object.assign([], this.participants);
        this.participants[index] = p;
        this.participantsSubject.next(this.participants);
    }

    deleteParticipant(participant: AuditTrailParticipantModel) {
        const _title = 'Are you sure?';
        const _description: string = 'The participant "' + participant.participants_names + '" will be deleted';
        const _waitDesciption = 'Participant deleting';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const p = this.findParticipant(participant);
            if (p) {
                const index = this.participants.indexOf(p);
                this.participants.splice(index, 1);
            }
            this.participantsSubject.next(this.participants);
        });
    }

    checkBoxChange(event: MatCheckboxChange, participant) {
        const p = this.findParticipant(participant);

        if (!p) {
            const newP = this.newParticipantFrom(participant, {checked: event.checked});
            this.participants.push(newP)
            this.participantsSubject.next(this.participants);
            return;
        }

        const index = this.participants.indexOf(p);
        const newP = this.newParticipantFrom(p, {checked: event.checked})

        this.participants.splice(index, 1, newP);
        this.participantsSubject.next(this.participants);
    }

    private findParticipant(participant: AuditTrailParticipantModel): AuditTrailParticipantModel {
        let p = null
        if (participant.ref_id == null) {
            p = this.participants.find(p => p.participants_side == participant.participants_side && p.participants_names == participant.participants_names);
        } else {
            p = this.participants.find(p => p.ref_id == participant.ref_id && p.participants_side == participant.participants_side);
        }
        return p;
    }

    private newParticipantFrom(p: AuditTrailParticipantModel, opt: {checked: boolean}): AuditTrailParticipantModel {
        const newP = new AuditTrailParticipantModel(); 
        newP.clear();
        newP.ref_id = p.ref_id
        newP.participants_side = p.participants_side;
        newP.participants_names = p.participants_names;
        newP.participants_descr = p.participants_descr;
        newP.checked = opt.checked;
        return newP;
    }

    private convertParticipants(participants: AuditTrailParticipantModel[], agencyName: string, clientName: string, toeSelected: boolean, defParticipants: any[]): any[] { 
        const defExists = (p: AuditTrailParticipantModel) => {
            const exists = defParticipants.find(dp => dp.side == p.participants_side && dp.id == p.ref_id);
            return exists != undefined;
        }
        // Split participants
        const [exists, additional] = _.partition(participants, (p => defExists(p)));
        
        const convertedDef = defParticipants.map(dp => {
            const p = new AuditTrailParticipantModel();
            p.clear();
            p.participants_side = dp.side;
            p.participants_names = dp.name;
            p.ref_id = dp.id

            const e = exists.find(ep => ep.ref_id == dp.id && ep.participants_side == dp.side)
            if (e) {
                p.checked = e.checked;
                p.participants_descr = e.participants_descr;
            }
            return p;
        })

        const convertedAdditional = additional.map(ap => {
            const p = new AuditTrailParticipantModel();
            p.clear();
            p.participants_side = ap.participants_side;
            p.participants_names = ap.participants_names
            p.ref_id = null;
            p.checked = ap.checked;
            p.participants_descr = ap.participants_descr;
            return p
        })

        
        return _.concat(convertedDef, convertedAdditional).map(p => {
            if (p.participants_side == "Our Side") {
                return {
                    ...p,
                    side_name: `${agencyName} (The Agency)`
                }
            }
            if (p.participants_side == "Client Side") {
                return {
                    ...p,
                    side_name: toeSelected ? `${clientName} (The Client)` : 'The Client'
                }
            }
            return {
                ...p,
                side_name: p.participants_side
            }
        });
    }
}
