<div class="card card-custom">
    <div class="card-body" *ngIf="!(loading$|async)">
        <kt-sizes-table-v2
            (removeComparable)="onRemoveComparable($event)"
            (showComparable)="onShowComparable($event)"
            (showComparableOverview)="onShowComparableOverview($event)"></kt-sizes-table-v2>
        <kt-criterions-table-v2
            (removeComparable)="onRemoveComparable($event)"
            (showComparable)="onShowComparable($event)"
            (showComparableOverview)="onShowComparableOverview($event)"
            ></kt-criterions-table-v2>
        <kt-adjustments-table-v2
            (removeComparable)="onRemoveComparable($event)"
            (showComparable)="onShowComparable($event)"
            ></kt-adjustments-table-v2>
        <kt-valuation-table-v2
            (removeComparable)="onRemoveComparable($event)"
            (showComparable)="onShowComparable($event)"
            (showComparableOverview)="onShowComparableOverview($event)"
        ></kt-valuation-table-v2>
    </div>
</div>