import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TpTemplateModel } from 'src/app/core/template';
import { TpTemplateListModalComponent } from 'src/app/views/pages/toe/_sub/tp-template-list-modal/tp-template-list-modal.component';

@Component({
  selector: 'kt-tp-template-modal',
  templateUrl: './tp-template-modal.component.html',
  styleUrls: ['./tp-template-modal.component.scss']
})
export class TpTemplateModalComponent implements OnInit {
  public tpTemplate: TpTemplateModel;
  constructor(
    private dialogRef: MatDialogRef<TpTemplateListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.tpTemplate = data.item;
  }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
