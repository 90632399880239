<div class="card">
    <div class="card-header info-header" style="background-color: #999999;">
        <span>Property Specifics</span>
    </div>
    <div class="card-body info-body" style="background-color: #ebebeb;">
        <table *ngIf="data$|async as ps" class="table">
            <tbody>
                <tr>
                    <th class="label-1">Property Type :</th>
                    <td class="value-1">{{ps.propertyType}}</td>
                    <th class="label-2">Property Sub-Type :</th>
                    <td class="value-2">{{ps.assetClass}}</td>
                </tr>
                <tr *ngIf="ps.subTypeCategory || ps.subCategory">
                    <ng-container *ngIf="ps.subTypeCategory">
                        <th>Sub-Type Category:</th>
                        <td>{{ps.subTypeCategory}}</td>
                    </ng-container>
                    <ng-container *ngIf="ps.subCategory">
                        <th>Sub Category:</th>
                        <td>{{ps.subCategory}}</td>
                    </ng-container>
                </tr>
                <tr *ngIf="targetProperty.top_property_type_id != 3">
                    <th>Location Grade :</th><td>{{ps.locationGrade}}</td>
                    <th>Property Size ({{ps.standardMeasurementName}}) :</th>
                    <td>
                    <span *ngIf="!hasSizeTable">
                        {{ps.propertySize}}
                    </span>
                    <span *ngIf="hasSizeTable" class="clickable" (click)="showSizeTable()">
                        {{ps.propertySize}}
                    </span>
                    </td>
                </tr>
                <ng-container *ngIf="targetProperty.top_property_type_id == 3">
                    <tr>
                        <th>Location Grade :</th><td>{{ps.locationGrade}}</td>
                        <th>Coordinate Reference System:</th>
                        <td>{{ps.coordinateRefSys}}</td>
                    </tr>
                    <tr>
                        <th>Total number of land parcel :</th><td>{{ps.totalLandParcel}}</td>
                        <th>Total land area ({{ps.totalLandAreaUnit}}):</th>
                        <td>{{ps.totalLandArea}}</td>
                    </tr>
                    <tr>
                        <th>Planning Status :</th><td>{{ps.planningStatus}}</td>
                        <th>Number of buildings:</th><td>{{ps.numberOfBuildings}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="targetProperty.type_id == 3 || targetProperty.type_id == 1">
                    <tr><th>Building Type :</th><td>{{ps.buildingType}}</td><th>Building Grade :</th><td>{{ps.buildingGrade}}</td></tr>
                </ng-container>
                <ng-container *ngIf="targetProperty.type_id == 7 || targetProperty.type_id == 17">
                    <tr><th>Property Grade :</th><td>{{ps.propertyGrade}}</td></tr>
                </ng-container>
                <tr>
                    <th>Address :</th>
                    <td colspan="3">
                        <span *ngIf='multimedia'>{{ps.address}}</span>
                        <span *ngIf='!multimedia' class="clickable" (click)="showInMap($event)">{{ps.address}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>