// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ChimneyStackActions, ChimneyStackActionTypes} from '../_actions/chimney-stack.actions';
// Models
import {ChimneyStackModel} from '../_models/chimney-stack.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ChimneyStacksState extends EntityState<ChimneyStackModel> {
    isAllChimneyStacksLoaded: boolean;
    queryRowsCount: number;
    queryResult: ChimneyStackModel[];
    lastCreatedChimneyStackId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ChimneyStackModel> = createEntityAdapter<ChimneyStackModel>();

export const initialChimneyStackState: ChimneyStacksState = adapter.getInitialState({
    isAllChimneyStacksLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedChimneyStackId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function chimneyStackReducer(state = initialChimneyStackState, action: ChimneyStackActions): ChimneyStacksState {
    switch (action.type) {
        case ChimneyStackActionTypes.ChimneyStacksPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedChimneyStackId: undefined
            };
        case ChimneyStackActionTypes.ChimneyStackActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ChimneyStackActionTypes.ChimneyStackOnServerCreated:
            return {
                ...state
            };
        case ChimneyStackActionTypes.ChimneyStackCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedChimneyStackId: action.payload.item.id
            });
        case ChimneyStackActionTypes.ChimneyStackUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ChimneyStackActionTypes.AllChimneyStacksLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllChimneyStacksLoaded: true
            });
        case ChimneyStackActionTypes.ChimneyStacksPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ChimneyStackActionTypes.ChimneyStacksPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialChimneyStackState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ChimneyStackActionTypes.ChimneyStackDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ChimneyStackActionTypes.ChimneyStackRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ChimneyStackActionTypes.ChimneyStackDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ChimneyStackActionTypes.ChimneyStackAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ChimneyStackActionTypes.ChimneyStackDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ChimneyStackActionTypes.ChimneyStackTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
