import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppState } from '../../../../../core/reducers';
import {
    AppendixCategoriesService
} from '../../../../../core/admin';

@Injectable()
export class AppendixCategoryResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                public appendixCategoriesService: AppendixCategoriesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const appendixCategoryId = Number(route.params.id);
        return this.appendixCategoriesService.getById(appendixCategoryId);
    }
}
