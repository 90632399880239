// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
// Models
import {AuditTrailModel} from '../_models/audit-trail.model';
import {AuditTrailParticipantModel} from '../';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const API_URL = '/audit-trails';

@Injectable()
export class AuditTrailsService {
    API_CUSTOMERS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignments/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    find(queryParams: QueryParamsModel, assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_CUSTOMERS_URL + assignmentId + API_URL;
        return this.http.get<any>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    create(_auditTrail: AuditTrailModel,
           assignmentId: number,
           _participants: AuditTrailParticipantModel[],
           _fileList: any[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {auditTrail: _auditTrail, participants: _participants, fileList: _fileList};
        const url = this.API_CUSTOMERS_URL + assignmentId + API_URL + '/add';
        return this.http.post<any>(url, body, {headers: httpHeaders});
    }

    update(_auditTrail: AuditTrailModel,
           assignmentId: number,
           _participants: AuditTrailParticipantModel[],
           _fileList: any[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {auditTrail: _auditTrail, participants: _participants, fileList: _fileList};
        const url = this.API_CUSTOMERS_URL + assignmentId + API_URL + `/${_auditTrail.id}`;
        return this.http.put<any>(url, body, {headers: httpHeaders});
    }


    delete(_id: number, _trashDeleteDescr: string, _userId: number): Observable<any> {
        const url = `${environment.baseApiUrl}/api/${this.subDomainService.subDomain}/audit-trails/delete/${_id}/${_userId}`;
        const body = {desc: _trashDeleteDescr, id: _id, user_id: _userId};
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(url, body, {headers: httpHeaders});
    }

    getById(id: number): Observable<any> {
        const url = environment.baseApiUrl + `api/${this.subDomainService.subDomain}` + API_URL;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/${id}`, {headers: httpHeaders});
    }

    // TRASH (User)
    getTrashed(assId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CUSTOMERS_URL + assId + API_URL + '/trash', {headers: httpHeaders});
    }

    flushTrash(assId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CUSTOMERS_URL + assId + API_URL + '/trash/flush', {headers: httpHeaders});
    }

    deleteFromTrash(agencyId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}` + API_URL + `/trash/${agencyId}`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashed(assId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CUSTOMERS_URL + assId + API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(agencyId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}` + API_URL + `/admin/trash/${agencyId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}` + API_URL + `/trash/${id}`, {headers: httpHeaders});
    }
}
