import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { select, Store } from "@ngrx/store";
import { combineLatest, forkJoin, of } from "rxjs";
import { concatMap, map, mergeMap, take } from "rxjs/operators";
import { AppState } from "../../reducers";
import { DeleteSignedDoc, GetTpSignedDocs, GotTpSignedDocs, SignedDocDeleted, TpSignedDocActionTypes, TpSignedDocEmtpyAction, TpSignedDocUploaded, TpSignedDocUploadFailed, UploadTpSignedDoc } from "../_actions/tp-signed-docs.actions";
import { UpdateSignedDocUploadTask } from "../_actions/tp-task.actions";
import { TpTaskModel } from "../_models/tp-task.model";
import { selectSignedDocUploadTask } from "../_selectors/tp-task.selectors";
import { TpSignedDocService } from "../_services/tp-signed-docs.service";

@Injectable()
export class TpSignedDocEffects {
	constructor(
		private actions$: Actions,
		private service: TpSignedDocService,
		private store: Store<AppState>
	) {}

	@Effect()
	getDocs$ = this.actions$.pipe(
		ofType<GetTpSignedDocs>(TpSignedDocActionTypes.GetSignedDocs),
		mergeMap(({payload}) => {
			return this.service.getDoc(payload.tpID);
		}),
		map(docs => {
			return new GotTpSignedDocs({docs});
		})
	)

	@Effect()
	uploadDoc$ = this.actions$.pipe(
		ofType<UploadTpSignedDoc>(TpSignedDocActionTypes.UploadSignedDoc),
		concatMap(({payload}) => {
			return forkJoin([this.service.uploadDoc(payload.tpID, payload.data), of(payload.tpID)])
		}),
		map(([res, tpID]) => {
			if (res instanceof Error) {
				return new TpSignedDocUploadFailed({error: res, tpID});
			}
			return new TpSignedDocUploaded({model: res});
		})
	)

	@Effect()
	updateTask$ = this.actions$.pipe(
		ofType<TpSignedDocUploaded>(TpSignedDocActionTypes.SignedDocUploaded),
		mergeMap(({payload}) => {
			return this.store.pipe(take(1), select(selectSignedDocUploadTask(payload.model.tp_id, payload.model.type)));
		}),
		map(task => {
			if (task) {
				const newTask: Update<TpTaskModel> = {
					id: task.id,
					changes: {
						point: task.max_point
					}
				}
				this.store.dispatch(new UpdateSignedDocUploadTask({partial: newTask}))
			}
			return new TpSignedDocEmtpyAction();
		})
	)


	@Effect()
	deleteDoc$ = this.actions$.pipe(
		ofType<DeleteSignedDoc>(TpSignedDocActionTypes.DeleteSignedDoc),
		mergeMap(({payload}) => {
			return combineLatest([
				of(payload),
				this.store.pipe(take(1), select(selectSignedDocUploadTask(payload.tpID, payload.type)))
			]);
		}),
		mergeMap(([payload, task]) => {
			let taskID = -1;
			if (task) {
				taskID = task.id;
			}
			return combineLatest([
				this.service.delete(payload.tpID, payload.id, taskID),
				of(task)
			])
		}),
		map(([id, task]) => {
			if (task) {
				const newTask: Update<TpTaskModel> = {
					id: task.id,
					changes: {
						point: 0
					}
				}
				this.store.dispatch(new UpdateSignedDocUploadTask({partial: newTask}));
			}
			return new SignedDocDeleted({id: id});
		})
	)
}