import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../core/reducers';
import {Location} from '@angular/common';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {Store, select} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {
    DefaultAssumption,
    SpecialAssumption,
    Departure,
    DefaultAssumptionsService,
    SpecialAssumptionsService,
    DeparturesService,
    ValuationStandard,
    AllValuationStandardRequested,
    selectAllValuationStandard,
    DefaultAssumptionOnServerCreated,
    SpecialAssumptionOnServerCreated,
    DepartureOnServerCreated
} from '../../../../core/linked-tables';

@Component({
    selector: 'kt-alt-checkbox-add-alt',
    templateUrl: './checkbox-add.component.html',
    styleUrls: ['./checkbox-add.component.scss']
})

export class CheckboxAddComponent implements OnInit, OnDestroy {

    item: any;
    form: UntypedFormGroup;
    hasFormErrors = false;
    valuationStandards: ValuationStandard[] = [];
    title: string;
    required: string;
    placeholder: string;

    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef
     * @param data: any
     * @param dialog: MatDialog
     * @param location: Location
     * @param fb: FormBuilder
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param defaultAssumptionsService
     * @param specialAssumptionsService
     * @param departuresService
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<CheckboxAddComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public location: Location,
                private fb: UntypedFormBuilder,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private defaultAssumptionsService: DefaultAssumptionsService,
                private specialAssumptionsService: SpecialAssumptionsService,
                private departuresService: DeparturesService,
                private store: Store<AppState>) {
    }

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.typeOfCheckbox === 'assumption') {
            this.title = 'Add default assumption';
            this.placeholder = 'Default assumption';
            this.required = 'Default assumption';
            this.item = new DefaultAssumption();
            this.item.clear();
        } else if (this.data.typeOfCheckbox === 'specialAssumption') {
            this.title = 'Add special assumption';
            this.placeholder = 'Special assumption';
            this.required = 'Special assumption';
            this.item = new SpecialAssumption();
            this.item.clear();
        } else {
            this.title = 'Add departure';
            this.placeholder = 'departure';
            this.required = 'departure';
            this.item = new Departure();
            this.item.clear();
        }
        this.initForm();
    }

    initForm() {
        this.createForm();

        this.store.dispatch(new AllValuationStandardRequested());

        const valuationStandardSubscription = this.store.pipe(
            select(selectAllValuationStandard))
            .subscribe(res => {
                this.valuationStandards = [];
                if (res) {
                    this.valuationStandards = res;
                }
            });

        this.subscriptions.push(valuationStandardSubscription);
    }

    createForm() {
        this.form = this.fb.group({
            name: [this.item.name, Validators.required],
            valuation_standard_id: [this.item.valuation_standard_id ? this.item.valuation_standard_id : ''],
        });

        if (this.data.typeOfCheckbox === 'departure') {
            this.form.controls.valuation_standard_id.setValidators([Validators.required]);
        } else {
            this.form.controls.valuation_standard_id.clearValidators();
        }
        this.form.controls.valuation_standard_id.updateValueAndValidity();
    }

    /**
     * Returns prepared assignment
     */
    prepareItem(): any {
        const controls = this.form.controls;
        if (this.data.typeOfCheckbox === 'assumption') {
            const _item = new DefaultAssumption();
            _item.clear();
            _item.id = this.item.id;
            _item.selected = true;
            _item.name = controls.name.value;

            return _item;
        } else if (this.data.typeOfCheckbox === 'specialAssumption') {
            const _item = new SpecialAssumption();
            _item.clear();
            _item.id = this.item.id;
            _item.selected = true;
            _item.name = controls.name.value;

            return _item;
        } else {
            const _item = new Departure();
            _item.clear();
            _item.id = this.item.id;
            _item.selected = true;
            _item.name = controls.name.value;
            _item.valuation_standard_id = controls.valuation_standard_id.value;
            _item.valuation_standard_name = this.valuationStandards.filter(el => el.id == controls.valuation_standard_id.value)[0].name;

            return _item;
        }
    }

    createItem() {
        this.hasFormErrors = false;
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const _item = this.prepareItem();
        this.dialogRef.close({item: _item});
        // if (this.data.typeOfCheckbox === 'assumption') {
        //     // this.store.dispatch(new DefaultAssumptionOnServerCreated({defaultAssumption: _item}));
        //     this.dialogRef.close({item: _item});
        //     return;
        // } else if (this.data.typeOfCheckbox === 'specialAssumption') {
        //     // this.store.dispatch(new SpecialAssumptionOnServerCreated({specialAssumption: _item}));
        //     this.dialogRef.close({item: _item});
        //     return;
        // } else {
        //     // this.store.dispatch(new DepartureOnServerCreated({departure: _item}));
        //     this.dialogRef.close({item: _item});
        //     return;
        // }
    }

    isFormValid() {
        const controls = this.form.controls;

        return (controls.name.value);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }
}
