import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { ToeAuditTaskReminderModel } from "../_models/toe-audit-task-reminder.model";

export enum ToeAuditTaskReminderActionTypes {
    ToeAuditTaskRemindersRequested = '[Toe Dashboard / Audit Trail] Toe Audit Task Reminders requested',
    ToeAuditTaskRemindersLoaded = '[Toe Audit Task Reminder Effects] Toe Audit Task Reminders loaded' ,

    CreateToeAuditTaskReminder = '[Toe Dashboard / Audit Trail] Create Toe Audit Task Reminder',
    ToeAuditTaskReminderCreated = '[Toe Audit Task Reminder Effects] Toe Audit Task Reminder created',

    UpdateToeAuditTaskReminder = '[Toe Dashboard / Audit Trail] Update Toe Audit Task Reminder',
    ToeAuditTaskReminderUpdated = '[Toe Audit Task Reminder Effects] Toe Audit Task Reminder updated',

    DeleteToeAuditTaskReminder = '[Toe Dashboard / Audit Trail] Delete Toe Audit Task Reminder',
    ToeAuditTaskReminderDeleted = '[Toe Audit Task Reminder Effects] Toe Audit Task Reminder deleted'
}

export class ToeAuditTaskRemindersRequested implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.ToeAuditTaskRemindersRequested;
    constructor(public payload: {
        toe_id: number
    }) {}
}

export class ToeAuditTaskRemindersLoaded implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.ToeAuditTaskRemindersLoaded;

    constructor(public payload: {
        reminders: ToeAuditTaskReminderModel[]
    }) {}
}

export class CreateToeAuditTaskReminder implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.CreateToeAuditTaskReminder

    constructor(public payload: {
        reminder: ToeAuditTaskReminderModel
    }) {}
}

export class ToeAuditTaskReminderCreated implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.ToeAuditTaskReminderCreated

    constructor(public payload: {
        reminder: ToeAuditTaskReminderModel
    }) {}
}

export class UpdateToeAuditTaskReminder implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.UpdateToeAuditTaskReminder

    constructor(public payload: {
        reminder: ToeAuditTaskReminderModel
    }) {}
}

export class ToeAuditTaskReminderUpdated implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.ToeAuditTaskReminderUpdated

    constructor(public payload: {
        reminder: Update<ToeAuditTaskReminderModel>
    }) {}
}

export class DeleteToeAuditTaskReminder implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.DeleteToeAuditTaskReminder

    constructor(public payload: {
        id: number
    }) {}
}

export class ToeAuditTaskReminderDeleted implements Action {
    readonly type = ToeAuditTaskReminderActionTypes.ToeAuditTaskReminderDeleted

    constructor(public payload: {
        id: number
    }) {}
}

export type ToeAuditTaskReminderActions 
    = ToeAuditTaskRemindersRequested
    | ToeAuditTaskRemindersLoaded
    | CreateToeAuditTaskReminder
    | ToeAuditTaskReminderCreated
    | UpdateToeAuditTaskReminder
    | ToeAuditTaskReminderUpdated
    | DeleteToeAuditTaskReminder
    | ToeAuditTaskReminderDeleted