import { P } from "@angular/cdk/keycodes";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { tap, throwIfEmpty } from "rxjs/operators";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { awConst } from "src/app/app.constants";
import { AssetClassDetailModel } from "src/app/core/asset_class";
import { AssetClassModel } from "src/app/core/asset_class/_models/asset-class.model";
import { ReportingProcessData } from "src/app/core/reporting/_models/reporting_process.model";
import { TypesUtilsService } from "src/app/core/_base/crud";
import { ImageViewerDialogComponent } from "src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component";
import { environment } from "src/environments/environment";
import { ConditionRatingV2Service } from "../../../services/condition-rating-v2.service";
import { IPicture } from "../../reporting-settings/picture-selector/picture-selector-old.component";
import {
    IPic,
    PicType,
} from "../../reporting-settings/picture-selector/picture-selector.component";
import { ReportDesignModel } from "../../../report-design-page/report-design.model";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

@Component({
    selector: "kt-picture-selector-v2",
    templateUrl: "./picture-selector-v2.component.html",
    styleUrls: ["./picture-selector-v2.component.scss"],
})
export class PictureSelectorV2Component implements OnInit {
    @Input()
    readonly: boolean;
    @Input()
    targetPropertyDetail: AssetClassDetailModel;
    @Input()
    reporting: ReportDesignModel;
    @Input()
    selectedPics: IPic[];
    @Input()
    picType: PicType;
    @Input()
    displayFeatured$: Observable<boolean>

    @Output()
    picsWithSelectedValue = new EventEmitter<IPic[]>();

    public pics: IPic[] = [];
    public displayFeatured: boolean = false;

    public basePath = environment.baseApiUrl;
    awConst = awConst;
    firstTime = true;
    private _picsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

    public observablePics$: Observable<IPic>[] = [];
    private _selectionMap: Map<string, number> = new Map();
    private _pics$: BehaviorSubject<IPic[]> = new BehaviorSubject([]);
    public pics$ = this._pics$.asObservable()

    constructor(
        private dialog: MatDialog,
        public typesUtilsService: TypesUtilsService,
        private changeDetection: ChangeDetectorRef,
        private crService: ConditionRatingV2Service,
        private subDomainService: SubdomainService
    ) { }

    ngOnInit(): void {
        let propertyPictureOrderNum = 1;
        let conditionRatingPictureOrderNum = 1;
        let buildingPictureOrderNum = 1;

        const ratings = this.crService.getRatings();

        if (this.picType == PicType.Property) {
            this.targetPropertyDetail.ac_pictures
                .forEach((pic) => {
                    if (pic.rating_desc) {
                        this.pics.push(
                            this._getPicFromImage(
                                pic,
                                PicType.Rating,
                                conditionRatingPictureOrderNum,
                                this.firstTime ? 1 : 2,
                                pic.rating_desc,
                                ratings
                            )
                        );
                        conditionRatingPictureOrderNum += 1;
                    } else {
                        this.pics.push(
                            this._getPicFromImage(
                                pic,
                                PicType.Property,
                                propertyPictureOrderNum,
                                this.firstTime ? 1 : 0,
                                pic.rating_desc
                            )
                        );
                        propertyPictureOrderNum += 1;
                    }

                this.firstTime = false;
            });
        }

        if (this.picType == PicType.Building) {
            this.targetPropertyDetail.building_information.files
                // .filter((pic) => pic.is_featured == 0)
                .forEach((pic) => {
                    this.pics.push(
                        this._getPicFromImage(
                            pic,
                            PicType.Building,
                            buildingPictureOrderNum,
                            this.firstTime ? 1 : 0,
                            pic.rating_desc
                        )
                    );
                    buildingPictureOrderNum += 1;
                    this.firstTime = false;
                });
        }

        this.customInit();
        this._pics$.next(this.pics)
        this.picsWithSelectedValue.emit(this.pics);

        this.displayFeatured$.pipe(
        ).subscribe(val => {
            this.displayFeatured = val
            if (!val) {
                this.pics.forEach(pic => {
                    if (pic.selection_status == 1) {
                        pic.selection_status = 2
                    }
                })
            } else {
                if (this.pics.length > 0 && !(this.pics.find(p => p.selection_status == 1))) {
                    this.pics[0].selection_status = 1;
                    this.pics[0].selected = true;
                }
            }
            this._pics$.next(this.pics)
        })
    }

    customInit() {
        const BreakError = {};
        if (this.reporting.property_pictures.length > 0) {
            this.reporting.property_pictures.map((pPic) => {
                try {
                    this.pics.forEach((pic, index) => {
                        if (pic.id == pPic.pic_id) {
                            this.pics[index].selected = pPic.selection != 0;
                            this.pics[index].selection_status = pPic.selection;
                            throw BreakError;
                        }
                    });
                } catch (err) {}
            });
            //TODO property pics initialize
        }

        if (this.reporting.building_pictures.length > 0) {
            this.reporting.building_pictures.map((pPic) => {
                try {
                    this.pics.forEach((pic, index) => {
                        if (pic.id == pPic.pic_id) {
                            this.pics[index].selected = pPic.selection != 0;
                            this.pics[index].selection_status = pPic.selection;
                            throw BreakError;
                        }
                    });
                } catch (err) {}
            });
            //TODO property pics initialize
        }
    }

    public checkboxChange(event: MatCheckboxChange, pic: IPic) {
        if (event.checked) {
            const newPic: IPic = { ...pic, selected: true };
        }
    }

    radioGroupChange(data: MatRadioChange, pic: IPic) {
        // pic.selection_status = parseInt(data.value);
        // pic.selected = true;
        // let itemIndex = this.pics.findIndex((item) => item.id == pic.id);
        // this.pics[itemIndex] = pic;
        // this.changeDetection.detectChanges();
        // if (data.value == 1) {
        //     this.deselectPreviousFeaturedPic(pic);
        // } else {
        //     this.featuredAutomatically();
        // }
        // this.picsWithSelectedValue.emit(this.pics);
    }

    deselectPreviousFeaturedPic(aPic: IPic) {
        this.pics
            .filter((pic) => pic.selection_status == 1 && aPic.id != pic.id)
            .forEach((featuredPic) => {
                let itemIndex = this.pics.findIndex(
                    (item) => item.id == featuredPic.id
                );

                if (featuredPic.type == PicType.Rating) {
                    featuredPic.selected = true;
                    featuredPic.selection_status = 2;
                } else {
                    featuredPic.selected = false;
                    featuredPic.selection_status = 0;
                }

                this.pics[itemIndex] = featuredPic;
            });
    }

    featuredAutomatically() {
        if (this.pics.filter((pic) => pic.selection_status == 1).length == 0) {
            this.pics[0].selected = true;
            this.pics[0].selection_status = 1;
        }
    }

    public getSelectedPics(): IPic[] {
        return this.pics;
    }

    public picIsSelected(pic: IPic) {
        if (pic.selected === true) {
            return "picture-container1";
        } else {
            return "picture-container";
        }
    }

    private _getPicFromImage(
        img: any,
        picType: PicType,
        orderNum: number,
        selection_status: number = 0,
        rating_desc: string = "",
        ratings: any[] = []
    ): IPic {
        const rating = ratings.find(rating => {
            if (rating.pictures.length == 0) {
                return false;
            }

            const picture = rating.pictures.find(picture => {
                if (picture.name == img.name && picture.title == img.title && picture.path == img.path) {
                    return true
                }
                return false
            })
            if (picture) {
                return true
            }
            return false
        })
        return {
            id: img.id,
            type: picType,
            title: img.title ? img.title : "N/A",
            name: img.name,
            path: img.path,
            url: this._getImageUlr(img.path, img.name),
            selected: selection_status == 2 || this.firstTime,
            selection_status: selection_status,
            orderNum,
            rating_desc: rating_desc,
            rating: rating 
                ? {
                    title: `${rating.code} - ${rating.area} (Floor: ${rating.floor})`,
                    rate: rating.rating,
                    description: rating.desc
                }
                : null
        };
    }

    private _getImageUlr(path: string, name: string): string {
        return this.basePath + `api/${this.subDomainService.subDomain}/files/download?path=` + path + "/" + name;
    }

    public previewPicture(pic: IPic) {
        this.dialog.open(ImageViewerDialogComponent, {
            data: {
                title: pic.title,
                picture: pic.path + "/" + pic.name,
                type: this.typesUtilsService.getFileType(pic.name),
                rating_desc: pic.rating_desc,
                withoutViewer: true,
                viewer_type: 'Report Design',
                rating: pic.rating
            },
        });
    }

    //to stop previewing picture
    public clickOnRadio(e) {
        e.stopPropagation();
    }

    _getboldTitle(type: PicType, orderNum: number): any {
        throw new Error("Method not implemented.");
    }

    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND;
    }

    onSelectionChange(event: {status: number, selected: boolean}, pic: IPic) {
        pic.selection_status = event.status;
        pic.selected = event.selected;
        let itemIndex = this.pics.findIndex((item) => item.id == pic.id);
        this.pics[itemIndex] = pic;
        if (event.status == 1) {
            this.deselectPreviousFeaturedPic(pic);
        }
        this._pics$.next(this.pics)
        this.picsWithSelectedValue.emit(this.pics);
    }


    onRadioClick(event: 'right' | 'left', pic: IPic) {
        const value = event == 'right' ? 2 : 1;
        const preSelection = this._selectionMap.get(pic.url)
        let selection_status = value;
        let selected = true;
        if (preSelection == value) {
            selection_status = 0;
            selected = false;
        } 
        this._selectionMap.set(pic.url, selection_status);
        let itemIndex = this.pics.findIndex((item) => item.id == pic.id);
        this.pics[itemIndex] = pic;
        if (selection_status == 1) {
            this.deselectPreviousFeaturedPic(pic);
        } else {
            this.featuredAutomatically();
        }
        this._pics$.next(this.pics)
        this.picsWithSelectedValue.emit(this.pics);
    }
}
