<div class="card h-100">
    <div class="card-header section-header">
        <span>Milestones</span>
    </div>
    <div class="card-body section-body">
        <table class="table" *ngIf="data$|async as data">
            <tbody>
                <tr>
                    <th>Valuer :</th>
                    <td>
                        <span class="clickable"
                            [ngbPopover]="popContent"
                            [popoverTitle]="data.valuer.full_name"
                            triggers="manual"
                            placement="bottom"
                            container="body"
                            #pV="ngbPopover" (click)="showUserInfo($event, pV, data.valuer)">
                            {{data.valuer.full_name}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Draft Report (if any) :</th>
                    <td>{{data.draftDate 
                        ? (data.draftDate  | dateWithOffset:toe.timezone)
                        : 'N/A'}}
                    </td>
                </tr>
                <tr>
                    <th>Final Report :</th>
                    <td>{{data.finalDate  | dateWithOffset:toe.timezone}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #popContent 
    let-role="role" let-qualification="qualification"
    let-email="email" let-agency="agency" let-user_pic="user_pic" >
    <div style="display: flex; align-items: center;">
        <div class="image_container mr-3">
            <img [src]="user_pic" (error)="imgOnError($event)">
        </div>
        <div>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Role:</span> {{role}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Qualification:</span> {{qualification}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Email:</span> {{email}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Agency:</span> {{ agency}}</p>
        </div>
    </div>
</ng-template>