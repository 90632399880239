<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
        </div>
        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;"
                   (click)="onSubmit()"
                   class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px">
                    Save
                </a>
            </span>
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="form" [formGroup]="form">
        <div class="card-body">
            <div class="form-group row" *ngIf="!isEdit">
                <ng-container *ngIf="type === inputType.DefaultAssumption">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <h5 class="my-3 text-mad text-uppercase">Assumptions</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-12">
                        <div class="py-1" *ngFor="let assumption of assumptions">
                            <mat-checkbox 
                                [disabled]="assumption.selected"
                                [checked]="assumption.selected" 
                                (change)="updateAssumption(assumption)">
                                <span>{{assumption.name}}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="type === inputType.SpecialAssumption">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <h5 class="my-3 text-mad text-uppercase">Special Assumptions</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-12">
                        <div class="py-1" *ngFor="let assumption of specialAssumptions">
                            <mat-checkbox 
                                [disabled]="assumption.selected"
                                [checked]="assumption.selected" 
                                (change)="updateSAssumption(assumption)">
                                <span>{{assumption.name}}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="type === inputType.Departure">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <h5 class="my-3 text-mad text-uppercase">Departures</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-12">
                        <div class="py-1" *ngFor="let departure of departures">
                            <mat-checkbox 
                                [disabled]="departure.selected"
                                [checked]="departure.selected" 
                                (change)="updateDeparture(departure)">
                                <span><b>{{departure.valuation_standard_name}}</b> - {{departure.name}}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="!isEdit">
                        <h5 class="my-3 text-mad text-uppercase">New Input</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-8">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Input" formControlName="input"></textarea>
                            <mat-error >Input is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <div class="row" *ngIf="type === inputType.Departure">
                            <div class="col-lg-12">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Valuation Standard" formControlName="valuation_standard">
                                        <mat-option *ngFor="let vs of valuationStandards"
                                            [value]="vs.id">
                                            {{vs.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Valuation Standard <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="item.source === inputSource.ToE && isEdit">
                            <div class="col-lg-12">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Status" formControlName="status">
                                        <ng-container *ngFor="let enum of status | keyvalue">
                                            <mat-option *ngIf="!checkEnum(enum.key)" [value]="enum.key">
                                                {{enum.value}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4" >
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Status" formControlName="status">
                                <ng-container *ngFor="let enum of status | keyvalue">
                                    <mat-option *ngIf="!checkEnum(enum.key)" [value]="enum.key">
                                        {{enum.value}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                </div>
                <div class="row" *ngIf="isEdit && item.status !== status.Valid && item.source === inputSource.ToE">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Justification" formControlName="justification"
                                rows="2">
                            </textarea>
                            <mat-error>Justification is <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>