// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AssetClassType} from '../_models/asset-class-type.model';

export enum AssetClassTypeActionTypes {

    AllAssetClassTypesRequested = '[AssetClassTypes Home Page] All AssetClassTypes Requested',
    AllAssetClassTypesLoaded = '[AssetClassTypes API] All AssetClassTypes Loaded',

    AssetClassTypeOnServerCreated = '[Edit AssetClassType Dialog] AssetClassType On Server Created',
    AssetClassTypeCreated = '[Edit AssetClassTypes Dialog] AssetClassTypes Created',
    AssetClassTypeUpdated = '[Edit AssetClassType Dialog] AssetClassType Updated',
    AssetClassTypeDeleted = '[AssetClassTypes List Page] AssetClassType Deleted',

    AssetClassTypesPageRequested = '[AssetClassTypes List Page] AssetClassTypes Page Requested',
    AssetClassTypesPageLoaded = '[AssetClassTypes API] AssetClassTypes Page Loaded',
    AssetClassTypesPageCancelled = '[AssetClassTypes API] AssetClassTypes Page Cancelled',

    AssetClassTypesPageToggleLoading = '[AssetClassTypes page] AssetClassTypes Page Toggle Loading',
    AssetClassTypesActionToggleLoading = '[AssetClassTypes] AssetClassTypes Action Toggle Loading',

    // trash system

    AssetClassTypeOnServerRestored = '[AssetClassType Trash] AssetClassType On Server Restored',
    AssetClassTypeRestored = '[AssetClassType Trash] AssetClassType Restored',

    AssetClassTypeOnServerAdminRestored = '[AssetClassType Admin Trash] AssetClassType On Server Restored',
    AssetClassTypeAdminRestored = '[AssetClassType Admin Trash] AssetClassType Restored',

    AssetClassTypeDeletedFromTrash = '[AssetClassType Trash] AssetClassType deleted',
    AssetClassTypeDeletedFromAdminTrash = '[AssetClassType Admin Trash] AssetClassType deleted',

    AssetClassTypeTrash = 'AssetClassType Trashed',
    AssetClassTypeTrashFlushed = 'AssetClassType Trash Flushed',
}

export class AssetClassTypeOnServerCreated implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeOnServerCreated;

    constructor(public payload: { assetClassType: AssetClassType}) {
    }
}

export class AssetClassTypeCreated implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeCreated;

    constructor(public payload: { assetClassType: AssetClassType }) {
    }
}

export class AssetClassTypeUpdated implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeUpdated;

    constructor(public payload: {
        partialassetClassType: Update<AssetClassType>,
        assetClassType: AssetClassType
    }) {
    }
}

export class AssetClassTypeDeleted implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassTypesPageRequested implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassTypesPageLoaded implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypesPageLoaded;

    constructor(public payload: { assetClassTypes: AssetClassType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class AssetClassTypesPageCancelled implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypesPageCancelled;
}

export class AllAssetClassTypesRequested implements Action {
    readonly type = AssetClassTypeActionTypes.AllAssetClassTypesRequested;
}

export class AllAssetClassTypesLoaded implements Action {
    readonly type = AssetClassTypeActionTypes.AllAssetClassTypesLoaded;

    constructor(public payload: { assetClassTypes: AssetClassType[] }) {
    }
}

export class AssetClassTypesPageToggleLoading implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassTypesActionToggleLoading implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class AssetClassTypeDeletedFromAdminTrash implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class AssetClassTypeDeletedFromTrash implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassTypeOnServerRestored implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassTypeRestored implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeRestored;

    constructor(public payload: { item: AssetClassType }) {
    }
}

export class AssetClassTypeOnServerAdminRestored implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassTypeAdminRestored implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeAdminRestored;

    constructor(public payload: { item: AssetClassType }) {
    }
}

export class AssetClassTypeTrashFlushed implements Action {
    readonly type = AssetClassTypeActionTypes.AssetClassTypeTrashFlushed;

    constructor() {
    }
}

export type AssetClassTypeActions = AssetClassTypeCreated
    | AssetClassTypeUpdated
    | AssetClassTypeDeleted
    | AssetClassTypesPageRequested
    | AssetClassTypesPageLoaded
    | AssetClassTypesPageCancelled
    | AllAssetClassTypesLoaded
    | AllAssetClassTypesRequested
    | AssetClassTypeOnServerCreated
    | AssetClassTypesPageToggleLoading
    | AssetClassTypesActionToggleLoading
    | AssetClassTypeDeletedFromAdminTrash
    | AssetClassTypeDeletedFromTrash
    | AssetClassTypeOnServerRestored
    | AssetClassTypeRestored
    | AssetClassTypeOnServerAdminRestored
    | AssetClassTypeAdminRestored
    | AssetClassTypeTrashFlushed;
