<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{title$ | async}}
            </h3>
        </div>
        <div class="card-toolbar">
            <div class="mr-6">
                <mat-checkbox [checked]="true" (change)="show100Fields($event)">
                    Show only 100% matching fields
                </mat-checkbox>
            </div>
            <ng-container *ngIf="!data.isMulti">
                <ng-container *ngIf="comparableSelectionInfo$|async as info">
                    <span [matTooltip]="info.tooltip">
                        <a href="javascript:;"
                            [class.button-disabled]="info.disabled"
                            (click)="onComparableSelect(info.action, info.refNum)"
                            class="btn btn-icon btn-sm btn-primary mr-2 p-1" style="width: 80px">
                            {{info.buttonText}}
                        </a>
                    </span>
                </ng-container>
            </ng-container>
            <a href="javascript:;"
                (click)="onNoClick()"
                class="btn btn-icon btn-sm btn-warning p1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <ng-container *ngIf="viewModel$ | async as viewModel">
                <ng-container *ngIf="!(loading$ | async)">
                    <div class="col-12 section-margin-bottom-60 pr-0">
                        <ng-container >
                            <h6 class="text-right pb-2 text-info">
                                {{viewModel.points}} points of comparisons are available
                            </h6>
                        </ng-container>

                        <agm-map
                            [latitude]="viewModel.locationData['tp'].latitude"
                            [longitude]="viewModel.locationData['tp'].longitude"
                            [zoom]="15"
                            [disableDoubleClickZoom]="true"
                            [fitBounds]="true"
                            [scrollwheel]="null"
                            [gestureHandling]="'cooperative'"
                            [draggable]="false"
                            [clickableIcons]="false"
                            style="height: 400px">
                            <agm-map-type-control></agm-map-type-control>
                            <agm-marker
                                [agmFitBounds]="true"
                                [latitude]="viewModel.locationData['tp'].latitude"
                                [longitude]="viewModel.locationData['tp'].longitude"
                                [iconUrl]="tpIcon"></agm-marker>
                            <ng-container *ngFor="let refNum of viewModel.refNums">
                                <agm-marker
                                    [agmFitBounds]="true"
                                    [latitude]="viewModel.locationData[refNum].latitude"
                                    [longitude]="viewModel.locationData[refNum].longitude"
                                    [iconUrl]="icon"
                                    [label]="viewModel.locationData[refNum].label"
                                    ></agm-marker>
                                <agm-polyline [strokeColor]="'red'">
                                    <agm-polyline-point [latitude]="viewModel.locationData[refNum].latitude" [longitude]="viewModel.locationData[refNum].longitude"></agm-polyline-point>
                                    <agm-polyline-point [latitude]="viewModel.locationData['tp'].latitude" [longitude]="viewModel.locationData['tp'].longitude"></agm-polyline-point>
                                </agm-polyline>
                            </ng-container>
                        </agm-map>
                    </div>
                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">Information about Comparable</h5>
                        <hr class="active">
                    </div>
                    <kt-considerations-info-table-v2
                        [refNums]="viewModel.refNums"
                        [isMulti]="viewModel.refNums.length > 1"
                        [considerationsInfo]="viewModel.considerationsInfo"
                        (considerationChange)="onConsiderationChange($event)"
                        ></kt-considerations-info-table-v2>

                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">Lease Details</h5>
                        <hr class="active">
                    </div>
                    <div class="mat-table-wrapper pl-4">
                        <table class="table lmat-elevation-z8 table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col" [ngStyle]="{'width': 100 / (viewModel.refNums.length + 1) + '%'}">
                                        item
                                    </th>
                                    <th scope="col" *ngFor="let refNum of viewModel.refNums" [style.width]="100/(viewModel.refNums.length + 1) + '%'">
                                        {{ viewModel.refNums.length > 1 ? refNum : 'Comparable' }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Start Date</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].startDate ? (viewModel.leaseDetailsInfo[refNum].startDate|date:'dd MMM yyyy') : 'N/A' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Lease duration</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].leaseDuration ? (viewModel.leaseDetailsInfo[refNum].leaseDuration + ' (' + viewModel.leaseDetailsInfo[refNum].durationType + ')' ) : 'N/A' }} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rent Free period (month)</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].rentFreePeriod }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fitting out period (month)</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].fittingOutPeriod }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Capital Payment</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].capitalPayment }} {{ viewModel.leaseDetailsInfo[refNum].currency }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Write-off period after (month)</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].writeOffPeriod }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Break option after (month)</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].breakOptionAfter }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rent review type</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].rentReviewType }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rent review cycle (month)</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].rentReviewCycle }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rent review detail</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].rentReviewDetail ?? 'N/A' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Recoverable</td>
                                    <td *ngFor="let refNum of viewModel.refNums">
                                        {{ viewModel.leaseDetailsInfo[refNum].totalRecoverable }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">Information about Target Property Inspection</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.targetPropertyInspectionInfo,type:'target-property'}"></ng-container>

                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">Location</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.locationInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>

                    <ng-container *ngIf="viewModel.buildingInfo.exists">
                        <ng-container *ngIf="viewModel.buildingInfo.rows.length > 0">
                            <div class="col-12 pr-0">
                                <h5 class="my-3 text-mad text-uppercase">The Building</h5>
                                <hr class="active">
                            </div>
                            <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.buildingInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>
                        </ng-container>
                    </ng-container>

                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">About the property</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.aboutPropertyInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>

                    <ng-container *ngIf="viewModel.externalAspectsInfo.exists">
                        <ng-container *ngIf="viewModel.externalAspectsInfo.rows.length > 0">
                            <div class="col-12 pr-0">
                                <h5 class="my-3 text-mad text-uppercase">External Aspects</h5>
                                <hr class="active">
                            </div>
                            <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.externalAspectsInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="viewModel.internalAspectsInfo.exists">
                        <ng-container *ngIf="viewModel.internalAspectsInfo.rows.length > 0">
                            <div class="col-12 pr-0">
                                <h5 class="my-3 text-mad text-uppercase">Internal Aspects</h5>
                                <hr class="active">
                            </div>
                            <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.internalAspectsInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="viewModel.groundInfo.exists">
                        <ng-container *ngIf="viewModel.groundInfo.rows.length > 0">
                            <div class="col-12 pr-0">
                                <h5 class="my-3 text-mad text-uppercase">Grounds</h5>
                                <hr class="active">
                            </div>
                            <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.groundInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>
                        </ng-container>
                    </ng-container>

                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">Sizes</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.sizesInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>

                    <ng-container *ngIf="viewModel.parcelIdentificationInfo.exists">
                        <ng-container *ngIf="viewModel.parcelIdentificationInfo.rows.length > 0">
                            <div class="col-12 pr-0">
                                <h5 class="my-3 text-mad text-uppercase">Land information (Parcel Identification)</h5>
                                <hr class="active">
                            </div>
                            <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.parcelIdentificationInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="viewModel.parcelLandAreaInfo.exists">
                        <ng-container *ngIf="viewModel.parcelLandAreaInfo.rows.length > 0">
                            <div class="col-12 pr-0">
                                <h5 class="my-3 text-mad text-uppercase">Land information (Land Area)</h5>
                                <hr class="active">
                            </div>
                            <ng-container *ngTemplateOutlet="table;context:{rows:viewModel.parcelLandAreaInfo.rows,type:'both',refNums:viewModel.refNums}"></ng-container>
                        </ng-container>
                    </ng-container>

                </ng-container>
            </ng-container>
        </mat-dialog-content>
    </div>
</div>

<ng-template #table let-rows="rows" let-type="type" let-refNums="refNums">
    <div class="mat-table-wrapper pl-4">
        <table class="table lmat-elevation-z8 table-striped">
            <thead class="thead-dark">
                <tr>
                    <th scope="col" [ngStyle]="{'width': type != 'both' ? '33%' : (100/(refNums.length + 2) + '%')}">
                        Item
                    </th>
                    <ng-container [ngSwitch]="type">
                        <th scope="col" [style.width]="'50%'" *ngSwitchCase="'target-property'">
                            Target Property
                        </th>
                        <ng-container *ngSwitchCase="'both'">
                            <th scope="col" *ngFor="let refNum of refNums" [style.width]="100/(refNums.length + 2) + '%'">
                                {{refNums.length > 1 ? (refNum) : 'Comparable'}}
                            </th>
                            <th scope="col" [style.width]="100/(refNums.length + 2) + '%'">
                                Target Property
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of rows">
                    <tr [class.rowCnt]="row.type == 'countable'">
                        <td>
                            <b>{{row.header}}</b>
                        </td>
                        <ng-container [ngSwitch]="type">
                            <ng-container scope="col" *ngSwitchCase="'target-property'">
                                <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells['tp']}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'both'">
                                <ng-container *ngFor="let refNum of refNums">
                                    <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells[refNum],numOfAssets:refNums.length,refNum:refNum}"></ng-container>
                                </ng-container>
                                <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells['tp'],numOfAssets:refNums.length,refNum:'tp'}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #cell let-cell="cell" let-numOfAssets="numOfAssets" let-refNum="refNum">
    <td *ngIf="cell.kind == 'simple'">
        {{cell.value}}
    </td> 
    <td *ngIf="cell.kind == 'click'">
        <span class="clickable" (click)="cell.onClick()">
            {{cell.value}}
        </span>
    </td>
    <ng-container *ngIf="cell.kind == 'location'">
        <ng-container *ngIf="numOfAssets == 1">
            <td colspan="2" class="text-center" *ngIf="refNum != 'tp'" [innerHTML]="cell.value" ></td>
        </ng-container>
        <ng-container *ngIf="numOfAssets > 1">
            <td [innerHTML]="cell.value" *ngIf="refNum != 'tp'"></td>
            <td *ngIf="refNum == 'tp'" style="background-color: #ebebeb"></td>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="cell.kind == 'unknown'">
        <td [class.selected-com]="true">
            Unknown
        </td>
    </ng-container>
    <ng-container *ngIf="cell.kind == 'unknown-with-value'">
        <td [class.selected-com]="true">
            {{ cell.value }}
        </td>
    </ng-container>
    <ng-container *ngIf="cell.kind == 'na'">
        <td [class.selected-com]="true">
            Not Applicable
        </td>
    </ng-container>
    <ng-container *ngIf="cell.kind == 'not available'">
        <td [class.selected-com]="true">
            Not Available
        </td>
    </ng-container>
    <td *ngIf="cell.kind == 'picture'">
        <span class="pl-1 pr-3 cursor-pointer" *ngFor="let pic of cell.pictures; index as i" (click)="cell.onClick(i)">
            <img alt="Pic"
                style="align-self: center;
                    height: 34px;
                    width: 34px;
                    object-fit: cover;
                    border-radius: 3px;"
                [src]="pic.url"
                (error)="onImageError($event)" />
        </span>
    </td>
    <ng-container *ngIf="cell.kind == 'checklist'">
        <td>
            <ng-container *ngIf="cell.value">
                <mat-checkbox [checked]="cell.value" style="pointer-events: none;"></mat-checkbox>
            </ng-container>
            <ng-container *ngIf="!cell.value">
                <div class="unchecked_icon" >
                    <mat-icon>clear</mat-icon>
                </div>
            </ng-container>
        </td>
    </ng-container>
</ng-template>