import {Appendix} from '../_models/appendix.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AppendicesState} from '../_reducers/appendix.reducers';
import * as fromAppendix from '../_reducers/appendix.reducers';
import {each} from 'lodash';

export const selectAppendicesState = createFeatureSelector<AppendicesState>('appendices');

export const selectAppendixById = (appendixId: number) => createSelector(
    selectAppendicesState,
    appendicesState => appendicesState.entities[appendixId]
);

export const selectAllAppendices = createSelector(
    selectAppendicesState,
    fromAppendix.selectAll
);

export const selectAllAppendicesIds = createSelector(
    selectAppendicesState,
    fromAppendix.selectIds
);

export const allAppendicesLoaded = createSelector(
    selectAppendicesState,
    appendicesState => appendicesState.isAllAppendicesLoaded
);


export const selectAppendicesPageLoading = createSelector(
    selectAppendicesState,
    appendicesState => appendicesState.listLoading
);

export const selectAppendicesActionLoading = createSelector(
    selectAppendicesState,
    appendicesState => appendicesState.actionLoading
);


export const selectTrashedAppendicesCount = createSelector(
    selectAppendicesState,
    (appendicesState) => appendicesState.totalTrashed
);

export const selectAdminTrashedAppendicesCount = createSelector(
    selectAppendicesState,
    (appendicesState) => appendicesState.totalAdminTrashed
);

export const selectLastCreatedAppendixId = createSelector(
    selectAppendicesState,
    appendicesState => appendicesState.lastCreatedAppendixId
);

export const selectAppendicesShowInitWaitingMessage = createSelector(
    selectAppendicesState,
    appendicesState => appendicesState.showInitWaitingMessage
);


export const selectAppendicesQueryResult = createSelector(
    selectAppendicesState,
    appendicesState => {
        const items: Appendix[] = [];
        each(appendicesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Appendix[] = httpExtension.sortArrayString(
            items, appendicesState.lastQuery.sortField, appendicesState.lastQuery.sortOrder
        );

        return new QueryResultsModel(result, appendicesState.queryRowsCount, '');
    }
);
