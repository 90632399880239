import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'kt-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit, OnDestroy {
  result$: Observable<{
    loading: boolean,
    success: boolean,
    error: boolean,
  }>;

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.result$ = this.data;
  }

  ngOnInit(): void {}

  ngOnDestroy() {

  }

  close() {
    this.dialogRef.close();
  }

}
