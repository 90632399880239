import {BaseModel} from '../../_base/crud';
import { CountryVpgaModel } from './vpga.model';

export class CountryModel extends BaseModel {
    id: number;
    name: string;
    picture: string;

    macro_context_field: string;
    status_public_domain: string;
    vpgas: CountryVpgaModel[]
    default_timezone: string;

    currency_id: number;
    currency: string;
    vat: number;
    city_cnt: number;
    client_cnt: number;
    relation_cnt: number;

    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    deletedBy: string;
    userDeletedBy: string;

    createdBy: string;
    lastUpdatedBy: string;


    clear(): void {
        this.id = undefined;
        this.name = '';
        this.status_public_domain = '';
        this.macro_context_field = '';
        this.vpgas = [];
        this.default_timezone = null

        this.picture = './assets/media/users/default.jpg';

        this.currency_id = undefined;
        this.currency = '';
        this.vat = 0;
        this.city_cnt = 0;
        this.client_cnt = 0;
        this.relation_cnt = 0;

        this.deletedBy = '';
        this.userDeletedBy = '';

        this.user_deleted = null;
        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
