<!-- <div>
    <mat-label>
        Has this property got any facilities?
    </mat-label>
</div>
<div>
    <mat-radio-group formControlName="has_facility">
        <mat-radio-button 
            *ngFor="let enum of radioButtonValues | keyvalue" 
            [value]="enum.key">
            {{enum.value}}
        </mat-radio-button>
    </mat-radio-group>
</div> -->
<div [ngClass]="{'readonly': readonly}">
    <tag-input matInput
                class="facility"
                [(ngModel)]="currentFacilities"
                [identifyBy]="'id'"
                [displayBy]="'name'"
                (onRemove)="tagRemove($event)"
                (click)="chooseFacilities()"
                [placeholder]="getPlaceholder()"
                [secondaryPlaceholder]="getPlaceholder()"
                readonly="true">
    </tag-input>
</div>