import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AssetClassModel } from 'src/app/core/asset_class';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { ToeModel, ToeReportService } from 'src/app/core/toe';
import { AllToeReportTasksByToeIDRequested, DeleteReportTask, UpdateReportTaskReports } from 'src/app/core/toe/_actions/toe-report-task.actions';
import { NewCoiModel } from 'src/app/core/toe/_models/new-coi.model';
import { ToeAmendmentModel } from 'src/app/core/toe/_models/toe-amendment.model';
import { ToeReportTaskModel } from 'src/app/core/toe/_models/toe-report-task.model';
import { selectReportTasksByToeID } from 'src/app/core/toe/_selectors/toe-report-tasks.selectors';
import { InformedConsentReportService } from 'src/app/core/toe/_services/informed-consent-report.service';
import { NewCoiService } from 'src/app/core/toe/_services/new-coi.service';
import { RemoveTpAmendmentService } from 'src/app/core/toe/_services/remove-tp-amendment.service';
import { ToeAmendmentService } from 'src/app/core/toe/_services/toe-amendment.service';
import { LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { environment } from 'src/environments/environment';
import { ImageViewerDialogComponent } from '../../../shared_components/image_viewer/image-viewer.dialog.component';
import { AmendmentDialogInputData, AmendmentDialogV2Component } from '../../_sub/amendment-dialog-v2/amendment-dialog-v2.component';
import { CoiDialogComponent, CoiDialogInput, CoiDialogReturn } from '../../_sub/coi-dialog/coi-dialog.component';
import { EditReportTaskInfoComponent } from '../edit-report-task-info/edit-report-task-info.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

interface TpSelect {
  id: number;
  name: string;
}

@Component({
  selector: 'kt-toe-documents',
  templateUrl: './toe-documents.component.html',
  styleUrls: ['./toe-documents.component.scss']
})
export class ToeDocumentsComponent implements OnInit, OnDestroy {
  @Input() toe: ToeModel;
  @Input() toeID: number;
  @Input() leadValuerID: number;
  @Input() workers: User[];
  @Input() pm: User;
  @Input() targetProperties: AssetClassModel[];
  @Output() toeStatusEvent = new EventEmitter<number>();
  @Output() toeDocumentsIncompleteTaskCnt = new EventEmitter<any>();
  @Output() signedUploadDone = new EventEmitter<boolean>();
  @ViewChild(NgbDropdown) dropDown: NgbDropdown;

  status = Status;
  state = State;
  columns = ['task_name', 'download_doc', 'completion_status', 'assignee', 'actions'];
  dataSource = new MatTableDataSource<DataSourceInterface>([]);
  docs = ['xlsx', 'xls', 'doc', 'docx'];
  currentUser: User;
  tasks: ToeReportTaskModel[] = [];
  newCoi: NewCoiModel;
  toeAmendmentModel: ToeAmendmentModel;
  cancel$: Subject<void> = new Subject();
  showAddNewCoiBtn$: Observable<boolean>;
  toeSignedUploaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  informedConsentSignedUploaded$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  getInformedConsentsReportCMD$: Subject<void> = new Subject();
  getAmendmentReporCMD$: Subject<void> = new Subject();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  incomplete_task_cnt = 0;
  tps$: Observable<TpSelect[]>;
  constructor(
    private store: Store<AppState>,
    private toeReportService: ToeReportService,
    private informedConsentReportService: InformedConsentReportService,
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private newCoiService: NewCoiService,
    private toeAmendmentService: ToeAmendmentService,
    private removeTpAmendmentService: RemoveTpAmendmentService,
    private layoutUtilsService: LayoutUtilsService,
    public subDomainService: SubdomainService
  ) { 
    this.subDomainService.subDomain
  }

  ngOnInit(): void {
    this.removeTpAmendmentService.getTps(this.toeID);
    this.store.dispatch(new AllToeReportTasksByToeIDRequested({toeID: this.toeID}));
    this.newCoiService.getNewCoi(this.toeID).pipe(takeUntil(this.cancel$)).subscribe(res => this.newCoi = res);
    this.toeAmendmentService.getAmendment(this.toeID).pipe(takeUntil(this.cancel$)).subscribe(res => {
      this.toeAmendmentModel = res;
    });
    
    this.tps$ = this.removeTpAmendmentService.selectableTps().pipe(map(tps => {
      return tps.map(tp => {
        return {
          id: tp.tp_id,
          name: tp.tp_name
        }
      })
    }))

    this.showAddNewCoiBtn$ = combineLatest([
      of(this.toe),
      this.toeSignedUploaded$,
      this.informedConsentSignedUploaded$
    ]).pipe(map(([toe, toeSigned, informedConsentSigned]) => {
      return toe.status != 4 && toe.status != -1 && toeSigned && informedConsentSigned
    }))

    combineLatest([
      this.toeReportService.getAllToeReports(this.toeID)
        .pipe(map(res => res.data[0])),
      this.getInformedConsentsReportCMD$.pipe(
        switchMap(() => {
          return this.informedConsentReportService.getAllReports(this.toeID)
        }),
        map(res => res.data)
      ),
      this.getAmendmentReporCMD$.pipe(
        switchMap(() => this.toeAmendmentService.getAllReports(this.toeID)),
        map(res => res.data)
      ),
      this.store.select(selectReportTasksByToeID(this.toeID))
        .pipe(
          filter(tasks => tasks && tasks.length > 0),
          tap(tasks => {
            this.tasks = tasks;
            const notCompletedTaskIndx = tasks.findIndex(task => task.point != task.max_point);
            const hasCompletedAllTasks = notCompletedTaskIndx >= 0 ? false : true;
            this.toeStatusEvent.emit(hasCompletedAllTasks ? 2 : 1);
          })
        ),
      this.store.select(currentUser).pipe(filter(user => user !== null), tap(user => this.currentUser = user)),
      of(this.toe),
      this.toeAmendmentService.getAmendmentList(this.toeID).pipe(map(res => res.data)),
      this.tps$
    ]).pipe(
        takeUntil(this.cancel$),
        map(([toeReport, informedConsentReports, amendmentReports, tasks, user ,toe, amendments, tps]: [any, any[], any[], ToeReportTaskModel[], User, ToeModel, any[], TpSelect[]]) => {
          this.incomplete_task_cnt = 0;
          const currendDate = new Date();
          let amendmentDetail = null;

          const data: DataSourceInterface[] = [];
          tasks.forEach(task => {
            // Task completion status
            const taskExpDate = this.typesUtilsService.getDateFromString(task.date);
            const stat = this.getTaskStatus(task.point == task.max_point, currendDate, taskExpDate);

            // Task state
            let state = State.Initial;
            let reportURL = null;
            let additionalTaskInfo: AdditionalTaskInfo = null;
            let canBeDeleted = false;
            let canBeSeen = task.point == task.max_point;
            let canBeEdited = toe.status != 4 && toe.status != -1 && (user.id == this.leadValuerID || user.id == this.pm.id);
            // let canBeReached = toe.status != 4 && toe.status != -1 && (user.id == this.leadValuerID || user.id == this.pm.id || user.id == task.assignee_id);
            let canBeReached = toe.status != 4 && (user.id == this.leadValuerID || user.id == this.pm.id || user.id == task.assignee_id);
            this.incomplete_task_cnt += task.point != task.max_point ? 1 : 0;
            this.toeDocumentsIncompleteTaskCnt.emit({incomplete: this.incomplete_task_cnt, total: tasks.length});

            let canBeUserDeleted = false;
            if (task.task_id == 7) {
              reportURL = toeReport.url;
              this.toeSignedUploaded$.next(task.point == task.max_point);
            } else if (task.task_id == 8) {
              const informedConsentReport = informedConsentReports.find(r => r.id == task.generated_report_id);
              reportURL = informedConsentReport ? informedConsentReport.url : null;
              state = informedConsentReport 
                        ? informedConsentReport.state == 1
                          ? State.Additional
                          : State.Initial
                        : State.Additional;
              if (state == State.Initial) {
                this.informedConsentSignedUploaded$.next(task.point == task.max_point);
              }
              if (state == State.Additional) {
                additionalTaskInfo = {
                  createdDate: informedConsentReport ? informedConsentReport.created_at : null,
                  userID: informedConsentReport ? informedConsentReport.user_id : undefined,
                  userName: informedConsentReport 
                              ? informedConsentReport.user 
                                ? informedConsentReport.user.full_name 
                                : null 
                              : null,
                  taskId: task.task_id 
                }
                canBeDeleted = true;
                canBeUserDeleted = (additionalTaskInfo.userID == user.id || this.leadValuerID == user.id)
              }
            } else {
              const amendmentReport = amendmentReports.find(r => r.id == task.generated_report_id);
              reportURL = amendmentReport ? amendmentReport.url : null;
              state = amendmentReport 
                        ? amendmentReport.state == 1
                          ? State.Additional
                          : State.Initial
                        : State.Initial;
              if (state == State.Additional) {
                additionalTaskInfo = {
                  createdDate: amendmentReport ? amendmentReport.created_at : null,
                  userID: amendmentReport ? amendmentReport.user_id : undefined,
                  userName: amendmentReport 
                              ? amendmentReport.user 
                                ? amendmentReport.user.full_name 
                                : null 
                              : null,
                  taskId: task.task_id
                }
                canBeDeleted = true;
                canBeUserDeleted = (additionalTaskInfo.userID == user.id || this.leadValuerID == user.id) 
              }

              if (amendments.find(amendment => amendment.task_id == task.id) != undefined && amendments.find(amendment => amendment.task_id == task.id).amendments.filter(obj => obj.hasOwnProperty('toe_id')).length > 0) {
                amendmentDetail = 'Abort ToE';
              } else {
                if (amendments.find(amendment => amendment.task_id == task.id) != undefined){
                  amendmentDetail = tps
                    .filter(tp => amendments
                    .find(amendment => amendment.task_id == task.id).amendments
                    .map(obj => obj.tp_id ? obj.tp_id : obj.tp_ids)
                    .flatMap(num => num)
                    .includes(tp.id)).reduce((previousValue, currentValue) => (previousValue.length > 0 ? previousValue + ', ' : previousValue) + currentValue.name,'');
                }
              }
            }

            const d: DataSourceInterface = {
              taskID: task.id,
              taskName: 'Signed Document Upload',
              taskDetail: task.detail,
              taskState: state,
              additionalTaskInfo,
              generatedReportURL: reportURL,
              amendmentDetail: amendmentDetail,
              taskActions: {
                canBeUserDeleted,
                canBeDeleted,
                canBeEdited,
                canBeReached,
                canBeSeen
              },
              assignee: {
                id: task.assignee_id,
                firstName: task.assignee.first_name,
                lastName: task.assignee.last_name,
                name: task.assignee.full_name,
                picture: task.assignee.picture,
                email: task.assignee.email,
                agency: toe.assignment?.agency?.name,
                qualification: task.assignee.qualification_name,
                role: toeRole(task.assignee.id, this.pm?.id, this.leadValuerID, this.workers.filter(w => w.id === task.assignee.id).length > 0)
              },
              completion: {
                expectedDate: task.date,
                completedDate: task.updated_at,
                status: stat.status,
                remainingTime: stat.remainingTime,
                overdueTime: stat.overdueTime
              },
            }
            data.push(d);
          }) 
          return data;
        })
      )
      .subscribe(data => this.dataSource.data = data);

    this.getInformedConsentsReportCMD$.next();
    this.getAmendmentReporCMD$.next();
  }

  ngOnDestroy() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  public downloadReport(url: string) {
    if (url == null) {
      return;
    }
    window.open(url, '_blank');
  }

  public addConflictOfInterests() {
    const dialogRef = this.dialog.open<CoiDialogComponent, CoiDialogInput, CoiDialogReturn>(CoiDialogComponent, {
      data: {
        toe: this.toe,
        newCoi: this.newCoi,
        targetProperties: this.targetProperties,
        purposeOfValuationID: this.toe.purpose_of_valuation_id,
        hasPrevKnowledge: this.newCoi.has_prev_knowledge,
        hasFinancialInterest: this.newCoi.has_financial_interest
      },
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.loading$.next(true);
      this.newCoiService.updateNewCoi(res.model).pipe(takeUntil(this.cancel$)).subscribe(res => {
        this.loading$.next(false);
        this.newCoi = res;
        this.store.dispatch(new AllToeReportTasksByToeIDRequested({toeID: this.toeID}));
        this.getInformedConsentsReportCMD$.next();
      })
    })
  }
  public addAmendments() {
    const dialogRef = this.dialog.open<AmendmentDialogV2Component, AmendmentDialogInputData>(AmendmentDialogV2Component, {
      data: {
        toe: this.toe
      },
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.loading$.next(true);
      let obs: Observable<ToeAmendmentModel> = null;
      if (res.amendment.id == undefined) {
        obs = this.toeAmendmentService.storeAmendment(this.toeID, res.amendment);
      } else {
        obs = this.toeAmendmentService.updateAmendment(this.toeID, res.amendment);
      }
      obs.pipe(takeUntil(this.cancel$)).subscribe(res => {
        this.loading$.next(false);
        this.toeAmendmentModel = res;
        this.store.dispatch(new AllToeReportTasksByToeIDRequested({toeID: this.toeID}));
        this.getAmendmentReporCMD$.next();
      })
      return;
    })
  }
  public deleteTask(row: DataSourceInterface) {
    const _title = 'Warning';
    const _description = 'You are about to delete a signed amendment. This action is not reversible. Are you sure you want to proceed?';
    const _waitDesciption = '';

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.store.dispatch(new DeleteReportTask({id: row.taskID}));
      this.signedUploadDone.emit(true);
    });
  }
  public viewDocument(row: DataSourceInterface) {
    const reportTask = this.tasks.find(t => t.id === row.taskID);
    const report = reportTask ? reportTask.report.data.find(r => r.checked == 1) : null;
    if (!report) {
      return;
    }
    if (this.docs.indexOf(this.typesUtilsService.getFileType(report.url)) > -1) {
      window.open(environment.baseApiUrl + report.url);
    } else {
      this.dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: report.url,
          type: this.typesUtilsService.getFileType(report.url)
        }
      });
    }
  }
  public editTaskInformation(row: DataSourceInterface) {
    const _title = 'Change Information';
    const users: User[] = [];
    this.workers.forEach(w => users.push(w));

    const indx = users.findIndex(u => u.id == this.pm.id);
    if (indx < 0) {
      users.unshift(this.pm);
    }

    const reportTask = this.tasks.find(t => t.id == row.taskID);

    this.dialog.open(EditReportTaskInfoComponent, {
      data: {
        title: _title,
        currentUserId: row.assignee.id,
        currentDate: row.completion.expectedDate,
        users,
        task: reportTask,
        manager_id: this.pm.id,
        lead_valuer_id: this.leadValuerID,
        pmIsWorker: this.isWorker(this.pm.id) 
      },
      width: '440px'
    })

  }
  public uploadDocument(row: DataSourceInterface) {
    const reportTask = this.tasks.find(t => t.id === row.taskID);
    const dialogRef = this.dialog.open(FileUploadComponent, {
      data: {
        title: 'Signed documents',
        files: reportTask ? reportTask.report.data : [],
        taskID: reportTask ? reportTask.task_id : null,
        taskDBID: reportTask ? reportTask.id : null,
        toeID: this.toeID,
        userID: this.currentUser.id
      },
      minHeight: '150px',
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.cancel$)).subscribe(res => {
      if (!res) {
        return;
      }

      this.store.dispatch(new UpdateReportTaskReports({
        id: reportTask ? reportTask.id : undefined,
        reports: res,
        taskCompleted: res.length > 0
      }));
      this.signedUploadDone.emit(true);
    });
  }

  public onHover(event, type: string) {
    this.dropDown.toggle();
  }

  private isWorker(id: number) {
    const worker = this.workers.filter(w => w.id == id);
    return worker.length > 0;
  }

  private getTaskStatus(completed: boolean, currentDate: Date, expDate: Date): {
    status: Status,
    remainingTime: string,
    overdueTime: string,
  } {
    let status = null;
    let remainingTime: string = null;
    let overDueTime = null;
    if (this.isDatesAreSame(currentDate, expDate)) {
      status = Status.Today;
    } else {
      const diff = currentDate.getTime() - expDate.getTime();
      if (diff < 0) {
        status = Status.InProgress;
        remainingTime = this.getDaysString(Math.abs(diff));
      } else {
        status = Status.Overdue;
        overDueTime = this.getDaysString(diff);
      }
    }

    if (completed) {
      status = Status.Completed;
    }

    return {
      status,
      remainingTime,
      overdueTime: overDueTime
    }
  }

  private isDatesAreSame(a: Date, b: Date): boolean {
    return (
      a.getFullYear() == b.getFullYear() && a.getMonth() == a.getMonth() && a.getDate() == b.getDate()
    )
  }

  private getDaysString(diff: number): string {
    const days = Math.floor(diff / 1000 / 60 / 60 / 24);
    return `${days} day${days > 1 ? 's' : ''}`
  }
}

interface DataSourceInterface {
  taskID: number;
  taskName: string;
  taskDetail: string;
  taskState: State;
  taskActions: TaskActions;
  additionalTaskInfo: AdditionalTaskInfo;
  generatedReportURL: string;
  assignee: Assignee;
  completion: TaskCompletion;
  amendmentDetail: any;
}

interface TaskActions {
  canBeDeleted: boolean;
  canBeUserDeleted: boolean;
  canBeSeen: boolean;
  canBeEdited: boolean;
  canBeReached: boolean;
}

interface AdditionalTaskInfo {
  createdDate: string;
  userID: number;
  userName: string;
  taskId: number;
}

interface Assignee {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  picture: string;
  email: string;
  qualification: string;
  role: string;
  agency: string;
}

interface TaskCompletion {
  status: Status;
  expectedDate: string;
  remainingTime: string;
  overdueTime: string;
  completedDate: string;
}

enum Status {
  InProgress,
  Today,
  Overdue,
  Completed
}

enum State {
  Initial,
  Additional
}

function toeRole(userId: number, projectManagerId: number, leadValuerId: number, isWorker: boolean): string {
  if (userId == projectManagerId && projectManagerId == leadValuerId) {
      return 'Project manager & Lead Valuer';
  } else if (userId == projectManagerId && isWorker) {
      return 'Project manager & Supporting Staff'
  } else if (userId == projectManagerId) {
      return 'Project manager';
  } else if (userId == leadValuerId) {
      return 'Lead Valuer';
  }
  return 'Supporting Staff';
}