import {CubicContent} from '../_models/cubic-content.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {CubicContentsState} from '../_reducers/cubic-content.reducers';
import * as fromCubicContent from '../_reducers/cubic-content.reducers';
import {each} from 'lodash';

export const selectCubicContentsState = createFeatureSelector<CubicContentsState>('cubicContents');

export const selectCubicContentById = (cubicContentId: number) => createSelector(
    selectCubicContentsState,
    cubicContentsState => cubicContentsState.entities[cubicContentId]
);

export const selectAllCubicContents = createSelector(
    selectCubicContentsState,
    fromCubicContent.selectAll
);

export const selectAllCubicContentsIds = createSelector(
    selectCubicContentsState,
    fromCubicContent.selectIds
);

export const allCubicContentsLoaded = createSelector(
    selectCubicContentsState,
    cubicContentsState => cubicContentsState.isAllCubicContentsLoaded
);


export const selectCubicContentsPageLoading = createSelector(
    selectCubicContentsState,
    cubicContentsState => cubicContentsState.listLoading
);

export const selectCubicContentsActionLoading = createSelector(
    selectCubicContentsState,
    cubicContentsState => cubicContentsState.actionLoading
);

export const selectLastCreatedCubicContentId = createSelector(
    selectCubicContentsState,
    cubicContentsState => cubicContentsState.lastCreatedCubicContentId
);

export const selectCubicContentsShowInitWaitingMessage = createSelector(
    selectCubicContentsState,
    cubicContentsState => cubicContentsState.showInitWaitingMessage
);

export const selectTrashedCubicContentCount = createSelector(
    selectCubicContentsState,
    (cubicContentsState) => cubicContentsState.totalTrashed
);

export const selectAdminTrashedCubicContentCount = createSelector(
    selectCubicContentsState,
    (cubicContentsState) => cubicContentsState.totalAdminTrashed
);


export const selectCubicContentQueryResult = createSelector(
    selectCubicContentsState,
    cubicContentsState => {
        const items: CubicContent[] = [];
        each(cubicContentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CubicContent[] = httpExtension.sortArray(items, cubicContentsState.lastQuery.sortField, cubicContentsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, cubicContentsState.totalCount, '', cubicContentsState.totalTrashed);
    }
);
