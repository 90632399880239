<ng-container *ngIf="user$|async as user">
  <div class="img-wrapper" 
    [ngbPopover]="popContent" 
    [popoverTitle]="popTitle" 
    placement="right" 
    triggers="mouseenter:mouseleave"
    container="body"
    popoverClass="min-w-max-content">
    <span class="symbol-label">
      <img class="h-100 align-self-start" [src]="user.profilePic" (error)="onImageError($event)" />
    </span>
  </div>
  <ng-template #popTitle>
    {{user.name}}
  </ng-template>
  <ng-template #popContent>
    <div class="tooltip-content">
      <div *ngIf="user.role"><span>Role:</span> {{user.role}}</div>
      <div><span>Qualification:</span> {{user.qualification}}</div>
      <div><span>Email:</span> {{user.email}}</div>
      <div *ngIf="user.agency"><span>Agency:</span> {{user.agency}}</div>
    </div> 
  </ng-template>
</ng-container>