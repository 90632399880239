import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllOfficeAdjustmentsRequested, AllResidentialAdjustmentsLoaded, AllResidentialAdjustmentsRequested, selectAllOfficeAdjustments, selectAllResidentialAdjustments } from 'src/app/core/linked-tables';
import { AllHouseAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/house-adjustment.actions';
import { AllLandAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/land-adjustment.actions';
import { AllParkingAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/parking-adjustment.actions';
import { AllRetailBuildingAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/retail-building-adjustment.actions';
import { AllRetailStoreAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/retail-store-adjustment.actions';
import { AllWarehouseAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/warehouse-adjustment.actions';
import { selectAllHouseAdjustments } from 'src/app/core/linked-tables/_selectors/house-adjustment.selectors';
import { selectAllLandAdjustments } from 'src/app/core/linked-tables/_selectors/land-adjustment.selectors';
import { selectAllParkingAdjustments } from 'src/app/core/linked-tables/_selectors/parking-adjustment.selectors';
import { selectAllRetailBuildingAdjustments } from 'src/app/core/linked-tables/_selectors/retail-building-adjustment.selectors';
import { selectAllRetailStoreAdjustments } from 'src/app/core/linked-tables/_selectors/retail-store-adjustment.selectors';
import { selectAllWarehouseAdjustments } from 'src/app/core/linked-tables/_selectors/warehouse-adjustment.selectors';
import { AppState } from 'src/app/core/reducers';
import { AdjustmentModel } from 'src/app/core/valuation-process/_models/valuation-process-adjustment.model';

@Component({
  selector: 'kt-edit-adjustment-modal-v2',
  templateUrl: './edit-adjustment-modal-v2.component.html',
  styleUrls: ['./edit-adjustment-modal-v2.component.scss']
})
export class EditAdjustmentModalV2Component implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup

  adjustments: any[]

  private _onDestroy$: Subject<void> = new Subject()

  constructor(
    private dialogRef: MatDialogRef<EditAdjustmentModalV2Component>,
    @Inject(MAT_DIALOG_DATA) private data: {item: AdjustmentModel, propertySubTypeId: number},
    private store$: Store<AppState>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    switch(this.data.propertySubTypeId) {
      case 1:
        this.store$.dispatch(new AllResidentialAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllResidentialAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 3:
        this.store$.dispatch(new AllOfficeAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllOfficeAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 7:
        this.store$.dispatch(new AllWarehouseAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllWarehouseAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 17:
        this.store$.dispatch(new AllHouseAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllHouseAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 2:
        this.store$.dispatch(new AllParkingAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllParkingAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 5:
        this.store$.dispatch(new AllRetailStoreAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllRetailStoreAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 11:
        this.store$.dispatch(new AllRetailBuildingAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllRetailBuildingAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
      case 13:
        this.store$.dispatch(new AllLandAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllLandAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = res ? res : []
        })
        break;
    }


    this.formGroup = this.formBuilder.group({
      adjustment_id: new UntypedFormControl(this.data.item.adjustmentId, Validators.required),
      name: new UntypedFormControl(this.data.item.name),
      desc: new UntypedFormControl(this.data.item.description, Validators.required),
      analysis: new UntypedFormControl(this.data.item.analysis)
    })
    this.formGroup.get('adjustment_id').valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      if (res != 0) {
        const ad = this.adjustments.find(a => a.id == res)
        const val = ad ? ad.description : ''
        this.formGroup.get('desc').setValue(val)
        this.formGroup.get('desc').updateValueAndValidity()
      }
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next() 
    this._onDestroy$.complete()
  }

  onClose() {
    this.dialogRef.close()
  }

  onSubmit() {
    const controls = this.formGroup.controls
    if (this.formGroup.invalid) {
        Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
        );
        return;
    }

    const id = Number(controls.adjustment_id.value)
    const ad = this.adjustments.find(a => a.id == id)

    this.dialogRef.close({
      adjustmentId: id,
      name: id == 0 ? controls.name.value : (ad ? ad.name : controls.name.value),
      description: controls.desc.value,
      analysis: controls.analysis.value
    })
  }

}
