import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { User } from '../../mad-auth/mad-auth.store';
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

@Injectable()
export class UserService {
    API_URERS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/users`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAllUsers(roleId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const params = new HttpParams()
            .set('roleId', '' + roleId);
        return this.http.get<any>(this.API_URERS_URL, {headers: httpHeaders, params});
    }

    getUserById(userId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URERS_URL + `/${userId}`, {headers: httpHeaders});
    }

    public upload(data) {
        const uploadURL = `${this.API_URERS_URL}/upload`;

        return this.http.post<any>(uploadURL, data, {
            headers: this.authService.getAuthHeadersForUpload(),
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {

                switch (event.type) {

                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return {status: 'progress', message: progress};

                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;
                }
            })
        );
    }


    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${this.API_URERS_URL}/${userId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: User): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(`${this.API_URERS_URL}/${_user.id}`, _user, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_URERS_URL, user, {headers: httpHeaders});
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    
    findUsers(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };
        return this.http.post(this.API_URERS_URL + '/find', queryParams, {headers: httpHeaders, params});
    }

    // CHECK =>  POST: check email exists on the server
    checkUserEmail(userEmail: string): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            userEmail
        };
        return this.http.post(this.API_URERS_URL + '/email/exists', body, {headers: httpHeaders});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URERS_URL + '/trash', {
            headers: httpHeaders
        });
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URERS_URL + `/admin/trash`, {
            headers: httpHeaders
        });
    }

    deleteFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URERS_URL + `/trash/${id}`, {
            headers: httpHeaders
        });
    }

    // delete from admin trash
    deleteFromAdminTrash(keyPlaceId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URERS_URL + `/admin/trash/${keyPlaceId}`, {
            headers: httpHeaders
        });
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URERS_URL + `/trash/${id}`, {
            headers: httpHeaders
        });
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URERS_URL + '/trash/flush', {
            headers: httpHeaders
        });
    }

    updateUserStatus(body: {
        user_id: number,
        is_active: number
    }): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URERS_URL + '/' + body.user_id + '/status', body, {headers: httpHeaders});
    }

    fetchUsers() {
        return this.http.get<{data: {
            id: number,
            first_name: string,
            last_name: string,
            valuer_qualification: {
                id: number,
                name: string
            }
        }[]}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/users/listing`,
            {
                headers: this.authService.getAuthHeaders()
            }
        ).pipe(
            map(response => response.data)
        )
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
