// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Services
import {BathroomFittingsService} from '../_services/bathroom-fitting.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allBathroomFittingsLoaded} from '../_selectors/bathroom-fitting.selectors';
// Actions
import {
    AllBathroomFittingsLoaded,
    AllBathroomFittingsRequested,
    BathroomFittingActionTypes,
    BathroomFittingsPageRequested,
    BathroomFittingsPageLoaded,
    BathroomFittingUpdated,
    BathroomFittingsPageToggleLoading,
    BathroomFittingDeleted,
    BathroomFittingOnServerCreated,
    BathroomFittingCreated,
    BathroomFittingAdminRestored,
    BathroomFittingDeletedFromAdminTrash,
    BathroomFittingDeletedFromTrash,
    BathroomFittingOnServerAdminRestored,
    BathroomFittingOnServerRestored,
    BathroomFittingRestored,
    BathroomFittingTrashFlushed,
    BathroomFittingsActionToggleLoading
} from '../_actions/bathroom-fitting.actions';

@Injectable()
export class BathroomFittingEffects {
    showPageLoadingDispatcher = new BathroomFittingsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new BathroomFittingsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new BathroomFittingsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllBathroomFittings$ = this.actions$
        .pipe(
            ofType<AllBathroomFittingsRequested>(BathroomFittingActionTypes.AllBathroomFittingsRequested),
            withLatestFrom(this.store.pipe(select(allBathroomFittingsLoaded))),
            filter(([action, isAllBathroomFittingsLoaded]) => !isAllBathroomFittingsLoaded),
            mergeMap(() => this.service.getAllBathroomFittings()),
            map(res => {
                return new AllBathroomFittingsLoaded({bathroomFittings: res.data});
            })
        );

    @Effect()
    loadBathroomFittingsPage$ = this.actions$
        .pipe(
            ofType<BathroomFittingsPageRequested>(BathroomFittingActionTypes.BathroomFittingsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findBathroomFittings(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsDividedModel = response[1];
                return new BathroomFittingsPageLoaded({
                    bathroomFittings: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingDeleted>(BathroomFittingActionTypes.BathroomFittingDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteBathroomFitting(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingUpdated>(BathroomFittingActionTypes.BathroomFittingUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateBathroomFitting(payload.bathroomFitting);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingOnServerCreated>(BathroomFittingActionTypes.BathroomFittingOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createBathroomFitting(payload.bathroomFitting).pipe(
                    tap(res => {
                        this.store.dispatch(new BathroomFittingCreated({bathroomFitting: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushBathroomFittingTrash$ = this.actions$
        .pipe(
            ofType<BathroomFittingTrashFlushed>(BathroomFittingActionTypes.BathroomFittingTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingOnServerRestored>(BathroomFittingActionTypes.BathroomFittingOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BathroomFittingRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingOnServerAdminRestored>(BathroomFittingActionTypes.BathroomFittingOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BathroomFittingAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingDeletedFromTrash>(BathroomFittingActionTypes.BathroomFittingDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashBathroomFitting$ = this.actions$
        .pipe(
            ofType<BathroomFittingDeletedFromAdminTrash>(BathroomFittingActionTypes.BathroomFittingDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: BathroomFittingsService, private store: Store<AppState>) {
    }
}
