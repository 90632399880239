import { MapsAPILoader } from "@agm/core";
import { Location } from "@angular/common";
import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { each } from "lodash";
import { BehaviorSubject, Observable, of, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, switchMap, take, takeUntil } from "rxjs/operators";
import { AllCitiesRequested, AllCountriesRequested, CityModel, CountryModel, selectAllCountries, selectCitiesByCountryId } from "src/app/core/admin";
import { AssetClassConsiderationModel, AssetClassLandAreaComponentModel, AssetClassLandAreaModel, AssetClassLandUseModel, AssetClassTenureModel } from "src/app/core/asset_class";
import { AssetClassDetailLandParcelModel } from "src/app/core/asset_class/_models/asset-class-detail-land-parcel.model";
import { AssetClassBuildingInformation } from "src/app/core/comparable";
import { AssetClassLandOnServerCreated, AssetClassLandOnServerUpdated, UpdatePreviouslyCreatedAssetClassLandId } from "src/app/core/comparable/_actions/asset-class-land.actions";
import { AssetClassLandModel, AssetClassServiceInfrastructureModel } from "src/app/core/comparable/_models/asset-class-land.model";
import { selectAssetClassLandActionLoading, selectAssetClassLandById, selectLastCreatedAssetClassLandId } from "src/app/core/comparable/_selectors/asset-class-land.selectors";
import { AllAssetClassTypesRequested, AllCoordinateReferenceSystemsRequested, AllGradesRequested, AssetClassType, CoordinateReferenceSystem, Grade, selectAllAssetClassTypes, selectAllCoordinateReferenceSystems, selectAllGrades } from "src/app/core/linked-tables";
import { PropertyTypes } from "src/app/core/linked-tables/_models/top-property-type.model";
import { AppState } from "src/app/core/reducers";
import { LayoutUtilsService, MessageType, TypesUtilsService } from "src/app/core/_base/crud";
import { LandAreaListComponent } from "../../../shared_components/land-parcel/land-area/land-area-list/land-area-list.component";
import { LandParcelMapComponent } from "../../../shared_components/land-parcel/land-parcel-map/land-parcel-map.component";
import { LandUseComponent } from "../../../shared_components/land-parcel/land-use/land-use.component";
import { ServicesInfrastructuresComponent } from "../../../shared_components/land-parcel/services-infrastructures/services-infrastructures.component";
import { TenureConsiderationListComponent } from "../../../shared_components/land-parcel/tenure-consideration/tenure-consideration-list/tenure-consideration-list.component";
import { LpBuildingTabComponent } from "../../../shared_components/lp-building-tab/lp-building-tab.component";
import { MapLocationComponent } from "../../../shared_components/map-location/map-location.component";
import { UploadFileComponent } from "../../../shared_components/upload-file/upload-file.component";
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { AcDocumentUploadComponent } from "../../../shared_components/ac-document-upload/ac-document-upload.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ValuationCheckerModalComponent } from "../../_sub/valuation-checker-modal/valuation-checker-modal.component";
import { environment } from "src/environments/environment";

@Component({
  selector: 'kt-land-modal',
  templateUrl: './land-modal.component.html',
  styleUrls: ['./land-modal.component.scss']
})
export class LandModalComponent implements OnInit {
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('simple')
  public uploadFileComponent: UploadFileComponent;
  @ViewChild(TenureConsiderationListComponent)
  public tenureConsiderationListComponent: TenureConsiderationListComponent;
  @ViewChild(LandParcelMapComponent)
  landParcelMapComponent: LandParcelMapComponent;
  @ViewChild(LandAreaListComponent)
  public landAreaListComponent: LandAreaListComponent;
  @ViewChild(LandUseComponent)
  public landUseComponent: LandUseComponent;
  @ViewChild(ServicesInfrastructuresComponent)
  public servicesInfrastructuresComponent: ServicesInfrastructuresComponent;
  @ViewChild(LpBuildingTabComponent)
  public lpBuildingTabComponent: LpBuildingTabComponent;
  @ViewChild('lpUploadFile', { static: false }) lpUploadFile: UploadFileComponent;
  @ViewChild(MapLocationComponent, { static: false })
  mapLocationComponent: MapLocationComponent;
  @ViewChild(AcDocumentUploadComponent, { static: false })
  acDocumentUploadComponent: AcDocumentUploadComponent;

  acLand: AssetClassLandModel;

  loading$: Observable<boolean>;

  points: { lat: number, lng: number }[] = [];
  centerLat = null;
  centerLng = null;

  formGroup: UntypedFormGroup;
  hasFormErrors: boolean = false;
  errorMessage: string = null;
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  coordinateReferenceSystems: CoordinateReferenceSystem[] = [];
  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  grades: Grade[] = [];
  propertySubTypes: AssetClassType[] = [];
  filteredPropertySubTypes: AssetClassType[];

  considerationListSubject = new BehaviorSubject<AssetClassConsiderationModel[]>([]);
  tenureSubject = new BehaviorSubject<AssetClassTenureModel>(new AssetClassTenureModel());
  landAreaComponentListSubject = new BehaviorSubject<AssetClassLandAreaComponentModel[]>([]);
  landAreaSubject = new BehaviorSubject<AssetClassLandAreaModel>(new AssetClassLandAreaModel());
  landUseSubject = new BehaviorSubject<AssetClassLandUseModel>(new AssetClassLandUseModel());
  serviceInfrastructureSubject = new BehaviorSubject<AssetClassDetailLandParcelModel>(new AssetClassDetailLandParcelModel());
  selectFacilitiesSubject = new BehaviorSubject<any[]>([1, 11]);
  buildingsSubject = new BehaviorSubject<AssetClassBuildingInformation[]>([]);

  private _subscriptions: Subscription[] = [];
  private _onDestroy: Subject<void> = new Subject();
  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  tabHeaders: TabHeader[] = [
    { label: 'Location & land identification', disabled: of(false) },
    { label: 'Property information', disabled: of(false) },
    { label: 'Lease & sale information', disabled: of(false) },
    { label: 'Pictures & documents', disabled: of(false) },
  ];
  location_tab_items = ['name', 'coordinate_reference_system_id', 'property_type_id'];
  error: any = {
    msg: ' Missing Fields in total:',
    fields: []
  }
  private valuationId: number | null = null

  constructor(
    public typesUtilsService: TypesUtilsService,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private mapsAPILoader: MapsAPILoader,
    private layoutUtilsService: LayoutUtilsService,
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<LandModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.valuationId = data.valuationId;
  }

  /**
   * Life Cycles
   */
  ngOnInit() {
    this.acLand = new AssetClassLandModel();
    this.acLand.clear();
    this.points = this.acLand.points;
    this._creatForm();
    this.considerationListSubject.next(this.acLand.consideration);
    this.tenureSubject.next(this.acLand.tenure);
    this.landAreaComponentListSubject.next(this.acLand.land_area_components);
    this.landAreaSubject.next(this.acLand.land_area);
    this.landUseSubject.next(this.acLand.land_use);
    this.buildingsSubject.next(Object.assign([], this.acLand.buildings));
    this.serviceInfrastructureSubject.next(this._convertServiceInfraToLandParcel(this.acLand.service_infra));
    if (this.acLand.facilities && this.acLand.facilities.length > 0) {
      this.selectFacilitiesSubject.next(this.acLand.facilities.split(','));
    }

    this.loading$ = this.store.pipe(select(selectAssetClassLandActionLoading));

    // Map
    this.mapsAPILoader.load().then(() => {
      const autoComplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['(cities)']
      });
      autoComplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autoComplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
            this.centerLat = 47.91868658952473;
            this.centerLng = 106.91766668255616;
          } else {
            this.centerLat = place.geometry.location.lat();
            this.centerLng = place.geometry.location.lng();
          }
        })
      })
    })

    // Linked table data
    this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
    const coordinateReferenceSystemsSubscription = this.store.pipe(
      select(selectAllCoordinateReferenceSystems),
    ).subscribe(res => {
      this.coordinateReferenceSystems = [];
      if (res) {
        this.coordinateReferenceSystems = res;
      }
    });
    this._subscriptions.push(coordinateReferenceSystemsSubscription);

    this.store.dispatch(new AllGradesRequested());
    const gradeSubscription = this.store.pipe(
      select(selectAllGrades))
      .subscribe(res => {
        this.grades = [];
        if (res) {
          this.grades = res;
        }
      });
    this._subscriptions.push(gradeSubscription);

    this.store.dispatch(new AllAssetClassTypesRequested());
    this.store.pipe(
      select(selectAllAssetClassTypes))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.propertySubTypes = [];
        if (res) {
          this.propertySubTypes = res;
          this.filteredPropertySubTypes = res;
          this.filterPropertySubTypes(PropertyTypes.Land);
        }
      });
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  filterPropertySubTypes(typeID) {
    if (typeID == PropertyTypes.Custom) {
      this.filteredPropertySubTypes = [];
    } else {
      this.filteredPropertySubTypes = this.propertySubTypes.filter(t => t.top_property_type_id == typeID);
    }
  }

  /**
   * Actions
   */
  back() {
    this.location.back();
  }
  onSubmit(isComplete: boolean) {
    let errorFields = [];
    this.hasFormErrors = false;
    this.showTitleRequiredError$.next(false);

    if (isComplete == false) {
      this._changeValidation(isComplete);
    }

    if (this.mapLocationComponent && this.landParcelMapComponent.getPath().length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'draw'];
    }

    if (this.mapLocationComponent) {
      if (isComplete && this.mapLocationComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
      }
    }
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).filter(cName => this.location_tab_items.includes(cName)).forEach(controlName => {
        if (!this.formGroup.controls[controlName].valid) {
          errorFields = [...errorFields, controlName];
        }
        this.formGroup.controls[controlName].markAsTouched();
      });
      this.hasFormErrors = true;
    }

    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).filter(cName => !this.location_tab_items.includes(cName)).forEach(controlName => {
        if (!this.formGroup.controls[controlName].valid) {
          errorFields = [...errorFields, controlName];
        }
        this.formGroup.controls[controlName].markAsTouched();
      });
      this.hasFormErrors = true;
    }

    this.tenureConsiderationListComponent && this.tenureConsiderationListComponent.updateValue(isComplete);
    if (isComplete && !this.tenureConsiderationListComponent.valid()) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'consideration'];
    }

    if (isComplete && this.uploadFileComponent.uploadFiles.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'pic'];
    } else {
      const uploadedFiles = this.uploadFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasFormErrors = true;
          this.showTitleRequiredError$.next(true);
          errorFields = [...errorFields, 'pic'];
        }
      })
    }

    if (this.hasFormErrors) {
      this.error.fields = errorFields;
      return;
    }

    this.landAreaListComponent && this.landAreaListComponent.updateValue(isComplete);
    this.landUseComponent && this.landUseComponent.updateValue(isComplete);
    this.servicesInfrastructuresComponent && this.servicesInfrastructuresComponent.updateValue(isComplete);
    const acLand = this._prepareAcLand({ isComplete });

    const dialogRef = this.dialog.open(ValuationCheckerModalComponent, {
      width: '400px',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == undefined) {
        return;
      }
      acLand.valuation_id = res ? this.valuationId : null;
      if (acLand.id) {
        this._updateAssetClass(acLand);
      } else {
        this._createAssetClass(acLand);
      }
    })

  }
  onHasFormErrorsChange(obj: { hasFormErrors: boolean }) {
    this.hasFormErrors = obj.hasFormErrors;
  }
  onSelectCountry() {
    const countryId = this.formGroup.controls.country_id.value;
    this.store.pipe(takeUntil(this._onDestroy), select(selectCitiesByCountryId(countryId)))
      .subscribe((res: CityModel[]) => {
        this.cityOfCountry = res;
      })
  }


  /**
   * Template Functions
   */
  getComponentTitle(): string {
    if (this.acLand && this.acLand.id) {
      return `Edit Land "${this.acLand.ref_num}"`
    }
    return 'Add Land'
  }

  /**
   * Methods
   */
  private _creatForm() {
    this.formGroup = this.formBuilder.group({
      name: [this.acLand.name, Validators.required],
      coordinate_reference_system_id: [this.acLand.coordinate_reference_system_id, Validators.required],

      property_type_id: [this.acLand.property_sub_type_id, Validators.required],
    });
  }
  private _prepareAcLand(input: { isComplete: boolean } = { isComplete: false }): AssetClassLandModel {
    const controls = this.formGroup.controls;
    const _acLand = new AssetClassLandModel();
    _acLand.clear();

    _acLand.id = this.acLand.id;
    _acLand.status = Number(input.isComplete);

    _acLand.property_sub_type_id = controls.property_type_id.value;

    _acLand.name = controls.name.value;
    _acLand.coordinate_reference_system_id = controls.coordinate_reference_system_id.value;
    _acLand.points = this.landParcelMapComponent ? this.landParcelMapComponent.getPath() : [];
    const bounds = new google.maps.LatLngBounds();
    const filtered = _acLand.points.filter(p => p.lat != null && p.lng != null);
    filtered.forEach(p => bounds.extend(p));
    _acLand.latitude = bounds.getCenter().lat();
    _acLand.longitude = bounds.getCenter().lng();

    if (this.mapLocationComponent) {
      _acLand.locationData = this.mapLocationComponent.getData();
    }

    _acLand.consideration = this.considerationListSubject.value;
    _acLand.tenure = this.tenureSubject.value;
    _acLand.land_area_components = this.landAreaComponentListSubject.value;
    _acLand.land_area = this.landAreaSubject.value;
    _acLand.land_use = this.landUseSubject.value;
    _acLand.buildings = this.lpBuildingTabComponent ? this.buildingsSubject.value : [];
    _acLand.facilities = this.selectFacilitiesSubject.value.join(',');
    _acLand.service_infra.road_access = this.serviceInfrastructureSubject.value.road_access;
    _acLand.service_infra.status_and_quality = this.serviceInfrastructureSubject.value.status_and_quality;
    _acLand.service_infra.access_points = this.serviceInfrastructureSubject.value.access_points;
    _acLand.service_infra.road_length = this.serviceInfrastructureSubject.value.road_length;
    _acLand.service_infra.owner_of_another_consent = this.serviceInfrastructureSubject.value.owner_of_another_consent;
    _acLand.service_infra.specify_other_service = this.serviceInfrastructureSubject.value.specify_other_service;

    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
      if (value) {
        _acLand.picture = value.success;
      }
    })

    if (this.lpUploadFile) {
      _acLand.building_pictures = this.lpUploadFile.uploadFiles;
      this.lpUploadFile.res$.pipe(take(1)).subscribe(value => {
        if (value) {
          _acLand.building_picture = value.success;
        }
      })
    }

    if (this.acDocumentUploadComponent) {
      _acLand.documents = this.acDocumentUploadComponent.getDocuments();
    }

    return _acLand;
  }
  private _setNullWhenZero(value: any) {
    if (value === undefined || value === '0' || value === 0 || value === -1) {
      return null;
    } else {
      return value;
    }
  }
  private _setNullWhenNegOne(value: any) {
    if (value === -1) {
      return null;
    }
    return value;
  }

  private _convertServiceInfraToLandParcel(item: AssetClassServiceInfrastructureModel): AssetClassDetailLandParcelModel {
    const _newItem = new AssetClassDetailLandParcelModel();
    _newItem.clear();
    _newItem.road_length = item.road_length;
    _newItem.road_access = item.road_access;
    _newItem.access_points = item.access_points;
    _newItem.owner_of_another_consent = item.owner_of_another_consent;
    _newItem.status_and_quality = item.status_and_quality;
    _newItem.specify_other_service = item.specify_other_service;
    return _newItem;
  }
  private _updateAssetClass(ac: AssetClassLandModel) {
    this.store.dispatch(new AssetClassLandOnServerUpdated({
      assetClassLand: ac,
      fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.layoutUtilsService.showActionNotification('Saved the changes', MessageType.Update, 3000, true, false);
    this.location.back();
  }
  private _createAssetClass(ac: AssetClassLandModel) {
    this.store.dispatch(new AssetClassLandOnServerCreated({
      assetClassLand: ac,
      fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : []
    }));
    this.store.pipe(
      select(selectLastCreatedAssetClassLandId),
      takeUntil(this._onDestroy),
      distinctUntilChanged(),
      switchMap(res => {
        if (!res) {
          return of(undefined)
        }
        this.store.dispatch(new UpdatePreviouslyCreatedAssetClassLandId())
        return this.store.select(selectAssetClassLandById(res))
      })
    ).subscribe(ac => {
      if (!ac) {
        return;
      }
      this.dialogRef.close(ac);
    })
  }
  onTabChanged($event) {
    const activeIndex = $event.index;
  }
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  changeTab(section: string) {
    const section_tab = [
      { sections: ['location', 'parcel'], tab_index: 0 },
      { sections: ['prop'], tab_index: 1 },
      { sections: ['consideration'], tab_index: 2 },
      { sections: ['pic'], tab_index: 3 },
    ]
    const active_Tab = section_tab.find(item => item.sections.includes(section));
    this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
    this.selectedtabchange.next(this.selectedTab);
  }
  erroTabMap() {
    return this.typesUtilsService.getCompTabErrorMap(false, this.error.fields, this.tabHeaders, undefined);
  }
  onCountryChange(evt: { countryCode: string }) {
    this.mapLocationComponent.setCountry(evt.countryCode);
  }
  private _changeValidation(isComplete: boolean) {
    const controls = this.formGroup.controls;
    const mustCnames = ['country_id', 'city_id', 'location_grade_id', 'address', 'sub_type_category_id',
      'sub_category_id', 'property_grade_id', 'completion_year', 'state_of_repair_id', 'handover_standard_id',
      'above_floors'];
    Object.keys(controls).forEach(cName => {
      if (isComplete && mustCnames.filter(item => item == cName).length == 1) {
        return;
      }
      controls[cName].clearValidators();
      controls[cName].updateValueAndValidity();
    });
  }
  cancel() {

  }
}