<!-- <button (click)="showToeDoc()">click</button> -->
<ng-container *ngIf="toeDocs$|async as toeDoc">
	<p><b>1 - Agency ({{toeDoc.agency.name}}): available documents: </b></p>
	<div class="w-100" *ngFor="let cert of toeDoc.agency.documents; let i = index" [ngClass]="{'readonly': readonly}">
		<mat-checkbox [checked]="cert.selected" (change)="certificateChange($event, 'agency', 'document', cert.id)">
			{{cert.name}}
		</mat-checkbox>
	</div>

	<p class="mt-8"><b>2 - Lead Valuer ({{toeDoc.leadValuer.name}}): available certifications and documents:</b></p>
	<div class="w-100" *ngFor="let cert of toeDoc.leadValuer.certificates" [ngClass]="{'readonly': readonly}">
		<mat-checkbox [checked]="cert.selected" (change)="certificateChange($event, 'leadValuer', 'certificate', cert.id)">
			{{cert.name}}
		</mat-checkbox>
	</div>
	<div class="w-100" *ngFor="let cert of toeDoc.leadValuer.documents" [ngClass]="{'readonly': readonly}">
		<mat-checkbox [checked]="cert.selected" (change)="certificateChange($event, 'leadValuer', 'document', cert.id)">
			{{cert.name}}
		</mat-checkbox>
	</div>
	<p *ngIf="toeDoc.leadValuer.documents.length == 0 && toeDoc.leadValuer.certificates.length == 0">No file available for that user</p>

	<ng-container *ngFor="let member of toeDoc.members; let i = index">
		<p class="mt-8"><b>{{i + 2}} - Support Valuer ({{member.name}}): available certifications and documents:</b></p>
		<div class="w-100" *ngFor="let cert of member.certificates" [ngClass]="{'readonly': readonly}">
			<mat-checkbox [checked]="cert.selected" (change)="certificateChange($event, 'member', 'certificate', cert.id, member.id)">
				{{cert.name}}
			</mat-checkbox>
		</div>
		<div class="w-100" *ngFor="let cert of member.documents" [ngClass]="{'readonly': readonly}">
			<mat-checkbox [checked]="cert.selected" (change)="certificateChange($event, 'member', 'document', cert.id, member.id)">
				{{cert.name}}
			</mat-checkbox>
		</div>
		<p *ngIf="member.documents.length == 0 && member.certificates.length == 0">No file available for that user</p>
	</ng-container>

	<ng-container *ngIf="toeDoc.otherDocs.length > 0">
		<p class="mt-8"><b>{{ toeDoc.members.length > 0 ? (toeDoc.members.length + 2) : 3 }} - Other ToE related documents</b></p>
		<div class="w-100" *ngFor="let doc of toeDoc.otherDocs" [ngClass]="{'readonly': readonly}">
			<mat-checkbox [checked]="doc.selected" (change)="certificateChange($event, 'other', 'document', doc.id)">
				{{doc.name}}
			</mat-checkbox>
		</div>
	</ng-container>
</ng-container>