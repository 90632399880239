<div class="form" *ngIf="deliveryTask$|async" [ngClass]="{'form-disabled': readonly}">
    <form [formGroup]="form">
        <div class="form-group row align-items-center">
            <div class="col-4">
                <strong>{{name}}</strong>
                <p>{{type}}</p>
            </div>
            <div class="col-4">
                <mat-checkbox formControlName="delivered">Delivered</mat-checkbox>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <span (click)="datePicker.open()">
                        <input matInput [matDatepicker]="datePicker" readonly 
                            placeholder="Date"
                            formControlName="delivered_at">
                    </span>
                    <mat-error>Date is <strong>required</strong></mat-error>
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <button mat-button 
                        *ngIf="form.controls.delivered_at" matSuffix mat-icon-button matTooltip="Clear"
                        (click)="clearDate(form.controls.delivered_at)">
                        <mat-icon>close</mat-icon> 
                    </button>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>