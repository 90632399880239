import {BaseModel} from '../../_base/crud';

export class LandAdjustmentModel extends BaseModel {
    id: number;
    name: string;
    description: string;
    default_description: string;
    relation_cnt: number;
    is_hidden: boolean;
    is_system_entry: boolean;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    deletedBy: string;
    user_deleted: Date;
    userDeletedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.description = '';
        this.default_description = ''
        this.relation_cnt = 0;
        this.is_hidden = false
        this.is_system_entry = false

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;

        this.deletedBy = '';
        this.userDeletedBy = '';
        this.user_deleted = new Date();
    }

    static copyWith(o: LandAdjustmentModel): LandAdjustmentModel {
        const model = new LandAdjustmentModel()
        model.id = o.id
        model.name = o.name
        model.description = o.description
        model.default_description = o.default_description
        model.relation_cnt = o.relation_cnt
        model.is_hidden = o.is_hidden
        model.is_system_entry = o.is_system_entry
        return model
    }
}