import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-color-picker-modal',
  templateUrl: './color-picker-modal.component.html',
  styleUrls: ['./color-picker-modal.component.scss']
})
export class ColorPickerModalComponent implements OnInit {
  color: null;
  private _color: null;
  triggerElementRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ColorPickerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.triggerElementRef = data.trigger;
    this.color = data.color;
    this._color = data.color;
  }

  ngOnInit(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();

    if(this._isPossibleToPutOnTop(rect)) {
      matDialogConfig.position = {
        bottom: `${window.innerHeight - (rect.top)}px`,
        left: `${rect.left + (rect.width / 2) - 100}px`
      };
    } else {
      matDialogConfig.position = {
        top: `${(rect.bottom)}px`,
        left: `${rect.left + (rect.width / 2) - 100}px`
      }
    }

    this.dialogRef.updatePosition(matDialogConfig.position);

    this.dialogRef.backdropClick().subscribe(res => {
      this.dialogRef.close({color: this._color});
    })
  }

  _isPossibleToPutOnTop(rect): boolean {
    if (rect.top < (470)) {
      return false;
    }
    return true;
  } 

  onChange(event) {
    this._color = event.color.hex;
  }
}
