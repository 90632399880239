// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SolUseClassificationsService} from '../_services/sol-use-classification.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSolUseClassificationsLoaded} from '../_selectors/sol-use-classification.selectors';
// Actions
import {
    AllSolUseClassificationsLoaded,
    AllSolUseClassificationsRequested,
    SolUseClassificationActionTypes,
    SolUseClassificationsPageRequested,
    SolUseClassificationsPageLoaded,
    SolUseClassificationUpdated,
    SolUseClassificationsPageToggleLoading,
    SolUseClassificationDeleted,
    SolUseClassificationOnServerCreated,
    SolUseClassificationCreated,
    SolUseClassificationAdminRestored,
    SolUseClassificationDeletedFromAdminTrash,
    SolUseClassificationDeletedFromTrash,
    SolUseClassificationOnServerAdminRestored,
    SolUseClassificationOnServerRestored,
    SolUseClassificationRestored,
    SolUseClassificationTrashFlushed,
    SolUseClassificationsActionToggleLoading
} from '../_actions/sol-use-classification.actions';

@Injectable()
export class SolUseClassificationEffects {
    showPageLoadingDispatcher = new SolUseClassificationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SolUseClassificationsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SolUseClassificationsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSolUseClassifications$ = this.actions$
        .pipe(
            ofType<AllSolUseClassificationsRequested>(SolUseClassificationActionTypes.AllSolUseClassificationsRequested),
            withLatestFrom(this.store.pipe(select(allSolUseClassificationsLoaded))),
            filter(([action, isAllSolUseClassificationsLoaded]) => !isAllSolUseClassificationsLoaded),
            mergeMap(() => this.service.getAllSolUseClassifications()),
            map(res => {
                return new AllSolUseClassificationsLoaded({solUseClassifications: res.data});
            })
        );

    @Effect()
    loadSolUseClassificationsPage$ = this.actions$
        .pipe(
            ofType<SolUseClassificationsPageRequested>(SolUseClassificationActionTypes.SolUseClassificationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findSolUseClassifications(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SolUseClassificationsPageLoaded({
                    solUseClassifications: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationDeleted>(SolUseClassificationActionTypes.SolUseClassificationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteSolUseClassification(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationUpdated>(SolUseClassificationActionTypes.SolUseClassificationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateSolUseClassification(payload.solUseClassification);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationOnServerCreated>(SolUseClassificationActionTypes.SolUseClassificationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createSolUseClassification(payload.solUseClassification).pipe(
                    tap(res => {
                        this.store.dispatch(new SolUseClassificationCreated({solUseClassification: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushSolUseClassificationTrash$ = this.actions$
        .pipe(
            ofType<SolUseClassificationTrashFlushed>(SolUseClassificationActionTypes.SolUseClassificationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationOnServerRestored>(SolUseClassificationActionTypes.SolUseClassificationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SolUseClassificationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationOnServerAdminRestored>(SolUseClassificationActionTypes.SolUseClassificationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SolUseClassificationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationDeletedFromTrash>(SolUseClassificationActionTypes.SolUseClassificationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSolUseClassification$ = this.actions$
        .pipe(
            ofType<SolUseClassificationDeletedFromAdminTrash>(SolUseClassificationActionTypes.SolUseClassificationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: SolUseClassificationsService, private store: Store<AppState>) {
    }
}
