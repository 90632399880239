import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassModel, selectTpTasksByTpId, ValuationModel } from 'src/app/core/asset_class';
import { AssignmentModel } from 'src/app/core/assignment';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

interface TemplateData {
  valuer: User;
  draftDate: string; 
  finalDate: string;
}

@Component({
  selector: 'kt-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss', '../../section.styles.scss']
})
export class MilestonesComponent implements OnInit {
  @Input() valuation: ValuationModel;
  @Input() toe: ToeModel;
  @Input() assignment: AssignmentModel;
  @Input() workers: any[];
  @Input() tp: AssetClassModel;
  data$: Observable<TemplateData>;
  awConst = awConst;
  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      of(this.tp),
      this.store$.select(selectTpTasksByTpId(this.tp.id)).pipe(map(val => val ? val : undefined), filter(val => val !== undefined)),
      this.activatedRoute.data.pipe(map(res => res.usersData.data ? res.usersData.data : undefined), filter(val => val !== undefined))
    ]).pipe(map(([tp, tasks, toeUsers]) => {
      const valuationTasks = tasks.filter(t => t.task_id === 2);
      const valuationTask = valuationTasks.find(t => t.second_id == this.valuation.id);
      const valuer = toeUsers.find(u => u.id === valuationTask.user_id);
      const data: TemplateData = {
        valuer: valuer,
        draftDate: tp.draft_date,
        finalDate: tp.valuation_date,
      };
      return data;
    }))
  }

  showUserInfo(event, popover: NgbPopover, user: User) {
    popover.popoverClass = "my-popover";
    if (popover.isOpen()) {
      popover.close();
    } else {
      const role = this._getRoleInfo(user.id);
      popover.open({
          role,
          qualification: user.qualification_name,
          email: user.email,
          agency: this.assignment.agency_name,
          user_pic: user.picture && user.picture.length > 0 ? this._getUserImageUrl(user.picture) : './assets/media/users/default.jpg'
      })
    }
  }

  _getUserImageUrl(path: string): string {
    return environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + path;
  }

  _getRoleInfo(uId: number) {
    let roleInfo: string;
    if (this.toe.assignment.valuer_id === uId) {
        roleInfo = 'Project Manager';
        if (this._isWorker(uId)) {
            roleInfo += this._isLeadValuer(uId)
                ? ' & Lead Valuer'
                : ' & Supporting Staff';
        }
        return roleInfo;
    }
    roleInfo = this._isLeadValuer(uId)
        ? 'Lead Valuer'
        : 'Supporting Staff';
    return roleInfo;
  }

  _isWorker(uId: number): boolean {
    const worker = this.workers.filter(w => w.worker_id === uId);
    return worker.length > 0;
  }

  _isLeadValuer(uId: number): boolean {
      return this.workers[0].worker_id === uId;
  }
  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
