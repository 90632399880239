// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {DefaultAssumptionsService} from '../_services/default-assumption.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allDefaultAssumptionsLoaded} from '../_selectors/default-assumption.selectors';
// Actions
import {
    AllDefaultAssumptionsLoaded,
    AllDefaultAssumptionsRequested,
    DefaultAssumptionActionTypes,
    DefaultAssumptionsPageRequested,
    DefaultAssumptionsPageLoaded,
    DefaultAssumptionUpdated,
    DefaultAssumptionsPageToggleLoading,
    DefaultAssumptionDeleted,
    DefaultAssumptionOnServerCreated,
    DefaultAssumptionCreated,
    DefaultAssumptionAdminRestored,
    DefaultAssumptionDeletedFromAdminTrash,
    DefaultAssumptionDeletedFromTrash,
    DefaultAssumptionOnServerAdminRestored,
    DefaultAssumptionOnServerRestored,
    DefaultAssumptionRestored,
    DefaultAssumptionTrashFlushed,
    DefaultAssumptionsActionToggleLoading
} from '../_actions/default-assumption.actions';

@Injectable()
export class DefaultAssumptionEffects {
    showPageLoadingDispatcher = new DefaultAssumptionsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new DefaultAssumptionsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new DefaultAssumptionsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllDefaultAssumptions$ = this.actions$
        .pipe(
            ofType<AllDefaultAssumptionsRequested>(DefaultAssumptionActionTypes.AllDefaultAssumptionsRequested),
            withLatestFrom(this.store.pipe(select(allDefaultAssumptionsLoaded))),
            filter(([action, isAllDefaultAssumptionsLoaded]) => !isAllDefaultAssumptionsLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllDefaultAssumptionsLoaded({defaultAssumptions: res.data});
            })
        );

    @Effect()
    loadDefaultAssumptionsPage$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionsPageRequested>(DefaultAssumptionActionTypes.DefaultAssumptionsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new DefaultAssumptionsPageLoaded({
                    defaultAssumptions: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionUpdated>(DefaultAssumptionActionTypes.DefaultAssumptionUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionOnServerCreated>(DefaultAssumptionActionTypes.DefaultAssumptionOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.defaultAssumption).pipe(
                    tap(res => {
                        this.store.dispatch(new DefaultAssumptionCreated({defaultAssumption: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushDefaultAssumptionTrash$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionTrashFlushed>(DefaultAssumptionActionTypes.DefaultAssumptionTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionOnServerRestored>(DefaultAssumptionActionTypes.DefaultAssumptionOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new DefaultAssumptionRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionOnServerAdminRestored>(DefaultAssumptionActionTypes.DefaultAssumptionOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new DefaultAssumptionAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionDeleted>(DefaultAssumptionActionTypes.DefaultAssumptionDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionDeletedFromTrash>(DefaultAssumptionActionTypes.DefaultAssumptionDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashDefaultAssumption$ = this.actions$
        .pipe(
            ofType<DefaultAssumptionDeletedFromAdminTrash>(DefaultAssumptionActionTypes.DefaultAssumptionDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: DefaultAssumptionsService, private store: Store<AppState>) {
    }
}
