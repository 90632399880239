<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getComponentTitle()}}</h3>
        </div>
        <div class="card-toolbar">
            <span [matTooltip]="'Save'">
                <a href="javascript:;"
                   (click)="onSubmit()"
                   class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px">
                    Save
                </a>
            </span>
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <form class="form" [formGroup]="criterionForm" *ngIf="criterionForm">
        <div class="card-body">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Criterion Name" formControlName="name" />
                            <mat-error>Name required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Criterion Category" formControlName="category">
                                <ng-container *ngFor="let enum of criterionCategories| keyvalue">
                                    <mat-option *ngIf="!checkEnum(enum.key)"
                                    [value]="enum.key">
                                    {{enum.value}}
                                </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error>Category Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid" *ngIf="categoryControl.value == criterionCategories.Other">
                            <input matInput placeholder="Category Name" formControlName="categoryName" />
                            <mat-error>Category name required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let com of refNums; let i = index" class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput 
                                [placeholder]="'Input for ' + com" 
                                [formControl]="comparableControl(com)" />
                            <mat-error>Input required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>