import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {delay} from 'rxjs/operators';
import {IvsStandardUpdated} from '../../../../core/linked-tables';
import { TranslateService } from '@ngx-translate/core';

import {
    IvsStandard,
    IvsStandardOnServerCreated,
    selectIvsStandardsActionLoading,
    selectIvsStandardById,
    selectLastCreatedIvsStandardId
} from '../../../../core/linked-tables';

@Component({
    selector: 'kt-ivs-standard-edit',
    templateUrl: './ivs-standard-edit.component.html',
    styleUrls: ['./ivs-standard-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class IvsStandardEditComponent implements OnInit, OnDestroy {

    ivsStandard: IvsStandard;
    oldIvsStandard: IvsStandard;

    ivsStandardForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectIvsStandardsActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectIvsStandardById(id))).subscribe(res => {
                    if (res) {
                        this.oldIvsStandard = Object.assign({}, res);
                        this.ivsStandard = Object.assign({}, this.oldIvsStandard);
                        this.initIvsStandard();
                    }
                });
            } else {
                this.ivsStandard = new IvsStandard();
                this.ivsStandard.clear();
                this.initIvsStandard();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Ivs standards', page: `../default/admin-management/linked-tables/Ivs-standards`},
            {
                title: this.getComponentTitle(), page: id > 0 ? `../default/admin-management/linked-tables/Ivs-standards/edit/${id}`
                    : `../default/admin-management/linked-tables/Ivs-standards/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initIvsStandard() {
        this.createForm();
    }


    createForm() {
        this.ivsStandardForm = this.fb.group({
            name: [this.ivsStandard.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.ivsStandard && this.ivsStandard.id > 0) {
            this.mode = 'EDIT';
            return `Edit IVS Standards '${
                this.ivsStandard.name
                }'`;
        }
        return 'New IVS Standards';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.ivsStandardForm.controls[controlName];
        return control.invalid && control.touched;
    }

    reset() {
        this.ivsStandard = Object.assign({}, this.oldIvsStandard);
        this.createForm();
        this.hasFormErrors = false;
        this.ivsStandardForm.markAsPristine();
        this.ivsStandardForm.markAsUntouched();
        this.ivsStandardForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.ivsStandardForm.controls;
        /** check form */
        if (this.ivsStandardForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.ivsStandard.id > 0) {
            this.updateIvsStandard(this.ivsStandard);
        } else {
            this.createIvsStandard(this.ivsStandard);
        }
    }

    /**
     * Update ivsStandard
     *
     * @param _ivsStandard: IvsStandardModel
     */
    updateIvsStandard(_ivsStandard: IvsStandard) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateIvsStandard: Update<IvsStandard> = {
            id: _ivsStandard.id,
            changes: _ivsStandard
        };
        this.store.dispatch(new IvsStandardUpdated({
            partialivsStandard: updateIvsStandard,
            ivsStandard: _ivsStandard
        }));

        this.navigateToParent();
    }

    /**
     * Create ivsStandard
     *
     * @param _ivsStandard: IvsStandardModel
     */
    createIvsStandard(_ivsStandard: IvsStandard) {
        this.store.dispatch(new IvsStandardOnServerCreated({ivsStandard: _ivsStandard}));
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedIvsStandardId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.ivsStandard && this.ivsStandard.name && this.ivsStandard.name.length > 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.ivsStandard.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

}
