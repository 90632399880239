import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AssetClassBuilding } from "src/app/core/comparable/_models/asset-class-building.model";

@Component({
    selector: 'kt-building-info-modal',
    templateUrl: './building-info-modal.component.html',
    styleUrls: ['./building-info-modal.component.scss']
})
export class BuildingInfoModalComponent implements OnInit {
    centerLat: number;
    centerLng: number;
    buildingInfo: AssetClassBuilding;
    constructor(
        private dialogRef: MatDialogRef<BuildingInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.centerLat = data.point.lat;
        this.centerLng = data.point.lng;
        this.buildingInfo = data.buildingInfo;
    }

    ngOnInit(): void {}

    close() {
        this.dialogRef.close()
    }
}