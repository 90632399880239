import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, mergeMap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { ReportingLoaded, ReportingProcessActionTypes, ReportingRequested, ReportingStored, ReportingUpdated } from "../_actions/reporting_process.actions";
import { ReportingProcessService } from "../_services/reporting_process.service";

@Injectable()
export class ReportingProcessEffects {
    constructor(
        private actions$: Actions,
        private service: ReportingProcessService,
        private store$: Store<AppState>
    ) {}

    @Effect()
    requestData$ = this.actions$.pipe(
        ofType<ReportingRequested>(ReportingProcessActionTypes.ReportingRequested),
        mergeMap(({payload}) => {
            return this.service.get(payload.id)
        }),
        map(data => {
            return new ReportingLoaded({
                data: data && data.reporting
            });
        })
    )


    @Effect()
    storeData$ = this.actions$.pipe(
        ofType<ReportingStored>(ReportingProcessActionTypes.ReportingStored),
        mergeMap(({payload}) => {
            return this.service.create(payload.tpId, payload.data);
        }),
        mergeMap(id => {
            return this.service.get(id);
        }),
        map(data => {
            return new ReportingLoaded({
                data: data && data.reporting
            });
        })
    )


    @Effect()
    updateData$ = this.actions$.pipe(
        ofType<ReportingUpdated>(ReportingProcessActionTypes.ReportingUpdated),
        mergeMap(({payload}) => {
            return this.service.update(payload.data.id, payload.data);
        }),
        mergeMap(id => {
            return this.service.get(id);
        }),
        map(data => {
            return new ReportingLoaded({
                data: data && data.reporting
            });
        })
    )
}