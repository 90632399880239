// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TopPropertyTypeActions, TopPropertyTypeActionTypes} from '../_actions/top-property-type.actions';
// Models
import {TopPropertyTypeModel} from '../_models/top-property-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface TopPropertyTypesState extends EntityState<TopPropertyTypeModel> {
    isAllTopPropertyTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: TopPropertyTypeModel[];
    lastCreatedTopPropertyTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TopPropertyTypeModel> = createEntityAdapter<TopPropertyTypeModel>();

export const initialTopPropertyTypeState: TopPropertyTypesState = adapter.getInitialState({
    isAllTopPropertyTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedTopPropertyTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function topPropertyTypeReducer(state = initialTopPropertyTypeState, action: TopPropertyTypeActions): TopPropertyTypesState {
    switch (action.type) {
        case TopPropertyTypeActionTypes.TopPropertyTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTopPropertyTypeId: undefined
            };
        case TopPropertyTypeActionTypes.TopPropertyTypeActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case TopPropertyTypeActionTypes.TopPropertyTypeOnServerCreated:
            return {
                ...state
            };
        case TopPropertyTypeActionTypes.TopPropertyTypeCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedTopPropertyTypeId: action.payload.item.id
            });
        case TopPropertyTypeActionTypes.TopPropertyTypeUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case TopPropertyTypeActionTypes.AllTopPropertyTypesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllTopPropertyTypesLoaded: true
            });
        case TopPropertyTypeActionTypes.TopPropertyTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case TopPropertyTypeActionTypes.TopPropertyTypesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialTopPropertyTypeState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case TopPropertyTypeActionTypes.TopPropertyTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case TopPropertyTypeActionTypes.TopPropertyTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case TopPropertyTypeActionTypes.TopPropertyTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case TopPropertyTypeActionTypes.TopPropertyTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case TopPropertyTypeActionTypes.TopPropertyTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case TopPropertyTypeActionTypes.TopPropertyTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
