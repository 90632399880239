<!--&lt;!&ndash; start::EXCHANGE INFORMATION &ndash;&gt;-->
<!--<kt-notice [icon]="'flaticon2-line-chart kt-font-primary'">-->
<!--Exchange Base Currency is USA Dollar (USD)<br>-->
<!--Last Updated: <strong>{{lastUpdatedDate}}</strong> <br>-->
<!--Use exchange rate API is <a class="kt-link" href="https://openexchangerates.org" target="_blank">OpenExchangeRates.org</a>-->
<!--</kt-notice>-->
<!--&lt;!&ndash; end::EXCHANGE INFORMATION &ndash;&gt;-->

<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'card-head-lg'"
                        [tooltipTitle]="'TOOLTIP.CURRENCY.LIST.TITLE' | translate"
                        [tooltipDesc]="'TOOLTIP.CURRENCY.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-title">
                <span>{{ 'CURRENCY.LIST.TITLE' | translate }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <button (click)="addCurrency()" mat-raised-button [matTooltip]="'CURRENCY.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button"
                    class="mr-2">
                <span>{{ 'CURRENCY.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button *ngIf="(trashCnt$ | async ) != 0" (click)="trash()" mat-raised-button 
                [matTooltip]="'CURRENCY.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt$ | async }})</span>
            </button>
            <button *ngIf="(adminTrashCnt$ | async) != 0" (click)="adminTrash()" mat-raised-button 
                [matTooltip]="'CURRENCY.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt$ | async }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="currencyStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_currencies }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TOTAL CURRENCIES</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_used_in_vals }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">USED IN VALUATIONS</span></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="asc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let currency">{{currency.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CURRENCY.LIST.DATATABLE.NAME' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let currency">{{currency.name}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="acronym">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CURRENCY.LIST.DATATABLE.CURRENCY' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let currency">{{currency.acronym}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="symbol">
                    <mat-header-cell *matHeaderCellDef>{{'CURRENCY.LIST.DATATABLE.SYMBOL' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let currency">{{currency.symbol}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="rate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CURRENCY.LIST.DATATABLE.EXCHANGE_RATE' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let currency">{{currency.rate | number: '.3'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'CURRENCY.LIST.DATATABLE.ACTIONS' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let currency">
                        <span
                                [matTooltip]="currency.country_cnt == 0 
                                    ? ('CURRENCY.LIST.BUTTON.DELETE.TOOLTIP.CAN' | translate) 
                                    : ('CURRENCY.LIST.BUTTON.DELETE.TOOLTIP.CANNOT' | translate)">
                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="currency.country_cnt != 0"
                                    (click)="deleteCurrency(currency)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2 }"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="currencyData.length == 0">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}</div>
        </div>
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
