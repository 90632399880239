import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationNoteModel } from "../_models/valuation-note.model";
import * as valuationNotesActions from '../_actions/valuation-notes.action';

export interface ValuationNotesState extends EntityState<ValuationNoteModel> {
    lastCreatedId: number
};
export const adapter: EntityAdapter<ValuationNoteModel> = createEntityAdapter({
    selectId: (entity: ValuationNoteModel) => entity.front_id
});
export const initialValuationNotesState: ValuationNotesState = adapter.getInitialState({
    lastCreatedId: 0
});

export function valuationNotesReducer(state = initialValuationNotesState, action: valuationNotesActions.Actions): ValuationNotesState {
    switch (action.type) {
        case valuationNotesActions.ActionTypes.DataLoaded: {
            return adapter.setAll(action.payload.data, {
                ...state,
                lastCreatedId: action.payload.lastCreateId,
            });
        }
        case valuationNotesActions.ActionTypes.AddData: {
            const id = state.lastCreatedId + 1;
            const data = Object.assign({}, action.payload.data) as ValuationNoteModel;
            data.front_id = id;
            return adapter.addOne(data, {...state, lastCreatedId: id});
        }
        case valuationNotesActions.ActionTypes.UpdateData: {
            return adapter.updateOne(action.payload.data, {...state});
        }
        case valuationNotesActions.ActionTypes.RemoveData: {
            return adapter.removeOne(action.payload.id, {...state});
        }
        default: 
            return state;
    }
}