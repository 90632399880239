import { ValuationNoteModel } from "src/app/core/valuation/_models/valuation-note.model";
import { valuationNotesAdapter, ValuationProcessState } from "../_reducer";
import { Update } from "@ngrx/entity";

export function addNote(
  state: ValuationProcessState['notes'],
  data: ValuationNoteModel
): ValuationProcessState['notes'] {
  const id = state.lastCreatedId + 1
  const _data = Object.assign({}, data) as ValuationNoteModel
  _data.front_id = id
  return valuationNotesAdapter.addOne(_data, {...state, lastCreatedId: id})
}

export function updateNote(
  state: ValuationProcessState['notes'],
  data: Update<ValuationNoteModel>
): ValuationProcessState['notes'] {
  return valuationNotesAdapter.updateOne(data, {...state})
}

export function removeNote(
  state: ValuationProcessState['notes'],
  id: number
): ValuationProcessState['notes'] {
  return valuationNotesAdapter.removeOne(id, {...state})
}