import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { Option } from 'src/app/views/pages/shared_components/filter-section/filter-selection/filter-selection.component'
import * as _ from 'lodash'
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { select, Store } from '@ngrx/store';
import { AllAssetClassTypesRequested, AllKeyCategoriesRequested, selectAllAssetClassTypes, selectAllKeyCategories } from 'src/app/core/linked-tables';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectValuationComparableSelectedPropertySubTypeIds } from 'src/app/core/valuation-process/_selectors/valuation-comparable.selectors';
import { environment } from 'src/environments/environment';

export interface FilterModel {
  q_name: string;
  city_name: string;
  centerLat: number;
  centerLng: number;
  sub_type_category_id: number;
  sub_category_id: number;
  status: number;
  use: number;
  startDate: any;
  endDate: any;
  top_building_type_id: number;
  key_category_id: number;
  
  // Other Filters
  location_grade_id: number,
  handover_standard_id: number,
  state_of_repair_id: number,
  property_grade_id: number,
  completion_year: string,
  above_floors: number,
  building_type_id: number,
  num_of_units: number,
  parking_type_id: number,
  has_automation: boolean,
  quality_of_soil_degradation_id: number,
  comparative_land_use_id: number,
  source_of_land_use_classification_id: number,
  existing_building_on_the_land_parcel: string,
  bedroom_ranges: string,
  bathroom_ranges: string,
  room_ranges: string, 
  indoor_garage_ranges: string,
  outdoor_garage_ranges: string,
  garden_id: number,
  selected_external_areas: string,
  selected_heatings: string,
  selected_services: string,
  selected_land_services: string,
  size_unit_id: number,
  size_ranges: string,
  picture_ranges: string,
  tenure: string, 
  selected_standard_measurements: string,
  source_information_id: number,
  source_type_id: number,
  source_credibility_id: number,
  has_source_photo: number,
  land_tenure_id: number,
  tenure_length: number,
  foundation_type_id: number,
  bounds: {
    sw_lat: number,
    ne_lat: number,
    sw_lng: number,
    ne_lng: number
  },
  valuation_id: number | null,
}

type SelectionType = 'sub-type-category' | 'sub-category' | 'status' | 'use' | 'building-type' | 'key-category';

export type FilterChange = {
  page: number;
  filter: FilterModel
  acTypeIds: number[];
}

@Component({
  selector: 'kt-filter-section-all',
  templateUrl: './filter-section-all.component.html',
  styleUrls: ['./filter-section-all.component.scss']
})
export class FilterSectionAllComponent implements OnInit, OnDestroy {
  acTypes = []
  items = [{value: 1, display: 'hh'}, {value: 2, display: 'basdfa'}];
  selectedAcTypes = [];
  darkGreen = '#187916';
  lightGreen = '#67a262';
  grey = '#666666';
  lightRed = '#fdd6da';
  @Input() acType: number;
  @Input() filter$: Observable<FilterModel>;
  @Output() filterChange: EventEmitter<FilterChange> = new EventEmitter();

  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  @ViewChild('searchCity', {static: true}) searchCity: ElementRef;

  filter: FilterModel;

  subTypeCategories: SubTypeCategoryModel[] = [];
  filteredSubTypeCategory$: BehaviorSubject<Option[]> = new BehaviorSubject([]);
  subCategories: SubCategoryModel[] = [];
  filteredSubCategories$: BehaviorSubject<Option[]> = new BehaviorSubject([]);
  statuses: Option[] = [{id: -1, text: 'All'}, {id: 0, text: 'Draft'}, {id: 1, text: 'Validated'}, {id: 2, text: 'Imported'}];
  buildingTypes: Option[] = [];
  keyCategories: Option[] = [];

  usesAcPlaceholder = 'Used';
  usesAcOptions = [{id: 0, text: 'Never'}, {id: 1, text: '< 10 times'}, {id: 2, text: '>= 10 times'}]
  usesBuildingPlaceholder = 'Linked Comparables';
  usesBuildingOptions = [{id: 0, text: 'Never'}, {id: 1, text: '< 10 comparables'}, {id: 2, text: '>= 10 comparables'}]

  private _resetCmd$: Subject<void> = new Subject();
  public resetCmd$: Observable<void> = this._resetCmd$.asObservable();
  private _resetSubCatCmd$: Subject<void> = new Subject();
  public resetSubCatCmd$: Observable<void> = this._resetSubCatCmd$.asObservable();
  private _onDestroy$: Subject<void> = new Subject();

  sections = {
    categories: {
      available: false,
      color: this.darkGreen,
    },
    buildingTypes: {
      available: false,
      color: this.darkGreen,
    },
    subTypeCategory: {
      available: false,
      color: this.darkGreen
    },
    subCategory: {
      available: false,
      color: this.darkGreen
    },
    status: {
      available: false,
      color: this.darkGreen,
    },
    uses: {
      available: false,
      color: this.darkGreen,
    },
    date: {
      available: false,
      color: this.darkGreen
    },
    other: {
      available: true,
    }
  }
  constructor(
    private store: Store<AppState>,
    private ngZone: NgZone,
  ) { }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this.store.select(selectValuationComparableSelectedPropertySubTypeIds).pipe(take(1)).subscribe(res => {
      this.selectedAcTypes = res.filter(id => id != this.acType);
    })
    if (this.acType == 13) {
      this.sections = {
        ...this.sections,
        subTypeCategory: {
          available: true,
          color: this.darkGreen,
        },
        status: {
          available: true,
          color: this.lightGreen
        },
        uses: {
          available: true,
          color: this.darkGreen
        },
        date: {
          available: true,
          color: this.lightGreen
        }
      }
    } else {
      this.sections = {
        ...this.sections,
        subTypeCategory: {
          available: true,
          color: this.darkGreen,
        },
        subCategory: {
          available: true,
          color: this.lightGreen,
        },
        status: {
          available: true,
          color: this.darkGreen
        },
        uses: {
          available: true,
          color: this.lightGreen
        },
        date: {
          available: true,
          color: this.darkGreen
        }
      }
    }
    this.filter$.pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      this.filter = res;
    })

    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(50),
      distinctUntilChanged(),
      tap(() => {
        const filter = Object.assign({}, this.filter) as FilterModel;
        filter.q_name = this.searchInput.nativeElement.value;
        this.filter = filter;
        this.filterChange.emit({
          page: 0,
          filter: filter,
          acTypeIds: this.selectedAcTypes
        })
      }),
      takeUntil(this._onDestroy$)
    ).subscribe();

    const autocomplete = new google.maps.places.Autocomplete(
      this.searchCity.nativeElement,
      {
        types: ['(cities)']
      }
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const filter = Object.assign({}, this.filter) as FilterModel;
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          this.searchCity.nativeElement.value = '';
          filter.city_name = this.searchCity.nativeElement.value; 
          this.filter = filter;
          this.filterChange.emit({
            page: null,
            filter: filter,
            acTypeIds: this.selectedAcTypes
          })
          return;
        }
        if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
            filter.centerLat = 47.91868658952473;
            filter.centerLng = 106.91766668255616;
        } else {
            filter.centerLat = place.geometry.location.lat();
            filter.centerLng = place.geometry.location.lng();
        }
        filter.city_name = this.searchCity.nativeElement.value; 
        this.filter = filter;
        this.filterChange.emit({
          page: null,
          filter: filter,
            acTypeIds: this.selectedAcTypes
        })
      })
    })

    this._fetchData();
  }

  onAcTypeAdd(event: any) {
    this.selectedAcTypes.push(event.value)
    this.filteredSubTypeCategory$.next(_.concat([{id: -1, text: 'All'}], this._filterSubTypeCategories([this.acType, ...this.selectedAcTypes])))
    this.filterChange.emit({
      page: null,
      filter: this.filter,
      acTypeIds: this.selectedAcTypes
    })
  }
  onAcTypeRemove(event: any) {
    const idx = this.selectedAcTypes.findIndex(t => t == event.value)
    if (idx != -1) {
      this.selectedAcTypes.splice(idx, 1)
    }
    this.filteredSubTypeCategory$.next(_.concat([{id: -1, text: 'All'}], this._filterSubTypeCategories([this.acType, ...this.selectedAcTypes])))
    this.filterChange.emit({
      page: null,
      filter: this.filter,
      acTypeIds: this.selectedAcTypes
    })
  }

  private _fetchData() {
    this.store.dispatch(new AllSubTypeCategorysRequested());
    this.store.pipe(
      select(selectAllSubTypeCategorys),
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.subTypeCategories = res ? res : [];
      this.filteredSubTypeCategory$.next(_.concat([{id: -1, text: 'All'}], this._filterSubTypeCategories([this.acType])))
    });

    this.store.dispatch(new AllSubCategorysRequested());
    this.store.pipe(
      select(selectAllSubCategorys),
      takeUntil(this._onDestroy$),
    ).subscribe(res => {
      this.subCategories = res ? res : [];
      if (this.acType != 13) {
        this.filteredSubCategories$.next(_.concat(this._filterSubCategories(this.filter.sub_type_category_id, this.filteredSubTypeCategory$.value)))
      }
    });

    // this.store.dispatch(new AllBuildingTypesRequested());
    // this.store.pipe(select(selectAllBuildingTypes), takeUntil(this._onDestroy$)).subscribe(res => {
    //   this.buildingTypes = res ? concat([{id: -1, text: 'All'}], res.map(item => ({id: item.id, text: item.name}))) : []
    // });
    this.store.dispatch(new AllAssetClassTypesRequested());
    this.store.pipe(select(selectAllAssetClassTypes), takeUntil(this._onDestroy$)).subscribe(res => {
      this.acTypes = res ? res.filter(t => t.id != this.acType && t.id != 16).map(t => ({
        display: t.id == 13 ? 'Land' : t.name,
        value: t.id
      })) : []
      if (this.acType == 13) {
        this.filteredSubTypeCategory$.next(res ? _.concat([{id: -1, text: 'All'}], res.filter(t => t.top_property_type_id == 3).map(t => ({id: t.id, text: t.name}))) : []);
      }
    })
  }

  private _filterSubTypeCategories(ac_types: number[]): Option[] {
    return this.subTypeCategories
      .filter(item => ac_types.includes(item.property_sub_type_id))
      .map(item => ({id: item.id, text: item.name}));
  }


  public onDateSelection(event: any) {
    const filter = Object.assign({}, this.filter) as FilterModel;
    filter.startDate = event.startDate.format('YYYY-MM-DD');
    filter.endDate = event.endDate.format('YYYY-MM-DD');
    this.filter = filter;
    this.filterChange.emit({
      page: null,
      filter: this.filter,

            acTypeIds: this.selectedAcTypes
    })
  }

  public onSelect(event: number, type: SelectionType) {
    const filter = Object.assign({}, this.filter) as FilterModel;
    switch (type) {
      case 'sub-type-category':
        filter.sub_type_category_id = event;
        filter.sub_category_id = null;
        this._resetSubCatCmd$.next();
        this.filteredSubCategories$.next(this._filterSubCategories(event, this.filteredSubTypeCategory$.value));
        break;
      case 'sub-category':
        filter.sub_category_id = event;
        break;
      case 'status':
        filter.status = event;
        break;
      case 'use':
        filter.use = event;
        break;
      case 'building-type':
        filter.top_building_type_id = event;
        break;
      case 'key-category':
        filter.key_category_id = event;
        break;
    }
    this.filter = filter;
    this.filterChange.emit({
      page: null,
      filter: this.filter,

            acTypeIds: this.selectedAcTypes
    })
  }

  private _filterSubCategories(sub_type_category_id: number, sub_categories: any[]): Option[] {
    if (sub_type_category_id == null || sub_type_category_id == -1) {
      return _.concat([{id: -1, text: 'All'}], this.subCategories
        .filter(item => sub_categories.map(st => st.id).includes(item.sub_type_category_id))
        .map(item => ({id: item.id, text: item.name})));
    }
    return _.concat([{id: -1, text: 'All'}], this.subCategories
      .filter(item => item.sub_type_category_id == sub_type_category_id)
      .map(item => ({id: item.id, text: item.name})));
  }

  public resetFilter() {
    this.searchInput.nativeElement.value = '';
    this.searchCity.nativeElement.value = '';
    this.filter = emptyFilter() 
    this._resetCmd$.next();
    this._resetSubCatCmd$.next();
    this.filterChange.emit({
      page: null,
      filter: this.filter,
            acTypeIds: this.selectedAcTypes
    })
  }
}

type EmptyFilterOpts = {
  valuation_id: number| null;
}
export function emptyFilter(opts: EmptyFilterOpts = {valuation_id: null}): FilterModel {
  return {
      q_name: null,
      city_name: null,
      centerLat: environment.mapCenter.lat,
      centerLng: environment.mapCenter.lng,
      sub_type_category_id: -1,
      sub_category_id: -1,
      status: -1,
      use: null,
      startDate: null,
      endDate: null,
      location_grade_id: null,
      handover_standard_id: null,
      state_of_repair_id: null,
      property_grade_id: null,
      comparative_land_use_id: null,
      completion_year: null,
      above_floors: null,
      building_type_id: null,
      num_of_units: null,
      parking_type_id: null,
      has_automation: null,
      quality_of_soil_degradation_id: null,
      source_of_land_use_classification_id: null,
      existing_building_on_the_land_parcel: null,
      bedroom_ranges: null,
      bathroom_ranges: null,
      room_ranges: null,
      indoor_garage_ranges: null,
      outdoor_garage_ranges: null,
      garden_id: null,
      selected_external_areas: null,
      selected_heatings: null,
      selected_services: null,
      size_unit_id: 1,
      size_ranges: null,
      picture_ranges: null,
      tenure: null,
      selected_standard_measurements: null,
      source_type_id: null,
      source_information_id: null,
      source_credibility_id: null,
      has_source_photo: null,
      land_tenure_id: null,
      tenure_length: null,
      foundation_type_id: null,
      top_building_type_id: -1,
      bounds: null,
      selected_land_services: null,
      key_category_id: -1,
      valuation_id: opts.valuation_id,
  }
}

