import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-parcel-identification-modal',
  templateUrl: './parcel-identification-modal.component.html',
  styleUrls: ['./parcel-identification-modal.component.scss']
})
export class ParcelIdentificationModalComponent implements OnInit {
  @Input() 
  points: any[];
  @Input() 
  centerLat: any;
  @Input() 
  centerLng: any;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close();
  }

  ngOnInit(): void {
  }

}
