<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
    <div>
        <div class="col-lg-12 kt-margin-bottom-20-mobile mb-2 pl-0">
            <span><b>{{ error.fields.length + error.msg }}</b></span>
        </div>
        <div class="mb-2" *ngFor="let item of erroTabMap">
            <span *ngIf="item.tab_cnames.length > 0"><b>{{ tabHeaders[item.tab_index].label + ':' }}</b></span>
            <ul class="col-lg-12 kt-margin-bottom-20-mobile pl-5">
                <li *ngFor="let errObj of item.tab_cnames">
                    {{errObj.label + ' (' + errObj.section + ')' }}
                </li>
            </ul>
        </div>
    </div>
</kt-alert>