import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { NewCoiModel } from "../_models/new-coi.model";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class NewCoiService {
	API_NEW_COI_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes`;
	constructor (
		private http: HttpClient,
		private authService: MadAuthService,
		private subDomainService: SubdomainService
	) {}


	getNewCoi(toeID: number): Observable<NewCoiModel> {
		const headers = this.authService.getAuthHeaders();
		return this.http.get<{data: NewCoiModel}>(`${this.API_NEW_COI_URL}/${toeID}/newcoi`, {
			headers
		}).pipe(map(res => res.data));
	}

	updateNewCoi(coiModel: NewCoiModel): Observable<NewCoiModel> {
		const headers = this.authService.getAuthHeaders();
		return this.http.post<{data: NewCoiModel}>(`${this.API_NEW_COI_URL}/${coiModel.toe_id}/newcoi/${coiModel.id}`, {model: coiModel}, {
			headers,
		}).pipe(map(res => res.data));
	}
}