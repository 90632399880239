// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {TenureService} from '../_services/tenure.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allTenureLoaded} from '../_selectors/tenure.selectors';
// Actions
import {
    AllTenureLoaded,
    AllTenureRequested,
    TenureActionTypes,
    TenurePageRequested,
    TenurePageLoaded,
    TenureUpdated,
    TenurePageToggleLoading,
    TenureDeleted,
    TenureOnServerCreated,
    TenureCreated,
    TenureActionToggleLoading, TenureOnServerAdminRestored, TenureAdminRestored,
    TenureTrashFlushed, TenureOnServerRestored, TenureRestored, TenureDeletedFromAdminTrash,
    TenureDeletedFromTrash
} from '../_actions/tenure.actions';

@Injectable()
export class TenureEffects {
    showPageLoadingDispatcher = new TenurePageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new TenureActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TenureActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTenure$ = this.actions$
        .pipe(
            ofType<AllTenureRequested>(TenureActionTypes.AllTenureRequested),
            withLatestFrom(this.store.pipe(select(allTenureLoaded))),
            filter(([action, isAllTenureLoaded]) => !isAllTenureLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllTenureLoaded({tenures: res.data});
            })
        );

    @Effect()
    loadTenurePage$ = this.actions$
        .pipe(
            ofType<TenurePageRequested>(TenureActionTypes.TenurePageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new TenurePageLoaded({
                    tenures: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );


    @Effect()
    updateTenure$ = this.actions$
        .pipe(
            ofType<TenureUpdated>(TenureActionTypes.TenureUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.tenure);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createTenure$ = this.actions$
        .pipe(
            ofType<TenureOnServerCreated>(TenureActionTypes.TenureOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.tenure).pipe(
                    tap(res => {
                        this.store.dispatch(new TenureCreated({tenure: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTenureTrash$ = this.actions$
        .pipe(
            ofType<TenureTrashFlushed>(TenureActionTypes.TenureTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreTenure$ = this.actions$
        .pipe(
            ofType<TenureOnServerRestored>(TenureActionTypes.TenureOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TenureRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminTenure$ = this.actions$
        .pipe(
            ofType<TenureOnServerAdminRestored>(TenureActionTypes.TenureOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TenureAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteTenure$ = this.actions$
        .pipe(
            ofType<TenureDeleted>(TenureActionTypes.TenureDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashTenure$ = this.actions$
        .pipe(
            ofType<TenureDeletedFromTrash>(TenureActionTypes.TenureDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashTenure$ = this.actions$
        .pipe(
            ofType<TenureDeletedFromAdminTrash>(TenureActionTypes.TenureDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: TenureService, private store: Store<AppState>) {
    }
}
