import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { OfficeAdjustmentModel, OfficeAdjustmentOnServerCreated, OfficeAdjustmentUpdated, selectLastCreatedOfficeAdjustmentId, selectOfficeAdjustmentById, selectOfficeAdjustmentsActionLoading } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { SubheaderService } from 'src/app/core/_base/layout';
import {Location} from '@angular/common';
import { delay } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import {combineLatest} from 'rxjs'

@Component({
  selector: 'kt-adjustments-edit',
  templateUrl: './office-adjustment-edit.component.html',
  styleUrls: ['./office-adjustment-edit.component.scss']
})
export class OfficeAdjustmentEditComponent implements OnInit, OnDestroy {
  model: OfficeAdjustmentModel;
  oldModel: OfficeAdjustmentModel;
  adjustmentForm: UntypedFormGroup;
  hasFormErrors = false;

  loading$: Observable<boolean>;
  viewLoading = false;
  loadingAfterSubmit = false;
  mode = 'NEW';
  readonly = false

  private componentSubscriptions: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private location: Location,
    public subheaderService: SubheaderService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectOfficeAdjustmentsActionLoading));
    const routeSubscription = combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data
    ]).subscribe(([params, data]) => {
        const id = params.id;
        this.readonly = data.readonly
        if (id && id > 0) {
            this.store.pipe(select(selectOfficeAdjustmentById(id))).subscribe(res => {
                if (res) {
                    this.oldModel = Object.assign({}, res);
                    this.model = Object.assign({}, this.oldModel);
                    this.initAdjustment();
                }
            });
        } else {
            this.model = new OfficeAdjustmentModel();
            this.model.clear();
            this.oldModel = Object.assign({}, this.model);
            this.initAdjustment();
        }
    });
    this.subscriptions.push(routeSubscription);

    if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
        this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
    } else {
        this.setBreadCrumbs(0);
    }
  }

  setBreadCrumbs(id: number): void {
    this.subheaderService.setTitle(this.getComponentTitle());
    this.subheaderService.setBreadcrumbs([
        {title: 'Developer', page: `../default/admin-management`},
        {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
        {title: 'Bases of Values', page: `../default/admin-management/linked-tables/base-of-values`},
        {
            title: this.getComponentTitle(),
            page: id > 0 ? `../default/admin-management/linked-tables/base-of-values/edit/${id}`
                : `../default/admin-management/linked-tables/base-of-values/add`
        }
    ]);
  }

  ngOnDestroy(): void {
    if (this.componentSubscriptions) {
        this.componentSubscriptions.unsubscribe();
    }
  }

  initAdjustment() {
    this.createForm();
  }

  createForm() {
    this.adjustmentForm = this.fb.group({
        name: [this.model.name, Validators.required],
        description: [this.model.description, Validators.required],
    });
  }

  getComponentTitle(): string {
    if (this.model && this.model.id > 0) {
        this.mode = 'EDIT';
        return this.translate.instant('OFFICE-ADJUSTMENTS.FORM.TITLE.EDIT', {name: this.model.name});
    }

    return this.translate.instant('OFFICE-ADJUSTMENTS.FORM.TITLE.NEW');
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.adjustmentForm.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  reset() {
    this.model = Object.assign({}, this.oldModel);
    if (this.oldModel.is_system_entry) {
      this.model.description = this.model.default_description;
    }
    this.createForm();
    this.hasFormErrors = false;
    this.adjustmentForm.markAsPristine();
    this.adjustmentForm.markAsUntouched();
    this.adjustmentForm.updateValueAndValidity();
  }

  onSubmit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.adjustmentForm.controls;
    /** check form */
    if (this.adjustmentForm.invalid) {
        Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
        );

        this.hasFormErrors = true;
        return;
    }

    const newModel = new OfficeAdjustmentModel()
    newModel.id = this.model.id
    newModel.name = this.readonly ? this.model.name : this.adjustmentForm.controls.name.value;
    newModel.description = this.adjustmentForm.controls.description.value;
    newModel.default_description = this.model.default_description
    newModel.is_hidden = this.model.is_hidden
    newModel.is_system_entry = this.model.is_system_entry

    if (this.model.id > 0) {
        this.updateBaseOfValue(newModel);
    } else {
        this.createAdjustment(newModel);
    }
  }

  createAdjustment(_model: OfficeAdjustmentModel) {
    this.store.dispatch(new OfficeAdjustmentOnServerCreated({item: _model}));
    this.oldModel = _model;
    this.componentSubscriptions = this.store.pipe(
        select(selectLastCreatedOfficeAdjustmentId),
        delay(1000), // Remove this line
    ).subscribe(res => {
        if (!res) {
            return;
        }
      });
    this.navigateToParent();
  }

  updateBaseOfValue(_model: OfficeAdjustmentModel) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const updateBaseOfValue: Update<OfficeAdjustmentModel> = {
        id: _model.id,
        changes: _model
    };
    this.store.dispatch(new OfficeAdjustmentUpdated({
        partialItem: updateBaseOfValue,
        item: _model
    }));
    this.oldModel = _model;

    this.navigateToParent();

  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  isFormValid() {
    return (this.model && this.model.name && this.model.name.length > 0
        && this.model.description && this.model.description.length > 0);
  }

  navigateToParent() {
    let url = '../';
    if (this.model.id > 0) {
        url = '../../';
    }
    this.router.navigate([url], {relativeTo: this.activatedRoute});
  }

  canDeactivate() {
    if (this.discard()) {
        if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
            return true;
        } else {
            // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
            const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
            const currentUrl = currentUrlTree.toString();
            this.location.go(currentUrl);
            // ---------work around end-----------------
            return false;
        }
    }
    return true;
  }

  discard() {
    if (this.model && this.oldModel) {
        return this.model.name != this.oldModel.name
            || this.model.description != this.oldModel.description
    }
    return false;
  }
}
