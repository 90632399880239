<div class="card h-100">
    <div class="card-header section-header">
        <span>ToE Specifics</span>
    </div>
    <div class="card-body section-body">
        <table class="table" *ngIf="data$|async as data">
            <tbody>
                <tr>
                    <th>Client: </th> <td>{{data.client}}</td>
                </tr>
                <tr>
                    <th>Purpose of Valuation: </th> <td>{{data.purposeOfValuation}}</td>
                </tr>
                <tr>
                    <th>{{data.basisOfValue.label}} of Value: </th> <td>{{data.basisOfValue.text}}</td>
                </tr>
                <tr *ngIf="data.toePath && !multimedia">
                    <th>Terms of engagement: </th> 
                    <td><span class="clickable" (click)="viewToe($event, data.toePath)">View</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>