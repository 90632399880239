<div class="pb-2">
    <div class="m-0 p-0 row">

        <div class="col-lg-12 p-0">
            <h5 class="text-mad text-uppercase">
                {{id}} - {{tpAssetClass.name}} <span style="padding-left: 1rem; color: orangered; text-transform: none; font-size: smaller;"> 
                {{tpAssetClass.state != 1 
                    ? ('(' + tpPercentage + '% Completed)')
                    : ('(Aborted)') }} </span>
            </h5>

            <hr class="active">
        </div>

        <div class="col-lg-12 p-0">
            <mat-tab-group
                    (selectedTabChange)="selectedTabChange($event)"
                    [(selectedIndex)]="selectedTab">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-home"></i>
                        Tasks
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="d-flex flex-row-reverse">
                            <!--<div class="row m-1 d-flex d-flex flex-row-reverse" style="">-->
                            <!--<h6 class="col text-uppercase my-auto">Not Assigned</h6>-->
                            <!--<div class="col-2">-->
                            <!--<div class="color-scheme" style="background-color: white;"></div>-->
                            <!--</div>-->
                            <!--</div>-->

                            
                        </div>
                        <div class="card card-custom">
                            <div class="card-header" [style.backgroundColor]="'#17c6bd'">
                                <!-- <div class="card-title"></div>
                                <div class="card-toolbar d-flex flex-row-reverse">
                                    <div class="row m-1 d-flex d-flex flex-row-reverse" style="">
                                        <h6 class="col text-uppercase my-auto">Not Completed</h6>
                                        <div class="col-2">
                                            <div class="color-scheme" style="background-color: #ffe5e6;"></div>
                                        </div>
                                    </div>
        
                                    <div class="row m-1 d-flex d-flex flex-row-reverse" style="">
                                        <h6 class="col text-uppercase my-auto">Completed</h6>
                                        <div class="col-2">
                                            <div class="color-scheme" style="background-color: #dbf7d1;"></div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="card-body" [style.backgroundColor]="'#d1f4f2'" >
                                <div class="mat-table-wrapper">
                                    <table mat-table #table [dataSource]="dataSource" >

                                        <!--displayedColumns = ['completion', 'task', 'details', 'date', 'user', 'action'];-->
                                        <!-- Position Column -->
                                        <ng-container matColumnDef="task">
                                            <th mat-header-cell *matHeaderCellDef>Category</th>
                                            <td mat-cell *matCellDef="let item" 
                                                [attr.rowspan]="item.rowSpan"
                                                [style.display]="item.rowSpan == 0 ? 'none' : ''"
                                                > {{item.task.task_name}}</td>
                                        </ng-container>
        
                                        <ng-container matColumnDef="details">
                                            <th mat-header-cell *matHeaderCellDef>Task</th>
                                            <td mat-cell *matCellDef="let item"> 
                                                {{item.task.task_detail ? item.task.task_detail : ''}}
                                                <ng-container *ngIf="item.task.task_id == 2">
                                                    <ng-container *ngIf="item.task.task_meta.approach != 'income'">
                                                        {{item.task.details}}
                                                    </ng-container> 
                                                    <ng-container *ngIf="item.task.task_meta.approach == 'income'">
                                                        <ng-container *ngIf="item.task.tp_meta.method_to_value_id != null">
                                                            {{item.task.details}}
                                                            <span *ngIf="item.task.task_meta.method_changed" class="text-warning font-bolder">
                                                                !
                                                            </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.task.tp_meta.method_to_value_id == null">
                                                            Income 
                                                            <mat-select placeholder="Select Method" (valueChange)="onMethodSelect($event, item)">
                                                                <mat-option *ngFor="let mtv of methodsToValueForIncome" [value]="mtv.id" [disabled]="mtv.disabled">
                                                                    {{mtv.text}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="item.task.task_id != 2">
                                                    {{item.task.details}}
                                                    <ng-container *ngIf="item.tooltip">
                                                        <button mat-icon-button color="primary" 
                                                            container="body"
                                                            placement="top"
                                                            [ngbPopover]="item.tooltip">
                                                            <mat-icon>info</mat-icon>
                                                        </button>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </ng-container>
        
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef>Exp. Completion date</th>
                                            <td mat-cell *matCellDef="let item">
                                                <ng-container *ngIf="(item.task.date && item.task.date.length > 0); else na">
                                                    <ng-container *ngTemplateOutlet="expDate; context: {date: item.task.date, completedDate: item.task.is_manual ? item.task.completed_at : item.task.updated_at, completed: item.task.point == item.task.max_point, state: tpAssetClass.state, isDraft: item.task.point != 0 && item.task.point != item.task.max_point}"></ng-container>
                                                </ng-container>
                                                <ng-template #na>
                                                    'N/A'
                                                </ng-template>
                                                <!-- {{item.date && item.date.length >0 ? (item.date  | date: 'dd MMM yyyy - hh a') : 'N/A'}} -->
                                            </td>
                                        </ng-container>
        
                                        <ng-container matColumnDef="user">
                                            <th mat-header-cell *matHeaderCellDef>User</th>
                                            <td mat-cell *matCellDef="let item" class="assignee"> 
                                                <div class="assignee-wrapper">
                                                    <ng-container *ngIf="item.assignee">
                                                        <kt-user-tooltip [user]="item.assignee"></kt-user-tooltip>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.assignee">
                                                        N/A
                                                    </ng-container>
                                                </div>
                                            </td>
                                        </ng-container>
        
        
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                                            <td mat-cell *matCellDef="let item; let i = index;">
                                                <ng-container *ngIf="item.task.task_meta">
                                                    <div *ngIf="item.task.task_meta.show_add_scenario" ngbDropdown class="dropdown dropdown-inline" [placement]="'bottom-right'" container="body">
                                                        <button mat-icon-button type="button" ngbDropdownToggle matTooltip="Add a Scenario">
                                                            <mat-icon [style.color]="item.disable_all_buttons ? 'lightgrey' : 'green'">add_circle</mat-icon>
                                                        </button>
                                                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right">
                                                            <ul class="navi navi-hover">
                                                                <li class="navi-item">
                                                                    <a href="javascript:;" class="navi-link" (click)="addBlankScenario(item)">
                                                                        <div class="navi-text">
                                                                            <span>New Blank scenario</span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li class="navi-item">
                                                                    <a href="javascript:;" class="navi-link" (click)="duplicateScenario(item)">
                                                                        <div class="navi-text">
                                                                            <span>Duplicate scenario</span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <button mat-icon-button
                                                        matTooltip="Delete scenario"
                                                        *ngIf="item.task.task_meta.show_delete_scenario"
                                                        type="button"
                                                        color="warn"
                                                        [disabled]="item.disable_all_buttons"
                                                        (click)="deleteScenario(item)"
                                                        >
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </ng-container>
                                                <button mat-icon-button
                                                        color="primary"
                                                        matTooltip="show"
                                                        *ngIf="item.canBeShown"
                                                        [disabled]="tpAssetClass.state == 1 || item.disable_all_buttons"
                                                        (click)="showRow(item.task)"
                                                        type="button">
                                                    <mat-icon>remove_red_eye</mat-icon>
                                                </button>
        
                                                <button mat-icon-button
                                                        color="primary"
                                                        *ngIf="projectManager"
                                                        [matTooltip]="tpAssetClass.state == 1 ? 'Task is Aborted' : 'Edit Information of the Task'"
                                                        [disabled]="tpAssetClass.state == 1 || toeStatus == 4 || item.disable_all_buttons"
                                                        (click)="edit(item.task)"
                                                        type="button">
                                                    <mat-icon>settings</mat-icon>
                                                </button>
        
                                                <span 
                                                    [matTooltip]="tpAssetClass.state == 1 
                                                                    ? 'Task is Aborted'
                                                                    : (!item.canReach)
                                                                        ? 'Cannot Reach, please complete previous tasks'
                                                                        : 'Reach'">
                                                    <button mat-icon-button
                                                            color="warn"
                                                            [disabled]="tpAssetClass.state == 1 || (!item.canReach) || item.disable_all_buttons"
                                                            (click)="editRow(item.task, item)"
                                                            type="button">
                                                        <i class="flaticon2-arrow"
                                                            [ngStyle]="{
                                                                'color': tpAssetClass.state != 1 && item.canReach ? 'black' : 'lightgray'}"></i>
                                                    </button>
                                                </span>

                                                <!-- <span 
                                                    [matTooltip]="tpAssetClass.state == 1 
                                                                    ? 'Task is Aborted'
                                                                    : (!item.canReach)
                                                                        ? 'Cannot Reach, please complete previous tasks'
                                                                        : 'Reach'">
                                                    <button mat-icon-button
                                                            color="warn"
                                                            [disabled]="tpAssetClass.state == 1 || (!item.canReach)"
                                                            (click)="editRowV2(item.task, item)"
                                                            type="button">
                                                        <i class="flaticon2-arrow"
                                                            [ngStyle]="{
                                                                'color': tpAssetClass.state != 1 && item.canReach ? 'black' : 'lightgray'}"></i>
                                                    </button>
                                                </span> -->
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns" [style.backgroundColor]="'#d1f4f2'"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                            style="background-color: {{row.rowColor}}"
                                            [ngClass]="{'aborted': tpAssetClass.state == 1}"
                                        >
                                                 <!-- [ngClass]="{'completed':  row.point == row.max_point,
                                                 'cert':  row.point != row.max_point}"> -->
                                        </tr>
                                    </table>
                                    <div class="mat-table-message" *ngIf="loading$ | async">
                                        {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="example-container mat-elevation-z0 mat-table-wrapper" style="min-height: 250px;">

                        </div> -->
                    </ng-template>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-file"></i>
                        External references
                    </ng-template>
                    <ng-template matTabContent>
                        <!--<div class="mat-elevation-z0" style="min-height: 250px;">-->
                        <div class="mat-elevation-z0" style="min-height: 250px;">
                            <kt-tp-documents
                                [toeStatus]="toeStatus"
                                [tpState]="tpAssetClass.state"
                                [toeOldFiles]="attFiles"
                                [userOldFiles]="additionalFiles"
                                [toeFolderOnEditMode]="'test'"
                                [userFolderOnEditMode]="'tp-additiona-doc'"
                                [parentId]="tpAssetClass.id"
                                [fileCategory]="checkedCategories">
                            </kt-tp-documents>
                        </div>
                    </ng-template>

                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-check"></i>
                        Outputs
                    </ng-template>
                    <ng-template matTabContent>
                        <!-- <div class="example-container mat-elevation-z0" style="min-height: 250px">

                        </div> -->
                        <kt-reports 
                            [tpState]="tpAssetClass.state"
                            [toeStatus]="toeStatus"
                            [inspectionCompleted$]="inspectionCompleted$" 
                            [valuationCompleted$]="valuationCompleted$" 
                            [canGenerateValuationReport$]="canGenerateValuationReport$"
                            [canGenerateDraftReport$]="canGenerateDraftReport$"
                            [tpID]="tpAssetClass.id"
                            [toeID]="toeId"
                            [isCustomTp]="tpAssetClass.top_property_type_id == propertyTypes.Custom"
                            [user]="currentUser"
                            [toeRestrictionsOnUse]="toeRestrictionsOnUse == 1"
                            ></kt-reports>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        Signed Reports
                    </ng-template>
                    <ng-template matTabContent>
                        <kt-signed-reports
                            [toeStatus]="toeStatus"
                            [tpState]="tpAssetClass.state"
                            [tpID]="tpAssetClass.id"
                            [taskUserId]="signedDocUploadTaskUserId"
                            [userID]="currentUser.id"
                            [user]="currentUser"
                            [draftStatementGenerated$]="draftStatementReportGeneratedObs$"
                            [valuationReportGenerated$]="valuationReportGeneratedObs$"
                        ></kt-signed-reports>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<ng-template #expDate let-date="date" let-completedDate="completedDate" let-completed="completed" let-state="state" let-isDraft="isDraft">
    <ng-template #expDateContent>
        <span>Expected completion date: {{date  | date: 'dd MMM yyy - hh a':currentUser.settings.time_zone_gmt}}</span>
    </ng-template>
    <ng-template #comDateContent>
        <span>Completed date: {{completedDate | date: 'dd MMM yyy ':currentUser.settings.time_zone_gmt}}</span>
    </ng-template>
    <ng-container *ngIf="getStatus(date, completed, state) as stat">
        <div class="d-flex w-100 justify-content-between align-items-center">
            <div *ngIf="stat.status == 0">
                <ng-container *ngTemplateOutlet="isDraftTem; context:{isDraft: isDraft}"></ng-container>
                <span style="color: #3797FF; font-weight: bold;">Remaining days: {{stat.text}}</span>
            </div>
            <div *ngIf="stat.status == 1">
                <ng-container *ngTemplateOutlet="isDraftTem; context:{isDraft: isDraft}"></ng-container>
                <span style="color: #FDA701; font-weight: bold;">Today</span>
            </div>
            <div *ngIf="stat.status == 2">
                <ng-container *ngTemplateOutlet="isDraftTem; context:{isDraft: isDraft}"></ng-container>
                <span style="color: #F44D5E; font-weight: bold;">Overdue: {{stat.text}}</span>
            </div>
            <div *ngIf="stat.status == 3">
                <ng-container *ngTemplateOutlet="isDraftTem; context:{isDraft: isDraft}"></ng-container>
                <span style="color: #16C4BB; font-weight: bold;">Completed</span>
            </div>
            <div *ngIf="stat.status == 4">
                <ng-container *ngTemplateOutlet="isDraftTem; context:{isDraft: isDraft}"></ng-container>
                <span style="color: #F44D5E; font-weight: bold;">{{stat.text}}</span>
            </div>
            <button mat-icon-button color="primary" *ngIf="stat.status != 3 && stat.status != 4"
                container="body"
                placement="top"
                [ngbPopover]="expDateContent">
                <mat-icon>info</mat-icon>
            </button>
            <button mat-icon-button color="primary" *ngIf="stat.status == 3"
                container="body"
                placement="top"
                [ngbPopover]="comDateContent">
                <mat-icon>info</mat-icon>
            </button>
        </div>
    </ng-container>
</ng-template>

<ng-template #isDraftTem let-isDraft="isDraft">
    <span  style="color: #FDA701; font-weight: bold;">{{ isDraft ? 'Draft - ' : ''}}</span>
</ng-template>
