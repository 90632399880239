<div class="form-group row section-margin-top-60">
    <div class="col-lg-12">
        <h5 class="my-3 text-mad text-uppercase">
            Building Pictures
        </h5>
        <hr class="active">
    </div>
    <kt-upload-file #buildingPic class="col-lg-12"
        [filesOnEditMode]="buildingPictures"
        [folderOnEditMode]="buildingPicturePath"
        [readOnly]="false"
        [parentForm]="'land'"
        [typeField]="false"
        fileType="image/*"
        split="2"
        [featuredPictureAllowed]="false"
        [hasThumbnail]="true"
        [addButtonLabel]="'Add Building Picture'">
    </kt-upload-file>
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent && uploadFileComponent.uploadFiles.length == 0">
            At least 1 Picture <strong>required</strong>
        </mat-error>
        <mat-error class="tag-custom-error" *ngIf="showTitleRequiredError$|async">
            Picture should have a <strong>Title</strong>
        </mat-error>
    </div>
</div>