import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetClassDetailModel, AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { ToeModel } from 'src/app/core/toe';
import { InspectionDetails } from 'src/app/views/pages/target-property/logistics-page/logistics.service';

type ModuleData = {
  inspectionType: string;
  inspectionDate: string | Date;
  draftDate: string;
  finalDate: string;
}

@Component({
  selector: 'kt-milestone-module',
  templateUrl: './milestone-module.component.html',
  styleUrls: [
    '../info-module.styles.scss',
    './milestone-module.component.scss'
  ]
})
export class MilestoneModuleComponent implements OnInit {
  @Input() targetProperty: AssetClassModel;
  @Input() tpDetails: AssetClassDetailModel;
  @Input() toe: ToeModel;
  @Input() valuation: ValuationModel;
  @Input() multimedia = false;
  @Input() valuationDate: string;
  @Input() valuationReportDate: string;
  @Input() inspectionDetail: InspectionDetails
  data$: Observable<ModuleData>;

  constructor() { }

  ngOnInit(): void {
    this.data$ = combineLatest([
      of(this.targetProperty),
      of(this.inspectionDetail)
    ]).pipe(map(([tp, inspectionDetail]) => {
      const [inspection, inspectionDate] = this._getInspectionInfo(tp.type_of_inspection);
      const data: ModuleData = {
        inspectionType: inspection,
        inspectionDate: inspectionDate,
        draftDate: this.targetProperty.draft_date,
        finalDate: this.targetProperty.valuation_date,
      }
      return data;
    }))
  }

  _getInspectionInfo(id: number): [string, string | Date] {
    switch (id) {
      case 1:
        return ['Desktop Valuation', this.inspectionDetail.information_date];
      case 2:
        return ['External Inspection (Drive by)', this.inspectionDetail.inspection_date];
      case 3:
        return ['Internal Inspection', this.inspectionDetail.inspection_date];
      case 4:
        return ['Full property survey', this.inspectionDetail.inspection_date];
      default:
        return ['N/A', null];
    }
  }

}
