import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CertificationFileModel } from 'src/app/core/file-upload';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { CertificateEditDialogComponent } from 'src/app/views/partials/content/crud/certificate-edit-dialog/certificate-edit-dialog.component';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'kt-memberships-table',
  templateUrl: './memberships-table.component.html',
  styleUrls: ['./memberships-table.component.scss']
})
export class MembershipsTableComponent implements OnInit, OnDestroy {
  @Input() certificates$: Observable<CertificationFileModel[]>;
  @Input() readonly: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns = ['organisation', 'certification', 'registration', 'prime_reference', 'date', 'actions'];
  docs = ['xlsx', 'xls', 'doc', 'docx'];

  certificates = new BehaviorSubject<CertificationFileModel[]>([]);
  private subscriptions: Subscription[] = [];
  private basePath: string = '';

  constructor(
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog
  ) {
    this.basePath = environment.baseApiUrl;
  }

  ngOnInit(): void {
    const initSub = this.certificates$.subscribe(c => {
      this.certificates.next(_.cloneDeep(c));
    });
    this.subscriptions.push(initSub);

    const certificatesSub = this.certificates.asObservable().subscribe((res) => {
      this.dataSource.data = res;
    });
    this.subscriptions.push(certificatesSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onFileUploaded(res: any) {
    const _tmp = Object.assign([], this.certificates.value);
    const obj = new CertificationFileModel();
    obj.clear();
    obj.org_id = 1;
    obj.org_name = 'RICS';
    obj.cert_type = 1;
    obj.cert_name = 'FRICS';
    obj.path = res.path;
    obj.name = res.name;
    _tmp.push(obj);
    this.certificates.next(this.mapCertificicate(_tmp));
    this.editCert(obj, _tmp.length - 1);
  }

  editCert(obj, _index) {
    const _title = 'Add Certification';

    const dialogRef = this.dialog.open(CertificateEditDialogComponent, {
      data: { title: _title, item: obj },
      width: '440px',
      disableClose: false,
      hasBackdrop: false,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const _tmp = Object.assign([], this.certificates.value);
      _tmp[_index] = res.item;
      this.certificates.next(this.mapCertificicate(_tmp));
    });
  }

  deleteCert(_index) {
    const _tmp = Object.assign([], this.certificates.value);
    _tmp.splice(_index, 1);
    this.certificates.next(this.mapCertificicate(_tmp));
  }

  previewCert(obj) {
    if (this.docs.indexOf(this.typesUtilsService.getFileType(obj.name)) > -1) {
      window.open(this.basePath + obj.path + '/' + obj.name);
    } else {
      const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: obj.path + '/' + obj.name,
          type: this.typesUtilsService.getFileType(obj.name)
        }
      });
    }
  }

  changeIsPrime(index: number, event: MatCheckboxChange) {
    const value = this.certificates.value;
    this.certificates.next(
      value.map((c, i) => {
        if (i == index) {
          c.is_prime_ref = event.checked;
        } else {
          c.is_prime_ref = false;
        }
        return c;
      })
    )
  }

  isExpired(dateValue) {
    if (dateValue == null || dateValue == undefined || dateValue.length == 0) {
      return false;
    }
    const date = this.typesUtilsService.getDateFromString(dateValue);
    return this.typesUtilsService.compareTwoDate(date, new Date()) <= 0;
  }

  getData() {
    return this.certificates.value;
  }
  validate(qualification_id: number): {isValid: boolean, error: {key: string, message: string}} {
    if (qualification_id != 1) {
      return {isValid: true, error: null};
    }

    if (this.certificates.value.length < 1) {
      return {isValid: false, error: {key: 'certificate_required', message: 'At least one membership is <strong>required</strong>'}}
    }

    if (!this.certificates.value.find(c => c.is_prime_ref)) {
      return {isValid: false, error: {key: 'certificates_prime_ref', message: 'At least one membership with prime reference is <strong>required</strong>'}};
    }
    return {isValid: true, error: null};
  }

  private mapCertificicate(certificates: CertificationFileModel[]): CertificationFileModel[] {
    if (certificates.find(c => c.is_prime_ref)) {
      return certificates;
    }
    if (certificates.length == 0) {
      return certificates;
    }
    certificates[0].is_prime_ref = true;
    return certificates;
  }
}
