import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kt-datepicker-tz-input',
  templateUrl: './datepicker-tz-input.component.html',
  styleUrls: ['./datepicker-tz-input.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: DatepickerTzInputComponent}
  ],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id'
  }
})
export class DatepickerTzInputComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Date> {
  form = new FormGroup({
    date: new FormControl<Date|null>(null),
    dateStr: new FormControl<string|null>(null)
  })

  @Input()
  get timezone(): string | null {
    return this._timezone$.value
  }
  set timezone(value: string | null) {
    this._timezone$.next(value)
  }
  private _timezone$ = new BehaviorSubject<string|null>(null)

  static nextId = 0;
  id = `datepicker-tz-input-${DatepickerTzInputComponent.nextId++}`

  stateChanges = new Subject<void>()
  focused = false;
  touched = false;
  controlType = 'datepicker-tz-input'
  onChange = (_: any) => {}
  onTouched = () => {}

  get empty(): boolean {
    const {value: {date}} = this.form
    return !date;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  @Input()
  get placeholder(): string {
    return this._placeholder
  }
  set placeholder(value: string) {
    this._placeholder = value
    this.stateChanges.next()
  }
  private _placeholder: string = ''

  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value)
    this.stateChanges.next()
  }
  private _required = false

  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
    this._disabled ? this.form.disable() : this.form.enable()
    this.stateChanges.next()
  }
  private _disabled = false

  @Input()
  get value(): Date | null {
    if (this.form.valid) {
      const {value: {date}} = this.form
      return date
    }
    return null
  }
  set value(_value: Date | null) {
    const dateStr = this._dateStrWithTimezone(_value, this.timezone)
    if (typeof _value == 'string' && _value == '') {
      this.form.patchValue({date: null, dateStr})
    } else {
      this.form.patchValue({date: _value, dateStr})
    }
    this.stateChanges.next()
  }

  get errorState(): boolean {
    const touched = !!this.ngControl?.touched
    if (touched != this.touched) {
      this.touched = touched
      this.stateChanges.next()
    }
    return (this.form.invalid || !!this.ngControl?.invalid) && this.touched
  }

  autofilled?: boolean;
  userAriaDescribedBy?: string;

  // MatdatePicker api
  @Input() max: Date | null;
  @Input() min: Date | null;
  @Output() dateChange = new EventEmitter<void>();
  onDateChange() {
    this.dateChange.emit()
  }

  private _onDestroy$ = new Subject<void>();

  constructor(
    private _datePipe: DatePipe,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) { 
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.form.controls.date.valueChanges.pipe(
        startWith(this.form.controls.date.value)
      ),
      this._timezone$
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([dateValue, timezone]) => {
      this.onChange(dateValue)
      const dateStr = this._dateStrWithTimezone(dateValue, timezone)
      this.form.controls.dateStr.patchValue(dateStr)
    })
  }
  ngOnDestroy(): void {
    this.stateChanges.complete()
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  // MatFormFieldControl methods
  setDescribedByIds(ids: string[]): void {
  }
  onContainerClick(event: MouseEvent): void {
  }

  // CVA methods
  writeValue(obj: any): void {
    this.value = obj
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  // Custom methods
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true
      this.stateChanges.next()
    }
  }
  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true
      this.focused = false
      this.onTouched()
      this.stateChanges.next()
    }

  }
  clearDate() {
    this.form.patchValue({date: null, dateStr: null})
  }
  private _dateStrWithTimezone(dateValue: Date | string | null, timezone: string | null): string | null {
    let dateStr: string | null = null
    if (typeof dateValue == 'string' && dateValue == '') {
      return dateStr
    }
    if (dateValue == null) {
      return dateStr
    }

    dateStr = this._datePipe.transform(dateValue, 'dd MMM yyyy')
    if (timezone == null) {
      return dateStr
    }

    // return `${dateStr} (GMT ${timezone})`
    return dateStr
  }
}
