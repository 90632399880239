import { Component, OnDestroy, OnInit} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {TypesUtilsService} from '../../../../core/_base/crud';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {combineLatest} from 'rxjs'

import {
    Facility,
    FacilityUpdated,
    FacilityOnServerCreated,
    selectFacilitiesActionLoading,
    selectFacilityById,
    selectLastCreatedFacilityId,
} from '../../../../core/linked-tables';
import {Location} from '@angular/common';

@Component({
    selector: 'kt-facility-edit',
    templateUrl: './facility-edit.component.html',
    styleUrls: ['./facility-edit.component.scss'],
})


export class FacilityEditComponent implements OnInit, OnDestroy {

    model: Facility;
    oldModel: Facility;

    facilityForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    title = ' Facility';
    mode = 'NEW';
    readonly = false
    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];
    private isBuilding = false;
    private acTypeId = 0;
    public facType = '';

    /**
     * Component constructor
     *
     * @param subheaderService: SubheaderService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store<AppState>
     */
    constructor(public subheaderService: SubheaderService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private location: Location,
                private fb: UntypedFormBuilder,
                public typesUtilsService: TypesUtilsService,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        if (this.router.url.indexOf('building') > -1) {
            this.isBuilding = true;
            this.title = 'Building Facility';
            this.facType = 'BUILDING_';
        }
        this.loading$ = this.store.pipe(select(selectFacilitiesActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            this.readonly = data.readonly
            if (params.ac_type_id) {
                this.acTypeId = Number(params.ac_type_id);
                switch (this.acTypeId) {
                    case 1: 
                        this.facType = 'APARTMENT_';
                        break;
                    case 3:
                        this.facType = 'OFFICE_COMMON_';
                        break;
                    case 12:
                        this.facType = 'OFFICE_EXCLUSIVE_';
                        break;
                }
                this.title = this.typesUtilsService.getAssetClassTypeName(params.ac_type_id) + this.title + (params.ac_type_id == '3' ? ' (common use)' : params.ac_type_id == '12' ? ' (exclusive use)' : '');
            }
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectFacilityById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initFacility();
                    }
                });
            } else {
                this.model = new Facility();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initFacility();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Handover Standard', page: `../default/admin-management/linked-tables/facilities`},
            {
                title: this.getComponentTitle(),
                page: id > 0 ? `../default/admin-management/linked-tables/facilities/edit/${id}`
                    : `../default/admin-management/linked-tables/facilities/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initFacility() {
        this.createForm();
    }


    createForm() {
        this.facilityForm = this.fb.group({
            name: [this.model.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return `Edit ${this.title} '${
                this.model.name
                }'`;
        }
        return `New ${this.title}`;
    }


    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.facilityForm.markAsPristine();
        this.facilityForm.markAsUntouched();
        this.facilityForm.updateValueAndValidity();
    }

    /**
     * Returns prepared assignment
     */
    prepareFacility(): Facility {
        const controls = this.facilityForm.controls;
        const _model = new Facility();
        _model.clear();
        _model.id = this.model.id;
        _model.name = controls.name.value;
        _model.asset_type_id = this.acTypeId;
        _model.is_building = this.isBuilding;

        return _model;
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.facilityForm.controls;
        /** check form */
        if (this.facilityForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedFacility = this.prepareFacility();
        if (this.model.id > 0) {
            this.updateFacility(editedFacility);
        } else {
            this.createFacility(editedFacility);
        }
    }

    /**
     * Update model
     *
     * @param _model: FacilityModel
     */
    updateFacility(_model: Facility) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateFacility: Update<Facility> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new FacilityUpdated({
            partialFacility: updateFacility,
            facility: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();
    }

    /**
     * Create model
     *
     * @param _model: FacilityModel
     */
    createFacility(_model: Facility) {

        this.store.dispatch(new FacilityOnServerCreated({facility: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedFacilityId),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        const controls = this.facilityForm.controls;

        return (controls.name.value);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }
}
