import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource } from "../../_base/crud";
import { selectAssetClassRetailShopInStore, selectAssetClassRetailShopPageLoading, selectAssetClassRetailShopShowInitWaitingMessage } from "../_selectors/asset-class-retail-shop.selectors";
import { tenureAdder } from "./tenure_extension";

export class AssetClassRetailShopDataSource extends BaseDataSource {
    trashed = 0;

    constructor(
        private store: Store<AppState>
    ) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssetClassRetailShopPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssetClassRetailShopShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssetClassRetailShopInStore)
        ).subscribe(response => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.trashed = response.trashed;
            this.entitySubject.next(response.items.map(e => tenureAdder(e)));
        })
    }
}