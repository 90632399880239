// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {TypeOfRegistrationsService} from '../_services/type-of-registration.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allTypeOfRegistrationsLoaded} from '../_selectors/type-of-registration.selectors';
// Actions
import {
    AllTypeOfRegistrationsLoaded,
    AllTypeOfRegistrationsRequested,
    TypeOfRegistrationActionTypes,
    TypeOfRegistrationsPageRequested,
    TypeOfRegistrationsPageLoaded,
    TypeOfRegistrationUpdated,
    TypeOfRegistrationsPageToggleLoading,
    TypeOfRegistrationDeleted,
    TypeOfRegistrationOnServerCreated,
    TypeOfRegistrationCreated,
    TypeOfRegistrationAdminRestored,
    TypeOfRegistrationDeletedFromAdminTrash,
    TypeOfRegistrationDeletedFromTrash,
    TypeOfRegistrationOnServerAdminRestored,
    TypeOfRegistrationOnServerRestored,
    TypeOfRegistrationRestored,
    TypeOfRegistrationTrashFlushed,
    TypeOfRegistrationsActionToggleLoading
} from '../_actions/type-of-registration.actions';

@Injectable()
export class TypeOfRegistrationEffects {
    showPageLoadingDispatcher = new TypeOfRegistrationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new TypeOfRegistrationsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TypeOfRegistrationsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTypeOfRegistrations$ = this.actions$
        .pipe(
            ofType<AllTypeOfRegistrationsRequested>(TypeOfRegistrationActionTypes.AllTypeOfRegistrationsRequested),
            withLatestFrom(this.store.pipe(select(allTypeOfRegistrationsLoaded))),
            filter(([action, isAllTypeOfRegistrationsLoaded]) => !isAllTypeOfRegistrationsLoaded),
            mergeMap(() => this.service.getAllTypeOfRegistrations()),
            map(res => {
                return new AllTypeOfRegistrationsLoaded({typeOfRegistrations: res.data});
            })
        );

    @Effect()
    loadTypeOfRegistrationsPage$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationsPageRequested>(TypeOfRegistrationActionTypes.TypeOfRegistrationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findTypeOfRegistrations(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new TypeOfRegistrationsPageLoaded({
                    typeOfRegistrations: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationDeleted>(TypeOfRegistrationActionTypes.TypeOfRegistrationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteTypeOfRegistration(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationUpdated>(TypeOfRegistrationActionTypes.TypeOfRegistrationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateTypeOfRegistration(payload.typeOfRegistration);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationOnServerCreated>(TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createTypeOfRegistration(payload.typeOfRegistration).pipe(
                    tap(res => {
                        this.store.dispatch(new TypeOfRegistrationCreated({typeOfRegistration: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTypeOfRegistrationTrash$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationTrashFlushed>(TypeOfRegistrationActionTypes.TypeOfRegistrationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationOnServerRestored>(TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TypeOfRegistrationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationOnServerAdminRestored>(TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TypeOfRegistrationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationDeletedFromTrash>(TypeOfRegistrationActionTypes.TypeOfRegistrationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashTypeOfRegistration$ = this.actions$
        .pipe(
            ofType<TypeOfRegistrationDeletedFromAdminTrash>(TypeOfRegistrationActionTypes.TypeOfRegistrationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: TypeOfRegistrationsService, private store: Store<AppState>) {
    }
}
