// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ToeModel} from '../_models/toe.model';
import {SupportingValuerModel} from '../_models/supporting-valuer.model';
import {AssetClassModel} from '../../asset_class';
import {CoiDetailsTPModel} from '../_models/coi-details-tp.model';
import {PaymentModel} from '../../asset_class/_models/payment.model';
import {PaymentTaxModel} from '../../asset_class/_models/payment-tax.model';
import {PaymentTermModel} from '../../asset_class/_models/payment-term.model';
import {ValuationModel} from '../../asset_class/_models/valuation.model';

export enum ToeActionTypes {
    ToeClear = '[toe list] toe clear',

    ToeOnServerCreated = '[Edit toe Dialog] toe On Server Created',
    ToeCreated = '[Edit toe Dialog] toe Created',

    ToeOnServerRestored = '[toe Trash] toe On Server Restored',
    ToeRestored = '[toe Trash] toe Restored',

    ToeOnServerAdminRestored = '[toe Admin Trash] toe On Server Restored',
    ToeAdminRestored = '[toe Admin Trash] toe Restored',

    ToeDeletedFromTrash = '[toe Trash] toe deleted',
    ToeDeletedFromAdminTrash = '[toe Admin Trash] toe deleted',

    ToeTrash = 'toe Trashed',
    ToeTrashFlushed = 'Toe Trash Flushed',

    OneToeRequest = '[toe API] One Toe Request',
    OneToeLoaded = '[toe API] One Toe Loaded',

    ToeUpdated = '[Edit toe Dialog] toe Updated',
    ToeOnServerUpdated = '[Toe update effect] toe updated on server',
    ToeStatusUpdated = '[Edit toe status ] Status Updated',
    OneToeDeleted = '[Toes List Page] One toe Deleted',
    ManyToesDeleted = '[Toes List Page] Many toe Deleted',
    ToesPageRequested = '[Toes List Page] Toes Page Requested',
    ToesPageLoaded = '[Toes API] Toes Page Loaded',
    ToesPageCancelled = '[Toes API] Toes Page Cancelled',
    ToesPageToggleLoading = '[Toes] Toes Page Toggle Loading',
    ToeActionToggleLoading = '[Toes] Toes Action Toggle Loading',

    AllToesByStatusRequested = '[Home DashBoard] All ToEs by Status Requested',
    AllToesByStatusLoaded = '[Home DashBoard] All ToEs by Status Loaded',

    ChangeToeStatusFromDone = '[Toe Dashboard] change toe status to validated or ongoing',
    ChangeToeStatusFromOngoing = '[Toe Dashboard] change toe status to validated ',
    ToeEmptyAction = '[Toe Effect] empty action',

    RevertToe = '[ToE Dashboard] Revert toe back',
    ToeReverted = '[ToE Effect] Toe is reverted'
}

export class ToeOnServerCreated implements Action {
    readonly type = ToeActionTypes.ToeOnServerCreated;

    constructor(public payload: {
        toe: ToeModel,
        workers: SupportingValuerModel[],
        rdms: number [],
        tp_list: AssetClassModel [],
        coi_properties: CoiDetailsTPModel [],
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        valuations: ValuationModel[],
        paymentTerms: PaymentTermModel[],
        standards: any[],
        fileList: any[],
        isDuplicated: boolean,
    }) {
    }
}

export class ToeClear implements Action {
    readonly type = ToeActionTypes.ToeClear;

    constructor() {
    }
}

export class ToeCreated implements Action {
    readonly type = ToeActionTypes.ToeCreated;

    constructor(public payload: { toe: ToeModel }) {
    }
}


export class ToeOnServerRestored implements Action {
    readonly type = ToeActionTypes.ToeOnServerRestored;

    constructor(public payload: { toeId: number }) {
    }
}


export class ToeRestored implements Action {
    readonly type = ToeActionTypes.ToeRestored;

    constructor(public payload: { toe: ToeModel }) {
    }
}

export class ToeOnServerAdminRestored implements Action {
    readonly type = ToeActionTypes.ToeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}


export class ToeAdminRestored implements Action {
    readonly type = ToeActionTypes.ToeAdminRestored;

    constructor(public payload: { item: ToeModel }) {
    }
}

export class ToeDeletedFromTrash implements Action {
    readonly type = ToeActionTypes.ToeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ToeDeletedFromAdminTrash implements Action {
    readonly type = ToeActionTypes.ToeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ToeOnServerUpdated implements Action {
    readonly type = ToeActionTypes.ToeOnServerUpdated;
    constructor() {}
}

export class ToeUpdated implements Action {
    readonly type = ToeActionTypes.ToeUpdated;

    constructor(public payload: {
        toe: ToeModel,
        workers: SupportingValuerModel[],
        partialToe: Update<ToeModel>,
        rdms: number [],
        tp_list: AssetClassModel [],
        coi_properties: CoiDetailsTPModel [],
        payment: PaymentModel,
        paymentTaxes: PaymentTaxModel[],
        valuations: ValuationModel[],
        paymentTerms: PaymentTermModel[],
        standards: any[],
        fileList: any[],
    }) {
    }

    // constructor(public payload: {
    //     partialtoe: Update<ToeModel>, // For State update
    //     toe: ToeModel // For Server update (through service)
    // }) {
    // }
}

export class ToeStatusUpdated implements Action {
    readonly type = ToeActionTypes.ToeStatusUpdated;

    constructor(public payload: {
        toe: Update<ToeModel>
    }) {
    }
}

export class OneToeDeleted implements Action {
    readonly type = ToeActionTypes.OneToeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ManyToesDeleted implements Action {
    readonly type = ToeActionTypes.ManyToesDeleted;

    constructor(public payload: { ids: number[] }) {
    }
}

export class ToesPageRequested implements Action {
    readonly type = ToeActionTypes.ToesPageRequested;

    constructor(public payload: { page: QueryParamsModel, assignmentId: number }) {
    }
}

export class ToesTrashRequested implements Action {
    readonly type = ToeActionTypes.ToeTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}


export class ToeTrashFlushed implements Action {
    readonly type = ToeActionTypes.ToeTrashFlushed;

    constructor() {
    }
}

export class ToesPageLoaded implements Action {
    readonly type = ToeActionTypes.ToesPageLoaded;

    constructor(public payload: { toes: ToeModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ToesPageCancelled implements Action {
    readonly type = ToeActionTypes.ToesPageCancelled;
}

export class ToesPageToggleLoading implements Action {
    readonly type = ToeActionTypes.ToesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ToeActionToggleLoading implements Action {
    readonly type = ToeActionTypes.ToeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OneToeRequest implements Action {
    readonly type = ToeActionTypes.OneToeRequest;

    constructor(public payload: { id: number }) {
    }
}

export class OneToeLoaded implements Action {
    readonly type = ToeActionTypes.OneToeLoaded;

    constructor(public payload: { toe: ToeModel }) {
    }
}

export class AllToesByStatusRequested implements Action {
    readonly type = ToeActionTypes.AllToesByStatusRequested;

    constructor(public payload: { status: number, page: QueryParamsModel }) {
    }
}

export class AllToesByStatusLoaded implements Action {
    readonly type = ToeActionTypes.AllToesByStatusLoaded;

    constructor(public payload: {
        toes: ToeModel[],
        page: QueryParamsModel,
        totalCount: number,
    }) {
    }
}

export class ChangeToeStatusFromDone implements Action {
    readonly type = ToeActionTypes.ChangeToeStatusFromDone;
    constructor(public payload: {toeID: number}){}
}

export class ChangeToeStatusFromOngoing implements Action {
    readonly type = ToeActionTypes.ChangeToeStatusFromOngoing;
    constructor(public payload: {toeID: number}) {}
}

export class ToeEmptyAction implements Action {
    readonly type = ToeActionTypes.ToeEmptyAction;
    constructor() {}
}

export class RevertToe implements Action {
    readonly type = ToeActionTypes.RevertToe;
    constructor(public payload: {
        update: Update<ToeModel>
    }) {}
}

export class ToeReverted implements Action {
    readonly type = ToeActionTypes.ToeReverted;
    constructor(){}
}

export type ToeActions = ToeOnServerCreated
    | ToeClear
    | ToeCreated
    | ToeOnServerRestored
    | ToeRestored
    | ToeOnServerAdminRestored
    | ToeAdminRestored
    | ToeDeletedFromTrash
    | ToeDeletedFromAdminTrash
    | ToeTrashFlushed
    | ToeUpdated
    | ToeStatusUpdated
    | OneToeDeleted
    | ManyToesDeleted
    | ToesPageRequested
    | ToesPageLoaded
    | ToesPageCancelled
    | ToesPageToggleLoading
    | ToeActionToggleLoading
    | OneToeRequest
    | OneToeLoaded
    | AllToesByStatusRequested
    | AllToesByStatusLoaded
    | ToeOnServerUpdated
    | ChangeToeStatusFromDone
    | ToeEmptyAction
    | RevertToe
    | ToeReverted;
