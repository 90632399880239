import {ExternalFacadeModel} from '../_models/external-facade.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ExternalFacadesState} from '../_reducers/external-facade.reducer';
import * as fromExternalFacade from '../_reducers/external-facade.reducer';
import {each} from 'lodash';

export const selectExternalFacadesState = createFeatureSelector<ExternalFacadesState>('external-facades');

export const selectExternalFacadeById = (id: number) => createSelector(
    selectExternalFacadesState,
    state => state.entities[id]
);

export const selectAllExternalFacades = createSelector(
    selectExternalFacadesState,
    fromExternalFacade.selectAll
);

export const selectAllExternalFacadesIds = createSelector(
    selectExternalFacadesState,
    fromExternalFacade.selectIds
);

export const allExternalFacadesLoaded = createSelector(
    selectExternalFacadesState,
    state => state.isAllExternalFacadesLoaded
);


export const selectExternalFacadesPageLoading = createSelector(
    selectExternalFacadesState,
    state => state.listLoading
);

export const selectExternalFacadesActionLoading = createSelector(
    selectExternalFacadesState,
    state => state.actionLoading
);

export const selectLastCreatedExternalFacadeId = createSelector(
    selectExternalFacadesState,
    state => state.lastCreatedExternalFacadeId
);

export const selectExternalFacadesShowInitWaitingMessage = createSelector(
    selectExternalFacadesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedExternalFacadeCount = createSelector(
    selectExternalFacadesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedExternalFacadeCount = createSelector(
    selectExternalFacadesState,
    (state) => state.totalAdminTrashed
);


export const selectExternalFacadeQueryResult = createSelector(
    selectExternalFacadesState,
    state => {
        const items: ExternalFacadeModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ExternalFacadeModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
