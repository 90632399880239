import { AbstractControl } from '@angular/forms';

export function ValidateProperty(control: AbstractControl) {
    if (control.value && !control.value.hasOwnProperty('id')) {
        return { validUrl: true };
    }
    return null;
}

export function TagRequiredValidator(control: AbstractControl) {
    if (control.value.length === 0) {
        return { tagRequired: true };
    }
    return null;
}
