import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Behavior } from 'popper.js';
import { BehaviorSubject, Observable } from 'rxjs';
import { AgencyModel } from 'src/app/core/admin';
import { ReportingToeRelatedDocuments } from 'src/app/core/reporting/_models/reporting_process.model';
import { SupportingValuerModel } from 'src/app/core/toe/_models/supporting-valuer.model';
import { TypesUtilsService } from 'src/app/core/_base/crud';

interface Document {
  id: number;
  name: string;
  selected: boolean;
}

interface ToeDocuments {
  agency: {
    name: string;
    documents: Document[]
  },
  leadValuer: {
    name: string;
    certificates: Document[],
    documents: Document[]
  },
  members: {
    id: number;
    name: string;
    certificates: Document[],
    documents: Document[]
  }[],
  otherDocs: Document[]
}

type userType = 'agency'|'leadValuer'|'member'|'other';
type docType = 'certificate' | 'document';

@Component({
  selector: 'kt-toe-docs',
  templateUrl: './toe-docs.component.html',
  styleUrls: ['./toe-docs.component.scss']
})
export class ToeDocsComponent implements OnInit {
  @Input()
  readonly: boolean;
  @Input()
  toeRelatedDocs: ReportingToeRelatedDocuments;

  @Input()
  agency: AgencyModel;

  @Input()
  leadValuer: SupportingValuerModel;

  @Input()
  members: SupportingValuerModel[]

  @Input()
  otherDocs: any[]

  private _currentToeDoc: ToeDocuments = null;
  private _toeDocs$: BehaviorSubject<ToeDocuments> = new BehaviorSubject(this._currentToeDoc);
  public toeDocs$: Observable<ToeDocuments> = this._toeDocs$.asObservable();

  constructor(
    private typesUtilsService: TypesUtilsService
  ) {}

  ngOnInit(): void {
    this._currentToeDoc = {
      agency: {
        name: this.agency.name,
        documents: this.agency.certificates.map(cert => {
          return {
            id: cert.id,
            name: `${cert.title} ${this.isExpired(cert.date) ? '(expired)' : ''}`,
            selected: this.toeRelatedDocs.agency_documents.includes(cert.id)
          }
        })
      },
      leadValuer: {
        name: `${this.leadValuer.worker.first_name} ${this.leadValuer.worker.last_name}`,
        certificates: this.leadValuer.worker.certificates.map(cert => {
          return {
            id: cert.id,
            name: `${cert.cert_name ? cert.cert_name : cert.organisation.name}/${cert.org_name ? cert.org_name : cert.certification.name} ${this.isExpired(cert.date) ? '(expired)' : ''}`,
            selected: this.toeRelatedDocs.lead_valuer.certificates.includes(cert.id)
          }
        }),
        documents: this.leadValuer.worker.files.map(file => {
          return {
            id: file.id,
            name: `${file.title ? file.title : 'N/A'} ${this.addExpired(file)}`,
            selected: this.toeRelatedDocs.lead_valuer.documents.includes(file.id)
          }
        })
      },
      members: this.members.map(member => {
        let certificates = [];
        let documents = [];
        const _member = this.toeRelatedDocs.members.find(m => m.id == member.id);
        if (_member) {
          certificates = member.worker.certificates.map(cert => {
            return {
              id: cert.id,
              name: `${cert.cert_name ? cert.cert_name : cert.organisation.name}/${cert.org_name ? cert.org_name : cert.certification.name} ${this.addExpired(cert)}`,
              selected: _member.certificates.includes(cert.id)
            }
          });
          documents = member.worker.files.map(file => {
            return {
              id: file.id,
              name: `${file.title ? file.title : 'N/A'} ${this.addExpired(file)}`,
              selected: _member.documents.includes(file.id)
            }
          })
        }
        return {
          id: member.id,
          name: `${member.worker.first_name} ${member.worker.last_name}`,
          certificates: certificates,
          documents: documents
        }
      }),
      otherDocs: this.otherDocs.map(d => {
        return {
          id: d.id,
          name: d.title,
          selected: this.toeRelatedDocs.otherDocs.includes(d.id)
        }
      })
    }
    this._toeDocs$.next(this._currentToeDoc);
  }

  addExpired(f) {
    return this.isExpired(f.date) ? '(expired)' : '';
  }

  isExpired(dateValue) {
    if (dateValue == null || dateValue == undefined || dateValue.length == 0) {
      return false;
    }
    const date = this.typesUtilsService.getDateFromString(dateValue);
    return this.typesUtilsService.compareTwoDate(date, new Date()) <= 0;
  }

  public certificateChange(event: MatCheckboxChange, usertype: userType, docType: docType, docID: number, userId: number = undefined) {
    switch (usertype) {
      case 'agency': {
        let agencyDocs = this._manupulateArr(this._currentToeDoc.agency.documents, docID, event.checked);
        this._currentToeDoc = {
          ...this._currentToeDoc,
          agency: {
            name: this._currentToeDoc.agency.name,
            documents: agencyDocs
          }
        }
        this._toeDocs$.next(this._currentToeDoc);
        return;
      }
      case 'leadValuer': {
        switch (docType) {
          case 'certificate': {
            let lvCerts = this._manupulateArr(this._currentToeDoc.leadValuer.certificates, docID, event.checked);
            this._currentToeDoc = {
              ...this._currentToeDoc,
              leadValuer: {
                name: this._currentToeDoc.leadValuer.name,
                documents: this._currentToeDoc.leadValuer.documents,
                certificates: lvCerts
              }
            }
            this._toeDocs$.next(this._currentToeDoc);
            return;
          }
          case 'document': {
            let lvDocs = this._manupulateArr(this._currentToeDoc.leadValuer.documents, docID, event.checked);
            this._currentToeDoc = {
              ...this._currentToeDoc,
              leadValuer: {
                name: this._currentToeDoc.leadValuer.name,
                documents: lvDocs,
                certificates: this._currentToeDoc.leadValuer.certificates
              }
            }
            return;
          }
          default:
            return;
        }
      }
      case 'member': {
        const memberIndx = this._currentToeDoc.members.findIndex(m => m.id == userId);
        if (memberIndx == -1) {
          return;
        }
        const member = this._currentToeDoc.members[memberIndx];
        switch (docType) {
          case 'certificate': {
            let memberCerts = this._manupulateArr(member.certificates, docID, event.checked);
            const newMember = {
              ...member,
              certificates: memberCerts
            }
            this._currentToeDoc.members.splice(memberIndx, 1, newMember);
            this._toeDocs$.next(this._currentToeDoc);
            return;
          }
          case 'document': {
            let memberDocs = this._manupulateArr(member.documents, docID, event.checked);
            const newMember = {
              ...member,
              documents: memberDocs
            }
            this._currentToeDoc.members.splice(memberIndx, 1, newMember);
            this._toeDocs$.next(this._currentToeDoc);
            return;
          }
          default:
            return;
        }
      }
      case 'other': {
        let otherDocs = this._manupulateArr(this._currentToeDoc.otherDocs, docID, event.checked);
        this._currentToeDoc = {
          ...this._currentToeDoc,
          otherDocs
        };
        this._toeDocs$.next(this._currentToeDoc);
        return;
      }
      default: {
        return;
      }
    }
  }

  private _manupulateArr(arr: Document[], id: number, selected: boolean): Document[] {
    const docIndx = arr.findIndex(item => item.id == id);
    if (docIndx == -1) {
      return arr;
    }
    const doc = {...arr[docIndx], selected: selected};
    arr.splice(docIndx, 1, doc);
    return arr;
  }

  public getToeDocs(): ReportingToeRelatedDocuments {
    return {
      agency_documents: this._currentToeDoc.agency.documents.filter(d => d.selected).map(d => d.id),
      lead_valuer: {
        certificates: this._currentToeDoc.leadValuer.certificates.filter(c => c.selected).map(cert => cert.id),
        documents: this._currentToeDoc.leadValuer.documents.filter(d => d.selected).map(doc => doc.id)
      },
      members: this._currentToeDoc.members.map(member => {
        return {
          id: member.id,
          certificates: member.certificates.filter(c => c.selected).map(cert => cert.id),
          documents: member.documents.filter(d => d.selected).map(doc => doc.id)
        }
      }),
      otherDocs: this._currentToeDoc.otherDocs.filter(d => d.selected).map(d => d.id)
    }
  }

  public showToeDoc() {}

}
