// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter, Update} from '@ngrx/entity';
// Actions
import {PaymentActions, PaymentActionTypes} from '../_actions/payment.actions';
// Models
import {PaymentModel} from '../_models/payment.model';
import {QueryParamsModel} from '../../_base/crud';

export interface PaymentsState extends EntityState<PaymentModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    trashed: number;
    lastCreatedPaymentId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PaymentModel> = createEntityAdapter<PaymentModel>();

export const initialPaymentsState: PaymentsState = adapter.getInitialState({
    paymentForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    trashed: 0,
    lastCreatedPaymentId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function paymentsReducer(state = initialPaymentsState, action: PaymentActions): PaymentsState {
    switch (action.type) {
        case PaymentActionTypes.PaymentsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPaymentId: undefined
            };
        }
        case PaymentActionTypes.PaymentActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case PaymentActionTypes.PaymentOnServerCreated:
            return {
                ...state
            };
        case PaymentActionTypes.PaymentCreated:
            return adapter.addOne(action.payload.payment, {
                ...state, lastCreatedPaymentId: action.payload.payment.id
            });
        case PaymentActionTypes.PaymentOnServerUpdated:
            return {
                ...state
            };
        case PaymentActionTypes.PaymentUpdated:
            return adapter.addOne(action.payload.payment, {
                ...state, lastCreatedPaymentId: action.payload.payment.id
            });
        case PaymentActionTypes.PaymentsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case PaymentActionTypes.PaymentsPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.payments, {
                ...initialPaymentsState,
                totalCount: action.payload.totalCount,
                trashed: action.payload.trashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case PaymentActionTypes.PaymentOnServerRestored:
            return {
                ...state
            };
        case PaymentActionTypes.PaymentRestored:
            return adapter.addOne(action.payload.payment, {
                ...state,
                lastCreatedPaymentId: action.payload.payment.id,
                trashed: state.trashed - 1
            });
        case PaymentActionTypes.PaymentDeletedFromTrash:
            return {
                ...state,
                trashed: state.trashed - 1
            };
        case PaymentActionTypes.OnePaymentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, trashed: state.trashed + 1
            });
        default:
            return state;
    }
}

export const getPaymentState = createFeatureSelector<PaymentModel>('payments');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
