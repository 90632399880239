<div [formGroup]="formGroup" *ngIf="formGroup" [ngClass]="{'form-disabled': readonly}">
    <div class="row">
        <div class="col-lg-12">
            Please indicate here all the services, utilities and or infrastructure that serve the property. They shall include, but are not limited to, all items that form an integral part of the property.
        </div>

        <ng-container *ngIf="isComplexForm">
            <div class="col-lg-12 mt-4">
                <mat-checkbox formControlName="road">
                    <strong>Road and other viable access</strong>
                </mat-checkbox>
            </div>
            <ng-container *ngIf="formGroup.controls.road.value">
                <div class="col-lg-6 mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="status_n_quality" placeholder="Status and quality" />
                    </mat-form-field>
                </div>
                <div class="col-lg-6 mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="access_points" placeholder="Access/egress points (vehicular and pedestrian)" />
                    </mat-form-field>
                </div>
                <div class="col-lg-6 mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="length_of_road" placeholder="Length of road frontage" />
                    </mat-form-field>
                </div>
                <div class="col-lg-6 mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="ownership" placeholder="Ownership if in the ownership of another and consent(s)" />
                    </mat-form-field>
                </div>
            </ng-container>

            <div class="col-lg-12 mt-8">
                <mat-checkbox formControlName="central_heating">
                    <strong>Central Heating</strong>
                </mat-checkbox>
            </div>
            <ng-container>
                <div class="col-lg-4 mt-4" *ngFor="let heating of heatings;">
                    <mat-checkbox [checked]="heating.checked" (change)="onHeatingChange($event, heating)">
                        {{heating.title}}
                    </mat-checkbox>
                </div>
                <div class="col-lg-12 mt-4" *ngIf="showOtherHeating">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="other_heating" type="text" placeholder="Please specify">
                        <mat-error>
                            Required field
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="hasServices">
            <div class="mt-8" [ngClass]="{'col-lg-12': isComplexForm, 'col-lg-4': !isComplexForm}">
                <mat-checkbox formControlName="all_of_it">
                    <strong>All of the following</strong>
                </mat-checkbox>
            </div>
            <ng-container *ngIf="!isComplexForm">
                <div class="col-lg-4 mt-8">
                    <mat-checkbox formControlName="road">
                        Road and other viable access
                    </mat-checkbox>
                </div>
                <div class="col-lg-4 mt-8">
                    <mat-checkbox formControlName="central_heating">
                        Central Heating
                    </mat-checkbox>
                </div>
            </ng-container>
            <ng-container *ngFor="let item of services">
                <div class="col-lg-4 mt-4" *ngIf="item.id != 10 || isComplexForm">
                    <mat-checkbox [checked]="item.checked" (change)="onServiceChange($event, item)">
                        <span>
                            {{item.title}}
                        </span>
                    </mat-checkbox>
                </div>
            </ng-container>
            <div class="col-lg-12 mt-4" *ngIf="showOtherService">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput formControlName="other_service" type="text" placeholder="Please specify">
                    <mat-error>
                        Required field
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</div>