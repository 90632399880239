// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SourceTypesService} from '../_services/source-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSourceTypesLoaded} from '../_selectors/source-type.selectors';
// Actions
import {
    AllSourceTypesLoaded,
    AllSourceTypesRequested,
    SourceTypeActionTypes,
    SourceTypesPageRequested,
    SourceTypesPageLoaded,
    SourceTypeUpdated,
    SourceTypesPageToggleLoading,
    SourceTypeDeleted,
    SourceTypeOnServerCreated,
    SourceTypeCreated,
    SourceTypeAdminRestored,
    SourceTypeDeletedFromAdminTrash,
    SourceTypeDeletedFromTrash,
    SourceTypeOnServerAdminRestored,
    SourceTypeOnServerRestored,
    SourceTypeRestored,
    SourceTypeTrashFlushed,
    SourceTypesActionToggleLoading
} from '../_actions/source-type.actions';

@Injectable()
export class SourceTypeEffects {
    showPageLoadingDispatcher = new SourceTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SourceTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SourceTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSourceTypes$ = this.actions$
        .pipe(
            ofType<AllSourceTypesRequested>(SourceTypeActionTypes.AllSourceTypesRequested),
            withLatestFrom(this.store.pipe(select(allSourceTypesLoaded))),
            filter(([action, isAllSourceTypesLoaded]) => !isAllSourceTypesLoaded),
            mergeMap(() => this.service.getAllSourceTypes()),
            map(res => {
                return new AllSourceTypesLoaded({sourceTypes: res.data});
            })
        );

    @Effect()
    loadSourceTypesPage$ = this.actions$
        .pipe(
            ofType<SourceTypesPageRequested>(SourceTypeActionTypes.SourceTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findSourceTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SourceTypesPageLoaded({
                    sourceTypes: result.data,
                    totalCount: result.pagination.total,totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeDeleted>(SourceTypeActionTypes.SourceTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteSourceType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeUpdated>(SourceTypeActionTypes.SourceTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateSourceType(payload.sourceType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeOnServerCreated>(SourceTypeActionTypes.SourceTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createSourceType(payload.sourceType).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceTypeCreated({sourceType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushSourceTypeTrash$ = this.actions$
        .pipe(
            ofType<SourceTypeTrashFlushed>(SourceTypeActionTypes.SourceTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeOnServerRestored>(SourceTypeActionTypes.SourceTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeOnServerAdminRestored>(SourceTypeActionTypes.SourceTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeDeletedFromTrash>(SourceTypeActionTypes.SourceTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSourceType$ = this.actions$
        .pipe(
            ofType<SourceTypeDeletedFromAdminTrash>(SourceTypeActionTypes.SourceTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: SourceTypesService, private store: Store<AppState>) {
    }
}
