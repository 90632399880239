// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
// Models
import {PaymentModel} from '../_models/payment.model';
import {PaymentTaxModel} from '../_models/payment-tax.model';
import {ValuationModel} from '../_models/valuation.model';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const RESIDENTIALS_PATH = '/payment';

@Injectable()
export class PaymentsService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    findPayments(queryParams: QueryParamsModel, toeId: number): Observable<QueryResultsModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_COMPARABLES_URL + toeId + RESIDENTIALS_PATH;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getApartments(queryParams: QueryParamsModel): Observable<any> {


        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
        };
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + '/data';
        return this.http.post<any>(url, _body, {headers: httpHeaders});
    }

    createPayment(_payment: PaymentModel,
                  _paymentTaxes: PaymentTaxModel[],
                  _valuations: ValuationModel[],): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            payment: _payment,
            paymentTaxes: _paymentTaxes,
            valuations: _valuations,

        };
        const url = this.API_COMPARABLES_URL + 'payment';
        return this.http.post<any>(url, body, {headers: httpHeaders});
    }

    updatePayment(_payment: PaymentModel,
                  _paymentTaxes: PaymentTaxModel[],
                  _valuations: ValuationModel[],): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            payment: _payment,
            paymentTaxes: _paymentTaxes,
            valuations: _valuations,
        };
        const url = this.API_COMPARABLES_URL + 'payment';
        return this.http.put(url + `/${_payment.terms_of_engagement_id}`, body, {headers: httpHeader});
    }

    // Trash
    getTrashedPayments(): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<PaymentModel[]>(url + `/trash`, {headers: httpHeaders});
    }

    // admin Trash
    getAdminTrashedPayments(paymentId: number): Observable<PaymentModel[]> {
        const url = this.API_COMPARABLES_URL + paymentId + RESIDENTIALS_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<PaymentModel[]>(url + `/admin/trash`, {headers: httpHeaders});
    }

    // Trash
    // delete from trash
    deleteFromTrash(paymentId: number): Observable<PaymentModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<PaymentModel>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/residentials` + `/trash/${paymentId}`,
            {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(paymentId: number): Observable<PaymentModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<PaymentModel>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/residentials` +
            `/trash/${paymentId}`, {headers: httpHeaders});
    }

    deletePayment(paymentId: number): Observable<PaymentModel> {
        const url = `${environment.baseApiUrl + `api/${this.subDomainService.subDomain}comparable/residentials`}/${paymentId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<PaymentModel>(url, {headers: httpHeaders});
    }

    getPayment(paymentId: number): Observable<PaymentModel> {
        const url = this.API_COMPARABLES_URL + 'payment';
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<PaymentModel>(url + `/${paymentId}`, {headers: httpHeaders});
    }

    getBuildingInfos(): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/building-informations`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getTenuresOfAc(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/${id}/tenures`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getSizesOfAc(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/${id}/sizes`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }


    getApartmentsByLocation(latLng1: google.maps.LatLng, latLng2: google.maps.LatLng): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            max_lng: latLng2.lng(),
            max_lat: latLng2.lat(),
            min_lng: latLng1.lng(),
            min_lat: latLng1.lat(),
        };
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        return this.http.post(url + `/map`, body, {headers: httpHeader});
    }
}
