import { ValuationProcessTargetPropertyRemote } from "../_services/valuation-process.service"
import { VBuilding } from "./valuation-asset-class-building.model"
import { displayConsiderations, getGarageRange, mapConsiderationRemoteToDomain, mapVAssetClassSizeRemoteToDomain, VAssetClassConsideration, VAssetClassConsiderationRemote, VAssetClassSize, VAssetClassSizeRemote, VGroundRemote, VInternalAspectRemote, VLocationData } from "./valuation-asset-class-common.models"
import { PictureModel, TypeOfInspection, ValuationProcessOfficeModel } from "./valuation-process-target-property.model"

export type VOfficeModel = {
    refNum: string,
    propertySubType: 'Office',
    propertySubTypeName: string,

    locationData: VLocationData,
    property: VOfficeAboutProperty,
    internalAspect: VOfficeInternalAspect,
    ground: VOfficeGround

    building: VBuilding
    oldBuildingInfo: any,

    pictures: PictureModel[]
    sizes: VAssetClassSize[]
}

export type VCOfficeModel = VOfficeModel & {
    id: number,

    // Info
    considerations: VAssetClassConsideration[],
    displayConsiderations: VAssetClassConsideration[]

    // Additional
    status: number,
    usedTimes: number,
    propertyTypeName: string
}

export type VTPOfficeModel = VOfficeModel & {
    inspectionInfo: {
        typeOfInspection: TypeOfInspection,
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string
    },
    refNum: 'tp',
}

export type VOfficeAboutProperty = {
    subTypeCategory: string,
    subCategory: string,
    stateOfRepair: string,
    handoverStandard: string,
    floors: string,
    energyEfficiencyGrade: string,
    generalDescription: string,
}

export type VOfficeInternalAspect = {
    windows: string,
    windowsComment: string,
    ceiling: string,
    ceilingComment: string,
    wallsAndPartition: string,
    wallsAndPartitionComment: string,
    floors: string,
    floorsComment: string,
    builtInFittings: string,
    others: string,
}

export type VOfficeGround = {
    indoorGarage: string
    indoorGarageComment: string,
    outdoorGarage: string,
    outdoorGarageComment: string,
    externalAreas: string,
    externalAreasComment: string
}

export type VCOfficeRemote = {
    id: number,
    refNum: string,
    usedTimes: number,
    status: number,
    property_sub_type_id: 3,
    propertySubTypeName: string,
    topPropertyTypeName: string,
    subTypeCategoryName: string,
    subCategoryName: string,
    state_of_repair_name: string,
    handover_standard_name: string,
    general_description: string,
    floor_location: string,
    energy_efficiency_grade_name: string,

    locationData: {
        latitude: string,
        longitude: string,
        countryName: string,
        cityName: string,
        zipCode: string,
        locationGradeName: string,
        address: string,
        timezoneOffset: string | null
    }
    sizes: VAssetClassSizeRemote[],
    considerations: VAssetClassConsiderationRemote[],
    pictures: PictureModel[]
    buildingInfo: {
        name: string,
        buildingType: string,
        completionYear: string,
        buildingTypeComment: string,
        buildingGrade: string,
        energyEfficiencyGrade: string,
        developer: string,
        anchorTenant: string,
        foundationType: string,
        buildingDescription: string,
        pictures: PictureModel[],
    },
    oldBuildingInfo: any,
    internal_aspect: VInternalAspectRemote,
    ground: VGroundRemote
}

export function mapComparableOfficeRemoteToDomain(comparable: VCOfficeRemote): VCOfficeModel {
    const considerations = mapConsiderationRemoteToDomain(comparable.considerations)
    return {
        id: comparable.id,
        refNum: comparable.refNum,
        propertySubType: 'Office',
        propertySubTypeName: comparable.propertySubTypeName,
        propertyTypeName: comparable.topPropertyTypeName,
        status: comparable.status,
        usedTimes: comparable.usedTimes,
        locationData: {
            latitude: Number(comparable.locationData.latitude),
            longitude: Number(comparable.locationData.longitude),
            country: comparable.locationData.countryName,
            city: comparable.locationData.cityName,
            zipCode: comparable.locationData.zipCode,
            address: comparable.locationData.address,
            locationGrade: comparable.locationData.locationGradeName,
            locationSurrounding: null,
            timezoneOffset: comparable.locationData.timezoneOffset
        },
        sizes: mapVAssetClassSizeRemoteToDomain(comparable.sizes),
        internalAspect: mapVOfficeInternalAspectRemoteToDomain(comparable.internal_aspect),
        property: {
            subCategory: comparable.subCategoryName,
            subTypeCategory: comparable.subTypeCategoryName,
            stateOfRepair: comparable.state_of_repair_name,
            handoverStandard: comparable.handover_standard_name,
            generalDescription: comparable.general_description,
            floors: comparable.floor_location,
            energyEfficiencyGrade: comparable.energy_efficiency_grade_name,
        },
        oldBuildingInfo: comparable.oldBuildingInfo,
        building: comparable.buildingInfo,
        considerations,
        displayConsiderations: displayConsiderations(considerations),
        pictures: comparable.pictures,
        ground: mapVOfficeGroundRemoteToDomain(comparable.ground)
    }
}

export function mapVTPOfficeRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessOfficeModel): VTPOfficeModel {
    return {
        refNum: 'tp',
        propertySubType: generalInfo.propertySubType,
        propertySubTypeName: tpRemote.propertySubTypeName,
        locationData: {
            latitude: generalInfo.locationData.latitude,
            longitude: generalInfo.locationData.longitude,
            country: generalInfo.locationData.countryName,
            city: generalInfo.locationData.cityName,
            zipCode: generalInfo.locationData.zipCode,
            address: generalInfo.locationData.address,
            locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
            locationSurrounding: tpRemote.locationSurrounding,
            timezoneOffset: tpRemote.locationData?.timezoneOffset
        },
        inspectionInfo: {
            typeOfInspection: tpRemote.inspectionTypeId,
            inspectionDate: new Date(tpRemote.inspectionDate),
            anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
            limitationDescription: tpRemote.limitationDesc
        },
        property: {
            subTypeCategory: tpRemote.subTypeCategoryName == 'N/A' ? null : tpRemote.subTypeCategoryName,
            subCategory: tpRemote.subCategoryName == 'N/A' ? null : tpRemote.subCategoryName,
            stateOfRepair: tpRemote.aboutProperty.stateOfRepairName,
            handoverStandard: tpRemote.aboutProperty.handoverStandardName,
            energyEfficiencyGrade: tpRemote.aboutProperty.energyEfficiencyGradeName,
            generalDescription: tpRemote.aboutProperty.generalDescription,
            floors: tpRemote.aboutProperty.floorLocation,
        },
        building: {
            name: tpRemote.buildingInfo.name,
            completionYear: tpRemote.buildingInfo.completionYear,
            buildingType: tpRemote.buildingInfo.buildingType == 'N/A' || tpRemote.buildingInfo.buildingType == null ? null : tpRemote.buildingInfo.buildingType,
            buildingTypeComment: tpRemote.buildingInfo.buildingTypeComment,
            buildingGrade: tpRemote.buildingInfo.buildingGrade == 'N/A' || tpRemote.buildingInfo.buildingGrade == null ? null : tpRemote.buildingInfo.buildingGrade,
            energyEfficiencyGrade: tpRemote.buildingInfo.energyEfficiencyGrade,
            developer: tpRemote.buildingInfo.developer,
            anchorTenant: tpRemote.buildingInfo.anchorTenant,
            foundationType: tpRemote.buildingInfo.foundationType,
            buildingDescription: tpRemote.buildingInfo.buildingDescription,
            pictures: tpRemote.buildingInfo.pictures
        },
        oldBuildingInfo: tpRemote.oldBuildingInfo,
        pictures: tpRemote.pictures,
        internalAspect: mapVOfficeInternalAspectRemoteToDomain(tpRemote.internalAspect),
        ground: mapVOfficeGroundRemoteToDomain(tpRemote.ground),
        sizes: mapVAssetClassSizeRemoteToDomain(tpRemote.sizes)
    }
}

export function mapVOfficeInternalAspectRemoteToDomain(remote: VInternalAspectRemote): VOfficeInternalAspect {
    return {
        windows: remote.windows_name,
        windowsComment: remote.windows_comment,
        ceiling: remote.ceiling_name,
        ceilingComment: remote.ceiling_comment,
        wallsAndPartition: remote.walls_and_partition_name,
        wallsAndPartitionComment: remote.walls_and_partition_comment,
        floors: remote.floor_type_name,
        floorsComment: remote.floor_type_comment,
        builtInFittings: remote.builtin_fitting_names,
        others: remote.has_other ? remote.other_aspects : null
    }
}

function mapVOfficeGroundRemoteToDomain(remote: VGroundRemote): VOfficeGround {
    return {
        indoorGarage: getGarageRange(remote.indoor_garage),
        indoorGarageComment: remote.indoor_garage_comment,
        outdoorGarage: getGarageRange(remote.outdoor_garage),
        outdoorGarageComment: remote.outdoor_garage_comment,
        externalAreas: remote.external_areas_name,
        externalAreasComment: remote.external_area_comment
    }
}