import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { AppState } from "../../reducers";
import * as valuationNotesActions from '../_actions/valuation-notes.action';
import { ValuationNoteModel } from "../_models/valuation-note.model";

@Injectable()
export class ValuationNotesEffects {
    constructor(private actions$: Actions, private store$: Store<AppState>) {}
    @Effect()
    loadData$ = this.actions$.pipe(
        ofType<valuationNotesActions.LoadData>(valuationNotesActions.ActionTypes.LoadData),
        map(({payload}) => {
            let id = 0;
            const data: ValuationNoteModel[] = [];
            payload.data.forEach(item => {
                const _new = Object.assign({}, item) as ValuationNoteModel;
                _new.front_id = id;
                id++;
                data.push(_new);
            });
            return new valuationNotesActions.DataLoaded({
                data,
                lastCreateId: id - 1
            });
        })
    )
}