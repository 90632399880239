import {BaseModel} from '../../_base/crud';
import {KeyCategoryModel} from '../../linked-tables';

export class KeyPlaceModel extends BaseModel {
    id: number;

    name: string;
    category_id: number;
    category_name: string;
    category: KeyCategoryModel;
    description: string;
    picture: string;
    what3words: string;
    latitude: number;
    longitude: number;
    city_of_location: string;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    user_deleted: Date;

    deletedBy: string;
    userDeletedBy: string;

    createdBy: string;
    lastUpdatedBy: string;
    valuation_id: number | null;

    clear(): void {
        this.id = undefined;

        this.name = '';
        this.category_id = undefined;
        this.category_name = '';
        this.description = '';
        this.picture = '';
        this.latitude = 0;
        this.longitude = 0;
        this.city_of_location = '';
        this.what3words = '';

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
        this.user_deleted = null;
        this.deletedBy = '';
        this.userDeletedBy = '';
        this.valuation_id = null;
    }
}

export interface KeyplacesStatus {
    total_lms: number,
    total_different_locations: number,
    total_used_in_vals: number
}
