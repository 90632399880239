// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ContactActions, ContactActionTypes, ContactDeletedFromTrash} from '../_actions/contact.actions';
// Models
import {ClientContactModel} from '../_models/client-contact.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ContactsState extends EntityState<ClientContactModel> {
    isAllContactsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ClientContactModel[];
    lastCreatedContactId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    totalTrashed: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ClientContactModel> = createEntityAdapter<ClientContactModel>();

export const initialContactsState: ContactsState = adapter.getInitialState({
    isAllContactsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedContactId: undefined,
    listLoading: false,
    actionLoading: false,
    totalTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function contactsReducer(state = initialContactsState, action: ContactActions): ContactsState {
    switch (action.type) {
        case ContactActionTypes.ContactsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedContactId: undefined
            };
        case ContactActionTypes.ContactsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ContactActionTypes.ContactOnServerCreated:
            return {
                ...state
            };
        case ContactActionTypes.ContactCreated:
            return adapter.addOne(action.payload.contact, {
                ...state, lastCreatedContactId: action.payload.contact.id
            });

        case ContactActionTypes.ContactUpdated:
            return adapter.updateOne(action.payload.partialContact, state);

        case ContactActionTypes.ContactDeletedFromTrash:
            return adapter.removeOne(action.payload.contactId, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ContactActionTypes.AllContactsLoaded:
            return adapter.addAll(action.payload.contacts, {
                ...state, isAllContactsLoaded: true
            });

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
