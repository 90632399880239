// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsFacilityModel} from '../../_base/crud';
// Services
import {FacilitiesService} from '../_services/facility.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allFacilitiesLoaded} from '../_selectors/facility.selectors';
// Actions
import {
    AllFacilitiesLoaded,
    AllFacilitiesRequested,
    FacilityActionTypes,
    FacilitiesPageRequested,
    FacilitiesPageLoaded,
    FacilityUpdated,
    FacilitiesPageToggleLoading,
    FacilityDeleted,
    FacilityOnServerCreated,
    FacilityCreated,
    FacilityAdminRestored,
    FacilityDeletedFromAdminTrash,
    FacilityDeletedFromTrash,
    FacilityOnServerAdminRestored,
    FacilityOnServerRestored,
    FacilityRestored,
    FacilityTrashFlushed,
    FacilitiesActionToggleLoading
} from '../_actions/facility.actions';

@Injectable()
export class FacilityEffects {
    showPageLoadingDispatcher = new FacilitiesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new FacilitiesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new FacilitiesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllFacilities$ = this.actions$
        .pipe(
            ofType<AllFacilitiesRequested>(FacilityActionTypes.AllFacilitiesRequested),
            withLatestFrom(this.store.pipe(select(allFacilitiesLoaded))),
            filter(([action, isAllFacilitiesLoaded]) => !isAllFacilitiesLoaded),
            mergeMap(() => this.service.getAllFacilities()),
            map(res => {
                return new AllFacilitiesLoaded({facilities: res.data});
            })
        );

    @Effect()
    loadFacilitiesPage$ = this.actions$
        .pipe(
            ofType<FacilitiesPageRequested>(FacilityActionTypes.FacilitiesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findFacilities(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsFacilityModel = response[1];
                return new FacilitiesPageLoaded({
                    facilities: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteFacility$ = this.actions$
        .pipe(
            ofType<FacilityDeleted>(FacilityActionTypes.FacilityDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFacility(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateFacility$ = this.actions$
        .pipe(
            ofType<FacilityUpdated>(FacilityActionTypes.FacilityUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateFacility(payload.facility);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createFacility$ = this.actions$
        .pipe(
            ofType<FacilityOnServerCreated>(FacilityActionTypes.FacilityOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createFacility(payload.facility).pipe(
                    tap(res => {
                        this.store.dispatch(new FacilityCreated({facility: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushFacilityTrash$ = this.actions$
        .pipe(
            ofType<FacilityTrashFlushed>(FacilityActionTypes.FacilityTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreFacility$ = this.actions$
        .pipe(
            ofType<FacilityOnServerRestored>(FacilityActionTypes.FacilityOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FacilityRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminFacility$ = this.actions$
        .pipe(
            ofType<FacilityOnServerAdminRestored>(FacilityActionTypes.FacilityOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new FacilityAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashFacility$ = this.actions$
        .pipe(
            ofType<FacilityDeletedFromTrash>(FacilityActionTypes.FacilityDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashFacility$ = this.actions$
        .pipe(
            ofType<FacilityDeletedFromAdminTrash>(FacilityActionTypes.FacilityDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: FacilitiesService, private store: Store<AppState>) {
    }
}
