import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationComparableAction, ValuationComparableActionTypes } from "../_actions/valuation-comparable.actions";
import { ValuationComparableMapData } from "../_models/valuation-comparable.model";

export interface ValuationComparableState extends EntityState<ValuationComparableMapData> {
    selectedPropertySubTypes: number[]
}
export const adapter: EntityAdapter<ValuationComparableMapData> = createEntityAdapter<ValuationComparableMapData>({
    selectId: (entity) => entity.refNum
})
export const initialValuationComparableState: ValuationComparableState = adapter.getInitialState({
    selectedPropertySubTypes: []
})
export function valuationComparableReducer(
    state = initialValuationComparableState,
    action: ValuationComparableAction
): ValuationComparableState {
    switch (action.type) {
        case ValuationComparableActionTypes.LoadSelectedPropertySubTypes: {
            return {...state, selectedPropertySubTypes: action.payload.propertySubTypeIds}
        }
        case ValuationComparableActionTypes.LoadComparables: {
            return {...state, selectedPropertySubTypes: action.payload.filter.propertySubTypeIds}
        }
        case ValuationComparableActionTypes.ComparablesLoaded:
            return adapter.setAll(action.payload.items, {...state})
        case ValuationComparableActionTypes.AddComparableMapData: 
            return adapter.addOne(action.payload.item, {...state})
        default:
            return state
    }
}