<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label text-uppercase">add existing criterion</h3>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
</div>

<div class="card-body">
    <div class="card card-custom">
        <div class="card-body criterion-body">
            <ng-container *ngIf="viewModel$|async as viewModel">

            <div class="col-md-12 mat-table-wrapper">
                <table mat-table class="lmat-elevation-z8" [dataSource]="viewModel.dataSource">
                    <!-- Headers -->
                    <ng-container matColumnDef="input-header">
                        <th mat-header-cell 
                            *matHeaderCellDef 
                            [attr.colspan]="2">Qualitative Inputs</th>
                    </ng-container>
            
                    <ng-container *ngFor="let refNum of viewModel.refNums; let i = index" [matColumnDef]="'com-' + i + '-' + viewModel.refNums.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            {{refNum}}
                        </th>
                    </ng-container>
            
                    <ng-container [matColumnDef]="'tp-' + viewModel.refNums.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            Target Property
                        </th>
                    </ng-container>
            
                    <ng-container matColumnDef="action-header">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                    </ng-container>
            
                    <!-- Cells -->
                    <ng-container matColumnDef="criterion">
                        <td mat-cell *matCellDef="let criterion">
                            {{criterion.publicName ? criterion.publicName : 'Not defined'}}
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="category">
                        <td mat-cell *matCellDef="let criterion">{{criterion.categoryName}}</td>
                    </ng-container>
            
                    <ng-container *ngFor="let refNum of viewModel.refNums; let i = index" [matColumnDef]="'com-' + i + '-' + viewModel.refNums.length">
                        <td mat-cell *matCellDef="let criterion"
                        [ngClass]="{
                            'bg-red': criterion.values[refNum].kind == 'unknown' || criterion.values[refNum].kind == 'not applicable',
                            'bg-green': criterion.values[refNum].kind == 'simple'
                        }">
                            {{criterion.values[refNum].kind == 'simple' 
                                ? criterion.values[refNum].value 
                                : criterion.values[refNum].kind == 'unknown'
                                    ? 'Unknown'
                                    : 'Not Applicable'}}
                        </td>
                    </ng-container>
            
                    <ng-container [matColumnDef]="'tp-' + viewModel.refNums.length">
                        <td mat-cell *matCellDef="let criterion"
                        [ngClass]="{
                            'bg-red': criterion.values['tp'].kind == 'unknown' || criterion.values['tp'].kind == 'not applicable',
                            'bg-green': criterion.values['tp'].kind == 'simple'
                        }">
                            {{criterion.values['tp'].kind == 'simple' 
                                ? criterion.values['tp'].value 
                                : criterion.values['tp'].kind == 'unknown'
                                    ? 'Unknown'
                                    : 'Not Applicable'}}
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="actions">
                        <td mat-cell *matCellDef="let criterion">
                            <button *ngIf="!criterion.active" mat-icon-button color="primary" (click)="onSetCriterionActive(criterion)">
                                <mat-icon>add_circle</mat-icon>
                            </button>
                            <button *ngIf="criterion.active" mat-icon-button color="warn" (click)="onSetCriterionDeactive(criterion)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="viewModel.headerColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: viewModel.displayedColumns; let i = index"
                        [ngClass]="{'colored-row': i%2}"></tr>
                </table>
            </div>
            </ng-container>
        </div>
    </div>
    
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h3 class="my-3 text-uppercase">add existing criterion</h3>
    <hr class="active">
</div> -->

