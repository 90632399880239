// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {StateRepairActions, StateRepairActionTypes} from '../_actions/state-repair.actions';
import {StateRepair} from '../_models/state-repair.model';

export interface StateRepairsState extends EntityState<StateRepair> {
    isAllStateRepairsLoaded: boolean;
    queryRowsCount: number;
    queryResult: StateRepair[];
    lastCreatedStateRepairId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<StateRepair> = createEntityAdapter<StateRepair>();

export const initialStateRepairsState: StateRepairsState = adapter.getInitialState({
    isAllStateRepairsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedStateRepairId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function stateRepairsReducer(state = initialStateRepairsState, action: StateRepairActions): StateRepairsState {
    switch (action.type) {
        case StateRepairActionTypes.StateRepairsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedStateRepairId: undefined
            };
        case StateRepairActionTypes.StateRepairsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case StateRepairActionTypes.StateRepairOnServerCreated:
            return {
                ...state
            };
        case StateRepairActionTypes.StateRepairCreated:
            return adapter.addOne(action.payload.stateRepair, {
                ...state, lastCreatedStateRepairId: action.payload.stateRepair.id
            });
        case StateRepairActionTypes.StateRepairUpdated:
            return adapter.updateOne(action.payload.partialStateRepair, state);
        case StateRepairActionTypes.AllStateRepairsLoaded:
            return adapter.addAll(action.payload.stateRepairs, {
                ...state, isAllStateRepairsLoaded: true
            });
        case StateRepairActionTypes.StateRepairsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case StateRepairActionTypes.StateRepairsPageLoaded:
            return adapter.addMany(action.payload.stateRepairs, {
                ...initialStateRepairsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case StateRepairActionTypes.StateRepairDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case StateRepairActionTypes.StateRepairRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case StateRepairActionTypes.StateRepairDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case StateRepairActionTypes.StateRepairAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case StateRepairActionTypes.StateRepairDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case StateRepairActionTypes.StateRepairTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
