// Angular
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CertificationFileModel} from '../../../../../core/file-upload';
import {TypesUtilsService} from '../../../../../core/_base/crud';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { AllCertificationsRequested, AllOrganisationsRequested, CertificationModel, OrganisationModel, selectAllCertifications, selectAllOrganisations } from 'src/app/core/linked-tables';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'kt-certificate-edit-dialog',
    templateUrl: './certificate-edit-dialog.component.html'
})
export class CertificateEditDialogComponent implements OnInit, OnDestroy {
    // Public properties
    viewLoading = false;
    form: UntypedFormGroup;
    model: CertificationFileModel;
    // organisations = [{
    //     name: 'RICS',
    //     id: 1
    // }];

    // certTypes = [{
    //     name: 'FRICS',
    //     id: 1
    // }, {
    //     name: 'MRICS',
    //     id: 2
    // }, {
    //     name: 'AssocRICS',
    //     id: 3
    // }];
    organisations: OrganisationModel[] = [];
    certifications: CertificationModel[] = [];
    private _onDestroy$: Subject<void> = new Subject();

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>
     * @param fb
     * @param typesUtilsService
     * @param data: any
     */
    constructor(public dialogRef: MatDialogRef<CertificateEditDialogComponent>,
                private fb: UntypedFormBuilder,
                public typesUtilsService: TypesUtilsService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.model = this.data.item;
        this.store.dispatch(new AllOrganisationsRequested());
        this.store.dispatch(new AllCertificationsRequested());
        this.store.select(selectAllOrganisations).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
            this.organisations = res ? res : [];
        })
        this.store.select(selectAllCertifications).pipe(takeUntil(this._onDestroy$)).subscribe(res => {
            this.certifications = res ? res : [];
        })
        this.createForm();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }


    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Close dialog with true result
     */
    save(): void {
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            return;
        }
        this.viewLoading = true;
        const _res = Object.assign({}, this.model) as CertificationFileModel;
        _res.org_id = controls.org_id.value;
        _res.org_name = this.organisations.find(el => el.id == controls.org_id.value).name;
        _res.cert_type = controls.cert_type.value;
        _res.cert_name = this.certifications.find(el => el.id == controls.cert_type.value).name;
        _res.registration = controls.registration.value;
        _res.date = controls.date.value ? this.typesUtilsService.getDateStringFromDate(controls.date.value) : '';
        setTimeout(() => {
            this.dialogRef.close({
                item: _res
            });
        }, 500);
    }

    private createForm() {
        this.form = this.fb.group({
            org_id: [this.model.org_id, Validators.required],
            cert_type: [this.model.cert_type, Validators.required],
            date: [this.model.date.length < 1 ? null : this.typesUtilsService.getDateFromString(this.model.date)],
            registration: [this.model.registration]
        });
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }
}
