// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TenureActions, TenureActionTypes} from '../_actions/tenure.actions';
// Models
import {Tenure} from '../_models/tenure.model';
import {QueryParamsModel} from '../../_base/crud';

export interface TenureState extends EntityState<Tenure> {
    isAllTenureLoaded: boolean;
    queryRowsCount: number;
    queryResult: Tenure[];
    lastCreatedTenureId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Tenure> = createEntityAdapter<Tenure>();

export const initialTenureState: TenureState = adapter.getInitialState({
    isAllTenureLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedTenureId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function tenuresReducer(state = initialTenureState, action: TenureActions): TenureState {
    switch (action.type) {
        case TenureActionTypes.TenurePageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTenureId: undefined
            };
        case TenureActionTypes.TenureActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case TenureActionTypes.TenureOnServerCreated:
            return {
                ...state
            };
        case TenureActionTypes.TenureCreated:
            return adapter.addOne(action.payload.tenure, {
                ...state, lastCreatedTenureId: action.payload.tenure.id
            });
        case TenureActionTypes.TenureUpdated:
            return adapter.updateOne(action.payload.partialTenure, state);

        case TenureActionTypes.AllTenureLoaded:
            return adapter.addAll(action.payload.tenures, {
                ...state,
                isAllTenureLoaded: true
            });
        case TenureActionTypes.TenurePageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case TenureActionTypes.TenurePageLoaded:
            return adapter.addMany(action.payload.tenures, {
                ...initialTenureState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case TenureActionTypes.TenureDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case TenureActionTypes.TenureRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case TenureActionTypes.TenureDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case TenureActionTypes.TenureAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case TenureActionTypes.TenureDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case TenureActionTypes.TenureTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
