import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TpAdminTaskLVModel} from '../_models/tp-admin-task-lv.model';
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class TpAdminTaskLVService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/tp-tasks`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getByUserId(queryParams: QueryParamsModel, userId: number, filterModel: any = null): Observable<TpAdminTaskLVModel> {
        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
            filterModel: filterModel,
        };
        return this.http.post<TpAdminTaskLVModel>(this.API_URL + `/by-user/admin/lv/${userId}`,
            _body,
            {headers: this.authService.getAuthHeaders()});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
