import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ParkingAdjustmentsState } from "../_reducers/parking-adjustment.reducers";
import * as fromAdjustment from '../_reducers/parking-adjustment.reducers';
import { ParkingAdjustmentModel } from "../_models/parking-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectParkingAdjustmentsState = createFeatureSelector<ParkingAdjustmentsState>('parking-adjustments');

export const selectParkingAdjustmentById = (AdjustmentId: number) => createSelector(
    selectParkingAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllParkingAdjustments = createSelector(
    selectParkingAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllParkingAdjustmentsIds = createSelector(
    selectParkingAdjustmentsState,
    fromAdjustment.selectIds
);

export const allParkingAdjustmentsLoaded = createSelector(
    selectParkingAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectParkingAdjustmentsPageLoading = createSelector(
    selectParkingAdjustmentsState,
    state => state.listLoading
);

export const selectParkingAdjustmentsActionLoading = createSelector(
    selectParkingAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedParkingAdjustmentId = createSelector(
    selectParkingAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectParkingAdjustmentsShowInitWaitingMessage = createSelector(
    selectParkingAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedParkingAdjustmentCount = createSelector(
    selectParkingAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedParkingAdjustmentCount = createSelector(
    selectParkingAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectParkingAdjustmentQueryResult = createSelector(
    selectParkingAdjustmentsState,
    state => {
        const items: ParkingAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ParkingAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);