<div class="card card-custom section-margin-bottom-60">
    <div class="card-header srer-header">
        <div class="card-title">
            <div class="card-label">
                <span>Sourcing / Required External References in ToE</span>
            </div>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.SOURCING_REQUIRED_EXTERNAL_REFERENCES.DESCRIPTION'|translate"
                [popoverTitle]="'TOOLTIP.VALUATION_PROCESS.SOURCING_REQUIRED_EXTERNAL_REFERENCES.TITLE'|translate">
                help
            </mat-icon>
        </div>
    </div>
    <div class="card-body srer-body">
        <div class="col-md-12 mat-table-wrapper">
            <table class="table srer-table lmat-elevation-z8">
                <thead>
                    <tr>
                        <th class="column-external-ref" scope="col">External Reference</th>
                        <th class="column-availability" scope="col">ToE Initial Availability</th>
                        <th class="column-is-provided" scope="col">Was it provided?</th>
                        <th class="column-type" scope="col">Type</th>
                        <th class="column-input" scope="col">Input</th>
                        <th class="column-status" scope="col">Status</th>
                        <th class="column-justification" scope="col">Justification for deletion or change</th>
                        <th class="column-actions">Action</th>
                    </tr>
                </thead>
                <tbody *ngFor="let data of tableData">
                    <tr class="colored-white">
                        <th class="cell-external-ref" rowspan="2" scope="rowgroup">{{data.externalRef}}</th>
                        <th class="cell-availability" rowspan="2" scope="rowgroup">{{data.initialAvailability ? 'Yes' : 'No'}}</th>
                        <th class="cell-is-provided" rowspan="2" scope="rowgroup">
                            <ng-container *ngTemplateOutlet="data.isProvided
                                ? provided
                                : notProvided; context: {template_data: data}
                            "></ng-container>
                        </th>
                        <td class="cell-assumption">Assumption</td>
                        <td><ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                            ? notAvailable
                            : input; context: {template_input: data.defaultAssumption.input}"></ng-container></td>
                        <td class="cell-status">
                            <ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                                ? notAvailable
                                : status;  context: {template_status: data.defaultAssumption.status}
                            "></ng-container>
                        </td>
                        <td><ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                            ? notAvailable
                            : justification; context: {template_justification: data.defaultAssumption.justification}"></ng-container></td>
                        <td class="cell-actions">
                            <ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                                ? notAvailable
                                : actions; context: {template_data: data, template_assumption: data.defaultAssumption}
                            "></ng-container>
                        </td>
                    </tr>
                    <tr class="colored-row">
                        <td class="cell-assumption">Special Assumption</td>
                        <td><ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                            ? notAvailable
                            : input; context: {template_input: data.specialAssumption.input}"></ng-container></td>
                        <td class="cell-status">
                            <ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                                ? notAvailable
                                : status;  context: {template_status: data.specialAssumption.status}
                            "></ng-container>
                        </td>
                        <td><ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                            ? notAvailable
                            : justification; context: {template_justification: data.specialAssumption.justification}"></ng-container></td>
                        <td class="cell-actions">
                            <ng-container *ngTemplateOutlet="data.initialAvailability && data.isProvided
                                ? notAvailable
                                : actions; context: {template_data: data, template_assumption: data.specialAssumption}
                            "></ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
    <h5 class="my-3 text-mad text-uppercase">sourcing / required external references in toe</h5>
    <hr class="active">
</div> -->

<ng-template #notAvailable>
    N/A
</ng-template>

<ng-template #actions let-data="template_data" let-assumption="template_assumption">
    <button [disabled]="readonly" mat-icon-button color="primary" (click)="edit(data, assumption)">
        <mat-icon>create</mat-icon>
    </button>
    <button [disabled]="readonly" mat-icon-button *ngIf="assumption.status != srerStatus.Valid" (click)="restore(data, assumption)">
        <mat-icon [style.color]="'green'">refresh</mat-icon>
    </button>
    <button 
        [disabled]="readonly"
        mat-icon-button 
        color="warn"
        *ngIf="assumption.status !== srerStatus.Removed"
        (click)="remove(data, assumption)">
        <mat-icon>delete</mat-icon>
    </button>
</ng-template>

<ng-template #provided let-data="template_data">
    <span>
        Yes ({{data.providedCount}})
        <button mat-icon-button color="primary" (click)="seeDoc(data)">
            <mat-icon>visibility</mat-icon>
        </button>
    </span>
</ng-template>

<ng-template #notProvided>No</ng-template>

<ng-template #status let-status="template_status">
    {{status === srerStatus.Valid 
        ? 'Valid' 
        : (status === srerStatus.Amended ? 'Amended' : 'Removed')}}
</ng-template>

<ng-template #justification let-justification="template_justification">
    {{justification ? justification : 'N/A'}}
</ng-template>

<ng-template #input let-input="template_input">
    {{input ? input : 'N/A'}}
</ng-template>