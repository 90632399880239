import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { AllAssetClassTypesRequested, AssetClassType, MainWallActionToggleLoading, MainWallDeleted, MainWallDeletedFromAdminTrash, MainWallDeletedFromTrash, MainWallModel, MainWallOnServerAdminRestored, MainWallOnServerRestored, MainWallsDataSource, MainWallService, MainWallsPageRequested, MainWallTrashFlushed, MainWallUpdated, selectAdminTrashedMainWallCount, selectAllAssetClassTypes, selectTrashedMainWallCount } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { addBuildingAsAssetClass } from '../lt_utils/add_building_ac';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
  selector: 'kt-main-walls-list',
  templateUrl: './main-walls-list.component.html',
  styleUrls: ['./main-walls-list.component.scss']
})
export class MainWallsListComponent implements OnInit {
  dataSource: MainWallsDataSource;
  displayedColumns = ['id', 'name', 'property-sub-type', 'is_hidden', 'actions'];
  trashCnt = 0;
  adminTrashCnt = 0;
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  // Selection
  mode = 'NEW';
  selection = new SelectionModel<MainWallModel>(true, []);

  // Subscriptions
  private subscriptions: Subscription[] = [];
  dropdownCtrl = new UntypedFormControl(7);
  public propertySubTypes: AssetClassType[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              public service: MainWallService,
              private layoutUtilsService: LayoutUtilsService,
              private translate: TranslateService,
              private store: Store<AppState>) {
  }

  ngOnInit(): void {
        this.store.dispatch(new AllAssetClassTypesRequested())
        this.store.select(selectAllAssetClassTypes).subscribe(res => {
          this.propertySubTypes = [];
          if (res) {
            this.propertySubTypes = res.filter(item => item.id == 7 || item.id == 11 || item.id == 17);
            this.propertySubTypes = addBuildingAsAssetClass(this.propertySubTypes);
          }
        });
        const dropSub = this.dropdownCtrl.valueChanges.subscribe(val => {
            this.loadList();
        });
        this.subscriptions.push(dropSub);
        
        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                
                this.paginator.pageIndex = 0;
                this.loadList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new MainWallsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {});
        this.subscriptions.push(entitiesSubscription);

        // trash count

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedMainWallCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedMainWallCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitatio
            this.loadList();
        }); // Remove this line, just loading imitation
  }

  addItem() {
      this.editItem(null);
  }

  ngOnDestroy() {
      this.subscriptions.forEach(el => el.unsubscribe());
  }

  loadList() {
      this.selection.clear();
      const queryParams = new QueryParamsModel(
          this.filterConfiguration(),
          this.sort.direction,
          this.sort.active,
          this.paginator.pageIndex + 1,
          this.paginator.pageSize
      );
      this.store.dispatch(new MainWallsPageRequested({page: queryParams}));
      this.selection.clear();
  }

  filterConfiguration(): any {
      const filter: any = {
        with_hidden: true
      };
      const searchText: string = this.searchInput.nativeElement.value;

      filter.name = searchText;
      filter.status = this.dropdownCtrl.value; 
      if (!searchText) {
          return filter;
      }
      return filter;
  }

  /**
   * Returns object for filter
   */
  resetFilter(): any {
      this.searchInput.nativeElement.value = '';
      this.loadList();
  }

  /**
   * Show Edit subTypeCategory dialog and save after success close result
   * @param subTypeCategory: ChimneyStack
   */
  editItem(item) {
      if (item) {
          this.router.navigate(['edit', item.id], {relativeTo: this.activatedRoute});
      } else {
          this.router.navigate(['add'], {relativeTo: this.activatedRoute});
      }
  }

  deleteItem (_item: MainWallModel) {
      const _title = this.translate.instant('MAIN_WALL.LIST.DIALOG.DELETE.TITLE');
      const _description: string = this.translate.instant('MAIN_WALL.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
      const _waitDesciption =  this.translate.instant('MAIN_WALL.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
          if (!res) {
              return;
          }

          this.store.dispatch(new MainWallDeleted({id: _item.id}));
      });
  }

  trash() {
      this.store.dispatch(new MainWallActionToggleLoading({isLoading: true}));
      this.service.getTrashed().subscribe(res => {

          this.store.dispatch(new MainWallActionToggleLoading({isLoading: false}));
          const items = [];
          res.data.forEach(elem => {
              items.push({
                  text: `${elem.name}`,
                  id: elem.id.toString(),
                  hasPermanentlyDelete: true,
                  date: elem.user_deleted,
                  deletedUser: (elem.user_deleted_by && elem.user_deleted_by.length > 0 ? elem.user_deleted_by : ''),
              });
          });
          this.show_trash(items);
      });
  }

  // Fetch admin trash data
  adminTrash() {
      this.store.dispatch(new MainWallActionToggleLoading({isLoading: true}));
      this.service.getAdminTrashed().subscribe(res => {

          this.store.dispatch(new MainWallActionToggleLoading({isLoading: false}));
          const items = [];
          res.data.forEach(elem => {
              items.push({
                  text: `${elem.name}`,
                  id: elem.id.toString(),
                  hasPermanentlyDelete: true,
                  date: elem.deleted_at,
                  deletedUser: (elem.deleted_by && elem.deleted_by.length > 0 ? elem.deleted_by : ''),
              });
          });
          this.show_trash(items, true);
      });
  }

  /**
   * Show Edit agency dialog and save after success close result
   * @param items
   * @param isAdmin
   */
  show_trash(items: any[], isAdmin = false) {
      let _title = this.translate.instant('MAIN_WALL.LIST.DIALOG.SHOW_TRASH.TITLE');
      if (isAdmin) {
          _title = this.translate.instant('MAIN_WALL.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
      }
      this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
  }

  restore(id: number, isAdmin = false) {
      if (isAdmin) {
          this.store.dispatch(new MainWallOnServerAdminRestored({id}));
      } else {
          this.store.dispatch(new MainWallOnServerRestored({id}));
      }
  }

  delete(id: number) {
      this.store.dispatch(new MainWallDeletedFromTrash({id}));
  }

  hardDelete(id: number) {
      this.store.dispatch(new MainWallDeletedFromAdminTrash({id}));
  }

  flush() {
      
      this.store.dispatch(new MainWallTrashFlushed());
  }

  viewEntry(item: MainWallModel) {
    this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
  }
  onHiddenChange(event: MatCheckboxChange, item: MainWallModel) {
    const _item = cloneDeep(item)
    _item.is_hidden = event.checked
    this.store.dispatch(new MainWallUpdated({
        item: _item,
        partialItem: {
            id: item.id,
            changes: {
                is_hidden: event.checked
            }
        }
    }))
  }
}
