// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectTopPropertyTypeQueryResult, selectTopPropertyTypesPageLoading, selectTopPropertyTypesShowInitWaitingMessage} from '../_selectors/top-property-type.selectors';

export class TopPropertyTypesDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectTopPropertyTypesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTopPropertyTypesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTopPropertyTypeQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
