import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../core/_base/crud';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {
    KeyPlaceModel,
    selectKeyPlaceById, selectKeyPlaceActionLoading, KeyPlaceOnServerCreated, selectLastCreatedKeyPlaceId, KeyPlaceUpdated
} from '../../../../../core/admin';
import {Location} from '@angular/common';
import {MapsAPILoader} from '@agm/core';
import {} from '@angular/google-maps/'
import {AllKeyCategoriesRequested, KeyCategoryModel, selectAllKeyCategories} from '../../../../../core/linked-tables';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Update} from '@ngrx/entity';
import {FileUploadService} from '../../../../../core/file-upload/_services';
import { ImageViewerDialogComponent } from '../../../shared_components/image_viewer/image-viewer.dialog.component';
import { awConst } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
import { MapsService } from 'src/app/core/map/maps.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'kt-key-place-edit',
    templateUrl: './key-place-edit.component.html',
    styleUrls: ['./key-place-edit.component.scss']
})

export class KeyPlaceEditComponent implements OnInit, OnDestroy {

    @ViewChild('mapTooltip') mapTooltip: ElementRef;
    @ViewChild('map', {static: true}) map: google.maps.Map;

    @ViewChild('fileInputImg') fileInputImg: ElementRef;
    @ViewChild('search')
    public searchElementRef: ElementRef;
    awConst = awConst;

    keyPlace: KeyPlaceModel;
    oldKeyPlace: KeyPlaceModel;

    keyPlaceForm: UntypedFormGroup;
    hasFormErrors = false;

    allCategories: KeyCategoryModel[] = [];
    marker: google.maps.Marker;
    geoCoder = new google.maps.Geocoder;
    lat = environment.mapCenter.lat;
    lng = environment.mapCenter.lng;
    zoom = 15;
    private _centerLat$ = new BehaviorSubject<number>(null);
    centerLat$ = combineLatest([
        this._centerLat$,
        this.mapsService.latitude$
    ]).pipe(map(([initial, def]) => {
        if (initial == null) {
            return def
        }
        return initial
    }))
    private _centerLng$ = new BehaviorSubject<number>(null);
    centerLng$ = combineLatest([
        this._centerLng$,
        this.mapsService.longitude$
    ]).pipe(map(([initial, def]) => {
        if (initial == null) {
            return def
        }
        return initial
    }))

    cityOfLocation = '';
    isShown = true;

    error: any;

    displayedColumns = ['type', 'name', 'file_name', 'actions'];
    dataSource = new MatTableDataSource([
        {
            name: 'Picture of the Landmark',
            type: 'jpeg',
            field: 'picture'
        }
    ]);
    loading$: Observable<boolean>;
    progressValue = 0;
    progress$: Observable<number>;
    progressSubject = new BehaviorSubject<number>(0);

    // Private properties
    private componentSubscriptions: Subscription;

    private subscriptions: Subscription[] = [];

    // Workaround
    private mapClickListener: google.maps.MapsEventListener;

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param fb: FormBuilder
     * @param location
     * @param subheaderService
     * @param assetClassResidentialService
     * @param layoutUtilsService
     * @param fileUploadService
     * @param dialog: MatDialog
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     * @param mapsAPILoader: MapsAPILoader
     * @param ngZone: NgZone
     * @param layoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                // private assetClassResidentialService: AssetClassResidentialsService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private store: Store<AppState>,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService,
                private mapsService: MapsService) {
    }

    ngOnInit() {
        this.loading$ = this.store.pipe(select(selectKeyPlaceActionLoading));
        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectKeyPlaceById(id))).subscribe(res => {
                    if (res) {
                        this.oldKeyPlace = Object.assign({}, res);
                        this.keyPlace = Object.assign({}, this.oldKeyPlace);
                        this._centerLat$.next(Number(this.keyPlace.latitude));
                        this._centerLng$.next(Number(this.keyPlace.longitude));

                        this.lng = this._centerLng$.value;
                        this.lat = this._centerLat$.value;

                        this.getWhat3words(new google.maps.LatLng(this.lng, this.lat));
                        this.initKeyPlace();
                    } else {
                        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
                        return;
                    }
                });
            } else {
                this.isShown = false;
                this.keyPlace = new KeyPlaceModel();
                this.keyPlace.clear();
                this.oldKeyPlace = Object.assign({}, this.keyPlace);
                this.oldKeyPlace.latitude = this.lat;
                this.oldKeyPlace.longitude = this.lng;
                this.initKeyPlace();
            }
        });
        this.subscriptions.push(routeSubscription);


        this.store.dispatch(new AllKeyCategoriesRequested());
        const categorySubscription = this.store.pipe(select(selectAllKeyCategories))
            .subscribe((res: KeyCategoryModel[]) => {
                this.allCategories = res;
            });

        this.subscriptions.push(categorySubscription);
        this.progress$ = this.progressSubject.asObservable();

        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder();

            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ['(cities)']
            });
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    if (place.formatted_address === 'Ulaanbaatar, Mongolia') {
                        this._centerLat$.next(47.91868658952473);
                        this._centerLng$.next(106.91766668255616);
                        this.zoom = 15;
                    } else {
                        // set latitude, longitude and zoom
                        this._centerLat$.next(place.geometry.location.lat());
                        this._centerLng$.next(place.geometry.location.lng());
                        this.zoom = 12;
                    }
                });
            });
        });
    }

    mapReady(event: any) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
        
        // Workaround click event on new agm
        this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
            this.ngZone.run(() => {
                this.mapRightClick(e);
            })
        })
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }

        // Workaround
        if (this.mapClickListener) {
            this.mapClickListener.remove();
        }
    }

    initKeyPlace() {
        this.createForm();
        if (!this.keyPlace.id) {
            this.subheaderService.setTitle('New KeyPlace');
            this.subheaderService.setBreadcrumbs([
                {title: 'Admin management', page: `../default/admin-management`},
                {title: 'KeyPlaces', page: `../default/admin-management/keyPlaces`},
                {title: 'Add KeyPlace', page: `../default/admin-management/keyPlaces/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit KeyPlace');
        this.subheaderService.setBreadcrumbs([
            {title: 'Admin management', page: `../default/admin-management`},
            {title: 'KeyPlaces', page: `../default/admin-management/keyPlaces`},
            {title: 'Add KeyPlace', page: `../default/ecommerce/products/edit`}
        ]);
    }


    createForm() {
        this.keyPlaceForm = this.fb.group({
            name: [this.keyPlace.name, Validators.required],
            what3words: [{value: '', disabled: true}, Validators.required],
            picture: [this.keyPlace.picture, Validators.required],
            category_id: [this.keyPlace.category_id, Validators.required],
            description: [this.keyPlace.description, Validators.required],
            latitude: [this.keyPlace.latitude, Validators.required],
            longitude: [this.keyPlace.longitude, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.keyPlace && this.keyPlace.id > 0) {
            return this.translate.instant('LANDMARK.FORM.TITLE.EDIT', {name: this.keyPlace.name});
        }

        return this.translate.instant('LANDMARK.FORM.TITLE.NEW');
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.keyPlaceForm.controls;
        /** check form */
        if (this.keyPlaceForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const _keyPlace = this.prepareKeyPlace();
        if (_keyPlace.id > 0) {
            this.updateKeyPlace(_keyPlace);
        } else {
            this.createKeyPlace(_keyPlace);
        }
    }

    /**
     * Update keyPlace
     *
     * @param _keyPlace: KeyPlaceModel
     */
    updateKeyPlace(_keyPlace: KeyPlaceModel) {
        const updateKeyPlace: Update<KeyPlaceModel> = {
            id: _keyPlace.id,
            changes: _keyPlace
        };
        this.store.dispatch(new KeyPlaceUpdated({
            partialKeyPlace: updateKeyPlace,
            keyPlace: _keyPlace
        }));
        this.oldKeyPlace = _keyPlace;
        this.layoutUtilsService.showActionNotification(
            this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'), MessageType.Update, 3000, true, false);
    }

    /**
     * Create keyPlace
     *
     * @param _keyPlace: KeyPlaceModel
     */
    createKeyPlace(_keyPlace: KeyPlaceModel) {

        this.store.dispatch(new KeyPlaceOnServerCreated({
            keyPlace: _keyPlace,
        }));
        this.oldKeyPlace = _keyPlace;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedKeyPlaceId),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(
                this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'), MessageType.Update, 3000, true, false);
        });
    }

    prepareKeyPlace() {
        const controls = this.keyPlaceForm.controls;
        const _keyPlace = new KeyPlaceModel();
        _keyPlace.clear();
        _keyPlace.id = this.keyPlace.id;
        _keyPlace.name = controls.name.value;
        _keyPlace.category_id = controls.category_id.value;
        _keyPlace.description = controls.description.value;
        _keyPlace.longitude = this.lng;
        _keyPlace.latitude = this.lat;
        _keyPlace.picture = controls.picture.value;
        _keyPlace.what3words = controls.what3words.value;
        _keyPlace.city_of_location = this.cityOfLocation;
        return _keyPlace;
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    back() {
        // if (this.keyPlace.id) {
        //     this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
        //     return;
        // }
        // this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        this.location.back();
    }

    isFormValid() {
        return true;
    }

    mapRightClick($event: google.maps.MouseEvent) {
        this.lat = $event.latLng.lat();
        this.lng = $event.latLng.lng();
        this._centerLat$.next(this.lat);
        this._centerLng$.next(this.lng);
        this.keyPlaceForm.controls.latitude.patchValue($event.latLng.lat());
        this.keyPlaceForm.controls.longitude.patchValue($event.latLng.lng());
        this.getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
        this.isShown = true;
    }

    markerDragEnd($event: google.maps.MouseEvent) {
        this.lat = $event.latLng.lat();
        this.lng = $event.latLng.lng();

        this._centerLat$.next(this.lat);
        this._centerLng$.next(this.lng);

        this.keyPlaceForm.controls.latitude.patchValue($event.latLng.lat());
        this.keyPlaceForm.controls.longitude.patchValue($event.latLng.lng());
        this.getWhat3words(new google.maps.LatLng($event.latLng.lat(), $event.latLng.lng()));
    }

    getWhat3words(latLng: google.maps.LatLng): void {
        this.layoutUtilsService.getWhat3Word(latLng).subscribe(res => {
            this.keyPlaceForm.controls.what3words.patchValue(res.words);
        });

        this.geoCoder.geocode({location: {lat: latLng.lat(), lng: latLng.lng()}}, (results, status) => {
            if (status && results && results.length > 0) {
                this.cityOfLocation = results.length > 1 ? results[results.length - 2].formatted_address : results[0].formatted_address;
            } else {
                // window.alert('No results found');
            }
        });
    }

    // file fuctions
    // upload
    uploadFile(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            this.upload(file, file.name);
        }
    }

    upload(file: File, name: string) {
        const formData = new FormData();
        formData.append('file', file, name);
        formData.append('path', 'key_place/');

        this.progressSubject.next(1);
        this.fileUploadService.upload2path(formData).subscribe(
            (res) => {
                if (res && res.status) {
                    if (res.status === 'progress') {
                        this.progressSubject.next(res.message);
                    }
                    if (res.status === 'done') {
                        this.keyPlaceForm.controls.picture.patchValue(res.filePath);
                        this.keyPlaceForm.controls.picture.updateValueAndValidity();
                        this.keyPlace.picture = res.filePath;

                        this.fileInputImg.nativeElement.value = '';
                        this.progressSubject.next(0);
                    }
                }
            },
            (err) => this.error = err
        );

    }

    addFiles() {
        this.fileInputImg.nativeElement.click();
    }

    deleteUploadedFile() {
        this.keyPlace.picture = '';
    }

    previewUploadedFile() {
        this.dialog.open(ImageViewerDialogComponent, {
            data: {
                picture: this.keyPlace.picture,
                type: 'jpg'
            }
        });
    }

    canDeactivate() {
        if (this.discard()) {

            // if (this.keyPlace.id === undefined &&
            //     (this.keyPlace.name.length > 0 || this.keyPlace.description.length > 0 || this.keyPlace.picture.length > 0)) {
            //     const _title = 'Are you sure?';
            //     const _description = 'The landmark data will be deleted';
            //
            //     const dialogRef = this.layoutUtilsService.deleteElement(_title, _description);
            //     dialogRef.afterClosed().subscribe(res => {
            //         if (!res) {
            //             return;
            //         }
            //         this.location.back();
            //     });
            //
            //     return;
            // }
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {

        const _keyPlace = this.prepareKeyPlace();

        return _keyPlace.name != this.oldKeyPlace.name ||
            _keyPlace.category_id != this.oldKeyPlace.category_id ||
            _keyPlace.description != this.oldKeyPlace.description ||
            _keyPlace.picture != this.oldKeyPlace.picture ||
            _keyPlace.latitude != this.oldKeyPlace.latitude ||
            _keyPlace.latitude != this.oldKeyPlace.latitude ||
            _keyPlace.longitude != this.oldKeyPlace.longitude;
    }
}
