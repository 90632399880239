import { Action } from "@ngrx/store";

export enum InformedConsentReportActionTypes {
	GenerateReport = '[Save Toe Dialog] Generate Informed Consent Report',
	ReportGenerated = '[Informed Consent Report Generate Effect] Report generated'
}

export class GenerateInformedConsentReport implements Action {
	readonly type = InformedConsentReportActionTypes.GenerateReport;
	constructor(public payload: {
		toeID: number,
		reportID: number
	}) {}
}

export class InformedConsentReportGenerated implements Action {
	readonly type = InformedConsentReportActionTypes.ReportGenerated;
	constructor(public payload: {success: boolean}) {}
}

export type InformedConsentReportActions = 
	| GenerateInformedConsentReport
	| InformedConsentReportGenerated;