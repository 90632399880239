function copy(tenures: any[]): any[] {
    const temp = [];
    tenures.forEach(t => temp.push(Object.assign({}, t)));
    return temp;
}

function tenurePicker(tenures: any[]): any {
    const ts = copy(tenures);
    ts.sort((a, b) => {
        const aDate = a.source.source_type_id != 1 ? new Date(a.source.information_date) : new Date(a.source.transaction_date);
        const bDate = b.source.source_type_id != 1 ? new Date(b.source.information_date) : new Date(b.source.transaction_date);
        return bDate.getTime() - aDate.getTime();
    });  
    return ts[0];
}

export function tenureAdder(e: any): any {
    if (e.tenures.length == 0) {
        return {...e, display_tenures: []};
    }
    const lt = e.tenures.filter(t => t.tenure == 'leasehold');
    const ft = e.tenures.filter(t => t.tenure == 'freehold');
    let tenures = [];
    if (lt.length > 0) {
        tenures.push(tenurePicker(lt));
    }
    if (ft.length > 0) {
        tenures.push(tenurePicker(ft));
    }

    return {...e, display_tenures: tenures}
}