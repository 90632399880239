// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AdditionalCostTitleActions, AdditionalCostTitleActionTypes} from '../_actions/additional-cost-title.actions';
// Models
import {AdditionalCostTitleModel} from '../_models/additional-cost-title.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AdditionalCostTitlesState extends EntityState<AdditionalCostTitleModel> {
    isAllAdditionalCostTitlesLoaded: boolean;
    queryRowsCount: number;
    queryResult: AdditionalCostTitleModel[];
    lastCreatedAdditionalCostTitleId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AdditionalCostTitleModel> = createEntityAdapter<AdditionalCostTitleModel>();

export const initialAdditionalCostTitleState: AdditionalCostTitlesState = adapter.getInitialState({
    isAllAdditionalCostTitlesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAdditionalCostTitleId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function additionalCostTitleReducer(state = initialAdditionalCostTitleState, action: AdditionalCostTitleActions): AdditionalCostTitlesState {
    switch (action.type) {
        case AdditionalCostTitleActionTypes.AdditionalCostTitlesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdditionalCostTitleId: undefined
            };
        case AdditionalCostTitleActionTypes.AdditionalCostTitleActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerCreated:
            return {
                ...state
            };
        case AdditionalCostTitleActionTypes.AdditionalCostTitleCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAdditionalCostTitleId: action.payload.item.id
            });
        case AdditionalCostTitleActionTypes.AdditionalCostTitleUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case AdditionalCostTitleActionTypes.AllAdditionalCostTitlesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllAdditionalCostTitlesLoaded: true
            });
        case AdditionalCostTitleActionTypes.AdditionalCostTitlesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case AdditionalCostTitleActionTypes.AdditionalCostTitlesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialAdditionalCostTitleState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case AdditionalCostTitleActionTypes.AdditionalCostTitleDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AdditionalCostTitleActionTypes.AdditionalCostTitleRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case AdditionalCostTitleActionTypes.AdditionalCostTitleDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case AdditionalCostTitleActionTypes.AdditionalCostTitleAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AdditionalCostTitleActionTypes.AdditionalCostTitleDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AdditionalCostTitleActionTypes.AdditionalCostTitleTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
