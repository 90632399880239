<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{data.title}}</h3>
		</div>
	</div>
	<div class="form">
		<div class="card-body">
			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
			<div class="form-group row">
				<div class="col-lg-12">
					{{ viewLoading ? data.waitDesciption : data.description}}
				</div>
			</div>

			<div class="form-group row" *ngIf="!viewLoading">
				<div class="col-lg-12">
					<mat-form-field class="mat-form-field-fluid">
						<textarea required matInput rows="4" [placeholder]="data.placeholder ? data.placeholder : 'Description'" [formControl]="inputDescriptionForUpdate">
						</textarea>
						<mat-error>{{data.error ? data.error : 'Description for justification of delete'}}
							<strong>required</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="card-footer b-0 p-0 text-right">
			<div class="form-actions form-actions-solid">
				<div class="row">
					<div class="col-lg-12">
						<button type="button" mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
						<button type="button" mat-raised-button color="primary" (click)="onYesClick()" [disabled]="inputDescriptionForUpdate.value.length == 0">Delete</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
