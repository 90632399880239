// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ExternalFacadeService} from '../_services/external-facade.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allExternalFacadesLoaded} from '../_selectors/external-facade.selectors';
// Actions
import {
    AllExternalFacadesLoaded,
    AllExternalFacadesRequested,
    ExternalFacadeActionTypes,
    ExternalFacadesPageRequested,
    ExternalFacadesPageLoaded,
    ExternalFacadeUpdated,
    ExternalFacadesPageToggleLoading,
    ExternalFacadeDeleted,
    ExternalFacadeOnServerCreated,
    ExternalFacadeCreated,
    ExternalFacadeActionToggleLoading, ExternalFacadeTrashFlushed, ExternalFacadeOnServerRestored, ExternalFacadeRestored,
    ExternalFacadeOnServerAdminRestored, ExternalFacadeAdminRestored, ExternalFacadeDeletedFromTrash, ExternalFacadeDeletedFromAdminTrash
} from '../_actions/external-facade.actions';

@Injectable()
export class ExternalFacadeEffects {
    showPageLoadingDispatcher = new ExternalFacadesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ExternalFacadeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ExternalFacadeActionToggleLoading({isLoading: false});

    @Effect()
    loadAllExternalFacade$ = this.actions$
        .pipe(
            ofType<AllExternalFacadesRequested>(ExternalFacadeActionTypes.AllExternalFacadesRequested),
            withLatestFrom(this.store.pipe(select(allExternalFacadesLoaded))),
            filter(([action, isAllExternalFacadeLoaded]) => !isAllExternalFacadeLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllExternalFacadesLoaded({items: res.data});
            })
        );

    @Effect()
    loadExternalFacadePage$ = this.actions$
        .pipe(
            ofType<ExternalFacadesPageRequested>(ExternalFacadeActionTypes.ExternalFacadesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ExternalFacadesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeUpdated>(ExternalFacadeActionTypes.ExternalFacadeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeOnServerCreated>(ExternalFacadeActionTypes.ExternalFacadeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalFacadeCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushExternalFacadeTrash$ = this.actions$
        .pipe(
            ofType<ExternalFacadeTrashFlushed>(ExternalFacadeActionTypes.ExternalFacadeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeOnServerRestored>(ExternalFacadeActionTypes.ExternalFacadeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalFacadeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeOnServerAdminRestored>(ExternalFacadeActionTypes.ExternalFacadeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ExternalFacadeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeDeleted>(ExternalFacadeActionTypes.ExternalFacadeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeDeletedFromTrash>(ExternalFacadeActionTypes.ExternalFacadeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashExternalFacade$ = this.actions$
        .pipe(
            ofType<ExternalFacadeDeletedFromAdminTrash>(ExternalFacadeActionTypes.ExternalFacadeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ExternalFacadeService, private store: Store<AppState>) {
    }
}
