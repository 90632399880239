import { Injectable } from "@angular/core";
import { AssetClassModel } from "../../asset_class";
import { ValuationVpga10Matter } from "../_models/vpga10-matter.model";

@Injectable()
export class Vpga10MatterService {

    restoreLocal(
        tp: AssetClassModel,
        item: ValuationVpga10Matter
    ): ValuationVpga10Matter {
        const _item = Object.assign({}, item) as ValuationVpga10Matter;
        const localVpga = tp.vpgas.find(vpga => vpga.id === item.local_vpga_id);
        if (localVpga) {
            _item.input = localVpga.vpga;
        }
        return _item;
    }
}