import {LandTenure} from '../_models/land-tenure.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {LandTenuresState} from '../_reducers/land-tenure.reducers';
import * as fromLandTenure from '../_reducers/land-tenure.reducers';
import {each} from 'lodash';

export const selectLandTenuresState = createFeatureSelector<LandTenuresState>('landTenures');

export const selectLandTenureById = (landTenureId: number) => createSelector(
    selectLandTenuresState,
    landTenuresState => landTenuresState.entities[landTenureId]
);

export const selectAllLandTenures = createSelector(
    selectLandTenuresState,
    fromLandTenure.selectAll
);

export const selectAllLandTenuresIds = createSelector(
    selectLandTenuresState,
    fromLandTenure.selectIds
);

export const allLandTenuresLoaded = createSelector(
    selectLandTenuresState,
    landTenuresState => landTenuresState.isAllLandTenuresLoaded
);


export const selectLandTenuresPageLoading = createSelector(
    selectLandTenuresState,
    landTenuresState => landTenuresState.listLoading
);

export const selectLandTenuresActionLoading = createSelector(
    selectLandTenuresState,
    landTenuresState => landTenuresState.actionLoading
);

export const selectLastCreatedLandTenureId = createSelector(
    selectLandTenuresState,
    landTenuresState => landTenuresState.lastCreatedLandTenureId
);

export const selectLandTenuresShowInitWaitingMessage = createSelector(
    selectLandTenuresState,
    landTenuresState => landTenuresState.showInitWaitingMessage
);

export const selectTrashedLandTenureCount = createSelector(
    selectLandTenuresState,
    (landTenuresState) => landTenuresState.totalTrashed
);

export const selectAdminTrashedLandTenureCount = createSelector(
    selectLandTenuresState,
    (landTenuresState) => landTenuresState.totalAdminTrashed
);


export const selectLandTenureQueryResult = createSelector(
    selectLandTenuresState,
    landTenuresState => {
        const items: LandTenure[] = [];
        each(landTenuresState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: LandTenure[] = httpExtension.sortArray(items, landTenuresState.lastQuery.sortField, landTenuresState.lastQuery.sortOrder);

        return new QueryResultsModel(result, landTenuresState.totalCount, '', landTenuresState.totalTrashed);
    }
);
