import { VApartmentAboutProperty } from "src/app/core/valuation-process/_models/valuation-apartment.model";
import { VAssetClassModelTypes } from "src/app/core/valuation-process/_models/valuation-asset-class.model";
import { VHouseAboutProperty } from "src/app/core/valuation-process/_models/valuation-house.model";
import { VLandAboutProperty } from "src/app/core/valuation-process/_models/valuation-land.model";
import { VOfficeAboutProperty } from "src/app/core/valuation-process/_models/valuation-office.model";
import { VParkingAboutProperty } from "src/app/core/valuation-process/_models/valuation-parking.model";
import { PropertySubTypes } from "src/app/core/valuation-process/_models/valuation-process-target-property.model";
import { VRetailBuildingAboutProperty } from "src/app/core/valuation-process/_models/valuation-retail-building.model";
import { VRetailShopAboutProperty } from "src/app/core/valuation-process/_models/valuation-retail-shop.model";
import { VWarehouseAboutProperty } from "src/app/core/valuation-process/_models/valuation-warehouse.model";
import { Cells, mergeRRows, mergeRows, Row, simpleCellCreator } from "./comparable-info-dialog.types";



const apartmentItems: {header: string, field: keyof VApartmentAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Floor location', field: 'floors'},
    {header: 'Energy Efficiency Grade', field: 'energyEfficiencyGrade'}
]
const houseItems: {header: string, field: keyof VHouseAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Number of floors (above ground)', field: 'aboveFloors'},
    {header: 'Energy Efficiency Grade', field: 'energyEfficiencyGrade'},
    {header: 'Basement floors', field: 'belowFloors'},
    {header: 'Construction Description', field: 'constructionDescription'},
    {header: 'Completion Year', field: 'completionYear'},
    {header: 'Approximate year the property was extended', field: 'approximateYear'},
    {header: 'Property Grade', field: 'propertyGrade'},
    {header: 'Foundation Type', field: 'foundationType'}
]
const officeItems: {header: string, field: keyof VOfficeAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Floor location', field: 'floors'},
    {header: 'Energy Efficiency Grade', field: 'energyEfficiencyGrade'}
]
const retailShopItems: {header: string, field: keyof VRetailShopAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Floor location', field: 'floors'},
]
const retailBuildingItems: {header: string, field: keyof VRetailBuildingAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Number of floors (above ground)', field: 'aboveFloors'},
    {header: 'Energy Efficiency Grade', field: 'energyEfficiencyGrade'},
    {header: 'Basement floors', field: 'belowFloors'},
    {header: 'Construction Description', field: 'constructionDescription'},
    {header: 'Completion Year', field: 'completionYear'},
    {header: 'Approximate year the property was extended', field: 'approximateYear'},
    {header: 'Property Grade', field: 'propertyGrade'},
    {header: 'Foundation Type', field: 'foundationType'}
]
const parkingItems: {header: string, field: keyof VParkingAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Floor location', field: 'floors'},,
    {header: 'Number of Units', field: 'numberOfUnits'},
    {header: 'Parking Type', field: 'parkingType'},
    {header: 'Automation', field: 'automation'}
]
const warehouseItems: {header: string, field: keyof VWarehouseAboutProperty}[] = [
    {header: 'Sub-Type Category', field: 'subTypeCategory'},
    {header: 'Sub Category', field: 'subCategory'},
    {header: 'Property General Description', field: 'generalDescription'},
    {header: 'State of Repair', field: 'stateOfRepair'},
    {header: 'Handover Standard', field: 'handoverStandard'},
    {header: 'Number of floors (above ground)', field: 'aboveFloors'},
    {header: 'Energy Efficiency Grade', field: 'energyEfficiencyGrade'},
    {header: 'Basement floors', field: 'belowFloors'},
    {header: 'Construction Description', field: 'constructionDescription'},
    {header: 'Completion Year', field: 'completionYear'},
    {header: 'Property Grade', field: 'propertyGrade'},
    {header: 'Foundation Type', field: 'foundationType'}
]
const landItems: {header: string, field: keyof VLandAboutProperty}[] = [
    {header: 'Land Cover Type', field: 'landCoverType'},
    {header: 'Quality of soil and degradation', field: 'qualityOfSoilDegradation'},
    {header: 'Comparative land use', field: 'comparativeLandUse'},
    {header: 'Source of land use classification', field: 'soureOfLandUseClassification'},
    {header: 'Date of land use approval and implementation', field: 'landUseApproval'},
    {header: 'Other characteristics', field: 'otherCharacteristics'}
]

const Items = {
    'Apartment': apartmentItems,
    'House': houseItems,
    'Office': officeItems,
    'Retail Shop': retailShopItems,
    'Retail Building': retailBuildingItems,
    'Parking': parkingItems,
    'Warehouse': warehouseItems,
    'Land': landItems
}


export function aboutPropertyRows(assetClasses: {
    refNum: string,
    propertySubType: PropertySubTypes,
    propertySubTypeName: string,
    property: VAssetClassModelTypes['property']
}[]): Row[] {
    const rrows = assetClasses.map(assetClass => {
        const items = Items[assetClass.propertySubType]
        return items.map((item) => {
            const cells: Cells = {}
            cells[assetClass.refNum] = simpleCellCreator(assetClass.property[item.field])
            return {
                header: item.header,
                cells,
                propertySubType: assetClass.propertySubTypeName
            }
        })
    })
    const mergedRows = mergeRRows(rrows)
    return mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))
}