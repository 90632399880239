<table *ngIf="sizeInfo" class="table" [ngClass]="{'readonly': readonly}">
    <thead>
        <th>Components</th>
        <th>Items</th>
        <th *ngFor="let info of sizeInfo.data">
            Floor ({{info.floor}}) / {{sizeInfo.unit_of_area_measurement}}
        </th>
        <th>
            Total / {{sizeInfo.unit_of_area_measurement}}
        </th>
    </thead>
    <tbody>
        <ng-container *ngIf="standardMeasurement.id != 8">
            <ng-container *ngTemplateOutlet="singlerow;context:{name:'A1', component:'a1_component', class:'a1'}"></ng-container>
            <ng-container *ngTemplateOutlet="singlerow;context:{name:'A2', component:'a2_component', class:'a2'}"></ng-container>
            <ng-container *ngIf="standardMeasurement.id == 4">
                <ng-container *ngTemplateOutlet="threerow;context:{name:'B1', component: 'b1_component', class:'b1'}"></ng-container>
            </ng-container>
            <ng-container *ngIf="standardMeasurement.id == 4 || standardMeasurement.id == 5">
                <ng-container *ngTemplateOutlet="threerow;context:{name:'B2', component: 'b2_component', class:'b2'}"></ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="standardMeasurement.id == 4 || standardMeasurement.id == 5 || standardMeasurement.id == 8">
            <ng-container *ngTemplateOutlet="threerow;context:{name:'B3', component: 'b3_component', class:'b3'}"></ng-container>
        </ng-container>
        <ng-container *ngIf="standardMeasurement.id != 8">
            <ng-container *ngTemplateOutlet="threerow;context:{name:'C', component: 'c_component', class:'c'}"></ng-container>
            <ng-container *ngTemplateOutlet="threerow;context:{name:'D', component: 'd_component', class:'d'}"></ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="threerow;context:{name:'E', component: 'e_component', class:'e'}"></ng-container>
        <ng-container *ngTemplateOutlet="threerow;context:{name:'F', component: 'f_component', class:'f'}"></ng-container>
        <ng-container *ngTemplateOutlet="threerow;context:{name:'G', component: 'g_component', class:'g'}"></ng-container>
        <ng-container *ngTemplateOutlet="threerow;context:{name:'H', component: 'h_component', class:'h'}"></ng-container>
        <tr>
            <th rowspan="3" class="total_component cell_component">
                Total 
                <br/>
                {{sizeInfo.standard}}
            </th>
            <th class="total_item cell_item">Total area</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input gray">
                {{info | colsizesum:'total':standardMeasurement.id}}
            </td>
            <td class="total_secondary cell_total cell_calculated">
                {{sizeInfo.data | colrowsizesum:'total':standardMeasurement.id}} 
            </td>
        </tr>
        <tr>
            <th class="total_item cell_item">Limited use areas</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input">
                {{info | colsizesum:'limited':standardMeasurement.id}}
            </td>
            <td class="total_secondary cell_total cell_calculated">
                {{sizeInfo.data | colrowsizesum:'limited':standardMeasurement.id}}
            </td>
        </tr>
        <tr>
            <th class="total_item cell_item cell_last">IPMS Total</th>
            <td *ngFor="let info of sizeInfo.data" class="total_cell cell_input cell_last cell_calculated ">
                {{(info | colsizesum:'total':standardMeasurement.id) - (info | colsizesum:'limited':standardMeasurement.id)}}
            </td>
            <td class="total_primary cell_total cell_last cell_calculated">
                {{(sizeInfo.data | colrowsizesum:'total':standardMeasurement.id) - (sizeInfo.data | colrowsizesum:'limited':standardMeasurement.id)}}
            </td>
        </tr>

        <tr>
            <th rowspan="5" class="additional_component cell_component">
                Additional 
                <br />
                Areas 
                <br />
                Outside 
                <br />
                {{sizeInfo.standard}}
            </th>
            <th class="additional_item cell_item">Patios</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input gray">
                <input 
                    noNeg
                    type="text" 
                    [value]="info.additional.patios" 
                    (focusout)="onFocusOut($event, info.floor, 'additional', 'patios')"/>
            </td>
            <td class="additional_secondary cell_total cell_calculated">
                {{sizeInfo.data | rowsizesum:'additional':'patios' }}
            </td>
        </tr>
        <tr>
            <th class="additional_item cell_item">Unenclosed Parking areas</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input">
                <input 
                    noNeg
                    type="text" 
                    [value]="info.additional.unenclosed_parking_areas" 
                    (focusout)="onFocusOut($event, info.floor, 'additional', 'unenclosed_parking_areas')"/>
            </td>
            <td class="additional_secondary cell_total cell_calculated ">
                {{sizeInfo.data | rowsizesum:'additional':'unenclosed_parking_areas' }}
            </td>
        </tr>
        <tr>
            <th class="additional_item cell_item">Staircases opening above level 0</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input gray">
                <input 
                    noNeg
                    type="text" 
                    [value]="info.additional.staircase" 
                    (focusout)="onFocusOut($event, info.floor, 'additional', 'staircase')"/>
            </td>
            <td class="additional_secondary cell_total cell_calculated ">
                {{sizeInfo.data | rowsizesum:'additional':'staircase' }}
            </td>
        </tr>
        <tr>
            <th class="additional_item cell_item">Voids including the enclosing wall > 0.25 sqm</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input">
                <input 
                    noNeg
                    type="text" 
                    [value]="info.additional.enclosing_wall" 
                    (focusout)="onFocusOut($event, info.floor, 'additional', 'enclosing_wall')"/>
            </td>
            <td class="additional_secondary cell_total cell_calculated">
                {{sizeInfo.data | rowsizesum:'additional':'enclosing_wall' }}
            </td>
        </tr>
        <tr>
            <th class="additional_item cell_item cell_last">Vertical penetrations part of common facilities</th>
            <td *ngFor="let info of sizeInfo.data" class="cell_input cell_last_additional gray">
                <input 
                    noNeg
                    type="text" 
                    [value]="info.additional.vertical_penetrations" 
                    (focusout)="onFocusOut($event, info.floor, 'additional', 'vertical_penetrations')"/>
            </td>
            <td class="additional_secondary cell_total cell_last_additional cell_calculated">
                {{sizeInfo.data | rowsizesum:'additional':'vertical_penetrations' }}
            </td>
        </tr>
    </tbody>
</table>

<ng-template #singlerow let-name="name" let-component="component" let-class="class">
    <tr>
        <th class="{{class}}_component cell_component">
            <div style="display: flex; justify-content: center;">
                <span>
                    {{name}}
                </span>
                <mat-icon 
                    style="cursor: pointer" 
                    container="body" 
                    [ngbPopover]="'TOOLTIP.INSPECTION.' + name + '_COMPONENT.DESCRIPTION'|translate"
                    [popoverTitle]="'TOOLTIP.INSPECTION.' + name + '_COMPONENT.TITLE'|translate">
                    help
                </mat-icon>
            </div>
        </th>
        <th class="{{class}}_item cell_item cell_last">IPMS Total</th>
        <td *ngFor="let info of sizeInfo.data" class="{{class}}_cell cell_input cell_last">
            <input 
                noNeg
                type="text" 
                [value]="info[component].total" 
                (focusout)="onFocusOut($event, info.floor, component, 'total')"/>
        </td>
        <td class="{{class}}_total_primary cell_total cell_last cell_calculated">
            {{sizeInfo.data | rowsizesum:component:'total'}}
        </td>
    </tr>
</ng-template>

<ng-template #threerow let-name="name" let-component="component" let-class="class">
    <tr>
        <th rowspan="3" class="{{class}}_component cell_component">
            <div style="display: flex; justify-content: center;">
                <span>
                    {{name}}
                </span>
                <mat-icon 
                    style="cursor: pointer" 
                    container="body" 
                    [ngbPopover]="'TOOLTIP.INSPECTION.' + name + '_COMPONENT.DESCRIPTION'|translate"
                    [popoverTitle]="'TOOLTIP.INSPECTION.' + name + '_COMPONENT.TITLE'|translate">
                    help
                </mat-icon>
            </div>
        </th>
        <th class="{{class}}_item cell_item">Total area</th>
        <td *ngFor="let info of sizeInfo.data" class="cell_input gray">
            <input 
                noNeg
                type="text" 
                [value]="info[component].total" 
                (focusout)="onFocusOut($event, info.floor, component, 'total')"/>
        </td>
        <td class="{{class}}_total_secondary cell_total cell_calculated">
            {{sizeInfo.data | rowsizesum:component:'total'}}
        </td>
    </tr>
    <tr>
        <th class="{{class}}_item cell_item">Limited use areas</th>
        <td *ngFor="let info of sizeInfo.data" class="cell_input">
            <input 
                noNeg
                type="text" 
                [value]="info[component].limited" 
                (focusout)="onFocusOut($event, info.floor, component, 'limited')"/>
        </td>
        <td class="{{class}}_total_secondary cell_total cell_calculated">
            {{sizeInfo.data | rowsizesum:component:'limited'}}
        </td>
    </tr>
    <tr>
        <th class="{{class}}_item cell_item cell_last">IPMS Total</th>
        <td *ngFor="let info of sizeInfo.data" class="{{class}}_cell cell_input cell_last cell_calculated">
            {{info[component].total - info[component].limited}}
        </td>
        <td class="{{class}}_total_primary cell_total cell_last cell_calculated">
            {{(sizeInfo.data | rowsizesum:component:'total') - (sizeInfo.data | rowsizesum:component:'limited')}}
        </td>
    </tr>
</ng-template>

<div class="legends">
    <ng-container *ngFor="let com of components">
        <div class="legend">
            <div class="color-block {{com.key}}_component"></div>
            <p><strong>{{com.name}}</strong> - {{com.description}}</p>
        </div>
    </ng-container>
</div>