// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {TpAdminTaskState} from '../_reducers/tp-admin-task.reducers';
import * as fromTpAdminTasks from '../_reducers/tp-admin-task.reducers';
import {TpAdminTaskModel} from '../_models/tp-admin-task.model';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';

export const selectTpAdminTasksState = createFeatureSelector<TpAdminTaskState>('tpAdminTasks');

export const selectTpAdminTaskById = (itemId: number) => createSelector(
    selectTpAdminTasksState,
    cs => cs.entities[itemId]
);

export const selectAllTpAdminTasks = createSelector(
    selectTpAdminTasksState,
    fromTpAdminTasks.selectAll
);

export const selectAllTpAdminTasksIds = createSelector(
    selectTpAdminTasksState,
    fromTpAdminTasks.selectIds
);

export const allTpAdminTasksLoaded = createSelector(
    selectTpAdminTasksState,
    cs => cs._isAllTpAdminTaskLoaded
);

export const selectTpAdminTasksPageLoading = createSelector(
    selectTpAdminTasksState,
    assetClassesState => assetClassesState.listLoading
);


export const selectTpAdminTasksShowInitWaitingMessage = createSelector(
    selectTpAdminTasksState,
    tpAdminTaskState => tpAdminTaskState.showInitWaitingMessage
);

export const selectTpAdminTasksActionLoading = createSelector(
    selectTpAdminTasksState,
    tpAdminTaskState => tpAdminTaskState.actionsLoading
);

export const selectTpAdminTasksByTpId = (tpId: number) => createSelector(
    selectTpAdminTasksState,
    tpAdminTaskState => {
        const items: TpAdminTaskModel[] = [];
        each(tpAdminTaskState.entities, element => {
            if (element.tp_id == tpId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'task_id');
    }
);

export const selectUploadTaskUsersByTpId = (tpId: number) => createSelector(
    selectTpAdminTasksState,
    tpAdminTaskState => {
        const items: TpAdminTaskModel[] = [];
        each(tpAdminTaskState.entities, element => {
            if (element.tp_id == tpId && element.task_id == 0) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArrayNumber(items, 'second_id');
    }
);

export const selectTpAdminTasksByToeId = (toeId: number) => createSelector(
    selectTpAdminTasksState,
    tpAdminTaskState => {
        const items: TpAdminTaskModel[] = [];
        each(tpAdminTaskState.entities, element => {
            if (element.toe_id == toeId) {
                items.push(element);
            }
        });

        const httpExtension = new HttpExtenstionsModel();
        return httpExtension.sortArray(items, 'tp_id');
    }
);


export const selectTpAdminTasksInStore = createSelector(
    selectTpAdminTasksState,
    tpAdminTaskState => {
        const items: TpAdminTaskModel[] = [];
        each(tpAdminTaskState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: TpAdminTaskModel[] = [];
        const res = httpExtension.separateArrayByCompletion(items);
        let completedResult: TpAdminTaskModel[] = [];
        let inCompletedResult: TpAdminTaskModel[] = [];
        if (tpAdminTaskState.lastQuery.sortField === 'updated_at') {
            completedResult = httpExtension.sortArrayDateTime(
                res['completed'],
                tpAdminTaskState.lastQuery.sortField,
                tpAdminTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayDateTime(
                res['inCompleted'],
                tpAdminTaskState.lastQuery.sortField,
                tpAdminTaskState.lastQuery.sortOrder
            );
        } else if (tpAdminTaskState.lastQuery.sortField === 'completion') {
            completedResult = httpExtension.sortArrayCompletion(
                res['completed'],
                tpAdminTaskState.lastQuery.sortField,
                tpAdminTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArrayCompletion(
                res['inCompleted'],
                tpAdminTaskState.lastQuery.sortField,
                tpAdminTaskState.lastQuery.sortOrder
            );
        } else {
            completedResult = httpExtension.sortArray(
                res['completed'],
                tpAdminTaskState.lastQuery.sortField,
                tpAdminTaskState.lastQuery.sortOrder
            );
            inCompletedResult = httpExtension.sortArray(
                res['inCompleted'],
                tpAdminTaskState.lastQuery.sortField,
                tpAdminTaskState.lastQuery.sortOrder
            );
        }
        result = inCompletedResult.concat(completedResult);
        return new QueryResultsModel(result, tpAdminTaskState.totalCount, '');
    }
);