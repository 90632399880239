// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {FireplacesChimneyBreast} from '../_models/fireplaces-chimney-breast.model';

export enum FireplacesChimneyBreastActionTypes {

    AllFireplacesChimneyBreastsRequested = '[FireplacesChimneyBreasts Home Page] All FireplacesChimneyBreasts Requested',
    AllFireplacesChimneyBreastsLoaded = '[FireplacesChimneyBreasts API] All FireplacesChimneyBreasts Loaded',

    FireplacesChimneyBreastOnServerCreated = '[Edit FireplacesChimneyBreast Dialog] FireplacesChimneyBreast On Server Created',
    FireplacesChimneyBreastCreated = '[Edit FireplacesChimneyBreasts Dialog] FireplacesChimneyBreasts Created',
    FireplacesChimneyBreastUpdated = '[Edit FireplacesChimneyBreast Dialog] FireplacesChimneyBreast Updated',
    FireplacesChimneyBreastDeleted = '[FireplacesChimneyBreasts List Page] FireplacesChimneyBreast Deleted',

    FireplacesChimneyBreastsPageRequested = '[FireplacesChimneyBreasts List Page] FireplacesChimneyBreasts Page Requested',
    FireplacesChimneyBreastsPageLoaded = '[FireplacesChimneyBreasts API] FireplacesChimneyBreasts Page Loaded',
    FireplacesChimneyBreastsPageCancelled = '[FireplacesChimneyBreasts API] FireplacesChimneyBreasts Page Cancelled',

    FireplacesChimneyBreastsPageToggleLoading = '[FireplacesChimneyBreasts page] FireplacesChimneyBreasts Page Toggle Loading',
    FireplacesChimneyBreastsActionToggleLoading = '[FireplacesChimneyBreasts] FireplacesChimneyBreasts Action Toggle Loading',

    // trash system

    FireplacesChimneyBreastOnServerRestored = '[FireplacesChimneyBreast Trash] FireplacesChimneyBreast On Server Restored',
    FireplacesChimneyBreastRestored = '[FireplacesChimneyBreast Trash] FireplacesChimneyBreast Restored',

    FireplacesChimneyBreastOnServerAdminRestored = '[FireplacesChimneyBreast Admin Trash] FireplacesChimneyBreast On Server Restored',
    FireplacesChimneyBreastAdminRestored = '[FireplacesChimneyBreast Admin Trash] FireplacesChimneyBreast Restored',

    FireplacesChimneyBreastDeletedFromTrash = '[FireplacesChimneyBreast Trash] FireplacesChimneyBreast deleted',
    FireplacesChimneyBreastDeletedFromAdminTrash = '[FireplacesChimneyBreast Admin Trash] FireplacesChimneyBreast deleted',

    FireplacesChimneyBreastTrash = 'FireplacesChimneyBreast Trashed',
    FireplacesChimneyBreastTrashFlushed = 'FireplacesChimneyBreast Trash Flushed',

    FireplacesChimneyBreastsClear = '[FireplacesChimneyBreasts API] FireplacesChimneyBreasts Page Clear',
}

export class FireplacesChimneyBreastOnServerCreated implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerCreated;

    constructor(public payload: { fireplacesChimneyBreast: FireplacesChimneyBreast}) {
    }
}

export class FireplacesChimneyBreastCreated implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastCreated;

    constructor(public payload: { fireplacesChimneyBreast: FireplacesChimneyBreast }) {
    }
}

export class FireplacesChimneyBreastUpdated implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastUpdated;

    constructor(public payload: {
        partialFireplacesChimneyBreast: Update<FireplacesChimneyBreast>,
        fireplacesChimneyBreast: FireplacesChimneyBreast
    }) {
    }
}

export class FireplacesChimneyBreastDeleted implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class FireplacesChimneyBreastsPageRequested implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class FireplacesChimneyBreastsPageLoaded implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageLoaded;

    constructor(public payload: { fireplacesChimneyBreasts: FireplacesChimneyBreast[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class FireplacesChimneyBreastsPageCancelled implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageCancelled;
}

export class AllFireplacesChimneyBreastsRequested implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.AllFireplacesChimneyBreastsRequested;
}

export class AllFireplacesChimneyBreastsLoaded implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.AllFireplacesChimneyBreastsLoaded;

    constructor(public payload: { fireplacesChimneyBreasts: FireplacesChimneyBreast[] }) {
    }
}

export class FireplacesChimneyBreastsPageToggleLoading implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class FireplacesChimneyBreastsActionToggleLoading implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class FireplacesChimneyBreastDeletedFromAdminTrash implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class FireplacesChimneyBreastDeletedFromTrash implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class FireplacesChimneyBreastOnServerRestored implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FireplacesChimneyBreastRestored implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastRestored;

    constructor(public payload: { item: FireplacesChimneyBreast }) {
    }
}

export class FireplacesChimneyBreastOnServerAdminRestored implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class FireplacesChimneyBreastAdminRestored implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastAdminRestored;

    constructor(public payload: { item: FireplacesChimneyBreast }) {
    }
}

export class FireplacesChimneyBreastTrashFlushed implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastTrashFlushed;

    constructor() {
    }
}

export class FireplacesChimneyBreastsClear implements Action {
    readonly type = FireplacesChimneyBreastActionTypes.FireplacesChimneyBreastsClear;

    constructor() {
    }
}

export type FireplacesChimneyBreastActions = FireplacesChimneyBreastCreated
    | FireplacesChimneyBreastUpdated
    | FireplacesChimneyBreastDeleted
    | FireplacesChimneyBreastsPageRequested
    | FireplacesChimneyBreastsPageLoaded
    | FireplacesChimneyBreastsPageCancelled
    | AllFireplacesChimneyBreastsLoaded
    | AllFireplacesChimneyBreastsRequested
    | FireplacesChimneyBreastOnServerCreated
    | FireplacesChimneyBreastsPageToggleLoading
    | FireplacesChimneyBreastsActionToggleLoading
    | FireplacesChimneyBreastDeletedFromAdminTrash
    | FireplacesChimneyBreastDeletedFromTrash
    | FireplacesChimneyBreastOnServerRestored
    | FireplacesChimneyBreastRestored
    | FireplacesChimneyBreastOnServerAdminRestored
    | FireplacesChimneyBreastAdminRestored
    | FireplacesChimneyBreastTrashFlushed
    | FireplacesChimneyBreastsClear;
