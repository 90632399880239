// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {MainWallActions, MainWallActionTypes} from '../_actions/main-wall.actions';
// Models
import {MainWallModel} from '../_models/main-wall.model';
import {QueryParamsModel} from '../../_base/crud';

export interface MainWallsState extends EntityState<MainWallModel> {
    isAllMainWallsLoaded: boolean;
    queryRowsCount: number;
    queryResult: MainWallModel[];
    lastCreatedMainWallId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<MainWallModel> = createEntityAdapter<MainWallModel>();

export const initialMainWallState: MainWallsState = adapter.getInitialState({
    isAllMainWallsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedMainWallId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function mainWallReducer(state = initialMainWallState, action: MainWallActions): MainWallsState {
    switch (action.type) {
        case MainWallActionTypes.MainWallsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMainWallId: undefined
            };
        case MainWallActionTypes.MainWallActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case MainWallActionTypes.MainWallOnServerCreated:
            return {
                ...state
            };
        case MainWallActionTypes.MainWallCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedMainWallId: action.payload.item.id
            });
        case MainWallActionTypes.MainWallUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case MainWallActionTypes.AllMainWallsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllMainWallsLoaded: true
            });
        case MainWallActionTypes.MainWallsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case MainWallActionTypes.MainWallsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialMainWallState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case MainWallActionTypes.MainWallDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case MainWallActionTypes.MainWallRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case MainWallActionTypes.MainWallDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case MainWallActionTypes.MainWallAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case MainWallActionTypes.MainWallDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case MainWallActionTypes.MainWallTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
