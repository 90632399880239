// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import { User } from '../../mad-auth/mad-auth.store';
// Models
import {QueryParamsModel} from '../../_base/crud';

export enum UserActionTypes {
    AllUsersOfRoleRequested = '[Users Module] All Users Requested',
    AllUsersOfRoleLoaded = '[Users Module] All Users Loaded',

    AllUsersLoaded = '[Users API] All Users Loaded',

    UserOnServerCreated = '[Edit User Component] User On Server Created',
    UserCreated = '[Edit User Dialog] User Created',
    UserUpdated = '[Edit User Dialog] User Updated',
    UserDeleted = '[Users List Page] User Deleted',
    UserStatusUpdated = '[Edit user status ] Status Updated',

    UserOnServerRestored = '[User Trash] User On Server Restored',
    UserRestored = '[User Trash] User Restored',

    UserOnServerAdminRestored = '[User Admin Trash] User On Server Restored',
    UserAdminRestored = '[User Admin Trash] User Restored',

    UserDeletedFromTrash = '[User Trash] User deleted',
    UserDeletedFromAdminTrash = '[User Admin Trash] User deleted',

    UserTrash = 'User Trashed',
    UserTrashFlushed = 'User Trash Flushed',

    UsersPageRequested = '[Users List Page] Users Page Requested',
    UsersPageLoaded = '[Users API] Users Page Loaded',

    UsersPageCancelled = '[Users API] Users Page Cancelled',
    UsersPageToggleLoading = '[Users] Users Page Toggle Loading',
    UsersActionToggleLoading = '[Users] Users Action Toggle Loading',

    UserNameCheckRequested = '[Users API] Username check Requested',
    UserNameCheckAction = '[Users API] Username check Action'
}

export class AllUsersOfRoleRequested implements Action {
    readonly type = UserActionTypes.AllUsersOfRoleRequested;

    constructor(public payload: { roleId: number }) {
    }
}

export class AllUsersOfRoleLoaded implements Action {
    readonly type = UserActionTypes.AllUsersOfRoleLoaded;

    constructor(public payload: { users: User[] }) {
    }
}

export class UserOnServerCreated implements Action {
    readonly type = UserActionTypes.UserOnServerCreated;

    constructor(public payload: { user: User }) {
    }
}

export class UserCreated implements Action {
    readonly type = UserActionTypes.UserCreated;

    constructor(public payload: { user: User }) {
    }
}


export class UserUpdated implements Action {
    readonly type = UserActionTypes.UserUpdated;

    constructor(public payload: {
        partialUser: Update<User>,
        user: User
    }) {
    }
}

export class UserDeleted implements Action {
    readonly type = UserActionTypes.UserDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class UsersPageRequested implements Action {
    readonly type = UserActionTypes.UsersPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class UsersPageLoaded implements Action {
    readonly type = UserActionTypes.UsersPageLoaded;

    constructor(public payload: {
        items: User[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}


export class UsersPageCancelled implements Action {
    readonly type = UserActionTypes.UsersPageCancelled;
}

export class UsersPageToggleLoading implements Action {
    readonly type = UserActionTypes.UsersPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class UsersActionToggleLoading implements Action {
    readonly type = UserActionTypes.UsersActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class UserNameCheckRequested implements Action {
    readonly type = UserActionTypes.UserNameCheckRequested;

    constructor(public payload: { userName: string }) {
    }
}

export class UserNameCheckAction implements Action {
    readonly type = UserActionTypes.UserNameCheckAction;

    constructor(public payload: { userNameExists: boolean }) {
    }
}


// Trash
export class UserTrashFlushed implements Action {
    readonly type = UserActionTypes.UserTrashFlushed;

    constructor() {
    }
}

export class UserDeletedFromTrash implements Action {
    readonly type = UserActionTypes.UserDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class UserDeletedFromAdminTrash implements Action {
    readonly type = UserActionTypes.UserDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class UserOnServerRestored implements Action {
    readonly type = UserActionTypes.UserOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UserRestored implements Action {
    readonly type = UserActionTypes.UserRestored;

    constructor(public payload: { item: User }) {
    }
}

export class UserOnServerAdminRestored implements Action {
    readonly type = UserActionTypes.UserOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UserAdminRestored implements Action {
    readonly type = UserActionTypes.UserAdminRestored;

    constructor(public payload: { item: User }) {
    }
}

export class UserStatusUpdated implements Action {
    readonly type = UserActionTypes.UserStatusUpdated;

    constructor(public payload: {
        user: Update<User>
    }) {
    }
}


export type UserActions = UserCreated
    | UserUpdated
    | UserDeleted
    | UserStatusUpdated
    | UserTrashFlushed
    | UserDeletedFromTrash
    | UserDeletedFromAdminTrash
    | UserOnServerRestored
    | UserRestored
    | UserOnServerAdminRestored
    | UserAdminRestored
    | UserOnServerCreated
    | UsersPageLoaded
    | UsersPageCancelled
    | UsersPageToggleLoading
    | UsersPageRequested
    | AllUsersOfRoleRequested
    | AllUsersOfRoleLoaded
    | UsersActionToggleLoading
    | UserNameCheckRequested
    | UserNameCheckAction;
