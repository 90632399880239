// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {BuiltinFittingActions, BuiltinFittingActionTypes} from '../_actions/builtin-fitting.actions';
// Models
import {BuiltinFitting} from '../_models/builtin-fitting.model';
import {QueryParamsModel} from '../../_base/crud';

export interface BuiltinFittingsState extends EntityState<BuiltinFitting> {
    isAllBuiltinFittingsLoaded: boolean;
    queryRowsCount: number;
    queryResult: BuiltinFitting[];
    lastCreatedBuiltinFittingId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BuiltinFitting> = createEntityAdapter<BuiltinFitting>();

export const initialBuiltinFittingsState: BuiltinFittingsState = adapter.getInitialState({
    isAllBuiltinFittingsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedBuiltinFittingId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function builtinFittingsReducer(state = initialBuiltinFittingsState, action: BuiltinFittingActions): BuiltinFittingsState {
    switch (action.type) {
        case BuiltinFittingActionTypes.BuiltinFittingsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBuiltinFittingId: undefined
            };
        case BuiltinFittingActionTypes.BuiltinFittingsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case BuiltinFittingActionTypes.BuiltinFittingOnServerCreated:
            return {
                ...state
            };
        case BuiltinFittingActionTypes.BuiltinFittingCreated:
            return adapter.addOne(action.payload.builtinFitting, {
                ...state, lastCreatedBuiltinFittingId: action.payload.builtinFitting.id
            });
        case BuiltinFittingActionTypes.BuiltinFittingUpdated:
            return adapter.updateOne(action.payload.partialBuiltinFitting, state);
        case BuiltinFittingActionTypes.AllBuiltinFittingsLoaded:
            return adapter.addAll(action.payload.builtinFittings, {
                ...state, isAllBuiltinFittingsLoaded: true
            });
        case BuiltinFittingActionTypes.BuiltinFittingsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case BuiltinFittingActionTypes.BuiltinFittingsPageLoaded:
            return adapter.addMany(action.payload.builtinFittings, {
                ...initialBuiltinFittingsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case BuiltinFittingActionTypes.BuiltinFittingDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case BuiltinFittingActionTypes.BuiltinFittingRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case BuiltinFittingActionTypes.BuiltinFittingDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case BuiltinFittingActionTypes.BuiltinFittingAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case BuiltinFittingActionTypes.BuiltinFittingDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case BuiltinFittingActionTypes.BuiltinFittingTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
