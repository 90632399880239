// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectSourceTypesQueryResult, selectSourceTypesPageLoading, selectSourceTypesShowInitWaitingMessage} from '../_selectors/source-type.selectors';

export class SourceTypesDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectSourceTypesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectSourceTypesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectSourceTypesQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
