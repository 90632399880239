import {Grade} from '../_models/grade.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {GradesState} from '../_reducers/grade.reducers';
import * as fromGrade from '../_reducers/grade.reducers';
import {each} from 'lodash';

export const selectGradesState = createFeatureSelector<GradesState>('grades');

export const selectGradeById = (gradeId: number) => createSelector(
    selectGradesState,
    gradesState => gradesState.entities[gradeId]
);

export const selectAllGrades = createSelector(
    selectGradesState,
    fromGrade.selectAll
);

export const selectAllGradesIds = createSelector(
    selectGradesState,
    fromGrade.selectIds
);

export const allGradesLoaded = createSelector(
    selectGradesState,
    gradesState => gradesState.isAllGradesLoaded
);


export const selectGradesPageLoading = createSelector(
    selectGradesState,
    gradesState => gradesState.listLoading
);

export const selectGradesActionLoading = createSelector(
    selectGradesState,
    gradesState => gradesState.actionLoading
);

export const selectLastCreatedGradeId = createSelector(
    selectGradesState,
    gradesState => gradesState.lastCreatedGradeId
);

export const selectGradesShowInitWaitingMessage = createSelector(
    selectGradesState,
    gradesState => gradesState.showInitWaitingMessage
);

export const selectTrashedGradeCount = createSelector(
    selectGradesState,
    (gradesState) => gradesState.totalTrashed
);

export const selectAdminTrashedGradeCount = createSelector(
    selectGradesState,
    (gradesState) => gradesState.totalAdminTrashed
);


export const selectGradeQueryResult = createSelector(
    selectGradesState,
    gradesState => {
        const items: Grade[] = [];
        each(gradesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Grade[] = httpExtension.sortArray(items, gradesState.lastQuery.sortField, gradesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, gradesState.totalCount, '', gradesState.totalTrashed);
    }
);
