<div *ngIf="tp.length == 0; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
    <h6 style="color:red;">
        There are currently no target properties to choose from
    </h6>
</ng-template>
<ng-template #elseBlock>
    <div class="mb-4">
        <tag-input matInput
                [(ngModel)]="currentTPs"
                [identifyBy]="'id'"
                [displayBy]="'order_num_label'"
                (click)="chooseTps()"
                (onRemove)="tagRemove($event)"
                [placeholder]="'+ Property'"
                [secondaryPlaceholder]="'+ Property'"
                #ngx>
        </tag-input>
    </div>
    <div class="example-container mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource">
            <!-- Position Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                <mat-cell *matCellDef="let tp"> {{tp.order_num}}</mat-cell>
            </ng-container>
            <!-- Weight Column -->
            <ng-container matColumnDef="asset_class_names">
                <mat-header-cell *matHeaderCellDef>Properties ID</mat-header-cell>
                <mat-cell *matCellDef="let tp"> {{tp.asset_class_names}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="conflict_name">
                <mat-header-cell *matHeaderCellDef>Conflict</mat-header-cell>
                <mat-cell *matCellDef="let tp"> {{conflicts[tp.conflict_id - 1].name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="conflict_descr">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let tp"> {{tp.conflict_descr}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="conflict_descr_b">
                <mat-header-cell *matHeaderCellDef>Mitigation</mat-header-cell>
                <mat-cell *matCellDef="let tp"> {{tp.conflict_descr_b}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
                <mat-cell *matCellDef="let tp"> {{tp.asset_class_names.split(',').length == 1 ? 'No' : 'Yes ('+tp.asset_class_names.split(',').length+')'}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let tp; let i = index;">
                    <button mat-icon-button
                            [disabled]="tp.can_edit && tp.can_edit == 0"
                            color="primary"
                            matTooltip="Edit Target Property"
                            (click)="editTP(tp, i)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <input #name style="height: 1px; border: none; background-color: transparent;">
    </div>
    <div lass="col-lg-12 kt-margin-bottom-20-mobile">
        <div class="d-inline-block mt-2" *ngIf="conflictData.mitigation_strategy && conflictData.mitigation_strategy.length > 0"
             style="font-size: small; text-align: justify; text-justify: inter-word;">
            <b>Mitigation strategy: </b>
            '{{conflictData.mitigation_strategy}}'
        </div>
    
        <div class="d-inline-block mt-2" *ngIf="conflictData.mitigation_strategy == null || conflictData.mitigation_strategy.length == 0"
             style="font-size: small; text-align: justify; text-justify: inter-word;">
            NO MITIGATION STRATEGY
        </div>
    </div>
</ng-template>
