import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { FacilityEditDialogComponent } from '../edit/facility-edit-dialog.component';
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';
import { ComparablesRadioButtonValues } from "../../../../../core/comparable/_models/RadioButtonValues";
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'kt-alt-facilities-list',
    templateUrl: './facilities-sub-list.component.html',
    styleUrls: ['./facilities-sub-list.component.scss']
})

export class FacilitiesSubListComponent implements OnInit, OnDestroy {
    @Input() readonly: boolean;
    @Input() selectedFacilities: BehaviorSubject<any[]>;
    @Input() isBuildingFacility = false;
    @Input() assetClassTypeId = 1;
    @Input() inValid: Subject<boolean>;
    currentFacilities: any[];
    dialogRef: any;
    radioButtonValues = ComparablesRadioButtonValues;

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param location
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param cdRef
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public location: Location,
                public typesUtilsService: TypesUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>,
                private cdRef: ChangeDetectorRef) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.selectedFacilities.subscribe(facilities => this.currentFacilities = facilities);
        if (this.inValid) {
            this.inValid.next(false);
        }
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    chooseFacilities() {
        this.dialogRef = this.dialog.open(FacilityEditDialogComponent, {
            data: {
                currentFacilities: this.currentFacilities,
                isBuildingFacility: this.isBuildingFacility,
                assetClassTypeId: this.assetClassTypeId,
                title: this.isBuildingFacility ? 'Building Facilities' : this.getPlaceholder().substring(2),
            }
        });
        this.dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    if (this.inValid) {
                        this.inValid.next(true);
                    }
                    return;
                }
                if (res.data.length == 0) {
                    if (this.inValid) {
                        this.inValid.next(true);
                    }
                }
                this.currentFacilities = res.data;
                if (this.inValid) {
                    this.inValid.next(false);
                }
                this.cdRef.markForCheck();
                this.cdRef.detectChanges();
            }
        );
    }

    tagRemove($event) {
        const tempFacilities = [...this.currentFacilities];
        const index = tempFacilities.findIndex(element => element.facility_id === $event.facility_id);
        if (index !== -1) {
            tempFacilities.splice(index, 1);
        }
        this.currentFacilities = [...tempFacilities];
        if (this.currentFacilities.length == 0) {
            if (this.inValid) {
                this.inValid.next(true);
            }
        }
    }

    getPlaceholder() {
        return this.isBuildingFacility ? 'Has the building got any facilities?' : '+ ' + this.typesUtilsService.getAssetClassTypeName(this.assetClassTypeId.toString()) + ' facilities' + (this.assetClassTypeId.toString() == '3' ? ' (common use)' : this.assetClassTypeId.toString() == '12' ? ' (exclusive use)' : '');
    }
}
