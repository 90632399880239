import {UnitLengthMeasurement} from '../_models/unit-length-measurement.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {UnitLengthMeasurementsState} from '../_reducers/unit-length-measurement.reducers';
import * as fromUnitLengthMeasurement from '../_reducers/unit-length-measurement.reducers';
import {each} from 'lodash';

export const selectUnitLengthMeasurementsState = createFeatureSelector<UnitLengthMeasurementsState>('unitLengthMeasurements');

export const selectUnitLengthMeasurementById = (unitLengthMeasurementId: number) => createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => unitLengthMeasurementsState.entities[unitLengthMeasurementId]
);

export const selectAllUnitLengthMeasurements = createSelector(
    selectUnitLengthMeasurementsState,
    fromUnitLengthMeasurement.selectAll
);

export const selectAllUnitLengthMeasurementsIds = createSelector(
    selectUnitLengthMeasurementsState,
    fromUnitLengthMeasurement.selectIds
);

export const allUnitLengthMeasurementsLoaded = createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => unitLengthMeasurementsState.isAllUnitLengthMeasurementsLoaded
);


export const selectUnitLengthMeasurementsPageLoading = createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => unitLengthMeasurementsState.listLoading
);

export const selectUnitLengthMeasurementsActionLoading = createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => unitLengthMeasurementsState.actionLoading
);

export const selectLastCreatedUnitLengthMeasurementId = createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => unitLengthMeasurementsState.lastCreatedUnitLengthMeasurementId
);

export const selectUnitLengthMeasurementsShowInitWaitingMessage = createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => unitLengthMeasurementsState.showInitWaitingMessage
);

export const selectTrashedUnitLengthMeasurementCount = createSelector(
    selectUnitLengthMeasurementsState,
    (unitLengthMeasurementsState) => unitLengthMeasurementsState.totalTrashed
);

export const selectAdminTrashedUnitLengthMeasurementCount = createSelector(
    selectUnitLengthMeasurementsState,
    (unitLengthMeasurementsState) => unitLengthMeasurementsState.totalAdminTrashed
);


export const selectUnitLengthMeasurementsQueryResult = createSelector(
    selectUnitLengthMeasurementsState,
    unitLengthMeasurementsState => {
        const items: UnitLengthMeasurement[] = [];
        each(unitLengthMeasurementsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: UnitLengthMeasurement[] = httpExtension.sortArray(items, unitLengthMeasurementsState.lastQuery.sortField, unitLengthMeasurementsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, unitLengthMeasurementsState.totalCount, '', unitLengthMeasurementsState.totalTrashed);
    }
);
