import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { uniq } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { startWith, take, takeUntil } from 'rxjs/operators';
import { AssetClassLandAreaComponentModel } from 'src/app/core/asset_class';
import { AcSource } from 'src/app/core/comparable';
import { AssetClassTenure, AcLandTenure, PropertyTenure } from 'src/app/core/comparable/_models/asset-class-tenure.model';
import { CountryData } from 'src/app/views/pages/admin-management/countries/countries-list/local-data';
import { SourceComponent } from '../../../../shared_components/source/source.component';
import { ParcelConsiderationTableComponent } from '../parcel-consideration-table/parcel-consideration-table.component';

interface IAddConsiderationData {
  acTenure: AssetClassTenure;
  components: AssetClassLandAreaComponentModel[],
  hasParcel: boolean,
  mode: 'add' | 'edit',
  editable: boolean,
  otherDates: {tenure: string, date: string}[]
}

@Component({
  selector: 'kt-add-consideration-dialog',
  templateUrl: './add-consideration-dialog.component.html',
  styleUrls: ['./add-consideration-dialog.component.scss', '../../../complex-btn.scss']
})
export class AddConsiderationDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('singleSelectCurrency') singleSelectCurrency: MatSelect;
  @ViewChild(SourceComponent, {static: false})
  private sourceComponent: SourceComponent;
  @ViewChild(ParcelConsiderationTableComponent, {static: false})
  private parcelConsiderations: ParcelConsiderationTableComponent;

  form: UntypedFormGroup;
  formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
  hasFormErrors: boolean = false;
  acTenure: AssetClassTenure;

  // Property Tenure
  countriesData = CountryData.countries;
  currencyCodes = [];
  filteredCountriesData: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  filterCtrlCountriesData: UntypedFormControl = new UntypedFormControl();
  private subscriptions: Subscription[] = [];
  private _onDestroy = new Subject<void>();
  options = [
      'Freehold',
      'Leasehold'
  ]
  selectedTenure = '';

  // Source 
  sourceSubject = new BehaviorSubject<AcSource>(new AcSource());

  // Land 
  parcelError$ = new BehaviorSubject(false);
  otherDates: string[] = [];
  isComplexform: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddConsiderationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddConsiderationData,
    private formBuilder: UntypedFormBuilder
  ) { 
    this.acTenure = this.data.acTenure;
    this.sourceSubject.next(this.data.acTenure.source);
    this.otherDates = this.data.otherDates.map(od => od.date);
  }

  ngOnInit(): void {
    this.isComplexform = this.acTenure.formType == 1;
    this.formSelectionCtrl.setValue(this.acTenure.formType);
    this.formSelectionCtrl.updateValueAndValidity();
    this.formSelectionCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
        if (val == 1) {
            this.isComplexform = true;
        } else {
            this.isComplexform = false;
        }
    })
    this.currencyCodes = Array.from(new Set<string>(this.countriesData.map(el => el.currencyCode)));
    this.filteredCountriesData.next(this.currencyCodes);

    this.filterCtrlCountriesData.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
            this.filterCountriesData();
        })
    this.form = this.formBuilder.group({
      currency: [this.acTenure.currency],
      tenure: [this.acTenure.tenure, [Validators.required]],
      total_consideration: [this.acTenure.total_consideration, [Validators.required, notNegativeValidator]],
      rent_type: [this.acTenure.rent_type],
      rent_review: [this.acTenure.rent_review],
      rent_review_comment: [this.acTenure.rent_review_comment],
      lease_duration: [this.acTenure.lease_duration],
      unexpired_lease_term: [this.acTenure.unexpired_lease_term],
      write_off_period: [this.acTenure.write_off_period],
      break_option: [this.acTenure.break_option],
      rent_free_period: [this.acTenure.rent_free_period],
      fitting_out_period: [this.acTenure.fitting_out_period],
      cap_payment: [this.acTenure.cap_payment],
      inclusive_property_management: [this.acTenure.inclusive_property_management],
      estimated_amount_property_management: [this.acTenure.estimated_amount_property_management],
      consideration_inclusive_cost: [this.acTenure.consideration_inclusive_cost],
      estimated_amount_utilities_cost: [this.acTenure.estimated_amount_utilities_cost],
      consideration_full_repair_cost: [this.acTenure.consideration_full_repair_cost],
      estimated_amount_repair_cost: [this.acTenure.estimated_amount_repair_cost],
    }, {validators: [
          checkIfLesserThanLease('rent_review'),
          checkIfLesserThanLease('unexpired_lease_term'),
          checkIfLesserThanLease('write_off_period'),
          checkIfLesserThanOrEqualToLease('break_option'),
          checkIfLesserThanOrEqualToLease('rent_free_period'),
          checkIfLesserThanOrEqualToLease('fitting_out_period'),
          checkIfLesserThanWriteOff('rent_free_period'),
          checkIfLesserThanWriteOff('fitting_out_period')
    ]});
    const tenureCtrl = this.form.get('tenure');
    const tenureChangeSub = tenureCtrl.valueChanges.pipe(startWith(tenureCtrl.value)).subscribe(tenureType => {
        if (tenureType === 'freehold') {
            this.form.get('rent_type').setValidators(null);
            this.form.get('rent_type').updateValueAndValidity();
            this.form.get('rent_review').setValidators(null);
            this.form.get('rent_review').updateValueAndValidity();
            this.form.get('lease_duration').setValidators(null);
            this.form.get('lease_duration').updateValueAndValidity();
            this.form.get('unexpired_lease_term').setValidators(null);
            this.form.get('unexpired_lease_term').updateValueAndValidity();
            this.form.get('write_off_period').setValidators(null);
            this.form.get('write_off_period').updateValueAndValidity();
            this.form.get('break_option').setValidators(null);
            this.form.get('break_option').updateValueAndValidity();
            this.form.get('rent_free_period').setValidators(null);
            this.form.get('rent_free_period').updateValueAndValidity();
            this.form.get('fitting_out_period').setValidators(null);
            this.form.get('fitting_out_period').updateValueAndValidity();
            this.form.get('inclusive_property_management').setValidators(null);
            this.form.get('inclusive_property_management').updateValueAndValidity();
            this.form.get('consideration_inclusive_cost').setValidators(null);
            this.form.get('consideration_inclusive_cost').updateValueAndValidity();
            this.form.get('consideration_full_repair_cost').setValidators(null);
            this.form.get('consideration_full_repair_cost').updateValueAndValidity();
        } else {
            this.form.get('rent_type').setValidators(Validators.required);
            this.form.get('rent_type').updateValueAndValidity();
            this.form.get('rent_review').setValidators([notNegativeValidator, notZeroValidator]);
            this.form.get('rent_review').updateValueAndValidity();
            this.form.get('lease_duration').setValidators([Validators.required, shouldBePositive]);
            this.form.get('lease_duration').updateValueAndValidity();
            this.form.get('unexpired_lease_term').setValidators([Validators.required, shouldBePositive]);
            this.form.get('unexpired_lease_term').updateValueAndValidity();
            this.form.get('write_off_period').setValidators([notNegativeValidator, notZeroValidator]);
            this.form.get('write_off_period').updateValueAndValidity();
            this.form.get('break_option').setValidators([notNegativeValidator]);
            this.form.get('break_option').updateValueAndValidity();
            this.form.get('rent_free_period').setValidators([notNegativeValidator]);
            this.form.get('rent_free_period').updateValueAndValidity();
            this.form.get('fitting_out_period').setValidators([notNegativeValidator]);
            this.form.get('fitting_out_period').updateValueAndValidity();
            this.form.get('inclusive_property_management').setValidators(Validators.required);
            this.form.get('inclusive_property_management').updateValueAndValidity();
            this.form.get('consideration_inclusive_cost').setValidators(Validators.required);
            this.form.get('consideration_inclusive_cost').updateValueAndValidity();
            this.form.get('consideration_full_repair_cost').setValidators(Validators.required);
            this.form.get('consideration_full_repair_cost').updateValueAndValidity();
        }
        this.otherDates = this.data.otherDates.filter(od => od.tenure == tenureType).map(od => od.date);
    });
    this.subscriptions.push(tenureChangeSub);

    const rentReviewCtrl = this.form.get('rent_review');
    const rentReviewChangeSub = rentReviewCtrl.valueChanges.subscribe(val => {
        if (val !== '' && val !== null && this.isComplexform) {
            this.form.get('rent_review_comment').setValidators(Validators.required);
            this.form.get('rent_review_comment').updateValueAndValidity();
        } else {
            this.form.get('rent_review_comment').setValidators(null);
            this.form.get('rent_review_comment').updateValueAndValidity();
        }
    });
    this.subscriptions.push(rentReviewChangeSub);

    const inclusivePropertyManagementCtrl = this.form.get('inclusive_property_management');
    const inclusivePropertyManagementChangeSub = inclusivePropertyManagementCtrl.valueChanges.subscribe(val => {
        if (val === 'no') {
            this.form.get('estimated_amount_property_management').setValidators(Validators.required);
            this.form.get('estimated_amount_property_management').updateValueAndValidity();
        } else {
            this.form.get('estimated_amount_property_management').setValidators(null);
            this.form.get('estimated_amount_property_management').updateValueAndValidity();
        }
    });
    this.subscriptions.push(inclusivePropertyManagementChangeSub);
    const considerationInclusiveCostCtrl = this.form.get('consideration_inclusive_cost');
    const considerationInclusiveCostChangeSub = considerationInclusiveCostCtrl.valueChanges.subscribe(val => {
        if (val === 'no') {
            this.form.get('estimated_amount_utilities_cost').setValidators(Validators.required);
            this.form.get('estimated_amount_utilities_cost').updateValueAndValidity();
        } else {
            this.form.get('estimated_amount_utilities_cost').setValidators(null);
            this.form.get('estimated_amount_utilities_cost').updateValueAndValidity();
        }
    });
    this.subscriptions.push(considerationInclusiveCostChangeSub);
    const considerationFullRepairCtrl = this.form.get('consideration_full_repair_cost');
    const considerationFullRepairSub = considerationFullRepairCtrl.valueChanges.subscribe(val => {
        if (val === 'no') {
            this.form.get('estimated_amount_repair_cost').setValidators(Validators.required);
            this.form.get('estimated_amount_repair_cost').updateValueAndValidity();
        } else {
            this.form.get('estimated_amount_repair_cost').setValidators(null);
            this.form.get('estimated_amount_repair_cost').updateValueAndValidity();
        }
    });
    this.subscriptions.push(considerationFullRepairSub);
  }

  ngAfterViewInit(): void {
    this.setInitialValueCurrency();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.parcelError$.next(false);
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
          controls[controlName].markAsTouched()
      });
      this.hasFormErrors = true;
    }

    if (this.parcelConsiderations && !this.parcelConsiderations.validate()) {
        this.hasFormErrors = true;
        this.parcelError$.next(true);
    }

    if (!this.sourceComponent.valid()) {
      this.hasFormErrors = true;
    }
    this.sourceComponent.updateSource(true);

    if (this.hasFormErrors) {
      return;
    }

    const result = this._prepareResult();
    this.dialogRef.close({
      result
    })
  }

  onSelectedComponentChange(event: any) {}

  private _prepareResult(): AssetClassTenure {
    const acTenure = new AssetClassTenure();
    acTenure.clear();

    const controls = this.form.controls;
    acTenure.id = this.acTenure.id;
    acTenure.total_consideration = Number(controls.total_consideration.value);
    acTenure.currency = controls.currency.value;
    acTenure.tenure = controls.tenure.value;

    if (acTenure.tenure !== 'freehold') {

        acTenure.rent_type = controls.rent_type.value;

        acTenure.rent_review = controls.rent_review.value;
        acTenure.rent_review_comment = controls.rent_review_comment.value;

        acTenure.lease_duration = controls.lease_duration.value;
        acTenure.unexpired_lease_term = controls.unexpired_lease_term.value;

        acTenure.write_off_period = controls.write_off_period.value;
        acTenure.break_option = Number(controls.break_option.value);

        acTenure.rent_free_period = Number(controls.rent_free_period.value);
        acTenure.fitting_out_period = Number(controls.fitting_out_period.value);
        acTenure.cap_payment = controls.cap_payment.value;

        acTenure.inclusive_property_management = controls.inclusive_property_management.value;
        if (acTenure.inclusive_property_management === 'no') {
            acTenure.estimated_amount_property_management = controls.estimated_amount_property_management.value;
        }

        acTenure.consideration_inclusive_cost = controls.consideration_inclusive_cost.value;
        if (acTenure.consideration_inclusive_cost === 'no') {
            acTenure.estimated_amount_utilities_cost = controls.estimated_amount_utilities_cost.value;
        }

        acTenure.consideration_full_repair_cost = controls.consideration_full_repair_cost.value;
        if (acTenure.consideration_full_repair_cost === 'no') {
            acTenure.estimated_amount_repair_cost = controls.estimated_amount_repair_cost.value;
        }
    }
    acTenure.source = this.sourceSubject.value;
    acTenure.additional = this.parcelConsiderations ? this.parcelConsiderations.onSave() : []; 
    acTenure.formType = this.isComplexform ? 1 : 2;
    return acTenure;
  }

  getTitle() {
      if (this.data.mode == 'add') {
          return 'Add Consideration';
      }
      return 'Edit Consideration';
  }

  protected setInitialValueCurrency() {
      this.filteredCountriesData.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
          this.singleSelectCurrency.compareWith = (a: string, b: string) => {
              return a === b;
          }
      })
  }

  protected filterCountriesData() {
      if (!this.countriesData) {
          return;
      }
      let search = this.filterCtrlCountriesData.value;
      if (!search || search === '') {
          this.filteredCountriesData.next(this.currencyCodes);
          return;
      } else {
          search = search.toLowerCase();
      }

      this.filteredCountriesData.next(this.currencyCodes.filter(item => item.toLowerCase().indexOf(search) > -1));
  }

}

function notNegativeValidator(control: AbstractControl): {[key: string]: boolean} | null {
    if ((control.value !== null && control.value !== '') && control.value < 0) {
        return {'notNegative': true}
    }
    return null;
}

function shouldBePositive(control: AbstractControl): {[key: string]: boolean} | null {
    if ((control.value !== null && control.value !== '') && control.value <= 0) {
        return {'shouldBePositive': true}
    }
    return null;
}

function checkIfLesserThanLease(field: string) {
    return function (fg:UntypedFormGroup) {
        const fieldValue = fg.get(field).value;
        const leaseValue = fg.get('lease_duration').value;
        if (fieldValue !== null && (Number(fieldValue) > Number(leaseValue))) {
            fg.get(field).setErrors({notLesserThanLease: true})
        } else {
            removeError(fg.get(field), 'notLesserThanLease');
        }
        return null;
    }
}

function checkIfLesserThanOrEqualToLease(field: string) {
    return function (fg:UntypedFormGroup) {
        const fieldValue = fg.get(field).value;
        const leaseValue = fg.get('lease_duration').value;
        if (fieldValue !== null && (Number(fieldValue) >= Number(leaseValue))) {
            fg.get(field).setErrors({notLesserThanLeaseOrEqualToLease: true})
        } else {
            removeError(fg.get(field), 'notLesserThanLeaseOrEqualToLease');
        }
        return null;
    }
}

function removeError(control: AbstractControl, error: string) {
    const err = control.errors; // get control errors
    if (err) {
        delete err[error]; // delete your own error
        if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
        } else {
        control.setErrors(err); // controls got other errors so set them back
        }
    }
}

function checkIfLesserThanWriteOff(field: string) {
    return function (fg: UntypedFormGroup) {
        const fieldValue = fg.get(field).value;
        const leaseValue = fg.get('write_off_period').value;
        if (fieldValue !== null && leaseValue !== null && leaseValue !== '' && (Number(fieldValue) >= Number(leaseValue))) {
            fg.get(field).setErrors({notLesserThanWriteOff: true})
        } else {
            removeError(fg.get(field), 'notLesserThanWriteOff');
        }
        return null;
    }
}

function notZeroValidator(control: AbstractControl): {[key: string]: boolean} | null {
    if ((control.value !== null && control.value !== '') && Number(control.value) == 0) {
      return {isZero: true}
    }
    return null;
  }
