import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'kt-generated-report-edit-dialog',
    templateUrl: './generated-report-edit-dialog.component.html'
})
export class GeneratedReportEditDialog implements OnInit {
    fg: UntypedFormGroup;
    description = '';

    constructor(
        public dialogRef: MatDialogRef<GeneratedReportEditDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder
    ) {
        this.description = this.data.description;
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.fg = this.fb.group({
            desc: [this.description]
        })
    }

    onYesClick() {
        setTimeout(() => {
            this.dialogRef.close({
                description: this.fg.controls.desc.value,
            })
        }, 500);
    }

    onNoClick() {
        this.dialogRef.close();
    }
}