import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { TpEditDialogComponent } from '../edit/tp-edit-dialog.component';
import { TpAddConflictDialogComponent } from '../add/tp-add-conflict-dialog.component';
import { LayoutUtilsService } from '../../../../../../core/_base/crud';
import { AppState } from '../../../../../../core/reducers';
import { ConflictService } from '../../../../../../core/toe';

const CONFLICTS: { id: number, name: string }[] = [{id: 1, name: 'No'}, {id: 2, name: 'Existing'}, {id: 3, name: 'Potential'}];

@Component({
    selector: 'kt-tp-list',
    templateUrl: './tp-list.component.html',
    styleUrls: ['./tp-list.component.scss'],
    
})

export class TpListComponent implements OnInit, OnDestroy {
    @ViewChild('ngx') ngx: ElementRef;
    @ViewChild("name") nameField: ElementRef;

    @Input() selectedTPs: any[];
    @Input() tp: any[];
    @Input() conflictData: any;
    currentTPs: any[];
    dialogRef: any;
    conflicts: { id: number, name: string }[] = [];

    displayedColumns = ['id', 'asset_class_names', 'conflict_name', 'conflict_descr', 'conflict_descr_b', 'group', 'actions'];
    dataSource = new MatTableDataSource([]);

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param location
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param cdRef
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private translateService: TranslateService,
                private store: Store<AppState>,
                public conflictService: ConflictService,
                private cdRef: ChangeDetectorRef) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.currentTPs = this.selectedTPs;
        this.currentTPs = this.selectedTPs.map(tp => {
            const readonly = tp.hasOwnProperty('can_edit') && !tp.can_edit;
            return {
                ...tp,
                readonly
            };
        });
        this.dataSource = new MatTableDataSource(this.currentTPs);
        this.conflicts = CONFLICTS;
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    chooseTps() {
        this.dialogRef = this.dialog.open(TpEditDialogComponent, {
            data: {
                toeTps: this.tp,
                selectedTp: this.selectedTPs
            }
        });
        this.dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
                this.currentTPs = [...this.currentTPs, ...res.data];
                this.selectedTPs = this.currentTPs;
                this.dataSource.data = this.currentTPs;
                this.dataSource = new MatTableDataSource(this.currentTPs);
                this.cdRef.markForCheck();
                this.cdRef.detectChanges();

                this.setData();
            }
        );
    }

    tagRemove($event) {
        this.nameField.nativeElement.focus();
        this.nameField.nativeElement.blur();
        const tempTPs = [...this.currentTPs];
        const index = tempTPs.findIndex(element => element.order_num === $event.order_num);
        if (index !== -1) {
            tempTPs.splice(index, 1);
        }
        this.currentTPs = [...tempTPs];
        this.dataSource = new MatTableDataSource(this.currentTPs);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();

        this.setData();
    }

    editTP(_item, index) {
        const dialogRef = this.dialog.open(TpAddConflictDialogComponent,
            {
                data: {
                    item: _item,
                    tp: this.tp,
                    selectedTp: this.selectedTPs,
                }
            });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            if (res.asset_class_names.length === 0 ) {
                this.currentTPs.splice(index, 1);
            } else {
                this.currentTPs[index] = res;
            }
            this.dataSource = new MatTableDataSource(this.currentTPs);
            this.cdRef.markForCheck();
            this.cdRef.detectChanges();
            
            this.setData();
        });
    }

    setData() {
        const _value = this.conflictService.getConflict(this.conflictData.name);
        this.currentTPs = this.currentTPs.map( item => {
            if (item.conflict_details_id == undefined) {
                item.conflict_details_id = this.conflictData.id;
            }
            if (item.conflict_details_name == '') {
                item.conflict_details_name = this.conflictData.name;
            }
            return item;
        })
        _value.coiDetailsTP = this.currentTPs;
        this.conflictService.updateConflictResult(_value);
    }
}
