<div class="col-md-12 kt-margin-bottom-20-mobile pl-0 pr-0">

    <form class="form" [formGroup]="buildingForm" *ngIf="buildingForm">
        <div class="form-group kt-form__group row">
            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Building ID" formControlName="building_name"/>
                    <mat-error>
                        Building ID
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Completion Year" formControlName="completion_year"/>
                    <mat-error>
                        Completion Year
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput min="0" placeholder="Total Building Floors" formControlName="total_floors"/>
                    <mat-error>
                        Total Building Floors
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-20-mobile my-2">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput rows="4" placeholder=" Building and Construction Description" formControlName="building_description"></textarea>
                    <mat-icon
                            matSuffix
                            class="cursor-pointer"
                            (click)="$event.stopPropagation();"
                            container="body"
                            [ngbPopover]="'TOOLTIP.BUILDING.CONSTRUCTION_DESC.DESCRIPTION' | translate"
                            [popoverTitle]="'TOOLTIP.BUILDING.CONSTRUCTION_DESC.TITLE' | translate">help
                    </mat-icon>
                    <mat-error> Building and Construction Description
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Building(s) Footprint" formControlName="footprint"/>
                    <mat-error>
                        Building(s) Footprint
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="% of land occupied by the building(s)" [formControl]="buildingForm.controls['land_occupation_percent']" mask="percent" suffix="%"/>
                    <mat-error>
                        % of land occupied by the building(s)
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Current use(s) of the building" formControlName="building_usage"/>
                    <mat-error>
                        Current use(s) of the building
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Environmental considerations" formControlName="env_consideration"/>
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [popoverTitle]="'TOOLTIP.INSPECTION.ENV_CONSIDERATION.TITLE' | translate"
                        [ngbPopover]="'TOOLTIP.INSPECTION.ENV_CONSIDERATION.DESCRIPTION' | translate">help
                    </mat-icon>
                    <mat-error>
                        Environmental considerations
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Building(s) current & previous occupation" formControlName="occupation"/>
                    <mat-error>
                        Building(s) current & previous occupation
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Building(s) Use Status" formControlName="building_use_status_id">
                        <mat-option *ngIf="parentFormType === 1" [value]="-1">Unknown</mat-option>
                        <mat-option *ngFor="let buildingUseStatus of buildingUseStatuses" [value]="buildingUseStatus.id">{{buildingUseStatus.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Building(s) Use Status
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Area of any ancillary hard surface" formControlName="area_of_hard_surface"/>
                    <mat-error>
                        Area of any ancillary hard surface
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>