import { VApartmentGround } from "src/app/core/valuation-process/_models/valuation-apartment.model";
import { VHouseGround } from "src/app/core/valuation-process/_models/valuation-house.model";
import { VOfficeGround } from "src/app/core/valuation-process/_models/valuation-office.model";
import { VPropertySubTypes } from "src/app/core/valuation-process/_models/valuation-process-target-property.model";
import { VRetailBuildingGround } from "src/app/core/valuation-process/_models/valuation-retail-building.model";
import { VRetailShopGround } from "src/app/core/valuation-process/_models/valuation-retail-shop.model";
import { VWarehouseGround } from "src/app/core/valuation-process/_models/valuation-warehouse.model";
import { Row, Cells, simpleCellCreator, mergeRRows, mergeRows } from "./comparable-info-dialog.types";

const apartmentItems: {header: string, field: keyof VApartmentGround}[] = [
    {header: 'Indoor Garage', field: 'indoorGarage'},
    {header: 'Indoor Garage comment', field: 'indoorGarageComment'},
    {header: 'Outdoor parking', field: 'outdoorGarage'},
    {header: 'Outdoor parking comment', field: 'outdoorGarageComment'},
    {header: 'External areas', field: 'externalAreas'},
    {header: 'External areas comment', field: 'externalAreasComment'},
]
const houseItems: {header: string, field: keyof VHouseGround}[] = [
    {header: 'Indoor Garage', field: 'indoorGarage'},
    {header: 'Indoor Garage comment', field: 'indoorGarageComment'},
    {header: 'Outdoor parking', field: 'outdoorGarage'},
    {header: 'Outdoor parking comment', field: 'outdoorGarageComment'},
    {header: 'Garden', field: 'garden'},
    {header: 'Garden comment', field: 'gardentComment'},
]
const officeItems: {header: string, field: keyof VOfficeGround}[] = [
    {header: 'Indoor Garage', field: 'indoorGarage'},
    {header: 'Indoor Garage comment', field: 'indoorGarageComment'},
    {header: 'Outdoor parking', field: 'outdoorGarage'},
    {header: 'Outdoor parking comment', field: 'outdoorGarageComment'},
    {header: 'External areas', field: 'externalAreas'},
    {header: 'External areas comment', field: 'externalAreasComment'},
]
const retailshopItems: {header: string, field: keyof VRetailShopGround}[] = [
    {header: 'Indoor Garage', field: 'indoorGarage'},
    {header: 'Indoor Garage comment', field: 'indoorGarageComment'},
    {header: 'Outdoor parking', field: 'outdoorGarage'},
    {header: 'Outdoor parking comment', field: 'outdoorGarageComment'},
    {header: 'External areas', field: 'externalAreas'},
    {header: 'External areas comment', field: 'externalAreasComment'},
]
const retailbuildingItems: {header: string, field: keyof VRetailBuildingGround}[] = [
    {header: 'Indoor Garage', field: 'indoorGarage'},
    {header: 'Indoor Garage comment', field: 'indoorGarageComment'},
    {header: 'Outdoor parking', field: 'outdoorGarage'},
    {header: 'Outdoor parking comment', field: 'outdoorGarageComment'},
    {header: 'Outdoor space', field:'outdoorSpace'},
    {header: 'Outdoor space comment', field:'outdoorSpaceComment'},
]
const warehouseItems: {header: string, field: keyof VWarehouseGround}[] = [
    {header: 'Indoor Garage', field: 'indoorGarage'},
    {header: 'Indoor Garage comment', field: 'indoorGarageComment'},
    {header: 'Outdoor parking', field: 'outdoorGarage'},
    {header: 'Outdoor parking comment', field: 'outdoorGarageComment'},
    {header: 'Outdoor space', field:'outdoorSpace'},
    {header: 'Outdoor space comment', field:'outdoorSpaceComment'},
]

const Items = {
    'Apartment': apartmentItems,
    'Office': officeItems,
    'House': houseItems,
    'Retail Shop': retailshopItems,
    'Retail Building': retailbuildingItems,
    'Warehouse': warehouseItems
}

export function groundRows(assetClasses: {
    refNum: string,
    propertySubType: VPropertySubTypes,
    propertySubTypeName: string,
    ground: VApartmentGround | VHouseGround | VOfficeGround | VRetailBuildingGround | VRetailShopGround | VWarehouseGround | null
}[]): Row[] {
    const rrows = assetClasses.map(assetClasses => {
        const items = Items[assetClasses.propertySubType]
        if (!items) {
            return []
        }
        return items.map(item => {
            const cells: Cells = {}
            cells[assetClasses.refNum] = simpleCellCreator(assetClasses.ground[item.field])
            return {
                header: item.header,
                cells,
                propertySubType: assetClasses.propertySubTypeName
            }
        })
    })
    const mergedRows = mergeRRows(rrows)
    return mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))
}