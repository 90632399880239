<div class="row kt-margin-bottom-20-mobile section-margin-bottom-60" style="text-align: right; font-weight: 600; font-size: large" *ngIf="stats$ | async as stats">
    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
        <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
            <span style="vertical-align: middle; color: #E8E8E8;">
                {{ stats.total }}
            </span>
        </div>
        <div class="float-right align-content-end w-100 text-uppercase text-white">
            <p class="m-0">
                <span style="color: #cccccc;">TOTAL COMPARABLES</span>
            </p>
        </div>
    </div>
    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
        <span style="vertical-align: middle; color: #E8E8E8;">
            {{ stats.comparables90Days }}
        </span>
        <div class="float-right align-content-end w-100 text-uppercase text-white">
            <p class="m-0"><span style="color: #E8E8E8;">COMPARABLES (< 90 DAYS)</span></p>
        </div>
    </div>
    <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
        <span style="vertical-align: middle; color: #E8E8E8;">
            {{ stats.comparables30Days }}
        </span>
        <div class="float-right align-content-end w-100 text-uppercase text-white">
            <p class="m-0"><span style="color: #E8E8E8;">COMPARABLES (< 30 DAYS)</span></p>
        </div>
    </div>
</div>