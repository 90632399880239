// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {ConflictService} from '../_services/conflict.service';
// State
import {AppState} from '../../reducers';
// Actions
import {AllConflictRequested, AllConflictLoaded, ConflictActionTypes} from '../_actions/conflict.actions';


@Injectable()
export class ConflictEffects {
    // showPageLoadingDispatcher = new ConflictsPageToggleLoading({isLoading: true});
    // showActionLoadingDispatcher = new ConflictActionToggleLoading({isLoading: true});
    // hideActionLoadingDispatcher = new ConflictActionToggleLoading({isLoading: false});

    @Effect()
    allConflictRequested$ = this.actions$.pipe(
        ofType<AllConflictRequested>(ConflictActionTypes.AllConflictRequested),
        mergeMap(() => {
            return this.conflictService.getAllConflict();
        }),
        map(response => {
            return new AllConflictLoaded({
                conflicts: response.data
            });
        })
    );

    constructor(private actions$: Actions, public conflictService: ConflictService, private store: Store<AppState>) {
    }
}
