// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
// Models
import {ValuationModel} from '../_models/valuation.model';
import {ValuationKeyplaceModel} from '../_models/valuation-keyplace.model';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class ValuationsService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/valuations`;
    constructor(
        private http: HttpClient, 
        private authService: MadAuthService, 
        private httpUtils: HttpUtilsService,
        private subDomainService: SubdomainService) {
    }

    findValuations(queryParams: QueryParamsModel, valuationId: number): Observable<QueryResultsModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = `${this.API_URL}/` + valuationId;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getApartments(queryParams: QueryParamsModel): Observable<any> {


        let _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
        };

        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_URL + '/data';
        return this.http.post<any>(url, _body, {headers: httpHeaders});
    }

    createValuation(_valuation: ValuationModel,
                    _keyPlaces: ValuationKeyplaceModel[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            valuation: _valuation,
            keyPlaces: _keyPlaces,
        };
        return this.http.post<any>(this.API_URL, body, {headers: httpHeaders});
    }

    updateValuation(_valuation: ValuationModel,
                    _keyPlaces: ValuationKeyplaceModel[],): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            valuation: _valuation,
            keyPlaces: _keyPlaces,
        };
        const url = this.API_URL;
        return this.http.put(url + `/${_valuation.id}`, body, {headers: httpHeader});
    }


    deletedValuation(_valuationId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_URL;
        return this.http.get(url + `/${_valuationId}`, {headers: httpHeaders});
    }

    // Trash
    getTrashedValuations(): Observable<any> {
        const url = this.API_URL;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/trash`, {headers: httpHeaders});
    }

    // admin Trash
    getAdminTrashedValuations(valuationId: number): Observable<any> {
        const url = this.API_URL + valuationId;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/admin/trash`, {headers: httpHeaders});
    }

    // Trash
    // delete from trash
    deleteFromTrash(valuationId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${valuationId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(valuationId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${valuationId}`, {headers: httpHeaders});
    }

    deleteValuation(valuationId: number): Observable<any> {
        const url = this.API_URL + `/${valuationId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(url, {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }

    getValuation(valuationId: number): Observable<ValuationModel> {
        const url = this.API_URL;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<ValuationModel>(url + `/${valuationId}`, {headers: httpHeaders});
    }

    getBuildingInfos(): Observable<any> {
        const url = this.API_URL + `/building-informations`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getTenuresOfAc(id: number): Observable<any> {
        const url = this.API_URL + `/${id}/tenures`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getSizesOfAc(id: number): Observable<any> {
        const url = this.API_URL + `/${id}/sizes`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }


    getApartmentsByLocation(latLng1: google.maps.LatLng, latLng2: google.maps.LatLng): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            max_lng: latLng2.lng(),
            max_lat: latLng2.lat(),
            min_lng: latLng1.lng(),
            min_lat: latLng1.lat(),
        };
        const url = this.API_URL;
        return this.http.post(url + `/map`, body, {headers: httpHeader});
    }
}
