import {GardenModel} from '../_models/garden.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {GardensState} from '../_reducers/garden.reducer';
import * as fromGarden from '../_reducers/garden.reducer';
import {each} from 'lodash';

export const selectGardensState = createFeatureSelector<GardensState>('gardens');

export const selectGardenById = (id: number) => createSelector(
    selectGardensState,
    state => state.entities[id]
);

export const selectAllGardens = createSelector(
    selectGardensState,
    fromGarden.selectAll
);

export const selectAllGardensIds = createSelector(
    selectGardensState,
    fromGarden.selectIds
);

export const allGardensLoaded = createSelector(
    selectGardensState,
    state => state.isAllGardensLoaded
);


export const selectGardensPageLoading = createSelector(
    selectGardensState,
    state => state.listLoading
);

export const selectGardensActionLoading = createSelector(
    selectGardensState,
    state => state.actionLoading
);

export const selectLastCreatedGardenId = createSelector(
    selectGardensState,
    state => state.lastCreatedGardenId
);

export const selectGardensShowInitWaitingMessage = createSelector(
    selectGardensState,
    state => state.showInitWaitingMessage
);


export const selectTrashedGardenCount = createSelector(
    selectGardensState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedGardenCount = createSelector(
    selectGardensState,
    (state) => state.totalAdminTrashed
);


export const selectGardenQueryResult = createSelector(
    selectGardensState,
    state => {
        const items: GardenModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GardenModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
