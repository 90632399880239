<kt-portlet>
    <kt-portlet-header [class]="'kt-portlet__head--lg'"
                       [tooltipTitle]="'TOOLTIP.TOE.DASHBOARD.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.TOE.DASHBOARD.DESCRIPTION' | translate"
                       [viewLoading$]="loading$">

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet__head-title">
                <span>Dashboard for the ToE "{{toe ? toe.name: ''}}"</span>
            </h3>
        </ng-container>

        <ng-container ktPortletTools>
            <a routerLink="../../"
               class="btn mr-2" mat-raised-button matTooltip="Back to the assignment dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Back</span>
            </a>

            <kt-context-menu-mad
                    [(menuItems)]="showingItems"
                    [(menuSubject)]="menuSubject"
                    [width]="200">

            </kt-context-menu-mad>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>

        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center">
                        <i class="fa fa-home fa-2x" style="vertical-align: middle; color: #E8E8E8;"></i>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0" >
                            {{ validTPs$|async }} <span style="color: #cccccc;">TARGET {{ (validTPs$ | async) == 1 ? 'PROPERTY':'PROPERTIES'}}</span>
                        </p>
                        <p class="m-0">
                            <span style="color: #cccccc;">FOR:</span> {{ (toe.client_name.length>50)? (toe.client_name | slice:0:50)+'..':(toe.client_name) }}
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center">
                        <i class="fa fa-eye fa-2x" style="vertical-align: middle; ; color: #E8E8E8;"></i>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0" *ngIf="insCnt > 0">{{ insCnt }}<span style="color: #E8E8E8;"> {{insCnt != 1 ? ' INSPECTIONS':  ' INSPECTION'}}</span></p>
                        <p class="m-0" *ngIf="insRevCnt > 0">
                            {{ insRevCnt }} <span style="color: #E8E8E8;">{{insRevCnt != 1 ? ' DESKTOP REVIEWS':  ' DESKTOP REVIEW'}}</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center">
                        <i class="fa fa-dollar-sign fa-2x" style="vertical-align: middle; ; color: #E8E8E8;"></i>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"> {{valCnt}} <span style="color: #E8E8E8;">{{valCnt > 1 ?  ' VALUATIONS' : ' VALUATION'}}</span></p>
                        <p class="m-0 font-size-sm"><span style="color: #E8E8E8;">Purpose:</span> {{toe.purpose_of_valuation_name}}</p>
                        <ng-container *ngIf="baseOfValuesInfo$|async as baseOfValuesInfo">
                            <p class="m-0 font-size-sm"><span style="color: #E8E8E8;">{{baseOfValuesInfo.label}}:</span> {{baseOfValuesInfo.text}}</p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #16c4bb !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center">
                        <i class="fa fa-tasks fa-2x" style="vertical-align: middle; color: #E8E8E8;"></i>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #E8E8E8;"> 
                                {{toe.status == -1 
                                    ? 'TOE Cancelled' 
                                    : completionPercent == 100 
                                        ? ('COMPLETED')
                                        : ('CURRENT PROGRESS: ' + completionPercent + '%' )}}
                            </span> 
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #3797ff !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center">
                        <i class="fa fa-calendar-alt fa-2x" style="vertical-align: middle; color: #E8E8E8;"></i>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <ng-container *ngIf="toe.status == -1">
                            <p class="m-0">
                                <span style="color: #E8E8E8">
                                    TOE Cancelled
                                </span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="toe.status != -1">
                            <p class="m-0">
                                <span style="color: #E8E8E8;">
                                    DEADLINE:
                                </span> 
                                {{toe.final_delivery_date  | date: 'dd MMM yyyy'}}
                            </p>
                            <p class="m-0">
                                <span style="color: #E8E8E8;">
                                    {{completionPercent == 100 
                                        ? 'REMAINING: Completed' 
                                        : remainingDays == 0 
                                            ? 'TODAY'
                                            : (remainingDays < 0 
                                                ? 'REMAINING:'
                                                : 'OVERDUE:')}}
                                </span>
                                {{ completionPercent == 100
                                    ? ''
                                    : remainingDays == 0 
                                        ? '' 
                                        : (remainingDays > 0 
                                            ? remainingDays 
                                            : -remainingDays) +' DAY(S)'}}
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center"
                     [style.background-color]="toeStatus != 0 ? '#884ffc': '#383342ba'"
                     [style.color]="toeStatus != 0 ? 'white !important': 'grey !important'">

                    <div class="float-left h-100 d-flex justify-content-center align-items-center">
                        <i class="fa fa-users fa-2x" style="vertical-align: middle; color: #E8E8E8;"></i>
                    </div>
                    <div class="float-right d-flex justify-content-end align-items-center flex-grow-1">
                        <span class="pl-1 pr-3" *ngFor="let item of toeUsers"
                              [ngbPopover]="popContent"
                              [popoverTitle]="item.full_name">
                             <ng-template #popContent>
                                <span class="font-weight-bold">Role:</span> {{getRoleInfo(item.id)}}<br>
                                <span class="font-weight-bold">Qualification:</span> {{item.qualification_name}}<br>
                                <span class="font-weight-bold">Email:</span> {{item.email }}<br>
                                <span class="font-weight-bold">Agency:</span> {{ assignment.agency_name}}<br>

                            </ng-template>
                            <img alt="Pic"
                                 style="align-self: center; max-height: 34px; border-radius: 17px;"
                                 [src]="item.picture && item.picture.length > 0 ? baseMediaUrl + item.picture: './assets/media/users/default.jpg'"
                            >
                        </span>

                    </div>

                </div>
            </div>
        </div>

        <div class="card card-custom section-margin-bottom-60">
            <div class="card-header summary-header">
                <div class="card-title">
                    <span>Summary</span>
                </div>
            </div>
            <div class="card-body summary-body">
                <div class="col-md-12">
                    <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="index">
                            <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.info.id}}
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="id">
                            <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
                            <mat-cell *matCellDef="let item" (click)="scroll(item.info.id)">
                                <span class="cursor-pointer">
                                    {{item.info.name}}
                                </span></mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="vals">
                            <mat-header-cell *matHeaderCellDef>Valuations</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <b><span
                                        class="label label-inline label-rounded label-outline-{{item.completed_val == item.info.valuations.length ? 'success':'danger'}} label-outline-2x label-lg "
                                        style="width: 8rem;">{{item.completed_val}} / {{item.info.valuations.length}}
                                </span></b>
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="ac">
                            <mat-header-cell *matHeaderCellDef>Property Sub-Type</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.info.top_property_type_id == propertyTypes.Custom ? item.info.custom_asset_class_name : item.info.type_name}}
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="property_det">
                            <mat-header-cell *matHeaderCellDef>Investigation status</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                 <span *ngIf="item.info.state != 1"
                                         class="label label-inline label-rounded label-outline-{{colors[detStatus(item.info)] }} label-outline-2x label-lg"
                                         style="width: 90px;">
                                        {{ statusTitle[detStatus(item.info)] }}
                                </span>
                                <span *ngIf="item.info.state == 1"
                                    class="label label-inline label-rounded label-outline-danger label-outline-2x label-lg"
                                    style="width: 90px;">
                                    Aborted
                                </span>
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef>Investigation Type</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{decisions[ item.info.type_of_inspection > 0 ? item.info.type_of_inspection-1 : 0] }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="multimedia">
                            <mat-header-cell *matHeaderCellDef>Multimedia</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <ng-container *ngIf="item.report_task.completed == 'available'">
                                    <span *ngIf="item.info.state != 1" class="cursor-pointer" (click)="navToMultimedia(item.info.multimedia)">
                                        {{item.info.multimedia ? item.info.multimedia.url : 'N/A'}}
                                    </span>
                                    <span *ngIf="item.info.state == 1" style="color: gray !important;">
                                        {{item.info.multimedia ? item.info.multimedia.url : 'N/A'}}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="item.report_task.completed == 'not available'">
                                    Pending reporting
                                    <mat-icon
                                            inline
                                            style="width: 24px"
                                            class="pl-2 cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            placement="top"
                                            popoverClass="testUI"
                                            [ngbPopover]="item.report_task.tooltip">
                                            help
                                    </mat-icon>
                                </ng-container>
                                <ng-container *ngIf="item.report_task.completed == 'not applicable'">
                                    Not Applicable
                                    <mat-icon
                                            inline
                                            style="width: 24px"
                                            class="pl-2 cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            placement="top"
                                            popoverClass="testUI"
                                            [ngbPopover]="item.report_task.tooltip">
                                            help
                                    </mat-icon>
                                </ng-container>
                            </mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>Info</mat-header-cell>
                            <mat-cell *matCellDef="let tp; let i = index;">
                                <ng-template #popContent>
                                    <span class="font-weight-bold">Property type:</span> {{tp.info.top_property_type_name}}<br>
                                    <span class="font-weight-bold">Property Sub-Type:</span> {{tp.info.top_property_type_id == propertyTypes.Custom ? tp.info.custom_asset_class_name : tp.info.type_name}}<br>
                                    <span class="font-weight-bold">Address:</span> {{tp.info.address}}<br>
                                    <span class="font-weight-bold">Surface area:</span> 
                                    <ng-container *ngIf="tp.info.surface != 0">
                                        {{tp.info.surface}} {{ toe.unit_of_measurement_id == 1 ? 'sqft': 'sqm' }}
                                    </ng-container>
                                    <ng-container *ngIf="tp.info.surface == 0">
                                        N/A
                                    </ng-container>
                                    <br>
                                    <span class="font-weight-bold">Measurement standard:</span> {{tp.info.standard_measurement_name}}<br>
        
                                </ng-template>
                                <ng-template #popTitle>
                                    {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                                </ng-template>
                                <button mat-icon-button
                                        color="primary"
                                        placement="left"
                                        container="body"
                                        [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                                        [ngbPopover]="popContent"
                                        [popoverTitle]="popTitle">
                                    <mat-icon>info</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
        
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            class="cert"
                            [ngClass]="{
                                'completed': row.completed,
                                'aborted': row.info.state == 1
                            }"
                        ></mat-row>
                    </mat-table>
                </div>
            </div>
        </div>

        <div class="section-margin-bottom-60 mx-0">
            <div class="col-lg-12 p-0">
                <h5 class="text-mad text-uppercase">
                    ADMIN TASKS
                    <span style="padding-left: 1rem; color: orangered; text-transform: none; font-size: smaller;"> 
                    {{('(' + adminTaskPercentage + '% Completed)') }} </span>
                </h5>
    
                <hr class="active">
            </div>
            <mat-tab-group
                    (selectedTabChange)="onTabChanged($event)"
                    [(selectedIndex)]="selectedTab">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-list"></i>
                        ToE & Amendments
                        <span class="badge badge-primary ml-1 round float-none" style="color:white" *ngIf="toe_documents_incomplete_task_cnt > 0">{{toe_documents_incomplete_task_cnt}}</span>
                        <span class="badge badge-success ml-1 round" *ngIf="toe_documents_incomplete_task_cnt == 0">
                            <i class="mat-tab-label-icon fa fa-check fa-xs mr-0" style="color:white"></i>
                        </span>
                    </ng-template>
                    <div>
                        <kt-toe-documents 
                            [toe]="toe"
                            [toeID]="toe.id"
                            [targetProperties]="entitySubject|async"
                            [leadValuerID]="workers.length > 0 && workers[0].lead_valuer ? workers[0].worker_id : 0"
                            [workers]="toeWorkers"
                            [pm]="pm"
                            (toeStatusEvent)="changeToeStatus($event)"
                            (toeDocumentsIncompleteTaskCnt)="changeToeDocumentsIncompleteTaskCnt($event)"
                            (signedUploadDone)="changeSignedUploadDone($event)"
                        ></kt-toe-documents>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-money-bill"></i>
                        Invoices
                    </ng-template>
                    <div>
                        <kt-toe-invoices #toe_invoices
                            [toe]="toe"
                            [toeID]="toe.id"
                            [targetProperties]="entitySubject|async"
                            [leadValuerID]="workers.length > 0 && workers[0].lead_valuer ? workers[0].worker_id : 0"
                            [workers]="toeWorkers"
                            [pm]="pm"
                            (invoiceIncompleteTaskCnt)="changeToeInvoicesIncompleteTaskCnt($event)"
                        ></kt-toe-invoices>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-handshake"></i>
                        Payment
                        <span class="badge badge-warning ml-1 round float-none" style="color:white" *ngIf="toe_settlement_incomplete_task_cnt > 0">{{toe_settlement_incomplete_task_cnt}}</span>
                        <span class="badge badge-success ml-1 round" *ngIf="toe_settlement_incomplete_task_cnt == 0">
                            <i class="mat-tab-label-icon fa fa-check fa-xs mr-0" style="color:white"></i>
                        </span>
                    </ng-template>
                    <div>
                        <kt-toe-settlement #toe_settlement
                            [toe]="toe"
                            [toeID]="toe.id"
                            [targetProperties]="entitySubject|async"
                            [leadValuerID]="workers.length > 0 && workers[0].lead_valuer ? workers[0].worker_id : 0"
                            [workers]="toeWorkers"
                            [pm]="pm"
                            (settlementIncompleteTaskCnt)="changeToeSettlementIncompleteTaskCnt($event)"
                        ></kt-toe-settlement>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="mat-tab-label-icon fa fa-shield-alt"></i>
                        Audit Trail
                        <ng-container *ngIf="auditTrailTasksNum$ | async as s">
                            <span class="badge badge-warning ml-1 round float-none" style="color:white" *ngIf="s.num > 0">{{s.num}}</span>
                            <span class="badge badge-success ml-1 round" *ngIf="s.num == 0">
                                <i class="mat-tab-label-icon fa fa-check fa-xs mr-0" style="color:white"></i>
                            </span>
                        </ng-container>
                    </ng-template>
                    <div>
                        <kt-toe-audit 
                            [toeId]="toe.id"
                            [targetProperties]="entitySubject|async"
                            [workers]="toeWorkers"
                            (taskCntChange)="changeAuditTaskCnt($event)"
                        ></kt-toe-audit>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="p-0 m-0">
            <!--    *ngIf="workers.length > 0"  -->
            <!--    START TP rows  -->
            <div class="col-lg-12 kt-margin-bottom-20-mobile p-3 section-margin-bottom-60"
                 *ngFor="let item of entitySubject| async;let i = index;"
                 id="anchor{{item.id}}">
                <kt-tp-row
                        #tpComponents
                        [tpAssetClass]="item"
                        [toeStatus]="toeStatus"
                        [toeFinalDate]="toe.final_delivery_date"
                        [projectManager]="showPM"
                        [id]="i+1"
                        [ngClass]="{gray: i%2}"
                        [selectedRdm]="selectedRdm"
                        [workers]="toeWorkers"
                        [pm]="pm"
                        [toeId]="toe.id"
                        [toeRestrictionsOnUse]="toe.restrictions_on_use"
                        [leadValuerId]="workers.length>0 && workers[0].lead_valuer  ? workers[0].worker_id : 0"
                        [assignment]="assignment">
                </kt-tp-row>
                <!-- <kt-asset-class-section
                    [assetClassId]="item.id"
                    [tpAssetClass]="item"
                    [index]="i + 1"
                    [currentUserIsProjectManager]="showPM"
                    [toeStatus]="toeStatus"
                    [pm]="pm"
                    [leadValuerId]="workers.length > 0 && workers[0].lead_valuer ? workers[0].worker_id : 0">
                </kt-asset-class-section> -->
            </div>
            <!--    END TP rows       -->
        </div>
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
