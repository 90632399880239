<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="col-lg-12" *ngFor="let assetClassType of assetClassTypes">
                <span>{{ assetClassType.name }}</span>
                <div class="row align-items-center container" *ngIf="isBuildingFacility">
                    <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" *ngFor="let item of assetClassType.building_facilities">
                        <mat-checkbox
                                [checked]="setSelectedFacilities(item)"
                                (change)="updateCheckedOptions(item, $event)">
                            {{ item.name }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row align-items-center container" *ngIf="!isBuildingFacility && assetClassTypeId == 1">
                    <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" *ngFor="let item of assetClassType.facilities">
                        <mat-checkbox
                                [checked]="setSelectedFacilities(item)"
                                (change)="updateCheckedOptions(item, $event)">
                            {{ item.name }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row align-items-center container" *ngIf="!isBuildingFacility && assetClassTypeId == 3">
                    <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" *ngFor="let item of assetClassType.office_facilities">
                        <mat-checkbox
                                [checked]="setSelectedFacilities(item)"
                                (change)="updateCheckedOptions(item, $event)">
                            {{ item.name }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row align-items-center container" *ngIf="!isBuildingFacility && assetClassTypeId == 12">
                    <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" *ngFor="let item of assetClassType.office_facilities_exclusive">
                        <mat-checkbox
                                [checked]="setSelectedFacilities(item)"
                                (change)="updateCheckedOptions(item, $event)">
                            {{ item.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer p-0 b-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" (click)="addFacility()">Add Facility</button>&nbsp;
                <button mat-button mat-raised-button color="primary" (click)="close()">Save</button>&nbsp;
            </div>
        </div>
    </div>
</div>