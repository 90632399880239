import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel } from '../../asset_class';
import {BaseModel} from '../../_base/crud';
import { ExternalAspectData, newExternalAspectData } from './asset-class-external-aspect.model';
import { AcGroundData, newAcGroundData } from './asset-class-ground.model';
import { InternalAspectData, newInternalAspectData } from './asset-class-internal-aspect.model';
import { AssetClassSizeModel } from './asset-class-size.model';
import { LocationData } from './location.model';
import { ComparablesRadioButtonValues } from './RadioButtonValues';

export class BuildingModel extends BaseModel {

    id: number;
    status: number;
    form_type: number;
    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    deletedBy: string;
    userDeletedBy: string;

    createdBy: string;
    lastUpdatedBy: string;

    // New fields
    locationData: LocationData;

    parcelIdentification: ParcelIdentificationModel; 

    building_name: string;
    completion_year: string;
    is_part_of_property: number;
    info_about_property: string;
    building_type: number;
    building_type_name: string;
    building_type_comment: string;
    building_grade_id: number;
    building_grade_name: string;
    energy_efficiency_grade_id: number;
    anchor_tenant: string;
    developer: string;
    foundation_type_id: number;
    building_description: string;

    serviceAndInfraData: {
        id: number,
        road: boolean;
        status_n_quality: string,
        access_points: string,
        length_of_road: string,
        ownership: string,

        central_heating: boolean,
        heatings: number[],
        other_heating: string,

        all_of_it: boolean,
        services: number[],
        other_service: string
    }

    externalAspectData: ExternalAspectData;
    internalAspectData: InternalAspectData;
    
    grounds: AcGroundData;
    
    pictures: any[];
    picture: string;
    sizes: AssetClassSizeModel[];
    documents: any[];
    
    linked_comparable: number;


    clear() {

        this.id = undefined;
        this.form_type = 2;

        this.created_at = new Date();
        this.updated_at = new Date();

        this.user_deleted = null;
        this.deleted_at = null;


        this.deletedBy = '';
        this.userDeletedBy = '';

        // New fields
        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        }

        this.parcelIdentification = new ParcelIdentificationModel();

        this.building_name = '';
        this.completion_year = '';
        this.is_part_of_property = 0;
        this.info_about_property = '';
        this.building_type = null;
        this.building_type_name = null;
        this.building_type_comment = null;
        this.building_grade_id = null;
        this.building_grade_name = null;
        this.energy_efficiency_grade_id = null;
        this.anchor_tenant = '';
        this.developer = '';
        this.foundation_type_id = null;
        this.building_description = '';

        this.serviceAndInfraData = {
            id: undefined,
            road: false,
            status_n_quality: null,
            access_points: null,
            length_of_road: null,
            ownership: null,

            central_heating: false,
            heatings: [],
            other_heating: null,

            all_of_it: false,
            services: [],
            other_service: null
        }

        this.externalAspectData = newExternalAspectData();
        this.internalAspectData = newInternalAspectData();

        this.grounds = newAcGroundData()
        
        this.pictures = [];
        this.picture = '';
        this.sizes = [];

        this.documents = [];

        this.linked_comparable = 0;
    }

    copy(data: any) {
        this.id = data.id;
        this.form_type = data.form_type;

        this.created_at = data.created_at;
        this.updated_at = data.updated_at;

        this.user_deleted = data.user_deleted;
        this.deleted_at = data.deleted_at;

        this.deletedBy = data.deletedBy;
        this.userDeletedBy = data.userDeletedBy;

        // New fields
        this.locationData = data.locationData;

        this.building_name = data.building_name;
        this.completion_year = data.completion_year;
        this.is_part_of_property = data.is_part_of_property;
        this.info_about_property = data.info_about_property;
        this.building_type = data.building_type;
        this.building_type_comment = data.building_type_comment;
        this.building_grade_id = data.building_grade_id;
        this.energy_efficiency_grade_id = data.energy_efficiency_grade_id;
        this.anchor_tenant = data.anchor_tenant;
        this.developer = data.developer;
        this.foundation_type_id = data.foundation_type_id;
        this.building_description = data.building_description;

        this.parcelIdentification = data.parcelIdentification;

        this.serviceAndInfraData = data.serviceAndInfraData;
        this.externalAspectData = data.externalAspectData;
        this.internalAspectData = data.internalAspectData;
        this.grounds = data.grounds;
        this.documents = data.documents;
        this.picture = data.picture;
        this.pictures = data.pictures;
        this.sizes = data.sizes;
    }
}

export class ParcelIdentificationModel {
    add_info_on_land: boolean;
    land_parcel_name: string;
    coordinate_reference_system_id: number;
    points: any[];
    landArea: AssetClassLandAreaModel;
    landAreaComponents: AssetClassLandAreaComponentModel[]

    constructor() {
        this.add_info_on_land = false;
        this.land_parcel_name = null;
        this.coordinate_reference_system_id = null;
        this.points = [];
        this.landAreaComponents = []
        this.landArea = new AssetClassLandAreaModel();
        this.landArea.clear();
    }
}