import {SourceInformation} from '../_models/source-information.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SourceInformationState} from '../_reducers/source-information.reducers';
import * as fromSourceInformation from '../_reducers/source-information.reducers';
import {each} from 'lodash';

export const selectSourceInformationState = createFeatureSelector<SourceInformationState>('sourceInformation');

export const selectSourceInformationById = (sourceInformationId: number) => createSelector(
    selectSourceInformationState,
    sourceInformationState => sourceInformationState.entities[sourceInformationId]
);

export const selectAllSourceInformation = createSelector(
    selectSourceInformationState,
    fromSourceInformation.selectAll
);

export const selectAllSourceInformationIds = createSelector(
    selectSourceInformationState,
    fromSourceInformation.selectIds
);

export const allSourceInformationLoaded = createSelector(
    selectSourceInformationState,
    sourceInformationState => sourceInformationState.isAllSourceInformationLoaded
);


export const selectSourceInformationPageLoading = createSelector(
    selectSourceInformationState,
    sourceInformationState => sourceInformationState.listLoading
);

export const selectSourceInformationActionLoading = createSelector(
    selectSourceInformationState,
    sourceInformationState => sourceInformationState.actionLoading
);

export const selectLastCreatedSourceInformationId = createSelector(
    selectSourceInformationState,
    sourceInformationState => sourceInformationState.lastCreatedSourceInformationId
);

export const selectSourceInformationShowInitWaitingMessage = createSelector(
    selectSourceInformationState,
    sourceInformationState => sourceInformationState.showInitWaitingMessage
);

export const selectTrashedSourceInformationCount = createSelector(
    selectSourceInformationState,
    (sourceInformationState) => sourceInformationState.totalTrashed
);

export const selectAdminTrashedSourceInformationCount = createSelector(
    selectSourceInformationState,
    (sourceInformationState) => sourceInformationState.totalAdminTrashed
);


export const selectSourceInformationQueryResult = createSelector(
    selectSourceInformationState,
    sourceInformationState => {
        const items: SourceInformation[] = [];
        each(sourceInformationState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SourceInformation[] = httpExtension.sortArray(items, sourceInformationState.lastQuery.sortField, sourceInformationState.lastQuery.sortOrder);

        return new QueryResultsModel(result, sourceInformationState.totalCount, '', sourceInformationState.totalTrashed);
    }
);
