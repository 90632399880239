import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

type ActionTypes = 'increase' | 'decrease';

@Component({
  selector: 'kt-percent-input-v2',
  templateUrl: './percent-input-v2.component.html',
  styleUrls: ['./percent-input-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PercentInputV2Component implements OnInit {
  @Input() value: number
  @Input() adjustmentName: string
  @Input() refNum: string
  @Output() onPercentChange: EventEmitter<number> = new EventEmitter()

  form: UntypedFormGroup
  currentVal = 0
  val$: BehaviorSubject<number> = new BehaviorSubject(this.currentVal)

  decreaseButtonTooltip: string;
  increaseButtonTooltip: string;

  constructor(
    formBuilder: UntypedFormBuilder
  ) { 
    this.form = formBuilder.group({
      percent: [this.currentVal + '%']
    })
  }

  ngOnInit(): void {
    this.decreaseButtonTooltip = `${this.adjustmentName} of Target Property less desirable than Comparable ${this.refNum}`;
    this.increaseButtonTooltip = `${this.adjustmentName} of Target Property more desirable than Comparable ${this.refNum}`;

    this.val$.next(this.value)
    this.form.get('percent').setValue(this.value + '%')
  }

  changeVal(action: ActionTypes) {
    const currentValue: number = this._getPercentValueInNum();
    switch (action) {
      case 'decrease': {
        const newVal = currentValue - 1;
        if (newVal < -100) {
          return;
        }
        this._updateFormValue(newVal);
        this._sendChange(newVal);
        break;
      }
      case 'increase': {
        const newVal = currentValue  + 1;
        if (newVal > 100) {
          return;
        }
        this._updateFormValue(newVal);
        this._sendChange(newVal);
        break;
      }
    }
  }

  onFocusOut(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    const _val = target.value.substring(0, target.value.length - 1);
    const val = Number(_val);
    this._updateFormValue(val);
    this._sendChange(val);
  }

  private _updateFormValue(val: number) {
    // this.currentVal = val;
    this.val$.next(val)
    this.form.controls.percent.setValue(val + '%')
    this.form.controls.percent.updateValueAndValidity()
  }

  private _sendChange(val: number) {
    this.onPercentChange.emit(val);
  }

  private _getPercentValueInNum(): number {
    const val: string = this.form.controls.percent.value;
    const numVal = Number(val.substring(0, val.length - 1));
    return numVal;
  }
}
