import {AssetClassRetailFacilityModel} from '../_models/asset-class-retail-facility.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassRetailFacilitiesState} from '../_reducers/asset-class-retail-facility.reducers';
import * as fromAssetClassRetailFacility from '../_reducers/asset-class-retail-facility.reducers';
import {each} from 'lodash';

export const selectAssetClassRetailFacilitiesState = createFeatureSelector<AssetClassRetailFacilitiesState>('assetClassRetailFacilities');

export const selectAllAssetClassRetailFacilities = createSelector(
    selectAssetClassRetailFacilitiesState,
    fromAssetClassRetailFacility.selectAll
);

export const selectAssetClassRetailFacilityQueryResult = createSelector(
    selectAssetClassRetailFacilitiesState,
    assetClassRetailFacilitiesState => {
        const items: AssetClassRetailFacilityModel[] = [];
        each(assetClassRetailFacilitiesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AssetClassRetailFacilityModel[] = httpExtension.sortArray(items, assetClassRetailFacilitiesState.lastQuery.sortField, assetClassRetailFacilitiesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, assetClassRetailFacilitiesState.queryRowsCount, '');
    }
);
