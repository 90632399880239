// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SpecialAssumptionsService} from '../_services/special-assumption.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSpecialAssumptionsLoaded} from '../_selectors/special-assumption.selectors';
// Actions
import {
    AllSpecialAssumptionsLoaded,
    AllSpecialAssumptionsRequested,
    SpecialAssumptionActionTypes,
    SpecialAssumptionsPageRequested,
    SpecialAssumptionsPageLoaded,
    SpecialAssumptionUpdated,
    SpecialAssumptionsPageToggleLoading,
    SpecialAssumptionDeleted,
    SpecialAssumptionOnServerCreated,
    SpecialAssumptionCreated,
    SpecialAssumptionAdminRestored,
    SpecialAssumptionDeletedFromAdminTrash,
    SpecialAssumptionDeletedFromTrash,
    SpecialAssumptionOnServerAdminRestored,
    SpecialAssumptionOnServerRestored,
    SpecialAssumptionRestored,
    SpecialAssumptionTrashFlushed,
    SpecialAssumptionsActionToggleLoading
} from '../_actions/special-assumption.actions';

@Injectable()
export class SpecialAssumptionEffects {
    showPageLoadingDispatcher = new SpecialAssumptionsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SpecialAssumptionsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SpecialAssumptionsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSpecialAssumptions$ = this.actions$
        .pipe(
            ofType<AllSpecialAssumptionsRequested>(SpecialAssumptionActionTypes.AllSpecialAssumptionsRequested),
            withLatestFrom(this.store.pipe(select(allSpecialAssumptionsLoaded))),
            filter(([action, isAllSpecialAssumptionsLoaded]) => !isAllSpecialAssumptionsLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllSpecialAssumptionsLoaded({specialAssumptions: res.data});
            })
        );

    @Effect()
    loadSpecialAssumptionsPage$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionsPageRequested>(SpecialAssumptionActionTypes.SpecialAssumptionsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SpecialAssumptionsPageLoaded({
                    specialAssumptions: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionUpdated>(SpecialAssumptionActionTypes.SpecialAssumptionUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionOnServerCreated>(SpecialAssumptionActionTypes.SpecialAssumptionOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.specialAssumption).pipe(
                    tap(res => {
                        this.store.dispatch(new SpecialAssumptionCreated({specialAssumption: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushSpecialAssumptionTrash$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionTrashFlushed>(SpecialAssumptionActionTypes.SpecialAssumptionTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionOnServerRestored>(SpecialAssumptionActionTypes.SpecialAssumptionOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SpecialAssumptionRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionOnServerAdminRestored>(SpecialAssumptionActionTypes.SpecialAssumptionOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SpecialAssumptionAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionDeleted>(SpecialAssumptionActionTypes.SpecialAssumptionDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionDeletedFromTrash>(SpecialAssumptionActionTypes.SpecialAssumptionDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSpecialAssumption$ = this.actions$
        .pipe(
            ofType<SpecialAssumptionDeletedFromAdminTrash>(SpecialAssumptionActionTypes.SpecialAssumptionDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: SpecialAssumptionsService, private store: Store<AppState>) {
    }
}
