<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <div class="card-label">Size Table</div>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="close()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body">
                <mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                        <mat-cell *matCellDef="let file">
                            <img [attr.src]="typesUtilsService.setIcon(typesUtilsService.getFileType(file.name))" />
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
                        <mat-cell *matCellDef="let file">
                            {{file.title}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="descr">
                        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                        <mat-cell *matCellDef="let file">{{file.descr}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let file">
                            <button mat-icon-button
                                    [matTooltip]="'GENERAL.TOOLTIP.PREVIEW_FILE' | translate"
                                    (click)="previewUploadedFile(file)"
                                    type="button">
                                <mat-icon *ngIf="!docs.includes(typesUtilsService.getFileType(file.name))">remove_red_eye</mat-icon>
                                <mat-icon *ngIf="docs.includes(typesUtilsService.getFileType(file.name))">get_app</mat-icon>
                            </button>&nbsp;
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
    </div>
</div>
