// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AssetClassRetailModel } from '../_models/asset-class-retail.model';
import {AssetClassTenure, AssetClassSizeModel, OfficeExternalAreaModel} from '..';

export enum AssetClassRetailActionTypes {
    AssetClassRetailOnServerCreated = '[Edit AssetClassRetail Dialog] AssetClassRetail On Server Created',
    AssetClassRetailCreated = '[Edit AssetClassRetail Dialog] AssetClassRetail Created',

    AssetClassRetailOnServerUpdated = '[Edit AssetClassRetail Dialog] AssetClassRetail On Server Updated',
    AssetClassRetailUpdated = '[Edit AssetClassRetail Dialog] AssetClassRetail Updated',

    AssetClassRetails = '[AssetClassRetails List] get all ',
    AssetClassRetailsPageRequested = '[AssetClassRetails List Page] AssetClassRetails Page Requested',
    AssetClassRetailMapPageRequested = '[AssetClassRetails Map Page] AssetClassRetails Page Requested',
    AssetClassRetailsPageLoaded = '[AssetClassRetails API] AssetClassRetails Page Loaded',

    AssetClassRetailOnServerRestored = '[AssetClassRetail Trash] AssetClassRetail On Server Restored',
    AssetClassRetailRestored = '[AssetClassRetail Trash] AssetClassRetail Restored',
    AssetClassRetailDeletedFromTrash = '[AssetClassRetail Trash] AssetClassRetail deleted',
    AssetClassRetailTrash= 'AssetClassRetail Trashed',
    RetailTrashFlushed = 'AssetClassRetail Trash Flushed',
    OneAssetClassRetailDeleted = '[AssetClassRetails List Page] One AssetClassRetail Deleted',

    AssetClassRetailsPageCancelled = '[AssetClassRetails API] AssetClassRetails Page Cancelled',
    AssetClassRetailsPageToggleLoading = '[AssetClassRetails] AssetClassRetails Page Toggle Loading',
    AssetClassRetailActionToggleLoading = '[AssetClassRetails] AssetClassRetails Action Toggle Loading',
    AssetClassRetailGet = '[AssetClassRetail Object] AssetClassRetail Object Requested'
}

export class AssetClassRetailOnServerCreated implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailOnServerCreated;
    constructor(public payload: {
        assetClassRetail: AssetClassRetailModel,
        tenures: AssetClassTenure[],
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        selectedFacilitiesExclusive: any[],
        officeExternalAreas: OfficeExternalAreaModel[] }) { }
}

export class AssetClassRetailOnServerUpdated implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailOnServerUpdated;
    constructor(public payload: {partialAssetClassRetail: Update<AssetClassRetailModel>,
        assetClassRetail: AssetClassRetailModel,
        tenures: AssetClassTenure[],
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        selectedFacilitiesExclusive: any[],
        officeExternalAreas: OfficeExternalAreaModel[] }) { }
}

export class AssetClassRetailGet implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetails;
    constructor(public payload: { assetClassRetailId: number }) { }
}

export class AssetClassRetailCreated implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailCreated;
    constructor(public payload: { assetClassRetail: AssetClassRetailModel }) { }
}

export class AssetClassRetailUpdated implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailUpdated;
    constructor(public payload: { assetClassRetail: AssetClassRetailModel }) { }
}

export class AssetClassRetailsPageRequested implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailsPageRequested;
    constructor(public payload: { page: QueryParamsModel}) { }
}

export class AssetClassRetailMapPageRequested implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailMapPageRequested;
    constructor(public payload: { page: QueryParamsModel}) { }
}

export class AssetClassRetailsPageLoaded implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailsPageLoaded;
    constructor(public payload: { assetClassRetails: AssetClassRetailModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) { }
}

export class AssetClassRetailsPageCancelled implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailsPageCancelled;
}

export class AssetClassRetailsPageToggleLoading implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AssetClassRetailActionToggleLoading implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AssetClassRetailOnServerRestored implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailOnServerRestored;
    constructor(public payload: { id: number }) { }
}

export class AssetClassRetailRestored implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailRestored;
    constructor(public payload: { assetClassRetail: AssetClassRetailModel }) { }
}

export class AssetClassRetailDeletedFromTrash implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailDeletedFromTrash;
    constructor(public payload: { assetClassRetailId: number }) { }
}

export class AssetClassRetailsTrashRequested implements Action {
    readonly type = AssetClassRetailActionTypes.AssetClassRetailTrash;
    constructor(public payload: { isLoading: boolean }) { }
}

export class RetailTrashFlushed implements Action {
    readonly type = AssetClassRetailActionTypes.RetailTrashFlushed;
    constructor() { }
}

export class OneAssetClassRetailDeleted implements Action {
    readonly type = AssetClassRetailActionTypes.OneAssetClassRetailDeleted;
    constructor(public payload: { id: number }) {}
}

export type AssetClassRetailActions = AssetClassRetailOnServerCreated
| AssetClassRetailOnServerUpdated
| AssetClassRetailCreated
| AssetClassRetailUpdated
| AssetClassRetailsPageRequested
| AssetClassRetailMapPageRequested
| AssetClassRetailsPageLoaded
| AssetClassRetailsPageCancelled
| AssetClassRetailsPageToggleLoading
| AssetClassRetailActionToggleLoading
| AssetClassRetailOnServerRestored
| AssetClassRetailRestored
| RetailTrashFlushed
| AssetClassRetailDeletedFromTrash
| OneAssetClassRetailDeleted
| AssetClassRetailGet;
