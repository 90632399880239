interface ValuationTask {
  id: number,
  scenario: {
    original_task_id: number,
    scenario_number: number,
    original_task_index: number
  }
}

export function sortOriginalTasks<T extends ValuationTask>(tasks: Array<T>): Array<T> {
  const _originalTasks = tasks.filter(t => {
    if (t.scenario == null) {
      return true;
    }
    if (t.id == t.scenario.original_task_id) {
      return true;
    }
    return false;
  })

  const originalTasks: T[] = Array.from({length: _originalTasks.length})
  const temp: T[] = []
  _originalTasks.forEach(t => {
    if (t.scenario) {
      originalTasks[t.scenario.original_task_index] = t
    } else {
      temp.push(t)
    }
  })
  for (let i = 0; i < originalTasks.length; i++) {
    if (originalTasks[i] == undefined) {
      originalTasks[i] = temp.shift()
    }
  }

  return originalTasks
}

export function sortValuationTasks<T extends ValuationTask>(tasks: Array<T>, originalTasks: Array<T>): Array<T & {valuation_index: number}> {
  const _temp = tasks.map(t => {
    const itsOriginalTaskId = t.scenario ? t.scenario.original_task_id : t.id
    const originalTask = originalTasks.find(ot => ot.id == itsOriginalTaskId)
    const originalTaskIndex = originalTasks.findIndex(ot => ot.id == itsOriginalTaskId)
    const index = originalTask && originalTask.scenario ? originalTask.scenario.original_task_index : originalTaskIndex
    return {
      task: t,
      index: index,
      scenario_number: t.scenario ? t.scenario.scenario_number : 1
    }
  })

  _temp.sort((a, b) => {
    if (a.index == b.index) {
      return a.scenario_number - b.scenario_number
    }
    return a.index > b.index ? 1 : -1
  })

  return _temp.map(item => ({...item.task, valuation_index: item.index}))
}