import {PaymentType} from '../_models/payment-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {PaymentTypesState} from '../_reducers/payment-type.reducers';
import * as fromPaymentType from '../_reducers/payment-type.reducers';
import {each} from 'lodash';

export const selectPaymentTypesState = createFeatureSelector<PaymentTypesState>('paymentTypes');

export const selectPaymentTypeById = (paymentTypeId: number) => createSelector(
    selectPaymentTypesState,
    paymentTypesState => paymentTypesState.entities[paymentTypeId]
);

export const selectAllPaymentTypes = createSelector(
    selectPaymentTypesState,
    fromPaymentType.selectAll
);

export const selectAllPaymentTypesIds = createSelector(
    selectPaymentTypesState,
    fromPaymentType.selectIds
);

export const allPaymentTypesLoaded = createSelector(
    selectPaymentTypesState,
    paymentTypesState => paymentTypesState.isAllPaymentTypesLoaded
);


export const selectPaymentTypesPageLoading = createSelector(
    selectPaymentTypesState,
    paymentTypesState => paymentTypesState.listLoading
);

export const selectPaymentTypesActionLoading = createSelector(
    selectPaymentTypesState,
    paymentTypesState => paymentTypesState.actionLoading
);

export const selectLastCreatedPaymentTypeId = createSelector(
    selectPaymentTypesState,
    paymentTypesState => paymentTypesState.lastCreatedPaymentTypeId
);

export const selectPaymentTypesShowInitWaitingMessage = createSelector(
    selectPaymentTypesState,
    paymentTypesState => paymentTypesState.showInitWaitingMessage
);

export const selectTrashedPaymentTypeCount = createSelector(
    selectPaymentTypesState,
    (paymentTypesState) => paymentTypesState.totalTrashed
);

export const selectAdminTrashedPaymentTypeCount = createSelector(
    selectPaymentTypesState,
    (paymentTypesState) => paymentTypesState.totalAdminTrashed
);


export const selectPaymentTypeQueryResult = createSelector(
    selectPaymentTypesState,
    paymentTypesState => {
        const items: PaymentType[] = [];
        each(paymentTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PaymentType[] = httpExtension.sortArray(items, paymentTypesState.lastQuery.sortField, paymentTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, paymentTypesState.totalCount, '', paymentTypesState.totalTrashed);
    }
);
