import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { TpSignedDoc } from "../_models/tp-signed-doc.model";
import { TpSignedDocState } from "../_reducers/tp-signed-docs.reducer";

export const selectTpSignedDocState = createFeatureSelector<TpSignedDocState>('tp-signed-docs');

export const selectIsUploading = (tpID: number) => createSelector(
	selectTpSignedDocState,
	state => state.isUploading[tpID]
)

export const selectIsUploadFailed = (tpID: number) => createSelector(
	selectTpSignedDocState,
	state => state.error[tpID]
)

export const selectTpSignedDocs = (tpID: number) => createSelector(
	selectTpSignedDocState,
	state => {
		const items: TpSignedDoc[] = [];
		each(state.entities, el => {
			if (el.tp_id == tpID) {
				items.push(el);
			}
		})
		return items;
	}
)