<div class="row">
    <div class="col-12">
        <h5 class="my-3 text-mad text-uppercase">ASSUMPTIONS (PART 2 GLOSSARY, RICS RED BOOK 2022)</h5>
        <hr class="active">
    </div>
</div>
<div class="row" [ngClass]="{'readonly': readonly}">
    <div class="col-12">
        <div class="mb-4">
            <mat-label>
                A supposition taken to be true. It involves facts, conditions or situations affecting the subject of, or approach to, a valuation that, by agreement, do not
                need to
                be
                verified by the valuer as part of the valuation process. Typically, an assumption is made where specific investigation by the valuer is not required in order to
                prove
                that something is true. (Default and special assumption maximum quantity is 15)
            </mat-label>
        </div>
    </div>
    <div class="col-12 py-1" *ngFor="let item of assumptionsData; let i = index">
        <mat-checkbox [checked]="item.selected" (change)="changeSelection($event, i)">
            <span>{{item.name}}</span>
        </mat-checkbox>
    </div>
    <div class="col-12">
        <a href="javascript:;" class="bnt bnt-primary mr-2 mt-2" color="primary"
            mat-raised-button
            *ngIf="!readonly"
            matTooltip="Add Assumption"
            (click)="addItem()">
            <span>Add Assumption</span> 
        </a> 
    </div> 
</div>