<div class="card card-custom">
    <div class="card-header">
        <div class="card-title" *ngIf="viewer_type == 'Default'">
            <h3 class="card-label" *ngIf="!withoutViewer">
                {{ 'IMAGE_VIEWER.TITLE' | translate }} {{ obj.title ? '(' + obj.title +')' : '' }}
            </h3>
            <h3 class="card-label" *ngIf="withoutViewer">
                <strong>{{obj.boldTitle ? (obj.boldTitle + ' - ') : ''}}</strong>
                {{obj.title ? obj.title : ''}}
            </h3>
        </div>
        <div class="card-title" *ngIf="viewer_type == 'Report Design'">
            <h3 class="card-label">
                {{obj.title}}
                <span *ngIf="obj.rating">
                    ({{obj.rating.title}} - {{obj.rating.rate}} {{obj.rating.description ? ("- " + obj.rating.description) : ""}})
                </span>
            </h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <mat-dialog-content>
                <div [ngClass]="loading ? 'loading' : 'loaded'">
                    <div *ngIf="obj.type != 'pdf'" class="container portrait">
                        <img (error)="imgOnError($event)" (load)="imgLoading($event)" [src]="path" />
                    </div>
                    <pdf-viewer *ngIf="obj.type == 'pdf'" [src]="path" [original-size]="true" [fit-to-page]="true"
                        [show-all]="true" style="display: block;" [render-text]="false"
                        (on-progress)="pdfLoadingProgress($event)" (error)="onError($event)">
                    </pdf-viewer>
                </div>
                <div *ngIf="hasError">
                    Error occured
                </div>
            </mat-dialog-content>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" [mat-dialog-close]="1" cdkFocusInitial>
                    {{ 'IMAGE_VIEWER.BUTTON.CLOSE.LABEL' | translate }}
                </button>&nbsp;
            </div>
        </div>
    </div>
</div>