import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { ToeReportTaskModel } from "../_models/toe-report-task.model";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class ToeReportTaskService {
	API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/report-tasks/`;
	constructor(
		private http: HttpClient,
		private authService: MadAuthService,
		private subDomainService: SubdomainService
	) {}

	getAllToeReportTasks(toeID: number): Observable<any> {
		const url = this.API_URL + toeID;
		const httpHeaders = this.authService.getAuthHeaders();
		return this.http.get<any>(url, {headers: httpHeaders});
	}

	updateReportTask(id: number, task: ToeReportTaskModel): Observable<any> {
		const url = this.API_URL + id;
		const httpHeaders = this.authService.getAuthHeaders();
		return this.http.post<any>(url, {
			user_id: task.assignee_id,
			date: task.date
		}, {headers: httpHeaders});
	}

	updateReportTaskStatus(id: number, status: boolean, reports: {id: number, checked: number}[]): Observable<any> {
		const url = this.API_URL + id + '/status';
		const httpHeaders = this.authService.getAuthHeaders();
		return this.http.post<any>(url, {
			status,
			reports
		}, {headers: httpHeaders});
	}

	deleteReportTask(id: number): Observable<any> {
		const url = this.API_URL + id;
		const headers = this.authService.getAuthHeaders();
		return this.http.delete<any>(url, {headers});
	}
}