// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {HandoverStandardActions, HandoverStandardActionTypes} from '../_actions/handover-standard.actions';
import {HandoverStandard} from '../_models/handover-standard.model';

export interface HandoverStandardsState extends EntityState<HandoverStandard> {
    isAllHandoverStandardsLoaded: boolean;
    queryRowsCount: number;
    queryResult: HandoverStandard[];
    lastCreatedHandoverStandardId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<HandoverStandard> = createEntityAdapter<HandoverStandard>();

export const initialHandoverStandardsState: HandoverStandardsState = adapter.getInitialState({
    isAllHandoverStandardsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedHandoverStandardId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function handoverStandardsReducer(state = initialHandoverStandardsState, action: HandoverStandardActions): HandoverStandardsState {
    switch (action.type) {
        case HandoverStandardActionTypes.HandoverStandardsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedHandoverStandardId: undefined
            };
        case HandoverStandardActionTypes.HandoverStandardsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case HandoverStandardActionTypes.HandoverStandardOnServerCreated:
            return {
                ...state
            };
        case HandoverStandardActionTypes.HandoverStandardCreated:
            return adapter.addOne(action.payload.handoverStandard, {
                ...state, lastCreatedHandoverStandardId: action.payload.handoverStandard.id
            });
        case HandoverStandardActionTypes.HandoverStandardUpdated:
            return adapter.updateOne(action.payload.partialHandoverStandard, state);
        case HandoverStandardActionTypes.AllHandoverStandardsLoaded:
            return adapter.addAll(action.payload.handoverStandards, {
                ...state, isAllHandoverStandardsLoaded: true
            });
        case HandoverStandardActionTypes.HandoverStandardsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case HandoverStandardActionTypes.HandoverStandardsPageLoaded:
            return adapter.addMany(action.payload.handoverStandards, {
                ...initialHandoverStandardsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case HandoverStandardActionTypes.HandoverStandardDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case HandoverStandardActionTypes.HandoverStandardRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case HandoverStandardActionTypes.HandoverStandardDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case HandoverStandardActionTypes.HandoverStandardAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case HandoverStandardActionTypes.HandoverStandardDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case HandoverStandardActionTypes.HandoverStandardTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
