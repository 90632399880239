export class QueryParamsDividedModel {
	// fields
	filter: any;
	sortOrder: string; // asc || desc
	sortField: string;
	pageNumber: number;
	pageSize: number;
	propertySubTypeId: number;

	// constructor overrides
	constructor(_filter: any,
		_sortOrder: string = 'asc',
		_sortField: string = '',
		_pageNumber: number = 1,
		_pageSize: number = 25,
		_propertySubTypeId: number = 0) {

		this.filter = _filter;
		this.sortOrder = _sortOrder;
		this.sortField = _sortField;
		this.pageNumber = _pageNumber;
		this.pageSize = _pageSize;
		this.propertySubTypeId = _propertySubTypeId;
	}

	isEmpty(): boolean {
		for(var key in this.filter) {
			if (this.filter.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}
}
