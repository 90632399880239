import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { DraftStatementReportModel } from "../_models/draft-statement-report.model";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class DraftStatementReportService {
	API_DRAFT_STATEMENT_REPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toes/draft-statement-report`

	constructor(
		private http: HttpClient,
		private authService: MadAuthService,
		private subDomainService: SubdomainService
	) {}

	getReports(tpID: number): Observable<any> {
		const headers = this.authService.getAuthHeaders();
		return this.http.get<any>(this.API_DRAFT_STATEMENT_REPORT_URL + 'ByTp' + `/${tpID}`, {
			headers
		}).pipe(catchError(err => {
			return EMPTY
		}))
	}

	createReport(report: DraftStatementReportModel): Observable<any> {
		const headers = this.authService.getAuthHeaders();
		return this.http.post<any>(this.API_DRAFT_STATEMENT_REPORT_URL, report, {
			headers
		});
	}

	deleteReport(id: number): Observable<any> {
		const headers = this.authService.getAuthHeaders();
		return this.http.delete<any>(this.API_DRAFT_STATEMENT_REPORT_URL + `/${id}`, {
			headers
		});
	}
}