// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {ConservatoryPorcheService} from '../_services/conservatory-porche.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allConservatoryPorchesLoaded} from '../_selectors/conservatory-porche.selectors';
// Actions
import {
    AllConservatoryPorchesLoaded,
    AllConservatoryPorchesRequested,
    ConservatoryPorcheActionTypes,
    ConservatoryPorchesPageRequested,
    ConservatoryPorchesPageLoaded,
    ConservatoryPorcheUpdated,
    ConservatoryPorchesPageToggleLoading,
    ConservatoryPorcheDeleted,
    ConservatoryPorcheOnServerCreated,
    ConservatoryPorcheCreated,
    ConservatoryPorcheActionToggleLoading, ConservatoryPorcheTrashFlushed, ConservatoryPorcheOnServerRestored, ConservatoryPorcheRestored,
    ConservatoryPorcheOnServerAdminRestored, ConservatoryPorcheAdminRestored, ConservatoryPorcheDeletedFromTrash, ConservatoryPorcheDeletedFromAdminTrash
} from '../_actions/conservatory-porche.actions';

@Injectable()
export class ConservatoryPorcheEffects {
    showPageLoadingDispatcher = new ConservatoryPorchesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ConservatoryPorcheActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ConservatoryPorcheActionToggleLoading({isLoading: false});

    @Effect()
    loadAllConservatoryPorche$ = this.actions$
        .pipe(
            ofType<AllConservatoryPorchesRequested>(ConservatoryPorcheActionTypes.AllConservatoryPorchesRequested),
            withLatestFrom(this.store.pipe(select(allConservatoryPorchesLoaded))),
            filter(([action, isAllConservatoryPorcheLoaded]) => !isAllConservatoryPorcheLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllConservatoryPorchesLoaded({items: res.data});
            })
        );

    @Effect()
    loadConservatoryPorchePage$ = this.actions$
        .pipe(
            ofType<ConservatoryPorchesPageRequested>(ConservatoryPorcheActionTypes.ConservatoryPorchesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ConservatoryPorchesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheUpdated>(ConservatoryPorcheActionTypes.ConservatoryPorcheUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheOnServerCreated>(ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new ConservatoryPorcheCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushConservatoryPorcheTrash$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheTrashFlushed>(ConservatoryPorcheActionTypes.ConservatoryPorcheTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheOnServerRestored>(ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ConservatoryPorcheRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheOnServerAdminRestored>(ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new ConservatoryPorcheAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheDeleted>(ConservatoryPorcheActionTypes.ConservatoryPorcheDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheDeletedFromTrash>(ConservatoryPorcheActionTypes.ConservatoryPorcheDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashConservatoryPorche$ = this.actions$
        .pipe(
            ofType<ConservatoryPorcheDeletedFromAdminTrash>(ConservatoryPorcheActionTypes.ConservatoryPorcheDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: ConservatoryPorcheService, private store: Store<AppState>) {
    }
}
