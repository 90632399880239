// NGRX
import { Action } from '@ngrx/store';
// Models
import { AssetClassResidentialFacilityModel } from '../_models/asset-class-residential-facility.model';

export enum AssetClassResidentialFacilityActionTypes {
    AllAssetClassResidentialFacilitiesRequested = '[AssetClassResidentialFacilities Home Page] All AssetClassResidentialFacilities Requested',
    AllAssetClassResidentialFacilitiesLoaded = '[AssetClassResidentialFacilities API] All AssetClassResidentialFacilities Loaded',
    AssetClassResidentialFacilityActionToggleLoading = '[AssetClassResidentialFacility] AssetClassResidentialFacility Action Toggle Loading',
}

export class AllAssetClassResidentialFacilitiesRequested implements Action {
    readonly type = AssetClassResidentialFacilityActionTypes.AllAssetClassResidentialFacilitiesRequested;
    constructor(public payload: { assetClassResidentialId: number }) { }
}

export class AllAssetClassResidentialFacilitiesLoaded implements Action {
    readonly type = AssetClassResidentialFacilityActionTypes.AllAssetClassResidentialFacilitiesLoaded;
    constructor(public payload: { assetClassResidentialFacilities: AssetClassResidentialFacilityModel[] }) { }
}

export class AssetClassResidentialFacilityActionToggleLoading implements Action {
    readonly type = AssetClassResidentialFacilityActionTypes.AssetClassResidentialFacilityActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AssetClassResidentialFacilityActions = AllAssetClassResidentialFacilitiesRequested
| AllAssetClassResidentialFacilitiesLoaded
| AssetClassResidentialFacilityActionToggleLoading;
