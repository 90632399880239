import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AssetClassSourceExternalReferenceModel, TpFileModel } from 'src/app/core/asset_class';
import { ToeRequiredComponent } from './toe-required/toe-required.component';
import { UserUploadedComponent } from './user-uploaded/user-uploaded.component';
import * as fromFileUploadSelectors from '../../../../../core/file-upload/_selectors/file-upload.selectors';
import * as fromFileUploadState from '../../../../../core/file-upload/_states/file-upload.state';
import { select, Store } from '@ngrx/store';
import { Globals } from 'src/app/core/_base/crud/utils/globals.service';
@Component({
  selector: 'kt-tp-documents',
  templateUrl: './tp-documents.component.html',
  styleUrls: ['./tp-documents.component.scss'],
})
export class TpDocumentsComponent implements OnInit {
  @Input() tpState: number;
  @Input() toeOldFiles: BehaviorSubject<TpFileModel[]>;
  @Input() userOldFiles: BehaviorSubject<TpFileModel[]>;
  @Input() toeFolderOnEditMode: string;
  @Input() userFolderOnEditMode: string;
  @Input() fileCategory: BehaviorSubject<AssetClassSourceExternalReferenceModel[]>;
  @Input() parentId: number;
  @Input() toeStatus: number;

  @ViewChild('fileInput', {read: ElementRef}) fInput: ElementRef;
  @ViewChild('toeRequired') toeRequiredComponent: ToeRequiredComponent;
  @ViewChild('userUploaded') userUploadedComponent: UserUploadedComponent;

  completed$: Observable<boolean>;
  progress$: Observable<number>;
  error$: Observable<string>;
  isInProgress$: Observable<boolean>;
  isReady$: Observable<boolean>;
  hasFailed$: Observable<boolean>;

  isInProgressSub = new BehaviorSubject<boolean>(false);
  progressSub = new BehaviorSubject<number>(0);
  hasFailedSub = new BehaviorSubject<boolean>(false);
  errorSub = new BehaviorSubject<string>('');
  cancelReq = new Subject<void>();

  fileType = 'application/pdf'

  lastChangedItem = new BehaviorSubject<TpFileModel>(new TpFileModel());

  whoUploaded$ = new BehaviorSubject<number>(0);
  whoUploaded: number;

  constructor(
    private store$: Store<fromFileUploadState.State>,
    private global: Globals,
  ) {}

  ngOnInit(): void {
    this.whoUploaded$.subscribe(val => {
      this.whoUploaded = val;
    });


    this.completed$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileCompleted)
    );

    this.progress$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileProgress)
    );

    this.error$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileError)
    );

    this.isInProgress$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileInProgress)
    );

    this.isReady$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileReady)
    );

    this.hasFailed$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileFailed)
    );
  }

  addFile() {
    this.userUploadedComponent.addFile(null, 0);
  }

  uploadFile(event) {
    if (this.whoUploaded === 1) {
      this.toeRequiredComponent.uploadFile(event);
    } else if (this.whoUploaded === 2) {
      this.userUploadedComponent.uploadFile(event);
    }
  }
}
