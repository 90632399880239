// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {PaymentType} from '../_models/payment-type.model';

export enum PaymentTypeActionTypes {

    AllPaymentTypesRequested = '[PaymentTypes Home Page] All PaymentTypes Requested',
    AllPaymentTypesLoaded = '[PaymentTypes API] All PaymentTypes Loaded',

    PaymentTypeOnServerCreated = '[Edit PaymentType Dialog] PaymentType On Server Created',
    PaymentTypeCreated = '[Edit PaymentTypes Dialog] PaymentTypes Created',
    PaymentTypeUpdated = '[Edit PaymentType Dialog] PaymentType Updated',
    PaymentTypeDeleted = '[PaymentTypes List Page] PaymentType Deleted',

    PaymentTypesPageRequested = '[PaymentTypes List Page] PaymentTypes Page Requested',
    PaymentTypesPageLoaded = '[PaymentTypes API] PaymentTypes Page Loaded',
    PaymentTypesPageCancelled = '[PaymentTypes API] PaymentTypes Page Cancelled',

    PaymentTypesPageToggleLoading = '[PaymentTypes page] PaymentTypes Page Toggle Loading',
    PaymentTypesActionToggleLoading = '[PaymentTypes] PaymentTypes Action Toggle Loading',

    // trash system

    PaymentTypeOnServerRestored = '[PaymentType Trash] PaymentType On Server Restored',
    PaymentTypeRestored = '[PaymentType Trash] PaymentType Restored',

    PaymentTypeOnServerAdminRestored = '[PaymentType Admin Trash] PaymentType On Server Restored',
    PaymentTypeAdminRestored = '[PaymentType Admin Trash] PaymentType Restored',

    PaymentTypeDeletedFromTrash = '[PaymentType Trash] PaymentType deleted',
    PaymentTypeDeletedFromAdminTrash = '[PaymentType Admin Trash] PaymentType deleted',

    PaymentTypeTrash = 'PaymentType Trashed',
    PaymentTypeTrashFlushed = 'PaymentType Trash Flushed',
}

export class PaymentTypeOnServerCreated implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeOnServerCreated;

    constructor(public payload: { paymentType: PaymentType}) {
    }
}

export class PaymentTypeCreated implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeCreated;

    constructor(public payload: { paymentType: PaymentType }) {
    }
}

export class PaymentTypeUpdated implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeUpdated;

    constructor(public payload: {
        partialPaymentType: Update<PaymentType>,
        paymentType: PaymentType
    }) {
    }
}

export class PaymentTypeDeleted implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class PaymentTypesPageRequested implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class PaymentTypesPageLoaded implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypesPageLoaded;

    constructor(public payload: { paymentTypes: PaymentType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class PaymentTypesPageCancelled implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypesPageCancelled;
}

export class AllPaymentTypesRequested implements Action {
    readonly type = PaymentTypeActionTypes.AllPaymentTypesRequested;
}

export class AllPaymentTypesLoaded implements Action {
    readonly type = PaymentTypeActionTypes.AllPaymentTypesLoaded;

    constructor(public payload: { paymentTypes: PaymentType[] }) {
    }
}

export class PaymentTypesPageToggleLoading implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PaymentTypesActionToggleLoading implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class PaymentTypeDeletedFromAdminTrash implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class PaymentTypeDeletedFromTrash implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PaymentTypeOnServerRestored implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PaymentTypeRestored implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeRestored;

    constructor(public payload: { item: PaymentType }) {
    }
}

export class PaymentTypeOnServerAdminRestored implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PaymentTypeAdminRestored implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeAdminRestored;

    constructor(public payload: { item: PaymentType }) {
    }
}

export class PaymentTypeTrashFlushed implements Action {
    readonly type = PaymentTypeActionTypes.PaymentTypeTrashFlushed;

    constructor() {
    }
}

export type PaymentTypeActions = PaymentTypeCreated
    | PaymentTypeUpdated
    | PaymentTypeDeleted
    | PaymentTypesPageRequested
    | PaymentTypesPageLoaded
    | PaymentTypesPageCancelled
    | AllPaymentTypesLoaded
    | AllPaymentTypesRequested
    | PaymentTypeOnServerCreated
    | PaymentTypesPageToggleLoading
    | PaymentTypesActionToggleLoading
    | PaymentTypeDeletedFromAdminTrash
    | PaymentTypeDeletedFromTrash
    | PaymentTypeOnServerRestored
    | PaymentTypeRestored
    | PaymentTypeOnServerAdminRestored
    | PaymentTypeAdminRestored
    | PaymentTypeTrashFlushed;
