// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SubCategoryActions, SubCategoryActionTypes} from '../_actions/sub-category.actions';
// Models
import {SubCategoryModel} from '../_models/sub-category.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SubCategorysState extends EntityState<SubCategoryModel> {
    isAllSubCategorysLoaded: boolean;
    queryRowsCount: number;
    queryResult: SubCategoryModel[];
    lastCreatedSubCategoryId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SubCategoryModel> = createEntityAdapter<SubCategoryModel>();

export const initialSubCategoryState: SubCategorysState = adapter.getInitialState({
    isAllSubCategorysLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSubCategoryId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function subCategoryReducer(state = initialSubCategoryState, action: SubCategoryActions): SubCategorysState {
    switch (action.type) {
        case SubCategoryActionTypes.SubCategorysPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSubCategoryId: undefined
            };
        case SubCategoryActionTypes.SubCategoryActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SubCategoryActionTypes.SubCategoryOnServerCreated:
            return {
                ...state
            };
        case SubCategoryActionTypes.SubCategoryCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedSubCategoryId: action.payload.item.id
            });
        case SubCategoryActionTypes.SubCategoryUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case SubCategoryActionTypes.AllSubCategorysLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllSubCategorysLoaded: true
            });
        case SubCategoryActionTypes.SubCategorysPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SubCategoryActionTypes.SubCategorysPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialSubCategoryState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case SubCategoryActionTypes.SubCategoryDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SubCategoryActionTypes.SubCategoryRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SubCategoryActionTypes.SubCategoryDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SubCategoryActionTypes.SubCategoryAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SubCategoryActionTypes.SubCategoryDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SubCategoryActionTypes.SubCategoryTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
