import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { Pagination } from "../_models/pagination.interface";
import { environment } from "src/environments/environment";
import { Sorting } from "../_models/sorting.interface";
import { AssignmentModel } from "../../assignment";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class TermsOfEngagementService {
  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/terms-of-engagements`
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  fetchList(filter: any, sort: Sorting, pagination: Pagination) {
    const headers = this.authService.getAuthHeaders()
    let params = new HttpParams();
    params = this.applyFilter(params, filter)
    params = this.applySort(params, sort)
    params = this.applyPagination(params, pagination)
    return this.http.get<{
      data: unknown[],
      meta: {
        total: number,
        page: number,
        page_size: number
      }
    }>(
      `${this.API_URL}`,
      {
        headers,
        params
      }
    )
  }

  fetchTotalInfo(filter: any) {
    const headers = this.authService.getAuthHeaders()
    let params = new HttpParams()
    params = this.applyFilter(params, filter)
    return this.http.get<{
      data: {
        total_toes: number,
        total_tps: number,
        total_valuations: number,
        total_assignments: number,
        trashed: number,
        admin_trashed: number
      }
    }>(`${this.API_URL}/total-infos`, {headers, params})
  }

  fetchTrashed() {
    const headers = this.authService.getAuthHeaders()
    return this.http.get<{
      data: {
        id: number,
        name: string,
        deleted_by: string,
        deleted_at: string
      }[]
    }>(`${this.API_URL}/trashed`, {headers})
  }

  fetchAdminTrashed() {
    const headers = this.authService.getAuthHeaders()
    return this.http.get<{
      data: {
        id: number,
        name: string,
        deleted_by: string,
        deleted_at: string
      }[]
    }>(`${this.API_URL}/trashed/admin`, {headers})
  }

  deleteToe(id: number) {
    const headers = this.authService.getAuthHeaders()
    return this.http.delete<void>(
      `${this.API_URL}/${id}`,
      {headers}
    )
  }

  deleteToeFromTrash(id: number) {
    const headers = this.authService.getAuthHeaders()
    return this.http.delete<void>(
      `${this.API_URL}/trashed/${id}`,
      {headers}
    )
  }

  deleteToeFromAdminTrash(id: number) {
    const headers = this.authService.getAuthHeaders()
    return this.http.delete<void>(
      `${this.API_URL}/trashed/admin/${id}`,
      {headers}
    )
  }

  flushTrash() {
    const headers = this.authService.getAuthHeaders()
    return this.http.delete<void>(
      `${this.API_URL}/trashed/flush`,
      {headers}
    )
  }

  restoreFromTrash(id: number) {
    const headers = this.authService.getAuthHeaders()
    return this.http.post<void>(
      `${this.API_URL}/trashed/restore/${id}`,
      {},
      {headers}
    )
  }

  restoreFromAdminTrash(id: number) {
    const headers = this.authService.getAuthHeaders()
    return this.http.post<void>(
      `${this.API_URL}/trashed/admin/restore/${id}`,
      {},
      {headers}
    )
  }

  getTpsListing(toeId: number) {
    const headers = this.authService.getAuthHeaders()
    return this.http.get<{data: {id: number, name: string}[]}>(
      `${this.API_URL}/${toeId}/target-properties-listing`,
      {headers}
    )
  }

  duplicateToe(toeId: number, selectedTps: number[]) {
    const headers = this.authService.getAuthHeaders()
    return this.http.post<{data: unknown}>(
      `${this.API_URL}/${toeId}/duplicate`,
      {selectedTps},
      {headers}
    )
  }

  moveToe(toeId: number, payload: {
    assignment: AssignmentModel
  }) {
    const headers = this.authService.getAuthHeaders()
    return this.http.post<{data: unknown}>(
      `${this.API_URL}/${toeId}/move`,
      payload,
      {headers}
    )
  }

  private applyFilter(params: HttpParams, filter: any): HttpParams {
    let _params = params;
    if (filter) {
      Object.keys(filter).forEach(key => {
        const val = filter[key];
        _params = _params.set(`filter[${key}]`, val)
      })
    }
    return _params
  }

  private applySort(params: HttpParams, sort: Sorting): HttpParams {
    return params
      .set('sortColumn', sort.column)
      .set('sortDir', sort.direction)
  }

  private applyPagination(params: HttpParams, pagination: Pagination): HttpParams {
    return params
      .set('page', pagination.page)
      .set('pageSize', pagination.pageSize)
  }
}