import { Action } from '@ngrx/store';

export enum MetaAppActionTypes {
    SaveState = '[Refresh/Reload page] Save state',
    DeleteState = '[Refresh/Reload page] Delete state',
    GetState = '[Refresh/Reload page] Get State'
}

export class SaveState implements Action {
    readonly type = MetaAppActionTypes.SaveState;
    constructor (
        public payload: {
            name: string,
        }
    ) {}
}

export class DeleteState implements Action {
    readonly type = MetaAppActionTypes.DeleteState;
    constructor () {}
}

export type MetaAppActions = SaveState | DeleteState;