import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface EditEntityDialogData {
  title: string
  configs: Config[];
}
export interface EditEntityDialogReturn {
  result: BaseField[];
}

type Type = 'text' | 'checkbox' | 'date-picker' | 'textarea' | 'selection';
type ValidationRule = 'required'
interface SelectionValue {
  id: number;
  value: string;
}

interface BaseField {
  type: Type;
  key: string;
  label: string;
  value: any;
}

interface Config extends BaseField {
  class?: string
  validationRules?: ValidationRule[]
  selectionValues?: SelectionValue[]
}

interface Field extends BaseField {
  formControl: UntypedFormControl,
  errors: any[]
}

@Component({
  selector: 'kt-edit-entity-dialog',
  templateUrl: './edit-entity-dialog.component.html',
  styleUrls: ['./edit-entity-dialog.component.scss']
})
export class EditEntityDialogComponent implements OnInit {
  fields: Field[] = [];
  constructor(
    public dialogRef: MatDialogRef<EditEntityDialogComponent, EditEntityDialogReturn>,
    @Inject(MAT_DIALOG_DATA) public data: EditEntityDialogData
  ) {
    this.fields = data.configs.map(this._convertConfigToField);
  }

  ngOnInit(): void {
  }

  onYesClick(): void {
    const [isValid, fields] = this._checkValidation(this.fields);
    if (!isValid) {
      this.fields = fields;
      return;
    }
    this.dialogRef.close({
      result: this.fields.map(f => ({
        type: f.type,
        key: f.key,
        label: f.label,
        value: f.formControl.value
      }))
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearDate(_control) {
    _control.setValue(null);
    _control.updateValueAndValidity();
  }

  private _convertConfigToField(config: Config): Field {
    const ctrl = new UntypedFormControl(config.value, config.validationRules ? ruleApplier(config.validationRules) : null);
    return {
      ...config,
      formControl: ctrl,
      errors: []
    };
  }


  private _checkValidation(fields: Field[]): [boolean, Field[]] {
    let isValid = true;
    const _fields = fields
      .map(f => {
        if (!f.formControl.valid) {
          f.formControl.markAsTouched();
          isValid = false;
          return {...f, errors: this._setErrorMessage(f.label, f.formControl.errors)};
        }
        return f;
      });
    return [isValid, _fields];
  }

  private _setErrorMessage(label: string, errors: ValidationErrors): string[] {
    const errorMessages: string[] = [];
    if (errors['required']) {
      errorMessages.push(`${label} is required`);
    }
    return errorMessages
  }
}

function ruleApplier(rules: ValidationRule[]): ValidatorFn[] {
  return rules.map(r => {
    switch (r) {
      case "required":
        return Validators.required;
      default:
        return null
    }
  })
}
