// Angular
import {Component, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, NgZone} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';

import {select, Store} from '@ngrx/store';
import {Observable, Subscription, BehaviorSubject, Subject, ReplaySubject, combineLatest, of} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import {startWith, take, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {
    AssetClassModel,
    ValuationModel,
    AssetClassDefaultAssumptionModel,
    AssetClassSpecialAssumptionModel,
    AssetClassDepartureModel,
    AssetClassSourceExternalReferenceModel,
    selectAssetClassSERByTpId
} from '../../../../../core/asset_class';
import {TypesUtilsService} from '../../../../../core/_base/crud';
import {
    AllUnitAreaMeasurementsRequested,
    selectAllUnitAreaMeasurements,
    UnitAreaMeasurement,
    PremiseOfValue,
    AllPremiseOfValuesRequested,
    selectAllPremiseOfValues,
    AssetClassType,
    AllAssetClassTypesRequested,
    selectAllAssetClassTypes,
    StandardMeasurement,
    AllStandardMeasurementsRequested,
    selectAllStandardMeasurements,
    MeasurementMethodology,
    AllMeasurementMethodologiesRequested,
    selectAllMeasurementMethodologies,
    PurposeMeasurement,
    AllPurposeMeasurementsRequested,
    selectAllPurposeMeasurements,
    DefaultAssumption,
    AllDefaultAssumptionsRequested,
    selectAllDefaultAssumptions,
    SpecialAssumption,
    AllSpecialAssumptionsRequested,
    selectAllSpecialAssumptions,
    Departure,
    AllDeparturesRequested,
    selectAllDepartures,
    AllSourceExternalReferencesRequested,
    selectAllSourceExternalReferences,
    SourceExternalReference,
    FloorNumberingScheme,
    AllFloorNumberingSchemesRequested,
    selectAllFloorNumberingSchemes,
    AllMethodsToValuesRequested,
    selectAllMethodsToValues,
    AllBaseOfValuesRequested,
    selectAllBaseOfValues,
} from '../../../../../core/linked-tables';
import {each} from 'lodash';
import {AppState} from '../../../../../core/reducers';
import {CheckboxAddComponent} from '../../../shared_components/add-checkboxes/checkbox-add.component';
import {
    CityModel,
    CountryModel,
    selectAllCountries,
    selectCitiesByCountryId,
    AllCountriesRequested,
    selectAllCities,
    AgencyModel
} from '../../../../../core/admin';
import {CountryData} from '../../../admin-management/countries/countries-list/local-data';
import {TagRequiredValidator} from '../../../validators/property.validator';
import { LocalVpgaModel } from 'src/app/core/asset_class/_models/local-vpga.model';
import { AllTopPropertyTypesRequested } from 'src/app/core/linked-tables/_actions/top-property-type.actions';
import { selectAllTopPropertyTypes } from 'src/app/core/linked-tables/_selectors/top-property-type.selectors';
import { PropertyTypes, TopPropertyTypeModel } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { environment } from 'src/environments/environment';
import { CountryEditComponent } from '../../../admin-management/countries/country-edit/country-edit.component';
import { CityEditComponent } from '../../../admin-management/countries/cities/city-edit/city-edit.component';
import { MapsAPILoader } from '@agm/core';
import { TimeZoneApiService } from 'src/app/core/g-map/timezone.api.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatepickerTzInputComponent } from '../../../mad-forms/datepicker-tz-input/datepicker-tz-input.component';

@Component({
    selector: 'kt-tp-add-dialog',
    templateUrl: './tp-add-dialog.component.html',
    styleUrls: ['./tp-add-dialog.component.scss'],
})
export class TpAddDialogComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    tp: AssetClassModel;
    viewMode: boolean;

    unitAreaMeasurements: UnitAreaMeasurement[];
    premiseOfValues: PremiseOfValue[];
    standardMeasurements: StandardMeasurement[] = [];
    measurementMethodologies: MeasurementMethodology[];
    purposeMeasurements: PurposeMeasurement[];

    topPropertyTypes: TopPropertyTypeModel[] = [];
    propertySubTypes: AssetClassType[] = [];
    filteredPropertySubTypes: AssetClassType[];
    filteredstandardMeasurements: StandardMeasurement[];
    propertyTypes = PropertyTypes;


    decisions = [
        {label: 'Desktop valuation (no inspection)', value: 1, default: true},
        {label: 'External Inspection (Drive by)', value: 2, default: false},
        {label: 'Internal inspection', value: 3, default: false},
        {label: 'Full property survey', value: 4, default: false}
    ];

    valuationsSubject = new BehaviorSubject<ValuationModel[]>([]);
    sourceExternalReferencesSubject = new BehaviorSubject<AssetClassSourceExternalReferenceModel[]>([]);
    public obs$: Observable<DefaultAssumption[]>;
    public obs1$: Observable<SpecialAssumption[]>;
    public obs2$: Observable<Departure[]>;

    assetClassDefaultAssumptions: AssetClassDefaultAssumptionModel[] = [];
    assetClassSpecialAssumptions: AssetClassSpecialAssumptionModel[] = [];
    assetClassDepartures: AssetClassDepartureModel[] = [];
    valuations: ValuationModel[] = [];

    assumptionsData: DefaultAssumption[];
    customAssumptionsData: DefaultAssumption[];
    specialAssumptionsData: SpecialAssumption[];
    customSpecialAssumption: SpecialAssumption[];
    departuresData: Departure[];
    customDeparture: Departure[];
    selectedSer: AssetClassSourceExternalReferenceModel[] = [];
    sourceExternalReferences: SourceExternalReference[] = [];

    // Countries
    // searchable selection
    @ViewChild('countryCurrency') countryCurrency: MatSelect;
    @ViewChild('reportingCurrency') reportingCurrency: MatSelect;

    /** control for the MatSelect filter keyword */
    public filterCtrlCountriesData: UntypedFormControl = new UntypedFormControl();
    public filterCtrlReportingData: UntypedFormControl = new UntypedFormControl();

    protected _onDestroy = new Subject<void>();
    /** list of models filtered by search keyword */
    public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public filteredReportsData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    floorNumberingSchemes: FloorNumberingScheme[] = [];
    agency: AgencyModel;
    allCountries: CountryModel[] = [];
    cityOfCountry: CityModel[] = [];

    countriesData = CountryData.countriesWithNoDuplicateCurrency();

    hasFormErrors = false;

    millesecondsOfOneDay = 86400000;
    valuationValidationError = false;

    radios: any[] = [
        {
            // tslint:disable-next-line: max-line-length
            label: '1 - Does this valuation include all the fixed equipment, fixtures, fittings and equipment owned by the Landlord and essential in the running or management of the property?',
            formControlName: 'included_all_equipment',
            triggerFlag: '0',
            show: 1,
            tooltip_key: 'FIXED_EQUIPMENT',
            triggerField: 'descr',
            decisions: [{label: 'Yes', value: 1, default: true}, {label: 'No', value: 0, default: false}],
            required: ['included_all_equipment_descr'],
            descr_label: 'Please specify exclusions',
        },
        {
            // tslint:disable-next-line: max-line-length
            label: '2 - Are any items, furnishings, equipment, improvements, plants or fixtures owned by the occupier or Tenant to be included in this valuation?',
            formControlName: 'included_furniture',
            triggerFlag: '1',
            show: 1,
            tooltip_key: 'BE_INCLUDED',
            triggerField: 'descr',
            decisions: [{label: 'Yes', value: 1, default: true}, {label: 'No', value: 0, default: false}],
            required: ['included_furniture_descr'],
            descr_label: 'Please specify',
        },
        {
            // tslint:disable-next-line: max-line-length
            label: '3 - Does this valuation take into consideration any natural hazards such as ground instability, mining or mineral extraction, radon gas, risk of flooding from all mechanisms including pluvial and fluvial sources or non-natural hazards such as contamination where substances are in, on or under the ground resulting from current or historic uses?',
            formControlName: 'natural_accident_consideration',
            triggerFlag: '1',
            show: 1,
            triggerField: 'descr',
            decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
            required: ['natural_accident_consideration_descr'],
            descr_label: 'Please specify',
        },
        {
            // tslint:disable-next-line: max-line-length
            label: '4 - Does this valuation take into consideration other hazardous materials present in or kept on the property, such as (but not limited to) regulated hazards, including chemicals, radioactive substances, explosive materials, waste management activities, asbestos, ozone depleting substances, oils and deleterious materials, such as building materials that degrade with age, causing structural problems, for example, high alumina cement, calcium chloride or wood-wool shuttering?',
            formControlName: 'material_accident_consideration',
            triggerFlag: '1',
            show: 1,
            triggerField: 'descr',
            decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
            required: ['material_accident_consideration_descr'],
            descr_label: 'Please specify',
        },
        {
            label: '5 - Does this valuation include Due Diligence checks?',
            formControlName: 'diligence_check',
            triggerFlag: '1',
            show: 1,
            tooltip_key: 'DUE_DILIGENCE',
            triggerField: 'descr',
            decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
            required: ['diligence_check_descr'],
            descr_label: 'Please specify',
        },
        {
            label: '6 - Type of investigation',
            formControlName: 'type_of_inspection',
            triggerFlag: '0',
            show: 1,
            triggerField: 'none',
            decisions: [
                {label: 'Desktop valuation (no inspection)', value: 1, default: true},
                {label: 'External Inspection (Drive by)', value: 2, default: false},
                {label: 'Internal inspection', value: 3, default: false},
                {label: 'Full property survey (Coming soon)', value: 4, default: false, disabled: true}
            ],
            required: [],
        },
        {
            label: '7 - Any expected limitations or restrictions on the inspection inquiry and/or analysis?',
            formControlName: 'expected_limitations_restrictions',
            triggerFlag: '1',
            show: 1,
            tooltip_key: 'ON_INSPECTION',
            triggerField: 'descr',
            decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
            required: ['limitations_and_restrictions'],
            descr_label: 'Please specify',
        },
        {
            label: '8 - Has the valuer been instructed to measure the property?',
            formControlName: 'instructed_to_measure',
            triggerFlag: '1',
            show: 1,
            triggerField: 'combos',
            decisions: [{label: 'Yes', value: 1, default: false}, {label: 'No', value: 0, default: true}],
            required: ['standard_measurement_id', 'measurement_methodology_id', 'purpose_measurement_id'],
        },
        {
            // tslint:disable-next-line: max-line-length
            label: '9 - Is the party that commissioned this valuation the intended lender of the valuation? (This question is showing because you have selected “Valuation of interests for secured lending” as valuation purpose in the ToE Form.',
            formControlName: 'intended_lender',
            triggerFlag: '0',
            show: 0,
            triggerField: 'none',
            decisions: [{label: 'Yes', value: 1, default: true}, {label: 'No', value: 0, default: false}],
            required: [],
        },
    ];

    decisionsExtra: any[] = [
        {label: 'Yes', value: 1, default: false},
        {label: 'No', value: 0, default: true},
        {label: 'I don’t know', value: 2, default: false}
    ];
    floorsValidators = [];
    atleastOneShouldHaveValue: boolean = false;
    mapFormArrayToData: number[] = [];

    isDuplicate: boolean = false;
    private _propertyType$ = new BehaviorSubject<number>(-1);
    propertyType$ = this._propertyType$.asObservable();

    public customTp$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showFloorLocation: boolean = false;
    private methodsToValue: any[] = [];
    private baseOfValues: any[] = []

    /** Map */
    timezoneOffset: string|null = null;
    latitude = undefined
    longitude = undefined
    currentCenter: {lat: number, lng: number} = {lat: 0, lng: 0}
    zoom = 15
    map: any
    markerLat: number = this.latitude
    markerLng: number = this.longitude
    mapClickListener: google.maps.MapsEventListener
    private geoCoder = new google.maps.Geocoder()
    onMapReady(event: any) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'))

        this.map.addListener('dragend', () => {
            this.latitude = this.currentCenter.lat
            this.longitude = this.currentCenter.lng
        })

        this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
            this.ngZone.run(() => {
                this.markerLat = e.latLng.lat()
                this.markerLng = e.latLng.lng()

                this.latitude = this.markerLat
                this.longitude = this.markerLng
                this.timezoneApiService.timeZoneOf({lat: this.markerLat, lng: this.markerLng}).subscribe(offset => {
                    this.timezoneOffset = offset
                })
                this.form.controls.latitude.patchValue(this.markerLat)
                this.form.controls.longitude.patchValue(this.markerLng)
            })
        }) 
    }
    onCenterChange(event: any) {
        if (event) {
            this.currentCenter = {lat: event.lat, lng: event.lng}
        }
    }
    setLocation() {

    }

    /** Valuation and Investigation Dates */
    get inspectionDateCtlr() {
        return this.form.controls.inspection_date
    }
    inspectionDate: Date | null;
    changeInspectionDate() {
        this.inspectionDate = new Date(this.form.controls.inspection_date.value);
    }
    clearInspectionDate(datePicker: DatepickerTzInputComponent) {
        datePicker.clearDate()
        this.inspectionDate = null;
        this.form.controls.inspection_date.setValue(null)
        this.form.controls.inspection_date.updateValueAndValidity()
    }
    draftDate: Date | null = null;
    changeDraftDate() {
        this.draftDate = new Date(this.form.controls.draft_date.value);
    }
    clearDraftDate(datePicker: DatepickerTzInputComponent) {
        datePicker.clearDate()
        this.draftDate = null
        this.form.controls.draft_date.setValue(null)
        this.form.controls.draft_date.updateValueAndValidity()
    }
    finalDate: Date | null = null;
    changeFinalDate() {
        this.finalDate = new Date(this.form.controls.valuation_date.value);
    }
    clearFinalDate(datePicker: DatepickerTzInputComponent) {
        datePicker.clearDate()
        this.finalDate = null
        this.form.controls.valuation_date.setValue(null)
        this.form.controls.valuation_date.updateValueAndValidity()
    }

    /**
     * Component constructor
     *
     */
    constructor(public dialogRef: MatDialogRef<TpAddDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store: Store<AppState>,
                private toeFB: UntypedFormBuilder,
                public typesUtilsService: TypesUtilsService,
                private ref: ChangeDetectorRef,
                private translate: TranslateService,
                private dialog: MatDialog,
                private ngZone: NgZone,
                private mapsAPILoader: MapsAPILoader,
                private timezoneApiService: TimeZoneApiService) {
        this.viewMode = this.data.viewMode;
        this.customAssumptionsData = [];
        this.customSpecialAssumption = [];
        this.customDeparture = [];
        this.assetClassDefaultAssumptions = [];
        this.assumptionsData = [];
        this.agency = this.data.agency;


        if (this.data && this.data.item) {
            this.tp = Object.assign({}, this.data.item) as AssetClassModel;
            this.latitude = this.tp.latitude
            this.longitude = this.tp.longitude
            this.markerLat = this.tp.latitude
            this.markerLng = this.tp.longitude
            this.customTp$.next(this.tp.top_property_type_id == PropertyTypes.Custom);
            this.assetClassDefaultAssumptions = Object.assign([], this.data.item.selected_default_assumptions);
            this.assetClassDefaultAssumptions
                .filter(el => !el.default_assumption_id)
                .forEach(el => {

                    const _tmp = new DefaultAssumption();
                    _tmp.clear();
                    _tmp.name = el.default_assumption_name;
                    _tmp.selected = true;
                    this.customAssumptionsData.push(_tmp);
                });

            this.assetClassSpecialAssumptions = Object.assign([], this.data.item.selected_special_assumptions);
            this.assetClassSpecialAssumptions
                .filter(el => !el.special_assumption_id)
                .forEach(el => {

                    const _tmp = new SpecialAssumption();
                    _tmp.clear();
                    _tmp.name = el.special_assumption_name;
                    _tmp.selected = true;
                    this.customSpecialAssumption.push(_tmp);
                });
            this.assetClassDepartures = Object.assign([], this.data.item.selected_departures);
            this.assetClassDepartures
                .filter(el => !el.departure_id)
                .forEach((el: any) => {

                    const _tmp = new Departure();
                    _tmp.clear();
                    _tmp.name = el.departure_name;
                    _tmp.valuation_standard_id = el.valuation_standard_id;
                    _tmp.valuation_standard_name = el.valuation_standard_name;
                    _tmp.selected = true;
                    this.customDeparture.push(_tmp);
                });

            this.valuations = Object.assign([], this.data.item.valuations);
            this.valuationsSubject.next(this.valuations);

            this.selectedSer = Object.assign([], this.tp.external_references);
            this.sourceExternalReferencesSubject.next(this.selectedSer)

        } else {
            this.tp = new AssetClassModel();
            this.tp.clear();
            this.tp.country_id = this.agency.country_id
            this.tp.city_id = this.agency.city_id
        }

        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder()
            this.geoCoder.geocode({address: `${this.agency.city_name}, ${this.agency.country_name}`}, (results, status) => {
                if (this.tp.latitude == undefined || this.tp.longitude === undefined) {
                    if (status && results.length > 0) {
                        this.latitude = results[0].geometry.location.lat()
                        this.longitude = results[0].geometry.location.lng()
                        this.markerLat = this.latitude
                        this.markerLng = this.longitude
                        this.timezoneApiService.timeZoneOf({lat: this.markerLat, lng: this.markerLng}).subscribe(offset => {
                            this.timezoneOffset = offset
                        })

                        this.form.controls.latitude.patchValue(this.markerLat)
                        this.form.controls.longitude.patchValue(this.markerLng)
                    }
                }
            })
        })
    }

    ngOnInit() {
        this.dialogRef.updateSize('80%', 'auto');

        this.tp.unit_of_area_measurement_id = this.data.measurement.id;
        this.tp.unit_of_area_measurement_name = this.data.measurement.name;

        this.createForm();

        this.store.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store.pipe(
            select(selectAllUnitAreaMeasurements))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.unitAreaMeasurements = [];
                if (res) {
                    this.unitAreaMeasurements = res;
                }
            });

        this.store.dispatch(new AllAssetClassTypesRequested());
        this.store.pipe(
            select(selectAllAssetClassTypes))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.propertySubTypes = [];
                if (res) {
                    this.propertySubTypes = res;
                    this.filteredPropertySubTypes = res;
                    if (this.tp.top_property_type_id && this.tp.top_property_type_id > 0) {
                        this.filterPropertySubTypes(this.tp.top_property_type_id);
                    }
                }
            });

        this.store.dispatch(new AllCountriesRequested());
        this.store.pipe(select(selectAllCountries))
            .pipe(takeUntil(this._onDestroy))
            .subscribe((res: CountryModel[]) => {
                this.allCountries = [];
                each(res, (_country: CountryModel) => {
                    this.allCountries.push(_country);
                });
            });

        this.setInitialValueCurrency();

        this.store.dispatch(new AllTopPropertyTypesRequested());
        this.store.pipe(select(selectAllTopPropertyTypes)).pipe(
            takeUntil(this._onDestroy)
        ).subscribe(res => {
            this.topPropertyTypes = [];
            if (res) {
                this.topPropertyTypes = res;
            }
        });

        this.store.dispatch(new AllFloorNumberingSchemesRequested());
        this.store.pipe(
            select(selectAllFloorNumberingSchemes),
            takeUntil(this._onDestroy)
        ).subscribe(res => {
            this.floorNumberingSchemes = res ? res : [];
        })


        this.store.dispatch(new AllBaseOfValuesRequested())
        this.store.pipe(
            select(selectAllBaseOfValues)
        ).subscribe(baseOfValues => {
            this.baseOfValues = baseOfValues
        })
        this.store.dispatch(new AllMethodsToValuesRequested());
        combineLatest([
            this.store.pipe(
            select(selectAllMethodsToValues)),
            this.customTp$
        ]).pipe(takeUntil(this._onDestroy)).subscribe(([res, customTp]) => {
                this.methodsToValue = [];
                if (res) {
                    this.methodsToValue = res;
                }
            });

    }

    // Not happy with this workaround
    get hasAnyResidual(): boolean {
        // Residual Method ID is 9
        const valuationsWithResidualMethod = this.valuationsSubject.value.filter(valuation => valuation.methods_to_value_id == 9)
        return valuationsWithResidualMethod.length != 0
    }

    onNoClick() {
        this.dialogRef.close();
    }

    getTitle() {
        if (this.tp && this.tp.id) {
            return 'Edit Target Property' + (this.viewMode ? ' (Only View)' : '');
        }
        return 'Add Target Property';
    }

    floorValidators: ValidatorFn[] = [
        (control: AbstractControl): ValidationErrors | null => {
        const value = control.value as string;
        if (value == "" || value == undefined || value == null) {
            return {error: 'Value must be provided'}
        }
        if (!isNaN(parseInt(value))) {
            return null
        }
        const splits = value.split(":")
        if (splits.length == 2) {
            if (!isNaN(parseInt(splits[0])) && !isNaN(parseInt(splits[1]))) {
            return null;
            }
        }
        return {error: 'Floor error'}
        }
    ]

    createForm() {
        this.form = this.toeFB.group({
            name: [this.tp.name, Validators.required],
            surface: [this.tp.surface, Validators.required],
            valuation_date: [{
                value: this.tp.valuation_date ? this.typesUtilsService.getDateFromString(this.tp.valuation_date) : '',
                disabled: false
            }, Validators.required],
            draft_date: [{
                value: this.tp.draft_date ? this.typesUtilsService.getDateFromString(this.tp.draft_date) : '',
                disabled: false
            }],
            inspection_date: [{
                value: this.tp.inspection_date ? this.typesUtilsService.getDateFromString(this.tp.inspection_date) : '',
                disabled: false
            }, Validators.required],
            included_in_valuation_id: [this.tp.included_in_valuation_id],
            furniture_is: [this.tp.furniture_is],
            floor_numbering_scheme_id: [this.tp.floor_numbering_scheme_id 
                ? this.tp.floor_numbering_scheme_id 
                : this.agency ? this.agency.floor_numbering_scheme_id : null, Validators.required],
            floors_valuated: [this.tp.floors_valuated && this.tp.floors_valuated.length > 0 ?
                this.tp.floors_valuated.split(',') : '',
                [TagRequiredValidator]
            ],

            percentage: [this.tp.percentage, Validators.required],
            discount: [this.tp.discount],
            discount_descr: [this.tp.discount_descr],

            ac_id: [this.tp.ac_id],
            unit_of_area_measurement_id: [{
                value: this.tp.unit_of_area_measurement_id,
                disabled: true
            }],
            country_id: [this.tp.country_id, Validators.required],
            city_id: [this.tp.city_id, Validators.required],
            zip_code: [this.tp.zip_code],
            address: [this.tp.address, Validators.required],
            latitude: [this.tp.latitude, Validators.required],
            longitude: [this.tp.longitude, Validators.required],
            target_property_type_id: [this.tp.top_property_type_id, Validators.required],
            property_type_id: [this.tp.type_id, Validators.required],
            country_currency: [this.tp.country_currency],
            reporting_currency: [this.tp.reporting_currency],
            currency_exchange_rate: [this.tp.currency_exchange_rate],
            included_all_equipment: [this.tp.included_all_equipment],
            included_all_equipment_descr: [this.tp.included_all_equipment_descr],
            included_furniture: [this.tp.included_furniture],
            included_furniture_descr: [this.tp.included_furniture_descr],
            natural_accident_consideration: [this.tp.natural_accident_consideration],
            natural_accident_consideration_descr: [this.tp.natural_accident_consideration_descr],
            material_accident_consideration: [this.tp.material_accident_consideration],
            material_accident_consideration_descr: [this.tp.material_accident_consideration_descr],
            diligence_check: [this.tp.diligence_check],
            diligence_check_descr: [this.tp.diligence_check_descr],
            type_of_inspection: [this.tp.type_of_inspection],
            expected_limitations_restrictions: [this.tp.expected_limitations_restrictions],
            limitations_and_restrictions: [this.tp.limitations_and_restrictions],
            instructed_to_measure: [this.tp.instructed_to_measure],
            standard_measurement_id: [this.tp.standard_measurement_id],
            new_standard_measurement_name: [this.tp.new_standard_measurement_name],
            measurement_methodology_id: [this.tp.measurement_methodology_id],
            purpose_measurement_id: [this.tp.purpose_measurement_id],
            intended_lender: [this.tp.intended_lender],
            specific_req_on_intended_lender: [this.tp.specific_req_on_intended_lender],
            specific_req_on_intended_lender_descr: [this.tp.specific_req_on_intended_lender_descr],
            level_consideration: this._createVpgaFormArray(this.tp.vpgas),

            assumptions: [],
            specialAssumptions: [],
            departures: [],

            // Custom Target Property fields
            custom_asset_class_name: [this.tp.custom_asset_class_name],
            custom_property_type: [this.tp.custom_property_type],
            custom_tenure: [this.tp.custom_tenure],
            custom_floor_valuated: [this.tp.custom_floor_valuated]
        });

        this.form.controls.standard_measurement_id.valueChanges.pipe(
            takeUntil(this._onDestroy)
        ).subscribe(val => {
            if (val == -1) {
                this.form.controls.new_standard_measurement_name.setValidators([Validators.required]);
            } else {
                this.form.controls.new_standard_measurement_name.clearValidators();
            }
            this.form.controls.new_standard_measurement_name.updateValueAndValidity();
        })

        // Check valid name and property sub type
        this.form.controls.name.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
            this.isDuplicate = this._canBeAdded(val, this._getTypeName(), this.data.others);
        });
        this.form.controls.property_type_id.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
            this.isDuplicate = this._canBeAdded(
                this.form.controls.name.value,
                this._getTypeName(),
                this.data.others
            );
        });
        this.form.controls.custom_property_type.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
            this.isDuplicate = this._canBeAdded(
                this.form.controls.name.value,
                this._getTypeName(),
                this.data.others
            )
        });
        this.form.controls.floors_valuated.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
            if (this.form.controls.floor_numbering_scheme_id.value == 2) {
                this._changeFloorsValueted(val);
            }
        });
        this.form.controls.floor_numbering_scheme_id.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
            if (val == 2) {
                this._changeFloorsValueted(this.form.controls.floors_valuated.value)
            }
        })


        this.radios.forEach(radio => {
            this.form.controls[radio.formControlName].valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
                if (radio.required.length > 0) {
                    radio.required.forEach(requiredFieldName => {
                        // tslint:disable-next-line: triple-equals
                        if (value == radio.triggerFlag) {
                            this.form.controls[requiredFieldName].setValidators([Validators.required]);
                        } else {
                            if (this.tp && this.tp.id) {
                                if (requiredFieldName !== 'standard_measurement_id') {
                                    this.form.controls[requiredFieldName].setValue(this.tp[requiredFieldName]);
                                }
                            } else {
                                if (requiredFieldName !== 'standard_measurement_id') {
                                    this.form.controls[requiredFieldName].setValue('');
                                }
                            }
                            this.form.controls[requiredFieldName].clearValidators();
                        }
                        this.form.controls.standard_measurement_id.setValidators([Validators.required]);
                        this.form.controls[requiredFieldName].updateValueAndValidity();
                    });
                }
            });
        });

        this.form.controls.specific_req_on_intended_lender.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
            if (value === 1) {
                this.form.controls.specific_req_on_intended_lender_descr.setValidators([Validators.required]);
            } else {
                if (this.tp && this.tp.id) {
                    this.form.controls.specific_req_on_intended_lender_descr.setValue(this.tp.specific_req_on_intended_lender_descr);
                } else {
                    this.form.controls.specific_req_on_intended_lender_descr.setValue('');
                }
                this.form.controls.specific_req_on_intended_lender_descr.clearValidators();
            }
            this.form.controls.specific_req_on_intended_lender_descr.updateValueAndValidity();
        });

        this.form.controls.intended_lender.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
            if (value === 1) {
                if (this.tp && this.tp.id) {
                    this.form.controls.specific_req_on_intended_lender.setValue(this.tp.specific_req_on_intended_lender);
                    this.form.controls.specific_req_on_intended_lender_descr.setValue(this.tp.specific_req_on_intended_lender_descr);
                } else {
                    this.form.controls.specific_req_on_intended_lender.setValue(0);
                    this.form.controls.specific_req_on_intended_lender_descr.setValue('');
                }
            }
        });

        // NOTE Here uses target property type id
        this.form.controls.target_property_type_id.valueChanges
        .pipe(
            startWith(this.form.controls.target_property_type_id.value),
            takeUntil(this._onDestroy)
        )
        .subscribe(acId => {
            if (acId && acId > 0) {
                this.filterPropertySubTypes(acId);
            }
            if (acId && (acId == PropertyTypes.Land || acId == PropertyTypes.Custom || acId == PropertyTypes.Industrial)) {
                this.showFloorLocation = false;
                this.form.controls.floors_valuated.clearValidators();
            } else {
                this.showFloorLocation = true;
                this.form.controls.floors_valuated.setValidators([TagRequiredValidator])
            }
            this.form.controls.floors_valuated.updateValueAndValidity();
            if (acId && acId == PropertyTypes.Custom) {
                this.form.controls.property_type_id.clearValidators();
            } else {
                this.form.controls.property_type_id.setValidators([Validators.required]);
            }
            this.customTp$.next(acId && acId == PropertyTypes.Custom);

            if (acId == PropertyTypes.Custom) {
                this.filterStandardMeasurements(acId, this.form.controls.property_type_id.value);
            }

            this._propertyType$.next(acId);
        });

        this.form.controls.property_type_id.valueChanges
        .pipe(
            startWith(this.form.controls.property_type_id.value),
            takeUntil(this._onDestroy))
        .subscribe(subTypeID => {
            if (subTypeID && subTypeID > 0)  {
                this.filterStandardMeasurements(this.form.controls.target_property_type_id.value, subTypeID);

                if (subTypeID == 17 || subTypeID == 11 || subTypeID == 13 || subTypeID == 7) {
                    this.showFloorLocation = false;
                    this.form.controls.floors_valuated.clearValidators();
                } else if (subTypeID == 2 || subTypeID == 5) {
                    this.showFloorLocation = true;
                    this.form.controls.floors_valuated.clearValidators();
                } else {
                    this.showFloorLocation = true;
                    this.form.controls.floors_valuated.setValidators([TagRequiredValidator])
                }
                this.form.controls.floors_valuated.updateValueAndValidity();
            }
        });

        this.form.controls.percentage.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(percentage => {
            if (percentage < 100) {
                this.form.controls.discount_descr.setValidators([Validators.required]);
            } else {
                this.form.controls.discount_descr.clearValidators();
            }
            this.form.controls.discount_descr.updateValueAndValidity();
        });


        this.fetchSelects();

        if (this.tp.id) {
            this.store.pipe(select(selectCitiesByCountryId(this.tp.country_id)), takeUntil(this._onDestroy)).subscribe((res: CityModel[]) => {
                this.cityOfCountry = res;
            });
        } else {
            if (this.tp.country_id) {
                this.store.pipe(select(selectCitiesByCountryId(this.tp.country_id)), takeUntil(this._onDestroy)).subscribe((res: CityModel[]) => {
                    this.cityOfCountry = res;
                });
            }
        }
        this.customTp$.pipe(takeUntil(this._onDestroy)).subscribe(isCustomTP => {
            let investigationDecision = null
            if (isCustomTP) {
                this.form.controls.custom_asset_class_name.setValidators([Validators.required]);
                this.form.controls.custom_property_type.setValidators([Validators.required]);
                this.form.controls.floors_valuated.clearValidators();
                investigationDecision = {
                    label: 'Full property survey',
                    value: 4,
                    default: false,
                    disabled: false
                }
            } else {
                this.form.controls.custom_asset_class_name.clearValidators();
                this.form.controls.custom_property_type.clearValidators();
                investigationDecision = {
                    label: 'Full property survey (Coming soon)',
                    value: 4,
                    default: false,
                    disabled: true
                }
            }
            this.form.controls.custom_asset_class_name.updateValueAndValidity();
            this.form.controls.custom_property_type.updateValueAndValidity();
            this.form.controls.floors_valuated.updateValueAndValidity();
            const investigationRadioIndx = this.radios.findIndex(r => r.label == '6 - Type of investigation');
            const investigationRadio = investigationRadioIndx > -1 && this.radios[investigationRadioIndx];
            const fullSurveyIndx = investigationRadio && investigationRadio.decisions.findIndex(d => d.value == 4);
            fullSurveyIndx && fullSurveyIndx > -1 && investigationRadio.decisions.splice(fullSurveyIndx, 1, investigationDecision);
            investigationRadioIndx > -1 && investigationRadio && this.radios.splice(investigationRadioIndx, 1, investigationRadio);
        });

        this.form.controls.country_id.valueChanges.subscribe((val) => {
            this.selectCountry()
        })
    }

    private _changeFloorsValueted(val: any) {
        if ((typeof val) != 'string') {
            const _val = val.map(v => {
                const splits = v.split(':');
                if (splits.length == 2 && splits[0] == '0') {
                    return {
                        value: `1-${splits[1]}`,
                        update: true
                    };
                } else if (splits.length == 1 && splits[0] == '0') {
                    return {
                        value: null,
                        update: true
                    }
                }
                return {
                    value: v,
                    update: false
                }
            });
            const shouldUpdate = _val.find(v => v.update);
            if (shouldUpdate) {
                this.form.controls.floors_valuated.setValue(_val.filter(v => v.value != null).map(v => v.value));
                this.form.controls.floors_valuated.updateValueAndValidity();
            }
        }
    }

    filterPropertySubTypes(typeID) {
        if (typeID == PropertyTypes.Custom) {
            this.filteredPropertySubTypes = [];
        } else {
            this.filteredPropertySubTypes = this.propertySubTypes.filter(t => t.top_property_type_id == typeID && t.hide_flag == environment.zero);
        }
    }

    filterStandardMeasurements(topPropertyTypeId, propertySubTypeId) {
        if (topPropertyTypeId == PropertyTypes.Custom) {
            this.filteredstandardMeasurements = this.standardMeasurements;
        } else {
            this.filteredstandardMeasurements = this.standardMeasurements.filter(propertyType => propertyType.asset_class_type_id === propertySubTypeId);

            // if (this.form.controls.standard_measurement_id.value != null 
            //     // && !this.filteredstandardMeasurements.some(element => element.id === this.form.controls.standard_measurement_id.value)
            //     ) {
            //     this.form.controls.standard_measurement_id.setValue('');
            // }
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    isValid() {
        return this.form.valid;
    }

    _createVpgaFormArray(vpgas: LocalVpgaModel[]): UntypedFormArray {
        const formArray = this.toeFB.array([]);
        vpgas.forEach(vpga => {
            const control = this.toeFB.control(vpga.vpga);
            formArray.push(control);
            this.mapFormArrayToData.push(vpga.id);
        })
        return formArray;
    }

    get vpgaFormArray(): UntypedFormArray {
        const formArray = this.form.get('level_consideration') as UntypedFormArray;
        return formArray;
    }

    addVPGAMatterField() {
        const control = this.toeFB.control('');
        this.vpgaFormArray.push(control);
        this.mapFormArrayToData.push(null);
    }

    removeVPGAMatterField(indx: number) {
        this.vpgaFormArray.removeAt(indx);
    }

    _getVpgaValue(): LocalVpgaModel[] {
        let vals: LocalVpgaModel[] = [];
        this.vpgaFormArray.controls.forEach((control, i) => {
            const _new = new LocalVpgaModel();
            if (this.mapFormArrayToData[i]) {
                _new.id = this.mapFormArrayToData[i];
            }
            _new.vpga = control.value;
            vals.push(_new);
        })
        return vals;
    }

    fetchSelects() {
        this.store.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store.pipe(
            select(selectAllUnitAreaMeasurements))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.unitAreaMeasurements = [];
                if (res) {
                    this.unitAreaMeasurements = res;
                }
            });

        this.store.dispatch(new AllPremiseOfValuesRequested());
        this.store.pipe(
            select(selectAllPremiseOfValues))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.premiseOfValues = [];
                if (res) {
                    this.premiseOfValues = res;
                }
            });


        this.store.dispatch(new AllStandardMeasurementsRequested());
        this.store.pipe(
            select(selectAllStandardMeasurements))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.standardMeasurements = [];
                if (res) {
                    this.standardMeasurements = res;
                    this.filteredstandardMeasurements = res;
                    if (this.tp.type_id && this.tp.type_id > 0) {
                        this.filterStandardMeasurements(this.tp.top_property_type_id, this.tp.type_id);
                    }
                }
            });

        this.store.dispatch(new AllMeasurementMethodologiesRequested());
        this.store.pipe(
            select(selectAllMeasurementMethodologies))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.measurementMethodologies = [];
                if (res) {
                    this.measurementMethodologies = res;
                }
            });

        this.store.dispatch(new AllPurposeMeasurementsRequested());
        this.store.pipe(
            select(selectAllPurposeMeasurements))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.purposeMeasurements = [];
                if (res) {
                    this.purposeMeasurements = res;
                }
            });

        this.store.dispatch(new AllDefaultAssumptionsRequested());
        this.store.pipe(
            select(selectAllDefaultAssumptions))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.assumptionsData = Object.assign([], this.customAssumptionsData);
                if (res) {
                    this.assumptionsData = Object.assign([], res) as DefaultAssumption[];

                    this.assumptionsData = this.assumptionsData.map((item) => {
                        // item.id && this.assetClassDefaultAssumptions.some(element => element.default_assumption_id === item.id)
                        if (this.assetClassDefaultAssumptions.some(element => element.default_assumption_id === item.id)) {
                            const temp = Object.assign({}, item);
                            temp.selected = true;
                            return temp;
                        } else {
                            const temp = Object.assign({}, item);
                            temp.selected = false;
                            return temp;
                        }
                    });
                    this.assumptionsData = this.assumptionsData.concat(this.customAssumptionsData);

                    this.ref.detectChanges();
                }
            });


        this.store.dispatch(new AllSpecialAssumptionsRequested());
        this.store.pipe(
            select(selectAllSpecialAssumptions))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.specialAssumptionsData = this.customSpecialAssumption;
                if (res) {
                    this.specialAssumptionsData = Object.assign([], res) as SpecialAssumption[];
                    this.specialAssumptionsData = this.specialAssumptionsData.map((item) => {
                        if (this.assetClassSpecialAssumptions.some(element => element.special_assumption_id === item.id)) {
                            const temp = Object.assign({}, item);
                            temp.selected = true;
                            return temp;
                        } else {
                            const temp = Object.assign({}, item);
                            temp.selected = false;
                            return temp;
                        }
                    });
                    this.specialAssumptionsData = this.specialAssumptionsData.concat(this.customSpecialAssumption);
                    this.ref.detectChanges();
                }
            });

        this.store.dispatch(new AllDeparturesRequested());
        this.store.pipe(
            select(selectAllDepartures))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.departuresData = [];
                if (res) {
                    this.departuresData = res;
                    const _noneDeparture = new Departure();
                    _noneDeparture.clear();
                    _noneDeparture.selected;
                    _noneDeparture.name = 'None';
                    this.departuresData = [_noneDeparture].concat(Object.assign([], res) as Departure[]);
                    this.departuresData = this.departuresData.map((item) => {
                        if (this.assetClassDepartures.some(element => element.departure_id === item.id)) {
                            const temp = Object.assign({}, item);
                            temp.selected = true;
                            return temp;
                        } else {
                            const temp = Object.assign({}, item);
                            temp.selected = false;
                            return temp;
                        }
                    });
                    if (this.customDeparture.length == 1 && this.customDeparture[0].name.toLowerCase() === 'none') {
                        this.departuresData = this.departuresData.map((item) => {
                            if (item.name.toLowerCase() === 'none') {
                                const temp = Object.assign({}, item);
                                temp.selected = true;
                                return temp;
                            } else {
                                return item;
                            }
                        });
                    } else {
                        this.departuresData = this.departuresData.concat(this.customDeparture);
                    }
                    this.ref.detectChanges();
                }
            });
        // fetch sourceExternalReferences part
        this.store.dispatch(new AllSourceExternalReferencesRequested());

        this.store.pipe(
            select(selectAllSourceExternalReferences))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.sourceExternalReferences = [];
                if (res) {
                    this.sourceExternalReferences = res.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                    this.sourceExternalReferences = this.sourceExternalReferences.map((item) => {
                        if (this.selectedSer.some(element => element.source_external_reference_id === item.id)) {
                            const temp = Object.assign({}, item);
                            temp.selected = true;
                            return temp;
                        } else {
                            return item;
                        }
                    });
                    this.ref.detectChanges();
                }
            });

        this.filterCtrlCountriesData.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterCountryCurrenciesData();
            });

        this.filterCtrlReportingData.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterReportingCurrenciesData();
            });
    }


    onSubmit(isComplete: boolean) {
        this.hasFormErrors = false;
        this.valuationValidationError = false;
        const controls = this.form.controls;
        this.changeValidator(isComplete);
        this.fetchSelectedData();
        if (this.isDuplicate) {
            return;
        }
        /** check form */
        if (isComplete && !this.form.valid) {
            Object.keys(controls).forEach(controlName => {
                controls[controlName].markAsTouched();
            } 
            );

            this.hasFormErrors = true;
            return;
        }
        if (isComplete) {
            if (this.valuationsSubject.value.length < 1) {
                this.hasFormErrors = true;
                return;
            }
            if (this.checkValidationOfValuations(this.valuationsSubject.value)) {
                this.hasFormErrors = true;
                this.valuationValidationError = true;
                return;
            }
            if (this.assetClassDefaultAssumptions.length < 1) {
                this.hasFormErrors = true;
                return;
            }
            if (this.assetClassSpecialAssumptions.length < 1) {
                this.hasFormErrors = true;
                return;
            }
            // if (this.vpgaFormArray.controls.length === 0) {
            //     this.hasFormErrors = true;
            //     return;
            // } else {
            //     let atLeastOneHasValue = false;
            //     this.vpgaFormArray.controls.forEach(c => {
            //         if (c.value !== '') {
            //             atLeastOneHasValue = true;
            //         }
            //     })
            //     if (!atLeastOneHasValue) {
            //         this.hasFormErrors = true;
            //         this.atleastOneShouldHaveValue = true;
            //         return;
            //     }
            // }
        }

        this.tp.prev_name = this.tp.name;
        this.tp.name = this.form.controls.name.value;
        this.tp.surface = this.form.controls.surface.value;
        this.tp.unit_of_area_measurement_id = this.form.controls.unit_of_area_measurement_id.value;
        if (this.tp.unit_of_area_measurement_id) {
            this.tp.unit_of_area_measurement_name = this.unitAreaMeasurements
                .find(el => el.id == this.tp.unit_of_area_measurement_id)
                .name;
        }
        // NOTE Here uses target property type id
        this.tp.top_property_type_id = this.form.controls.target_property_type_id.value;
        this.tp.type_id = this.form.controls.property_type_id.value;
        if (this.tp.type_id) {
            const tpType = this.propertySubTypes.find(el => el.id == this.tp.type_id);
            this.tp.type_name = tpType && this.tp.top_property_type_id != PropertyTypes.Custom
                ? tpType.name
                : this.form.controls.custom_property_type.value
                    ? this.form.controls.custom_property_type.value
                    : this.form.controls.custom_asset_class_name.value
            // this.tp.property_type_id = this.form.controls.property_type_id.value;
        } else {
            this.tp.type_name = this.form.controls.custom_property_type.value 
                ? this.form.controls.custom_property_type.value
                : this.form.controls.custom_asset_class_name.value;
        }

        this.tp.is_complete = Number(isComplete);

        const _inspectionDate = new Date(this.form.controls.inspection_date.value);
        if (this.form.controls.inspection_date.value) {
            this.tp.inspection_date = this.typesUtilsService.dateFormat(_inspectionDate);
        } else {
            this.tp.inspection_date = null;
        }
        const _draftDate = new Date(this.form.controls.draft_date.value);
        if (this.form.controls.draft_date.value) {
            this.tp.draft_date = this.typesUtilsService.dateFormat(_draftDate);
        } else {
            this.tp.draft_date = null;
        }
        const _valuationDate = new Date(this.form.controls.valuation_date.value);
        if (this.form.controls.valuation_date.value) {
            this.tp.valuation_date = this.typesUtilsService.dateFormat(_valuationDate);
        } else {
            this.tp.valuation_date = null;
        }
        this.tp.country_id = this.form.controls.country_id.value;
        this.tp.city_id = this.form.controls.city_id.value;
        this.tp.zip_code = this.form.controls.zip_code.value;
        this.tp.address = this.form.controls.address.value;
        this.tp.latitude = this.form.controls.latitude.value;
        this.tp.longitude = this.form.controls.longitude.value;
        this.tp.timezone_offset = this.timezoneOffset;

        this.tp.country_currency = this.form.controls.country_currency.value;
        this.tp.reporting_currency = this.form.controls.reporting_currency.value;
        this.tp.currency_exchange_rate = this.form.controls.currency_exchange_rate.value;
        if (!this.currencyDifferent()) {
            this.tp.currency_exchange_rate = '';
        }

        this.tp.floor_numbering_scheme_id = this.form.controls.floor_numbering_scheme_id.value;
        this.tp.floors_valuated = this.form.controls.floors_valuated.value.length > 0 ?
            this.form.controls.floors_valuated.value.join(',') : '';

        // Freaking new change
        this.tp.percentage = this.form.controls.percentage.value;
        this.tp.discount = this.form.controls.discount.value;
        this.tp.discount_descr = this.form.controls.discount_descr.value;
        const firstVal = this.valuationsSubject.value.length >= 1 ? this.valuationsSubject.value[0] : null;
        if (firstVal) {
            this.tp.premise_of_value_id = firstVal.premise_of_value_id;
            this.tp.tenure_id = firstVal.tenure_id;
            this.tp.custom_tenure = firstVal.tenure_custom;
        }

        this.tp.measurement_methodology_id = this.form.controls.measurement_methodology_id.value;
        this.tp.standard_measurement_id = this.form.controls.standard_measurement_id.value;
        this.tp.new_standard_measurement_name = this.form.controls.new_standard_measurement_name.value;
        this.tp.purpose_measurement_id = this.form.controls.purpose_measurement_id.value;

        this.tp.included_all_equipment = this.form.controls.included_all_equipment.value;
        this.tp.included_all_equipment_descr = this.form.controls.included_all_equipment_descr.value;
        this.tp.included_furniture = this.form.controls.included_furniture.value;
        this.tp.included_furniture_descr = this.form.controls.included_furniture_descr.value;
        this.tp.natural_accident_consideration = this.form.controls.natural_accident_consideration.value;
        this.tp.natural_accident_consideration_descr = this.form.controls.natural_accident_consideration_descr.value;
        this.tp.material_accident_consideration = this.form.controls.material_accident_consideration.value;
        this.tp.material_accident_consideration_descr = this.form.controls.material_accident_consideration_descr.value;
        this.tp.diligence_check = this.form.controls.diligence_check.value;
        this.tp.diligence_check_descr = this.form.controls.diligence_check_descr.value;
        this.tp.type_of_inspection = this.form.controls.type_of_inspection.value;
        this.tp.expected_limitations_restrictions = this.form.controls.expected_limitations_restrictions.value;
        this.tp.limitations_and_restrictions = this.form.controls.limitations_and_restrictions.value;
        this.tp.instructed_to_measure = this.form.controls.instructed_to_measure.value;
        this.tp.intended_lender = this.form.controls.intended_lender.value;
        this.tp.specific_req_on_intended_lender = this.form.controls.specific_req_on_intended_lender.value;
        this.tp.specific_req_on_intended_lender_descr = this.form.controls.specific_req_on_intended_lender_descr.value;
        // this.tp.level_consideration = this.form.controls.level_consideration.value;
        this.tp.level_consideration = 'this._getVpgaValue()';
        this.tp.vpgas = this._getVpgaValue();

        this.tp._isEditMode = true;

        let valuations = this.valuationsSubject.value;
        valuations = valuations.map(valuation => {
            const temp = Object.assign({}, valuation) as ValuationModel;
            temp.asset_class_name = this.tp.name;
            temp.surface = this.tp.surface;
            temp.inspection_type = this.tp.type_of_inspection
            temp.asset_class_type_id = this.tp.type_id;
            const mtv = this.methodsToValue.find(mtv => mtv.id == temp.methods_to_value_id)
            temp.methods_to_value_name = mtv ? mtv.name : '';
            const baseOfValue = this.baseOfValues.find(bov => bov.id == temp.base_of_value_id)
            temp.base_of_value_name = baseOfValue ? baseOfValue.name : ''
            temp.property_type_name = 
                this.tp.top_property_type_id == PropertyTypes.Custom
                    ? this.tp.custom_property_type ? this.tp.custom_property_type : this.tp.custom_asset_class_name
                    : this.getAssetClassType(this.tp.type_id);
            return temp;
        });
        this.valuationsSubject.next(valuations);

        this.tp.valuations = this.valuationsSubject.value;
        this.tp.selected_default_assumptions = this.assetClassDefaultAssumptions;
        this.tp.selected_special_assumptions = this.assetClassSpecialAssumptions;
        this.tp.selected_departures = this.assetClassDepartures;
        this.tp.external_references = this.sourceExternalReferencesSubject.value;

        this.tp.custom_asset_class_name = this.form.controls.custom_asset_class_name.value;
        this.tp.custom_floor_valuated = this.form.controls.custom_floor_valuated.value;
        this.tp.custom_property_type = this.form.controls.custom_property_type.value;

        this.dialogRef.close(this.tp);
    }

    updateDefaultAssumptionCheckedOptions(_index, $event) {

        if ($event.checked) {
            this.toggleNoneNew('assumption', _index)
        }
        const _tmpArray = Object.assign([], this.assumptionsData);
        _tmpArray[_index].selected = $event.checked;
        this.assumptionsData = _tmpArray;


    }

    updateSpecialAssumptionCheckedOptions(_index, $event) {
        if ($event.checked) {
            this.toggleNoneNew('specialAssumption', _index)
        }
        const _tmpArray = Object.assign([], this.specialAssumptionsData);
        _tmpArray[_index].selected = $event.checked;
        this.specialAssumptionsData = _tmpArray;
    }

    updateDepartureCheckedOptions(_index, $event) {
        if ($event.checked) {
            this.toggleNoneNew('departure', _index)
        }
        const _tmpArray = Object.assign([], this.departuresData);
        _tmpArray[_index].selected = $event.checked;
        this.departuresData = _tmpArray;
    }

    changeSer(event, s) {
        if (event.checked) {
            if (s.name.toLowerCase() === 'none') {
                this.selectedSer = [];
            } else {
                const index = this.selectedSer.findIndex(element => this.sourceExternalReferences
                    .find(ser => ser.id === element.source_external_reference_id)
                    .name.toLowerCase() === 'none');
                if (index !== -1) {
                    this.selectedSer.splice(index, 1);
                }
            }

            const item = new AssetClassSourceExternalReferenceModel();
            item.source_external_reference_id = s.id;
            this.selectedSer = [...this.selectedSer, item];

            this.sourceExternalReferences = this.toggleNone('externalReference');
        } else {
            this.selectedSer = this.selectedSer.filter((item) => {
                return item.source_external_reference_id != s.id;
            });
        }
    }

    setSourceExternalReference(item): boolean {
        if (this.selectedSer.length <= 0) {
            return false;
        } else {
            return this.selectedSer.some(element => element.source_external_reference_id === item.id);
        }
    }

    private changeValidator(isComplete: boolean = false) {
        if (isComplete) {
            this.form.controls.name.setValidators([Validators.required]);
            this.form.controls.surface.setValidators([Validators.required]);
            this.form.controls.valuation_date.setValidators([Validators.required]);
            this.form.controls.inspection_date.setValidators([Validators.required]);
            this.form.controls.unit_of_area_measurement_id.setValidators([Validators.required]);
            this.form.controls.percentage.setValidators([Validators.required]);
            // this.form.controls.floors_valuated.setValidators([TagRequiredValidator]);
            this.form.controls.target_property_type_id.setValidators([Validators.required]);
            this.form.controls.country_currency.setValidators([Validators.required]);
            this.form.controls.reporting_currency.setValidators([Validators.required]);
            if (this.currencyDifferent()) {
                this.form.controls.currency_exchange_rate.setValidators([Validators.required]);
            }
            if (this.form.controls.target_property_type_id && this.form.controls.target_property_type_id.value != PropertyTypes.Custom) {
                this.form.controls.property_type_id.setValidators([Validators.required]);
            }
            if (this.assetClassDefaultAssumptions.length < 1) {
                this.hasFormErrors = true;
            }
            if (this.assetClassSpecialAssumptions.length < 1) {
                this.hasFormErrors = true;
            }
            this.form.controls.address.setValidators([Validators.required]);
            this.form.controls.country_id.setValidators([Validators.required]);
            this.form.controls.city_id.setValidators([Validators.required]);
        } else {
            this.form.controls.name.clearValidators();
            this.form.controls.surface.clearValidators();
            this.form.controls.valuation_date.clearValidators();
            this.form.controls.inspection_date.clearValidators();
            this.form.controls.unit_of_area_measurement_id.clearValidators();
            this.form.controls.floors_valuated.clearValidators();
            this.form.controls.percentage.clearValidators();
            this.form.controls.target_property_type_id.clearValidators();
            this.form.controls.country_currency.clearValidators();
            this.form.controls.reporting_currency.clearValidators();
            this.form.controls.currency_exchange_rate.clearValidators();
            this.form.controls.property_type_id.clearValidators();
            this.form.controls.included_all_equipment_descr.clearValidators();
            this.form.controls.included_furniture_descr.clearValidators();
            this.form.controls.natural_accident_consideration_descr.clearValidators();
            this.form.controls.material_accident_consideration_descr.clearValidators();
            this.form.controls.diligence_check_descr.clearValidators();
            this.form.controls.specific_req_on_intended_lender_descr.clearValidators();
            this.form.controls.address.clearValidators();
            this.form.controls.country_id.clearValidators();
            this.form.controls.city_id.clearValidators();
        }
        Object.keys(this.form.controls).forEach(controlName => {
            // if (controlName != 'standard_measurement_id') {
            this.form.controls[controlName].updateValueAndValidity();
            // }
        });
    }

    addCheckbox(type) {
        const dialogRef = this.dialog.open(CheckboxAddComponent, {
            data: {
                typeOfCheckbox: type
            },
            minWidth: '340px',
            width: '40%',
            position: {top: '50px', right: '50px'}
        });
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe((res) => {
                if (res) {
                    let _tmp = null;
                    const _new = null;
                    switch (type) {
                        case 'assumption':
                            this.toggleNoneNew('assumption', 1);
                            _tmp = Object.assign([], this.assumptionsData);
                            _tmp.push(res.item);
                            this.assumptionsData = _tmp;
                            break;
                        case 'specialAssumption':
                            this.toggleNoneNew('specialAssumption', 1);
                            _tmp = Object.assign([], this.specialAssumptionsData);
                            _tmp.push(res.item);
                            this.specialAssumptionsData = _tmp;
                            break;
                        case 'departure':
                            this.toggleNoneNew('departure', 1)
                            _tmp = Object.assign([], this.departuresData);
                            _tmp.push(res.item);
                            this.departuresData = _tmp;
                            break;
                        default:
                            break;
                    }

                }
            }
        );
    }

    /**
     * Close Alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    toggleNoneNew(sectionName, _index) {
        if (sectionName === 'assumption' &&
            (this.assumptionsData[_index].name.toLowerCase() === 'none' ||
                this.assumptionsData
                    .filter(el => el.selected && el.name.toLowerCase() === 'none')
                    .length)

        ) {
            this.assumptionsData = this.assumptionsData.map(el => {
                el.selected = false;
                return el;
            });
            return
        }

        if (sectionName === 'specialAssumption' &&
            (this.specialAssumptionsData[_index].name.toLowerCase() === 'none' ||
                this.specialAssumptionsData
                    .filter(el => el.selected && el.name.toLowerCase() === 'none')
                    .length)

        ) {
            this.specialAssumptionsData = this.specialAssumptionsData.map(el => {
                el.selected = false;
                return el;
            });
            return;
        }

        if (sectionName === 'departure' &&
            (this.departuresData[_index].name.toLowerCase() === 'none' ||
                this.departuresData
                    .filter(el => el.selected && el.name.toLowerCase() === 'none')
                    .length)

        ) {
            this.departuresData = this.departuresData.map(el => {
                el.selected = false;
                return el;
            });
            return;
        }
    }

    toggleNone(sectionName) {
        let tempArray = [];
        let tempSelectedArray = [];
        let idName = '';
        if (sectionName === 'externalReference') {
            tempArray = this.sourceExternalReferences;
            tempSelectedArray = this.selectedSer;
            idName = 'source_external_reference_id';
        }

        tempArray = tempArray.map((item) => {
            if (tempSelectedArray.some(element => element[idName] === item.id)) {
                const temp = Object.assign({}, item);
                temp.selected = true;
                return temp;
            } else {
                const temp = Object.assign({}, item);
                temp.selected = false;
                return temp;
            }
        });

        return tempArray;
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }

    /**
     * Sets the initial value after the filteredSelectionModels are loaded initially
     */
    protected setInitialValueCurrency() {
        this.filteredCountriesData.next(this.countriesData.slice());
        this.filteredReportsData.next(this.countriesData.slice());

        this.filteredCountriesData
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.countryCurrency.compareWith = (a: string, b: string) => {
                    return a === b;
                };
            });
        this.filteredReportsData
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.reportingCurrency.compareWith = (a: string, b: string) => {
                    return a === b;
                };
            });
    }

    protected filterCountryCurrenciesData() {
        if (!this.countriesData) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrlCountriesData.value;
        if (!search || search === '') {
            this.filteredCountriesData.next(this.countriesData.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        this.filteredCountriesData.next(
            this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterReportingCurrenciesData() {
        if (!this.countriesData) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrlReportingData.value;
        if (!search || search === '') {
            this.filteredReportsData.next(this.countriesData.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        this.filteredReportsData.next(
            this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
        );
    }

    currencyDifferent() {
        return  this.form.controls.country_currency.value != this.form.controls.reporting_currency.value;

    }

    selectCountry() {
        const countryId = this.form.controls.country_id.value;
        this.store.pipe(select(selectCitiesByCountryId(countryId)), takeUntil(this._onDestroy)).subscribe((res: CityModel[]) => {
            this.cityOfCountry = res;
        });
        if (this.allCountries.length > 0) {
            const country = this.allCountries.find(country => country.id === countryId)
            if (!country) {
                return
            }
            const countryDefaultCurrency = country.currency;

            this.form.controls.country_currency.patchValue(countryDefaultCurrency);
            this.form.controls.reporting_currency.patchValue(countryDefaultCurrency);
        }
    }

    selectCurrency() {
        if (!this.currencyDifferent()) {
            this.form.controls.currency_exchange_rate.setValue('');
        }
    }

    getPrevDate(date: Date): Date {
        if (date) {
            // return new Date(date.getTime() - this.millesecondsOfOneDay);
            return new Date(date.getTime());
        }
        return null;
    }

    getNextDate(date: Date): Date {
        if (date) {
            // return new Date(date.getTime() + this.millesecondsOfOneDay);
            return new Date(date.getTime())
        }
        return null;
    }

    public getAssetClassType(id: number): string {
        const acType = this.propertySubTypes && this.propertySubTypes.find(ac => ac.id == id);
        return acType ? acType.name : '';
    }

    public getTopPropertyTypeName(id: number): string {
        const acType = this.topPropertyTypes && this.topPropertyTypes.find(ac => ac.id == id);
        return acType ? acType.title : '';
    }

    private fetchSelectedData() {


        var _tmpArray = [];
        this.assumptionsData
            .filter(el => el.selected)
            .forEach(el => {
                const _new = new AssetClassDefaultAssumptionModel();
                _new.clear();
                const _oldData = this.assetClassDefaultAssumptions
                    .filter(_item =>
                        el.id ? _item.default_assumption_id === el.id :
                            _item.default_assumption_name === el.name);

                if (_oldData.length) {
                    _new.id = _oldData[0].id;
                }
                _new.default_assumption_name = el.name;
                _new.default_assumption_id = el.id;
                _tmpArray.push(_new)
            });
        this.assetClassDefaultAssumptions = _tmpArray;

        _tmpArray = [];
        this.specialAssumptionsData
            .filter(el => el.selected)
            .forEach(el => {
                const _new = new AssetClassSpecialAssumptionModel();
                _new.clear();
                const _oldData = this.assetClassSpecialAssumptions
                    .filter(_item =>
                        el.id ? _item.special_assumption_id === el.id :
                            _item.special_assumption_name === el.name);

                if (_oldData.length) {
                    _new.id = _oldData[0].id;
                }
                _new.special_assumption_name = el.name;
                _new.special_assumption_id = el.id;
                _tmpArray.push(_new)
            });
        this.assetClassSpecialAssumptions = _tmpArray;


        _tmpArray = [];
        this.departuresData
            .filter(el => el.selected)
            .forEach(el => {
                const _new = new AssetClassDepartureModel();
                _new.clear();
                const _oldData = this.assetClassDepartures
                    .filter(_item =>
                        el.id ? _item.departure_id === el.id :
                            _item.departure_name === el.name);

                if (_oldData.length) {
                    _new.id = _oldData[0].id;
                }
                _new.departure_name = el.name;
                _new.valuation_standard_id = el.valuation_standard_id;
                _new.departure_id = el.id;
                _tmpArray.push(_new)
            });
        this.assetClassDepartures = _tmpArray;

    }

    private _canBeAdded(name: string, type_name: string, others: any[]): boolean {
        if (others.length == 0) {
            return false;
        }
        const tp = others.find(_tp => {
            if (name != null && _tp.name == name.trim() && _tp.type_name == type_name ) {
                return true;
            }
            return false;
        })
        return tp ? true : false;
    }

    private _getTypeName(): string {
        const propertyTypeID = this.form.controls.target_property_type_id.value;
        const propertySubTypeID = this.form.controls.property_type_id.value;
        if (propertySubTypeID) {
            const tpType = this.propertySubTypes.find(el => el.id == propertySubTypeID);
            return tpType && propertyTypeID != PropertyTypes.Custom
                ? tpType.name
                : this.form.controls.custom_property_type.value
                    ? this.form.controls.custom_property_type.value
                    : this.form.controls.custom_asset_class_name.value
        } else {
            return this.form.controls.custom_property_type.value
                ? this.form.controls.custom_property_type.value
                : this.form.controls.custom_asset_class_name.value;
        } 
    }

    private checkValidationOfValuations(valuations: ValuationModel[]): boolean {
        const checkValidation = (val: ValuationModel): boolean => {
            if (!val.base_of_value_id) {
                return true;
            }
            if (!val.premise_of_value_id) {
                return true;
            }
            if (!val.approaches_to_value_id) {
                return true;
            }
            if (!val.methods_to_value_id) {
                return true;
            }
            if (!val.tenure_id && !val.tenure_custom) {
                return true;
            }
            if (val.base_of_value_id == 2 && val.tenure_id != 1) {
                return true;
            }
            return false;
        }

        const val = valuations.find(checkValidation)
        if (val) {
            return true;
        }
        return false;
    }

    public addCountry() {
        const dialogRef = this.dialog.open(CountryEditComponent, { data: { id: undefined } });
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(res => {
            if (!res) {
                return;
            }
            this.form.controls.country_id.setValue(res.id);
            this.form.controls.country_id.updateValueAndValidity();
        })
    }

    public addCity() {
        const newCity = new CityModel();
        newCity.clear();
        newCity.country_id = this.form.controls.country_id.value;
        const country = this.allCountries.find(c => c.id == newCity.country_id);
        newCity.country_name = country ? country.name : null;
        const dialogRef = this.dialog.open(CityEditComponent, { data: { city: newCity } });
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(res => {
            if (!res) {
                return;
            }
            this.form.controls.city_id.setValue(res.id);
            this.form.controls.city_id.updateValueAndValidity();
            this.selectCountry();
        })
    }
}
