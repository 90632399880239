// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap, switchMap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {TpFileService} from '../_services/tp-file.service';
// Actions
import {
    AllTpFileRequested,
    AllTpFileLoaded,
    TpFileActionTypes,
    TpFileActionToggleLoading,
    TpFileCreated,
    TpFileOnServerCreated,
    TpFileOnServerUpdated,
    TpFileDeleted,
    AllTpFileOfTPRequested,
    AllTpFileOfToeRequested
} from '../_actions/tp-file.actions';

import {AppState} from '../../reducers';

@Injectable()
export class TpFileEffects {

    showActionLoadingDispatcher = new TpFileActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TpFileActionToggleLoading({isLoading: false});

    @Effect()
    loadTpFilesOfTp$ = this.actions$
        .pipe(
            ofType<AllTpFileOfTPRequested>(TpFileActionTypes.AllTpFileOfTPRequested),
            switchMap(({payload}) => this.service.getAttachedFilesByTpId(payload.tpId)),
            map((result) => {
                return new AllTpFileLoaded({
                    files: result.data
                })
            })
        )

    @Effect()
    loadAllTpFile$ = this.actions$
        .pipe(
            ofType<AllTpFileRequested>(TpFileActionTypes.AllTpFileRequested),
            mergeMap(() => this.service.getAttachedFiles()),
            map((result: any) => {
                return new AllTpFileLoaded({
                    files: result.data
                });
            })
        );

    @Effect()
    loadTpFileOfToe$ = this.actions$
        .pipe(
            ofType<AllTpFileOfToeRequested>(TpFileActionTypes.AllTpFileOfToeRequested),
            switchMap(({payload}) => this.service.getAttachedFilesByToeId(payload.toeId)),
            map((result) => {
                return new AllTpFileLoaded({
                    files: result.data
                })
            })
        )

    @Effect()
    deleteTpFile$ = this.actions$
        .pipe(
            ofType<TpFileDeleted>(TpFileActionTypes.TpFileDeleted),
            mergeMap(({payload}) => {
                    return this.service.deleteAttachedFile(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTpFile$ = this.actions$
        .pipe(
            ofType<TpFileOnServerUpdated>(TpFileActionTypes.TpFileOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.saveFile(payload.tp_id, payload.tpFile);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    //
    @Effect()
    createTpFile$ = this.actions$
        .pipe(
            ofType<TpFileOnServerCreated>(TpFileActionTypes.TpFileOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.saveFile(payload.tp_id, payload.tp_file).pipe(
                        tap(res => {
                        if (res) {
                            this.store.dispatch(new TpFileCreated({tp_file: res.data}));
                        }
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: TpFileService, private store: Store<AppState>) {
    }
}
