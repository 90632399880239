<div class="form">
    <div class="form-filtration">
        <div class="row">
            <mat-form-field class="col-3 mat-form-field-fluid">
                <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate" />
            </mat-form-field>

            <mat-form-field class="col-3 mat-form-field-fluid">
                <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                    [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_LOCATION' | translate"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    #searchCity />
            </mat-form-field>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="d-flex flex-wrap flex-grow-1" >
                <kt-filter-selection
                    *ngIf="sections.buildingTypes.available"
                    [bg]="sections.buildingTypes.color"
                    class="mr-2 mt-2"
                    placeholder="Building Type"
                    [resetCmd$]="resetCmd$"
                    [selections]="buildingTypes"
                    (onSelect)="onSelect($event, 'building-type')"></kt-filter-selection>
                <kt-filter-selection
                    *ngIf="sections.subTypeCategory.available"
                    [bg]="sections.subTypeCategory.color"
                    class="mr-2 mt-2"
                    placeholder="Sub-type Category"
                    [resetCmd$]="resetCmd$"
                    [selections]="filteredSubTypeCategory$ | async"
                    (onSelect)="onSelect($event, 'sub-type-category')"></kt-filter-selection>
                <kt-filter-selection
                    *ngIf="sections.categories.available"
                    [bg]="sections.categories.color"
                    class="mr-2 mt-2"
                    placeholder="Category"
                    [resetCmd$]="resetCmd$"
                    [selections]="keyCategories"
                    (onSelect)="onSelect($event, 'key-category')"></kt-filter-selection>
                <kt-filter-selection
                    *ngIf="sections.subCategory.available"
                    [bg]="sections.subCategory.color"
                    class="mr-2 mt-2"
                    placeholder="Sub Category"
                    [resetCmd$]="resetSubCatCmd$"
                    [selections]="filteredSubCategories$ | async"
                    (onSelect)="onSelect($event, 'sub-category')"></kt-filter-selection>
                <kt-filter-selection
                    *ngIf="sections.status.available"
                    [bg]="sections.status.color"
                    class="mr-2 mt-2"
                    placeholder="Status"
                    [resetCmd$]="resetCmd$"
                    [selections]="statuses"
                    (onSelect)="onSelect($event, 'status')"></kt-filter-selection>
                <kt-filter-selection
                    *ngIf="sections.uses.available"
                    [bg]="sections.uses.color"
                    class="mr-2 mt-2"
                    [placeholder]="acType != 0 ? usesAcPlaceholder : usesBuildingPlaceholder"
                    [resetCmd$]="resetCmd$"
                    [selections]="acType != 0 ? usesAcOptions : usesBuildingOptions"
                    (onSelect)="onSelect($event, 'use')"></kt-filter-selection>
                <kt-filter-date-selection
                    *ngIf="sections.date.available"
                    [bg]="sections.date.color"
                    class="mr-2 mt-2"
                    (onDateSelection)="onDateSelection($event)"
                    [resetCmd$]="resetCmd$"></kt-filter-date-selection>
            </div>
            <div class="d-flex">
                <kt-other-filters 
                    *ngIf="sections.other.available"
                    [assetType]="acType" 
                    [filter]="filter"
                    class="mr-2 mt-2"
                    [resetCmd$]="resetCmd$"
                    (onApply)="onApply($event)"></kt-other-filters>
                <div ngbDropdown class="w-100 mt-2">
                    <button ngbDropdownToggle class="btn w-100 font-semibold"
                        style="padding-top: 0.8rem; padding-bottom: 0.8rem; color: red; font-weight: 600"
                        [style.background-color]="lightRed"
                        (click)="resetFilter()">
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>