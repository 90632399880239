import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-valuation-checker-modal',
  templateUrl: './valuation-checker-modal.component.html',
  styleUrls: ['./valuation-checker-modal.component.scss']
})
export class ValuationCheckerModalComponent implements OnInit {
  value: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ValuationCheckerModalComponent>
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.value);
  }

  toggle(event: MatCheckboxChange) {
    this.value = event.checked;
  }
}
