import { MapsAPILoader } from '@agm/core';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { uniq} from 'lodash';
import { Observable, Subject, BehaviorSubject, of, combineLatest } from 'rxjs';
import { takeUntil, startWith, take, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AssetClassOfficeModel, AssetClassSizeModel, AssetClassOfficesService, AssetClassOfficeOnServerCreated, AssetClassOfficeOnServerUpdated, selectLastCreatedAssetClassOfficeId, AssetClassTenure } from 'src/app/core/comparable';
import { UpdatePreviouslyCreatedACOfficeId } from 'src/app/core/comparable/_actions/asset-class-office.actions';
import { selectAssetClassOfficeById } from 'src/app/core/comparable/_selectors/asset-class-office.selectors';
import { HandoverStandard, StateRepair, Grade, BuildingType, AllHandoverStandardsRequested, selectAllHandoverStandards, AllStateRepairsRequested, selectAllStateRepairs, AllGradesRequested, selectAllGrades, AllBuildingTypesRequested, selectAllBuildingTypes, selectOfficeLayoutsActionLoading, AllUnitAreaMeasurementsRequested, selectAllUnitAreaMeasurements } from 'src/app/core/linked-tables';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { AppState } from 'src/app/core/reducers';
import { TypesUtilsService, LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { SubheaderService, LayoutConfigService } from 'src/app/core/_base/layout';
import { environment } from 'src/environments/environment';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { AcGroundsComponent } from '../../../shared_components/ac-grounds/ac-grounds.component';
import { AccommodationLayoutTableComponent } from '../../../shared_components/accommodation-layout-table/accommodation-layout-table.component';
import { BuildingFormComponent } from '../../../shared_components/building-form/building-form.component';
import { BuildingSectionComponent } from '../../../shared_components/building-section/building-section.component';
import { InternalAspectsComponent } from '../../../shared_components/internal-aspects/internal-aspects.component';
import { MapLocationComponent } from '../../../shared_components/map-location/map-location.component';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { UploadFileComponent } from '../../../shared_components/upload-file/upload-file.component';
import { ConsiderationAndSourceComponent } from '../../_sub/consideration-and-source/consideration-and-source.component';
import { ValuationCheckerModalComponent } from '../../_sub/valuation-checker-modal/valuation-checker-modal.component';
import {v4 as uuidv4} from 'uuid'
import { AssetClassSize } from 'src/app/core/v2/types';

@Component({
  selector: 'kt-office-modal',
  templateUrl: './office-modal.component.html',
  styleUrls: ['./office-modal.component.scss', '../../complex-btn.scss']
})
export class OfficeModalComponent implements OnInit {
    @ViewChild(MapLocationComponent, {static: false}) 
    mapLocationComponent: MapLocationComponent;

    @ViewChild('simple')
    public uploadFileComponent: UploadFileComponent;
    @ViewChild('buildingPic')
    public buildingFileComponent: UploadFileComponent;
    @ViewChild(InternalAspectsComponent, {static: false})
    internalAspectComponent: InternalAspectsComponent;
    @ViewChild(AcGroundsComponent, {static: false})
    acGroundsComponent: AcGroundsComponent;
    @ViewChild(AcDocumentUploadComponent, {static: false})
    acDocumentUploadComponent: AcDocumentUploadComponent;
    @ViewChild(ConsiderationAndSourceComponent, {static: false})
    considerationAndSourceComponent: ConsiderationAndSourceComponent;
    @ViewChild(BuildingSectionComponent, {static: false})
    buildingSectionComponent: BuildingSectionComponent;
    @ViewChild(AccommodationLayoutTableComponent, {static: false})
    accommodationLayoutTable: AccommodationLayoutTableComponent;
    @ViewChild(BuildingFormComponent, {static: false})
    buildingFormComponent: BuildingFormComponent;

    ac: AssetClassOfficeModel;
    form: UntypedFormGroup;
    saveState: boolean;

    loading$: Observable<boolean>;
    
    protected _onDestroy = new Subject<void>();
    formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
    isComplexForm: boolean = false;
    hasFormErrors: boolean = false;
    isShown: boolean = true;
    markerLat: number;
    markerLng: number;
    centerLat = null;
    centerLng = null;
    subCategories: SubCategoryModel[] = [];
    filteredSubCategories: SubCategoryModel[] = [];
    public filteredSubTypeCategories: BehaviorSubject<SubTypeCategoryModel[]> = new BehaviorSubject([]);
    subTypeCategories: SubTypeCategoryModel[] = [];
    handoverStandards: HandoverStandard[] = [];
    stateOfRepairs: StateRepair[] = [];
    grades: Grade[] = [];
    buildingTypes: BuildingType[] = [];
    private _floors$: BehaviorSubject<number[]> = new BehaviorSubject([]);
    public floors$: Observable<number[]> = this._floors$.asObservable();
    aboveFloor$: BehaviorSubject<number> = new BehaviorSubject(null);
    belowFloor$: BehaviorSubject<number> = new BehaviorSubject(null);
    tenuresSubject = new BehaviorSubject<AssetClassTenure[]>([])
    sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
    sizes$ = combineLatest([ 
      this.store.select(selectAllUnitAreaMeasurements),
      this.sizesSubject.asObservable()
    ]).pipe(
      map(([unitAreaMeasurements, sizes]) => {
        const items: AssetClassSize[] = sizes.map(size => ({
          id: size.id,
          uid: size.uid,
          size: size.size,
          standardMeasurementName: size.standard_measurement_name,
          unitAreaMeasurementAcronym: unitAreaMeasurements.find(item => item.id === size.unit_of_area_measurement_id)?.acronym
        }))
        return items
      })
    )
    showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public hasParcel = of(false);
    public comps = new BehaviorSubject([]);
    public considerationError$ = new BehaviorSubject(false);
    selectedTab = 0;
    selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
    selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
    tabHeaders: TabHeader[] = [
      {label: 'Location & building', disabled: of(false)},
      {label: 'Property information', disabled: of(false)},
      {label: 'Lease & sale information', disabled: of(false)},
      {label: 'Pictures & documents', disabled: of(false)},
    ]
    error:any = {
      msg: ' Missing Fields in total:',
      fields: []
    }
    private valuationId: number|null = null;
    
    /**
     * Component constructor
     *
     * @param assetClassOfficesService: AssetClassOfficesService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param dialog: Dialog
     * @param mapsAPILoader: MapsAPILoader
     * @param layoutConfigService: LayoutConfigService
     * @param ngZone: NgZone
     */
    constructor(public assetClassOfficesService: AssetClassOfficesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                public dialog: MatDialog,
                private mapsAPILoader: MapsAPILoader,
                private layoutConfigService: LayoutConfigService,
                private ngZone: NgZone,
                private translate: TranslateService,
                private dialogRef: MatDialogRef<OfficeModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.saveState = false;
        this.valuationId = data.valuationId;
        // this.fileType = 'image/*';
    }

    ngOnInit() {
      const routeSubscription = this.activatedRoute.params.subscribe(params => {
        const id = params.office_id;
        if (id && id > 0) {
          this.activatedRoute.data.subscribe(data => {
            const res = data.data;
            this.ac = res.data;
            this.isComplexForm = this.ac.formType == 1;
            this._floors$.next(this._getFloorsFrom(this.ac.floor_details));
            const deepCopiedSizes = _deepCopy(this.ac.sizes)
              .map(size => {
                size.uid = uuidv4()
                return size
              })
            const deepCopiedTenures = _deepCopy(this.ac.tenures)
              .map(tenure => {
                const rentSizeUuid = deepCopiedSizes.find(size => size.id === tenure.rent_size_id)?.uid
                const expensesSizeUuid = deepCopiedSizes.find(size => size.id === tenure.expenses_size_id)?.uid
                tenure.rent_size_uid = rentSizeUuid
                tenure.expenses_size_uid = expensesSizeUuid
                return tenure
              })
            this.tenuresSubject.next(deepCopiedTenures)
            this.sizesSubject.next(deepCopiedSizes);
            this.markerLat = Number(res.data.locationData.latitude);
            this.markerLng = Number(res.data.locationData.longitude);
            this.centerLng = this.markerLng;
            this.centerLat = this.markerLat;
            this.createForm();
          });
        } else {
          this.ac = new AssetClassOfficeModel();
          this.ac.clear();
          this.createForm();
        }
      });

      this.store.dispatch(new AllUnitAreaMeasurementsRequested())
      this.store.dispatch(new AllSubCategorysRequested());
      this.store.select(selectAllSubCategorys).pipe(takeUntil(this._onDestroy))
        .subscribe(res => {
          this.subCategories = res ? res : [];
          this.filteredSubCategories = this._filterSubCategories(this.ac.sub_type_category_id);
        });

      this.store.dispatch(new AllSubTypeCategorysRequested());
      this.store.pipe(
        select(selectAllSubTypeCategorys), takeUntil(this._onDestroy))
        .subscribe(res => {
          this.subTypeCategories = [];
          if (res) {
            this.subTypeCategories = res;
            this.filteredSubTypeCategories.next(this._filterSubTypeCategories(3));
          }
        });

      this.store.dispatch(new AllHandoverStandardsRequested());
      this.store.pipe(select(selectAllHandoverStandards), takeUntil(this._onDestroy))
        .subscribe(res => {
          this.handoverStandards = res ? res : [];
        });
      this.store.dispatch(new AllStateRepairsRequested());
      this.store.pipe(select(selectAllStateRepairs), takeUntil(this._onDestroy))
        .subscribe(res => {
          this.stateOfRepairs = res ? res : [];
        });

      this.store.dispatch(new AllGradesRequested());
      this.store.pipe(select(selectAllGrades), takeUntil(this._onDestroy))
        .subscribe(res => this.grades = res ? res : []);
      this.store.dispatch(new AllBuildingTypesRequested());
      this.store.select(selectAllBuildingTypes).pipe(takeUntil(this._onDestroy)).subscribe(res => {
          this.buildingTypes = res ? res : [];
      });

      this.loading$ = this.store.pipe(select(selectOfficeLayoutsActionLoading));
    }

    private _changeValidation(fields: string[], hasValidation: boolean) {
      const controls = this.form.controls;
      fields.forEach(field => {
        if (hasValidation) {
          controls[field].setValidators([Validators.required]);
        } else {
          controls[field].clearValidators();
        }
        controls[field].updateValueAndValidity();
      })
    }
    createForm() {
      this.formSelectionCtrl.setValue(this.ac.formType);
      this.formSelectionCtrl.updateValueAndValidity();
      this.formSelectionCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
        if (val == 1) {
          this.isComplexForm = true;
        } else {
          this.isComplexForm = false;
        }
        if (this.form) {
          this._changeValidation(['building_type'], !this.isComplexForm);
        }
      });
      this.form = this.fb.group({
        sub_type_category_id: [this.ac.sub_type_category_id, Validators.required],
        sub_category_id: [this.ac.sub_category_id, Validators.required],
        handover_standard_id: [this.ac.handover_standard_id, Validators.required],
        floor_details: [this.ac.floor_details && this.ac.floor_details.length > 0 
            ? this.ac.floor_details.split(',')
            : '', Validators.required],
        state_of_repair_id: [this.ac.state_of_repair_id, Validators.required],
        energy_efficiency_grade_id: [this.ac.energy_efficiency_grade_id],
        general_desc: [this.ac.property_general_desc],
        building_name: [this.ac.building_info.building_name],
        building_type: [this.ac.building_info.building_type, this.isComplexForm ? null : Validators.required],
        building_type_comment: [this.ac.building_info.building_type_comment]
      });
      this.form.controls.sub_type_category_id.valueChanges.pipe(
        startWith(this.form.controls.sub_type_category_id.value),
        takeUntil(this._onDestroy)
      ).subscribe(val => {
        this.filteredSubCategories = this._filterSubCategories(val)

        if (this.filteredSubCategories.length == 0) {
          this.form.controls.sub_category_id.clearValidators();
        } else {
          this.form.controls.sub_category_id.setValidators([Validators.required]);
        }
        this.form.controls.sub_category_id.updateValueAndValidity();
      })
    }
    onFloorAdd(event) {
        const floorStr = event.value;
        const floors = this._getFloors(floorStr);
        const _floors = this._floors$.value;
        floors.forEach(floor => {
            if (_floors.findIndex(f => f == floor) > -1) {
                return;
            }
            _floors.push(floor);
        });
        _floors.sort();
        this._floors$.next(_floors);
    }
    onFloorRemove(event) {
        const floors = this._getFloors(event);
        const _floors = this._floors$.value;
        floors.forEach(floor => {
            const index = _floors.findIndex(f => f == floor);
            if (index > -1) {
                _floors.splice(index, 1);
            }
        });
        _floors.sort();
        this._floors$.next(_floors);
    }
    private _getFloorsFrom(data: string) {
      if (data == null) {
        return [];
      }
        const floorRaws = data.split(',');
        let floors = [];
        floorRaws.forEach(raw => {
            const _floors = this._getFloors(raw);
            _floors.forEach(f => floors.push(f));
        });
        floors = uniq(floors);
        return floors;
    }
    private _getFloors(raw: string): number[] {
      if (raw == null) {
        return [];
      }
        const splits = raw.split(':');
        const floors: number[] = []
        if (splits.length == 2) {
            const lower = parseInt(splits[0]);
            const upper = parseInt(splits[1]);
            for (let i = lower; i <= upper; i++) {
                floors.push(i);
            }
        } else {
            floors.push(parseInt(splits[0]));
        }
        return floors;
    }

    private _filterSubCategories(stcId: number) {
      if (stcId == null) {
        return [];
      }
      return this.subCategories.filter(item => item.sub_type_category_id == stcId);
    }
    private _filterSubTypeCategories(typeId: number) {
      return this.subTypeCategories.filter(item => item.property_sub_type_id == typeId);
    }


    ngOnDestroy(): void {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

  onCenterChange(locationData) {
    this.centerLat = locationData.latitude;
    this.centerLng = locationData.longitude;
    this.ac = Object.assign({}, this.ac, {
      locationData: {
        ...this.ac.locationData,
        latitude: (Number(locationData.latitude)),
        longitude: (Number(locationData.longitude)),
        address: locationData.address,
        location_grade_id: locationData.location_grade_id,
        zip_code: locationData.zip_code,
        city_id: locationData.city_id,
        location_surrounding: locationData.location_surrounding
      }
    });
  }

    onPicChange(pics: {pictures: any[], picture: any}) {
      this.ac = Object.assign({}, this.ac, {
        building_info: {
          ...this.ac.building_info,
          pictures: pics.pictures,
          picture: pics.picture
        }
      })
    }

    getComponentTitle(): string {
      if (this.ac && this.ac.id) {
        return `Edit Office "${this.ac.ref_num}"`;
      }
      return 'Add Office';
    }

    cancel() {
      this.dialogRef.close();
    }

    /**
     * On Submit
     */
    onSubmit(status: boolean) {
      let errorFields = [];
      this.hasFormErrors = false;
      this.considerationError$.next(false);
      this.showTitleRequiredError$.next(false);
      
      if (this.mapLocationComponent) {
        if (status && this.mapLocationComponent.validate()) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
        }
      }
      if (this.buildingSectionComponent) {
        if (status && this.buildingSectionComponent.validate()) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, ...this.buildingSectionComponent.errorFields];
        }
      }

      if (this.buildingFormComponent) {
        if (status && this.buildingFormComponent.validate()) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, ...this.buildingFormComponent.errorFields.map(f => 'building_form_' + f)];
        }
      }

      const controls = this.form.controls;
      if (status && this.form.invalid) {
        Object.keys(controls).forEach(cname => {
          if (!controls[cname].valid) {
            errorFields = [...errorFields, cname];
          }
          controls[cname].markAsTouched();
        })
        this.hasFormErrors = true;
      }
      if (status && this.sizesSubject.value.length == 0) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, 'size'];
      }
      if (this.internalAspectComponent) {
        if (status && this.internalAspectComponent.validate()) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, ...this.internalAspectComponent.errorFields];
        }
      }
      if (status && this.acGroundsComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.acGroundsComponent.errorFields];
        if (this.acGroundsComponent.errorFields.length == 0) {
          errorFields = [...errorFields, 'grounds'];
        }
      }

      if (status && !this.considerationAndSourceComponent.validate()) {
        this.hasFormErrors = true;
        this.considerationError$.next(true);
        errorFields = [...errorFields, 'consideration'];
      }

      if (status && this.uploadFileComponent.uploadFiles.length == 0) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, 'pic'];
      } else {
        const uploadedFiles = this.uploadFileComponent.uploadFiles;
        uploadedFiles.forEach(file => {
          if (file.title === null || file.title === '') {
            this.hasFormErrors = true;
            this.showTitleRequiredError$.next(true);
            errorFields = [...errorFields, 'pic'];
          }
        })
      }
      if (status && this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length == 0) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, 'b_pic'];
      } else if (this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length > 0) {
        const uploadedFiles = this.buildingFileComponent.uploadFiles;
        uploadedFiles.forEach(file => {
          if (file.title === null || file.title === '') {
            this.hasFormErrors = true;
            this.showTitleRequiredError$.next(true);
            errorFields = [...errorFields, 'b_pic'];
          }
        })
      }
      if (this.hasFormErrors) {
        this.error.fields = errorFields;
        return;
      }

      const editedAssetClassOffice = this.prepareAssetClassOffice(status);
      const dialogRef = this.dialog.open(ValuationCheckerModalComponent, {
        width: '400px',
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(res => {
        if (res == undefined) {
          return;
        }
        editedAssetClassOffice.valuation_id = res ? this.valuationId : null
        if (editedAssetClassOffice.id > 0) {
          this.updateAssetClassOffice(editedAssetClassOffice);
        } else {
          this.createAssetClassOffice(editedAssetClassOffice);
        }
      })

    }

    /**
     * Returns prepared assignment
     */
    prepareAssetClassOffice(isComplete: boolean): AssetClassOfficeModel {
      const _assetClassOffice = new AssetClassOfficeModel();
      _assetClassOffice.clear();
      _assetClassOffice.id = this.ac.id;
      _assetClassOffice.status = isComplete ? 1 : 0;
      _assetClassOffice.formType = this.formSelectionCtrl.value;

      const controls = this.form.controls;
      if (this.mapLocationComponent) {
        _assetClassOffice.locationData = this.mapLocationComponent.getData();
      }

      if (this.buildingSectionComponent) {
        _assetClassOffice.building_info = this.buildingSectionComponent.getDataForComparable();
      }

      _assetClassOffice.sub_type_category_id = controls.sub_type_category_id.value;
      _assetClassOffice.sub_category_id = controls.sub_category_id.value;
      _assetClassOffice.handover_standard_id = controls.handover_standard_id.value;
      _assetClassOffice.floor_details = controls.floor_details.value.length > 0 
          ? controls.floor_details.value.join(',') 
          : '';
      _assetClassOffice.state_of_repair_id = controls.state_of_repair_id.value;
      _assetClassOffice.energy_efficiency_grade_id = controls.energy_efficiency_grade_id.value;
      _assetClassOffice.property_general_desc = controls.general_desc.value;
      if (!this.isComplexForm) {
        _assetClassOffice.building_info.building_name = controls.building_name.value;
        _assetClassOffice.building_info.building_type = controls.building_type.value;
        _assetClassOffice.building_info.building_type_comment = controls.building_type_comment.value;
      }
      _assetClassOffice.accommodation = this.accommodationLayoutTable ? {...this.accommodationLayoutTable.getData(), id: this.ac.accommodation.id} : {id: this.ac.accommodation.id, data: null, others: []};
      if (this.internalAspectComponent) {
        _assetClassOffice.internalAspectData = this.internalAspectComponent.getData();
      }
      _assetClassOffice.grounds = {...this.acGroundsComponent.getData(), id: this.ac.grounds.id};
      this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
          if (value) {
              _assetClassOffice.picture = value.success;
          }
      });
      _assetClassOffice.sizes = this.sizesSubject.value;
      _assetClassOffice.tenures = this.considerationAndSourceComponent ? this.considerationAndSourceComponent.getData() : [];
      if (this.acDocumentUploadComponent) {
        _assetClassOffice.documents = this.acDocumentUploadComponent.getDocuments();
      }
      if (this.buildingFileComponent) {
        this.buildingFileComponent.res$.pipe(take(1)).subscribe(value => {
            if (value) {
              _assetClassOffice.building_info.picture = value.success;
            }
        });
        _assetClassOffice.building_info.pictures = this.buildingFileComponent.uploadFiles;
      }
      if (this.buildingFormComponent) {
        _assetClassOffice.buildingInfo = this.buildingFormComponent.getData();
      }
      return _assetClassOffice;
    }

    setNullWhenZero(value: any) {
      if (value === undefined || value === '0' || value === 0 || value === -1) {
        return null;
      } else {
        return value;
      }
    }
    setNullWhenNegOne(value: any) {
      if (value === -1) {
        return null;
      }
      return value;
    }

    /**
     * Create assignment
     *
     * @param _assetClassOffice: AssetClassOfficeModel
     */
    createAssetClassOffice(_assetClassOffice: AssetClassOfficeModel) {

      this.store.dispatch(new AssetClassOfficeOnServerCreated({
        assetClassOffice: _assetClassOffice,
        tenures: _assetClassOffice.tenures,
        sizes: _assetClassOffice.sizes,
        fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : [],
        selectedFacilities: [],
        selectedFacilitiesExclusive: [],
        officeExternalAreas: [],
        documents: _assetClassOffice.documents 
      }));
      // this.dialogRef.close(true)
      this.store.pipe(
        select(selectLastCreatedAssetClassOfficeId),
        takeUntil(this._onDestroy),
        distinctUntilChanged(),
        switchMap(res => {
          if (!res) {
            return of(undefined)
          }
          this.store.dispatch(new UpdatePreviouslyCreatedACOfficeId())
          return this.store.select(selectAssetClassOfficeById(res))
        })
      ).subscribe(ac => {
        if (!ac) {
          return;
        }
        this.dialogRef.close(ac)
      })
    }

    /**
     * Update assignment
     *
     * @param _assetClassOffice: AssetClassOfficeModel
     */
    updateAssetClassOffice(_assetClassOffice: AssetClassOfficeModel) {
      const updateAssignment: Update<AssetClassOfficeModel> = {
          id: _assetClassOffice.id,
          changes: _assetClassOffice
      };
      this.store.dispatch(new AssetClassOfficeOnServerUpdated({
        partialAssetClassOffice: updateAssignment,
        assetClassOffice: _assetClassOffice,
        tenures: _assetClassOffice.tenures,
        sizes: _assetClassOffice.sizes,
        fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : [],
        selectedFacilities: [],
        selectedFacilitiesExclusive: [],
        officeExternalAreas: [],
        documents: _assetClassOffice.documents
      }));
      this.dialogRef.close(true)
    }

    discard() {
      if (!this.saveState &&
        localStorage.getItem(environment.authTokenKey)) {
        return true;
      };
      return false;
    }
    onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
      this.hasFormErrors = obj.hasFormErrors;
    }

    navToList() {
      // this.router.navigate(['../'], {relativeTo: this.activatedRoute});
      const url = `${this.layoutConfigService.getCurrentMainRoute()}/comparable/offices`;
      this.router.navigate([url], {relativeTo: this.activatedRoute});
    }
    onTabChanged($event) {
      const activeIndex = $event.index;
    }
    onTabChange(index: number) {
      this.selectedTab = index;
    }
    changeTab(section: string) {
      const section_tab = [
        {sections: ['location', 'building'], tab_index: 0},
        {sections: ['prop'], tab_index: 1},
        {sections: ['consideration'], tab_index: 2},
        {sections: ['pic'], tab_index: 3},
      ]
      const active_Tab = section_tab.find(item => item.sections.includes(section));
      this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
      this.selectedtabchange.next(this.selectedTab);
    }
    erroTabMap() {
      return this.typesUtilsService.getCompTabErrorMap(this.isComplexForm, this.error.fields, this.tabHeaders, 3);
    }
}

interface Item {
  name: string;
}

function filter<T extends Item>(data: T[], searchControl: UntypedFormControl): T[] {
  let search: string = searchControl.value;
  if (!search || search === '') {
    return data;
  } else {
    search = search.toLowerCase();
  }
  return data.filter(item => item.name.toLowerCase().indexOf(search) > -1);
}

function _deepCopy(arr: any[]): any[] {
  return arr.map(item => ({...item}));
}