// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {
    selectKeyCategoryQueryResult,
    selectKeyCategoriesPageLoading,
    selectKeyCategoriesShowInitWaitingMessage
} from '../_selectors/key-category.selectors';

export class KeyCategoriesDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectKeyCategoriesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectKeyCategoriesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectKeyCategoryQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
