import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { RoofCoveringModel } from "../_models/roof-covering.model";

export enum RoofCoveringActionTypes {
    AllRoofCoveringsRequested = '[Linked Area] All RoofCovering Requested',
    AllRoofCoveringsLoaded = '[RoofCovering Effect] All RoofCovering Loaded',

    RoofCoveringOnServerCreated = '[Add RoofCovering Page] RoofCovering On Server Created',
    RoofCoveringCreated = '[RoofCovering Effect] RoofCovering Created',
    RoofCoveringUpdated = '[RoofCovering Effect] RoofCovering Updated',
    RoofCoveringDeleted = '[RoofCovering List Page] RoofCovering Deleted',

    RoofCoveringOnServerRestored = '[RoofCovering Trash] RoofCovering On Server Restored',
    RoofCoveringRestored = '[RoofCovering Trash] RoofCovering Restored',

    RoofCoveringOnServerAdminRestored = '[RoofCovering Admin Trash] RoofCovering On Server Restored',
    RoofCoveringAdminRestored = '[RoofCovering Admin Trash] RoofCovering Restored',

    RoofCoveringDeletedFromTrash = '[RoofCovering Trash] RoofCovering deleted',
    RoofCoveringDeletedFromAdminTrash = '[RoofCovering Admin Trash] RoofCovering deleted',

    RoofCoveringTrash = 'RoofCovering Trashed',
    RoofCoveringTrashFlushed = 'RoofCovering Trash Flushed',

    RoofCoveringsPageRequested = '[RoofCovering List Page] RoofCovering Page Requested',
    RoofCoveringsPageLoaded = '[RoofCovering API] RoofCovering Page Loaded',
    RoofCoveringsPageCancelled = '[RoofCovering API] RoofCovering Page Cancelled',

    RoofCoveringsPageToggleLoading = '[RoofCovering page] RoofCovering Page Toggle Loading',
    RoofCoveringActionToggleLoading = '[RoofCovering] RoofCovering Action Toggle Loading'
}

export class RoofCoveringOnServerCreated implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringOnServerCreated;

    constructor(public payload: { item: RoofCoveringModel }) {
    }
}

export class RoofCoveringCreated implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringCreated;

    constructor(public payload: { item: RoofCoveringModel }) {
    }
}

export class RoofCoveringUpdated implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringUpdated;

    constructor(public payload: {
        partialItem: Update<RoofCoveringModel>,
        item: RoofCoveringModel
    }) {
    }
}

export class RoofCoveringDeleted implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RoofCoveringsPageRequested implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class RoofCoveringsPageLoaded implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringsPageLoaded;

    constructor(public payload: { items: RoofCoveringModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class RoofCoveringsPageCancelled implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringsPageCancelled;
}

export class AllRoofCoveringsRequested implements Action {
    readonly type = RoofCoveringActionTypes.AllRoofCoveringsRequested;
}

export class AllRoofCoveringsLoaded implements Action {
    readonly type = RoofCoveringActionTypes.AllRoofCoveringsLoaded;

    constructor(public payload: { items: RoofCoveringModel[] }) {
    }
}

export class RoofCoveringsPageToggleLoading implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RoofCoveringActionToggleLoading implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class RoofCoveringDeletedFromAdminTrash implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RoofCoveringDeletedFromTrash implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RoofCoveringOnServerRestored implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RoofCoveringRestored implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringRestored;

    constructor(public payload: { item: RoofCoveringModel }) {
    }
}

export class RoofCoveringOnServerAdminRestored implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RoofCoveringAdminRestored implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringAdminRestored;

    constructor(public payload: { item: RoofCoveringModel }) {
    }
}

export class RoofCoveringTrashFlushed implements Action {
    readonly type = RoofCoveringActionTypes.RoofCoveringTrashFlushed;

    constructor() {
    }
}


export type RoofCoveringActions = RoofCoveringCreated
    | RoofCoveringUpdated
    | RoofCoveringDeleted
    | RoofCoveringsPageRequested
    | RoofCoveringsPageLoaded
    | RoofCoveringsPageCancelled
    | AllRoofCoveringsLoaded
    | AllRoofCoveringsRequested
    | RoofCoveringOnServerCreated
    | RoofCoveringDeletedFromAdminTrash
    | RoofCoveringDeletedFromTrash
    | RoofCoveringOnServerRestored
    | RoofCoveringRestored
    | RoofCoveringOnServerAdminRestored
    | RoofCoveringAdminRestored
    | RoofCoveringTrashFlushed
    | RoofCoveringsPageToggleLoading
    | RoofCoveringActionToggleLoading;