<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Accommodation Layout Table</h3>
            <span class="card-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
        </div>
        <div class="card-toolbar">

            <a href="javascript:;"
               (click)="close()"
               class="btn btn-icon btn-sm btn-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body m-0 h-100">
        <kt-accommodation-layout-table
            [propertySubTypeID]="inputData.propertySubTypeID"
            [initData]="inputData.accommodationLayout.data"
            [initOthers]="inputData.accommodationLayout.others"
            [hasTitle]="false"
            [readonly]="true"
            [floors$]="floors"
            [aboveFloor]="aboveFloor"
            [belowFloor]="belowFloor"></kt-accommodation-layout-table>
    </div>
</div>