<div class="card card-custom">
    <div class="card-header">
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <form [formGroup]="form">
                <div class="form-group  row">
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="User"
                                        formControlName="user">
                                <mat-option *ngFor="let item of users"
                                            [value]="item.id">{{item.first_name}} {{item.last_name}} {{userRole(item.id) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="mat-form-field-fluid">
                            <mat-placeholder>Expected Completion Date</mat-placeholder>
                            <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
                            <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                            <input matInput formControlName="date" [matDatetimepicker]="datetimePicker" autocomplete="false">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button (click)="onNoClick()">
                            Close
                        </button>
                        <button mat-button (click)="save()" color="primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
