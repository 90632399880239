import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription, timer} from 'rxjs';
import {debounceTime, delayWhen, retryWhen, tap} from 'rxjs/operators';
import {UnitAreaMeasurement} from 'src/app/core/linked-tables';
import {AppState} from 'src/app/core/reducers';
import {ValuationAdjustmentModel} from 'src/app/core/valuation/_models/valuation-adjustment.model';
import {AdjustmentValuation} from 'src/app/core/valuation/_models/valuation.model';
import {selectAllValuationAdjustments} from 'src/app/core/valuation/_selectors/valuation-adjustment.selector';
import * as valuationSelectors from 'src/app/core/valuation/_selectors/valuation.selector';
import {selectAllValComparables} from '../../../../../../../core/valuation/_selectors/valuation-data.selector';
import {takeUntil} from 'rxjs/operators';
import * as valuationActions from 'src/app/core/valuation/_actions/valuation.actions';
import { delay } from 'lodash';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';

@Component({
    selector: 'kt-valuation-table',
    templateUrl: './valuation-table.component.html',
    styleUrls: ['./valuation-table.component.scss']
})
export class ValuationTableComponent implements OnInit, OnDestroy {
    @Input() readonly: boolean;
    @Input() targetProperty: AssetClassModel;
    @Input() valuation: ValuationModel|null;
    @Input() unitMeasurement$: BehaviorSubject<UnitAreaMeasurement>;
    @Output() removeComparableParent: EventEmitter<any> = new EventEmitter();
    @Output() showOverviewParent: EventEmitter<any> = new EventEmitter();
    @Output() showMultipleOverviewParent: EventEmitter<any> = new EventEmitter();

    valuations$: BehaviorSubject<AdjustmentValuation[]> = new BehaviorSubject([]);
    totalWeigths$: BehaviorSubject<number> = new BehaviorSubject(0);
    tmpVal$: BehaviorSubject<number> = new BehaviorSubject(0);
    is100$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    totalWeighted$: Observable<number>;
    totalPropertyValue: number = 0;
    totalPropertyValue$: Observable<number>;
    totalTPValue$: Observable<number>;
    totalTPWeighted$: Observable<number>;
    subscriptions: Subscription[] = [];

    tableData$: BehaviorSubject<{
        com: any,
        val: AdjustmentValuation
    }[]> = new BehaviorSubject([]);

    form: UntypedFormGroup;

    protected _onDestroy = new Subject<void>();

    constructor(private store: Store<AppState>,
                private formBuilder: UntypedFormBuilder,) {
    }

    ngOnInit(): void {
        this._createForm();
        const sub = combineLatest([
            this.store.select(selectAllValComparables),
            this.store.select(valuationSelectors.selectAllValuations)
        ]).pipe(
            tap(([comparables, valuations]) => {
                if (comparables.length !== valuations.length) {
                    throw `Not same ${comparables.length} and ${valuations.length}`;
                }
            }), 
            retryWhen(err => 
                err.pipe(
                    delayWhen(_ => timer(200)))
            )
        )
        .subscribe(([comparables, valuations]) => {
            const tableData: {com: any, val: AdjustmentValuation}[] = [];
            comparables.forEach(com => {
                const v = valuations.find(val => val.comInfo.id === com.id);
                const data: {com: any, val: AdjustmentValuation} = {com, val: v};
                tableData.push(data);
            });
            this._computeTotalWeights(tableData);
            this.tableData$.next(tableData);
        })
        this.subscriptions.push(sub);

        this.totalWeighted$ = this.store.select(valuationSelectors.selectTotalWeightedAvgGrossUnit);
        this.totalPropertyValue$ = this.store.select(valuationSelectors.selectTotalPropertyValue).pipe(tap(val => this.totalPropertyValue = val));
        this.totalTPValue$ = this.store.select(valuationSelectors.selectTotalTPValue);
        this.totalTPWeighted$ = this.store.select(valuationSelectors.selectTotalTPInUnitMeasurementValue);

        const justificationSub = this.store.select(valuationSelectors.selectJustification).subscribe(res => {
            const control = this.formBuilder.control({value: res, disabled: this.readonly});
            this.form.setControl('justification', control);
        });
        const capSub = this.store.select(valuationSelectors.selectCapAllowanceData).subscribe(data => {
            const capControl = this.formBuilder.control({value: data.capitalAllowance, disabled: this.readonly});
            this.form.setControl('capital_allowance', capControl);
            const justControl = this.formBuilder.control({value: data.justification, disabled: this.readonly});
            this.form.setControl('capital_allowance_justification', justControl);
            if (data.capitalAllowance != 0) {
                this.form.controls.capital_allowance_justification.setValidators(Validators.required);
                this.form.controls.capital_allowance_justification.markAsTouched();
            }
        });
        this.subscriptions.push(capSub);

        this.subscriptions.push(justificationSub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    _createForm() {
        this.form = this.formBuilder.group({
            justification: this.formBuilder.control({value: '', disabled: this.readonly}),
            weights: this.formBuilder.array([]),
        })
    }

    _createWeigthFormArray(tableData: {com: any, val: AdjustmentValuation}[]): UntypedFormArray {
        const weigthFormArray = this.formBuilder.array([]);
        tableData.forEach(data => {
            const control = this.formBuilder.control({value: data.val.weighting + '%', disabled: this.readonly});
            weigthFormArray.push(control);
        })
        return weigthFormArray;
    }

    _computeTotalWeights(tableData: {com: any, val: AdjustmentValuation}[]) {
        let totalWeights = 0;
        tableData.forEach(data => totalWeights += data.val.weighting);
        const is100 = totalWeights === 100;
        this.totalWeigths$.next(totalWeights);
        this.is100$.next(is100);
        this.form.setControl('weights', this._createWeigthFormArray(tableData));
    }

    onFocus(e: FocusEvent, indx: number) {
        const control = this._getWeigthControl(indx);
        let val: string = control.value;
        val = val.substring(0, control.value.length - 1);
        this.tmpVal$.next(Number(val));
    }

    onFocusOut(e: FocusEvent, indx: number, valuation: AdjustmentValuation) {
        const target = e.target as HTMLInputElement;
        const val = target.value.substring(0, target.value.length - 1);
        let totalVal = this.totalWeigths$.value;
        const preVal = this.tmpVal$.value;
        totalVal -= preVal;
        totalVal += Number(val);
        const is100 = totalVal === 100;
        this.is100$.next(is100);
        this.totalWeigths$.next(totalVal);
        const newVal = Object.assign({}, valuation) as AdjustmentValuation;
        newVal.weighting = Number(val);
        this.store.dispatch(new valuationActions.UpdateValuationInternalData({
            valuation: newVal
        }));
    }

    onJustificationFocusOut(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        const val = target.value;
        this.store.dispatch(new valuationActions.UpdateJustification({
            justification: val
        }));
    }

    onCapitalAllowanceFocusOut(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        const val = target.value;
        let num = val != '' ? this._thousandSeparated(val) : 0;
        if (num < 0 && Math.abs(num) >= this.totalPropertyValue) {
            num = 0;
        }
        this.store.dispatch(new valuationActions.UpdateCapitalAllowance({
            capitalAllowance: num 
        }))
    }
    onCapitalAllowanceJustFocusOut(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        const val = target.value;
        this.store.dispatch(new valuationActions.UpdateCapitalAllowanceJustification({
            capitalAllowanceJustification: val
        }))
    }

    _getWeigthControl(indx: number) {
        const weigth = this.form.get('weights') as UntypedFormArray;
        return weigth.at(indx);
    }

    showOverview(com: any) {
        this.showOverviewParent.emit(com);
    }

    removeComparable(index: number, com: any) {
        this.removeComparableParent.emit({index, com});
    }

    showMultipleOverview() {
        this.showMultipleOverviewParent.emit();
    }

    private _thousandSeparated(val: string): number {
        const joined = val.split(',').join('');
        return Number(joined);
    }
}
