import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource } from "../../_base/crud";
import { AssetClassLandModel } from "../_models/asset-class-land.model";
import { selectAssetClassLandInStore, selectAssetClassLandPageLoading, selectAssetClassLandShowInitWaitingMessage } from "../_selectors/asset-class-land.selectors";

export class AssetClassLandDataSource extends BaseDataSource {
    trashed = 0;

    constructor(
        private store: Store<AppState>
    ) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAssetClassLandPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAssetClassLandShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAssetClassLandInStore)
        ).subscribe(response => {
            let items = response.items.map(item => areaAdder(item));
            items = items.map(item => tenureAdder(item));
            this.paginatorTotalSubject.next(response.totalCount);
            this.trashed = response.trashed;
            this.entitySubject.next(items);
        })
    }
}

export function areaAdder(ac: any): any {
    const size = google.maps.geometry.spherical.computeArea(ac.points.map(p => (new google.maps.LatLng(p.lat, p.lng))));
    return {
        ...ac,
        area: size,
        size: `${size.toFixed(2)} m2 (${(size * 10.7639).toFixed(2)} ft2)`
    }
}

export function tenureAdder(ac: any): any {
    const ts = copy(ac.consideration);
    ts.sort((a, b) => {
        if (a.source && b.source) {
            const aDate = a.source.source_type_id != 1 ? new Date(a.source.information_date) : new Date(a.source.transaction_date);
            const bDate = b.source.source_type_id != 1 ? new Date(b.source.information_date) : new Date(b.source.transaction_date);
            return bDate.getTime() - aDate.getTime();
        }
        return 0
    })
    return {
        ...ac,
        display_tenures: ts.length > 0 ? [{
            data: ts[0],
            total_consideration: ts[0].consideration,
            currency: ts[0].currency,
            tenure: ts[0].land_tenure ? `${ts[0].land_tenure.name} (${ts[0].land_tenure.payment_type})` : 'N/I',
            source_date: ts[0].source 
                ? ts[0].source.source_date
                    ? ts[0].source.source_date
                    : null
                : null
        }] : []
    }
}

function copy(tenures: any[]): any[] {
    const temp = [];
    tenures.forEach(t => temp.push(Object.assign({}, t)));
    return temp;
}