// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AuditTrailReport} from '../_models/audit-trail-report.model';

export enum AuditTrailReportActionTypes {

    AllAuditTrailReportsRequested = '[AuditTrailReports Home Page] All AuditTrailReports Requested',
    AllAuditTrailTpReportsRequested = '[AuditTrailReports Home Page] All AuditTrailReports Requested',
    AllAuditTrailReportsLoaded = '[AuditTrailReports API] All AuditTrailReports Loaded',

    AuditTrailReportOnServerCreated = '[Edit AuditTrailReport Dialog] AuditTrailReport On Server Created',
    AuditTrailReportCreated = '[Edit AuditTrailReports Dialog] AuditTrailReports Created',
    AuditTrailReportUpdated = '[Edit AuditTrailReport Dialog] AuditTrailReport Updated',
    AuditTrailReportDeleted = '[AuditTrailReports List Page] AuditTrailReport Deleted',

    AuditTrailReportsPageRequested = '[AuditTrailReports List Page] AuditTrailReports Page Requested',
    AuditTrailReportsPageLoaded = '[AuditTrailReports API] AuditTrailReports Page Loaded',
    AuditTrailReportsPageCancelled = '[AuditTrailReports API] AuditTrailReports Page Cancelled',

    AuditTrailReportsPageToggleLoading = '[AuditTrailReports page] AuditTrailReports Page Toggle Loading',
    AuditTrailReportsActionToggleLoading = '[AuditTrailReports] AuditTrailReports Action Toggle Loading'
}

export class AuditTrailReportOnServerCreated implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportOnServerCreated;

    constructor(public payload: { auditTrailReport: AuditTrailReport}) {
    }
}

export class AuditTrailReportCreated implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportCreated;

    constructor(public payload: { auditTrailReport: AuditTrailReport }) {
    }
}

export class AuditTrailReportUpdated implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportUpdated;

    constructor(public payload: {
        partialAuditTrailReport: Update<AuditTrailReport>,
        auditTrailReport: AuditTrailReport
    }) {
    }
}

export class AuditTrailReportDeleted implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AuditTrailReportsPageRequested implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AuditTrailReportsPageLoaded implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportsPageLoaded;

    constructor(public payload: { auditTrailReports: AuditTrailReport[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel }) {
    }
}

export class AuditTrailReportsPageCancelled implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportsPageCancelled;
}

export class AllAuditTrailReportsRequested implements Action {
    readonly type = AuditTrailReportActionTypes.AllAuditTrailReportsRequested;

    constructor(public payload: { assignmentId: number }) {
    }
}

export class AllAuditTrailTpReportsRequested implements Action {
    readonly type = AuditTrailReportActionTypes.AllAuditTrailTpReportsRequested;

    constructor(public payload: { assignmentId: number, toeId: number, tpId: number }) {
    }
}

export class AllAuditTrailReportsLoaded implements Action {
    readonly type = AuditTrailReportActionTypes.AllAuditTrailReportsLoaded;

    constructor(public payload: { auditTrailReports: AuditTrailReport[] }) {
    }
}

export class AuditTrailReportsPageToggleLoading implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AuditTrailReportsActionToggleLoading implements Action {
    readonly type = AuditTrailReportActionTypes.AuditTrailReportsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export type AuditTrailReportActions = AuditTrailReportCreated
    | AuditTrailReportUpdated
    | AuditTrailReportDeleted
    | AuditTrailReportsPageRequested
    | AuditTrailReportsPageLoaded
    | AuditTrailReportsPageCancelled
    | AllAuditTrailReportsLoaded
    | AllAuditTrailReportsRequested
    | AllAuditTrailTpReportsRequested
    | AuditTrailReportOnServerCreated
    | AuditTrailReportsPageToggleLoading
    | AuditTrailReportsActionToggleLoading;
