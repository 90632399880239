<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>

            <mat-icon
                    class="cursor-pointer icon-gray"
                    container="body"
                    class="pt-1 pl-2"
                    *ngIf="data.itemType != 'departure'"
                    [popoverTitle]="'TOOLTIP.TP.ASSUMPTION.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.TP.ASSUMPTION.DESCRIPTION' | translate"
                    (click)="$event.stopPropagation();">help
            </mat-icon>
        </div>
    </div>
    <div class="form" [formGroup]="form">
        <div class="card-body">
            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput placeholder="{{data.placeholder}}" rows="5" formControlName="text">
                    </textarea>
                    <mat-error>{{data.placeholder}}
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="data.itemType == 'departure'">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Valuation Standard"
                                formControlName="valuation_standard_id">
                        <mat-option *ngFor="let valuationStandard of valuationStandards" value="{{ valuationStandard.id }}">{{valuationStandard.name}}</mat-option>
                    </mat-select>

                    <mat-error>Valuation Standard
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" (click)="close()">Close</button>&nbsp;
                <button mat-button mat-raised-button color="submit" class="btn btn-success" (click)="createItem()">Add</button>&nbsp;
            </div>
        </div>
    </div>
</div>