// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectAdditionalCostTitleQueryResult, selectAdditionalCostTitlesPageLoading, selectAdditionalCostTitlesShowInitWaitingMessage} from '../_selectors/additional-cost-title.selectors';

export class AdditionalCostTitlesDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAdditionalCostTitlesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAdditionalCostTitlesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAdditionalCostTitleQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
