// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {RainwaterPipeActions, RainwaterPipeActionTypes} from '../_actions/rainwater-pipe.actions';
// Models
import {RainwaterPipeModel} from '../_models/rainwater-pipe.model';
import {QueryParamsModel} from '../../_base/crud';

export interface RainwaterPipesState extends EntityState<RainwaterPipeModel> {
    isAllRainwaterPipesLoaded: boolean;
    queryRowsCount: number;
    queryResult: RainwaterPipeModel[];
    lastCreatedRainwaterPipeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RainwaterPipeModel> = createEntityAdapter<RainwaterPipeModel>();

export const initialRainwaterPipeState: RainwaterPipesState = adapter.getInitialState({
    isAllRainwaterPipesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedRainwaterPipeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function rainwaterPipeReducer(state = initialRainwaterPipeState, action: RainwaterPipeActions): RainwaterPipesState {
    switch (action.type) {
        case RainwaterPipeActionTypes.RainwaterPipesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedRainwaterPipeId: undefined
            };
        case RainwaterPipeActionTypes.RainwaterPipeActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case RainwaterPipeActionTypes.RainwaterPipeOnServerCreated:
            return {
                ...state
            };
        case RainwaterPipeActionTypes.RainwaterPipeCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedRainwaterPipeId: action.payload.item.id
            });
        case RainwaterPipeActionTypes.RainwaterPipeUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case RainwaterPipeActionTypes.AllRainwaterPipesLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllRainwaterPipesLoaded: true
            });
        case RainwaterPipeActionTypes.RainwaterPipesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case RainwaterPipeActionTypes.RainwaterPipesPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialRainwaterPipeState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case RainwaterPipeActionTypes.RainwaterPipeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case RainwaterPipeActionTypes.RainwaterPipeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case RainwaterPipeActionTypes.RainwaterPipeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case RainwaterPipeActionTypes.RainwaterPipeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case RainwaterPipeActionTypes.RainwaterPipeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case RainwaterPipeActionTypes.RainwaterPipeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
