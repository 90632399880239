// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AssetClassOfficeModel} from '../_models/asset-class-office.model';
import {AssetClassTenure, AssetClassSizeModel, OfficeExternalAreaModel} from '..';

export enum AssetClassOfficeActionTypes {
    AssetClassOfficeOnServerCreated = '[Edit AssetClassOffice Dialog] AssetClassOffice On Server Created',
    AssetClassOfficeCreated = '[Edit AssetClassOffice Dialog] AssetClassOffice Created',

    AssetClassOfficeOnServerUpdated = '[Edit AssetClassOffice Dialog] AssetClassOffice On Server Updated',
    AssetClassOfficeUpdated = '[Edit AssetClassOffice Dialog] AssetClassOffice Updated',

    AssetClassOffices = '[AssetClassOffices List] get all ',
    AssetClassOfficesPageRequested = '[AssetClassOffices List Page] AssetClassOffices Page Requested',
    AssetClassOfficeMapPageRequested = '[AssetClassOffices Map Page] AssetClassOffices Page Requested',
    AssetClassOfficesPageLoaded = '[AssetClassOffices API] AssetClassOffices Page Loaded',

    AssetClassOfficeOnServerRestored = '[AssetClassOffice Trash] AssetClassOffice On Server Restored',
    AssetClassOfficeRestored = '[AssetClassOffice Trash] AssetClassOffice Restored',
    AssetClassOfficeOnServerAdminRestored = '[AssetClassOffice Admin Trash] AssetClassOffice On Server Restored',
    AssetClassOfficeAdminRestored = '[AssetClassOffice Admin Trash] AssetClassOffice Restored',
    
    AssetClassOfficeDeletedFromTrash = '[AssetClassOffice Trash] AssetClassOffice deleted',
    AssetClassOfficeDeletedFromAdminTrash = '[AssetClassOffice Admin Trash] AssetClassOffice deleted',
    AssetClassOfficeTrash = 'AssetClassOffice Trashed',
    OfficeTrashFlushed = 'Office Trash Flushed',
    OneAssetClassOfficeDeleted = '[AssetClassOffices List Page] One AssetClassOffice Deleted',

    AssetClassOfficesPageCancelled = '[AssetClassOffices API] AssetClassOffices Page Cancelled',
    AssetClassOfficesPageToggleLoading = '[AssetClassOffices] AssetClassOffices Page Toggle Loading',
    AssetClassOfficeActionToggleLoading = '[AssetClassOffices] AssetClassOffices Action Toggle Loading',
    AssetClassOfficeGet = '[AssetClassOffice Object] AssetClassOffice Object Requested',

    AssetClassOfficeDuplicate = '[AssetClassOffice List] AssetClassOffice Duplicate',

    UpdatePreviouslyCreatedACId = '[AssetClassOffice Edit] Update Previously Created AssetClassOffice Id'
}

export class AssetClassOfficeOnServerCreated implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeOnServerCreated;

    constructor(public payload: {
        assetClassOffice: AssetClassOfficeModel,
        tenures: AssetClassTenure[],
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        selectedFacilitiesExclusive: any[],
        officeExternalAreas: OfficeExternalAreaModel[],
        documents: any[]
    }) {
    }
}

export class AssetClassOfficeOnServerUpdated implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeOnServerUpdated;

    constructor(public payload: {
        partialAssetClassOffice: Update<AssetClassOfficeModel>,
        assetClassOffice: AssetClassOfficeModel,
        tenures: AssetClassTenure[],
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        selectedFacilitiesExclusive: any[],
        officeExternalAreas: OfficeExternalAreaModel[],
        documents: any[]
    }) {
    }
}

export class AssetClassOfficeGet implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOffices;

    constructor(public payload: { assetClassOfficeId: number }) {
    }
}

export class AssetClassOfficeCreated implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeCreated;

    constructor(public payload: { assetClassOffice: AssetClassOfficeModel }) {
    }
}

export class AssetClassOfficeUpdated implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeUpdated;

    constructor(public payload: { assetClassOffice: AssetClassOfficeModel }) {
    }
}

export class AssetClassOfficesPageRequested implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassOfficeMapPageRequested implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeMapPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassOfficesPageLoaded implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficesPageLoaded;

    constructor(public payload: {
        assetClassOffices: AssetClassOfficeModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class AssetClassOfficesPageCancelled implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficesPageCancelled;
}

export class AssetClassOfficesPageToggleLoading implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassOfficeActionToggleLoading implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassOfficeOnServerRestored implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassOfficeRestored implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeRestored;

    constructor(public payload: { assetClassOffice: AssetClassOfficeModel }) {
    }
}

export class AssetClassOfficeOnServerAdminRestored implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassOfficeAdminRestored implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeAdminRestored;

    constructor(public payload: { assetClassOffice: AssetClassOfficeModel }) {
    }
}

export class AssetClassOfficeDeletedFromTrash implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeDeletedFromTrash;

    constructor(public payload: { assetClassOfficeId: number }) {
    }
}

export class AssetClassOfficeDeletedFromAdminTrash implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassOfficesTrashRequested implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OfficeTrashFlushed implements Action {
    readonly type = AssetClassOfficeActionTypes.OfficeTrashFlushed;

    constructor() {
    }
}

export class OneAssetClassOfficeDeleted implements Action {
    readonly type = AssetClassOfficeActionTypes.OneAssetClassOfficeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassOfficeDuplicate implements Action {
    readonly type = AssetClassOfficeActionTypes.AssetClassOfficeDuplicate;
    constructor(public payload: {id: number}) {}
}

export class UpdatePreviouslyCreatedACOfficeId implements Action {
    readonly type = AssetClassOfficeActionTypes.UpdatePreviouslyCreatedACId;
    constructor() {}
}

export type AssetClassOfficeActions = AssetClassOfficeOnServerCreated
    | AssetClassOfficeOnServerUpdated
    | AssetClassOfficeCreated
    | AssetClassOfficeUpdated
    | AssetClassOfficesPageRequested
    | AssetClassOfficeMapPageRequested
    | AssetClassOfficesPageLoaded
    | AssetClassOfficesPageCancelled
    | AssetClassOfficesPageToggleLoading
    | AssetClassOfficeActionToggleLoading
    | AssetClassOfficeOnServerRestored
    | AssetClassOfficeRestored
    | AssetClassOfficeOnServerAdminRestored
    | AssetClassOfficeAdminRestored
    | OfficeTrashFlushed
    | AssetClassOfficeDeletedFromTrash
    | AssetClassOfficeDeletedFromAdminTrash
    | OneAssetClassOfficeDeleted
    | AssetClassOfficeGet
    | AssetClassOfficeDuplicate
    | UpdatePreviouslyCreatedACOfficeId;
