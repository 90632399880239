import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/core/reducers';
import { ValuationNoteModel } from 'src/app/core/valuation/_models/valuation-note.model';
import * as valuationNotesSelectors from 'src/app/core/valuation/_selectors/valuation-notes.selectors';
import * as valuationNotesActions from 'src/app/core/valuation/_actions/valuation-notes.action';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EditNoteComponent } from '../../edit-note/edit-note.component';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'kt-notes-module',
  templateUrl: './notes-module.component.html',
  styleUrls: ['../info-module.styles.scss', './notes-module.component.scss']
})
export class NotesModuleComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean = false;
  @Input() workers: any[]
  @Input() valuerUserId: number;
  @Input() currentUser: User;
  data$: Observable<ValuationNoteModel[]>;
  subscriptions: Subscription[] = [];
  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.data$ = this.store$.select(valuationNotesSelectors.selectAll).pipe(map((data) => {
      const newData: ValuationNoteModel[] = data.map(item => {
        const d = Object.assign({}, item) as ValuationNoteModel;
        d.user_name = d.user_name + ' (' + this._valuationRoleInfo(item.user_id) + ')';
        return d
      })
      return newData;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addNote() {
    const dialogRef = this.dialog.open(EditNoteComponent, {
      width: '50vw',
      data: {
        isEdit: false,
        user: this.currentUser,
      }
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new valuationNotesActions.AddData({
          data: res
        }));
      }
    });
    this.subscriptions.push(dialogSub);
  }

  onChangeChecked(event, note: ValuationNoteModel) {
    const updateNote: Update<ValuationNoteModel> = {
      id: note.front_id,
      changes: {
        status: event.checked
      }
    };
    this.store$.dispatch(new valuationNotesActions.UpdateData({data: updateNote}));
  }

  editNote(note: ValuationNoteModel) {
    const dialogRef = this.dialog.open(EditNoteComponent, {
      width: '50vw',
      data: {
        isEdit: true,
        item: note,
        user: this.currentUser
      }
    });
    const dialogSub = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const updateNote: Update<ValuationNoteModel> = {
          id: note.front_id,
          changes: {
            ...res
          }
        };
        this.store$.dispatch(new valuationNotesActions.UpdateData({data: updateNote}));
      }
    });
    this.subscriptions.push(dialogSub);
  }

  deleteNote(id: number) {
    this.store$.dispatch(new valuationNotesActions.RemoveData({id}));
  }

  _valuationRoleInfo(uId: number): string {
    let roleInfo: string = '';
    if (this._isLeadValuer(uId)) {
      roleInfo = 'Lead Valuer';
      if (uId === this.valuerUserId) {
        roleInfo += ' & Valuer'
      }
    } else {
      if (uId === this.valuerUserId) {
        roleInfo = 'Valuer'
      }
    }
    return roleInfo;
  }

  _isLeadValuer(uId: number): boolean {
    return this.workers[0].worker_id === uId;
}

}
