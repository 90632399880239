<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h4 class="card-label">Audit Task</h4>
		</div>
		<div class="card-toolbar">
			<button *ngIf="!readonly" mat-button mat-raised-button color="primary" (click)="save()" class="mr-2">
				Save
			</button>
			<button mat-button mat-raised-button color="warn" (click)="close()" aria-label="Close"
				style="background-color: orange; color: white;">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
	<div class="card-body pt-0 w-100">
		<div class="form" *ngIf="auditTask$|async" [ngClass]="{'form-disabled': readonly}">
			<form [formGroup]="form">
				<div class="form-group row align-items-center">
					<div class="col-8">
						<strong>Did you create the audit trail?</strong>
					</div>
					<div class="col-4">
						<mat-radio-group formControlName="created_audit">
							<mat-radio-button [value]="0">No</mat-radio-button>
							<mat-radio-button [value]="1">Yes</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
