import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel } from 'src/app/core/asset_class';

@Component({
  selector: 'kt-land-area-modal',
  templateUrl: './land-area-modal.component.html',
  styleUrls: ['./land-area-modal.component.scss']
})
export class LandAreaModalComponent implements OnInit {
  landAreaComponentSubject: BehaviorSubject<AssetClassLandAreaComponentModel[]> = new BehaviorSubject([]);
  landAreaSubject: BehaviorSubject<AssetClassLandAreaModel> = new BehaviorSubject(new AssetClassLandAreaModel());

  constructor(
    private dialogRef: MatDialogRef<LandAreaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

  }

  ngOnInit(): void {
    this.landAreaComponentSubject.next(this.data.landAreaComponents);
    this.landAreaSubject.next(this.data.landArea);
  }

  close() {
    this.dialogRef.close();
  }

}
