import { Component, Input, OnInit } from '@angular/core';
import { AssetClassModel } from 'src/app/core/asset_class';
import { ToeModel } from 'src/app/core/toe';

@Component({
  selector: 'kt-tp-info',
  templateUrl: './tp-info.component.html',
  styleUrls: ['./tp-info.component.scss']
})
export class TpInfoComponent implements OnInit {
  @Input()
  tp: AssetClassModel;
  @Input()
  toe: ToeModel;

  constructor() { }

  ngOnInit(): void {
  }

}
