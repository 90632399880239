import {CoiDetailsTPModel} from './coi-details-tp.model';

export class ConflictModel {

    optionType: number;
    index: number;
    text: string;
    text2: string;

    prefix: string;
    optionPrefix: string;

    optionType_a: number;
    index_a: number;
    optionPrefix_a: string;
    coiDetailsTP: CoiDetailsTPModel[];
    type_of_conflict_id: number;
    appropriate_parties: string;
    reason_continue: string;

    canEdit: boolean;

    _isValid: boolean;

    clear() {
        this.index = 0;
        this.prefix = '';
        this.optionPrefix = '';
        this.text = '';
        this.text2 = '';
        this.index_a = 0;
        this.optionPrefix_a = '';
        this.coiDetailsTP = [];
        this._isValid = false;
        this.type_of_conflict_id = null;
        this.appropriate_parties = '';
        this.reason_continue = '';
        this.canEdit = true;
    }

}
