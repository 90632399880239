import { AssetClassConsiderationModel, AssetClassLandAreaComponentModel, AssetClassLandAreaModel, AssetClassLandUseModel, AssetClassTenureModel } from "../../asset_class";
import { BaseModel } from "../../_base/crud";
import { AssetClassBuildingInformation } from "./asset-class-building-information.model";
import { LocationData } from "./location.model";

export class AssetClassLandModel extends BaseModel {
    id: number;
    ref_num: string;
    status: number;

    property_sub_type_id: number;
    locationData: LocationData;

    name: string;
    coordinate_reference_system_id: number;
    points: any[];
    
    country_id: number;
    city_id: number;
    zip_code: string;
    location_grade_id: number;
    address: string;
    latitude: number;
    longitude: number;

    ac_pictures: any[];
    picture: string;

    documents: any[];
    building_pictures: any[];
    building_picture: string;

    consideration: AssetClassConsiderationModel[];
    tenure: AssetClassTenureModel;
    land_area: AssetClassLandAreaModel;
    land_area_components: AssetClassLandAreaComponentModel[];
    land_use: AssetClassLandUseModel;
    buildings: AssetClassBuildingInformation[];
    service_infra: AssetClassServiceInfrastructureModel;
    facilities: string;

    userDeletedBy: string;
    deletedBy: string;
    createdBy: string;
    lastUpdatedBy: string;

    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    valuation_id: number | null = null;

    clear() {
        this.id = undefined;
        this.ref_num = null;
        this.status = 0;

        this.property_sub_type_id = null;
        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        }

        this.name = null;
        this.coordinate_reference_system_id = undefined;
        this.points = [];

        this.country_id = undefined;
        this.city_id = undefined;
        this.zip_code = null;
        this.location_grade_id = undefined;
        this.address = null;

        this.ac_pictures = [];
        this.picture = '';

        this.documents = [];
        this.building_pictures = [];
        this.building_picture = '';

        this.consideration = [];
        this.tenure = new AssetClassTenureModel();
        this.tenure.clear();
        this.land_area_components = [];
        this.land_area = new AssetClassLandAreaModel();
        this.land_area.clear();
        this.land_use = new AssetClassLandUseModel();
        this.land_use.clear();
        this.buildings = [];
        this.facilities = '';
        this.service_infra = new AssetClassServiceInfrastructureModel();
        this.service_infra.clear();

        this.userDeletedBy = null;
        this.deletedBy = null;
        this.createdBy = null;
        this.lastUpdatedBy = null;

        this.user_deleted = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
        this.valuation_id = null;
    }
}

export class AssetClassServiceInfrastructureModel {
    road_access: number;
    status_and_quality: string;
    access_points: string;
    road_length: string;
    owner_of_another_consent: string;
    specify_other_service: string;

    clear() {
        this.road_access = 0;
        this.status_and_quality = '';
        this.access_points = '';
        this.road_length = '';
        this.owner_of_another_consent = '';
        this.specify_other_service = '';
    }
}