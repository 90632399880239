import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuditTrailParticipantModel } from 'src/app/core/assignment';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { InspectionBaseComponent } from '../../target-property/inspection-base/inspection-base.component';
import { ParticipantListComponent } from '../participant/participant-list/participant-list.component';
import { TimeFieldComponent } from './time-field/time-field.component';
import { DatepickerTzInputComponent } from '../../mad-forms/datepicker-tz-input/datepicker-tz-input.component';

@Component({
  selector: 'kt-inspection-details',
  templateUrl: './inspection-details.component.html',
  styleUrls: ['./inspection-details.component.scss']
})
export class InspectionDetailsComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() acDetailID: number;
  @Input() typeOfInspection: number;
  @Input() agencyName: BehaviorSubject<string>;
  @Input() agencyId$: BehaviorSubject<number>;
  @Input() clientName: BehaviorSubject<string>;
  @Input() clientId$: BehaviorSubject<number>;
  @Input() toeId$: BehaviorSubject<number>;
  @Input() participantsSubject: BehaviorSubject<AuditTrailParticipantModel[]>;
  @Input() inputData: InspectionBaseComponent['inspectionDetailData']
  @Input() timezoneOffset: string | null;
  @Output() recordPotentialChange = new EventEmitter<boolean>();

  @ViewChild(ParticipantListComponent, {static: false}) participantListComponent: ParticipantListComponent;


  form: UntypedFormGroup;
  hasFormErrors: boolean = false;
  toeSelected: BehaviorSubject<boolean> = new  BehaviorSubject(true);
  atLeastOneParticipant$ = new BehaviorSubject<boolean>(false);
  
  errorFields: string[] = [];
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public typesUtilsService: TypesUtilsService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      information_date: [this.inputData.information_date, this.typeOfInspection == 1 ? Validators.required : null],
      source_of_information: [this.inputData.source_of_information, this.typeOfInspection == 1 ? Validators.required : null],
      inspection_date: [this.inputData.inspection_date, this.typeOfInspection != 1 ?  Validators.required : null],
      time_of_inspection: [new Date(this.inputData.time_of_inspection), this.typeOfInspection != 1 ? Validators.required : null],
      time_of_ins: [new Date(this.inputData.time_of_inspection), this.typeOfInspection != 1 ? Validators.required : null],
      duration_of_inspection: [readableDuration(this.inputData.duration_of_inspection), this.typeOfInspection != 1 ? Validators.required : null],
      any_limitations_or_restrictions: [this.inputData.any_limitations_or_restrictions],
      limitations_desc: [this.inputData.limitation_desc],
      inspection_note: [this.inputData.inspection_note],
      record_potential_env_issues: [this.inputData.record_potential_env_issues]
    });
    this.form.controls.record_potential_env_issues.valueChanges.pipe(
        takeUntil(this._onDestroy$)
    ).subscribe(val => this.recordPotentialChange.emit(val));
  }

  clearInformationDate(datePicker: DatepickerTzInputComponent) {
    datePicker.clearDate()
    this.form.controls.information_date.setValue(null)
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearDate(ctlr: AbstractControl) {
    ctlr.setValue(null);
    ctlr.updateValueAndValidity();
  }

  openTimeField(event) {
      const durationOfInspectionCtrl = this.form.controls.duration_of_inspection;
      const dialog = this.dialog.open(TimeFieldComponent, {
          data: {
              trigger: new ElementRef(event.currentTarget),
              duration: durationFromReadable(durationOfInspectionCtrl.value)
          },
          width: '18.5em',
          backdropClass: 'map-backdrop'
      });
      dialog.afterClosed().subscribe(val => {
          if (!val) {
              return;
          }
          durationOfInspectionCtrl.setValue(readableDuration(val))
      })
  }
  radioChanged(ev) {
      if (ev.value) {
          this.form.controls.limitations_desc.setValidators(Validators.required);
      } else {
          this.form.controls.limitations_desc.clearValidators();
      }
        this.form.controls.limitations_desc.updateValueAndValidity();
  }

  checkValidation(isComplete: boolean): boolean {
    this.errorFields = [];
    this.atLeastOneParticipant$.next(false);
    let isValid = true;
    this.hasFormErrors = false;
    const controls = this.form.controls;
    if (isComplete && this.form.invalid) {
        Object.keys(controls).forEach(cName => {
            if (controls[cName].invalid) {
                this.errorFields.push(cName);
            }
            controls[cName].markAsTouched();
        });
        isValid = false;
    }

    // Check participants
    if (this.participantListComponent) {
        const participants = this.participantListComponent.participants;
        if (isComplete && participants.length == 0) {
            isValid = false;
            this.hasFormErrors = true;
            this.atLeastOneParticipant$.next(true);
            this.errorFields.push('participants');
        }
    }

    return isValid;
  }

    getData(): InspectionBaseComponent['inspectionDetailData'] {
        const controls = this.form.controls;
        const informationDate = controls.information_date.value 
            ? this.typesUtilsService.getDateStringFromDate(new Date(controls.information_date.value))
            : null;

        const inspectionDate = controls.inspection_date.value
            ? this.typesUtilsService.getDateStringFromDate(new Date(controls.inspection_date.value))
            : null;

        const any_limitations_or_restrictions: boolean = controls.any_limitations_or_restrictions.value;
        
        return {
            information_date: informationDate,
            source_of_information: controls.source_of_information.value,
            inspection_date: inspectionDate,
            time_of_inspection: controls.time_of_inspection.value,
            duration_of_inspection: durationFromReadable(controls.duration_of_inspection.value),
            any_limitations_or_restrictions,
            limitation_desc: any_limitations_or_restrictions ? controls.limitations_desc.value :  '',
            inspection_note: controls.inspection_note.value,
            record_potential_env_issues: controls.record_potential_env_issues.value
        }
    }

    getParticipants(): AuditTrailParticipantModel[] {
        if (!this.participantListComponent) {
            return [];
        }
        const participants = [];
        for (const item of this.participantListComponent.participants) {
            const _newItem = Object.assign({}, item) as AuditTrailParticipantModel;
            _newItem.linked_id = this.acDetailID;
            participants.push(_newItem);
        }
        return participants;
    }

}

function durationFromReadable(readable: string): string {
    const splits = readable.split(' ');
    if (splits.length === 4) {
        return `${splits[0]}:${splits[2]}`;
    }
    if (splits.length === 2) {
        return `00:${splits[0]}`;
    }
    return null;
}

function readableDuration(duration: string): string {
    if (duration === null) {
        return ''
    }
    const splits = duration.split(':');
    if (splits.length != 2) {
        return ''
    }

    const hours = Number(splits[0]);
    const minutes = Number(splits[1]);
    if (hours == 0) {
        return `${minutes} minute(s)`;
    }
    return `${hours} hour(s) ${minutes} minute(s)`
}
