// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AdminService} from '../_services/admin.service';
// State
import {AppState} from '../../../core/reducers';
// Actions
import {
    AllContactsLoaded,
    AllContactsLoadedWithTrash,
    AllContactsRequested,
    ContactActionTypes,
    ContactUpdated,
    ContactOnServerCreated,
    ContactCreated,
    ContactsActionToggleLoading,
    ContactDeletedFromTrash,
} from '../_actions/contact.actions';

@Injectable()
export class ContactEffects {
    showActionLoadingDispatcher = new ContactsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ContactsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllContacts$ = this.actions$
        .pipe(
            ofType<AllContactsRequested>(ContactActionTypes.AllContactsRequested),
            // withLatestFrom(this.store.pipe(select(allContactsLoaded))),
            // filter(([action, isAllContactsLoaded]) => !isAllContactsLoaded),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.getContacts(payload.clientId);
            }),
            map(res => {
                return new AllContactsLoaded({
                    contacts: res.data
                });
            })
        );

    @Effect()
    loadAllContactsWithTrash$ = this.actions$
        .pipe(
            ofType<AllContactsRequested>(ContactActionTypes.AllContactsRequested),
            // withLatestFrom(this.store.pipe(select(allContactsLoaded))),
            // filter(([action, isAllContactsLoaded]) => !isAllContactsLoaded),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.getContacts(payload.clientId);
            }),
            map(res => {
                return new AllContactsLoadedWithTrash({
                    contacts: res.data,
                    totalTrashed: res.total_trashed
                });
            })
        );


    @Effect()
    updateContact$ = this.actions$
        .pipe(
            ofType<ContactUpdated>(ContactActionTypes.ContactUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateContact(payload.contact);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createContact$ = this.actions$
        .pipe(
            ofType<ContactOnServerCreated>(ContactActionTypes.ContactOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createContact(payload.contact).pipe(
                    tap(res => {
                        this.store.dispatch(new ContactCreated({contact: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
                // this.store.dispatch(this.hideActionLoadingDispatcher);
                // return new ClientUpdated({contacts: res.data});
            }),
        );

    @Effect()
    deleteContactFromTrash$ = this.actions$
        .pipe(
            ofType<ContactDeletedFromTrash>(ContactActionTypes.ContactDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteContact(payload.contactId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AdminService, private store: Store<AppState>) {
    }
}
