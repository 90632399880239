<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div class="form form-group-seperator-dashed">
        <form [formGroup]="paymentTaxForm" class="form">
            <div class="card-body pt-0">
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Account" formControlName="asset_class">
                                <mat-option *ngFor="let targetProperty of targetProperties" [value]="targetProperty">{{targetProperty.name}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Target Property Type
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Title" formControlName="title_id">
                                <mat-option *ngFor="let title of titles" [value]="title.id">
                                    {{title.name}}
                                </mat-option>
                                <mat-option [value]="-1">
                                    Other
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Title
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3" *ngIf="paymentTaxForm.controls.title_id.value == -1">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Title" formControlName="name"/>
                            <mat-error>
                                Title
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Description" formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Qty" formControlName="qty" mask="separator" thousandSeparator=","/>
                            <mat-error>
                                Qty
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile mb-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Unit Price" formControlName="amount" mask="separator" thousandSeparator=","/>
                            <mat-error>
                                Unit Price
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
                <button type="button" [disabled]="!isValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()"
                        matTooltip="Add consideration">
                    Add
                </button>
            </div>
        </div>
    </div>
</div>
