import { TVOMMethodData, TVOMMethodVersion } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/tvom-types"
import { calculateHeadlineRent, calculatePresentValueAtPercentRate, calculateYearsPurchaseAtPercentRate, durationCalculation, roundTo4Decimals } from "./utils"
import { AssetClassTenure } from "../../comparable"
import { ConsiderationValueHeadlineToEffective, ConsiderationValueHeadlineToEffectiveRentToSale } from "../../valuation-process/_models/valuation-process-criterion.model"

export function calculateTVOM(considerationValue: ConsiderationValueHeadlineToEffective | ConsiderationValueHeadlineToEffectiveRentToSale, size: number): number {
  if (considerationValue.considerationConvertionData.methods.subMethod === 0) {
    const result = calculateTVOMCapAndTargetRateMethod(
      considerationValue.consideration.oldTenure,
      considerationValue.considerationConvertionData.rateValues.targetRate,
      considerationValue.considerationConvertionData.rateValues.capRate,
      size
    )
    return result ? result.effectiveRent : undefined
  }
  if (considerationValue.considerationConvertionData.methods.subMethod === 1) {
    const result = calculateTVOMCapRateMethod(
      considerationValue.consideration.oldTenure,
      considerationValue.considerationConvertionData.rateValues.capRate,
      size
    )
    return result ? result.effectiveRent : undefined
  }
  if (considerationValue.considerationConvertionData.methods.subMethod === 2) {
    const result = calculateTVOMTargetRateMethod(
      considerationValue.consideration.oldTenure,
      considerationValue.considerationConvertionData.rateValues.targetRate,
      size
    )
    return result ? result.effectiveRent : undefined
  }
  return undefined
}

export function calculateTVOMCapAndTargetRateMethod(consideration: AssetClassTenure, targetRate: number, capitalRate: number, size: number): TVOMMethodData | undefined {
  targetRate = Number(targetRate)
  capitalRate = Number(capitalRate)

  const capital_payment = Number(consideration.cap_payment)
  const total_consideration = Number(consideration.total_consideration)
  const write_off_period = durationCalculation(Number(consideration.write_off_period), consideration.duration_type)
  const rent_free_period = durationCalculation(Number(consideration.rent_free_period), consideration.duration_type)
  const fitting_out_period = durationCalculation(Number(consideration.fitting_out_period), consideration.duration_type)
  const methodVersion = Number(consideration.write_off_period) !== 0 && Number(consideration.write_off_period) < Number(consideration.lease_duration)
    ? TVOMMethodVersion.WriteOffIsLesserThanLease
    : TVOMMethodVersion.WriteOffIsEqualToLeaseOrNA;

  const headlineRent = calculateHeadlineRent(total_consideration, consideration.rent_input_amount_type, consideration.rent_basis_id, size)
  let rentReceivedFor = 0;
  if (methodVersion === TVOMMethodVersion.WriteOffIsLesserThanLease) {
    rentReceivedFor = write_off_period - rent_free_period;
  } else {
    rentReceivedFor = Number(consideration.lease_duration) - rent_free_period;
  }
  const yearsPurchaseOfRentReceived = calculateYearsPurchaseAtPercentRate(targetRate, rentReceivedFor);
  const presentValueOfRentFree = calculatePresentValueAtPercentRate(targetRate, rent_free_period);
  const capValOfHeadlineRent = headlineRent * yearsPurchaseOfRentReceived * presentValueOfRentFree;
  const capValOfInducements = capValOfHeadlineRent - capital_payment;
  const leaseTermCapPeriod = rentReceivedFor + rent_free_period - fitting_out_period;
  const yearsPurchaseOfLeaseTerm = calculateYearsPurchaseAtPercentRate(capitalRate, leaseTermCapPeriod);
  const presentValueOfFittingOut = calculatePresentValueAtPercentRate(capitalRate, fitting_out_period);
  const deferredYearsPurchase = (yearsPurchaseOfLeaseTerm * presentValueOfFittingOut);
  const effectiveRent = capValOfInducements / deferredYearsPurchase;
  const result = {
    headlineRent,
    rentReceivedFor,
    yearsPurchaseOfRentReceived,
    presentValueOfRentFree,
    capValOfHeadlineRent,
    capValOfInducements,
    leaseTermCapPeriod,
    yearsPurchaseOfLeaseTerm,
    presentValueOfFittingOut,
    deferredYearsPurchase,
    effectiveRent
  }
  const rounded = roundTVOMResult(result);
  const converted = convertNaNToUndefined(rounded);
  return converted;
}

export function calculateTVOMCapRateMethod(consideration: AssetClassTenure, capitalRate: number, size: number) {
  const capitalisationRate = Number(capitalRate);
  // const isSameCurrency = consideration.currency === quote_currency;
  // const conv = consideration.conversion.find(c => c.quote_currency === quote_currency);
  // const total_consideration = isSameCurrency 
  //     ? consideration.total_consideration
  //     : conv 
  //         ? (Number(consideration.total_consideration) * conv.exchange_rate) 
  //         : undefined;
  // const capital_payment = isSameCurrency
  //     ? Number(consideration.cap_payment)
  //     : conv 
  //         ? (Number(consideration.cap_payment) * conv.exchange_rate)
  //         : undefined;
  const total_consideration = Number(consideration.total_consideration)
  const capital_payment = Number(consideration.cap_payment)
  const write_off_period = durationCalculation(Number(consideration.write_off_period), consideration.duration_type)
  const rent_free_period = durationCalculation(Number(consideration.rent_free_period), consideration.duration_type)
  const fitting_out_period = durationCalculation(Number(consideration.fitting_out_period), consideration.duration_type)
  const methodVersion = Number(consideration.write_off_period) !== 0 && Number(consideration.write_off_period) < Number(consideration.lease_duration)
    ? TVOMMethodVersion.WriteOffIsLesserThanLease
    : TVOMMethodVersion.WriteOffIsEqualToLeaseOrNA;

  const headlineRent = calculateHeadlineRent(total_consideration, consideration.rent_input_amount_type, consideration.rent_basis_id, size);
  let rentReceivedFor = 0;
  if (methodVersion === TVOMMethodVersion.WriteOffIsLesserThanLease) {
    rentReceivedFor = write_off_period - rent_free_period;
  } else {
    rentReceivedFor = Number(consideration.lease_duration) - rent_free_period;
  }
  const yearsPurchaseOfRentReceived = calculateYearsPurchaseAtPercentRate(capitalisationRate, rentReceivedFor);
  const presentValueOfRentFree = calculatePresentValueAtPercentRate(capitalisationRate, rent_free_period);
  const capValOfHeadlineRent = headlineRent * yearsPurchaseOfRentReceived * presentValueOfRentFree;
  const capValOfInducements = capValOfHeadlineRent - capital_payment;
  const leaseTermCapPeriod = rentReceivedFor + rent_free_period - fitting_out_period;
  const yearsPurchaseOfLeaseTerm = calculateYearsPurchaseAtPercentRate(capitalisationRate, leaseTermCapPeriod);
  const presentValueOfFittingOut = calculatePresentValueAtPercentRate(capitalisationRate, fitting_out_period);
  const deferredYearsPurchase = yearsPurchaseOfLeaseTerm * presentValueOfFittingOut;
  const effectiveRent = capValOfInducements / deferredYearsPurchase;
  const result = {
    headlineRent,
    rentReceivedFor,
    yearsPurchaseOfRentReceived,
    presentValueOfRentFree,
    capValOfHeadlineRent,
    capValOfInducements,
    leaseTermCapPeriod,
    yearsPurchaseOfLeaseTerm,
    presentValueOfFittingOut,
    deferredYearsPurchase,
    effectiveRent
  }
  const rounded = roundTVOMResult(result);
  const converted = convertNaNToUndefined(rounded);
  return converted;
}

export function calculateTVOMTargetRateMethod(consideration: AssetClassTenure, targetRate: number, size: number) {
  targetRate = Number(targetRate);
  // const isSameCurrency = consideration.currency === quote_currency;
  // const conv = consideration.conversion.find(c => c.quote_currency === quote_currency);
  // const total_consideration = isSameCurrency 
  //     ? consideration.total_consideration
  //     : conv 
  //         ? (Number(consideration.total_consideration) * conv.exchange_rate) 
  //         : undefined;
  // const capital_payment = isSameCurrency
  //     ? Number(consideration.cap_payment)
  //     : conv 
  //         ? (Number(consideration.cap_payment) * conv.exchange_rate)
  //         : undefined;

  const capital_payment = Number(consideration.cap_payment)
  const total_consideration = Number(consideration.total_consideration)
  const write_off_period = durationCalculation(Number(consideration.write_off_period), consideration.duration_type)
  const rent_free_period = durationCalculation(Number(consideration.rent_free_period), consideration.duration_type)
  const fitting_out_period = durationCalculation(Number(consideration.fitting_out_period), consideration.duration_type)
  const methodVersion = Number(consideration.write_off_period) !== 0 && Number(consideration.write_off_period) < Number(consideration.lease_duration)
    ? TVOMMethodVersion.WriteOffIsLesserThanLease
    : TVOMMethodVersion.WriteOffIsEqualToLeaseOrNA;

  const headlineRent = calculateHeadlineRent(total_consideration, consideration.rent_input_amount_type, consideration.rent_basis_id, size);
  let rentReceivedFor = 0;
  if (methodVersion === TVOMMethodVersion.WriteOffIsLesserThanLease) {
    rentReceivedFor = write_off_period - rent_free_period;
  } else {
    rentReceivedFor = Number(consideration.lease_duration) - rent_free_period;
  }
  const yearsPurchaseOfRentReceived = calculateYearsPurchaseAtPercentRate(targetRate, rentReceivedFor);
  const presentValueOfRentFree = calculatePresentValueAtPercentRate(targetRate, rent_free_period);
  const capValOfHeadlineRent = headlineRent * yearsPurchaseOfRentReceived * presentValueOfRentFree;
  const capValOfInducements = capValOfHeadlineRent - capital_payment;
  const leaseTermCapPeriod = rentReceivedFor + rent_free_period - fitting_out_period;
  const yearsPurchaseOfLeaseTerm = calculateYearsPurchaseAtPercentRate(targetRate, leaseTermCapPeriod);
  const presentValueOfFittingOut = calculatePresentValueAtPercentRate(targetRate, fitting_out_period);
  const deferredYearsPurchase = yearsPurchaseOfLeaseTerm * presentValueOfFittingOut;
  const effectiveRent = capValOfInducements / deferredYearsPurchase;
  const result = {
    headlineRent,
    rentReceivedFor,
    yearsPurchaseOfRentReceived,
    presentValueOfRentFree,
    capValOfHeadlineRent,
    capValOfInducements,
    leaseTermCapPeriod,
    yearsPurchaseOfLeaseTerm,
    presentValueOfFittingOut,
    deferredYearsPurchase,
    effectiveRent
  }
  const rounded = roundTVOMResult(result);
  const converted = convertNaNToUndefined(rounded);
  return converted;
}

function roundTVOMResult(val: TVOMMethodData): TVOMMethodData {
  const res = Object.assign({}, val) as TVOMMethodData;
  Object.entries(val).forEach(([field, value]) => {
    res[field] = roundTo4Decimals(value);
  })
  return res;
}

function convertNaNToUndefined(val: TVOMMethodData): TVOMMethodData {
  const res = Object.assign({}, val) as TVOMMethodData;
  Object.entries(val).forEach(([field, value]) => {
    res[field] = Number.isNaN(value) ? undefined : value;
  });
  return res;
}