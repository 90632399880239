// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {GardenService} from '../_services/garden.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allGardensLoaded} from '../_selectors/garden.selectors';
// Actions
import {
    AllGardensLoaded,
    AllGardensRequested,
    GardenActionTypes,
    GardensPageRequested,
    GardensPageLoaded,
    GardenUpdated,
    GardensPageToggleLoading,
    GardenDeleted,
    GardenOnServerCreated,
    GardenCreated,
    GardenActionToggleLoading, GardenTrashFlushed, GardenOnServerRestored, GardenRestored,
    GardenOnServerAdminRestored, GardenAdminRestored, GardenDeletedFromTrash, GardenDeletedFromAdminTrash
} from '../_actions/garden.actions';

@Injectable()
export class GardenEffects {
    showPageLoadingDispatcher = new GardensPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new GardenActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new GardenActionToggleLoading({isLoading: false});

    @Effect()
    loadAllGarden$ = this.actions$
        .pipe(
            ofType<AllGardensRequested>(GardenActionTypes.AllGardensRequested),
            withLatestFrom(this.store.pipe(select(allGardensLoaded))),
            filter(([action, isAllGardenLoaded]) => !isAllGardenLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllGardensLoaded({items: res.data});
            })
        );

    @Effect()
    loadGardenPage$ = this.actions$
        .pipe(
            ofType<GardensPageRequested>(GardenActionTypes.GardensPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new GardensPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateGarden$ = this.actions$
        .pipe(
            ofType<GardenUpdated>(GardenActionTypes.GardenUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createGarden$ = this.actions$
        .pipe(
            ofType<GardenOnServerCreated>(GardenActionTypes.GardenOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new GardenCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushGardenTrash$ = this.actions$
        .pipe(
            ofType<GardenTrashFlushed>(GardenActionTypes.GardenTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreGarden$ = this.actions$
        .pipe(
            ofType<GardenOnServerRestored>(GardenActionTypes.GardenOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new GardenRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminGarden$ = this.actions$
        .pipe(
            ofType<GardenOnServerAdminRestored>(GardenActionTypes.GardenOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new GardenAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteGarden$ = this.actions$
        .pipe(
            ofType<GardenDeleted>(GardenActionTypes.GardenDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashGarden$ = this.actions$
        .pipe(
            ofType<GardenDeletedFromTrash>(GardenActionTypes.GardenDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashGarden$ = this.actions$
        .pipe(
            ofType<GardenDeletedFromAdminTrash>(GardenActionTypes.GardenDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: GardenService, private store: Store<AppState>) {
    }
}
