// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SolUseClassificationActions, SolUseClassificationActionTypes} from '../_actions/sol-use-classification.actions';
// Models
import {SolUseClassification} from '../_models/sol-use-classification.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SolUseClassificationsState extends EntityState<SolUseClassification> {
    isAllSolUseClassificationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: SolUseClassification[];
    lastCreatedSolUseClassificationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SolUseClassification> = createEntityAdapter<SolUseClassification>();

export const initialSolUseClassificationsState: SolUseClassificationsState = adapter.getInitialState({
    isAllSolUseClassificationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSolUseClassificationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function solUseClassificationsReducer(state = initialSolUseClassificationsState, action: SolUseClassificationActions): SolUseClassificationsState {
    switch (action.type) {
        case SolUseClassificationActionTypes.SolUseClassificationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSolUseClassificationId: undefined
            };
        case SolUseClassificationActionTypes.SolUseClassificationsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SolUseClassificationActionTypes.SolUseClassificationOnServerCreated:
            return {
                ...state
            };
        case SolUseClassificationActionTypes.SolUseClassificationCreated:
            return adapter.addOne(action.payload.solUseClassification, {
                ...state, lastCreatedSolUseClassificationId: action.payload.solUseClassification.id
            });
        case SolUseClassificationActionTypes.SolUseClassificationUpdated:
            return adapter.updateOne(action.payload.partialSolUseClassification, state);
        case SolUseClassificationActionTypes.AllSolUseClassificationsLoaded:
            return adapter.addAll(action.payload.solUseClassifications, {
                ...state, isAllSolUseClassificationsLoaded: true
            });
        case SolUseClassificationActionTypes.SolUseClassificationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SolUseClassificationActionTypes.SolUseClassificationsPageLoaded:
            return adapter.addMany(action.payload.solUseClassifications, {
                ...initialSolUseClassificationsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case SolUseClassificationActionTypes.SolUseClassificationDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case SolUseClassificationActionTypes.SolUseClassificationRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case SolUseClassificationActionTypes.SolUseClassificationDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case SolUseClassificationActionTypes.SolUseClassificationAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case SolUseClassificationActionTypes.SolUseClassificationDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case SolUseClassificationActionTypes.SolUseClassificationTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
