import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { 
  selectAdminTrashedValuationStandardCount, 
  selectTrashedValuationStandardCount, 
  ValuationStandardActionToggleLoading, 
  ValuationStandardDataSource, 
  ValuationStandardDeleted, 
  ValuationStandardDeletedFromAdminTrash, 
  ValuationStandardDeletedFromTrash, 
  ValuationStandardOnServerAdminRestored, 
  ValuationStandardOnServerRestored, 
  ValuationStandardPageRequested, 
  ValuationStandardService, 
  ValuationStandardTrashFlushed,
  ValuationStandard,
  ValuationStandardUpdated
} from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout';
import { cloneDeep } from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';

@Component({
  selector: 'kt-valuation-standards-list',
  templateUrl: './valuation-standards-list.component.html',
  styleUrls: ['./valuation-standards-list.component.scss']
})
export class ValuationStandardsListComponent implements OnInit {

  dataSource: ValuationStandardDataSource;
  displayedColumns = ['id', 'name', 'is_hidden', 'actions'];
  trashCnt = 0;
  adminTrashCnt = 0;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  selection = new SelectionModel<ValuationStandard>(true, []);
  valuationStandardResult: ValuationStandard[] = [];

  subscriptions: Subscription[] = [];

  canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private service: ValuationStandardService,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Valuation Standards');
    this.subheaderService.setBreadcrumbs([
      {title: 'Developer', page: '../default/admin-management'},
      {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
      {title: 'Valuation Standards', page: `../default/admin-management/linked-tables/valuation-standards`}
    ]);

    this.paginator._intl.itemsPerPageLabel = 'Display';
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadValuationStandardList())
    )
        .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadValuationStandardList();
        })
    )
        .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new ValuationStandardDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
        skip(1),
        distinctUntilChanged()
    ).subscribe(res => {
        this.valuationStandardResult = res;
    });
    this.subscriptions.push(entitiesSubscription);

    // trash count
    const selectTrashedSubscription = this.store.pipe(
        select(selectTrashedValuationStandardCount)
    ).subscribe(res => {
        this.trashCnt = res;
    });
    this.subscriptions.push(selectTrashedSubscription);

    const selectAdminTrashedSubscription = this.store.pipe(
        select(selectAdminTrashedValuationStandardCount)
    ).subscribe(res => {
        this.adminTrashCnt = res;
    });
    this.subscriptions.push(selectAdminTrashedSubscription);
    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
        this.loadValuationStandardList();
    }); // Remove this line, just loading imitation
  }

  loadValuationStandardList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
    this.store.dispatch(new ValuationStandardPageRequested({page: queryParams}));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {
      with_hidden: true
    };
    const searchText: string = this.searchInput.nativeElement.value;
    filter.name = searchText;
    if (!searchText) {
      return filter;
    }
    return filter;
  }

  resetFilter(): any {
    this.searchInput.nativeElement.value = '';
    this.loadValuationStandardList();
  }

  addValuationStandard() {
    this.editValuationStandard(null);
  }

  editValuationStandard(_item: ValuationStandard) {
    if (_item) {
      this.router.navigate(['edit', _item.id], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }
  }

  deleteValuationStandard(_item: ValuationStandard) {
    const _title = this.translate.instant('VALUATION_STANDARD.LIST.DIALOG.DELETE.TITLE');
    const _description: string = this.translate.instant('VALUATION_STANDARD.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
    const _waitDesciption =  this.translate.instant('VALUATION_STANDARD.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.store.dispatch(new ValuationStandardDeleted({id: _item.id}));
    })
  }

  // Fetch trash data
  trash() {
    this.store.dispatch(new ValuationStandardActionToggleLoading({isLoading: true}));
    this.service.getTrashed().subscribe(res => {

        this.store.dispatch(new ValuationStandardActionToggleLoading({isLoading: false}));
        const items = [];
        res.data.forEach(elem => {
            items.push({
                text: `${elem.name}`,
                id: elem.id.toString(),
                hasPermanentlyDelete: true,
                date: elem.user_deleted,
                deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
            });
        });
        this.show_trash(items);
    });
}

  // Fetch admin trash data
  adminTrash() {
    this.store.dispatch(new ValuationStandardActionToggleLoading({isLoading: true}));
    this.service.getAdminTrashed().subscribe(res => {

        this.store.dispatch(new ValuationStandardActionToggleLoading({isLoading: false}));
        const items = [];
        res.data.forEach(elem => {
            items.push({
                text: `${elem.name}`,
                id: elem.id.toString(),
                hasPermanentlyDelete: true,
                date: elem.deleted_at,
                deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
            });
        });
        this.show_trash(items, true);
    });
  }

  show_trash(items: any[], isAdmin = false) {
    let _title = this.translate.instant('VALUATION_STANDARD.LIST.DIALOG.SHOW_TRASH.TITLE');
    if (isAdmin) {
      _title = this.translate.instant('VALUATION_STANDARD.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
    }
      this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
  }

  restore(id: number, isAdmin = false) {
    if (isAdmin) {
      this.store.dispatch(new ValuationStandardOnServerAdminRestored({id}));
    } else {
      this.store.dispatch(new ValuationStandardOnServerRestored({id}));
    }
  }

  delete(id: number) {
    this.store.dispatch(new ValuationStandardDeletedFromTrash({id}));
  }

  hardDelete(id: number) {
    this.store.dispatch(new ValuationStandardDeletedFromAdminTrash({id}));
  }

  flush() {
    this.store.dispatch(new ValuationStandardTrashFlushed());
  }

  view(item: ValuationStandard) {
    this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
  }
  onHiddenChange(event: MatCheckboxChange, item: ValuationStandard) {
    const _item = cloneDeep(item)
    _item.is_hidden = event.checked
    this.store.dispatch(new ValuationStandardUpdated({partialValuationStandard: {
      id: item.id,
      changes: {
        is_hidden: event.checked
      }
    }, valuationStandard: _item}))
  }
}
