<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h4 class="card-label">{{header}}</h4>
		</div>
		<div class="card-toolbar">
			<input #fileInput type="file" (change)="uploadFiles($event)" style="display: none;" multiple accept=".xlsx,.xls,image/*,.doc,.docx,.pdf" />

			<button *ngIf="!data.readonly" mat-button mat-raised-button color="primary" (click)="onAddClick()" class="mr-2">
				Add files
			</button>
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
	<div class="card-body pt-0 w-100">
		<mat-dialog-content>
			<div class="m-1 row justify-content-end">
				<div *ngIf="(isInProgress$|async)" class="mr-2">
					<div style="margin-bottom: 14px;">Uploading... {{progress$|async}}</div>
				</div>
				<div class="error mr-2" *ngIf="(hasFailed$|async)">
					Error: {{error$|async}}
				</div>
				<div class="mr-2" *ngIf="(isInProgress$|async)">
					<span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">
						Cancel
					</span>
				</div>
			</div>
			

			<div *ngIf="dataSource.data.length > 0" class="mat-elevation-z0">
				<mat-table [dataSource]="dataSource">
					<ng-container matColumnDef="type">
						<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
						<mat-cell *matCellDef="let file">
							<img [attr.src]="typesUtilsService.setIcon(typesUtilsService.getFileType(file.name))" />
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef>File name</mat-header-cell>
						<mat-cell *matCellDef="let file">
							{{ typesUtilsService.excludeExtention(file.name) }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="title">
						<mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
						<mat-cell *matCellDef="let file">{{file.title}}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="descr">
						<mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
						<mat-cell *matCellDef="let file">{{file.descr}}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="actions">
						<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
						<mat-cell *matCellDef="let file">
							<button mat-icon-button
									color="primary"
									[disabled]="data.readonly"
									[matTooltip]="'GENERAL.TOOLTIP.EDIT_DESCRIPTION' | translate"
									(click)="editFileDescription(file)"
									type="button">
								<mat-icon>create</mat-icon>
							</button>&nbsp;
							<button mat-icon-button
									color="warn"
									[disabled]="data.readonly"
									[matTooltip]="'GENERAL.TOOLTIP.DELETE_FILE' | translate"
									(click)="deleteUploadedFile(file)"
									type="button">
								<mat-icon>delete</mat-icon>
							</button>&nbsp;
							<button mat-icon-button
									[matTooltip]="'GENERAL.TOOLTIP.PREVIEW_FILE' | translate"
									(click)="previewUploadedFile(file)"
									type="button">
								<mat-icon *ngIf="!docs.includes(typesUtilsService.getFileType(file.name))">remove_red_eye</mat-icon>
								<mat-icon *ngIf="docs.includes(typesUtilsService.getFileType(file.name))">get_app</mat-icon>
							</button>&nbsp;
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
				</mat-table>
			</div>
		</mat-dialog-content>
	</div>
</div>