export class Link {
    id: number;
    link_type: number;
    title: string;
    url: string;
    description: string;

    constructor(link_type: number) {
        this.id = undefined;
        this.link_type = link_type;
        this.title = '';
        this.description = '';
        this.url = '';
    }
}