import {StateRepair} from '../_models/state-repair.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {StateRepairsState} from '../_reducers/state-repair.reducers';
import * as fromStateRepair from '../_reducers/state-repair.reducers';
import {each} from 'lodash';

export const selectStateRepairsState = createFeatureSelector<StateRepairsState>('stateRepairs');

export const selectStateRepairById = (stateRepairIdId: number) => createSelector(
    selectStateRepairsState,
    stateRepairsState => stateRepairsState.entities[stateRepairIdId]
);

export const selectAllStateRepairs = createSelector(
    selectStateRepairsState,
    fromStateRepair.selectAll
);

export const selectAllStateRepairsIds = createSelector(
    selectStateRepairsState,
    fromStateRepair.selectIds
);

export const allStateRepairsLoaded = createSelector(
    selectStateRepairsState,
    stateRepairsState => stateRepairsState.isAllStateRepairsLoaded
);


export const selectStateRepairsPageLoading = createSelector(
    selectStateRepairsState,
    stateRepairsState => stateRepairsState.listLoading
);

export const selectStateRepairsActionLoading = createSelector(
    selectStateRepairsState,
    stateRepairsState => stateRepairsState.actionLoading
);

export const selectLastCreatedStateRepairId = createSelector(
    selectStateRepairsState,
    stateRepairsState => stateRepairsState.lastCreatedStateRepairId
);

export const selectStateRepairsShowInitWaitingMessage = createSelector(
    selectStateRepairsState,
    stateRepairsState => stateRepairsState.showInitWaitingMessage
);

export const selectTrashedStateRepairCount = createSelector(
    selectStateRepairsState,
    (stateRepairsState) => stateRepairsState.totalTrashed
);

export const selectAdminTrashedStateRepairCount = createSelector(
    selectStateRepairsState,
    (stateRepairsState) => stateRepairsState.totalAdminTrashed
);


export const selectStateRepairQueryResult = createSelector(
    selectStateRepairsState,
    stateRepairsState => {
        const items: StateRepair[] = [];
        each(stateRepairsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: StateRepair[] = httpExtension.sortArray(items, stateRepairsState.lastQuery.sortField, stateRepairsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, stateRepairsState.totalCount, '', stateRepairsState.totalTrashed);
    }
);
