<form [formGroup]="form">
    <div class="col-md-12">
        <div class="row" *ngIf="(subMethod$|async) && (subMethod$|async) !== subMethodType.TargetRate">
            <div class="col-md-6">
                <mat-form-field  class="mat-form-field-fluid">
                    <input matInput 
                        placeholder="Capitalisation Rate (%)" 
                        formControlName="capRate"
                        mask="percent.5"
                        suffix="%">
                    <mat-error *ngIf="form.controls.capRate.hasError('required')">
                        Capitalisation Rate is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls.capRate.hasError('isZero')">
                        Capitalisation Rate cannot be <strong>0</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field  class="mat-form-field-fluid">
                    <input matInput placeholder="Capitalisation Rate Justification" formControlName="capRateJustification">
                    <mat-error>
                        Capitalisation Rate Justification is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="(subMethod$|async) && (subMethod$|async) !== subMethodType.CapitalisationRate">
            <div class="col-md-6">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput 
                        placeholder="Target Rate (%)" 
                        formControlName="targetRate"
                        mask="percent.5"
                        suffix="%">
                    <mat-error *ngIf="form.controls.targetRate.hasError('required')">
                        Target Rate is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls.targetRate.hasError('isZero')">
                        Target Rate cannot be <strong>0</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Target Rate Justification" formControlName="targetRateJustification">
                    <mat-error>
                        Target Rate Justification is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>

<ng-container *ngIf="templateData$|async as data">
    <div class="col-md-12 mb-10 section-margin-top-60">
        <h5 class="my-3 text-mad text-uppercase">{{title}} - {{getSubmethodName(data.subMethodType)}}</h5>
        <hr class="active">
    </div>
    <div class="col-md-12 mat-table-wrapper section-margin-bottom-60">
        <table class="table lmat-elevation-z8" style="overflow:hidden">
            <!-- Headline Rent Row -->
            <tr>
                <th>Headline Rent (per year) ({{data.tpCurrency}})</th>
                <td>{{data.calculatedResult.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</td>
                <td>
                    <ng-template #popHeadlineRent>
                        <strong>Calculation:</strong> Headline Rent (per year)
                        <ng-container *ngIf="data.rent_input_amount_type === 'total'">
                            <ng-container *ngIf="data.rent_basis_id === 1">
                                <br> 
                                = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span> <span class="text-red"> x 12</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x 12</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 2">
                                <br> 
                                = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span> <span class="text-red"> x 4</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x 4</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 3">
                                <br> 
                                = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="data.rent_input_amount_type === 'per_units'">
                            <ng-container *ngIf="data.rent_basis_id === 1">
                                <br> 
                                = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span> <span class="text-red"> x 12</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span> <span class="text-red"> x 12</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 2">
                                <br> 
                                = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span> <span class="text-red"> x 4</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span> <span class="text-red"> x 4</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 3">
                                <br> 
                                = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span>
                            </ng-container>
                        </ng-container>
                        <br>
                        = <span class="text-green">{{data.calculatedResult.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</span>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popHeadlineRent" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <!-- Rent Free Period Row -->
            <tr class="gray">
                <th>Rent Free Period (Years)</th>
                <td colspan="2">{{data.rent_free_period | mask:'separator.2'}}</td>
            </tr>

            <!-- Write off or Lease duration row -->
            <ng-container>
                <tr *ngIf="data.methodVersion === methodVersionEnum.WriteOffIsLesserThanLease">
                    <th>Write off (after how many years)</th>
                    <td colspan="2">{{data.write_off_period | mask:'separator.2'}}</td>
                </tr>
                <tr *ngIf="data.methodVersion === methodVersionEnum.WriteOffIsEqualToLeaseOrNA">
                    <th>Lease Duration (Years)</th>
                    <td colspan="2">{{data.lease_duration}}</td>
                </tr>
            </ng-container>

            <!-- Rent Received for row -->
            <tr class="gray">
                <th>Rent Received for (Years)</th>
                <td>{{data.calculatedResult.rentReceivedFor | mask:'separator.2'}}</td>
                <td>
                    <ng-template #popRentReceivedFor>
                        <strong>Calculation:</strong> Rent Received for (years) 
                        <br>
                        = <span class="text-blue">{{data.methodVersion === methodVersionEnum.WriteOffIsLesserThanLease 
                            ? '(Write off)' 
                            : '(Lease Duration)'}}</span> <span class="text-red"> - </span> <span class="text-blue">(Rent Free Period)</span> 
                        <br>
                        = <span class="text-blue">{{data.methodVersion === methodVersionEnum.WriteOffIsLesserThanLease 
                            ? (data.write_off_period | mask:'separator.2')
                            : data.lease_duration}}</span> <span class="text-red"> - </span> <span class="text-blue">{{data.rent_free_period | mask:'separator.2'}}</span> 
                        <br>
                        = <span class="text-green">{{data.calculatedResult.rentReceivedFor | mask:'separator.2'}}</span>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popRentReceivedFor" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <!-- Fitting out period row -->
            <tr>
                <th>Fitting out Period (Years)</th>
                <td colspan="2">{{data.fitting_out_period | mask:'separator.2'}}</td>
            </tr>

            <!-- Year's Purchase value of Rent Received for row -->
            <tr class="gray">
                <th>
                    <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate">
                        <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.rentReceivedFor, rate: data.rateValues.targetRate}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate)">
                        <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.rentReceivedFor, rate: data.rateValues.capRate}"></ng-container>
                    </ng-container>
                </th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.yearsPurchaseOfRentReceived; else notAvailable">
                        {{data.calculatedResult.yearsPurchaseOfRentReceived}}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popYearsPurchase>
                        <strong>Calculation:</strong> 
                            <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate">
                                <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.rentReceivedFor, rate: data.rateValues.targetRate}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate)">
                                <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.rentReceivedFor, rate: data.rateValues.capRate}"></ng-container>
                            </ng-container>
                        <br>
                        = 
                            <span class="text-red">(1 - (1 + </span>
                            <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate
                                ? '(Target Rate)'
                                : '(Capitalisation Rate)'}}</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">(Rent Received for)</span>
                            </sup>
                            <span class="text-red">)/</span>
                            <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate
                                ? '(Target Rate)'
                                : '(Capitalisation Rate)'}}</span>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.yearsPurchaseOfRentReceived">
                            =  
                                <span class="text-red">(1 - (1 + </span>
                                <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate
                                    ? data.rateValues.targetRate
                                    : data.rateValues.capRate}}%</span>
                                <span class="text-red">)</span>
                                <sup>
                                    <span class="text-red">-</span>
                                    <span class="text-blue">{{data.calculatedResult.rentReceivedFor | mask:'separator.2'}}</span>
                                </sup>
                                <span class="text-red">)/</span>
                                <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate
                                    ? data.rateValues.targetRate
                                    : data.rateValues.capRate}}%</span>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.yearsPurchaseOfRentReceived}}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popYearsPurchase" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr>
                <th>
                    <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate">
                        <ng-container *ngTemplateOutlet="presentValue; context: {year: data.rent_free_period, rate: data.rateValues.targetRate}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate)">
                        <ng-container *ngTemplateOutlet="presentValue; context: {year: data.rent_free_period, rate: data.rateValues.capRate}"></ng-container>
                    </ng-container>
                </th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.presentValueOfRentFree; else notAvailable">
                        {{data.calculatedResult.presentValueOfRentFree}}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popPresentValue>
                        <strong>Calculation:</strong> 
                            <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate">
                                <ng-container *ngTemplateOutlet="presentValue; context: {year: data.rent_free_period, rate: data.rateValues.targetRate}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate)">
                                <ng-container *ngTemplateOutlet="presentValue; context: {year: data.rent_free_period, rate: data.rateValues.capRate}"></ng-container>
                            </ng-container>
                        <br>
                        = 
                            <span class="text-red">(1 + </span>
                            <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate
                                ? '(Target Rate)'
                                : '(Capitalisation Rate)'}}</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">(Rent Free Period)</span>
                            </sup>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.presentValueOfRentFree">
                            =  
                                <span class="text-red">(1 + </span>
                                <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.TargetRate
                                    ? data.rateValues.targetRate
                                    : data.rateValues.capRate}}%</span>
                                <span class="text-red">)</span>
                                <sup>
                                    <span class="text-red">-</span>
                                    <span class="text-blue">{{data.rent_free_period | mask:'separator.2'}}</span>
                                </sup>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.presentValueOfRentFree}}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popPresentValue" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr class="gray">
                <th>Capital Value of Headline Rent ({{data.tpCurrency}})</th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.capValOfHeadlineRent; else notAvailable">
                        {{data.calculatedResult.capValOfHeadlineRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popCapValOfHeadlineRent>
                        <strong>Calculation:</strong> Capital Value of Headline Rent 
                        <br>
                        = 
                            <span class="text-blue">(Headline Rent)</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">(Year's Purchase)</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">(Present Value)</span>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.capValOfHeadlineRent">
                            =  
                                <span class="text-blue">{{data.calculatedResult.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                <span class="text-red"> x </span>
                                <span class="text-blue">{{data.calculatedResult.yearsPurchaseOfRentReceived}}</span>
                                <span class="text-red"> x </span>
                                <span class="text-blue">{{data.calculatedResult.presentValueOfRentFree}}</span>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.capValOfHeadlineRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popCapValOfHeadlineRent" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr>
                <th>Capital Payment ({{data.tpCurrency}})</th>
                <td colspan="2">{{data.capital_payment | mask:'separator.2':{thousandSeparator: ','} }}</td>
            </tr>

            <tr class="gray">
                <th>Capital Value of Inducements ({{data.tpCurrency}})</th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.capValOfInducements; else notAvailable">
                        {{data.calculatedResult.capValOfInducements.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popCapValOfInducements>
                        <strong>Calculation:</strong> Capital Value of Inducements 
                        <br>
                        = 
                            <span class="text-blue">(Capital Value)</span>
                            <span class="text-red"> - </span>
                            <span class="text-blue">(Capital Payment)</span>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.capValOfInducements">
                            =  
                                <span class="text-blue">{{data.calculatedResult.capValOfHeadlineRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                <span class="text-red"> - </span>
                                <span class="text-blue">{{data.capital_payment | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.capValOfInducements.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popCapValOfInducements" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr>
                <th>Lease Term Capitalisation Period (years)</th>
                <td>{{data.calculatedResult.leaseTermCapPeriod}}</td>
                <td>
                    <ng-template #popLeaseTermCapPeriod>
                        <strong>Calculation:</strong> Lease Term Capitalisation Period 
                        <br>
                        = 
                            <span class="text-blue">(Rent Received for)</span>
                            <span class="text-red"> + </span>
                            <span class="text-blue">(Rent Free Period)</span>
                            <span class="text-red"> - </span>
                            <span class="text-blue">(Fitting out Period)</span>
                        <br>
                        =  
                            <span class="text-blue">{{data.calculatedResult.rentReceivedFor | mask:'separator.2'}}</span>
                            <span class="text-red"> + </span>
                            <span class="text-blue">{{data.rent_free_period | mask:'separator.2'}}</span>
                            <span class="text-red"> - </span>
                            <span class="text-blue">{{data.fitting_out_period | mask:'separator.2'}}</span>
                        <br>
                        = <span class="text-green">{{data.calculatedResult.leaseTermCapPeriod}}</span>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popLeaseTermCapPeriod" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr class="gray">
                <th>
                    <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate">
                        <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.leaseTermCapPeriod, rate: data.rateValues.capRate}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate)">
                        <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.leaseTermCapPeriod, rate: data.rateValues.targetRate}"></ng-container>
                    </ng-container>
                </th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.yearsPurchaseOfLeaseTerm; else notAvailable">
                        {{data.calculatedResult.yearsPurchaseOfLeaseTerm}}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popYearsPurchaseLeaseTerm>
                        <strong>Calculation:</strong> 
                            <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate">
                                <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.leaseTermCapPeriod, rate: data.rateValues.capRate}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate)">
                                <ng-container *ngTemplateOutlet="yearsPurchase; context: {year: data.calculatedResult.leaseTermCapPeriod, rate: data.rateValues.targetRate}"></ng-container>
                            </ng-container> 
                        <br>
                        = 
                            <span class="text-red">(1 - (1 + </span>
                            <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                                ? '(Capitalisation Rate)'
                                : '(Target Rate)'}}</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">(Lease Term Capitalisation Period)</span>
                            </sup>
                            <span class="text-red">)/</span>
                            <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                                ? '(Capitalisation Rate)'
                                : '(Target Rate)'}}</span>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.yearsPurchaseOfLeaseTerm">
                            =  
                                <span class="text-red">(1 - (1 + </span>
                                <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                                    ? data.rateValues.capRate
                                    : data.rateValues.targetRate}}%</span>
                                <span class="text-red">)</span>
                                <sup>
                                    <span class="text-red">-</span>
                                    <span class="text-blue">{{data.calculatedResult.leaseTermCapPeriod}}</span>
                                </sup>
                                <span class="text-red">)/</span>
                                <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                                    ? data.rateValues.capRate
                                    : data.rateValues.targetRate}}%</span>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.yearsPurchaseOfLeaseTerm}}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popYearsPurchaseLeaseTerm" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr>
                <!-- <th>Present Value (PV) @{{
                    data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                    ? data.rateValues.capRate
                    : data.rateValues.targetRate}}% for {{data.consideration.fitting_out_period}} year(s)</th> -->
                <th>
                    <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate">
                        <ng-container *ngTemplateOutlet="presentValue; context: {year: data.fitting_out_period, rate: data.rateValues.capRate}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate)">
                        <ng-container *ngTemplateOutlet="presentValue; context: {year: data.fitting_out_period, rate: data.rateValues.targetRate}"></ng-container>
                    </ng-container>
                </th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.presentValueOfFittingOut; else notAvailable">
                        {{data.calculatedResult.presentValueOfFittingOut}}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popPresentValueFittingOut>
                        <strong>Calculation:</strong> 
                        <!-- Present Value (PV) @{{
                            data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                            ? data.rateValues.capRate
                            : data.rateValues.targetRate}}% for {{data.consideration.fitting_out_period}} year(s)  -->
                            <ng-container *ngIf="data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate">
                                <ng-container *ngTemplateOutlet="presentValue; context: {year: data.fitting_out_period, rate: data.rateValues.capRate}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!(data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate)">
                                <ng-container *ngTemplateOutlet="presentValue; context: {year: data.fitting_out_period, rate: data.rateValues.targetRate}"></ng-container>
                            </ng-container>
                        <br>
                        = 
                            <span class="text-red">(1 + </span>
                            <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                                ? '(Capitalisation Rate)'
                                : '(Target Rate)'}}</span>
                            <span class="text-red">)</span>
                            <sup>
                                <span class="text-red">-</span>
                                <span class="text-blue">(Fitting out Period)</span>
                            </sup>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.presentValueOfFittingOut">
                            =  
                                <span class="text-red">(1 + </span>
                                <span class="text-blue">{{data.subMethodType === subMethodType.TargetRateAndCapitalisationRate || data.subMethodType === subMethodType.CapitalisationRate
                                    ? data.rateValues.capRate
                                    : data.rateValues.targetRate}}%</span>
                                <span class="text-red">)</span>
                                <sup>
                                    <span class="text-red">-</span>
                                    <span class="text-blue">{{data.fitting_out_period}}</span>
                                </sup>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.presentValueOfFittingOut}}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popPresentValueFittingOut" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr class="gray">
                <th>Deferred Year's Purchase</th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.deferredYearsPurchase; else notAvailable">
                        {{data.calculatedResult.deferredYearsPurchase}}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popDeferred>
                        <strong>Calculation:</strong> Deferred Year's Purchase 
                        <br>
                        = 
                            <span class="text-blue">(Year's Purchase)</span>
                            <span class="text-red"> x </span>
                            <span class="text-blue">(Present Value)</span>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.deferredYearsPurchase">
                            =  
                                <span class="text-blue">{{data.calculatedResult.yearsPurchaseOfLeaseTerm}}</span>
                                <span class="text-red"> x </span>
                                <span class="text-blue">{{data.calculatedResult.presentValueOfFittingOut}}</span>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.deferredYearsPurchase}}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popDeferred" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>

            <tr class="computed">
                <th>Effective Rent (per year) ({{data.tpCurrency}})</th>
                <td>
                    <ng-container *ngIf="data.calculatedResult.effectiveRent; else notAvailable">
                        {{data.calculatedResult.effectiveRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}
                    </ng-container>
                </td>
                <td>
                    <ng-template #popEffectiveRent>
                        <strong>Calculation:</strong> Effective Rent (per year) 
                        <br>
                        = 
                            <span class="text-blue">(Capital Value of Inducements)</span>
                            <span class="text-red"> / </span>
                            <span class="text-blue">(Deferred Year's Purchase)</span>
                        <br>
                        <ng-container *ngIf="data.calculatedResult.effectiveRent">
                            =  
                                <span class="text-blue">{{data.calculatedResult.capValOfInducements.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                <span class="text-red"> / </span>
                                <span class="text-blue">{{data.calculatedResult.deferredYearsPurchase}}</span>
                            <br>
                            = <span class="text-green">{{data.calculatedResult.effectiveRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-container>
                    </ng-template>
                    <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popEffectiveRent" style="cursor: pointer;">
                        <mat-icon color="primary">information</mat-icon>
                    </span>
                </td>
            </tr>
        </table>
    </div>
</ng-container>

<ng-template #yearsPurchase let-year="year" let-rate="rate">
    Year's Purchase (YP) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year|mask:'separator.2'}} year(s)
</ng-template>


<ng-template #presentValue let-year="year" let-rate="rate">
    Present Value (PV) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year|mask:'separator.2'}} year(s)
</ng-template>

<ng-template #notAvailable>
    <span class="text-red">N/A</span>
</ng-template>


