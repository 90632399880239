import { BaseTemplateDataModel } from ".";
import { CriterionType } from "../../valuation";
import { CriterionCategories } from "../../valuation/_models/criterion-categories.model";

export class CriterionsTemplateModel implements BaseTemplateDataModel {
    id: number;
    name: string;
    description: string;

    asset_class_type_id: number;
    default_criterions: ICriterionTemplate[];
    custom_criterions: ICriterionTemplate[];

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.description = null;
        this.name = null;
        this.asset_class_type_id = undefined;
        this.default_criterions = [];
        this.custom_criterions = [];
    }
}

export interface ICriterionTemplate {
    id: number;
    crit_id: number;
    name: string;
    category: CriterionCategories,
    categoryName: string;
    type: CriterionType
}

export interface IDefaultCriterion {
    crit_id: number,
    name: string,
    category_id: number,
    criterion_str: string,
    order_num: number,
}