import {UnitMeasurement} from '../_models/unit-measurement.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {UnitMeasurementsState} from '../_reducers/unit-measurement.reducers';
import * as fromUnitMeasurement from '../_reducers/unit-measurement.reducers';
import {each} from 'lodash';

export const selectUnitMeasurementsState = createFeatureSelector<UnitMeasurementsState>('unitMeasurements');

export const selectUnitMeasurementById = (unitMeasurementId: number) => createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => unitMeasurementsState.entities[unitMeasurementId]
);

export const selectAllUnitMeasurements = createSelector(
    selectUnitMeasurementsState,
    fromUnitMeasurement.selectAll
);

export const selectAllUnitMeasurementsIds = createSelector(
    selectUnitMeasurementsState,
    fromUnitMeasurement.selectIds
);

export const allUnitMeasurementsLoaded = createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => unitMeasurementsState.isAllUnitMeasurementsLoaded
);


export const selectUnitMeasurementsPageLoading = createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => unitMeasurementsState.listLoading
);

export const selectUnitMeasurementsActionLoading = createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => unitMeasurementsState.actionLoading
);

export const selectLastCreatedUnitMeasurementId = createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => unitMeasurementsState.lastCreatedUnitMeasurementId
);

export const selectUnitMeasurementsShowInitWaitingMessage = createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => unitMeasurementsState.showInitWaitingMessage
);

export const selectTrashedUnitMeasurementCount = createSelector(
    selectUnitMeasurementsState,
    (unitMeasurementsState) => unitMeasurementsState.totalTrashed
);

export const selectAdminTrashedUnitMeasurementCount = createSelector(
    selectUnitMeasurementsState,
    (unitMeasurementsState) => unitMeasurementsState.totalAdminTrashed
);



export const selectUnitMeasurementsQueryResult = createSelector(
    selectUnitMeasurementsState,
    unitMeasurementsState => {
        const items: UnitMeasurement[] = [];
        each(unitMeasurementsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: UnitMeasurement[] = httpExtension.sortArray(items, unitMeasurementsState.lastQuery.sortField, unitMeasurementsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, unitMeasurementsState.totalCount, '', unitMeasurementsState.totalTrashed);
    }
);
