import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject } from 'rxjs';
import { ConditionRatingDocumentsComponent } from '../../../shared_components/condition-rating-documents/condition-rating-documents.component';

@Component({
  selector: 'kt-inspection-condition-ratings-upload',
  templateUrl: './inspection-condition-ratings-upload.component.html',
  styleUrls: ['./inspection-condition-ratings-upload.component.scss'],
})
export class InspectionConditionRatingsUploadComponent implements OnInit {
  @ViewChild(ConditionRatingDocumentsComponent, {static: true})
  documentsComponent: ConditionRatingDocumentsComponent;
  @Input() files: any[];
  @Input() readonly: boolean = false;

  errorFields = [];
  hasErrors: boolean = false;
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
  ) { }

  ngOnInit(): void {
  }

  checkValidation(isComplete: boolean): boolean {
    this.errorFields = [];
    let errorFields = new Set<string>();
    let isValid = true;
    this.hasErrors = false;
    this.showTitleRequiredError$.next(false);
    let hasTitleError = false;

    if (isComplete && this.documentsComponent.uploadedFiles.length < 1) {
      this.hasErrors = true;
      isValid = false;
      errorFields.add('no_condition_document');
    } else if (isComplete) {
      this.documentsComponent.uploadedFiles.forEach(file => {
        if (file.title == null || file.title == '') {
          this.hasErrors = true;
          hasTitleError = true;
          isValid = false;
          errorFields.add('no_condition_document_title');
        }
      })
    }
    this.showTitleRequiredError$.next(hasTitleError);

    for (let v of errorFields.values()) {
      this.errorFields.push(v);
    }

    return isValid;
  }

  getDocuments(): any[] {
    return this.documentsComponent.getDocuments();
  }
}
