import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { AdjustmentModel } from '../_models/valuation-process-adjustment.model'
import { ValuationProcessAdjustmentActions, ValuationProcessAdjustmentActionTypes } from '../_actions/adjustment.actions'
import * as _ from 'lodash'
import { AdjustmentFormComponent } from "src/app/views/pages/template/templates/adjustment-template/_sub/adjustment-form/adjustment-form.component";

export interface ValuationProcessAdjustmentsState extends EntityState<AdjustmentModel>{
    lastCreatedId: number
}
export const adapter: EntityAdapter<AdjustmentModel> = createEntityAdapter()
const initialValuationProcessAdjustmentsState: ValuationProcessAdjustmentsState = adapter.getInitialState({
    lastCreatedId: 0
})
export function valuationProcessAdjustmentsReducer(
    state = initialValuationProcessAdjustmentsState,
    action: ValuationProcessAdjustmentActions
): ValuationProcessAdjustmentsState {
    switch (action.type) {
        case ValuationProcessAdjustmentActionTypes.LoadAdjustments: {
            let lastCreatedId = 0
            const adjs = action.payload.adjustments.map(ad => {
                lastCreatedId += 1
                let values = ad.values;
                if (Array.isArray(values) && values.length == 0) {
                    values = {}
                }
                return {
                    ...ad,
                    id: lastCreatedId,
                    values
                }
            })
            return adapter.setAll(adjs, {...state, lastCreatedId})
        }
        case ValuationProcessAdjustmentActionTypes.AddAdjustments: {
            let lastCreatedId = state.lastCreatedId
            const entities: AdjustmentModel[] = action.payload.adjustments.map(adjustment => {
                const values: {[key: string]: number} = {}
                action.payload.refNums.forEach(refNum => values[refNum] = 0)
                lastCreatedId += 1
                return {
                    id: lastCreatedId,
                    name: adjustment.name,
                    description: adjustment.description,
                    adjustmentId: adjustment.id,
                    values,
                    analysis: null
                }
            })
            return adapter.addMany(entities, {...state, lastCreatedId})
        }
        case ValuationProcessAdjustmentActionTypes.DeleteAdjustment: {
            return adapter.removeOne(action.payload.id, state)
        }
        case ValuationProcessAdjustmentActionTypes.EditAdjustment: {
            return adapter.updateOne({
                id: action.payload.id,
                changes: action.payload.changes 
            }, state)
        }
        case ValuationProcessAdjustmentActionTypes.AddComparable: {
            const entities: Update<AdjustmentModel>[] = []
            _.each(state.entities, (entity) => {
                const values = _.cloneDeep(entity.values)
                values[action.payload.refNum] = 0
                entities.push({
                    id: entity.id,
                    changes: {
                        values
                    }
                })
            })
            return adapter.updateMany(entities, state)
        }
        case ValuationProcessAdjustmentActionTypes.AddMultipleComparables: {
            let lastCreatedId = 0
            const adjs = action.payload.adjustmentTabData.adjustments.map(ad => {
                lastCreatedId += 1
                let values = ad.values;
                if (Array.isArray(values) && values.length == 0) {
                    values = {}
                }
                return {
                    ...ad,
                    id: lastCreatedId,
                    values
                }
            })
            return adapter.setAll(adjs, {...state, lastCreatedId})
        }
        case ValuationProcessAdjustmentActionTypes.RemoveComparable: {
            const entities: Update<AdjustmentModel>[] = []
            _.each(state.entities, (entity) => {
                const values = _.cloneDeep(entity.values)
                delete values[action.payload.refNum]
                const comparablesNum = Object.keys(values).length
                entities.push({
                    id: entity.id,
                    changes: {
                        values,
                        analysis: comparablesNum == 0 ? '' : entity.analysis
                    }
                })
            })
            return adapter.updateMany(entities, state)
        }
        case ValuationProcessAdjustmentActionTypes.UpdateAdjustment: {
            const values = _.cloneDeep(action.payload.item.values)
            values[action.payload.refNum] = action.payload.value
            return adapter.updateOne({
                id: action.payload.item.id,
                changes: {
                    values
                }
            }, state)
        }
        case ValuationProcessAdjustmentActionTypes.UnimportValuation: {
            const entities: Update<AdjustmentModel>[] = []
            _.each(state.entities, (entity) => {
                entities.push({
                    id: entity.id,
                    changes: {
                        analysis: ''
                    }
                })
            })
            return adapter.updateMany(entities, state)
        }
        case ValuationProcessAdjustmentActionTypes.ResetState: {
            return initialValuationProcessAdjustmentsState
        }
        default:
            return state
    }
}
