export class QueryResultsModel {
s    // fields
    items: any[];
    totalCount: number;
    errorMessage: string;
    trashed: number;
    page: number

    constructor(items: any[] = [], totalCount: number = 0, errorMessage: string = '', _trashed: number = 0, _page: number = 0) {
        this.items = items;
        this.totalCount = totalCount;
        this.errorMessage = errorMessage;
        this.trashed = _trashed;
        this.page = _page;
    }
}
