import {MapsAPILoader} from '@agm/core';
import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, NgZone, ElementRef, Input, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialog,} from '@angular/material/dialog';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import { map, take } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {
    AssetClassModel,
    AssetClassesService,
    ValuationModel,
    AssetClassDefaultAssumptionModel,
    AssetClassSpecialAssumptionModel,
    AssetClassDepartureModel,
    AssetClassSourceExternalReferenceModel,
    ValuationKeyplaceModel,
    AssetClassDetailModel,
    AllTpTaskRequested,
    AllPropertyDetailReportsRequested,
    AllTpFileRequested,
    AllAssetClassSERRequested
} from '../../../../core/asset_class';
import {
    UnitAreaMeasurement,
    PremiseOfValue,
    AssetClassType,
    StandardMeasurement,
    MeasurementMethodology,
    PurposeMeasurement,
    DefaultAssumption,
    SpecialAssumption,
    Departure,
    SourceExternalReference,
    AllKeyCategoriesRequested,
    AllFloorTypesRequested,
    AllWindowTypesRequested,
    AllResidentialTypesRequested,
    AllGradesRequested,
    AllHandoverStandardsRequested,
    AllOfficeLayoutsRequested,
    AllAssetClassTypesRequested,
    AllStateRepairsRequested,
    AllExternalWallsRequested,
    AllFoundationTypesRequested,
    AllUnitAreaMeasurementsRequested,
    AllStandardMeasurementsRequested,
    AllValuationStandardRequested,
    AllPremiseOfValuesRequested,
    AllPropertyTypesRequested,
    AllTenureRequested,
    AllSpecialAssumptionsRequested,
    AllUnitMeasurementsRequested,
    AllChimneyStacksRequested,
    AllRoofStructuresRequested,
    AllRoofCoveringsRequested,
    AllRainwaterPipesRequested,
    AllMainWallsRequested,
    AllOutsideDoorsRequested,
    AllConservatoryPorchesRequested,
    AllOtherJoinerysRequested,
    AllCoordinateReferenceSystemsRequested,
    AllPlaningStatusesRequested,
    AllQosDegradationsRequested,
    AllGardensRequested,
    AllShopFrontTypesRequested,
    AllBuildingTypesRequested,
    AllParkingTypesRequested,
    AllCeilingsRequested,
    AllWallsAndPartitionsRequested,
    AllBathroomFittingsRequested,
    AllBuiltinFittingsRequested,
} from '../../../../core/linked-tables';
import {
    ToeModel,
    ToeService
} from '../../../../core/toe';
import {
    KeyPlaceService,
    AllCitiesRequested,
} from '../../../../core/admin';
import {AppState} from '../../../../core/reducers';
import {LayoutUtilsService, TypesUtilsService, MessageType} from '../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../core/_base/layout';
import {AssetType, CreateCriterions, ValuationDataModel} from 'src/app/core/valuation';
import {CreateSizeCriterions, RestartSizeCriterionState} from 'src/app/core/valuation/_actions/size_criterion.actions';
import {RestartCriterionState} from 'src/app/core/valuation/_actions/criterion.actions';
import {RestartValuationAdjustmentState} from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import {
    FetchValuationData, RestartValuationDataState, ValuationDataStore, ValuationDataStoreActionLoading
} from '../../../../core/valuation/_actions/valuation-data.actions';
import {
    selectAdjustmentTabData,
    selectAllValComparables,
    selectAssumptionTabData,
    selectIsDisableAdjustment,
    selectValuationData
} from '../../../../core/valuation/_selectors/valuation-data.selector';
import {takeUntil} from 'rxjs/operators';
import * as valuationActions from 'src/app/core/valuation/_actions/valuation.actions';
import * as valuationNotesSelectors from 'src/app/core/valuation/_selectors/valuation-notes.selectors';
import { selectAdjustmentProcessFinished } from 'src/app/core/valuation/_selectors/valuation.selector';
import { CurrencyExchangeService, ICurrencyExchange } from 'src/app/core/admin/_services/currency_exchange.service';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { validateAdjustmentTabData } from 'src/app/core/valuation/_models/tab_data.model';
import { ConditionRatingV2Service } from '../services/condition-rating-v2.service';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { AssignmentModel } from 'src/app/core/assignment';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'kt-valuation-process-edit',
    templateUrl: './valuation-process-edit.component.html',
    styleUrls: ['./valuation-process-edit.component.scss'],
    providers: [ConditionRatingV2Service, StandardMeasurementUtility],
    encapsulation: ViewEncapsulation.None
})
export class ValuationProcessEditComponent implements OnInit, OnDestroy {

    readonly = true;

    // tab permissions
    _tabAdjusmentSub: BehaviorSubject<boolean> = new BehaviorSubject(true);
    tabAdjustment$: Observable<boolean> = this._tabAdjusmentSub.asObservable();
    _summarySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    summaryTab$: Observable<boolean> = this._summarySubject.asObservable();
    discard$: Observable<boolean>;
    canDiscard: boolean = true;
    valData: ValuationDataModel;

    // component variables
    protected _onDestroy = new Subject<void>();

    // Mode
    mapText = 'Map View';
    listText = 'List View';
    listViewMode = true;

    // map
    @ViewChild('search', {static: true}) searchElementRef: ElementRef;
    map: any;
    previous;
    selectedId = 0;
    zoom = 15;
    centerLat = environment.mapCenter.lat;
    centerLng = environment.mapCenter.lng;
    currentCenter: { lat: 0, lng: 0 };
    valuationId: number;

    lat = environment.mapCenter.lat;
    lng = environment.mapCenter.lng;

    icon = {
        labelOrigin: {x: 18, y: 22},
        url: './assets/media/icons/pin_blue.svg',
        scaledSize: {
            width: 44,
            height: 44
        }
    };

    // old need to clear up

    assetClass: AssetClassModel;
    assetClassDet: AssetClassDetailModel;
    assetClassForm: UntypedFormGroup;

    targetProperties: any[];
    unitAreaMeasurements: UnitAreaMeasurement[];
    premiseOfValues: PremiseOfValue[];
    assetClassTypes: AssetClassType[];
    standardMeasurements: StandardMeasurement[];
    measurementMethodologies: MeasurementMethodology[];
    purposeMeasurements: PurposeMeasurement[];

    loading$: Observable<boolean>;
    hasFormErrors = false;
    valuationsSubject = new BehaviorSubject<ValuationModel[]>([]);
    public obs$: Observable<DefaultAssumption[]>;
    public obs1$: Observable<SpecialAssumption[]>;
    public obs2$: Observable<Departure[]>;

    toeData: ToeModel;

    assetClassDefaultAssumptions: AssetClassDefaultAssumptionModel[] = [];
    assetClassSpecialAssumptions: AssetClassSpecialAssumptionModel[] = [];
    assetClassDepartures: AssetClassDepartureModel[] = [];
    valuations: ValuationModel[] = [];
    valuationKeyPlaces: ValuationKeyplaceModel[] = [];
    sourceExternalReferences: SourceExternalReference[] = [];
    selectedSer: AssetClassSourceExternalReferenceModel[] = [];

    selectedCategory: any[] = [];
    selectedkeyPlaces: any[] = [];

    allCategorySelected = true;

    currencyExchanges: ICurrencyExchange[] = [];
    currencyExchangeChannel = new BehaviorSubject<ICurrencyExchange>(null);
    allConvertedChannel = new BehaviorSubject<boolean>(false);

    selectedTab: number = 0;

    currentUser: User;

    tabHeaders: TabHeader[] = [];
    hasError$ = new BehaviorSubject<{
        valid: boolean,
        msg: string
    }>({
        valid: true,
        msg: null
    });

    public assignment: AssignmentModel;

    /**
     * Component constructor
     *
     * @param assetClassesService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param storePipe$
     * @param dialog: Dialog
     * @param keyPlaceService
     * @param ngZone
     * @param mapsAPILoader: MapsAPILoader
     * @param layoutConfigService: LayoutConfigService
     * @param ref: ChangeDetectorRef
     * @param toeService: ToeService
     * @param translate
     */
    constructor(public assetClassesService: AssetClassesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private storePipe$: Store<AppState>,
                public dialog: MatDialog,
                public keyPlaceService: KeyPlaceService,
                private ngZone: NgZone,
                private mapsAPILoader: MapsAPILoader,
                private layoutConfigService: LayoutConfigService,
                private ref: ChangeDetectorRef,
                private currencyExchangeService: CurrencyExchangeService,
                private translate: TranslateService,
                private readonlyService: ReadonlyService,
                private crService: ConditionRatingV2Service,
                private smUtilityService: StandardMeasurementUtility) {
    }

    ngOnInit() {
        this.tabHeaders = [
            {label: '1 - Background', disabled: of(false)},
            {label: '2 - Landmarks', disabled: of(false)},
            {label: '3 - Comparables', disabled: of(false)},
            {label: '4 - Adjustments', disabled: this.tabAdjustment$},
            {label: '5 - Preliminaries review', disabled: this.tabAdjustment$},
            {label: '6 - Summary', disabled: this.summaryTab$}
        ]
        const userSubscription = this.store.pipe(
            takeUntil(this._onDestroy),
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                }
            });
        this.readonly = this.readonlyService.isReadOnly();
        this.currencyExchangeChannel.subscribe(res => {
            if (res === null) {
                return;
            }

            const indx = this.currencyExchanges.findIndex(ce => {
                if (ce.id && ce.id === res.id) {
                    return true;
                }
                if (ce.base_currency && ce.quote_currency && ce.date) {
                    if (ce.base_currency === res.base_currency && ce.quote_currency === res.quote_currency && ce.date === res.date) {
                        return true;
                    }
                }
                return false;
            });

            if (indx === -1) {
                this.currencyExchanges.push(res);
            } else {
                this.currencyExchanges[indx].exchange_rate = res.exchange_rate;
            }
        })

        this.activatedRoute.params
            .pipe(takeUntil(this._onDestroy))
            .subscribe(params => {
                this.valuationId = params.valuation_id;
                this.store.dispatch(new FetchValuationData({
                    asset_class_id: params.asset_class_id,
                    valuation_id: params.valuation_id,
                })); // fetch data
                this.activatedRoute.data.subscribe(res => {
                    this.toeData = Object.assign({}, res.toeData.data) as ToeModel;
                    this.assetClass = Object.assign({}, res.assetClassData.data) as AssetClassModel;
                    this.assetClassDet = Object.assign({}, res.assetClassDet.data) as AssetClassDetailModel;
                    this.assignment = Object.assign({}, res.assignment.data) as AssignmentModel;
                    this.crService.setComponentsAll(this.smUtilityService, this.assetClassDet, this.assetClass);
                    this.crService.setConditionRatingsFromRatingModels(res.subData.ratings);
                    this.crService.setSchemeId(this.assetClassDet.aboutProperty.floor_numbering_scheme_id);
                    const valuation = Object.assign({}, res.valuation.data) as ValuationModel;
                    this.lat = this.assetClass.details.latitude;
                    this.lng = this.assetClass.details.longitude;
                    const type = this.getType(this.assetClass.type_id);
                    // Todo: create criterions only for type of tp
                    this.store.dispatch(new CreateCriterions(
                        {
                            assetClass: this.assetClass,
                            toe: this.toeData,
                            valuation: valuation
                        }
                    ));
                    this.store.dispatch(new CreateSizeCriterions({
                        assetType: type,
                        assetClass: this.assetClass,
                        toe: this.toeData,
                    }));
                    this.valuations = Object.assign([], res.assetClassData.data.valuations);
                    this.store.dispatch(new AllTpTaskRequested({tpId: params.asset_class_id}));
                });
            });


        combineLatest([
            this.store.pipe(select(selectAllValComparables)),
            this.store.pipe(select(selectIsDisableAdjustment))
        ]).pipe(
            takeUntil(this._onDestroy),
            map(([comparables, disableAdjustment]) => {
                const isAllConverted = this.isAllComparableConverted(comparables);
                return !isAllConverted || disableAdjustment;
            }))
            .subscribe(res => this._tabAdjusmentSub.next(res)) ;
        this.store.pipe(
            takeUntil(this._onDestroy),
            select(selectAdjustmentProcessFinished),
            map(finished => !finished)
        ).subscribe(res => this._summarySubject.next(res));
        // this.store.pipe(select(selectHasSavedValuationData)).subscribe(hasSaved => {
        //     this.canDiscard = hasSaved
        // });

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectValuationData))
            .subscribe((res: ValuationDataModel) => {
                this.valData = res;
            });

        // Request all linked tables data
        this.store.dispatch(new AllTenureRequested());
        this.store.dispatch(new AllPropertyTypesRequested());
        this.store.dispatch(new AllKeyCategoriesRequested());
        this.store.dispatch(new AllCitiesRequested());
        this.store.dispatch(new AllFloorTypesRequested());
        this.store.dispatch(new AllWindowTypesRequested());
        this.store.dispatch(new AllResidentialTypesRequested());
        this.store.dispatch(new AllGradesRequested());
        this.store.dispatch(new AllHandoverStandardsRequested());
        this.store.dispatch(new AllOfficeLayoutsRequested());
        this.store.dispatch(new AllAssetClassTypesRequested());
        this.store.dispatch(new AllStateRepairsRequested());
        this.store.dispatch(new AllExternalWallsRequested());
        this.store.dispatch(new AllFoundationTypesRequested());
        this.store.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store.dispatch(new AllStandardMeasurementsRequested());
        this.store.dispatch(new AllValuationStandardRequested());
        this.store.dispatch(new AllPremiseOfValuesRequested());
        this.store.dispatch(new AllPropertyDetailReportsRequested({
            tpID: this.assetClass.id
        }));
        this.store.dispatch(new AllAssetClassSERRequested());
        this.store.dispatch(new AllTpFileRequested());
        this.store.dispatch(new AllSpecialAssumptionsRequested());
        this.store.dispatch(new AllUnitMeasurementsRequested());
        this.store.dispatch(new AllSubTypeCategorysRequested());
        this.store.dispatch(new AllSubCategorysRequested());
        this.store.dispatch(new AllChimneyStacksRequested());
        this.store.dispatch(new AllRoofStructuresRequested());
        this.store.dispatch(new AllRoofCoveringsRequested());
        this.store.dispatch(new AllRainwaterPipesRequested());
        this.store.dispatch(new AllMainWallsRequested());
        this.store.dispatch(new AllOutsideDoorsRequested());
        this.store.dispatch(new AllConservatoryPorchesRequested());
        this.store.dispatch(new AllOtherJoinerysRequested());
        this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
        this.store.dispatch(new AllPlaningStatusesRequested());
        this.store.dispatch(new AllQosDegradationsRequested());
        this.store.dispatch(new AllGardensRequested());
        this.store.dispatch(new AllShopFrontTypesRequested());
        this.store.dispatch(new AllBuildingTypesRequested());
        this.store.dispatch(new AllParkingTypesRequested());
        this.store.dispatch(new AllCeilingsRequested());
        this.store.dispatch(new AllWallsAndPartitionsRequested());
        this.store.dispatch(new AllBathroomFittingsRequested());
        this.store.dispatch(new AllBuiltinFittingsRequested());
        this.store.dispatch(new AllCoordinateReferenceSystemsRequested());
        this.store.dispatch(new AllPlaningStatusesRequested());
        this.router.events.forEach(event => {
            if (event instanceof NavigationEnd) {
                this.store.dispatch(new RestartCriterionState());
                this.store.dispatch(new RestartSizeCriterionState());
                this.store.dispatch(new RestartValuationAdjustmentState());
                this.store.dispatch(new valuationActions.ResetValuationState());
                this.store.dispatch(new RestartValuationDataState())
            }
        })
    }


    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }


    /**
     * Returns page title
     */
    getComponentTitle(): string {
        return this.valData ?
            `Valuation: "${this.assetClass.name}" - "${this.valData.valuation.approaches_to_value_name}" - "${this.valData.valuation.methods_to_value_name}" ${this.readonly ? ' (READONLY)' : ''}` :
            'Valuation: Loading data';
    }

    private isAllComparableConverted(comparables: any[]): boolean {
        let atLeastOneNotConverted = false;
        comparables.forEach(c => {
            c.tenures.forEach(t => {
                if (t.currency !== this.assetClass.country_currency) {
                    const conv = t.conversion.find(con => con.quote_currency === this.assetClass.country_currency);
                if (conv) {
                    const convertedValue = Number(t.total_consideration) * Number(conv.exchange_rate);
                    if (convertedValue <= 0) {
                        atLeastOneNotConverted = true;
                    }
                } else {
                    atLeastOneNotConverted = true;
                }
                }
            })
        });
        return !atLeastOneNotConverted;
    }

    back() {
        this.location.back();
    }

    /**
     * On Submit
     */
    onSubmit(isComplete: boolean = false) {
        this.hasError$.next({valid: true, msg: null});
        this.currencyExchangeService.bulkCreateOrUpdate(this.currencyExchanges).subscribe();
        this.store.dispatch(new ValuationDataStoreActionLoading());
        combineLatest([
            this.store.pipe(select(selectAdjustmentTabData), take(1)),
            this.store.pipe(select(selectAssumptionTabData), take(1)),
            this.store.pipe(select(valuationNotesSelectors.selectAll), take(1)),
        ]).pipe(takeUntil(this._onDestroy)).subscribe(([adjustmentTabData, assumptionTabData, notes]) => {
            if (isComplete) {
                const valid = validateAdjustmentTabData(adjustmentTabData);
                if (!valid) {
                    this.hasError$.next({valid: false, msg: "Consideration is required"});
                    return;
                }
            }
            this.store.dispatch(new ValuationDataStore({
                adjustmentTabData, assumptionTabData, notes, validation: isComplete, toeID: this.toeData.id, userId: this.currentUser.id
            }));
            this.layoutUtilsService.showActionNotification(this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'),MessageType.Update, 3000, true, false);
            this.router.navigate(['../../../', 'dashboard'], {relativeTo: this.activatedRoute});
        });
    }

    /**
     * Reset
     */
    reset() {
    }


    /**
     * Close Alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    selectedTabChange($event) {}

    canDeactivate() {
        if (!this.canDiscard) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
        // return true;
    }

    onTabChange(index: number) {
        this.selectedTab = index;
    }

    private getType(type_id: number): AssetType {
        switch (type_id) {
            case 1:
                return AssetType.Residential;
            case 3:
                return AssetType.Office;
            case 7:
                return AssetType.Warehouse;
            case 17:
                return AssetType.House;
            case 5:
                return AssetType.RetailShop;
            case 11:
                return AssetType.RetailBuilding;
            case 2:
                return AssetType.Parking;
            default:
                return AssetType.Office
        }
    }
}
