import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AssetClassSEROnServerUpdated,
  AssetClassSourceExternalReferenceModel,
  selectUploadTaskUsersByTpId,
  TpFileModel,
  TpFileUpdated,
  TpTaskModel,
} from 'src/app/core/asset_class';
import { AssetClassSERUpdated } from 'src/app/core/asset_class/_actions/asset-class-ser.action';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { Globals } from 'src/app/core/_base/crud/utils/globals.service';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { environment } from 'src/environments/environment';
import * as fromFileUploadActions from '../../../../../../core/file-upload/_actions/file-upload.actions';
import * as fromFileUploadSelectors from '../../../../../../core/file-upload/_selectors/file-upload.selectors';
import * as fromFileUploadState from '../../../../../../core/file-upload/_states/file-upload.state';
import { TpDocumentInfoEditDialogComponent } from '../../tp-document-info-edit-dialog/tp-document-info-edit-dialog.component';

@Component({
  selector: 'kt-toe-required',
  templateUrl: './toe-required.component.html',
  styleUrls: ['./toe-required.component.scss'],
})
export class ToeRequiredComponent implements OnInit, OnDestroy {
  @Input() tpState: number;
  @Input() oldFiles: BehaviorSubject<TpFileModel[]>;
  @Input() readOnly: boolean;
  @Input() folderOnEditMode: string;
  @Input() parentId: number;
  @Input() fileCategory: BehaviorSubject<
    AssetClassSourceExternalReferenceModel[]
  >;
  @Input() descriptionField = false;
  @Input() fileType = '';
  @Input() fileInput: ElementRef<HTMLInputElement>;
  @Input() callUpload$: BehaviorSubject<number>;
  @Input() lastChangedItem: BehaviorSubject<TpFileModel>
  @Input() toeStatus: number;

  @ViewChild('toeRequiredRows', { static: true }) template;

  res$: Observable<any>;

  fileFolder: string;
  uploadFiles: TpFileModel[];
  docs: string[] = [];
  basePath: string;
  fileName: string;
  activeRowIndex = 0;
  addingItemCategory = 0;
  currentUser: User;
  tpTasks: TpTaskModel[] = [];

  dataSource$: BehaviorSubject<TpFileModel[]> = new BehaviorSubject([]);

  _categories: AssetClassSourceExternalReferenceModel[] = [];
  _obsTp: Observable<TpFileModel[]>;
  _obsSER: Observable<AssetClassSourceExternalReferenceModel[]>;
  // lastChangedItem = new BehaviorSubject<TpFileModel>(new TpFileModel());
  subscriptions: Subscription[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private store$: Store<fromFileUploadState.State>,
    private store: Store<AppState>,
    private global: Globals,
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.fileFolder = '';
    this.uploadFiles = [];
    this.docs = ['xlsx', 'xls', 'doc', 'docx'];
    this.basePath = environment.baseApiUrl;
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);

    if (this.fileType === '') {
      this.fileType = 'application/pdf';
    }
    this.resetUpload();

    this.dataSource$.next(this.calculateCatCnt(this.uploadFiles));

    this._obsTp = this.oldFiles.asObservable();
    this._obsSER = this.fileCategory.asObservable();

    this._obsSER.subscribe((res) => {
      this._categories = res;
      this._obsTp.subscribe((result) => {
        this.uploadFiles = [];
        this._categories.forEach((item) => {
          const _newItem = new TpFileModel();
          _newItem.clear();
          _newItem.cat_id = item.source_external_reference.id;
          _newItem.cat_name = item.source_external_reference.name;
          _newItem.ac_ser = item;
          if (item.reference_available) {
            _newItem.client_ref = 2;
          } else {
            _newItem.client_ref = 1;
          }
          if (
            result.filter((value) => value.cat_id == _newItem.cat_id).length < 1
          ) {
            this.uploadFiles.push(_newItem);
          }
        });

        this.uploadFiles = [
          ...this.uploadFiles,
          ...result.filter((value) =>
            this._categories.find(
              (el) => el.source_external_reference.id == value.cat_id
            )
          ),
        ];
        this.dataSource$.next(this.calculateCatCnt(this.uploadFiles));
      });
    });

    this.fileName = '';

    this.res$ = this.store$.pipe(
      select(fromFileUploadSelectors.selectUploadFileRes)
    );

    this.res$.subscribe((res) => {
      if (res && res.tp_id && Number(res.tp_id) == this.parentId) {
        this.global.activeTPId = this.parentId;
        const _item = Object.assign(
          {},
          this.uploadFiles[this.activeRowIndex]
        ) as TpFileModel;
        if (_item.path.length > 0) {
          _item.id = undefined;
        }
        _item.path = res.uploaded_files[0].path;
        _item.name = res.uploaded_files[0].name;
        this.lastChangedItem.next(_item);
      }
    });

    this.store.pipe(take(1), select(currentUser)).subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });

    const s1 = this.store
      .pipe(select(selectUploadTaskUsersByTpId(this.parentId)))
      .subscribe((res) => {
        if (res) {
          this.tpTasks = res;
        }
      });
    this.subscriptions.push(s1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Actions
  editFileReference(obj: TpFileModel) {
    const _title = 'Adding Information';

    const dialogRef = this.dialog.open(TpDocumentInfoEditDialogComponent, {
      data: {
        client_ref: obj.client_ref,
        title: _title,
        descr: obj.ac_ser ? obj.ac_ser.description : '',
      },
      width: '440px',
      position: { top: '50px', right: '50px' },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      let editedObj = Object.assign({}, obj);
      let changedAssetClassSER;
      if (res.description !== null) {
        changedAssetClassSER = this.updateSourceExternalReferenceDesc(
          obj,
          res.description
        );
        if (obj.client_ref === res.ref) {
          return;
        }
      }

      if (obj.id) {
        editedObj.client_ref = res.ref;
      } else {
        editedObj = new TpFileModel();
        editedObj.clear();
        editedObj.cat_id = obj.cat_id;
        editedObj.client_ref = res.ref;
        editedObj.cat_name = res.cat_name;
      }

      if (changedAssetClassSER) {
        editedObj.ac_ser = changedAssetClassSER;
      }

      this.global.activeTPId = this.parentId;
      this.lastChangedItem.next(editedObj);
    });
  }

  deleteUploadedFile(obj) {
    const _title = 'Are you sure?';
    const _description = 'The File "' + obj.cat_name + '" will be deleted';
    const _waitDesciption = 'ToE deleting';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const deletedItem = Object.assign({}, obj) as TpFileModel;
      deletedItem._isEditMode = true;

      this.global.activeTPId = this.parentId;
      this.lastChangedItem.next(deletedItem);
    });
  }

  previewUploadedFile(obj) {
    if (this.docs.indexOf(this.getFileType(obj.name)) > -1) {
      window.open(this.basePath + obj.path + '/' + obj.name);
    } else {
      this.dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: obj.path + '/' + obj.name,
          type: this.getFileType(obj.name),
        },
      });
    }
  }

  addFile(_file: TpFileModel, index) {
    this.addingItemCategory = _file.cat_id;
    this.activeRowIndex = index;
    this.fileInput.nativeElement.click();
    this.callUpload$.next(1)
  }

  uploadFile(event: any) {
    const files: FileList = event.target.files;
    const firstFile = files.item(0);
    this.fileName = firstFile.name;

    const formData = new FormData();
    Array.from(files).forEach((file, i) => {
      formData.append('files[' + i + ']', file, file.name);
    });
    if (this.fileFolder) {
      formData.append('path', this.fileFolder);
    }

    this.store$.dispatch(
      new fromFileUploadActions.UploadToTpAction({
        formData,
        tpId: this.parentId
      })
    );
    // clear the input form
    event.srcElement.value = null;
  }

  // Helpers
  updateSourceExternalReferenceDesc(
    item: TpFileModel,
    desc: string
  ): AssetClassSourceExternalReferenceModel {
    const _cat = this._categories.find(
      (val) => val.source_external_reference_id == item.cat_id
    );
    const editedCat = Object.assign({}, _cat);
    editedCat.description = desc;
    this.store.dispatch(
      new AssetClassSEROnServerUpdated({
        ser: editedCat,
      })
    );
    const updateAssetClassSER: Update<AssetClassSourceExternalReferenceModel> = {
      id: editedCat.id,
      changes: editedCat,
    };
    this.store.dispatch(
      new AssetClassSERUpdated({
        partialSER: updateAssetClassSER,
      })
    );

    const editedTpFile = Object.assign({}, item);
    editedTpFile.ac_ser = editedCat;
    const updateTpFile: Update<TpFileModel> = {
      id: editedTpFile.id,
      changes: editedTpFile,
    };
    this.store.dispatch(
      new TpFileUpdated({
        partialTpFile: updateTpFile,
      })
    );

    return editedCat;
  }

  resetUpload() {
    this.fileFolder = '';
    this.uploadFiles = [];
    this.fileName = '';
    this.store$.dispatch(new fromFileUploadActions.UploadResetAction());
  }

  calculateCatCnt(param: TpFileModel[]) {
    let _res = [];
    let _currCat = 0;
    let _currCnt = 0;
    param.forEach((el) => {
      if (_currCat != el.cat_id) {
        _currCnt = 1;
      } else {
        _currCnt++;
      }
      const _tmpObj = Object.assign({}, el);
      _tmpObj.cat_cnt = _currCnt;
      _currCat = _tmpObj.cat_id;
      _res.push(_tmpObj);
    });
    return _res;
  }

  getFileType(name) {
    return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
  }

  hasCert(catId) {
    const _item = this.tpTasks.find((el) => el.second_id == catId);

    if (_item && this.currentUser) {
      return _item.user_id == this.currentUser.id;
    } else {
      return false;
    }
  }
}
