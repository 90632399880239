// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {WallsAndPartition} from '../_models/walls-and-partition.model';

export enum WallsAndPartitionActionTypes {

    AllWallsAndPartitionsRequested = '[WallsAndPartitions Home Page] All WallsAndPartitions Requested',
    AllWallsAndPartitionsLoaded = '[WallsAndPartitions API] All WallsAndPartitions Loaded',

    WallsAndPartitionOnServerCreated = '[Edit WallsAndPartition Dialog] WallsAndPartition On Server Created',
    WallsAndPartitionCreated = '[Edit WallsAndPartitions Dialog] WallsAndPartitions Created',
    WallsAndPartitionUpdated = '[Edit WallsAndPartition Dialog] WallsAndPartition Updated',
    WallsAndPartitionDeleted = '[WallsAndPartitions List Page] WallsAndPartition Deleted',

    WallsAndPartitionsPageRequested = '[WallsAndPartitions List Page] WallsAndPartitions Page Requested',
    WallsAndPartitionsPageLoaded = '[WallsAndPartitions API] WallsAndPartitions Page Loaded',
    WallsAndPartitionsPageCancelled = '[WallsAndPartitions API] WallsAndPartitions Page Cancelled',

    WallsAndPartitionsPageToggleLoading = '[WallsAndPartitions page] WallsAndPartitions Page Toggle Loading',
    WallsAndPartitionsActionToggleLoading = '[WallsAndPartitions] WallsAndPartitions Action Toggle Loading',

    // trash system

    WallsAndPartitionOnServerRestored = '[WallsAndPartition Trash] WallsAndPartition On Server Restored',
    WallsAndPartitionRestored = '[WallsAndPartition Trash] WallsAndPartition Restored',

    WallsAndPartitionOnServerAdminRestored = '[WallsAndPartition Admin Trash] WallsAndPartition On Server Restored',
    WallsAndPartitionAdminRestored = '[WallsAndPartition Admin Trash] WallsAndPartition Restored',

    WallsAndPartitionDeletedFromTrash = '[WallsAndPartition Trash] WallsAndPartition deleted',
    WallsAndPartitionDeletedFromAdminTrash = '[WallsAndPartition Admin Trash] WallsAndPartition deleted',

    WallsAndPartitionTrash = 'WallsAndPartition Trashed',
    WallsAndPartitionTrashFlushed = 'WallsAndPartition Trash Flushed',

    WallsAndPartitionsClear = '[WallsAndPartitions API] WallsAndPartitions Page Clear',
}

export class WallsAndPartitionOnServerCreated implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionOnServerCreated;

    constructor(public payload: { wallsAndPartition: WallsAndPartition}) {
    }
}

export class WallsAndPartitionCreated implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionCreated;

    constructor(public payload: { wallsAndPartition: WallsAndPartition }) {
    }
}

export class WallsAndPartitionUpdated implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionUpdated;

    constructor(public payload: {
        partialWallsAndPartition: Update<WallsAndPartition>,
        wallsAndPartition: WallsAndPartition
    }) {
    }
}

export class WallsAndPartitionDeleted implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class WallsAndPartitionsPageRequested implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionsPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class WallsAndPartitionsPageLoaded implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionsPageLoaded;

    constructor(public payload: { wallsAndPartitions: WallsAndPartition[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class WallsAndPartitionsPageCancelled implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionsPageCancelled;
}

export class AllWallsAndPartitionsRequested implements Action {
    readonly type = WallsAndPartitionActionTypes.AllWallsAndPartitionsRequested;
}

export class AllWallsAndPartitionsLoaded implements Action {
    readonly type = WallsAndPartitionActionTypes.AllWallsAndPartitionsLoaded;

    constructor(public payload: { wallsAndPartitions: WallsAndPartition[] }) {
    }
}

export class WallsAndPartitionsPageToggleLoading implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class WallsAndPartitionsActionToggleLoading implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class WallsAndPartitionDeletedFromAdminTrash implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class WallsAndPartitionDeletedFromTrash implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class WallsAndPartitionOnServerRestored implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class WallsAndPartitionRestored implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionRestored;

    constructor(public payload: { item: WallsAndPartition }) {
    }
}

export class WallsAndPartitionOnServerAdminRestored implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class WallsAndPartitionAdminRestored implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionAdminRestored;

    constructor(public payload: { item: WallsAndPartition }) {
    }
}

export class WallsAndPartitionTrashFlushed implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionTrashFlushed;

    constructor() {
    }
}

export class WallsAndPartitionsClear implements Action {
    readonly type = WallsAndPartitionActionTypes.WallsAndPartitionsClear;

    constructor() {
    }
}

export type WallsAndPartitionActions = WallsAndPartitionCreated
    | WallsAndPartitionUpdated
    | WallsAndPartitionDeleted
    | WallsAndPartitionsPageRequested
    | WallsAndPartitionsPageLoaded
    | WallsAndPartitionsPageCancelled
    | AllWallsAndPartitionsLoaded
    | AllWallsAndPartitionsRequested
    | WallsAndPartitionOnServerCreated
    | WallsAndPartitionsPageToggleLoading
    | WallsAndPartitionsActionToggleLoading
    | WallsAndPartitionDeletedFromAdminTrash
    | WallsAndPartitionDeletedFromTrash
    | WallsAndPartitionOnServerRestored
    | WallsAndPartitionRestored
    | WallsAndPartitionOnServerAdminRestored
    | WallsAndPartitionAdminRestored
    | WallsAndPartitionTrashFlushed
    | WallsAndPartitionsClear;
