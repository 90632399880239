// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ValuationsState} from '../_reducers/valuation.reducers';
import * as fromValuation from '../_reducers/valuation.reducers';
import {ValuationModel} from '../_models/valuation.model';

export const selectValuationsState = createFeatureSelector<ValuationsState>('valuations');

export const selectValuationById = (valuationId: number) => createSelector(
    selectValuationsState,
    valuationsState => valuationsState.entities[valuationId]
);

export const selectValuationsPageLoading = createSelector(
    selectValuationsState,
    valuationsState => valuationsState.listLoading
);

export const selectValuationsTotalTrashed = createSelector(
    selectValuationsState,
    valuationsState => valuationsState.totalTrashed
);

export const selectValuationsTotalAdminTrashed = createSelector(
    selectValuationsState,
    valuationsState => valuationsState.totalAdminTrashed
);

export const selectValuationsActionLoading = createSelector(
    selectValuationsState,
    valuationsState => valuationsState.actionLoading
);

export const selectLastCreatedValuationId = createSelector(
    selectValuationsState,
    valuationsState => valuationsState.lastCreatedValuationId
);

export const selectValuationsShowInitWaitingMessage = createSelector(
    selectValuationsState,
    valuationsState => valuationsState.showInitWaitingMessage
);

export const selectValuationsInStore = createSelector(
    selectValuationsState,
    valuationsState => {
        const items: ValuationModel[] = [];
        each(valuationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: ValuationModel[] = [];
        if (valuationsState.lastQuery.sortField === 'updated_at') {
            result = httpExtension.sortArrayDateTime(
                items,
                valuationsState.lastQuery.sortField,
                valuationsState.lastQuery.sortOrder
            );
        } else {
            result = httpExtension.sortArray(
                items,
                valuationsState.lastQuery.sortField,
                valuationsState.lastQuery.sortOrder
            );
        }
        return new QueryResultsModel(result, valuationsState.totalCount, '');
    }
);

// export const selectValuationByToeId = (toeId: number) => createSelector(
//     selectValuationsState,
//     valuationsState => {
//         const items: ValuationModel[] = [];
//         each(valuationsState.entities, element => {
//             if (element.toe_id == toeId) {
//                 items.push(element);
//             }
//         });
//         return items;
//     }
// );

export const selectAllValuations = createSelector(
    selectValuationsState,
    fromValuation.selectAll
);
