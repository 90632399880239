// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {BuildingUseStatussService} from '../_services/building-use-status.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allBuildingUseStatussLoaded} from '../_selectors/building-use-status.selectors';
// Actions
import {
    AllBuildingUseStatussLoaded,
    AllBuildingUseStatussRequested,
    BuildingUseStatusActionTypes,
    BuildingUseStatussPageRequested,
    BuildingUseStatussPageLoaded,
    BuildingUseStatusUpdated,
    BuildingUseStatussPageToggleLoading,
    BuildingUseStatusDeleted,
    BuildingUseStatusOnServerCreated,
    BuildingUseStatusCreated,
    BuildingUseStatusAdminRestored,
    BuildingUseStatusDeletedFromAdminTrash,
    BuildingUseStatusDeletedFromTrash,
    BuildingUseStatusOnServerAdminRestored,
    BuildingUseStatusOnServerRestored,
    BuildingUseStatusRestored,
    BuildingUseStatusTrashFlushed,
    BuildingUseStatussActionToggleLoading
} from '../_actions/building-use-status.actions';

@Injectable()
export class BuildingUseStatusEffects {
    showPageLoadingDispatcher = new BuildingUseStatussPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new BuildingUseStatussActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new BuildingUseStatussActionToggleLoading({isLoading: false});

    @Effect()
    loadAllBuildingUseStatuss$ = this.actions$
        .pipe(
            ofType<AllBuildingUseStatussRequested>(BuildingUseStatusActionTypes.AllBuildingUseStatussRequested),
            withLatestFrom(this.store.pipe(select(allBuildingUseStatussLoaded))),
            filter(([action, isAllBuildingUseStatussLoaded]) => !isAllBuildingUseStatussLoaded),
            mergeMap(() => this.service.getAllBuildingUseStatuss()),
            map(res => {
                return new AllBuildingUseStatussLoaded({buildingUseStatuss: res.data});
            })
        );

    @Effect()
    loadBuildingUseStatussPage$ = this.actions$
        .pipe(
            ofType<BuildingUseStatussPageRequested>(BuildingUseStatusActionTypes.BuildingUseStatussPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findBuildingUseStatuss(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new BuildingUseStatussPageLoaded({
                    buildingUseStatuss: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusDeleted>(BuildingUseStatusActionTypes.BuildingUseStatusDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteBuildingUseStatus(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusUpdated>(BuildingUseStatusActionTypes.BuildingUseStatusUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateBuildingUseStatus(payload.buildingUseStatus);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusOnServerCreated>(BuildingUseStatusActionTypes.BuildingUseStatusOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createBuildingUseStatus(payload.buildingUseStatus).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingUseStatusCreated({buildingUseStatus: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushBuildingUseStatusTrash$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusTrashFlushed>(BuildingUseStatusActionTypes.BuildingUseStatusTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusOnServerRestored>(BuildingUseStatusActionTypes.BuildingUseStatusOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingUseStatusRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusOnServerAdminRestored>(BuildingUseStatusActionTypes.BuildingUseStatusOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new BuildingUseStatusAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusDeletedFromTrash>(BuildingUseStatusActionTypes.BuildingUseStatusDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashBuildingUseStatus$ = this.actions$
        .pipe(
            ofType<BuildingUseStatusDeletedFromAdminTrash>(BuildingUseStatusActionTypes.BuildingUseStatusDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: BuildingUseStatussService, private store: Store<AppState>) {
    }
}
