<form class="form" [formGroup]="form">
    <div class="col-md-12 mb-10">
        <h5 class="my-3 text-mad text-uppercase">headline to effective process</h5>
        <hr class="active">
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Choose method" formControlName="method">
                        <mat-option *ngFor="let method of methods; let i = index" [value]="i">
                            {{method}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Method is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="showSubmethods$|async" class="col-md-6">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Choose sub-method" formControlName="subMethod">
                        <mat-option *ngFor="let method of subMethods; let i = index" [value]="i">
                            {{method}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Method is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <ng-container [ngSwitch]="selectedMethod$|async">
        <kt-time-value-of-money-method *ngSwitchCase="0"
            #timeValudOfMoneyMethod 
            [refNum]="refNum"
            [tpCurrency]="tpCurrency"
            [title]="'time value of money'"
            [selectedSubMethod$]="selectedSubMethod$"
            [consideration]="consideration"
            [ratesAndJustifications]="ratesAndJustifications"
            (valueComputed)="valueComputed($event)"></kt-time-value-of-money-method>
        <kt-cash-flow-method *ngSwitchCase="1"
            #cashFlowMethod
            [refNum]="refNum"
            [tpCurrency]="tpCurrency"
            [consideration]="consideration"
            [ratesAndJustifications]="cfmRatesAndJustifications"
            [selected]="selected"
            (valueComputed)="valueComputed($event)"></kt-cash-flow-method>
        <kt-straightline-method 
            *ngSwitchCase="2" 
            #straightLineMethod
            [refNum]="refNum"
            [tpCurrency]="tpCurrency"
            [consideration]="consideration"
            (valueComputed)="valueComputed($event)">
        </kt-straightline-method>
    </ng-container>
</form>