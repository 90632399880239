export interface ValuationsWithTaskInfo {
  valuation: any,
  valuation_index: number,
  scenario: {
    scenario_number: number
  }
}

export interface GroupedValuations {
  valuation_index: number,
  valuations: Array<ValuationsWithTaskInfo>
}

export function groupValuationsWithTaskInfoByMainValuation(valuations: Array<ValuationsWithTaskInfo>): Array<GroupedValuations> {
  if (valuations == null || valuations == undefined || valuations.length == 0) {
    return []
  }

  const last = valuations[valuations.length - 1]
  const valuationsArray: Array<GroupedValuations> = Array.from({length: last.valuation_index + 1})
  for (let i = 0; i < valuations.length; i++) {
    const item = valuations[i]
    if (valuationsArray[item.valuation_index] != undefined) {
      valuationsArray[item.valuation_index].valuations.push(item)
    } else {
      valuationsArray[item.valuation_index] = {valuation_index: item.valuation_index, valuations: [item]}
    }
  }
  return valuationsArray
}