import {Component, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AssetClassDetailModel, AssetClassModel, ValuationModel} from 'src/app/core/asset_class';
import {
    selectAllUnitAreaMeasurements,
    UnitAreaMeasurement
} from 'src/app/core/linked-tables';
import {AppState} from 'src/app/core/reducers';
import {ToeModel} from 'src/app/core/toe';
import {RemoveComparable} from 'src/app/core/valuation/_actions/criterion.actions';
import {SizeCriterionRemoveComparable} from 'src/app/core/valuation/_actions/size_criterion.actions';
import {RemoveComparableValuationAdjustment} from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import {ValuationAdjustmentModel} from 'src/app/core/valuation/_models/valuation-adjustment.model';
import {DeleteEntityDialogComponent} from 'src/app/views/partials/content/crud';
import {selectAllValComparables, selectValuationData} from '../../../../../../core/valuation/_selectors/valuation-data.selector';
import {takeUntil} from 'rxjs/operators';
import {ValuationDataModel} from '../../../../../../core/valuation';
import {UpdateValuationData} from '../../../../../../core/valuation/_actions/valuation-data.actions';
import * as valuationActions from 'src/app/core/valuation/_actions/valuation.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComparableInfoDialogComponent } from '../comparable-info-dialog/comparable-info-dialog.component';

@Component({
    selector: 'kt-adjustment-tab',
    templateUrl: './adjustment-tab.component.html',
    styleUrls: ['./adjustment-tab.component.scss']
})
export class AdjustmentTabComponent implements OnInit, OnDestroy {
    @Input() readonly: boolean;
    @Input() targetProperty: AssetClassModel;
    @Input() tpDetails: AssetClassDetailModel;
    @Input() toe: ToeModel;

    unitOfMeasurement$: BehaviorSubject<UnitAreaMeasurement> = new BehaviorSubject(null);
    comparables: any[];
    valData: ValuationDataModel;
    valuation: ValuationModel;

    loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    adjustments$: BehaviorSubject<ValuationAdjustmentModel[]> = new BehaviorSubject([]);
    protected _onDestroy = new Subject<void>();

    constructor(private store: Store<AppState>,
                private dialog: MatDialog,
                private viewContainerRef: ViewContainerRef,
                private modalService: NgbModal) {
    }
    
    ngOnInit(): void {
        this.loaded$.next(false);
        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectValuationData))
            .subscribe((res: ValuationDataModel) => {
                this.valData = res;
                this.valuation = this.valData.valuation;
                this.loaded$.next(true);
            });

        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllValComparables)).subscribe(res => {
            this.comparables = Object.assign([], res);
        });
        this.store
            .pipe(takeUntil(this._onDestroy))
            .pipe(select(selectAllUnitAreaMeasurements), map(res => {
                if (res) {
                    const um = res.find(item => item.system_id === this.toe.unit_of_measurement_id);
                    this.unitOfMeasurement$.next(um);
                }
            }))
            .subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    removeComparable(data: { index: number, com: any }) {
        const _title = 'Are you sure?';
        const _description = `The comparable ${data.com.ref_num} will be removed`;
        const _waitDesciption = 'Removing comparable';
        const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
            data: {title: _title, description: _description, waitDesciption: _waitDesciption},
            width: '440px'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.store.dispatch(new RemoveComparable({comparableID: data.com.id}));
            this.store.dispatch(new SizeCriterionRemoveComparable({comparableID: data.com.id}));
            this.store.dispatch(new RemoveComparableValuationAdjustment({comID: data.com.id}));
            this.store.dispatch(new valuationActions.RemoveValuation({comID: data.com.id}));
            this.comparables.splice(data.index, 1);
            this.saveComData(this.comparables);
        })
    }

    saveComData(_param) {
        const _item = Object.assign({}, this.valData);
        _item.comparables = _param;
        this.store.dispatch(new UpdateValuationData({item: _item}));
    }

    showOverview(assetClass) {
        const dialogRef = this.dialog.open(ComparableInfoDialogComponent, {
            data: {
                propertySubTypeId: this.targetProperty.type_id,
                targetProperty: this.targetProperty,
                comparables: [assetClass],
                valuation: this.valuation,
                selectedIds: this.comparables.map(el => el.id),
                addButton: false,
                isMulti: false
            },
            width: '80vw',
            viewContainerRef: this.viewContainerRef,
            disableClose: true
        });
        const dialogSub = dialogRef.afterClosed().subscribe(res => {
            this.saveComData(this.comparables.filter(el => res.ids.indexOf(el.id) > -1))
        });
    }

    showMultipleOverview() {
        const dialogRef = this.dialog.open(ComparableInfoDialogComponent, {
            data: {
            propertySubTypeId: this.targetProperty.type_id,
            targetProperty: this.targetProperty,
            comparables: this.comparables,
            selectedIds: this.comparables.map(el => el.id),
            addButton: false,
            isMulti: true
            },
            width: '80vw',
            viewContainerRef: this.viewContainerRef,
            disableClose: true
        })
    }
}