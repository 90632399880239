<div class="d-flex flex-column">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <button class="btn btn-primary" color="primary" (click)="addParticipant()" type="button" *ngIf="!readOnly">
            Add Participant
        </button>
    </div>

    <!-- &lt;!&ndash;<div *ngIf="(participantsSubject| async).value > 0" class="example-container mat-elevation-z0">&ndash;&gt; -->
    <div class="example-container mat-elevation-z0" [ngClass]="{'readonly': readOnly}">
        <mat-table #table [dataSource]="dataSource" matSort>
            <!-- &lt;!&ndash; Position Column &ndash;&gt; -->
            <ng-container matColumnDef="participants_names">
                <mat-header-cell *matHeaderCellDef> Full Name</mat-header-cell>
                <mat-cell *matCellDef="let participant"> {{participant.participants_names}}</mat-cell>
            </ng-container>
            <!-- &lt;!&ndash; Name Column &ndash;&gt; -->
            <ng-container matColumnDef="participants_side">
                <mat-header-cell *matHeaderCellDef> Participant Side</mat-header-cell>
                <mat-cell *matCellDef="let participant"> {{participant.side_name}}</mat-cell>
            </ng-container>
            <!-- &lt;!&ndash; Weight Column &ndash;&gt; -->
            <ng-container matColumnDef="participants_descr">
                <mat-header-cell *matHeaderCellDef> Extra Note</mat-header-cell>
                <mat-cell *matCellDef="let participant; let i = index"> 
                    <textarea 
                        cols="30"
                        rows="1"
                        class="description-input"
                        [value]="participant.participants_descr"
                        (change)="descriptionChange($event, participant, i)"
                        >
                    </textarea>
                </mat-cell>
            </ng-container>
            <!-- &lt;!&ndash; Symbol Column &ndash;&gt; -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let participant;let i = index;">
                    <mat-checkbox class="mr-2" [disabled]="readOnly" [checked]="participant.checked" (change)="checkBoxChange($event, participant)"></mat-checkbox>
                    <button mat-icon-button
                            color="primary"
                            matTooltip="Edit the Participant"
                            (click)="editParticipant(participant, i)"
                            type="button"
                            [disabled]="readOnly">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button mat-icon-button
                            color="warn"
                            matTooltip="Delete Participant"
                            (click)="deleteParticipant(participant)"
                            type="button"
                            *ngIf="participant.ref_id == null"
                            [disabled]="readOnly">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>