// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AssetClassTypeActions, AssetClassTypeActionTypes} from '../_actions/asset-class-type.actions';
// Models
import {AssetClassType} from '../_models/asset-class-type.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AssetClassTypesState extends EntityState<AssetClassType> {
    isAllAssetClassTypesLoaded: boolean;
    queryRowsCount: number;
    queryResult: AssetClassType[];
    lastCreatedAssetClassTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassType> = createEntityAdapter<AssetClassType>();

export const initialAssetClassTypesState: AssetClassTypesState = adapter.getInitialState({
    isAllAssetClassTypesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAssetClassTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassTypesReducer(state = initialAssetClassTypesState, action: AssetClassTypeActions): AssetClassTypesState {
    switch (action.type) {
        case AssetClassTypeActionTypes.AssetClassTypesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassTypeId: undefined
            };
        case AssetClassTypeActionTypes.AssetClassTypesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AssetClassTypeActionTypes.AssetClassTypeOnServerCreated:
            return {
                ...state
            };
        case AssetClassTypeActionTypes.AssetClassTypeCreated:
            return adapter.addOne(action.payload.assetClassType, {
                ...state, lastCreatedAssetClassTypeId: action.payload.assetClassType.id
            });
        case AssetClassTypeActionTypes.AssetClassTypeUpdated:
            return adapter.updateOne(action.payload.partialassetClassType, state);
        case AssetClassTypeActionTypes.AllAssetClassTypesLoaded:
            return adapter.addAll(action.payload.assetClassTypes, {
                ...state, isAllAssetClassTypesLoaded: true
            });
        case AssetClassTypeActionTypes.AssetClassTypesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case AssetClassTypeActionTypes.AssetClassTypesPageLoaded:
            return adapter.addMany(action.payload.assetClassTypes, {
                ...initialAssetClassTypesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case AssetClassTypeActionTypes.AssetClassTypeDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AssetClassTypeActionTypes.AssetClassTypeRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case AssetClassTypeActionTypes.AssetClassTypeDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case AssetClassTypeActionTypes.AssetClassTypeAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AssetClassTypeActionTypes.AssetClassTypeDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AssetClassTypeActionTypes.AssetClassTypeTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
