import { DatePipe } from "@angular/common";
import { Component, HostListener, Inject, Injector, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { NgxMaskPipe } from "ngx-mask";
import { BehaviorSubject, combineLatest, forkJoin, Observable, ObservedValueOf, of, Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import { AssetClassTenureService } from "src/app/core/asset_class/_services/asset-class-tenures.service";
import { AllAssetClassTypesRequested, selectAllBathroomFittings, selectAllBuildingTypes, selectAllBuiltinFittings, selectAllCeilings, selectAllChimneyStacks, selectAllConservatoryPorches, selectAllCoordinateReferenceSystems, selectAllFireplacesChimneyBreasts, selectAllFloorTypes, selectAllFoundationTypes, selectAllGardens, selectAllGrades, selectAllHandoverStandards, selectAllMainWalls, selectAllOtherJoinerys, selectAllOutdoorSpaces, selectAllOutsideDoors, selectAllParkingTypes, selectAllPlaningStatuses, selectAllQosDegradations, selectAllRainwaterPipes, selectAllRoofCoverings, selectAllRoofStructures, selectAllShopFrontTypes, selectAllStateRepairs, selectAllStorageTypes, selectAllWallsAndPartitions, selectAllWindowTypes, selectAssetClassTypeById, selectStandardMeasurementsForAc, selectUnitAreaMeasurementById, StandardMeasurement, UnitAreaMeasurement } from "src/app/core/linked-tables";
import { AppState } from "src/app/core/reducers";
import { AddComparable, RemoveComparable } from "src/app/core/valuation/_actions/criterion.actions";
import { SizeCriterionAddComparable, SizeCriterionRemoveComparable } from "src/app/core/valuation/_actions/size_criterion.actions";
import { AddComparableValuationAdjustment, RemoveComparableValuationAdjustment } from "src/app/core/valuation/_actions/valuation-adjustment.actions";
import * as valuationActions from 'src/app/core/valuation/_actions/valuation.actions';
import { DateAgoPipe } from "src/app/views/theme/pipes/date-ago.pipe";
import { environment } from "src/environments/environment";
import { ComparableDocumentsModalComponent } from "../comparable-documents-modal/comparable-documents-modal.component";
import { LeaseholdInfoModalComponent } from "../leasehold-info-dialog/leasehold-info-modal.component";
import { TextWithPictureSliderModalComponent } from "../text-with-picture-slider-dialog/text-with-picture-slider-modal.component";
import { each, flatten, uniq } from 'lodash'
import { AssetClassModel } from "src/app/core/asset_class";
import { ConditionRatingModalComponent } from "../condition-rating-dialog/condition-rating-modal.component";
import { AssetClassDetailService } from "src/app/core/asset_class/_services/asset-class-detail.service";
import { selectAllSubTypeCategorys, selectAllSubTypeCategorysIds } from "src/app/core/linked-tables/_selectors/sub-type-category.selectors";
import { selectAllSubCategorys } from "src/app/core/linked-tables/_selectors/sub-category.selectors";
import { AccommodationLayoutModalComponent } from "./accommodation-layout-modal/accommodation-layout-modal.component";
import { ServicesAndInfrastructuresComponent } from "src/app/views/pages/shared_components/services-and-infrastructures/services-and-infrastructures.component";
import { SizesModalComponent } from "../sizes-modal/sizes-modal.component";
import { ResidentialExternalAreaModel } from "src/app/core/comparable";
import { TVOMMethodVersion } from "../adjustment-tab/_modals/consideration-modal/tvom-types";
import { ParcelIdentificationModalComponent } from "./parcel-identification-modal/parcel-identification-modal.component";
import { ParcelConsiderationModalComponent } from "./parcel-consideration-modal/parcel-consideration-modal.component";
import { LandAreaModalComponent } from "./land-area-modal/land-area-modal.component";
import { awConst } from 'src/app/app.constants';
import { selectAllValTenures } from "src/app/core/valuation/_selectors/valuation-data.selector";
import { ConsiderationInfoTableComponent } from "./consideration-info-table/consideration-info-table.component";
import { ConditionRatingV2Service } from "../../../services/condition-rating-v2.service";
import { BuildingInfoModalComponent } from "./building-info-modal/building-info-modal.component";
import { StandardMeasurementUtility } from "src/app/core/linked-tables/_services/standard-measurement-utility.service";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

type CellType = {
    info: string;
    unknown: boolean;
    click: any;
    type: 'link' | 'tenure' | 'default' | 'pictures' | 'location' | 'checklist' | 'gray',
    pics: any[],
    check: boolean,
}

type RowType = {
    title: string;
    type: 'default' | 'comparable',
    cells: Record<string, CellType>
}

@Component({
    selector: 'ke-comparable-info-dialog',
    templateUrl: './comparable-info-dialog.component.html',
    styleUrls: ['./comparable-info-dialog.component.scss'],
    providers: [DateAgoPipe, DatePipe, StandardMeasurementUtility]
})
export class ComparableInfoDialogComponent implements OnInit, OnDestroy {
    @ViewChild(ConsiderationInfoTableComponent, {static: false})
    considerationInfoTableComponent: ConsiderationInfoTableComponent;

    public title$: Observable<string>;

    public centerLat: number = 0;
    public centerLng: number = 0;
    icon: google.maps.Icon = {
        url: './assets/media/icons/my_marker.svg',
        labelOrigin: new google.maps.Point(18, 40)
    }
    awConst = awConst;
    comPoints: any[] = [];
    clabel = {
        color: '#000000',
        fontFamily: '',
        fontSize: '14px',
        fontWeight: 'bold',
        className: 'bg-op',
        text: ''
    }

    public tpLat: number = 0;
    public tpLng: number = 0;
    tpIcon: google.maps.Icon = {
        url: './assets/media/icons/pin_red.svg',
        scaledSize: new google.maps.Size(30, 30),
        labelOrigin: new google.maps.Point(18, 22)
    }

    private _complexity$ = new BehaviorSubject<boolean>(true);
    public complexity$ = this._complexity$.asObservable();
    private _show100fields$ = new BehaviorSubject<boolean>(true);
    public comparableInfoRows$: Observable<RowType[]>;
    public tpInfoRows$: Observable<RowType[]>;
    public locationRows$: Observable<RowType[]>;
    public buildingRows$: Observable<RowType[]>;
    public buildingSectionRows$: Observable<RowType[]>;
    public aboutPropertyRows$: Observable<RowType[]>;
    public serviceNInfraRows$: Observable<RowType[]> = of([]);
    public externalAspectRows$: Observable<RowType[]>;
    public internalAspectRows$: Observable<RowType[]>;
    public groundRows$: Observable<RowType[]>;
    public sizeRows$: Observable<RowType[]>;
    public parcelIdentification$: Observable<RowType[]>;
    public parcelConsideration$: Observable<RowType[]>;
    public landAreaRows$: Observable<RowType[]>;

    selectedIds: any[] = [];
    public comparables: any[] = [];
    private _onDestroy: Subject<void> = new Subject();
    acRatings = [];
    standardMeasurements: StandardMeasurement[];
    sm: StandardMeasurement;
    unitAreaMeasurement$: Observable<UnitAreaMeasurement>;
    bedrooms$: Observable<number>;
    externalAreas$: Observable<ResidentialExternalAreaModel[]>;

    rowCnt: Observable<number> = of(null);
    private locationRowCnt = new BehaviorSubject<number>(0);
    private buildingRowCnt = new BehaviorSubject<number>(0);
    private aboutPropertyRowCnt = new BehaviorSubject<number>(0);
    private serviceNInfraRowCnt = new BehaviorSubject<number>(0);
    private externalAspectRowCnt = new BehaviorSubject<number>(0);
    private internalAspectRowCnt = new BehaviorSubject<number>(0);
    private groundRowCnt = new BehaviorSubject<number>(0);
    private sizeRowCnt = new BehaviorSubject<number>(0);
    private parcelIdCnt = new BehaviorSubject<number>(0);
    private parcelConCnt = new BehaviorSubject<number>(0);
    private landAreaRowCnt = new BehaviorSubject<number>(0);

    public garageRange: {
        value: number,
        label: string,
    }[] = [];

    public sections = {
        building: false,
        buildingSection: false,
        services_and_infra: true,
        external_aspect: true,
        internal_aspect: true,
        ground: true,
        land_information: true,
        parcel_identification: true,
        parcel_consideration: true,
        land_area: true
    }

    comparablesData: {com: any, selectedTenure: number}[] = [];

    constructor(
        public dialogRef: MatDialogRef<ComparableInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store$: Store<AppState>,
        private datePipe: DatePipe,
        private dateAgePipe: DateAgoPipe,
        private modalService: NgbModal,
        private dialog: MatDialog,
        private acTenureService: AssetClassTenureService,
        private maskPipe: NgxMaskPipe,
        private viewContainerRef: ViewContainerRef,
        private crService: ConditionRatingV2Service,
        private assetClassDetailService: AssetClassDetailService,
        private smService: StandardMeasurementUtility,
        private subDomainService: SubdomainService
    ) {
        this.selectedIds = this.data.selectedIds.map(id => id);
        this.comparables = this.data.comparables;
        // this.comparablesData = this.comparables.map(com => ({com: com, selectedTenure: null}));
        this.comparables.forEach(com => {
            this.comPoints.push({
                label: {
                    ...this.clabel,
                    text: com.ref_num
                },
                lat: Number(com.locationData.latitude),
                lng: Number(com.locationData.longitude)
            })
        });
        this.garageRange = this.setGarageRange();
    }

    ngOnInit(): void {
        this.store$.select(selectAllValTenures).pipe(takeUntil(this._onDestroy))
        .subscribe(tenures => {
            this.comparablesData = this.comparables.map(com => {
                const t = tenures.find(tn => tn.comId == com.id);
                if (t != undefined)  {
                    return {com: com, selectedTenure: t.selectedTenure};
                }
                if (com.tenures.length == 1) {
                    return {com: com, selectedTenure: com.tenures[0].id};
                }
                return {com: com, selectedTenure: null};
            });
        });
        switch (this.data.propertySubTypeId) {
            case 1: {
                this.sections = {
                    ...this.sections,
                    building: true,
                    services_and_infra: false,
                    external_aspect: false,
                    land_information: false,
                    parcel_identification: false,
                    parcel_consideration: false,
                    land_area: false
                }
                break;
            }
            case 2: {
                this.sections = {
                    ...this.sections,
                    building: this.data.targetProperty.details.sub_type_category_id == 20,
                    external_aspect: false,
                    internal_aspect: false,
                    ground: false
                }
                break;
            }
            case 3: {
                this.sections = {
                    ...this.sections,
                    building: true,
                    services_and_infra: false,
                    external_aspect: false,
                    land_information: false,
                    parcel_identification: false,
                    parcel_consideration: false,
                    land_area: false
                }
                break;
            }
            case 5: {
                this.sections = {
                    ...this.sections,
                    building: true,
                    services_and_infra: false,
                    external_aspect: false,
                    land_information: false,
                    parcel_identification: false,
                    parcel_consideration: false,
                    land_area: false
                };
                break;
            }
        }
        if (!this.data.isMulti) {
            this.title$ = this.store$.select(selectAssetClassTypeById(this.data.propertySubTypeId)).pipe(
                filter(type => type != undefined),
                map(type => type.name),
                map(propertySubTypeName => `${propertySubTypeName} #${this.data.comparables[0].ref_num}`)
            )
        } else {
            this.title$ = of('Comparables Overview');
        }

        this.bedrooms$ = of(this.data.targetProperty.details.bedroom);
        this.externalAreas$ = of(this.data.targetProperty.details.selected_external_area);
        this.unitAreaMeasurement$ = this.store$.select(selectUnitAreaMeasurementById(this.data.targetProperty.details.unit_measurement_id))
        this.store$.pipe(
            select(selectStandardMeasurementsForAc(this.data.propertySubTypeId)))
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.standardMeasurements = [];
                if (res) {
                    this.standardMeasurements = res;
                    if (this.standardMeasurements.length > 0) {
                        this.sm = this.standardMeasurements.find(item => item.id == this.data.targetProperty.details.measurement_standard_id);
                    }
                }
            });

        this.assetClassDetailService.fetchSubData(this.data.targetProperty.id)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res: any) => {
                    if (res) {
                        this.acRatings = res.ratings;
                    }
                });

        this.centerLat = this.comparables.length > 1
            ? Number(this.data.targetProperty.details.locationData.latitude)
            : Number(this.data.comparables[0].locationData.latitude);
        this.centerLng = this.comparables.length > 1
            ? Number(this.data.targetProperty.details.locationData.longitude)
            : Number(this.data.comparables[0].locationData.longitude);
        this.tpLat = Number(this.data.targetProperty.details.locationData.latitude);
        this.tpLng = Number(this.data.targetProperty.details.locationData.longitude);

        this.complexity$.pipe(
            takeUntil(this._onDestroy)
        ).subscribe(complexity => {
            this.tpInfoRows$ = this.createTpInfoRows(this.data.targetProperty);
            this.locationRows$ = this.createLocationRows(this.data.targetProperty, this.comparables);
            this.buildingRows$ = complexity ? this.createBuildingRows(this.data.targetProperty, this.comparables) : of([]);
            this.aboutPropertyRows$ = this.createboutPropertyRows(this.data.targetProperty, this.comparables);
            this.serviceNInfraRows$ = complexity ? this.createServiceNInfraRows(this.data.targetProperty, this.comparables) : of([]);
            this.externalAspectRows$ = complexity ? this.createExternalAspectRows(this.data.targetProperty, this.comparables) : of([]);
            this.internalAspectRows$ = complexity ? this.createInternalAspectRows(this.data.targetProperty, this.comparables) : of([]);
            this.groundRows$ = this.createGroundRows(this.data.targetProperty, this.comparables);
            this.sizeRows$ = this.createSizeRows(this.data.targetProperty, this.comparables);
            this.parcelIdentification$ = complexity ? this.createParcelIdentificationRows(this.data.targetProperty, this.comparables) : of([]);
            this.parcelConsideration$ = complexity ? this.createParcelConsiderationRows(this.data.targetProperty, this.comparables) : of([]);
            this.landAreaRows$ = complexity ? this.createLandAreaRows(this.data.targetProperty, this.comparables) : of([]);

            this.rowCnt = combineLatest([
                this.locationRowCnt,
                this.aboutPropertyRowCnt,
                this.serviceNInfraRowCnt,
                this.externalAspectRowCnt,
                this.internalAspectRowCnt,
                this.groundRowCnt,
                this.sizeRowCnt,
                this.parcelIdCnt,
                this.parcelConCnt,
                this.landAreaRowCnt,
                this.buildingRowCnt
            ]).pipe(map(([locationRows, aboutPropertyRows, serviceNInfraRows, externalAspectRows, internalAspectRows, sizeRows, groundRowCnt, parcelId, parcelConCnt, landAreaRowCnt, buildingRowCnt]) => {
                let total = 0;
                total = total + locationRows + aboutPropertyRows + sizeRows + groundRowCnt;
                if (complexity) {
                    total = total + serviceNInfraRows + externalAspectRows + internalAspectRows + parcelId + parcelConCnt + landAreaRowCnt + buildingRowCnt;
                }
                return total;
            }));
        })
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onNoClick() {
        let tenures = [];
        if (this.considerationInfoTableComponent) {
            const comDatas = this.considerationInfoTableComponent._comparablesData$.value;
            tenures = comDatas
                .filter(cd => this.alreadyAdded(cd.com))
                .map(cd => ({comId: cd.com.id, selectedTenure: cd.selectedTenure}));

        }
        this.dialogRef.close({ids: this.selectedIds, tenureInfo: tenures});
    }

    alreadyAdded(item: any) {
        return this.selectedIds.indexOf(item.id) > -1;
    }

    addItem(item: any) {
        let tenures = [];
        if (this.considerationInfoTableComponent) {
            const comDatas = this.considerationInfoTableComponent._comparablesData$.value;
            tenures = comDatas.map(cd => ({comId: cd.com.id, selectedTenure: cd.selectedTenure}));
            if (this.alreadyAdded(item)) {
                tenures = tenures.filter(t => t.comId != item.id);
            } else {
                tenures = tenures; 
            }
        }
        if (this.alreadyAdded(item)) {
            this.store$.dispatch(new RemoveComparable({ comparableID: item.id }));
            this.store$.dispatch(new SizeCriterionRemoveComparable({ comparableID: item.id }));
            this.store$.dispatch(new RemoveComparableValuationAdjustment({ comID: item.id }));
            this.store$.dispatch(new valuationActions.RemoveValuation({ comID: item.id }));
            this.selectedIds.splice(this.selectedIds.indexOf(item.id), 1);
        } else {
            const t = tenures.find(t => t.comId == item.id);
            if (t && t.selectedTenure == null) {
                return;
            }
            this.selectedIds.push(item.id);
            this.store$.dispatch(new AddComparable({ comparable: item, selectedTenure: t ? t.selectedTenure : null, targetProperty: this.data.targetProperty, valuation: this.data.valuation }));
            this.store$.dispatch(new SizeCriterionAddComparable({ comparable: item }))
            this.store$.dispatch(new AddComparableValuationAdjustment({ com: item }))
            this.store$.dispatch(new valuationActions.AddValuation({ com: item }))
        }
        this.dialogRef.close({ids: this.selectedIds, tenureInfo: tenures});
    }

    show100fields(event: MatCheckboxChange) {
        this._show100fields$.next(event.checked);
    }

    toggleComplexity(event: MatCheckboxChange) {
        this._complexity$.next(event.checked);
    }

    private createComparableInfoRows(comparables: any[]): Observable<RowType[]> {
        const obs$: Observable<any[]>[] = []
        comparables.forEach(comparable => {
            obs$.push(this.acTenureService.getByAc(comparable.id, this.data.propertySubTypeId).pipe(map(res => res.data)));
        })
        return combineLatest([
            this.store$.select(selectAssetClassTypeById(this.data.propertySubTypeId)),
            forkJoin(obs$)
        ]).pipe(
            map(([subType, tenuresRes]) => {
                const tenures = flatten(tenuresRes);
                const defaultCell: CellType = {
                    info: '',
                    unknown: false,
                    type: 'default',
                    click: null,
                    pics: [],
                    check: false
                }
                const defaultRow: RowType = {
                    title: '',
                    type: 'default',
                    cells: {}
                }
                const rows: RowType[] = [];
                const databaseRow: RowType = {
                    ...defaultRow,
                    title: 'Database Information',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const databaseInfo: CellType = {
                        ...defaultCell,
                        info: `Added to the database by ${comparable.createdBy} on ${this.datePipe.transform(comparable.created_at, 'dd MMM yyyy')} - (${this.dateAgePipe.transform(comparable.created_at)})`,
                    }
                    databaseRow.cells[comparable.ref_num] = databaseInfo;
                })
                rows.push(databaseRow);

                const typeInfoRow: RowType = {
                    ...defaultRow,
                    title: "Comparable Type",
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const typeInfo: CellType = {
                        ...defaultCell,
                        info: subType ? subType.name : ""
                    }
                    typeInfoRow.cells[comparable.ref_num] = typeInfo;
                })
                rows.push(typeInfoRow);

                const freeholdRow: RowType = {
                    ...defaultRow,
                    title: 'Tenure (Freehold)',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const tenure = tenures.find(item => item.tenure == 'freehold' && item.ac_id == comparable.id);
                    const tUnknown = tenure ? false : true;
                    const tenureCell: CellType = {
                        ...defaultCell,
                        info: tUnknown ? 'Unknown' : `Freehold (${this.maskPipe.transform(tenure.total_consideration, 'separator.2', {thousandSeparator: ','})}) (${tenure.currency})`,
                        unknown: tUnknown
                    }
                    freeholdRow.cells[comparable.ref_num] = tenureCell;
                })
                rows.push(freeholdRow);

                const leaseholdRow: RowType = {
                    ...defaultRow,
                    title: 'Tenure (Leasehold)',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const tenure = tenures.find(item => item.tenure == 'leasehold' && item.ac_id == comparable.id);
                    const tUnknown = tenure ? false : true;
                    const tenureCell: CellType = {
                        ...defaultCell,
                        info: tUnknown ? 'Unknown' : `Leasehold (${this.maskPipe.transform(tenure.total_consideration, 'separator.2', {thousandSeparator: ','})} (${tenure.currency}) per month)`,
                        unknown: tUnknown,
                        type: 'tenure',
                        click: this.click('leasehold', { tenure: tenure })
                    }
                    leaseholdRow.cells[comparable.ref_num] = tenureCell;
                });
                rows.push(leaseholdRow);

                const sourceTypeRow: RowType = {
                    ...defaultRow,
                    title: "Source Type",
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const sourceDate = comparable.source.source_type_id == 1
                        ? comparable.source.transaction_date
                        : comparable.source.information_date
                    const sourceType: CellType = {
                        ...defaultCell,
                        info: `${comparable.source.source_type_name} - ${this.datePipe.transform(sourceDate, 'dd MMM yyyy')} - (${this.dateAgePipe.transform(sourceDate)})`
                    }
                    sourceTypeRow.cells[comparable.ref_num] = sourceType;
                })
                rows.push(sourceTypeRow);

                const sourceInfoRow: RowType = {
                    ...defaultRow,
                    title: "Source Information",
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const sourceInfo: CellType = {
                        ...defaultCell,
                        info: comparable.source.source_information_name,
                        unknown: comparable.source.source_information_id == 1
                    }
                    sourceInfoRow.cells[comparable.ref_num] = sourceInfo;
                })
                rows.push(sourceInfoRow)

                const sourceCredRow: RowType = {
                    ...defaultRow,
                    title: 'Source Credibility',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const sourceCredibility: CellType = {
                        ...defaultCell,
                        info: comparable.source.source_credibility_id != -1
                            ? comparable.source.source_credibility_name
                            : 'Unknown',
                        unknown: comparable.source.source_credibility_id == -1
                    }
                    sourceCredRow.cells[comparable.ref_num] = sourceCredibility;
                })
                rows.push(sourceCredRow)

                const sourceDescRow: RowType = {
                    ...defaultRow,
                    title: 'Source Description',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const isUnknown = !comparable.source.validation_source || !comparable.source.validation_source.length
                    const sourceDescription: CellType = {
                        ...defaultCell,
                        info: isUnknown ? 'Unknown' : comparable.source.validation_source,
                        unknown: isUnknown
                    }
                    sourceDescRow.cells[comparable.ref_num] = sourceDescription;
                })
                rows.push(sourceDescRow);

                const sourceWebRow: RowType = {
                    ...defaultRow,
                    title: 'Source Web Address',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const addressUnknown = !comparable.source.web_address || !comparable.source.web_address.length
                    const sourceWeb: CellType = {
                        ...defaultCell,
                        info: addressUnknown ? 'Unknown or Not Applicable' : comparable.source.web_address,
                        unknown: addressUnknown,
                        type: 'link'
                    }
                    sourceWebRow.cells[comparable.ref_num] = sourceWeb;
                })
                rows.push(sourceWebRow);

                const picRow: RowType = {
                    ...defaultRow,
                    title: 'Source Pictures',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const comparableDataSourcePic = [];
                    if (comparable.source.source_picture_1 && comparable.source.source_picture_1.length > 0) {
                        comparableDataSourcePic.push({ name: comparable.source.source_picture_1, path: '', url: this.getUrl('', comparable.source.source_picture_1) });
                    }
                    if (comparable.source.source_picture_2 && comparable.source.source_picture_2.length > 0) {
                        comparableDataSourcePic.push({ name: comparable.source.source_picture_2, path: '', url: this.getUrl('', comparable.source.source_picture_2) });
                    }
                    const picturesUnknown = !comparableDataSourcePic.length
                    const picturesRow: CellType = {
                        ...defaultCell,
                        pics: comparableDataSourcePic,
                        unknown: picturesUnknown,
                        type: 'pictures',
                        click: this.click('pictures', { array: comparableDataSourcePic, title: 'Source pictures' })
                    }
                    picRow.cells[comparable.ref_num] = picturesRow;
                })
                rows.push(picRow);

                const documentRow: RowType = {
                    ...defaultRow,
                    title: 'External Documents Available',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const documentUnknown = !comparable.documents.length;
                    const document: CellType = {
                        ...defaultCell,
                        info: documentUnknown ? 'No documents available' : `${comparable.documents.length} document${comparable.documents.length > 1 ? 's' : ''}`,
                        unknown: documentUnknown,
                        click: this.click('com-documents', { comparable })
                    }
                    documentRow.cells[comparable.ref_num] = document;
                })
                rows.push(documentRow);

                return rows;
            })
        )
    }

    private createTpInfoAORows(tp: AssetClassModel): Observable<RowType[]> {
        const rows: RowType[] = [];
        return of(rows);
    }

    private createTpInfoRows(tp: AssetClassModel): Observable<RowType[]> {
        const rows: RowType[] = [];
        const defaultCell: CellType = {
            info: '',
            unknown: false,
            type: 'default',
            click: null,
            pics: [],
            check: false
        }
        const defaultRow: RowType = {
            title: '',
            type: 'default',
            cells: {},
        }

        const dateOfInspectionRow: RowType = {
            ...defaultRow,
            title: 'Date of Inspection',
            cells: {}
        }
        const inspectionDate = tp.type_of_inspection == 1
            ? tp.details.information_date
            : tp.details.inspection_date;
        const dateOfInspectionCell: CellType = {
            ...defaultCell,
            info: `${this.datePipe.transform(inspectionDate, 'dd MMM yyyy')} - (${this.dateAgePipe.transform(inspectionDate)})`
        }
        dateOfInspectionRow.cells['tp'] = dateOfInspectionCell;
        rows.push(dateOfInspectionRow);

        const limitationsRow: RowType = {
            ...defaultRow,
            title: 'Limitations or restrictions',
            cells: {}
        }
        const limitationsCell: CellType = {
            ...defaultCell,
            info: !tp.details.any_limitations_or_restrictions ? 'No' : `Yes ${tp.details.any_limitations_or_restrictions && tp.details.limitations_desc && tp.details.limitations_desc.length ? ('- ' + tp.details.limitations_desc) : ''}`
        }
        limitationsRow.cells['tp'] = limitationsCell;
        rows.push(limitationsRow);

        if (tp.type_of_inspection == 3 || tp.type_of_inspection == 4) {
            const conditionRatingRow: RowType = {
                ...defaultRow,
                title: 'Condition Rating',
                cells: {}
            }
            const conditionRatingCell: CellType = {
                ...defaultCell,
                info: 'View Condition Rating',
                click: this.click('condition-rating', null)
            }
            conditionRatingRow.cells['tp'] = conditionRatingCell;
            rows.push(conditionRatingRow);
        }

        const locationRow: RowType = {
            ...defaultRow,
            title: 'Location and Surroundings Description',
            cells: {}
        }
        const locationUnknow = !tp.details.locationData.location_surrounding || !tp.details.locationData.location_surrounding.length;
        const locationCell: CellType = {
            ...defaultCell,
            info: locationUnknow ? 'Unknown' : tp.details.locationData.location_surrounding,
            unknown: locationUnknow
        }
        locationRow.cells['tp'] = locationCell;
        rows.push(locationRow);

        return of(rows);
    }

    private createLocationRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this.store$.select(selectAllGrades).pipe(filter(grades => grades != undefined)),
            this._show100fields$
        ]).pipe(map(([grades, show100]) => {
            let countable = 0;
            const rows: RowType[] = [];
            const defaultCell: CellType = {
                info: '',
                unknown: false,
                type: 'default',
                click: null,
                pics: [],
                check: false
            }
            const defaultRow: RowType = {
                title: '',
                type: 'default',
                cells: {}
            }

            const coordinatesRow: RowType = {
                ...defaultRow,
                title: 'Google coordinates',
                cells: {}
            };
            comparables.forEach(comparable => {
                const cLat = Number(comparable.locationData.latitude);
                const cLng = Number(comparable.locationData.longitude);
                const coordinateCell: CellType = {
                    ...defaultCell,
                    info: `${cLat}, ${cLng}`
                }
                coordinatesRow.cells[comparable.ref_num] = coordinateCell;
            });
            const coordinateCell: CellType = {
                ...defaultCell,
                info: `${Number(tp.details.locationData.latitude)}, ${Number(tp.details.locationData.longitude)}`
            };
            coordinatesRow.cells['tp'] = coordinateCell;
            rows.push(coordinatesRow);

            const locationRow: RowType = {
                ...defaultRow,
                title: 'Property Location',
                cells: {}
            }
            comparables.forEach(comparable => {
                const distOfCom = this.distance(
                    Number(comparable.locationData.latitude),
                    Number(comparable.locationData.longitude),
                    Number(tp.details.locationData.latitude),
                    Number(tp.details.locationData.longitude),
                    'K'
                )
                const locationCell: CellType = {
                    ...defaultCell,
                    info: distOfCom == 0 ? 'Located in the same location as Target Property' : `Located <b> ${distOfCom > 1 ? (distOfCom + 'km') : (distOfCom * 1000).toFixed(0) + 'm'} </b> from Target Property`,
                    type: 'location'
                };
                locationRow.cells[comparable.ref_num] = locationCell;
            });
            const locationCell: CellType = {
                ...defaultCell,
                type: 'location'
            };
            locationRow.cells['tp'] = locationCell;
            rows.push(locationRow);

            const countryRow: RowType = {
                ...defaultRow,
                title: 'Country',
                cells: {}
            }
            comparables.forEach(comparable => {
                const countryCell: CellType = {
                    ...defaultCell,
                    info: comparable.locationData.country_name
                }
                countryRow.cells[comparable.ref_num] = countryCell;
            });
            const countryCell: CellType = {
                ...defaultCell,
                info: (tp as any).details.country_name
            };
            countryRow.cells['tp'] = countryCell;
            rows.push(countryRow);

            const cityRow: RowType = {
                ...defaultRow,
                title: 'City',
                cells: {}
            }
            comparables.forEach(comparable => {
                const unknown = comparable.locationData.city_id == -1
                const cityCell: CellType = {
                    ...defaultCell,
                    info: unknown ? 'Unknown' : comparable.locationData.city_name,
                    unknown: unknown
                }
                cityRow.cells[comparable.ref_num] = cityCell;
            });
            const cityUnknown = tp.details.locationData.city_id == -1;
            const cityCell: CellType = {
                ...defaultCell,
                info: cityUnknown ? 'Unknown' : (tp as any).details.city_name,
                unknown: cityUnknown
            };
            cityRow.cells['tp'] = cityCell;
            rows.push(cityRow);

            const zipCodeRow: RowType = {
                ...defaultRow,
                title: 'Zip Code',
                cells: {}
            }
            comparables.forEach(comparable => {
                const zipCodeUnknown = !comparable.locationData.zip_code || !comparable.locationData.zip_code.length;
                const zipCodeCell: CellType = {
                    ...defaultCell,
                    unknown: zipCodeUnknown,
                    info: zipCodeUnknown ? 'Unknown' : comparable.locationData.zip_code
                }
                zipCodeRow.cells[comparable.ref_num] = zipCodeCell;
            })
            const zipCodeUnknown = !tp.details.locationData.zip_code || !tp.details.locationData.zip_code.length;
            const zipCodeCell: CellType = {
                ...defaultCell,
                unknown: zipCodeUnknown,
                info: zipCodeUnknown ? 'Unknown' : tp.details.locationData.zip_code
            }
            zipCodeRow.cells['tp'] = zipCodeCell;
            rows.push(zipCodeRow);

            let locationCountable = true;
            const locationGradeRow: RowType = {
                ...defaultRow,
                title: 'Location Grade',
                cells: {}
            };
            comparables.forEach(comparable => {
                locationCountable = locationCountable && comparable.locationData.location_grade_id > -1;
                const lgUnknown = comparable.locationData.location_grade_id == -1;
                const grade = grades.find(grade => grade.id == comparable.locationData.location_grade_id);
                const lgCell: CellType = {
                    ...defaultCell,
                    unknown: lgUnknown,
                    info: lgUnknown ? 'Unknown' : (grade ? grade.name : '')
                }
                locationGradeRow.cells[comparable.ref_num] = lgCell;
            });
            locationCountable = locationCountable && tp.details.locationData.location_grade_id > -1;
            const lgUnknown = tp.details.locationData.location_grade_id == -1;
            const grade = grades.find(grade => grade.id == tp.details.locationData.location_grade_id);
            const lgCell: CellType = {
                ...defaultCell,
                unknown: lgUnknown,
                info: lgUnknown ? 'Unknown' : (grade ? grade.name : '')
            }
            locationGradeRow.cells['tp'] = lgCell;
            if (locationCountable) {
                locationGradeRow.type = 'comparable';
                countable += 1;
            }
            rows.push(locationGradeRow);


            let addressCountable = true;
            const addressRow: RowType = {
                ...defaultRow,
                title: 'Address',
                cells: {}
            }
            comparables.forEach(comparable => {
                const addressUnknown = !comparable.locationData.address && !comparable.locationData.address.length;
                addressCountable = addressCountable && !addressUnknown;
                const addressCell: CellType = {
                    ...defaultCell,
                    unknown: addressUnknown,
                    info: addressUnknown ? 'Unknown' : comparable.locationData.address
                }
                addressRow.cells[comparable.ref_num] = addressCell;
            })
            const addressUnknown = !tp.details.locationData.address && !tp.details.locationData.address.length;
            addressCountable = addressCountable && !addressUnknown;
            const addressCell: CellType = {
                ...defaultCell,
                unknown: addressUnknown,
                info: addressUnknown ? 'Unknown' : tp.details.locationData.address
            }
            addressRow.cells['tp'] = addressCell;
            if (addressCountable) {
                addressRow.type = 'comparable';
                countable += 1;
            }
            rows.push(addressRow);

            this.locationRowCnt.next(countable);
            return rows;
        }));
    }

    private createBuildingRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this.store$.select(selectAllBuildingTypes),
            this.store$.select(selectAllGrades),
            this.store$.select(selectAllFoundationTypes),
            this._show100fields$,
            this._complexity$
        ]).pipe(map(([buildingTypes, grades, foundationTypes, show100, complexity]) => {
            let countable = 0;
            const rows: RowType[] = [];
            const defaultRow: RowType = {
                title: '',
                type: 'default',
                cells: {}
            }
            const defaultCell: CellType = {
                info: '',
                unknown: false,
                type: 'default',
                click: null,
                pics: [],
                check: false
            };

            const mapping = [
                { field: 'building_name', title: 'Building ID', propertySubTypeID: [5, 1, 3, 2], type: 'text' },
                { field: 'completion_year', title: 'Completion year', propertySubTypeID: [5, 1, 3, 2], type: 'text' },
                { field: 'building_type', title: 'Building Type', propertySubTypeID: [5, 1 ,3, 2], type: 'id', colName: 'buildingTypes' },
                { field: 'building_type_comment', title: 'Building Type comment', propertySubTypeID: [5, 1, 3, 2], type: 'text' },
                { field: 'building_grade_id', title: 'Building Grade', propertySubTypeID: [5, 1, 3, 2], type: 'id', colName: 'grades' },
                { field: 'energy_efficiency_grade_id', title: 'Energy Efficiency Grade', propertySubTypeID: [5, 1, 3, 2], type: 'id', colName: 'grades' },
                { field: 'developer', title: 'Developer', propertySubTypeID: [5, 1, 3, 2], type: 'text' },
                { field: 'anchor_tenant', title: 'Primary anchor tenants', propertySubTypeID: [5, 1, 3, 2], type: 'text' },
                { field: 'foundation_type_id', title: 'Foundation type', propertySubTypeID: [5, 1, 3, 2], type: 'id', colName: 'foundationTypes' },
                { field: 'building_description', title: 'Construction description', propertySubTypeID: [5, 1, 3, 2], type: 'text' },
            ]

            // comparables.forEach(comparable => {
            mapping.forEach(map => {
                if (map.propertySubTypeID.includes(this.data.propertySubTypeId)) {
                    switch (map.type) {
                        case 'id': {
                            const tpCols = eval(map.colName).find(item => item.id == tp.details.building[map.field]);
                            const _row = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = eval(map.colName).find(item => {
                                        if (this.data.propertuSubTypeId == 5) {
                                            return item.id == comparable.buildingInfo[map.field]
                                        }
                                        return item.id == comparable.buildingInfo[map.field]
                                    });
                                    return {
                                        val: compCols ? compCols.name : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols ? tpCols.name : null
                            )
                            rows.push(_row.row);
                            countable += _row.countable ? 1 : 0;
                            return;
                        }
                        case 'text': {
                            const tpCols = tp.details.building[map.field];

                            const _row = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    let compCols = undefined;
                                    if (this.data.propertySubTypeId == 5) {
                                        compCols = comparable.buildingInfo[map.field];
                                    } else {
                                        compCols = comparable.buildingInfo[map.field]
                                    }
                                    return {
                                        val: compCols && compCols.length > 0 ? compCols : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols && tpCols.length > 0 ? tpCols : null,
                            )
                            rows.push(_row.row);
                            countable += _row.countable ? 1 : 0;
                            return;
                        }
                    }
                }
            })

            if (this.data.propertySubTypeId == 1) {
                const picRow: RowType = {
                    ...defaultRow,
                    title: 'Pictures',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const pics: any[] = comparable.building_info.pictures.map(pic => ({
                        name: pic.name,
                        path: pic.path,
                        url: this.getUrl(pic.path, pic.name)
                    }));
                    const picturesUnknown = !pics.length;
                    const picturesRow: CellType = {
                        ...defaultCell,
                        pics,
                        unknown: picturesUnknown,
                        type: 'pictures',
                        click: this.click('pictures', { array: comparable.building_info.pictures, title: 'Building Pictures' })
                    }
                    picRow.cells[comparable.ref_num] = picturesRow;
                })
                const pics: any[] = tp.details.building.pictures.filter(el => !el.rating_desc || !el.rating_desc.length).map(pic => ({
                    name: pic.name,
                    path: pic.path,
                    url: this.getUrl(pic.path, pic.name)
                }));
                const picturesUnknown = !pics.length;
                const picturesRow: CellType = {
                    ...defaultCell,
                    pics,
                    unknown: picturesUnknown,
                    type: 'pictures',
                    click: this.click('pictures', { array: tp.details.building.pictures.filter(el => !el.rating_desc || !el.rating_desc.length), title: 'Target Property Pictures' })
                }
                picRow.cells['tp'] = picturesRow;
                rows.push(picRow);
            }

            const buildingInfoRow: RowType = {
                ...defaultRow,
                title: 'Full building info',
                cells: {}
            }
            comparables.forEach(comparable => {
                const cell: CellType = {
                    ...defaultCell,
                    info: 'View full building form',
                    click: this.click('building', {buildingInfo: comparable.buildingInfo, point: {lat: comparable.locationData.latitude, lng: comparable.locationData.longitude}})
                }
                buildingInfoRow.cells[comparable.ref_num] = cell;
            })
            const buildingInfoCell: CellType = {
                ...defaultCell,
                info: 'View full building form',
                click: this.click('building', {buildingInfo: tp.details.buildingInfo, point: {lat: tp.details.locationData.latitude, lng: tp.details.locationData.longitude}})
            }
            buildingInfoRow.cells['tp'] = buildingInfoCell;
            rows.push(buildingInfoRow)

            if (show100) {
                const filteredRows = rows.filter(row => 
                    this._filterShow100(row)
                );
                this.buildingRowCnt.next(filteredRows.filter(row => row.type == 'comparable').length)
                return filteredRows
            }

            this.buildingRowCnt.next(countable);
            return rows;
        }));
    }

    private createboutPropertyRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this.store$.select(selectAllSubTypeCategorys),
            this.store$.select(selectAllSubCategorys),
            this.store$.select(selectAllGrades),
            this.store$.select(selectAllStateRepairs),
            this.store$.select(selectAllHandoverStandards),
            this.store$.select(selectAllFoundationTypes),
            this.store$.select(selectAllParkingTypes),
            this._show100fields$,
            this._complexity$,
        ]).pipe(map(([subTypeCategories, subCategories, grades, stateRepairs, handoverStandards, foundationTypes, parkingTypes, show100, complexity]: any[]) => {
            let countable = 0;
            const rows: RowType[] = [];
            const defaultRow: RowType = {
                title: '',
                type: 'default',
                cells: {}
            }
            const defaultCell: CellType = {
                info: '',
                unknown: false,
                type: 'default',
                click: null,
                pics: [],
                check: false
            };

            let subTypeCategoryCountable = true;
            const subTypeCategoryRow: RowType = {
                ...defaultRow,
                title: 'Sub-Type Category',
                cells: {}
            };
            comparables.forEach(comparable => {
                const subTypeCategory = subTypeCategories.find(stc => stc.id == comparable.sub_type_category_id || stc.id == comparable.subtype_category_id);
                const subTypeCategoryUnknown = subTypeCategory ? false : true;
                subTypeCategoryCountable = subTypeCategoryCountable && !subTypeCategoryUnknown;
                const subTypeCategoryCell: CellType = {
                    ...defaultCell,
                    unknown: subTypeCategoryUnknown,
                    info: subTypeCategoryUnknown ? 'Unknown' : subTypeCategory.name
                };
                subTypeCategoryRow.cells[comparable.ref_num] = subTypeCategoryCell;
            });
            const subTypeCategory = subTypeCategories.find(stc => stc.id == tp.details.sub_type_category_id);
            const subTypeCategoryUnknown = subTypeCategory ? false : true;
            subTypeCategoryCountable = subTypeCategoryCountable && !subTypeCategoryUnknown;
            const subTypeCategoryCell: CellType = {
                ...defaultCell,
                unknown: subTypeCategoryUnknown,
                info: subTypeCategoryUnknown ? 'Unknown' : subTypeCategory.name
            };
            subTypeCategoryRow.cells['tp'] = subTypeCategoryCell;
            if (subTypeCategoryCountable) {
                subTypeCategoryRow.type = 'comparable';
                countable += 1;
            }
            rows.push(subTypeCategoryRow);

            let subCategoryCountable = true;
            const subCategoryRow: RowType = {
                ...defaultRow,
                title: 'Sub Category',
                cells: {}
            };
            comparables.forEach(comparable => {
                const subCategory = subCategories.find(stc => stc.id == comparable.sub_category_id);
                const subCategoryUnknown = subCategory ? false : true;
                subCategoryCountable = subCategoryCountable && !subCategoryUnknown;
                const subCategoryCell: CellType = {
                    ...defaultCell,
                    unknown: subCategoryUnknown,
                    info: subCategoryUnknown || subCategory == undefined ? 'Unknown' : subCategory.name
                };
                subCategoryRow.cells[comparable.ref_num] = subCategoryCell;
            });
            const subCategory = subCategories.find(stc => stc.id == tp.details.sub_category_id);
            const subCategoryUnknown = subTypeCategory ? false : true;
            subCategoryCountable = subCategoryCountable && !subCategoryUnknown;
            const subCategoryCell: CellType = {
                ...defaultCell,
                unknown: subCategoryUnknown,
                info: subCategoryUnknown || subCategory == undefined ? 'Unknown' : subCategory.name
            };
            subCategoryRow.cells['tp'] = subCategoryCell;
            if (subCategoryCountable) {
                subCategoryRow.type = 'comparable';
                countable += 1;
            }
            rows.push(subCategoryRow);

            const mapping = [
                { field: 'num_of_units', title: 'Number of unit(s)', propertySubTypeID: [2], type: 'number', fieldOfSimple: true},
                { field: 'parking_type_id', title: 'Parking Type', propertySubTypeID: [2], type: 'id', colName: 'parkingTypes', fieldOfSimple: true},
                { field: 'property_grade_id', title: 'Property Grade', propertySubTypeID: [7, 17], type: 'id', colName: 'grades', fieldOfSimple: true },
                { field: 'general_desc|property_general_desc', title: 'Property General description', propertySubTypeID: [1, 3, 7, 17, 5, 2], type: 'text', fieldOfSimple: false },
                { field: 'completion_year', title: 'Completion Year', propertySubTypeID: [7, 17], type: 'text', fieldOfSimple: true },
                { field: 'apprx_year_extended', title: 'Approximate year the property was extended', propertySubTypeID: [7, 17], type: 'text', fieldOfSimple: false },
                { field: 'state_of_repair_id', title: 'State or Repair', propertySubTypeID: [1, 3, 7, 17, 5, 2], type: 'id', colName: 'stateRepairs', fieldOfSimple: true },
                { field: 'handover_standard_id', title: 'Handover Standard', propertySubTypeID: [1, 3, 7, 17, 5, 2], type: 'id', colName: 'handoverStandards', fieldOfSimple: true },
                { field: 'foundation_type_id', title: 'Foundation Type', propertySubTypeID: [7, 17], type: 'id', colName: 'foundationTypes', fieldOfSimple: false },
                { field: 'above_floors', title: 'Number of floors (above ground)', propertySubTypeID: [7, 17], type: 'number', fieldOfSimple: true },
                { field: 'below_floors', title: 'Basement floors', propertySubTypeID: [7, 17], type: 'number', fieldOfSimple: true },
                { field: 'floor_details', title: 'Floor location', propertySubTypeID: [1, 3, 5, 2], type: 'text', fieldOfSimple: true },
                { field: 'energy_efficiency_grade_id', title: 'Energy Efficiency Grade', propertySubTypeID: [1, 3, 7, 17], type: 'id', colName: 'grades', fieldOfSimple: true },
                { field: 'construction_desc', title: 'Construction description', propertySubTypeID: [7, 17], type: 'text', fieldOfSimple: true },
            ];

            // comparables.forEach(comparable => {
            mapping.forEach(map => {
                if (map.propertySubTypeID.includes(this.data.propertySubTypeId)) {
                    switch (map.type) {
                        case 'id': {
                            const tpCols = eval(map.colName).find(item => item.id == tp.details.aboutProperty[map.field]);
                            const _row = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = eval(map.colName).find(item => item.id == comparable[map.field]);
                                    return {
                                        val: compCols ? compCols.name : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols ? tpCols.name : null
                            )
                            if (complexity) {
                                rows.push(_row.row);
                                countable += _row.countable ? 1 : 0;
                            } else if (map.fieldOfSimple) {
                                rows.push(_row.row);
                                countable += _row.countable ? 1 : 0;
                            }
                            return;
                        }
                        case 'text': {
                            const fields = map.field.split('|');
                            let tpCols = '';
                            if (fields.length == 2) {
                                tpCols = tp.details.aboutProperty[fields[0]];
                            } else {
                                tpCols = tp.details.aboutProperty[fields[0]];
                            }

                            const _row = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    let compCols = '';
                                    if (fields.length == 2) {
                                        compCols = comparable.hasOwnProperty(fields[0]) ? comparable[fields[0]] : comparable[fields[1]];
                                    } else {
                                        compCols = comparable[fields[0]];
                                    }
                                    return {
                                        val: compCols && compCols.length > 0 ? compCols : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols && tpCols.length > 0 ? tpCols : null,
                            )
                            if (complexity) {
                                rows.push(_row.row);
                                countable += _row.countable ? 1 : 0;
                            } else if (map.fieldOfSimple) {
                                rows.push(_row.row);
                                countable += _row.countable ? 1 : 0;
                            }
                            return;
                        }
                        case 'number': {
                            const tpCols = tp.details.aboutProperty[map.field];

                            const _row = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = comparable[map.field];
                                    return {
                                        val: compCols,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols,
                                'number'
                            )
                            if (complexity) {
                                rows.push(_row.row);
                                countable += _row.countable ? 1 : 0;
                            } else if (map.fieldOfSimple) {
                                rows.push(_row.row);
                                countable += _row.countable ? 1 : 0;
                            }
                            return;
                        }
                    }
                }
            })
            // })

            if (complexity && this.data.propertySubTypeId != 2) {
                const accommodationLayoutRow: RowType = {
                    ...defaultRow,
                    title: 'Accommodation Layout',
                    type: 'comparable',
                    cells: {}
                }
                let allEmpty = true
                comparables.forEach(comparable => {
                    const isEmpty = this._checkEmptinessOfAccommodation(this.data.propertySubTypeId, comparable.aboveFloor, comparable.belowFloor, comparable.accommodation, comparable.floor_details);
                    const cell: CellType = {
                        ...defaultCell,
                        info: isEmpty ? 'No Information Available' : 'Show Accommodation Layout Table',
                        click: isEmpty ? null : this.click('accommodation', { aboveFloor: comparable.above_floors, belowFloor: comparable.below_floors, accommodation: comparable.accommodation, floors: comparable.floor_details })
                    }
                    accommodationLayoutRow.cells[comparable.ref_num] = cell;
                    if (!isEmpty) {
                        allEmpty = false
                    }
                })
                const isEmpty = this._checkEmptinessOfAccommodation(this.data.propertySubTypeId, tp.details.aboutProperty.above_floors, tp.details.aboutProperty.below_floors, tp.details.accommodation, tp.details.aboutProperty.floor_details);
                const accommodationCell: CellType = {
                    ...defaultCell,
                    info: isEmpty ? 'No Information Available' : 'Show Accommodation Layout Table',
                    click: isEmpty ? null : this.click('accommodation', { aboveFloor: tp.details.aboutProperty.above_floors, belowFloor: tp.details.aboutProperty.below_floors, accommodation: tp.details.accommodation, floors: tp.details.aboutProperty.floor_details }),
                }
                if (!isEmpty) {
                    allEmpty = false
                }
                accommodationLayoutRow.cells['tp'] = accommodationCell;
                if (allEmpty) {
                    accommodationLayoutRow.type = 'default'
                }
                countable += accommodationLayoutRow.type == 'comparable' ? 1 : 0
                rows.push(accommodationLayoutRow);
            }

            if (this.data.propertySubTypeId == 2) {
                const automationRow: RowType = {
                    ...defaultRow,
                    title: 'Automation',
                    cells: {}
                }
                comparables.forEach(comparable => {
                    const cell: CellType = {
                        ...defaultCell,
                        info: comparable.automation == 0 
                            ? 'No' 
                            : comparable.automation_type == 'full'
                                ? 'Automated parking system'
                                : 'Semi-automated parking sytem'

                    }
                    automationRow.cells[comparable.ref_num] = cell;
                });
                const automationCell: CellType = {
                    ...defaultCell,
                    info: tp.details.aboutProperty.automation == 0
                        ? 'No'
                        : tp.details.aboutProperty.automation_type == 'full'
                            ? 'Automated parking system'
                            : 'Semi-automated parking sytem'
                }
                automationRow.cells['tp'] = automationCell;
                rows.push(automationRow);
            }


            const picRow: RowType = {
                ...defaultRow,
                title: 'Pictures',
                cells: {}
            }
            comparables.forEach(comparable => {
                const pics: any[] = comparable.ac_pictures.map(pic => ({
                    name: pic.name,
                    path: pic.path,
                    url: this.getUrl(pic.path, pic.name)
                }));
                const picturesUnknown = !pics.length;
                const picturesRow: CellType = {
                    ...defaultCell,
                    pics,
                    unknown: picturesUnknown,
                    type: 'pictures',
                    click: this.click('pictures', { array: comparable.ac_pictures, title: 'Comparable Property Pictures' })
                }
                picRow.cells[comparable.ref_num] = picturesRow;
            })
            const pics: any[] = tp.details.ac_pictures.filter(el => !el.rating_desc || !el.rating_desc.length).map(pic => ({
                name: pic.name,
                path: pic.path,
                url: this.getUrl(pic.path, pic.name)
            }));
            const picturesUnknown = !pics.length;
            const picturesRow: CellType = {
                ...defaultCell,
                pics,
                unknown: picturesUnknown,
                type: 'pictures',
                click: this.click('pictures', { array: tp.details.ac_pictures.filter(el => !el.rating_desc || !el.rating_desc.length), title: 'Target Property Pictures' })
            }
            picRow.cells['tp'] = picturesRow;
            rows.push(picRow);

            if (show100) {
                const filteredRows = rows.filter(row => this._filterShow100(row));
                this.aboutPropertyRowCnt.next(filteredRows.filter(row => row.type == 'comparable').length)
                return filteredRows
            }

            this.aboutPropertyRowCnt.next(countable);
            return rows;
        }))
    }

    private createServiceNInfraRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        type serviceNInfra = ServicesAndInfrastructuresComponent['serviceAndInfraData']
        if (tp.type_id == 1) {
            return of([]);
        }
        return combineLatest([
            this._show100fields$
        ]).pipe(map(([show100]) => {
            let countable = 0;
            const heatingList =
                [{ id: 1, title: 'Gas' },
                { id: 2, title: 'Electric' },
                { id: 3, title: 'Solid Fuel' },
                { id: 4, title: 'Oil' }]
            const services =
                [{ id: 1, title: "Waste water/sewerage disposal", checked: false },
                { id: 2, title: "Storm water drainage", checked: false },
                { id: 3, title: "Potable water supply", checked: false },
                { id: 4, title: "Electricity (main, solar, off-grid or independently generated)", checked: false },
                { id: 5, title: "Telephony/telecommunications", checked: false },
                { id: 6, title: "Internet access", checked: false },
                { id: 7, title: "Postal service", checked: false },
                { id: 8, title: "Garbage disposal", checked: false },
                { id: 9, title: "Transport (public or other facilities)", checked: false }]
            const defaultCell: CellType = {
                info: '',
                unknown: false,
                type: 'default',
                click: null,
                pics: [],
                check: false
            }
            const defaultRow: RowType = {
                title: '',
                type: 'default',
                cells: {}
            }
            const rows: RowType[] = [];

            let showRoadValues = false;
            const roadRow: RowType = {
                ...defaultRow,
                title: 'Road and other viable access',
                cells: {},
                type: 'comparable'
            }
            const roadCellCreater = (serviceAndInfra: serviceNInfra, ref_num: string) => {
                showRoadValues = showRoadValues || serviceAndInfra.road;
                const cell: CellType = {
                    ...defaultCell,
                    check: serviceAndInfra.road,
                    type: 'checklist'
                }
                roadRow.cells[ref_num] = cell;
            }
            comparables.forEach(comparable => {
                roadCellCreater(comparable.serviceAndInfraData, comparable.ref_num);
            })
            roadCellCreater(tp.details.serviceAndInfraData, 'tp');
            rows.push(roadRow)
            countable += 1;

            if (showRoadValues) {
                const statusNQ = this._simpleDefautRow(
                    'Status and Quality',
                    comparables.map(comparable => ({ val: comparable.serviceAndInfraData.status_n_quality, ref_num: comparable.ref_num })),
                    tp.details.serviceAndInfraData.status_n_quality);
                rows.push(statusNQ.row);
                countable += statusNQ.countable ? 1 : 0;

                const access = this._simpleDefautRow(
                    'Access/egress points (vehicular and pedestrian)',
                    comparables.map(comparable => ({ val: comparable.serviceAndInfraData.access_points, ref_num: comparable.ref_num })),
                    tp.details.serviceAndInfraData.access_points);
                rows.push(access.row);
                countable += access.countable ? 1 : 0;

                const lengthOfRoad = this._simpleDefautRow(
                    'Length of road frontage',
                    comparables.map(comparable => ({ val: comparable.serviceAndInfraData.length_of_road, ref_num: comparable.ref_num })),
                    tp.details.serviceAndInfraData.length_of_road);
                rows.push(lengthOfRoad.row);
                countable += lengthOfRoad.countable ? 1 : 0;

                const ownership = this._simpleDefautRow(
                    'Ownership if in the ownership of another and consent(s)',
                    comparables.map(comparable => ({ val: comparable.serviceAndInfraData.ownership, ref_num: comparable.ref_num })),
                    tp.details.serviceAndInfraData.ownership);
                rows.push(ownership.row);
                countable += ownership.countable ? 1 : 0;
            }

            const centralHeatingRow = this._simpleChecklistRow(
                'Central Heating',
                comparables.map(comparable => ({ val: comparable.serviceAndInfraData.central_heating, ref_num: comparable.ref_num })),
                tp.details.serviceAndInfraData.central_heating
            );
            rows.push(centralHeatingRow);
            heatingList.forEach(heating => {
                const row = this._simpleChecklistRow(
                    heating.title,
                    comparables.map(comparable => ({
                        val: comparable.serviceAndInfraData.heatings.find(id => id == heating.id) ? true : false,
                        ref_num: comparable.ref_num
                    })),
                    tp.details.serviceAndInfraData.heatings.find(id => id == heating.id) ? true : false
                )
                rows.push(row);
            });
            const otherHeating = this._simpleDefautRow(
                'Other Heating',
                comparables.map(comparable => ({
                    val: comparable.serviceAndInfraData.heatings.find(id => id == 5)
                        ? comparable.serviceAndInfraData.other_heating
                        : null,
                    ref_num: comparable.ref_num
                })),
                tp.details.serviceAndInfraData.heatings.find(id => id == 5)
                    ? tp.details.serviceAndInfraData.other_heating
                    : null
            )
            rows.push(otherHeating.row);
            countable += otherHeating.countable ? 1 : 0;

            if (this.data.propertySubTypeId != 2) {
                services.forEach(service => {
                    const row = this._simpleChecklistRow(
                        service.title,
                        comparables.map(comparable => ({
                            val: comparable.serviceAndInfraData.services.find(id => id == service.id) ? true : false,
                            ref_num: comparable.ref_num
                        })),
                        tp.details.serviceAndInfraData.services.find(id => id == service.id) ? true : false
                    )
                    rows.push(row);
                });
                const otherService = this._simpleDefautRow(
                    'Other Service',
                    comparables.map(comparable => ({
                        val: comparable.serviceAndInfraData.services.find(id => id == 10)
                            ? comparable.serviceAndInfraData.other_service
                            : null,
                        ref_num: comparable.ref_num
                    })),
                    tp.details.serviceAndInfraData.services.find(id => id == 10)
                        ? tp.details.serviceAndInfraData.other_service
                        : null
                )
                rows.push(otherService.row);
                countable += otherService.countable ? 1 : 0;
            }

            if (show100) {
                const filteredRows = rows.filter(row => 
                    this._filterShow100(row)
                );
                this.serviceNInfraRowCnt.next(filteredRows.filter(row => row.type == 'comparable').length)
                return filteredRows
            }

            this.serviceNInfraRowCnt.next(countable);
            return rows;
        }))
    }

    private createExternalAspectRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this._show100fields$,
            this.store$.select(selectAllChimneyStacks),
            this.store$.select(selectAllRoofCoverings),
            this.store$.select(selectAllRainwaterPipes),
            this.store$.select(selectAllMainWalls),
            this.store$.select(selectAllWindowTypes),
            this.store$.select(selectAllOutsideDoors),
            this.store$.select(selectAllConservatoryPorches),
            this.store$.select(selectAllOtherJoinerys)
        ]).pipe(map(([show100, chimneyStacks, roofCoverings, rainwaterPipes, mainWalls, windowTypes, outsideDoors, conservatories, otherJoineries]: any[]) => {
            let countable = 0;
            const rows: RowType[] = []

            const mapping = [
                { field: 'chimney_stack_id', title: 'Chimney Stack', propertySubTypeID: [17], type: 'id', colName: 'chimneyStacks' },
                { field: 'chimney_stack_comment', title: 'Chimney Stack comment', propertySubTypeID: [17], type: 'text' },
                { field: 'roof_covering_id', title: 'Roof Covering', propertySubTypeID: [7, 17, 11], type: 'id', colName: 'roofCoverings' },
                { field: 'roof_covering_comment', title: 'Roof Covering comment', propertySubTypeID: [7, 17, 11], type: 'text' },
                { field: 'rainwater_pipe_id', title: 'Rainwater pipes and gutters', propertySubTypeID: [7, 17, 11], type: 'id', colName: 'rainwaterPipes' },
                { field: 'rainwater_pipe_comment', title: 'Rainwater pipes and gutters comment', propertySubTypeID: [7, 17, 11], type: 'text' },
                { field: 'main_wall_id', title: 'Main wall', propertySubTypeID: [7, 17, 11], type: 'id', colName: 'mainWalls' },
                { field: 'main_wall_comment', title: 'Main wall comment', propertySubTypeID: [7, 17, 11], type: 'text' },
                { field: 'window_id', title: 'Window', propertySubTypeID: [7, 17, 11], type: 'id', colName: 'windowTypes' },
                { field: 'window_comment', title: 'Window comment', propertySubTypeID: [7, 17, 11], type: 'text' },
                { field: 'outside_door_id', title: 'Outside door', propertySubTypeID: [7, 17, 11], type: 'id', colName: 'outsideDoors' },
                { field: 'outside_door_comment', title: 'Outside door comment', propertySubTypeID: [7, 17, 11], type: 'text' },
                { field: 'conservatory_id', title: 'Conservatory and porches', propertySubTypeID: [17], type: 'id', colName: 'conservatories' },
                { field: 'conservatory_comment', title: 'Conservatory and porches comment', propertySubTypeID: [17], type: 'text' },
                { field: 'other_joinery_id', title: 'Other joinery and finishes', propertySubTypeID: [17], type: 'id', colName: 'otherJoineries' },
                { field: 'other_joinery_comment', title: 'Other joinery and finishes comment', propertySubTypeID: [17], type: 'text' },
            ];

            // comparables.forEach(comparable => {
            mapping.forEach(map => {
                if (map.propertySubTypeID.includes(Number(this.data.propertySubTypeId))) {
                    switch (map.type) {
                        case 'id': {
                            const tpCols = eval(map.colName).find(item => item.id == tp.details.externalAspectData[map.field]);
                            const externalAspect = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = eval(map.colName).find(item => item.id == comparable.externalAspectData[map.field]);
                                    return {
                                        val: compCols ? compCols.name : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols ? tpCols.name : null
                            )
                            rows.push(externalAspect.row);
                            countable += externalAspect.countable ? 1 : 0;
                            return;
                        }
                        case 'text': {
                            const tpCols = tp.details.externalAspectData[map.field];

                            const externalAspectText = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = comparable.externalAspectData[map.field];
                                    return {
                                        val: compCols && compCols.length > 0 ? compCols : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols && tpCols.length > 0 ? tpCols : null,
                            )
                            rows.push(externalAspectText.row);
                            countable += externalAspectText.countable ? 1 : 0;
                            return;
                        }
                    }
                }
            })
            // });

            if (this.data.propertySubTypeId == 7 || this.data.propertySubTypeId == 11) {
                const dock = this._simpleDefautRow(
                    'Loading Docks',
                    comparables.map(comparable => ({ val: comparable.externalAspectData.loading_dock ? 'Yes' : 'No', ref_num: comparable.ref_num })),
                    tp.details.externalAspectData.loading_dock ? 'Yes' : 'No',
                )
                rows.push(dock.row);
                countable += dock.countable ? 1 : 0;

                const dockComment = this._simpleDefautRow(
                    'Loading docks comments',
                    comparables.map(comparable => ({ val: comparable.externalAspectData.loading_dock ? comparable.externalAspectData.loading_dock_comment : null, ref_num: comparable.ref_num })),
                    tp.details.externalAspectData.loading_dock ? tp.details.externalAspectData.loading_dock_comment : null,
                );
                rows.push(dockComment.row);
                countable += dockComment.countable ? 1 : 0;
            }

            const otherAspect = this._simpleDefautRow(
                'Other',
                comparables.map(comparable => ({
                    val: comparable.externalAspectData.has_other ? comparable.externalAspectData.other_aspects : null,
                    ref_num: comparable.ref_num
                })),
                tp.details.externalAspectData.has_other ? tp.details.externalAspectData.other_aspects : null
            );
            rows.push(otherAspect.row);
            countable += otherAspect.countable ? 1 : 0;

            if (show100) {
                const filteredRows = rows.filter(row => {
                    return this._filterShow100(row)
                })
                this.externalAspectRowCnt.next(filteredRows.filter(row => row.type == 'comparable').length)
                return filteredRows
            }

            this.externalAspectRowCnt.next(countable);
            return rows;
        }))
    }

    private createInternalAspectRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this._show100fields$,
            this.store$.select(selectAllRoofStructures),
            this.store$.select(selectAllCeilings),
            this.store$.select(selectAllFloorTypes),
            this.store$.select(selectAllFireplacesChimneyBreasts),
            this.store$.select(selectAllBuiltinFittings),
            this.store$.select(selectAllBathroomFittings),
            this.store$.select(selectAllStorageTypes),
            this.store$.select(selectAllWallsAndPartitions),
            this.store$.select(selectAllShopFrontTypes),
            this.store$.select(selectAllWindowTypes)
        ]).pipe(map(([show100, roofStructures, ceilings, floorTypes, fireplacesChimneyBreasts, builtinFittings, bathroomFittings, storageTypes, wallsAndPartitions, shopFrontTypes, windowTypes]: any[]) => {
            let countable = 0;
            const defaultCell: CellType = {
                info: '',
                unknown: false,
                type: 'default',
                click: null,
                pics: [],
                check: false
            }
            const defaultRow: RowType = {
                title: '',
                type: 'default',
                cells: {}
            }
            const rows: RowType[] = []

            const mapping = [
                { field: 'windows_id', title: 'Windows', propertySubTypeID: [3, 1], type: 'id', colName: 'windowTypes'},
                { field: 'windows_comment', title: 'Windows type comment', propertySubTypeID: [3, 1], type: 'text' },
                { field: 'shop_front_type_id', title: 'Shop front type', propertySubTypeID: [5, 11], type: 'id', colName: 'shopFrontTypes' },
                { field: 'shop_front_type_comment', title: 'Shop front type comment', propertySubTypeID: [5, 11], type: 'text' },
                { field: 'roof_structure_id', title: "Roof Structure", propertySubTypeID: [7, 17, 11], type: 'id', colName: 'roofStructures' },
                { field: 'roof_structure_comment', title: "Roof Structure comments", propertySubTypeID: [7, 17, 11], type: 'text' },
                { field: 'ceiling_id', title: "Ceilings", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'id', colName: 'ceilings' },
                { field: 'ceiling_comment', title: "Ceilings comments", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'text' },
                { field: 'walls_and_partition_id', title: "Walls and partitions", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'id', colName: 'wallsAndPartitions' },
                { field: 'walls_and_partition_comment', title: "Walls and partitions comments", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'text' },
                { field: 'height', title: "Height", propertySubTypeID: [7, 11], type: 'text' },
                { field: 'height_comment', title: "Height comments", propertySubTypeID: [7, 11], type: 'text' },
                { field: 'cubic_content', title: "Cubic content", propertySubTypeID: [7, 11], type: 'text' },
                { field: 'cubic_content_comment', title: "Cubic content comments", propertySubTypeID: [7, 11], type: 'text' },
                { field: 'storage_type_id', title: "Storage type", propertySubTypeID: [7], type: 'id', colName: 'storageTypes' },
                { field: 'storage_type_comment', title: "Storage type comments", propertySubTypeID: [7], type: 'text' },
                { field: 'floor_type_id', title: "Floors", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'id', colName: 'floorTypes' },
                { field: 'floor_type_comment', title: "Floors comments", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'text' },
                { field: 'fireplaces_chimney_breast_id', title: "Fireplaces, chimney breasts and flues", propertySubTypeID: [17], type: 'id', colName: 'fireplacesChimneyBreasts' },
                { field: 'fireplaces_chimney_breast_comment', title: "Fireplaces, chimney breasts and flues comments", propertySubTypeID: [17], type: 'text' },
                { field: 'woodwork', title: "Woodwork", propertySubTypeID: [17], type: 'text' },
                { field: 'woodwork_comment', title: "Floors comments", propertySubTypeID: [17], type: 'text' },
                { field: 'builtin_fitting_ids', title: "Built-in fittings", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'id', colName: 'builtinFittings' },
                { field: 'other_aspects', title: "Other Internal Aspect", propertySubTypeID: [7, 17, 5, 11], type: 'text' },
                { field: 'has_automation', title: "Automation equipment & technology", propertySubTypeID: [7], type: 'checkbox' },
            ]

            const automations = [
                { id: 1, title: "Goods-to-person (GTP)", checked: false },
                { id: 2, title: "Automated Storage and Retrieval Systems (AS/RS)", checked: false },
                { id: 3, title: "Automatic Guided Vehicles (AGVs)", checked: false },
                { id: 4, title: "Autonomous Mobile Robots", checked: false },
                { id: 5, title: "Pick-to-Light and Put-to-Light Systems", checked: false },
                { id: 6, title: "Voice Picking and Tasking", checked: false },
                { id: 7, title: "Automated Sortation Systems", checked: false },
            ]

            // comparables.forEach(comparable => {
            mapping.forEach(map => {
                if (map.propertySubTypeID.includes(Number(this.data.propertySubTypeId))) {
                    switch (map.type) {
                        case 'id': {
                            if (map.colName == 'builtinFittings') {
                                const tpCols = eval(map.colName).filter(( item ) => {
                                    if (tp.details.internalAspectData[map.field]) {
                                        return tp.details.internalAspectData[map.field].split(',').includes(item.id + '')
                                    }
                                    return false
                                    }).reduce((previousValue, currentValue) => previousValue + ', ' + currentValue.name, '');

                                const internalAspect = this._simpleDefautRow(
                                    map.title,
                                    comparables.map(comparable => {
                                        const compCols = eval(map.colName).filter(( item ) => 
                                            comparable.internalAspectData[map.field]
                                                ? comparable.internalAspectData[map.field]
                                                    .split(',')
                                                    .includes(item.id + '')
                                                : false
                                            ).reduce((previousValue, currentValue) => previousValue + ', ' + currentValue.name, '');
                                        return {
                                            val: compCols.length > 0 ? compCols.substring(2) : null,
                                            ref_num: comparable.ref_num
                                        }
                                    }),
                                    tpCols.length > 0 ? tpCols.substring(2) : null,
                                )
                                rows.push(internalAspect.row);
                                countable += internalAspect.countable ? 1 : 0;
                                return;
                            }
                            const tpCols = eval(map.colName).find(item => item.id == tp.details.internalAspectData[map.field]);

                            const internalAspect = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = eval(map.colName).find(item => item.id == comparable.internalAspectData[map.field]);
                                    return {
                                        val: compCols ? compCols.name : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols ? tpCols.name : null,
                            )
                            rows.push(internalAspect.row);
                            countable += internalAspect.countable ? 1 : 0;
                            return;
                        }
                        case 'text': {
                            const tpCols = tp.details.internalAspectData[map.field];

                            const internalAspectText = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = comparable.internalAspectData[map.field];
                                    return {
                                        val: compCols && compCols.length > 0 ? compCols : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols && tpCols.length > 0 ? tpCols : null,
                            )
                            rows.push(internalAspectText.row);
                            countable += internalAspectText.countable ? 1 : 0;
                            return;
                        }
                        case 'checkbox': {
                            const tpCols = tp.details.internalAspectData[map.field];

                            const row = this._simpleChecklistRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = comparable.internalAspectData[map.field];
                                    return {
                                        val: compCols == 1 ? true : false,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols == 1 ? true : false
                            )
                            rows.push(row);
                            return;
                        }
                    }
                }
            })
            // });

            if (Number(this.data.propertySubTypeId) == 7) {
                automations.forEach(automation => {
                    const row = this._simpleChecklistRow(
                        automation.title,
                        comparables.map(comparable => ({
                            val: comparable.internalAspectData.automations.find(id => id == automation.id) ? true : false,
                            ref_num: comparable.ref_num
                        })),
                        tp.details.internalAspectData.automations.find(id => id == automation.id) ? true : false
                    )
                    rows.push(row);
                });
            }

            if (show100) {
                return rows.filter(row => {
                    let allOfThemAvailable = true;
                    Object.keys(row.cells).forEach(propName => {
                        allOfThemAvailable = allOfThemAvailable && !row.cells[propName].unknown
                    })
                    return allOfThemAvailable;
                })
            }

            this.internalAspectRowCnt.next(countable);
            return rows;
        }))
    }
    private createGroundRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this._show100fields$,
            this._complexity$,
            of(this.garageRange),
            this.store$.select(selectAllOutdoorSpaces),
            this.store$.select(selectAllGardens),
        ]).pipe(map(([show100, complexity, garageRanges, outdoorSpaces, gardens]: any[]) => {
            let countable = 0;
            const defaultCell: CellType = {
                info: '',
                unknown: false,
                type: 'default',
                click: null,
                pics: [],
                check: false
            }
            const defaultRow: RowType = {
                title: '',
                type: 'default',
                cells: {}
            }
            const rows: RowType[] = []

            const mapping = [
                { field: 'indoor_garage', title: "Indoor Garage", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'id', colName: 'garageRanges', key: 'value', name: 'label', showOnsimple: true },
                { field: 'indoor_garage_comment', title: "Indoor Garage comments", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'text', showOnsimple: false },
                { field: 'outdoor_garage', title: "Outdoor parking", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'id', colName: 'garageRanges', key: 'value', name: 'label', showOnsimple: true },
                { field: 'outdoor_garage_comment', title: "Outdoor parking comments", propertySubTypeID: [7, 17, 5, 11, 1, 3], type: 'text', showOnsimple: false },
                { field: 'garden_id', title: "Garden", propertySubTypeID: [17], type: 'id', colName: 'gardens', showOnsimple: true },
                { field: 'garden_comment', title: "Garden comments", propertySubTypeID: [17], type: 'text', showOnsimple: false },
                { field: 'outdoor_space_id', title: "Outdoor space", propertySubTypeID: [7, 11], type: 'id', colName: 'outdoorSpaces', showOnsimple: true },
                { field: 'outdoor_space_comment', title: "Outdoor space comments", propertySubTypeID: [7, 11], type: 'text', showOnsimple: false },
                { field: 'external_areas_string', title: "External Areas", propertySubTypeID: [5, 1, 3], type: 'text', showOnsimple: true },
                { field: 'external_area_comment', title: "External Areas comment", propertySubTypeID: [5, 1, 3], type: 'text', showOnsimple: false },
            ]

            // comparables.forEach(comparable => {
            mapping.filter((item) => { if (!item.showOnsimple && !complexity) { return false } return true }).forEach(map => {
                if (map.propertySubTypeID.includes(Number(this.data.propertySubTypeId))) {
                    switch (map.type) {
                        case 'id': {
                            const tpCols = eval(map.colName).find(item => item[map.key ? map.key : 'id'] == tp.details.grounds[map.field]);

                            const internalAspect = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = eval(map.colName).find(item => item[map.key ? map.key : 'id'] == comparable.grounds[map.field]);
                                    return {
                                        val: compCols ? compCols[map.name ? map.name : 'name'] : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols ? tpCols[map.name ? map.name : 'name'] : null,
                            )
                            rows.push(internalAspect.row);
                            countable += internalAspect.countable ? 1 : 0;
                            return;
                        }
                        case 'text': {
                            const tpCols = tp.details.grounds[map.field];

                            const internalAspectText = this._simpleDefautRow(
                                map.title,
                                comparables.map(comparable => {
                                    const compCols = comparable.grounds[map.field];
                                    return {
                                        val: compCols && compCols.length > 0 ? compCols : null,
                                        ref_num: comparable.ref_num
                                    }
                                }),
                                tpCols && tpCols.length > 0 ? tpCols : null,
                            )
                            rows.push(internalAspectText.row);
                            countable += internalAspectText.countable ? 1 : 0;
                            return;
                        }
                    }
                }
            })
            // });

            if (show100) {
                const filtereredRows = rows.filter(row => {
                    return this._filterShow100(row);
                })
                this.groundRowCnt.next(filtereredRows.filter(row => row.type == 'comparable').length)
                return filtereredRows
            }

            this.groundRowCnt.next(countable);
            return rows;
        }))
    }
    private createParcelIdentificationRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this._show100fields$,
            this.store$.select(selectAllCoordinateReferenceSystems)
        ]).pipe(map(([show100, coordinateSystems]) => {
            let countable = 0;
            const rows: RowType[] = [];
            const parcelId = this._simpleDefautRow(
                'Land Parcel ID',
                comparables.map(comparable => ({ val: comparable.add_info_on_land ? comparable.land_parcel_name : null, ref_num: comparable.ref_num })),
                tp.details.add_info_on_land ? tp.details.land_parcel_name : null
            )
            parcelId.row.type = 'default';
            rows.push(parcelId.row);

            const coordinateRef = this._simpleArrayRow(
                'Coordinate Reference System',
                comparables.map(comparable => ({ val: comparable.add_info_on_land ? comparable.coordinate_reference_system_id : -1, ref_num: comparable.ref_num })),
                tp.details.add_info_on_land ? tp.details.coordinate_reference_system_id : -1,
                coordinateSystems
            )
            rows.push(coordinateRef.row);
            countable = coordinateRef.countable ? 1 : 0;

            const mapRow: RowType = {
                title: 'Map',
                cells: {},
                type: 'default'
            }
            const cellCreater = (ref_num: string, points, lat, lng) => {
                const unknown = !points.length;
                const cell: CellType = {
                    info: unknown ? 'No points' : 'Show in map',
                    unknown,
                    pics: [],
                    check: false,
                    type: 'default',
                    click: this.click('parcel-identification', { points, centerLat: Number(lat), centerLng: Number(lng) })
                }
                mapRow.cells[ref_num] = cell;
            }
            comparables.forEach(comparable => {
                cellCreater(comparable.ref_num, comparable.add_info_on_land ? comparable.points : [], comparable.locationData.latitude, comparable.locationData.longitude);
            });
            cellCreater('tp', tp.details.add_info_on_land ? tp.details.points : [], tp.details.locationData.latitude, tp.details.locationData.longitude);
            rows.push(mapRow);

            if (show100) {
                const filteredRows = rows.filter(row => {
                    return this._filterShow100(row)
                });
                this.parcelIdCnt.next(filteredRows.filter(row => row.type == 'comparable').length)
                return filteredRows
            }

            this.parcelIdCnt.next(countable);
            return rows;
        }))
    }
    private createParcelConsiderationRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this._show100fields$
        ]).pipe(map(([show100]) => {
            let countable = 0;
            const rows: RowType[] = []

            const otherCons = this._simpleDefautRow(
                'Land Comments',
                comparables.map(comparable => ({ val: comparable.has_parcel_consideration ? comparable.land_comment : null, ref_num: comparable.ref_num })),
                tp.details['has_parcel_consideration'] ? tp.details['land_comment'] : null,
            );
            rows.push(otherCons.row);
            countable += otherCons.countable ? 1 : 0;

            const mapRow: RowType = {
                title: 'Parcel Consideration Components',
                cells: {},
                type: 'default'
            }
            const cellCreater = (ref_num: string, parcel_tenures: any[]) => {
                const unknown = !parcel_tenures.length;
                const cell: CellType = {
                    info: unknown ? 'No Components' : 'Parcel Consideration Breakdown',
                    unknown,
                    pics: [],
                    check: false,
                    type: ref_num == 'tp' ? 'gray' : 'default',
                    click: this.click('parcel-consideration', { parcel_tenures })
                }
                mapRow.cells[ref_num] = cell;
            }
            comparables.forEach(comparable => {
                cellCreater(comparable.ref_num, comparable.has_parcel_consideration ? comparable.parcel_tenures : []);
            });
            cellCreater('tp', tp.details['has_parcel_consideration'] ? tp.details['parcel_tenures'] : []);
            rows.push(mapRow);

            if (show100) {
                const frows = rows.filter(row => {
                    return this._filterShow100(row)
                })
                this.parcelConCnt.next(frows.filter(row => row.type == 'comparable').length)
                return frows
            }

            this.parcelConCnt.next(countable);
            return rows;
        }))
    }

    private createLandAreaRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        return combineLatest([
            this._show100fields$,
            this.store$.select(selectAllPlaningStatuses),
            this.store$.select(selectAllQosDegradations)
        ]).pipe(map(([show100, planningStatuses, qosDegradations]) => {
            let countable = 0;
            const rows: RowType[] = []

            const planningStatus = this._simpleArrayRow(
                'Planning Status of the land parcel',
                comparables.map(comparable => ({ val: comparable.add_info_on_land ? comparable.landArea.planning_status_id : -1, ref_num: comparable.ref_num })),
                tp.details.add_info_on_land ? tp.details.landArea.planning_status_id : -1,
                planningStatuses
            );
            rows.push(planningStatus.row);
            countable += planningStatus.countable ? 1 : 0;

            const qos = this._simpleArrayRow(
                'Quality of soil and degradation',
                comparables.map(comparable => ({ val: comparable.add_info_on_land ? comparable.landArea.qos_degradation_id : -1, ref_num: comparable.ref_num })),
                tp.details.add_info_on_land ? tp.details.landArea.qos_degradation_id : -1,
                qosDegradations
            );
            rows.push(qos.row);
            countable += qos.countable ? 1 : 0;

            const envCons = this._simpleDefautRow(
                'Environmental considerations',
                comparables.map(comparable => ({ val: comparable.add_info_on_land ? comparable.landArea.env_considerations : null, ref_num: comparable.ref_num })),
                tp.details.add_info_on_land ? tp.details.landArea.env_considerations : null,
            );
            rows.push(envCons.row);
            countable += envCons.countable ? 1 : 0;

            const otherCons = this._simpleDefautRow(
                'Other considerations',
                comparables.map(comparable => ({ val: comparable.add_info_on_land ? comparable.landArea.other_considerations : null, ref_num: comparable.ref_num })),
                tp.details.add_info_on_land ? tp.details.landArea.other_considerations : null,
            );
            rows.push(otherCons.row);
            countable += otherCons.countable ? 1 : 0;

            const mapRow: RowType = {
                title: 'Land Area Components',
                cells: {},
                type: 'default'
            }
            const cellCreater = (ref_num: string, landAreaComponents: any[], landArea: any) => {
                const unknown = !landAreaComponents.length;
                const cell: CellType = {
                    info: unknown ? 'No Components' : 'Land Area Breakdown',
                    unknown,
                    pics: [],
                    check: false,
                    type: 'default',
                    click: this.click('land-area', { landAreaComponents, landArea })
                }
                mapRow.cells[ref_num] = cell;
            }
            comparables.forEach(comparable => {
                cellCreater(comparable.ref_num, comparable.add_info_on_land ? comparable.points : [], comparable.landArea);
            });
            cellCreater('tp', tp.details.add_info_on_land ? tp.details.landAreaComponents : [], tp.details.landArea);
            rows.push(mapRow);

            if (show100) {
                const filteredRows = rows.filter(row => {
                    return this._filterShow100(row);
                });
                this.landAreaRowCnt.next(filteredRows.filter(row => row.type == 'comparable').length)
                return filteredRows
            }

            this.landAreaRowCnt.next(countable);
            return rows;
        }))
    }
    private _simpleArrayRow(
        title: string,
        comparablesData: { val: number, ref_num: string }[],
        tpData: number,
        arr: any[]): {
            row: RowType,
            countable: boolean
        } {
        let countable = true;
        const row: RowType = {
            title,
            cells: {},
            type: 'default'
        }

        const cellCreater = (val: number, ref_num: string) => {
            const item = arr.find(item => item.id == val);
            const unknown = item ? false : true;
            countable = countable && !unknown;
            const cell: CellType = {
                info: unknown ? 'Unknown' : item.name,
                unknown,
                type: 'default',
                click: null,
                pics: [],
                check: false
            }
            row.cells[ref_num] = cell;
        }
        comparablesData.forEach(data => {
            cellCreater(data.val, data.ref_num)
        });
        cellCreater(tpData, 'tp');
        if (countable) {
            row.type = 'comparable';
        }

        return {
            row,
            countable
        }
    }

    private _simpleDefautRow(title: string, comparablesData: { val: string, ref_num: string }[], tpData: string, type: string = 'string'): {
        row: RowType,
        countable: boolean
    } {
        let countable = true;
        const row: RowType = {
            title,
            cells: {},
            type: 'default'
        }
        const cellCreater = (val: string, ref_num: string) => {
            let unknown = false;
            if (type == 'string') {
                unknown = !val || !val.length;
            } else if (type == 'number') {
                unknown = val == null || val == undefined ? true : false
            }
            countable = countable && !unknown;
            const cell: CellType = {
                info: unknown ? 'Unknown' : val,
                unknown: unknown,
                type: 'default',
                click: null,
                pics: [],
                check: false
            }
            row.cells[ref_num] = cell;
        }
        comparablesData.forEach(data => {
            cellCreater(data.val, data.ref_num);
        });
        cellCreater(tpData, 'tp');
        if (countable) {
            row.type = 'comparable';
        }
        return {
            row,
            countable
        }
    }

    private _simpleChecklistRow(title: string, comparableData: { val: boolean, ref_num: string }[], tpData: boolean): RowType {
        const row: RowType = {
            title,
            cells: {},
            type: 'comparable'
        }
        const cellCreater = (val: boolean, ref_num: string) => {
            const cell: CellType = {
                info: '',
                check: val,
                click: null,
                pics: [],
                unknown: false,
                type: 'checklist'
            }
            row.cells[ref_num] = cell;
        }
        comparableData.forEach(data => {
            cellCreater(data.val, data.ref_num);
        })
        cellCreater(tpData, 'tp');
        return row;
    }

    private createSizeRows(tp: AssetClassModel, comparables: any[]): Observable<RowType[]> {
        let countable = 1;
        const rows: RowType[] = [];
        const defaultCell: CellType = {
            info: '',
            unknown: false,
            type: 'default',
            click: null,
            pics: [],
            check: false
        }
        const defaultRow: RowType = {
            title: '',
            type: 'comparable',
            cells: {}
        }

        this.standardMeasurements.forEach((el) => {
            const sizeRow: RowType = {
                ...defaultRow,
                title: el.name,
                cells: {}
            }
            comparables.forEach(comparable => {
                const compSize = comparable.sizes.find(item => el.id == item.standard_measurement_id);
                if (comparable.standard_measurement_id == el.id) {
                    countable++;
                }
                const lgUnknown = compSize ? false : true;
                const lgCell: CellType = {
                    ...defaultCell,
                    unknown: lgUnknown,
                    info: lgUnknown ? 'N/A' : (tp.details.toe_unit_measurement_id == 1 ? compSize.size_f + ' sqft' : compSize.size_m + ' sqm')
                }
                sizeRow.cells[comparable.ref_num] = lgCell;

                const tpSize = tp.details['sizes'].find(item => el.id == item.standard_measurement_id);
                const tpUnknown = tpSize ? false : true;
                const tpCell: CellType = {
                    ...defaultCell,
                    unknown: tpUnknown,
                    info: tpUnknown ? 'N/A' : (tp.details.toe_unit_measurement_id == 1 ? tpSize.size_f + ' sqft' : tpSize.size_m + ' sqm'),
                    click: tp.instructed_to_measure == 1 
                        ? this.smService.checkHasOwnTable(this.sm.id)
                            ?  this.click('size', null) 
                            : null
                        : null
                }
                sizeRow.cells['tp'] = tpCell;
            });
            let allUnknown = true
            Object.values(sizeRow.cells).forEach(v => {
                if (!v.unknown) {
                    allUnknown = false
                }
            })
            if (!allUnknown) {
                rows.push(sizeRow);
            }
        });
        this.sizeRowCnt.next(countable);
        return of(rows);
    }

    public click(type: 'com-documents' | 'leasehold' | 'pictures' | 'condition-rating' | 'accommodation' | 'size' | 'parcel-identification' | 'parcel-consideration' | 'land-area' | 'building', opts: any) {
        switch (type) {
            case 'com-documents': {
                return () => this.showComparableDocuments(opts.comparable);
            }
            case 'leasehold': {
                return () => this.showLeaseholdInfo(opts.tenure);
            }
            case 'pictures': {
                return (index: number) => this.showThumbnail(opts.array, opts.title, index);
            }
            case 'condition-rating': {
                return () => this.showConditionRating();
            }
            case 'accommodation': {
                return () => this.showAccommodationLayoutTable(this.data.propertySubTypeId, opts.aboveFloor, opts.belowFloor, opts.accommodation, opts.floors);
            }
            case 'size': {
                return () => this.showSizes();
            }
            case 'parcel-identification': {
                return () => this.showParcelIdentification(opts.points, { centerLat: opts.centerLat, centerLng: opts.centerLng });
            }
            case 'parcel-consideration': {
                return () => this.showParcelConsideration(opts.parcel_tenures);
            }
            case 'land-area': {
                return () => this.showLandAreaBreakdown(opts.landAreaComponents, opts.landArea)
            }
            case 'building': {
                return () => this.showBuildingForm(opts.buildingInfo, opts.point);
            }
        }
    }

    private showBuildingForm(buildingInfo, point) {
        this.dialog.open(BuildingInfoModalComponent, {
            data: {
                buildingInfo: buildingInfo,
                point: point
            },
            width: '80vw'
        })
    }


    private showLandAreaBreakdown(landAreaComponents, landArea) {
        this.dialog.open(LandAreaModalComponent, {
            data: {
                landAreaComponents,
                landArea
            },
            width: '80vw'
        });
    }

    private showParcelIdentification(points: any[], { centerLat, centerLng }: { centerLat: any, centerLng: any }) {
        const modalRef = this.modalService.open(ParcelIdentificationModalComponent, {
            windowClass: 'parcel-identification-modal',
            backdrop: 'static'
        });
        modalRef.componentInstance.points = points.map(point => ({ lat: Number(point.lat), lng: Number(point.lng) }));
        modalRef.componentInstance.centerLat = centerLat;
        modalRef.componentInstance.centerLng = centerLng;
    }

    private showParcelConsideration(parcel_tenures: any[]) {
        const modalRef = this.modalService.open(ParcelConsiderationModalComponent, {
            windowClass: 'parcel-consideration-modal',
            backdrop: 'static'
        });
        modalRef.componentInstance.parcel_tenures = parcel_tenures;
    }

    private showComparableDocuments(com) {
        const modalRef = this.modalService.open(ComparableDocumentsModalComponent, {
            windowClass: 'comprable_documents_modal',
            backdrop: 'static'
        });
        modalRef.componentInstance.documents = com.documents;
    }

    showLeaseholdInfo(tenure) {
        const modalRef = this.modalService.open(LeaseholdInfoModalComponent, {
            windowClass: 'leasehold_info_modal',
            backdrop: 'static'
        });
        modalRef.componentInstance.consideration = tenure;
    }

    private showThumbnail(_array, _title, index) {
        const modalRef = this.modalService.open(TextWithPictureSliderModalComponent, {
            windowClass: 'text_with_picture_slider_modal',
            backdrop: 'static'
        });
        modalRef.componentInstance.inputTtems = _array;
        modalRef.componentInstance.inputTitle = _title;
        modalRef.componentInstance.inputTndex = index;
    }

    private getUrl(path, name) {
        let _picture = path + '/' + name;
        if (_picture.charAt(0) == '/') {
            _picture = _picture.substring(1, _picture.length);
        }
        const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
        return imgURL;
    }
    private showConditionRating() {
        const modalRef = this.modalService.open(ConditionRatingModalComponent, {
            windowClass: 'condition_rating_modal',
            backdrop: 'static',
            injector: Injector.create({
                providers: [{
                    provide: ConditionRatingV2Service,
                    useValue: this.crService
                }]
            })
        });
        modalRef.componentInstance.data = {
            ratings: this.acRatings,
            tpDetails: this.data.targetProperty.details
        };
    }
    private distance(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            const radlat1 = Math.PI * lat1 / 180;
            const radlat2 = Math.PI * lat2 / 180;
            const theta = lon1 - lon2;
            const radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == 'K') {
                dist = dist * 1.609344
            }
            if (unit == 'N') {
                dist = dist * 0.8684
            }
            return parseFloat(dist.toFixed(2));
        }
    }
    private showAccommodationLayoutTable(propertySubTypeID: number, aboveFloor: number, belowFloor: number, accommodationLayout: any, floor_details: string) {
        const modalRef = this.modalService.open(AccommodationLayoutModalComponent, {
            windowClass: 'accommodation_layout_info_modal',
            backdrop: 'static'
        });
        let floors;
        if ([1, 2, 3, 5].includes(propertySubTypeID)) {
            floors = this._getFloorsFrom(floor_details);
        } else {
            floors = this._getFloorsFromAboveAndBelow(aboveFloor, belowFloor);
        }
        modalRef.componentInstance.inputData = {
            propertySubTypeID,
            aboveFloor,
            belowFloor,
            accommodationLayout,
            floors
        }
    }

    private _checkEmptinessOfAccommodation(propertySubTypeID: number, aboveFloor: number, belowFloor: number, accommodationLayout: any, floor_details: string): boolean {
        let floors = [];
        if ([1, 2, 3, 5].includes(propertySubTypeID)) {
            floors = this._getFloorsFrom(floor_details);
        } else {
            floors = this._getFloorsFromAboveAndBelow(aboveFloor, belowFloor);
        }
        let isEmpty = true
        if (accommodationLayout.data != null) {
            floors.forEach(floor => {
                if (accommodationLayout.data[floor] != null)  {
                    Object.values(accommodationLayout.data[floor]).forEach(value => {
                        if (value != null && value != undefined) {
                            isEmpty = false
                        }
                    })
                }
            })
        }
        return isEmpty;
    }

    private _getFloorsFromAboveAndBelow(above: number, below: number): number[] {
        const l = -1 * (below ? below : 0);
        const h = (above ? above : 0);
        const numbers: number[] = [];
        for (let i = l; i < 0; i++) {
            numbers.push(i);
        }
        for (let i = 0; i <= h; i++) {
            numbers.push(i);
        }
        return numbers;
    }

    private _getFloorsFrom(data: string) {
        let floors = [];
        if (data) {
            const floorRaws = data.split(',');
            floorRaws.forEach(raw => {
                const _floors = this._getFloors(raw);
                _floors.forEach(f => floors.push(f));
            });
            floors = uniq(floors);
        }
        return floors;
    }
    private _getFloors(raw: string): number[] {
        const splits = raw.split('-');
        const floors: number[] = []
        if (splits.length == 2) {
            const lower = parseInt(splits[0]);
            const upper = parseInt(splits[1]);
            for (let i = lower; i <= upper; i++) {
                floors.push(i);
            }
        } else {
            floors.push(parseInt(splits[0]));
        }
        return floors;
    }
    private showSizes() {
        const modalRef = this.modalService.open(SizesModalComponent, {
            windowClass: 'sizes_modal',
            backdrop: 'static',
            injector: Injector.create({
                providers: [{
                    provide: ConditionRatingV2Service,
                    useValue: this.crService
                }]
            }) 
        });
        const rooms = {
            bath: 0,
            kitchen: 0,
            bedrooms: 0,
            livingRooms: 0,
            toilet: 0
        }
        each(this.data.targetProperty.details.accommodation.data, (col) => {
            Object.entries(col).forEach(([key, value]) => {
                if (rooms.hasOwnProperty(key)) {
                rooms[key] += value != undefined ? Number(value) : 0; 
                }
            })
        })
        modalRef.componentInstance.info = {
            ac_id: this.data.targetProperty.id,
            standard_measurement: this.sm,
            unitAreaMeasurement$: this.unitAreaMeasurement$,
            readonly: true,
            bedrooms$: this.bedrooms$,
            externalAreas$: this.externalAreas$,
            floorAction$: of({
                type: 'remove',
                floors: [],
                scheme_id: this.data.targetProperty.details.aboutProperty.floor_numbering_scheme_id
            }),
            sizeChanges$: this.unitAreaMeasurement$.pipe(
                map(um => ({
                    standard_measurement_id: this.sm.id,
                    unit_area_measurement_id: um.id,
                    unit_area_measurement_name: um.acronym
                }))
            ),
            roomChanges$: of(rooms)
        };
    }

    private setGarageRange(): { value: number, label: string }[] {
        const ranges: { value: number, label: string }[] = [];
        ranges.push({ value: 0, label: '0' });
        ranges.push({ value: 1, label: '1 to 10' });
        ranges.push({ value: 2, label: '11 to 25' });

        let lowerBound = 25;
        let upperBound = 50;
        let i = 3;
        while (upperBound <= 200) {
            ranges.push({ value: i, label: `${lowerBound + 1} to ${upperBound}` });
            lowerBound = upperBound;
            upperBound = 25 + lowerBound;
            i++;
        }
        ranges.push({ value: i, label: '>200' });

        return ranges;
    }
    public imgOnError(e) {
        e.target.src = awConst.IMAGE_NOT_FOUND
    }

    @HostListener('document:keydown.escape', ['$event']) onEscapeKeyHandler(event: KeyboardEvent) {
        if (this.modalService.hasOpenModals()) {
            return
        }
        if (this.dialog.openDialogs.length > 1) {
            return
        }
        this.dialogRef.close()
    }

    private _filterShow100(row: RowType) {
        let atLeaseOneOfThemAvailable = false;
        Object.keys(row.cells).forEach(propName => {
            if (!row.cells[propName].unknown) {
                atLeaseOneOfThemAvailable = true;
            }
        })
        return atLeaseOneOfThemAvailable;
    }
}