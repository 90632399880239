import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { OfficeAdjustmentModel } from '../_models/office-adjustment.model';

export enum OfficeAdjustmentsActionTypes {
    AllOfficeAdjustmentsRequested = '[Office Adjustments List] All Office Adjustments Requested',
    AllOfficeAdjustmentsLoaded = '[Office Adjustments API] All Office Adjustments Loaded',

    OfficeAdjustmentOnServerCreated = '[Edit Office Adjustment] Office Adjustment on Server Created',
    OfficeAdjustmentCreated = '[Edit Office Adjustment] Office Adjustment Created',
    OfficeAdjustmentUpdated = '[Edit Office Adjustment] Office Adjustment Updated',
    OfficeAdjustmentDeleted = '[Office Adjustment Deleted] Office Adjustment Deleted',

    OfficeAdjustmentRestored = '[Office Adjustment Trash] Office Adjustment Restored',
    OfficeAdjustmentOnServerRestored = '[Office Adjustment Trash] Office Adjustment On Server Restored',

    OfficeAdjustmentOnServerAdminRestored = '[Office Adjustment Admin Trash] Office Adjustment On Server Restored',
    OfficeAdjustmentAdminRestored = '[Office Adjustment Admin Trash] Office Adjustment Restored',

    OfficeAdjustmentDeletedFromTrash = '[Office Adjustment Trash] Office Adjustment deleted',
    OfficeAdjustmentDeletedFromAdminTrash = '[Office Adjustment Admin Trash] Office Adjustment deleted',

    OfficeAdjustmentTrash = 'Office Adjustment Trashed',
    OfficeAdjustmentTrashFlushed = 'Office Adjustment Trash Flushed',

    // Page system

    OfficeAdjustmentsPageRequested = '[Office Adjustment List Page] Office Adjustment Page Requested',
    OfficeAdjustmentsPageLoaded = '[Office Adjustment API] Office Adjustment Page Loaded',
    OfficeAdjustmentsPageCancelled = '[Office Adjustment API] Office Adjustment Page Cancelled',

    OfficeAdjustmentsPageToggleLoading = '[Office Adjustment page] Office Adjustment Page Toggle Loading',
    OfficeAdjustmentActionToggleLoading = '[Office Adjustment] Office Adjustment Action Toggle Loading'
}

export class OfficeAdjustmentOnServerCreated implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentOnServerCreated;

    constructor(public payload: { item: OfficeAdjustmentModel }) {
    }
}

export class OfficeAdjustmentCreated implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentCreated;

    constructor(public payload: { item: OfficeAdjustmentModel }) {
    }
}

export class OfficeAdjustmentUpdated implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<OfficeAdjustmentModel>,
        item: OfficeAdjustmentModel
    }) {
    }
}

export class OfficeAdjustmentDeleted implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeAdjustmentsPageRequested implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OfficeAdjustmentsPageLoaded implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentsPageLoaded;

    constructor(public payload: { items: OfficeAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OfficeAdjustmentsPageCancelled implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentsPageCancelled;
}

export class AllOfficeAdjustmentsRequested implements Action {
    readonly type = OfficeAdjustmentsActionTypes.AllOfficeAdjustmentsRequested;
}

export class AllOfficeAdjustmentsLoaded implements Action {
    readonly type = OfficeAdjustmentsActionTypes.AllOfficeAdjustmentsLoaded;

    constructor(public payload: { items: OfficeAdjustmentModel[] }) {
    }
}

export class OfficeAdjustmentsPageToggleLoading implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OfficeAdjustmentActionToggleLoading implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OfficeAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeAdjustmentDeletedFromTrash implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeAdjustmentOnServerRestored implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeAdjustmentRestored implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentRestored;

    constructor(public payload: { item: OfficeAdjustmentModel }) {
    }
}

export class OfficeAdjustmentOnServerAdminRestored implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeAdjustmentAdminRestored implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentAdminRestored;

    constructor(public payload: { item: OfficeAdjustmentModel }) {
    }
}

export class OfficeAdjustmentTrashFlushed implements Action {
    readonly type = OfficeAdjustmentsActionTypes.OfficeAdjustmentTrashFlushed;

    constructor() {
    }
}

export type OfficeAdjustmentActions = OfficeAdjustmentCreated
    | OfficeAdjustmentUpdated
    | OfficeAdjustmentDeleted
    | OfficeAdjustmentsPageRequested
    | OfficeAdjustmentsPageLoaded
    | OfficeAdjustmentsPageCancelled
    | AllOfficeAdjustmentsLoaded
    | AllOfficeAdjustmentsRequested
    | OfficeAdjustmentOnServerCreated
    | OfficeAdjustmentDeletedFromAdminTrash
    | OfficeAdjustmentDeletedFromTrash
    | OfficeAdjustmentOnServerRestored
    | OfficeAdjustmentRestored
    | OfficeAdjustmentOnServerAdminRestored
    | OfficeAdjustmentAdminRestored
    | OfficeAdjustmentTrashFlushed
    | OfficeAdjustmentsPageToggleLoading
    | OfficeAdjustmentActionToggleLoading;