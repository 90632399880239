import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationSRERModel } from "../_models/valuation-srer.model";
import * as valuationSRERActions from '../_actions/valuation-srer.actions';

export interface ValuationSRERState extends EntityState<ValuationSRERModel> {}
export const adapter: EntityAdapter<ValuationSRERModel> = createEntityAdapter();
export const initialValuationSRERState: ValuationSRERState = adapter.getInitialState();

export function valuationSRERReducer(state = initialValuationSRERState, action: valuationSRERActions.Actions): ValuationSRERState {
    switch (action.type) {
        case valuationSRERActions.ActionTypes.DataLoaded: {
            return adapter.setAll(action.payload.data, {
                ...state
            });
        }
        case valuationSRERActions.ActionTypes.DataUpdated: {
            return adapter.updateOne(action.payload.data, {...state});
        }
        default: 
            return state;
    }
}