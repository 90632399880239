<div class="kt-login-body">
  <div class="kt-login-form">
    <!-- <h1>
      <a href="https://www.interval-soft.com/" title="Login Page">
        Login Page
      </a>
    </h1> -->
    <div class="mad-login-form">
      <img src="assets/media/interval/logo.png" class="interval-blue-logo" alt="InterVal logo">
      <div style="background-color: #333; width:450px; border-radius: 4px;">
        <h1 style="color: #fff; font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        margin-top: 0;
        text-align: center; padding-top: 4rem;">Thank You</h1>


        <span class="center">
            <p style="color: #fff; padding-top: 4rem; padding-left: 4rem; padding-right: 4rem; padding-bottom: 2rem; text-align: center">
                You have successfully registered your new account with InterVal. We will send you an email to confirm your registration. You should receive this message within the next 5 minutes. Please, log in to your designated email and look for the message from InterVal to confirm your registration and to get started revolutionizing your property valuations.
            </p>

            <p style="color: #fff; padding-top: 2rem; padding-left: 4rem; padding-right: 4rem; padding-bottom: 4rem; text-align: center">
                Please contact <b>info&#64;interval-soft.com</b> with any questions.
            </p>
        </span>

      </div>

      <div class="sign-up">
        <span class="center">
          <!-- <span class="kt-login-signup-label">Don't have an account yet?</span>&nbsp;&nbsp; -->
          <!-- <a href="/mad-auth/register" class="kt-link kt-login-signup-link">
              Sign Up
          </a> -->
        </span>
      </div>
    </div>
  </div>
</div>
