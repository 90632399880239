import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RetailStoreAdjustmentsState } from "../_reducers/retail-store-adjustment.reducers";
import * as fromAdjustment from '../_reducers/retail-store-adjustment.reducers';
import { RetailStoreAdjustmentModel } from "../_models/retail-store-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectRetailStoreAdjustmentsState = createFeatureSelector<RetailStoreAdjustmentsState>('retail-store-adjustments');

export const selectRetailStoreAdjustmentById = (AdjustmentId: number) => createSelector(
    selectRetailStoreAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllRetailStoreAdjustments = createSelector(
    selectRetailStoreAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllRetailStoreAdjustmentsIds = createSelector(
    selectRetailStoreAdjustmentsState,
    fromAdjustment.selectIds
);

export const allRetailStoreAdjustmentsLoaded = createSelector(
    selectRetailStoreAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectRetailStoreAdjustmentsPageLoading = createSelector(
    selectRetailStoreAdjustmentsState,
    state => state.listLoading
);

export const selectRetailStoreAdjustmentsActionLoading = createSelector(
    selectRetailStoreAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedRetailStoreAdjustmentId = createSelector(
    selectRetailStoreAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectRetailStoreAdjustmentsShowInitWaitingMessage = createSelector(
    selectRetailStoreAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedRetailStoreAdjustmentCount = createSelector(
    selectRetailStoreAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedRetailStoreAdjustmentCount = createSelector(
    selectRetailStoreAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectRetailStoreAdjustmentQueryResult = createSelector(
    selectRetailStoreAdjustmentsState,
    state => {
        const items: RetailStoreAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RetailStoreAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);