<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <div class="card-label">Size Table</div>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="onClose()"
               class="btn btn-icon btn-sm btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <div class="row" style="width: 100%;">
                <kt-sizes-module
                    class="col-lg-12"
                    [assetClassID]="data.ac_id"
                    [sizeChanges$]="data.sizeChanges$"
                    [floorAction$]="data.floorAction$"
                    [bedroom$]="data.bedrooms$"
                    [roomChange$]="data.roomChanges$"
                    [externalAreas$]="data.externalAreas$"
                    [readonly]="data.readonly">
                ></kt-sizes-module>
            </div>
        </mat-dialog-content>
    </div>
</div>