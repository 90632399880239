import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Update} from '@ngrx/entity';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../core/_base/layout';
import {AppState} from '../../../../core/reducers';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {
    KeyCategoryModel,
    KeyCategoryOnServerCreated,
    selectKeyCategoriesActionLoading,
    selectKeyCategoryById,
    selectLastCreatedKeyCategoryId, KeyCategoryUpdated
} from '../../../../core/linked-tables';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {TypesUtilsService} from '../../../../core/_base/crud';
import {FileUploadService} from '../../../../core/file-upload/_services';
import {Location} from '@angular/common';
import { awConst } from 'src/app/app.constants';
import {combineLatest} from 'rxjs'

@Component({
    selector: 'kt-key-category-edit',
    templateUrl: './key-category-edit.component.html',
    styleUrls: ['./key-category-edit.component.scss'],
})


export class KeyCategoryEditComponent implements OnInit, OnDestroy {

    @ViewChild('fileInputImg') fileInputImg: ElementRef;
    awConst = awConst;
    model: KeyCategoryModel;
    oldModel: KeyCategoryModel;

    keyCategoryForm: UntypedFormGroup;
    hasFormErrors = false;

    loading$: Observable<boolean>;
    viewLoading = false;
    loadingAfterSubmit = false;
    mode = 'NEW';
    readonly = false;
    // Private properties
    private componentSubscriptions: Subscription;
    subscriptions: Subscription[] = [];

    displayedColumns = ['type', 'name', 'file_name', 'actions'];
    dataSource = new MatTableDataSource();

    fileRows = [
        {
            name: 'Marker Icon',
            type: 'svg',
            field: 'icon'
        }
    ];

    progressValue = 0;
    progress$: Observable<number>;
    progressSubject = new BehaviorSubject<number>(0);
    error: any;

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param typesUtilsService: TypesUtilsService
     * @param uploadService: FileUploadService
     * @param dialog: MatDialog
     * @param subheaderService: SubheaderService
     * @param store: Store<AppState>
     * @param translate: TranslateService)
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                public typesUtilsService: TypesUtilsService,
                private uploadService: FileUploadService,
                private dialog: MatDialog,
                public subheaderService: SubheaderService,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource(this.fileRows);
        this.loading$ = this.store.pipe(select(selectKeyCategoriesActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectKeyCategoryById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initKeyCategory();
                    }
                });
            } else {
                this.model = new KeyCategoryModel();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initKeyCategory();
            }
        });
        this.subscriptions.push(routeSubscription);

        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
            this.setBreadCrumbs(Number(this.activatedRoute.snapshot.paramMap.get('id')));
        } else {
            this.setBreadCrumbs(0);
        }
    }

    setBreadCrumbs(id: number): void {
        this.subheaderService.setTitle(this.getComponentTitle());
        this.subheaderService.setBreadcrumbs([
            {title: 'Developer', page: `../default/admin-management`},
            {title: 'Linked tables', page: `../default/admin-management/linked-tables`},
            {title: 'Landmark Types', page: `../default/admin-management/linked-tables/base-of-values`},
            {
                title: this.getComponentTitle(),
                page: id > 0 ? `../default/admin-management/linked-tables/base-of-values/edit/${id}`
                    : `../default/admin-management/linked-tables/base-of-values/add`
            }
        ]);
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initKeyCategory() {
        this.createForm();
    }


    createForm() {
        this.keyCategoryForm = this.fb.group({
            name: [this.model.name, Validators.required],
        });
    }

    /**
     * Returns page title
     */
    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return `Edit Landmark Type '${
                this.model.name
                }'`;
        }

        return 'New Landmark Type';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.keyCategoryForm.controls[controlName];
        return control.invalid && control.touched;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.keyCategoryForm.markAsPristine();
        this.keyCategoryForm.markAsUntouched();
        this.keyCategoryForm.updateValueAndValidity();
    }

    /** ACTIONS */

    /**
     * On Submit
     */
    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.keyCategoryForm.controls;
        /** check form */
        if (this.keyCategoryForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.model.id > 0) {
            this.updateKeyCategory(this.model);
        } else {
            this.createKeyCategory(this.model);
        }
    }

    /**
     * Update model
     *
     * @param _model: KeyCategoryModel
     */
    updateKeyCategory(_model: KeyCategoryModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateKeyCategory: Update<KeyCategoryModel> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new KeyCategoryUpdated({
            partialKeyCategory: updateKeyCategory,
            keyCategory: _model
        }));
        this.oldModel = _model;
        this.navigateToParent();
    }

    /**
     * Create model
     *
     * @param _model: KeyCategoryModel
     */
    createKeyCategory(_model: KeyCategoryModel) {
        this.store.dispatch(new KeyCategoryOnServerCreated({keyCategory: _model}));
        this.oldModel = _model;

        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedKeyCategoryId),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0);
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    addFiles(type) {
        this.fileInputImg.nativeElement.click();
    }


    // upload
    uploadFile(event) {
        if (event.target.files && event.target.files[0]) {


            const file = event.target.files[0];
            this.model.icon = file.name;
            this.upload(file, file.name);
        }
    }

    upload(file: File, name: string, path: string = 'landmark_type/') {
        const formData = new FormData();
        formData.append('file', file, name);
        formData.append('path', path);

        this.progressSubject.next(1);
        this.uploadService.upload2path(formData).subscribe(
            // (res) => this.uploadResponse = res,
            (res) => {
                if (!res) {
                    return;
                }
                if (res.status) {
                    if (res.status === 'progress') {
                        this.progressSubject.next(res.message);
                    }
                    if (res.status === 'done') {


                        this.model.icon = res.filePath;

                        this.fileInputImg.nativeElement.value = '';
                        this.progressSubject.next(0);
                    }
                }
            },
            (err) => this.error = err
        );
    }

    deleteUploadedFile(field) {
        this.model.icon = '';
    }

    previewUploadedFile(type) {
        // this.dialog.open(ImageViewerDialogComponent, {
        //     data: {
        //         picture: this.model.icon,
        //         type: 'jpg'
        //     }
        // });
    }

    getFileName(name) {
        return (this.model.icon && this.model.icon.length > 0) ?
            this.model.icon.split('/').pop().replace(/^\d+_/, '') :
            awConst.NOFILE;
    }


    canDeactivate() {
        if (this.discard()) {
            if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name || this.model.icon != this.oldModel.icon;
        }
        return false;
    }

}
