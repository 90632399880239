<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{title$ | async}}</h3>
        </div>
        <div class="card-toolbar">
            <div class="mr-6">
                <mat-checkbox [checked]="true" (change)="show100fields($event)">
                    Show only 100% matching fields
                </mat-checkbox>
            </div>
            <span *ngIf="!data.isMulti" [matTooltip]="!alreadyAdded(comparables[0]) && data.selectedIds.length == 5
                    ? 'You can choose to limit 5 comparables'
                    : alreadyAdded(comparables[0]) ? 'Remove this comparable' : 'Select this comparable'">
                <a href="javascript:;"
                    *ngIf="data.addButton"
                    [class.button-disabled]="data.selectedIds.length == 5 && !alreadyAdded(comparables[0])"
                    (click)="addItem(comparables[0])"
                    class="btn btn-icon btn-sm btn-primary mr-2 p-1" style="width: 80px;">
                    {{alreadyAdded(comparables[0]) ? 'remove' : 'add'}}
                </a>
            </span>

            <a href="javascript:;"
                (click)="onNoClick()"
                class="btn btn-icon btn-sm btn-warning p1">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body">
        <mat-dialog-content>
        <div class="col-12 section-margin-bottom-60 pr-0">
            <ng-container *ngIf="(rowCnt|async) as rowCnt">
                <h6 class="text-right pb-2 text-info" *ngIf="rowCnt">
                    {{rowCnt}} points of comparisons are available
                </h6>
            </ng-container>
            <!-- <h6 class="text-right pb-2 text-info" *ngIf="rowCnt | async">
            </h6> -->
            <agm-map
                [latitude]="centerLat"
                [longitude]="centerLng"
                [zoom]="15"
                [disableDoubleClickZoom]="true"
                [fitBounds]="true"
                [scrollwheel]="null"
                [gestureHandling]="'cooperative'"
                [draggable]="false"
                [clickableIcons]="false"
                style="height: 400px;">
                <agm-map-type-control></agm-map-type-control>
                <agm-marker
                    *ngFor="let point of comPoints"
                    [agmFitBounds]="true"
                    [latitude]="point.lat"
                    [longitude]="point.lng"
                    [iconUrl]="icon"
                    [label]="point.label">
                </agm-marker>
                <agm-marker
                    [agmFitBounds]="true"
                    [latitude]="tpLat"
                    [longitude]="tpLng"
                    [iconUrl]="tpIcon"></agm-marker>
                <agm-polyline *ngFor="let point of comPoints" [strokeColor]="'red'">
                    <agm-polyline-point [latitude]="point.lat" [longitude]="point.lng">
                    </agm-polyline-point>
                    <agm-polyline-point [latitude]="tpLat" [longitude]="tpLng">
                    </agm-polyline-point>
                </agm-polyline>
            </agm-map>
        </div>

        <div class="col-12 pr-0">
            <h5 class="my-3 text-mad text-uppercase">Information about Comparable</h5>
            <hr class="active">
        </div>
        <!-- <ng-container *ngTemplateOutlet="table;context:{rows$:comparableInfoRows$,type:'comparable'}"></ng-container> -->
        <kt-consideration-info-table 
            [selectedIds]="selectedIds"
            [comparablesData]="comparablesData" 
            [isMulti]="data.isMulti"
            [targetProperty]="data.targetProperty"
            [propertySubTypeId]="data.propertySubTypeId"></kt-consideration-info-table>

        <div class="col-12 pr-0">
            <h5 class="my-3 text-mad text-uppercase">Information about Target Property Inspection</h5>
            <hr class="active">
        </div>
        <ng-container *ngTemplateOutlet="table;context:{rows$:tpInfoRows$,type:'target-property'}"></ng-container>

        <div class="col-12 pr-0">
            <h5 class="my-3 text-mad text-uppercase">Location</h5>
            <hr class="active">
        </div>
        <ng-container *ngTemplateOutlet="table;context:{rows$:locationRows$,type:'both'}"></ng-container>

        <ng-container *ngIf="sections.building &&  (complexity$|async)">
            <ng-container *ngIf="(buildingRows$|async) as rows">
                <ng-container *ngIf="rows.length > 0">
                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">The Building</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows$:buildingRows$,type:'both'}"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <div class="col-12 pr-0">
            <h5 class="my-3 text-mad text-uppercase">About the property</h5>
            <hr class="active">
        </div>
        <ng-container *ngTemplateOutlet="table;context:{rows$:aboutPropertyRows$,type:'both'}"></ng-container>

        <!-- <ng-container *ngIf="(complexity$|async) && (serviceNInfraRows$|async).length"> -->
        <ng-container *ngIf="sections.services_and_infra && (complexity$|async)">
            <ng-container *ngIf="(serviceNInfraRows$|async) as rows">
                <ng-container *ngIf="rows.length > 0">
                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">Services and infrastructure</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows$:serviceNInfraRows$,type:'both'}"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="sections.external_aspect &&  (complexity$|async)">
            <ng-container *ngIf="(externalAspectRows$|async) as rows">
                <ng-container *ngIf="rows.length > 0">
                    <div class="col-12 pr-0">
                        <h5 class="my-3 text-mad text-uppercase">External Aspects</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows$:externalAspectRows$,type:'both'}"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="sections.internal_aspect && (complexity$|async)">
            <ng-container *ngIf="(internalAspectRows$|async) as rows">
                <ng-container *ngIf="rows.length > 0">
                    <div class="col-12 pr-0" >
                        <h5 class="my-3 text-mad text-uppercase">Internal Aspects</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows$:internalAspectRows$,type:'both'}"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="sections.ground">
            <ng-container *ngIf="(groundRows$|async) as rows">
                <ng-container *ngIf="rows.length > 0">
                    <div class="col-12 pr-0" >
                        <h5 class="my-3 text-mad text-uppercase">Grounds</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngTemplateOutlet="table;context:{rows$:groundRows$,type:'both'}"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <div class="col-12 pr-0">
            <h5 class="my-3 text-mad text-uppercase">Sizes</h5>
            <hr class="active">
        </div>
        <ng-container *ngTemplateOutlet="table;context:{rows$:sizeRows$,type:'both'}"></ng-container>

        <ng-container *ngIf="complexity$|async">
            <ng-container *ngIf="parcelIdentification$|async as pir">
                <ng-container *ngIf="landAreaRows$|async as lar">
                    <ng-container *ngIf="parcelConsideration$|async as pcr">
                        <ng-container *ngIf="pir.length > 0 || lar.length > 0 || pcr.length > 0">
                            <!-- <div class="col-12 pr-0" *ngIf="sections.land_information">
                                <h5 class="my-3 text-mad text-uppercase">Land Information</h5>
                                <hr class="active">
                            </div> -->
                            <ng-container *ngIf="sections.parcel_identification && pir.length > 0">
                                <div class="col-12">
                                    <h6 class="my-3 text-mad text-uppercase">Land information (Parcel Identification)</h6>
                                </div>
                                <ng-container *ngTemplateOutlet="table;context:{rows$:parcelIdentification$,type:'both'}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="sections.land_area && lar.length > 0">
                                <div class="col-12">
                                    <h6 class="my-3 text-mad text-uppercase">Land information (Land Area)</h6>
                                </div>
                                <ng-container *ngTemplateOutlet="table;context:{rows$:landAreaRows$,type:'both'}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="sections.parcel_consideration && pcr.length > 0">
                                <div class="col-12">
                                    <h6 class="my-3 text-mad text-uppercase">Land information (Parcel Consideration)</h6>
                                </div>
                                <ng-container *ngTemplateOutlet="table;context:{rows$:parcelConsideration$,type:'both'}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        </mat-dialog-content>
    </div>
</div>

<ng-template #table let-rows$="rows$" let-type="type" >
    <div class="mat-table-wrapper pl-4">
        <table class="table lmat-elevation-z8 table-striped">
            <thead class="thead-dark">
                <tr>
                    <th scope="col" [ngStyle]="{'width': type != 'both' ? '33%' : (100/(comparables.length + 2) + '%')}">Item</th>
                    <ng-container [ngSwitch]="type">
                        <th scope="col" [style.width]="'50%'" *ngSwitchCase="'target-property'">
                            Target Property
                        </th>
                        <ng-container *ngSwitchCase="'comparable'">
                            <th scope="col" *ngFor="let com of comparables" [style.width]="100/(comparables.length + 1) + '%'">
                                {{data.isMulti ? (com.ref_num) : 'Comparable'}}
                            </th>
                        </ng-container>
                        <ng-container *ngSwitchCase="'both'">
                            <th scope="col" *ngFor="let com of comparables" [style.width]="100/(comparables.length + 2) + '%'">
                                {{data.isMulti ? (com.ref_num) : 'Comparable'}}
                            </th>
                            <th scope="col" [style.width]="100/(comparables.length + 2) + '%'">
                                Target Property
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="(rows$|async) as rows">
                    <ng-container *ngIf="rows.length > 0">
                        <ng-container *ngFor="let row of rows">
                            <tr [class.rowCnt]="row.type == 'comparable'">
                            <td>
                                <b>{{row.title}}</b>
                            </td>
                            <ng-container [ngSwitch]="type">
                                <ng-container scope="col" *ngSwitchCase="'target-property'">
                                    <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells['tp']}"></ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'comparable'">
                                    <ng-container *ngFor="let com of comparables">
                                        <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells[com.ref_num]}"></ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'both'">
                                    <ng-container *ngFor="let com of comparables">
                                        <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells[com.ref_num],ref_num:com.ref_num}"></ng-container>
                                    </ng-container>
                                    <ng-container *ngTemplateOutlet="cell;context:{cell:row.cells['tp'],ref_num:'tp'}"></ng-container>
                                </ng-container>
                            </ng-container>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="rows.length == 0">
                        There is no common dataset to display for the properties
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #cell let-cell="cell" let-ref_num="ref_num">
    <td *ngIf="ref_num == 'tp' && cell.type == 'gray'" style="background-color: #ebebeb;"></td>
    <ng-container *ngIf="cell.unknown && cell.type != 'gray'">
        <td [class.selected-com]="cell.unknown">
            {{cell.info}}
        </td>
    </ng-container>
    <ng-container *ngIf="!cell.unknown">
        <ng-container [ngSwitch]="cell.type">
            <td *ngSwitchCase="'default'">
                <ng-container *ngIf="cell.click">
                    <span class="clickable" (click)="cell.click()">
                        {{cell.info}}
                    </span>
                </ng-container>
                <ng-container *ngIf="!cell.click">
                    {{cell.info}}
                </ng-container>
            </td>
            <td *ngSwitchCase="'link'">
                <a [href]="'http://' + cell.info">{{cell.info}}</a>
            </td>
            <td *ngSwitchCase="'tenure'">
                {{cell.info}} <span class="clickable" (click)="cell.click()">(click to see detail)</span>
            </td>
            <td *ngSwitchCase="'pictures'">
                <span class="pl-1 pr-3 cursor-pointer" *ngFor="let pic of cell.pics; index as i" (click)="cell.click(i)">
                    <img alt="Pic"
                            style="align-self: center;
                                height: 34px;
                                width: 34px;
                                object-fit: cover;
                                border-radius: 3px;"
                            [src]="pic.url"
                            (error)="imgOnError($event)">
                </span>
            </td>
            <ng-container *ngSwitchCase="'location'">
                <ng-container *ngIf="comparables.length == 1">
                    <td colspan="2" class="text-center" *ngIf="ref_num != 'tp'" [innerHTML]="cell.info">
                    </td>
                </ng-container>
                <ng-container *ngIf="comparables.length > 1">
                    <td [innerHTML]="cell.info" *ngIf="ref_num != 'tp'">
                    </td>
                    <td *ngIf="ref_num == 'tp'" style="background-color: #ebebeb;"></td>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'checklist'">
                <td>
                    <ng-container *ngIf="cell.check">
                        <mat-checkbox [checked]="cell.check" style="pointer-events: none;"></mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="!cell.check">
                        <div class="unchecked_icon" >
                            <mat-icon>clear</mat-icon>
                        </div>
                    </ng-container>
                </td>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>