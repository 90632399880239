import { Component, Input, OnInit } from '@angular/core';
import { AssetClassDetailModel, AssetClassModel } from 'src/app/core/asset_class';
import { ConditionRatingV2Service } from '../../../target-property/services/condition-rating-v2.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'kt-condition-ratin-module',
  templateUrl: './condition-ratin-module.component.html',
  styleUrls: ['./condition-ratin-module.component.scss', '../info-module.styles.scss']
})
export class ConditionRatinModuleComponent implements OnInit {
  @Input() targetProperty: AssetClassModel;
  @Input() tpDetails: AssetClassDetailModel;
  @Input() useMatDialog: boolean = true;

  hasComponents: boolean = true;

  public components$ = this.crService.components$.pipe(tap(components => this.hasComponents = components.length > 0));
  public conditionRatings$ = this.crService.ratings$
  public schemeId$ = this.crService.schemeId$
  constructor(
    private crService: ConditionRatingV2Service
  ) { }

  ngOnInit(): void {
  }

}
