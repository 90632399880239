import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { Action } from "rxjs/internal/scheduler/Action";
import { ValuationProcessCriterionActions, ValuationProcessCriterionActionTypes } from "../_actions/criterion.actions";
import { VAssetClassModelTypes } from "../_models/valuation-asset-class.model";
import { CriterionModel, DefaultCriterionModel, CriterionValue, CriterionCategory, categoryName } from "../_models/valuation-process-criterion.model";
import * as _ from 'lodash'
import { AllTpAdminTaskRequested } from "../../asset_class";
import { isApartmentComparable, isHouseComparable, isOfficeComparable, isParkingComparable, isRetailBuildingComparable, isRetailShopComparable, isWarehouseComparable } from "../_models/valuation-comparable.model";
import { AssetClassTenure } from "../../comparable";
import { VAssetClassConsideration } from "../_models/valuation-asset-class-common.models";
import { calculateRecoverable } from "../../v2/core";

export interface ValuationProcessCriterionsState extends EntityState<DefaultCriterionModel> { 
    lastCreatedId: number
}
export const adapter: EntityAdapter<DefaultCriterionModel> = createEntityAdapter()
export const initialValuationProcessCriterionsState: ValuationProcessCriterionsState = adapter.getInitialState({
    lastCreatedId: 0
})
export function valuationProcessCriterionsReducer(
    state = initialValuationProcessCriterionsState,
    action: ValuationProcessCriterionActions
): ValuationProcessCriterionsState {
    switch (action.type) {
        case ValuationProcessCriterionActionTypes.LoadCriterionsFromServer: {
            let lastCreatedId = 0
            const crits = action.payload.crits.map(crit => {
                lastCreatedId += 1
                let values = crit.values;
                if (Array.isArray(values) && values.length == 0) {
                    values = {}
                }
                return {
                    ...crit,
                    id: lastCreatedId,
                    values
                }
            })
            return adapter.setAll(crits, {...state, lastCreatedId})
        }
        case ValuationProcessCriterionActionTypes.LoadCriterions: {
            const {criterions, id} = createCriterions(criterionBluePrints)
            return adapter.setAll(criterions, { ...state, lastCreatedId: id })
        }
        case ValuationProcessCriterionActionTypes.CreateTPCriterions: {
            const updates = updateCriterions(Object.values(state.entities), action.payload.targetProperty)
            return adapter.updateMany(updates, state)
        } 
        case ValuationProcessCriterionActionTypes.ToggleCriterionActive: {
            const update: Update<DefaultCriterionModel> = {
                id: action.payload.id,
                changes: {
                    active: action.payload.active
                }
            }
            return adapter.updateOne(update, state)
        }
        case ValuationProcessCriterionActionTypes.AddComparable: {
            const updates = updateCriterions(Object.values(state.entities), action.payload.comparable, action.payload.selectedConsiderationId)
            return adapter.updateMany(updates, state)
        }
        case ValuationProcessCriterionActionTypes.AddMultipleComparable: {
            let lastCreatedId = 0
            const crits = action.payload.adjustmentTabData.criterions.map(crit => {
                lastCreatedId += 1
                let values = crit.values;
                if (Array.isArray(values) && values.length == 0) {
                    values = {}
                }
                return {
                    ...crit,
                    id: lastCreatedId,
                    values
                }
            })
            return adapter.setAll(crits, {...state, lastCreatedId})
        }
        case ValuationProcessCriterionActionTypes.RemoveComparable: {
            const updates = removeComparable(Object.values(state.entities), action.payload.refNum)
            return adapter.updateMany(updates, state)
        }
        case ValuationProcessCriterionActionTypes.EditDefaultCriterion: {
            const update: Update<DefaultCriterionModel> = {
                id: action.payload.criterion.id,
                changes: action.payload.criterion,
            }
            return adapter.updateOne(update, {...state})
        }
        case ValuationProcessCriterionActionTypes.ResetState: {
            return initialValuationProcessCriterionsState
        }
        case ValuationProcessCriterionActionTypes.InsertCriterionsFromTemplate: {
            const crits = Object.values(state.entities)
                .filter(criterion => 
                    action.payload.defaultCriterions.find(dc => 
                        dc.category == criterion.category && criterion.name.toLowerCase().trim() == dc.name.toLowerCase().trim()
                    ) != undefined
                ).map(criterion => ({
                    id: criterion.id,
                    changes: {
                        active: true
                    }
                }))
            return adapter.updateMany(crits, state)
        }
        case ValuationProcessCriterionActionTypes.ResetDefaultCriterions: {
            const values = _.cloneDeep(action.payload.criterion.values)
            action.payload.comparables.forEach(com => {
                const selectedConsideration = action.payload.selectedConsiderations.find(sc => sc.refNum === com.refNum)
                values[com.refNum] = addValue(action.payload.criterion, com, selectedConsideration?.id)
            })
            return adapter.updateOne({id: action.payload.criterion.id, changes: {values, isChanged: false}}, state)
        }
        default:
            return state
    }
}

type BluePrint = {
    name: string,
    type: CriterionCategory,
    field: string
}

const criterionBluePrints: BluePrint[] = [
    {name: 'Location Grade', type: CriterionCategory.Location, field: 'locationGrade'},
    {name: 'Address', type: CriterionCategory.Location, field: 'address'},

    {name: 'Sub-Type Category', type: CriterionCategory.Property, field: 'subTypeCategory'},
    {name: 'Sub Category', type: CriterionCategory.Property, field: 'subCategory'},
    {name: 'Property Grade', type: CriterionCategory.Property, field: 'propertyGrade'},
    {name: 'Property General Description', type: CriterionCategory.Property, field: 'generalDescription'},
    {name: 'Completion Year', type: CriterionCategory.Property, field: 'completionYear'},
    {name: 'Approximate year the property was extended', type: CriterionCategory.Property, field: 'approximateYear'},
    {name: 'Number of floors (above ground)', type: CriterionCategory.Property, field: 'aboveFloors'},
    {name: 'Basement floors', type: CriterionCategory.Property, field: 'belowFloors'},
    {name: 'Floor Location', type: CriterionCategory.Property, field: 'floors'},
    {name: 'State of Repair', type: CriterionCategory.Property, field: 'stateOfRepair'},
    {name: 'Property Energy Efficiency Grade', type: CriterionCategory.Property, field: 'energyEfficiencyGrade'},
    {name: 'Handover Standard', type: CriterionCategory.Property, field: 'handoverStandard'},
    {name: 'Construction description', type: CriterionCategory.Property, field: 'constructionDescription'},
    {name: 'Number of Units', type: CriterionCategory.Property, field: 'numberOfUnits'},
    {name: 'Parking Type', type: CriterionCategory.Property, field: 'parkingType'},
    {name: 'Automation', type: CriterionCategory.Property, field: 'automation'},
    {name: 'Coordinate Reference System', type: CriterionCategory.Property, field: 'coordinateReferencySystem'},
    {name: 'Land Cover Type', type: CriterionCategory.Property, field: 'landCoverType'},
    {name: 'Quality of soil and degradation', type: CriterionCategory.Property, field: 'qualityOfSoilDegradation'},
    {name: 'Comparative land use', type: CriterionCategory.Property, field: 'comparativeLandUse'},
    {name: 'Source of land use classification', type: CriterionCategory.Property, field: 'soureOfLandUseClassification'},
    {name: 'Other characteristics', type: CriterionCategory.Property, field: 'otherCharacteristics'},
    {name: 'Date of land use approval', type: CriterionCategory.Property, field: 'landUseApproval'},

    {name: 'Building grade', type: CriterionCategory.Building, field: 'buildingGrade'},
    {name: 'Completion Year', type: CriterionCategory.Building, field: 'completionYear'},
    {name: 'Building Type', type: CriterionCategory.Building, field: 'buildingType|buildingTypeComment'},
    {name: 'Developer', type: CriterionCategory.Building, field: 'developer'},
    {name: 'Primary Anchor Tenant', type: CriterionCategory.Building, field: 'anchorTenant'},
    {name: 'Energy Efficiency Grade', type: CriterionCategory.Building, field: 'energyEfficiencyGrade'},
    {name: 'Foundation Type', type: CriterionCategory.Building, field: 'foundationType'},
    {name: 'Building Name', type: CriterionCategory.Building, field: 'name'},
    {name: 'Building Construction Description', type: CriterionCategory.Building, field: 'buildingDescription'},

    {name: 'Chimney Stack', type: CriterionCategory.ExternalAspect, field: 'chimneyStack|chimneyStackComment'},
    {name: 'Roof Covering', type: CriterionCategory.ExternalAspect, field: 'roofCovering|roofCoveringComment'},
    {name: 'Rainwater pipes and gutters', type: CriterionCategory.ExternalAspect, field: 'rainwaterPipe|rainwaterPipeComment'},
    {name: 'Main wall', type: CriterionCategory.ExternalAspect, field: 'mainWall|mainWallComment'},
    {name: 'Window', type: CriterionCategory.ExternalAspect, field: 'window|windowComment'},
    {name: 'Outside door', type: CriterionCategory.ExternalAspect, field: 'outsideDoor|outsideDoorComment'},
    {name: 'Conservatory and porches', type: CriterionCategory.ExternalAspect, field: 'conservatory|conservatoryComment'},
    {name: 'Other joinery and finishes', type: CriterionCategory.ExternalAspect, field: 'otherJoinery|otherJoineryComment'},
    {name: 'Loading docks', type: CriterionCategory.ExternalAspect, field: 'loadingDock'},
    {name: 'Other external aspect', type: CriterionCategory.ExternalAspect, field: 'others'},

    {name: 'Roof structure', type: CriterionCategory.InternalAspect, field: 'roofStructure|roofStructureComment'},
    {name: 'Shop front type', type: CriterionCategory.InternalAspect, field: 'shopFrontType|shopFrontTypeComment'},
    {name: 'Ceiling', type: CriterionCategory.InternalAspect, field: 'ceiling|ceilingComment'},
    {name: 'Walls and Partition', type: CriterionCategory.InternalAspect, field: 'wallsAndPartition|wallsAndPartitionComment'},
    {name: 'Floor', type: CriterionCategory.InternalAspect, field: 'floors|floorsComment'},
    {name: 'Bathroom fittings', type: CriterionCategory.InternalAspect, field: 'bathroomFitting|bathroomFittingComment'},
    {name: 'Height', type: CriterionCategory.InternalAspect, field: 'height|heightComment'},
    {name: 'Cubic content', type: CriterionCategory.InternalAspect, field: 'cubicContent|cubicContentComment'},
    {name: 'Storage type', type: CriterionCategory.InternalAspect, field: 'storageType|storageTypeComment'},
    {name: 'Window', type: CriterionCategory.InternalAspect, field: 'windows|windowsComment'},
    {name: 'Fireplaces, chimney breasts and flues', type: CriterionCategory.InternalAspect, field: 'fireplacesAndChimney|fireplacesAndChimneyComment'},
    {name: 'Other internal aspect', type: CriterionCategory.InternalAspect, field: 'others'},

    {name: 'Indoor Garage', type: CriterionCategory.Ground, field: 'indoorGarage|indoorGarageComment'},
    {name: 'Outdoor Garage', type: CriterionCategory.Ground, field: 'outdoorGarage|outdoorGarageComment'},
    {name: 'Outdoor Space', type: CriterionCategory.Ground, field: 'outdoorSpace|outdoorSpaceComment'},
    {name: 'Garden', type: CriterionCategory.Ground, field: 'gardent|gardenComment'},

    {name: 'Coordinate Reference System', type: CriterionCategory.LandParcel, field: 'coordinateReferencySystem'},
    {name: 'Planning Status of the Land Parcel', type: CriterionCategory.LandParcel, field: 'planningStatus'},
    {name: 'Quality of soil and degradation', type: CriterionCategory.LandParcel, field: 'qualityOfSoil'},
    {name: 'Environmental considerations', type: CriterionCategory.LandParcel, field: 'environmentalConsideration'},
    {name: 'Other considerations', type: CriterionCategory.LandParcel, field: 'otherConsideration'},

    {name: 'Start Date', type: CriterionCategory.LeaseDetail, field: 'start_date'},
    {name: 'Lease Duration', type: CriterionCategory.LeaseDetail, field: 'lease_duration'},
    {name: 'Rent Free period (month)', type: CriterionCategory.LeaseDetail, field: 'rent_free_period'},
    {name: 'Fitting out period (month)', type: CriterionCategory.LeaseDetail, field: 'fitting_out_period'},
    {name: 'Capital Payment', type: CriterionCategory.LeaseDetail, field: 'cap_payment'},
    {name: 'Write-off period after', type: CriterionCategory.LeaseDetail, field: 'write_off_period'},
    {name: 'Break option after', type: CriterionCategory.LeaseDetail, field: 'break_option'},
    {name: 'Rent Review Type', type: CriterionCategory.LeaseDetail, field: 'rent_review_type_name'},
    {name: 'Rent Review Cycle', type: CriterionCategory.LeaseDetail, field: 'rent_review'},
    {name: 'Rent Review detail', type: CriterionCategory.LeaseDetail, field: 'rent_review_detail'},
    {name: 'Total Recoverable', type: CriterionCategory.LeaseDetail, field: 'total_recoverable'},
]

function createCriterions(criterionBluePrints: BluePrint[]): { criterions: DefaultCriterionModel[], id: number } {
    let id = 0
    return {
        criterions: criterionBluePrints.map(bp => {
            id += 1
            return {
                id: id,
                name: bp.name,
                publicName: bp.name,
                values: {},
                type: 'Default',
                active: false,
                category: bp.type,
                categoryName: categoryName(bp.type),
                field: bp.field,
                isChanged: false
            }
        }), id
    }
}

function updateCriterions(criterions: DefaultCriterionModel[], assetClass: VAssetClassModelTypes, selectedConsiderationId: number | undefined = undefined): Update<DefaultCriterionModel>[] {
    return criterions.map(criterion => {
        const values = _.cloneDeep(criterion.values)
        values[assetClass.refNum] = addValue(criterion, assetClass, selectedConsiderationId)
        return {
            id: criterion.id,
            changes: {
                values
            }
        }
    })
}

function removeComparable(criterions: DefaultCriterionModel[], refNum: string): Update<DefaultCriterionModel>[] {
    return criterions.map(criterion => {
        const values = _.cloneDeep(criterion.values)
        delete values[refNum]
        return {
            id: criterion.id,
            changes: {
                values
            }
        }
    })
}

function addValue(criterion: DefaultCriterionModel, assetClass: VAssetClassModelTypes, selectedConsiderationId: number | undefined): CriterionValue {
    switch (criterion.category) {
        case CriterionCategory.Location: {
            if (!checkUndefined(assetClass.locationData, criterion.field)) {
                return {kind: 'not applicable'}
            }
            return criterionValueCreator(assetClass.locationData[criterion.field])
        }
        case CriterionCategory.Property: {
            if (!checkUndefined(assetClass.property, criterion.field)) {
                return {kind: 'not applicable'}
            }
            return criterionValueCreator(assetClass.property[criterion.field])
        }
        case CriterionCategory.Building: {
            if (assetClass.propertySubType == 'House' || assetClass.propertySubType == 'Retail Building' || assetClass.propertySubType == 'Warehouse' || assetClass.propertySubType == 'Land') {
                return {kind: 'not applicable'}
            }
            const splits = criterion.field.split('|');
            if (splits.length == 1) {
                if (!checkUndefined(assetClass.building, criterion.field)) {
                    return {kind: 'not applicable'}
                }
                return criterionValueCreator(assetClass.building[criterion.field])
            } else {
                if (!checkUndefined(assetClass.building, splits[0])) {
                    return {kind: 'not applicable'}
                }
                return criterionValueCreatorWithComment(assetClass.building[splits[0]], assetClass.building[splits[1]])
            }
        }
        case CriterionCategory.ExternalAspect: {
            if (assetClass.propertySubType == 'Apartment' || assetClass.propertySubType == 'Office' || assetClass.propertySubType == 'Retail Shop' || assetClass.propertySubType == 'Parking' || assetClass.propertySubType == 'Land') {
                return {kind: 'not applicable'}
            }
            const splits = criterion.field.split('|')
            if (!checkUndefined(assetClass.externalAspect, splits[0])) {
                return {kind: 'not applicable'}
            }
            if (splits.length == 1) {
                return criterionValueCreator(assetClass.externalAspect[splits[0]])
            } else {
                return criterionValueCreatorWithComment(assetClass.externalAspect[splits[0]], assetClass.externalAspect[splits[1]])
            }
        }
        case CriterionCategory.InternalAspect: {
            if (assetClass.propertySubType == 'Parking' || assetClass.propertySubType == 'Land') {
                return {kind: 'not applicable'}
            }
            const splits = criterion.field.split('|')
            if (!checkUndefined(assetClass.internalAspect, splits[0])) {
                return {kind: 'not applicable'}
            }
            if (splits.length == 1) {
                return criterionValueCreator(assetClass.internalAspect[splits[0]])
            } else {
                return criterionValueCreatorWithComment(assetClass.internalAspect[splits[0]], assetClass.internalAspect[splits[1]])
            }
        }
        case CriterionCategory.Ground: {
            if (assetClass.propertySubType == 'Parking' || assetClass.propertySubType == 'Land') {
                return {kind: 'not applicable'}
            }
            const splits = criterion.field.split('|')
            if (!checkUndefined(assetClass.ground, splits[0])) {
                return {kind: 'not applicable'}
            }
            if (splits.length == 1) {
                return criterionValueCreator(assetClass.ground[splits[0]])
            } else {
                return criterionValueCreatorWithComment(assetClass.ground[splits[0]], assetClass.ground[splits[1]])
            }
        }
        case CriterionCategory.LandParcel: {
            if (assetClass.propertySubType == 'Apartment' || assetClass.propertySubType == 'Office' || assetClass.propertySubType == 'Retail Shop' || assetClass.propertySubType == 'Land') {
                return {kind: 'not applicable'}
            }
            if (!checkUndefined(assetClass.parcelIdentification, criterion.field)) {
                return {kind: 'not applicable'}
            }
            return criterionValueCreator(assetClass.parcelIdentification[criterion.field])
        }
        case CriterionCategory.LeaseDetail: {
            if (assetClass.propertySubType == 'Apartment' && isApartmentComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            if (assetClass.propertySubType == 'House' && isHouseComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            if (assetClass.propertySubType == 'Office' && isOfficeComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            if (assetClass.propertySubType == 'Retail Shop' && isRetailShopComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            if (assetClass.propertySubType == 'Retail Building' && isRetailBuildingComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            if (assetClass.propertySubType == 'Warehouse' && isWarehouseComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            if (assetClass.propertySubType == 'Parking' && isParkingComparable(assetClass)) {
                return leaseDetailCriterion(assetClass.considerations, selectedConsiderationId, criterion.field)
            }
            return {kind: 'not applicable'}
        }
        default:
            return {kind: 'unknown'}
    }
}

function leaseDetailCriterion(considerations: VAssetClassConsideration[], selectedConsiderationId: number | undefined, field: string): CriterionValue {
    if (considerations.length === 0) {
        return {kind: 'unknown'}
    }
    let consideration: VAssetClassConsideration = null
    if (selectedConsiderationId == undefined) {
        consideration = considerations[0]
    } else {
        consideration = considerations.find(c => c.id === selectedConsiderationId)
    }
    if (consideration && consideration.considerationType !== 'Land') {
        if (field === 'total_recoverable') {
            return criterionValueCreator(calculateRecoverable(consideration.oldTenure))
        }
        let value = consideration.oldTenure[field]
        if (value != null && value !== undefined && ['rent_free_period', 'fitting_out_period', 'write_off_period', 'break_option', 'rent_review'].includes(field)) {
            value = consideration.oldTenure.duration_type === 'months'
                ? value
                : Number(value) * 12
        }
        return criterionValueCreator(value)
    }
    return {kind: 'unknown'}
}

function checkUndefined(object: any, property: string): boolean {
    return object.hasOwnProperty(property)
}

function criterionValueCreator(value: string | number | null | undefined): CriterionValue {
    if (value == null) {
        return {kind: 'unknown'}
    }
    if (value == undefined) {
        return {kind: 'not applicable'}
    }
    if (typeof value == 'string') {
        if (value.trim().length == 0) {
            return {kind: 'unknown'}
        }
        return {
            kind: 'simple',
            value
        }
    }
    return {
        kind: 'simple',
        value: value.toString()
    }
}

function criterionValueCreatorWithComment(value: string | number | null | undefined, comment: string | null | undefined): CriterionValue {
    if (value == null) {
        return {kind: 'unknown'}
    }
    if (value == undefined) {
        return {kind: 'not applicable'}
    }
    if (typeof value == 'string') {
        if (value.trim().length == 0) {
            return {kind: 'unknown'}
        }
        return {
            kind: 'simple',
            value: `${value}.${comment ? (' ' + comment) : ''}`
        }
    }
    return {
        kind: 'simple',
        value: `${value}.${comment ? (' ' + comment) : ''}`
    }
}