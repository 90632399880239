// NGRX
import {Action} from '@ngrx/store';
// Models
import {ConflictOfInterestModel} from '../_models/conflict-of-interest.model';

export enum ConflictActionTypes {
    AllConflictRequested = '[Conflict List Page] Conflict Page Requested',
    AllConflictLoaded = '[Conflict API] Conflicts Page Loaded',

    AllConflictRemoved = '[New conflict dialog] all conflict state removed'
}

export class AllConflictRequested implements Action {
    readonly type = ConflictActionTypes.AllConflictRequested;

    constructor() {
    }
}

export class AllConflictLoaded implements Action {
    readonly type = ConflictActionTypes.AllConflictLoaded;

    constructor(public payload: { conflicts: ConflictOfInterestModel[] }) {
    }
}

export class AllConflictRemoved implements Action {
    readonly type = ConflictActionTypes.AllConflictRemoved;
    constructor(){}
}


export type ConflictActions = AllConflictRequested
    | AllConflictLoaded
    | AllConflictRemoved;
