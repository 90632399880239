import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllWarehouseAdjustmentsLoaded,
    AllWarehouseAdjustmentsRequested,
    WarehouseAdjustmentActionToggleLoading,
    WarehouseAdjustmentAdminRestored,
    WarehouseAdjustmentCreated,
    WarehouseAdjustmentDeleted,
    WarehouseAdjustmentDeletedFromAdminTrash,
    WarehouseAdjustmentDeletedFromTrash,
    WarehouseAdjustmentOnServerAdminRestored,
    WarehouseAdjustmentOnServerCreated,
    WarehouseAdjustmentOnServerRestored,
    WarehouseAdjustmentRestored,
    WarehouseAdjustmentsActionTypes,
    WarehouseAdjustmentsPageLoaded,
    WarehouseAdjustmentsPageRequested,
    WarehouseAdjustmentsPageToggleLoading,
    WarehouseAdjustmentTrashFlushed,
    WarehouseAdjustmentUpdated
} from '../_actions/warehouse-adjustment.actions';
import {allWarehouseAdjustmentsLoaded} from '../_selectors/warehouse-adjustment.selectors';
import {WarehouseAdjustmentsService} from '../_services/warehouse-adjustment.service';

@Injectable()
export class WarehouseAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: WarehouseAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new WarehouseAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new WarehouseAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new WarehouseAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllWarehouseAdjustmentsRequested>(WarehouseAdjustmentsActionTypes.AllWarehouseAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allWarehouseAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllWarehouseAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentsPageRequested>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new WarehouseAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentUpdated>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentOnServerCreated>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new WarehouseAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentTrashFlushed>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentOnServerRestored>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new WarehouseAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentOnServerAdminRestored>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new WarehouseAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentDeleted>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentDeletedFromTrash>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<WarehouseAdjustmentDeletedFromAdminTrash>(WarehouseAdjustmentsActionTypes.WarehouseAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}