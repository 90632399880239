import {
    AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges,
    SimpleChanges, OnDestroy
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutUtilsService } from '../../../../../core/_base/crud';
import { ExternalReferencesEditDialogComponent } from '../external-references-edit/external-references-edit.dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {AssetClassSourceExternalReferenceModel} from '../../../../../core/asset_class';
import { ExternalRefTemplateListComponent } from '../../../toe/_sub/external-ref-template-list/external-ref-template-list.component';
import { takeUntil } from 'rxjs/operators';
import { ExternalRefTemplateModel } from 'src/app/core/template';
import { ExternalRefTemplateModalComponent } from '../../../template/templates/external-ref-template/_sub/external-ref-template-modal/external-ref-template-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { AllSourceExternalReferencesRequested, selectAllSourceExternalReferences, SourceExternalReference } from 'src/app/core/linked-tables';

@Component({
    selector: 'kt-external-references-list',
    templateUrl: './external-references-list.component.html',
    styleUrls: ['./external-references-list.component.scss']
})

export class ExternalReferencesListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    displayedColumns = ['source_external_reference.name', 'reference_available', 'assumption', 'special_assumption', 'actions'];

    @Input() readonly: boolean = false;
    @Input() sourceExternalReferencesSubject: BehaviorSubject<AssetClassSourceExternalReferenceModel[]>;
    @Input() isTemplate: boolean;
    sourceExternalReferences: AssetClassSourceExternalReferenceModel[] = [];

    dataSource = new MatTableDataSource([]);
    trashCnt = 0;
    adminTrashCnt = 0;
    private subscription: Subscription;

    @ViewChild(MatSort) matSort: MatSort;

    // Selection
    selection = new SelectionModel<AssetClassSourceExternalReferenceModel>(true, []);
    private _onDestroy$: Subject<void> = new Subject();
    private _sourceExternalReferences: SourceExternalReference[] = [];

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private layoutUtilsService: LayoutUtilsService,
                private cdRef: ChangeDetectorRef,
                private translate: TranslateService, 
                private store: Store<AppState>) {
    }

    ngOnInit() {
        this.store.dispatch(new AllSourceExternalReferencesRequested());
        this.store.select(selectAllSourceExternalReferences).pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(res => {
            this._sourceExternalReferences = [];
            if (res) {
                this._sourceExternalReferences = res;
            }
        })
        this.subscription = this.sourceExternalReferencesSubject.asObservable().subscribe(
            (res) => {
                this.sourceExternalReferences = res;
                this.dataSource.data = this.sourceExternalReferences;
            }
        );
    }

    _addFromTemplate() {
        const dialogRef = this.dialog.open(ExternalRefTemplateListComponent);
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(
            res => {
                if (!res) {
                    return;
                }
                switch (res.type) {
                    case 'select':
                        if (this._sourceExternalReferences.length > 0) {
                            this.sourceExternalReferences = res.template.source_external_references.map(ser => {
                                const acSer = new AssetClassSourceExternalReferenceModel();
                                acSer.clear();
                                acSer.source_external_reference_id = ser.source_external_reference_id;
                                acSer.source_external_reference = this._sourceExternalReferences.find(s => s.id === ser.source_external_reference_id);
                                return acSer;
                            });
                            this.sourceExternalReferencesSubject.next(this.sourceExternalReferences);
                        }
                        break;
                    case 'view':
                        this._viewTemplate(res.template);
                        break;
                    default:
                        break;
                }
            }
        )
    }

    private _viewTemplate(template: ExternalRefTemplateModel) {
        this.dialog.open(ExternalRefTemplateModalComponent, {
            data: {
                template
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {}

    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
    ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    addExternalReference() {
        const newAuditTrailParticipant = new AssetClassSourceExternalReferenceModel();
        newAuditTrailParticipant.clear(); // Set all defaults fields
        this.editExternalReference(newAuditTrailParticipant, -1);
    }
    
    editExternalReference(_participant: AssetClassSourceExternalReferenceModel, index: number) {
        const _sourceExternalReferences = this.sourceExternalReferences.map((el, i) => {
            if (i != index) {
                return el.source_external_reference_id;
            }
        });
        const dialogRef = this.dialog.open(ExternalReferencesEditDialogComponent, {
            data: {participant: _participant, sourceExternalReferences: _sourceExternalReferences}
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
    
                if (index != -1) {
                    this.sourceExternalReferences[index] = res.participant;
                    this.dataSource.data = this.sourceExternalReferences;
                    this.sourceExternalReferencesSubject.next(this.sourceExternalReferences);
                    this.cdRef.detectChanges();
                } else {
                    // _participant.id = this.generateId();
                    this.sourceExternalReferences = [...this.sourceExternalReferences, res.participant];
                    // this.dataSource.data = this.sourceExternalReferences;
                    this.sourceExternalReferencesSubject.next(this.sourceExternalReferences);
                    this.cdRef.detectChanges();
                }
            }
        );
    }
    
    deleteExternalReference(participant: AssetClassSourceExternalReferenceModel, index: number) {
        const _title = 'Are you sure?';
        const _description: string = 'The External reference "' + participant.source_external_reference.name + '" will be deleted';
        const _waitDesciption = 'External reference deleting';
    
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.sourceExternalReferences.splice(index, 1);
            // this.dataSource.data = this.sourceExternalReferences;
            this.sourceExternalReferencesSubject.next(this.sourceExternalReferences);
        });
    }
}
