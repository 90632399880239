<form class="form" [formGroup]="formGroup" *ngIf="formGroup" [ngClass]="{'readonly': readonly}">
    <div class="form-group">
        <div class="row">
            <div class="col-4">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput type="text" placeholder="Template Name" formControlName="name">
                    <mat-error>Template Name <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
            <div class="col-2"></div>
            <div class="col-4">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Asset Class" formControlName="asset_class_type">
                        <mat-option *ngFor="let type of assetClassTypes" [value]="type.id">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Asset Class <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput type="text" placeholder="Template Description" formControlName="description"></textarea>
                    <mat-error>Template Description <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div style="display: flex; justify-content: flex-end; margin-bottom: 1em;">
                    <button 
                        mat-raised-button
                        color="primary"
                        *ngIf="!readonly" 
                        (click)="addAdjustmentField()"
                        [disabled]="!selectedAssetClassTypeId">
                        <span [matTooltip]="selectedAssetClassTypeId ? 'Add Adjustment' : 'Select Asset Class'">
                            Add Adjustment
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-table [dataSource]="dataSource" >
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *matCellDef="let adj">{{adj.adjustment_id != 0 ? adj.name : ('Other - ' + adj.name)}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                        <mat-cell *matCellDef="let adj">{{adj.description}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let adj; let i = index;">
                            <button mat-icon-button
                                [disabled]="readonly"
                                color="primary"
                                type="button"
                                (click)="editAdjutment(adj, i)">
                                <mat-icon>create</mat-icon>
                            </button>
                            <button mat-icon-button
                                [disabled]="readonly"
                                color="warn"
                                type="button"
                                (click)="deleteAdjustment(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['name', 'description', 'actions']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['name', 'description', 'actions']"></mat-row>
                </mat-table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-error *ngIf="atLeastOneAdjustmentError" class="tag-custom-error">
                    At least 1 Adjustment <strong>required</strong>
                </mat-error>
            </div>
        </div>
    </div>
</form>
