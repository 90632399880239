// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// Translate
import {TranslateModule} from '@ngx-translate/core';
import {PartialsModule} from '../../partials/partials.module';
// Services
import {HttpUtilsService, TypesUtilsService,  LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import {SharedModule} from '../shared_components/shared.module';
// Material
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';

// Components

import {TOEComponent} from './toe.component';
import {ToeEditComponent} from './toe-edit/toe-edit.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
    ConflictEffects,
    conflictReducer,
    ConflictService,
    ToeEffects,
    ToeService,
    toesReducer,
    ToeReportEffects,
    toeReportsReducer,
    ToeReportService,
} from '../../../core/toe';
import {ToeListComponent} from './toe-list/toe-list.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {ContextMenuMadComponent} from '../../partials/layout';
import {TagInputModule} from 'ngx-chips';
import {IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {ToeReportEditDialogComponent} from '../../partials/content/crud/toe-report-edit-dialog/toe-report-edit-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {CanDeactivateGuard} from '../../../core/auth';
import {GetCurrentUserResolver} from './_sub/resolver/get-current-user.resolver';
import {TpAddDialogComponent} from './_sub/tp-add-dialog/tp-add-dialog.component';
import {TpEditDialogComponent} from './_sub/target-properties/edit/tp-edit-dialog.component';
import {SupportValuerEditDialogComponent} from './_sub/support-valuer-edit/support-valuer-edit.dialog.component';
import {ConflictDetailComponent} from './_sub/conflict-detail/conflict-detail.component';
import {ConflictDetailOldComponent} from './_sub/conflict-detail-old/conflict-detail-old.component';
import {SignedToeDialogComponent} from './_sub/signed-toe-dialog/signed-toe-dialog.component';
import {GeneratedReportEditDialog} from './_sub/generated-report-edit-dialog/generated-report-edit-dialog.component';
import {TpAddConflictDialogComponent} from './_sub/target-properties/add/tp-add-conflict-dialog.component';
import {FileDialogComponent} from './_sub/file-dialog/file-dialog.component';
import {SupportValuerListComponent} from './_sub/support-valuer-list/support-valuer-list.component';
import {TpListComponent} from './_sub/target-properties/list/tp-list.component';
import {GetToeWorkersResolver} from './_sub/resolver/get-toe-workers.resolver';
import {SaveToeIdResolver} from './_sub/resolver/save-toe-id-resolver';
import {ToeGetResolver} from './_sub/resolver/toe-get-resolver';
import {ToeAssignmentGetResolver} from './_sub/resolver/toe-assignment-get-resolver';
import { FilterCoiService } from './toe-edit/filter-coi.service';
import { SaveToeDialogComponent } from './_sub/save-toe-dialog/save-toe-dialog.component';
import { informedConsentReducer } from 'src/app/core/toe/_reducers/informed-consent-report.reducer';
import { InformedConsentReportEffects } from 'src/app/core/toe/_effects/informed-consent-report.effects';
import { InformedConsentReportService } from 'src/app/core/toe/_services/informed-consent-report.service';
import { ToeTemplateResolver } from '../template/templates/toe-template/toe-template.resolver';
import { FileDialogV2Component } from './_sub/file-dialog-v2/file-dialog-v2.component';
import { TemplateModule } from '../template/template.module';
import { ToeTemplateListModalComponent } from './_sub/toe-template-list-modal/toe-template-list-modal.component';
import { TemplateFeatureModule } from '../template/template-feature.module';
import { TpTemplateListModalComponent } from './_sub/tp-template-list-modal/tp-template-list-modal.component';
import { ExternalRefTemplateListComponent } from './_sub/external-ref-template-list/external-ref-template-list.component';
import { SupportValuerTemplateListModalComponent } from './_sub/support-valuer-template-list-modal/support-valuer-template-list-modal.component';
import { ToeDuplicateDialogComponent } from './_sub/toe-duplicate-dialog/toe-duplicate-dialog.component';
import { ToeDuplicateWarningDialogComponent } from './_sub/toe-duplicate-warning-dialog/toe-duplicate-warning-dialog.component';
import { ToeConflictsService } from 'src/app/core/toe/_services/toe-conflicts.service';
import { toeConflictsReducer } from 'src/app/core/toe/_reducers/toe-conflicts.reducer';
import { ToeConflictsEffects } from 'src/app/core/toe/_effects/toe-conflicts.effects';
import { ToeConflictsModalComponent } from './_sub/toe-conflicts-modal/toe-conflicts-modal.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { DateWithOffsetPipe } from 'src/app/core/_base/date-with-offset.pipe';
import { CoreModule } from 'src/app/core/core.module';
import { MadFormsModule } from '../mad-forms/mad-form.module';
import { MadDateAdapter } from 'src/app/core/date/mad-date-adapter';
import { CoreStandardsService } from 'src/app/core/linked-tables/_services/core-standards.service';
import { MadSharedModule } from '../mad_shared/shared.module';
import { QuoteResolver } from './_sub/resolver/quote.resolver';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const routes: Routes = [
    {
        path: '',
        component: TOEComponent,
        children: [
            {
                path: '',
                redirectTo: 'toes',
                pathMatch: 'full'
            },
            {
                path: 'add',
                component: ToeEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    ass: ToeAssignmentGetResolver,
                    userData: GetCurrentUserResolver,
                }
            },
            {
                path: 'edit/:toe_id',
                component: ToeEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    ass: ToeAssignmentGetResolver,
                    saveToeId: SaveToeIdResolver,
                    toe: ToeGetResolver,
                    toeWorkers: GetToeWorkersResolver
                }
            },
            {
                path: 'toes',
                component: ToeListComponent
            },
            {
                path: 't/add',
                component: ToeEditComponent,
            },
            {
                path: 't/edit/:template_id',
                component: ToeEditComponent,
                resolve: {
                    template: ToeTemplateResolver
                }
            },
            {
                path: 'add-from-t/:template_id',
                component: ToeEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    ass: ToeAssignmentGetResolver,
                    userData: GetCurrentUserResolver,
                    template: ToeTemplateResolver
                }
            },
            {
                path: 'add-from-quote/:quote_id',
                component: ToeEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    ass: ToeAssignmentGetResolver,
                    userData: GetCurrentUserResolver,
                    quote: QuoteResolver
                }
            },
            {
                path: 'toes/:toe_id',
                loadChildren: () => import('../target-property/target-property.module').then(m => m.TargetPropertyModule),
                resolve: {
                    data: SaveToeIdResolver
                },
                // canActivate: [ModuleGuard],
            },
            {
                path: 'toes/v2/:toe_id',
                loadChildren: () => import('../toe-dashboard/toe-dashboard.module').then(m => m.ToeDashboardModule),
                // canActivate: [ModuleGuard],
            },
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        HttpClientModule,
        PartialsModule,
        NgbModule,
        NativeDateModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        NgxMatSelectSearchModule,
        TagInputModule,
        SharedModule,
        MadFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        StoreModule.forFeature('conflicts', conflictReducer),
        StoreModule.forFeature('toes', toesReducer),
        StoreModule.forFeature('toeReports', toeReportsReducer),
        StoreModule.forFeature('informedConsentReport', informedConsentReducer),
        StoreModule.forFeature('toe-conflicts', toeConflictsReducer),
        EffectsModule.forFeature([ConflictEffects, ToeEffects, ToeReportEffects, InformedConsentReportEffects, ToeConflictsEffects]),
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        TemplateFeatureModule,
        MadSharedModule
    ],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                // panelClass: 'kt-mat-dialog-container__wrapper',
                // maxHeight: '800px',
                // minHeight: '90vh',
                height: 'auto',
                width: '900px'
            }
        },
        {
            provide: DateAdapter,
            useClass: MadDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD MMM YYYY',
                },
                display: {
                    dateInput: 'DD MMM YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            }
        },
        HttpUtilsService,
        TypesUtilsService,
        ToeService,
        ToeReportService,
        ConflictService,
        CookieService,
        LayoutUtilsService,
        FilterCoiService,
        GetCurrentUserResolver,
        GetToeWorkersResolver,
        SaveToeIdResolver,
        ToeAssignmentGetResolver,
        ToeGetResolver,
        InformedConsentReportService,
        ToeTemplateResolver,
        ToeConflictsService,
        CoreStandardsService,
        QuoteResolver,
        provideNgxMask(options)
    ],
    declarations: [
        TOEComponent,
        // ToDoComponent,
        ToeEditComponent,
        SupportValuerListComponent,
        SupportValuerEditDialogComponent,
        TpAddDialogComponent,
        TpAddConflictDialogComponent,
        SignedToeDialogComponent,
        FileDialogComponent,
        ToeReportEditDialogComponent,
        ConflictDetailComponent,
        ConflictDetailOldComponent,
        ToeListComponent,
        // SearchSelectionComponent,
        TpEditDialogComponent,
        TpListComponent,
        GeneratedReportEditDialog,
        SaveToeDialogComponent,
        FileDialogV2Component,
        ToeTemplateListModalComponent,
        TpTemplateListModalComponent,
        ExternalRefTemplateListComponent,
        SupportValuerTemplateListModalComponent,
        ToeDuplicateDialogComponent,
        ToeDuplicateWarningDialogComponent,
        ToeConflictsModalComponent,
    ],
    exports: [ConflictDetailComponent]
})
export class ToeModule {
}
