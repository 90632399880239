// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AdditionalCostTitleService} from '../_services/additional-cost-title.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allAdditionalCostTitlesLoaded} from '../_selectors/additional-cost-title.selectors';
// Actions
import {
    AllAdditionalCostTitlesLoaded,
    AllAdditionalCostTitlesRequested,
    AdditionalCostTitleActionTypes,
    AdditionalCostTitlesPageRequested,
    AdditionalCostTitlesPageLoaded,
    AdditionalCostTitleUpdated,
    AdditionalCostTitlesPageToggleLoading,
    AdditionalCostTitleDeleted,
    AdditionalCostTitleOnServerCreated,
    AdditionalCostTitleCreated,
    AdditionalCostTitleActionToggleLoading, AdditionalCostTitleTrashFlushed, AdditionalCostTitleOnServerRestored, AdditionalCostTitleRestored,
    AdditionalCostTitleOnServerAdminRestored, AdditionalCostTitleAdminRestored, AdditionalCostTitleDeletedFromTrash, AdditionalCostTitleDeletedFromAdminTrash
} from '../_actions/additional-cost-title.actions';

@Injectable()
export class AdditionalCostTitleEffects {
    showPageLoadingDispatcher = new AdditionalCostTitlesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AdditionalCostTitleActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AdditionalCostTitleActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AllAdditionalCostTitlesRequested>(AdditionalCostTitleActionTypes.AllAdditionalCostTitlesRequested),
            withLatestFrom(this.store.pipe(select(allAdditionalCostTitlesLoaded))),
            filter(([action, isAllAdditionalCostTitleLoaded]) => !isAllAdditionalCostTitleLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllAdditionalCostTitlesLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdditionalCostTitlePage$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitlesPageRequested>(AdditionalCostTitleActionTypes.AdditionalCostTitlesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AdditionalCostTitlesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleUpdated>(AdditionalCostTitleActionTypes.AdditionalCostTitleUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleOnServerCreated>(AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new AdditionalCostTitleCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdditionalCostTitleTrash$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleTrashFlushed>(AdditionalCostTitleActionTypes.AdditionalCostTitleTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleOnServerRestored>(AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AdditionalCostTitleRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleOnServerAdminRestored>(AdditionalCostTitleActionTypes.AdditionalCostTitleOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AdditionalCostTitleAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleDeleted>(AdditionalCostTitleActionTypes.AdditionalCostTitleDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleDeletedFromTrash>(AdditionalCostTitleActionTypes.AdditionalCostTitleDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdditionalCostTitle$ = this.actions$
        .pipe(
            ofType<AdditionalCostTitleDeletedFromAdminTrash>(AdditionalCostTitleActionTypes.AdditionalCostTitleDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AdditionalCostTitleService, private store: Store<AppState>) {
    }
}
