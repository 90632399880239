// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {BuiltinFitting} from '../_models/builtin-fitting.model';

export enum BuiltinFittingActionTypes {

    AllBuiltinFittingsRequested = '[BuiltinFittings Home Page] All BuiltinFittings Requested',
    AllBuiltinFittingsLoaded = '[BuiltinFittings API] All BuiltinFittings Loaded',

    BuiltinFittingOnServerCreated = '[Edit BuiltinFitting Dialog] BuiltinFitting On Server Created',
    BuiltinFittingCreated = '[Edit BuiltinFittings Dialog] BuiltinFittings Created',
    BuiltinFittingUpdated = '[Edit BuiltinFitting Dialog] BuiltinFitting Updated',
    BuiltinFittingDeleted = '[BuiltinFittings List Page] BuiltinFitting Deleted',

    BuiltinFittingsPageRequested = '[BuiltinFittings List Page] BuiltinFittings Page Requested',
    BuiltinFittingsPageLoaded = '[BuiltinFittings API] BuiltinFittings Page Loaded',
    BuiltinFittingsPageCancelled = '[BuiltinFittings API] BuiltinFittings Page Cancelled',

    BuiltinFittingsPageToggleLoading = '[BuiltinFittings page] BuiltinFittings Page Toggle Loading',
    BuiltinFittingsActionToggleLoading = '[BuiltinFittings] BuiltinFittings Action Toggle Loading',

    // trash system

    BuiltinFittingOnServerRestored = '[BuiltinFitting Trash] BuiltinFitting On Server Restored',
    BuiltinFittingRestored = '[BuiltinFitting Trash] BuiltinFitting Restored',

    BuiltinFittingOnServerAdminRestored = '[BuiltinFitting Admin Trash] BuiltinFitting On Server Restored',
    BuiltinFittingAdminRestored = '[BuiltinFitting Admin Trash] BuiltinFitting Restored',

    BuiltinFittingDeletedFromTrash = '[BuiltinFitting Trash] BuiltinFitting deleted',
    BuiltinFittingDeletedFromAdminTrash = '[BuiltinFitting Admin Trash] BuiltinFitting deleted',

    BuiltinFittingTrash = 'BuiltinFitting Trashed',
    BuiltinFittingTrashFlushed = 'BuiltinFitting Trash Flushed',

    BuiltinFittingsClear = '[BuiltinFittings API] BuiltinFittings Page Clear',
}

export class BuiltinFittingOnServerCreated implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingOnServerCreated;

    constructor(public payload: { builtinFitting: BuiltinFitting}) {
    }
}

export class BuiltinFittingCreated implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingCreated;

    constructor(public payload: { builtinFitting: BuiltinFitting }) {
    }
}

export class BuiltinFittingUpdated implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingUpdated;

    constructor(public payload: {
        partialBuiltinFitting: Update<BuiltinFitting>,
        builtinFitting: BuiltinFitting
    }) {
    }
}

export class BuiltinFittingDeleted implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class BuiltinFittingsPageRequested implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingsPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class BuiltinFittingsPageLoaded implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingsPageLoaded;

    constructor(public payload: { builtinFittings: BuiltinFitting[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class BuiltinFittingsPageCancelled implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingsPageCancelled;
}

export class AllBuiltinFittingsRequested implements Action {
    readonly type = BuiltinFittingActionTypes.AllBuiltinFittingsRequested;
}

export class AllBuiltinFittingsLoaded implements Action {
    readonly type = BuiltinFittingActionTypes.AllBuiltinFittingsLoaded;

    constructor(public payload: { builtinFittings: BuiltinFitting[] }) {
    }
}

export class BuiltinFittingsPageToggleLoading implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class BuiltinFittingsActionToggleLoading implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class BuiltinFittingDeletedFromAdminTrash implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class BuiltinFittingDeletedFromTrash implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class BuiltinFittingOnServerRestored implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuiltinFittingRestored implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingRestored;

    constructor(public payload: { item: BuiltinFitting }) {
    }
}

export class BuiltinFittingOnServerAdminRestored implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class BuiltinFittingAdminRestored implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingAdminRestored;

    constructor(public payload: { item: BuiltinFitting }) {
    }
}

export class BuiltinFittingTrashFlushed implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingTrashFlushed;

    constructor() {
    }
}

export class BuiltinFittingsClear implements Action {
    readonly type = BuiltinFittingActionTypes.BuiltinFittingsClear;

    constructor() {
    }
}

export type BuiltinFittingActions = BuiltinFittingCreated
    | BuiltinFittingUpdated
    | BuiltinFittingDeleted
    | BuiltinFittingsPageRequested
    | BuiltinFittingsPageLoaded
    | BuiltinFittingsPageCancelled
    | AllBuiltinFittingsLoaded
    | AllBuiltinFittingsRequested
    | BuiltinFittingOnServerCreated
    | BuiltinFittingsPageToggleLoading
    | BuiltinFittingsActionToggleLoading
    | BuiltinFittingDeletedFromAdminTrash
    | BuiltinFittingDeletedFromTrash
    | BuiltinFittingOnServerRestored
    | BuiltinFittingRestored
    | BuiltinFittingOnServerAdminRestored
    | BuiltinFittingAdminRestored
    | BuiltinFittingTrashFlushed
    | BuiltinFittingsClear;
