import {ConservatoryPorcheModel} from '../_models/conservatory-porche.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ConservatoryPorchesState} from '../_reducers/conservatory-porche.reducer';
import * as fromConservatoryPorche from '../_reducers/conservatory-porche.reducer';
import {each} from 'lodash';

export const selectConservatoryPorchesState = createFeatureSelector<ConservatoryPorchesState>('conservatory-and-porches');

export const selectConservatoryPorcheById = (id: number) => createSelector(
    selectConservatoryPorchesState,
    state => state.entities[id]
);

export const selectAllConservatoryPorches = createSelector(
    selectConservatoryPorchesState,
    fromConservatoryPorche.selectAll
);

export const selectAllConservatoryPorchesIds = createSelector(
    selectConservatoryPorchesState,
    fromConservatoryPorche.selectIds
);

export const allConservatoryPorchesLoaded = createSelector(
    selectConservatoryPorchesState,
    state => state.isAllConservatoryPorchesLoaded
);


export const selectConservatoryPorchesPageLoading = createSelector(
    selectConservatoryPorchesState,
    state => state.listLoading
);

export const selectConservatoryPorchesActionLoading = createSelector(
    selectConservatoryPorchesState,
    state => state.actionLoading
);

export const selectLastCreatedConservatoryPorcheId = createSelector(
    selectConservatoryPorchesState,
    state => state.lastCreatedConservatoryPorcheId
);

export const selectConservatoryPorchesShowInitWaitingMessage = createSelector(
    selectConservatoryPorchesState,
    state => state.showInitWaitingMessage
);


export const selectTrashedConservatoryPorcheCount = createSelector(
    selectConservatoryPorchesState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedConservatoryPorcheCount = createSelector(
    selectConservatoryPorchesState,
    (state) => state.totalAdminTrashed
);


export const selectConservatoryPorcheQueryResult = createSelector(
    selectConservatoryPorchesState,
    state => {
        const items: ConservatoryPorcheModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ConservatoryPorcheModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
