// CRUD
import {QueryParamsModel} from './query-models/query-params.model';
import {QueryResultsModel} from './query-models/query-results.model';

export class HttpExtenstionsModel {

    /**
     * Filtration with sorting
     * First do Sort then filter
     *
     * @param _entities: any[]
     * @param _queryParams: QueryParamsModel
     * @param _filtrationFields: string[]
     */
    baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
        // Filtration
        let entitiesResult = this.searchInArray(_entities, _queryParams.filter, _filtrationFields);

        // Sorting
        // start
        if (_queryParams.sortField) {
            entitiesResult = this.sortArray(entitiesResult, _queryParams.sortField, _queryParams.sortOrder);
        }
        // end

        // Paginator
        // start
        const totalCount = entitiesResult.length;
        const initialPos = _queryParams.pageNumber * _queryParams.pageSize;
        entitiesResult = entitiesResult.slice(initialPos, initialPos + _queryParams.pageSize);
        // end

        const queryResults = new QueryResultsModel();
        queryResults.items = entitiesResult;
        queryResults.totalCount = totalCount;
        return queryResults;
    }

    /**
     * Sort array by field name and order-type
     * @param _incomingArray: any[]
     * @param _sortField: string
     * @param _sortOrder: string
     */
    sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
        if (!_sortField) {
            return _incomingArray;
        }

        let result: any[] = [];

        if (_sortField == 'id') {
            if (_sortOrder === 'asc') {
                result = _incomingArray.sort((a, b) => Number(a[_sortField]) - Number(b[_sortField]));
            } else if (_sortOrder === 'desc') {
                result = _incomingArray.sort((a, b) => Number(b[_sortField]) - Number(a[_sortField]));
            } else {
                result = _incomingArray;
            }
            return result;
        }

        result = _incomingArray.sort((a, b) => {
            if (a[_sortField] < b[_sortField]) {
                return _sortOrder === 'asc' ? -1 : 1;
            }

            if (a[_sortField] > b[_sortField]) {
                return _sortOrder === 'asc' ? 1 : -1;
            }

            return 0;
        });
        return result;
    }

    sortArrayString(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {

        let result = [];

        if (_sortField == 'id') {
            if (_sortOrder === 'asc') {
                result = _incomingArray.sort((a, b) => Number(a[_sortField]) - Number(b[_sortField]));
            } else if (_sortOrder === 'desc') {
                result = _incomingArray.sort((a, b) => Number(b[_sortField]) - Number(a[_sortField]));
            } else {
                result = _incomingArray;
            }
            return result;
        }


        result = _incomingArray.sort((a, b) => {
            const fields = _sortField.split('.');
            if (fields.length > 1) {
                const localeCompare = a[fields[0]][fields[1]].toLowerCase().localeCompare(b[fields[0]][fields[1]].toLowerCase(), 'en', {
                    sensitivity: 'base',
                    ignorePunctuation: true
                });

                return localeCompare * (_sortOrder === 'asc' ? 1 : -1);
            }

            const _localeCompare = a[_sortField].toLowerCase().localeCompare(b[_sortField].toLowerCase(), 'en', {
                sensitivity: 'base',
                ignorePunctuation: true
            });

            return _localeCompare * (_sortOrder === 'asc' ? 1 : -1);
        });
        return result;
    }

    sortArrayStringNoArticle(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {

        const result = _incomingArray.sort((a, b) => {
            const aStr = a[_sortField].toLowerCase().replace(/^the /g, '').replace(/^a /g, '').replace(/^an /g, '');
            const bStr = b[_sortField].toLowerCase().replace(/^the /g, '').replace(/^a /g, '').replace(/^an /g, '');
            const _localeCompare = aStr.localeCompare(bStr, 'en', {
                sensitivity: 'base',
                ignorePunctuation: true
            });
            return _localeCompare * (_sortOrder === 'asc' ? 1 : -1);
        });
        return result;
    }

    /**
     * Sort array by field name and order-type
     * @param _incomingArray: any[]
     * @param _sortField: string
     * @param _sortOrder: string
     */
    sortArrayDateTime(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
        if (!_sortField) {
            return _incomingArray;
        }

        let result: any[] = [];
        result = _incomingArray.sort((a, b) => {

            const fields = _sortField.split('.');
            if (fields.length > 1) {
                if (new Date(a[fields[0]][fields[1]]) < new Date(b[fields[0]][fields[1]])) {
                    return _sortOrder === 'asc' ? -1 : 1;
                }

                if (new Date(a[fields[0]][fields[1]]) > new Date(b[fields[0]][fields[1]])) {
                    return _sortOrder === 'asc' ? 1 : -1;
                }
            }

            if (new Date(a[_sortField]) < new Date(b[_sortField])) {
                return _sortOrder === 'asc' ? -1 : 1;
            }

            if (new Date(a[_sortField]) > new Date(b[_sortField])) {
                return _sortOrder === 'asc' ? 1 : -1;
            }

            return 0;
        });
        return result;
    }

    /**
     * Sort array by field name and order-type
     * @param _incomingArray: any[]
     * @param _sortField: string
     * @param _sortOrder: string
     */
    sortArrayNumber(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
        if (!_sortField) {
            return _incomingArray;
        }

        let result: any[] = [];
        if (_sortOrder === 'asc') {
            result = _incomingArray.sort((a, b) => (isNaN(Number(a[_sortField])) ? Number((a[_sortField]).substring(0, (a[_sortField]).indexOf(' '))) : Number(a[_sortField])) - (isNaN(Number(b[_sortField])) ? Number((b[_sortField]).substring(0, (b[_sortField]).indexOf(' '))) : Number(b[_sortField])));
        } else if (_sortOrder === 'desc') {
            result = _incomingArray.sort((a, b) => (isNaN(Number(b[_sortField])) ? Number((b[_sortField]).substring(0, (b[_sortField]).indexOf(' '))) : Number(b[_sortField])) - (isNaN(Number(a[_sortField])) ? Number((a[_sortField]).substring(0, (a[_sortField]).indexOf(' '))) : Number(a[_sortField])));
        } else {
            result = _incomingArray;
        }

        return result;
    }

    /**
     * Sort array by field name and order-type
     * @param _incomingArray: any[]
     * @param _sortField: string
     * @param _sortOrder: string
     */
    sortArrayCompletion(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
        if (!_sortField) {
            return _incomingArray;
        }

        let result: any[] = [];
        if (_sortOrder === 'asc') {
            result = _incomingArray.sort((a, b) => Number(a['point'] == a['max_point'] ? 1 : 0) - Number(b['point'] == b['max_point'] ? 1 : 0));
        } else if (_sortOrder === 'desc') {
            result = _incomingArray.sort((a, b) => Number(b['point'] == b['max_point'] ? 1 : 0) - Number(a['point'] == a['max_point'] ? 1 : 0));
        } else {
            result = _incomingArray;
        }

        return result;
    }

    /**
     * Sort array with custom key
     * @param _incomingArray: any[]
     * @param _sortField: string
     * @param _sortOrder: string
     * @param _key: any[]
     */
    sortArrayWithCustomKey(_incomingArray: any[],
                           _sortField: string = '',
                           _sortOrder: string = 'asc',
                           _key: any[]): any[] {
        if (!_sortField) {
            return _incomingArray;
        }

        let result: any[] = [];

        if (_sortOrder === 'asc') {
            result = _incomingArray.sort((a, b) => _key.indexOf(a[_sortField]) - _key.indexOf(b[_sortField]));
        } else if (_sortOrder === 'desc') {
            result = _incomingArray.sort((a, b) => _key.indexOf(b[_sortField]) - _key.indexOf(a[_sortField]));
        } else {
            result = _incomingArray;
        }

        return result;
    }

    /**
     * Filter array by some fields
     *
     * @param _incomingArray: any[]
     * @param _queryObj: any
     * @param _filtrationFields: string[]
     */
    searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
        const result: any[] = [];
        let resultBuffer: any[] = [];
        const indexes: number[] = [];
        let firstIndexes: number[] = [];
        let doSearch: boolean = false;

        _filtrationFields.forEach(item => {
            if (item in _queryObj) {
                _incomingArray.forEach((element, index) => {
                    if (element[item] === _queryObj[item]) {
                        firstIndexes.push(index);
                    }
                });
                firstIndexes.forEach(element => {
                    resultBuffer.push(_incomingArray[element]);
                });
                _incomingArray = resultBuffer.slice(0);
                resultBuffer = [].slice(0);
                firstIndexes = [].slice(0);
            }
        });

        Object.keys(_queryObj).forEach(key => {
            const searchText = _queryObj[key].toString().trim().toLowerCase();
            if (key && !_filtrationFields.some(e => e === key) && searchText) {
                doSearch = true;
                try {
                    _incomingArray.forEach((element, index) => {
                        if (element[key]) {
                            const _val = element[key].toString().trim().toLowerCase();
                            if (_val.indexOf(searchText) > -1 && indexes.indexOf(index) === -1) {
                                indexes.push(index);
                            }
                        }
                    });
                } catch (ex) {
                    // console.log(ex, key, searchText);
                }
            }
        });

        if (!doSearch) {
            return _incomingArray;
        }

        indexes.forEach(re => {
            result.push(_incomingArray[re]);
        });

        return result;
    }

    separateArrayByCompletion(_incomingArray: any[]): {
        [name: string]: any[]
    } {
        const result: { [name: string]: any[] } = {};
        result['completed'] = _incomingArray.filter(item => item['point'] === item['max_point']);
        result['inCompleted'] = _incomingArray.filter(item => item['point'] !== item['max_point']);
        return result;
    }
}