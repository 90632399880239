import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { selectPremiseOfValueById } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ReportingProcessData } from 'src/app/core/reporting/_models/reporting_process.model';

interface TemplateData {  
  basis: string;
  approach: string;
  method: string;
  premiseOfValue: string;
  valuationDate: Date;
  lastEditedDate: string;
  tenure: string;
}

@Component({
  selector: 'kt-valuation-specific',
  templateUrl: './valuation-specific.component.html',
  styleUrls: ['./valuation-specific.component.scss', '../../section.styles.scss']
})
export class ValuationSpecificComponent implements OnInit {
  @Input() valuation: ValuationModel;
  @Input() valuations: ValuationModel[] = [];
  @Input() fileCnt: number = 0;
  @Input() tp: AssetClassModel;
  @Input() reporting: ReportingProcessData;
  @Input() multimedia = false;
  data$: Observable<TemplateData>;
  constructor(
    private store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.data$ = this.store$.select(selectPremiseOfValueById(this.valuation.premise_of_value_id)).pipe(
      map(val => val ? val.name : undefined),
      filter(val => val !== undefined),
      map(premiseOfValue => {
        const data: TemplateData = {
          basis: this.valuation.base_of_value_name,
          approach: this.valuation.approaches_to_value_name,
          method: this.valuation.methods_to_value_name,
          premiseOfValue,
          valuationDate: this.valuation.updated_at,
          lastEditedDate: (this.reporting && this.reporting.valuation_date) ?? null,
          tenure: this.valuation.tenure_name
        }
        return data;
      })
    )
  }

}
