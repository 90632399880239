import { Component, Input, OnInit } from '@angular/core';
import { CFMMidTerm } from '../../../../cf-method-types';

@Component({
  selector: 'kt-mid-term-table',
  templateUrl: './mid-term-table.component.html',
  styleUrls: ['./mid-term-table.component.scss', '../../../headline-to-effective.component.scss']
})
export class MidTermTableComponent implements OnInit {
  @Input() data: CFMMidTerm;
  @Input() growthRate: number;
  @Input() targetRate: number;
  @Input() rentReview: number;
  @Input() term: number;

  constructor() { }

  ngOnInit(): void {}

}
