import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { TpSignedDocActions, TpSignedDocActionTypes } from "../_actions/tp-signed-docs.actions";
import { TpSignedDoc } from "../_models/tp-signed-doc.model";

export interface TpSignedDocState extends EntityState<TpSignedDoc> {
	isUploading: Record<number, boolean>;
	error: Record<number, Error|null>;
}
export const adapter: EntityAdapter<TpSignedDoc> = createEntityAdapter<TpSignedDoc>();
export const initialTpSignedDocState: TpSignedDocState = adapter.getInitialState({
	isUploading: {} as Record<number, boolean>,
	error: {} as Record<number, Error|null>
});

export function tpSignedDocReducer(state = initialTpSignedDocState, action: TpSignedDocActions): TpSignedDocState {
	let currentIsUploading: Record<number, boolean>;
	let currentError: Record<number, Error|null>;
	switch (action.type) {
		case TpSignedDocActionTypes.GetSignedDocs: 
			return state;
		case TpSignedDocActionTypes.GotSignedDocs:
			return adapter.upsertMany(action.payload.docs, state);
		case TpSignedDocActionTypes.UploadSignedDoc:
			currentIsUploading = Object.assign({}, state.isUploading);
			currentIsUploading[action.payload.tpID] = true;
			return {
				...state,
				isUploading: currentIsUploading
			};
		case TpSignedDocActionTypes.SignedDocUploaded:
			currentIsUploading = Object.assign({}, state.isUploading);
			currentIsUploading[action.payload.model.tp_id] = false;
			currentError = Object.assign({}, state.error);
			currentError[action.payload.model.tp_id] = null
			return adapter.addOne(action.payload.model, {
				...state, 
				isUploading: currentIsUploading,
				error: currentError
			});
		case TpSignedDocActionTypes.SignedDocUploadFailed:
			currentIsUploading = Object.assign({}, state.isUploading);
			currentIsUploading[action.payload.tpID] = false;
			currentError = Object.assign({}, state.error);
			currentError[action.payload.tpID] = action.payload.error
			return {
				...state, 
				isUploading: currentIsUploading, 
				error: currentError
			};
		case TpSignedDocActionTypes.SignedDocDeleted: 
			return adapter.removeOne(action.payload.id, state);
		default:
			return state;
	}
}