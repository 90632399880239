import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConstants {
  public NOFILE = 'No File';
}

export const awConst = {
  NOFILE: 'No File',
  IMAGE_NOT_FOUND: './assets/media/bg/image-not-found.png'
};
