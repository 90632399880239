// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {HandoverStandard} from '../_models/handover-standard.model';

export enum HandoverStandardActionTypes {

    AllHandoverStandardsRequested = '[HandoverStandards Home Page] All HandoverStandards Requested',
    AllHandoverStandardsLoaded = '[HandoverStandards API] All HandoverStandards Loaded',

    HandoverStandardOnServerCreated = '[Edit HandoverStandard Dialog] HandoverStandard On Server Created',
    HandoverStandardCreated = '[Edit HandoverStandards Dialog] HandoverStandards Created',
    HandoverStandardUpdated = '[Edit HandoverStandard Dialog] HandoverStandard Updated',
    HandoverStandardDeleted = '[HandoverStandards List Page] HandoverStandard Deleted',

    HandoverStandardsPageRequested = '[HandoverStandards List Page] HandoverStandards Page Requested',
    HandoverStandardsPageLoaded = '[HandoverStandards API] HandoverStandards Page Loaded',
    HandoverStandardsPageCancelled = '[HandoverStandards API] HandoverStandards Page Cancelled',

    HandoverStandardsPageToggleLoading = '[HandoverStandards page] HandoverStandards Page Toggle Loading',
    HandoverStandardsActionToggleLoading = '[HandoverStandards] HandoverStandards Action Toggle Loading',

    // trash system

    HandoverStandardOnServerRestored = '[HandoverStandard Trash] HandoverStandard On Server Restored',
    HandoverStandardRestored = '[HandoverStandard Trash] HandoverStandard Restored',

    HandoverStandardOnServerAdminRestored = '[HandoverStandard Admin Trash] HandoverStandard On Server Restored',
    HandoverStandardAdminRestored = '[HandoverStandard Admin Trash] HandoverStandard Restored',

    HandoverStandardDeletedFromTrash = '[HandoverStandard Trash] HandoverStandard deleted',
    HandoverStandardDeletedFromAdminTrash = '[HandoverStandard Admin Trash] HandoverStandard deleted',

    HandoverStandardTrash = 'HandoverStandard Trashed',
    HandoverStandardTrashFlushed = 'HandoverStandard Trash Flushed',
}

export class HandoverStandardOnServerCreated implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardOnServerCreated;

    constructor(public payload: { handoverStandard: HandoverStandard }) {
    }
}

export class HandoverStandardCreated implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardCreated;

    constructor(public payload: { handoverStandard: HandoverStandard }) {
    }
}

export class HandoverStandardUpdated implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardUpdated;

    constructor(public payload: {
        partialHandoverStandard: Update<HandoverStandard>,
        handoverStandard: HandoverStandard
    }) {
    }
}

export class HandoverStandardDeleted implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class HandoverStandardsPageRequested implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class HandoverStandardsPageLoaded implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardsPageLoaded;

    constructor(public payload: { handoverStandards: HandoverStandard[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class HandoverStandardsPageCancelled implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardsPageCancelled;
}

export class AllHandoverStandardsRequested implements Action {
    readonly type = HandoverStandardActionTypes.AllHandoverStandardsRequested;
}

export class AllHandoverStandardsLoaded implements Action {
    readonly type = HandoverStandardActionTypes.AllHandoverStandardsLoaded;

    constructor(public payload: { handoverStandards: HandoverStandard[] }) {
    }
}

export class HandoverStandardsPageToggleLoading implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class HandoverStandardsActionToggleLoading implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class HandoverStandardDeletedFromAdminTrash implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class HandoverStandardDeletedFromTrash implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class HandoverStandardOnServerRestored implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class HandoverStandardRestored implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardRestored;

    constructor(public payload: { item: HandoverStandard }) {
    }
}

export class HandoverStandardOnServerAdminRestored implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class HandoverStandardAdminRestored implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardAdminRestored;

    constructor(public payload: { item: HandoverStandard }) {
    }
}

export class HandoverStandardTrashFlushed implements Action {
    readonly type = HandoverStandardActionTypes.HandoverStandardTrashFlushed;

    constructor() {
    }
}

export type HandoverStandardActions = HandoverStandardCreated
    | HandoverStandardUpdated
    | HandoverStandardDeleted
    | HandoverStandardsPageRequested
    | HandoverStandardsPageLoaded
    | HandoverStandardsPageCancelled
    | AllHandoverStandardsLoaded
    | AllHandoverStandardsRequested
    | HandoverStandardOnServerCreated
    | HandoverStandardsPageToggleLoading
    | HandoverStandardsActionToggleLoading
    | HandoverStandardDeletedFromAdminTrash
    | HandoverStandardDeletedFromTrash
    | HandoverStandardOnServerRestored
    | HandoverStandardRestored
    | HandoverStandardOnServerAdminRestored
    | HandoverStandardAdminRestored
    | HandoverStandardTrashFlushed;
