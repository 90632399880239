import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';

@Component({
  selector: 'kt-mad-ngb-image-viewer',
  templateUrl: './mad-ngb-image-viewer.component.html',
  styleUrls: ['./mad-ngb-image-viewer.component.scss']
})
export class MadNgbImageViewerComponent implements OnInit {

  @Input()
  obj: any;
  @Input()
  dataWithoutViewer: boolean;
  img: any;
  path: string;
  basePath: string;
  loading: boolean;
  hasError: boolean = false;
  withoutViewer: boolean = false;
  awConst = awConst;

  constructor(
    private activeModal: NgbActiveModal
  ) { 
    this.basePath = environment.baseApiUrl;
    this.loading = true;
    if (this.dataWithoutViewer) {
      this.withoutViewer = true;
    }
  }

  ngOnInit(): void {
    this.path = this.obj.picture;
    this.img = this.obj.img;
  }

  onImageLoad(e) {
    this.loading = false;
  }

  imgOnError(e) {
    this.loading = false;
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  pdfLoadingProgress(progressData: PDFProgressData) {
    if (progressData.loaded) {
      this.loading = false;
    }
  }

  onError(e: any) {
    this.loading = false;
    this.hasError = true;
  }

  onNoClick() {
    this.activeModal.close();
  }
}
