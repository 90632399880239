// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {RentReviewComment} from '../_models/rent-review-comment.model';

export enum RentReviewCommentActionTypes {

    AllRentReviewCommentsRequested = '[RentReviewComments Home Page] All RentReviewComments Requested',
    AllRentReviewCommentsLoaded = '[RentReviewComments API] All RentReviewComments Loaded',

    RentReviewCommentOnServerCreated = '[Edit RentReviewComment Dialog] RentReviewComment On Server Created',
    RentReviewCommentCreated = '[Edit RentReviewComments Dialog] RentReviewComments Created',
    RentReviewCommentUpdated = '[Edit RentReviewComment Dialog] RentReviewComment Updated',
    RentReviewCommentDeleted = '[RentReviewComments List Page] RentReviewComment Deleted',

    RentReviewCommentsPageRequested = '[RentReviewComments List Page] RentReviewComments Page Requested',
    RentReviewCommentsPageLoaded = '[RentReviewComments API] RentReviewComments Page Loaded',
    RentReviewCommentsPageCancelled = '[RentReviewComments API] RentReviewComments Page Cancelled',

    RentReviewCommentsPageToggleLoading = '[RentReviewComments page] RentReviewComments Page Toggle Loading',
    RentReviewCommentsActionToggleLoading = '[RentReviewComments] RentReviewComments Action Toggle Loading',

    // trash system

    RentReviewCommentOnServerRestored = '[RentReviewComment Trash] RentReviewComment On Server Restored',
    RentReviewCommentRestored = '[RentReviewComment Trash] RentReviewComment Restored',

    RentReviewCommentOnServerAdminRestored = '[RentReviewComment Admin Trash] RentReviewComment On Server Restored',
    RentReviewCommentAdminRestored = '[RentReviewComment Admin Trash] RentReviewComment Restored',

    RentReviewCommentDeletedFromTrash = '[RentReviewComment Trash] RentReviewComment deleted',
    RentReviewCommentDeletedFromAdminTrash = '[RentReviewComment Admin Trash] RentReviewComment deleted',

    RentReviewCommentTrash = 'RentReviewComment Trashed',
    RentReviewCommentTrashFlushed = 'RentReviewComment Trash Flushed',
}

export class RentReviewCommentOnServerCreated implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentOnServerCreated;

    constructor(public payload: { rentReviewComment: RentReviewComment}) {
    }
}

export class RentReviewCommentCreated implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentCreated;

    constructor(public payload: { rentReviewComment: RentReviewComment }) {
    }
}

export class RentReviewCommentUpdated implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentUpdated;

    constructor(public payload: {
        partialRentReviewComment: Update<RentReviewComment>,
        rentReviewComment: RentReviewComment
    }) {
    }
}

export class RentReviewCommentDeleted implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class RentReviewCommentsPageRequested implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class RentReviewCommentsPageLoaded implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentsPageLoaded;

    constructor(public payload: { rentReviewComments: RentReviewComment[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class RentReviewCommentsPageCancelled implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentsPageCancelled;
}

export class AllRentReviewCommentsRequested implements Action {
    readonly type = RentReviewCommentActionTypes.AllRentReviewCommentsRequested;
}

export class AllRentReviewCommentsLoaded implements Action {
    readonly type = RentReviewCommentActionTypes.AllRentReviewCommentsLoaded;

    constructor(public payload: { rentReviewComments: RentReviewComment[] }) {
    }
}

export class RentReviewCommentsPageToggleLoading implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class RentReviewCommentsActionToggleLoading implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class RentReviewCommentDeletedFromAdminTrash implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class RentReviewCommentDeletedFromTrash implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class RentReviewCommentOnServerRestored implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RentReviewCommentRestored implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentRestored;

    constructor(public payload: { item: RentReviewComment }) {
    }
}

export class RentReviewCommentOnServerAdminRestored implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class RentReviewCommentAdminRestored implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentAdminRestored;

    constructor(public payload: { item: RentReviewComment }) {
    }
}

export class RentReviewCommentTrashFlushed implements Action {
    readonly type = RentReviewCommentActionTypes.RentReviewCommentTrashFlushed;

    constructor() {
    }
}

export type RentReviewCommentActions = RentReviewCommentCreated
    | RentReviewCommentUpdated
    | RentReviewCommentDeleted
    | RentReviewCommentsPageRequested
    | RentReviewCommentsPageLoaded
    | RentReviewCommentsPageCancelled
    | AllRentReviewCommentsLoaded
    | AllRentReviewCommentsRequested
    | RentReviewCommentOnServerCreated
    | RentReviewCommentsPageToggleLoading
    | RentReviewCommentsActionToggleLoading
    | RentReviewCommentDeletedFromAdminTrash
    | RentReviewCommentDeletedFromTrash
    | RentReviewCommentOnServerRestored
    | RentReviewCommentRestored
    | RentReviewCommentOnServerAdminRestored
    | RentReviewCommentAdminRestored
    | RentReviewCommentTrashFlushed;
