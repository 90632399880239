import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LandAdjustmentsState } from "../_reducers/land-adjustment.reducers";
import * as fromAdjustment from '../_reducers/land-adjustment.reducers';
import { LandAdjustmentModel } from "../_models/land-adjustment.model";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";

export const selectLandAdjustmentsState = createFeatureSelector<LandAdjustmentsState>('land-adjustments');

export const selectLandAdjustmentById = (AdjustmentId: number) => createSelector(
    selectLandAdjustmentsState,
    state => state.entities[AdjustmentId]
);

export const selectAllLandAdjustments = createSelector(
    selectLandAdjustmentsState,
    fromAdjustment.selectAll
);

export const selectAllLandAdjustmentsIds = createSelector(
    selectLandAdjustmentsState,
    fromAdjustment.selectIds
);

export const allLandAdjustmentsLoaded = createSelector(
    selectLandAdjustmentsState,
    state => state.isAllAdjustmentsLoaded
);


export const selectLandAdjustmentsPageLoading = createSelector(
    selectLandAdjustmentsState,
    state => state.listLoading
);

export const selectLandAdjustmentsActionLoading = createSelector(
    selectLandAdjustmentsState,
    state => state.actionLoading
);

export const selectLastCreatedLandAdjustmentId = createSelector(
    selectLandAdjustmentsState,
    state => state.lastCreatedAdjustmentId
);

export const selectLandAdjustmentsShowInitWaitingMessage = createSelector(
    selectLandAdjustmentsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedLandAdjustmentCount = createSelector(
    selectLandAdjustmentsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedLandAdjustmentCount = createSelector(
    selectLandAdjustmentsState,
    (state) => state.totalAdminTrashed
);


export const selectLandAdjustmentQueryResult = createSelector(
    selectLandAdjustmentsState,
    state => {
        const items: LandAdjustmentModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: LandAdjustmentModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);