export type BaseTask = {
  id: number,
  ac_id: number,
  assignee_id: number,
  assignee: {
    id: number,
    name: string
  },
  point: number,
  expected_date: string,
  completed_date: string
}

type ExternalRefTask = BaseTask & {
  key: 'external_refs',
  category: 'investigation',
  category_name: string,
  name: string
}
export function mapToExternalRefTask(base: BaseTask): ExternalRefTask {
  return {
    ...base,
    key: 'external_refs',
    category: 'investigation',
    category_name: 'Investigation',
    name: 'External References'
  }
}

type LogisticsTask = BaseTask & {
  key: 'logistics',
  category: 'investigation',
  category_name: string,
  name: string
}
export function mapToLogisticsTask(base: BaseTask): LogisticsTask {
  return {
    ...base,
    key: 'logistics',
    category: 'investigation',
    category_name: 'Investigation',
    name: 'Logistics'
  }
}

type LandmarksTask = BaseTask & {
  key: 'landmarks',
  category: 'investigation',
  category_name: string,
  name: string
}
export function mapToLandmarksTask(base: BaseTask): LandmarksTask {
  return {
    ...base,
    key: 'landmarks',
    category: 'investigation',
    category_name: 'Investigation',
    name: 'Landmarks'
  }
}

type InspectionTask = BaseTask & {
  key: 'inspection',
  category: 'investigation',
  category_name: string,
  name: string,
  inspection_type: {
    name: string
  }
}
export function mapToInspectionTask(base: BaseTask, meta: {inspection_type: string}): InspectionTask {
  return {
    ...base,
    key: 'inspection',
    category: 'investigation',
    category_name: 'Investigation',
    name: meta.inspection_type,
    inspection_type: {
      name: meta.inspection_type
    }
  }
}

type FinancialTask = BaseTask & {
  key: 'financial',
  category: 'investigation',
  category_name: string,
  name: string
}
export function mapToFinancialTask(base: BaseTask): FinancialTask {
  return {
    ...base,
    key: 'financial',
    category: 'investigation',
    category_name: 'Investigation',
    name: 'Financial Details'
  }
}

type ValuationTask = BaseTask & {
  key: 'valuation',
  category: 'valuation',
  category_name: string,
  name: string,
  valuation: {
    id: number
  }
}
export function mapToValuationTask(base: BaseTask, index: number, meta: {valuation_id: number, method: string, tenure: string}): ValuationTask {
  return {
    ...base,
    key: 'valuation',
    category: 'valuation',
    category_name: `Valuation ${index + 1}`,
    name: `${meta.method} (${meta.tenure})`,
    valuation: {
      id: meta.valuation_id
    }
  }
}

export type Task 
  = ExternalRefTask
  | LogisticsTask
  | LandmarksTask
  | InspectionTask
  | FinancialTask 
  | ValuationTask

export type TaskKey = Task['key']