<div>
    <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container" *ngIf="!disable">
        <button
                class="btn btn-primary"
                color="primary"
                (click)="addItem()"
                matTooltip="Add a New Consideration"
                type="button">Add Consideration
        </button>
    </div>

    <mat-table #table [dataSource]="datasource">
        <ng-container matColumnDef="land_tenure_name">
            <mat-header-cell *matHeaderCellDef>Land Tenure</mat-header-cell>
            <mat-cell *matCellDef="let item; let i = index">
                <mat-select style="width: 80%;" [(ngModel)]="item.land_tenure_id" *ngIf="!disable">
                    <mat-option *ngFor="let val of landTenures" [value]="val.id">
                        {{val.name + ' (' + val.payment_type + ')'}}
                    </mat-option>
                </mat-select>
                {{ disable ? item.land_tenure_name : '' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tenure_length">
            <mat-header-cell *matHeaderCellDef>Tenure Length (years)</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <input class="title-input" [(ngModel)]="item.tenure_length" *ngIf="!disable"/>
                {{ disable ? item.tenure_length : '' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="currency">
            <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <!-- {{item.currency}} -->
                <mat-select style="width: 80%;" [(ngModel)]="item.currency" *ngIf="!disable">
                    <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                        {{_data.currencyCode}}
                    </mat-option>
                </mat-select>
                {{ disable ? item.currency: '' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="consideration">
            <mat-header-cell *matHeaderCellDef>Consideration</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <input class="title-input" [(ngModel)]="item.consideration" mask="separator" thousandSeparator="," *ngIf="!disable"/>
                {{ disable ? (item.consideration | mask: 'separator':{thousandSeparator: ','} ) : '' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" *ngIf="!disable">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let item; let i = index;">
                <button mat-icon-button
                        color="primary"
                        matTooltip="Edit"
                        (click)="editItem(item, i)"
                        type="button">
                    <mat-icon>create</mat-icon>
                </button>&nbsp;
                <button mat-icon-button
                        color="warn"
                        matTooltip="Delete"
                        (click)="deleteItem(i)"
                        type="button">
                    <mat-icon>delete</mat-icon>
                </button>&nbsp;
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>    
</div>