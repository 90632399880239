import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { each } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllCitiesRequested, AllCountriesRequested, CityModel, Client, ClientOnServerCreated, CountryModel, selectAllCities, selectAllCountries, selectCitiesByCountryId, selectLastCreatedClientId } from 'src/app/core/admin';
import { AppState } from 'src/app/core/reducers';
import { CityEditComponent } from '../../../admin-management/countries/cities/city-edit/city-edit.component';
import { CountryEditComponent } from '../../../admin-management/countries/country-edit/country-edit.component';
import { ContactListComponent } from '../_subs/client_contact/contact-list/contact-list.component';

@Component({
  selector: 'kt-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss']
})
export class ClientModalComponent implements OnInit, OnDestroy {
  @ViewChild(ContactListComponent, { static: false }) contactComponent: ContactListComponent;
  // Countries
  hasFormErrors = false;
  hasFormErrorsDesc = '';
  private _allCountries: CountryModel[] = [];
  public countries: CountryModel[] = [];
  private _cityOfCountry: CityModel[] = [];
  public cities: CityModel[] = [];
  client: Client;
  viewLoading = false;
  loadingAfterSubmit = false;
  private componentSubscriptions: Subscription;
  countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  cityFilterCtrl: UntypedFormControl = new UntypedFormControl();
  private _onDestroy$: Subject<void> = new Subject();
  private subscriptions: Subscription[] = [];
  clientForm: UntypedFormGroup;
  emailRegex: any = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  validationMessages: any;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ClientModalComponent>
  ) {
    this.validationMessages = {
      email: [
        {
          type: 'required',
          message: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.REQUIRED.MESSAGE'),
          decorated: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.REQUIRED.DECORATION')
        }, {
          type: 'pattern',
          message: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.PATTERN.MESSAGE'),
          decorated: this.translate.instant('CLIENT.FORM.GENERAL.FORM_FIELD.EMAIL.VALIDATION.PATTERN.DECORATION')
        }
      ]
    };
  }

  ngOnInit(): void {
    this.client = new Client();
    this.client.clear();
    this.initClient();
    this.store.dispatch(new AllCountriesRequested());
    this.store.dispatch(new AllCitiesRequested());
    const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
      this._allCountries = [];
      each(res, (_country: CountryModel) => {
        this._allCountries.push(_country);
      });
      this._filterCountries();
    });

    this.subscriptions.push(countrySubscription);

    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this._filterCountries()
      });
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this._filterCities();
      })
  }

  ngOnDestroy(): void {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
    this.subscriptions.forEach(s => s.unsubscribe());
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _filterCountries() {
    let search = this.countryFilterCtrl.value;
    if (!search || search === '') {
      this.countries = this._allCountries.slice();
      return;
    } else {
      search = search.toLowerCase();
    }

    this.countries = this._allCountries.filter(c => c.name.toLowerCase().indexOf(search) > -1);
  }

  private _filterCities() {
    let search = this.cityFilterCtrl.value;
    if (!search || search === '') {
      this.cities = this._cityOfCountry.slice();
      return;
    } else {
      search = search.toLowerCase();
    }

    this.cities = this._cityOfCountry.filter(c => c.name.toLowerCase().indexOf(search) > -1);
  }

  addCountry() {
    const dialogRef = this.dialog.open(CountryEditComponent, { data: { id: undefined } });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      this.clientForm.controls.country_id.setValue(res.id);
      this.clientForm.controls.country_id.updateValueAndValidity();
    })
  }

  addCity() {
    const newCity = new CityModel();
    newCity.clear();
    newCity.country_id = this.clientForm.controls.country_id.value;
    const country = this._allCountries.find(c => c.id == newCity.country_id);
    newCity.country_name = country ? country.name : null;
    const dialogRef = this.dialog.open(CityEditComponent, { data: { city: newCity } });
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      this.clientForm.controls.city_id.setValue(res.id);
      this.clientForm.controls.city_id.updateValueAndValidity();
      this.selectCountry();
    })
  }

  initClient() {
    this.createForm();
  }


  createForm() {
    this.clientForm = this.fb.group({
      name: [this.client.name, Validators.required],
      last_name: [this.client.last_name],
      phone: [this.client.phone],
      email: [this.client.email],
      country_id: [this.client.country_id, Validators.required],
      city_id: [this.client.city_id, Validators.required],
      zip_code: [this.client.zip_code],

      type: [{ value: this.client.type, disabled: this.client.id }, Validators.required],
      address: [this.client.address, Validators.required],
    });
    if (this.client.country_id > 0) {
      this.selectCountry();
    }

    const typeCtrl = this.clientForm.controls.type;
    const changes$ = typeCtrl.valueChanges;

    changes$.subscribe(type => {
      if (type === 'Company') {
        this.clientForm.controls.last_name.clearValidators();
        this.clientForm.controls.phone.clearValidators();
        this.clientForm.controls.email.clearValidators();
      } else {
        this.clientForm.controls.last_name.setValidators([Validators.required]);
        this.clientForm.controls.phone.setValidators([Validators.required]);
        this.clientForm.controls.email.setValidators(Validators.compose([Validators.required, Validators.pattern(this.emailRegex)]));
      }
      this.clientForm.controls.last_name.updateValueAndValidity();
      this.clientForm.controls.phone.updateValueAndValidity();
      this.clientForm.controls.email.updateValueAndValidity();
    });
  }

  selectCountry() {
    this.client.country_id
    this.store.pipe(select(selectCitiesByCountryId(this.clientForm.controls.country_id.value))).subscribe((res: CityModel[]) => {
      this._cityOfCountry = res;
      this._filterCities();
    });
  }

  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.clientForm.controls;
    /** check form */
    if (this.clientForm.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });

      this.hasFormErrorsDesc = this.translate.instant('GENERAL.MESSAGE.FORM_WARNING');
      this.hasFormErrors = true;
      return;
    }

    if (this.client.type == 'Company' && this.contactComponent.contactData.length == 0) {

      this.hasFormErrorsDesc = this.translate.instant('CLIENT.FORM.FIELD.GENERAL.CONTACTS.VALIDATION');
      this.hasFormErrors = true;
      return;
    }

    this.createClient(this.client);
  }

  createClient(_client: Client) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.store.dispatch(new ClientOnServerCreated({
      client: _client,
      contacts: this.contactComponent ? this.contactComponent.contactData : []
    }));
    this.componentSubscriptions = this.store.pipe(
      select(selectLastCreatedClientId),
    ).subscribe(res => {
      if (!res) {
        return;
      }
      this.viewLoading = false;
      this.dialogRef.close({
        id: res,
        isEdit: false,
      });
    });
  }
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  isFormValid() {
    if (!this.client) {
      return false;
    }
    if (this.client.type == 'Company') {
      return (this.client && this.client.name && this.client.name.length > 0) &&
        (this.client && this.client.address && this.client.address.length > 0) &&
        (this.client && this.client.country_id && this.client.country_id > 0) &&
        (this.client && this.client.city_id && this.client.city_id > 0);
    }
    return (this.client && this.client.name && this.client.name.length > 0) &&
      (this.client && this.client.last_name && this.client.last_name.length > 0) &&
      (this.client && this.client.phone && this.client.phone.length > 7) &&
      (this.client && this.client.email && this.client.email.length > 0) &&
      (this.client && this.client.address && this.client.address.length > 0) &&
      (this.client && this.client.country_id && this.client.country_id > 0) &&
      (this.client && this.client.city_id && this.client.city_id > 0);
  }
}
