// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {AgenciesState} from '../_reducers/agency.reducers';
import * as fromAgencies from '../_reducers/agency.reducers';
import {HttpExtenstionsModel, QueryResultsModel} from '../../_base/crud';
import {each} from 'lodash';
import {AgencyModel} from '../';

export const selectAgenciesState = createFeatureSelector<AgenciesState>('agencies');

export const selectAgencyById = (agencyId: number) => createSelector(
    selectAgenciesState,
    cs => cs.entities[agencyId]
);

export const selectAllAgencies = createSelector(
    selectAgenciesState,
    fromAgencies.selectAll
);

export const selectActionLoading = createSelector(
    selectAgenciesState,
    _state => _state.actionsLoading
);

export const selectAgenciesPageLoading = createSelector(
    selectAgenciesState,
    _state => _state.listLoading
);


export const selectLastCreatedAgencyId = createSelector(
    selectAgenciesState,
    (_state) => _state.lastCreatedAgencyId
);

export const selectTrashedAgencyCount = createSelector(
    selectAgenciesState,
    (_state) => _state.totalTrashed
)

export const selectAdminTrashedAgencyCount = createSelector(
    selectAgenciesState,
    (_state) => _state.totalAdminTrashed
);

export const selectQueryResult = createSelector(
    selectAgenciesState,
    _state => {
        const items: AgencyModel[] = [];
        each(_state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();

        let result: AgencyModel[] = [];
        switch (_state.lastQuery.sortField) {
            case 'name':
                result = httpExtension
                    .sortArrayString(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);
                break;

            default:
                result = httpExtension
                    .sortArray(items, _state.lastQuery.sortField, _state.lastQuery.sortOrder);

        }

        return new QueryResultsModel(result, result.length, '', _state.totalTrashed);
    }
);

export const selectAgenciesShowInitWaitingMessage = createSelector(
    selectAgenciesState,
    _state => _state.showInitWaitingMessage
);

