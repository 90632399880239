import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AddToeTemplate, DeleteToeTemplate, DuplicateToeTemplate, LoadToeTemplates, ToeTemplateActionTypes, ToeTemplateAdded, ToeTemplatesLoaded, ToeTemplateUpdated, UpdateToeTemplate } from "../_actions";
import { ToeTemplateModel } from "../_models";
import { ToeTemplateState } from "../_reducers";
import { ToeTemplateService } from "../_services";

@Injectable()
export class ToeTemplateEffects {
    constructor(
        private actions: Actions, 
        private service: ToeTemplateService,
    ) {}
    @Effect()
    loadToeTemplates$ =
        this.actions.pipe(
            ofType<LoadToeTemplates>(ToeTemplateActionTypes.LOAD_TOE_TEMPLATES),
            mergeMap(({payload}) => {
                return this.service.getAll(payload.request)
            }),
            map(res => {
                return new ToeTemplatesLoaded({
                    templates: res.templates,
                    total: res.total
                });
            })
        )

    @Effect()
    addToeTemplate$ =
        this.actions.pipe(
            ofType<AddToeTemplate>(ToeTemplateActionTypes.ADD_TOE_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.create(payload.template);
            }),
            map(res => {
                return new ToeTemplateAdded({
                    template: res 
                })
            })
        )

    @Effect()
    updateToeTemplate$ =
        this.actions.pipe(
            ofType<UpdateToeTemplate>(ToeTemplateActionTypes.UPDATE_TOE_TEMPLATE),
            mergeMap(({payload}) => {
                return this.service.update(payload.id, payload.template);
            }),
            map(res => {
                const newTemplate: Update<ToeTemplateModel> = {
                    id: res.id,
                    changes: {
                        ...res
                    }
                }
                return new ToeTemplateUpdated({
                    id: res.id,
                    template: newTemplate
                })
            })
        )

    @Effect()
    deleteToeTemplate$ =
        this.actions.pipe(
            ofType<DeleteToeTemplate>(ToeTemplateActionTypes.DELETE_TOE_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.delete(payload.id),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ]);
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadToeTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )

    @Effect()
    duplicateToeTemplate$ =
        this.actions.pipe(
            ofType<DuplicateToeTemplate>(ToeTemplateActionTypes.DUPLICATE_TOE_TEMPLATE),
            mergeMap(({payload}) => {
                return combineLatest([
                    this.service.duplicate(payload.template),
                    of({currentPage: payload.currentPage, currentPageSize: payload.currentPageSize})
                ]);
            }),
            map(([res, {currentPage, currentPageSize}]) => {
                return new LoadToeTemplates({
                    request: {
                        pageNumber: currentPage,
                        pageSize: currentPageSize
                    }
                })
            })
        )
}