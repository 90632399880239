<div>
    <div class="example-container mat-elevation-z0">
        <div class="col-md-12 px-0 kt-margin-bottom-20-mobile" *ngIf="paymentForm.controls.pricing_strategy.value == 5">
            <h5 class="my-3">Overview</h5>
        </div>
        <mat-table #table [dataSource]="dataSource"> 
            <ng-container matColumnDef="asset_class_name">
                <mat-header-cell *matHeaderCellDef> Target Property ID</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.asset_class_name ? item.asset_class_name: 'N/A'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="property_type_id">
                <mat-header-cell *matHeaderCellDef> Property Sub-Type</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{getPropertyTypeName(item)}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="base_of_value_name">
                <mat-header-cell *matHeaderCellDef>Basis of Value</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.base_of_value_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="methods_to_value_name">
                <mat-header-cell *matHeaderCellDef> Method</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.methods_to_value_name.toUpperCase()}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="review_type">
                <mat-header-cell *matHeaderCellDef>Investigation Type</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{ getReviewType(item) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="approaches_to_value_name">
                <mat-header-cell *matHeaderCellDef> Size</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <ng-container *ngIf="item.surface == 0">
                        N/A
                    </ng-container>
                    <ng-container *ngIf="item.surface != 0">
                        {{item.surface}} {{unitSubject.value}}
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="estimated_value" *ngIf="paymentForm.controls.pricing_strategy.value == 2">
                <mat-header-cell *matHeaderCellDef> Estimated Value</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <!-- <mat-form-field 
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never"> -->
                        <div class="px-2">
                            <input class="input" placeholder="Estimated value"
                            [(ngModel)]="item.estimated_value"
                            mask="separator"
                            thousandSeparator=","
                            prefix="{{currencySubject.value}} "
                            (blur)="change($event, i)">
                        </div>
                    <!-- </mat-form-field> -->
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="percentage" *ngIf="paymentForm.controls.pricing_strategy.value == 2">
                <mat-header-cell *matHeaderCellDef> %</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <!-- <mat-form-field 
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never"> -->
                        <div>
                            <input class="input" placeholder="Percentage"
                            [(ngModel)]="item.percentage"
                            mask="percent"
                            suffix="%"
                            (blur)="change($event, i)">
                        </div>
                    <!-- </mat-form-field> -->
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="per_size_value" *ngIf="paymentForm.controls.pricing_strategy.value == 3">
                <mat-header-cell *matHeaderCellDef> Per {{unitSubject.value}} </mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <!-- <mat-form-field 
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never"> -->
                            <input class="input" placeholder="Per Size value"
                            [(ngModel)]="item.per_size_value"
                            mask="separator"
                            thousandSeparator=","
                            prefix="{{currencySubject.value}} "
                            (blur)="change($event, i)">
                    <!-- </mat-form-field> -->
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="daily_rate" *ngIf="paymentForm.controls.pricing_strategy.value == 4">
                <mat-header-cell *matHeaderCellDef> Daily Rate</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <div class="px-2">
                        <input class="input" placeholder="Daily Rate"
                        [(ngModel)]="item.daily_rate"
                        mask="separator"
                        thousandSeparator=","
                        prefix="{{currencySubject.value}} "
                        (blur)="change($event, i)">
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="days" *ngIf="paymentForm.controls.pricing_strategy.value == 4">
                <mat-header-cell *matHeaderCellDef> Days</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <div>
                        <input class="input" placeholder="Days"
                        [(ngModel)]="item.days"
                        mask="0000"
                        (blur)="change($event, i)">
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="total_price" *ngIf="paymentForm.controls.pricing_strategy.value != 5">
                <mat-header-cell *matHeaderCellDef> Price</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index">
                    <!-- <mat-form-field *ngIf="paymentForm.controls.pricing_strategy.value == 1"
                        class="mt-0 mb-0"
                        [appearance]="toeStatus === 0 ? 'fill' : 'legacy'"
                        floatLabel="never"> -->
                        <div *ngIf="paymentForm.controls.pricing_strategy.value == 1">
                            <input class="input" placeholder="Price"
                            [(ngModel)]="item.total_price"
                            mask="separator"
                            thousandSeparator=","
                            prefix="{{currencySubject.value}} "
                            (blur)="change($event, i)">
                        </div>
                    <!-- </mat-form-field> -->
                    <span *ngIf="paymentForm.controls.pricing_strategy.value != 1">
                        {{currencySubject.value}} {{item.total_price | mask: 'separator':{thousandSeparator: ','} }}
                    </span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>