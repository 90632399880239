// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {PlaningStatusesService} from '../_services/planing-status.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allPlaningStatusesLoaded} from '../_selectors/planing-status.selectors';
// Actions
import {
    AllPlaningStatusesLoaded,
    AllPlaningStatusesRequested,
    PlaningStatusActionTypes,
    PlaningStatusesPageRequested,
    PlaningStatusesPageLoaded,
    PlaningStatusUpdated,
    PlaningStatusesPageToggleLoading,
    PlaningStatusDeleted,
    PlaningStatusOnServerCreated,
    PlaningStatusCreated,
    PlaningStatusAdminRestored,
    PlaningStatusDeletedFromAdminTrash,
    PlaningStatusDeletedFromTrash,
    PlaningStatusOnServerAdminRestored,
    PlaningStatusOnServerRestored,
    PlaningStatusRestored,
    PlaningStatusTrashFlushed,
    PlaningStatusesActionToggleLoading
} from '../_actions/planing-status.actions';

@Injectable()
export class PlaningStatusEffects {
    showPageLoadingDispatcher = new PlaningStatusesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PlaningStatusesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PlaningStatusesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllPlaningStatuses$ = this.actions$
        .pipe(
            ofType<AllPlaningStatusesRequested>(PlaningStatusActionTypes.AllPlaningStatusesRequested),
            withLatestFrom(this.store.pipe(select(allPlaningStatusesLoaded))),
            filter(([action, isAllPlaningStatusesLoaded]) => !isAllPlaningStatusesLoaded),
            mergeMap(() => this.service.getAllPlaningStatuses()),
            map(res => {
                return new AllPlaningStatusesLoaded({planingStatuses: res.data});
            })
        );

    @Effect()
    loadPlaningStatusesPage$ = this.actions$
        .pipe(
            ofType<PlaningStatusesPageRequested>(PlaningStatusActionTypes.PlaningStatusesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findPlaningStatuses(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PlaningStatusesPageLoaded({
                    planingStatuses: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deletePlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusDeleted>(PlaningStatusActionTypes.PlaningStatusDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deletePlaningStatus(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updatePlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusUpdated>(PlaningStatusActionTypes.PlaningStatusUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updatePlaningStatus(payload.planingStatus);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createPlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusOnServerCreated>(PlaningStatusActionTypes.PlaningStatusOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createPlaningStatus(payload.planingStatus).pipe(
                    tap(res => {
                        this.store.dispatch(new PlaningStatusCreated({planingStatus: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushPlaningStatusTrash$ = this.actions$
        .pipe(
            ofType<PlaningStatusTrashFlushed>(PlaningStatusActionTypes.PlaningStatusTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusOnServerRestored>(PlaningStatusActionTypes.PlaningStatusOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PlaningStatusRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminPlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusOnServerAdminRestored>(PlaningStatusActionTypes.PlaningStatusOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PlaningStatusAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashPlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusDeletedFromTrash>(PlaningStatusActionTypes.PlaningStatusDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashPlaningStatus$ = this.actions$
        .pipe(
            ofType<PlaningStatusDeletedFromAdminTrash>(PlaningStatusActionTypes.PlaningStatusDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: PlaningStatusesService, private store: Store<AppState>) {
    }
}
