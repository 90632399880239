import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataTableDataSource } from "src/app/views/partials/content/widgets/general/data-table/data-table.data-source";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { AgencyInvolvementWithClient, AgencyInvolvementWithTp, LvInvolvementWithClient, LvInvolvementWithTp, ToeConflictAgency, ToeConflictClient, ToeConflictLeadValuer, ToeConflictTpQuery } from "../_models/toe-conflicts-model";
import { SubdomainService } from "../../_base/subdomain.service";

@Injectable()
export class ToeConflictsService {
    private apiURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe-conflicts`

    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {}

    loadLvInvolvementsWithClient(lead_valuer_id: number, client_id: number, toe_id?: number): Observable<{
        lead_valuer: ToeConflictLeadValuer,
        client: ToeConflictClient,
        involvements: LvInvolvementWithClient[]
    }> {
        const headers = this.authService.getAuthHeaders()
        const apiURL = `${this.apiURL}/lv-involvements-with-client`
        let params = new HttpParams()
            .set('lead_valuer_id', lead_valuer_id)
            .set('client_id', client_id)

        if (toe_id) {
            params = new HttpParams()
                .set('lead_valuer_id', lead_valuer_id)
                .set('client_id', client_id)
                .set('toe_id', toe_id)
        }

        return this.http.get<{data: {
            lead_valuer: ToeConflictLeadValuer,
            client: ToeConflictClient,
            involvements: LvInvolvementWithClient[]
        }}>(apiURL, {headers, params}).pipe(
            map(res => res.data),
            map(data => {
                return {
                    ...data,
                    involvements: data.involvements.map(item => {
                        return {
                            ...item,
                            toe_signing_date: new Date(item.toe_signing_date)
                        }
                    })
                }
            })
        )
    }

    loadAgencyInvolvementsWithClient(agency_id: number, client_id: number, toe_id?: number): Observable<{
        agency: ToeConflictAgency,
        client: ToeConflictClient,
        involvements: AgencyInvolvementWithClient[]
    }> {
        const headers = this.authService.getAuthHeaders()
        const apiURL = `${this.apiURL}/agency-involvements-with-client`
        let params = new HttpParams()
            .set('agency_id', agency_id)
            .set('client_id', client_id)
        if (toe_id) {
            params = new HttpParams()
                .set('agency_id', agency_id)
                .set('client_id', client_id)
                .set('toe_id', toe_id)
        }

        return this.http.get<{data: {
            agency: ToeConflictAgency,
            client: ToeConflictClient,
            involvements: AgencyInvolvementWithClient[]
        }}>(apiURL, {headers, params}).pipe(
            map(res => res.data),
            map(data => {
                return {
                    ...data,
                    involvements: data.involvements.map(item => {
                        return {
                            ...item,
                            toe_signing_date: new Date(item.toe_signing_date)
                        }
                    })
                }
            })
        )
    }

    loadLvInvolvementWithTp(lead_valuer_id: number, query: ToeConflictTpQuery[], toe_id?: number): Observable<{
        lead_valuer: ToeConflictLeadValuer,
        involvements: LvInvolvementWithTp[]
    }> {
        const headers = this.authService.getAuthHeaders()
        const apiUrl = `${this.apiURL}/lv-involvements-with-tps`

        return this.http.post<{data: {
            lead_valuer: ToeConflictLeadValuer,
            involvements: LvInvolvementWithTp[]
        }}>(apiUrl, {
            lead_valuer_id,
            toe_id: toe_id,
            tp_queries: query,
        }, {headers}).pipe(
            map(res => res.data),
            map(data => {
                return {
                    ...data,
                    involvements: data.involvements.map(item => {
                        return {
                            ...item,
                            toe_signing_date: new Date(item.toe_signing_date)
                        }
                    })
                }
            })
        )
    }

    loadAgencyInvolvementWithTp(agency_id: number, query: ToeConflictTpQuery[], toe_id?: number): Observable<{
        agency: ToeConflictAgency,
        involvements: AgencyInvolvementWithTp[]
    }> {
        const headers = this.authService.getAuthHeaders()
        const apiUrl = `${this.apiURL}/agency-involvements-with-tps`

        return this.http.post<{data: {
            agency: ToeConflictAgency,
            involvements: AgencyInvolvementWithTp[]
        }}>(apiUrl, {
            agency_id,
            toe_id: toe_id,
            tp_queries: query,
        }, {headers}).pipe(
            map(res => res.data),
            map(data => {
                return {
                    ...data,
                    involvements: data.involvements.map(item => {
                        return {
                            ...item,
                            toe_signing_date: new Date(item.toe_signing_date)
                        }
                    })
                }
            })
        )
    }
}