<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="col-lg-12" *ngFor="let builtinFitting of builtinFittings">
                <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile">
                    <mat-checkbox
                            [checked]="setSelectedBuiltinFittings(builtinFitting)"
                            (change)="updateCheckedOptions(builtinFitting, $event)">
                        {{ builtinFitting.name }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="card-footer p-0 b-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary" (click)="addBuiltinFitting()">Add Built-in Fitting</button>&nbsp;
                <button mat-button mat-raised-button color="primary" (click)="close()">Save</button>&nbsp;
            </div>
        </div>
    </div>
</div>