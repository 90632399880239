import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { PaymentTaxModel } from '../../../../../core/asset_class/_models/payment-tax.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalCostTitleModel, AllAdditionalCostTitlesRequested, selectAllAdditionalCostTitles } from 'src/app/core/linked-tables';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'kt-add-additional-cost-dialog-alt',
    templateUrl: './add-additional-cost-dialog.component.html',
    styleUrls: ['./add-additional-cost-dialog.component.scss']
})
export class AddAdditionalCostDialogComponent implements OnInit, OnDestroy {

    acPaymentTax: PaymentTaxModel;
    paymentTaxForm: UntypedFormGroup;
    targetProperties: any[] = [];
    
    subscriptions: Subscription[] = [];
    hasFormErrors = false;

    titles: AdditionalCostTitleModel[] = []
    private _onDestroy$: Subject<void> = new Subject();

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<FetchEntityDialogComponent>,
     * @param data: inputData
     * @param fb: FormBuilder
     * @param store: AppState
     */
    constructor(public dialogRef: MatDialogRef<AddAdditionalCostDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this._setupAdditionalCostTitles()
        // if (this.data.targetProperties) {
            this.targetProperties = this.data.targetProperties;
        // }
        if (this.data.acPaymentTax) {
            this.acPaymentTax = this.data.acPaymentTax;
        } else {
            this.acPaymentTax = new PaymentTaxModel();
            this.acPaymentTax.clear();
        }
        this.createForm();
    }

    private _setupAdditionalCostTitles() {
        this.store.dispatch(new AllAdditionalCostTitlesRequested());
        this.store.pipe(
            select(selectAllAdditionalCostTitles), 
            takeUntil(this._onDestroy$)
        ).subscribe(titles => {
            if (titles) {
                this.titles = titles
            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Create form
     */
    createForm() {
        this.paymentTaxForm = this.fb.group({
            terms_of_engagement_id: [this.acPaymentTax.terms_of_engagement_id],
            asset_class: [this.getAssetClass(this.acPaymentTax.asset_class_id)],
            qty: [this.acPaymentTax.qty],
            name: [this.acPaymentTax.name, Validators.required],
            description: [this.acPaymentTax.description],
            title_id: [this.acPaymentTax.title_id, Validators.required],
            amount: [this.acPaymentTax.amount, Validators.required],
        });
        this.paymentTaxForm.controls.title_id.valueChanges.pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(val => {
            if (val != -1) {
                const title = this.titles.find(t => t.id == val)
                if (title) {
                    this.paymentTaxForm.controls.name.setValue(title.name)
                    this.paymentTaxForm.controls.name.updateValueAndValidity()
                }
            }
        })
    }
    
    
    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }
    
    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.acPaymentTax && this.acPaymentTax.id) {
            // tslint:disable-next-line:no-string-throw
            return `Edit Additional Cost`;
        }
    
        return 'New Additional Cost';
    }
    
    isValid() {
        return true;
    }
    
    radioChanged() {
    
    }
    
    onSubmit() {
        this.hasFormErrors = false;
        /** check form */
        if (!this.isValid()) {
            this.hasFormErrors = true;
            return;
        }
    
        const controls = this.paymentTaxForm.controls;
        /** check form */
        if (this.paymentTaxForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
    
            this.hasFormErrors = true;
            return;
        }
        const _acPaymentTax = new PaymentTaxModel();
        _acPaymentTax.clear();
    
        if (this.acPaymentTax.id) {
            _acPaymentTax.id = this.acPaymentTax.id;
        }
    
        _acPaymentTax._isEditMode = this.acPaymentTax._isEditMode;
        _acPaymentTax.asset_class_id = controls.asset_class.value.id;
        _acPaymentTax.asset_class_name = controls.asset_class.value.name;
        _acPaymentTax.title_id = controls.title_id.value;
        _acPaymentTax.name = controls.name.value;
        _acPaymentTax.description = controls.description.value;
        _acPaymentTax.qty = controls.qty.value;
        _acPaymentTax.amount = controls.amount.value;
    
        this.dialogRef.close({
            size: _acPaymentTax,
            isEdit: false
        });
    }
    
    getAssetClass(asset_class_id) {
        return this.targetProperties.find(tp => tp.id === asset_class_id);
    }
}
