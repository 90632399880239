import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../../core/_base/layout';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
import {select, Store} from '@ngrx/store';
import {debounceTime, delay, distinctUntilChanged, map, skip, take, tap} from 'rxjs/operators';
import {BehaviorSubject, fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {TranslateService} from '@ngx-translate/core';
import {
  AgenciesPageRequested,
  AgencyActionToggleLoading,
  AgencyDatasource,
  AgencyDeleted,
  AgencyDeletedFromAdminTrash,
  AgencyDeletedFromTrash,
  AgencyModel,
  AgencyOnServerAdminRestored,
  AgencyOnServerRestored,
  AgencyService,
  AgencyTrashFlushed,
  selectAdminTrashedAgencyCount,
  selectTrashedAgencyCount
} from '../../../../../core/admin';
import { currentUser, hasPermission, User } from 'src/app/core/mad-auth/mad-auth.store'

type pmStatus = {
  total_agencies: string,
  total_assignements: string,
  total_toes: string,
}

@Component({
  selector: 'kt-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss']
})
export class AgencyListComponent implements OnInit, OnDestroy {
  dataSource: AgencyDatasource;
  displayedColumns = ['id', 'name', 'country_name', 'city_name', 'ass_cnt', 'toe_cnt', 'actions'];
  trashCnt$: Observable<number>;
  adminTrashCnt$: Observable<number>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort', {static: true}) sort: MatSort;

  currentUser: User;
  agenciesResult: AgencyModel[];
  // Subscriptions
  private subscriptions: Subscription[] = [];
  agencyStatusCnts$ = new BehaviorSubject<pmStatus>({
    total_agencies: '0',
    total_assignements: '0',
    total_toes: '0',
  });
  filter: any;

  // Permissions
  canEdit$ = this.store.pipe(
    select(hasPermission(['agency:crud'])),
  )
  canAdminThrash$ = this.store.pipe(
    select(hasPermission(['admin_trash']))
  )

  /**
   * Component constructor
   *
   * @param activatedRoute
   * @param router
   * @param dialog: MatDialog
   * @param snackBar: MatSnackBar
   * @param layoutConfigService
   * @param service
   * @param subheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   */
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public layoutConfigService: LayoutUtilsService,
              public service: AgencyService,
              public subheaderService: SubheaderService,
              private layoutUtilsService: LayoutUtilsService,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private store: Store<AppState>) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {

    this.subheaderService.setTitle('Agencies');
    this.paginator._intl.itemsPerPageLabel = this.translate.instant('GENERAL.PAGELABEL');
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadAgenciesList())
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    this.trashCnt$ = this.store.pipe(select(selectTrashedAgencyCount));
    // this.trashCnt$ = of(5).pipe(delay(2000))
    this.adminTrashCnt$ = this.store.pipe(select(selectAdminTrashedAgencyCount));

    // Init DataSource
    this.dataSource = new AgencyDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      if (res.length > 0 || res) {
      this.agenciesResult = res;
        let total_toe = 0;
        res.forEach(element => {
          total_toe += element['assignments'].reduce((total, item) => total + item.toe_cnt, 0)
        });
        this.agencyStatusCnts$.next(
          {
            total_agencies: res.length.toString(),
            total_assignements: (res.reduce((total, item) => total + item.ass_cnt, 0) as string),
            total_toes: total_toe.toString(),
          }
        )
      }
    });

    this.subscriptions.push(entitiesSubscription);

    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadAgenciesList();
    }); // Remove this line, just loading imitation

    this.store.pipe(take(1), select(currentUser)).subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load Agencys List from service through data-source
   */
  loadAgenciesList() {
    const queryParams = new QueryParamsModel(
      this.filter,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(new AgenciesPageRequested({page: queryParams}));
  }


  /** ACTIONS */
  /**
   * Delete agency
   *
   * @param _item: AgencyModel
   */
  deleteAgency(_item: AgencyModel) {
    const _title = this.translate.instant('AGENCY.LIST.DIALOG.DELETE.TITLE');
    const _description = this.translate.instant('AGENCY.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
    const _waitDesciption = this.translate.instant('AGENCY.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

    const dialogRef = this.layoutUtilsService.deleteAgency(_title, _item.name, _waitDesciption, _item['assignments']);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.store.dispatch(new AgencyDeleted({id: _item.id}));
    });
  }

  // Fetch trash data
  trash() {
    this.store.dispatch(new AgencyActionToggleLoading({isLoading: true}));
    this.service.getTrashed().subscribe(res => {

      this.store.dispatch(new AgencyActionToggleLoading({isLoading: false}));
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `${elem.name}`,
          id: elem.id.toString(),
          deletedUser: elem.userDeletedBy,
          hasPermanentlyDelete: true,
          date: elem.user_deleted
        });
      });
      this.show_trash(items);
    });
  }

  // Fetch admin trash data
  adminTrash() {
    this.store.dispatch(new AgencyActionToggleLoading({isLoading: true}));
    this.service.getAdminTrashed().subscribe(res => {

      this.store.dispatch(new AgencyActionToggleLoading({isLoading: false}));
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `${elem.name}`,
          id: elem.id.toString(),
          deletedUser: elem.deletedBy,
          hasPermanentlyDelete: true,
          date: elem.user_deleted
        });
      });
      this.show_trash(items, true);
    });
  }

  /**
   * Show Edit agency dialog and save after success close result
   * @param items
   * @param isAdmin
   */
  show_trash(items: any[], isAdmin = false) {
    let _title = this.translate.instant('AGENCY.LIST.DIALOG.SHOW_TRASH.TITLE');
    if (isAdmin) {
      _title = this.translate.instant('AGENCY.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
    }
    this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
  }

  restore(id: number, isAdmin = false) {
    if (isAdmin) {
      this.store.dispatch(new AgencyOnServerAdminRestored({id}));
    } else {
      this.store.dispatch(new AgencyOnServerRestored({id}));
    }
  }

  delete(id: number) {
    this.store.dispatch(new AgencyDeletedFromTrash({id}));
  }

  hardDelete(id: number) {
    this.store.dispatch(new AgencyDeletedFromAdminTrash({id}));
  }

  flush() {
    this.store.dispatch(new AgencyTrashFlushed());
  }

  /**
   * Show add agency dialog
   */
  addAgency() {
    this.editAgency(null);
  }

  /**
   * Show Edit agency dialog and save after success close result
   * @param _agency: Agency
   */
  editAgency(_agency) {
    if (_agency) {
      this.router.navigate(['../agencies/edit', _agency.id], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['../agencies/add'], {relativeTo: this.activatedRoute});
    }
  }

  draft_cnt(agency) {
    return agency.assignments.reduce((total, item) => total + item.toe_cnt_draft, 0);
  }
  ongoing_cnt(agency) {
    return agency.assignments.reduce((total, item) => total + item.toe_cnt_ongoing, 0);
  }
  complete_cnt(agency) {
    return agency.assignments.reduce((total, item) => total + item.toe_cnt_complete, 0);
  }

  onFilterChange(event) {
    this.paginator.pageIndex = 0;
    this.filter = event;
    this.loadAgenciesList();
  }
}
