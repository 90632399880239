// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {SourceType} from '../_models/source-type.model';

export enum SourceTypeActionTypes {

    AllSourceTypesRequested = '[SourceTypes Home Page] All SourceTypes Requested',
    AllSourceTypesLoaded = '[SourceTypes API] All SourceTypes Loaded',

    SourceTypeOnServerCreated = '[Edit SourceType Dialog] SourceType On Server Created',
    SourceTypeCreated = '[Edit SourceTypes Dialog] SourceTypes Created',
    SourceTypeUpdated = '[Edit SourceType Dialog] SourceType Updated',
    SourceTypeDeleted = '[SourceTypes List Page] SourceType Deleted',

    SourceTypesPageRequested = '[SourceTypes List Page] SourceTypes Page Requested',
    SourceTypesPageLoaded = '[SourceTypes API] SourceTypes Page Loaded',
    SourceTypesPageCancelled = '[SourceTypes API] SourceTypes Page Cancelled',

    SourceTypesPageToggleLoading = '[SourceTypes page] SourceTypes Page Toggle Loading',
    SourceTypesActionToggleLoading = '[SourceTypes] SourceTypes Action Toggle Loading',

    // trash system

    SourceTypeOnServerRestored = '[SourceType Trash] SourceType On Server Restored',
    SourceTypeRestored = '[SourceType Trash] SourceType Restored',

    SourceTypeOnServerAdminRestored = '[SourceType Admin Trash] SourceType On Server Restored',
    SourceTypeAdminRestored = '[SourceType Admin Trash] SourceType Restored',

    SourceTypeDeletedFromTrash = '[SourceType Trash] SourceType deleted',
    SourceTypeDeletedFromAdminTrash = '[SourceType Admin Trash] SourceType deleted',

    SourceTypeTrash = 'SourceType Trashed',
    SourceTypeTrashFlushed = 'SourceType Trash Flushed',
}

export class SourceTypeOnServerCreated implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeOnServerCreated;

    constructor(public payload: { sourceType: SourceType}) {
    }
}

export class SourceTypeCreated implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeCreated;

    constructor(public payload: { sourceType: SourceType }) {
    }
}

export class SourceTypeUpdated implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeUpdated;

    constructor(public payload: {
        partialSourceType: Update<SourceType>,
        sourceType: SourceType
    }) {
    }
}

export class SourceTypeDeleted implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SourceTypesPageRequested implements Action {
    readonly type = SourceTypeActionTypes.SourceTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SourceTypesPageLoaded implements Action {
    readonly type = SourceTypeActionTypes.SourceTypesPageLoaded;

    constructor(public payload: { sourceTypes: SourceType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SourceTypesPageCancelled implements Action {
    readonly type = SourceTypeActionTypes.SourceTypesPageCancelled;
}

export class AllSourceTypesRequested implements Action {
    readonly type = SourceTypeActionTypes.AllSourceTypesRequested;
}

export class AllSourceTypesLoaded implements Action {
    readonly type = SourceTypeActionTypes.AllSourceTypesLoaded;

    constructor(public payload: { sourceTypes: SourceType[] }) {
    }
}

export class SourceTypesPageToggleLoading implements Action {
    readonly type = SourceTypeActionTypes.SourceTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SourceTypesActionToggleLoading implements Action {
    readonly type = SourceTypeActionTypes.SourceTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class SourceTypeDeletedFromAdminTrash implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class SourceTypeDeletedFromTrash implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SourceTypeOnServerRestored implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceTypeRestored implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeRestored;

    constructor(public payload: { item: SourceType }) {
    }
}

export class SourceTypeOnServerAdminRestored implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceTypeAdminRestored implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeAdminRestored;

    constructor(public payload: { item: SourceType }) {
    }
}

export class SourceTypeTrashFlushed implements Action {
    readonly type = SourceTypeActionTypes.SourceTypeTrashFlushed;

    constructor() {
    }
}

export type SourceTypeActions = SourceTypeCreated
    | SourceTypeUpdated
    | SourceTypeDeleted
    | SourceTypesPageRequested
    | SourceTypesPageLoaded
    | SourceTypesPageCancelled
    | AllSourceTypesLoaded
    | AllSourceTypesRequested
    | SourceTypeOnServerCreated
    | SourceTypesPageToggleLoading
    | SourceTypesActionToggleLoading
    | SourceTypeDeletedFromAdminTrash
    | SourceTypeDeletedFromTrash
    | SourceTypeOnServerRestored
    | SourceTypeRestored
    | SourceTypeOnServerAdminRestored
    | SourceTypeAdminRestored
    | SourceTypeTrashFlushed;
