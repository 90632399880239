// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {CoordinateReferenceSystemsService} from '../_services/coordinate-reference-system.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allCoordinateReferenceSystemsLoaded} from '../_selectors/coordinate-reference-system.selectors';
// Actions
import {
    AllCoordinateReferenceSystemsLoaded,
    AllCoordinateReferenceSystemsRequested,
    CoordinateReferenceSystemActionTypes,
    CoordinateReferenceSystemsPageRequested,
    CoordinateReferenceSystemsPageLoaded,
    CoordinateReferenceSystemUpdated,
    CoordinateReferenceSystemsPageToggleLoading,
    CoordinateReferenceSystemDeleted,
    CoordinateReferenceSystemOnServerCreated,
    CoordinateReferenceSystemCreated,
    CoordinateReferenceSystemAdminRestored,
    CoordinateReferenceSystemDeletedFromAdminTrash,
    CoordinateReferenceSystemDeletedFromTrash,
    CoordinateReferenceSystemOnServerAdminRestored,
    CoordinateReferenceSystemOnServerRestored,
    CoordinateReferenceSystemRestored,
    CoordinateReferenceSystemTrashFlushed,
    CoordinateReferenceSystemsActionToggleLoading
} from '../_actions/coordinate-reference-system.actions';

@Injectable()
export class CoordinateReferenceSystemEffects {
    showPageLoadingDispatcher = new CoordinateReferenceSystemsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new CoordinateReferenceSystemsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CoordinateReferenceSystemsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllCoordinateReferenceSystems$ = this.actions$
        .pipe(
            ofType<AllCoordinateReferenceSystemsRequested>(CoordinateReferenceSystemActionTypes.AllCoordinateReferenceSystemsRequested),
            withLatestFrom(this.store.pipe(select(allCoordinateReferenceSystemsLoaded))),
            filter(([action, isAllCoordinateReferenceSystemsLoaded]) => !isAllCoordinateReferenceSystemsLoaded),
            mergeMap(() => this.service.getAllCoordinateReferenceSystems()),
            map(res => {
                return new AllCoordinateReferenceSystemsLoaded({coordinateReferenceSystems: res.data});
            })
        );

    @Effect()
    loadCoordinateReferenceSystemsPage$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemsPageRequested>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findCoordinateReferenceSystems(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new CoordinateReferenceSystemsPageLoaded({
                    coordinateReferenceSystems: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemDeleted>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteCoordinateReferenceSystem(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemUpdated>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateCoordinateReferenceSystem(payload.coordinateReferenceSystem);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemOnServerCreated>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createCoordinateReferenceSystem(payload.coordinateReferenceSystem).pipe(
                    tap(res => {
                        this.store.dispatch(new CoordinateReferenceSystemCreated({coordinateReferenceSystem: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushCoordinateReferenceSystemTrash$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemTrashFlushed>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemOnServerRestored>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CoordinateReferenceSystemRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemOnServerAdminRestored>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CoordinateReferenceSystemAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemDeletedFromTrash>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashCoordinateReferenceSystem$ = this.actions$
        .pipe(
            ofType<CoordinateReferenceSystemDeletedFromAdminTrash>(CoordinateReferenceSystemActionTypes.CoordinateReferenceSystemDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: CoordinateReferenceSystemsService, private store: Store<AppState>) {
    }
}
