// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {ToeReportActions, ToeReportActionTypes} from '../_actions/toe-report.actions';
// Models
import {ToeReport} from '../_models/toe-report.model';
import {QueryParamsModel} from '../../_base/crud';

export interface ToeReportsState extends EntityState<ToeReport> {
    isAllToeReportsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ToeReport[];
    lastCreatedToeReportId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    isGeneratingReport: boolean;
    reportGenerateSuccess: boolean;
    reportGenerateError: boolean;
    currentGeneratingReportID: number;
}

export const adapter: EntityAdapter<ToeReport> = createEntityAdapter<ToeReport>();

export const initialToeReportsState: ToeReportsState = adapter.getInitialState({
    isAllToeReportsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedToeReportId: undefined,
    listLoading: false,
    actionLoading: false,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    isGeneratingReport: false,
    reportGenerateSuccess: true,
    reportGenerateError: false,
    currentGeneratingReportID: undefined,
});

export function toeReportsReducer(state = initialToeReportsState, action: ToeReportActions): ToeReportsState {
    switch (action.type) {
        case ToeReportActionTypes.ToeReportsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedToeReportId: undefined
            };
        case ToeReportActionTypes.ToeReportsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ToeReportActionTypes.ToeReportOnServerCreated:
            return {
                ...state, lastCreatedToeReportId: undefined
            };
        case ToeReportActionTypes.ToeReportCreated:
            return adapter.addOne(action.payload.toeReport, {
                ...state, lastCreatedToeReportId: action.payload.toeReport.id
            });
        case ToeReportActionTypes.ToeReportUpdated:
            return adapter.updateOne(action.payload.partialToeReport, state);
        case ToeReportActionTypes.ToeReportDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state
            });
        case ToeReportActionTypes.AllToeReportsLoaded:
            return adapter.addAll(action.payload.toeReports, {
                ...state, isAllToeReportsLoaded: true
            });
        case ToeReportActionTypes.ToeReportsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ToeReportActionTypes.ToeReportsPageLoaded:
            return adapter.addMany(action.payload.toeReports, {
                ...initialToeReportsState,
                listLoading: false,
                queryRowsCount: action.payload.totalCount,
                queryResult: action.payload.toeReports,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case ToeReportActionTypes.GenerateReport:
            return {
                ...state,
                isGeneratingReport: true,
                currentGeneratingReportID: action.payload.reportID
            }
        case ToeReportActionTypes.ReportGenerated: {
            if (action.payload.success) {
                return {
                    ...state,
                    isGeneratingReport: false,
                    reportGenerateSuccess: true,
                    reportGenerateError: false
                }
            } else {
                return {
                    ...state,
                    isGeneratingReport: false,
                    reportGenerateError: true,
                    reportGenerateSuccess: false
                }
            }
        }
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
