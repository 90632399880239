<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form form-group-seperator-dashed">
        <form [formGroup]="form">
            <div class="card-body pt-0">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="{{assetClassTypeName}} measurement standard"
                                formControlName="standard_measurement_id">
                                <mat-option *ngFor="let item of standardMeasurements" 
                                    [value]="item.id"
                                    [disabled]="selectedMeasurements.indexOf(item.id) > -1">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Standard measurement <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Unit type"
                                formControlName="unit_of_area_measurement_id">
                                <mat-option *ngFor="let item of unitAreaMeasurements" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Unit type <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Size" formControlName="size" mask="separator," >
                            <mat-error>
                                Size <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
                <button type="button" [disabled]="!isValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()"
                        matTooltip="Add size">
                    {{size.id ? 'Save': 'Add'}}
                </button>
            </div>
        </div>
    </div>
</div>