// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {InteractionType} from '../_models/interaction-type.model';

export enum InteractionTypeActionTypes {

    AllInteractionTypesRequested = '[InteractionTypes Home Page] All InteractionTypes Requested',
    AllInteractionTypesLoaded = '[InteractionTypes API] All InteractionTypes Loaded',

    InteractionTypeOnServerCreated = '[Edit InteractionType Dialog] InteractionType On Server Created',
    InteractionTypeCreated = '[Edit InteractionTypes Dialog] InteractionTypes Created',
    InteractionTypeUpdated = '[Edit InteractionType Dialog] InteractionType Updated',
    InteractionTypeDeleted = '[InteractionTypes List Page] InteractionType Deleted',

    InteractionTypesPageRequested = '[InteractionTypes List Page] InteractionTypes Page Requested',
    InteractionTypesPageLoaded = '[InteractionTypes API] InteractionTypes Page Loaded',
    InteractionTypesPageCancelled = '[InteractionTypes API] InteractionTypes Page Cancelled',

    InteractionTypesPageToggleLoading = '[InteractionTypes page] InteractionTypes Page Toggle Loading',
    InteractionTypesActionToggleLoading = '[InteractionTypes] InteractionTypes Action Toggle Loading',

    // trash system

    InteractionTypeOnServerRestored = '[InteractionType Trash] InteractionType On Server Restored',
    InteractionTypeRestored = '[InteractionType Trash] InteractionType Restored',

    InteractionTypeOnServerAdminRestored = '[InteractionType Admin Trash] InteractionType On Server Restored',
    InteractionTypeAdminRestored = '[InteractionType Admin Trash] InteractionType Restored',

    InteractionTypeDeletedFromTrash = '[InteractionType Trash] InteractionType deleted',
    InteractionTypeDeletedFromAdminTrash = '[InteractionType Admin Trash] InteractionType deleted',

    InteractionTypeTrash = 'InteractionType Trashed',
    InteractionTypeTrashFlushed = 'InteractionType Trash Flushed',
}

export class InteractionTypeOnServerCreated implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeOnServerCreated;

    constructor(public payload: { interactionType: InteractionType}) {
    }
}

export class InteractionTypeCreated implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeCreated;

    constructor(public payload: { interactionType: InteractionType }) {
    }
}

export class InteractionTypeUpdated implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeUpdated;

    constructor(public payload: {
        partialinteractionType: Update<InteractionType>,
        interactionType: InteractionType
    }) {
    }
}

export class InteractionTypeDeleted implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class InteractionTypesPageRequested implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class InteractionTypesPageLoaded implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypesPageLoaded;

    constructor(public payload: { interactionTypes: InteractionType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class InteractionTypesPageCancelled implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypesPageCancelled;
}

export class AllInteractionTypesRequested implements Action {
    readonly type = InteractionTypeActionTypes.AllInteractionTypesRequested;
}

export class AllInteractionTypesLoaded implements Action {
    readonly type = InteractionTypeActionTypes.AllInteractionTypesLoaded;

    constructor(public payload: { interactionTypes: InteractionType[] }) {
    }
}

export class InteractionTypesPageToggleLoading implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class InteractionTypesActionToggleLoading implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class InteractionTypeDeletedFromAdminTrash implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class InteractionTypeDeletedFromTrash implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class InteractionTypeOnServerRestored implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class InteractionTypeRestored implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeRestored;

    constructor(public payload: { item: InteractionType }) {
    }
}

export class InteractionTypeOnServerAdminRestored implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class InteractionTypeAdminRestored implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeAdminRestored;

    constructor(public payload: { item: InteractionType }) {
    }
}

export class InteractionTypeTrashFlushed implements Action {
    readonly type = InteractionTypeActionTypes.InteractionTypeTrashFlushed;

    constructor() {
    }
}

export type InteractionTypeActions = InteractionTypeCreated
    | InteractionTypeUpdated
    | InteractionTypeDeleted
    | InteractionTypesPageRequested
    | InteractionTypesPageLoaded
    | InteractionTypesPageCancelled
    | AllInteractionTypesLoaded
    | AllInteractionTypesRequested
    | InteractionTypeOnServerCreated
    | InteractionTypesPageToggleLoading
    | InteractionTypesActionToggleLoading
    | InteractionTypeDeletedFromAdminTrash
    | InteractionTypeDeletedFromTrash
    | InteractionTypeOnServerRestored
    | InteractionTypeRestored
    | InteractionTypeOnServerAdminRestored
    | InteractionTypeAdminRestored
    | InteractionTypeTrashFlushed;
