import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, QueryParamsDividedModel, TypesUtilsService } from '../../../../core/_base/crud';
import { Store, select } from '@ngrx/store';
import { debounceTime, delay, distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';

import { 
    BuiltinFittingsService,
    BuiltinFitting,
    BuiltinFittingsDataSource,
    BuiltinFittingsPageRequested,
    BuiltinFittingDeleted,
    selectTrashedBuiltinFittingCount,
    selectAdminTrashedBuiltinFittingCount,
    BuiltinFittingsActionToggleLoading,
    BuiltinFittingOnServerAdminRestored,
    BuiltinFittingOnServerRestored,
    BuiltinFittingDeletedFromTrash,
    BuiltinFittingDeletedFromAdminTrash,
    BuiltinFittingTrashFlushed,
    BuiltinFittingsClear,
    AssetClassType,
    AllAssetClassTypesRequested,
    selectAllAssetClassTypes,
    BuiltinFittingUpdated,
} from '../../../../core/linked-tables';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';


@Component({
    selector: 'kt-builtin-fittings-list',
    templateUrl: './builtin-fittings-list.component.html',
    styleUrls: ['./builtin-fittings-list.component.scss']
})
export class BuiltinFittingsListComponent implements OnInit, OnDestroy {

    dataSource: BuiltinFittingsDataSource;
    displayedColumns = ['id', 'name', 'is_hidden', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<BuiltinFitting>(true, []);
    builtinFittingResult: BuiltinFitting[] = [];
    title = '';
    propertySubTypeId = 0;

    trashCnt: number = 0;
    adminTrashCnt: number = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];
    dropdownCtrl = new UntypedFormControl(7);
    public propertySubTypes: AssetClassType[] = [];
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

    /**
     * Component constructor
     *
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public service: BuiltinFittingsService,
        public subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        public typesUtilsService: TypesUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ids = [1, 3, 5, 7, 11, 17]
    ngOnInit() {
        this.store.dispatch(new AllAssetClassTypesRequested())
        this.store.select(selectAllAssetClassTypes).subscribe(res => {
          this.propertySubTypes = [];
          if (res) {
            this.propertySubTypes = res.filter(item => this.ids.includes(item.id));
          }
        });
        const dropSub = this.dropdownCtrl.valueChanges.subscribe(val => {
            this.loadList();
        });
        this.subscriptions.push(dropSub);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new BuiltinFittingsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.builtinFittingResult = res;
        });

        this.subscriptions.push(entitiesSubscription);

        // trash count
        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedBuiltinFittingCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedBuiltinFittingCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadList();
        }); // Remove this line, just loading imitation
    }

    /**
     * Load Purpose Of Valuation List from service through data-source
     */
    loadList() {
        this.selection.clear();
        const queryParams = new QueryParamsDividedModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.propertySubTypeId
        );
        this.store.dispatch(new BuiltinFittingsPageRequested({ page: queryParams }));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {
            with_hidden: true
        };
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        filter.status = this.dropdownCtrl.value;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadList();
    }

    /**
     * Show add purpose of valuation view
     */
    addBuiltinFitting() {
        this.editBuiltinFitting(null);
    }

    /**
     * Show Edit builtinFitting dialog and save after success close result
     * @param builtinFitting: BuiltinFitting
     */
    editBuiltinFitting(_builtinFitting) {
        if (_builtinFitting)
            this.router.navigate(['edit', _builtinFitting.id], {relativeTo: this.activatedRoute});
        else
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    deleteBuiltinFitting(_item: BuiltinFitting) {
        const _title = this.translate.instant('BUILTIN_FITTING.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('BUILTIN_FITTING.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
        const _waitDesciption =  this.translate.instant('BUILTIN_FITTING.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new BuiltinFittingDeleted({id: _item.id}));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // Fetch trash data
    trash() {
        this.store.dispatch(new BuiltinFittingsActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new BuiltinFittingsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new BuiltinFittingsActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new BuiltinFittingsActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('BUILTIN_FITTING.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('BUILTIN_FITTING.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new BuiltinFittingOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new BuiltinFittingOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new BuiltinFittingDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new BuiltinFittingDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new BuiltinFittingTrashFlushed());
    }

    viewEntry(item: BuiltinFitting) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }

    onHiddenChange(event: MatCheckboxChange, item: BuiltinFitting) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new BuiltinFittingUpdated({
            builtinFitting: _item,
            partialBuiltinFitting: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
