// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectApproachesToValueQueryResult, selectApproachesToValuesPageLoading, selectApproachesToValuesShowInitWaitingMessage} from '../_selectors/approaches-to-value.selectors';

export class ApproachesToValuesDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectApproachesToValuesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectApproachesToValuesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectApproachesToValueQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
