// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {GradeActions, GradeActionTypes} from '../_actions/grade.actions';
import {Grade} from '../_models/grade.model';

export interface GradesState extends EntityState<Grade> {
    isAllGradesLoaded: boolean;
    queryRowsCount: number;
    queryResult: Grade[];
    lastCreatedGradeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Grade> = createEntityAdapter<Grade>();

export const initialGradesState: GradesState = adapter.getInitialState({
    isAllGradesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedGradeId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function gradesReducer(state = initialGradesState, action: GradeActions): GradesState {
    switch (action.type) {
        case GradeActionTypes.GradesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGradeId: undefined
            };
        case GradeActionTypes.GradesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case GradeActionTypes.GradeOnServerCreated:
            return {
                ...state
            };
        case GradeActionTypes.GradeCreated:
            return adapter.addOne(action.payload.grade, {
                ...state, lastCreatedGradeId: action.payload.grade.id
            });
        case GradeActionTypes.GradeUpdated:
            return adapter.updateOne(action.payload.partialGrade, state);
        case GradeActionTypes.AllGradesLoaded:
            return adapter.addAll(action.payload.grades, {
                ...state, isAllGradesLoaded: true
            });
        case GradeActionTypes.GradesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case GradeActionTypes.GradesPageLoaded:
            return adapter.addMany(action.payload.grades, {
                ...initialGradesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case GradeActionTypes.GradeDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case GradeActionTypes.GradeRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case GradeActionTypes.GradeDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case GradeActionTypes.GradeAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case GradeActionTypes.GradeDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case GradeActionTypes.GradeTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
