import {
    ValuationProcessActions, ValuationProcessActionTypes
} from '../_actions/valuation-process.actions'
import {
    ValuationProcessDataModel
} from '../_models'
import * as _ from 'lodash'
import { ValidatedValuationModel } from '../_models/validated-valuation.model'

export type ValuationProcessType = 'default' | 'liquidation'
export interface ValuationProcessState {
    valuationType: ValuationProcessType | null,
    data: ValuationProcessDataModel | null,
    selectedConsiderations: Array<{
        refNum: string,
        id: number
    }>,
    comparables: string[],
    loading: boolean
} 
export const initialValuationProcessState: ValuationProcessState = {
    valuationType: null,
    data: null,
    loading: false,
    comparables: [],
    selectedConsiderations: [],
}

export function valuationProcessReducer(
    state = initialValuationProcessState,
    action: ValuationProcessActions
): ValuationProcessState {
    switch (action.type) {
        case ValuationProcessActionTypes.RequestValuationProcessData: {
            return {
                ...state,
                loading: true
            }
        }
        case ValuationProcessActionTypes.ValuationProcessDataLoaded: {
            const refNums = action.payload.comparables.map(com => com.ref_num)
            const considerations = action.payload.comparables.filter(com => com.selected_tenure_id != null).map(com => ({refNum: com.ref_num, id: com.selected_tenure_id}))
            return {
                ...state,
                valuationType: getValuationProcessType(action.payload.data),
                data: action.payload.data,
                comparables: refNums,
                selectedConsiderations: considerations,
                loading: false,
            }
        }
        case ValuationProcessActionTypes.UpdateValuationProcessSelectedConsideration: {
            const selectedConsiderations = _.cloneDeep(state.selectedConsiderations)
            const idx = selectedConsiderations.findIndex(item => item.refNum == action.payload.refNum)
            if (idx != -1) {
                selectedConsiderations[idx] = action.payload
            } else {
                selectedConsiderations.push(action.payload)
            }
            return {
                ...state,
                selectedConsiderations
            }
        }
        case ValuationProcessActionTypes.AddComparableToValuation: {
            return {
                ...state,
                comparables: [action.payload.refNum, ...state.comparables] ,
            }
        }
        case ValuationProcessActionTypes.ImportMultipleComparablesToValuation: {
            return {
                ...state,
                comparables: action.payload.comparables.map(item => item.refNum),
                selectedConsiderations: action.payload.comparables.map(item => ({refNum: item.refNum, id: item.tenureId}))
            }
        }
        case ValuationProcessActionTypes.RemoveComparableFromValuation: {
            const refNums = _.cloneDeep(state.comparables)
            const idx = refNums.findIndex(refNum => refNum == action.payload.refNum)
            if (idx == -1) {
                return state
            }
            refNums.splice(idx, 1)
            return {
                ...state,
                comparables: refNums,
            }
        }
        case ValuationProcessActionTypes.UnimportValuation: {
            return {
                ...state,
                comparables: []
            }
        } 
        case ValuationProcessActionTypes.ResetState: {
            return initialValuationProcessState
        }
        default: 
            return state
    }
}

function getValuationProcessType(data: ValuationProcessDataModel): ValuationProcessType {
    if (data.valuation.baseOfValueId == 7) {
        return 'liquidation'
    }
    return 'default'
}