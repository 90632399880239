// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {BuildingUseStatusActions, BuildingUseStatusActionTypes} from '../_actions/building-use-status.actions';
// Models
import {BuildingUseStatus} from '../_models/building-use-status.model';
import {QueryParamsModel} from '../../_base/crud';

export interface BuildingUseStatussState extends EntityState<BuildingUseStatus> {
    isAllBuildingUseStatussLoaded: boolean;
    queryRowsCount: number;
    queryResult: BuildingUseStatus[];
    lastCreatedBuildingUseStatusId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BuildingUseStatus> = createEntityAdapter<BuildingUseStatus>();

export const initialBuildingUseStatussState: BuildingUseStatussState = adapter.getInitialState({
    isAllBuildingUseStatussLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedBuildingUseStatusId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function buildingUseStatussReducer(state = initialBuildingUseStatussState, action: BuildingUseStatusActions): BuildingUseStatussState {
    switch (action.type) {
        case BuildingUseStatusActionTypes.BuildingUseStatussPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBuildingUseStatusId: undefined
            };
        case BuildingUseStatusActionTypes.BuildingUseStatussActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case BuildingUseStatusActionTypes.BuildingUseStatusOnServerCreated:
            return {
                ...state
            };
        case BuildingUseStatusActionTypes.BuildingUseStatusCreated:
            return adapter.addOne(action.payload.buildingUseStatus, {
                ...state, lastCreatedBuildingUseStatusId: action.payload.buildingUseStatus.id
            });
        case BuildingUseStatusActionTypes.BuildingUseStatusUpdated:
            return adapter.updateOne(action.payload.partialBuildingUseStatus, state);
        case BuildingUseStatusActionTypes.AllBuildingUseStatussLoaded:
            return adapter.addAll(action.payload.buildingUseStatuss, {
                ...state, isAllBuildingUseStatussLoaded: true
            });
        case BuildingUseStatusActionTypes.BuildingUseStatussPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case BuildingUseStatusActionTypes.BuildingUseStatussPageLoaded:
            return adapter.addMany(action.payload.buildingUseStatuss, {
                ...initialBuildingUseStatussState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case BuildingUseStatusActionTypes.BuildingUseStatusDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case BuildingUseStatusActionTypes.BuildingUseStatusRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case BuildingUseStatusActionTypes.BuildingUseStatusDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case BuildingUseStatusActionTypes.BuildingUseStatusAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case BuildingUseStatusActionTypes.BuildingUseStatusDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case BuildingUseStatusActionTypes.BuildingUseStatusTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
