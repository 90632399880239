import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ConservatoryPorcheModel } from "../_models/conservatory-porche.model";

export enum ConservatoryPorcheActionTypes {
    AllConservatoryPorchesRequested = '[Linked Area] All ConservatoryPorche Requested',
    AllConservatoryPorchesLoaded = '[ConservatoryPorche Effect] All ConservatoryPorche Loaded',

    ConservatoryPorcheOnServerCreated = '[Add ConservatoryPorche Page] ConservatoryPorche On Server Created',
    ConservatoryPorcheCreated = '[ConservatoryPorche Effect] ConservatoryPorche Created',
    ConservatoryPorcheUpdated = '[ConservatoryPorche Effect] ConservatoryPorche Updated',
    ConservatoryPorcheDeleted = '[ConservatoryPorche List Page] ConservatoryPorche Deleted',

    ConservatoryPorcheOnServerRestored = '[ConservatoryPorche Trash] ConservatoryPorche On Server Restored',
    ConservatoryPorcheRestored = '[ConservatoryPorche Trash] ConservatoryPorche Restored',

    ConservatoryPorcheOnServerAdminRestored = '[ConservatoryPorche Admin Trash] ConservatoryPorche On Server Restored',
    ConservatoryPorcheAdminRestored = '[ConservatoryPorche Admin Trash] ConservatoryPorche Restored',

    ConservatoryPorcheDeletedFromTrash = '[ConservatoryPorche Trash] ConservatoryPorche deleted',
    ConservatoryPorcheDeletedFromAdminTrash = '[ConservatoryPorche Admin Trash] ConservatoryPorche deleted',

    ConservatoryPorcheTrash = 'ConservatoryPorche Trashed',
    ConservatoryPorcheTrashFlushed = 'ConservatoryPorche Trash Flushed',

    ConservatoryPorchesPageRequested = '[ConservatoryPorche List Page] ConservatoryPorche Page Requested',
    ConservatoryPorchesPageLoaded = '[ConservatoryPorche API] ConservatoryPorche Page Loaded',
    ConservatoryPorchesPageCancelled = '[ConservatoryPorche API] ConservatoryPorche Page Cancelled',

    ConservatoryPorchesPageToggleLoading = '[ConservatoryPorche page] ConservatoryPorche Page Toggle Loading',
    ConservatoryPorcheActionToggleLoading = '[ConservatoryPorche] ConservatoryPorche Action Toggle Loading'
}

export class ConservatoryPorcheOnServerCreated implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerCreated;

    constructor(public payload: { item: ConservatoryPorcheModel }) {
    }
}

export class ConservatoryPorcheCreated implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheCreated;

    constructor(public payload: { item: ConservatoryPorcheModel }) {
    }
}

export class ConservatoryPorcheUpdated implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheUpdated;

    constructor(public payload: {
        partialItem: Update<ConservatoryPorcheModel>,
        item: ConservatoryPorcheModel
    }) {
    }
}

export class ConservatoryPorcheDeleted implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ConservatoryPorchesPageRequested implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorchesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ConservatoryPorchesPageLoaded implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorchesPageLoaded;

    constructor(public payload: { items: ConservatoryPorcheModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ConservatoryPorchesPageCancelled implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorchesPageCancelled;
}

export class AllConservatoryPorchesRequested implements Action {
    readonly type = ConservatoryPorcheActionTypes.AllConservatoryPorchesRequested;
}

export class AllConservatoryPorchesLoaded implements Action {
    readonly type = ConservatoryPorcheActionTypes.AllConservatoryPorchesLoaded;

    constructor(public payload: { items: ConservatoryPorcheModel[] }) {
    }
}

export class ConservatoryPorchesPageToggleLoading implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorchesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ConservatoryPorcheActionToggleLoading implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ConservatoryPorcheDeletedFromAdminTrash implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ConservatoryPorcheDeletedFromTrash implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ConservatoryPorcheOnServerRestored implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ConservatoryPorcheRestored implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheRestored;

    constructor(public payload: { item: ConservatoryPorcheModel }) {
    }
}

export class ConservatoryPorcheOnServerAdminRestored implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ConservatoryPorcheAdminRestored implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheAdminRestored;

    constructor(public payload: { item: ConservatoryPorcheModel }) {
    }
}

export class ConservatoryPorcheTrashFlushed implements Action {
    readonly type = ConservatoryPorcheActionTypes.ConservatoryPorcheTrashFlushed;

    constructor() {
    }
}


export type ConservatoryPorcheActions = ConservatoryPorcheCreated
    | ConservatoryPorcheUpdated
    | ConservatoryPorcheDeleted
    | ConservatoryPorchesPageRequested
    | ConservatoryPorchesPageLoaded
    | ConservatoryPorchesPageCancelled
    | AllConservatoryPorchesLoaded
    | AllConservatoryPorchesRequested
    | ConservatoryPorcheOnServerCreated
    | ConservatoryPorcheDeletedFromAdminTrash
    | ConservatoryPorcheDeletedFromTrash
    | ConservatoryPorcheOnServerRestored
    | ConservatoryPorcheRestored
    | ConservatoryPorcheOnServerAdminRestored
    | ConservatoryPorcheAdminRestored
    | ConservatoryPorcheTrashFlushed
    | ConservatoryPorchesPageToggleLoading
    | ConservatoryPorcheActionToggleLoading;