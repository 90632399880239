// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {PremiseOfValue} from '../_models/premise-of-value.model';

export enum PremiseOfValueActionTypes {

    AllPremiseOfValuesRequested = '[PremiseOfValues Home Page] All PremiseOfValues Requested',
    AllPremiseOfValuesLoaded = '[PremiseOfValues API] All PremiseOfValues Loaded',

    PremiseOfValueOnServerCreated = '[Edit PremiseOfValue Dialog] PremiseOfValue On Server Created',
    PremiseOfValueCreated = '[Edit PremiseOfValues Dialog] PremiseOfValues Created',
    PremiseOfValueUpdated = '[Edit PremiseOfValue Dialog] PremiseOfValue Updated',
    PremiseOfValueDeleted = '[PremiseOfValues List Page] PremiseOfValue Deleted',

    // trash system

    PremiseOfValueOnServerRestored = '[PremiseOfValue Trash] PremiseOfValue On Server Restored',
    PremiseOfValueRestored = '[PremiseOfValue Trash] PremiseOfValue Restored',

    PremiseOfValueOnServerAdminRestored = '[PremiseOfValue Admin Trash] PremiseOfValue On Server Restored',
    PremiseOfValueAdminRestored = '[PremiseOfValue Admin Trash] PremiseOfValue Restored',

    PremiseOfValueDeletedFromTrash = '[PremiseOfValue Trash] PremiseOfValue deleted',
    PremiseOfValueDeletedFromAdminTrash = '[PremiseOfValue Admin Trash] PremiseOfValue deleted',

    PremiseOfValueTrash = 'PremiseOfValue Trashed',
    PremiseOfValueTrashFlushed = 'PremiseOfValue Trash Flushed',

    PremiseOfValuesPageRequested = '[PremiseOfValues List Page] PremiseOfValues Page Requested',
    PremiseOfValuesPageLoaded = '[PremiseOfValues API] PremiseOfValues Page Loaded',
    PremiseOfValuesPageCancelled = '[PremiseOfValues API] PremiseOfValues Page Cancelled',

    PremiseOfValuesPageToggleLoading = '[PremiseOfValues page] PremiseOfValues Page Toggle Loading',
    PremiseOfValuesActionToggleLoading = '[PremiseOfValues] PremiseOfValues Action Toggle Loading'
}

export class PremiseOfValueOnServerCreated implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueOnServerCreated;

    constructor(public payload: { premiseOfValue: PremiseOfValue }) {
    }
}

export class PremiseOfValueCreated implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueCreated;

    constructor(public payload: { premiseOfValue: PremiseOfValue }) {
    }
}

export class PremiseOfValueUpdated implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueUpdated;

    constructor(public payload: {
        partialItem: Update<PremiseOfValue>,
        item: PremiseOfValue
    }) {
    }
}

export class PremiseOfValueDeleted implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class PremiseOfValuesPageRequested implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValuesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class PremiseOfValuesPageLoaded implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValuesPageLoaded;

    constructor(public payload: {
        premiseOfValues: PremiseOfValue[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class PremiseOfValuesPageCancelled implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValuesPageCancelled;
}

export class AllPremiseOfValuesRequested implements Action {
    readonly type = PremiseOfValueActionTypes.AllPremiseOfValuesRequested;
}

export class AllPremiseOfValuesLoaded implements Action {
    readonly type = PremiseOfValueActionTypes.AllPremiseOfValuesLoaded;

    constructor(public payload: { premiseOfValues: PremiseOfValue[] }) {
    }
}

export class PremiseOfValuesPageToggleLoading implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValuesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PremiseOfValuesActionToggleLoading implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValuesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class PremiseOfValueDeletedFromAdminTrash implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class PremiseOfValueDeletedFromTrash implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PremiseOfValueOnServerRestored implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PremiseOfValueRestored implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueRestored;

    constructor(public payload: { item: PremiseOfValue }) {
    }
}

export class PremiseOfValueOnServerAdminRestored implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PremiseOfValueAdminRestored implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueAdminRestored;

    constructor(public payload: { item: PremiseOfValue }) {
    }
}

export class PremiseOfValueTrashFlushed implements Action {
    readonly type = PremiseOfValueActionTypes.PremiseOfValueTrashFlushed;

    constructor() {
    }
}

export type PremiseOfValueActions = PremiseOfValueCreated
    | PremiseOfValueUpdated
    | PremiseOfValueDeleted
    | PremiseOfValuesPageRequested
    | PremiseOfValuesPageLoaded
    | PremiseOfValuesPageCancelled
    | AllPremiseOfValuesLoaded
    | AllPremiseOfValuesRequested
    | PremiseOfValueOnServerCreated
    | PremiseOfValuesPageToggleLoading
    | PremiseOfValueDeletedFromAdminTrash
    | PremiseOfValueDeletedFromTrash
    | PremiseOfValueOnServerRestored
    | PremiseOfValueRestored
    | PremiseOfValueOnServerAdminRestored
    | PremiseOfValueAdminRestored
    | PremiseOfValueTrashFlushed
    | PremiseOfValuesActionToggleLoading;
