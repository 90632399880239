<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet-head--lg'" [viewLoading$]="dataSource.loading$"
                       [tooltipTitle]="'TOOLTIP.TRAINING_AND_DOCUMENT.LIST.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.TRAINING_AND_DOCUMENT.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet-head-title">
                <span>{{ 'TRAINING_DOC.LIST.TITLE' | translate }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <button (click)="addTrainingDocument()" mat-raised-button
                    [matTooltip]="'TRAINING_DOC.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary"
                    class="mr-2"
                    type="button">
                <span>{{ 'TRAINING_DOC.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button *ngIf="trashCnt != 0" (click)="trash()" mat-raised-button
                    [matTooltip]="'TRAINING_DOC.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button *ngIf="adminTrashCnt != 0" (click)="adminTrash()" mat-raised-button
                    [matTooltip]="'TRAINING_DOC.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="kt-form">
            <!-- start::FILTERS -->
            <div class="kt-form-filtration">
                <div class="row align-items-center active">

                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">
                    </mat-form-field>
                    <div class="col-md-1">
                        <button (click)="resetFilter()" mat-raised-button [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>

                </div>
            </div>

            <!-- end::FILTERS -->

            <!-- start::GROUP ACTIONS -->
            <!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
            <div class="row align-items-center collapse kt-form-group-actions kt-margin-top-20 kt-margin-bottom-20"
                 [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="kt-form-group kt-form-group--inline">
                        <div class="kt-form-label kt-form-label-no-wrap">
                            <label class="kt--font-bold kt-font-danger-">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
                                {{ selection.selected.length }}
                            </label>
                            <!-- selectedCountsTitle => function from codeBehind (client-list.component.ts file) -->
                            <!-- selectedCountsTitle => just returns title of selected items count -->
                            <!-- for example: Selected records count: 4 -->
                        </div>
                        <div class="kt-form-control kt-form-group--inline">

                            <!--<button (click)="fetchClients()" mat-raised-button matTooltip="Fetch selected clients" class="mat-button-mt-4">-->
                            <!--<mat-icon>clear_all</mat-icon>-->
                            <!--Fetch Selected-->
                            <!--</button>&nbsp;&lt;!&ndash; Call 'fetch-entity-dialog' from _shared folder &ndash;&gt;-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped" #table [dataSource]="dataSource" matSort #sort1="matSort"
                       matSortActive="id" matSortDirection="desc" matSortDisableClear>

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell *matCellDef="let trainingDocument">{{trainingDocument.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'TRAINING_DOC.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let trainingDocument">{{trainingDocument.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'TRAINING_DOC.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let trainingDocument">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>


                            <p [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                        username: trainingDocument.createdBy ? trainingDocument.createdBy : 'Unknown',
                                        date: (trainingDocument.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                } ">
                            </p>
                            <p [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                        username: trainingDocument.lastUpdatedBy ? trainingDocument.lastUpdatedBy: 'Unknown',
                                        date: (trainingDocument.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                } ">
                            </p>
                        </ng-template>
                        <button mat-icon-button
                                color="primary"
                                container="body"
                                [ngbPopover]="popContent"
                                [popoverTitle]="popTitle"
                                *ngIf="currentUser"
                        >
                            <mat-icon
                                    [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info
                            </mat-icon>
                        </button>

                        <button mat-icon-button color="primary" [matTooltip]="'TRAINING_DOC.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editTrainingDocument(trainingDocument)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <button mat-icon-button color="warn" [matTooltip]="'TRAINING_DOC.LIST.BUTTON.DELETE.TOOLTIP' | translate" type="button"
                                (click)="deleteTrainingDocument(trainingDocument)">
                            <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index" [ngClass]="{gray: index%2}">
                </mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}</div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
                           [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>