// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {SourceExternalReferenceActions, SourceExternalReferenceActionTypes} from '../_actions/source-external-reference.actions';
// Models
import {SourceExternalReference} from '../_models/source-external-reference.model';
import {QueryParamsModel} from '../../_base/crud';

export interface SourceExternalReferencesState extends EntityState<SourceExternalReference> {
    isAllSourceExternalReferencesLoaded: boolean;
    queryRowsCount: number;
    queryResult: SourceExternalReference[];
    lastCreatedSourceExternalReferenceId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SourceExternalReference> = createEntityAdapter<SourceExternalReference>();

export const initialSourceExternalReferencesState: SourceExternalReferencesState = adapter.getInitialState({
    isAllSourceExternalReferencesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedSourceExternalReferenceId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function sourceExternalReferencesReducer(state = initialSourceExternalReferencesState, action: SourceExternalReferenceActions): SourceExternalReferencesState {
    switch (action.type) {
        case SourceExternalReferenceActionTypes.SourceExternalReferencesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSourceExternalReferenceId: undefined
            };
        case SourceExternalReferenceActionTypes.SourceExternalReferencesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerCreated:
            return {
                ...state
            };
        case SourceExternalReferenceActionTypes.SourceExternalReferenceCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedSourceExternalReferenceId: action.payload.item.id
            });
        case SourceExternalReferenceActionTypes.SourceExternalReferenceUpdated:
            return adapter.updateOne(action.payload.partialItem, state);
        case SourceExternalReferenceActionTypes.AllSourceExternalReferencesLoaded:
            return adapter.addAll(action.payload.sourceExternalReferences, {
                ...state, isAllSourceExternalReferencesLoaded: true
            });
        case SourceExternalReferenceActionTypes.SourceExternalReferencesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case SourceExternalReferenceActionTypes.SourceExternalReferencesPageLoaded:
            return adapter.addMany(action.payload.sourceExternalReferences, {
                ...initialSourceExternalReferencesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case SourceExternalReferenceActionTypes.SourceExternalReferenceDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case SourceExternalReferenceActionTypes.SourceExternalReferenceRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case SourceExternalReferenceActionTypes.SourceExternalReferenceDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case SourceExternalReferenceActionTypes.SourceExternalReferenceAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case SourceExternalReferenceActionTypes.SourceExternalReferenceDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case SourceExternalReferenceActionTypes.SourceExternalReferenceTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
