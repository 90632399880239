// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {KeyCategoryActions, KeyCategoryActionTypes} from '../_actions/key-category.actions';
// Models
import {KeyCategoryModel} from '../_models/key-category.model';
import {QueryParamsModel} from '../../_base/crud';

export interface KeyCategoriesState extends EntityState<KeyCategoryModel> {
    isAllKeyCategoriesLoaded: boolean;
    queryRowsCount: number;
    queryResult: KeyCategoryModel[];
    lastCreatedKeyCategoryId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<KeyCategoryModel> = createEntityAdapter<KeyCategoryModel>();

export const initialKeyCategoriesState: KeyCategoriesState = adapter.getInitialState({
    isAllKeyCategoriesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedKeyCategoryId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function keyCategoriesReducer(state = initialKeyCategoriesState, action: KeyCategoryActions): KeyCategoriesState {
    switch (action.type) {
        case KeyCategoryActionTypes.KeyCategoriesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedKeyCategoryId: undefined
            };
        case KeyCategoryActionTypes.KeyCategoriesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case KeyCategoryActionTypes.KeyCategoryOnServerCreated:
            return {
                ...state
            };
        case KeyCategoryActionTypes.KeyCategoryCreated:
            return adapter.addOne(action.payload.keyCategory, {
                ...state, lastCreatedKeyCategoryId: action.payload.keyCategory.id
            });
        case KeyCategoryActionTypes.KeyCategoryUpdated:
            return adapter.updateOne(action.payload.partialKeyCategory, state);
        case KeyCategoryActionTypes.AllKeyCategoriesLoaded:
            return adapter.addAll(action.payload.keyCategories, {
                ...state, isAllKeyCategoriesLoaded: true
            });
        case KeyCategoryActionTypes.KeyCategoriesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case KeyCategoryActionTypes.KeyCategoriesPageLoaded:
            return adapter.addMany(action.payload.keyCategories, {
                ...initialKeyCategoriesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
            case KeyCategoryActionTypes.KeyCategoryDeleted:
                return adapter.removeOne(action.payload.id, {
                    ...state, totalTrashed: state.totalTrashed + 1
                });
    
            case KeyCategoryActionTypes.KeyCategoryRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalTrashed: state.totalTrashed - 1
                });
    
            case KeyCategoryActionTypes.KeyCategoryDeletedFromTrash:
                return {
                    ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
                };
    
            // Admin Trash Action
            case KeyCategoryActionTypes.KeyCategoryAdminRestored:
                return adapter.addOne(action.payload.item, {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                });
    
            case KeyCategoryActionTypes.KeyCategoryDeletedFromAdminTrash:
                return {
                    ...state, totalAdminTrashed: state.totalAdminTrashed - 1
                };
            case KeyCategoryActionTypes.KeyCategoryTrashFlushed:
                return {
                    ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
                };
            default:
                return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
