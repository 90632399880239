<div class="mat-table-wrapper pl-4">
    <table class="table lmat-elevation-z8 table-striped">
        <thead class="thead-dark">
            <tr>
                <th scope="col" [ngStyle]="{'width': 100/(comparablesData.length + 1) + '%'}">
                    Item
                </th>
                <th scope="col" *ngFor="let comData of comparablesData" [style.width]="100/(comparablesData.length + 1) + '%'">
                    {{isMulti ? (comData.com.ref_num) : 'Comparable'}}
                </th>
            </tr>
        </thead>
        <tbody *ngIf="(rows$|async) as rows">
            <tr>
                <td>
                    Comparable Type
                </td>
                <td *ngFor="let comData of comparablesData">
                    {{rows.comparableType[comData.com.ref_num]}}
                </td>
            </tr>
            <tr>
                <td>
                    Considerations
                </td>
                <td *ngFor="let comData of comparablesData">
                    <ng-container *ngIf="rows.consideration[comData.com.ref_num].values.length == 1">
                        {{rows.consideration[comData.com.ref_num].values[0].value}}
                    </ng-container>
                    <ng-container *ngIf="rows.consideration[comData.com.ref_num].values.length > 1">
                        <mat-select 
                            [panelClass]="'overview-consideration-select'"
                            (selectionChange)="onSelectionChange($event, comData.com.id)"
                            [value]="rows.consideration[comData.com.ref_num].selected"
                            [placeholder]="rows.consideration[comData.com.ref_num].placeholder" >
                            <mat-option *ngFor="let v of rows.consideration[comData.com.ref_num].values" 
                                [value]="v.id">
                                {{v.value}}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td>
                    Source Type
                </td>
                <ng-container *ngFor="let comData of comparablesData">
                    <ng-container *ngIf="rows.source.type[comData.com.ref_num] == notSelected">
                        <td style="background-color: gray;"></td>
                    </ng-container>
                    <ng-container *ngIf="rows.source.type[comData.com.ref_num] != notSelected">
                        <ng-container *ngIf="rows.source.type[comData.com.ref_num] == 'Unknown'">
                            <td class="selected-com" >
                                {{rows.source.type[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="rows.source.type[comData.com.ref_num] != 'Unknown'">
                            <td>
                                {{rows.source.type[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Information 
                </td>
                <ng-container *ngFor="let comData of comparablesData">
                    <ng-container *ngIf="rows.source.information[comData.com.ref_num] == notSelected">
                        <td style="background-color: gray;"></td>
                    </ng-container>
                    <ng-container *ngIf="rows.source.information[comData.com.ref_num] != notSelected">
                        <ng-container *ngIf="rows.source.information[comData.com.ref_num] == 'Unknown'">
                            <td class="selected-com" >
                                {{rows.source.information[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="rows.source.information[comData.com.ref_num] != 'Unknown'">
                            <td>
                                {{rows.source.information[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Credibility 
                </td>
                <ng-container *ngFor="let comData of comparablesData">
                    <ng-container *ngIf="rows.source.credibility[comData.com.ref_num] == notSelected">
                        <td style="background-color: gray;"></td>
                    </ng-container>
                    <ng-container *ngIf="rows.source.credibility[comData.com.ref_num] != notSelected">
                        <ng-container *ngIf="rows.source.credibility[comData.com.ref_num] == 'Unknown'">
                            <td class="selected-com">
                                {{rows.source.credibility[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="rows.source.credibility[comData.com.ref_num] != 'Unknown'">
                            <td>
                                {{rows.source.credibility[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Description 
                </td>
                <ng-container *ngFor="let comData of comparablesData">
                    <ng-container *ngIf="rows.source.description[comData.com.ref_num] == notSelected">
                        <td style="background-color: gray;"></td>
                    </ng-container>
                    <ng-container *ngIf="rows.source.description[comData.com.ref_num] != notSelected">
                        <ng-container *ngIf="rows.source.description[comData.com.ref_num] == 'Unknown'">
                            <td class="selected-com" >
                                {{rows.source.description[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="rows.source.description[comData.com.ref_num] != 'Unknown'">
                            <td>
                                {{rows.source.description[comData.com.ref_num]}}
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>