import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'lodash';
import { Behavior } from 'popper.js';
import { BehaviorSubject, combineLatest, Observable, ObservedValueOf, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResidentialExternalAreaModel } from 'src/app/core/comparable';
import { AllUnitAreaMeasurementsRequested, selectAllUnitAreaMeasurements, StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { AppState } from 'src/app/core/reducers';
import { MongoliaStandardTableComponent } from '../size-module/mongolia-standard-table/mongolia-standard-table.component';
import { Rooms } from '../size-module/mongolia-standard-table/mongolia-standard-table.types';
import { IpmsStandardMeasurementComponentComponent } from './ipms-standard-measurement-component/ipms-standard-measurement-component.component';
import { FloorAction, SizeChange } from './sizes-module.types';

@Component({
  selector: 'kt-sizes-module',
  templateUrl: './sizes-module.component.html',
  styleUrls: ['./sizes-module.component.scss'],
  providers: [StandardMeasurementUtility]
})
export class SizesModuleComponent implements OnInit, OnDestroy {
  @Input() assetClassID: number;
  @Input() sizeChanges$: Observable<SizeChange>;
  @Input() floorAction$: Observable<FloorAction>;
  @Input() externalAreas$: Observable<ResidentialExternalAreaModel[]>
  @Input() bedroom$: Observable<number>;
  @Input() roomChange$: Observable<Rooms>;
  @Input() readonly: boolean;

  @ViewChild(IpmsStandardMeasurementComponentComponent, {static: false}) ipmsTable: IpmsStandardMeasurementComponentComponent;
  @ViewChild(MongoliaStandardTableComponent, {static: false}) msTable: MongoliaStandardTableComponent;

  private _onDestroy$: Subject<void> = new Subject();
  private _sizeChanges$: BehaviorSubject<SizeChange> = new BehaviorSubject(null);
  sizeChange$ = this._sizeChanges$.asObservable();

  smID = null;
  _unitAreaMeasurement$: BehaviorSubject<UnitAreaMeasurement> = new BehaviorSubject(null);
  unitAreaMeasurement$ = this._unitAreaMeasurement$.asObservable();

  table: 'ipms' | 'mongolianStandard' | null = null;

  constructor(
    public smUtilityService: StandardMeasurementUtility,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new AllUnitAreaMeasurementsRequested());
    combineLatest([
      this.sizeChanges$,
      this.store.select(selectAllUnitAreaMeasurements)
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([val, units]) => {
      if (!units) {
        return;
      }
      const unit = units.find(u => u.id == val.unit_area_measurement_id)
      if (!unit) {
        return;
      }
      this._unitAreaMeasurement$.next(unit);
      this.smID = val.standard_measurement_id;

      if (this.smUtilityService.isIPMS(val.standard_measurement_id)) {
        this.table = 'ipms';
      }
      if (this.smUtilityService.isMongolianStandard(val.standard_measurement_id)) {
        this.table = 'mongolianStandard';
      }
      this._sizeChanges$.next(val);
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  isValid(): boolean {
    if (this.smUtilityService.isMongolianStandard(this.smID)) {
      return this.msTable.isValid();
    }
    if (this.smUtilityService.isIPMS(this.smID)) {
      return this.ipmsTable.isValid();
    }
    return false;
  }

  getData(): any {
    if (this.smUtilityService.isMongolianStandard(this.smID)) {
      return this.msTable.getData();
    }
    if (this.smUtilityService.isIPMS(this.smID)) {
      return this.ipmsTable.getData();
    }
    return null;
  }
}
