import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {PaymentsService} from '../_services/payment.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    PaymentActionTypes,
    PaymentsPageRequested,
    PaymentsPageLoaded,
    PaymentActionToggleLoading,
    PaymentsPageToggleLoading,
    PaymentOnServerCreated,
    PaymentOnServerUpdated,
    PaymentCreated,
    PaymentUpdated,
    PaymentsTrashRequested,
    PaymentRestored,
    PaymentOnServerRestored,
    PaymentDeletedFromTrash,
    OnePaymentDeleted,
    PaymentGet, PaymentMapPageRequested
} from '../_actions/payment.actions';
import {of} from 'rxjs';

@Injectable()
export class PaymentEffects {
    showPageLoadingDispatcher = new PaymentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PaymentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PaymentActionToggleLoading({isLoading: false});

    @Effect()
    loadPaymentsPage$ = this.actions$.pipe(
        ofType<PaymentsPageRequested>(PaymentActionTypes.PaymentsPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.paymentsService.findPayments(payload.page, payload.toeId);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new PaymentsPageLoaded({
                payments: result.data,
                totalCount: result.pagination.total,
                trashed: result.pagination.total_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadPaymentMapPage$ = this.actions$.pipe(
        ofType<PaymentMapPageRequested>(PaymentActionTypes.PaymentMapPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.paymentsService.getApartments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new PaymentsPageLoaded({
                payments: result.data,
                totalCount: result.pagination.total,
                trashed: result.pagination.total_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createPayment$ = this.actions$.pipe(
        ofType<PaymentOnServerCreated>(PaymentActionTypes.PaymentOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.paymentsService.createPayment(
                    payload.payment,
                    payload.paymentTaxes,
                    payload.valuations,
                    // payload.special_assumptions,
                    // payload.departures
                ).pipe(
                tap(res => {
                    this.store.dispatch(new PaymentCreated({payment: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updatePayment$ = this.actions$
        .pipe(
            ofType<PaymentOnServerUpdated>(PaymentActionTypes.PaymentOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.paymentsService.updatePayment(
                        payload.payment,
                        payload.paymentTaxes,
                        payload.valuations,
                        // payload.special_assumptions,
                        // payload.departures
                    ).pipe(
                    tap(res => {
                        this.store.dispatch(new PaymentUpdated({payment: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    trashPayments$ = this.actions$
        .pipe(
            ofType<PaymentsTrashRequested>(PaymentActionTypes.PaymentTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.paymentsService.getTrashedPayments();
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePayment$ = this.actions$
        .pipe(
            ofType<PaymentOnServerRestored>(PaymentActionTypes.PaymentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.paymentsService.restoreFromTrash(payload.paymentId).pipe(
                    tap(res => {
                        this.store.dispatch(new PaymentRestored({payment: res}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deletePaymentFromTrash$ = this.actions$
        .pipe(
            ofType<PaymentDeletedFromTrash>(PaymentActionTypes.PaymentDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.paymentsService.deleteFromTrash(payload.paymentId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deletePayment$ = this.actions$
        .pipe(
            ofType<OnePaymentDeleted>(PaymentActionTypes.OnePaymentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.paymentsService.deletePayment(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    getPayment$ = this.actions$
        .pipe(
            ofType<PaymentGet>(PaymentActionTypes.PaymentGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.paymentsService.getPayment(payload.paymentId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(
        private actions$: Actions,
        private paymentsService: PaymentsService,
        private store: Store<AppState>
    ) { }
}
