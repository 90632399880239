import {ResidentialType} from '../_models/residential-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ResidentialTypesState} from '../_reducers/residential-type.reducers';
import * as fromResidentialType from '../_reducers/residential-type.reducers';
import {each} from 'lodash';

export const selectResidentialTypesState = createFeatureSelector<ResidentialTypesState>('residentialTypes');

export const selectResidentialTypeById = (valuationStandardId: number) => createSelector(
    selectResidentialTypesState,
    valuationStandardsState => valuationStandardsState.entities[valuationStandardId]
);

export const selectAllResidentialTypes = createSelector(
    selectResidentialTypesState,
    fromResidentialType.selectAll
);

export const selectAllResidentialTypesIds = createSelector(
    selectResidentialTypesState,
    fromResidentialType.selectIds
);

export const allResidentialTypesLoaded = createSelector(
    selectResidentialTypesState,
    valuationStandardsState => valuationStandardsState.isAllResidentialTypesLoaded
);


export const selectResidentialTypesPageLoading = createSelector(
    selectResidentialTypesState,
    valuationStandardsState => valuationStandardsState.listLoading
);

export const selectResidentialTypesActionLoading = createSelector(
    selectResidentialTypesState,
    valuationStandardsState => valuationStandardsState.actionLoading
);

export const selectLastCreatedResidentialTypeId = createSelector(
    selectResidentialTypesState,
    valuationStandardsState => valuationStandardsState.lastCreatedResidentialTypeId
);

export const selectResidentialTypesShowInitWaitingMessage = createSelector(
    selectResidentialTypesState,
    valuationStandardsState => valuationStandardsState.showInitWaitingMessage
);

export const selectTrashedResidentialTypeCount = createSelector(
    selectResidentialTypesState,
    (valuationStandardsState) => valuationStandardsState.totalTrashed
);

export const selectAdminTrashedResidentialTypeCount = createSelector(
    selectResidentialTypesState,
    (valuationStandardsState) => valuationStandardsState.totalAdminTrashed
);


export const selectResidentialTypeQueryResult = createSelector(
    selectResidentialTypesState,
    residentialTypesState => {
        const items: ResidentialType[] = [];
        each(residentialTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ResidentialType[] = httpExtension.sortArray(items, residentialTypesState.lastQuery.sortField, residentialTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, residentialTypesState.totalCount, '', residentialTypesState.totalTrashed);
    }
);
