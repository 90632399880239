// NGRX
import {EntityState, EntityAdapter, createEntityAdapter, Update} from '@ngrx/entity';
// Actions
import {AssetClassDetailActions, AssetClassDetailActionTypes} from '../_actions/asset-class-detail.actions';
// Models
import {AssetClassDetailModel} from '../_models/asset-class-detail.model';

export interface AssetClassDetailState extends EntityState<AssetClassDetailModel> {
    listLoading: boolean;
    actionLoading: boolean;
    lastCreatedAssetClassDetailId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassDetailModel> = createEntityAdapter<AssetClassDetailModel>();

export const initialAssetClassDetailState: AssetClassDetailState = adapter.getInitialState({
    listLoading: false,
    actionLoading: false,
    lastCreatedAssetClassDetailId: undefined,
    showInitWaitingMessage: true
});

export function assetClassDetailReducer(state = initialAssetClassDetailState, action: AssetClassDetailActions): AssetClassDetailState {
    switch (action.type) {
        case AssetClassDetailActionTypes.AssetClassActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AssetClassDetailActionTypes.AssetClassDetailOnServerCreated:
            return {
                ...state, lastCreatedAssetClassDetailId: undefined
            };

        case AssetClassDetailActionTypes.AssetClassDetailCreated:
            return adapter.addOne(action.payload.assetClass, {
                ...state, lastCreatedAssetClassDetailId: action.payload.assetClass.id
            });

        case AssetClassDetailActionTypes.AssetClassDetailOnServerUpdated:
            return {
                ...state, lastCreatedAssetClassDetailId: undefined
            };

        case AssetClassDetailActionTypes.AssetClassDetailUpdated:
            const _item: Update<AssetClassDetailModel> = {
                id: action.payload.assetClass.id,
                changes: action.payload.assetClass
            };
            return adapter.updateOne(_item, state);

        case AssetClassDetailActionTypes.AssetClassDetailLoaded: {
            return {
                ...state, lastCreatedAssetClassDetailId: action.payload.assetClass.id
            };
        }
        default:
            return state;
    }
}

// export const getAssetClassDetailState = createFeatureSelector<AssetClassDetailModel>('assetClassDetails');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
