// Angular
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CertificationFileModel} from '../../../../../core/file-upload';
import {TypesUtilsService} from '../../../../../core/_base/crud';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
    selector: 'kt-percentage-bottom-sheet',
    templateUrl: './percentage-bottom-sheet.component.html'
})
export class PercentageBottomSheet implements OnInit {
    // Public properties
    form: UntypedFormGroup;
    max = 100;
    min = -100;
    value = 0;

    /**
     * Component constructor
     *
     * @param bottomSheetRef
     * @param data
     */
    constructor(private bottomSheetRef: MatBottomSheetRef<PercentageBottomSheet>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
        if (this.data.value) {
            this.value = this.data.value;
        }
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.bottomSheetRef.backdropClick().subscribe(() => {
            this.bottomSheetRef.dismiss(this.value);
        });
    }

    formatLabel(value: number) {
        return value + '%';
    }

    closeSheet(event: MouseEvent): void {
        this.bottomSheetRef.dismiss(this.value);
        event.preventDefault();
    }
}
