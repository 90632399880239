import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { HouseAdjustmentModel } from '../_models/house-adjustment.model';

export enum HouseAdjustmentsActionTypes {
    AllHouseAdjustmentsRequested = '[House Adjustments List] All House Adjustments Requested',
    AllHouseAdjustmentsLoaded = '[House Adjustments API] All House Adjustments Loaded',

    HouseAdjustmentOnServerCreated = '[Edit House Adjustment] House Adjustment on Server Created',
    HouseAdjustmentCreated = '[Edit House Adjustment] House Adjustment Created',
    HouseAdjustmentUpdated = '[Edit House Adjustment] House Adjustment Updated',
    HouseAdjustmentDeleted = '[House Adjustment Deleted] House Adjustment Deleted',

    HouseAdjustmentRestored = '[House Adjustment Trash] House Adjustment Restored',
    HouseAdjustmentOnServerRestored = '[House Adjustment Trash] House Adjustment On Server Restored',

    HouseAdjustmentOnServerAdminRestored = '[House Adjustment Admin Trash] House Adjustment On Server Restored',
    HouseAdjustmentAdminRestored = '[House Adjustment Admin Trash] House Adjustment Restored',

    HouseAdjustmentDeletedFromTrash = '[House Adjustment Trash] House Adjustment deleted',
    HouseAdjustmentDeletedFromAdminTrash = '[House Adjustment Admin Trash] House Adjustment deleted',

    HouseAdjustmentTrash = 'House Adjustment Trashed',
    HouseAdjustmentTrashFlushed = 'House Adjustment Trash Flushed',

    // Page system

    HouseAdjustmentsPageRequested = '[House Adjustment List Page] House Adjustment Page Requested',
    HouseAdjustmentsPageLoaded = '[House Adjustment API] House Adjustment Page Loaded',
    HouseAdjustmentsPageCancelled = '[House Adjustment API] House Adjustment Page Cancelled',

    HouseAdjustmentsPageToggleLoading = '[House Adjustment page] House Adjustment Page Toggle Loading',
    HouseAdjustmentActionToggleLoading = '[House Adjustment] House Adjustment Action Toggle Loading'
}

export class HouseAdjustmentOnServerCreated implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentOnServerCreated;

    constructor(public payload: { item: HouseAdjustmentModel }) {
    }
}

export class HouseAdjustmentCreated implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentCreated;

    constructor(public payload: { item: HouseAdjustmentModel }) {
    }
}

export class HouseAdjustmentUpdated implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<HouseAdjustmentModel>,
        item: HouseAdjustmentModel
    }) {
    }
}

export class HouseAdjustmentDeleted implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class HouseAdjustmentsPageRequested implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class HouseAdjustmentsPageLoaded implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentsPageLoaded;

    constructor(public payload: { items: HouseAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class HouseAdjustmentsPageCancelled implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentsPageCancelled;
}

export class AllHouseAdjustmentsRequested implements Action {
    readonly type = HouseAdjustmentsActionTypes.AllHouseAdjustmentsRequested;
}

export class AllHouseAdjustmentsLoaded implements Action {
    readonly type = HouseAdjustmentsActionTypes.AllHouseAdjustmentsLoaded;

    constructor(public payload: { items: HouseAdjustmentModel[] }) {
    }
}

export class HouseAdjustmentsPageToggleLoading implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class HouseAdjustmentActionToggleLoading implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class HouseAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class HouseAdjustmentDeletedFromTrash implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class HouseAdjustmentOnServerRestored implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class HouseAdjustmentRestored implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentRestored;

    constructor(public payload: { item: HouseAdjustmentModel }) {
    }
}

export class HouseAdjustmentOnServerAdminRestored implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class HouseAdjustmentAdminRestored implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentAdminRestored;

    constructor(public payload: { item: HouseAdjustmentModel }) {
    }
}

export class HouseAdjustmentTrashFlushed implements Action {
    readonly type = HouseAdjustmentsActionTypes.HouseAdjustmentTrashFlushed;

    constructor() {
    }
}

export type HouseAdjustmentActions = HouseAdjustmentCreated
    | HouseAdjustmentUpdated
    | HouseAdjustmentDeleted
    | HouseAdjustmentsPageRequested
    | HouseAdjustmentsPageLoaded
    | HouseAdjustmentsPageCancelled
    | AllHouseAdjustmentsLoaded
    | AllHouseAdjustmentsRequested
    | HouseAdjustmentOnServerCreated
    | HouseAdjustmentDeletedFromAdminTrash
    | HouseAdjustmentDeletedFromTrash
    | HouseAdjustmentOnServerRestored
    | HouseAdjustmentRestored
    | HouseAdjustmentOnServerAdminRestored
    | HouseAdjustmentAdminRestored
    | HouseAdjustmentTrashFlushed
    | HouseAdjustmentsPageToggleLoading
    | HouseAdjustmentActionToggleLoading;