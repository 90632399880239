// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ConstructionType} from '../_models/construction-type.model';

export enum ConstructionTypeActionTypes {

    AllConstructionTypesRequested = '[ConstructionTypes Home Page] All ConstructionTypes Requested',
    ConstructionTypesOfBuildingTypeRequested = '[ConstructionTypes Home Page]  ConstructionTypes of Building type Requested',
    AllConstructionTypesLoaded = '[ConstructionTypes API] All ConstructionTypes Loaded',

    ConstructionTypeOnServerCreated = '[Edit ConstructionType Dialog] ConstructionType On Server Created',
    ConstructionTypeCreated = '[Edit ConstructionTypes Dialog] ConstructionTypes Created',
    ConstructionTypeUpdated = '[Edit ConstructionType Dialog] ConstructionType Updated',
    ConstructionTypeDeleted = '[ConstructionTypes List Page] ConstructionType Deleted',

    ConstructionTypesPageRequested = '[ConstructionTypes List Page] ConstructionTypes Page Requested',
    ConstructionTypesPageLoaded = '[ConstructionTypes API] ConstructionTypes Page Loaded',
    ConstructionTypesPageCancelled = '[ConstructionTypes API] ConstructionTypes Page Cancelled',

    ConstructionTypesPageToggleLoading = '[ConstructionTypes page] ConstructionTypes Page Toggle Loading',
    ConstructionTypesActionToggleLoading = '[ConstructionTypes] ConstructionTypes Action Toggle Loading',

    // trash system

    ConstructionTypeOnServerRestored = '[ConstructionType Trash] ConstructionType On Server Restored',
    ConstructionTypeRestored = '[ConstructionType Trash] ConstructionType Restored',

    ConstructionTypeOnServerAdminRestored = '[ConstructionType Admin Trash] ConstructionType On Server Restored',
    ConstructionTypeAdminRestored = '[ConstructionType Admin Trash] ConstructionType Restored',

    ConstructionTypeDeletedFromTrash = '[ConstructionType Trash] ConstructionType deleted',
    ConstructionTypeDeletedFromAdminTrash = '[ConstructionType Admin Trash] ConstructionType deleted',

    ConstructionTypeTrash = 'ConstructionType Trashed',
    ConstructionTypeTrashFlushed = 'ConstructionType Trash Flushed',
}

export class ConstructionTypeOnServerCreated implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeOnServerCreated;

    constructor(public payload: { constructionType: ConstructionType }) {
    }
}

export class ConstructionTypeCreated implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeCreated;

    constructor(public payload: { constructionType: ConstructionType }) {
    }
}

export class ConstructionTypeUpdated implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeUpdated;

    constructor(public payload: {
        partialConstructionType: Update<ConstructionType>,
        constructionType: ConstructionType
    }) {
    }
}

export class ConstructionTypeDeleted implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ConstructionTypesPageRequested implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ConstructionTypesPageLoaded implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypesPageLoaded;

    constructor(public payload: { constructionTypes: ConstructionType[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ConstructionTypesPageCancelled implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypesPageCancelled;
}

export class AllConstructionTypesRequested implements Action {
    readonly type = ConstructionTypeActionTypes.AllConstructionTypesRequested;
}

export class ConstructionTypesOfBuildingTypeRequested implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypesOfBuildingTypeRequested;

    constructor(public payload: { typeId: number }) {
    }
}

export class AllConstructionTypesLoaded implements Action {
    readonly type = ConstructionTypeActionTypes.AllConstructionTypesLoaded;

    constructor(public payload: { constructionTypes: ConstructionType[] }) {
    }
}

export class ConstructionTypesPageToggleLoading implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ConstructionTypesActionToggleLoading implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ConstructionTypeDeletedFromAdminTrash implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ConstructionTypeDeletedFromTrash implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ConstructionTypeOnServerRestored implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ConstructionTypeRestored implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeRestored;

    constructor(public payload: { item: ConstructionType }) {
    }
}

export class ConstructionTypeOnServerAdminRestored implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ConstructionTypeAdminRestored implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeAdminRestored;

    constructor(public payload: { item: ConstructionType }) {
    }
}

export class ConstructionTypeTrashFlushed implements Action {
    readonly type = ConstructionTypeActionTypes.ConstructionTypeTrashFlushed;

    constructor() {
    }
}

export type ConstructionTypeActions = ConstructionTypeCreated
    | ConstructionTypeUpdated
    | ConstructionTypeDeleted
    | ConstructionTypesPageRequested
    | ConstructionTypesOfBuildingTypeRequested
    | ConstructionTypesPageLoaded
    | ConstructionTypesPageCancelled
    | AllConstructionTypesLoaded
    | AllConstructionTypesRequested
    | ConstructionTypeOnServerCreated
    | ConstructionTypesPageToggleLoading
    | ConstructionTypesActionToggleLoading
    | ConstructionTypeDeletedFromAdminTrash
    | ConstructionTypeDeletedFromTrash
    | ConstructionTypeOnServerRestored
    | ConstructionTypeRestored
    | ConstructionTypeOnServerAdminRestored
    | ConstructionTypeAdminRestored
    | ConstructionTypeTrashFlushed;
