// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsDividedModel} from '../../_base/crud';
// Models
import {Ceiling} from '../_models/ceiling.model';

export enum CeilingActionTypes {

    AllCeilingsRequested = '[Ceilings Home Page] All Ceilings Requested',
    AllCeilingsLoaded = '[Ceilings API] All Ceilings Loaded',

    CeilingOnServerCreated = '[Edit Ceiling Dialog] Ceiling On Server Created',
    CeilingCreated = '[Edit Ceilings Dialog] Ceilings Created',
    CeilingUpdated = '[Edit Ceiling Dialog] Ceiling Updated',
    CeilingDeleted = '[Ceilings List Page] Ceiling Deleted',

    CeilingsPageRequested = '[Ceilings List Page] Ceilings Page Requested',
    CeilingsPageLoaded = '[Ceilings API] Ceilings Page Loaded',
    CeilingsPageCancelled = '[Ceilings API] Ceilings Page Cancelled',

    CeilingsPageToggleLoading = '[Ceilings page] Ceilings Page Toggle Loading',
    CeilingsActionToggleLoading = '[Ceilings] Ceilings Action Toggle Loading',

    // trash system

    CeilingOnServerRestored = '[Ceiling Trash] Ceiling On Server Restored',
    CeilingRestored = '[Ceiling Trash] Ceiling Restored',

    CeilingOnServerAdminRestored = '[Ceiling Admin Trash] Ceiling On Server Restored',
    CeilingAdminRestored = '[Ceiling Admin Trash] Ceiling Restored',

    CeilingDeletedFromTrash = '[Ceiling Trash] Ceiling deleted',
    CeilingDeletedFromAdminTrash = '[Ceiling Admin Trash] Ceiling deleted',

    CeilingTrash = 'Ceiling Trashed',
    CeilingTrashFlushed = 'Ceiling Trash Flushed',

    CeilingsClear = '[Ceilings API] Ceilings Page Clear',
}

export class CeilingOnServerCreated implements Action {
    readonly type = CeilingActionTypes.CeilingOnServerCreated;

    constructor(public payload: { ceiling: Ceiling}) {
    }
}

export class CeilingCreated implements Action {
    readonly type = CeilingActionTypes.CeilingCreated;

    constructor(public payload: { ceiling: Ceiling }) {
    }
}

export class CeilingUpdated implements Action {
    readonly type = CeilingActionTypes.CeilingUpdated;

    constructor(public payload: {
        partialCeiling: Update<Ceiling>,
        ceiling: Ceiling
    }) {
    }
}

export class CeilingDeleted implements Action {
    readonly type = CeilingActionTypes.CeilingDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class CeilingsPageRequested implements Action {
    readonly type = CeilingActionTypes.CeilingsPageRequested;

    constructor(public payload: { page: QueryParamsDividedModel }) {
    }
}

export class CeilingsPageLoaded implements Action {
    readonly type = CeilingActionTypes.CeilingsPageLoaded;

    constructor(public payload: { ceilings: Ceiling[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsDividedModel }) {
    }
}

export class CeilingsPageCancelled implements Action {
    readonly type = CeilingActionTypes.CeilingsPageCancelled;
}

export class AllCeilingsRequested implements Action {
    readonly type = CeilingActionTypes.AllCeilingsRequested;
}

export class AllCeilingsLoaded implements Action {
    readonly type = CeilingActionTypes.AllCeilingsLoaded;

    constructor(public payload: { ceilings: Ceiling[] }) {
    }
}

export class CeilingsPageToggleLoading implements Action {
    readonly type = CeilingActionTypes.CeilingsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class CeilingsActionToggleLoading implements Action {
    readonly type = CeilingActionTypes.CeilingsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class CeilingDeletedFromAdminTrash implements Action {
    readonly type = CeilingActionTypes.CeilingDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class CeilingDeletedFromTrash implements Action {
    readonly type = CeilingActionTypes.CeilingDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class CeilingOnServerRestored implements Action {
    readonly type = CeilingActionTypes.CeilingOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CeilingRestored implements Action {
    readonly type = CeilingActionTypes.CeilingRestored;

    constructor(public payload: { item: Ceiling }) {
    }
}

export class CeilingOnServerAdminRestored implements Action {
    readonly type = CeilingActionTypes.CeilingOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class CeilingAdminRestored implements Action {
    readonly type = CeilingActionTypes.CeilingAdminRestored;

    constructor(public payload: { item: Ceiling }) {
    }
}

export class CeilingTrashFlushed implements Action {
    readonly type = CeilingActionTypes.CeilingTrashFlushed;

    constructor() {
    }
}

export class CeilingsClear implements Action {
    readonly type = CeilingActionTypes.CeilingsClear;

    constructor() {
    }
}

export type CeilingActions = CeilingCreated
    | CeilingUpdated
    | CeilingDeleted
    | CeilingsPageRequested
    | CeilingsPageLoaded
    | CeilingsPageCancelled
    | AllCeilingsLoaded
    | AllCeilingsRequested
    | CeilingOnServerCreated
    | CeilingsPageToggleLoading
    | CeilingsActionToggleLoading
    | CeilingDeletedFromAdminTrash
    | CeilingDeletedFromTrash
    | CeilingOnServerRestored
    | CeilingRestored
    | CeilingOnServerAdminRestored
    | CeilingAdminRestored
    | CeilingTrashFlushed
    | CeilingsClear;
