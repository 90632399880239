<div class="mad-container">
    <div *ngIf="hasTitle" ktPortletTitle class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">{{title ? title : 'Documents'}}</h5>
        <hr class="active">
    </div>
	<div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container" *ngIf="!readonly">
		<div *ngIf="(isInProgress$|async)" class="mr-2">
			<div style="margin-bottom: 14px;">Uploading... {{progress$|async}}</div>
		</div>
		<div class="error mr-2" *ngIf="(hasFailed$|async)">
			Error: {{error$|async}}
		</div>
		<div class="mr-2" *ngIf="(isInProgress$|async)">
			<span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">
				Cancel
			</span>
		</div>
        <div *ngIf="!readonly">
            <span class="btn btn-primary" color="primary" mat-raised-button (click)="onAddClick()">Add files</span>
            <input #fileInput type="file" (change)="uploadFiles($event)" style="display: none" multiple accept=".xlsx,.xls,image/*,.doc,.docx,.pdf"/>
        </div>
	</div>
	<div class="mat-elevation-z0">
		<mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="type">
				<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
				<mat-cell *matCellDef="let file">
					<img [attr.src]="typesUtilsService.setIcon(typesUtilsService.getFileType(file.name))" />
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="title">
				<mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
				<mat-cell *matCellDef="let file">
                    <input class="title-input" [value]="file.title" (focusout)="onInputFocusOut($event, file)" >
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="descr">
				<mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
				<mat-cell *matCellDef="let file">{{file.descr}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
				<mat-cell *matCellDef="let file">
					<button mat-icon-button
							color="primary"
							[disabled]="readonly"
							[matTooltip]="'GENERAL.TOOLTIP.EDIT_DESCRIPTION' | translate"
							(click)="editFileDescription(file)"
							type="button">
						<mat-icon>create</mat-icon>
					</button>&nbsp;
					<button mat-icon-button
							color="warn"
							[disabled]="readonly"
							[matTooltip]="'GENERAL.TOOLTIP.DELETE_FILE' | translate"
							(click)="deleteUploadedFile(file)"
							type="button">
						<mat-icon>delete</mat-icon>
					</button>&nbsp;
					<button mat-icon-button
							[matTooltip]="'GENERAL.TOOLTIP.PREVIEW_FILE' | translate"
							(click)="previewUploadedFile(file)"
							type="button">
						<mat-icon *ngIf="!docs.includes(typesUtilsService.getFileType(file.name))">remove_red_eye</mat-icon>
						<mat-icon *ngIf="docs.includes(typesUtilsService.getFileType(file.name))">get_app</mat-icon>
					</button>&nbsp;
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
	</div>
</div>