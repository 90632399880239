<div>
    <div ktPortletTitle class="kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">TENURE</h5>
        <hr class="active">
    </div>

    <div class="form" [formGroup]="form">

        <section class="row">
            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Current Occupier, if any" formControlName="current_occupier"/>
                </mat-form-field>
            </div>

            <div class="col-lg-8 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Previous Occupier, if any" formControlName="previous_occupier"/>
                </mat-form-field>
            </div>

            <div class="col-lg-8 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Rights, restrictions and responsibilities in, over, on or under the land" formControlName="rrr_over_land"/>
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.RRR.DESCRIPTION' | translate">help
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Any other rights and restrictions ?" formControlName="additional_rrr_over_land"/>
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.RRR_ADDITONAL.DESCRIPTION' | translate">help
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Any known differences between documented boundaries, legal boundaries and physical boundaries ?" formControlName="difference_between_boundaries"/>
                </mat-form-field>
            </div>
        </section>
    </div>

    <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
        <button
            *ngIf="buttonEnable$|async"
                class="btn btn-primary"
                color="primary"
                (click)="addItem()"
                matTooltip="Add ground rent"
                type="button">Add ground rent 
        </button>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="land_tenure_name">
            <mat-header-cell *matHeaderCellDef>Land Tenure</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.land_tenure_name}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tenure_length">
            <mat-header-cell *matHeaderCellDef>Tenure Length (years)</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.tenure_length == 0 ? 'In perpetuity' : item.tenure_length}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="currency">
            <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.currency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="consideration">
            <mat-header-cell *matHeaderCellDef>Consideration</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{item.consideration | mask: 'separator':{thousandSeparator: ','} }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let item; let i = index;">
                <button mat-icon-button
                        color="primary"
                        matTooltip="Edit"
                        (click)="editItem(item, i)"
                        type="button">
                    <mat-icon>create</mat-icon>
                </button>&nbsp;
                <button mat-icon-button
                        color="warn"
                        matTooltip="Delete"
                        (click)="deleteItem(i)"
                        type="button">
                    <mat-icon>delete</mat-icon>
                </button>&nbsp;
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>    
</div>