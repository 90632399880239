import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AppState } from "../../reducers";
import { AssetClassHouseActionToggleLoading, AssetClassHouseActionTypes, AssetClassHouseAdminRestored, AssetClassHouseCreated, AssetClassHouseDeleted, AssetClassHouseDeletedFromAdminTrash, AssetClassHouseDeletedFromTrash, AssetClassHouseDuplicate, AssetClassHouseOnServerAdminRestored, AssetClassHouseOnServerCreated, AssetClassHouseOnServerRestored, AssetClassHouseOnServerUpdated, AssetClassHousePageLoaded, AssetClassHousePageRequested, AssetClassHousePageToggleLoading, AssetClassHouseRestored, AssetClassHouseTrashFlushed, AssetClassHouseUpdated } from "../_actions/asset-class-house.actions";
import { AssetClassHouseService } from "../_services/asset-class-house.service";

@Injectable()
export class AssetClassHouseEffects {
    showPageLoadingDispatcher = new AssetClassHousePageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassHouseActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassHouseActionToggleLoading({isLoading: false});

    constructor(
        private actions$: Actions,
        private service: AssetClassHouseService,
        private store: Store<AppState>
    ) {}

    @Effect()
    loadAssetClassHousePage$ = this.actions$.pipe(
        ofType<AssetClassHousePageRequested>(AssetClassHouseActionTypes.AssetClassHousePageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.find(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery = response[1];
            return new AssetClassHousePageLoaded({
                AssetClassHouses: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            })
        })
    )

    @Effect()
    createAssetClassHouse$ = this.actions$.pipe(
        ofType<AssetClassHouseOnServerCreated>(AssetClassHouseActionTypes.AssetClassHouseOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                payload.AssetClassHouse,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassHouseCreated({AssetClassHouse: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    updateAssetClassHouse$ = this.actions$.pipe(
        ofType<AssetClassHouseOnServerUpdated>(AssetClassHouseActionTypes.AssetClassHouseOnServerUpdated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.updateAssetClass(
                payload.AssetClassHouse,
                payload.fileList
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassHouseUpdated(
                        {AssetClassHouse: res.data}
                    ))
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassHouse$ = this.actions$.pipe(
        ofType<AssetClassHouseDeleted>(AssetClassHouseActionTypes.AssetClassHouseDeleted),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteAssetClass(payload.id);
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        })
    )

    @Effect()
    deleteAssetClassHouseFromTrash$ = this.actions$.pipe(
        ofType<AssetClassHouseDeletedFromTrash>(AssetClassHouseActionTypes.AssetClassHouseDeletedFromTrash),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.deleteFromTrash(payload.id)
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    deleteAssetClassHouseFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassHouseDeletedFromAdminTrash>(AssetClassHouseActionTypes.AssetClassHouseDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassHouse$ = this.actions$.pipe(
        ofType<AssetClassHouseOnServerRestored>(AssetClassHouseActionTypes.AssetClassHouseOnServerRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassHouseRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    restoreAdminAssetClassHouse$ = this.actions$.pipe(
        ofType<AssetClassHouseOnServerAdminRestored>(AssetClassHouseActionTypes.AssetClassHouseOnServerAdminRestored),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.restoreFromTrash(payload.id).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassHouseAdminRestored({ac: res.data}));
                })
            )
        }),
        map(() => {
            return this.hideActionLoadingDispatcher
        })
    )

    @Effect()
    flushTrash$ = this.actions$
        .pipe(
            ofType<AssetClassHouseTrashFlushed>(AssetClassHouseActionTypes.AssetClassHouseTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    duplicateHouse$ = this.actions$
        .pipe(
            ofType<AssetClassHouseDuplicate>(AssetClassHouseActionTypes.DuplicateAssetClass),
            mergeMap(({payload}) => {
                return this.service.duplicate(payload.id)
            }),
            map((response) => {
                return new AssetClassHouseCreated({AssetClassHouse: response.data})
            })
        )
}