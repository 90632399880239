import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { FacilityAddDialogComponent } from '../add/facility-add-dialog.component';
import { AssetClassType, AssetClassTypesService, Facility } from '../../../../../core/linked-tables';
import { AssetClassResidentialFacilityModel } from '../../../../../core/comparable';
import { LayoutUtilsService } from '../../../../../core/_base/crud';
import { AppState } from '../../../../../core/reducers';

@Component({
    selector: 'kt-alt-facility-edit',
    templateUrl: './facility-edit-dialog.component.html',
    styleUrls: ['./facility-edit-dialog.component.scss']
})

export class FacilityEditDialogComponent implements OnInit, OnDestroy {
    facilities: Facility[] = [];
    isBuildingFacility = false;
    assetClassTypeId = 0;
    assetClassTypes: AssetClassType[] = [];
    returnFacilities: any[];

    /**
     * Component constructor
     *
     * @param dialogRef
     * @param data
     * @param dialog: MatDialog
     * @param location
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param assetClassTypesService
     * @param cdRef
     */
    constructor(public dialogRef: MatDialogRef<FacilityEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public location: Location,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private store: Store<AppState>,
                private assetClassTypesService: AssetClassTypesService,
                private cdRef: ChangeDetectorRef) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.returnFacilities = [];
        this.assetClassTypeId = this.data.assetClassTypeId;

        if (this.data.currentFacilities.length > 0) {
            this.returnFacilities = this.data.currentFacilities;
        }

        this.getData();
    }

    ngOnDestroy(): void {
    }

    setSelectedFacilities(item): boolean {
        if (this.returnFacilities.length <= 0) {
            return false;
        } else {
            return this.returnFacilities.some(element => element.facility_id === item.id);
        }
    }

    updateCheckedOptions(item, $event) {
        if ($event.checked) {
            const obj: AssetClassResidentialFacilityModel = new AssetClassResidentialFacilityModel();
            obj.clear();
            obj.facility_id = item.id;
            obj.name = item.name;
            this.returnFacilities = [...this.returnFacilities, obj];
        } else {
            const index = this.returnFacilities.findIndex(element => element.facility_id === item.id);
            if (index !== -1) {
                this.returnFacilities.splice(index, 1);
            }
        }
    }

    addFacility() {
        const dialogRef = this.dialog.open(FacilityAddDialogComponent, {
            data: {is_building: this.isBuildingFacility, asset_class_type_id: this.assetClassTypeId},
            width: '340px',
            position: {top: '50px', right: '50px'}
        });
        dialogRef.afterClosed().subscribe(dRes => {
                this.getData();
            }
        );
    }

    close() {
        this.dialogRef.close({
            data: this.returnFacilities
        });
    }

    getData() {
        if (this.data.isBuildingFacility) {
            this.isBuildingFacility = this.data.isBuildingFacility;
            this.assetClassTypesService.getAllAssetClassTypes().subscribe((res) => {
                this.assetClassTypes = res.data.filter((assetClassType) => {
                    return assetClassType.building_facilities.length > 0;
                });
            });
        } else if (this.data.assetClassTypeId == 1) {
            this.assetClassTypesService.getAllAssetClassTypes().subscribe((res) => {
                this.assetClassTypes = res.data.filter((assetClassType) => {
                    return assetClassType.facilities.length > 0;
                });
            });
        } else if (this.data.assetClassTypeId == 3) {
            this.assetClassTypesService.getAllAssetClassTypes().subscribe((res) => {
                this.assetClassTypes = res.data.filter((assetClassType) => {
                    return assetClassType.office_facilities.length > 0;
                });
            });
        } else if (this.data.assetClassTypeId == 12) {
            this.assetClassTypesService.getAllAssetClassTypes().subscribe((res) => {
                this.assetClassTypes = res.data.filter((assetClassType) => {
                    return assetClassType.office_facilities_exclusive.length > 0;
                });
            });
        }
    }
}
