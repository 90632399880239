// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {PurposeMeasurementsService} from '../_services/purpose-measurement.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allPurposeMeasurementsLoaded} from '../_selectors/purpose-measurement.selectors';
// Actions
import {
    AllPurposeMeasurementsLoaded,
    AllPurposeMeasurementsRequested,
    PurposeMeasurementActionTypes,
    PurposeMeasurementsPageRequested,
    PurposeMeasurementsPageLoaded,
    PurposeMeasurementUpdated,
    PurposeMeasurementsPageToggleLoading,
    PurposeMeasurementDeleted,
    PurposeMeasurementOnServerCreated,
    PurposeMeasurementCreated,
    PurposeMeasurementAdminRestored,
    PurposeMeasurementDeletedFromAdminTrash,
    PurposeMeasurementDeletedFromTrash,
    PurposeMeasurementOnServerAdminRestored,
    PurposeMeasurementOnServerRestored,
    PurposeMeasurementRestored,
    PurposeMeasurementTrashFlushed,
    PurposeMeasurementsActionToggleLoading
} from '../_actions/purpose-measurement.actions';

@Injectable()
export class PurposeMeasurementEffects {
    showPageLoadingDispatcher = new PurposeMeasurementsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PurposeMeasurementsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PurposeMeasurementsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllPurposeMeasurement$ = this.actions$
        .pipe(
            ofType<AllPurposeMeasurementsRequested>(PurposeMeasurementActionTypes.AllPurposeMeasurementsRequested),
            withLatestFrom(this.store.pipe(select(allPurposeMeasurementsLoaded))),
            filter(([action, isAllPurposeMeasurementLoaded]) => !isAllPurposeMeasurementLoaded),
            mergeMap(() => this.service.getAllPurposeMeasurements()),
            map(res => {
                return new AllPurposeMeasurementsLoaded({purposeMeasurements: res.data});
            })
        );

    @Effect()
    loadPurposeMeasurementPage$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementsPageRequested>(PurposeMeasurementActionTypes.PurposeMeasurementsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findPurposeMeasurements(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PurposeMeasurementsPageLoaded({
                    purposeMeasurements: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deletePurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementDeleted>(PurposeMeasurementActionTypes.PurposeMeasurementDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deletePurposeMeasurement(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updatePurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementUpdated>(PurposeMeasurementActionTypes.PurposeMeasurementUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updatePurposeMeasurement(payload.purposeMeasurement);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createPurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementOnServerCreated>(PurposeMeasurementActionTypes.PurposeMeasurementOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createPurposeMeasurement(payload.purposeMeasurement).pipe(
                    tap(res => {
                        this.store.dispatch(new PurposeMeasurementCreated({purposeMeasurement: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushPurposeMeasurementTrash$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementTrashFlushed>(PurposeMeasurementActionTypes.PurposeMeasurementTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementOnServerRestored>(PurposeMeasurementActionTypes.PurposeMeasurementOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PurposeMeasurementRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminPurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementOnServerAdminRestored>(PurposeMeasurementActionTypes.PurposeMeasurementOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PurposeMeasurementAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashPurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementDeletedFromTrash>(PurposeMeasurementActionTypes.PurposeMeasurementDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashPurposeMeasurement$ = this.actions$
        .pipe(
            ofType<PurposeMeasurementDeletedFromAdminTrash>(PurposeMeasurementActionTypes.PurposeMeasurementDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: PurposeMeasurementsService, private store: Store<AppState>) {
    }
}
