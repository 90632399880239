// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {QosDegradationsService} from '../_services/qos-degradation.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allQosDegradationsLoaded} from '../_selectors/qos-degradation.selectors';
// Actions
import {
    AllQosDegradationsLoaded,
    AllQosDegradationsRequested,
    QosDegradationActionTypes,
    QosDegradationsPageRequested,
    QosDegradationsPageLoaded,
    QosDegradationUpdated,
    QosDegradationsPageToggleLoading,
    QosDegradationDeleted,
    QosDegradationOnServerCreated,
    QosDegradationCreated,
    QosDegradationAdminRestored,
    QosDegradationDeletedFromAdminTrash,
    QosDegradationDeletedFromTrash,
    QosDegradationOnServerAdminRestored,
    QosDegradationOnServerRestored,
    QosDegradationRestored,
    QosDegradationTrashFlushed,
    QosDegradationsActionToggleLoading
} from '../_actions/qos-degradation.actions';

@Injectable()
export class QosDegradationEffects {
    showPageLoadingDispatcher = new QosDegradationsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new QosDegradationsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new QosDegradationsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllQosDegradations$ = this.actions$
        .pipe(
            ofType<AllQosDegradationsRequested>(QosDegradationActionTypes.AllQosDegradationsRequested),
            withLatestFrom(this.store.pipe(select(allQosDegradationsLoaded))),
            filter(([action, isAllQosDegradationsLoaded]) => !isAllQosDegradationsLoaded),
            mergeMap(() => this.service.getAllQosDegradations()),
            map(res => {
                return new AllQosDegradationsLoaded({qosDegradations: res.data});
            })
        );

    @Effect()
    loadQosDegradationsPage$ = this.actions$
        .pipe(
            ofType<QosDegradationsPageRequested>(QosDegradationActionTypes.QosDegradationsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findQosDegradations(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new QosDegradationsPageLoaded({
                    qosDegradations: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationDeleted>(QosDegradationActionTypes.QosDegradationDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteQosDegradation(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationUpdated>(QosDegradationActionTypes.QosDegradationUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateQosDegradation(payload.qosDegradation);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationOnServerCreated>(QosDegradationActionTypes.QosDegradationOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createQosDegradation(payload.qosDegradation).pipe(
                    tap(res => {
                        this.store.dispatch(new QosDegradationCreated({qosDegradation: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushQosDegradationTrash$ = this.actions$
        .pipe(
            ofType<QosDegradationTrashFlushed>(QosDegradationActionTypes.QosDegradationTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationOnServerRestored>(QosDegradationActionTypes.QosDegradationOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new QosDegradationRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationOnServerAdminRestored>(QosDegradationActionTypes.QosDegradationOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new QosDegradationAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationDeletedFromTrash>(QosDegradationActionTypes.QosDegradationDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashQosDegradation$ = this.actions$
        .pipe(
            ofType<QosDegradationDeletedFromAdminTrash>(QosDegradationActionTypes.QosDegradationDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: QosDegradationsService, private store: Store<AppState>) {
    }
}
