import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {
    AllRetailBuildingAdjustmentsLoaded,
    AllRetailBuildingAdjustmentsRequested,
    RetailBuildingAdjustmentActionToggleLoading,
    RetailBuildingAdjustmentAdminRestored,
    RetailBuildingAdjustmentCreated,
    RetailBuildingAdjustmentDeleted,
    RetailBuildingAdjustmentDeletedFromAdminTrash,
    RetailBuildingAdjustmentDeletedFromTrash,
    RetailBuildingAdjustmentOnServerAdminRestored,
    RetailBuildingAdjustmentOnServerCreated,
    RetailBuildingAdjustmentOnServerRestored,
    RetailBuildingAdjustmentRestored,
    RetailBuildingAdjustmentsActionTypes,
    RetailBuildingAdjustmentsPageLoaded,
    RetailBuildingAdjustmentsPageRequested,
    RetailBuildingAdjustmentsPageToggleLoading,
    RetailBuildingAdjustmentTrashFlushed,
    RetailBuildingAdjustmentUpdated
} from '../_actions/retail-building-adjustment.actions';
import {allRetailBuildingAdjustmentsLoaded} from '../_selectors/retail-building-adjustment.selectors';
import {RetailBuildingAdjustmentsService} from '../_services/retail-building-adjustment.service';

@Injectable()
export class RetailBuildingAdjustmentsEffects {
    constructor(private actions$: Actions,
                private service: RetailBuildingAdjustmentsService,
                private store: Store<AppState>) {
    }

    showPageLoadingDispatcher = new RetailBuildingAdjustmentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RetailBuildingAdjustmentActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RetailBuildingAdjustmentActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAdjustment$ = this.actions$
        .pipe(
            ofType<AllRetailBuildingAdjustmentsRequested>(RetailBuildingAdjustmentsActionTypes.AllRetailBuildingAdjustmentsRequested),
            withLatestFrom(this.store.pipe(select(allRetailBuildingAdjustmentsLoaded))),
            filter(([action, isAllAdjustmentLoaded]) => !isAllAdjustmentLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllRetailBuildingAdjustmentsLoaded({items: res.data});
            })
        );

    @Effect()
    loadAdjustmentPage$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentsPageRequested>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new RetailBuildingAdjustmentsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentUpdated>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentOnServerCreated>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new RetailBuildingAdjustmentCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushAdjustmentTrash$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentTrashFlushed>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentOnServerRestored>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RetailBuildingAdjustmentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentOnServerAdminRestored>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RetailBuildingAdjustmentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentDeleted>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentDeletedFromTrash>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAdjustment$ = this.actions$
        .pipe(
            ofType<RetailBuildingAdjustmentDeletedFromAdminTrash>(RetailBuildingAdjustmentsActionTypes.RetailBuildingAdjustmentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
}