
import {RoofCoveringModel} from '../_models/roof-covering.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {RoofCoveringsState} from '../_reducers/roof-covering.reducers';
import * as fromRoofCovering from '../_reducers/roof-covering.reducers';
import {each} from 'lodash';

export const selectRoofCoveringsState = createFeatureSelector<RoofCoveringsState>('roof-coverings');

export const selectRoofCoveringById = (id: number) => createSelector(
    selectRoofCoveringsState,
    state => state.entities[id]
);

export const selectAllRoofCoverings = createSelector(
    selectRoofCoveringsState,
    fromRoofCovering.selectAll
);

export const selectAllRoofCoveringsIds = createSelector(
    selectRoofCoveringsState,
    fromRoofCovering.selectIds
);

export const allRoofCoveringsLoaded = createSelector(
    selectRoofCoveringsState,
    state => state.isAllRoofCoveringsLoaded
);


export const selectRoofCoveringsPageLoading = createSelector(
    selectRoofCoveringsState,
    state => state.listLoading
);

export const selectRoofCoveringsActionLoading = createSelector(
    selectRoofCoveringsState,
    state => state.actionLoading
);

export const selectLastCreatedRoofCoveringId = createSelector(
    selectRoofCoveringsState,
    state => state.lastCreatedRoofCoveringId
);

export const selectRoofCoveringsShowInitWaitingMessage = createSelector(
    selectRoofCoveringsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedRoofCoveringCount = createSelector(
    selectRoofCoveringsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedRoofCoveringCount = createSelector(
    selectRoofCoveringsState,
    (state) => state.totalAdminTrashed
);


export const selectRoofCoveringQueryResult = createSelector(
    selectRoofCoveringsState,
    state => {
        const items: RoofCoveringModel[] = [];
        each(state.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RoofCoveringModel[] = httpExtension.sortArray(items, state.lastQuery.sortField, state.lastQuery.sortOrder);

        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
);
