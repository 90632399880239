<table class="table" *ngIf="sizeInfo" [ngClass]="{'readonly': readonly}">
    <thead>
        <th>
            Room Type
        </th>
        <th colspan="2">
            Items
        </th>
        <th *ngFor="let floor of sizeInfo.floors" colspan="2">
            <ng-container *ngIf="schemeId == 1">
                <span *ngIf="floor < 0">
                    Lower Ground ({{floor}})
                </span>
                <span *ngIf="floor == 0">
                    Ground
                </span>
                <span *ngIf="floor > 0">
                    Floor {{floor}}
                </span>
            </ng-container>
            <ng-container *ngIf="schemeId == 2">
                <span *ngIf="floor < 0">
                    Lower Ground ({{floor}}) 
                </span>
                <span *ngIf="floor == 1">
                    Floor 1 (Ground) 
                </span>
                <span *ngIf="floor > 1">
                    Floor {{floor}}
                </span>
            </ng-container>
            <span>
                / {{sizeInfo.unit_of_area_measurement}}
            </span>
        </th>
        <th>
            Total / {{sizeInfo.unit_of_area_measurement}}
        </th>
    </thead>
    <tbody>
        <ng-container *ngFor="let data of sizeInfo.living_areas; let i = index">
            <ng-container *ngTemplateOutlet="simplerow;context:{
                data: data,
                floors: sizeInfo.floors,
                num: sizeInfo.living_areas.length,
                room: 'Living Area',
                is_top: i == 0,
                offset: 0,
                index: i + 1,
                type: 'living_areas'
            }"></ng-container>
        </ng-container>
        <ng-container *ngFor="let data of sizeInfo.standard_facilities; let i = index">
            <ng-container *ngTemplateOutlet="simplerow;context:{
                data: data,
                floors: sizeInfo.floors,
                num: sizeInfo.standard_facilities.length,
                room: 'Standard facilities',
                is_top: i == 0,
                offset: sizeInfo.living_areas.length,
                index: i + 1,
                type: 'standard_facilities'
            }"></ng-container>
        </ng-container>
        <ng-container *ngFor="let data of sizeInfo.external_areas; let i = index">
            <ng-container *ngTemplateOutlet="externalarea;context:{
                data: data,
                floors: sizeInfo.floors,
                num: sizeInfo.external_areas.length,
                room: 'External Areas',
                is_top: i == 0,
                type: 'external_areas',
                index: i + 1
            }"></ng-container>
        </ng-container>
        <tr>
            <th class="total_primary cell_room cell_last">
                Total area
            </th>
            <th colspan="2" class="total_secondary cell_item cell_last">
                Sa total
            </th>
            <td *ngFor="let floor of sizeInfo.floors" colspan="2" class="cell_last cell_floor_total">
                {{sizeInfo | mscolsum:floor}}
            </td>
            <td class="total_secondary_light cell_computed cell_last">
                {{sizeInfo | mstotal}}
            </td>
        </tr>
    </tbody>
</table>

<ng-template
    #simplerow
    let-room="room"
    let-is_top="is_top"
    let-num="num"
    let-data="data"
    let-floors="floors"
    let-type="type"
    let-index="index"
    let-offset="offset">
    <tr>
        <ng-container *ngIf="is_top">
            <th [rowSpan]="num" class="{{type}}_primary cell_room">
                {{room}}
            </th>
        </ng-container>
        <th class="{{type}}_secondary cell_item_name" [ngClass]="{'cell_last': index == num}">
            {{data.name}}
        </th>
        <th class="{{type}}_secondary cell_item_key" [ngClass]="{'cell_last': index == num}">
            S{{offset + index}}
        </th>
        <td *ngFor="let floor of floors" colspan="2" class="cell_input" [ngClass]="{'cell_last': index == num}" [style.width.%]="55 / sizeInfo.floors.length">
            <input 
                noNeg
                type="text"
                [value]="data.floors[floor].size"
                (focusout)="onFocusOut($event, type, data.name, floor)">
        </td>
        <td class="{{type}}_secondary_light cell_computed" [ngClass]="{'cell_last': index == num}">
            {{data | msrowsum}}
        </td>
    </tr>
</ng-template>

<ng-template #externalarea
    let-room="room"
    let-is_top="is_top"
    let-num="num"
    let-data="data"
    let-floors="floors"
    let-type="type"
    let-index="index"
    >
    <tr>
        <ng-container *ngIf="is_top">
            <th [rowSpan]="num" class="{{type}}_primary cell_room">
                {{room}}
            </th>
        </ng-container>
        <th class="{{type}}_secondary cell_item_name" [ngClass]="{'cell_last': index == num}">
            {{data.name}}
        </th>
        <th class="{{type}}_secondary cell_item_key" [ngClass]="{'cell_last': index == num}">
            {{data.key}}
        </th>
        <ng-container *ngFor="let floor of floors">
            <td class="cell_ea_input" [ngClass]="{'cell_last': index == num}" [style.width.%]="55 / (sizeInfo.floors.length * 2)">
                <input 
                    noNeg
                    type="text"
                    [value]="data.floors[floor].size"
                    (focusout)="onFocusOut($event, type, data.name, floor)">
            </td>
            <td class="cell_ea_output" [ngClass]="{'cell_last': index == num}" [style.width.%]="55 / (sizeInfo.floors.length * 2)">
                {{data.floors[floor].size * data.multiplier}}
            </td>
        </ng-container>
        <td class="{{type}}_secondary_light cell_computed" [ngClass]="{'cell_last': index == num}">
            {{data | msrowsum}}
        </td>
    </tr>
</ng-template>