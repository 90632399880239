<div class="mad-container">
    <ng-container *ngIf="!lowLevelTitle">
    <div ktPortletTitle  class="kt-margin-bottom-20-mobile" *ngIf="!disable">
        <h5 class="my-3 text-mad text-uppercase">LAND AREA</h5>
        <hr class="active">
    </div>
    </ng-container>
    <ng-container *ngIf="lowLevelTitle">
        <div ktPortletTitle class="col-lg-12 mb-0 multimedia-header item-center pl-0 ">
            <h5 class="my-3 text-black text-uppercase">
                {{lowLevelTitle}}
            </h5>
        </div>
    </ng-container>
    <div class="example-container mat-elevation-z0 mb-8" >
        <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container" *ngIf="!disable">
            <button
                *ngIf="buttonEnable$|async"
                    class="btn btn-primary"
                    color="primary"
                    (click)="addItem()"
                    matTooltip="Add a New Component"
                    type="button">Add Component
            </button>
        </div>
    
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef>Land Parcel Component</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index;">
                    {{item.order + 1}}
                </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="unit_area_measurement_name">
                <mat-header-cell *matHeaderCellDef>Area Unit Type</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.unit_area_measurement_name}}
                </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="surface">
                <mat-header-cell *matHeaderCellDef>Surface</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.surface | mask: 'separator':{thousandSeparator: ','} }}
                </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="limited_use">
                <mat-header-cell *matHeaderCellDef>Restricted / limited use</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{item.limited_use}}
                </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="3d_restriction">
                <mat-header-cell *matHeaderCellDef>3D restriction
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="$event.stopPropagation();"
                        container="body"
                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.3D_RESTRICTION.DESCRIPTION' | translate">help
                    </mat-icon>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div *ngIf="(item.underground_restriction > 0 || item.aerial_restriction > 0) && item.unit_length_measurement_id; else na">
                        {{(item.underground_restriction > 0 
                            ? 'Depth: ' + (item.underground_restriction | mask:'separator':{thousandSeparator: ','} ) + item.unit_length_measurement_acronym
                            : '')}}
                        {{item.underground_restriction > 0 && item.aerial_restriction > 0 ? ', ' : ''}}
                        {{item.aerial_restriction > 0 
                            ? 'Height: ' + (item.aerial_restriction | mask:'separator':{thousandSeparator: ','} ) + item.unit_length_measurement_acronym
                            : ''}}
                    </div>
                    <ng-template #na>
                        N/A
                    </ng-template>
                </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index;">
                    <button *ngIf="disable" mat-icon-button
                            color="primary"
                            matTooltip="Edit"
                            (click)="editItem(item, i)"
                            type="button">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>&nbsp;
                    <button *ngIf="!disable" mat-icon-button
                            color="primary"
                            matTooltip="Edit"
                            (click)="editItem(item, i)"
                            type="button">
                        <mat-icon>create</mat-icon>
                    </button>&nbsp;
                    <button *ngIf="!disable" mat-icon-button
                            color="warn"
                            matTooltip="Delete"
                            (click)="deleteItem(i)"
                            type="button">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>

    <div *ngIf="!disable" class="form" [formGroup]="form">
        <section class="row">
            <div class="col-lg-4 kt-margin-bottom-10-mobile mt-4">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select placeholder="Planning Status of the land parcel" formControlName="planning_status_id">
                        <mat-option *ngFor="let planingStatus of planingStatuses" [value]="planingStatus.id">{{planingStatus.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Planning Status of the land parcel
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <ng-container *ngIf="showOtherFields">
                <div class="col-lg-4 kt-margin-bottom-10-mobile mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Quality of soil and degradation" formControlName="qos_degradation_id">
                            <mat-option *ngFor="let qosDegradation of qosDegradations" [value]="qosDegradation.id">{{qosDegradation.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Quality of soil and degradation
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 kt-margin-bottom-10-mobile mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Environmental considerations" formControlName="env_considerations"/>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer"
                            (click)="$event.stopPropagation();"
                            container="body"
                            [ngbPopover]="'TOOLTIP.LAND_PARCEL.ENV_CONSIDERATION.DESCRIPTION' | translate">help 
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 kt-margin-bottom-10-mobile mt-4">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Other considerations" formControlName="other_considerations"/>
                    </mat-form-field>
                </div>

            </ng-container>
        </section>
    </div>
</div>