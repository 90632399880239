import {SubCategoryModel} from '../_models/sub-category.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {SubCategorysState} from '../_reducers/sub-category.reducers';
import * as fromSubCategory from '../_reducers/sub-category.reducers';
import {each} from 'lodash';

export const selectSubCategorysState = createFeatureSelector<SubCategorysState>('subCategories');

export const selectSubCategoryById = (topPropertyTypeId: number) => createSelector(
    selectSubCategorysState,
    topPropertyTypesState => topPropertyTypesState.entities[topPropertyTypeId]
);

export const selectAllSubCategorys = createSelector(
    selectSubCategorysState,
    fromSubCategory.selectAll
);

export const selectAllSubCategorysIds = createSelector(
    selectSubCategorysState,
    fromSubCategory.selectIds
);

export const allSubCategorysLoaded = createSelector(
    selectSubCategorysState,
    topPropertyTypesState => topPropertyTypesState.isAllSubCategorysLoaded
);


export const selectSubCategorysPageLoading = createSelector(
    selectSubCategorysState,
    topPropertyTypesState => topPropertyTypesState.listLoading
);

export const selectSubCategorysActionLoading = createSelector(
    selectSubCategorysState,
    topPropertyTypesState => topPropertyTypesState.actionLoading
);

export const selectLastCreatedSubCategoryId = createSelector(
    selectSubCategorysState,
    topPropertyTypesState => topPropertyTypesState.lastCreatedSubCategoryId
);

export const selectSubCategorysShowInitWaitingMessage = createSelector(
    selectSubCategorysState,
    topPropertyTypesState => topPropertyTypesState.showInitWaitingMessage
);


export const selectTrashedSubCategoryCount = createSelector(
    selectSubCategorysState,
    (topPropertyTypesState) => topPropertyTypesState.totalTrashed
);

export const selectAdminTrashedSubCategoryCount = createSelector(
    selectSubCategorysState,
    (topPropertyTypesState) => topPropertyTypesState.totalAdminTrashed
);


export const selectSubCategoryQueryResult = createSelector(
    selectSubCategorysState,
    topPropertyTypesState => {
        const items: SubCategoryModel[] = [];
        each(topPropertyTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SubCategoryModel[] = httpExtension.sortArray(items, topPropertyTypesState.lastQuery.sortField, topPropertyTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, topPropertyTypesState.totalCount, '', topPropertyTypesState.totalTrashed);
    }
);
