import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllDeparturesRequested, Departure, selectAllDepartures } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { ITpTemplateSelectedDeparture } from 'src/app/core/template/_models/tp-template.model';
import { AddAdModalComponent } from '../assumptions-and-departures/add-ad-modal/add-ad-modal.component';

type ItemData = Departure & {type: number};

@Component({
  selector: 'kt-departures-selection',
  templateUrl: './departures-selection.component.html',
  styleUrls: ['./departures-selection.component.scss']
})
export class DeparturesSelectionComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() selectedDepartures: ITpTemplateSelectedDeparture[];
  @Input() customDepartures: Departure[];
  departuresData: ItemData[] = [];
  _customDepartures: Departure[] = [];

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._customDepartures = this.customDepartures.map(el => Object.assign({}, el));
    this.store.dispatch(new AllDeparturesRequested());
    this.store.select(selectAllDepartures).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.departuresData = [];
      if (res) {
        let _noneDeparture = new Departure();
        _noneDeparture.clear();
        _noneDeparture.name = 'None';
        const _newnoneDeparture = Object.assign({}, _noneDeparture) as ItemData;
        _newnoneDeparture.type = 0;
        const isNoneSelected = this.selectedDepartures.find(el => el.departure_name == 'None' && el.departure_id == null && el.valuation_standard_id == null);
        if (isNoneSelected) {
          _newnoneDeparture.selected = true;
        }
        let departuresData: ItemData[] = res.map(item => {
          const selected = this.selectedDepartures.filter(el => el.type == 0).some(el => el.departure_id === item.id);
          const temp = Object.assign({}, item);
          temp.selected = selected;
          return {
            ...temp,
            type: 0
          } as ItemData;
        });
        let customDepartures: ItemData[] = this._customDepartures.map(item => {
          const selected = this.selectedDepartures.filter(el => el.type == 1).some(el => el.departure_id === item.id);
          const temp = Object.assign({}, item);
          temp.selected = selected;
          return {
            ...temp,
            type: 1
          } as ItemData;
        })
        this.departuresData = [_newnoneDeparture].concat(departuresData);
        this.departuresData = this.departuresData.concat(customDepartures);
      }
    })
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addItem() {
    const dialogRef = this.dialog.open(AddAdModalComponent, {
      data: {
        itemType: 'departure',
        title: 'Add Departure',
        placeholder: 'Departure'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const assumption = new Departure();
      assumption.clear();
      assumption.name = res.text;
      assumption.valuation_standard_id = res.valuationStandardId;
      assumption.valuation_standard_name = res.valuationStandardName;
      assumption.selected = true;
      this._customDepartures.push(assumption);
      const _tmpArray = Object.assign([], this.departuresData);
      const newDeparture = Object.assign({}, assumption) as ItemData;
      newDeparture.type = 1;
      _tmpArray.push(newDeparture);
      this.departuresData = _tmpArray;
    })
  }

  changeSelection(event: MatCheckboxChange, index: number) {
    if (event.checked) {
      this._selectingNone(index);
    }
    const _tmpArray = Object.assign([], this.departuresData);
    _tmpArray[index].selected = event.checked;
    this.departuresData = _tmpArray;
  }

  private _selectingNone(index) {
    if (index == 0) {
      this.departuresData = this.departuresData.map(el => {
        el.selected = false;
        return el;
      })
    } else {
      const _tmpArray = Object.assign([], this.departuresData);
      _tmpArray[0].selected = false;
      this.departuresData = _tmpArray;
    }
  }

  public getData(): {
    selected: ITpTemplateSelectedDeparture[],
    customDepartures: Departure[] 
  } {
    const selected = this.departuresData.filter(el => el.selected).map(el => ({
      departure_id: el.id,
      departure_name: el.name,
      valuation_standard_id: el.valuation_standard_id,
      type: el.type,
      valuation_standard_name: el.valuation_standard_name
    }))
    return {
      selected,
      customDepartures: this._customDepartures
    }
  }

}
