import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';
import {delay, take, takeUntil} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AllLandTenuresRequested,
    selectAllLandTenures,
    LandTenure,
} from '../../../../../../core/linked-tables';
import {
    AssetClassConsiderationModel,
} from '../../../../../../core/asset_class';
import {CountryData} from '../../../../admin-management/countries/countries-list/local-data';
import { AcSource } from 'src/app/core/comparable';
import { SourceComponent } from '../../../source/source.component';
import { isFinite, isNumber } from 'lodash';


@Component({
    selector: 'kt-tenure-consideration-edit-dialog',
    templateUrl: './tenure-consideration-edit.dialog.component.html',
    styleUrls: ['./tenure-consideration-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TenureConsiderationEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(SourceComponent, {static: false})
    private sourceComponent: SourceComponent;
    // searchable selection
    @ViewChild('singleSelectCurrency') singleSelectCurrency: MatSelect;

    // Public properties
    tenureConsideration: AssetClassConsiderationModel;
    form: UntypedFormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    landTenures: LandTenure[] = [];
    countriesData = CountryData.countriesWithNoDuplicateCurrency();
    tenureLengthMin = 1;
    tenureLengthMax = 999;

    /** control for the MatSelect filter keyword */
    public filterCtrlCountriesData: UntypedFormControl = new UntypedFormControl();
    /** list of models filtered by search keyword */
    public filteredCountriesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    currencySubject = new BehaviorSubject<string>('');

    protected _onDestroy = new Subject<void>();

    decisionsExtra: any[] = [
        {label: 'Yes', value: 1, default: false},
        {label: 'No', value: 0, default: true},
    ];

    // Private properties
    private componentSubscriptions: Subscription;
    private subscriptions: Subscription[] = [];
    sourceSubject = new BehaviorSubject<AcSource>(new AcSource());
    otherDates: string[] = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<TenureConsiderationEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.tenureConsideration) {
            this.tenureConsideration = Object.assign({}, this.data.tenureConsideration);
            if (this.data.tenureConsideration.source) {
                this.sourceSubject.next(this.data.tenureConsideration.source);
            }
        } else {
            this.tenureConsideration = new AssetClassConsiderationModel();
            this.tenureConsideration.clear();
        }
        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
    createForm() {
        this.form = this.fb.group({
            land_tenure_id: [this.tenureConsideration.land_tenure_id, Validators.required],
            tenure_length: [this.tenureConsideration.tenure_length == 0 ? 'In perpetuity' : this.tenureConsideration.tenure_length],
            currency: [this.tenureConsideration.currency, Validators.required],
            consideration: [this.tenureConsideration.consideration, Validators.required],
        });
        this.fetchSelects();

        this.form.controls.currency.valueChanges.subscribe(value => {
            this.currencySubject.next(value);
        });
    }

    fetchSelects() {
        this.store.dispatch(new AllLandTenuresRequested());
        
        const sourceSubscription = this.store.pipe(
            select(selectAllLandTenures))
            .subscribe(res => {
                this.landTenures = [];
                if (res) {
                    this.landTenures = res;
                }
            });
        this.subscriptions.push(sourceSubscription);
        
        this.filteredCountriesData.next(this.countriesData.slice());

        this.filterCtrlCountriesData.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterCountriesData();
            });
    }

    ngAfterViewInit() {
        this.setInitialValueCurrency();
    }

    /** ACTIONS */

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        
            this.hasFormErrors = true;
            return;
        }
        if (!this.sourceComponent.valid()) {
            this.hasFormErrors = true;
        }
        this.sourceComponent.updateSource(true);

        if (this.hasFormErrors) {
            return;
        }

        const selectedLandTenure = this.landTenures.find(item => item.id === controls.land_tenure_id.value);
        this.tenureConsideration.land_tenure_name = selectedLandTenure ? selectedLandTenure.name : '';
        this.tenureConsideration.land_tenure_id = controls.land_tenure_id.value;
        this.tenureConsideration.tenure_length = controls.tenure_length.value == 'In perpetuity' ? 0 : controls.tenure_length.value;
        this.tenureConsideration.currency = controls.currency.value;
        this.tenureConsideration.consideration = controls.consideration.value;
        this.tenureConsideration.source = this.sourceSubject.value;
        
        if (this.tenureConsideration.id) {
            this.tenureConsideration._isEditMode = true;
        }
        
        this.dialogRef.close({
            tenureConsideration: this.tenureConsideration,
        });
    }

    /**
     * Sets the initial value after the filteredSelectionModels are loaded initially
     */
     protected setInitialValueCurrency() {
        this.filteredCountriesData
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelectCurrency.compareWith = (a: string, b: string) => {
                    return a === b;
                };
            });
    }

    protected filterCountriesData() {
        if (!this.countriesData) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrlCountriesData.value;
        if (!search || search === '') {
            this.filteredCountriesData.next(this.countriesData.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the models
        this.filteredCountriesData.next(
            this.countriesData.filter(countryData => countryData.currencyCode.toLowerCase().indexOf(search) > -1)
        );
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.tenureConsideration && this.tenureConsideration.id) {
            return `Edit ground rent`;
        }
        return 'Add ground rent';
    }

    isFormValid() {
        return !this.form.invalid;
    }

    onYearChange(event) {
        let value = event.target.value;
        let numValue = Number(value);
        if (!isFinite(numValue)) {
            this.form.controls.tenure_length.setValue('In perpetuity');
            this.form.controls.tenure_length.updateValueAndValidity();
            return;
        }
        if (numValue <= 0) {
            this.form.controls.tenure_length.setValue('In perpetuity');
            this.form.controls.tenure_length.updateValueAndValidity();
            return;
        }
        this.form.controls.tenure_length.setValue(numValue);
        this.form.controls.tenure_length.updateValueAndValidity();
        return;
    }
}
