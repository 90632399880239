import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AssetClassTenure } from 'src/app/core/comparable';
import { TVOMRatesAndJustification, TVOMSubmethodType } from '../tvom-types';
import { CFMethodResult, HeadlineToEffectiveValueCalculatedEventData, SLMethodResult, TVOMMethodResult} from '../result-data-types';
import { CashFlowMethodComponent } from './cash-flow-method/cash-flow-method.component';
import { StraightlineMethodComponent } from './straight-line-method/straight-line-method.component';
import { TimeValueOfMoneyMethodComponent } from './time-value-of-money-method/time-value-of-money-method.component';
import { ConsiderationConversionMethod } from '../headline-to-effective-process-types';
import { CFMRatesAndJustifications, CFMReturnType, SelectedRent } from '../cf-method-types';

@Component({
  selector: 'kt-headline-to-effective',
  templateUrl: './headline-to-effective.component.html',
  styleUrls: ['./headline-to-effective.component.scss']
})
export class HeadlineToEffectiveComponent implements OnInit {
  @Input() refNum: string
  @Input() tpCurrency: string;
  @Input() consideration: AssetClassTenure;
  @Input() method: ConsiderationConversionMethod;
  @Input() ratesAndJustifications: TVOMRatesAndJustification;
  @Input() cfmRatesAndJustifications: CFMRatesAndJustifications;
  @Input() selected: SelectedRent;
  @Output() onValueComputed = new EventEmitter<HeadlineToEffectiveValueCalculatedEventData>();

  @ViewChild('timeValudOfMoneyMethod') tvomMethodComponent: TimeValueOfMoneyMethodComponent;
  @ViewChild('cashFlowMethod') cfMethodComponent: CashFlowMethodComponent;
  @ViewChild('straightLineMethod') slMethodComponent: StraightlineMethodComponent; 

  form: UntypedFormGroup;
  methods = ['Time value of money', 'Cash Flow', 'Straight line method'];
  subMethods = ['Target rate & capitalisation rate', 'Capitalisation rate', 'Target rate'];
  showSubmethods$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedMethod$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  selectedSubMethod$: BehaviorSubject<TVOMSubmethodType> = new BehaviorSubject(undefined);
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      method: new UntypedFormControl(this.method.main, Validators.required),
      subMethod: new UntypedFormControl(this.method.sub)
    });
    const methodControl = this.form.get('method');
    const subMethodControl = this.form.get('subMethod');
    const methodSub = methodControl.valueChanges.pipe(startWith(methodControl.value)).subscribe(val => {
      if (val === 0) {
        subMethodControl.setValidators([Validators.required]);
        subMethodControl.updateValueAndValidity();
        this.showSubmethods$.next(true);

        const submethodSub = subMethodControl.valueChanges.pipe(startWith(subMethodControl.value)).subscribe(val => {
          switch (val) {
            case 0: {
              this.selectedSubMethod$.next(TVOMSubmethodType.TargetRateAndCapitalisationRate);
              break;
            }
            case 1: {
              this.selectedSubMethod$.next(TVOMSubmethodType.CapitalisationRate);
              break;
            }
            case 2: {
              this.selectedSubMethod$.next(TVOMSubmethodType.TargetRate);
              break;
            }
          }
        });
        this.subscriptions.push(submethodSub);
      } else {
        subMethodControl.setValidators(null);
        subMethodControl.updateValueAndValidity();
        this.showSubmethods$.next(false);
      }

      this.selectedMethod$.next(val);
    });
    this.subscriptions.push(methodSub);

    
  }

  valueComputed(value: TVOMMethodResult | SLMethodResult | CFMethodResult) {
    this.onValueComputed.emit({
      result: value,
      methods: {
        main: this.form.get('method').value,
        sub: this.form.get('subMethod').value
      }
    });
  }

  isInValid(): boolean {
    let hasInvalidField = false;
    if (this.tvomMethodComponent && this.tvomMethodComponent.isInValid()) {
      hasInvalidField = true;
    }
    if (this.slMethodComponent && this.slMethodComponent.isInValid()) {
      hasInvalidField = true;
    }
    if (this.cfMethodComponent && this.cfMethodComponent.isInValid()) {
      hasInvalidField = true;
    }

    if (this.form.invalid) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });
      hasInvalidField = true;
    }

    return hasInvalidField;
  }

  getJustifications(): TVOMRatesAndJustification |CFMReturnType {
    if (this.tvomMethodComponent) {
      return this.tvomMethodComponent.getJustifications();
    }
    if (this.cfMethodComponent) {
      return this.cfMethodComponent.getJustifications();
    }
    return {
      capRate: undefined,
      capRateJustification: null,
      targetRate: undefined,
      targetRateJustification: null,
    }
  }
}
