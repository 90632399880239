// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {UnitAreaMeasurementActions, UnitAreaMeasurementActionTypes} from '../_actions/unit-area-measurement.actions';
// Models
import {UnitAreaMeasurement} from '../_models/unit-area-measurement.model';
import {QueryParamsModel} from '../../_base/crud';

export interface UnitAreaMeasurementsState extends EntityState<UnitAreaMeasurement> {
    isAllUnitAreaMeasurementsLoaded: boolean;
    queryRowsCount: number;
    queryResult: UnitAreaMeasurement[];
    lastCreatedUnitAreaMeasurementId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<UnitAreaMeasurement> = createEntityAdapter<UnitAreaMeasurement>();

export const initialUnitAreaMeasurementsState: UnitAreaMeasurementsState = adapter.getInitialState({
    isAllUnitAreaMeasurementsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedUnitAreaMeasurementId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function unitAreaMeasurementsReducer(state = initialUnitAreaMeasurementsState, action: UnitAreaMeasurementActions): UnitAreaMeasurementsState {
    switch (action.type) {
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedUnitAreaMeasurementId: undefined
            };
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerCreated:
            return {
                ...state
            };
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementCreated:
            return adapter.addOne(action.payload.unitAreaMeasurement, {
                ...state, lastCreatedUnitAreaMeasurementId: action.payload.unitAreaMeasurement.id
            });
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementUpdated:
            return adapter.updateOne(action.payload.partialunitAreaMeasurement, state);
        case UnitAreaMeasurementActionTypes.AllUnitAreaMeasurementsLoaded:
            return adapter.addAll(action.payload.unitAreaMeasurements, {
                ...state, isAllUnitAreaMeasurementsLoaded: true
            });
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageLoaded:
            return adapter.addMany(action.payload.unitAreaMeasurements, {
                ...initialUnitAreaMeasurementsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case UnitAreaMeasurementActionTypes.UnitAreaMeasurementTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
