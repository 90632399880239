// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {BuildingActions, BuildingActionTypes} from '../_actions/building.actions';
// Models
import {QueryParamsModel} from '../../_base/crud';
import {BuildingModel} from '../_models/building.model';

export interface BuildingsState extends EntityState<BuildingModel> {
    _isAllBuildingsLoaded: boolean;
    listLoading: boolean;
    totalCount: number;
    actionsLoading: boolean;
    lastCreatedBuildingId: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BuildingModel> = createEntityAdapter<BuildingModel>();

export const initialBuildingsState: BuildingsState = adapter.getInitialState({
    _isAllBuildingsLoaded: false,
    listLoading: false,
    totalCount: 0,
    actionsLoading: false,
    lastCreatedBuildingId: undefined,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function buildingsReducer(state = initialBuildingsState, action: BuildingActions): BuildingsState {
    switch (action.type) {
        case BuildingActionTypes.BuildingsPageRequested:
            return {
                ...state,
                _isAllBuildingsLoaded: false
            };
        case BuildingActionTypes.BuildingsPageLoaded:
            return adapter.addMany(action.payload.buildings, {
                ...initialBuildingsState,
                _isAllBuildingsLoaded: true,
                listLoading: false,
                totalCount: action.payload.totalCount,
                showInitWaitingMessage: false,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                lastQuery: action.payload.page
            });


        case BuildingActionTypes.BuildingsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBuildingId: undefined
            };
        }
        case BuildingActionTypes.BuildingActionToggleLoading: {
            return {
                ...state, actionsLoading: action.payload.isLoading
            };
        }

        case BuildingActionTypes.BuildingDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case BuildingActionTypes.BuildingRestored:
            return adapter.addOne(action.payload.building, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case BuildingActionTypes.BuildingDeletedFromTrash:
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case BuildingActionTypes.BuildingAdminRestored:
            return adapter.addOne(action.payload.building, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case BuildingActionTypes.BuildingDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case BuildingActionTypes.BuildingTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };

        case BuildingActionTypes.BuildingOnServerCreated:
            return {
                ...state
            };

        case BuildingActionTypes.BuildingCreated:
            return adapter.addOne(action.payload.building, {
                ...state, lastCreatedBuildingId: action.payload.building.id
            });

        case BuildingActionTypes.BuildingUpdated:
            return adapter.updateOne(action.payload.partialBuilding, state);

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
