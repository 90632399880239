// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AssetClassResidentialModel} from '../_models/asset-class-residential.model';
import {AssetClassTenure, AssetClassSizeModel, ResidentialExternalAreaModel} from '../';

export enum AssetClassResidentialActionTypes {
    AssetClassResidentialOnServerCreated = '[Edit AssetClassResidential Dialog] AssetClassResidential On Server Created',
    AssetClassResidentialCreated = '[Edit AssetClassResidential Dialog] AssetClassResidential Created',

    AssetClassResidentialOnServerUpdated = '[Edit AssetClassResidential Dialog] AssetClassResidential On Server Updated',
    AssetClassResidentialUpdated = '[Edit AssetClassResidential Dialog] AssetClassResidential Updated',

    AssetClassResidentials = '[AssetClassResidentials List] get all ',
    AssetClassResidentialsPageRequested = '[AssetClassResidentials List Page] AssetClassResidentials Page Requested',
    AssetClassMapPageRequested = '[AssetClassResidentials Map Page] AssetClassResidentials Page Requested',
    AssetClassResidentialsPageLoaded = '[AssetClassResidentials API] AssetClassResidentials Page Loaded',

    AssetClassResidentialOnServerRestored = '[AssetClassResidential Trash] AssetClassResidential On Server Restored',
    AssetClassResidentialRestored = '[AssetClassResidential Trash] AssetClassResidential Restored',
    AssetClassResidentialOnServerAdminRestored = '[AssetClassResidential Trash] AssetClassResidential On Server Admin Restored',
    AssetClassResidentialAdminRestored = '[AssetClassResidential Trash] AssetClassResidential Admin Restored',
    AssetClassResidentialDeletedFromTrash = '[AssetClassResidential Trash] AssetClassResidential deleted',
    AssetClassResidentialDeletedFromAdminTrash = '[AssetClassResidential Admin Trash] AssetClassResidential deleted',

    AssetClassResidentialTrash = 'AssetClassResidential Trashed',
    ApartmentTrashFlushed = 'Apartment Trash Flushed',
    OneAssetClassResidentialDeleted = '[AssetClassResidentials List Page] One AssetClassResidential Deleted',

    AssetClassResidentialsPageCancelled = '[AssetClassResidentials API] AssetClassResidentials Page Cancelled',
    AssetClassResidentialsPageToggleLoading = '[AssetClassResidentials] AssetClassResidentials Page Toggle Loading',
    AssetClassResidentialActionToggleLoading = '[AssetClassResidentials] AssetClassResidentials Action Toggle Loading',
    AssetClassResidentialGet = '[AssetClassResidential Object] AssetClassResidential Object Requested',

    AssetClassResidentialDuplicate = '[AssetClassResidential List] AssetClassResidential Duplicate',

    UpdatePreviouslyCreatedAssetClassResidentialId = '[AssetClassResidential Edit] Update Previously Created AssetClassResidential Id'
}

export class AssetClassResidentialOnServerCreated implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialOnServerCreated;

    constructor(public payload: {
        assetClassResidential: AssetClassResidentialModel,
        tenures: AssetClassTenure[],
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        apartmentResidentialExternalAreas: ResidentialExternalAreaModel[],
        documents: any[]
    }) {
    }
}

export class AssetClassResidentialOnServerUpdated implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialOnServerUpdated;

    constructor(public payload: {
        partialAssetClassResidential: Update<AssetClassResidentialModel>,
        assetClassResidential: AssetClassResidentialModel,
        tenures: AssetClassTenure[],
        sizes: AssetClassSizeModel[],
        fileList: any[],
        selectedFacilities: any[],
        apartmentResidentialExternalAreas: ResidentialExternalAreaModel[],
        documents: any[]
    }) {
    }
}

export class AssetClassResidentialGet implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentials;

    constructor(public payload: { assetClassResidentialId: number }) {
    }
}

export class AssetClassResidentialCreated implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialCreated;

    constructor(public payload: { assetClassResidential: AssetClassResidentialModel }) {
    }
}

export class AssetClassResidentialUpdated implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialUpdated;

    constructor(public payload: { assetClassResidential: AssetClassResidentialModel }) {
    }
}

export class AssetClassResidentialsPageRequested implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassMapPageRequested implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassMapPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassResidentialsPageLoaded implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialsPageLoaded;

    constructor(public payload: {
        assetClassResidentials: AssetClassResidentialModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class AssetClassResidentialsPageCancelled implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialsPageCancelled;
}

export class AssetClassResidentialsPageToggleLoading implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassResidentialActionToggleLoading implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassResidentialOnServerRestored implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassResidentialRestored implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialRestored;

    constructor(public payload: { assetClassResidential: AssetClassResidentialModel }) {
    }
}

export class AssetClassResidentialOnServerAdminRestored implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassResidentialAdminRestored implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialAdminRestored;

    constructor(public payload: { assetClassResidential: AssetClassResidentialModel }) {
    }
}

export class AssetClassResidentialDeletedFromAdminTrash implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassResidentialDeletedFromTrash implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialDeletedFromTrash;

    constructor(public payload: { assetClassResidentialId: number }) {
    }
}

export class AssetClassResidentialsTrashRequested implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ApartmentTrashFlushed implements Action {
    readonly type = AssetClassResidentialActionTypes.ApartmentTrashFlushed;

    constructor() {
    }
}

export class OneAssetClassResidentialDeleted implements Action {
    readonly type = AssetClassResidentialActionTypes.OneAssetClassResidentialDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassResidentialDuplicate implements Action {
    readonly type = AssetClassResidentialActionTypes.AssetClassResidentialDuplicate;
    constructor(public payload: {id: number}) {}
}

export class UpdatePreviouslyCreatedAssetClassResidentialId implements Action {
    readonly type = AssetClassResidentialActionTypes.UpdatePreviouslyCreatedAssetClassResidentialId;
    constructor() {}
}

export type AssetClassResidentialActions = AssetClassResidentialOnServerCreated
    | AssetClassResidentialOnServerUpdated
    | AssetClassResidentialCreated
    | AssetClassResidentialUpdated
    | AssetClassResidentialsPageRequested
    | AssetClassMapPageRequested
    | AssetClassResidentialsPageLoaded
    | AssetClassResidentialsPageCancelled
    | AssetClassResidentialsPageToggleLoading
    | AssetClassResidentialActionToggleLoading
    | AssetClassResidentialOnServerRestored
    | AssetClassResidentialRestored
    | AssetClassResidentialOnServerAdminRestored
    | AssetClassResidentialAdminRestored
    | ApartmentTrashFlushed
    | AssetClassResidentialDeletedFromTrash
    | AssetClassResidentialDeletedFromAdminTrash
    | OneAssetClassResidentialDeleted
    | AssetClassResidentialGet
    | AssetClassResidentialDuplicate
    | UpdatePreviouslyCreatedAssetClassResidentialId;

