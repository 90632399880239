// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {RicsStandardActions, RicsStandardActionTypes} from '../_actions/rics-standard.actions';
// Models
import {RicsStandard} from '../_models/rics-standard.model';
import {QueryParamsModel} from '../../_base/crud';

export interface RicsStandardsState extends EntityState<RicsStandard> {
    isAllRicsStandardsLoaded: boolean;
    queryRowsCount: number;
    queryResult: RicsStandard[];
    lastCreatedRicsStandardId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RicsStandard> = createEntityAdapter<RicsStandard>();

export const initialRicsStandardsState: RicsStandardsState = adapter.getInitialState({
    isAllRicsStandardsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedRicsStandardId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function ricsStandardsReducer(state = initialRicsStandardsState, action: RicsStandardActions): RicsStandardsState {
    switch (action.type) {
        case RicsStandardActionTypes.RicsStandardsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedRicsStandardId: undefined
            };
        case RicsStandardActionTypes.RicsStandardsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case RicsStandardActionTypes.RicsStandardOnServerCreated:
            return {
                ...state
            };
        case RicsStandardActionTypes.RicsStandardCreated:
            return adapter.addOne(action.payload.ricsStandard, {
                ...state, lastCreatedRicsStandardId: action.payload.ricsStandard.id
            });
        case RicsStandardActionTypes.RicsStandardUpdated:
            return adapter.updateOne(action.payload.partialricsStandard, state);
        case RicsStandardActionTypes.AllRicsStandardsLoaded:
            return adapter.addAll(action.payload.ricsStandards, {
                ...state, isAllRicsStandardsLoaded: true
            });
        case RicsStandardActionTypes.RicsStandardsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case RicsStandardActionTypes.RicsStandardsPageLoaded:
            return adapter.addMany(action.payload.ricsStandards, {
                ...initialRicsStandardsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case RicsStandardActionTypes.RicsStandardDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case RicsStandardActionTypes.RicsStandardRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case RicsStandardActionTypes.RicsStandardDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case RicsStandardActionTypes.RicsStandardAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case RicsStandardActionTypes.RicsStandardDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case RicsStandardActionTypes.RicsStandardTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
