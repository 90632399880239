import { v4 as uuidv4 } from 'uuid'
import { CalculationMeta, CostRevenue, HeadlineToEffectiveMeta, Lease, PropertyRevenue } from '../../types'
import { EffectiveRentUtil } from '../headline-rent'
import { calculationResult } from '../../_selectors'
import { defaultCalculationMeta } from '../../utils'

export function getSummary(data: any) {
  const costsSummary = getCostsSummary(data.targetProperty, data.leases, data.propertyRevenues, data.methods, data.valuation_date ? new Date(data.valuation_date) : null)
  return {
    costsSummary
  }
}

function getCostsSummary(targetProperty: any, leases: any[], propertyRevenues: any[], methods: any[], valuationDate: Date) {
  const totalLeasedArea = leases.reduce((acc, lease) => acc + lease.leased_area, 0)
  const acronym = targetProperty.size.unitAreaMeasurement.acronym
  const tpSize = targetProperty.size.value
  const leasesSummary = leases.map(item => mapLeaseSummary(item, {totalLeasedArea, tpSize}, methods, valuationDate))
  const propertyRevenuesSummary = propertyRevenues.map(item => mapPropertyRevenueSummary(item , {totalLeasedArea, tpSize}, methods, valuationDate))

  const items = [...leasesSummary, ...propertyRevenuesSummary]
  const totalValue = items.reduce((acc, item) => acc + item.value, 0)
  return {
    lists: items,
    result: {
      currency: targetProperty.country_currency,
      sizeAcronym: acronym,
      perUnit: totalValue / tpSize,
      value: totalValue
    }
  }
}

function mapPropertyRevenueSummary(lease: any, sizes: {totalLeasedArea: number, tpSize: number}, methods: any[], valuationDate: Date) {
  const item = mapRevenueAPIToModel(lease)
  const size = item.size === 'total_lease'
    ? sizes.totalLeasedArea
    : item.size === 'tp'
      ? sizes.tpSize
      : item.sizeInput
  const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
  const result = calculationResult(
    item,
    effectiveRent,
    sizes,
    valuationDate,
  )
  return {
    method: mapValuationMethod(item, methods),
    rentReview: item.rentReviewType ? 'Yes' : 'No',
    currency: item.currency,
    amount: item.rentInputAmount,
    rentBasis: mapRentBasis(item.rentBasis?.id) ,
    capitalisationRate: item.calculationMeta
      ? item.calculationMeta.capitalisationRate
      : null,
    value: result.value,
    name: item.name,
    classification: item.propertySubType
      ? item.propertySubType.name
      : 'N/A'
  }
}

function mapLeaseSummary(lease: any, sizes: {totalLeasedArea: number, tpSize: number}, methods: any[], valuationDate: Date) {
  const item = mapLeaseAPIToModel(lease)
  const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
  const result = calculationResult(
    item,
    effectiveRent,
    sizes,
    valuationDate,
  )
  return {
    method: mapValuationMethod(item, methods),
    rentReview: item.rentReviewType ? 'Yes' : 'No',
    currency: item.currency,
    amount: item.rentInputAmount,
    rentBasis: mapRentBasis(item.rentBasis?.id) ,
    capitalisationRate: item.calculationMeta
      ? item.calculationMeta.capitalisationRate
      : null,
    value: result.value,
    name: item.tenantName,
    classification: item.propertySubType
      ? item.propertySubType.name
      : 'N/A',
  }
}

function mapValuationMethod(item: Lease | PropertyRevenue, methods: {id: number, name: string}[]) {
  if (!item.calculationMeta) return null
  const method = methods.find(method => method.id === item.calculationMeta.methodId)
  if (!method) return null
  return method.name
}

function mapRentBasis(id: number): string {
  if (id === 1) return '/monthly'
  if (id === 2) return '/quarterly'
  if (id === 3) return '/yearly'
  return ''
}

export function mapRevenueAPIToModel(item: any): PropertyRevenue {
  return {
      id: item.id,
      kind: 'property-revenue',
      type: item.type,
      uid: uuidv4(),
      name: item.name,
      size: item.size,
      sizeInput: item.size_input,
      currency: item.currency,
      rentType: item.rent_type,
      propertySubType: item.property_sub_type
        ? {id: item.property_sub_type.id, name: item.property_sub_type.name}
        : null,
      startDate: new Date(item.start_date),
      duration: item.duration,
      durationType: item.duration_type,
      rentFreePeriod: item.rent_free_period,
      fittingOutPeriod: item.fitting_out_period,
      writeOffPeriodAfter: item.write_off_period,
      breakOptionAfter: item.break_option_after,
      capitalPayment: item.capital_payment,
      unexpiredLeaseTerms: item.unexpired_lease_terms,
      rentInputAmountType: item.rent_input_amount_type,
      rentInputAmount: item.rent_input_amount,
      rentBasis: item.rent_basis
        ? {id: item.rent_basis.id, name: item.rent_basis.name}
        : null,
      rentReviewType: item.rent_review_type
        ? {id: item.rent_review_type.id, name: item.rent_review_type.name}
        : null,
      rentReviewCycle: item.rent_review_cycle,
      rentReviewDetail: item.rent_review_detail,
      rentReviewDetailType: item.rent_review_detail_type,
      expensesInputAmountType: item.expenses_input_amount_type,
      recoverablePropertyManagement: item.recoverable_property_management,
      recoverableLeasingExpenses: item.recoverable_leasing_expenses,
      recoverableUtilities: item.recoverable_utilities,
      recoverableMaintenance: item.recoverable_maintenance,
      recoverableInsurance: item.recoverable_insurance,
      recoverableJanitorial: item.recoverable_janitorial,
      recoverablePropertyTaxes: item.recoverable_property_taxes,
      recoverableBusiness: item.recoverable_business,
      recoverableOthers: item.recoverable_others,
      nonRecoverablePropertyManagement: item.non_recoverable_property_management,
      nonRecoverableLeasingExpenses: item.non_recoverable_leasing_expenses,
      nonRecoverableUtilities: item.non_recoverable_utilities,
      nonRecoverableMaintenance: item.non_recoverable_maintenance,
      nonRecoverableInsurance: item.non_recoverable_insurance,
      nonRecoverableJanitorial: item.non_recoverable_janitorial,
      nonRecoverablePropertyTaxes: item.non_recoverable_property_taxes,
      nonRecoverableBusiness: item.non_recoverable_business,
      nonRecoverableOthers: item.non_recoverable_others,
      notes: item.notes,
      headlineToEffectiveMeta: item.headline_to_effective_meta
        ? headlineToEffectiveMeta(item.headline_to_effective_meta)
        : null,
      calculationMeta: item.calculation_meta
        ? calculationMeta(item.calculation_meta)
        : defaultCalculationMeta(),
      subLease: item.sub_lease
        ? mapSubLeaseAPIToModel(item.sub_lease)
        : null
  }
}

export function mapLeaseAPIToModel(item: any): Lease {
  return {
      id: item.id,
      kind: 'lease',
      type: item.type,
      uid: uuidv4(),
      tenantName: item.tenant_name,
      leasedArea: item.leased_area,
      currency: item.currency,
      rentType: item.rent_type,
      propertySubType: item.property_sub_type
        ? {id: item.property_sub_type.id, name: item.property_sub_type.name}
        : null,
      startDate: new Date(item.start_date),
      duration: item.duration,
      durationType: item.duration_type,
      rentFreePeriod: item.rent_free_period,
      fittingOutPeriod: item.fitting_out_period,
      writeOffPeriodAfter: item.write_off_period,
      breakOptionAfter: item.break_option_after,
      capitalPayment: item.capital_payment,
      unexpiredLeaseTerms: item.unexpired_lease_terms,
      rentInputAmountType: item.rent_input_amount_type,
      rentInputAmount: item.rent_input_amount,
      rentBasis: item.rent_basis
        ? {id: item.rent_basis.id, name: item.rent_basis.name}
        : null,
      rentReviewType: item.rent_review_type
        ? {id: item.rent_review_type.id, name: item.rent_review_type.name}
        : null,
      rentReviewCycle: item.rent_review_cycle,
      rentReviewDetail: item.rent_review_detail,
      rentReviewDetailType: item.rent_review_detail_type,
      expensesInputAmountType: item.expenses_input_amount_type,
      recoverablePropertyManagement: item.recoverable_property_management,
      recoverableLeasingExpenses: item.recoverable_leasing_expenses,
      recoverableUtilities: item.recoverable_utilities,
      recoverableMaintenance: item.recoverable_maintenance,
      recoverableInsurance: item.recoverable_insurance,
      recoverableJanitorial: item.recoverable_janitorial,
      recoverablePropertyTaxes: item.recoverable_property_taxes,
      recoverableBusiness: item.recoverable_business,
      recoverableOthers: item.recoverable_others,
      nonRecoverablePropertyManagement: item.non_recoverable_property_management,
      nonRecoverableLeasingExpenses: item.non_recoverable_leasing_expenses,
      nonRecoverableUtilities: item.non_recoverable_utilities,
      nonRecoverableMaintenance: item.non_recoverable_maintenance,
      nonRecoverableInsurance: item.non_recoverable_insurance,
      nonRecoverableJanitorial: item.non_recoverable_janitorial,
      nonRecoverablePropertyTaxes: item.non_recoverable_property_taxes,
      nonRecoverableBusiness: item.non_recoverable_business,
      nonRecoverableOthers: item.non_recoverable_others,
      notes: item.notes,
      headlineToEffectiveMeta: item.headline_to_effective_meta
        ? headlineToEffectiveMeta(item.headline_to_effective_meta)
        : null,
      calculationMeta: item.calculation_meta
        ? calculationMeta(item.calculation_meta)
        : defaultCalculationMeta(),
      subLease: item.sub_lease
        ? mapSubLeaseAPIToModel(item.sub_lease)
        : null
  }
}

function mapSubLeaseAPIToModel(item: any): CostRevenue {
  if (item.kind === 'lease') {
    return mapLeaseAPIToModel(item)
  } else if (item.kind === 'property-revenue') {
    return mapRevenueAPIToModel(item)
  }
  return null
}

export function calculationMeta(item: any): CalculationMeta {
  return {
    reversionaryYieldJustification: item.reversionary_yield_justification,
    marketRent: item.market_rent,
    activeBreakOption: item.active_break_option == 1,
    capitalisationRate: item.capitalisation_rate,
    capitalisationRateJustification: item.capitalisation_rate_justification,
    estimatedVoidPeriod: item.estimated_void_period,
    estimatedVoidPeriodJustification: item.estimated_void_period_justification,
    purchasersCost: item.purchaser_cost,
    methodId: item.method_id,
    timingId: item.timing_id,
    growthRatePerYear: item.growth_rate_per_year,
    allRiskYieldJustification: item.all_risk_yield_justification,
    allRiskYield: item.all_risk_yield,
    reversionaryYield: item.reversionary_yield,

    annualSinkingFund: item.annual_sinking_fund,
    annualSinkingFundJustification: item.annual_sinking_fund_justification,
    annualSinkingFund2ndTerm: item.annual_sinking_fund_2nd_term,
    annualSinkingFund2ndTermJustification: item.annual_sinking_fund_2nd_term_justification,
    taxRate: item.tax_rate,
    taxRateJustification: item.tax_rate_justification,
    subLeaseActiveBreakOption: item.sub_lease_active_break_option == 1,
    subLeaseEstimatedVoidPeriod: item.sub_lease_estimated_void_period,
    subLeaseEstimatedVoidPeriodJustification: item.sub_lease_estimated_void_period_justification,
    subLeaseGrowthRatePerYear: item.sub_lease_growth_rate_per_year,
    securedRent: item.secured_rent,
    estimatedRentForLand: item.estimated_rent_for_land,
    allRiskYieldForASF: item.all_risk_yield_for_asf,
    allRiskYieldForASFJustification: item.all_risk_yield_for_asf_justification,
    allRiskYieldOnPerpetuity: item.all_risk_yield_on_perpetuity,
    allRiskYieldOnPerpetuityJustification: item.all_risk_yield_on_perpetuity_justification,
    costOfRebuilding: item.cost_of_rebuilding,
    estimatedBuildingTerminableLife: item.estimated_building_terminable_life,
    exchangeRate: item.exchange_rate
  }
}

export function headlineToEffectiveMeta(item: any): HeadlineToEffectiveMeta {
  return {
        mainMethod: item.main_method,
        subMethod: item.sub_method,
        targetRate: item.target_rate,
        targetRateJustification: item.target_rate_justification,
        capitalRate: item.capital_rate,
        capitalRateJustification: item.capital_rate_justification,
        growthRate: item.growth_rate,
        growthRateJustification: item.growth_rate_justification,
        effectiveRentJustification: item.effective_rent_justification,
        main: item.main == 1,
        index: item.index
  }
}