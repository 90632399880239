<div class="mat-table-wrapper pl-4">
    <table class="table lmat-elevation-z8 table-striped">
        <thead class="thead-dark">
            <tr>
                <th scope="col" [ngStyle]="{'width': 100/(refNums.length + 1) + '%'}">
                    item
                </th>
                <th scope="col" *ngFor="let refNum of refNums" [style.width]="100/(refNums.length + 1) + '%'">
                    {{ isMulti ? refNum : 'Comparable' }}
                </th>
            </tr>
        </thead>
        <tbody *ngIf="considerationsInfo">
            <tr>
                <td>
                    Comparable Type
                </td>
                <td *ngFor="let refNum of refNums">
                    {{considerationsInfo[refNum].comparableType}}
                </td>
            </tr>
            <tr>
                <td>
                    Considerations
                </td>
                <td *ngFor="let refNum of refNums">
                    <ng-container *ngIf="considerationsInfo[refNum].considerations.length == 1">
                        {{ considerationsInfo[refNum].considerations[0].info }}
                    </ng-container>
                    <ng-container *ngIf="considerationsInfo[refNum].considerations.length > 1">
                        <mat-select 
                            [panelClass]="'overview-consideration-select'"
                            [value]="considerationsInfo[refNum].selectedConsideration"
                            (selectionChange)="onSelectionChange($event, refNum)"
                            [placeholder]="considerationsInfo[refNum].placeholder"
                            >
                            <mat-option *ngFor="let consideration of considerationsInfo[refNum].considerations"
                                [value]="consideration.id">
                                {{ consideration.info }}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td>
                    Source Type
                </td>
                <ng-container *ngFor="let refNum of refNums">
                    <ng-container *ngIf="considerationsInfo[refNum].sourceType == NOTSELECTED">
                        <td style="background-color: gray"></td>
                    </ng-container>
                    <ng-container *ngIf="considerationsInfo[refNum].sourceType != NOTSELECTED">
                        <td [ngClass]="{'selected-com': considerationsInfo[refNum].sourceType == UNKNOWN}">
                            {{ considerationsInfo[refNum].sourceType}}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Information
                </td>
                <ng-container *ngFor="let refNum of refNums">
                    <ng-container *ngIf="considerationsInfo[refNum].sourceInformation == NOTSELECTED">
                        <td style="background-color: gray"></td>
                    </ng-container>
                    <ng-container *ngIf="considerationsInfo[refNum].sourceInformation != NOTSELECTED">
                        <td [ngClass]="{'selected-com': considerationsInfo[refNum].sourceInformation == UNKNOWN}">
                            {{ considerationsInfo[refNum].sourceInformation}}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Credibility 
                </td>
                <ng-container *ngFor="let refNum of refNums">
                    <ng-container *ngIf="considerationsInfo[refNum].sourceCredibility == NOTSELECTED">
                        <td style="background-color: gray"></td>
                    </ng-container>
                    <ng-container *ngIf="considerationsInfo[refNum].sourceCredibility != NOTSELECTED">
                        <td [ngClass]="{'selected-com': considerationsInfo[refNum].sourceCredibility == UNKNOWN}">
                            {{ considerationsInfo[refNum].sourceCredibility}}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Description 
                </td>
                <ng-container *ngFor="let refNum of refNums">
                    <ng-container *ngIf="considerationsInfo[refNum].sourceDescription == NOTSELECTED">
                        <td style="background-color: gray"></td>
                    </ng-container>
                    <ng-container *ngIf="considerationsInfo[refNum].sourceDescription != NOTSELECTED">
                        <td [ngClass]="{'selected-com': considerationsInfo[refNum].sourceDescription == UNKNOWN}">
                            {{ considerationsInfo[refNum].sourceDescription}}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <td>
                    Source Files
                </td>
                <ng-container *ngFor="let refNum of refNums">
                    <ng-container *ngIf="!considerationsInfo[refNum].sourcePictures">
                        <td style="background-color: gray"></td>
                    </ng-container>
                    <ng-container *ngIf="considerationsInfo[refNum].sourcePictures">
                        <td [ngClass]="{'selected-com': considerationsInfo[refNum].sourcePictures.length == 0}">
                            <ng-container *ngIf="considerationsInfo[refNum].sourcePictures.length == 0">
                                Not Available
                            </ng-container>
                            <ng-container *ngIf="considerationsInfo[refNum].sourcePictures.length > 0">
                                <span class="pl-1 pr-3 cursor-pointer" *ngFor="let pic of considerationsInfo[refNum].sourcePictures; index as i" (click)="showThumbnail(considerationsInfo[refNum].sourcePictures, i)">
                                    <img alt="Pic"
                                        style="align-self: center;
                                            height: 34px;
                                            width: 34px;
                                            object-fit: cover;
                                            border-radius: 3px;"
                                        [src]="pic.url"
                                        (error)="onImageError($event)" />
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>