<div class="mat-table-wrapper">
    <mat-table [dataSource]="dataSource" class="table lmat-elevation-z8 table-striped">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'justify-content-center': item.key == 'inspected'}">
                <div>
                    {{item.name}}
                    <ul *ngIf="item.list != null">
                        <li *ngFor="let l of item.list">
                            {{l}}
                        </li>
                    </ul>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="building">
            <mat-header-cell *matHeaderCellDef>Building</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.building.value == null}">
                <mat-checkbox 
                    *ngIf="item.building.inspected && item.building.value != null" 
                    [checked]="item.building.value"
                    (change)="checkBoxChange($event, item, 'building')"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="basement">
            <mat-header-cell *matHeaderCellDef>Basement and subterranean features (e.g. air raid shelters)</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.basement.value == null}">
                <mat-checkbox 
                    *ngIf="item.basement.inspected && item.basement.value != null" 
                    [checked]="item.basement.value"
                    (change)="checkBoxChange($event, item, 'basement')"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="outbuilding">
            <mat-header-cell *matHeaderCellDef>Outbuilding, including garages and stores</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.outbuilding.value == null}">
                <mat-checkbox 
                    *ngIf="item.outbuilding.inspected && item.outbuilding.value != null" 
                    (change)="checkBoxChange($event, item, 'outbuilding')"
                    [checked]="item.outbuilding.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hardstanding">
            <mat-header-cell *matHeaderCellDef>Hardstanding</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.hardstanding.value == null}">
                <mat-checkbox 
                    *ngIf="item.hardstanding.inspected && item.hardstanding.value != null" 
                    (change)="checkBoxChange($event, item, 'hardstanding')"
                    [checked]="item.hardstanding.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="agriculture">
            <mat-header-cell *matHeaderCellDef>Agriculture Land</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.agriculture.value == null}">
                <mat-checkbox 
                    *ngIf="item.agriculture.inspected && item.agriculture.value != null" 
                    (change)="checkBoxChange($event, item, 'agriculture')"
                    [checked]="item.agriculture.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="landscaping">
            <mat-header-cell *matHeaderCellDef>Landscaping and/or natural woodland</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.landscaping.value == null}">
                <mat-checkbox 
                    *ngIf="item.landscaping.inspected && item.landscaping.value!= null" 
                    (change)="checkBoxChange($event, item, 'landscaping')"
                    [checked]="item.landscaping.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="water_course">
            <mat-header-cell *matHeaderCellDef>Water course or adjacent thereto</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.water_course.value == null}">
                <mat-checkbox 
                    *ngIf="item.water_course.inspected && item.water_course.value != null" 
                    (change)="checkBoxChange($event, item, 'water_course')"
                    [checked]="item.water_course.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="surrounding_area">
            <mat-header-cell *matHeaderCellDef>Surrounding areas</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.surrounding_area.value == null}">
                <mat-checkbox 
                    *ngIf="item.surrounding_area.inspected && item.surrounding_area.value!= null" 
                    (change)="checkBoxChange($event, item, 'surrounding_area')"
                    [checked]="item.surrounding_area.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="extent_of_inspection">
            <mat-header-cell *matHeaderCellDef>Extent of inspection of immediate vicinity</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.extent_of_inspection.value == null}">
                <mat-checkbox 
                    *ngIf="item.extent_of_inspection.inspected && item.extent_of_inspection.value != null" 
                    (change)="checkBoxChange($event, item, 'extent_of_inspection')"
                    [checked]="item.extent_of_inspection.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gardens">
            <mat-header-cell *matHeaderCellDef>Gardens</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.gardens.value == null}">
                <mat-checkbox 
                    *ngIf="item.gardens.inspected && item.gardens.value != null" 
                    (change)="checkBoxChange($event, item, 'gardens')"
                    [checked]="item.gardens.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="extent">
            <mat-header-cell *matHeaderCellDef>Extent</mat-header-cell>
            <mat-cell *matCellDef="let item" [ngClass]="{'na': item.extent.value == null}">
                <mat-checkbox 
                    *ngIf="item.extent.inspected && item.extent.value != null" 
                    (change)="checkBoxChange($event, item, 'extent')"
                    [checked]="item.extent.value"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" style="border-bottom: none;"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="{'gray': (i+1)%2==0, 'inspected-row': row.key == 'inspected'}"></mat-row>
    </mat-table>
</div>

<kt-checklist-table [inputData]="inputData.table2">
</kt-checklist-table>

<mat-form-field class="mat-form-field-fluid mt-5">
    <textarea matInput placeholder="Other notes or observations" row="3" [(ngModel)]="otherNotes"></textarea>
</mat-form-field>