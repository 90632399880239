import {BuildingType} from '../_models/building-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {BuildingTypesState} from '../_reducers/building-type.reducers';
import * as fromBuildingType from '../_reducers/building-type.reducers';
import {each} from 'lodash';

export const selectBuildingTypesState = createFeatureSelector<BuildingTypesState>('buildingTypes');

export const selectBuildingTypeById = (buildingTypeId: number) => createSelector(
    selectBuildingTypesState,
    buildingTypesState => buildingTypesState.entities[buildingTypeId]
);

export const selectAllBuildingTypes = createSelector(
    selectBuildingTypesState,
    fromBuildingType.selectAll
);

export const selectAllBuildingTypesIds = createSelector(
    selectBuildingTypesState,
    fromBuildingType.selectIds
);

export const allBuildingTypesLoaded = createSelector(
    selectBuildingTypesState,
    buildingTypesState => buildingTypesState.isAllBuildingTypesLoaded
);


export const selectBuildingTypesPageLoading = createSelector(
    selectBuildingTypesState,
    buildingTypesState => buildingTypesState.listLoading
);

export const selectBuildingTypesActionLoading = createSelector(
    selectBuildingTypesState,
    buildingTypesState => buildingTypesState.actionLoading
);

export const selectLastCreatedBuildingTypeId = createSelector(
    selectBuildingTypesState,
    buildingTypesState => buildingTypesState.lastCreatedBuildingTypeId
);

export const selectBuildingTypesShowInitWaitingMessage = createSelector(
    selectBuildingTypesState,
    buildingTypesState => buildingTypesState.showInitWaitingMessage
);

export const selectTrashedBuildingTypeCount = createSelector(
    selectBuildingTypesState,
    (buildingTypesState) => buildingTypesState.totalTrashed
);

export const selectAdminTrashedBuildingTypeCount = createSelector(
    selectBuildingTypesState,
    (buildingTypesState) => buildingTypesState.totalAdminTrashed
);


export const selectBuildingTypeQueryResult = createSelector(
    selectBuildingTypesState,
    buildingTypesState => {
        const items: BuildingType[] = [];
        each(buildingTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BuildingType[] = httpExtension.sortArray(items, buildingTypesState.lastQuery.sortField, buildingTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, buildingTypesState.totalCount, '', buildingTypesState.totalTrashed);
    }
);
