import { Injectable } from "@angular/core";
import { AssetClassTenure } from "../../comparable";
import { ConsiderationConversionProcess, CriterionModel} from "../_models/criterion.model";
import { TVOMMethodData, TVOMMethodVersion } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/tvom-types";
import { StraightLineMethodData } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/straight-line-method-types";
import { CashFlowMethodData, CashFlowMethodVersion, CFMEffectiveRent, CFMFirstTerm, CFMLastTerm, CFMMidTerm, CFMPreliminary } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/cf-method-types";

@Injectable()
export class ConsiderationCriterionService {

    // Initial
    addComparable(
        tpConsiderationId: number, 
        selectedTenure: number|null,
        tenures: AssetClassTenure[], 
        quote_currency: string
    ): [number, ConsiderationConversionProcess] { 
        const tenure = this._getSameTypeOfTenure(tpConsiderationId, tenures, selectedTenure);
        const isSameCurrency = tenure.currency === quote_currency;
        const conv = tenure.conversion.find(c => c.quote_currency === quote_currency);
        const total_consideration = isSameCurrency 
            ? tenure.total_consideration 
            : conv 
                ? (tenure.total_consideration * conv.exchange_rate) 
                : undefined;
        if (tpConsiderationId === 1) {
            if (tenure.tenure === 'freehold') {
                return [undefined, ConsiderationConversionProcess.FreeholdToLeasehold];
            } else if (tenure.tenure === 'leasehold') {
                if (tenure.rent_type === 'effective') {
                    return [total_consideration * 12, ConsiderationConversionProcess.NoProcess];
                } else if (tenure.rent_type === 'headline') {
                    return [undefined, ConsiderationConversionProcess.HeadlineToEffective];
                }
            }
        } else {
            if (tenure.tenure === 'freehold') {
                return [total_consideration, ConsiderationConversionProcess.NoProcess];
            } else if (tenure.tenure === 'leasehold') {
                if (tenure.rent_type === 'effective') {
                    return [undefined, ConsiderationConversionProcess.LeaseholdToFreehold];
                } else if (tenure.rent_type === 'headline') {
                    return [undefined, ConsiderationConversionProcess.HeadlineToEffectiveToLeaseholdToFreehold];
                }
            }
        }
        return [undefined, ConsiderationConversionProcess.NoProcess];
    }

    _getSameTypeOfTenure(tpConsiderationId: number, tenures: AssetClassTenure[], selectedTenure: number|null): AssetClassTenure {
        if (selectedTenure == null) {
            return tenures[0];
        }
        return tenures.find(t => t.id == selectedTenure);
        // if (tenures.length > 1) {
        //     if (tpConsiderationId === 1) {
        //         const tenure = tenures.find(t => t.tenure === "leasehold");
        //         return tenure;
        //     } else {
        //         const tenure = tenures.find(t => t.tenure === "freehold");
        //         return tenure;
        //     }
        // } else {
        //     return tenures[0];
        // }
    }

    _convertSingleNaNToUndefined(val: number): number {
        return Number.isNaN(val) ? undefined : val;
    }

    // Straigth line method calculations
    computeStraightLineMethod(consideration: AssetClassTenure, quote_currency: string): StraightLineMethodData {
        const isSameCurrency = consideration.currency === quote_currency;
        const conv = consideration.conversion.find(c => c.quote_currency === quote_currency);
        let total_consideration = isSameCurrency 
            ? consideration.total_consideration 
            : conv 
                ? (consideration.total_consideration * conv.exchange_rate) 
                : undefined;
        let cap_payment = isSameCurrency
            ? Number(consideration.cap_payment)
            : conv 
                ? (Number(consideration.cap_payment) * conv.exchange_rate) 
                : undefined;
        const headlineRent = total_consideration * 12;
        const receivedFor = Number(consideration.lease_duration) - Number(consideration.rent_free_period);
        const capitalValueOfHeadlineRent = headlineRent * receivedFor;
        const capitalValueOfInducements = capitalValueOfHeadlineRent - cap_payment;
        const spreadOver = Number(consideration.lease_duration) - Number(consideration.fitting_out_period);
        const effectiveRent = capitalValueOfInducements / spreadOver;
        const result = {
            headlineRent, receivedFor, capitalValueOfHeadlineRent, capitalValueOfInducements, spreadOver, effectiveRent
        };
        const rounded = this._roundSLMethod(result);
        return this._convertNanToUndefinedSLMethod(rounded);
    }

    _convertNanToUndefinedSLMethod(data: StraightLineMethodData): StraightLineMethodData {
        const res = Object.assign({}, data) as StraightLineMethodData;
        Object.entries(data).forEach(([field, value]) => {
            res[field] = Number.isNaN(value) ? undefined : value;
        });
        return res;
    }

    _roundSLMethod(val: StraightLineMethodData): StraightLineMethodData {
        const res = Object.assign({}, val) as StraightLineMethodData;
        Object.entries(val).forEach(([field, value]) => {
            res[field] = this._roundTo4Decimals(value);
        })
        return res;
    }

    // --- Cash Flow Method section --- //
    computeCFMethod(consideration: AssetClassTenure, tr: number, gr: number, methodVersion: CashFlowMethodVersion, quote_currency: string): CashFlowMethodData {
        const targetRate = Number(tr);
        const growthRate = Number(gr);
        let numberOfTerms = undefined;
        let remainingYears = undefined;
        let numberOfTermsReal = undefined;
        if (methodVersion == CashFlowMethodVersion.WriteOffIsLesserThanLease) {
            [numberOfTerms, numberOfTermsReal, remainingYears] = this._getNumOfTermsAndRemainingYears(Number(consideration.write_off_period), Number(consideration.rent_review));
        } else {
            [numberOfTerms, numberOfTermsReal, remainingYears] = this._getNumOfTermsAndRemainingYears(Number(consideration.lease_duration), Number(consideration.rent_review));
        }

        // Preliminary Calculation
        const preliminary = this._computerCFMPreliminary(consideration, targetRate, methodVersion, quote_currency);
        const firstTerm = this._computeCFMFirstTerm(consideration, targetRate);
        const lastTerm = this._computeCFMLastTerm(consideration, growthRate, targetRate, numberOfTerms, remainingYears);
        const midTerms = this._computeCFMMidTerms(consideration, numberOfTerms - 2, growthRate, targetRate);

        let factorValue = firstTerm.factorValue + lastTerm.factorValueOfRevisionaryRent;
        midTerms.forEach(mt => {
            factorValue += mt.factorValueOfRevisionaryRent;
        });

        const effectiveRent = preliminary.capitalValueOfInducements / factorValue;
        const effectiveRents: CFMEffectiveRent[] = [];
        let prevER = effectiveRent;
        for (let i = 0; i < (numberOfTerms - 1); i++) {
            let temp = 1 + (growthRate/100);
            const val = this._computeEffectiveRent(prevER, growthRate, Number(consideration.rent_review));
            const [suffix, prevSuffix] = this._getSuffixes(i + 1);
            const er: CFMEffectiveRent = {
                prev: prevER,
                value: val,
                termSuffix: suffix,
                prevSuffix: prevSuffix,
                isSelected: false,
            }
            effectiveRents.push(er);
            prevER = er.value;
        }

        const result: CashFlowMethodData = {
            numberOfTerms,
            numberOfTermsReal,
            remainingYears,
            preliminary,
            firstTerm,
            lastTerm,
            midTerms,
            factorValue,
            results: {
                effectiveRent,
                effectiveRents,
                isSelected: false,
            }
        };
        const rounded = this._roundCFMResult(result);
        const converted = this._convertNanToUndefinedCFM(rounded);
        return converted;
    }

    _computerCFMPreliminary(consideration: AssetClassTenure, targetRate: number, methodVersion: CashFlowMethodVersion, quote_currency: string): CFMPreliminary {
        const isSameCurrency = consideration.currency === quote_currency;
        const conv = consideration.conversion.find(c => c.quote_currency === quote_currency);
        const total_consideration = isSameCurrency 
            ? consideration.total_consideration
            : conv 
                ? (Number(consideration.total_consideration) * conv.exchange_rate) 
                : undefined;
        const capital_payment = isSameCurrency
            ? Number(consideration.cap_payment)
            : conv 
                ? (Number(consideration.cap_payment) * conv.exchange_rate)
                : undefined;
        const headlineRent = total_consideration * 12;
        let rentReceivedFor = 0;
        if (methodVersion === CashFlowMethodVersion.WriteOffIsLesserThanLease) {
            rentReceivedFor = Number(consideration.write_off_period) - Number(consideration.rent_free_period);
        } else {
            rentReceivedFor = Number(consideration.lease_duration) - Number(consideration.rent_free_period);
        }
        const yearsPurchaseOfRentReceivedFor = this._computeYearsPurchase(targetRate, rentReceivedFor);
        const presentValueOfRentFreePeriod = this._computePresentValue(targetRate, Number(consideration.rent_free_period));
        const capitalValueOfHeadlineRent = headlineRent * yearsPurchaseOfRentReceivedFor * presentValueOfRentFreePeriod;
        const capitalValueOfInducements = capitalValueOfHeadlineRent - capital_payment;
        const preliminary: CFMPreliminary = {
            headlineRent,
            rentReceivedFor,
            yearsPurchaseOfRentReceivedFor,
            presentValueOfRentFreePeriod,
            capitalValueOfHeadlineRent,
            capitalValueOfInducements,
        };
        return preliminary;
    }

    _computeCFMFirstTerm(consideration: AssetClassTenure, targetRate: number): CFMFirstTerm {
        const yp = this._computeYearsPurchase(targetRate, Number(consideration.rent_review));
        const pv = this._computePresentValue(targetRate, Number(consideration.fitting_out_period));
        const factor = yp * pv;
        const res: CFMFirstTerm = {
            yearsPurchaseOfRentReviewCycle: yp,
            presentValueOfFittingOutPeriod: pv,
            factorValue: factor
        }
        return res;
    }

    _computeCFMLastTerm(consideration: AssetClassTenure, growthRate: number, targetRate: number, currentTerm: number, lastTermPeriod: number): CFMLastTerm {
        const revision = this._computeRevisionToFutureValue(growthRate, Number(consideration.rent_review), currentTerm);
        const yp = this._computeYearsPurchase(targetRate, lastTermPeriod);
        const year = Number(consideration.rent_review) * (currentTerm - 1);
        const pv = this._computePresentValue(targetRate, year);
        const factor = revision * yp * pv;
        const [termSuffix, prevTermSuffix] = this._getSuffixes(currentTerm);
        const res: CFMLastTerm = {
            year,
            termSuffix,
            prevTermSuffix,
            revisionToFutureRentalValue: revision,
            yearsPurchaseOfLastTermPeriod: yp,
            presentValueOfRentReviewCycle: pv,
            factorValueOfRevisionaryRent: factor,
        }
        return res;
    }

    _computeCFMMidTerms(consideration: AssetClassTenure, terms: number, growthRate: number, targetRate: number): CFMMidTerm[] {
        const midTerms: CFMMidTerm[] = [];
        let i = 0;
        for (i; i < terms; i++) {
            const term = this._computeCFMMidTerm(consideration, i+2, growthRate, targetRate);
            midTerms.push(term);
        }
        return midTerms;
    }

    _computeCFMMidTerm(consideration: AssetClassTenure, currentTerm: number, growthRate: number, targetRate: number): CFMMidTerm {
        const revision = this._computeRevisionToFutureValue(growthRate, Number(consideration.rent_review), currentTerm);
        const yp = this._computeYearsPurchase(targetRate, Number(consideration.rent_review));
        const year = Number(consideration.rent_review) * (currentTerm - 1);
        const pv = this._computePresentValue(targetRate, year);
        const factor = revision * yp * pv;
        const [termSuffix, prevTermSuffix] = this._getSuffixes(currentTerm);
        const res: CFMMidTerm = {
            year,
            termSuffix,
            prevTermSuffix,
            revisionToFutureRentalValue: revision,
            presentValueOfRentReviewCycle: pv,
            yearsPurchaseOfRentReview: yp,
            factorValueOfRevisionaryRent: factor,
        }
        return res;
    }

    _getSuffixes(term: number): [string, string] {
        const r = term % 10;
        switch (r) {
        case 1:
            return ['st', 'th'];
        case 2:
            return ['nd', 'st'];
        case 3:
            return ['rd', 'nd'];
        case 4:
            return ['th', 'rd'];
        default:
            return ['th', 'th'];
        }
    }

    _computeYearsPurchase(rate: number, year: number): number {
        const rateInVal = rate / 100;
        let temp = 1 + rateInVal;
        temp = Math.pow(temp, -year);
        temp = 1 - temp;
        return temp / rateInVal;
    }

    _computePresentValue(rate: number, year: number): number {
        if (rate === null || rate === 0) {
            return NaN;
        }
        const rateInVal = rate / 100;
        let temp = 1 + rateInVal;
        return Math.pow(temp, -year);
    }

    _computeRevisionToFutureValue(rate: number, year: number, term: number): number {
        if (rate === null || rate === 0) {
            return NaN;
        }
        const rateVal = rate / 100;
        let temp = year * (term - 1);
        return Math.pow((1 + rateVal), temp);
    }

    _computeEffectiveRent(preER: number, growthRate: number, reviewCycle: number): number {
        if (growthRate === null) {
            return NaN;
        }
        const rateVal = growthRate / 100;
        let temp = Math.pow((1 + rateVal), reviewCycle);
        return preER * temp;
    }

    _roundCFMResult(val: CashFlowMethodData): CashFlowMethodData {
        const preliminary = Object.assign({}, val.preliminary) as CFMPreliminary;
        const firstTerm = Object.assign({}, val.firstTerm) as CFMFirstTerm;
        const lastTerm = Object.assign({}, val.lastTerm) as CFMLastTerm;
        const midTerms: CFMMidTerm[] = [];

        Object.entries(val.preliminary).forEach(([field, value]) => {
            preliminary[field] = this._roundTo4Decimals(value);
        });
        Object.entries(val.firstTerm).forEach(([field, value]) => {
            firstTerm[field] = this._roundTo4Decimals(value);
        })
        Object.entries(val.lastTerm).forEach(([field, value]) => {
            lastTerm[field] = typeof value === 'number' ? this._roundTo4Decimals(value) : value;
        });
        val.midTerms.forEach(mt => {
            const midTerm = Object.assign({}, mt) as CFMMidTerm;
            Object.entries(mt).forEach(([field, value]) => {
                midTerm[field] = typeof value === 'number' ? this._roundTo4Decimals(value) : value;
            });
            midTerms.push(midTerm);
        })

        let effectiveRent = this._roundTo4Decimals(val.results.effectiveRent);
        const effectiveRents: CFMEffectiveRent[] = [];
        val.results.effectiveRents.forEach(er => {
            const e = Object.assign({}, er) as CFMEffectiveRent;
            e.prev = this._roundTo4Decimals(er.prev);
            e.value = this._roundTo4Decimals(er.value);
            effectiveRents.push(e);
        })


        const res: CashFlowMethodData = {
            numberOfTerms: val.numberOfTerms,
            numberOfTermsReal: this._roundTo4Decimals(val.numberOfTermsReal),
            remainingYears: val.remainingYears,
            preliminary,
            firstTerm,
            lastTerm,
            midTerms,
            factorValue: this._roundTo4Decimals(val.factorValue),
            results: {
                effectiveRent,
                effectiveRents,
                isSelected: false,
            }
        }
        return res;
    }

    _convertNanToUndefinedCFM(val: CashFlowMethodData): CashFlowMethodData {
        const preliminary = Object.assign({}, val.preliminary) as CFMPreliminary;
        const firstTerm = Object.assign({}, val.firstTerm) as CFMFirstTerm;
        const lastTerm = Object.assign({}, val.lastTerm) as CFMLastTerm;
        const midTerms: CFMMidTerm[] = [];

        Object.entries(val.preliminary).forEach(([field, value]) => {
            preliminary[field] = Number.isNaN(value) ? undefined : value;
        });
        Object.entries(val.firstTerm).forEach(([field, value]) => {
            firstTerm[field] = Number.isNaN(value) ? undefined : value;
        })
        Object.entries(val.lastTerm).forEach(([field, value]) => {
            lastTerm[field] = typeof value === 'number' 
                ? Number.isNaN(value) ? undefined : value
                : value;
        });
        val.midTerms.forEach(mt => {
            const midTerm = Object.assign({}, mt) as CFMMidTerm;
            Object.entries(mt).forEach(([field, value]) => {
                midTerm[field] = typeof value === 'number' 
                    ? Number.isNaN(value) ? undefined : value
                    : value;
            });
            midTerms.push(midTerm);
        })

        let effectiveRent = Number.isNaN(val.results.effectiveRent) ? undefined : val.results.effectiveRent;
        const effectiveRents: CFMEffectiveRent[] = [];
        val.results.effectiveRents.forEach(er => {
            const e = Object.assign({}, er) as CFMEffectiveRent;
            e.prev = Number.isNaN(er.prev) ? undefined : er.prev;
            e.value = Number.isNaN(er.value) ? undefined : er.value;
            effectiveRents.push(e);
        })


        const res: CashFlowMethodData = {
            numberOfTerms: val.numberOfTerms,
            numberOfTermsReal: val.numberOfTermsReal,
            remainingYears: val.remainingYears,
            preliminary,
            firstTerm,
            lastTerm,
            midTerms,
            factorValue: Number.isNaN(val.factorValue) ? undefined : val.factorValue,
            results: {
                effectiveRent,
                effectiveRents,
                isSelected: false,
            }
        }
        return res;
    }

    _getNumOfTermsAndRemainingYears(writeOffOrLease: number, rentReview: number): [number, number, number] {
        const realValue = writeOffOrLease / rentReview;
        const numberOfTerms = Math.ceil(realValue);
        const remainder = writeOffOrLease % rentReview;
        if (remainder === 0) {
            return [numberOfTerms, realValue, rentReview];
        }
        return [numberOfTerms, realValue, remainder];
    }

    // --- Value conversion --- //
    convertLeaseholdToFreehold(allRiskYield: number, considerationPerMonth: number): number {
        const ary = allRiskYield / 100;
        const result = considerationPerMonth * 12 / ary;
        return this._roundTo4Decimals(result);
    }

    convertHeadlineLeaseholdToFreehold(allRiskYield: number, effectiveRentPerYear: number): number {
        const ary = allRiskYield / 100;
        const result = effectiveRentPerYear / ary;
        return this._roundTo4Decimals(result);
    }

    convertFreeholdToLeaseHold(allRiskYield: number, capitalValue: number): number {
        const ary = allRiskYield / 100;
        const result = (capitalValue * ary) / 12;
        return this._roundTo4Decimals(result) * 12;
    }

    _convertNaNToUndefined(val: TVOMMethodData): TVOMMethodData {
        const res = Object.assign({}, val) as TVOMMethodData;
        Object.entries(val).forEach(([field, value]) => {
            res[field] = Number.isNaN(value) ? undefined : value;
        });
        return res;
    }

    _roundTVOMResult(val: TVOMMethodData): TVOMMethodData {
        const res = Object.assign({}, val) as TVOMMethodData;
        Object.entries(val).forEach(([field, value]) => {
            res[field] = this._roundTo4Decimals(value);
        })
        return res;
    }

    _roundTo4Decimals(val: number): number {
        return Math.round(val * 10000) / 10000;
    }

    _computeYearsPurchaseAt6Percent(rate: number, year: number): number {
        const rateInVal = rate / 100;
        let temp = 1 + rateInVal;
        temp = Math.pow(temp, -year);
        temp = 1 - temp;
        return temp / rateInVal;
    }

    _computePresentValueAt6Percent(rate: number, year: number): number {
        if (rate === null || rate === 0) {
            return NaN;
        }
        const rateInVal = rate / 100;
        let temp = 1 + rateInVal;
        return Math.pow(temp, -year);
    }

    // Helpers
    _calculationBasedOnRentBasis(total_consideration: number, consideration: AssetClassTenure, size: number) {
        let amount = 0;
        if (consideration.rent_input_amount_type === 'total') {
            amount = total_consideration
        } else if (consideration.rent_input_amount_type === 'per_units') {
            const sizeUnit = size ? size : 0
            amount = total_consideration * sizeUnit
        }


        if (consideration.rent_basis_id === 1) {
            return amount * 12
        } else if (consideration.rent_basis_id === 2) {
            return amount * 4
        } else {
            return amount
        }
    }
}