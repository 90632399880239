// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'kt-tp-document-info-edit-dialog',
    templateUrl: './tp-document-info-edit-dialog.component.html'
})
export class TpDocumentInfoEditDialogComponent implements OnInit {

    formGroup: UntypedFormGroup;
    ref_value = 0;
    decsription = '';

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>
     * @param data: any
     */
    constructor(public dialogRef: MatDialogRef<TpDocumentInfoEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private translate: TranslateService) {
        if (data.client_ref) {
            this.ref_value = data.client_ref;
        }
        this.decsription = data.descr;
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.createForm();
    }

    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }


    /**
     * Close dialog with true result
     */
    onYesClick(): void {
        setTimeout(() => {
            let returnDescription;
            if (this.decsription === this.formGroup.controls.description.value) {
                returnDescription = null;
            } else {
                returnDescription = this.formGroup.controls.description.value;
            }
            this.dialogRef.close({
                ref: this.formGroup.controls.ref_control.value,
                description: returnDescription,
            });
        }, 500);
    }

    createForm() {
        this.formGroup = this.fb.group({
            ref_control: [this.ref_value],
            description: [this.decsription]
        });
    }
}
