// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {TypeOfRegistrationActions, TypeOfRegistrationActionTypes} from '../_actions/type-of-registration.actions';
import {TypeOfRegistration} from '../_models/type-of-registration.model';

export interface TypeOfRegistrationsState extends EntityState<TypeOfRegistration> {
    isAllTypeOfRegistrationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: TypeOfRegistration[];
    lastCreatedTypeOfRegistrationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TypeOfRegistration> = createEntityAdapter<TypeOfRegistration>();

export const initialTypeOfRegistrationsState: TypeOfRegistrationsState = adapter.getInitialState({
    isAllTypeOfRegistrationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedTypeOfRegistrationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function typeOfRegistrationsReducer(state = initialTypeOfRegistrationsState, action: TypeOfRegistrationActions): TypeOfRegistrationsState {
    switch (action.type) {
        case TypeOfRegistrationActionTypes.TypeOfRegistrationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTypeOfRegistrationId: undefined
            };
        case TypeOfRegistrationActionTypes.TypeOfRegistrationsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerCreated:
            return {
                ...state
            };
        case TypeOfRegistrationActionTypes.TypeOfRegistrationCreated:
            return adapter.addOne(action.payload.typeOfRegistration, {
                ...state, lastCreatedTypeOfRegistrationId: action.payload.typeOfRegistration.id
            });
        case TypeOfRegistrationActionTypes.TypeOfRegistrationUpdated:
            return adapter.updateOne(action.payload.partialTypeOfRegistration, state);
        case TypeOfRegistrationActionTypes.AllTypeOfRegistrationsLoaded:
            return adapter.addAll(action.payload.typeOfRegistrations, {
                ...state, isAllTypeOfRegistrationsLoaded: true
            });
        case TypeOfRegistrationActionTypes.TypeOfRegistrationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case TypeOfRegistrationActionTypes.TypeOfRegistrationsPageLoaded:
            return adapter.addMany(action.payload.typeOfRegistrations, {
                ...initialTypeOfRegistrationsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case TypeOfRegistrationActionTypes.TypeOfRegistrationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case TypeOfRegistrationActionTypes.TypeOfRegistrationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case TypeOfRegistrationActionTypes.TypeOfRegistrationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case TypeOfRegistrationActionTypes.TypeOfRegistrationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case TypeOfRegistrationActionTypes.TypeOfRegistrationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case TypeOfRegistrationActionTypes.TypeOfRegistrationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
