import {
    AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges,
    SimpleChanges, OnDestroy
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LayoutUtilsService} from '../../../../../core/_base/crud';
// import { AuditTrailParticipantModel } from '../../../../../core/assignment';
import {LinkEditDialogComponent} from '../link-edit/link-edit.dialog.component';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Link} from '../../../../../core/asset_class';

@Component({
    selector: 'kt-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss']
})

export class LinkListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    displayedColumns = ['title', 'description', 'url', 'actions'];
    @Input() links: Link[] = [];
    @Input() readOnly: boolean;
    @Input() atLeastOneParticipant$: BehaviorSubject<boolean>;
    @Input() linkType: number;

    dataSource = new MatTableDataSource([]);
    _toeSelected = false;
    _agencyName: string;
    _clientName: string;
    trashed: number;
    private subscription: Subscription;

    @ViewChild(MatSort) matSort: MatSort;

    // Selection
    selection = new SelectionModel<Link>(true, []);

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private layoutUtilsService: LayoutUtilsService,
                private cdRef: ChangeDetectorRef,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.dataSource.data = this.links;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
    ngAfterViewInit() {
        // this.dataSource.sort = this.matSort;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    addLink() {
        const newAuditTrailParticipant = new Link(this.linkType);
        this.editLink(newAuditTrailParticipant, 0);
    }

    editLink(_link: Link, index: number) {
        const dialogRef = this.dialog.open(LinkEditDialogComponent, {
            data: {
                link: _link,
            }
        });
        dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
                if (res.link && res.link.id) {
                    const links = Object.assign([], this.links);
                    links[index] = res.link;
                    this.links = links;
                } else {
                    this.links = [...this.links, res.link];
                }
                this.dataSource.data = this.links;
            }
        );
    }

    deleteLink(link: Link, index: number) {
        const _title = 'Are you sure?';
        const _description: string = 'The Matterport link "' + link.title + '" will be deleted';
        const _waitDesciption = 'Matterport link deleting';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const links = Object.assign([], this.links);
            links.splice(index, 1);
            this.links = links;
            this.dataSource.data = this.links;
        });
    }
}
