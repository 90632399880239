// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'kt-delete-entity-with-descr-dialog',
	templateUrl: './delete-entity-with-descr-dialog.component.html'
})
export class DeleteEntityWithDescrDialogComponent implements OnInit {
	// Public properties
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	inputDescriptionForUpdate = new UntypedFormControl('');

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityWithDescrDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<DeleteEntityWithDescrDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick() {
		if (this.inputDescriptionForUpdate.value.length === 0) {
			return;
		}

		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		setTimeout(() => {
			this.dialogRef.close(this.inputDescriptionForUpdate.value); // Keep only this row
		}, 500);
	}
}
