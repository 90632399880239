<div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
    <div *ngIf="(isInProgress$ | async)" class="mr-2">
        <div style="margin-bottom: 14px;">Uploading... {{ progress$ | async }}%</div>
    </div>

    <div class="error" *ngIf="(hasFailed$ | async)" class="mr-2">
        Error: {{ error$ | async }}
    </div>

    <div *ngIf="(isInProgress$ | async)" class="mr-2">
        <span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">Cancel Upload</span>
    </div>
    <div>
        <span class="btn btn-primary"
            color="primary"
            mat-raised-button
            (click)="addFile()">
            {{addButtonLabel}}
        </span>
        <input #fileInput 
            type="file" 
            style="display: none;"
            multiple
            accept="{{fileType}}"
            (change)="uploadFile($event)">
    </div>
</div>