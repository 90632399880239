// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {UnitAreaMeasurement} from '../_models/unit-area-measurement.model';

export enum UnitAreaMeasurementActionTypes {

    AllUnitAreaMeasurementsRequested = '[UnitAreaMeasurements Home Page] All UnitAreaMeasurements Requested',
    AllUnitAreaMeasurementsLoaded = '[UnitAreaMeasurements API] All UnitAreaMeasurements Loaded',

    UnitAreaMeasurementOnServerCreated = '[Edit UnitAreaMeasurement Dialog] UnitAreaMeasurement On Server Created',
    UnitAreaMeasurementCreated = '[Edit UnitAreaMeasurements Dialog] UnitAreaMeasurements Created',
    UnitAreaMeasurementUpdated = '[Edit UnitAreaMeasurement Dialog] UnitAreaMeasurement Updated',
    UnitAreaMeasurementDeleted = '[UnitAreaMeasurements List Page] UnitAreaMeasurement Deleted',

    UnitAreaMeasurementsPageRequested = '[UnitAreaMeasurements List Page] UnitAreaMeasurements Page Requested',
    UnitAreaMeasurementsPageLoaded = '[UnitAreaMeasurements API] UnitAreaMeasurements Page Loaded',
    UnitAreaMeasurementsPageCancelled = '[UnitAreaMeasurements API] UnitAreaMeasurements Page Cancelled',

    UnitAreaMeasurementsPageToggleLoading = '[UnitAreaMeasurements page] UnitAreaMeasurements Page Toggle Loading',
    UnitAreaMeasurementsActionToggleLoading = '[UnitAreaMeasurements] UnitAreaMeasurements Action Toggle Loading',

    // trash system

    UnitAreaMeasurementOnServerRestored = '[UnitAreaMeasurement Trash] UnitAreaMeasurement On Server Restored',
    UnitAreaMeasurementRestored = '[UnitAreaMeasurement Trash] UnitAreaMeasurement Restored',

    UnitAreaMeasurementOnServerAdminRestored = '[UnitAreaMeasurement Admin Trash] UnitAreaMeasurement On Server Restored',
    UnitAreaMeasurementAdminRestored = '[UnitAreaMeasurement Admin Trash] UnitAreaMeasurement Restored',

    UnitAreaMeasurementDeletedFromTrash = '[UnitAreaMeasurement Trash] UnitAreaMeasurement deleted',
    UnitAreaMeasurementDeletedFromAdminTrash = '[UnitAreaMeasurement Admin Trash] UnitAreaMeasurement deleted',

    UnitAreaMeasurementTrash = 'UnitAreaMeasurement Trashed',
    UnitAreaMeasurementTrashFlushed = 'UnitAreaMeasurement Trash Flushed',
}

export class UnitAreaMeasurementOnServerCreated implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerCreated;

    constructor(public payload: { unitAreaMeasurement: UnitAreaMeasurement}) {
    }
}

export class UnitAreaMeasurementCreated implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementCreated;

    constructor(public payload: { unitAreaMeasurement: UnitAreaMeasurement }) {
    }
}

export class UnitAreaMeasurementUpdated implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementUpdated;

    constructor(public payload: {
        partialunitAreaMeasurement: Update<UnitAreaMeasurement>,
        unitAreaMeasurement: UnitAreaMeasurement
    }) {
    }
}

export class UnitAreaMeasurementDeleted implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class UnitAreaMeasurementsPageRequested implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class UnitAreaMeasurementsPageLoaded implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageLoaded;

    constructor(public payload: { unitAreaMeasurements: UnitAreaMeasurement[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class UnitAreaMeasurementsPageCancelled implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageCancelled;
}

export class AllUnitAreaMeasurementsRequested implements Action {
    readonly type = UnitAreaMeasurementActionTypes.AllUnitAreaMeasurementsRequested;
}

export class AllUnitAreaMeasurementsLoaded implements Action {
    readonly type = UnitAreaMeasurementActionTypes.AllUnitAreaMeasurementsLoaded;

    constructor(public payload: { unitAreaMeasurements: UnitAreaMeasurement[] }) {
    }
}

export class UnitAreaMeasurementsPageToggleLoading implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class UnitAreaMeasurementsActionToggleLoading implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class UnitAreaMeasurementDeletedFromAdminTrash implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class UnitAreaMeasurementDeletedFromTrash implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class UnitAreaMeasurementOnServerRestored implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UnitAreaMeasurementRestored implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementRestored;

    constructor(public payload: { item: UnitAreaMeasurement }) {
    }
}

export class UnitAreaMeasurementOnServerAdminRestored implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class UnitAreaMeasurementAdminRestored implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementAdminRestored;

    constructor(public payload: { item: UnitAreaMeasurement }) {
    }
}

export class UnitAreaMeasurementTrashFlushed implements Action {
    readonly type = UnitAreaMeasurementActionTypes.UnitAreaMeasurementTrashFlushed;

    constructor() {
    }
}

export type UnitAreaMeasurementActions = UnitAreaMeasurementCreated
    | UnitAreaMeasurementUpdated
    | UnitAreaMeasurementDeleted
    | UnitAreaMeasurementsPageRequested
    | UnitAreaMeasurementsPageLoaded
    | UnitAreaMeasurementsPageCancelled
    | AllUnitAreaMeasurementsLoaded
    | AllUnitAreaMeasurementsRequested
    | UnitAreaMeasurementOnServerCreated
    | UnitAreaMeasurementsPageToggleLoading
    | UnitAreaMeasurementsActionToggleLoading
    | UnitAreaMeasurementDeletedFromAdminTrash
    | UnitAreaMeasurementDeletedFromTrash
    | UnitAreaMeasurementOnServerRestored
    | UnitAreaMeasurementRestored
    | UnitAreaMeasurementOnServerAdminRestored
    | UnitAreaMeasurementAdminRestored
    | UnitAreaMeasurementTrashFlushed;
