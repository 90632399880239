import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AdjustmentValuation } from "../_models/valuation.model";


export enum AdjustmentValuationActionTypes {
    AddValuation = '[Valuation table] Create valuation',
    ValuationAdded = '[Valuation table] Valuation created',
    RemoveValuation = '[Valuation table] Remove valuation',
    ValuationRemoved = '[Valuation table] Valuation removed',

    AddMultipleValuation = '[Valuation table] Add multiple valuation',
    MultipleValuationAdded = '[Valuation table] multiple valuation added',

    UpdateValuationsExternalData = '[Valuation table] Update valuations based on external data (size, consideration, adjustments)',
    ValuationsUpdated = '[Valuation table] Valuations are updated',
    UpdateValuationInternalData = '[Valuation table] Update valuation based on internal data (weight)',
    ValuationUpdated = '[Valuation table] Valuation is updated',

    ValuationComputeTotals = '[Valuation table] Compute totals of valuation',
    ValuationTotalsComputed = '[Valuation table] Totals of valuation computed',

    UpdateJustification = '[Valuation table] Update justification',
    UpdateCapitalAllowance = '[Valuation table] Update capital allowance',
    UpdateCapitalAllowanceJustification = '[Valuation table] Update capital allowance justification',

    ResetValuationState = '[Valuation table] Reset state of valuation'
}

export class AddValuation implements Action {
    readonly type = AdjustmentValuationActionTypes.AddValuation;
    constructor(public payload: {com: any}) {}
}

export class ValuationAdded implements Action {
    readonly type = AdjustmentValuationActionTypes.ValuationAdded;
    constructor(public payload: {valuation: AdjustmentValuation}) {}
}

export class RemoveValuation implements Action {
    readonly type = AdjustmentValuationActionTypes.RemoveValuation;
    constructor(public payload: {comID: number}) {}
}

export class ValuationRemoved implements Action {
    readonly type = AdjustmentValuationActionTypes.ValuationRemoved;
    constructor(public payload: {id: number}) {}
}

export class AddMultipleValuation implements Action {
    readonly type = AdjustmentValuationActionTypes.AddMultipleValuation;
    constructor(public payload: {coms: any[]}) {}
}

export class MultipleValuationAdded implements Action {
    readonly type = AdjustmentValuationActionTypes.MultipleValuationAdded;
    constructor(public payload: {valuations: AdjustmentValuation[], id: number}) {}
}

export class UpdateValuationsExternalData implements Action {
    readonly type = AdjustmentValuationActionTypes.UpdateValuationsExternalData;
    constructor() {}
}

export class ValuationsUpdated implements Action {
    readonly type = AdjustmentValuationActionTypes.ValuationsUpdated;
    constructor(public payload: {
        valuations: AdjustmentValuation[]
    }) {}
}

export class UpdateValuationInternalData implements Action {
    readonly type = AdjustmentValuationActionTypes.UpdateValuationInternalData;
    constructor(public payload: {
        valuation: AdjustmentValuation,
    }) {}
}

export class ValuationUpdated implements Action {
    readonly type = AdjustmentValuationActionTypes.ValuationUpdated;
    constructor(public payload: {
        updateValuation: Update<AdjustmentValuation>
    }) {}
}

export class ValuationComputeTotals implements Action {
    readonly type = AdjustmentValuationActionTypes.ValuationComputeTotals;
    constructor() {}
}

export class ValuationTotalsComputed implements Action {
    readonly type = AdjustmentValuationActionTypes.ValuationTotalsComputed;
    constructor(public payload: {
        totalWeighted: number,
        totalValue: number,
        totalTPValue: number,
        totalTPWeighted: number
    }) {}
}

export class ResetValuationState implements Action {
    readonly type = AdjustmentValuationActionTypes.ResetValuationState;
    constructor() {};
}

export class UpdateJustification implements Action {
    readonly type = AdjustmentValuationActionTypes.UpdateJustification;
    constructor(public payload: {justification: string}) {}
}

export class UpdateCapitalAllowance implements Action {
    readonly type = AdjustmentValuationActionTypes.UpdateCapitalAllowance;
    constructor(public payload: {capitalAllowance: number}) {}
}

export class UpdateCapitalAllowanceJustification implements Action {
    readonly type = AdjustmentValuationActionTypes.UpdateCapitalAllowanceJustification;
    constructor(public payload: {capitalAllowanceJustification: string}) {}
}

export type AdjustmentValuationActions = AddValuation
    | ValuationAdded

    | RemoveValuation
    | ValuationRemoved

    | AddMultipleValuation
    | MultipleValuationAdded

    | UpdateValuationsExternalData
    | ValuationsUpdated
    | UpdateValuationInternalData
    | ValuationUpdated
    
    | ValuationComputeTotals
    | ValuationTotalsComputed

    | UpdateJustification
    | UpdateCapitalAllowance
    | UpdateCapitalAllowanceJustification
    
    | ResetValuationState;

