// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {IpmsComponentsService} from '../_services/ipms-component.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allIpmsComponentsLoaded} from '../_selectors/ipms-component.selectors';
// Actions
import {
    AllIpmsComponentsLoaded,
    AllIpmsComponentsRequested,
    IpmsComponentActionTypes,
    IpmsComponentsPageRequested,
    IpmsComponentsPageLoaded,
    IpmsComponentUpdated,
    IpmsComponentsPageToggleLoading,
    IpmsComponentDeleted,
    IpmsComponentOnServerCreated,
    IpmsComponentCreated,
    IpmsComponentAdminRestored,
    IpmsComponentDeletedFromAdminTrash,
    IpmsComponentDeletedFromTrash,
    IpmsComponentOnServerAdminRestored,
    IpmsComponentOnServerRestored,
    IpmsComponentRestored,
    IpmsComponentTrashFlushed,
    IpmsComponentsActionToggleLoading
} from '../_actions/ipms-component.actions';

@Injectable()
export class IpmsComponentEffects {
    showPageLoadingDispatcher = new IpmsComponentsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new IpmsComponentsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new IpmsComponentsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllIpmsComponents$ = this.actions$
        .pipe(
            ofType<AllIpmsComponentsRequested>(IpmsComponentActionTypes.AllIpmsComponentsRequested),
            withLatestFrom(this.store.pipe(select(allIpmsComponentsLoaded))),
            filter(([action, isAllIpmsComponentsLoaded]) => !isAllIpmsComponentsLoaded),
            mergeMap(() => this.service.getAllIpmsComponents()),
            map(res => {
                return new AllIpmsComponentsLoaded({IpmsComponents: res.data});
            })
        );

    @Effect()
    loadIpmsComponentsPage$ = this.actions$
        .pipe(
            ofType<IpmsComponentsPageRequested>(IpmsComponentActionTypes.IpmsComponentsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findIpmsComponents(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new IpmsComponentsPageLoaded({
                    IpmsComponents: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentDeleted>(IpmsComponentActionTypes.IpmsComponentDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteIpmsComponent(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentUpdated>(IpmsComponentActionTypes.IpmsComponentUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateIpmsComponent(payload.IpmsComponent);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentOnServerCreated>(IpmsComponentActionTypes.IpmsComponentOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createIpmsComponent(payload.IpmsComponent).pipe(
                    tap(res => {
                        this.store.dispatch(new IpmsComponentCreated({IpmsComponent: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushIpmsComponentTrash$ = this.actions$
        .pipe(
            ofType<IpmsComponentTrashFlushed>(IpmsComponentActionTypes.IpmsComponentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentOnServerRestored>(IpmsComponentActionTypes.IpmsComponentOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IpmsComponentRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentOnServerAdminRestored>(IpmsComponentActionTypes.IpmsComponentOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IpmsComponentAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentDeletedFromTrash>(IpmsComponentActionTypes.IpmsComponentDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashIpmsComponent$ = this.actions$
        .pipe(
            ofType<IpmsComponentDeletedFromAdminTrash>(IpmsComponentActionTypes.IpmsComponentDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: IpmsComponentsService, private store: Store<AppState>) {
    }
}
