// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Models
import { AuditTrailParticipantModel } from '../_models/audit-trail-participant.model';

export enum AuditTrailParticipantActionTypes {
    AuditTrailParticipantOnServerCreated = '[Edit AuditTrailParticipant Dialog] AuditTrailParticipant On Server Created',

    AuditTrailParticipants = '[AuditTrailParticipants List] get all ',
    AuditTrailParticipantsPageRequested = '[AuditTrailParticipants List Page] AuditTrailParticipants Page Requested',
    AuditTrailParticipantsPageLoaded = '[AuditTrailParticipants API] AuditTrailParticipants Page Loaded',

    AuditTrailParticipantsPageCancelled = '[AuditTrailParticipants API] AuditTrailParticipants Page Cancelled',
    AuditTrailParticipantsPageToggleLoading = '[AuditTrailParticipants] AuditTrailParticipants Page Toggle Loading',
    AuditTrailParticipantActionToggleLoading = '[AuditTrailParticipants] AuditTrailParticipants Action Toggle Loading',
    AuditTrailParticipantGet = '[AuditTrailParticipant Object] AuditTrailParticipant Object Requested',

    AuditTrailParticipantCreated = '[Edit AuditTrailParticipant Dialog] AuditTrailParticipant Created',
    AuditTrailParticipantUpdated = '[Edit AuditTrailParticipant Dialog] AuditTrailParticipant Updated',
    AuditTrailParticipantDeleted = '[AuditTrailParticipant List Page] AuditTrailParticipant Deleted'
}

export class AuditTrailParticipantOnServerCreated implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantOnServerCreated;
    constructor(public payload: { auditTrailParticipant: AuditTrailParticipantModel, auditTrailId: number }) { }
}

export class AuditTrailParticipantGet implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantGet;
    constructor(public payload: { auditTrailParticipantId: number }) { }
}

export class AuditTrailParticipantCreated implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantCreated;
    constructor(public payload: { auditTrailParticipant: AuditTrailParticipantModel }) { }
}

export class AuditTrailParticipantsPageRequested implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantsPageRequested;
    constructor(public payload: { auditTrailId: number }) { }
}

export class AuditTrailParticipantsPageLoaded implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantsPageLoaded;
    constructor(public payload: { auditTrailParticipants: AuditTrailParticipantModel[] }) { }
}

export class AuditTrailParticipantsPageCancelled implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantsPageCancelled;
}

export class AuditTrailParticipantsPageToggleLoading implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AuditTrailParticipantActionToggleLoading implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AuditTrailParticipantUpdated implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantUpdated;

    constructor(public payload: {
        partialAuditTrailParticipant: Update<AuditTrailParticipantModel>,
        auditTrailParticipant: AuditTrailParticipantModel
    }) {
    }
}

export class AuditTrailParticipantDeleted implements Action {
    readonly type = AuditTrailParticipantActionTypes.AuditTrailParticipantDeleted;

    constructor(public payload: { id: number }) {
    }
}

export type AuditTrailParticipantActions = AuditTrailParticipantOnServerCreated
| AuditTrailParticipantCreated
| AuditTrailParticipantsPageRequested
| AuditTrailParticipantsPageLoaded
| AuditTrailParticipantsPageCancelled
| AuditTrailParticipantsPageToggleLoading
| AuditTrailParticipantActionToggleLoading
| AuditTrailParticipantGet
| AuditTrailParticipantUpdated
| AuditTrailParticipantDeleted;
