import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassWarehouseModel } from "../_models/asset-class-warehouse.model";

export enum AssetClassWarehouseActionTypes {
    AssetClassWarehouseActionToggleLoading = '[AssetClassWarehouse Effect] AssetClassWarehouse Action Toggle Loading',

    AssetClassWarehouseOnServerCreated = '[Edit AssetClassWarehouse page] AssetClassWarehouse On Server Created',
    AssetClassWarehouseCreated = '[AssetClassWarehouse Effect] AssetClassWarehouse Created',
    
    AssetClassWarehouseOnServerUpdated = '[Edit AssetClassWarehouse page] AssetClassWarehouse On Server Updated',
    AssetClassWarehouseUpdated = '[AssetClassWarehouse Effect] AssetClassWarehouse Updated',

    AssetClassWarehouseDeleted = '[AssetClassWarehouse List Page] Asset Class Land Deleted',
    AssetClassWarehouseDeletedFromTrash = '[AssetClassWarehouse Trash Modal] Asset Class Land Deleted from Trash',
    AssetClassWarehouseDeletedFromAdminTrash = '[AssetClassWarehouse Trash Modal] Asset Class Land Deleted from Admin Trash',
    AssetClassWarehouseTrashFlushed = '[AssetClassWarehouse Trash Modal] Asset Class Warehouse Trash Flushed',

    AssetClassWarehouseOnServerRestored = '[AssetClassWarehouse Trash Modal] Asset Class Land Restored',
    AssetClassWarehouseRestored = '[AssetClassWarehouse Effect] Asset Class Land Restored',
    AssetClassWarehouseOnServerAdminRestored = '[AssetClassWarehouse Admin Trash Modal] Asset Class Land Admin Restored',
    AssetClassWarehouseAdminRestored = '[AssetClassWarehouse Effect] Asset Class Land Admin Restored',

    AssetClassWarehousePageToggleLoading = '[AssetClassWarehouse Effect] AssetClassWarehouse Page Toggle Loading',
    AssetClassWarehousePageRequested = '[AssetClassWarehouse List Page] All AssetClassWarehouse requested',
    AssetClassWarehousePageLoaded = '[AssetClassWarehouse Effect] All AssetClassWarehouse loaded',

    UpdatePreviouslyCreatedACId = '[AssetClassWarehouse Edit] Update Previously Created AssetClassWarehouse Id',

    DuplicateAssetClass = '[AssetClassWarehouse List Page] Duplicate Asset Class'
}

export class AssetClassWarehouseOnServerCreated implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerCreated;
    constructor(public payload: {
        AssetClassWarehouse: AssetClassWarehouseModel,
        fileList: any[],
    }) {}
}

export class AssetClassWarehouseCreated implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseCreated;
    constructor(public payload: {AssetClassWarehouse: AssetClassWarehouseModel}) {}
}

export class AssetClassWarehouseOnServerUpdated implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerUpdated;
    constructor(public payload: {
        AssetClassWarehouse: AssetClassWarehouseModel,
        fileList: any[] 
    }) {}
}

export class AssetClassWarehouseUpdated implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseUpdated;
    constructor(public payload: {AssetClassWarehouse: AssetClassWarehouseModel}) {}
}

export class AssetClassWarehousePageRequested implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehousePageRequested;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class AssetClassWarehousePageLoaded implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehousePageLoaded;
    constructor(public payload: {
        AssetClassWarehouses: AssetClassWarehouseModel[],
        totalCount: number;
        totalTrashed: number;
        totalAdminTrashed: number;
        page: QueryParamsModel
    }) {}
}

export class AssetClassWarehouseDeleted implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseDeleted;
    constructor(public payload: {id: number}) {}
}

export class AssetClassWarehouseDeletedFromTrash implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseDeletedFromTrash;
    constructor(public payload: {id: number}) {}
}

export class AssetClassWarehouseOnServerRestored implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassWarehouseRestored implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseRestored;
    constructor(public payload: {ac: AssetClassWarehouseModel}) {}
}

export class AssetClassWarehouseOnServerAdminRestored implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseOnServerAdminRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassWarehouseAdminRestored implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseAdminRestored;
    constructor(public payload: {ac: AssetClassWarehouseModel}) {}
}

export class AssetClassWarehousePageToggleLoading implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehousePageToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassWarehouseActionToggleLoading implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassWarehouseDeletedFromAdminTrash implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class AssetClassWarehouseTrashFlushed implements Action {
    readonly type = AssetClassWarehouseActionTypes.AssetClassWarehouseTrashFlushed;
    constructor() {}
}

export class UpdatePreviouslyCreatedACWarehouseId implements Action {
    readonly type = AssetClassWarehouseActionTypes.UpdatePreviouslyCreatedACId;
    constructor() {}
}

export class AssetClassWarehouseDuplicate implements Action {
    readonly type = AssetClassWarehouseActionTypes.DuplicateAssetClass;
    constructor(public payload: {id: number}) {}
}

export type AssetClassWarehouseActions 
    = AssetClassWarehouseOnServerCreated
    | AssetClassWarehouseCreated

    | AssetClassWarehouseOnServerUpdated
    | AssetClassWarehouseUpdated

    | AssetClassWarehousePageRequested
    | AssetClassWarehousePageLoaded

    | AssetClassWarehouseDeleted
    | AssetClassWarehouseDeletedFromTrash
    | AssetClassWarehouseDeletedFromAdminTrash
    | AssetClassWarehouseTrashFlushed

    | AssetClassWarehouseOnServerRestored
    | AssetClassWarehouseOnServerAdminRestored
    | AssetClassWarehouseRestored
    | AssetClassWarehouseAdminRestored
    
    | AssetClassWarehousePageToggleLoading
    | AssetClassWarehouseActionToggleLoading
    
    | UpdatePreviouslyCreatedACWarehouseId
    
    | AssetClassWarehouseDuplicate;