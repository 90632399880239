<div class="d-flex flex-column" style="min-height: 200px;">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <div *ngIf="isLV && isLV !== isPM" class="mr-2">
            <div style="margin-bottom: 14px;">Only Project Manager can change the lead valuer</div>
        </div>
        <div ngbDropdown placement="bottom-right" *ngIf="!isTemplate && !readonly" class="dropdown dropdown-inline">
            <button ngbDropdownToggle type="button" class="btn btn-label-brand btn-bold btn-sm dropdown-toggle" style="cursor: pointer !important;">Add</button>
            <div ngbDropdownMenu style="width: 230px;">
                <ul class="navi navi-hover">
                    <li class="navi-item">
                        <a class="navi-link" href="javascript:;" (click)="addValuer()">
                            <span class="navi-icon">
                                <i class="flaticon2-add"></i>
                            </span>
                            <span class="navi-text">
                                Add Team Member
                            </span>
                        </a>
                    </li>
                    <li class="navi-item">
                        <a class="navi-link" href="javascript:;" (click)="_addValuersFromTemplate()">
                            <span class="navi-icon">
                                <i class="flaticon2-add"></i>
                            </span>
                            <span class="navi-text">
                                Add from template
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <button 
            *ngIf="isTemplate"
            [disabled]="readonly"
            [ngStyle]="{
            'pointer-events': isTemplate || isLV || isPM ? '':'none'
            }" mat-raised-button color="primary" (click)="addValuer()" type="button">
            Add Team Member
        </button>
    </div>

    <mat-table #table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="worker">
            <mat-header-cell *matHeaderCellDef> Team Member</mat-header-cell>
            <mat-cell *matCellDef="let valuer"> {{valuer.worker.first_name}} {{valuer.worker.last_name ? valuer.worker.last_name : ''}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="worker.qualification_id">
            <mat-header-cell *matHeaderCellDef> Qualification</mat-header-cell>
            <mat-cell *matCellDef="let valuer"> {{getValuerQualificationName(valuer.worker.qualification_id)}}</mat-cell>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="lead_valuer">
            <mat-header-cell *matHeaderCellDef> Lead Valuer</mat-header-cell>
            <mat-cell [ngClass]="{'pointer-none': !(isTemplate || isPM)}" *matCellDef="let valuer">
                <mat-checkbox 
                    [checked]="valuer.lead_valuer" 
                    (change)="onchangeLeadValuer($event, valuer)" 
                    [disabled]="readonly || valuer.lead_valuer == 1 || valuer.worker.qualification_id == 2">
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="task">
            <mat-header-cell *matHeaderCellDef>Default Task</mat-header-cell>
            <mat-cell *matCellDef="let valuer"> {{valuer.assigned_default_task_id != null ? valuer.assigned_default_task_name : 'No Default Task Assigned'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let valuer; let i = index">
                <button mat-icon-button
                        color="warn"
                        matTooltip="Delete the Team Member"
                        (click)="deleteValuer(valuer, i)"
                        type="button"
                        [disabled]="readonly"
                        [ngStyle]="{'pointer-events': isTemplate || isLV || isPM ? '':'none'}">
                    <mat-icon>delete</mat-icon>
                </button>&nbsp;
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

</div>