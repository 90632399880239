<div class="percent-input-container" [formGroup]="form"
        [ngClass]="{
            'cell-dark-gray': (val$|async) == 0,
            'cell-red': (val$|async) < 0,
            'cell-green': (val$|async) > 0
        }">
    <button class="percent-input-btn red-btn"
        type="button"
        (click)="changeVal('decrease')"
        [matTooltip]="decreaseButtonTooltip"
        matTooltipPosition="left"
        matTooltipClass="red-btn-tooltip">
        -
    </button>
    <input percentRange class="percent-input-element" formControlName="percent" size="5"
        (focusout)="onFocusOut($event)">
    <button class="percent-input-btn green-btn"
        type="button"
        (click)="changeVal('increase')"
        [matTooltip]='increaseButtonTooltip'
        matTooltipPosition="right"
        matTooltipClass="green-btn-tooltip">
        +
    </button>
</div>