<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Target Properties</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="row align-items-center container">
                <div class="col-lg-4 col-md-4 col-sm-6 py-1 kt-margin-10-mobile" *ngFor="let item of toeTps">
                    <mat-checkbox
                        (change)="updateCheckedOptions(item, $event)"
                        [checked]="item.selected"
                    >
                        {{ item.name }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="card-footer b-0 p-0">
            <div class="form-actions form-actions-solid">
                <mat-checkbox
                    (change)="setAll($event)"
                >    
                    Select all properties
                </mat-checkbox>
                <div style="float: right !important; padding-top: -20px !important;">
                    <button mat-button mat-raised-button color="primary" (click)="close(true)" [disabled]="!hasCheckedTp">Add as group</button>&nbsp;
                    <button mat-button mat-raised-button color="primary" (click)="close(false)" [disabled]="!hasCheckedTp" >Add</button>&nbsp;
                </div>
            </div>

        </div>
    </div>
</div>