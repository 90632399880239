// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {UnitMeasurementsService} from '../_services/unit-measurement.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allUnitMeasurementsLoaded} from '../_selectors/unit-measurement.selectors';
// Actions
import {
    AllUnitMeasurementsLoaded,
    AllUnitMeasurementsRequested,
    UnitMeasurementActionTypes,
    UnitMeasurementsPageRequested,
    UnitMeasurementsPageLoaded,
    UnitMeasurementUpdated,
    UnitMeasurementsPageToggleLoading,
    UnitMeasurementDeleted,
    UnitMeasurementOnServerCreated,
    UnitMeasurementCreated,
    UnitMeasurementAdminRestored,
    UnitMeasurementDeletedFromAdminTrash,
    UnitMeasurementDeletedFromTrash,
    UnitMeasurementOnServerAdminRestored,
    UnitMeasurementOnServerRestored,
    UnitMeasurementRestored,
    UnitMeasurementTrashFlushed,
    UnitMeasurementsActionToggleLoading
} from '../_actions/unit-measurement.actions';

@Injectable()
export class UnitMeasurementEffects {
    showPageLoadingDispatcher = new UnitMeasurementsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new UnitMeasurementsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new UnitMeasurementsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllUnitMeasurements$ = this.actions$
        .pipe(
            ofType<AllUnitMeasurementsRequested>(UnitMeasurementActionTypes.AllUnitMeasurementsRequested),
            withLatestFrom(this.store.pipe(select(allUnitMeasurementsLoaded))),
            filter(([action, isAllUnitMeasurementsLoaded]) => !isAllUnitMeasurementsLoaded),
            mergeMap(() => this.service.getAllUnitMeasurements()),
            map(res => {
                return new AllUnitMeasurementsLoaded({unitMeasurements: res.data});
            })
        );

    @Effect()
    loadUnitMeasurementsPage$ = this.actions$
        .pipe(
            ofType<UnitMeasurementsPageRequested>(UnitMeasurementActionTypes.UnitMeasurementsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findUnitMeasurements(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new UnitMeasurementsPageLoaded({
                    unitMeasurements: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementDeleted>(UnitMeasurementActionTypes.UnitMeasurementDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteUnitMeasurement(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementUpdated>(UnitMeasurementActionTypes.UnitMeasurementUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateUnitMeasurement(payload.unitMeasurement);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementOnServerCreated>(UnitMeasurementActionTypes.UnitMeasurementOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createUnitMeasurement(payload.unitMeasurement).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitMeasurementCreated({unitMeasurement: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushUnitMeasurementTrash$ = this.actions$
        .pipe(
            ofType<UnitMeasurementTrashFlushed>(UnitMeasurementActionTypes.UnitMeasurementTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementOnServerRestored>(UnitMeasurementActionTypes.UnitMeasurementOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitMeasurementRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementOnServerAdminRestored>(UnitMeasurementActionTypes.UnitMeasurementOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new UnitMeasurementAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementDeletedFromTrash>(UnitMeasurementActionTypes.UnitMeasurementDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashUnitMeasurement$ = this.actions$
        .pipe(
            ofType<UnitMeasurementDeletedFromAdminTrash>(UnitMeasurementActionTypes.UnitMeasurementDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: UnitMeasurementsService, private store: Store<AppState>) {
    }
}
