// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {OfficeStandardMeasurementsService} from '../_services/office-standard-measurement.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allOfficeStandardMeasurementsLoaded} from '../_selectors/office-standard-measurement.selectors';
// Actions
import {
    AllOfficeStandardMeasurementsLoaded,
    AllOfficeStandardMeasurementsRequested,
    OfficeStandardMeasurementActionTypes,
    OfficeStandardMeasurementsPageRequested,
    OfficeStandardMeasurementsPageLoaded,
    OfficeStandardMeasurementUpdated,
    OfficeStandardMeasurementsPageToggleLoading,
    OfficeStandardMeasurementDeleted,
    OfficeStandardMeasurementOnServerCreated,
    OfficeStandardMeasurementCreated,
    OfficeStandardMeasurementAdminRestored,
    OfficeStandardMeasurementDeletedFromAdminTrash,
    OfficeStandardMeasurementDeletedFromTrash,
    OfficeStandardMeasurementOnServerAdminRestored,
    OfficeStandardMeasurementOnServerRestored,
    OfficeStandardMeasurementRestored,
    OfficeStandardMeasurementTrashFlushed,
    OfficeStandardMeasurementsActionToggleLoading
} from '../_actions/office-standard-measurement.actions';

@Injectable()
export class OfficeStandardMeasurementEffects {
    showPageLoadingDispatcher = new OfficeStandardMeasurementsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new OfficeStandardMeasurementsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new OfficeStandardMeasurementsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllOfficeStandardMeasurements$ = this.actions$
        .pipe(
            ofType<AllOfficeStandardMeasurementsRequested>(OfficeStandardMeasurementActionTypes.AllOfficeStandardMeasurementsRequested),
            withLatestFrom(this.store.pipe(select(allOfficeStandardMeasurementsLoaded))),
            filter(([action, isAllOfficeStandardMeasurementsLoaded]) => !isAllOfficeStandardMeasurementsLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllOfficeStandardMeasurementsLoaded({officeStandardMeasurements: res.data});
            })
        );

    @Effect()
    loadOfficeStandardMeasurementsPage$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementsPageRequested>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new OfficeStandardMeasurementsPageLoaded({
                    officeStandardMeasurements: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementUpdated>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.officeStandardMeasurement);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementOnServerCreated>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.officeStandardMeasurement).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeStandardMeasurementCreated({officeStandardMeasurement: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushOfficeStandardMeasurementTrash$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementTrashFlushed>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementOnServerRestored>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeStandardMeasurementRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementOnServerAdminRestored>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new OfficeStandardMeasurementAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementDeleted>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementDeletedFromTrash>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashOfficeStandardMeasurement$ = this.actions$
        .pipe(
            ofType<OfficeStandardMeasurementDeletedFromAdminTrash>(OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: OfficeStandardMeasurementsService, private store: Store<AppState>) {
    }
}
