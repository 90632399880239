import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageViewerDialogComponent } from "./image-viewer.component";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    PdfViewerModule,
    MatButtonModule
  ],
  declarations: [ImageViewerDialogComponent],
  exports: [ImageViewerDialogComponent],
})
export class ImageViewerModule {}