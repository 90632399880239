// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {createFeatureSelector} from '@ngrx/store';
// Actions
import {TpAdminTaskLVActions, TpAdminTaskLVActionTypes} from '../_actions/tp-admin-task-lv.actions';
import {TpAdminTaskLVModel} from '../_models/tp-admin-task-lv.model';

// Models
import {QueryParamsModel} from '../../_base/crud';

export interface TpAdminTaskLVState extends EntityState<TpAdminTaskLVModel> {
    _isAllTpAdminTaskLVLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedItemId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TpAdminTaskLVModel> = createEntityAdapter<TpAdminTaskLVModel>();

export const initialTpAdminTaskLVState: TpAdminTaskLVState = adapter.getInitialState({
    _isAllTpAdminTaskLVLoaded: false,
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedItemId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function tpAdminTaskLVReducer(state = initialTpAdminTaskLVState, action: TpAdminTaskLVActions): TpAdminTaskLVState {
    switch (action.type) {
        case TpAdminTaskLVActionTypes.AllTpAdminTaskLVRequested:
            return {
                ...state,
                _isAllTpAdminTaskLVLoaded: false
            };
        case TpAdminTaskLVActionTypes.AllTpAdminTaskLVLoaded:
            return adapter.addAll(action.payload.tasks, {
                ...state,
                _isAllTpAdminTaskLVLoaded: true
            });
        case TpAdminTaskLVActionTypes.TpAdminTaskLVCleared:
            return adapter.removeAll(state);

        case TpAdminTaskLVActionTypes.TpAdminTaskLVDeleted:
            return adapter.removeOne(action.payload.id, state);

        case TpAdminTaskLVActionTypes.TpAdminTaskLVOnServerCreated:
            return {
                ...state
            };
        case TpAdminTaskLVActionTypes.TpAdminTaskLVCreated:

            return adapter.addOne(action.payload.task, {
                ...state
            });

        case TpAdminTaskLVActionTypes.TpAdminTaskLVUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case TpAdminTaskLVActionTypes.TpAdminTaskLVByUserPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.assetClasses, {
                ...initialTpAdminTaskLVState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case TpAdminTaskLVActionTypes.AllTpAdminTaskLVLoadedByUser:
            return adapter.addMany(action.payload.tasks, {
                ...initialTpAdminTaskLVState,
                _isAllTpAdminTaskLVLoaded: true,
                listLoading: false,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
            });
        case TpAdminTaskLVActionTypes.TpAdminTaskLVsClear:
            return adapter.removeAll(initialTpAdminTaskLVState);
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
