export enum Tasks {
  ExternalRefTask = 0,
  InvestigationTask = 1,
  ValuationTask = 2,
  ReportingTask = 3,
  DeliveryTask = 4,
  UploadDocumentTask = 10,
  AuditTrailTask = 11,
  DueDiligenceTask = 12,
  LogisticsTask = 13,
  LandmarksTask = 14,
  ReportDesignTask = 15,
}

export enum DocumentTypes {
  DraftStatement = 1,
  Valuation = 2
}

export type BaseTask = {
  id: number,
  assignee: {
    id: number,
    name: string
  },
  originalTask: any,
  updatedAt: string
}

export type ExternalReferenceTaskItem = BaseTask & {
  key: 'external-reference-document'
}

export type Task
  = ExternalReferenceTaskItem

export function mapTpTaskToTaskModel(task: any): Task | null {
  const base: BaseTask = {
    id: task.id,
    assignee: {
      id: task.assignee.id,
      name: `${task.assignee.first_name} ${task.assignee.last_name}`
    },
    updatedAt: task.updated_at,
    originalTask: task
  }

  switch (task.task_id) {
    // External Reference Document task
    case 0: {
      return {
        key: 'external-reference-document',
        ...base
      }
    }
    default: {
      return null;
    }
  }
}