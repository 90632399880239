import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { SubdomainService } from "../../_base/subdomain.service";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Quote } from "../types/quote";

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  get(id: number) {
    return this.http.get<{data: Quote}>(
      environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/quotes/${id}`,
      {
        headers: this.authService.getAuthHeaders()
      }
    ).pipe(
      map(response => response.data)
    )
  }
}