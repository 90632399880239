// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// RxJS
import {Observable, of} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
// Models
import {AssignmentModel} from '../_models/assignment.model';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {map} from 'rxjs/operators';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class AssignmentsService {
    API_TP_LOGS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/tp/logs`;
    API_TOE_LOGS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/toe/logs`;
    API_ASSIGNMENTS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignments`;
    API_ASSIGNMENT_LOGS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignment/logs`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    // CREATE =>  POST: add a new assignment to the server
    create(assignment: AssignmentModel): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_ASSIGNMENTS_URL, assignment, {headers: httpHeaders});
    }

    duplicate(assignment: AssignmentModel, toeNames: string[], oldAssignmentID: number): Observable<any> {
        const body = {
            assignment: assignment,
            toesNames: toeNames,
            oldAssignmentID
        }
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_ASSIGNMENTS_URL + '/duplicate', body, {headers: httpHeaders});
    }

    // READ
    getAll(): Observable<any> {
        // ?token=eyJhbGciOiJIUzI1NiI...

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_ASSIGNMENTS_URL, {
            headers: httpHeaders
        });
    }

    // Trash
    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_ASSIGNMENTS_URL + `/trash`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_ASSIGNMENTS_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_ASSIGNMENTS_URL + `/trash/${assignmentId}`, {headers: httpHeaders});
    }

    getById(assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get <any>(this.API_ASSIGNMENTS_URL + `/${assignmentId}`, {headers: httpHeaders});
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    find(queryParams: QueryParamsModel): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };
        return this.http.get<any>(this.API_ASSIGNMENTS_URL, {
            headers: httpHeaders,
            params: params
        });
    }

    // UPDATE => PUT: update the assignment on the server
    update(assignment: AssignmentModel): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        return this.http.put(this.API_ASSIGNMENTS_URL + `/${assignment.id}`, assignment, {headers: httpHeader})
            .pipe(map((res: any) => {
                    return res;
                }),
                catchError(this.handleError<any>('update', []))
            );
    }

    // UPDATE Status
    updateStatusForAssignment(assignments: AssignmentModel[], status: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {
            assignmentsForUpdate: assignments,
            newStatus: status
        };
        const url = this.API_ASSIGNMENTS_URL + '/updateStatus';
        return this.http.put(url, body, {headers: httpHeaders});
    }

    // DELETE => delete the assignment from the server
    delete(assignmentId: number): Observable<any> {
        const url = `${this.API_ASSIGNMENTS_URL}/${assignmentId}`;
        const httpHeader = this.authService.getAuthHeaders();
        return this.http.delete<any>(url, {headers: httpHeader});
    }

    deleteAssignments(ids: number[] = []): Observable<any> {
        const url = this.API_ASSIGNMENTS_URL + '/deleteAssignments';
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {assignmentIdsForDelete: ids};
        return this.http.put<QueryResultsModel>(url, body, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_ASSIGNMENTS_URL + `/admin/trash/${assignmentId}`, {headers: httpHeaders});
    }

    // delete from trash
    deleteFromTrash(assignmentId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_ASSIGNMENTS_URL + `/trash/${assignmentId}`, {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_ASSIGNMENTS_URL + '/trash/flush', {headers: httpHeaders});
    }

    updateStatus(id: number, status: number) {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_ASSIGNMENTS_URL + `/${id}/status`, {status}, {headers:httpHeaders});
    }

    getLogsByAssignemnt(assignment_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_ASSIGNMENT_LOGS_URL + `/${assignment_id}`, {headers: httpHeaders});
    }

    getLogsByToe(toe_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TOE_LOGS_URL + `/${toe_id}`, {headers: httpHeaders});
    }

    getLogsByTp(tp_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_TP_LOGS_URL + `/${tp_id}`, {headers: httpHeaders});
    }

    getToesListing(assignment_id: number) {
        const headers = this.authService.getAuthHeaders()
        return this.http.get<{data: Array<{id: number, name: string}>}>(
            `${this.API_ASSIGNMENTS_URL}/${assignment_id}/toes`,
            {headers}
        )
    }

    duplicateAssignment(payload: {
        name: string,
        assignment_id: number,
        project_manager_id: number,
        agency_id: number,
        selectedToeIds: number[]
    }) {
        const headers = this.authService.getAuthHeaders()
        return this.http.post<{data: unknown}>(
            `${this.API_ASSIGNMENTS_URL}/duplicate`,
            payload,
            {headers}
        )
    }

    fetchListing(filter?: {
        agencyId?: number
    }) {
        let params = new HttpParams()
        if (filter) {
            if (filter.agencyId != undefined) {
                params = params.set('agency_id', filter.agencyId)
            }
        }
        return this.http.get<{data: {
            id: number,
            name: string
        }[]}>(environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/assignments/listing`, {
            headers: this.authService.getAuthHeaders(),
            params: params
        }).pipe(
            map(response => response.data)
        )
    }


    /*
   * Handle Http operation that failed.
   * Let the app continue.
  *
  * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
