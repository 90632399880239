import { Injectable, OnDestroy } from "@angular/core";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { ResidentialExternalAreaModel } from "src/app/core/comparable";
import { FloorAction } from "../../shared_components/sizes-module/sizes-module.types";

@Injectable()
export class InspectionSizeService implements OnDestroy {
    private floors = [];
    private _floorAction$: ReplaySubject<FloorAction|null> = new ReplaySubject();
    floorAction$: Observable<FloorAction|null> = this._floorAction$.asObservable();

    private _bedrooms$: BehaviorSubject<number> = new BehaviorSubject(0);
    bedrooms$: Observable<number> = this._bedrooms$.asObservable();

    private _externalAreas$: BehaviorSubject<ResidentialExternalAreaModel[]> = new BehaviorSubject([]);
    externalAreas$: Observable<ResidentialExternalAreaModel[]> = this._externalAreas$.asObservable();

    constructor() {}
    ngOnDestroy(): void {
        
    }

    setFloor({floors, scheme_id}: {floors: number[], scheme_id: number}) {
        const removed = this.floors.filter(f => !floors.includes(f));
        const new_floors = floors.filter(f => !this.floors.includes(f));
        this.floors = cloneDeep(floors);
        this._floorAction$.next({type: 'remove', floors: removed, scheme_id});
        this._floorAction$.next({type: 'add', floors: new_floors, scheme_id});
    }

    setExternalAreas(items: any[]) {
        this._externalAreas$.next(items);
    }
}