import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConflictOfInterestModel} from '../../../../../core/toe';
import {ConflictType} from '../../../../../core/toe/_static-data/conflict-type';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConflictModel} from '../../../../../core/toe/_models/conflict.model';
import {ConflictService} from '../../../../../core/toe';
import {combineLatest, fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {CoiDetailsTPModel} from '../../../../../core/toe';
import {TpListComponent} from '../target-properties/list/tp-list.component';
import { TranslateService } from '@ngx-translate/core';

const TYPE_OF_CONFLICT: any[] = [
    {
        id: 1,
        name: 'Party Conflict',
        descr: 'a situation in which the duty of an RICS member (working independently or within a non-regulated firm or within a regulated firm) or a regulated firm to act in the interests of a client or other party in a professional assignment conflicts with a duty owed to another client or party in relation to the same or a related professional assignment'
    },
    {
        id: 2,
        name: 'Own Interest Conflict',
        descr: 'a situation in which the duty of an RICS member (working independently or within a non-regulated firm or within a regulated firm) or a regulated firm to act in the interests of a client in a professional assignment conflicts with the interests of that same RICS member/ firm (or in the case of a regulated firm, the interests of any of the individuals within that regulated firm who are involved directly or indirectly in that or any related professional assignment)'
    },
    {
        id: 3,
        name: 'Confidential Information Conflict',
        descr: 'a conflict between the duty of an RICS member (working independently or within a non-regulated firm or within a regulated firm) under paragraph 2.2 of the PS mentioned above to provide material information to one client, and the duty of that RICS member (working independently or within a non-regulated firm) or of a regulated firm under paragraph 2.1 (ibid) to another client to keep that same information confidential'
    },
];

@Component({
    selector: 'kt-conflict-detail',
    templateUrl: './conflict-detail.component.html',
    styleUrls: ['./conflict-detail.component.scss']
})

export class ConflictDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(TpListComponent)
    private tpListComponent: TpListComponent;

    @Input() conflictData: ConflictOfInterestModel;
    @Input() isDisclosure = false;
    @Input() index: number;
    @Input() tp: any;
    @Input() readonly: boolean;

    @Output() onClickFileBtn: EventEmitter<any> = new EventEmitter();
    selectedOptionIndex: number;
    options: { name: string, prefix: string }[];
    explainOptionPrefix: string[];
    selectedOptionIndex_a: number;
    options_a: { name: string, prefix: string }[];
    explainOptionPrefix_a: string[];
    conflictForm: UntypedFormGroup;
    explainDisable = false;
    cntFile = 0;
    dialogRef: any;
    selectedTPs: CoiDetailsTPModel[] = [];
    typeOfConflicts: any[] = [];
    canEdit: boolean = true;

    @ViewChild('field') field: ElementRef;
    private explainFieldSubscription: Subscription;

    private subscriptions: Subscription[] = [];


    // tooltipKeys: any[] = [
    //     'previous_work', 
    //     'personal_work', 
    //     'employees_knows_each_other', 
    //     'interest_valuation', 
    //     'mad_acting', 
    //     'asset_previously_valuated', 
    //     'mad_third_party', 
    //     'employees_knowledge',
    //     'conflicted_projects', 
    //     'conflicted_projects_b',
    //     'common_staff',
    //     'fee_sharing',
    //     'valuation_significant_revenue',
    //     'verbal_value',
    //     'valuing_for_lender', 
    //     'long_standing_professional',
    //     'introduction'
    //     'acting_for', 'providing_development_consultancy'];


    constructor(private formBuilder: UntypedFormBuilder,
                public conflictService: ConflictService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.typeOfConflicts = TYPE_OF_CONFLICT;
        this.options = ConflictType.conflictTypes[this.conflictData.option_type - 1].options;
        this.explainOptionPrefix = ConflictType.conflictTypes[this.conflictData.option_type - 1].explainOptionPrefix;
        this.options_a = ConflictType.conflictTypes[this.conflictData.option_type_a - 1].options;
        if (this.options_a[0].name !== 'tag-input') {
            this.explainOptionPrefix_a = ConflictType.conflictTypes[this.conflictData.option_type_a - 1].explainOptionPrefix;
        }
        if (this.isDisclosure) {
            const _value = this.conflictService.getDisclosure(this.conflictData.name);
            this.selectedOptionIndex = _value.index;
            this.conflictForm = this.formBuilder.group({
                explain: [_value.text, Validators.required]
            });
        } else {
            const _value = this.conflictService.getConflict(this.conflictData.name);
            this.selectedOptionIndex = _value.index;
            this.selectedOptionIndex_a = _value.index_a;
            this.canEdit = _value.canEdit;
            this.conflictForm = this.formBuilder.group({
                explain: [{value: _value.text, disabled: !_value.canEdit}, Validators.required],
                explain2: [{value: _value.text2, disabled: !_value.canEdit}, Validators.required],
                type_of_conflict_id: [{value: _value.type_of_conflict_id, disabled: !_value.canEdit}, Validators.required],
                appropriate_parties: [{value: _value.appropriate_parties, disabled: !_value.canEdit}, Validators.required],
                reason_continue: [{value: _value.reason_continue, disabled: !_value.canEdit}, Validators.required],
            });
            const sub = combineLatest([
                this.conflictForm.controls.explain.valueChanges,
                this.conflictForm.controls.explain2.valueChanges
            ]).pipe(
                debounceTime(50),
                distinctUntilChanged()
            ).subscribe(() => this.sendDataToService());
            this.subscriptions.push(sub);
            this.selectedTPs = _value.coiDetailsTP;
        }
        this.conflictForm.controls.explain.updateValueAndValidity();
        this.conflictForm.controls.explain2.updateValueAndValidity();
        this.conflictForm.controls.type_of_conflict_id.updateValueAndValidity();
        this.conflictForm.controls.appropriate_parties.updateValueAndValidity();
        this.conflictForm.controls.reason_continue.updateValueAndValidity();

        if (this.conflictData.name == 'valuer_involved_in_purchase') {
            const cntSub = this.conflictService.fileCntSubject.subscribe(
                (cnt: number) => {
                    this.cntFile = cnt;
                }
            );
            this.subscriptions.push(cntSub);
        }
    }

    ngOnDestroy(): void {
        this.sendDataToService();
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngAfterViewInit(): void {
        if (this.conflictData.name == 'purpose_of_valuation') {
            if (this.selectedOptionIndex < 1) {
                this.conflictForm.controls.explain.setValue('The valuation may not be acceptable to a lender');
                this.explainDisable = true;
            } else {
                this.conflictForm.controls.explain.setValue(null);
                this.explainDisable = false;
            }
        }
    }

    get showForm_a(): boolean {
        const _res = this.explainOptionPrefix.indexOf(this.options[this.selectedOptionIndex].prefix) > -1;
        return _res;
    }

    get showForm(): boolean {
        if (this.options_a[0].name !== 'tag-input') {
            const _res = this.explainOptionPrefix_a.indexOf(this.options_a[this.selectedOptionIndex_a].prefix) > -1;
            if (_res && this.field) {
            } else {
                if (this.explainFieldSubscription) {
                    this.explainFieldSubscription.unsubscribe();
                    this.explainFieldSubscription = null;
                }
            }
            return _res;
        } else {
            return false;
        }
    }

    public valid() {

        const controls = this.conflictForm.controls;
        if (!this.conflictForm.valid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        }
    }

    sendDataToService() {
        const _conflict = new ConflictModel();
        _conflict.clear();
        _conflict.prefix = this.conflictData.name;
        _conflict.optionPrefix = this.options[this.selectedOptionIndex].prefix;

        _conflict.index = this.selectedOptionIndex;
        _conflict.optionType = this.conflictData.option_type;
        _conflict.text = this.conflictForm.controls.explain.value;
        _conflict.text2 = this.conflictForm.controls.explain2.value;
        _conflict.canEdit = this.canEdit;

        _conflict._isValid = this.explainOptionPrefix.indexOf(this.options[this.selectedOptionIndex].prefix) > -1 ? (_conflict.text && _conflict.text.length >= 20) : true;

        if (this.isDisclosure) {
            this.conflictService.updateDisclosureResult(_conflict);
        } else {
            _conflict.index_a = this.selectedOptionIndex_a;
            _conflict.optionType_a = this.conflictData.option_type_a;
            _conflict.optionPrefix_a = this.options_a[this.selectedOptionIndex_a].prefix;
            _conflict.type_of_conflict_id = this.conflictForm.controls.type_of_conflict_id.value;
            _conflict.appropriate_parties = this.conflictForm.controls.appropriate_parties.value;
            _conflict.reason_continue = this.conflictForm.controls.reason_continue.value;
            if (this.tpListComponent) {
                _conflict.coiDetailsTP = this.tpListComponent.currentTPs;
            }
            this.conflictService.updateConflictResult(_conflict);
        }
    }

    radioChanged() {

        // explain
        if (this.conflictData.name == 'purpose_of_valuation') {
            if (this.selectedOptionIndex < 1) {
                this.conflictForm.controls.explain.setValue('The valuation may not be acceptable to a lender');
                this.explainDisable = true;
            } else {
                this.conflictForm.controls.explain.setValue(null);
                this.explainDisable = false;
            }
        }

        this.sendDataToService();

        // if (this.conflictData.name == 'valuation_relied_on_other_parties') {
        //     this.conflictService.changedValueOfDisclosure(this.conflictData.name, this.selectedOptionIndex);
        // }
        setTimeout(() => {
            if (this.field) {
                this.field.nativeElement.focus();
            }
        }, 500);
    }

    addFiles() {
        //
        this.onClickFileBtn.emit();
    }

    countString() {
        return this.cntFile == 0 ? '' : `(${this.cntFile})`;
    }
}
