import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ParkingAdjustmentModel } from '../_models/parking-adjustment.model';

export enum ParkingAdjustmentsActionTypes {
    AllParkingAdjustmentsRequested = '[Parking Adjustments List] All Parking Adjustments Requested',
    AllParkingAdjustmentsLoaded = '[Parking Adjustments API] All Parking Adjustments Loaded',

    ParkingAdjustmentOnServerCreated = '[Edit Parking Adjustment] Parking Adjustment on Server Created',
    ParkingAdjustmentCreated = '[Edit Parking Adjustment] Parking Adjustment Created',
    ParkingAdjustmentUpdated = '[Edit Parking Adjustment] Parking Adjustment Updated',
    ParkingAdjustmentDeleted = '[Parking Adjustment Deleted] Parking Adjustment Deleted',

    ParkingAdjustmentRestored = '[Parking Adjustment Trash] Parking Adjustment Restored',
    ParkingAdjustmentOnServerRestored = '[Parking Adjustment Trash] Parking Adjustment On Server Restored',

    ParkingAdjustmentOnServerAdminRestored = '[Parking Adjustment Admin Trash] Parking Adjustment On Server Restored',
    ParkingAdjustmentAdminRestored = '[Parking Adjustment Admin Trash] Parking Adjustment Restored',

    ParkingAdjustmentDeletedFromTrash = '[Parking Adjustment Trash] Parking Adjustment deleted',
    ParkingAdjustmentDeletedFromAdminTrash = '[Parking Adjustment Admin Trash] Parking Adjustment deleted',

    ParkingAdjustmentTrash = 'Parking Adjustment Trashed',
    ParkingAdjustmentTrashFlushed = 'Parking Adjustment Trash Flushed',

    // Page system

    ParkingAdjustmentsPageRequested = '[Parking Adjustment List Page] Parking Adjustment Page Requested',
    ParkingAdjustmentsPageLoaded = '[Parking Adjustment API] Parking Adjustment Page Loaded',
    ParkingAdjustmentsPageCancelled = '[Parking Adjustment API] Parking Adjustment Page Cancelled',

    ParkingAdjustmentsPageToggleLoading = '[Parking Adjustment page] Parking Adjustment Page Toggle Loading',
    ParkingAdjustmentActionToggleLoading = '[Parking Adjustment] Parking Adjustment Action Toggle Loading'
}

export class ParkingAdjustmentOnServerCreated implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerCreated;

    constructor(public payload: { item: ParkingAdjustmentModel }) {
    }
}

export class ParkingAdjustmentCreated implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentCreated;

    constructor(public payload: { item: ParkingAdjustmentModel }) {
    }
}

export class ParkingAdjustmentUpdated implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentUpdated;

    constructor(public payload: {
        partialItem: Update<ParkingAdjustmentModel>,
        item: ParkingAdjustmentModel
    }) {
    }
}

export class ParkingAdjustmentDeleted implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingAdjustmentsPageRequested implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ParkingAdjustmentsPageLoaded implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageLoaded;

    constructor(public payload: { items: ParkingAdjustmentModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ParkingAdjustmentsPageCancelled implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageCancelled;
}

export class AllParkingAdjustmentsRequested implements Action {
    readonly type = ParkingAdjustmentsActionTypes.AllParkingAdjustmentsRequested;
}

export class AllParkingAdjustmentsLoaded implements Action {
    readonly type = ParkingAdjustmentsActionTypes.AllParkingAdjustmentsLoaded;

    constructor(public payload: { items: ParkingAdjustmentModel[] }) {
    }
}

export class ParkingAdjustmentsPageToggleLoading implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ParkingAdjustmentActionToggleLoading implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ParkingAdjustmentDeletedFromAdminTrash implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingAdjustmentDeletedFromTrash implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingAdjustmentOnServerRestored implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingAdjustmentRestored implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentRestored;

    constructor(public payload: { item: ParkingAdjustmentModel }) {
    }
}

export class ParkingAdjustmentOnServerAdminRestored implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ParkingAdjustmentAdminRestored implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentAdminRestored;

    constructor(public payload: { item: ParkingAdjustmentModel }) {
    }
}

export class ParkingAdjustmentTrashFlushed implements Action {
    readonly type = ParkingAdjustmentsActionTypes.ParkingAdjustmentTrashFlushed;

    constructor() {
    }
}

export type ParkingAdjustmentActions = ParkingAdjustmentCreated
    | ParkingAdjustmentUpdated
    | ParkingAdjustmentDeleted
    | ParkingAdjustmentsPageRequested
    | ParkingAdjustmentsPageLoaded
    | ParkingAdjustmentsPageCancelled
    | AllParkingAdjustmentsLoaded
    | AllParkingAdjustmentsRequested
    | ParkingAdjustmentOnServerCreated
    | ParkingAdjustmentDeletedFromAdminTrash
    | ParkingAdjustmentDeletedFromTrash
    | ParkingAdjustmentOnServerRestored
    | ParkingAdjustmentRestored
    | ParkingAdjustmentOnServerAdminRestored
    | ParkingAdjustmentAdminRestored
    | ParkingAdjustmentTrashFlushed
    | ParkingAdjustmentsPageToggleLoading
    | ParkingAdjustmentActionToggleLoading;