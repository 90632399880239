// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {AssetClassModel} from '../_models/asset-class.model';
import {ValuationModel} from '../_models/valuation.model';
import {AssetClassDefaultAssumptionModel} from '../_models/asset-class-default-assumption.model';
import {AssetClassSpecialAssumptionModel} from '../_models/asset-class-special-assumption.model';
import {AssetClassDepartureModel} from '../_models/asset-class-departure.model';
import {AssetClassSourceExternalReferenceModel} from '../_models/asset-class-source-external-reference.model';

export enum AssetClassActionTypes {
    AssetClassOnServerCreated = '[Edit AssetClass Dialog] AssetClass On Server Created',
    AssetClassCreated = '[Edit AssetClass Dialog] AssetClass Created',

    AssetClassOnServerUpdated = '[Edit AssetClass Dialog] AssetClass On Server Updated',
    AssetClassUpdated = '[Edit AssetClass Dialog] AssetClass Updated',

    AssetClasses = '[AssetClasses List] get all ',
    AssetClassesPageRequested = '[AssetClasses List Page] AssetClasses Page Requested',
    AssetClassMapPageRequested = '[AssetClasses Map Page] AssetClasses Page Requested',
    AssetClassesPageLoaded = '[AssetClasses API] AssetClasses Page Loaded',

    AssetClassOnServerRestored = '[AssetClass Trash] AssetClass On Server Restored',
    AssetClassRestored = '[AssetClass Trash] AssetClass Restored',
    AssetClassDeletedFromTrash = '[AssetClass Trash] AssetClass deleted',
    AssetClassTrash = 'AssetClass Trashed',
    AssetClassTrashFlushed = 'AssetClass Trashed Flushed',
    OneAssetClassDeleted = '[AssetClasses List Page] One AssetClass Deleted',
    AssetClassValuationDeleted = '[AssetClasses Dashboard] AssetClass Valuation Deleted',

    AssetClassesPageCancelled = '[AssetClasses API] AssetClasses Page Cancelled',
    AssetClassesPageToggleLoading = '[AssetClasses] AssetClasses Page Toggle Loading',
    AssetClassActionToggleLoading = '[AssetClasses] AssetClasses Action Toggle Loading',
    AssetClassGet = '[AssetClass Object] AssetClass Object Requested'
}

export class AssetClassOnServerCreated implements Action {
    readonly type = AssetClassActionTypes.AssetClassOnServerCreated;

    constructor(public payload: {
        assetClass: AssetClassModel,
        valuations: ValuationModel[],
        default_assumptions: AssetClassDefaultAssumptionModel[],
        special_assumptions: AssetClassSpecialAssumptionModel[],
        departures: AssetClassDepartureModel[],
        ser: AssetClassSourceExternalReferenceModel[]
    }) {
    }
}

export class AssetClassOnServerUpdated implements Action {
    readonly type = AssetClassActionTypes.AssetClassOnServerUpdated;

    constructor(public payload: {
        partialAssetClass: Update<AssetClassModel>,
        assetClass: AssetClassModel,
        valuations: ValuationModel[],
        default_assumptions: AssetClassDefaultAssumptionModel[],
        special_assumptions: AssetClassSpecialAssumptionModel[],
        departures: AssetClassDepartureModel[],
        ser: AssetClassSourceExternalReferenceModel[]
    }) {
    }
}

export class AssetClassGet implements Action {
    readonly type = AssetClassActionTypes.AssetClasses;

    constructor(public payload: { assetClassId: number }) {
    }
}

export class AssetClassCreated implements Action {
    readonly type = AssetClassActionTypes.AssetClassCreated;

    constructor(public payload: { assetClass: AssetClassModel }) {
    }
}

export class AssetClassUpdated implements Action {
    readonly type = AssetClassActionTypes.AssetClassUpdated;

    constructor(public payload: { assetClass: AssetClassModel }) {
    }
}

export class AssetClassValuationDeleted implements Action {
    readonly type = AssetClassActionTypes.AssetClassValuationDeleted;

    constructor(public payload: {
        tpId: number,
        valId: number
    }) {
    }
}

export class AssetClassesPageRequested implements Action {
    readonly type = AssetClassActionTypes.AssetClassesPageRequested;

    constructor(public payload: { page: QueryParamsModel, toeId: number }) {
    }
}

export class AssetClassMapPageRequested implements Action {
    readonly type = AssetClassActionTypes.AssetClassMapPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class AssetClassesPageLoaded implements Action {
    readonly type = AssetClassActionTypes.AssetClassesPageLoaded;

    constructor(public payload: {
        assetClasses: AssetClassModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}

export class AssetClassesPageCancelled implements Action {
    readonly type = AssetClassActionTypes.AssetClassesPageCancelled;
}

export class AssetClassesPageToggleLoading implements Action {
    readonly type = AssetClassActionTypes.AssetClassesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassActionToggleLoading implements Action {
    readonly type = AssetClassActionTypes.AssetClassActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassOnServerRestored implements Action {
    readonly type = AssetClassActionTypes.AssetClassOnServerRestored;

    constructor(public payload: { assetClassId: number }) {
    }
}

export class AssetClassRestored implements Action {
    readonly type = AssetClassActionTypes.AssetClassRestored;

    constructor(public payload: { assetClass: AssetClassModel }) {
    }
}

export class AssetClassDeletedFromTrash implements Action {
    readonly type = AssetClassActionTypes.AssetClassDeletedFromTrash;

    constructor(public payload: { assetClassId: number }) {
    }
}

export class AssetClassesTrashRequested implements Action {
    readonly type = AssetClassActionTypes.AssetClassTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AssetClassTrashFlushed implements Action {
    readonly type = AssetClassActionTypes.AssetClassTrashFlushed;

    constructor() {
    }
}

export class OneAssetClassDeleted implements Action {
    readonly type = AssetClassActionTypes.OneAssetClassDeleted;

    constructor(public payload: { id: number }) {
    }
}

export type AssetClassActions = AssetClassOnServerCreated
    | AssetClassOnServerUpdated
    | AssetClassCreated
    | AssetClassUpdated
    | AssetClassesPageRequested
    | AssetClassMapPageRequested
    | AssetClassesPageLoaded
    | AssetClassesPageCancelled
    | AssetClassesPageToggleLoading
    | AssetClassActionToggleLoading
    | AssetClassOnServerRestored
    | AssetClassRestored
    | AssetClassTrashFlushed
    | AssetClassDeletedFromTrash
    | OneAssetClassDeleted
    | AssetClassValuationDeleted
    | AssetClassGet;
