<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{rate.code}} - {{rate.name}} (Floor: {{floor}})</h3>
        </div>
    </div>
    <div class="form kt-form--label-align-right form-group-seperator-dashed">
        <!--<form [formGroup]="toeForm2" class="kt-form kt-form&#45;&#45;group-seperator-dashed">-->
        <form [formGroup]="form" class="form">
            <div class="card-body pt-0">
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Rating"
                                        [(ngModel)]="rate.rating"
                                        (selectionChange)="selectionChange()"
                                        formControlName="rating">

                                <mat-option [value]="'N/A'">Not applicable</mat-option>
                                <mat-option [value]="'N/I'">Not Inspected</mat-option>
                                <mat-option [value]="'Rate 1'">Rate 1</mat-option>
                                <mat-option [value]="'Rate 2'">Rate 2</mat-option>
                                <mat-option [value]="'Rate 3'">Rate 3</mat-option>
                            </mat-select>

                            <mat-error>Rating
                                <strong>required</strong>
                            </mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="isShowDesc">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea
                                    matInput
                                    placeholder="Description"
                                    formControlName="desc"
                                    [(ngModel)]="rate.description"
                            ></textarea>
                            <mat-error>Description
                                <strong>required</strong>
                            </mat-error>

                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
                <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()"
                        matTooltip="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>