import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Location } from '@angular/common';
import { MatDialog } from "@angular/material/dialog";
import { TypesUtilsService } from "src/app/core/_base/crud";
import { ComparablesRadioButtonValues } from "src/app/core/comparable/_models/RadioButtonValues";
import { BathroomFittingEditDialogComponent } from "../edit/bathroom-fitting-edit-dialog.component";

@Component({
  selector: 'kt-bathroom-fittings-list',
  templateUrl: './bathroom-fittings-sub-list.component.html',
  styleUrls: ['./bathroom-fittings-sub-list.component.scss']
})
export class BathroomFittingsSubListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() readonly: boolean;
  @Input() selectedBathroomFittings: any[];
  @Input() propertySubTypeId = 1;

  currentBathroomFittings: any[]
  dialogRef: any;
  radioButtonValues = ComparablesRadioButtonValues

  constructor(
    public dialog: MatDialog,
    public location: Location,
    public typesUtilsService: TypesUtilsService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedBathroomFittings) {
      this.currentBathroomFittings = changes.selectedBathroomFittings.currentValue
    }
  }

  ngOnInit(): void {
    this.currentBathroomFittings = this.selectedBathroomFittings 
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  chooseBathroomFittings() {
    this.dialogRef = this.dialog.open(BathroomFittingEditDialogComponent, {
      data: {
        currentBathroomFittings: this.currentBathroomFittings,
        propertySubTypeId: this.propertySubTypeId,
        title: this.getPlaceholder()
      }
    })
    this.dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.currentBathroomFittings = res.data
      }
      this.cdRef.markForCheck()
      this.cdRef.detectChanges()
    })
  }

  tagRemove($event) {
    const tempFittings = [...this.currentBathroomFittings]
    const index = tempFittings.findIndex(element => element.facility_id == $event.facility_id);
    if (index !== -1) {
      tempFittings.splice(index, 1)
    }
    this.currentBathroomFittings = [...tempFittings]
  }

  getPlaceholder() {
    return 'Bathroom fittings'
  }
}