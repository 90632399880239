import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { RetailStoreAdjustmentActions, RetailStoreAdjustmentsActionTypes } from "../_actions/retail-store-adjustment.actions";
import { RetailStoreAdjustmentModel } from "../_models/retail-store-adjustment.model";

export interface RetailStoreAdjustmentsState extends EntityState<RetailStoreAdjustmentModel> {
    isAllAdjustmentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: RetailStoreAdjustmentModel[];
    lastCreatedAdjustmentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RetailStoreAdjustmentModel> = createEntityAdapter<RetailStoreAdjustmentModel>();

export const initialAdjustmentsState: RetailStoreAdjustmentsState = adapter.getInitialState({
    isAllAdjustmentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAdjustmentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function retailStoreAdjustmentsReducer(state = initialAdjustmentsState, action: RetailStoreAdjustmentActions): RetailStoreAdjustmentsState {
    switch (action.type) {
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdjustmentId: undefined
            };
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentOnServerCreated:
            return {
                ...state
            };
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAdjustmentsId: action.payload.item.id
            });
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case RetailStoreAdjustmentsActionTypes.AllRetailStoreAdjustmentsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllAdjustmentssLoaded: true
            });
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialAdjustmentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case RetailStoreAdjustmentsActionTypes.RetailStoreAdjustmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();