// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {TaskEffortsService} from '../_services/task-effort.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allTaskEffortsLoaded} from '../_selectors/task-effort.selectors';
// Actions
import {
    AllTaskEffortsLoaded,
    AllTaskEffortsRequested,
    TaskEffortActionTypes,
    TaskEffortsPageRequested,
    TaskEffortsPageLoaded,
    TaskEffortUpdated,
    TaskEffortsPageToggleLoading,
    TaskEffortDeleted,
    TaskEffortOnServerCreated,
    TaskEffortCreated,
    TaskEffortAdminRestored,
    TaskEffortDeletedFromAdminTrash,
    TaskEffortDeletedFromTrash,
    TaskEffortOnServerAdminRestored,
    TaskEffortOnServerRestored,
    TaskEffortRestored,
    TaskEffortTrashFlushed,
    TaskEffortsActionToggleLoading
} from '../_actions/task-effort.actions';

@Injectable()
export class TaskEffortEffects {
    showPageLoadingDispatcher = new TaskEffortsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new TaskEffortsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new TaskEffortsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllTaskEfforts$ = this.actions$
        .pipe(
            ofType<AllTaskEffortsRequested>(TaskEffortActionTypes.AllTaskEffortsRequested),
            withLatestFrom(this.store.pipe(select(allTaskEffortsLoaded))),
            filter(([action, isAllTaskEffortsLoaded]) => !isAllTaskEffortsLoaded),
            mergeMap(() => this.service.getAllTaskEfforts()),
            map(res => {
                return new AllTaskEffortsLoaded({items: res.data});
            })
        );

    @Effect()
    loadTaskEffortsPage$ = this.actions$
        .pipe(
            ofType<TaskEffortsPageRequested>(TaskEffortActionTypes.TaskEffortsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findTaskEfforts(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new TaskEffortsPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortDeleted>(TaskEffortActionTypes.TaskEffortDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteTaskEffort(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortUpdated>(TaskEffortActionTypes.TaskEffortUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortOnServerCreated>(TaskEffortActionTypes.TaskEffortOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createTaskEffort(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new TaskEffortCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushTaskEffortTrash$ = this.actions$
        .pipe(
            ofType<TaskEffortTrashFlushed>(TaskEffortActionTypes.TaskEffortTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortOnServerRestored>(TaskEffortActionTypes.TaskEffortOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TaskEffortRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortOnServerAdminRestored>(TaskEffortActionTypes.TaskEffortOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new TaskEffortAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortDeletedFromTrash>(TaskEffortActionTypes.TaskEffortDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashTaskEffort$ = this.actions$
        .pipe(
            ofType<TaskEffortDeletedFromAdminTrash>(TaskEffortActionTypes.TaskEffortDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: TaskEffortsService, private store: Store<AppState>) {
    }
}
