<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <div class="card-label">Team Templates</div>
        </div>
        <div class="card-toolbar">
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
    </div>
    <div class="card-body">
        <kt-template-list
            [dataSource]="dataSource"
            [pageSize]="pageSize"
            [isSelection]="true"
            (onLoadList)="loadList($event)"
            (onSelectTemplate)="selectTemplate($event)"
            (onViewTemplate)="viewTemplate($event)">
        </kt-template-list>
    </div>
</div>