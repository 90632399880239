import { select, Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { BaseDataSource, QueryResultsModel } from "../../_base/crud";
import { selectParkingAdjustmentQueryResult, selectParkingAdjustmentsPageLoading, selectParkingAdjustmentsShowInitWaitingMessage } from "../_selectors/parking-adjustment.selectors";

export class ParkingAdjustmentsDataSource extends BaseDataSource {
    trashed = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectParkingAdjustmentsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectParkingAdjustmentsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectParkingAdjustmentQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        })
        
    }
}