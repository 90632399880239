// NGRX
import { Action } from '@ngrx/store';
// Models
import { AssetClassRetailFacilityModel } from '../_models/asset-class-retail-facility.model';

export enum AssetClassRetailFacilityActionTypes {
    AllAssetClassRetailFacilitiesRequested = '[AssetClassRetailFacilities Home Page] All AssetClassRetailFacilities Requested',
    AllAssetClassRetailFacilitiesLoaded = '[AssetClassRetailFacilities API] All AssetClassRetailFacilities Loaded',
    AssetClassRetailFacilityActionToggleLoading = '[AssetClassRetailFacility] AssetClassRetailFacility Action Toggle Loading',
}

export class AllAssetClassRetailFacilitiesRequested implements Action {
    readonly type = AssetClassRetailFacilityActionTypes.AllAssetClassRetailFacilitiesRequested;
    constructor(public payload: { assetClassRetailId: number }) { }
}

export class AllAssetClassRetailFacilitiesLoaded implements Action {
    readonly type = AssetClassRetailFacilityActionTypes.AllAssetClassRetailFacilitiesLoaded;
    constructor(public payload: { assetClassRetailFacilities: AssetClassRetailFacilityModel[] }) { }
}

export class AssetClassRetailFacilityActionToggleLoading implements Action {
    readonly type = AssetClassRetailFacilityActionTypes.AssetClassRetailFacilityActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AssetClassRetailFacilityActions = AllAssetClassRetailFacilitiesRequested
| AllAssetClassRetailFacilitiesLoaded
| AssetClassRetailFacilityActionToggleLoading;
