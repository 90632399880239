// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {TypeOfRegistration} from '../_models/type-of-registration.model';

export enum TypeOfRegistrationActionTypes {

    AllTypeOfRegistrationsRequested = '[TypeOfRegistrations Home Page] All TypeOfRegistrations Requested',
    AllTypeOfRegistrationsLoaded = '[TypeOfRegistrations API] All TypeOfRegistrations Loaded',

    TypeOfRegistrationOnServerCreated = '[Edit TypeOfRegistration Dialog] TypeOfRegistration On Server Created',
    TypeOfRegistrationCreated = '[Edit TypeOfRegistrations Dialog] TypeOfRegistrations Created',
    TypeOfRegistrationUpdated = '[Edit TypeOfRegistration Dialog] TypeOfRegistration Updated',
    TypeOfRegistrationDeleted = '[TypeOfRegistrations List Page] TypeOfRegistration Deleted',

    TypeOfRegistrationsPageRequested = '[TypeOfRegistrations List Page] TypeOfRegistrations Page Requested',
    TypeOfRegistrationsPageLoaded = '[TypeOfRegistrations API] TypeOfRegistrations Page Loaded',
    TypeOfRegistrationsPageCancelled = '[TypeOfRegistrations API] TypeOfRegistrations Page Cancelled',

    TypeOfRegistrationsPageToggleLoading = '[TypeOfRegistrations page] TypeOfRegistrations Page Toggle Loading',
    TypeOfRegistrationsActionToggleLoading = '[TypeOfRegistrations] TypeOfRegistrations Action Toggle Loading',

    // trash system

    TypeOfRegistrationOnServerRestored = '[TypeOfRegistration Trash] TypeOfRegistration On Server Restored',
    TypeOfRegistrationRestored = '[TypeOfRegistration Trash] TypeOfRegistration Restored',

    TypeOfRegistrationOnServerAdminRestored = '[TypeOfRegistration Admin Trash] TypeOfRegistration On Server Restored',
    TypeOfRegistrationAdminRestored = '[TypeOfRegistration Admin Trash] TypeOfRegistration Restored',

    TypeOfRegistrationDeletedFromTrash = '[TypeOfRegistration Trash] TypeOfRegistration deleted',
    TypeOfRegistrationDeletedFromAdminTrash = '[TypeOfRegistration Admin Trash] TypeOfRegistration deleted',

    TypeOfRegistrationTrash = 'TypeOfRegistration Trashed',
    TypeOfRegistrationTrashFlushed = 'TypeOfRegistration Trash Flushed',
}

export class TypeOfRegistrationOnServerCreated implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerCreated;

    constructor(public payload: { typeOfRegistration: TypeOfRegistration}) {
    }
}

export class TypeOfRegistrationCreated implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationCreated;

    constructor(public payload: { typeOfRegistration: TypeOfRegistration }) {
    }
}

export class TypeOfRegistrationUpdated implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationUpdated;

    constructor(public payload: {
        partialTypeOfRegistration: Update<TypeOfRegistration>,
        typeOfRegistration: TypeOfRegistration
    }) {
    }
}

export class TypeOfRegistrationDeleted implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfRegistrationsPageRequested implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class TypeOfRegistrationsPageLoaded implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationsPageLoaded;

    constructor(public payload: { typeOfRegistrations: TypeOfRegistration[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class TypeOfRegistrationsPageCancelled implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationsPageCancelled;
}

export class AllTypeOfRegistrationsRequested implements Action {
    readonly type = TypeOfRegistrationActionTypes.AllTypeOfRegistrationsRequested;
}

export class AllTypeOfRegistrationsLoaded implements Action {
    readonly type = TypeOfRegistrationActionTypes.AllTypeOfRegistrationsLoaded;

    constructor(public payload: { typeOfRegistrations: TypeOfRegistration[] }) {
    }
}

export class TypeOfRegistrationsPageToggleLoading implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class TypeOfRegistrationsActionToggleLoading implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class TypeOfRegistrationDeletedFromAdminTrash implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class TypeOfRegistrationDeletedFromTrash implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfRegistrationOnServerRestored implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfRegistrationRestored implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationRestored;

    constructor(public payload: { item: TypeOfRegistration }) {
    }
}

export class TypeOfRegistrationOnServerAdminRestored implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class TypeOfRegistrationAdminRestored implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationAdminRestored;

    constructor(public payload: { item: TypeOfRegistration }) {
    }
}

export class TypeOfRegistrationTrashFlushed implements Action {
    readonly type = TypeOfRegistrationActionTypes.TypeOfRegistrationTrashFlushed;

    constructor() {
    }
}

export type TypeOfRegistrationActions = TypeOfRegistrationCreated
    | TypeOfRegistrationUpdated
    | TypeOfRegistrationDeleted
    | TypeOfRegistrationsPageRequested
    | TypeOfRegistrationsPageLoaded
    | TypeOfRegistrationsPageCancelled
    | AllTypeOfRegistrationsLoaded
    | AllTypeOfRegistrationsRequested
    | TypeOfRegistrationOnServerCreated
    | TypeOfRegistrationsPageToggleLoading
    | TypeOfRegistrationsActionToggleLoading
    | TypeOfRegistrationDeletedFromAdminTrash
    | TypeOfRegistrationDeletedFromTrash
    | TypeOfRegistrationOnServerRestored
    | TypeOfRegistrationRestored
    | TypeOfRegistrationOnServerAdminRestored
    | TypeOfRegistrationAdminRestored
    | TypeOfRegistrationTrashFlushed;
