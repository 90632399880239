import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AssetClassDetailModel } from 'src/app/core/asset_class';
import { FloorRateModel } from 'src/app/core/asset_class/_models/floor-rate.model';
import { environment } from 'src/environments/environment';
import { MadNgbImageViewerComponent } from '../../../shared_components/mad-ngb-image-viewer/mad-ngb-image-viewer.component';
import { MMImageViewerDialogComponent } from '../image_viewer/mm-image-viewer.dialog.component';
import { SliderImageViewerComponent, SliderImageViewerInput } from '../slider-image-viewer/slider-image-viewer.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type ModuleData = {
  id: number;
  slideId: string;
  type: string;
  orderNum: number;
  name: string;
  url: string;
  description?: string;
}

@Component({
  selector: 'kt-mm-pictures-module',
  templateUrl: './pictures-module.component.html',
  styleUrls: ['./pictures-module.component.scss']
})
export class PicturesModuleComponent implements OnInit {
  @Input() actionTemplate: TemplateRef<any>;
  @Input() tpDetails: AssetClassDetailModel;
  @Input() changeSlide$: Observable<string>;
  @Input() pictureType: string;
  @Input() conditionRatingPictures$: Observable<any[]> = of([]);
  @Input() useMatDialog: boolean = true;
  @ViewChild(NgbCarousel) ngbCarousel: NgbCarousel;
  data$: Observable<ModuleData[]>
  ratingList: FloorRateModel[] = [];
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pictures: any[]
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {    
    this.data$ = this.conditionRatingPictures$.pipe(
      mergeMap(ratingPics => {
        return combineLatest([
          of(this.pictureType != 'rating' ? this.tpDetails.ac_pictures : []),
          of(this.pictureType != 'rating' ? this.tpDetails.building_information.files: []),
          of(this.pictureType == 'rating' ? ratingPics : [])
        ])
      }),
      map(([acPictures, buildingPictures, ratingPictures]) => {
        const newAcPictures: ModuleData[] = acPictures
          .filter(img => !img.hasOwnProperty('rating_desc') || img.rating_desc == '')
          .map((img, i) => {
            const pData: ModuleData = {
              id: img.id,
              slideId: `ac-${img.id}`,
              type: 'Property Picture',
              orderNum: i + 1,
              name: img.title ? img.title : 'N/A',
              url: this._getImageUrl(img)
            };
            return pData;
          });

        const newRatingPictures: ModuleData[] = ratingPictures
          .map((img, i) => {
            const pData: ModuleData = {
              id: img.id,
              slideId: `ac-${img.id}`,
              type: '',
              orderNum: i + 1,
              name: img.title ? img.title : 'N/A',
              description: '',
              url: this._getImageUrl(img)
            };
            return pData;
          });

        const newBuildingPictures: ModuleData[] = buildingPictures.map((img, i) => {
          const pData: ModuleData = {
            id: img.id,
            slideId: `b-${img.id}`,
            type: 'Building Picture',
            orderNum: i + 1,
            name: img.title ? img.title : 'N/A',
            url: this._getImageUrl(img)
          };
          return pData;
        });
        if (this.pictureType == 'ac') {
          this.pictures = [...newAcPictures];
          return this.pictures
        } else if (this.pictureType == 'rating') {
          this.pictures = [...newRatingPictures];
          return this.pictures
        } else if (this.pictureType == 'building') {
          this.pictures = [...newBuildingPictures];
          return this.pictures
        }
        this.pictures = [...newAcPictures, ...newRatingPictures, ...newBuildingPictures]
        return this.pictures
      })
    );
  }

  _getImageUrl(img: any): string {
    let _picture = img.path + '/' + img.name;
    const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
    return imgURL;
  }

  previewUploadedFile(obj, index) {
    if (this.useMatDialog) {
      this.dialog.open<SliderImageViewerComponent, SliderImageViewerInput>(SliderImageViewerComponent, {
        data: {
          images: this.pictures.map(pic => ({
            picture: pic.url,
            type: this.getFileType(pic.name),
            img: pic
          })),
          index: `img-${index}`
        },
        // height: '80vh'
        maxWidth: '85%',
        width: 'fit-content'
      })
      // const dialogRef = this.dialog.open(MMImageViewerDialogComponent, {
      //   data: {
      //     picture: obj.url,
      //     type: this.getFileType(obj.name),
      //     img: obj,
      //     otherImages: this.pictures.map(item => ({
      //       picture: item.url,
      //       type: this.getFileType(item.name),
      //       img: item
      //     }))
      //   },
      //   maxWidth: '85%',
      //   maxHeight: '85%',
      //   width: 'fit-content',
      // });
    } else {
      const modalRef = this.modalService.open(MadNgbImageViewerComponent, {
          windowClass: 'condition_rating_new_picture_modal',
          backdrop: 'static'
      });
      modalRef.componentInstance.obj = {
        picture: obj.url,
        type: this.getFileType(obj.name),
        img: obj
      }
      modalRef.componentInstance.dataWithoutViewer = false;
    }
  }

  getFileType(name) {
    return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
  }
}
