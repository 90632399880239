import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportingProcessState } from '../_reducers/reporting_process.reducer';

export const selectReportingProcessState = createFeatureSelector<ReportingProcessState>(
  'reporting-process'
);

export const selectReporting = (id: number) =>
  createSelector(selectReportingProcessState, (state) => {
    return state.entities[id];
  });

export const selectReportingMarketResearch = (id: number) => 
  createSelector(selectReporting(id), state => {
      return state ? state.marketResearch : [];
  });

export const selectReportingAppendices = (id: number) =>
  createSelector(selectReporting(id), state => {
      return state ? state.appendices : [];
  })