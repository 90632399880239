import { Component, ElementRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { each } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AssetClassDetailModel, AssetClassModel } from 'src/app/core/asset_class';
import { ResidentialExternalAreaModel } from 'src/app/core/comparable';
import { selectCoordinateReferenceSystemById, selectGradeById, selectPlaningStatusById, selectStandardMeasurementById, selectUnitAreaMeasurementById, selectUnitMeasurementById, StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { selectSubCategoryById } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectSubTypeCategoryById } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import { MapModalComponent } from '../../map-modal/map-modal.component';
import { SizeTableModalComponent } from '../../size-modal/size-modal.component';

type ModuleData = {
  assetClass: string;
  propertyType: string;
  subTypeCategory: string;
  subCategory: string;
  tenure: string;
  standardMeasurementName: string;
  propertySize: string;
  buildingType: string;
  buildingGrade: string;
  locationGrade: string;
  address: string;
  propertyGrade: string;
  coordinateRefSys: string;
  totalLandParcel: number;
  totalLandAreaUnit: string;
  totalLandArea: number;
  planningStatus: string,
  numberOfBuildings: string;
}

@Component({
  selector: 'kt-property-specifics-module',
  templateUrl: './property-specifics-module.component.html',
  styleUrls: ['../info-module.styles.scss', './property-specifics-module.component.scss']
})
export class PropertySpecificsModuleComponent implements OnInit {
  @Input() targetProperty: AssetClassModel;
  @Input() tpDetails: AssetClassDetailModel;
  @Input() toe: ToeModel;
  @Input() subData: any;
  @Input() multimedia = false;
  data$: Observable<ModuleData>;
  unitAreaMeasurement$: Observable<UnitAreaMeasurement>;
  bedrooms$: Observable<number>;
  externalAreas$: Observable<ResidentialExternalAreaModel[]>;
  sm: StandardMeasurement;
  hasSizeTable: boolean = false;
  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private smUtility: StandardMeasurementUtility
  ) { }

  ngOnInit(): void {
    this.bedrooms$ = of(this.tpDetails.bedroom);
    this.externalAreas$ = of(this.tpDetails.selected_external_area);
    this.unitAreaMeasurement$ = this.store$.select(selectUnitAreaMeasurementById(this.tpDetails.unit_measurement_id))
    this.data$ = combineLatest([
      of(this.targetProperty.type_name),
      of(this.targetProperty.top_property_type_name),
      this.store$.select(selectGradeById(this.tpDetails.location_grade_id)).pipe(map(val => val ? val.name : undefined), filter(val => val !== undefined)),
      this.store$.select(selectGradeById(this.tpDetails.building.building_grade_id ? this.tpDetails.building.building_grade_id : this.tpDetails.building_information.grade_id)).pipe(map(val => val ? val.name : undefined)),
      of(this.tpDetails.country_name),
      this.store$.select(selectUnitMeasurementById(this.toe.unit_of_measurement_id)).pipe(map(val => val ? val.area : undefined), filter(val => val !== undefined)),
      this.activatedRoute.data.pipe(map(res => res.subData ? res.subData.sizes : this.subData.sizes)),
      this.store$.select(selectStandardMeasurementById(this.tpDetails.measurement_standard_id)),
      this.store$.select(selectGradeById(this.tpDetails.aboutProperty.property_grade_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectSubTypeCategoryById(this.tpDetails.sub_type_category_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectSubCategoryById(this.tpDetails.sub_category_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectCoordinateReferenceSystemById(this.tpDetails.land_parcel.coordinate_reference_system_id)).pipe(map(val => val ? val.name : undefined)),
      this.store$.select(selectPlaningStatusById(this.tpDetails.land_parcel.planning_status_id)).pipe(map(val => val ? val.name : undefined))
    ]).pipe(map(([assetType, propertyType, locationGrade, buildingGrade, countryName, unitAreaMeasurement, sizes, sm, propertyGrade, subTypeCategory, subCategory, coordinateRefSys, planningStatus]) => {
      this.sm = sm;
      this.hasSizeTable = this.sm && this.targetProperty.instructed_to_measure == 1 && this.smUtility.checkHasOwnTable(this.sm.id);
      const landArea = this._totalLandArea(this.tpDetails)
      const data: ModuleData = {
        subTypeCategory,
        subCategory,
        assetClass: assetType,
        propertyType: propertyType,
        standardMeasurementName: this.sm ? this._getStandardMeasurementName(this.sm.name) : this.tpDetails.new_measurement_standard_name,
        propertySize: sizes && sizes.length > 0 ? sizes[0].size + ' (' + unitAreaMeasurement + ')' : '',
        buildingGrade: buildingGrade,
        locationGrade: locationGrade,
        buildingType: this.tpDetails.building ? this.tpDetails.building.building_type_name : this.tpDetails.building_information.type_name,
        address:  `${this.tpDetails.address} - ${this.tpDetails.city_name} - ${(this.tpDetails.zip_code ? this.tpDetails.zip_code + ' - ' : '')} ${countryName}`,
        propertyGrade,
        tenure: '',
        coordinateRefSys,
        totalLandParcel: this._totalLandParcel(this.tpDetails),
        totalLandAreaUnit: landArea.unit,
        totalLandArea: landArea.value,
        planningStatus,
        numberOfBuildings: this._numberOfBuildings(this.tpDetails)
      };
      return data;
    }));
  }

  private _totalLandParcel(tpDetails: AssetClassDetailModel): number {
    if (!tpDetails.land_parcel) {
      return 0;
    }
    if (!tpDetails.land_parcel.ac_detail_land_area) {
      return 0;
    }

    return tpDetails.land_parcel.ac_detail_land_area.length;
  }

  private _totalLandArea(tpDetails: AssetClassDetailModel): {unit: string, value: number} {
    if (!tpDetails.land_parcel) {
      return {unit: 'Sqm', value: 0}
    }
    if (!tpDetails.land_parcel.ac_detail_land_area) {
      return {unit: 'Sqm', value: 0}
    }

    return tpDetails.land_parcel.ac_detail_land_area.reduce((acc, la) => {
      return {unit: la.unit_area_measurement.acronym, value: acc.value + la.surface} 
    }, {unit: '', value: 0})
  }

  private _numberOfBuildings(tpDetails: AssetClassDetailModel): string {
    if (!tpDetails.land_parcel) {
      return 'Unknown'
    }

    if (tpDetails.land_parcel.building_exist != 1) {
      return 'Unknown'
    }

    return tpDetails.land_parcel.building_count.toString()
  }

  showInMap(event) {
    const target = new ElementRef(event.currentTarget);
    this.dialog.open(MapModalComponent, {
      width: '800px',
      height: '450px',
      backdropClass: 'map-backdrop',
      data: {
        latitude: this.tpDetails.latitude,
        longitude: this.tpDetails.longitude,
        trigger: target
      }
    })
  }

  showSizeTable() {
    const rooms = {
        bath: 0,
        kitchen: 0,
        bedrooms: 0,
        livingRooms: 0,
        toilet: 0
    }
    each(this.tpDetails.accommodation.data, (col) => {
        Object.entries(col).forEach(([key, value]) => {
            if (rooms.hasOwnProperty(key)) {
            rooms[key] += value != undefined ? Number(value) : 0; 
            }
        })
    })
    this.dialog.open(SizeTableModalComponent, {
      width: '80vw',
      data: {
        ac_id: this.targetProperty.id,
        standard_measurement: this.sm,
        unitAreaMeasurement$: this.unitAreaMeasurement$,
        readonly: true,
        bedrooms$: this.bedrooms$,
        externalAreas$: this.externalAreas$,
        floorAction$: of({
            type: 'remove',
            floors: [],
            scheme_id: this.tpDetails.aboutProperty.floor_numbering_scheme_id
        }),
        sizeChanges$: this.unitAreaMeasurement$.pipe(
            map(um => ({
                standard_measurement_id: this.sm.id,
                unit_area_measurement_id: um.id,
                unit_area_measurement_name: um.acronym
            }))
        ),
        roomChanges$: of(rooms)
      },
      viewContainerRef: this.viewContainerRef
    });
  }

  _getStandardMeasurementName(name: string): string {
    if (name.indexOf('-') == -1) {
      return name;
    }
    return name.substring(0, name.indexOf('-'));
  }
}
