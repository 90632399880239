        <form class="form" [formGroup]="formGroup" *ngIf="formGroup" [ngClass]="{'readonly': readonly}">
            <div class="form-group">
                <div class="row">
                    <div class="col-12">
                        <h5 class="my-3 text-mad text-uppercase">template information</h5>
                        <hr class="active">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput type="text" placeholder="Template Name" formControlName="name">
                            <mat-error>Template Name <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput type="text" placeholder="Template Description" formControlName="description"></textarea>
                            <mat-error>Template Description <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-12">
                        <h5 class="my-3 text-mad text-uppercase">target property information</h5>
                        <hr class="active">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Property Type" formControlName="target_property_type_id">
                                <mat-select-trigger>
                                    <span class="text-danger font-weight-bold" *ngIf="(customTp$|async); else regular">
                                        Custom
                                    </span>
                                    <ng-template #regular>
                                        <span>
                                            {{getAssetClassType(formGroup.controls.target_property_type_id.value)}}
                                        </span>
                                    </ng-template>
                                </mat-select-trigger>
                                <mat-option
                                    *ngFor="let type of topPropertyTypes" [value]="type.id">
                                    {{type.title}}
                                </mat-option>
                                <mat-option [value]="-1">
                                    <span class="text-danger font-weight-bold">
                                        Custom
                                    </span>
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                    *ngIf="(customTp$|async)"
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.CUSTOM_ASSET_CLASS.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.CUSTOM_ASSET_CLASS.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="(customTp$|async)">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput type="text" placeholder="Asset Class Name" formControlName="custom_asset_class_name">
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="formGroup.controls.target_property_type_id.value && formGroup.controls.target_property_type_id.value != subPropertyTypes.Custom">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Property Sub-Type" formControlName="property_type_id">
                                <mat-option *ngFor="let propertyType of filteredPropertyTypes" [value]="propertyType.id">
                                    {{propertyType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="(customTp$|async)">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Property Type" formControlName="custom_property_type"/>
                            <mat-icon matSuffix class="cursor-pointer icon-gray" container="body" (click)="$event.stopPropagation()"
                                [popoverTitle]="'TOOLTIP.TP.CUSTOM_PROPERTY_TYPE.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.TP.CUSTOM_PROPERTY_TYPE.DESCRIPTION' | translate">
                                help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-4">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select  placeholder="Premise of Value" formControlName="premise_of_value_id">
                                <mat-option [value]="0">-</mat-option>
                                <mat-option *ngFor="let premiseOfValue of premiseOfValues" [value]="premiseOfValue.id">{{premiseOfValue.name}}</mat-option>
                            </mat-select>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.PREMISE.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.PREMISE.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div> -->
                    <!-- <div *ngIf="formGroup.controls.target_property_type_id.value && formGroup.controls.target_property_type_id.value == subPropertyTypes.Land" class="col-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select  placeholder="Land Tenure" formControlName="tenure_id">
                                <mat-option *ngFor="let tenure of landTenures" [value]="tenure.id">{{tenure.name + '(' + tenure.payment_type + ')'}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="formGroup.controls.target_property_type_id.value && formGroup.controls.target_property_type_id.value != subPropertyTypes.Land && formGroup.controls.target_property_type_id.value != subPropertyTypes.Custom" class="col-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select  placeholder="Tenure" formControlName="tenure_id">
                                <mat-option *ngFor="let tenure of tenures" [value]="tenure.id">{{tenure.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="formGroup.controls.target_property_type_id.value && formGroup.controls.target_property_type_id.value == subPropertyTypes.Custom" class="col-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput  placeholder="Tenure" formControlName="custom_tenure"/>
                            <mat-icon matSuffix class="cursor-pointer icon-gray" container="body" (click)="$event.stopPropagation()"
                                [popoverTitle]="'TOOLTIP.TP.CUSTOM_TENURE.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.TP.CUSTOM_TENURE.DESCRIPTION' | translate">
                                help
                            </mat-icon>
                        </mat-form-field>
                    </div> -->
                    <!-- <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Interest Valued" formControlName="percentage" mask="percent" suffix="%"/>

                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.INTEREST_VALUED.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.INTEREST_VALUED.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="formGroup.controls.percentage.value && formGroup.controls.percentage.value < 100">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Expected Restricted Interest Discount (if any)" formControlName="discount" mask="percent" suffix="%"/>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.INTEREST_DISCOUNT.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.INTEREST_DISCOUNT.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="formGroup.controls.percentage.value && formGroup.controls.percentage.value < 100">
                        <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput
                                    placeholder="Clarify the relationship of the fractional interest being valued relative to all other fractional interests and the obligations of the fractional interest ownership, if any, to other fractional interest owners."
                                    rows="2" formControlName="discount_descr"></textarea>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Country" formControlName="country_id" (selectionChange)="selectCountry()">
                                <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
                            </mat-select>
                            <mat-error>Country
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="City" formControlName="city_id">
                                <mat-option *ngFor="let city of cityOfCountry" [value]="city.id">{{city.name}}</mat-option>
                                <mat-option *ngIf="cityOfCountry.length == 0" disabled>Please add city to Cities List</mat-option>
                            </mat-select>
                            <mat-error>City
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Zipcode/Postalcode" formControlName="zip_code"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form-group section-margin-top-60">
                <div class="row">
                    <div class="col-12">
                        <h5 class="my-3 text-mad text-uppercase">extent of investigation, limitations and restrictions during the inspection</h5>
                        <hr class="active">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" *ngFor="let radio of radios; let i = index">
                        <div class="mb-4 mt-4" *ngIf="radio.show">
                            <mat-label>
                                {{radio.label}}
                                <mat-icon
                                        *ngIf="radio.tooltip_key"
                                        class="cursor-pointer icon-gray"
                                        [popoverTitle]="'TOOLTIP.TP.'+radio.tooltip_key+'.TITLE' | translate"
                                        [ngbPopover]="'TOOLTIP.TP.'+radio.tooltip_key+'.DESCRIPTION' | translate"
                                        (click)="$event.stopPropagation();">help
                                </mat-icon>
                            </mat-label>
                        </div>
                        <mat-radio-group class="ml-4 mt-4 mb-2" [formControlName]="radio.formControlName" *ngIf="radio.show && i != 5">
                            <mat-radio-button class="mr-2"
                                                *ngFor="let decision of radio.decisions"
                                                [value]="decision.value">
                                {{decision.label}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-radio-group class="ml-4 mt-4 mb-2" [formControlName]="radio.formControlName" *ngIf="radio.show && i == 5">
                            <mat-radio-button class="mr-2"
                                                *ngFor="let decision of radio.decisions" [value]="decision.value"
                                                [disabled]="decision.disabled">
                                {{decision.label}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-form-field class="mat-form-field-fluid ml-4 mt-4" *ngIf="formGroup.controls[radio.formControlName].value == radio.triggerFlag && radio.triggerField == 'descr'">
                            <textarea matInput [placeholder]="radio.descr_label" rows="2" [formControlName]="radio.required[0]"></textarea>
                        </mat-form-field>
                        <div class="row align-items-center container mt-4">
                            <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="radio.triggerField == 'combos'">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Measurement Standard" [formControlName]="radio.required[0]">
                                        <mat-option [value]="0">-</mat-option>
                                        <mat-option *ngFor="let standardMeasurement of filteredStandardMeasurements" [value]="standardMeasurement.id">{{standardMeasurement.name}}</mat-option>
                                    </mat-select>

                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.MEASUREMENT.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.MEASUREMENT.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="formGroup.controls[radio.formControlName].value == radio.triggerFlag && radio.triggerField == 'combos'">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Measurement Methodology" [formControlName]="radio.required[1]">
                                        <mat-option [value]="0">-</mat-option>
                                        <mat-option *ngFor="let measurementMethodology of measurementMethodologies" [value]="measurementMethodology.id">{{measurementMethodology.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="formGroup.controls[radio.formControlName].value == radio.triggerFlag && radio.triggerField == 'combos'">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Purpose of Measurement" [formControlName]="radio.required[2]">
                                        <mat-option [value]="0">-</mat-option>
                                        <mat-option *ngFor="let purposeMeasurement of purposeMeasurements" [value]="purposeMeasurement.id">{{purposeMeasurement.name}}</mat-option>
                                    </mat-select>
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.PURPOSE.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.PURPOSE.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group section-margin-top-60">
                <div class="row">
                    <div class="col-12">
                        <h5 class="my-3 text-mad text-uppercase">valuation specifics</h5>
                        <hr class="active">
                    </div>
                    <div class="col-12">
                        <kt-ac-valuation 
                            [readonly]="readonly"
                            [customTp$]="customTp$.asObservable()"
                            [valuations$]="valuations$"
                            [propertyType$]="propertyType$"
                            [baseOfValueId$]="baseOfValueId$"
                            (onValuationAdded)="valuationAdd($event)"
                            (onValuationEdited)="valuationEdit($event)"
                            (onValuationRemoved)="valuationDelete($event)"></kt-ac-valuation>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Interest Valued" formControlName="percentage" mask="percent" suffix="%"/>

                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.INTEREST_VALUED.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.INTEREST_VALUED.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="formGroup.controls.percentage.value && formGroup.controls.percentage.value < 100">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Expected Restricted Interest Discount (if any)" formControlName="discount" mask="percent" suffix="%"/>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.INTEREST_DISCOUNT.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.INTEREST_DISCOUNT.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="formGroup.controls.percentage.value && formGroup.controls.percentage.value < 100">
                        <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput
                                    placeholder="Clarify the relationship of the fractional interest being valued relative to all other fractional interests and the obligations of the fractional interest ownership, if any, to other fractional interest owners."
                                    rows="2" formControlName="discount_descr"></textarea>
                            <mat-icon
                                    matSuffix
                                    class="cursor-pointer icon-gray"
                                    container="body"
                                    [popoverTitle]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.DESCRIPTION' | translate"
                                    (click)="$event.stopPropagation();">help
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form-group section-margin-top-60">
                <div class="row">
                    <div class="col-12">
                        <h5 class="my-3 text-mad text-uppercase">valuation methods performed on this target property</h5>
                        <hr class="active">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Country Currency" formControlName="country_currency" #countryCurrency (selectionChange)="selectCurrency()">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="filterCtrlCountriesData"
                                                            placeholderLabel="Search"
                                                            noEntriesFoundLabel="Country Currencies not found">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                                    {{_data.currencyCode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Reporting Currency" formControlName="reporting_currency" #reportingCurrency (selectionChange)="selectCurrency()">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="filterCtrlReportingData"
                                                            placeholderLabel="Search"
                                                            noEntriesFoundLabel="Reporting Currencies not found">

                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let _data of filteredReportsData | async" [value]="_data.currencyCode">
                                    {{_data.currencyCode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 kt-margin-bottom-10-mobile"
                            *ngIf="currencyDifferent()">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Basis of Currency Exchange Rate" formControlName="currency_exchange_rate"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form-group section-margin-top-60">
                <kt-assumptions-selection
                    [readonly]="readonly"
                    [selectedAssumptions]="selectedAssumptions"
                    [customAssumptions]="customAssumptionsData"></kt-assumptions-selection>
            </div>
            <div class="form-group section-margin-top-60">
                <kt-special-assumptions-selection 
                    [readonly]="readonly"
                    [selectedSpecialAssumptions]="selectedSpecialAssumptions"
                    [customSpecialAssumptions]="customSpecialAssumptions"></kt-special-assumptions-selection>
            </div>
            <div class="form-group section-margin-top-60">
                <kt-departures-selection
                    [readonly]="readonly"
                    [selectedDepartures]="selectedDepartures"
                    [customDepartures]="customDepartures"></kt-departures-selection>
            </div>

            <div class="form-group section-margin-top-60">
                <div class="row">
                    <div class="col-12">
                        <span style="display: flex; justify-content: space-between;">
                            <h5 class="my-3 text-mad text-uppercase">VPGA 10 – Matters that may give rise to material valuation uncertainty</h5>
                            <button type="button" mat-raised-button
                                    color="primary"
                                    *ngIf="!readonly"
                                    [matTooltip]="'Add New Field'"
                                    (click)="addVPGAMatterField()">
                                Add VPGA Matter
                            </button>
                        </span>
                        <hr class="active">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12  kt-margin-bottom-20-mobile" *ngFor="let control of vpgaFormArray.controls; let i = index">
                        <div class="row" style="align-items: center;">
                            <mat-form-field class="mat-form-field-fluid col-lg-11">
                                <textarea matInput [placeholder]="'VPGA Matter (' + (i + 1) + ')'"
                                            [formControl]="control"></textarea>
                            </mat-form-field>
                            <button type="button" [disabled]="readonly" mat-icon-button class="col-lg-1" color="warn"
                                    (click)="removeVPGAMatterField(i)">
                                <mat-icon [matTooltip]="'Remove Field'">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group section-margin-top-60">
                <div class="row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile pt-2">
                        <h5 class="my-3 text-mad text-uppercase">SOURCING AND EXTERNAL REFERENCES</h5>
                        <p>
                            <strong>Here you will add the information needed to perform the valuation.</strong><br>
                            Such information will be sourced from the client engaging us for the valuation. Should the required information to properly carry out the valuation not be
                            available,
                            assumptions and / or special assumptions have to be made as to the supposed nature of that information.
                        </p>
                        <hr class="active">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <kt-external-references-list [isTemplate]="true" [readonly]="readonly" [sourceExternalReferencesSubject]="sourceExternalReferenceSubject">
                        </kt-external-references-list>
                    </div>
                </div>
            </div>
        </form>