// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {SourceInformation} from '../_models/source-information.model';

export enum SourceInformationActionTypes {

    AllSourceInformationRequested = '[Source Information Home Page] All Source Information Requested',
    AllSourceInformationLoaded = '[Source Information API] All SourceInformation Loaded',

    SourceInformationOnServerCreated = '[Edit Source Information Dialog] Source Information On Server Created',
    SourceInformationCreated = '[Edit Source Information Dialog] Source Information Created',
    SourceInformationUpdated = '[Edit Source Information Dialog] SourceInformation Updated',
    SourceInformationDeleted = '[Source Information List Page] SourceInformation Deleted',

    SourceInformationPageRequested = '[Source Information List Page] Source Information Page Requested',
    SourceInformationPageLoaded = '[Source Information API] Source Information Page Loaded',
    SourceInformationPageCancelled = '[Source Information API] Source Information Page Cancelled',

    SourceInformationPageToggleLoading = '[Source Information page] Source Information Page Toggle Loading',
    SourceInformationActionToggleLoading = '[Source Information] Source Information Action Toggle Loading',

    // trash system

    SourceInformationOnServerRestored = '[SourceInformation Trash] SourceInformation On Server Restored',
    SourceInformationRestored = '[SourceInformation Trash] SourceInformation Restored',

    SourceInformationOnServerAdminRestored = '[SourceInformation Admin Trash] SourceInformation On Server Restored',
    SourceInformationAdminRestored = '[SourceInformation Admin Trash] SourceInformation Restored',

    SourceInformationDeletedFromTrash = '[SourceInformation Trash] SourceInformation deleted',
    SourceInformationDeletedFromAdminTrash = '[SourceInformation Admin Trash] SourceInformation deleted',

    SourceInformationTrash = 'SourceInformation Trashed',
    SourceInformationTrashFlushed = 'SourceInformation Trash Flushed',
}

export class SourceInformationOnServerCreated implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationOnServerCreated;

    constructor(public payload: { sourceInformation: SourceInformation}) {
    }
}

export class SourceInformationCreated implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationCreated;

    constructor(public payload: { sourceInformation: SourceInformation }) {
    }
}

export class SourceInformationUpdated implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationUpdated;

    constructor(public payload: {
        partialSourceInformation: Update<SourceInformation>,
        sourceInformation: SourceInformation
    }) {
    }
}

export class SourceInformationDeleted implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SourceInformationPageRequested implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SourceInformationPageLoaded implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationPageLoaded;

    constructor(public payload: { sourceInformation: SourceInformation[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SourceInformationPageCancelled implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationPageCancelled;
}

export class AllSourceInformationRequested implements Action {
    readonly type = SourceInformationActionTypes.AllSourceInformationRequested;
}

export class AllSourceInformationLoaded implements Action {
    readonly type = SourceInformationActionTypes.AllSourceInformationLoaded;

    constructor(public payload: { sourceInformation: SourceInformation[] }) {
    }
}

export class SourceInformationPageToggleLoading implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SourceInformationActionToggleLoading implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class SourceInformationDeletedFromAdminTrash implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class SourceInformationDeletedFromTrash implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SourceInformationOnServerRestored implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceInformationRestored implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationRestored;

    constructor(public payload: { item: SourceInformation }) {
    }
}

export class SourceInformationOnServerAdminRestored implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SourceInformationAdminRestored implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationAdminRestored;

    constructor(public payload: { item: SourceInformation }) {
    }
}

export class SourceInformationTrashFlushed implements Action {
    readonly type = SourceInformationActionTypes.SourceInformationTrashFlushed;

    constructor() {
    }
}

export type SourceInformationActions = SourceInformationCreated
    | SourceInformationUpdated
    | SourceInformationDeleted
    | SourceInformationPageRequested
    | SourceInformationPageLoaded
    | SourceInformationPageCancelled
    | AllSourceInformationLoaded
    | AllSourceInformationRequested
    | SourceInformationOnServerCreated
    | SourceInformationPageToggleLoading
    | SourceInformationActionToggleLoading
    | SourceInformationDeletedFromAdminTrash
    | SourceInformationDeletedFromTrash
    | SourceInformationOnServerRestored
    | SourceInformationRestored
    | SourceInformationOnServerAdminRestored
    | SourceInformationAdminRestored
    | SourceInformationTrashFlushed;
