import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { AssetClassModel } from 'src/app/core/asset_class';
import * as valuationActions from 'src/app/core/valuation/_actions/valuation.actions';
import { AppState } from 'src/app/core/reducers';
import { RemoveComparable, UpdateConsiderationCriterion } from 'src/app/core/valuation/_actions/criterion.actions';
import { SizeCriterionRemoveComparable } from 'src/app/core/valuation/_actions/size_criterion.actions';
import { RemoveComparableValuationAdjustment } from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import { selectAllValComparables, selectLastCurrencyConvertedComID, selectValuationData } from 'src/app/core/valuation/_selectors/valuation-data.selector';
import { ValuationDataModel } from 'src/app/core/valuation';
import { ChangeConsiderationCurrencyExchange, UpdateValuationData } from 'src/app/core/valuation/_actions/valuation-data.actions';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CurrencyExchangeService, ICurrencyExchange } from 'src/app/core/admin/_services/currency_exchange.service';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { MatDialog } from '@angular/material/dialog';
import { ComparableInfoDialogComponent } from '../../comparable-info-dialog/comparable-info-dialog.component';
import { ApartmentModalComponent } from 'src/app/views/pages/comparable/apartments/modal/apartment-modal.component';

interface IConvertedValue {
  [comId: number]: {
    [tenureId: number]: {
      rate: number,
      converted: number
    }
  }
}

@Component({
  selector: 'kt-comparable-tab-table',
  templateUrl: './comparable-tab-table.component.html',
  styleUrls: ['./comparable-tab-table.component.scss'],
})
export class ComparableTabTableComponent implements OnInit, OnDestroy {
  @Input()
  readonly: boolean;
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  currencyExchangeChannel: BehaviorSubject<ICurrencyExchange>;
  @Input()
  allConvertedChannel: BehaviorSubject<boolean>;
  @Output()
  onAddComparable: EventEmitter<void> = new EventEmitter();

  valData: ValuationDataModel;
  selectedComparables: any[] = [];
  convertedValues: IConvertedValue = {} ;

  atLeastOneNotConverted: boolean;

  form: UntypedFormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private store$: Store<AppState>,
    private modalService: NgbModal,
    private considerationCriterionService: ConsiderationCriterionService,
    private currencyExchangeService: CurrencyExchangeService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currencyExchangeService.getCurrencyExchangeRate(new Date(2020, 4, 10), ['MNT', 'GBP']).subscribe(
  );

    this.form = new UntypedFormGroup({});
    const valSub = this.store$
      .pipe(select(selectValuationData))
      .subscribe((res: ValuationDataModel) => {
        this.valData = res;
      });
    this.subscriptions.push(valSub);

    const comSub = combineLatest([
      this.store$.select(selectAllValComparables),
      this.store$.select(selectLastCurrencyConvertedComID),
      this.store$.select(selectValuationData)
    ]).subscribe(([com, id, valData]) => {
      if (com) {
        const currentControls = this.form.controls;
        Object.keys(currentControls).forEach((key) => {
          this.form.removeControl(key);
        });
        // const data = convertCom(com);
        this.creaFormControls(com);
        const _tempCom = [];
        com.forEach(c => _tempCom.push(c));
        _tempCom.sort((c1, c2) => c1.id - c2.id);
        this.selectedComparables = _tempCom;
        const converted = this.convertValues(this.selectedComparables);
        this.convertedValues = converted;
      }
      if (id && valData) {
        const com = this.selectedComparables.find(c => c.id === id);
        if (com) {
          this.store$.dispatch(new UpdateConsiderationCriterion({
            com: com, 
            tpTenureID: valData.valuation.tenure_id, 
            quote_currency: this.targetProperty.country_currency}))
        }
      }
    });
    this.subscriptions.push(comSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onFocusOut(event, ref_num: string, tenureId: number, sameCurrency: boolean) {
    // Find Control
    const control = this.form.get(ref_num + '_' + tenureId);
    const rate = Number(control.value);
    // Find conversion
    const com = this.selectedComparables.find((c) => c.ref_num == ref_num);
    const tenure = com.tenures.find((t) => t.id == tenureId);
    const conv = tenure.conversion.find(
      (conv) => conv.quote_currency == this.targetProperty.country_currency
    );

    // Do action
    if (conv && conv.id) {
      this.currencyExchangeChannel.next({
        id: conv.id,
        exchange_rate: rate,
      });
      this.store$.dispatch(
        new ChangeConsiderationCurrencyExchange({
          comId: com.id,
          tenureId: tenure.id,
          currencyExchange: {
            id: conv.id,
            exchange_rate: rate,
          },
          sameCurrency
        })
      );
    } else {
      // Create
      this.currencyExchangeChannel.next({
        date: com.source.source_date,
        base_currency: tenure.currency,
        quote_currency: this.targetProperty.country_currency,
        exchange_rate: rate,
      });
      this.store$.dispatch(
        new ChangeConsiderationCurrencyExchange({
          comId: com.id,
          tenureId: tenure.id,
          currencyExchange: {
            date: com.source.source_date,
            base_currency: tenure.currency,
            quote_currency: this.targetProperty.country_currency,
            exchange_rate: rate,
          },
          sameCurrency
        })
      );
    }
  }

  showAcInfo(com) {
    const dialogRef = this.dialog.open(ComparableInfoDialogComponent, {
      data: {
        propertySubTypeId: this.targetProperty.type_id,
        targetProperty: this.targetProperty,
        comparables: [com],
        valuation: this.valData,
        selectedIds: this.selectedComparables 
          ? this.selectedComparables.map(el => el.id)
          : [],
        addButton: this.readonly ? false : true,
        isMulti: false
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef,
      disableClose: true
    });
  }

  deleteAc(com) {
    const indx = this.selectedComparables.indexOf(com.id);
    this.store$.dispatch(new RemoveComparable({ comparableID: com.id }));
    this.store$.dispatch(
      new SizeCriterionRemoveComparable({ comparableID: com.id })
    );
    this.store$.dispatch(
      new RemoveComparableValuationAdjustment({ comID: com.id })
    );
    this.store$.dispatch(
      new valuationActions.RemoveValuation({ comID: com.id })
    );

    this.saveComData(this.selectedComparables.filter((el) => el.id != com.id));
  }

  showSelectedItemsInfo() {
    const dialogRef = this.dialog.open(ComparableInfoDialogComponent, {
      data: {
        propertySubTypeId: this.targetProperty.type_id,
        targetProperty: this.targetProperty,
        comparables: this.selectedComparables,
        selectedIds: this.selectedComparables 
          ? this.selectedComparables.map(el => el.id)
          : [],
        addButton: this.readonly ? false : true,
        isMulti: true
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef,
      disableClose: true
    })
  }

  isCurrencySame(tenures) {
    if (tenures.length == 1) {
      return false;
    }
    return tenures[0].currency === tenures[1].currency;
  }

  isTenureCurrencyNTpCurrenySame(t): boolean {
    return t.currency === this.targetProperty.country_currency;
  }

  private saveComData(_param) {
    const _item = Object.assign({}, this.valData);
    _item.comparables = _param;
    this.store$.dispatch(new UpdateValuationData({ item: _item }));
  }

  private creaFormControls(com) {
    com.forEach((c) => {
      if (this.isCurrencySame(c.tenures)) {
        const t = c.tenures[0];
        if (this.isTenureCurrencyNTpCurrenySame(t)) {
          return;
        }
        const conv = t.conversion.find(
          (con) => con.quote_currency === this.targetProperty.country_currency
        );
        if (conv) {
          const control = new UntypedFormControl({value: conv.exchange_rate, disabled: this.readonly});
          this.form.addControl(c.ref_num + '_' + t.id, control);
        } else {
          const control = new UntypedFormControl({value: 0, disabled: this.readonly});
          this.form.addControl(c.ref_num + '_' + t.id, control);
        }
      } else {
        c.tenures.forEach((t) => {
          if (this.isTenureCurrencyNTpCurrenySame(t)) {
            return;
          }
          const conv = t.conversion.find(
            (con) => con.quote_currency === this.targetProperty.country_currency
          );
          if (conv) {
            const control = new UntypedFormControl({value: conv.exchange_rate, disabled: this.readonly});
            this.form.addControl(c.ref_num + '_' + t.id, control);
          } else {
            const control = new UntypedFormControl({value: 0, disabled: this.readonly});
            this.form.addControl(c.ref_num + '_' + t.id, control);
          }
        });
      }
    });
  }

  private convertValues(com): IConvertedValue {
    const converted: IConvertedValue = {};
    com.forEach(c => {
      converted[c.id] = {};
      c.tenures.forEach(t => {
        if (this.isTenureCurrencyNTpCurrenySame(t)) {
          converted[c.id][t.id] = {
            rate: undefined,
            converted: t.total_consideration
          };
        } else {
          converted[c.id][t.id] = {
            rate: undefined,
            converted: undefined
          };
          const conv = t.conversion.find(
            (con) => con.quote_currency === this.targetProperty.country_currency
          );
          if (conv) {
            const convertedValue =
              Number(t.total_consideration) * Number(conv.exchange_rate);
            converted[c.id][t.id] = {
              rate: Number(conv.exchange_rate),
              converted: convertedValue
            };
            if (convertedValue <= 0) {
              converted[c.id][t.id] = {
                rate: undefined,
                converted: undefined
              };
            }
          }
        }
      })
    });
    return converted;
  }

  addComparable() {
    this.onAddComparable.emit();
  }
}

function convertCom(coms): any[] {
  const res = [];
  coms.forEach(c => {
    const newCom = Object.assign({}, c);
    const newTenures = [] ;
    c.tenures.forEach(t => {
      const newT = {
        ...t,
        exchangeRate: 500,
        convertedTotalConsideration: 345000,
      }
      newTenures.push(newT);
    })
    newCom.tenures = newTenures;
    res.push(newCom);
  })
  return res;
}
