// Angular
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from 'src/app/core/mad-auth/mad-auth.store';
import {TpTaskModel, TpTaskUpdated} from '../../../../../core/asset_class';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';
import {Update} from '@ngrx/entity';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import { BulkUpdateTpTasks } from 'src/app/core/asset_class/_actions/tp-task.actions';

@Component({
    selector: 'kt-pm-edit-dialog',
    templateUrl: './pm-edit-dialog.component.html'
})
export class PmEditDialogComponent implements OnInit {
    // Public properties
    viewLoading = false;
    form: UntypedFormGroup;
    users: User[] = [];
    item: TpTaskModel;
    managerId = 0;
    leadValuerId = 0;
    defaultDate = null;
    pmIsWorker: boolean;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>
     * @param fb
     * @param store
     * @param data: any
     * @param translate
     */
    constructor(public dialogRef: MatDialogRef<PmEditDialogComponent>,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.users = this.data.users;
        this.item = this.data.item;
        this.defaultDate = this.data.default_date;
        this.managerId = this.data.manager_id;
        this.leadValuerId = this.data.lead_valuer_id;
        this.pmIsWorker = this.data.pmIsWorker;
        this.createForm();
    }

    createForm() {
        let userId;
        let dateValue = null;
        if (this.item.user_id) {
            userId = this.item.user_id;
        } else {
            if (this.users.length > 0) {
                userId = this.users[0].id;
            }
        }

        if (this.item.date.length > 0) {
            dateValue = moment(this.item.date);
        } else {
            dateValue = moment(this.defaultDate);
        }

        this.form = this.fb.group({
            user: [userId, Validators.required],
            date: [dateValue]
        });
    }


    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Close dialog with true result
     */
    save(): void {
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            return;
        }
        let dateValue = '';
        if (controls.date.value) {
            dateValue = controls.date.value.format('YYYY-MM-DD HH:mm');
        }


        this.viewLoading = true;
        const changedItem = Object.assign({}, this.item) as TpTaskModel;
        changedItem.user_id = controls.user.value;
        const tmpUser = this.users.find(el => el.id == controls.user.value);
        changedItem.user_name = tmpUser.first_name + ' ' + (tmpUser.last_name ? tmpUser.last_name : '');
        changedItem.date = dateValue;

        if (this.item.task_id == 0 || this.item.task_id == 10 || this.item.task_id == 4) {
            this.store.dispatch(new BulkUpdateTpTasks({
                id: this.item.id,
                user_id: changedItem.user_id,
                user_name: changedItem.user_name,
                date: changedItem.date,
                tp_id: this.item.tp_id,
                task_id: this.item.task_id
            }));
        } else {

            const updateItem: Update<TpTaskModel> = {
                id: changedItem.id,
                changes: changedItem
            };
            this.store.dispatch(new TpTaskUpdated({
                partialItem: updateItem,
                item: changedItem
            }));
        }


        of(undefined).pipe(delay(1000)).subscribe(() => {
            this.viewLoading = false;
            this.dialogRef.close({});
        });
    }

    userRole(_id: number) {
        if (_id == this.managerId && this.managerId == this.leadValuerId) {
            return ' (Project manager & Lead Valuer)';
        } else if (_id == this.managerId && this.pmIsWorker) {
            return ' (Project manager & Supporting Staff) '
        } else if (_id == this.managerId) {
            return ' (Project manager)';
        } else if (_id == this.leadValuerId) {
            return ' (Lead Valuer)';
        }
        return '';
    }
}
