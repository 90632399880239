import {ApproachesToValue} from '../_models/approaches-to-value.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {ApproachesToValuesState} from '../_reducers/approaches-to-value.reducers';
import * as fromApproachesToValue from '../_reducers/approaches-to-value.reducers';
import {each} from 'lodash';

export const selectApproachesToValuesState = createFeatureSelector<ApproachesToValuesState>('approachesToValues');

export const selectApproachesToValueById = (approachesToValueId: number) => createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => approachesToValuesState.entities[approachesToValueId]
);

export const selectAllApproachesToValues = createSelector(
    selectApproachesToValuesState,
    fromApproachesToValue.selectAll
);

export const selectAllApproachesToValuesIds = createSelector(
    selectApproachesToValuesState,
    fromApproachesToValue.selectIds
);

export const allApproachesToValuesLoaded = createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => approachesToValuesState.isAllApproachesToValuesLoaded
);


export const selectApproachesToValuesPageLoading = createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => approachesToValuesState.listLoading
);

export const selectApproachesToValuesActionLoading = createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => approachesToValuesState.actionLoading
);

export const selectLastCreatedApproachesToValueId = createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => approachesToValuesState.lastCreatedApproachesToValueId
);

export const selectApproachesToValuesShowInitWaitingMessage = createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => approachesToValuesState.showInitWaitingMessage
);


export const selectTrashedApproachesToValueCount = createSelector(
    selectApproachesToValuesState,
    (approachesToValuesState) => approachesToValuesState.totalTrashed
);

export const selectAdminTrashedApproachesToValueCount = createSelector(
    selectApproachesToValuesState,
    (approachesToValuesState) => approachesToValuesState.totalAdminTrashed
);


export const selectApproachesToValueQueryResult = createSelector(
    selectApproachesToValuesState,
    approachesToValuesState => {
        const items: ApproachesToValue[] = [];
        each(approachesToValuesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ApproachesToValue[] = httpExtension.sortArray(items, approachesToValuesState.lastQuery.sortField, approachesToValuesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, approachesToValuesState.queryRowsCount, '');
    }
);
