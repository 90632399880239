import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded, ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded, ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded, ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded, LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP, LoadConflictOfAgencyHasPreviousInvolvementWithTheClient, LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP, LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient, ToeConflictActionTypes } from "../_actions/toe-conflict.actions";
import { ToeConflictsService } from "../_services/toe-conflicts.service";

@Injectable()
export class ToeConflictsEffects {
    constructor(private actions: Actions, private service: ToeConflictsService) {}
    @Effect()
    loadLVInvolvementWithClients = this.actions.pipe(
        ofType<LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient>(ToeConflictActionTypes.LoadConflictOfLeadValuerHasPreviousInvolvementWithTheClient),
        switchMap(({payload}) => {
            return this.service.loadLvInvolvementsWithClient(payload.lead_valuer_id, payload.client_id, payload.toe_id)
        }),
        map(res => {
            return new ConflictOfLeadValuerHasPreviousInvolvementWithTheClientLoaded(res)
        })
    )

    @Effect()
    loadAgencyInvolvementWithClients = this.actions.pipe(
        ofType<LoadConflictOfAgencyHasPreviousInvolvementWithTheClient>(ToeConflictActionTypes.LoadConflictOfAgencyHasPreviousInvolvementWithTheClient),
        switchMap(({payload}) => {
            return this.service.loadAgencyInvolvementsWithClient(payload.agency_id, payload.client_id, payload.toe_id)
        }),
        map(res => {
            return new ConflictOfAgencyHasPreviousInvolvementWithTheClientLoaded(res)
        })
    )

    @Effect()
    loadLvInvolvementWithTps = this.actions.pipe(
        ofType<LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP>(ToeConflictActionTypes.LoadConflictOfLeadValuerAlreadyPerformedValuationOnTheTP),
        switchMap(({payload}) => {
            return this.service.loadLvInvolvementWithTp(payload.lead_valuer_id, payload.tp_queries, payload.toe_id)
        }),
        map(res => {
            return new ConflictOfLeadValuerAlreadyPerformedValuationOnTheTPLoaded(res)
        })
    )

    @Effect()
    loadAgencyInvolvementWithTps = this.actions.pipe(
        ofType<LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP>(ToeConflictActionTypes.LoadConflictOfAgencyAlreadyPerformedValuationOnTheTP),
        switchMap(({payload}) => {
            return this.service.loadAgencyInvolvementWithTp(payload.agency_id, payload.tp_queries, payload.toe_id)
        }),
        map(res => {
            return new ConflictOfAgencyAlreadyPerformedValuationOnTheTPLoaded(res)
        })
    )
}