// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {
    selectFacilityQueryResult,
    selectFacilitiesPageLoading,
    selectFacilitiesShowInitWaitingMessage
} from '../_selectors/facility.selectors';
import {skip} from 'rxjs/operators';

export class FacilitiesDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>, isBuilding: number, acTypeId: number) {
        super();

        this.loading$ = this.store.pipe(
            select(selectFacilitiesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectFacilitiesShowInitWaitingMessage)
        );

        this.store.pipe(
            skip(1),
            select(selectFacilityQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
