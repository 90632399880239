// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';

// Services
import {CurrencyService} from '../_services/currency.service';
// Actions
import {
    AllCurrenciesRequested,
    AllCurrenciesLoaded,
    CurrencyActionTypes,
    CurrenciesActionToggleLoading,
    CurrencyCreated,
    CurrencyOnServerCreated,
    CurrencyUpdated,
    CurrencyAdminRestored,
    CurrencyDeletedFromAdminTrash,
    CurrencyDeletedFromTrash,
    CurrencyOnServerAdminRestored,
    CurrencyOnServerRestored,
    CurrencyRestored,
    CurrencyTrashFlushed,
    CurrencyDeleted
} from '../_actions/currency.actions';

import {AppState} from '../../reducers';


@Injectable()
export class CurrencyEffects {

    showActionLoadingDispatcher = new CurrenciesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CurrenciesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllCurrencies$ = this.actions$
        .pipe(
            ofType<AllCurrenciesRequested>(CurrencyActionTypes.AllCurrenciesRequested),
            mergeMap(() => this.service.getAllCurrencies()),
            map((result: any) => {
                return new AllCurrenciesLoaded({
                    currencies: result.data,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed
                });
            })
        );


    @Effect()
    createCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyOnServerCreated>(CurrencyActionTypes.CurrencyOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createCurrency(payload.currency).pipe(
                    tap(res => {
                        this.store.dispatch(new CurrencyCreated({currency: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyUpdated>(CurrencyActionTypes.CurrencyUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateCurrency(payload.currency);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyDeleted>(CurrencyActionTypes.CurrencyDeleted),
            mergeMap(({payload}) => {
                    return this.service.deleteCurrency(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    flushCurrencyTrash$ = this.actions$
        .pipe(
            ofType<CurrencyTrashFlushed>(CurrencyActionTypes.CurrencyTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyOnServerRestored>(CurrencyActionTypes.CurrencyOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CurrencyRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyOnServerAdminRestored>(CurrencyActionTypes.CurrencyOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CurrencyAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteFromTrashCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyDeletedFromTrash>(CurrencyActionTypes.CurrencyDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashCurrency$ = this.actions$
        .pipe(
            ofType<CurrencyDeletedFromAdminTrash>(CurrencyActionTypes.CurrencyDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     return of(new AllCurrenciesRequested());
    // });

    constructor(private actions$: Actions, private service: CurrencyService, private store: Store<AppState>) {
    }
}
