<div class="row">
    <div class="col-12">
        <h4 class="my-3 text-mad text-uppercase d-flex align-items-center">
            <span>
                DOCUMENTS
            </span>
            <mat-icon matSuffix class="cursor-pointer icon-gray ml-4"
                inline
                container="body"
                [ngbPopover]="'Certificates, Insurance documents, etc.'"
                (click)="$event.stopPropagation();">help
            </mat-icon>
        </h4>
        <hr class="active">
    </div>
    <kt-upload-wrapper *ngIf="!readonly" class="col-12" [fileFolder]="agency.path" (fileUploaded)="onFileUploaded($event)"
        (pathChanged)="onPathChanged($event)">
    </kt-upload-wrapper>
    <div class="col-12" [ngClass]="{'readonly': readonly}">
        <div class="example-container mat-elevation-z0">
            <mat-table #table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <img [attr.src]="setIcon(getFileType(file.name))" alt="">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <input class="title-input" [value]="file.title" (change)="inputChange($event, file)">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>Expiration date</mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <ng-container *ngIf="file.date && file.date.length > 0; else na">
                            <span>{{file.date | date: 'dd MMM yyyy'}}</span>
                            <ng-container *ngIf="isExpired(file.date)">
                                <span class="ml-1" style="color: red">(expired!)</span>
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="insert_to_all_reports">
                    <mat-header-cell *matHeaderCellDef>Automatically insert to all reports</mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <mat-checkbox [checked]="file.is_checked" (change)="onchange($event, file)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <button *ngIf="!readonly" mat-icon-button color="primary" matTooltip="Edit Description"
                            (click)="editFileDescription(file)" type="button">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;
                        <button *ngIf="!readonly" mat-icon-button color="warn" matTooltip="Delete File" (click)="deleteUploadedFile(file)"
                            type="button">
                            <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                        <button mat-icon-button matTooltip="Preview file" (click)="previewUploadedFile(file)"
                            type="button">
                            <mat-icon *ngIf="!docs.includes(getFileType(file.name))">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="docs.includes(getFileType(file.name))">get_app</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</div>

<ng-template #na>
    N/A
</ng-template>