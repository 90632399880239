// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {OfficeStandardMeasurement} from '../_models/office-standard-measurement.model';

export enum OfficeStandardMeasurementActionTypes {

    AllOfficeStandardMeasurementsRequested = '[OfficeStandardMeasurements Home Page] All OfficeStandardMeasurements Requested',
    AllOfficeStandardMeasurementsLoaded = '[OfficeStandardMeasurements API] All OfficeStandardMeasurements Loaded',

    OfficeStandardMeasurementOnServerCreated = '[Edit OfficeStandardMeasurement Dialog] OfficeStandardMeasurement On Server Created',
    OfficeStandardMeasurementCreated = '[Edit OfficeStandardMeasurements Dialog] OfficeStandardMeasurements Created',
    OfficeStandardMeasurementUpdated = '[Edit OfficeStandardMeasurement Dialog] OfficeStandardMeasurement Updated',

    OfficeStandardMeasurementsPageRequested = '[OfficeStandardMeasurements List Page] OfficeStandardMeasurements Page Requested',
    OfficeStandardMeasurementsPageLoaded = '[OfficeStandardMeasurements API] OfficeStandardMeasurements Page Loaded',
    OfficeStandardMeasurementsPageCancelled = '[OfficeStandardMeasurements API] OfficeStandardMeasurements Page Cancelled',

    OfficeStandardMeasurementsPageToggleLoading = '[OfficeStandardMeasurements page] OfficeStandardMeasurements Page Toggle Loading',
    OfficeStandardMeasurementsActionToggleLoading = '[OfficeStandardMeasurements] OfficeStandardMeasurements Action Toggle Loading',

    OfficeStandardMeasurementDeleted = '[OfficeStandardMeasurements List Page] OfficeStandardMeasurement Deleted',

    // trash system

    OfficeStandardMeasurementOnServerRestored = '[OfficeStandardMeasurement Trash] OfficeStandardMeasurement On Server Restored',
    OfficeStandardMeasurementRestored = '[OfficeStandardMeasurement Trash] OfficeStandardMeasurement Restored',

    OfficeStandardMeasurementOnServerAdminRestored = '[OfficeStandardMeasurement Admin Trash] OfficeStandardMeasurement On Server Restored',
    OfficeStandardMeasurementAdminRestored = '[OfficeStandardMeasurement Admin Trash] OfficeStandardMeasurement Restored',

    OfficeStandardMeasurementDeletedFromTrash = '[OfficeStandardMeasurement Trash] OfficeStandardMeasurement deleted',
    OfficeStandardMeasurementDeletedFromAdminTrash = '[OfficeStandardMeasurement Admin Trash] OfficeStandardMeasurement deleted',

    OfficeStandardMeasurementTrash = 'OfficeStandardMeasurement Trashed',
    OfficeStandardMeasurementTrashFlushed = 'OfficeStandardMeasurement Trash Flushed',
}

export class OfficeStandardMeasurementOnServerCreated implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerCreated;

    constructor(public payload: { officeStandardMeasurement: OfficeStandardMeasurement }) {
    }
}

export class OfficeStandardMeasurementCreated implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementCreated;

    constructor(public payload: { officeStandardMeasurement: OfficeStandardMeasurement }) {
    }
}

export class OfficeStandardMeasurementUpdated implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementUpdated;

    constructor(public payload: {
        partialofficeStandardMeasurement: Update<OfficeStandardMeasurement>,
        officeStandardMeasurement: OfficeStandardMeasurement
    }) {
    }
}

export class OfficeStandardMeasurementDeleted implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeStandardMeasurementsPageRequested implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OfficeStandardMeasurementsPageLoaded implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageLoaded;

    constructor(public payload: { officeStandardMeasurements: OfficeStandardMeasurement[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OfficeStandardMeasurementsPageCancelled implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageCancelled;
}

export class AllOfficeStandardMeasurementsRequested implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.AllOfficeStandardMeasurementsRequested;
}

export class AllOfficeStandardMeasurementsLoaded implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.AllOfficeStandardMeasurementsLoaded;

    constructor(public payload: { officeStandardMeasurements: OfficeStandardMeasurement[] }) {
    }
}

export class OfficeStandardMeasurementsPageToggleLoading implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OfficeStandardMeasurementsActionToggleLoading implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OfficeStandardMeasurementDeletedFromAdminTrash implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class OfficeStandardMeasurementDeletedFromTrash implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeStandardMeasurementOnServerRestored implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeStandardMeasurementRestored implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementRestored;

    constructor(public payload: { item: OfficeStandardMeasurement }) {
    }
}

export class OfficeStandardMeasurementOnServerAdminRestored implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OfficeStandardMeasurementAdminRestored implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementAdminRestored;

    constructor(public payload: { item: OfficeStandardMeasurement }) {
    }
}

export class OfficeStandardMeasurementTrashFlushed implements Action {
    readonly type = OfficeStandardMeasurementActionTypes.OfficeStandardMeasurementTrashFlushed;

    constructor() {
    }
}

export type OfficeStandardMeasurementActions = OfficeStandardMeasurementCreated
    | OfficeStandardMeasurementUpdated
    | OfficeStandardMeasurementsPageRequested
    | OfficeStandardMeasurementsPageLoaded
    | OfficeStandardMeasurementsPageCancelled
    | AllOfficeStandardMeasurementsLoaded
    | AllOfficeStandardMeasurementsRequested
    | OfficeStandardMeasurementOnServerCreated
    | OfficeStandardMeasurementsPageToggleLoading
    | OfficeStandardMeasurementsActionToggleLoading
    | OfficeStandardMeasurementDeleted
    | OfficeStandardMeasurementDeletedFromAdminTrash
    | OfficeStandardMeasurementDeletedFromTrash
    | OfficeStandardMeasurementOnServerRestored
    | OfficeStandardMeasurementRestored
    | OfficeStandardMeasurementOnServerAdminRestored
    | OfficeStandardMeasurementAdminRestored
    | OfficeStandardMeasurementTrashFlushed;
