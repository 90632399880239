import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { QueryParamsModel } from "src/app/core/_base/crud";
import { SubdomainService } from "src/app/core/_base/subdomain.service";
import { KeyPlacesPageRequested, selectAllKeyPlaces } from "src/app/core/admin";
import { AllKeyCategoriesRequested, selectAllKeyCategories } from "src/app/core/linked-tables";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { AppState } from "src/app/core/reducers";
import { environment } from "src/environments/environment";


@Injectable()
export class LandmarksService implements OnDestroy {
  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/investigation-landmarks`
  private _categories = [];
  private _categories$ = this.store.select(selectAllKeyCategories).pipe(
    tap((categories) => this._categories = categories)
  )
  private _selectedCategories$ = new BehaviorSubject([])

  public selectedCategories$ = this._selectedCategories$.asObservable().pipe(
    tap(() => this.fetchLandmarks())
  )

  public allSelected$ = combineLatest([ 
    this.selectedCategories$,
    this._categories$
  ]).pipe(
    map(([selectedCategories, categories]) => selectedCategories.length == categories.length)
  )

  public categories$ = combineLatest([
    this.selectedCategories$,
    this._categories$
  ]).pipe(
    map(([selectedCategories, categories]) => {
      return categories.map(category => {
        return {
          ...category,
          selected: selectedCategories.findIndex(sc => sc.id == category.id) != -1
        }
      })
    })
  )

  public landmarks$ = this.store.select(selectAllKeyPlaces)

  _selectedLandmarks = []
  public selectedLandmarks$ = new BehaviorSubject([])
  private _targetPropertyLocation$ = new BehaviorSubject<{latitude: number, longitude: number}>(null)
  targetPropertyLocation$ = this._targetPropertyLocation$.asObservable()

  // public selectedKeyPlaces = []

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {
    this.store.dispatch(new AllKeyCategoriesRequested())
  }

  ngOnDestroy(): void {
    const queryParams = new QueryParamsModel(
      {
        name: '',
        key_categories: ''
      },
      'asc',
      'id',
      1,
      100
    )
    this.store.dispatch(new KeyPlacesPageRequested({page: queryParams}))
  }

  setAll() {
    this._selectedCategories$.next(this._categories.map(category => ({id: category.id})))
  }

  unsetAll() {
    this._selectedCategories$.next([])
  }

  check(id: number) {
    const selectedCategories = this._selectedCategories$.value
    selectedCategories.push({id: id})
    this._selectedCategories$.next(selectedCategories)
  }

  uncheck(id: number) {
    const selectedCategories = this._selectedCategories$.value
    const index = selectedCategories.findIndex(sc => sc.id === id)
    selectedCategories.splice(index, 1)
    this._selectedCategories$.next(selectedCategories)
  }

  fetchLandmarks() {
    const filter: any = {}
    filter.name = ''
    filter.key_categories = this._selectedCategories$.value.map(val => val.id).join('-')

    const queryParams = new QueryParamsModel(
      filter,
      'asc',
      'id',
      1,
      100
    )
    this.store.dispatch(new KeyPlacesPageRequested({page: queryParams}))
  }

  addLandmark(landmark) {
    let _item = Object.assign([], this._selectedLandmarks);

    if (this.alreadyAdded(landmark)) {
      _item = this._selectedLandmarks.filter((item) => {
        return item.id != landmark.id;
      })
    } else {
      _item = [...this._selectedLandmarks, landmark];
    }
    this._selectedLandmarks = _item
    this.selectedLandmarks$.next(this._selectedLandmarks)
  }

  alreadyAdded(landmark) {
    if (this._selectedLandmarks.length == 0) {
      return false
    }
    return this._selectedLandmarks.filter(item => item.id === landmark.id).length
  }

  deleteLandmark(landmark) {
    const _item = this._selectedLandmarks.filter(el => el.id != landmark.id)
    this._selectedLandmarks = _item
    this.selectedLandmarks$.next(_item)
  }

  fetchInvestigationLandmarks(assetClassId: number) {
    const httpHeaders = this.authService.getAuthHeaders()
    return this.httpClient.get<{
      data: {
        landmarks: Array<any>,
        target_property_location: {
          latitude: string,
          longitude: string
        }
      }
    }>(`${this.API_URL}/edit/${assetClassId}`, {headers: httpHeaders}).pipe(
      map(res => res.data),
      tap(({landmarks, target_property_location}) => {
        this._selectedLandmarks = landmarks
        this.selectedLandmarks$.next(this._selectedLandmarks)
        this._selectedCategories$.next(this._selectedLandmarks.map(item => ({id: item.category_id})))
        this._targetPropertyLocation$.next({
          latitude: Number(target_property_location.latitude),
          longitude: Number(target_property_location.longitude)
        })
      })
    )
  }

  submit(assetClassId) {
    const httpHeaders = this.authService.getAuthHeaders()
    return this.httpClient.post(`${this.API_URL}/${assetClassId}`, {items: this._selectedLandmarks}, {headers: httpHeaders})
  }
} 