import { Action } from "@ngrx/store";
import { ReportType } from "src/app/views/pages/multimedia/_sub/files/signed-reports/signed-reports.component";
import { TpSignedDoc } from "../_models/tp-signed-doc.model";

export enum TpSignedDocActionTypes {
	GetSignedDocs = '[Signed Reports Table] Get signed documents',
	GotSignedDocs = '[Signed Reports Effect] Got signed documents',

	UploadSignedDoc = '[Signed Reports Table] Uploads signed document',

	SignedDocUploaded = '[Signed Reports Effect] Signed document uploaded',
	SignedDocUploadFailed = '[Signed Reports Effect] Signed document upload failed',

	DeleteSignedDoc = '[Signed Reports Table] Delete report',
	SignedDocDeleted = '[Signed Reports Effect] Report deleted',

	EmptyAction = '[Signed Reports Effect] empty action'
}

export class GetTpSignedDocs implements Action {
	readonly type = TpSignedDocActionTypes.GetSignedDocs;
	constructor(public payload: {tpID: number}) {}
}
export class GotTpSignedDocs implements Action {
	readonly type = TpSignedDocActionTypes.GotSignedDocs;
	constructor(public payload: {docs: TpSignedDoc[]}) {}
}

export class UploadTpSignedDoc implements Action {
	readonly type = TpSignedDocActionTypes.UploadSignedDoc;
	constructor(public payload: {
		tpID: number,
		data: FormData
	}) {}
}

export class TpSignedDocUploaded implements Action {
	readonly type = TpSignedDocActionTypes.SignedDocUploaded;
	constructor(public payload: {
		model: TpSignedDoc
	}) {}
}

export class TpSignedDocUploadFailed implements Action {
	readonly type = TpSignedDocActionTypes.SignedDocUploadFailed;
	constructor(public payload: {
		tpID: number,
		error: Error
	}) {}
}

export class TpSignedDocEmtpyAction implements Action {
	readonly type = TpSignedDocActionTypes.EmptyAction;
	constructor() {}
}

export class DeleteSignedDoc implements Action {
	readonly type = TpSignedDocActionTypes.DeleteSignedDoc;
	constructor(public payload: {id: number, tpID: number, type: ReportType}) {}
}
export class SignedDocDeleted implements Action {
	readonly type = TpSignedDocActionTypes.SignedDocDeleted;
	constructor(public payload: {id: number}) {}
}

export type TpSignedDocActions 
	= UploadTpSignedDoc
	| TpSignedDocUploaded
	| TpSignedDocUploadFailed
	| GetTpSignedDocs
	| GotTpSignedDocs
	| TpSignedDocEmtpyAction
	| DeleteSignedDoc
	| SignedDocDeleted;