<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{data.participant ? 'Edit Participant' : 'New Participant'}}
      </h3>
    </div>
  </div>
  <div>
    <form [formGroup]="form" class="form form-group-seperator-dashed">
      <div class="card-body">
        <div class="form-group kt-form__group row">
          <div class="col-lg-6">
            <mat-form-field class="mat-form-field-fluid">
              <mat-select placeholder="Participant Side" formControlName="side">
                <mat-option *ngFor="let item of service.participantSides" [value]="item.value">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error>Participant Side is <strong>required</strong></mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Participan Full Name" formControlName="name" />
              <mat-error>Participant full name is <strong>required</strong></mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group kt-form__group row">
          <div class="col-lg-12">
            <mat-form-field class="mat-form-field-fluid">
              <textarea matInput rows="4" type="text" placeholder="Extra Note" formControlName="descr"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="card-footer p-0 b-0">
        <div class="form-actions form-actions-solid">
          <div class="row text-right">
            <div class="col-lg-12" >
              <button type="button" class="mr-4" mat-raised-button (click)="onClose()">
                Close
              </button>
              <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form> 
  </div>
</div>