import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllResidentialAdjustmentsRequested, AllOfficeAdjustmentsRequested, ResidentialAdjustmentModel, selectAllResidentialAdjustments, selectAllOfficeAdjustments } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { AllHouseAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/house-adjustment.actions';
import { AllLandAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/land-adjustment.actions';
import { AllParkingAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/parking-adjustment.actions';
import { AllRetailBuildingAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/retail-building-adjustment.actions';
import { AllRetailStoreAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/retail-store-adjustment.actions';
import { AllWarehouseAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/warehouse-adjustment.actions';
import { selectAllWarehouseAdjustments } from 'src/app/core/linked-tables/_selectors/warehouse-adjustment.selectors';
import { selectAllHouseAdjustments } from 'src/app/core/linked-tables/_selectors/house-adjustment.selectors';
import { selectAllParkingAdjustments } from 'src/app/core/linked-tables/_selectors/parking-adjustment.selectors';
import { selectAllRetailStoreAdjustments } from 'src/app/core/linked-tables/_selectors/retail-store-adjustment.selectors';
import { selectAllRetailBuildingAdjustments } from 'src/app/core/linked-tables/_selectors/retail-building-adjustment.selectors';
import { selectAllLandAdjustments } from 'src/app/core/linked-tables/_selectors/land-adjustment.selectors';

@Component({
  selector: 'kt-add-adjustments-modal-v2',
  templateUrl: './add-adjustments-modal-v2.component.html',
  styleUrls: ['./add-adjustments-modal-v2.component.scss']
})
export class AddAdjustmentsModalV2Component implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup;
  adjustments: Array<any & {selected: boolean}> = []
  showOthers = false

  private _selectedAdjustments: any[] = []
  private _onDestroy$: Subject<void> = new Subject()

  constructor(
    private dialogRef: MatDialogRef<AddAdjustmentsModalV2Component>,
    @Inject(MAT_DIALOG_DATA) private data: {
      selectedIds: number[],
      propertySubTypeId: number
    },
    private store$: Store<AppState>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    const others = new ResidentialAdjustmentModel()
    others.clear()
    others.id = 0;
    others.name = 'Others'

    switch(this.data.propertySubTypeId) {
      case 1:
        this.store$.dispatch(new AllResidentialAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllResidentialAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 3:
        this.store$.dispatch(new AllOfficeAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllOfficeAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 7:
        this.store$.dispatch(new AllWarehouseAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllWarehouseAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 17:
        this.store$.dispatch(new AllHouseAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllHouseAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 2:
        this.store$.dispatch(new AllParkingAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllParkingAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 5:
        this.store$.dispatch(new AllRetailStoreAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllRetailStoreAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 11:
        this.store$.dispatch(new AllRetailBuildingAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllRetailBuildingAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
      case 13:
        this.store$.dispatch(new AllLandAdjustmentsRequested());
        this.store$.pipe(
          select(selectAllLandAdjustments),
          takeUntil(this._onDestroy$)
        ).subscribe(res => this.setAdjustments(res, others))
        break;
    }


    this.formGroup = this.formBuilder.group({
      name: new UntypedFormControl(''),
      desc: new UntypedFormControl('')
    }) 

  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  private setAdjustments(res, others) {
    let _adjustments = [{...others, selected: false}]
    if (res) {
      _adjustments = [...(res.map(item => ({...item, selected: false}))), {...others, selected: false}]
    }
    this.adjustments = _adjustments.map(item => {
      if (this.data.selectedIds.includes(item.id)) {
        return {...item, selected: true}
      }
      return item
    })
  }

  onChangeCheckBox(event: MatCheckboxChange, item: ResidentialAdjustmentModel) {
    if (event.checked) {
      this._selectedAdjustments.push(item);
    } else {
      this._selectedAdjustments = this._selectedAdjustments.filter(el => el.id != item.id);
    }

    if (this._selectedAdjustments.find(el => el.id == 0)) {
      this.showOthers = true;
      this.formGroup.get('name').setValidators([Validators.required])
      this.formGroup.get('desc').setValidators([Validators.required]);
      this.formGroup.get('name').updateValueAndValidity();
      this.formGroup.get('desc').updateValueAndValidity();
    } else {
      this.showOthers = false;
      this.formGroup.get('name').clearValidators();
      this.formGroup.get('desc').clearValidators();
      this.formGroup.get('name').updateValueAndValidity();
      this.formGroup.get('desc').updateValueAndValidity();
    }
  }

  onClose() {
    this.dialogRef.close()
  }

  onSubmit() {
    const controls = this.formGroup.controls;
    if (this.formGroup.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
      );
      return;
    }

    const response: {name: string, description: string, id: number}[] = this._selectedAdjustments.map(ad => {
      return {
        id: ad.id,
        name: ad.id == 0 ? controls.name.value : ad.name,
        description: ad.id == 0 ? controls.desc.value : ad.description
      };
    });

    this.dialogRef.close(response);
  }

}
