// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ComparativeLandUse} from '../_models/comparative-land-use.model';

export enum ComparativeLandUseActionTypes {

    AllComparativeLandUsesRequested = '[ComparativeLandUses Home Page] All ComparativeLandUses Requested',
    AllComparativeLandUsesLoaded = '[ComparativeLandUses API] All ComparativeLandUses Loaded',

    ComparativeLandUseOnServerCreated = '[Edit ComparativeLandUse Dialog] ComparativeLandUse On Server Created',
    ComparativeLandUseCreated = '[Edit ComparativeLandUses Dialog] ComparativeLandUses Created',
    ComparativeLandUseUpdated = '[Edit ComparativeLandUse Dialog] ComparativeLandUse Updated',
    ComparativeLandUseDeleted = '[ComparativeLandUses List Page] ComparativeLandUse Deleted',

    ComparativeLandUsesPageRequested = '[ComparativeLandUses List Page] ComparativeLandUses Page Requested',
    ComparativeLandUsesPageLoaded = '[ComparativeLandUses API] ComparativeLandUses Page Loaded',
    ComparativeLandUsesPageCancelled = '[ComparativeLandUses API] ComparativeLandUses Page Cancelled',

    ComparativeLandUsesPageToggleLoading = '[ComparativeLandUses page] ComparativeLandUses Page Toggle Loading',
    ComparativeLandUsesActionToggleLoading = '[ComparativeLandUses] ComparativeLandUses Action Toggle Loading',

    // trash system

    ComparativeLandUseOnServerRestored = '[ComparativeLandUse Trash] ComparativeLandUse On Server Restored',
    ComparativeLandUseRestored = '[ComparativeLandUse Trash] ComparativeLandUse Restored',

    ComparativeLandUseOnServerAdminRestored = '[ComparativeLandUse Admin Trash] ComparativeLandUse On Server Restored',
    ComparativeLandUseAdminRestored = '[ComparativeLandUse Admin Trash] ComparativeLandUse Restored',

    ComparativeLandUseDeletedFromTrash = '[ComparativeLandUse Trash] ComparativeLandUse deleted',
    ComparativeLandUseDeletedFromAdminTrash = '[ComparativeLandUse Admin Trash] ComparativeLandUse deleted',

    ComparativeLandUseTrash = 'ComparativeLandUse Trashed',
    ComparativeLandUseTrashFlushed = 'ComparativeLandUse Trash Flushed',
}

export class ComparativeLandUseOnServerCreated implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseOnServerCreated;

    constructor(public payload: { comparativeLandUse: ComparativeLandUse}) {
    }
}

export class ComparativeLandUseCreated implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseCreated;

    constructor(public payload: { comparativeLandUse: ComparativeLandUse }) {
    }
}

export class ComparativeLandUseUpdated implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseUpdated;

    constructor(public payload: {
        partialComparativeLandUse: Update<ComparativeLandUse>,
        comparativeLandUse: ComparativeLandUse
    }) {
    }
}

export class ComparativeLandUseDeleted implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ComparativeLandUsesPageRequested implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUsesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ComparativeLandUsesPageLoaded implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUsesPageLoaded;

    constructor(public payload: { comparativeLandUses: ComparativeLandUse[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ComparativeLandUsesPageCancelled implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUsesPageCancelled;
}

export class AllComparativeLandUsesRequested implements Action {
    readonly type = ComparativeLandUseActionTypes.AllComparativeLandUsesRequested;
}

export class AllComparativeLandUsesLoaded implements Action {
    readonly type = ComparativeLandUseActionTypes.AllComparativeLandUsesLoaded;

    constructor(public payload: { comparativeLandUses: ComparativeLandUse[] }) {
    }
}

export class ComparativeLandUsesPageToggleLoading implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUsesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ComparativeLandUsesActionToggleLoading implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUsesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class ComparativeLandUseDeletedFromAdminTrash implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class ComparativeLandUseDeletedFromTrash implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ComparativeLandUseOnServerRestored implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ComparativeLandUseRestored implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseRestored;

    constructor(public payload: { item: ComparativeLandUse }) {
    }
}

export class ComparativeLandUseOnServerAdminRestored implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ComparativeLandUseAdminRestored implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseAdminRestored;

    constructor(public payload: { item: ComparativeLandUse }) {
    }
}

export class ComparativeLandUseTrashFlushed implements Action {
    readonly type = ComparativeLandUseActionTypes.ComparativeLandUseTrashFlushed;

    constructor() {
    }
}

export type ComparativeLandUseActions = ComparativeLandUseCreated
    | ComparativeLandUseUpdated
    | ComparativeLandUseDeleted
    | ComparativeLandUsesPageRequested
    | ComparativeLandUsesPageLoaded
    | ComparativeLandUsesPageCancelled
    | AllComparativeLandUsesLoaded
    | AllComparativeLandUsesRequested
    | ComparativeLandUseOnServerCreated
    | ComparativeLandUsesPageToggleLoading
    | ComparativeLandUsesActionToggleLoading
    | ComparativeLandUseDeletedFromAdminTrash
    | ComparativeLandUseDeletedFromTrash
    | ComparativeLandUseOnServerRestored
    | ComparativeLandUseRestored
    | ComparativeLandUseOnServerAdminRestored
    | ComparativeLandUseAdminRestored
    | ComparativeLandUseTrashFlushed;
