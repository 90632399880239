// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectors
import {selectHandoverStandardQueryResult, selectHandoverStandardsPageLoading, selectHandoverStandardsShowInitWaitingMessage} from '../_selectors/handover-standard.selectors';

export class HandoverStandardsDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectHandoverStandardsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectHandoverStandardsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectHandoverStandardQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
