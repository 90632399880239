// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {InteractionTypesService} from '../_services/interaction-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allInteractionTypesLoaded} from '../_selectors/interaction-type.selectors';
// Actions
import {
    AllInteractionTypesLoaded,
    AllInteractionTypesRequested,
    InteractionTypeActionTypes,
    InteractionTypesPageRequested,
    InteractionTypesPageLoaded,
    InteractionTypeUpdated,
    InteractionTypesPageToggleLoading,
    InteractionTypeDeleted,
    InteractionTypeOnServerCreated,
    InteractionTypeCreated,
    InteractionTypeAdminRestored,
    InteractionTypeDeletedFromAdminTrash,
    InteractionTypeDeletedFromTrash,
    InteractionTypeOnServerAdminRestored,
    InteractionTypeOnServerRestored,
    InteractionTypeRestored,
    InteractionTypeTrashFlushed,
    InteractionTypesActionToggleLoading
} from '../_actions/interaction-type.actions';

@Injectable()
export class InteractionTypeEffects {
    showPageLoadingDispatcher = new InteractionTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new InteractionTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new InteractionTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllInteractionTypes$ = this.actions$
        .pipe(
            ofType<AllInteractionTypesRequested>(InteractionTypeActionTypes.AllInteractionTypesRequested),
            withLatestFrom(this.store.pipe(select(allInteractionTypesLoaded))),
            filter(([action, isAllInteractionTypesLoaded]) => !isAllInteractionTypesLoaded),
            mergeMap(() => this.service.getAllInteractionTypes()),
            map(res => {
                return new AllInteractionTypesLoaded({interactionTypes: res.data});
            })
        );

    @Effect()
    loadInteractionTypesPage$ = this.actions$
        .pipe(
            ofType<InteractionTypesPageRequested>(InteractionTypeActionTypes.InteractionTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findInteractionTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new InteractionTypesPageLoaded({
                    interactionTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeDeleted>(InteractionTypeActionTypes.InteractionTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteInteractionType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeUpdated>(InteractionTypeActionTypes.InteractionTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateInteractionType(payload.interactionType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeOnServerCreated>(InteractionTypeActionTypes.InteractionTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createInteractionType(payload.interactionType).pipe(
                    tap(res => {
                        this.store.dispatch(new InteractionTypeCreated({interactionType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushInteractionTypeTrash$ = this.actions$
        .pipe(
            ofType<InteractionTypeTrashFlushed>(InteractionTypeActionTypes.InteractionTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeOnServerRestored>(InteractionTypeActionTypes.InteractionTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new InteractionTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeOnServerAdminRestored>(InteractionTypeActionTypes.InteractionTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new InteractionTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeDeletedFromTrash>(InteractionTypeActionTypes.InteractionTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashInteractionType$ = this.actions$
        .pipe(
            ofType<InteractionTypeDeletedFromAdminTrash>(InteractionTypeActionTypes.InteractionTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: InteractionTypesService, private store: Store<AppState>) {
    }
}
