import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { ParkingAdjustmentActions, ParkingAdjustmentsActionTypes } from "../_actions/parking-adjustment.actions";
import { ParkingAdjustmentModel } from "../_models/parking-adjustment.model";

export interface ParkingAdjustmentsState extends EntityState<ParkingAdjustmentModel> {
    isAllAdjustmentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: ParkingAdjustmentModel[];
    lastCreatedAdjustmentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ParkingAdjustmentModel> = createEntityAdapter<ParkingAdjustmentModel>();

export const initialAdjustmentsState: ParkingAdjustmentsState = adapter.getInitialState({
    isAllAdjustmentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedAdjustmentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function parkingAdjustmentssReducer(state = initialAdjustmentsState, action: ParkingAdjustmentActions): ParkingAdjustmentsState {
    switch (action.type) {
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdjustmentId: undefined
            };
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentOnServerCreated:
            return {
                ...state
            };
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentCreated:
            return adapter.addOne(action.payload.item, {
                ...state, lastCreatedAdjustmentsId: action.payload.item.id
            });
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case ParkingAdjustmentsActionTypes.AllParkingAdjustmentsLoaded:
            return adapter.addAll(action.payload.items, {
                ...state, isAllAdjustmentssLoaded: true
            });
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentsPageLoaded:
            return adapter.addMany(action.payload.items, {
                ...initialAdjustmentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case ParkingAdjustmentsActionTypes.ParkingAdjustmentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case ParkingAdjustmentsActionTypes.ParkingAdjustmentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case ParkingAdjustmentsActionTypes.ParkingAdjustmentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case ParkingAdjustmentsActionTypes.ParkingAdjustmentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case ParkingAdjustmentsActionTypes.ParkingAdjustmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();