<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;"
               (click)="onNoClick()"
               class="btn btn-icon btn-sm btn-warning"
               data-card-tool="remove"
               data-toggle="tooltip"
               data-placement="top"
               title="Remove Card">
                <i class="ki ki-close icon-nm"></i>
            </a>
        </div>
    </div>
    <div class="card-body">
        <!-- <div [outerHTML]="data.src"></div> -->
        <iframe 
            [src]="src" 
            frameborder="0" 
            allowfullscreen 
            height="400"
            allow="autoplay; fullscreen; picture-in-picture"></iframe>
    </div>
</div>