// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {TpFinancialTaskActions, TpFinancialTaskActionTypes} from '../_actions/tp-financial-task.actions';
import {TpFinancialTaskModel} from '../_models/tp-financial-task.model';

// Models
import {QueryParamsModel} from '../../_base/crud';

export interface TpFinancialTaskState extends EntityState<TpFinancialTaskModel> {
    _isAllTpFinancialTaskLoaded: boolean;
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedItemId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TpFinancialTaskModel> = createEntityAdapter<TpFinancialTaskModel>();

export const initialTpFinancialTaskState: TpFinancialTaskState = adapter.getInitialState({
    _isAllTpFinancialTaskLoaded: false,
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedItemId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function tpFinancialTaskReducer(state = initialTpFinancialTaskState, action: TpFinancialTaskActions): TpFinancialTaskState {
    switch (action.type) {
        case TpFinancialTaskActionTypes.AllTpFinancialTaskRequested:
            return {
                ...state,
                _isAllTpFinancialTaskLoaded: false
            };
        case TpFinancialTaskActionTypes.AllTpFinancialTaskLoaded:
            return adapter.addAll(action.payload.tasks, {
                ...state,
                _isAllTpFinancialTaskLoaded: true
            });
        case TpFinancialTaskActionTypes.TpFinancialTaskCleared:
            return adapter.removeAll(state);

        case TpFinancialTaskActionTypes.TpFinancialTaskDeleted:
            return adapter.removeOne(action.payload.id, state);

        case TpFinancialTaskActionTypes.TpFinancialTaskOnServerCreated:
            return {
                ...state
            };
        case TpFinancialTaskActionTypes.TpFinancialTaskCreated:

            return adapter.addOne(action.payload.task, {
                ...state
            });

        case TpFinancialTaskActionTypes.TpFinancialTaskUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case TpFinancialTaskActionTypes.TpFinancialTaskByUserPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.assetClasses, {
                ...initialTpFinancialTaskState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case TpFinancialTaskActionTypes.AllTpFinancialTaskLoadedByUser:
            return adapter.addMany(action.payload.tasks, {
                ...initialTpFinancialTaskState,
                _isAllTpFinancialTaskLoaded: true,
                listLoading: false,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
            });
        case TpFinancialTaskActionTypes.TpFinancialTasksClear:
            return adapter.removeAll(initialTpFinancialTaskState);
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
