export interface PostValuationDeliveryServicetData {
    id: number;
    general_info: string;
    includes_in_toe: boolean;
    hourly_rate: number;
    currency: string;
    free_hours: number;
    validity_of_fees: number;
    include_text: string;
    overridden_in_toe: boolean;
}

export function newPostValuationDeliveryServicetData(): PostValuationDeliveryServicetData {
    return {
        id: undefined,
        general_info: '',
        includes_in_toe: false,
        hourly_rate: undefined,
        currency: '',
        free_hours: undefined,
        validity_of_fees: undefined,
        include_text: '',
        overridden_in_toe: false,
    };
}