import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { CriterionsTemplateModel } from "../_models";
import { CriterionsTemplateState } from "../_reducers";
import { criterionsTemplateSelectors } from "../_selectors";
import { TemplateTableDataSource } from "./base-template.datasource";

export class CriterionsTemplateDataSource extends TemplateTableDataSource<CriterionsTemplateModel> {
    constructor(private store: Store<CriterionsTemplateState>) {
        super();
        this.isLoading$ = this.store.select(criterionsTemplateSelectors.selectIsLoading);
        this.total$ = this.store.select(criterionsTemplateSelectors.selectTotal);

        this.store.pipe(
            takeUntil(this.destroy$),
            select(criterionsTemplateSelectors.selectCriterionsTemplates)
        ).subscribe(res => {
            this.entitySubject$.next(res);
        })
    }
}