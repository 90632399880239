// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {PurposeMeasurement} from '../_models/purpose-measurement.model';

export enum PurposeMeasurementActionTypes {

    AllPurposeMeasurementsRequested = '[PurposeMeasurements Home Page] All PurposeMeasurements Requested',
    AllPurposeMeasurementsLoaded = '[PurposeMeasurements API] All PurposeMeasurements Loaded',

    PurposeMeasurementOnServerCreated = '[Edit PurposeMeasurement Dialog] PurposeMeasurement On Server Created',
    PurposeMeasurementCreated = '[Edit PurposeMeasurements Dialog] PurposeMeasurements Created',
    PurposeMeasurementUpdated = '[Edit PurposeMeasurement Dialog] PurposeMeasurement Updated',
    PurposeMeasurementDeleted = '[PurposeMeasurements List Page] PurposeMeasurement Deleted',

    PurposeMeasurementsPageRequested = '[PurposeMeasurements List Page] PurposeMeasurements Page Requested',
    PurposeMeasurementsPageLoaded = '[PurposeMeasurements API] PurposeMeasurements Page Loaded',
    PurposeMeasurementsPageCancelled = '[PurposeMeasurements API] PurposeMeasurements Page Cancelled',

    PurposeMeasurementsPageToggleLoading = '[PurposeMeasurements page] PurposeMeasurements Page Toggle Loading',
    PurposeMeasurementsActionToggleLoading = '[PurposeMeasurements] PurposeMeasurements Action Toggle Loading',

    // trash system

    PurposeMeasurementOnServerRestored = '[PurposeMeasurement Trash] PurposeMeasurement On Server Restored',
    PurposeMeasurementRestored = '[PurposeMeasurement Trash] PurposeMeasurement Restored',

    PurposeMeasurementOnServerAdminRestored = '[PurposeMeasurement Admin Trash] PurposeMeasurement On Server Restored',
    PurposeMeasurementAdminRestored = '[PurposeMeasurement Admin Trash] PurposeMeasurement Restored',

    PurposeMeasurementDeletedFromTrash = '[PurposeMeasurement Trash] PurposeMeasurement deleted',
    PurposeMeasurementDeletedFromAdminTrash = '[PurposeMeasurement Admin Trash] PurposeMeasurement deleted',

    PurposeMeasurementTrash = 'PurposeMeasurement Trashed',
    PurposeMeasurementTrashFlushed = 'PurposeMeasurement Trash Flushed',
}

export class PurposeMeasurementOnServerCreated implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementOnServerCreated;

    constructor(public payload: { purposeMeasurement: PurposeMeasurement}) {
    }
}

export class PurposeMeasurementCreated implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementCreated;

    constructor(public payload: { purposeMeasurement: PurposeMeasurement }) {
    }
}

export class PurposeMeasurementUpdated implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementUpdated;

    constructor(public payload: {
        partialpurposeMeasurement: Update<PurposeMeasurement>,
        purposeMeasurement: PurposeMeasurement
    }) {
    }
}

export class PurposeMeasurementDeleted implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeMeasurementsPageRequested implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class PurposeMeasurementsPageLoaded implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementsPageLoaded;

    constructor(public payload: { purposeMeasurements: PurposeMeasurement[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class PurposeMeasurementsPageCancelled implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementsPageCancelled;
}

export class AllPurposeMeasurementsRequested implements Action {
    readonly type = PurposeMeasurementActionTypes.AllPurposeMeasurementsRequested;
}

export class AllPurposeMeasurementsLoaded implements Action {
    readonly type = PurposeMeasurementActionTypes.AllPurposeMeasurementsLoaded;

    constructor(public payload: { purposeMeasurements: PurposeMeasurement[] }) {
    }
}

export class PurposeMeasurementsPageToggleLoading implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class PurposeMeasurementsActionToggleLoading implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Trash

export class PurposeMeasurementDeletedFromAdminTrash implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class PurposeMeasurementDeletedFromTrash implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeMeasurementOnServerRestored implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeMeasurementRestored implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementRestored;

    constructor(public payload: { item: PurposeMeasurement }) {
    }
}

export class PurposeMeasurementOnServerAdminRestored implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class PurposeMeasurementAdminRestored implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementAdminRestored;

    constructor(public payload: { item: PurposeMeasurement }) {
    }
}

export class PurposeMeasurementTrashFlushed implements Action {
    readonly type = PurposeMeasurementActionTypes.PurposeMeasurementTrashFlushed;

    constructor() {
    }
}

export type PurposeMeasurementActions = PurposeMeasurementCreated
    | PurposeMeasurementUpdated
    | PurposeMeasurementDeleted
    | PurposeMeasurementsPageRequested
    | PurposeMeasurementsPageLoaded
    | PurposeMeasurementsPageCancelled
    | AllPurposeMeasurementsLoaded
    | AllPurposeMeasurementsRequested
    | PurposeMeasurementOnServerCreated
    | PurposeMeasurementsPageToggleLoading
    | PurposeMeasurementsActionToggleLoading
    | PurposeMeasurementDeletedFromAdminTrash
    | PurposeMeasurementDeletedFromTrash
    | PurposeMeasurementOnServerRestored
    | PurposeMeasurementRestored
    | PurposeMeasurementOnServerAdminRestored
    | PurposeMeasurementAdminRestored
    | PurposeMeasurementTrashFlushed;
