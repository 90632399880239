import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, fromEvent, merge, of, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, take, tap } from 'rxjs/operators';
import { AssetClassRetailBuildingDeleted, AssetClassRetailBuildingDeletedFromAdminTrash, AssetClassRetailBuildingDeletedFromTrash, AssetClassRetailBuildingDuplicate, AssetClassRetailBuildingOnServerAdminRestored, AssetClassRetailBuildingOnServerRestored, AssetClassRetailBuildingPageRequested, AssetClassRetailBuildingTrashFlushed } from 'src/app/core/comparable/_actions/asset-class-retail-building.actions';
import { AssetClassRetailBuildingDataSource } from 'src/app/core/comparable/_data-sources/asset-class-retail-building.datasource';
import { AssetClassRetailBuildingModel } from 'src/app/core/comparable/_models/asset-class-retail-building.model';
import { selectAdminTrashedAssetClassRetailBuildingCount, selectAssetClassRetailBuildingLastQuery, selectTrashedAssetClassRetailBuildingCount } from 'src/app/core/comparable/_selectors/asset-class-retail-building.selectors';
import { AssetClassRetailBuildingService } from 'src/app/core/comparable/_services/asset-class-retail-building.service';
import { FileUploadService } from 'src/app/core/file-upload';
import { currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { emptyFilter, FilterModel, FilterChange } from '../../../shared_components/filter-section/filter-section.component';
import { RetailBuildingFilterDialogComponent } from '../_sub/retail-building-filter-dialog/retail-building-filter-dialog.component';
import { User } from 'src/app/core/auth';

@Component({
  selector: 'kt-retail-building-list',
  templateUrl: './retail-building-list.component.html',
  styleUrls: ['./retail-building-list.component.scss']
})
export class RetailBuildingListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('fileInput') fileInput;

  dataSource: AssetClassRetailBuildingDataSource;
  displayedColumns = ['id', 'sub_type', 'size', 'consideration', 'source_date', 'actions'];

  lastQueryParams: QueryParamsModel;
  filterModel: FilterModel = emptyFilter();
  filterCnt: number = 0;

  trashCnt = 0;
  adminTrashCnt = 0;
  fileType = 'application/json';

  menuItems = [
    {
      title: 'Import',
      icon: 'flaticon2-add',
      refId: 0,
      disabled: false
    },
    {
      title: 'Download Template',
      icon: 'flaticon2-download',
      refId: 1,
      disabled: false
    },
    {
      title: 'Export',
      icon: 'flaticon2-box-1',
      refId: 2,
      disabled: true
    }
  ];
  menuSubject = new BehaviorSubject<number>(-1);

  private _subscriptions: Subscription[] = [];
  filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
  filterObs$ = this.filter$.asObservable();
  canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
  currentUser: User | null

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private ngZone: NgZone,
    private layoutService: LayoutUtilsService,
    private service: AssetClassRetailBuildingService,
    private dialog: MatDialog,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
    this._subscriptions.push(userSub)
    this.paginator._intl.itemsPerPageLabel = 'Display';

    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this._subscriptions.push(sortSubscription);

    const paginatorSubscription = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this._loadList())
    ).subscribe();
    this._subscriptions.push(paginatorSubscription);

    this.dataSource = new AssetClassRetailBuildingDataSource(this.store);

    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this._loadList();
    }); // Remove this line, just loading imitation

    this.lastQueryParams = new QueryParamsModel({});
    const lastQuerySubscription = this.store.pipe(
      select(selectAssetClassRetailBuildingLastQuery),
      take(1)
    ).subscribe(res => {
      if (res.isEmpty()) {
        return;
      }
      this.lastQueryParams = res;
      this._updateFilter(res.filter)
    });
    this._subscriptions.push(lastQuerySubscription);

    const trashSubscription = this.store.pipe(
      select(selectTrashedAssetClassRetailBuildingCount)
    ).subscribe(res => {
      this.trashCnt = res;
    });
    this._subscriptions.push(trashSubscription);

    const adminTrashSubscription = this.store.pipe(
      select(selectAdminTrashedAssetClassRetailBuildingCount)
    ).subscribe(res => {
      this.adminTrashCnt = res;
    });
    this._subscriptions.push(adminTrashSubscription);

    // menu observer
    const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
      switch (refId) {
        case 0:
          this.addFiles();
          break;
        case 1:
          this.downloadJsonTemplate();
          break;
        case 2:
          // TODO: export comparables
          break;
        default:
          break;
      }
    });
    this._subscriptions.push(menuSubjectSubscribe);
  }
  onFilterChange(event: FilterChange) {
    if (event.page != null) {
      this.paginator.pageIndex = event.page
    }
    this.filterModel = event.filter;
    this._loadList();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  addFiles() {
    this.fileInput.nativeElement.click();
  }

  importComparable(event: any) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.fileUploadService.uploadCompJson(formData).subscribe(
      (res) => {
        if (!res) {
          return;
        }
        if (res.success) {
          this._loadList();
        }
      },
      (err) => console
    );
  }

  addComparable() {
    this.editComparable(null);
  }
  editComparable(ac: AssetClassRetailBuildingModel) {
    if (ac) {
      this.router.navigate(['edit', ac.id], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['add'], { relativeTo: this.activatedRoute })
    }
  }
  deleteComparable(ac: AssetClassRetailBuildingModel) {
    this.store.dispatch(new AssetClassRetailBuildingDeleted({ id: ac.id }));
  }
  getItemCssClassByStatus(status: number): string {
    switch (status) {
      case 0:
        return 'warning';
      case 2:
        return 'info';
      default:
        return 'success';
    }
  }
  getItemStatusString(status: number): string {
    switch (status) {
      case 0:
        return 'Draft';
      case 2:
        return 'imported';
      default:
        return 'Validated';
    }
  }
  trash() {
    this.service.getTrashed().subscribe(res => {
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `#${elem.ref_num}`,
          id: elem.id.toString(),
          date: elem.user_deleted,
          hasPermanentlyDelete: true,
          deletedUser: elem.userDeletedBy
        });
      });
      this._showTrashed(items);
    })
  }
  adminTrash() {
    this.service.getAdminTrashed().subscribe(res => {
      const items = [];
      res.data.forEach(elem => {
        items.push({
          text: `#${elem.ref_num}`,
          id: elem.id.toString(),
          date: elem.deleted_at,
          hasPermanentlyDelete: true,
          deletedUser: elem.deletedBy
        });
      });
      this._showTrashed(items, true);
    })
  }
  delete(id: number) {
    this.store.dispatch(new AssetClassRetailBuildingDeletedFromTrash({ id }))
  }
  restore(id: number, isAdmin = false) {
    if (isAdmin) {
      this.store.dispatch(new AssetClassRetailBuildingOnServerAdminRestored({ id: id }));
    } else {
      this.store.dispatch(new AssetClassRetailBuildingOnServerRestored({ id: id }));
    }
  }
  hardDelete(id: number) {
    this.store.dispatch(new AssetClassRetailBuildingDeletedFromAdminTrash({ id: id }))
  }
  goToMap() {
    this.router.navigate(['map'], { relativeTo: this.activatedRoute });
  }
  duplicate(id: number) {
    this.store.dispatch(new AssetClassRetailBuildingDuplicate({id: id}))
  }
  private _loadList() {
    let queryParams = null;
    if (this.lastQueryParams.isEmpty()) {
      const _filter = Object.assign({}, this.filterModel) as FilterModel;
      queryParams = new QueryParamsModel(
        _filter,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    } else {
      queryParams = this.lastQueryParams;
    }
    this.lastQueryParams = new QueryParamsModel({});
    this.store.dispatch(new AssetClassRetailBuildingPageRequested({ page: queryParams }));
  }
  private _updateFilter(filter: FilterModel) {
    this.filter$.next(filter);
  }
  private _showTrashed(items, isAdmin = false) {
    let _title = 'Deleted RetailBuilding Comparables';
    if (isAdmin) {
      _title = 'Admin Deleted RetailBuilding Comparables';
    }
    this.layoutService.trashedEntities(_title, items, this, isAdmin);
  }

  downloadJsonTemplate() {
    this.fileUploadService.compTemplateDownload(11).subscribe(res => {
      if (res.type == 4) {
        let blob = new Blob([res.body], { type: 'application/json' });
        let url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = "interval-comp-retail-building-template.json";
        anchor.href = url;
        anchor.click();
      }
    },
      err => {
        alert("Problem while downloading the file.");
      }
    );
  }
  flush() {
    this.store.dispatch(new AssetClassRetailBuildingTrashFlushed());
  }
}
