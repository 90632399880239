import { Component, Input, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { ValuationProcessUserModel } from 'src/app/core/valuation-process';
import { environment } from 'src/environments/environment';

export type TeamInvolvementModuleViewModel = {
  projectManager: ValuationProcessUserModel 
  leadValuer: ValuationProcessUserModel
  inspection: ValuationProcessUserModel
  valuer: ValuationProcessUserModel
}

@Component({
  selector: 'kt-team-involvement-module-v2',
  templateUrl: './team-involvement-module-v2.component.html',
  styleUrls: [
    '../info-module.styles.scss',
    './team-involvement-module-v2.component.scss'
  ]
})
export class TeamInvolvementModuleV2Component implements OnInit {
  @Input() viewModel: TeamInvolvementModuleViewModel

  constructor(
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
  }

  showUserInfo(event, popover: NgbPopover, user: ValuationProcessUserModel) {
    popover.popoverClass = "my-popover";
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({
          role: user.role,
          qualification: user.qualificationName,
          email: user.email,
          agency: user.agencyName,
          user_pic: user.profilePicture && user.profilePicture.length > 0 ? this._getUserImageUrl(user.profilePicture) : './assets/media/users/default.jpg'
      })
    }
  }

  _getUserImageUrl(path: string): string {
    return environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + path;
  }

  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }
}
