// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {SourceExternalReferencesService} from '../_services/source-external-reference.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allSourceExternalReferencesLoaded} from '../_selectors/source-external-reference.selectors';
// Actions
import {
    AllSourceExternalReferencesLoaded,
    AllSourceExternalReferencesRequested,
    SourceExternalReferenceActionTypes,
    SourceExternalReferencesPageRequested,
    SourceExternalReferencesPageLoaded,
    SourceExternalReferenceUpdated,
    SourceExternalReferencesPageToggleLoading,
    SourceExternalReferenceDeleted,
    SourceExternalReferenceOnServerCreated,
    SourceExternalReferenceCreated,
    SourceExternalReferenceAdminRestored,
    SourceExternalReferenceDeletedFromAdminTrash,
    SourceExternalReferenceDeletedFromTrash,
    SourceExternalReferenceOnServerAdminRestored,
    SourceExternalReferenceOnServerRestored,
    SourceExternalReferenceRestored,
    SourceExternalReferenceTrashFlushed,
    SourceExternalReferencesActionToggleLoading
} from '../_actions/source-external-reference.actions';

@Injectable()
export class SourceExternalReferenceEffects {
    showPageLoadingDispatcher = new SourceExternalReferencesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new SourceExternalReferencesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new SourceExternalReferencesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllSourceExternalReference$ = this.actions$
        .pipe(
            ofType<AllSourceExternalReferencesRequested>(SourceExternalReferenceActionTypes.AllSourceExternalReferencesRequested),
            withLatestFrom(this.store.pipe(select(allSourceExternalReferencesLoaded))),
            filter(([action, isAllSourceExternalReferenceLoaded]) => !isAllSourceExternalReferenceLoaded),
            mergeMap(() => this.service.getAllSourceExternalReferences()),
            map(res => {
                return new AllSourceExternalReferencesLoaded({sourceExternalReferences: res.data});
            })
        );

    @Effect()
    loadSourceExternalReferencePage$ = this.actions$
        .pipe(
            ofType<SourceExternalReferencesPageRequested>(SourceExternalReferenceActionTypes.SourceExternalReferencesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findSourceExternalReferences(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new SourceExternalReferencesPageLoaded({
                    sourceExternalReferences: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceDeleted>(SourceExternalReferenceActionTypes.SourceExternalReferenceDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteSourceExternalReference(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceUpdated>(SourceExternalReferenceActionTypes.SourceExternalReferenceUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateSourceExternalReference(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceOnServerCreated>(SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createSourceExternalReference(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceExternalReferenceCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushSourceExternalReferenceTrash$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceTrashFlushed>(SourceExternalReferenceActionTypes.SourceExternalReferenceTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceOnServerRestored>(SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceExternalReferenceRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceOnServerAdminRestored>(SourceExternalReferenceActionTypes.SourceExternalReferenceOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new SourceExternalReferenceAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceDeletedFromTrash>(SourceExternalReferenceActionTypes.SourceExternalReferenceDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashSourceExternalReference$ = this.actions$
        .pipe(
            ofType<SourceExternalReferenceDeletedFromAdminTrash>(SourceExternalReferenceActionTypes.SourceExternalReferenceDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: SourceExternalReferencesService, private store: Store<AppState>) {
    }
}
