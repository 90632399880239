// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../reducers';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// Selectirs
// tslint:disable-next-line: max-line-length
import { selectAppendicesPageLoading, selectAppendicesShowInitWaitingMessage, selectAppendicesQueryResult } from '../_selectors/appendix.selectors';

export class AppendicesDataSource extends BaseDataSource {
    trashed = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAppendicesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAppendicesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAppendicesQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
