// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AssetClassTypesService} from '../_services/asset-class-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allAssetClassTypesLoaded} from '../_selectors/asset-class-type.selectors';
// Actions
import {
    AllAssetClassTypesLoaded,
    AllAssetClassTypesRequested,
    AssetClassTypeActionTypes,
    AssetClassTypesPageRequested,
    AssetClassTypesPageLoaded,
    AssetClassTypeUpdated,
    AssetClassTypesPageToggleLoading,
    AssetClassTypeDeleted,
    AssetClassTypeOnServerCreated,
    AssetClassTypeCreated,
    AssetClassTypesActionToggleLoading,
    AssetClassTypeAdminRestored,
    AssetClassTypeDeletedFromAdminTrash,
    AssetClassTypeDeletedFromTrash,
    AssetClassTypeOnServerAdminRestored,
    AssetClassTypeOnServerRestored,
    AssetClassTypeRestored,
    AssetClassTypeTrashFlushed,
} from '../_actions/asset-class-type.actions';

@Injectable()
export class AssetClassTypeEffects {
    showPageLoadingDispatcher = new AssetClassTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAssetClassTypes$ = this.actions$
        .pipe(
            ofType<AllAssetClassTypesRequested>(AssetClassTypeActionTypes.AllAssetClassTypesRequested),
            withLatestFrom(this.store.pipe(select(allAssetClassTypesLoaded))),
            filter(([action, isAllAssetClassTypesLoaded]) => !isAllAssetClassTypesLoaded),
            mergeMap(() => this.service.getAllAssetClassTypes()),
            map(res => {
                return new AllAssetClassTypesLoaded({assetClassTypes: res.data});
            })
        );

    @Effect()
    loadAssetClassTypesPage$ = this.actions$
        .pipe(
            ofType<AssetClassTypesPageRequested>(AssetClassTypeActionTypes.AssetClassTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findAssetClassTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AssetClassTypesPageLoaded({
                    assetClassTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeDeleted>(AssetClassTypeActionTypes.AssetClassTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAssetClassType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeUpdated>(AssetClassTypeActionTypes.AssetClassTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAssetClassType(payload.assetClassType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeOnServerCreated>(AssetClassTypeActionTypes.AssetClassTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createAssetClassType(payload.assetClassType).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassTypeCreated({assetClassType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAssetClassTypeTrash$ = this.actions$
        .pipe(
            ofType<AssetClassTypeTrashFlushed>(AssetClassTypeActionTypes.AssetClassTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeOnServerRestored>(AssetClassTypeActionTypes.AssetClassTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeOnServerAdminRestored>(AssetClassTypeActionTypes.AssetClassTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeDeletedFromTrash>(AssetClassTypeActionTypes.AssetClassTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAssetClassType$ = this.actions$
        .pipe(
            ofType<AssetClassTypeDeletedFromAdminTrash>(AssetClassTypeActionTypes.AssetClassTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AssetClassTypesService, private store: Store<AppState>) {
    }
}
