// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {DefaultAssumption} from '../_models/default-assumption.model';

export enum DefaultAssumptionActionTypes {

    AllDefaultAssumptionsRequested = '[DefaultAssumptions Home Page] All DefaultAssumptions Requested',
    AllDefaultAssumptionsLoaded = '[DefaultAssumptions API] All DefaultAssumptions Loaded',

    DefaultAssumptionOnServerCreated = '[Edit DefaultAssumption Dialog] DefaultAssumption On Server Created',
    DefaultAssumptionCreated = '[Edit DefaultAssumptions Dialog] DefaultAssumptions Created',
    DefaultAssumptionUpdated = '[Edit DefaultAssumption Dialog] DefaultAssumption Updated',
    DefaultAssumptionDeleted = '[DefaultAssumptions List Page] DefaultAssumption Deleted',

    // trash system

    DefaultAssumptionOnServerRestored = '[DefaultAssumption Trash] DefaultAssumption On Server Restored',
    DefaultAssumptionRestored = '[DefaultAssumption Trash] DefaultAssumption Restored',

    DefaultAssumptionOnServerAdminRestored = '[DefaultAssumption Admin Trash] DefaultAssumption On Server Restored',
    DefaultAssumptionAdminRestored = '[DefaultAssumption Admin Trash] DefaultAssumption Restored',

    DefaultAssumptionDeletedFromTrash = '[DefaultAssumption Trash] DefaultAssumption deleted',
    DefaultAssumptionDeletedFromAdminTrash = '[DefaultAssumption Admin Trash] DefaultAssumption deleted',

    DefaultAssumptionTrash = 'DefaultAssumption Trashed',
    DefaultAssumptionTrashFlushed = 'DefaultAssumption Trash Flushed',


    DefaultAssumptionsPageRequested = '[DefaultAssumptions List Page] DefaultAssumptions Page Requested',
    DefaultAssumptionsPageLoaded = '[DefaultAssumptions API] DefaultAssumptions Page Loaded',
    DefaultAssumptionsPageCancelled = '[DefaultAssumptions API] DefaultAssumptions Page Cancelled',

    DefaultAssumptionsPageToggleLoading = '[DefaultAssumptions page] DefaultAssumptions Page Toggle Loading',
    DefaultAssumptionsActionToggleLoading = '[DefaultAssumptions] DefaultAssumptions Action Toggle Loading'
}

export class DefaultAssumptionOnServerCreated implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionOnServerCreated;

    constructor(public payload: { defaultAssumption: DefaultAssumption }) {
    }
}

export class DefaultAssumptionCreated implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionCreated;

    constructor(public payload: { defaultAssumption: DefaultAssumption }) {
    }
}

export class DefaultAssumptionUpdated implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionUpdated;

    constructor(public payload: {
        partialItem: Update<DefaultAssumption>,
        item: DefaultAssumption
    }) {
    }
}

export class DefaultAssumptionDeleted implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class DefaultAssumptionsPageRequested implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class DefaultAssumptionsPageLoaded implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionsPageLoaded;

    constructor(public payload: {
        defaultAssumptions: DefaultAssumption[],
        totalTrashed: number,
        totalAdminTrashed: number,
        totalCount: number,
        page: QueryParamsModel
    }) {
    }
}

export class DefaultAssumptionsPageCancelled implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionsPageCancelled;
}

export class AllDefaultAssumptionsRequested implements Action {
    readonly type = DefaultAssumptionActionTypes.AllDefaultAssumptionsRequested;
}

export class AllDefaultAssumptionsLoaded implements Action {
    readonly type = DefaultAssumptionActionTypes.AllDefaultAssumptionsLoaded;

    constructor(public payload: { defaultAssumptions: DefaultAssumption[] }) {
    }
}

export class DefaultAssumptionsPageToggleLoading implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class DefaultAssumptionsActionToggleLoading implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class DefaultAssumptionDeletedFromAdminTrash implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class DefaultAssumptionDeletedFromTrash implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class DefaultAssumptionOnServerRestored implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class DefaultAssumptionRestored implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionRestored;

    constructor(public payload: { item: DefaultAssumption }) {
    }
}

export class DefaultAssumptionOnServerAdminRestored implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class DefaultAssumptionAdminRestored implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionAdminRestored;

    constructor(public payload: { item: DefaultAssumption }) {
    }
}

export class DefaultAssumptionTrashFlushed implements Action {
    readonly type = DefaultAssumptionActionTypes.DefaultAssumptionTrashFlushed;

    constructor() {
    }
}

export type DefaultAssumptionActions = DefaultAssumptionCreated
    | DefaultAssumptionUpdated
    | DefaultAssumptionDeleted
    | DefaultAssumptionsPageRequested
    | DefaultAssumptionsPageLoaded
    | DefaultAssumptionsPageCancelled
    | AllDefaultAssumptionsLoaded
    | AllDefaultAssumptionsRequested
    | DefaultAssumptionOnServerCreated
    | DefaultAssumptionDeletedFromAdminTrash
    | DefaultAssumptionDeletedFromTrash
    | DefaultAssumptionOnServerRestored
    | DefaultAssumptionRestored
    | DefaultAssumptionOnServerAdminRestored
    | DefaultAssumptionAdminRestored
    | DefaultAssumptionTrashFlushed
    | DefaultAssumptionsPageToggleLoading
    | DefaultAssumptionsActionToggleLoading;
