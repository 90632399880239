import {BaseModel} from '../../_base/crud';

export class CityModel extends BaseModel {
    id: number;
    name: string;
    picture: string;
    country_id: number;
    country_name: string;
    client_cnt: number;
    relation_cnt: number;

    user_deleted_by: number;
    deleted_by: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    user_deleted: Date;

    createdBy: string;
    lastUpdatedBy: string;
    
    deletedBy: string;
    userDeletedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.picture = './assets/media/users/default.jpg';

        this.country_id = 0;
        this.country_name = '';
        this.client_cnt = 0;
        this.relation_cnt = 0;

        this.deletedBy = '';
        this.userDeletedBy = '';

        this.user_deleted = null;
        this.deleted_at = null;
        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
