// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Models
import {AssetClassRetailFacilityModel} from '../_models/asset-class-retail-facility.model';
import {QueryParamsModel} from '../../_base/crud';
import {AssetClassRetailFacilityActions, AssetClassRetailFacilityActionTypes} from '../_actions/asset-class-retail-facility.actions';

export interface AssetClassRetailFacilitiesState extends EntityState<AssetClassRetailFacilityModel> {
    isAllAssetClassRetailFacilitiesLoaded: boolean;
    queryRowsCount: number;
    queryResult: AssetClassRetailFacilityModel[];
    lastCreatedOfficeTypeId: number;
    listLoading: boolean;
    actionLoading: boolean;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassRetailFacilityModel> = createEntityAdapter<AssetClassRetailFacilityModel>();

export const initialAssetClassRetailFacilitiesState: AssetClassRetailFacilitiesState = adapter.getInitialState({
    isAllAssetClassRetailFacilitiesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedOfficeTypeId: undefined,
    listLoading: false,
    actionLoading: false,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassRetailFacilitiesReducer(state = initialAssetClassRetailFacilitiesState, action: AssetClassRetailFacilityActions): AssetClassRetailFacilitiesState {
    switch (action.type) {
        case AssetClassRetailFacilityActionTypes.AssetClassRetailFacilityActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case AssetClassRetailFacilityActionTypes.AllAssetClassRetailFacilitiesLoaded:
            return adapter.addAll(action.payload.assetClassRetailFacilities, {
                ...state, isAllAssetClassRetailFacilitiesLoaded: true
            });
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
