<div class="card card-custom">
    <div class="card-header" *ngIf="link">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="link">
        <form [formGroup]="linkForm" class="form form-group-seperator-dashed">
            <div class="card-body">

                <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    Oops! It looks like you’ve forgotten some important information.
                </kt-alert>

                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   placeholder="Title"
                                   formControlName="title"/>
                            <mat-error>Title
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   placeholder="Description"
                                   formControlName="description"/>
                            <mat-error>Description
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput
                                   placeholder="Link/URL"
                                   formControlName="url"/>
                            <mat-icon
                                matSuffix
                                class="cursor-pointer icon-gray"
                                container="body"
                                placement="left"
                                popoverClass="modal-pop"
                                [popoverTitle]="'TOOLTIP.INSPECTION.MATTERPORT.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.INSPECTION.MATTERPORT.DESCRIPTION' | translate"
                                (click)="$event.stopPropagation();">help
                            </mat-icon>
                            <mat-error>url
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0 b-0">
                <div class="form-actions form-actions-solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="Close">
                                Close
                            </button>&nbsp;
                            <button type="button" [disabled]="!isNameValid()" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()" [disabled]="viewLoading"
                                    matTooltip="Save changes">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
