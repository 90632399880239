<div class="card card-custom">
    <div class="card-body-progress">
        <!-- here can place a progress bar-->
        <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{data.title}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <mat-dialog-content>
                <form [formGroup]="form">
                    <div class="form-group kt-form__group row">
                        <div class="col-lg-12">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Organisation"
                                            formControlName="org_id">
                                    <mat-option *ngFor="let item of organisations"
                                                [value]="item.id">{{item.name}}
                                    </mat-option>
                                </mat-select>
    
                                <mat-error>Organisation
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mat-form-field-fluid">
                                <!--<input required matInput placeholder="Title" [formControl]="inputTitleForUpdate"/>-->
                                <mat-select placeholder="Certification"
                                            formControlName="cert_type">
                                    <mat-option *ngFor="let item of certifications"
                                                [value]="item.id">{{item.name}}
                                    </mat-option>
                                </mat-select>
    
                                <mat-error>Certification
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mat-form-field-fluid">
                                <span (click)="addPicker_date.open()">
                                    <input matInput [matDatepicker]="addPicker_date"
                                           readonly
                                           placeholder="Expiration Date"
                                           formControlName="date">
                                </span>
                                <mat-datepicker-toggle matSuffix [for]="addPicker_date"></mat-datepicker-toggle>
                                <button mat-button *ngIf="form.controls.date.value"
                                        matSuffix mat-icon-button
                                        matTooltip="Clear"
                                        (click)="clearDate(form.controls.date)">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-datepicker #addPicker_date></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12">
                            <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput rows="3"
                                      placeholder="Registration"
                                      formControlName="registration"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <div class="row">
                    <div class="col-lg-12">
                        <button mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
                        <button mat-button mat-raised-button (click)="save()" class="btn btn-success" color="submit">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
