import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { ExternalRefTemplateModel } from "../_models";
import { ExternalRefTemplateState } from "../_reducers";
import { externalRefTemplateSelectors } from "../_selectors";
import { TemplateTableDataSource } from "./base-template.datasource";

export class ExternalRefTemplateDataSource extends TemplateTableDataSource<ExternalRefTemplateModel> {
    constructor(private store: Store<ExternalRefTemplateState>) {
        super();
        this.isLoading$ = this.store.select(externalRefTemplateSelectors.selectIsLoading);
        this.total$ = this.store.select(externalRefTemplateSelectors.selectTotal);

        this.store.pipe(
            takeUntil(this.destroy$),
            select(externalRefTemplateSelectors.selectExternalRefTemplates)
        ).subscribe(res => {
            this.entitySubject$.next(res);
        })
    }
}