// Angular
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';
import {TypesUtilsService} from '../../../../../core/_base/crud';

@Component({
    selector: 'kt-document-info-edit-dialog',
    templateUrl: './document-info-edit-dialog.component.html'
})
export class DocumentInfoEditDialogComponent implements OnInit {
    // Public properties
    viewLoading = false;
    loadingAfterSubmit = false;
    inputTitleForUpdate = new UntypedFormControl('', Validators.required);
    inputDescriptionForUpdate = new UntypedFormControl('');
    dateForUpdate = new UntypedFormControl('');

    descriptionField = false;
    checkBox = false;
    checkBoxValue: boolean = false;

    featuredPictureAllowed = false;
    isFeatured: boolean = false;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<SaveEntityWithDescrDialogComponent>
     * @param typesUtilsService
     * @param data: any
     */
    constructor(public dialogRef: MatDialogRef<DocumentInfoEditDialogComponent>,
                public typesUtilsService: TypesUtilsService,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data.checkBox) {
            this.checkBox = data.checkBox;
            this.checkBoxValue = data.checkBoxValue;
        }

        if (data.featuredPictureAllowed) {
            this.featuredPictureAllowed = data.featuredPictureAllowed;
            this.isFeatured = data.isFeatured;
        }

        if (data.descriptionField) {
            this.descriptionField = data.descriptionField;
        }

    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.fileTitle) {
            this.inputTitleForUpdate.setValue(this.data.fileTitle);
        }
        if (this.data.fileDescription) {
            this.inputDescriptionForUpdate.setValue(this.data.fileDescription);
        }

        if (this.data.date && this.data.date.length > 0) {
            this.dateForUpdate.setValue(this.typesUtilsService.getDateFromString(this.data.date));
        }
    }

    /**
     * Close dialog with false result
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    changed() {
        this.checkBoxValue = !this.checkBoxValue;
    }

    featuredChanged() {
        this.isFeatured = !this.isFeatured;
    }

    /**
     * Close dialog with true result
     */
    onYesClick(): void {
        if (!this.inputTitleForUpdate.value || this.inputTitleForUpdate.value.length === 0) {
            this.inputTitleForUpdate.markAsTouched();
            return;
        }
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        this.loadingAfterSubmit = true;

        const _date = this.dateForUpdate.value;
        let _dateString = this.dateForUpdate.value;
        if (_date) {
            _dateString = this.typesUtilsService.dateFormat(_date);
        }

        setTimeout(() => {
            this.dialogRef.close({
                title: this.inputTitleForUpdate.value,
                descr: this.inputDescriptionForUpdate.value,
                is_checked: this.checkBoxValue,
                date: _dateString,
            });
        }, 500);
    }

    clearDate(_control) {
        _control.setValue(null);
        _control.updateValueAndValidity();
    }
}
