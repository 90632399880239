import {
    Component, OnDestroy, OnInit, ViewChild,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {AppState} from '../../../../core/reducers';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ConstructionType} from '../../../../core/linked-tables/_models/construction-type.model';
import {selectAllConstructionTypes} from '../../../../core/linked-tables/_selectors/contruction-type.selectors';
import {TranslateService} from '@ngx-translate/core';
import {
    AllStateRepairsRequested,
    AllTypeOfTitlesRequested,
    Grade,
    selectAllGrades,
    selectAllStateRepairs,
    selectAllTypeOfTitles,
    StateRepair,
    TypeOfTitle,
    ExternalWall,
    AllExternalWallsRequested,
    selectAllExternalWalls,
    FoundationType,
    AllFoundationTypesRequested,
    selectAllFoundationTypes,
    BuildingUseStatus,
    AllBuildingUseStatussRequested,
    selectAllBuildingUseStatuss
} from '../../../../core/linked-tables';
import {
    AssetClassBuildingInformation,
    AssetClassResidentialsService,
    BuildingFacilityModel,
    BuildingModel,
    BuildingOnServerCreated,
    BuildingService,
    BuildingUpdated,
    selectLastCreatedBuildingId
} from '../../../../core/comparable';
import {BuildingInfoDialogComponent} from '../../comparable/_sub/building-info-dialog/building-info-dialog.component';
import {UploadFileComponent} from '../upload-file/upload-file.component';
import {FacilitiesSubListComponent} from '../facilities/list/facilities-sub-list.component';
import {Update} from '@ngrx/entity';
import { ComparablesRadioButtonValues } from "src/app/core/comparable/_models/RadioButtonValues";
import { TargetPropertyRadioButtonValues } from 'src/app/core/asset_class/_models/RadioButtonValues';

@Component({
    selector: 'kt-lp-building-information',
    templateUrl: './lp-building-information.component.html',
    styleUrls: ['./lp-building-information.component.scss']
})
export class LpBuildingInformationComponent implements OnInit, OnDestroy {

    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() buildingSubject: BehaviorSubject<AssetClassBuildingInformation>;
    @Input() buildingsSubject: BehaviorSubject<AssetClassBuildingInformation[]>;
    @Input() parentFormType: ParentFormType = ParentFormType.ComparableForm;
    @Input() nth: number;

    buildingForm: UntypedFormGroup;
    hasFormErrors = false;
    // linked tables
    buildingUseStatuses: BuildingUseStatus[] = [];
    typeOfTitles: TypeOfTitle[] = [];
    constructionTypes: ConstructionType[] = [];
    grades: Grade[] = [];
    years: any[];
    externalWalls: ExternalWall[] = [];
    foundationTypes: FoundationType[] = [];

    buildingCPSource: BuildingModel;

    folder: string;

    selectedConstructions: number[] = [];

    private subscriptions: Subscription[] = [];
    formValueChanged = false;

    facilitiesInValid$: Subject<boolean> = new Subject();
    radioButtonValues: typeof ComparablesRadioButtonValues | typeof TargetPropertyRadioButtonValues;

    // hasFormErrors = false;
    public garageRange: {
        value: number,
        label: string,
    }[] = [];

    hasTitleRequiredError: boolean = false;
    showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param layoutUtilsService
     * @param fb: FormBuilder
     * @param dialog
     * @param store
     * @param buildingService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private layoutUtilsService: LayoutUtilsService,
                private fb: UntypedFormBuilder,
                private dialog: MatDialog,
                private store: Store<AppState>,
                private buildingService: BuildingService,
                private translate: TranslateService) {

        const max = new Date().getFullYear();
        const min = max - 100;
        const years = [];
        for (let i = max; i >= min; i--) {
            years.push(i);
        }
        // this.years = years;

    }

    ngOnInit() {
        // Check if parent form is Comparable or Inspection
        if (this.parentFormType === ParentFormType.ComparableForm) {
            this.radioButtonValues = ComparablesRadioButtonValues;
        } else {
            this.radioButtonValues = TargetPropertyRadioButtonValues;
        }

        if (!this.buildingSubject.value) {
            const buildingInfo = new AssetClassBuildingInformation();
            buildingInfo.clear();
            this.buildingSubject.next(buildingInfo);
            this.buildingForm.valueChanges
                .pipe(
                    debounceTime(150),
                    // The user can type quite quickly in the input box,
                    // and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                    distinctUntilChanged(), // This operator will eliminate duplicate values
                    tap(() => {
                        this.updateValue();
                    })
                )
                .subscribe();
        }
        this.buildingSubject.asObservable().subscribe(res => this.createForm());

        this.store.dispatch(new AllBuildingUseStatussRequested());
        const buildingUseStatusesSubscription = this.store.pipe(
            select(selectAllBuildingUseStatuss))
            .subscribe(res => {
                this.buildingUseStatuses = [];
                if (res) {
                    this.buildingUseStatuses = res;
                }
            });

        this.subscriptions.push(buildingUseStatusesSubscription);
        this.createForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    createForm() {
        this.buildingForm = this.fb.group({

            building_name: [this.buildingSubject.value.building_name === 'N/A' ? null : this.buildingSubject.value.building_name,
                Validators.required],
            total_floors: [this.buildingSubject.value.total_floors],
            completion_year: [this.buildingSubject.value.completion_year],
            building_description: [this.buildingSubject.value.building_description, Validators.required],

            is_part_of_lp: [this.buildingSubject.value.is_part_of_lp],

            footprint: [this.buildingSubject.value.footprint],
            land_occupation_percent: [this.buildingSubject.value.land_occupation_percent],
            building_usage: [this.buildingSubject.value.building_usage],
            env_consideration: [this.buildingSubject.value.env_consideration],
            occupation: [this.buildingSubject.value.occupation],
            building_use_status_id: [this.buildingSubject.value.building_use_status_id],
            area_of_hard_surface: [this.buildingSubject.value.area_of_hard_surface],
        });

        this.buildingForm.valueChanges.subscribe(value => {
            this.updateValue(false);
        });
}

    get has_building_facility() {
        return this.buildingForm.get('has_building_facility');
    }

    updateValue(isComplete = true) {
        this.loadingSubject.next(true);
        if (isComplete && !this.valid()) {
            this.loadingSubject.next(false);
            return
        }
        const controls = this.buildingForm.controls;

        const buildingInfo = new AssetClassBuildingInformation();
        buildingInfo.clear();
        buildingInfo.id = this.buildingSubject.value.id ? this.buildingSubject.value.id : undefined;
        buildingInfo.building_name = controls.building_name.value;
        buildingInfo.completion_year = controls.completion_year.value;
        buildingInfo.total_floors = controls.total_floors.value;
        buildingInfo.building_description = controls.building_description.value;
        buildingInfo.footprint = controls.footprint.value;
        buildingInfo.land_occupation_percent = controls.land_occupation_percent.value;
        buildingInfo.building_usage = controls.building_usage.value;
        buildingInfo.env_consideration = controls.env_consideration.value;
        buildingInfo.occupation = controls.occupation.value;
        buildingInfo.building_use_status_id = this.setNullWhenUnknown(controls.building_use_status_id.value);
        buildingInfo.area_of_hard_surface = controls.area_of_hard_surface.value;
        buildingInfo.is_part_of_lp = 1;

        this.buildingSubject.next(buildingInfo);

        let buildingList = this.buildingsSubject.value;
        buildingList[this.nth] = this.buildingSubject.value;
        this.buildingsSubject.next(buildingList);

        this.loadingSubject.next(false);
    }

    valid() {
        let isValid = true;
        this.hasFormErrors = false;
        this.hasTitleRequiredError = false;
        const controls = this.buildingForm.controls;
        /** check form */
        if (this.buildingForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                    controls[controlName].markAsTouched();
                }
            );
            isValid = false;
            this.hasFormErrors = true;
        }

        return isValid;
    }

    prepareBuilding(): BuildingModel {
        const controls = this.buildingForm.controls;
        const _building = new BuildingModel();
        _building.clear();
        if (this.buildingSubject.value.id) {
            _building.id = this.buildingSubject.value.id;
        }
        // _building.user_id = this.currentUser.id;

        _building.building_name = controls.building_name.value;
        // _building.total_floors = controls.total_floors.value;
        _building.completion_year = controls.completion_year.value;
        _building.anchor_tenant = controls.anchor_tenant.value;
        _building.developer = controls.developer.value;
        // _building.has_building_facility = controls.has_building_facility.value;

        _building.building_grade_id = this.setNullWhenUnknown(controls.grade_id.value);
        if (_building.building_grade_id) {
            const item = this.grades.find(grade => grade.id === _building.building_grade_id);
            // _building.building_grade_name = item ? item.name : '';
        }


        _building.energy_efficiency_grade_id = this.setNullWhenUnknown(controls.energy_efficiency_grade_id.value);

        // _building.building_type_name = controls.type_name.value;
        // _building.state_repair_id = this.setNullWhenUnknown(controls.state_repair_id.value);

        // _building.building_indoor_garage_places = controls.building_indoor_garage_places.value;
        // _building.building_outdoor_garage_places = controls.building_outdoor_garage_places.value;
        _building.building_description = controls.building_description.value;
        _building.is_part_of_property = controls.is_part_of_property.value;
        _building.info_about_property = controls.info_about_property.value;
        if (!_building.is_part_of_property) {
            _building.info_about_property = '';
        }
        // _building.external_wall_id = this.setNullWhenUnknown(controls.external_wall_id.value);
        _building.foundation_type_id = this.setNullWhenUnknown(controls.foundation_type_id.value);

        return _building;
    }

    private setNullWhenUnknown(value: number): number | null {
        if (value === -1) {
            return null;
        }
        return value;
    }

    clearBuilding() {
        const _tmp = new AssetClassBuildingInformation();
        _tmp.clear();
        this.formValueChanged = false;
        this.buildingCPSource = null;
        this.buildingSubject.next(_tmp);
    }

}

export enum ParentFormType {
    ComparableForm = 1,
    InspectionForm = 2
}
