import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {NotificationModel} from '../_models/notification.model';
import {HttpUtilsService} from '../../_base/crud';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class NotificationService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/notifications`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAll(userId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(`${this.API_URL}/${userId}`, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the role on the server
    update(item: NotificationModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${item.id}`, item, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the role on the server
    delete(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(this.API_URL + `/${id}`, {headers: httpHeaders});
    }

    flush(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/flush`, {headers: httpHeaders});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
