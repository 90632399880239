import {
    NgModule
} from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import {
    StoreModule
} from "@ngrx/store";
import {
    ApproachesToValueEffects,
    approachesToValuesReducer,
    AssetClassTypeEffects,
    assetClassTypesReducer,
    AssetClassValuationGroupEffects,
    assetClassValuationGroupsReducer,
    BaseOfValueEffects,
    baseOfValuesReducer,
    BuildingTypeEffects,
    buildingTypesReducer,
    BuildingUseStatusEffects,
    buildingUseStatussReducer,
    ChimneyStackEffects,
    chimneyStackReducer,
    ComparativeLandUseEffects,
    comparativeLandUsesReducer,
    ConservatoryPorcheEffects,
    conservatoryPorcheReducer,
    ConstructionTypeEffects,
    constructionTypesReducer,
    CoordinateReferenceSystemEffects,
    coordinateReferenceSystemsReducer,
    DefaultAssumptionEffects,
    defaultAssumptionsReducer,
    DepartureEffects,
    departuresReducer,
    ExternalAreaEffects,
    externalAreasReducer,
    ExternalWallEffects,
    externalWallsReducer,
    facilitiesReducer,
    FacilityEffects,
    FloorTypeEffects,
    floorTypesReducer,
    FoundationTypeEffects,
    foundationTypesReducer,
    GradeEffects,
    gradesReducer,
    HandoverStandardEffects,
    handoverStandardsReducer,
    IncludedInValuationEffects,
    includedInValuationsReducer,
    InteractionTypeEffects,
    interactionTypesReducer,
    IpmsStandardEffects,
    ipmsStandardsReducer,
    IvsStandardEffects,
    ivsStandardsReducer,
    keyCategoriesReducer,
    KeyCategoryEffects,
    LandTenureEffects,
    landTenuresReducer,
    MainWallEffects,
    mainWallReducer,
    measurementMethodologiesReducer,
    MeasurementMethodologyEffects,
    MethodsToValueEffects,
    methodsToValuesReducer,
    OfficeAdjustmentsEffects,
    officeAdjustmentssReducer,
    OfficeLayoutEffects,
    officeLayoutsReducer,
    OfficeStandardMeasurementEffects,
    officeStandardMeasurementsReducer,
    OtherJoineryEffects,
    otherJoineryReducer,
    OutsideDoorEffects,
    outsideDoorReducer,
    ParcelCharacteristicEffects,
    parcelCharacteristicsReducer,
    PlaningStatusEffects,
    planingStatusesReducer,
    PremiseOfValueEffects,
    premiseOfValuesReducer,
    PropertyTypeEffects,
    propertyTypesReducer,
    PurposeMeasurementEffects,
    purposeMeasurementsReducer,
    PurposeOfValuationEffects,
    purposeOfValuationsReducer,
    QosDegradationEffects,
    qosDegradationsReducer,
    RainwaterPipeEffects,
    rainwaterPipeReducer,
    RentReviewCommentEffects,
    rentReviewCommentsReducer,
    ReportDeliveryMethodEffects,
    reportDeliveryMethodsReducer,
    ResidentialAdjustmentsEffects,
    residentialAdjustmentssReducer,
    ResidentialTypeEffects,
    residentialTypesReducer,
    RicsStandardEffects,
    ricsStandardsReducer,
    RoofCoveringEffects,
    roofCoveringReducer,
    SolUseClassificationEffects,
    solUseClassificationsReducer,
    SourceCredibilityEffects,
    sourceCredibilityReducer,
    SourceExternalReferenceEffects,
    sourceExternalReferencesReducer,
    SourceInformationEffects,
    sourceInformationReducer,
    SourceTypeEffects,
    sourceTypesReducer,
    SpecialAssumptionEffects,
    specialAssumptionsReducer,
    StandardMeasurementEffects,
    standardMeasurementsReducer,
    StateRepairEffects,
    stateRepairsReducer,
    TaskEffortEffects,
    taskEffortsReducer,
    TenureEffects,
    tenuresReducer,
    TypeOfRegistrationEffects,
    typeOfRegistrationsReducer,
    TypeOfTitleEffects,
    typeOfTitlesReducer,
    UnitAreaMeasurementEffects,
    unitAreaMeasurementsReducer,
    UnitLengthMeasurementEffects,
    unitLengthMeasurementsReducer,
    UnitMeasurementEffects,
    unitMeasurementsReducer,
    ValuationStandardEffects,
    valuationStandardReducer,
    ValuerQualificationEffects,
    valuerQualificationsReducer,
    WindowTypeEffects,
    windowTypesReducer,
    BuiltinFittingEffects,
    builtinFittingsReducer,
    CeilingEffects,
    ceilingsReducer,
    CubicContentEffects,
    cubicContentsReducer,
    RoofStructureEffects,
    roofStructuresReducer,
    StorageTypeEffects,
    storageTypesReducer,
    WallsAndPartitionEffects,
    wallsAndPartitionsReducer,
    FireplacesChimneyBreastEffects,
    BathroomFittingEffects,
    fireplacesChimneyBreastsReducer,
    bathroomFittingsReducer,
    gardenReducer,
    outdoorSpaceReducer,
    OutdoorSpaceEffects,
    GardenEffects,
    externalFacadeReducer,
    ExternalFacadeEffects,
    floorNumberingSchemesReducer,
    FloorNumberingSchemeEffects,
    organisationReducer,
    OrganisationEffects,
    certificationReducer,
    CertificationEffects,
    paymentTypesReducer,
    PaymentTypeEffects
} from ".";
import { HouseAdjustmentsEffects } from "./_effects/house-adjustment.effects";
import { IpmsComponentEffects } from "./_effects/ipms-component.effects";
import { SubCategoryEffects } from "./_effects/sub-category.effects";
import { SubTypeCategoryEffects } from "./_effects/sub-type-category.effects";
import { TopPropertyTypeEffects } from "./_effects/top-property-type.effects";
import { WarehouseAdjustmentsEffects } from "./_effects/warehouse-adjustment.effects";
import { RetailBuildingAdjustmentsEffects } from "./_effects/retail-building-adjustment.effects";
import { houseAdjustmentssReducer } from "./_reducers/house-adjustment.reducers";
import { ipmsComponentsReducer } from "./_reducers/ipms-component.reducers";
import { subCategoryReducer } from "./_reducers/sub-category.reducers";
import { subTypeCategoryReducer } from "./_reducers/sub-type-category.reducers";
import { topPropertyTypeReducer } from "./_reducers/top-property-type.reducers";
import { warehouseAdjustmentssReducer } from "./_reducers/warehouse-adjustment.reducers";
import { retailBuildingAdjustmentssReducer } from "./_reducers/retail-building-adjustment.reducers";
import { shopFrontTypeReducer } from "./_reducers/shop-front-type.reducer";
import { ShopFrontTypeEffects } from "./_effects/shop-front-type.effects";
import { parkingTypeReducer } from "./_reducers/parking-type.reducers";
import { ParkingTypeEffects } from "./_effects/parking-type.effects";
import { retailStoreAdjustmentsReducer } from "./_reducers/retail-store-adjustment.reducers";
import { parkingAdjustmentssReducer } from "./_reducers/parking-adjustment.reducers";
import { landAdjustmentssReducer } from "./_reducers/land-adjustment.reducers";
import { RetailStoreAdjustmentsEffects } from "./_effects/retail-store-adjustment.effects";
import { ParkingAdjustmentsEffects } from "./_effects/parking-adjustment.effects";
import { LandAdjustmentsEffects } from "./_effects/land-adjustment.effects";
import { additionalCostTitleReducer } from "./_reducers/additional-cost-title.reducer";
import { AdditionalCostTitleEffects } from "./_effects/additional-cost-title.effects";

@NgModule({
    imports: [
        StoreModule.forFeature('interactionTypes', interactionTypesReducer),
        StoreModule.forFeature('purposeOfValuations', purposeOfValuationsReducer),
        StoreModule.forFeature('reportDeliveryMethods', reportDeliveryMethodsReducer),
        StoreModule.forFeature('sourceExternalReferences', sourceExternalReferencesReducer),
        StoreModule.forFeature('baseOfValues', baseOfValuesReducer),
        StoreModule.forFeature('premiseOfValues', premiseOfValuesReducer),
        StoreModule.forFeature('measurementMethodologies', measurementMethodologiesReducer),
        StoreModule.forFeature('purposeMeasurements', purposeMeasurementsReducer),
        StoreModule.forFeature('assetClassTypes', assetClassTypesReducer),
        StoreModule.forFeature('standardMeasurements', standardMeasurementsReducer),
        StoreModule.forFeature('officeStandardMeasurements', officeStandardMeasurementsReducer),
        StoreModule.forFeature('approachesToValues', approachesToValuesReducer),
        StoreModule.forFeature('defaultAssumptions', defaultAssumptionsReducer),
        StoreModule.forFeature('departures', departuresReducer),
        StoreModule.forFeature('includedInValuations', includedInValuationsReducer),
        StoreModule.forFeature('methodsToValues', methodsToValuesReducer),
        StoreModule.forFeature('specialAssumptions', specialAssumptionsReducer),
        StoreModule.forFeature('valuationStandard', valuationStandardReducer),
        StoreModule.forFeature('ipmsStandards', ipmsStandardsReducer),
        StoreModule.forFeature('ivsStandards', ivsStandardsReducer),
        StoreModule.forFeature('ricsStandards', ricsStandardsReducer),
        StoreModule.forFeature('unitMeasurements', unitMeasurementsReducer),
        StoreModule.forFeature('unitAreaMeasurements', unitAreaMeasurementsReducer),
        StoreModule.forFeature('externalAreas', externalAreasReducer),

        StoreModule.forFeature('residentialTypes', residentialTypesReducer),
        StoreModule.forFeature('handoverStandards', handoverStandardsReducer),
        StoreModule.forFeature('grades', gradesReducer),
        StoreModule.forFeature('typeOfRegistrations', typeOfRegistrationsReducer),
        StoreModule.forFeature('facilities', facilitiesReducer),

        StoreModule.forFeature('sourceTypes', sourceTypesReducer),
        StoreModule.forFeature('sourceCredibility', sourceCredibilityReducer),
        StoreModule.forFeature('sourceInformation', sourceInformationReducer),

        StoreModule.forFeature('constructionTypes', constructionTypesReducer),
        StoreModule.forFeature('buildingTypes', buildingTypesReducer),
        StoreModule.forFeature('stateRepairs', stateRepairsReducer),
        StoreModule.forFeature('typeOfTitles', typeOfTitlesReducer),

        StoreModule.forFeature('keyCategories', keyCategoriesReducer),
        StoreModule.forFeature('rentReviewComments', rentReviewCommentsReducer),
        StoreModule.forFeature('floorTypes', floorTypesReducer),
        StoreModule.forFeature('windowTypes', windowTypesReducer),
        StoreModule.forFeature('externalWalls', externalWallsReducer),
        StoreModule.forFeature('foundationTypes', foundationTypesReducer),
        StoreModule.forFeature('assetClassValuationGroups', assetClassValuationGroupsReducer),
        StoreModule.forFeature('valuerQualifications', valuerQualificationsReducer),
        StoreModule.forFeature('propertyTypes', propertyTypesReducer),
        StoreModule.forFeature('tenures', tenuresReducer),

        // StoreModule.forFeature('tpTasks', tpTaskReducer),
        StoreModule.forFeature('taskEfforts', taskEffortsReducer),
        StoreModule.forFeature('officeLayouts', officeLayoutsReducer),
        StoreModule.forFeature('residential-adjustments', residentialAdjustmentssReducer),
        StoreModule.forFeature('office-adjustments', officeAdjustmentssReducer),

        StoreModule.forFeature('buildingUseStatuss', buildingUseStatussReducer),
        StoreModule.forFeature('comparativeLandUses', comparativeLandUsesReducer),
        StoreModule.forFeature('coordinateReferenceSystems', coordinateReferenceSystemsReducer),
        StoreModule.forFeature('landTenures', landTenuresReducer),
        StoreModule.forFeature('parcelCharacteristics', parcelCharacteristicsReducer),
        StoreModule.forFeature('planingStatuses', planingStatusesReducer),
        StoreModule.forFeature('qosDegradations', qosDegradationsReducer),
        StoreModule.forFeature('solUseClassifications', solUseClassificationsReducer),
        StoreModule.forFeature('unitLengthMeasurements', unitLengthMeasurementsReducer),
        StoreModule.forFeature('ipms-components', ipmsComponentsReducer),
        StoreModule.forFeature('topPropertyTypes', topPropertyTypeReducer),
        StoreModule.forFeature('subTypeCategories', subTypeCategoryReducer),
        StoreModule.forFeature('subCategories', subCategoryReducer),
        StoreModule.forFeature('builtinFittings', builtinFittingsReducer),
        StoreModule.forFeature('ceilings', ceilingsReducer),
        StoreModule.forFeature('cubicContents', cubicContentsReducer),
        StoreModule.forFeature('roofStructures', roofStructuresReducer),
        StoreModule.forFeature('storageTypes', storageTypesReducer),
        StoreModule.forFeature('wallsAndPartitions', wallsAndPartitionsReducer),

        StoreModule.forFeature('chimney-stacks', chimneyStackReducer),
        StoreModule.forFeature('roof-coverings', roofCoveringReducer),
        StoreModule.forFeature('conservatory-and-porches', conservatoryPorcheReducer),
        StoreModule.forFeature('main-walls', mainWallReducer),
        StoreModule.forFeature('other-joineries', otherJoineryReducer),
        StoreModule.forFeature('outside-doors', outsideDoorReducer),
        StoreModule.forFeature('rainwater-pipes', rainwaterPipeReducer),
        StoreModule.forFeature('fireplacesChimneyBreasts', fireplacesChimneyBreastsReducer),
        StoreModule.forFeature('bathroomFittings', bathroomFittingsReducer),

        StoreModule.forFeature('gardens', gardenReducer),
        StoreModule.forFeature('outdoor-spaces', outdoorSpaceReducer),

        StoreModule.forFeature('house-adjustments', houseAdjustmentssReducer),
        StoreModule.forFeature('warehouse-adjustments', warehouseAdjustmentssReducer),
        StoreModule.forFeature('retail-building-adjustments', retailBuildingAdjustmentssReducer),
        StoreModule.forFeature('retail-store-adjustments', retailStoreAdjustmentsReducer),
        StoreModule.forFeature('parking-adjustments', parkingAdjustmentssReducer),
        StoreModule.forFeature('land-adjustments', landAdjustmentssReducer),

        StoreModule.forFeature('shop-front-types', shopFrontTypeReducer),
        StoreModule.forFeature('parking-types', parkingTypeReducer),
        StoreModule.forFeature('external-facades', externalFacadeReducer),

        StoreModule.forFeature('floorNumberingSchemes', floorNumberingSchemesReducer),

        StoreModule.forFeature('organisations', organisationReducer),
        StoreModule.forFeature('certifications', certificationReducer),
        StoreModule.forFeature('paymentTypes', paymentTypesReducer),
        StoreModule.forFeature('additional-cost-titles', additionalCostTitleReducer),

        EffectsModule.forFeature([InteractionTypeEffects,
            PurposeOfValuationEffects,
            ReportDeliveryMethodEffects,
            SourceExternalReferenceEffects,
            BaseOfValueEffects,
            PremiseOfValueEffects,
            MeasurementMethodologyEffects,
            PurposeMeasurementEffects,
            AssetClassTypeEffects,
            StandardMeasurementEffects,
            OfficeStandardMeasurementEffects,
            IncludedInValuationEffects,
            SourceExternalReferenceEffects,
            ApproachesToValueEffects,
            PurposeMeasurementEffects,
            DefaultAssumptionEffects,
            DepartureEffects,
            MethodsToValueEffects,
            SpecialAssumptionEffects,
            ValuationStandardEffects,
            IpmsStandardEffects,
            IvsStandardEffects,
            RicsStandardEffects,

            ResidentialTypeEffects,
            HandoverStandardEffects,
            GradeEffects,
            TypeOfRegistrationEffects,
            UnitMeasurementEffects,
            FacilityEffects,

            SourceCredibilityEffects,
            SourceInformationEffects,
            SourceTypeEffects,

            BuildingTypeEffects,
            ConstructionTypeEffects,
            StateRepairEffects,
            TypeOfTitleEffects,

            KeyCategoryEffects,
            RentReviewCommentEffects,

            UnitAreaMeasurementEffects,
            ExternalAreaEffects,
            FloorTypeEffects,
            WindowTypeEffects,
            ExternalWallEffects,
            FoundationTypeEffects,
            AssetClassValuationGroupEffects,
            ValuerQualificationEffects,
            PropertyTypeEffects,
            TenureEffects,

            TaskEffortEffects,
            OfficeLayoutEffects,
            ResidentialAdjustmentsEffects,
            OfficeAdjustmentsEffects,

            BuildingUseStatusEffects,
            ComparativeLandUseEffects,
            CoordinateReferenceSystemEffects,
            LandTenureEffects,
            ParcelCharacteristicEffects,
            PlaningStatusEffects,
            QosDegradationEffects,
            SolUseClassificationEffects,
            UnitLengthMeasurementEffects,
            IpmsComponentEffects,
            TopPropertyTypeEffects,
            SubTypeCategoryEffects,
            SubCategoryEffects,
            BuiltinFittingEffects,
            CeilingEffects,
            CubicContentEffects,
            RoofStructureEffects,
            StorageTypeEffects,
            WallsAndPartitionEffects,

            ChimneyStackEffects,
            RoofCoveringEffects,
            ConservatoryPorcheEffects,
            MainWallEffects,
            OtherJoineryEffects,
            OutsideDoorEffects,
            RainwaterPipeEffects,
            FireplacesChimneyBreastEffects,
            BathroomFittingEffects,
            GardenEffects,
            OutdoorSpaceEffects,
            HouseAdjustmentsEffects,
            WarehouseAdjustmentsEffects,
            RetailBuildingAdjustmentsEffects,

            ShopFrontTypeEffects,
            ParkingTypeEffects,
            RetailStoreAdjustmentsEffects,
            ParkingAdjustmentsEffects,
            LandAdjustmentsEffects,

            ExternalFacadeEffects,

            FloorNumberingSchemeEffects,

            OrganisationEffects,
            CertificationEffects,
            PaymentTypeEffects,
            AdditionalCostTitleEffects
        ])
    ]
})
export class LinkedTablesStoreModule {}
