import {UnitAreaMeasurement} from '../_models/unit-area-measurement.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {UnitAreaMeasurementsState} from '../_reducers/unit-area-measurement.reducers';
import * as fromUnitAreaMeasurement from '../_reducers/unit-area-measurement.reducers';
import {each} from 'lodash';

export const selectUnitAreaMeasurementsState = createFeatureSelector<UnitAreaMeasurementsState>('unitAreaMeasurements');

export const selectUnitAreaMeasurementById = (unitAreaMeasurementId: number) => createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => unitAreaMeasurementsState.entities[unitAreaMeasurementId]
);

export const selectAllUnitAreaMeasurements = createSelector(
    selectUnitAreaMeasurementsState,
    fromUnitAreaMeasurement.selectAll
);

export const selectAllUnitAreaMeasurementsIds = createSelector(
    selectUnitAreaMeasurementsState,
    fromUnitAreaMeasurement.selectIds
);

export const allUnitAreaMeasurementsLoaded = createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => unitAreaMeasurementsState.isAllUnitAreaMeasurementsLoaded
);


export const selectUnitAreaMeasurementsPageLoading = createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => unitAreaMeasurementsState.listLoading
);

export const selectUnitAreaMeasurementsActionLoading = createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => unitAreaMeasurementsState.actionLoading
);

export const selectLastCreatedUnitAreaMeasurementId = createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => unitAreaMeasurementsState.lastCreatedUnitAreaMeasurementId
);

export const selectUnitAreaMeasurementsShowInitWaitingMessage = createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => unitAreaMeasurementsState.showInitWaitingMessage
);

export const selectTrashedUnitAreaMeasurementCount = createSelector(
    selectUnitAreaMeasurementsState,
    (unitAreaMeasurementsState) => unitAreaMeasurementsState.totalTrashed
);

export const selectAdminTrashedUnitAreaMeasurementCount = createSelector(
    selectUnitAreaMeasurementsState,
    (unitAreaMeasurementsState) => unitAreaMeasurementsState.totalAdminTrashed
);


export const selectUnitAreaMeasurementsQueryResult = createSelector(
    selectUnitAreaMeasurementsState,
    unitAreaMeasurementsState => {
        const items: UnitAreaMeasurement[] = [];
        each(unitAreaMeasurementsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: UnitAreaMeasurement[] = httpExtension.sortArray(items, unitAreaMeasurementsState.lastQuery.sortField, unitAreaMeasurementsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, unitAreaMeasurementsState.totalCount, '', unitAreaMeasurementsState.totalTrashed);
    }
);
