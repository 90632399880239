import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';
import {ToeService} from '../../../../../core/toe';
import {AppState} from '../../../../../core/reducers';

@Injectable()
export class ToeWorkersResolver implements Resolve<any> {
    constructor(private store: Store<AppState>,
                private global: Globals,
                private activatedRoute: ActivatedRoute,
                private toeService: ToeService) {
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot) {
        return this.toeService.getWorker(this.global.activeTOEId);
    }
}
