<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<div class="card-label">
				Add new amendment(s) for "{{title}}"
			</div>
		</div>
		<div class="card-toolbar">
			<button mat-button mat-raised-button style="background-color: orange; color: white;" (click)="cancel()" 
				class="mr-2">
				Cancel
			</button>
			<button mat-button mat-raised-button 
				class="validate-btn"
				(click)="validate()">
				Validate
			</button>
		</div>
	</div>

	<div class="card-body pt-0 w-100">
		<mat-dialog-content class="content">
			<div class="form" [formGroup]="form" autocomplete="off">
				<kt-alert *ngIf="(error$|async) as errorMsg" 
					type="warn" 
					[showCloseButton]="true" 
					[duration]="10000"
					(close)="onAlertClose()">
					{{errorMsg}}
				</kt-alert>
				<div class="form-group">
					<div class="row">
						<div class="col-6">
							<mat-form-field class="mat-form-field-fluid">
								<kt-datepicker-tz-input
									#amendmentDatePicker
									placeholder="Date of Amendment"
									formControlName="amendment_date"
									[timezone]="timezone"
									[min]="data.toe.instruction_date"></kt-datepicker-tz-input>
								<button mat-button mat-icon-button matSuffix type="button">
									<mat-icon>today</mat-icon>
								</button>
								<button mat-button mat-icon-button matSuffix type="button" (click)="amendmentDatePicker.clearDate()">
									<mat-icon>close</mat-icon>
								</button>
								<mat-error>Date of Amendment is <strong>required</strong></mat-error>
								<!-- <span (click)="amendmentDatePicker.open()">
									<input matInput [matDatepicker]="amendmentDatePicker" readonly 
										placeholder="Date of Amendment"
										[min]="data.toe.instruction_date"
										formControlName="amendment_date">
								</span>
								<mat-error>Date of Amendment is <strong>required</strong></mat-error>
								<mat-datepicker-toggle matSuffix [for]="amendmentDatePicker"></mat-datepicker-toggle>
								<button mat-button *ngIf="form.controls.amendment_date" matSuffix mat-icon-button matTooltip="Clear"
									(click)="clearDate(form.controls.amendment_date)">
									<mat-icon>close</mat-icon> 
								</button>
								<mat-datepicker #amendmentDatePicker></mat-datepicker> -->
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div ngbDropdown [placement]="'bottom-left'" 
								class="dropdown dropdown-inline">
								<a ngbDropdownToggle href="javascript:;"
									class="btn btn-label-brand btn-bold bnt-sm" style="cursor: pointer !important;">
									<span class="d-flex align-items-center">
										Add an amendment	
										<mat-icon class="ml-4" inline="true">add_circle</mat-icon>
									</span>
								</a>	
								<div ngbDropdownMenu>
									<ul class="navi navi-hover" style="width: 200px;">
										<li class="navi-item" *ngFor="let action of (amendmentActions$|async)">
											<a href="javascript:;" [class]="!action.disabled ? 'navi-link' : 'navi-link disabled'" (click)="actionClick(action)">
												<span class="navi-text">{{action.name}}</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div class="form-group">
					<ng-container *ngFor="let amendment of (amendments$|async)">
						<ng-container [ngSwitch]="amendment.type">
							<div class="row" *ngSwitchCase="amendmentType.RemoveTP">
								<div class="col-12">
									<kt-remove-tp-amendment-v3
										#amendmentComp 
										[inputAmendment]="amendment"
										(onAmendmentRemove)="onAmendmentRemove($event)"></kt-remove-tp-amendment-v3>
								</div>
							</div>
							<div class="row" *ngSwitchCase="amendmentType.AbortToe">
								<div class="col-12">
									<kt-abort-toe-amendment #amendmentComp 
										[inputAmendment]="amendment" 
										(onAmendmentRemove)="onAmendmentRemove($event)"></kt-abort-toe-amendment>
								</div>
							</div>
							<div class="row" *ngSwitchDefault>
								<div class="col-12">
									None
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>	
		</mat-dialog-content>
	</div>
</div>