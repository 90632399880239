// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// Models
import {ClientContactModel} from '../_models/client-contact.model';

export enum ContactActionTypes {

    AllContactsRequested = '[Contacts Home Page] All Contacts Requested',
    AllContactsLoaded = '[Contacts API] All Contacts Loaded',
    AllContactsLoadedWithTrash = '[Contacts API] All Contacts Loaded WIth Trash',

    ContactOnServerCreated = '[Edit Contact Dialog] Contact On Server Created',
    ContactCreated = '[Edit Contacts Dialog] Contacts Created',
    ContactUpdated = '[Edit Contact Dialog] Contact Updated',
    ContactDeletedFromTrash = '[Contacts List Page] Contact Deleted',

    ContactsPageToggleLoading = '[Contacts page] Contacts Page Toggle Loading',
    ContactsActionToggleLoading = '[Contacts] Contacts Action Toggle Loading',
}

export class ContactOnServerCreated implements Action {
    readonly type = ContactActionTypes.ContactOnServerCreated;

    constructor(public payload: { contact: ClientContactModel }) {
    }
}

export class ContactCreated implements Action {
    readonly type = ContactActionTypes.ContactCreated;

    constructor(public payload: { contact: ClientContactModel }) {
    }
}

export class ContactUpdated implements Action {
    readonly type = ContactActionTypes.ContactUpdated;

    constructor(public payload: {
        partialContact: Update<ClientContactModel>,
        contact: ClientContactModel
    }) {
    }
}

export class ContactDeletedFromTrash implements Action {
    readonly type = ContactActionTypes.ContactDeletedFromTrash;

    constructor(public payload: { contactId: number }) {
    }
}

export class AllContactsRequested implements Action {
    readonly type = ContactActionTypes.AllContactsRequested;

    constructor(public payload: { clientId: number }) {
    }
}

export class AllContactsLoaded implements Action {
    readonly type = ContactActionTypes.AllContactsLoaded;

    constructor(public payload: { contacts: ClientContactModel[] }) {
    }
}

export class AllContactsLoadedWithTrash implements Action {
    readonly type = ContactActionTypes.AllContactsLoadedWithTrash;

    constructor(public payload: { contacts: ClientContactModel[], totalTrashed: number }) {
    }
}

export class ContactsPageToggleLoading implements Action {
    readonly type = ContactActionTypes.ContactsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ContactsActionToggleLoading implements Action {
    readonly type = ContactActionTypes.ContactsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export type ContactActions = ContactCreated
    | ContactUpdated
    | AllContactsLoaded
    | AllContactsLoadedWithTrash
    | AllContactsRequested
    | ContactOnServerCreated
    | ContactsPageToggleLoading
    | ContactsActionToggleLoading
    | ContactDeletedFromTrash;
