<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{mode == 'edit' ? 'Edit Adjustment' : 'Add Addjustment'}}</h3>
        </div>
        <div class="card-toolbar">
			<button mat-button mat-raised-button class="btn-success mr-2" 
				color="warn" aria-label="Close" (click)="onSaveClick()">
                Save
			</button>
			<button mat-button mat-raised-button style="background-color: orange; color: white"
				color="warn" aria-label="Close" (click)="onNoClick()">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
    </div>
    <div class="card-body">
        <form class="form" [formGroup]="formGroup" *ngIf="formGroup">
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select placeholder="Adjustment" formControlName="adjustment_id">
                                <mat-option [value]="0">Other</mat-option>
                                <mat-option *ngFor="let item of adjustments" [value]="item.id" [disabled]="item.disabled">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>Adjustment <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6" *ngIf="formGroup.controls.adjustment_id.value == 0">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Adjustment Name" formControlName="adjustment_name" />
                            <mat-error>Adjustment name <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <textarea matInput placeholder="Adjustment Description" rows="2" formControlName="adjustment_description"></textarea>
                            <mat-error>Adjustment description <strong>required</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>