<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Edit Video Information</h3>
        </div>
        <div class="card-toolbar">
            <button
                (click)="onYesClick()" 
                class="btn btn-sm btn-success mr-2" cdkFocusInitial
                style="width: 80px;" >
                Save
            </button>
            <button (click)="onNoClick()"
                class="btn btn-sm btn-icon btn-light-warning p-1">
                <i class="ki ki-close icon-nm"></i>
            </button>
        </div>
    </div>
    <div class="form">
        <div class="card-body">
            <div class="form-group row">
                <div class="col-lg-12">
                    <mat-form-field class="mat-form-field-fluid">
                        <textarea matInput rows="4" placeholder="Description"
                            [formControl]="descriptionControl">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>