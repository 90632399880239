import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { PaymentsPageLoaded } from "../../asset_class";
import { StandardMeasurement, UnitAreaMeasurement } from "../../linked-tables";
import { SizeCriterionActions, SizeCriterionActionTypes } from "../_actions/size_criterion.actions";
import { CriterionModel } from "../_models/criterion.model";

export interface SizeCriterionsState extends EntityState<CriterionModel> {
    standardMeasurements: StandardMeasurement[],
    unitAreaMeasurement: UnitAreaMeasurement,
    default: CriterionModel,
    isLoading: boolean
}

export const adapter: EntityAdapter<CriterionModel> = createEntityAdapter();

export const initialSizeCriterionsState: SizeCriterionsState = adapter.getInitialState({
    standardMeasurements: [],
    unitAreaMeasurement: null,
    default: null,
    isLoading: true,
});

export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();

export function sizeCriterionReducer(state = initialSizeCriterionsState, action: SizeCriterionActions): SizeCriterionsState {
    switch (action.type) {
        case SizeCriterionActionTypes.SizeCriterionsLoading: {
            return {...state , isLoading: true}
        }
        case SizeCriterionActionTypes.SizeCriterionsCreated: {
            return adapter.setAll(action.payload.criterions, {
                ...state,
                default: action.payload.default,
                standardMeasurements: action.payload.standardMeasurements,
                unitAreaMeasurement: action.payload.unitAreaMeasurement,
                isLoading: false
            })
        }
        case SizeCriterionActionTypes.SizeCriterionComparableAdded: {
            return adapter.setAll(action.payload.criterions, {
                ...state,
                default: action.payload.default
            })
        }
        case SizeCriterionActionTypes.SizeCriterionComparableRemoved: {
            return adapter.setAll(action.payload.criterions, {
                ...state,
                default: action.payload.default
            })
        }
        case SizeCriterionActionTypes.SizeCriterionDefaultChanged: {
            return adapter.updateMany(action.payload.changedCriterions, {
                ...state,
                default: action.payload.default
            });
        }
        case SizeCriterionActionTypes.SizeCriterionResetted: {
            if (action.payload.changed) {
                return adapter.updateOne(action.payload.changed, {
                    ...state,
                    default: action.payload.default
                });
            }
            return {
                ...state,
                default: action.payload.default
            }
        }
        case SizeCriterionActionTypes.RestartSizeCriterionState: {
            return initialSizeCriterionsState
        }
        case SizeCriterionActionTypes.SizeCriterionValueChanged: {
            const _state = {...state};
            if (action.payload.default) {
                _state.default = action.payload.default;
            }
            return adapter.setAll(action.payload.criterions, {
                ..._state
            })
        }
        default: 
            return state;
    }
}

