<div class="card card-custom">
  <div *ngIf="loading$|async" class="card-body-progress card-body-progress-overlay">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{title$|async}}
      </h3>
    </div>

    <div class="card-toolbar">
      <span [matTooltip]="'Duplicate'">
        <button class="btn btn-icon btn-sm btn-success mr-2 p-1" style="width: 80px" (click)="onSubmit()">
          Validate
        </button>
      </span>

      <button class="btn btn-icon btn-sm btn-light-warning p-1" (click)="onClose()">
        <i class="ki ki-close icon-nm"></i>
      </button>
    </div>
  </div>

  <div class="form">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h5 class="my-3 text-mad">Target Properties</h5>
          <hr class="active" />
        </div>

        <ng-container *ngFor="let option of (tps$|async)">
          <div class="col-4">
            <mat-checkbox [checked]="option.checked" (change)="onTpSelected($event, option)">
              {{option.name}}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>