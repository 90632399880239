<div class="d-flex flex-column">

    <form class="form" [formGroup]="formGroup" *ngIf="formGroup">
        <div class="form-group kt-form-group row">
            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Payment terms" name="paymentTermCnt" formControlName="payment_term" (change)="addItem($event.target.value)" type="number" ng-min="paymentTermMin" ng-max="paymentTermMax"/>
                    <mat-error *ngIf="formGroup.controls.payment_term.errors?.min || formGroup.controls.payment_term.errors?.max">
                        Value must be between {{paymentTermMin}} to {{paymentTermMax}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6 kt-margin-bottom-10-mobile"></div>
            <ng-container formArrayName="terms">
                <ng-container *ngFor="let termForm of terms.controls; let i = index">
                    <ng-container [formGroup]="termForm">
                        <div class="col-lg-4 kt-margin-bottom-10-mobile mb-3 mt-3">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput [placeholder]="termForm.controls.title.value" formControlName="percent" mask="percent" suffix="%"/>
                                <mat-error>
                                    Percent
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-10-mobile">
                            <mat-form-field class="mat-form-field-fluid mt-3">
                                    <!-- <span (click)="addPicker_payment_date.open()">
                                    <input matInput [matDatepicker]="addPicker_payment_date" (click)="addPicker_payment_date.open()"
                                        readonly
                                        [placeholder]="termForm.controls.title.value.replace('-', ' Date-')"
                                        formControlName="payment_date">
                                    </span>
                                <mat-datepicker-toggle matSuffix [for]="addPicker_payment_date"></mat-datepicker-toggle>
                                <button mat-button *ngIf="termForm.controls.payment_date.value" matSuffix mat-icon-button
                                        matTooltip="Clear"
                                        (click)="clearDate(i)">
                                    <mat-icon>close</mat-icon>
                                </button>
        
                                <mat-datepicker #addPicker_payment_date></mat-datepicker> -->
                                <kt-datepicker-tz-input
                                    #paymentDatePicker
                                    [placeholder]="termForm.controls.title.value.replace('-', ' Date-')"
                                    [timezone]="timezoneOffset"
                                    formControlName="payment_date"
                                ></kt-datepicker-tz-input>
                                <button mat-button mat-icon-button matSuffix type="button">
                                    <mat-icon>today</mat-icon>
                                </button>
                                <button mat-button mat-icon-button matSuffix type="button" (click)="clearDate(i)">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-error>
                                    Payment Date
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-10-mobile"></div>
                    </ng-container>
                </ng-container>
                <div class="col-md-12 kt-margin-bottom-20-mobile">
                    <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="terms.length == 0">
                        Payment terms cannot be 
                    </mat-error>
                    <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="terms.length > 0 && termsPercent != 100">
                        The total payment terms should equal 100%. It is currently: <strong>{{termsPercent}}</strong>
                    </mat-error>
                    <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="terms.length > 1 && termsPercent == 100 && !paymentDateValid">
                        {{message}}
                    </mat-error>
                </div>
            </ng-container>
        </div>
    </form>
</div>