// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ConflictState, selectAll} from '../_reducers/conflict.reducers';

export const selectConflictState = createFeatureSelector<ConflictState>('conflicts');

export const selectConflictById = (id: number) => createSelector(
    selectConflictState,
    conflictState => conflictState.entities[id]
);

export const selectAllConflicts = createSelector(
    selectConflictState,
    selectAll
);
