import * as _toeTemplateSelectors from './toe-template.selectors';
import * as _tpTemplateSelectors from './tp-template.selectors';
import * as _externalRefTemplateSelectors from './external-ref-template.selectors';
import * as _adjustmentTemplateSelectors from './adjustment-template.selectors';
import * as _criterionsTemplateSelectors from './criterions-template.selectors';
import * as _teamTemplateSelectors from './team-template.selectors';

export const toeTemplateSelectors = _toeTemplateSelectors;
export const tpTemplateSelectors = _tpTemplateSelectors;
export const externalRefTemplateSelectors = _externalRefTemplateSelectors;
export const adjustmentTemplateSelectors = _adjustmentTemplateSelectors;
export const criterionsTemplateSelectors = _criterionsTemplateSelectors;
export const teamTemplateSelectors = _teamTemplateSelectors;