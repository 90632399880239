import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ValuationProcessSizeCriterionsState, adapter } from "../_reducers/valuation-process-size-criterion.reducers";

const { selectAll } = adapter.getSelectors()

export const selectValuationProcessSizeCriterionsState = createFeatureSelector<ValuationProcessSizeCriterionsState>('valuation-size-criterions')

export const selectValuationProcessSizeCriterions = createSelector(
    selectValuationProcessSizeCriterionsState,
    selectAll
)

export const selectValuationProcessDefaultSizeCriterion = createSelector(
    selectValuationProcessSizeCriterions,
    sizeCriterions => {
        return sizeCriterions.find(crit => crit.active)
    }
)