import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { VPOModel } from 'src/app/core/admin/_models/agency.model';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { takeUntil } from 'rxjs/operators';
import { EditElementWithInfoDialogRef } from 'src/app/core/_base/crud/utils/layout-utils.service';

@Component({
  selector: 'kt-vpos-table',
  templateUrl: './vpos-table.component.html',
  styleUrls: ['./vpos-table.component.scss']
})
export class VposTableComponent implements OnInit, OnDestroy {
  @Input() vpos: VPOModel[] = [];

  dataSource = new MatTableDataSource();
  displayedColumns = ['vpo_name', 'membership_id', 'prime_reference', 'actions']

  private _vpos$: BehaviorSubject<VPOModel[]> = new BehaviorSubject([]);
  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit(): void {
    this._vpos$.next(_.cloneDeep(this.vpos));
    this._vpos$.pipe(takeUntil(this._onDestroy$)).subscribe(vpos => {
      this.dataSource.data = vpos;
    }) 
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getVpos(): VPOModel[] {
    const vpos = this._vpos$.value;
    return vpos;
  }

  addItem() {
    const dialogRef = this._openEditDialog('Add VPO', null, null);
    dialogRef.afterClosed()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }
        const vpo = new VPOModel();
        res.result.forEach(f => {
          vpo[f.key] = f.value;
        });
        this._updateArr(vpo);
      })
  }

  editItem(item: VPOModel, index: number) {
    const dialogRef = this._openEditDialog('Edit VPO', item.name, item.membership_id);
    dialogRef.afterClosed()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }
        const vpo = new VPOModel();
        res.result.forEach(f => {
          vpo[f.key] = f.value;
        });
        vpo.id = item.id;
        vpo.is_prime_ref = item.is_prime_ref;
        this._updateArr(vpo, index);
      })
  }
  deleteItem(index: number) {
    const vpos = this._vpos$.value;
    vpos.splice(index, 1);
    this._vpos$.next(vpos);
  }

  changeIsPrime(index: number, event: MatCheckboxChange) {
    const vpos = this._vpos$.value;
    const vpo = vpos[index];
    vpo.is_prime_ref = event.checked;
    this._vpos$.next(vpos);
  }

  private _openEditDialog(title: string, vpoName: string, membershipId: string): EditElementWithInfoDialogRef {
    return this.layoutUtilsService.editElementWithInfo({
      title: title,
      configs: [
        {
          type: 'text',
          label: 'VPO Name',
          value: vpoName,
          key: 'name',
          validationRules: ['required']
        },
        {
          type: 'text',
          label: 'Membership ID',
          value: membershipId,
          key: 'membership_id'
        },
      ]
    });
  }

  private _updateArr(vpo: VPOModel, index?: number) {
    const vpos = this._vpos$.value;
    if (index != undefined || index != null) {
      vpos[index] = vpo;
      this._vpos$.next(vpos);
      return;
    } 
    vpos.push(vpo);
    this._vpos$.next(vpos);
    return;
  }
}
