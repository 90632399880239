import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { TranslateService } from '@ngx-translate/core';

import { Link } from '../../../../../core/asset_class';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

const PARTICIPANTS_SIDE: string[] = ['None', 'Our Side', 'Client Side', 'External'];

@Component({
    selector: 'kt-link-edit-dialog',
    templateUrl: './link-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./link-edit.dialog.component.scss']
})
export class LinkEditDialogComponent implements OnInit, OnDestroy {

    // // Public properties
    link: Link;
    linkForm: UntypedFormGroup;
    link$: Observable<Link>;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    linksSide: {name: string, value: string}[] = [];
    selectedParticipantSide: {name: string, value: string};
    link_type = 1;

    // Private properties
    private componentSubscriptions: Subscription;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<LinkEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.data.link) {
            this.link = Object.assign({}, this.data.link);
        } else {
            this.link = new Link(this.link_type);
        }
        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Create form
     */
    createForm() {
        this.linkForm = this.fb.group({
            title: [this.link.title, Validators.required],
            description: [this.link.description, Validators.required],
            url: [this.link.url, Validators.required],
            link_type: [this.link.link_type]
        });
    }

    /** ACTIONS */

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        /** check form */
        if (!this.isNameValid()) {
            this.hasFormErrors = true;
            return;
        }
        
        const controls = this.linkForm.controls;
        /** check form */
        if (this.linkForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
        
            this.hasFormErrors = true;
            return;
        }
        this.link.title = this.linkForm.controls.title.value;
        this.link.description = this.linkForm.controls.description.value;
        this.link.url = this.linkForm.controls.url.value;
        
        this.dialogRef.close({
            link: this.link,
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        // this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.link && this.link.id) {
            return `Edit Matterport Link`;
        }
        
        return 'New Matterport Link';
    }

    /**
     * Returns is name valid
     */
    isNameValid(): boolean {
        return this.linkForm.valid;
    }

}
