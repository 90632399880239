// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
// Models
import {AssetClassResidentialModel} from '../_models/asset-class-residential.model';
import {environment} from '../../../../environments/environment';
import {AssetClassBuildingInformation} from '../_models/asset-class-building-information.model';
import {AssetClassTenure} from '../_models/asset-class-tenure.model';
import {AssetClassSizeModel} from '../_models/asset-class-size.model';
import {ResidentialExternalAreaModel} from '../_models/residential-external-area.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';

const RESIDENTIALS_PATH = 'residentials';

@Injectable()
export class AssetClassResidentialsService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    findAssetClassResidentials(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getApartments(queryParams: QueryParamsModel): Observable<any> {


        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
        };
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + '/data';
        return this.http.post<any>(url, _body, {headers: httpHeaders});
    }

    createAssetClassResidential(_assetClassResidential: AssetClassResidentialModel,
                                _tenures: AssetClassTenure[],
                                _sizes: AssetClassSizeModel[],
                                _fileList: any[],
                                _selectedFacilities: any[],
                                _apartmentResidentialExternalAreas: ResidentialExternalAreaModel[],
                                _documents: any[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {
            assetClassResidential: _assetClassResidential,
            tenures: _tenures,
            sizes: _sizes,
            fileList: _fileList,
            selectedFacilities: _selectedFacilities,
            apartmentResidentialExternalAreas: _apartmentResidentialExternalAreas,
            documents: _documents
        };
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        return this.http.post<any>(url, body, {headers: httpHeaders});
    }

    updateAssetClassResidential(_assetClassResidential: AssetClassResidentialModel,
                                _tenures: AssetClassTenure[],
                                _sizes: AssetClassSizeModel[],
                                _fileList: any[],
                                _selectedFacilities: any[],
                                _apartmentResidentialExternalAreas: ResidentialExternalAreaModel[],
                                _documents: any[]): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            assetClassResidential: _assetClassResidential,
            tenures: _tenures,
            sizes: _sizes,
            fileList: _fileList,
            selectedFacilities: _selectedFacilities,
            apartmentResidentialExternalAreas: _apartmentResidentialExternalAreas,
            documents: _documents
        };
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;

        return this.http.put(url + `/${_assetClassResidential.id}`, body, {headers: httpHeader});
    }

    // Trash
    getTrashed(): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/trash`, {headers: httpHeaders});
    }

    // admin Trash
    getAdminTrashed(): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + `/admin/trash`, {headers: httpHeaders});
    }

    // Trash
    // delete from trash
    deleteFromTrash(assetClassResidentialId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl +
            `api/${this.subDomainService.subDomain}/comparable/residentials/trash/${assetClassResidentialId}`,
            {headers: httpHeaders});
    }

    // delete from Admin trash
    deleteFromAdminTrash(assetClassResidentialId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/residentials/admin/trash/${assetClassResidentialId}`,
            {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(assetClassResidentialId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/residentials` + `/trash/${assetClassResidentialId}`,
            {headers: httpHeaders});
    }

    deleteAssetClassResidential(assetClassResidentialId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/comparable/residentials/${assetClassResidentialId}`;
        return this.http.delete<any>(url, {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + '/trash/flush', {headers: httpHeaders});
    }

    getAssetClassResidential(assetClassResidentialId: number): Observable<AssetClassResidentialModel> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<AssetClassResidentialModel>(url + `/${assetClassResidentialId}`, {headers: httpHeaders});
    }

    getBuildingInfoById(assetClassResidentialId: number): Observable<AssetClassBuildingInformation> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/${assetClassResidentialId}/building-information`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<AssetClassBuildingInformation>(url, {headers: httpHeaders});
    }

    getBuildingInfos(): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/building-informations`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getTenuresOfAc(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/${id}/tenures`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getSizesOfAc(id: number): Observable<any> {
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH + `/${id}/sizes`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getApartmentsByLocation(latLng1: google.maps.LatLng, latLng2: google.maps.LatLng): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        httpHeader.set('Content-Type', 'application/json');
        const body = {
            max_lng: latLng2.lng(),
            max_lat: latLng2.lat(),
            min_lng: latLng1.lng(),
            min_lat: latLng1.lat(),
        };
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;

        return this.http.post(url + `/map`, body, {headers: httpHeader});
    }

    duplicate(id: number): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + RESIDENTIALS_PATH;
        return this.http.post<any>(url + `/${id}/copy`, {}, {
            headers: httpHeader
        });
    }
}
