import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {
    Appendix,
    AppendixDeleted,
    AppendicesDataSource,
    AppendicesPageRequested,
    selectAdminTrashedAppendicesCount,
    selectTrashedAppendicesCount, AppendixCategory, AppendicesService, selectAppendixCategoryById,
    AppendiceDeletedFromAdminTrash,
    AppendiceDeletedFromTrash, AppendiceOnServerAdminRestored,
    AppendiceOnServerRestored, AppendiceTrashFlushed, AppendicesActionToggleLoading, AppendicesClear
} from '../../../../../core/admin';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../core/_base/crud';
import {SubheaderService} from '../../../../../core/_base/layout';
import { User } from 'src/app/core/auth';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';


@Component({
    selector: 'kt-appendices-list',
    templateUrl: './appendices-list.component.html',
    styleUrls: ['./appendices-list.component.scss']
})

export class AppendicesListComponent implements OnInit, OnDestroy {

    dataSource: AppendicesDataSource;
    displayedColumns = ['id', 'name', 'description', 'actions'];
    trashCnt = 0;
    adminTrashCnt = 0;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    appendixResult: Appendix[] = [];
    catId: number;
    category: AppendixCategory;
    currentUser: User|null

    // Subscriptions
    private subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param subheaderService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param service
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private service: AppendicesService,
                private store: Store<AppState>) {
        this.store.dispatch(new AppendicesClear());
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
        this.subscriptions.push(userSub)
        this.catId = Number(this.activatedRoute.snapshot.paramMap.get('cat_id'));

        const catSubscription = this.store.pipe(select(selectAppendixCategoryById(this.catId))).subscribe(res => {
            if (res) {
                this.category = Object.assign({}, res);
            }
        });
        this.subscriptions.push(catSubscription);

        this.paginator._intl.itemsPerPageLabel = this.translate.instant('GENERAL.PAGELABEL');
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadAppendicesList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadAppendicesList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new AppendicesDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.appendixResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedAppendicesCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedAppendicesCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);


        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadAppendicesList();
        }); // Remove this line, just loading imitation
    }

    /**
     * Load Appendices List from service through data-source
     */
    loadAppendicesList() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
        // Call request from server
        this.store.dispatch(new AppendicesPageRequested({page: queryParams, catId: this.catId}));
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.name = searchText;
        if (!searchText) {
            return filter;
        }
        return filter;
    }

    /**
     * Returns object for filter
     */
    resetFilter(): any {
        this.searchInput.nativeElement.value = '';
        this.loadAppendicesList();
    }

    /**
     * Show add appendix dialog
     */
    addAppendix() {
        this.editAppendix(null);
    }

    /**
     * Show Edit appendix view and save after success close result
     * @param _appendix: Appendix
     */
    editAppendix(_appendix) {
        if (_appendix) {
            this.router.navigate(['edit', _appendix.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['add'], {relativeTo: this.activatedRoute});
        }
    }

    deleteAppendix(_item: Appendix) {
        const _title = this.translate.instant('APPENDIX.LIST.DIALOG.DELETE.TITLE');
        const _description: string = this.translate.instant('APPENDIX.LIST.DIALOG.DELETE.DESCRIPTION', {id: _item.id});
        const _waitDesciption = this.translate.instant('APPENDIX.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new AppendixDeleted({id: _item.id}));
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


// Fetch trash data
    trash() {
        this.store.dispatch(new AppendicesActionToggleLoading({isLoading: true}));
        this.service.getTrashed(this.catId).subscribe(res => {

            this.store.dispatch(new AppendicesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy,
                    date: elem.user_deleted
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new AppendicesActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed(this.catId).subscribe(res => {

            this.store.dispatch(new AppendicesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.deletedBy,
                    date: elem.deleted_at
                });
            });
            this.show_trash(items, true);
        });
    }

    /**
     * Show Edit agency dialog and save after success close result
     * @param items: any[]
     * @param isAdmin: boolean
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = this.translate.instant('APPENDIX.LIST.DIALOG.SHOW_TRASH.TITLE');
        if (isAdmin) {
            _title = this.translate.instant('APPENDIX.LIST.DIALOG.SHOW_TRASH.TITLE_ADMIN');
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new AppendiceOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new AppendiceOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new AppendiceDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new AppendiceDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new AppendiceTrashFlushed({catId: this.catId}));
    }

}
