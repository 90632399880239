import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { select, Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import { selectCountryById } from "../../admin";
import { AppState } from "../../reducers";
import * as valuationVpga10MattersActions from '../_actions/vpga10-matters.actions';
import { Status } from "../_models/valuation-assumption-departure.model";
import { ValuationVpga10Matter } from "../_models/vpga10-matter.model";
import * as valuationVpga10MattersSelectors from '../_selectors/vpga10-matters.selectors';
import { Vpga10MatterService } from "../_services/vpga10-matters.service";

@Injectable()
export class ValuationVpga10MattersEffects {
    constructor (
        private actions$: Actions,
        private store$: Store<AppState>,
        private service: Vpga10MatterService,
    ) {}

    @Effect()
    loadData$ = this.actions$.pipe(
        ofType<valuationVpga10MattersActions.LoadData>(valuationVpga10MattersActions.ActionTypes.LoadData),
        map(({payload}) => {
            let id = 0;
            const vpga10Matters: ValuationVpga10Matter[] = [];
            payload.data.forEach(item => {
                const _new = Object.assign({}, item) as ValuationVpga10Matter;
                _new.front_id = id;
                id++;
                vpga10Matters.push(_new);
            });

            return new valuationVpga10MattersActions.DataLoaded({
                data: vpga10Matters,
                id: id -1
            });
        })
    )

    @Effect()
    add$ = this.actions$.pipe(
        ofType<valuationVpga10MattersActions.AddVpgaMatter>(valuationVpga10MattersActions.ActionTypes.AddVpgaMatter),
        mergeMap(({payload}) => {
            return combineLatest([
                of(payload.data),
                this.store$.pipe(select(valuationVpga10MattersSelectors.selectLastCreatedID), take(1))
            ])
        }),
        map(([data, id]) => {
            const _new = Object.assign({}, data) as ValuationVpga10Matter;
            _new.front_id = id + 1;
            return new valuationVpga10MattersActions.VpgaMatterAdded({
                data: _new,
                id: _new.front_id
            });
        })
    )

    @Effect()
    edit$ = this.actions$.pipe(
        ofType<valuationVpga10MattersActions.EditVpgaMatter>(valuationVpga10MattersActions.ActionTypes.EditVpgaMatter),
        map(({payload}) => {
            const _update: Update<ValuationVpga10Matter> = {
                id: payload.data.front_id,
                changes: {
                    ...payload.data
                }
            };
            return new valuationVpga10MattersActions.VpgaMatterEdited({
                data: _update
            });
        })
    )

    @Effect()
    restoreLocalVpga = this.actions$.pipe(
        ofType<valuationVpga10MattersActions.RestoreLocalVpgaMatter>(valuationVpga10MattersActions.ActionTypes.RestoreVpgaMatter),
        map(({payload}) => {
            const res = this.service.restoreLocal(payload.tp, payload.data);
            const _updated: Update<ValuationVpga10Matter> = {
                id: res.front_id,
                changes: {
                    justification: null,
                    status: Status.Valid,
                    input: res.input
                }
            };
            return new valuationVpga10MattersActions.VpgaMatterRestored({
                data: _updated
            });
        })
    )

    @Effect()
    removeLocalVpga = this.actions$.pipe(
        ofType<valuationVpga10MattersActions.RemoveToeLocalVpgaMatter>(valuationVpga10MattersActions.ActionTypes.RemoveToeVpgaMatter),
        map(({payload}) => {
            const res = this.service.restoreLocal(payload.tp, payload.data);
            const _updated: Update<ValuationVpga10Matter> = {
                id: res.front_id,
                changes: {
                    justification: payload.justification,
                    status: Status.Removed,
                    input: res.input
                }
            };
            return new valuationVpga10MattersActions.ToeVpgaMatterRemoved({
                data: _updated
            });
        })
    )
}