import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    AssetClassRetailFacilitiesService
} from '../../../../../core/comparable';

@Injectable()
export class AssetClassRetailFacilitiesGetResolver implements Resolve<any> {
    constructor(public assetClassRetailFacilitiesService: AssetClassRetailFacilitiesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const retailId = Number(route.params.retail_id);
        return this.assetClassRetailFacilitiesService.getAllAssetClassRetailFacilities(retailId);
    }
}
