import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { ValuationStandard } from '../_models/valuation-standard.model';
import { SubdomainService } from '../../_base/subdomain.service';
import { map } from 'rxjs/operators';


@Injectable()
export class ValuationStandardService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/valuation-standards`
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private httpUtils: HttpUtilsService,
        private subDomainService: SubdomainService
    ) {}

    getAll(): Observable<any> {
        return this.http.get<any>(this.API_URL, {
            headers: this.authService.getAuthHeaders()
        });
    }

    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(valuationStandardId: number): Observable<ValuationStandard> {
        return this.http.get<ValuationStandard>(this.API_URL + `/${valuationStandardId}`, {
            headers: this.authService.getAuthHeaders()
        });
    }

    create(valuationStandard: ValuationStandard): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {valuationStandard: valuationStandard};
        return this.http.post(this.API_URL, body, {headers: httpHeaders});
    }

    update(valuationStandard: ValuationStandard): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(
            this.API_URL + `/${valuationStandard.id}`,
            valuationStandard, 
            {headers: httpHeaders});
    }

    delete(valuationStandardId: number): Observable<any> {
        return this.http.delete(this.API_URL + `/${valuationStandardId}`, {
            headers: this.authService.getAuthHeaders()
        });
    }

    // Trash
    getTrashed(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash', {
            headers: this.authService.getAuthHeaders()
        });
    }

    flushTrash(): Observable<any> {
        return this.http.get<any>(this.API_URL + '/trash/flush', {
            headers: this.authService.getAuthHeaders()
        });
    }

    deleteFromTrash(id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/trash/${id}`, {
            headers: this.authService.getAuthHeaders()
        });
    }

    restoreFromTrash(id: number): Observable<any> {
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: this.authService.getAuthHeaders()});
    }

    // Admin trash
    getAdminTrashed(): Observable<any> {
        return this.http.get(this.API_URL + `/admin/trash`, {headers: this.authService.getAuthHeaders()});
    }

    deleteFromAdminTrash(_id: number): Observable<any> {
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: this.authService.getAuthHeaders()});
    }

    fetchListing() {
        return this.http.get<{data: {id: number, name: string}[]}>(
            environment.baseApiUrl + `api/v2/${this.subDomainService.subDomain}/valuation-standards/listing`,
            {
                headers: this.authService.getAuthHeaders()
            }
        ).pipe(
            map(response => response.data)
        )
    }
}