// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {WindowTypesService} from '../_services/window-type.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allWindowTypesLoaded} from '../_selectors/window-type.selectors';
// Actions
import {
    AllWindowTypesLoaded,
    AllWindowTypesRequested,
    WindowTypeActionTypes,
    WindowTypesPageRequested,
    WindowTypesPageLoaded,
    WindowTypeUpdated,
    WindowTypesPageToggleLoading,
    WindowTypeDeleted,
    WindowTypeOnServerCreated,
    WindowTypeCreated,
    WindowTypeAdminRestored,
    WindowTypeDeletedFromAdminTrash,
    WindowTypeDeletedFromTrash,
    WindowTypeOnServerAdminRestored,
    WindowTypeOnServerRestored,
    WindowTypeRestored,
    WindowTypeTrashFlushed,
    WindowTypesActionToggleLoading
} from '../_actions/window-type.actions';

@Injectable()
export class WindowTypeEffects {
    showPageLoadingDispatcher = new WindowTypesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new WindowTypesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new WindowTypesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllWindowTypes$ = this.actions$
        .pipe(
            ofType<AllWindowTypesRequested>(WindowTypeActionTypes.AllWindowTypesRequested),
            withLatestFrom(this.store.pipe(select(allWindowTypesLoaded))),
            filter(([action, isAllWindowTypesLoaded]) => !isAllWindowTypesLoaded),
            mergeMap(() => this.service.getAllWindowTypes()),
            map(res => {
                return new AllWindowTypesLoaded({windowTypes: res.data});
            })
        );

    @Effect()
    loadWindowTypesPage$ = this.actions$
        .pipe(
            ofType<WindowTypesPageRequested>(WindowTypeActionTypes.WindowTypesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findWindowTypes(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new WindowTypesPageLoaded({
                    windowTypes: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeDeleted>(WindowTypeActionTypes.WindowTypeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteWindowType(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeUpdated>(WindowTypeActionTypes.WindowTypeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateWindowType(payload.windowType);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeOnServerCreated>(WindowTypeActionTypes.WindowTypeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createWindowType(payload.windowType).pipe(
                    tap(res => {
                        this.store.dispatch(new WindowTypeCreated({windowType: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushWindowTypeTrash$ = this.actions$
        .pipe(
            ofType<WindowTypeTrashFlushed>(WindowTypeActionTypes.WindowTypeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeOnServerRestored>(WindowTypeActionTypes.WindowTypeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new WindowTypeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeOnServerAdminRestored>(WindowTypeActionTypes.WindowTypeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new WindowTypeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeDeletedFromTrash>(WindowTypeActionTypes.WindowTypeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashWindowType$ = this.actions$
        .pipe(
            ofType<WindowTypeDeletedFromAdminTrash>(WindowTypeActionTypes.WindowTypeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: WindowTypesService, private store: Store<AppState>) {
    }
}
