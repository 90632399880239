export class TopPropertyTypeModel {
    id: number;
    title: string;

    relation_cnt: number;
    is_hidden: boolean;
    is_system_entry: boolean;

    user_deleted_by: string;
    user_deleted: Date;

    deleted_by: string;
    deleted_at: Date;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.title = null;

        this.relation_cnt = 0;
        this.is_hidden = false;
        this.is_system_entry = false;

        this.user_deleted_by = null;
        this.user_deleted = null;

        this.deleted_by = null;
        this.deleted_at = null;

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}

export enum PropertyTypes {
    Custom = -1,
    Residential = 1,
    Commercial = 2,
    Land = 3,
    Industrial = 4
}