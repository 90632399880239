<div class="card card-custom">
    <div class="card-body">
        <kt-assumptions-table [readonly]="readonly" [targetProperty]="targetProperty"></kt-assumptions-table>
        <kt-vpga10-table [readonly]="readonly" [targetProperty]="targetProperty" [toe]="toe"></kt-vpga10-table>
        <kt-source-required-external-ref-table
            [readonly]="readonly"
            [targetProperty]="targetProperty"
            [toe]="toe"
        >
        </kt-source-required-external-ref-table>
    </div>
</div>