<div class="card card-custom">
    <div class="form">
        <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
        <div [hidden]="loading" class="card-body py-0 px-0">
            <button class="btn btn-xs" color="warn" (click)="onNoClick()" cdkFocusInitial aria-label="Close"
                style="background-color: orange; color: white;">
                <span aria-hidden="true">&times;</span>
            </button>
            <mat-dialog-content class="px-0">
                <div [ngClass]="loading ? 'loading' : 'loaded'">
                    <div *ngIf="obj.type != 'pdf'" class="container portrait px-0">
                        <img (error)="imgOnError($event)" (load)="onImageLoad($event)" [src]="path"/>
                        <div class="image-caption">
                            <div>
                                {{(img.type ? img.type : '') + (img.orderNum ? ' (' + img.orderNum + ') - ' : '')}} 
                                <span>{{img.name + (img.description ? (' - ' + img.description) : '')}}</span>
                            </div>                
                            <!-- <div class="image-action">
                                <ng-container *ngTemplateOutlet="actionTemplate ? actionTemplate : defaultAction;context:{image: img}"></ng-container>
                            </div> -->
                        </div>
                    </div>
                    <pdf-viewer *ngIf="obj.type == 'pdf'" [src]="path"
                        [original-size]="true"
                        [fit-to-page]="true"
                        [show-all]="true"
                        style="display: block;"
                        [render-text]="false"
                        (on-progress)="pdfLoadingProgress($event)"
                        (error)="onError($event)"
                    >
                    </pdf-viewer>
                </div>
                <div *ngIf="hasError">
                    Error occured
                </div>
            </mat-dialog-content>
        </div>
    </div>
</div>
