import {BaseModel} from '../../_base/crud';

export class TpTaskModel extends BaseModel {
    id: number;

    toe_id: number;
    tp_id: number;
    tp_name: string;
    task_id: number;
    task_name: string;
    second_id: number;
    order_num: number;
    details: string; // atrribute
    details_name: string; // actual field for filter and search by name
    point: number;
    max_point: number;
    is_manual: boolean;

    date: string;
    user_id: number;
    leadValuer: any[];
    user_name: string;

    user_deleted: string;
    created_at: string;
    deleted_at: string;
    updated_at: string;
    completed_at: string;
    assignee?: any


    clear() {
        this.id = undefined;

        this.toe_id = undefined;
        this.tp_id = undefined;
        this.tp_name = '';
        this.task_id = undefined;
        this.task_name = '';
        this.second_id = undefined;
        this.details = '';
        this.details_name = '';
        this.point = 0;
        this.max_point = 0;
        this.date = '';
        this.user_id = undefined;
        this.leadValuer = [];
        this.user_deleted = '';
        this.created_at = '';
        this.deleted_at = '';
        this.updated_at = '';
    }
}

export enum TpTaskOrder {
    DocumentUpload = 0,
    Investigation = 1,
    DueDiligence = 2,
    Valuation = 3,
    Reporting = 4,
    SignedDocDraft = 6,
    DeliveryDraft = 7,
    SignedDocVal = 8,
    DeliveryVal = 9,
    AuditTrail = 5,
}
