import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustomCriterionModel } from "../_models/valuation-process-criterion.model";
import { ValuationProcessCustomCriterionState } from "../_reducers/valuation-custom-criterion.reducer";
import * as _ from 'lodash'

const selectValuationProcessCustomCriterionsState = createFeatureSelector<ValuationProcessCustomCriterionState>('valuation-custom-criterions')
export const selectAllValuationProcessCustomCriterions = createSelector(
    selectValuationProcessCustomCriterionsState,
    state => {
        const criterions: CustomCriterionModel[] = []
        _.each(state.entities, (entity) => {
            criterions.push(entity)
        })
        return criterions
    }
)