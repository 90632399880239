export const config = {
    valuation: [
        'assignment',
        'audit_trail',
        'toe'
    ],
    comparables: [
        'apartment',
        'office',
        'considerations'
    ],
    user_management: [
        'agency',
        'user',
        'client',
    ],
    localisation: [
        'country',
        'city',
        'currency',
        'landmark',
        'building'
    ],
    resource: [
        'market_research',
        'appendix',
        'appendix_category'
    ],
    support: [
        'training_doc'
    ],
    auth: 'auth',
    constant: 'constant',
    general: 'general',
    image_viewer: 'image_viewer',
    contact: 'contact',
    lt: 'lt',
    menu: 'menu',
    mytask: 'mytask',
    tooltip: 'tooltip',
    translator: 'translator',
    linked_tables: {
        toes_and_audit_trails: [
            'valuation_standards',          'base_of_value',
            'purpose_of_valuations',        'approaches_to_value',
            'methods_to_value',             'premises_of_value',
            'default_assumption',           'special_assumption',
            'departure',                    'report_delivery_method',
            'extent_of_investigation',      'source_external_reference',
            'interaction_types'
        ],
        measurements: [
            'measurement_methodology',      'purpose_measurement',
            'standard_measurement',         'system_measurement',
            'unit_area_measurement',        'ipms_standard'
        ],
        property: [
            'asset_class_type',             'property_type',
            'apartment_type',               'office_layout',
            'facility',                     'handover_standard',
            'grade',                        'state_of_repair',
            'type_of_registration',         'type_of_title',
            'external_area',                'floor_type',
            'window_type',                  'external_wall',
            'foundation_type',              'tenure',
            'task_effort',                  'building_type',
            'ipms_component',               'top_property_type',
            'sub-type-category',            'sub-category'
        ],
        comparables_and_landmark: [
            'landmark_type',                'source_credibility',
            'source_information',           'source_type'
        ],
        valutions: [
            'residential-adjustments',
            'office-adjustments'
        ]
    }
};
