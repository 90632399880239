import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { TpTaskService } from 'src/app/core/asset_class';
import { TypesUtilsService } from 'src/app/core/_base/crud';

@Component({
  selector: 'kt-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit, OnDestroy {
  @Input() taskId: number;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() type: string;

  deliveryTask$: Observable<{id: number, delivered: boolean, delivered_at: Date}>
  form: UntypedFormGroup;
  mode: 'new' | 'edit'
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tpTaskService: TpTaskService,
    public typesUtilsService: TypesUtilsService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      delivered: [false],
      delivered_at: [null]
    });
    if (this.disabled) {
      this.form.controls.delivered.disable();
      this.form.controls.delivered_at.disable();
    }
    this.form.controls.delivered.valueChanges.pipe(
      startWith(this.form.controls.delivered.value),
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      if (val) {
        this.form.controls.delivered_at.setValidators([Validators.required]);
      } else {
        this.form.controls.delivered_at.clearValidators();
      }
      this.form.controls.delivered_at.updateValueAndValidity();
    })
    this.deliveryTask$ = this.tpTaskService
      .getDeliveryTask(this.taskId)
      .pipe(
        takeUntil(this._onDestroy$),
        map(task => {
          if (task == null) {
            return {
              id: undefined,
              delivered: false,
              delivered_at: null
            }
          }
          return {
            id: task.id,
            delivered: task.is_delivered,
            delivered_at: task.date
          }
        }),
        tap(task => {
          this.mode = task.id ? 'edit' : 'new';
          this.form.patchValue(task);
        })
      )
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearDate(control: AbstractControl) {
    control.setValue(null);
    control.updateValueAndValidity();
  }

  save(): {task: {task_id: number, is_delivered: boolean, date: string}, hasError: boolean} {
    let hasError = false;
    if (this.form.invalid) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(ctrlName => {
        if (controls[ctrlName].invalid) {
          controls[ctrlName].markAsTouched();
          hasError = true;
        }
      })
    }
    if (hasError) {
      return {
        task: null,
        hasError: true
      }
    }
    const date = this.form.controls.delivered_at.value ? new Date(this.form.controls.delivered_at.value) : null;
    return {
      task: {
        task_id: this.taskId,
        is_delivered: this.form.controls.delivered.value ? true : false,
        date: date ? this.typesUtilsService.getDateStringFromDate(date) : null
      },
      hasError: false
    }
  }
}
