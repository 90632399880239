import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel, QueryResultsModel } from "../../_base/crud";
import { AssetClassRetailShopModel } from "../_models/asset-class-retail-shop.model";
import { AssetClassRetailShopState } from "../_reducers/asset-class-retail-shop.reducer";

export const selectAssetClassRetailShopState = createFeatureSelector<AssetClassRetailShopState>('assetClassRetailShops');
export const selectAssetClassRetailShopPageLoading = createSelector(selectAssetClassRetailShopState, state => state.listLoading);
export const selectAssetClassRetailShopActionLoading = createSelector(selectAssetClassRetailShopState, state => state.actionLoading);
export const selectAssetClassRetailShopShowInitWaitingMessage = createSelector(selectAssetClassRetailShopState, state => state.showInitWaitingMessage);
export const selectAssetClassRetailShopLastQuery = createSelector(selectAssetClassRetailShopState, state => state.lastQuery);
export const selectTrashedAssetClassRetailShopCount = createSelector(selectAssetClassRetailShopState, state => state.totalTrashed);
export const selectAdminTrashedAssetClassRetailShopCount = createSelector(selectAssetClassRetailShopState, state => state.totalAdminTrashed);
export const selectAssetClassRetailShopById = (id: number) => createSelector(selectAssetClassRetailShopState, state => state.entities[id])

export const selectAssetClassRetailShopInStore = createSelector(
    selectAssetClassRetailShopState,
    state => {
        const items: AssetClassRetailShopModel[] = [];
        each(state.entities, el => {
            items.push(el);
        });

        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassRetailShopModel[] = [];
        switch (state.lastQuery.sortField) {
            default: 
                result = httpExtension.sortArray(
                    items,
                    state.lastQuery.sortField,
                    state.lastQuery.sortOrder
                )
        }
        return new QueryResultsModel(result, state.totalCount, '', state.totalTrashed);
    }
)

export const selectLastCreatedAssetClassRetailShopId = createSelector(
    selectAssetClassRetailShopState,
    state => {
        if (state.previouslyCreatedACId != state.lastCreatedAssetClassRetailShopId) {
            return state.lastCreatedAssetClassRetailShopId
        }
        return undefined
    }
)