import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { OtherJoineryModel, OtherJoineryOnServerCreated, OtherJoineryUpdated, selectOtherJoineryById, selectOtherJoinerysActionLoading, selectLastCreatedOtherJoineryId } from 'src/app/core/linked-tables';
import {Location} from '@angular/common';
import { SubheaderService } from 'src/app/core/_base/layout';
import { AppState } from 'src/app/core/reducers';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import {combineLatest} from 'rxjs'

@Component({
  selector: 'kt-other-joinery-edit',
  templateUrl: './other-joinery-edit.component.html',
  styleUrls: ['./other-joinery-edit.component.scss']
})
export class OtherJoineryEditComponent implements OnInit {
  model: OtherJoineryModel;
  oldModel: OtherJoineryModel;

  form: UntypedFormGroup;
  hasFormErrors = false;

  loading$: Observable<boolean>;
  viewLoading = false;
  loadingAfterSubmit = false;
  mode = 'NEW';
  readonly = false
  // Private properties
  private componentSubscriptions: Subscription;
  private subscriptions: Subscription[] = [];
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private location: Location,
              public subheaderService: SubheaderService,
              private store: Store<AppState>,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
        this.loading$ = this.store.pipe(select(selectOtherJoinerysActionLoading));
        const routeSubscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.data
        ]).subscribe(([params, data]) => {
            const id = params.id;
            this.readonly = data.readonly
            if (id && id > 0) {
                this.store.pipe(select(selectOtherJoineryById(id))).subscribe(res => {
                    if (res) {
                        this.oldModel = Object.assign({}, res);
                        this.model = Object.assign({}, this.oldModel);
                        this.initOtherJoinery();
                    }
                });
            } else {
                this.model = new OtherJoineryModel();
                this.model.clear();
                this.oldModel = Object.assign({}, this.model);
                this.initOtherJoinery();
            }
        });
        this.subscriptions.push(routeSubscription);

  }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    initOtherJoinery() {
      this.createForm();
    }

    createForm() {
      this.form = this.fb.group({
          name: [this.model.name, Validators.required],
      });
    }

    getComponentTitle(): string {
        if (this.model && this.model.id > 0) {
            this.mode = 'EDIT';
            return this.translate.instant('OTHER_JOINERY.FORM.TITLE.EDIT', {name: this.model.name});
        }

        return this.translate.instant('OTHER_JOINERY.FORM.TITLE.NEW');
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.form.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    reset() {
        this.model = Object.assign({}, this.oldModel);
        this.createForm();
        this.hasFormErrors = false;
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
    }

    onSubmit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        if (this.model.id > 0) {
            this.update(this.model);
        } else {
            this.create(this.model);
        }
    }

    update(_model: OtherJoineryModel) {
        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        const updateOtherJoinery: Update<OtherJoineryModel> = {
            id: _model.id,
            changes: _model
        };
        this.store.dispatch(new OtherJoineryUpdated({
            partialItem: updateOtherJoinery,
            item: _model
        }));
        this.oldModel = _model;

        this.navigateToParent();

    }

    /**
     * Create model
     *
     * @param _model: OtherJoineryModel
     */
    create(_model: OtherJoineryModel) {
        this.store.dispatch(new OtherJoineryOnServerCreated({item: _model}));
        this.oldModel = _model;
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedOtherJoineryId),
            delay(1000), // Remove this line
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.navigateToParent();
        });
    }

    navigateToParent() {
        let url = '../';
        if (this.model.id > 0) {
            url = '../../';
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    isFormValid() {
        return (this.model && this.model.name && this.model.name.length > 0);
    }

    canDeactivate() {
        if (this.discard()) {
            if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
                return true;
            } else {
                // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
                const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
                const currentUrl = currentUrlTree.toString();
                this.location.go(currentUrl);
                // ---------work around end-----------------
                return false;
            }
        }
        return true;
    }

    discard() {
        if (this.model && this.oldModel) {
            return this.model.name != this.oldModel.name;
        }
        return false;
    }
}
