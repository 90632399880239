import { Component, ElementRef, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColorPickerModalComponent } from 'src/app/views/pages/shared_components/color-picker-modal/color-picker-modal.component';
import * as _ from 'lodash';
import { ReportSchemeService } from '../report-scheme.service';
import { ReportFont, ReportScheme } from 'src/app/core/admin/_models/report-scheme.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'kt-report-designs-table',
  templateUrl: './report-designs-table.component.html',
  styleUrls: ['./report-designs-table.component.scss'],
  providers: [ReportSchemeService]
})
export class ReportDesignsTableComponent implements OnInit, OnDestroy {
  @Input() schemes: ReportScheme[];
  @Input() readonly: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns = ['report', 'primary_color', 'footer_color', 'font', 'actions'];

  private _schemes$: BehaviorSubject<ReportScheme[]> = new BehaviorSubject([]);
  private _onDestroy$: Subject<void> = new Subject();
  fonts: ReportFont[] = [];

  constructor(
    private dialog: MatDialog,
    private reportSchemeService: ReportSchemeService
  ) { }

  ngOnInit(): void {
    this.fonts = this.reportSchemeService.getFonts();
    const schemes = _.cloneDeep(this.schemes.length > 0 ? this.schemes : this.reportSchemeService.resetAll());

    this._schemes$.next(
      this._orderSchemes(schemes)
    )
    this._schemes$.pipe(takeUntil(this._onDestroy$)).subscribe(schemes => {
      this.dataSource.data = schemes;
    })
  }

  private _orderSchemes(schemes: ReportScheme[]) {
    schemes.sort((a, b) => a.type - b.type);
    return schemes;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getData(): ReportScheme[] {
    const schemes = this._schemes$.value;
    return schemes;
  }

  openColorPicker(event, item: ReportScheme, i: number, type: 'primary' | 'footer') {
    if (this.readonly) {
      return;
    }
    const target = new ElementRef(event.currentTarget);
    const dialogRef = this.dialog.open(ColorPickerModalComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        trigger: target,
        color: item.primaryColor
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const scheme = {...item};
      if (type == 'primary') {
        scheme.primaryColor = res.color;
      } else {
        scheme.footerColor = res.color;
      }
      const schemes = this._schemes$.value;
      schemes[i] = scheme;
      this._schemes$.next(this._orderSchemes(schemes));
    })
  }

  onSelectionChange(event: MatSelectChange, item: ReportScheme, i: number) {
    const schemes = this._schemes$.value;
    schemes[i].fontId = event.value;
    this._schemes$.next(this._orderSchemes(schemes));
  }

  reset(item: ReportScheme, i: number) {
    const scheme = this.reportSchemeService.reset(item.type);
    const schemes = this._schemes$.value;
    schemes[i] = {
      ...scheme,
      id: item.id,
    };
    this._schemes$.next(this._orderSchemes(schemes));
  }

  resetAll() {
    const _schemes = this._schemes$.value;
    const schemes = this.reportSchemeService.resetAll().map(scheme => {
      const _scheme = _schemes.find(s => s.type == scheme.type);
      return {
        ...scheme,
        id: _scheme ? _scheme.id : undefined
      }
    });
    this._schemes$.next(this._orderSchemes(schemes));
  }
}
