<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet-head--lg'" [viewLoading$]="dataSource.loading$"
                       [tooltipTitle]="'TOOLTIP.APPENDIX.LIST.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.APPENDIX.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet-head-title">
                <span>
                    {{ 'APPENDIX.LIST.TITLE' | translate:{
                        name: category ? category.name : ''
                    } }}
                </span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <a [routerLink]="['../../']"
               href="javascript:;"
               class="btn mr-2"
               mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                   back_page: ('APPENDIX_CATEGORY.LIST.TITLE' | translate)
               }">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">
                    {{ 'GENERAL.BUTTON.BACK' | translate }}
                </span>
            </a>

            <button (click)="addAppendix()"
                    mat-raised-button
                    [matTooltip]="'APPENDIX.LIST.BUTTON.NEW.TOOLTIP' | translate"
                    color="primary"
                    class="mr-2"
                    type="button">
                <span>{{ 'APPENDIX.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>

            <button *ngIf="trashCnt != 0" (click)="trash()" mat-raised-button
                    [matTooltip]="'APPENDIX.LIST.BUTTON.TRASHED.TOOLTIP' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button *ngIf="adminTrashCnt != 0" (click)="adminTrash()" mat-raised-button
                    [matTooltip]="'APPENDIX.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate" type="button" class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS -->
        <div class="kt-form">
            <!-- start::FILTERS -->
            <div class="kt-form-filtration">
                <div class="row align-items-center active">
                    <mat-form-field class="col-md-2 mat-form-field-fluid">
                        <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">
                    </mat-form-field>
                    <div class="col-md-1">
                        <button (click)="resetFilter()" mat-raised-button
                                [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button" style="background-color: #c4c5d6 !important;">
                            <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->
        </div>
        <!-- end::FILTERS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="name"
                       matSortDirection="asc"
                       matSortDisableClear>

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell *matCellDef="let appendix">{{appendix.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'APPENDIX.LIST.DATATABLE.NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let appendix">{{appendix.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                        Description
                    </mat-header-cell>
                    <mat-cell *matCellDef="let appendix">{{appendix.description}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APPENDIX.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let appendix">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <!-- <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: appendix.createdBy ? appendix.createdBy : 'Unknown',
                                date: (appendix.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: appendix.lastUpdatedBy ? appendix.lastUpdatedBy: 'Unknown',
                                date: (appendix.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </div> -->
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                username: appendix.createdBy ? appendix.createdBy : 'Unknown',
                                date: (appendix.created_at | date: 'dd MMM yyyy')
                                }">
                            </div>
                            <div [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: appendix.lastUpdatedBy ? appendix.lastUpdatedBy: 'Unknown',
                                date: (appendix.updated_at | date: 'dd MMM yyyy')
                                }">
                            </div>
                        </ng-template>
                        <button mat-icon-button
                                color="primary"

                                container="body"
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                                [ngbPopover]="popContent"
                                [popoverTitle]="popTitle"
                                *ngIf="currentUser"
                        >
                            <mat-icon>info</mat-icon>
                        </button>

                        <button mat-icon-button color="primary"
                                [matTooltip]="'APPENDIX.LIST.BUTTON.EDIT.TOOLTIP' | translate" (click)="editAppendix(appendix)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                        <button mat-icon-button color="warn"
                                [matTooltip]="'APPENDIX.LIST.BUTTON.DELETE.TOOLTIP' | translate" type="button"
                                (click)="deleteAppendix(appendix)">
                            <mat-icon>delete</mat-icon>
                        </button>&nbsp;
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index" [ngClass]="{gray: index%2}">
                </mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}</div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
                           [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>