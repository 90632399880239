import {BaseModel} from '../../_base/crud';

export class ToeReport extends BaseModel {
    id: number;
    toe_id: number;
    url: string;
    status: number;
    toe_status: number;
    file_size: string;
    file_name: string;
    user_id: number;

    sent_to: number;
    sent_by: number;
    sent_by_name: string;
    sent_at: string;
    delivery_detail_id: number;
    delivery_detail_name: string;
    sent_descr: string;
    report_desc: string;

    created_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.toe_id = undefined;
        this.url = '';
        this.status = 0;
        this.toe_status = 0;
        this.file_size = '';
        this.user_id = undefined;

        this.sent_to = 0;
        this.sent_by = 0;
        this.sent_by_name = '';
        this.sent_at = '';
        this.delivery_detail_id = undefined;
        this.delivery_detail_name = '';
        this.sent_descr = '';
        this.report_desc = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}
