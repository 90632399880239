// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { AuditTrailParticipantsState } from '../_reducers/audit-trail-participant.reducers';
import * as fromParticipants from '../_reducers/audit-trail-participant.reducers';

export const selectAuditTrailParticipantsState = createFeatureSelector<AuditTrailParticipantsState>('auditTrailParticipants');

export const selectAuditTrailParticipantById = (auditTrailId: number) => createSelector(
    selectAuditTrailParticipantsState,
    auditTrailsState => auditTrailsState.entities[auditTrailId]
);

export const selectAllAuditTrailParticipants = createSelector(
    selectAuditTrailParticipantsState,
    fromParticipants.selectAll
);

export const selectAuditTrailParticipantsPageLoading = createSelector(
    selectAuditTrailParticipantsState,
    auditTrailsState => auditTrailsState.listLoading
);

export const selectAuditTrailParticipantsActionLoading = createSelector(
    selectAuditTrailParticipantsState,
    auditTrailsState => auditTrailsState.actionLoading
);

export const selectLastCreatedAuditTrailParticipantId = createSelector(
    selectAuditTrailParticipantsState,
    auditTrailsState => auditTrailsState.lastCreatedAuditTrailParticipantId
);

export const selectAuditTrailParticipantsShowInitWaitingMessage = createSelector(
    selectAuditTrailParticipantsState,
    auditTrailsState => auditTrailsState.showInitWaitingMessage
);
export const selectAuditTrailParticipants = createSelector(
    selectAllAuditTrailParticipants,
    (auditTrailParticipants) => auditTrailParticipants
);
