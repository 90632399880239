import { Action } from "@ngrx/store";
import { ReportingProcessData } from "../_models/reporting_process.model";

export enum ReportingProcessActionTypes {
    ReportingRequested = '[Reporting Process Page] Reporting Process data requested',
    ReportingLoaded = '[Reporting Effects] Reporting Process data loaded',

    ReportingStored = '[Reporting Process Page] Reporting Process data stored',
    ReportingUpdated = '[Reporting Process Page] Reporting Process data update'
}

export class ReportingRequested implements Action {
    readonly type = ReportingProcessActionTypes.ReportingRequested;
    constructor(public payload: {id: number}) {}
}

export class ReportingLoaded implements Action {
    readonly type = ReportingProcessActionTypes.ReportingLoaded;
    constructor(public payload: {data: ReportingProcessData}) {}
}

export class ReportingStored implements Action {
    readonly type = ReportingProcessActionTypes.ReportingStored;
    constructor(public payload: {tpId: number, data: ReportingProcessData}) {}
}

export class ReportingUpdated implements Action {
    readonly type = ReportingProcessActionTypes.ReportingUpdated;
    constructor(public payload: {data: ReportingProcessData}) {}
}

export type ReportingActions = ReportingRequested
    | ReportingLoaded
    | ReportingStored
    | ReportingUpdated;