<form class="form" [formGroup]="formGroup" *ngIf="formGroup" [ngClass]="{'readonly': readonly}">
    <div class="form-group">
        <div class="row">
            <div class="col-4">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput type="text" placeholder="Template Name" formControlName="name">
                    <mat-error>Template Name <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput type="text" placeholder="Template Description" formControlName="description"></textarea>
                    <mat-error>Template Description <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
<kt-toe-members 
    [readonly]="readonly" 
    [members$]="members$" 
    [refId]="template.id"
    [isTemplate]="true"></kt-toe-members>
<div class="row">
    <div class="col-12">
        <mat-error *ngIf="atLeastOneValuerError" class="tag-custom-error">
            At least 1 Valuer <strong>required</strong>
        </mat-error>
    </div>
</div>