import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { takeUntil } from 'rxjs/operators';
import { ICurrencyExchange } from 'src/app/core/admin/_services/currency_exchange.service';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { AppState } from 'src/app/core/reducers';
import { AddComparable } from 'src/app/core/valuation';
import { SizeCriterionAddComparable } from 'src/app/core/valuation/_actions/size_criterion.actions';
import { AddComparableValuationAdjustment } from 'src/app/core/valuation/_actions/valuation-adjustment.actions';
import * as valuationActions from 'src/app/core/valuation/_actions/valuation.actions';
import { selectAllValComparables, selectValuationData } from 'src/app/core/valuation/_selectors/valuation-data.selector';
import { ApartmentModalComponent } from 'src/app/views/pages/comparable/apartments/modal/apartment-modal.component';
import { HouseModalComponent } from 'src/app/views/pages/comparable/house/modal/house-modal.component';
import { OfficeModalComponent } from 'src/app/views/pages/comparable/offices/modal/office-modal.component';
import { ParkingModalComponent } from 'src/app/views/pages/comparable/parkings/modal/parking-modal.component';
import { RetailBuildingModalComponent } from 'src/app/views/pages/comparable/retail-building/modal/retail-building-modal.component';
import { RetailsModalComponent } from 'src/app/views/pages/comparable/retails/modal/retails-modal.component';
import { WarehouseModalComponent } from 'src/app/views/pages/comparable/warehouse/modal/warehouse-modal.component';
import { ComparableTabMapComponent } from './comparable-tab-map/comparable-tab-map.component';

@Component({
  selector: 'kt-comparable-tab',
  templateUrl: './comparable-tab.component.html',
  styleUrls: ['./comparable-tab.component.scss']
})
export class ComparableTabComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  currencyExchangeChannel: BehaviorSubject<ICurrencyExchange>;
  @Input()
  allConvertedChannel: BehaviorSubject<boolean>;

  @ViewChild(ComparableTabMapComponent, {static: false})
  mapComponent: ComparableTabMapComponent;

  selectedComparables = [];  
  loaded$ = new BehaviorSubject<boolean>(false);
  valuation: ValuationModel;
  valuationSub: Subscription
  comparableSub: Subscription;
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loaded$.next(false);
    this.valuationSub = this.store.select(selectValuationData).subscribe(res => {
      if (res) {
        this.valuation = res.valuation;
        this.loaded$.next(true);
      }
    })
    this.comparableSub = this.store
      .pipe(select(selectAllValComparables))
      .subscribe((res) => {
        this.selectedComparables = res;
      });
  }

  ngOnDestroy(): void {
    this.valuationSub.unsubscribe();
    this.comparableSub.unsubscribe();
  }

  addComparable() {
    let dialogRef = null;
    switch (this.targetProperty.type_id) {
      case 1: {
        dialogRef = this.dialog.open(ApartmentModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
      case 17: {
        dialogRef = this.dialog.open(HouseModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
      case 3: {
        dialogRef = this.dialog.open(OfficeModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
      case 11: {
        dialogRef = this.dialog.open(RetailBuildingModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
      case 5: {
        dialogRef = this.dialog.open(RetailsModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
      case 2: {
        dialogRef = this.dialog.open(ParkingModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
      case 7: {
        dialogRef = this.dialog.open(WarehouseModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.id
          }
        })
        break;
      }
    }
    if (dialogRef != null) {
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return
        }
        this.store.dispatch(new AddComparable({ 
          comparable: res, 
          selectedTenure: res.tenures.length > 0 ? res.tenures[0].id : null, 
          targetProperty: this.targetProperty, 
          valuation: this.valuation
        }));
        this.store.dispatch(new SizeCriterionAddComparable({ 
          comparable: res
        }))
        this.store.dispatch(new AddComparableValuationAdjustment({ 
          com: res
        }))
        this.store.dispatch(new valuationActions.AddValuation({ 
          com: res
        }))


        if (this.mapComponent) {
          const selectedIds = this.selectedComparables ? this.selectedComparables.map(el => el.id) : []
          selectedIds.push(res.id)
          const comparablesData = [{com: res, selectedTenure: res.tenures.length > 0 ? res.tenures[0].id : null}]
          const tenures = comparablesData.map(c => ({comId: c.com.id, selectedTenure: c.selectedTenure}));
          this.mapComponent.overviewAction({ids: selectedIds, tenureInfo: tenures}, [res])
        }
      })
    }
  }

}
