// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {KeyCategoriesService} from '../_services/key-category.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allKeyCategoriesLoaded} from '../_selectors/key-category.selectors';
// Actions
import {
    AllKeyCategoriesLoaded,
    AllKeyCategoriesRequested,
    KeyCategoriesPageRequested,
    KeyCategoriesPageLoaded,
    KeyCategoryUpdated,
    KeyCategoriesPageToggleLoading,
    KeyCategoryDeleted,
    KeyCategoryOnServerCreated,
    KeyCategoryCreated,
    KeyCategoryAdminRestored,
    KeyCategoryDeletedFromAdminTrash,
    KeyCategoryDeletedFromTrash,
    KeyCategoryOnServerAdminRestored,
    KeyCategoryOnServerRestored,
    KeyCategoryRestored,
    KeyCategoryTrashFlushed,
    KeyCategoriesActionToggleLoading
} from '../_actions/key-category.actions';
import {KeyCategoryActionTypes} from '../_actions/key-category.actions';

@Injectable()
export class KeyCategoryEffects {
    showPageLoadingDispatcher = new KeyCategoriesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new KeyCategoriesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new KeyCategoriesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllKeyCategory$ = this.actions$
        .pipe(
            ofType<AllKeyCategoriesRequested>(KeyCategoryActionTypes.AllKeyCategoriesRequested),
            withLatestFrom(this.store.pipe(select(allKeyCategoriesLoaded))),
            filter(([action, isAllKeyCategoryLoaded]) => !isAllKeyCategoryLoaded),
            mergeMap(() => this.service.getAllKeyCategories()),
            map(res => {
                return new AllKeyCategoriesLoaded({keyCategories: res.data});
            })
        );

    @Effect()
    loadKeyCategoryPage$ = this.actions$
        .pipe(
            ofType<KeyCategoriesPageRequested>(KeyCategoryActionTypes.KeyCategoriesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findKeyCategories(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new KeyCategoriesPageLoaded({
                    keyCategories: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryDeleted>(KeyCategoryActionTypes.KeyCategoryDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteKeyCategory(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryUpdated>(KeyCategoryActionTypes.KeyCategoryUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateKeyCategory(payload.keyCategory);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryOnServerCreated>(KeyCategoryActionTypes.KeyCategoryOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createKeyCategory(payload.keyCategory).pipe(
                    tap(res => {
                        this.store.dispatch(new KeyCategoryCreated({keyCategory: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushKeyCategoryTrash$ = this.actions$
        .pipe(
            ofType<KeyCategoryTrashFlushed>(KeyCategoryActionTypes.KeyCategoryTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryOnServerRestored>(KeyCategoryActionTypes.KeyCategoryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new KeyCategoryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryOnServerAdminRestored>(KeyCategoryActionTypes.KeyCategoryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new KeyCategoryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryDeletedFromTrash>(KeyCategoryActionTypes.KeyCategoryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashKeyCategory$ = this.actions$
        .pipe(
            ofType<KeyCategoryDeletedFromAdminTrash>(KeyCategoryActionTypes.KeyCategoryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: KeyCategoriesService, private store: Store<AppState>) {
    }
}
