import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { OutsideDoorModel } from "../_models/outside-door.model";

export enum OutsideDoorActionTypes {
    AllOutsideDoorsRequested = '[Linked Area] All OutsideDoor Requested',
    AllOutsideDoorsLoaded = '[OutsideDoor Effect] All OutsideDoor Loaded',

    OutsideDoorOnServerCreated = '[Add OutsideDoor Page] OutsideDoor On Server Created',
    OutsideDoorCreated = '[OutsideDoor Effect] OutsideDoor Created',
    OutsideDoorUpdated = '[OutsideDoor Effect] OutsideDoor Updated',
    OutsideDoorDeleted = '[OutsideDoor List Page] OutsideDoor Deleted',

    OutsideDoorOnServerRestored = '[OutsideDoor Trash] OutsideDoor On Server Restored',
    OutsideDoorRestored = '[OutsideDoor Trash] OutsideDoor Restored',

    OutsideDoorOnServerAdminRestored = '[OutsideDoor Admin Trash] OutsideDoor On Server Restored',
    OutsideDoorAdminRestored = '[OutsideDoor Admin Trash] OutsideDoor Restored',

    OutsideDoorDeletedFromTrash = '[OutsideDoor Trash] OutsideDoor deleted',
    OutsideDoorDeletedFromAdminTrash = '[OutsideDoor Admin Trash] OutsideDoor deleted',

    OutsideDoorTrash = 'OutsideDoor Trashed',
    OutsideDoorTrashFlushed = 'OutsideDoor Trash Flushed',

    OutsideDoorsPageRequested = '[OutsideDoor List Page] OutsideDoor Page Requested',
    OutsideDoorsPageLoaded = '[OutsideDoor API] OutsideDoor Page Loaded',
    OutsideDoorsPageCancelled = '[OutsideDoor API] OutsideDoor Page Cancelled',

    OutsideDoorsPageToggleLoading = '[OutsideDoor page] OutsideDoor Page Toggle Loading',
    OutsideDoorActionToggleLoading = '[OutsideDoor] OutsideDoor Action Toggle Loading'
}

export class OutsideDoorOnServerCreated implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorOnServerCreated;

    constructor(public payload: { item: OutsideDoorModel }) {
    }
}

export class OutsideDoorCreated implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorCreated;

    constructor(public payload: { item: OutsideDoorModel }) {
    }
}

export class OutsideDoorUpdated implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorUpdated;

    constructor(public payload: {
        partialItem: Update<OutsideDoorModel>,
        item: OutsideDoorModel
    }) {
    }
}

export class OutsideDoorDeleted implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class OutsideDoorsPageRequested implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class OutsideDoorsPageLoaded implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorsPageLoaded;

    constructor(public payload: { items: OutsideDoorModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class OutsideDoorsPageCancelled implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorsPageCancelled;
}

export class AllOutsideDoorsRequested implements Action {
    readonly type = OutsideDoorActionTypes.AllOutsideDoorsRequested;
}

export class AllOutsideDoorsLoaded implements Action {
    readonly type = OutsideDoorActionTypes.AllOutsideDoorsLoaded;

    constructor(public payload: { items: OutsideDoorModel[] }) {
    }
}

export class OutsideDoorsPageToggleLoading implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class OutsideDoorActionToggleLoading implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class OutsideDoorDeletedFromAdminTrash implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OutsideDoorDeletedFromTrash implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class OutsideDoorOnServerRestored implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OutsideDoorRestored implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorRestored;

    constructor(public payload: { item: OutsideDoorModel }) {
    }
}

export class OutsideDoorOnServerAdminRestored implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class OutsideDoorAdminRestored implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorAdminRestored;

    constructor(public payload: { item: OutsideDoorModel }) {
    }
}

export class OutsideDoorTrashFlushed implements Action {
    readonly type = OutsideDoorActionTypes.OutsideDoorTrashFlushed;

    constructor() {
    }
}


export type OutsideDoorActions = OutsideDoorCreated
    | OutsideDoorUpdated
    | OutsideDoorDeleted
    | OutsideDoorsPageRequested
    | OutsideDoorsPageLoaded
    | OutsideDoorsPageCancelled
    | AllOutsideDoorsLoaded
    | AllOutsideDoorsRequested
    | OutsideDoorOnServerCreated
    | OutsideDoorDeletedFromAdminTrash
    | OutsideDoorDeletedFromTrash
    | OutsideDoorOnServerRestored
    | OutsideDoorRestored
    | OutsideDoorOnServerAdminRestored
    | OutsideDoorAdminRestored
    | OutsideDoorTrashFlushed
    | OutsideDoorsPageToggleLoading
    | OutsideDoorActionToggleLoading;