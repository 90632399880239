// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {PurposeOfValuationActions, PurposeOfValuationActionTypes} from '../_actions/purpose-of-valuation.actions';
// Models
import {PurposeOfValuation} from '../_models/purpose-of-valuation.model';
import {QueryParamsModel} from '../../_base/crud';

export interface PurposeOfValuationsState extends EntityState<PurposeOfValuation> {
    isAllPurposeOfValuationsLoaded: boolean;
    queryRowsCount: number;
    queryResult: PurposeOfValuation[];
    lastCreatedPurposeOfValuationId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PurposeOfValuation> = createEntityAdapter<PurposeOfValuation>();

export const initialPurposeOfValuationsState: PurposeOfValuationsState = adapter.getInitialState({
    isAllPurposeOfValuationsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedPurposeOfValuationId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function purposeOfValuationsReducer(state = initialPurposeOfValuationsState, action: PurposeOfValuationActions): PurposeOfValuationsState {
    switch (action.type) {
        case PurposeOfValuationActionTypes.PurposeOfValuationsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPurposeOfValuationId: undefined
            };
        case PurposeOfValuationActionTypes.PurposeOfValuationsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case PurposeOfValuationActionTypes.PurposeOfValuationOnServerCreated:
            return {
                ...state
            };
        case PurposeOfValuationActionTypes.PurposeOfValuationCreated:
            return adapter.addOne(action.payload.purposeOfValuation, {
                ...state, lastCreatedPurposeOfValuationId: action.payload.purposeOfValuation.id
            });
        case PurposeOfValuationActionTypes.PurposeOfValuationUpdated:
            return adapter.updateOne(action.payload.partialpurposeOfValuation, state);

        case PurposeOfValuationActionTypes.AllPurposeOfValuationsLoaded:
            return adapter.addAll(action.payload.purposeOfValuations, {
                ...state, isAllPurposeOfValuationsLoaded: true
            });
        case PurposeOfValuationActionTypes.PurposeOfValuationsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case PurposeOfValuationActionTypes.PurposeOfValuationsPageLoaded:
            return adapter.addMany(action.payload.purposeOfValuations, {
                ...initialPurposeOfValuationsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case PurposeOfValuationActionTypes.PurposeOfValuationDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case PurposeOfValuationActionTypes.PurposeOfValuationRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case PurposeOfValuationActionTypes.PurposeOfValuationDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case PurposeOfValuationActionTypes.PurposeOfValuationAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case PurposeOfValuationActionTypes.PurposeOfValuationDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case PurposeOfValuationActionTypes.PurposeOfValuationTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
