import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassParkingActions, AssetClassParkingActionTypes } from "../_actions/asset-class-parking.actions";
import { AssetClassParkingModel } from "../_models/asset-class-parking.model";

export interface AssetClassParkingState extends EntityState<AssetClassParkingModel> {
    actionLoading: boolean;
    listLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastQuery: QueryParamsModel;
    previouslyCreatedACId: number;
    lastCreatedAssetClassParkingId: number;
    showInitWaitingMessage: boolean;
}
export const adapter: EntityAdapter<AssetClassParkingModel> = createEntityAdapter<AssetClassParkingModel>();
export const initialAssetClassParkingState: AssetClassParkingState = adapter.getInitialState({
    actionLoading: false,
    listLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    previouslyCreatedACId: undefined,
    lastCreatedAssetClassParkingId: undefined,
    showInitWaitingMessage: true
});

export function assetClassParkingReducer(
    state = initialAssetClassParkingState,
    action: AssetClassParkingActions
): AssetClassParkingState {
    switch (action.type) {
        case AssetClassParkingActionTypes.UpdatePreviouslyCreatedACId: {
            return {
                ...state,
                previouslyCreatedACId: state.lastCreatedAssetClassParkingId
            }
        }
        case AssetClassParkingActionTypes.AssetClassParkingPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassParkingId: undefined
            }
        }
        case AssetClassParkingActionTypes.AssetClassParkingActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            }
        }
        case AssetClassParkingActionTypes.AssetClassParkingOnServerCreated: {
            return state;
        }
        case AssetClassParkingActionTypes.AssetClassParkingCreated: {
            return adapter.addOne(
                action.payload.AssetClassParking,
                {...state, previouslyCreatedACId: state.lastCreatedAssetClassParkingId, lastCreatedAssetClassParkingId: action.payload.AssetClassParking.id}
            )
        }
        case AssetClassParkingActionTypes.AssetClassParkingUpdated: {
            return adapter.addOne(
                action.payload.AssetClassParking,
                {...state, lastCreatedAssetClassParkingId: action.payload.AssetClassParking.id}
            )
        }
        case AssetClassParkingActionTypes.AssetClassParkingPageLoaded: {
            return adapter.addMany(action.payload.AssetClassParkings, {
                ...initialAssetClassParkingState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            })
        }
        case AssetClassParkingActionTypes.AssetClassParkingDeleted: {
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            })
        }
        case AssetClassParkingActionTypes.AssetClassParkingDeletedFromTrash: {
            return {
                ...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            }
        }
        case AssetClassParkingActionTypes.AssetClassParkingDeletedFromAdminTrash:
            return {...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AssetClassParkingActionTypes.AssetClassParkingOnServerRestored: {
            return state
        }
        case AssetClassParkingActionTypes.AssetClassParkingRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                lastCreatedAssetClassParkingId: action.payload.ac.id,
                totalTrashed: state.totalTrashed - 1
            })
        }
        case AssetClassParkingActionTypes.AssetClassParkingOnServerAdminRestored: {
            return state
        }
        case AssetClassParkingActionTypes.AssetClassParkingAdminRestored: {
            return adapter.addOne(action.payload.ac, {
                ...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            })
        }
        case AssetClassParkingActionTypes.AssetClassParkingTrashFlushed: {
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            }
        }
        default: {
            return state;
        }
    }
}