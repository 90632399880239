import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationVpga10Matter } from "../_models/vpga10-matter.model";
import * as valuationVpga10MattersActions from '../_actions/vpga10-matters.actions';

export interface ValuationVpga10MattersState extends EntityState<ValuationVpga10Matter> {
    lastCreatedId: number
};
export const adapter: EntityAdapter<ValuationVpga10Matter> = createEntityAdapter({
    selectId: (entity: ValuationVpga10Matter) => entity.front_id
});
export const initialValuationVpga10MattersState: ValuationVpga10MattersState = adapter.getInitialState({
    lastCreatedId: 0
})
export function valuationVpga10MattersReducer(
    state = initialValuationVpga10MattersState,
    action: valuationVpga10MattersActions.Actions
): ValuationVpga10MattersState {
    switch (action.type) {
        case valuationVpga10MattersActions.ActionTypes.DataLoaded: {
            return adapter.setAll(action.payload.data, {
                ...state,
                lastCreatedId: action.payload.id
            });
        }
        case valuationVpga10MattersActions.ActionTypes.VpgaMatterAdded: {
            return adapter.addOne(action.payload.data, {
                ...state, lastCreatedId: action.payload.id
            });
        }
        case valuationVpga10MattersActions.ActionTypes.VpgaMatterEdited: {
            return adapter.updateOne(action.payload.data, {...state});
        }
        case valuationVpga10MattersActions.ActionTypes.VpgaMatterRestored: {
            return adapter.updateOne(action.payload.data, {...state});
        }
        case valuationVpga10MattersActions.ActionTypes.ToeVpgaMatterRemoved: {
            return adapter.updateOne(action.payload.data, {...state});
        }
        case valuationVpga10MattersActions.ActionTypes.RemoveInputVpgaMatter: {
            return adapter.removeOne(action.payload.id, {...state});
        }
        default:
            return state;
    }
}

