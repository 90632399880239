import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllOfficeAdjustmentsRequested, AllResidentialAdjustmentsRequested, selectAllOfficeAdjustments, selectAllResidentialAdjustments } from 'src/app/core/linked-tables';
import { AllHouseAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/house-adjustment.actions';
import { AllLandAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/land-adjustment.actions';
import { AllParkingAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/parking-adjustment.actions';
import { AllRetailBuildingAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/retail-building-adjustment.actions';
import { AllRetailStoreAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/retail-store-adjustment.actions';
import { AllWarehouseAdjustmentsRequested } from 'src/app/core/linked-tables/_actions/warehouse-adjustment.actions';
import { selectAllHouseAdjustments } from 'src/app/core/linked-tables/_selectors/house-adjustment.selectors';
import { selectAllLandAdjustments } from 'src/app/core/linked-tables/_selectors/land-adjustment.selectors';
import { selectAllParkingAdjustments } from 'src/app/core/linked-tables/_selectors/parking-adjustment.selectors';
import { selectAllRetailBuildingAdjustments } from 'src/app/core/linked-tables/_selectors/retail-building-adjustment.selectors';
import { selectAllRetailStoreAdjustments } from 'src/app/core/linked-tables/_selectors/retail-store-adjustment.selectors';
import { selectAllWarehouseAdjustments } from 'src/app/core/linked-tables/_selectors/warehouse-adjustment.selectors';
import { AppState } from 'src/app/core/reducers';
import { IAdjustmentTemplate } from 'src/app/core/template';

@Component({
  selector: 'kt-adjustment-form',
  templateUrl: './adjustment-form.component.html',
  styleUrls: ['./adjustment-form.component.scss']
})
export class AdjustmentFormComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();

  formGroup: UntypedFormGroup;
  adjustments: {
    id: number,
    name: string,
    description: string,
    disabled: boolean
  }[] = [];
  adjustment: IAdjustmentTemplate;
  selectedAssetClassType: number;
  mode: string;
  selectedAdjustments: IAdjustmentTemplate[];

  constructor(
    private dialogRef: MatDialogRef<AdjustmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>
  ) { 
    this.adjustment = this.data.adjustment;
    this.selectedAssetClassType = this.data.selectedAssetClassType;
    this.mode = this.data.mode;
    this.selectedAdjustments = this.data.selected;
  }

  ngOnInit(): void {
    this._fetchData(this.selectedAssetClassType);
    this.formGroup = this.fb.group({
      adjustment_id: [this.adjustment.adjustment_id, Validators.required],
      adjustment_name: [this.adjustment.name],
      adjustment_description: [this.adjustment.description, Validators.required]
    });
    this.formGroup.get('adjustment_id').valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(
      val => {
        if (val !== 0) {
          const adjustment = this.adjustments.find(a => a.id == val);
          const desc = adjustment ? adjustment.description : '';
          this.formGroup.get('adjustment_description').setValue(desc);
          this.formGroup.get('adjustment_description').updateValueAndValidity();
          this.formGroup.get('adjustment_name').clearValidators();
          this.formGroup.get('adjustment_name').updateValueAndValidity();
        } else {
          this.formGroup.get('adjustment_name').setValidators(Validators.required);
          this.formGroup.get('adjustment_name').updateValueAndValidity();
        }
      }
    )
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _fetchData(type_id: number) {
    if (type_id == undefined || type_id == null) {
      return;
    }
    switch (type_id) {
      case 1:
        this.store.dispatch(new AllResidentialAdjustmentsRequested());
        this.store.select(selectAllResidentialAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 3:
        this.store.dispatch(new AllOfficeAdjustmentsRequested());
        this.store.select(selectAllOfficeAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 7: 
        this.store.dispatch(new AllWarehouseAdjustmentsRequested());
        this.store.select(selectAllWarehouseAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 17:
        this.store.dispatch(new AllHouseAdjustmentsRequested());
        this.store.select(selectAllHouseAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 2:
        this.store.dispatch(new AllParkingAdjustmentsRequested());
        this.store.select(selectAllParkingAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 5:
        this.store.dispatch(new AllRetailStoreAdjustmentsRequested());
        this.store.select(selectAllRetailStoreAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 11:
        this.store.dispatch(new AllRetailBuildingAdjustmentsRequested());
        this.store.select(selectAllRetailBuildingAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      case 13:
        this.store.dispatch(new AllLandAdjustmentsRequested());
        this.store.select(selectAllLandAdjustments).pipe(
          takeUntil(this._onDestroy$)
        ).subscribe(res => {
          this.adjustments = [];
          if (res) {
            this.adjustments = res.map(item => ({id: item.id, name: item.name, description: item.description, disabled: this._contain(this.selectedAdjustments, item)}));
          }
        });
        break;
      default:
        break;
    }
  }
  private _contain(arr: any[], item: any): boolean {
    const index = arr.findIndex(arrItem => arrItem.adjustment_id === item.id);
    return index > -1;
  }

  public onNoClick() {
    this.dialogRef.close();
  }

  public onSaveClick() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const controls = this.formGroup.controls;
    const adjustmentID = controls.adjustment_id.value;
    let adjustmentName = null;
    if (adjustmentID != 0) {
      const adjustment = this.adjustments.find(a => a.id == adjustmentID);
      adjustmentName = adjustment.name;
    } else {
      adjustmentName = controls.adjustment_name.value;
    }
    const adjustmentDesc = controls.adjustment_description.value;
    const adjustment: IAdjustmentTemplate = {
      id: this.adjustment.id,
      adjustment_id: adjustmentID,
      name: adjustmentName,
      description: adjustmentDesc 
    }
    this.dialogRef.close(adjustment)
  }

}
