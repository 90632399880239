// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {IvsStandardsService} from '../_services/ivs-standard.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allIvsStandardsLoaded} from '../_selectors/ivs-standard.selectors';
// Actions
import {
    AllIvsStandardsLoaded,
    AllIvsStandardsRequested,
    IvsStandardActionTypes,
    IvsStandardsPageRequested,
    IvsStandardsPageLoaded,
    IvsStandardUpdated,
    IvsStandardsPageToggleLoading,
    IvsStandardDeleted,
    IvsStandardOnServerCreated,
    IvsStandardCreated,
    IvsStandardAdminRestored,
    IvsStandardDeletedFromAdminTrash,
    IvsStandardDeletedFromTrash,
    IvsStandardOnServerAdminRestored,
    IvsStandardOnServerRestored,
    IvsStandardRestored,
    IvsStandardTrashFlushed,
    IvsStandardsActionToggleLoading
} from '../_actions/ivs-standard.actions';

@Injectable()
export class IvsStandardEffects {
    showPageLoadingDispatcher = new IvsStandardsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new IvsStandardsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new IvsStandardsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllIvsStandards$ = this.actions$
        .pipe(
            ofType<AllIvsStandardsRequested>(IvsStandardActionTypes.AllIvsStandardsRequested),
            withLatestFrom(this.store.pipe(select(allIvsStandardsLoaded))),
            filter(([action, isAllIvsStandardsLoaded]) => !isAllIvsStandardsLoaded),
            mergeMap(() => this.service.getAllIvsStandards()),
            map(res => {
                return new AllIvsStandardsLoaded({ivsStandards: res.data});
            })
        );

    @Effect()
    loadIvsStandardsPage$ = this.actions$
        .pipe(
            ofType<IvsStandardsPageRequested>(IvsStandardActionTypes.IvsStandardsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findIvsStandards(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new IvsStandardsPageLoaded({
                    ivsStandards: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardDeleted>(IvsStandardActionTypes.IvsStandardDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteIvsStandard(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardUpdated>(IvsStandardActionTypes.IvsStandardUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateIvsStandard(payload.ivsStandard);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardOnServerCreated>(IvsStandardActionTypes.IvsStandardOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createIvsStandard(payload.ivsStandard).pipe(
                    tap(res => {
                        this.store.dispatch(new IvsStandardCreated({ivsStandard: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushIvsStandardTrash$ = this.actions$
        .pipe(
            ofType<IvsStandardTrashFlushed>(IvsStandardActionTypes.IvsStandardTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardOnServerRestored>(IvsStandardActionTypes.IvsStandardOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IvsStandardRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardOnServerAdminRestored>(IvsStandardActionTypes.IvsStandardOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new IvsStandardAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardDeletedFromTrash>(IvsStandardActionTypes.IvsStandardDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashIvsStandard$ = this.actions$
        .pipe(
            ofType<IvsStandardDeletedFromAdminTrash>(IvsStandardActionTypes.IvsStandardDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    constructor(private actions$: Actions, private service: IvsStandardsService, private store: Store<AppState>) {
    }
}
