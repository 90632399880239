import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import { AppState } from "../../reducers";
import * as assumptionDepartureActions from '../_actions/assumption_departure.actions';
import * as assumptionDepartureSelectors from '../_selectors/assumption_departure.selectors';
import { ValuationAssumptionDeparture, Type, Status } from "../_models/valuation-assumption-departure.model";
import { Update } from "@ngrx/entity";
import { AssumptionDepartureService } from "../_services/assumption_departure.service";

@Injectable() 
export class ValuationAssumptionDepartureEffects {
    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private service: AssumptionDepartureService
    ) {}

    @Effect()
    loadData$ = this.actions$.pipe(
        ofType<assumptionDepartureActions.LoadData>(assumptionDepartureActions.ActionTypes.LoadData),
        map(({payload}) => {
            let def_id = 0;
            const defaultAssumptions: ValuationAssumptionDeparture[] = [];
            payload.default.forEach(a => { 
                const _new = Object.assign({}, a) as ValuationAssumptionDeparture;
                _new.front_id = def_id;
                def_id++;
                defaultAssumptions.push(_new);
            })

            let sp_id = 0;
            const specialAssumptions: ValuationAssumptionDeparture[] = [];
            payload.special.forEach(a => {
                const _new = Object.assign({}, a) as ValuationAssumptionDeparture;
                _new.front_id = sp_id;
                sp_id++;
                specialAssumptions.push(_new);
            })

            let dp_id = 0;
            const departures: ValuationAssumptionDeparture[] = [];
            payload.departure.forEach(a => {
                const _new = Object.assign({}, a) as ValuationAssumptionDeparture;
                _new.front_id = dp_id;
                dp_id++;
                departures.push(_new);
            })
            return new assumptionDepartureActions.DataLoaded({
                default: defaultAssumptions,
                def_id: def_id - 1,
                special: specialAssumptions,
                sp_id: sp_id - 1,
                departure: departures,
                dp_id: dp_id - 1
            });
        })
    )

    @Effect()
    add$ = this.actions$.pipe(
        ofType<assumptionDepartureActions.AddAssumptionOrDeparture>(assumptionDepartureActions.ActionTypes.AddAssumptionOrDeparture),
        mergeMap(({payload}) => {
            switch (payload.data.type) {
                case Type.DefaultAssumption: {
                    return combineLatest([
                        of(payload.data),
                        this.store$.pipe(select(assumptionDepartureSelectors.selecLastCreatedDefaultAssumptionId), take(1))
                    ]);
                }
                case Type.SpecialAssumption: {
                    return combineLatest([
                        of(payload.data),
                        this.store$.pipe(select(assumptionDepartureSelectors.selecLastCreatedSpecialAssumptionId), take(1))
                    ]);
                }
                case Type.Departure: {
                    return combineLatest([
                        of(payload.data),
                        this.store$.pipe(select(assumptionDepartureSelectors.selecLastCreatedDepartureId), take(1))
                    ])
                }
            }
        }),
        map(([data, id]) => {
            const _new = Object.assign({}, data) as ValuationAssumptionDeparture;
            _new.front_id = id + 1;
            return new assumptionDepartureActions.AssumptionOrDepartureAdded({
                data: _new,
                id: _new.front_id
            });
        })
    )

    @Effect()
    edit$ = this.actions$.pipe(
        ofType<assumptionDepartureActions.EditAssumptionOrDeparture>(assumptionDepartureActions.ActionTypes.EditAssumptionOrDeparture),
        map(({payload}) => {
            const _update: Update<ValuationAssumptionDeparture> = {
                id: payload.data.front_id,
                changes: {
                    ...payload.data
                }
            };

            return new assumptionDepartureActions.AssumptionOrDepartureEdited({
                data: _update,
                type: payload.data.type
            })
        })
    )

    @Effect()
    removeToE$ = this.actions$.pipe(
        ofType<assumptionDepartureActions.RemoveToEAssumptionOrDeparture>(assumptionDepartureActions.ActionTypes.RemoveToEAssumptionOrDeparture),
        map(({payload}) => {
            const restored = this.service.restore(payload.tp, payload.data);
            const _updated: Update<ValuationAssumptionDeparture> = {
                id: restored.front_id,
                changes: {
                    justification: payload.justification,
                    status: Status.Removed,
                    input: restored.input
                }
            }
            return new assumptionDepartureActions.ToEAssumptionOrDepartureRemoved({
                data: _updated,
                type: restored.type
            });
        })
    )

    @Effect()
    removeInput$ = this.actions$.pipe(
        ofType<assumptionDepartureActions.RemoveInputAssumptionOrDeparture>(assumptionDepartureActions.ActionTypes.RemoveInputAssumptionOrDeparture),
        map(({payload}) => {
            return new assumptionDepartureActions.InputAssumptionOrDepartureRemoved({
                id: payload.data.front_id,
                type: payload.data.type
            })
        })
    )

    @Effect()
    restore$ = this.actions$.pipe(
        ofType<assumptionDepartureActions.RestoreAssumptionOrDeparture>(assumptionDepartureActions.ActionTypes.RestoreAssumptionOrDeparture),
        map(({payload}) => {
            const restored = this.service.restore(payload.tp, payload.data);
            const _updated: Update<ValuationAssumptionDeparture> = {
                id: restored.front_id,
                changes: {
                    justification: null,
                    status: Status.Valid,
                    input: restored.input
                }
            };
            return new assumptionDepartureActions.AssumptionOrDepartureRestored({
                data: _updated,
                type: restored.type
            })
        })
    )
}