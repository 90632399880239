<div class="d-flex flex-column mb-4" *ngIf="loading$|async">
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<span></span>
		</div>
		<div class="card-toolbar">
			<div *ngIf="showAddNewCoiBtn$|async" class="d-flex justify-content-end">
				<div ngbDropdown [autoClose]="false" [placement]="'bottom-right'" 
					class="dropdown dropdown-inline mr-4"
					(mouseenter)="onHover($event, 'mouseenter')"
					(mouseleave)="onHover($event, 'mouseleave')">
					<div ngbDropdownAnchor 
						class="btn btn-label-brand btn-bold btn-sm d-flex align-items-center bg-white"
						style="pointer-events: none !important; cursor: pointer !important;">
						<img style="width: 1.5rem; margin-right: 1rem;" 
							src="/assets/media/icons/finance.svg" />
						<span class="font-weight-bold">Amend ToE</span>
					</div>
					<div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right" style="width: 240px;">
						<ul class="navi navi-hover">
							<li class="navi-item">
								<a href="javascript:;" class="navi-link" (click)="addConflictOfInterests()">
									<div class="d-flex justify-content-between navi-text">
										<div class="d-flex align-items-center">
											<mat-icon inline="true">add</mat-icon>
											<span>New Conflict(s)</span>
										</div>
										<button mat-icon-button
											(click)="$event.stopPropagation()"
											placement="top"
											container="body"
											[ngbPopover]="'TOOLTIP.DASHBOARD.NEW_CONFLICT_OF_INTERESTS.DESCRIPTION' | translate"
											[popoverTitle]="'TOOLTIP.DASHBOARD.NEW_CONFLICT_OF_INTERESTS.TITLE' | translate">
											<mat-icon>info</mat-icon>
										</button>
									</div>
								</a>
							</li>
							<li class="navi-item">
								<a href="javascript:;" class="navi-link" (click)="addAmendments()">
									<div class="d-flex justify-content-between navi-text">
										<div class="d-flex align-items-center">
											<mat-icon inline="true">add</mat-icon>
											<span>New Amendment(s)</span>
										</div>
										<button mat-icon-button
											(click)="$event.stopPropagation()"
											placement="top"
											container="body"
											[ngbPopover]="'TOOLTIP.DASHBOARD.NEW_AMENDMENTS.DESCRIPTION' | translate"
											[popoverTitle]="'TOOLTIP.DASHBOARD.NEW_AMENDMENTS.TITLE' | translate">
											<mat-icon>info</mat-icon>
										</button>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="mat-table-wrapper">
			<mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="task_name">
					<mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.taskName}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="download_doc">
					<mat-header-cell *matHeaderCellDef>Generated document</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.taskDetail}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="completion_status">
					<mat-header-cell *matHeaderCellDef>Exp. Completion date</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<ng-container *ngTemplateOutlet="taskCompStat; context: {stat: item.completion}"></ng-container>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="assignee">
					<mat-header-cell *matHeaderCellDef>User</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<kt-user-tooltip [user]="item.assignee" ></kt-user-tooltip>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<button mat-icon-button color="primary" 
							[ngClass]="{'invisible': item.taskState == state.Initial}"
							[ngbPopover]="newCoiInfo"
							placement="top"
							container="body">
							<mat-icon>info</mat-icon>
						</button>
						<ng-template #newCoiInfo>
							{{ item.additionalTaskInfo.taskId == 8 
								? 'New conflict(s)'
								: item.taskDetail == 'TOE Cancellation'
									? 'TOE Cancellation'
									: 'New amendment(s)'}} added on the 
							{{item.additionalTaskInfo ? (item.additionalTaskInfo.createdDate  | date: 'dd MMM yyy - h:m' ) : ''}} 
							by 
							{{item.additionalTaskInfo ? item.additionalTaskInfo.userName : ''}}
							<br><span class="font-weight-bold">{{item.amendmentDetail.includes('Abort') ? '': 'Removal of TPs: ' }}</span> {{item.amendmentDetail}} 
						</ng-template>
						<button mat-icon-button color="primary" (click)="downloadReport(item.generatedReportURL)">
							<mat-icon>get_app</mat-icon>
						</button>
						<span *ngIf="item.taskActions.canBeDeleted" [matTooltip]="item.taskActions.canBeUserDeleted ? 'Delete' : 'Cannot delete'">
							<button mat-icon-button color="warn" matTooltip="Delete" (click)="deleteTask(item)"
								type="button" [disabled]="!item.taskActions.canBeUserDeleted">
								<mat-icon>delete</mat-icon>
							</button>
						</span>
						<button mat-icon-button color="primary" matTooltip="View document" (click)="viewDocument(item)"
							type="button" *ngIf="item.taskActions.canBeSeen">
							<mat-icon>remove_red_eye</mat-icon>
						</button>
						<button mat-icon-button color="primary" matTooltip="Edit Information of the Task" (click)="editTaskInformation(item)"
							type="button" *ngIf="item.taskActions.canBeEdited">
							<mat-icon>settings</mat-icon>
						</button>
						<span [matTooltip]="item.taskActions.canBeReached ? 'Reach' : 'Cannot Reach'">
							<button mat-icon-button color="primary" (click)="uploadDocument(item)"
								type="button" [disabled]="!item.taskActions.canBeReached">
								<i class="flaticon2-arrow" [ngStyle]="{
									'color': item.taskActions.canBeReached ? 'black' : 'lightgray'
								}"></i>
							</button>
						</span>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="columns"
					[style.backgroundColor]="'#a6cef8'">
				</mat-header-row>
				<mat-row *matRowDef="let row; columns: columns"></mat-row>
			</mat-table>
		</div>
	</div>
</div>

<ng-template #taskCompStat let-stat="stat">
	<ng-template #expDateContent>
		<span>Expected completion date: {{stat.expectedDate | date: 'dd MMM yyy - hh a'}}</span>
	</ng-template>
	<ng-template #compDateContent>
		<span>Completed date: {{stat.completedDate  | date: 'dd MMM yyy'}}</span>
	</ng-template>

	<div class="d-flex w-100 justify-content-between align-items-center">
		<div *ngIf="stat.status == status.InProgress">
			<span style="color: #3797FF; font-weight: bold">Remaining days: {{stat.remainingTime}}</span>
		</div>
		<div *ngIf="stat.status == status.Today">
			<span style="color: #FDA701; font-weight: bold">Today</span>
		</div>
		<div *ngIf="stat.status == status.Overdue">
			<span style="color: #F44D5E; font-weight: bold">Overdue: {{stat.overdueTime}}</span>
		</div>
		<div *ngIf="stat.status == status.Completed">
			<span style="color: #16C4BB; font-weight: bold">Completed</span>
		</div>
		<button mat-icon-button color="primary" *ngIf="stat.status != status.Completed"
			container="body"
			placement="top"
			[ngbPopover]="expDateContent">
			<mat-icon>info</mat-icon>
		</button>
		<button mat-icon-button color="primary" *ngIf="stat.status == status.Completed"
			container="body"
			placement="top"
			[ngbPopover]="compDateContent">
			<mat-icon>info</mat-icon>
		</button>
	</div>
</ng-template>