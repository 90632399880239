import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, Subscription, of } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, skip, take, delay } from 'rxjs/operators'
import { FloorNumberingScheme, FloorNumberingSchemeDeleted, FloorNumberingSchemeDeletedFromAdminTrash, FloorNumberingSchemeDeletedFromTrash, FloorNumberingSchemeOnServerAdminRestored, FloorNumberingSchemeOnServerRestored, FloorNumberingSchemesActionToggleLoading, FloorNumberingSchemesDataSource, FloorNumberingSchemesPageRequested, FloorNumberingSchemesService, FloorNumberingSchemeTrashFlushed, FloorNumberingSchemeUpdated, selectAdminTrashedFloorNumberingSchemeCount, selectTrashedFloorNumberingSchemeCount } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'kt-floor-numbering-schemes-list',
  templateUrl: './floor-numbering-schemes-list.component.html',
  styleUrls: ['./floor-numbering-schemes-list.component.scss']
})
export class FloorNumberingSchemesListComponent implements OnInit, OnDestroy {
  dataSource: FloorNumberingSchemesDataSource;
  displayedColumns = ['id', 'name', 'is_hidden', 'actions'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  // Selection
  foundationTypeResult: FloorNumberingScheme[] = [];

  trashCnt: number = 0;
  adminTrashCnt: number = 0;

  // Subscriptions
  private subscriptions: Subscription[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    private service: FloorNumberingSchemesService
  ) { }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Display';
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this._loadList())
    )
        .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
            this.paginator.pageIndex = 0;
            this._loadList();
        })
    )
        .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new FloorNumberingSchemesDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
        skip(1),
        distinctUntilChanged()
    ).subscribe(res => {
        this.foundationTypeResult = res;
    });

    this.subscriptions.push(entitiesSubscription);

    // trash count
    const selectTrashedSubscription = this.store.pipe(
        select(selectTrashedFloorNumberingSchemeCount)
    ).subscribe(res => {
        this.trashCnt = res;
    });
    this.subscriptions.push(selectTrashedSubscription);

    const selectAdminTrashedSubscription = this.store.pipe(
        select(selectAdminTrashedFloorNumberingSchemeCount)
    ).subscribe(res => {
        this.adminTrashCnt = res;
    });
    this.subscriptions.push(selectAdminTrashedSubscription);

    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
        this._loadList();
    }); // Remove this line, just loading imitation
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private _loadList() {
    const queryParams = new QueryParamsModel(
      this._filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
    this.store.dispatch(new FloorNumberingSchemesPageRequested({page: queryParams}));
  }

  private _filterConfiguration(): any {
      const filter: any = {
        with_hidden: true
      };
      const searchText: string = this.searchInput.nativeElement.value;

      filter.name = searchText;
      if (!searchText) {
          return filter;
      }
      return filter;
  }

  resetFilter(): any {
      this.searchInput.nativeElement.value = '';
      this._loadList();
  }

  addItem() {
      this.editItem(null);
  }

  editItem(_item) {
      if (_item) {
          this.router.navigate(['edit', _item.id], {relativeTo: this.activatedRoute});
      } else {
          this.router.navigate(['add'], {relativeTo: this.activatedRoute});
      }
  }

  deleteItem(_item: FloorNumberingScheme) {
      const _title = this.translate.instant('FLOOR_NUMBERING_SCHEMES.LIST.DIALOG.DELETE.TITLE');
      const _description: string = this.translate.instant('FLOOR_NUMBERING_SCHEMES.LIST.DIALOG.DELETE.DESCRIPTION', {name: _item.name});
      const _waitDesciption =  this.translate.instant('FLOOR_NUMBERING_SCHEMES.LIST.DIALOG.DELETE.WAIT_DESCRIPTION');

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
          if (!res) {
              return;
          }

          this.store.dispatch(new FloorNumberingSchemeDeleted({id: _item.id}));
      });
  }

    trash() {
        this.store.dispatch(new FloorNumberingSchemesActionToggleLoading({isLoading: true}));
        this.service.getTrashed().subscribe(res => {

            this.store.dispatch(new FloorNumberingSchemesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.user_deleted,
                    deletedUser: (elem.userDeletedBy && elem.userDeletedBy.length > 0 ? elem.userDeletedBy : ''),
                });
            });
            this.show_trash(items);
        });
    }

    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new FloorNumberingSchemesActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new FloorNumberingSchemesActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `${elem.name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: (elem.deletedBy && elem.deletedBy.length > 0 ? elem.deletedBy : ''),
                });
            });
            this.show_trash(items, true);
        });
    }
    
    /**
     * Show Edit agency dialog and save after success close result
     * @param items
     * @param isAdmin
     */
    show_trash(items: any[], isAdmin = false) {
        let _title = 'Deleted Foundation Types';
        if (isAdmin) {
            _title = 'Admin Deleted Foundation Types';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new FloorNumberingSchemeOnServerAdminRestored({id}));
        } else {
            this.store.dispatch(new FloorNumberingSchemeOnServerRestored({id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new FloorNumberingSchemeDeletedFromTrash({id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new FloorNumberingSchemeDeletedFromAdminTrash({id}));
    }

    flush() {
        this.store.dispatch(new FloorNumberingSchemeTrashFlushed());
    }

    viewEntry(item: FloorNumberingScheme) {
        this.router.navigate(['view', item.id], {relativeTo: this.activatedRoute})
    }
    onHiddenChange(event: MatCheckboxChange, item: FloorNumberingScheme) {
        const _item = cloneDeep(item)
        _item.is_hidden = event.checked
        this.store.dispatch(new FloorNumberingSchemeUpdated({
            FloorNumberingScheme: _item,
            partialFloorNumberingScheme: {
                id: item.id,
                changes: {
                    is_hidden: event.checked
                }
            }
        }))
    }
}
