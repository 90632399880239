// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {RainwaterPipeService} from '../_services/rainwater-pipe.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allRainwaterPipesLoaded} from '../_selectors/rainwater-pipe.selectors';
// Actions
import {
    AllRainwaterPipesLoaded,
    AllRainwaterPipesRequested,
    RainwaterPipeActionTypes,
    RainwaterPipesPageRequested,
    RainwaterPipesPageLoaded,
    RainwaterPipeUpdated,
    RainwaterPipesPageToggleLoading,
    RainwaterPipeDeleted,
    RainwaterPipeOnServerCreated,
    RainwaterPipeCreated,
    RainwaterPipeActionToggleLoading, RainwaterPipeTrashFlushed, RainwaterPipeOnServerRestored, RainwaterPipeRestored,
    RainwaterPipeOnServerAdminRestored, RainwaterPipeAdminRestored, RainwaterPipeDeletedFromTrash, RainwaterPipeDeletedFromAdminTrash
} from '../_actions/rainwater-pipe.actions';

@Injectable()
export class RainwaterPipeEffects {
    showPageLoadingDispatcher = new RainwaterPipesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new RainwaterPipeActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new RainwaterPipeActionToggleLoading({isLoading: false});

    @Effect()
    loadAllRainwaterPipe$ = this.actions$
        .pipe(
            ofType<AllRainwaterPipesRequested>(RainwaterPipeActionTypes.AllRainwaterPipesRequested),
            withLatestFrom(this.store.pipe(select(allRainwaterPipesLoaded))),
            filter(([action, isAllRainwaterPipeLoaded]) => !isAllRainwaterPipeLoaded),
            mergeMap(() => {
                return this.service.getAll()
            }),
            map(res => {
                return new AllRainwaterPipesLoaded({items: res.data});
            })
        );

    @Effect()
    loadRainwaterPipePage$ = this.actions$
        .pipe(
            ofType<RainwaterPipesPageRequested>(RainwaterPipeActionTypes.RainwaterPipesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new RainwaterPipesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeUpdated>(RainwaterPipeActionTypes.RainwaterPipeUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeOnServerCreated>(RainwaterPipeActionTypes.RainwaterPipeOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.item).pipe(
                    tap(res => {
                        this.store.dispatch(new RainwaterPipeCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    // trash system


    @Effect()
    flushRainwaterPipeTrash$ = this.actions$
        .pipe(
            ofType<RainwaterPipeTrashFlushed>(RainwaterPipeActionTypes.RainwaterPipeTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeOnServerRestored>(RainwaterPipeActionTypes.RainwaterPipeOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RainwaterPipeRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeOnServerAdminRestored>(RainwaterPipeActionTypes.RainwaterPipeOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new RainwaterPipeAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeDeleted>(RainwaterPipeActionTypes.RainwaterPipeDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeDeletedFromTrash>(RainwaterPipeActionTypes.RainwaterPipeDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashRainwaterPipe$ = this.actions$
        .pipe(
            ofType<RainwaterPipeDeletedFromAdminTrash>(RainwaterPipeActionTypes.RainwaterPipeDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: RainwaterPipeService, private store: Store<AppState>) {
    }
}
