<div class="row">
    <div class="col-12">
        <h4 class="my-3 text-mad text-uppercase d-flex align-items-center">
            <span>reports' color schemes</span>
        </h4>
        <hr class="active">
    </div>
    <div class="col-12" *ngIf="!readonly">
        <div ktPortletTools class="mb-1 justify-content-end mad-toolbar-container">
            <div>
                <button class="btn font-semibold reset-btn" mat-raised-button (click)="resetAll()" type="button">
                    Reset all
                </button>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div clas="example-container mat-elevation-z0">
            <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="report">
                    <mat-header-cell *matHeaderCellDef>Report</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="primary_color">
                    <mat-header-cell *matHeaderCellDef>Primary Color</mat-header-cell>
                    <mat-cell *matCellDef="let item, let i = index">
                        <div class="d-flex align-items-center" [ngClass]="{'pointer': !readonly}" (click)="openColorPicker($event, item, i, 'primary')">
                            <div class="color-sqr" [style.backgroundColor]="item.primaryColor"></div>
                            <div class="ml-2">{{item.primaryColor | uppercase }}</div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="footer_color">
                    <mat-header-cell *matHeaderCellDef>Secondary Color</mat-header-cell>
                    <mat-cell *matCellDef="let item, let i = index">
                        <ng-container *ngIf="item.footerColor">
                            <div class="d-flex align-items-center pointer" (click)="openColorPicker($event, item, i, 'footer')">
                                <div class="color-sqr" [style.backgroundColor]="item.footerColor"></div>
                                <div class="ml-2">{{item.footerColor | uppercase }}</div>
                            </div>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="font">
                    <mat-header-cell *matHeaderCellDef>Font</mat-header-cell>
                    <mat-cell *matCellDef="let item, let i = index">
                        <mat-select [value]="item.fontId" (selectionChange)="onSelectionChange($event, item, i)">
                            <mat-option *ngFor="let font of fonts" [value]="font.id">
                                {{font.name}}
                            </mat-option>
                        </mat-select>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item, let i = index">
                        <button *ngIf="!readonly" mat-icon-button color="warn" matTooltip="Reset Settings" type="button" (click)="reset(item, i)">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" matTooltip="Test Settings" type="button">
                            <mat-icon>playlist_add_check</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</div>


<ng-template #colorPicker>
    <color-chrome></color-chrome>
</ng-template>