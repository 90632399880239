// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {RentReviewCommentActions, RentReviewCommentActionTypes} from '../_actions/rent-review-comment.actions';
import {RentReviewComment} from '../_models/rent-review-comment.model';

export interface RentReviewCommentsState extends EntityState<RentReviewComment> {
    isAllRentReviewCommentsLoaded: boolean;
    queryRowsCount: number;
    queryResult: RentReviewComment[];
    lastCreatedRentReviewCommentId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RentReviewComment> = createEntityAdapter<RentReviewComment>();

export const initialRentReviewCommentsState: RentReviewCommentsState = adapter.getInitialState({
    isAllRentReviewCommentsLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedRentReviewCommentId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function rentReviewCommentsReducer(state = initialRentReviewCommentsState, action: RentReviewCommentActions): RentReviewCommentsState {
    switch (action.type) {
        case RentReviewCommentActionTypes.RentReviewCommentsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedRentReviewCommentId: undefined
            };
        case RentReviewCommentActionTypes.RentReviewCommentsActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        case RentReviewCommentActionTypes.RentReviewCommentOnServerCreated:
            return {
                ...state
            };
        case RentReviewCommentActionTypes.RentReviewCommentCreated:
            return adapter.addOne(action.payload.rentReviewComment, {
                ...state, lastCreatedRentReviewCommentId: action.payload.rentReviewComment.id
            });
        case RentReviewCommentActionTypes.RentReviewCommentUpdated:
            return adapter.updateOne(action.payload.partialRentReviewComment, state);
        case RentReviewCommentActionTypes.AllRentReviewCommentsLoaded:
            return adapter.addAll(action.payload.rentReviewComments, {
                ...state, isAllRentReviewCommentsLoaded: true
            });
        case RentReviewCommentActionTypes.RentReviewCommentsPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };
        case RentReviewCommentActionTypes.RentReviewCommentsPageLoaded:
            return adapter.addMany(action.payload.rentReviewComments, {
                ...initialRentReviewCommentsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        case RentReviewCommentActionTypes.RentReviewCommentDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case RentReviewCommentActionTypes.RentReviewCommentRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case RentReviewCommentActionTypes.RentReviewCommentDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case RentReviewCommentActionTypes.RentReviewCommentAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case RentReviewCommentActionTypes.RentReviewCommentDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case RentReviewCommentActionTypes.RentReviewCommentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
