// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
// CRUD
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
// Models
import {AssetClassOfficeModel} from '../_models/asset-class-office.model';
import {environment} from '../../../../environments/environment';
import {AssetClassBuildingInformation, AssetClassTenure, AssetClassSizeModel, OfficeExternalAreaModel} from '..';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';
// import {LatLng} from '@agm/core';

const AC_PATH = 'offices';

@Injectable()
export class AssetClassOfficesService {
    API_COMPARABLES_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getApartments(queryParams: QueryParamsModel): Observable<any> {


        const _body = {
            sortOrder: queryParams.sortOrder,
            sortField: queryParams.sortField,
            page: queryParams.pageNumber.toString(),
            pageSize: queryParams.pageSize.toString(),
            filter: queryParams.filter,
        };

        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH + '/data';
        return this.http.post<any>(url, _body, {headers: httpHeaders});
    }

    createAssetClassOffice(_assetClassOffice: AssetClassOfficeModel,
                           _tenures: AssetClassTenure[],
                           _sizes: AssetClassSizeModel[],
                           _fileList: any[],
                           _selectedFacilities: any[],
                           _selectedFacilitiesExclusive: any[],
                           _officeExternalAreas: OfficeExternalAreaModel[],
                           _documents: any[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {
            assetClassOffice: _assetClassOffice,
            tenures: _tenures,
            sizes: _sizes,
            fileList: _fileList,
            selectedFacilities: _selectedFacilities,
            selectedFacilitiesExclusive: _selectedFacilitiesExclusive,
            officeExternalAreas: _officeExternalAreas,
            documents: _documents
        };
        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.post<any>(url, body, {headers: httpHeaders});
    }

    updateAssetClassOffice(_assetClassOffice: AssetClassOfficeModel,
                           _tenures: AssetClassTenure[],
                           _sizes: AssetClassSizeModel[],
                           _fileList: any[],
                           _selectedFacilities: any[],
                           _selectedFacilitiesExclusive: any[],
                           _officeExternalAreas: OfficeExternalAreaModel[],
                           _documents: any[]): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        httpHeader.set('Content-Type', 'application/json');
        const body = {
            assetClassOffice: _assetClassOffice,
            tenures: _tenures,
            sizes: _sizes,
            fileList: _fileList,
            selectedFacilities: _selectedFacilities,
            selectedFacilitiesExclusive: _selectedFacilitiesExclusive,
            officeExternalAreas: _officeExternalAreas,
            documents: _documents
        };

        const url = this.API_COMPARABLES_URL + AC_PATH;

        return this.http.put(url + `/${_assetClassOffice.id}`, body, {headers: httpHeader});
    }

    // Trash
    getTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.get<AssetClassOfficeModel[]>(url + `/trash`, {headers: httpHeaders});
    }

    // admin Trash
    getAdminTrashed(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.get<any>(url + `/admin/trash`,
            {headers: httpHeaders});
    }

    // Trash
    // delete from trash
    deleteFromTrash(assetClassOfficeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/offices` +
            `/trash/${assetClassOfficeId}`, {headers: httpHeaders});
    }

    // delete from trash
    deleteFromAdminTrash(assetClassOfficeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/offices` +
            `/admin/trash/${assetClassOfficeId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(assetClassOfficeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/comparable/offices` +
            `/trash/${assetClassOfficeId}`, {headers: httpHeaders});
    }

    deleteAssetClassOffice(assetClassOfficeId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/comparable/offices/${assetClassOfficeId}`;
        return this.http.delete<any>(url, {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const url = this.API_COMPARABLES_URL + AC_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(url + '/trash/flush', {headers: httpHeaders});
    }

    getAssetClassOffice(assetClassOfficeId: number): Observable<AssetClassOfficeModel> {
        const url = this.API_COMPARABLES_URL + AC_PATH;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<AssetClassOfficeModel>(url + `/${assetClassOfficeId}`, {headers: httpHeaders});
    }

    getWhat3Word(latLng: google.maps.LatLng): Observable<any> {

        const url = 'https://api.what3words.com/v2/reverse?coords=' +
            latLng.lat() + ',' +
            latLng.lng() + '&display=full&format=json&key=G6C4BV33&lang=en';

        return this.http.get<any>(url);
    }

    getBuildingInfoById(assetClassOfficeId: number): Observable<AssetClassBuildingInformation> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH + `/${assetClassOfficeId}/building-information`;
        return this.http.get<AssetClassBuildingInformation>(url, {headers: httpHeaders});
    }

    getBuildingInfos(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH + `/building-informations`;
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getTenuresOfAc(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH + `/${id}/tenures`;
        return this.http.get<any>(url, {headers: httpHeaders});
    }

    getSizesOfAc(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH + `/${id}/sizes`;
        return this.http.get<any>(url, {headers: httpHeaders});
    }


    getApartmentsByLocation(latLng1: google.maps.LatLng, latLng2: google.maps.LatLng): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const body = {
            max_lng: latLng2.lng(),
            max_lat: latLng2.lat(),
            min_lng: latLng1.lng(),
            min_lat: latLng1.lat(),
        };
        const url = this.API_COMPARABLES_URL + AC_PATH;

        return this.http.post(url + `/map`, body, {headers: httpHeader});
    }

    duplicate(id: number): Observable<any> {
        const httpHeader = this.authService.getAuthHeaders();
        const url = this.API_COMPARABLES_URL + AC_PATH;
        return this.http.post<any>(url + `/${id}/copy`, {}, {
            headers: httpHeader
        });
    }
}
