<div ngbDropdown class="w-100" >
    <button ngbDropdownToggle class="btn w-100 d-flex justify-content-between align-items-center dropdown-toggle text-white" 
        style="padding-top: 0.8rem; padding-bottom: 0.8rem"
        [style.background-color]="bg">
            <small class="sub_text">{{subText$|async}}</small>
            <p class="mb-0">{{text$ | async}}</p>
    </button>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right">
        <button class="dropdown-item" [ngClass]="{'bg-blue': option.id==-1}" *ngFor="let option of selections"
            (click)="onSelect(option)">
            {{option.text}}
        </button>
    </div>
</div>