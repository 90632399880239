import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    TrainingDocumentsService
} from '../../../../../core/training-documents';

@Injectable()
export class TrainingDocumentResolver implements Resolve<any> {
    constructor(public trainingDocumentsService: TrainingDocumentsService) {}

    resolve(route: ActivatedRouteSnapshot) {
        const trainingDocumentId = Number(route.params.id);
        return this.trainingDocumentsService.getById(trainingDocumentId);
    }
}
