import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpUtilsService} from '../../_base/crud';
import {TpFileModel} from '../_models/tp-file.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class TpFileService {

    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/target_property/attachments`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAttachedFiles(): Observable<any> {
        return this.http.get<any>(this.API_URL);
    }

    getAttachedFilesByTpId(_id: number): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        return this.http.get<any>(this.API_URL + '/' + _id);
    }

    getAttachedFilesByToeId(_id: number): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        return this.http.get<any>(this.API_URL + '/by-toe/' + _id);
    }

    saveFile(_id: number, _obj: TpFileModel): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(this.API_URL + '/' + _id, _obj, {headers: httpHeaders});
    }

    deleteAttachedFile(_id: number): Observable<any> {
        return this.http.delete(this.API_URL + '/' + _id);
    }
}
