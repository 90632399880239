import {BaseModel} from '../../_base/crud';
import {SourceExternalReference} from '../../linked-tables';

export class AssetClassTenureModel extends BaseModel {
    id: number;
    current_occupier: string;
    previous_occupier: string;
    rrr_over_land: string;
    additional_rrr_over_land: string;
    difference_between_boundaries: string;

    created_at: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.current_occupier = '';
        this.previous_occupier = '';
        this.rrr_over_land = '';
        this.additional_rrr_over_land = '';
        this.difference_between_boundaries = '';

        this.created_at = new Date();
        this.updated_at = new Date();
    }
}