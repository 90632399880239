import {User, UserService} from '../../../core/auth';
import {UntypedFormControl} from '@angular/forms';
import {timer} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';

export const emailAsyncValidator = (service: UserService, user: User, time: number = 500) => {
    return (input: UntypedFormControl) => {
        return timer(time).pipe(
            switchMap(() => service.checkUserEmail(input.value)),
            map(res => {
                return res.userEmailExists && user.email !== input.value ? {emailExist: true} : null;
            })
        );
    };
};
