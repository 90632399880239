import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutUtilsService} from '../../../../core/_base/crud';
import {AppState} from '../../../../core/reducers';
import {Observable, Subscription} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
    AllTpTaskRequested,
    AssetClassesService,
    selectTpTasksByToeId, TpTaskCleared,
    TpTaskModel
} from '../../../../core/asset_class';
import {
    selectToesActionLoading, ToeModel, ToeService
} from '../../../../core/toe';

import {CookieService} from 'ngx-cookie-service';
import {PmEditDialogComponent} from '../_sub/pm-edit-dialog/pm-edit-dialog.component';
import {Globals} from '../../../../core/_base/crud/utils/globals.service';
import {AssignmentModel, selectAssignmentById} from '../../../../core/assignment';
import {currentUser, User} from 'src/app/core/mad-auth/mad-auth.store';
import {take} from 'rxjs/operators';

@Component({
    selector: 'kt-project-management',
    templateUrl: './project-management.component.html',
    styleUrls: ['./project-management.component.scss'],
})

export class ProjectManagementComponent implements OnInit, OnDestroy {

    displayedColumns = ['tp_name', 'task', 'details', 'date', 'user', 'action'];

    rowSpan = [];
    serverData: TpTaskModel[] = [];
    dataSource = new MatTableDataSource([]);
    workers: User[] = [];

    // Subscriptions
    private subscriptions: Subscription[] = [];

    loading$: Observable<boolean>;
    toeStatus = 0;
    toe: ToeModel;
    assignment: AssignmentModel;
    currentUser: User;

    /**
     * Component constructor
     * @param activatedRoute
     * @param router
     * @param dialog
     * @param cookieService
     * @param modelService
     * @param layoutUtilsService
     * @param toeService
     * @param global
     * @param snackBar: MatSnackBar
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private cookieService: CookieService,
                private modelService: AssetClassesService,
                private layoutUtilsService: LayoutUtilsService,
                private toeService: ToeService,
                private global: Globals,
                public snackBar: MatSnackBar,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {

        this.store.dispatch(new TpTaskCleared());
        this.store.dispatch(new AllTpTaskRequested());
        this.loading$ = this.store.pipe(
            select(selectToesActionLoading)
        );

        if (this.global.activeAssignmentId) {

            const selectAssSub = this.store.pipe(select(selectAssignmentById(this.global.activeAssignmentId))).subscribe(res => {
                if (res) {
                    this.assignment = Object.assign({}, res);
                } else {
                }
            });
            this.subscriptions.push(selectAssSub);
        }

        const selectActivatedRoute = this.activatedRoute.data.subscribe(res => {
            this.workers = res.workers.data.map(obj => obj.worker);
            this.toe = res.toe;
        });
        this.subscriptions.push(selectActivatedRoute);

        const tpTasksByToeIdSubscription = this.store.pipe(
            select(selectTpTasksByToeId(this.global.activeTOEId)))
            .subscribe(res => {
                this.serverData = [];
                if (res && res.length > 0) {
                    this.serverData = res;
                    this.rowSpan = [];
                    let _cnt = 0;
                    let _tpId = this.serverData[0].tp_id;
                    this.serverData.forEach((el, i) => {
                        this.rowSpan.push(0);
                        if (el.tp_id != _tpId) {
                            this.rowSpan[i - _cnt] = _cnt;
                            _cnt = 0;
                            _tpId = el.tp_id;
                        }
                        _cnt++;
                    });
                    this.rowSpan[this.rowSpan.length - _cnt] = _cnt;
                }
                this.dataSource.data = this.serverData;
            });
        this.subscriptions.push(tpTasksByToeIdSubscription);

        this.store.pipe(
            take(1),
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                }
            });

    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    edit(obj, _index) {


        const _title = 'Change Information';
        let _users = Object.assign([], this.workers);

        if ((obj.task_id == 4 || obj.task_id == 0) && !_users.find(u => u.id == this.assignment.valuer_id)) {
            _users.push(this.assignment.manager);
        }
        if (obj.task_id == 3) {
            _users = _users.slice(0, 1);
        }


        this.dialog.open(PmEditDialogComponent, {
            data: {title: _title, item: obj, users: _users, manager_id: this.assignment.valuer_id},
            width: '440px',
        });
    }
}
