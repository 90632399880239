import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { ToeTemplateModel } from "../_models";
import { ToeTemplateState } from "../_reducers";
import { toeTemplateSelectors } from "../_selectors";
import { TemplateTableDataSource } from "./base-template.datasource";

export class ToeTemplateDataSource extends TemplateTableDataSource<ToeTemplateModel> {
    constructor(private store: Store<ToeTemplateState>) {
        super();

        this.isLoading$ = this.store.select(toeTemplateSelectors.selectIsLoading);
        this.total$ = this.store.select(toeTemplateSelectors.selectTotal)

        this.store.pipe(
            takeUntil(this.destroy$),
            select(toeTemplateSelectors.selectToeTemplates),
        ).subscribe(res => {
            this.entitySubject$.next(res);
        })
    }
}