import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { AssetClassParkingModel } from "../_models/asset-class-parking.model";

export enum AssetClassParkingActionTypes {
    AssetClassParkingActionToggleLoading = '[AssetClassParking Effect] AssetClassParking Action Toggle Loading',

    AssetClassParkingOnServerCreated = '[Edit AssetClassParking page] AssetClassParking On Server Created',
    AssetClassParkingCreated = '[AssetClassParking Effect] AssetClassParking Created',
    
    AssetClassParkingOnServerUpdated = '[Edit AssetClassParking page] AssetClassParking On Server Updated',
    AssetClassParkingUpdated = '[AssetClassParking Effect] AssetClassParking Updated',

    AssetClassParkingDeleted = '[AssetClassParking List Page] Asset Class Land Deleted',
    AssetClassParkingDeletedFromTrash = '[AssetClassParking Trash Modal] Asset Class Land Deleted from Trash',
    AssetClassParkingDeletedFromAdminTrash = '[AssetClassParking Trash Modal] Asset Class House Deleted from Admin Trash',
    AssetClassParkingTrashFlushed = '[AssetClassParking Trash Modal] Asset Class Parking Trash Flushed',

    AssetClassParkingOnServerRestored = '[AssetClassParking Trash Modal] Asset Class Land Restored',
    AssetClassParkingRestored = '[AssetClassParking Effect] Asset Class Land Restored',
    AssetClassParkingOnServerAdminRestored = '[AssetClassParking Admin Trash Modal] Asset Class Land Admin Restored',
    AssetClassParkingAdminRestored = '[AssetClassParking Effect] Asset Class Land Admin Restored',

    AssetClassParkingPageToggleLoading = '[AssetClassParking Effect] AssetClassParking Page Toggle Loading',
    AssetClassParkingPageRequested = '[AssetClassParking List Page] All AssetClassParking requested',
    AssetClassParkingPageLoaded = '[AssetClassParking Effect] All AssetClassParking loaded',

    UpdatePreviouslyCreatedACId = '[AssetClassParking Edit] Update Previously Created AssetClassParking Id',

    DuplicateAssetClass = '[AssetClassParking List Page] Duplicate Asset Class'
}

export class AssetClassParkingOnServerCreated implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingOnServerCreated;
    constructor(public payload: {
        AssetClassParking: AssetClassParkingModel,
        fileList: any[],
    }) {}
}

export class AssetClassParkingCreated implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingCreated;
    constructor(public payload: {AssetClassParking: AssetClassParkingModel}) {}
}

export class AssetClassParkingOnServerUpdated implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingOnServerUpdated;
    constructor(public payload: {
        AssetClassParking: AssetClassParkingModel,
        fileList: any[] 
    }) {}
}

export class AssetClassParkingUpdated implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingUpdated;
    constructor(public payload: {AssetClassParking: AssetClassParkingModel}) {}
}

export class AssetClassParkingPageRequested implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingPageRequested;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class AssetClassParkingPageLoaded implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingPageLoaded;
    constructor(public payload: {
        AssetClassParkings: AssetClassParkingModel[],
        totalCount: number;
        totalTrashed: number;
        totalAdminTrashed: number;
        page: QueryParamsModel
    }) {}
}

export class AssetClassParkingDeleted implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingDeleted;
    constructor(public payload: {id: number}) {}
}

export class AssetClassParkingDeletedFromTrash implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingDeletedFromTrash;
    constructor(public payload: {id: number}) {}
}

export class AssetClassParkingOnServerRestored implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingOnServerRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassParkingRestored implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingRestored;
    constructor(public payload: {ac: AssetClassParkingModel}) {}
}

export class AssetClassParkingOnServerAdminRestored implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingOnServerAdminRestored;
    constructor(public payload: {id: number}) {}
}

export class AssetClassParkingAdminRestored implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingAdminRestored;
    constructor(public payload: {ac: AssetClassParkingModel}) {}
}

export class AssetClassParkingPageToggleLoading implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingPageToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassParkingActionToggleLoading implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingActionToggleLoading;
    constructor(public payload: {isLoading: boolean}) {}
}

export class AssetClassParkingDeletedFromAdminTrash implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}
export class AssetClassParkingTrashFlushed implements Action {
    readonly type = AssetClassParkingActionTypes.AssetClassParkingTrashFlushed;
    constructor() {}
}

export class UpdatePreviouslyCreatedACParkingId implements Action {
    readonly type = AssetClassParkingActionTypes.UpdatePreviouslyCreatedACId;
    constructor() {}
}

export class AssetClassParkingDuplicate implements Action {
    readonly type = AssetClassParkingActionTypes.DuplicateAssetClass;

    constructor(public payload: {id: number}) {}
}

export type AssetClassParkingActions 
    = AssetClassParkingOnServerCreated
    | AssetClassParkingCreated

    | AssetClassParkingOnServerUpdated
    | AssetClassParkingUpdated

    | AssetClassParkingPageRequested
    | AssetClassParkingPageLoaded

    | AssetClassParkingDeleted
    | AssetClassParkingDeletedFromTrash
    | AssetClassParkingDeletedFromAdminTrash
    | AssetClassParkingTrashFlushed

    | AssetClassParkingOnServerRestored
    | AssetClassParkingOnServerAdminRestored
    | AssetClassParkingRestored
    | AssetClassParkingAdminRestored
    
    | AssetClassParkingPageToggleLoading
    | AssetClassParkingActionToggleLoading
    
    | UpdatePreviouslyCreatedACParkingId
    
    | AssetClassParkingDuplicate;
