import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {UserService} from '../../../../../core/auth';

@Injectable()
export class UserResolver implements Resolve<any> {
    constructor(private service: UserService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const _id = Number(route.params.id);
        return this.service.getUserById(_id);
    }
}
