<div class="d-flex flex-column">
    <div ktPortletTools class="mb-1 d-flex justify-content-end">
        <a href="javascript:;"
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            (click)="addItem()"
            matTooltip="Add a New valuation">
            <span class="kt-hidden-mobile"> Add Valuation</span>
        </a>
    </div>

    <div class="mat-table-wrapper mat-elevation-z0 tag-custom-padding-16_25">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="base_of_value_name">
                    <mat-header-cell *matHeaderCellDef > 
                        <span class="mr-2">
                            Basis
                        </span>
                        <mat-icon
                                matSuffix
                                class="cursor-pointer icon-gray"
                                container="body"
                                [popoverTitle]="'TOOLTIP.TP.BASE.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.TP.BASE.DESCRIPTION' | translate"
                                (click)="$event.stopPropagation();">help
                        </mat-icon>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <mat-select [(ngModel)]="item.base_of_value_id">
                            <mat-option *ngFor="let baseOfValue of baseOfValues" 
                                [value]="baseOfValue.id"
                                [disabled]="baseOfValue.id == 7 && liquidationValueAlreadyExists()">
                                {{baseOfValue.name}}
                            </mat-option>
                        </mat-select>
                    </mat-cell>
            </ng-container>
            <ng-container matColumnDef="premise_of_value_name">
                    <mat-header-cell *matHeaderCellDef > 
                        <span class="mr-2">
                            Premise
                        </span>
                        <mat-icon
                                matSuffix
                                class="cursor-pointer icon-gray"
                                container="body"
                                [popoverTitle]="'TOOLTIP.TP.PREMISE.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.TP.PREMISE.DESCRIPTION' | translate"
                                (click)="$event.stopPropagation();">help
                        </mat-icon>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <mat-select [(ngModel)]="item.premise_of_value_id">
                            <mat-option *ngFor="let premiseOfValue of premiseOfValues" [value]="premiseOfValue.id">
                                {{premiseOfValue.name}}
                            </mat-option>
                        </mat-select>
                    </mat-cell>
            </ng-container>
            <ng-container matColumnDef="approaches_to_value_name">
                    <mat-header-cell *matHeaderCellDef > 
                        <span class="mr-2">
                            Approach
                        </span> 
                        <mat-icon
                                matSuffix
                                class="cursor-pointer icon-gray"
                                container="body"
                                [popoverTitle]="'TOOLTIP.TP.APPROACHES.TITLE' | translate"
                                [ngbPopover]="'TOOLTIP.TP.APPROACHES.DESCRIPTION' | translate"
                                (click)="$event.stopPropagation();">help
                        </mat-icon>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <mat-select [(ngModel)]="item.approaches_to_value_id">
                            <mat-option *ngFor="let atv of filteredApproachesToValuesData|async" [value]="atv.id">
                                {{atv.name}}
                            </mat-option>
                        </mat-select>
                    </mat-cell>
            </ng-container>

            <ng-container matColumnDef="methods_to_value_name">
                <mat-header-cell *matHeaderCellDef > 
                    <span class="mr-2">
                        Method
                    </span>
                    <mat-icon
                            matSuffix
                            class="cursor-pointer icon-gray"
                            container="body"
                            [popoverTitle]="'TOOLTIP.TP.METHODS.TITLE' | translate"
                            [ngbPopover]="'TOOLTIP.TP.METHODS.DESCRIPTION' | translate"
                            (click)="$event.stopPropagation();">help
                    </mat-icon>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <mat-select [(ngModel)]="item.methods_to_value_id">
                        <mat-option *ngFor="let mtv of methodsToValueMap[item.approaches_to_value_id]" [value]="mtv.id" [disabled]="mtv.disabled">
                            {{mtv.text}}
                        </mat-option>
                    </mat-select>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tenure">
                <mat-header-cell *matHeaderCellDef >Tenure</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <ng-container [ngSwitch]="tenureType$ |async">
                        <ng-container *ngSwitchCase="tenureType.Default">
                            <mat-select [(ngModel)]="item.tenure_id">
                                <mat-option *ngFor="let tenure of tenures" [value]="tenure.id" [disabled]="item.base_of_value_id == 2 && tenure.id != 1">{{tenure.name}}</mat-option>
                            </mat-select>
                        </ng-container>
                        <ng-container *ngSwitchCase="tenureType.Land">
                            <mat-select [(ngModel)]="item.tenure_id">
                                <mat-option *ngFor="let tenure of landTenures" [value]="tenure.id">{{tenure.name + '(' + tenure.payment_type + ')'}}</mat-option>
                            </mat-select>
                        </ng-container>
                        <ng-container *ngSwitchCase="tenureType.Custom">
                            <mat-form-field appearance="fill">
                                <input matInput [(ngModel)]="item.tenure_custom"/>
                                <mat-icon matSuffix class="cursor-pointer icon-gray" container="body" (click)="$event.stopPropagation()"
                                    [popoverTitle]="'TOOLTIP.TP.CUSTOM_TENURE.TITLE' | translate"
                                    [ngbPopover]="'TOOLTIP.TP.CUSTOM_TENURE.DESCRIPTION' | translate">
                                    help
                                </mat-icon>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="consideration_type">
                <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <mat-select [(ngModel)]="item.consideration_type_id">
                        <mat-option *ngFor="let considerationType of (considerationTypes$|async)" [value]="considerationType.id">
                            {{considerationType.name}}
                        </mat-option>
                    </mat-select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let item; let i = index;">
                    <button mat-icon-button
                            color="warn"
                            matTooltip="Delete valuation"
                            (click)="deleteItem(item)"
                            type="button">
                        <mat-icon>delete</mat-icon>
                    </button>&nbsp;
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>