import { Component, Input, OnInit } from '@angular/core';
import { AssetClassTenure } from 'src/app/core/comparable';
import { CashFlowMethodData, CashFlowMethodVersion } from '../../../../cf-method-types';

@Component({
  selector: 'kt-preliminary-table',
  templateUrl: './preliminary-table.component.html',
  styleUrls: [
    './preliminary-table.component.scss',
    '../../../headline-to-effective.component.scss'
  ]
})
export class PreliminaryTableComponent implements OnInit {
  @Input() data: {
    tpCurrency: string,
    total_consideration: number,
    capital_payment: number,
    consideration: AssetClassTenure,
    calculatedResult: CashFlowMethodData,
    rateValue: {
      targetRate: number,
      growthRate: number,
    },
    methodVersion: CashFlowMethodVersion,
    rent_free_period: number,
    write_off_period: number,
    fitting_out_period: number,
    rent_input_amount_type: string,
    rent_basis_id: number,
    rent_basis_name: string,
    size: number
  }
  methodVersionEnum = CashFlowMethodVersion;

  constructor() { }

  ngOnInit(): void {
  }

}
