import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpUtilsService, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';

import {AuditTrailReport} from '../_models/audit-trail-report.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


``
@Injectable()
export class AuditTrailReportService {
    API_AUDIT_TRAIL_GENERATE_REPORT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignments/report/`;
    API_AUDIT_TRAIL_GENERATE_REPORT_TP_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignments/report/tp/`;
    API_AUDIT_TRAIL_GENERATE_REPORTS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignments/generated-reports/`;
    API_AUDIT_TRAIL_GENERATE_REPORTS_TP_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/valuation/generated-reports/`;
    API_AUDIT_TRAIL_GENERATE_REPORTS_DELETE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/assignments/generated-reports/delete/`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    getAllAuditTrailReports(assignmentId: number): Observable<any> {
        const url = this.API_AUDIT_TRAIL_GENERATE_REPORTS_URL + `${assignmentId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<QueryResultsModel>(url, {headers: httpHeaders});
    }

    getAllAuditTrailReportsByTp(assignmentId: number, toeId: number, tpId: number): Observable<any> {
        const url = this.API_AUDIT_TRAIL_GENERATE_REPORTS_TP_URL + `${assignmentId}/${toeId}/${tpId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<QueryResultsModel>(url, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new auditTrailReport to the server
    createAuditTrailReport(auditTrailReport: AuditTrailReport): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_AUDIT_TRAIL_GENERATE_REPORTS_URL + 'add', auditTrailReport, {headers: httpHeaders});
        // return this.http.get<any>(this.API_AUDIT_TRAIL_GENERATE_REPORT_URL + `${auditTrailId}/${userId}/${auditTrailStatus}`);
    }

    // UPDATE => PUT: update the auditTrailReport on the server
    updateAuditTrailReport(auditTrailReport: AuditTrailReport): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_AUDIT_TRAIL_GENERATE_REPORT_URL + `/${auditTrailReport.id}`, auditTrailReport, {headers: httpHeaders});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    // DELETE => delete the auditTrailReport from the server
    deleteAuditTrailReport(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(this.API_AUDIT_TRAIL_GENERATE_REPORTS_DELETE_URL + `${id}`, {headers: httpHeaders});
    }

    generateAuditTrailReport(auditTrailId: number, userId: number, reportId: number, onlySigned: boolean): Observable<any> {
        let onlySignedInt = 0;
        if (onlySigned) {
            onlySignedInt = 1;
        }
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_AUDIT_TRAIL_GENERATE_REPORT_URL + `${auditTrailId}/${userId}/${reportId}/${onlySignedInt}`, {headers: httpHeaders});
    }

    generateAuditTrailReportByTp(auditTrailId: number, userId: number, reportId: number, onlySigned: boolean, toeId: number, tpId: number): Observable<any> {
        let onlySignedInt = 0;
        if (onlySigned) {
            onlySignedInt = 1;
        }
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_AUDIT_TRAIL_GENERATE_REPORT_TP_URL + `${auditTrailId}/${userId}/${reportId}/${onlySignedInt}/${toeId}/${tpId}`, {headers: httpHeaders});
    }
}
