import {QueryParamsModel} from '../../_base/crud/models/query-models/query-params.model';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassesService} from '../_services/asset-class.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AssetClassActionTypes,
    AssetClassesPageRequested,
    AssetClassesPageLoaded,
    AssetClassActionToggleLoading,
    AssetClassesPageToggleLoading,
    AssetClassOnServerCreated,
    AssetClassOnServerUpdated,
    AssetClassCreated,
    AssetClassUpdated,
    AssetClassesTrashRequested,
    AssetClassRestored,
    AssetClassOnServerRestored,
    AssetClassDeletedFromTrash,
    OneAssetClassDeleted,
    AssetClassTrashFlushed,
    AssetClassGet, AssetClassMapPageRequested, AssetClassValuationDeleted
} from '../_actions/asset-class.actions';
import {of} from 'rxjs';

@Injectable()
export class AssetClassEffects {
    showPageLoadingDispatcher = new AssetClassesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassActionToggleLoading({isLoading: false});

    @Effect()
    loadAssetClassesPage$ = this.actions$.pipe(
        ofType<AssetClassesPageRequested>(AssetClassActionTypes.AssetClassesPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.findAssetClasses(payload.page, payload.toeId);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassesPageLoaded({
                assetClasses: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadAssetClassMapPage$ = this.actions$.pipe(
        ofType<AssetClassMapPageRequested>(AssetClassActionTypes.AssetClassMapPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getApartments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];

            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassesPageLoaded({
                assetClasses: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createAssetClass$ = this.actions$.pipe(
        ofType<AssetClassOnServerCreated>(AssetClassActionTypes.AssetClassOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClass(
                    payload.assetClass,
                    payload.valuations,
                    payload.default_assumptions,
                    payload.special_assumptions,
                    payload.departures,
                    payload.ser,
                ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassCreated({assetClass: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updateAssetClass$ = this.actions$
        .pipe(
            ofType<AssetClassOnServerUpdated>(AssetClassActionTypes.AssetClassOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAssetClass(
                    payload.assetClass,
                    payload.valuations,
                    payload.default_assumptions,
                    payload.special_assumptions,
                    payload.departures,
                    payload.ser,
                ).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassUpdated({assetClass: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteAssetClassValuation$ = this.actions$
        .pipe(
            ofType<AssetClassValuationDeleted>(AssetClassActionTypes.AssetClassValuationDeleted),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deletedAssetClassValuation(
                    payload.tpId,
                    payload.valId
                ).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassUpdated({assetClass: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    trashAssetClasses$ = this.actions$
        .pipe(
            ofType<AssetClassesTrashRequested>(AssetClassActionTypes.AssetClassTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getTrashedAssetClasses();
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClass$ = this.actions$
        .pipe(
            ofType<AssetClassOnServerRestored>(AssetClassActionTypes.AssetClassOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.assetClassId).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassRestored({assetClass: res}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassFromTrash$ = this.actions$
        .pipe(
            ofType<AssetClassDeletedFromTrash>(AssetClassActionTypes.AssetClassDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.assetClassId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClass$ = this.actions$
        .pipe(
            ofType<OneAssetClassDeleted>(AssetClassActionTypes.OneAssetClassDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAssetClass(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAgencyTrash$ = this.actions$
        .pipe(
            ofType<AssetClassTrashFlushed>(AssetClassActionTypes.AssetClassTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    getAssetClass$ = this.actions$
        .pipe(
            ofType<AssetClassGet>(AssetClassActionTypes.AssetClassGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getAssetClass(payload.assetClassId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(
        private actions$: Actions,
        private service: AssetClassesService,
        private store: Store<AppState>
    ) { }
}
