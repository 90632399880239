import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AssetClassModel } from "../../asset_class";
import { StandardMeasurement, UnitAreaMeasurement } from "../../linked-tables";
import { ToeModel } from "../../toe";
import { AssetType } from "../_models/asset-type.model";
import { CriterionModel } from "../_models/criterion.model";

export enum SizeCriterionActionTypes {
    CreateSizeCriterions = 'Create size criterions',
    SizeCriterionsCreated = 'Size criterions are created',

    SizeCriterionAddComparable = 'Add comparable data to size criterions',
    SizeCriterionComparableAdded = 'Comparable data is added to size criterions',
    SizeCriterionRemoveComparable = 'Remove comparable data from size criterions',
    SizeCriterionComparableRemoved = 'Comparable data is removed from size criterions',

    SizeCriterionChangeDefault = 'Change Size Criterion',
    SizeCriterionDefaultChanged = 'Changed Size Criterion',

    SizeCriterionReset = 'Reset value of size criterion',
    SizeCriterionResetted = 'Value of size criterion resetted',

    RestartSizeCriterionState = 'Restart state of size criterion',
    SizeCriterionsLoading = 'Loading size criterions',


    SizeCriterionAddMultipleComparables = 'Add multiple comparable data to size criterions',

    SizeCriterionChangeValue = 'Change Size Criterion Value',
    SizeCriterionValueChanged = 'Changed Size Criterion Value',

    SizeCriterionEmtpyAction = 'Size Criterion Empty Action'
}

export class CreateSizeCriterions implements Action {
    readonly type = SizeCriterionActionTypes.CreateSizeCriterions;
    constructor(public payload: {
        assetType: AssetType,
        assetClass: AssetClassModel,
        toe: ToeModel,
    }) {}
}

export class SizeCriterionsCreated implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionsCreated;
    constructor(public payload: {
        criterions: CriterionModel[],
        default: CriterionModel,
        standardMeasurements: StandardMeasurement[],
        unitAreaMeasurement: UnitAreaMeasurement
    }) {}
}

export class SizeCriterionAddComparable implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionAddComparable;
    constructor(public payload: {
        comparable: any
    }) {}
}

export class SizeCriterionComparableAdded implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionComparableAdded;
    constructor(public payload: {criterions: CriterionModel[], default: CriterionModel}) {}
}

export class SizeCriterionRemoveComparable implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionRemoveComparable;
    constructor(public payload: {
        comparableID: number
    }) {}
}

export class SizeCriterionComparableRemoved implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionComparableRemoved;
    constructor(public payload: {criterions: CriterionModel[], default: CriterionModel}) {}
}

export class SizeCriterionChangeDefault implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionChangeDefault;
    constructor(public payload: {criterion: CriterionModel}) {};

}

export class SizeCriterionDefaultChanged implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionDefaultChanged;
    constructor(public payload: {
        changedCriterions: Update<CriterionModel>[],
        default: CriterionModel
    }) {}
}

export class SizeCriterionReset implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionReset;
    constructor(public payload: {
        criterion: CriterionModel,
        assetClass: AssetClassModel,
        comparables: any[]
    }) {}
}

export class SizeCriterionResetted implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionResetted;
    constructor(public payload: {
        default: CriterionModel,
        changed: Update<CriterionModel>
    }) {}
}

export class RestartSizeCriterionState implements Action {
    readonly type = SizeCriterionActionTypes.RestartSizeCriterionState;
    constructor() {}
}

export class SizeCriterionsLoading implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionsLoading;
    constructor() {}
}

export class SizeCriterionAddMultipleComparables implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionAddMultipleComparables;
    constructor(public payload: {
        comparable: any[],
    }) {}
}

export class SizeCriterionChangeValue implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionChangeValue;
    constructor(public payload: {
        id: number;
        type: 'com' | 'tp';
        asset_id: number;
        value: number;
    }) {}
}

export class SizeCriterionValueChanged implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionValueChanged;
    constructor(public payload: {
        criterions: CriterionModel[],
        default: CriterionModel|null
    }) {}
}

export class SizeCriterionEmptyAction implements Action {
    readonly type = SizeCriterionActionTypes.SizeCriterionEmtpyAction;
}

export type SizeCriterionActions = SizeCriterionsCreated
    | SizeCriterionComparableAdded
    | SizeCriterionComparableRemoved
    | SizeCriterionDefaultChanged
    | SizeCriterionResetted
    | RestartSizeCriterionState
    | SizeCriterionsLoading
    | SizeCriterionValueChanged
    | SizeCriterionEmptyAction;