import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DraftStatementReportModel } from "../_models/draft-statement-report.model";
import { DraftStatementReportState } from "../_reducers/draft-statement-report.reducer";

export const selectDraftStatementReportState = createFeatureSelector<DraftStatementReportState>('draft-statement-report');
export const selectDraftStatementReportIsGenerating = (tpId: number) => createSelector(
	selectDraftStatementReportState,
	state => {
		return state.isGenerating[tpId];
	}
)
export const selectDraftStatementReportByTpId = (tpId: number) => createSelector(
	selectDraftStatementReportState,
	state => {
		if (!state.isAllReportsLoaded[tpId]) {
			return null;
		}
		const reports: DraftStatementReportModel[] = [];
		for (const key in state.entities) {
			const val = state.entities[key];
			if (val.tp_id === tpId) {
				reports.push(val);
			}
		}
        reports.sort((a, b) => {
            const d1 = new Date(b.created_at);
            const d2 = new Date(a.created_at);
            return d1.getTime() - d2.getTime();
        })


        return reports.length > 0 ? [reports[0]] : [];
	}
)