import {BaseModel} from '../../_base/crud';

export class AppendixCategory extends BaseModel {
    id: number;
    name: string;
    appendix_cnt: number;
    relation_cnt: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    user_deleted: Date;

    deletedBy: string;
    userDeletedBy: string;

    createdBy: string;
    lastUpdatedBy: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.relation_cnt = 0;
        this.appendix_cnt = 0;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
        this.user_deleted = null;

        this.deletedBy = '';
        this.userDeletedBy = '';
    }
}
