<div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline">
    <a ngbDropdownToggle href="javascript:;" class="btn btn btn-label-brand btn-bold btn-sm dropdown-toggle" style="cursor: pointer !important;">
        {{menuName}}
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg-right" [style.width.px]="width">
        <ul class="navi navi-hover">
            <!--<li class="navi-header font-weight-bold">-->
                <!--<span>Choose an action:</span>-->
            <!--</li>-->
            <li class="navi-item"
                *ngFor="let item of menuItems"
                [matTooltip]="item.disabled ? 'Disabled' : ''">

                <a [class]="item.disabled ? 'navi-link disabled' : 'navi-link'"
                   style="cursor: pointer;"
                   (click)="clickAction(item)">
                    <span *ngIf="item.icon" class="navi-icon">
                        <i [class]="item.icon"></i>
                    </span>
                    <span class="navi-text">{{ item.title }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>