<div class="card">
    <div class="card-header info-header">
        <span>Available Documents</span>
    </div>
    <div class="card-body info-body">
        <table class="table info-table" *ngIf="data$|async as data">
            <thead>
                <th class="info-table__name">Name</th>
                <th class="info-table__type">Type</th>
                <th class="info-table__uploaded">Uploaded</th>
                <th class="info-table__by">By</th>
                <th class="info-table__view">View</th>
            </thead>
            <tbody>
                <tr *ngFor="let doc of data.documents; let i = index" [ngClass]="{
                    'white': i%2 == 0, 
                    'whiter-green': i%2 != 0,
                    'info-border-bottom': i == data.lastBorder - 1 || i == data.lastBorderDefault - 1}">
                        <td>{{doc.name}}</td>
                        <td>{{doc.type}}</td>
                        <td>{{doc.uploaded  | date: 'dd MMM yyy'}}</td>
                        <td><span 
                            [ngbPopover]="popContent"
                            [popoverTitle]="doc.uploadedBy"
                            triggers="manual"
                            placement="right"
                            class="clickable"
                            container="body"
                            #pV="ngbPopover" (click)="showUserInfo($event, pV, doc.uploadedUser)">
                                {{doc.uploadedBy}}
                        </span>
                        </td>
                        <td>
                            <span style="cursor: pointer;" (click)="showDocument(doc.url)">
                                <mat-icon >visibility</mat-icon>
                            </span>
                        </td>
                </tr>
                <tr *ngIf="data.documents.length == 0">
                    <td colspan="5" style="text-align: center;">
                        No Available Documents
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #popContent 
    let-role="role" let-qualification="qualification"
    let-email="email" let-agency="agency" let-user_pic="user_pic" >
    <div style="display: flex; align-items: center;">
        <div class="image_container mr-3">
            <img [src]="user_pic" (error)="imgOnError($event)">
        </div>
        <div>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Role:</span> {{role}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Qualification:</span> {{qualification}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Email:</span> {{email}}</p>
            <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Agency:</span> {{ agency}}</p>
        </div>
    </div>
</ng-template>