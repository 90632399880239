// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
import {each} from 'lodash';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassResidentialsState} from '../_reducers/asset-class-residential.reducers';
import {AssetClassResidentialModel} from '../_models/asset-class-residential.model';

export const selectAssetClassResidentialsState = createFeatureSelector<AssetClassResidentialsState>('assetClassResidentials');

export const selectAssetClassResidentialById = (assetClassResidentialId: number) => createSelector(
    selectAssetClassResidentialsState,
    assetClassResidentialsState => assetClassResidentialsState.entities[assetClassResidentialId]
);

export const selectAssetClassResidentialsPageLoading = createSelector(
    selectAssetClassResidentialsState,
    assetClassResidentialsState => assetClassResidentialsState.listLoading
);

export const selectAssetClassResidentialsActionLoading = createSelector(
    selectAssetClassResidentialsState,
    assetClassResidentialsState => assetClassResidentialsState.actionLoading
);

export const selectLastCreatedAssetClassResidentialId = createSelector(
    selectAssetClassResidentialsState,
    assetClassResidentialsState => {
        if (assetClassResidentialsState.previouslyCreatedAssetClassResidentialId != assetClassResidentialsState.lastCreatedAssetClassResidentialId) {
            return assetClassResidentialsState.lastCreatedAssetClassResidentialId
        }
        return undefined 
    }
);

export const selectAssetClassResidentialsShowInitWaitingMessage = createSelector(
    selectAssetClassResidentialsState,
    state => state.showInitWaitingMessage
);


export const selectTrashedAssetClassResidentialCount = createSelector(
    selectAssetClassResidentialsState,
    (state) => state.totalTrashed
);

export const selectAdminTrashedAssetClassResidentialCount = createSelector(
    selectAssetClassResidentialsState,
    (state) => state.totalAdminTrashed
);

export const selectAssetClassResidentialsLastQuery = createSelector(
    selectAssetClassResidentialsState,
    state => state.lastQuery
)

export const selectAssetClassResidentialsInStore = createSelector(
    selectAssetClassResidentialsState,
    assetClassResidentialsState => {
        const items: AssetClassResidentialModel[] = [];
        each(assetClassResidentialsState.entities, element => {
            if (assetClassResidentialsState.lastQuery.filter
                && assetClassResidentialsState.lastQuery.filter.pic_cnt
                && assetClassResidentialsState.lastQuery.filter.pic_cnt.length > 0) {

                const _tmp = assetClassResidentialsState.lastQuery.filter.pic_cnt.split('-');
                if (element.ac_pictures.length >= _tmp[0] && element.ac_pictures.length <= _tmp[1]) {
                    items.push(element);
                }
            } else {
                items.push(element);
            }
        });
        const httpExtension = new HttpExtenstionsModel();
        let result: AssetClassResidentialModel[] = [];
        switch (assetClassResidentialsState.lastQuery.sortField) {
            case 'residential_type_name':
                result = httpExtension.sortArrayString(
                    items,
                    assetClassResidentialsState.lastQuery.sortField,
                    assetClassResidentialsState.lastQuery.sortOrder
                );
                break;
            case 'source.source_date':
                result = httpExtension.sortArrayDateTime(
                    items,
                    assetClassResidentialsState.lastQuery.sortField,
                    assetClassResidentialsState.lastQuery.sortOrder
                );
                break;
            case 'building_information.building_name':
                result = httpExtension.sortArrayString(
                    items,
                    assetClassResidentialsState.lastQuery.sortField,
                    assetClassResidentialsState.lastQuery.sortOrder
                );
                break;
            case 'updated_at':
                result = httpExtension.sortArrayDateTime(
                    items,
                    assetClassResidentialsState.lastQuery.sortField,
                    assetClassResidentialsState.lastQuery.sortOrder
                );
                break;

            case 'size':
                result = httpExtension.sortArrayNumber(
                    items,
                    'sizeInMeter',
                    assetClassResidentialsState.lastQuery.sortOrder
                );
                break;

            default:
                result = httpExtension.sortArray(
                    items,
                    assetClassResidentialsState.lastQuery.sortField,
                    assetClassResidentialsState.lastQuery.sortOrder
                );
        }
        return new QueryResultsModel(result, assetClassResidentialsState.totalCount);
    }
);
