import { createAction, props } from "@ngrx/store"
import { ValuationComparableFilter } from "src/app/core/valuation-process/_actions/valuation-comparable.actions"
import { ValuationComparableMapData } from "src/app/core/valuation-process/_models"
import { VComparable } from "../types/asset-class/comparable"
import { HeadlineToEffectiveMeta } from "../types"

export const loadComparables = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Load Comparables',
  props<{ 
    filter: ValuationComparableFilter
  }>()
)

export const comparablesLoaded = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Comparables Loaded',
  props<{
    items: ValuationComparableMapData[]
  }>()
)

export const loadFullComparables = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Load Full Comparables',
  props<{
    refNums: string[]
  }>()
)

export const fullComparablesLoaded = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Full Comparables Loaded',
  props<{
    items: VComparable[]
  }>()
)

export const addComparable = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Add Comparable',
  props<{
    refNum: string,
  }>()
)

export const removeComparable = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Remove Comparable',
  props<{
    refNum: string
  }>()
)

export const selectConsideration = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Select consideration',
  props<{
    refNum: string,
    id: number
  }>()
)

export const setComparableHeadlineToEffective = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Set Comparable Headline to Effective',
  props<{
    refNum: string,
    meta: HeadlineToEffectiveMeta
  }>()
)

export const addedComparableRequested = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Added Comparable Requested',
  props<{refNum: string}>()
)

export const addedComparableLoaded = createAction(
  '[Valuation Process Page - V2 / Market Data Tab] Added Comparable Loaded',
  props<{item: VComparable}>()
)