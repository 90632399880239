import {AssetClassResidentialFacilityModel} from '../_models/asset-class-residential-facility.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {AssetClassResidentialFacilitiesState} from '../_reducers/asset-class-residential-facility.reducers';
import * as fromAssetClassResidentialFacility from '../_reducers/asset-class-residential-facility.reducers';
import {each} from 'lodash';

export const selectAssetClassResidentialFacilitiesState = createFeatureSelector<AssetClassResidentialFacilitiesState>('assetClassResidentialFacilities');

export const selectAllAssetClassResidentialFacilities = createSelector(
    selectAssetClassResidentialFacilitiesState,
    fromAssetClassResidentialFacility.selectAll
);

export const selectAssetClassResidentialFacilityQueryResult = createSelector(
    selectAssetClassResidentialFacilitiesState,
    assetClassResidentialFacilitiesState => {
        const items: AssetClassResidentialFacilityModel[] = [];
        each(assetClassResidentialFacilitiesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AssetClassResidentialFacilityModel[] = httpExtension.sortArray(items, assetClassResidentialFacilitiesState.lastQuery.sortField, assetClassResidentialFacilitiesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, assetClassResidentialFacilitiesState.queryRowsCount, '');
    }
);
