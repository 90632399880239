// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// State
import {CurrenciesState} from '../_reducers/currency.reducers';
import * as fromCurrencies from '../_reducers/currency.reducers';

export const selectCurrenciesState = createFeatureSelector<CurrenciesState>('currencies');

export const selectCurrencyById = (currencyId: number) => createSelector(
    selectCurrenciesState,
    cs => cs.entities[currencyId]
);

export const selectAllCurrencies = createSelector(
    selectCurrenciesState,
    fromCurrencies.selectAll
);

export const selectCurrencies = createSelector(
    selectAllCurrencies,
    (currencies) => currencies.filter(currency => currency.deleted_at == null)
);

export const selectTrashedCurrencies = createSelector(
    selectAllCurrencies,
    (currencies) => currencies.filter(currency => currency.deleted_at)
);

export const selectAllCurrenciesIds = createSelector(
    selectCurrenciesState,
    fromCurrencies.selectIds
);

export const allCurrenciesLoaded = createSelector(
    selectCurrenciesState,
    cs => cs._isAllCurrenciesLoaded
);


export const selectLastCreatedCurrencyId = createSelector(
    selectCurrenciesState,
    currenciesState => currenciesState.lastCreatedCurrencyId
);

export const selectCurrenciesShowInitWaitingMessage = createSelector(
    selectCurrenciesState,
    currenciesState => currenciesState.showInitWaitingMessage
);

export const selectTrashedCurrencyCount = createSelector(
    selectCurrenciesState,
    (currenciesState) => currenciesState.totalTrashed
);

export const selectAdminTrashedCurrencyCount = createSelector(
    selectCurrenciesState,
    (currenciesState) => currenciesState.totalAdminTrashed
);
