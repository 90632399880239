// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {PremiseOfValuesService} from '../_services/premise-of-value.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allPremiseOfValuesLoaded} from '../_selectors/premise-of-value.selectors';
// Actions
import {
    AllPremiseOfValuesLoaded,
    AllPremiseOfValuesRequested,
    PremiseOfValueActionTypes,
    PremiseOfValuesPageRequested,
    PremiseOfValuesPageLoaded,
    PremiseOfValueUpdated,
    PremiseOfValuesPageToggleLoading,
    PremiseOfValueDeleted,
    PremiseOfValueOnServerCreated,
    PremiseOfValueCreated,
    PremiseOfValueAdminRestored,
    PremiseOfValueDeletedFromAdminTrash,
    PremiseOfValueDeletedFromTrash,
    PremiseOfValueOnServerAdminRestored,
    PremiseOfValueOnServerRestored,
    PremiseOfValueRestored,
    PremiseOfValueTrashFlushed,
    PremiseOfValuesActionToggleLoading
} from '../_actions/premise-of-value.actions';


@Injectable()
export class PremiseOfValueEffects {
    showPageLoadingDispatcher = new PremiseOfValuesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new PremiseOfValuesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new PremiseOfValuesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllPremiseOfValue$ = this.actions$
        .pipe(
            ofType<AllPremiseOfValuesRequested>(PremiseOfValueActionTypes.AllPremiseOfValuesRequested),
            withLatestFrom(this.store.pipe(select(allPremiseOfValuesLoaded))),
            filter(([action, isAllPremiseOfValueLoaded]) => !isAllPremiseOfValueLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllPremiseOfValuesLoaded({premiseOfValues: res.data});
            })
        );

    @Effect()
    loadPremiseOfValuePage$ = this.actions$
        .pipe(
            ofType<PremiseOfValuesPageRequested>(PremiseOfValueActionTypes.PremiseOfValuesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.fetch(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PremiseOfValuesPageLoaded({
                    premiseOfValues: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );


    @Effect()
    updatePremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueUpdated>(PremiseOfValueActionTypes.PremiseOfValueUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createPremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueOnServerCreated>(PremiseOfValueActionTypes.PremiseOfValueOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.premiseOfValue).pipe(
                    tap(res => {
                        this.store.dispatch(new PremiseOfValueCreated({premiseOfValue: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushPremiseOfValueTrash$ = this.actions$
        .pipe(
            ofType<PremiseOfValueTrashFlushed>(PremiseOfValueActionTypes.PremiseOfValueTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restorePremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueOnServerRestored>(PremiseOfValueActionTypes.PremiseOfValueOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PremiseOfValueRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminPremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueOnServerAdminRestored>(PremiseOfValueActionTypes.PremiseOfValueOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new PremiseOfValueAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deletePremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueDeleted>(PremiseOfValueActionTypes.PremiseOfValueDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashPremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueDeletedFromTrash>(PremiseOfValueActionTypes.PremiseOfValueDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashPremiseOfValue$ = this.actions$
        .pipe(
            ofType<PremiseOfValueDeletedFromAdminTrash>(PremiseOfValueActionTypes.PremiseOfValueDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: PremiseOfValuesService, private store: Store<AppState>) {
    }
}
