// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../../core/reducers';
// Selectirs
import {selectQueryResult, selectClientsPageLoading, selectClientsShowInitWaitingMessage} from '../_selectors/client.selectors';

export class ClientsDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectClientsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectClientsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.trashed = response.trashed;
            this.entitySubject.next(response.items);
        });

    }
}
