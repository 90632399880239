import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { SubTypeCategoryModel } from "../_models/sub-type-category.model";

export enum SubTypeCategoryActionTypes {
    AllSubTypeCategorysRequested = '[Linked Area] All SubTypeCategory Requested',
    AllSubTypeCategorysLoaded = '[SubTypeCategory Effect] All SubTypeCategory Loaded',

    SubTypeCategoryOnServerCreated = '[Add SubTypeCategory Page] SubTypeCategory On Server Created',
    SubTypeCategoryCreated = '[SubTypeCategory Effect] SubTypeCategory Created',
    SubTypeCategoryUpdated = '[SubTypeCategory Effect] SubTypeCategory Updated',
    SubTypeCategoryDeleted = '[SubTypeCategory List Page] SubTypeCategory Deleted',

    SubTypeCategoryOnServerRestored = '[SubTypeCategory Trash] SubTypeCategory On Server Restored',
    SubTypeCategoryRestored = '[SubTypeCategory Trash] SubTypeCategory Restored',

    SubTypeCategoryOnServerAdminRestored = '[SubTypeCategory Admin Trash] SubTypeCategory On Server Restored',
    SubTypeCategoryAdminRestored = '[SubTypeCategory Admin Trash] SubTypeCategory Restored',

    SubTypeCategoryDeletedFromTrash = '[SubTypeCategory Trash] SubTypeCategory deleted',
    SubTypeCategoryDeletedFromAdminTrash = '[SubTypeCategory Admin Trash] SubTypeCategory deleted',

    SubTypeCategoryTrash = 'SubTypeCategory Trashed',
    SubTypeCategoryTrashFlushed = 'SubTypeCategory Trash Flushed',

    SubTypeCategorysPageRequested = '[SubTypeCategory List Page] SubTypeCategory Page Requested',
    SubTypeCategorysPageLoaded = '[SubTypeCategory API] SubTypeCategory Page Loaded',
    SubTypeCategorysPageCancelled = '[SubTypeCategory API] SubTypeCategory Page Cancelled',

    SubTypeCategorysPageToggleLoading = '[SubTypeCategory page] SubTypeCategory Page Toggle Loading',
    SubTypeCategoryActionToggleLoading = '[SubTypeCategory] SubTypeCategory Action Toggle Loading'
}

export class SubTypeCategoryOnServerCreated implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryOnServerCreated;

    constructor(public payload: { item: SubTypeCategoryModel }) {
    }
}

export class SubTypeCategoryCreated implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryCreated;

    constructor(public payload: { item: SubTypeCategoryModel }) {
    }
}

export class SubTypeCategoryUpdated implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryUpdated;

    constructor(public payload: {
        partialItem: Update<SubTypeCategoryModel>,
        item: SubTypeCategoryModel
    }) {
    }
}

export class SubTypeCategoryDeleted implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class SubTypeCategorysPageRequested implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategorysPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class SubTypeCategorysPageLoaded implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategorysPageLoaded;

    constructor(public payload: { items: SubTypeCategoryModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class SubTypeCategorysPageCancelled implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategorysPageCancelled;
}

export class AllSubTypeCategorysRequested implements Action {
    readonly type = SubTypeCategoryActionTypes.AllSubTypeCategorysRequested;
}

export class AllSubTypeCategorysLoaded implements Action {
    readonly type = SubTypeCategoryActionTypes.AllSubTypeCategorysLoaded;

    constructor(public payload: { items: SubTypeCategoryModel[] }) {
    }
}

export class SubTypeCategorysPageToggleLoading implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategorysPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class SubTypeCategoryActionToggleLoading implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class SubTypeCategoryDeletedFromAdminTrash implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SubTypeCategoryDeletedFromTrash implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class SubTypeCategoryOnServerRestored implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SubTypeCategoryRestored implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryRestored;

    constructor(public payload: { item: SubTypeCategoryModel }) {
    }
}

export class SubTypeCategoryOnServerAdminRestored implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class SubTypeCategoryAdminRestored implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryAdminRestored;

    constructor(public payload: { item: SubTypeCategoryModel }) {
    }
}

export class SubTypeCategoryTrashFlushed implements Action {
    readonly type = SubTypeCategoryActionTypes.SubTypeCategoryTrashFlushed;

    constructor() {
    }
}


export type SubTypeCategoryActions = SubTypeCategoryCreated
    | SubTypeCategoryUpdated
    | SubTypeCategoryDeleted
    | SubTypeCategorysPageRequested
    | SubTypeCategorysPageLoaded
    | SubTypeCategorysPageCancelled
    | AllSubTypeCategorysLoaded
    | AllSubTypeCategorysRequested
    | SubTypeCategoryOnServerCreated
    | SubTypeCategoryDeletedFromAdminTrash
    | SubTypeCategoryDeletedFromTrash
    | SubTypeCategoryOnServerRestored
    | SubTypeCategoryRestored
    | SubTypeCategoryOnServerAdminRestored
    | SubTypeCategoryAdminRestored
    | SubTypeCategoryTrashFlushed
    | SubTypeCategorysPageToggleLoading
    | SubTypeCategoryActionToggleLoading;