import {OfficeStandardMeasurement} from '../_models/office-standard-measurement.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {OfficeStandardMeasurementsState} from '../_reducers/office-standard-measurement.reducers';
import * as fromOfficeStandardMeasurement from '../_reducers/office-standard-measurement.reducers';
import {each} from 'lodash';

export const selectOfficeStandardMeasurementsState = createFeatureSelector<OfficeStandardMeasurementsState>('officeStandardMeasurements');

export const selectOfficeStandardMeasurementById = (officeStandardMeasurementId: number) => createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => officeStandardMeasurementsState.entities[officeStandardMeasurementId]
);

export const selectAllOfficeStandardMeasurements = createSelector(
    selectOfficeStandardMeasurementsState,
    fromOfficeStandardMeasurement.selectAll
);

export const selectAllOfficeStandardMeasurementsIds = createSelector(
    selectOfficeStandardMeasurementsState,
    fromOfficeStandardMeasurement.selectIds
);

export const allOfficeStandardMeasurementsLoaded = createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => officeStandardMeasurementsState.isAllOfficeStandardMeasurementsLoaded
);


export const selectOfficeStandardMeasurementsPageLoading = createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => officeStandardMeasurementsState.listLoading
);

export const selectOfficeStandardMeasurementsActionLoading = createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => officeStandardMeasurementsState.actionLoading
);

export const selectLastCreatedOfficeStandardMeasurementId = createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => officeStandardMeasurementsState.lastCreatedOfficeStandardMeasurementId
);

export const selectOfficeStandardMeasurementsShowInitWaitingMessage = createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => officeStandardMeasurementsState.showInitWaitingMessage
);


export const selectTrashedOfficeStandardMeasurementCount = createSelector(
    selectOfficeStandardMeasurementsState,
    (officeStandardMeasurementsState) => officeStandardMeasurementsState.totalTrashed
);

export const selectAdminTrashedOfficeStandardMeasurementCount = createSelector(
    selectOfficeStandardMeasurementsState,
    (officeStandardMeasurementsState) => officeStandardMeasurementsState.totalAdminTrashed
);


export const selectOfficeStandardMeasurementQueryResult = createSelector(
    selectOfficeStandardMeasurementsState,
    officeStandardMeasurementsState => {
        const items: OfficeStandardMeasurement[] = [];
        each(officeStandardMeasurementsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OfficeStandardMeasurement[] = httpExtension.sortArray(items, officeStandardMeasurementsState.lastQuery.sortField, officeStandardMeasurementsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, officeStandardMeasurementsState.totalCount, '', officeStandardMeasurementsState.totalTrashed);
    }
);
