<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h4 class="card-label">Manual</h4>
		</div>
		<div class="card-toolbar">
			<button mat-button mat-raised-button color="primary" (click)="save()" class="mr-2">
				Save
			</button>
			<button mat-button mat-raised-button color="warn" (click)="close()" aria-label="Close"
				style="background-color: orange; color: white;">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
	<div class="card-body pt-0 w-100">
		<div class="form" [ngClass]="{'form-disabled': readonly}">
			<form [formGroup]="form">
				<div class="form-group row align-items-center">
					<div class="col-4">
						<mat-checkbox formControlName="completed">Completed</mat-checkbox>
					</div>
					<div class="col-8">
						<mat-form-field>
							<span (click)="amendmentDatePicker.open()">
								<input matInput [matDatepicker]="amendmentDatePicker" readonly 
									placeholder="Completed Date"
									formControlName="completed_at">
							</span>
							<mat-error>Completed Date is <strong>required</strong></mat-error>
							<mat-datepicker-toggle matSuffix [for]="amendmentDatePicker"></mat-datepicker-toggle>
							<button mat-button *ngIf="form.controls.amendment_date" matSuffix mat-icon-button matTooltip="Clear"
								(click)="clearDate(form.controls.amendment_date)">
								<mat-icon>close</mat-icon> 
							</button>
							<mat-datepicker #amendmentDatePicker></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>