import {TaskEffort} from '../_models/task-effort.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {TaskEffortsState} from '../_reducers/task-effort.reducers';
import * as fromTaskEffort from '../_reducers/task-effort.reducers';
import {each} from 'lodash';

export const selectTaskEffortsState = createFeatureSelector<TaskEffortsState>('taskEfforts');

export const selectTaskEffortById = (taskEffortId: number) => createSelector(
    selectTaskEffortsState,
    taskEffortsState => taskEffortsState.entities[taskEffortId]
);

export const selectAllTaskEfforts = createSelector(
    selectTaskEffortsState,
    fromTaskEffort.selectAll
);

export const selectAllTaskEffortsIds = createSelector(
    selectTaskEffortsState,
    fromTaskEffort.selectIds
);

export const allTaskEffortsLoaded = createSelector(
    selectTaskEffortsState,
    taskEffortsState => taskEffortsState.isAllTaskEffortsLoaded
);


export const selectTaskEffortsPageLoading = createSelector(
    selectTaskEffortsState,
    taskEffortsState => taskEffortsState.listLoading
);

export const selectTaskEffortsActionLoading = createSelector(
    selectTaskEffortsState,
    taskEffortsState => taskEffortsState.actionLoading
);

export const selectLastCreatedTaskEffortId = createSelector(
    selectTaskEffortsState,
    taskEffortsState => taskEffortsState.lastCreatedTaskEffortId
);

export const selectTaskEffortsShowInitWaitingMessage = createSelector(
    selectTaskEffortsState,
    taskEffortsState => taskEffortsState.showInitWaitingMessage
);

export const selectTrashedTaskEffortCount = createSelector(
    selectTaskEffortsState,
    (taskEffortsState) => taskEffortsState.totalTrashed
);

export const selectAdminTrashedTaskEffortCount = createSelector(
    selectTaskEffortsState,
    (taskEffortsState) => taskEffortsState.totalAdminTrashed
);


export const selectTaskEffortQueryResult = createSelector(
    selectTaskEffortsState,
    taskEffortsState => {
        const items: TaskEffort[] = [];
        each(taskEffortsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: TaskEffort[] = httpExtension.sortArray(items, taskEffortsState.lastQuery.sortField, taskEffortsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, taskEffortsState.totalCount, '', taskEffortsState.totalTrashed);
    }
);
