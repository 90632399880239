// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectFoundationTypeQueryResult, selectFoundationTypesPageLoading, selectFoundationTypesShowInitWaitingMessage} from '../_selectors/foundation-type.selectors';

export class FoundationTypesDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectFoundationTypesPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectFoundationTypesShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectFoundationTypeQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
