import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { ToeAuditTaskReminderActions, ToeAuditTaskReminderActionTypes } from "../_actions/toe-audit-task-reminder.actions";
import { ToeAuditTaskReminderModel } from "../_models/toe-audit-task-reminder.model";

export interface ToeAuditTaskReminderState extends EntityState<ToeAuditTaskReminderModel> {}
export const adapter = createEntityAdapter<ToeAuditTaskReminderModel>()
export const initialState = adapter.getInitialState()

export function toeAuditTrailTaskReminderReducer(state = initialState, action: ToeAuditTaskReminderActions): ToeAuditTaskReminderState {
    switch (action.type) {
        case ToeAuditTaskReminderActionTypes.ToeAuditTaskRemindersLoaded: {
            return adapter.setAll(action.payload.reminders, {...state})
        }
        case ToeAuditTaskReminderActionTypes.ToeAuditTaskReminderCreated: {
            return adapter.addOne(action.payload.reminder, {...state})
        }
        case ToeAuditTaskReminderActionTypes.ToeAuditTaskReminderUpdated: {
            return adapter.updateOne(action.payload.reminder, {...state})
        }
        case ToeAuditTaskReminderActionTypes.ToeAuditTaskReminderDeleted: {
            return adapter.removeOne(action.payload.id, {...state})
        }
        default:
            return state
    }
}