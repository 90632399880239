import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { QueryParamsModel } from "../../_base/crud";
import { ExternalFacadeModel } from "../_models/external-facade.model";

export enum ExternalFacadeActionTypes {
    AllExternalFacadesRequested = '[Linked Area] All ExternalFacade Requested',
    AllExternalFacadesLoaded = '[ExternalFacade Effect] All ExternalFacade Loaded',

    ExternalFacadeOnServerCreated = '[Add ExternalFacade Page] ExternalFacade On Server Created',
    ExternalFacadeCreated = '[ExternalFacade Effect] ExternalFacade Created',
    ExternalFacadeUpdated = '[ExternalFacade Effect] ExternalFacade Updated',
    ExternalFacadeDeleted = '[ExternalFacade List Page] ExternalFacade Deleted',

    ExternalFacadeOnServerRestored = '[ExternalFacade Trash] ExternalFacade On Server Restored',
    ExternalFacadeRestored = '[ExternalFacade Trash] ExternalFacade Restored',

    ExternalFacadeOnServerAdminRestored = '[ExternalFacade Admin Trash] ExternalFacade On Server Restored',
    ExternalFacadeAdminRestored = '[ExternalFacade Admin Trash] ExternalFacade Restored',

    ExternalFacadeDeletedFromTrash = '[ExternalFacade Trash] ExternalFacade deleted',
    ExternalFacadeDeletedFromAdminTrash = '[ExternalFacade Admin Trash] ExternalFacade deleted',

    ExternalFacadeTrash = 'ExternalFacade Trashed',
    ExternalFacadeTrashFlushed = 'ExternalFacade Trash Flushed',

    ExternalFacadesPageRequested = '[ExternalFacade List Page] ExternalFacade Page Requested',
    ExternalFacadesPageLoaded = '[ExternalFacade API] ExternalFacade Page Loaded',
    ExternalFacadesPageCancelled = '[ExternalFacade API] ExternalFacade Page Cancelled',

    ExternalFacadesPageToggleLoading = '[ExternalFacade page] ExternalFacade Page Toggle Loading',
    ExternalFacadeActionToggleLoading = '[ExternalFacade] ExternalFacade Action Toggle Loading'
}

export class ExternalFacadeOnServerCreated implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeOnServerCreated;

    constructor(public payload: { item: ExternalFacadeModel }) {
    }
}

export class ExternalFacadeCreated implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeCreated;

    constructor(public payload: { item: ExternalFacadeModel }) {
    }
}

export class ExternalFacadeUpdated implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeUpdated;

    constructor(public payload: {
        partialItem: Update<ExternalFacadeModel>,
        item: ExternalFacadeModel
    }) {
    }
}

export class ExternalFacadeDeleted implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalFacadesPageRequested implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ExternalFacadesPageLoaded implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadesPageLoaded;

    constructor(public payload: { items: ExternalFacadeModel[], totalCount: number, totalTrashed: number, totalAdminTrashed: number, page: QueryParamsModel }) {
    }
}

export class ExternalFacadesPageCancelled implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadesPageCancelled;
}

export class AllExternalFacadesRequested implements Action {
    readonly type = ExternalFacadeActionTypes.AllExternalFacadesRequested;
}

export class AllExternalFacadesLoaded implements Action {
    readonly type = ExternalFacadeActionTypes.AllExternalFacadesLoaded;

    constructor(public payload: { items: ExternalFacadeModel[] }) {
    }
}

export class ExternalFacadesPageToggleLoading implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ExternalFacadeActionToggleLoading implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class ExternalFacadeDeletedFromAdminTrash implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalFacadeDeletedFromTrash implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalFacadeOnServerRestored implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalFacadeRestored implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeRestored;

    constructor(public payload: { item: ExternalFacadeModel }) {
    }
}

export class ExternalFacadeOnServerAdminRestored implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class ExternalFacadeAdminRestored implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeAdminRestored;

    constructor(public payload: { item: ExternalFacadeModel }) {
    }
}

export class ExternalFacadeTrashFlushed implements Action {
    readonly type = ExternalFacadeActionTypes.ExternalFacadeTrashFlushed;

    constructor() {
    }
}


export type ExternalFacadeActions = ExternalFacadeCreated
    | ExternalFacadeUpdated
    | ExternalFacadeDeleted
    | ExternalFacadesPageRequested
    | ExternalFacadesPageLoaded
    | ExternalFacadesPageCancelled
    | AllExternalFacadesLoaded
    | AllExternalFacadesRequested
    | ExternalFacadeOnServerCreated
    | ExternalFacadeDeletedFromAdminTrash
    | ExternalFacadeDeletedFromTrash
    | ExternalFacadeOnServerRestored
    | ExternalFacadeRestored
    | ExternalFacadeOnServerAdminRestored
    | ExternalFacadeAdminRestored
    | ExternalFacadeTrashFlushed
    | ExternalFacadesPageToggleLoading
    | ExternalFacadeActionToggleLoading;