import {BaseModel} from '../../_base/crud';

export class AssetClassSizeModel extends BaseModel {
    id: number;
    uid: string;
    ac_id: number;
    ac_type_id: number;
    standard_measurement_id: number;
    standard_measurement_name: string;
    unit_of_area_measurement_id: number;
    unit_of_area_measurement_name: string;
    size: number;
    size_f: number;
    size_m: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;
        this.uid = undefined;
        this.ac_id = undefined;
        this.ac_type_id = undefined;
        this.standard_measurement_id = undefined;
        this.standard_measurement_name = '';
        this.unit_of_area_measurement_id = undefined;
        this.unit_of_area_measurement_name = '';
        this.size = undefined;
        this.size_f = undefined;
        this.size_m = undefined;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
