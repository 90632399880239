import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AssetClassValuationGroup} from '../_models/asset-class-valuation-group.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class AssetClassValuationGroupsService {
    API_INTERACTION_TYPE_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/asset-class-valuation-groups`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // AssetClassValuationGroups
    getAllAssetClassValuationGroups(): Observable<any> {
        return this.http.get<any>(this.API_INTERACTION_TYPE_URL, {headers: this.authService.getAuthHeaders()});
    }


    findAssetClassValuationGroups(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = this.API_INTERACTION_TYPE_URL;
		return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
    }

    getAssetClassValuationGroupById(assetClassValuationGroupId: number): Observable<AssetClassValuationGroup> {
        return this.http.get<AssetClassValuationGroup>(this.API_INTERACTION_TYPE_URL + `/${assetClassValuationGroupId}`, {headers: this.authService.getAuthHeaders()});
    }

    // CREATE =>  POST: add a new assetClassValuationGroup to the server
    createAssetClassValuationGroup(assetClassValuationGroup: AssetClassValuationGroup): Observable<any> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const body = {assetClassValuationGroup: assetClassValuationGroup};

        return this.http.post(this.API_INTERACTION_TYPE_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the assetClassValuationGroup on the server
    updateAssetClassValuationGroup(assetClassValuationGroup: AssetClassValuationGroup): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_INTERACTION_TYPE_URL + `/${assetClassValuationGroup.id}`, assetClassValuationGroup, {headers: httpHeaders});
    }

    // DELETE => delete the assetClassValuationGroup from the server
    deleteAssetClassValuationGroup(assetClassValuationGroupId: number): Observable<any> {
        const url = `${this.API_INTERACTION_TYPE_URL}/${assetClassValuationGroupId}`;
        return this.http.delete(url, {headers: this.authService.getAuthHeaders()});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
