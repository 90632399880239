import {TopPropertyTypeModel} from '../_models/top-property-type.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {TopPropertyTypesState} from '../_reducers/top-property-type.reducers';
import * as fromTopPropertyType from '../_reducers/top-property-type.reducers';
import {each} from 'lodash';

export const selectTopPropertyTypesState = createFeatureSelector<TopPropertyTypesState>('topPropertyTypes');

export const selectTopPropertyTypeById = (topPropertyTypeId: number) => createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => topPropertyTypesState.entities[topPropertyTypeId]
);

export const selectAllTopPropertyTypes = createSelector(
    selectTopPropertyTypesState,
    fromTopPropertyType.selectAll
);

export const selectAllTopPropertyTypesIds = createSelector(
    selectTopPropertyTypesState,
    fromTopPropertyType.selectIds
);

export const allTopPropertyTypesLoaded = createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => topPropertyTypesState.isAllTopPropertyTypesLoaded
);


export const selectTopPropertyTypesPageLoading = createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => topPropertyTypesState.listLoading
);

export const selectTopPropertyTypesActionLoading = createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => topPropertyTypesState.actionLoading
);

export const selectLastCreatedTopPropertyTypeId = createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => topPropertyTypesState.lastCreatedTopPropertyTypeId
);

export const selectTopPropertyTypesShowInitWaitingMessage = createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => topPropertyTypesState.showInitWaitingMessage
);


export const selectTrashedTopPropertyTypeCount = createSelector(
    selectTopPropertyTypesState,
    (topPropertyTypesState) => topPropertyTypesState.totalTrashed
);

export const selectAdminTrashedTopPropertyTypeCount = createSelector(
    selectTopPropertyTypesState,
    (topPropertyTypesState) => topPropertyTypesState.totalAdminTrashed
);


export const selectTopPropertyTypeQueryResult = createSelector(
    selectTopPropertyTypesState,
    topPropertyTypesState => {
        const items: TopPropertyTypeModel[] = [];
        each(topPropertyTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: TopPropertyTypeModel[] = httpExtension.sortArray(items, topPropertyTypesState.lastQuery.sortField, topPropertyTypesState.lastQuery.sortOrder);

        return new QueryResultsModel(result, topPropertyTypesState.totalCount, '', topPropertyTypesState.totalTrashed);
    }
);
