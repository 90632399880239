import { Component, ElementRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapModalComponent } from '../../valuation-process-edit/_sub/background-tab/map-modal/map-modal.component';
import { each } from 'lodash';
import { SizeTableModalComponent } from '../../valuation-process-edit/_sub/background-tab/size-modal/size-modal.component';
import { StandardMeasurement, UnitAreaMeasurement } from 'src/app/core/linked-tables';
import { of } from 'rxjs';

type BaseViewModel = {
  targetPropertyId: number
  propertyType: string
  propertySubType: string
  address: string
  latitude: number
  longitude: number
}
type Size = {
  value: number,
  unitAreaMeasurement: string,
  isClickable: boolean ,
  unitAreaMeasurementModel: UnitAreaMeasurement
}

type ApartmentViewModel = BaseViewModel & {
  kind: 'Apartment'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  bedroom: number,
  floorNumberinSchemeId: number,
  selectedExternalAreas: any,
  buildingType: string,
  buildingGrade: string
}

type HouseViewModel = BaseViewModel & {
  kind: 'House'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  floorNumberinSchemeId: number,
  propertyGrade: string
}

type OfficeViewModel = BaseViewModel & {
  kind: 'Office'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  floorNumberinSchemeId: number,
  selectedExternalAreas: any,
  buildingType: string,
  buildingGrade: string
}

type RetailShopViewModel = BaseViewModel & {
  kind: 'Retail Shop'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  floorNumberinSchemeId: number,
  selectedExternalAreas: any,
  buildingType: string,
  buildingGrade: string
}

type RetailBuildingViewModel = BaseViewModel & {
  kind: 'Retail Building'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  floorNumberinSchemeId: number,
  propertyGrade: string
}

type ParkingViewModel = BaseViewModel & {
  kind: 'Parking'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  floorNumberinSchemeId: number,
}

type WarehouseViewModel = BaseViewModel & {
  kind: 'Warehouse'
  subTypeCategory: string,
  subCategory: string,
  locationGrade: string,
  measurementStandard: string,
  size: Size,
  accommodation: any,
  measurementStandardModel: StandardMeasurement,
  floorNumberinSchemeId: number,
  propertyGrade: string
}

type LandViewModel = BaseViewModel & {
  kind: 'Land',
  locationGrade: string,
  coordinateReferenceSystem: string,
  landParcelNum: number,
  totalLandArea: number,
  unitMeasurement: string,
  planningStatus: string,
  numOfBuilding: 'Unknown' | number
}


export type PropertySpecificsModuleViewModel 
  = ApartmentViewModel
  | HouseViewModel
  | OfficeViewModel
  | RetailShopViewModel
  | RetailBuildingViewModel
  | ParkingViewModel
  | WarehouseViewModel
  | LandViewModel

@Component({
  selector: 'kt-property-specifics-module-v2',
  templateUrl: './property-specifics-module-v2.component.html',
  styleUrls: [
    '../info-module.styles.scss',
    './property-specifics-module-v2.component.scss'
  ]
})
export class PropertySpecificsModuleV2Component implements OnInit {
  @Input() viewModel: PropertySpecificsModuleViewModel

  constructor(
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit(): void {
  }

  showInMap(event) {
    const target = new ElementRef(event.currentTarget);
    this.dialog.open(MapModalComponent, {
      width: '800px',
      height: '450px',
      backdropClass: 'map-backdrop',
      data: {
        latitude: this.viewModel.latitude,
        longitude: this.viewModel.longitude,
        trigger: target
      }
    })
  }

  showInfo() {
    if (this.viewModel.kind == 'Land') {
      return
    }
    const rooms = {
        bath: 0,
        kitchen: 0,
        bedrooms: 0,
        livingRooms: 0,
        toilet: 0
    }
    each(this.viewModel.accommodation.data, (col) => {
        Object.entries(col).forEach(([key, value]) => {
            if (rooms.hasOwnProperty(key)) {
            rooms[key] += value != undefined ? Number(value) : 0; 
            }
        })
    })
    let meta = {
      bedroom: 0,
      selectedExternalAreas: [] 
    }
    switch (this.viewModel.kind) {
      case 'Apartment': {
        meta = {
          ...meta,
          bedroom: this.viewModel.bedroom,
          selectedExternalAreas: this.viewModel.selectedExternalAreas
        }
        break;
      }
      case 'Office':
      case 'Retail Shop': {
        meta = {
          ...meta,
          selectedExternalAreas: this.viewModel.selectedExternalAreas
        }
      }
    }
    
    this.dialog.open(SizeTableModalComponent, { 
      width: '80vw',
      data: {
        ac_id: this.viewModel.targetPropertyId,
        standard_measurement: this.viewModel.measurementStandardModel,
        unitAreaMeasurement$: of(this.viewModel.size.unitAreaMeasurementModel),
        readonly: true,
        bedrooms$: of(meta.bedroom),
        externalAreas$: of(meta.selectedExternalAreas),
        floorAction$: of({
            type: 'remove',
            floors: [],
            scheme_id: this.viewModel.floorNumberinSchemeId 
        }),
        sizeChanges$: of({
          standard_measurement_id: this.viewModel.measurementStandardModel?.id,
          unit_area_measurement_id: this.viewModel.size.unitAreaMeasurementModel?.id,
          unit_area_measurement_name: this.viewModel.size.unitAreaMeasurement
        }) ,
        roomChanges$: of(rooms)
      },
      viewContainerRef: this.viewContainerRef
    });
  }
}
