import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AssetClassLandAreaComponentModel, AssetClassLandAreaModel } from 'src/app/core/asset_class';
import { ParcelIdentificationModel } from 'src/app/core/comparable/_models/building.model';
import { AllCoordinateReferenceSystemsRequested, CoordinateReferenceSystem, selectAllCoordinateReferenceSystems } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { LandAreaListComponent } from '../land-parcel/land-area/land-area-list/land-area-list.component';
import { LandParcelMapComponent } from '../land-parcel/land-parcel-map/land-parcel-map.component';

export interface ParcelIdentificationData {
  add_info_on_land: boolean,
  land_parcel_name: string,
  coordinate_reference_system_id: number,
  points: {lat: number, lng: number}[],
  landAreaComponentListSubject: BehaviorSubject<AssetClassLandAreaComponentModel[]>,
  landAreaSubject: BehaviorSubject<AssetClassLandAreaModel>
}

@Component({
  selector: 'kt-parcel-identifaction-section',
  templateUrl: './parcel-identifaction-section.component.html',
  styleUrls: ['./parcel-identifaction-section.component.scss']
})
export class ParcelIdentifactionSectionComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(LandParcelMapComponent, {static: false})
  landParcelMapComponent: LandParcelMapComponent;
  @ViewChild(LandAreaListComponent, {static: false})
  landAreaListComponent: LandAreaListComponent;
  @Input() readonly: boolean;
  @Input() centerLat: number;
  @Input() centerLng: number;
  @Input() data: ParcelIdentificationData;
  @Input() landAreaLowLevelTitle: string = null;
  @Output() checked: EventEmitter<boolean> = new EventEmitter();
  formGroup: UntypedFormGroup;

  coordinateReferenceSystems: CoordinateReferenceSystem[] = [];

  private _onDestroy$: Subject<void> = new Subject();
  errorFields = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<AppState>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data && !changes.data.firstChange) {
      this._populateForm()
    }
  }

  private _populateForm() {
    if (this.formGroup) {
      this.formGroup.patchValue({
        add_info_on_land: this.data.add_info_on_land,
        land_parcel_name: this.data.land_parcel_name,
        coordinate_reference_system_id: this.data.coordinate_reference_system_id
      });
      this.formGroup.updateValueAndValidity();
    } else {
      this.formGroup = this.formBuilder.group({
        add_info_on_land: [this.data.add_info_on_land],
        land_parcel_name: [this.data.land_parcel_name],
        coordinate_reference_system_id: [this.data.coordinate_reference_system_id]
      })
    }
  }

  ngOnInit(): void {
    this.store$.dispatch(new AllCoordinateReferenceSystemsRequested());
    this.store$.pipe(
        select(selectAllCoordinateReferenceSystems),
        takeUntil(this._onDestroy$)
    ).subscribe(res => {
        this.coordinateReferenceSystems = [];
        if (res) {
            this.coordinateReferenceSystems = res;
        }
    });
    this._populateForm();

    this.formGroup.controls.add_info_on_land.valueChanges.pipe(
      startWith(this.formGroup.controls.add_info_on_land.value),
      takeUntil(this._onDestroy$)
    ).subscribe(val => this.checked.emit(val));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  public validate(): boolean {
    let errorFields = [];
    let hasError = false;
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(cName => {
        if (this.formGroup.controls[cName].invalid) {
          errorFields = [...errorFields, cName];
        }
        this.formGroup.controls[cName].markAsTouched();
      })
      this.errorFields = errorFields;
      hasError = true;
    }
    return hasError;
  }

  public getData(): ParcelIdentificationModel {
    this.landAreaListComponent && this.landAreaListComponent.updateValue();

    const model = new ParcelIdentificationModel();
    const controls = this.formGroup.controls;
    model.add_info_on_land = controls.add_info_on_land.value;
    model.land_parcel_name = controls.land_parcel_name.value;
    model.coordinate_reference_system_id = controls.coordinate_reference_system_id.value;
    model.points = this.landParcelMapComponent ? this.landParcelMapComponent.getPath() : [];
    if (this.landAreaListComponent) {
      model.landArea = this.landAreaListComponent.getData();
    }
    model.landAreaComponents = this.data.landAreaComponentListSubject.value;
    return model;
  }

}
