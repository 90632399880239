import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UnitMeasurement } from "../../linked-tables";
import { VComparable } from "../_models";
import { AdjustmentModel } from "../_models/valuation-process-adjustment.model";
import { ConsiderationCriterionModel, SizeCriterionModel } from "../_models/valuation-process-criterion.model";
import { ValuationProcessValuationState } from "../_reducers/valuation.reducer";
import { selectValuationProcessAdjustments } from "./adjustment.selectors";
import { selectValuationProcessConsiderationCriterion } from "./consideration-criterion.selector";
import { selectAllValuationProcessCriterions } from "./default-criterion.selectors";
import { selectValuationProcessDefaultSizeCriterion, selectValuationProcessSizeCriterions } from "./size-criterion.selectors";
import { selectValuationProcessSelectedComparables, selectValuationProcessSelectedComparablesFull, selectValuationProcessState, selectValuationProcessTargetPropertyUnitMeasurement, selectValuationProcessValuation } from "./valuation-process.selectors";
import * as LiquidationValuationSelectors from './liquidation-valuation.selectors'

export const selectValuationProcessValuationState = createFeatureSelector<ValuationProcessValuationState>('valuation-process-valuation')

export const selectValuationProcessValuationCalculations = createSelector(
    selectValuationProcessValuationState,
    selectValuationProcessSelectedComparables,
    selectValuationProcessDefaultSizeCriterion,
    selectValuationProcessAdjustments,
    selectValuationProcessConsiderationCriterion,
    (state, refNums, defaultSize, adjustments, considerationCriterion) => {
      const totalAdjustments = computeTotalAdjustments(adjustments)
      const adjustedConsiderations = computeAdjustedConsiderations(totalAdjustments, considerationCriterion, refNums)
      const adjustedGrossUnitRates = computeAdjustedGrossUnitRates(adjustedConsiderations, defaultSize, refNums)
      const weightedAvgRates = computeWeightedAvgRates(state.weightings, adjustedGrossUnitRates)
      const totalWeighted = computeWeightedTotal(weightedAvgRates)
      const tpValue = computeTPValue(totalWeighted, defaultSize ? defaultSize.values['tp'] : null);
      const totalTPValue = tpValue == null ? null : (tpValue + state.capitalAllowance)
      const totalTPWeighted = totalTPValue == null 
        ? null 
        : defaultSize == null || defaultSize.values['tp'] == null || defaultSize.values['tp'] == 0
          ? null
          : totalTPValue / defaultSize.values['tp']

      return {
        totalAdjustments,
        adjustedConsiderations,
        adjustedGrossUnitRates,
        weightings: state.weightings,
        totalWeight: state.totalWeighting,
        is100: state.totalWeighting == 100,
        weightedAvgRates,
        totalWeighted,
        tpValue,
        totalTPValue,
        totalTPWeighted
      }
    }
)

export const selectValuationProcessValuationIsValid = createSelector(
  selectValuationProcessValuationCalculations,
  calculations => {
    return calculations.is100 && calculations.tpValue != null
  }
)

export const selectValuationProcessValuationFullState = createSelector(
    selectValuationProcessValuationState,
    selectValuationProcessSelectedComparables,
    selectValuationProcessConsiderationCriterion,
    selectValuationProcessTargetPropertyUnitMeasurement,
    selectValuationProcessValuationCalculations,
    (state, refNums, considerationCriterion, unitMeasurement, calculations) => {
      const tenureRelatedInfo = getConsiderationTypeRelatedInfo(considerationCriterion)
      const tenureRelatedInfo2 = getConsiderationTypeRelatedInfo2(considerationCriterion)

      const adjustedConsiderationRowHeader = `Adjusted Consideration${tenureRelatedInfo} (${considerationCriterion.countryCurrency})`

      const adjustedGrossUnitRateRowHeader = `Adjusted Gross Unit Rate${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${unitMeasurement})`


      const weightedAvgRowHeader = `Weighted Average Gross Unit Rate${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${unitMeasurement})`

      const tpRowHeader = `Target Property${tenureRelatedInfo2} (${considerationCriterion.countryCurrency})`

      const capitalAllowanceRowHeader = `Capital Allowance (${considerationCriterion.countryCurrency})`

      const totalTPRowHeader = `Total Target Property Value (${considerationCriterion.countryCurrency})`
      const totalTPWeightedHeader = `Total Target Property Value (${considerationCriterion.countryCurrency}/${unitMeasurement})`
      return {
        ...calculations,
        refNums,
        adjustedConsiderationRowHeader,
        adjustedGrossUnitRateRowHeader,

        justification: state.justification,

        weightedAvgRowHeader,
        tpRowHeader,
        capitalAllowanceRowHeader,
        capitalAllowance: state.capitalAllowance,
        capitalAllowanceJustification: state.capitalAllowanceJustification,
        totalTPRowHeader,
        totalTPWeightedHeader,
      }
    }
)

export const selectValuationProcessValuationSummary = createSelector(
    selectValuationProcessValuationFullState,
    selectValuationProcessDefaultSizeCriterion,
    selectValuationProcessTargetPropertyUnitMeasurement,
    selectValuationProcessConsiderationCriterion,
    selectValuationProcessAdjustments,
    selectValuationProcessSelectedComparablesFull,
    selectValuationProcessValuation,
    LiquidationValuationSelectors.selectLiquidationValueEstimateFormValue,
    (state, sizeCrit, unitMeasurement, considerationCrit, adjustments, comparables, valuation, liquidationValueEstimateFormValue) => {
        const sizeRowHeader = `Size (${sizeCrit.name}) (${unitMeasurement})`
        const sourceDates = getSourceDates(considerationCrit)
        const totalAdjustmentsRow = `Total Adjustment (%) (${adjustments.length} adjustment${adjustments.length != 1 ? 's' : ''})`
        const tenureRelatedInfo = getConsiderationTypeRelatedInfo2(considerationCrit)
        const tpInUnitRowHeader = `Target property${tenureRelatedInfo} (${considerationCrit.countryCurrency}/${unitMeasurement})`
        const buildingInfo = getBuildingInfo(comparables)

        const liquidationDiscountRowHeader = `${valuation.premiseOfValueName} Discount (${liquidationValueEstimateFormValue.discount}%) (${considerationCrit.countryCurrency})`
        const liquidationValueRowHeader = `Liquidation Value (${considerationCrit.countryCurrency})`
        const totalDiscount = liquidationValueEstimateFormValue.discount * state.totalTPValue / 100
        return {
            ...state,
            sizeRowHeader,
            sizes: sizeCrit.values,

            considerationRowHeader: considerationCrit.categoryName,
            considerations: considerationCrit.values,

            sourceDates,
            totalAdjustmentsRow,
            tpInUnitRowHeader,
            buildingInfo,

            premiseOfValueName: valuation.premiseOfValueName,
            liquidationValueSection: {
              addDiscount: liquidationValueEstimateFormValue.addDiscount,
              discount: liquidationValueEstimateFormValue.discount,
              liquidationDiscountRowHeader,
              liquidationValueRowHeader,
              totalDiscount,
              value: state.totalTPValue - totalDiscount 
            }
        }
    }
)

export const selectAdjustmentTabDat = createSelector(
    selectValuationProcessSizeCriterions,
    selectValuationProcessAdjustments,
    selectAllValuationProcessCriterions,
    selectValuationProcessConsiderationCriterion,
    selectValuationProcessValuationState,
    (sizecriterions, adjustments, criterions, considerationCrit, valuation) => {
        return {
            sizecriterions,
            adjustments,
            criterions,
            considerationCrit,
            valuation
        }
    }
)

type Value = {[key: string]: number}

export function computeTotalAdjustments(adjustments: AdjustmentModel[]): Value {
  return adjustments.reduce((acc, ad) => {
    Object.entries(ad.values).forEach(([k, v], _) => {
      const prev = acc[k]
      acc[k] = prev ? (prev + v) : v
    })
    return acc
  }, {})
}

export function computeAdjustedConsiderations(totalAdjustment: Value, considerationCrit: ConsiderationCriterionModel, refNums: string[]): Value {
  if (considerationCrit == null) {
    return refNums.reduce((acc, refNum) => {
      acc[refNum] = null
      return acc
    }, {})
  }
  return Object.entries(considerationCrit.values).reduce((acc, [k, v]) => {
    const adjustment = totalAdjustment[k] ? totalAdjustment[k] : 0
    acc[k] = computeAdjustedConsideration(adjustment, v.value) 
    return acc
  }, {})
}

export function computeAdjustedConsideration(adjustment: number, consideration: number | null): number {
  if (consideration == null) {
    return 0
  }
  const val = consideration * adjustment
  const temp = val / 100
  return consideration + temp
}

export function computeAdjustedGrossUnitRates(adjustedConsiderations: Value, sizes: SizeCriterionModel, refNums: string[]): Value {
  if (sizes == null) {
    return refNums.reduce((acc, refNum) => {
      acc[refNum] = null
      return acc
    }, {})
  }
  return Object.entries(sizes.values).reduce((acc, [k, v]) => {
    acc[k] = computeAdjustedGrossUnitRate(adjustedConsiderations[k], v)
    return acc
  }, {})
}

export function computeAdjustedGrossUnitRate(adjustedConsideration: number, size: number | null): number {
  if (size == null || size == 0) {
    return null
  }
  return adjustedConsideration / size
}

export function computeWeightedAvgRates(weighting: Value, grossUnitRates: Value): Value {
  return Object.entries(weighting).reduce((acc, [k, v]) => {
    acc[k] = computeWeightedAvgRate(v, grossUnitRates[k])
    return acc
  }, {})
}

export function computeWeightedAvgRate(weight: number, grossUnitRate: number | null): number {
  if (grossUnitRate == null) {
    return null
  }

  const val = grossUnitRate * weight
  return val / 100
}

export function computeWeightedTotal(weightedAvgRates: Value): number | null {
  return Object.values(weightedAvgRates).reduce((acc, v) => {
    if (v == null) {
      return null
    }
    if (acc == null) {
      return null
    }
    return acc + v
  }, 0)
}

export function computeTPValue(totalWeighted: number | null, size: number | null): number | null {
  if (totalWeighted == null || size == null) {
    return null
  }
  return totalWeighted * size
}

 export function getConsiderationTypeRelatedInfo(considerationCrit: ConsiderationCriterionModel): string {
    if (considerationCrit == null) {
      return ''
    }
    switch (considerationCrit.considerationType) {
      case 'Rent':
        return ` (per year)`
      default: return ''
    }
  }
 export function getConsiderationTypeRelatedInfo2(considerationCrit: ConsiderationCriterionModel): string {
    switch (considerationCrit.considerationType) {
      case 'Rent':
        return ' Rental Value (per year)'
      default:
        return ' Value'
    }
  }

  export function getSourceDates(considerationCrit: ConsiderationCriterionModel): {[key: string]: {type: string, date: Date, dateStr: string}} {
    return Object.entries(considerationCrit.values).reduce((acc, [k, value]) =>{
        const consideration = value.consideration
        if (!consideration) {
            acc[k] = null
            return acc
        }
        if (!consideration.source) {
            acc[k] = null
            return acc
        }
        acc[k] = {
            type: consideration.source.sourceTypeName,
            date: consideration.source.sourceDate,
            dateStr: typeof consideration.source.sourceDate == 'string' 
              ? getDateStringFromDate(new Date(consideration.source.sourceDate))
              : consideration.source.sourceDate 
                ? getDateStringFromDate(consideration.source.sourceDate)
                : ''
        }
        return acc
    }, {})
  }

  export function getBuildingInfo(comprables: VComparable[]): {exists: boolean, info: {[key: string]: string}} {
    let exists = false

    const info = comprables.reduce((acc, com) => {
        if (com.propertySubType == 'Apartment' || com.propertySubType == 'Office' || com.propertySubType == 'Parking') {
            exists = true
            acc[com.refNum] = com.building.name
            return acc
        }
        acc[com.refNum] = 'N/I'
        return acc
    }, {})

    return {exists, info}
  }

  function getDateStringFromDate(date: Date): string {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dateToday = date.getDate();
    return `${year}-${month > 9 ? month : '0' + month}-${dateToday > 9 ? dateToday : '0' + dateToday}`;
  }