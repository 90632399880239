<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Filters</h3>
            <span class="card-icon kt-hide"><i class="la la-gear"></i></span>
        </div>
        <div class="card-toolbar">
            <a href="javascript:;" 
                class="btn" 
                (click)="onNoClick()"
                mat-raised-button 
                matTooltip="Close">
                <i class="ki ki-close"></i>
            </a>
        </div>
    </div>
    <div class="form kt-form--label-align-right form-group-seperator-dashed" [formGroup]="form">
        <div class="card-body m-0 h-100">
            <mat-dialog-content>
                <div class="row align-items-center mt-2">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">
                            Location
                        </h5>
                        <hr class="active" />
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="location_grade" placeholder="Location Grade">
                                <mat-option *ngFor="let grade of grades" [value]="grade.id">
                                    {{grade.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row align-items-center mt-10">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">
                            {{propertySectionTitle}}
                        </h5>
                        <hr class="active" />
                    </div>
                    <div class="col-lg-3" *ngIf="sections.property_grade">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="property_grade" placeholder="Property Grade">
                                <mat-option *ngFor="let item of grades" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.handover_standard">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="handover_standard" placeholder="Handover standard">
                                <mat-option *ngFor="let item of handoverStandards" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.state_of_repair">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="state_repair" placeholder="State of Repair">
                                <mat-option *ngFor="let item of stateRepairs" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.completion_year">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput formControlName="completion_year" placeholder="Completion Year"  />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.above_floors">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput type="number" formControlName="above_floors" placeholder="Number of floors (above ground)"  />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.building_type">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="building_type" placeholder="Building Type">
                                <mat-option *ngFor="let item of buildingTypes" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.num_of_units">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput type="number" formControlName="num_of_units" placeholder="Number of units"  />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.parking_type">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="parking_type" placeholder="Parking Type">
                                <mat-option *ngFor="let item of parkingTypes" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="sections.automation">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="automation" placeholder="Automation">
                                <mat-option value="1">Yes</mat-option>
                                <mat-option value="2">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4" *ngIf="sections.quality_of_soil_degradation">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="qos_degradation" placeholder="Quality of soil and degradation">
                                <mat-option *ngFor="let item of qos" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4" *ngIf="sections.comparative_land_use">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="comparative_land_use" placeholder="Comparative land use">
                                <mat-option *ngFor="let item of comparativeLandUses" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4" *ngIf="sections.source_of_land_use_classification">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="source_of_land_use" placeholder="Source of land use classification">
                                <mat-option *ngFor="let item of sol_uses" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4" *ngIf="sections.existing_building_on_the_land_parcel">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput formControlName="existing_building" placeholder="Existing building on the land parcel"  />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4" *ngIf="sections.foundation_type">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="foundation_type" placeholder="Foundation Type">
                                <mat-option *ngFor="let item of foundationTypes" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row align-items-center mt-10" *ngIf="topSections.rooms">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Rooms & Beds</h5>
                        <hr class="active" />
                    </div>
                    <div class="col-lg-4">
                        Bedrooms - ({{bedroomRanges[0]}} - {{bedroomRanges[1] >= 10 ? '10+' : bedroomRanges[1]}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [minSelected]="bedroomRanges[0]"
                                    [maxSelected]="bedroomRanges[1] >= 10 ? 10 : bedroomRanges[1]"
                                    [max]="10"
                                    [showStepIndicator]="true"
                                    (onChange)="onSliderChange($event, 'bedroom')"></npn-slider>
                    </div>
                    <div class="col-lg-4">
                        Bathrooms - ({{bathroomRanges[0]}} - {{bathroomRanges[1] >= 10 ? '10+' : bathroomRanges[1]}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [minSelected]="bathroomRanges[0]"
                                    [maxSelected]="bathroomRanges[1] >= 10 ? 10 : bathroomRanges[1]"
                                    [max]="10"
                                    [showStepIndicator]="true"
                                    (onChange)="onSliderChange($event, 'bathroom')"></npn-slider>
                    </div>
                    <div class="col-lg-4">
                        Rooms - ({{roomRanges[0]}} - {{roomRanges[1] >= 10 ? '10+' : roomRanges[1]}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [minSelected]="roomRanges[0]"
                                    [maxSelected]="roomRanges[1] >= 10 ? 10 : roomRanges[1]"
                                    [max]="10"
                                    [showStepIndicator]="true"
                                    (onChange)="onSliderChange($event, 'room')"></npn-slider>
                    </div>
                </div>
                <div class="row align-items-center mt-10" *ngIf="topSections.service_and_infra">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Services & Infrastructure</h5>
                        <hr class="active" />
                    </div>
                    <div class="col-lg-12" *ngIf="sections.compInfra">
                        <div>
                            <label class="external-area-label-gray font-weight-bold">Heating</label>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 mt-4" *ngFor="let heating of heatings;">
                                <mat-checkbox [checked]="heating.checked" (change)="onHeatingChange($event, heating)">
                                    {{heating.title}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="mt-4">
                            <label class="external-area-label-gray font-weight-bold">Service</label>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 mt-4 text-wrap" *ngFor="let heating of services;">
                                <mat-checkbox [checked]="heating.checked" (change)="onServiceChange($event, heating)" class="text-wrap">
                                    <span class="text-wrap">
                                        {{heating.title}}
                                    </span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" *ngIf="sections.landInfra">
                        <div class="row">
                            <div class="col-lg-4 mt-4 text-wrap" *ngFor="let heating of landServices;">
                                <mat-checkbox [checked]="heating.checked" (change)="onLandServiceChange($event, heating)" class="text-wrap">
                                    <span class="text-wrap">
                                        {{heating.title}}
                                    </span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-10" *ngIf="topSections.facilities">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Facilities</h5>
                        <hr class="active" />
                    </div>
                    <div class="col-lg-4">
                        {{indoorGarageTitle}} - ({{indoorGarageRanges[0]}} - {{indoorGarageRanges[1] >= 200 ? '200+' : indoorGarageRanges[1]}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [minSelected]="indoorGarageRanges[0]"
                                    [maxSelected]="indoorGarageRanges[1] >= 200 ? 200 : indoorGarageRanges[1]"
                                    [max]="200"
                                    [step]="25"
                                    [showStepIndicator]="true"
                                    (onChange)="onSliderChange($event, 'indoor_garage')"></npn-slider>
                    </div>
                    <div class="col-lg-4">
                        {{outdoorGarageTitle}} - ({{outdoorGarageRanges[0]}} - {{outdoorGarageRanges[1] >= 200 ? '200+' : outdoorGarageRanges[1]}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [minSelected]="outdoorGarageRanges[0]"
                                    [maxSelected]="outdoorGarageRanges[1] >= 200 ? 200 : outdoorGarageRanges[1]"
                                    [max]="200"
                                    [step]="25"
                                    [showStepIndicator]="true"
                                    (onChange)="onSliderChange($event, 'outdoor_garage')"></npn-slider>
                    </div>
                    <div class="col-lg-12 mt-4" *ngIf="sections.external_areas">
                        <div>
                            <label class="external-area-label-gray font-weight-bold">{{ 'APARTMENT.FORM.FIELD.CHARACTERISTICS.EXTERNAL_AREAS.TITLE' | translate }}</label>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                <div *ngFor="let externalArea of externalAreas; let i = index"
                                    class="col-4 kt-margin-bottom-20-mobile pt-2">
                                    <mat-checkbox
                                            [checked]="setSelectedExternalArea(externalArea)"
                                            (change)="updateExternalAreas(externalArea, $event)"
                                    >
                                        {{externalArea.name}}
                                    </mat-checkbox>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mt-4" *ngIf="sections.garden">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="garden" placeholder="Garden">
                                <mat-option *ngFor="let item of gardesn" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row align-items-center mt-10">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">
                            {{sizeSectionTitle}}
                        </h5>
                        <hr class="active" />
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="size_unit" [placeholder]="sizePlaceholder">
                                <mat-option *ngFor="let item of unitAreaMeasurements" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        {{sizeRangePlaceholder}} - ({{sizeRanges[0]}} - {{sizeRanges[1] >= 250 ? '250+' : sizeRanges[1]}} {{selectedSizeUnit}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [max]="250"
                                    [minSelected]="sizeRanges[0]"
                                    [maxSelected]="sizeRanges[1] >= 250 ? 250 : sizeRanges[1]"
                                    [step]="10"
                                    (onChange)="onSliderChange($event, 'sizes')">
                        </npn-slider>
                    </div>
                </div>
                <div class="row align-items-center mt-10">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Pictures</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-4">
                        Pictures - ({{pictureRanges[0]}} - {{pictureRanges[1] >= 10 ? '10+' : pictureRanges[1]}})
                    </div>
                    <div class="col-lg-8">
                        <npn-slider [min]="0"
                                    [max]="10"
                                    [minSelected]="pictureRanges[0]"
                                    [maxSelected]="pictureRanges[1] >= 10 ? 10 : pictureRanges[1]"
                                    (onChange)="onSliderChange($event, 'pictures')">
                        </npn-slider>
                    </div>
                </div>
                <div class="row align-items-center mt-10" *ngIf="topSections.tenure">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Tenures</h5>
                        <hr class="active">
                    </div>
                    <ng-container *ngIf="sections.tenure">
                        <div class="col-lg-6 pt-2">
                            <mat-checkbox
                                (change)="changeConsideration($event, 'freehold')"
                                [checked]="freehold">
                                Freehold
                            </mat-checkbox>
                        </div>
                        <div class="col-lg-6 pt-2">
                            <mat-checkbox
                                (change)="changeConsideration($event, 'leasehold')"
                                [checked]="leasehold">
                                Leasehold
                            </mat-checkbox>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="sections.landTenur">
                        <div class="col-lg-6">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select formControlName="land_tenure" placeholder="Land Tenure">
                                    <mat-option *ngFor="let item of landTenures" [value]="item.id">
                                        {{item.name + ' (' + item.payment_type + ')'}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6" >
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput formControlName="tenure_length" placeholder="Tenure length"  />
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
                <div class="row align-items-center mt-10">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Measurement Standards</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-4 pt-2"
                        *ngFor="let item of standardMeasurements">
                        <mat-checkbox 
                            (change)="changeStandard($event, item.id)"
                            [checked]="selectedStandardMeasurements.includes(item.id)">
                            {{item.name}}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="row align-items-center mt-10" *ngIf="topSections.sources">
                    <div class="col-lg-12">
                        <h5 class="text-mad text-uppercase">Sources</h5>
                        <hr class="active">
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="source_type" placeholder="Source Type">
                                <mat-option *ngFor="let item of sourceTypes" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="source_info" placeholder="Source Information">
                                <mat-option *ngFor="let item of sourceInformations" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="source_cred" placeholder="Source Credibility">
                                <mat-option *ngFor="let item of sourceCredibility" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="has_photo" placeholder="Has supporting pictures?">
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="0">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-dialog-content>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button mat-button mat-raised-button color="primary"
                    (click)="onYesClick()">
                    Apply
                </button>
            </div>
        </div>
    </div>
</div>