// NGRX
import {Store, select} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
// Selectirs
import {selectExternalAreasQueryResult, selectExternalAreasPageLoading, selectExternalAreasShowInitWaitingMessage} from '../_selectors/external-area.selectors';

export class ExternalAreasDataSource extends BaseDataSource {
    trashed: number = 0;

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectExternalAreasPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectExternalAreasShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectExternalAreasQueryResult)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }
}
