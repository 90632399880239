import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AdditionalTermModel } from 'src/app/core/admin/_models/agency.model';
import * as _ from 'lodash';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { EditElementWithInfoDialogRef } from 'src/app/core/_base/crud/utils/layout-utils.service';

@Component({
  selector: 'kt-additional-terms-table',
  templateUrl: './additional-terms-table.component.html',
  styleUrls: ['./additional-terms-table.component.scss']
})
export class AdditionalTermsTableComponent implements OnInit, OnDestroy {
  @Input() terms: AdditionalTermModel[] 
  @Input() readonly: boolean = false;

  dataSource = new MatTableDataSource();
  displayedColumns = ['title', 'content', 'location', 'actions'];

  private _terms$: BehaviorSubject<AdditionalTermModel[]> = new BehaviorSubject([]);
  private _onDestroy$: Subject<void> = new Subject();
  private locations: {id: number, name: string}[] = [{id: 1, name: 'Signature of the parties'}, {id: 2, name: 'Delivery, fees & condition'}];

  constructor(
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit(): void {
    this._terms$.next(_.cloneDeep(this.terms));
    this._terms$.pipe(
      map(terms => terms.map(term => ({
        ...term,
        content_trimmed: term.content ? this._trimContent(term.content) : 'N/A',
        location_name: term.location_id ? this._getLocationName(term.location_id) : 'N/A'
      }))),
      takeUntil(this._onDestroy$)
    ).subscribe(terms => {
      this.dataSource.data = terms;
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getData(): AdditionalTermModel[] {
    const data = this._terms$.value;
    return data;
  }

  addItem() {
    const dialogRef = this._openEditDialog("Add Term", {title: null, content: null, location_id: null});
    dialogRef.afterClosed()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }
        const term = new AdditionalTermModel();
        res.result.forEach(f => {
          term[f.key] = f.value;
        });
        const terms = this._terms$.value;
        terms.push(term);
        this._terms$.next(terms);
      })
  }
  editItem(index: number, item: AdditionalTermModel) {
    const dialogRef = this._openEditDialog("Edit Term", {title: item.title, content: item.content, location_id: item.location_id});
    dialogRef.afterClosed()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }
        const term = new AdditionalTermModel();
        res.result.forEach(f => {
          term[f.key] = f.value;
        })
        term.id = item.id;
        const terms = this._terms$.value;
        terms[index] = term;
        this._terms$.next(terms);
      })
  }
  deleteItem(index: number) {
    const terms = this._terms$.value;
    terms.splice(index, 1);
    this._terms$.next(terms);
  }

  private _openEditDialog(title: string, values: {title: string, content: string, location_id: number}): EditElementWithInfoDialogRef {
    return this.layoutUtilsService.editElementWithInfo({
      title: title,
      configs: [
        {
          type: 'text',
          label: 'Title',
          value: values.title,
          key: 'title',
          validationRules: ['required']
        },
        {
          type: 'textarea',
          label: 'Content',
          value: values.content,
          key: 'content',
          class: 'mt-4',
          validationRules: ['required']
        },
        {
          type: 'selection',
          label: 'Location',
          value: values.location_id,
          key: 'location_id',
          class: 'mt-4',
          validationRules: ['required'],
          selectionValues: this.locations.map(l => ({id: l.id, value: l.name}))
        }
      ]
    })
  }

  private _trimContent(content: string): string {
    if (content.length <= 50) {
      return content;
    }
    return content.substring(0, 50) + '...';
  }

  private _getLocationName(id: number): string {
    const location = this.locations.find(l => l.id == id);
    return location ? location.name : 'N/A'
  }

}
