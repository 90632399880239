import {MapsAPILoader} from '@agm/core';
import {Location} from '@angular/common';
import {Component, HostListener, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {delay, map, startWith, take, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {each, uniq} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
// tslint:disable-next-line: max-line-length
import {
    AcSource, AssetClassBuildingInformation, AssetClassResidentialFacilityModel, AssetClassResidentialModel,
    AssetClassResidentialOnServerCreated, AssetClassResidentialOnServerUpdated, AssetClassResidentialsService, AssetClassTenure,
    selectLastCreatedAssetClassResidentialId, AssetClassSizeModel, ResidentialExternalAreaModel
} from '../../../../../core/comparable';
// tslint:disable-next-line: max-line-length
import {
  AllGradesRequested,
  AllHandoverStandardsRequested,
  AllResidentialTypesRequested,
  Grade,
  ResidentialType,
  selectAllGrades,
  selectAllHandoverStandards,
  ExternalArea,
  AllExternalAreasRequested,
  selectAllExternalAreas,
  FloorType,
  AllFloorTypesRequested,
  selectAllFloorTypes,
  WindowType,
  AllWindowTypesRequested,
  selectAllWindowTypes, selectResidentialTypesActionLoading, HandoverStandard, StateRepair, AllStateRepairsRequested, selectAllStateRepairs, BuildingType, AllBuildingTypesRequested, selectAllBuildingTypes,
  AllUnitAreaMeasurementsRequested,
  selectAllUnitAreaMeasurements
} from '../../../../../core/linked-tables';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {UploadFileComponent} from '../../../shared_components/upload-file/upload-file.component';
import {BuildingInformationComponent} from '../../../shared_components/building-information/building-information.component';
import {FacilitiesSubListComponent} from '../../../shared_components/facilities/list/facilities-sub-list.component';
import {
  CityModel,
  CountryModel,
  selectAllCountries,
  selectCitiesByCountryId,
  AllCountriesRequested,
  AllCitiesRequested
} from '../../../../../core/admin';
import { CountryEditComponent } from '../../../admin-management/countries/country-edit/country-edit.component';
import { ComparablesRadioButtonValues } from "../../../../../core/comparable/_models/RadioButtonValues";
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { MapLocationComponent } from '../../../shared_components/map-location/map-location.component';
import { ConsiderationAndSourceComponent } from '../../_sub/consideration-and-source/consideration-and-source.component';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { InternalAspectsComponent } from '../../../shared_components/internal-aspects/internal-aspects.component';
import { AcGroundsComponent } from '../../../shared_components/ac-grounds/ac-grounds.component';
import { BuildingSectionComponent } from '../../../shared_components/building-section/building-section.component';
import { AccommodationLayoutTableComponent } from '../../../shared_components/accommodation-layout-table/accommodation-layout-table.component';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { AssetClassBuilding } from 'src/app/core/comparable/_models/asset-class-building.model';
import { BuildingFormComponent } from '../../../shared_components/building-form/building-form.component';
import { AssetClassSize } from 'src/app/core/v2/types';
import {v4 as uuidv4} from 'uuid'

@Component({
  selector: 'kt-apartment-edit',
  templateUrl: './apartment-edit.component.html',
  styleUrls: ['./apartment-edit.component.scss', '../../complex-btn.scss'],
})
export class ApartmentEditComponent implements OnInit, OnDestroy{
  @ViewChild(MapLocationComponent, {static: false}) 
  mapLocationComponent: MapLocationComponent;

  @ViewChild('simple')
  public uploadFileComponent: UploadFileComponent;
  @ViewChild('buildingPic')
  public buildingFileComponent: UploadFileComponent;
  @ViewChild(InternalAspectsComponent, {static: false})
  internalAspectComponent: InternalAspectsComponent;
  @ViewChild(AcGroundsComponent, {static: false})
  acGroundsComponent: AcGroundsComponent;
  @ViewChild(AcDocumentUploadComponent, {static: false})
  acDocumentUploadComponent: AcDocumentUploadComponent;
  @ViewChild(ConsiderationAndSourceComponent, {static: false})
  considerationAndSourceComponent: ConsiderationAndSourceComponent;
  @ViewChild(BuildingSectionComponent, {static: false})
  buildingSectionComponent: BuildingSectionComponent;
  @ViewChild(AccommodationLayoutTableComponent, {static: false})
  accommodationLayoutTable: AccommodationLayoutTableComponent;
  @ViewChild(BuildingFormComponent, {static: false})
  buildingFormComponent: BuildingFormComponent;

  assetClassResidential: AssetClassResidentialModel;
  assetClassResidentialForm: UntypedFormGroup;

  loading$: Observable<boolean>;
  hasFormErrors = false;
  markerLat: number;
  markerLng: number;
  zoom = 15;
  isShown = true;
  centerLat = null;
  centerLng = null;
  
  
  
  protected _onDestroy = new Subject<void>();
  formSelectionCtrl: UntypedFormControl = new UntypedFormControl();
  isComplexForm: boolean = false;
  subCategories: SubCategoryModel[] = [];
  filteredSubCategories: SubCategoryModel[] = [];
  public filteredSubTypeCategories: BehaviorSubject<SubTypeCategoryModel[]> = new BehaviorSubject([]);
  subTypeCategories: SubTypeCategoryModel[] = [];
  handoverStandards: HandoverStandard[] = [];
  stateOfRepairs: StateRepair[] = [];
  grades: Grade[] = [];
  buildingTypes: BuildingType[] = [];
  aboveFloor$: BehaviorSubject<number> = new BehaviorSubject(null);
  belowFloor$: BehaviorSubject<number> = new BehaviorSubject(null);
  private _floors$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public floors$: Observable<number[]> = this._floors$.asObservable();
  private _rooms$: BehaviorSubject<{room: number, bedroom: number, bathroom: number}> = new BehaviorSubject(null);
  public rooms$ = this._rooms$.asObservable();
  tenuresSubject = new BehaviorSubject<AssetClassTenure[]>([])
  sizesSubject = new BehaviorSubject<AssetClassSizeModel[]>([]);
  sizes$ = combineLatest([ 
    this.store.select(selectAllUnitAreaMeasurements),
    this.sizesSubject.asObservable()
  ]).pipe(
    map(([unitAreaMeasurements, sizes]) => {
      const items: AssetClassSize[] = sizes.map(size => ({
        id: size.id,
        uid: size.uid,
        size: size.size,
        standardMeasurementName: size.standard_measurement_name,
        unitAreaMeasurementAcronym: unitAreaMeasurements.find(item => item.id === size.unit_of_area_measurement_id)?.acronym
      }))
      return items
    })
  )
  showTitleRequiredError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasParcel = of(false);
  public comps = new BehaviorSubject([]);
  public considerationError$ = new BehaviorSubject(false);
  saveState: boolean; 

  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  tabHeaders: TabHeader[] = [
    {label: 'Location & building', disabled: of(false)},
    {label: 'Property information', disabled: of(false)},
    {label: 'Lease & sale information', disabled: of(false)},
    {label: 'Pictures & documents', disabled: of(false)},
  ]
  error:any = {
    msg: ' Missing Fields in total:',
    fields: []
  }

  timezoneOffset: string | null

  /**
   * Component constructor
   *
   * @param assetClassResidentialsService: AssetClassResidentialsService
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param fb: FormBuilder
   * @param location: Location
   * @param subheaderService: SubheaderService
   * @param typesUtilsService: TypesUtilsService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store
   * @param dialog: Dialog
   * @param mapsAPILoader: MapsAPILoader
   * @param layoutConfigService: LayoutConfigService
   * @param ngZone: NgZone
   * @param translate
   */
  constructor(public assetClassResidentialsService: AssetClassResidentialsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private location: Location,
              private subheaderService: SubheaderService,
              public typesUtilsService: TypesUtilsService,
              private layoutUtilsService: LayoutUtilsService,
              private store: Store<AppState>,
              public dialog: MatDialog,
              private mapsAPILoader: MapsAPILoader,
              private layoutConfigService: LayoutConfigService,
              private ngZone: NgZone,
              private translate: TranslateService) {
      this.saveState = false;
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this._onDestroy)).subscribe(params => {
      const id = params.apartment_id;
      if (id && id > 0) {
        this.activatedRoute.data.subscribe(data => {
          const res = data.data;
          const facilities = data.data2;
          const buildingFacilities = data.data3;
          this.assetClassResidential = res.data;
          this.isComplexForm = this.assetClassResidential.formType == 1;
          this._floors$.next(this._getFloorsFrom(this.assetClassResidential.floor_details));
          const deepCopiedSizes = _deepCopy(this.assetClassResidential.sizes)
            .map(size => {
              size.uid = uuidv4()
              return size
            })
          const deepCopiedTenures = _deepCopy(this.assetClassResidential.tenures)
            .map(tenure => {
              const rentSizeUuid = deepCopiedSizes.find(size => size.id === tenure.rent_size_id)?.uid
              const expensesSizeUuid = deepCopiedSizes.find(size => size.id === tenure.expenses_size_id)?.uid
              tenure.rent_size_uid = rentSizeUuid
              tenure.expenses_size_uid = expensesSizeUuid
              return tenure
            })
          this.tenuresSubject.next(deepCopiedTenures)
          this.sizesSubject.next(deepCopiedSizes);
          this.markerLat = Number(res.data.locationData.latitude);
          this.markerLng = Number(res.data.locationData.longitude);
          this.centerLng = this.markerLng;
          this.centerLat = this.markerLat;
          this.createForm();
        });
      } else {
        this.assetClassResidential = new AssetClassResidentialModel();
        this.assetClassResidential.clear();
        this.createForm();
      }

    this.store.dispatch(new AllUnitAreaMeasurementsRequested())
    this.store.dispatch(new AllSubCategorysRequested());
    this.store.select(selectAllSubCategorys)
      .subscribe(res => {
        this.subCategories = res ? res : [];
        this.filteredSubCategories = this._filterSubCategories(this.assetClassResidential.sub_type_category_id);
      });

    this.store.dispatch(new AllSubTypeCategorysRequested());
    this.store.pipe(
      select(selectAllSubTypeCategorys), takeUntil(this._onDestroy))
      .subscribe(res => {
        this.subTypeCategories = [];
        if (res) {
          this.subTypeCategories = res;
          this.filteredSubTypeCategories.next(this._filterSubTypeCategories(1));
        }
      });
    this.store.dispatch(new AllHandoverStandardsRequested());
    this.store.pipe(select(selectAllHandoverStandards), takeUntil(this._onDestroy))
      .subscribe(res => {
        this.handoverStandards = res ? res : [];
      });
    this.store.dispatch(new AllStateRepairsRequested());
    this.store.pipe(select(selectAllStateRepairs), takeUntil(this._onDestroy))
      .subscribe(res => {
        this.stateOfRepairs = res ? res : [];
      });
    this.store.dispatch(new AllGradesRequested());
    this.store.pipe(select(selectAllGrades), takeUntil(this._onDestroy))
      .subscribe(res => this.grades = res ? res : []);
    this.store.dispatch(new AllBuildingTypesRequested());
    this.store.select(selectAllBuildingTypes).pipe(takeUntil(this._onDestroy)).subscribe(res => {
        this.buildingTypes = res ? res : [];
    });

    });

    this.loading$ = this.store.pipe(select(selectResidentialTypesActionLoading));
  }
  onFloorAdd(event) {
      const floorStr = event.value;
      const floors = this._getFloors(floorStr);
      const _floors = this._floors$.value;
      floors.forEach(floor => {
          if (_floors.findIndex(f => f == floor) > -1) {
              return;
          }
          _floors.push(floor);
      });
      _floors.sort();
      this._floors$.next(_floors);
  }
  onFloorRemove(event) {
      const floors = this._getFloors(event);
      const _floors = this._floors$.value;
      floors.forEach(floor => {
          const index = _floors.findIndex(f => f == floor);
          if (index > -1) {
              _floors.splice(index, 1);
          }
      });
      _floors.sort();
      this._floors$.next(_floors);
  }

  onCenterChange(locationData) {
    this.centerLat = locationData.latitude;
    this.centerLng = locationData.longitude;
    this.assetClassResidential = Object.assign({}, this.assetClassResidential, {
      locationData: {
        ...this.assetClassResidential.locationData,
        latitude: locationData.latitude === null ? null : (Number(locationData.latitude)),
        longitude: locationData.longitude === null ? null : (Number(locationData.longitude)),
        address: locationData.address,
        location_grade_id: locationData.location_grade_id,
        zip_code: locationData.zip_code,
        city_id: locationData.city_id,
        location_surrounding: locationData.location_surrounding
      }
    });
  }

  onPicChange(pics: {pictures: any[], picture: any}) {
    this.assetClassResidential = Object.assign({}, this.assetClassResidential, {
      building_info: {
        ...this.assetClassResidential.building_info,
        pictures: pics.pictures,
        picture: pics.picture
      }
    })
  }

  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormErrors = obj.hasFormErrors;
  }

  private _filterSubCategories(stcId: number) {
    if (stcId == null) {
      return [];
    }
    return this.subCategories.filter(item => item.sub_type_category_id == stcId);
  }
  private _filterSubTypeCategories(typeId: number) {
    return this.subTypeCategories.filter(item => item.property_sub_type_id == typeId);
  }
  private _getFloorsFrom(data: string) {
    if (data == null) {
      return [];
    }
      const floorRaws = data.split(',');
      let floors = [];
      floorRaws.forEach(raw => {
          const _floors = this._getFloors(raw);
          _floors.forEach(f => floors.push(f));
      });
      floors = uniq(floors);
      return floors;
  }
  private _getFloors(raw: string): number[] {
    if (raw == null) {
      return [];
    }
      const splits = raw.split(':');
      const floors: number[] = []
      if (splits.length == 2) {
          const lower = parseInt(splits[0]);
          const upper = parseInt(splits[1]);
          for (let i = lower; i <= upper; i++) {
              floors.push(i);
          }
      } else {
          floors.push(parseInt(splits[0]));
      }
      return floors;
  }


  ngOnDestroy(): void {

    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private createForm() {
    this.formSelectionCtrl.setValue(this.assetClassResidential.formType);
    this.formSelectionCtrl.updateValueAndValidity();
    this.formSelectionCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
      if (val == 1) {
        this.isComplexForm = true;
      } else {
        this.isComplexForm = false;
      }
      if (this.assetClassResidentialForm) {
        this._changeValidation(['number_of_rooms', 'number_of_bedrooms', 'number_of_bathrooms', 'building_type'], !this.isComplexForm);
        // if (this.isComplexForm) {
        //   this._rooms$.next({
        //     room: this.assetClassResidentialForm.controls.number_of_rooms.value,
        //     bedroom: this.assetClassResidentialForm.controls.number_of_bedrooms.value,
        //     bathroom: this.assetClassResidentialForm.controls.number_of_bathrooms.value,
        //   });
        // } else {
        //   if (this.accommodationLayoutTable) {
        //     const accomData = this.accommodationLayoutTable.getData().data;
        //     let totalRooms = 0;
        //     let totalBedrooms = 0;
        //     let totalBathrooms = 0;
        //     Object.values(accomData).forEach(value => {
        //       if (value["total"]) {
        //         totalRooms += Number(value["total"]);
        //       }
        //       if (value["bedrooms"]) {
        //         totalBedrooms += Number(value["bedrooms"]);
        //       }
        //       if (value["bath"]) {
        //         totalBathrooms += Number(value["bath"]);
        //       }
        //     });
        //     this.assetClassResidentialForm.controls.number_of_rooms.setValue(totalRooms == 0 ? null : totalRooms);
        //     this.assetClassResidentialForm.controls.number_of_bedrooms.setValue(totalBedrooms == 0 ? null : totalBedrooms);
        //     this.assetClassResidentialForm.controls.number_of_bathrooms.setValue(totalBathrooms == 0 ? null : totalBathrooms);
        //   }
        // }
      }
    });

    this.assetClassResidentialForm = this.fb.group({
      sub_type_category_id: [this.assetClassResidential.sub_type_category_id, Validators.required],
      sub_category_id: [this.assetClassResidential.sub_category_id, Validators.required],
      handover_standard_id: [this.assetClassResidential.handover_standard_id, Validators.required],
      floor_details: [this.assetClassResidential.floor_details && this.assetClassResidential.floor_details.length > 0 
          ? this.assetClassResidential.floor_details.split(',')
          : '', Validators.required],
      state_of_repair_id: [this.assetClassResidential.state_of_repair_id, Validators.required],
      energy_efficiency_grade_id: [this.assetClassResidential.energy_efficiency_grade_id],
      general_desc: [this.assetClassResidential.property_general_desc],
      number_of_rooms: [this.assetClassResidential.room, this.isComplexForm ? null : Validators.required],
      number_of_bedrooms: [this.assetClassResidential.bedroom, this.isComplexForm ? null : Validators.required],
      number_of_bathrooms: [this.assetClassResidential.bathroom, this.isComplexForm ? null : Validators.required],
      building_name: [this.assetClassResidential.building_info.building_name],
      building_type: [this.assetClassResidential.building_info.building_type, this.isComplexForm ? null : Validators.required],
      building_type_comment: [this.assetClassResidential.building_info.building_type_comment]
    });
    this.assetClassResidentialForm.controls.sub_type_category_id.valueChanges.pipe(
      startWith(this.assetClassResidentialForm.controls.sub_type_category_id.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      this.filteredSubCategories = this._filterSubCategories(val)
      if (this.filteredSubCategories.length == 0) {
        this.assetClassResidentialForm.controls.sub_category_id.clearValidators();
      } else {
        this.assetClassResidentialForm.controls.sub_category_id.setValidators([Validators.required]);
      }
      this.assetClassResidentialForm.controls.sub_category_id.updateValueAndValidity();
    })

}
  private _changeValidation(fields: string[], hasValidation: boolean) {
    const controls = this.assetClassResidentialForm.controls;
    fields.forEach(field => {
      if (hasValidation) {
        controls[field].setValidators([Validators.required]);
      } else {
        controls[field].clearValidators();
      }
      controls[field].updateValueAndValidity();
    })
  }



  /**
   * Returns page title
   */
  getComponentTitle(): string {
    if (this.assetClassResidential && this.assetClassResidential.id) {
      return this.translate.instant('APARTMENT.FORM.TITLE.EDIT', {id: this.assetClassResidential.ref_num});
    }
    return this.translate.instant('APARTMENT.FORM.TITLE.NEW');
  }

  back() {
    this.location.back();
  }

  /**
   * On Submit
   */
  onSubmit(status: boolean) {
    let errorFields = [];
    this.hasFormErrors = false;
    this.considerationError$.next(false);
        
    if (this.mapLocationComponent) {
      if (status && this.mapLocationComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.mapLocationComponent.errorFields];
      }
    }
    if (this.buildingSectionComponent) {
      if (status && this.buildingSectionComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.buildingSectionComponent.errorFields];
      }
    }

    if (this.buildingFormComponent) {
      if (status && this.buildingFormComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.buildingFormComponent.errorFields.map(f => 'building_form_' + f)];
      }
    }

    const controls = this.assetClassResidentialForm.controls;
    if (status && this.assetClassResidentialForm.invalid) {
      Object.keys(controls).forEach(cname => {
        if (!controls[cname].valid) {
          errorFields = [...errorFields, cname];
        }
        controls[cname].markAsTouched();
      })
      this.hasFormErrors = true;
    }
    if (status && this.sizesSubject.value.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'size'];
    }
    if (this.internalAspectComponent) {
      if (status && this.internalAspectComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.internalAspectComponent.errorFields];
      }
    }
    if (status && this.acGroundsComponent.validate()) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, this.acGroundsComponent.errorFields];
      if (this.acGroundsComponent.errorFields.length == 0) {
        errorFields = [...errorFields, 'grounds'];
      }
    }

    if (status && !this.considerationAndSourceComponent.validate()) {
      this.hasFormErrors = true;
      this.considerationError$.next(true);
      errorFields = [...errorFields, 'consideration'];
    }

    if (status && this.uploadFileComponent.uploadFiles.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'pic'];
    } else {
      const uploadedFiles = this.uploadFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasFormErrors = true;
          this.showTitleRequiredError$.next(true);
          errorFields = [...errorFields, 'pic'];
        }
      })
    }
    if (status && this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length == 0) {
      this.hasFormErrors = true;
      errorFields = [...errorFields, 'b_pic'];
    } else if (this.buildingFileComponent && this.buildingFileComponent.uploadFiles.length > 0) {
      const uploadedFiles = this.buildingFileComponent.uploadFiles;
      uploadedFiles.forEach(file => {
        if (file.title === null || file.title === '') {
          this.hasFormErrors = true;
          this.showTitleRequiredError$.next(true);
          errorFields = [...errorFields, 'b_pic'];
        }
      })
    }
    if (this.hasFormErrors) {
      this.error.fields = errorFields;
      return;
    }

    const editedAssetClassResidential = this.prepareAssetClassResidential(status);

    if (editedAssetClassResidential.id > 0) {
      this.updateAc(editedAssetClassResidential);
    } else {
      this.createAc(editedAssetClassResidential);
    }
  }

  /**
   * Returns prepared assignment
   */
  prepareAssetClassResidential(isComplete: boolean): AssetClassResidentialModel {
    const _assetClassResidential = new AssetClassResidentialModel();
    _assetClassResidential.clear();
    _assetClassResidential.id = this.assetClassResidential.id;
    _assetClassResidential.status = isComplete ? 1 : 0;
    _assetClassResidential.formType = this.formSelectionCtrl.value;

    const controls = this.assetClassResidentialForm.controls;
    if (this.mapLocationComponent) {
      _assetClassResidential.locationData = this.mapLocationComponent.getData();
    }

    if (this.buildingSectionComponent) {
      _assetClassResidential.building_info = this.buildingSectionComponent.getDataForComparable();
    }

    _assetClassResidential.sub_type_category_id = controls.sub_type_category_id.value;
    _assetClassResidential.sub_category_id = controls.sub_category_id.value;
    _assetClassResidential.handover_standard_id = controls.handover_standard_id.value;
    _assetClassResidential.floor_details = controls.floor_details.value.length > 0 
        ? controls.floor_details.value.join(',') 
        : '';
    _assetClassResidential.state_of_repair_id = controls.state_of_repair_id.value;
    _assetClassResidential.energy_efficiency_grade_id = controls.energy_efficiency_grade_id.value;
    _assetClassResidential.property_general_desc = controls.general_desc.value;
    _assetClassResidential.room = controls.number_of_rooms.value;
    _assetClassResidential.bathroom = controls.number_of_bathrooms.value;
    _assetClassResidential.bedroom = controls.number_of_bedrooms.value;
    if (!this.isComplexForm) {
      _assetClassResidential.building_info.building_name = controls.building_name.value;
      _assetClassResidential.building_info.building_type = controls.building_type.value;
      _assetClassResidential.building_info.building_type_comment = controls.building_type_comment.value;
    }
    _assetClassResidential.accommodation = this.accommodationLayoutTable ? {...this.accommodationLayoutTable.getData(), id: this.assetClassResidential.accommodation.id} : {id: this.assetClassResidential.accommodation.id, data: null, others: []};
    if (this.internalAspectComponent) {
      _assetClassResidential.internalAspectData = this.internalAspectComponent.getData();
    }
    _assetClassResidential.grounds = {...this.acGroundsComponent.getData(), id: this.assetClassResidential.grounds.id};
    this.uploadFileComponent.res$.pipe(take(1)).subscribe(value => {
        if (value) {
            _assetClassResidential.picture = value.success;
        }
    });
    _assetClassResidential.sizes = this.sizesSubject.value;
    _assetClassResidential.tenures = this.considerationAndSourceComponent ? this.considerationAndSourceComponent.getData() : [];
    if (this.acDocumentUploadComponent) {
      _assetClassResidential.documents = this.acDocumentUploadComponent.getDocuments();
    }
    if (this.buildingFileComponent) {
      this.buildingFileComponent.res$.pipe(take(1)).subscribe(value => {
          if (value) {
            _assetClassResidential.building_info.picture = value.success;
          }
      });
      _assetClassResidential.building_info.pictures = this.buildingFileComponent.uploadFiles;
    }
    if (this.buildingFormComponent) {
      _assetClassResidential.buildingInfo = this.buildingFormComponent.getData();
    }
    if (!this.isComplexForm) {
      _assetClassResidential.buildingInfo.id = this.assetClassResidential.buildingInfo.id
      _assetClassResidential.buildingInfo.infoSection.building_name = controls.building_name.value;
      _assetClassResidential.buildingInfo.infoSection.building_type = controls.building_type.value;
      _assetClassResidential.buildingInfo.infoSection.building_type_comment = controls.building_type_comment.value;
    }
    return _assetClassResidential;
  }

  seeUploadFiles() {}

  createAc(_ac: AssetClassResidentialModel) {
    this.store.dispatch(new AssetClassResidentialOnServerCreated({
      assetClassResidential: _ac,
      tenures: _ac.tenures,
      sizes: _ac.sizes,
      fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : [],
      selectedFacilities: [],
      apartmentResidentialExternalAreas: [],
      documents: _ac.documents
    }));
    this.saveState = true;
    this.layoutUtilsService.showActionNotification(
      this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'),
      MessageType.Create, 3000, true, false);
    this.location.back();
  }

  updateAc(_ac: AssetClassResidentialModel) {
    const updateAssignment: Update<AssetClassResidentialModel> = {
      id: _ac.id,
      changes: _ac
    };
    this.store.dispatch(new AssetClassResidentialOnServerUpdated({
      partialAssetClassResidential: updateAssignment,
      assetClassResidential: _ac,
      tenures: _ac.tenures,
      sizes: _ac.sizes,
      fileList: this.uploadFileComponent ? this.uploadFileComponent.uploadFiles : [],
      selectedFacilities: [],
      apartmentResidentialExternalAreas: [],
      documents: _ac.documents 
    }));
    this.saveState = true;
    this.layoutUtilsService.showActionNotification(
      this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'),
      MessageType.Update, 3000, true, false);
    this.location.back();
  }



  canDeactivate() {
    if (this.discard()) {
      if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
        return true;
      } else {
        // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
        const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
        const currentUrl = currentUrlTree.toString();
        this.location.go(currentUrl);
        // ---------work around end-----------------
        return false;
      }
    }
    return true;
  }

  discard() {
    // return true;
    const assetClassResidential = this.prepareAssetClassResidential(true);
    if (!this.saveState &&
      localStorage.getItem(environment.authTokenKey)) {
      return true;
    };
    return false;
  }

  navToList() {
    const url = `${this.layoutConfigService.getCurrentMainRoute()}/comparable/apartments`;
    this.router.navigate([url], {relativeTo: this.activatedRoute});
  }

  onTabChanged($event) {
    const activeIndex = $event.index;
  }
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  changeTab(section: string) {
    const section_tab = [
      {sections: ['location', 'building'], tab_index: 0},
      {sections: ['prop'], tab_index: 1},
      {sections: ['consideration'], tab_index: 2},
      {sections: ['pic'], tab_index: 3},
    ]
    const active_Tab = section_tab.find(item => item.sections.includes(section));
    this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
    this.selectedtabchange.next(this.selectedTab);
  }
  erroTabMap() {
    return this.typesUtilsService.getCompTabErrorMap(this.isComplexForm, this.error.fields, this.tabHeaders, 1);
  }

  onTimezoneChange(offset: string) {
    this.timezoneOffset = offset
  }
}

function _deepCopy(arr: any[]): any[] {
  return arr.map(item => ({...item}));
}