// NGRX
import {createFeatureSelector} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {AssetClassResidentialActions, AssetClassResidentialActionTypes} from '../_actions/asset-class-residential.actions';
// Models
import {AssetClassResidentialModel} from '../_models/asset-class-residential.model';
import {QueryParamsModel} from '../../_base/crud';

export interface AssetClassResidentialsState extends EntityState<AssetClassResidentialModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    previouslyCreatedAssetClassResidentialId: number;
    lastCreatedAssetClassResidentialId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AssetClassResidentialModel> = createEntityAdapter<AssetClassResidentialModel>();

export const initialAssetClassResidentialsState: AssetClassResidentialsState = adapter.getInitialState({
    assetClassResidentialForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    previouslyCreatedAssetClassResidentialId: undefined,
    lastCreatedAssetClassResidentialId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function assetClassResidentialsReducer(state = initialAssetClassResidentialsState, action: AssetClassResidentialActions): AssetClassResidentialsState {
    switch (action.type) {
        case AssetClassResidentialActionTypes.UpdatePreviouslyCreatedAssetClassResidentialId: {
            return {
                ...state,
                previouslyCreatedAssetClassResidentialId: state.lastCreatedAssetClassResidentialId
            }
        }
        case AssetClassResidentialActionTypes.AssetClassResidentialsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAssetClassResidentialId: undefined
            };
        }
        case AssetClassResidentialActionTypes.AssetClassResidentialActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AssetClassResidentialActionTypes.AssetClassResidentialOnServerCreated:
            return {
                ...state
            };
        case AssetClassResidentialActionTypes.AssetClassResidentialCreated:
            return adapter.addOne(action.payload.assetClassResidential, {
                ...state, 
                previouslyCreatedAssetClassResidentialId: state.lastCreatedAssetClassResidentialId,
                lastCreatedAssetClassResidentialId: action.payload.assetClassResidential.id
            });

        case AssetClassResidentialActionTypes.ApartmentTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        case AssetClassResidentialActionTypes.AssetClassResidentialUpdated:
            return adapter.addOne(action.payload.assetClassResidential, {
                ...state, lastCreatedAssetClassResidentialId: action.payload.assetClassResidential.id
            });
        case AssetClassResidentialActionTypes.AssetClassResidentialsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AssetClassResidentialActionTypes.AssetClassResidentialsPageLoaded: {
            // adapter.removeAll(state);
            return adapter.addMany(action.payload.assetClassResidentials, {
                ...initialAssetClassResidentialsState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        case AssetClassResidentialActionTypes.AssetClassResidentialOnServerRestored:
            return {
                ...state
            };
        case AssetClassResidentialActionTypes.AssetClassResidentialRestored:
            return adapter.addOne(action.payload.assetClassResidential, {
                ...state,
                lastCreatedAssetClassResidentialId: action.payload.assetClassResidential.id,
                totalTrashed: state.totalTrashed - 1
            });

        case AssetClassResidentialActionTypes.AssetClassResidentialAdminRestored:
            return adapter.addOne(action.payload.assetClassResidential, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AssetClassResidentialActionTypes.AssetClassResidentialDeletedFromTrash:
            return {...state,
                totalTrashed: state.totalTrashed - 1,
                totalAdminTrashed: state.totalAdminTrashed + 1
            };
        case AssetClassResidentialActionTypes.AssetClassResidentialDeletedFromAdminTrash:
            return {...state,
                totalAdminTrashed: state.totalAdminTrashed - 1
            };

        case AssetClassResidentialActionTypes.OneAssetClassResidentialDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });
        case AssetClassResidentialActionTypes.AssetClassResidentialOnServerUpdated:
            return adapter.updateOne(action.payload.partialAssetClassResidential, state);
        default:
            return state;
    }
}

export const getAssetClassResidentialState = createFeatureSelector<AssetClassResidentialModel>('assetClassResidentials');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
