import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
// Material
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColorChromeModule} from 'ngx-color/chrome';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {UploadVideoComponent} from './upload-video/upload-video.component';
import {AcSizeComponent} from './ac-size/ac-size.component';
import {PolyLinesComponent} from './land-parcel/poly-lines/poly-lines.component';
import {TenureConsiderationListComponent} from './land-parcel/tenure-consideration/tenure-consideration-list/tenure-consideration-list.component';
import {TenureConsiderationEditDialogComponent} from './land-parcel/tenure-consideration/tenure-consideration-edit/tenure-consideration-edit.dialog.component';
import {LandAreaListComponent} from './land-parcel/land-area/land-area-list/land-area-list.component';
import {LandAreaEditDialogComponent} from './land-parcel/land-area/land-area-edit/land-area-edit.dialog.component';
import {LandUseComponent} from './land-parcel/land-use/land-use.component';
import {ServicesInfrastructuresComponent} from './land-parcel/services-infrastructures/services-infrastructures.component';
// import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask'
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {BuildingInformationComponent} from './building-information/building-information.component';
import {LpBuildingInformationComponent} from './lp-building-information/lp-building-information.component';
import {LpBuildingTabComponent} from './lp-building-tab/lp-building-tab.component';
import {FacilitiesSubListComponent} from './facilities/list/facilities-sub-list.component';
import {FacilityAddDialogComponent} from './facilities/add/facility-add-dialog.component';
import {FacilityEditDialogComponent} from './facilities/edit/facility-edit-dialog.component';
import {BuiltinFittingsSubListComponent} from './builtin-fittings/list/builtin-fittings-sub-list.component';
import {BuiltinFittingAddDialogComponent} from './builtin-fittings/add/builtin-fitting-add-dialog.component';
import {BuiltinFittingEditDialogComponent} from './builtin-fittings/edit/builtin-fitting-edit-dialog.component';
import {TagInputModule} from 'ngx-chips';
import {NgbModule, NgbPopoverModule, NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ParticipantListComponent} from './participant/participant-list/participant-list.component';
import {ParticipantEditDialogComponent} from './participant/participant-edit/participant-edit.dialog.component';
import {PartialsModule} from '../../partials/partials.module';
import {AddSizeDialogComponent} from '../comparable/_sub/add-size-dialog/add-size-dialog.component';
import {BuildingInfoDialogComponent} from '../comparable/_sub/building-info-dialog/building-info-dialog.component';
import {AcValuationComponent} from './ac-valuation/ac-valuation.component';
import {AddValuationDialogComponent} from './add-valuation-dialog/add-valuation-dialog.component';
import {CheckboxAddComponent} from './add-checkboxes/checkbox-add.component';
import {AcAdditionalCostComponent} from './payment/ac-additional-cost/ac-additional-cost.component';
import {AcPaymentTermsAltComponent} from './payment/ac-payment-terms-alt/ac-payment-terms-alt.component';
import {AcValuationBreakdownComponent} from './payment/ac-valuation-breakdown/ac-valuation-breakdown.component';
import {AddAdditionalCostDialogComponent} from './payment/add-additional-cost-dialog/add-additional-cost-dialog.component';
import {AddPaymentTermDialogComponent} from './payment/add-payment-term-dialog/add-payment-term-dialog.component';
import {AgmCoreModule} from '@agm/core';
import {AuditReportDialogComponent} from './audit-report-dialog/audit-report-dialog.component';
import {UploadDocumentComponent} from './upload-document/upload-document.component';
import {ExternalReferencesListComponent} from './external-references/external-references-list/external-references-list.component';
import {ExternalReferencesEditDialogComponent} from './external-references/external-references-edit/external-references-edit.dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {featureReducer, UploadFileEffects} from '../../../core/file-upload';
import {FileUploadService} from '../../../core/file-upload/_services';
import {ImageViewerDialogComponent} from './image_viewer/image-viewer.dialog.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {BuildingService} from '../../../core/comparable';
import { DocumentInfoEditDialogComponent } from '../../partials/content/crud/document-info-edit-dialog/document-info-edit-dialog.component';
import {PictureSliderDialogComponent} from './picture-slider-dialog/picture-slider-dialog.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { MultiplePdfViewerComponent } from './multiple-pdf-viewer/multiple-pdf-viewer.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { ToeMembersComponent } from './toe-members/toe-members.component';
import { ToeMembersDailyRatesComponent } from './toe-members-daily-rates/toe-members-daily-rates.component';
import { AddMemberComponent } from './toe-members/add-member/add-member.component';
import { ConditionRatingEditComponent } from './condition-rating-table/condition-rating-edit/condition-rating-edit.component';
import { VideoViewerDialogComponent } from './upload-video/video-viewer-dialog/video-viewer-dialog.component';
import { VideoUploadService } from 'src/app/core/file-upload/_services/video-upload.service';
import { videoUploadReducer } from 'src/app/core/file-upload/_reducers/video-upload.reducers';
import { VideoUploadEffects } from 'src/app/core/file-upload/_effects/video-upload.effects';
import { VideoEditDialogComponent } from './upload-video/video-edit-dialog/video-edit-dialog.component';
import {LinkListComponent} from './links/link-list/link-list.component';
import {LinkEditDialogComponent} from './links/link-edit/link-edit.dialog.component';
import { SizeModuleComponent } from './size-module/size-module.component';
import { IpmsTableComponent } from './size-module/ipms-table/ipms-table.component';
import { MongoliaStandardTableComponent } from './size-module/mongolia-standard-table/mongolia-standard-table.component';
import { SizeService } from './size-module/size.service';
import { ColRowSizeSumPipe, ColSizeSumPipe, MsColSumPipe, MsRowSumPipe, MsTotalPipe, SumeSizePipe } from './size-module/pipes/size-sum.pipe';
import { NoNegativeDirective } from './size-module/directive/no-negative.directive';
import { PicturesModuleComponent } from '../multimedia/_sub/pictures-module/pictures-module.component';
import { MMImageViewerDialogComponent } from '../multimedia/_sub/image_viewer/mm-image-viewer.dialog.component';
import { MadNgbImageViewerComponent } from './mad-ngb-image-viewer/mad-ngb-image-viewer.component';
import { AcDocumentUploadComponent } from './ac-document-upload/ac-document-upload.component';
import { LandParcelMapComponent } from './land-parcel/land-parcel-map/land-parcel-map.component';
import { PropertyObservationChecklistComponent } from './property-observation-checklist/property-observation-checklist.component';
import { ChecklistTableComponent } from './property-observation-checklist/_sub/checklist-table/checklist-table.component';
import { ExternalAspectsComponent } from './external-aspects/external-aspects.component';
import { InternalAspectsComponent } from './internal-aspects/internal-aspects.component';
import { PostValuationDeliveryServiceComponent } from './post-valuation-delivery-service/post-valuation-delivery-service.component';
import { ServicesAndInfrastructuresComponent } from './services-and-infrastructures/services-and-infrastructures.component'
import { AccommodationLayoutTableComponent } from './accommodation-layout-table/accommodation-layout-table.component';
import { AcGroundsComponent } from './ac-grounds/ac-grounds.component';
import { ParcelConsiderationsComponent } from './parcel-considerations/parcel-considerations.component';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { OwlDateTimeModule } from '@busacca/ng-pick-datetime';
import { TimeFieldComponent } from './inspection-details/time-field/time-field.component';
import { MapLocationComponent } from './map-location/map-location.component';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { AboutPropertyComponent } from './about-property/about-property.component';
import { BuildingComponent } from './building/building.component';
import { ParcelIdentifactionSectionComponent } from './parcel-identifaction-section/parcel-identifaction-section.component';
import { BuildingSectionComponent } from './building-section/building-section.component';
import { BuildingInternalAspectsComponent } from './building-internal-aspects/building-internal-aspects.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { ExportFinancialsDialogComponent } from './export-financials-dialog/export-financials-dialog.component';
import { MapMarkerInfoWindowComponent } from './map-marker-info-window/map-marker-info-window.component';
import { ToMarkerItemConverterService } from './map-marker-info-window/to-marker-item-converter-service.service';
import { SourceComponent } from './source/source.component';
import { FilterSectionComponent } from './filter-section/filter-section.component';
import { FilterSelectionComponent } from './filter-section/filter-selection/filter-selection.component';
import { FilterSelectionCurrencyComponent } from './filter-section/filter-selection-currency/filter-selection-currency.component';
import { FilterDateSelectionComponent } from './filter-section/filter-date-selection/filter-date-selection.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OtherFiltersComponent } from './filter-section/other-filters/other-filters.component';
import { FilterDialogComponent } from './filter-section/filter-dialog/filter-dialog.component';
import {NpnSliderModule} from 'npn-slider';
import { FilterComponent } from './filter/filter.component';
import { FSelectionComponent } from './filter/f-selection/f-selection.component';
import { ContactEditDialogComponent } from './client/_subs/client_contact/contact-edit/contact-edit.dialog.component';
import { ContactListComponent } from './client/_subs/client_contact/contact-list/contact-list.component';
import { ClientModalComponent } from './client/client-modal/client-modal.component';
import { SizesModuleComponent } from './sizes-module/sizes-module.component';
import { IpmsStandardMeasurementComponentComponent } from './sizes-module/ipms-standard-measurement-component/ipms-standard-measurement-component.component';
import { RegNumberEditDialogComponent } from './reg-numbers/reg-number-edit/reg-number-edit.dialog.component';
import { RegNumbersListComponent } from './reg-numbers/reg-numbers-list/reg-numbers-list.component';
import { ConditionRatingTableV2Component } from './condition-rating-table-v2/condition-rating-table-v2.component';
import { ConditionRatinModuleComponent } from '../multimedia/_sub/condition-ratin-module/condition-ratin-module.component';
import { ConditionRatingDocumentsComponent } from './condition-rating-documents/condition-rating-documents.component';
import { BuildingFormComponent } from './building-form/building-form.component';
import { UploadWrapperComponent } from './upload-wrapper/upload-wrapper.component';
import { ColorPickerModalComponent } from './color-picker-modal/color-picker-modal.component';
import { ToeSummaryComponent } from './toe-summary/toe-summary.component';
import { ParticipantService } from './participant/participant-list/participant.service';
import { FCheckboxComponent } from './filter/f-checkbox/f-checkbox.component';
import { MapComparableInfoComponent } from './map-comparable-info/map-comparable-info.component';
import { LocationComponent } from './location/location.component';
import { MadFormsModule } from '../mad-forms/mad-form.module';
import { DuplicateToeDialogComponent } from './duplicate-toe-dialog/duplicate-toe-dialog.component';
import { MoveToeDialogComponent } from './move-toe-dialog/move-toe-dialog.component';
import { DownloadToeFilesDialogComponent } from './download-toe-files-dialog/download-toe-files-dialog.component';
import { MadSharedModule } from '../mad_shared/shared.module';
import { BathroomFittingsSubListComponent } from './bathroom-fittings/list/bathroom-fittings-sub-list.component';
import { BathroomFittingEditDialogComponent } from './bathroom-fittings/edit/bathroom-fitting-edit-dialog.component';
import { BathroomFittingAddDialogComponent } from './bathroom-fittings/add/bathroom-fitting-add-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        NgbPopoverModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        TagInputModule,
        FormsModule,
        ReactiveFormsModule,
        PartialsModule,
        NgxMatSelectSearchModule,
        MadFormsModule,
        NgxDaterangepickerMd.forRoot(),
        TranslateModule.forChild(),
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        AgmMarkerClustererModule,
        PdfViewerModule,
        ColorChromeModule,
        StoreModule.forFeature('uploadFile', featureReducer),
        StoreModule.forFeature('videoUpload', videoUploadReducer),
        EffectsModule.forFeature([UploadFileEffects, VideoUploadEffects]),
        OwlDateTimeModule,
        NpnSliderModule,
        MadSharedModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        FacilitiesSubListComponent,
        FacilityAddDialogComponent,
        FacilityEditDialogComponent,
        BuiltinFittingsSubListComponent,
        BuiltinFittingAddDialogComponent,
        BuiltinFittingEditDialogComponent,
        BathroomFittingsSubListComponent,
        BathroomFittingEditDialogComponent,
        BathroomFittingAddDialogComponent,
        UploadFileComponent,
        UploadVideoComponent,
        UploadDocumentComponent,
        AcSizeComponent,
        PolyLinesComponent,
        TenureConsiderationListComponent,
        TenureConsiderationEditDialogComponent,
        LandAreaListComponent,
        LandAreaEditDialogComponent,
        LandUseComponent,
        ServicesInfrastructuresComponent,
        AuditReportDialogComponent,
        AddSizeDialogComponent,
        BuildingInformationComponent,
        LpBuildingInformationComponent,
        LpBuildingTabComponent,
        BuildingInfoDialogComponent,
        ParticipantListComponent,
        ParticipantEditDialogComponent,
        AcValuationComponent,
        AddValuationDialogComponent,
        CheckboxAddComponent,
        AcAdditionalCostComponent,
        AcValuationBreakdownComponent,
        AddAdditionalCostDialogComponent,
        ImageViewerDialogComponent,
        AcPaymentTermsAltComponent,
        AddPaymentTermDialogComponent,
        PictureSliderDialogComponent,
        ExternalReferencesListComponent,
        ExternalReferencesEditDialogComponent,
        ComingSoonComponent,
        FormErrorComponent,
        MultiplePdfViewerComponent,
        TabHeaderComponent,
        ToeMembersComponent,
        ToeMembersDailyRatesComponent,
        AddMemberComponent,
        ConditionRatingEditComponent,
        VideoViewerDialogComponent,
        VideoEditDialogComponent,
        LinkListComponent,
        LinkEditDialogComponent,
        SizeModuleComponent,
        IpmsTableComponent,
        MongoliaStandardTableComponent,
        SumeSizePipe,
        ColSizeSumPipe,
        ColRowSizeSumPipe,
        MsRowSumPipe,
        MsColSumPipe,
        MsTotalPipe,
        NoNegativeDirective,
        PicturesModuleComponent,
        MMImageViewerDialogComponent,
        MadNgbImageViewerComponent,
        AcDocumentUploadComponent,
        LandParcelMapComponent,
        PropertyObservationChecklistComponent,
        ChecklistTableComponent,
        ExternalAspectsComponent,
        InternalAspectsComponent,
        PostValuationDeliveryServiceComponent,
        ServicesAndInfrastructuresComponent,
        AccommodationLayoutTableComponent,
        AcGroundsComponent,
        ParcelConsiderationsComponent,
        InspectionDetailsComponent,
        TimeFieldComponent,
        MapLocationComponent,
        AboutPropertyComponent,
        BuildingComponent,
        ExportDialogComponent,
        ExportFinancialsDialogComponent,
        ParcelIdentifactionSectionComponent,
        BuildingSectionComponent,
        BuildingInternalAspectsComponent,
        MapMarkerInfoWindowComponent,
        SourceComponent,
        FilterSectionComponent,
        FilterSelectionComponent,
        FilterSelectionCurrencyComponent,
        FilterDateSelectionComponent,
        OtherFiltersComponent,
        FilterDialogComponent,
        FilterComponent,
        FSelectionComponent,
        ContactEditDialogComponent,
        ContactListComponent,
        ClientModalComponent,
        SizesModuleComponent,
        IpmsStandardMeasurementComponentComponent,
        RegNumbersListComponent,
        RegNumberEditDialogComponent,
        ConditionRatingTableV2Component,
        ConditionRatinModuleComponent,
        ConditionRatingDocumentsComponent,
        BuildingFormComponent,
        UploadWrapperComponent,
        ColorPickerModalComponent,
        ToeSummaryComponent,
        FCheckboxComponent,
        MapComparableInfoComponent,
        LocationComponent,
        DuplicateToeDialogComponent,
        MoveToeDialogComponent,
        DownloadToeFilesDialogComponent,
    ],
    exports: [
        FacilitiesSubListComponent,
        FacilityAddDialogComponent,
        FacilityEditDialogComponent,
        BuiltinFittingsSubListComponent,
        BuiltinFittingAddDialogComponent,
        BuiltinFittingEditDialogComponent,
        BathroomFittingsSubListComponent,
        BathroomFittingEditDialogComponent,
        BathroomFittingAddDialogComponent,
        ParticipantListComponent,
        ParticipantEditDialogComponent,
        UploadFileComponent,
        UploadVideoComponent,
        UploadDocumentComponent,
        AcSizeComponent,
        PolyLinesComponent,
        TenureConsiderationListComponent,
        LandAreaListComponent,
        LandUseComponent,
        ServicesInfrastructuresComponent,
        AuditReportDialogComponent,
        BuildingInformationComponent,
        LpBuildingInformationComponent,
        LpBuildingTabComponent,
        AcValuationComponent,
        AddValuationDialogComponent,
        PictureSliderDialogComponent,
        CheckboxAddComponent,
        AcAdditionalCostComponent,
        AcValuationBreakdownComponent,
        AcPaymentTermsAltComponent,
        ExternalReferencesListComponent,
        ExternalReferencesEditDialogComponent,
        ComingSoonComponent,
        FormErrorComponent,
        TabHeaderComponent,
        ToeMembersComponent,
        ToeMembersDailyRatesComponent,
        LinkListComponent,
        LinkEditDialogComponent,
        SizeModuleComponent,
        MMImageViewerDialogComponent,
        PicturesModuleComponent,
        AcDocumentUploadComponent,
        LandParcelMapComponent,
        PropertyObservationChecklistComponent,
        ExternalAspectsComponent,
        InternalAspectsComponent,
        PostValuationDeliveryServiceComponent,
        ServicesAndInfrastructuresComponent,
        AccommodationLayoutTableComponent,
        AcGroundsComponent,
        ParcelConsiderationsComponent,
        InspectionDetailsComponent,
        TimeFieldComponent,
        MapLocationComponent,
        AboutPropertyComponent,
        BuildingComponent,
        ParcelIdentifactionSectionComponent,
        BuildingSectionComponent,
        BuildingInternalAspectsComponent,
        MapMarkerInfoWindowComponent,
        SourceComponent,
        FilterSectionComponent,
        FilterSelectionComponent,
        FilterSelectionCurrencyComponent,
        FilterDateSelectionComponent,
        FilterComponent,
        FSelectionComponent,
        ContactListComponent,
        ClientModalComponent,
        SizesModuleComponent,
        RegNumbersListComponent,
        RegNumberEditDialogComponent,
        ConditionRatingTableV2Component,
        ConditionRatinModuleComponent,
        ConditionRatingDocumentsComponent,
        BuildingFormComponent,
        UploadWrapperComponent,
        ColorPickerModalComponent,
        ToeSummaryComponent,
        FCheckboxComponent,
        MapComparableInfoComponent,
        LocationComponent,
        DuplicateToeDialogComponent,
        MoveToeDialogComponent,
        DownloadToeFilesDialogComponent
    ],
    providers: [
        MatDialogConfig,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                maxHeight: 'auto',
                width: '900px'
            }
        },
        FileUploadService,
        BuildingService,
        VideoUploadService,
        SizeService,
        DatePipe,
        ToMarkerItemConverterService,
        ParticipantService,
        provideNgxMask()
    ]
})

export class SharedModule {
}
