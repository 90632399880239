import { createFeatureSelector, createSelector } from "@ngrx/store";
import { each } from "lodash";
import { HttpExtenstionsModel } from "../../_base/crud";
import { ToeReportTaskModel } from "../_models/toe-report-task.model";
import { ToeReportTasksState } from "../_reducers/toe-report-task.reducer";

export const selectToeReportTasksState = createFeatureSelector<ToeReportTasksState>('toeReportTasks');
export const selectReportTasksByToeID = (toeID: number) => createSelector(
	selectToeReportTasksState,
	state => {
		const items: ToeReportTaskModel[] = [];
		each(state.entities, element => {
			items.push(element);
		});
		const httpExtension = new HttpExtenstionsModel();
		const tasks = items.filter(i => i.toe_id == toeID);
		const result: ToeReportTaskModel[] = httpExtension.sortArrayNumber(tasks, 'task_id', 'asc');
		return result;
	}
)