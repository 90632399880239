// Angular
import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
// CRUD
import {QueryResultsModel} from '../models/query-models/query-results.model';
import {QueryParamsModel} from '../models/query-models/query-params.model';
import {HttpExtenstionsModel} from '../../crud/models/http-extentsions-model';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class HttpUtilsService {
    /**
     * Prepare query http params
     * @param queryParams: QueryParamsModel
     */
    getFindHTTPParams(queryParams): HttpParams {

        let params = new HttpParams()
            .set('filter[name]', queryParams.filter ? queryParams.filter.name : '')
            .set('filter[status]', queryParams.filter ?
                (Object.is(queryParams.filter.status, undefined) ?
                    -1 : queryParams.filter.status) : -1)
            .set('filter[with_hidden]', queryParams.filter && queryParams.filter.with_hidden ? 'true' : '')
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString());

        if (queryParams.filter && queryParams.filter.hasOwnProperty('max_lng')) {
            params = new HttpParams()
                .set('filter[max_lng]', queryParams.filter.max_lng)
                .set('filter[max_lat]', queryParams.filter.max_lat)
                .set('filter[min_lng]', queryParams.filter.min_lng)
                .set('filter[min_lat]', queryParams.filter.min_lat)

                .set('filter[sw_lat]', queryParams.filter.sw_lat)
                .set('filter[ne_lat]', queryParams.filter.ne_lat)

                .set('filter[sw_lng]', queryParams.filter.sw_lng)
                .set('filter[ne_lng]', queryParams.filter.ne_lng)

                .set('filter[name]', queryParams.filter.name)
                .set('filter[status]', Object.is(queryParams.filter.status, undefined) ? -1 : queryParams.filter.status)

                .set('sortOrder', queryParams.sortOrder)
                .set('sortField', queryParams.sortField)
                .set('page', queryParams.pageNumber.toString())
                .set('pageSize', queryParams.pageSize.toString());
        }


        return params;
    }

    /**
     * Prepare query http params
     * @param queryParams: QueryParamsFacilityModel
     */
    getFindFacilityHTTPParams(queryParams): HttpParams {
        const params = new HttpParams()
            .set('filter[name]', queryParams.filter.name)
            .set('filter[status]', Object.is(queryParams.filter.status, undefined) ? -1 : queryParams.filter.status)
            .set('filter[with_hidden]', queryParams.filter && queryParams.filter.with_hidden ? 'true' : '')
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
            .set('isBuilding', queryParams.isBuilding)
            .set('acTypeId', queryParams.acTypeId);

        return params;
    }

    /**
     * Prepare query http params
     * @param queryParams: QueryParamsFacilityModel
     */
    getFindDividedHTTPParams(queryParams): HttpParams {
        const params = new HttpParams()
            .set('filter[name]', queryParams.filter.name)
            .set('filter[status]', Object.is(queryParams.filter.status, undefined) ? -1 : queryParams.filter.status)
            .set('filter[with_hidden]', queryParams.filter && queryParams.filter.with_hidden ? 'true' : '')
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
            .set('propertySubTypeId', queryParams.propertySubTypeId);

        return params;
    }

    /**
     * get standard content-type
     */
    getHTTPHeaders(): HttpHeaders {
        const userToken = localStorage.getItem(environment.authTokenKey) ? localStorage.getItem(environment.authTokenKey) : sessionStorage.getItem('multimediaToken');
        let result = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Headers', '*');
        if (userToken) {
            result = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + userToken);
        }
        return result;
    }

    /**
     * get standard content-type
     */
     getHTTPHeadersForUpload(): HttpHeaders {
        const userToken = localStorage.getItem(environment.authTokenKey) ? localStorage.getItem(environment.authTokenKey) : sessionStorage.getItem('multimediaToken');
        let result = new HttpHeaders()
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Headers', '*');
        if (userToken) {
            result = new HttpHeaders().set('Authorization', 'Bearer ' + userToken);
        }
        return result;
    }

    /**
     * get standard content-type
     */
    getMMHTTPHeaders(): HttpHeaders {
        const userToken = sessionStorage.getItem('multimediaToken');
        let result = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Headers', '*');
        if (userToken) {
            result = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + userToken);
        }
        return result;
    }

    getTokenString(): string {
        const userToken = localStorage.getItem(environment.authTokenKey);
        return '?token=' + userToken;
    }

    baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
        const httpExtention = new HttpExtenstionsModel();
        return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
    }

    sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
        const httpExtention = new HttpExtenstionsModel();
        return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
    }

    searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
        const httpExtention = new HttpExtenstionsModel();
        return httpExtention.searchInArray(_incomingArray, _queryObj, _filtrationFields);
    }
}
