import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AppState} from '../../../../../core/reducers';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { selectUsersInStore, User} from '../../../../../core/auth';
import { MadRole } from 'src/app/core/mad-auth/mad-roles.store';
import {SupportingValuerModel} from '../../../../../core/toe/_models/supporting-valuer.model';
import {TranslateService} from '@ngx-translate/core';
import {
    AllValuerQualificationsRequested,
    ValuerQualification,
    selectAllValuerQualifications,
} from '../../../../../core/linked-tables';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'kt-support-valuer-edit',
    templateUrl: './support-valuer-edit.dialog.component.html',
})
export class SupportValuerEditDialogComponent implements OnInit, OnDestroy {

    // Public properties
    worker: SupportingValuerModel;
    workerForm: UntypedFormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    valuerQualifications: ValuerQualification[] = [];

    workers: User[] = [];
    filteredWorkers: User[] = [];
    roles: MadRole[] = [];

    tpTasks: Array<{
        id: number,
        name: string,
        disabled: boolean,
        selected: boolean
    }> = [
        {
            id: 0,
            name: 'Documents Upload (External References)',
            disabled: false,
            selected: false
        },
        {
            id: 1,
            name: 'Investigation',
            disabled: false,
            selected: false
        },
        {
            id: 2,
            name: 'Valuation',
            disabled: false,
            selected: false
        },
        {
            id: 3,
            name: 'Reporting',
            disabled: false,
            selected: false
        },
        {
            id: 4,
            name: 'Delivery',
            disabled: false,
            selected: false
        },
        {
            id: 10,
            name: 'Document Upload (Signed Report)',
            disabled: false,
            selected: false
        },
    ];

    private subscriptions: Subscription[] = [];

    // Private properties
    private componentSubscriptions: Subscription;

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     * @param fb
     * @param store: Store<AppState>
     */
    constructor(public dialogRef: MatDialogRef<SupportValuerEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: UntypedFormBuilder,
                private store: Store<AppState>,
                private translate: TranslateService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.tpTasks = this.tpTasks.map(t => {
            const selected = this._isTaskSelected(this.data.teamMembers.value, t.id);
            return {
                ...t,
                selected
            }
        }) 
        this.store.dispatch(new AllValuerQualificationsRequested());
        const valuerQualificationsSubscribe = this.store.pipe(
            // distinctUntilChanged(),
            select(selectAllValuerQualifications))
            .subscribe(res => {
                this.valuerQualifications = [];
                if (res) {
                    this.valuerQualifications = res;
                }
            });
        this.subscriptions.push(valuerQualificationsSubscribe);

        const usersSub = this.store.pipe(
            select(selectUsersInStore))
            .subscribe(res => {
                this.workers = [];
                if (res) {
                    this.workers = res.items;
                    this.filteredWorkers = this.workers.filter(item => !this.data.teamMembers.value.map(item => parseInt(item.worker_id)).includes(item.id));
                }
            });
        this.subscriptions.push(usersSub);


        this.worker = new SupportingValuerModel();
        this.worker.clear();
        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Create form
     */
    createForm() {
        this.workerForm = this.fb.group({
            worker_id: [this.worker.worker_id, Validators.required],
            lead_valuer: [{value: this.worker.lead_valuer == 1, disabled: true}],
            task_id: [null]
        });
        const workerValueChangeSub = this.workerForm.controls.worker_id.valueChanges.pipe(
            startWith(this.workerForm.controls.worker_id.value)
        ).subscribe(worker_id => {
            this._changeLeadValuerState(worker_id);
        });
        this.subscriptions.push(workerValueChangeSub);
        const formSub = combineLatest([
            this.workerForm.controls.worker_id.valueChanges.pipe(startWith(this.workerForm.controls.worker_id.value)),
            this.workerForm.controls.lead_valuer.valueChanges.pipe(startWith(this.workerForm.controls.lead_valuer.value))
        ]).subscribe(([worker_id, isLeadValuer]) => {
            this._changeTasksAvailability(worker_id, isLeadValuer);
        })
        this.subscriptions.push(formSub);
        // this.workerForm.controls.worker_id.valueChanges.subscribe(worker_id => {
        //     this.disableSupportStaff();

        //     if (worker_id) {
        //         this.workerForm.controls.task_id.enable();
        //         this._changeTasksAvailability(worker_id);
        //     }
        // });
    }

    /** ACTIONS */

    /**
     * Returns contact for save
     */
    prepareSupportingValuerModel(): SupportingValuerModel {
        const valuer = new SupportingValuerModel();
        valuer.clear();
        valuer.worker_id = this.workerForm.controls.worker_id.value;
        valuer.lead_valuer = this.workerForm.controls.lead_valuer.value;
        // valuer.assigned_default_task_id = this.workerForm.controls.task_id.value;
        // if (valuer.assigned_default_task_id != null) {
        //     const task = this.tpTasks.find(t => t.id == valuer.assigned_default_task_id);
        //     if (task) {
        //         valuer.assigned_default_task_name = task.name;
        //     }
        // }
        // valuer.previous_involvment = this.workerForm.controls.previous_involvment.value;
        valuer.worker = this.workers.filter(x => x.id == valuer.worker_id)[0];
        return valuer;
    }

    /**
     * Save data
     */
    onSubmit() {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;

        const controls = this.workerForm.controls;
        /** check form */
        if (this.workerForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedSupportingValuerModel = this.prepareSupportingValuerModel();

        this.dialogRef.close({
            worker: editedSupportingValuerModel,
            isEdit: false
        });
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /** UI */
    /**
     * Returns component title
     */
    getTitle(): string {
        if (this.worker && this.worker.id) {
            return `Edit member info`;
        }
        return 'Add new team member';
    }

    disableSupportStaff() {
        if (this.workerForm.controls.worker_id.value != undefined) {
            let worker = this.workers.find(item => item.id == this.workerForm.controls.worker_id.value);
            if (worker && worker.qualification_id === 2) {
                this.workerForm.controls.lead_valuer.setValue(0);
                return true;
            }
        }
        return false;
    }

    getValuerQualificationName(id): string {
        if (!id) {
            return '(N/A)';
        }
        if (this.valuerQualifications.length > 0) {
            return this.valuerQualifications.find(el => el.id == id).name;
        } else {
            return 'N/A';
        }
    }

    private _changeLeadValuerState(worker_id: number) {
        if (worker_id == undefined) {
            return;
        }

        const worker = this.workers.find(w => w.id == worker_id);
        if (!worker) {
            return;
        }

        const isQualified = worker.qualification_id == 1;
        if (isQualified) {
            const reportTask = this.tpTasks.find(t => t.id == 3 && t.selected);
            if (reportTask) {
                this.workerForm.controls.lead_valuer.disable();
            } else {
                this.workerForm.controls.lead_valuer.enable();
            }
        } else {
            this.workerForm.controls.lead_valuer.patchValue(false);
            this.workerForm.controls.lead_valuer.disable();
        }
    }

    private _changeTasksAvailability(workerId: number, isLeadValuer: boolean) {
        const worker = this.workers.find(w => w.id == workerId);
        if (!worker) {
            return;
        }
        const isQualified = worker.qualification_id == 1;
        this.tpTasks = this.tpTasks.map(task => {
            let disabled = false;
            if (task.id == 2) {
                disabled = !isQualified;
            }
            if (task.id == 3) {
                disabled = !(isQualified && isLeadValuer);
            }
            disabled = task.selected || disabled;
            return {
                id: task.id,
                name: task.name,
                disabled,
                selected: task.selected
            }
        });

        const currentSelectedTaskId = this.workerForm.controls.task_id.value;
        if (currentSelectedTaskId) {
            const isSelectable = this.tpTasks.find(t => t.id == currentSelectedTaskId && !t.disabled);
            if (!isSelectable) {
                this.workerForm.controls.task_id.patchValue(null);
            }
        }
    }

    private _isTaskSelected(teamMembers: any[], id: number): boolean {
        const indx = teamMembers.findIndex(m => m.assigned_default_task_id == id);
        return indx > -1;
    }
}
