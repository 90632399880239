// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {LandTenuresService} from '../_services/land-tenure.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allLandTenuresLoaded} from '../_selectors/land-tenure.selectors';
// Actions
import {
    AllLandTenuresLoaded,
    AllLandTenuresRequested,
    LandTenureActionTypes,
    LandTenuresPageRequested,
    LandTenuresPageLoaded,
    LandTenureUpdated,
    LandTenuresPageToggleLoading,
    LandTenureDeleted,
    LandTenureOnServerCreated,
    LandTenureCreated,
    LandTenureAdminRestored,
    LandTenureDeletedFromAdminTrash,
    LandTenureDeletedFromTrash,
    LandTenureOnServerAdminRestored,
    LandTenureOnServerRestored,
    LandTenureRestored,
    LandTenureTrashFlushed,
    LandTenuresActionToggleLoading
} from '../_actions/land-tenure.actions';

@Injectable()
export class LandTenureEffects {
    showPageLoadingDispatcher = new LandTenuresPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new LandTenuresActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new LandTenuresActionToggleLoading({isLoading: false});

    @Effect()
    loadAllLandTenures$ = this.actions$
        .pipe(
            ofType<AllLandTenuresRequested>(LandTenureActionTypes.AllLandTenuresRequested),
            withLatestFrom(this.store.pipe(select(allLandTenuresLoaded))),
            filter(([action, isAllLandTenuresLoaded]) => !isAllLandTenuresLoaded),
            mergeMap(() => this.service.getAllLandTenures()),
            map(res => {
                return new AllLandTenuresLoaded({landTenures: res.data});
            })
        );

    @Effect()
    loadLandTenuresPage$ = this.actions$
        .pipe(
            ofType<LandTenuresPageRequested>(LandTenureActionTypes.LandTenuresPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findLandTenures(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new LandTenuresPageLoaded({
                    landTenures: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureDeleted>(LandTenureActionTypes.LandTenureDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteLandTenure(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureUpdated>(LandTenureActionTypes.LandTenureUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateLandTenure(payload.landTenure);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureOnServerCreated>(LandTenureActionTypes.LandTenureOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createLandTenure(payload.landTenure).pipe(
                    tap(res => {
                        this.store.dispatch(new LandTenureCreated({landTenure: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushLandTenureTrash$ = this.actions$
        .pipe(
            ofType<LandTenureTrashFlushed>(LandTenureActionTypes.LandTenureTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureOnServerRestored>(LandTenureActionTypes.LandTenureOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new LandTenureRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureOnServerAdminRestored>(LandTenureActionTypes.LandTenureOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new LandTenureAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureDeletedFromTrash>(LandTenureActionTypes.LandTenureDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashLandTenure$ = this.actions$
        .pipe(
            ofType<LandTenureDeletedFromAdminTrash>(LandTenureActionTypes.LandTenureDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: LandTenuresService, private store: Store<AppState>) {
    }
}
