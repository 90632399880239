import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AssetClassHouseService } from "src/app/core/comparable/_services/asset-class-house.service";

@Injectable()
export class AssetClassHouseGetResolver implements Resolve<any> {
    constructor(
        private assetClassHouseService: AssetClassHouseService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const id = Number(route.params.house_id);
        return this.assetClassHouseService.getAssetClass(id);
    }
}