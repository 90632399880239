// NGRX
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import {PremiseOfValueActions, PremiseOfValueActionTypes} from '../_actions/premise-of-value.actions';
// Models
import {PremiseOfValue} from '../_models/premise-of-value.model';
import {QueryParamsModel} from '../../_base/crud';

export interface PremiseOfValuesState extends EntityState<PremiseOfValue> {
    isAllPremiseOfValuesLoaded: boolean;
    queryRowsCount: number;
    queryResult: PremiseOfValue[];
    lastCreatedPremiseOfValueId: number;
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;

    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PremiseOfValue> = createEntityAdapter<PremiseOfValue>();

export const initialPremiseOfValuesState: PremiseOfValuesState = adapter.getInitialState({
    isAllPremiseOfValuesLoaded: false,
    queryRowsCount: 0,
    queryResult: [],
    lastCreatedPremiseOfValueId: undefined,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,

    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function premiseOfValuesReducer(state = initialPremiseOfValuesState, action: PremiseOfValueActions): PremiseOfValuesState {
    switch (action.type) {
        case PremiseOfValueActionTypes.PremiseOfValuesPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPremiseOfValueId: undefined
            };

        case PremiseOfValueActionTypes.PremiseOfValuesActionToggleLoading:
            return {
                ...state, actionLoading: action.payload.isLoading
            };

        case PremiseOfValueActionTypes.PremiseOfValueOnServerCreated:
            return {
                ...state
            };

        case PremiseOfValueActionTypes.PremiseOfValueCreated:
            return adapter.addOne(action.payload.premiseOfValue, {
                ...state, lastCreatedPremiseOfValueId: action.payload.premiseOfValue.id
            });

        case PremiseOfValueActionTypes.PremiseOfValueUpdated:
            return adapter.updateOne(action.payload.partialItem, state);

        case PremiseOfValueActionTypes.AllPremiseOfValuesLoaded:
            return adapter.addAll(action.payload.premiseOfValues, {
                ...state, isAllPremiseOfValuesLoaded: true
            });

        case PremiseOfValueActionTypes.PremiseOfValuesPageCancelled:
            return {
                ...state, listLoading: false, queryRowsCount: 0, queryResult: [], lastQuery: new QueryParamsModel({})
            };

        case PremiseOfValueActionTypes.PremiseOfValuesPageLoaded:
            return adapter.addMany(action.payload.premiseOfValues, {
                ...initialPremiseOfValuesState,
                totalCount: action.payload.totalCount,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });

        case PremiseOfValueActionTypes.PremiseOfValueDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case PremiseOfValueActionTypes.PremiseOfValueRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case PremiseOfValueActionTypes.PremiseOfValueDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case PremiseOfValueActionTypes.PremiseOfValueAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case PremiseOfValueActionTypes.PremiseOfValueDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case PremiseOfValueActionTypes.PremiseOfValueTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
