import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { result } from 'lodash';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { LayoutUtilsService, TypesUtilsService } from 'src/app/core/_base/crud';
import { ImageViewerDialogComponent } from 'src/app/views/pages/shared_components/image_viewer/image-viewer.dialog.component';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'kt-other-documents',
  templateUrl: './other-documents.component.html',
  styleUrls: ['./other-documents.component.scss']
})
export class OtherDocumentsComponent implements OnInit {
  @Input() user: User;
  @Input() readonly: boolean = false;

  dataSource = new MatTableDataSource();
  displayedColumns = ['type', 'title', 'date', 'insert_to_all_reports', 'actions'];
  docs = ['xlsx', 'xls', 'doc', 'docx'];

  basePath: string;
  path: string = '';

  documents = []

  constructor(
    public typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.basePath = environment.baseApiUrl;
  }

  ngOnInit(): void {
    this.path = this.user.path;
    this.documents = _.cloneDeep(this.user.reports);
    this.dataSource.data = this.documents;
  }

  onFileUploaded(res: any) {
    this.documents.push(res);
    this.dataSource.data = this.documents;
  }

  getFileType(name) {
    return name.substring(name.indexOf('.') + 1, name.length).toLowerCase();
  }

  public inputChange(event, file) {
    const itemIndex = this.documents.findIndex(uf => {
      return uf.name == file.name
    });
    if (itemIndex == -1) {
      return;
    }
    const item = this.documents[itemIndex];
    const newitem = Object.assign({}, item);
    newitem.title = event.target.value;
    this.documents.splice(itemIndex, 1, newitem);
    this.dataSource.data = this.documents;
  }

  isExpired(dateValue) {
    if (dateValue == null || dateValue == undefined || dateValue.length == 0) {
      return false;
    }
    const date = this.typesUtilsService.getDateFromString(dateValue);
    return this.typesUtilsService.compareTwoDate(date, new Date()) <= 0;
  }

  onchange($ev, obj: any) {
    const index = this.documents.indexOf(obj);
    const editedObj = Object.assign({}, obj);
    editedObj.is_checked = !editedObj.is_checked;
    this.documents = Object.assign([], this.documents, { [index]: editedObj });
    this.dataSource.data = this.documents;
  }

  setIcon(type) {
    let ext = 'doc';
    switch (type) {
      case 'png':
        ext = 'png';
        break;
      case 'jpeg':
        ext = 'jpg';
        break;
      case 'jpg':
        ext = 'jpg';
        break;
      case 'gif':
        ext = 'gif';
        break;
      case 'pdf':
        ext = 'pdf';
        break;
      case 'xls':
        ext = 'xls';
        break;
      case 'xlsx':
        ext = 'xls';
        break;
      case 'rtf':
        ext = 'doc';
        break;
      case 'doc':
        ext = 'doc';
        break;
      case 'docx':
        ext = 'doc';
        break;
      case 'mp3':
        ext = 'mp3';
        break;
    }
    return './assets/media/files-alt/' + ext + '.svg';
  }

  deleteUploadedFile(obj) {
    const index = this.documents.indexOf(obj);
    this.documents.splice(index, 1);
    this.dataSource.data = this.documents;
  }

  previewUploadedFile(obj) {
    if (this.docs.indexOf(this.getFileType(obj.name)) > -1) {
      window.open(this.basePath + obj.path + '/' + obj.name);
    } else {
      const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
        data: {
          title: obj.title,
          picture: obj.path + '/' + obj.name,
          type: this.getFileType(obj.name)
        }
      });
    }
  }

  editFileDescription(file: any) {
    const dialogRef = this.layoutUtilsService.editElementWithInfo({
      title: 'Other documents',
      configs: [
        {
          type: 'text', 
          key: 'title', 
          label: 'Document Title', 
          value: file.title,
        },
        {
          type: 'checkbox', 
          key: 'is_checked', 
          label: 'Automatically insert to all reports', 
          value: file.is_checked,
          class: 'mt-4'
        },
        {
          type: 'date-picker',
          key: 'date',
          label: 'Expiration Date',
          value: file.date,
          class: 'mt-4'
        }
      ]
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      const index = this.documents.indexOf(file);
      const editedOb = Object.assign({}, file);
      res.result.forEach(r => {
        if (r.type == 'date-picker') {
          editedOb[r.key] = r.value ? this.typesUtilsService.getDateStringFromDate(r.value) : '';
        } else {
          editedOb[r.key] = r.value;
        }
      });
      this.documents[index] = editedOb;
      this.dataSource.data = this.documents;
    })
  }

  onPathChanged(path: string) {
    this.path = path;
  }
}
