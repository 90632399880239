import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {MainWallModel} from '../_models/main-wall.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class MainWallService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/main-walls`;
    constructor(private http: HttpClient, private authService: MadAuthService, private httpUtils: HttpUtilsService, private subDomainService: SubdomainService) {
    }

    // MainWalls
    getAll(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }


    fetch(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(this.API_URL, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    getById(MainWallId: number): Observable<MainWallModel> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<MainWallModel>(this.API_URL + `/${MainWallId}`, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new MainWall to the server
    create(_item: MainWallModel): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();

        return this.http.post(this.API_URL, _item, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the MainWall on the server
    update(MainWall: MainWallModel): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${MainWall.id}`, MainWall, {headers: httpHeaders});
    }

    // DELETE => delete the MainWall from the server
    delete(MainWallId: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_URL}/${MainWallId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }

    // TRASH (User)
    getTrashed(): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }

    deleteFromTrash(_id: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${_id}`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashed(): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(_id: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${_id}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {

        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: httpHeaders});
    }


    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
