<div class="col-md-12" *ngIf="numberOfTerms$|async as numberOfTerms" style="display: flex;">
    <strong>Number of Terms : </strong> 
    <span class="ml-5">{{numberOfTerms.val}}</span>
    <ng-template #terms>
        <strong>Calculation:</strong> Number of Terms
        <br>
        =
            <span class="text-red">Roundup(</span>
            <span class="text-blue">{{methodVersion === methodVersionEnum.WriteOffIsLesserThanLease
                ? '(Write off Period)'
                : '(Lease Duration)'}}</span>
            <span class="text-red">/</span>
            <span class="text-blue">(Rent Review Cycle)</span>
            <span class="text-red">)</span>
        <br>
        <ng-container *ngIf="numberOfTerms">
            = 
                <span class="text-red">Roundup(</span>
                <span class="text-blue">{{methodVersion === methodVersionEnum.WriteOffIsLesserThanLease
                    ? (write_off_period | mask:'separator.2')
                    : consideration.lease_duration}}</span>
                <span class="text-red">/</span>
                <span class="text-blue">{{rent_review | mask:'separator.2'}}</span>
                <span class="text-red">)</span>
            <br>
            =
                <span class="text-red">Roundup(</span>
                <span class="text-blue">{{numberOfTerms.real}}</span>
                <span class="text-red">)</span>
            <br>
            = <span class="text-green">{{numberOfTerms.val}}</span>
        </ng-container>
    </ng-template>
    <span class="ml-10" popoverClass="my-popover" placement="top" container="body" [ngbPopover]="terms" style="cursor: pointer;">
        <mat-icon color="primary">information</mat-icon>
    </span>
</div>

<form [formGroup]="form">
    <div class="col-md-12">
        <div class="row" >
            <div class="col-md-6">
                <mat-form-field  class="mat-form-field-fluid">
                    <input matInput 
                        placeholder="Target Rate (%)" 
                        formControlName="targetRate"
                        mask="percent.5"
                        suffix="%">
                    <mat-error *ngIf="form.controls.targetRate.hasError('required')">
                        Target Rate is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls.targetRate.hasError('isZero')">
                        Target Rate cannot be <strong>0</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field  class="mat-form-field-fluid">
                    <input matInput placeholder="Target Rate Justification" 
                        formControlName="targetRateJustification">
                    <mat-error>
                        Target Rate Justification is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput 
                        placeholder="Assumed Growth Rate (%)" 
                        formControlName="growthRate"
                        mask="percent.5"
                        suffix="%">
                    <mat-error *ngIf="form.controls.growthRate.hasError('required')">
                        Assumed Growth Rate is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls.growthRate.hasError('isZero')">
                        Assumed Growth Rate cannot be <strong>0</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Assumed Growth Rate Justification" 
                        formControlName="growthRateJustification">
                    <mat-error>
                        Assumed Growth Rate Justification is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <ng-container *ngIf="templateData$|async as data">
        <ng-container *ngIf="data !== null">
            <div class="col-md-12 mb-10 section-margin-top-60">
                <h5 class="my-3 text-mad text-uppercase">cash flow method</h5>
                <hr class="active">
            </div>
        
            <kt-preliminary-table [data]="data"></kt-preliminary-table>
            <kt-first-term-table 
                [data]="data.calculatedResult.firstTerm"
                [rentReview]="rent_review"
                [fittingOut]="fitting_out_period"
                [targetRate]="data.rateValue.targetRate"></kt-first-term-table>
            <ng-container *ngFor="let midTerm of data.calculatedResult.midTerms; let i = index">
                <kt-mid-term-table
                    [data]="midTerm"
                    [growthRate]="data.rateValue.growthRate"
                    [targetRate]="data.rateValue.targetRate"
                    [rentReview]="rent_review"
                    [term]="i + 2"></kt-mid-term-table>
            </ng-container>
            <kt-last-term-table 
                [data]="data.calculatedResult.lastTerm"
                [growthRate]="data.rateValue.growthRate"
                [targetRate]="data.rateValue.targetRate"
                [remainingYears]="data.calculatedResult.remainingYears"
                [term]="data.calculatedResult.numberOfTerms"
                [rentReview]="rent_review"></kt-last-term-table>

        <div class="col-md-12 mb-1 mt-4">
            <h6 class="my-3 text-mad text-uppercase">Factor value</h6>
        </div>
        <div class="col-md-12 mat-table-wrapper">
            <table class="table lmat-elevation-z8" style="overflow: hidden;">
                <tr>
                    <th class="calculation__name">
                        Factor Value of Effective Rent
                    </th>
                    <td class="calculation__value">
                        <ng-container *ngIf="data.calculatedResult.factorValue; else notAvailable">
                            {{data.calculatedResult.factorValue}}
                        </ng-container>
                    </td>
                    <td class="calculation__tooltip">
                        <ng-template #factorValue>
                            <strong>Calculation:</strong> Factor Value of Effective Rent
                            <br>
                            =
                                <span class="text-blue">(Factor Value of 1st term)</span>
                                <span class="text-red"> + </span>
                                <ng-container *ngFor="let term of data.calculatedResult.midTerms; let i = index">
                                    <span class="text-blue">(Factor value of {{i + 1}}{{term.prevTermSuffix}} revesionary rent)</span>
                                    <span class="text-red"> + </span>
                                </ng-container>
                                <span class="text-blue">(Factor value of {{data.calculatedResult.numberOfTerms - 1}}{{data.calculatedResult.lastTerm.prevTermSuffix}} reversionary rent)</span>
                            <br>
                            <ng-container *ngIf="data.calculatedResult.factorValue">
                                = 
                                <span class="text-blue">{{data.calculatedResult.firstTerm.factorValue}}</span>
                                <span class="text-red"> + </span>
                                <ng-container *ngFor="let term of data.calculatedResult.midTerms; let i = index">
                                    <span class="text-blue">{{term.factorValueOfRevisionaryRent}}</span>
                                    <span class="text-red"> + </span>
                                </ng-container>
                                <span class="text-blue">{{data.calculatedResult.lastTerm.factorValueOfRevisionaryRent}}</span>
                                <br>
                                =
                                <span class="text-green">{{data.calculatedResult.factorValue}}</span>
                            </ng-container>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="factorValue" style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    
        <div class="col-md-12 mb-1 mt-4">
            <h6 class="my-3 text-mad text-uppercase">Results</h6>
        </div>
        <div class="col-md-12 mat-table-wrapper">
            <table class="table lmat-elevation-z8" style="overflow: hidden;">
                <tr class="computed">
                    <th class="calculation__result__name">
                        Effective Rent ({{data.tpCurrency}})
                    </th>
                    <td class="calculation__result__value">
                        <ng-container *ngIf="data.calculatedResult.results.effectiveRent; else notAvailable">
                            {{data.calculatedResult.results.effectiveRent | mask:'separator.2':{thousandSeparator: ','} }}
                        </ng-container>
                    </td>
                    <td class="calculation__result__checkbox">
                        <mat-checkbox 
                            [checked]="data.calculatedResult.results.isSelected"
                            (change)="onSelectEffectiveRent(true, undefined)"
                            [disabled]="data.calculatedResult.results.effectiveRent === undefined || data.calculatedResult.results.isSelected"></mat-checkbox>
                    </td>
                    <td class="calculation__result__tooltip">
                        <ng-template #effectiveRent>
                            <strong>Calculation:</strong> Effective Rent
                            <br>
                            =
                                <span class="text-blue">(Capital Value of Inducements)</span>
                                <span class="text-red"> / </span>
                                <span class="text-blue">(Factor Value of Effective Rent)</span>
                            <br>
                            <ng-container *ngIf="data.calculatedResult.results.effectiveRent">
                                = 
                                    <span class="text-blue">{{data.calculatedResult.preliminary.capitalValueOfInducements | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                    <span class="text-red"> / </span>
                                    <span class="text-blue">{{data.calculatedResult.factorValue}}</span>
                                <br>
                                =
                                <span class="text-green">{{data.calculatedResult.results.effectiveRent | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            </ng-container>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="effectiveRent" style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
    
                <tr class="computed" *ngFor="let er of data.calculatedResult.results.effectiveRents; let i = index">
                    <th>
                        Effective Rent at {{i + 1}}{{er.termSuffix}} rent review ({{data.tpCurrency}})
                    </th>
                    <td>
                        <ng-container *ngIf="er.value; else notAvailable">
                            {{er.value | mask:'separator.2':{thousandSeparator: ','} }}
                        </ng-container>
                    </td>
                    <td>
                        <mat-checkbox 
                            [checked]="er.isSelected"
                            (change)="onSelectEffectiveRent(false, i)"
                            [disabled]="er.value === undefined || er.isSelected">
                        </mat-checkbox>
                    </td>
                    <td>
                        <ng-template #effectiveRent>
                            <strong>Calculation:</strong> Effective Rent at {{i + 1}}{{er.termSuffix}} rent review
                            <br>
                            =
                                <span class="text-blue">{{i === 0
                                    ? '(Effective Rent)'
                                    : '(Effective Rent ' + i + er.prevSuffix + ' Review)'}}</span>
                                <span class="text-red"> x (1 + </span>
                                <span class="text-blue">(Assumed Growth Rate)</span>
                                <span class="text-red">)</span>
                                <sup>
                                    <span class="text-blue">(Rent Review Cycle)</span>
                                </sup>
                            <br>
                            <ng-container *ngIf="er.value">
                                = 
                                    <span class="text-blue">{{er.prev | mask:'separator.2':{thousandSeparator: ','} }}</span>
                                    <span class="text-red"> x (1 + </span>
                                    <span class="text-blue">{{data.rateValue.growthRate}}%</span>
                                    <span class="text-red">)</span>
                                    <sup>
                                        <span class="text-blue">{{rent_review|mask:'separator.2'}}</span>
                                    </sup>
                                <br>
                                =
                                <span class="text-green">{{er.value | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            </ng-container>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="effectiveRent" style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
            </table>
            <span class="tag-custom-error" style="float: right;" *ngIf="selectError$|async">At lease one should be selected</span>
    
            <div class="col-md-6">
                <mat-form-field  class="mat-form-field-fluid">
                    <input matInput placeholder="Effective Rent Selection Justification" 
                        formControlName="effectiveRentJustification">
                    <mat-error>
                        Effective Rent Selection Justification is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        </ng-container>
    
        
    </ng-container>
</form>

<ng-template #yearsPurchase let-year="year" let-rate="rate">
    Year's Purchase (YP) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year}} year(s)
</ng-template>


<ng-template #presentValue let-year="year" let-rate="rate">
    Present Value (PV) @<ng-container *ngIf="rate; else notAvailable">{{rate}}</ng-container>% for {{year}} year(s)
</ng-template>

<ng-template #notAvailable>
    <span class="text-red">N/A</span>
</ng-template>