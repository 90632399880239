import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

type ActionTypes = 'increase' | 'decrease';

@Component({
  selector: 'kt-percent-input',
  templateUrl: './percent-input.component.html',
  styleUrls: ['./percent-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PercentInputComponent,
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PercentInputComponent implements OnInit, ControlValueAccessor {
  @Input() adjustmentName: string;
  @Input() comparableID: string;
  @Output() onPercentChange: EventEmitter<number> = new EventEmitter();

  form: UntypedFormGroup;
  currentVal: number = 0;

  decreaseButtonTooltip: string;
  increaseButtonTooltip: string;

  onChange: any = (val: number) => {};
  onTouched: any = () => {};
  touched = false;
  disabled = false;

  constructor(
    formBuilder: UntypedFormBuilder
  ) { 
    this.form = formBuilder.group({
      percent: [this.currentVal + '%']
    });
  }

  ngOnInit(): void {    
    this.decreaseButtonTooltip = `${this.adjustmentName} of Target Property less desirable than Comparable ${this.comparableID}`;
    this.increaseButtonTooltip = `${this.adjustmentName} of Target Property more desirable than Comparable ${this.comparableID}`;
  }

  writeValue(val: number): void {
    this._updateFormValue(val);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }  
  
  changeVal(action: ActionTypes) {
    const currentValue: number = this._getPercentValueInNum();
    switch (action) {
      case 'decrease': {
        const newVal = currentValue - 1;
        if (newVal < -100) {
          return;
        }
        this._updateFormValue(newVal);
        this._sendChange(newVal);
        break;
      }
      case 'increase': {
        const newVal = currentValue  + 1;
        if (newVal > 100) {
          return;
        }
        this._updateFormValue(newVal);
        this._sendChange(newVal);
        break;
      }
    }
  }

  onFocusOut(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    const _val = target.value.substr(0, target.value.length - 1);
    const val = Number(_val);
    this._updateFormValue(val);
    this._sendChange(val);
  }

  private _updateFormValue(val: number) {
    this.currentVal = val;
    this.form.controls.percent.setValue(val + '%')
    this.onChange(val);
  }

  private _sendChange(val: number) {
    this.onPercentChange.emit(val);
  }

  private _getPercentValueInNum(): number {
    const val: string = this.form.controls.percent.value;
    const numVal = Number(val.substr(0, val.length - 1));
    return numVal;
  }
}
