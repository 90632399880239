<div *ngIf="rentReviewType === rentReviewTypeEnum.RentReviewIsEqualToOneOrNA">
    <kt-time-value-of-money-method
        #timeValueOfMoneyMethod
        [refNum]="refNum"
        [tpCurrency]="tpCurrency"
        [title]="'single cash flow'"
        [selectedSubMethod$]="targetRate$"
        [consideration]="consideration"
        [ratesAndJustifications]="ratesAndJustification"
        (valueComputed)="tvomComputed($event)"
    ></kt-time-value-of-money-method>
</div>

<div *ngIf="rentReviewType === rentReviewTypeEnum.RentReviewIsGreaterThanOne">
    <kt-cfm 
        #cfm
        [refNum]="refNum"
        [tpCurrency]="tpCurrency"
        [consideration]="consideration" 
        [ratesAndJustifications]="ratesAndJustifications"
        [selected]="selected"
        (valueComputed)="cfmValueComputed($event)"></kt-cfm>
</div>
