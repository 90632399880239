import {RentReviewComment} from '../_models/rent-review-comment.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {RentReviewCommentsState} from '../_reducers/rent-review-comment.reducers';
import * as fromRentReviewComment from '../_reducers/rent-review-comment.reducers';
import {each} from 'lodash';

export const selectRentReviewCommentsState = createFeatureSelector<RentReviewCommentsState>('rentReviewComments');

export const selectRentReviewCommentById = (rentReviewCommentId: number) => createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => rentReviewCommentsState.entities[rentReviewCommentId]
);

export const selectAllRentReviewComments = createSelector(
    selectRentReviewCommentsState,
    fromRentReviewComment.selectAll
);

export const selectAllRentReviewCommentsIds = createSelector(
    selectRentReviewCommentsState,
    fromRentReviewComment.selectIds
);

export const allRentReviewCommentsLoaded = createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => rentReviewCommentsState.isAllRentReviewCommentsLoaded
);


export const selectRentReviewCommentsPageLoading = createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => rentReviewCommentsState.listLoading
);

export const selectRentReviewCommentsActionLoading = createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => rentReviewCommentsState.actionLoading
);

export const selectLastCreatedRentReviewCommentId = createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => rentReviewCommentsState.lastCreatedRentReviewCommentId
);

export const selectRentReviewCommentsShowInitWaitingMessage = createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => rentReviewCommentsState.showInitWaitingMessage
);

export const selectTrashedRentReviewCommentCount = createSelector(
    selectRentReviewCommentsState,
    (rentReviewCommentsState) => rentReviewCommentsState.totalTrashed
);

export const selectAdminTrashedRentReviewCommentCount = createSelector(
    selectRentReviewCommentsState,
    (rentReviewCommentsState) => rentReviewCommentsState.totalAdminTrashed
);


export const selectRentReviewCommentQueryResult = createSelector(
    selectRentReviewCommentsState,
    rentReviewCommentsState => {
        const items: RentReviewComment[] = [];
        each(rentReviewCommentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RentReviewComment[] = httpExtension.sortArray(items, rentReviewCommentsState.lastQuery.sortField, rentReviewCommentsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, rentReviewCommentsState.totalCount, '', rentReviewCommentsState.totalTrashed);
    }
);
