import {BaseModel} from '../../_base/crud';

export class AssetClassLandUseModel extends BaseModel {
    id: number;

    land_cover_type: string;
    qos_degradation_id: number;
    qos_degradation_name: string;
    comparative_land_use_id: number;
    comparative_land_use_name: string;
    sol_use_classification_id: number;
    sol_use_classification_name: string;
    other_characteristics: string;
    land_use_approval_implementation: string;
    building_exist: number;
    building_count: number;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = undefined;

        this.land_cover_type = '';
        this.qos_degradation_id = undefined;
        this.qos_degradation_name = '';
        this.comparative_land_use_id = undefined;
        this.comparative_land_use_name = '';
        this.sol_use_classification_id = undefined;
        this.sol_use_classification_name = '';
        this.other_characteristics = '';
        this.land_use_approval_implementation = '';
        this.building_exist = 2; // 2 means dont know
        this.building_count = 1;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
